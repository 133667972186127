import React from 'react';
import { StrictDropdownProps, DropdownItemProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { Dropdown } from 'components/atoms/Dropdown/Dropdown';

interface DropdownWithLabelProps extends StrictDropdownProps {
  options: DropdownItemProps[];
  onChange?: (e: React.SyntheticEvent, data: StrictDropdownProps) => void;
  title?: string;
  name?: string;
  selected?: string;
  placeholder?: string;
  search?: boolean;
  testId?: string;
  className?: string;
  isRequired?: boolean;
}
// NOTE: When using this component you need to create an outer container to set your desired width

export const DropdownWithLabel: React.FC<DropdownWithLabelProps> = ({
  onChange,
  options,
  title,
  name,
  selected,
  placeholder,
  search = true,
  testId,
  children,
  className = '',
  isRequired,
  ...props
}) => (
  <DropDownContainer>
    {title && (
      <Label>
        {title}
        <RequiredLabel>{isRequired && '*'}</RequiredLabel>
      </Label>
    )}
    <StyledDropdown
      testId={testId}
      name={name}
      onChange={onChange}
      search={search}
      placeholder={placeholder}
      options={options}
      value={selected}
      className={className}
      {...props}
    >
      {children}
    </StyledDropdown>
  </DropDownContainer>
);

const DropDownContainer = styled.div`
  &&&& {
    margin-bottom: 0;
    width: 100%;
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      color: ${props.theme.colors.neutralGrey8};
      width: 100%;
      height: ${props.theme.pxToRem(44)};
      padding-right: ${props.theme.pxToRem(10)};
      ${props.error ? `border: 1px solid ${props.theme.colors.interfaceRed};` : ''}
      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }
      &&&.ui.dropdown > .default {
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }
      &&&.ui.dropdown > .menu {
        max-height: ${props.theme.pxToRem(170)};
      }
      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: 1px solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: 1px solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.primaryPurple};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }
      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: 1px solid blue;
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
      &&&.ui.selection.dropdown {
        display: inline-flex;
        padding: 0 ${props.theme.pxToRem(10)} 0 0;
      }
      svg {
        color: ${props.theme.colors.primaryPurple};
      }
    }
  `}
`;

const Label = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const RequiredLabel = styled.label`
  color: ${props => props.theme.colors.interfaceRed};
`;
