import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { provisionSeat, setProvisioningLoading, setProvisioningError } from '../modules/centerAdmin';
import { provisioningLoadingSelector, provisioningErrorSelector } from '../modules/centerAdmin/selectors';
import { PageCenterAdminProvisioning } from 'components/pages/PageCenterAdminProvisioning/PageCenterAdminProvisioning';

const mapActionCreators = (dispatch: Dispatch) => ({
  provision(): void {
    dispatch(provisionSeat());
  },
  reset(): void {
    dispatch(setProvisioningLoading(false));
    dispatch(setProvisioningError(null));
  },
});

const mapStateToProps = (state: State.Root) => {
  return {
    loading: provisioningLoadingSelector(state),
    error: provisioningErrorSelector(state),
  };
};

export const PageCenterAdminProvisioningContainer = connect(
  mapStateToProps,
  mapActionCreators
)(PageCenterAdminProvisioning);
