import React from 'react';
import { Loader as SemanticLoader } from 'semantic-ui-react';
import styled from 'styled-components';

interface LoaderProps {
  content?: string;
  size?: string;
  active?: boolean;
  inline?: boolean | string;
  className?: string;
}

const StyledLoader = styled(SemanticLoader)`
  &&&&& {
    color: inherit;
  }

  &&&&&::before {
    border: 0.2em solid rgba(0, 0, 0, 0.1);
  }

  &&&&&::after {
    border-color: #767676 transparent transparent; /* this color is taken from semantic ui css */
  }
`;

export const Loader: React.FC<LoaderProps> = ({
  content = 'Loading',
  size = 'medium',
  active = true,
  inline,
  className,
  ...props
}) => <StyledLoader className={className} {...props} content={content} size={size} active={active} inline={inline} />;
