import { createSelector } from 'reselect';
import { SalesforceResponses } from 'mxp-schemas';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.exemptionEducational,
  (exemptionEducational: State.ExemptionEducational): State.ExemptionEducational => exemptionEducational
);

export const educationalQualificationFetchSelector = createSelector(
  rootSelector,
  (exemptionEducational: State.ExemptionEducational): boolean => exemptionEducational.exemptReferenceFetched
);

export const educationalQualificationSelector = createSelector(
  rootSelector,
  (exemptionEducational: State.ExemptionEducational): SalesforceResponses.SalesforceExemptionIdEducational | any =>
    exemptionEducational.exempReference
);

export const educationalQualificationrontloadRequestSelector = createSelector(
  rootSelector,
  (exemptionEducational: State.ExemptionEducational): boolean => exemptionEducational.exemptReferenceFrontloadRequest
);

export const educationQualificationSelector = createSelector(
  rootSelector,
  (exemptionEducational: State.ExemptionEducational): SalesforceResponses.SalesforceExemptionIdEducational | any =>
    exemptionEducational?.allEducationalReference
);
