import React from 'react';
import styled from 'styled-components';
import { InputWithValidation } from 'components/molecules';
import { CheckboxProps as SemanticUICheckboxProps, StrictDropdownProps } from 'semantic-ui-react';
import { Utils, InquiryForm as InquiryFormUtils } from 'mxp-utils';
import { Dropdown } from 'components/atoms';
import {
  channelInquiryFormAreaOfInterestOptions as areaOfInterestOptions,
  channelInquiryFormMarketSegmentOptions as marketSegmentOptions,
} from 'constants/index';
import { InquiryForm } from 'mxp-schemas';
import { StyledCheckbox } from './InquiryFormB2B';

interface InquiryFormChannelProps {
  setFormValid: (valid: boolean) => void;
  form: InquiryForm.Channel;
  setForm: (form: InquiryForm.Channel) => void;
  onFormStart: () => void;
}

export const InquiryFormChannel: React.FC<InquiryFormChannelProps> = ({ form, setForm, setFormValid, onFormStart }) => {
  const isFirstNameEmpty = !Boolean(form.firstName.length);
  const isFirstNameValid = Utils.isShorterThan(form.firstName, InquiryForm.FieldLengths.FIRST_NAME);
  const isLastNameEmpty = !Boolean(form.lastName.length);
  const isLastNameValid = Utils.isShorterThan(form.lastName, InquiryForm.FieldLengths.LAST_NAME);
  const isEmailEmpty = !Boolean(form.email.length);
  const isEmailValid = Utils.isEmailFormatValid(form.email);
  const isCountryEmpty = !Boolean(form.countryCode.length);
  const isAreaOfInterestEmpty = !Boolean(form.areaOfInterest.length);
  const isNumberOfMembersEmpty = !Boolean(form.numberOfMembers.length);
  const isNumberOfMembersValid = Utils.isShorterThan(form.numberOfMembers, InquiryForm.FieldLengths.NUMBER_OF_MEMBERS);
  const isMarketSegmentEmpty = !Boolean(form.marketSegment.length);
  const isCompanyEmpty = !Boolean(form.company.length);
  const isCompanyValid = Utils.isShorterThan(form.company, InquiryForm.FieldLengths.COMPANY);

  React.useEffect(() => {
    const formValid =
      !isEmailEmpty &&
      isEmailValid &&
      !isFirstNameEmpty &&
      isFirstNameValid &&
      !isLastNameEmpty &&
      isLastNameValid &&
      !isCountryEmpty &&
      !isAreaOfInterestEmpty &&
      (isNumberOfMembersEmpty || isNumberOfMembersValid) &&
      !isMarketSegmentEmpty &&
      !isCompanyEmpty &&
      isCompanyValid;
    setFormValid(formValid);
  }, [
    form,
    isEmailValid,
    isEmailEmpty,
    isFirstNameEmpty,
    isFirstNameValid,
    isLastNameEmpty,
    isLastNameValid,
    isCountryEmpty,
    isAreaOfInterestEmpty,
    isNumberOfMembersValid,
    isNumberOfMembersEmpty,
    isMarketSegmentEmpty,
    isCompanyEmpty,
    isCompanyValid,
    setFormValid,
  ]);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, firstName: event.target.value });
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, lastName: event.target.value });
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, email: event.target.value });
  };
  const handleCountryDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedCountry: string = data.value as string;
      setForm({ ...form, countryCode: selectedCountry });
    },
    [setForm, form]
  );
  const handleAreaOfInterestDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedAreaOfInterest: string = data.value as string;
      setForm({ ...form, areaOfInterest: selectedAreaOfInterest });
    },
    [setForm, form]
  );
  const handleMarketSegmentDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedMarketSegment: string = data.value as string;
      setForm({ ...form, marketSegment: selectedMarketSegment });
    },
    [setForm, form]
  );
  const handleNumberOfMembersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, numberOfMembers: event.target.value });
  };
  const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, company: event.target.value });
  };
  const handleMarketingOptInChange = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      setForm({ ...form, marketingOptIn: Boolean(data.checked) });
    },
    [setForm, form]
  );

  const fieldRequired = 'Required';
  const fieldMaxLength = (length: number) => `Sorry, that's too long. Must be fewer than ${length} characters.`;
  return (
    <>
      <InputWithValidation
        labelName={'First Name'}
        name={InquiryForm.FieldIds.FIRST_NAME}
        type="text"
        value={form.firstName}
        testId={InquiryForm.FieldIds.FIRST_NAME}
        labelId={InquiryForm.FieldIds.FIRST_NAME}
        onChange={handleFirstNameChange}
        onClick={onFormStart}
        isCorrect={isFirstNameValid || isFirstNameEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.FIRST_NAME)}`}
      />
      <InputWithValidation
        labelName={'Last Name'}
        name={InquiryForm.FieldIds.LAST_NAME}
        type="text"
        value={form.lastName}
        testId={InquiryForm.FieldIds.LAST_NAME}
        labelId={InquiryForm.FieldIds.LAST_NAME}
        onChange={handleLastNameChange}
        isCorrect={isLastNameValid || isLastNameEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.LAST_NAME)}`}
      />
      <InputWithValidation
        labelName={'Business Email Address'}
        name={InquiryForm.FieldIds.EMAIL}
        type="email"
        value={form.email}
        testId={InquiryForm.FieldIds.EMAIL}
        labelId={InquiryForm.FieldIds.EMAIL}
        onChange={handleEmailChange}
        isCorrect={isEmailValid || isEmailEmpty}
        infoMessage={fieldRequired}
        errorMessage={'Please enter a valid email address.'}
        isCorrectIconShown
      />
      <DropDownContainer>
        <Label>Country/Region</Label>
        <StyledDropdown
          testId={InquiryForm.FieldIds.COUNTRY_CODE}
          onChange={handleCountryDropdownChange}
          options={InquiryFormUtils.CountriesList}
          value={form.countryCode}
          placeholder="Select country/region"
          search={true}
        />
      </DropDownContainer>
      <DropDownContainer>
        <Label>Area of Interest</Label>
        <StyledDropdown
          testId={InquiryForm.FieldIds.AREA_OF_INTEREST}
          onChange={handleAreaOfInterestDropdownChange}
          options={areaOfInterestOptions}
          value={form.areaOfInterest}
          placeholder="Select option"
        />
      </DropDownContainer>
      <InputWithValidation
        labelName={'Size of Audience'}
        name={InquiryForm.FieldIds.NUMBER_OF_MEMBERS}
        type="text"
        value={form.numberOfMembers}
        testId={InquiryForm.FieldIds.NUMBER_OF_MEMBERS}
        labelId={InquiryForm.FieldIds.NUMBER_OF_MEMBERS}
        onChange={handleNumberOfMembersChange}
        isCorrect={isNumberOfMembersValid || isNumberOfMembersEmpty}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.NUMBER_OF_MEMBERS)}`}
      />
      <DropDownContainer>
        <Label>Market Segment Served</Label>
        <StyledDropdown
          testId={InquiryForm.FieldIds.MARKET_SEGMENT}
          onChange={handleMarketSegmentDropdownChange}
          options={marketSegmentOptions}
          value={form.marketSegment}
          placeholder="Select option"
        />
      </DropDownContainer>
      <InputWithValidation
        labelName={'Name of Organization'}
        name={InquiryForm.FieldIds.COMPANY}
        type="text"
        value={form.company}
        testId={InquiryForm.FieldIds.COMPANY}
        labelId={InquiryForm.FieldIds.COMPANY}
        onChange={handleCompanyChange}
        isCorrect={isCompanyValid || isCompanyEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.COMPANY)}`}
      />
      <StyledCheckbox
        width="18"
        height="18"
        type="checkbox"
        testId={InquiryForm.FieldIds.MARKETING_OPT_IN}
        label={'Sign me up for group-learning marketing emails'}
        checked={form.marketingOptIn}
        onChange={handleMarketingOptInChange}
      />
    </>
  );
};

const DropDownContainer = styled.div`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(26)};
  }
`;

const Label = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      width: 100%;
      height: ${props.theme.pxToRem(48)};
      padding-right: ${props.theme.pxToRem(10)};
      background-color: ${props.theme.colors.neutralWhite};

      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }

      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.primaryPurple};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }

      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.primaryPurple};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }

      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.interfaceBlue};
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
    }
  `}
`;
