import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, ButtonEnums, Checkbox, Container, Heading, TablePlaceholder, ClapSpinner } from 'components/atoms';
import { Table } from 'components/atoms/Table/Table';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { ProfileHeading } from 'components/molecules';
import { HeaderSteps } from 'components/molecules/HeaderSteps/HeaderSteps';
import { HeaderAdmin } from 'components/App/AdminApp';
import { NoSearchResults } from 'components/admin/organisms/NoSearchResults';
import { RouteComponentProps } from 'react-router-dom';
import { generatePath, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { adminSearchInvoiceScreens, Routes, AdminTableType } from 'constants/index';
import { SearchInvoicesTableRow } from 'components/admin/molecules/SearchInvoicesTableRow/SearchInvoicesTableRow';
import { AdminInvoiceSearchBackButton } from 'components/admin/atoms/AdminInvoiceSearchBackButton/AdminInvoiceSearchBackButton';
import {
  searchInvoicesListSelector,
  downloadingErrorSelector,
  downloadingIdSelector,
  isAllInvoicesSelectedSelector,
  isMultipleInvoicesSelectedSelector,
  isPayMultipleInvoicesButtonEnabledSelector,
  paginatedInvoicesSelector,
  isFirmBillingSelector,
  paginationSelectorFactory,
  isSearchInvoicesLoadingSelector,
} from 'modules/admin/selectors';
import { getPath } from 'utils';
import {
  getAdminInvoiceFile,
  getMultipleAdminInvoiceFiles,
  setSingleInvoiceSelected,
  toggleSelectAllInvoices,
  resetInvoiceItems,
  setPaginationAction,
  searchInvoices,
} from 'modules/admin/actions';
import { push } from 'connected-react-router';
import { ReactComponent as Payment } from 'resources/images/ic-payment.svg';
import { ReactComponent as GetApp } from 'resources/images/icon-dev-ic-get-app.svg';
import { theme } from 'theme';
import { Pagination } from 'components/organisms/CombinedFiltersPaginationView/Pagination';

export const AdminInvoicesSearchResultsPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { searchTerm, legalEntity } = useParams();
  const dispatch = useDispatch();
  const invoicesList = useSelector(searchInvoicesListSelector);
  const downloadId = useSelector(downloadingIdSelector);
  const downloadError = useSelector(downloadingErrorSelector);
  const isLoading = useSelector(isSearchInvoicesLoadingSelector);
  const isAllInvoicesSelected = useSelector(isAllInvoicesSelectedSelector);
  const isMultipleInvoicesSelected = useSelector(isMultipleInvoicesSelectedSelector);
  const isPayMultipleInvoicesButtonEnabled = useSelector(isPayMultipleInvoicesButtonEnabledSelector);
  const type = AdminTableType.INVOICES;
  const paginatedInvoices = useSelector(paginatedInvoicesSelector);
  const { limit, offset, totalCount }: State.Pagination = useSelector(paginationSelectorFactory(type));
  const isFirmBilling = useSelector(isFirmBillingSelector);

  const handleDownloadFileClick = React.useCallback(
    (invoice: State.SearchInvoicesResult) => {
      dispatch(getAdminInvoiceFile(invoice));
    },
    [dispatch]
  );

  const handlePayClick = React.useCallback(
    (invoiceId: string) => {
      dispatch(setSingleInvoiceSelected(invoiceId));
      dispatch(push(generatePath(getPath(Routes.ADMIN_INVOICES_PAYMENT))));
    },
    [dispatch]
  );

  const previousPage = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showNoResultsError = !Boolean(invoicesList?.length);

  const handlePayMultipleInvoicesClick = React.useCallback(() => {
    dispatch(push(generatePath(getPath(Routes.ADMIN_INVOICES_PAYMENT))));
  }, [dispatch]);

  const handleDownloadMultipleFileClick = React.useCallback(() => {
    dispatch(getMultipleAdminInvoiceFiles());
  }, [dispatch]);

  const handleSelectAllInvoices = React.useCallback(() => {
    dispatch(toggleSelectAllInvoices(isMultipleInvoicesSelected, isAllInvoicesSelected));
  }, [dispatch, isMultipleInvoicesSelected, isAllInvoicesSelected]);

  const handleInvoiceDetailsClick = React.useCallback(
    (invoiceNumber: string) => {
      if (isFirmBilling) {
        dispatch(resetInvoiceItems());
        dispatch(
          push(generatePath(getPath(Routes.ADMIN_INVOICES_DETAILS), { searchTerm, invoiceNumber, legalEntity }))
        );
      }
    },
    [dispatch, isFirmBilling, searchTerm, legalEntity]
  );

  const handlePageChange = React.useCallback(
    currentPage => {
      dispatch(setPaginationAction({ type, modifier: { offset: limit * (currentPage - 1) } }));
    },
    [type, limit, dispatch]
  );

  const onsuccessCallBack = () => {
    dispatch(
      searchInvoices(
        !searchTerm?.includes('INV') ? searchTerm : '',
        legalEntity,
        searchTerm?.includes('INV') ? searchTerm : ''
      )
    );
  };

  const isSearchingInvoice = searchTerm?.includes('INV');

  useEffect(() => {
    if (showNoResultsError && isSearchingInvoice && searchTerm) {
      dispatch(searchInvoices('', legalEntity, searchTerm));
    }
  }, [isSearchingInvoice, showNoResultsError, searchTerm, legalEntity, dispatch]);

  return (
    <>
      {showNoResultsError && !isSearchingInvoice ? (
        <>
          <HeaderAdmin />
          <NoSearchResults
            backLinkText="Back to search invoice"
            lookUpQuery={searchTerm}
            tipsList={[
              'Check the spelling of your invoice number or AICPA/CIMA UID',
              'Check if you have selected the correct legal entity',
              'Try searching using another invoice number or AICPA/CIMA UID',
            ]}
            previousPage={previousPage}
          />
        </>
      ) : (
        <>
          <HeaderSteps stepIndex={1} labels={adminSearchInvoiceScreens} />
          <StyledContainer>
            {isFirmBilling ? (
              <AdminInvoiceSearchBackButton text="Back to search invoice" to={getPath(Routes.ADMIN_INVOICES_SEARCH)} />
            ) : (
              <PreviousPage backText="Back to search invoice" />
            )}
            <StyledProfileHeading title="Search Results" />
            <StyledHeading as="h4">
              Showing {invoicesList?.length} invoices for <SearchTermText>"{searchTerm}"</SearchTermText>
            </StyledHeading>
            <StyledControlsContainer>
              <StyledIconContainer>
                <StyledHeaderPayButton
                  testId={'pay-multiple-invoice'}
                  variant={ButtonEnums.variants.link}
                  size={ButtonEnums.sizes.medium}
                  icon={<Payment />}
                  iconPosition={ButtonEnums.iconPosition.left}
                  onClick={handlePayMultipleInvoicesClick}
                  disabled={!isPayMultipleInvoicesButtonEnabled}
                >
                  Pay
                </StyledHeaderPayButton>
                <VerticalDivider />
                {downloadId && !downloadError && isMultipleInvoicesSelected ? (
                  <StyledSpinner>
                    <ClapSpinner
                      size={24}
                      frontColor={theme.colors.primaryPurple}
                      backColor={theme.colors.primaryPurple}
                    />
                  </StyledSpinner>
                ) : (
                  <StyledHeaderDownloadButton
                    testId={'download-multiple-invoice'}
                    variant={ButtonEnums.variants.link}
                    size={ButtonEnums.sizes.medium}
                    icon={<GetApp />}
                    iconPosition={ButtonEnums.iconPosition.left}
                    onClick={handleDownloadMultipleFileClick}
                    disabled={!isMultipleInvoicesSelected}
                  >
                    Download
                  </StyledHeaderDownloadButton>
                )}
              </StyledIconContainer>
              {totalCount > limit && (
                <Pagination
                  testId={'pagination'}
                  total={totalCount}
                  page={offset / limit + 1}
                  perPage={limit}
                  onPageChange={handlePageChange}
                />
              )}
            </StyledControlsContainer>
            {isLoading ? (
              <TablePlaceholder />
            ) : (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>
                      <CheckboxStyled
                        width="18"
                        height="18"
                        type="checkbox"
                        testId={`select-all-invoices-checkbox`}
                        onChange={handleSelectAllInvoices}
                        indeterminate={isMultipleInvoicesSelected && !isAllInvoicesSelected}
                        checked={isAllInvoicesSelected}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>Date</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Invoice #</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Total Price</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Due Date</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={2}>&nbsp;</Table.HeaderCell>
                    {isFirmBilling && <Table.HeaderCell width={2}>&nbsp;</Table.HeaderCell>}
                    <Table.HeaderCell width={2}>&nbsp;</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {paginatedInvoices?.map((invoice: State.SearchInvoicesResult) => (
                    <SearchInvoicesTableRow
                      key={invoice.id}
                      invoice={invoice}
                      downloadId={downloadId}
                      downloadError={downloadError}
                      isDownloadButtonDisabled={isMultipleInvoicesSelected || false}
                      downloadFileClick={handleDownloadFileClick}
                      payInvoiceClick={handlePayClick}
                      isFirmBilling={isFirmBilling}
                      invoiceDetailsClick={handleInvoiceDetailsClick}
                      legalEntity={legalEntity}
                      onSuccessCancel={onsuccessCallBack}
                    />
                  ))}
                </Table.Body>
              </Table>
            )}
          </StyledContainer>
        </>
      )}
    </>
  );
};

const StyledContainer = styled(Container)`
  padding-bottom: ${props => props.theme.pxToRem(30)};
`;
const StyledControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchTermText = styled.div`
  font-weight: ${props => props.theme.fontWeights.medium};
  display: inline-block;
`;

const StyledProfileHeading = styled(ProfileHeading)`
  margin-top: ${props => props.theme.pxToRem(-8)};
`;

const StyledHeading = styled(Heading)`
  font-weight: ${props => props.theme.fontWeights.light};
  margin-top: 0;
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    > label {
      font-size: ${props => props.theme.fontSizes.s};
      color: ${props => props.theme.colors.neutralGrey8};
      font-weight: ${props => props.theme.fontWeights.light};
    }

    &.ui.checkbox {
      display: block;
    }

    &.ui.checkbox input:indeterminate ~ label:after {
      opacity: 1;
      color: ${props => props.theme.colors.neutralWhite};
      background-color: ${props => props.theme.colors.primaryPurple};
      border: ${props => props.theme.pxToRem(-1)} solid ${props => props.theme.colors.primaryPurple};
      border-radius: ${props => props.theme.pxToRem(2)};
      width: ${({ width }) => `${width}px`};
      height: ${({ height }) => `${height}px`};
    }
  }
`;

const StyledSpinner = styled.div`
  display: flex;
  margin: auto 0;
`;

const StyledIconContainer = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const VerticalDivider = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey3};
  width: ${props => props.theme.pxToRem(1)};
  flex-shrink: 0;
  margin: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(28)};
`;

const StyledHeaderDownloadButton = styled(Button)`
  display: flex;
  margin-left: auto;
  svg path {
    fill: ${props => props.theme.colors.primaryPurple};
  }

  ${props =>
    props.disabled &&
    `
  svg path {
    fill: ${props.theme.colors.neutralGrey4};
  }
  `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: initial;
  }
`;

const StyledHeaderPayButton = styled(Button)`
  display: flex;
  margin-left: auto;
  ${props =>
    props.disabled &&
    `
  svg path {
    fill: ${props.theme.colors.neutralGrey4};
  }
  `}
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: initial;
  }
`;
