import styled from 'styled-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { isMembershipsLoadedSelector, isMembershipsLoadingSelector } from 'modules/products/selectors';
import { getMemberships } from 'modules/products/actions';
import { PageFirmRoster } from 'components/pages/PageFirmRoster/PageFirmRoster';
import { PageFirmBilling } from 'components/pages/PageFirmBilling/PageFirmBilling';
import { PageFirmInfo } from 'components/pages/PageFirmInfo/PageFirmInfo';
import { FirmHeaderContainer } from 'containers/FirmHeaderContainer';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { HelpSection } from 'components/organisms/HelpSection/HelpSection';
import { Footer } from 'components/molecules/Footer/Footer';
import { Container, Grid, Divider } from 'components/atoms';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { PageFirmNavigationContainer } from 'containers/PageFirmNavigationContainer';
import { PageFirmSelectionContainer } from 'containers/PageFirmSelectionContainer';

export const PageFirm: React.FC = () => {
  const dispatch = useDispatch();

  const targetContainer = React.useRef<HTMLDivElement | null>(null);
  const containerContentRef = React.useRef<HTMLDivElement | null>(null);

  const membershipsLoaded = useSelector(isMembershipsLoadedSelector);
  const membershipsLoading = useSelector(isMembershipsLoadingSelector);

  React.useEffect(() => {
    if (membershipsLoaded || membershipsLoading) return;
    dispatch(getMemberships());
  }, [membershipsLoaded, membershipsLoading, dispatch]);

  return (
    <>
      <HeaderPageWrapper contentRef={targetContainer} contentInnerRef={containerContentRef}>
        <FirmHeaderContainer />
        <StyledContainer>
          <Grid stackable>
            <Grid.Row stretched>
              <Grid.Column tablet={16} computer={16}>
                <StyledContentContainer>
                  <Switch>
                    <Route path={getPath(Routes.FIRM_INFO)} component={PageFirmInfo} />
                    <Route path={getPath(Routes.FIRM_ROSTER)} component={PageFirmRoster} />
                    <Route path={getPath(Routes.FIRM_BILLING)} component={PageFirmBilling} />
                    <Route
                      exact
                      path={getPath(Routes.FIRM_ROOT)}
                      // tslint:disable-next-line: jsx-no-lambda
                      render={props =>
                        props.match.params.orgId ? (
                          <PageFirmNavigationContainer {...props} />
                        ) : (
                          <PageFirmSelectionContainer {...props} />
                        )
                      }
                    />
                  </Switch>
                  <StyledDivider />
                  <HelpSection />
                </StyledContentContainer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </StyledContainer>
        <Footer />
      </HeaderPageWrapper>
    </>
  );
};

const StyledContainer = styled(Container)`
  &.ui.container {
    flex: 1 0 auto;
    display: flex;

    > .grid {
      flex: 1 1 auto;
    }
  }
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${props => props.theme.pxToRem(16)};
  margin-top: ${props => props.theme.pxToRem(32)};
  margin-bottom: ${props => props.theme.pxToRem(80)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(24)};
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-bottom: ${props => props.theme.pxToRem(30)};
    border-top-color: transparent;
    border-bottom-color: ${props => props.theme.colors.neutralGrey3};
    color: ${props => props.theme.colors.neutralGrey3};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(25)};
    }
  }
`;
