import { Checkout, Product, User as UserTypes } from 'mxp-schemas';
import { UserMemberTypes } from 'modules/user/constants';
import { User as UserUtils, Utils } from 'mxp-utils';
import { fromCentsFormat } from 'utils';
import { getFormattedBundlePrice } from 'modules/products/helpers';

export const extractContentCardItemPrices = (
  prices: State.Price[] | null,
  membershipRole: string,
  roles: string[],
  isBulkOrder?: boolean
): Product.ProductCardPrice => {
  // If CT Item has GBP and USD Prices, we default to USD, but if it only has GBP, we use the GBP prices
  const allPrices = isBulkOrder
    ? defaultToUSDPrices(prices)?.sort((a: any, b: any) => (a.amount < b.amount ? -1 : 1))
    : prices?.sort((a: any, b: any) => (a.amount < b.amount ? -1 : 1));

  const highestPrice = allPrices?.[allPrices.length - 1];
  const memberPrices = allPrices?.filter(
    (price: any) => price.channel.applicableUserRoles[0] === UserTypes.MembershipIdsEnum.MRUSR0001
  );

  // logged out
  if (membershipRole === UserMemberTypes.LOGGED_OUT) {
    const loggedOutPrices = allPrices;
    return isBulkOrder
      ? {
          startPrice: loggedOutPrices?.[0]?.amount,
          startCurrency: loggedOutPrices?.[0]?.currency,
          endPrice: loggedOutPrices?.[loggedOutPrices.length - 1]?.amount,
          endCurrency: loggedOutPrices?.[loggedOutPrices.length - 1]?.currency,
          membership: UserMemberTypes.LOGGED_OUT,
        }
      : {
          startPrice: loggedOutPrices?.[0]?.amount,
          endPrice: loggedOutPrices?.[loggedOutPrices.length - 1]?.amount,
          membership: UserMemberTypes.LOGGED_OUT,
        };
  }

  // non-member
  if (membershipRole === UserMemberTypes.NONMEMBER) {
    const nonMemberPrices = allPrices?.filter(
      (price: any) => price.channel.applicableUserRoles[0] === UserTypes.MembershipIdsEnum.NON_MEMBER
    );
    return isBulkOrder
      ? {
          startPrice: nonMemberPrices?.[0]?.amount,
          startCurrency: nonMemberPrices?.[0]?.currency,
          endPrice: memberPrices?.[0]?.amount,
          endCurrency: memberPrices?.[0]?.currency,
          membership: UserMemberTypes.NONMEMBER,
        }
      : {
          startPrice: nonMemberPrices?.[0]?.amount,
          endPrice: memberPrices?.[0]?.amount,
          membership: UserMemberTypes.NONMEMBER,
        };
  }

  // AICPA member
  if (membershipRole === UserMemberTypes.MEMBER && roles?.includes(UserTypes.MembershipIdsEnum.MRUSR0001)) {
    return isBulkOrder
      ? {
          startPrice: highestPrice?.amount,
          startCurrency: highestPrice?.currency,
          endPrice: memberPrices?.[0]?.amount,
          endCurrency: memberPrices?.[0]?.currency,
          membership: UserMemberTypes.MEMBER.replace('m', 'M'),
        }
      : {
          startPrice: highestPrice?.amount,
          endPrice: memberPrices?.[0]?.amount,
          membership: UserMemberTypes.MEMBER.replace('m', 'M'),
        };
  }

  // premium prices
  const premiumPrices = allPrices?.filter(
    (price: any) => price.channel.applicableUserRoles[0] !== UserTypes.MembershipIdsEnum.NON_MEMBER
  );
  // user applicable premium prices
  const userPremiumPrices = premiumPrices?.filter((priceInfo: any) => {
    return priceInfo.channel.applicableUserRoles.filter((userRole: string) => roles.includes(userRole)).length;
  });

  return isBulkOrder
    ? {
        startPrice: highestPrice?.amount,
        startCurrency: highestPrice?.currency,
        endPrice: userPremiumPrices?.[0]?.amount,
        endCurrency: userPremiumPrices?.[0]?.currency,
        membership:
          (userPremiumPrices?.[0]?.channel?.applicableUserRoles?.[0] &&
            UserUtils.membershipMapNames[userPremiumPrices[0].channel.applicableUserRoles[0]]) ||
          '',
      }
    : {
        startPrice: highestPrice?.amount,
        endPrice: userPremiumPrices?.[0]?.amount,
        membership:
          (userPremiumPrices?.[0]?.channel?.applicableUserRoles?.[0] &&
            UserUtils.membershipMapNames[userPremiumPrices[0].channel.applicableUserRoles[0]]) ||
          '',
      };
};

export const checkIfCurrencyPriceInPrices = (
  prices: State.Price[] | null,
  currency: Product.ProductCurrencyLabel,
  tier?: Checkout.Tiers
) => {
  if (prices && !!prices.length && tier) {
    const ifTierInPriceTiers = Utils.checkIfTierInPriceTiers(prices, currency, tier);

    return prices?.some(price => {
      if (!!price.custom?.fields?.country_grouping?.length) {
        // check if already setup tiers
        if (price.currency === currency) {
          // if currency equals to price currency
          if (ifTierInPriceTiers) {
            // check if tier is include in country grouping
            return price.custom?.fields?.country_grouping?.includes(tier);
          }
          return true;
        }
        return false;
      }
      return price.currency === currency; // not yet setup tiers, return all price with currency
    });
  }

  return false;
};

export const FREE_LABEL = 'FREE';

export const zeroToLabel = (amount: number): string => {
  return amount === 0 ? FREE_LABEL : `${amount}`;
};

export const productDiscountLabel = (label: string) => {
  return `(${label.replace(/AICPA|Discount/g, '').trim()} price)`;
};

/**
 * getMemberPriceRangeForNonmembers
 * @param variantsPriceInfoForUser
 * Used to get price range for members or applicable role
 *
 */
export const getPriceRangeForNonmembers = (
  variantsPriceInfoForUser: Product.UserApplicableVariantsPricingType,
  rangeForMembers: boolean
): Product.PriceForRole[] => {
  return Object.keys(variantsPriceInfoForUser)
    .reduce((allPrices: Product.PriceForRole[], key: string) => {
      const variantPrices: Product.PriceForRole[] = variantsPriceInfoForUser[key];
      const price = variantPrices.find(variantPrice =>
        rangeForMembers
          ? variantPrice.applyedDiscountRole === UserTypes.MembershipIdsEnum.MRUSR0001
          : variantPrice.isApplicable
      );
      if (price) {
        allPrices.push(price);
      }
      return allPrices;
    }, [])
    .sort((a: Product.PriceForRole, b: Product.PriceForRole) => a.price.amount - b.price.amount);
};

export const getFormattedBundleCardPrices = (
  bundleCardPrices: Product.BundleMembershipPrices,
  bundleDiscountPercent: number,
  currency: string
): State.FormattedBundleCardPrices => {
  const bundlePrices = getFormattedBundlePrice(bundleCardPrices, currency);
  const bundlePricesWithoutDiscount: Product.Price[] = Object.values(bundlePrices)
    .map((value: Product.BundlePrices) => Object.values(value))
    .reduce((acc: Product.Price[], value: Product.Price[]) => acc.concat(value), []);

  const bundlePricesWithDiscount: Product.Price[] = bundlePricesWithoutDiscount.map((bundlePrice: Product.Price) =>
    getBundlePriceWithDiscountApplied(bundlePrice, bundleDiscountPercent)
  );

  const sortedBundlePricesWithDiscount: Product.Price[] = [...bundlePricesWithDiscount].sort(
    (a: Product.Price, b: Product.Price) => a.amount - b.amount
  );

  const standardNonMemberBundlePrice: Product.Price = {
    amount: fromCentsFormat(bundlePrices?.nonMember?.max?.amount || 0),
    currency,
  };

  return {
    standardNonMemberPrice: standardNonMemberBundlePrice,
    minBundlePrice: sortedBundlePricesWithDiscount[0],
    maxBundlePrice: sortedBundlePricesWithDiscount[sortedBundlePricesWithDiscount.length - 1],
  };
};

const getBundlePriceWithDiscountApplied = (
  bundlePrice: Product.Price,
  bundleDiscountPercent?: number
): Product.Price => {
  const fractionAfterDiscount = (100 - (bundleDiscountPercent || 0)) / 100;
  return { amount: fromCentsFormat(bundlePrice.amount * fractionAfterDiscount), currency: bundlePrice.currency };
};

export const sumInvoicesAmount = (invoices: State.Invoice[] | null | undefined): number => {
  return invoices?.length
    ? invoices.reduce((acc: number, invoice: State.Invoice) => {
        return acc + invoice.amount;
      }, 0)
    : 0;
};

export const sumInvoicesBalance = (invoices: State.Invoice[] | null | undefined): number => {
  return invoices?.length
    ? invoices.reduce((acc: number, invoice: State.Invoice) => {
        return acc + invoice.balance;
      }, 0)
    : 0;
};

const defaultToUSDPrices = (prices: State.Price[] | null) => {
  // Check if there are GBP and USD prices
  const hasGBP = prices?.some(price => price.currency === 'GBP');
  const hasUSD = prices?.some(price => price.currency === 'USD');

  // If USD prices are present, filter out any other currencies
  if (hasUSD) {
    return prices?.filter(price => price.currency === 'USD');
  }
  // If there are GBP prices, we use those
  if (hasGBP && !hasUSD) {
    return prices?.filter(price => price.currency === 'GBP');
  }
  // If there are no GBP prices or no USD prices, return the original array (which may contain EUR prices)
  return prices;
};
