import React from 'react';
import { Modal as SemanticUIModal, ModalProps as SemanticUIModalProps } from 'semantic-ui-react';
import { GlobalModalDimmerStyle } from 'components/atoms/GlobalModalDimmerStyle/GlobalModalDimmerStyle';
import { Heading } from 'components/atoms/Heading/Heading';
import styled, { css } from 'styled-components';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { Loader } from 'components/atoms/Loader/Loader';

export enum ModalSizes {
  MINI = 'mini',
  TINY = 'tiny',
  SMALL = 'small',
  LARGE = 'large',
  FULLSCREEN = 'fullscreen',
}

interface ModalProps extends SemanticUIModalProps {
  testId: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  heading?: string;
  cancelNode?: React.ReactNode;
  confirmNode?: React.ReactNode;
  footerNode?: React.ReactNode;
  isNarrow?: boolean;
  oneOption?: boolean;
  loading?: boolean;
  onClose?: () => void;
  isFullScreenOnMobile?: boolean;
  showCloseCross?: boolean;
  isCloseButtonPullRight?: boolean;
  isCloseButtonPrimary?: boolean;
  headingCenterBoldText?: string;
  headingCenterAfterBoldText?: string;
  blur?: boolean;
  contentBackground?: string;
  contentFullWidth?: boolean;
  background?: string;
  isOfflineExemptionCalculator?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  icon,
  heading,
  cancelNode,
  confirmNode,
  footerNode,
  testId,
  onClose,
  oneOption = false,
  loading = false,
  isFullScreenOnMobile = false,
  showCloseCross,
  isCloseButtonPullRight = false,
  isCloseButtonPrimary = false,
  headingCenterBoldText,
  headingCenterAfterBoldText,
  blur,
  contentBackground,
  background,
  contentFullWidth,
  isOfflineExemptionCalculatorModal = false,
  ...props
}) => {
  const hasIcon: boolean = Boolean(icon);
  return (
    <>
      <GlobalModalDimmerStyle blur={props.open && (blur || isOfflineExemptionCalculatorModal)} />
      <StyledModal
        data-testid={`modal-${testId}`}
        data-one-option={oneOption}
        data-full-screen={isFullScreenOnMobile}
        contentBackground={contentBackground}
        background={background}
        contentFullWidth={contentFullWidth}
        {...props}
      >
        {loading ? (
          <Loader inline="centered" />
        ) : (
          <>
            {(oneOption || showCloseCross) && (
              <CloseButton
                pullRight={isCloseButtonPullRight}
                onClick={onClose}
                data-testid="modal-authentication-close"
              >
                <StyledCloseIcon data-is-primary={isCloseButtonPrimary} />
              </CloseButton>
            )}
            {heading && (
              <SemanticUIModal.Header>
                {hasIcon && <StyledIconContainer>{icon}</StyledIconContainer>}
                <ModalHeading hasIcon={hasIcon} as="h2">
                  {heading}
                  {Boolean(headingCenterBoldText) && (
                    <>
                      <StyledHeadingBoldText>&nbsp;{headingCenterBoldText}</StyledHeadingBoldText>&nbsp;
                      {Boolean(headingCenterAfterBoldText) && headingCenterAfterBoldText}
                    </>
                  )}
                </ModalHeading>
              </SemanticUIModal.Header>
            )}
            <StyledContent data-one-option={oneOption}>{children}</StyledContent>
            {(cancelNode || confirmNode || footerNode) && (
              <SemanticUIModal.Actions>
                {footerNode}
                {cancelNode}
                {confirmNode}
              </SemanticUIModal.Actions>
            )}
          </>
        )}
      </StyledModal>
    </>
  );
};

interface StyledCloseButton {
  pullRight: boolean;
}

const CloseButton = styled.button<StyledCloseButton>`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
  margin-left: auto;
  margin-right: 0;
  display: block;

  ${props =>
    props.pullRight &&
    css`
      & {
        position: absolute;
        right: 0;
      }
    `}
`;

const StyledCloseIcon = styled(IconClose)<{ 'data-is-primary': boolean }>`
  fill: ${props => props.theme.colors.neutralGrey4};
  ${props => props.theme.mediaQueries.mobileOnly} {
    fill: ${props => props.theme.colors.primaryPurple};
  }
  ${props =>
    props['data-is-primary'] &&
    css`
      & {
        fill: ${({ theme }) => theme.colors.primaryPurple};
      }
    `}
`;

const StyledContent = styled(SemanticUIModal.Content)<{ 'data-one-option': boolean }>`
  ${props =>
    props['data-one-option'] &&
    `
      &&&&& {
        max-width: ${props.theme.pxToRem(400)};
        margin: 0 auto;
      }
    `}
`;

const StyledModal = styled(SemanticUIModal)<{
  'data-one-option': boolean;
  'data-full-screen': boolean;
  contentBackground?: string;
  background?: string;
  contentFullWidth?: boolean;
}>`
  &&&&&& {
    font-family: ${props => props.theme.fontFamily};
    background-color: ${props => (props.background ? props.background : props.theme.colors.neutralWhite)};
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
    padding: 2rem 1.25rem 1.25rem;
    ${props =>
      props['data-one-option'] &&
      `
      padding-top: ${props.theme.pxToRem(20)}
      max-width: ${props.theme.pxToRem(590)}
    `};

    > .header {
      text-align: center;
      border-bottom: 0;
      border-radius: 2px;
      padding: 0 !important;
    }
    > .content {
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.light};
      line-height: 1.57;
      padding: 0.5rem 0 2.625rem;
      ${props =>
        props.contentBackground &&
        `
          background: ${props.contentBackground}
        `};
      ${props =>
        props.contentFullWidth &&
        `
          max-width: 100%;
          padding: 0 10px;
          `};
    }
    > .actions {
      border-radius: 2px;
      text-align: center;
      padding: 1.25rem 0 0 !important;
      display: flex;
      justify-content: center;
      background: transparent;

      > button,
      a {
        width: ${props => props.theme.pxToRem(170)};
        margin: 0;

        ${props => props.theme.mediaQueries.mobileOnly} {
          width: 50%;
        }

        &:first-child {
          margin-right: ${props => props.theme.pxToRem(10)};
        }
      }
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      ${props =>
        props['data-full-screen'] &&
        `
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          max-width: initial;
          margin: 0;
          border-radius: 0
        `}
    }
  }
`;

const StyledIconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border: solid 1px ${props => props.theme.colors.neutralGrey2};
  border-radius: 50%;
  background-color: ${props => props.theme.colors.neutralGrey1};

  > svg {
    width: 40px;
    height: 40px;
  }
`;

const ModalHeading = styled(Heading)<{ hasIcon: boolean }>`
  color: ${props => props.theme.colors.neutralGrey8};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  ${props => (props.hasIcon ? 'margin-top: 12px' : '')}
`;

const StyledHeadingBoldText = styled.span`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.bold};
`;
