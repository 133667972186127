import React from 'react';
import styled from 'styled-components/macro';
import { Contentful } from 'mxp-schemas';
import { Heading, ButtonEnums, ButtonVariants, ButtonLink } from 'components/atoms';
import { isColorDark } from 'utils/colorHelpers';
import { SubBlockPromoCard } from './SubBlockPromoCard';
import { Grid } from 'semantic-ui-react';
import { PromoCards } from '../../../constants/image-dimensions';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}
export const BlockPromoCard: React.FC<Props> = ({ modularBlock, headingType }) => {
  const block = modularBlock as Contentful.ModularContent.PromoCardBlock;
  const isBgColorDark: boolean = isColorDark(block.bgColor);

  const renderPromoButton = (): React.ReactNode => {
    if (!block.ctaUrl || !block.ctaText) return;
    return (
      <StyledButtonLink
        size={ButtonEnums.sizes.medium}
        variant={isBgColorDark ? ButtonEnums.variants.secondaryNegative : ButtonVariants.secondary}
        bordered
        to={block.ctaUrl}
        testId="product-promo-block"
      >
        {block.ctaText}
      </StyledButtonLink>
    );
  };

  const renderPromoHeaderBody = (): React.ReactNode => {
    return (
      <>
        <StyledHeaderContainer isBgColorDark={isBgColorDark}>
          {block.header && (
            <StyledHeading
              as={headingType}
              dangerouslySetInnerHTML={{ __html: block.header }}
              tabIndex={0}
              isBgColorDark={isBgColorDark}
            />
          )}
          {block.description && (
            <StyledParagraph
              dangerouslySetInnerHTML={{ __html: block.description }}
              tabIndex={0}
              isBgColorDark={isBgColorDark}
            />
          )}
        </StyledHeaderContainer>

        <Grid data-testid="content-card-items" stackable>
          <Grid.Row columns="equal" centered>
            {block?.landingPageSubBlocks?.map((item: Contentful.LandingPages.LandingPageSubBlock) => (
              <StyledColumnCard key={item.id}>
                <SubBlockPromoCard
                  testId={`sbpc-${item.id}`}
                  header={item.header}
                  description={item.description}
                  imageUrl={`${item.image}&fit=fill&w=${PromoCards.WIDTH}&q=${PromoCards.QUALITY}`}
                  to={item.ctaUrl}
                />
              </StyledColumnCard>
            ))}
          </Grid.Row>
        </Grid>

        <StyledButtonWrapper>{renderPromoButton()}</StyledButtonWrapper>
      </>
    );
  };

  return (
    <>
      <StyledContainer>{renderPromoHeaderBody()}</StyledContainer>
    </>
  );
};

const StyledColumnCard = styled(Grid.Column)`
  @media (min-width: 360px) and (max-width: 767px) {
    &&& {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`;

const StyledContainer = styled.div`
  margin: 0 auto;

  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow: hidden;
    position: relative;
  }
`;

const StyledHeaderContainer = styled.div<{ isBgColorDark?: boolean }>`
  margin: 0 auto;
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: ${props => props.theme.pxToRem(32)};
  justify-content: center;
  align-items: center;
`;

const StyledButtonLink = styled(ButtonLink)<{ isBgColorDark?: boolean }>`
  &&&&& {
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(20)};
    margin-bottom: ${props => props.theme.pxToRem(60)};

    &:hover,
    &:focus {
      &&&&& {
        background-color: ${props => props.theme.colors.neutralWhite};
        color: ${props => props.theme.colors.primaryLightPurple};
      }

      ${({ variant, bordered, theme }) =>
        variant === ButtonVariants.secondary &&
        ` 
    &&&&& {
      ${bordered && `border: ${theme.pxToRem(1)} solid ${theme.colors.primaryLightPurple}`}
    }
    `}
    }
  }
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-bottom: ${props => props.theme.pxToRem(48)} !important;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(20)} !important;
  }
`;

const StyledHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin: ${props => props.theme.pxToRem(160)} 0 ${props => props.theme.pxToRem(24)} 0 !important;
  }
`;
