import React from 'react';
import styled from 'styled-components';
import {
  Grid as SemanticGrid,
  GridProps as SemanticGridProps,
  GridRowProps as SemanticGridRowProps,
  GridColumnProps as SemanticGridColumnProps,
} from 'semantic-ui-react';

export const Grid = ({ children, stackable = true, ...props }: SemanticGridProps) => (
  <StyledSemanticGrid {...props} stackable={stackable}>
    {children}
  </StyledSemanticGrid>
);

Grid.Row = ({ children, ...props }: SemanticGridRowProps) => (
  <StyledSemanticGridRow {...props}>{children}</StyledSemanticGridRow>
);

Grid.Column = ({ children, ...props }: SemanticGridColumnProps) => (
  <StyledSemanticGridColumn {...props}>{children}</StyledSemanticGridColumn>
);

const StyledSemanticGrid = styled(SemanticGrid)`
  &&& {
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: -10px;
    margin-right: -10px;

    @media print {
      display: block;
    }
  }
`;

const StyledSemanticGridRow = styled(SemanticGrid.Row)`
  &&&& {
    padding-top: 10px;
    padding-bottom: 10px;

    @media print {
      display: block;
    }
  }
`;

const StyledSemanticGridColumn = styled(SemanticGrid.Column)`
  &&&&& {
    padding-left: 10px;
    padding-right: 10px;

    @media print {
      display: block;
    }
  }
`;
