import { createAction } from 'redux-actions';
import { default as request } from 'utils/GraphQLClient';
import { CREATE_MIP_CONTACT } from 'mxp-graphql-queries';

export const createMipContact: any = createAction(`mip/createMipContact`, (contactForm: any) => async () => {
  const practiceList = contactForm.practiceList.map((practice: any) => {
    practice.fax = Array.isArray(practice?.fax) ? practice?.fax?.join('-') : practice.fax;
    delete practice.phones;
    const newPractice = practice;
    return newPractice;
  });
  const payload = {
    ...contactForm,
    practiceList,
  };
  return request(CREATE_MIP_CONTACT, { contact: payload });
});
