import React from 'react';
import { Product, Orders } from 'mxp-schemas';
import { Link } from 'components/atoms';
import { StyledLink } from './ProductItem';
import { DetailRowWrap, DetailHead, DetailContent } from './ProductItemDetails';
import { getPath, MomentHelpers } from 'utils';
import { PhysicalProductsLineItemStatesNamesMap, Routes } from 'constants/index';

interface Props {
  cartLineItemState?: Orders.LineItemStates;
  isOrderConfirmationPage: boolean;
  isProfilePurchasesPage: boolean;
  isSubscription: boolean;
  productType: string;
  publicationYear?: string;
  quantity: number;
  shipmentDate?: string;
  sku?: string;
  standingOrderStatus?: Product.StandingOrderStatus | null;
  viewTextForAnalytics?: string;
}

export const PhysicalProductsDetails: React.FC<Props> = ({
  isProfilePurchasesPage,
  viewTextForAnalytics,
  isOrderConfirmationPage,
  cartLineItemState = Orders.LineItemStates.AWAITING_SHIPMENT,
  standingOrderStatus,
  shipmentDate,
  productType,
  sku,
  publicationYear,
  isSubscription,
}) => {
  const isPageProfilePurchasesOrOrderConfirmation = isProfilePurchasesPage || isOrderConfirmationPage;

  const renderStandingOrderRenewalText = (status: Product.StandingOrderStatus): React.ReactNode => {
    switch (status) {
      case Product.StandingOrderStatus.ACTIVE:
        return (
          <span>
            Thanks for creating a&nbsp;
            <StyledLink
              testId={`standing-order-link-${productType}-${sku}`}
              to={getPath(Routes.STANDING_ORDERS)}
              viewTextForAnalytics={viewTextForAnalytics}
            >
              standing order.
            </StyledLink>
            &nbsp;When the next edition is released, your default payment card will be charged. We’ll send a reminder
            email with the release date and price 30 days in advance.
            {isOrderConfirmationPage && (
              <>
                <br />
                Manage your renewals anytime in&nbsp;
                <StyledLink
                  testId={`profile-purchases-link-${productType}-${sku}`}
                  to={getPath(Routes.PROFILE_PURCHASES)}
                  viewTextForAnalytics={viewTextForAnalytics}
                >
                  your purchase history
                </StyledLink>
              </>
            )}
          </span>
        );
      case Product.StandingOrderStatus.CANCELLED:
        return (
          <>
            Standing order cancelled. <br />
            Miss the convenience of a standing order? It's an easy fix: Add the product to your cart, and opt into the
            standing order during checkout.
          </>
        );
      case Product.StandingOrderStatus.HISTORICAL:
        return (
          <>
            {publicationYear} edition <br />
          </>
        );

      default:
        return null;
    }
  };

  const renderShipmentStatusMessage = (lineItemStatus: Orders.LineItemStates): React.ReactNode => {
    switch (lineItemStatus) {
      case Orders.LineItemStates.AWAITING_SHIPMENT:
      case Orders.LineItemStates.INITIAL:
        return (
          isPageProfilePurchasesOrOrderConfirmation && (
            <div>
              You’ll receive a shipment confirmation email with tracking information when your product leaves the
              warehouse.
              <br />
              This product is eligible for return within 30 days of shipping date.&nbsp;
              {renderLinkReturnCancellationPolicy()}
            </div>
          )
        );
      case Orders.LineItemStates.SHIPPED:
        const isReturnWindowClosed =
          shipmentDate && MomentHelpers.getDiff(new Date().toISOString(), shipmentDate, 'days') > 30;
        return (
          <div>
            {isReturnWindowClosed
              ? `This product's return window has closed.`
              : 'This product is eligible for U.S. returns within 30 days of the shipping date. For U.S. returns, select the “Return item” button to the right of the item. For international refunds,'}
            &nbsp;
            <Link
              testId="product-item-details--contact-us"
              to={getPath(Routes.CONTACT_US)}
              viewTextForAnalytics={viewTextForAnalytics}
            >
              contact us
            </Link>
            .&nbsp;{renderLinkReturnCancellationPolicy()}
          </div>
        );
      default:
        return null;
    }
  };

  const renderLinkReturnCancellationPolicy = (): React.ReactNode => (
    <Link
      testId="product-item-details--return-cancellation-policy"
      to={getPath(Routes.REFUND_POLICY)}
      viewTextForAnalytics={viewTextForAnalytics}
    >
      Return and cancellation policy
    </Link>
  );

  return (
    <>
      {isPageProfilePurchasesOrOrderConfirmation && !isSubscription && (
        <>
          <DetailRowWrap>
            <DetailHead>Order status</DetailHead>
            <DetailContent>
              {PhysicalProductsLineItemStatesNamesMap[cartLineItemState]}
              <br />
              {renderShipmentStatusMessage(cartLineItemState)}
            </DetailContent>
          </DetailRowWrap>
          {standingOrderStatus && (
            <DetailRowWrap>
              <DetailHead>Renewal</DetailHead>
              <DetailContent>{renderStandingOrderRenewalText(standingOrderStatus)}</DetailContent>
            </DetailRowWrap>
          )}
        </>
      )}
    </>
  );
};
