import React from 'react';
import styled from 'styled-components/macro';
import { Link, ExternalSourceLabel, Icon, OnlyDesktop, OnlyMobile, LinkEnums } from 'components/atoms';
import { TRENDING_EVENT, handleEvent } from 'utils/Analytics';
import { getContentUrl } from 'utils';

interface IProps {
  items: State.ITrendingArticle[] | null;
  className?: string;
  isPageFeed?: boolean;
}

export const TrendingArticles: React.FC<IProps> = React.memo(props => {
  const { className, items, isPageFeed } = props;

  if (!items || !items.length) {
    return null;
  }

  return (
    <StyledWrapper data-testid={`top-trending`} className={className}>
      <StyledTitle>{isPageFeed ? 'Trending' : 'Trending Blog Posts'}</StyledTitle>
      <StyledList>
        {items.map(item => (
          <TrendingItem item={item} key={item.title} />
        ))}
      </StyledList>
    </StyledWrapper>
  );
});

interface TrendingItemProps {
  item: State.ITrendingArticle;
}

const TrendingItem: React.FC<TrendingItemProps> = React.memo(({ item }) => {
  const { title, contentSource, slug, externalUrl } = item;

  const externalSource = externalUrl && contentSource;
  const handleLinkClick = React.useCallback(() => {
    handleEvent({ text: `trending:${title}:${slug || ''}`, href: slug || externalUrl }, TRENDING_EVENT);
  }, [title, slug, externalUrl]);

  const renderLinkedBlock = () => {
    if (externalUrl) {
      return (
        <StyledLink
          type={LinkEnums.type.standaloneLink}
          isExternal
          testId={title}
          to={externalUrl}
          onClick={handleLinkClick}
        >
          {title}
        </StyledLink>
      );
    }
    return (
      <StyledLink to={getContentUrl(item as State.ContentCardItem)} onClick={handleLinkClick}>
        {title}
      </StyledLink>
    );
  };

  return (
    <>
      <li>
        {renderLinkedBlock()}
        <OnlyDesktop>
          <StyledArrow name={'angle right'} />
        </OnlyDesktop>
        <OnlyMobile>
          <StyledArrowMobile name={'caret right'} />
        </OnlyMobile>
      </li>
      {externalSource && <Source label={externalSource} />}
      <OptionDivider />
    </>
  );
});
const StyledLink = styled(Link)`
  width: 100%;
  padding-right: ${props => props.theme.pxToRem(16)};
  padding-top: ${props => props.theme.pxToRem(6)};
  padding-left: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.fontSizes.xs};
`;
const Source = styled(ExternalSourceLabel)`
  text-align: left;
  margin-left: ${props => props.theme.pxToRem(38)};
`;

const StyledList = styled.ol`
  list-style: none;
  counter-reset: article-counter;
  margin: 0;
  padding: 0;

  li {
    counter-increment: article-counter;
    display: flex;
  }
  li::before {
    content: counter(article-counter) '.';
    font-size: ${props => props.theme.fontSizes.xxl};
    font-weight: ${props => props.theme.fontWeights.light};
    color: ${props => props.theme.colors.neutralGrey4};
  }
`;

const StyledTitle = styled.h4`
  font-size: ${props => props.theme.fontSizes.l};
  line-height: 32px;
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: normal;
  margin: 0 0 12px;
  white-space: pre-line;
  ${props => props.theme.mediaQueries.mobileOnly} {
    white-space: normal;
  }
`;

const StyledArrow = styled(Icon)`
  margin-left: 10px;
  position: absolute;
  right: 0;
  padding-top: 16px;
  color: ${props => props.theme.colors.neutralGrey4};
`;

const StyledArrowMobile = styled(StyledArrow)`
  color: ${props => props.theme.colors.primaryPurple};
`;

const StyledWrapper = styled.div`
  margin: 0;
`;

const OptionDivider = styled.div`
  height: 1px;
  background-color: ${props => props.theme.colors.neutralGrey2};
  margin: 20px 0px 20px 38px;
`;
