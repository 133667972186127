import React from 'react';
import styled from 'styled-components';
import { FeedTargetableLinks } from 'components/molecules/FeedTargetableLinks/FeedTargetableLinks';
import { User as UserUtils } from 'mxp-utils';
import { User } from 'mxp-schemas';
import { useSelector } from 'react-redux';
import { sectionsHashSelector } from 'modules/startup/selectors';

interface Props {
  memberships: Common.IExternalLinks[];
  browseAllMembershipsLink?: string;
  title?: string;
  className?: string;
  setBurger?: (state: boolean) => void;
  browseAllLinkTitle: string;
}

export const FeedSectionMembership: React.FC<Props> = React.memo(props => {
  const sectionsHash = useSelector(sectionsHashSelector);

  const { memberships, browseAllMembershipsLink, title, setBurger, browseAllLinkTitle } = props;
  const finalLinks = memberships.reduce((result: Common.IExternalLinks[], link: Common.IExternalLinks) => {
    const forensicLink = sectionsHash[User.FVSSectionMembershipIdsEnum.FORENSIC]?.propPageLink;
    const valuationLink = sectionsHash[User.FVSSectionMembershipIdsEnum.VALUATION]?.propPageLink;
    // FVS Section Member
    if (
      link.title === UserUtils.sectionMembershipMap[User.MembershipIdsEnum.MRUSR0018]?.title ||
      link.title === UserUtils.sectionMembershipMap[User.MembershipIdsEnum.MRUSR0019]?.title
    ) {
      result.push({
        title: UserUtils.fvsSectionMembershipMap.Forensic.title,
        link: forensicLink,
        isInternal: true,
      });
      result.push({
        title: UserUtils.fvsSectionMembershipMap.Valuation.title,
        link: valuationLink,
        isInternal: true,
      });
    }
    result.push(link);
    return result;
  }, []);

  return (
    <StyledFeedTargetableLinks
      title={title}
      links={finalLinks}
      testId="feedSectionMemberships"
      browseAllLink={browseAllMembershipsLink}
      isMultipleLines
      setBurger={setBurger}
      browseAllLinkTitle={browseAllLinkTitle}
    />
  );
});

const StyledFeedTargetableLinks = styled(FeedTargetableLinks)`
  margin: ${props => props.theme.pxToRem(36)} 0;
`;
