import React, { memo } from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { LabeledData } from 'components/atoms';
import { ProfileHeading } from 'components/molecules/ProfileHeading/ProfileHeading';
import { datesToYearsMonths, partTimeDatesToYearsMonths } from 'utils/dateHelper';
import { MembershipTypes } from 'mxp-schemas';
import { unescapeString } from 'utils/StringHelpers';

interface Props {
  practicalExpReqRecord: MembershipTypes.PracticalExperienceRequirementEmployment;
}

export const PracticalExpReqEmploymentDetails: React.FC<Props> = memo(({ practicalExpReqRecord }) => {
  const data = practicalExpReqRecord;
  return (
    <>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData label="Business Name" data={data?.employer?.name || ''} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData label="Organization Type" data={data?.employer?.type || ''} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData label="Business Address Line 1" data={data?.employer?.addressLine1 || ''} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData label="Business Address Line 2" data={data?.employer?.addressLine2 || ''} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData label="City" data={data?.employer?.city || ''} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData label="State" data={data?.employer?.state || ''} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData label="ZIP/Postal Code" data={data?.employer?.postalCode || ''} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData label="Country" data={data?.employer?.country || ''} />
        </Grid.Column>
      </Grid.Row>
      <br />
      <Grid.Row>
        <Grid.Column width={16}>
          <StyledProfileHeading title="Your Role" hasBorder={false} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="Job title"
            data={
              <>
                {data?.jobTitle || ''}
                <StyledEmploymentType>{data?.employmentType || ''}</StyledEmploymentType>
              </>
            }
          />
        </Grid.Column>
      </Grid.Row>
      {data.employmentType === MembershipTypes.PracticalExperienceEmploymentType.PART_TIME && (
        <Grid.Row>
          <Grid.Column width={16}>
            <LabeledData label="Number of days per week on average" data={data.numberOfDaysPerWeek || ''} />
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData label="Start date" data={data.startDate || ''} />
        </Grid.Column>
        <Grid.Column width={16}>
          <LabeledData label="End date" data={data.endDate || ''} />
        </Grid.Column>
        {data.startDate && data.endDate && (
          <CustomDateSpanColumn width={16}>
            {data.employmentType === MembershipTypes.PracticalExperienceEmploymentType.FULL_TIME ? (
              `${datesToYearsMonths([
                { startDate: data.startDate.toString(), endDate: data.endDate.toString() },
              ])} in total`
            ) : (
              <>
                {`${partTimeDatesToYearsMonths(
                  [{ startDate: data.startDate.toString(), endDate: data.endDate.toString() }],
                  data.numberOfDaysPerWeek
                )} in total`}
              </>
            )}
          </CustomDateSpanColumn>
        )}
      </Grid.Row>
      <StyledRow>
        <Grid.Column width={16}>
          <LabeledData label="Summary of roles and responsibilities" data={unescapeString(data.summary) || ''} />
        </Grid.Column>
      </StyledRow>
    </>
  );
});

const StyledEmploymentType = styled.p``;

const CustomDateSpanColumn = styled(Grid.Column)`
  margin: ${props => `${props.theme.pxToRem(20)} 0 ${props.theme.pxToRem(20)}`};
  font-size: ${props => props.theme.fontSizes.s} !important;
  font-weight: ${props => props.theme.fontWeights.light} !important;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledProfileHeading = styled(ProfileHeading)`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.l};
  margin-bottom: ${props => props.theme.pxToRem(5)};
`;

const StyledRow = styled(Grid.Row)`
  && {
    margin-top: ${props => props.theme.pxToRem(30)};
  }
`;
