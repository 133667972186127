import { OnlyDesktopCSS, OnlyMobileCSS, StepperBar } from 'components/atoms';
import { Routes, CimaMipAmlApplicationSteps, CimaMipCheckoutSteps } from '../../../constants';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { getPath } from 'utils';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import { StepperProps } from 'components/atoms/StepperBar/StepperBar';

interface Props {
  isCimaMipAmlJourney?: boolean;
  isCimaMipCheckoutJourney?: boolean;
}

export const HeaderCimaMipApplication: React.FC<Props> = ({ isCimaMipAmlJourney = false }) => {
  const { pathname: currPath, hash: currHash } = useLocation();

  const membershipPathFound =
    currPath !== getPath(Routes.CIMA_MIP_AML_APPLICATION_CTF_RETURN_FORM) ||
    currPath !== getPath(Routes.CIMA_MIP_CHECKOUT_ATTESTATION);

  const cimaMipAmlSteps: StepperProps[] = useMemo(
    () =>
      CimaMipAmlApplicationSteps.map(({ name, pathName, endPathName }, i) => {
        const path = getPath(pathName);
        const currPageId = CimaMipAmlApplicationSteps.findIndex(el => getPath(el.pathName) === currPath);

        const order = 1;

        return {
          title: `${i + order}. ${name}`,
          isActive: currPath === path,
          isSuccess: i < currPageId,
        };
      }),
    // eslint-disable-next-line
    [currPath, currHash]
  );

  const cimaMipCheckoutSteps: StepperProps[] = useMemo(
    () =>
      CimaMipCheckoutSteps.map(({ name, pathName, endPathName }, i) => {
        const path = getPath(pathName);
        const currPageId = CimaMipCheckoutSteps.findIndex(el => getPath(el.pathName) === currPath);

        const order = 1;

        return {
          title: `${i + order}. ${name}`,
          isActive: currPath === path,
          isSuccess: i < currPageId,
        };
      }),
    // eslint-disable-next-line
    [currPath, currHash]
  );

  return (
    <>
      <StyledDiv className="cimaMipStyles">
        <OnlyDesktopCSS>
          <StyledContainer fluid className="cimaMipStyles">
            {membershipPathFound && (
              <StepperBar className="true" steps={isCimaMipAmlJourney ? cimaMipAmlSteps : cimaMipCheckoutSteps} />
            )}
          </StyledContainer>
        </OnlyDesktopCSS>
        <OnlyMobileCSS>
          <StyledMobileContainer>
            {currPath !== getPath(Routes.APPLICATION_FORM_START) && (
              <StyledMobileBottom>
                <StepperBar steps={cimaMipAmlSteps} />
              </StyledMobileBottom>
            )}
          </StyledMobileContainer>
        </OnlyMobileCSS>
      </StyledDiv>
    </>
  );
};

const StyledDiv = styled.div`
  .cimaMipStyles {
    background-color: ${props => props.theme.colors.neutralGrey1};
  }
`;

const StyledContainer = styled(Container)`
  &&&& {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &&&&.cimaMipStyles {
    > div {
      padding: ${props => props.theme.pxToRem(16)} 0 ${props => props.theme.pxToRem(12)};
      box-sizing: border-box;
      margin: auto;
    }
  }
`;

const StyledMobileContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledMobileBottom = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.neutralGrey1};
  padding: ${props => props.theme.pxToRem(8)} 0;
  margin: 0 ${props => props.theme.pxToRem(-22.5)};
  width: 100%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(8)};
  }
`;
