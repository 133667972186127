import { getLocation } from 'connected-react-router';
import { ContentRestriction, Routes } from 'constants/index';
import {
  contentMemberSelector,
  contentPageItemSelector,
  relatedContentSelector,
  toolkitPageContentSelector,
} from './selectors';
import {
  getPageNameAccessor,
  storefrontLandingPageProducts,
  storefrontAggregationPageProducts,
  premiumAicpaGatedContent,
  premiumAicpaContentAccessed,
  premiumTopics,
  extractProductPayload,
} from 'utils/Analytics/helpers';
import { EventDescribor, UTAG_TYPES, Accessor } from 'utils/Analytics/types';
import { landingPageSelector } from 'modules/landingPagesContent/selectors';
import { staticLandingPageSelector } from 'modules/staticLandingPagesContent/selectors';

import {
  contentIsLockedSelector,
  contentIsExclusiveSelector,
  productAggPageContentSelector,
} from 'modules/content/selectors';
import { userAuthStatusSelector } from 'modules/user/selectors';
import { productTypesSlugsStrSelector } from 'modules/productTypes/selectors';
import { topicsSlugsStrSelector } from 'modules/topics/selectors';
import { getReferrerSelector } from 'modules/search/selectors';
import { User, Contentful } from 'mxp-schemas';
import { isPremiumContentPageSelector } from '../router';

interface Topic {
  id: string;
}

interface Industry {
  id: string;
}
interface SubTopic {
  id: string;
  topicId: string;
}

const isRelatedContent = (state: State.Root) => {
  const relatedContent = relatedContentSelector(state);
  const contentLength = relatedContent?.length || 0;
  const payload = extractProductPayload(relatedContent, {
    total: contentLength,
    from: 1,
    to: contentLength,
    perPage: contentLength,
    pageNumber: 1,
  });

  if (!contentLength) return;
  return payload;
};

export const addTopicNames = (_: any, state: any): string => {
  const isPremiumContentPage = isPremiumContentPageSelector(state);
  if (isPremiumContentPage) {
    return premiumTopics(state?.search?.results || []);
  }

  const { topicsHash } = state.topics;
  const contentItem = contentPageItemSelector(state);
  const topics = contentItem?.topics || [];
  const premiumSubscriptionTopic = contentItem?.topicalSubscriptions?.map(topicSub => topicSub.slug) || [];

  const topicNames = Array.isArray(topics) ? topics?.map((topic: Topic) => topicsHash[topic.id].name) : [];
  const allNames = premiumSubscriptionTopic.concat(topicNames);
  return allNames.join('|') || '';
};

const addSubTopicNames = (_: any, state: any): string => {
  const { topicsHash } = state.topics;
  const subtopics = (contentPageItemSelector(state)?.subtopics as SubTopic[]) || [];
  return Array.isArray(subtopics)
    ? subtopics
        ?.map((subtopic: SubTopic) => topicsHash[subtopic.topicId]?.subtopics?.[subtopic.id]?.name || '')
        .join('|')
    : '';
};

export const addIndustryNames = (_: any, state: any): string => {
  const { industriesHash } = state.industries;
  const contentItem = contentPageItemSelector(state);
  const industries = contentItem?.industries || [];
  const industryNames = Array.isArray(industries)
    ? industries?.map((industry: Industry) => industriesHash[industry.id].name)
    : [];
  return industryNames.join('|') || '';
};

const getPremiumSubscription = (contentMember: { [key: string]: User.MembershipIdsEnum[] }) => {
  const hasPremiumSubscription = contentMember?.TopicalSubscription?.length;
  const hasPremiumSection = contentMember?.Section?.length;

  if (hasPremiumSubscription) {
    return hasPremiumSection
      ? `${ContentRestriction.PREMIUM}/${ContentRestriction.PREMIUM_SUBSCRIPTION}`
      : ContentRestriction.PREMIUM_SUBSCRIPTION;
  }

  return '';
};

export const lockedContentAccessors: () => Accessor[] = () => [
  {
    name: 'gated_content',
    path: 'roles',
    default: 'free',
    processor: (roles: User.MembershipIdsEnum, state: State.Root) => {
      const isPremiumContentPage = isPremiumContentPageSelector(state);
      if (isPremiumContentPage) {
        return premiumAicpaGatedContent(state?.search?.results || []);
      }

      const {
        isMemberExclusive,
        isRegisteredExclusive,
        isSectionExclusive,
        isCredentialExclusive,
        isSectionAndCredentialExclusive,
      } = contentIsExclusiveSelector(state);

      const contentMember = contentMemberSelector(state);

      const premiumSubscription = getPremiumSubscription(contentMember);
      if (premiumSubscription) {
        return premiumSubscription;
      }

      if (isRegisteredExclusive) {
        return ContentRestriction.REGISTERED;
      }
      if (isMemberExclusive) {
        return ContentRestriction.MEMBER;
      }
      if (isSectionExclusive || isCredentialExclusive || isSectionAndCredentialExclusive) {
        return ContentRestriction.PREMIUM;
      }
      return ContentRestriction.FREE;
    },
  },
  {
    name: 'content_accessed',
    path: '',
    processor: (a, state: State.Root) => {
      const isPremiumContentPage = isPremiumContentPageSelector(state);
      if (isPremiumContentPage) {
        return premiumAicpaContentAccessed(state?.search?.results || []);
      }

      return String(!contentIsLockedSelector(state));
    },
  },
  {
    name: 'user_login',
    path: '',
    processor: (a, state: State.Root) => userAuthStatusSelector(state),
  },
];

export const ContentAnalyticsConsts: {
  [key: string]: EventDescribor;
} = {
  'content/GET_CONTENT_HASH': {
    name: 'loadingFeed',
    accessors: [
      {
        name: 'pagination_marker',
        path: 'meta',
        processor: a => `${parseInt(a.perPage, 10) * parseInt(a.page, 10) || 0}`,
      },
      {
        name: 'pagination_total',
        path: 'meta',
        processor: a => `${parseInt(a.total, 10)}`,
      },
    ],
  },
  'content/GET_CONTENT_ITEM': {
    name: 'content_view', // fires event using content_view name
    route: Routes.CONTENT_PAGE,
    accessors: [
      ...getPageNameAccessor(),
      ...lockedContentAccessors(),
      {
        name: 'content_id',
        path: 'id',
        processor: (a, b) => contentPageItemSelector(b)?.id || '',
      },
      {
        name: 'content_title',
        path: 'title',
        processor: (a, b) => contentPageItemSelector(b)?.title || '',
      },
      {
        name: 'content_category',
        path: 'contentCategory.slug',
        processor: (a, b) => contentPageItemSelector(b)?.contentCategory?.slug || '',
      },
      {
        name: 'content_type',
        path: 'contentType',
        processor: (a, b) => contentPageItemSelector(b)?.contentType?.name || '',
      },
      {
        name: 'content_author',
        path: 'contentAuthor[0].name',
        processor: (a, b) => contentPageItemSelector(b)?.contentAuthor?.[0]?.name || '',
      },
      {
        name: 'content_date_created',
        path: 'dateCreated',
        processor: (a, b) => contentPageItemSelector(b)?.dateCreated || '',
      },
      {
        name: 'content_topics',
        path: 'topics',
        processor: addTopicNames,
      },
      {
        name: 'content_subtopics',
        path: 'subtopics',
        processor: addSubTopicNames,
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  'content/FETCH_STATIC_LANDING_PAGE': {
    name: 'content_view', // fires event using content_view name
    accessors: [
      ...getPageNameAccessor(),
      ...lockedContentAccessors(),
      {
        name: 'content_id',
        path: 'id',
        processor: (a, b) => staticLandingPageSelector(b)?.entryId || '',
      },
      {
        name: 'content_title',
        path: 'title',
        processor: (a, b) => staticLandingPageSelector(b)?.title || '',
      },
      {
        name: 'content_category',
        path: 'contentCategory.slug',
        processor: (a, b) => staticLandingPageSelector(b)?.contentCategory?.slug || '',
      },
      {
        name: 'content_type',
        path: 'contentType',
        processor: (a, b) => staticLandingPageSelector(b)?.contentType || '',
      },
      {
        name: 'content_author',
        path: 'contentAuthor[0].name',
        processor: (a, b) => staticLandingPageSelector(b)?.contentAuthor?.[0]?.name || '',
      },
      {
        name: 'content_date_created',
        path: 'dateCreated',
        processor: (a, b) => staticLandingPageSelector(b)?.dateCreated || '',
      },
      {
        name: 'content_topics',
        path: 'topics',
        processor: (a, b) => {
          const topics = staticLandingPageSelector(b)?.topics;
          const topicNames = Array.isArray(topics) ? topics?.map((topic: any) => topic.name) : [];
          return topicNames.join('|') || '';
        },
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  'content/GET_TOOLKIT_PAGE': {
    name: 'toolkit_view',
    route: Routes.TOOLKIT_PAGE,
    accessors: [
      ...getPageNameAccessor(),
      {
        name: 'content_id',
        path: 'id',
        processor: (a, b) => toolkitPageContentSelector(b)?.id || '',
      },
      {
        name: 'content_title',
        path: 'title',
        processor: (a, b) => toolkitPageContentSelector(b)?.title || '',
      },
      {
        name: 'content_category',
        path: 'contentCategory',
        processor: (a, b) => toolkitPageContentSelector(b)?.contentCategory?.slug || '',
      },
      {
        name: 'content_type',
        path: 'contentType',
        processor: (a, b) => toolkitPageContentSelector(b)?.contentType || '',
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  'content/FETCH_LANDING_PAGE': {
    name: 'storefront_product_view',
    accessors: [
      ...getPageNameAccessor(),

      {
        name: 'sec_product_id',
        path: '',
        processor: (a, b) => {
          const pageBlocks = landingPageSelector(b)?.landingPageBlocks as Contentful.LandingPages.LandingPageBlock[];
          return storefrontLandingPageProducts(pageBlocks, b)?.id || [];
        },
      },
      {
        name: 'sec_product_category',
        path: '',
        processor: (a, b) => {
          const pageBlocks = landingPageSelector(b)?.landingPageBlocks as Contentful.LandingPages.LandingPageBlock[];
          return storefrontLandingPageProducts(pageBlocks, b)?.category || [];
        },
      },
      {
        name: 'sec_product_price',
        path: '',
        processor: (a, b) => {
          const pageBlocks = landingPageSelector(b)?.landingPageBlocks as Contentful.LandingPages.LandingPageBlock[];
          return storefrontLandingPageProducts(pageBlocks, b)?.price || [];
        },
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  'content/GET_PRODUCT_AGGS_PAGE_CONTENT': {
    name: 'storefront_aggregation_view',
    accessors: [
      ...getPageNameAccessor(),
      {
        name: 'topics',
        path: '',
        processor: (a, b) => {
          const topics = topicsSlugsStrSelector(b);
          const { pathname: currentPathname } = getLocation(b);
          const aggType = currentPathname.split('/')?.[2] || '';
          return topics.includes(aggType) ? aggType : 'all';
        },
      },
      {
        name: 'type',
        path: '',
        processor: (a, b) => {
          const productTypes = productTypesSlugsStrSelector(b);
          const { pathname: currentPathname } = getLocation(b);
          const aggType = currentPathname.split('/')?.[2] || '';
          return productTypes.includes(aggType) ? aggType : 'all';
        },
      },
      {
        name: 'search_type',
        path: '',
        processor: (a, b) => {
          const referrer = getReferrerSelector(b);
          return referrer ? 'intended' : 'navigated';
        },
      },
      {
        name: 'search_referrer',
        path: '',
        processor: (a, b) => {
          return getReferrerSelector(b);
        },
      },
      {
        name: 'sec_product_id',
        path: '',
        processor: (a, b) => {
          const items = productAggPageContentSelector(b)?.mainSectionItems as State.ContentCardItem[];
          return storefrontAggregationPageProducts(items, b)?.id || [];
        },
      },
      {
        name: 'sec_product_category',
        path: '',
        processor: (a, b) => {
          const items = productAggPageContentSelector(b)?.mainSectionItems as State.ContentCardItem[];
          return storefrontAggregationPageProducts(items, b)?.category || [];
        },
      },
      {
        name: 'sec_product_price',
        path: '',
        processor: (a, state: State.Root) => {
          const items = productAggPageContentSelector(state)?.mainSectionItems as State.ContentCardItem[];

          return storefrontAggregationPageProducts(items, state)?.price || [];
        },
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  'content/GET_RELATED_CONTENT': {
    name: 'related_product_view',
    accessors: [
      ...getPageNameAccessor(),
      {
        name: 'product_name',
        path: 'name',
        processor: (a, b) => {
          return isRelatedContent(b)?.name || '';
        },
      },
      {
        name: 'product_category',
        path: 'productType',
        processor: (a, b) => {
          return isRelatedContent(b)?.category || '';
        },
      },
      {
        name: 'product_id',
        path: 'productId',
        processor: (a, b) => {
          return isRelatedContent(b)?.id || '';
        },
      },
      {
        name: 'product_location',
        path: 'location',
        processor: (a, b) => {
          return isRelatedContent(b)?.location || '';
        },
      },

      {
        name: 'product_price',
        path: 'pricing',
        processor: (a, b) => {
          return isRelatedContent(b)?.price || 0;
        },
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
};
