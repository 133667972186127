import React, { useState, useEffect, useCallback } from 'react';
import { Popup } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { Utils, Admin as AdminUtils } from 'mxp-utils';
import { User } from 'mxp-schemas';
import {
  membershipTiersSelector,
  membershipTypesSelector,
  userMembershipTypeSelector,
  userMembershipPackageSelector,
  isFirmAffiliatedForCPEAOrPCPSSelector,
} from 'modules/membership/selectors';
import { centerMembershipPackageOrganizationSelector, isAuthSelector } from 'modules/user/selectors';
import {
  addMembershipToCart,
  setMembershipPackageTier,
  setCenterMembershipPackageType,
} from 'modules/membership/actions';
import { setMembershipPackageOrganizationIssuer } from 'modules/user/actions';
import { ButtonEnums, FeedPlaceholder } from 'components/atoms';
import { DropdownProps } from 'components/atoms/Dropdown/Dropdown';
import { TierBenefitCard } from 'components/atoms/BenefitCard/TierBenefitCard';
import {
  FirmMembershipTabSelector,
  DefaultTab,
} from 'components/molecules/FirmMembershipTabSelector/FirmMembershipTabSelector';
import { isAdminPortalSelector } from 'modules/app/selectors';
import {
  CartCompoundAnalytics,
  getOrderTypeAnalytics,
  isB2BMembershipType,
  isB2CMembershipType,
} from 'utils/Analytics/helpers';
import { freeTrialEndDateSelector } from 'modules/products/selectors';
import { ADD_CART, ADD_CART_FIRST, handleEvent, handleGoogleAdsRemarketing, EVENT_CLICK } from 'utils/Analytics';
import { MembershipPackageAccordionStatus } from '../../../constants';
import {
  PCAOB_MIN_NUM,
  FIRM_REGISTERED_DROPDOWN_OPTIONS,
  getFirmRegisteredPCAOBDefaultValue,
  isAcademiaUtil,
  checkIfInRange,
  getCenterMembershipProductsTabItems,
  /* INPUT UTILS */
  cpaInputValidationIsCorrect,
  cpaInputValidationErrorMessage,
  cpaFormInputDescription,
  pcaobInputNumberOfIssuersValue,
  pcaobInputNumberOfIssuersErrorMessage,
  getButtonIconName,
  getButtonTitle,
  renewApplyButtonDisabled,
  renewApplyButtonTitle,
} from './centerMembershipTierSelectionUtils';
import {
  StyledTierContainer,
  StyledBenefitHeader,
  StyledTierBenefitContainer,
  StyledTierBenefitItem,
  StyledButtonContainer,
  StyledTierPrice,
  MainDiv,
  StyledButton,
  StyledCAQEditButton,
  StyledBenefitsContainer,
  StyledInputContainer,
  StyledCpaContainer,
  StyledPCAOBContainer,
  StyledLabel,
  StyledFormInputDesc,
  StyledInputWithValidation,
  StyledHelpUs,
  StyledDropdown,
  StyledApplyNowValidationText,
} from './centerMembershipTierSelectionStyles';

interface Props {
  isRenewal?: boolean;
  renewalNumberOfCpa?: string;
  centerMembershipEndDate?: string;
}

export const CenterMembershipTierSelection: React.FC<Props> = ({
  isRenewal = false,
  renewalNumberOfCpa = '',
  centerMembershipEndDate,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [cpaCount, setCpaCount] = useState('');
  const [cpaInputHasError, setCpaInputHasError] = useState('');
  const [issuersInputHasError, setIssuersInputHasError] = useState('');

  const [form, setForm] = useState({
    numberOfCpa: '',
    PCAOBNumOfIssuers: '',
    tierSku: '',
  });
  const [loading, setLoading] = useState(false);
  const [isCAQDisable, setIsCAQDisable] = useState(false);
  const [isNonCAQDisable, setIsNonCAQDisable] = useState(false);
  const [isNumCpaTouched, setIsNumCpaTouched] = useState(false);
  const [isNumIssuerTouched, setIsNumIssuerTouched] = useState(false);

  // impersonation
  const isAdmin = useSelector(isAdminPortalSelector);
  const isAuth = useSelector(isAuthSelector);
  const membershipTierList = useSelector(membershipTiersSelector).list;

  const {
    type: relationshipType,
    numCPA,
    PCAOBNumOfIssuers,
    isPCAOBOrganization,
  } = useSelector(centerMembershipPackageOrganizationSelector);
  const { list } = useSelector(membershipTypesSelector);

  // selected center membership type
  const membershipType = useSelector(userMembershipTypeSelector);
  const benefits = list?.filter((type: any) => type.slug.toLowerCase() === `${membershipType}`.toLowerCase());
  const isFirmAffiliatedForCPEAOrPCPS = useSelector(isFirmAffiliatedForCPEAOrPCPSSelector);

  // center membership variants/tiers
  const membershipTiers: State.MembershipTierBlock[] = membershipTierList.filter(
    tier => (isAuth && isAdmin) || !tier.isInternalAgent
  );

  const [firmMembershipDefaultTab, setFirmMembershipDefaultTab] = useState<DefaultTab[]>([]);
  const isFirmRegisteredPCAOBDefaultValue = getFirmRegisteredPCAOBDefaultValue(isPCAOBOrganization);
  const [isFirmRegisteredPCAOB, setIsFirmRegisteredPCAOB] = useState<string>(isFirmRegisteredPCAOBDefaultValue);
  const isFirmPCAOB = Boolean(isFirmRegisteredPCAOB === FIRM_REGISTERED_DROPDOWN_OPTIONS[0].value);

  const renewalProductItem = useSelector(userMembershipPackageSelector);
  const isAcademia = isAcademiaUtil(relationshipType, membershipType);
  const isAcademiaRenewal = isRenewal || isAcademia;
  const [isTierOpen, setIsTierOpen] = useState(isAcademiaRenewal);

  const numCPAString = `${numCPA || ''}`;
  const initNumCPA = isRenewal ? renewalNumberOfCpa : numCPAString;

  const getMembershipSelectedTierId = (cpa: string = '', pcaobIssuersCount: string = ''): string => {
    const { defaultTierId, tabs } = getCenterMembershipProductsTabItems(
      cpa,
      membershipType,
      isAcademia,
      relationshipType,
      pcaobIssuersCount,
      membershipTiers
    );

    setFirmMembershipDefaultTab(tabs as any);
    return defaultTierId;
  };

  const showTiers = isAcademiaRenewal || !!form.tierSku;

  const basePrice = membershipTiers
    ?.filter((tiers: any) => tiers.sku === form.tierSku)
    .map((tier: any) => {
      if (isRenewal && isFirmAffiliatedForCPEAOrPCPS) {
        const discountedPrice = tier.prices.find((price: any) =>
          price.channel?.applicableUserRoles.includes(User.MembershipIdsEnum.Centers_Affiliated)
        );

        if (discountedPrice) {
          return discountedPrice;
        }
      }
      return tier.prices[0];
    })[0]?.amount;

  const formattedBasePrice = Utils.fromCentsFormat(basePrice);

  const freeTrialEndDate = useSelector(freeTrialEndDateSelector);

  const checkIfNumeric = (str: string) => /^\d+$/.test(str);

  const handleAddCardEvent = useCallback(
    async (data: any) => {
      if (!data) return;
      const cartItems = await data.lineItems;

      const addToCart = cartItems.map((productItem: any) => {
        const {
          productId,
          name,
          productType,
          quantity,
          variant: { sku = '' } = {},
          totalPrice: { centAmount = 0 } = {},
        } = productItem;

        const amount = Number(centAmount / 100).toFixed(2);

        const isB2BMembership = isB2BMembershipType(productType);
        const isB2CMembership = isB2CMembershipType(productType);

        const payload: CartCompoundAnalytics = {
          id: [productId],
          bundleId: [productId],
          name: [name],
          category: [productType],
          price: [+amount],
          quantity: [quantity],
          sku: [sku],
          orderType: [
            getOrderTypeAnalytics({
              isStandingOrder: false,
              isBundle: false,
              isFreeTrial: !!freeTrialEndDate,
              isB2BMembership,
              isB2CMembership,
            }),
          ],
          shippingCost: [],
          shippingLocation: [],
          shippingType: [],
          productTax: [],
          freeTrialEndDate: [freeTrialEndDate],
        };

        return payload;
      });

      const firstCart = addToCart.slice(0, 1);

      handleEvent(firstCart[0], ADD_CART_FIRST);
      handleGoogleAdsRemarketing(firstCart[0]);

      const remainingInCarts = addToCart.splice(1);

      remainingInCarts.map((item: any) => {
        handleEvent(item, ADD_CART);
        handleGoogleAdsRemarketing(item);
        return true;
      });
    },
    [freeTrialEndDate]
  );

  const modalComplete = React.useCallback(async () => {
    const destination = getPath(Routes.CENTER_MEMBERSHIP_FORM);
    const membershipStatus = MembershipPackageAccordionStatus.CPA;

    isRenewal
      ? await handleEvent(
          {
            clickValue: `button:renew-membership:int:modal-complete-renew:${destination}#${membershipStatus}`,
          },
          EVENT_CLICK
        )
      : await handleEvent(
          {
            clickValue: `button:center-membership:int:modal-complete-Apply Now${destination}#${membershipStatus}`,
          },
          EVENT_CLICK
        );
  }, [isRenewal]);

  const handleAddToCart = useCallback(async () => {
    setLoading(true);
    await dispatch(setCenterMembershipPackageType(renewalProductItem.type?.id, renewalProductItem.type?.slug));
    await dispatch(setMembershipPackageOrganizationIssuer(form.numberOfCpa, form.PCAOBNumOfIssuers, isFirmPCAOB));
    await dispatch(setMembershipPackageTier(form.tierSku));
    const cartLineItems = await dispatch(addMembershipToCart(isRenewal, centerMembershipEndDate));
    await handleAddCardEvent(cartLineItems.payload.addMembershipToCart); // tslint:disable-next-line
    await modalComplete(); // tslint:disable-next-line

    if (isRenewal) {
      history.push(getPath(Routes.CENTER_MEMBERSHIP_FORM_ATTESTATION));
    } else {
      history.push(getPath(Routes.CENTER_MEMBERSHIP_FORM_START));
    }
  }, [
    history,
    dispatch,
    form,
    isRenewal,
    renewalProductItem,
    isFirmPCAOB,
    handleAddCardEvent,
    modalComplete,
    centerMembershipEndDate,
  ]);

  const handleCpaTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsNumCpaTouched(true);
    const isNumeric = checkIfNumeric(event?.target.value || '0');
    setCpaCount(event?.target.value);
    setCpaInputHasError(!isNumeric ? 'Please enter numerical characters.' : '');

    if (isNumeric) {
      setForm({
        ...form,
        numberOfCpa: event?.target.value,
        // ignore CPA text change to get the tier
        ...(!AdminUtils.isCAQType(membershipType) && {
          tierSku: getMembershipSelectedTierId(`${event?.target.value}`, form.PCAOBNumOfIssuers),
        }),
      });
    }
  };

  const handleIssuersTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsNumIssuerTouched(true);
    const isNumeric = checkIfNumeric(event?.target.value);
    setIssuersInputHasError(!isNumeric ? 'Please enter numerical characters.' : '');
    setForm({
      ...form,
      PCAOBNumOfIssuers: event?.target.value,
      ...(AdminUtils.isCAQType(membershipType) && {
        tierSku: getMembershipSelectedTierId(form.numberOfCpa, `${event?.target.value}`),
      }),
    });
  };

  const handleDropdownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setIsFirmRegisteredPCAOB(`${data.value}`);

    const tempPCAOB = data.value === FIRM_REGISTERED_DROPDOWN_OPTIONS[0].value;

    if (tempPCAOB) {
      setIsNumIssuerTouched(true);
      setIssuersInputHasError('Please input the number of Issuer audits');
    } else {
      setIssuersInputHasError('');
    }

    setForm({
      PCAOBNumOfIssuers: !tempPCAOB ? `${PCAOB_MIN_NUM}` : `${PCAOBNumOfIssuers || ''}`,
      numberOfCpa: cpaCount,
      tierSku: getMembershipSelectedTierId(
        `${cpaCount}`,
        !tempPCAOB ? `${PCAOB_MIN_NUM}` : `${PCAOBNumOfIssuers || ''}`
      ),
    });
  };

  const onTabChange = (tierId: string) => setForm({ ...form, tierSku: tierId });

  const handleCAQEditClick = () => {
    if (isCAQDisable) {
      setIsCAQDisable(false);
      setIsTierOpen(false);
    } else {
      setIsTierOpen(true);
      setIsCAQDisable(true);
    }
  };

  const handleNonCAQClick = () => {
    if (isNonCAQDisable) {
      setIsNonCAQDisable(false);
      setIsTierOpen(false);
    } else {
      setIsTierOpen(true);
      setIsNonCAQDisable(true);
    }
  };

  const handleClearCpaCount = () => setCpaCount('');

  const handleClearIssuerCount = () => setForm(prev => ({ ...prev, PCAOBNumOfIssuers: '' }));

  const checkCpaCountInput = (cpa: string = ''): string => {
    const activeTier: State.MembershipTierBlock | undefined = membershipTiers.find(
      (tier: State.MembershipTierBlock) => tier.sku === form.tierSku
    );

    if (!Number(cpaCount)) return 'Please enter a valid number';

    let maxNumError: string = '';
    if (activeTier !== undefined && !isRenewal) {
      maxNumError = !checkIfInRange(cpa, membershipType, `${activeTier.minCpas}`, `${activeTier.maxCpas}`)
        ? 'You have exceeded the maximum number of CPAs allowed'
        : '';
      return maxNumError;
    }

    return !isRenewal ? maxNumError : '';
  };

  const checkIssuerCountInput = (issuerCount: string = ''): string => {
    const activeTier: State.MembershipTierBlock | undefined = membershipTiers.find(
      (tier: State.MembershipTierBlock) => tier.sku === form.tierSku
    );

    if (activeTier !== undefined && !isRenewal && isFirmPCAOB) {
      return !checkIfInRange(issuerCount, membershipType, `${activeTier.minIssuers}`, `${activeTier.maxIssuers}`)
        ? 'You have exceeded the maximum number of Issuer Audits allowed'
        : '';
    }
    return !isRenewal && isFirmPCAOB ? '1 You have exceeded the maximum number of Issuer Audits allowed' : '';
  };

  useEffect(() => {
    // this will run once
    // initialize form
    const tempIssuerAudits = !isPCAOBOrganization ? `${PCAOB_MIN_NUM}` : `${PCAOBNumOfIssuers || ''}`;

    setForm({
      numberOfCpa: initNumCPA,
      PCAOBNumOfIssuers: tempIssuerAudits,
      tierSku: getMembershipSelectedTierId(initNumCPA, tempIssuerAudits),
    });
    setCpaCount(initNumCPA);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // validate form when data changes
    // useEffect will execute every time the form changes
    if (checkIfNumeric(cpaCount)) setCpaInputHasError(cpaCount && !isFirmPCAOB ? checkCpaCountInput(cpaCount) : '');
    if (form.PCAOBNumOfIssuers) {
      if (isFirmRegisteredPCAOB && checkIfNumeric(form.PCAOBNumOfIssuers)) {
        setIssuersInputHasError(checkIssuerCountInput(form.PCAOBNumOfIssuers));
      }
    }

    if (checkCpaCountInput(cpaCount).length) setCpaInputHasError(checkCpaCountInput(cpaCount));
  }, [form, setForm]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!membershipTiers.length) return <FeedPlaceholder />;
  return (
    <MainDiv>
      {!isRenewal && !isAcademia && (
        <StyledInputContainer isCAQ={AdminUtils.isCAQType(membershipType)}>
          <StyledCpaContainer>
            <StyledLabel>Number of CPAs</StyledLabel>
            <Popup
              header="Number of CPAs"
              content="Please enter at least one (1) or more CPAs"
              position="top right"
              on="focus"
              trigger={
                <div>
                  {/* For CAQ */}
                  {AdminUtils.isCAQType(membershipType) && (
                    <StyledInputWithValidation
                      type="text"
                      name="number-of-cpas"
                      placeholder="Number of CPAs"
                      testId="number-of-cpas"
                      value={cpaCount}
                      onChange={handleCpaTextChange}
                      isCorrect={cpaInputValidationIsCorrect(cpaInputHasError, isNumCpaTouched)}
                      isCorrectIconShown={true}
                      errorMessage={cpaInputValidationErrorMessage(isNumCpaTouched, cpaInputHasError)}
                      disabled={isCAQDisable}
                      clear={handleClearCpaCount}
                    />
                  )}

                  {/* For PCPS, GAQC, CPEA, EBPAQC */}
                  {!AdminUtils.isCAQType(membershipType) && (
                    <StyledInputWithValidation
                      type="text"
                      name="number-of-cpas"
                      placeholder="Number of CPAs"
                      testId="number-of-cpas"
                      value={cpaCount}
                      onChange={handleCpaTextChange}
                      isCorrect={cpaInputValidationIsCorrect(cpaInputHasError, isNumCpaTouched)}
                      isCorrectIconShown={true}
                      errorMessage={cpaInputValidationErrorMessage(isNumCpaTouched, cpaInputHasError)}
                      disabled={isNonCAQDisable}
                      clear={handleClearCpaCount}
                    />
                  )}
                </div>
              }
            />
            <StyledFormInputDesc>{cpaFormInputDescription(AdminUtils.isCAQType(membershipType))}</StyledFormInputDesc>
          </StyledCpaContainer>

          {/* For CAQ */}
          {AdminUtils.isCAQType(membershipType) && (
            <>
              <StyledPCAOBContainer>
                <StyledLabel>Is your firm registered with PCAOB?</StyledLabel>
                <StyledDropdown
                  testId="pcaob-dropdown"
                  placeholder="Select"
                  value={isFirmRegisteredPCAOB}
                  options={FIRM_REGISTERED_DROPDOWN_OPTIONS}
                  onChange={handleDropdownChange}
                  disabled={isCAQDisable}
                />
              </StyledPCAOBContainer>

              <StyledCpaContainer>
                <StyledLabel>Number of Issuer Audits</StyledLabel>
                <div>
                  <StyledInputWithValidation
                    type="text"
                    name="number-of-issuer-audits"
                    placeholder="Number of Issuer Audits"
                    testId="number-of-cpas"
                    value={pcaobInputNumberOfIssuersValue(isFirmPCAOB, form.PCAOBNumOfIssuers)}
                    onChange={handleIssuersTextChange}
                    isCorrect={Boolean(issuersInputHasError === '')}
                    isCorrectIconShown={true}
                    errorMessage={pcaobInputNumberOfIssuersErrorMessage(isNumIssuerTouched, issuersInputHasError)}
                    disabled={!isFirmPCAOB || isCAQDisable}
                    clear={handleClearIssuerCount}
                  />
                  <StyledFormInputDesc>Form 2</StyledFormInputDesc>
                </div>
              </StyledCpaContainer>

              <StyledCpaContainer>
                <StyledCAQEditButton
                  testId="edit-button"
                  variant={ButtonEnums.variants.primary}
                  iconPosition={ButtonEnums.iconPosition.left}
                  iconName={getButtonIconName(isCAQDisable)}
                  onClick={handleCAQEditClick}
                  disabled={!Boolean(cpaInputHasError === '') || !Boolean(issuersInputHasError === '')}
                >
                  {getButtonTitle(isCAQDisable)}
                </StyledCAQEditButton>
              </StyledCpaContainer>
            </>
          )}

          {/* For PCPS, GAQC, CPEA, EBPAQC */}
          {!AdminUtils.isCAQType(membershipType) && (
            <StyledCAQEditButton
              testId="go-button"
              variant={ButtonEnums.variants.secondary}
              onClick={handleNonCAQClick}
              iconPosition={ButtonEnums.iconPosition.left}
              iconName={getButtonIconName(isNonCAQDisable)}
              disabled={!Boolean(cpaInputHasError === '')}
            >
              {getButtonTitle(isNonCAQDisable)}
            </StyledCAQEditButton>
          )}

          <br />
        </StyledInputContainer>
      )}

      {showTiers && isTierOpen ? (
        <StyledTierContainer>
          <FirmMembershipTabSelector
            membershipType={membershipType}
            defaultTabs={firmMembershipDefaultTab}
            defaultTierId={form.tierSku}
            onTabChange={onTabChange}
          />

          <StyledBenefitsContainer>
            <StyledBenefitHeader>Key benefits</StyledBenefitHeader>

            <StyledTierBenefitContainer>
              {benefits.map((item: any) => (
                <React.Fragment key={item.id}>
                  {(item.benefits || []).map((benefit: any, index: number) => (
                    <StyledTierBenefitItem key={`${item.id}-${benefit.header}`}>
                      <TierBenefitCard title={benefit.header} description={benefit.description} />
                    </StyledTierBenefitItem>
                  ))}
                </React.Fragment>
              ))}
            </StyledTierBenefitContainer>

            <StyledButtonContainer>
              <StyledTierPrice>{`$${Number(formattedBasePrice)}/yr`}</StyledTierPrice>
              <StyledButton
                to={getPath(Routes.CENTER_MEMBERSHIP_FORM_START)}
                variant={ButtonEnums.variants.primary}
                bordered
                testId="test-id"
                onClick={handleAddToCart}
                disabled={renewApplyButtonDisabled(
                  showTiers,
                  issuersInputHasError,
                  AdminUtils.isCAQType(membershipType),
                  cpaInputHasError
                )}
                loading={loading}
              >
                {renewApplyButtonTitle(isRenewal)}
              </StyledButton>
              {AdminUtils.isCAQType(membershipType) && cpaInputHasError !== '' && (
                <StyledApplyNowValidationText>
                  Choose a tier based on the number of CPAs and Issuers inputted.
                </StyledApplyNowValidationText>
              )}
            </StyledButtonContainer>
          </StyledBenefitsContainer>
        </StyledTierContainer>
      ) : (
        <StyledHelpUs>
          Help us find the correct
          <br />
          membership tier for you.
        </StyledHelpUs>
      )}
      <br />
    </MainDiv>
  );
};
