import React from 'react';
import Loadable from 'react-loadable';
import { Loader } from 'semantic-ui-react';

const Loading = () => <Loader active content="Loading" />;

export const PageLoginContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageLoginContainer" */ 'containers/PageLoginContainer');
    return module.PageLoginContainer;
  },
  loading: Loading,
  modules: ['LoginPageContainer'],
});
export const PageMyPurchasesContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageLoginContainer" */ 'containers/ProfilePurchasesContainer');
    return module.ProfilePurchasesContainer;
  },
  loading: Loading,
  modules: ['LoginPageContainer'],
});

export const PageLandingContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageLandingContainer" */ 'containers/PageLandingContainer');
    return module.PageLandingContainer;
  },
  loading: Loading,
  modules: ['PageLandingContainer'],
});

export const PageForgotEmailContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageForgotEmailContainer" */ 'containers/PageForgotEmailContainer'
    );
    return module.PageForgotEmailContainer;
  },
  loading: Loading,
  modules: ['PageForgotEmailContainer'],
});

export const PageForgotPasswordContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageForgotPasswordContainer" */ 'containers/PageForgotPasswordContainer'
    );
    return module.PageForgotPasswordContainer;
  },
  loading: Loading,
  modules: ['PageForgotPasswordContainer'],
});

export const PageResetPasswordContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageResetPasswordContainer" */ 'containers/PageResetPasswordContainer'
    );
    return module.PageResetPasswordContainer;
  },
  loading: Loading,
  modules: ['PageResetPasswordContainer'],
});

export const NotFound = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "NotFound" */ 'components/pages/NotFound/NotFound');
    return module.NotFound;
  },
  loading: Loading,
  modules: ['NotFound'],
});

export const NotInvitedMember = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "NotInvitedMember" */ 'components/pages/NotInvitedMember/NotInvitedMember'
    );
    return module.NotInvitedMember;
  },
  loading: Loading,
  modules: ['NotInvitedMember'],
});

export const CimaNotInvitedMember = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "CimaNotInvitedMember" */ 'components/pages/CimaNotInvitedMember/CimaNotInvitedMember'
    );
    return module.CimaNotInvitedMember;
  },
  loading: Loading,
  modules: ['CimaNotInvitedMember'],
});

export const PageHomeFeedContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageHomeFeedContainer" */ 'containers/PageHomeFeedContainer');
    return module.PageHomeFeedContainer;
  },
  loading: Loading,
  modules: ['PageHomeFeedContainer'],
});

export const PageBlogFeedContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageBlogFeedContainer" */ 'containers/PageBlogFeedContainer');
    return module.PageBlogFeedContainer;
  },
  loading: Loading,
  modules: ['PageBlogFeedContainer'],
});

export const PageContentContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageContentContainer" */ 'containers/PageContentContainer');
    return module.PageContentContainer;
  },
  loading: Loading,
  modules: ['PageContentContainer'],
});

export const PageTopicAggregationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageTopicAggregationContainer" */ 'containers/PageTopicAggregationContainer'
    );
    return module.PageTopicAggregationContainer;
  },
  loading: Loading,
  modules: ['PageTopicAggregationContainer'],
});

export const PageIndustryAggregationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageIndustryAggregationContainer" */ 'containers/PageIndustryAggregationContainer'
    );
    return module.PageIndustryAggregationContainer;
  },
  loading: Loading,
  modules: ['PageIndustryAggregationContainer'],
});

export const PageTrendAggregationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageTrendAggregationContainer" */ 'containers/PageTrendAggregationContainer'
    );
    return module.PageTrendAggregationContainer;
  },
  loading: Loading,
  modules: ['PageTrendAggregationContainer'],
});

export const PageSkillAggregationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageSkillAggregationContainer" */ 'containers/PageSkillAggregationContainer'
    );
    return module.PageSkillAggregationContainer;
  },
  loading: Loading,
  modules: ['PageSkillAggregationContainer'],
});

export const PageCategoryAggregationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCategoryAggregationContainer" */ 'containers/PageCategoryAggregationContainer'
    );
    return module.PageCategoryAggregationContainer;
  },
  loading: Loading,
  modules: ['PageCategoryAggregationContainer'],
});

export const PagePreferencesContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PagePreferencesContainer" */ 'containers/PagePreferencesContainer'
    );
    return module.PagePreferencesContainer;
  },
  loading: Loading,
  modules: ['PagePreferencesContainer'],
});

export const PageSkillsPreferencesContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageSkillsPreferencesContainer" */ 'containers/PageSkillsPreferencesContainer'
    );
    return module.PageSkillsPreferencesContainer;
  },
  loading: Loading,
  modules: ['PageSkillsPreferencesContainer'],
});

export const PagePracticeAreasPreferencesContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PagePracticeAreasPreferencesContainer" */ 'containers/PagePracticeAreasPreferencesContainer'
    );
    return module.PagePracticeAreasPreferencesContainer;
  },
  loading: Loading,
  modules: ['PagePracticeAreasPreferencesContainer'],
});

export const PageIndustriesPreferencesContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageIndustriesPreferencesContainer" */ 'containers/PageIndustriesPreferencesContainer'
    );
    return module.PageIndustriesPreferencesContainer;
  },
  loading: Loading,
  modules: ['PageIndustriesPreferencesContainer'],
});

export const ProfilePreferencesContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "ProfilePreferencesContainer" */ 'containers/ProfilePreferencesContainer'
    );
    return module.ProfilePreferencesContainer;
  },
  loading: Loading,
  modules: ['ProfilePreferencesContainer'],
});

export const PersonalInformationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PersonalInformationContainer" */ 'containers/PersonalInformationContainer'
    );
    return module.PersonalInformationContainer;
  },
  loading: Loading,
  modules: ['PersonalInformationContainer'],
});

export const ProfileSavedItemsContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "ProfileSavedItemsContainer" */ 'containers/ProfileSavedItemsContainer'
    );
    return module.ProfileSavedItemsContainer;
  },
  loading: Loading,
  modules: ['ProfileSavedItemsContainer'],
});

export const PageMyProfile = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageMyProfile" */ 'components/pages/PageProfile/PageMyProfile');
    return module.PageMyProfile;
  },
  loading: Loading,
  modules: ['PageMyProfile'],
});

export const MyContentPreferences = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "MyContentPreferences" */ 'components/pages/ProfilePreferences/MyContentPreferences'
    );
    return module.MyContentPreferences;
  },
  loading: Loading,
  modules: ['MyContentPreferences'],
});

export const MyCommunications = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "MyCommunications" */ 'components/pages/ProfilePreferences/MyCommunications'
    );
    return module.MyCommunications;
  },
  loading: Loading,
  modules: ['MyCommunications'],
});

export const PersonalInformation = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PersonalInformation" */ 'components/pages/PageProfile/PersonalInformation'
    );
    return module.PersonalInformation;
  },
  loading: Loading,
  modules: ['PersonalInformation'],
});

export const PageCimaMembershipExamEPA2 = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCimaMembershipExamEPA2" */ 'containers/PageCimaExamEpa2Container'
    );
    return module.PageCimaExamEpa2L4Container;
  },
  loading: Loading,
  modules: ['PageCimaExamEpa2L4Container'],
});

export const PageCimaMembershipEPA2L7 = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCimaMembershipExamEPA2" */ 'containers/PageCimaExamEpa2Container'
    );
    return module.PageCimaExamEpa2L7Container;
  },
  loading: Loading,
  modules: ['PageCimaExamEpa2L7Container'],
});

export const PageCimaSupervisor = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCimaSupervisor" */ 'components/pages/PageCimaSupervisor/CimaSupervisor'
    );
    return module.CimaSupervisor;
  },
  loading: Loading,
  modules: ['PageCimaSupervisor'],
});

export const PageLoginSecurity = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageProfile" */ 'components/pages/PageLoginSecurity');
    return module.PageLoginSecurity;
  },
  loading: Loading,
  modules: ['PageLoginSecurity'],
});

export const MyWallet = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "MyWallet" */ 'components/pages/PageProfile/MyWallet');
    return module.MyWallet;
  },
  loading: Loading,
  modules: ['MyWallet'],
});

export const ProfileReceiptsContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageProfile" */ 'containers/ProfileReceiptsContainer');
    return module.ProfileReceiptsContainer;
  },
  loading: Loading,
  modules: ['ProfileReceiptsContainer'],
});

export const PagePromptToSetPreferences = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PagePromptToSetPreferences" */ 'components/pages/PagePromptToSetPreferences'
    );
    return module.PagePromptToSetPreferences;
  },
  loading: Loading,
  modules: ['PagePromptToSetPreferences'],
});

export const PageOrderConfirmationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageOrderConfirmationContainer" */ 'containers/PageOrderConfirmationContainer'
    );
    return module.PageOrderConfirmationContainer;
  },
  loading: Loading,
  modules: ['PageOrderConfirmationContainer'],
});

export const PageOrderError = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageOrderError" */ 'components/pages/PageOrderError');
    return module.PageOrderError;
  },
  loading: Loading,
  modules: ['PageOrderError'],
});

export const PageCheckoutContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageCheckoutContainer" */ 'containers/PageCheckoutContainer');
    return module.PageCheckoutContainer;
  },
  loading: Loading,
  modules: ['PageCheckoutContainer'],
});

export const PageInvoiceCheckoutContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageInvoiceCheckoutContainer" */ 'containers/PageInvoiceCheckoutContainer'
    );
    return module.PageInvoiceCheckoutContainer;
  },
  loading: Loading,
  modules: ['PageInvoiceCheckoutContainer'],
});

export const PageInvoicePaymentConfirmationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageInvoiceCheckoutContainer" */ 'containers/PageInvoicePaymentConfirmationContainer'
    );
    return module.PageInvoicePaymentConfirmationContainer;
  },
  loading: Loading,
  modules: ['PageInvoicePaymentConfirmationContainer'],
});

export const PageCenterAdminInvoicePaymentConfirmationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageInvoiceCheckoutContainer" */ 'containers/PageCenterAdminInvoicePaymentConfirmationContainer'
    );
    return module.PageCenterAdminInvoicePaymentConfirmationContainer;
  },
  loading: Loading,
  modules: ['PageCenterAdminInvoicePaymentConfirmationContainer'],
});

export const PageInvoiceError = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageInvoiceError" */ 'components/pages/PageInvoiceError');
    return module.PageInvoiceError;
  },
  loading: Loading,
  modules: ['PageInvoiceError'],
});

export const PageReceiptCheckoutContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageReceiptCheckoutContainer" */ 'containers/PageReceiptCheckoutContainer'
    );
    return module.PageReceiptCheckoutContainer;
  },
  loading: Loading,
  modules: ['PageReceiptCheckoutContainer'],
});

export const PageSearchContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageSearchContainer" */ 'containers/PageSearchContainer');
    return module.PageSearchContainer;
  },
  loading: Loading,
  modules: ['PageSearchContainer'],
});

export const PageFeatureToggleContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageFeatureToggleContainer" */ 'containers/PageFeatureToggleContainer'
    );
    return module.PageFeatureToggleContainer;
  },
  loading: Loading,
  modules: ['PageFeatureToggleContainer'],
});

export const ModalTopicUnfollowContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "ModalTopicUnfollowContainer" */ 'containers/ModalTopicUnfollowContainer'
    );
    return module.ModalTopicUnfollowContainer;
  },
  loading: Loading,
  modules: ['ModalTopicUnfollowContainer'],
});

export const PageToolkitContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageToolkitContainer" */ 'containers/PageToolkitContainer');
    return module.PageToolkitContainer;
  },
  loading: Loading,
  modules: ['PageToolkitContainer'],
});

export const PageProductContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageProductContainer" */ 'containers/PageProductContainer');
    return module.PageProductContainer;
  },
  loading: Loading,
  modules: ['PageProductContainer'],
});

export const PageCartContainer = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageCartContainer" */ 'containers/PageCartContainer');
    return module.PageCartContainer;
  },
  loading: Loading,
  modules: ['PageCartContainer'],
});

export const ModalPaymentProcessingContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "ModalPaymentProcessingContainer" */ 'containers/ModalPaymentProcessingContainer'
    );
    return module.ModalPaymentProcessingContainer;
  },
  loading: Loading,
  modules: ['ModalPaymentProcessingContainer'],
});

export const ModalAddressValidationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "ModalAddressValidationContainer" */ 'containers/ModalAddressValidationContainer'
    );
    return module.ModalAddressValidationContainer;
  },
  loading: Loading,
  modules: ['ModalAddressValidationContainer'],
});

export const ProductAccessError = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "ProductAccessError" */ 'components/pages/ProductAccessError');
    return module.ProductAccessError;
  },
  loading: Loading,
  modules: ['ProductAccessError'],
});

export const PageProductAggregationContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageProductAggregationContainer" */ 'containers/PageProductAggregationContainer'
    );
    return module.PageProductAggregationContainer;
  },
  loading: Loading,
  modules: ['PageProductAggregationContainer'],
});

export const PageProductCalendarContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageProductCalendarContainer" */ 'containers/PageProductCalendarContainer'
    );
    return module.PageProductCalendarContainer;
  },
  loading: Loading,
  modules: ['PageProductCalendarContainer'],
});

export const ModalResetImpersonatedUserPasswordContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "ModalResetImpersonatedUserPasswordContainer" */ 'containers/ModalResetImpersonatedUserPasswordContainer'
    );
    return module.ModalResetImpersonatedUserPasswordContainer;
  },
  loading: Loading,
  modules: ['ModalResetImpersonatedUserPassword'],
});

export const PageStaticLandingContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageStaticLandingContainer" */ 'containers/PageStaticLandingContainer'
    );
    return module.PageStaticLandingContainer;
  },
  loading: Loading,
  modules: ['PageStaticLandingContainer'],
});

export const PageMembershipApplication = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageMembershipApplication" */ 'components/pages/PageMembershipApplication'
    );
    return module.PageMembershipApplication;
  },
  loading: Loading,
  modules: ['PageMembershipApplication'],
});

export const FcmaPageCredentialApplication = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "FcmaPageCredentialApplication" */ 'components/pages/FcmaPageCredentialApplication'
    );
    return module.FcmaPageCredentialApplication;
  },
  loading: Loading,
  modules: ['FcmaPageCredentialApplication'],
});

export const PageCenterMembershipApplication = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCenterMembershipApplication" */ 'components/pages/PageCenterMembershipApplication'
    );
    return module.PageCenterMembershipApplication;
  },
  loading: Loading,
  modules: ['PageCenterMembershipApplication'],
});

export const PageMembershipPackage = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageMembershipPackage" */ 'components/pages/PageMembershipPackage'
    );
    return module.PageMembershipPackage;
  },
  loading: Loading,
  modules: ['PageMembershipPackage'],
});

export const PageCenterMembershipPackage = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCenterMembershipPackage" */ 'components/pages/PageCenterMembershipPackage'
    );
    return module.PageCenterMembershipPackage;
  },
  loading: Loading,
  modules: ['PageCenterMembershipPackage'],
});

export const PageCimaMembershipPackage = Loadable({
  loader: async () => {
    const module = await import('components/pages/PageCimaMembershipPackage');
    return module.PageCimaMembershipPackage;
  },
  loading: Loading,
  modules: ['PageCimaMembershipPackage'],
});

export const PageCimaMembershipStudyPath = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCimaMembershipStudyPath" */ 'components/pages/PageCimaMembershipStudyPath/PageCimaMembershipStudyPath'
    );
    return module.PageCimaMembershipStudyPath;
  },
  loading: Loading,
  modules: ['PageCimaMembershipStudyPath'],
});

export const PageCimaMembershipExemptionContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCimaMembershipExemption" */ 'containers/CimaMembershipStudypath/PageCimaMembershipExemptionContainer'
    );
    return module.PageCimaMembershipExemptionContainer;
  },
  loading: Loading,
  modules: ['PageCimaMembershipExemptionContainer'],
});

export const CimaExemptionCalculatorContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "CimaExemptionCalculatorContainer" */ 'containers/CimaExemptionCalculatorContainer'
    );
    return module.CimaExemptionCalculatorContainer;
  },
  loading: Loading,
  modules: ['CimaExemptionCalculatorContainer'],
});

export const PageCimaMembershipExamFileUpload = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCimaMembershipExamFileUpload" */ 'components/pages/PageCimaMembershipExamFileUpload/PageCimaMembershipExamFileUpload'
    );
    return module.PageCimaMembershipExamFileUpload;
  },
  loading: Loading,
  modules: ['PageCimaMembershipExamFileUpload'],
});

export const PageMembershipBenefitsContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageMembershipBenefitsContainer" */ 'containers/PageMembershipBenefitsContainer'
    );
    return module.PageMembershipBenefitsContainer;
  },
  loading: Loading,
  modules: ['PageMembershipBenefitsContainer'],
});

export const PageMemberCredentialDirectoryContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageMemberCredentialDirectoryContainer" */ 'containers/PageMemberCredentialDirectoryContainer'
    );
    return module.PageMemberCredentialDirectoryContainer;
  },
  loading: Loading,
  modules: ['PageMemberCredentialDirectoryContainer'],
});

export const PageMemberCredentialDirectoryResultsContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageMemberCredentialDirectoryResultsContainer" */ 'containers/PageMemberCredentialDirectoryResultsContainer'
    );
    return module.PageMemberCredentialDirectoryResultsContainer;
  },
  loading: Loading,
  modules: ['PageMemberCredentialDirectoryResultsContainer'],
});

export const PageCredentialsApplication = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCredentialsSelectionContainer" */ 'components/pages/PageCredentialsApplication'
    );
    return module.PageCredentialsApplication;
  },
  loading: Loading,
  modules: ['PageCredentialsApplication'],
});

export const PageMembershipInviteThankyouContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageMembershipInviteThankyouContainer" */ 'components/pages/PageMembershipInviteThankyouContainer'
    );
    return module.PageMembershipInviteThankyouContainer;
  },
  loading: Loading,
  modules: ['PageMembershipInviteThankyouContainer'],
});

export const PageCimaExamsDashboard = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCimaExamsDashboard" */ 'components/pages/PageCimaExamsDashboard/PageCimaExam'
    );
    return module.PageCimaExam;
  },
  loading: Loading,
  modules: ['PageCimaExamsDashboard'],
});

export const PageCimaExamsExemptions = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCimaExamsExemptions" */ 'components/pages/PageCimaExamsExemptions'
    );
    return module.PageCimaExamsExemptions;
  },
  loading: Loading,
  modules: ['PageCimaExamsExemptions'],
});

export const PageCimaExamsGateway = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "PageCimaExamsGateway" */ 'components/pages/PageCimaExamsGateway');
    return module.PageCimaExamsGateway;
  },
  loading: Loading,
  modules: ['PageCimaExamsGateway'],
});

export const PageCimaExamResultsContainer = Loadable({
  loader: async () => {
    const module = await import('containers/PageCimaExamResultsContainer');
    return module.PageCimaExamResultsContainer;
  },
  loading: Loading,
  modules: ['PageCimaExamResultsContainer'],
});

export const PagePracticalExperienceRequirement = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageCredentialsSelectionContainer" */ 'components/pages/PagePracticalExperienceRequirement'
    );
    return module.PagePracticalExperienceRequirement;
  },
  loading: Loading,
  modules: ['PagePracticalExperienceRequirement'],
});

export const PageTuitionProviderDetails = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageTuitionProvider" */ 'components/pages/PageTuitionProvider/PageTuitionProviderDetails'
    );
    return module.PageTuitionProviderDetails;
  },
  loading: Loading,
  modules: ['PageTuitionProviderDetails'],
});

export const PageTuitionProviderApprenticeRoster = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageTuitionProvider" */ 'components/pages/PageTuitionProvider/PageTuitionProviderApprenticeRoster'
    );
    return module.PageTuitionProviderApprenticeRoster;
  },
  loading: Loading,
  modules: ['PageTuitionProviderApprenticeRoster'],
});

export const PageTuitionProvider = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageTuitionProvider" */ 'components/pages/PageTuitionProvider/PageTuitionProvider'
    );
    return module.PageTuitionProvider;
  },
  loading: Loading,
  modules: ['PageTuitionProvider'],
});

export const PageCimaMembershipPromoCodeContainer = Loadable({
  loader: async () => {
    const module = await import('containers/PageCimaMembershipPromoCodeContainer');
    return module.PageCimaMembershipPromoCodeContainer;
  },
  loading: Loading,
  modules: ['PageCimaMembershipPromoCodeContainer'],
});

export const PageEventRegistrationContainer = Loadable({
  loader: async () => {
    const module = await import('containers/EventRegistrationContainer');
    return module.EventRegistrationContainer;
  },
  loading: Loading,
  modules: ['EventRegistrationContainer'],
});

export const PageCimaMembershipDocumentUpload = Loadable({
  loader: async () => {
    const module = await import('components/pages/PageCimaMembershipDocumentUpload/PageCimaMembershipDocumentUpload');
    return module.PageCimaMembershipDocumentUpload;
  },
  loading: Loading,
  modules: ['PageCimaMembershipDocumentUpload'],
});

export const PageConfirmationBenefitsContainer = Loadable({
  loader: async () => {
    const module = await import(
      /* webpackChunkName: "PageConfirmationBenefitsContainer" */ 'containers/PageConfirmationBenefitsContainer'
    );
    return module.PageConfirmationBenefitsContainer;
  },
  loading: Loading,
  modules: ['PageConfirmationBenefitsContainer'],
});

export const PageMipContactDetails = Loadable({
  loader: async () => {
    const module = await import('components/pages/PageMipContactDetails/PageMipContactDetails');
    return module.PageMipContactDetails;
  },
  loading: Loading,
  modules: ['PageMipContactDetails'],
});

export const PageMipWorkHistory = Loadable({
  loader: async () => {
    const module = await import('components/pages/PageMipWorkHistory/PageMipWorkHistory');
    return module.PageMipWorkHistory;
  },
  loading: Loading,
  modules: ['PageMipWorkHistory'],
});

export const MipPracticeRequirementsContainer = Loadable({
  loader: async () => {
    const module = await import('containers/MipPracticeRequirementsContainer');
    return module.MipPracticeRequirementsContainer;
  },
  loading: Loading,
  modules: ['MipPracticeRequirementsContainer'],
});

export const PagePostExamsSpecialConsiderationContainer = Loadable({
  loader: async () => {
    const module = await import('containers/PagePostExamsSpecialConsiderationContainer');
    return module.PagePostExamsSpecialConsiderationContainer;
  },
  loading: Loading,
  modules: ['PagePostExamsSpecialConsiderationContainer'],
});

export const MipAmlApplicationContainer = Loadable({
  loader: async () => {
    const module = await import('containers/MipAmlApplicationContainer');
    return module.MipAmlApplicationContainer;
  },
  loading: Loading,
  modules: ['MipAmlApplicationContainer'],
});

export const PageDoubleDonation = Loadable({
  loader: async () => {
    const module = await import('containers/DoubleDonation/DoubleDonationContainer');
    return module.DoubleDonationContainer;
  },
  loading: Loading,
  modules: ['DoubleDonationContainer'],
});

export const PageMipCheckout = Loadable({
  loader: async () => {
    const module = await import('components/pages/PageMipCheckout/PageMipCheckout');
    return module.PageMipCheckout;
  },
  loading: Loading,
  modules: ['PageMipCheckout'],
});

export const MipMainApplicationContainer = Loadable({
  loader: async () => {
    const module = await import('containers/MipMainApplicationContainer');
    return module.MipAmlApplicationContainer;
  },
  loading: Loading,
  modules: ['MipMainApplicationContainer'],
});

export const PageMipRenewalMainApplication = Loadable({
  loader: async () => {
    const module = await import('components/pages/PageRenewalMainApplication/PageRenewalMainApplication');
    return module.PageRenewalMainApplication;
  },
  loading: Loading,
  modules: ['PageRenewalMainApplication'],
});

export const PageMipRenewalPracticeRequirements = Loadable({
  loader: async () => {
    const module = await import(
      'components/pages/PageMipRenewalPracticeRequirements/PageMipRenewalPracticeRequirements'
    );
    return module.PageMipRenewalPracticeRequirements;
  },
  loading: Loading,
  modules: ['PageMipRenewalPracticeRequirements'],
});

export const PagePreSignUp = Loadable({
  loader: async () => {
    const module = await import('components/pages/PagePreSignUp/PagePreSignUp');
    return module.PagePreSignUp;
  },
  loading: Loading,
  modules: ['PagePreSignUp'],
});

export const PageFirmSelectionContainer = Loadable({
  loader: async () => {
    const module = await import('containers/PageFirmSelectionContainer');
    return module.PageFirmSelectionContainer;
  },
  loading: Loading,
  modules: ['PageFirmSelectionContainer'],
});

export const PageTuitionProviderSelectionContainer = Loadable({
  loader: async () => {
    const module = await import('containers/PageTuitionProviderSelectionContainer');
    return module.PageTuitionProviderSelectionContainer;
  },
  loading: Loading,
  modules: ['PageTuitionProviderSelectionContainer'],
});

export const PaywallNotificationContainer = Loadable({
  loader: async () => {
    const module = await import('containers/PaywallNotificationContainer');
    return module.PaywallNotificationContainer;
  },
  loading: Loading,
  modules: ['PaywallNotificationContainer'],
});

export const PageCenterAdminSeatsManagementContainer = Loadable({
  loader: async () => {
    const module = await import('containers/PageCenterAdminSeatsManagementContainer');
    return module.PageCenterAdminSeatsManagementContainer;
  },
  loading: Loading,
  modules: ['PageCenterAdminSeatsManagementContainer'],
});

export const SlideMobileHeaderMenuContainer = Loadable({
  loader: async () => {
    const module = await import('containers/SlideMobileHeaderMenuContainer');
    return module.SlideMobileHeaderMenuContainer;
  },
  loading: Loading,
  modules: ['SlideMobileHeaderMenuContainer'],
});

export const StepPaymentContainer = Loadable({
  loader: async () => {
    const module = await import('containers/StepPaymentContainer');
    return module.StepPaymentContainer;
  },
  loading: Loading,
  modules: ['StepPaymentContainer'],
});

export const StepPaymentEckohContainer = Loadable({
  loader: async () => {
    const module = await import('containers/StepPaymentEckohContainer');
    return module.StepPaymentEckohContainer;
  },
  loading: Loading,
  modules: ['StepPaymentEckohContainer'],
});

export const StepPaymentInvoiceContainer = Loadable({
  loader: async () => {
    const module = await import('containers/StepPaymentInvoiceContainer');
    return module.StepPaymentInvoiceContainer;
  },
  loading: Loading,
  modules: ['StepPaymentInvoiceContainer'],
});

export const StepPaymentReceiptContainer = Loadable({
  loader: async () => {
    const module = await import('containers/StepPaymentReceiptContainer');
    return module.StepPaymentReceiptContainer;
  },
  loading: Loading,
  modules: ['StepPaymentReceiptContainer'],
});

export const SubscriptionToggleContainer = Loadable({
  loader: async () => {
    const module = await import('containers/SubscriptionToggleContainer');
    return module.SubscriptionToggleContainer;
  },
  loading: Loading,
  modules: ['SubscriptionToggleContainer'],
});

export const SubskillsSlideInModalContainer = Loadable({
  loader: async () => {
    const module = await import('containers/SubskillsSlideInModalContainer');
    return module.SubskillsSlideInModalContainer;
  },
  loading: Loading,
  modules: ['SubskillsSlideInModalContainer'],
});

export const SubtopicsSlideInModalContainer = Loadable({
  loader: async () => {
    const module = await import('containers/SubtopicsSlideInModalContainer');
    return module.SubtopicsSlideInModalContainer;
  },
  loading: Loading,
  modules: ['SubtopicsSlideInModalContainer'],
});

export const TabCenterAdminInfoContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabCenterAdminInfoContainer');
    return module.TabCenterAdminInfoContainer;
  },
  loading: Loading,
  modules: ['TabCenterAdminInfoContainer'],
});

export const TabCenterAdminMembershipsInvoicesContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabCenterAdminMembershipsInvoicesContainer');
    return module.TabCenterAdminMembershipsInvoicesContainer;
  },
  loading: Loading,
  modules: ['TabCenterAdminMembershipsInvoicesContainer'],
});

export const TabCenterAdminMembershipsManageContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabCenterAdminMembershipsManageContainer');
    return module.TabCenterAdminMembershipsManageContainer;
  },
  loading: Loading,
  modules: ['TabCenterAdminMembershipsManageContainer'],
});

export const TabFirmBillingFlpOrganizationContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabFirmBillingFlpOrganizationContainer');
    return module.TabFirmBillingFlpOrganizationContainer;
  },
  loading: Loading,
  modules: ['TabFirmBillingFlpOrganizationContainer'],
});

export const TabFirmBillingInvoicesContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabFirmBillingInvoicesContainer');
    return module.TabFirmBillingInvoicesContainer;
  },
  loading: Loading,
  modules: ['TabFirmBillingInvoicesContainer'],
});

export const TabFirmBillingManageContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabFirmBillingManageContainer');
    return module.TabFirmBillingManageContainer;
  },
  loading: Loading,
  modules: ['TabFirmBillingManageContainer'],
});

export const TabFirmBillingMembershipAddonsContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabFirmBillingMembershipAddonsContainer');
    return module.TabFirmBillingMembershipAddonsContainer;
  },
  loading: Loading,
  modules: ['TabFirmBillingMembershipAddonsContainer'],
});

export const TabFirmInfoBasicCompanyDetailsContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabFirmInfoBasicCompanyDetailsContainer');
    return module.TabFirmInfoBasicCompanyDetailsContainer;
  },
  loading: Loading,
  modules: ['TabFirmInfoBasicCompanyDetailsContainer'],
});

export const TabFirmRosterFlpUpgradesInvitesModalContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabFirmRosterFlpUpgradesInvitesModalContainer');
    return module.TabFirmRosterFlpUpgradesInvitesModalContainer;
  },
  loading: Loading,
  modules: ['TabFirmRosterFlpUpgradesInvitesModalContainer'],
});

export const TabFirmRosterManageContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabFirmRosterManageContainer');
    return module.TabFirmRosterManageContainer;
  },
  loading: Loading,
  modules: ['TabFirmRosterManageContainer'],
});

export const TabFirmRosterMembersInvitesContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabFirmRosterMembersInvitesContainer');
    return module.TabFirmRosterMembersInvitesContainer;
  },
  loading: Loading,
  modules: ['TabFirmRosterMembersInvitesContainer'],
});

export const TabFirmRosterUpgradesInvitesModalContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabFirmRosterUpgradesInvitesModalContainer');
    return module.TabFirmRosterUpgradesInvitesModalContainer;
  },
  loading: Loading,
  modules: ['TabFirmRosterUpgradesInvitesModalContainer'],
});

export const TabFlpSubscriptionsContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabFlpSubscriptionsContainer');
    return module.TabFlpSubscriptionsContainer;
  },
  loading: Loading,
  modules: ['TabFlpSubscriptionsContainer'],
});

export const TabTuitionProviderInfoContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TabTuitionProviderInfoContainer');
    return module.TabTuitionProviderInfoContainer;
  },
  loading: Loading,
  modules: ['TabTuitionProviderInfoContainer'],
});

export const TellUsSurveyContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TellUsSurveyContainer');
    return module.TellUsSurveyContainer;
  },
  loading: Loading,
  modules: ['TellUsSurveyContainer'],
});

export const TuitionProviderHeaderContainer = Loadable({
  loader: async () => {
    const module = await import('containers/TuitionProviderHeaderContainer');
    return module.TuitionProviderHeaderContainer;
  },
  loading: Loading,
  modules: ['TuitionProviderHeaderContainer'],
});

export const UserWidgetContainer = Loadable({
  loader: async () => {
    const module = await import('containers/UserWidgetContainer');
    return module.UserWidgetContainer;
  },
  loading: Loading,
  modules: ['UserWidgetContainer'],
});

export const InquiryFormBulkOrderContainer = Loadable({
  loader: async () => {
    const module = await import('containers/InquiryFormBulkOrderContainer');
    return module.InquiryFormBulkOrderContainer;
  },
  loading: Loading,
  modules: ['InquiryFormBulkOrderContainer'],
});
