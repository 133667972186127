import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { membershipActionNames } from 'modules/membership/constants';
import { setProfessionalQualification, setSelectedPassedUSCPAExam, fetchProfessionalQualifications } from './action';
import { isServer } from 'utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [setProfessionalQualification]: {
    next: (state: State.ExemptionProfessional, action: any): State.ExemptionProfessional => ({
      ...state,
      exempReference: action.payload.id,
      exemptReferenceFetched: true,
      exemptReferenceFrontloadRequest: isServer,
    }),
  },
  [setSelectedPassedUSCPAExam]: {
    next: (state: State.ExemptionProfessional, action: any): State.ExemptionProfessional => ({
      ...state,
      selectedPassedUSCPAExam: action.payload.selectedPassedUSCPAExam || '',
    }),
  },
  [membershipActionNames.GET_PERSON_LEVEL_EXEMPTION]: {
    next: (state: State.ExemptionProfessional, action: any): State.ExemptionProfessional => ({
      ...state,
      selectedPassedUSCPAExam: state.selectedPassedUSCPAExam || action.payload.selectedPassedUSCPAExam,
    }),
  },

  [fetchProfessionalQualifications]: {
    next: (state: State.ExemptionProfessional, action: any): State.ExemptionProfessional => ({
      ...state,
      allProfessionalExperienceReference: action.payload.getExemption,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
