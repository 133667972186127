import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  children: any;
  type: string;
  isSearchResult?: boolean;
  isFree?: boolean;
  className?: string;
  onClick?(): void;
  shouldIndent?: boolean;
}

export const CardOutlay: React.FC<Props> = ({
  children,
  type,
  onClick,
  isSearchResult,
  isFree,
  className = '',
  shouldIndent = true,
}) => {
  const isProduct = type === 'product';
  const isProductHeaderCustomize = type === 'customize-header';
  const isCimaHeaderCustomize = type === 'cima-header';
  return (
    <StyledCardOutlay
      className={className}
      data-testid="card-outlay"
      isProduct={isProduct}
      isProductHeaderCustomize={isProductHeaderCustomize}
      isCimaHeaderCustomize={isCimaHeaderCustomize}
      onClick={onClick}
      isSearchResult={isSearchResult}
    >
      <StyledBody
        isProduct={isProduct}
        isCimaHeaderCustomize={isCimaHeaderCustomize}
        isProductHeaderCustomize={isProductHeaderCustomize}
        isFree={isFree}
        shouldIndent={shouldIndent}
      >
        {children}
      </StyledBody>
    </StyledCardOutlay>
  );
};

interface StyledCardProps {
  isProductHeaderCustomize: boolean;
  isProduct: boolean;
  isSearchResult?: boolean;
  isFree?: boolean;
  isCimaHeaderCustomize?: boolean;
  shouldIndent?: boolean;
}

const StyledCardOutlay = styled.div<StyledCardProps>`
  width: 100%;
  margin-bottom: ${props => props.theme.pxToRem(20)};
  ${props =>
    props.isProduct &&
    props.isSearchResult &&
    !props.isProductHeaderCustomize &&
    css`
      ${props.theme.mediaQueries.mobileOnly} {
        padding-bottom: ${props.theme.pxToRem(28)};
      }
    `}

  ${props =>
    props.isProduct &&
    props.isSearchResult &&
    !props.isProductHeaderCustomize &&
    css`
      & + &&&,
      &:first-child {
        border-top: none;
      }
    `}

  ${props =>
    props.isProduct &&
    !props.isSearchResult &&
    !props.isProductHeaderCustomize &&
    css`
      & {
        padding: ${props.theme.pxToRem(28)} 0;
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3};
        border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3};
      }
      & + &&&,
      &:first-child {
        border-top: none;
      }
    `}
  
  ${props =>
    props.isProductHeaderCustomize &&
    !props.isSearchResult &&
    !props.isProduct &&
    css`
      & {
        padding: 0.7rem 0;
        border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3};
      }
      & + &&&,
      &:first-child {
        border-top: none;
      }
    `}

  ${props =>
    !props.isProduct &&
    !props.isProductHeaderCustomize &&
    !props.isSearchResult &&
    css`
      ${props.theme.mediaQueries.mobileOnly} {
        border-radius: ${props.theme.pxToRem(1)};
        box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
        background-color: ${props.theme.colors.neutralWhite};
      }
      ${props.isCimaHeaderCustomize &&
      `
        font-weight: ${props.theme.fontWeights.medium};
        ${props.theme.mediaQueries.mobileOnly} {
          background-color: none;
          box-shadow: none;
        }
      `}
    `}

  ${props =>
    props.isSearchResult &&
    css`
       {
        border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3};
        padding: ${props.theme.pxToRem(20)} 0 ${props.theme.pxToRem(28)} 0;
      }
    `}

    ${props =>
    props.isCimaHeaderCustomize &&
    css`
      margin-bottom: ${props.theme.pxToRem(10)};
    `}

  &:first-child {
    margin-top: 0;
  }
`;

const StyledBody = styled.div<StyledCardProps>`
  position: relative;
  overflow: hidden;
  ${props =>
    props.isProduct &&
    css`
      display: flex;
      ${props.theme.mediaQueries.mobileOnly} {
        flex-direction: column;
      }
    `}

  ${props =>
    props.isProductHeaderCustomize &&
    css`
      display: flex;
      width: 100%;
      text-align: left;
      height: ${props.theme.pxToRem(45)};
      ${props.theme.mediaQueries.mobileOnly} {
        flex-direction: column;
      }
    `}

  ${props =>
    props.isProductHeaderCustomize &&
    (props.isFree
      ? css`
          color: ${props.theme.colors.neutralWhite};
        `
      : css`
          color: ${props.theme.colors.neutralGrey8};
        `)}
  
    ${props =>
    props.isCimaHeaderCustomize &&
    css`
      display: flex;
      width: 100%;
      text-align: left;
      padding-left: ${props.theme.pxToRem(props.shouldIndent ? 10 : 0)};
    `}
`;
