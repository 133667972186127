import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Contentful, MembershipTypes, Product } from 'mxp-schemas';
import { MembershipPackageAccordionStatus, Routes } from '../../../constants';
import { MembershipTypeSelection } from 'components/molecules/MembershipTypeSelection/MembershipTypeSelection';
import {
  setMembershipPackageType,
  checkIfCredentialEligibleForMembership,
  checkSelectedSection,
  setMembershipPackageTier,
  setMembershipJourneyType,
  setCenterMembershipPackageType,
  fetchMembershipTiers,
} from 'modules/membership';
import { getMembershipPackagePath, getCenterMembershipPackagePath, getPath, isMobileViewPort } from 'utils';
import { Grid } from 'semantic-ui-react';
import { Utils } from 'mxp-utils';

import { ModalUpgradeMembership } from '../ModalUpgradeMembership/ModalUpgradeMembership';

import {
  membershipEligibleCredentialSelector,
  membershipEligibleSectionSelector,
  membershipProductListDataSelector,
} from 'modules/membership/selectors';
import { isUserMemberSelector, isAuthSelector, isUserMemberSuspendedSelector } from 'modules/user/selectors';
import { productProductListDataSelector, productsListDataFetchedSelector } from 'modules/products/selectors';
import { getProductsListData } from 'modules/products/actions';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';

interface Props {
  modularBlock: Contentful.ModularContent.MembershipProductBlock;
  isFullPagePagination: boolean;
  benefitBlock?: Contentful.ModularContent.BenefitsBlock;
}

export const BlockMembershipProducts: React.FC<Props> = ({ modularBlock, isFullPagePagination, benefitBlock }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = isMobileViewPort();
  const location = useLocation();

  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const eligibleCredential = useSelector(membershipEligibleCredentialSelector);
  const eligibleSection = useSelector(membershipEligibleSectionSelector);
  const isUserMember = useSelector(isUserMemberSelector);
  const isUserMemberSuspended = useSelector(isUserMemberSuspendedSelector);
  const membershipProductOnProducts = useSelector(productProductListDataSelector);
  const membershipProductOnMemberships = useSelector(membershipProductListDataSelector);
  const isAuth = useSelector(isAuthSelector);
  const productsListDataFetched = useSelector(productsListDataFetchedSelector);
  const { useNewSignUpOnly } = useSelector(featureTogglesSelector);
  const isCimaMembershipPackagePage = location?.pathname === MembershipTypes.MembershipRoute.PACKAGE;

  const userMembershipProduct = useMemo(() => {
    return !!membershipProductOnMemberships?.length ? membershipProductOnMemberships : membershipProductOnProducts;
  }, [membershipProductOnMemberships, membershipProductOnProducts]);

  useEffect(
    () => {
      if (Boolean(eligibleCredential.tier)) {
        if (eligibleCredential.tier === 'not eligible' && !eligibleCredential.eligible) {
          setUpgradeModalOpen(!upgradeModalOpen);
        } else {
          (async () => {
            await dispatch(setMembershipPackageTier(eligibleCredential.tier));
          })();
        }
      }
    },
    [eligibleCredential, setUpgradeModalOpen, dispatch, history] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    (async () => {
      // This should only trigger when the user is logged in
      if (isAuth && !membershipProductOnProducts?.length && !productsListDataFetched) {
        await dispatch(getProductsListData());
      }
    })();
  }, [dispatch, membershipProductOnProducts, productsListDataFetched, isAuth]);

  useEffect(() => {
    if (Boolean(eligibleSection.tier)) {
      if (eligibleSection.tier !== 'not eligible' && eligibleSection.eligible) {
        history.push(getPath(Routes.MEMBERSHIP_BENEFIT));
      }
    }
  }, [eligibleSection, history]);

  const pathForMobile = useCallback(
    (nextDirectory: string) => {
      const shouldNotAllowToProceedForMobile = isMobile && useNewSignUpOnly && !isCimaMembershipPackagePage;
      return shouldNotAllowToProceedForMobile ? getPath(Routes.PRE_SIGN_UP) : nextDirectory;
    },
    [isCimaMembershipPackagePage, isMobile, useNewSignUpOnly]
  );

  const navigateToPackageBuilder = useCallback(
    async (id: string, slug: string, productType: string[], isVariant: boolean) => {
      if (!isAuth) {
        if (productType[0] === Product.ProductType.CENTER_MEMBERSHIP) {
          dispatch(setMembershipJourneyType(MembershipTypes.MembershipJourneyType.CENTER_MEMBERSHIP_SIGN_UP));
        } else {
          history.push(getCenterMembershipPackagePath(MembershipPackageAccordionStatus.Type));
        }
      }

      if ((!!productType.length && productType[0] === Product.ProductType.SECTION) || slug.indexOf('Section') !== -1) {
        // section selection
        if (isUserMember) {
          await dispatch(checkSelectedSection(userMembershipProduct, slug));
        }

        history.push(pathForMobile(getMembershipPackagePath(MembershipPackageAccordionStatus.Sections)));
      } else if ((!!productType.length && productType[0] === Product.ProductType.CREDENTIAL) || isVariant) {
        // credential selection, apparently credential variants can also be added to this
        if (userMembershipProduct && !!userMembershipProduct.length) {
          await dispatch(
            checkIfCredentialEligibleForMembership(userMembershipProduct, slug, productType[0], isVariant)
          );
        }

        history.push(pathForMobile(getMembershipPackagePath(MembershipPackageAccordionStatus.Credentials)));
      } else {
        // must be a membership product
        await dispatch(setMembershipPackageType(id, slug));
        if (productType[0] === Product.ProductType.CENTER_MEMBERSHIP) {
          history.push(getCenterMembershipPackagePath(MembershipPackageAccordionStatus.Type));
          dispatch(setCenterMembershipPackageType(id, slug));
          dispatch(fetchMembershipTiers(true, slug));
          // history.push(getCenterMembershipPackagePath(MembershipPackageAccordionStatus.Organization));
        } else {
          if ((userMembershipProduct && !!userMembershipProduct.length) || (id && slug)) {
            history.push(pathForMobile(getMembershipPackagePath(MembershipPackageAccordionStatus.Tier)));
            return;
          }

          history.push(pathForMobile(getMembershipPackagePath(MembershipPackageAccordionStatus.Type)));
        }
      }
    },
    [dispatch, history, userMembershipProduct, isUserMember, isAuth, pathForMobile]
  );

  const isConsistCenterProduct = modularBlock?.cmsProducts?.some((cmsProduct: any) =>
    cmsProduct?.productType?.includes(Product.ProductType.CENTER_MEMBERSHIP)
  );

  return (
    <div id="products">
      <StyledGrid isFullPagePagination={isFullPagePagination}>
        <ModalUpgradeMembership visible={upgradeModalOpen} setVisibility={setUpgradeModalOpen} />
        <Grid.Column>
          <StyledHeader>{modularBlock.header}</StyledHeader>
          <StyledText>{Utils.stripHtml(modularBlock.description)}</StyledText>
          <StyledContainer>
            <MembershipTypeSelection
              isUserMember={isUserMember}
              products={modularBlock.cmsProducts}
              selectMembership={navigateToPackageBuilder}
              isPropPage={true}
              benefitsListDescription={benefitBlock?.description}
              isUserMemberSuspended={isUserMemberSuspended}
              isConsistCenterProduct={isConsistCenterProduct}
            />
          </StyledContainer>
        </Grid.Column>
      </StyledGrid>
    </div>
  );
};

const StyledHeader = styled.h3<any>`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.35;
  text-align: center;
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xl};
  }
`;

const StyledText = styled.div`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
  text-align: center;
  margin: ${props => `${props.theme.pxToRem(24)} auto ${props.theme.pxToRem(60)} auto`};
  width: ${props => props.theme.pxToRem(621)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
    width: auto;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const StyledGrid = styled(Grid)`
  &&.ui.grid {
    margin-left: auto;
    margin-right: auto;
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
    }
  }
`;
