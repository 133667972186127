import React, { useState, Dispatch, SetStateAction } from 'react';
import { Modal, Button, ButtonVariants, RadioButton } from 'components/atoms';
import styled from 'styled-components';
import { User } from 'mxp-schemas';

interface Props {
  showFiveYearsInCandidateModal: boolean;
  onClose: () => void;
  membershipTypes: any[];
  setSelectedProduct: Dispatch<SetStateAction<string>>;
}

const MembershipTypeOptions = [
  { text: 'Upgrade my current membership', activeType: User.MembershipTypes.REGULAR },
  { text: 'Renew my candidate membership', activeType: User.MembershipTypes.CANDIDATE },
];

export const ModalFiveYearsInCandidate: React.FC<Props> = ({
  showFiveYearsInCandidateModal,
  onClose,
  setSelectedProduct,
  membershipTypes,
}) => {
  const [selectedType, setSelectedType] = useState(MembershipTypeOptions[0].activeType);
  const [membershipType, setMembershipType] = useState(membershipTypes?.[0]);

  const handleChangeOption = (activeType: User.MembershipTypes) => (event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedType(activeType);
    setMembershipType(membershipTypes.find(item => item.slug === activeType));
  };

  const handleConfirmButton = React.useCallback(() => {
    if (membershipType && membershipType.slug) {
      setSelectedProduct(selectedType);
    }
    onClose();
  }, [membershipType, onClose, selectedType, setSelectedProduct]);

  return (
    <StyledModal
      open={showFiveYearsInCandidateModal}
      showCloseCross={true}
      onClose={onClose}
      size="tiny"
      heading={'You are ready to upgrade your membership type'}
      confirmNode={
        <StyledButton
          testId="five-years-candidate-button"
          variant={ButtonVariants.primary}
          onClick={handleConfirmButton}
        >
          Confirm and Continue
        </StyledButton>
      }
      testId="preselected-membership-type"
    >
      <StyledText>We recommend you to upgrade your membership to an Affiliate or Regular membership.</StyledText>
      {MembershipTypeOptions.map(({ text, activeType }) => (
        <StyledDiv key={activeType} onClick={handleChangeOption(activeType)}>
          <StyledRadioButton checked={activeType === selectedType} />
          <StyleName>{text}</StyleName>
        </StyledDiv>
      ))}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &&&&& {
    width: ${props => props.theme.pxToRem(590)};
    height: fit-content;
    border-radius: 0px;

    h2 {
      font-size: ${props => props.theme.pxToRem(32)};
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 95%;
    }

    > .content {
      padding-bottom: ${props => props.theme.pxToRem(10)} !important;
    }
  }
`;

const StyledText = styled.p`
  &&&& {
    text-align: center;
    line-height: 1.57;
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.pxToRem(18)};
    padding: 0 ${props => props.theme.pxToRem(40)} 0 ${props => props.theme.pxToRem(20)} !important;
  }
`;

const StyledButton = styled(Button)`
  &&&&& {
    border-radius: 0px;
    width: ${props => props.theme.pxToRem(170)};
    height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledRadioButton = styled(RadioButton)`
  margin-bottom: ${props => props.theme.pxToRem(12)};
  margin-right: ${props => props.theme.pxToRem(10)};
  float: left;

  :hover {
    cursor: pointer;
  }
`;

const StyleName = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(12)};
  font-size: ${props => props.theme.pxToRem(17)};
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.pxToRem(4)};
  transform: translateY(40%);
  padding-left: ${props => props.theme.pxToRem(130)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: ${props => props.theme.pxToRem(20)};
  }
`;
