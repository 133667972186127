import React, { useState } from 'react';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment-timezone';
import { MomentHelpers } from 'utils/MomentHelpers';
import { OnlyDesktop, OnlyMobile } from 'components/atoms';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { FilterNames } from 'constants/index';
import { isServer } from 'utils';
import { ReactComponent as ArrowRight, ReactComponent as ArrowLeft } from 'resources/images/icon-arrow-up.svg';

export interface DateFilterItemProps {
  name: string;
  id: string;
  resultCount: number;
}

interface DateFilterProps {
  selectedStartDate?: string;
  selectedEndDate?: string;
  items?: DateFilterItemProps[];
  handleChange?: (name: string, value: string) => void;
  onMonthChanged?: (month: moment.Moment) => void;
  className?: string;
  numberOfMonths?: number;
  hideTitle?: boolean;
  hideLegend?: boolean;
  eventsPage?: boolean;
  hasPreSelectedStartDate?: boolean;
  markedDates?: string[];
  seatManagementPage?: boolean;
}

export const DateFilter: React.FC<DateFilterProps> = ({
  selectedStartDate,
  selectedEndDate,
  handleChange,
  onMonthChanged,
  className,
  items = [],
  numberOfMonths = 1,
  hideTitle = false,
  hideLegend = false,
  eventsPage = false,
  hasPreSelectedStartDate,
  markedDates,
  seatManagementPage = false,
}) => {
  const START_DATE = 'startDate';
  const END_DATE = 'endDate';
  const DATE_FORMAT = 'YYYY-MM-DD';
  const momentInstance = moment();

  const startDateMoment: moment.Moment | null = selectedStartDate
    ? MomentHelpers.convertToMoment(selectedStartDate, DATE_FORMAT)
    : null;

  const endDateMoment: moment.Moment | null = selectedEndDate
    ? MomentHelpers.convertToMoment(selectedEndDate, DATE_FORMAT)
    : null;

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(
    selectedStartDate || selectedEndDate ? END_DATE : START_DATE
  );

  const [currentMonth, setCurrentMonth] = useState<moment.Moment>(
    selectedStartDate ? (startDateMoment as moment.Moment) : momentInstance
  );

  const isBeforeThisMonth = React.useCallback(() => {
    return momentInstance.isSame(currentMonth, 'month');
  }, [currentMonth, momentInstance]);

  const arrowLeftOnClickFunc = React.useCallback(e => isBeforeThisMonth() && e.stopPropagation(), [isBeforeThisMonth]);

  const leftArrow = <StyledArrowLeft data-events-page={eventsPage} onClick={arrowLeftOnClickFunc} />;
  const rightArrow = <StyledArrowRight data-events-page={eventsPage} />;

  const handleDatesChange = React.useCallback(
    ({ startDate, endDate }: any) => {
      if (!handleChange) return;
      const startDateFormatted = startDate?.format(DATE_FORMAT) || '';
      const endDateFormatted = endDate?.format(DATE_FORMAT) || '';
      const isCalendarEmpty: boolean = !selectedStartDate;
      const isEndDateRangeValueBeforeStartDate = !endDateFormatted && !selectedEndDate;

      // startDate value selection logic
      if (isCalendarEmpty || isEndDateRangeValueBeforeStartDate) {
        handleChange(FilterNames.EVENT_START_DATE, startDateFormatted);
        return;
      }

      // startDate value selection logic
      if (!isCalendarEmpty && !selectedEndDate) {
        handleChange(FilterNames.EVENT_END_DATE, endDateFormatted);
        return;
      }

      // range in place selection logic
      handleChange(FilterNames.EVENT_START_DATE, startDateFormatted);
      handleChange(FilterNames.EVENT_END_DATE, '');
    },
    [handleChange, selectedStartDate, selectedEndDate]
  );

  const onFocusChangeFunc = React.useCallback((input: any) => {
    setFocusedInput(!input ? START_DATE : input);
  }, []);

  const isDayHighlightedFunc = React.useCallback((day: any) => day.isSame(momentInstance, 'day'), [momentInstance]);

  const isOutsideRangeFunc = React.useCallback(
    (day: moment.Moment) => {
      if (hasPreSelectedStartDate) {
        return day.isBefore(startDateMoment, 'day');
      }
      return day.isBefore(momentInstance, 'day');
    },
    [momentInstance, hasPreSelectedStartDate, startDateMoment]
  );

  const renderDayContentsFunc = React.useCallback(
    (day: moment.Moment) => {
      const isMarkDate = markedDates?.includes(moment.utc(day).format(DATE_FORMAT));
      return (
        <>
          <span className={`${!seatManagementPage && 'CalendarEventText'}`}>{day.format('D')}</span>
          {isMarkDate && <span className="CalendarEventDot" />}
          {!day.isBefore(momentInstance, 'day') &&
            items.filter(item => moment(item.name).format(DATE_FORMAT) === day.format(DATE_FORMAT)).length > 0 && (
              <span className="CalendarEventDot" />
            )}
        </>
      );
    },
    [items, momentInstance, markedDates, seatManagementPage]
  );

  const setVisibleMonthFunc = React.useCallback(
    (month: moment.Moment) => {
      setCurrentMonth(month);
      onMonthChanged?.(month);
    },
    [onMonthChanged]
  );

  const initialVisibleMonthFunc = React.useCallback(() => momentInstance, [momentInstance]);

  const initialVisibleMonthDesktopFunc = React.useCallback(
    () => startDateMoment || momentInstance,
    [startDateMoment, momentInstance]
  );

  React.useEffect(() => {
    if (startDateMoment) {
      onMonthChanged?.(startDateMoment);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDayWidthForMobile = React.useCallback((): number => {
    if (isServer) {
      return 0;
    }
    const dayWith = Math.floor((window.innerWidth - 66) / 7);
    return (dayWith > 48 ? 48 : dayWith) as number;
  }, []);
  return (
    <FilterContainer className={className} eventsPage={eventsPage} seatManagementPage={seatManagementPage}>
      {!hideTitle && <StyledTitle eventsPage={eventsPage}>Select a date or a date range</StyledTitle>}
      {!hideLegend && <StyledLegend>Events available on this day</StyledLegend>}
      <OnlyDesktop>
        <DayPickerRangeController
          initialVisibleMonth={initialVisibleMonthDesktopFunc}
          startDate={startDateMoment}
          endDate={endDateMoment}
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={onFocusChangeFunc}
          isDayHighlighted={isDayHighlightedFunc}
          numberOfMonths={numberOfMonths}
          hideKeyboardShortcutsPanel
          navNext={rightArrow}
          navPrev={leftArrow}
          daySize={31}
          noBorder
          isOutsideRange={isOutsideRangeFunc}
          renderDayContents={renderDayContentsFunc}
          onPrevMonthClick={setVisibleMonthFunc}
          onNextMonthClick={setVisibleMonthFunc}
          transitionDuration={eventsPage ? 0 : 300}
        />
      </OnlyDesktop>
      <OnlyMobile>
        <DayPickerRangeController
          startDate={startDateMoment}
          endDate={endDateMoment}
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={onFocusChangeFunc}
          isDayHighlighted={isDayHighlightedFunc}
          numberOfMonths={eventsPage ? 1 : 12}
          hideKeyboardShortcutsPanel
          navNext={rightArrow}
          navPrev={leftArrow}
          daySize={getDayWidthForMobile()}
          noBorder
          isOutsideRange={isOutsideRangeFunc}
          renderDayContents={renderDayContentsFunc}
          orientation={eventsPage ? 'horizontal' : 'verticalScrollable'}
          withPortal={eventsPage ? false : true}
          initialVisibleMonth={eventsPage ? initialVisibleMonthDesktopFunc : initialVisibleMonthFunc}
          onPrevMonthClick={setVisibleMonthFunc}
          onNextMonthClick={setVisibleMonthFunc}
          transitionDuration={eventsPage ? 0 : 300}
        />
      </OnlyMobile>
    </FilterContainer>
  );
};

const FilterContainer = styled.div<{ eventsPage: boolean; seatManagementPage: boolean }>`
  ${props =>
    props.eventsPage &&
    `
    .DayPicker,
    .DayPicker__horizontal,
    .CalendarMonthGrid {
      background: none !important;
    }
    .CalendarMonthGrid__horizontal {
      left: 0;
    }
    .DayPicker_weekHeaders__horizontal {
      margin-left: 0;
    }
    .DayPicker_weekHeader_ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 252px;
      height: 48px;
      color: #282937;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.5;
      letter-spacing: 0.22px;
      text-transform: uppercase;
    }
    ${props.theme.mediaQueries.desktopOnly} {
    &&&&& {
        .DayPicker,
        .DayPicker > div > div,
        .DayPicker_transitionContainer
         {
          width: 100% !important;
        }

        .DayPicker_transitionContainer {
          min-height: 344px;
          border: 0;
        }

        .DayPickerNavigation_button {
          position: absolute;
          top: -40px;
          left: 0
          &:last-child{
            left: 250px;
          }
        }

        .CalendarMonthGrid_month__horizontal {
          width: 298px;
          height: 330px;
          border: solid 1px #efefef;
          border-radius: 2px;
          margin-right: 19px;
          background: #fff;
          box-shadow: 0 2px 5px 0 rgba(140, 140, 140, 0.5);
        }

        .CalendarMonth_caption {
          padding-top: 18px;
          padding-bottom: 55px;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
        }

        .DayPicker_weekHeaders__horizontal {
          margin-left: 0;
        }

        .DayPicker_weekHeader {
          top: 58px !important;
          padding: 0 22px !important;
          width: 298px;
          background: #f7f7f7;
          display: flex;
          justify-content: center;

          :nth-child(2){
            left: 317px !important;
          }

          :last-child {
            left: 634px !important;
          }
        }

        .DayPicker_weekHeader_li {
          width: 36px !important;
        }

        .CalendarMonth {
          background: none;
          padding: 0 !important;
        }

        .CalendarMonth_table {
          width: 252px;
          margin: 0 22px;
          box-sizing: border-box;
        }

        .CalendarDay {
          padding: 2px;
          line-height: 32px;
          .CalendarEventText {
            display: inline-block;
            height: 32px;
            line-height: 32px;
          }
        }
      }
    }
  }
  ${props.theme.mediaQueries.largeScreenMin} {
    &&&&& {
        .DayPicker_transitionContainer {
          min-height: 344px;
        }
        .DayPickerNavigation_button {
          position: absolute;
          top: -40px;
          left: 0
          &:last-child{
            left: 250px;
          }
        }

        .CalendarMonthGrid_month__horizontal {
          width: 361px;
          height: 344px;
          margin-right: 18px;
        }

        .CalendarMonth_caption {
          padding-top: 18px;
          padding-bottom: 55px;
        }

        .DayPicker_weekHeader {
          top: 58px !important;
          padding: 0 22px !important;
          width: 361px;

          :nth-child(2){
            left: 379px !important;
          }

          :last-child {
            left: 758px !important;
          }
        }

        .DayPicker_weekHeader_ul {
          width: 315px;
          height: 48px;
        }
        .DayPicker_weekHeader_li {
          width: 45px !important;
        }

        .CalendarMonth_table {
          width: 315px;
          margin: 0 22px;
        }

        .CalendarDay {
          padding: 4px 7px 4px 6px;
        }
      }
    }
    ${props.theme.mediaQueries.mobileOnly} {
    &&&&& {
        .DayPicker,
        .DayPicker > div > div,
        .DayPicker_transitionContainer {
          max-width: 335px !important;
          margin: 0 auto;
        }

        .DayPickerNavigation__horizontal {
          left: 10px;
          right: 10px;
          width: auto;
          padding-top: ${props.theme.pxToRem(20)};
        }
        .DayPicker_transitionContainer {
          background: #fff;

        }
        .CalendarMonth {
          padding: 0 !important;
        }
        .CalendarMonthGrid_month__horizontal {
          width: 335px;
          height: 335px;
          margin-right: 0;
        }

        .CalendarMonth_caption {
          padding-top: 18px;
          padding-bottom: 55px;
        }

        .DayPicker_weekHeader {
          top: 58px !important;
          padding: 0 10px !important;
          width: 335px;
        }

        .DayPicker_weekHeader_ul {
          width: 335px;
          height: 48px;
        }
        .DayPicker_weekHeader_li {
          width: 45px !important;
        }

        .CalendarMonth_table {
          width: 315px;
          margin: 0 10px;
        }

        .CalendarDay {
          padding: 2px;
        }
      }
    }`}

  .DayPicker_transitionContainer {
    border-radius: ${props => props.theme.pxToRem(2)};
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey2};
    min-height: ${props => props.theme.pxToRem(310)};

    ${props =>
      !props.eventsPage &&
      `${props.theme.mediaQueries.mobileOnly} {
      position: relative;
      overflow-y: hidden;
      min-height: auto;
    }`}
  }

  .DayPicker_weekHeader_li {
    font-size: 0;
  }

  .DayPicker_weekHeader_li::first-letter {
    visibility: visible;
    font-size: ${props => props.theme.fontSizes.xxs};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: 1.5;
    letter-spacing: ${props => props.theme.pxToRem(0.22)};
    color: ${props => props.theme.colors.neutralGrey8};

    ${props =>
      !props.eventsPage &&
      `${props.theme.mediaQueries.mobileOnly} {
        font-size: ${props.theme.fontSizes.xs};
        line-height: 1.57;
    }`}
  }

  .DayPickerNavigation__horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${props => props.theme.pxToRem(36)};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: ${props => props.theme.pxToRem(12)};
  }

  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: ${props => props.theme.pxToRem(40)};
  }

  .DayPickerNavigation__vertical {
    ${props => props.theme.mediaQueries.mobileOnly} {
      display: none;
    }
  }

  .DayPicker_weekHeader {
    height: ${props => props.theme.pxToRem(40)};
    background-color: ${props => props.theme.colors.neutralGrey1};
    display: flex;
    align-items: center;
    top: ${props => props.theme.pxToRem(48)};

    ${props =>
      !props.eventsPage &&
      `${props.theme.mediaQueries.mobileOnly} {
        top: 0;
    }`}
  }

  .DayPicker_weekHeader_ul {
    white-space: nowrap;
  }

  .DayPickerNavigation_button {
    display: inline-block;
    width: ${props => props.theme.pxToRem(24)};
    height: ${props => props.theme.pxToRem(24)};
  }

  .CalendarMonth_caption {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    line-height: 1.5;
    color: ${props => props.theme.colors.neutralGrey8};
    padding-top: ${props => props.theme.pxToRem(12)};
    padding-bottom: ${props => props.theme.pxToRem(68)};

    ${props =>
      !props.eventsPage &&
      `${props.theme.mediaQueries.mobileOnly} {
        padding-top: ${props.theme.pxToRem(16)};
        padding-bottom: ${props.theme.pxToRem(8)};
        text-align: left;
    }`}
  }

  .CalendarDay {
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: 1.57;
    color: ${props => props.theme.colors.neutralGrey8};
    border: none;
    position: relative;
    outline: none;

    ${props =>
      !props.eventsPage &&
      `${props.theme.mediaQueries.mobileOnly} {
        font-size: ${props.theme.fontSizes.m};
        line-height: 1.33;
    }`}

    &:hover {
      background-color: ${props => props.theme.colors.neutralWhite};
      border: none;
      color: inherit;
    }

    & > .CalendarEventText {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &:not(.CalendarDay__blocked_out_of_range):hover {
      & > .CalendarEventText {
        color: ${props => props.theme.colors.neutralGrey8};
        background-color: ${props => props.theme.colors.neutralGrey2};

        ${props => props.theme.mediaQueries.mobileOnly} {
          color: ${props => props.theme.colors.neutralWhite};
          background-color: ${props => props.theme.colors.primaryPurple};
        }
      }
    }

    &.CalendarDay__blocked_out_of_range {
      color: ${props => props.theme.colors.neutralGrey4};
    }

    & > .CalendarEventDot {
      width: ${props => props.theme.pxToRem(4)};
      height: ${props => props.theme.pxToRem(4)};
      border-radius: 50%;
      background-color: #aa7ca7;
      position: absolute;
      left: calc(50% - ${props => props.theme.pxToRem(2)});
      bottom: ${props => props.theme.pxToRem(3)};
      z-index: 2;

      ${props => props.theme.mediaQueries.mobileOnly} {
        bottom: ${props => props.theme.pxToRem(6)};
      }
    }

    &.CalendarDay__highlighted_calendar {
      font-weight: ${props => props.theme.fontWeights.medium};
      color: ${props => props.theme.colors.primaryPurple};
      background-color: ${props => props.theme.colors.neutralWhite};
    }

    &.CalendarDay__selected_start,
    &.CalendarDay__selected_end {
      position: relative;
      background-color: ${props => props.theme.colors.neutralWhite};
      font-weight: ${props => props.theme.fontWeights.medium};

      & > .CalendarEventText {
        color: ${props => props.theme.colors.neutralWhite};
        background-color: ${props => props.theme.colors.primaryPurple};
      }

      &:hover {
        & > .CalendarEventText {
          background-color: ${props => props.theme.colors.primaryLightPurple};
          color: ${props => props.theme.colors.neutralWhite};
        }
        ${props => props.theme.mediaQueries.mobileOnly} {
          & > .CalendarEventText {
            color: ${props => props.theme.colors.neutralWhite};
            background-color: ${props => props.theme.colors.primaryPurple};
          }
        }
      }

      &::after {
        content: ' ';
        width: ${props => (props.seatManagementPage ? '100%' : '50%')};
        height: 100%;
        background-color: rgba(114, 36, 108, 0.12);
        top: 0;
        z-index: 1;
      }
    }

    &:not(.CalendarDay__selected_start_no_selected_end).CalendarDay__selected_start {
      &::after {
        position: absolute;
        right: 0;
      }
    }

    &.CalendarDay__selected_end {
      &::after {
        position: absolute;
        left: 0;
      }
    }

    &.CalendarDay__selected_span {
      background-color: rgba(114, 36, 108, 0.12);
    }

    &.CalendarDay__hovered_span,
    &.CalendarDay__hovered_span:hover {
      background: none;
      border: initial;
    }
  }
`;

const StyledTitle = styled.div<{ eventsPage: boolean }>`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralGrey8};

  ${props =>
    !props.eventsPage &&
    `${props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props.theme.pxToRem(20)};
      font-size: ${props.theme.fontSizes.s};
      line-height: 1.5;
    }`}
`;

const StyledArrowRight = styled(ArrowRight)<{ 'data-events-page': boolean }>`
  fill: ${props => (props['data-events-page'] ? props.theme.colors.neutralWhite : props.theme.colors.primaryPurple)};
  transform: rotate(90deg);
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};

  ${props =>
    !props['data-events-page'] &&
    `${props.theme.mediaQueries.mobileOnly} {
      display: none;
    }`}

  ${props => props.theme.mediaQueries.mobileOnly} {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledArrowLeft = styled(ArrowLeft)<{ 'data-events-page': boolean }>`
  fill: ${props => (props['data-events-page'] ? props.theme.colors.neutralWhite : props.theme.colors.primaryPurple)};
  transform: rotate(270deg);
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};

  ${props =>
    !props['data-events-page'] &&
    `${props.theme.mediaQueries.mobileOnly} {
      display: none;
    }`}

  ${props => props.theme.mediaQueries.mobileOnly} {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledLegend = styled.div`
  padding-left: ${props => props.theme.pxToRem(10)};
  margin-bottom: ${props => props.theme.pxToRem(12)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: 0.22px;
  color: ${props => props.theme.colors.neutralBlack};
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    top: 40%;
    left: 0;
    width: ${props => props.theme.pxToRem(4)};
    height: ${props => props.theme.pxToRem(4)};
    border-radius: 50%;
    background-color: #aa7ca7;
  }
`;
