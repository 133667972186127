import React from 'react';
import styled from 'styled-components';
import { RadioToggle } from 'components/atoms';

interface Props {
  autoRenewEnabled: boolean | null;
  item: Common.ProductItemData;
  renewalOnText: string;
  renewalOffText: string;
  productName: string;
  toggleSubscriptionRenewal?: (enable: boolean, item: Common.ProductItemData, productName: string) => void;
  disabled?: boolean;
}

export const RenewalToggle: React.FC<Props> = ({
  autoRenewEnabled,
  item,
  productName,
  renewalOnText,
  renewalOffText,
  toggleSubscriptionRenewal,
  disabled,
  ...props
}) => {
  const onToggleSubscriptionRenewal = React.useCallback(() => {
    if (toggleSubscriptionRenewal) {
      toggleSubscriptionRenewal(true, item, productName);
    }
  }, [item, toggleSubscriptionRenewal, productName]);

  return (
    <StyledHorizontalFlex {...props}>
      <StyledRadioToggle
        disabled={disabled}
        checked={!!autoRenewEnabled}
        label={''}
        handleChange={onToggleSubscriptionRenewal}
      />
      <SubscriptionRadioLabel {...props}>
        {autoRenewEnabled ? <div>{renewalOnText}</div> : <div>{renewalOffText}</div>}
      </SubscriptionRadioLabel>
    </StyledHorizontalFlex>
  );
};

const StyledHorizontalFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRadioToggle = styled(RadioToggle)`
  &&&& {
    transform: scale(0.7);
    margin: ${props => props.theme.pxToRem(3)} 0;
    justify-content: center;
    display: flex;
  }
`;

const SubscriptionRadioLabel = styled.div`
  min-width: ${props => props.theme.pxToRem(53)};
  display: inline;
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
  flex-shrink: 0;
`;
