import { match as MatchProps } from 'react-router';
import { createSelector, OutputSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { User, Product, MembershipTypes, B2B, Salesforce } from 'mxp-schemas';
import { FirmAdmin as FirmAdminUtils } from 'mxp-utils';
import { centPriceToString, emptyArray } from 'utils';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';
import { FirmAdminTableType } from 'constants/firm-admin';
import { combineHashWithEditedHash, isFLPCapable } from './helpers';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';
import { employmentDataSelector } from 'modules/user/selectors';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.firmAdmin,
  (firmAdmin: FirmAdmin.Root): FirmAdmin.Root => firmAdmin
);

const organizationsSelector = createSelector(
  rootSelector,
  (clientAdmin: FirmAdmin.Root): FirmAdmin.Organizations => clientAdmin.organizations
);

export const organizationsListSelector = createSelector(
  [organizationsSelector],
  (organizations: FirmAdmin.Organizations) => organizations.list || emptyArray
);
interface OrganizationNavigationParams {
  orgId: string;
}
export const organizationNavigationMatchSelector: any = createMatchSelector(getPath(Routes.FIRM_ROOT));
export const selectedOrganizationIdSelector = createSelector(
  [organizationNavigationMatchSelector],
  (match: MatchProps<OrganizationNavigationParams>): string => match?.params?.orgId || ''
);

const flatOrganizationsSelector = createSelector(
  [organizationsListSelector],
  (organizationsList: FirmAdmin.Organization[]): FirmAdmin.Organization[] => {
    if (!organizationsList) return emptyArray;
    return (function getFlatOrganizations(list: FirmAdmin.Organization[], result: FirmAdmin.Organization[] = []) {
      list.forEach(item => {
        result.push(item);
        if (item.branches) getFlatOrganizations(item.branches, result);
      });
      return result;
    })(organizationsList);
  }
);

export const selectedOrganizationSelector = createSelector(
  [selectedOrganizationIdSelector, flatOrganizationsSelector],
  (orgId: string, flatOrganizations: FirmAdmin.Organization[]): FirmAdmin.Organization | null => {
    return flatOrganizations.find((organization: FirmAdmin.Organization) => organization.id === orgId) || null;
  }
);

export const selectedOrganizationOfficeLocationsHashSelector = createSelector(
  selectedOrganizationSelector,
  (organization: FirmAdmin.Organization | null): FirmAdmin.OfficeLocationsHash => {
    if (!organization) return emptyArray;
    const options = [
      {
        organizationId: organization.id,
        branchCountry: organization.shippingAddress.country,
        branchState: organization.shippingAddress.state || 'N/A',
        branchCity: organization.shippingAddress.city,
        officeLocation: organization.shippingAddress.addressLine1,
      },
    ];

    const organizationOfficeLocations = (function getBranchesAddresses(
      branches,
      officeLocations: FirmAdmin.OfficeLocation[]
    ) {
      if (!branches) return officeLocations;
      branches.forEach(branch => {
        officeLocations.push({
          organizationId: branch.id,
          branchCountry: branch.shippingAddress.country,
          branchState: branch.shippingAddress.state || 'N/A',
          branchCity: branch.shippingAddress.city,
          officeLocation: branch.shippingAddress.addressLine1,
        });
        if (branch.branches) getBranchesAddresses(branch.branches, officeLocations);
      });
      return officeLocations;
    })(organization.branches, options);

    return organizationOfficeLocations.reduce(
      (acc: FirmAdmin.OfficeLocationsHash, item) => ({
        ...acc,
        [item.branchCountry]: {
          ...(acc[item.branchCountry] || {}),
          [item.branchState]: {
            ...(acc[item.branchCountry]?.[item.branchState] || {}),
            [item.branchCity]: [...(acc[item.branchCountry]?.[item.branchState]?.[item.branchCity] || []), item],
          },
        },
      }),
      {}
    );
  }
);

export const useFLPFeaturesSelector = createSelector(
  [selectedOrganizationSelector, featureTogglesSelector],
  (organization: FirmAdmin.Organization | null, featureToggles: any): boolean => {
    return isFLPCapable(organization);
  }
);

export const cimaFeaturesSelector = createSelector(
  [selectedOrganizationSelector, featureTogglesSelector],
  (organization: FirmAdmin.Organization | null, featureToggles: any): boolean => {
    return (
      organization?.organizationCapabilities?.type === FirmAdminUtils.parseType(B2B.OrganizationCapabilityType.CIMA).sf
    );
  }
);

export const currencySelector = createSelector(
  [selectedOrganizationSelector],
  (organization: FirmAdmin.Organization | null): Product.ProductCurrencyLabel => {
    if (organization?.currency) {
      return organization.currency;
    }
    const isCimaOrganization =
      organization?.organizationCapabilities?.type === FirmAdminUtils.parseType(B2B.OrganizationCapabilityType.CIMA).sf;
    return isCimaOrganization ? Product.ProductCurrencyLabel.GBP : Product.ProductCurrencyLabel.USD;
  }
);

const productsRootSelector = createSelector(
  (state: State.Root): State.Products => state.products,
  (products: State.Products): State.Products => products
);

export const membershipsSelector = createSelector(
  [productsRootSelector, featureTogglesSelector, selectedOrganizationSelector],
  (
    products: State.Products,
    featureToggle: any,
    org: FirmAdmin.Organization | null
  ): Product.SimplifiedMembership[] => {
    const organizationOfAnyCimaType =
      org?.organizationCapabilities?.type === FirmAdminUtils.parseType(B2B.OrganizationCapabilityType.CIMA).sf;

    return products.memberships?.filter((membership: Product.SimplifiedMembership) =>
      membership.variants?.find((variant: Product.SimplifiedMembershipVariant) =>
        organizationOfAnyCimaType
          ? variant.membershipBody === FirmAdminUtils.parseType(B2B.OrganizationCapabilityType.CIMA).ct &&
            membership.productType !== Product.ProductType.FLP
          : variant.membershipBody === FirmAdminUtils.parseType(B2B.OrganizationCapabilityType.AICPA).ct &&
            variant.membershipKey !== MembershipTypes.MembershipKeys.PRE_CANDIDATE &&
            membership.productType !== Product.ProductType.FLP
      )
    );
  }
);

export const subscriptionsSelector = createSelector(
  [productsRootSelector, cimaFeaturesSelector],
  (products: State.Products, isCima: boolean): Product.SimplifiedMembership[] => {
    return (
      products.memberships?.filter((membership: Product.SimplifiedMembership) =>
        membership.variants?.find((variant: Product.SimplifiedMembershipVariant) => {
          return (
            membership.productType === Product.ProductType.FLP &&
            (isCima ? variant?.cima_zuora_product_rate_plan !== 'N/A' : variant?.zuora_product_rate_plan !== 'N/A')
          );
        })
      ) || []
    );
  }
);

export const dataSelectorFactory = <T = FirmAdmin.FirmMember>(type: FirmAdmin.FirmAdminTableType) =>
  createSelector(rootSelector, (firmAdmin: FirmAdmin.Root): FirmAdmin.Data<T> => firmAdmin.data[type]);

export const dataAllIdsSelectorFactory = (type: FirmAdmin.FirmAdminTableType) =>
  createSelector(rootSelector, (firmAdmin: FirmAdmin.Root): string[] => {
    const { hash } = firmAdmin.data[type];
    const includedInAicpaFirmBilling = firmAdmin.pagination.manageFirmBilling.filters.isPaidByFirm;
    const includedInCimaOrganizationBilling = firmAdmin.pagination.cimaOrganizationBilling.filters.isPaidByFirm;
    const includedInFlpFirmBilling = firmAdmin.pagination.flpOrganizationBilling.filters.isPaidByFlp;

    return Object.keys(hash).reduce((acc: string[], key: string) => {
      const {
        isCheckboxDisabled,
        toDisplay,
        isAddToFirmBillingDisabled,
        isRemoveFromFirmDisabled,
        isAddToFlpFirmBillingDisabled,
      } = hash[key];

      if (isCheckboxDisabled) {
        return acc;
      }

      if (
        (type === FirmAdminTableType.CIMA_ORGANIZATION_BILLING || type === FirmAdminTableType.MANAGE_FIRM_BILLING) &&
        isAddToFirmBillingDisabled
      ) {
        return acc;
      }

      if (type === FirmAdminTableType.FLP_ORGANIZATION_BILLING && isAddToFlpFirmBillingDisabled) {
        return acc;
      }
      if (
        (!isCheckboxDisabled &&
          toDisplay &&
          (type === FirmAdminTableType.MEMBERSHIPS_AND_ADDONS ||
            type === FirmAdminTableType.FLP_ORGANIZATION_ADDONS)) ||
        (includedInAicpaFirmBilling && !isRemoveFromFirmDisabled && toDisplay) ||
        (!includedInAicpaFirmBilling && !isAddToFirmBillingDisabled) ||
        (includedInCimaOrganizationBilling && !isRemoveFromFirmDisabled && toDisplay) ||
        (!includedInCimaOrganizationBilling && !isAddToFirmBillingDisabled) ||
        (includedInFlpFirmBilling && !isRemoveFromFirmDisabled && toDisplay) ||
        (!includedInFlpFirmBilling && !isAddToFlpFirmBillingDisabled)
      ) {
        acc.push(key);
      }
      return acc;
    }, []);
  });

export const paginationSelectorFactory = (type: FirmAdmin.FirmAdminTableType) =>
  createSelector(rootSelector, (firmAdmin: FirmAdmin.Root): FirmAdmin.Pagination => firmAdmin.pagination[type]);

export const isNoSearchAndFiltersInPaginationSelectorFactory = (type: FirmAdmin.FirmAdminTableType) =>
  createSelector(
    paginationSelectorFactory(type),
    (pagination: FirmAdmin.Pagination): boolean =>
      !pagination.query &&
      !Object.keys(pagination.filters).filter((key: string) => {
        if (type === FirmAdminTableType.UPGRADABLE_MEMBERS) {
          return ['membershipType', 'membershipTier', 'position'].includes(key) && (pagination.filters as any)[key];
        }
        return (
          ['membershipType', 'membershipTier', 'position', 'subscriptionStatus'].includes(key) &&
          (pagination.filters as any)[key]
        );
      }).length
  );

export const membersSelectorFactory = <T>(
  dataSelector: OutputSelector<State.Root, FirmAdmin.Data<any>, (firmAdmin: FirmAdmin.Root) => FirmAdmin.Data<any>>,
  type?: FirmAdmin.FirmAdminTableType
) =>
  // Readme. toDisplay is synthetic property for pagination feature
  createSelector(
    [dataSelector, membershipTypeBySlugMapSelector],
    (data: FirmAdmin.Data<T & { toDisplay: boolean }>, membershipsSlugMap): T[] =>
      Object.values(combineHashWithEditedHash(data.hash, data.modifiedHash, membershipsSlugMap, type)).filter(
        item => item.toDisplay
      )
  );

export const membershipTypeBySlugMapSelector = createSelector([membershipsSelector], memberships =>
  memberships.reduce((acc, curr) => {
    const tierMapBySku = curr.variants?.reduce((tierAcc: any, tierCurr: any) => {
      if (!tierCurr.sku) return tierAcc;
      tierAcc[tierCurr.sku] = tierCurr;
      return tierAcc;
    }, {} as any);
    acc[curr.slug] = { ...curr, tierMapBySku };
    return acc;
  }, {} as any)
);

export const isOrganizationsFetchedSelector = createSelector(
  [organizationsSelector],
  (organizations: FirmAdmin.Organizations): boolean => organizations.isOrganizationsFetched
);

const organizationAdminsSelector = createSelector(
  rootSelector,
  (clientAdmin: FirmAdmin.Root): FirmAdmin.OrganizationAdmins => clientAdmin.organizationAdmins
);

export const organizationAdminsListSelector = createSelector(
  [organizationAdminsSelector],
  (organizationAdmins: FirmAdmin.OrganizationAdmins) => organizationAdmins.list || emptyArray
);

export const isOrganizationAdminsFetchedSelector = createSelector(
  [organizationAdminsSelector],
  (organizationAdmins: FirmAdmin.OrganizationAdmins): boolean => organizationAdmins.isOrganizationAdminsFetched
);

export const firmAdminAddonsSelector = createSelector(
  [rootSelector, selectedOrganizationSelector],
  (firmAdmin: FirmAdmin.Root, org: FirmAdmin.Organization | null) => {
    const getMembershipBody = (body: any) => (typeof body === 'object' ? body!['key' as keyof string] : body);

    return (
      firmAdmin.addons?.filter(addon =>
        org?.organizationCapabilities?.type === FirmAdminUtils.parseType(B2B.OrganizationCapabilityType.CIMA).sf
          ? getMembershipBody(addon.membershipBody) === Product.ProductMembershipBody.CIMA
          : getMembershipBody(addon.membershipBody) === Product.ProductMembershipBody.AICPA
      ) || null
    );
  }
);

export const firmAdminSectionsSelector = createSelector(
  [rootSelector],
  (firmAdmin: FirmAdmin.Root) => firmAdmin.sections
);

export const firmAdminCredentialsSelector = createSelector(
  [rootSelector],
  (firmAdmin: FirmAdmin.Root) => firmAdmin.credentials
);

export const personalAddonsModalUserSelector = createSelector(
  [rootSelector],
  (firmAdmin: FirmAdmin.Root): FirmAdmin.FirmMember | null => {
    if (!firmAdmin.personalAddonsModalUserId) return null;
    return firmAdmin.data.membershipsAndAddons.hash[firmAdmin.personalAddonsModalUserId] || null;
  }
);

export const orgDetailsSelector = createSelector([rootSelector], (firmAdmin: FirmAdmin.Root): User.FirmAdminTypes => {
  return firmAdmin.orgDetails;
});

export const isSubscriptionsDataLoadedSelector = createSelector(
  [rootSelector],
  (firmAdmin: FirmAdmin.Root): boolean => {
    return firmAdmin?.data?.membershipsAndAddons?.isSubscriptionsDataLoaded || false;
  }
);

export const quotesFLPStatusSelector = createSelector(
  [rootSelector],
  (firmAdmin: FirmAdmin.Root): User.FirmBillingQuoteStatus => {
    return firmAdmin?.data?.flpOrganizationAddons?.quotesStatus || User.FirmBillingQuoteStatus.INITIALIZED;
  }
);

export const quotesStatusSelector = createSelector(
  [rootSelector],
  (firmAdmin: FirmAdmin.Root): User.FirmBillingQuoteStatus => {
    return firmAdmin?.data?.membershipsAndAddons?.quotesStatus || User.FirmBillingQuoteStatus.INITIALIZED;
  }
);

export const personalAddonsModalProductsSelector = createSelector(
  [
    firmAdminAddonsSelector,
    firmAdminSectionsSelector,
    firmAdminCredentialsSelector,
    personalAddonsModalUserSelector,
    selectedOrganizationSelector,
  ],
  (
    addons: FirmAdmin.TransformedProduct[] | null,
    sections: FirmAdmin.TransformedProduct[] | null,
    credentials: FirmAdmin.TransformedProduct[] | null,
    member: FirmAdmin.FirmMember | null,
    selectedOrganization: FirmAdmin.Organization | null
  ): FirmAdmin.AddonCardData[] => {
    if (!member) return emptyArray;

    const subscriptionsHash =
      member.subscriptions?.reduce((acc: any, subscription: FirmAdmin.FirmAdminSubscription) => {
        acc[subscription.sku] = { ...subscription };
        return acc;
      }, {}) || {};

    const memberSkus: string[] = Object.keys(subscriptionsHash);

    const membershipBenefitSkus =
      member.subscriptions?.find(sub => sub.membershipType === User.MembershipType.AICPA)?.benefitSkus || [];
    const currency = selectedOrganization?.currency;
    const mapper = (products: FirmAdmin.TransformedProduct[] | null, type: any): FirmAdmin.AddonCardData[] => {
      if (!products?.length) return emptyArray;
      return products.reduce((acc, item) => {
        const variant = item?.variants.find(v => memberSkus.includes(v?.sku || '')) || item.variants?.[0];
        const priceObject = currency
          ? variant.prices?.find(price => price.currency === currency) || variant.prices?.[0]
          : variant.prices?.[0];
        const variantSku = variant.sku as string;

        if (!priceObject || !priceObject.channel?.id || membershipBenefitSkus.includes(variantSku)) return acc;

        const centPrice = priceObject.amount;

        const subscription = subscriptionsHash?.[variantSku];
        const isIncludedInFirmBilling =
          !Boolean(subscription) || subscription?.subscriptionAccountId !== subscription?.subscriptionInvoiceOwnerId;

        const mapped = {
          productId: item.productId,
          description: item.description,
          variantSku,
          addonType: type,
          title: item.name.length > 100 ? `${item.name.slice(0, 60)} ...` : item.name,
          centPrice,
          channelId: priceObject.channel.id,
          priceFormatted: centPriceToString(centPrice, currency),
          expiryDate: subscription?.renewalDate || null,
          // Existing subscription
          subscriptionNumber: subscription?.subscriptionNumber || null,
          renewalDate: subscription?.renewalDate || null,
          ratePlanId: subscription?.ratePlanId || null,
          isPaid:
            Boolean(subscription?.invoiceId && subscription?.invoiceBalance === 0) || subscription?.invoiceId === null,
          isIncludedInFirmBilling,
          isMembership: false,
          membershipEndDate: member.expiryDate,

          requestedAddToQuote: false,
        };
        acc.push(mapped);
        return acc;
      }, [] as any);
    };

    return [...mapper(addons, 'add-on'), ...mapper(sections, 'section'), ...mapper(credentials, 'credential')];
  }
);

export const bulkAddonsModalProductsSelector = createSelector(
  [firmAdminAddonsSelector, firmAdminSectionsSelector],
  (
    addons: FirmAdmin.TransformedProduct[] | null,
    sections: FirmAdmin.TransformedProduct[] | null
  ): FirmAdmin.TransformedProduct[] => [...(addons || emptyArray), ...(sections || emptyArray)]
);

export const firmAdminProductsSkuNameHashSelector = createSelector(
  [firmAdminAddonsSelector, firmAdminSectionsSelector, firmAdminCredentialsSelector],
  (
    addons: FirmAdmin.TransformedProduct[] | null,
    sections: FirmAdmin.TransformedProduct[] | null,
    credentials: FirmAdmin.TransformedProduct[] | null
  ): { [key: string]: string } =>
    [...(addons || emptyArray), ...(sections || emptyArray), ...(credentials || emptyArray)].reduce(
      (acc: { [key: string]: string }, item: FirmAdmin.TransformedProduct) => {
        acc[item.variants[0].sku as string] = item.name;
        return acc;
      },
      {}
    )
);

export const bulkAddonsModalProductsBySkusHashSelector = createSelector(
  bulkAddonsModalProductsSelector,
  (products: FirmAdmin.TransformedProduct[]): { [key: string]: FirmAdmin.TransformedProduct } =>
    products.reduce((acc, product) => ({ ...acc, [product.variants[0].sku as string]: product }), {})
);

export const isFirmAdminRenewalSeasonSelector = createSelector(
  [rootSelector],
  (firmAdmin: FirmAdmin.Root): boolean | null => firmAdmin.isRenewalSeason
);

export const isAddonsSectionsCredentialsFetchedSelector = createSelector(
  [firmAdminAddonsSelector, firmAdminSectionsSelector, firmAdminCredentialsSelector],
  (
    addons: FirmAdmin.TransformedProduct[] | null,
    sections: FirmAdmin.TransformedProduct[] | null,
    credentials: FirmAdmin.TransformedProduct[] | null
  ): boolean => Boolean(addons && sections && credentials)
);

export const selectedMembersSelectorFactory = <T = FirmAdmin.FirmMember>(type: FirmAdmin.FirmAdminTableType) =>
  createSelector(dataSelectorFactory<T>(type), (data: FirmAdmin.Data<T>): T[] => {
    return Object.values(data.hash).filter((member: any) => data.selectedIds.includes(member.userId));
  });

export const selectedMembersStatusSelectorFactory = (type: FirmAdmin.FirmAdminTableType) =>
  createSelector(selectedMembersSelectorFactory(type), (selectedMembers: FirmAdmin.FirmMember[]) => {
    return selectedMembers.reduce(
      (acc: { anyMemberInInvoice: boolean; anyMemberInQuote: boolean }, member) => {
        if (!acc.anyMemberInQuote && member.quoteStatus === User.FirmBillingQuoteStatus.QUOTE_GENERATED) {
          acc.anyMemberInQuote = true;
        }
        if (
          !acc.anyMemberInInvoice &&
          member.subscriptions?.some(
            (subscription: FirmAdmin.FirmAdminSubscription) =>
              subscription.invoiceBalance && subscription.invoiceBalance > 0
          )
        ) {
          acc.anyMemberInInvoice = true;
        }
        return acc;
      },
      {
        anyMemberInInvoice: false,
        anyMemberInQuote: false,
      }
    );
  });

export const isGenerateInvoiceModalOpenedSelector = createSelector(
  [rootSelector],
  (firmAdmin: FirmAdmin.Root): boolean => {
    return firmAdmin.isGenerateInvoiceModalOpened;
  }
);

export const isMemberMembershipIsInQuoteSelectorFactory = (userId: string) =>
  createSelector(
    dataSelectorFactory<FirmAdmin.FirmMember>(FirmAdminTableType.MEMBERSHIPS_AND_ADDONS),
    (data: FirmAdmin.Data<FirmAdmin.FirmMember>): boolean => {
      return Boolean(data.hash[userId]?.quoteItems?.some(quoteItem => quoteItem.isMembership));
    }
  );

export const allMembershipsBySkuMapSelector = createSelector(
  [membershipsSelector, currencySelector],
  (memberships: Product.SimplifiedMembership[], currency) =>
    memberships?.reduce((acc: any, product: any) => {
      product.variants.forEach((variant: any) => {
        const price = variant.prices?.find((val: any) => val.value.currencyCode === currency);

        acc[variant.sku] = {
          productId: product.id,
          newVariantSku: variant.sku || '',
          newCentPrice: price?.value.centAmount || 0,
          variantSku: variant.sku || '',
          centPrice: price?.value.centAmount || 0,
          channelId: price?.channel?.id || '',
          description: product?.description, // TODO Check what expected in download Quote description,
          isIncludedInFirmBilling: true,
          subscriptionNumber: null,
          renewalDate: null,
          isMembership: true,
          action: User.QuoteItemActions.QUOTE,
          options: User.FirmBillingQuoteItemOptions.READY,
        };
      });

      return acc;
    }, {} as any)
);

export const allSubscriptionsBySkuMapSelector = createSelector(
  [subscriptionsSelector, currencySelector],
  (subscriptions: Product.SimplifiedMembership[], currency) => {
    return subscriptions?.reduce((acc: any, product) => {
      product.variants.forEach(variant => {
        const price = variant.prices?.find(variantPrice => variantPrice.value.currencyCode === currency);
        if (variant.sku) {
          acc[variant.sku] = {
            productId: product.id,
            newVariantSku: variant.sku || '',
            newCentPrice: price?.value.centAmount || 0,
            variantSku: variant.sku || '',
            centPrice: price?.value.centAmount || 0,
            channelId: price?.channel?.id || '',
            description: product?.description, // TODO Check what expected in download Quote description,
            isIncludedInFirmBilling: true,
            subscriptionNumber: null,
            renewalDate: null,
            isMembership: true,
            action: User.QuoteItemActions.QUOTE,
            options: User.FirmBillingQuoteItemOptions.READY,
          };
        }
      });

      return acc;
    }, {} as any);
  }
);

export const slugBySkuSubscriptionsSelector = createSelector([subscriptionsSelector], subs =>
  subs?.reduce((acc: any, product: any) => {
    product.variants.forEach((variant: any) => {
      acc[variant.sku] = {
        slug: product.slug,
        duration: variant.flpTerm,
      };
    });

    return acc;
  }, {} as any)
);

const membershipsRootSelector = createSelector(
  (state: State.Root): State.Membership => state.membership,
  (membership: State.Membership): State.Membership => membership
);

export const inviteDataSelector = createSelector(
  [membershipsRootSelector],
  (membership: State.Membership): State.InviteData => {
    return membership.inviteData;
  }
);

export const firmAdminEventsSelector = createSelector(
  [rootSelector],
  (firmAdmin: FirmAdmin.Root): User.FirmAdminEvents => {
    return firmAdmin.events;
  }
);

export const isCimaFirmBillingSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData) => {
    return employmentData.employers.find(
      employer =>
        [
          MembershipTypes.RelationshipTypeEnum.FIRM_BILLING,
          MembershipTypes.RelationshipTypeEnum.FLP_FIRM_BILLING,
        ].includes(employer.relationshipType) &&
        employer.organization.organizationCapabilities?.type === Salesforce.LegalEntity.CIMA
    );
  }
);
