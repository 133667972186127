import React from 'react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import { Button, ButtonEnums, ButtonLink, Divider, Label } from 'components/atoms';
import { getPath } from 'utils';
import { DOWNLOAD_EVENT, handleEvent } from 'utils/Analytics';
import { Routes } from 'constants/index';
import { generatePath } from 'react-router-dom';
import { useParams } from 'react-router';
import { getInvoiceFormFile } from 'modules/clientAdmin/actions';
import { downloadingErrorSelector, downloadingIdSelector } from 'modules/clientAdmin/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ZuoraTypes } from 'mxp-schemas';
import { InputWithValidation } from '../InputWithValidation/InputWithValidation';
import { DropdownWithLabel } from '../DropdownWithLabel/DropdownWithLabel';
import { StepPaymentEckohContainer } from 'components/App/lazy-imports';

interface Props {
  isCheckPaymentOption: boolean;
  isSelected: boolean;
  invoice: ZuoraTypes.Invoice | null;
  isWireCheckPayment?: boolean;
  isBankTransferCheque?: boolean;
  isOfflinePayment?: boolean;
  isEckohPayment?: boolean;
  isPaypalPayment?: boolean;
  isCheckBankTransferPayment?: boolean;
}

export const PaymentOptionsText: React.FC<Props> = ({
  isCheckPaymentOption,
  isSelected,
  invoice,
  isWireCheckPayment,
  isOfflinePayment,
  isEckohPayment,
  isBankTransferCheque,
  isCheckBankTransferPayment,
}) => {
  const { orgId } = useParams<{ orgId: string }>();
  const rootOrganizationId = orgId;
  const renderBackToInvoicesLink = (): React.ReactNode => {
    return (
      <StyledBackToInvoicesLink
        variant={ButtonEnums.variants.link}
        to={
          rootOrganizationId
            ? generatePath(getPath(Routes.FIRM_BILLING_TAB_INVOICES), { orgId: rootOrganizationId })
            : getPath(Routes.ORG_INVOICES)
        }
        testId="invoice-checkout-payment-options-button"
      >
        Return to Invoices
      </StyledBackToInvoicesLink>
    );
  };

  const dispatch = useDispatch();
  const downloadId = useSelector(downloadingIdSelector);
  const downloadError = useSelector(downloadingErrorSelector);

  const handleDownloadFileClick = React.useCallback(() => {
    const selectedInvoice = invoice as State.InvoiceTableRow;
    handleEvent({ filename: `${invoice?.invoiceNumber}.pdf` }, DOWNLOAD_EVENT);
    dispatch(getInvoiceFormFile(selectedInvoice));
  }, [invoice]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderChangeOrderProcessOption = (): React.ReactNode => {
    return (
      <>
        <StyledBackToInvoicesLink
          variant={ButtonEnums.variants.link}
          to={
            rootOrganizationId
              ? generatePath(getPath(Routes.FIRM_BILLING_TAB_INVOICES), { orgId: rootOrganizationId })
              : getPath(Routes.ORG_INVOICES)
          }
          testId="invoice-checkout-payment-options-change-order-process-option"
        >
          Change order process option
        </StyledBackToInvoicesLink>
      </>
    );
  };

  return (
    <>
      {(isBankTransferCheque ||
        isWireCheckPayment ||
        isOfflinePayment ||
        isCheckBankTransferPayment ||
        isCheckPaymentOption) && (
        <StyledDiv show={isSelected}>
          {isCheckPaymentOption && isSelected && (
            <>
              <StyledText>
                Download and print the invoice below, complete the attached remittance form, and send it with your
                check.
              </StyledText>
              <ContactUsDetails
                paymentOptionText="if
                you have any questions about paying by check"
              />
              <StyledDownloadButton
                loading={downloadId === invoice?.id && !downloadError}
                testId="download-invoice-form"
                size={ButtonEnums.sizes.medium}
                variant={ButtonEnums.variants.primary}
                onClick={handleDownloadFileClick}
              >
                Download Form
              </StyledDownloadButton>
            </>
          )}
          {isWireCheckPayment && isSelected && (
            <>
              <StyledText>
                Please see invoice for details on paying via check or contact GEC for information regarding wire
                transfer.
              </StyledText>
            </>
          )}
          {isCheckBankTransferPayment && isSelected && (
            <>
              <StyledText>
                Please see invoice for details on paying via bank transfer or contact GEC for information regarding wire
                transfer.
              </StyledText>
            </>
          )}
          {isBankTransferCheque && isSelected && (
            <>
              <StyledText>
                For information regarding paying via cheque or bank transfer please refer to the invoice
              </StyledText>
            </>
          )}
          {isOfflinePayment && isSelected && (
            <>
              <StyledText>The customer provided a verified offline payment, such as a check</StyledText>
              {renderChangeOrderProcessOption()}
              <Divider />
              <StyledText>
                Please enter the payment ID linked to the user account. You can find the 32-digit payment ID in the
                Unapplied Payments Report.
              </StyledText>

              <>
                <Label>Payment ID</Label>
                <InputWithValidation
                  name="payment-id"
                  testId="payment-id"
                  value=""
                  isCorrect
                  // tslint:disable-next-line jsx-no-lambda
                  onChange={() => alert('test')}
                  type="text"
                />
              </>
              <StyledDropDown title="Search for Payment ID" options={[]} defaultValue="Select a payment ID" />

              <Button testId="verify-payment-id">Verify Payment ID</Button>

              <Divider />
              <Button testId="submit-with-offline-payment">Submit with offline payment</Button>
            </>
          )}

          {isOfflinePayment ? null : (
            <StyledBackToInvoicesLinkWrapper>
              {isSelected && renderBackToInvoicesLink()}
            </StyledBackToInvoicesLinkWrapper>
          )}
        </StyledDiv>
      )}

      {isEckohPayment && (
        <StyledDiv show={isSelected}>
          {isEckohPayment && isSelected && <StepPaymentEckohContainer />}
          <StyledBackToInvoicesLinkWrapper>{isSelected && renderBackToInvoicesLink()}</StyledBackToInvoicesLinkWrapper>
        </StyledDiv>
      )}
    </>
  );
};

export const ContactUsDetails: React.FC<{
  paymentOptionText: string | null;
  isCreditDebitPayment?: boolean;
  isAllowedPartialPayment?: boolean;
}> = ({ paymentOptionText, isCreditDebitPayment, isAllowedPartialPayment }) => {
  const phoneContact = '800-634-6780';
  const emailContact = 'client.support@aicpa-cima.com';

  return (
    <StyledText isCreditDebitPayment={isCreditDebitPayment}>
      {isCreditDebitPayment
        ? isAllowedPartialPayment
          ? 'Choose the best option for your payment.  You have set up payment terms in your sales agreement, please refer to that sales agreement to view your set up options or contact us'
          : 'Price calculated on next step. Contact us'
        : 'Contact us'}{' '}
      at <StyledContactLink href={`tel:${phoneContact}`}>{phoneContact}</StyledContactLink>, option 1, or{' '}
      <StyledContactLink href={`mailto:${emailContact}`}>{emailContact}</StyledContactLink>
      {paymentOptionText ? ' ' : ''}
      {paymentOptionText}. We’re open 8am - 5pm Eastern (US time){isCreditDebitPayment ? '' : '.'}
    </StyledText>
  );
};

const StyledDiv = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? 'block' : 'none')};
  width: 100%;
  margin: 0 ${props => props.theme.pxToRem(20)} 0 ${props => props.theme.pxToRem(30)};
  padding: ${props => `0 ${props.theme.pxToRem(20)} ${props.theme.pxToRem(15)} 0`};
`;

const StyledText = styled.div<ThemedStyledProps<{ isCreditDebitPayment?: boolean }, DefaultTheme>>`
  font-size: ${props => (props.isCreditDebitPayment ? props.theme.fontSizes.xs : props.theme.fontSizes.s)};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  max-width: ${props => (props.isCreditDebitPayment ? props.theme.pxToRem(454) : props.theme.pxToRem(352))};
  margin: 0 0 ${props => props.theme.pxToRem(16)} 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => (props.isCreditDebitPayment ? props.theme.pxToRem(334) : props.theme.pxToRem(303))};
    margin: 0 0 ${props => (props.isCreditDebitPayment ? props.theme.pxToRem(32) : props.theme.pxToRem(24))} 0;
  }
`;

const StyledBackToInvoicesLink = styled(ButtonLink)`
  span {
    font-weight: ${props => props.theme.fontWeights.light};
    font-size: ${props => props.theme.fontSizes.s};
    text-decoration: underline;
    color: ${props => props.theme.buttonColors.primary};
  }
`;

const StyledBackToInvoicesLinkWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(16)};
`;

const StyledContactLink = styled.a`
  color: ${props => props.theme.buttonColors.primary};
`;

const StyledDownloadButton = styled(Button)`
  &&&& {
    display: inline-block;
    width: ${props => props.theme.pxToRem(164)};
  }
`;

const StyledDropDown = styled(DropdownWithLabel)`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(32)};
  }
`;
