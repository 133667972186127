import React from 'react';
import styled from 'styled-components';
import { Modal } from 'components/atoms/Modal/Modal';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import { Link } from 'components/atoms/Link/Link';
import { EVENT_CLICK, handleEvent, NAV_CLICK } from 'utils/Analytics';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { ReactComponent as Done } from 'resources/images/icon-dev-ic-done.svg';

interface Props {
  testId?: string;
  open: boolean;
  onConfirmUrl: string;
  onCancel: () => void;
}

export const ReturnProductItemModal: React.FC<Props> = ({ testId = 'return', open, onConfirmUrl, onCancel }) => {
  const handleReturnItemClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonCtaText = (event.target as HTMLAnchorElement).textContent;
    handleEvent(
      {
        clickValue: `button:profile-purchases:ext:${buttonCtaText}`,
      },
      NAV_CLICK
    );
    handleEvent(
      {
        clickValue: `button:profile-purchases:int:return-item:modal-complete-${buttonCtaText}`,
      },
      EVENT_CLICK
    );
  }, []);

  return (
    <StyledModal
      testId={testId}
      open={open}
      heading="Returning now, canceling ahead"
      size="tiny"
      confirmNode={
        <ButtonLink
          testId={`${testId}-confirm`}
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          to={onConfirmUrl}
          bordered
          external
          onClick={handleReturnItemClick}
          overrideAnalytics
        >
          Return item
        </ButtonLink>
      }
      cancelNode={
        <Button
          testId={`${testId}-cancel`}
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          onClick={onCancel}
        >
          Go back
        </Button>
      }
    >
      <StyledParagraph>
        Sorry it didn't work out. As part of your return, we'll go ahead and cancel future shipments of your standing
        order. But you'll be missing out on:
      </StyledParagraph>
      <StyledList>
        <li>
          <Done />
          <span>The latest information delivered automatically to your door</span>
        </li>
        <li>
          <Done />
          <span>Discounts for being a valued customer</span>
        </li>
        <li>
          <Done />
          <span>Hassle-free management of your renewal preferences</span>
        </li>
      </StyledList>
      <StyledLink
        testId={`${testId}-how-it-works`}
        to={getPath(Routes.STANDING_ORDERS)}
        viewTextForAnalytics="profile-purchases"
      >
        How standing orders work
      </StyledLink>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &&&&&& {
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      ${props => props.theme.mediaQueries.desktopOnly} {
        padding-bottom: ${props => props.theme.pxToRem(40)} !important;
      }
    }

    > .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        flex-direction: column-reverse;

        button,
        a {
          width: 100%;
          margin-right: 0;
          margin-bottom: ${props => props.theme.pxToRem(12)};

          :first-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

const StyledParagraph = styled.p`
  max-width: ${props => props.theme.pxToRem(360)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.71;
  text-align: center;

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(295)};
  }
`;

const StyledList = styled.ul`
  max-width: ${props => props.theme.pxToRem(389)};
  padding-left: 0;
  margin: 0 0 ${props => props.theme.pxToRem(20)} 0;
  list-style: none;

  li {
    display: flex;
    margin-bottom: ${props => props.theme.pxToRem(8)};
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 1.57;

    :last-child {
      margin-bottom: 0;
    }

    svg {
      flex-shrink: 0;
      width: ${props => props.theme.pxToRem(20)};
      height: ${props => props.theme.pxToRem(20)};
      margin-right: ${props => props.theme.pxToRem(6)};

      path {
        fill: ${props => props.theme.colors.primaryPurple};
      }
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(295)};
  }
`;

const StyledLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
`;
