import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { UserWidget } from 'components/molecules/UserWidget/UserWidget';
import { MiniUserWidget } from 'components/molecules/MiniUserWidget/MiniUserWidget';
import {
  userNameSelector,
  isUserMemberSelector,
  isAuthSelector,
  isClientAdminSelector,
  isFirmBillingClientAdminSelector,
  isCentersClientAdminSelector,
  isUserMemberSuspendedSelector,
  isTuitionProviderClientAdminSelector,
  personAccountDataSelector,
} from 'modules/user/selectors';
import { logout } from 'modules/user/actions';
import {
  userSettingsExpandedSelector,
  burgerExpandedSelector,
  orgProfileExpandedSelector,
} from 'modules/layouts/selectors';

import { setUserSettingsExpanded, setBurgerExpand, setOrgProfileExpanded } from 'modules/layouts';
import { activeMembershipSubscriptionSelector } from 'modules/membership/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  async logout(): Promise<void> {
    await dispatch(logout());
    dispatch(setBurgerExpand(false));
  },

  navigate(path: string): void {
    dispatch(push(path));
  },

  goToInternal(route: string): void {
    dispatch(setUserSettingsExpanded(false));
    dispatch(setBurgerExpand(false));
    dispatch(setOrgProfileExpanded(false));
    dispatch(push(route));
  },

  setUserSettingsExpanded(expanded: boolean): void {
    dispatch(setUserSettingsExpanded(expanded));
  },

  setOrgProfileExpanded(expanded: boolean): void {
    dispatch(setOrgProfileExpanded(expanded));
  },
});

const mapStateToProps = (state: State.Root) => ({
  isAuth: isAuthSelector(state),
  isUserMember: isUserMemberSelector(state),
  userName: userNameSelector(state),
  userSettingsExpanded: userSettingsExpandedSelector(state),
  orgProfileExpanded: orgProfileExpandedSelector(state),
  burgerExpanded: burgerExpandedSelector(state),
  isClientAdmin: isClientAdminSelector(state),
  isFirmBillingClientAdmin: isFirmBillingClientAdminSelector(state),
  isCentersClientAdmin: isCentersClientAdminSelector(state),
  isUserMemberSuspended: isUserMemberSuspendedSelector(state),
  isTuitionProviderClientAdmin: isTuitionProviderClientAdminSelector(state),
  learningPathway: personAccountDataSelector(state).learningPathway,
  hasActiveMembership: Boolean(activeMembershipSubscriptionSelector(state)?.id),
});

export const UserWidgetContainer = connect(mapStateToProps, mapActionCreators)(UserWidget);

export const MiniUserWidgetContainer = connect(mapStateToProps, mapActionCreators)(MiniUserWidget);
