import { CreditCardIcon } from 'components/atoms';
import { ExpiryLabel } from 'components/atoms/ExpiryLabel/ExpiryLabel';
import { FilterRadioLabel } from 'components/molecules/FilterRadioLabel/FilterRadioLabel';
import { CreditCardTypes, OtherPaymentTypes } from 'constants/index';

import { isAuthSelector } from 'modules/user/selectors';
import { isAdminPortalSelector, paypalUrlConfigSelector } from 'modules/app/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MomentHelpers } from 'utils/MomentHelpers';
import { CreditCardActions } from './CreditCardActions';

interface CreditCardProps {
  card: State.CreditCard;
  checked?: boolean;
  checkable?: boolean;
  setSelectedCreditCardId?: (cardId: string) => void;
  testId: string;
  onSetDefault: (card: State.CreditCard) => void;
  onDelete?: (card: State.CreditCard) => void;
}

const Dot: React.FC<{ card: State.CreditCard }> = ({ card }) => (
  <>
    {card.cardType !== OtherPaymentTypes.PAYPAL &&
      [
        ...Array(
          Object.keys(CreditCardTypes).includes(card.cardType.toUpperCase())
            ? 12
            : card.cardType === OtherPaymentTypes.BANKTRANSFER
            ? 5
            : 4
        ).keys(),
      ].map(x =>
        (x + 1) % 4 === 0 && Object.keys(CreditCardTypes).includes(card.cardType.toUpperCase()) ? (
          <>&#8226;&nbsp;</>
        ) : (
          <>&#8226;</>
        )
      )}
  </>
);

export const CreditCard: React.FC<CreditCardProps> = ({
  card,
  checked = false,
  checkable = false,
  setSelectedCreditCardId,
  testId,
  onSetDefault,
  onDelete,
}) => {
  const isAuth = useSelector(isAuthSelector);
  const isAdminPortal = useSelector(isAdminPortalSelector);
  const isImpersonation = Boolean(isAuth) && isAdminPortal;
  const paypalUrl = useSelector(paypalUrlConfigSelector);

  const deletePaymentMethod = React.useCallback((): void => {
    if (onDelete) onDelete(card);
  }, [onDelete, card]);

  const handleRadioChange = React.useCallback((): void => {
    if (setSelectedCreditCardId) {
      setSelectedCreditCardId(card.id);
    }
  }, [card.id, setSelectedCreditCardId]);

  if (!card) return null;

  const cardNumberEnding: string =
    card.cardType === OtherPaymentTypes.PAYPAL ? card.cardHolderInfo.cardHolderName : card.cardNumber.slice(-4); // condition on slicing base on payment type
  const expirationYearLastTwoDigits: string = card?.expirationYear?.toString().slice(-2) as string;
  const expirationMonth: string =
    (card?.expirationMonth as number) < 10 ? `0${card.expirationMonth}` : (card?.expirationMonth?.toString() as string);
  const creditCardTypes: string[] = Object.values(CreditCardTypes);
  const isExpired = creditCardTypes.includes(card.cardType) && MomentHelpers.isCardExpired(card);

  const CardElem: React.FC<any> = () => (
    <StyledContainer
      key={testId}
      default={card.defaultPaymentMethod}
      isExpired={isExpired}
      checked={checked}
      checkable={checkable}
      data-testid={testId}
    >
      <div>
        <StyledIconRow>
          <div>
            <CreditCardIcon type={card.cardType} />
          </div>
          <StyledDefault data-testid={`${testId}_default`}>{card.defaultPaymentMethod && 'Default'}</StyledDefault>
        </StyledIconRow>
        <StyledInfoRow>
          <div>
            {card.cardType === OtherPaymentTypes.BANKTRANSFER && <> UK bank account number </>}
            <Dot {...{ card }} />
            {card.cardType !== OtherPaymentTypes.PAYPAL && (
              <StyledDots selected={checkable && checked}>{cardNumberEnding}</StyledDots>
            )}
          </div>
          {expirationMonth && expirationYearLastTwoDigits && (
            <div>
              <StyledSpan isExpired={isExpired}>
                {expirationMonth}/{expirationYearLastTwoDigits}
              </StyledSpan>
            </div>
          )}
        </StyledInfoRow>
      </div>
      <StyledCardHoldersRow isExpired={isExpired} isPaypal={card.cardType === OtherPaymentTypes.PAYPAL}>
        {card.cardHolderInfo?.cardHolderName}
      </StyledCardHoldersRow>
      <StyledIconRow>
        <CreditCardActions
          card={card}
          testId={testId}
          onSetDefault={onSetDefault}
          onRemove={deletePaymentMethod}
          isExpired={isExpired}
          isImpersonation={isImpersonation}
          paypalUrl={paypalUrl}
        />
        {isExpired && (
          <ExpiryLabel year={card.expirationYear} month={card.expirationMonth} testId="creditCardExpiryLabel" />
        )}
      </StyledIconRow>
    </StyledContainer>
  );
  return checkable ? (
    <FilterRadioLabel
      className="radio"
      checked={checked}
      testId="saved-card-details--radio"
      onChange={handleRadioChange}
      reverseLabelAndRadio
      disabled={isExpired}
    >
      <CardElem />
    </FilterRadioLabel>
  ) : (
    <CardElem />
  );
};

const StyledContainer = styled.div<{ default: boolean; isExpired: boolean; checked: boolean; checkable: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSizes.xs};
  padding: ${props => props.theme.pxToRem(20)};
  border-radius: ${props => props.theme.pxToRem(8)};
  box-shadow: 0 2px ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${props => (props.default ? props.theme.colors.neutralGrey6 : props.theme.colors.neutralGrey3)};
  background-color: ${props => props.theme.colors.neutralWhite};

  ${props => !props.checkable && `margin-bottom: ${props.theme.pxToRem(22)};`};
  ${props => props.checkable && props.checked && `background-color: ${props.theme.colors.membershipTagPurple};`};

  width: ${props => props.theme.pxToRem(265)};
  height: ${props => props.theme.pxToRem(165)};

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-right: ${props => (props.checkable ? props.theme.pxToRem(32) : props.theme.pxToRem(20))};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    ${props =>
      !props.checkable &&
      `
      width: 100%;
      max-width: ${props.theme.pxToRem(335)};
      height: ${props.theme.pxToRem(209)};
    `};
  }
`;

const StyledDefault = styled.div`
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;
`;

const StyledIconRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.colors.neutralGrey8};
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-top: ${props => props.theme.pxToRem(13)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(22)};
  }
`;

const StyledCardHoldersRow = styled.div<{ isExpired: boolean; isPaypal: boolean }>`
  font-weight: ${props => props.theme.fontWeights.light};
  justify-self: center;
  color: ${props => props.theme.colors.neutralGrey8};
  ${props => (props.isExpired ? `opacity: 0.4;` : '')};
  ${props =>
    props.isPaypal &&
    `
      display: inline-block;
      width: 15em;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
  `};
`;

const StyledDots = styled.span<{ selected: boolean }>`
  color: ${props => (props.selected ? props.theme.colors.neutralGrey5 : props.theme.colors.neutralGrey3)};
  line-height: ${props => props.theme.pxToRem(22)};
`;

const StyledSpan = styled.span<{ isExpired: boolean }>`
  ${props => (props.isExpired ? `opacity: 0.4;` : '')}
`;
