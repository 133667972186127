import React, { useState } from 'react';
import { CheckoutCountriesListHash, CheckoutCountriesFilteredListHash } from 'mxp-utils';
import styled from 'styled-components';
import { useScript, ScriptLoadingState } from 'hooks/useScript';
import { isServer, docReady } from 'utils';
import { Loader } from 'components/atoms/Loader/Loader';
import { useSelector } from 'react-redux';
import { checkoutCountriesListTogglesSelector } from 'modules/featureToggle/selectors';
import { isB2BSelector } from 'modules/admin/selectors';
import { Checkout, ZuoraTypes } from 'mxp-schemas';
import { legalEntitySelector, saveCreditCardForFutureSelector } from 'modules/checkout/selectors';
import { handleEvent } from 'utils/Analytics';
import { ADD_PAYMENT_INFO } from 'utils/Analytics/constants';

interface Props {
  accountId: string | null;
  prepopulateFields?: { [key: string]: string | undefined };
  onCardSubmitSuccess: (data: any) => void;
  onCardSubmitError: (doSendMessage: boolean, data: any) => void;
  getHostedPageSignature: () => Promise<void>;
  onIFrameLoadingChange: (iFrameLoading: boolean) => void;
  onTurnoffLoader?: () => void;
  isModal?: boolean;
  currency?: string;
  totalAmount?: number;
}

export const CreditCardAdd: React.FC<Props> = ({
  accountId,
  prepopulateFields,
  getHostedPageSignature,
  onIFrameLoadingChange,
  onCardSubmitSuccess,
  onCardSubmitError,
  onTurnoffLoader,
  isModal = false,
  currency = 'USD',
  totalAmount = 0,
}) => {
  const zuoraScriptStatus = useScript('https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js', 'C0001');
  const [iFrameLoading, setIFrameLoading] = useState(false);
  const iFrameId: string = 'zuora_payment';
  const isB2B = useSelector(isB2BSelector);
  const toggles = useSelector(checkoutCountriesListTogglesSelector);
  const legalEntity = useSelector(legalEntitySelector);
  const saveCreditCardForFuture = useSelector(saveCreditCardForFutureSelector);
  const isCima = legalEntity?.toLowerCase() === ZuoraTypes.LegalEntity.CIMA.toLowerCase() ? true : false;
  const zuoraCallback = React.useCallback(
    event => {
      // Will catch if the iframe is submitted without input
      if (typeof event.data === 'string' && onTurnoffLoader !== undefined) {
        const eventData = JSON.parse(event.data);
        if (eventData.action === 'resize') {
          onTurnoffLoader();
        }
      }
      if (!event?.origin || event?.origin !== window?.location?.origin || typeof event.data !== 'string') return;
      try {
        const data = JSON.parse(event.data);
        if (data.responseFrom === 'Response_From_Submit_Page' && data.success === 'true') {
          if (saveCreditCardForFuture) {
            handleEvent(data, ADD_PAYMENT_INFO);
          }
          onCardSubmitSuccess(data);
        } else {
          onCardSubmitError(true, event.data);
        }
      } catch (error) {
        onCardSubmitError(false, error);
      }
    },
    [onCardSubmitSuccess, onCardSubmitError, onTurnoffLoader, saveCreditCardForFuture]
  );

  const handleIFrameLoading = () => {
    setIFrameLoading(false);
    onIFrameLoadingChange(false);
  };

  React.useEffect(() => {
    if (isServer || zuoraScriptStatus !== ScriptLoadingState.Ready) return;
    window.addEventListener('message', zuoraCallback);
    return () => {
      const iframe = (document as any).querySelector(`#${iFrameId} iframe`);
      if (iframe) iframe.addEventListener('load', handleIFrameLoading);

      window.removeEventListener('message', zuoraCallback, false);
    };
  }, [zuoraCallback, zuoraScriptStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (isServer || zuoraScriptStatus !== ScriptLoadingState.Ready) return;
    const renderedIframe = (document as any).querySelector(`#${iFrameId} iframe`);
    if (renderedIframe) renderedIframe.parentNode.removeChild(renderedIframe); // Remove iframe from DOM before load new

    const countryList: Checkout.CountriesListHash = toggles.length
      ? CheckoutCountriesFilteredListHash(toggles, isB2B)
      : CheckoutCountriesListHash;
    setIFrameLoading(true);
    getHostedPageSignature().then((action: any) => {
      (window as any).Z.render(
        {
          ...action.payload,
          style: 'inline',
          submitEnabled: false,
          field_accountId: accountId,
          countryWhiteList: Object.values(countryList).map((country: any) => country.ISOAlpha3Code),
          ...(isCima ? { authorizationAmount: totalAmount } : {}),
          field_currency: currency,
        },
        prepopulateFields || {},
        () => console.info('iframe callback')
      );
      docReady(() => {
        const iframe = (document as any).querySelector(`#${iFrameId} iframe`);

        if (iframe) iframe.addEventListener('load', handleIFrameLoading);
      });
    });
  }, [accountId, prepopulateFields, zuoraScriptStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledIframe isModal={isModal}>
      {iFrameLoading && (
        <LoaderWrapper>
          <Loader size="small" active content="Loading" inline />
        </LoaderWrapper>
      )}
      <div id={iFrameId} />
    </StyledIframe>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => `${props.theme.pxToRem(40)} 0`};
`;

const StyledIframe = styled.div<{ isModal: boolean }>`
  padding-bottom: ${props => props.theme.pxToRem(30)};
  ${props =>
    props.isModal &&
    `
      width: ${props.theme.pxToRem(455)};
      & iframe {
        width: 100%;
      }
      ${props.theme.mediaQueries.mobileOnly} {
        width: 100% !important;
      }
  `}
`;
