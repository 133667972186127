import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import {
  User,
  Product as ProductTypes,
  Salesforce,
  SalesforceResponses,
  MembershipTypes,
  Checkout,
  Product as ProductSchema,
  Contentful,
  ZuoraTypes,
} from 'mxp-schemas';
import { Product, Admin as AdminUtils, FirmAdmin as FirmAdminUtils, Utils } from 'mxp-utils';
import {
  userRolesSelector,
  isUserMemberSelector,
  tlwSelector,
  customerMembershipsSelector,
  customerCredentialsSelector,
  customerSectionsSelector,
  customerInactiveCredentialsSelector,
  customerInactiveSectionsSelector,
  customerOrderHistorySelector,
  newEmployerJobTitleSelector,
  personAccountIsHonorarySelector,
  customerInactiveMembershipsSelector,
  isUserMemberSuspendedSelector,
  currentJourneyLearningPathwaySelector,
  isFirmAICPAAffiliatedSelector,
  cimaMembershipSelector,
  cimaMembershipsTermTypeSelector,
  isEPA1CompletedSelector,
  isEPA2CompletedSelector,
  learningPathwaySelector,
  userTierSelector,
  isUserMemberLapsedSelector,
  isAicpaMemberSelector,
  userDataSelector,
  isCimaMemberSelector,
  inactiveMembershipsTermTypeSelector,
  cimaMembershipsTermTierSelector,
  isFlpToPqSwitchSelector,
  cimaMembershipTermIsTenYearsSelector,
  inactiveMembershipTermSelector,
  fcmaSfCredentialSelector,
  personAccountCountrySelector,
  isAuthSelector,
  cimaMembershipsProductIdSelector,
} from 'modules/user/selectors';
import { transformLineItemsToCartDetailItems } from 'modules/cart/helpers';
import {
  productCurrencySelector,
  productsListDataFetchedSelector,
  productsListDataSelector,
} from 'modules/products/selectors';
import { isActiveCheckDates } from 'modules/products/helpers';
import { getRenewalSeasonOverrideSelector } from 'modules/startup/selectors';
import { constantsSelector, fcmaPeriodCheckDurationSelector, isAdminPortalSelector } from 'modules/app/selectors';
import { filterProductsByCategory, seeMoreProducts, getFirstFiveProducts, getProductByType } from './helpers';
import { CONSTANTS } from 'modules/app/constants';
import { CIMA_MEMBERSHIP_ROUTES, Routes } from 'constants/index';
import {
  isServer,
  getFlattendCardVariantLabel,
  isProductForRenewal,
  isEmptyString,
  isValidRichText,
  hasTruthyValue,
  areAllTruthy,
  isRenewablePathway,
  shouldHideButtonFor3MonthRule,
} from 'utils';
import { LineItem } from 'mxp-schemas/src/types/carts';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_FLP_EMBARGO_OVERRIDE, USE_NEW_MEMBERSHIP_AICPA } from 'modules/featureToggle/constants';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.membership,
  membershipRoot => membershipRoot
);

export const membershipLoadingSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['loading'] => membership.loading
);

export const membershipTypesSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['types'] => membership.types
);

export const membershipTypesListSelector = createSelector(
  membershipTypesSelector,
  (types: State.Membership['types']): State.MembershipProductBlock[] => types.list
);

export const membershipProductSelector = createSelector(
  rootSelector,
  (membership: State.Membership): any => membership.product
);

export const roleSelectionSelector = createSelector(
  rootSelector,
  (membership: State.Membership): boolean => (membership.product as any)?.roleSelection
);

export const membershipAddonsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['addons'] => membership.addons
);

export const userMembershipPackageSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['userChoice'] => membership.userChoice
);

export const userMembershipTypeSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): string => userChoice.type.slug
);

export const userMembershipTierSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): string => userChoice.tier
);

export const userMembershipRelatedAddonsSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): State.UserChoiceAddon[] => userChoice.addons
);

export const userMembershipPathwaySelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): State.UserChoicePathway[] => userChoice.pathways
);

export const selectedPathwayBundleProductIdSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): string => userChoice.selectedPathwayBundleId
);

export const userMembershipCredentialProductIdSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): string => userChoice.credentialProductId
);

export const userMembershipSectionProductIdSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): string => userChoice.sectionProductId
);

export const userMembershipFLPVariantSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): string => userChoice.flpVariant
);

export const userMembershipChangeMyLearningPathSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): boolean => userChoice.changeMyLearningPath || false
);

export const userMembershipIsLearningPathToChangeMatchedPreviouslySelectedPathSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): boolean =>
    userChoice.isLearningPathToChangeMatchedPreviouslySelectedPath || false
);

export const isCimaMembershipJourneySelector = createSelector(rootSelector, (membership: State.Membership): boolean => {
  const currentPath = !isServer && (window as any)?.location?.pathname;
  return membership?.journeyType
    ? membership?.journeyType === MembershipTypes.MembershipJourneyType.CIMA_MEMBERSHIP_SIGN_UP
    : Object.values(CIMA_MEMBERSHIP_ROUTES)
        .map((item: any) => item?.path)
        .includes(currentPath);
});

export const membershipInviteDataSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['inviteData'] => membership.inviteData
);

export const filteredCIMAMembershipTypesSelector = createSelector(
  membershipTypesSelector,
  (types: State.Membership['types']): State.MembershipProductBlock[] => types.filteredCIMAMembershipTypes
);

export const selectedCimaMembershipKeyByUserChoiceSelector = createSelector(
  [userMembershipTypeSelector, filteredCIMAMembershipTypesSelector],
  (userMembershipType: string, filteredCIMAMembershipTypes: State.MembershipProductBlock[]): string | undefined =>
    filteredCIMAMembershipTypes?.find(typeList => typeList.slug === userMembershipType)?.membershipKey
);

export const isUpgradeToFlpRegularSelector = createSelector([rootSelector], (membership: State.Membership) => {
  const isMembershipUpgradeButtonClicked = membership.events.isClickedMembershipUpgrade;
  const isPERStatusCompleted =
    membership.practicalExperienceRequirement?.portfolio?.status === MembershipTypes.PERPortfolioStatus.COMPLETED;
  const isUpgradeToFlpRegular = isPERStatusCompleted && isMembershipUpgradeButtonClicked;

  return isUpgradeToFlpRegular;
});

export const membershipTiersSelector = createSelector(
  [
    rootSelector,
    currentJourneyLearningPathwaySelector,
    userMembershipTypeSelector,
    isCimaMembershipJourneySelector,
    selectedCimaMembershipKeyByUserChoiceSelector,
  ],
  (
    membership: State.Membership,
    currentJourneyLearningPathway: string,
    userChoiceSlug: string,
    isCimaMembershipJourney,
    selectedCimaMembershipKeyByUserChoice
  ): {
    list: State.MembershipTierBlock[];
  } => {
    const isMembershipUpgradeButtonClicked = membership.events.isClickedMembershipUpgrade;
    const isPERStatusCompleted =
      membership.practicalExperienceRequirement?.portfolio?.status === MembershipTypes.PERPortfolioStatus.COMPLETED;
    const isUpgradeToFlpRegular = isPERStatusCompleted && isMembershipUpgradeButtonClicked;
    const isUserSelectedRegularOrRetired =
      selectedCimaMembershipKeyByUserChoice === MembershipTypes.MembershipKeys.REGULAR ||
      selectedCimaMembershipKeyByUserChoice === MembershipTypes.MembershipKeys.RETIRED;
    if (
      // Affiliate Core (with FLP learningPathway) upgrading to Regular should display Regular variants not FLP
      // FLP with selected Regular/Retired membership should display Regular/Retired Tiers
      ((!isUpgradeToFlpRegular &&
        !isUserSelectedRegularOrRetired &&
        currentJourneyLearningPathway === MembershipTypes.Pathway.FLP) ||
        userChoiceSlug === MembershipTypes.CIMALearningPathwaySlug.FLP ||
        membership?.inviteData?.isFLP) &&
      isCimaMembershipJourney
    ) {
      if (!membership.product) {
        return { list: [] };
      }

      const flpVariants = Object.values(membership?.product as any)
        ?.map((product: any) => product.variants)
        ?.filter((variant: any) => variant); // removed undefined variants

      return { list: flpVariants };
    }
    return { list: membership.product?.variants || [] };
  }
);

export const tiersWithUserApplicablePricingSelector = createSelector(
  [
    membershipTiersSelector,
    userRolesSelector,
    tlwSelector,
    newEmployerJobTitleSelector,
    personAccountIsHonorarySelector,
    roleSelectionSelector,
    isCimaMembershipJourneySelector,
    userTierSelector,
    productCurrencySelector,
    membershipInviteDataSelector,
    currentJourneyLearningPathwaySelector,
    userMembershipPackageSelector,
  ],
  (
    productVariants: { list: State.MembershipTierBlock[] },
    userRoles: User.MembershipIdsEnum[],
    tlw,
    newEmployerJobTitle,
    isHonorary,
    roleSelection,
    isCimaMembershipJourney,
    userTier,
    currency,
    inviteData,
    currentJourneyLearningPathway,
    userMembershipPackage
  ): { list: State.MembershipTierBlock[] } => {
    if (!productVariants.list.length) {
      return productVariants;
    }
    let productVariantsList: State.MembershipTierBlock[] = productVariants.list;

    const otherVariants = productVariantsList.reduce((agg, product) => {
      product.otherVariants?.forEach((item: any) => agg.push(item));
      return agg;
    }, [] as State.MembershipTierBlock[]);

    const prices = Product.userApplicableVariantsPricing(
      otherVariants.length !== 0 ? otherVariants : productVariants.list,
      userRoles,
      false,
      currency.label,
      userTier
    );

    const isTlw = Boolean(MembershipTypes.EmploymentStatus.find(status => status.text === tlw.employmentStatus));

    const userRoleType = MembershipTypes.Roles.find(data => data.value === newEmployerJobTitle);

    const userTierEligibility = isTlw
      ? MembershipTypes.RoleCategoryEnum.TLW
      : isHonorary
      ? MembershipTypes.RoleCategoryEnum.HONORARY
      : userRoleType
      ? userRoleType.category
      : MembershipTypes.RoleCategoryEnum.STAFF;

    if (!isCimaMembershipJourney) {
      if (!roleSelection && isHonorary) {
        productVariantsList = productVariants.list.filter(
          (variant: any) => variant.tierLevel.key === MembershipTypes.RoleCategoryEnum.HONORARY
        );
      } else {
        productVariantsList = productVariants.list.filter(
          (variant: any) => variant.tierLevel.key === userTierEligibility
        );
      }
    } else {
      if (
        currentJourneyLearningPathway === MembershipTypes.Pathway.FLP ||
        userMembershipPackage?.type.slug === MembershipTypes.CIMALearningPathwaySlug.FLP
      ) {
        // if firm billing invite
        const isFLPInvite = inviteData?.inviteId && inviteData?.isFLP;

        const isFLPFirmEducation =
          isFLPInvite && inviteData?.organization?.type === MembershipTypes.OrganizationType.FLP_UNIVERSITY;

        const isFromCimaInvite =
          inviteData?.organization?.organizationCapabilities?.type === Salesforce.LegalEntity.CIMA;

        productVariantsList = productVariants.list
          ?.filter((variant: ProductSchema.Variant) =>
            isFLPInvite
              ? isFromCimaInvite
                ? variant?.cima_zuora_product_rate_plan !== 'N/A'
                : variant?.zuora_product_rate_plan !== 'N/A'
              : variant
          )
          ?.filter((variant: ProductSchema.Variant) =>
            isFLPFirmEducation ? variant.isForUniversity : !variant.isForUniversity
          );
      }
    }

    return transformPricing(productVariants, productVariantsList, prices);
  }
);

const transformPricing = (productVariants: any, productVariantsList: any, prices: any) => {
  return {
    ...productVariants,
    list: productVariantsList.map((variant: any) => {
      if (variant.otherVariants) {
        return {
          ...variant,
          transformedPrice: prices[variant.sku],
          otherVariants: variant.otherVariants?.map((otherVariant: any) => ({
            ...otherVariant,
            transformedPrice: prices[otherVariant.sku],
          })),
        };
      }
      return {
        ...variant,
        transformedPrice: prices[variant.sku],
      };
    }),
  };
};

export const userMembershipCredentialSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): State.UserChoiceCredential[] => userChoice.credentials
);

export const userMembershipSectionFreeProductSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): State.UserChoiceSectionFreeProduct[] => userChoice.sectionFreeProduct
);

export const userMembershipSectionProductWithPriceSelector = createSelector(
  userMembershipPackageSelector,
  (userChoice: State.Membership['userChoice']): State.UserChoiceSectionProductWithPrice[] =>
    userChoice.sectionProductWithPrice
);

export const membershipCredentialSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['credentials'] => ({
    ...membership.credentials,
    list: membership.credentials.list.map(product => ({
      ...product,
      label: getFlattendCardVariantLabel(product.variants[0], product.productType),
    })),
  })
);

export const membershipCredentialGetByProductIdSelector = (productId: string) =>
  createSelector(membershipCredentialSelector, (credentials: State.Membership['credentials']) => {
    const { list } = credentials;

    const filteredProducts = list.filter(credential => credential.productId === productId);

    return filteredProducts;
  });

export const membershipRelatedAddonsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['addons'] => ({
    ...membership.addons,
    list: membership.addons.list.map(product => ({
      ...product,
      label: getFlattendCardVariantLabel(product.variants[0], product.productType),
    })),
  })
);

export const membershipRelatedPathwaysSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['pathways'] => ({
    ...membership.pathways,
    list: membership.pathways.list.map(product => ({
      ...product,
      label: getFlattendCardVariantLabel(product.variants[0], product.productType),
    })),
  })
);

export const membershipCredentialApplicationSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['credentialQuestions'] => membership.credentialQuestions
);

export const membershipSectionRootSelector = createSelector(
  [rootSelector],
  (membership: State.Membership): State.Membership['sections'] => ({
    ...membership.sections,
  })
);

export const membershipSectionSelector = createSelector(
  [membershipSectionRootSelector, userRolesSelector, productCurrencySelector, userTierSelector],
  (
    sections: State.Membership['sections'],
    userRoles: User.MembershipIdsEnum[],
    currency: State.ProductCurrency,
    userTier: Checkout.Tiers
  ): State.Membership['sections'] => ({
    ...sections,
    listOfProductWithPrice: sections.listOfProductWithPrice.map(product => {
      const prices = Product.userApplicableVariantsPricing(
        product.variants,
        [...userRoles, User.MembershipIdsEnum.MRUSR0001],
        false, // isContribution
        currency?.label,
        userTier
      );
      return {
        ...product,
        label: `${getFlattendCardVariantLabel(product.variants[0], product.productType)}: ${product.description}`,
        variants: product.variants.map((variant: ProductTypes.Variant) => ({
          ...variant,
          price: prices[variant.sku as string].find(p => p.isLowestApplicablePrice === true),
        })),
      };
    }),
  })
);

export const membershipSeeMoreSectionSelector = createSelector(
  membershipSectionSelector,
  ({ listOfProductWithPrice, categories }: State.Membership['sections']): State.SectionWithPriceProduct[] => {
    const moreProducts = seeMoreProducts(listOfProductWithPrice, categories);

    return moreProducts;
  }
);

export const membershipSeeMoreRelatedAddOnSelector = createSelector(
  membershipRelatedAddonsSelector,
  ({ list, categories }: State.Membership['addons']): State.AddOnProducts[] => {
    const moreProducts = seeMoreProducts(list, categories);

    return moreProducts;
  }
);

export const membershipSeeMoreRelatedPathwaySelector = createSelector(
  membershipRelatedPathwaysSelector,
  ({ list, categories }: State.Membership['pathways']): State.PathwayProducts[] => {
    const moreProducts = seeMoreProducts(list, categories);

    return moreProducts;
  }
);

export const membershipSeeMoreCredentialSelector = createSelector(
  membershipCredentialSelector,
  ({ list, categories }: State.Membership['credentials']): State.CredentialProducts[] => {
    const moreProducts = seeMoreProducts(list, categories);

    return moreProducts;
  }
);

export const membershipFilterSectionByCategorySelector = (categoryName: string) =>
  createSelector(membershipSectionSelector, (sections: State.Membership['sections']) => {
    const { listOfProductWithPrice, categories } = sections;
    const { updateSelectedCategory, filteredProducts } = filterProductsByCategory(
      listOfProductWithPrice,
      categories,
      categoryName
    );

    return {
      updateSelectedCategory,
      filteredProducts,
    };
  });

export const membershipFilterCredentialByCategorySelector = (categoryName: string) =>
  createSelector(membershipCredentialSelector, (credentials: State.Membership['credentials']) => {
    const { categories, list } = credentials;

    const { updateSelectedCategory, filteredProducts } = filterProductsByCategory(list, categories, categoryName);

    return {
      updateSelectedCategory,
      filteredProducts,
    };
  });

export const membershipFilterRelatedAddOnByCategorySelector = (categoryName: string) =>
  createSelector(membershipRelatedAddonsSelector, (addOns: State.Membership['addons']) => {
    const { categories, list } = addOns;

    const { updateSelectedCategory, filteredProducts } = filterProductsByCategory(list, categories, categoryName);

    return {
      updateSelectedCategory,
      filteredProducts,
    };
  });

export const membershipFilterRelatedPathwaysByCategorySelector = (categoryName: string) =>
  createSelector(membershipRelatedPathwaysSelector, (pathways: State.Membership['pathways']) => {
    const { categories, list } = pathways;

    const { updateSelectedCategory, filteredProducts } = filterProductsByCategory(list, categories, categoryName);
    return {
      updateSelectedCategory,
      filteredProducts,
    };
  });

export const membershipFirstPageOfCredentialProductsSelector = createSelector(
  membershipCredentialSelector,
  (credentials: State.Membership['credentials']): State.ProductCredential[] => {
    const { list } = credentials;

    const products = getFirstFiveProducts(list);

    return products;
  }
);

export const membershipFirstPageOfSectionProductsSelector = createSelector(
  membershipSectionSelector,
  (sections: State.Membership['sections']): State.SectionWithPriceProduct[] => {
    const { listOfProductWithPrice } = sections;

    const products = getFirstFiveProducts(listOfProductWithPrice);

    return products;
  }
);

export const membershipFirstPageOfAddOnProductsSelector = createSelector(
  membershipRelatedAddonsSelector,
  (addOns: State.Membership['addons']): State.AddOnProduct[] => {
    const { list } = addOns;
    const products = getFirstFiveProducts(list);

    return products;
  }
);

export const membershipRelatedAddonsListSelector = createSelector(
  membershipRelatedAddonsSelector,
  (membershipAddOns: State.Membership['addons']): State.AddOnProducts[] => {
    return membershipAddOns?.list || [];
  }
);

// membership-benefits
export const userMembershipBenefitsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['benefits'] => membership.benefits
);
export const groupedMembershipBenefitsListSelector = createSelector(
  userMembershipBenefitsSelector,
  (benefits: State.Membership['benefits']): ProductTypes.MembershipBenefit[] => {
    return (benefits?.list as any) || [];
  }
);
export const membershipBenefitsListSelector = createSelector(
  userMembershipBenefitsSelector,
  (benefits: State.Membership['benefits']): ProductTypes.TransformedProduct[] => {
    return benefits?.list || [];
  }
);
export const isMembershipBenefitsFetchedSelector = createSelector(
  userMembershipBenefitsSelector,
  (benefits: State.Membership['benefits']): boolean => benefits?.isFetched
);
export const isMembershipBenefitsFetchSuccessSelector = createSelector(
  userMembershipBenefitsSelector,
  (benefits: State.Membership['benefits']): boolean => benefits?.success
);
export const isMembershipBenefitsFetchLoading = createSelector(
  userMembershipBenefitsSelector,
  (benefits: State.Membership['benefits']): boolean => benefits?.loading
);
export const userSelectedBenefitsSelector = createSelector(
  userMembershipBenefitsSelector,
  (benefits: State.Membership['benefits']): any =>
    benefits?.userSelected
      ?.filter(
        (benefit: any) =>
          benefit?.productType !== ProductTypes.ProductType.FEE &&
          benefit?.productType !== ProductTypes.ProductType.MEMBERSHIP &&
          benefit?.productId
      )
      .filter(
        // remove the duplicate data
        (benefit: any, index: number, self: any) => index === self.findIndex((t: any) => t.slug === benefit?.slug)
      )
);
export const skuSelectedBenefitsSelector = createSelector(
  userMembershipBenefitsSelector,
  (benefits: State.Membership['benefits']): any =>
    benefits?.skuSelected.filter(
      // remove the duplicate data
      (sku: string, index: number, self: any) => index === self.findIndex((s: any) => s === sku)
    )
);
export const membershipSelectedBenefitsSelector = createSelector(
  userMembershipBenefitsSelector,
  (benefits: State.Membership['benefits']): any => benefits?.membershipSelected
);

export const isRenewalSeasonSelector = createSelector(
  rootSelector,
  getRenewalSeasonOverrideSelector,
  (membership: State.Membership, override): boolean => (!!override ? override : membership.isRenewalSeason)
);

export const isRenewalSelector = createSelector(
  [isRenewalSeasonSelector, isUserMemberSelector, isUserMemberSuspendedSelector],
  (isRenewalSeason: boolean, isUserMember: boolean, isUserMemberSuspended: boolean): boolean =>
    isRenewalSeason && (isUserMember || isUserMemberSuspended)
);

export const membershipSubscriptionsSelector = createSelector(
  productsListDataSelector,
  (products): State.Membership['productsListData'] => products
);

export const isFiveYearsCandidateSelector = createSelector(
  [membershipSubscriptionsSelector, isRenewalSelector, membershipTypesSelector],
  (
    membershipSubscriptions: State.Membership['productsListData'],
    isRenewal: boolean,
    membershipTypes: State.Membership['types']
  ): boolean | undefined => {
    return membershipSubscriptions?.isFiveYearsCandidate && isRenewal && Boolean(membershipTypes?.list.length);
  }
);

export const membershipSubscriptionsFetchedSelector = createSelector(
  productsListDataFetchedSelector,
  (productListDataFetched: boolean): boolean => productListDataFetched
);

export const activeMembershipSubscriptionSelector = createSelector(
  membershipSubscriptionsSelector,
  isUserMemberLapsedSelector,
  (
    membershipSubscriptions: State.Membership['productsListData'],
    isUserMemberLapsed: boolean
  ): State.LineItemWithAccessDates | undefined => {
    const activeMembershipSubscription = membershipSubscriptions?.lineItems.find(
      (lineItem: State.LineItemWithAccessDates) =>
        lineItem.productType === ProductTypes.ProductType.MEMBERSHIP &&
        lineItem.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.ACTIVE
    );
    return isUserMemberLapsed ? undefined : activeMembershipSubscription;
  }
);

export const activeMembershipSubscriptionAddonsSelector = createSelector(
  activeMembershipSubscriptionSelector,
  productsListDataSelector,
  (activeMembershipSubscription, productsListData) => {
    const ALLOWED_ADDONS: string[] = [
      ProductSchema.ProductType.SUBSCRIPTION,
      ProductSchema.ProductType.COURSE,
      ProductSchema.ProductType.WEBCAST,
      ProductSchema.ProductType.CONFERENCE,
      ProductSchema.ProductType.PUBLICATION,
    ];
    const orderNumber = activeMembershipSubscription?.orderNumber;

    const childOrderFilter = (item: State.LineItem) =>
      item.raveParentOrderNumber === orderNumber && ALLOWED_ADDONS.includes(item.productType);
    const sameOrderFilter = (item: State.LineItem) =>
      item.orderNumber === orderNumber && ALLOWED_ADDONS.includes(item.productType);

    return productsListData?.lineItems.filter(item => childOrderFilter(item) || sameOrderFilter(item)) || [];
  }
);

export const activeFlpSubscriptionSelector = createSelector(
  membershipSubscriptionsSelector,
  (membershipSubscriptions: State.Membership['productsListData']): State.LineItemWithAccessDates | undefined =>
    membershipSubscriptions?.lineItems.find(
      (lineItem: State.LineItemWithAccessDates) =>
        lineItem.productType === ProductTypes.ProductType.FLP &&
        lineItem.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.ACTIVE
    )
);

export const canceledMembershipSubscriptionSelector = createSelector(
  membershipSubscriptionsSelector,
  (membershipSubscriptions: State.Membership['productsListData']): State.LineItemWithAccessDates | undefined =>
    membershipSubscriptions?.lineItems.find(
      (lineItem: State.LineItemWithAccessDates) =>
        lineItem.productType === ProductTypes.ProductType.MEMBERSHIP &&
        lineItem.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.CANCELLED
    )
);

export const activeCredentialsSubscriptionSelector = createSelector(
  productsListDataSelector,
  (membershipSubscriptions: State.Membership['productsListData']): State.LineItemWithAccessDates[] | undefined =>
    membershipSubscriptions?.lineItems.filter(
      (lineItem: State.LineItemWithAccessDates) =>
        lineItem.productType === ProductTypes.ProductType.CREDENTIAL &&
        lineItem?.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.ACTIVE
    )
);

export const activeMipCredentialsSubscriptionSelector = createSelector(
  productsListDataSelector,
  (membershipSubscriptions: State.Membership['productsListData']): State.LineItemWithAccessDates[] | undefined =>
    membershipSubscriptions?.lineItems.filter(
      (lineItem: State.LineItemWithAccessDates) =>
        lineItem.masterVariantSKU === MembershipTypes.CimaMip.MIP &&
        // During Active or Grace period in SF, subscription status is Active in Zuora
        lineItem?.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.ACTIVE
    )
);

export const activeSectionsSubscriptionSelector = createSelector(
  productsListDataSelector,
  (membershipSubscriptions: State.Membership['productsListData']): State.LineItemWithAccessDates[] | undefined =>
    membershipSubscriptions?.lineItems.filter(
      (lineItem: State.LineItemWithAccessDates) =>
        lineItem.productType === ProductTypes.ProductType.SECTION &&
        lineItem?.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.ACTIVE
    )
);

// get subscriptions of memberships, sections, and credentials
export const activeMemSecCredSubscriptionSelector = createSelector(
  productsListDataSelector,
  (membershipSubscriptions: State.Membership['productsListData']): State.LineItemWithAccessDates[] | undefined =>
    membershipSubscriptions?.lineItems.filter(
      (lineItem: State.LineItemWithAccessDates) =>
        [
          ProductTypes.ProductType.MEMBERSHIP,
          ProductTypes.ProductType.FLP,
          ProductTypes.ProductType.SECTION,
          ProductTypes.ProductType.CREDENTIAL,
        ].includes(lineItem.productType as ProductTypes.ProductType) &&
        lineItem?.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.ACTIVE
    )
);

export const activeMembershipAutoRenewEnabledSelector = createSelector(
  activeMembershipSubscriptionSelector,
  (activeMembershipSubscription: State.LineItemWithAccessDates | undefined): boolean =>
    !!activeMembershipSubscription?.autoRenewEnabled
);

export const membershipStateProductListDataSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.ProductsListData | null => membership.productsListData
);

export const membershipSubscriptionDonationsSelector = createSelector(
  membershipStateProductListDataSelector,
  (products: State.Membership['productsListData']): State.LineItem[] | undefined => {
    return products?.lineItems?.filter(
      (lineItem: State.LineItem) => lineItem.productType === ProductTypes.ProductType.CONTRIBUTION
    );
  }
);

export const isCenterMembershipJourneySelector = createSelector(rootSelector, (membership: State.Membership): boolean =>
  [
    `${MembershipTypes.MembershipJourneyType.CENTER_MEMBERSHIP_SIGN_UP}`,
    `${MembershipTypes.MembershipJourneyType.CENTER_MEMBERSHIP_RENEWAL}`,
  ].includes(membership.journeyType)
);

export const isCenterMembershipRenewalSelector = createSelector(
  rootSelector,
  (membership: State.Membership): boolean =>
    membership.journeyType === MembershipTypes.MembershipJourneyType.CENTER_MEMBERSHIP_RENEWAL
);

export const isMembershipJourneySelector = createSelector(
  rootSelector,
  (membership: State.Membership): boolean =>
    membership.journeyType === MembershipTypes.MembershipJourneyType.MEMBERSHIP_SIGN_UP
);

export const isCimaMipJourneySelector = createSelector(
  rootSelector,
  (membership: State.Membership): boolean =>
    membership.journeyType === MembershipTypes.MembershipJourneyType.CIMA_MIP_SIGN_UP
);

export const isMipRenewalSeasonSelector = createSelector(
  rootSelector,
  getRenewalSeasonOverrideSelector,
  (membership: State.Membership, override): boolean | undefined =>
    !!override ? override : membership.isMipRenewalSeason
);

export const membershipTypesFetchedSelector = createSelector(
  membershipTypesSelector,
  (types: State.Membership['types']): boolean => types.isFetched
);

export const membershipAddonsFetchedSelector = createSelector(
  membershipAddonsSelector,
  (addons: State.Membership['addons']): boolean => addons.isFetched
);
// individual
export const userIndividualSelector = createSelector(
  rootSelector,
  (individual: State.Membership): State.Membership['individual'] => individual.individual
);

export const individualListSelector = createSelector(
  userIndividualSelector,
  (individual: State.Membership['individual']): MembershipTypes.IndividualItems[] => {
    return individual?.list || [];
  }
);

export const searchTypeSelector = createSelector(
  userIndividualSelector,
  (individual: State.Membership['individual']): number => {
    return individual?.searchType || 1;
  }
);

export const individualTotalSizeSelector = createSelector(
  userIndividualSelector,
  (individual: State.Membership['individual']): number => {
    return individual?.totalSize || 0;
  }
);

export const isIndividualFetchedSelector = createSelector(
  userIndividualSelector,
  (individual: State.Membership['individual']): boolean => individual?.isFetched
);

export const isIndividualFetchSuccessSelector = createSelector(
  userIndividualSelector,
  (individual: State.Membership['individual']): boolean => individual?.success
);

export const isIndividualFetchLoading = createSelector(
  userIndividualSelector,
  (individual: State.Membership['individual']): boolean => individual?.loading
);
export const individualInitialSearchSelector = createSelector(
  userIndividualSelector,
  (individual: State.Membership['individual']): any => individual?.individualSearchObject
);

export const individualQuerySelector = createSelector(
  (state: any) => state.router,
  (router: any): any => {
    return router.location.pathname.replace('/directories/results/%3F', '');
  }
);

export const userScsSelector = createSelector(
  customerOrderHistorySelector,
  (orders): SalesforceResponses.ConsolidatedServiceContract[] =>
    orders.reduce((agg, order) => {
      order.lineItems.forEach(lineItem => agg.push(...lineItem.serviceContracts));
      return agg;
    }, [] as SalesforceResponses.ConsolidatedServiceContract[])
);
export const checkUserActiveBenefitsSelector = createSelector(
  groupedMembershipBenefitsListSelector,
  userScsSelector,
  (
    membershipBenefits: ProductTypes.MembershipBenefit[],
    userScs: SalesforceResponses.ConsolidatedServiceContract[]
  ): any => {
    const groupedBenefits = membershipBenefits
      ?.map((benefit: any) => {
        if (benefit?.products?.length < 2) return false;
        const serviceContract = userScs?.find(servContract => servContract.RV_SKU__c === benefit.obj.sku);
        if (!serviceContract) return false;

        const remainingQty = serviceContract.RV_TotalQuantity__c - serviceContract.cli.length;
        return remainingQty > 0;
      })
      .some(response => response);

    return groupedBenefits;
  }
);
export const userClisSelector = createSelector(
  customerOrderHistorySelector,
  (orders): SalesforceResponses.SalesforceContractLineItemRecord[] =>
    orders.reduce((agg, order) => {
      order.lineItems.forEach(lineItem => lineItem.serviceContracts.forEach(sc => agg.push(...sc.cli)));
      return agg;
    }, [] as SalesforceResponses.SalesforceContractLineItemRecord[])
);

const credentialProductMatcher = (
  orders: SalesforceResponses.ConsolidatedOrder[],
  credentials: Salesforce.Credential[],
  ctList: State.ProductsListData | null
): Salesforce.CredentialProductAndOrder[] => {
  const res = credentials.reduce(
    (agg: any, credential) => {
      if (!credential.credentialTerms || !ctList) {
        return agg;
      }
      const order = orders.find(
        o => !!o.lineItems.find(lineItem => credential?.credentialTerms?.some(ct => ct.orderLineItemId === lineItem.Id))
      );
      const ctOrder = ctList?.lineItems.find(
        ctItem =>
          ctItem?.orderNumber === order?.Name &&
          ctItem?.productType === ProductTypes.ProductType.CREDENTIAL &&
          ctItem?.variant?.sku === credential?.sku
      );

      const isUsingSfEndDate = FirmAdminUtils.areAllTruthy(
        ctOrder?.productType === ProductTypes.ProductType.CREDENTIAL,
        ctOrder?.variant?.attributes?.membershipBody?.key === ZuoraTypes.LegalEntity.CIMA
      );

      agg.push({
        credential,
        order,
        ctOrder,
        cartDetail: ctOrder
          ? {
              ...transformLineItemsToCartDetailItems([ctOrder])[0],
              ...(isUsingSfEndDate && {
                accessStartDate: credential?.since,
                accessEndDate: credential?.endDate,
              }),
            }
          : undefined,
      });
      return agg;
    },
    [] // { credential: {}, order: {}, ctOrder: {} }
  );
  return res;
};

export const currentCredentialProducts = createSelector(
  customerOrderHistorySelector,
  customerCredentialsSelector,
  productsListDataSelector,
  credentialProductMatcher
);

export const inactiveCredentialProducts = createSelector(
  customerOrderHistorySelector,
  customerInactiveCredentialsSelector,
  productsListDataSelector,
  credentialProductMatcher
);

const sectionProductMatcher = (
  orders: SalesforceResponses.ConsolidatedOrder[], // customerOrder
  sections: Salesforce.Section[], // user -> sections
  ctList: State.ProductsListData | null // productListData
): Salesforce.SectionProductAndOrder[] => {
  return sections.reduce(
    (agg: any, section) => {
      if (!section.sectionTerms) {
        return agg;
      }
      const order = orders.find(
        o => !!o.lineItems.find(lineItem => section?.sectionTerms?.some(s => s.orderLineItemId === lineItem.Id))
      );
      const ctOrder = ctList
        ? ctList?.lineItems.find(
            ctItem =>
              ctItem.orderNumber === order?.Name &&
              ctItem.productType === ProductTypes.ProductType.SECTION &&
              ctItem?.variant?.sku === section?.sku
          )
        : null;
      agg.push({
        section,
        order,
        ctOrder,
        cartDetail: ctOrder ? transformLineItemsToCartDetailItems([ctOrder])[0] : undefined,
      });
      return agg;
    },
    [] // { credential: {}, order: {}, ctOrder: {} }
  );
};

export const currentSectionProducts = createSelector(
  customerOrderHistorySelector,
  customerSectionsSelector,
  productsListDataSelector,
  sectionProductMatcher
);

export const inactiveSectionProducts = createSelector(
  customerOrderHistorySelector,
  customerInactiveSectionsSelector,
  productsListDataSelector,
  sectionProductMatcher
);

export const inactiveMembershipProduct = createSelector(
  customerOrderHistorySelector,
  customerInactiveMembershipsSelector,
  productsListDataSelector,
  (orders, inactiveMemberships, ctList): Salesforce.MembershipProductAndOrder => {
    const membership = inactiveMemberships?.[0];
    const order = orders?.find(
      o =>
        !!o?.lineItems.find(
          lineItem =>
            lineItem.Id === membership?.membershipTerm?.orderLineItemId ||
            lineItem.Id === membership?.inactiveTerms?.[0]?.orderLineItemId
        )
    );
    const ctOrder = ctList?.lineItems.find(
      ctItem => ctItem.orderNumber === order?.Name || ctItem.productType === ProductTypes.ProductType.MEMBERSHIP
    );
    const cartDetail = ctOrder ? transformLineItemsToCartDetailItems([ctOrder])[0] : undefined;
    return {
      membership,
      order,
      ctOrder,
      cartDetail,
    };
  }
);

export const isUserResignedSelector = createSelector(
  [userDataSelector, inactiveMembershipProduct],
  (data: State.Profile, inactiveMembership: Salesforce.MembershipProductAndOrder): boolean =>
    data.memberStatus === MembershipTypes.MembershipState.RESIGNED ||
    inactiveMembership?.membership?.status === Salesforce.MembershipStatus.RESIGNED ||
    data.memberStatus === MembershipTypes.MembershipState.TERMINATED
);

export const isCimaAffiliateLapsedSelector = createSelector(
  [inactiveMembershipProduct, isUserResignedSelector, isUserMemberLapsedSelector],
  (
    inactiveMembership: Salesforce.MembershipProductAndOrder,
    isUserResigned: boolean,
    isUserMemberLapsed: boolean
  ): boolean =>
    AdminUtils.isCimaAffiliateType(
      inactiveMembership?.membership?.membershipBody || '',
      inactiveMembership?.membership?.inactiveTerms?.[0]?.membershipTermType || ''
    ) &&
    (inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED || isUserResigned) &&
    isUserMemberLapsed
);

export const isAicpaAffiliateLapsedSelector = createSelector(
  [inactiveMembershipProduct, isUserResignedSelector, isUserMemberLapsedSelector],
  (
    inactiveMembership: Salesforce.MembershipProductAndOrder,
    isUserResigned: boolean,
    isUserMemberLapsed: boolean
  ): boolean =>
    AdminUtils.isAicpaAffiliateType(
      inactiveMembership?.membership?.membershipBody || '',
      inactiveMembership?.membership?.inactiveTerms?.[0]?.membershipTermType || ''
    ) &&
    (inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED || isUserResigned) &&
    isUserMemberLapsed
);

export const isCimaRegularLapsedSelector = createSelector(
  [inactiveMembershipProduct, isUserResignedSelector, isUserMemberLapsedSelector],
  (
    inactiveMembership: Salesforce.MembershipProductAndOrder,
    isUserResigned: boolean,
    isUserMemberLapsed: boolean
  ): boolean =>
    AdminUtils.isCimaRegularType(
      inactiveMembership?.membership?.membershipBody || '',
      inactiveMembership?.membership?.inactiveTerms?.[0]?.membershipTermType || ''
    ) &&
    (inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED || isUserResigned) &&
    isUserMemberLapsed
);

export const isCimaRetiredLapsedSelector = createSelector(
  [inactiveMembershipProduct, isUserResignedSelector, isUserMemberLapsedSelector],
  (
    inactiveMembership: Salesforce.MembershipProductAndOrder,
    isUserResigned: boolean,
    isUserMemberLapsed: boolean
  ): boolean =>
    AdminUtils.isCimaRetiredType(
      inactiveMembership?.membership?.membershipBody || '',
      inactiveMembership?.membership?.inactiveTerms?.[0]?.membershipTermType || ''
    ) &&
    (inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED || isUserResigned) &&
    isUserMemberLapsed
);

export const isCimaPQCandidateLapsedSelector = createSelector(
  [inactiveMembershipProduct, learningPathwaySelector, isUserResignedSelector, isUserMemberLapsedSelector],
  (
    inactiveMembership: Salesforce.MembershipProductAndOrder,
    learningPathway: MembershipTypes.Pathway | null | undefined,
    isUserResigned: boolean,
    isUserMemberLapsed: boolean
  ): boolean =>
    AdminUtils.isCimaPQCandidateProductType(
      learningPathway || '',
      inactiveMembership?.membership?.inactiveTerms?.[0]?.membershipTermType || ''
    ) &&
    (inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED || isUserResigned) &&
    isUserMemberLapsed
);

export const isCimaPQApprenticeLapsedSelector = createSelector(
  [inactiveMembershipProduct, learningPathwaySelector, isUserResignedSelector, isUserMemberLapsedSelector],
  (
    inactiveMembership: Salesforce.MembershipProductAndOrder,
    learningPathway: MembershipTypes.Pathway | null | undefined,
    isUserResigned: boolean,
    isUserMemberLapsed: boolean
  ): boolean =>
    AdminUtils.isCimaApprenticeCandidateProductType(
      learningPathway || '',
      inactiveMembership?.membership?.inactiveTerms?.[0]?.membershipTermType || ''
    ) &&
    (inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED || isUserResigned) &&
    isUserMemberLapsed
);

export const isCimaFLPLapsedSelector = createSelector(
  [inactiveMembershipProduct, learningPathwaySelector, isUserResignedSelector, isUserMemberLapsedSelector],
  (
    inactiveMembership: Salesforce.MembershipProductAndOrder,
    learningPathway: MembershipTypes.Pathway | null | undefined,
    isUserResigned: boolean,
    isUserMemberLapsed: boolean
  ): boolean =>
    learningPathway === MembershipTypes.Pathway.FLP &&
    (inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED || isUserResigned) &&
    isUserMemberLapsed
);

export const isCimaMembershipLapsedSelector = createSelector(
  [inactiveMembershipProduct, isCimaFLPLapsedSelector, isUserResignedSelector, isUserMemberLapsedSelector],
  (
    inactiveMembership: Salesforce.MembershipProductAndOrder,
    isCimaFLPLapsed: boolean,
    isUserResigned: boolean,
    isUserMemberLapsed: boolean
  ): boolean =>
    (inactiveMembership?.membership?.membershipBody === MembershipTypes.MembershipBody.CIMA &&
      (inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED || isUserResigned) &&
      isUserMemberLapsed) ||
    isCimaFLPLapsed
);

export const inactiveMemTierCodeSelector = createSelector(
  inactiveMembershipProduct,
  (inactiveMembership: Salesforce.MembershipProductAndOrder): string =>
    inactiveMembership?.ctOrder?.variant?.attributes?.tierCode?.key ||
    inactiveMembership?.membership?.inactiveTerms?.[0]?.membershipTermTier ||
    ''
);

export const currentMembershipProduct = createSelector(
  customerOrderHistorySelector,
  customerMembershipsSelector,
  productsListDataSelector,
  (orders, memberships, ctList): Salesforce.MembershipProductAndOrder => {
    const membership = memberships[0];
    const order = orders.find(
      o => !!o?.lineItems.find(lineItem => lineItem.Id === membership?.membershipTerm?.orderLineItemId)
    );

    const ctOrder = ctList?.lineItems.find(ctItem =>
      ctItem.productType === ProductTypes.ProductType.FLP
        ? ctItem.orderNumber === order?.Name &&
          (ctItem.variant?.attributes?.includedCimaMembershipType === membership?.membershipTerm?.productId ||
            ctItem.variant?.attributes?.includedMembershipType === membership?.membershipTerm?.productId ||
            ctItem.variant?.attributes?.cpaHolderMembership === membership?.membershipTerm?.productId ||
            ctItem.orderNumber === membership?.membershipTerm?.zuoraOrderNumber)
        : ctItem.orderNumber === order?.Name && ctItem.variant?.sku === membership?.membershipTerm?.productId
    );

    const cartDetail = ctOrder ? transformLineItemsToCartDetailItems([ctOrder])[0] : undefined;
    return {
      membership,
      order,
      ctOrder,
      cartDetail,
    };
  }
);

export const isLastMembershipLapsedSelector = createSelector(
  [currentMembershipProduct, inactiveMembershipProduct],
  (
    currentMembership: Salesforce.MembershipProductAndOrder,
    inactiveMembership: Salesforce.MembershipProductAndOrder
  ): boolean => {
    if (currentMembership?.membership) {
      return currentMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED;
    }
    // to check inactiveMembership when currentMembership is empty
    return inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED;
  }
);

export const deprovisionProductsSelector = createSelector(
  productsListDataSelector,
  (ctList): Array<{ orderId: string; skusForItemsBeingDeprovisioned: string[] }> => {
    const productTypesForDeprovision = [
      ProductTypes.ProductType.MEMBERSHIP,
      ProductTypes.ProductType.SECTION,
      ProductTypes.ProductType.CREDENTIAL,
      ProductTypes.ProductType.FLP,
      ProductTypes.ProductType.CONTRIBUTION,
    ];
    if (ctList) {
      const { lineItems } = ctList;
      const deprovisionProducts = lineItems.filter(lineItem =>
        productTypesForDeprovision.includes(lineItem.productType as ProductTypes.ProductType)
      );
      const uniqueOrderId: string[] = [];
      const deprovisionPayload: Array<{ orderId: string; skusForItemsBeingDeprovisioned: string[] }> = [];

      if (deprovisionProducts) {
        // Getting the orderIds
        deprovisionProducts.forEach(product => {
          if (!uniqueOrderId.some(id => id === product.orderId)) {
            uniqueOrderId.push(product.orderId as string);
          }
        });

        // Getting the products to be deprovisioned per orderId
        uniqueOrderId.forEach(orderId => {
          const filteredProducts = deprovisionProducts.filter(product => product.orderId === orderId);
          const skusForItemsBeingDeprovisioned: string[] = [];
          filteredProducts.forEach(product => {
            skusForItemsBeingDeprovisioned.push(product.variant?.sku as string);
          });
          deprovisionPayload.push({ orderId, skusForItemsBeingDeprovisioned });
        });

        return deprovisionPayload;
      }
      return [];
    }
    return [];
  }
);

export const currentMembershipTypeSelector = createSelector(
  customerMembershipsSelector,
  productsListDataSelector,
  (memberships, ctList): State.LineItem => {
    const membership = memberships[0];

    const membershipType = ctList?.lineItems.find(
      ctItem => ctItem.variant?.sku === membership?.membershipTerm?.productId
    ) as unknown as State.LineItem;

    return membershipType;
  }
);

export const isMembershipForRenewalSelector = createSelector(
  currentMembershipProduct,
  (membership: Salesforce.MembershipProductAndOrder): boolean => {
    // For AICPA: User can renew within 3 months or 90 days before latest term expiry date
    const currentDate = moment();
    const latestMembershipExpiryDate = membership?.membership?.allTerms?.[0]?.expiryDate;
    const renewalStartCycle = moment(latestMembershipExpiryDate).subtract(3, 'months');
    return currentDate.isSameOrAfter(renewalStartCycle);
  }
);

export const userHasMembershipBenefitsToSelectSelector = createSelector(
  currentMembershipProduct,
  (membership: Salesforce.MembershipProductAndOrder): string[] =>
    membership?.order?.lineItems?.reduce(
      (agg: string[], lineItem: any) =>
        agg.concat(
          lineItem.serviceContracts?.filter((sc: any) => sc.cli.length < sc.RV_TotalQuantity__c).map((sc: any) => sc.Id)
        ),
      []
    ) || []
);

export const currentMembershipSubscriptionSelector = createSelector(
  membershipSubscriptionsSelector,
  (membershipSubscriptions: State.Membership['productsListData']) => {
    const membership = membershipSubscriptions?.lineItems.find(
      (lineItem: State.LineItemWithAccessDates) =>
        lineItem.productType === ProductTypes.ProductType.MEMBERSHIP &&
        lineItem.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.ACTIVE
    );
    return membership;
  }
);

export const currentMembershipSubscriptionKeySelector = createSelector(
  membershipSubscriptionsSelector,
  (membershipSubscriptions: State.Membership['productsListData']): string => {
    const key = membershipSubscriptions?.lineItems.find(
      (lineItem: State.LineItemWithAccessDates) =>
        lineItem.productType === ProductTypes.ProductType.MEMBERSHIP &&
        lineItem.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.ACTIVE
    )?.variant?.attributes.membershipKey?.key;
    // added flpName here when renewing FLP and changing to CIMA PQ scenario for hiding proration banner
    const flpName = membershipSubscriptions?.lineItems.find(
      (lineItem: State.LineItemWithAccessDates) =>
        lineItem.productType === ProductTypes.ProductType.FLP &&
        lineItem.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.ACTIVE
    )?.variant?.attributes.flpName;
    return key || flpName || '';
  }
);

export const membershipTypeBySlugSelector = (slug: string) =>
  createSelector(membershipTypesSelector, types => {
    const { list } = types;

    const filteredProduct = list.find(type => type.slug === slug);

    return {
      id: filteredProduct?.id,
    };
  });

export const membershipEligibleCredentialSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['membershipEligibleCredential'] =>
    membership.membershipEligibleCredential
);

export const membershipEligibleSectionSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['membershipEligibleSection'] => membership.membershipEligibleSection
);

export const membershipUpdateApprenticeLevelSelector = createSelector(
  rootSelector,
  (membership: State.Membership): boolean => membership.isUpdateApprenticeLevel
);

export const membershipProductsLineItemsSelector = createSelector(rootSelector, membership =>
  membership.productsListData?.lineItems?.map(member => ({
    type: member.name,
    productType: member.productType,
    tier: member.variant?.sku,
    productSlug: member.productSlug,
    orderDate: member.orderDate,
    membershipKey: member.variant?.attributes?.membershipKey?.key,
    membershipBody: member.variant?.attributes?.membershipBody?.key,
  }))
);

export const membershipProductListDataSelector = createSelector(membershipSubscriptionsSelector, lineItem =>
  lineItem?.lineItems
    .filter(item => item.productType === ProductTypes.ProductType.MEMBERSHIP)
    .map(value => {
      return {
        type: value.name,
        tier: value.variant?.sku,
        productSlug: value.productSlug,
        orderDate: value.orderDate,
      };
    })
);

export const isCimaRegularMembershipSelector = createSelector(membershipSubscriptionsSelector, lineItem =>
  lineItem?.lineItems?.some(item => item.productSlug === MembershipTypes.CimaMembershipProductSlug.CIMA_REGULAR_PRODUCT)
);

export const sectionProductListDataSelector = createSelector(membershipSubscriptionsSelector, lineItem =>
  lineItem?.lineItems
    .filter(item => item.productType === ProductTypes.ProductType.SECTION)
    .map(value => {
      return {
        id: value.id,
        type: value.name,
        sku: value.variant?.sku,
        productSlug: value.productSlug,
      };
    })
);

export const productsToBeDisabledIfMembershipIsDisabledSelector = createSelector(
  activeCredentialsSubscriptionSelector,
  activeSectionsSubscriptionSelector,
  (credentials, sections): string[] => {
    const AREnabledCredentials = credentials?.filter(cred => cred?.autoRenewEnabled);
    const AREnabledSections = sections?.filter(sec => sec?.autoRenewEnabled);

    const AREnabledSubscriptionNumberIds: any = [].concat(
      AREnabledCredentials?.map(cred => cred?.subscriptionNumber) as any,
      AREnabledSections?.map(sec => sec?.subscriptionNumber) as any
    );
    return AREnabledSubscriptionNumberIds;
  }
);

export const hasExistingCredentialSelector = createSelector(productsListDataSelector, productListData => {
  const products = getProductByType(productListData, ProductTypes.ProductType.CREDENTIAL);

  return !!products.length;
});

export const hasExistingSectionSelector = createSelector(productsListDataSelector, productListData => {
  const products = getProductByType(productListData, ProductTypes.ProductType.SECTION);

  return !!products.length;
});

export const hasOutstandingMembershipSelector = createSelector(productsListDataSelector, productListData => {
  const products = getProductByType(productListData, ProductTypes.ProductType.MEMBERSHIP);
  return !!products.length;
});

export const hasOutstandingSectionSelector = createSelector(productsListDataSelector, productListData => {
  const products = getProductByType(productListData, ProductTypes.ProductType.SECTION);
  return !!products.length;
});

export const hasOutstandingCredentialSelector = createSelector(productsListDataSelector, productListData => {
  const products = getProductByType(productListData, ProductTypes.ProductType.CREDENTIAL);
  return !!products.length;
});

export const existingCredentialSelector = createSelector(productsListDataSelector, productListData => {
  return getProductByType(productListData, ProductTypes.ProductType.CREDENTIAL);
});

export const existingSectionSelector = createSelector(productsListDataSelector, productListData => {
  return getProductByType(productListData, ProductTypes.ProductType.SECTION);
});

export const existingFreeSectionSelector = createSelector(productsListDataSelector, productListData => {
  const now = new Date();
  return (
    productListData?.lineItems.filter(
      (item: State.LineItem) =>
        item.productType === ProductTypes.ProductType.SECTION &&
        item.orderNumber?.startsWith('BEN') &&
        isActiveCheckDates(item as any, now)
    ) || []
  );
});

export const membershipSubscriptionLineItemsSelector = createSelector(
  membershipSubscriptionsSelector,
  (membership: State.Membership['productsListData']): State.LineItem[] => membership?.lineItems || []
);

export const getMembershipTypeSubscriptionSelector = createSelector(
  membershipSubscriptionLineItemsSelector,
  (lineItems: State.LineItem[]): ProductTypes.MembershipApplicationType => {
    return Product.checkProductMembershipApplicationType(lineItems);
  }
);

export const getMembershipTypesSubscriptionSelector = createSelector(
  membershipSubscriptionLineItemsSelector,
  (lineItems: State.LineItem[]): ProductTypes.MembershipApplicationType[] => {
    return Product.getProductMembershipApplicationTypes(lineItems);
  }
);

export const practicalExperienceRequirementSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['practicalExperienceRequirement'] =>
    membership.practicalExperienceRequirement
);

export const practicalExperienceRequirementStatusSelector = createSelector(
  rootSelector,
  (membership: State.Membership): string | null => membership.practicalExperienceRequirement?.portfolio?.status
);

export const practicalExpReqEmploymentRecordSelector = (id: string, recordType: string) =>
  createSelector(
    practicalExperienceRequirementSelector,
    (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']) => {
      return practicalExperienceRequirement.employment.find((data: any) => data.id === id);
    }
  );

export const showFeedbackSelector = (id: string, recordType: string) =>
  createSelector(practicalExpReqEmploymentRecordSelector(id, recordType), formData => {
    return (
      formData?.status === MembershipTypes.PracticalExperienceRequirementStatus.ACTION_REQUIRED ||
      formData?.status === MembershipTypes.PracticalExperienceRequirementStatus.APPROVED ||
      formData?.status === MembershipTypes.PracticalExperienceRequirementStatus.SIGNED_OFF
    );
  });

export const showPERButtonsSelector = (id: string, recordType: string) =>
  createSelector(practicalExpReqEmploymentRecordSelector(id, recordType), formData => {
    return (
      formData?.status === MembershipTypes.PracticalExperienceRequirementStatus.APPROVED ||
      formData?.status === MembershipTypes.PracticalExperienceRequirementStatus.SIGNED_OFF
    );
  });
export const practicalExpReqSkillsActivitesRecordSelector = (id: string, recordType: string) =>
  createSelector(
    practicalExperienceRequirementSelector,
    (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']) => {
      switch (recordType) {
        case 'skills':
          return practicalExperienceRequirement.skills.find((data: any) => data.id === id);
        case 'activities':
          return practicalExperienceRequirement.activities.find((data: any) => data.id === id);
        default:
          return practicalExperienceRequirement.skills.find((data: any) => data.id === id);
      }
    }
  );

export const searchOrganizationsByWildcardLoadingSelector = createSelector(
  practicalExperienceRequirementSelector,
  (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']): boolean =>
    practicalExperienceRequirement.searchOrganizationsByWildcardLoading
);

export const searchResultOrganizationsSelector = createSelector(
  practicalExperienceRequirementSelector,
  (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']) =>
    practicalExperienceRequirement.searchResultOrganizations
);

export const searchOrganizationsCitiesLoadingSelector = createSelector(
  practicalExperienceRequirementSelector,
  (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']): boolean =>
    practicalExperienceRequirement.searchOrganizationsCitiesLoading
);

export const searchResultOrganizationsCitiesSelector = createSelector(
  practicalExperienceRequirementSelector,
  (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']) =>
    practicalExperienceRequirement.searchResultOrganizationsCities
);

export const employerAlreadyExistsSelector = createSelector(
  practicalExperienceRequirementSelector,
  (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']): boolean =>
    practicalExperienceRequirement.employerAlreadyExists
);

export const newEmployerSelector = createSelector(
  practicalExperienceRequirementSelector,
  (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']): State.NewAccountPersonAccount =>
    practicalExperienceRequirement.newEmployer
);

export const userSelectedCredentialsSelector = createSelector(
  membershipCredentialSelector,
  (credentials: State.Membership['credentials']): any[] => credentials.userSelected
);

export const membershipFLPProductsSelector = createSelector(membershipProductSelector, (products: any): any => {
  if (!products) {
    return [];
  }
  return Object.values(products);
});

export const isComingFromPropPageSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['isComingFromPropPage'] => membership.isComingFromPropPage
);

export const isCredentialsJourneySelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['isCredentialsJourney'] => membership.isCredentialsJourney
);

export const isRenewalsJourneySelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['isRenewalsJourney'] => membership.isRenewalsJourney
);

export const hasSelectedCredentialSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['hasSelectedCredential'] => membership.hasSelectedCredential
);

export const hasSelectedSectionSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['hasSelectedSection'] => membership.hasSelectedSection
);

export const hasSelectedTierSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['hasSelectedTier'] => membership.hasSelectedTier
);

export const hasSelectedTypeSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['hasSelectedType'] => membership.hasSelectedType
);

export const hasSelectedAddOnsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['hasSelectedType'] => membership.hasSelectedAddOns
);

export const isFLPSwitchSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['isFLPSwitch'] => membership.isFLPSwitch
);

export const isFLPUpgradeSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['isFLPUpgrade'] => membership.isFLPUpgrade
);

export const isFLPSwitchOrUpgradeSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['isFLPUpgrade'] => membership.isFLPSwitch || membership.isFLPUpgrade
);

export const flpPersonExam = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['flpPersonExam'] => membership.flpPersonExam
);

export const addCartLoadingSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['addCartLoading'] => membership.addCartLoading
);

export const sectionsCredentialsRenewalSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['sectionsCredentialsRenewal'] =>
    membership.sectionsCredentialsRenewal
);

export const membershipTierSelector = createSelector(
  customerMembershipsSelector,
  membershipProductSelector,
  (memberships, selectedProduct): any => {
    const current: string = memberships[0]?.membershipTerm?.productId || '';
    const upgrade: string[] = [];
    const downgrade: string[] = [];

    if (selectedProduct) {
      const currentVariant = selectedProduct?.variants?.find((variant: any) => variant?.sku === current);

      if (!currentVariant) {
        return {
          upgrade,
          current,
          downgrade,
        };
      }
      let isUpgrading = false;
      selectedProduct?.variants?.forEach((variant: any) => {
        if (!isUpgrading && variant.sku !== current) {
          downgrade.push(variant.sku);
        } else if (isUpgrading && variant.sku !== current) {
          upgrade.push(variant.sku);
        } else {
          isUpgrading = true;
        }
      });
    }

    return {
      upgrade,
      current,
      downgrade,
    };
  }
);

export const membershipRenewalsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['renewals'] => membership.renewals
);

export const membershipEventSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['events'] => membership.events
);

export const clickedMembershipUpgradeSelector = createSelector(
  membershipEventSelector,
  (events: State.Membership['events']): boolean => events.isClickedMembershipUpgrade
);

export const clickedBeginApplicationSelector = createSelector(
  membershipEventSelector,
  (events: State.Membership['events']): boolean => events.isBeginApplicationClicked
);

export const clickedSectionRenewalSelector = createSelector(
  membershipEventSelector,
  (events: State.Membership['events']): boolean => events.isClickedCredentialsSectionsRenewal
);

export const clickedMembershipRenewalSelector = createSelector(
  membershipEventSelector,
  (events: State.Membership['events']): boolean => events.isClickedMembershipRenewal
);

export const clickedCimaMembershipRenewalInProfileSelector = createSelector(
  membershipEventSelector,
  (events: State.Membership['events']): boolean => events.isClickedCimaMembershipRenewalInProfile
);

export const clickedCimaMembershipRenewalInFeedSelector = createSelector(
  membershipEventSelector,
  (events: State.Membership['events']): boolean => events.isClickedCimaMembershipRenewalInFeed
);

export const clickedCimaMembershipRenewalSelector = createSelector(
  [
    clickedCimaMembershipRenewalInProfileSelector,
    clickedCimaMembershipRenewalInFeedSelector,
    clickedMembershipRenewalSelector,
  ],
  (
    isClickedCimaMembershipRenewalInProfile: boolean,
    isClickedCimaMembershipRenewalInFeed: boolean,
    isClickedMembershipRenewal: boolean
  ): boolean =>
    isClickedCimaMembershipRenewalInProfile || isClickedCimaMembershipRenewalInFeed || isClickedMembershipRenewal
);

export const clickedMembershipBuyAgainSelector = createSelector(
  membershipEventSelector,
  (events: State.Membership['events']): boolean => events.isClickedMembershipBuyAgain
);

export const membershipPropPageURLSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['propPageUrl'] => membership.propPageUrl
);

export const currentMembershipSubscriptionSlugSelector = createSelector(
  membershipSubscriptionsSelector,
  isUserMemberLapsedSelector,
  inactiveMembershipProduct,
  (
    membershipSubscriptions: State.Membership['productsListData'],
    isUserMemberLapsed: boolean,
    inactiveMembership: Salesforce.MembershipProductAndOrder
  ): string => {
    if (isUserMemberLapsed) {
      return inactiveMembership?.membership?.slug || '';
    }
    const slug = membershipSubscriptions?.lineItems.find(
      (lineItem: State.LineItemWithAccessDates) => lineItem.productType === ProductTypes.ProductType.MEMBERSHIP
    )?.productSlug;
    return slug || '';
  }
);

export const currentMembershipSubscriptionRelatedProducts = createSelector(
  currentMembershipSubscriptionSelector,
  productsListDataSelector,
  (currentMembershipSubscription, productsListData): Common.ProductItemData[] => {
    const ctOrder = productsListData
      ? productsListData.lineItems.filter(
          (data: State.LineItem) =>
            !([ProductTypes.ProductType.MEMBERSHIP, ProductTypes.ProductType.FEE] as string[]).includes(
              data.productType
            ) && data.subscriptionStatus === 'Active'
        )
      : [];
    return ctOrder.length > 0 ? transformLineItemsToCartDetailItems(ctOrder) : [];
  }
);

export const currentMembershipSubscriptionSkuSelector = createSelector(
  currentMembershipSubscriptionSelector,
  isUserMemberLapsedSelector,
  inactiveMembershipProduct,
  (
    currentMembershipSubscription: State.LineItem | undefined,
    isUserMemberLapsed: boolean,
    inactiveMembership: Salesforce.MembershipProductAndOrder
  ): string => {
    const sku = isUserMemberLapsed
      ? inactiveMembership?.membership?.inactiveTerms?.[0]?.productId
      : currentMembershipSubscription?.variant?.sku;

    return sku || '';
  }
);

export const allMembershipTypesSelector = createSelector(
  membershipTypesSelector,
  (types: State.Membership['types']): State.MembershipProductBlock[] => types.allCIMAMembershipTypes
);

export const currentMembershipProductIdSelector = createSelector(
  [
    allMembershipTypesSelector,
    currentMembershipSubscriptionSkuSelector,
    cimaMembershipsTermTypeSelector,
    isUserMemberLapsedSelector,
    inactiveMembershipsTermTypeSelector,
  ],
  (
    allMembershipTypes: State.MembershipProductBlock[],
    currentMembershipSubscriptionSku: string,
    currentMembershipTermType: string | undefined,
    isUserMemberLapsed: boolean,
    inactiveMembershipTermType: string
  ): string => {
    if (isUserMemberLapsed) {
      return allMembershipTypes.find(type => type?.membershipKey === inactiveMembershipTermType)?.id || '';
    }
    return (
      allMembershipTypes.find(
        type =>
          type.skus?.includes(currentMembershipSubscriptionSku) || type?.membershipKey === currentMembershipTermType
      )?.id || ''
    );
  }
);

export const isCimaRenewalSeasonSelector = createSelector(
  rootSelector,
  getRenewalSeasonOverrideSelector,
  (membership: State.Membership, override): boolean | undefined =>
    !!override ? override : membership.isCimaRenewalSeason
);

export const currentMembershipProductSkuSelector = createSelector(
  membershipSubscriptionsSelector,
  currentMembershipSubscriptionSkuSelector,
  cimaMembershipsProductIdSelector,
  (
    membershipSubscriptions: State.Membership['productsListData'],
    currentMembershipSubscriptionSku: string,
    cimaMembershipProductId: string
  ): string => {
    const sku = membershipSubscriptions?.lineItems.find(
      (lineItem: State.LineItemWithAccessDates) =>
        lineItem.productType === ProductTypes.ProductType.MEMBERSHIP &&
        lineItem.subscriptionStatus === ProductTypes.ZuoraSubscriptionStatus.ACTIVE
    )?.variant?.sku;
    return cimaMembershipProductId || sku || currentMembershipSubscriptionSku || '';
  }
);

export const isCimaRenewalSelector = createSelector(
  [isCimaRenewalSeasonSelector, isUserMemberSelector, isUserMemberSuspendedSelector],
  (isRenewalSeason: boolean | undefined, isUserMember: boolean, isUserMemberSuspended: boolean): boolean | undefined =>
    isRenewalSeason && (isUserMember || isUserMemberSuspended)
);

export const selectedMembershipTierCodeSelector = createSelector(
  membershipSubscriptionsSelector,
  (membershipSubscriptions: State.Membership['productsListData']): string => {
    const tierCode = membershipSubscriptions?.lineItems.find(
      (lineItem: State.LineItemWithAccessDates) => lineItem.productType === ProductTypes.ProductType.MEMBERSHIP
    )?.variant?.attributes?.tierCode?.key;
    return tierCode || '';
  }
);

export const isOpenEmploymentModalFromInviteSelector = createSelector(
  rootSelector,
  (membership: State.Membership): boolean => membership.isOpenEmploymentModalFromInvite
);

export const isFirmAffiliatedForCPEAOrPCPSSelector = createSelector(
  [isFirmAICPAAffiliatedSelector, userMembershipTypeSelector],
  (isFirmAICPAAffiliated: boolean, userMembershipType: string): boolean =>
    isFirmAICPAAffiliated && (AdminUtils.isCPEAType(userMembershipType) || AdminUtils.isPCPSType(userMembershipType))
);

export const isCimaAffiliateCanUpgradeSelector = createSelector(
  [
    cimaMembershipsTermTypeSelector,
    cimaMembershipSelector,
    practicalExperienceRequirementStatusSelector,
    selectedMembershipTierCodeSelector,
    isEPA1CompletedSelector,
    isEPA2CompletedSelector,
    learningPathwaySelector,
    currentJourneyLearningPathwaySelector,
  ],
  (
    membershipType: string,
    membership: Salesforce.FirmMembership | undefined,
    perStatus: string | null,
    tierCode: string,
    isEPA1Completed: boolean,
    isEPA2Completed: boolean,
    learningPathway: MembershipTypes.Pathway | null | undefined,
    currentlearningPathwaySelector: string
  ): boolean => {
    const isApprenticePathway = hasTruthyValue(
      AdminUtils.isApprenticePathway(learningPathway as string),
      AdminUtils.isApprenticePathway(currentlearningPathwaySelector as string)
    );
    const cimaMemBody = membership?.membershipBody as string;
    const isSubscribedToCimaAffiliate = AdminUtils.isCimaAffiliateType(cimaMemBody, membershipType);
    const isPERStatusCompleted = perStatus === MembershipTypes.PERPortfolioStatus.COMPLETED;
    const isCimaCore = tierCode === MembershipTypes.TierCode.CORE;
    const isCimaPre = tierCode === MembershipTypes.TierCode.PRE;

    return (
      isSubscribedToCimaAffiliate &&
      hasTruthyValue(
        areAllTruthy(isCimaCore, isPERStatusCompleted),
        areAllTruthy(isCimaPre, isEPA1Completed, isEPA2Completed),
        isApprenticePathway
      )
    );
  }
);

export const isUserAicpaMemberSelector = createSelector(
  currentMembershipProduct,
  (currentMembership: Salesforce.MembershipProductAndOrder): boolean =>
    currentMembership?.membership?.membershipBody === MembershipTypes.MembershipBody.AICPA
);

export const isUserRegularAicpaMemberSelector = createSelector(
  currentMembershipProduct,
  (currentMembership: Salesforce.MembershipProductAndOrder): boolean =>
    currentMembership?.membership?.membershipBody === MembershipTypes.MembershipBody.AICPA &&
    currentMembership?.membership?.membershipTerm?.membershipTermType === MembershipTypes.MembershipKeys.REGULAR
);

export const isCimaCandidateTypeSelector = createSelector(
  cimaMembershipsTermTypeSelector,
  (membershipTermType: any): boolean => {
    return membershipTermType === MembershipTypes.MembershipKeys.CANDIDATE;
  }
);

export const numberOfDowngradesSelector = createSelector(
  [customerMembershipsSelector],
  (memberships: Salesforce.FirmMembership[]): number => {
    const allTerms = memberships?.[0]?.allTerms;

    // get number of downgrades
    let numberOfDowngrades = 0;
    allTerms?.forEach((item: any, index: number) => {
      if (
        index > 0 &&
        allTerms?.[index - 1]?.membershipTermType === MembershipTypes.MembershipKeys.CGMA_AFFILIATE &&
        item?.membershipTermType === MembershipTypes.MembershipKeys.CANDIDATE
      ) {
        numberOfDowngrades += 1;
      }
    });

    return numberOfDowngrades;
  }
);

export const membershipEntryLevelsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['entryLevels'] => membership.entryLevels
);

export const membershipOfflineExemptionsEntryLevelsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['offlineExemptionsEntryLevels'] =>
    membership.offlineExemptionsEntryLevels
);

export const membershipTierNameSelector = createSelector(
  [membershipTiersSelector, userMembershipTierSelector, userMembershipTypeSelector],
  (productVariants: { list: State.MembershipTierBlock[] }, tierCode: string, slug: string): string | undefined => {
    const productFound = productVariants.list?.find(product => product.sku === tierCode || product.slug === slug);
    return productFound?.name ?? productFound?.tierName ?? tierCode;
  }
);

export const selectedBenefitsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): any => membership.benefits.selectedBenefits
);

export const isFLPFirmBillingSelector = createSelector(
  rootSelector,
  (membership: State.Membership): boolean | undefined => membership.inviteData?.isFLP
);

export const membershipLoggedOutInviteDataSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['loggedOutInviteData'] => membership.loggedOutInviteData
);

export const hasNotMetMinimumRequirementsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['hasNotMetMinimumRequirements'] =>
    membership.hasNotMetMinimumRequirements
);

export const isAllowToAddFcmaInCartSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['isAllowToAddFcmaInCart'] => membership.isAllowToAddFcmaInCart
);

export const hasSelectedFcmaDesignationSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['hasSelectedFcmaDesignation'] =>
    membership.hasSelectedFcmaDesignation
);

export const selectedAttestationsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['attestationsToQuery'] => membership.attestationsToQuery
);

export const attestationsSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['attestations'] => membership.attestations
);

export const attestationsIsFetchedSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['attestationsIsFetched'] => membership.attestationsIsFetched
);

export const isFlpModalOpenSelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['flpModal'] => membership.flpModal
);

export const isCimaMembershipPageJourneySelector = createSelector(
  rootSelector,
  (membership: State.Membership): State.Membership['isCimaMembershipPageJourney'] =>
    membership.isCimaMembershipPageJourney
);

export const isCimaCgmaAffiliateSelector = createSelector(
  currentMembershipProduct,
  (currentMembership: Salesforce.MembershipProductAndOrder): boolean => {
    const cimaMemBody = currentMembership?.membership?.membershipBody as string;
    const cimaMemType = currentMembership?.membership?.membershipTerm?.membershipTermType as string;
    return AdminUtils.isCimaCgmaAffiliateType(cimaMemBody, cimaMemType);
  }
);
export const clickedFcmaPropPageSelector = createSelector(
  membershipEventSelector,
  (events: State.Membership['events']): boolean => events.isClickedFcmaPropPage
);

export const hasActiveFlpProductSelector = createSelector(
  activeFlpSubscriptionSelector,
  (activeFlpSubscription: State.LineItemWithAccessDates | undefined): boolean => Boolean(activeFlpSubscription)
);

export const isCimaPqCandidateRenewalSelector = createSelector(
  [isCimaCandidateTypeSelector, learningPathwaySelector, currentJourneyLearningPathwaySelector, isCimaRenewalSelector],
  (
    isCimaCandidateType: boolean | undefined,
    learningPathway: MembershipTypes.Pathway | null | undefined,
    currentJourneyLearningPathway: string,
    isCimaRenewal: boolean | undefined
  ): boolean =>
    Boolean(
      isCimaCandidateType &&
        learningPathway &&
        AdminUtils.isCimaPqPathway(learningPathway) &&
        AdminUtils.isCimaPqPathway(currentJourneyLearningPathway) &&
        isCimaRenewal
    )
);

// use only if current learning pathway is FLP and renewing FLP products
export const isFlpRenewalSelector = createSelector(
  constantsSelector,
  customerMembershipsSelector,
  learningPathwaySelector,
  isUserMemberLapsedSelector,
  currentJourneyLearningPathwaySelector,
  (
    constants,
    customerMemberships,
    learningPathway,
    isUserMemberLapsed: boolean,
    currentJourneyLearningPathway
  ): boolean => {
    if (isUserMemberLapsed) {
      return false;
    }
    const membershipEndDate = moment(customerMemberships?.[0]?.allTerms?.[0].expiryDate);
    const today = moment();
    const membershipDiffDays = membershipEndDate.diff(today, 'days');
    const flpRenewalSeasonOpen = constants?.[CONSTANTS.FLP_RENEWAL_SEASON_OPEN] ?? 90;

    return (
      learningPathway === MembershipTypes.Pathway.FLP &&
      membershipDiffDays <= flpRenewalSeasonOpen &&
      currentJourneyLearningPathway === MembershipTypes.Pathway.FLP
    );
  }
);

export const inactiveMembershipTypeSelector = createSelector(
  inactiveMembershipProduct,
  (inactiveMembership: Salesforce.MembershipProductAndOrder): { type: string; tier: string } => {
    if (
      hasTruthyValue(
        !inactiveMembership.membership?.membershipBody,
        !inactiveMembership.membership?.inactiveTerms?.[0]?.membershipTermType
      )
    ) {
      return { type: '', tier: '' };
    }
    const inactiveMembershipTier = inactiveMembership.membership?.inactiveTerms?.[0]?.membershipTermTier || '';
    const inactiveMembershipName = `${inactiveMembership.membership?.membershipBody} Membership - ${inactiveMembership.membership?.inactiveTerms?.[0]?.membershipTermType}`;
    return { type: inactiveMembershipName, tier: inactiveMembershipTier };
  }
);

export const isL7CandidateUpgradeSelector = createSelector(
  [cimaMembershipsTermTypeSelector, cimaMembershipsTermTierSelector, isEPA1CompletedSelector, isEPA2CompletedSelector],
  (cimaMembershipsTermType: string, tierCode: string, isEPA1Completed: boolean, isEPA2Completed: boolean): boolean => {
    const isL7CandidateUpgrade =
      ((cimaMembershipsTermType === MembershipTypes.MembershipKeys.CANDIDATE &&
        tierCode === MembershipTypes.TierCode.APPRENTICE_L7) ||
        (cimaMembershipsTermType === MembershipTypes.MembershipKeys.AFFILIATE &&
          tierCode === MembershipTypes.TierCode.CORE)) &&
      isEPA1Completed &&
      isEPA2Completed;
    return isL7CandidateUpgrade || false;
  }
);

export const clickedMembershipUpgradeAndFLPSwitchOrUpgradeSelector = createSelector(
  membershipEventSelector,
  rootSelector,
  isL7CandidateUpgradeSelector,
  isFlpToPqSwitchSelector,
  cimaMembershipTermIsTenYearsSelector,
  (
    events,
    membership,
    isL7CandidateUpgrade,
    isFlpToPqSwitch,
    cimaMembershipTermIsTenYears
  ): {
    isClickedMembershipUpgrade: boolean;
    isFLPSwitchOrUpgrade: boolean;
    isL7CandidateUpgrade: boolean;
    isFlpToPqSwitch: boolean;
    cimaMembershipTermIsTenYears: boolean;
  } => {
    return {
      isClickedMembershipUpgrade: events.isClickedMembershipUpgrade, // membership type change
      isFLPSwitchOrUpgrade: membership.isFLPSwitch || membership.isFLPUpgrade,
      isL7CandidateUpgrade,
      isFlpToPqSwitch,
      cimaMembershipTermIsTenYears,
    };
  }
);

export const isCimaCandidateApprenticeLapsedRenewalSelector = createSelector(
  isCimaPQApprenticeLapsedSelector,
  clickedCimaMembershipRenewalInProfileSelector,
  clickedMembershipBuyAgainSelector,
  (isCimaApprenticeLapsed, isClickedCimaMembershipRenewalInProfile, isClickedMembershipBuyAgain): boolean => {
    const isCimaCandidateApprenticeLapsedRenewal =
      isCimaApprenticeLapsed && (isClickedCimaMembershipRenewalInProfile || isClickedMembershipBuyAgain);
    return isCimaCandidateApprenticeLapsedRenewal || false;
  }
);

export const isApprenticeCandidateForRenewalSelector = createSelector(
  [
    learningPathwaySelector,
    cimaMembershipsTermTypeSelector,
    isCimaRenewalSelector,
    activeMembershipSubscriptionSelector,
    selectedMembershipTierCodeSelector,
  ],
  (learningPathway, cimaMembershipsTermType, isCimaRenewal, activeMembership, selectedMembershipTierCode): boolean => {
    const isApprenticePathway = AdminUtils.isApprenticePathway(learningPathway as string);
    const isCandidate = cimaMembershipsTermType === MembershipTypes.MembershipKeys.CANDIDATE;
    const activeMembershipTierCode = activeMembership?.variant?.attributes?.tierCode?.key;
    const isApprenticeCandidateForRenewal =
      isCimaRenewal && isApprenticePathway && isCandidate && activeMembershipTierCode === selectedMembershipTierCode;
    return isApprenticeCandidateForRenewal || false;
  }
);

export const isApprenticeRegularForRenewalSelector = createSelector(
  [
    learningPathwaySelector,
    cimaMembershipsTermTypeSelector,
    isCimaRenewalSelector,
    activeMembershipSubscriptionSelector,
    selectedMembershipTierCodeSelector,
  ],
  (learningPathway, cimaMembershipsTermType, isCimaRenewal, activeMembership, selectedMembershipTierCode): boolean => {
    const isApprenticePathway = AdminUtils.isApprenticePathway(learningPathway as string);
    const isCandidate = cimaMembershipsTermType === MembershipTypes.MembershipKeys.REGULAR;
    const activeMembershipTierCode = activeMembership?.variant?.attributes?.tierCode?.key;
    const isApprenticeRegularForRenewal =
      isCimaRenewal && isApprenticePathway && isCandidate && activeMembershipTierCode === selectedMembershipTierCode;
    return isApprenticeRegularForRenewal || false;
  }
);

export const isApprenticeAffiliateForRenewalSelector = createSelector(
  [
    learningPathwaySelector,
    cimaMembershipsTermTypeSelector,
    isCimaRenewalSelector,
    activeMembershipSubscriptionSelector,
    selectedMembershipTierCodeSelector,
  ],
  (learningPathway, cimaMembershipsTermType, isCimaRenewal, activeMembership, selectedMembershipTierCode): boolean => {
    const isApprenticePathway = AdminUtils.isApprenticePathway(learningPathway as string);
    const isCandidate = cimaMembershipsTermType === MembershipTypes.MembershipKeys.AFFILIATE;
    const activeMembershipTierCode = activeMembership?.variant?.attributes?.tierCode?.key;
    const isApprenticeAffiliateForRenewal =
      isCimaRenewal && isApprenticePathway && isCandidate && activeMembershipTierCode === selectedMembershipTierCode;
    return isApprenticeAffiliateForRenewal || false;
  }
);

export const isUserHasMultipleTermsSelector = createSelector(
  customerMembershipsSelector,
  (customerMemberships: Salesforce.FirmMembership[]): boolean => {
    return customerMemberships[0]?.allTerms ? customerMemberships[0]?.allTerms?.length > 1 : false;
  }
);

export const isMembershipRenewedSelector = createSelector(
  customerMembershipsSelector,
  (customerMemberships: Salesforce.FirmMembership[]): boolean => {
    // if the customerMemberships latest term - current date month diff is greater than 6, it means that membership is already renewed.
    // Membership renewed is based on months instead of years to accommodate any cycle configured in CT
    // const currentDate = moment();
    // const latestMembershipTermDate = moment(customerMemberships?.[0]?.allTerms?.[0].expiryDate);
    // const monthDiff = Math.floor(latestMembershipTermDate.diff(currentDate, 'months', true));
    // return UserUtils.conditionalFunction(customerMemberships[0]?.allTerms, monthDiff > 6, false);
    return false;
  }
);

export const isAicpaDueRenewalSelector = createSelector(
  isRenewalSeasonSelector,
  isUserHasMultipleTermsSelector,
  isMembershipRenewedSelector,
  isMembershipForRenewalSelector,
  isAicpaMemberSelector,
  isUserMemberSuspendedSelector,
  isCimaMemberSelector,
  (
    isRenewalSeason,
    isUserHasMultipleTerms,
    isMembershipRenewed,
    isMembershipForRenewal,
    isUserAicpaMember,
    isUserMemberSuspended,
    isUserCimaMember
  ) => {
    return (
      isRenewalSeason &&
      (!isUserHasMultipleTerms || !isMembershipRenewed) &&
      isMembershipForRenewal &&
      (isUserAicpaMember || isUserMemberSuspended) &&
      !isUserCimaMember
    );
  }
);

export const isCimaDueRenewalSelector = createSelector(
  isCimaMemberSelector,
  isUserMemberSuspendedSelector,
  isCimaRenewalSeasonSelector,
  learningPathwaySelector,
  customerMembershipsSelector,
  constantsSelector,
  isUserHasMultipleTermsSelector,
  isMembershipRenewedSelector,
  (
    isCimaMember,
    isUserMemberSuspended,
    isCimaRenewalSeason,
    learningPathway,
    customerMemberships,
    constants,
    isUserHasMultipleTerms,
    isMembershipRenewed
  ) => {
    const today = moment();
    const membershipFlpEndDate = moment(customerMemberships?.[0]?.allTerms?.[0].expiryDate);
    const membershipDiffDays = membershipFlpEndDate.diff(today, 'days');
    const flpRenewalSeasonOpen = constants?.[CONSTANTS.FLP_RENEWAL_SEASON_OPEN];
    const isPathwayRenewable =
      learningPathway === MembershipTypes.Pathway.FLP ? membershipDiffDays <= flpRenewalSeasonOpen : true;

    return (
      (isCimaMember || isUserMemberSuspended) &&
      isCimaRenewalSeason &&
      isPathwayRenewable &&
      (!isUserHasMultipleTerms || !isMembershipRenewed)
    );
  }
);

export const isNonLapsedCimaMemberForRenewalSelector = createSelector(
  [isMembershipRenewedSelector, isAicpaMemberSelector, isUserMemberLapsedSelector],
  (isMembershipRenewed: boolean, isUserAicpaMember: boolean | undefined, isUserLapsed: boolean): boolean => {
    return areAllTruthy(!isMembershipRenewed, !isUserAicpaMember, !isUserLapsed);
  }
);

export const isCimaDueRenewalButtonSelector = createSelector(
  [
    customerMembershipsSelector,
    isNonLapsedCimaMemberForRenewalSelector,
    constantsSelector,
    isCimaRenewalSelector,
    learningPathwaySelector,
  ],
  (
    customerMemberships: Salesforce.FirmMembership[],
    isNonLapsedCimaMemberForRenewal: boolean,
    constants: null | Contentful.Constants.Constants,
    isCimaRenewalCondition: boolean | undefined,
    learningPathway: MembershipTypes.Pathway | null | undefined
  ): boolean => {
    const latestMembershipEndDate = moment(customerMemberships?.[0]?.allTerms?.[0].expiryDate);
    const isPathwayRenewable = isRenewablePathway(learningPathway, latestMembershipEndDate, constants);
    const hideButtonFor3MonthRule = shouldHideButtonFor3MonthRule(latestMembershipEndDate);

    return areAllTruthy(
      isCimaRenewalCondition,
      isNonLapsedCimaMemberForRenewal,
      isPathwayRenewable,
      !hideButtonFor3MonthRule
    );
  }
);

export const isFlpDueRenewalSelector = createSelector(
  isUserMemberSelector,
  isUserMemberSuspendedSelector,
  isUserHasMultipleTermsSelector,
  isMembershipRenewedSelector,
  learningPathwaySelector,
  customerMembershipsSelector,
  constantsSelector,
  hasActiveFlpProductSelector,
  (
    isUserMember,
    isUserMemberSuspended,
    isUserHasMultipleTerms,
    isMembershipRenewed,
    learningPathway,
    customerMemberships,
    constants,
    hasActiveFlpProduct
  ) => {
    const today = moment();
    const membershipFlpEndDate = moment(customerMemberships?.[0]?.allTerms?.[0].expiryDate);
    const membershipDiffDays = membershipFlpEndDate.diff(today, 'days');
    const flpRenewalSeasonOpen = constants?.[CONSTANTS.FLP_RENEWAL_SEASON_OPEN];
    const isPathwayRenewable =
      learningPathway === MembershipTypes.Pathway.FLP ? membershipDiffDays <= flpRenewalSeasonOpen : true;

    return (
      (isUserMember || isUserMemberSuspended) &&
      (!isUserHasMultipleTerms || !isMembershipRenewed) &&
      isPathwayRenewable &&
      learningPathway === MembershipTypes.Pathway.FLP &&
      hasActiveFlpProduct
    );
  }
);

export const isSectionRenewalSelector = createSelector(
  [isRenewalSeasonSelector, activeSectionsSubscriptionSelector],
  (isRenewalSeason, activeSections = []) => {
    if (!isRenewalSeason || !activeSections.length) return false;

    const now = new Date();

    return activeSections
      .filter((lineItem: any) => isActiveCheckDates(lineItem, now))
      .some(section => isProductForRenewal(section.zuoraTermEndDate || section.accessEndDate || ''));
  }
);

export const isCredentialRenewalSelector = createSelector(
  [isRenewalSeasonSelector, activeCredentialsSubscriptionSelector],
  (isRenewalSeason, activeCredentials = []) => {
    if (!isRenewalSeason || !activeCredentials.length) return false;

    const now = new Date();

    return activeCredentials
      .filter((lineItem: any) => isActiveCheckDates(lineItem, now))
      .some(credential => isProductForRenewal(credential.zuoraTermEndDate || credential.accessEndDate || ''));
  }
);

export const isSectionCredentialRenewalSelector = createSelector(
  [isSectionRenewalSelector, isCredentialRenewalSelector],
  (isSectionRenewal, isCredentialRenewal) => isSectionRenewal || isCredentialRenewal
);

export const hasActiveMigratedAcmaAndCgmaCredentialsSelector = createSelector(
  [productsListDataSelector, customerCredentialsSelector],
  (productsList, userSFCredentials) => {
    const hasAcmaCredential =
      productsList?.lineItems?.some(
        cred =>
          cred?.productType === ProductSchema.ProductType.CREDENTIAL &&
          cred?.subscriptionStatus !== ProductSchema.ZuoraSubscriptionStatus.CANCELLED &&
          cred?.variant?.attributes?.credentialKey?.key === ProductSchema.CredentialKey.ACMA &&
          cred?.variant?.sku === ProductSchema.CIMA_CREDENTIALS_SKU.ACMA &&
          !cred?.orderNumber?.includes('_CIMAACMA01') // not manually created CT order. E.g a3C4U000001zBOFUA2_CIMAACMA01
      ) || false;

    const hasCgmaCredential =
      productsList?.lineItems?.some(
        cred =>
          cred?.productType === ProductSchema.ProductType.CREDENTIAL &&
          cred?.subscriptionStatus !== ProductSchema.ZuoraSubscriptionStatus.CANCELLED &&
          cred?.variant?.attributes?.credentialKey?.key === ProductSchema.CredentialKey.CGMA &&
          cred?.variant?.sku === ProductSchema.CIMA_CREDENTIALS_SKU.CGMA &&
          !cred?.orderNumber?.includes('_CIMACGMA01') // not manually created CT order. E.g a3C4U000001zB4oUAE_CIMACGMA01
      ) || false;

    const hasAcmaSfCredential =
      userSFCredentials?.some(
        cred =>
          cred?.sku === ProductSchema.CIMA_CREDENTIALS_SKU.ACMA &&
          cred?.status === ProductSchema.ZuoraSubscriptionStatus.ACTIVE
      ) || false;

    const hasCgmaSfCredential =
      userSFCredentials?.some(
        cred =>
          cred?.sku === ProductSchema.CIMA_CREDENTIALS_SKU.CGMA &&
          cred?.status === ProductSchema.ZuoraSubscriptionStatus.ACTIVE
      ) || false;

    // If ACMA and CGMA does not exist in Products state, use the details from Salesforce (user -> credentials)
    const hasActiveMigratedAcmaAndCgmaCredentials =
      hasAcmaSfCredential && hasCgmaSfCredential && !hasAcmaCredential && !hasCgmaCredential;

    return hasActiveMigratedAcmaAndCgmaCredentials;
  }
);

export const isUserNotFLPandSwitchOrUpgradeSelector = createSelector(
  isFLPSwitchSelector,
  clickedMembershipUpgradeSelector,
  (isFLPSwitch: State.Membership['isFLPSwitch'], isclickedMembershipUpgrade: any) =>
    (
      shouldRedirectToCredentialPage: boolean,
      isTrainingProvider: boolean,
      membershipApplicationType: string
    ): User.MembershipApplicationStages => {
      const isApplicationDonation = User.MembershipApplicationStages.DONATION;
      const isUserTrainingProvider = isTrainingProvider
        ? User.MembershipApplicationStages.DOCUMENT
        : User.MembershipApplicationStages.TRAINING;
      const isCredentialsPage = User.MembershipApplicationStages.CREDENTIALS;
      const isUserCima = membershipApplicationType === ProductSchema.MembershipApplicationType.CIMA;
      const isCimaAndNotUpgradeorFlpSwitch = [isUserCima, !isclickedMembershipUpgrade, !isFLPSwitch].every(
        (flag: boolean) => flag
      );
      if (shouldRedirectToCredentialPage) {
        return isCredentialsPage;
      }
      if (isCimaAndNotUpgradeorFlpSwitch) {
        return isUserTrainingProvider;
      }
      return isApplicationDonation;
    }
);

// Same flag used for:
// 1. Renew membership in Profile Membership Card
// 2. Renew now in Page Home Feed
// 3. Renew your membership in Page My Profile
export const isRenewalButtonSelector = createSelector(
  [isAicpaDueRenewalSelector, isCimaDueRenewalButtonSelector],
  (isAicpaRenewal: boolean, isCimaRenewal: boolean): { isAicpaRenewal: boolean; isCimaRenewal: boolean } => {
    return { isAicpaRenewal, isCimaRenewal };
  }
);

export const practicalExpReqRecordSelector = (formData: any, data: any) =>
  createSelector(
    practicalExperienceRequirementSelector,
    (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']) => {
      const { formDataEmployment } = practicalExperienceRequirement;

      const newEmploymentSupervisor = !formData
        ? (data?.newEmployment.supervisor as MembershipTypes.PracticalExperienceRequirementSupervisor)
        : data?.newEmployment.supervisor?.name;

      const practicalExpReqRecord = newEmploymentSupervisor
        ? data?.newEmployment.supervisor
        : (formDataEmployment.supervisor as MembershipTypes.PracticalExperienceRequirementSupervisor);

      return practicalExpReqRecord;
    }
  );

export const activityFeedbackButtonsSelector = (perId: any, recordType: any) =>
  createSelector(practicalExpReqSkillsActivitesRecordSelector(perId, recordType), formData => {
    const isFeedbackActivity = Boolean(
      formData?.status === MembershipTypes.PracticalExperienceRequirementStatus.ACTION_REQUIRED ||
        formData?.status === MembershipTypes.PracticalExperienceRequirementStatus.APPROVED ||
        formData?.status === MembershipTypes.PracticalExperienceRequirementStatus.SIGNED_OFF
    );

    const isButtonsActivity = Boolean(
      formData?.status === MembershipTypes.PracticalExperienceRequirementStatus.APPROVED ||
        formData?.status === MembershipTypes.PracticalExperienceRequirementStatus.SIGNED_OFF
    );

    return { isFeedbackActivity, isButtonsActivity };
  });

export const supervisorDataSelector = (perId: any, data: any) =>
  createSelector(
    practicalExperienceRequirementSelector,
    (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']) => {
      const { formDataEmployment } = practicalExperienceRequirement;
      const supervisorData = perId ? formDataEmployment.supervisor : data?.newEmployment.supervisor;
      return supervisorData;
    }
  );

export const invalidPERRecordSelector = (activitySkills: any, data: any) =>
  createSelector(
    practicalExperienceRequirementSelector,
    (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']) => {
      const { formDataEmployment } = practicalExperienceRequirement;
      const invalidRichTextEditorValue =
        Object.values(activitySkills)
          .filter(formDataValues => typeof formDataValues === 'string')
          .includes('<p><br data-cke-filler="true"></p>') ||
        !Object.values(activitySkills).filter(formDataValues => typeof formDataValues === 'string');
      const invalidateFields: boolean =
        isEmptyString(activitySkills?.situation || '') ||
        isEmptyString(activitySkills?.task || '') ||
        isEmptyString(activitySkills?.action || '') ||
        !isValidRichText(activitySkills?.task || '') ||
        isEmptyString(activitySkills?.result || '') ||
        invalidRichTextEditorValue;
      const hasError =
        !invalidateFields &&
        (!isEmptyString(data?.newEmployment?.supervisor?.employer?.name || '') ||
          !isEmptyString(formDataEmployment?.supervisor?.name || ''))
          ? false
          : true;
      return hasError;
    }
  );

export const activityDropdownPERSelector = (perId: any) =>
  createSelector(
    practicalExpReqSkillsActivitesRecordSelector(perId, 'activities'),
    practicalExperienceRequirementSelector,
    (formData, practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']) => {
      const coreActivitiesOptions = Object.values(MembershipTypes.PracticalExperienceCoreActivity) as string[];
      const selectedSkillActivities = practicalExperienceRequirement.activities.map(
        ({ activityType: userActivity }) => userActivity
      );

      const activityDropdownOptions = coreActivitiesOptions
        .filter(
          (activityItem: string) =>
            !selectedSkillActivities.includes(activityItem as MembershipTypes.PracticalExperienceCoreActivity) ||
            activityItem === formData?.activityType
        )
        .map((value: any, index) => {
          return { key: index, value, text: value };
        });

      return activityDropdownOptions;
    }
  );

export const skillsDropdownPERSelector = (perId: any) =>
  createSelector(
    practicalExpReqSkillsActivitesRecordSelector(perId, 'skills'),
    practicalExperienceRequirementSelector,
    (formData, practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']) => {
      const skillsBehaviorOptions = Object.values(MembershipTypes.PracticalExperienceSkillsBehavior) as string[];
      const selectedSkillActivities = practicalExperienceRequirement.skills.map(
        ({ activityType: userActivity }) => userActivity
      );

      const skillsDropdownOptions = skillsBehaviorOptions
        .filter(
          (skillItem: string) =>
            !selectedSkillActivities.includes(skillItem as MembershipTypes.PracticalExperienceSkillsBehavior) ||
            skillItem === formData?.activityType
        )
        .map((value: any, index) => {
          return { key: index, value, text: value };
        });

      return skillsDropdownOptions;
    }
  );

export const currentMembershipEntitySelector = createSelector(
  currentMembershipProduct,
  (currentMembership: Salesforce.MembershipProductAndOrder): string | undefined =>
    currentMembership?.membership?.membershipBody
);

export const inactiveMembershipEntitySelector = createSelector(
  inactiveMembershipProduct,
  (inactiveMembership: Salesforce.MembershipProductAndOrder): string | undefined =>
    inactiveMembership?.membership?.membershipBody
);

export const flpNonMemberProductNameSelector = createSelector(
  [userScsSelector, currentMembershipEntitySelector],
  (productName, membershipEntity) => {
    const desiredProductName =
      membershipEntity === MembershipTypes.MembershipBody.AICPA
        ? MembershipTypes.MembershipTypeTitle.AICPA_CAN
        : MembershipTypes.MembershipTypeTitle.CIMA_CAN;

    return productName.find(obj => obj.RV_ProductName__c === desiredProductName);
  }
);

export const isCenterMembershipSelector = createSelector(
  rootSelector,
  (membership: State.Membership): boolean =>
    membership.journeyType === MembershipTypes.MembershipJourneyType.CENTER_MEMBERSHIP_SIGN_UP
);

export const isCimaRegularRenewalSelector = createSelector(
  [isRenewalsJourneySelector, isCimaMembershipJourneySelector, selectedCimaMembershipKeyByUserChoiceSelector],
  (isRenewalsJourney, isCimaMembershipJourney, selectedCimaMembershipKeyByUserChoice): boolean => {
    const isSelectedCimaMembershipKeyByUserChoiceRegular =
      selectedCimaMembershipKeyByUserChoice === MembershipTypes.MembershipKeys.REGULAR;
    return isRenewalsJourney && isCimaMembershipJourney && isSelectedCimaMembershipKeyByUserChoiceRegular;
  }
);

export const isCimaCgmaAffiliateLapsedSelector = createSelector(
  [inactiveMembershipProduct, isUserResignedSelector, isUserMemberLapsedSelector],
  (
    inactiveMembership: Salesforce.MembershipProductAndOrder,
    isUserResigned: boolean,
    isUserMemberLapsed: boolean
  ): boolean =>
    areAllTruthy(
      AdminUtils.isCimaCgmaAffiliateType(
        String(inactiveMembership?.membership?.membershipBody),
        String(inactiveMembership?.membership?.inactiveTerms?.[0]?.membershipTermType)
      ),
      hasTruthyValue(inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED, isUserResigned),
      isUserMemberLapsed
    )
);

export const customerSalesforceMembershipSelector = createSelector(customerMembershipsSelector, memberships => {
  const membership = memberships[0]; // current term in sf
  if (hasTruthyValue(!membership?.membershipBody, !membership?.membershipTerm?.membershipTermType)) {
    return { membershipType: '', membershipTier: '' };
  }
  const membershipType = `${membership?.membershipBody} Membership - ${membership?.membershipTerm?.membershipTermType}`;
  const membershipTier = membership?.membershipTerm?.membershipTermTier;

  return { membershipType, membershipTier };
});

export const fcmaCredentialProductSelector = createSelector(
  membershipSubscriptionsSelector,
  (productsListData: State.Membership['productsListData']): State.LineItem | undefined =>
    productsListData?.lineItems.find(lineItem =>
      areAllTruthy(
        lineItem?.productType === ProductSchema.ProductType.CREDENTIAL,
        lineItem?.variant?.attributes?.credentialKey?.key === ProductSchema.CredentialKey.FCMA
      )
    )
);

export const hasFcmaCredentialTermSelector = createSelector(
  [customerCredentialsSelector, customerInactiveCredentialsSelector, fcmaCredentialProductSelector],
  (
    customerCredentials: Salesforce.Credential[],
    customerInactiveCredentials: Salesforce.Credential[],
    fcmaCredentialProductLineItem: State.LineItem | undefined
  ): boolean =>
    [...customerCredentials, ...customerInactiveCredentials].some(
      (credential: Salesforce.Credential) => credential?.sku === fcmaCredentialProductLineItem?.variant?.sku
    )
);

export const invitedLapsedLinkSelector = createSelector(
  [membershipInviteDataSelector],
  (inviteData: State.Membership['inviteData']): Routes => {
    const isAssociation =
      inviteData?.organization?.organizationCapabilities?.type === Salesforce.LegalEntity.ASSOCIATION;
    const isFLP = inviteData?.isFLP;

    if (inviteData?.isPaidByFLP || inviteData?.isPaidByFirm) {
      if (isAssociation && !isFLP) return Routes.APPLICATION_FORM_ATTESTATION;
      return Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION;
    }

    if (isAssociation && !isFLP) return Routes.APPLICATION_FORM_DONATIONS;
    return Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS;
  }
);

export const perFetchSuccessSelector = createSelector(
  practicalExperienceRequirementSelector,
  (practicalExperienceRequirement: State.Membership['practicalExperienceRequirement']): boolean =>
    practicalExperienceRequirement.perFetchSuccess
);

export const membershipProgressBarSelector = createSelector(
  [
    isRenewalButtonSelector,
    isRenewalsJourneySelector,
    isCredentialsJourneySelector,
    membershipEventSelector,
    isUserMemberSelector,
  ],
  (
    { isAicpaRenewal },
    isRenewalsJourney,
    isCredentialsJourney,
    { isClickedSectionsJourney },
    isUserMember
  ): {
    isMembershipForRenewal: boolean;
    isCredentialMemberJourney: boolean;
    isSectionsJourney: boolean;
  } => {
    const isSectionsJourney = isClickedSectionsJourney;
    const isCredentialMemberJourney = isCredentialsJourney && isUserMember;
    const isMembershipForRenewal =
      (isAicpaRenewal || isRenewalsJourney) && !isCredentialsJourney && !isClickedSectionsJourney && isUserMember;
    return { isMembershipForRenewal, isCredentialMemberJourney, isSectionsJourney };
  }
);

export const hasCimaRegularMembershipSelector = createSelector(
  membershipSubscriptionsSelector,
  (productListData: State.Membership['productsListData']) =>
    productListData?.lineItems?.some((item: LineItem): boolean | undefined =>
      areAllTruthy(
        item?.variant?.attributes?.membershipBody?.label === MembershipTypes.MembershipBody.CIMA,
        item?.variant?.attributes?.membershipKey?.key === MembershipTypes.MembershipKeys.REGULAR
      )
    )
);

export const hasCimaRetiredMembershipSelector = createSelector(
  membershipSubscriptionsSelector,
  (productListData: State.Membership['productsListData']) =>
    productListData?.lineItems?.some((item: LineItem): boolean | undefined =>
      areAllTruthy(
        item?.variant?.attributes?.membershipBody?.label === MembershipTypes.MembershipBody.CIMA,
        item?.variant?.attributes?.membershipKey?.key === MembershipTypes.MembershipKeys.RETIRED
      )
    )
);

export const joiningFeePriceSelector = createSelector(
  [membershipTypesSelector, productCurrencySelector],
  (membershipTypes, currency): string => {
    const joiningFee = membershipTypes.joiningFee;
    if (joiningFee) {
      const priceInfoForUser = Product.getProductPrice(
        joiningFee,
        joiningFee?.variants?.[0]?.sku || '',
        [User.MembershipIdsEnum.MRUSR0001],
        currency.label
      );
      return Utils.centPriceToString(priceInfoForUser?.priceFinal?.amount || 0, priceInfoForUser?.priceFinal?.currency);
    }
    return '';
  }
);

export const isAddFcmaCredentialInCartSelector = createSelector(
  [
    fcmaSfCredentialSelector,
    fcmaPeriodCheckDurationSelector,
    clickedCimaMembershipRenewalSelector,
    isCimaRegularLapsedSelector,
    isCimaRetiredLapsedSelector,
    cimaMembershipsTermTypeSelector,
  ],
  (
    fcmaSfCredential,
    fcmaPeriodCheckDuration,
    isClickedCimaMembershipRenewal,
    isCimaRegularLapsed,
    isCimaRetiredLapsed,
    cimaMembershipsTermType
  ) => {
    // If user has no FCMA credential record in SF, return false. Else, continue with the succeeding logic
    if (!Boolean(Object.keys(fcmaSfCredential || {}).length)) return false;

    const fcmaCredentialExpiryDate = moment(fcmaSfCredential?.credentialTerms[0]?.expiryDate); // SFS_CRT_ExpiryDate__c
    const currentDate = moment().format('YYYY-MM-DD');
    const expiryAndCurrentDateDifference = Math.floor(fcmaCredentialExpiryDate.diff(currentDate, 'days', true));
    const isCurrentMembershipTermTypeRegularOrRetired = [
      MembershipTypes.MembershipKeys.REGULAR,
      MembershipTypes.MembershipKeys.RETIRED,
    ].includes(cimaMembershipsTermType as MembershipTypes.MembershipKeys);

    const isAddFcmaCredentialInCart = areAllTruthy(
      expiryAndCurrentDateDifference <= parseInt(fcmaPeriodCheckDuration, 10), // 90 days
      hasTruthyValue(
        areAllTruthy(isClickedCimaMembershipRenewal, isCurrentMembershipTermTypeRegularOrRetired), // Current Membership Term Type/Key For Renewal is Regular or Retired
        isCimaRegularLapsed, // Lapsed Regular Membership Rejoining
        isCimaRetiredLapsed // Lapsed Retired Membership Rejoining
      )
    );

    return isAddFcmaCredentialInCart;
  }
);

export const isEmbargoedCountry = createSelector(
  [personAccountCountrySelector, constantsSelector],
  (userCountry: string | undefined, constant: null | Contentful.Constants.Constants) => {
    return constant?.embargoed_countries?.includes(userCountry);
  }
);

export const isFLPEmbargoedSelector = createSelector(
  [
    isEmbargoedCountry,
    isAdminPortalSelector,
    isAuthSelector,
    state => getFeatureToggleByKeySelector(state, USE_FLP_EMBARGO_OVERRIDE),
  ],
  (isEmbargoed, isAdmin: boolean, isAuth: boolean | null, useFLPEmbargoOverride: boolean): boolean => {
    const isImpersonation = isAdmin && isAuth;
    const overrideFlpEmbargo = isImpersonation && useFLPEmbargoOverride;
    if (overrideFlpEmbargo) {
      return false;
    }
    return isEmbargoed;
  }
);
export const isRegularUpgradingToRetiredSelector = createSelector(
  [selectedCimaMembershipKeyByUserChoiceSelector, cimaMembershipsTermTypeSelector],
  (selectedMembershipType, currentMembershipType): boolean => {
    return (
      selectedMembershipType === MembershipTypes.MembershipKeys.RETIRED &&
      currentMembershipType === MembershipTypes.MembershipKeys.REGULAR
    );
  }
);

export const isExpressRenewalSelector = createSelector(
  [(state: State.Root) => getFeatureToggleByKeySelector(state, USE_NEW_MEMBERSHIP_AICPA), isRenewalsJourneySelector],
  (useNewMembershipAicpa, isRenewalsJourney): boolean => useNewMembershipAicpa && isRenewalsJourney
);

export const isAicpaMemberLapsedSelector = createSelector(
  [inactiveMembershipProduct, isUserMemberLapsedSelector, isUserResignedSelector],
  (
    inactiveMembership: Salesforce.MembershipProductAndOrder,
    isUserMemberLapsed: boolean,
    isUserResigned: boolean
  ): boolean =>
    inactiveMembership?.membership?.membershipBody === MembershipTypes.MembershipBody.AICPA &&
    (inactiveMembership?.membership?.status === Salesforce.MembershipStatus.LAPSED || isUserResigned) &&
    isUserMemberLapsed
);

export const isAicpaMemberFiveYearsLapsedSelector = createSelector(
  [inactiveMembershipTermSelector, isAicpaMemberLapsedSelector],
  (inactiveMembershipTerm: any, isUserAicpaMemberLapsed: boolean): boolean =>
    areAllTruthy(isUserAicpaMemberLapsed, moment().diff(inactiveMembershipTerm?.expiryDate, 'year') >= 5)
);

export const isAicpaMemberMoreThanOneYearLapsedSelector = createSelector(
  [inactiveMembershipTermSelector, isAicpaMemberLapsedSelector],
  (inactiveMembershipTerm: any, isUserAicpaMemberLapsed: boolean): boolean =>
    areAllTruthy(isUserAicpaMemberLapsed, moment().diff(inactiveMembershipTerm?.expiryDate, 'year') >= 1)
);

export const isMembershipSectionsFetchedSelector = createSelector(
  membershipSectionRootSelector,
  (benefits: State.Membership['sections']): boolean => benefits?.isFetched
);

export const fcmaSubscriptionNumberSelector = createSelector(
  activeCredentialsSubscriptionSelector,
  (activeCredentialsSubscription: State.LineItemWithAccessDates[] | undefined): string | undefined =>
    activeCredentialsSubscription?.find(
      (credential: State.LineItemWithAccessDates) =>
        credential?.variant?.sku === ProductSchema.CIMA_CREDENTIALS_SKU.FCMA
    )?.subscriptionNumber
);
