import React, { useState } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';
import { formatPrice } from 'utils';
import { ReactComponent as IconLockOn } from 'resources/images/icon-dev-ic-lock-on.svg';
import { Button, ButtonEnums } from 'components/atoms';
import { INVOICE_PAYMENT_BUTTON_COMPLETE, handleEvent, getInvoicePaymentCheckoutPayload } from 'utils/Analytics';
import { Invoices } from 'mxp-schemas';
import { CreditCardAdd } from 'components/organisms/CreditCardAdd/CreditCardAdd';

interface Props {
  accountId: string | null;
  amountOption: string | null;
  isInvoicePayment: boolean;
  invoice: State.Invoice | null;
  isAdminInvoicesPayment: boolean;
  adminInvoicesTotalAmountToPay: number;
  payInvoice(paymentMethodId: string): void;
  sendMessage: (message: string) => void;
  getHostedPageSignature: () => Promise<void>;
}

export const StepPayment: React.FC<Props> = ({
  accountId,
  amountOption,
  isInvoicePayment,
  invoice,
  isAdminInvoicesPayment,
  adminInvoicesTotalAmountToPay,
  payInvoice,
  sendMessage,
  getHostedPageSignature,
}) => {
  const totalAmountToPay = isAdminInvoicesPayment
    ? formatPrice(adminInvoicesTotalAmountToPay)
    : formatPrice(invoice?.amount || 0);

  const onCardSubmitSuccess = React.useCallback(
    async (data: any) => {
      payInvoice(data.refId);
    },
    [payInvoice]
  );

  const onCardSubmitError = React.useCallback((doSendMessage: boolean, data: any) => {
    try {
      Sentry.captureException(data);
      if (doSendMessage) sendMessage(data);
    } catch {
      console.error('Sentry failed to start');
    }
    // isAdminInvoicesPayment ? goToAdminInvoicesPaymentErrorPage() : goToInvoiceCheckoutErrorPage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [iFrameLoading, setIFrameLoading] = useState(false);
  const onIFrameLoadingChange = React.useCallback((loading: boolean) => setIFrameLoading(loading), []);

  const handleSubmitClick = React.useCallback(() => {
    (window as any).Z.submit();

    if (isInvoicePayment) {
      const invoiceAnalytics: Invoices.InvoiceAnalytics = {
        isSavedCardUsed: false,
        amountOption,
        amount: invoice?.amount || null,
        invoiceNumber: invoice?.invoiceNumber,
        isB2B: true,
      };

      const payload = getInvoicePaymentCheckoutPayload(invoiceAnalytics);

      if (payload) {
        handleEvent(payload, INVOICE_PAYMENT_BUTTON_COMPLETE);
      }
    }
  }, [isInvoicePayment, invoice, amountOption]);

  return (
    <>
      {!iFrameLoading && (
        <CreditCardAdd
          accountId={accountId}
          onCardSubmitError={onCardSubmitError}
          onCardSubmitSuccess={onCardSubmitSuccess}
          getHostedPageSignature={getHostedPageSignature}
          onIFrameLoadingChange={onIFrameLoadingChange}
        />
      )}
      <ButtonStyled
        testId={isAdminInvoicesPayment ? 'admin-invoices-payment--button' : 'invoice-payment--button'}
        variant={ButtonEnums.variants.primary}
        icon={<IconLockOn />}
        iconPosition={ButtonEnums.iconPosition.left}
        onClick={handleSubmitClick}
        isAdminInvoicesPayment={isAdminInvoicesPayment}
      >
        {`Pay ${totalAmountToPay}`}
      </ButtonStyled>
    </>
  );
};

const ButtonStyled = styled(Button)<{ isAdminInvoicesPayment?: boolean }>`
  &&&& {
    margin-top: ${props => props.isAdminInvoicesPayment && props.theme.pxToRem(-8)};
    margin-bottom: ${props => props.isAdminInvoicesPayment && props.theme.pxToRem(200)};
    line-height: ${props => props.theme.pxToRem(24)};
  }
`;
