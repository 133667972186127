import React, { useEffect } from 'react';
import { useHistory, useLocation, generatePath, useParams } from 'react-router';
import { matchPath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Heading, Grid, ButtonLink, ButtonEnums } from 'components/atoms';
import { SubBlockBenefit } from './SubBlockBenefit';
import { CONSTANTS } from 'modules/app/constants';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful } from 'mxp-schemas';
import { Routes, StorageNames } from 'constants/index';
import { getPath, setSessionStorageItem } from 'utils';
import { FLPErrorModal } from 'components/molecules/FlpSubscriptionModal/ErrorModal';
import { getFLPModalConfirmPath } from 'utils/productItemHelpers';
import { practicalExperienceRequirementSelector, isFlpModalOpenSelector } from 'modules/membership/selectors';
import { constantsSelector } from 'modules/app/selectors';
import { studentProgressionSelector, personAccountDataSelector, isAuthSelector } from 'modules/user/selectors';
import { getPracticalExperienceRequirement, setIsFlpModalOpen } from 'modules/membership/actions';

import {
  ExternalLinkStyles,
  ExternalLinkStylesDark,
  DefaultInlineLinkStyles,
  InlineLinkOnDarkBgStyles,
} from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}

export const BlockBenefit: React.FC<Props> = ({ modularBlock, headingType }) => {
  const block = modularBlock as Contentful.ModularContent.BenefitsBlock;
  const isBgColorDark: boolean = isColorDark(block.bgColor);

  const history = useHistory();
  const dispatch = useDispatch();
  const constants = useSelector(constantsSelector);
  const isAuth = useSelector(isAuthSelector);
  const studentProgression = useSelector(studentProgressionSelector);
  const { learningPathway } = useSelector(personAccountDataSelector);
  const flpModal = useSelector(isFlpModalOpenSelector);
  const cgmaJourneySlug = 'cgma-journey';

  const { pathname } = useLocation();
  const { slug } = useParams() as any;

  const isCimaMembershipLandingPage =
    Boolean(matchPath(pathname, { path: getPath(Routes.STATIC_LANDING_PAGES), exact: true })) &&
    constants?.[CONSTANTS.CIMA_MEMBERSHIP_LANDING_PAGE_SLUG] &&
    constants[CONSTANTS.CIMA_MEMBERSHIP_LANDING_PAGE_SLUG] === slug;

  useEffect(() => {
    // This should only trigger when the user is logged in
    if (isAuth) dispatch(getPracticalExperienceRequirement(true));
  }, [dispatch, isAuth]);

  const { portfolio } = useSelector(practicalExperienceRequirementSelector);

  const handleFLPModalConfirm = () => {
    const path: any = getFLPModalConfirmPath(portfolio, studentProgression, learningPathway, flpModal.modalVariant);
    history.push(generatePath(getPath(path)));
    dispatch(setIsFlpModalOpen(false, ''));
  };
  const handleFlpModalClose = () => {
    dispatch(setIsFlpModalOpen(false, ''));
  };

  const handleButtonOnClick = () => {
    setSessionStorageItem({ [StorageNames.previousPage]: history.location.pathname });
    history.push(getPath(Routes.CIMA_MEMBERSHIP_PROMO_CODE));
  };

  return (
    <>
      <StyledHeaderContainer isBgColorDark={isBgColorDark} id={block.ctaUrl === '#cgma-journey' ? 'cgma-journey' : ''}>
        {block.title && (
          <StyledParagraph
            tabIndex={0}
            dangerouslySetInnerHTML={{ __html: block.title }}
            isBgColorDark={isBgColorDark}
          />
        )}
        {block.header && (
          <StyledHeading
            as={headingType}
            dangerouslySetInnerHTML={{ __html: block.header }}
            isBgColorDark={isBgColorDark}
            tabIndex={0}
          />
        )}
        {block.description && (
          <StyledParagraph
            tabIndex={0}
            dangerouslySetInnerHTML={{ __html: block.description }}
            isBgColorDark={isBgColorDark}
          />
        )}
      </StyledHeaderContainer>
      <StyledGrid columns="3" centered>
        {block?.landingPageSubBlocks?.map((item: Contentful.LandingPages.LandingPageSubBlock) => (
          <Grid.Column key={item.id}>
            <SubBlockBenefit
              testId={`bsb-${item.id}`}
              title={item.header}
              description={item.description}
              imageUrl={item.image}
              to={item.ctaUrl}
            />
          </Grid.Column>
        ))}
      </StyledGrid>
      {block.ctaText &&
        block.ctaUrl &&
        (!isCimaMembershipLandingPage ? (
          <StyledButtonWrapper>
            <ButtonLink
              testId="logged-out-home-benefit-block"
              to={block.ctaUrl}
              variant={isBgColorDark ? ButtonEnums.variants.secondaryNegative : ButtonEnums.variants.primary}
              size={ButtonEnums.sizes.medium}
              external={block.ctaUrl.includes('http')}
              onClick={handleButtonOnClick}
            >
              {block.ctaText}
            </ButtonLink>
          </StyledButtonWrapper>
        ) : (
          block.anchorSlug === cgmaJourneySlug && (
            <StyledButtonWrapper>
              <ButtonLink
                testId="logged-out-home-benefit-block"
                to={'#'}
                variant={isBgColorDark ? ButtonEnums.variants.secondaryNegative : ButtonEnums.variants.primary}
                size={ButtonEnums.sizes.medium}
                external={block.ctaUrl.includes('http')}
                onClick={handleButtonOnClick}
              >
                {block.ctaText}
              </ButtonLink>
              {flpModal.isOpen && (
                <FLPErrorModal
                  open={flpModal.isOpen}
                  onClose={handleFlpModalClose}
                  variant={flpModal.modalVariant}
                  onConfirm={handleFLPModalConfirm}
                />
              )}
            </StyledButtonWrapper>
          )
        ))}
    </>
  );
};

const StyledHeaderContainer = styled.div<{ isBgColorDark?: boolean }>`
  margin: 0 auto;
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
  .rich-text-external-link {
    ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${props => (props.isBgColorDark ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  margin-top: ${props => props.theme.pxToRem(16)};
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;

  p {
    margin: 0;
    line-height: 1.38;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.l};

    p {
      line-height: 1.33;
    }
  }
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;

  p {
    margin: 0;
    line-height: 1.33;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};

    p {
      line-height: 1.5;
    }
  }
`;

const StyledGrid = styled(Grid)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(39)};
    margin-bottom: ${props => props.theme.pxToRem(24)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(24)};
    }
  }
`;

const StyledButtonWrapper = styled.div`
  text-align: center;

  > a {
    width: ${props => props.theme.pxToRem(316)};
  }
`;
