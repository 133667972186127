export const getByProfBodyAndQualName = (professionalData: any, profBody: string, qualName: string) => {
  return professionalData?.find(
    (profInfo: any) =>
      profInfo?.SFS_EXR_NameOfProfessionalBody__c?.toLowerCase() === profBody?.toLowerCase() &&
      profInfo?.SFS_EXR_NameOfQualification__c?.toLowerCase() === qualName?.toLowerCase()
  );
};

export const validateYearQualify = (yearGraduation: string, eligibleFromYear: string, eligibleUnTilYear: string) => {
  const eligible: boolean =
    Number(yearGraduation) >= Number(eligibleFromYear) && Number(yearGraduation) <= Number(eligibleUnTilYear);
  return eligible;
};
