import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { ExitSurvey } from 'components/organisms/ExitSurvey/ExitSurvey';
import { userFeedbackValuesSelector } from 'modules/user/selectors';
import { putUserSurveyFeedbackValues } from 'modules/user/actions';
import { contentLoadingSelector } from 'modules/content/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  async submitFeedback(survey: string, feedback: State.UserFeedbackRecord): Promise<void> {
    await dispatch(putUserSurveyFeedbackValues(survey, feedback));
  },

  navigate(path: string): void {
    dispatch(push(path));
  },
});

const mapStateToProps = (state: State.Root) => ({
  values: userFeedbackValuesSelector(state),
  isLoading: contentLoadingSelector(state),
});

export const ExitSurveyContainer = connect(mapStateToProps, mapActionCreators)(ExitSurvey);
