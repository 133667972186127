import React from 'react';
import styled from 'styled-components';
import { Survey } from 'components/molecules/Survey/Survey';
import { SurveyOption } from 'constants/index';

interface ArticleFeedbackResponseProps {
  testId: string;
  isLoading?: boolean;
  options: SurveyOption[];
  onSubmit: (...args: any[]) => void;
}

const StyledContainer = styled.div`
  &&& {
    margin-top: 3rem;
    padding: 0 3rem;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const ArticleFeedbackResponse: React.FC<ArticleFeedbackResponseProps> = React.memo(
  ({ options, testId, onSubmit }) => {
    return (
      <StyledContainer data-testid={testId}>
        <Survey options={options} submit={onSubmit} testId="article-feedback" />
      </StyledContainer>
    );
  }
);
