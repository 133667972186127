import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Accordion } from 'semantic-ui-react';
import { CimaExamResultsSectionPanel } from '../CimaExamResultsSectionPanel';
import { User } from 'mxp-schemas';
import { getExamResultStatusIcon } from 'utils/index';
import { learningPathwaySelector } from 'modules/user/selectors';
import { isExamScoreShown, isDistinction } from 'utils';

interface Props {
  examSectionRecords: User.Exams;
}

export const ExamProficiencyResults: React.FC<Props> = React.memo(({ examSectionRecords }) => {
  const [accordionOption, setAccordionOption] = useState({
    stepNumber: examSectionRecords.id,
    isOpen: false,
  });

  const learningPathway = useSelector(learningPathwaySelector);
  const showExamScore = isExamScoreShown(examSectionRecords, learningPathway);
  const isResultDistinction = isDistinction(examSectionRecords);

  const handleShow = useCallback(() => {
    setAccordionOption((prevState: any) => ({
      ...prevState,
      stepNumber: examSectionRecords.id,
      isOpen: !prevState.isOpen,
    }));
  }, [examSectionRecords]);

  return (
    <StyledAccordion active={accordionOption.isOpen}>
      <Accordion>
        <StyledAccordionTitle active={accordionOption.isOpen} onClick={handleShow}>
          <StyledLiContainer>
            <StyledScoreResult>
              <StyledScoreSpacer>
                <StyledSpan>Result</StyledSpan>
                <StyledValue>
                  <StyledMiniBadge color={examSectionRecords.status}>
                    <StyledIcons>
                      {examSectionRecords?.status && (
                        <img src={getExamResultStatusIcon(examSectionRecords.status)} alt={examSectionRecords.status} />
                      )}
                    </StyledIcons>
                  </StyledMiniBadge>
                  <div>{isResultDistinction ? 'Distinction' : examSectionRecords?.status}</div>
                </StyledValue>
              </StyledScoreSpacer>
              {showExamScore && (
                <StyledScoreSpacer>
                  <StyledSpan>Score</StyledSpan>
                  <StyledValue>{examSectionRecords.overallExamScore}</StyledValue>
                </StyledScoreSpacer>
              )}
              <div>
                <StyledSpan>Date</StyledSpan>
                <StyledValue>{examSectionRecords.examDate}</StyledValue>
              </div>
            </StyledScoreResult>
            <StyledInfoPillContainer>
              <span>{accordionOption.isOpen ? '-' : '+'}</span>
            </StyledInfoPillContainer>
          </StyledLiContainer>
        </StyledAccordionTitle>

        <StyledAccordionContent active={accordionOption.isOpen}>
          <CimaExamResultsSectionPanel data={examSectionRecords} isAllExams={true} />
        </StyledAccordionContent>
      </Accordion>
    </StyledAccordion>
  );
});

const StyledAccordion = styled.div<{ active: boolean }>`
  &&&&&& {
    border-radius: ${props => props.theme.pxToRem(10)} !important;
    background: ${props => props.theme.colors.neutralGrey1};
    border-width: thin;

    ${({ active }) =>
      active &&
      css`
        border-style: solid;
      `}
  }
`;

const StyledLiContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.pxToRem(8)};
`;

const StyledMiniBadge = styled.div<{ color: string }>`
  border-radius: 100%
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  margin-right: ${props => props.theme.pxToRem(5)}
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.neutralBlack};

  ${({ color }) =>
    color === User.StudentExamResultStatus.PASS
      ? css`
          background-color: ${props => props.theme.colors.secondaryTeal};
        `
      : color === (User.StudentExamResultStatus.FAIL || User.StudentExamResultStatus.NO_SHOW)
      ? css`
          background-color: ${props => props.theme.colors.neutralBlack};
        `
      : ''}
`;

const StyledIcons = styled.div`
  display: flex;
  width: ${props => props.theme.pxToRem(14)};
  height: ${props => props.theme.pxToRem(14)};
`;

const StyledScoreResult = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSizes.xs};
`;

const StyledScoreSpacer = styled.div`
  width: ${props => props.theme.pxToRem(150)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(100)};
  }
`;

const StyledValue = styled.div`
  display: flex;
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const StyledInfoPillContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &&& {
    span {
      color: ${props => props.theme.colors.neutralGrey9};
      font-size: ${props => props.theme.fontSizes.xl};
    }
  }
`;

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 1.33;
`;

const StyledAccordionTitle = styled(Accordion.Title)`
  padding: ${props => props.theme.pxToRem(20)} !important;
`;

const StyledAccordionContent = styled(Accordion.Content)`
  padding: ${props => props.theme.pxToRem(20)} !important;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: ${props => props.theme.colors.neutralWhite};
`;
