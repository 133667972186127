export const getModalData = (filteredLowerLevel: any[], isFlpCurrentJourney: boolean) => {
  return isFlpCurrentJourney
    ? filteredLowerLevel
        ?.filter(
          (f: any) =>
            Number(f.name.replace(/^\D+/g, '')) !== 3 &&
            Number(f?.name.replace(/^\D+/g, '')) !== 5 &&
            Number(f?.name.replace(/^\D+/g, '')) !== 7
        )
        ?.map((level: any) => {
          return {
            id: level.id,
            cardIndex: level.name.replace(/^\D+/g, ''),
            levelName: level.name,
            levelDescription: level.description,
            listTitle: level.description,
            title: level.title,
            entryPointLevelExamList: level.masterExamReference
              ?.filter((f: any) => f?.name)
              ?.map((master: any) => {
                return {
                  id: master.id,
                  entryPointLevelExam: master.name,
                };
              }),
          };
        })
    : filteredLowerLevel?.map((level: any) => {
        return {
          id: level.id,
          cardIndex: level.name.replace(/^\D+/g, ''),
          levelName: level.name,
          levelDescription: level.description,
          listTitle: level.description,
          title: level.title,
          entryPointLevelExamList: level.masterExamReference
            ?.filter((f: any) => f?.name)
            ?.map((master: any) => {
              return {
                id: master.id,
                entryPointLevelExam: master.name,
              };
            }),
        };
      });
};
