import jwtDecode from 'jwt-decode';
import { isServer, setSessionStorageItem, getSessionStorageItem, removeSessionStorageItem } from 'utils';
import { AuthHelper } from './base';
import { ADMIN_AUTH_STATUS, SESSION_STORE_NAMES, StorageNames } from 'constants/index';
import { User as UserTypes } from 'mxp-schemas';
import * as Sentry from '@sentry/browser';
const OKTA_STORAGE_KEY = 'oktaToken';
const USE_PKCE = false;
export class AppcAuthHelper extends AuthHelper {
  constructor() {
    super(
      OKTA_STORAGE_KEY,
      {
        storage: 'sessionStorage',
      },
      USE_PKCE,
      true
    );
  }
  async appcAgentSessionExists(): Promise<ADMIN_AUTH_STATUS> {
    if (isServer) return ADMIN_AUTH_STATUS.UNAUTHENTICATED;
    try {
      const token = await this.getValidTokenFromOktaStore();
      if (token) {
        return ADMIN_AUTH_STATUS.AUTHORIZED;
      }
      const { tokens } = await this.authClient.token.parseFromUrl();
      if (tokens?.idToken || tokens?.accessToken) {
        await this.authClient.tokenManager.add(this.oktaStorageKey, tokens?.idToken || tokens?.accessToken);
        return ADMIN_AUTH_STATUS.AUTHORIZED;
      }
    } catch (err) {
      if (err.errorCode === 'invalid_scope' || err.errorCode === 'access_denied') {
        return ADMIN_AUTH_STATUS.UNAUTHORIZED;
      }
      return ADMIN_AUTH_STATUS.UNAUTHENTICATED;
    }
    return ADMIN_AUTH_STATUS.UNAUTHENTICATED;
  }
  async redirectForLogin(): Promise<void> {
    if (isServer) return;
    await this.authClient.token.getWithRedirect({
      responseType: this.isBuiltInServer ? ['id_token'] : ['token'],
      scopes: this.isBuiltInServer ? ['openid', 'email', 'profile', 'groups'] : ['appc'],
      pkce: USE_PKCE,
    });
  }
  setImpersonationToken(impersonationToken: string) {
    if (isServer) return;
    setSessionStorageItem({ [StorageNames.adminImpersonationToken]: impersonationToken });
  }
  getBulkRenewalAccess() {
    if (isServer) return;
    const isRenewalAdmin = (getSessionStorageItem(OKTA_STORAGE_KEY, 'okta-token-storage') as any)?.claims
      ?.isRenewalAdmin;
    return isRenewalAdmin;
  }
  getImpersonationToken(): string | null {
    if (isServer) return null;
    return getSessionStorageItem(StorageNames.adminImpersonationToken) as string | null;
  }
  endImpersonation() {
    if (isServer) return null;

    removeSessionStorageItem(StorageNames.adminImpersonationToken);
  }
  async sessionExists() {
    if (isServer) return null;
    const sessionAvailable = await this.getImpersonationToken();

    if (sessionAvailable) {
      return UserTypes.SessionType.OKTA_SESSION;
    }
    return UserTypes.SessionType.NO_SESSION;
  }

  async getUserTokenData(): Promise<{ sub: string } | null> {
    const token: string | null = await this.getImpersonationToken();
    if (!token) return null;
    const parsedToken: { sub: string } = jwtDecode(token);
    if (!parsedToken) return null;
    return parsedToken;
  }
  async getValidAccessToken(): Promise<string | undefined> {
    const impersonationSessionExists = await this.sessionExists();
    const session: boolean = impersonationSessionExists === UserTypes.SessionType.OKTA_SESSION ? true : false;
    return session ? Promise.resolve(this.getImpersonationToken() as string) : this.getValidTokenFromOktaStore();
  }
  protected async getValidTokenFromOktaStore(): Promise<string | undefined> {
    try {
      const token = await this.authClient.tokenManager.get(this.oktaStorageKey);
      if (!token || (!token.idToken && !token.accessToken)) return;
      const now: number = Date.now();
      if (token.expiresAt * 1000 <= now) {
        await this.clearTokens();
        return;
      }
      return token.idToken || token.accessToken;
    } catch (error) {
      Sentry.captureException(error);
      return;
    }
  }
  async signOutUser(): Promise<void> {
    removeSessionStorageItem(StorageNames.adminImpersonationToken);
    return this.signOutUserFromOkta();
  }
  async signOutUserFromOkta(): Promise<void> {
    if (isServer) return;
    const session = await this.authClient.session.exists();
    const token = await this.authClient.tokenManager.get(this.oktaStorageKey);
    await this.clearTokens();
    if (session) {
      // sign-out will cause a redirect / page reload
      await this.authClient.signOut({
        postLogoutRedirectUri: window.location.origin,
        ...(this.isBuiltInServer ? { idToken: token } : { accessToken: token }),
      });
    } else if (token) {
      // Session doesn't exist but the access token is available (e.g. SSO disabled for Safari)
      // revoke the token
      if (!this.isBuiltInServer) {
        // Can't revoke an ID Token
        await this.authClient.revokeAccessToken(token);
      }
      // force a window re-direct to ensure consistency with the session exist route
      window.location.replace(window.location.origin);
    }
  }

  async clearTokens(): Promise<void> {
    removeSessionStorageItem(StorageNames.adminClientRole, SESSION_STORE_NAMES.ADMIN_SESSION_STORE_KEY);
    await super.clearTokens();
  }
}
