import React from 'react';
import styled from 'styled-components/macro';
import { PRODUCT_CART_STATUS, SOURCE, CART_CTA_TEXT } from 'constants/index';
import { Content, Product, User } from 'mxp-schemas';
import { ButtonEnums, ButtonLink, Button } from 'components/atoms';
import { AccessOPLProduct } from 'components/molecules/AccessOPLProduct/AccessOPLProduct';
import { ReactComponent as IconExternal } from 'resources/images/ic-open-in-new.svg';

interface Props {
  thirdPartyLink?: string;
  isTransferableProductType: boolean;
  handlePageButton: () => void;
  productButtonStatus: PRODUCT_CART_STATUS;
  cartLoading: boolean;
  cartTransitionError: boolean;
  selectedVariantSKU?: string;
  productType: string;
  productId: string;
  disabled?: boolean;
  isPhysicalProduct: boolean;
  isPurchased?: boolean;
  isPremiumContent?: boolean;
  premiumContentTo?: string;
  handleProvisionAccess: () => void;
  isOPL?: boolean;
  oplRedirectUrl?: string;
  oplHidUrl?: string;
  userEmail?: string;
  fvsMembership?: User.MembershipIdsEnum[] | [];
  oktaId?: string;
  isUserFromRestrictedCountry?: boolean;
}
export const PurchaseButton: React.FC<Props> = ({
  thirdPartyLink = '',
  isTransferableProductType,
  handlePageButton,
  productButtonStatus,
  cartLoading,
  cartTransitionError,
  selectedVariantSKU,
  productType,
  productId,
  disabled,
  isPhysicalProduct,
  isPurchased = false,
  isPremiumContent = false,
  premiumContentTo = '',
  handleProvisionAccess,
  isOPL,
  oplRedirectUrl,
  oplHidUrl,
  userEmail,
  fvsMembership,
  oktaId,
  isUserFromRestrictedCountry,
}) => {
  const redirectParams = `icid=${SOURCE}:${Content.CategorySlugs.CPE_LEARNING}:${productId}:${CART_CTA_TEXT[productButtonStatus]}`;

  // To check if the thirdPartyLink has query params
  const hasQuery = thirdPartyLink?.includes('?');

  if (isPurchased && handleProvisionAccess) {
    return isPremiumContent ? (
      <StyledButtonLink
        fluid
        external
        testId="purchase-summary-book-with-partner"
        variant={ButtonEnums.variants.primary}
        to={premiumContentTo}
        disabled={disabled}
      >
        Access Now
      </StyledButtonLink>
    ) : isOPL ? (
      <AccessOPLProduct
        testId="purchase-summary-book-with-partner-opl"
        userEmail={userEmail}
        oktaId={oktaId}
        fvsMembership={fvsMembership}
        oplRedirectUrl={oplRedirectUrl}
        oplHidUrl={oplHidUrl}
        isPurchase
      />
    ) : (
      <StyledButton
        testId="access-now-purchase-summary"
        variant={ButtonEnums.variants.primary}
        onClick={handleProvisionAccess}
      >
        Access Now
      </StyledButton>
    );
  }

  return thirdPartyLink && !isPhysicalProduct ? (
    isTransferableProductType ? (
      <StyledButtonLinkWithIcon
        fluid
        external
        testId="purchase-summary-continue-to-checkout"
        variant={ButtonEnums.variants.primary}
        onClick={handlePageButton}
        to={thirdPartyLink}
        disabled={disabled}
      >
        <StyledIcon />
        Continue to checkout
      </StyledButtonLinkWithIcon>
    ) : (
      <StyledButtonLink
        fluid
        external
        testId="purchase-summary-book-with-partner"
        variant={ButtonEnums.variants.primary}
        onClick={handlePageButton}
        to={
          productType !== Product.ProductType.CONFERENCE
            ? thirdPartyLink
            : hasQuery
            ? `${thirdPartyLink}&${redirectParams}`
            : `${thirdPartyLink}?${redirectParams}`
        }
        disabled={disabled}
      >
        {CART_CTA_TEXT[productButtonStatus]}
      </StyledButtonLink>
    )
  ) : (
    <StyledButton
      testId="purchase-summary-add-to-cart"
      variant={ButtonEnums.variants.primary}
      loading={cartLoading}
      disabled={isUserFromRestrictedCountry || !selectedVariantSKU || cartTransitionError || disabled}
      onClick={handlePageButton}
    >
      {CART_CTA_TEXT[productButtonStatus]}
    </StyledButton>
  );
};

const StyledButtonLinkWithIcon = styled(ButtonLink)`
  max-width: ${props => props.theme.pxToRem(221)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    position: absolute;
    right: ${props => props.theme.pxToRem(10)};
    top: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledIcon = styled(IconExternal)`
  vertical-align: middle;
  display: inline-block;
  margin: 0 ${props => props.theme.pxToRem(6)} 0 0;
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
  path {
    fill: ${props => props.theme.colors.neutralWhite};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  max-width: ${props => props.theme.pxToRem(175)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    position: absolute;
    right: ${props => props.theme.pxToRem(10)};
    top: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledButton = styled(Button)`
  &&&&&&&&& {
    width: ${props => props.theme.pxToRem(164)};
    height: ${props => props.theme.pxToRem(40)};
    transition: none;
    ${props => props.theme.mediaQueries.mobileOnly} {
      position: absolute;
      right: ${props => props.theme.pxToRem(20)};
      top: ${props => props.theme.pxToRem(14)};
    }
  }
`;
