import { ReactComponent as GetApp } from 'resources/images/icon-dev-ic-get-app.svg';
import styled from 'styled-components/macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import { personAccountCountrySelector } from 'modules/user/selectors';
import { handleEvent, DOWNLOAD_EVENT } from 'utils/Analytics';
import download from 'downloadjs';
import { constantsSelector } from 'modules/app/selectors';
import { DOWNLOAD_CONSTANTS } from 'modules/app/constants';
import { getDownloadDocumentsSelector } from 'modules/content/selectors';
import { ContentfulHelpers } from 'mxp-utils';
import { MipCountry } from 'modules/user/constants';
import { getDownloadContentItemBySlug } from 'modules/content/actions';
import { getFileExtension } from 'utils/StringHelpers';

interface Props {
  onClose(): void;
  isLocked?: boolean;
  open: boolean;
}

export const MipDownloadApplicationModal: React.FC<Props> = (props: Props) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const dispatch = useDispatch();
  const constants = useSelector(constantsSelector);
  const downloadDocumentLink = useSelector(getDownloadDocumentsSelector);

  // Get application form based on user's country (Based on Primary Address)
  const getCountryName = useSelector(personAccountCountrySelector);
  const isFromUK = Boolean(getCountryName === MipCountry.UK);
  const isFromIreland = Boolean(getCountryName === MipCountry.IRELAND);

  useEffect(() => {
    const mipDocument = isFromUK
      ? DOWNLOAD_CONSTANTS.MIP_UK_FILE_DOWNLOAD
      : isFromIreland
      ? DOWNLOAD_CONSTANTS.MIP_IRELAND_FILE_DOWNLOAD
      : DOWNLOAD_CONSTANTS.MIP_OTHER_COUNTRIES_FILE_DOWNLOAD;
    dispatch(getDownloadContentItemBySlug(constants?.[mipDocument], ContentfulHelpers.CONTENT_TYPES.DOWNLOAD));
  }, [dispatch, isFromUK, isFromIreland, constants]);

  const handleDownloadClick = useCallback(async () => {
    const mipFileLink = downloadDocumentLink?.[0]?.fileLink;

    // If there are no file extension, make zip as default
    const mipFileName = `${downloadDocumentLink?.[0]?.name}.${
      getFileExtension(mipFileLink) ? getFileExtension(mipFileLink).toString() : 'zip'
    }`;

    handleEvent({ filename: mipFileName }, DOWNLOAD_EVENT);
    setIsButtonLoading(true);
    const getMipFile = await fetch(mipFileLink);
    const getFileResponse = await getMipFile?.blob();
    await download(getFileResponse, mipFileName);
    setIsButtonLoading(false);
    props.onClose();
  }, [props, downloadDocumentLink]);

  return (
    <StyledModal
      open={props.open}
      onClose={props.onClose}
      heading="Download the application"
      testId="download-application-modal"
      showCloseCross
      cancelNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          testId="cancel-download"
          onClick={props.onClose}
        >
          Cancel
        </Button>
      }
      confirmNode={
        <Button
          size={ButtonEnums.sizes.small}
          testId="start-download"
          variant={ButtonEnums.variants.primary}
          onClick={handleDownloadClick}
          icon={<GetApp />}
          iconPosition={ButtonEnums.iconPosition.left}
          loading={isButtonLoading}
          className="downloadButton"
        >
          Download
        </Button>
      }
    >
      Ensure you have all the documentation ready before you begin. After completing this application, please save your
      completed documents and email them to fellowship.applications@aicpa-cima.com.{' '}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &&&& > button {
    position: absolute !important;
    top: ${props => props.theme.pxToRem(16)}!important;
    right: ${props => props.theme.pxToRem(16)}!important;
  }

  &&&& {
    width: ${props => props.theme.pxToRem(590)};
    background: ${props => props.theme.colors.neutralWhite};
    margin-top: ${props => props.theme.pxToRem(48)};
    border-radius: ${props => props.theme.pxToRem(2)};
    padding: ${props => props.theme.pxToRem(37)} 0 ${props => props.theme.pxToRem(20)}!important;
    box-sizing: border-box;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    > .content {
      margin: 0 !important;
      text-align: center;
      box-sizing: border-box;
      padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(20)}!important;
    }

    > .header {
      margin: 0 auto;
      text-align: center;
      display: table;
      h2 {
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }

    > .actions {
      margin: 0 ${props => props.theme.pxToRem(20)};
    }

    > .actions > .downloadButton {
      width: ${props => props.theme.pxToRem(215)}!important;
    }
  }

  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;
