import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import request from 'utils/GraphQLClient';
import {
  MUTATE_UPDATE_PERSON_LEVEL_EXEMPTION,
  QUERY_PERSON_LEVEL_EXEMPTION,
  MUTATE_UPDATE_DOCUMENT_STATUS,
  QUERY_QUALIFICATION_DATA,
  GET_PERSON_LEVEL_EXEMPTION_UNIVERSITY_DETAILS,
} from 'mxp-graphql-queries';
import { personAccountIdSelector } from 'modules/user/selectors';
import { membershipActionNames } from 'modules/membership/constants';
import { personLevelExemptionActionNames } from './constants';
import { RecordTypePage } from 'modules/exemptionProfessional/constants';
import { educationalQualificationDataSelector } from 'modules/personLevelExemption';
import { educationQualificationSelector } from 'modules/exemptionEducational/selectors';
import { getByProfBodyAndQualName } from '../exemptionReference/helpers';
import { updateAccountUniversityDetails } from 'modules/user/actions';
import { MembershipTypes, User } from 'mxp-schemas';
import { fetchRequiredDocuments } from 'modules/requiredDocuments';
import { getPersonLevelExemptions } from 'modules/personLevelExemptions';
import { allRequiredDocuments } from 'modules/allRequiredDocuments/action';

// ------------------------------------
// Actions
// ------------------------------------

export const showLoading: any = createAction(membershipActionNames.GET_QUALIFICATION_DATA_LOADING);

export const getPersonLevelExemptionId: any = createAction(
  membershipActionNames.GET_PERSON_LEVEL_EXEMPTION,
  async () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const accountId = personAccountIdSelector(state);
    return request(QUERY_PERSON_LEVEL_EXEMPTION, { accountId }).then((response: any) => {
      const id = response?.getPersonLevelExemptionId?.id || '';
      const exemptionKey = response?.getPersonLevelExemptionId?.exemptionKey || '';
      const personAccountKey = response?.getPersonLevelExemptionId?.personAccountKey || '';
      const chosenExemption = response?.getPersonLevelExemptionId?.chosenExemption || '';
      const status = response?.getPersonLevelExemptionId?.status || '';
      const date = response?.getPersonLevelExemptionId?.date || '';
      const graduationYear = response?.getPersonLevelExemptionId?.graduationYear || '';
      const professionalStatus = response?.getPersonLevelExemptionId?.professionalStatus || '';
      const selectedPassedUSCPAExam = response?.getPersonLevelExemptionId?.selectedPassedUSCPAExam || '';
      const personLevelExemptionRecords = response?.getPersonLevelExemptionId?.personLevelExemptionRecords || '';
      const professionalQualification = response?.getPersonLevelExemptionId?.professionalQualification || [];
      const educationalQualification = response?.getPersonLevelExemptionId?.educationalQualification || [];
      const tlwReason = response?.getPersonLevelExemptionId?.tlwReason || '';
      const yearsofAccountingExperience = response?.getPersonLevelExemptionId?.yearsofAccountingExperience || '';
      const experienceofWorking = response?.getPersonLevelExemptionId?.experienceofWorking || '';
      const personLevelExemption = {
        id,
        exemptionKey,
        personAccountKey,
        status,
        date,
        graduationYear,
        professionalStatus,
        selectedPassedUSCPAExam,
        personLevelExemptionRecords,
        professionalQualification,
        educationalQualification,
        tlwReason,
        chosenExemption,
        yearsofAccountingExperience,
        experienceofWorking,
      };
      return personLevelExemption;
    });
  }
);

export const setOfflineExemptionStatus: any = createAction(
  personLevelExemptionActionNames.SET_OFFLINE_EXEMPTION_STATUS,
  (hasActiveOfflineExemptionCalculation: boolean) => {
    return {
      hasActiveOfflineExemptionCalculation,
    };
  }
);

export const getQualificationData: any = createAction(
  membershipActionNames.GET_QUALIFICATION_DATA,
  (recordType: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const accountId = personAccountIdSelector(state);
    dispatch(showLoading());
    return request(QUERY_QUALIFICATION_DATA, { accountId, recordType }).then((response: any) => {
      const personAccountKey = response?.getQualificationDataById?.personAccountKey || '';
      const professionalQualification = response?.getQualificationDataById?.professionalQualification || [];
      const educationalQualification =
        response?.getQualificationDataById?.educationalQualification?.filter(
          (data: any) => data?.educQualificationLevel && data?.educCourseTitle && data?.educGraduationYear
        ) || [];
      const personLevelExemptionRecords = response?.getQualificationDataById?.personLevelExemptionRecords || '';
      if (recordType === RecordTypePage.PROFESSIONAL_QUALIFICATION) {
        return {
          recordType,
          personAccountKey,
          personLevelExemptionRecords,
          professionalQualification,
        };
      }
      return {
        recordType,
        personAccountKey,
        personLevelExemptionRecords,
        educationalQualification,
      };
    });
  }
);

export const updatePersonLevelExemption: any = createAction(
  'updatePersonLevelExemption/UPDATE_STATUS',
  (data: Partial<State.PersonLevelExemptionStatus>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const { id, exemptionKey, personAccountKey, status }: any = data;

    return request(MUTATE_UPDATE_PERSON_LEVEL_EXEMPTION, {
      personLevelExemption: {
        id,
        exemptionKey,
        personAccountKey,
        status,
      },
    }).then(response => {
      return response.updatePersonLevelExemption;
    });
  }
);

export const updateDocumentStatus: any = createAction(
  'updateDocumentStatus/UPDATE_STATUS',
  (status?: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const accountId = personAccountIdSelector(state);
    return request(MUTATE_UPDATE_DOCUMENT_STATUS, {
      documentStatus: { personAccountKey: accountId, status: status || User.ExemptionLevelStatus.Pending },
    }).then(response => {
      return response;
    });
  }
);

export const mapProfessionalQualificationData: any = (
  hasProfessionalAccountingLicense: boolean,
  professionalQualification?: any,
  selectedUSCPAExam?: string
) => {
  if (professionalQualification) {
    const professionalQualificationData: State.ProfessionalQualification = professionalQualification?.map(
      (item: any) => ({
        hasAccountingLicense:
          hasProfessionalAccountingLicense ||
          Boolean(
            item?.nameProfessionalBodyId &&
              selectedUSCPAExam &&
              item?.nameProfessionalBody &&
              item?.nameQualificationBodyExcemp &&
              item?.yearQualify &&
              item?.candidateNumber
          ),
        exemptionKey: item?.nameProfessionalBodyId,
        selectedPassedUSCPAExam: selectedUSCPAExam,
        nameOfProfessionalBody: item?.nameProfessionalBody,
        nameofQualifications: item?.nameQualificationBodyExcemp,
        yearQualify: item?.yearQualify,
        membershipCandidateNumber: item?.candidateNumber,
      })
    );
    return professionalQualificationData;
  }
  return [
    {
      hasAccountingLicense: hasProfessionalAccountingLicense,
    },
  ];
};

export const mapProfRecord: any = (professionalQualification?: any) => {
  if (professionalQualification) {
    const professionalQualificationData: State.ProfessionalQualification = professionalQualification?.map(
      (item: any) => ({
        profBodyName: item?.nameProfessionalBody,
        profQualificationName: item?.nameQualificationBodyExcemp,
        profQualifyYear: item?.yearQualify,
        profMemCandidateNum: item?.candidateNumber,
        selectedPassedUSCPAExam: item?.usCPAExam,
      })
    );
    return professionalQualificationData;
  }
  return [];
};

export const setProfessionalQualificationData: any = createAction(
  personLevelExemptionActionNames.SET_PROFESSIONAL_QUALIFICATION_DATA,
  (hasProfessionalAccountingLicense: boolean, professionalQualification?: any, selectedUSCPAExam?: string) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const { allProfessionalExperienceReference } = state?.exemptionProfessional;

      const professionalQualificationData = mapProfessionalQualificationData(
        hasProfessionalAccountingLicense,
        professionalQualification,
        selectedUSCPAExam
      );
      const profRecord = mapProfRecord(professionalQualification);

      const profqualificationDatas = !!professionalQualificationData?.length
        ? professionalQualificationData.map((professional: any) => {
            const getExemptKeyId = getByProfBodyAndQualName(
              allProfessionalExperienceReference,
              professional?.nameOfProfessionalBody,
              professional?.nameofQualifications
            );
            return { ...professional, exemptionKey: getExemptKeyId?.Id };
          })
        : [];

      return {
        professionalQualificationData: profqualificationDatas,
        profRecord,
      };
    }
);

export const mapEducRecord: any = (educationalQualification?: any) => {
  if (educationalQualification) {
    const educationalQualificationData: State.EducationalQualification = educationalQualification?.map((item: any) => ({
      educInstitutionName: item?.institution,
      educQualificationLevel: item?.qualificationLevel,
      educCourseTitle: item?.courseTitle,
      educGraduationYear: item?.yearGrad,
      educConditionalExemptionStudent: item?.conditionalExemptionAllowed,
      eligibleFromYear: item?.eligibleFromYear,
      eligibleUntilYear: item?.eligibleUntilYear,
    }));
    return educationalQualificationData;
  }
  return [];
};

export const mapEducationalQualificationData: any = (
  hasEducationalQualification: boolean,
  educationalQualification?: any
) => {
  if (educationalQualification) {
    const educationalQualificationData: State.EducationalQualification = educationalQualification?.map((item: any) => ({
      hasEducationalQualification:
        hasEducationalQualification ||
        Boolean(item?.qualificationId && item?.qualificationLevel && item?.courseTitle && item?.yearGrad),
      exemptionKey: item?.qualificationId,
      universityName: item?.institution,
      qualificationLevel: item?.qualificationLevel,
      courseTitle: item?.courseTitle,
      graduationYear: item?.yearGrad,
      conditionalExemptionStudent: item?.conditionalExemptionAllowed,
    }));

    return educationalQualificationData;
  }
  return [
    {
      hasEducationalQualification,
    },
  ];
};

export const setEducationalQualificationData: any = createAction(
  personLevelExemptionActionNames.SET_EDUCATIONAL_QUALIFICATION_DATA,
  (hasEducationalQualification: boolean, educationalQualification?: any) => async (dispatch: Dispatch) => {
    const educationalQualificationData = mapEducationalQualificationData(
      hasEducationalQualification,
      educationalQualification
    );

    let yearGrad = '';
    let conditionalExemptionAllowed = false;
    if (educationalQualification?.length) {
      ({ yearGrad, conditionalExemptionAllowed } = educationalQualification?.[0]);
      if (educationalQualification?.length > 1) {
        const recordsFilteredByYear: any = (array: any[]) => {
          return array?.find(
            (qualification: any) =>
              qualification?.yearGrad === String(Math.max(...array?.map((item: any) => item?.yearGrad)))
          );
        };
        const recordsFilteredByMou = educationalQualification?.filter(
          (qualification: any) => qualification?.conditionalExemptionAllowed === true
        );

        ({ yearGrad, conditionalExemptionAllowed } = recordsFilteredByMou?.length
          ? recordsFilteredByMou?.length > 1
            ? recordsFilteredByYear(recordsFilteredByMou)
            : recordsFilteredByMou?.[0]
          : recordsFilteredByYear(educationalQualification));
      }
    }
    dispatch(updateAccountUniversityDetails(yearGrad, conditionalExemptionAllowed));
    dispatch(setUniversityDetails(educationalQualification));
    const educRecord = mapEducRecord(educationalQualification);
    return {
      educationalQualificationData,
      educRecord,
    };
  }
);

export const setEducationalQualificationDataFromRehydrate: any = createAction(
  personLevelExemptionActionNames.SET_EDUCATIONAL_QUALIFICATION_DATA_FROM_REHYDRATE,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const educationalQualification = educationalQualificationDataSelector(state);
    const educationalExemptionList = educationQualificationSelector(state);

    const filteredValues = educationalQualification?.filter(
      (qual: any) =>
        qual?.educInstitutionName && qual?.educGraduationYear && qual?.educQualificationLevel && qual?.educCourseTitle
    );

    const reducedValues = filteredValues?.reduce(
      (acc: any, value: any) => [
        ...acc,
        {
          qualificationId:
            educationalExemptionList?.find(
              (educationalExemption: any) =>
                educationalExemption?.SFS_EXR_CourseName__c === value?.educCourseTitle &&
                educationalExemption?.SFS_EXR_DegreeType__c === value?.educQualificationLevel
            )?.Id || '',
          qualificationLevel: value?.educQualificationLevel || '',
          courseTitle: value?.educCourseTitle || '',
          yearGrad: value?.educGraduationYear || '',
        },
      ],
      []
    );

    const educationalQualificationData = mapEducationalQualificationData(true, reducedValues);
    return {
      educationalQualificationData,
    };
  }
);

export const setWorkExperienceData: any = createAction(
  personLevelExemptionActionNames.SET_WORK_EXPERIENCE_DATA,
  (workExperience: any, exemptionId: string) => {
    const workExperienceData: State.WorkExperience = workExperience?.map((item: any) => ({
      exemptionKey: exemptionId,
      yearsofAccountingExperience: item?.financialExperienceYears,
      experienceofWorking: item?.academiaExperienceYears,
      professionalStatus: item?.employmentCurrentStatus,
      tlwReason: item?.tlwReason || '',
    }));

    return workExperienceData;
  }
);

export const getPersonLevelExemptionUniversityDetails: any = createAction(
  personLevelExemptionActionNames.GET_PERSON_LEVEL_EXEMPTION_UNIVERSITY_DETAILS,
  (personAccountId: string) => request(GET_PERSON_LEVEL_EXEMPTION_UNIVERSITY_DETAILS, { personAccountId })
);

export const setUniversityDetails: any = createAction(
  personLevelExemptionActionNames.SET_UNIVERSITY_DETAILS,
  (educationalQualification: any[]) => {
    const payload = {
      universityDetailsData: educationalQualification?.map((item: any) => ({
        exemptionId: item?.qualificationId,
        universityName: item?.institution,
        universityHasMOU: item?.conditionalExemptionAllowed,
        graduationYear: item?.yearGrad,
      })),
    };
    return payload;
  }
);

export const getPersonLevelExemptionRequiredDocuments: any = createAction(
  'personLevelExemption/GET_PERSON_LEVEL_EXEMPTION_REQUIRED_DOCUMENTS',
  () => async (dispatch: Dispatch) => {
    const { payload: personLevelExemptions }: any = await dispatch(getPersonLevelExemptions());
    const { payload: allRequiredDocumentsList }: any = await dispatch(allRequiredDocuments());

    const personLevelExemptionsMap: any[] = !personLevelExemptions?.length
      ? []
      : personLevelExemptions?.map((item: any) => {
          return {
            level: Number(item?.SFS_PLE_ExemptionKey__r?.SFS_EXR_ExemptionLevelKey__r?.Name.replace(/\D/g, '')) || null,
            documents: item?.SFS_PLE_ExemptionKey__r?.SFS_EXR_RequiredDocument__c?.split(';') || [],
          };
        });

    const allRequiredDocumentsListMap: any[] = !allRequiredDocumentsList?.length
      ? []
      : allRequiredDocumentsList?.map((item: any) => {
          return {
            level: Number(item?.SFS_EXR_ExemptionLevelKey__r?.Name?.replace(/\D/g, '')) || null,
            documents: item?.SFS_EXR_RequiredDocument__c?.split(';') || [],
          };
        });

    const combinedExemptions: any[] = [...personLevelExemptionsMap, ...allRequiredDocumentsListMap];

    const maxLevel = !combinedExemptions?.length ? 0 : Math.max(...combinedExemptions?.map((item: any) => item?.level));

    let filteredExemptions: any[] = !combinedExemptions?.length
      ? []
      : combinedExemptions
          ?.filter((item: any) => item?.level === maxLevel && !!item?.documents?.length)
          .map((item: any): any[] => item?.documents)
          .flat();

    if (!filteredExemptions?.length) {
      const { payload: personLevelExemption } = await dispatch(getPersonLevelExemptionId());
      const { payload: requiredDocs } = !personLevelExemption?.exemptionKey
        ? []
        : await dispatch(fetchRequiredDocuments(personLevelExemption?.exemptionKey));

      filteredExemptions = !requiredDocs?.length
        ? []
        : requiredDocs
            ?.filter(
              (doc: any) =>
                doc.name !== MembershipTypes.FileFilterName.PRACTICAL_EXPERIENCE_REQUIREMENT_PROOF &&
                doc.name !== MembershipTypes.FileFilterName.EXPERIENCE_ATTESTATION
            )
            .map((item: any) => item?.name);
    }
    const uniqueExemptions: any[] = [...new Set(filteredExemptions)];
    return uniqueExemptions || [];
  }
);
