import { contentLanguagesSlugIdMapSelector } from './selectors';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Helpers
// ------------------------------------

/*
 * Convert contentLanguages filter slugs to contentLanguages ids
 */
export const convertContentLanguagesSlugsToIds = (
  contentLanguages: string | string[],
  state: State.Root
): string[] | undefined => {
  if (!contentLanguages) return;
  const contentLanguagesSlugs: string[] = Array.isArray(contentLanguages) ? contentLanguages : [contentLanguages];
  // Call contentLanguagesSlugIdMapSelector only if contentLanguagesSlugs exist
  const contentLanguagesSlugIdMap: { [key: string]: string } = contentLanguagesSlugIdMapSelector(state);
  // Get contentLanguages ids array from slugs if exist
  return contentLanguagesSlugs.map(slug => contentLanguagesSlugIdMap[slug]).filter(Boolean);
};

/*
 * slug id hash map creator
 */
export const slugIdMapCreator = (contentLanguages: any[] | null): { [key: string]: string } => {
  if (!contentLanguages) return emptyObject;
  return contentLanguages.reduce((acc: any, contentLanguage: State.ContentLanguage) => {
    acc[contentLanguage.slug] = contentLanguage.id;
    return acc;
  }, {});
};
