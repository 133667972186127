import React from 'react';
import { FeedTargetableLink } from 'components/atoms/FeedTargetableLink/FeedTargetableLink';

interface ExternalSourceLabelProps {
  label: string;
  className?: string;
  to?: any;
}

export const ExternalSourceLabel: React.FC<ExternalSourceLabelProps> = ({ label, to }) => (
  <FeedTargetableLink key={label} iconPurple={true} title={label} link={to} testId={label} />
);
