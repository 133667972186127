import { createSelector } from 'reselect';
import { User } from 'mxp-utils';
import { holdingPageBypassConfigSelector } from '../app/selectors';
import { areAllTruthy } from 'utils';

export const rootSelector = createSelector(
  (state: State.Root): State.Startup => state.startup,
  (startup: State.Startup): State.Startup => startup
);

export const getRenewalSeasonOverrideSelector = createSelector(
  rootSelector,
  (startup: State.Startup): boolean | undefined =>
    startup.siteEntry.params.renewalseason === 'true'
      ? true
      : startup.siteEntry.params.renewalseason === 'false'
      ? false
      : undefined
);

export const getRenewalDateOverrideSelector = createSelector(
  rootSelector,
  (startup: State.Startup): string | undefined => startup.siteEntry.params.renewalDate
);

export const getMembershipCode = createSelector(rootSelector, (startup: State.Startup): string =>
  startup.siteEntry.params.membershipcode ? startup.siteEntry.params.membershipcode : ''
);

export const firmMembershipsHashSelector = createSelector(
  rootSelector,
  (startup: any): any => startup.firmMembershipsHash
);

export const sectionsHashSelector = createSelector(rootSelector, (startup: any): any => startup.sectionsHash);

export const accessRestrictionsHashSelector = createSelector(
  rootSelector,
  (startup: any): any => startup.accessRestrictionsHash
);

export const credentialsHashSelector = createSelector(rootSelector, (startup: any): any => startup.credentialsHash);

const topicalSubscriptionsHashSelector = createSelector(
  rootSelector,
  (startup: any): any => startup.topicalSubscriptionsHash
);

export const combinedConstantsHashSelector = createSelector(
  [
    firmMembershipsHashSelector,
    sectionsHashSelector,
    accessRestrictionsHashSelector,
    credentialsHashSelector,
    topicalSubscriptionsHashSelector,
  ],
  (
    firmMembershipsHash: any,
    sectionsHash: any,
    accessRestrictionsHash: any,
    credentialsHash: any,
    topicalSubscriptionsHash: any
  ): State.PaywallNotifications => ({
    ...firmMembershipsHash,
    ...sectionsHash,
    ...accessRestrictionsHash,
    ...credentialsHash,
    ...topicalSubscriptionsHash,
    ...User.lockedNotificationMap,
  })
);

export const getTypeSelector = createSelector(rootSelector, (startup: State.Startup): string =>
  startup.siteEntry.params.type ? startup.siteEntry.params.type : ''
);

export const getInviteIdSelector = createSelector(
  rootSelector,
  (startup: State.Startup): string => startup.siteEntry.params.inviteid || startup.siteEntry.params.inviteId || ''
);

export const isPaperBillingSelector = createSelector(rootSelector, (startup: State.Startup): boolean =>
  Boolean(startup.siteEntry.params.isPaperBilling ? startup.siteEntry.params.isPaperBilling : false)
);

export const getActionSelector = createSelector(
  rootSelector,
  (startup: State.Startup): string => startup.siteEntry.params.action || ''
);

export const getTokenSelector = createSelector(
  rootSelector,
  (startup: State.Startup): string => startup.siteEntry.params.token || ''
);

export const getUniquestAllowedUrl = createSelector(
  rootSelector,
  (startup: State.Startup): any => startup.uniquestAllowedUrl
);

export const getCimaMembershipTypeSelector = createSelector(rootSelector, (startup: State.Startup): string =>
  startup.siteEntry.params.getCimaType ? startup.siteEntry.params.getCimaType : ''
);

export const savePaypalSelector = createSelector(rootSelector, (startup: State.Startup): boolean => {
  return startup?.siteEntry?.params?.savePayment === 'true';
});

export const holdingPageBypassSelector = createSelector(
  [holdingPageBypassConfigSelector, rootSelector],
  (bypassKey: string, startup: State.Startup): boolean | undefined =>
    startup?.siteEntry?.params?.[bypassKey] === 'true' ? true : false
);

const isPathwaySiteEntryPageSelector = createSelector([rootSelector], (startup: State.Startup): boolean =>
  startup?.siteEntry?.page?.includes('/cima-membership/studypath/pathway')
);

const isSiteEntrySfmcLinkSelector = createSelector([rootSelector], (startup: State.Startup): boolean =>
  areAllTruthy(
    startup?.siteEntry?.params?.utm_medium === 'email',
    startup?.siteEntry?.params?.utm_source === 'SFMC_RAVE'
  )
);

export const bypassHomeRedirectionSelector = createSelector(
  [isPathwaySiteEntryPageSelector, isSiteEntrySfmcLinkSelector],
  (isPathwaySiteEntryPage: boolean, isSiteEntrySfmcLink: boolean): boolean =>
    areAllTruthy(isSiteEntrySfmcLink, isPathwaySiteEntryPage)
);
