import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { User, Salesforce, MembershipTypes, ExemptionLevels } from 'mxp-schemas';
import { Membership } from 'mxp-utils';
import { getStudentProgressionData } from 'modules/user/actions';
import { updatePersonExamStatus } from 'modules/membership/actions';
import { EXAM_CARD_SIZE, Button, ButtonEnums } from 'components/atoms';
import { ConfirmWaiveExamModal } from 'components/molecules/ConfirmWaiveExamModal/ConfirmWaiveExamModal';
import { CimaRenderBlock } from './CimaRenderBlock';
import { getExemptionLevelReferenceByName, updateLowerExemption } from 'modules/exemptionReference/action';
import { constantsSelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
interface Props {
  qualificationLevelDetails: User.QualificationLevel | undefined;
  pathway: MembershipTypes.Pathway | null | undefined;
}

export const CimaQualificationLevel: React.FC<Props> = ({ qualificationLevelDetails, pathway }) => {
  const dispatch = useDispatch();
  const constants = useSelector(constantsSelector);

  const [waiveExamLoading, setWaiveExamLoading] = useState(false);
  const [selectedExamBlock, setSelectedExamBlock] = useState<User.SubjectBlock | null>(null);
  const [isWaiveExamModalOpen, setIsWaiveExamModalOpen] = useState(false);

  // get slug for learn more link
  const qualificationLevelPathSlug = Membership.getQualificationLevelPathSlug(qualificationLevelDetails?.name || '');

  // Objective Test exams
  const objectiveTestBlocks = qualificationLevelDetails?.blocks.filter(
    block => block.type === User.SubjectBlockType.ObjectiveTest
  );
  // FLP exams
  const flpBlocks = qualificationLevelDetails?.blocks.filter(block => block.type === User.SubjectBlockType.FLP);
  const flpQualificationLevel = qualificationLevelDetails?.blocks.filter(
    block =>
      block.qualificationlevel === MembershipTypes.CimaQualificationLevelPathValue.BusinessAndFinanceEssentialsCourse
  );
  // Case study exams
  const caseStudyBlocks = qualificationLevelDetails?.blocks.filter(
    block => block.type === User.SubjectBlockType.CaseStudy
  );
  // EPA2 exams
  const ePA2Blocks = qualificationLevelDetails?.blocks.filter(
    block => block.examRecordType === Salesforce.ExamRecordType.EPA2
  );
  // EPA1 for Apprenticeship L4 & L7 exams
  const ePA1Blocks = qualificationLevelDetails?.blocks.filter(
    block => block.pathway === 'Apprentice_L4' && block.type === User.SubjectBlockType.CaseStudy
  );

  const isLengthForSmallCard = Number(qualificationLevelDetails?.blocks.length) % 3 === 0;
  const hasCaseStudyBlocks = qualificationLevelDetails?.blocks.some(
    block => block.type === User.SubjectBlockType.CaseStudy
  );
  const cardBlockSize = hasCaseStudyBlocks || isLengthForSmallCard ? EXAM_CARD_SIZE.SMALL : EXAM_CARD_SIZE.MEDIUM;

  const learnMoreURL = useCallback(() => {
    if (!qualificationLevelPathSlug) return;
    const key = qualificationLevelPathSlug
      .replace(/-/g, '_')
      .toUpperCase() as keyof typeof CONSTANTS.CIMA_EXAMS_DASHBOARD;

    return constants?.[CONSTANTS.CIMA_EXAMS_DASHBOARD?.[key]];
  }, [constants, qualificationLevelPathSlug]);

  const handleLearnMoreClick = useCallback(() => {
    const url = learnMoreURL();
    if (url) {
      window.open(url);
    }
  }, [learnMoreURL]);

  const onWaiveExamModalToggle = useCallback(() => {
    if (isWaiveExamModalOpen) setSelectedExamBlock(null);
    setIsWaiveExamModalOpen(!isWaiveExamModalOpen);

    setWaiveExamLoading(false);
  }, [isWaiveExamModalOpen]);

  const onWaiveExamModalConfirm = async () => {
    setWaiveExamLoading(true);

    await dispatch(updatePersonExamStatus({ id: selectedExamBlock?.examExemptionId, status: 'Waived' }));
    if (qualificationLevelDetails?.name === MembershipTypes.CimaQualificationLevelPathValue.CertBa) {
      const level1 = await dispatch(getExemptionLevelReferenceByName(`${ExemptionLevels.ExemptionLevels.Level_1}`));
      if (level1?.payload?.getExemptionLevelReferenceByName.Id) {
        await dispatch(updateLowerExemption(level1?.payload?.getExemptionLevelReferenceByName.Id));
      }
    }
    await dispatch(getStudentProgressionData());

    onWaiveExamModalToggle();
    // TODO: Update exam block status to bookable
  };

  const onExamSelected = useCallback((examBlock: User.SubjectBlock) => {
    setSelectedExamBlock(examBlock);
  }, []);

  return (
    <StyledContainer>
      <ConfirmWaiveExamModal
        open={isWaiveExamModalOpen}
        onClose={onWaiveExamModalToggle}
        onConfirm={onWaiveExamModalConfirm}
        isLoading={waiveExamLoading}
      />

      <StyledHowDoes>How does {qualificationLevelDetails?.name} work?</StyledHowDoes>
      <StyledLearnMore
        to={learnMoreURL()}
        variant={ButtonEnums.variants.link}
        testId={'learn-more-button'}
        onClick={handleLearnMoreClick}
      >
        Learn more about {qualificationLevelDetails?.name}
      </StyledLearnMore>
      {(() => {
        if (
          [MembershipTypes.Pathway.FLP, MembershipTypes.Pathway.PQ, MembershipTypes.Pathway.CHINESE_PQ].includes(
            pathway as MembershipTypes.Pathway
          )
        ) {
          return (
            <>
              <StyledExamCardContainer cardSize={cardBlockSize}>
                <CimaRenderBlock
                  blocks={objectiveTestBlocks}
                  cardSize={cardBlockSize}
                  handleOpenWaiveExamModal={onWaiveExamModalToggle}
                  onExamSelected={onExamSelected}
                />
              </StyledExamCardContainer>

              <StyledExamCardContainer cardSize={cardBlockSize}>
                <CimaRenderBlock
                  blocks={flpBlocks}
                  cardSize={flpQualificationLevel ? EXAM_CARD_SIZE.SMALL : cardBlockSize}
                  handleOpenWaiveExamModal={onWaiveExamModalToggle}
                  onExamSelected={onExamSelected}
                />
              </StyledExamCardContainer>

              {hasCaseStudyBlocks && (
                <StyledLargeCardContainer>
                  <CimaRenderBlock
                    blocks={caseStudyBlocks}
                    cardSize={EXAM_CARD_SIZE.LARGE}
                    handleOpenWaiveExamModal={onWaiveExamModalToggle}
                    onExamSelected={onExamSelected}
                  />
                </StyledLargeCardContainer>
              )}
            </>
          );
        }

        if (pathway === MembershipTypes.Pathway.APPRENTICE_L4) {
          return (
            <>
              {(ePA1Blocks || ePA2Blocks) && (
                <StyledExamCardContainer cardSize={EXAM_CARD_SIZE.MEDIUM}>
                  <CimaRenderBlock
                    blocks={ePA1Blocks}
                    cardSize={EXAM_CARD_SIZE.MEDIUM}
                    handleOpenWaiveExamModal={onWaiveExamModalToggle}
                    onExamSelected={onExamSelected}
                  />

                  <CimaRenderBlock
                    blocks={ePA2Blocks}
                    cardSize={EXAM_CARD_SIZE.MEDIUM}
                    handleOpenWaiveExamModal={onWaiveExamModalToggle}
                    onExamSelected={onExamSelected}
                  />
                </StyledExamCardContainer>
              )}

              {objectiveTestBlocks && (
                <StyledExamCardContainer cardSize={EXAM_CARD_SIZE.MEDIUM}>
                  <CimaRenderBlock
                    blocks={objectiveTestBlocks}
                    cardSize={EXAM_CARD_SIZE.MEDIUM}
                    handleOpenWaiveExamModal={onWaiveExamModalToggle}
                    onExamSelected={onExamSelected}
                  />
                </StyledExamCardContainer>
              )}
            </>
          );
        }

        if (pathway === MembershipTypes.Pathway.APPRENTICE_L7) {
          return (
            <>
              {ePA1Blocks && (
                <StyledExamCardContainer cardSize={EXAM_CARD_SIZE.MEDIUM}>
                  <CimaRenderBlock
                    blocks={ePA1Blocks}
                    cardSize={EXAM_CARD_SIZE.MEDIUM}
                    handleOpenWaiveExamModal={onWaiveExamModalToggle}
                    onExamSelected={onExamSelected}
                  />
                </StyledExamCardContainer>
              )}

              {ePA2Blocks && (
                <StyledExamCardContainer cardSize={EXAM_CARD_SIZE.MEDIUM}>
                  <CimaRenderBlock
                    blocks={ePA2Blocks}
                    cardSize={EXAM_CARD_SIZE.MEDIUM}
                    handleOpenWaiveExamModal={onWaiveExamModalToggle}
                    onExamSelected={onExamSelected}
                  />
                </StyledExamCardContainer>
              )}

              {objectiveTestBlocks && (
                <StyledExamCardContainer cardSize={cardBlockSize}>
                  <CimaRenderBlock
                    blocks={objectiveTestBlocks}
                    cardSize={cardBlockSize}
                    handleOpenWaiveExamModal={onWaiveExamModalToggle}
                    onExamSelected={onExamSelected}
                  />
                </StyledExamCardContainer>
              )}

              {hasCaseStudyBlocks && (
                <StyledLargeCardContainer>
                  <CimaRenderBlock
                    blocks={caseStudyBlocks}
                    cardSize={EXAM_CARD_SIZE.LARGE}
                    handleOpenWaiveExamModal={onWaiveExamModalToggle}
                    onExamSelected={onExamSelected}
                  />
                </StyledLargeCardContainer>
              )}
            </>
          );
        }
      })()}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  box-sizing: border-box;
  color: ${props => props.theme.colors.neutralGrey8};
  padding: ${props => `${props.theme.pxToRem(10)} 0 ${props.theme.pxToRem(15)} ${props.theme.pxToRem(25)}`};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledExamCardContainer = styled.div<{ cardSize: EXAM_CARD_SIZE }>`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${props => props.theme.pxToRem(15)};

  ${({ cardSize }) =>
    cardSize === EXAM_CARD_SIZE.SMALL &&
    css`
      gap: ${props => props.theme.pxToRem(10)};
    `}

  ${({ cardSize }) =>
    cardSize === EXAM_CARD_SIZE.MEDIUM &&
    css`
      gap: ${props => props.theme.pxToRem(20)};
    `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
    width: 100%;
  }
`;

const StyledLargeCardContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.pxToRem(10)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledHowDoes = styled.p`
  line-height: 1.33;
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.bold};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(15)};
  }
`;

const StyledLearnMore = styled(Button)`
  &&&&& {
    display: block;
    margin: ${props => props.theme.pxToRem(30)} 0;
    font-size: ${props => props.theme.fontSizes.s};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: ${props => props.theme.pxToRem(20)};
    }
  }
`;
