import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { getLocation, getSearch } from 'connected-react-router';
import { QUERY_LANDING_PAGE } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import qs from 'query-string';
import { getProductItem, resetProductsModule } from 'modules/products/actions';

export const resetModule: any = createAction('landingPage/RESET_MODULE');
export const resetSkipLandingPageFrontloadRequest: any = createAction('landingPage/RESET_SKIP_FRONTLOAD');

export const fetchLandingPageContent: any = createAction(
  'content/FETCH_LANDING_PAGE',
  (slug: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { pathname } = getLocation(state);
    const search = getSearch(state);
    const queryParams = qs.parse(search);

    if (queryParams?.sku) {
      dispatch(resetProductsModule());
      dispatch(getProductItem(null, queryParams?.sku));
    }

    const isPageStorefront: boolean = pathname === getPath(Routes.STOREFRONT_PAGE);
    return request(QUERY_LANDING_PAGE, { useDynamicTrending: true, slug, isPageStorefront });
  }
);
