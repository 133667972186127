import React from 'react';
import { theme } from 'theme';
import styled from 'styled-components';

interface CimaFilterProps {
  activeFilter: string;
  filters: Array<string>;
  onClickFilter: (filter: string) => void;
}

export const CimaFilter: React.FC<CimaFilterProps> = ({ activeFilter, filters, onClickFilter }: CimaFilterProps) => {
  const renderFilter = () => {
    if (filters.length) {
      return filters.map(filter => {
        return (
          <StyledTag style={activeFilter === filter ? activeStyle : {}} onClick={() => onClickFilter(filter)}>
            {filter}
          </StyledTag>
        );
      });
    }
    return <></>;
  };
  return <StyledTagContainer>{renderFilter()}</StyledTagContainer>;
};

const StyledTagContainer = styled.div`
  margin: ${props => props.theme.pxToRem(40)} 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledTag = styled.span`
  color: ${props => props.theme.colors.primaryPurple};
  border-radius: ${props => props.theme.pxToRem(15)};
  background-color: ${props => props.theme.colors.neutralGrey2};
  cursor: pointer;
  padding: ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(2)};
  &&& {
    &:hover {
      background-color: ${props => props.theme.colors.primaryPurple};
      color: ${props => props.theme.colors.neutralWhite};
    }
  }
`;

const activeStyle = {
  backgroundColor: theme.colors.primaryPurple,
  color: theme.colors.neutralWhite,
};
