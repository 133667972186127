import { priceToFloat, getPath, fromCentsFormat, isServer, extractContentCardItemPrices } from 'utils';
import {
  ContentRestriction,
  ContentTypes,
  PaginationInt,
  Routes,
  MEMBERSHIP_APPLICATION_ROUTES,
  CIMA_MEMBERSHIP_ROUTES,
  MEMBERSHIP_ROUTES,
} from 'constants/index';
import { Cart, Checkout, Content, Invoices, Product, Shipping, Contentful, MembershipTypes } from 'mxp-schemas';
import { Accessor } from './types';
import * as CUSTOM_EVENTS from './constants';
import { CountriesList, Utils, isPhysicalProduct as isPhysicalProductCheck } from 'mxp-utils';
import {
  isAuthSelector,
  userLocationSelector,
  userMemberTypeSelector,
  userRolesSelector,
} from 'modules/user/selectors';
import {
  isFlpDueRenewalSelector,
  isCimaDueRenewalSelector,
  isAicpaDueRenewalSelector,
} from 'modules/membership/selectors';
import { UserMemberTypes } from 'modules/user/constants';
import { productCurrencySelector, freeTrialEndDateSelector } from 'modules/products/selectors';
import { getFormattedBundlePrice } from 'modules/products/helpers';
import { otherPaymentMethodSelector, selectedCreditCardIdSelector } from 'modules/checkout/selectors';
import { store } from '../../store';
import { ADD_CART, ADD_CART_FIRST, handleEvent } from 'utils/Analytics';
import { toolkitPageContentSelector, contentPageItemSelector } from 'modules/content/selectors';
import { staticLandingPageSelector } from 'modules/staticLandingPagesContent/selectors';
import { LOCATION_CHANGE } from 'connected-react-router';

interface SurfacedProductAnalytics {
  category: string[];
  id: string[];
  quantity: number[];
  price: number[];
  name: string[];
  location: string[];
  isAuth?: boolean;
}

export interface CartCompoundAnalytics {
  name: string[];
  category: string[];
  id: string[];
  bundleId: string[];
  bundleSku?: string[];
  sku: string[];
  quantity: number[];
  price: number[];
  cartAmount?: number;
  click?: string;
  orderType: string[];
  shippingType: string[];
  shippingLocation: string[];
  shippingCost: number[];
  productTax: number[];
  freeTrialEndDate: string[];
  isUpsell?: boolean;
  productBundleSkus?: string[];
  productBundleIds?: string[];
}

export interface ProductCompoundAnalytics {
  name: string[];
  category: string[];
  id: string[];
  sku: string[];
  price: number[];
  pageName: string;
}

interface PaymentCartCompoundAnalytics extends CartCompoundAnalytics {
  couponsCodes: string[];
  paymentType: string[];
  orderNumber?: string | null;
}

interface AdditionalCartCompoundAnalytics {
  couponsCodes: string[];
  paymentType: string[];
  discountAmount: number[];
  discountReason: string[];
}

interface PaymentCardPayload {
  cardPaymentType: string;
}

interface InvoiceCheckoutPaymentPayload {
  orderType: string;
  id?: string;
  paymentType: string;
  name: string;
  sku: string;
  price: number | null;
  quantity: number;
}

enum CART_PAYMENT_TYPE {
  CARD = 'card',
}
enum ProductTypes {
  product = 'product',
}

enum MembershipJourney {
  AICPA = 'AICPA',
  CIMA = 'CIMA',
  FCMA = 'FCMA',
  MIP = 'Become a Member',
  MIPRenewal = 'Become a Member Renewal',
  PER = 'PER CIMA',
  REGIONS = 'Pathway CIMA',
  CENTER = 'CENTER',
  FIRM = 'Firm Billing',
}

export const generateFacetString = (facets: string[][]): string[] => {
  return facets.reduce((agg: string[], facet) => {
    if (!facet[1]) {
      agg.push(`${facet[0]}:all`);
    } else {
      Array.isArray(facet[1])
        ? agg.push(...facet[1].map(i => `${facet[0]}:${i}`))
        : agg.push(`${facet[0]}:${facet[1]}`);
    }
    return agg;
  }, []);
};

export const triggerAddCartEvent = async (data: any) => {
  if (!data) return;

  const state = store?.getState();
  const freeTrialEndDate = freeTrialEndDateSelector(state);

  const cartItems = await data.lineItems;
  const addToCart = cartItems.map((productItem: any) => {
    const {
      productId,
      name,
      productType,
      quantity,
      variant: { sku = '' } = {},
      totalPrice: { centAmount = 0 } = {},
    } = productItem;

    const amount = Number(centAmount / 100).toFixed(2);
    const isB2BMembership = isB2BMembershipType(productType);
    const isB2CMembership = isB2CMembershipType(productType);

    const payload: CartCompoundAnalytics = {
      id: [productId],
      bundleId: [productId],
      name: [name],
      category: [productType],
      price: [+amount],
      quantity: [quantity],
      sku: [sku],
      orderType: [
        getOrderTypeAnalytics({
          isStandingOrder: false,
          isBundle: false,
          isFreeTrial: !!freeTrialEndDate,
          isB2BMembership,
          isB2CMembership,
        }),
      ],
      shippingCost: [],
      shippingLocation: [],
      shippingType: [],
      productTax: [],
      freeTrialEndDate: [freeTrialEndDate],
    };

    return payload;
  });

  const firstCart = addToCart.slice(0, 1);

  handleEvent(firstCart[0], ADD_CART_FIRST);

  const remainingInCarts = addToCart.splice(1);

  remainingInCarts.map((item: any) => {
    handleEvent(item, ADD_CART);
    return true;
  });
};

export const extractProductPayload: (
  searchResults: State.ContentCardItem[] | null,
  pagination: PaginationInt
) => SurfacedProductAnalytics | null = (searchResults, pagination) => {
  if (!searchResults || !searchResults.length) {
    return null;
  }

  const state = store.getState();
  const currency = productCurrencySelector(state);
  const { country } = userLocationSelector(state);

  const membershipType = userMemberTypeSelector(state);
  const membershipRoles = userRolesSelector(state);
  const tier = CountriesList.find(c => c.key === country)?.tier || CountriesList[0]?.tier;

  const payload: SurfacedProductAnalytics = searchResults.reduce(
    (acc: SurfacedProductAnalytics, item: State.ContentCardItem, idx: number) => {
      if (!item || item.contentType.slug !== ContentTypes.PRODUCT) {
        return acc;
      }

      const updatedPrices = Utils.getUpdatedPrices(item?.prices as any, currency?.label, tier);
      const priceRange = extractContentCardItemPrices(updatedPrices, membershipType, membershipRoles);
      const finalPrice = (priceRange?.membership === 'nonmember' ? priceRange?.startPrice : priceRange?.endPrice) || 0;

      acc.category.push(Array.isArray(item.productType) ? item.productType[0] : item.productType);
      acc.id.push(item.id);
      acc.quantity.push(1);
      acc.price.push(finalPrice);
      acc.name.push(item.title);
      acc.location.push(`${pagination.from + idx}/${pagination.total}`);
      return acc;
    },
    {
      category: [],
      id: [],
      quantity: [],
      price: [],
      name: [],
      location: [],
    }
  );
  if (!payload.id.length) {
    return null;
  }
  return payload;
};

export const getPageNameAndSiteSection = (customHash?: string) => {
  const pathSplitter = (val: string = '') => (val ? val.split('/')[1] : '');
  const pathReplacer = (val: string = '') => (val ? val.replace(/\//g, ':') : '');
  const state = store?.getState();
  const hash = customHash || state?.router?.location?.hash || (!isServer && (window as any)?.location?.hash);
  const hashString = hash ? hash.replace('#', '') : null;
  const routerPathname = state?.router?.location?.pathname;
  const windowPathname = !isServer && (window as any)?.location?.pathname;
  const windowPathnameWithHash = !isServer && `${(window as any)?.location?.pathname}#${hashString}`;
  const siteSection = pathSplitter(routerPathname || windowPathname);
  const pageName = pathReplacer(routerPathname || windowPathname);
  const pageNameWithHash = hashString ? `${pageName}:${hashString}` : pageName;
  return {
    siteSection: `${siteSection}`,
    pageName: `aicpa${pageNameWithHash}`,
    pageNameNoHash: `aicpa${pageName}`,
    routerPathname,
    windowPathname,
    windowPathnameWithHash,
  };
};

export const getContentId = () => {
  const state = store?.getState();
  return contentPageItemSelector(state)?.id
    ? contentPageItemSelector(state)?.id
    : staticLandingPageSelector(state)?.entryId
    ? staticLandingPageSelector(state)?.entryId
    : toolkitPageContentSelector(state)?.id || '';
};

export const getMembershipJourney = (a: any, b: State.Root): any => {
  const { siteSection, windowPathname } = getPageNameAndSiteSection();
  const section = a?.siteSection || siteSection;

  if (windowPathname.includes('landing/regional-pathway-cima')) return MembershipJourney.REGIONS;
  if (windowPathname.includes('landing/become-an-aicpa-member')) return MembershipJourney.MIP;
  if (windowPathname.includes('landing/fcma-credential')) return MembershipJourney.FCMA;
  if (windowPathname.includes('/account/firm')) return MembershipJourney.FIRM;

  switch (section) {
    case 'membership':
      return MembershipJourney.AICPA;
    case 'cima-membership':
    case 'cima-dashboard':
      return MembershipJourney.CIMA;
    case 'fcma-application':
      return MembershipJourney.FCMA;
    case 'cima-mip':
      if (window?.location?.search === '?isMipRenewal=true') return MembershipJourney.MIPRenewal;
      return MembershipJourney.MIP;
    case 'cima-mip-renewal':
      return MembershipJourney.MIPRenewal;
    case 'practical-experience-requirement':
      return MembershipJourney.PER;
    case 'center-membership':
      return MembershipJourney.CENTER;
    default:
      return MembershipJourney.AICPA;
  }
};

const filterPaymentByEntity = (obj: any) => {
  return Object.entries(obj)
    .map(([key, value]: any) => {
      if (!(typeof value === 'object' && value !== null)) return false;
      return value.map((val: any) => ({ ...val, key }));
    })
    .filter(Boolean)
    .flat();
};

export const getSelectedPaymentMethod = (a: any) => {
  const state = store?.getState();
  const paymentMethods = otherPaymentMethodSelector(state);
  const selectedCreditCardId = selectedCreditCardIdSelector(state);

  const association = filterPaymentByEntity(paymentMethods.association);
  const cima = filterPaymentByEntity(paymentMethods.cima);
  const allSavedCards = [...association, ...cima];

  const selectedCard = allSavedCards.find(val => (val.id = selectedCreditCardId));

  if (selectedCard) {
    if (selectedCard.key === 'creditCards') return 'credit card';
    return selectedCard.key;
  }

  if (a.isPaypalSelected) return 'paypal';

  return 'credit card';
};

export const isMembershipRenewalHandler = (a: any, b: State.Root): any => {
  const APPLICATION_ROUTES = {
    // AICPA ROUTES
    ...MEMBERSHIP_ROUTES,
    ...MEMBERSHIP_APPLICATION_ROUTES,
    // CIMA ROUTES
    ...CIMA_MEMBERSHIP_ROUTES,
  };
  const state = store?.getState();
  const isFLPUpgrade = isFlpDueRenewalSelector(state);
  const isCimaRenewal = isCimaDueRenewalSelector(state);
  const isAicpaRenewal = isAicpaDueRenewalSelector(state);
  const membershipJourney = getMembershipJourney(a, b);

  let isRenewalJourney = isAicpaRenewal;

  if (membershipJourney === MembershipJourney.CIMA) {
    isRenewalJourney = isCimaRenewal || isFLPUpgrade;
  }

  const { routerPathname, windowPathname } = getPageNameAndSiteSection();
  const pathname = routerPathname || windowPathname;

  const isApplicationRoutePath = Object.values(APPLICATION_ROUTES).find(route => {
    return pathname === route.path;
  });

  return isApplicationRoutePath ? isRenewalJourney : null;
};

export const extractPremiumPayload = (contentList: State.ContentCardItem[]) => {
  const aicpaContentAccessed = premiumAicpaContentAccessed(contentList);
  const aicpaGatedContent = premiumAicpaGatedContent(contentList);
  const topics = premiumTopics(contentList);

  return {
    aicpaContentAccessed,
    aicpaGatedContent,
    topics,
  };
};

export const premiumAicpaContentAccessed = (contentList: State.ContentCardItem[]) =>
  contentList.some(card => card.isLocked === Content.ContentLockStatus.UNLOCKED) ? 'true' : 'false';

export const premiumAicpaGatedContent = (contentList: State.ContentCardItem[]) =>
  contentList.some(card => card.restrictionDetails?.length > 1)
    ? `${ContentRestriction.PREMIUM}/${ContentRestriction.PREMIUM_SUBSCRIPTION}`
    : ContentRestriction.PREMIUM_SUBSCRIPTION;

export const premiumTopics = (contentList: State.ContentCardItem[]) =>
  contentList?.map((card: State.ContentCardItem) => card?.topicalSubscriptions?.[0]?.slug)?.[0];

const getShippingLocationOrType = (
  item: Common.ProductItemData,
  selectedShippingName?: string,
  getShippingLocationName?: boolean
) => {
  const isPhBasicProduct: boolean =
    (item.productType === Product.ProductType.PUBLICATION &&
      [Product.AvailableFormat.PAPERBACK, Product.AvailableFormat.HARDCOVER].includes(
        item.availableFormat?.key as Product.AvailableFormat
      )) ||
    (item.productType === Product.ProductType.COURSE && item.availableFormat?.key === Product.AvailableFormat.TEXT);
  const isPhSubscriptionProduct = isPhysicalProductCheck(item).isPhSubscriptionProduct;
  const isPhysicalProduct: boolean = isPhSubscriptionProduct || isPhBasicProduct;
  return isPhysicalProduct && selectedShippingName
    ? getShippingLocationName
      ? Checkout.ShippingLocationForAnalyticsMap[selectedShippingName]
      : Checkout.ShippingTypeNameAnalyticsByMethodNameMap[selectedShippingName]
    : 'Digital';
};

const getProductQuantities = (cartItems: Common.ProductItemData[]) => {
  const result = cartItems.reduce(
    (
      acc: { physicalProductsQty: number; physicalSubscriptionQty: number; digitalProductsQty: number },
      item: Common.ProductItemData
    ) => {
      const isPhBasicProduct: boolean =
        (item.productType === Product.ProductType.PUBLICATION &&
          [Product.AvailableFormat.PAPERBACK, Product.AvailableFormat.HARDCOVER].includes(
            item.availableFormat?.key as Product.AvailableFormat
          )) ||
        (item.productType === Product.ProductType.COURSE && item.availableFormat?.key === Product.AvailableFormat.TEXT);
      const isPhSubscriptionProduct = isPhysicalProductCheck(item).isPhSubscriptionProduct;
      return {
        physicalSubscriptionQty: isPhSubscriptionProduct
          ? acc.physicalSubscriptionQty + item.quantity!
          : acc.physicalSubscriptionQty,
        physicalProductsQty: isPhBasicProduct ? acc.physicalProductsQty + item.quantity! : acc.physicalProductsQty,
        digitalProductsQty:
          !isPhSubscriptionProduct && !isPhBasicProduct ? acc.digitalProductsQty + 1 : acc.digitalProductsQty,
      };
    },
    {
      physicalSubscriptionQty: 0,
      physicalProductsQty: 0,
      digitalProductsQty: 0,
    }
  );

  return result;
};

interface OrderTypeAnalyticsOptions {
  isStandingOrder: boolean;
  isBundle: boolean;
  isFreeTrial: boolean;
  isB2CMembership: boolean;
  isB2BMembership: boolean;
}

export const getOrderTypeAnalytics = (options: OrderTypeAnalyticsOptions): string => {
  switch (true) {
    case options.isFreeTrial:
      return 'Free Trial Order';
    case options.isStandingOrder && options.isBundle:
      return 'Bundle Standing Order';
    case options.isStandingOrder:
      return 'Standing Order';
    case options.isBundle:
      return 'Bundle Order';
    case options.isB2CMembership:
      return 'B2C Membership Order';
    case options.isB2BMembership:
      return 'B2B Membership Order';
    default:
      return 'Standard Order';
  }
};

export const isB2CMembershipType = (productType: string) => {
  const membershipProductTypes = ['membership-type', 'membership-benefit', 'membership-add-on-group', 'fee-type'];

  const b2cMembership = membershipProductTypes.some(b2cMembershipTypes => b2cMembershipTypes === productType);

  return b2cMembership;
};
export const isB2BMembershipType = (productType: string) => {
  const centerCategories = ['center-membership'];
  const b2bMembership = centerCategories.some(b2bMembershipTypes => b2bMembershipTypes === productType);
  return b2bMembership;
};

const getCartAnalyticsPayload = (
  cartItems: Common.ProductItemData[],
  selectedShipping?: Shipping.ShippingPrice,
  salesTax?: number | null,
  refundPrice?: number | null
) => {
  const payload = cartItems.reduce<CartCompoundAnalytics>(
    (acc: CartCompoundAnalytics, item, idx: number) => {
      if (!item) {
        return acc;
      }
      const isStandingOrderOptIn =
        item.custom?.fields?.standing_order_discount === Product.StandingOrderEligible.STANDING_NEW;
      // shipping cost per line item calculations
      const internationalWithMagazineFixedFeeCents = 2500;
      const isPhBasicProduct: boolean =
        (item.productType === Product.ProductType.PUBLICATION &&
          [Product.AvailableFormat.PAPERBACK, Product.AvailableFormat.HARDCOVER].includes(
            item.availableFormat?.key as Product.AvailableFormat
          )) ||
        (item.productType === Product.ProductType.COURSE && item.availableFormat?.key === Product.AvailableFormat.TEXT);
      const isPhSubscription: boolean = isPhysicalProductCheck(item).isPhSubscriptionProduct;
      const isPhProduct = isPhSubscription || isPhBasicProduct;
      const physicalBasicTotalQuantity: number = getProductQuantities(cartItems).physicalProductsQty;
      const physicalSubscriptionTotalQuantity: number = getProductQuantities(cartItems).physicalSubscriptionQty;
      const totalPhProductsQuantity: number = physicalBasicTotalQuantity + physicalSubscriptionTotalQuantity;
      const digitalProductsQuantity = getProductQuantities(cartItems).digitalProductsQty;
      const totalProductsQuantity = digitalProductsQuantity + totalPhProductsQuantity;
      const shippingCostPerLineItem: number =
        selectedShipping?.name === Checkout.ShippingTypeName.INTERNATIONAL_WITH_MAGAZINE
          ? isPhSubscription
            ? (Math.round(internationalWithMagazineFixedFeeCents / physicalSubscriptionTotalQuantity) *
                item.quantity!) /
              100
            : isPhBasicProduct
            ? Math.round(
                ((selectedShipping.centAmount - internationalWithMagazineFixedFeeCents) / physicalBasicTotalQuantity) *
                  item.quantity!
              ) / 100
            : 0
          : selectedShipping?.centAmount && isPhProduct
          ? (Math.round(selectedShipping.centAmount / totalPhProductsQuantity) * item.quantity!) / 100
          : 0;

      const taxPerLineItem: number =
        (salesTax && Math.round(((salesTax * 100) / totalProductsQuantity) * item.quantity!) / 100) || 0;

      const sku = Boolean(item.masterVariantSKU) ? item.masterVariantSKU : item.sku;

      const isBundle = item.bundleId ? item.bundleName || item.title : item.title;

      const formattedProratedPrice = priceToFloat(item.formattedProratedPrice) || 0;
      const formattedPrice = parseFloat(item.formattedPrice!) || 0;
      const prices = Boolean(item.formattedProratedPrice) ? formattedProratedPrice : formattedPrice;

      const isB2BMembership = isB2BMembershipType(item.productType);
      const isB2CMembership = isB2CMembershipType(item.productType);

      acc.name.push(isBundle || '');
      acc.category.push(item.productType);
      acc.id.push(item.bundleId! || item.productId!);
      acc.bundleId.push(item.bundleId || '');
      acc.bundleSku?.push(item?.masterVariantSKU || '');
      acc.sku.push(sku || '');
      acc.quantity.push(item.quantity || 1);
      acc.price.push(prices > 0 ? prices : 0);
      acc.orderType.push(
        getOrderTypeAnalytics({
          isStandingOrder: isStandingOrderOptIn,
          isBundle: !!item.bundleId,
          isFreeTrial: !!item.isFreeTrial,
          isB2BMembership,
          isB2CMembership,
        })
      );
      acc.shippingLocation.push(getShippingLocationOrType(item, selectedShipping?.name, true));
      acc.shippingType.push(getShippingLocationOrType(item, selectedShipping?.name));
      acc.shippingCost.push(shippingCostPerLineItem);
      acc.productTax.push(taxPerLineItem);
      acc.freeTrialEndDate.push(item.isFreeTrial ? item.accessEndDate : '');
      return acc;
    },

    {
      name: [],
      category: [],
      id: [],
      bundleId: [],
      bundleSku: [],
      sku: [],
      quantity: [],
      price: [],
      orderType: [],
      shippingType: [],
      shippingCost: [],
      freeTrialEndDate: [],
      shippingLocation: [],
      productTax: [],
    }
  );
  const taxAmount = payload.productTax.reduce((a, b) => a + b, 0);
  const cartAmount = (payload.price.reduce((a, b) => a + b, 0) + (taxAmount || 0) - (refundPrice || 0)).toFixed(2);

  return {
    ...payload,
    cartAmount: priceToFloat(cartAmount), // toFixed method turns number -> string
  };
};

const getItemsPriceInfoPayload: (
  itemsPriceInfo: Cart.CartItemPriceInfo[],
  paymentTypeAll?: CART_PAYMENT_TYPE // TODO use real info when multiple types available
) => AdditionalCartCompoundAnalytics = (itemsPriceInfo, paymentTypeAll = CART_PAYMENT_TYPE.CARD) => {
  const payload = itemsPriceInfo.reduce(
    (acc, item) => {
      const promoDiscounts = item.discounts.filter(
        (discount: Cart.DiscountDetails) => discount.discountType === Cart.DISCOUNT_TYPE.CART_PROMO_DISCOUNT
      );
      const promoLabel = promoDiscounts && promoDiscounts.length ? promoDiscounts[0].label : '';

      const { reason } = getDiscountAnalytics(item.discounts);
      if (item?.donationNames) {
        // to have discount_amount and discount_reason for each donation sku selected
        item?.donationNames.forEach(() => {
          acc.discountReason.push(reason);
          acc.discountAmount.push(+item.formattedTotalDiscount);
          acc.couponsCodes.push(promoLabel);
          acc.paymentType.push(paymentTypeAll);
        });
      } else {
        acc.discountReason.push(reason);
        acc.discountAmount.push(+item.formattedTotalDiscount);
        acc.couponsCodes.push(promoLabel);
        acc.paymentType.push(paymentTypeAll);
      }

      return acc;
    },
    {
      couponsCodes: [] as string[],
      paymentType: [] as string[],
      discountReason: [] as string[],
      discountAmount: [] as number[],
    }
  );
  return payload;
};

const transformAnalyticsCartItems: (cartItems: Common.ProductItemData[]) => Common.ProductItemData[] = (
  cartItems: Common.ProductItemData[]
) => {
  const newCartItems: { [key: string]: Common.ProductItemData } = cartItems?.reduce(
    (res: { [key: string]: Common.ProductItemData }, value: any) => {
      if (!res[value?.bundleId || value?.lineItemId]) {
        res[value?.bundleId || value?.lineItemId] = {
          ...value,
          formattedPrice: 0,
        };
      }

      res[value?.bundleId! || value?.lineItemId!].formattedPrice! += priceToFloat(value.formattedPrice);

      return res;
    },
    {}
  );
  return Object.values(newCartItems) as Common.ProductItemData[];
};

const transformAnalyticsItemPriceInfo: (
  itemsPriceInfo: Cart.CartItemPriceInfo[]
) => Cart.CartItemPriceInfo[] = itemsPriceInfo => {
  const newItemPriceInfo = itemsPriceInfo.reduce((res: any, value: any) => {
    if (!res[value?.bundleId || value.lineItemId]) {
      res[value?.bundleId || value.lineItemId] = {
        ...value,
        formattedTotalDiscount: 0,
      };
    }

    res[value?.bundleId || value?.lineItemId].formattedTotalDiscount += priceToFloat(value.formattedTotalDiscount);

    return res;
  }, {});
  return Object.values(newItemPriceInfo);
};

export const extractCartPayload: (
  cartItems: Common.ProductItemData[] | null,
  itemsPriceInfo: Cart.CartItemPriceInfo[],
  selectedShipping?: Shipping.ShippingPrice,
  salesTax?: number | null,
  refundPrice?: number | null
) => CartCompoundAnalytics | null = (cartItems, itemsPriceInfo, selectedShipping, salesTax, refundPrice) => {
  if (!cartItems || !cartItems.length) {
    return null;
  }

  const newCartItems = transformAnalyticsCartItems(cartItems);

  const newItemPriceInfo = transformAnalyticsItemPriceInfo(itemsPriceInfo);

  const updatedCartItems = newCartItems.reduce((state: Common.ProductItemData[], current): Common.ProductItemData[] => {
    if (!current) return [];
    const intersection = newItemPriceInfo.find(
      (item: Cart.CartItemPriceInfo) => item.lineItemId === current.lineItemId
    );
    state.push({ ...current, formattedProratedPrice: intersection?.formattedProratedPrice });

    return state;
  }, []);

  const cartPayload = getCartAnalyticsPayload(updatedCartItems, selectedShipping, salesTax, refundPrice);
  const { discountReason, discountAmount } = getItemsPriceInfoPayload(newItemPriceInfo);

  const payload = { ...cartPayload, refundPrice, discountReason, discountAmount };

  if (!payload.id.length) {
    return null;
  }
  return payload;
};

const extractPaymentCardPayload: (savedCardUsed: boolean | null) => PaymentCardPayload = savedCardUsed => ({
  cardPaymentType: savedCardUsed === null ? '' : savedCardUsed ? 'saved_card_used' : 'saved_card_not_used',
});

export const extractPaymentCartPayload: (
  cartItems: Common.ProductItemData[] | null,
  itemsPriceInfo: Cart.CartItemPriceInfo[],
  savedCardUsed: boolean,
  paymentTypeAll?: CART_PAYMENT_TYPE, // TODO use real info when multiple types available
  salesTax?: number | null,
  selectedShipping?: Shipping.ShippingPrice,
  membershipRefund?: number | null
) => PaymentCartCompoundAnalytics | null = (
  cartItems,
  itemsPriceInfo,
  savedCardUsed,
  paymentTypeAll = CART_PAYMENT_TYPE.CARD,
  salesTax,
  selectedShipping,
  membershipRefund
) => {
  if (!cartItems || !cartItems.length || !itemsPriceInfo || !itemsPriceInfo.length) {
    return null;
  }

  const newCartItems = transformAnalyticsCartItems(cartItems);
  const newItemPriceInfo = transformAnalyticsItemPriceInfo(itemsPriceInfo);

  const updatedCartItems = newCartItems.reduce((state: Common.ProductItemData[], current): Common.ProductItemData[] => {
    if (!current) return [];
    const intersection = newItemPriceInfo.find(
      (item: Cart.CartItemPriceInfo) => item.lineItemId === current.lineItemId
    );
    state.push({ ...current, formattedProratedPrice: intersection?.formattedProratedPrice });

    return state;
  }, []);

  const cartPayload = getCartAnalyticsPayload(updatedCartItems, selectedShipping, salesTax, membershipRefund);
  const { cardPaymentType } = extractPaymentCardPayload(savedCardUsed);
  const { couponsCodes, discountReason, discountAmount, paymentType } = getItemsPriceInfoPayload(newItemPriceInfo);
  const payloadWithPromos = {
    ...cartPayload,
    couponsCodes,
    discountReason,
    discountAmount,
    paymentType,
    cardPaymentType,
  };

  if (!payloadWithPromos.id.length) {
    return null;
  }

  return payloadWithPromos;
};

export const extractPaymentOrderPayload: (
  cartItems: Common.ProductItemData[] | null,
  itemsPriceInfo: Cart.CartItemPriceInfo[],
  orderNumber: string | null,
  savedCardUsed: boolean | null,
  paymentTypeAll?: CART_PAYMENT_TYPE, // TODO use real info when multiple types available
  shippingInfo?: Shipping.ShippingPrice,
  summary?: State.ProductsListDataSummary
) => PaymentCartCompoundAnalytics | null = (
  cartItems,
  itemsPriceInfo,
  orderNumber,
  savedCardUsed,
  paymentTypeAll = CART_PAYMENT_TYPE.CARD,
  shippingInfo,
  summary
) => {
  if (!cartItems || !cartItems.length) {
    return null;
  }
  const isRefund = Boolean(summary && summary?.isRefund === true);
  const totalCentAmount = (summary && summary?.totalPrice?.centAmount) || 0;
  const totalAmount = totalCentAmount / 100;
  const refundCentAmount = (summary && summary?.refundPrice?.centAmount) ?? 0;
  const refundPrice = refundCentAmount / 100;
  const shippingCentAmount = shippingInfo?.centAmount ?? 0;
  const shippingPrice = shippingCentAmount / 100;

  const newCartItems = transformAnalyticsCartItems(cartItems);
  const newItemPriceInfo = transformAnalyticsItemPriceInfo(itemsPriceInfo);

  const updatedCartItems = newCartItems.reduce((state: Common.ProductItemData[], current): Common.ProductItemData[] => {
    if (!current) return [];
    const intersection = newItemPriceInfo.find(
      (item: Cart.CartItemPriceInfo) => item.lineItemId === current.lineItemId
    );
    state.push({ ...current, formattedProratedPrice: intersection?.formattedProratedPrice });

    return state;
  }, []);

  const productCost: number = itemsPriceInfo.reduce((acc, product) => {
    const price = Boolean(product.proratedPrice)
      ? product?.proratedPrice?.centAmount ?? 0
      : product.price.centAmount || 0;
    return acc + price;
  }, 0);
  const productPrice = productCost / 100;
  const salesTax = isRefund
    ? Math.abs(refundPrice) - Math.abs(totalAmount) - Math.abs(productPrice)
    : totalAmount - productPrice - shippingPrice || 0;

  const payload = getCartAnalyticsPayload(updatedCartItems, shippingInfo, salesTax, refundPrice);
  const { cardPaymentType } = extractPaymentCardPayload(savedCardUsed);
  const { discountReason, discountAmount, couponsCodes, paymentType } = getItemsPriceInfoPayload(newItemPriceInfo);

  const payloadWithPromos = {
    ...payload,
    cartAmount: isRefund ? totalAmount : totalAmount - refundPrice, // override the result from getCartAnalyticsPayload to get exact total amount
    couponsCodes,
    paymentType,
    discountReason,
    discountAmount,
    orderNumber,
    cardPaymentType,
  };

  if (!payloadWithPromos.id.length) {
    return null;
  }

  return payloadWithPromos;
};

export const getInvoicePaymentCheckoutPayload: (
  payload: Invoices.InvoiceAnalytics
) => InvoiceCheckoutPaymentPayload = payload => {
  const { isB2B, amountOption, isSavedCardUsed, invoiceNumber, amount } = payload;

  const orderType = isB2B ? 'B2B Invoice Order' : 'B2C Invoice Order';
  const fullOrPartialPaymentType =
    amountOption === Invoices.PaymentAmountOptions.FULL_PAYMENT
      ? `${orderType} - Invoice Full`
      : `${orderType} - Invoice Partial`;
  const isSavedCardUsedType = isSavedCardUsed ? 'saved_card_used' : 'saved_card_not_used';

  return {
    orderType,
    id: invoiceNumber,
    paymentType: 'card',
    category: fullOrPartialPaymentType,
    name: fullOrPartialPaymentType,
    sku: fullOrPartialPaymentType,
    price: amount,
    quantity: 1,
    cardPaymentType: isB2B ? '' : isSavedCardUsedType,
  };
};

const getDiscountAnalytics = (discounts: Cart.DiscountDetails[]): any => {
  const { amount, reason } = discounts.reduce(
    (acc: { amount: number; reason: string[] }, discount: Cart.DiscountDetails) => {
      const discountAmount: number = discount.formattedDiscountAmount
        ? priceToFloat(discount.formattedDiscountAmount)
        : 0;
      acc.amount += discountAmount;
      acc.reason.push(discount && discount.label);
      return acc;
    },
    {
      amount: 0,
      reason: [] as string[],
    }
  );
  return { amount, reason: reason && reason.length && reason.join('|') };
};

export const getPageNameAccessor: (hash?: string) => Accessor[] = hash => [
  {
    name: 'page_name',
    path: '',
    processor: a => {
      if (a?.pageName) return a.pageName;
      const { pageName } = getPageNameAndSiteSection(hash);
      return pageName;
    },
  },
  {
    name: 'site_section',
    path: '',
    processor: a => {
      if (a?.siteSection) return a.siteSection;
      const { siteSection } = getPageNameAndSiteSection(hash);
      return siteSection;
    },
  },
];

export enum LoginOrReg {
  Login = 'login',
  Reg = 'reg',
}

export const getLoginAccessors = (loginOrReg: LoginOrReg) => [
  {
    name: 'modal_view_name',
    path: 'isModal',
    processor: (isModal: boolean) => `${isModal ? 'modal' : 'web'}-${loginOrReg}`,
  },
  {
    name: 'modal_event',
    path: '',
    processor: () => `${loginOrReg}-start`,
  },
  ...getPageNameAccessor(),
];

export const getSessionCampaignIdAccessors: () => Accessor[] = () => [
  {
    name: 'session_cid',
    path: '',
    processor: (a, b) => `${b?.router?.location?.query?.cid}`,
  },
];

export const headerAndFooterEventPayload = (prefix: any, route: Routes, textValue?: string) => {
  const { pageName, siteSection, routerPathname, windowPathname } = getPageNameAndSiteSection();
  const searchHash = '#search';
  const isSearchHash = getPath(route).includes(searchHash);
  const destinationPath = isSearchHash ? `${routerPathname || windowPathname}${searchHash}` : getPath(route);
  const rootRoutes = [Routes.ROOT, Routes.FEED, Routes.ADMIN_ROOT, Routes.ADMIN_ORGANIZATION];
  const isLogoClicked = rootRoutes.indexOf(route) !== -1;
  const pathSliced = destinationPath.substring(destinationPath.lastIndexOf('/') + 1);
  const buttonText = isLogoClicked ? 'Logo' : textValue;

  const genericAttributes = {
    value: `${prefix}:${isLogoClicked ? 'Logo' : pathSliced}`,
    href: destinationPath,
    clickValue: `button:link:int:${prefix}::${buttonText}:${!pathSliced ? '/home' : destinationPath}`, // default to home if no value
    pageName,
    siteSection,
  };

  return genericAttributes;
};

const bundleProductsInfo = (product: State.ContentCardItem, state: State.Root) => {
  const isAuth = isAuthSelector(state);
  const userMemberType = userMemberTypeSelector(state);
  const currency = productCurrencySelector(state).label;
  const isMember: boolean = userMemberType === UserMemberTypes.PREMIUM || userMemberType === UserMemberTypes.MEMBER;

  const bundleInfo = product?.bundleInfo;

  const productPrices: State.BundleMembershipPrices | undefined = getFormattedBundlePrice(bundleInfo?.prices, currency);

  const fractionAfterDiscount = Math.round(100 - (bundleInfo?.bundleDiscountPercent || 0)) / 100;

  const minPriceNonMemberBundleFormatted = fromCentsFormat(
    (productPrices?.nonMember?.min?.amount || 0) * fractionAfterDiscount
  ).toFixed(2);

  const minPriceMemberBundleFormatted = fromCentsFormat(
    (productPrices?.member?.min?.amount || 0) * fractionAfterDiscount
  ).toFixed(2);

  const minPrice: string | undefined = isAuth
    ? isMember
      ? minPriceMemberBundleFormatted
      : minPriceNonMemberBundleFormatted
    : minPriceMemberBundleFormatted;

  return priceToFloat(minPrice).toFixed(2);
};

const storefrontProductsPayload = (allProducts: State.ContentCardItem[], state: State.Root) => {
  return allProducts?.reduce(
    (
      acc: {
        category: string[];
        id: string[];
        price: number[];
      },
      product: State.ContentCardItem
    ) => {
      const price =
        (product?.prices?.sort((price1: State.Price, price2: State.Price) => price2?.amount - price1?.amount)?.[0]
          ?.amount as number) || 0;

      const bundlePrice = bundleProductsInfo(product, state);

      const bundleInfo = product?.bundleInfo;

      const finalPrice = bundleInfo ? bundlePrice : price;

      acc.category.push(product?.productType?.[0]);
      acc.id.push(product?.id);
      acc.price.push(+finalPrice);
      return acc;
    },
    {
      category: [],
      id: [],
      price: [],
    }
  );
};

export const storefrontLandingPageProducts = (
  pageBlocks: Contentful.LandingPages.LandingPageBlock[],
  state: State.Root
) => {
  const aggProducts = pageBlocks?.reduce(
    (acc: State.ContentCardItem[], block: Contentful.LandingPages.LandingPageBlock) => {
      if (block?.products) {
        acc.push(...(block.products as (State.ContentCardItem | any)[]));
      }
      return acc;
    },
    []
  );
  return storefrontProductsPayload(aggProducts || [], state);
};

export const storefrontAggregationPageProducts = (allProducts: State.ContentCardItem[], state: State.Root) => {
  const aggProducts = allProducts?.reduce(
    (acc: State.ContentCardItem[], index: State.ContentCardItem[] | State.ContentCardItem) => {
      acc.push(...(index as State.ContentCardItem[]));
      return acc;
    },
    []
  );
  return storefrontProductsPayload(aggProducts || [], state);
};
let lastLocationPath: string | null = null;
export const validateEvent = (type: string, state: State.Root, payload?: any) => {
  const { routerPathname, windowPathname, windowPathnameWithHash } = getPageNameAndSiteSection();
  const pathname = routerPathname || windowPathname || '';
  const storefrontPage = getPath(Routes.STOREFRONT_PAGE);
  const membershipTypes = getPath(Routes.MEMBERSHIP_FORM);
  const centerMembership = getPath(Routes.CENTER_MEMBERSHIP_FORM);
  const cimaMembership = getPath(Routes.CIMA_MEMBERSHIP_PACKAGE);
  const isProductRelated = state?.products?.relatedContent?.filter(
    data => data?.contentfulType === ProductTypes.product
  ).length;
  const getProductRelated = state?.content?.relatedContent?.filter(
    data => data?.contentfulType === ProductTypes.product
  ).length;
  const isAuth = state?.user?.isAuth;
  const currentContentId = state?.layouts?.currentContentId;
  const previousContentId = state?.layouts?.previousContentId;

  switch (type) {
    case LOCATION_CHANGE:
      if (payload.force) return true; // to allow page view
      if (payload && routerPathname === lastLocationPath) return;
      lastLocationPath = state?.router?.location?.pathname;
      return true;
    case 'search/FETCH_CONTENT':
      return pathname !== storefrontPage;
    case 'content/FETCH_LANDING_PAGE':
      if (pathname === membershipTypes) return; // no page_view on package builder
      return pathname === storefrontPage;
    case CUSTOM_EVENTS.AGGREGATION_PRODUCT_VIEW:
      return !pathname.includes(storefrontPage);
    case 'membership/STOREFRONT_AGGREGATION_VIEW_TYPE':
    case 'membership/STOREFRONT_AGGREGATION_VIEW_TIER':
    case 'membership/STOREFRONT_AGGREGATION_VIEW_SECTION':
    case 'membership/STOREFRONT_AGGREGATION_VIEW_CREDENTIAL':
    case 'membership/STOREFRONT_AGGREGATION_VIEW_SKILL':
    case 'membership/GET_MEMBERSHIP_RELATED_ADDONS':
    case 'membership/STOREFRONT_AGGREGATION_VIEW_CIMA_TYPE':
      if (windowPathnameWithHash === `${cimaMembership}#summary`) return; // for cima skill storefront
      return pathname === membershipTypes || pathname === centerMembership || pathname === cimaMembership;
    case 'products/GET_PRODUCT_ITEM':
      if (state?.products?.isProductViewTriggered) return;
      return true;
    case 'content/GET_CONTENT_ITEM':
      if (state?.content?.isContentFetched && previousContentId === currentContentId) return;
      return true;
    case 'products/GET_RELATED_CONTENT':
      if (!isProductRelated) return; // if the product view is not exact path or unpublish in contentful
      if (state?.products?.isRelatedProductFetched) return;
      return true;
    case 'content/GET_RELATED_CONTENT':
      if (!getProductRelated) return;
      if (isAuth && state?.content?.isRelatedContentFetched) return;
      return true;
    case CUSTOM_EVENTS.INVOICE_PAYMENT_COMPLETE:
      if (!payload?.id) return;
      return true;
    default:
      return true;
  }
};

export const currentLearningPathway: any = (currentJourneyLearningPathway: any, isFLP: any) => {
  if (isFLP && currentJourneyLearningPathway !== MembershipTypes.Pathway.FLP) {
    return { isFLP: false };
  }
  return { isFLP };
};
