import moment from 'moment-timezone';
import { handleActions, Action } from 'redux-actions';
import { initialState } from './constants';
import { getReceipts, resetModule, setDownloadingId, getReceiptFile } from './actions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [getReceipts]: {
    next: (state: State.Receipts, action: Action<State.InvoiceTableRow[]>): State.Receipts => ({
      ...state,
      receiptsList: action.payload.map((receipt: State.InvoiceTableRow) => ({
        ...receipt,
        date: moment(receipt.invoiceDate).format('MMM DD, YYYY'),
      })),
      receiptsListFetched: true,
    }),
  },

  [setDownloadingId]: (state: State.Receipts, action: any): State.Receipts => ({
    ...state,
    downloadingId: action.payload,
    downloadingError: false,
  }),

  [getReceiptFile]: {
    next: (state: State.Receipts): State.Receipts => ({
      ...state,
      downloadingId: null,
      downloadingError: false,
    }),
    throw: (state: State.Receipts): State.Receipts => ({
      ...state,
      downloadingError: true,
    }),
  },

  [resetModule]: (): State.Receipts => initialState,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);

export * from './selectors';
export * from './constants';
export * from './actions';
