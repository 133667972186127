import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import qs from 'query-string';
import {
  isCartFetchedSelector,
  isCartLoadingSelector,
  isExistingCartItemSelector,
  hasPromoSelector,
} from 'modules/cart/selectors';
import { isServer } from 'utils';
import { addCartItem, addPromoCodeItem } from 'modules/cart';
import { isProductFetchedSelector } from 'modules/products/selectors';

export const useAutoAddProductSkuAndPromo = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => qs.parse(search), [search]);
  const isCartFetched: boolean | undefined = useSelector(isCartFetchedSelector);
  const isCartLoading: boolean = useSelector(isCartLoadingSelector);
  const isExistingCartItem = useSelector(isExistingCartItemSelector);
  const isProductFetched = useSelector(isProductFetchedSelector);
  const hasPromo = useSelector(hasPromoSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isServer) {
      async function process() {
        if (isCartFetched && (isProductFetched || (!isProductFetched && queryParams?.code && !queryParams?.sku))) {
          const canPromoBeAddedtoCart = Boolean(queryParams?.code && !isCartLoading && !hasPromo);
          const canProductBeAddedtoCart = Boolean(queryParams?.sku && !isCartLoading && !isExistingCartItem);
          if (canProductBeAddedtoCart) {
            await dispatch(addCartItem('', queryParams?.sku, null, true));
            if (canPromoBeAddedtoCart) {
              await dispatch(addPromoCodeItem(queryParams?.code, true));
              return;
            }
          }
          if (canPromoBeAddedtoCart) await dispatch(addPromoCodeItem(queryParams?.code, true));
        }
      }
      process();
    }
  }, [isCartFetched, isProductFetched]); // eslint-disable-line react-hooks/exhaustive-deps
};
