import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { cancelInvoice, toggleSelectedSubscription } from 'modules/admin/actions';
import { selectedSubscriptionsSelector } from 'modules/admin/selectors';
import { formatPrice, getSessionStorageItem, setSessionStorageItem } from 'utils';
import { handleEvent, EVENT_CLICK } from 'utils/Analytics';
import { Modal, ButtonEnums, Button, ButtonVariants } from 'components/atoms';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';
import { ActionResultModal } from 'components/organisms/FirmDataTable/ActionResultModal';
import { ActionResult } from 'constants/firm-admin';
import { Invoices, ZuoraTypes } from 'mxp-schemas';
import { StorageNames, SESSION_STORE_NAMES } from 'constants/index';

interface Props {
  invoice: State.SearchInvoicesResult;
  cancelInvoiceBtnTestId?: string;
  cancelInvoiceBtnText?: string;
  cancelInvoiceBtnIcon?: React.ReactNode;
  cancelInvoiceBtnVariant?: ButtonVariants;
  disabled?: boolean;
  invoiceItem?: State.InvoiceItemResult;
  subscription?: State.SubscriptionsResult;
  isInvoiceParent?: boolean;
  onSuccess?: () => void;
}

enum Stage {
  AreYouSure,
  Done,
  Fail,
}

export const CancelInvoiceModal: React.FC<Props> = ({
  invoice,
  cancelInvoiceBtnTestId,
  cancelInvoiceBtnText,
  cancelInvoiceBtnIcon,
  cancelInvoiceBtnVariant,
  disabled,
  invoiceItem,
  subscription,
  isInvoiceParent,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [stage, setStage] = useState<Stage>(Stage.AreYouSure);
  const [cancelInvoiceModalOpen, setCancelInvoiceModalOpen] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const shouldStyleCancelButton: boolean = !cancelInvoiceBtnIcon && !cancelInvoiceBtnVariant;
  const selectedSubscriptions: State.SubscriptionsResult[] = useSelector(selectedSubscriptionsSelector);
  const [balance, setBalance] = useState(0);
  const [invoiceRemoveItem, setInvoiceRemoveItem] = useState<State.InvoiceItemResult>();
  const [itemFlag, setItemFlag] = useState(false);

  React.useEffect(() => {
    const isSubscriptionInSelectedList = selectedSubscriptions.some(
      item => item.subscriptionName === invoiceItem?.subscriptionName
    );
    if (
      cancelInvoiceModalOpen &&
      invoiceItem &&
      ((!isSubscriptionInSelectedList && selectedSubscriptions.length === 1) || selectedSubscriptions.length > 1)
    ) {
      selectedSubscriptions.forEach(item => dispatch(toggleSelectedSubscription(item.subscriptionName)));
      dispatch(toggleSelectedSubscription(subscription?.subscriptionName));
    }
  }, [dispatch, invoiceItem, selectedSubscriptions, cancelInvoiceModalOpen, subscription]);

  const selectedInvoiceItemsBalance = React.useMemo(
    () =>
      selectedSubscriptions.reduce((total, item: State.SubscriptionsResult) => {
        return total + item.chargeAmount;
      }, 0),
    [selectedSubscriptions]
  );

  const handleOpenModal = React.useCallback(() => {
    if (typeof invoiceItem !== 'undefined') {
      setItemFlag(true);
      setBalance(invoiceItem!.balance + (invoiceItem!.taxAmount || 0));
      setInvoiceRemoveItem(invoiceItem);
    }
    setCancelInvoiceModalOpen(true);
  }, [setCancelInvoiceModalOpen, invoiceItem]);

  const mapping = React.useMemo((): {
    [key in Stage]: [string | React.ReactElement, string, string | null, string, React.ReactElement | null];
  } => {
    const returnButtonText = `Return to ${selectedSubscriptions?.length ? 'Invoice Details' : 'Search Results'}`;
    return {
      [Stage.AreYouSure]: [
        selectedSubscriptions?.length || cancelInvoiceBtnText === 'Remove Item' ? (
          <>
            Are you sure you want to remove{' '}
            <StyledMedium>{selectedSubscriptions?.length === 0 ? 1 : selectedSubscriptions?.length}</StyledMedium> items
            from your invoice {invoice.invoiceNumber}?
          </>
        ) : (
          `Are you sure you want to cancel your invoice ${invoice.invoiceNumber}?`
        ),
        'Confirm Changes',
        'Cancel Changes',
        'tiny',
        null,
      ],
      [Stage.Done]: [
        !isInvoiceParent ? (
          <>
            <StyledMedium>{selectedSubscriptions?.length || `1`}</StyledMedium> selected items have been removed from{' '}
            {invoice.invoiceNumber}
          </>
        ) : (
          `Your Invoice ${invoice.invoiceNumber} has been cancelled`
        ),
        returnButtonText,
        null,
        'tiny',
        <StyledCheckmark key="0" />,
      ],
      [Stage.Fail]: ['Cannot cancel invoice', returnButtonText, null, 'tiny', null],
    };
  }, [invoice, selectedSubscriptions, cancelInvoiceBtnText, isInvoiceParent]);

  const [heading, confirmationButtonLabel, cancellationButtonLabel, modalSize, icon] = mapping[stage];
  const modalContent = React.useMemo(() => {
    if (stage === Stage.Fail) {
      return (
        <ActionResultModal
          resultTitle={heading}
          status={ActionResult.Fail}
          showResult
          resultHelperText={errorMessage}
        />
      );
    }
    if (stage === Stage.AreYouSure) {
      return (
        <StyledParagraph>
          <>
            A credit memo worth{' '}
            <StyledMedium>
              {formatPrice(
                selectedSubscriptions?.length ? selectedInvoiceItemsBalance : itemFlag ? balance : invoice.amount,
                invoice.currency
              )}
            </StyledMedium>{' '}
            amount will be applied against your invoice.
          </>

          <StyledDefaultText>
            Once the changes have been confirmed, your affected members will be notified, you will not be able to easily
            undo the changes.
          </StyledDefaultText>
        </StyledParagraph>
      );
    }
    if (stage === Stage.Done) {
      return (
        <StyledSuccessContainer>
          <StyledSuccessText>
            Emails will be sent to those on your roster whose records have been updated, informing them of the change.
          </StyledSuccessText>
        </StyledSuccessContainer>
      );
    }
    return null;
  }, [stage, errorMessage, heading, selectedSubscriptions, selectedInvoiceItemsBalance, balance, invoice, itemFlag]);
  const onConfirmEvent = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      switch (stage) {
        case Stage.AreYouSure:
          if (cancelInvoice) {
            setCancelLoading(true);

            const params: Invoices.CancelInvoiceParams = {
              invoiceId: invoice.id,
              subscriptionIds: invoiceRemoveItem
                ? [invoiceRemoveItem.id]
                : selectedSubscriptions?.length
                ? selectedSubscriptions.map((item: State.SubscriptionsResult) => item.subscriptionName)
                : [],
              entityType: invoice.legalEntity?.toLowerCase().includes('cima')
                ? ZuoraTypes.EntityName.UK_CIMA
                : ZuoraTypes.EntityName.US_AICPA,
            };

            await dispatch(cancelInvoice(params))
              .then(() => {
                updateSession(params);
                setStage(Stage.Done);
              })
              .catch(() => {
                setErrorMessage('System error please try again, research why invoice can’t be cancelled.');
                setStage(Stage.Fail);
              })
              .finally(() => {
                setCancelLoading(false);
              });
          }
          break;
        case Stage.Done:
          setStage(Stage.AreYouSure);
          setCancelInvoiceModalOpen(false);
          if (onSuccess) {
            onSuccess();
          }
          break;
        case Stage.Fail:
          setStage(Stage.AreYouSure);
          setCancelInvoiceModalOpen(false);
          break;
      }

      return handleEvent({ clickValue: `button:cancel-invoice:int:modal-${confirmationButtonLabel}` }, EVENT_CLICK);
    },
    [dispatch, confirmationButtonLabel, stage, invoice, selectedSubscriptions, invoiceRemoveItem, onSuccess]
  );

  const onCancelEvent = React.useCallback(() => {
    setCancelInvoiceModalOpen(!cancelInvoiceModalOpen);
    setStage(Stage.AreYouSure);
    return handleEvent({ clickValue: `button:cancel-invoice:int:modal-${cancellationButtonLabel}` }, EVENT_CLICK);
  }, [cancellationButtonLabel, setCancelInvoiceModalOpen, cancelInvoiceModalOpen]);

  const updateSession = (params: Invoices.CancelInvoiceParams) => {
    const invoiceInProgress: any = getSessionStorageItem(
      StorageNames.faCancelInvoice,
      SESSION_STORE_NAMES.FB_ADMIN
    ) as any;
    let inProgressItems: any[] = [];
    if (invoiceInProgress) {
      const isInList = invoiceInProgress.find((item: any) => item.invoiceId === params.invoiceId);
      inProgressItems = !isInList
        ? [...invoiceInProgress, { invoiceId: params.invoiceId, triggeredDate: new Date() }]
        : invoiceInProgress;
    } else {
      inProgressItems = [{ invoiceId: params.invoiceId, triggeredDate: new Date() }];
    }
    setSessionStorageItem({ [StorageNames.faCancelInvoice]: inProgressItems }, SESSION_STORE_NAMES.FB_ADMIN);
  };

  return (
    <StyledModal
      stage={stage}
      icon={icon}
      showCloseCross={stage !== Stage.Done}
      open={cancelInvoiceModalOpen}
      size={modalSize}
      heading={stage !== Stage.Fail && heading}
      testId="confirm-cancel-invoice"
      onClose={onCancelEvent}
      cancelNode={
        !!cancellationButtonLabel && (
          <Button
            className="cancel"
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.secondary}
            testId="cancel"
            onClick={onCancelEvent}
          >
            {cancellationButtonLabel}
          </Button>
        )
      }
      confirmNode={
        <Button
          className="confirm"
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          testId="confirm"
          onClick={onConfirmEvent}
          disabled={stage === Stage.AreYouSure && cancelLoading}
          loading={stage === Stage.AreYouSure && cancelLoading}
        >
          {confirmationButtonLabel}
        </Button>
      }
      trigger={
        <ButtonStyled
          bordered
          size={ButtonEnums.sizes.small}
          iconPosition={cancelInvoiceBtnIcon ? ButtonEnums.iconPosition.left : undefined}
          icon={cancelInvoiceBtnIcon}
          variant={cancelInvoiceBtnVariant || ButtonEnums.variants.secondary}
          onClick={handleOpenModal}
          toggle
          disabled={disabled}
          testId={cancelInvoiceBtnTestId || 'cancel-invoice-btn'}
          data-should-style={shouldStyleCancelButton}
        >
          {cancelInvoiceBtnText ?? 'Cancel Invoice'}
        </ButtonStyled>
      }
    >
      {modalContent}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)<{ stage: Stage }>`
  &&&&&& {
    ${props =>
      (props.stage === Stage.Done || props.stage === Stage.Fail) &&
      `
      .actions > button {
        min-width: ${props.theme.pxToRem(260)};
      }
    `}

    .header > h2 {
      padding-bottom: ${props => props.theme.pxToRem(15)};
    }
  }
`;

const StyledParagraph = styled.p`
  text-align: center;
  padding-top: ${props => props.theme.pxToRem(15)};
  padding-bottom: ${props => props.theme.pxToRem(13)};
`;

const StyledCheckmark = styled(Checkmark)`
  &&& {
    path {
      fill: ${props => props.theme.colors.interfaceGreen};
    }
  }
`;

const ButtonStyled = styled(Button)<{ 'data-should-style': boolean }>`
  &&&& {
    ${props =>
      props['data-should-style'] &&
      `
      margin-left: ${props.theme.pxToRem(10)};
      min-width: ${props.theme.pxToRem(180)};
      line-height: ${props.theme.pxToRem(24)};
      padding-left: ${props.theme.pxToRem(10)};
      padding-right: ${props.theme.pxToRem(10)};
    `};
  }
`;

const StyledSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledDefaultText = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  text-align: center;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-top: ${props => props.theme.pxToRem(20)};
  max-width: 100%;
`;

const StyledSuccessText = styled(StyledDefaultText)`
  margin-top: 0;
`;

const StyledMedium = styled.span`
  font-weight: ${props => props.theme.fontWeights.medium};
`;
