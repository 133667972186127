import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.individualConsent,
  (individualConsent: State.IndividualConsent): State.IndividualConsent => individualConsent
);

export const individualConsentSelector = rootSelector;
