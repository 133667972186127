import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleSavedItem, fetchSearchContentByIds } from 'modules/savedItems';
import { toggleSavedBlogPost } from 'modules/savedBlogPosts';
import { ADD_SAVED_ITEM, REMOVE_SAVED_ITEM, handleEvent } from 'utils/Analytics';
import { Content } from 'mxp-schemas';

interface Props {
  id: string;
  isSaved: boolean;
  title: string;
  description: string;
  slug: string;
  externalUrl: string;
  contentSource: string;
  isBlogFeed?: boolean;
}

export const useToggleSavedItem = ({
  id,
  isSaved,
  contentSource,
  title,
  description,
  slug,
  externalUrl,
  isBlogFeed = false,
}: Props) => {
  const dispatch = useDispatch();

  const handleClick = React.useCallback(
    async (event: React.SyntheticEvent<HTMLElement>): Promise<void> => {
      if (!id) return;
      event.preventDefault();
      event.stopPropagation();

      if (contentSource === Content.BlogContentSource && isBlogFeed) {
        dispatch(toggleSavedBlogPost(id, !isSaved, contentSource, isBlogFeed));
      } else {
        await dispatch(toggleSavedItem(id, !isSaved, contentSource, isBlogFeed));
        dispatch(fetchSearchContentByIds());
      }
      const url = document.location.href;
      handleEvent(
        { item: `${isSaved ? 'un' : ''}saved:${title}:${slug || externalUrl}:${id}`, description, url },
        isSaved ? REMOVE_SAVED_ITEM : ADD_SAVED_ITEM
      );
    },
    [dispatch, isSaved, id, description, externalUrl, slug, title, contentSource, isBlogFeed]
  );

  return handleClick;
};
