import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link, LinkEnums } from '../Link/Link';
import { MembershipPackageAccordionStatus, Routes } from 'constants/index';
import { getMembershipPackagePath, getPath, getCimaMembershipPackagePath } from 'utils';
import { Product } from 'mxp-schemas';
import { Admin as AdminUtils } from 'mxp-utils';
import { learningPathwaySelector, membershipsTermTypeSelector } from 'modules/user/selectors';
import { useSelector } from 'react-redux';
import IconClose from 'resources/images/ic_edit.svg';

interface Props {
  membershipApplicationType?: Product.MembershipApplicationType;
}

export const MembershipChangePackage: React.FC<Props> = ({ membershipApplicationType }) => {
  const learningPathway = useSelector(learningPathwaySelector);
  const membershipsTermType = useSelector(membershipsTermTypeSelector);

  const isSubscribedToFlp = AdminUtils.isFlpPathway(learningPathway as string);
  const isSubscribedToFlpAffiliate = AdminUtils.isFlpAffiliateType(learningPathway as string, membershipsTermType);
  const isSubscribedToFlpCandidate = AdminUtils.isFlpCandidateType(learningPathway as string, membershipsTermType);

  const goToNextPage = useMemo(() => {
    if (isSubscribedToFlp) {
      if (isSubscribedToFlpAffiliate) {
        return getPath(Routes.ROOT);
      }
      if (isSubscribedToFlpCandidate) {
        return getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Skill);
      }
      return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#level`;
    }
    if (membershipApplicationType === Product.MembershipApplicationType.CIMA) {
      return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#level`;
    }
    return getMembershipPackagePath(MembershipPackageAccordionStatus.Tier);
  }, [isSubscribedToFlp, isSubscribedToFlpAffiliate, isSubscribedToFlpCandidate, membershipApplicationType]);

  return (
    <StyledDiv>
      <Link underline={LinkEnums.underline.noUnderlineOnHover} to={goToNextPage}>
        <StyledIcon src={IconClose} alt="edit" />
        Adjust my membership
      </Link>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  margin-top: ${props => props.theme.pxToRem(25)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  font-size: ${props => props.theme.fontSizes.xs};
  text-align: center;
`;

const StyledIcon = styled.img`
  margin-right: ${props => props.theme.pxToRem(3)};
  position: relative;
  top: ${props => props.theme.pxToRem(3)};
  width: ${props => props.theme.pxToRem(15)};
  height: ${props => props.theme.pxToRem(15)}; // ie11 support
`;
