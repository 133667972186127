import React from 'react';
import styled from 'styled-components';

export enum ExternalAdBannerType {
  TOP = 'topAdBanner',
  BOTTOM = 'bottomAdBanner',
  SQUARE = 'squareAdBanner',
}

interface ExternalAdAttributes {
  id: string;
  size: Array<[number, number]>;
  keyValueTargeting?: { [key: string]: string };
}

export const ExternalAdBanners: { [key: string]: ExternalAdAttributes } = {
  [ExternalAdBannerType.TOP]: {
    id: 'top-ad-banner',
    size: [
      [300, 50],
      [728, 90],
    ],
    keyValueTargeting: {
      resp: 'r1',
    },
  },
  [ExternalAdBannerType.BOTTOM]: {
    id: 'bottom-ad-banner',
    size: [
      [300, 50],
      [728, 90],
    ],
    keyValueTargeting: {
      resp: 'r2',
    },
  },
  [ExternalAdBannerType.SQUARE]: { id: 'square-ad-banner', size: [[300, 250]] },
};

interface Props {
  type: ExternalAdBannerType;
}

export const ExternalAdBanner: React.FC<Props> = ({ type }) => {
  const isAdSquare = type === ExternalAdBannerType.SQUARE;
  return (
    <section key={`${ExternalAdBanners[type]?.id}-wrapper`}>
      <StyledWrapper isAdSquare={isAdSquare} id={`${ExternalAdBanners[type].id}-container`}>
        <StyledContainer isAdSquare={isAdSquare}>
          <StyledBannerLabel isAdSquare={isAdSquare}>Advertisement Feature</StyledBannerLabel>
          <StyledBanner isAdSquare={isAdSquare} id={ExternalAdBanners[type].id} />
        </StyledContainer>
      </StyledWrapper>
    </section>
  );
};

const StyledWrapper = styled.div<{ isAdSquare: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${props => (!props.isAdSquare ? 'center' : 'normal')};
  padding: ${props => props.theme.pxToRem(16)} 0;
`;

const StyledContainer = styled.div<{ isAdSquare: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${props => (!props.isAdSquare ? props.theme.pxToRem(728) : props.theme.pxToRem(300))};

  ${props => props.isAdSquare && `margin-left: ${props.theme.pxToRem(22.875)};`};

  @media only screen and (max-width: 751px) {
    ${props =>
      !props.isAdSquare &&
      `
        align-items: center;
    `}
  }
`;

const StyledBannerLabel = styled.div<{ isAdSquare: boolean }>`
  color: ${props => props.theme.colors.neutralGrey7};
  font-size: ${props => props.theme.fontSizes.xxs};
  text-transform: uppercase;
  margin-bottom: ${props => props.theme.pxToRem(8)};

  @media only screen and (max-width: 751px) {
    ${props =>
      !props.isAdSquare &&
      `
        width: ${props.theme.pxToRem(300)};
    `}
  }
`;

const StyledBanner = styled.div<{ isAdSquare: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => (!props.isAdSquare ? props.theme.pxToRem(90) : props.theme.pxToRem(250))};

  @media only screen and (max-width: 751px) {
    ${props => !props.isAdSquare && `height: ${props.theme.pxToRem(50)};`};
  }
`;
