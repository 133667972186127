export interface ErrorMessage {
  message: string;
}

export enum changePasswordErrorCodes {
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  RESET_PASS_PREVIOUSLY_USED = 'RESET_PASS_PREVIOUSLY_USED',
  RESET_PASS_CURRENT_USED = 'RESET_PASS_CURRENT_USED',
}

const cardErrorMessage: string =
  'Sorry, there has been a problem with your payment. Please contact your card issuer, or try a different payment method.';
const technicalErrorMessage: string = `Sorry, the order didn't go through. Please try again.`;
const wrongCardDetailsErrorMessage: string = `Sorry, the order didn't go through. Please review your payment information and try again.`;
// https://cgmastore.atlassian.net/wiki/spaces/NEX/pages/1874329645/Issue+with+Card+error+codes
export const paymentTechErrorCodes: { [key: string]: ErrorMessage } = {
  '01': {
    message: cardErrorMessage,
  },
  '04': {
    message: cardErrorMessage,
  },
  '05': {
    message: cardErrorMessage,
  },
  '06': {
    message: technicalErrorMessage,
  },
  '07': {
    message: technicalErrorMessage,
  },
  '09': {
    message: technicalErrorMessage,
  },
  '10': {
    message: cardErrorMessage,
  },
  '12': {
    message: cardErrorMessage,
  },
  '13': {
    message: technicalErrorMessage,
  },
  '14': {
    message: wrongCardDetailsErrorMessage,
  },
  '33': {
    message: cardErrorMessage,
  },
  '41': {
    message: cardErrorMessage,
  },
  '50': {
    message: cardErrorMessage,
  },
  '52': {
    message: cardErrorMessage,
  },
  '56': {
    message: cardErrorMessage,
  },
  '64': {
    message: wrongCardDetailsErrorMessage,
  },
  '65': {
    message: wrongCardDetailsErrorMessage,
  },
  '66': {
    message: cardErrorMessage,
  },
  '68': {
    message: cardErrorMessage,
  },
  '71': {
    message: wrongCardDetailsErrorMessage,
  },
  '72': {
    message: cardErrorMessage,
  },
  '74': {
    message: wrongCardDetailsErrorMessage,
  },
  '77': {
    message: technicalErrorMessage,
  },
  '79': {
    message: cardErrorMessage,
  },
  '89': {
    message: cardErrorMessage,
  },
  '98': {
    message: technicalErrorMessage,
  },
  '99': {
    message: cardErrorMessage,
  },
  B1: {
    message: cardErrorMessage,
  },
  B5: {
    message: wrongCardDetailsErrorMessage,
  },
  C3: {
    message: cardErrorMessage,
  },
  C4: {
    message: cardErrorMessage,
  },
  C5: {
    message: cardErrorMessage,
  },
  D7: {
    message: cardErrorMessage,
  },
  E4: {
    message: wrongCardDetailsErrorMessage,
  },
  F3: {
    message: cardErrorMessage,
  },
  H9: {
    message: wrongCardDetailsErrorMessage,
  },
  L2: {
    message: technicalErrorMessage,
  },
  L3: {
    message: wrongCardDetailsErrorMessage,
  },
  L4: {
    message: cardErrorMessage,
  },
  R5: {
    message: cardErrorMessage,
  },
  R6: {
    message: technicalErrorMessage,
  },
  R7: {
    message: cardErrorMessage,
  },
  R8: {
    message: cardErrorMessage,
  },
  BP: {
    message: cardErrorMessage,
  },
  BQ: {
    message: cardErrorMessage,
  },
  BR: {
    message: cardErrorMessage,
  },
  BS: {
    message: cardErrorMessage,
  },
  PB: {
    message: cardErrorMessage,
  },
  '45': {
    message: technicalErrorMessage,
  },
};

export const changePasswordError: { [key: string]: ErrorMessage } = {
  [changePasswordErrorCodes.INCORRECT_PASSWORD]: {
    message: 'Your current password is incorrect, please try again with a different password.',
  },
  [changePasswordErrorCodes.RESET_PASS_PREVIOUSLY_USED]: {
    message: 'This password matches one of your previous passwords. Please choose a different password.',
  },
  [changePasswordErrorCodes.RESET_PASS_CURRENT_USED]: {
    message: 'New password cannot be your current password. Please choose a different password.',
  },
};
