import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { MembershipTypes, Salesforce, User, PearsonVue as PvType } from 'mxp-schemas';
import { CimaNotificationBanner } from 'components/molecules';
import { useDispatch, useSelector } from 'react-redux';
import { pearsonVueSSOLinkConfigSelector } from 'modules/app/selectors';
import { openPersonVue } from 'modules/user/actions';
import { PearsonVue } from 'components/atoms';
import { examBookingDate } from 'utils/dateHelper';

const getDaysOrHoursRemaining = (appointmentDate: string, isDays: boolean = true) => {
  if (isDays) {
    const examDate = moment(appointmentDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const currentDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return examDate.diff(currentDate, 'days');
  }
  return moment(appointmentDate).diff(moment(), 'hours');
};

interface Props {
  studentProgression: State.StudentProgression | null;
  pathway: MembershipTypes.Pathway | null | undefined;
  gatewayStatus: User.GatewayDetails | null | undefined;
  scrollToNextStep?: () => void;
}

const defaultUpcomingBookedExams = [
  {
    title: '',
    main: 'Book your next exam',
    sub: `Once booked, you'll receive an email from Person VUE confirming the location, date and time.`,
  },
];

export const CimaDashboardUpcomingExamBooking: React.FC<Props> = ({
  studentProgression,
  pathway,
  gatewayStatus,
  scrollToNextStep,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>(defaultUpcomingBookedExams);
  const [showBadge, setShowBadge] = useState(false);
  const pearsonVueSSOLink: string = useSelector(pearsonVueSSOLinkConfigSelector);
  const [ssoPV, setSsoPv] = useState(false);
  const [htmlText, setHtmlText] = useState<string>('');

  const isL4Apprentice = pathway === MembershipTypes.Pathway.APPRENTICE_L4;
  const scheduleAndCancelPeriodHrs: number = 48;
  const isApprenticeshipPathway = Boolean(isL4Apprentice || pathway === MembershipTypes.Pathway.APPRENTICE_L7);

  const gatewayNotPassed = isApprenticeshipPathway
    ? Boolean(gatewayStatus?.status !== User.GatewayStatus.Passed)
    : false;

  const getCombinedAddress = (testCenterAddress: State.Address | null | undefined) => {
    if (!!testCenterAddress) {
      return `${testCenterAddress.addressLine1}${
        testCenterAddress.addressLine2 ? `, ${testCenterAddress.addressLine2}` : ''
      }, ${testCenterAddress.city}${testCenterAddress.state ? `, ${testCenterAddress.state}` : ''}, ${
        testCenterAddress.country
      }`;
    }
    return '';
  };

  const nextStepClick = () => {
    if (scrollToNextStep) scrollToNextStep();
  };

  const rescheduleClick = async (appointmentId: string) => {
    const { getPVSession, success } = await OpenPvDashboard(
      PvType.SSO_ACTIONS.RESCHEDULE_EXAM,
      appointmentId,
      dispatch
    );
    setSsoPv(success);
    setHtmlText(getPVSession);
  };

  const takeOnlineExamClick = async (appointmentId: string) => {
    const { getPVSession, success } = await OpenPvDashboard(
      PvType.SSO_ACTIONS.VIEW_APPOINTMENT_ACTIVITY_FOR_REGISTRATION_ID,
      appointmentId,
      dispatch
    );
    setSsoPv(success);
    setHtmlText(getPVSession);
  };

  const cancelClick = async (appointmentId: string) => {
    const { getPVSession, success } = await OpenPvDashboard(PvType.SSO_ACTIONS.CANCEL_EXAM, appointmentId, dispatch);
    setSsoPv(success);
    setHtmlText(getPVSession);
  };

  useEffect(() => {
    if (studentProgression?.upcomingExamBooking?.length) {
      setData(
        studentProgression?.upcomingExamBooking?.map((upcomingExam: User.SubjectBlock) => ({
          title: 'Upcoming Exam',
          main: upcomingExam.appointmentDetails?.examDateTime ? examBookingDate(upcomingExam.appointmentDetails) : '',
          exam: [
            {
              level: upcomingExam.qualificationlevel,
              subject: upcomingExam.name,
              online_exam: upcomingExam.appointmentDetails?.deliveryFormat === User.ExamDeliveryFormat.OnlineExam,
              address: getCombinedAddress(upcomingExam.appointmentDetails?.testCenterAddress),
              pvBookingId: upcomingExam.appointmentDetails?.pvBookingId,
            },
          ],
          remaining: `${getDaysOrHoursRemaining(upcomingExam.appointmentDetails?.examDateTime ?? '')} DAYS TO GO`,
          remainingInHours: parseInt(
            getDaysOrHoursRemaining(upcomingExam.appointmentDetails?.examDateTime ?? '', false).toString(),
            10
          ),
          rescheduleClick,
          cancelClick,
          takeOnlineExamClick,
        }))
      );
      return;
    }

    if (isApprenticeshipPathway) {
      const apprenticeshipL4 =
        studentProgression?.qualificationLevels[studentProgression?.qualificationLevels.length - 1];

      const EPA1 = isL4Apprentice
        ? apprenticeshipL4?.blocks.find(
            block => block.pathway === 'Apprentice_L4' && block.type === User.SubjectBlockType.CaseStudy
          )
        : studentProgression?.qualificationLevels
            .find(level => level.name === 'Strategic Level')
            ?.blocks.find(block => block.pathway === 'Apprentice_L7' && block.type === User.SubjectBlockType.CaseStudy);

      const EPA2 = apprenticeshipL4?.blocks.find(block => block.examRecordType === Salesforce.ExamRecordType.EPA2);

      const apprenticeCompletedStatus =
        EPA1?.status === User.SubjectBlockStatus.Completed && EPA2?.status === User.SubjectBlockStatus.Completed;

      const apprenticeRatifiedStatus =
        EPA1?.ratificationStatus === User.RatificationStatus.RATIFIED &&
        EPA2?.ratificationStatus === User.RatificationStatus.RATIFIED;

      const apprenticeEpaExamPassed = apprenticeCompletedStatus && apprenticeRatifiedStatus;

      if (apprenticeEpaExamPassed) {
        setData([
          {
            title: '',
            main: 'Congratulations on passing your apprenticeship!',
            sub: 'All your exams are complete! Now, sit back and relax while we apply for your certificate.',
            exam: [
              {
                completed: true,
              },
            ],
          },
        ]);
        setShowBadge(true);

        return;
      }

      // This setting is only applicable to L4
      if (
        isL4Apprentice &&
        ((EPA1?.resultStatus === User.ResultStatus.Complete &&
          EPA1?.ratificationStatus === User.RatificationStatus.RATIFIED) ||
          (EPA2?.resultStatus === User.ResultStatus.Complete &&
            EPA2?.ratificationStatus === User.RatificationStatus.RATIFIED))
      ) {
        // Used EPA2.completion date as a backup value
        const referenceCompletionDate = EPA1?.completionDate || EPA2?.completionDate;
        const dateData = moment(referenceCompletionDate?.split(' ')[0], 'MM/DD/YYYY');
        const startingPointDate = dateData.isValid()
          ? dateData.add(1, 'years').toISOString()
          : gatewayStatus?.epa2GraduationDate;

        const remainingDays = getDaysOrHoursRemaining(startingPointDate || '');
        // Less than 30 days signals there's only a few days left to accomplish EPA
        const main = remainingDays < 30 ? 'Only a few days left to complete your EPA' : `You're almost there!`;

        setData([
          {
            title: '',
            main,
            sub: `Be sure to complete your EPA1 and EPA2 before the deadline.`,
            remaining: `${remainingDays} DAYS TO GO`,
            remainingInHours: parseInt(
              getDaysOrHoursRemaining(gatewayStatus?.epa2GraduationDate ?? '', false).toString(),
              10
            ),
          },
        ]);

        return;
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const isScheduleAndCancelPeriod: boolean = useMemo(
    () => Boolean(data.find((item: any) => item?.remainingInHours <= scheduleAndCancelPeriodHrs)),
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <CimaNotificationBanner
        data={data}
        nextStepClick={nextStepClick}
        showBadge={showBadge}
        isGatewayNotPassed={gatewayNotPassed}
        showRescheduleAndCancel={isScheduleAndCancelPeriod}
      />
      {htmlText && <PearsonVue ssoLink={pearsonVueSSOLink} htmlText={htmlText} submit={ssoPV} />}
    </>
  );
};

const OpenPvDashboard = async (action: PvType.SSO_ACTIONS, registrationId: string, dispatch: any) => {
  const resp = await dispatch(openPersonVue(action, registrationId));
  const { getPVSession, success } = resp.payload;
  return { getPVSession, success };
};
