import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Print } from 'resources/images/ic-print.svg';
import { ReactComponent as Payment } from 'resources/images/ic-payment.svg';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export const PageAdminHomeInvoice = () => {
  const dispatch = useDispatch();
  const handlePaymentClick = React.useCallback(() => {
    dispatch(push(getPath(Routes.ADMIN_INVOICES_SEARCH)));
  }, [dispatch]);

  const handlePrintClick = React.useCallback(() => {
    dispatch(push(getPath(Routes.ADMIN_INVOICES_FILTER)));
  }, [dispatch]);

  return (
    <>
      <StyledText data-testid="invoice-intro">To help us get you started, what are you looking to do?</StyledText>

      <FlexWrap onClick={handlePaymentClick}>
        <StyledPayment />
        <Label>Pay invoice(s)</Label>
      </FlexWrap>
      <FlexWrap onClick={handlePrintClick}>
        <StyledPrint />
        <Label>Print or email invoice(s)</Label>
      </FlexWrap>
    </>
  );
};

const StyledText = styled.div`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(18)};
`;

const Label = styled.div`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-left: ${props => props.theme.pxToRem(8)};
`;

const FlexWrap = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(18)};

  cursor: pointer;
`;

const StyledPrint = styled(Print)`
  padding-left: ${props => props.theme.pxToRem(2)};
  & path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;
const StyledPayment = styled(Payment)`
  padding-bottom: ${props => props.theme.pxToRem(3)};
`;
