import React from 'react';
import styled from 'styled-components';
import { LabeledIcon, Link, OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import iconBeenHere from 'resources/images/ic-been-here.svg';
import iconHelp from 'resources/images/icon-dev-ic-help.svg';
import iconUpdate from 'resources/images/icon-dev-ic-update.svg';
import iconShipping from 'resources/images/ic-local-shipping.svg';
import iconPhone from 'resources/images/ic-call.svg';

interface Props {
  className?: string;
  hideCheckoutHelp?: boolean;
  isMembershipJourney?: boolean;
  viewTextForAnalytics?: string;
  isCimaMipJourney?: boolean;
  isOnCenterMembershipLandingPage?: boolean;
  isFcmaCredential?: boolean;
}

export const HelpSection: React.FC<Props> = React.memo(
  ({
    className,
    hideCheckoutHelp = false,
    viewTextForAnalytics,
    isMembershipJourney = false,
    isCimaMipJourney = false,
    isOnCenterMembershipLandingPage = false,
    isFcmaCredential = false,
  }) => {
    return (
      <div className={className}>
        {!isMembershipJourney && (
          <>
            <StyledLabeledIcon
              icon={<StyledHelpIcon src={isCimaMipJourney ? iconPhone : iconHelp} alt="icon help" />}
              label="Have a question?"
              mobileLabel="Have a question?"
            />
            {isCimaMipJourney ? (
              <>
                <OnlyDesktopCSS>
                  <StyledExtraInfo>
                    We’re open Monday through Friday, 9am–6pm ET. Contact us by selecting the chat icon to the right of
                    your screen. You can also call us at <StyledSpan>888.777.7077</StyledSpan> if you’re in the United
                    States or at
                    <StyledSpan>+1.919.402.4500</StyledSpan> if you’re anywhere else in the world.
                  </StyledExtraInfo>
                </OnlyDesktopCSS>

                <OnlyMobileCSS>
                  <StyledExtraInfo>
                    We’re open Monday through Friday, 9am–6pm ET. Contact us by selecting the chat icon to the right of
                    your screen. You can also call us a{' '}
                    <Link
                      isExternal
                      testId="ei-question-us-phone"
                      to="tel:888-777-7077"
                      viewTextForAnalytics={viewTextForAnalytics}
                    >
                      888.777.7077
                    </Link>{' '}
                    if you’re in the United States or at{' '}
                    <Link
                      isExternal
                      testId="ei-question-non-us-phone"
                      to="tel:+1-919-402-4500"
                      viewTextForAnalytics={viewTextForAnalytics}
                    >
                      +1.919.402.4500
                    </Link>{' '}
                    if you’re anywhere else in the world.
                  </StyledExtraInfo>
                </OnlyMobileCSS>
              </>
            ) : (
              <StyledExtraInfo>
                We’re open Monday through Friday, 9am–6pm ET. Contact us by selecting the chat icon to the right of your
                screen. You can also call us at{' '}
                <Link
                  isExternal
                  testId="ei-question-us-phone"
                  to="tel:888-777-7077"
                  viewTextForAnalytics={viewTextForAnalytics}
                >
                  888.777.7077
                </Link>{' '}
                if you’re in the United States or at{' '}
                <Link
                  isExternal
                  testId="ei-question-non-us-phone"
                  to="tel:+1-919-402-4500"
                  viewTextForAnalytics={viewTextForAnalytics}
                >
                  +1.919.402.4500
                </Link>{' '}
                if you’re anywhere else in the world.
              </StyledExtraInfo>
            )}
          </>
        )}
        {!isCimaMipJourney && (
          <>
            {(!hideCheckoutHelp || isMembershipJourney || isFcmaCredential) && (
              <>
                <StyledLabeledIcon
                  icon={<StyledIcon src={iconBeenHere} alt="icon checkmark" />}
                  label="Our guarantee"
                  mobileLabel="Our guarantee"
                />
                <StyledExtraInfo>
                  Every purchase you make from the AICPA & CIMA is safe and secure. We also guarantee 100% customer
                  satisfaction on most of our products. If you’re not satisfied with your purchase, please contact us.
                </StyledExtraInfo>
                {!isOnCenterMembershipLandingPage && (
                  <>
                    <StyledLabeledIcon
                      icon={<StyledIcon src={iconUpdate} alt="icon-update" />}
                      label="Our refund policy"
                      mobileLabel="Our refund policy"
                    />
                    <StyledExtraInfo>
                      To start the return process, please contact us first. View all products{' '}
                      <Link
                        testId="about-refund-policy"
                        to={getPath(Routes.REFUND_POLICY)}
                        target="_blank"
                        viewTextForAnalytics={viewTextForAnalytics}
                      >
                        eligible for refunds
                      </Link>
                      .
                    </StyledExtraInfo>
                  </>
                )}
              </>
            )}
            {!isMembershipJourney && (
              <>
                <StyledLabeledIcon
                  icon={<StyledIcon src={iconShipping} alt="icon shipping" />}
                  label="Shipping rates and policies"
                  mobileLabel="Shipping rates and policies"
                />
                <StyledExtraInfo>
                  View your options for{' '}
                  <Link
                    isExternal
                    testId="about-shipping"
                    to={getPath(Routes.SHIPPING_DELIVERY)}
                    viewTextForAnalytics={viewTextForAnalytics}
                  >
                    U.S and international deliveries.
                  </Link>
                </StyledExtraInfo>
              </>
            )}
          </>
        )}
      </div>
    );
  }
);

const StyledIcon = styled.img`
  margin-right: ${props => props.theme.pxToRem(8)};
  align-self: center;
  display: inline-flex;
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
`;

const StyledHelpIcon = styled.img`
  margin-right: ${props => props.theme.pxToRem(8)};
  align-self: center;
  display: inline-flex;
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
`;

const StyledLabeledIcon = styled(LabeledIcon)`
  &&&& {
    display: inline-flex;
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.xxs};
    font-weight: ${props => props.theme.fontWeights.medium};
    line-height: 1.5;
    letter-spacing: ${props => props.theme.pxToRem(0.22)};
  }
`;

const StyledExtraInfo = styled.p`
  margin-bottom: ${props => props.theme.pxToRem(16)};
  margin-left: ${props => props.theme.pxToRem(24)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
`;

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.primaryPurple};
  text-decoration: underline;
`;
