import React, { useEffect, useState, useRef, useMemo } from 'react';
import moment from 'moment-timezone';
import styled, { css } from 'styled-components';
import {
  Button,
  ButtonVariants,
  MembershipStepperNotificationHeader,
  NotificationBanner,
  NotificationBannerEnums,
  FeedPlaceholder,
  ButtonEnums,
  Grid,
  ButtonLink,
} from 'components/atoms';
import { Divider, StrictDropdownProps, DropdownProps, DropdownItemProps, Loader } from 'semantic-ui-react';
import { getApplicationProgressRoute, Routes } from 'constants/index';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { Salesforce, Checkout, SmartyStreets, MembershipTypes, Product, User } from 'mxp-schemas';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { initialState } from 'modules/user/constants';
import { Scroller, getPath } from 'utils';
import { Admin as AdminUtils, Utils, CheckoutCountriesListHash } from 'mxp-utils';
import { ApplicationEmploymentSubForm } from './ApplicationEmploymentSubForm';
import { Link } from 'components/atoms/Link/Link';
import { ModalAddressValidation } from 'components/organisms';
import { EmploymentForm } from './EmploymentForm';
import { useSelector } from 'react-redux';
import { isPaperBillingSelector } from 'modules/startup/selectors';
import { userMembershipTypeSelector } from 'modules/membership/selectors';
import {
  userAicpaUidSelector,
  tlwSelector,
  primaryEmployerSelector,
  employersWithEmployeeRelationshipSelector,
} from 'modules/user/selectors';
import { generatePath } from 'react-router-dom';
import * as Style from 'components/pages/PageProfile/Employment/MyEmploymentDetails.style';
import MyProfileDefaultModal from 'components/pages/PageProfile/Employment/MyProfileDefaultModal';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_DISABLE_PA_CREATION } from 'modules/featureToggle/constants';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';

interface DropdownOption {
  key: number;
  text: string;
  value: string;
  sfOrgId?: string;
}

const optionsBusinesses: DropdownOption[] = [];
const optionsBusinessCities: DropdownOption[] = [];

interface Props {
  employers: Array<Omit<State.AccountPersonAccount, 'personAccount'>>;
  customerProfileFetched: boolean;
  customerProfileFetchSuccess: boolean;
  employmentDataUpdateLoading: boolean;
  employerAlreadyExists: boolean;
  selectedMembershipType: string;
  searchResultOrganizations: any;
  searchOrganizationsByWildcardLoading: boolean;
  searchOrganizationsCitiesLoading: boolean;
  searchResultOrganizationsCities: string[] | null;
  newEmployer: State.NewAccountPersonAccount;
  employmentDataFromPromoFetched: boolean;
  employmentDataFromPromoFetchSuccess: boolean;
  organizationPromoCode?: string;
  application: Salesforce.Application;
  isOrganizationAddressValid?: boolean;
  organizationSSValidationReason: string | null;
  organizationSSSuggestions: SmartyStreets.ValidationCandidate[];
  organizationSSValidationFetched: boolean;
  isAddressValidationModalOpen: boolean;
  organizationIsSecondaryAddressNeeded: boolean;
  organizationSmartystreetsValidationSkipped: boolean;
  isOrganizationAddressValidationLoading: boolean;
  isCenterMembershipJourney?: boolean;
  isCimaMembershipJourney?: boolean;
  isMembershipJourney?: boolean;
  isAcknowledgementConfirmed?: boolean;
  isUnderReview?: boolean;
  isForModal?: boolean;
  inviteData?: State.InviteData;
  searchOrganizationsByWildcard: (businessName: string, businessZipCode?: string, zoomInfoSearch?: boolean) => void;
  searchOrganizationsCities: (accountName: string, wildcardString: string, zoomInfoSearch?: boolean) => void;
  getOrganizationByNameAndCity: (accountName: string, cityName: string) => void;
  checkIfOrganizationExists: (accountName: string) => Promise<boolean>;
  updateEmploymentData: (
    employmentData: State.NewAccountPersonAccount,
    application: State.Application,
    membershipApplicationType: Product.MembershipApplicationType,
    createNew?: boolean,
    hasExistingEmployers?: boolean,
    isCenterMembershipJourney?: boolean,
    isForModal?: boolean,
    isPaperBilling?: boolean,
    shouldRedirectToCredentialPage?: boolean
  ) => void;
  clearNewEmployerData: () => void;
  getOrganizationDataFromPromo: (accountNumber: string) => void;
  toggleModalAddressValidation: (toggle: boolean) => void;
  validateAddress: (address: Checkout.Address) => Promise<void>;
  clearSmartyStreetsSecondaryAddressValidation: () => void;
  setSuggestedAddress: (address: Partial<State.Address>) => Promise<void>;
  toggleCreateOrganization?: () => void;
  togglePartnerDeclareAcknowledgement?: (company: string, isBusinessNew: boolean | null) => void;
  acknowledgementConfirmed?: () => void;
  setIsUnderReview?: (isUnderReview: boolean) => void;
  handleModalClose: () => void;
  handleSelectedEmployment?: (value: any) => void;
  membershipApplicationType: Product.MembershipApplicationType;
  processTLW?: (tlwInfo: {
    id?: string;
    aicpaUid: string;
    tlwReason: string;
    tlwEndDate: string;
    employmentStatus: string;
  }) => void;
  setNewEmployerJobTitle?: (jobTitle: string) => void;
  getMyProfile?: () => Promise<void>;
  setIsOpenEmploymentModalFromInvite: (isOpen: boolean) => Promise<void>;
  isUserMember: boolean;
  cartCredentials: State.LineItem[];
  useNewMembershipAICPA?: boolean;
}

export const ApplicationEmploymentForm: React.FC<Props> = ({
  employers,
  customerProfileFetched,
  customerProfileFetchSuccess,
  employmentDataUpdateLoading,
  employerAlreadyExists,
  selectedMembershipType,
  searchResultOrganizations,
  searchOrganizationsByWildcardLoading,
  searchResultOrganizationsCities,
  searchOrganizationsCitiesLoading,
  newEmployer,
  employmentDataFromPromoFetched,
  employmentDataFromPromoFetchSuccess,
  organizationPromoCode,
  application,
  isOrganizationAddressValid,
  organizationSSValidationReason,
  organizationSSSuggestions,
  organizationSSValidationFetched,
  isAddressValidationModalOpen,
  organizationIsSecondaryAddressNeeded,
  organizationSmartystreetsValidationSkipped,
  isOrganizationAddressValidationLoading,
  isCenterMembershipJourney,
  isCimaMembershipJourney,
  isMembershipJourney,
  isAcknowledgementConfirmed,
  isUnderReview,
  isForModal = false,
  handleModalClose,
  handleSelectedEmployment,
  inviteData,
  searchOrganizationsByWildcard,
  searchOrganizationsCities,
  getOrganizationByNameAndCity,
  checkIfOrganizationExists,
  updateEmploymentData,
  clearNewEmployerData,
  getOrganizationDataFromPromo,
  toggleModalAddressValidation,
  validateAddress,
  clearSmartyStreetsSecondaryAddressValidation,
  setSuggestedAddress,
  toggleCreateOrganization,
  togglePartnerDeclareAcknowledgement,
  acknowledgementConfirmed,
  setIsUnderReview,
  membershipApplicationType,
  processTLW,
  setNewEmployerJobTitle,
  getMyProfile,
  setIsOpenEmploymentModalFromInvite,
  isUserMember,
  cartCredentials,
  useNewMembershipAICPA,
}) => {
  const isPaperBilling = useSelector(isPaperBillingSelector);
  const userMembershipTypeChoice = useSelector(userMembershipTypeSelector) as string;
  const currentUserAICPAUID = useSelector(userAicpaUidSelector);
  const currentUserTLW = useSelector(tlwSelector);
  const currentUserPrimaryEmployer = useSelector(primaryEmployerSelector);
  const employersWithEmployeeRelationship = useSelector(employersWithEmployeeRelationshipSelector);
  const useDisablePA = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_DISABLE_PA_CREATION)
  );

  const [isAddressDetailsEditable, setIsAddressDetailsEditable] = useState<boolean>(false);
  const [isOrganizationValid, setIsOrganizationValid] = useState<boolean>(false);
  const [employmentForm, setEmploymentForm] = useState(initialState.employmentData.newEmployer);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [isBusinessNew, setIsBusinessNew] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [businesses, setBusinesses] = useState<DropdownOption[]>(optionsBusinesses);
  const [businessCities, setBusinessCities] = useState<DropdownOption[]>(optionsBusinessCities);
  const [warningMessage, setWarningMessage] = useState('');
  const [searchTempStorage, setSearchTempStorage] = useState({
    businessName: '',
    businessCity: '',
    businessZipCode: '',
  });
  const [stateBusinessList, setStateBusinessList] = useState({
    value: null,
    searchQuery: '',
    filteredList: businesses,
  });
  const [hasPromoCode, setHasPromoCode] = useState(false);
  const [existingEmployerFromSearch, setExistingEmployerFromSearch] = useState(false);
  const [isShippingAddress, setIsShippingAddress] = useState(true);
  const [hasAddressValidError, setHasAddressValidError] = useState(false);
  const [canEditEmploymentInfo, setEditEmploymentInfo] = useState(false);
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState<any>('');
  const [tlwData, setTlwData] = useState(currentUserTLW || initialState.tlw);
  // TODO: Error due to status not being saved in employer field. Implement that and change default state to false.
  const [isEmploymentFormValid, setIsEmploymentFormValid] = useState(false);
  const [isSearchingExternal, setIsSearchingExternal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [employerSelectionUseCase, setEmployerSelectionUseCase] = useState<User.EmployerSelectionUseCaseEnum>(
    User.EmployerSelectionUseCaseEnum.SEARCH_TO_CONNECTED_EMPLOYERS
  );
  const [tempStorageSearchResultOrganization, setTempStorageSearchResultOrganization] = useState<any>([]);
  const [isPeerReviewErrorModalOpen, setIsPeerReviewErrorModalOpen] = useState(false);

  const [addressFormErrors, setAddressFormErrors] = useState({});
  const [isClose, setIsClose] = useState({ businessName: false, role: false, workPhoneNumber: false });

  const businessNameElementId = 'businessName';
  const businessCityElementId = 'businessCity';
  const refNewBusiness: any = useRef(null);
  const refDropdownBusinessName: any = React.createRef();

  const hasExistingEmployers = isCenterMembershipJourney ? false : Boolean(employers?.length);
  const { employmentRole: role } = application;
  const business = employmentForm.organization;

  const isPaidByFirm = inviteData && Boolean(inviteData?.inviteId) && inviteData.isPaidByFirm;
  const businessName = hasExistingEmployers
    ? employers[0].organization.name
    : isPaidByFirm && inviteData
    ? inviteData.organization.name
    : business.name;
  const businessType = hasExistingEmployers
    ? employers[0].organization.type
    : isPaidByFirm && inviteData
    ? inviteData.organization.type
    : business.type;
  const address = business.billingAddress || initialState.employmentData.newEmployer.organization.billingAddress;
  const phone = business.phone || initialState.employmentData.newEmployer.organization.phone!;

  const businessAddressLine1 =
    isPaidByFirm && inviteData ? inviteData.organization.billingAddress.addressLine1 : address?.addressLine1;
  const businessAddressLine2 =
    isPaidByFirm && inviteData ? inviteData.organization.billingAddress.addressLine2 : address?.addressLine2;

  const isAddressLine2Empty = !Boolean(businessAddressLine2);
  const isAddressLine2IsValid = organizationIsSecondaryAddressNeeded ? !isAddressLine2Empty : true;
  const addressLine2ErrorMessage = 'Please enter a suite or apartment number';

  const isBusinessNameValid = searchTempStorage.businessName.length > 2;

  const businessCity = isPaidByFirm && inviteData ? inviteData.organization.billingAddress.city : address?.city;
  const businessState = isPaidByFirm && inviteData ? inviteData.organization.billingAddress.state : address?.state;
  const businessPostalCode =
    isPaidByFirm && inviteData ? inviteData.organization.billingAddress.zipCode : address?.zipCode;
  const phoneNumber = isPaidByFirm && inviteData ? inviteData.organization.phone ?? '' : phone;

  const businessNameDOM = document.getElementById(businessNameElementId);
  const businessCityDOM = document.getElementById(businessCityElementId);

  const shippingAddress = business.shippingAddress;

  const smartyStreetsAddress: Checkout.Address = {
    addressLine1: businessAddressLine1,
    addressLine2: businessAddressLine2,
    city: businessCity,
    state: businessState,
    zipCode: businessPostalCode,
    country: address?.country,
  };

  const hasSmartyStreetsInvalidReason = organizationSSValidationReason === SmartyStreets.ValidationReason.INVALID;

  const isUSBillingAddress: boolean = Boolean(
    employmentForm.organization.billingAddress?.country === CheckoutCountriesListHash.USA.ISOAlpha3Code
  );
  const isPAOrg: boolean = Boolean(
    employmentForm.organization.type === MembershipTypes.OrganizationType.PUBLIC_ACCOUNTING
  );

  // Employment Form
  const showEmploymentForm = Boolean(
    customerProfileFetched &&
      customerProfileFetchSuccess &&
      (isForModal || selectedMembershipType || isCenterMembershipJourney)
  );
  const isPromoCodeAvailable = Boolean(hasPromoCode && organizationPromoCode);

  // If this component is used outside Application steps, we check the store for membership journey type as fallback
  const membershipJourneyApplicationType =
    membershipApplicationType ||
    (isMembershipJourney
      ? Product.MembershipApplicationType.AICPA
      : isCimaMembershipJourney
      ? Product.MembershipApplicationType.CIMA
      : Product.MembershipApplicationType.AICPA_CENTER_MEMBERSHIP);

  const renderTLWReasonSelection = Boolean(employmentForm.status === MembershipTypes.EmploymentStatus[1].value);
  const renderRoleSelection = Boolean(employmentForm.status === MembershipTypes.EmploymentStatus[0].value);
  const renderEmploymentDetails = hasExistingEmployers;

  const shouldRedirectToCredentialPage = useMemo(
    () => !!cartCredentials?.length && !isUserMember,
    [cartCredentials, isUserMember]
  );

  const { pageNameNoHash } = getPageNameAndSiteSection();
  useEffect(() => {
    if (isForModal && Boolean(inviteData) && employersWithEmployeeRelationship.length > 0) {
      const inviteUserEmployer = employersWithEmployeeRelationship.find(
        data => data.organization.id === inviteData?.organizationId
      );
      if (inviteUserEmployer) {
        setEmploymentForm({
          ...initialState.employmentData.newEmployer,
          id: inviteUserEmployer?.id,
          relationshipType: inviteUserEmployer.relationshipType,
          jobTitle: inviteUserEmployer?.jobTitle || '',
          organization: inviteUserEmployer.organization,
          status: MembershipTypes.EmploymentStatus[0].value,
        });
      }
    }
  }, [isForModal, inviteData, employersWithEmployeeRelationship]);

  useEffect(() => {
    // Re-invoke getMyProfile to repopulate employers
    if (getMyProfile && !isForModal) {
      setIsLoading(true);
      const reinvokeGetMyProfile = async () => {
        await getMyProfile();
      };
      reinvokeGetMyProfile().then(() => {
        setIsLoading(false);
      });
    }
  }, [getMyProfile, setIsLoading, isForModal]);

  useEffect(() => {
    if (isForModal) {
      const isTLW = renderTLWReasonSelection && tlwData.tlwReason;
      const isEmployerAddressValid =
        employmentForm.organization.billingAddress?.addressLine1 &&
        employmentForm.organization.billingAddress?.city &&
        employmentForm.organization.billingAddress?.state &&
        (employmentForm.organization.billingAddress?.zipCode || !isUSBillingAddress);
      const isSelectedConnectedEmployer =
        renderRoleSelection &&
        employerSelectionUseCase === User.EmployerSelectionUseCaseEnum.SEARCH_TO_CONNECTED_EMPLOYERS &&
        employmentForm.id &&
        employmentForm.jobTitle &&
        isEmployerAddressValid;
      const isSelectedFromSFEmployer =
        renderRoleSelection &&
        employerSelectionUseCase === User.EmployerSelectionUseCaseEnum.SEARCH_TO_SALESFORCE_ZOOMINFO &&
        employmentForm.id &&
        employmentForm.jobTitle &&
        isEmployerAddressValid;
      const isSelectedFromZIEmployer =
        renderRoleSelection &&
        employerSelectionUseCase === User.EmployerSelectionUseCaseEnum.SEARCH_TO_SALESFORCE_ZOOMINFO &&
        !employmentForm.id &&
        employmentForm.organization.name &&
        employmentForm.jobTitle &&
        isEmployerAddressValid;
      const isEmployerCreatedManually =
        renderRoleSelection &&
        employerSelectionUseCase === User.EmployerSelectionUseCaseEnum.CREATE_MANUALLY &&
        employmentForm.organization.name &&
        employmentForm.jobTitle &&
        isEmployerAddressValid;
      if (
        employmentForm.status &&
        (isTLW ||
          isSelectedConnectedEmployer ||
          isSelectedFromSFEmployer ||
          isSelectedFromZIEmployer ||
          isEmployerCreatedManually)
      ) {
        setIsEmploymentFormValid(true);
      } else {
        setIsEmploymentFormValid(false);
      }
    }
    if (!isForModal) {
      if (
        currentUserPrimaryEmployer ||
        (employmentForm.organization.name && employmentForm.jobTitle) ||
        (businessName && employmentForm.jobTitle)
      ) {
        setIsEmploymentFormValid(true);
      } else {
        setIsEmploymentFormValid(false);
      }
    }
  }, [
    employmentForm,
    employerSelectionUseCase,
    renderTLWReasonSelection,
    renderRoleSelection,
    tlwData,
    isForModal,
    currentUserPrimaryEmployer,
    businessName,
    isUSBillingAddress,
  ]);

  useEffect(() => {
    if (employmentForm.organization.type && isCenterMembershipJourney) {
      const orgType = employmentForm.organization.type;
      const isOrgInvalid = Boolean(
        isCenterMembershipJourney &&
          (AdminUtils.allInvalidOrgs(orgType) ||
            (AdminUtils.isPCPSType(userMembershipTypeChoice) && AdminUtils.pcpsInvalidOrgs(orgType)) ||
            (AdminUtils.similarGroupValidOrgs(userMembershipTypeChoice) &&
              (AdminUtils.pcpsInvalidOrgs(orgType) || orgType === MembershipTypes.OrganizationType.CONSULTING_FIRM)))
      );
      if (isOrgInvalid) {
        setIsOrganizationValid(false);
      } else {
        setIsOrganizationValid(true);
      }
    } else {
      setIsOrganizationValid(false);
    }
  }, [employmentForm.organization.type, userMembershipTypeChoice, isCenterMembershipJourney, setIsOrganizationValid]);

  useEffect(() => {
    if (isAcknowledgementConfirmed && acknowledgementConfirmed && setIsUnderReview) {
      handleAcknowledgementConfirm();
      acknowledgementConfirmed();
    }
  }, [isAcknowledgementConfirmed, employmentForm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!hasExistingEmployers && organizationPromoCode) {
      getOrganizationDataFromPromo(organizationPromoCode);
    }
  }, [organizationPromoCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (employmentDataFromPromoFetched) {
      setHasPromoCode(Boolean(newEmployer.organization));
    }
  }, [employmentDataFromPromoFetched]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isBusinessNew && refNewBusiness?.current) {
      refNewBusiness.current.focus();
    }
  }, [isBusinessNew, refNewBusiness]);

  useEffect(() => {
    if (customerProfileFetched && !customerProfileFetchSuccess) {
      setHasErrorMessage(true);
    }
  }, [customerProfileFetched, customerProfileFetchSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!employmentDataUpdateLoading && employerAlreadyExists) {
      setWarningMessage(`"${searchTempStorage.businessName}" already exists. Please do a search instead.`);
      Scroller.scrollToTop();
    }
  }, [employmentDataUpdateLoading, employerAlreadyExists, searchOrganizationsByWildcardLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchResultOrganizations) {
      if (searchResultOrganizations?.length) {
        const items: DropdownOption[] = searchResultOrganizations?.map((organization: any, index: number) => {
          const city = organization.billingAddress?.city || organization.shippingAddress?.city;
          return {
            value: organization.name,
            key: index,
            text: `${organization.name}${city ? ` (${city})` : ''}`,
            sfOrgId: organization?.id,
          };
        });
        if (isSearchingExternal) {
          const combinedTempStorageSearchResultOrganization = [
            ...tempStorageSearchResultOrganization,
            ...searchResultOrganizations,
          ];
          const filteredAsUniqueTempStorageSearchResultOrganization =
            combinedTempStorageSearchResultOrganization.filter(
              (v, i, a) => a.findIndex(t => t.id === v.id || t.name === v.name) === i
            );
          setTempStorageSearchResultOrganization(filteredAsUniqueTempStorageSearchResultOrganization);
          setBusinesses([...businesses, ...items]);
          setStateBusinessList({
            ...stateBusinessList,
            filteredList: [...stateBusinessList.filteredList, ...items],
          });
        } else {
          setTempStorageSearchResultOrganization(searchResultOrganizations);
          setBusinesses(items);
          setStateBusinessList({
            ...stateBusinessList,
            filteredList: items,
          });
        }
        businessNameDOM?.focus();
      } else {
        if (searchTempStorage.businessName) {
          setWarningMessage(
            `No results were found for "${searchTempStorage.businessName}". ${
              !isSearchingExternal ? 'Try Search further' : ''
            }`
          );
        }
      }
    }
  }, [searchResultOrganizations]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchResultOrganizationsCities) {
      if (searchResultOrganizationsCities?.length) {
        const items: DropdownOption[] = searchResultOrganizationsCities?.map((city: string, index: number) => {
          return { value: city, key: index, text: city };
        });
        setBusinessCities(items);
        businessCityDOM?.focus();
      } else {
        if (searchTempStorage.businessCity) {
          setWarningMessage(`No results were found for "${searchTempStorage.businessCity}"`);
        }
      }
    }
  }, [searchResultOrganizationsCities]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (newEmployer?.organization?.id) {
      setEmploymentForm({
        ...newEmployer,
        ...(role && {
          relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
          jobTitle: role,
        }),
        ...(isForModal && {
          status: MembershipTypes.EmploymentStatus[0].value,
          relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
        }),
      });
    }
  }, [newEmployer, role, isForModal]);

  useEffect(() => {
    if (role) {
      setEmploymentForm({
        ...employmentForm,
        relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
        jobTitle: role,
      });
    }
  }, [role]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shippingAddress?.addressLine1) {
      setIsShippingAddress(true);
    }
  }, [shippingAddress?.addressLine1]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      organizationSSValidationFetched &&
      hasSmartyStreetsInvalidReason &&
      !isOrganizationAddressValidationLoading &&
      !isOrganizationAddressValid
    ) {
      setHasAddressValidError(true);
      setAddressFormErrors({
        line1: true,
        city: true,
        state: true,
        zipCode: true,
      });
      setHasErrorMessage(true);
      Scroller.scrollToTop();
    } else if ((isOrganizationAddressValid || hasExistingEmployers || isPaidByFirm) && canProceed && !isForModal) {
      // Will get the suggested address from smarty street
      const returnedAddressSuggestion = organizationSSSuggestions[0]?.fullSuggestion;
      if (isCenterMembershipJourney) {
        // Will update address base on the smarty street suggestion
        updateEmploymentData(
          {
            ...employmentForm,
            organization: {
              ...business,
              billingAddress: {
                addressLine1:
                  returnedAddressSuggestion?.street || employmentForm.organization?.billingAddress?.addressLine1 || '',
                addressLine2:
                  returnedAddressSuggestion?.streetSec ||
                  employmentForm.organization?.billingAddress?.addressLine2 ||
                  '',
                city: returnedAddressSuggestion?.city || employmentForm.organization?.billingAddress?.city || '',
                county: returnedAddressSuggestion?.county || employmentForm.organization?.billingAddress?.county || '',
                zipCode:
                  returnedAddressSuggestion?.zipcode || employmentForm.organization?.billingAddress?.zipCode || '',
                state: returnedAddressSuggestion?.state || employmentForm.organization?.billingAddress?.state || '',
                country:
                  returnedAddressSuggestion?.country || employmentForm.organization?.billingAddress?.country || 'USA',
              },
              ...(isShippingAddress && {
                shippingAddress: {
                  addressLine1:
                    returnedAddressSuggestion?.street ||
                    employmentForm.organization?.billingAddress?.addressLine1 ||
                    '',
                  addressLine2:
                    returnedAddressSuggestion?.streetSec ||
                    employmentForm.organization?.billingAddress?.addressLine2 ||
                    '',
                  city: returnedAddressSuggestion?.city || employmentForm.organization?.billingAddress?.city || '',
                  county:
                    returnedAddressSuggestion?.county || employmentForm.organization?.billingAddress?.county || '',
                  zipCode:
                    returnedAddressSuggestion?.zipcode || employmentForm.organization?.billingAddress?.zipCode || '',
                  state: returnedAddressSuggestion?.state || employmentForm.organization?.billingAddress?.state || '',
                  country:
                    returnedAddressSuggestion?.country || employmentForm.organization?.billingAddress?.country || 'USA',
                },
              }),
            },
            status: `${Salesforce.AccountPersonAccountStatuses.ACTIVE}`,
          },
          application,
          membershipJourneyApplicationType,
          Boolean(!employmentForm.organization.id),
          hasExistingEmployers,
          isCenterMembershipJourney
        );
        if (toggleCreateOrganization) {
          toggleCreateOrganization();
        }
      } else {
        updateEmploymentData(
          {
            ...employmentForm,
            organization: {
              ...business,
              billingAddress: {
                addressLine1:
                  returnedAddressSuggestion?.street || employmentForm.organization?.billingAddress?.addressLine1 || '',
                addressLine2:
                  returnedAddressSuggestion?.streetSec ||
                  employmentForm.organization?.billingAddress?.addressLine2 ||
                  '',
                city: returnedAddressSuggestion?.city || employmentForm.organization?.billingAddress?.city || '',
                county: returnedAddressSuggestion?.county || employmentForm.organization?.billingAddress?.county || '',
                zipCode:
                  returnedAddressSuggestion?.zipcode || employmentForm.organization?.billingAddress?.zipCode || '',
                state: returnedAddressSuggestion?.state || employmentForm.organization?.billingAddress?.state || '',
                country:
                  returnedAddressSuggestion?.country || employmentForm.organization?.billingAddress?.country || 'USA',
              },
              ...(isShippingAddress && {
                shippingAddress: {
                  addressLine1:
                    returnedAddressSuggestion?.street ||
                    employmentForm.organization?.billingAddress?.addressLine1 ||
                    '',
                  addressLine2:
                    returnedAddressSuggestion?.streetSec ||
                    employmentForm.organization?.billingAddress?.addressLine2 ||
                    '',
                  city: returnedAddressSuggestion?.city || employmentForm.organization?.billingAddress?.city || '',
                  county:
                    returnedAddressSuggestion?.county || employmentForm.organization?.billingAddress?.county || '',
                  zipCode:
                    returnedAddressSuggestion?.zipcode || employmentForm.organization?.billingAddress?.zipCode || '',
                  state: returnedAddressSuggestion?.state || employmentForm.organization?.billingAddress?.state || '',
                  country:
                    returnedAddressSuggestion?.country || employmentForm.organization?.billingAddress?.country || 'USA',
                },
              }),
            },
            ...(Boolean(!isPaidByFirm) && { isPrimary: true }),
          },
          application,
          membershipJourneyApplicationType,
          isBusinessNew,
          hasExistingEmployers,
          undefined,
          undefined,
          isPaperBilling,
          shouldRedirectToCredentialPage
        );
      }
    }
    // eslint-disable-next-line
  }, [
    organizationSSValidationFetched,
    isOrganizationAddressValidationLoading,
    shouldRedirectToCredentialPage,
    canProceed,
  ]);

  // Pre-fill the employmentForm with Primary Employer if there is existing
  useEffect(() => {
    if (currentUserPrimaryEmployer && !isCenterMembershipJourney && !inviteData?.inviteId) {
      const { id, relationshipType, jobTitle, organization, isPrimary } = currentUserPrimaryEmployer;
      const {
        id: orgId,
        name,
        type,
        creditHoldStatus,
        hasAICPAUid,
        AICPAUid,
        isFirmBillingEnabled,
        billingAddress,
        shippingAddress: orgShippingAddress,
        phone: orgPhone,
        profileEmployerAddress,
        profileEmployerPhone,
        email,
      } = organization;
      setEmploymentForm({
        ...initialState.employmentData.newEmployer,
        id,
        relationshipType,
        jobTitle,
        isPrimary,
        organization: {
          id: orgId,
          name,
          type,
          creditHoldStatus,
          hasAICPAUid,
          AICPAUid,
          isFirmBillingEnabled,
          billingAddress,
          shippingAddress: orgShippingAddress,
          phone: orgPhone,
          profileEmployerAddress,
          profileEmployerPhone,
          email,
        },
        status: MembershipTypes.EmploymentStatus[0].value,
      });
    }
  }, [currentUserPrimaryEmployer, setEmploymentForm, isCenterMembershipJourney, inviteData]);

  // Pre-fill shipping address when the SetShippingAddress checkbox is ticked
  useEffect(() => {
    if (!inviteData?.inviteId) {
      setEmploymentForm({
        ...employmentForm,
        organization: {
          ...business,
          shippingAddress: isShippingAddress
            ? address
            : initialState.employmentData.newEmployer.organization?.shippingAddress,
        },
      });
    }
  }, [isShippingAddress, address]); // eslint-disable-line react-hooks/exhaustive-deps

  const closePeerReviewErrorModal = (event: React.SyntheticEvent<HTMLElement | SVGSVGElement>) => {
    event?.preventDefault();
    setIsPeerReviewErrorModalOpen(false);
  };

  const togglePeerReviewErrorModal = (event?: React.SyntheticEvent<HTMLElement | SVGSVGElement>) => {
    event?.preventDefault();
    setIsPeerReviewErrorModalOpen(!isPeerReviewErrorModalOpen);
  };

  const handleSearchSFBusinessNameWithZipCode = React.useCallback(() => {
    if (searchTempStorage.businessName.length > 2) {
      setIsSearchingExternal(false);
      searchOrganizationsByWildcard(searchTempStorage.businessName, searchTempStorage.businessZipCode);
      setWarningMessage('');
    } else {
      setWarningMessage('Enter three or more letters of the business’s name.');
    }
  }, [searchTempStorage, searchOrganizationsByWildcard, setWarningMessage]);

  const handleSearchZIBusinessNameWithZipCode = React.useCallback(() => {
    if (searchTempStorage.businessName.length > 2) {
      setIsSearchingExternal(true);
      searchOrganizationsByWildcard(searchTempStorage.businessName, searchTempStorage.businessZipCode, true);
      setWarningMessage('');
    } else {
      setWarningMessage('Enter three or more letters of the business’s name.');
    }
  }, [searchTempStorage, searchOrganizationsByWildcard, setWarningMessage]);

  const searchFn = (event: React.ChangeEvent<HTMLInputElement>, searchQuery: string) => {
    const filteredList = businesses.filter(item => !searchQuery || item.value.includes(searchQuery));
    return filteredList;
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>, { searchQuery }: { searchQuery: string }) => {
    setWarningMessage('');
    const filteredList = searchFn(event, searchQuery);
    setStateBusinessList({ ...stateBusinessList, searchQuery, filteredList });
    setSearchTempStorage({
      ...searchTempStorage,
      businessName: event.target.value,
    });
  };

  const handleSearchBusinessCity = React.useCallback(
    (orgId?: string) => {
      if (isSearchingExternal && orgId) {
        searchOrganizationsCities(`${orgId}`, searchTempStorage.businessCity, true);
      } else {
        if (searchTempStorage.businessCity.length > 2) {
          searchOrganizationsCities(businessName as string, searchTempStorage.businessCity);
        } else {
          setWarningMessage('Business city should be at least three characters.');
        }
      }
    },
    [searchTempStorage, businessName, searchOrganizationsCities, setWarningMessage, isSearchingExternal]
  );

  const handleBusinessChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, data: DropdownProps) => {
      const org = searchResultOrganizations?.find(
        (organization: any) => organization.name === data.value && organization.type !== null
      );
      const type = org?.type || '';
      setEmploymentForm({
        ...employmentForm,
        organization: {
          ...business,
          id: org?.id || '',
          name: data.value as string,
          type,
          billingAddress: org?.billingAddress,
          shippingAddress: org?.shippingAddress,
          phone: org?.phone,
        },
      });

      if (isSearchingExternal && org?.id) {
        handleSearchBusinessCity(org?.id);
      }
      setIsBusinessNew(false);
      setExistingEmployerFromSearch(true);
      setWarningMessage('');
      clearSmartyStreetsSecondaryAddressValidation();
      setIsEmploymentFormValid(true);
    },
    [
      isSearchingExternal,
      employmentForm,
      business,
      searchResultOrganizations,
      setEmploymentForm,
      setIsBusinessNew,
      setExistingEmployerFromSearch,
      clearSmartyStreetsSecondaryAddressValidation,
      handleSearchBusinessCity,
    ]
  );

  const handleBusinessCityChange = React.useCallback(
    (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, billingAddress: { ...address, city: data.value as string } },
      });
      if (!isSearchingExternal) getOrganizationByNameAndCity(businessName as string, data.value as string);
    },
    [employmentForm, business, businessName, address, isSearchingExternal, getOrganizationByNameAndCity]
  );

  const handleSearchAgainBusinessName = React.useCallback(() => {
    setEmploymentForm({
      ...initialState.employmentData.newEmployer,
      ...(role && {
        relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
        jobTitle: role,
      }),
      ...(isForModal && {
        status: MembershipTypes.EmploymentStatus[0].value,
        relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
      }),
    });
    setStateBusinessList({
      ...stateBusinessList,
      filteredList: [],
    });
    setBusinessCities([]);
    setIsBusinessNew(false);
    setWarningMessage('');
    setSearchTempStorage({
      businessName: '',
      businessCity: '',
      businessZipCode: '',
    });
    setExistingEmployerFromSearch(false);
    clearSmartyStreetsSecondaryAddressValidation();
  }, [
    stateBusinessList,
    role,
    isForModal,
    setEmploymentForm,
    setStateBusinessList,
    setIsBusinessNew,
    setWarningMessage,
    setSearchTempStorage,
    setExistingEmployerFromSearch,
    clearSmartyStreetsSecondaryAddressValidation,
  ]);

  const handleSearchAgainBusinessCity = React.useCallback(() => {
    setEmploymentForm({
      ...initialState.employmentData.newEmployer,
      ...(role && {
        relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
        jobTitle: role,
      }),
      organization: {
        ...initialState.employmentData.newEmployer.organization,
        name: employmentForm.organization.name,
        type: employmentForm.organization.type,
      },
    });
    businessCityDOM?.focus();
    setBusinessCities([]);
    setSearchTempStorage({
      ...searchTempStorage,
      businessCity: '',
    });
    clearSmartyStreetsSecondaryAddressValidation();
  }, [
    businessCityDOM,
    employmentForm,
    searchTempStorage,
    role,
    setEmploymentForm,
    setBusinessCities,
    setSearchTempStorage,
    clearSmartyStreetsSecondaryAddressValidation,
  ]);

  const handleSearchCityChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setWarningMessage('');
      setSearchTempStorage({
        ...searchTempStorage,
        businessCity: event.target.value,
      });
    },
    [searchTempStorage, setWarningMessage, setSearchTempStorage]
  );

  const handleSearchNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setWarningMessage('');
      setSearchTempStorage({
        ...searchTempStorage,
        businessName: event.target.value,
      });
    },
    [searchTempStorage, setWarningMessage, setSearchTempStorage]
  );

  const handleSearchZipCodeChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setWarningMessage('');
      setSearchTempStorage({
        ...searchTempStorage,
        businessZipCode: event.target.value,
      });
    },
    [searchTempStorage, setWarningMessage, setSearchTempStorage]
  );

  const handleNewBusinessChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, name: event.target.value },
      });
      setSearchTempStorage({
        ...searchTempStorage,
        businessName: event.target.value,
      });
    },
    [employmentForm, business, setEmploymentForm, searchTempStorage]
  );

  const handleOrganizationTypeOnChange = React.useCallback(
    (event: React.SyntheticEvent, data: StrictDropdownProps) => {
      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, type: data.value as string },
      });
    },
    [employmentForm, business, setEmploymentForm]
  );

  const handleAddressLine1OnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddressFormErrors({ ...addressFormErrors, line1: false });
      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, billingAddress: { ...address, addressLine1: event.target.value } },
      });
    },
    [employmentForm, business, address, addressFormErrors, setEmploymentForm, setAddressFormErrors]
  );

  const handleAddressLine2OnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, billingAddress: { ...address, addressLine2: event.target.value } },
      });
    },
    [employmentForm, business, address, setEmploymentForm]
  );

  const handleAddressCityOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddressFormErrors({ ...addressFormErrors, city: false });
      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, billingAddress: { ...address, city: event.target.value } },
      });
    },
    [employmentForm, business, address, addressFormErrors, setEmploymentForm, setAddressFormErrors]
  );

  const handleAddressStateOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddressFormErrors({ ...addressFormErrors, state: false });
      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, billingAddress: { ...address, state: event.target.value } },
      });
    },
    [employmentForm, business, address, addressFormErrors, setEmploymentForm, setAddressFormErrors]
  );

  const handleAddressPostalCodeOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddressFormErrors({ ...addressFormErrors, zipCode: false });
      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, billingAddress: { ...address, zipCode: event.target.value } },
      });
    },
    [employmentForm, business, address, addressFormErrors, setEmploymentForm, setAddressFormErrors]
  );

  const handleAddressCountryOnChange = React.useCallback(
    (event: React.SyntheticEvent, data: StrictDropdownProps) => {
      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, billingAddress: { ...address, country: data.value as string } },
      });
    },
    [employmentForm, business, address, setEmploymentForm]
  );

  const handleSetShippingAddressChange = React.useCallback(
    (event: React.SyntheticEvent, { checked }) => {
      setIsShippingAddress(checked);
    },
    [setIsShippingAddress]
  );

  // Will set the address if there is confirmation on Smarty street
  const setShippingAddress = React.useCallback(
    async (setAddress: any) => {
      setEmploymentForm({
        ...employmentForm,
        organization: {
          ...business,
          billingAddress: setAddress,
        },
      });
    },
    [employmentForm, setEmploymentForm, business]
  );
  const handlePhoneNumberOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (Utils.hasLowercase(value) || Utils.hasUppercase(value) || Utils.hasSpecialChar(value)) return;
      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, phone: value },
      });
    },
    [employmentForm, business, setEmploymentForm]
  );

  const handleCenterPhoneNumberOnChange = React.useCallback(
    (dialCode: string, workPhoneNumber: string) => {
      const value = `${dialCode}${workPhoneNumber}`;

      setEmploymentForm({
        ...employmentForm,
        organization: { ...business, phone: value },
      });
    },
    [employmentForm, business, setEmploymentForm]
  );

  const handleEditChange = () => {
    setIsAddressDetailsEditable(true);
  };

  const handleAcknowledgementConfirm = () => {
    const newEmploymentForm = { ...employmentForm };
    newEmploymentForm.organization.underReview = isUnderReview;
    newEmploymentForm.status = `${Salesforce.AccountPersonAccountStatuses.ACTIVE}`;

    updateEmploymentData(
      newEmploymentForm,
      application,
      membershipJourneyApplicationType,
      isBusinessNew,
      hasExistingEmployers,
      isCenterMembershipJourney
    );

    if (setIsUnderReview) {
      setIsUnderReview(false);
    }
    if (toggleCreateOrganization) {
      toggleCreateOrganization();
    }
  };

  const handleSubmit = (e: any) => {
    setHasErrorMessage(false);
    setWarningMessage('');
    setHasAddressValidError(false);
    setAddressFormErrors({});
    if (setNewEmployerJobTitle) {
      setNewEmployerJobTitle(employmentForm.jobTitle);
    }
    if (
      businessType === MembershipTypes.OrganizationType.PUBLIC_ACCOUNTING &&
      togglePartnerDeclareAcknowledgement &&
      isCenterMembershipJourney
    ) {
      validateAddress({
        addressLine1: businessAddressLine1 || '',
        addressLine2: businessAddressLine2,
        city: businessCity || '',
        state: businessState || '',
        zipCode: businessPostalCode || '',
        country: address?.country || '',
      }).then(() => {
        togglePartnerDeclareAcknowledgement(employmentForm.organization.name, isBusinessNew);
      });
      togglePartnerDeclareAcknowledgement(employmentForm.organization.name, isBusinessNew);
      return;
    }

    if (isCenterMembershipJourney) {
      validateAddress({
        addressLine1: businessAddressLine1 || '',
        addressLine2: businessAddressLine2,
        city: businessCity || '',
        state: businessState || '',
        zipCode: businessPostalCode || '',
        country: address?.country || '',
      }).then(() => {
        setCanProceed(true);
      });
      return;
    }

    if (hasExistingEmployers && !isForModal) {
      setCanProceed(true);
      return;
    }

    if (isPaidByFirm) {
      setEmploymentForm({
        ...employmentForm,
        organization: {
          ...employmentForm.organization,
          ...inviteData?.organization,
        },
      });
      setCanProceed(true);
      return;
    }

    if (isForModal) {
      if (useDisablePA) {
        if (isPAOrg && isUSBillingAddress && !isCenterMembershipJourney && isBusinessNew) {
          setIsPeerReviewErrorModalOpen(true);
          setCanProceed(false);
          return;
        }
      }

      if (canEditEmploymentInfo || (inviteData?.inviteId && !inviteData.isPaidByFirm)) {
        validateAddress({
          addressLine1: businessAddressLine1 || '',
          addressLine2: businessAddressLine2,
          city: businessCity || '',
          state: businessState || '',
          zipCode: businessPostalCode || '',
          country: address?.country || '',
        }).then((res: any) => {
          if (res?.payload?.isShippingAddressValid) {
            updateEmploymentData(
              {
                ...employmentForm,
                ...(employmentForm?.status && {
                  status: employmentForm?.status === MembershipTypes.EmploymentStatus[0].value ? 'Active' : 'Inactive',
                }),
                isPrimary: true,
              },
              application,
              membershipJourneyApplicationType,
              Boolean(!employmentForm.id),
              hasExistingEmployers,
              false,
              isForModal
            );

            if (processTLW) {
              if (employmentForm.status === MembershipTypes.EmploymentStatus[1].value) {
                processTLW({
                  ...(Boolean(currentUserTLW.id) && { id: currentUserTLW.id }),
                  aicpaUid: currentUserAICPAUID,
                  tlwReason: tlwData.tlwReason,
                  tlwEndDate: moment().format('YYYY-MM-DD'),
                  employmentStatus: employmentForm.status ?? '',
                });
              } else {
                if (tlwData.tlwReason) {
                  processTLW({
                    ...(Boolean(currentUserTLW.id) && { id: currentUserTLW.id }),
                    aicpaUid: currentUserAICPAUID,
                    tlwReason: tlwData.tlwReason,
                    tlwEndDate: moment().format('YYYY-MM-DD'),
                    employmentStatus: '',
                  });
                }
              }
            }

            if (inviteData?.inviteId) setIsOpenEmploymentModalFromInvite(false);

            handleModalClose();
            if (handleSelectedEmployment) {
              handleSelectedEmployment(selectedEmploymentStatus);
            }
          }
        });
      } else {
        updateEmploymentData(
          {
            ...employmentForm,
            ...(employmentForm?.status && {
              status: employmentForm?.status === MembershipTypes.EmploymentStatus[0].value ? 'Active' : 'Inactive',
            }),
          },
          application,
          membershipJourneyApplicationType,
          Boolean(!employmentForm.id),
          hasExistingEmployers,
          false,
          isForModal
        );
        if (processTLW && employmentForm.status === MembershipTypes.EmploymentStatus[1].value) {
          processTLW({
            ...(Boolean(currentUserTLW.id) && { id: currentUserTLW.id }),
            aicpaUid: currentUserAICPAUID,
            tlwReason: tlwData.tlwReason,
            tlwEndDate: moment().format('YYYY-MM-DD'),
            employmentStatus: employmentForm.status ?? '',
          });
        }
        if (inviteData?.inviteId) setIsOpenEmploymentModalFromInvite(false);
        handleModalClose();
        if (handleSelectedEmployment) {
          handleSelectedEmployment(selectedEmploymentStatus);
        }
      }
      return;
    }
    // B2C Journey Employee Information Page
    if (useDisablePA) {
      if (isPAOrg && isUSBillingAddress && !isCenterMembershipJourney && isBusinessNew) {
        setIsPeerReviewErrorModalOpen(true);
        setCanProceed(false);
        return;
      }
    }

    validateAddress({
      addressLine1: businessAddressLine1 || '',
      addressLine2: businessAddressLine2,
      city: businessCity || '',
      state: businessState || '',
      zipCode: businessPostalCode || '',
      country: address?.country || '',
    }).then(() => {
      setCanProceed(true);
    });
  };

  const handleItemClick = (event: React.SyntheticEvent, data: DropdownItemProps) => {
    refDropdownBusinessName.current.handleItemClick(event, data);
  };

  const handleCreateNewClick = React.useCallback(async () => {
    setEmployerSelectionUseCase(User.EmployerSelectionUseCaseEnum.CREATE_MANUALLY);
    setEditEmploymentInfo(true);
    setWarningMessage('');
    clearNewEmployerData();

    setEmploymentForm({
      ...employmentForm,
      ...(role && {
        status: MembershipTypes.EmploymentStatus[0].value,
        relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
        jobTitle: role,
      }),
      organization: {
        ...initialState.employmentData.newEmployer.organization,
        name: searchTempStorage.businessName || '',
      },
    });
    setIsBusinessNew(true);
  }, [searchTempStorage, role, setWarningMessage, setIsBusinessNew, clearNewEmployerData, employmentForm]);

  const handleEditPrimaryEmployerClick = React.useCallback(
    (event: React.SyntheticEvent, data: DropdownProps) => {
      setEditEmploymentInfo(true);
    },
    [setEditEmploymentInfo]
  );

  const onChangeRole = React.useCallback(
    (event: React.SyntheticEvent, data: StrictDropdownProps) => {
      setHasErrorMessage(false);
      setWarningMessage('');
      setHasAddressValidError(false);
      setEmploymentForm(prevState => ({
        ...prevState,
        jobTitle: data.value as string,
      }));
    },
    [setEmploymentForm]
  );

  const onStatusChange = React.useCallback(
    (event: React.SyntheticEvent, data: StrictDropdownProps) => {
      setSelectedEmploymentStatus(data?.value);
      setHasErrorMessage(false);
      setWarningMessage('');
      setHasAddressValidError(false);
      setEmploymentForm(prevState => ({
        ...prevState,
        status: data.value as string,
      }));
      setIsEmploymentFormValid(false);
      if (setNewEmployerJobTitle && !inviteData?.inviteId) {
        setNewEmployerJobTitle('');
      }
    },
    [setEmploymentForm, setNewEmployerJobTitle, inviteData]
  );

  const onTLWChange = React.useCallback(
    (event: React.SyntheticEvent, data: StrictDropdownProps) => {
      setHasErrorMessage(false);
      setWarningMessage('');
      setHasAddressValidError(false);
      setTlwData(prevState => ({
        ...prevState,
        tlwReason: data.value as string,
      }));
    },
    [setTlwData]
  );

  const handleSelectItemConnectedEmployer = (
    employer: Omit<State.AccountPersonAccount, 'personAccount'> | undefined
  ) => {
    if (employer) {
      const { id, relationshipType, jobTitle, organization } = employer;
      const {
        id: orgId,
        name,
        type,
        creditHoldStatus,
        hasAICPAUid,
        AICPAUid,
        isFirmBillingEnabled,
        billingAddress,
        shippingAddress: orgShippingAddress,
        phone: orgPhone,
        profileEmployerAddress,
        profileEmployerPhone,
        email,
      } = organization;
      setEmploymentForm({
        ...employmentForm,
        id,
        relationshipType,
        jobTitle,
        isPrimary: true,
        organization: {
          id: orgId,
          name,
          type,
          creditHoldStatus,
          hasAICPAUid,
          AICPAUid,
          isFirmBillingEnabled,
          billingAddress,
          shippingAddress: orgShippingAddress,
          phone: orgPhone,
          profileEmployerAddress,
          profileEmployerPhone,
          email,
        },
      });
    }
  };

  const handleSelectItemSearchedEmployer = (name: string, sfOrgId?: string) => {
    const org = searchResultOrganizations?.length
      ? searchResultOrganizations.find((organization: any) => organization.id === sfOrgId || organization.name === name)
      : null;
    if (org) {
      if (!org.id) {
        setIsBusinessNew(true);
      }
      setEmploymentForm({
        ...employmentForm,
        id: '',
        jobTitle: '',
        isPrimary: true,
        organization: {
          id: org?.id,
          name: org.name,
          type: org.type,
          creditHoldStatus: org?.creditHoldStatus,
          hasAICPAUid: org?.hasAICPAUid,
          AICPAUid: org?.AICPAUid,
          isFirmBillingEnabled: org?.isFirmBillingEnabled,
          billingAddress: org?.billingAddress,
          shippingAddress: org?.shippingAddress,
          phone: org?.phone,
          profileEmployerAddress: org?.profileEmployerAddress,
          profileEmployerPhone: org?.profileEmployerPhone,
          email: org?.email,
        },
      });
      setEditEmploymentInfo(true);
    }
  };

  const handleUpdateEmployerSelectionUseCase = (useCase: User.EmployerSelectionUseCaseEnum) => {
    setEmployerSelectionUseCase(useCase);
  };

  const handleBackToSearchConnectedOrganization = () => {
    setEmployerSelectionUseCase(User.EmployerSelectionUseCaseEnum.SEARCH_TO_CONNECTED_EMPLOYERS);
    setEditEmploymentInfo(false);
    if (currentUserPrimaryEmployer) {
      const { id, relationshipType, jobTitle, organization, isPrimary } = currentUserPrimaryEmployer;
      const {
        id: orgId,
        name,
        type,
        creditHoldStatus,
        hasAICPAUid,
        AICPAUid,
        isFirmBillingEnabled,
        billingAddress,
        shippingAddress: orgShippingAddress,
        phone: orgPhone,
        profileEmployerAddress,
        profileEmployerPhone,
        email,
      } = organization;
      setEmploymentForm({
        ...employmentForm,
        id,
        relationshipType,
        jobTitle,
        isPrimary,
        organization: {
          id: orgId,
          name,
          type,
          creditHoldStatus,
          hasAICPAUid,
          AICPAUid,
          isFirmBillingEnabled,
          billingAddress,
          shippingAddress: orgShippingAddress,
          phone: orgPhone,
          profileEmployerAddress,
          profileEmployerPhone,
          email,
        },
        status: MembershipTypes.EmploymentStatus[0].value,
      });
    } else {
      setEmploymentForm({
        ...initialState.employmentData.newEmployer,
        status: MembershipTypes.EmploymentStatus[0].value,
      });
    }
  };

  const handleBackToSearchOrganizationSFZoomInfo = () => {
    setEmployerSelectionUseCase(User.EmployerSelectionUseCaseEnum.SEARCH_TO_SALESFORCE_ZOOMINFO);
    setEditEmploymentInfo(false);
    setEmploymentForm({
      ...initialState.employmentData.newEmployer,
      status: MembershipTypes.EmploymentStatus[0].value,
    });
  };

  const onCloseHandler = (value: any) => {
    setIsClose({ ...isClose, [value]: true });
  };

  // For Turning on or off the AVS Design
  const isCIMA = membershipApplicationType === Product.MembershipApplicationType.CIMA;
  const shouldShowAVSChanges = useNewMembershipAICPA && !isCIMA;

  // AVS texts
  const backButtonText = 'Go back';

  if (isLoading && !isForModal) return <StyledLoader active content="Loading" />;

  return (
    <>
      <ModalAddressValidation
        shippingAddress={smartyStreetsAddress}
        reason={organizationSSValidationReason}
        shippingSuggestions={organizationSSSuggestions}
        billingAddress={null}
        billingReason={null}
        billingSuggestions={[]}
        loading={false}
        smartyValidationFetched={organizationSSValidationFetched}
        isAddressValidationModalOpen={isAddressValidationModalOpen}
        isRaveCheckout={false}
        toggleModalAddressValidation={toggleModalAddressValidation}
        validateAddress={validateAddress}
        setSuggestedAddress={setSuggestedAddress}
        setShippingAddress={setShippingAddress}
      />

      <StyledContainer>
        {isCenterMembershipJourney && (
          <StyledHeaderParagraph>
            {hasErrorMessage && (
              <StyledNotificationBanner
                testId="membership-employment-form-error"
                childrenTestId="membership-application-employment-error-children"
                variant={NotificationBannerEnums.variant.red}
                icon={<StyledIconError />}
              >
                {hasAddressValidError ? (
                  <>
                    Sorry, we’re unable to verify your address. Please edit the address and try again. If your address
                    is valid and you are still experiencing this error, please contact us through the chat icon on the
                    right or schedule a chat appointment through our{' '}
                    <Link isExternal to={getPath(Routes.HELP)} testId="ei-question-us-phone">
                      help page
                    </Link>
                    .
                  </>
                ) : (
                  <>Uh oh. Something went wrong, please try again.</>
                )}
              </StyledNotificationBanner>
            )}
            {warningMessage && (
              <StyledNotificationBanner
                testId="membership-employment-form-error"
                childrenTestId="membership-application-employment-error-children"
                variant={NotificationBannerEnums.variant.blue}
                icon={<StyledIconError />}
              >
                {warningMessage}
              </StyledNotificationBanner>
            )}
          </StyledHeaderParagraph>
        )}
        {!isCenterMembershipJourney && !isForModal && (
          <>
            {!shouldShowAVSChanges && (
              <>
                <StyledPreviousPage
                  backText="Back"
                  route={generatePath(
                    getPath(
                      getApplicationProgressRoute(
                        User.MembershipApplicationStages.QUALIFICATION,
                        membershipApplicationType
                      )
                    )
                  )}
                />
                <MembershipStepperNotificationHeader />
              </>
            )}

            <StyledEmployment avsStyles={shouldShowAVSChanges}>Employment information</StyledEmployment>
            {!shouldShowAVSChanges && <Divider />}
            {shouldShowAVSChanges && <StyledSubtitle>Please confirm your current employment details</StyledSubtitle>}
          </>
        )}
        {!isCenterMembershipJourney && (
          <StyledHeaderParagraph>
            {!isForModal &&
              'This information helps ensure compliance with your company’s policies and peer review. Plus, your firm may cover the cost of membership dues.'}
            {hasErrorMessage && (
              <StyledNotificationBanner
                testId="membership-employment-form-error"
                childrenTestId="membership-application-employment-error-children"
                variant={NotificationBannerEnums.variant.red}
                icon={<StyledIconError />}
              >
                {hasAddressValidError ? (
                  <>
                    Sorry, we’re unable to verify your address. Please edit the address and try again. If your address
                    is valid and you are still experiencing this error, please contact us through the chat icon on the
                    right or schedule a chat appointment through our{' '}
                    <Link isExternal to={getPath(Routes.HELP)} testId="ei-question-us-phone">
                      help page
                    </Link>
                    .
                  </>
                ) : (
                  <>Uh oh. Something went wrong, please try again.</>
                )}
              </StyledNotificationBanner>
            )}
            {warningMessage && (
              <StyledNotificationBanner
                testId="membership-employment-form-error"
                childrenTestId="membership-application-employment-error-children"
                variant={NotificationBannerEnums.variant.blue}
                icon={<StyledIconError />}
              >
                {warningMessage}
              </StyledNotificationBanner>
            )}
          </StyledHeaderParagraph>
        )}
      </StyledContainer>

      {showEmploymentForm || isPromoCodeAvailable ? (
        <StyledContainer>
          {currentUserPrimaryEmployer && !isForModal && !isCenterMembershipJourney && (
            <ApplicationEmploymentSubForm employers={employers} isAvsDesign={shouldShowAVSChanges} />
          )}
          {currentUserPrimaryEmployer && isForModal && !isCenterMembershipJourney ? (
            <EmploymentForm
              isSearchingExternal={isSearchingExternal}
              isCenterMembershipJourney={isCenterMembershipJourney}
              employerAlreadyExists={employerAlreadyExists}
              selectedMembershipType={selectedMembershipType}
              searchOrganizationsByWildcardLoading={searchOrganizationsByWildcardLoading}
              searchOrganizationsCitiesLoading={searchOrganizationsCitiesLoading}
              isAddressDetailsEditable={isAddressDetailsEditable}
              handleEditChange={handleEditChange}
              employmentForm={employmentForm}
              handleCreateNewClick={handleCreateNewClick}
              handleItemClick={handleItemClick}
              handlePhoneNumberOnChange={handlePhoneNumberOnChange}
              handleSetShippingAddressChange={handleSetShippingAddressChange}
              handleAddressCountryOnChange={handleAddressCountryOnChange}
              handleAddressPostalCodeOnChange={handleAddressPostalCodeOnChange}
              handleAddressStateOnChange={handleAddressStateOnChange}
              handleAddressCityOnChange={handleAddressCityOnChange}
              handleAddressLine2OnChange={handleAddressLine2OnChange}
              handleAddressLine1OnChange={handleAddressLine1OnChange}
              handleOrganizationTypeOnChange={handleOrganizationTypeOnChange}
              handleNewBusinessChange={handleNewBusinessChange}
              handleSearchBusinessCity={handleSearchBusinessCity}
              handleSearchCityChange={handleSearchCityChange}
              handleSearchAgainBusinessCity={handleSearchAgainBusinessCity}
              handleSearchAgainBusinessName={handleSearchAgainBusinessName}
              handleBusinessCityChange={handleBusinessCityChange}
              handleBusinessChange={handleBusinessChange}
              handleSearchChange={handleSearchChange}
              searchFn={searchFn}
              handleSearchSFBusinessNameWithZipCode={handleSearchSFBusinessNameWithZipCode}
              handleSearchZIBusinessNameWithZipCode={handleSearchZIBusinessNameWithZipCode}
              phoneNumber={phoneNumber}
              businessPostalCode={businessPostalCode}
              businessState={businessState}
              businessCity={businessCity}
              addressLine2ErrorMessage={addressLine2ErrorMessage}
              isAddressLine2IsValid={isAddressLine2IsValid}
              isBusinessNameValid={isBusinessNameValid}
              businessAddressLine2={businessAddressLine2}
              businessAddressLine1={businessAddressLine1}
              address={address}
              businessType={businessType}
              businessName={businessName}
              role={role}
              refDropdownBusinessName={refDropdownBusinessName}
              refNewBusiness={refNewBusiness}
              businessNameElementId={businessNameElementId}
              businessCityElementId={businessCityElementId}
              isShippingAddress={isShippingAddress}
              existingEmployerFromSearch={existingEmployerFromSearch}
              hasPromoCode={hasPromoCode}
              stateBusinessList={stateBusinessList}
              businessCities={businessCities}
              isBusinessNew={isBusinessNew}
              isForModal={isForModal}
              hasExistingEmployers={hasExistingEmployers}
              canEditEmploymentInfo={canEditEmploymentInfo}
              handleEditPrimaryEmployerClick={handleEditPrimaryEmployerClick}
              onChangeRole={onChangeRole}
              onStatusChange={onStatusChange}
              tlwData={tlwData}
              onTLWChange={onTLWChange}
              renderTLWReasonSelection={renderTLWReasonSelection}
              renderRoleSelection={renderRoleSelection}
              renderEmploymentDetails={renderEmploymentDetails}
              // Newly Added
              employers={employers}
              employerSelectionUseCase={employerSelectionUseCase}
              handleUpdateEmployerSelectionUseCase={handleUpdateEmployerSelectionUseCase}
              handleSelectItemConnectedEmployer={handleSelectItemConnectedEmployer}
              handleSelectItemSearchedEmployer={handleSelectItemSearchedEmployer}
              handleSearchNameChange={handleSearchNameChange}
              handleSearchZipCodeChange={handleSearchZipCodeChange}
              searchTempStorage={searchTempStorage}
              handleBackToSearchConnectedOrganization={handleBackToSearchConnectedOrganization}
              handleBackToSearchOrganizationSFZoomInfo={handleBackToSearchOrganizationSFZoomInfo}
              addressFormErrors={addressFormErrors}
              onCloseHandler={onCloseHandler}
              isClose={isClose}
            />
          ) : (
            <EmploymentForm
              isSearchingExternal={isSearchingExternal}
              employerAlreadyExists={employerAlreadyExists}
              selectedMembershipType={selectedMembershipType}
              searchOrganizationsByWildcardLoading={searchOrganizationsByWildcardLoading}
              searchOrganizationsCitiesLoading={searchOrganizationsCitiesLoading}
              isAddressDetailsEditable={isAddressDetailsEditable}
              handleEditChange={handleEditChange}
              employmentForm={employmentForm}
              handleCreateNewClick={handleCreateNewClick}
              handleItemClick={handleItemClick}
              handlePhoneNumberOnChange={handlePhoneNumberOnChange}
              handleSetShippingAddressChange={handleSetShippingAddressChange}
              handleAddressCountryOnChange={handleAddressCountryOnChange}
              handleAddressPostalCodeOnChange={handleAddressPostalCodeOnChange}
              handleAddressStateOnChange={handleAddressStateOnChange}
              handleAddressCityOnChange={handleAddressCityOnChange}
              handleAddressLine2OnChange={handleAddressLine2OnChange}
              handleAddressLine1OnChange={handleAddressLine1OnChange}
              handleOrganizationTypeOnChange={handleOrganizationTypeOnChange}
              handleNewBusinessChange={handleNewBusinessChange}
              handleSearchBusinessCity={handleSearchBusinessCity}
              handleSearchCityChange={handleSearchCityChange}
              handleSearchAgainBusinessCity={handleSearchAgainBusinessCity}
              handleSearchAgainBusinessName={handleSearchAgainBusinessName}
              handleBusinessCityChange={handleBusinessCityChange}
              handleBusinessChange={handleBusinessChange}
              handleSearchChange={handleSearchChange}
              searchFn={searchFn}
              handleSearchSFBusinessNameWithZipCode={handleSearchSFBusinessNameWithZipCode}
              handleSearchZIBusinessNameWithZipCode={handleSearchZIBusinessNameWithZipCode}
              phoneNumber={phoneNumber}
              businessPostalCode={businessPostalCode}
              businessState={businessState}
              businessCity={businessCity}
              addressLine2ErrorMessage={addressLine2ErrorMessage}
              isAddressLine2IsValid={isAddressLine2IsValid}
              isBusinessNameValid={isBusinessNameValid}
              businessAddressLine2={businessAddressLine2}
              businessAddressLine1={businessAddressLine1}
              address={address}
              businessType={businessType}
              businessName={businessName}
              role={role}
              refDropdownBusinessName={refDropdownBusinessName}
              refNewBusiness={refNewBusiness}
              businessNameElementId={businessNameElementId}
              businessCityElementId={businessCityElementId}
              isShippingAddress={isShippingAddress}
              existingEmployerFromSearch={existingEmployerFromSearch}
              hasPromoCode={hasPromoCode}
              stateBusinessList={stateBusinessList}
              businessCities={businessCities}
              isBusinessNew={isBusinessNew}
              isCenterMembershipJourney={isCenterMembershipJourney}
              isForModal={isForModal}
              hasExistingEmployers={hasExistingEmployers}
              canEditEmploymentInfo={canEditEmploymentInfo}
              onChangeRole={onChangeRole}
              onStatusChange={onStatusChange}
              handleEditPrimaryEmployerClick={isPaidByFirm}
              isPaidByFirm={isPaidByFirm}
              tlwData={tlwData}
              onTLWChange={onTLWChange}
              renderTLWReasonSelection={renderTLWReasonSelection}
              renderRoleSelection={renderRoleSelection}
              renderEmploymentDetails={renderEmploymentDetails}
              // Newly Added
              employers={employers}
              employerSelectionUseCase={employerSelectionUseCase}
              handleUpdateEmployerSelectionUseCase={handleUpdateEmployerSelectionUseCase}
              handleSelectItemConnectedEmployer={handleSelectItemConnectedEmployer}
              handleSelectItemSearchedEmployer={handleSelectItemSearchedEmployer}
              handleSearchNameChange={handleSearchNameChange}
              handleSearchZipCodeChange={handleSearchZipCodeChange}
              searchTempStorage={searchTempStorage}
              handleBackToSearchConnectedOrganization={handleBackToSearchConnectedOrganization}
              handleBackToSearchOrganizationSFZoomInfo={handleBackToSearchOrganizationSFZoomInfo}
              handleCenterPhoneNumberOnChange={handleCenterPhoneNumberOnChange}
              addressFormErrors={addressFormErrors}
              onCloseHandler={onCloseHandler}
              isClose={isClose}
            />
          )}
          {/* PEER REVIEW ERROR MODAL */}
          <MyProfileDefaultModal
            heading=""
            open={isPeerReviewErrorModalOpen}
            close={closePeerReviewErrorModal}
            size="small"
            maxWidth={700}
          >
            <Style.StyledParagraph align="center">
              Unfortunately, we are unable to process your update request through self-service at this time. Because
              this firm is a public accounting firm enrolled in a practice monitoring program (e.g., peer review),
              additional information is required.
            </Style.StyledParagraph>
            <Style.StyledParagraph align="center">
              If you are an owner, partner, or senior firm member who has left or is leaving a public accounting firm,
              please refer to{' '}
              <a href="https://us.aicpa.org/content/dam/aicpa/interestareas/peerreview/resources/peerreviewforms/downloadabledocuments/firm-struct-chg-form.pdf">
                the Firm Structure Change form found here
              </a>
              , complete the appropriate section based on the included instructions, and submit the form to your firm’s{' '}
              <a href="https://us.aicpa.org/interestareas/peerreview/community/links/pradministeringentities">
                Administering Entity.
              </a>
            </Style.StyledParagraph>
            <Style.StyledParagraph align="center">
              If you have any additional questions or concerns, please contact the Peer Review team at
              prsupport@aicpa.org.
            </Style.StyledParagraph>
            <Style.CenteredButtons>
              <Style.DesktopStyled>
                <UnderstandButton
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={e => togglePeerReviewErrorModal(e)}
                  label="I Understand"
                />
              </Style.DesktopStyled>
              <Style.MobileStyled>
                <UnderstandButton
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={e => togglePeerReviewErrorModal(e)}
                  label="I Understand"
                />
              </Style.MobileStyled>
            </Style.CenteredButtons>
          </MyProfileDefaultModal>

          <StyledDiv isForModal={isForModal}>
            {isCenterMembershipJourney && (
              <StyledConfirmButton
                variant={ButtonVariants.secondary}
                bordered
                testId="btn-cancel"
                onClick={toggleCreateOrganization}
              >
                Cancel
              </StyledConfirmButton>
            )}
            {isCenterMembershipJourney ? (
              // Center Membership confirm button
              // fields to check in employmentform
              !Utils.checkEmploymentFormRequiredFields(
                ['name', 'type', { billingAddress: ['addressLine1', 'city', 'state', 'zipCode', 'country'] }, 'phone'],
                employmentForm
              ) ||
              (!isOrganizationValid && !Boolean(employmentForm.organization.type)) ? (
                // Display a disabled button with Confirm text
                <StyledConfirmButton
                  isForModal={isForModal}
                  onClick={handleSubmit}
                  testId="confirm-button"
                  disabled={true}
                  variant={ButtonVariants.primary}
                  loading={employmentDataUpdateLoading || isOrganizationAddressValidationLoading}
                >
                  Confirm
                </StyledConfirmButton>
              ) : (
                // isOrganizationValid && Boolean(employmentForm.organization.type)
                <StyledConfirmButton
                  isForModal={isForModal}
                  onClick={handleSubmit}
                  testId="confirm-button"
                  disabled={!isOrganizationValid}
                  variant={ButtonVariants.primary}
                  loading={employmentDataUpdateLoading || isOrganizationAddressValidationLoading}
                >
                  Confirm
                </StyledConfirmButton>
              ) // As per the new design. Show the confim button even if disabled
            ) : (
              // B2C confirm button
              <>
                {isForModal ? (
                  <StyledConfirmButton
                    isForModal={isForModal}
                    onClick={handleSubmit}
                    testId="confirm-button"
                    disabled={!isEmploymentFormValid}
                    variant={ButtonVariants.primary}
                    others={{ pageName: `${pageNameNoHash}:employment` }}
                    loading={employmentDataUpdateLoading || isOrganizationAddressValidationLoading}
                  >
                    Confirm
                  </StyledConfirmButton>
                ) : (
                  <StyledButtonsGrid columns={shouldShowAVSChanges ? 2 : 1} avsStyles={shouldShowAVSChanges}>
                    <Grid.Row>
                      <Grid.Column>
                        <StyledConfirmButton
                          isForModal={isForModal}
                          onClick={handleSubmit}
                          testId="confirm-button"
                          disabled={!isEmploymentFormValid}
                          variant={ButtonVariants.primary}
                          others={{ pageName: `${pageNameNoHash}:employment` }}
                          loading={employmentDataUpdateLoading || isOrganizationAddressValidationLoading}
                          avsStyles={shouldShowAVSChanges}
                        >
                          Confirm
                        </StyledConfirmButton>
                      </Grid.Column>

                      {shouldShowAVSChanges && !isForModal && (
                        <Grid.Column>
                          <StyledBackButtonLink
                            variant={ButtonEnums.variants.secondary}
                            to={generatePath(
                              getPath(
                                getApplicationProgressRoute(
                                  User.MembershipApplicationStages.QUALIFICATION,
                                  membershipApplicationType
                                )
                              )
                            )}
                            testId="back-button"
                          >
                            {backButtonText}
                          </StyledBackButtonLink>
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </StyledButtonsGrid>
                )}
              </>
            )}
          </StyledDiv>
        </StyledContainer>
      ) : (
        <StyledFeedPlaceholder>
          <FeedPlaceholder />
        </StyledFeedPlaceholder>
      )}
    </>
  );
};

const StyledContainer = styled.div`
  padding: ${props => props.theme.pxToRem(20)};
  * {
    font-family: ${props => props.theme.fontFamily};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledEmployment = styled.h1<{ avsStyles?: boolean }>`
  margin-bottom: ${props => (props.avsStyles ? props.theme.pxToRem(0) : props.theme.pxToRem(16))} !important;
  font-size: ${props => (props.avsStyles ? props.theme.fontSizes.l : props.theme.fontSizes.xl)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => (props.avsStyles ? props.theme.fontWeights.medium : props.theme.fontWeights.light)};
  color: ${props => (props.avsStyles ? props.theme.colors.primaryPurple : props.theme.colors.neutralGrey8)};
  text-transform: ${props => (props.avsStyles ? 'capitalize' : 'none')};
`;

const StyledPreviousPage = styled(PreviousPage)`
  &&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(16)};
    }
  }
`;

const StyledHeaderParagraph = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.5;
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-top: ${props => props.theme.pxToRem(24)};
`;

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;

const StyledFeedPlaceholder = styled.div`
  padding: ${props => props.theme.pxToRem(20)};
`;

const StyledConfirmButton = styled(Button)<{ avsStyles?: boolean; isForModal?: boolean }>`
  &&&&& {
    height: ${props => props.theme.pxToRem(48)};
    font-size: ${props => props.theme.fontSizes.m};
    width: ${props => (props.avsStyles && !props.isForModal ? '100%' : props.theme.pxToRem(265))};
    margin-top: ${props => (props.isForModal ? '' : props.theme.pxToRem(40))};
    margin-right: ${props => (props.variant === ButtonVariants.primary ? 'initial' : props.theme.pxToRem(10))};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => (props.avsStyles && !props.isForModal ? '100%' : props.theme.pxToRem(190))} !important;
      height: ${props =>
        props.avsStyles && !props.isForModal ? props.theme.pxToRem(48) : props.theme.pxToRem(34)} !important;
      font-size: ${props => props.theme.fontSizes.s} !important;
    }
  }
`;

const StyledDiv = styled.div<any>`
  ${props =>
    props.isForModal
      ? css`
          text-align: center;
        `
      : ''}
`;

const StyledLoader = styled(Loader)`
  position: relative !important;
  top: 130px !important;
  height: auto !important;
`;

const StyledSubtitle = styled.h4`
  margin-top: props.theme.pxToRem(5);
  margin-bottom: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledBackButtonLink = styled(ButtonLink)<any>`
  width: 100%;
  height: ${props => props.theme.pxToRem(48)};
  font-size: ${props => props.theme.fontSizes.m};
  margin-top: ${props => props.theme.pxToRem(40)}!important;
`;

const StyledButtonsGrid = styled(Grid)<{ avsStyles?: boolean }>`
  &&.ui.stackable.grid > .row > .column:first-child {
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => (props.avsStyles ? '65%' : '100%')} !important;
    }
  }
  &&.ui.stackable.grid > .row > .column:last-child {
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => (props.avsStyles ? '35%' : '100%')} !important;
      padding-left: ${props => (props.avsStyles ? 0 : '1rem')} !important;
    }
  }
`;

const UnderstandButton: React.FC<ModalButtonProps> = ({ onClick, isDisabled, label, loading }) => (
  <Style.StyledUnderstandButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId="understand-ethics-modal"
    disabled={isDisabled}
    loading={loading}
  >
    {label ? label : 'Save'}
  </Style.StyledUnderstandButton>
);

interface ModalButtonProps {
  onClick: (event?: any) => void;
  isDisabled?: boolean;
  variant?: ButtonVariants;
  label?: string;
  loading?: boolean;
}
