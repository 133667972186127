import React from 'react';
import { RichTextBlocks } from 'mxp-utils';
import styled from 'styled-components';
import { Link, ExternalLinkStyles, DefaultInlineLinkStyles, ExternalLinkStylesDark } from 'components/atoms/Link/Link';
import { Plus, Minus } from 'components/atoms/svg';
import { RenderPromotionalComponent } from 'components/pages/PageToolkit/RenderPromotionalComponent';
import { RenderBlockList } from 'components/pages/PageToolkit/RenderBlockList';
import { StyledDivider } from 'components/pages/PageToolkit/StyledDivider';
import { LockStateIcon } from 'components/pages/PageToolkit/LockStateIcon';
import { VideoPlayer } from 'components/molecules/VideoPlayer/VideoPlayer';
import { addTableSeparator, getContentUrl, isExternal, replaceHttpWithHttps } from 'utils';
import { handleEvent, CLICK } from 'utils/Analytics';
import { ContentTypes } from './PageToolkit';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as OpenInNew } from 'resources/images/ic-open-in-new.svg';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';
import { AnchorMarkdownRenderer, Expandable } from 'components/atoms';
import gfm from 'remark-gfm';

interface Props {
  item: State.ToolkitPageBlock;
  idx: number;
  showDivider?: boolean;
}

export const RenderToolkitPageBlock: React.FC<Props> = React.memo(({ item, idx, showDivider = false }) => {
  const { accordion, title, bodyCopy, videoAddOn, componentList } = item;

  const renderToolkitPageBody = React.useCallback(() => {
    return componentList?.map((component: State.ContentCardItem, index: number) => {
      const switchValue = component.contentType.slug || component.contentType;
      const tableMarkdown = addTableSeparator(component?.tableMarkdown as any);

      const handleLink = () => {
        if (switchValue === ContentTypes.link) {
          handleEvent({ text: `toolkit-block-link:${component.description}:${component.url}` }, CLICK);
        } else {
          handleEvent({ text: `toolkit-accordian:${component.description}:${getContentUrl(component)}` }, CLICK);
        }
      };

      switch (switchValue) {
        case ContentTypes.promotionalComponent:
          return (
            <RenderPromotionalComponent
              item={component}
              showDivider={false}
              idx={index}
              key={`toolkit-block-promotional-${component.id}`}
              testId={`toolkit-block-promotional-${index}`}
            />
          );
        case ContentTypes.table:
          return (
            <StyledTable
              key={`toolkit-block-table-${component.id}`}
              dangerouslySetInnerHTML={{ __html: RichTextBlocks.createTable({ fields: component }) }}
            />
          );
        case ContentTypes.listBlock:
          return <RenderBlockList item={component} idx={index} key={`list-block-${component.id}`} />;
        case ContentTypes.advancedTable:
          return (
            <StyledAdvancedTable
              plugins={[gfm]}
              key={`toolkit-block-advancedtable-${component.id}`}
              className="ui striped table"
              children={tableMarkdown}
              renderers={{
                link: AnchorMarkdownRenderer,
              }}
              tabIndex={0}
            />
          );
        case ContentTypes.link:
          const isExternalink: boolean = isExternal(component.url);
          return (
            <StyledLinkBlock key={`toolkit-block-link-${component.id}`}>
              <StyledLinkTitle isExternal={isExternalink} to={replaceHttpWithHttps(component.url)} onClick={handleLink}>
                {isExternalink && <StyledIcon iconPurple={true} />}
                {component.title}
              </StyledLinkTitle>
              <StyledLinkParagraph>{component.description}</StyledLinkParagraph>
            </StyledLinkBlock>
          );
        default:
          return (
            <React.Fragment key={`toolkit-block-links-${component.id}`}>
              <StyledAccordionLinkTitle
                isExternal={!!component.externalUrl}
                to={getContentUrl(component)}
                onClick={handleLink}
              >
                <LockStateIcon lockStatus={component.isLocked} />
                {component.title}
              </StyledAccordionLinkTitle>
              <StyledAccordionLinkParagraph tabIndex={0}>{component.description}</StyledAccordionLinkParagraph>
            </React.Fragment>
          );
      }
    });
  }, [componentList]);

  const getBodyContent = (): React.ReactNode => (
    <>
      <StyledAccordionCopyContainer tabIndex={0} dangerouslySetInnerHTML={{ __html: bodyCopy }} />
      {renderToolkitPageBody()}
      {videoAddOn && <VideoPlayer video={videoAddOn} testId="toolkit-video-player-add-on" />}
    </>
  );

  const renderToolkitPageBlockWithAccordion = (): React.ReactNode => (
    <StyledMarginExpandable>
      <StyledExpandableWrapper key={`toolkit-block-accordion-${idx}`}>
        <Expandable
          items={[
            {
              id: idx,
              heading: <StyledAccordionTitle tabIndex={0}>{title}</StyledAccordionTitle>,
              content: getBodyContent(),
            },
          ]}
          expandIcon={<Plus testId={`toolkit-expand-icon-${idx}`} />}
          collapseIcon={<Minus testId={`toolkit-collapse-icon-${idx}`} />}
        />
      </StyledExpandableWrapper>
    </StyledMarginExpandable>
  );

  return (
    <>
      {accordion ? (
        renderToolkitPageBlockWithAccordion()
      ) : (
        <div key={`toolkit-block-no-accordion-${idx}`}>
          <StyledMargin>
            <StyledNonAccordionTitle tabIndex={0}>{title}</StyledNonAccordionTitle>
            {getBodyContent()}
          </StyledMargin>
          {showDivider && <StyledDivider />}
        </div>
      )}
    </>
  );
});

const StyledIcon = styled(OpenInNew)<{ iconPurple: boolean }>`
  vertical-align: middle;
  display: inline-block;
  margin: 0 ${props => props.theme.pxToRem(6)} ${props => props.theme.pxToRem(4)} 0;
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
  ${props =>
    props.iconPurple &&
    `
      path {
        fill: ${props.theme.colors.primaryPurple};
      }
    `}
`;

const StyledAccordionTitle = styled.h3`
  font-size: ${props => props.theme.pxToRem(20)};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
  margin: 0;

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const StyledNonAccordionTitle = styled.h3`
  padding-top: ${props => props.theme.pxToRem(16)};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledExpandableWrapper = styled.div`
  margin-bottom: -1px;
  border-bottom: 1px solid ${props => props.theme.colors.neutralGrey3};
  border-top: 1px solid ${props => props.theme.colors.neutralGrey3};
`;

const StyledAccordionCopyContainer = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: 10%;
  margin-bottom: 20px;

  a {
    ${DefaultInlineLinkStyles};
    .rich-text-external-link {
      ${ExternalLinkStyles}
    }
    .rich-text-external-link:before {
      content: '';
      background: url(${IC_OPEN_IN_NEW_PURPLE});
      vertical-align: middle;
      display: inline-block;
      background-size: contain;
      background-position: center;
      margin: 0 ${props => props.theme.pxToRem(4)} 0 0;
      width: ${props => props.theme.pxToRem(14)};
      height: ${props => props.theme.pxToRem(14)};
    }
  }
`;

const StyledAccordionLinkTitle = styled(Link)`
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledMargin = styled.div`
  margin: 2% 7%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 2%;
  }
`;

const StyledMarginExpandable = styled.div`
  margin: 0 7%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 0 2%;
  }
`;

const StyledAccordionLinkParagraph = styled.p`
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.78;
  color: ${props => props.theme.colors.neutralGrey8};
  padding-bottom: ${props => props.theme.pxToRem(24)};
  padding-top: ${props => props.theme.pxToRem(8)};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: 10%;
`;

const StyledTable = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow-x: auto;
  }
  & table {
    width: 100%;
    text-align: left;
    margin-bottom: ${props => props.theme.pxToRem(40)};
    border-collapse: collapse;

    tbody {
      tr:nth-child(odd) {
        background-color: ${props => props.theme.colors.neutralWhite};
      }
    }
    th {
      padding: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(23)};
      font-size: ${props => props.theme.fontSizes.xs};
    }

    tr {
      background-color: ${props => props.theme.colors.neutralGrey1};
    }

    td {
      padding: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(23)};
      border-bottom: 1px solid ${props => props.theme.colors.neutralGrey3};
      font-size: ${props => props.theme.fontSizes.s};
    }
  }
`;

const StyledLinkBlock = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  padding-bottom: ${props => props.theme.pxToRem(42)};
`;

const StyledLinkTitle = styled(Link)<{ isExternal: boolean }>`
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.medium};
  ${props =>
    props.isExternal &&
    `
    color: ${props.theme.colors.neutralGrey8};
    `}
`;

const StyledLinkParagraph = styled.p`
  line-height: 1.78;
  color: ${props => props.theme.colors.neutralGrey8};
  padding-top: ${props => props.theme.pxToRem(8)};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: 10%;
`;

export const StyledAdvancedTable = styled(ReactMarkdown as any)`
  &&&& {
    text-align: center;
    border-style: solid;
    border-width: 0 0 ${props => props.theme.pxToRem(1)} 0;
    border-color: ${props => props.theme.colors.neutralGrey3};
    margin: 0;
    background: ${props => props.bgColor};

    .rich-text-external-link {
      ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
    }
    .rich-text-external-link:before {
      content: '';
      background: url(${props => (props.isBgColorDark ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
      vertical-align: middle;
      display: inline-block;
      background-size: contain;
      background-position: center;
      margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
      width: ${props => props.theme.pxToRem(16)};
      height: ${props => props.theme.pxToRem(16)};
    }
    .rich-text-internal-link {
      ${DefaultInlineLinkStyles};
    }

    table {
      width: 100%;
      border: 0;
      text-align: left;
      table-layout: fixed;
      overflow: hidden;
      thead tr {
        background-color: ${props => props.theme.colors.neutralGrey1};
      }
      tr {
        :nth-child(2n) {
          background-color: rgba(247, 247, 247, 0.3);
        }
        ${props => props.theme.mediaQueries.mobileOnly} {
          padding-top: ${props => props.theme.pxToRem(40)};
          padding-bottom: ${props => props.theme.pxToRem(24)};
          box-shadow: 0 ${props => props.theme.pxToRem(-1)} 0 0 ${props => props.theme.colors.neutralGrey3} inset !important;
        }
      }
      tr th {
        padding: ${props => props.theme.pxToRem(11)} ${props => props.theme.pxToRem(20)};
        color: ${props => props.theme.colors.neutralGrey8};
        font-size: ${props => props.theme.fontSizes.s};
        font-weight: ${props => props.theme.fontWeights.medium};
        line-height: 1.57;
        min-width: ${props => props.theme.pxToRem(190)};
        :nth-child(2) {
          text-align: left;
        }
        ${props => props.theme.mediaQueries.mobileOnly} {
          padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;
        }
      }
      tr td {
        padding: ${props => props.theme.pxToRem(21)} ${props => props.theme.pxToRem(20)};
        border-top-color: ${props => props.theme.colors.neutralGrey3};
        color: ${props => props.theme.colors.neutralGrey8};
        font-size: ${props => props.theme.fontSizes.s};
        font-weight: ${props => props.theme.fontWeights.light};
        line-height: 1.5;
        min-width: ${props => props.theme.pxToRem(190)};
        :nth-child(2) {
          text-align: left;
        }
        ${props => props.theme.mediaQueries.mobileOnly} {
          padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;
          :first-child {
            font-weight: ${props => props.theme.fontWeights.regular};
          }
          :nth-child(2) {
            text-align: left;
          }
        }
      }
      td {
        word-wrap: break-word;
        overflow: hidden;
      }
      ${props => props.theme.mediaQueries.mobileOnly} {
        border-width: 0;
        margin-top: 0;
      }
    }
  }
`;
