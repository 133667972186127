import React from 'react';
import { Modal, ClapSpinner } from 'components/atoms';
import styled from 'styled-components';
import { theme } from 'theme';

interface Props {
  isPaymentProcessingModalOpen: boolean;
  isFreeCart: boolean;
}

export const ModalPaymentProcessing: React.FC<Props> = ({ isPaymentProcessingModalOpen, isFreeCart }) => (
  <StyledModal
    size="tiny"
    heading="Almost done"
    open={isPaymentProcessingModalOpen}
    testId="payment-processing"
    centered={false} // For IE11.
  >
    <StyledClapSpinnerContainer>
      <ClapSpinner size={54} frontColor={theme.colors.primaryPurple} backColor={theme.colors.primaryPurple} loading />
    </StyledClapSpinnerContainer>
    {!isFreeCart && 'Your payment is processing. '}Please <b>do not refresh the page.</b>
  </StyledModal>
);

const StyledModal = styled(Modal)`
  &&&&& {
    padding: 0 ${props => props.theme.pxToRem(20)};
    margin-top: ${props => props.theme.pxToRem(137)} !important;
    margin-bottom: ${props => props.theme.pxToRem(137)} !important;
    height: ${props => props.theme.pxToRem(310)};
    width: ${props => props.theme.pxToRem(335)};
    border-radius: ${props => props.theme.pxToRem(2)};
    text-align: center;
    box-shadow: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(20)} 0 rgba(0, 0, 0, 0.3);

    div.header {
      margin-top: ${props => props.theme.pxToRem(150)};
      font-weight: ${props => props.theme.fontWeights.light};
      line-height: 1.33;
      color: ${props => props.theme.colors.neutralGrey8};
    }
    div.content {
      color: ${props => props.theme.colors.neutralGrey8};
      padding: 0;
      margin-top: ${props => props.theme.pxToRem(4)};
      line-height: 1.57;
      b {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const StyledClapSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: ${props => props.theme.pxToRem(150)};
  justify-content: center;
  align-items: center;
`;
