import React from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';

interface Props {
  disabled?: boolean;
  checked?: boolean;
  testId?: string;
  className?: string;
  onChange?: (fieldName?: any, value?: any) => void;
  smallerIcon?: boolean;
  name?: string;
}

export const RadioButton: React.FC<Props> = ({
  checked = false,
  disabled = false,
  testId,
  className = '',
  onChange,
  smallerIcon = false,
  name,
}) => {
  return (
    <StyledRadio
      name={name}
      smallerIcon={smallerIcon}
      data-testid={testId}
      checked={checked}
      disabled={disabled}
      className={className}
      onClick={onChange}
    >
      <StyledMiddle
        smallerIcon={smallerIcon}
        checked={checked}
        disabled={disabled}
        data-testid={`${testId}-styled-middle`}
      />
    </StyledRadio>
  );
};

const StyledRadio = styled.div<ThemedStyledProps<Props, DefaultTheme>>`
  width: ${props => props.theme.pxToRem(props.smallerIcon ? 18 : 24)};
  height: ${props => props.theme.pxToRem(props.smallerIcon ? 18 : 24)};
  border-radius: 50%;
  background-color: ${(props: any) =>
    props.checked && !props.disabled
      ? props.theme.colors.primaryPurple
      : props.disabled
      ? props.theme.colors.neutralGrey6
      : props.theme.colors.neutralWhite};
  border: 1px solid
    ${(props: any) =>
      props.checked && !props.disabled ? props.theme.colors.primaryPurple : props.theme.colors.neutralGrey7};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover div:first-child {
    background-color: ${props => !props.disabled && !props.checked && props.theme.colors.neutralGrey6};
  }
`;

const StyledMiddle: any = styled.div<ThemedStyledProps<Props, DefaultTheme>>`
  width: ${(props: any) => (props.smallerIcon ? props.theme.pxToRem(8) : props.theme.pxToRem(10))};
  height: ${(props: any) => (props.smallerIcon ? props.theme.pxToRem(8) : props.theme.pxToRem(10))};
  border-radius: 50%;
  background-color: ${(props: any) =>
    props.disabled ? props.theme.colors.neutralGrey6 : props.theme.colors.neutralWhite};
`;
