import React from 'react';
import Carousel from 'react-multi-carousel';
import styled from 'styled-components';
import { DotProps } from 'react-multi-carousel/lib/types';
import { Container, Responsive } from 'semantic-ui-react';
import { VideoPlayer } from 'components/molecules';
import { Spacer } from 'components/atoms';
import { ExternalLinkStylesDark } from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_WHITE } from 'resources/images';

interface Props {
  modularBlock: any;
}

export const TestimonialCarousel: React.FC<Props> = ({ modularBlock }) => {
  const { onlyMobile, onlyTablet, onlyComputer } = Responsive;
  const { testimonials, headerText, mainText } = modularBlock;
  const testimonialSlides = testimonials.map((i: any) => {
    const data = { author: i.author, quote: i.quote, role: i.role };
    if (i.media?.image) return { ...data, imageUrl: `https:${i.media?.image}`, title: i.media?.title };
    if (i.media?.video?.embedCode) {
      return {
        ...data,
        videoOptions: {
          embedCode: i.media?.video?.embedCode,
        },
      };
    }
    if (i.media?.video?.brightcove) {
      return {
        ...data,
        videoOptions: {
          brightcove: {
            ...i.media?.video?.brightcove,
          },
        },
      };
    }
    return null;
  });

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: onlyComputer.minWidth as number,
      }, //  Number(onlyComputer.minWidth) etc. Prevents the slides to render
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: onlyTablet.maxWidth as number,
        min: onlyTablet.minWidth as number,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: onlyMobile.maxWidth as number,
        min: onlyMobile.minWidth as number,
      },
      items: 1,
    },
  };

  const StyledDots = ({ active, onClick }: DotProps) => {
    return (
      <li className={active ? 'active' : 'inactive'} onClick={onClick}>
        &#11044;
      </li>
    );
  };
  // tslint:disable-next-line no-shadowed-variable
  const HeaderMainText: React.FC<{ headerText: string; mainText: string }> = React.memo(({ headerText, mainText }) => {
    return (
      <>
        <StyledHeaderText dangerouslySetInnerHTML={{ __html: headerText }} />
        <StyledMainText dangerouslySetInnerHTML={{ __html: mainText }} />
      </>
    );
  });

  return (
    <>
      <StyledMediaContainer>
        <HeaderMainText headerText={headerText} mainText={mainText} />
        <StyledContainer>
          <Carousel
            arrows={false}
            responsive={responsive}
            showDots={true}
            customDot={<StyledDots />}
            renderDotsOutside={true}
          >
            {testimonialSlides.map((testimonialSlide: any, index: any) => {
              return (
                <Container key={index}>
                  <StyledMediaContainer>
                    {testimonialSlide.imageUrl ? (
                      <img src={testimonialSlide.imageUrl} alt="" />
                    ) : testimonialSlide.videoOptions ? (
                      <VideoPlayer
                        testId="testId"
                        video={testimonialSlide.videoOptions}
                        inTestimonialsCarousel={true}
                      />
                    ) : null}
                  </StyledMediaContainer>
                  <Spacer />

                  <StyledQuote dangerouslySetInnerHTML={{ __html: testimonialSlide.quote }} />
                  <StyledAttribution>
                    <span dangerouslySetInnerHTML={{ __html: testimonialSlide.author }} /> -{' '}
                    <span dangerouslySetInnerHTML={{ __html: testimonialSlide.role }} />
                  </StyledAttribution>
                  <StyledQuoteMarkRow>
                    <Spacer />

                    <h4 className="ui horizontal divider header">
                      <StyledQuoteMark>&nbsp;</StyledQuoteMark>{' '}
                    </h4>
                    <Spacer />
                  </StyledQuoteMarkRow>
                </Container>
              );
            })}
          </Carousel>
        </StyledContainer>
      </StyledMediaContainer>
    </>
  );
};

const StyledHeaderText = styled.div`
  font-weight: 500;
  text-align: center;
  top: ${props => props.theme.pxToRem(-25)};
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-left: ${props => props.theme.pxToRem(195)};
    margin-right: ${props => props.theme.pxToRem(196)};
    margin: 0 ${props => props.theme.pxToRem(250)} ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.pxToRem(32)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    height: ${props => props.theme.pxToRem(32)};
    object-fit: contain;
    font-size: ${props => props.theme.pxToRem(24)};
  }
`;
const StyledMainText = styled.div`
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
  top: ${props => props.theme.pxToRem(-17)};
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-left: ${props => props.theme.pxToRem(95)};
    margin-right: ${props => props.theme.pxToRem(96)};
    margin: ${props => props.theme.pxToRem(24)} 0 ${props => props.theme.pxToRem(40)};
    font-size: ${props => props.theme.pxToRem(18)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(16)};
    margin-right: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(97)};
    margin: ${props => props.theme.pxToRem(11)} ${props => props.theme.pxToRem(15)} 0;
    font-size: ${props => props.theme.pxToRem(18)};
  }
`;
const StyledMediaContainer = styled.div`
  width: 100%;
  position: relative;
`;

const StyledQuote = styled.div`
  width: ${props => props.theme.pxToRem(740)};
  margin: ${props => `0 auto ${props.theme.pxToRem(15)} auto`};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: 300;
  text-align: center;
  color: ${({ theme }) => theme.colors.primaryPurple};

  i,
  p,
  blockquote {
    font-style: normal;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
    width: 100%;
  }
`;

const StyledQuoteMarkRow = styled.div`
  margin: 0 auto;
  width: ${props => props.theme.pxToRem(740)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledQuoteMark = styled.span`
  position: relative;
  width: 100%;
  &::after {
    content: '”';
    position: absolute;
    font-size: ${props => props.theme.pxToRem(80)};
    color: ${({ theme }) => theme.colors.primaryPurple};
    letter-spacing: ${props => props.theme.pxToRem(4)};
    text-align: center;
    left: -15px;
    top: -5px;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    &::after {
      font-size: ${props => props.theme.pxToRem(40)};
      left: -5px;
      top: 0;
    }
  }
`;

const StyledAttribution = styled.div`
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.primaryPurple};

  p,
  span {
    display: inline;
  }
`;

const StyledContainer = styled.div`
  .react-multi-carousel-list {
    min-height: ${props => props.theme.pxToRem(100)}
    overflow: hidden;
    align-items: flex-start;
  }

  .react-multi-carousel-item {
    position: relative;
    bottom: 0;
    align-items: flex-start;
  }

  .react-multi-carousel-dot-list {
    margin-bottom: ${props => props.theme.pxToRem(20)};
    position: relative;
    margin-left: -${props => props.theme.pxToRem(12)};
  }

  .react-multi-carousel-dot-list > li {
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryPurple};
    opacity: 0.5;
    padding: ${props => props.theme.pxToRem(8)};
    font-size: ${props => props.theme.pxToRem(8)};
  }

  .react-multi-carousel-dot-list > li.active {
    opacity: 1;
    transform: scale(1.6);
  }

  img {
    width: ${props => props.theme.pxToRem(740)};
    height: ${props => props.theme.pxToRem(260)};
    display: block;
    object-fit: cover;
    margin: 0 auto;
    ${props => props.theme.mediaQueries.mobileOnly} {
      object-fit: contain;
      width: 100%;
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    .ui.divider {
      margin: ${props => props.theme.pxToRem(10)};
    }
  .rich-text-external-link {
    ${ExternalLinkStylesDark}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${IC_OPEN_IN_NEW_WHITE});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;
