import React from 'react';
import styled from 'styled-components';
import { Divider, Button, ButtonEnums, Link } from 'components/atoms';
import { generatePath } from 'react-router-dom';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
export interface FollowIndustryIF {
  id: string;
  name: string;
  slug: string;
}

export interface Props {
  industries: FollowIndustryIF[];
  userIndustriesPref: string[];
  toggleIndustryPref: (industryId: string, isOnEvent: boolean) => void;
}

export const ContentIndustriesFollow: React.FC<Props> = props => {
  const { industries, userIndustriesPref, toggleIndustryPref } = props;
  const handleToggleIndustry = React.useCallback(
    (industryId: string, isOnEvent: boolean) => {
      toggleIndustryPref(industryId, isOnEvent);
    },
    [toggleIndustryPref]
  );

  return (
    <>
      {industries.length !== 0 && (
        <>
          <StyledLargeHeading tabIndex={0}>Industries</StyledLargeHeading>
          {industries.map(industry => {
            const isActive: boolean = userIndustriesPref.includes(industry.id);
            const isOnEvent: boolean = !isActive;
            return (
              <StyledTopicLine key={industry.id} data-testid={`sub-${industry.id}`}>
                <StyledTopicLink to={generatePath(getPath(Routes.INDUSTRY_AGGS_PAGE), { industrySlug: industry.slug })}>
                  {industry.name}
                </StyledTopicLink>
                <StyledFollowButton
                  active={isActive}
                  testId={`follow-${industry.id}`}
                  onClick={handleToggleIndustry.bind(null, industry.id, isOnEvent)}
                  iconName={isActive ? 'check' : 'plus'}
                  iconPosition={ButtonEnums.iconPosition.left}
                  variant={ButtonEnums.variants.primaryToggle}
                  toggle
                  bordered
                >
                  {isActive ? 'Following' : 'Follow'}
                </StyledFollowButton>
              </StyledTopicLine>
            );
          })}
          <Divider />
        </>
      )}
    </>
  );
};

const StyledLargeHeading = styled.h3`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.l};
  margin: ${props => props.theme.pxToRem(20)} 0 ${props => props.theme.pxToRem(25)};
  line-height: 1.5;
`;

const StyledTopicLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledTopicLink = styled(Link)`
  margin: ${props => props.theme.pxToRem(8)} 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledFollowButton = styled(Button)`
  ${props => props.theme.mediaQueries.computerMin} {
    &.toggle:focus {
      border: ${props => props.theme.pxToRem(1)} solid
        ${props => (props.bordered ? props.theme.colors.primaryLightPurple : 'transparent')};
      background-color: ${props => props.theme.colors.primaryLightPurple} !important;
      color: ${props => props.theme.colors.neutralWhite} !important;
    }

    &.toggle.active:hover {
      border: ${props => props.theme.pxToRem(1)} solid
        ${props => (props.bordered ? props.theme.colors.interfaceRed : 'transparent')};
      background-color: ${props => props.theme.colors.interfaceRed} !important;
    }
  }
`;
