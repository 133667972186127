import React from 'react';

interface Layers {
  size?: number;
  color?: string;
  className?: string;
}

export const Layers = React.memo<Layers>(({ size = 24, color = '#FFFFFF', className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 2L2 7L12 12L22 7L12 2Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M2 17L12 22L22 17" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2 12L12 17L22 12" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
));
