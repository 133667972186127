import React from 'react';
import styled from 'styled-components';
import { Modal, ModalSizes } from 'components/atoms';

interface props {
  size: ModalSizes;
  heading: string;
  open: boolean;
  testId: string;
  centered: boolean;
}

export const StyledAddressModal: React.FC<props> = React.memo(({ size, heading, open, testId, centered, children }) => {
  return (
    <StyledModal size={size} heading={heading} open={open} testId={testId} centered={centered}>
      {children}
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  &&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
      ${props => `padding: ${props.theme.pxToRem(40)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(10)}`}
    }
    padding: ${props => props.theme.pxToRem(46)} ${props => props.theme.pxToRem(50)};
    margin-top: ${props => props.theme.pxToRem(137)} !important;
    margin-bottom: ${props => props.theme.pxToRem(137)} !important;

    width: ${props => props.theme.pxToRem(590)};
    border-radius: ${props => props.theme.pxToRem(2)};
    text-align: center;
    box-shadow: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(20)} 0 rgba(0, 0, 0, 0.3);

    div.header {
      font-weight: ${props => props.theme.fontWeights.light};
      line-height: 1.33;
      color: ${props => props.theme.colors.neutralGrey8};
    }
    div.content {
      color: ${props => props.theme.colors.neutralGrey8};
      padding: 0;
      margin-top: ${props => props.theme.pxToRem(7)};
      margin-bottom: ${props => props.theme.pxToRem(12)};
      line-height: 1.57;
      b {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;
