import { createAction } from 'redux-actions';
import { GET_EXEMPTION_REFERENCE } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';

export const setEducationalQualification: any = createAction(
  'exempReference/SET_EDUCATIONAL_QUALIFICATION_ID',
  (id: []) => () => {
    return {
      id,
    };
  }
);

export const fetchEducationalQualifications: any = createAction(
  'exempReference/FETCH_EDUCATIONAL_QUALIFICATION',
  (type: string) => () => {
    return request(GET_EXEMPTION_REFERENCE, { type });
  }
);
