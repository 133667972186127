import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { createSelector } from 'reselect';
import { push, createMatchSelector } from 'connected-react-router';
import { Dispatch } from 'redux';
import { PageToolkit, Props } from 'components/pages/PageToolkit/PageToolkit';
import { burgerExpandedSelector } from 'modules/layouts/selectors';
import { resetCurrentContentId, toggleLoginReload } from 'modules/layouts';
import { toggleUserShortcuts } from 'modules/user/actions';
import { userShortcutsUrlsSelector, isAuthSelector, userHasPrefSelector } from 'modules/user/selectors';
import {
  topicsHashSelector,
  subtopicsHashSelector,
  topicsSlugIdMapSelector,
  subtopicsSlugIdMapSelector,
} from 'modules/topics/selectors';
import {
  toolkitPageContentSelector,
  contentLoadingSelector,
  isContentFetchedSelector,
} from 'modules/content/selectors';
import { getToolkitPage } from 'modules/content/actions';
import { Routes } from 'constants/index';
import { getPath, emptyObject } from 'utils';

const mapActionCreators = (dispatch: Dispatch) => ({
  async fetchToolkitPageContent(slug: string): Promise<void> {
    await dispatch(getToolkitPage(slug)).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },

  resetCurrentContentId(): void {
    dispatch(resetCurrentContentId());
  },

  toggleUserShortcuts({ topicSlug, isOnEvent, subtopicSlug }: Common.ToggleUserShortcutsProps): void {
    dispatch(toggleUserShortcuts({ topicSlug, isOnEvent, subtopicSlug }));
  },

  toggleLoginReload(shouldReload: boolean): void {
    dispatch(toggleLoginReload(shouldReload));
  },
});

const mapStateToProps = (state: State.Root, ownProps: { match: { params: { slug: string } } }) => {
  const matchSelector: any = createMatchSelector(getPath(Routes.TOOLKIT_PAGE));

  const { match } = ownProps;
  const { slug } = match && match.params ? match.params : { slug: '' };

  const topicSlugSelector = createSelector(
    matchSelector,
    (matches: any): string => (matches && matches.params.topicSlug) || ''
  );

  const subtopicSlugSelector = createSelector(
    matchSelector,
    (matches: any): string => (matches && matches.params.subtopicSlug) || ''
  );

  const topicFromParamsSelector = createSelector(
    [topicsHashSelector, topicsSlugIdMapSelector, topicSlugSelector],
    (
      topicsHash: State.TopicsHash | null,
      topicsSlugIdMap: { [key: string]: string },
      slg: string | null
    ): State.Topic | null => {
      if (!slg || !topicsHash || !topicsSlugIdMap) return null;
      const id: string = topicsSlugIdMap[slg];
      if (!id) return null;
      const topicObj: State.Topic = topicsHash[id];
      return topicObj;
    }
  );

  const subtopicFromParamsSelector = createSelector(
    [subtopicsHashSelector, subtopicsSlugIdMapSelector, subtopicSlugSelector],
    (
      subtopicsHash: State.SubtopicsHash | null,
      subtopicsSlugIdMap: { [key: string]: string },
      slg: string | null
    ): State.Subtopic | null => {
      if (!slg || !subtopicsHash || !subtopicsSlugIdMap) return null;
      const id: string = subtopicsSlugIdMap[slg];
      if (!id) return null;
      const subtopicObj: State.Subtopic = subtopicsHash[id];
      return subtopicObj;
    }
  );

  const userHasShortcutSelector = createSelector(
    [userShortcutsUrlsSelector, toolkitPageContentSelector],
    (userShortcutsUrls: string[], toolkitPageContent: any): boolean => {
      if (
        !userShortcutsUrls ||
        !userShortcutsUrls.length ||
        !slug ||
        !toolkitPageContent ||
        !toolkitPageContent.title
      ) {
        return false;
      }
      // TODO BAD_SHORTCUTS this is a hack don't do this at home kids!
      // we should have a universal shortcut system, not this
      return userShortcutsUrls.includes(
        `${toolkitPageContent.title}${toolkitPageContent.contentCategory.slug || ''}/$TOOLKIT$/${slug}`
      );
    }
  );
  const burgerExpanded = burgerExpandedSelector(state);
  const topicSlug: string = topicSlugSelector(state);
  const subtopicSlug: string = subtopicSlugSelector(state);

  const topic: State.Topic | null = topicFromParamsSelector(state);
  const subtopic: State.Subtopic | null = subtopicFromParamsSelector(state);
  const { name: topicName = '' }: any = topic || emptyObject;
  const { id: subtopicId }: any = subtopic || emptyObject;

  const isAuth: boolean | null = isAuthSelector(state);
  const userHasPref: boolean = userHasPrefSelector(state);
  const isUserOnBoarded = Boolean(isAuth) && userHasPref;

  return {
    loading: contentLoadingSelector(state),
    isContentFetched: isContentFetchedSelector(state),
    topicSlug,
    topicName,
    subtopicSlug,
    subtopicId,
    hasShortcut: userHasShortcutSelector(state),
    toolkitPageContent: toolkitPageContentSelector(state),
    slug,
    burgerExpanded,
    isAuth,
    isUserOnBoarded,
  } as any;
};

const frontload = async (props: Props) => {
  if (props.slug) return props.fetchToolkitPageContent(props.slug);
};

const options = {
  onUpdate: true,
  _experimental_updateFunc: (prevProps: Props, newProps: Props) =>
    prevProps.slug !== newProps.slug || prevProps.isAuth !== newProps.isAuth,
};

export const PageToolkitContainer = connect(
  mapStateToProps,
  mapActionCreators
)(frontloadConnect(frontload, options)(PageToolkit));
