import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { HeaderCombined } from 'components/molecules/HeaderCombined/HeaderCombined';
import { isAuthSelector, isClientAdminSelector, isUserMemberSelector } from 'modules/user/selectors';
import {
  acrossLinksExpandedSelector,
  burgerExpandedSelector,
  userSettingsExpandedSelector,
  megaMobileNavHistoryLastSelector,
  searchModalExpandedSelector,
  orgProfileExpandedSelector,
} from 'modules/layouts/selectors';
import {
  setAcrossLinksExpanded,
  setBurgerExpand,
  setUserSettingsExpanded,
  backMegaMobileNavHistory,
  clearMegaMobileNavHistory,
  setSearchModalExpand,
  setOrgProfileExpanded,
} from 'modules/layouts';
import { megamenuSelector } from 'modules/header/selectors';
import { clearSuggestions } from 'modules/search/actions';
import { numberOfItemsInCartSelector } from 'modules/cart';
import { USE_SITE_BANNER } from 'modules/featureToggle/constants';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  navigate(path: string): void {
    dispatch(push(path));
  },

  setBurger(expanded: boolean): void {
    dispatch(setBurgerExpand(expanded));
  },

  setSearchModal(expanded: boolean): void {
    dispatch(setSearchModalExpand(expanded));
  },

  setUserSettingsExpanded(expanded: boolean): void {
    dispatch(setUserSettingsExpanded(expanded));
  },

  closeUserSettingsExpanded(): void {
    dispatch(setUserSettingsExpanded(false));
  },

  closeOrgProfileExpanded(): void {
    dispatch(setOrgProfileExpanded(false));
  },

  closeAcrossLinksExpanded(): void {
    dispatch(setAcrossLinksExpanded(false));
  },

  backMegaHistory(): void {
    dispatch(backMegaMobileNavHistory());
  },

  clearMegaHistory(): void {
    dispatch(clearMegaMobileNavHistory());
  },

  clearSuggestions(): void {
    dispatch(clearSuggestions());
  },
});

const mapStateToProps = (state: State.Root) => ({
  isAuth: isAuthSelector(state),
  isClientAdmin: isClientAdminSelector(state),
  isAdminPortal: isAdminPortalSelector(state),
  burgerExpanded: burgerExpandedSelector(state),
  searchModalExpanded: searchModalExpandedSelector(state),
  userSettingsExpanded: userSettingsExpandedSelector(state),
  orgProfileExpanded: orgProfileExpandedSelector(state),
  acrossLinksExpanded: acrossLinksExpandedSelector(state),
  megamenu: megamenuSelector(state),
  megaHistoryLast: megaMobileNavHistoryLastSelector(state),
  numberOfItemsInCart: numberOfItemsInCartSelector(state),
  useSiteBanner: getFeatureToggleByKeySelector(state, USE_SITE_BANNER),
  isUserMember: isUserMemberSelector(state),
});

export const HeaderCombinedContainer = connect(mapStateToProps, mapActionCreators)(HeaderCombined);
