// Added RegEx in formatting addressLine1 to prevent Invalid_Values error during checkout
// for country with no smarty street validation
export const formatValidAddress = (value: string | undefined) => value?.replace(/\/\//g, '/').replace(/[<>]/g, ' ');

export const manageValidationBypassState = (
  isBypassValidationChecked: boolean,
  removeValidationBypass: boolean,
  setRemoveValidationBypass: (modifier: boolean) => void
) => {
  // Do not remove validation bypass if admin has set bypass validation checkbox to true
  if (isBypassValidationChecked) setRemoveValidationBypass(false);
  // Remove validation if the bypass checkbox is unchecked and the value hasn't already been set
  else if (!isBypassValidationChecked && !removeValidationBypass) setRemoveValidationBypass(true);
};
