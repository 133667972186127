import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

interface Props {
  criteria: any;
}

export const EligibilityCriteriaCard: React.FC<Props> = ({ criteria }) => (
  <MarkDownWrapper>
    <ReactMarkdown children={criteria as any} />
    <StyledNote>
      Please note that meeting any one of these criteria is necessary to be considered eligible for the specified
      membership type.
    </StyledNote>
  </MarkDownWrapper>
);

const MarkDownWrapper = styled.div`
  &&&&& {
    padding: ${props => `${props.theme.pxToRem(25)}`};
    margin-bottom: ${props => `${props.theme.pxToRem(20)}`};
  }
`;

const StyledNote = styled.div`
  margin-top: ${props => `${props.theme.pxToRem(20)}`};
  color: ${props => `${props.theme.colors.primaryPurple}`};
  font-style: italic;
`;
