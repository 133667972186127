import {
  arrayIncludes,
  getFormattedBundleCardPrices,
  getVariantsFormatLabel,
  productTypeToLabel,
  subscriptionTypeToLabel,
} from 'utils';

import moment from 'moment-timezone';
import { Cart, Content, Orders, Product } from 'mxp-schemas';
import { ContentTypes, FilterActiveKeys, ProfileListSortByValues } from '../../constants';
import { MomentHelpers } from 'utils/MomentHelpers';
import { getMatchingLineItems } from '../cart/helpers';

export const isEventInNHours = (item: Common.ProductItemData, numberOfHours: number): boolean => {
  if (item.productType === Product.ProductType.CONFERENCE || item.productType === Product.ProductType.WEBCAST) {
    const timeDiff = MomentHelpers.getTimeDiffInHours(item.accessStartDate);

    if (timeDiff >= 48) {
      return false;
    }

    return timeDiff <= numberOfHours;
  }
  return false;
};

export const isActive = (item: Common.ProductItemData, now: Date): boolean => {
  if (
    (item.cartLineItemState === Orders.LineItemStates.CANCELLED_AND_DEPROVISIONED &&
      item.productType !== Product.ProductType.SECTION &&
      item.productType !== Product.ProductType.CREDENTIAL) ||
    item.cartLineItemState === Orders.LineItemStates.DEPROVISIONED_EXPIRED
  ) {
    return false;
  }
  if (item.isPhysicalProduct) {
    //  standingOrderStatus == null is a regular physical product with no standing order
    return item.standingOrderStatus === null ? true : item.standingOrderStatus === Product.StandingOrderStatus.ACTIVE;
  }

  if (item.subscriptionStatus === Product.ZuoraSubscriptionStatus.CANCELLED) {
    return false;
  }
  return isActiveCheckDates(item, now);
};

export const isActiveCheckDates = (item: Common.ProductItemData, now: Date): boolean => {
  const nowMoment = moment(now);
  const endDate = moment(item.zuoraTermEndDate || item.accessEndDate).add(1, 'days'); // adds 1 day to ignore hour time

  const isSameOrBefore = nowMoment.isSameOrBefore(endDate);

  // if access end date is already past then we would have to compare it on subscription status
  // for grace period, suspended credential and sections to be able to consider still as active product.
  if (
    (item.productType === Product.ProductType.CREDENTIAL || item.productType === Product.ProductType.SECTION) &&
    !isSameOrBefore
  ) {
    return item.subscriptionStatus === Product.ZuoraSubscriptionStatus.ACTIVE;
  }
  // if there is no end date assume its active
  return isSameOrBefore || !item.accessEndDate;
};

export const isInHistoryRangePurchases = (
  item: Common.ProductItemData,
  range: ProfileListSortByValues,
  now: Date
): boolean => {
  const nowMoment = moment(now).utc();
  const diffMonth = Math.abs(moment(item.orderDate).diff(nowMoment, 'months'));
  const monthsRange = getHistoryMonths(range);

  const out = diffMonth <= monthsRange || monthsRange === 0;
  return out;
};

export const isInHistoryRangeReceipts = (
  receipt: State.Invoice,
  range: ProfileListSortByValues,
  now: Date
): boolean => {
  const nowMoment = moment(now).utc();
  const diffMonth = Math.ceil(nowMoment.diff(receipt.invoiceDate, 'months', true));
  const monthsRange = getHistoryMonths(range);
  const out = diffMonth <= monthsRange || monthsRange === 0;
  return out;
};

export const getHistoryMonths = (range: ProfileListSortByValues) => {
  switch (range) {
    case ProfileListSortByValues.ALL_TIME:
      return 0;
    case ProfileListSortByValues.ONE_YEAR:
      return 12;
    case ProfileListSortByValues.SIX_MONTHS:
      return 6;
    case ProfileListSortByValues.THREE_MONTHS:
      return 3;
    default:
      return 0;
  }
};

export const matchActiveFilter = (item: Common.ProductItemData, filterKey: FilterActiveKeys, now: Date): boolean => {
  switch (filterKey) {
    case FilterActiveKeys.ACTIVE:
      return isActive(item, now);
    case FilterActiveKeys.INACTIVE:
      return !isActive(item, now);
  }
};

export const getProductFormatLabel = (productItem: Product.ProductItem): string => {
  const productType: Product.ProductType | '' = (productItem?.productType as Product.ProductType) || '';
  const productFormatLabel: string =
    productItem &&
    (productItem.subscriptionProductType && productType === Product.ProductType.SUBSCRIPTION
      ? productItem.subscriptionProductType.key === Product.SubscriptionProductType.WEBCAST
        ? subscriptionTypeToLabel(productItem.subscriptionProductType.key) || productItem.subscriptionProductType.label
        : productTypeToLabel(productType)
      : productItem.format && arrayIncludes([Product.ProductType.COURSE, Product.ProductType.PUBLICATION], productType)
      ? getVariantsFormatLabel(productItem)
      : '');
  return productFormatLabel || productTypeToLabel(productType);
};

export const getProductTypeLabel = (productItem: Product.ProductItem): string => {
  const productType = productItem?.productType as Product.ProductType;
  if (!productItem || !productType) return '';
  const { showQuantity, subscriptionProductType, format } = productItem;
  const isSubscription: boolean = productType === Product.ProductType.SUBSCRIPTION;
  const isSubscriptionCourse: boolean = subscriptionProductType?.key === Product.SubscriptionProductType.COURSE;
  const isCourse: boolean = productType === Product.ProductType.COURSE;
  const isExam: boolean = format?.key === Product.AvailableFormat.EXAM;
  const isDonation: boolean = productType === Product.ProductType.CONTRIBUTION;

  const productTypeLabel: string = (() => {
    if (isSubscription) {
      return showQuantity && !isSubscriptionCourse
        ? subscriptionTypeToLabel(Product.ProductTypeLabels.MAGAZINE_SUBSCRIPTION)
        : subscriptionTypeToLabel(subscriptionProductType.key) || subscriptionProductType.label;
    }
    if (format && isCourse) {
      return isExam ? format.label : productTypeToLabel(productType);
    }
    if (isDonation) {
      return productTypeToLabel(productType);
    }
    return '';
  })();

  return productTypeLabel || productTypeToLabel(productType);
};

export const mapProductBundleToContentCardItem = (
  bundleProduct: Product.ProductBundle,
  currency: string
): State.ContentCardItem => {
  const isProductType: boolean = Object.values(Product.ProductType).includes(
    bundleProduct.productType as Product.ProductType
  );

  return {
    id: bundleProduct.productId,
    title: bundleProduct.name,
    description: bundleProduct.description || '',
    contentfulType: isProductType ? ContentTypes.PRODUCT : '',
    contentType: {
      name: Product.PRODUCT_TYPES_NAMES[bundleProduct.productType],
      slug: isProductType ? ContentTypes.PRODUCT : '',
    },
    contentCategory: {
      name: isProductType ? Content.CategorySlugs.CPE_LEARNING : '',
      slug: isProductType ? Content.CategorySlugs.CPE_LEARNING : '',
    },
    image: {
      url: bundleProduct.variants?.[0]?.images?.[0]?.imageUrl || '',
      altText: '',
      caption: '',
      credit: '',
      title: '',
    },
    dateCreated: '',
    timeToConsume: null,
    slug: bundleProduct.slug || '',
    productType: [bundleProduct.productType],
    credits: bundleProduct.credits ? [parseInt(bundleProduct.credits, 10)] : [],
    availableFormat: [''],
    externalUrl: '',
    url: '',
    contentSource: '',
    startPrice: null,
    endPrice: null,
    prices: null,
    startDate: null,
    endDate: null,
    level: [],
    conferenceState: '',
    conferenceCity: '',
    roles: [],
    matchedRoles: [],
    isLocked: Content.ContentLockStatus.NO_LOCK,
    restrictionDetails: [],
    topicalSubscriptions: [],
    originProductType: [],
    bundleCardInfo: getBundleCardInfo(bundleProduct, currency),
  };
};

export const getBundleCardInfo = (bundleProduct: Product.ProductBundle, currency: string): State.BundleCardInfo => {
  const formattedBundlePrices = bundleProduct.bundlePrices
    ? getFormattedBundleCardPrices(bundleProduct.bundlePrices, bundleProduct.bundleDiscountPercent || 0, currency)
    : undefined;

  return {
    prices: formattedBundlePrices,
    bundleDiscountPercent: bundleProduct.bundleDiscountPercent,
    numberOfComponentProducts: bundleProduct.bundleProducts?.length,
  };
};

export const getVariants = (productItem: Product.ProductItem): Product.Variant[] =>
  productItem && productItem.variants ? productItem.variants : [];

export const getIsConference = (productItem: Product.ProductItem): boolean =>
  productItem?.productType === Product.ProductType.CONFERENCE;

export const getIsCourse = (productItem: Product.ProductItem): boolean =>
  productItem?.productType === Product.ProductType.COURSE;

export const getIsExam = (productItem: Product.ProductItem): boolean =>
  productItem?.productType === Product.ProductType.EXAM;

export const getIsProductTypeWithMultipleOptions = (productItem: Product.ProductItem): boolean =>
  [
    Product.ProductType.WEBCAST,
    Product.ProductType.CONFERENCE,
    Product.ProductType.EXAM,
    Product.ProductType.COURSE,
    Product.ProductType.PUBLICATION,
    Product.ProductType.CONTRIBUTION,
    Product.ProductType.EVENT,
  ].includes(productItem?.productType as Product.ProductType);

export const getFilteredVariants = (
  variants: Product.Variant[],
  isProductTypeWithVariants: boolean,
  isExam: boolean,
  isCourse: boolean,
  isConference: boolean,
  isContribution?: boolean
) => {
  const present = moment();

  // added new condition to check if the conference is not yet ended

  return isProductTypeWithVariants && isContribution
    ? variants.filter(variant => !variant.renewable)
    : isProductTypeWithVariants && (!isExam || !isCourse)
    ? variants
        .filter(
          v =>
            !v.startDateTime ||
            present.isBefore(v.startDateTime) ||
            (isConference &&
              (!v?.startDateTime || present.isAfter(v?.startDateTime)) &&
              (!v?.endDateTime || present.isBefore(v?.endDateTime)))
        )
        .sort((a: Product.Variant, b: Product.Variant) => {
          if (a.isMaster) return -1;

          if (b.isMaster) return 1;

          return a.startDateTime && b.startDateTime
            ? new Date(a.startDateTime).valueOf() - new Date(b.startDateTime).valueOf()
            : 0;
        })
    : variants;
};

// checks if bundle item is available, considering existing quantity in the cart

export const getVariantsBundleItemsWithOutOfStockCheck = (
  bundleProductItems: Product.ProductItem[] | undefined,
  cart: Cart.Cart
): State.BundleItemVariantOutOfStock[][] => {
  const out = (bundleProductItems || [])?.map((product: Product.ProductItem) => {
    const variants = getVariants(product);

    const processed = variants.map((variant: Product.Variant) => {
      const isPhysical = Boolean(variant?.isPhysicalProduct);
      const sku = variant?.sku || '';
      // skip checking cart we are sure its out of stock
      if (isPhysical && (variant?.availability?.availableQuantity || 0) < 1) {
        return { sku, isOutOfStock: true };
      }

      const matchingLineItems = getMatchingLineItems(product, cart, variant?.sku);
      if (isPhysical && Boolean(matchingLineItems?.length)) {
        const required = matchingLineItems[0].quantity + 1;
        const available = variant?.availability?.availableQuantity || 0;
        const isOutOfStockIncludingCart = required > available;

        return { sku, isOutOfStock: isOutOfStockIncludingCart };
      }
      return { sku, isOutOfStock: false };
    });
    return processed;
  });

  return out;
};

const getBundlePrices = (productPrices: any, currency: string) => {
  const prices = { ...productPrices };
  const subset = [currency].map(key => {
    if (!prices[key]) {
      // to provide 0 default value if a different currency is not added to CT
      return [key, { min: { amount: 0, currency }, max: { amount: 0, currency } }];
    }
    return [key, prices[key]];
  });

  return subset[0] ? subset[0][subset[0].length - 1] : null;
};

export const getFormattedBundlePrice = (bundlePrices: State.BundleMembershipPrices | undefined, currency: string) => {
  if (bundlePrices?.member?.min || bundlePrices?.nonMember?.min) {
    return bundlePrices;
  }
  return {
    member: getBundlePrices(bundlePrices?.member, currency),
    nonMember: getBundlePrices(bundlePrices?.nonMember, currency),
  };
};
