import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components';
import { Responsive } from 'semantic-ui-react';
import { ContentCard } from 'components/molecules/ContentCard/ContentCard';
import { ContentTypes } from 'constants/index';

interface Props {
  content: State.ContentCardItem[] | null;
  className?: string;
  heroCategoryCarousel?: boolean;
  editorialHeroCarousel?: boolean;
  transparentCarousel?: boolean;
  itemTestId?: string;
  testId?: string;
  isBgColorDark?: boolean;
  isCampaignCard?: boolean;
  isBundleCard?: boolean;
}

export const ContentCardsCarousel: React.FC<Props> = ({
  className = '',
  content,
  heroCategoryCarousel,
  editorialHeroCarousel,
  itemTestId,
  testId,
  isBgColorDark = false,
  isCampaignCard = false,
  isBundleCard = false,
  transparentCarousel,
}: Props) => {
  const { onlyMobile, onlyTablet, onlyComputer } = Responsive;

  const responsive = React.useMemo(
    () => ({
      largeDesktop: {
        breakpoint: { max: 5000, min: 1150 },
        items: 4,
      },
      desktop: {
        breakpoint: { max: 1150, min: Number(onlyComputer.minWidth) },
        items: 3,
        partialVisibilityGutter: 50,
      },
      tablet: {
        breakpoint: { max: Number(onlyTablet.maxWidth), min: Number(onlyTablet.minWidth) },
        items: 2,
        partialVisibilityGutter: 100,
      },
      mobile: {
        breakpoint: { max: Number(onlyMobile.maxWidth), min: Number(onlyMobile.minWidth) },
        items: 1,
        partialVisibilityGutter: 50,
      },
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!content || !content.length) return null;

  return (
    <StyledContainer isBgColorDark={isBgColorDark} className={className} data-testid={testId || 'carousel-holder'}>
      <Carousel arrows={false} partialVisible responsive={responsive}>
        {content.map((contentCardItem: State.ContentCardItem) => (
          <ContentCard
            isCampaignCard={isCampaignCard}
            isBgColorDark={isBgColorDark}
            isBundleCard={isBundleCard}
            key={contentCardItem.id}
            isInCarousel
            contentCardItem={contentCardItem}
            testid={
              itemTestId ||
              `related-content-${contentCardItem.contentType.slug === ContentTypes.PRODUCT ? 'product' : 'content'}-${
                contentCardItem.id
              }`
            }
            isHeroCard={heroCategoryCarousel}
            isEditorialHeroCard={editorialHeroCarousel}
            isTransparentCard={transparentCarousel}
            aria-label={`Carousel item with title ${contentCardItem.title}`}
          />
        ))}
      </Carousel>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ isBgColorDark?: boolean }>`
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.primaryDarkPurple)};
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  & > div {
    overflow: visible;
    overflow-y: scroll;
  }
  li {
    display: flex;
    padding: 0 10px;
  }
`;
