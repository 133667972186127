export { analyticsMiddleware } from './analytics';
export { triggerOnboardingMiddleware, triggerOnBoardingMetaData } from './triggerOnboarding';
export { loginRequiredMiddleware, loginRequiredMetaData } from './loginRequired';
export { refreshVergicMiddleware } from './refreshVergic';

export const addMetaData = (...metaDataObjects: any) => (...payload: any) => {
  const additionalMetaData = metaDataObjects.reduce((aggregation: any, metaDataObject: any) => {
    return { ...aggregation, ...metaDataObject };
  }, {});

  return {
    payload: [...payload],
    ...additionalMetaData,
  };
};
