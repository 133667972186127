import React from 'react';
import styled from 'styled-components';
import { Divider, Button, ButtonEnums, Link } from 'components/atoms';
import { generatePath } from 'react-router-dom';
import { Routes } from 'constants/index';
import { getPath } from 'utils';

export interface FollowSkillIF {
  id: string;
  name: string;
  slug: string;
}

export interface FollowSubskillIF extends FollowSkillIF {
  skillId: string;
  skillSlug: string;
}
export interface Props {
  skills: FollowSkillIF[];
  subskills: FollowSubskillIF[];
  userSkillsPref: string[];
  userSubskillsPref: string[];
  toggleSkillPref: (skillId: string, isOnEvent: boolean) => void;
  toggleSubskillPref: (skillId: string, subskillId: string, isOnEvent: boolean) => void;
}

export const ContentSkillsFollow: React.FC<Props> = props => {
  const { skills, subskills, userSkillsPref, userSubskillsPref, toggleSkillPref, toggleSubskillPref } = props;
  const handleToggleSkill = React.useCallback(
    (skillId: string, isOnEvent: boolean) => {
      toggleSkillPref(skillId, isOnEvent);
    },
    [toggleSkillPref]
  );

  return (
    <>
      {skills.length !== 0 && (
        <>
          <StyledLargeHeading tabIndex={0}>Skills</StyledLargeHeading>
          {skills.map(skill => {
            const isActive: boolean = userSkillsPref.includes(skill.id);
            const isOnEvent: boolean = !isActive;
            return (
              <StyledTopicLine key={skill.id} data-testid={`sub-${skill.id}`}>
                <StyledTopicLink to={generatePath(getPath(Routes.SKILL_AGGS_PAGE), { skillSlug: skill.slug })}>
                  {skill.name}
                </StyledTopicLink>
                <StyledFollowButton
                  active={isActive}
                  testId={`follow-${skill.id}`}
                  onClick={handleToggleSkill.bind(null, skill.id, isOnEvent)}
                  iconName={isActive ? 'check' : 'plus'}
                  iconPosition={ButtonEnums.iconPosition.left}
                  variant={ButtonEnums.variants.primaryToggle}
                  toggle
                  bordered
                >
                  {isActive ? 'Following' : 'Follow'}
                </StyledFollowButton>
              </StyledTopicLine>
            );
          })}
          <Divider />
        </>
      )}

      {subskills.length !== 0 && (
        <>
          <StyledLargeHeading tabIndex={0}>Subskills</StyledLargeHeading>
          {subskills.map(subskill => {
            const isActive: boolean = userSubskillsPref.includes(subskill.id);
            const isOnEvent: boolean = !isActive;
            return (
              <StyledTopicLine key={subskill.id} data-testid={`sub-${subskill.id}`}>
                <StyledTopicLink
                  to={generatePath(getPath(Routes.SKILL_AGGS_PAGE), {
                    skillSlug: subskill.skillSlug,
                    subskillSlug: subskill.slug,
                  })}
                >
                  {subskill.name}
                </StyledTopicLink>
                <StyledFollowButton
                  active={isActive}
                  testId={`follow-${subskill.id}`}
                  onClick={toggleSubskillPref.bind(null, subskill.skillId, subskill.id, isOnEvent)}
                  iconName={isActive ? 'check' : 'plus'}
                  iconPosition={ButtonEnums.iconPosition.left}
                  variant={ButtonEnums.variants.primaryToggle}
                  toggle
                  bordered
                >
                  {isActive ? 'Following' : 'Follow'}
                </StyledFollowButton>
              </StyledTopicLine>
            );
          })}
          <Divider />
        </>
      )}
    </>
  );
};

const StyledLargeHeading = styled.h3`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.l};
  margin: ${props => props.theme.pxToRem(20)} 0 ${props => props.theme.pxToRem(25)};
  line-height: 1.5;
`;

const StyledTopicLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledTopicLink = styled(Link)`
  margin: ${props => props.theme.pxToRem(8)} 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledFollowButton = styled(Button)`
  ${props => props.theme.mediaQueries.computerMin} {
    &.toggle:focus {
      border: ${props => props.theme.pxToRem(1)} solid
        ${props => (props.bordered ? props.theme.colors.primaryLightPurple : 'transparent')};
      background-color: ${props => props.theme.colors.primaryLightPurple} !important;
      color: ${props => props.theme.colors.neutralWhite} !important;
    }

    &.toggle.active:hover {
      border: ${props => props.theme.pxToRem(1)} solid
        ${props => (props.bordered ? props.theme.colors.interfaceRed : 'transparent')};
      background-color: ${props => props.theme.colors.interfaceRed} !important;
    }
  }
`;
