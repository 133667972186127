import React, { useState, RefObject } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import {
  BundleItemsSelectionInfo,
  BundleSummedPrices,
  productPageItemSelector,
  isBundleItemsPurchasedSelector,
  isBundleSelector,
  freeTrialEndDateSelector,
  productCurrencySelector,
  isUserFromRestrictedCountrySelector,
  productHasAvailablePreferredCurrencySelector,
} from 'modules/products/selectors';
import { productAccessConfigSelector, isAdminPortalSelector } from 'modules/app/selectors';
import { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Cart, Product, User as UserTypes } from 'mxp-schemas';
import { NotificationForAnonymousUser } from 'components/molecules/NotificationForAnonymousUser/NotificationForAnonymousUser';
import { OnlyDesktop, OnlyMobile, IconButton, Checkbox, Link, ButtonEnums, Button } from 'components/atoms';
import { PRODUCT_CART_STATUS, Routes } from 'constants/index';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { emptyObject, arrayIncludes, getPath, formatPrice, fromCentsFormat, MomentHelpers } from 'utils';
import { QuantityBox } from 'components/atoms/QuantityBox/QuantityBox';
import { PurchaseButton } from './PurchaseButton';
import { StickyFooter } from './StickyFooter';
import { notAvailableTxt, WarningLowStock, WarningNeedToAdjustQuantity, WarningOutOfStock } from './QuantityWarnings';
import { WebcastOrConferenceOrExamVariants } from './WebcastOrConferenceOrExamVariants';
import { StandingOrderNotification } from 'components/molecules/StandingOrderNotification/StandingOrderNotification';
import { BackToTop } from 'components/molecules/BackToTop/BackToTop';
import { CART_UPDATE_EVENT } from 'utils/Analytics/constants';
import { handleEvent } from 'utils/Analytics';
import {
  CartCompoundAnalytics,
  getOrderTypeAnalytics,
  isB2BMembershipType,
  isB2CMembershipType,
} from 'utils/Analytics/helpers';
import { ProductBundle } from 'mxp-schemas/src/types/products';
import { BundleItem } from './BundleItem';
import { ContributionVariants } from './ContributionVariants';
import { isUserMemberSelector } from 'modules/user/selectors';

interface Props {
  testId: string;
  hasAttestation: boolean;
  hasAttestationVariants: Product.Variant[];
  name: string;
  date: string;
  location: string;
  className?: string;
  variantDateTime?: Product.VariantDateTimeInfo[] | null;
  variantOptionPrice?: Product.VariantWithPriceInfo[] | null;
  pricing?: Product.PriceInfo;
  productButtonStatus: PRODUCT_CART_STATUS;
  cartLoading: boolean;
  cartTransitionError: boolean;
  selectedVariantSKU?: string;
  isAvailableForSale: boolean;
  isProductWithMultipleOptions: boolean;
  isAuth: boolean | null;
  priceRange: Product.PriceRange;
  thirdPartyLink?: string;
  isTransferableProductType: boolean;
  isProductTypeWithMultipleOptions: boolean;
  productId: string;
  isPhysicalProduct: boolean;
  sessions?: number | null;
  productChildrenInfoScrollTarget: RefObject<HTMLDivElement>;
  isMultiDayWebcast: boolean;
  isWebcastSeries: boolean;
  isPhSubscriptionMagazine: boolean;
  calculatedAccessDuration: string;
  alreadyHasAccessInfoMessage: string;
  standingOrderDiscount?: Product.StandingOrderDiscount;
  cartItemsList?: Cart.LineItem[];
  bundleBlockRefs?: Common.BundleElement[];
  bundleItemsSelectionInfo?: BundleItemsSelectionInfo[] | null;
  bundleSummedPrices?: BundleSummedPrices;
  isExistingCartItem?: boolean;
  isPhPrSubscription?: boolean;
  isSubscription?: boolean;
  isPremiumContent: boolean;
  premiumContentTo: string;
  pageButtonHandler: (
    productCartStatus: PRODUCT_CART_STATUS,
    productType: string,
    cartValidation: Common.CartValidation | null,
    setIsUpdateCartPrice?: (status: boolean) => void,
    isUpdateCartPrice?: boolean,
    lineItemId?: string
  ) => void;
  quantityHandler: (value: number) => void;
  onSelectionchange: (variantSKU: string, isFreeTrial: boolean) => void;
  onPriceChange: (selectedDonationPrice: string) => void;
  setHideHeader?: (hide: boolean) => void;
  setIsQuantityActive?: (hide: boolean) => void;
  updateCartItem?: (quantity: number, standingOrder: boolean, lineItemId: string, donationPrice?: string) => void;
  handleProvisionAccess: () => void;
  defaultProductVariantWithAttestationSku?: string | null;
  isProductHasUserPrice?: boolean | null;
  isFreeTrialSelected: boolean;
  userEmail?: string;
  fvsMembership?: UserTypes.MembershipIdsEnum[] | [];
  oktaId?: string;
  isUserSuspendedByEthics?: boolean;
}

export const PurchaseSummary: React.FC<Props> = ({
  testId,
  hasAttestation,
  hasAttestationVariants,
  name,
  date,
  location,
  className,
  variantDateTime,
  variantOptionPrice,
  pricing,
  productButtonStatus,
  cartLoading,
  cartTransitionError,
  selectedVariantSKU,
  isAvailableForSale,
  isProductWithMultipleOptions,
  isAuth,
  priceRange,
  thirdPartyLink,
  isTransferableProductType,
  isProductTypeWithMultipleOptions,
  productId,
  isPhysicalProduct,
  isPremiumContent,
  premiumContentTo,
  pageButtonHandler,
  quantityHandler,
  onSelectionchange,
  onPriceChange,
  setHideHeader,
  sessions,
  productChildrenInfoScrollTarget,
  isMultiDayWebcast,
  isWebcastSeries,
  isPhSubscriptionMagazine,
  calculatedAccessDuration,
  alreadyHasAccessInfoMessage,
  updateCartItem,
  cartItemsList,
  bundleBlockRefs,
  bundleItemsSelectionInfo,
  bundleSummedPrices,
  isExistingCartItem,
  isPhPrSubscription,
  isSubscription,
  handleProvisionAccess,
  defaultProductVariantWithAttestationSku,
  isProductHasUserPrice,
  isFreeTrialSelected,
  userEmail,
  fvsMembership,
  oktaId,
  isUserSuspendedByEthics,
}) => {
  const isUserFromRestrictedCountry = useSelector(isUserFromRestrictedCountrySelector);

  const mobileScrollableContentRef: any = React.useRef();
  const firstBundleItemRef: any = React.useRef();
  const isMember: boolean = useSelector(isUserMemberSelector);
  const productItem: Product.ProductItem = useSelector(productPageItemSelector);
  const freeTrialEndDate: string = useSelector(freeTrialEndDateSelector);
  const isBundleItemsPurchased: boolean = useSelector(isBundleItemsPurchasedSelector);
  const isBundle: boolean = useSelector(isBundleSelector);
  const { oplRedirectUrl, oplHidUrl }: State.ProductAccess = useSelector(productAccessConfigSelector);
  const isOPL: boolean = Boolean(
    isSubscription &&
      productItem?.subscriptionProductType?.key === Product.SubscriptionProductType.PUBLICATION &&
      (productItem.showQuantity === undefined || !productItem.showQuantity)
  );
  const singleOptionIsOnStock: boolean = Boolean(productItem?.variants[0].availability?.isOnStock);
  const singleOptionAvailableQuantity: number | undefined =
    !isProductTypeWithMultipleOptions && productItem?.showQuantity
      ? productItem.variants[0].availability?.availableQuantity
      : undefined;
  const previousSubscriptionType = productItem?.previousSubscriptionType || null;
  const isCourse: boolean = productItem?.productType === Product.ProductType.COURSE;
  const availabilityDuration = productItem.availability;
  const calculatedAvailabilityDuration = availabilityDuration
    ? MomentHelpers.daysToAvailabilityDuration(availabilityDuration)
    : '';

  const contributionVariants: Product.Variant[] = productItem?.variants?.filter(
    (variant: Product.Variant) => !variant.renewable
  );

  const productHasAvailablePreferredCurrency = useSelector(productHasAvailablePreferredCurrencySelector);
  const productHasNoMatchingPreferredCurrency = !productHasAvailablePreferredCurrency;

  const handleSelectionChange = (variantSKU: string, isFreeTrial?: boolean) => () => {
    onPriceChange('');
    onSelectionchange(variantSKU, isFreeTrial || false);
    setDiscountValue(0);
    setIsUpdateButtonsShown(false);
    const currentCartItem = cartItemsList?.find(
      (currentProduct: Cart.LineItem) => currentProduct?.variant?.sku === variantSKU
    );
    setVariantSku(variantSKU);
    const isStandingOrderChecked: boolean =
      (currentCartItem?.custom?.fields?.standing_order_discount as any) === Product.StandingOrderEligible.STANDING_NEW;
    setCartProductId(currentCartItem?.id);
    setIsChecked(isStandingOrderChecked ? isStandingOrderChecked : false);
    setTempIsChecked(isStandingOrderChecked ? isStandingOrderChecked : false);
    setQuantityValidation({
      ...quantityValidation,
      quantityValue: currentCartItem?.quantity || 1,
      isMoreThanAvailable: false,
    });
  };

  // Big assumption; master variant is index of 0!
  const {
    priceFull: initialPriceFull = null,
    discountAmount = null,
    priceFinal: initialPriceFinal = null,
  } = pricing || (emptyObject as any);

  const maxPrice: Product.FormattedPriceData | null =
    initialPriceFull && priceRange && priceRange.maxPrice
      ? {
          label: (initialPriceFull && initialPriceFull.label) || '',
          formattedPrice: priceRange.maxPrice.transformedPrice,
          amount: priceRange.maxPrice.price.amount,
          channel: priceRange.maxPrice.price.channel,
          currency: priceRange.maxPrice.price.currency,
        }
      : null;
  const priceFull: Product.FormattedPriceData | null =
    !isAuth && !selectedVariantSKU && priceRange && priceRange.maxPrice ? maxPrice : initialPriceFull;
  const priceFinal: Product.FormattedPriceData | null =
    !isAuth && !selectedVariantSKU && priceRange && priceRange.maxPrice
      ? {
          label: (initialPriceFinal && initialPriceFinal.label) || '',
          formattedPrice: priceRange.maxPrice.transformedPrice,
          amount: priceRange.maxPrice.price.amount,
          channel: priceRange.maxPrice.price.channel,
          currency: priceRange.maxPrice.price.currency,
        }
      : initialPriceFinal;
  const hasNonMemberPrice = pricing?.hasNonMemberPrice;
  const handlePageButton = () => {
    pageButtonHandler(
      productButtonStatus,
      productItem.productType,
      {
        quantityValue,
        isChecked,
      },
      setIsUpdateCartPrice,
      isUpdateCartPrice,
      lineItemId
    );
  };

  const passQuantityToHero = (value: number) => {
    quantityHandler(value);
  };

  interface InitialStateOfQuantity {
    quantityValue: number;
    isMoreThanAvailable: boolean;
  }

  const [isSummaryOpen, setSummaryOpen] = useState(false);
  const [availability, setAvailableQuantity] = useState(
    productItem?.variants?.[0].availability?.availableQuantity || 0
  );
  const isOutOfStock = availability === 0;
  const isLowOnStock = availability > 0 && availability <= 10;
  const [quantityValidation, setQuantityValidation] = useState<InitialStateOfQuantity>({
    quantityValue: 1,
    isMoreThanAvailable: false,
  });
  const [isChecked, setIsChecked] = useState(false);
  // Temporary store checkbox status, without calculate values, until update cart is called
  const [isTempChecked, setTempIsChecked] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);
  const [isUpdateButtonsShown, setIsUpdateButtonsShown] = useState(false);
  const [variantSku, setVariantSku] = useState<string | undefined>('');

  const { quantityValue, isMoreThanAvailable } = quantityValidation;

  const [tempQuantityValue, setTempQuantityValue] = useState<number>(quantityValue);
  const [productCartId, setCartProductId] = useState<string | undefined>('');

  const handleToggleSummary = React.useCallback(() => {
    if (setHideHeader) {
      setHideHeader(!isSummaryOpen);
    }
    setSummaryOpen(!isSummaryOpen);
  }, [isSummaryOpen, setSummaryOpen, setHideHeader]);

  const isWebcastOrConference: boolean = arrayIncludes(
    [Product.ProductType.WEBCAST, Product.ProductType.CONFERENCE],
    productItem.productType
  );

  const showPriceSummary =
    (!thirdPartyLink && thirdPartyLink === '' && !isTransferableProductType) || isTransferableProductType;

  const changeQuantity = (e: { target: HTMLInputElement }): void => {
    const value = parseInt(e.target.value, 10);
    setQuantityValidation({
      ...quantityValidation,
      quantityValue: value,
      isMoreThanAvailable: isPhysicalProduct && availability < value,
    });
    passQuantityToHero(value);
  };

  const standingOrderDiscountAmount = (value: number | undefined) => {
    const percentAsDecimal = value ? value / 100 : 0;
    if (isChecked) {
      setDiscountValue(percentAsDecimal);
    } else {
      setDiscountValue(0);
    }
  };

  const checkboxChecked = () => {
    setIsChecked(!isChecked);
    setTempIsChecked(!isChecked);
  };

  const tempCheckboxChecked = () => {
    setTempIsChecked(!isTempChecked);
    setIsUpdateButtonsShown(true);
  };

  const shippingCostString = 'Shipping cost ';
  const salesTaxesString = 'and applicable tax will be added later during checkout';
  const freeTrialRenewalNotice = React.useMemo(() => {
    if (variantOptionPrice?.some(v => v.isFreeTrial)) {
      let priceString = '';
      if (variantOptionPrice && selectedVariantSKU) {
        const fullVariant = variantOptionPrice.find(v => v.sku === selectedVariantSKU && !v.isFreeTrial);
        priceString = fullVariant?.priceInfo?.priceFinal?.formattedPrice || '';
      }
      return (
        `*Free trial${!isSubscription ? 's' : ''} will convert to full paid access ${
          isSubscription && availabilityDuration
            ? `(${calculatedAvailabilityDuration} auto-renewable subscription)`
            : ''
        } on the final day of the trial. ` +
        `Your default payment card will be charged ${priceString} plus sales tax unless you opt out before the free trial ends. `
      );
    }
  }, [variantOptionPrice, selectedVariantSKU, availabilityDuration, calculatedAvailabilityDuration, isSubscription]);

  const standingOrderValue = () => {
    const standingOrderData =
      productItem?.standingOrderDiscount &&
      (productItem?.standingOrderDiscount.new || productItem?.standingOrderDiscount.recurrent)
        ? productItem?.standingOrderDiscount
        : null;
    standingOrderDiscountAmount(standingOrderData?.new || standingOrderData?.recurrent);
    return (
      <StyledStandingOrder>
        {standingOrderData && (
          <>
            <StyledCheckbox
              checked={productButtonStatus === PRODUCT_CART_STATUS.IN_CART ? isTempChecked : isChecked}
              onChange={productButtonStatus === PRODUCT_CART_STATUS.IN_CART ? tempCheckboxChecked : checkboxChecked}
              height={18}
              width={18}
            />
            <StandingOrderNotification standingOrderDiscount={standingOrderData} />
          </>
        )}
      </StyledStandingOrder>
    );
  };

  const renderUpdateQuantity = (e: { target: HTMLInputElement }): void => {
    setIsUpdateButtonsShown(true);
    const value = parseInt(e.target.value, 10);

    setQuantityValidation({ ...quantityValidation, isMoreThanAvailable: isPhysicalProduct && availability < value });
    setTempQuantityValue(value);
    passQuantityToHero(value);
  };

  const updateCart = () => {
    const hasQuantityChanged: boolean = quantityValue !== tempQuantityValue;
    const hasStandingOrderChanged: boolean = isChecked !== isTempChecked;

    if (!isMoreThanAvailable) {
      setQuantityValidation({ ...quantityValidation, quantityValue: tempQuantityValue });
      setIsUpdateButtonsShown(false);
      setIsChecked(isTempChecked);
      if (updateCartItem && productItem && tempQuantityValue) {
        const id = productCartId || cartItemsList?.entries()?.next().value[1]?.id;
        if (id) updateCartItem(tempQuantityValue, isTempChecked, id);
      }
    }

    const isB2BMembership = isB2BMembershipType(productItem.productType);
    const isB2CMembership = isB2CMembershipType(productItem.productType);

    const payload: CartCompoundAnalytics = {
      category: [productItem.productType],
      id: [productId],
      bundleId: [],
      name: [productItem?.name || ''],
      price: [fromCentsFormat(priceFinal?.amount || 0)],
      quantity: [tempQuantityValue || 1],
      sku: [variantSku || ''],
      click: `button:product:standing-order-opt-in-out:${hasStandingOrderChanged ? 'Y' : 'N'}:quantity-update:${
        hasQuantityChanged ? 'Y' : 'N'
      }`,
      orderType: [
        getOrderTypeAnalytics({
          isStandingOrder: isTempChecked,
          isBundle: false,
          isFreeTrial: !!freeTrialEndDate,
          isB2BMembership,
          isB2CMembership,
        }),
      ],
      shippingCost: [],
      shippingLocation: [],
      shippingType: [],
      freeTrialEndDate: [freeTrialEndDate],
      productTax: [],
    };
    handleEvent(payload, CART_UPDATE_EVENT);
  };

  const cancelUpdateCart = () => {
    setQuantityValidation({
      ...quantityValidation,
      quantityValue,
      isMoreThanAvailable: Boolean(isPhysicalProduct && quantityValue && availability < quantityValue),
    });
    setTempQuantityValue(quantityValue);
    setIsUpdateButtonsShown(false);
    setTempIsChecked(isChecked);
    passQuantityToHero(quantityValue);
  };

  const renderQtyUpdateButtons = (): React.ReactNode => (
    <QtyUpdateButtonsContainer>
      <StyledCancelButton
        testId="cancel"
        variant={ButtonEnums.variants.secondary}
        size={ButtonEnums.sizes.medium}
        bordered
        onClick={cancelUpdateCart}
      >
        Cancel
      </StyledCancelButton>
      <StyledUpdateButton
        testId="update"
        variant={ButtonEnums.variants.primary}
        size={ButtonEnums.sizes.medium}
        bordered
        onClick={updateCart}
        disabled={isMoreThanAvailable || !tempQuantityValue}
      >
        {' '}
        Update cart
      </StyledUpdateButton>
    </QtyUpdateButtonsContainer>
  );

  const renderBundleItems = (mobile?: boolean): React.ReactNode => (
    <React.Fragment>
      {mobile && productItem?.bundleProducts && (
        <BundleItem
          key={`${productItem?.bundleProducts[0].name}${0}`}
          bundleItem={productItem?.bundleProducts[0]}
          bundleItemsSelectionInfo={bundleItemsSelectionInfo}
          isExistingCartItem={isExistingCartItem}
          index={0}
          bundleBlockRefs={bundleBlockRefs}
          mobile={mobile}
          handleToggleSummary={handleToggleSummary}
          firstBundleItemRef={firstBundleItemRef}
          isUserSuspendedByEthics={isUserSuspendedByEthics}
        />
      )}
      {productItem?.bundleProducts?.slice(mobile ? 1 : 0).map((bundleItem: ProductBundle, i) => (
        <BundleItem
          key={`${bundleItem.name}${mobile ? i + 1 : i}`}
          bundleItem={bundleItem}
          bundleItemsSelectionInfo={bundleItemsSelectionInfo}
          isExistingCartItem={isExistingCartItem}
          index={mobile ? i + 1 : i}
          bundleBlockRefs={bundleBlockRefs}
          mobile={mobile}
          handleToggleSummary={handleToggleSummary}
          isUserSuspendedByEthics={isUserSuspendedByEthics}
        />
      ))}
    </React.Fragment>
  );

  const renderManagePurchases = (): React.ReactNode => (
    <ManagePurchasesMessage>
      You have an active {isCourse ? 'course' : 'subscription'} for this product. You can always manage it in your{' '}
      <Link testId="purchase-summary-link-to-purchases-page" to={getPath(Routes.PROFILE_PURCHASES)}>
        Profile
      </Link>
    </ManagePurchasesMessage>
  );

  const hasBundleAllSelections =
    !isBundle ||
    Boolean(
      bundleItemsSelectionInfo?.reduce(
        (acc: boolean, item: any) => acc && Boolean(item.selectedSku) && !item.isOutOfStock && !item.isSubscribed,
        true
      )
    ) ||
    isExistingCartItem;

  const isContribution: boolean = productItem.productType === Product.ProductType.CONTRIBUTION;
  const disableNonMemberPurchase = !pricing?.hasNonMemberPrice && !isMember && !isBundle;

  const isMultiVariantSubscription: boolean =
    productItem.productType === Product.ProductType.SUBSCRIPTION &&
    !!variantOptionPrice?.length &&
    variantOptionPrice?.length > 1;

  const settingPrice = (price: Product.FormattedPriceData | null) => {
    const quantity = 1;

    const currentCartItem = cartItemsList?.find((currentProduct: Cart.LineItem) => currentProduct);
    const displayPrice = currentCartItem ? currentCartItem?.totalPrice?.centAmount : null;
    const priceAmount = displayPrice ? displayPrice : price?.amount;
    const priceCurrency = price?.currency;
    const finalPriceCents = fromCentsFormat(priceAmount ? priceAmount : 0);
    const totalPrice = finalPriceCents && quantity ? finalPriceCents * quantity : 0;
    const finalPrice = formatPrice(totalPrice, priceCurrency);
    const priceWithoutCurrencySign = finalPrice.replace(/[^0-9\.-]+/g, ''); // eslint-disable-line

    return priceWithoutCurrencySign;
  };
  const variantPrice = isUserSuspendedByEthics ? priceFull : priceFinal;

  const [currentVariantPrice, setVariantPrice] = useState({
    price: settingPrice(variantPrice),
  });

  const isContributionAmtZero: boolean = Number(currentVariantPrice?.price) < 1;

  interface EditHistoryInterface {
    sku: string;
    price: string;
    isAlreadyinCart: boolean;
    lineId: string;
  }

  const [editHistory, setEditHistory] = React.useState<EditHistoryInterface[]>([]);
  const [showButtons, setShowPriceUpdateButtons] = React.useState(false);
  const [isUpdateCartPrice, setIsUpdateCartPrice] = React.useState(false);
  const [lineItemId, setLineItemId] = React.useState('');

  const purchaseButtonDisabled = React.useMemo(
    () =>
      !hasBundleAllSelections ||
      (isPhysicalProduct && (isMoreThanAvailable || isOutOfStock || !quantityValue || isUpdateButtonsShown)) ||
      showButtons ||
      (isContribution && isContributionAmtZero) ||
      isBundleItemsPurchased ||
      productHasNoMatchingPreferredCurrency ||
      disableNonMemberPurchase,
    [
      hasBundleAllSelections,
      isPhysicalProduct,
      isMoreThanAvailable,
      isOutOfStock,
      quantityValue,
      isUpdateButtonsShown,
      showButtons,
      isContribution,
      isContributionAmtZero,
      isBundleItemsPurchased,
      productHasNoMatchingPreferredCurrency,
      disableNonMemberPurchase,
    ]
  );

  const updateContribPrice = (variantSKU: string, inputPrice: any, isFromInput: boolean = false) => {
    const filteredArr = editHistory?.filter((item: any) => item?.sku !== selectedVariantSKU);
    setEditHistory(filteredArr);

    const currentCartItem = cartItemsList?.find(
      (currentProduct: Cart.LineItem) => currentProduct?.variant?.sku === variantSKU
    );
    const result = currentCartItem ? true : false;
    setIsUpdateCartPrice(result);
    setLineItemId(currentCartItem?.id || '');

    const newObj: EditHistoryInterface = {
      sku: selectedVariantSKU || '',
      price: inputPrice || '',
      isAlreadyinCart: result,
      lineId: currentCartItem?.id || '',
    };

    setEditHistory(history => [...editHistory, newObj]);
    return result;
  };

  const isAdmin = useSelector(isAdminPortalSelector);
  const isImpersonated = Boolean(isAuth) && isAdmin;
  const renderContributionVariants = (): React.ReactNode => (
    <ContributionVariants
      variants={contributionVariants}
      product={productItem}
      selectedVariantSKU={selectedVariantSKU}
      handleSelectionChange={handleSelectionChange}
      hasAttestation={hasAttestation}
      hasAttestationVariants={hasAttestationVariants}
      priceFinal={!isUserSuspendedByEthics ? priceFinal : priceFull}
      onPriceChange={onPriceChange}
      showButtons={showButtons}
      setShowPriceUpdateButtons={setShowPriceUpdateButtons}
      settingPrice={settingPrice}
      currentVariantPrice={currentVariantPrice}
      setVariantPrice={setVariantPrice}
      updateContribPrice={updateContribPrice}
      editHistory={editHistory}
      setEditHistory={setEditHistory}
      setIsUpdateCartPrice={setIsUpdateCartPrice}
      setLineItemId={setLineItemId}
      defaultProductVariantWithAttestationSku={defaultProductVariantWithAttestationSku}
      isProductHasUserPrice={isProductHasUserPrice}
      isAuth={isAuth}
      isMember={isMember}
    />
  );
  return (
    <>
      <OnlyDesktop>
        <div className={className}>
          <StyledDiv data-testid={testId} className={className}>
            <StyledTitle data-testid="purchase-summary-title">{name}</StyledTitle>
            {isWebcastOrConference && !isMultiDayWebcast && !isWebcastSeries && (
              <>
                <StyledSubtitle data-testid="purchase-summary-date">{date}</StyledSubtitle>
                <StyledSubtitle data-testid="purchase-summary-location">{location}</StyledSubtitle>
              </>
            )}
            {!isAvailableForSale ? (
              <NotAvailableNoteStyled data-testid={'not-available-label'}>{notAvailableTxt}</NotAvailableNoteStyled>
            ) : (
              <>
                <HorizontalDivider />

                {!isProductTypeWithMultipleOptions &&
                  productItem?.availability &&
                  productItem?.availability > 0 &&
                  productItem.showQuantity && (
                    <>
                      <StyledFlexDiv>
                        <StyledContainer>
                          <StyledTitleSmall data-testid="purchase-summary-title">{name}</StyledTitleSmall>
                          <StyledQuantityDivRight>
                            <QuantityBox
                              availableQuantity={availability}
                              testId="purchase-summary--single-variant"
                              handleProductQtyChange={
                                productButtonStatus === PRODUCT_CART_STATUS.NOT_IN_CART
                                  ? changeQuantity
                                  : renderUpdateQuantity
                              }
                              productQty={
                                PRODUCT_CART_STATUS.IN_CART && isUpdateButtonsShown ? tempQuantityValue : quantityValue
                              }
                            />
                          </StyledQuantityDivRight>
                        </StyledContainer>
                        {Boolean(
                          singleOptionAvailableQuantity &&
                            singleOptionAvailableQuantity <= 10 &&
                            isPhysicalProduct &&
                            !isMoreThanAvailable
                        ) && <WarningLowStock availableQuantity={singleOptionAvailableQuantity} />}
                        {singleOptionIsOnStock &&
                          isMoreThanAvailable &&
                          isPhysicalProduct &&
                          !isProductTypeWithMultipleOptions && (
                            <WarningNeedToAdjustQuantity availableQuantity={singleOptionAvailableQuantity} />
                          )}
                        {!singleOptionIsOnStock && (
                          <WarningOutOfStock productInterestFormUrl={productItem.variants[0].productInterestFormUrl} />
                        )}
                        {isUpdateButtonsShown && renderQtyUpdateButtons()}
                      </StyledFlexDiv>

                      <HorizontalDivider />
                    </>
                  )}

                {isContribution && renderContributionVariants()}

                {(isProductTypeWithMultipleOptions || isWebcastSeries || isMultiVariantSubscription) &&
                  !isContribution && (
                    <WebcastOrConferenceOrExamVariants
                      productType={productItem.productType as Product.ProductType}
                      isProductWithMultipleOptions={isProductWithMultipleOptions}
                      variantDateTime={variantDateTime}
                      variantOptionPrice={variantOptionPrice}
                      handleSelectionChange={handleSelectionChange}
                      selectedVariantSKU={selectedVariantSKU}
                      thirdPartyLink={thirdPartyLink}
                      sessions={sessions}
                      isMultiDayWebcast={isMultiDayWebcast}
                      productChildrenInfoScrollTarget={productChildrenInfoScrollTarget}
                      isWebcastSeries={isWebcastSeries}
                      calculatedAccessDuration={calculatedAccessDuration}
                      handleToggleSummary={handleToggleSummary}
                      changeQuantity={changeQuantity}
                      quantityValue={quantityValue}
                      isLowOnStock={isLowOnStock}
                      setAvailableQuantity={setAvailableQuantity}
                      isMoreThanAvailable={isMoreThanAvailable}
                      standingOrderValue={standingOrderValue}
                      renderQtyUpdateButtons={renderQtyUpdateButtons}
                      renderUpdateQuantity={renderUpdateQuantity}
                      isUpdateButtonsShown={isUpdateButtonsShown}
                      productButtonStatus={productButtonStatus}
                      tempQuantityValue={tempQuantityValue}
                      isFreeTrialSelected={isFreeTrialSelected}
                      subscriptionProductType={productItem.subscriptionProductType}
                      previousSubscriptionType={previousSubscriptionType}
                      isUserSuspendedByEthics={isUserSuspendedByEthics}
                    />
                  )}

                {isBundle && bundleBlockRefs?.length && renderBundleItems()}
                {showPriceSummary && !isContribution && (
                  <>
                    {isBundle ? (
                      <BundlePriceDetails
                        bundleSummedPrices={bundleSummedPrices}
                        quantityValue={quantityValue}
                        isAuth={isAuth}
                        onlyFinal={isTransferableProductType && !!thirdPartyLink}
                        isPhysicalProduct={isPhysicalProduct}
                        thirdPartyLink={thirdPartyLink}
                        standingOrderDiscountAmount={discountValue}
                        hasBundleAllSelections={Boolean(hasBundleAllSelections)}
                        isUserSuspendedByEthics={isUserSuspendedByEthics}
                      />
                    ) : (
                      <PriceDetails
                        priceFinal={priceFinal}
                        priceFull={priceFull}
                        quantityValue={quantityValue}
                        discountAmount={discountAmount}
                        isAuth={isAuth}
                        onlyFinal={isTransferableProductType && !!thirdPartyLink}
                        isPhysicalProduct={isPhysicalProduct}
                        thirdPartyLink={thirdPartyLink}
                        standingOrderDiscountAmount={discountValue}
                        hasNonMemberPrice={hasNonMemberPrice}
                        isUserSuspendedByEthics={isUserSuspendedByEthics}
                      />
                    )}
                  </>
                )}
                {!isAuth && !isContribution && <StyledNotificationForAnonymousUser isPurchaseSummary />}
                {alreadyHasAccessInfoMessage && (
                  <>
                    <HorizontalDividerMargins />
                    <AlreadyHasAccessMessage> {alreadyHasAccessInfoMessage} </AlreadyHasAccessMessage>
                  </>
                )}
                {productItem?.isSubscribed && (
                  <>
                    <HorizontalDividerMargins />
                    {renderManagePurchases()}
                  </>
                )}

                <StyledButtonWrapper>
                  {(!isContribution ||
                    (isContribution && isProductHasUserPrice && !hasAttestation) ||
                    (isContribution && isProductHasUserPrice && hasAttestation && (isMember || isImpersonated))) && (
                    <PurchaseButton
                      isUserFromRestrictedCountry={isUserFromRestrictedCountry}
                      thirdPartyLink={thirdPartyLink}
                      isTransferableProductType={isTransferableProductType}
                      handlePageButton={handlePageButton}
                      productButtonStatus={isUpdateCartPrice ? PRODUCT_CART_STATUS.NOT_IN_CART : productButtonStatus}
                      cartLoading={cartLoading}
                      selectedVariantSKU={selectedVariantSKU}
                      cartTransitionError={cartTransitionError}
                      productType={productItem.productType}
                      productId={productId}
                      disabled={purchaseButtonDisabled}
                      isPhysicalProduct={isPhysicalProduct}
                      handleProvisionAccess={handleProvisionAccess}
                      isPurchased={productItem?.isSubscribed}
                      isPremiumContent={isPremiumContent}
                      premiumContentTo={premiumContentTo}
                      isOPL={isOPL}
                      oplRedirectUrl={oplRedirectUrl}
                      oplHidUrl={oplHidUrl}
                      userEmail={userEmail}
                      fvsMembership={fvsMembership}
                      oktaId={oktaId}
                    />
                  )}
                </StyledButtonWrapper>
              </>
            )}
          </StyledDiv>
          {isAvailableForSale && !isContribution && (
            <>
              <StyledAdditionalInfo>
                <StyledLink
                  testId="purchase-summary-continue-to-shipping-cost"
                  to={getPath(Routes.SHIPPING_DELIVERY)}
                  target="blank"
                >
                  {shippingCostString}
                </StyledLink>
                {salesTaxesString}
              </StyledAdditionalInfo>
              <StyledFreeTrialRenewalNotice>{freeTrialRenewalNotice}</StyledFreeTrialRenewalNotice>
            </>
          )}
        </div>
      </OnlyDesktop>
      <OnlyMobile>
        {isSummaryOpen && (
          <SummaryContainerMobile>
            <MobileSummaryHead>
              <MobileSummaryHeadTexts>
                <MobileSummaryHeadTitle data-testid="purchase-summary-name">{name}</MobileSummaryHeadTitle>
                {isWebcastOrConference && !isMultiDayWebcast && (
                  <>
                    <MobileSummaryHeadTitle data-testid="purchase-summary-date">{date}</MobileSummaryHeadTitle>
                    <MobileSummaryHeadTitle data-testid="purchase-summary-location">{location}</MobileSummaryHeadTitle>
                  </>
                )}
              </MobileSummaryHeadTexts>
              <StyledIconButton onClick={handleToggleSummary}>
                <IconClose />
              </StyledIconButton>
            </MobileSummaryHead>
            <HorizontalDividerPadded />
            {productItem?.isSubscribed && renderManagePurchases()}
            {(isProductTypeWithMultipleOptions || isPhPrSubscription || isMultiVariantSubscription) &&
              !isContribution && (
                <WebcastOrConferenceOrExamVariants
                  productType={productItem.productType as Product.ProductType}
                  isProductWithMultipleOptions={isProductWithMultipleOptions}
                  variantDateTime={variantDateTime}
                  variantOptionPrice={variantOptionPrice}
                  handleSelectionChange={handleSelectionChange}
                  selectedVariantSKU={selectedVariantSKU}
                  thirdPartyLink={thirdPartyLink}
                  sessions={sessions}
                  isMultiDayWebcast={isMultiDayWebcast}
                  productChildrenInfoScrollTarget={productChildrenInfoScrollTarget}
                  isWebcastSeries={isWebcastSeries}
                  calculatedAccessDuration={calculatedAccessDuration}
                  handleToggleSummary={handleToggleSummary}
                  changeQuantity={changeQuantity}
                  quantityValue={quantityValue}
                  isLowOnStock={isLowOnStock}
                  setAvailableQuantity={setAvailableQuantity}
                  isMoreThanAvailable={isMoreThanAvailable}
                  standingOrderValue={standingOrderValue}
                  renderQtyUpdateButtons={renderQtyUpdateButtons}
                  renderUpdateQuantity={renderUpdateQuantity}
                  isUpdateButtonsShown={isUpdateButtonsShown}
                  productButtonStatus={productButtonStatus}
                  tempQuantityValue={tempQuantityValue}
                  isPhPrSubscription={isPhPrSubscription}
                  isPhSubscriptionMagazine={isPhSubscriptionMagazine}
                  isFreeTrialSelected={isFreeTrialSelected}
                  subscriptionProductType={productItem.subscriptionProductType}
                  previousSubscriptionType={previousSubscriptionType}
                />
              )}

            <ScrollableMobileContents isContribution={isContribution} ref={mobileScrollableContentRef}>
              {isContribution && renderContributionVariants()}
              {isBundle && bundleBlockRefs?.length && renderBundleItems(true)}
              {showPriceSummary && !isContribution && (
                <>
                  {isBundle ? (
                    <BundlePriceDetails
                      bundleSummedPrices={bundleSummedPrices}
                      quantityValue={quantityValue}
                      isAuth={isAuth}
                      onlyFinal={isTransferableProductType && !!thirdPartyLink}
                      isPhysicalProduct={isPhysicalProduct}
                      thirdPartyLink={thirdPartyLink}
                      standingOrderDiscountAmount={discountValue}
                      hasBundleAllSelections={Boolean(hasBundleAllSelections)}
                      isUserSuspendedByEthics={isUserSuspendedByEthics}
                    />
                  ) : (
                    <PriceDetails
                      priceFinal={priceFinal}
                      priceFull={priceFull}
                      quantityValue={quantityValue}
                      discountAmount={discountAmount}
                      isAuth={isAuth}
                      onlyFinal={isTransferableProductType && !!thirdPartyLink}
                      isPhysicalProduct={isPhysicalProduct}
                      thirdPartyLink={thirdPartyLink}
                      standingOrderDiscountAmount={discountValue}
                      hasNonMemberPrice={hasNonMemberPrice}
                      isUserSuspendedByEthics={isUserSuspendedByEthics}
                    />
                  )}
                </>
              )}
              {!isAuth && !isContribution && <StyledNotificationForAnonymousUser isPurchaseSummary />}
              {alreadyHasAccessInfoMessage && (
                <>
                  <HorizontalDividerPaddedMargins />
                  <AlreadyHasAccessMessage> {alreadyHasAccessInfoMessage} </AlreadyHasAccessMessage>
                </>
              )}
              {isSummaryOpen && !isContribution && (
                <>
                  <StyledAdditionalInfoMobile>
                    <StyledLink
                      testId="purchase-summary-continue-to-shipping-cost"
                      to={getPath(Routes.SHIPPING_DELIVERY)}
                      target="blank"
                    >
                      {shippingCostString}
                    </StyledLink>
                    {salesTaxesString}
                  </StyledAdditionalInfoMobile>
                  <StyledFreeTrialRenewalNoticeMobile>{freeTrialRenewalNotice}</StyledFreeTrialRenewalNoticeMobile>
                </>
              )}
            </ScrollableMobileContents>
            <BackToTop
              modalOptions={{
                scrollPositionRef: mobileScrollableContentRef,
                scrollIntoRef: firstBundleItemRef,
                scrollBelowRef: firstBundleItemRef,
              }}
            />
          </SummaryContainerMobile>
        )}

        <StickyFooter
          priceFull={priceFull}
          discountAmount={discountAmount}
          priceFinal={priceFinal}
          handleToggleSummary={handleToggleSummary}
          isSummaryOpen={isSummaryOpen}
          cartLoading={cartLoading}
          cartTransitionError={cartTransitionError}
          handlePageButton={handlePageButton}
          selectedVariantSKU={selectedVariantSKU}
          productButtonStatus={isUpdateCartPrice ? PRODUCT_CART_STATUS.NOT_IN_CART : productButtonStatus}
          thirdPartyLink={thirdPartyLink}
          isTransferableProductType={isTransferableProductType}
          productType={productItem.productType}
          productId={productId}
          isPhysicalProduct={isPhysicalProduct}
          purchaseButtonDisabled={purchaseButtonDisabled}
          isBundle={isBundle}
          bundleSummedPrices={bundleSummedPrices}
          handleProvisionAccess={handleProvisionAccess}
          isPurchased={productItem?.isSubscribed}
          currentVariantPrice={currentVariantPrice?.price}
          isProductHasUserPrice={isProductHasUserPrice}
          isPremiumContent={isPremiumContent}
          premiumContentTo={premiumContentTo}
          isUserSuspendedByEthics={isUserSuspendedByEthics}
        />
      </OnlyMobile>
    </>
  );
};

const PriceDetails: React.FC<{
  priceFull: Product.FormattedPriceData | null;
  discountAmount: Product.FormattedPriceData | null;
  priceFinal: Product.FormattedPriceData | null;
  isAuth: boolean | null;
  onlyFinal: boolean;
  isPhysicalProduct?: boolean;
  thirdPartyLink?: string;
  quantityValue: number | null;
  standingOrderDiscountAmount?: number;
  hasNonMemberPrice?: boolean;
  isUserSuspendedByEthics?: boolean;
}> = ({
  priceFull,
  discountAmount,
  priceFinal,
  isAuth,
  onlyFinal,
  isPhysicalProduct,
  thirdPartyLink,
  quantityValue,
  standingOrderDiscountAmount,
  hasNonMemberPrice,
  isUserSuspendedByEthics,
}) => {
  return (
    <PricingWrap>
      {!onlyFinal && isAuth && priceFull && discountAmount && hasNonMemberPrice && !isUserSuspendedByEthics && (
        <PriceRow
          testId="non-member"
          priceData={priceFull}
          quantityValue={quantityValue}
          standingOrderDiscountAmount={standingOrderDiscountAmount}
        />
      )}
      {!onlyFinal && !isAuth && priceFull && hasNonMemberPrice && !isUserSuspendedByEthics && (
        <PriceRow
          testId="non-member"
          priceData={priceFull}
          discounted
          quantityValue={quantityValue}
          standingOrderDiscountAmount={standingOrderDiscountAmount}
        />
      )}
      {!onlyFinal && isAuth && discountAmount && hasNonMemberPrice && !isUserSuspendedByEthics && (
        <PriceRow
          testId="discount-member"
          priceData={discountAmount}
          discounted
          quantityValue={quantityValue}
          standingOrderDiscountAmount={standingOrderDiscountAmount}
        />
      )}
      {priceFinal && !isUserSuspendedByEthics && (
        <PriceRow
          testId="no-tax"
          priceData={priceFinal}
          final
          isPhysicalProduct={isPhysicalProduct}
          thirdPartyLink={thirdPartyLink}
          quantityValue={quantityValue}
          standingOrderDiscountAmount={standingOrderDiscountAmount}
        />
      )}
      {isUserSuspendedByEthics && priceFull && priceFinal && (
        <PriceRow
          testId="no-tax"
          priceData={priceFull}
          final
          isPhysicalProduct={isPhysicalProduct}
          thirdPartyLink={thirdPartyLink}
          quantityValue={quantityValue}
          standingOrderDiscountAmount={standingOrderDiscountAmount}
          isUserSuspendedByEthics={isUserSuspendedByEthics}
          priceFinalIfSuspended={priceFull}
        />
      )}
    </PricingWrap>
  );
};

const BundlePriceDetails: React.FC<{
  bundleSummedPrices: BundleSummedPrices | undefined;
  isAuth: boolean | null;
  onlyFinal: boolean;
  isPhysicalProduct?: boolean;
  thirdPartyLink?: string;
  quantityValue: number | null;
  standingOrderDiscountAmount?: number;
  hasBundleAllSelections: boolean;
  isUserSuspendedByEthics?: boolean;
}> = ({
  bundleSummedPrices,
  isAuth,
  onlyFinal,
  isPhysicalProduct,
  thirdPartyLink,
  quantityValue,
  standingOrderDiscountAmount,
  hasBundleAllSelections,
  isUserSuspendedByEthics,
}) => {
  const { priceFull, userRolesDiscounts, bundleDiscount, priceFinal, suspendedUserPriceFinal } =
    bundleSummedPrices || (emptyObject as BundleSummedPrices);
  const hasRolesDiscounts = Boolean(userRolesDiscounts?.length);
  return (
    <PricingWrap>
      {!onlyFinal && isAuth && priceFull && !isUserSuspendedByEthics && (
        <PriceRow
          testId="non-member"
          priceData={priceFull}
          quantityValue={quantityValue}
          standingOrderDiscountAmount={standingOrderDiscountAmount}
        />
      )}
      {!onlyFinal && !isAuth && priceFull && (
        <PriceRow
          testId="non-member"
          priceData={priceFull}
          discounted
          quantityValue={quantityValue}
          standingOrderDiscountAmount={standingOrderDiscountAmount}
        />
      )}
      {!onlyFinal && isAuth && hasRolesDiscounts && userRolesDiscounts?.length && !isUserSuspendedByEthics && (
        <>
          {userRolesDiscounts.map((pricing: Product.FormattedPriceData) => (
            <PriceRow
              key={pricing.label}
              testId="discount-member"
              priceData={pricing}
              discounted
              quantityValue={quantityValue}
              standingOrderDiscountAmount={standingOrderDiscountAmount}
              usePlaceholder={!hasBundleAllSelections}
            />
          ))}
        </>
      )}
      {!onlyFinal && bundleDiscount && (
        <PriceRow
          testId="discount-member"
          priceData={bundleDiscount}
          discounted
          quantityValue={quantityValue}
          standingOrderDiscountAmount={standingOrderDiscountAmount}
          usePlaceholder={!hasBundleAllSelections}
        />
      )}
      {priceFinal && !isUserSuspendedByEthics && (
        <PriceRow
          testId="no-tax"
          priceData={priceFinal}
          final
          isPhysicalProduct={isPhysicalProduct}
          thirdPartyLink={thirdPartyLink}
          quantityValue={quantityValue}
          standingOrderDiscountAmount={standingOrderDiscountAmount}
          usePlaceholder={!hasBundleAllSelections}
        />
      )}
      {suspendedUserPriceFinal && isUserSuspendedByEthics && (
        <PriceRow
          testId="no-tax"
          priceData={suspendedUserPriceFinal}
          final
          isPhysicalProduct={isPhysicalProduct}
          thirdPartyLink={thirdPartyLink}
          quantityValue={quantityValue}
          standingOrderDiscountAmount={standingOrderDiscountAmount}
          usePlaceholder={!hasBundleAllSelections}
        />
      )}
    </PricingWrap>
  );
};

const MobileSummaryHead = styled.div`
  display: flex;
  padding: ${props => props.theme.pxToRem(20)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const AlreadyHasAccessMessage = styled.div`
  padding: ${props => props.theme.pxToRem(20)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const MobileSummaryHeadTexts = styled.div`
  padding-top: ${props => props.theme.pxToRem(38)};
  padding-left: ${props => props.theme.pxToRem(8)};
`;

const MobileSummaryHeadTitle = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(8)};
`;

const ScrollableMobileContents = styled.div<{ isContribution?: boolean }>`
  height: calc(100% - ${props => props.theme.pxToRem(154)});
  overflow: ${props => (props.isContribution ? `scroll` : `auto`)};
`;

const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  padding-left: ${props => props.theme.pxToRem(20)};
  > svg {
    fill: ${props => props.theme.colors.primaryPurple};
    width: ${props => props.theme.pxToRem(26)};
    height: ${props => props.theme.pxToRem(26)};
  }
`;

const SummaryContainerMobile = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  background-color: ${props => props.theme.colors.neutralGrey1};
  position: fixed;
  top: 0;
  width: 100%;
  height: ${props => `calc(100% - ${props.theme.pxToRem(70)})`};
  line-height: 1.38;
  z-index: 1;
`;

interface PricingProps {
  discounted?: boolean;
  final?: boolean;
  priceData?: Product.FormattedPriceData;
  testId?: string;
  selectedVariantFormat?: string;
  isPhysicalProduct?: boolean;
  thirdPartyLink?: string;
  quantityValue?: number | null;
  standingOrderDiscountAmount?: number;
  usePlaceholder?: boolean;
  isUserSuspendedByEthics?: boolean;
  priceFinalIfSuspended?: Product.FormattedPriceData;
}

const PriceRow: React.FC<PricingProps> = ({
  discounted,
  final,
  priceData,
  testId,
  isPhysicalProduct,
  thirdPartyLink,
  quantityValue,
  standingOrderDiscountAmount,
  usePlaceholder,
  isUserSuspendedByEthics,
  priceFinalIfSuspended,
}) => {
  const curr = useSelector(productCurrencySelector);

  const priceAmount = priceData?.amount;
  const priceCurrency = priceData?.currency || curr?.label;

  const finalPriceCents = fromCentsFormat(priceAmount ? priceAmount : 0);
  const discountedPrice =
    finalPriceCents && quantityValue && standingOrderDiscountAmount
      ? finalPriceCents * quantityValue * standingOrderDiscountAmount
      : 0;

  const totalPrice = finalPriceCents && quantityValue ? finalPriceCents * quantityValue - discountedPrice : 0;
  const finalPrice = formatPrice(totalPrice, priceCurrency);
  const priceToDisplay = !usePlaceholder ? priceData && finalPrice : `-${curr.sign}---`;

  return (
    <PriceInfoRow final={final}>
      {!isUserSuspendedByEthics ? (
        <PricingLabel data-testid={`${testId}-label`} discounted={discounted} final={final}>
          {thirdPartyLink && isPhysicalProduct && final
            ? 'Price before sales tax/shipping'
            : priceData && priceData.label}
        </PricingLabel>
      ) : (
        <PricingLabel data-testid={`${testId}-label`} discounted={discounted} final={final}>
          {thirdPartyLink && isPhysicalProduct && final
            ? 'Price before sales tax/shipping'
            : priceFinalIfSuspended && priceFinalIfSuspended.label}
        </PricingLabel>
      )}
      <PricingValue data-testid={`${testId}-value`} discounted={discounted} final={final}>
        {priceToDisplay}
      </PricingValue>
    </PriceInfoRow>
  );
};

const StyledDiv = styled.div`
  margin: 0;
  float: right;
  min-height: ${props => props.theme.pxToRem(30)};
  -webkit-backdrop-filter: blur(4px);
  width: ${props => props.theme.pxToRem(323)};
  backdrop-filter: blur(4px);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.neutralGrey1};
`;

const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.pxToRem(20)};
`;
const StyledContainer = styled.div`
  display: flex;
`;

const StyledTitleSmall = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
  width: 100%;
  align-items: center;
  display: flex;
`;

const StyledAdditionalInfo = styled.span`
  margin-top: ${props => props.theme.pxToRem(15)};
  float: right;
  text-align: center;
  width: ${props => props.theme.pxToRem(323)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledFreeTrialRenewalNotice = styled.div`
  margin-top: ${props => props.theme.pxToRem(15)};
  float: right;
  text-align: center;
  width: ${props => props.theme.pxToRem(323)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
`;

const StyledAdditionalInfoMobile = styled.span`
  margin-top: ${props => props.theme.pxToRem(15)};
  text-align: center;
  display: block;
  width: 100%;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledFreeTrialRenewalNoticeMobile = styled.div`
  margin-top: ${props => props.theme.pxToRem(15)};
  text-align: center;
  display: block;
  width: 100%;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
`;

const StyledQuantityDivRight = styled.div`
  display: flex;
  width: 100%;
  max-height: ${props => props.theme.pxToRem(43)};
  justify-content: flex-end;
  align-items: center;
`;

const StyledStandingOrder = styled.div`
  display: flex;
  margin-left: ${props => props.theme.pxToRem(12)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  padding: 0 0 ${props => `${props.theme.pxToRem(4)}`} ${props => `${props.theme.pxToRem(20)}`};
  & p,
  & a {
    font-size: ${props => props.theme.fontSizes.xxs};
  }
  & p {
    display: block;
  }
  & a {
    width: 100%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: ${props => props.theme.pxToRem(20)};
`;

const NotAvailableNoteStyled = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  padding-left: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const PriceInfoRow = styled.div<PricingProps>`
  padding-top: ${props => (props.final ? props.theme.pxToRem(8) : props.theme.pxToRem(4))};
  padding-left: ${props => props.theme.pxToRem(20)};
  padding-right: ${props => props.theme.pxToRem(20)};
  display: flex;
  justify-content: space-between;
`;

const PricingWrap = styled.div`
  padding-top: ${props => props.theme.pxToRem(20)};
`;

const PricingLabel = styled.div<PricingProps>`
  color: ${props => (props.discounted ? props.theme.colors.secondaryTeal : props.theme.colors.neutralGrey8)};
  font-size: ${props => (props.final ? props.theme.pxToRem(18) : props.theme.pxToRem(14))};
  font-weight: ${props => props.theme.fontWeights.light};
  font-weight: ${props => (props.final ? props.theme.fontWeights.medium : props.theme.fontWeights.light)};
`;

const PricingValue = styled.div<ThemedStyledProps<PricingProps, DefaultTheme>>`
  color: ${props => (props.discounted ? props.theme.colors.secondaryTeal : props.theme.colors.neutralGrey8)};
  font-weight: ${props =>
    !(props.discounted || props.final) ? props.theme.fontWeights.regular : props.theme.fontWeights.medium};
  font-size: ${props => (props.final ? props.theme.pxToRem(18) : props.theme.pxToRem(14))};
  text-decoration: ${props => (!(props.discounted || props.final) ? 'line-through' : 'none')};
  padding-left: ${props => props.theme.pxToRem(1)};
`;

const StyledTitle = styled.div`
  width: 100%;
  padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(8)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.pxToRem(18)};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledNotificationForAnonymousUser = styled(NotificationForAnonymousUser)`
  padding: ${props => props.theme.pxToRem(7)} ${props => props.theme.pxToRem(20)} 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-top: ${props => props.theme.pxToRem(12)};
  }
`;

const StyledSubtitle = styled.div`
  width: 100%;
  padding-left: ${props => props.theme.pxToRem(20)};
  padding-bottom: ${props => props.theme.pxToRem(8)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.pxToRem(18)};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const HorizontalDivider = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey3};
  width: 100%;
  height: ${props => props.theme.pxToRem(1)};
`;

const HorizontalDividerMargins = styled(HorizontalDivider as any)`
  margin-top: ${props => props.theme.pxToRem(25)};
  margin-bottom: ${props => props.theme.pxToRem(4)};
`;

const HorizontalDividerPadded = styled(HorizontalDivider)`
  width: auto;
  margin-right: ${props => props.theme.pxToRem(20)};
  margin-left: ${props => props.theme.pxToRem(20)};
`;
const HorizontalDividerPaddedMargins = styled(HorizontalDividerPadded)`
  width: auto;
  margin-top: ${props => props.theme.pxToRem(25)};
  margin-bottom: ${props => props.theme.pxToRem(4)};
  margin-right: ${props => props.theme.pxToRem(20)};
  margin-left: ${props => props.theme.pxToRem(20)};
`;

const StyledButtonWrapper = styled.div`
  padding: ${props => props.theme.pxToRem(20)};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 auto;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const StyledLink = styled(Link)`
  &.standing-order-link {
    display: block;
    width: 100%;
  }
`;

const QtyUpdateButtonsContainer = styled.div`
  position: relative;
  margin: ${props => `${props.theme.pxToRem(16)} 0 ${props.theme.pxToRem(24)} ${props.theme.pxToRem(93)}`};

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin: 0;
    padding: ${props => props.theme.pxToRem(18)};
    display: flex;
    justify-content: center;
  }
`;

const StyledUpdateButton = styled(Button)`
  &&&& {
    width: ${props => props.theme.pxToRem(111)};
    height: ${props => props.theme.pxToRem(34)};
    min-height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.pxToRem(14)};
    padding: 0;
    margin-right: 5px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

const StyledCancelButton = styled(Button)`
  &&&& {
    margin-right: ${props => props.theme.pxToRem(10)};
    width: ${props => props.theme.pxToRem(75)};
    height: ${props => props.theme.pxToRem(34)};
    min-height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.pxToRem(14)};
    padding: 0;
  }
`;

const ManagePurchasesMessage = styled.div`
  padding: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(20)} 0 ${props => props.theme.pxToRem(20)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-top: ${props => props.theme.pxToRem(20)};
  }
`;
