import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { SearchModal } from './SearchModal';
import { fetchSearchSuggestions, clearSuggestions, removeSearchTerm, getSearchHistory } from 'modules/search/actions';
import { searchSuggestionsSelector, searchHistorySelector } from 'modules/search/selectors';
import { searchModalExpandedSelector } from 'modules/layouts/selectors';
import { setSearchModalExpand } from 'modules/layouts';
import { isAuthSelector } from 'modules/user/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  fetchSuggestions(searchQuery: string): void {
    dispatch(fetchSearchSuggestions(searchQuery));
  },

  clearSuggestions(): void {
    dispatch(clearSuggestions());
  },

  deleteSearchHistoryItem(searchQuery: string): void {
    dispatch(removeSearchTerm(searchQuery));
  },

  getSearchHistory(): void {
    dispatch(getSearchHistory());
  },

  close(): void {
    dispatch(setSearchModalExpand(false));
  },
  navigate(path: string): void {
    dispatch(push(path));
  },
});

const mapStateToProps = (state: State.Root) => ({
  isAuth: isAuthSelector(state),
  suggestions: searchSuggestionsSelector(state),
  recentSearches: searchHistorySelector(state),
  showModal: searchModalExpandedSelector(state),
});

export const SearchModalContainer = connect(mapStateToProps, mapActionCreators)(SearchModal);
