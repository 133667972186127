import { Product } from 'mxp-schemas';
import { createSelector } from 'reselect';
import { emptyObject } from 'utils/emptyItems';
import { slugIdMapCreator } from './helpers';

const rootSelector = createSelector(
  (state: State.Root): State.ProductTypes => state.productTypes,
  productTypes => productTypes
);

export const productTypesHashSelector = createSelector(
  rootSelector,
  (productTypes: State.ProductTypes): State.ProductTypesHash => productTypes.productTypesHash || emptyObject
);
// includes also artificial product types made for pageProductAgg
export const productTypesSelector = createSelector(
  productTypesHashSelector,
  (productTypesHash: State.ProductTypesHash | null): State.ProductType[] | null => {
    return productTypesHash && Object.values(productTypesHash);
  }
);
// excludes synthetic product types (e.g. Webcast-conference) built for page product Aggregation
const productTypesOnlyOrganicSelector = createSelector(
  productTypesHashSelector,
  (productTypesHash: State.ProductTypesHash | null): State.ProductType[] | null =>
    // organic product type should have only one product type key
    productTypesHash && Object.values(productTypesHash).filter(item => item?.productTypeKeys?.length === 1)
);
export const productTypesOrganicSortedSelector = createSelector(
  productTypesOnlyOrganicSelector,
  (productTypes: State.ProductType[] | null): State.ProductType[] | null => {
    return (
      productTypes &&
      Object.values(productTypes).sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
    );
  }
);

export const productTypeCalendarSelector = createSelector(
  productTypesHashSelector,
  (productTypesHash: State.ProductTypesHash): State.ProductType => productTypesHash[Product.ProductSlug.CALENDAR]
);

export const productTypesFetchedSelector = createSelector(
  rootSelector,
  (productTypes: State.ProductTypes): boolean => productTypes.productTypesFetched
);

export const productTypesSlugIdMapSelector = createSelector(productTypesSelector, slugIdMapCreator);

export const productTypesSlugsStrSelector = createSelector(
  productTypesHashSelector,
  (productTypesHash: State.ProductTypesHash | null): string => Object.keys(productTypesHash || emptyObject).join('|')
);
