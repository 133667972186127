import React, { FunctionComponent } from 'react';
import styled, { css, ThemedStyledProps, DefaultTheme } from 'styled-components';
import { isServer } from 'utils';

interface Props {
  src: string;
  alt: string;
  imageRatio: string;
  stretchByWidth?: boolean;
  className?: string;
}

export const LazyImage: FunctionComponent<Props> = ({ src, alt, imageRatio, stretchByWidth, className = '' }) => {
  // const [previewLoaded, setPreviewLoaded] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);

  // const handlePreviewLoaded = React.useCallback(() => {
  //   setPreviewLoaded(true);
  // }, [setPreviewLoaded]);

  const handleImageLoaded = React.useCallback(() => {
    setImageLoaded(true);
  }, [setImageLoaded]);

  return (
    <StyledContainer className={className}>
      <StyledPreview
        // FixMe: when the contenful image storage is fixed, change src to `${src}&w=88&h=20&fit=thumb`
        // src={`https://www.aicpa.org/content/dam/aicpa/AICPA-logo.png.transform/375w/image.png`}
        loaded={true} // previewLoaded
        // onLoad={handlePreviewLoaded}
      />
      {src && !src.startsWith('null') && (
        <StyledImage
          alt={alt}
          src={src}
          loaded={isServer || imageLoaded}
          onLoad={handleImageLoaded}
          stretchByWidth={stretchByWidth}
          tabIndex={0}
        />
      )}
      <div style={{ paddingBottom: `${imageRatio}` }} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  background-color: #f6f6f6;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
`;

const sharedImage = css`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s linear;
  opacity: ${({ loaded }: any) => (loaded ? 1 : 0)};
`;

const StyledPreview: any = styled.div`
  ${sharedImage}
  background-color: ${props => props.theme.colors.neutralGrey3};
  /* this is needed so Safari keeps sharp edges */
  transform: scale(1);
`;

const StyledImage: any = styled.img<{ stretchByWidth: boolean }>`
  ${sharedImage}
  ${props =>
    props.stretchByWidth &&
    `
    height: auto;
    width: 100%;
  `}
`;
