import { getPath } from 'utils';
import { push, getLocation } from 'connected-react-router';
import { Dispatch } from 'redux';
import { Routes } from 'constants/index';
import { userHasPrefSelector, isAuthSelector } from 'modules/user/selectors';
import { toggleModalOnboardingStartVisible } from 'modules/layouts';

export const triggerOnBoardingMetaData = (
  { isActionSkippedBeforeOnBoarding } = {
    isActionSkippedBeforeOnBoarding: false,
  }
) => {
  return {
    isOnBoardingTrigger: true,
    isActionSkippedBeforeOnBoarding,
  };
};

export const triggerOnboardingMiddleware = ({
  dispatch,
  getState,
}: {
  dispatch: Dispatch;
  getState: () => State.Root;
}) => (next: any) => (action: { type: string; payload: any; meta: any }) => {
  const { type, meta } = action;
  const state: State.Root = getState();
  const { pathname } = getLocation(state as any);
  const hasPref: boolean = userHasPrefSelector(state);
  const isAuth: boolean | null = isAuthSelector(state);
  const preferencesPath: string = getPath(Routes.PREFERENCES);

  // Skip location change page_view event for dynamic routes.
  const isLocationChangeEvent: boolean = type === '@@router/LOCATION_CHANGE';
  if (isLocationChangeEvent) {
    return next(action);
  }

  const isTrigger = meta?.isOnBoardingTrigger;

  // When a payload creator returns a promise the action is fired twice
  const isAlreadyOnPreferences = pathname === preferencesPath;

  if (isTrigger && !hasPref && isAuth && !isAlreadyOnPreferences) {
    dispatch(toggleModalOnboardingStartVisible());
    dispatch(push({ pathname: preferencesPath, state: { prevRoute: pathname } }));

    if (meta?.isActionSkippedBeforeOnBoarding) return;
  }

  return next(action);
};
