import React from 'react';
import Helmet from 'react-helmet';
import { SingleColumnPageTemplate } from 'components/templates/SingleColumn/SingleColumn';
import { Loader } from 'semantic-ui-react';
import { ButtonEnums, ButtonLink, Grid, Link, Paragraph } from '../../atoms';
import styled from 'styled-components';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { useParams } from 'react-router';

interface Props {
  processing: boolean;
  allocate(): void;
  reset(): void;
  error: CustomErrors.GraphQLError | null;
}

export const PageShareAllocation: React.FC<Props> = ({ processing, allocate, error, reset }) => {
  React.useEffect(() => {
    if (!processing) allocate();
    return reset;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showLoading = Boolean(processing && !error);
  let { productSlug }: { productSlug: string | undefined } = useParams();

  if (productSlug) {
    productSlug = productSlug
      .split('-')
      .filter(text => text)
      .join(' ');
  }

  return (
    <>
      <Helmet>
        <title>Allocate Product | AICPA & CIMA</title>
      </Helmet>

      <SingleColumnPageTemplate>
        {error && (
          <Grid>
            <Grid.Row>
              <Grid.Column computer={8}>
                <Wrapper>
                  <ErrorScreen error={error} productSlug={productSlug} />
                  <StyledButtonLink
                    testId="error-go-to-invoices"
                    variant={ButtonEnums.variants.primary}
                    size={ButtonEnums.sizes.medium}
                    to={getPath(Routes.STOREFRONT_PAGE)}
                  >
                    Browse CPE &amp; Learning
                  </StyledButtonLink>
                </Wrapper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {showLoading && <Loader active>Loading</Loader>}
        {!error && !showLoading && <>Allocation Done.</>}
      </SingleColumnPageTemplate>
    </>
  );
};

interface ErrorScreenProps {
  error: CustomErrors.GraphQLError | null;
  productSlug: string | undefined;
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({ error, productSlug }) => {
  const data = React.useMemo(() => {
    const errorMsg = error?.primaryApplicationError?.message || '';
    const tokenUsedStr = 'has been used already';
    const tokenAlreadyAllocatedStr = 'is already allocated';
    const notExistInOrgStr = 'does not exist in organization.';
    const invalidUser = 'No seat available for email';

    if (errorMsg.includes(invalidUser)) {
      return {
        title: 'Check "My Purchases" to start your learning',
        message:
          'This link is no longer available. If you do not have access, please contact the individual who assigned this learning.',
        contactText: 'For more help, contact us at',
      };
    }
    if (errorMsg.includes(tokenUsedStr) || errorMsg.includes(tokenAlreadyAllocatedStr)) {
      return {
        title: 'Check "My Purchases" to start your learning',
        message:
          'The invitation to access this product has been claimed. If you do not have access, please contact the individual who assigned this learning.',
        contactText: 'For more help, contact us at',
      };
    }
    if (errorMsg.includes('The price book entry is inactive.')) {
      return {
        title: 'Sorry for the inconvenience',
        message:
          'This product is no longer available for access. Please contact the individual who assigned this learning to you.',
        contactText: 'If you need help with this please contact us at',
      };
    }
    if (errorMsg.includes(notExistInOrgStr)) {
      return {
        title: 'Sorry for the inconvenience',
        message: 'You must register and be associated with the organization for access.',
        contactText: 'If you need help with this please contact us at',
      };
    }

    const product = productSlug ? `"${productSlug}"` : 'this product';
    const message = (
      <>
        The seat for <ProductInfo>{product}</ProductInfo> has reached maximum capacity. Please contact the person who
        sent you this email in order to get more information.
      </>
    );

    return {
      title: 'Sorry for the inconvenience',
      message,
      contactText: 'If you need help with this please contact us at',
    };
  }, [error, productSlug]);
  return (
    <>
      <StyledH1 data-testid="error-title">{data.title}</StyledH1>
      <StyledParagraph testId="error-message-text">{data.message}</StyledParagraph>
      <StyledParagraph testId="error-please-contact-text">
        {data.contactText}&nbsp;
        <Link isExternal testId="error-email" to="mailto:service@aicpa.org">
          service@aicpa.org
        </Link>
        &nbsp;or&nbsp;
        <Link isExternal testId="error-phone" to="tel:888-777-7077">
          888-777-7077
        </Link>
        , 9am–6pm ET, Monday through Friday.
      </StyledParagraph>
    </>
  );
};

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${props => props.theme.pxToRem(22)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  :last-of-type {
    margin-bottom: ${props => props.theme.pxToRem(32)};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    width: ${props => props.theme.pxToRem(193)};
  }
`;

const Wrapper = styled.div`
  margin: ${props => props.theme.pxToRem(40)} 0;
`;

const StyledH1 = styled.h1`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
`;

const ProductInfo = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: capitalize;
`;
