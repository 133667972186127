import React from 'react';
import styled from 'styled-components/macro';
import { Grid } from 'components/atoms';
import { AnimationBlock, ANIMATIONTYPES } from 'components/molecules/AnimationBlock/AnimationBlock';
import { Loader } from 'components/atoms/Loader/Loader';
import { isValidHTML } from 'utils/index';

interface Props {
  modularBlock: Common.ModularContentBlock;
  isFullPagePagination?: boolean;
  isLandingPage?: boolean;
}

export const BlockTurtlAnimation: React.FC<Props> = ({ modularBlock, isFullPagePagination, isLandingPage }) => {
  const { description, header, descriptionText, content } = (modularBlock as Common.AnimationBlock) || {};
  const [iFrameLoading, setIFrameLoading] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [isHomePage, setHomePage] = React.useState(false);
  const hasLandingPage = Boolean(Object.keys(content || {}).length === 0);
  const homePage = Boolean(isLandingPage && !isFullPagePagination);

  React.useEffect(() => {
    if (!description) return;
    const html = isValidHTML(description);
    setError(html);
    setHomePage(homePage);
    setIFrameLoading(true);
  }, [description, hasLandingPage, homePage]);

  return (
    <StyledGrid
      centered={!iFrameLoading}
      isFullPagePagination={isFullPagePagination}
      isLandingPage={isLandingPage}
      isHomePage={isHomePage}
      isError={isError}
    >
      {!iFrameLoading ? (
        <LoaderWrapper>
          <Loader size="small" active content="Loading" inline />
        </LoaderWrapper>
      ) : (
        <>
          <Grid.Row>
            {description && (
              <AnimationBlock
                oembed={description}
                isLandingPage={isLandingPage}
                header={header}
                descriptionText={descriptionText}
                isFullPagePagination={isFullPagePagination}
                type={ANIMATIONTYPES.TURTL}
              />
            )}
          </Grid.Row>
        </>
      )}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)<{ isFullPagePagination: boolean }>`
  &&&&& {
    overflow-y: ${props => (props.isFullPagePagination ? 'auto' : 'visible')};
    margin-left: 0;
    margin-right: 0;
    ${props =>
      props.isLandingPage &&
      props.isError &&
      `
        padding-top: ${props.theme.pxToRem(20)};
        padding-bottom: ${props.theme.pxToRem(20)};
      `}
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => `${props.theme.pxToRem(40)} 0`};
`;
