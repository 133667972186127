import React from 'react';
import styled from 'styled-components';
import { Product } from 'mxp-schemas';
import { ProductsListParent } from 'components/molecules/ProductsList/ProductsList';
import { StyledLink } from './ProductItem';
import { DetailRowWrap } from './ProductItemDetails';
import { RenewalToggle } from './RenewalToggle';
import { getPath, MomentHelpers } from 'utils';
import { Routes } from 'constants/index';
interface Props {
  item: Common.ProductItemData;
  parentPage: ProductsListParent;
  viewTextForAnalytics?: string;
  isActiveProduct: boolean;
  toggleSubscriptionRenewal?: (enable: boolean, item: Common.ProductItemData, productName: string) => void;
}

export const ProductItemFreeTrialDetails: React.FC<Props> = ({
  item,
  parentPage,
  viewTextForAnalytics,
  isActiveProduct,
  toggleSubscriptionRenewal,
}) => {
  const {
    productType,
    sku,
    formattedFullProductPrice,
    isFreeTrial,
    freeTrialTerm,
    accessEndDate,
    autoRenewEnabled,
    availability,
  } = item;
  const isOrderConfirmationPage: boolean = parentPage === ProductsListParent.ORDER_CONFIRMATION;
  const isCartPage: boolean = parentPage === ProductsListParent.CART_PAGE;
  const isProfilePurchasesPage: boolean = parentPage === ProductsListParent.PURCHASES_PROFILE;
  const isSubscription: boolean = productType === Product.ProductType.SUBSCRIPTION;
  const isOnlineCourse: boolean = productType === Product.ProductType.COURSE;
  const accessEndDateFormatted = accessEndDate ? MomentHelpers.formatExpiryDate(accessEndDate) : '';
  const isExpiredSubscription = (isSubscription || isOnlineCourse) && !isActiveProduct;
  const isLastAccessDay =
    !autoRenewEnabled && String(accessEndDate).startsWith(new Date().toISOString().substring(0, 10));
  const calculatedAvailabilityDuration = availability ? MomentHelpers.daysToAvailabilityDuration(availability) : '';

  if (!isFreeTrial || (!isOrderConfirmationPage && !isCartPage && !isProfilePurchasesPage)) {
    return null;
  }

  const renderFreeTrialAROnDetail = (): React.ReactNode => (
    <>
      This is a <StyledMediumText>{freeTrialTerm}-day free trial.</StyledMediumText> On{' '}
      <StyledMediumText>{accessEndDateFormatted}</StyledMediumText>, your default card will be automatically charged{' '}
      <StyledMediumText>{formattedFullProductPrice}</StyledMediumText> plus sales tax
      {isSubscription ? (
        <>
          , and full paid access to this <StyledMediumText>{calculatedAvailabilityDuration}</StyledMediumText>{' '}
          auto-renewable subscription will start.
        </>
      ) : (
        ' for full paid access to this product.'
      )}
    </>
  );

  const renderFreeTrialAROffDetail = (): React.ReactNode => (
    <>
      This is a <StyledMediumText>{freeTrialTerm}-day free trial.</StyledMediumText> On{' '}
      <StyledMediumText>{accessEndDateFormatted}</StyledMediumText>, your access will end. No charges will be made to
      your default card. To continue accessing this {isSubscription ? 'subscription' : 'product'}, switch the toggle to
      “on”.
    </>
  );

  const renderTermsSecondSentenceForOrderConfirmationPage = (): React.ReactNode => (
    <div>
      To manage your free trial, go to&nbsp;
      <StyledLink
        target="_blank"
        testId={`purchases-link-${productType}-${sku}`}
        to={getPath(Routes.PROFILE_PURCHASES)}
        viewTextForAnalytics={viewTextForAnalytics}
      >
        Purchases
      </StyledLink>
      &nbsp;in your profile.
    </div>
  );

  const renderTermsSecondSentenceForCartPage = (): React.ReactNode => (
    <>
      {' '}
      Opt out or cancel your free trial anytime under Purchases.
      {isSubscription && renderLinkWhatToExpect(isCartPage)}
    </>
  );

  const renderLinkWhatToExpect = (isCartPageParam: boolean): React.ReactNode => (
    <StyledLinkWrapper isCartPage={isCartPageParam}>
      <StyledLink
        testId={`cart-what-to-expect-link-${productType}-${sku}`}
        to={getPath(Routes.WHAT_TO_EXPECT_FROM_SUBSCRIPTIONS)}
      >
        {isCartPageParam ? 'What to expect with subscriptions and auto-renewals' : 'Subscription Terms & Conditions'}
      </StyledLink>
    </StyledLinkWrapper>
  );

  const renderTermsSecondSentence = (): React.ReactNode =>
    isOrderConfirmationPage
      ? renderTermsSecondSentenceForOrderConfirmationPage()
      : isCartPage
      ? renderTermsSecondSentenceForCartPage()
      : '';

  const renderAccessForXDaysBlock = (): React.ReactNode => (
    <DetailRowWrap>
      <DetailHead>Access for</DetailHead>
      <DetailContent>{freeTrialTerm} days</DetailContent>
    </DetailRowWrap>
  );

  const renderTermsBlockForConfirmationOrCart = (): React.ReactNode => (
    <DetailRowWrap>
      <DetailHead>Terms</DetailHead>
      <DetailContent>
        <>
          This is a <StyledMediumText>{freeTrialTerm}-day free trial.</StyledMediumText> On{' '}
          <StyledMediumText>{accessEndDateFormatted}</StyledMediumText>, your default card will be automatically charged{' '}
          <StyledMediumText>{formattedFullProductPrice}</StyledMediumText> plus sales tax
          {isSubscription ? (
            <>
              , and full paid access to this <StyledMediumText>{calculatedAvailabilityDuration}</StyledMediumText>{' '}
              auto-renewable subscription will start.
            </>
          ) : (
            ' for full paid access to this product.'
          )}
          {renderTermsSecondSentence()}
        </>{' '}
      </DetailContent>
    </DetailRowWrap>
  );

  const renderPurchasesPageExpiredSubscriptionBlocks = (): React.ReactNode => (
    <>
      <DetailRowWrap>
        <DetailHead>Expired</DetailHead>
        <DetailContent>{accessEndDateFormatted}</DetailContent>
      </DetailRowWrap>
      <DetailRowWrap>
        <DetailHead>Terms</DetailHead>
        <DetailContent>
          Your {freeTrialTerm}-day free trial has expired.
          {isSubscription && (
            <>
              {' '}
              Start a new subscription to resume your benefits.
              <StyledList>
                <li>Get direct access to content updates</li>
                <li>Cancel anytime</li>
              </StyledList>
            </>
          )}
        </DetailContent>
      </DetailRowWrap>
    </>
  );

  const renderPurchasesPageActiveSubscriptionBlocksWithARSwitch = (): React.ReactNode => (
    <>
      <DetailRowWrap>
        <DetailHead>Free Trial</DetailHead>
      </DetailRowWrap>
      <RenewalToggle
        item={item}
        productName={item.title}
        autoRenewEnabled={autoRenewEnabled!}
        renewalOnText="Future paid product access is active"
        renewalOffText="Future paid product access is inactive."
        toggleSubscriptionRenewal={toggleSubscriptionRenewal}
      />
      <DetailRowWrap>
        <DetailContent>
          <StyledLeftPadded>
            {autoRenewEnabled ? renderFreeTrialAROnDetail() : renderFreeTrialAROffDetail()}
            {isSubscription &&
              (autoRenewEnabled ? (
                <div>With this feature turned on, you agree to the {renderLinkWhatToExpect(isCartPage)}.</div>
              ) : (
                <div>If you turn on this feature, you agree to the {renderLinkWhatToExpect(isCartPage)}.</div>
              ))}
          </StyledLeftPadded>
        </DetailContent>
      </DetailRowWrap>
    </>
  );

  const renderPurchasesPageLastSubscriptionDayBlock = (): React.ReactNode => (
    <DetailRowWrap>
      <DetailHead>Free Trial</DetailHead>
      <DetailContent>
        Your free trial ends today. To keep accessing this product, order the full paid version tomorrow.
      </DetailContent>
    </DetailRowWrap>
  );

  return (
    <>
      {isOrderConfirmationPage && renderAccessForXDaysBlock()}
      {(isOrderConfirmationPage || isCartPage) && renderTermsBlockForConfirmationOrCart()}
      {isProfilePurchasesPage &&
        (isLastAccessDay
          ? renderPurchasesPageLastSubscriptionDayBlock()
          : isExpiredSubscription
          ? renderPurchasesPageExpiredSubscriptionBlocks()
          : renderPurchasesPageActiveSubscriptionBlocksWithARSwitch())}
    </>
  );
};

const DetailHead = styled.div`
  width: ${props => props.theme.pxToRem(95)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};
  height: ${props => props.theme.pxToRem(18)};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
  flex-shrink: 0;
`;

const DetailContent = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
  max-width: ${props => props.theme.pxToRem(400)};
`;

const StyledMediumText = styled.div`
  display: inline;
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledLeftPadded = styled.div`
  padding-left: ${props => props.theme.pxToRem(53)};
  flex-grow: 1;
`;

const StyledLinkWrapper = styled.div<{ isCartPage: boolean }>`
  ${props =>
    !props.isCartPage &&
    `
    display: inline;
  `}
`;

const StyledList = styled.ul`
  padding-left: ${props => props.theme.pxToRem(16)};
  margin: ${props => props.theme.pxToRem(3)} 0;
`;
