import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.shareAllocation,
  (shareAllocation: State.ShareAllocation): State.ShareAllocation => shareAllocation
);

export const processingSelector = createSelector(
  rootSelector,
  (shareAllocation: State.ShareAllocation): boolean => shareAllocation.processing
);

export const errorSelector = createSelector(
  rootSelector,
  (shareAllocation: State.ShareAllocation): CustomErrors.GraphQLError | null => shareAllocation.error
);
