import React from 'react';
import { useSelector } from 'react-redux';
import { StrictDropdownProps, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { MembershipTypes, User } from 'mxp-schemas';
import { Dropdown } from 'components/atoms';
import { FirmAdminTableType } from 'constants/firm-admin';
import { membershipsSelector } from 'modules/firmAdmin/selectors';
import { SimplifiedMembership } from 'mxp-schemas/dist/types/products';
interface Props {
  selectedTierSku?: string;
  selectedTypeSku?: string;
  testId: string;
  disabled?: boolean;
  renderAsTableCell?: boolean;
  membershipTypeHeaderNode?: React.ReactNode;
  membershipTierHeaderNode?: React.ReactNode;
  positionCategory?: MembershipTypes.RoleCategoryEnum;
  onSelectTierSku?: (tierSku: string, membershipTypeSlug?: User.B2BMembershipTypes) => void;
  onSelectTypeSku?: (typeSku: string) => void;
  isTierHighlighted?: boolean;
  isTypeHighlighted?: boolean;
  selectedIsPaidByFirm?: boolean | string;
  tableType?: FirmAdmin.FirmAdminTableType;
  isTypeSelected?: boolean;
  isTierSelected?: boolean;
  hideTypeDropdown?: boolean;
  hideTierDropdown?: boolean;
  initialMembershipType?: string;
  tierDisabled?: boolean;
  isHonorary?: boolean;
  isTLW?: boolean;
  useCimaFeatures?: boolean;
  isAddMember?: boolean;
}

export const MembershipTypeAndTierDropdown: React.FC<Props> = ({
  selectedTierSku,
  selectedTypeSku,
  testId,
  disabled,
  renderAsTableCell,
  membershipTypeHeaderNode,
  membershipTierHeaderNode,
  positionCategory,
  onSelectTierSku,
  onSelectTypeSku,
  isTierHighlighted,
  isTypeHighlighted,
  selectedIsPaidByFirm,
  tableType,
  isTypeSelected,
  isTierSelected,
  hideTypeDropdown = false,
  hideTierDropdown = false,
  initialMembershipType,
  tierDisabled = false,
  isHonorary = false,
  useCimaFeatures = false,
  isAddMember = false,
}) => {
  const [selectedType, setSelectedType] = React.useState<string>('');
  const [selectedTier, setSelectedTier] = React.useState<string>('');
  const memberships = useSelector(membershipsSelector);

  React.useEffect(() => {
    if (memberships?.length && selectedTierSku) {
      memberships.some(m => {
        if (m.variants.some(v => v.sku === selectedTierSku)) {
          setSelectedType(m.slug);
          setSelectedTier(selectedTierSku || '');
          return true;
        }
        return false;
      });
    }

    if (memberships?.length && selectedTypeSku) {
      setSelectedType(selectedTypeSku);
    }

    if ((selectedTierSku === '' && selectedTier !== selectedTierSku) || selectedIsPaidByFirm === false) {
      setSelectedTier('');
      setSelectedType('');
      if (onSelectTypeSku) onSelectTypeSku('');
      if (onSelectTierSku) onSelectTierSku('');
    }
  }, [memberships, selectedTierSku, selectedTier, selectedIsPaidByFirm]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDisabledTypeOption = (slug: User.B2BMembershipTypes) =>
    (initialMembershipType === User.B2BMembershipTypes.REGULAR &&
      [
        User.B2BMembershipTypes.AFFILIATE,
        User.B2BMembershipTypes.CANDIDATE,
        User.B2BMembershipTypes.INTERNATIONAL_ASSOCIATE,
      ].includes(slug)) ||
    (initialMembershipType === User.B2BMembershipTypes.RETIRED &&
      [User.B2BMembershipTypes.AFFILIATE, User.B2BMembershipTypes.CANDIDATE].includes(slug));

  const typeOptions = React.useMemo(() => {
    const parseMembership = (membership: SimplifiedMembership) => {
      return {
        key: membership?.slug,
        value: membership?.slug,
        text: membership?.name,
        disabled: Boolean(
          tableType === FirmAdminTableType.UPGRADABLE_MEMBERS &&
            handleDisabledTypeOption(membership?.slug as User.B2BMembershipTypes)
        ),
      };
    };

    if (useCimaFeatures && !isAddMember) {
      const filterMembers = [
        MembershipTypes.CimaMembershipProductSlug.CIMA_CANDIDATE_PRODUCT,
        MembershipTypes.CimaMembershipProductSlug.CIMA_AFFILIATE_PRODUCT,
        MembershipTypes.CimaMembershipProductSlug.CIMA_RETIRED_PRODUCT,
        MembershipTypes.CimaMembershipProductSlug.CIMA_REGULAR_PRODUCT,
      ];

      return memberships
        ? memberships
            ?.filter(m => {
              const slug = m.slug as MembershipTypes.CimaMembershipProductSlug;
              return filterMembers.includes(slug);
            })
            .map(m => parseMembership(m))
        : [];
    }

    if (useCimaFeatures) {
      const membership = memberships.find(
        m => m.slug === MembershipTypes.CimaMembershipProductSlug.CIMA_CANDIDATE_PRODUCT
      );

      return membership ? [parseMembership(membership)] : [];
    }

    return memberships?.map(m => parseMembership(m));
  }, [memberships, tableType, useCimaFeatures]); // eslint-disable-line react-hooks/exhaustive-deps

  const tierOptions = React.useMemo(() => {
    if (!selectedType) return [];
    return memberships
      .find(m => m.slug === selectedType)
      ?.variants.filter(variant => {
        if (useCimaFeatures && !isAddMember) {
          const filterMembershipType = [
            MembershipTypes.CimaMembershipProductSlug.CIMA_CANDIDATE_PRODUCT,
            MembershipTypes.CimaMembershipProductSlug.CIMA_AFFILIATE_PRODUCT,
            MembershipTypes.CimaMembershipProductSlug.CIMA_RETIRED_PRODUCT,
          ];
          if (filterMembershipType.includes(selectedType as MembershipTypes.CimaMembershipProductSlug)) {
            return variant?.tierCode === MembershipTypes.TierCode.CORE;
          }

          if (selectedType === MembershipTypes.CimaMembershipProductSlug.CIMA_REGULAR_PRODUCT) {
            const tierCode = variant?.tierCode === undefined ? '' : variant?.tierCode;

            return ['Core', 'Essentials', 'Lead', 'Specialist'].indexOf(tierCode) !== -1;
          }
        }

        if (
          useCimaFeatures &&
          (selectedType === MembershipTypes.CimaMembershipProductSlug.CIMA_CANDIDATE_PRODUCT ||
            selectedType === MembershipTypes.CimaMembershipProductSlug.CIMA_AFFILIATE_PRODUCT)
        ) {
          return variant?.tierCode === MembershipTypes.TierCode.CORE;
        }

        return (
          !variant.roleSelection ||
          (isHonorary && variant.tierLevel === MembershipTypes.RoleCategoryEnum.HONORARY) ||
          !positionCategory ||
          positionCategory === variant.tierLevel
        );
      })
      .map(t => ({
        key: t.sku,
        value: t.sku,
        text: t.tierName,
      }));
  }, [isHonorary, selectedType, memberships, positionCategory, useCimaFeatures]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTierChange = (event: React.SyntheticEvent<HTMLElement>, data: StrictDropdownProps) => {
    const sku = data.value as string;
    setSelectedTier(sku);
    if (onSelectTierSku) onSelectTierSku(sku, selectedType as User.B2BMembershipTypes);
  };

  const handleTypeChange = (event: React.SyntheticEvent<HTMLElement>, data: StrictDropdownProps) => {
    const sku = data.value as string;
    setSelectedType(sku);
    setSelectedTier('');
    if (onSelectTypeSku) onSelectTypeSku(sku);
    if (onSelectTierSku) onSelectTierSku('');
  };

  const renderMembershipTypeDropdown = (fullWidth?: boolean) => (
    <DropdownStyled
      testId={`${testId}-m-type-dropdown`}
      options={typeOptions}
      value={selectedType}
      onChange={handleTypeChange}
      placeholder="Select type"
      disabled={disabled}
      data-full-width={fullWidth}
      data-no-margin={renderAsTableCell}
      data-highlighted={isTypeHighlighted}
      data-not-selected={tableType === FirmAdminTableType.MEMBERS_INVITES && !isTypeSelected}
    />
  );

  const renderMembershipTierDropdown = (fullWidth?: boolean) => (
    <DropdownStyled
      testId={`${testId}-m-tier-dropdown`}
      options={tierOptions}
      value={selectedTier}
      onChange={handleTierChange}
      selectOnBlur={false}
      placeholder="Select tier"
      disabled={tierDisabled || disabled || Boolean(!selectedType)}
      data-full-width={fullWidth}
      data-no-margin={renderAsTableCell}
      data-highlighted={isTierHighlighted}
      data-not-selected={tableType === FirmAdminTableType.MEMBERS_INVITES && Boolean(selectedType) && !isTierSelected}
    />
  );

  const renderMembershipTypeDropdownWithHeader = () => (
    <StyledContainer>
      {membershipTypeHeaderNode}
      {renderMembershipTypeDropdown(true)}
    </StyledContainer>
  );

  const renderMembershipTierDropdownWithHeader = () => (
    <StyledContainer>
      {membershipTierHeaderNode}
      {renderMembershipTierDropdown(true)}
    </StyledContainer>
  );

  return renderAsTableCell ? (
    <>
      {!hideTypeDropdown && <Table.Cell singleLine>{renderMembershipTypeDropdown()}</Table.Cell>}
      {!hideTierDropdown && <Table.Cell singleLine>{renderMembershipTierDropdown()}</Table.Cell>}
    </>
  ) : (
    <>
      {!hideTypeDropdown &&
        (membershipTypeHeaderNode ? renderMembershipTypeDropdownWithHeader() : renderMembershipTypeDropdown())}

      {!hideTierDropdown &&
        (membershipTierHeaderNode ? renderMembershipTierDropdownWithHeader() : renderMembershipTierDropdown())}
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  & ~ & {
    margin-top: ${props => props.theme.pxToRem(32)};
  }
`;

const DropdownStyled = styled(Dropdown)<{
  'data-full-width'?: boolean;
  'data-no-margin'?: boolean;
  'data-highlighted'?: boolean;
  'data-not-selected'?: boolean;
}>`
  &&&&&& {
    height: ${props => props.theme.pxToRem(44)};
    background-color: ${props => props.disabled && props.theme.colors.neutralGrey2};
    width: ${props => (props['data-full-width'] ? '100%' : props.theme.pxToRem(140))};
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.light};
    border-radius: ${props => props.theme.pxToRem(2)};
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey4};
    font-family: ${props => props.theme.fontFamily};
    color: ${props =>
      props.disabled
        ? props.theme.colors.neutralGrey4
        : props['data-highlighted']
        ? props.theme.colors.primaryPurple
        : props.theme.colors.neutralGrey8};
    padding: ${props => props.theme.pxToRem(9)} ${props => props.theme.pxToRem(15)};
    border-color: ${props =>
      props['data-highlighted']
        ? props.theme.colors.primaryPurple
        : props['data-not-selected']
        ? props.theme.colors.interfaceRed
        : props.theme.colors.neutralGrey4};
    && svg {
      min-width: ${props => props.theme.pxToRem(12)};
    }
    && .text {
      overflow: hidden;
      margin-right: ${props => props.theme.pxToRem(4)};
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
