import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { generatePath } from 'react-router';
import { ButtonEnums, Button } from 'components/atoms';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';

interface Props {
  orgId: string;
  title: string;
  address: string;
  locations?: number;
  orgCapabilityType: string;
  initFirmRosterDynamoTable?: (orgId: string, path: string, orgCapabilityType: string) => Promise<void>;
}

export const FirmSelectionItem: React.FC<Props> = ({
  orgId,
  title,
  address,
  locations,
  initFirmRosterDynamoTable,
  orgCapabilityType,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const path = generatePath(getPath(Routes.FIRM_ROOT), { orgId });

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setLoading(true);
      if (initFirmRosterDynamoTable) {
        const buttonText = (e?.target as HTMLAnchorElement)?.textContent;
        handleEvent({ clickValue: `button:link:int::${buttonText}:${path}` }, NAV_CLICK);
        initFirmRosterDynamoTable(orgId, path, orgCapabilityType);
      }
    },
    [path, orgId, initFirmRosterDynamoTable, orgCapabilityType]
  );

  return (
    <StyledListItem>
      <StyledCompanyInfo>
        <StyledCompanyTitle>{title}</StyledCompanyTitle>
        <StyledCompanyAddress>{address}</StyledCompanyAddress>
        {Boolean(locations) && (
          <StyledCompanyLocations>
            + {locations} {Number(locations) > 1 ? 'locations' : 'location'}
          </StyledCompanyLocations>
        )}
      </StyledCompanyInfo>
      {initFirmRosterDynamoTable && (
        <StyledButton
          overrideAnalytics={true}
          loading={loading}
          size={ButtonEnums.sizes.medium}
          variant={ButtonEnums.variants.primary}
          testId={`view-organization-${orgId}-button`}
          onClick={handleButtonClick}
          iconName="chevron right"
          iconPosition={ButtonEnums.iconPosition.right}
        >
          View [branch]
        </StyledButton>
      )}
    </StyledListItem>
  );
};

const StyledListItem = styled.li`
  min-height: ${props => props.theme.pxToRem(120)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => props.theme.pxToRem(10)};
  padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(40)};
  box-shadow: 0 0 ${props => props.theme.pxToRem(8)} 0 ${props => props.theme.colors.neutralGrey3};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledCompanyInfo = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const StyledCompanyTitle = styled.h4`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.l};
  margin-top: 0;
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

const StyledCompanyAddress = styled.h5`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.s};
  margin-bottom: ${props => props.theme.pxToRem(3)};
  margin-top: 0;
  color: ${props => props.theme.colors.neutralGrey4};
`;

const StyledCompanyLocations = styled.p`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledButton = styled(Button)`
  width: ${props => props.theme.pxToRem(150)};
`;
