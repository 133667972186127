import React from 'react';

interface ParagraphProps {
  children: any;
  className?: string;
  testId?: string;
}

export const Paragraph: React.FC<ParagraphProps> = ({ children, className, testId }) => (
  <p tabIndex={0} className={className} data-testid={testId}>
    {children}
  </p>
);
