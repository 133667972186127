import React from 'react';
import styled from 'styled-components';
import { formatPriceToTwoDecimalPoints, centPriceToString } from 'utils';
import { UserMemberTypes } from 'modules/user/constants';
import { productCurrencySelector } from 'modules/products/selectors';
import { useSelector } from 'react-redux';
import { getFormattedBundlePrice } from 'modules/products/helpers';

interface Props {
  bundleCardInfo?: State.BundleCardInfo;
  bundleInfo?: State.BundleInfo;
  isAuth?: boolean | null;
  userMemberType?: UserMemberTypes;
  isSearchResult?: boolean;
  isUserSuspendedByEthics?: boolean;
}

export const BundleCardDetails: React.FC<Props> = React.memo(
  ({ bundleCardInfo, bundleInfo, isAuth, userMemberType, isSearchResult = false, isUserSuspendedByEthics }) => {
    const currency = useSelector(productCurrencySelector)?.label;
    const isBundleProduct: boolean = !!bundleInfo;
    const { numberOfComponentProducts, bundleDiscountPercent } = bundleInfo || bundleCardInfo || {};
    const cardPrices: State.FormattedBundleCardPrices | undefined = bundleCardInfo?.prices;
    const isMember: boolean = userMemberType === UserMemberTypes.PREMIUM || userMemberType === UserMemberTypes.MEMBER;
    const discountExists = Boolean(bundleDiscountPercent);
    const showNumberOfComponentProductsFirst: boolean = isBundleProduct && isSearchResult;
    const productPrices = getFormattedBundlePrice(bundleInfo?.prices, currency);
    const {
      standardNonMemberPriceFormatted,
      minPriceNonMemberBundleFormatted,
      maxPriceNonMemberBundleFormatted,
      minPriceMemberBundleFormatted,
      maxPriceMemberBundleFormatted,
    } = React.useMemo(() => {
      const fractionAfterDiscount = (100 - (bundleInfo?.bundleDiscountPercent || 0)) / 100;
      return {
        standardNonMemberPriceFormatted: centPriceToString(
          productPrices?.nonMember?.max?.amount || 0,
          productPrices?.nonMember?.max?.currency || currency
        ),
        maxPriceNonMemberBundleFormatted: centPriceToString(
          (productPrices?.nonMember?.max?.amount || 0) * fractionAfterDiscount,
          productPrices?.nonMember?.max?.currency || currency
        ),
        minPriceNonMemberBundleFormatted: centPriceToString(
          (productPrices?.nonMember?.min?.amount || 0) * fractionAfterDiscount,
          productPrices?.nonMember?.min?.currency || currency
        ),
        maxPriceMemberBundleFormatted: centPriceToString(
          (productPrices?.member?.max?.amount || 0) * fractionAfterDiscount,
          productPrices?.member?.max?.currency || currency
        ),
        minPriceMemberBundleFormatted: centPriceToString(
          (productPrices?.member?.min?.amount || 0) * fractionAfterDiscount,
          productPrices?.member?.min?.currency || currency
        ),
      };
    }, [bundleInfo, productPrices, currency]);

    const standardPrice: string | undefined = React.useMemo(
      () =>
        isBundleProduct
          ? standardNonMemberPriceFormatted
          : cardPrices &&
            formatPriceToTwoDecimalPoints(
              cardPrices.standardNonMemberPrice.amount,
              cardPrices.standardNonMemberPrice.currency
            ),
      [isBundleProduct, cardPrices, standardNonMemberPriceFormatted]
    );

    const getBundleProductPriceRange = React.useMemo(() => {
      const minPrice: string | undefined = isAuth
        ? isMember
          ? minPriceMemberBundleFormatted
          : minPriceNonMemberBundleFormatted
        : minPriceMemberBundleFormatted;
      const maxPrice: string | undefined = isMember ? maxPriceMemberBundleFormatted : maxPriceNonMemberBundleFormatted;
      const isMinAndMaxBundlePriceEqual = Boolean(minPrice && maxPrice && minPrice === maxPrice);

      if (!isAuth && !isMember) {
        return isMinAndMaxBundlePriceEqual ? minPrice : `${minPrice} - ${maxPrice}`;
      }

      if (isAuth && !isMember) {
        return `From ${minPriceNonMemberBundleFormatted} (${minPriceMemberBundleFormatted} for members)`;
      }

      if (isUserSuspendedByEthics && isMember) {
        return isMinAndMaxBundlePriceEqual ? minPrice : `${minPrice} - ${maxPrice}`;
      }

      return `From ${minPriceMemberBundleFormatted} (Member price)`;
    }, [
      isMember,
      isAuth,
      minPriceMemberBundleFormatted,
      minPriceNonMemberBundleFormatted,
      maxPriceMemberBundleFormatted,
      maxPriceNonMemberBundleFormatted,
      isUserSuspendedByEthics,
    ]);

    const getBundleCardPriceRange = React.useMemo(() => {
      const isMinAndMaxBundlePriceEqual = Boolean(
        cardPrices && cardPrices.minBundlePrice.amount === cardPrices.maxBundlePrice.amount
      );
      return cardPrices && !isBundleProduct
        ? isMinAndMaxBundlePriceEqual
          ? formatPriceToTwoDecimalPoints(cardPrices.minBundlePrice.amount, cardPrices.minBundlePrice.currency)
          : `${formatPriceToTwoDecimalPoints(
              cardPrices.minBundlePrice.amount,
              cardPrices.minBundlePrice.currency
            )} - ${formatPriceToTwoDecimalPoints(cardPrices.maxBundlePrice.amount, cardPrices.maxBundlePrice.currency)}`
        : ``;
    }, [cardPrices, isBundleProduct]);
    const bundlePriceRange = isBundleProduct ? getBundleProductPriceRange : getBundleCardPriceRange;

    const renderPrices = () => (
      <StyledBundleTextContainer noMarginBottom={showNumberOfComponentProductsFirst}>
        {discountExists && standardPrice && !isBundleProduct && (
          <StyledBundleDetailsText isStruckThrough>{standardPrice}&nbsp;</StyledBundleDetailsText>
        )}
        <StyledBundleDetailsText>{bundlePriceRange}</StyledBundleDetailsText>
      </StyledBundleTextContainer>
    );

    const renderDiscount = () => (
      <StyledBundleTextContainer noMarginBottom={showNumberOfComponentProductsFirst}>
        <StyledBundleDetailsText isTeal={isBundleProduct}>Discount:&nbsp;</StyledBundleDetailsText>
        <StyledBundleDetailsText isBold isTeal={isBundleProduct}>
          {bundleDiscountPercent}% off
        </StyledBundleDetailsText>
      </StyledBundleTextContainer>
    );

    const renderNumberOfComponentProducts = () => (
      <StyledBundleTextContainer noMarginBottom={showNumberOfComponentProductsFirst}>
        <StyledBundleDetailsText>No. of Products: {numberOfComponentProducts}</StyledBundleDetailsText>
      </StyledBundleTextContainer>
    );

    return (
      <StyledDetails data-testid="bundle-card-details" biggerFont={showNumberOfComponentProductsFirst}>
        {showNumberOfComponentProductsFirst && !!numberOfComponentProducts && renderNumberOfComponentProducts()}
        {!!bundlePriceRange && renderPrices()}
        {!!bundleDiscountPercent && renderDiscount()}
        {!showNumberOfComponentProductsFirst && !!numberOfComponentProducts && renderNumberOfComponentProducts()}
      </StyledDetails>
    );
  }
);

const StyledBundleDetailsText = styled.div<{ isStruckThrough?: boolean; isBold?: boolean; isTeal?: boolean }>`
  text-decoration: ${props => (props.isStruckThrough ? 'line-through' : 'none')};
  font-weight: ${props => (props.isBold ? props.theme.fontWeights.medium : props.theme.fontWeights.light)};
  ${props => props.isTeal && `color: ${props.theme.colors.secondaryTeal};`};
`;

const StyledBundleTextContainer = styled.div<{ noMarginBottom?: boolean }>`
  display: flex;
  margin-bottom: ${props => (props.noMarginBottom ? '' : props.theme.pxToRem(9))};
`;

const StyledDetails = styled.div<{ biggerFont?: boolean }>`
  font-size: ${props => (props.biggerFont ? props.theme.fontSizes.xs : props.theme.fontSizes.xxs)};
  font-weight: ${props => props.theme.fontWeights.light};
`;
