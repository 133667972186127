import React from 'react';

export const useQualtricsFeedback = () => {
  const removeElement = React.useCallback(() => {
    const styleElement = document.querySelector('#QSI_ScreenCapture');

    if (styleElement && styleElement.parentNode) styleElement.parentNode.removeChild(styleElement);
  }, []);

  React.useEffect(() => {
    const body = document.querySelector('body');
    if (!body) return;
    const element = document.querySelector('#QSI_ScreenCapture');
    const style = document.createElement('style');
    if (element) {
      element.classList.add('QSI_ScreenCapture');
    }

    style.innerHTML = `
			.QSI_ScreenCapture > div > img {
				width: 100px;
				height: 96px;
			}
		`;

    document.body.appendChild(style);

    // cleanup script on hook unmount
    return removeElement;
  }, [removeElement]);
};
