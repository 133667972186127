import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { ReactComponent as Arrow } from 'resources/images/icon-ui-icons-ic-arrow-back.svg';
import { DropdownWithLabel, Footer } from 'components/molecules';
import { ExamCardResults } from 'components/molecules/ExamCardResults/ExamCardResults';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { Container, Divider } from 'components/atoms';
import { Loader } from 'components/atoms/Loader/Loader';
import { getPath } from 'utils';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
import { User } from 'mxp-schemas';
import { Routes } from 'constants/index';

interface Props {
  getUserSyllabus: () => void;
  getExamHistory: () => void;
  allExamsResults: User.AllStudentExamResults[];
  userSyllabus: string[];
  isSyllabusLoading: boolean;
  isExamHistoryResultLoading: boolean;
}

const OptionSelection = {
  Key: 'Option',
  All: 'Select All',
};

export const PageCimaAllExamResults: React.FC<Props> = ({
  getUserSyllabus,
  getExamHistory,
  allExamsResults,
  userSyllabus,
  isSyllabusLoading,
  isExamHistoryResultLoading,
}) => {
  const history = useHistory();

  const [selectedOption, setSelectedOption] = useState(OptionSelection.All);
  const [dropdownOption, setDropdownOption] = useState([
    { key: OptionSelection.Key, text: OptionSelection.All, value: OptionSelection.All },
  ]);
  const [examResults, setExamResults] = useState(allExamsResults);

  const onButtonClick = useCallback(
    (event?: any) => {
      const destination = getPath(Routes.CIMA_EXAMS_DASHBOARD_ROOT);
      const { pageName, siteSection } = getPageNameAndSiteSection();
      const buttonText = event?.target?.innerText || event?.target?.textContent || '';

      handleEvent(
        {
          clickValue: `button:link:int::${buttonText}:${destination}`,
          pageName,
          siteSection,
        },
        NAV_CLICK
      );

      history.push(destination);
    },
    [history]
  );

  const updateStateWithoutDuplicates = (newSyllabus: { key: string; text: string; value: string }) => {
    if (newSyllabus?.value?.length) {
      const isDuplicate = dropdownOption.some(options => options.value === newSyllabus.value);

      if (!isDuplicate) {
        setDropdownOption(prevSyllabus => [...prevSyllabus, newSyllabus]);
      }
    }
  };

  const updateDropdownOption = useCallback(() => {
    if (userSyllabus.length) {
      userSyllabus.forEach((item: string, index: number) => {
        const lastOption = dropdownOption[dropdownOption.length - 1];
        const newKey = `${lastOption.key}${index + 1}`;

        const newSyllabus = {
          key: newKey,
          text: item,
          value: item,
        };

        updateStateWithoutDuplicates(newSyllabus);
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOption, updateStateWithoutDuplicates]);

  const filterSelectionExams = useCallback(() => {
    if (selectedOption === OptionSelection.All) {
      setExamResults(allExamsResults);
    }

    const filteredRecords = examResults
      ?.map((response: any) => {
        const exams = response?.exams.filter((item: any) => item.syllabus === selectedOption);
        return (
          exams?.length && {
            qualificationLevel: response.qualificationLevel,
            exams,
          }
        );
      })
      .filter(Boolean);

    setExamResults(filteredRecords); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    // Initial fetch for user syllabus
    getUserSyllabus();
    getExamHistory();
  }, [getUserSyllabus, getExamHistory]);

  useEffect(() => {
    // update dropdown option for syllabus
    updateDropdownOption();
    if (!examResults?.length) {
      setExamResults(allExamsResults);
    }
  }, [updateDropdownOption, allExamsResults, examResults]);

  useEffect(() => {
    // Refetch exam results data when selected option has value
    filterSelectionExams();
  }, [filterSelectionExams]);

  const handleDropdownChange = useCallback(
    (event: any, data: any) => {
      if (data?.value) {
        setSelectedOption(data.value);
        setExamResults(allExamsResults);
      }
    },
    [allExamsResults]
  );

  return (
    <>
      <HeaderPageWrapper>
        <StyledContainer>
          <StyledBackArrowContainer onClick={onButtonClick}>
            <StyledBackText>
              <StyledArrow />
              Go to my exam dashboard
            </StyledBackText>
          </StyledBackArrowContainer>
          <StyledText>All Your Exam Results</StyledText>
          <StyledDividerLine />

          {isExamHistoryResultLoading && examResults.length ? (
            <StyledDropdown
              testId="syllabus"
              title="Syllabus"
              placeholder="Show All"
              value={selectedOption}
              options={dropdownOption}
              onChange={handleDropdownChange}
              disabled={!isSyllabusLoading}
            />
          ) : (
            <></>
          )}

          {!isExamHistoryResultLoading && (
            <LoaderWrapper>
              <Loader active content="Loading" inline />
            </LoaderWrapper>
          )}

          {isExamHistoryResultLoading && !examResults.length ? (
            <StyledNoResults>
              <StyledText>No results found</StyledText>
            </StyledNoResults>
          ) : (
            examResults.map((data: User.AllStudentExamResults) => (
              <ExamCardResults allExamsResults={data} key={data.qualificationLevel} />
            ))
          )}
        </StyledContainer>
        <Footer />
      </HeaderPageWrapper>
    </>
  );
};

const StyledContainer = styled(Container)`
  &&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(100)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(40)};
    }
  }
`;

const StyledArrow = styled(Arrow)`
  position: relative;
  top: ${props => props.theme.pxToRem(4)};
`;

const StyledBackText = styled.div`
  height: ${props => props.theme.pxToRem(22)};
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: 300;
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralGrey8};
  &&&&:hover {
    text-decoration: underline;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const StyledBackArrowContainer = styled.div`
  height: ${props => props.theme.pxToRem(50)};
  margin: 0 0 ${props => props.theme.pxToRem(23)};
  object-fit: contain;
  margin-top: ${props => props.theme.pxToRem(44)};
  cursor: pointer;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(10)};
    margin-right: ${props => props.theme.pxToRem(10)};
    position: relative;
    width: 100%;
  }
`;

const StyledText = styled.div`
  font-family: Roboto;
  font-weight: ${props => props.theme.fontWeights.thin};
  font-size: ${props => props.theme.fontSizes.xl};
  line-height: 1.38;
  letter-spacing: normal;
  margin-bottom: ${props => props.theme.pxToRem(20)}
  color: ${props => props.theme.colors.neutralGrey8};
   ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(10)};
    margin-right: ${props => props.theme.pxToRem(10)};
    position: relative;
    width: 100%;
  }
`;

const StyledNoResults = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  height: calc(30vh + ${props => props.theme.pxToRem(20)});
`;

const StyledDividerLine = styled(Divider)`
  margin-top: ${props => props.theme.pxToRem(40)} !important;
  margin-bottom: ${props => props.theme.pxToRem(40)} !important;
  width: ${props => props.theme.pxToRem(1123)};
  height: ${props => props.theme.pxToRem(2)};
  object-fit: contain;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: auto;
  }
`;

const StyledDropdown = styled(DropdownWithLabel)`
  ${props => `
    &&& {
      width: ${props.theme.pxToRem(265)};
      padding: ${props.theme.pxToRem(12)};
      height: ${props.theme.pxToRem(46)};
      margin-right: ${props.theme.pxToRem(12)};
      border-radius: ${props.theme.pxToRem(5)};
      margin-bottom: ${props.theme.pxToRem(20)};
      
      && :hover {
        cursor: pointer;
      }
      && :focus {
        cursor: text;
      }
    }
  `}
`;

const LoaderWrapper = styled.div`
  display: grid;
  align-items: center;
  height: calc(30vh + ${props => props.theme.pxToRem(20)});
`;
