import React, { MutableRefObject } from 'react';
import styled, { css, ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Product } from 'mxp-schemas';
import { CategoryDecorationMap } from 'constants/index';
import { productTypeToLabel } from 'utils';

interface CategoryProps {
  categoryName: string;
  categorySlug: string;
  isProduct?: boolean;
  className?: string;
  thumbnail?: boolean;
  isSearchResult?: boolean;
  noCategoryName?: boolean;
  isHeroCard?: boolean;
  isPadded?: boolean;
  isBundleCard?: boolean;
  isBgColorDark?: boolean;
  isBordered?: boolean;
  categoryRef?: MutableRefObject<HTMLDivElement | null>;
}

export const Category: React.FC<CategoryProps> = React.memo(
  ({
    categoryName,
    categorySlug,
    className = '',
    thumbnail,
    isSearchResult = false,
    isProduct = false,
    noCategoryName = false,
    isHeroCard,
    isPadded = true,
    isBundleCard = false,
    isBgColorDark = false,
    categoryRef,
  }) => {
    const IconComponent: any = CategoryDecorationMap[categorySlug]?.icon || CategoryDecorationMap.DEFAULT.icon;

    return (
      <CategoryHolderWrapper
        className="category-box-wrapper"
        data-testid="category-wrapper"
        thumbnail={thumbnail}
        isSearchResult={isSearchResult}
        color={
          isBundleCard
            ? CategoryDecorationMap.BUNDLE.color
            : CategoryDecorationMap[categorySlug]?.color || CategoryDecorationMap.DEFAULT.color
        }
        isBgColorDark={isBgColorDark}
        isBordered={!(!noCategoryName || (noCategoryName && isProduct))}
      >
        {(!noCategoryName || (noCategoryName && isProduct)) && (
          <>
            <StylesCategoryHolder
              isHeroCard={isHeroCard}
              data-testid={`category-box-${productTypeToLabel(categoryName as Product.ProductType).toLowerCase()}`}
              className="category-box"
              thumbnail={thumbnail}
              isPadded={isPadded}
              isSearchResult={isSearchResult}
              isBundleCard={isBundleCard}
              isBgColorDark={isBgColorDark}
              ref={categoryRef}
            >
              {!isProduct && (
                <StyledIcon className="category-icon">
                  <IconComponent />
                </StyledIcon>
              )}
              {productTypeToLabel(categoryName as Product.ProductType)}
            </StylesCategoryHolder>
            <StyledCategoryLine
              isSearchResult={isSearchResult}
              thumbnail={thumbnail}
              isBgColorDark={isBgColorDark}
              color={
                isBundleCard
                  ? CategoryDecorationMap.BUNDLE.color
                  : CategoryDecorationMap[categorySlug]?.color || CategoryDecorationMap.DEFAULT.color
              }
            />
          </>
        )}
      </CategoryHolderWrapper>
    );
  }
);

const StyledCategoryLine = styled.div<ThemedStyledProps<Partial<CategoryProps>, DefaultTheme>>`
  flex: 1 1 auto;
  ${props =>
    props.thumbnail &&
    !props.isSearchResult &&
    css`
      border-bottom: ${props.theme.pxToRem(2)} solid ${props.color};
    `}
`;
const CategoryHolderWrapper = styled.div<ThemedStyledProps<Partial<CategoryProps>, DefaultTheme>>`
  display: flex;
  ${props =>
    props.thumbnail &&
    !props.isSearchResult &&
    css`
      color: ${props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8};
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30px;
    `}
  ${props =>
    props.isBordered &&
    `
      border-bottom: ${props.theme.pxToRem(2)} solid ${props.color};
    `}

  ${props =>
    props.isSearchResult &&
    `
       border-bottom: none;
    `}
`;

const StylesCategoryHolder = styled.div<ThemedStyledProps<Partial<CategoryProps>, DefaultTheme>>`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.2px;
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey7)};
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${props => {
    return props.isPadded ? props.theme.pxToRem(20) : 0;
  }};

  ${props =>
    props.thumbnail &&
    css`
      padding: ${props.theme.pxToRem(14)} ${props.theme.pxToRem(20)} 0;
      display: inline-block;
      margin: 0;
    `}

  ${props =>
    (props.isHeroCard || props.isBundleCard) &&
    `
      padding: ${props.theme.pxToRem(7)} ${props.theme.pxToRem(20)}`}
  ${props =>
    props.theme.mediaQueries.mobileOnly && props.isSearchResult
      ? `padding: ${props.theme.pxToRem(14)} ${props.theme.pxToRem(20)} 0 0`
      : ''}
`;

const StyledIcon = styled.span`
  display: inline-block;
  vertical-align: top;
  margin: 0 4px 0 0;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
