/* tslint:disable:no-object-literal-type-assertion */

import { createContext, useContext } from 'react';
import { MembershipTypes } from 'mxp-schemas';
import { initialState } from 'modules/membership/constants';

type employmentType = MembershipTypes.PracticalExperienceRequirementEmployment;
type supervisorType = MembershipTypes.PracticalExperienceRequirementSupervisor;
type skillsAndActivitiesType = MembershipTypes.PracticalExperienceRequirementSkillsCoreActivity;
type portfolioStatusType = MembershipTypes.PERPortfolio;
const state = initialState.practicalExperienceRequirement;

type ContextType = {
  isPageReadOnly: boolean;
  setIsPageReadOnly: (value: boolean) => void;
  showNotification: boolean;
  setShowNotification: (value: boolean) => void;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  data: {
    employment: employmentType[];
    skills: skillsAndActivitiesType[];
    activities: skillsAndActivitiesType[];
    portfolio: portfolioStatusType;
    newEmployment: employmentType;
  };

  employment: employmentType;
  setEmployment: (value: employmentType) => void;
  skill: skillsAndActivitiesType;
  setSkill: (value: skillsAndActivitiesType) => void;
  activity: skillsAndActivitiesType;
  setActivity: (value: skillsAndActivitiesType) => void;
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  supervisors: supervisorType[];
  setSupervisors: (value: supervisorType[]) => void;
  isSubmitted?: boolean;
  setIsSubmitted: (value: boolean) => void;
  isSupervisorReadOnly?: boolean;
  setIsSupervisorReadOnly: (value: boolean) => void;
  isSupervisorChanged?: boolean;
  setIsSupervisorChanged: (value: boolean) => void;
  isFormValid?: boolean;
  setIsFormValid: (value: boolean) => void;
  isChangingSupervisor?: boolean;
  isFromDashboard?: boolean;
  setIsFromDashboard: (value: boolean) => void;
  isEmployerEdit?: boolean;
  setIsEmployerEdit: (value: boolean) => void;
  isRoleEdit?: boolean;
  setIsRoleEdit: (value: boolean) => void;
  isSupervisorEdit?: boolean;
  setIsSupervisorEdit: (value: boolean) => void;
  setIsChangingSupervisor: (value: boolean) => void;
  setNewSupervisorEmployerData: (modifier: Partial<State.Employer>) => void;
  setNewEmployeeSupervisorData: (modifier: Partial<State.PracticalExperienceRequirementSupervisor>) => void;
  resetSupervisorEmployerData: () => void;
  resetSupervisorData: () => void;
  resetSupervisorEmployerAddress: () => void;
  setSupervisorOnEdit: (modifier: Partial<State.PracticalExperienceRequirementEmployment>) => void;
  success: boolean;
  setIsSuccess: (value: boolean) => void;
  setNewEmployeeSupervisorFormData: (modifier: Partial<State.PracticalExperienceRequirementSupervisor>) => void;
  setNewSupervisorEmployerFormData: (modifier: Partial<State.Employer>) => void;
  resetSupervisorFormData: () => void;
  resetSupervisorEmployerFormData: () => void;
  resetSupervisorEmployerAddressFormData: () => void;
  setSupervisorFormDataOnEdit: (modifier: Partial<State.PracticalExperienceRequirementEmployment>) => void;
  isResubmit: boolean;
  isSupervisorValidationAllowed?: boolean;
  setIsSupervisorValidationAllowed: (value: boolean) => void;
  setIsResubmit: (value: boolean) => void;
};

export const PageContext = createContext<ContextType>({
  // Default values
  isPageReadOnly: false,
  setIsPageReadOnly: () => {
    //
  },
  data: {
    employment: [],
    skills: [],
    activities: [],
    portfolio: {
      id: '',
      personAccountId: '',
      hasBeenAudited: true,
      status: null,
    },
    newEmployment: initialState.practicalExperienceRequirement.newEmployment,
  },
  showNotification: false,
  setShowNotification: () => {
    //
  },
  showModal: false,
  setShowModal: () => {
    //
  },
  employment: state.employment[0],
  skill: state.skills[0],
  activity: state.activities[0],
  setEmployment: () => state.employment[0],
  setSkill: () => state.skills[0],
  setActivity: () => state.activities[0],
  isSaving: false,
  setIsSaving: () => {
    //
  },
  supervisors: [state.newEmployment.supervisor] as supervisorType[],
  setSupervisors: () => {
    //
  },
  isSubmitted: false,
  setIsSubmitted: () => {
    //
  },
  isSupervisorReadOnly: true,
  setIsSupervisorReadOnly: () => {
    //
  },
  isSupervisorChanged: false,
  setIsSupervisorChanged: () => {
    //
  },
  isFormValid: false,
  setIsFormValid: () => {
    //
  },
  isChangingSupervisor: false,
  setIsChangingSupervisor: () => {
    //
  },
  isFromDashboard: false,
  setIsFromDashboard: () => {
    //
  },
  isEmployerEdit: false,
  setIsEmployerEdit: () => {
    //
  },
  isRoleEdit: false,
  setIsRoleEdit: () => {
    //
  },
  isSupervisorEdit: false,
  setIsSupervisorEdit: () => {
    //
  },
  setNewSupervisorEmployerData: () => {
    //
  },
  setNewEmployeeSupervisorData: () => {
    //
  },
  resetSupervisorEmployerData: () => {
    //
  },
  resetSupervisorData: () => {
    //
  },
  resetSupervisorEmployerAddress: () => {
    //
  },
  setSupervisorOnEdit: () => {
    //
  },
  success: state.success,
  setIsSuccess: () => {
    //
  },
  setNewEmployeeSupervisorFormData: () => {
    //
  },
  setNewSupervisorEmployerFormData: () => {
    //
  },
  resetSupervisorFormData: () => {
    //
  },
  resetSupervisorEmployerFormData: () => {
    //
  },
  resetSupervisorEmployerAddressFormData: () => {
    //
  },
  setSupervisorFormDataOnEdit: () => {
    //
  },
  isResubmit: state.isResubmit,
  setIsResubmit: () => {
    //
  },
  isSupervisorValidationAllowed: false,
  setIsSupervisorValidationAllowed: () => {
    //
  },
});
export const usePageContext = () => useContext(PageContext);
