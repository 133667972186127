import React from 'react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import { ReactComponent as SchoolHatIcon } from 'resources/images/ic-school.svg';
import { MomentHelpers } from 'utils/MomentHelpers';
import { UserMemberTypes } from 'modules/user/constants';
import { availableFormatToLabel } from 'utils';
import { CardPrice } from 'components/atoms/CardPrice/CardPrice';
import { BundleCardDetails } from 'components/atoms/BundleCardDetails/BundleCardDetails';
import { Product } from 'mxp-schemas';
interface Props {
  fileFormat?: string[];
  studyLevel?: string[];
  priceRange?: Product.ProductCardPrice;
  credits?: number[];
  conferenceCity?: string;
  conferenceState?: string;
  startDate: string | null;
  endDate: string | null;
  isRelatedContent?: boolean;
  variantsCount?: number;
  parentProductSessions?: number;
  upcomingParentProductDates?: string[];
  isBundleCard?: boolean;
  isAggregationCard?: boolean;
  bundleCardInfo?: State.BundleCardInfo;
  bundleInfo?: State.BundleInfo;
  isAuth?: boolean | null;
  userMemberType?: UserMemberTypes;
  isSearchResult?: boolean;
  isContribution?: boolean;
  isBgColorDark?: boolean;
  currencyLabel?: Product.ProductCurrencyLabel;
  currencySign?: Product.ProductCurrencySign;
  isUserSuspendedByEthics?: boolean;
}

export const ProductDetails: React.FC<Props> = React.memo(
  ({
    isBgColorDark = false,
    fileFormat,
    studyLevel,
    priceRange,
    credits,
    startDate,
    endDate,
    conferenceCity,
    conferenceState,
    isRelatedContent,
    variantsCount,
    parentProductSessions,
    upcomingParentProductDates,
    isBundleCard = false,
    isAggregationCard = false,
    bundleCardInfo,
    bundleInfo,
    isAuth = false,
    userMemberType,
    isSearchResult = false,
    isContribution = false,
    currencyLabel,
    currencySign,
    isUserSuspendedByEthics,
  }) => {
    const formatedStartDate: string | null = startDate && MomentHelpers.getDate(startDate);
    const formatedEndDate: string | null = endDate && MomentHelpers.getDate(endDate);
    const location = [conferenceCity, conferenceState].filter(Boolean).join(', ');
    const moreEvents = variantsCount && variantsCount > 1 ? ` + ${variantsCount - 1} more` : '';

    const isMultiDay: boolean = Boolean(fileFormat && fileFormat.includes(Product.AvailableFormat.MULTI_DAY));
    const isSeries: boolean = Boolean(fileFormat && fileFormat.includes(Product.AvailableFormat.SERIES));

    const parentProductParts: string = parentProductSessions
      ? isMultiDay
        ? `${parentProductSessions}-part webcast `
        : isSeries
        ? `${parentProductSessions}-part series `
        : ''
      : '';

    const parentProductNextStartDate: string = upcomingParentProductDates?.[0]
      ? MomentHelpers.getDate(upcomingParentProductDates[0])
      : '';
    const parentProductNextStartString: string = parentProductNextStartDate
      ? `Next start: ${parentProductNextStartDate}`
      : '';
    const parentProductMoreDatesString: string =
      isMultiDay && upcomingParentProductDates && upcomingParentProductDates?.length > 1
        ? ` + ${upcomingParentProductDates?.length - 1} more`
        : '';
    const parentProductDates: string =
      parentProductNextStartString && parentProductMoreDatesString
        ? `(${parentProductNextStartString}${parentProductMoreDatesString})`
        : parentProductNextStartString
        ? `(${parentProductNextStartString})`
        : '';

    const dateRange =
      formatedStartDate === formatedEndDate || !endDate
        ? `${formatedStartDate}`
        : `${formatedStartDate} - ${formatedEndDate}`;

    const parentProductFormattedInfo: string = React.useMemo(
      () => `${parentProductParts}${parentProductDates}`,
      [parentProductParts, parentProductDates]
    );

    const formattedLabel: string = React.useMemo(() => {
      if (fileFormat) {
        return fileFormat
          .map(format => {
            if (format?.includes('[]')) {
              const splittedFormat = format?.split('[]');
              return `${availableFormatToLabel(splittedFormat[0])} ${
                splittedFormat.length === 3 ? `- ${splittedFormat[1]} ` : splittedFormat[1]
              }${splittedFormat[2] ? splittedFormat[2] : ''}`;
            }
            return availableFormatToLabel(format);
          })
          .filter(Boolean)
          .join(', ');
      }

      return '';
    }, [fileFormat]);

    const renderProductCPE = (cpeCredit: number[] | undefined) => {
      const cpeCreditSize = cpeCredit?.length;

      if (!cpeCredit || !cpeCreditSize || !cpeCredit[0]) return null;
      return (
        <StyledDiv data-testid="product-details-credit" isBgColorDark={isBgColorDark}>
          <StyledSchoolIcon />
          <StyledCPE>CPE Credits:</StyledCPE>
          {cpeCredit[0] === 255
            ? 'Unlimited'
            : cpeCreditSize > 1
            ? `${cpeCredit[0]} - ${cpeCredit[cpeCreditSize - 1]}`
            : cpeCredit[0]}
        </StyledDiv>
      );
    };

    return (
      <StyledProductDetails
        data-testid="product-details"
        isRelatedContent={isRelatedContent}
        isAggregationCard={isAggregationCard}
        isBgColorDark={isBgColorDark}
      >
        {fileFormat && !isMultiDay && !isSeries && (
          <div tabIndex={0} data-testid="product-details-fileFormat">
            {formattedLabel}
          </div>
        )}
        {studyLevel && !isBundleCard && (
          <div data-testid="product-details-studyLevel" tabIndex={0}>
            Level: {studyLevel.join(', ')}
          </div>
        )}
        {startDate && endDate && !isMultiDay && !isSeries && !isBundleCard && (
          <div tabIndex={0} data-testid="product-details-timeRange">
            {dateRange}
            {moreEvents}
            {location && formatedStartDate !== formatedEndDate && ` | ${location}`}
          </div>
        )}
        {(isMultiDay || isSeries) && (
          <div tabIndex={0} data-testid="parent-product-upcoming-dates">
            {parentProductFormattedInfo}
          </div>
        )}
        {isBundleCard
          ? (bundleCardInfo || bundleInfo) && (
              <BundleCardDetails
                bundleCardInfo={bundleCardInfo}
                bundleInfo={bundleInfo}
                userMemberType={userMemberType}
                isAuth={isAuth}
                isSearchResult={isSearchResult}
                isUserSuspendedByEthics={isUserSuspendedByEthics}
              />
            )
          : priceRange && (
              <CardPrice
                prices={priceRange}
                isContribution={isContribution}
                currencyLabel={currencyLabel}
                currencySign={currencySign}
                isUserSuspendedByEthics={isUserSuspendedByEthics}
              />
            )}
        {credits && <>{renderProductCPE(credits)}</>}
      </StyledProductDetails>
    );
  }
);

const StyledProductDetails = styled.div<
  ThemedStyledProps<
    { isRelatedContent: boolean | void; isBgColorDark: boolean; isAggregationCard: boolean },
    DefaultTheme
  >
>`
  ${props => `
    font-size: ${props.theme.fontSizes.xs}
    font-weight: ${props.theme.fontWeights.light};
    color: ${props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8};
    margin-top: ${props.theme.pxToRem(12)};
    margin-bottom: ${props.isAggregationCard ? props.theme.pxToRem(8) : '0'};
    line-height: 1.43;
  `}
`;

const StyledDiv = styled.div<{ isBgColorDark: boolean }>`
  ${props => `
    font-size: ${props.theme.fontSizes.xxs};
    font-weight: ${props.theme.fontWeights.light};
    padding-top: ${props.theme.pxToRem(5)};
    display: flex;

    &&&& {
      svg {
        path {
          fill: ${props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8};
        }
      }
    }
  `}
`;

const StyledCPE = styled.div`
  ${props => `
    padding-right: ${props.theme.pxToRem(3)};
  `}
`;

const StyledSchoolIcon = styled(SchoolHatIcon)`
  width: ${props => props.theme.pxToRem(14)};
  height: ${props => props.theme.pxToRem(14)};
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
  }
`;
