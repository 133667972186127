import { createSelector } from 'reselect';
import { slugIdMapCreator } from './helpers';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.trends,
  trendsRoot => trendsRoot
);

export const trendsHashSelector = createSelector(
  rootSelector,
  (trendsRoot: State.Trends): State.TrendsHash | null => trendsRoot.trendsHash
);

export const trendsSelector = createSelector(
  trendsHashSelector,
  (trendsHash: State.TrendsHash | null): State.Trend[] | null => trendsHash && Object.values(trendsHash)
);

export const trendSlugIdMapSelector = createSelector(trendsSelector, slugIdMapCreator);

export const trendNameByTrendSlugSelectorFactory = (trendSlug: string) =>
  createSelector(
    [trendsHashSelector, trendSlugIdMapSelector],
    (trendsHash: State.TrendsHash | null, trendsSlugIdMap: { [key: string]: string }): string => {
      if (!trendSlug || !trendsHash || !trendsSlugIdMap) return '';
      const trendId: string = trendsSlugIdMap[trendSlug];
      if (!trendId) return '';
      const trend: State.Trend = trendsHash[trendId];
      return trend ? trend.name : '';
    }
  );
