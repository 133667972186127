import React from 'react';
import styled from 'styled-components/macro';
import {
  RadioButton,
  Button,
  Divider,
  ButtonEnums,
  NotificationBanner,
  NotificationBannerEnums,
} from 'components/atoms';
import { ReactComponent as ErrorComponent } from 'resources/images/ic-error.svg';
import { Invoices } from 'mxp-schemas';
import { ContactUsDetails } from '../PaymentOptions/PaymentOptionsText';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { fullCimaInvoiceDetailsSelector } from 'modules/clientAdmin/selectors';
import { getCurrencySign } from 'utils';

interface Props {
  isCreditOrDebitCardPaymentOption: boolean;
  noSelectedPaymentOption: boolean;
  amount: number;
  formattedBalance: string;
  isValidAmountCheckError: boolean;
  installmentTerm: string | null;
  isAchPaymentOption: boolean;
  isPaypalPaymentOption: boolean;
  goToNextScreen: (paymentOption: string, amount: number | null) => void;
  isCenterMembershipJourney?: boolean;
  loading?: boolean;
}

export const PaymentAmount: React.FC<Props> = ({
  isCreditOrDebitCardPaymentOption,
  noSelectedPaymentOption,
  amount,
  formattedBalance,
  isValidAmountCheckError,
  installmentTerm,
  isAchPaymentOption,
  isPaypalPaymentOption,
  goToNextScreen,
  isCenterMembershipJourney,
  loading,
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [paymentOption, setPaymentOption] = React.useState(Invoices.PaymentAmountOptions.FULL_PAYMENT);
  const isFullPayment: boolean = paymentOption === Invoices.PaymentAmountOptions.FULL_PAYMENT;
  const isPartialPayment: boolean = paymentOption === Invoices.PaymentAmountOptions.PARTIAL_PAYMENT;
  const isDisabledButton: boolean =
    (isCenterMembershipJourney
      ? false
      : !isCreditOrDebitCardPaymentOption && !isAchPaymentOption && !isPaypalPaymentOption) ||
    noSelectedPaymentOption ||
    (isPartialPayment && (inputValue === '' || parseFloat(inputValue) === 0));
  const isAllowedPartialPayment: boolean = installmentTerm !== Invoices.InstallmentTerm.ONE_TIME;
  const invoices = useSelector(fullCimaInvoiceDetailsSelector);
  const currencySign = getCurrencySign(invoices.find(item => item.currency)?.currency);

  const onOtherAmountInputChange = React.useCallback((input: any) => {
    const { value } = input;
    setInputValue(value);
  }, []);

  const handleSelection = React.useCallback(paymentOptionValue => {
    setPaymentOption(paymentOptionValue);
  }, []);

  const handleGoToNextScreen = React.useCallback(() => {
    goToNextScreen(paymentOption, isFullPayment ? amount : parseFloat(inputValue));
  }, [goToNextScreen, paymentOption, isFullPayment, inputValue, amount]);

  return (
    <>
      {isCreditOrDebitCardPaymentOption && !isCenterMembershipJourney && (
        <>
          <StyledDivider />

          <StyledLabel>
            {isAllowedPartialPayment ? 'How much would you like to pay?' : 'Pay your Invoice balance'}
          </StyledLabel>

          <ContactUsDetails
            paymentOptionText={
              !isAllowedPartialPayment
                ? `if you
          wish to pay in a different currency`
                : ''
            }
            isCreditDebitPayment
            isAllowedPartialPayment={isAllowedPartialPayment}
          />

          <PaymentOptionRadioButton
            paymentAmountOption={Invoices.PaymentAmountOptions.FULL_PAYMENT}
            isFullPayment={isFullPayment}
            invoiceText={`Invoice Balance (${formattedBalance})`}
            handleSelection={handleSelection}
          />

          {isAllowedPartialPayment && (
            <StyledOtherAmountDiv>
              <PaymentOptionRadioButton
                paymentAmountOption={Invoices.PaymentAmountOptions.PARTIAL_PAYMENT}
                isPartialPayment={isPartialPayment}
                invoiceText={'Other Amount'}
                handleSelection={handleSelection}
              />
              {isPartialPayment && (
                <StyledOtherAmount
                  thousandSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  prefix={currencySign}
                  placeholder={`${currencySign}0.00`}
                  onValueChange={onOtherAmountInputChange}
                  disabled={isFullPayment}
                />
              )}
            </StyledOtherAmountDiv>
          )}

          {isValidAmountCheckError && isAllowedPartialPayment && (
            <StyledNotificationBanner
              testId="invoice-payment-partial-amount-error"
              childrenTestId="invoice-payment-partial-amount-error"
              variant={NotificationBannerEnums.variant.red}
              icon={<ErrorComponent />}
            >
              Your payment amount cannot be more than the invoice total.
            </StyledNotificationBanner>
          )}
        </>
      )}

      {(isCreditOrDebitCardPaymentOption ||
        noSelectedPaymentOption ||
        isCenterMembershipJourney ||
        isAchPaymentOption ||
        isPaypalPaymentOption) && (
        <StyledNextPageButton
          testId={'payment-options-next-page'}
          variant={ButtonEnums.variants.primary}
          onClick={handleGoToNextScreen}
          loading={loading}
          disabled={loading || isDisabledButton}
        >
          {!isCreditOrDebitCardPaymentOption ? 'Proceed ' : 'Continue to Payment'}
        </StyledNextPageButton>
      )}
    </>
  );
};

const PaymentOptionRadioButton: React.FC<{
  paymentAmountOption: string;
  isFullPayment?: boolean;
  isPartialPayment?: boolean;
  invoiceText: string;
  handleSelection: (paymentAmountOption: string) => void;
}> = ({ paymentAmountOption, isFullPayment, isPartialPayment, invoiceText, handleSelection }) => {
  const onClick = React.useCallback(() => {
    handleSelection(paymentAmountOption);
  }, [handleSelection, paymentAmountOption]);

  return (
    <StyledPayBalanceRadioDiv onClick={onClick}>
      <StyledRadioButton
        testId={
          paymentAmountOption === Invoices.PaymentAmountOptions.FULL_PAYMENT
            ? 'radio-invoice-payment-full-amount'
            : 'radio-invoice-payment-partial-amount'
        }
        checked={paymentAmountOption === Invoices.PaymentAmountOptions.FULL_PAYMENT ? isFullPayment : isPartialPayment}
      />
      <StyledTextInvoiceAmountToPay>{invoiceText}</StyledTextInvoiceAmountToPay>
    </StyledPayBalanceRadioDiv>
  );
};

const StyledRadioButton = styled(RadioButton)`
  margin-right: ${props => props.theme.pxToRem(12)};
`;

const StyledLabel = styled.div`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  margin-bottom: ${props => props.theme.pxToRem(8)};
  max-width: ${props => props.theme.pxToRem(354)};
`;

const StyledTextInvoiceAmountToPay = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledPayBalanceRadioDiv = styled.div`
  display: flex;
  padding: ${props => props.theme.pxToRem(16)} 0 ${props => props.theme.pxToRem(16)} 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledNextPageButton = styled(Button)`
  width: ${props => props.theme.pxToRem(189)};
  &&&& {
    margin-top: ${props => props.theme.pxToRem(24)};
  }
`;

const StyledDivider = styled(Divider)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(24)};
    margin-bottom: ${props => props.theme.pxToRem(39)};
  }
`;

const StyledOtherAmountDiv = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(16)};
`;

const StyledOtherAmount = styled(NumberFormat)`
  width: ${props => props.theme.pxToRem(264)};
  height: ${props => props.theme.pxToRem(44)};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSizes.s};
  padding: ${props =>
    `${props.theme.pxToRem(10)} ${props.theme.pxToRem(8)} ${props.theme.pxToRem(10)}  ${props.theme.pxToRem(15)}`};
  border-radius: ${props => props.theme.pxToRem(4)};
  border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey4};
  background-color: ${props => props.theme.colors.neutralWhite};
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  max-width: ${props => props.theme.pxToRem(401)};
  height: ${props => props.theme.pxToRem(68)};
  margin: 0 0 ${props => props.theme.pxToRem(16)} 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(16)};
  }
`;
