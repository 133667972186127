import React from 'react';
import styled from 'styled-components';
import { HtmlInputrops, Input } from 'semantic-ui-react';

export const TableSearchInput: React.FC<HtmlInputrops> = props => {
  return <StyledInput {...props} icon="search" iconPosition="left" />;
};

const StyledInput = styled(Input)`
  &&&& {
    input {
      border-radius: ${props => props.theme.pxToRem(22)} !important;
      border-color: ${props => props.theme.colors.neutralGrey6} !important;
      color: ${props => props.theme.colors.neutralGrey8};
      width: ${props => props.theme.pxToRem(550)};
      max-width: ${props => props.theme.pxToRem(550)};
      font-family: 'Roboto' !important;
      font-size: ${props => props.theme.fontSizes.s} !important;
      font-weight: ${props => props.theme.fontWeights.light} !important;
      &::placeholder {
        color: ${props => props.theme.colors.neutralGrey7} !important;
      }
      ${props => props.theme.mediaQueries.mobileOnly} {
        max-width: 100%;
        width: ${props => props.theme.pxToRem(550)};
      }
    }

    i {
      opacity: 1 !important;
      color: ${props => props.theme.colors.primaryPurple};
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      max-width: 100%;
      width: ${props => props.theme.pxToRem(550)};
    }
  }
`;
