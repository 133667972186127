import React from 'react';
interface SaveProps {
  size?: number;
  color?: string;
  className?: string;
}

export const Save = React.memo<SaveProps>(({ size = 24, color = '#000', className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" className={className}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M9.466 3v2H7v13l5-2.18L17 18v-6.524h2V21l-7-3-7 3 .01-16c0-1.1.89-2 1.99-2h2.466zm12.1-2L23 2.412 16.314 9 12 4.764l1.434-1.41 2.879 2.822L21.566 1z"
    />
  </svg>
));
