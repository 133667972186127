import React, { useCallback, useState, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AccordionPanelProps, Label } from 'semantic-ui-react';
import { centPriceToString, getPath } from 'utils';
import { MembershipTypes, User } from 'mxp-schemas';
import { Product, Admin as AdminUtils } from 'mxp-utils';
import { Button, FeedPlaceholder, ButtonEnums } from 'components/atoms';
import { MembershipCard } from 'components/organisms';
import {
  addMembershipPackageRelatedAddon,
  removeMembershipPackageRelatedAddon,
  filterMembershipRelatedAddonByCategory,
  seeMoreMembershipRelatedAddonProduct,
  membershipPackageUpdatePageOfSpecificAddonCategory,
  getMembershipAddOnProducts,
  setMembershipPackageTier,
} from 'modules/membership';
import {
  isRenewalSelector,
  membershipRelatedAddonsSelector,
  membershipSubscriptionsSelector,
  userMembershipRelatedAddonsSelector,
  isCimaMembershipJourneySelector,
  userMembershipTierSelector,
  isFLPSwitchSelector,
  isFLPUpgradeSelector,
  isCimaRenewalSelector,
  currentMembershipProduct,
  currentMembershipProductSkuSelector,
  membershipTiersSelector,
  isCimaRetiredLapsedSelector,
  isCimaPqCandidateRenewalSelector,
  isRegularUpgradingToRetiredSelector,
} from 'modules/membership/selectors';
import {
  learningPathwaySelector,
  cimaMembershipsTermTypeSelector,
  currentJourneyLearningPathwaySelector,
  isEPA1CompletedSelector,
  isEPA2CompletedSelector,
  cimaMembershipSelector,
  isCimaMemberSelector,
} from 'modules/user/selectors';
import { selectedPassedUSCPAExamSelector } from 'modules/exemptionProfessional/selectors';
import { USCpaExamEnum } from 'modules/exemptionProfessional/constants';
import { productCurrencySelector } from 'modules/products/selectors';
import { CimaMembershipPackagePanelWrapper } from '../CimaMembershipPackagePanelWrapper/CimaMembershipPackagePanelWrapper';
import { Routes, MembershipPackageAccordionStatus } from 'constants/index';

interface MembershipPackagePanelProps extends AccordionPanelProps {
  handleClick: () => void;
  loading?: boolean;
}

export const CimaMembershipAddonPanel: React.FC<MembershipPackagePanelProps> = ({ handleClick, loading }) => {
  const ALL = 'All';
  const [selectedCategory, setSelectedCategory] = useState(ALL);

  const dispatch = useDispatch();

  const { list: relatedAddOnProductList, categories } = useSelector(membershipRelatedAddonsSelector);
  const userChoicesRelatedAddonProduct = useSelector(userMembershipRelatedAddonsSelector);
  const isRenewal = useSelector(isRenewalSelector);
  const isCimaRenewal = useSelector(isCimaRenewalSelector);
  const productListData = useSelector(membershipSubscriptionsSelector);
  const isCimaMembershipJourney = useSelector(isCimaMembershipJourneySelector);
  const userMembershipTier = useSelector(userMembershipTierSelector);
  const learningPathway = useSelector(learningPathwaySelector);
  const currentMembershipKey = useSelector(cimaMembershipsTermTypeSelector);
  const isFLPSwitch = useSelector(isFLPSwitchSelector);
  const isFLPUpgrade = useSelector(isFLPUpgradeSelector);
  const isCimaPqCandidateRenewal = useSelector(isCimaPqCandidateRenewalSelector);
  const currentMembershipTier = useSelector(currentMembershipProductSkuSelector);
  const isSubscribedToFlp = AdminUtils.isFlpPathway(learningPathway as string);
  const cimaMemBody = useSelector(cimaMembershipSelector)?.membershipBody as string;

  const selectedPassedUSCPAExam = useSelector(selectedPassedUSCPAExamSelector);
  const currMembership = useSelector(currentMembershipProduct);

  const currentJourneyLearningPathway = useSelector(currentJourneyLearningPathwaySelector);
  const isCimaPqCurrentJourney = AdminUtils.isCimaPqPathway(currentJourneyLearningPathway as string);
  const isFlpCurrentJourney = AdminUtils.isFlpPathway(currentJourneyLearningPathway as string);
  const membershipTiers = useSelector(membershipTiersSelector).list;
  const isEPA1Completed = useSelector(isEPA1CompletedSelector);
  const isEPA2Completed = useSelector(isEPA2CompletedSelector);

  const isCimaRetiredLapsed = useSelector(isCimaRetiredLapsedSelector);

  const isUserCimaMember = useSelector(isCimaMemberSelector);
  const isForCimaRenewal = isRenewal && isUserCimaMember;

  const isUpgradeToRegular =
    learningPathway === MembershipTypes.Pathway.APPRENTICE_L7 && isEPA1Completed && isEPA2Completed;

  const isAicpaMember = useMemo(() => {
    return currMembership?.membership?.membershipBody === MembershipTypes.MembershipBody.AICPA;
  }, [currMembership]);

  const isAicpaRegularMember = useMemo(() => {
    return currMembership?.membership?.membershipTerm?.membershipTermType === MembershipTypes.MembershipKeys.REGULAR;
  }, [currMembership]);

  const [membershipLoading, setMembershipLoading] = useState(false);
  const [isAlreadyGetAddOns, setIsAlreadyGetAddOns] = useState(false);
  const [isAlreadyGetAddOnsForLapsed, setIsAlreadyGetAddOnsForLapsed] = useState(false);
  const isSubscribedToCimaPq = AdminUtils.isCimaPqPathway(learningPathway as string);
  const isSubscribedToCimaRegular = AdminUtils.isCimaRegularType(cimaMemBody, currentMembershipKey);
  const isSubscribedToCimaAffiliate = AdminUtils.isCimaAffiliateType(cimaMemBody, currentMembershipKey);
  const isSubscribedToCimaCgmaAffiliate = AdminUtils.isCimaCgmaAffiliateType(cimaMemBody, currentMembershipKey);
  const isSubscribedToCimaRetired = AdminUtils.isCimaRetiredType(cimaMemBody, currentMembershipKey);
  const currencyChosen = useSelector(productCurrencySelector);
  const isRegularUpgradingToRetired = useSelector(isRegularUpgradingToRetiredSelector);

  const membershipCandidateSku = membershipTiers?.find(tiers => tiers.tierCode === MembershipTypes.TierCode.CORE)?.sku;

  const relatedProducts = useMemo(() => {
    return relatedAddOnProductList
      .filter((product: State.AddOnProducts) => product.visible)
      .map(product => ({
        ...product,
        selected: userChoicesRelatedAddonProduct.some(choice => choice.sku === product.variants[0].sku),
      }));
  }, [relatedAddOnProductList, userChoicesRelatedAddonProduct]);

  const hasNextPage = useMemo(() => {
    const NUM_PER_PAGE = 6;
    const sectionWithPriceCategory = categories.filter(({ name }) => name === selectedCategory);

    const numbPages = Math.ceil(
      selectedCategory === ALL
        ? relatedAddOnProductList.length / NUM_PER_PAGE
        : relatedAddOnProductList.filter(product => product.categories?.some(({ name }) => name === selectedCategory))
            .length / NUM_PER_PAGE
    );

    return (sectionWithPriceCategory[0]?.page ?? 0) < numbPages;
  }, [categories, relatedAddOnProductList, selectedCategory]);

  const productCategoryCount = categories.length;

  const handleMembershipCardRelatedAddOnButtonClick = useCallback(
    (productId: string, sku: string, selected: boolean) => {
      selected
        ? dispatch(removeMembershipPackageRelatedAddon(sku))
        : dispatch(addMembershipPackageRelatedAddon(productId, sku));
    },
    [dispatch]
  );

  const filterByCategory = useCallback(
    (categoryName: string) => {
      if (productCategoryCount >= 2) {
        setSelectedCategory(categoryName);
        dispatch(filterMembershipRelatedAddonByCategory(categoryName));
      }
    },
    [dispatch, productCategoryCount]
  );

  const variantsPriceInfoForUser = useCallback(
    (data: State.AddOnProducts) => {
      const priceInfoForUser = Product.getProductPrice(
        data,
        data.variants[0].sku ?? '',
        [User.MembershipIdsEnum.MRUSR0001],
        currencyChosen.label
      );
      return priceInfoForUser.priceFinal?.amount ?? 0;
    },
    [currencyChosen]
  );

  const handleSeeMore = useCallback(() => {
    dispatch(membershipPackageUpdatePageOfSpecificAddonCategory(selectedCategory));
    dispatch(seeMoreMembershipRelatedAddonProduct(selectedCategory));
  }, [dispatch, selectedCategory]);

  // LAPSED Membership Add Ons Getting
  useEffect(() => {
    const isMembershipLapsed = isCimaRetiredLapsed;
    const getAddOnsBySku = async () => {
      setIsAlreadyGetAddOns(true);
      setMembershipLoading(true);
      if (!userMembershipTier) {
        await dispatch(setMembershipPackageTier(currentMembershipTier));
      }
      await dispatch(getMembershipAddOnProducts());
      setMembershipLoading(false);
    };

    if (isMembershipLapsed && currentMembershipTier && !isAlreadyGetAddOnsForLapsed) {
      getAddOnsBySku();
      setIsAlreadyGetAddOnsForLapsed(true);
    }
  }, [isCimaRetiredLapsed, currentMembershipTier, dispatch, isAlreadyGetAddOnsForLapsed, userMembershipTier]);

  useEffect(() => {
    const getAddOnsBySku = async () => {
      setIsAlreadyGetAddOns(true);
      setMembershipLoading(true);
      if (
        isCimaRenewal &&
        isCimaMembershipJourney &&
        !isSubscribedToCimaRegular &&
        !isSubscribedToCimaAffiliate &&
        !isFLPSwitch &&
        !isCimaPqCandidateRenewal &&
        !isFLPUpgrade &&
        selectedPassedUSCPAExam !== USCpaExamEnum.YES_LICENSED_BEFORE &&
        !isAicpaMember &&
        !isAicpaRegularMember &&
        !isSubscribedToFlp &&
        !isUpgradeToRegular &&
        !(isSubscribedToCimaPq && isFlpCurrentJourney) &&
        !isSubscribedToCimaCgmaAffiliate &&
        !isSubscribedToCimaRetired &&
        !isCimaRetiredLapsed
      ) {
        await dispatch(setMembershipPackageTier(currentMembershipTier));
      }
      if (isSubscribedToFlp && isCimaPqCurrentJourney) {
        await dispatch(setMembershipPackageTier(membershipCandidateSku));
      }

      const isMembershipLapsed = isCimaRetiredLapsed;

      // Fetching AddOns for Lapsed is done from the other useEffect
      if (!isMembershipLapsed) {
        await dispatch(getMembershipAddOnProducts());
        setMembershipLoading(false);
      }
    };

    if (!isAlreadyGetAddOns) getAddOnsBySku();
  }, [
    isCimaPqCandidateRenewal,
    userMembershipTier,
    isAlreadyGetAddOns,
    isCimaRenewal,
    isCimaMembershipJourney,
    currentMembershipTier,
    isSubscribedToCimaRegular,
    isSubscribedToCimaAffiliate,
    isFLPSwitch,
    isFLPUpgrade,
    selectedPassedUSCPAExam,
    isAicpaMember,
    isAicpaRegularMember,
    dispatch,
    isSubscribedToFlp,
    membershipTiers,
    isCimaPqCurrentJourney,
    membershipCandidateSku,
    isUpgradeToRegular,
    isCimaRetiredLapsed,
    isFlpCurrentJourney,
    isSubscribedToCimaPq,
    isSubscribedToCimaCgmaAffiliate,
    isSubscribedToCimaRetired,
  ]);

  if (membershipLoading) {
    return <FeedPlaceholder />;
  }

  const destinationURL = isRegularUpgradingToRetired
    ? getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL)
    : isForCimaRenewal
    ? getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS)
    : `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Summary}`;

  return (
    <CimaMembershipPackagePanelWrapper>
      <CategoriesWrapper>
        {categories.map((category: State.ProductCategories) => {
          return !!category?.name ? (
            <StyledButton
              key={category.name}
              testId={`${category.name}`}
              isSelected={category.selected}
              onClick={filterByCategory.bind(null, category.name)}
            >
              {category.name}
            </StyledButton>
          ) : null;
        })}
      </CategoriesWrapper>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', rowGap: '32px' }}>
        {relatedProducts.map(data => (
          <MembershipCard
            key={data.variants[0].sku}
            headerTitle={data.name}
            description={data.description ?? ''}
            formattedPrice={centPriceToString(variantsPriceInfoForUser(data), currencyChosen.label)}
            isCardSelected={data.selected}
            isCurrent={
              isRenewal && productListData?.lineItems.some(lineItem => lineItem.variant?.sku === data.variants[0].sku)
            }
            slug={`${data.slug}`}
            handleClick={handleMembershipCardRelatedAddOnButtonClick.bind(
              null,
              data.productId,
              data.variants[0].sku as string,
              !!data.selected
            )}
            productDetails={data}
            isExistingMember={false}
          />
        ))}
      </div>
      {hasNextPage && (
        <SeeMoreWrapper>
          <StyledLabel onClick={handleSeeMore}>See more</StyledLabel>
        </SeeMoreWrapper>
      )}
      <ConfirmButtonWrapper>
        <StyledConfirmButton
          to={destinationURL}
          testId={'confirm-btn'}
          onClick={handleClick}
          loading={loading}
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
        >
          {isForCimaRenewal ? 'Confirm and continue' : 'Next'}
        </StyledConfirmButton>
      </ConfirmButtonWrapper>
    </CimaMembershipPackagePanelWrapper>
  );
};

interface StyledButtonProps {
  isSelected: boolean;
}

const activeCategoryButtonStyles = css`
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryPurple};
  color: ${props => props.theme.colors.neutralWhite};
  background-color: ${props => props.theme.colors.primaryPurple};
`;

const wrapperWidthResponsiveStyles = css`
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: 100%;
  }
`;

const CategoriesWrapper = styled.div`
  text-align: left;
  margin-top: ${props => props.theme.pxToRem(20)};
  padding-left: ${props => props.theme.pxToRem(8)};
  ${wrapperWidthResponsiveStyles}
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-top: ${props => props.theme.pxToRem(9)};
    margin-left: ${props => props.theme.pxToRem(-22)};
  }
`;

const StyledButton = styled(Button)<StyledButtonProps>`
  &&& {
    font-weight: normal;
    border-radius: ${props => props.theme.pxToRem(6)};
    align-items: center;
    padding: ${props =>
      `${props.theme.pxToRem(8)} ${props.theme.pxToRem(14)} ${props.theme.pxToRem(8)} ${props.theme.pxToRem(14)}`};
    border-radius: ${props => props.theme.pxToRem(20)};

    margin: ${props =>
      `${props.theme.pxToRem(-5)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(40)} ${props.theme.pxToRem(-15)}`};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: ${props =>
        `${props.theme.pxToRem(10)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(12)} ${props.theme.pxToRem(3)}`};
    }

    &:hover {
      ${activeCategoryButtonStyles}
    }

    &:focus {
      ${activeCategoryButtonStyles}
    }

    ${props =>
      props.isSelected
        ? css`
            ${activeCategoryButtonStyles}
          `
        : css`
            color: ${props.theme.colors.primaryPurple};
            background-color: ${props.theme.colors.neutralGrey2};
          `}
  }
`;

const StyledLabel = styled(Label)`
  &&& {
    background-color: transparent;
    color: ${props => props.theme.colors.primaryPurple};

    &:hover {
      cursor: pointer;
    }
  }
`;

const SeeMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${wrapperWidthResponsiveStyles}
`;

const StyledConfirmButton = styled(Button)`
  &&& {
    width: ${props => props.theme.pxToRem(265)};
    height: ${props => props.theme.pxToRem(24)};
    border-radius: ${props => props.theme.pxToRem(4)};
    background-color: ${props => props.theme.colors.primaryPurple};
    color: ${props => props.theme.colors.neutralWhite};
    line-height: 1.57 !important;
    object-fit: contain;
    margin-top: ${props => props.theme.pxToRem(46)} !important;
    &:hover {
      ${activeCategoryButtonStyles}
    }
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    position: relative;
    top: ${props => props.theme.pxToRem(25)};
  }
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${props => props.theme.pxToRem(50)};
  ${wrapperWidthResponsiveStyles}
`;
