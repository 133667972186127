import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { OrganizationFirmInformation } from 'components/pages/PageOrganizationNew/OrganizationFirmInformation';
import { getOrganizations, getOrganizationAdminsByRole } from 'modules/clientAdmin/index';
import {
  selectedClientOrganizationSelector,
  clientOrganizationAdminsListSelector,
  clientOrganizationAdminsIsFetchedSelector,
} from 'modules/clientAdmin/selectors';
import { cimaFeaturesSelector } from 'modules/firmAdmin/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  getOrganizations(): void {
    dispatch(getOrganizations()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
  getOrganizationAdmins(): void {
    dispatch(getOrganizationAdminsByRole()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
});

const mapStateToProps = (state: State.Root) => ({
  selectedOrganization: selectedClientOrganizationSelector(state),
  organizationAdmins: clientOrganizationAdminsListSelector(state),
  organizationAdminsIsFetched: clientOrganizationAdminsIsFetchedSelector(state),
  useCimaFeatures: cimaFeaturesSelector(state),
});

export const OrganizationFirmInformationContainer = connect(
  mapStateToProps,
  mapActionCreators
)(OrganizationFirmInformation);
