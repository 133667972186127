import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { frontloadConnect } from 'react-frontload';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import {
  offlinePageFetchedSelector,
  offlinePageSelector,
  skipOfflinePageFrontloadRequestSelector,
} from 'modules/offlinePageContent/selectors';
import {
  fetchOfflinePageContent,
  resetModule,
  resetSkipLandingPageFrontloadRequest,
} from 'modules/offlinePageContent/actions';
import { PageSiteOffline } from 'components/pages/PageSiteOffline/PageSiteOffline';
import { Contentful } from 'mxp-schemas';

export interface Props {
  landingPageFetched: boolean;
  landingPage: Contentful.LandingPages.ParsedPage | null;
  skipLandingPageFrontloadRequest: boolean;
  fetchOfflinePageContent: (slug: string) => void;
  resetSkipLandingPageFrontloadRequest: () => void;
  resetModule: () => void;
}

const mapActionCreators = (dispatch: Dispatch) => ({
  async fetchOfflinePageContent(slug: string): Promise<void> {
    await dispatch(fetchOfflinePageContent(slug)).catch(() => {
      return dispatch(push(getPath(Routes.NOT_FOUND), { fetchError: true }));
    });
  },
  resetModule(): void {
    dispatch(resetModule());
  },
  resetSkipLandingPageFrontloadRequest(): void {
    dispatch(resetSkipLandingPageFrontloadRequest());
  },
});

const mapStateToProps = (state: State.Root) => {
  return {
    landingPageFetched: offlinePageFetchedSelector(state),
    landingPage: offlinePageSelector(state),
    skipLandingPageFrontloadRequest: skipOfflinePageFrontloadRequestSelector(state),
  };
};

const frontload = async (props: Props) => {
  if (props.skipLandingPageFrontloadRequest) {
    return props.resetSkipLandingPageFrontloadRequest();
  }
  return props.fetchOfflinePageContent('maintenance-page');
};

const options = {
  onUpdate: false,
};

export const PageSiteOfflineContainer = connect(
  mapStateToProps,
  mapActionCreators
)(frontloadConnect(frontload, options)(PageSiteOffline));
