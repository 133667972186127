import React from 'react';
import { Modal } from 'components/atoms';
import styled from 'styled-components';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';

const StyledModal = styled(Modal)`
  &&&&&& {
    overflow-x: hidden;
    margin: unset;
    margin-bottom: 40px;
    height: ${props => (props.isNewAVSDesign ? props.theme.pxToRem(500) : '')};
    width: ${props => (props.isNewAVSDesign ? props.theme.pxToRem(650) : '')};
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: ${props => props.theme.pxToRem(40)} 0 0 0 !important;
      margin: 0 auto;
      width: 100%;
      max-width: ${props => props.theme.pxToRem(props.maxWidth)};
    }
    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;

interface Props {
  heading: string;
  open: boolean;
  close: (e?: any) => void;
  children?: React.ReactNode;
  size?: string;
  maxWidth?: number;
  isNewAVSDesign?: boolean;
}

const MyProfileDefaultModal = ({ heading, open, close, size, children, maxWidth, isNewAVSDesign }: Props) => {
  return (
    <StyledModal
      size={size || 'small'}
      heading={!isNewAVSDesign ? heading : ''}
      open={open}
      maxWidth={maxWidth ?? 400}
      isNewAVSDesign={isNewAVSDesign}
    >
      <CloseButton>
        <StyledCloseIcon onClick={close} />
      </CloseButton>
      {children && React.Children.toArray(children)}
    </StyledModal>
  );
};

export default MyProfileDefaultModal;
