import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { resetModule, fetchStaticLandingPageContent, resetSkipStaticLandingPageFrontloadRequest } from './actions';
import { isServer } from 'utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchStaticLandingPageContent]: {
    next: (state: State.StaticLandingPagesContent, action: any): State.StaticLandingPagesContent => ({
      ...state,
      staticLandingPage: action.payload.getStaticLandingPage,
      staticLandingPageFetched: true,
      skipStaticLandingPageFrontloadRequest: isServer,
    }),
  },

  [resetSkipStaticLandingPageFrontloadRequest]: (
    state: State.StaticLandingPagesContent
  ): State.StaticLandingPagesContent => ({
    ...state,
    skipStaticLandingPageFrontloadRequest: false,
  }),

  [resetModule]: (): State.StaticLandingPagesContent => initialState,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
