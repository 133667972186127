import React, { Dispatch, memo, SetStateAction, useEffect, useState, useCallback } from 'react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import { Icon, Table } from 'semantic-ui-react';
import { Button, ButtonEnums, Modal, OnlyDesktop, OnlyMobile, Checkbox, EmailTag } from 'components/atoms';
import { ReactComponent as Cancel } from 'resources/images/ic-cancel.svg';
import moment from 'moment-timezone';
import { SeatManagement } from 'mxp-schemas';
import { ServiceContract } from 'mxp-schemas/src/types/seatManagement';
import { EVENT_CLICK, handleEvent } from 'utils/Analytics';
import { ReactComponent as IconExclamation } from 'resources/images/ic-exclamation.svg';
import { ReactComponent as CalendarIcon } from 'resources/images/ic-calendar.svg';
import { DateFilter } from 'components/molecules';
import { FilterNames } from 'constants/index';
import { emptyObject, MomentHelpers } from 'utils';
import { useSelector } from 'react-redux';
import { isB2BAgentSelector } from 'modules/admin/selectors';

interface Props {
  contract: SeatManagement.ServiceContract;
  inactive: boolean;
  isOpenModal: boolean;
  isInviteUpdate: boolean;
  seatManagementInvite: SeatManagement.AllocatedSeatDynamoDbRecord[];
  handleInviteCancel: (inviteId: string) => void;
  getSeatManagementInvite: (orderNumber: string) => void;
  showSeatAssignmentModal: (contract: ServiceContract) => void;
  showDeallocationModal: () => void;
  setSelectedSeatsToDeallocate(
    payload: {
      contract: SeatManagement.ServiceContract;
      lineItemsToDeallocate: SeatManagement.ContractLineItem[];
      pendingList?: string[];
    } | null
  ): void;
  extendProductAccess: (args: SeatManagement.ProductAccessExtension) => void;
  toggleProductAccessExtensionModal: () => void;
  getRecentExtensionProcessStatus: (contractId: string) => boolean;
}

const NOT_ACCEPTED = 'N/A';
const ASSIGN_SEATS_CTA_TXT = 'Assign Seats';
const ASSIGN_STATUS = {
  SEAT_ACTIVE: 'Seat Active',
  INVITE_PENDING: 'Invite Pending',
};

const EXTEND_ACCESS_MODAL_DATE_FORMAT = 'MMM DD, YYYY';

export const SeatManagementTableRow: React.FC<Props> = memo(
  ({
    contract,
    inactive,
    isOpenModal,
    isInviteUpdate,
    seatManagementInvite,
    handleInviteCancel,
    getSeatManagementInvite,
    showSeatAssignmentModal,
    showDeallocationModal,
    setSelectedSeatsToDeallocate,
    extendProductAccess,
    toggleProductAccessExtensionModal,
    getRecentExtensionProcessStatus,
  }) => {
    const [isSummaryOpen, setSummaryOpen] = useState(false);
    const [isDisabledRemoveAll, setIsDisabledRemoveAll] = useState(false);

    useEffect(() => {
      if (!isOpenModal || isInviteUpdate) {
        getSeatManagementInvite(contract?.orderNumber);
      }

      if (contract.deprovisionAllowed) {
        setIsDisabledRemoveAll(true);
      }
    }, [getSeatManagementInvite, contract, isOpenModal, isInviteUpdate]);

    const handleRemoveSeat = useCallback(
      (items: SeatManagement.ContractLineItem[], ctaText: string, pendingList?: string[]) => {
        setSelectedSeatsToDeallocate({ contract, lineItemsToDeallocate: items, pendingList });
        showDeallocationModal();
        handleEvent({ clickValue: `button:b2b-seat-management:int:${ctaText}` }, EVENT_CLICK);
        handleEvent({ clickValue: `button:b2b-seat-management:int:modal-start-${ctaText}` }, EVENT_CLICK);
      },
      [showDeallocationModal, contract, setSelectedSeatsToDeallocate]
    );

    const openSeatsAssignmentsToContract = useCallback(() => {
      showSeatAssignmentModal(contract);

      handleEvent({ clickValue: `button:b2b-seat-management:int:${ASSIGN_SEATS_CTA_TXT}` }, EVENT_CLICK);
      handleEvent({ clickValue: `button:b2b-seat-management:int:modal-start-${ASSIGN_SEATS_CTA_TXT}` }, EVENT_CLICK);
    }, [contract, showSeatAssignmentModal]);
    const totalActualQuantity = contract.totalQuantity - Number(contract?.cancelledQuantity);
    const isAllSeatsAssigned = totalActualQuantity === contract.allocatedQuantity;
    const isSeatAssigned = contract.allocatedQuantity !== 0;
    const getSeatManagementDates = (items: SeatManagement.ContractLineItem[] | undefined) => {
      const sortedUniqueEndDates = items
        ?.map(item => item.contractLineItemAccessEndDate)
        .filter(
          (contractLineItemAccessEndDate, index, currentVal) =>
            currentVal.indexOf(contractLineItemAccessEndDate) === index
        )
        .sort((date1: string, date2: string) => +new Date(date1) - +new Date(date2));
      const endDateCount = sortedUniqueEndDates?.length ?? 0;
      const earliestEndDate = sortedUniqueEndDates && sortedUniqueEndDates[0];
      const latestEndDate = sortedUniqueEndDates && sortedUniqueEndDates[endDateCount - 1];
      let dateRangeString = <div>{moment.utc(latestEndDate).format(EXTEND_ACCESS_MODAL_DATE_FORMAT)}</div>;
      if (endDateCount > 1) {
        dateRangeString = (
          <div>
            <div>{`${moment.utc(earliestEndDate).format(EXTEND_ACCESS_MODAL_DATE_FORMAT)} -`}</div>
            <div>{moment.utc(latestEndDate).format(EXTEND_ACCESS_MODAL_DATE_FORMAT)}</div>
          </div>
        );
      }
      const markedDates: string[] = [];
      sortedUniqueEndDates?.map(item => {
        markedDates.push(moment.utc(item).format('YYYY-MM-DD'));
      });
      return {
        earliestEndDate,
        latestEndDate,
        dateRangeString,
        markedDates,
      };
    };
    const formattedContractEndDate = moment.utc(contract?.endDatetime).format(EXTEND_ACCESS_MODAL_DATE_FORMAT);
    const handleToggleSummary = useCallback(() => {
      if (!inactive) {
        setSummaryOpen(!isSummaryOpen);
      }
    }, [isSummaryOpen, setSummaryOpen, inactive]);
    return (
      <>
        <StyledRow key={contract.id} expanded={isSummaryOpen}>
          <CellBoldStyled>
            <FlexSpace>
              <div>{`${contract.allocatedQuantity} of ${totalActualQuantity} assigned`}</div>
              <StyledOnlyMobile>
                <Expand onClick={handleToggleSummary} data-testid="trigger-expand">
                  <AngleIcon fitted name={!isSummaryOpen ? 'angle down' : 'angle up'} inactive={inactive} />
                </Expand>
              </StyledOnlyMobile>
            </FlexSpace>
          </CellBoldStyled>
          <CellProductNameStyled>{contract.productName}</CellProductNameStyled>
          <CellEndDate>{formattedContractEndDate}</CellEndDate>
          <CellStyled>
            <FlexWrap>
              <ButtonStyled
                testId={`${contract.id}`}
                variant={ButtonEnums.variants.secondary}
                size={ButtonEnums.sizes.small}
                disabled={inactive || isAllSeatsAssigned}
                onClick={openSeatsAssignmentsToContract}
              >
                {ASSIGN_SEATS_CTA_TXT}
              </ButtonStyled>
              <OnlyDesktop>
                <Expand onClick={handleToggleSummary} data-testid="trigger-expand">
                  <AngleIcon fitted name={!isSummaryOpen ? 'angle down' : 'angle up'} inactive={inactive} />
                </Expand>
              </OnlyDesktop>
            </FlexWrap>
          </CellStyled>
        </StyledRow>
        {isSummaryOpen && (
          <>
            <CellSummaryStyled colSpan="4">
              <RowSummary
                assignedList={contract?.contractLineItems}
                assignedSeatManagementList={seatManagementInvite}
                handleRemove={handleRemoveSeat}
                handleInviteCancel={handleInviteCancel}
                isSeatAssigned={isSeatAssigned}
                isDisabled={contract?.deprovisionAllowed}
                isDisabledRemoveAll={contract?.contractLineItems?.length !== 0 ? isDisabledRemoveAll : true}
                orderNumber={contract?.orderNumber}
                serviceContractId={contract?.id}
                contract={contract}
                getSeatManagementDates={getSeatManagementDates}
                extendProductAccess={extendProductAccess}
                handleToggleSummary={handleToggleSummary}
                toggleProductAccessExtensionModal={toggleProductAccessExtensionModal}
                getRecentExtensionProcessStatus={getRecentExtensionProcessStatus}
              />
            </CellSummaryStyled>
          </>
        )}
      </>
    );
  }
);

interface CsvProps {
  fileName: string;
  serviceContractId?: string;
  orderNumber: string;
  dataList: SeatManagement.AllocatedSeatDynamoDbRecord[];
}

const DownloadButton: React.FC<CsvProps> = memo(({ fileName, dataList, serviceContractId, orderNumber }) => {
  const CsvData = async () => {
    const ArrayData = dataList
      .filter(
        (data: SeatManagement.AllocatedSeatDynamoDbRecord) =>
          data.orderNumber === orderNumber && data.serviceContractId === serviceContractId
      )
      .map((item: SeatManagement.AllocatedSeatDynamoDbRecord, key) => ({
        Reference: key + 1,
        'First Name': `${item?.firstName || ''}`,
        'Last Name': `${item?.lastName || ''} `,
        'Email address': `${item.endUserEmail}`,
        'Seat Allocation Status': `${item.status}`,
      }));

    const keys = Object.keys(ArrayData[0]);
    const result: string = `${keys.join(', ')}\n${ArrayData.map((item: any) =>
      keys.map(key => item[key]).join(', ')
    ).join('\n')}`;

    const element = document.createElement('a');
    element.href = encodeURI(`data:text/csv;charset=utf-8,${result}`);
    element.download = fileName;
    element.click();
  };

  return (
    <ButtonStyled
      variant={ButtonEnums.variants.link}
      testId="seat-management-csv-download-btn"
      iconName="download"
      iconPosition={ButtonEnums.iconPosition.left}
      size={ButtonEnums.sizes.small}
      onClick={CsvData}
    >
      Download seat allocation report
    </ButtonStyled>
  );
});

interface RowSummaryProps {
  assignedList: SeatManagement.ContractLineItem[] | any;
  assignedSeatManagementList: SeatManagement.AllocatedSeatDynamoDbRecord[] | any;
  handleRemove: (item: SeatManagement.ContractLineItem[], ctaText: string, pendingList?: string[]) => void;
  handleInviteCancel: (inviteId: string) => void;

  orderNumber: string;
  serviceContractId?: string;
  isSeatAssigned: boolean;
  isDisabled: boolean;
  isDisabledRemoveAll: boolean;
  contract: SeatManagement.ServiceContract;
  getSeatManagementDates: (items: SeatManagement.ContractLineItem[]) => any;
  extendProductAccess: (args: SeatManagement.ProductAccessExtension) => void;
  handleToggleSummary: () => void;
  toggleProductAccessExtensionModal: () => void;
  getRecentExtensionProcessStatus: (contractId: string) => any;
}

const RowSummary: React.FC<RowSummaryProps> = ({
  assignedList,
  assignedSeatManagementList,
  handleRemove,
  handleInviteCancel,
  orderNumber,
  serviceContractId,
  handleToggleSummary,
  isDisabled,
  isDisabledRemoveAll,
  contract,
  getSeatManagementDates,
  extendProductAccess,
  toggleProductAccessExtensionModal,
  getRecentExtensionProcessStatus,
}) => {
  const isB2BAgent = useSelector(isB2BAgentSelector);
  const [selectedUsers, setSelectedUsers]: any[] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [newEndDate, setNewEndDate] = useState('');
  const [disableSeatManagementCtaButtons, setDisableSeatManagementCtaButtons] = useState(true);
  const [isAccessExtensionModalOpen, setIsAccessExtensionModalOpen] = useState(false);
  const [emailTagsContainerHeight, setEmailTagsContainerHeight] = useState(95);
  const [showMoreSeats, setShowMoreSeats] = useState(false);
  const [showMoreSeatsTable, setShowMoreSeatsTable] = useState(false);
  const [dateFilters, setDateFilters] = useState<{ [key: string]: string | undefined }>(emptyObject);
  const [disableAccessExtensionConfirmation, setDisableAccessExtensionConfirmation] = useState(true);
  const [uncoveredUsers, setUncoveredUsers]: any[] = useState([]);
  const [coveredUsers, setCoveredUsers]: any[] = useState([]);
  const [isSelectEndDateInvalid, setIsSelectEndDateInvalid] = useState(false);
  const isProductExtendable = contract.isExtendable;
  const pendingList = assignedSeatManagementList
    ?.filter(
      (data: SeatManagement.AllocatedSeatDynamoDbRecord) =>
        data.orderNumber === orderNumber && data.status === 'Pending'
    )
    ?.map((data: SeatManagement.AllocatedSeatDynamoDbRecord) => data.token);

  const ctaText = 'Remove all seats';
  const onRemoveAll = useCallback(() => {
    handleRemove(assignedList, ctaText, pendingList);
  }, [handleRemove, assignedList, pendingList]);

  const toggleSelectAll = useCallback(() => {
    if (isSelectAll) {
      setSelectedUsers([]);
      setDisableSeatManagementCtaButtons(true);
    } else {
      setSelectedUsers(assignedList);
      setDisableSeatManagementCtaButtons(false);
    }
    setIsSelectAll(!isSelectAll);
  }, [setIsSelectAll, isSelectAll, setSelectedUsers, assignedList]);

  const onSelectUser = useCallback(
    selectedUser => {
      const selectedUsersArray = selectedUsers.slice();
      const isUserSelected =
        selectedUsersArray.filter(
          (user: SeatManagement.ContractLineItem) => user.contractLineItemId === selectedUser.contractLineItemId
        ).length > 0;
      if (isUserSelected) {
        const selectedUserIndex = selectedUsersArray.findIndex(
          (user: any) => user.contractLineItemId === selectedUser.contractLineItemId
        );
        selectedUsersArray?.splice(selectedUserIndex, 1);
      } else {
        selectedUsersArray.push(selectedUser);
      }
      setSelectedUsers(selectedUsersArray);
      setDisableSeatManagementCtaButtons(selectedUsersArray.length > 0);
      setIsSelectAll(selectedUsersArray.length === assignedList.length);
    },
    [selectedUsers, setSelectedUsers, setDisableSeatManagementCtaButtons, setIsSelectAll, assignedList.length]
  );

  useEffect(() => {
    setDisableSeatManagementCtaButtons(selectedUsers.length < 1);
    setIsSelectAll(selectedUsers.length === assignedList.length);
  }, [selectedUsers, assignedList.length]);

  const handleShowAccessExtensionModal = async () => {
    const checkStatus = await getRecentExtensionProcessStatus(contract.id);
    if (checkStatus.payload) {
      toggleProductAccessExtensionModal();
    } else {
      setIsAccessExtensionModalOpen(true);
    }
  };

  const scrollToTop = (elementId: string, callBackFunc: () => void) => {
    const topElement = document.getElementById(elementId);
    if (topElement) {
      topElement.scrollTop = 0;
    }
    callBackFunc();
  };

  const handleSetShowMoreSeats = useCallback(() => {
    scrollToTop(`seat-management-email-tags-wrapper-${contract.id}`, () => {
      if (showMoreSeats) {
        setEmailTagsContainerHeight(95);
      } else {
        setEmailTagsContainerHeight(225);
      }
      setShowMoreSeats(!showMoreSeats);
    });
  }, [showMoreSeats, contract.id]);

  const handleSetShowMoreSeatsTable = useCallback(() => {
    scrollToTop(`seat-management-checkbox-wrapper-${contract.id}`, () => {
      setShowMoreSeatsTable(!showMoreSeatsTable);
    });
  }, [showMoreSeatsTable, contract.id]);

  const handleDateFilterChange = useCallback(
    (filterName: string, value: string): void => {
      if (filterName === FilterNames.EVENT_END_DATE && !value) return;
      const shouldResetEndDateValue: boolean = filterName === FilterNames.EVENT_START_DATE;
      setDateFilters({
        ...dateFilters,
        [filterName]: value || undefined,
        ...(shouldResetEndDateValue && { [FilterNames.EVENT_END_DATE]: '' }),
      });
      setNewEndDate(value);
    },
    [setNewEndDate, dateFilters]
  );

  const handleCancelAccessExtension = useCallback(() => {
    setDateFilters({});
    setIsSelectEndDateInvalid(false);
    setNewEndDate('');
    setUncoveredUsers([]);
    setIsAccessExtensionModalOpen(false);
    handleSetShowMoreSeats();
  }, [
    setDateFilters,
    setIsSelectEndDateInvalid,
    setIsAccessExtensionModalOpen,
    setNewEndDate,
    handleSetShowMoreSeats,
    setUncoveredUsers,
  ]);

  const existingEndDate = isSelectAll ? contract.endDatetime : getSeatManagementDates(selectedUsers).latestEndDate;

  const handleConfirmAccessExtension = () => {
    const apiParam: any = [];
    selectedUsers?.map((item: any) => {
      const prevDate = moment(isSelectAll ? contract.endDatetime : item.endDate);
      const newDate = moment(newEndDate);
      const daysExtended = newDate.diff(prevDate, 'days');
      apiParam.push({
        serviceContractOrderNumber: contract?.orderNumber,
        serviceContractId: contract?.id,
        contractLineItemId: item?.contractLineItemId,
        productId: contract?.seatProductId,
        email: item?.contactEmail,
        isSelectAll,
        dateExtended: newEndDate,
        daysExtended,
      });
    });
    extendProductAccess({
      selectedUsersCount: selectedUsers?.length,
      productName: contract?.productName,
      currentEndDate: existingEndDate,
      newEndDate,
      apiParam,
    });
    handleToggleSummary();
    setSelectedUsers([]);
    setDateFilters({});
    setIsAccessExtensionModalOpen(false);
  };

  const getGroupedAssignedUsersPerEndDate = useCallback((users: SeatManagement.ContractLineItem[]) => {
    const reducedAssignedUsers = users.reduce((group: any, user: SeatManagement.ContractLineItem) => {
      const { contractLineItemAccessEndDate } = user;
      const formattedEndDate = moment.utc(contractLineItemAccessEndDate).format(EXTEND_ACCESS_MODAL_DATE_FORMAT);
      group[formattedEndDate] = group[formattedEndDate] ?? [];
      group[formattedEndDate].push(user);
      return group;
    }, {});
    const groupedAssignedUsers = Object.keys(reducedAssignedUsers)
      .map(key => [key, reducedAssignedUsers[key]])
      .sort((a, b) => {
        const da = new Date(a[0]);
        const db = new Date(b[0]);
        return +new Date(da) - +new Date(db);
      });
    return groupedAssignedUsers;
  }, []);

  const groupedAssignedUsersPerEndDate = getGroupedAssignedUsersPerEndDate(assignedList);

  const removeUser = React.useCallback(
    (userId: string) => {
      const newSelectedUsers = selectedUsers.filter(
        (user: SeatManagement.AllocatedSeatDynamoDbRecord) => user.contractLineItemId !== userId
      );
      setSelectedUsers(newSelectedUsers);
    },
    [selectedUsers, setSelectedUsers]
  );

  useEffect(() => {
    const latestEndDate = getSeatManagementDates(selectedUsers).latestEndDate;
    const previousEndDate = MomentHelpers.convertToMoment(latestEndDate, 'YYYY-MM-DD');
    const newSelectedEndDate = MomentHelpers.convertToMoment(newEndDate, 'YYYY-MM-DD');
    const isNewSelectedDateValid = previousEndDate.isBefore(newSelectedEndDate, 'day');
    if (selectedUsers.length > 0 && isNewSelectedDateValid && uncoveredUsers.length < 1) {
      setDisableAccessExtensionConfirmation(false);
    } else {
      setDisableAccessExtensionConfirmation(true);
    }
  }, [selectedUsers, newEndDate, getSeatManagementDates, uncoveredUsers.length]);

  const handleSeatDeallocation = useCallback(() => {
    handleRemove(selectedUsers, 'Remove Seat');
  }, [selectedUsers, handleRemove]);

  useEffect(() => {
    if (isAccessExtensionModalOpen && selectedUsers.length < 1) {
      handleCancelAccessExtension();
    }
  }, [selectedUsers.length, handleCancelAccessExtension, isAccessExtensionModalOpen]);

  const formattedContractListLatestEndDate = moment
    .utc(getSeatManagementDates(selectedUsers).latestEndDate)
    .format(EXTEND_ACCESS_MODAL_DATE_FORMAT);

  useEffect(() => {
    const selectedUsersArray = selectedUsers.slice();
    const coveredUsersArray: any = [];
    const uncoveredUsersArray: any = [];
    selectedUsersArray.map((user: SeatManagement.ContractLineItem) => {
      if (
        MomentHelpers.convertToMoment(newEndDate, 'YYYY-MM-DD').isBefore(
          MomentHelpers.convertToMoment(user.contractLineItemAccessEndDate, 'YYYY-MM-DD'),
          'day'
        )
      ) {
        uncoveredUsersArray.push(user);
      } else {
        coveredUsersArray.push(user);
      }
      return user;
    });
    setIsSelectEndDateInvalid(uncoveredUsersArray.length > 0);
    setUncoveredUsers(uncoveredUsersArray);
    setCoveredUsers(coveredUsersArray);
  }, [
    newEndDate,
    setUncoveredUsers,
    selectedUsers,
    isAccessExtensionModalOpen,
    setIsSelectEndDateInvalid,
    setCoveredUsers,
  ]);

  const removeUncoveredUsers = useCallback(() => {
    setSelectedUsers(coveredUsers);
    setUncoveredUsers([]);
    setIsSelectEndDateInvalid(false);
  }, [setIsSelectEndDateInvalid, setSelectedUsers, setUncoveredUsers, coveredUsers]);

  const getInvalidEndDateErrorMessage = () => {
    return (
      <>
        <span>The date you have selected is before the end date for</span>
        {` ${uncoveredUsers.length} user(s). `}
        <span>
          <StyledClickableText onClick={removeUncoveredUsers}>Remove those users</StyledClickableText>
          {` to proceed with the selected date or pick a new date after ${formattedContractListLatestEndDate}.`}
        </span>
      </>
    );
  };

  const getEndDatesAndSeatCounts = useCallback(() => {
    const groupedSelectedUsers = getGroupedAssignedUsersPerEndDate(selectedUsers);
    const endDatesAndSeatCounts = groupedSelectedUsers.map(group => {
      return (
        <>
          <br />
          <StyledBold>{group[0]}</StyledBold> for{' '}
          <StyledBold>{`${group[1].length} ${group[1].length > 1 ? 'seats' : 'seat'}`}</StyledBold>
        </>
      );
    });
    return endDatesAndSeatCounts;
  }, [selectedUsers, getGroupedAssignedUsersPerEndDate]);
  return (
    <>
      <Summary>
        <>
          {isB2BAgent && isProductExtendable && assignedList.length > 0 && (
            <SeatAssignmentContainer>
              <StyledCheckBoxWrapper
                id={`seat-management-checkbox-wrapper-${contract.id}`}
                showMoreSeats={showMoreSeatsTable}
                numberOfItems={assignedList.length}
              >
                <SelectAllWrapper>
                  <StyledCheckBox
                    id={`select_all_${contract.id}`}
                    testId={`select_all_${contract.id}`}
                    value={isSelectAll}
                    label={isSelectAll ? 'Deselect All' : 'Select All'}
                    onChange={toggleSelectAll}
                    checked={false}
                  />
                </SelectAllWrapper>
                {groupedAssignedUsersPerEndDate.map(group =>
                  /**
                   * NOTE: groupedAssignedUsersPerEndDate is an array of arrays.
                   * On index 0 is the group end date and on index 1 is the list of users.
                   */
                  group[1].map((user: SeatManagement.ContractLineItem) => (
                    <SeatManagementUserCheckbox
                      key={user.contractLineItemId}
                      userDetails={user}
                      onSelectUser={onSelectUser}
                      selectedUsers={selectedUsers}
                    />
                  ))
                )}
              </StyledCheckBoxWrapper>
              {assignedList.length > 5 && (
                <ShowMoreSeatsButtonWrapper isOnTable={true}>
                  <ShowMoreSeatActionButton
                    testId={`show-more-seat-button-${contract.id}`}
                    handleShowMoreSeats={handleSetShowMoreSeatsTable}
                    showMoreSeats={showMoreSeatsTable}
                  />
                </ShowMoreSeatsButtonWrapper>
              )}

              <StyledButtonWrapper>
                <StyledLongButton
                  testId={`remove-seat`}
                  variant={ButtonEnums.variants.secondary}
                  size={ButtonEnums.sizes.small}
                  disabled={disableSeatManagementCtaButtons}
                  onClick={handleSeatDeallocation}
                >
                  Remove Seat
                </StyledLongButton>
                <StyledLongButton
                  testId={`extend-access`}
                  variant={ButtonEnums.variants.secondary}
                  size={ButtonEnums.sizes.small}
                  disabled={disableSeatManagementCtaButtons}
                  onClick={handleShowAccessExtensionModal}
                >
                  Extend Access
                </StyledLongButton>
                <DownloadButton
                  fileName="Seat allocation report.csv"
                  orderNumber={orderNumber}
                  serviceContractId={serviceContractId}
                  dataList={assignedSeatManagementList}
                />
              </StyledButtonWrapper>
            </SeatAssignmentContainer>
          )}
          {assignedSeatManagementList?.length > 0 && (!isProductExtendable || !isB2BAgent) && (
            <>
              {assignedSeatManagementList
                .filter(
                  (data: SeatManagement.AllocatedSeatDynamoDbRecord) =>
                    data.orderNumber === orderNumber && data.serviceContractId === serviceContractId
                )
                .map((assignedItems: SeatManagement.AllocatedSeatDynamoDbRecord) => (
                  <FlexWrapAssignDetail key={assignedItems.accountId}>
                    <CellName>
                      {assignedItems.used ? `${assignedItems.firstName} ${assignedItems.lastName}` : NOT_ACCEPTED}
                    </CellName>
                    <CellEmail>{assignedItems.endUserEmail} </CellEmail>
                    <CellStatus>
                      {assignedItems.used ? (
                        <StatusTag>{ASSIGN_STATUS.SEAT_ACTIVE}</StatusTag>
                      ) : (
                        <StatusTag>{ASSIGN_STATUS.INVITE_PENDING}</StatusTag>
                      )}
                    </CellStatus>
                    {assignedItems.used ? (
                      <>
                        {assignedList.map((item: SeatManagement.ContractLineItem) => {
                          if (item.contractLineItemId === assignedItems.contractLineItemId) {
                            return <CIMARemoveButton isDisabled={isDisabled} handleRemove={handleRemove} item={item} />;
                          }
                          return <></>;
                        })}
                      </>
                    ) : (
                      <CancelButton
                        handleInviteCancel={handleInviteCancel}
                        cancelInviteId={assignedItems.token}
                        endUserEmail={assignedItems.endUserEmail}
                      />
                    )}
                  </FlexWrapAssignDetail>
                ))}
              <StylesManageWrapper>
                <ButtonStyled
                  testId={`remove-all`}
                  variant={ButtonEnums.variants.secondary}
                  size={ButtonEnums.sizes.small}
                  disabled={isDisabledRemoveAll}
                  onClick={onRemoveAll}
                >
                  {ctaText}
                </ButtonStyled>
                <Expand />
                {assignedSeatManagementList.length !== 0 && (
                  <DownloadButton
                    fileName="Seat allocation report.csv"
                    orderNumber={orderNumber}
                    serviceContractId={serviceContractId}
                    dataList={assignedSeatManagementList}
                  />
                )}
              </StylesManageWrapper>
            </>
          )}
          {(assignedList.length < 1 || assignedSeatManagementList.length < 1) && (
            <EmptyMessageContainer>No seats assigned. Assigned users will be listed here.</EmptyMessageContainer>
          )}
        </>
      </Summary>

      <AccessExtensionModal
        open={isAccessExtensionModalOpen}
        onClose={handleCancelAccessExtension}
        centered
        testId="extend-access-modal"
        icon={<StyledCalendarIcon />}
        heading="Extend Access"
        cancelNode={
          <StyledButton
            size={ButtonEnums.sizes.small}
            testId="extend-cancel"
            variant={ButtonEnums.variants.secondary}
            onClick={handleCancelAccessExtension}
          >
            Cancel
          </StyledButton>
        }
        confirmNode={
          <StyledButton
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.primary}
            testId="extend-confirm"
            onClick={handleConfirmAccessExtension}
            disabled={disableAccessExtensionConfirmation}
          >
            Yes, Extend Access
          </StyledButton>
        }
      >
        <StyledModalBody>
          <StyledCenteredTextDiv>
            Access to: <StyledBold>{contract.productName}</StyledBold> expires on {getEndDatesAndSeatCounts()}
          </StyledCenteredTextDiv>{' '}
          <br /> <br />
          <StyledCenteredDiv>
            Please select which date you would like to extend to: <br />
          </StyledCenteredDiv>
          <br />
          <StyledCenteredCalendarDiv>
            <DateFilter
              selectedStartDate={getSeatManagementDates(selectedUsers).earliestEndDate}
              selectedEndDate={newEndDate}
              handleChange={handleDateFilterChange}
              numberOfMonths={1}
              hideTitle
              hideLegend
              hasPreSelectedStartDate
              markedDates={getSeatManagementDates(selectedUsers).markedDates}
              seatManagementPage
            />
          </StyledCenteredCalendarDiv>
          <br />
          {isSelectEndDateInvalid && <ErrorMessageContainer>{getInvalidEndDateErrorMessage()}</ErrorMessageContainer>}
          <StyledBold>Extend access for: </StyledBold>
          <EmailTagsContainer
            id={`seat-management-email-tags-wrapper-${contract.id}`}
            height={emailTagsContainerHeight}
          >
            {selectedUsers.map((user: SeatManagement.ContractLineItem) => (
              <EmailTag
                key={user.contractLineItemId}
                email={user?.contactEmail}
                index={user.contractLineItemId || ''}
                handleRemove={removeUser}
                hasErrors
              />
            ))}
          </EmailTagsContainer>
          {selectedUsers.length > 6 && (
            <ShowMoreSeatsButtonWrapper isOnTable={false}>
              <ShowMoreSeatActionButton
                testId={`show-more-seat-button-${contract.id}`}
                handleShowMoreSeats={handleSetShowMoreSeats}
                showMoreSeats={showMoreSeats}
              />
            </ShowMoreSeatsButtonWrapper>
          )}
        </StyledModalBody>
      </AccessExtensionModal>
    </>
  );
};

interface SeatManagementUserCheckboxProps {
  userDetails: SeatManagement.ContractLineItem;
  onSelectUser: (user: SeatManagement.ContractLineItem) => void;
  selectedUsers: SeatManagement.ContractLineItem[];
}

const SeatManagementUserCheckbox: React.FC<SeatManagementUserCheckboxProps> = ({
  userDetails,
  selectedUsers,
  onSelectUser,
}) => {
  const [isUserSelected, setIsUserSelected] = useState(false);
  useEffect(() => {
    setIsUserSelected(
      selectedUsers.some(
        (item: SeatManagement.ContractLineItem) => item.contractLineItemId === userDetails.contractLineItemId
      )
    );
  }, [selectedUsers, userDetails.contractLineItemId]);

  const handleSelectUser = () => {
    onSelectUser(userDetails);
    setIsUserSelected(
      selectedUsers.some(
        (item: SeatManagement.ContractLineItem) => item.contractLineItemId === userDetails.contractLineItemId
      )
    );
  };

  return (
    <>
      <StyledCheckBox
        id={userDetails.contractLineItemId}
        testId={userDetails.contractLineItemId}
        value={userDetails.contractLineItemId}
        checked={isUserSelected}
        onChange={handleSelectUser}
      />
      <CustomCheckBoxLabel>
        <CellName>
          <StyledDiv>{userDetails.contactName}</StyledDiv>
        </CellName>
        <CellEmail>{userDetails.contactEmail}</CellEmail>
        <CellLineItemEndDate>
          {moment.utc(userDetails.contractLineItemAccessEndDate).format(EXTEND_ACCESS_MODAL_DATE_FORMAT)}
        </CellLineItemEndDate>
        <CellStatus>
          <StatusTag>{ASSIGN_STATUS.SEAT_ACTIVE}</StatusTag>
        </CellStatus>
      </CustomCheckBoxLabel>
    </>
  );
};

const CIMARemoveButton: React.FC<{
  isMobile?: boolean;
  handleRemove: (item: any[], ctaText: string) => void;
  item: SeatManagement.ContractLineItem;
  isDisabled?: boolean;
}> = ({ isMobile = false, handleRemove, item, isDisabled }) => {
  const ctaText = 'Remove Seat';
  const onClick = useCallback(() => {
    handleRemove([item], ctaText);
  }, [item, handleRemove]);

  return (
    <StyledRemoveButton
      testId={`remove-button-${item}`}
      variant={ButtonEnums.variants.link}
      size={isMobile ? ButtonEnums.sizes.medium : ButtonEnums.sizes.small}
      disabled={isDisabled}
      icon={<StyledCancel />}
      iconPosition={ButtonEnums.iconPosition.left}
      onClick={onClick}
    >
      {ctaText}
    </StyledRemoveButton>
  );
};

const CancelButton: React.FC<{
  isMobile?: boolean;
  handleInviteCancel: (inviteId: string) => void;
  cancelInviteId: string;
  endUserEmail: string;
}> = ({ isMobile = false, handleInviteCancel, cancelInviteId, endUserEmail }) => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const ctaText = 'Cancel Invite';
  const onSubmit = useCallback(() => {
    if (handleInviteCancel) {
      handleInviteCancel(cancelInviteId);
    }
  }, [cancelInviteId, handleInviteCancel]);

  return (
    <>
      <StyledRemoveButton
        testId={`remove-button-${cancelInviteId}`}
        variant={ButtonEnums.variants.link}
        size={isMobile ? ButtonEnums.sizes.medium : ButtonEnums.sizes.small}
        icon={<StyledCancel />}
        iconPosition={ButtonEnums.iconPosition.left}
        onClick={setModalOpened.bind(null, true)}
      >
        {ctaText}
      </StyledRemoveButton>
      <CancelInviteModal {...{ modalOpened }} {...{ setModalOpened }} {...{ endUserEmail }} {...{ onSubmit }} />
    </>
  );
};

const ShowMoreSeatActionButton: React.FC<{
  testId: string;
  handleShowMoreSeats: () => void;
  showMoreSeats: boolean;
}> = ({ testId, handleShowMoreSeats, showMoreSeats }) => {
  return (
    <ShowMoreSeatButton
      testId={testId}
      variant={ButtonEnums.variants.standAloneLink}
      size={ButtonEnums.sizes.small}
      onClick={handleShowMoreSeats}
      iconPosition={ButtonEnums.iconPosition.right}
      icon={
        <ShowMoreSeatIconWrapper>
          <AngleIcon fitted name={!showMoreSeats ? 'angle down' : 'angle up'} />
        </ShowMoreSeatIconWrapper>
      }
    >
      {`${showMoreSeats ? 'Show less seats' : 'Show all seats'}`}
    </ShowMoreSeatButton>
  );
};

interface ModalProps {
  modalOpened: boolean;
  setModalOpened: Dispatch<SetStateAction<boolean>>;
  endUserEmail: string;
  onSubmit: () => void;
}

const CancelInviteModal: React.FC<ModalProps> = memo(({ modalOpened, setModalOpened, endUserEmail, onSubmit }) => {
  return (
    <>
      <StyledModal
        open={modalOpened}
        onClose={setModalOpened.bind(null, false)}
        testId="prod-cancel-refund-modal"
        heading="Are you sure?"
        icon={<StyledIconExclamation />}
        cancelNode={
          <StyledButton
            size={ButtonEnums.sizes.small}
            testId="return-prev-page"
            variant={ButtonEnums.variants.secondary}
            onClick={setModalOpened.bind(null, false)}
          >
            No, return to previous page
          </StyledButton>
        }
        confirmNode={
          <StyledButton
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.primary}
            testId="refund-cancel"
            onClick={onSubmit}
          >
            Yes, remove seat
          </StyledButton>
        }
      >
        <StyledCenteredDiv>
          <StyledBold>{endUserEmail}</StyledBold> will lose access to this product. Do you confirm this change?
        </StyledCenteredDiv>
      </StyledModal>
    </>
  );
});

const StyledModal = styled(Modal)`
  &&&&& {
    width: ${props => props.theme.pxToRem(600)};
    padding: 0;
  }
`;

const AccessExtensionModal = styled(Modal)`
  &&&&& {
    width: ${props => props.theme.pxToRem(600)};
    .content {
      padding-bottom: ${props => props.theme.pxToRem(15)} !important;
    }
  }
`;

const StyledModalBody = styled.div`
  padding: ${props => props.theme.pxToRem(35)};
  padding-bottom: 0;
`;

const StyledIconExclamation = styled(IconExclamation)`
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledBold = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: 0 ${props => props.theme.pxToRem(3)} 0 ${props => props.theme.pxToRem(3)};
`;

const StyledButton = styled(Button)`
  &&&&& {
    min-width: ${props => props.theme.pxToRem(190)};
    font-size: ${props => props.theme.fontSizes.xs};
    margin-right: ${props => props.theme.pxToRem(20)};
    margin-top: ${props => props.theme.pxToRem(10)};
    margin-bottom: 0;
  }
`;

const StyledCenteredTextDiv = styled.div`
  text-align: center;
`;
const StyledCenteredCalendarDiv = styled.div`
  justify-content: center;
  display: flex;
`;

const StyledCenteredDiv = styled.div`
  text-align: center;
  margin-bottom: ${props => props.theme.pxToRem(8)};
`;

const StyledCancel = styled(Cancel)`
  &&&&&&& {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => props.theme.pxToRem(24)};
      height: ${props => props.theme.pxToRem(24)};
    }
  }
`;

const StyledRemoveButton = styled(Button)`
  &&&& {
    &:hover {
      color: ${props => props.theme.colors.neutralGrey8};
    }
    font-size: ${props => props.theme.pxToRem(14)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.pxToRem(16)};
    }
    svg path {
      fill: ${props => props.theme.colors.primaryPurple};
    }

    span {
      width: ${props => props.theme.pxToRem(90)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: ${props => props.theme.pxToRem(100)};
        font-size: ${props => props.theme.pxToRem(14)};
      }
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: ${props => props.theme.pxToRem(-6)};
    }
  }
`;

const StylesManageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: left;
  padding-top: ${props => props.theme.pxToRem(20)};
`;

const FlexWrap = styled.div`
  display: flex;
`;

const FlexWrapAssignDetail = styled(FlexWrap)`
  margin-bottom: ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: block;
  }
`;

const CellName = styled.div`
  width: ${props => props.theme.pxToRem(200)};
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(14)};
  }
  .no-wrap {
    width: ${props => props.theme.pxToRem(180)};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CellLineItemEndDate = styled.div`
  width: ${props => props.theme.pxToRem(200)};
  padding-left: ${props => props.theme.pxToRem(23)};
`;

const CellEmail = styled.div`
  width: ${props => props.theme.pxToRem(415)};
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(8)};
  }
`;

const CellStatus = styled.div`
  width: ${props => props.theme.pxToRem(200)};
  max-height: ${props => props.theme.pxToRem(20)};
  display: flex;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(8)};
    justify-content: left;
  }
`;

const StatusTag = styled.div`
  background: ${props => props.theme.colors.neutralGrey7};
  color: ${props => props.theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(20)};
  width: ${props => props.theme.pxToRem(120)};
  height: ${props => props.theme.pxToRem(20)};
  justify-content: center;
  display: flex;
  text-align: center;
`;

const Summary = styled.div`
  width: 100%;
  margin-left: ${props => props.theme.pxToRem(10)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: auto;
    margin-left: 0;
    box-shadow: 0 -0.0625rem 0 0 #e5e5e6 inset !important;
    padding-left: ${props => props.theme.pxToRem(16)};
    padding-bottom: ${props => props.theme.pxToRem(16)};
  }
`;

const CellStyled = styled(Table.Cell)`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const CellSummaryStyled = styled(CellStyled)`
  &&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: 0;
    }
  }
`;

const CellEndDate = styled(Table.Cell)`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const CellProductNameStyled = styled(CellStyled)`
  max-width: ${props => props.theme.pxToRem(325)};
`;

const CellBoldStyled = styled(Table.Cell)`
  &&&&&&& {
    font-weight: ${props => props.theme.fontWeights.medium};

    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.fontSizes.s} !important;
    }
  }
`;

const ButtonStyled = styled(Button)`
  &&&&&&& {
    min-width: ${props => props.theme.pxToRem(126)};
    font-size: ${props => props.theme.fontSizes.xs} !important;
    ${props => props.theme.mediaQueries.mobileOnly} {
      min-width: ${props => props.theme.pxToRem(140)};
      margin-top: ${props => props.theme.pxToRem(8)};
    }
  }
`;
const StyledLongButton = styled(Button)`
  &&&&&&& {
    margin-right: ${props => props.theme.pxToRem(15)};
    font-size: ${props => props.theme.fontSizes.xs} !important;
    width: ${props => props.theme.pxToRem(170)} !important;
  }
`;

const Expand = styled.div`
  margin-left: ${props => props.theme.pxToRem(35)};
  margin-bottom: ${props => props.theme.pxToRem(4)};
`;

const FlexSpace = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AngleIcon = styled(Icon)<ThemedStyledProps<{ inactive: boolean }, DefaultTheme>>`
  &&& {
    font-size: ${props => props.theme.fontSizes.m};
    color: ${props => (props.inactive ? props.theme.colors.neutralGrey4 : props.theme.colors.primaryPurple)};
    padding-top: ${props => props.theme.pxToRem(3)};
  }
`;

const StyledOnlyMobile = styled(OnlyMobile)`
  display: inline-block;
`;

export const StyledRow = styled(Table.Row)<ThemedStyledProps<{ expanded: boolean }, DefaultTheme>>`
  &&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      box-shadow: ${props => (!props.expanded ? '0 -0.0625rem 0 0 #e5e5e6 inset !important' : 'none !important')};
    }
  }
`;

const SeatAssignmentContainer = styled.div`
  width: 100%;
  align-items: left;
`;

const StyledButtonWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const StyledCheckBoxWrapper = styled.div<{
  showMoreSeats: boolean;
  numberOfItems: number;
}>`
  margin-top: ${props => props.theme.pxToRem(10)};
  padding-right: ${props => props.theme.pxToRem(10)};
  height: auto;
  max-height: ${props =>
    `${
      props.numberOfItems > 5
        ? props.showMoreSeats
          ? props.numberOfItems > 15
            ? props.theme.pxToRem(580)
            : 'auto'
          : props.theme.pxToRem(210)
        : 'auto'
    }`};
  overflow-y: ${props => `${props.numberOfItems > 15 && props.showMoreSeats ? 'auto' : 'hidden'}`};
  overflow-x: hidden;
`;

const StyledCheckBox = styled(Checkbox)`
  ${props => `
    &&&.ui.checkbox label {
      font-size: ${props.theme.pxToRem(14)}
      font-weight: ${props.theme.fontWeights.light};
      color: ${props.theme.colors.primaryPurple};
    }
  `};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

const CustomCheckBoxLabel = styled.div`
  display: flex;
  margin-top: ${props => props.theme.pxToRem(-35)};
  margin-bottom: ${props => props.theme.pxToRem(13)};
`;

const EmailTagsContainer = styled.div<{ height: number }>`
  margin-top: ${props => props.theme.pxToRem(10)};
  width: ${props => props.theme.pxToRem(510)};
  max-height: ${props => props.theme.pxToRem(props.height)};
  overflow-y: ${props => `${props.height === 225 ? 'auto' : 'hidden'}`};
`;

const ShowMoreSeatsButtonWrapper = styled.div<{ isOnTable: boolean }>`
  display: flex;
  justify-content: end;
  margin-top: ${props => props.theme.pxToRem(15)};
  padding-right: ${props => props.theme.pxToRem(props.isOnTable ? 125 : 0)};
`;

const ShowMoreSeatButton = styled(Button)`
  &&&&&&& {
    font-size: ${props => props.theme.fontSizes.xs} !important;
    color: ${props => props.theme.colors.primaryPurple};
    ${props => props.theme.mediaQueries.mobileOnly} {
      min-width: ${props => props.theme.pxToRem(140)};
      margin-top: ${props => props.theme.pxToRem(8)};
    }
  }
`;

const ShowMoreSeatIconWrapper = styled.div`
  margin-left: ${props => props.theme.pxToRem(10)};
`;

const ErrorMessageContainer = styled.div`
  color: ${props => props.theme.colors.interfaceRed};
  width: 100%;
  word-wrap: break-word;
  font-weight: 600;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.xxs};
  padding: ${props => props.theme.pxToRem(8)};
`;

const StyledClickableText = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const SelectAllWrapper = styled.div`
  width: 100%;
`;

const StyledDiv = styled.div`
  margin-left: 30px;
  width: ${props => props.theme.pxToRem(160)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmptyMessageContainer = styled.div`
  width: 100%;
  margin-top: 0;
  padding-top: 0;
`;
