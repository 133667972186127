import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CardOutlay } from 'components/atoms';
import { ReactComponent as IconCertCheck } from 'resources/images/icon-dev-ic-certificate-program.svg';
import { ExemptionLevels, MembershipTypes } from 'mxp-schemas';
import { currentJourneyLearningPathwaySelector } from 'modules/user/selectors';
interface EntryPointLevelExamList {
  entryPointLevelExam: string;
}
export interface EntryPointLevelCardProps {
  headerTitle?: string | number;
  description?: string;
  entryPointLevelExamList?: EntryPointLevelExamList[];
  isSelected?: boolean;
  isFLPPath?: boolean;
  levelNameValidateText?: string;
  slug?: string;
  isOfflineExemptionCalculator?: boolean;
}

export const EntryPointLevelCard: React.FC<EntryPointLevelCardProps> = React.memo(
  ({
    headerTitle,
    entryPointLevelExamList,
    isFLPPath = false,
    levelNameValidateText = '',
    slug,
    description,
    isOfflineExemptionCalculator,
  }) => {
    const currentJourneyLearningPathway = useSelector(currentJourneyLearningPathwaySelector);
    const titleHeader = Number(headerTitle);
    const levelNameValidate =
      currentJourneyLearningPathway === MembershipTypes.Pathway.FLP ||
      isFLPPath ||
      slug === MembershipTypes.CIMALearningPathwaySlug.FLP
        ? Object.values(ExemptionLevels.LevelNameFLP)[titleHeader - 1]
        : currentJourneyLearningPathway === MembershipTypes.Pathway.CHINESE_PQ
        ? Object.values(ExemptionLevels.LevelNameChinesePQ)[titleHeader - 1]
        : Object.values(ExemptionLevels.LevelName)[titleHeader - 1];

    return (
      <>
        <StyledHeaderDiv>
          <CardOutlay type="cima-header">
            {levelNameValidateText ? levelNameValidateText : levelNameValidate}
          </CardOutlay>
        </StyledHeaderDiv>

        <StyledHeaderDiv>
          <CardOutlay type="cima-header">
            {isOfflineExemptionCalculator && <StyledLevelDescription>{description}</StyledLevelDescription>}
          </CardOutlay>
        </StyledHeaderDiv>

        <BodyCardWrapper>
          <StyledDescription>
            {entryPointLevelExamList?.map((item: { entryPointLevelExam: string }, key) => {
              return (
                <ItemListWrapper key={key}>
                  <StyledIconCheck />
                  <p>{item.entryPointLevelExam}</p>
                </ItemListWrapper>
              );
            })}
          </StyledDescription>
        </BodyCardWrapper>
      </>
    );
  }
);

const BodyCardWrapper = styled.div`
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDescription = styled.div`
  position: relative;
  left: 0;
  margin: 0 0 ${props => props.theme.pxToRem(24)};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: ${props => props.theme.fontWeights.light};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
`;

const StyledHeaderDiv = styled.div`
  display: flex;
  position: relative;
`;

const StyledIconCheck = styled(IconCertCheck)`
  display: block;
`;

const StyledLevelDescription = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
`;
const ItemListWrapper = styled.div`
  display: flex;
  position: relative;
  margin-top: ${props => props.theme.pxToRem(5)};
  align-items: center;
  gap: ${props => props.theme.pxToRem(10)};
`;
