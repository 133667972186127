import React from 'react';
import styled from 'styled-components';
import { Button, ButtonEnums, Container } from 'components/atoms';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';

interface Props {
  lookUpQuery?: string;
  tipsList?: string[];
  previousPage: () => void;
  backLinkText: string;
}
export const NoSearchResults: React.FC<Props> = ({ lookUpQuery, tipsList, previousPage, backLinkText }) => {
  return (
    <Container>
      <PreviousPage backText={backLinkText} />
      <StyledNoCustomer>No results found for</StyledNoCustomer>
      <StyledLookupQuery>{lookUpQuery}</StyledLookupQuery>
      <StyledTipsParagraph>Tips for your next search:</StyledTipsParagraph>
      <StyledTipsList>
        {tipsList?.map((tip: string) => (
          <li key={tip}>{tip}</li>
        ))}
      </StyledTipsList>

      <StyledSearchAgainButton
        size={ButtonEnums.sizes.medium}
        variant={ButtonEnums.variants.primary}
        testId="return-to-admin-page"
        onClick={previousPage}
      >
        Search again
      </StyledSearchAgainButton>
    </Container>
  );
};

const StyledNoCustomer = styled.p`
  font-size: ${props => props.theme.fontSizes.m};
  margin: ${props => `${props.theme.pxToRem(24)}`} 0 ${props => `${props.theme.pxToRem(24)}`} 0;
  font-weight: ${props => props.theme.fontWeights.light};
  margin-bottom: ${props => `${props.theme.pxToRem(26)}`};
`;

const StyledLookupQuery = styled.p`
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledTipsParagraph = styled.p`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledTipsList = styled.ul`
  padding-left: ${props => `${props.theme.pxToRem(24)}`};
  margin: ${props => `${props.theme.pxToRem(-8)}`} 0;

  & > li {
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.light};
    margin-bottom: ${props => `${props.theme.pxToRem(8)}`};
  }
`;

const StyledSearchAgainButton = styled(Button)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(24)};
    margin-top: ${props => props.theme.pxToRem(32)};
  }
`;
