import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'components/atoms';
import { MarketingOptionsInterface, Option } from './interfaces';

export const MarketingOptions: React.FC<MarketingOptionsInterface> = ({ options, children }) => {
  return (
    <>
      <StyledCheckboxContainer>
        {options.map((option: Option) => (
          <StyledCheckbox
            key={option.id}
            onChange={option.onChange}
            checked={option.checked}
            id={option.id}
            testId={option.testId}
            label={option.label}
            width={18}
            height={18}
          />
        ))}
      </StyledCheckboxContainer>
      <StyledChildrenContainer>{children}</StyledChildrenContainer>
    </>
  );
};

const StyledCheckboxContainer = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(30)};
`;

const StyledChildrenContainer = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  margin-bottom: ${props => props.theme.pxToRem(30)};
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    display: block;
    margin-top: ${props => props.theme.pxToRem(24)};
    font-weight: ${props => props.theme.fontWeights.regular};
    font-size: ${props => props.theme.fontSizes.xxs};
  }
  &.ui.checkbox .box,
  &.ui.checkbox label {
    display: inline-block;
  }
  &.ui.checkbox input:checked ~ label {
    font-weight: ${props => props.theme.fontWeights.medium};
    font-size: ${props => props.theme.fontSizes.xxs};
  }
`;
