import React from 'react';
import styled from 'styled-components';
import { DropdownProps, Dropdown } from 'components/atoms/Dropdown/Dropdown';
import { DropdownSearchModal } from './DropdownSearchModal';
import { isMobileViewPort } from 'utils';

interface DropdownSearchProps extends DropdownProps {
  name: string;
  title: string;
  placeholder: string;
  isOpenState?: boolean;
  className?: string;
  onChange: (e: React.SyntheticEvent, data: DropdownProps) => void;
  // Next props required only for DropdownSearchModal -> DateFilter
  selectedStartDate?: string;
  selectedEndDate?: string;
  eventDateFilterItems?: Common.FilterItemProps[];
  handleDateFilterChange?: (filterName: string, value: string) => void;
}

export const DropdownSearch: React.FC<DropdownSearchProps> = React.memo(
  ({
    title,
    className,
    open = false,
    children,
    defaultOpen = false,
    // Next props required only for DropdownSearchModal -> DateFilter
    selectedStartDate,
    selectedEndDate,
    eventDateFilterItems,
    handleDateFilterChange,
    ...restProps
  }) => {
    const [isOpen, setIsOpen] = React.useState(defaultOpen);
    const [isModalOpen, setModalOpen] = React.useState(false);
    const isMobile: boolean = isMobileViewPort();

    const handleOnOpen = React.useCallback(() => {
      if (isMobile) return;
      setIsOpen(true);
    }, [isMobile]);

    const handleOnClose = React.useCallback(() => {
      if (isMobile) return;
      setIsOpen(false);
    }, [isMobile]);

    const toggleDropdownModal = React.useCallback(() => {
      const skipModalToggle: boolean = Boolean(!isMobile || (!isModalOpen && restProps.value));
      if (!skipModalToggle) setModalOpen(!isModalOpen);
    }, [isMobile, isModalOpen, restProps.value]);

    return (
      <>
        <StyledContainer open={isOpen || open} className={className}>
          <StyledTitle className="ds-title">{title}</StyledTitle>
          <StyledDropdown
            onClose={handleOnClose}
            onOpen={handleOnOpen}
            onClick={toggleDropdownModal}
            {...restProps}
            scrolling
            open={isOpen || open}
          >
            {children}
          </StyledDropdown>
        </StyledContainer>
        <StyledDivider />
        <DropdownSearchModal
          name={restProps.name}
          title={title}
          placeholder={restProps.placeholder}
          isModalOpen={isModalOpen}
          options={restProps.options}
          toggleDropdownModal={toggleDropdownModal}
          onChange={restProps.onChange}
          // Next props required only for DropdownSearchModal -> DateFilter
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          eventDateFilterItems={eventDateFilterItems}
          handleDateFilterChange={handleDateFilterChange}
        />
      </>
    );
  }
);

const StyledContainer = styled.div<{ open: boolean }>`
  width: 100%;
  align-self: center;
  border: ${props => (props.open ? `solid 1px ${props.theme.colors.primaryPurple}` : `solid 1px transparent`)};
  border-radius: ${props => props.theme.pxToRem(2)};
`;

const StyledTitle = styled.div`
  padding: 0 0 0 ${props => props.theme.pxToRem(24)};
  margin-top: ${props => props.theme.pxToRem(24)};
  margin-bottom: ${props => props.theme.pxToRem(3)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;
`;

const StyledDropdown = styled(Dropdown)`
  &&&& {
    display: flex;
    width: 100%;
    height: fit-content;
    padding-top: ${props => props.theme.pxToRem(40)};
    padding-left: ${props => props.theme.pxToRem(24)};
    border: none;
    margin-top: -${props => props.theme.pxToRem(40)};
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.s};

    svg {
      position: relative;
      top: ${props => props.theme.pxToRem(-20)};
      min-width: ${props => props.theme.pxToRem(10)};
      margin-right: ${props => props.theme.pxToRem(24)};

      ${props => props.theme.mediaQueries.mobileOnly} {
        transform: rotate(-90deg); /* rotate dropdown icon to the right */
      }

      path {
        fill: ${props => props.theme.colors.primaryPurple};
      }
    }

    &&& > div.text {
      overflow: hidden;
      min-height: ${props => props.theme.pxToRem(45)};
      font-size: ${props => props.theme.fontSizes.s};
      font-weight: ${props => props.theme.fontWeights.medium};
      white-space: nowrap;
      text-overflow: ellipsis;

      &.default {
        color: ${props => props.theme.colors.neutralGrey8};
        font-weight: ${props => props.theme.fontWeights.light};
        line-height: 1.5;
      }
    }

    div.visible.menu.transition {
      border: none;
      margin: ${props => props.theme.pxToRem(2)} 0 0;
      box-shadow: 0 ${props => props.theme.pxToRem(1)} ${props => props.theme.pxToRem(4)} 0 rgba(80, 80, 80, 0.5);

      ::before {
        border: none;
      }

      > div.item {
        padding: ${props => `${props.theme.pxToRem(15)} 0  0 ${props.theme.pxToRem(31)}`} !important;
        color: ${props => props.theme.colors.neutralBlack};
        font-size: ${props => props.theme.fontSizes.xs};
        font-weight: ${props => props.theme.fontWeights.light};

        ::after {
          content: '';
          display: block;
          border-bottom: solid ${props => props.theme.colors.neutralGrey1} ${props => props.theme.pxToRem(1)};
          margin: ${props => `${props.theme.pxToRem(15)} ${props.theme.pxToRem(6)} 0 ${props.theme.pxToRem(-25)}`};
        }
      }
    }
  }
`;

const StyledDivider = styled.div`
  width: ${props => props.theme.pxToRem(1)};
  margin: ${props => props.theme.pxToRem(5)} 0;
  background-color: ${props => props.theme.colors.neutralGrey3};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: calc(100% - ${props => props.theme.pxToRem(40)});
    height: ${props => props.theme.pxToRem(1)};
    margin: 0 ${props => props.theme.pxToRem(20)};
  }
`;
