import { createSelector } from 'reselect';
import { Contentful } from 'mxp-schemas';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.landingPagesContent,
  (landingPagesContent: State.LandingPagesContent): State.LandingPagesContent => landingPagesContent
);

export const landingPageFetchedSelector = createSelector(
  rootSelector,
  (landingPagesContent: State.LandingPagesContent): boolean => landingPagesContent.landingPageFetched
);

export const landingPageSelector = createSelector(
  rootSelector,
  (landingPagesContent: State.LandingPagesContent): Contentful.LandingPages.ParsedPage | null =>
    landingPagesContent.landingPage
);

export const skipLandingPageFrontloadRequestSelector = createSelector(
  rootSelector,
  (landingPagesContent: State.LandingPagesContent): boolean => landingPagesContent.skipLandingPageFrontloadRequest
);
