// ------------------------------------
// Constants
// ------------------------------------

export enum DocumentUploadActionNames {
  SET_LOADING = 'document/SET_LOADING',
  SET_ERROR = 'document/SET_ERROR',
  GET_DOCUMENT = 'document/GET_DOCUMENT',
  GET_DOCUMENT_CIMA = 'document/GET_DOCUMENT_CIMA',
  GET_DOCUMENT_FILES = 'document/GET_DOCUMENT_FILES',
  UPLOAD_DOCUMENT = 'document/UPLOAD_DOCUMENT',
  UPLOAD_DOCUMENT_CIMA = 'document/UPLOAD_DOCUMENT_CIMA',
  REMOVE_DOCUMENT_CIMA = 'document/REMOVE_DOCUMENT_CIMA',
  GET_DOCUMENT_NAME = 'document/GET_DOCUMENT_NAME',
  GET_DOCUMENT_EXAM_EXEMPTION = 'document/GET_DOCUMENT_EXAM_EXEMPTION',
}

export enum ErrorType {
  TOO_MANY_FILES = 'too-many-files',
  FILE_NAME_TOO_LONG = 'file-name-too-long',
  FILE_TOO_LARGE = 'file-too-large',
  FILE_INVALID_TYPE = 'file-invalid-type',
  SOMETHING_WENT_WRONG = 'something-went-wrong',
}

export const ApprenticeMathEnglish = 'Maths & English Level 2 certificates';

export const initialState: State.DocumentUpload = {
  error: null,
  loading: false,
  fileName: null,
};
