import React from 'react';
import styled from 'styled-components';
import { Paragraph, Heading, ButtonEnums, ButtonLink } from 'components/atoms';
import { Routes } from 'constants/index';
import { getPath } from 'utils';

interface ArticleFeedbackSuccessProps {
  testId: string;
  thumbIcon?: React.Component;
  editFeedback?: () => void;
}

export const ArticleFeedbackSuccess: React.FC<ArticleFeedbackSuccessProps> = React.memo(({ testId }) => {
  return (
    <StyledWrapper data-testid={testId}>
      <StyledHeading as="h2">Thanks for your feedback</StyledHeading>
      <StyledParagraph>
        We're happy to deliver the content that interests you. Help us keep going by updating your preferences with your
        latest interests and goals.
      </StyledParagraph>
      <StyledButtonLink
        testId="article-feedback-manage-pref"
        variant={ButtonEnums.variants.primary}
        size={ButtonEnums.sizes.large}
        to={getPath(Routes.PROFILE_PREFERENCES)}
        bordered
      >
        Manage preferences
      </StyledButtonLink>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  justify-content: center;
  text-align: center;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.pxToRem(3)};
  font-size: ${props => props.theme.fontSizes.l};
`;

const StyledParagraph = styled(Paragraph)`
  line-height: 1.33;

  & {
    margin-bottom: ${props => props.theme.pxToRem(30)};
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.light};
    font-style: normal;
    font-stretch: normal;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  width: ${props => props.theme.pxToRem(235)};
`;
