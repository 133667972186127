import React from 'react';
import { Product } from 'mxp-schemas';
import { Link } from 'components/atoms/Link/Link';
import { TextParagraph, TitleSizes } from './ProductParagraphs';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

interface Props {
  label: string;
}

export const AccessibilityBlock: React.FC<Props> = React.memo(({ label }) => {
  const renderAccessibilityLink = (): React.ReactNode => (
    <Link isTextLink to={getPath(Routes.WEBSITE_ACCESSIBILITY)}>
      Association's Website Accessibility Policy
    </Link>
  );

  const renderEmailLink = (): React.ReactNode => (
    <Link isTextLink isExternal to="mailto:adaaccessibility@aicpa-cima.com">
      adaaccessibility@aicpa-cima.com
    </Link>
  );

  return label === Product.CloseCaptionedEnum.CLOSE_CAPTIONED ? (
    <TextParagraph
      testId="accessibility-block"
      title="Accessibility"
      contentText={
        <p>
          The Association is dedicated to removing barriers to the accountancy profession and ensuring that all
          accountancy professionals and other members of the public with an interest in the profession or joining the
          profession, including those with disabilities, have access to the profession and the Association's website,
          educational materials, products, and services.The Association is committed to making professional learning
          accessible to all product users. This commitment is maintained in accordance with applicable law. For
          additional information, please refer to the {renderAccessibilityLink()}.{' '}
          <em>As part of this commitment, this product is closed-captioned.</em> For additional accommodation requests
          please contact {renderEmailLink()} and indicate the product that you are interested in (title, etc.) and the
          requested accommodation(s): Audio/Visual/Other. A member of our team will be in contact with you promptly to
          make sure we meet your needs appropriately.
        </p>
      }
      titleSize={TitleSizes.LARGE}
    />
  ) : (
    <TextParagraph
      testId="accessibility-block"
      title="Accessibility"
      contentText={
        <p>
          The Association is dedicated to removing barriers to the accountancy profession and ensuring that all
          accountancy professionals and other members of the public with an interest in the profession or joining the
          profession, including those with disabilities, have access to the profession and the Association's website,
          educational materials, products, and services. The Association is committed to making professional learning
          accessible to all. This commitment is maintained in accordance with applicable law. For additional
          information, please refer to the {renderAccessibilityLink()}. For accommodation requests, please contact{' '}
          {renderEmailLink()} and indicate the product that you are interested in (title, etc.) and the requested
          accommodation(s): Audio/Visual/Other. A member of our team will be in contact with you promptly to make sure
          we meet your needs appropriately.
        </p>
      }
      titleSize={TitleSizes.LARGE}
    />
  );
});
