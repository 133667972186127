import React, { useState } from 'react';
import { Modal, Button, ButtonVariants, RadioButton } from '../../atoms';
import { MembershipPackageAccordionStatus } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { User } from 'mxp-schemas';
import { useHistory } from 'react-router';
import { getMembershipPackagePath } from 'utils';
import { setMembershipPackageType } from 'modules/membership';
import { membershipTypesListSelector } from 'modules/membership/selectors';
interface Props {
  retiredModalOpen: boolean;
  onClose: () => void;
  onClickUpdateSelection?: () => void;
}

const MembershipTypeOptions = [
  { text: 'Switch to Regular membership', activeType: User.MembershipTypes.REGULAR },
  { text: 'Switch to Affiliate membership', activeType: User.MembershipTypes.AFFILIATE },
];

const subText =
  'You subscribed for a Retired membership and you are under 65 years old. We recommend a Regular membership if you are a CPA and Affiliate if you are not a CPA';

export const RetiredQualificationModal: React.FC<Props> = ({ retiredModalOpen, onClose, onClickUpdateSelection }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedType, setSelectedType] = useState<User.MembershipTypes>(MembershipTypeOptions[0].activeType);
  const membershipTypesList = useSelector(membershipTypesListSelector);
  const handleChangeOption = (activeType: User.MembershipTypes) => (event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedType(activeType);
  };

  const handleConfirmButton = React.useCallback(() => {
    const typeSlug =
      selectedType === User.MembershipTypes.REGULAR
        ? User.B2BMembershipTypes.REGULAR
        : User.B2BMembershipTypes.AFFILIATE;

    const typeSelected = membershipTypesList?.find(type => type.slug === typeSlug);
    if (typeSelected?.id) {
      history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Tier));
      dispatch(setMembershipPackageType(typeSelected?.id, typeSlug));
      if (onClickUpdateSelection) {
        onClickUpdateSelection();
      } else {
        onClose();
      }
    }
  }, [dispatch, history, membershipTypesList, onClickUpdateSelection, onClose, selectedType]);

  return (
    <StyledModal
      showCloseCross={true}
      testId="retired-qualification-modal"
      heading="You are not eligible for this membership type"
      open={retiredModalOpen}
      onClose={onClose}
      confirmNode={
        <StyledButton
          testId="update-my-selection-button"
          variant={ButtonVariants.primary}
          onClick={handleConfirmButton}
        >
          Update my selection
        </StyledButton>
      }
    >
      <StyledText>{subText}</StyledText>
      {MembershipTypeOptions.map(({ text, activeType }) => (
        <StyledDiv key={activeType} onClick={handleChangeOption(activeType)}>
          <StyledRadioButton checked={activeType === selectedType} />
          <StyleName>{text}</StyleName>
        </StyledDiv>
      ))}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  & {
    width: ${props => props.theme.pxToRem(590)};
  }

  &&& .ui.modal ${props => props.theme.mediaQueries.mobileOnly} {
    width: 95%;
`;

const StyledText = styled.p`
  text-align: center;
  line-height: 1.57;
  margin-top: ${props => props.theme.pxToRem(28)};
  padding: 0 ${props => props.theme.pxToRem(40)} 0 ${props => props.theme.pxToRem(40)};
`;

const StyledButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(170)};
    height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyleName = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(12)};
  font-size: ${props => props.theme.pxToRem(17)};
`;

const StyledRadioButton = styled(RadioButton)`
  margin-bottom: ${props => props.theme.pxToRem(12)};
  margin-right: ${props => props.theme.pxToRem(10)};
  float: left;
  :hover {
    cursor: pointer;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.pxToRem(4)};
  transform: translateY(40%);
  padding-left: ${props => props.theme.pxToRem(280)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: ${props => props.theme.pxToRem(20)};
  }
`;
