import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Utils, InquiryForm as InquiryFormUtils } from 'mxp-utils';
import { RouteComponentProps, Route } from 'react-router-dom';
import { HelpSection, CountriesDropdown } from 'components/organisms';
import { Container, Grid, Divider, Checkbox, ButtonEnums, Link } from 'components/atoms';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { ReactComponent as Correct } from 'resources/images/icon-dev-ic-check-circle.svg';
import { ReactComponent as ErrorIcon } from 'resources/images/ic-error.svg';
import { MobileInput } from 'components/atoms/Input/MobileInput';
import { InputWithValidation, TextAreaWithValidation, RadioOption, Footer } from 'components/molecules';
import { Button, StrictDropdownProps } from 'semantic-ui-react';
import { userLoadingSelector, personAccountDataSelector, isUserMemberSelector } from 'modules/user/selectors';
import { createGDPRCase, caseAddressValidation, sendGDPREmail, fetchExistingCase } from 'modules/createCase/actions';
import { Routes } from 'constants/index';
import { getPath, hasTruthyValue } from 'utils';

enum reason {
  Valid = 'VALID',
  CleanUp = 'CLEAN_UP',
  Invalid = 'INVALID',
  Ambiguous = 'AMBIGUOUS',
}

enum disposition {
  Yes = 'Yes',
  No = 'No',
}

enum requestType {
  AccessRequest = 'Data Subject Access Request',
  ErasureRequest = 'Data Erasure Request',
}

// TODO: Must be moved to container, action, selectors
export const Gdpr: React.FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const loading = useSelector(userLoadingSelector);
  const [submitStatus, setSubmitStatus] = useState('');
  const personAccountData = useSelector(personAccountDataSelector);
  const isMember: boolean = useSelector(isUserMemberSelector);

  const defaultRegistrationFormState = {
    accountId: isMember ? personAccountData.id : '',
    addressLine1: personAccountData.address.addressLine1 ? personAccountData.address.addressLine1 : '',
    city: personAccountData.address.city ? personAccountData.address.city : '',
    zipcode: personAccountData?.address?.zipCode ? personAccountData?.address?.zipCode : '',
    region: 'USA',
    country: 'United_States_of_America',
    aicpaNumber: '',
    state: personAccountData?.address?.zipCode ? personAccountData?.address?.state : '',
    firstName: personAccountData.firstName ? personAccountData.firstName : '',
    lastName: personAccountData.lastName ? personAccountData.lastName : '',
    email: personAccountData.primaryEmail.emailAddress ? personAccountData.primaryEmail.emailAddress : '',
    phone: '',
    aicpaMember: '',
    cimaMember: '',
    requestData: '',
    certification: 'false',
    description: '',
  };

  const [countrySelected, setCountrySelected] = useState('US');
  const [registrationForm, setRegistrationForm] = useState(defaultRegistrationFormState);
  const [isRegistrationFormValid, setRegistrationFormValid] = useState(false);
  const [phoneObject, setPhoneObject] = useState({
    phoneNumber: '',
    countryCode: 'US',
    dialCode: '+1',
  });

  const firstNameIsEmpty = !Boolean(registrationForm.firstName.length);
  const lastNameIsEmpty = !Boolean(registrationForm.lastName.length);
  const addressIsEmpty = !Boolean(registrationForm.addressLine1.length);
  const cityIsEmpty = !Boolean(registrationForm.city.length);
  const stateIsEmpty = !Boolean(registrationForm.state.length);
  const zipIsEmpty = !Boolean(registrationForm.zipcode.length);
  const emailIsEmpty = !Boolean(registrationForm.email.length);
  const phoneIsEmpty = !Boolean(phoneObject.phoneNumber.length);
  const aicpaMemberEmpty = !Boolean(registrationForm.aicpaMember.length);
  const aicpaNumberEmpty = !Boolean(registrationForm.aicpaNumber.length);
  const cimaMemberEmpty = !Boolean(registrationForm.cimaMember.length);
  const requestDataEmpty = !Boolean(registrationForm.requestData.length);
  const certificationEmpty = !Boolean(registrationForm.certification === 'true');
  const descriptionEmpty = !Boolean(registrationForm.description.length);
  const isShowMemberIdFieldGdprForm = hasTruthyValue(
    registrationForm.aicpaMember === disposition.Yes,
    registrationForm.cimaMember === disposition.Yes
  );

  React.useEffect(() => {
    if (phoneObject) {
      setRegistrationForm({ ...registrationForm, phone: ` ${phoneObject.dialCode} ${phoneObject.phoneNumber}` });
    }
  }, [phoneObject]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRequestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    registrationForm.requestData =
      event.toString() === requestType.AccessRequest ? requestType.AccessRequest : requestType.ErasureRequest;

    setRegistrationForm({ ...registrationForm, requestData: registrationForm.requestData });
    if (event.toString() === requestType.ErasureRequest) {
      setRegistrationForm({ ...registrationForm, description: ' ' });
    }
    if (event.toString() === requestType.AccessRequest) {
      setRegistrationForm(prevState => {
        // Object.assign would also work
        return { ...prevState, description: prevState.description === ' ' ? '' : prevState.description };
      });
    }
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, firstName: event.target.value });
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, lastName: event.target.value });
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, addressLine1: event.target.value });
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, city: event.target.value });
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, state: event.target.value });
  };

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, zipcode: event.target.value });
  };

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRegistrationForm({ ...registrationForm, email: event.target.value });
    },
    [registrationForm, setRegistrationForm]
  );

  const handleAICPAMemberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    registrationForm.aicpaMember = event.toString() === disposition.Yes ? disposition.Yes : disposition.No;
    setRegistrationForm({ ...registrationForm, aicpaMember: registrationForm.aicpaMember });

    if (registrationForm.aicpaMember === disposition.No) {
      setRegistrationForm({ ...registrationForm, aicpaNumber: ' ' });
    }
    if (registrationForm.aicpaMember === disposition.Yes) {
      setRegistrationForm({ ...registrationForm, aicpaNumber: '' });
    }
  };

  const handleAICPANumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    registrationForm.aicpaNumber = event.toString() === disposition.Yes ? disposition.Yes : disposition.No;
    const value = event.target.value;
    if (/[a-z]/i.test(value) || /[!@#$%^&*`~()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)) return; // eslint-disable-line
    setRegistrationForm({ ...registrationForm, aicpaNumber: value });
  };

  const handleDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedCountry: string = data.value as string;
      setCountrySelected(selectedCountry);
      const countryValue = InquiryFormUtils.countryCodeToCountryValue(selectedCountry);
      if (countryValue) {
        setRegistrationForm({ ...registrationForm, region: selectedCountry, country: countryValue.value });
      }
    },
    [setRegistrationForm, registrationForm]
  );

  const handleCIMAMemberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    registrationForm.cimaMember = event.toString() === disposition.Yes ? disposition.Yes : disposition.No;
    setRegistrationForm({ ...registrationForm, cimaMember: registrationForm.cimaMember });
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, description: event.target.value });
  };

  const handleCheckChange = (event: any) => {
    if (event.target.checked) {
      setRegistrationForm({ ...registrationForm, certification: 'true' });
    } else {
      setRegistrationForm({ ...registrationForm, certification: 'false' });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  React.useEffect(() => {
    const formValid =
      !firstNameIsEmpty &&
      !lastNameIsEmpty &&
      !addressIsEmpty &&
      !cityIsEmpty &&
      !stateIsEmpty &&
      !zipIsEmpty &&
      !emailIsEmpty &&
      !phoneIsEmpty &&
      !aicpaNumberEmpty &&
      !aicpaMemberEmpty &&
      !requestDataEmpty &&
      !descriptionEmpty &&
      !certificationEmpty &&
      !cimaMemberEmpty;
    setRegistrationFormValid(formValid);
  }, [
    firstNameIsEmpty,
    lastNameIsEmpty,
    addressIsEmpty,
    cityIsEmpty,
    stateIsEmpty,
    zipIsEmpty,
    emailIsEmpty,
    phoneIsEmpty,
    aicpaNumberEmpty,
    aicpaMemberEmpty,
    requestDataEmpty,
    descriptionEmpty,
    certificationEmpty,
    cimaMemberEmpty,
  ]);

  const [validReason, setValidReason] = useState('');

  // To convert US States to its whole name to be accepted to Salesforce
  if (countrySelected === 'US' && registrationForm.state.length === 2) {
    const getState = InquiryFormUtils.getStatesByCountry(countrySelected);
    const convertToSFState = getState.find(state => state.value === registrationForm.state);
    registrationForm.state = convertToSFState?.text || registrationForm.state;
  }

  const submitRegistration = async () => {
    const shippingAddress = {
      addressLine1: registrationForm.addressLine1,
      addressLine2: '',
      city: registrationForm.city,
      state: registrationForm.state,
      zipCode: registrationForm.zipcode,
      country: registrationForm.region,
    };
    const addressResult = await dispatch(caseAddressValidation(shippingAddress));
    setValidReason(addressResult.payload.smartystreetsValidation.shippingAddress.reason);
  };

  React.useEffect(() => {
    if (validReason === reason.Valid || validReason === reason.CleanUp) {
      dispatch(fetchExistingCase(registrationForm.email)).then((rec: any) => {
        if (rec.payload.getExistingCaseByEmail.length) {
          setSubmitStatus('missing');
        } else {
          dispatch(createGDPRCase({ caseInfo: registrationForm })).then(() => {
            if (personAccountData.id) {
              const gdprEmail = {
                subscriberKey: personAccountData.id,
                emailAddress: registrationForm.email,
                firstName: registrationForm.firstName,
                lastName: registrationForm.lastName,
                oktaId: personAccountData.oktaId,
              };
              dispatch(sendGDPREmail({ gdprRequest: gdprEmail })).then(() => {
                setSubmitStatus('success');
              });
              setValidReason(''); // clear trigger
            } else {
              setSubmitStatus('success');
            }
          });
        }
      });
    } else if (validReason === reason.Invalid || validReason === reason.Ambiguous) {
      setSubmitStatus('missing');
    } else {
      setValidReason(''); // clear trigger
      setSubmitStatus('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validReason]);

  const isEmailValidOrEmpty = Utils.isEmailFormatValid(registrationForm.email) || emailIsEmpty;
  const isFirstNameValidOrEmpty = Utils.isAlphaSpaceString(registrationForm.firstName) || firstNameIsEmpty;
  const isLastNameValidOrEmpty = Utils.isAlphaSpaceString(registrationForm.lastName) || lastNameIsEmpty;
  const isAdressValidLimit = Utils.isShorterThan(registrationForm.addressLine1, 255);
  const isCityValidLimit = Utils.isShorterThan(registrationForm.city, 255);
  const isStateValidLimit = Utils.isShorterThan(registrationForm.state, 255);
  const isZipValidLimit = Utils.isShorterThan(registrationForm.zipcode, 255);
  return (
    <HeaderPageWrapper>
      <StyledContainer>
        <Grid stackable>
          <Grid.Row stretched>
            <StyledGridColumn tablet={16} computer={16} floated="left">
              <StyledDiv>
                <StyledContentContainer>
                  <StyledText>GDPR Request Form</StyledText>

                  {submitStatus === 'success' && (
                    <SuccessStatus>
                      <StyledCorrectIcon />
                      <p>
                        Your request has been received. The Security & Privacy Office will follow up with you within 30
                        days regarding the status of your request.
                      </p>
                    </SuccessStatus>
                  )}

                  {submitStatus === 'missing' && (
                    <ErrorStatus>
                      <StyledErrorIcon />
                      <p>
                        Uh oh! It looks like you have already submitted a request using this email address. To check the
                        status of your request, please contact our Security & Privacy Office at{' '}
                        <a
                          href={'mailto:SecurityandPrivacyOffice@aicpa-cima.com.'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          SecurityandPrivacyOffice@aicpa-cima.com.
                        </a>
                        Thank you!
                      </p>
                    </ErrorStatus>
                  )}
                </StyledContentContainer>
              </StyledDiv>
              <StyledContentContainer>
                <form onSubmit={handleSubmit}>
                  <div>
                    <StyledManProf>
                      Manage your <StyledU to={getPath(Routes.MY_CONSENTS)}>consents preferences</StyledU> on your
                      profile
                    </StyledManProf>
                    <StyledHead>Submit your GDPR request</StyledHead>
                    <br />
                    <div>
                      <StyledRadioContainers>
                        <RadioDiv>
                          <RadioOption
                            checked={registrationForm.requestData === 'Data Subject Access Request' ? true : false}
                            value="Data Subject Access Request"
                            label="Data Subject Access Request"
                            onClick={handleRequestChange}
                          />
                        </RadioDiv>
                      </StyledRadioContainers>
                      <StyledRadioContainers>
                        <RadioDiv>
                          <RadioOption
                            checked={registrationForm.requestData === 'Data Erasure Request' ? true : false}
                            value="Data Erasure Request"
                            label="Data Erasure Request"
                            onClick={handleRequestChange}
                          />
                        </RadioDiv>
                      </StyledRadioContainers>
                    </div>
                  </div>
                  {(registrationForm.requestData === requestType.AccessRequest ||
                    registrationForm.requestData === requestType.ErasureRequest) && (
                    <div>
                      <StyledInput
                        onChange={handleFirstNameChange}
                        name={'First Name'}
                        testId={'First Name'}
                        type="text"
                        isCorrect={isFirstNameValidOrEmpty}
                        labelId={'first-name'}
                        labelName="First Name"
                        value={registrationForm.firstName}
                        autoFocus
                        errorMessage={'You have entered an invalid character'}
                        infoMessage={'Please enter your First Name'}
                        isCorrectIconShown
                      />
                      <StyledInput
                        onChange={handleLastNameChange}
                        name={'Last Name'}
                        testId={'Last Name'}
                        type="text"
                        isCorrect={isLastNameValidOrEmpty}
                        labelId={'Last-name'}
                        labelName="Last Name"
                        value={registrationForm.lastName}
                        autoFocus
                        errorMessage={'You have entered an invalid character'}
                        infoMessage={'Please enter your Last Name'}
                        isCorrectIconShown
                      />
                      <StyledInput
                        onChange={handleAddressChange}
                        name={'Address'}
                        testId={'Address'}
                        type="text"
                        isCorrect={validReason === reason.Invalid ? false : isAdressValidLimit}
                        labelId={'Address'}
                        labelName="Address Line 1"
                        value={registrationForm.addressLine1}
                        autoFocus
                        errorMessage={'This address is not recognised, please try again.'}
                        infoMessage={'Please enter your Address'}
                        isCorrectIconShown
                      />
                      <StyledInput
                        onChange={handleCityChange}
                        name={'City'}
                        testId={'City'}
                        type="text"
                        isCorrect={validReason === reason.Invalid ? false : isCityValidLimit}
                        labelId={'City'}
                        labelName="City"
                        value={registrationForm.city}
                        autoFocus
                        errorMessage={'Please input a City.'}
                        infoMessage={'Please enter your City'}
                        isCorrectIconShown
                      />
                      <div>
                        <StyledFlex>
                          <StyledFlexDual>
                            <StyledAddressInput
                              onChange={handleStateChange}
                              name={'State/Province'}
                              testId={'State/Province'}
                              type="text"
                              isCorrect={isStateValidLimit}
                              labelId={'State/Province'}
                              labelName="State/Province"
                              value={registrationForm.state}
                              autoFocus
                              errorMessage={'Please enter a valid State/Province'}
                              infoMessage={'Please enter your State/Province'}
                              isCorrectIconShown
                            />
                          </StyledFlexDual>
                          <StyledFlexDual>
                            <StyledAddressInput
                              onChange={handleZipChange}
                              name={'Zip'}
                              testId={'Zip'}
                              type="text"
                              isCorrect={isZipValidLimit}
                              labelId={'Zip'}
                              labelName="Zip"
                              value={registrationForm.zipcode as string}
                              autoFocus
                              errorMessage={'Please enter a valid Zip code'}
                              infoMessage={'Please enter your Zipcode'}
                              isCorrectIconShown
                            />
                          </StyledFlexDual>
                        </StyledFlex>
                      </div>
                      <CountryContainer>
                        <CountriesDropdown
                          onChange={handleDropdownChange}
                          title="Location"
                          countrySelected={countrySelected}
                        />
                      </CountryContainer>
                      <StyledInput
                        onChange={handleEmailChange}
                        name={'Email Address'}
                        testId={'Email Address'}
                        type="email"
                        isCorrect={isEmailValidOrEmpty}
                        labelId={'Email-address'}
                        labelName="Email Address"
                        value={registrationForm.email}
                        autoFocus
                        errorMessage={'Please enter a valid email address'}
                        infoMessage={'Please enter your Email Address'}
                        isCorrectIconShown
                      />
                      <StyledMobileInput
                        phoneObject={phoneObject}
                        setPhoneObject={setPhoneObject}
                        errorMessage={'Please enter a valid phone number'}
                        label={'Phone Number'}
                      />
                      <StyledRadioContainer>
                        <p>Are you an AICPA member? </p>
                        <RadioDiv>
                          <RadioOption
                            checked={registrationForm.aicpaMember === disposition.Yes && true}
                            value="Yes"
                            label="Yes"
                            onClick={handleAICPAMemberChange}
                          />
                          <RadioOption
                            checked={registrationForm.aicpaMember === disposition.No && true}
                            value="No"
                            label="No"
                            onClick={handleAICPAMemberChange}
                          />
                        </RadioDiv>
                      </StyledRadioContainer>
                      <StyledRadioContainer>
                        <p>Are you a CIMA member or student? </p>
                        <RadioDiv>
                          <RadioOption
                            checked={registrationForm.cimaMember === disposition.Yes && true}
                            value="Yes"
                            label="Yes"
                            onClick={handleCIMAMemberChange}
                          />
                          <RadioOption
                            checked={registrationForm.cimaMember === disposition.No && true}
                            value="No"
                            label="No"
                            onClick={handleCIMAMemberChange}
                          />
                        </RadioDiv>
                      </StyledRadioContainer>
                      {isShowMemberIdFieldGdprForm && (
                        <StyledInput
                          onChange={handleAICPANumberChange}
                          name={'AICPAMemberNumber'}
                          testId={'AICPAMemberNumber-GdprForm'}
                          type="text"
                          isCorrect={true}
                          labelId={'AICPAMemberNumber'}
                          labelName="Please provide your Member ID"
                          value={registrationForm.aicpaNumber}
                          autoFocus
                          errorMessage={'Please provide your Member ID'}
                          infoMessage={'Please provide your Member ID'}
                          isCorrectIconShown
                        />
                      )}

                      {registrationForm.requestData === requestType.AccessRequest && (
                        <>
                          <p>Please describe the information you are seeking</p>
                          <StyledTextArea
                            onChange={handleTextAreaChange}
                            name="description"
                            testId="description"
                            isCorrect={!descriptionEmpty}
                            value={registrationForm.description}
                            errorMessage={'Please input a reason for your request.'}
                            infoMessage={'Please enter the needed details.'}
                          />
                          <StyledConfirmationLabel>
                            Please describe the information you are requesting. This should be as detailed as possible
                            to enable us to satisfy your request in a timely manner.
                          </StyledConfirmationLabel>
                        </>
                      )}

                      <StyledCheckbox>
                        <Checkbox testId="checkbox" onChange={handleCheckChange} id="checkbox" autoFocus />
                        <StyledConfirmationLabel>
                          I certify under penalty of perjury that I am the Individual or have authority to submit this
                          request on behalf of the individual about whom I am making this request.
                        </StyledConfirmationLabel>
                      </StyledCheckbox>
                      <SubmitButton
                        loading={loading}
                        size={ButtonEnums.sizes.large}
                        onClick={submitRegistration}
                        testId="register"
                        variant={ButtonEnums.variants.primary}
                        disabled={!isRegistrationFormValid}
                        complete={isRegistrationFormValid ? true : false}
                      >
                        Submit
                      </SubmitButton>
                    </div>
                  )}
                </form>
              </StyledContentContainer>
              <StyledDivider />
              <StyledContentContainer>
                <StyledHelpSection hideCheckoutHelp={true} />
              </StyledContentContainer>
            </StyledGridColumn>
          </Grid.Row>
        </Grid>
      </StyledContainer>
      <Route exact path={getPath(Routes.PRIVACY_GDPR)} component={Footer} />
    </HeaderPageWrapper>
  );
};

const StyledTextArea = styled(TextAreaWithValidation)`
  min-height: ${props => props.theme.pxToRem(200)};
  min-width: ${props => props.theme.pxToRem(500)};
  :focus {
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.tertiaryTeal};
  }
  border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey5};
`;

const StyledHelpSection = styled(HelpSection)`
  margin-top: ${props => props.theme.pxToRem(40)};
  margin-bottom: ${props => props.theme.pxToRem(80)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(24)};
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledContainer = styled.div``;

const StyledContentContainer = styled(Container)`
  display: flex;
  align-items: center;
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(40)};
    border-top-color: transparent;
    border-bottom-color: ${props => props.theme.colors.neutralGrey3};
    color: ${props => props.theme.colors.neutralGrey3};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(25)};
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: ${props => props.theme.pxToRem(260)};
  box-shadow: ${props => props.theme.colors.neutralGrey3};
  background-color: ${props => props.theme.colors.neutralGrey1};
  margin-bottom: ${props => props.theme.pxToRem(40)};
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&&&& {
    display: block !important;
  }
`;

const StyledText = styled.p`
  font-size: ${props => props.theme.fontSizes.xl};
`;

const StyledInput = styled(InputWithValidation)`
  height: ${props => props.theme.pxToRem(60)};
  width: ${props => props.theme.pxToRem(500)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: calc(100% - ${props => props.theme.pxToRem(5)});
  }
`;

const StyledRadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${props => props.theme.pxToRem(500)};
  align-items: baseline;
`;

const StyledRadioContainers = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${props => props.theme.pxToRem(500)};
  align-items: baseline;
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

const RadioDiv = styled.div`
  display: flex;
  gap: ${props => props.theme.pxToRem(10)};
`;

const StyledFlex = styled.div`
  width: 500px;
  display: flex;
  align-items: baseline;
  gap: ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledFlexDual = styled.div`
  flex: 1;
  width: 200px;
  .input {
    width: 236px;
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      padding-right: 5px;
    }
  }
`;

const StyledAddressInput = styled(InputWithValidation)`
  position: relative;
  height: ${props => props.theme.pxToRem(60)};
  width: ${props => props.theme.pxToRem(250)};
  &&& {
    input#Zip {
      @media (max-width: 991px) {
        width: 100% !important;
      }
    }
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: calc(100% - ${props => props.theme.pxToRem(5)});
  }
`;

const CountryContainer = styled.div`
  width: ${props => props.theme.pxToRem(500)};
`;

const StyledMobileInput = styled(MobileInput)`
  &&& {
    width: ${props => props.theme.pxToRem(400)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: calc(100% - ${props => props.theme.pxToRem(5)});
    }
    input {
      border-radius: 0 5px 5px 0;
      height: ${props => props.theme.pxToRem(60)} !important;
    }
    div:nth-child(3) {
      flex: 1 100%;
    }
  }
  &&&& {
    ${props => `
    &&& {
      height: ${props.theme.pxToRem(60)};
   }
	}
  `}
  }
`;

const StyledHead = styled.p`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.thin};
`;

const StyledU = styled(Link)`
  color: ${props => props.theme.colors.primaryPurple};
`;

const StyledManProf = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.thin};
  margin-bottom: ${props => props.theme.pxToRem(40)};
`;

const StyledConfirmationLabel = styled.p`
  margin-top: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.thin};
  max-width: ${props => props.theme.pxToRem(400)};
  transform: translateY(${props => props.theme.pxToRem(-8)});
`;

const SuccessStatus = styled.div`
  color: #47a23d;
  position: relative;
  max-width: 699px;
  width: 100%;
  padding: 15px 16px;
  border-radius: 4px;
  background: #d4e7ee;
  border: 1px solid;
  margin-bottom: ${props => props.theme.pxToRem(20)};
  p {
    color: ${props => props.theme.colors.neutralGrey8};
    margin-left: ${props => props.theme.pxToRem(24)};
    line-height: 1.57;
    font-size: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledCorrectIcon = styled(Correct)`
  position: absolute;
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  left: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.interfaceGreen};
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ErrorStatus = styled.div`
  position: relative;
  max-width: 699px;
  width: 100%;
  padding: 15px 16px;
  border-radius: 4px;
  background: #fceaec;
  border: 1px solid #e83147;
  margin-bottom: ${props => props.theme.pxToRem(20)};
  p {
    margin-left: ${props => props.theme.pxToRem(24)};
    line-height: 1.57;
    font-size: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledErrorIcon = styled(ErrorIcon)`
  position: absolute;
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  left: ${props => props.theme.pxToRem(20)};
  color: #e83047;
  top: 50%;
  color: #e83047;
  transform: translate(-50%, -50%);
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${props => props.theme.pxToRem(15)};
  margin-top: ${props => props.theme.pxToRem(30)};
`;

const SubmitButton = styled(Button)`
  background: ${props => (props.complete ? '#72246c !important' : '')};
  color: ${props => (props.complete ? '#fff !important' : '')};
  &&&& {
    padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(120)};
    margin-top: ${props => props.theme.pxToRem(30)};
  }
`;
