import React from 'react';
import { User } from 'mxp-schemas';

interface Props {
  action: string;
  hidUsername: string;
  hidOktaId: string;
  hidOktaRoleId: User.MembershipIdsEnum[] | [];
  hidURL: string;
  children: React.ReactNode;
  target: string;
}

export const OPLForm: React.FC<Props> = ({
  action,
  hidUsername,
  hidOktaId,
  hidOktaRoleId,
  hidURL,
  children,
  target,
}) => {
  return (
    <form action={action} method="post" target={target}>
      <input type="hidden" name="hidUsername" value={hidUsername} />
      <input type="hidden" name="hidOktaId" value={hidOktaId} />
      <input type="hidden" name="hidOktaRoleId" value={hidOktaRoleId} />
      <input type="hidden" name="hidSourceSiteCode" value="RAVE" />
      <input type="hidden" name="hidURL" value={hidURL} />
      {children}
    </form>
  );
};
