import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { getMarketingData, updateMarketingData, resetConsentModule } from './actions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [getMarketingData]: {
    next: (state: State.IndividualConsent, action: any): State.IndividualConsent => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    throw: (state: State.IndividualConsent, action: any): State.IndividualConsent => ({
      ...state,
      isLoading: true,
    }),
  },

  [updateMarketingData]: {
    next: (state: State.IndividualConsent, action: any): State.IndividualConsent => ({
      ...state,
      ...action.payload,
    }),
    throw: (state: State.IndividualConsent, action: any): State.IndividualConsent => ({
      ...state,
      isLoading: true,
    }),
  },

  [resetConsentModule]: (): State.IndividualConsent => initialState,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);

export * from './selectors';
export * from './constants';
export * from './actions';
