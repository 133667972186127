import React from 'react';
import { Button, Modal, ButtonEnums } from 'components/atoms';
import styled from 'styled-components';
import { ReactComponent as IconUnsuccessful } from 'resources/images/ic-unsuccessful.svg';

interface Props {
  isLastItem: boolean;
  topicId: string | null;
  topicName: string;
  subtopicName: string;
  subtopicsNames: string[];
  closeModal: () => void;
  toggleTopicPref: (topicId: string) => void;
  goToProfile: () => void;
}

export const ModalTopicUnfollow: React.FC<Props> = React.memo(props => {
  const { topicId, topicName, subtopicName, subtopicsNames, closeModal, goToProfile, toggleTopicPref, isLastItem } =
    props;

  const handleConfirmClick = React.useCallback((): void => {
    if (!topicId) return;
    toggleTopicPref(topicId);
  }, [topicId, toggleTopicPref]);

  const renderMessage = (): React.ReactNode => {
    if (isLastItem) {
      return subtopicName ? (
        <WarningText>
          You must follow at least <b>one</b> subtopic. <b>{subtopicName}</b> is the only subtopic you are following in{' '}
          <b>{topicName}</b> topic, you can change <b>subtopic</b> selection in manage preferences.
        </WarningText>
      ) : (
        <WarningText>
          You must follow at least <b>one</b> topic. <b>{topicName}</b> is the only topic you are following, you can
          change topic selection in manage preferences.
        </WarningText>
      );
    }

    return (
      <WarningText>
        By unfollowing <SelectedTopic>{topicName}</SelectedTopic> you will also stop following the subtopics you have
        selected:
        <SubtopicList>
          {subtopicsNames.map((name: string) => (
            <React.Fragment key={name}>
              <li>{name}</li>
            </React.Fragment>
          ))}
        </SubtopicList>
      </WarningText>
    );
  };

  return (
    <Modal
      open={Boolean(topicId)}
      size="tiny"
      icon={isLastItem ? <StyledIconUnsuccessful /> : undefined}
      heading={isLastItem ? 'Unfollow unsuccessful' : 'Are you sure?'}
      cancelNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          onClick={closeModal}
          testId="discard-warning"
        >
          {isLastItem ? 'Return to previous page' : 'No, keep following'}
        </Button>
      }
      confirmNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          bordered
          onClick={isLastItem ? goToProfile : handleConfirmClick}
          testId="confirm-warning"
        >
          {isLastItem ? 'Manage preferences' : 'Yes, unfollow'}
        </Button>
      }
      testId="unfollow-subtopics-warning"
    >
      {renderMessage()}
    </Modal>
  );
});

const SelectedTopic = styled.strong`
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const SubtopicList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline;
    font-weight: ${props => props.theme.fontWeights.medium};

    ::after {
      content: ', ';
    }

    :last-child::after {
      content: '';
    }

    :nth-last-child(2)::after {
      content: ' and ';
    }
  }
`;

const WarningText = styled.div`
  text-align: center;
`;

const StyledIconUnsuccessful = styled(IconUnsuccessful)`
  fill: ${props => props.theme.colors.secondaryOrange};
`;
