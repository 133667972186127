import React from 'react';
import styled from 'styled-components';
import { LazyImage } from 'components/atoms/LazyImage/LazyImage';
import { OPLForm } from 'components/molecules/Form/OPLForm';
import { handleEvent, EVENT_CLICK } from 'utils/Analytics';
import { isLinkExternal, formattedURL } from '../BenefitBlock/BenefitBlock';
import { Contentful, User } from 'mxp-schemas';
import { BenefitHeaderCharacterLimit } from '../../../constants/index';

interface BenefitTileProps {
  header: string;
  description: string;
  url: string;
  userEmail?: string;
  oktaId?: string;
  oplHidUrl?: string;
  oplRedirectUrl?: string;
  moduleVersion?: string;
  userHasRequiredAccessRestrictionRole: boolean;
  isOplLink?: boolean;
  image: Contentful.MembershipRestriction.BenefitsSubBlockImage;
  imageMembership: Contentful.MembershipRestriction.BenefitsSubBlockImage;
  fvsMembership?: User.MembershipIdsEnum[] | [];
}
const defaultImageRatio: string = '100%';

export const BenefitTile: React.FC<BenefitTileProps> = ({
  header,
  image,
  imageMembership,
  description,
  url,
  userEmail = '',
  oktaId = '',
  oplHidUrl = '',
  oplRedirectUrl = '',
  fvsMembership = [],
  userHasRequiredAccessRestrictionRole,
  isOplLink,
  moduleVersion,
}) => {
  const handleClickCard = React.useCallback(() => {
    if (url || isOplLink) {
      const finalURL = isOplLink ? oplRedirectUrl : url;
      const isExternal = isLinkExternal(finalURL);
      handleEvent(
        {
          clickValue: `card:section-mem-module:${isExternal ? 'ext' : 'int'}:${header}:${formattedURL(
            finalURL,
            isExternal
          )}`,
          moduleVersion,
        },
        EVENT_CLICK
      );
    }
  }, [isOplLink, oplRedirectUrl, header, url, moduleVersion]);

  const renderImageHolder = () => (
    <ImageHolder
      data-testid="tile-image-holder"
      userHasRequiredAccessRestrictionRole={userHasRequiredAccessRestrictionRole}
    >
      <LazyImage
        src={userHasRequiredAccessRestrictionRole ? imageMembership.url : image.url}
        alt={userHasRequiredAccessRestrictionRole ? imageMembership.title : image.title}
        imageRatio={defaultImageRatio}
        stretchByWidth
      />
    </ImageHolder>
  );

  const renderDescription = () => (
    <DescriptionHolder userHasRequiredAccessRestrictionRole={userHasRequiredAccessRestrictionRole}>
      {userHasRequiredAccessRestrictionRole ? (
        isOplLink ? (
          <StyledPremiumHeaderOPL>{header}</StyledPremiumHeaderOPL>
        ) : (
          <StyledPremiumHeader tabIndex={0} href={url || undefined} onClick={handleClickCard}>
            {header}
          </StyledPremiumHeader>
        )
      ) : (
        <StyledHeader tabIndex={0} href={url || undefined} onClick={handleClickCard}>
          {header}
        </StyledHeader>
      )}
      {header.length <= BenefitHeaderCharacterLimit && (
        <StyledDescription tabIndex={0}>{description}</StyledDescription>
      )}
    </DescriptionHolder>
  );

  const renderTiles = () => {
    if (userHasRequiredAccessRestrictionRole) {
      if (isOplLink) {
        return (
          <OPLForm
            action={oplRedirectUrl}
            hidUsername={userEmail}
            hidOktaId={oktaId}
            hidOktaRoleId={fvsMembership}
            hidURL={oplHidUrl}
            target="_blank"
          >
            <StyledButton type="submit" onClick={handleClickCard}>
              {renderImageHolder()}
              {renderDescription()}
            </StyledButton>
          </OPLForm>
        );
      }

      return (
        <StyledLink href={url || undefined} onClick={handleClickCard}>
          {renderImageHolder()}
          {renderDescription()}
        </StyledLink>
      );
    }
    return (
      <TileContainer>
        {renderImageHolder()}
        {renderDescription()}
      </TileContainer>
    );
  };
  return <>{renderTiles()}</>;
};

const TileContainer: any = styled.div`
  ${props => `
  height: ${props.theme.pxToRem(90)};
  width: ${props.theme.pxToRem(438)};
  display: flex;
  border-radius: ${props.theme.pxToRem(10)};
  &&&& {
    padding: 0;
    margin: ${props.theme.pxToRem(10)} 0;
  }
  `}
`;

const StyledLink = styled.a<{ href: any }>`
  ${props => `
    width: ${props.theme.pxToRem(360)};
    height: ${props.theme.pxToRem(104)};
    display: flex;
    border: solid ${props.theme.pxToRem(1)} rgba(0, 0, 0, 0.1);
    box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);
    border-radius: ${props.theme.pxToRem(10)};
    background-color: ${props.theme.colors.neutralWhite};
    &&&& {
      padding: 0;
      margin: ${props.theme.pxToRem(10)} 0;
      text-decoration: none;
    }
    ${props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      height: ${props.theme.pxToRem(96)};
    }
    &:hover {
      border-color: rgba(114, 36, 108, 0.4);
      box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);
      ${
        !props.href?.length
          ? `
        border-color: rgba(0, 0, 0, 0.1);
        box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);
        cursor: default;
        pointer-events: none;
      `
          : ''
      }
    }
  `}
`;

const StyledButton = styled.button`
  ${props => `
    outline:none;
    width: ${props.theme.pxToRem(360)};
    height: ${props.theme.pxToRem(104)};
    display: flex;
    border: solid ${props.theme.pxToRem(1)} rgba(0, 0, 0, 0.1);
    box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);
    border-radius: ${props.theme.pxToRem(10)};
    background-color: ${props.theme.colors.neutralWhite};
    &&&& {
      padding: 0;
      margin: ${props.theme.pxToRem(10)} 0;
      text-decoration: none;
      text-align: left;
    }
    ${props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      height: ${props.theme.pxToRem(96)};
    }
    &:hover {
      border-color: rgba(114, 36, 108, 0.4);
      box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
    },
  `}
`;

const ImageHolder = styled.div<{ userHasRequiredAccessRestrictionRole: boolean }>`
  width: ${props => props.theme.pxToRem(32)};
  padding-top: ${props => props.theme.pxToRem(2)};
  ${props =>
    props.userHasRequiredAccessRestrictionRole &&
    `
    width: ${props.theme.pxToRem(102)};
    height: ${props.theme.pxToRem(102)};
    padding-top: 0;
      ${props.theme.mediaQueries.mobileOnly} {
        width: ${props.theme.pxToRem(94)};
        height: ${props.theme.pxToRem(94)};
      }
  `}

  > div {
    ${props =>
      props.userHasRequiredAccessRestrictionRole &&
      `border-radius: ${props.theme.pxToRem(10)} 0 0 ${props.theme.pxToRem(10)};
    `}
    background-color: transparent;
    > div {
      background-color: transparent;
      height: ${props => props.theme.pxToRem(102)};
      width: ${props => props.theme.pxToRem(102)};
      ${props =>
        props.userHasRequiredAccessRestrictionRole &&
        `
        width: ${props.theme.pxToRem(102)};
        height: ${props.theme.pxToRem(102)};
        ${props.theme.mediaQueries.mobileOnly} {
          width: ${props.theme.pxToRem(94)};
          height: ${props.theme.pxToRem(94)};
        }
    `}
    }
    > img {
      object-fit: cover;
      ${props =>
        props.userHasRequiredAccessRestrictionRole &&
        `
        height: ${props.theme.pxToRem(104)};
        width: ${props.theme.pxToRem(104)};
        ${props.theme.mediaQueries.mobileOnly} {
          height: ${props.theme.pxToRem(94)};
          width: ${props.theme.pxToRem(94)};
        }
      `}
    }
  }
`;

const DescriptionHolder = styled.div<{ userHasRequiredAccessRestrictionRole: boolean }>`
  width: ${props => props.theme.pxToRem(292)};
  margin: 0 ${props => props.theme.pxToRem(16)};
  ${props =>
    props.userHasRequiredAccessRestrictionRole &&
    `
    margin: ${props.theme.pxToRem(16)};
    width: ${props.theme.pxToRem(256)};
    ${props.theme.mediaQueries.mobileOnly} {
      margin: ${props.theme.pxToRem(12)};
      width: ${props.theme.pxToRem(227)};
      height: ${props.theme.pxToRem(96)};
    }
  `}
`;

const StyledHeader = styled.a<{ href: any }>`
  ${props => `
  font-size: ${props.theme.pxToRem(16)};
  font-weight: ${props.theme.fontWeights.medium};
  line-height: 1.5;
  color: ${props.theme.colors.neutralGrey8};
  margin: 0;
  ${props.theme.mediaQueries.ieOnly} {
    font-weight: ${props.theme.fontWeights.bold};
  }
  &:hover {
      color: ${props.theme.colors.primaryPurple};
    text-decoration: underline;

`}
`;

const StyledPremiumHeader = styled.a<{ href: any }>`
  ${props => `
  font-size: ${props.theme.pxToRem(18)};
  font-weight: ${props.theme.fontWeights.medium};
  line-height: 1.5;
  color: ${props.theme.colors.neutralGrey8};
  margin: 0;
  &:hover {
    color: ${props.theme.colors.primaryPurple};
    text-decoration: underline;
  }
`}
`;

const StyledPremiumHeaderOPL = styled.p`
  ${props => `
  font-size: ${props.theme.pxToRem(18)};
  font-weight: ${props.theme.fontWeights.medium};
  line-height: 1.5;
  color: ${props.theme.colors.neutralGrey8};
  margin: 0;
`}
`;

const StyledDescription = styled.p`
  ${props => `
  height: ${props.theme.pxToRem(40)};
  font-size: ${props.theme.pxToRem(14)};
  font-weight: ${props.theme.fontWeights.regular};
  line-height: 1.57;
  color: ${props.theme.colors.neutralGrey8};
  margin-top: ${props.theme.pxToRem(4)};
  overflow: hidden;
  text-overflow: ellipsis;
`}
`;
