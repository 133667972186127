import React from 'react';
import * as Sentry from '@sentry/browser';
import { useSelector } from 'react-redux';
import { sentryDSNSelector, appEnvSelector, appVersionSelector } from 'modules/app/selectors';

export const useSentry = () => {
  const environment: string = useSelector(appEnvSelector);
  const version: string | null = useSelector(appVersionSelector);
  const sentryDSN: string = useSelector(sentryDSNSelector);

  React.useEffect(() => {
    if (sentryDSN) {
      try {
        Sentry.init({
          dsn: sentryDSN,
          release: `mxp-alpha-plus@${version}`,
          environment,
        });
        Sentry.configureScope(scope => {
          scope.setTag('application_context', 'Frontend');
        });
      } catch (e) {
        console.error('Sentry init error');
      }
    }
  }, [environment, version, sentryDSN]);
};
