import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Container, Grid, Divider, HeaderProps as SemanticUIHeaderProps } from 'semantic-ui-react';
import { ExitSurveyContainer as ExitSurvey } from 'containers/ExitSurveyContainer';
import { Heading, OnlyMobile, OnlyDesktop, Button, ButtonEnums, ButtonProps } from 'components/atoms';
import { TellUsSurveyContainer as TellUsSurvey } from 'components/App/lazy-imports';
import { ICON_ARROW_UP, ICON_ARROW_DOWN } from 'resources/images';
import { handleEvent, PREFIXES, EXTERNAL_LINK_EVENT } from 'utils/Analytics';
import { isEmptyString } from 'utils/StringHelpers';
import { ExtLinks } from 'constants/index';
import { getConstantByKeySelector } from 'modules/app/selectors';
import { isCimaPQApprenticeLapsedSelector } from 'modules/membership/selectors';
import { userMemberTypeSelector } from 'modules/user/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { ReactComponent as OpenInNEW } from 'resources/images/ic-open-in-new.svg';
import { RichTextBlocks } from 'mxp-utils';
import { UserMemberTypes } from 'modules/user/constants';
import { setIsCimaMembershipPageJourney } from 'modules/membership/actions';

interface Props {
  buttonTestId?: string;
  title?: string;
  description?: string;
  shouldHaveDescriptions?: boolean;
  ctaText?: string;
  ctaURL?: string;
  showButton?: boolean;
  logoutBanner?: boolean;
  profileBanner?: boolean;
  isReactivate?: boolean;
  setMembershipEvent?: (event: string) => void;
}

export const GenericBanner: React.FC<Props> = ({
  buttonTestId: propButtonTestId,
  title: propTitle,
  description: propDescription,
  shouldHaveDescriptions: propShouldHaveDescriptions = true,
  ctaText: propCtaText,
  ctaURL: propCtaURL,
  showButton: propShowButton,
  logoutBanner,
  profileBanner,
  isReactivate,
  setMembershipEvent,
}: Props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const constantTile =
    useSelector(
      getConstantByKeySelector(logoutBanner ? CONSTANTS.LOGGED_OUT_BANNER.TITLE : CONSTANTS.LOGGED_IN_BANNER.TITLE)
    ) || '';
  const constantDescription =
    useSelector(
      getConstantByKeySelector(
        logoutBanner ? CONSTANTS.LOGGED_OUT_BANNER.DESCRIPTION : CONSTANTS.LOGGED_IN_BANNER.DESCRIPTION
      )
    ) || '';
  const constantCtaText =
    useSelector(
      getConstantByKeySelector(
        logoutBanner ? CONSTANTS.LOGGED_OUT_BANNER.CTA_TEXT : CONSTANTS.LOGGED_IN_BANNER.CTA_TEXT
      )
    ) || '';
  const constantCtaURL =
    useSelector(
      getConstantByKeySelector(logoutBanner ? CONSTANTS.LOGGED_OUT_BANNER.CTA_URL : CONSTANTS.LOGGED_IN_BANNER.CTA_URL)
    ) || '';

  const userStatus: UserMemberTypes = useSelector(userMemberTypeSelector);
  const isCimaApprenticeLapsed = useSelector(isCimaPQApprenticeLapsedSelector);
  const title = propTitle || constantTile;
  const description = propShouldHaveDescriptions ? propDescription || constantDescription : '';
  const ctaText = propCtaText || constantCtaText;
  const ctaURL = propCtaURL || constantCtaURL;
  const showButton = propShowButton || (ctaURL && ctaURL.trim().startsWith('http') && ctaText && ctaText.trim());

  const [showBanner] = useState(() => {
    return Boolean(title || description || ctaText || ctaURL);
  });

  const bannerText = React.useMemo(
    () => ({
      title: title || '',
      description: description || '',
      ctaText: ctaText || '',
    }),
    [title, description, ctaText] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [showTellUsSurvey, setTellUsSurveyVisibility] = React.useState<boolean>(false);
  const [showExitSurvey, setExitSurveyVisibility] = React.useState<boolean>(false);

  const ButtonCtaLegacy: React.FC<Props> = ({ buttonTestId }) => {
    const isExternal = RichTextBlocks.isExternal(ctaURL.trim());

    return (
      showButton && (
        <StyledButton
          testId={`${propButtonTestId || buttonTestId}-cta-legacy`}
          onClick={navigateToGoToLink}
          variant={ButtonEnums.variants.secondaryNegative}
          size={ButtonEnums.sizes.medium}
        >
          {isExternal && <StyledIcon />}

          {bannerText.ctaText}
        </StyledButton>
      )
    );
  };

  const navigateToGoToLink = React.useCallback(() => {
    if (isCimaApprenticeLapsed && setMembershipEvent) setMembershipEvent('isClickedCimaMembershipRenewalInProfile');
    if (isReactivate && setMembershipEvent) {
      setMembershipEvent('isClickedMembershipBuyAgain');
      dispatch(setIsCimaMembershipPageJourney(false, pathname));
    }

    if (ctaURL) {
      if (profileBanner) {
        userStatus !== UserMemberTypes.LOGGED_OUT ? history.push(ctaURL) : window.open(ctaURL, '_blank', 'noreferrer');
      } else {
        window.open(ctaURL, '_blank', 'noreferrer');
      }
    }
    handleEvent(
      {
        text: `${PREFIXES.HEADER}:aicpa.org:${bannerText.ctaText}`,
        href: ctaURL.trim(),
      },
      EXTERNAL_LINK_EVENT
    );
  }, [bannerText.ctaText, ctaURL]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigateToLegacy = React.useCallback(() => {
    window.location.href = ExtLinks.LEGACY;
    handleEvent(
      {
        text: `${PREFIXES.HEADER}:aicpa.org:${bannerText.ctaText}`,
        href: ctaURL.trim(),
      },
      EXTERNAL_LINK_EVENT
    );
  }, [bannerText.ctaText]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTellUsSurveyClose = React.useCallback((): void => {
    setTellUsSurveyVisibility(false);
  }, []);

  // Component will unmount effect
  React.useEffect(() => {
    window.onpopstate = () => {
      if (showExitSurvey) {
        setExitSurveyVisibility(false);
      }

      if (showTellUsSurvey) {
        setTellUsSurveyVisibility(false);
      }
    };
  }, [showExitSurvey, showTellUsSurvey]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!showBanner) return null;
  return (
    <Banner data-testid="generic-banner">
      <Container>
        <StyledGridContainer columns={16} container>
          <Grid.Column computer={12} mobile={14}>
            <StyledInnerGridContainer>
              <StyledGridColumn computer={11} mobile={14}>
                <HiddenInput
                  id="toggle"
                  isEmptyValue={isEmptyString(bannerText.title) || isEmptyString(bannerText.description)}
                  type="checkbox"
                />
                <Title as="h2" indent={!propShouldHaveDescriptions}>
                  <Label
                    htmlFor="toggle"
                    isEmptyValue={isEmptyString(bannerText.title) || isEmptyString(bannerText.description)}
                  >
                    {bannerText.title}
                  </Label>
                </Title>
                {!isEmptyString(bannerText.title) && !isEmptyString(bannerText.description) ? (
                  <ExpandableContent id="expand">
                    <OnlyMobile>
                      <StyledDivider />
                    </OnlyMobile>
                    <Text>{bannerText.description}&nbsp;</Text>
                    <OnlyMobile>
                      <ButtonCtaLegacy buttonTestId="mobile" />
                    </OnlyMobile>
                  </ExpandableContent>
                ) : (
                  <>
                    <Text>{bannerText.description}&nbsp;</Text>
                    <OnlyMobile>
                      <ButtonCtaLegacy buttonTestId="mobile" />
                    </OnlyMobile>
                  </>
                )}
              </StyledGridColumn>
            </StyledInnerGridContainer>
          </Grid.Column>
          <OnlyDesktop>
            <Grid.Column>
              <ButtonCtaLegacy buttonTestId="desktop" />
            </Grid.Column>
          </OnlyDesktop>
        </StyledGridContainer>
      </Container>
      <ExitSurvey show={showExitSurvey} navigateToLegacy={navigateToLegacy} />
      <TellUsSurvey show={showTellUsSurvey} onClose={handleTellUsSurveyClose} />
    </Banner>
  );
};

interface StyledButtonProps extends ButtonProps {
  indentButton?: boolean;
}

interface TitleProps extends SemanticUIHeaderProps {
  indent?: boolean;
}

const Banner = styled(Container)`
  &&& {
    width: 100vw;
    padding: ${props => props.theme.pxToRem(28)} 0;
    background: ${props =>
      `linear-gradient(77deg, ${props.theme.colors.secondaryDarkBlue}, ${props.theme.colors.interfaceBlue})`};
    color: ${props => props.theme.colors.neutralWhite};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

const Title = styled(Heading)<TitleProps>`
  margin-top: ${props => props.theme.pxToRem(!props.indent ? 0 : 25)};
  margin-bottom: ${props => props.theme.pxToRem(4)};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const Text = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledButton = styled(Button)<StyledButtonProps>`
  &&&& {
    margin: ${props => props.theme.pxToRem(!props.indentButton ? 35 : 20)} 0;
  }

  &&&&:hover {
    background-color: ${props => props.theme.colors.neutralWhite};
    color: ${props => props.theme.colors.secondaryDarkBlue};
    border-color: ${props => props.theme.colors.secondaryDarkBlue};
    path {
      fill: ${props => props.theme.colors.secondaryDarkBlue};
    }
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs} !important;
    margin: 0 !important;
  }
`;

const StyledGridContainer = styled(Grid)`
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const StyledInnerGridContainer = styled(Grid)`
  &&& {
    align-items: center;
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  &&& {
    flex: 1;
  }
`;

const HiddenInput = styled.input<{ isEmptyValue: boolean }>`
  display: none;
  visibility: hidden;

  ${props => props.theme.mediaQueries.mobileOnly} {
    &#toggle:checked ~ #expand {
      height: auto;
      overflow: visible;
    }

    &#toggle:checked ~ h2 > label {
      &::before {
        background-image: ${props => (props.isEmptyValue ? `none` : `url(${ICON_ARROW_UP})`)};
      }
    }
  }
`;

const Label = styled.label<{ isEmptyValue: boolean }>`
  ${props => props.theme.mediaQueries.mobileOnly} {
    &::before {
      position: absolute;
      left: ${props => props.theme.pxToRem(-15)};
      content: '';
      width: ${props => props.theme.pxToRem(18)};
      height: ${props => props.theme.pxToRem(15)};
      background-image: ${props => (props.isEmptyValue ? `none` : `url(${ICON_ARROW_DOWN})`)};
      filter: invert(100%);
      transition: transform 0.3s ease-in-out;
    }
  }
`;

const ExpandableContent = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow: hidden;
    height: 0;
  }
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }
`;
const StyledIcon = styled(OpenInNEW)`
  vertical-align: middle;
  display: inline-block;
  margin: 0 ${props => props.theme.pxToRem(3)} ${props => props.theme.pxToRem(2)} 0;
  width: ${props => props.theme.pxToRem(14)};
  height: ${props => props.theme.pxToRem(14)};
  path {
    fill: ${props => props.theme.colors.neutralWhite};
  }
`;
