/* eslint-disable react-hooks/exhaustive-deps */
/* tslint:disable jsx-no-lambda */
/* tslint:disable no-object-literal-type-assertion */
/* eslint-disable complexity */
import React, { useState, useEffect, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MembershipTypes } from 'mxp-schemas';
import {
  Dropdown as Dropdowns,
  DropdownProps,
  DropdownItemProps,
  StrictDropdownProps,
  Icon,
  Popup,
} from 'semantic-ui-react';
import { InputWithValidation, DropdownWithLabel } from 'components/molecules';
import { Utils } from 'mxp-utils';
import styled from 'styled-components';
import { Input, Label, Link } from 'components/atoms';
import { ReactComponent as IconInfo } from 'resources/images/icon-live-ic-info.svg';

import {
  searchResultOrganizationsSelector,
  searchOrganizationsByWildcardLoadingSelector,
  employerAlreadyExistsSelector,
  searchOrganizationsCitiesLoadingSelector,
  searchResultOrganizationsCitiesSelector,
  newEmployerSelector,
} from 'modules/membership/selectors';
import {
  checkIfOrganizationExists,
  clearNewEmployerData,
  getOrganizationByNameAndCity,
  searchOrganizationsByWildcard,
  searchOrganizationsByWildcardLoading,
  searchOrganizationsCitiesLoading,
  searchOrganizationsCities,
  resetEmploymentDataEffect,
} from 'modules/membership/actions';
import { usePageContext } from 'components/pages/PagePracticalExperienceRequirement/PageContext';
import { Routes } from 'constants/index';
import { generatePath } from 'react-router';
import { getPath, isEmptyString } from 'utils';
interface DropdownOption {
  key: number;
  text: string;
  value: string;
}

const optionsBusinesses: DropdownOption[] = [];
const optionsBusinessCities: DropdownOption[] = [];
type supervisorType = MembershipTypes.PracticalExperienceRequirementSupervisor;
type employerType = MembershipTypes.PracticalExperienceRequirementEmployer;

interface DropdownMenu {
  value: null | string;
  searchQuery: string;
  filteredList: supervisorType[];
}

interface Props {
  supervisors: supervisorType[];
  supervisor: supervisorType;
  onChangeData?: (value: supervisorType) => void;
  setSupervisorInfo?: (value: Partial<State.PracticalExperienceRequirementSupervisor>) => void;
  supervisorData?: any;
  setSupervisorEmployer?: (value: Partial<State.Employer>) => void;
  resetSupervisorEmployer?: () => void;
  resetSuperVisorData?: () => void;
  resetSupervisorEmployerAddress?: () => void;
}

export const PracticalExpReqSupervisorForm: React.FC<Props> = memo(
  ({
    onChangeData,
    setSupervisorInfo,
    supervisorData,
    setSupervisorEmployer,
    resetSupervisorEmployer,
    resetSuperVisorData,
    resetSupervisorEmployerAddress,
    ...props
  }) => {
    const { setIsFormValid, isSupervisorValidationAllowed, setIsSupervisorValidationAllowed } = usePageContext();
    const [supervisor, setSupervisor] = useState(props?.supervisor || ({} as supervisorType));
    const [isSupervisorNew, setIsSupervisorNew] = useState(false);
    const dispatch = useDispatch();
    const searchOrganizationsByWildcardLoadingSelect = useSelector(searchOrganizationsByWildcardLoadingSelector);
    const searchOrganizationsCitiesLoadingSelect = useSelector(searchOrganizationsCitiesLoadingSelector);
    const employerAlreadyExists = useSelector(employerAlreadyExistsSelector);
    const searchResultOrganizations = useSelector(searchResultOrganizationsSelector);
    const searchResultOrganizationsCities = useSelector(searchResultOrganizationsCitiesSelector);
    const newEmployer = useSelector(newEmployerSelector);
    const [businesses, setBusinesses] = useState<DropdownOption[]>(optionsBusinesses);
    const [isBusinessNew, setIsBusinessNew] = useState(false);
    const [businessCities, setBusinessCities] = useState<DropdownOption[]>(optionsBusinessCities);
    const [existingEmployerFromSearch, setExistingEmployerFromSearch] = useState(false);
    const [fieldsTouched, setFieldsTouched] = useState(new Map());
    const refNewBusiness: any = useRef(null);
    const [stateBusinessList, setStateBusinessList] = useState({
      value: null,
      searchQuery: '',
      filteredList: businesses,
    });
    const [searchTempStorage, setSearchTempStorage] = useState({
      businessName: '',
      businessCity: '',
    });

    const refDropdownBusinessName: any = React.createRef();

    const businessNameElementId = 'perBusinessName';
    const businessCityElementId = 'perBusinessCity';

    const businessNameDOM = document.getElementById(businessNameElementId);
    const businessCityDOM = document.getElementById(businessCityElementId);
    const supervisors = props.supervisors || ([] as supervisorType[]);
    const [stateSupervisorList, setStateSupervisorList] = useState({} as DropdownMenu);

    useEffect(() => {
      setStateSupervisorList({
        value: null,
        searchQuery: '',
        filteredList: supervisors?.filter((item: any) => item?.firstName && item?.lastName),
      });
    }, [supervisors]);

    const FIRST_NAME_FIELD_ID = 'firstName';
    const LAST_NAME_FIELD_ID = 'lastName';
    const JOB_TITLE_FIELD_ID = 'jobTitle';
    const CITY_FIELD_ID = 'city';
    const ADDRESS_LINE1_FIELD_ID = 'addressLine1';
    const ADDRESS_LINE2_FIELD_ID = 'addressLine2';
    const STATE_FIELD_ID = 'state';
    const POSTAL_CODE_FIELD_ID = 'postalCode';
    const EMAIL_ADDRESS = 'email';
    const LINKEDIN_URL = 'linkedInUrl';
    const BUSINESS_NAME_FIELD_ID = 'businessName';
    const BUSINESS_NAME_SEARCH_ID = 'businessSearch';
    const COMPANY_LOCATION = 'companyLocation';
    const COUNTRY = 'country';

    // FORM FIELDS' VALIDATIONS
    const requiredFieldErrorMessage = 'Please complete this mandatory field.';
    const letterAndHyphen = /^[A-Za-z- ]+$/;
    const isFirstNameNotEmpty = Boolean(supervisorData?.supervisor?.firstName?.length);
    const isFirstNameValid = letterAndHyphen.test(supervisorData?.supervisor?.firstName || ''); // Only allow letters and hyphen
    const firstNameErrorMessage = !isFirstNameNotEmpty
      ? requiredFieldErrorMessage
      : 'You have entered an invalid character';

    const isLastNameNotEmpty = Boolean(supervisorData?.supervisor?.lastName?.length);
    const isLastNameValid = /^[A-Za-z- ]+$/.test(supervisorData?.supervisor?.lastName || ''); // Only allow letters and hyphen
    const lastNameErrorMessage = !isLastNameNotEmpty
      ? requiredFieldErrorMessage
      : 'You have entered an invalid character';

    const isJobTitleLengthValid = Boolean(supervisorData?.supervisor?.jobTitle?.length);
    const jobTitleErrorMessage = !isJobTitleLengthValid ? requiredFieldErrorMessage : '';

    const isEmailEmpty = !Boolean(supervisorData?.supervisor?.email?.length);
    const isEmailValid = Utils.isEmailFormatValid(supervisorData?.supervisor?.email as string);
    const emailErrorMessage = isEmailEmpty ? requiredFieldErrorMessage : 'Please enter a valid email address';

    const isBusinessNameEmpty = isEmptyString((supervisorData?.supervisor?.employer?.name as string) || '');
    const isBusinessLocationEmpty = isEmptyString((supervisorData?.supervisor?.employer?.city as string) || '');
    const isNewBusinessInvalid =
      isBusinessNew &&
      (isEmptyString((supervisorData?.supervisor?.employer?.addressLine1 as string) || '') ||
        isEmptyString(supervisorData?.supervisor?.employer?.city as string) ||
        isEmptyString((supervisorData?.supervisor?.employer?.postalCode as string) || '') ||
        isEmptyString((supervisorData?.supervisor?.employer?.state as string) || '') ||
        isEmptyString((supervisorData?.supervisor?.employer?.country as string) || '')); // tslint:disable-line

    const handleInputChange = React.useCallback((key: string, value: any, isEmployerField?: boolean): void => {
      setFieldsTouched(fields => new Map(fields.set(key, true)));
      if (isEmployerField) {
        setSupervisorEmployer?.({ [key]: value });
      } else {
        setSupervisorInfo?.({ [key]: value });
      }
    }, []);

    const handleItemSupervisorClick = (event: React.SyntheticEvent, data: DropdownItemProps) => {
      setIsSupervisorNew(false);
      const updatedSupervisor = props.supervisors.find(item => {
        return data.text === `${item.firstName} ${item.lastName}`;
      });
      setSupervisorInfo?.(updatedSupervisor as State.PracticalExperienceRequirementSupervisor);

      setSupervisor(updatedSupervisor as supervisorType);
    };

    const handleSearchSupervisor = (event: React.SyntheticEvent, data: StrictDropdownProps) => {
      // handle Supervisor search
    };

    const handleCreateNewSupervisorClick = () => {
      setIsSupervisorNew(true);
      setIsSupervisorValidationAllowed(false);
      resetSuperVisorData?.();
    };

    const searchFn = (event: React.ChangeEvent<HTMLInputElement>, searchQuery: string) => {
      const filteredList = businesses.filter(item => !searchQuery || item.value.includes(searchQuery));
      return filteredList;
    };

    useEffect(() => {
      if (supervisor?.employer) {
        setExistingEmployerFromSearch(true);
      }
    }, []);

    useEffect(() => {
      setIsFormValid(
        isFirstNameNotEmpty &&
          isFirstNameValid &&
          isLastNameNotEmpty &&
          isLastNameValid &&
          isJobTitleLengthValid &&
          !isEmailEmpty &&
          !isNewBusinessInvalid &&
          !isBusinessNameEmpty &&
          !isBusinessLocationEmpty &&
          isEmailValid
      );
    }, [supervisorData]);

    useEffect(() => {
      if (newEmployer?.organization?.id) {
        const emp = newEmployer.organization;
        const address = newEmployer.organization.billingAddress;
        setSupervisorEmployer?.({
          id: emp.id,
          name: emp.name,
          type: emp.type || '',
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          state: address.state,
          postalCode: address.zipCode,
          country: address.country,
        });
      }
    }, [newEmployer]);
    useEffect(() => {
      dispatch(resetEmploymentDataEffect());
    }, [dispatch]);
    useEffect(() => {
      if (searchResultOrganizations) {
        if (searchResultOrganizations?.length) {
          const items: DropdownOption[] = searchResultOrganizations?.map(
            (organization: { name: string; type: string }, index: number) => {
              return { value: organization.name, key: index, text: organization.name };
            }
          );
          setBusinesses(items);
          setStateBusinessList({
            ...stateBusinessList,
            filteredList: items,
          });
          businessNameDOM?.focus();
        }
      }
    }, [searchResultOrganizations]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (searchResultOrganizationsCities?.length) {
        const items: DropdownOption[] = searchResultOrganizationsCities?.map((city: string, index: number) => {
          return { value: city, key: index, text: city };
        });
        setBusinessCities(items);
        businessCityDOM?.focus();
      }
    }, [searchResultOrganizationsCities]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearchChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      { searchQuery }: { searchQuery: string }
    ) => {
      // set Warning Message empty
      const filteredList = searchFn(event, searchQuery);
      setStateBusinessList({ ...stateBusinessList, searchQuery, filteredList });
      setSearchTempStorage({
        ...searchTempStorage,
        businessName: event.target.value,
      });
    };

    const handleBusinessChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>, data: DropdownProps) => {
        const org = searchResultOrganizations?.find(
          organization => organization.name === data.value && organization.type !== null
        );

        setSupervisorEmployer?.({ name: data.value as string, type: org?.type });

        setIsBusinessNew(false);
        setExistingEmployerFromSearch(true);
        // set Warning Message empty
      },
      [searchResultOrganizations, setIsBusinessNew, setExistingEmployerFromSearch]
    );

    const handleSearchBusinessName = () => {
      if (searchTempStorage.businessName.length > 2) {
        dispatch(searchOrganizationsByWildcard(searchTempStorage.businessName));
        dispatch(searchOrganizationsByWildcardLoading());
      } else {
        // set Warning Message 'Business name should be at least three characters.';
      }
    };

    const handleSearchAgainBusinessName = React.useCallback(
      () => {
        setStateBusinessList({
          ...stateBusinessList,
          filteredList: [],
        });
        setBusinessCities([]);
        setIsBusinessNew(false);
        // set Warning Message empty
        setSearchTempStorage({
          businessName: '',
          businessCity: '',
        });
        // reset data here
        resetSupervisorEmployer?.();
        dispatch(resetEmploymentDataEffect());
        setExistingEmployerFromSearch(false);
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [stateBusinessList, setStateBusinessList, setIsBusinessNew, setSearchTempStorage, setExistingEmployerFromSearch]
    );

    const handleItemClick = (event: React.SyntheticEvent, data: DropdownItemProps) => {
      refDropdownBusinessName.current.handleItemClick(event, data);
    };

    const handleCreateNewClick = React.useCallback(
      async (event: React.SyntheticEvent, data: DropdownProps) => {
        // set Warning Message empty
        dispatch(clearNewEmployerData());
        await dispatch(checkIfOrganizationExists(searchTempStorage.businessName)).then((response: any) => {
          if (!response.payload) {
            setSupervisorEmployer?.({ name: searchTempStorage.businessName });

            setIsBusinessNew(true);
          }
        });
      },
      [searchTempStorage, setIsBusinessNew, checkIfOrganizationExists, clearNewEmployerData, supervisor] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleNewBusinessChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setSupervisorEmployer?.({ name: event.target.value });
        setSearchTempStorage({
          ...searchTempStorage,
          businessName: event.target.value,
        });
      },
      [supervisor, searchTempStorage]
    );

    const handleBusinessCityChange = React.useCallback(
      (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setSupervisor({
          ...supervisor,
          employer: {
            ...supervisor.employer,
            city: data.value as string,
          } as employerType,
        });
        dispatch(searchOrganizationsCitiesLoading());
        dispatch(
          getOrganizationByNameAndCity(supervisorData?.supervisor?.employer?.name as string, data.value as string)
        );
      },
      [supervisorData] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleSearchCityChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        // set Warning Message empty
        setSearchTempStorage({
          ...searchTempStorage,
          businessCity: event.target.value,
        });
      },
      [searchTempStorage, setSearchTempStorage]
    );

    const handleSearchBusinessCity = React.useCallback(() => {
      if (searchTempStorage.businessCity.length > 2) {
        dispatch(searchOrganizationsCitiesLoading());
        dispatch(
          searchOrganizationsCities(supervisorData.supervisor?.employer?.name as string, searchTempStorage.businessCity)
        );
      } else {
        // set Warning Message 'Business city should be at least three characters.');
      }
    }, [searchTempStorage, searchOrganizationsCities]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearchAgainBusinessCity = React.useCallback(() => {
      resetSupervisorEmployerAddress?.();

      businessCityDOM?.focus();
      setBusinessCities([]);
      setSearchTempStorage({
        ...searchTempStorage,
        businessCity: '',
      });
      dispatch(resetEmploymentDataEffect());
    }, [businessCityDOM, searchTempStorage, setBusinessCities, setSearchTempStorage, setIsBusinessNew]);

    const handleTouchField = React.useCallback(
      (key: string): void => {
        setFieldsTouched(fields => new Map(fields.set(key, true)));
      },
      [supervisor, setFieldsTouched]
    );

    return (
      <StyledContainer>
        <StyledFlexItem>
          <StyledIPD>Practical experience requirements (PER) supervisor</StyledIPD>
          <Popup
            key="IPDSupervisor"
            content={
              <div>
                Which supervisor(s) can verify your PER? Be sure to ask permission before providing their details.
              </div>
            }
            basic
            trigger={<StyledIcon />}
          />
        </StyledFlexItem>
        <br />
        <StyledVolunteer>
          Provide details of a recent or current supervisor who has direct knowledge of your work. Depending on your
          work experience and number of roles, you may need to submit contact information for more than one supervisor.
        </StyledVolunteer>
        <StyledVolunteer>
          If a supervisor does not directly oversee your work, contact us for alternative arrangements. You can select
          the chat icon to the right of your screen, or{' '}
          <Link data-testid={`visit-help-page-link`} to={generatePath(getPath(Routes.HELP))}>
            visit our help page
          </Link>{' '}
          for frequently asked questions and other contact information.
        </StyledVolunteer>
        <StyledInputLabel>Supervisor</StyledInputLabel>
        <StyledDropdown
          scrolling
          selection
          testId="supervisor"
          selected={supervisor?.employer?.name}
          options={[]}
          placeholder="Select or add a supervisor"
          onChange={handleSearchSupervisor}
          // loading={!!searchSupervisorByWildcardLoadingSelect} supervisor loading selector
          // selected={supervisor?.employer?.name}
          className="selection"
        >
          <Dropdowns.Menu key="business-menu">
            {stateSupervisorList?.filteredList &&
              stateSupervisorList?.filteredList.map((option: any, index: number) => {
                return (
                  <Dropdowns.Item
                    key={option.id}
                    text={`${option.firstName} ${option.lastName}`}
                    value={option}
                    onClick={handleItemSupervisorClick}
                  />
                );
              })}
            <StyledDropdownMenuItem text={'+ Add New'} onClick={handleCreateNewSupervisorClick} />
          </Dropdowns.Menu>
        </StyledDropdown>

        {(supervisorData?.supervisor?.firstName || isSupervisorNew) && (
          // !searchSupervisorByWildcardLoadingSelect &&
          // (!employerAlreadyExists &&
          <StyledSupervisorContainer>
            <InputWithValidation
              onChange={(e: any) => handleInputChange(FIRST_NAME_FIELD_ID, e.target.value)}
              name={FIRST_NAME_FIELD_ID}
              testId={FIRST_NAME_FIELD_ID}
              type="text"
              labelName="First Name"
              labelId={FIRST_NAME_FIELD_ID}
              value={supervisorData?.supervisor.firstName || ''}
              errorMessage={firstNameErrorMessage}
              isCorrect={isFirstNameNotEmpty && isFirstNameValid}
              isCorrectIconShown={
                (!fieldsTouched.get(FIRST_NAME_FIELD_ID) || (isFirstNameNotEmpty && isFirstNameValid)) &&
                isSupervisorNew
              }
              placeholder="Enter first name here"
              disabled={!isSupervisorNew}
              allowedValidationOnload={!isSupervisorValidationAllowed}
            />
            <InputWithValidation
              onChange={(e: any) => handleInputChange(LAST_NAME_FIELD_ID, e.target.value)}
              name={LAST_NAME_FIELD_ID}
              testId={LAST_NAME_FIELD_ID}
              type="text"
              labelName="Last Name"
              labelId={LAST_NAME_FIELD_ID}
              value={supervisorData?.supervisor?.lastName || ''}
              errorMessage={lastNameErrorMessage}
              isCorrect={isLastNameNotEmpty && isLastNameValid}
              isCorrectIconShown={
                (!fieldsTouched.get(LAST_NAME_FIELD_ID) || (isLastNameNotEmpty && isLastNameValid)) && isSupervisorNew
              }
              placeholder="Enter last name here"
              disabled={!isSupervisorNew}
              allowedValidationOnload={!isSupervisorValidationAllowed}
            />
            <InputWithValidation
              onChange={(e: any) => handleInputChange(JOB_TITLE_FIELD_ID, e.target.value)}
              name={JOB_TITLE_FIELD_ID}
              testId={JOB_TITLE_FIELD_ID}
              type="text"
              labelName="Job title (per the PER submission)"
              labelId={JOB_TITLE_FIELD_ID}
              value={supervisorData?.supervisor?.jobTitle || ''}
              errorMessage={jobTitleErrorMessage}
              isCorrect={isJobTitleLengthValid}
              isCorrectIconShown={(!fieldsTouched.get(JOB_TITLE_FIELD_ID) || isJobTitleLengthValid) && isSupervisorNew}
              placeholder="Enter Job title (per the PER submission)"
              disabled={!isSupervisorNew}
              allowedValidationOnload={!isSupervisorValidationAllowed}
            />
            <StyledInputLabel>Business name</StyledInputLabel>
            <StyledEmployerDropdown
              ref={refDropdownBusinessName}
              searchInput={{ id: businessNameElementId }}
              placeholder="Search Business Name"
              search={searchFn}
              onChange={handleBusinessChange}
              selectOnNavigation
              selectOnBlur={false}
              onSearchChange={handleSearchChange}
              loading={!!searchOrganizationsByWildcardLoadingSelect}
              icon={<Icon name="search" onClick={handleSearchBusinessName} />}
              disabled={
                (supervisorData?.supervisor?.employer?.name || isBusinessNew) &&
                !searchOrganizationsByWildcardLoadingSelect &&
                !employerAlreadyExists
              }
              value={supervisorData?.supervisor?.employer?.name || ''}
              text={!isBusinessNew ? supervisorData?.supervisor?.employer?.name : ''}
              className="selection"
              onBlur={() => setFieldsTouched(fields => new Map(fields.set(BUSINESS_NAME_SEARCH_ID, true)))}
            >
              <Dropdowns.Menu key="business-menu">
                {stateBusinessList.filteredList.map((option: any) => {
                  return (
                    <Dropdowns.Item
                      key={`${option.key}`}
                      text={option.text}
                      value={option.value}
                      onClick={handleItemClick}
                    />
                  );
                })}
                <StyledDropdownMenuItem text={'+ Create New'} onClick={handleCreateNewClick} />
              </Dropdowns.Menu>
            </StyledEmployerDropdown>
            {fieldsTouched.get(BUSINESS_NAME_SEARCH_ID) &&
              !isBusinessNew &&
              isEmptyString(supervisorData?.supervisor?.employer?.name) && (
                <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
              )}
            {(supervisorData?.supervisor?.employer?.name || isBusinessNew) &&
              !searchOrganizationsByWildcardLoadingSelect &&
              (!employerAlreadyExists || existingEmployerFromSearch) && (
                <>
                  <StyledResetLink onClick={handleSearchAgainBusinessName}>Search again</StyledResetLink>
                  {isBusinessNew && (
                    <StyledVerticalLine>
                      <StyledInputLabel>Name</StyledInputLabel>
                      <StyledSubInput
                        inputRef={refNewBusiness}
                        placeholder="New Business Name"
                        testId="new-business-name"
                        value={supervisorData?.supervisor?.employer?.name || ''}
                        onChange={handleNewBusinessChange}
                        disabled={!isBusinessNew}
                        onBlur={() => handleTouchField(BUSINESS_NAME_FIELD_ID)}
                      />
                      {fieldsTouched.get(BUSINESS_NAME_FIELD_ID) &&
                        isEmptyString(supervisorData?.supervisor?.employer?.name || '') && (
                          <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                        )}
                    </StyledVerticalLine>
                  )}
                  <>
                    <StyledLabelBusinessCity>Company's location</StyledLabelBusinessCity>
                    <StyledEmployerDropdown
                      searchInput={{ id: businessCityElementId }}
                      placeholder="Search Company's location"
                      options={businessCities}
                      search
                      onChange={handleBusinessCityChange}
                      selectOnNavigation
                      selectOnBlur={false}
                      selection
                      onSearchChange={handleSearchCityChange}
                      loading={!!searchOrganizationsCitiesLoadingSelect}
                      icon={{ className: 'search', onClick: () => handleSearchBusinessCity() }}
                      disabled={Boolean(supervisorData?.supervisor?.employer?.city) || isBusinessNew}
                      value={supervisorData?.supervisor?.employer?.city || ''}
                      text={!isBusinessNew ? supervisorData?.supervisor?.employer?.city : ''}
                      onBlur={() => handleTouchField(COMPANY_LOCATION)}
                    />
                    {fieldsTouched.get(COMPANY_LOCATION) &&
                      isEmptyString(supervisorData?.supervisor?.employer?.city || '') && (
                        <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                      )}
                  </>

                  {(supervisorData?.supervisor?.employer?.city || isBusinessNew) && (
                    <div>
                      {isBusinessNew || (
                        <StyledResetLink onClick={handleSearchAgainBusinessCity}>Search again</StyledResetLink>
                      )}
                      <StyledVerticalLine>
                        <StyledInputLabel>Business address line 1</StyledInputLabel>
                        <StyledSubInput
                          placeholder="Address Line 1"
                          testId="address-line-1"
                          value={supervisorData?.supervisor?.employer?.addressLine1 || ''}
                          onChange={(e: any) => handleInputChange(ADDRESS_LINE1_FIELD_ID, e.target.value, true)}
                          disabled={!isBusinessNew}
                          onBlur={() => handleTouchField(ADDRESS_LINE1_FIELD_ID)}
                        />
                        {fieldsTouched.get(ADDRESS_LINE1_FIELD_ID) &&
                          isEmptyString(supervisorData?.supervisor?.employer?.addressLine1 || '') && (
                            <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                          )}
                        <StyledInputLabel>Business address line 2 (if applicable)</StyledInputLabel>
                        <StyledSubInput
                          placeholder="Address Line 2"
                          testId="address-line-2"
                          value={supervisorData?.supervisor?.employer?.addressLine2 || ''}
                          onChange={(e: any) => handleInputChange(ADDRESS_LINE2_FIELD_ID, e.target.value, true)}
                          disabled={!isBusinessNew}
                        />
                        <StyledContainerCity>
                          <StyledInputLabel>City</StyledInputLabel>
                          <StyledSubInput
                            placeholder="City"
                            testId="address-city"
                            value={supervisorData?.supervisor?.employer?.city || ''}
                            onChange={(e: any) => handleInputChange(CITY_FIELD_ID, e.target.value, true)}
                            disabled={!isBusinessNew}
                            onBlur={() => handleTouchField(CITY_FIELD_ID)}
                          />
                          {fieldsTouched.get(CITY_FIELD_ID) &&
                            isEmptyString(supervisorData?.supervisor?.employer?.city || '') && (
                              <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                            )}
                        </StyledContainerCity>
                        <StyledContainerZIPCountry>
                          <StyledContainerState>
                            <StyledInputLabel>State/province</StyledInputLabel>
                            <StyledInput
                              placeholder="State"
                              testId="state"
                              value={supervisorData?.supervisor?.employer?.state || ''}
                              onChange={(e: any) => handleInputChange(STATE_FIELD_ID, e.target.value, true)}
                              disabled={!isBusinessNew}
                              onBlur={() => handleTouchField(STATE_FIELD_ID)}
                            />
                            {fieldsTouched.get(STATE_FIELD_ID) &&
                              isEmptyString(supervisorData?.supervisor?.employer?.state || '') && (
                                <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                              )}
                          </StyledContainerState>
                          <StyledContainerZIP>
                            <StyledInputLabel>ZIP/Postal Code</StyledInputLabel>
                            <StyledInput
                              placeholder="ZIP/Postal Code"
                              testId="code"
                              value={supervisorData?.supervisor?.employer?.postalCode || ''}
                              onChange={(e: any) => handleInputChange(POSTAL_CODE_FIELD_ID, e.target.value, true)}
                              disabled={!isBusinessNew}
                              onBlur={() => handleTouchField(POSTAL_CODE_FIELD_ID)}
                            />
                            {fieldsTouched.get(POSTAL_CODE_FIELD_ID) &&
                              isEmptyString(supervisorData?.supervisor?.employer?.postalCode || '') && (
                                <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                              )}
                          </StyledContainerZIP>
                        </StyledContainerZIPCountry>
                        <StyledContainerCountry>
                          <StyledInputLabel>Country</StyledInputLabel>
                          <StyledSubInput
                            placeholder="Country"
                            testId="country"
                            value={supervisorData?.supervisor?.employer?.country || ''}
                            onChange={(e: any) => handleInputChange(COUNTRY, e.target.value, true)}
                            disabled={!isBusinessNew}
                            onBlur={() => handleTouchField(COUNTRY)}
                          />
                          {fieldsTouched.get(COUNTRY) &&
                            isEmptyString(supervisorData?.supervisor?.employer?.country || '') && (
                              <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                            )}
                        </StyledContainerCountry>
                      </StyledVerticalLine>
                    </div>
                  )}
                </>
              )}
            <br />
            <InputWithValidation
              onChange={(e: any) => handleInputChange(EMAIL_ADDRESS, e.target.value)}
              name={EMAIL_ADDRESS}
              testId={EMAIL_ADDRESS}
              type="email"
              labelName="Email Address"
              labelId={EMAIL_ADDRESS}
              value={supervisorData?.supervisor?.email || ''}
              errorMessage={emailErrorMessage}
              isCorrect={!isEmailEmpty && isEmailValid}
              isCorrectIconShown={
                (!fieldsTouched.get(EMAIL_ADDRESS) || (!isEmailEmpty && isEmailValid)) && isSupervisorNew
              }
              placeholder="Enter Email Address here"
              disabled={!isSupervisorNew}
              allowedValidationOnload={!isSupervisorValidationAllowed}
            />
            <StyledLinkedIn>
              <StyledLinkedLabel>https://www.linkedin.com/in/</StyledLinkedLabel>
              <StyledLinkedInput
                onChange={(e: any) => handleInputChange(LINKEDIN_URL, e.target.value)}
                name={LINKEDIN_URL}
                testId={LINKEDIN_URL}
                type="text"
                isCorrect={true}
                labelId={LINKEDIN_URL}
                value={supervisorData?.supervisor?.linkedInUrl || ''}
                maxLength={100}
                // errorMessage={firstNameErrorMessage}
                isCorrectIconShown={false}
                placeholder="LinkedIn URL (optional)"
              />
            </StyledLinkedIn>
          </StyledSupervisorContainer>
        )}
      </StyledContainer>
    );
  }
);

const StyledContainer = styled.div`
  width: 50%;
  margin-top: ${props => props.theme.pxToRem(15)};
  margin-bottom: ${props => props.theme.pxToRem(55)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledSupervisorContainer = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(55)};
`;

const StyledDropdown = styled(DropdownWithLabel)`
  ${props => `
    &&&& {
      width: 70%;
      padding: ${props.theme.pxToRem(12)};
      height: ${props.theme.pxToRem(46)};
      margin-bottom: ${props.theme.pxToRem(18)};
      border-radius: ${props.theme.pxToRem(5)};
      &&&&.ui.dropdown > .default {
        color: ${props.theme.colors.primaryPurple};
      }
   }
  `}
`;

const StyledEmployerDropdown = styled(Dropdowns)`
  &&&&&.disabled {
    opacity: 1;
    background-color: ${props => props.theme.colors.neutralGrey2};
    input {
      color: ${props => props.theme.colors.neutralGrey8};
    }
  }

  &&&.ui.dropdown .menu > .item b {
    display: none;
  }

  &&&&& {
    height: ${props => props.theme.pxToRem(44)};
    display: flex;
    div {
      align-self: center;
    }
    i {
      padding-top: ${props => props.theme.pxToRem(12)};
    }
  }
`;

const StyledDropdownMenuItem = styled(Dropdowns.Item)`
  &&&&& {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.primaryPurple};

    &:hover {
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyledInput = styled(Input)`
  &&& {
    display: flex;
    margin-bottom: ${props => props.theme.pxToRem(7)};
  }

  &&&.disabled {
    cursor: not-allowed;
    opacity: 1;
    input {
      border-color: ${props => props.theme.colors.neutralGrey4};
      background-color: ${props => props.theme.colors.neutralGrey2};
    }
  }
`;

const StyledSubInput = styled(Input)`
  &&& {
    display: flex;
    margin-bottom: ${props => props.theme.pxToRem(7)};
  }

  &&&.disabled {
    cursor: not-allowed;
    opacity: 1;
    input {
      border-color: ${props => props.theme.colors.neutralGrey4};
      background-color: ${props => props.theme.colors.neutralGrey2};
    }
  }
`;

const StyledContainerCity = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledContainerCountry = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledContainerZIPCountry = styled.div`
  display: flex;
  /* max-width: ${props => props.theme.pxToRem(400)}; */
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledContainerZIP = styled.div`
  margin-right: ${props => props.theme.pxToRem(16)};
  /* max-width: ${props => props.theme.pxToRem(180)}; */
  ${props => props.theme.mediaQueries.mobileOnly} {
    /* max-width: ${props => props.theme.pxToRem(144)}; */
  }
`;

const StyledContainerState = styled.div`
  margin-right: ${props => props.theme.pxToRem(16)};
`;

const StyledInputLabel = styled(Label)`
  height: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  display: inline-block;

  ${props => props.theme.mediaQueries.mobileOnly}  {
    margin: ${props => props.theme.pxToRem(12)} 0;
    display: block;
    width: 100%;
  }
`;

const StyledResetLink = styled.div`
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  margin-top: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.primaryPurple};
`;

const StyledVerticalLine = styled.div`
  border-left: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey5};
  display: inline-block;
  width: 100%;
  padding-left: ${props => props.theme.pxToRem(23)};
`;

const StyledLabelBusinessCity = styled.label`
  margin-top: ${props => props.theme.pxToRem(32)};
  margin-bottom: ${props => props.theme.pxToRem(8)};

  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};

  display: block;
`;

const StyledIPD = styled(Label)`
  width: ${props => props.theme.pxToRem(524)};
  height: ${props => props.theme.pxToRem(32)};
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(23)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledVolunteer = styled.div`
  margin: ${props => props.theme.pxToRem(12)} 0 ${props => props.theme.pxToRem(16)};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledLinkedInput = styled(InputWithValidation)`
  width: ${props => props.theme.pxToRem(392)};
  margin-left: ${props => props.theme.pxToRem(17.6)};
`;

const StyledLinkedLabel = styled(Label)`
  width: ${props => props.theme.pxToRem(141)};
  height: ${props => props.theme.pxToRem(24)};
  margin: 0px ${props => props.theme.pxToRem(71)} 0px 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledLinkedIn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIcon = styled(IconInfo)`
  color: ${props => props.theme.colors.primaryPurple};
  align-self: flex-start;
  flex: 0 0 ${props => props.theme.pxToRem(25)};
  width: ${props => props.theme.pxToRem(15)};
  height: ${props => props.theme.pxToRem(15)};
`;

const StyledFlexItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledErrorMessage = styled.p`
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.interfaceRed};
`;
