import React, { useCallback, RefObject } from 'react';
import styled from 'styled-components/macro';
import { Grid, Link, LinkEnums, ButtonEnums, Button } from 'components/atoms';
import { DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { Routes } from 'constants/index';
import { getPath, productTypeToSlug, Scroller } from 'utils';
import { generatePath } from 'react-router-dom';
import { Product } from 'mxp-schemas';
import { ReactComponent as BundleProducts } from 'resources/images/new-product-bundle-products.svg';
import { ReactComponent as BulkPurchasing } from 'resources/images/new-product-group-pricing.svg';
import { ReactComponent as CertificateProgram } from 'resources/images/new-product-certificate.svg';
import { ReactComponent as MyCPE } from 'resources/images/new-product-mycpe.svg';
import { ReactComponent as LinkedProducts } from 'resources/images/new-product-linked-products.svg';
import { ReactComponent as StandingOrders } from 'resources/images/new-product-standing-order.svg';
import { ReactComponent as FreeTrialIcon } from 'resources/images/new-product-free-trial-clock.svg';
import { ReactComponent as ProductReview } from 'resources/images/icon-ui-icons-ic-star-border.svg';

interface Props {
  isExam: boolean;
  hasStandingOrders: boolean;
  standingOrderDiscount: Product.StandingOrderDiscount;
  hasCertificateProgram: boolean;
  hasLinkedProducts: boolean;
  linkedProducts: Product.ProductItem[];
  hasBadge: boolean;
  productChildrenScrollTarget: RefObject<HTMLDivElement>;
  bundleBlockScrollTarget: RefObject<HTMLDivElement>;
  belongsToBundle: boolean;
  maxProductFreeTrialDays: number;
  freeTrialBlockScrollTarget: RefObject<HTMLDivElement>;
  ratingsAndReviewChildrenScrollTarget: RefObject<HTMLDivElement>;
}

export const ProductBadges: React.FC<Props> = ({
  isExam,
  hasStandingOrders,
  standingOrderDiscount,
  hasCertificateProgram,
  hasLinkedProducts,
  linkedProducts,
  hasBadge,
  productChildrenScrollTarget,
  bundleBlockScrollTarget,
  belongsToBundle,
  maxProductFreeTrialDays,
  freeTrialBlockScrollTarget,
  ratingsAndReviewChildrenScrollTarget,
}) => {
  const scrollToProductChildrenInfoHandler = useCallback(() => {
    Scroller.scrollToTarget(productChildrenScrollTarget, { useWindow: true });
  }, [productChildrenScrollTarget]);

  const scrollToBundleBlock = useCallback(() => {
    Scroller.scrollToTarget(bundleBlockScrollTarget, { useWindow: true });
  }, [bundleBlockScrollTarget]);

  const scrollToFreeTrialBlock = useCallback(() => {
    Scroller.scrollToTarget(freeTrialBlockScrollTarget, { useWindow: true });
  }, [freeTrialBlockScrollTarget]);

  const scrollToReviewBlock = useCallback(() => {
    Scroller.scrollToTarget(ratingsAndReviewChildrenScrollTarget, { useWindow: true });
  }, [ratingsAndReviewChildrenScrollTarget]);

  return (
    <Grid>
      <StyledColumn computer={6} tablet={16}>
        <StyledBadge data-testid="badge-product-review">
          <ProductReview />
          <StyledLinkText>
            &nbsp;View &nbsp;
            <StyledMoreButtonSession
              testId="product-review-block"
              variant={ButtonEnums.variants.link}
              size={ButtonEnums.sizes.medium}
              onClick={scrollToReviewBlock}
            >
              product reviews
            </StyledMoreButtonSession>
          </StyledLinkText>
        </StyledBadge>
      </StyledColumn>

      <StyledColumn computer={6} tablet={16}>
        <StyledBadge data-testid="badge-group-pricing">
          <BulkPurchasing />
          <StyledLinkText>
            &nbsp;Business &amp; partner&nbsp;
            <StyledMoreButtonSession
              testId="bulk-purchasing-block"
              variant={ButtonEnums.variants.link}
              size={ButtonEnums.sizes.medium}
              onClick={scrollToProductChildrenInfoHandler}
            >
              discounts
            </StyledMoreButtonSession>
          </StyledLinkText>
        </StyledBadge>
      </StyledColumn>
      {hasStandingOrders && standingOrderDiscount && (
        <StyledColumn computer={8} tablet={16}>
          <StyledBadge data-testid="badge-standing-orders">
            <StandingOrders />
            <StyledLinkText>
              &nbsp;Standing order option
              <StyledLink
                type={LinkEnums.type.inlineLink}
                to={`${generatePath(getPath(Routes.STANDING_ORDERS))}`}
                target={'_blank'}
              >
                Save {standingOrderDiscount.new ? `${standingOrderDiscount.new}% now` : null}
              </StyledLink>
            </StyledLinkText>
          </StyledBadge>
        </StyledColumn>
      )}
      {hasCertificateProgram && (
        <StyledColumn computer={5} tablet={16}>
          <StyledBadge data-testid="badge-certificate-program">
            <CertificateProgram />
            <StyledLinkText>
              &nbsp;Part of
              <StyledLink
                type={LinkEnums.type.inlineLink}
                to={`${generatePath(getPath(Routes.PRODUCT_AGGS_BY_TYPE_PAGE), {
                  productTypeSlug: Product.ProductSlug.CERTIFICATE_PROGRAM,
                })}`}
                target={'_blank'}
              >
                Certificate Program
              </StyledLink>
            </StyledLinkText>
          </StyledBadge>
        </StyledColumn>
      )}
      {hasLinkedProducts && (
        <StyledColumn computer={6} tablet={16}>
          <StyledBadge data-testid="badge-linked-products">
            <LinkedProducts />
            <StyledLinkText>
              &nbsp;Also available as
              {(linkedProducts as Product.ProductItem[]).map((linkedProduct: Product.ProductItem, i, arr) => (
                <StyledLink
                  key={linkedProduct.slug}
                  type={LinkEnums.type.inlineLink}
                  to={productLink(
                    linkedProduct.productType as Product.ProductType,
                    linkedProduct.slug as string,
                    linkedProduct.subscriptionProductType?.key as string
                  )}
                  target={'_blank'}
                >
                  {arr.length - 1 === i ? `${linkedProduct.productTypeLabel}` : `${linkedProduct.productTypeLabel},`}
                </StyledLink>
              ))}
            </StyledLinkText>
          </StyledBadge>
        </StyledColumn>
      )}
      {!isExam && hasBadge && (
        <StyledColumn computer={6} tablet={16}>
          <StyledBadge data-testid="badge-webcast-pass">
            <MyCPE />
            <StyledLinkText>
              <StyledLink
                type={LinkEnums.type.inlineLink}
                to={generatePath(getPath(Routes.CPE_MANAGER))}
                target={'_blank'}
              >
                MyCPE Manager
              </StyledLink>
              &nbsp;compatible
            </StyledLinkText>
          </StyledBadge>
        </StyledColumn>
      )}
      {belongsToBundle && (
        <StyledColumn computer={6} tablet={16}>
          <StyledBadge data-testid="badge-bundles-block">
            <BundleProducts />
            <StyledLinkText>
              &nbsp;Part of a discounted&nbsp;
              <StyledMoreButtonSession
                testId="bundles-block"
                variant={ButtonEnums.variants.link}
                size={ButtonEnums.sizes.medium}
                onClick={scrollToBundleBlock}
              >
                bundle
              </StyledMoreButtonSession>
            </StyledLinkText>
          </StyledBadge>
        </StyledColumn>
      )}
      {!!maxProductFreeTrialDays && (
        <StyledColumn computer={6} tablet={16}>
          <StyledBadge data-testid="badge-free-trials-block">
            <FreeTrialIcon />
            <StyledLinkText>
              &nbsp;Available as a{' '}
              <StyledMoreButtonSession
                testId="badge-free-trials-block-link"
                variant={ButtonEnums.variants.link}
                size={ButtonEnums.sizes.medium}
                onClick={scrollToFreeTrialBlock}
              >
                {`${maxProductFreeTrialDays}-day free trial*`}
              </StyledMoreButtonSession>
            </StyledLinkText>
          </StyledBadge>
        </StyledColumn>
      )}
    </Grid>
  );
};

const StyledBadge = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLinkText = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralBlack};
`;

const StyledMoreButtonSession = styled(Button)`
  &&&& {
    span {
      ${DefaultInlineLinkStyles};
    }
  }
`;

const StyledLink = styled(Link)`
  margin-left: ${props => props.theme.pxToRem(5)};
  color: ${props => props.theme.colors.primaryDarkPurple};
`;

const StyledColumn = styled(Grid.Column)`
  &&&&& {
    padding-top: 11px;
    padding-bottom: 11px;
  }
`;

const productLink = (type: Product.ProductType, slug: string, subscriptionType?: string) => {
  return subscriptionType ? `../${subscriptionType}/${slug}` : `../${productTypeToSlug(type)}/${slug}`;
};
