import React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { fullUrlWithSearchSelector } from 'modules/app/selectors';
import { ReactComponent as IconLinkedIn } from 'resources/images/ic-social-linkedin.svg';
import { ReactComponent as IconTwitter } from 'resources/images/ic-social-twitter.svg';
import { ReactComponent as IconFacebook } from 'resources/images/ic-social-facebook.svg';
import { ReactComponent as IconEmail } from 'resources/images/icon-ui-icons-ic-email.svg';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { ReactComponent as IconShare } from 'resources/images/share.svg';
import { isServer, isMobileViewPort } from 'utils';
import { getZIndexFor, Z_INDEX_ORDER } from 'utils/getZIndexFor';
import { handleEvent, NAV_CLICK } from 'utils/Analytics';

interface Props {
  title?: string;
  isSticky?: boolean;
}

enum SocialSharingIcons {
  LINKEDIN = 'LINKEDIN',
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  EMAIL = 'EMAIL',
}

export const SocialSharing = React.memo<Props>(({ title = '', isSticky = false }) => {
  const url = useSelector(fullUrlWithSearchSelector);
  const [toggled, setToggled] = React.useState(false);
  const isMobile = isMobileViewPort();

  const onClick = (icon: SocialSharingIcons) => (event: React.MouseEvent) => {
    if (isServer) return;

    event.preventDefault();

    let sharer = '';
    switch (icon) {
      case SocialSharingIcons.EMAIL:
        sharer = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}`;
        break;
      case SocialSharingIcons.LINKEDIN:
        sharer = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
        break;
      case SocialSharingIcons.TWITTER:
        sharer = `https://twitter.com/intent/tweet?url=${url}`;
        break;
      case SocialSharingIcons.FACEBOOK:
        sharer = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
    }

    const pathName = window.location.pathname.split('/')[1];
    const destination = icon;

    handleEvent({ clickValue: `button:link:int::SOCIALSHARING:${destination}:/${pathName}` }, NAV_CLICK);

    window.open(sharer, '_blank', 'noopener,noreferrer');
  };

  const activeShareIcon = () => {
    const pathName = window.location.pathname.split('/')[1];

    if (toggled) {
      handleEvent({ clickValue: `button:link:int::SOCIALSHARING-CLOSE:/${pathName}` }, NAV_CLICK);
    } else {
      handleEvent({ clickValue: `button:link:int::SOCIALSHARING-OPEN:/${pathName}` }, NAV_CLICK);
    }

    if (isSticky) setToggled(!toggled);

    if (isMobile) {
      if (toggled) {
        document.body.removeAttribute('style');
      } else {
        document.body.style.overflow = 'hidden';
      }
    }
    const element = document.querySelector('#embedded-service');
    if (element) {
      const styleElement = document.querySelector('#uniquest-greyed');
      if (styleElement && styleElement.parentNode) styleElement.parentNode.removeChild(styleElement);

      const style = document.createElement('style');
      style.setAttribute('id', 'uniquest-greyed');
      style.innerHTML = `
        .embedded-service {
          pointer-events: ${toggled ? 'all !important' : 'none !important'} ;
          opacity:${toggled ? '1' : '0.7'};
        }`;
      document.body.appendChild(style);
    }
  };

  const renderSocialSharingIcon = (icon: SocialSharingIcons) => {
    let svg;
    switch (icon) {
      case SocialSharingIcons.LINKEDIN:
        svg = <IconLinkedIn />;
        break;
      case SocialSharingIcons.TWITTER:
        svg = <IconTwitter />;
        break;
      case SocialSharingIcons.FACEBOOK:
        svg = <IconFacebook />;
        break;
      case SocialSharingIcons.EMAIL:
        svg = <StyledIconEmail />;
        break;
    }

    const styledButtonProps = {
      ...(icon === SocialSharingIcons.EMAIL
        ? { href: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}` } && {
            onClick: onClick(icon),
            href: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}`,
          }
        : { onClick: onClick(icon) }),
    };

    return (
      <StyledButton as={icon === SocialSharingIcons.EMAIL ? 'a' : undefined} {...styledButtonProps}>
        {svg}
      </StyledButton>
    );
  };

  const trigger = (
    <StyledTrigger
      aria-label={'Social sharing button'}
      isSticky={isSticky}
      isActive={toggled}
      onClick={activeShareIcon}
    >
      {toggled ? <IconClose /> : <StyledIconShare />}
    </StyledTrigger>
  );

  const contentisStickyTrue = (
    <>
      {toggled && (
        <StyledSocials isSticky={isSticky}>
          {Object.values(SocialSharingIcons).map((icon: SocialSharingIcons) => renderSocialSharingIcon(icon))}
        </StyledSocials>
      )}
    </>
  );

  const contentisStickyFalse = (
    <>
      <StyledText>Share this page</StyledText>
      <StyledSocials isSticky={isSticky}>
        {Object.values(SocialSharingIcons).map((icon: SocialSharingIcons) => renderSocialSharingIcon(icon))}
      </StyledSocials>
      {isSticky && (
        <>
          {trigger}
          {/* tslint:disable-next-line: jsx-no-lambda */}
          <StyledCloseButton aria-label={'Close'} onClick={() => setToggled(!toggled)}>
            <IconClose />
          </StyledCloseButton>
        </>
      )}
    </>
  );

  const content = (
    <StyledContent isSticky={isSticky}>{isSticky ? contentisStickyTrue : contentisStickyFalse}</StyledContent>
  );

  return isSticky ? (
    <StyledButtonContainer>
      <StyledGreyedBackground toggled={toggled} isMobile={isMobile} />
      {trigger}
      <StyledStickyWrapper>{content}</StyledStickyWrapper>
    </StyledButtonContainer>
  ) : (
    <Popup
      key={'social-sharing-pop'}
      data-testid={'social-sharing-popup'}
      trigger={trigger}
      content={content}
      position={!isMobile ? 'right center' : 'top center'}
      style={{ padding: '20px' }}
      hoverable
      hideOnScroll
      on={!isMobile ? ['hover'] : ['hover', 'click']}
    />
  );
});

const StyledStickyWrapper = styled.div`
  ${props => `
    transform: translateX(0%);
    left: ${props.theme.pxToRem(5)};
    transition: transform 1.3s ease-in-out;
    z-index: ${getZIndexFor(Z_INDEX_ORDER.SocialSharing)};`}
`;

const StyledButtonContainer = styled.div`
  ${props => `
    position: fixed;
    bottom: ${props.theme.pxToRem(120)};
    left: 0.9%;
  `}
`;

const StyledTrigger = styled.button<{ isSticky?: boolean; isActive?: boolean }>`
  ${props =>
    props.isSticky &&
    `
      position: fixed;
      bottom: ${props.theme.pxToRem(75)};
      left: 0.9%;
      border-radius:50%;
      outline: 0;
      border: 0;
      background-color: ${props.theme.colors.primaryPurple};

      svg > path {
        fill: ${props.theme.colors.primaryPurple};
      }

      width: ${props.theme.pxToRem(56)};
      height: ${props.theme.pxToRem(56)};
      cursor: pointer;
      z-index: ${getZIndexFor(Z_INDEX_ORDER.SocialSharing)};
      box-shadow: ${props.theme.pxToRem(-1)} ${props.theme.pxToRem(2)} #0000001c;

    `};

  ${props =>
    !props.isSticky &&
    `
      border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3};
      background-color: ${props.theme.colors.neutralWhite};
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: 0;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        height: ${props.theme.pxToRem(20)};
        width: ${props.theme.pxToRem(20)};
        path {
          stroke: ${props.theme.colors.primaryPurple};
          fill: ${props.theme.colors.neutralWhite};
        }
      }
      &:focus, &:hover, &.active {
        border-color: transparent;
        background-color: ${props.theme.colors.primaryPurple};
        svg {
          path {
            stroke: ${props.theme.colors.neutralWhite};
            fill: ${props.theme.colors.primaryPurple};
          }
        }
      }
    `};

  ${props =>
    props.isActive &&
    `
      position: fixed;
      bottom: ${props.theme.pxToRem(75)};
      left: 0.9%;
      border-radius:50%;
      outline: 0;
      border: 0;
      background-color: ${props.theme.colors.primaryPurple};

      svg > path {
        fill: ${props.theme.colors.neutralWhite};
      }

      width: ${props.theme.pxToRem(56)};
      height: ${props.theme.pxToRem(56)};
      cursor: pointer;
      z-index: ${getZIndexFor(Z_INDEX_ORDER.SocialSharing)};
      box-shadow: ${props.theme.pxToRem(-1)} ${props.theme.pxToRem(2)} #0000001c;

    `};
`;

const StyledGreyedBackground = styled.div<{ toggled: boolean; isMobile?: boolean }>`
  ${props =>
    props.toggled &&
    props.isMobile &&
    `
    position: fixed;
    background-color:${props.theme.colors.neutralGrey6};
    z-index: ${getZIndexFor(Z_INDEX_ORDER.SocialSharingGreyedBackground)};
    width: 100%;
    height: 100%;
    top: ${props.theme.pxToRem(0)};
    opacity: 0.7;
    left: ${props.theme.pxToRem(0)};
    `}
`;

const StyledContent = styled.div<{ isSticky: boolean }>`
  ${props =>
    props.isSticky &&
    `
      position: relative;
      bottom: ${props.theme.pxToRem(15)}
      padding-left: ${props.theme.pxToRem(6)}
    `}
`;

const StyledSocials = styled.div<{ isSticky: boolean }>`
  display: ${props => (props.isSticky ? 'inline-grid' : 'flex')};
  ${props => `
    button:not(:last-child){
      margin-right: ${props.theme.pxToRem(20)};
    }
  `}
`;

const StyledButton = styled.button`
  ${props => `
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    > svg {
      width: ${props.theme.pxToRem(44)};
      height: ${props.theme.pxToRem(44)};
    }
  `}
`;

const StyledIconEmail = styled(IconEmail)`
  ${props => `
    border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3};
    background: transparent;
    border-radius: 50%;
    background-color:${props.theme.colors.neutralWhite};
  `}
`;

const StyledText = styled.div`
  ${props => `
    color: ${props.theme.colors.neutralGrey8};
    font-size: ${props.theme.fontSizes.xs};
    margin-bottom: ${props.theme.pxToRem(20)};
`}
`;

const StyledCloseButton = styled.div`
  position: absolute;
  top: ${props => props.theme.pxToRem(4)};
  right: ${props => props.theme.pxToRem(14)};
  cursor: pointer;
`;

const StyledIconShare = styled(IconShare)`
  position: relative;
  top: ${props => props.theme.pxToRem(3)};
  right: ${props => props.theme.pxToRem(1)};
`;
