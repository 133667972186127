import React from 'react';
import styled from 'styled-components/macro';
import { RadioButton } from 'components/atoms';
import { PaymentOptionsText } from './PaymentOptionsText';
import { Invoices, ZuoraTypes } from 'mxp-schemas';
import { ReactComponent as Paypal } from 'resources/images/paypal-logo.svg';
import { ReactComponent as Eckoh } from 'resources/images/eckoh-logo.svg';

interface Props {
  testId: string;
  label: string;
  selectedPaymentOption: string | null;
  invoice: ZuoraTypes.Invoice | null;
  isCenterMembershipJourney?: boolean;
  isWireCheckPayment?: boolean;
  isBankTransferCheque?: boolean;
  isOfflinePayment?: boolean;
  isEckohPayment?: boolean;
  isPaypalPayment?: boolean;

  handlePaymentSelectionChange: (paymentOption: string) => void;
}

export const PaymentOptions: React.FC<Props> = ({
  testId,
  label,
  selectedPaymentOption,
  invoice,
  isCenterMembershipJourney,
  handlePaymentSelectionChange,
  isWireCheckPayment,
  isBankTransferCheque,
  isOfflinePayment,
  isEckohPayment,
  isPaypalPayment,
}) => {
  const isSelected: boolean = label === selectedPaymentOption;
  const isCheckPaymentOption: boolean = selectedPaymentOption === Invoices.PaymentOptions.CHECK;
  const isCheckBankTransferPayment: boolean = selectedPaymentOption === Invoices.PaymentOptions.BANK_TRANSFER;
  const isOptionWithText =
    !isCenterMembershipJourney ||
    isWireCheckPayment ||
    isBankTransferCheque ||
    isOfflinePayment ||
    isEckohPayment ||
    isPaypalPayment;

  const handleSelection = React.useCallback(() => {
    handlePaymentSelectionChange(label);
  }, [label, handlePaymentSelectionChange]);

  const renderLabel = () => {
    switch (label) {
      case Invoices.PaymentOptions.PAYPAL:
        return (
          <StyledLabel isSelectedLabel={isSelected}>
            <StyledPaypalLogo />
          </StyledLabel>
        );
      case Invoices.PaymentOptions.ECKOH:
        return (
          <StyledLabel isSelectedLabel={isSelected}>
            Pay with <StyledEckohLogo />
          </StyledLabel>
        );
      case Invoices.PaymentOptions.ACH:
        return <StyledLabel isSelectedLabel={isSelected}>eCheck</StyledLabel>;
      default:
        return <StyledLabel isSelectedLabel={isSelected}>{label}</StyledLabel>;
    }
  };
  return (
    <>
      <StyledDiv onClick={handleSelection}>
        <StyledRadioButton testId={testId} checked={isSelected} />
        {renderLabel()}
      </StyledDiv>

      <>
        {isOptionWithText ? (
          <PaymentOptionsText
            isCheckPaymentOption={isCheckPaymentOption}
            isSelected={isSelected}
            invoice={invoice}
            isWireCheckPayment={isWireCheckPayment}
            isBankTransferCheque={isBankTransferCheque}
            isOfflinePayment={isOfflinePayment}
            isEckohPayment={isEckohPayment}
            isPaypalPayment={isPaypalPayment}
            isCheckBankTransferPayment={isCheckBankTransferPayment}
          />
        ) : null}
      </>
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  padding: ${props => `${props.theme.pxToRem(16)} 0 `};
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: ${props => props.theme.pxToRem(6)};
`;

const StyledLabel = styled.div<{ isSelectedLabel: boolean }>`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  display: flex;
  align-items: center;
  ${props => props.isSelectedLabel && `font-size: ${props.theme.fontSizes.m}`};
  ${props => props.isSelectedLabel && `font-weight: ${props.theme.fontWeights.medium}`};
`;

const StyledPaypalLogo = styled(Paypal)`
  width: ${props => props.theme.pxToRem(70)};
  height: ${props => props.theme.pxToRem(24)};
`;

const StyledEckohLogo = styled(Eckoh)`
  width: ${props => props.theme.pxToRem(70)};
  height: ${props => props.theme.pxToRem(24)};
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;
