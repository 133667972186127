import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { fetchProfessionalQualification, fetchEducationQualification, fetchWorkExperience } from './action';
import { isServer } from 'utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchProfessionalQualification]: {
    next: (state: State.ExemptionReference, action: any): State.ExemptionReference => ({
      ...state,
      exempReference: action.payload.getExemption,
      exemptReferenceFetched: true,
      exemptReferenceFrontloadRequest: isServer,
    }),
  },

  [fetchEducationQualification]: {
    next: (state: State.ExemptionReference, action: any): State.ExemptionReference => ({
      ...state,
      exempReference: action.payload.getExemption,
      exemptReferenceFetched: true,
      exemptReferenceFrontloadRequest: isServer,
    }),
  },

  [fetchWorkExperience]: {
    next: (state: State.ExemptionReference, action: any): State.ExemptionReference => ({
      ...state,
      exempReference: action.payload.getExemption,
      exemptReferenceFetched: true,
      exemptReferenceFrontloadRequest: isServer,
      workExperienceDataFetched: true,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
