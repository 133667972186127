import React from 'react';
import { RouteComponentProps } from 'react-router';
import { NoSearchResults } from 'components/admin/organisms/NoSearchResults';

export const AdminNoLookUpResult: React.FC<RouteComponentProps> = ({ location, history }) => {
  const { query } = location.state as any;

  const previousPage = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <NoSearchResults
      backLinkText="Back to search"
      lookUpQuery={query}
      tipsList={[
        'Check the spelling of your keyword or account number',
        'Try searching using another keyword or account number',
      ]}
      previousPage={previousPage}
    />
  );
};
