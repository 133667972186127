import React, { RefObject, useRef } from 'react';
import styled from 'styled-components';
import { Button, Category, ButtonEnums, Divider, AnchorMarkdownRenderer } from 'components/atoms';
import { DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { ContentRichText } from 'components/organisms/ContentRichText';
import { VergicCalendar } from 'components/organisms/VergicCalendar/VergicCalendar';
import { DownloadItem } from 'components/molecules/DownloadItem/DownloadItem';
import { SocialSharing } from 'components/molecules/SocialSharing/SocialSharing';
import { MomentHelpers } from 'utils/MomentHelpers';
import { Bookmark, Bookmarked, Copy, DownloadArrow } from 'components/atoms/svg';
import { ExclusiveTag } from './ExclusiveTag';
import { VideoPlayer } from 'components/molecules/VideoPlayer/VideoPlayer';
import { ContentTypes } from 'constants/index';
import { ReactMarkdownStyled } from 'components/pages/PageProduct/ProductParagraphs';
import { isJSON } from 'utils/StringHelpers';
import { Content } from 'mxp-schemas';
import { BlockRenderer } from '../BlockRenderer/BlockRenderer';
import { isMobileViewPort, isServer } from 'utils';
import { ContentfulHelpers } from 'mxp-utils';
import { StyledAdvancedTable } from 'components/pages/PageToolkit/RenderToolkitPageBlock';
import { PaywallNotificationContainer } from 'components/App/lazy-imports';

interface Props {
  content: State.ContentItem;
  hideContentDetails?: boolean;
  isContentSaved: boolean;
  toggleSavedItem: (e: React.SyntheticEvent<HTMLElement>) => void;
  toggleCopy: () => void;
  topButtonsRef: RefObject<HTMLDivElement>;
  navigate: (path: string) => void;
  isLocked?: boolean;
  isContentExclusive: Common.ContentExclusive;
  isAuth: boolean | null;
  topicSlug?: string;
  subtopicSlug?: string;
  topicName?: string;
  isUserOnBoarded: boolean;
}

export const ContentHolder: React.FC<Props> = ({
  content,
  hideContentDetails = false,
  isContentSaved,
  toggleSavedItem,
  toggleCopy,
  topButtonsRef,
  navigate,
  isLocked = false,
  isContentExclusive,
  isAuth,
  topicSlug,
  subtopicSlug,
  topicName,
  isUserOnBoarded,
}) => {
  const isProduct: boolean = content.contentType.slug === ContentTypes.PRODUCT;
  const isStaticArticle = content.contentfulType === 'staticArticle';
  const contentfulType = content.contentfulType;
  const downloadSectionRef = useRef(null);

  const scrollToDownloadSection = (ref: any) => {
    if (isServer) return;
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const downloadHandler = () => {
    scrollToDownloadSection(downloadSectionRef);
  };

  const getAuthors = React.useCallback(() => {
    if (!content.contentAuthor) return '';
    return (
      <>
        {content.contentAuthor.map((item: { name: string; credentials: string; jobTitle: string[] }, id: number) => (
          <ContentAuthor tabIndex={0} key={id} data-testid="content-author">
            {`${item.name || ''} ${item.credentials || ''} ${item.jobTitle ? item.jobTitle.join(', ') : ''}`}
          </ContentAuthor>
        ))}
      </>
    );
  }, [content.contentAuthor]);

  const getContentDetails = () => {
    const hasDateCreated: boolean = content?.dateCreated.length > 0;
    const agoTime = hasDateCreated ? MomentHelpers.getAgoTime(content?.dateCreated) : 'Date unknown';
    const isTimeMoreThanOrEqualEightHrs: boolean =
      hasDateCreated && MomentHelpers.getDiff(new Date().toISOString(), content?.dateCreated, 'hours') >= 8;

    const publishedDate = isTimeMoreThanOrEqualEightHrs ? MomentHelpers.getDate(content?.dateCreated, false) : agoTime;

    const consumptionValue = MomentHelpers.getTimeToConsumeOrSize({
      time: content?.timeToConsume,
      category: content?.contentfulType,
      downloads: content?.downloads,
    });

    return (
      <ContentDetails tabIndex={0} data-testid="content-details">
        {`${publishedDate} · ${consumptionValue} ${
          content?.contentSource === Content.BlogContentSource ? `· ${content?.contentSource}` : ''
        }`}{' '}
      </ContentDetails>
    );
  };

  const getAuthorParagraph = React.useCallback(() => {
    if (!content.contentAuthor) return '';
    const isBioPopulated = content.contentAuthor.some(author => author.authorBio !== null);
    if (isBioPopulated) {
      return (
        <>
          <Divider />
          {content.contentAuthor.map(author => (
            <>
              <Wrap data-testid="content-author-info">
                <h3 tabIndex={0}>
                  {author.name}
                  {author.credentials ? `, ${author.credentials}` : ''}
                </h3>
                {author.authorBio && <ReactMarkdownStyled children={author.authorBio} />}
              </Wrap>
            </>
          ))}
        </>
      );
    }
  }, [content.contentAuthor]);

  const pageCopy = content.pageCopy && content.pageCopy.split(/<blockrenderer>|<videoblock>|<advancedTableBlock >/);
  const renderAdvancedTable = (item: any) => {
    if (item.indexOf('<subAdvancedTableBlock>') > -1) {
      let modifiedMarkdown = item.replace(/<br\s*\\?>/g, '\n');
      modifiedMarkdown = modifiedMarkdown.replace(/<subAdvancedTableBlock>/g, '');
      return modifiedMarkdown;
    }
    return item;
  };

  const checkNextItemForAccordion = React.useCallback(
    (idx: number) => {
      const nextId = idx + 1;
      const item = pageCopy && pageCopy[nextId];
      const isToolkitPageBlock =
        item &&
        (item as any).contentType &&
        (item as any).contentType === ContentfulHelpers.CONTENT_TYPES.TOOLKIT_PAGE_BLOCK;
      if (!isToolkitPageBlock) {
        return false;
      }
      return (item as State.ToolkitPageBlock).accordion;
    },
    [pageCopy]
  );

  return (
    <StyledContentHolder data-testid="styled-content-holder">
      <FadeOutWrapper>
        {!isLocked && !hideContentDetails && content.video && (
          <VideoPlayer video={content.video} testId="video-player" />
        )}
        {!hideContentDetails && content.contentCategory && (
          <Category
            categorySlug={content.contentCategory.slug}
            categoryName={content.contentCategory.name}
            isProduct={isProduct}
          />
        )}
        {content.title && (
          <ContentTitle tabIndex={0} data-testid="content-title" id="contentTitle">
            {content.title}
          </ContentTitle>
        )}
        {!hideContentDetails && (
          <>
            {getAuthors()}
            {getContentDetails()}

            <ExclusiveTag isContentExclusive={isContentExclusive} />

            <ButtonWrapper ref={topButtonsRef}>
              <StyledCopySaveBox
                aria-label={`Copy ${content.title}`}
                testId="content-top-copy-button"
                onClick={toggleCopy}
                variant={ButtonEnums.variants.iconWithCircle}
                tabIndex={0}
              >
                <OffScreenSpan>{content.title}</OffScreenSpan>
                <Copy />
              </StyledCopySaveBox>
              <StyledCopySaveBox
                aria-label={`Bookmark ${content.title}`}
                testId="content-top-save-button"
                active={isContentSaved}
                onClick={toggleSavedItem}
                variant={ButtonEnums.variants.iconWithCircle}
                tabIndex={0}
              >
                <OffScreenSpan>{content.title}</OffScreenSpan>
                {isContentSaved ? <Bookmarked /> : <Bookmark />}
              </StyledCopySaveBox>
              <SocialSharing title={content.title} />
            </ButtonWrapper>
          </>
        )}
        {content.subtitleText && (
          <StyledContentRichText
            testid="content-subtitleText"
            source={content.subtitleText}
            isSubtitle
            navigate={navigate}
          />
        )}
        <Divider />
        {content.downloads && (
          <StyledContainer>
            <StyledDownloadButton
              aria-label={`Download ${content.title}`}
              testId="content-download-button"
              variant={ButtonEnums.variants.standAloneLink}
            >
              <DownloadArrow />
            </StyledDownloadButton>
            <StyledLabel>
              Resource{' '}
              <StyledButtonLink>
                <Button
                  aria-label={`Download ${content.title}`}
                  testId="content-anchor-button"
                  onClick={downloadHandler}
                  variant={ButtonEnums.variants.link}
                >
                  download
                </Button>
              </StyledButtonLink>
              available
            </StyledLabel>
          </StyledContainer>
        )}
        {!isLocked && !hideContentDetails && content.audio && (
          <Podcast data-testid="podcast-player" dangerouslySetInnerHTML={{ __html: content.audio }} />
        )}
        {content.standFirst && (
          <StyledContentRichText
            testid="content-standfirst"
            source={content.standFirst}
            standFirst
            navigate={navigate}
          />
        )}
        {content?.isAppointmentCalendarPage && <VergicCalendar />}
        {!content?.isAppointmentCalendarPage &&
          pageCopy &&
          pageCopy.map((item: string, index: number) =>
            !isJSON(item) ? (
              item.indexOf('<subAdvancedTableBlock>') > -1 ? (
                <StyledAdvancedTable
                  className="ui striped table"
                  children={renderAdvancedTable(item)}
                  renderers={{
                    link: AnchorMarkdownRenderer,
                  }}
                  key={item + index}
                />
              ) : (
                <StyledContentRichText
                  key={item + index}
                  testid="content-page-copy"
                  source={item}
                  navigate={navigate}
                />
              )
            ) : JSON.parse(item).brightcove ? (
              <VideoPlayer key={item + index} video={JSON.parse(item)} testId="video-player-page-copy" />
            ) : JSON.parse(item)[0] ? (
              getBlock(
                item,
                isAuth,
                isUserOnBoarded,
                index,
                pageCopy.length,
                topicSlug,
                subtopicSlug,
                topicName,
                checkNextItemForAccordion(index),
                contentfulType
              )
            ) : null
          )}
        {((!isLocked && !hideContentDetails) || isStaticArticle) && content.videoAddOn && (
          <VideoPlayer video={content.videoAddOn} testId="video-player-add-on" />
        )}

        {isLocked && <FadeOut />}
      </FadeOutWrapper>
      <div ref={downloadSectionRef}>
        {!hideContentDetails &&
          content.downloads &&
          content.downloads.map(item => (
            <DownloadItem
              isLocked={isLocked}
              key={item.fileLink}
              name={item.name}
              fileNames={item.fileNames}
              buttonText={item.buttonText}
              fileSize={item.fileSize}
              fileLink={item.fileLink}
            />
          ))}
      </div>
      {!isLocked && !hideContentDetails && content?.contentAuthor?.length ? getAuthorParagraph() : null}
      <PaywallNotificationContainer isContentExclusive={isContentExclusive} />
    </StyledContentHolder>
  );
};

const getBlock = (
  item: any,
  isAuth: boolean | null,
  isUserOnBoarded: boolean,
  index: number,
  length: number,
  topicSlug?: string,
  subtopicSlug?: string,
  topicName?: string,
  isNextItemAccordion?: boolean | undefined,
  contentfulType?: string
) => {
  const block = JSON.parse(item)[0];
  const isMobile: boolean = isMobileViewPort();
  const isArticlePage: boolean = contentfulType === 'staticArticle' || contentfulType === 'article';
  const contentType = block.contentType;
  const showDivider: boolean = index !== length - 1 ? !isNextItemAccordion : false;

  switch (contentType) {
    case ContentfulHelpers.CONTENT_TYPES.PROMOTIONAL_COMPONENT_BLOCK:
    case ContentfulHelpers.CONTENT_TYPES.FEATURED_CONTENT_BLOCK:
    case ContentfulHelpers.CONTENT_TYPES.TOOLKIT_PAGE_BLOCK:
      const toolkitPageBlock = {
        block,
        idx: index,
        isFullWidth: contentType === ContentfulHelpers.CONTENT_TYPES.PROMOTIONAL_COMPONENT_BLOCK,
        showDivider,
        topicSlug: topicSlug || '',
        topicName: topicName || '',
        subtopicSlug: subtopicSlug || '',
        key: 'main-promotional-section',
      };
      return <BlockRenderer blockRenderToolkit={toolkitPageBlock} />;
    default:
      const landingPageBlock = {
        block,
        isMobile,
        isAuth,
        isFullPagePagination: false,
        isUserOnBoarded,
        isArticlePage,
      };
      return (
        <React.Fragment key={(block as any)?.id}>
          <BlockRenderer blockRenderLandingPage={landingPageBlock} />
          {showDivider && <StyledDividerLandingPageBlock />}
        </React.Fragment>
      );
  }
};

const OffScreenSpan = styled.span`
  border: 0 !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`;

const StyledDividerLandingPageBlock = styled(Divider)`
  width: 100%;
  &&& {
    margin: ${props => `${props.theme.pxToRem(40)} 0 0 0`};
  }
`;

const FadeOutWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const FadeOut = styled.div`
  position: absolute;
  bottom: 1.4rem;
  left: 0;
  width: 100%;
  height: ${props => props.theme.pxToRem(80)};
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 100)
  ); /* needed for fix in mobile */
`;

const StyledContentHolder = styled.div`
  ${props => `
    font-weight: ${props.theme.fontWeights.light};
    color: ${props.theme.colors.neutralBlack};
    a {
      font-weight: ${props.theme.fontWeights.regular};
    }
  `}
`;

const StyledContentRichText = styled(ContentRichText)`
  ${props => `
    p {
      margin: 0 0 ${props.theme.pxToRem(20)};
      line-height: ${props.theme.fontSizes.xl};
      font-size: ${props.theme.fontSizes.m};
    }
  `}

  a {
    ${DefaultInlineLinkStyles};
  }
`;

const Podcast = styled.div`
  margin: 0 0 ${props => props.theme.pxToRem(30)};
  iframe {
    width: 100%;
    height: ${props => props.theme.pxToRem(90)};
    border: none;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 0 0 ${props => props.theme.pxToRem(28)};
  }
`;

const ContentTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes.xxl};
  line-height: 1.35;
  margin: 0 0 ${props => props.theme.pxToRem(8)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.regular};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xl};
    line-height: 1.375;
  }
`;

const ContentAuthor = styled.strong`
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.33;
  display: block;
  margin: 0 0 ${props => props.theme.pxToRem(6)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const ContentDetails = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.57;
  display: block;
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.neutralGrey4};
`;

const ButtonWrapper = styled.div`
  display: flex;
  button:not(:last-child) {
    margin-right: ${props => props.theme.pxToRem(20)};
  }
`;

const Wrap = styled.div`
  margin-top: ${props => props.theme.pxToRem(16)};
`;

const StyledCopySaveBox = styled(Button)`
  &&&& {
    ${props => props.theme.mediaQueries.computerMin} {
      &&:focus {
        border-color: transparent;
        background: ${props => props.theme.colors.primaryLightPurple};
        outline: none;
        path {
          fill: ${props => props.theme.colors.neutralWhite};
        }
      }
    }
  }
`;

const StyledContainer = styled.div`
  &&&& {
    display: flex;
    margin: ${props => props.theme.pxToRem(25)} 0;
  }
`;

const StyledDownloadButton = styled(Button)`
  &&&& {
    cursor: default;
  }
`;

const StyledLabel = styled.p`
  display: flex;
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8} !important;
  line-height: 2;
  margin-left: ${props => props.theme.pxToRem(6)};
`;

const StyledButtonLink = styled.aside`
  margin: 0 ${props => props.theme.pxToRem(4)};
`;
