import { Cart, CommerceTypes, Product, Orders, MembershipTypes } from 'mxp-schemas';
import { Access, Cart as CartUtils, User as UserUtils } from 'mxp-utils';
import {
  formatPrice,
  fromCentsFormat,
  toCentsFormat,
  arrayIncludes,
  emptyArray,
  hasTruthyValue,
  areAllTruthy,
} from 'utils';
import { getMomentDateTimeInfo } from 'utils/MomentHelpers';
import { dateRangeBuilder } from 'utils/productItemHelpers';
import { getLocalStorageItem, LOCAL_STORAGE, removeLocalStorageItem, setLocalStorageItem } from 'utils/localStorage';
import { StorageNames } from 'constants/index';
import { AnonCartInfo } from './actions';
import moment from 'moment-timezone';

// FixMe. transformation logic must be moved to the server
export const transformLineItemsToCartDetailItems = (
  lineItems: State.LineItem[],
  discountCodes?: CommerceTypes.DiscountCodeInfo[] | null,
  membershipProratedAmount?: number,
  proratedPricesAmount?: Orders.ZuoraInvoiceItem[]
): Common.ProductItemData[] => {
  return lineItems.map((item: State.LineItem) => {
    const isMembership = item.productType === Product.ProductType.MEMBERSHIP;
    const isFLP = item.productType === Product.ProductType.FLP;
    const isCenterMembership = item.productType === Product.ProductType.CENTER_MEMBERSHIP;
    const isCredentialOrSection =
      item.productType === Product.ProductType.CREDENTIAL || item.productType === Product.ProductType.SECTION;
    const isWebcastOrConference = arrayIncludes(
      [Product.ProductType.WEBCAST, Product.ProductType.CONFERENCE],
      item.productType
    );

    let proratedPriceCredentialSection: number | null = null;
    if (isCredentialOrSection) {
      proratedPriceCredentialSection =
        proratedPricesAmount?.find(prices => prices.chargeDescription === item.name)?.amountWithoutTax || null;
    }
    const isMipCredential = item?.variant?.attributes?.credentialKey?.key === MembershipTypes.CredentialKeys.MIP;

    if (isMipCredential) {
      proratedPriceCredentialSection = item.totalPrice.centAmount / 100;
    }
    let membershipProductProratedAmount: number =
      proratedPriceCredentialSection || membershipProratedAmount || item.totalContractedValue || 0;
    const mergedPromos = getMergedPromos(item, discountCodes);
    if (mergedPromos.length) {
      mergedPromos.forEach(promo => {
        if (promo.discountAmount.centAmount !== 0) {
          let proratedDiscountAmount;
          if (isMembership || isCredentialOrSection || isCenterMembership) {
            const promoDiscountPercentage =
              ((promo.discountAmount.centAmount * -1) / (item.basePrice.value.centAmount / 100)) * 100;
            // only do this transform in order confirmation since we only have totalContractedValue in that case.
            // NOTE: totalContractedValue in this case only sum the chargeAmount in our invoice without the discountAmount.
            membershipProductProratedAmount = UserUtils.conditionalFunction(
              areAllTruthy(
                hasTruthyValue(isMembership, isCenterMembership),
                !proratedPriceCredentialSection,
                !membershipProratedAmount
              ),
              membershipProductProratedAmount,
              membershipProductProratedAmount + (promo.discountAmount.centAmount * -1) / 100
            );
            proratedDiscountAmount = UserUtils.conditionalFunction(
              areAllTruthy(
                hasTruthyValue(isMembership, isCenterMembership),
                !proratedPriceCredentialSection,
                !membershipProratedAmount
              ),
              Number((membershipProductProratedAmount || 0) * (promoDiscountPercentage / 100) * -1),
              promo.discountAmount.centAmount
            );
            promo.discountAmount = {
              ...promo.discountAmount,
              centAmount: proratedDiscountAmount || 0,
            };
          }
        }
      });
    }
    const appliedPromoCode: string =
      mergedPromos && mergedPromos.length && mergedPromos[0].label ? mergedPromos[0].label : '';

    const availability = Access.extractAttribute(Access.PRODUCT_ATTRIBUTE_AVAILABILITY, item) as number;

    const autoRenew = item.productType === Product.ProductType.SUBSCRIPTION;

    const location = (item.variant && item.variant.attributes && item.variant.attributes.locations) || '';

    const guid = item?.variant?.attributes?.GUID?.trim();

    const vitalSourceCode = item?.variant?.attributes?.vitalSourceCode?.trim();

    const domainString = item?.variant?.attributes?.domainString?.trim();

    const accessStartDate = Access.calculateStartDate(Access.AccessRetrievalMethod.CalculateOnlyIfDoesNotExist, item);
    const accessEndDate = Access.calculateEndDate(
      Access.AccessRetrievalMethod.CalculateOnlyIfDoesNotExist,
      accessStartDate,
      item
    );

    const sourceSystem = item?.sourceSystem;

    const conferenceAccessLink = item?.variant?.attributes?.conferenceAccessLink?.trim();
    const webcastAccessLink = item?.variant?.attributes?.WebcastAccessLink?.trim();

    let formattedFullProductPrice = '';
    if (item.fullProductPrice?.value?.centAmount) {
      formattedFullProductPrice = formatPrice(
        fromCentsFormat(item.fullProductPrice.value.centAmount),
        item.fullProductPrice.value.currencyCode
      );
    }

    const currency = item?.totalPrice?.currencyCode || '';

    let membershipProrated;

    if (isMembership || isCredentialOrSection || isCenterMembership || isFLP) {
      let totalProratedDiscount = mergedPromos?.reduce((sum: number, discount: Cart.DiscountDetails) => {
        const discountAmount = (discount.discountAmount?.centAmount || 0) as unknown as number;
        return sum + discountAmount;
      }, 0);

      let discountPercentage = 0;
      let membershipProductBaseAmount = 0;
      if (totalProratedDiscount) {
        totalProratedDiscount = totalProratedDiscount / 100;
        discountPercentage =
          isMembership || isCredentialOrSection || isCenterMembership || isFLP
            ? ((totalProratedDiscount * -1) / membershipProductProratedAmount) * 100
            : ((totalProratedDiscount * -1) / (item.basePrice.value.centAmount / 100)) * 100;
        totalProratedDiscount = membershipProductProratedAmount * (discountPercentage / 100);
        membershipProductBaseAmount = membershipProductProratedAmount;
        membershipProductProratedAmount = membershipProductProratedAmount - totalProratedDiscount;
      }
      if (Boolean(membershipProductProratedAmount)) {
        membershipProrated = {
          formattedProratedPrice: formatPrice(membershipProductProratedAmount, currency),
          formattedPrice: formatPrice(membershipProductProratedAmount, currency),
          proratedPrice: {
            type: 'centPrecision',
            currencyCode: currency,
            centAmount: Math.round(toCentsFormat(parseFloat(membershipProductProratedAmount.toFixed(2)))),
            fractionDigits: 2,
          },
          formattedBasePrice: formatPrice(membershipProductBaseAmount || membershipProductProratedAmount, currency),
          basePrice: {
            ...item.basePrice,
            centAmount: Math.round(
              toCentsFormat(parseFloat((membershipProductBaseAmount || membershipProductProratedAmount).toFixed(2)))
            ),
          },
        };
      }
    }
    return {
      coverSrc: item.variant && item.variant.images.length && item.variant.images[0].url,
      title: item.name,
      productId: item.productId,
      subtitle: null, // first/main category
      productType: item.productType,
      availableFormat:
        item.variant && item.variant.attributes && item.variant.attributes.productType
          ? item.variant.attributes.productType
          : null,
      subscriptionProductType:
        item.variant && item.variant.attributes && item.variant.attributes.subscriptionProductType
          ? item.variant.attributes.subscriptionProductType
          : null,
      formattedBasePrice: formatPrice(
        fromCentsFormat(item.basePrice.value.centAmount),
        item.basePrice.value.currencyCode
      ),
      formattedPrice: formatPrice(
        isMembership || isCredentialOrSection || isCenterMembership || isFLP
          ? item.totalContractedValue || fromCentsFormat(getOnePiecePriceCentAmount(item))
          : fromCentsFormat(getOnePiecePriceCentAmount(item)),
        item.totalPrice.currencyCode
      ),
      formattedFullProductPrice,
      appliedPromoCode,
      sku: item.variant && item.variant.sku,
      lineItemId: item.id,
      availability,
      autoRenew,
      autoRenewEnabled: item.autoRenewEnabled || null,
      orderDate: item.orderDate as string,
      orderId: item.orderId as string,
      subscriptionNumber: item.subscriptionNumber,
      subscriptionStartDate: item.subscriptionStartDate,
      isAutoRenewable: item.isAutoRenewable,
      isOnlyProductOnSubscription: item.isOnlyProductOnSubscription,
      subscriptionStatus: item.subscriptionStatus,
      ratePlanId: item.ratePlanId,
      orderNumber: item.orderNumber,
      productLink: item.productSlug,
      dateRange: isWebcastOrConference ? getDateTimeInfo(item) : null,
      location,
      guid,
      vitalSourceCode,
      domainString,
      accessStartDate: accessStartDate.toISOString(),
      accessEndDate: accessEndDate.toISOString(),
      productChildInfo: item?.variant?.productChildInfo || [],
      isPathwayProduct: Boolean(item?.variant?.attributes?.isPathwayProduct),
      contentRoleId: item?.variant?.attributes?.contentRoleId?.key,
      showQuantity: item?.variant?.attributes?.showQuantity,
      availableQuantity: item?.availableQuantity,
      quantity: item?.quantity,
      standingOrderEligible: item?.variant?.attributes?.standingOrderEligible,
      standingOrderDiscount: item?.variant?.attributes?.standingOrderDiscount,
      custom: item?.custom as any,
      optionalText: item?.variant?.attributes?.optionalText,
      cartLineItemState: item.state[0].state.obj?.key as Orders.LineItemStates,
      trackingId: item.trackingId,
      carrierName: item.carrierName,
      standingOrderStatus: item?.standingOrderStatus || null,
      publicationYear: item?.variant?.attributes?.publicationYear,
      isPhysicalProduct: item.isPhysicalProduct,
      shipmentDate: item.shipmentDate,
      bundleId: item.bundleId,
      bundleName: item.bundleName,
      bundleSlug: item.bundleSlug,
      masterVariantSKU: item.masterVariantSKU,
      bundleDiscountPercentage: item.bundleDiscountPercentage,
      conferenceAccessLink,
      webcastAccessLink,
      sourceSystem,
      renewalPrice: item.renewalPrice,
      shouldRemindRenewal: item.shouldRemindRenewal,
      fundName: item?.variant?.attributes?.fundName,
      attestationCartText: item?.variant?.attributes?.attestationCartText,
      attestationRequired: item?.variant?.attributes?.attestationRequired?.key,
      zuoraTermStartDate: item.zuoraTermStartDate,
      zuoraTermEndDate: item.zuoraTermEndDate,
      zuoraProductId: item.ratePlanProductId,
      isInGracePeriod: item.isInGracePeriod,
      isFreeTrial: CartUtils.isLineItemFreeTrial(item),
      freeTrialTerm: item.freeTrialTerm,
      previousSubscriptionType: item.previousSubscriptionType,
      cmsProductData: item.cmsProductData,
      ...membershipProrated,
      membershipBody: item?.variant?.attributes?.membershipBody?.key,
      mainProductPublished: item.mainProductPublished,
      variantProductPublished: item.variantProductPublished,
      otherVariants: item?.otherVariants || [],
      flpTerm: item?.variant?.attributes?.flpTerm?.label,
      orderConfirmationAddress: item.orderConfirmationAddress,
      lastExtendedOn: item.lastExtendedOn,
      isExtended: item.isExtended,
    };
  });
};

export const getMergedPromos = (
  item: State.LineItem,
  discountCodes?: CommerceTypes.DiscountCodeInfo[] | null,
  locale = 'en-US'
): Cart.DiscountDetails[] => {
  const currency = item?.totalPrice?.currencyCode;

  return item.discountedPricePerQuantity.reduce((acc, discount) => {
    discount.discountedPrice.includedDiscounts.forEach(includedDiscount => {
      const deltaPromoSum: number = -1 * includedDiscount.discountedAmount.centAmount * item.quantity;
      const discountAmount = { ...includedDiscount.discountedAmount };
      const itemCodeDiscountId = includedDiscount.discount.obj && includedDiscount.discount.id;

      // get promo code label for display
      const matchingPromoCodes =
        discountCodes?.length &&
        discountCodes.filter(discountCode => {
          const ids = discountCode.discountCode?.obj?.cartDiscounts?.map(cartDiscount => cartDiscount.id);
          return ids && ids.length && ids.some(id => id === itemCodeDiscountId);
        });

      const promoCodeLabel: string =
        (matchingPromoCodes && matchingPromoCodes.length && matchingPromoCodes[0]?.discountCode?.obj?.code) || '';
      const includedDiscountInCart: any = includedDiscount.discount.obj;

      const iStandingOrderDiscount: boolean = Boolean(
        includedDiscountInCart?.lineItemFieldTypes?.standing_order_discount &&
          (item?.custom?.fields?.standing_order_discount as any) === Product.StandingOrderEligible.STANDING_NEW
      );

      const autoAppliedDiscountLabel: string = iStandingOrderDiscount
        ? 'Standing Order'
        : includedDiscount.discount.obj?.name[locale] || '';

      const label: string = promoCodeLabel || autoAppliedDiscountLabel;
      const discountType = promoCodeLabel
        ? Cart.DISCOUNT_TYPE.CART_PROMO_DISCOUNT
        : Cart.DISCOUNT_TYPE.AUTO_APPLIED_DISCOUNT;

      acc.push({
        label,
        formattedDiscountAmount: formatPrice(fromCentsFormat(deltaPromoSum), currency),
        discountAmount: { ...discountAmount, centAmount: deltaPromoSum },
        discountType,
      });
    });
    return acc;
  }, [] as Cart.DiscountDetails[]);
};

const getDateTimeInfo = (item: State.LineItem) => {
  const availableFormat = item?.variant?.attributes?.productType?.key || null;
  const isMultidayWebcast = availableFormat === Product.AvailableFormat.MULTI_DAY;
  const isSeriesWebcast = availableFormat === Product.AvailableFormat.SERIES;
  const productChildInfo = item?.variant?.productChildInfo || [];
  const earliestVariant = productChildInfo?.[0];

  if (isMultidayWebcast && productChildInfo.length) {
    return dateRangeBuilder(
      getMomentDateTimeInfo(earliestVariant?.sku, earliestVariant?.startDateTime, earliestVariant?.endDateTime),
      item.productType,
      availableFormat,
      productChildInfo?.length
    );
  }
  if (isSeriesWebcast && productChildInfo.length) {
    return dateRangeBuilder(
      getMomentDateTimeInfo(earliestVariant?.sku, earliestVariant?.startDateTime, earliestVariant?.endDateTime),
      item.productType,
      availableFormat,
      earliestVariant?.productChildCount
    );
  }

  const start = item?.variant?.attributes?.startDateTime;
  const end = item?.variant?.attributes?.endDateTime;
  return dateRangeBuilder(
    getMomentDateTimeInfo(item?.variant?.sku ? item.variant.sku : '', start, end),
    item.productType
  );
};

export const transformItemsPriceData = (
  list: Cart.Cart | Product.ProductsListData,
  args: {
    isRenewalSeason?: boolean;
    hasExistingMembership?: boolean;
    membershipProratedAmount?: number;
    proratedPricesAmount?: Orders.ZuoraInvoiceItem[];
    isProductsListData?: boolean;
    isCenterMembershipRenewal?: boolean;
    activeCredentials?: Cart.LineItemWithAccessDates[];
    activeSections?: Cart.LineItemWithAccessDates[];
    isCimaRenewalSeason?: boolean;
    userRoles?: string[];
    membershipRefund?: number;
    isClickedMembershipUpgradeAndisFLPSwitchOrUpgrade?: any;
    isTenYearsCimaRegular?: boolean;
  }
): Cart.CartItemPriceInfo[] => {
  if (!list?.lineItems?.length) return emptyArray;
  const {
    isRenewalSeason,
    hasExistingMembership,
    membershipProratedAmount,
    proratedPricesAmount,
    isProductsListData,
    isCenterMembershipRenewal,
    activeCredentials,
    activeSections,
    isCimaRenewalSeason,
    userRoles,
    membershipRefund,
    isClickedMembershipUpgradeAndisFLPSwitchOrUpgrade,
    isTenYearsCimaRegular,
  } = args;

  const {
    isClickedMembershipUpgrade,
    isFLPSwitchOrUpgrade,
    isL7CandidateUpgrade,
    isFlpToPqSwitch,
    cimaMembershipTermIsTenYears,
  } = UserUtils.conditionalFunction(
    isClickedMembershipUpgradeAndisFLPSwitchOrUpgrade,
    isClickedMembershipUpgradeAndisFLPSwitchOrUpgrade,
    {}
  );

  let lineItems = list?.lineItems;
  let donationsitemPriceInfo;
  let donationsItems;
  const hasMembershipProduct = lineItems?.some(
    (lineItem: Cart.LineItem) => lineItem.productType === Product.ProductType.MEMBERSHIP
  );

  if (hasMembershipProduct) {
    const donationLineItems = lineItems.filter(
      (lineItem: Cart.LineItem) => lineItem.productType === Product.ProductType.CONTRIBUTION
    );

    donationsItems = donationLineItems;

    donationsitemPriceInfo = donationLineItems?.reduce(
      (acc: { donationNames: string[]; totalPrice: number; currency: string }, item: Cart.LineItem) => {
        acc.donationNames.push(item.name);
        return {
          donationNames: acc.donationNames,
          totalPrice: acc.totalPrice + item.totalPrice.centAmount,
          currency: item?.totalPrice?.currencyCode,
        };
      },
      {
        donationNames: [],
        totalPrice: 0,
        currency: '',
      }
    );

    lineItems = lineItems.filter(
      (lineItem: Cart.LineItem) => lineItem.productType !== Product.ProductType.CONTRIBUTION
    );
  }

  const itemPriceInfo = lineItems.map((item: Cart.LineItem): Cart.CartItemPriceInfo => {
    const currency = item?.totalPrice?.currencyCode || '';
    const discounts: Cart.DiscountDetails[] = [];
    let totalDiscount: number = 0;
    const hasBasePriceDesc = Boolean(item?.basePrice);
    const hasMemberPriceDesc = Boolean(item?.membershipPrice);
    const isMembership = item.productType === Product.ProductType.MEMBERSHIP;
    const isCenterMembership = item.productType === Product.ProductType.CENTER_MEMBERSHIP;
    const isCredentialOrSection =
      item.productType === Product.ProductType.CREDENTIAL || item.productType === Product.ProductType.SECTION;
    const isFLP = item.productType === Product.ProductType.FLP;
    const isFcmaCredential = item?.variant?.attributes?.credentialKey?.key === MembershipTypes.CredentialKeys.FCMA;
    const hasExistingFcmaCredential = activeCredentials?.some(
      credential => credential.variant?.attributes?.credentialKey?.key === MembershipTypes.CredentialKeys.FCMA
    );
    const isFcmaCredentialZeroPrice = areAllTruthy(isFcmaCredential, !hasExistingFcmaCredential);
    const isFcmaApplicationFee = item?.productSlug === Product.Fee.FCMA_APPLICATION_FEE;
    const isFcmaRouteOneJourney = isTenYearsCimaRegular ?? cimaMembershipTermIsTenYears;
    const isFcmaApplicationFeeZeroPrice = areAllTruthy(isFcmaApplicationFee, isFcmaRouteOneJourney);
    const isApplicationFeeZeroPrice = item?.productSlug === Product.Fee.CIMA_APPLICATION_FEE && isL7CandidateUpgrade;

    const hasMemberDiscount = areAllTruthy(
      item.basePrice.channelDescription !== item.membershipPrice.channelDescription,
      !isMembership,
      !isCenterMembership,
      !isFcmaCredentialZeroPrice,
      !isFcmaApplicationFeeZeroPrice
    );

    const isContribution = item.productType === Product.ProductType.CONTRIBUTION;

    const hasRefundPrice = Boolean(list.summary?.refundPrice?.centAmount);
    const hasFLPRefundPrice = [isFLP, hasRefundPrice].every((flag: boolean) => flag);

    if (!isContribution) {
      if (
        hasBasePriceDesc &&
        hasMemberPriceDesc &&
        hasMemberDiscount &&
        !isFLPSwitchOrUpgrade &&
        !isApplicationFeeZeroPrice &&
        !hasFLPRefundPrice
      ) {
        const delta = (item.membershipPrice.value.centAmount - item.basePrice.value.centAmount) * item.quantity;
        if (delta !== 0) {
          discounts.push({
            label: item.membershipPrice.hasChannel
              ? item.membershipPrice.channelDescription
              : getMatchingMembershipDiscountChannel(
                  item.variant,
                  item.membershipPrice.value.centAmount,
                  item.membershipPrice.value.currencyCode,
                  userRoles
                ),
            formattedDiscountAmount: formatPrice(fromCentsFormat(delta), currency),
            discountAmount: { ...item.membershipPrice.value, centAmount: delta },
            discountType: Cart.DISCOUNT_TYPE.MEMBERSHIP_DISCOUNT,
          });
          totalDiscount += delta;
        }
      }

      const mergedPromos = getMergedPromos(item, list.discountCodes);
      if (mergedPromos.length) {
        mergedPromos.forEach(promo => {
          if (promo.discountAmount.centAmount !== 0) {
            let proratedTotalDiscount = 0;
            let proratedDiscountAmount;
            if (isCredentialOrSection || isCenterMembership) {
              proratedDiscountAmount =
                proratedPricesAmount?.reduce((sum: number, price: Orders.ZuoraInvoiceItem) => {
                  if (price.processingType === 'Discount' && price.chargeDescription.includes(item.name)) {
                    proratedTotalDiscount += isMipCredential
                      ? item.totalPrice.centAmount / 100
                      : price.amountWithoutTax;
                  }
                  return proratedTotalDiscount;
                }, 0) || 0;
            }

            if (proratedDiscountAmount) {
              proratedDiscountAmount = proratedDiscountAmount * 100;
            }
            if (isMembership || isFLP) {
              const promoDiscountPercentage =
                ((promo.discountAmount.centAmount * -1) / (item.basePrice.value.centAmount / 100)) * 100;
              proratedDiscountAmount = Number(
                (isFLP
                  ? item.basePrice.value.centAmount / 100
                  : membershipProratedAmount || item.totalContractedValue || 0) *
                  (promoDiscountPercentage / 100) *
                  -1
              );
            }
            // tslint:disable-next-line:no-object-literal-type-assertion
            discounts.push({
              label: promo.label,
              formattedDiscountAmount: proratedDiscountAmount
                ? formatPrice(fromCentsFormat(proratedDiscountAmount), currency)
                : promo.formattedDiscountAmount,
              discountAmount:
                proratedDiscountAmount && isProductsListData
                  ? {
                      ...promo.discountAmount,
                      centAmount: proratedDiscountAmount,
                    }
                  : proratedDiscountAmount
                  ? proratedDiscountAmount
                  : promo.discountAmount,
              discountType: promo.discountType,
            } as Cart.DiscountDetails);

            totalDiscount += proratedDiscountAmount ? proratedDiscountAmount : promo.discountAmount.centAmount;
          }
        });
      }
    }
    const hasExistingSection = !!activeSections?.find(section => section.productId === item.productId);
    const hasExistingCredential = !!activeCredentials?.find(credential => credential.productId === item.productId);

    // Selectors for MiP
    // MIP Signup: Proration
    // MIP Renewal: No proration
    const isMipCredential = item?.variant?.attributes?.credentialKey?.key === MembershipTypes.CredentialKeys.MIP;
    const hasExistingMipCredential = !!activeCredentials?.find(
      credential => credential.variant?.attributes?.credentialKey?.key === MembershipTypes.CredentialKeys.MIP
    );
    const isNonMipCredentialOrSection = [isCredentialOrSection, !isMipCredential].every((flag: boolean) => flag);
    const isAicpaMemBody = item.variant?.attributes?.membershipBody?.label === MembershipTypes.MembershipBody.AICPA;
    const isCimaMemBody = item.variant?.attributes?.membershipBody?.label === MembershipTypes.MembershipBody.CIMA;
    const renewalSeasonToUse = isAicpaMemBody ? isRenewalSeason : isCimaRenewalSeason;
    const isCimaCandidateZeroPrice = areAllTruthy(
      isCimaMemBody,
      item?.variant?.attributes?.membershipKey?.key === MembershipTypes.MembershipKeys.CANDIDATE,
      !hasExistingMembership
    );

    const proration = UserUtils.conditionalFunction(
      areAllTruthy(
        hasTruthyValue(
          isMembership,
          isNonMipCredentialOrSection,
          areAllTruthy(isCenterMembership, !isCenterMembershipRenewal),
          isFLP,
          areAllTruthy(isMipCredential, !hasExistingMipCredential)
        ),
        hasTruthyValue(
          !renewalSeasonToUse,
          areAllTruthy(renewalSeasonToUse, isCenterMembership, !isCenterMembershipRenewal),
          // add condition for cima renewal season for CIMA memberships (Membership, Credentials, FLPs)
          areAllTruthy(
            renewalSeasonToUse,
            isMembership,
            hasTruthyValue(
              !hasExistingMembership,
              areAllTruthy(
                hasTruthyValue(isAicpaMemBody, isCimaMemBody),
                hasTruthyValue(isClickedMembershipUpgrade, isFlpToPqSwitch)
              )
            )
          ),
          areAllTruthy(renewalSeasonToUse, isCredentialOrSection, !hasExistingSection, !hasExistingCredential),
          areAllTruthy(renewalSeasonToUse, isFLP)
        )
      ),
      CartUtils.calculateProration(
        UserUtils.conditionalFunction(
          item.variant?.attributes?.membershipMonthStart?.label,
          item.variant?.attributes?.membershipMonthStart?.label,
          moment().format('MMMM')
        ),
        UserUtils.conditionalFunction(
          item.variant?.attributes?.membershipMonthEnd?.label,
          item.variant?.attributes?.membershipMonthEnd?.label,
          moment().add(item.variant?.attributes?.flpTerm?.key, 'M').format('MMMM')
        ),
        'MMM YYYY'
      ),
      null
    );

    // This should match with prices in server
    const basePriceValue = UserUtils.conditionalFunction(
      hasTruthyValue(
        isContribution,
        isMipCredential,
        isFcmaCredentialZeroPrice, // FCMA zero price during signup
        isFcmaApplicationFeeZeroPrice, // FCMA application fee zero price during Route 1 (10 years CIMA Regular Membership) only
        isApplicationFeeZeroPrice, // Application Fee zero price during upgrade from L7 to Regular
        isCimaCandidateZeroPrice // Cima Candidate zero price during signup
      ),
      item.totalPrice.centAmount,
      item.basePrice.value.centAmount
    );

    const totalDiscountValue = isMipCredential ? 0 : totalDiscount;

    let priceInfo: Cart.CartItemPriceInfo = {
      lineItemId: item.id,
      productId: item.productId,
      bundleId: item.bundleId,
      productName: item.name,
      productType: item.productType,
      formattedPrice: formatPrice(fromCentsFormat(getOnePiecePriceCentAmount(item)), currency),
      price: { ...item.totalPrice, centAmount: item.totalPrice.centAmount },
      formattedBasePrice: formatPrice(fromCentsFormat(basePriceValue), currency),
      basePrice: {
        ...item.basePrice.value,
        centAmount: basePriceValue,
      },
      formattedTotalDiscount: isMipCredential ? '' : formatPrice(fromCentsFormat(totalDiscount), currency),
      totalDiscount: { ...item.basePrice.value, centAmount: totalDiscountValue },
      hasDiscounts: !!totalDiscountValue,
      discounts: isMipCredential ? [] : discounts,
      quantity: item?.quantity,
      formattedBasePriceWithQuantity: formatPrice(fromCentsFormat(basePriceValue * item.quantity), currency),
      formattedNetPriceWithQuantity: formatPrice(
        fromCentsFormat(getOnePiecePriceCentAmount(item) * item.quantity),
        currency
      ),
      tierName: item.variant?.attributes?.tierName,
      proration: proration || null,
    };

    if (isMembership || isCredentialOrSection || isCenterMembership || isFLP) {
      let proratedPriceCredentialSection;
      if (isCredentialOrSection) {
        let credSectionPrice = 0;
        proratedPriceCredentialSection =
          proratedPricesAmount?.reduce((sum: number, currentPrice: Orders.ZuoraInvoiceItem) => {
            if (currentPrice.chargeDescription === item.name && currentPrice.processingType === 'Charge') {
              credSectionPrice += currentPrice.amountWithoutTax;
            }
            return credSectionPrice;
          }, 0) || null;
      }
      let totalProratedDiscount = discounts?.reduce((sum: number, discount: Cart.DiscountDetails) => {
        const discountAmount = isProductsListData
          ? (discount.discountAmount.centAmount as unknown as number)
          : (discount.discountAmount as unknown as number);
        return sum + discountAmount;
      }, 0);

      if (totalProratedDiscount) {
        totalProratedDiscount = totalProratedDiscount / 100;
      }
      let membershipProductProratedAmount: number =
        (proratedPriceCredentialSection || membershipProratedAmount || item.totalContractedValue || 0) - 0;
      let discountPercentage = 0;
      let membershipProductBaseAmount = 0;
      let membershipRefundShare = 0;
      const membershipBasePrice = priceInfo.basePrice.centAmount / 100;
      discountPercentage =
        isMembership || isCredentialOrSection || isCenterMembership
          ? ((totalProratedDiscount * -1) / membershipProductProratedAmount) * 100
          : ((totalProratedDiscount * -1) / (priceInfo.basePrice.centAmount / 100)) * 100;
      if (!Boolean(isProductsListData)) {
        // THIS IS TO GET THE EXACT AMOUNT OF YOUR DISCOUNT AFTER REFUND DEDUCTION.
        if (membershipRefund && Number(membershipRefund) > 0 && (isMembership || isFLP)) {
          if (isFLP) {
            membershipProductProratedAmount = membershipBasePrice + totalProratedDiscount - membershipRefund; // get the correct amount after deduction if both have discount and refund at the same time.
          }
          if (isMembership) {
            membershipRefundShare = membershipRefund * (discountPercentage / 100); // this is from checkout selector.
            membershipProductProratedAmount =
              membershipProductProratedAmount + totalProratedDiscount - membershipRefundShare; // get the correct amount after deduction if both have discount and refund at the same time.
          }
        } else {
          if (isFLP) {
            membershipProductProratedAmount = membershipBasePrice + totalProratedDiscount; // totalProratedDiscount is negative
          }
          if (isMembership) {
            membershipProductProratedAmount = membershipProductProratedAmount + totalProratedDiscount; // totalProratedDiscount is negative
          }
        }
      } else {
        const summaryList = list.summary;
        if (totalProratedDiscount) {
          const refundPrice = (summaryList?.refundPrice?.centAmount || 0) / 100 || 0; // this is from productsListData selector.
          totalProratedDiscount = (priceInfo.basePrice.centAmount / 100) * (discountPercentage / 100);
          if (refundPrice && Number(refundPrice) > 0 && (isMembership || isFLP)) {
            if (isFLP) {
              // THIS IS TO GET THE EXACT AMOUNT OF YOUR DISCOUNT AFTER REFUND DEDUCTION.
              membershipProductBaseAmount = membershipBasePrice - refundPrice;
              membershipProductProratedAmount = membershipBasePrice - totalProratedDiscount - refundPrice; //  get the correct amount after deduction if both have discount and refund at the same time.
            }
            if (isMembership) {
              totalProratedDiscount = membershipProductProratedAmount * (discountPercentage / 100);
              membershipRefundShare = refundPrice * (discountPercentage / 100);
              membershipProductBaseAmount = membershipProductProratedAmount - membershipRefundShare;
              membershipProductProratedAmount =
                membershipProductProratedAmount - totalProratedDiscount - membershipRefundShare; //  get the correct amount after deduction if both have discount and refund at the same time.
            }
          } else {
            if (isFLP) {
              membershipProductBaseAmount = membershipBasePrice;
              membershipProductProratedAmount = membershipBasePrice - totalProratedDiscount; // totalProratedDiscount is negative
            }
            if (isMembership) {
              totalProratedDiscount = membershipProductProratedAmount * (discountPercentage / 100);
              membershipProductBaseAmount = membershipProductProratedAmount;
              membershipProductProratedAmount = membershipProductProratedAmount - totalProratedDiscount; // totalProratedDiscount is negative
            }
          }
        }
      }
      // 0 does not mean it does not have prorated amount
      if (Boolean(membershipProductProratedAmount)) {
        priceInfo = {
          ...priceInfo,
          formattedProratedPrice: formatPrice(membershipProductProratedAmount, currency),
          formattedPrice: formatPrice(membershipProductProratedAmount, currency),
          proratedPrice: {
            type: 'centPrecision',
            currencyCode: currency,
            centAmount: Math.round(toCentsFormat(parseFloat(membershipProductProratedAmount.toFixed(2)))),
            fractionDigits: 2,
          },
          formattedBasePrice: formatPrice(membershipProductBaseAmount || membershipProductProratedAmount, currency),
          basePrice: {
            ...priceInfo.basePrice,
            centAmount: Math.round(
              toCentsFormat(parseFloat((membershipProductBaseAmount || membershipProductProratedAmount).toFixed(2)))
            ),
          },
        };
      }
    }

    if ((item.custom?.fields?.purchase_type as any) === Product.FreeTrials.FREE_TRIAL) {
      priceInfo = {
        ...priceInfo,
        hasDiscounts: false,
        formattedBasePrice: priceInfo.formattedPrice,
        basePrice: priceInfo.price,
        formattedTotalDiscount: formatPrice(0, currency),
        totalDiscount: {
          type: 'centPrecision',
          currencyCode: currency,
          centAmount: 0,
          fractionDigits: 2,
        },
        discounts: [],
        formattedBasePriceWithQuantity: priceInfo.formattedNetPriceWithQuantity,
      };
    }

    return priceInfo;
  });

  if (donationsitemPriceInfo?.donationNames?.length) {
    const currency = donationsitemPriceInfo.currency || '';
    itemPriceInfo.push({
      lineItemId: '',
      productName: 'Donations',
      formattedPrice: formatPrice(fromCentsFormat(donationsitemPriceInfo.totalPrice), currency),
      price: {
        type: 'centPrecision',
        currencyCode: currency,
        centAmount: donationsitemPriceInfo.totalPrice,
        fractionDigits: 2,
      },
      formattedBasePrice: formatPrice(fromCentsFormat(donationsitemPriceInfo.totalPrice), currency),
      basePrice: {
        type: 'centPrecision',
        currencyCode: currency,
        centAmount: donationsitemPriceInfo.totalPrice,
        fractionDigits: 2,
      },
      formattedTotalDiscount: formatPrice(0, currency),
      totalDiscount: {
        type: 'centPrecision',
        currencyCode: currency,
        centAmount: 0,
        fractionDigits: 2,
      },
      hasDiscounts: false,
      discounts: [],
      quantity: 1,
      formattedBasePriceWithQuantity: '',
      formattedNetPriceWithQuantity: '',
      donationNames: donationsitemPriceInfo.donationNames,
      donationLineItems: donationsItems,
    });
  }
  return itemPriceInfo;
};

export const storeAnonCartInfo = (cartId: string, cartVersion: number) => {
  setLocalStorageItem({ [StorageNames.anonCartInfo]: { cartId, cartVersion } }, LOCAL_STORAGE.ANON_AICPA_DATA);
};

export const getAnonCartInfo = () =>
  getLocalStorageItem(StorageNames.anonCartInfo, LOCAL_STORAGE.ANON_AICPA_DATA) as AnonCartInfo;

export const removeAnonCartInfo = () => {
  removeLocalStorageItem(StorageNames.anonCartInfo, LOCAL_STORAGE.ANON_AICPA_DATA);
};

export const getOnePiecePriceCentAmount = (item: Cart.LineItem) => item.totalPrice.centAmount / item.quantity;

export const getMatchingLineItems = (
  productItem: Product.ProductItem,
  cart: Cart.Cart,
  selectedVariantSku?: string
): Cart.LineItem[] => {
  const lineItems: Cart.LineItem[] = cart?.lineItems || emptyArray;
  return lineItems.filter((item: Cart.LineItem) => {
    const sameProduct = item.productId === productItem.productId;
    const sameVariant = item.variant ? item.variant.sku === selectedVariantSku : false;
    return sameProduct && sameVariant;
  });
};

export const getHasItemInTheCart = (productItem: Product.ProductItem, cart: Cart.Cart, selectedVariantSku?: string) => {
  return (cart?.lineItems || emptyArray).some(
    item => item.productId === productItem?.productId && item?.variant?.sku === selectedVariantSku
  );
};

export const isFreeTrialInCart = (items: Cart.LineItem[]) => {
  return items.some(isItemFreeTrial);
};

export const isItemFreeTrial = (item: Cart.LineItem) => {
  return item.isFreeTrial === true;
};

export const getMatchingMembershipDiscountChannel = (
  variant: Cart.LineItemVariant | undefined,
  centAmount: number,
  currency = 'USD',
  roles: string[] = []
) => {
  const emptyString = '';
  const matchedPrice = variant?.prices?.find(
    (price: any) =>
      price.value?.centAmount === centAmount &&
      price.value?.currencyCode === currency &&
      roles.some(role => price.channel?.obj?.key.includes(role))
  );

  if (!matchedPrice) {
    return emptyString;
  }

  const discountRole = roles.find(role => matchedPrice.channel?.obj?.key.includes(role)) || emptyString;
  return UserUtils.membershipDiscountMapNamesForCartSummary[discountRole] || emptyString;
};

export const ProductsWithUnPublishedNotificationMessage = (cartItems?: Common.ProductItemData[] | null) => {
  // #get list of main products unpublished
  const mainProductList = getProductName(
    cartItems?.filter(
      (cartItem: any) => !cartItem?.mainProductPublished && cartItem?.mainProductPublished !== undefined
    )
  );
  // #get list of variant products unpublished
  const variantProductList = getProductName(
    cartItems?.filter(
      (cartItem: any) => !cartItem?.variantProductPublished && cartItem?.mainProductPublished !== undefined
    )
  );
  // #get list of bundle that have products unpublished
  const bundleProductList = getBundleName(
    cartItems?.filter((cartItem: any) => cartItem?.bundleId && cartItem?.bundleName)
  );
  if (mainProductList?.length && !Boolean(bundleProductList?.length)) {
    return {
      titleHeader: mainProductList.length !== 1 ? `Products no longer available` : `Product no longer available`,
      item: mainProductList.length !== 1 ? `The following items` : `The following item`,
      products: mainProductList.toString(),
      message:
        mainProductList.length !== 1 ? `are no longer available in the store.` : `is no longer available in the store.`,
      titleMessage:
        mainProductList.length !== 1
          ? ` Please remove them from your cart to proceed to checkout.`
          : ` Please remove it from your cart to proceed to checkout.`,
      isError: true,
    };
  }
  if (variantProductList?.length && !Boolean(bundleProductList?.length)) {
    return {
      titleHeader:
        variantProductList.length !== 1 ? `Products no longer available` : 'Product format no longer available',
      item: variantProductList.length !== 1 ? `These items` : 'This item is no longer available in',
      products: variantProductList.length !== 1 ? variantProductList.toString() : '',
      format:
        variantProductList.length === 1
          ? `'${cartItems?.find((item: any) => item?.variantProductPublished === false)?.availableFormat?.label}'`
          : '',
      message: variantProductList.length !== 1 ? `are no longer available in the selected format.` : 'format.',
      titleMessage:
        variantProductList.length !== 1
          ? `Please select a new format or remove the items from your cart.`
          : 'Please select new different format or remove the item from your cart.',
      isError: true,
    };
  }
  if (
    (bundleProductList?.length && mainProductList?.length) ||
    (bundleProductList?.length && variantProductList?.length)
  ) {
    return {
      titleHeader: bundleProductList.length !== 1 ? `Products no longer available` : `Product no longer available`,
      item: bundleProductList.length !== 1 ? `The following items` : `The following item`,
      products: bundleProductList.toString(),
      message:
        bundleProductList.length !== 1
          ? `are no longer available in the selected format`
          : `is no longer available in the selected format`,
      titleMessage:
        'Due to this change, the bundle discount no longer applies. Please remove the bundle from your cart to proceed to checkout.',
      isError: true,
    };
  }
  return {
    titleHeader: '',
    item: '',
    products: '',
    message: '',
    titleMessage: '',
    isError: false,
  };
};

export const getProductName = (items: any[] | undefined) => {
  return items?.map((item: { title: any }) => ` [${item?.title}]`);
};

export const getBundleName = (items: any[] | undefined) => {
  const bundle = items?.map((item: { bundleName: any }) => ` [${item?.bundleName}]`);
  return [...new Set(bundle)];
};
export const ProductsWithEthicsNotificationMessage = (
  items?: State.ProductsWithEthicStatus[] | undefined,
  isUserSuspendedByEthics?: boolean | undefined,
  isProductHaveIncorrectPrice?: boolean | undefined
) => {
  const productList = items?.map(item => ` [${item.productName}]`);
  const message =
    items?.length !== 1
      ? `remove them from your cart and re-add them`
      : `remove this product from your cart and re-add it`;
  const messageDiscount = items?.length !== 1 ? ` at the discounted price to continue with your purchase.` : `.`;
  if (isUserSuspendedByEthics && isProductHaveIncorrectPrice && items) {
    return `You do not have access to the discounted member price for
    ${productList} because you are suspended due to Ethics reasons. Please ${message} at full price to continue with your purchase. For
    any questions, please contact`;
  }
  if (!isUserSuspendedByEthics && isProductHaveIncorrectPrice && items) {
    return `Your suspension due to ethics reasons has been lifted. To access discounted member prices for
    ${productList} please ${message}${messageDiscount} For any questions, please contact`;
  }
  return `You do not have access to the discounted member price because you are suspended due to Ethics reasons. For
  any questions, please contact`;
};
