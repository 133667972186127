import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { PracticalExperienceRecord } from 'components/molecules';
import { constantsSelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { Link } from 'components/atoms';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { IC_CHECK, IC_CHECK_GREEN } from 'resources/images';
import { MembershipTypes } from 'mxp-schemas';
import { formatMonthsToYears } from 'utils/dateHelper';
import { Routes } from 'constants/index';
import moment from 'moment-timezone';

export enum RecordType {
  EMPLOYMENT = 'Employment',
  ACTIVITIES = 'Activities',
  SKILLS = 'Skills',
  EXAM_EPA2 = 'Exam EPA2',
  EPA2L7 = 'EPA2L7',
}

interface Props {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  items?: any[];
  recordType: RecordType;
  defaultCount?: number;
  maxCounter?: number;
  links?: MembershipTypes.PracticalExperienceRecordLink[];
  disableSharedLinks?: boolean;
  isApprentice?: boolean;
  isUploadDisable?: boolean;
  countOptions?: {
    statusesToCount:
      | MembershipTypes.PracticalExperienceRequirementStatus[]
      | MembershipTypes.EPACaseStatus[]
      | string[];
    label: string;
  };
  pathway?: any;
  portfolioStatus?: string | null;
  onEdit?: () => void;
  onView?: () => void;
  onAdd?: () => void;
  onWithdraw?: () => void;
  onRemove?: () => void;
}

export const PracticalExperienceType: React.FC<Props> = memo(
  ({
    title,
    description,
    items = [],
    defaultCount = 1,
    maxCounter,
    recordType,
    links,
    isApprentice,
    isUploadDisable,
    disableSharedLinks,
    countOptions = {
      statusesToCount: [MembershipTypes.PracticalExperienceRequirementStatus.APPROVED],
      label: 'complete',
    },
    pathway,
    portfolioStatus,
    onEdit,
    onView,
    onAdd,
    onWithdraw,
    onRemove,
  }) => {
    const dispatch = useDispatch();
    const PracticalExpReqStatus = MembershipTypes.PracticalExperienceRequirementStatus;
    const constants = useSelector(constantsSelector);
    const perUrlMoreInformation = constants?.[CONSTANTS.PER_URL_MORE_INFORMATION];

    const statusBinded = (status: any) => {
      if (
        [MembershipTypes.Pathway.PQ, MembershipTypes.Pathway.FLP, MembershipTypes.Pathway.CHINESE_PQ].includes(
          pathway as MembershipTypes.Pathway
        )
      ) {
        return status === MembershipTypes.PracticalExperienceRequirementStatus.APPROVED;
      }
      if (isApprentice) {
        return (
          status === MembershipTypes.EPACaseStatus.DRAFTED ||
          status === MembershipTypes.EPACaseStatus.PENDING_VALIDATION ||
          status === MembershipTypes.EPACaseStatus.RFI ||
          status === MembershipTypes.EPACaseStatus.SCREENING ||
          status === MembershipTypes.EPACaseStatus.WITHDRAWN ||
          status === MembershipTypes.EPACaseStatus.IN_ARBITRATION
        );
      }
    };
    const isNotIncluded = (activityType: any) => {
      return (
        activityType !== MembershipTypes.EPA2ReflectiveStatements.REFLECTIVE_STATEMENT_4 &&
        activityType !== MembershipTypes.EPA2ReflectiveStatements.REFLECTIVE_STATEMENT_5 &&
        activityType !== MembershipTypes.EPA2PortfolioEvidence.PORTFOLIO_EVIDENCE_2 &&
        activityType !== MembershipTypes.EPA2PortfolioEvidence.PORTFOLIO_EVIDENCE_3 &&
        activityType !== MembershipTypes.EPA2PortfolioEvidence.PORTFOLIO_EVIDENCE_4 &&
        activityType !== MembershipTypes.EPA2PortfolioEvidence.PORTFOLIO_EVIDENCE_5
      );
    };

    const practicalExpReqRecords = () => {
      const records = [];
      for (let i = 0; i < defaultCount; i += 1) {
        // For Add Record
        if (items[i] === undefined) {
          const pathname = getRecordPath(recordType);
          records.push(
            <PracticalExperienceRecord
              key={i}
              label={getRecordLabel(recordType!)}
              status={PracticalExpReqStatus.EMPTY}
              isApprentice={isApprentice}
              isUploadDisable={isUploadDisable}
              onAdd={onAdd ? onAdd : () => dispatch(push({ pathname: generatePath(getPath(pathname as Routes)) }))}
            />
          );
        }
        // For Records except Add
        else {
          if (!isUploadDisable) {
            records.push(
              <PracticalExperienceRecord
                key={i}
                label={recordType === RecordType.EMPLOYMENT ? items[i]?.employer?.name : items[i]?.activityType}
                jobTitle={items[i]?.jobTitle}
                startDate={items[i]?.startDate}
                endDate={items[i]?.endDate}
                numberOfDaysPerWeek={items[i]?.numberOfDaysPerWeek}
                employmentType={items[i]?.employmentType}
                status={items[i].status}
                isApprentice={isApprentice}
                isUploadDisable={isUploadDisable}
                onEdit={onEdit ? onEdit : items[i].onEdit}
                onView={onView ? onView : items[i].onView}
                onAdd={onAdd ? onAdd : items[i].onAdd}
                onWithdraw={onWithdraw ? onWithdraw : items[i].onWithdraw}
                onRemove={onRemove ? onRemove : items[i].onRemove}
                isStudent={[
                  MembershipTypes.Pathway.PQ,
                  MembershipTypes.Pathway.FLP,
                  MembershipTypes.Pathway.CHINESE_PQ,
                ].includes(pathway as MembershipTypes.Pathway)}
                portfolioStatus={portfolioStatus}
              />
            );
          } else if (isUploadDisable) {
            records.push(
              <PracticalExperienceRecord
                key={i}
                label={recordType === RecordType.EMPLOYMENT ? items[i]?.employer?.name : items[i]?.activityType}
                jobTitle={items[i]?.jobTitle}
                startDate={items[i]?.startDate}
                endDate={items[i]?.endDate}
                numberOfDaysPerWeek={items[i]?.numberOfDaysPerWeek}
                employmentType={items[i]?.employmentType}
                status={items[i].status}
                isApprentice={isApprentice}
                isUploadDisable={isUploadDisable}
                onView={onView ? onView : items[i].onView}
                onWithdraw={onWithdraw ? onWithdraw : items[i].onWithdraw}
                onRemove={onRemove ? onRemove : items[i].onRemove}
                isStudent={[
                  MembershipTypes.Pathway.PQ,
                  MembershipTypes.Pathway.FLP,
                  MembershipTypes.Pathway.CHINESE_PQ,
                ].includes(pathway as MembershipTypes.Pathway)}
                portfolioStatus={portfolioStatus}
              />
            );
          }
        }
      }
      return records;
    };

    let totalMonths = 0;
    if (recordType === RecordType.EMPLOYMENT) {
      items.forEach((item: any) => {
        if (item.status === PracticalExpReqStatus.APPROVED) {
          totalMonths +=
            item.employmentType === MembershipTypes.PracticalExperienceEmploymentType.FULL_TIME
              ? Math.round(moment(item.endDate).diff(item.startDate, 'month', true)) // Full time formula
              : Math.round(
                  (item.numberOfDaysPerWeek / 5) * Math.round(moment(item.endDate).diff(item.startDate, 'month', true))
                ); // Part time formula
        }
      });
    }
    const confirmedWorkingExperience = formatMonthsToYears(totalMonths);

    return (
      <StyledContainer>
        <StyledItemTitle>{title}</StyledItemTitle>
        <StyledDescription>{description}</StyledDescription>
        <SubHeadingDetails>
          <div>
            {(disableSharedLinks === undefined || !disableSharedLinks) && (
              <StyledLink testId={`employment-record-${title}`} to={perUrlMoreInformation} isExternal={true}>
                More information
              </StyledLink>
            )}
            {links &&
              links.map((item, index) => (
                <StyledButtonLink
                  key={index}
                  size={ButtonEnums.sizes.medium}
                  variant={ButtonEnums.variants.link}
                  testId={`employment-record-${title}`}
                  onClick={() => {
                    window.open(item.url, '_blank');
                  }} // tslint:disable-line jsx-no-lambda
                >
                  {item.title}
                </StyledButtonLink>
              ))}
          </div>
          <StyledCompletionDetails>
            {recordType === RecordType.EMPLOYMENT ? (
              <>
                <StyledCompletedYears>
                  {confirmedWorkingExperience && `${confirmedWorkingExperience} confirmed`}
                </StyledCompletedYears>
                <StyledDivider>/</StyledDivider>
                <StyledOverallYears>3 years</StyledOverallYears>
              </>
            ) : (
              <>
                {items
                  .filter((data: MembershipTypes.PracticalExperienceRequirementSkillsCoreActivity) =>
                    isNotIncluded(data.activityType)
                  )
                  .map((item, index) => (
                    <span key={index}>
                      <StyledIcon
                        src={(countOptions.statusesToCount as any[]).includes(item.status) ? IC_CHECK_GREEN : IC_CHECK}
                      />
                    </span>
                  ))}
                <span>
                  {`${
                    items
                      .filter(
                        (data: MembershipTypes.PracticalExperienceRequirementSkillsCoreActivity) =>
                          isNotIncluded(data.activityType) && statusBinded(data.status)
                      )
                      .map(item => (countOptions.statusesToCount as any[]).includes(item.status)).length
                  } of ${maxCounter ? maxCounter : defaultCount} ${countOptions.label}`}
                </span>
              </>
            )}
          </StyledCompletionDetails>
        </SubHeadingDetails>
        {practicalExpReqRecords()}
      </StyledContainer>
    );
  }
);

const getRecordLabel = (recordType: RecordType): string => {
  switch (recordType) {
    case RecordType.EMPLOYMENT:
      return 'Add employment information';
    case RecordType.ACTIVITIES:
      return 'Add core work activity';
    case RecordType.SKILLS:
      return 'Add skill & behaviour';
    default:
      return 'Add employment information';
  }
};

const getRecordPath = (recordType: RecordType): Routes => {
  switch (recordType) {
    case RecordType.EMPLOYMENT:
      return Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT;
    case RecordType.ACTIVITIES:
      return Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES;
    case RecordType.SKILLS:
      return Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_SKILLS_BEHAVIORS;
    default:
      return Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT;
  }
};

const StyledDivider = styled.span`
  margin-right: ${props => props.theme.pxToRem(10)};
  margin-left: ${props => props.theme.pxToRem(10)};
`;

const StyledCompletedYears = styled.span`
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledOverallYears = styled.span`
  color: ${props => props.theme.colors.neutralGrey6};
`;

const StyledCompletionDetails = styled.div`
  display: flex;
  align-items: center;
`;

const StyledContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(50)};

  img {
    width: ${props => props.theme.pxToRem(24)};
    height: ${props => props.theme.pxToRem(24)};
  }
`;

const StyledIcon = styled.img<ThemedStyledProps<{ isApproved?: boolean }, DefaultTheme>>`
  margin-right: ${props => `${props.theme.pxToRem(5)}`};
  ${props => `
    fill: ${props.isApproved ? props.theme.colors.secondaryTeal : ''};
  `}
`;

const StyledButtonLink = styled(Button)`
  &&&&&&&& {
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(16)} ${props.theme.pxToRem(20)} 0`};
    margin: 0 ${props => `${props.theme.pxToRem(5)}`};
    display: inline;
    font-size: ${props => props.theme.fontSizes.s};
  }
`;

const SubHeadingDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledItemTitle = styled.h3`
  font-size: ${props => props.theme.fontSizes.m};
`;

const StyledDescription = styled.p`
  width: 50%;
  color: ${props => props.theme.colors.neutralGrey6};
  margin-bottom: 0.1rem;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primaryPurple};
  text-decoration: underline;
  margin: 0 ${props => `${props.theme.pxToRem(5)}`};
  display: inline;
  font-size: ${props => props.theme.fontSizes.s};
  line-height: 4rem;
`;
