import { MembershipTypes, Contentful } from 'mxp-schemas';
// ------------------------------------
// Constants
// ------------------------------------
export enum membershipActionNames {
  IS_LOADING = 'membership/LOADING',
  IS_NOT_LOADING = 'membership/NOT_LOADING',

  GET_MEMBERSHIP_TYPES = 'membership/GET_MEMBERSHIP_TYPES',
  SET_MEMBERSHIP_TYPES = 'membership/SET_MEMBERSHIP_TYPES',
  GET_MEMBERSHIP_TIERS = 'membership/GET_MEMBERSHIP_TIERS',
  SET_MEMBERSHIP_PRODUCT = 'membership/SET_MEMBERSHIP_PRODUCT',
  GET_MEMBERSHIP_SECTIONS = 'membership/GET_MEMBERSHIP_SECTIONS',
  GET_MEMBERSHIP_RELATED_ADDONS = 'membership/GET_MEMBERSHIP_RELATED_ADDONS',
  GET_MEMBERSHIP_CREDENTIALS = 'membership/GET_MEMBERSHIP_CREDENTIALS',

  GET_USER_MEMBERSHIP_PACKAGE = 'membership/GET_USER_MEMBERSHIP_PACKAGE',
  SET_MEMBERSHIP_PACKAGE_TYPE = 'membership/SET_MEMBERSHIP_PACKAGE_TYPE',
  SET_MEMBERSHIP_PACKAGE_TIER = 'membership/SET_MEMBERSHIP_PACKAGE_TIER',
  SET_MEMBERSHIP_PACKAGE_SECTION = 'membership/SET_MEMBERSHIP_PACKAGE_SECTION',
  SEE_MORE_MEMBERSHIP_PACKAGE_RELATED_ADDON_PRODUCT = 'membership/SEE_MORE_MEMBERSHIP_PACKAGE_RELATED_ADDON_PRODUCT',
  SEE_MORE_MEMBERSHIP_PACKAGE_RELATED_PATHWAY_PRODUCT = 'membership/SEE_MORE_MEMBERSHIP_PACKAGE_RELATED_PATHWAY_PRODUCT',
  SEE_MORE_MEMBERSHIP_PACKAGE_SECTION_PRODUCT_WITH_PRICE = 'membership/SEE_MORE_MEMBERSHIP_PACKAGE_SECTION_PRODUCT_WITH_PRICE',
  SEE_MORE_MEMBERSHIP_PACKAGE_CREDENTIAL_PRODUCT = 'membership/SEE_MORE_MEMBERSHIP_PACKAGE_CREDENTIAL_PRODUCT',
  RESET_MEMBERSHIP_USER_CHOICE = 'membership/RESET_MEMBERSHIP_USER_CHOICE',
  RESET_MEMBERSHIP_USER_CHOICESLUG = 'membership/RESET_MEMBERSHIP_USER_CHOICESLUG',

  ADD_MEMBERSHIP_TO_CART = 'membership/ADD_MEMBERSHIP_TO_CART',
  ADD_CART_LOADING = 'membership/ADD_CART_LOADING',

  SAVING_APPLICATION_PART = 'membership/SAVING_APPLICATION_PART',

  ADD_MEMBERSHIP_PACKAGE_SECTION_FREE_PRODUCT = 'membership/ADD_MEMBERSHIP_PACKAGE_SECTION_FREE_PRODUCT',
  ADD_MEMBERSHIP_PACKAGE_RELATED_ADDON = 'membership/ADD_MEMBERSHIP_PACKAGE_RELATED_ADDON',
  ADD_MEMBERSHIP_PACKAGE_SECTION_WITH_PRICE_PRODUCT = 'membership/ADD_MEMBERSHIP_PACKAGE_SECTION_WITH_PRICE_PRODUCT',
  ADD_MEMBERSHIP_PACKAGE_CREDENTIAL = 'membership/ADD_MEMBERSHIP_PACKAGE_CREDENTIAL',
  ADD_ADDON_PRODUCT_LIST_DATA_IN_USERCHOICE = 'membership/ADD_ADDON_PRODUCT_LIST_DATA_IN_USERCHOICE',
  ADD_INCLUDED_ADD_ONS = 'membership/ADD_INCLUDED_ADD_ONS',
  ADD_CREDENTIAL_PRODUCT_ID_IN_USERCHOICE = 'membership/ADD_CREDENTIAL_PRODUCT_ID_IN_USERCHOICE',
  ADD_SECTION_PRODUCT_ID_IN_USERCHOICE = 'membership/ADD_SECTION_PRODUCT_ID_IN_USERCHOICE',
  ADD_MEMBERSHIP_PACKAGE_RELATED_PATHWAY = 'membership/ADD_MEMBERSHIP_PACKAGE_RELATED_PATHWAY',
  ADD_FLP_VARIANT_IN_USERCHOICE = 'membership/ADD_FLP_VARIANT_IN_USERCHOICE',

  REMOVE_MEMBERSHIP_PACKAGE_SECTION_FREE_PRODUCT = 'membership/REMOVE_MEMBERSHIP_PACKAGE_SECTION_FREE_PRODUCT',
  REMOVE_MEMBERSHIP_PACKAGE_RELATED_ADDON = 'membership/REMOVE_MEMBERSHIP_PACKAGE_RELATED_ADDON',
  REMOVE_MEMBERSHIP_PACKAGE_SECTION_WITH_PRICE_PRODUCT = 'membership/REMOVE_MEMBERSHIP_PACKAGE_SECTION_WITH_PRICE_PRODUCT',
  REMOVE_MEMBERSHIP_PACKAGE_CREDENTIAL = 'membership/REMOVE_MEMBERSHIP_PACKAGE_CREDENTIAL',
  REMOVE_MEMBERSHIP_PACKAGE_CREDENTIAL_BY_SKU = 'membership/REMOVE_MEMBERSHIP_PACKAGE_CREDENTIAL_BY_SKU',
  REMOVE_MEMBERSHIP_PACKAGE_RELATED_PATHWAY = 'membership/REMOVE_MEMBERSHIP_PACKAGE_RELATED_PATHWAY',
  SET_SELECTED_PATHWAY_BUNDLE_ID = 'membership/SET_PATHWAY_BUNDLE_ID',

  FILTER_MEMBERSHIP_CREDENTIAL_BY_CATEGORY = 'membership/FILTER_MEMBERSHIP_CREDENTIAL_BY_CATEGORY',
  FILTER_MEMBERSHIP_PACKAGE_RELATED_ADDON_BY_CATEGORY = 'membership/FILTER_MEMBERSHIP_PACKAGE_RELATED_ADDON_BY_CATEGORY',
  FILTER_MEMBERSHIP_PACKAGE_SECTION_BY_CATEGORY = 'membership/FILTER_MEMBERSHIP_PACKAGE_SECTION_BY_CATEGORY',
  FILTER_MEMBERSHIP_PACKAGE_RELATED_PATHWAY_BY_CATEGORY = 'membership/FILTER_MEMBERSHIP_PACKAGE_RELATED_PATHWAY_BY_CATEGORY',

  MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_SECTION_CATEGORY = 'membership/MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_SECTION_CATEGORY',
  MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_ADDON_CATEGORY = 'membership/MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_ADDON_CATEGORY',
  MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_PATHWAY_CATEGORY = 'membership/MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_PATHWAY_CATEGORY',
  MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_CREDENTIAL_CATEGORY = 'membership/MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_CREDENTIAL_CATEGORY',

  SHOW_MEMBERSHIP_FIRST_PAGE_OF_SECTION_PRODUCTS = 'membership/SHOW_MEMBERSHIP_FIRST_PAGE_OF_SECTION_PRODUCTS',
  SHOW_MEMBERSHIP_FIRST_PAGE_OF_ADDON_PRODUCTS = 'membership/SHOW_MEMBERSHIP_FIRST_PAGE_OF_ADDON_PRODUCTS',
  SHOW_MEMBERSHIP_FIRST_PAGE_OF_CREDENTIAL_PRODUCTS = 'membership/SHOW_MEMBERSHIP_FIRST_PAGE_OF_CREDENTIAL_PRODUCTS',
  // membership benefit
  GET_MEMBERSHIP_BENEFIT = 'membership/GET_MEMBERSHIP_BENEFIT',
  GET_MEMBERSHIP_BENEFIT_PRODUCT = 'membership/GET_MEMBERSHIP_BENEFIT_PRODUCT',
  GET_MEMBERSHIP_RELATED_BENEFITS = 'membership/GET_MEMBERSHIP_RELATED_BENEFITS',
  GET_ALL_PRODUCTS_LIST_DATA = 'membership/GET_ALL_PRODUCTS_LIST_DATA',
  GET_SELECTED_BENEFITS = 'membership/GET_SELECTED_BENEFITS',
  SET_SELECTED_SKU_BENEFITS = 'membership/SET_SELECTED_SKU_BENEFITS',
  SET_SELECTED_MEMBERSHIP_BENEFITS = 'membership/SET_SELECTED_MEMBERSHIP_BENEFITS',

  FETCH_EXEMPTION_ENGINE = 'membership/FETCH_EXEMPTION_ENGINE',
  MUTATE_PERSON_EXAM_EXEMPTION = 'membership/MUTATE_PERSON_EXAM_EXEMPTION',
  FETCH_EXEMPTION_LEVEL = 'membership/FETCH_EXEMPTION_LEVEL',
  MUTATE_PERSON_EXAM_STATUS = 'membership/MUTATE_PERSON_EXAM_STATUS',

  // person level exemption
  GET_PERSON_LEVEL_EXEMPTION = 'getPersonLevelExemptionId/PERSON_LEVEL_EXEMPTION',
  GET_QUALIFICATION_DATA = 'getQualificationData/GET_QUALIFICATION',
  GET_PERSON_LEVEL_EXEMPTIONS = 'getPersonLevelExemptionId/PERSON_LEVEL_EXEMPTIONS',
  SET_FLP_PERSON_EXAM = 'membership/SET_FLP_PERSON_EXAM',
  GET_QUALIFICATION_DATA_LOADING = 'getQualificationData/GET_QUALIFICATION_LOADING',

  // center membership
  SET_CENTER_MEMBERSHIP_PACKAGE_TYPE = 'membership/SET_CENTER_MEMBERSHIP_PACKAGE_TYPE',
  SET_MEMBERSHIP_JOURNEY_TYPE = 'membership/SET_MEMBERSHIP_JOURNEY_TYPE',
  GET_RENEWAL_SEASON_INFO = 'membership/GET_RENEWAL_SEASON_INFO',

  // credential
  SEARCH_INDIVIDUAL = 'membership/SEARCH_INDIVIDUAL',
  CHANGE_MEMBERSHIP_FILTER = 'membership/CHANGE_MEMBERSHIP_FILTER',
  GET_DATA = 'membership/GET_DATA',
  GET_SELECTED_CREDENTIALS = 'membership/GET_SELECTED_CREDENTIALS',
  SET_IS_CREDENTIALS_JOURNEY = 'membership/SET_IS_CREDENTIALS_JOURNEY',
  SET_CREDENTIALS_ITEM_RENEWAL = 'membership/SET_CREDENTIALS_ITEM_RENEWAL',
  SET_IS_ALLOW_TO_ADD_FCMA_IN_CART = 'membership/SET_IS_ALLOW_TO_ADD_FCMA_IN_CART',
  SET_HAS_SELECTED_FCMA_DESIGNATION = 'membership/SET_HAS_SELECTED_FCMA_DESIGNATION',

  // membership
  SET_MEMBERSHIP_ITEM_RENEWAL = 'membership/SET_MEMBERSHIP_ITEM_RENEWAL',
  SET_IS_RENEWALS_JOURNEY = 'membership/SET_IS_RENEWALS_JOURNEY',
  SET_FLP_DISCOUNTED_PRICE = 'membership/SET_FLP_DISCOUNTED_PRICE',

  SET_IS_FLP_UPGRADE = 'membership/SET_IS_FLP_UPGRADE',
  SET_IS_FLP_SWITCH = 'membership/SET_IS_FLP_SWITCH',

  SET_LEARNING_PATHWAY = 'membership/SET_LEARNING_PATHWAY',
  SET_CHANGE_MY_LEARNING_PATH = 'membership/SET_CHANGE_MY_LEARNING_PATH',
  SET_IS_LEARNING_PATH_TO_CHANGE_MATCHED_PREVIOUSLY_SELECTED_PATH = 'membership/SET_IS_LEARNING_PATH_TO_CHANGE_MATCHED_PREVIOUSLY_SELECTED_PATH',

  // credential app
  SET_CREDENTIAL_APPLICATION_VALUE = 'membership/SET_CREDENTIAL_APPLICATION_VALUE',
  SUBMIT_CREDENTIAL_APPLICATION = 'membership/SUBMIT_CREDENTIAL_APPLICATION',
  GET_DATA_OF_SPECIFIC_INVITE = 'membership/GET_DATA_OF_SPECIFIC_INVITE',
  CHECK_IF_CREDENTIAL_ELIGIBLE_FOR_MEMBERSHIP = 'membership/CHECK_IF_CREDENTIAL_ELIGIBLE_FOR_MEMBERSHIP',
  CHECK_SELECTED_SECTION_IF_IT_IS_PART_OF_MEMBERSHIP_BENEFIT = 'membership/CHECK_SELECTED_SECTION_IF_IT_IS_PART_OF_MEMBERSHIP_BENEFIT',

  // practical experience requirement
  GET_PRACTICAL_EXPERIENCE_REQUIREMENT = 'membership/GET_PRACTICAL_EXPERIENCE_REQUIREMENT',
  UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_RECORD = 'membership/UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_RECORD', // update redux record
  UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT = 'membership/UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT',
  UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_ACTIVITIES = 'membership/UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_ACTIVITIES',
  UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_SKILLS = 'membership/UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_SKILLS',
  REMOVE_PRACTICAL_EXPERIENCE_REQUIREMENT_MODULE_RECORD = 'membership/REMOVE_PRACTICAL_EXPERIENCE_REQUIREMENT_MODULE_RECORD',
  QUERY_ORGANIZATIONS_BY_WILDCARD = 'membership/QUERY_ACCOUNTS_BY_WILDCARD',
  QUERY_ORGANIZATIONS_BY_WILDCARD_LOADING = 'membership/QUERY_ACCOUNTS_BY_WILDCARD_LOADING',
  QUERY_ORGANIZATIONS_CITIES = 'membership/QUERY_ORGANIZATIONS_CITIES',
  QUERY_ORGANIZATIONS_CITIES_LOADING = 'membership/QUERY_ORGANIZATIONS_CITIES_LOADING',
  QUERY_ORGANIZATION_BY_NAME_AND_CITY = 'membership/QUERY_ORGANIZATION_BY_NAME_AND_CITY',
  QUERY_ORGANIZATION_IF_EXISTS = 'membership/QUERY_ORGANIZATION_IF_EXISTS',
  CLEAR_NEW_EMPLOYER_DATA = 'membership/CLEAR_NEW_EMPLOYER_DATA',
  RESET_EMPLOYMENT_EFFECT = 'membership/RESET_EMPLOYMENT_EFFECT',

  SET_COMING_FROM_PROP_PAGE = 'membership/SET_COMING_FROM_PROP_PAGE',
  SET_SECTIONS_CREDENTIALS_RENEWAL = 'membership/SET_SECTIONS_CREDENTIALS_RENEWAL',

  SET_MEMBERSHIP_EVENT = 'membership/SET_MEMBERSHIP_EVENT',
  SET_PROP_PAGE_URL = 'membership/SET_PROP_PAGE_URL',
  REMOVE_PROP_PAGE_URL = 'membership/REMOVE_PROP_PAGE_URL',
  // Firm
  SET_INVITE_DATA_STATUS = 'membership/SET_INVITE_DATA_STATUS',

  GET_CIMA_MEMBERSHIP_TYPES = 'membership/GET_CIMA_MEMBERSHIP_TYPES',
  SET_ROLE_FORM_DATA = 'membership/SET_ROLE_FORM_DATA',
  SET_EMPLOYER_FORM_DATA = 'membership/SET_EMPLOYER_FORM_DATA',
  SET_EMPLOYER_DATA = 'membership/SET_EMPLOYER_DATA',
  SET_EMPLOYMENT_ROLE_DATA = 'membership/SET_EMPLOYMENT_ROLE_DATA',
  SET_EMPLOYMENT_SUPERVISOR_DATA = 'membership/SET_EMPLOYMENT_SUPERVISOR_DATA',
  SET_EMPLOYMENT_SUPERVISOR_EMPLOYER_DATA = 'membership/SET_EMPLOYMENT_SUPERVISOR_EMPLOYER_DATA',
  CLEAR_EMPLOYMENT_DATA = 'membership/CLEAR_EMPLOYMENT_DATA',
  CLEAR_EMPLOYMENT_ADDRESS_DATA = 'membership/CLEAR_EMPLOYMENT_ADDRESS_DATA',
  CLEAR_EXISTING_EMPLOYMENT_DATA = 'membership/CLEAR_EXISTING_EMPLOYMENT_DATA',
  CLEAR_EXISTING_EMPLOYMENT_ADDRESS_DATA = 'membership/CLEAR_EXISTING_EMPLOYMENT_ADDRESS_DATA',
  CLEAR_SUPERVISOR_DATA = 'membership/CLEAR_SUPERVISOR_DATA',
  CLEAR_SUPERVISOR_EMPLOYMENT_DATA = 'membership/CLEAR_SUPERVISOR_EMPLOYMENT_DATA',
  CLEAR_NEW_EMPLOYMENT_DATA = 'membership/CLEAR_NEW_EMPLOYMENT_DATA',
  CLEAR_SUPERVISOR_EMPLOYER_ADDRESS = 'membership/CLEAR_SUPERVISOR_EMPLOYER_ADDRESS',
  VALIDATE_EMPLOYEE_FORM = 'membership/VALIDATE_EMPLOYEE_FORM',

  SET_FORM_DATA_EMPLOYMENT = 'membership/SET_FORM_DATA_EMPLOYMENT',
  SET_EMPLOYMENT_SUPERVISOR_FORM_DATA = 'membership/SET_EMPLOYMENT_SUPERVISOR_FORM_DATA',
  SET_EMPLOYMENT_SUPERVISOR_EMPLOYER_FORM_DATA = 'membership/SET_EMPLOYMENT_SUPERVISOR_EMPLOYER_FORM_DATA',
  CLEAR_SUPERVISOR_EMPLOYMENT_FORM_DATA = 'membership/CLEAR_SUPERVISOR_EMPLOYMENT_FORM_DATA',
  CLEAR_SUPERVISOR_EMPLOYER_ADDRESS_FORM_DATA = 'membership/CLEAR_SUPERVISOR_EMPLOYER_ADDRESS_FORM_DATA',
  CLEAR_SUPERVISOR_FORM_DATA = 'membership/CLEAR_SUPERVISOR_FORM_DATA',

  SET_USERCHOICE_TYPE = 'membership/SET_USERCHOICE_TYPE',
  SET_UPDATE_APPRENTICE_LEVEL = 'membership/SET_UPDATE_APPRENTICE_LEVEL',
  // regional pathway
  GET_ALL_PRODUCTS_PATHWAY = 'membership/GET_ALL_PRODUCTS_PATHWAY',
  SET_IS_OPEN_EMPLOYMENT_MODAL_FROM_INVITE = 'SET_IS_OPEN_EMPLOYMENT_MODAL_FROM_INVITE',

  // entry levels
  SET_ENTRY_LEVELS = 'entryLevels/SET_ENTRY_LEVELS',
  GET_ENTRY_LEVELS = 'entryLevels/GET_ENTRY_LEVELS',
  SET_OFFLINE_EXEMPTIONS_ENTRY_LEVELS = 'offlineExemptionsEntryLevels/SET_OFFLINE_EXEMPTIONS_ENTRY_LEVELS',

  ADD_MEMBERSHIP_SELECTED_BENEFIT = 'membership/ADD_MEMBERSHIP_SELECTED_BENEFIT',

  GET_PER_FINANCIAL_BODIES = 'membership/GET_PER_FINANCIAL_BODIES',

  // Firm Billing
  GET_MEMBERSHIP_INVITE_DATA = 'membership/GET_MEMBERSHIP_INVITE_DATA',
  SET_HAS_NOT_MEET_MINIMUM_REQUIREMENTS = 'membership/SET_HAS_NOT_MEET_MINIMUM_REQUIREMENTS',

  // Cgma designation journey
  SET_IS_FLP_MODAL_OPEN = 'membership/SET_IS_FLP_MODAL_OPEN',
  SET_IS_CIMA_MEMBERSHIP_PAGE_JOURNEY = 'membership/SET_IS_CIMA_MEMBERSHIP_PAGE_JOURNEY',
  SET_FCMA_IN_USER_CHOICE = 'membership/SET_FCMA_IN_USER_CHOICE',

  // Attestations
  GET_ATTESTATIONS = 'membership/GET_ATTESTATIONS',
  SET_ATTESTATION_IS_FETCHING = 'membership/SET_ATTESTATION_IS_FETCHING',
  APPEND_ATTESTATION_QUERY = 'membership/APPEND_ATTESTATION_QUERY',
  SUBMIT_ATTESTATIONS = 'membership/SUBMIT_ATTESTATIONS',
  SET_ATTESTATION = 'membership/SET_ATTESTATION',

  GET_INACTIVE_MEMBERSHIP_PRODUCT_NAME = 'membership/GET_INACTIVE_MEMBERSHIP_PRODUCT_NAME',

  // Custom Storefront Aggregation View
  STOREFRONT_AGGREGATION_VIEW_TYPE = 'membership/STOREFRONT_AGGREGATION_VIEW_TYPE',
  STOREFRONT_AGGREGATION_VIEW_TIER = 'membership/STOREFRONT_AGGREGATION_VIEW_TIER',
  STOREFRONT_AGGREGATION_VIEW_SECTION = 'membership/STOREFRONT_AGGREGATION_VIEW_SECTION',
  STOREFRONT_AGGREGATION_VIEW_SKILL = 'membership/STOREFRONT_AGGREGATION_VIEW_SKILL',
  STOREFRONT_AGGREGATION_VIEW_CREDENTIAL = 'membership/STOREFRONT_AGGREGATION_VIEW_CREDENTIAL',
  STOREFRONT_AGGREGATION_VIEW_CIMA_TYPE = 'membership/STOREFRONT_AGGREGATION_VIEW_CIMA_TYPE',
  // Dues
  SET_RENEWAL_PRODUCTS = 'membership/SET_RENEWAL_PRODUCTS',

  // Membership - Express Renewal
  SET_HAS_SELECTED_CREDENTIAL = 'membership/SET_HAS_SELECTED_CREDENTIAL',
  MODIFY_MEMBERSHIP_ACCORDION = 'membership/MODIFY_MEMBERSHIP_ACCORDION',

  SET_HAS_SELECTED_SECTION = 'membership/SET_HAS_SELECTED_SECTION',
  SET_HAS_SELECTED_TIER = 'membership/SET_HAS_SELECTED_TIER',
  SET_HAS_SELECTED_TYPE = 'membership/SET_HAS_SELECTED_TYPE',
  SET_HAS_SELECTED_ADDONS = 'membership/SET_HAS_SELECTED_ADDONS',
}
// TODO: Update
export const initialState: State.Membership = {
  loading: false,
  journeyType: '',
  types: {
    list: [],
    filteredCIMAMembershipTypes: [],
    allCIMAMembershipTypes: [],
    error: null,
    isFetched: false,
    joiningFee: null,
  },
  product: null,
  credentials: {
    list: [],
    categories: [],
    error: null,
    userSelected: [],
  },
  sections: {
    listOfFreeProduct: [],
    listOfProductWithPrice: [],
    benefitQuantity: 0,
    categories: [],
    error: null,
    isFetched: false,
  },
  addons: {
    list: [],
    categories: [],
    error: null,
    isFetched: false,
  },
  pathways: {
    list: [],
    categories: [],
    error: null,
    isFetched: false,
  },
  userChoice: {
    type: {
      id: '',
      slug: '',
    },
    tier: '',
    credentials: [],
    sectionFreeProduct: [],
    sectionProductWithPrice: [],
    addons: [],
    credentialProductId: '',
    sectionProductId: '',
    flpVariant: null,
    pathways: [],
    selectedPathwayBundleId: '',
    isFirmAffiliatedForCPEAOrPCPS: false,
    changeMyLearningPath: false,
    isLearningPathToChangeMatchedPreviouslySelectedPath: false,
  },
  inviteData: {
    inviteId: '',
    isPaidByFirm: false,
    isExistingUserUponInvite: false,
    type: {
      id: '',
      slug: '',
    },
    tier: '',
    organizationId: '',
    organization: {
      id: '',
      name: '',
      type: '',
      AICPAUid: '',
      creditHoldStatus: false,
      hasAICPAUid: false,
      isFirmBillingEnabled: false,
      billingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: 'USA' },
      shippingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: '' },
      phone: '',
      email: '',
    },
    error: null,
    position: '',
    status: '',
    isFLP: false,
    isPaidByFLP: false,
    flpSubscriptionType: '',
    duration: '',
  },
  membershipEligibleCredential: {
    membershipTypeSlug: '',
    productId: '',
    tier: '',
    eligible: false,
  },
  membershipEligibleSection: {
    membershipTypeSlug: '',
    tier: '',
    eligible: false,
  },
  addCartStatus: {
    success: false,
    error: null,
  },
  addCartLoading: false,
  benefits: {
    list: [],
    isFetched: false,
    success: false,
    loading: false,
    errors: null,
    userSelected: [],
    skuSelected: [],
    membershipSelected: '',
    selectedBenefits: [],
  },
  productsListData: null,
  attestationsToQuery: {
    [Contentful.Attestations.CategoryType.JOURNEY]: [],
    [Contentful.Attestations.CategoryType.MEMBERSHIP_TYPE]: [],
    [Contentful.Attestations.CategoryType.PATHWAY]: [],
    [Contentful.Attestations.CategoryType.EXEMPTION_TYPE]: [],
    [Contentful.Attestations.CategoryType.DOCUMENT_STATUS]: [],
    [Contentful.Attestations.CategoryType.DONATION]: [],
    [Contentful.Attestations.CategoryType.GENERIC]: [],
  },
  attestations: {
    [Contentful.Attestations.CategoryType.JOURNEY]: [],
    [Contentful.Attestations.CategoryType.MEMBERSHIP_TYPE]: [],
    [Contentful.Attestations.CategoryType.PATHWAY]: [],
    [Contentful.Attestations.CategoryType.EXEMPTION_TYPE]: [],
    [Contentful.Attestations.CategoryType.DOCUMENT_STATUS]: [],
    [Contentful.Attestations.CategoryType.DONATION]: [],
    [Contentful.Attestations.CategoryType.GENERIC]: [],
  },
  attestationsIsFetched: false,
  productsListDataFetched: false,
  allProductsListData: null,
  allProductsListDataFetched: false,
  isRenewalSeason: false,
  isCimaRenewalSeason: false,
  isMipRenewalSeason: false,
  individual: {
    individualSearchObject: null,
    searchType: 1,
    totalSize: 0,
    list: [],
    isFetched: false,
    success: false,
    loading: false,
    errors: null,
  },
  credentialQuestions: {
    CPALicense: false,
    IntlCPALicense: false,
    IntlOrgName: '',
    IntlMemberNum: '',
    PFSReinEdu: false,
    FINRANumber: '',
    PFSExam: false,
    PFSQualExams: false,
    PFSStdEdu: false,
    PFSStdExp: false,
    PFSExdEdu: false,
    PFSExdExp: false,
    CITPAICPAStdExam: false,
    CITPStdExam: false,
    CITPStdExp: false,
    CITPCertExp: false,
    CITPReinEdu: false,
    CITPExdExam: false,
    CITPExdExp: false,
    CITPExdEdu: false,
    CFFStdExam: false,
    CFFStdExp: false,
    CFFStdEdu: false,
    CFFCertExam: false,
    CFFCertEdu: false,
    CFFExdExam: false,
    CFFExdEdu: false,
    CFFExdExp: false,
    CFFReinEdu: false,
    CVFIReinEdu: false,
    CVFIReinExp: false,
    CVFICredEdu1: false,
    ConCVFICredExam: false,
    CVFIConCPAExp: false,
    ExdCVFICredExam: false,
    CVFIExdCPAExp: false,
    FurtherEdu: false,
    AICPAProfStdEdu: false,
    CVFIFinCourse: false,
    CVFICredEdu2: false,
    ABVExam: false,
    ABVQualExams5: false,
    CEIVExam: false,
    CEIVQualExams: false,
    CEIVAICPAEdu: false,
    CEIVExp: false,
    CEIVEdu: false,
    CEIVReinEdu: false,
    CEIVReinExp: false,
    ABVQualExams3: false,
    ABVEdu: false,
    ABVExp: false,
    ABVReinEdu: false,
    CGMAExams: false,
    CGMAExp: false,
    publicProfileVisible: false,
  },
  practicalExperienceRequirement: {
    isPageReadOnly: false,
    employerAlreadyExists: false,
    employmentDataUpdateSuccess: false,
    employmentDataUpdateLoading: false,
    searchResultOrganizations: null,
    searchOrganizationsByWildcardLoading: false,
    searchResultOrganizationsCities: null,
    searchOrganizationsCitiesLoading: false,
    portfolio: {
      id: '',
      name: '',
      aicpaId: '',
      personAccountId: '',
      hasBeenAudited: false,
      status: null,
    },
    formDataEmployment: {
      id: '',
      employer: {
        id: '',
        name: '',
        type: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      jobTitle: '',
      startDate: '',
      endDate: '',
      employmentType: null,
      numberOfDaysPerWeek: '',
      summary: '',
      status: MembershipTypes.PracticalExperienceRequirementStatus.EMPTY,
      submissionDate: '',
      supervisor: {
        id: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        employer: {
          id: '',
          name: '',
          type: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        email: '',
        linkedInUrl: '',
        isCimaAssessor: false,
      },
    },
    newEmployer: {
      personAccountId: '',
      relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
      jobTitle: '',
      organization: {
        id: '',
        name: '',
        type: '',
        creditHoldStatus: false,
        hasAICPAUid: false,
        AICPAUid: '',
        isFirmBillingEnabled: false,
        billingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: 'USA' },
        shippingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: '' },
        phone: '',
        profileEmployerAddress: null,
        profileEmployerPhone: null,
        email: '',
      },
    },
    newEmployment: {
      id: '',
      employer: {
        id: '',
        name: '',
        type: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      jobTitle: '',
      startDate: '',
      endDate: '',
      employmentType: MembershipTypes.PracticalExperienceEmploymentType.FULL_TIME,
      numberOfDaysPerWeek: '',
      summary: '',
      status: MembershipTypes.PracticalExperienceRequirementStatus.EMPTY,
      submissionDate: '',
      supervisor: {
        id: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        employer: {
          id: '',
          name: '',
          type: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        email: '',
        linkedInUrl: '',
        isCimaAssessor: false,
      },
    },
    employment: [],
    newSkills: {
      id: '',
      activityType: null,
      situation: '',
      task: '',
      action: '',
      result: '',
      status: MembershipTypes.PracticalExperienceRequirementStatus.EMPTY,
      submissionDate: '',
      supervisor: {
        id: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        employer: {
          id: '',
          name: '',
          type: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        email: '',
        linkedInUrl: '',
        isCimaAssessor: false,
      },
    },
    skills: [],
    newActivities: {
      id: '',
      activityType: null,
      situation: '',
      task: '',
      action: '',
      result: '',
      status: MembershipTypes.PracticalExperienceRequirementStatus.EMPTY,
      submissionDate: '',
      supervisor: {
        id: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        employer: {
          id: '',
          name: '',
          type: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        email: '',
        linkedInUrl: '',
        isCimaAssessor: false,
      },
    },
    activities: [],
    success: false,
    isResubmit: false,
    perFetchSuccess: false,
  },
  isComingFromPropPage: false,
  isCredentialsJourney: false,
  isRenewalsJourney: false,
  hasSelectedCredential: false,
  hasSelectedSection: false,
  hasSelectedTier: false,
  hasSelectedType: false,
  hasSelectedAddOns: false,
  isFLPSwitch: false,
  isFLPUpgrade: false,
  flpPersonExam: [],
  sectionsCredentialsRenewal: {
    isTriggered: false,
    productType: '',
  },
  renewals: {
    credentials: {
      productId: '',
      sku: '',
    },
    sections: {
      productId: '',
      sku: '',
    },
    membership: {
      productId: '',
      slug: '',
    },
  },
  events: {
    isClickedCredentialsSectionsRenewal: false,
    isClickedMembershipRenewal: false,
    isClickedCimaMembershipRenewalInProfile: false,
    isClickedCimaMembershipRenewalInFeed: false,
    isTriggerCimaResumeApplication: false,
    isMembershipApplicationLogoClicked: false,
    isClickedStartNewApplication: false,
    cimaRehydrateDataHookIsFetched: false,
    isClickedMembershipUpgrade: false,
    isUserLoggedOutClickedApplyMembership: false,
    isClickedMembershipBuyAgain: false,
    isClickedSectionsJourney: false,
    isClickedCenterMembershipJourney: false,
    isClickedFcmaPropPage: false,
    isUpgradeFLPSubscriptionClicked: false,
    isBeginApplicationClicked: false,
  },
  propPageUrl: {
    credential: '',
    section: '',
    variant: '',
  },
  isUpdateApprenticeLevel: false,
  isOpenEmploymentModalFromInvite: true,
  entryLevels: {
    name: '',
    pathwaySlug: '',
  },
  offlineExemptionsEntryLevels: [],
  loggedOutInviteData: {
    lastName: '',
    firstName: '',
    email: '',
    isAlreadyHaveAccount: false,
  },
  hasNotMetMinimumRequirements: false,
  flpModal: {
    isOpen: false,
    modalVariant: '',
  },
  isCimaMembershipPageJourney: {
    isComingFromCimaMembershipPage: false,
    previousPath: '',
  },
  renewalProducts: {
    memberships: [],
    credentials: [],
    sections: [],
  },
  isAllowToAddFcmaInCart: false,
  hasSelectedFcmaDesignation: false,
};

export const LOGIN_IMAGE_KEY = 'login';

export const MEMBERSHIP_TIER_HEADER_COLORS = {
  lightBlue: '#0394d1',
  green: '#31be00',
  darkBlue: '#195fb3',
  purple: '#72246c',
};

export enum MembershipAddOnsProductSlugs {
  ANNUAL_WEBCAST_PASS = 'annual-webcast-pass',
  MICROSOFT_POWER_BI_POWER_BI_SERIES = 'microsoft-power-bi-power-bi-series',
  CPEXPRESS = 'cpexpress',
}

export enum MembershipAddOnsProductSkus {
  ANNUAL_WEBCAST_PASS = 'VSAPXX12',
  MICROSOFT_POWER_BI_POWER_BI_SERIES = '169600',
  CPEXPRESS = 'BYT-XX',
}

export enum MembershipRegularTypeSkus {
  CORE = 'MEMREGSC22',
  ESSENTIAL = 'MEMREGSE22',
  SELECT = 'MEMREGSS22',
  PARTNER_CORE = 'MEMREGPC22',
  PARTNER_SPECIALIST = 'MEMREGPS22',
  PARTNER_ADVANCE = 'MEMREGPA22',
  RETIRED = 'MEMRETS0122',
  REGULAR_TLW = 'MEMREGTLW22',
  EXAM_CANDIDATE = 'MEMCANS0122',
  INT_ASSOCIATE = 'MEMIAS22',
  LEAD = 'MEMREGSL22',
}
