import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { ModalTopicUnfollow } from 'components/organisms/ModalTopicUnfollow';
import { toggleTopicPrefAndUpdateGoldenRecord } from 'modules/user/actions';
import { userSubtopicsPrefSelector } from 'modules/user/selectors';
import { topicsHashSelector } from 'modules/topics/selectors';
import { modalTopicUnfollowMetaSelector } from 'modules/layouts/selectors';
import { toggleModalTopicUnfollow } from 'modules/layouts';
import { emptyArray, getPath } from 'utils';
import { Routes } from 'constants/index';

const mapActionCreators = (dispatch: Dispatch) => ({
  toggleTopicPref(topicId: string): void {
    dispatch(toggleTopicPrefAndUpdateGoldenRecord(topicId, false, { confirmedToUnfollowTopicThatHasSubtopics: true }));
    dispatch(toggleModalTopicUnfollow());
  },

  closeModal(): void {
    dispatch(toggleModalTopicUnfollow());
  },

  goToProfile(): void {
    dispatch(toggleModalTopicUnfollow());
    dispatch(push(getPath(Routes.MY_TOPIC_PREFERENCES)));
  },
});

const mapStateToProps = () => {
  const topicNameSelector = createSelector(
    [topicsHashSelector, modalTopicUnfollowMetaSelector],
    (topicsHash: State.TopicsHash | null, meta: State.ModalTopicUnfollowMeta | null): string => {
      if (!topicsHash || !meta || !meta.topicId || !topicsHash[meta.topicId]) return '';
      return topicsHash[meta.topicId].name || '';
    }
  );

  const subtopicNameSelector = createSelector(
    [topicsHashSelector, modalTopicUnfollowMetaSelector],
    (topicsHash: State.TopicsHash | null, meta: State.ModalTopicUnfollowMeta | null): string => {
      if (!topicsHash || !meta || !meta.topicId || !meta.subtopicId || !topicsHash[meta.topicId]) return '';
      return topicsHash[meta.topicId].subtopics[meta.subtopicId].name || '';
    }
  );

  const subtopicsNamesSelector = createSelector(
    [topicsHashSelector, userSubtopicsPrefSelector, modalTopicUnfollowMetaSelector],
    (
      topicsHash: State.TopicsHash | null,
      userSubtopicsPref: string[],
      meta: State.ModalTopicUnfollowMeta | null
    ): string[] => {
      if (!topicsHash || !meta || !meta.topicId || !topicsHash[meta.topicId]) return emptyArray;
      return userSubtopicsPref.reduce((acc: string[], id: string) => {
        const subtopic: State.Subtopic = topicsHash[meta.topicId].subtopics[id];
        if (subtopic && subtopic.name) acc.push(subtopic.name);
        return acc;
      }, []);
    }
  );

  return (state: State.Root) => {
    const meta: State.ModalTopicUnfollowMeta | null = modalTopicUnfollowMetaSelector(state);
    const topicId: string | null = meta && meta.topicId;
    const subtopicId: string | null = meta && meta.subtopicId;
    const isLastItem: boolean = Boolean(meta && meta.isLastItem);
    const subtopicsNames: string[] = !subtopicId ? subtopicsNamesSelector(state) : emptyArray;

    return {
      topicId,
      topicName: topicNameSelector(state),
      subtopicName: subtopicId ? subtopicNameSelector(state) : '',
      subtopicsNames,
      isLastItem,
    };
  };
};

export const ModalTopicUnfollowContainer = connect(mapStateToProps, mapActionCreators)(ModalTopicUnfollow);
