import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { resetModule, fetchLandingPageContent, resetSkipLandingPageFrontloadRequest } from './actions';
import { isServer } from 'utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchLandingPageContent]: {
    next: (state: State.LandingPagesContent, action: any): State.LandingPagesContent => ({
      ...state,
      landingPage: action.payload.getLandingPage,
      landingPageFetched: true,
      skipLandingPageFrontloadRequest: isServer,
    }),
  },

  [resetSkipLandingPageFrontloadRequest]: (state: State.LandingPagesContent): State.LandingPagesContent => ({
    ...state,
    skipLandingPageFrontloadRequest: false,
  }),

  [resetModule]: (): State.LandingPagesContent => initialState,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
