import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Input } from 'components/atoms';
import { DefaultInlineLinkStyles } from 'components/atoms/Link/Link';

export interface ForgotEmail {
  idNumber: string;
  lastName: string;
}

interface ForgotEmailProps {
  forgotEmailForm: ForgotEmail;
  setForgotEmail: ({ idNumber, lastName }: ForgotEmail) => void;
  setFormValid: (isValid: boolean) => void;
  handleHelpModalOpen: () => void;
}
const L_NAME_FIELD_ID = 'last-name';
const ID_NAME_FIELD_ID = 'id-number';
const ID_LENGTH = 9;

const validateIdNumberFormat = (idNum: string) => idNum.length === ID_LENGTH;

export const ForgotEmailForm: React.FC<ForgotEmailProps> = ({
  forgotEmailForm,
  setForgotEmail,
  setFormValid,
  handleHelpModalOpen,
}) => {
  React.useEffect(() => {
    const isIdNumberValid: boolean = validateIdNumberFormat(forgotEmailForm.idNumber);
    const isLastNameValid: boolean = Boolean(forgotEmailForm.lastName?.length);
    const isFormValid = isIdNumberValid && isLastNameValid;
    setFormValid(isFormValid);
  }, [setFormValid, forgotEmailForm]);

  const handleIdNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    const regNum = /^\d+$/;
    const isNum = regNum.test(val);
    const isNotTooLong = val?.length <= ID_LENGTH;
    if ((isNum || !val) && isNotTooLong) {
      setForgotEmail({ ...forgotEmailForm, idNumber: event.target.value });
    }
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForgotEmail({ ...forgotEmailForm, lastName: event.target.value });
  };

  const showHelpModal = useCallback(() => handleHelpModalOpen(), [handleHelpModalOpen]);

  return (
    <>
      <StyledInput
        fluid
        labelName="Account number"
        name={L_NAME_FIELD_ID}
        value={forgotEmailForm.idNumber}
        testId={L_NAME_FIELD_ID}
        labelId={L_NAME_FIELD_ID}
        autoComplete="off"
        type="tel"
        onChange={handleIdNumberChange}
      />
      <StyledFindAICPA data-testid="find-number-link" onClick={showHelpModal}>
        Find your account number
      </StyledFindAICPA>

      <StyledInput
        fluid
        labelName="Last name"
        name={ID_NAME_FIELD_ID}
        type="text"
        value={forgotEmailForm.lastName}
        testId={ID_NAME_FIELD_ID}
        labelId={ID_NAME_FIELD_ID}
        autoComplete="off"
        onChange={handleLastNameChange}
      />
    </>
  );
};

const StyledFindAICPA = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  margin-bottom: ${props => props.theme.pxToRem(24)};
  cursor: pointer;

  ${DefaultInlineLinkStyles};
`;

const StyledInput = styled(Input)`
  margin-bottom: ${props => props.theme.pxToRem(8)};
  max-width: ${props => props.theme.pxToRem(360)};
`;
