import { handleActions } from 'redux-actions';
import { getMegaMenu } from './actions';
import { emptyArray } from 'utils/index';

const initialState: State.Header = { megamenu: emptyArray, megamenuFetched: false };

const ACTION_HANDLERS = {
  [getMegaMenu]: {
    next: (state: State.Header, action: any): State.Header => ({
      ...state,
      megamenu: action.payload.megamenu,
      megamenuFetched: true,
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
