import React from 'react';

interface Edit {
  size?: number;
  color?: string;
  className?: string;
}

export const Edit = React.memo<Edit>(({ size = 24, color = '#FFFFFF', className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5 2.49632C18.8978 2.0985 19.4374 1.875 20 1.875C20.5626 1.875 21.1022 2.0985 21.5 2.49632C21.8978 2.89415 22.1213 3.43371 22.1213 3.99632C22.1213 4.55893 21.8978 5.0985 21.5 5.49632L12 14.9963L8 15.9963L9 11.9963L18.5 2.49632Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
));
