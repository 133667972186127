// ------------------------------------
// Constants
// ------------------------------------

export const phoneNumberWithDialCodeState: State.ContactPointPhone = {
  id: '',
  phoneNumber: '',
  countryCode: '',
  isNew: false,
  areaCode: '',
  extension: '',
  isPrimary: false,
  phoneType: '',
};

export const practiceState: State.PracticeInfo = {
  id: '',
  practiceName: '',
  isPrimaryPractice: false,
  practiceFax: '',
  practiceWebsite: '',
  totalStaffNum: '',
  practiceTelNumbers: [phoneNumberWithDialCodeState],
  address: {
    id: '',
    firstName: '',
    lastName: '',
    company: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    addressType: '',
    lat: '',
    long: '',
    isPrimary: false,
    loading: false,
  },
  isCompleteContact: false,
  isCompletePracticeRequirement: false,
};

export const practiceRequirementState: State.MipPracticeRequirement = {
  id: '',
  name: '',
  personAccountKey: '',
  website: '',
  primaryPractice: false,
  practiceName: '',
  bodyName: '',
  functionalSpecialism: '',
  totalOfStaff: '',
  brokerInsurerName: '',
  policyNumber: '',
  expiryDate: '',
  fax: '',
  nominatedPerson: false,
  mobileNumbers: [phoneNumberWithDialCodeState],
  isCompleteContact: false,
  isCompletePracticeRequirement: false,
  address: {
    id: '',
    firstName: '',
    lastName: '',
    company: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    addressType: '',
    lat: '',
    long: '',
    isPrimary: false,
    loading: false,
  },
  phones: [phoneNumberWithDialCodeState],
};

export const contactDetails: State.MipContact = {
  id: '',
  accountId: '',
  parentId: '',
  firstName: '',
  lastName: '',
  telNumbers: [] as State.ContactPointPhone[],
  mobileNumbers: [] as State.ContactPointPhone[],
  practiceList: [practiceRequirementState],
};

export const initialMipWorkHistory: State.MipWorkHistory = {
  id: '',
  furtherInformation: '',
  refereeName: '',
  telephone: '',
  emailAddress: '',
  jobTitle: '',
  employer: '',
  yearsQualified: '',
  yearsRefereeKnowYou: '',
  isWorkHistoryComplete: false,
  additionalTelephone: '',
};
export const initialMipAml: State.MipAMLReturn = {
  isCompleteAml: '',
  id: '',
  recordTypeId: '',
  countryOfPractice: '',
  registeredAMLCTF: '',
  criminalityCheck: '',
  beneficialOwners: '',
  nameOfBeneficial: '',
  position: '',
  servicesRegulated: '',
  amlCTFSupervisor: '',
  residentOverseas: '',
  beneficialOfficers: '',
  businessType: '',
  businessRules: '',
  registrationNumber: '',
  sizeofClientBase: '',
  numberOfStaff: '',
  annualTurnover: '',
  franchise: '',
  franchiseName: '',
  singleMultipleOffice: '',
  partnersInPractice: '',
  terroristFinancingThreats: '',
  companies: '',
  riskWithClient: '',
  clientBaseComposed: '',
  clientTypeOrService: '',
  clientType: '',
  clientsRejected: '',
  cddProcesses: '',
  electronicProviders: '',
  specifyObligations: '',
  cddInformation: '',
  financialServices: '',
  typesOfIndustries: '',
  clientsBusiness: '',
  changesInRisk: '',
  practiceProvides: '',
  higherRiskClients: '',
  trainingUndertaken: '',
  trainingProvidedToStaff: '',
  offshoreTCSP: '',
  cimaMoneyLaundering: '',
  disclosuresHotline: '',
  launderingOfficer: '',
  amlAssessments: '',
  suspiciousActivity: '',
  supervisor: '',
  specifyCountry: '',
  serviceInUK: '',
  offerInUK: '',
  supervisedAMLCTF: '',
  theSupervisor: '',
  officeAndLocation: '',
  systemAndControl: '',
  directorName: '',
  regulatedServices: '',
  numberOfCompanies: '',
  registrationName: '',
  numberOfClients: '',
  numSARSPracticeMade: '',
};

export const mipState: State.MIP = {
  // TODO: add initial state for work history, and aml
  contactDetails,
  practiceRequirement: practiceRequirementState,
  workHistory: initialMipWorkHistory,
  amlReturn: initialMipAml,
  isRenewalSeason: false,
  loading: false,
};

export const MipAMLAttestationInitialState: State.MipAMLAttestationInitialState = {
  Id: '',
  Name: '',
  RecordTypeId: '',
  SFS_ATT_AMLApplicationKey__c: '',
  SFS_ATT_AccountAttestedForKey__c: '',
  SFS_ATT_ApplicationPartKey__c: '',
  SFS_ATT_AttestationCreatedByKey__c: '',
};

export const initialStateContact: State.MipContactReference = {
  contactReference: { contact: [], accountDetails: {} },
  contactReferenceFetched: false,
  contactReferenceFrontloadRequest: false,
};

export const MipAttestationInitialState: State.MipAttestationInitialState = {
  Id: '',
  Name: '',
  RecordTypeId: '',
  SFS_ATT_AMLApplicationKey__c: '',
  SFS_ATT_AccountAttestedForKey__c: '',
  SFS_ATT_ApplicationPartKey__c: '',
  SFS_ATT_AttestationCreatedByKey__c: '',
};

export const attestationPracticeRequirementInitialState: State.AttestationPracticeRequirementReference = {
  attestationPracticeReference: [],
  attestationPracticeFetched: false,
  attestationPracticeFrontloadRequest: false,
};

export const mipStateRenewal: State.MIP = {
  contactDetails,
  practiceRequirement: practiceRequirementState,
  workHistory: initialMipWorkHistory,
  amlReturn: initialMipAml,
  isRenewalSeason: true,
  loading: false,
};
