import React, { useCallback, useState, useEffect } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { getPath } from 'utils';
import { Invoices, ZuoraTypes } from 'mxp-schemas';

import { InvoicesTable } from 'components/organisms/InvoicesTable/InvoicesTable';
import { InvoicesTab } from 'components/molecules/InvoicesTab/InvoicesTab';
import {
  Link,
  NotificationBanner,
  NotificationBannerEnums,
  TablePlaceholder,
  Divider,
  OnlyMobile,
  Label,
  Button,
} from 'components/atoms';
import { PromotionalBanner, ProfileHeading, DropdownWithLabel } from 'components/molecules';
import { ReactComponent as ErrorComponent } from 'resources/images/ic-error.svg';
import { Routes } from 'constants/index';
import { useDispatch } from 'react-redux';
import { selectMultipleInvoice, setLegalEntity } from 'modules/admin/actions';
import { ReactComponent as Cancel } from 'resources/images/ic-cancel.svg';

interface Props extends RouteComponentProps {
  error: CustomErrors.GraphQLError | null;
  invoicesListFetched: boolean;
  dueInvoicesList: State.InvoiceTableRow[] | null;
  paidInvoicesList: State.InvoiceTableRow[] | null;
  downloadId: string | null;
  downloadError: boolean;
  isB2C?: boolean;
  getInvoices: (legalEntity: ZuoraTypes.LegalEntity) => void;
  getInvoiceFile: (id: string) => void;
  resetModule: () => void;
  navigate: (path: string) => void;
  pagination: {
    [key in Invoices.InvoiceKeys]: State.Pagination;
  };
  setPagination?: ({ type, modifier: { offset } }: { type: string; modifier: { offset: number } }) => void;
}

export const OrganizationInvoices: React.FC<Props> = ({
  error,
  downloadId,
  downloadError,
  invoicesListFetched = false,
  dueInvoicesList = [],
  paidInvoicesList = [],
  isB2C = false,
  // RouteProps
  location,
  // Actions
  getInvoices,
  getInvoiceFile,
  resetModule,
  navigate,
  pagination,
  setPagination,
}) => {
  // local tab state setup
  const [activeTab, setTab] = React.useState(Invoices.InvoiceKeys.DUE_NOW);
  const [selectedInvoice, setSelectedInvoice] = useState<string[]>([]);
  const [selectedInvoiceId, setInvoiceId] = useState<string>('');
  const [dropdownLegalEntity, setDropdownLegalEntity] = useState(ZuoraTypes.LegalEntity.ASSOCIATION);
  const [dueInvoicesListProps, setDueInvoicesListProps] = useState(dueInvoicesList);
  const [paidInvoicesListProps, setPaidInvoicesListProps] = useState(paidInvoicesList);

  const dispatch = useDispatch();

  const isDueTab = Boolean(activeTab === Invoices.InvoiceKeys.DUE_NOW);
  const isPaidTab = Boolean(activeTab === Invoices.InvoiceKeys.PAYMENT_HISTORY);
  const isInvoiceSelected = Boolean(selectedInvoice.length === 0);
  const { offset: dueOffset, limit: dueLimit } = pagination[Invoices.InvoiceKeys.DUE_NOW];
  const { offset, limit } = pagination[Invoices.InvoiceKeys.PAYMENT_HISTORY];

  useEffect(() => {
    dispatch(setLegalEntity(ZuoraTypes.LegalEntity.ASSOCIATION));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDueInvoicesListProps([...(dueInvoicesList ?? [])]?.splice(dueOffset, dueLimit));
  }, [dueOffset, dueLimit, dueInvoicesList]);

  useEffect(() => {
    setPaidInvoicesListProps([...(paidInvoicesList ?? [])]?.splice(offset, limit));
  }, [offset, limit, paidInvoicesList]);

  useEffect(() => {
    getInvoices(dropdownLegalEntity);
    return resetModule;
  }, [getInvoices, resetModule, dropdownLegalEntity]);

  const handleDownloadFileClick = React.useCallback(
    (invoiceId: string) => {
      if (downloadId && !downloadError) return;
      getInvoiceFile(invoiceId);
    },
    [getInvoiceFile, downloadId, downloadError]
  );

  const handlePayClick = React.useCallback(
    (invoiceId: string) => {
      navigate(
        generatePath(getPath(Routes.ORG_INVOICE_CHECKOUT), {
          invoiceId,
        })
      );
    },
    [navigate]
  );

  const handleTabChange = React.useCallback((name: Invoices.InvoiceKeys) => {
    setTab(name);
  }, []);

  const selectInvoiceHandler = useCallback(
    (checked: boolean, invoiceId: string) => {
      let list = [...selectedInvoice];
      if (checked) {
        setSelectedInvoice((prevState: string[]) => [...prevState, invoiceId]);
        setInvoiceId(invoiceId);
        list = [...list, invoiceId];
        dueInvoicesList?.map((invoice: any) => {
          if (invoice.id === invoiceId) invoice.isSelected = true;
        });
      } else {
        list = list.filter(id => id !== invoiceId);
        dueInvoicesList?.map((invoice: any) => {
          if (invoice.id === invoiceId) invoice.isSelected = false;
        });
      }

      setSelectedInvoice(list);
      dispatch(selectMultipleInvoice(list));
    },
    [dispatch, selectedInvoice, dueInvoicesList]
  );

  const handleClearSelection = useCallback(() => {
    // dispatch(setDataAction({ type, modifier: { selectedIds: [] } }));
    dueInvoicesList?.map((invoice: any) => {
      invoice.isSelected = false;
    });
    setSelectedInvoice([]);
  }, [dueInvoicesList]);

  const buildInvoicesBlock = (): React.ReactNode => {
    if (isPaidTab) {
      return paidInvoicesList?.length ? (
        <>
          <InvoicesTable
            setPagination={setPagination}
            offset={offset}
            limit={limit}
            usePagination
            itemCount={paidInvoicesList?.length}
            tableName={activeTab}
            invoices={paidInvoicesListProps}
            handleDownloadClick={handleDownloadFileClick}
            handlePayClick={handlePayClick}
            downloadId={downloadId}
            downloadError={downloadError}
          />
          <InvoicesBanner isFirstTab={!isPaidTab} hasInvoices />
        </>
      ) : (
        <InvoicesBanner isFirstTab={!isPaidTab} />
      );
    }

    const handleDropdownChange = (e: any, data: any) => {
      setDropdownLegalEntity(data.value);
      dispatch(setLegalEntity(data.value));
    };

    const legalEntityOptions = Object.values(ZuoraTypes.LegalEntity).map((value: string, index) => {
      return { key: index, value, text: value };
    });

    return dueInvoicesList?.length ? (
      <div>
        <StyledDiv>
          <StyledLabel>Status</StyledLabel>
          <StyledDropdown
            testId="legal-entity-dropdown"
            options={legalEntityOptions}
            placeholder={dropdownLegalEntity}
            onChange={handleDropdownChange}
            selected={dropdownLegalEntity || ''}
          />
        </StyledDiv>

        <InvoicesTable
          setPagination={setPagination}
          offset={dueOffset}
          limit={dueLimit}
          usePagination
          itemCount={dueInvoicesList?.length}
          tableName={activeTab}
          invoices={dueInvoicesListProps}
          handleDownloadClick={handleDownloadFileClick}
          handlePayClick={handlePayClick}
          downloadId={downloadId}
          downloadError={downloadError}
          handleInvoiceSelect={selectInvoiceHandler}
        />
        <InvoicesBanner isFirstTab={isDueTab} hasInvoices />

        <StickyFooter>
          <StyledInvoiceLabel>
            {isInvoiceSelected ? 'Select Invoices to Pay' : ` You selected ${selectedInvoice.length} invoice/s`}
          </StyledInvoiceLabel>
          <StyledCancelSectionWrapper>
            {isInvoiceSelected ? null : (
              <StyledCancelSectionWrapper onClick={handleClearSelection}>
                <StyledCancelIcon />
                <StyledCancelLabel>Cancel selection</StyledCancelLabel>
              </StyledCancelSectionWrapper>
            )}

            <StyledPayButton
              disabled={isInvoiceSelected}
              testId="pay-invoice-btn"
              // tslint:disable-next-line jsx-no-lambda
              onClick={() => handlePayClick(selectedInvoiceId)}
              isInvoiceSelected={isInvoiceSelected}
            >
              Pay
            </StyledPayButton>
          </StyledCancelSectionWrapper>
        </StickyFooter>
      </div>
    ) : (
      <>
        <StyledDiv>
          <StyledLabel>Status</StyledLabel>
          <StyledDropdown
            testId="legal-entity-dropdown"
            options={legalEntityOptions}
            placeholder={dropdownLegalEntity}
            onChange={handleDropdownChange}
            selected={dropdownLegalEntity || ''}
          />
        </StyledDiv>

        <InvoicesBanner isFirstTab={isDueTab} />
      </>
    );
  };

  return (
    <>
      <StyledProfileHeading title="Invoices" />
      <>
        <InvoicesTab activeTab={activeTab} setTab={setTab} location={location} handleTabChange={handleTabChange} />
        <OnlyMobile>
          <MobileDivider />
        </OnlyMobile>
      </>
      {error ? (
        <StyledNotificationBanner
          testId="error-contracts"
          childrenTestId="error-contracts-text"
          variant={NotificationBannerEnums.variant.red}
          icon={<ErrorComponent />}
        >
          Your invoices download was interrupted.&nbsp;
        </StyledNotificationBanner>
      ) : (
        <StyledTableBlock>{invoicesListFetched ? buildInvoicesBlock() : <TablePlaceholder />}</StyledTableBlock>
      )}
    </>
  );
};

const InvoicesBanner: React.FC<{ isFirstTab: boolean; hasInvoices?: boolean }> = ({ isFirstTab, hasInvoices }) => {
  const textBlock = React.useMemo(() => {
    const phoneContact = '800-634-6780';
    const emailContact = 'client.support@aicpa-cima.com';
    const noInvoiceTitle = isFirstTab
      ? 'You currently have no payments due'
      : `You haven't made a payment on our new site`;
    const title = hasInvoices ? `Need help with ${isFirstTab ? 'an invoice' : 'your group order'}?` : noInvoiceTitle;
    const noInvoiceDescription = isFirstTab
      ? 'To get help with your invoice or with any issues, please contact our dedicated group sales team at'
      : 'Need help with your group order? Contact our dedicated group sales team at';
    const description = hasInvoices ? 'Contact our dedicated group sales team at' : noInvoiceDescription;
    return {
      title,
      phoneContact,
      emailContact,
      description,
    };
  }, [isFirstTab, hasInvoices]);

  return (
    <PromotionalBanner
      title={textBlock.title}
      description={
        <>
          {textBlock.description}{' '}
          <Link isExternal testId="ei-question-us-phone" to={`tel:${textBlock.phoneContact}`}>
            {textBlock.phoneContact}
          </Link>
          , option 1, or{' '}
          <Link isExternal testId="ei-question-us-email" to={`mailto:${textBlock.emailContact}`}>
            {textBlock.emailContact}
          </Link>
          . We’re open 8am - 5pm Eastern (US time).
        </>
      }
      testId="invoices-banner"
      titleTestId="invoices-banner-title"
      descriptionTestId="invoices-banner-description"
      isMobileFullWidth
      showBackground
      fromInvoicePage={true}
    />
  );
};

const StyledProfileHeading = styled(ProfileHeading)`
  margin-bottom: ${props => props.theme.pxToRem(8)};
`;

const StyledTableBlock = styled.div`
  margin-top: ${props => props.theme.pxToRem(32)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: 0;
  }
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  margin-top: ${props => props.theme.pxToRem(40)};
`;

const MobileDivider = styled(Divider)`
  &&&& {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
`;

const StickyFooter = styled.div`
  z-index: 1;
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: ${props => props.theme.pxToRem(100)};
  left: 0;
  bottom: 0;
  right: 0;
  padding-right: ${props => props.theme.pxToRem(110)};
  border-top: 1px solid ${props => props.theme.colors.neutralGrey3};
  background: ${props => props.theme.colors.neutralGrey1};
  align-items: center;
`;

const StyledDropdown = styled(DropdownWithLabel)`
  &&&&&&&& {
    padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(15)};
    width: ${props => props.theme.pxToRem(180)};
    height: ${props => props.theme.pxToRem(46)};
    display: flex;
    color: ${props => props.theme.colors.primaryPurple};
    border: ${props => props.theme.colors.neutralBlack} ${props => props.theme.pxToRem(1)} solid;
    border-radius: ${props => props.theme.pxToRem(8)};
    margin-left: ${props => props.theme.pxToRem(15)};
    .divider.text {
      color: ${props => props.theme.colors.primaryPurple}!important;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.pxToRem(40)};
`;

const StyledLabel = styled(Label)`
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledInvoiceLabel = styled(Label)`
  font-weight: ${props => props.theme.fontWeights.light};
  padding-left: ${props => props.theme.pxToRem(60)};
  font-size: ${props => props.theme.fontSizes.l};
`;

const StyledPayButton = styled(Button)<{ isInvoiceSelected: boolean }>`
  &&&& {
    justify-content: center;
    min-width: ${props => props.theme.pxToRem(146)};
    height: ${props => props.theme.pxToRem(48)};
    color: ${props => props.theme.colors.neutralWhite};
    background-color: ${props => (props.isInvoiceSelected ? '' : props.theme.colors.primaryPurple)};
  }
`;

const StyledCancelLabel = styled(Label)`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledCancelIcon = styled(Cancel)`
  color: ${props => props.theme.colors.primaryPurple};
`;

const StyledCancelSectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
