import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Content, User, Contentful as ContentItem } from 'mxp-schemas';
import { User as UserUtils, RichTextBlocks } from 'mxp-utils';
import { Popup } from 'semantic-ui-react';
import { Lock } from 'components/atoms/svg';
import { FeedTargetableLink, OnlyDesktop, OnlyMobile, ButtonEnums, Link, Button } from 'components/atoms';
import { ReactComponent as IconUnlock } from 'resources/images/ic-lock-off.svg';
import { emptyArray, getPath } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticationModalVisibleSelector } from 'modules/layouts/selectors';
import { showLogin, showRegistration } from 'modules/layouts';
import { UserMemberTypes } from 'modules/user/constants';
import { generatePath } from 'react-router';
import { Routes } from '../../../constants';
import { accessRestrictionsHashSelector, credentialsHashSelector } from 'modules/startup/selectors';
interface Props {
  isLocked: string;
  contentRoles: any[];
  matchedRoles: any[];
  align?: ContentLockAlign;
  testId?: string;
  isTextHidden?: boolean;
  userStatus: UserMemberTypes;
  restrictionDetails?: Content.RestrictionDetails[];
  topicalSubscriptions?: ContentItem.TopicalSubscriptionFields[];
}

enum ConnectorType {
  and = 'and',
  or = 'or',
}

export enum ContentLockAlign {
  left = 'left',
  right = 'right',
}

const PopupPosition = {
  left: 'right center',
  right: 'left center',
  leftMobile: 'bottom left',
  rightMobile: 'bottom right',
};

interface LockedExtendedDetails extends UserUtils.SectionMembership {
  role: User.MembershipIdsEnum;
}

export const ContentLock: React.FC<Props> = React.memo(
  ({
    isLocked,
    align = ContentLockAlign.left,
    testId = 'lock-content',
    contentRoles,
    matchedRoles,
    isTextHidden = false,
    userStatus,
    restrictionDetails = [],
    topicalSubscriptions = [],
  }) => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(isAuthenticationModalVisibleSelector);
    const accessRestrictionsHash = useSelector(accessRestrictionsHashSelector);
    const credentialsHash = useSelector(credentialsHashSelector);

    const handleLoginClick = useCallback(() => {
      dispatch(showLogin({ redirectToLoginPage: false }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRegistrationClick = useCallback((event: React.SyntheticEvent<HTMLElement>) => {
      event.preventDefault();

      dispatch(showRegistration({ redirectToLoginPage: false }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const aicpaMemberRole: UserUtils.SuppressContentMembership =
      UserUtils.contentSuppressMembershipMap[User.MembershipIdsEnum.MRUSR0001];
    const cimaMemberRole: UserUtils.SuppressContentMembership =
      UserUtils.contentSuppressMembershipMap[User.MembershipIdsEnum.MRUKR0001];
    const getRoleRestrictionDetail = (link: string, role: User.MembershipIdsEnum | string) => {
      if (restrictionDetails && restrictionDetails.length) {
        const restrictionDetail: Content.RestrictionDetails | undefined = restrictionDetails.find(
          (detail: Content.RestrictionDetails) => detail.role === role
        );
        if (restrictionDetail?.learnMoreUrl) {
          return restrictionDetail?.learnMoreUrl;
        }
        return link;
      }
      return link;
    };
    const transformedRoleList = contentRoles?.reduce((acc: UserUtils.SuppressContentMembership[], role: string) => {
      const transformedRole = UserUtils.contentSuppressMembershipMap[role];
      if (transformedRole) {
        transformedRole.link = accessRestrictionsHash[role]
          ? accessRestrictionsHash[role].propPageLink
          : transformedRole.link;
        if (role === User.MembershipIdsEnum.MRUSR0001) {
          transformedRole.title = aicpaMemberRole.title3;
        }
        if (role === User.MembershipIdsEnum.MRUKR0001) {
          transformedRole.title = cimaMemberRole.title3;
        }
        if (!acc.some(addedRole => addedRole.title === transformedRole.title)) {
          transformedRole.link = getRoleRestrictionDetail(transformedRole.link, role);
          acc.push(transformedRole);
        }
      }
      return acc;
    }, []);

    const titleSelector = (title: string, index: number, arrLength: number, type: ConnectorType) => {
      if (index === 0) {
        return title;
      }
      if (index === arrLength - 1) {
        return ` ${type} ${title}`;
      }
      if (index < arrLength) {
        return `, ${title}`;
      }
    };

    const isContentForLoggedIn: boolean = contentRoles?.[0] === User.MembershipIdsEnum.LOGGED_IN;

    const renderTitle = () => {
      if (isContentForLoggedIn) {
        return [aicpaMemberRole.title];
      }

      if (topicalSubscriptions?.length) {
        return topicalSubscriptions[0].name;
      }

      return transformedRoleList
        .slice(0, 3)
        .map((role: UserUtils.SuppressContentMembership, index: number, arr: UserUtils.SuppressContentMembership[]) =>
          titleSelector(role.title2, index, arr.length, ConnectorType.or)
        )
        .join('');
    };
    const renderWithPrefix = () => {
      const prefixA: string = 'a';
      const prefixAn: string = 'an';
      const prefixHaveThe: string = 'have the';

      if (topicalSubscriptions?.length) {
        return `${prefixHaveThe} ${renderTitle()}`;
      }

      const isFVSorNFP: boolean =
        transformedRoleList[0]?.title === 'FVS Section' || transformedRoleList[0]?.title === 'NFP Section';
      return `${isFVSorNFP ? prefixAn : prefixA} ${renderTitle()}`;
    };

    const renderLearnMoreList = () => {
      if (!isContentForLoggedIn) {
        if (topicalSubscriptions?.length) {
          return topicalSubscriptions.map((item, index, arr) => {
            const link = generatePath(getPath(Routes.PRODUCT_PAGE), {
              productTypeSlug: item.productType,
              slug: item.productSlug,
            });
            const isExternal = RichTextBlocks.isExternal(link);

            return (
              item.productSlug && (
                <FeedTargetableLink
                  key={`${item.name}${index}`}
                  link={link}
                  testId={item.name}
                  title={`OR Learn more about the ${item.name} credential`}
                  isNoMargin={index === arr.length - 1}
                  iconPurple={isExternal}
                />
              )
            );
          });
        }

        const newList: UserUtils.SuppressContentMembership[] = transformedRoleList;
        return newList.map((item, index, arr) => {
          const isExternal = RichTextBlocks.isExternal(item.link);
          return (
            item.link && (
              <FeedTargetableLink
                key={`${item.title}${index}`}
                link={item.link}
                testId={item.title}
                iconPurple={isExternal}
                title={`OR Learn more about ${item.title}`}
                isNoMargin={index === arr.length - 1}
              />
            )
          );
        });
      }
      return null;
    };

    const assignRoles = (
      hasLocked: string,
      matchedRolesArr: User.MembershipIdsEnum[],
      contentRolesArr: User.MembershipIdsEnum[]
    ): { [key: string]: LockedExtendedDetails } => {
      // checks if there are any roles for unlocked state
      const noRolesForUnlockState: boolean =
        hasLocked === Content.ContentLockStatus.UNLOCKED && (!matchedRolesArr || !matchedRolesArr.length);
      // checks if there are any roles for locked state
      const noRolesForLockState: boolean =
        hasLocked === Content.ContentLockStatus.LOCKED && (!contentRolesArr || !contentRolesArr.length);
      if (noRolesForUnlockState || noRolesForLockState) {
        return emptyArray;
      }
      // if lock state is present return related roles in content
      if (hasLocked === Content.ContentLockStatus.LOCKED) {
        return contentRolesArr.reduce((arr: { [key: string]: LockedExtendedDetails }, role: User.MembershipIdsEnum) => {
          const roleDetails: UserUtils.SectionMembership = UserUtils.PremiumContentMap[role];
          const roleKey = UserUtils.searchKeysForPremiumContentFilter[role];
          if (roleDetails?.link) {
            const link: string = credentialsHash[role] ? credentialsHash[role].propPageLink : roleDetails.link;
            roleDetails.link = getRoleRestrictionDetail(link, role);
          }
          if (UserUtils.PremiumContentMap[role] && !arr[roleKey]) {
            return {
              ...arr,
              [roleKey]: { ...roleDetails, role },
            };
          }
          return arr;
        }, {});
      }
      // if unlock state is present return related roles in matched roles between user and content
      return matchedRolesArr.reduce((arr: { [key: string]: LockedExtendedDetails }, role: User.MembershipIdsEnum) => {
        const roleKey = UserUtils.searchKeysForPremiumContentFilter[role];
        const roleDetails: UserUtils.SectionMembership = UserUtils.PremiumContentMap[role];
        if (roleDetails?.link) {
          const link: string = credentialsHash[role] ? credentialsHash[role].propPageLink : roleDetails.link;
          roleDetails.link = getRoleRestrictionDetail(link, role);
        }
        if (
          UserUtils.PremiumContentMap[role] &&
          role !== User.MembershipIdsEnum.MRUSR0001 &&
          role !== User.MembershipIdsEnum.MRUKR0001 &&
          !arr[roleKey]
        ) {
          return {
            ...arr,
            [roleKey]: { ...roleDetails, role },
          };
        }
        return arr;
      }, {});
    };

    const filteredRolesDetailsMap: {
      [key: string]: LockedExtendedDetails;
    } = assignRoles(isLocked, matchedRoles, contentRoles);

    const renderHeader = (hasLocked: string) => {
      if (hasLocked === Content.ContentLockStatus.UNLOCKED) {
        return null;
      }
      return (
        <StyledHeader>
          <IconUnlock /> This content is exclusive to members
        </StyledHeader>
      );
    };

    const renderBenefitList = (
      sortedRolesKeys: string[],
      rolesMap: { [key: string]: LockedExtendedDetails },
      topicalSubs: ContentItem.TopicalSubscriptionFields[]
    ) => {
      const filteredRolesAligned =
        sortedRolesKeys?.map((role: string) => {
          const topicalSubInFiltered = topicalSubs?.find(topicSub => rolesMap[role].role === topicSub.oktaRoleId);

          if (topicalSubInFiltered) {
            const dynamicLink = generatePath(getPath(Routes.PRODUCT_PAGE), {
              productTypeSlug: topicalSubInFiltered.productType,
              slug: topicalSubInFiltered.productSlug,
            });

            const titleTopical = topicalSubInFiltered.name;
            return { link: dynamicLink, title: titleTopical, role: topicalSubInFiltered.oktaRoleId };
          }

          const roleDetails: LockedExtendedDetails = rolesMap[role];

          const link = getRoleRestrictionDetail(roleDetails.link, roleDetails.role);
          const title = roleDetails.title;
          return { link, title, role };
        }) || [];

      const showOnlyTopicalSubs = userStatus === UserMemberTypes.NONMEMBER && topicalSubs?.length;
      const concatedRoles = showOnlyTopicalSubs
        ? filteredRolesAligned.filter(roleDetail => {
            return UserUtils.onlySubscriptionRoles.includes(roleDetail.role as User.MembershipIdsEnum);
          })
        : filteredRolesAligned;

      return concatedRoles.map((roleData: { link: string; title: string }, index: number, arr) => {
        return (
          <StyleLockedContainer key={`locked-content-list${index}`} isNoMargin={index === arr.length - 1}>
            <StyledTitle>{roleData.title}</StyledTitle>
            <FeedTargetableLink
              testId={`unlocked-content-list-link${index}`}
              title="Learn More"
              link={roleData.link}
              iconPurple
              isNoMargin
            />
          </StyleLockedContainer>
        );
      });
    };

    const renderContent = (hasLocked: string, rolesDetailsMap?: { [key: string]: LockedExtendedDetails }) => {
      if (!rolesDetailsMap) {
        return null;
      }
      const filteredRolesKeys = Object.keys(rolesDetailsMap);
      filteredRolesKeys.sort();

      if (hasLocked === Content.ContentLockStatus.LOCKED) {
        // if user is logged out
        if (userStatus === UserMemberTypes.LOGGED_OUT) {
          return (
            <StyledContent>
              {isContentForLoggedIn
                ? 'This content is free, but you must log in first.'
                : `If you hold the ${renderWithPrefix()} credential, simply log in with your AICPA account.`}
              <StyleLockedContainer centerContent>
                <StyledLoginButton
                  testId="lockedContentRegister"
                  onClick={handleLoginClick}
                  variant={ButtonEnums.variants.primary}
                  size={ButtonEnums.sizes.medium}
                  bordered
                >
                  Log in
                </StyledLoginButton>
                {isContentForLoggedIn && (
                  <>
                    <SmallOr>Or</SmallOr>
                    <StyledRegisterLink testId="lockedContentRegister" to="" onClick={handleRegistrationClick}>
                      Register
                    </StyledRegisterLink>
                  </>
                )}
              </StyleLockedContainer>
              {renderLearnMoreList()}
            </StyledContent>
          );
        }
        // if user don't have sections/credentials/topical subs etc then return the list to unlock content
        // const memberCustomer = userStatus === UserMemberTypes.NONMEMBER ? 'customers' : 'members';
        const heading = `The key to unlocking the content is an AICPA membership`;
        const postfix =
          filteredRolesKeys?.length && topicalSubscriptions?.length && userStatus === UserMemberTypes.MEMBER
            ? 'Join the section or start a subscription to access the content.'
            : '';

        return (
          <StyledContent>
            {heading}
            {renderBenefitList(filteredRolesKeys, rolesDetailsMap, topicalSubscriptions)}
            {postfix}
          </StyledContent>
        );
      }
      if (hasLocked === Content.ContentLockStatus.UNLOCKED) {
        // if user is logged in
        if (matchedRoles.length === 1 && matchedRoles[0] === User.MembershipIdsEnum.LOGGED_IN) {
          return <StyledContent>Welcome back to AICPA.org</StyledContent>;
        }
        // if user has only membership to AICPA then return welcome message
        if (matchedRoles.length === 1 && matchedRoles[0] === User.MembershipIdsEnum.MRUSR0001) {
          return <StyledContent>Thanks for being an AICPA member</StyledContent>;
        }
        if (matchedRoles.length === 1 && matchedRoles[0] === User.MembershipIdsEnum.MRUKR0001) {
          return <StyledContent>Thanks for being a CIMA member</StyledContent>;
        }
        // if user has sections/credentials/topical subs etc then return benefit list
        return (
          <StyledContent>
            You’re seeing this content because you have:
            {filteredRolesKeys.map((role: string, idx: number) => (
              <StyledUnlockedTitle key={`unlocked-content-list${idx}`}>
                {rolesDetailsMap[role].title}
              </StyledUnlockedTitle>
            ))}
          </StyledContent>
        );
      }
    };

    const renderTrigger = () => (
      <StyledLock align={align} isTextHidden={isTextHidden}>
        {isLocked === Content.ContentLockStatus.LOCKED ? (
          <>
            <StyledLockIcon /> {!isTextHidden && 'LOCKED'}
          </>
        ) : (
          <>
            <StyledUnlockIcon /> {!isTextHidden && 'UNLOCKED'}
          </>
        )}
      </StyledLock>
    );

    const renderPopup = (alignVal: string) => (
      <StylePopup
        content={renderContent(isLocked, filteredRolesDetailsMap)}
        key={'user.name'}
        header={renderHeader(isLocked)}
        position={alignVal}
        hoverable
        disabled={isModalOpen}
        trigger={renderTrigger()}
        hideOnScroll
      />
    );
    if (isLocked === Content.ContentLockStatus.NO_LOCK) {
      return <></>;
    }

    return (
      <StyledWrapper align={align} isTextHidden={isTextHidden}>
        <OnlyMobile as="div">
          {renderPopup(align === ContentLockAlign.left ? PopupPosition.leftMobile : PopupPosition.rightMobile)}
        </OnlyMobile>
        <OnlyDesktop as="div">{renderPopup(PopupPosition[align])}</OnlyDesktop>
      </StyledWrapper>
    );
  }
);

const StyledLock = styled.div<{ align: string; isTextHidden: boolean }>`
  ${props => `
    padding-right: ${props.theme.pxToRem(props.isTextHidden ? 0 : 6)};
    font-size: ${props.theme.fontSizes.xxs};
    font-weight: ${props.theme.fontWeights.light};
    line-height: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.22px;
    color: ${props.theme.colors.neutralBlack};
    border-radius: 2px;
  `}
  ${props =>
    props.align === 'left'
      ? css`
          background-color: ${props.theme.colors.neutralWhite};
        `
      : css`
          background-color: ${props.theme.colors.neutralGrey2};
        `}
`;

const StyledWrapper = styled.div<{ align: string; isTextHidden: boolean }>`
  text-align: center;
  position: absolute;
  z-index: 2;
  top: ${props => props.theme.pxToRem(props.isTextHidden ? 0 : 10)};
  ${props =>
    props.align === 'left'
      ? css`
          left: ${props.theme.pxToRem(10)};
        `
      : css`
          right: ${props.theme.pxToRem(20)};
        `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    top: 0;

    ${props =>
      props.align === 'left'
        ? css`
            left: 0;
            padding: ${props.theme.pxToRem(10)};
          `
        : css`
            right: 0;
            padding: ${props.theme.pxToRem(10)} 0;
          `}
  }
`;

const StylePopup = styled(Popup)`
  &&&& {
    padding: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(15)};
    max-width: ${props => props.theme.pxToRem(400)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      max-width: calc(100% - 3em);
    }
  }
`;

const StyledHeader = styled.div`
  font-family: 'Roboto';
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fontSizes.s};
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralGrey8};
  padding-bottom: ${props => props.theme.pxToRem(12)};
  border-bottom: 2px solid ${props => props.theme.colors.neutralGrey3};
  margin-bottom: ${props => props.theme.pxToRem(12)};

  & svg {
    margin-right: ${props => props.theme.pxToRem(6)};
    path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyledContent = styled.div`
  font-family: 'Roboto';
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledTitle = styled.div`
  font-family: 'Roboto'; /* fix for IE */
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-right: ${props => props.theme.pxToRem(10)};
`;

const StyledUnlockedTitle = styled.div`
  font-family: 'Roboto'; /* fix for IE */
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralGrey8};
  padding-top: ${props => props.theme.pxToRem(4)};
`;

const StyleLockedContainer = styled.div<{ centerContent?: boolean; isNoMargin?: boolean }>`
  font-family: 'Roboto'; /* fix for IE */
  display: flex;
  flex-direction: row;
  ${props =>
    !props.isNoMargin &&
    `
      margin-bottom: ${props.theme.pxToRem(12)};
    `}
  ${props =>
    props.centerContent &&
    `
    align-items: center;
    justify-content: flex-start;
  `}

  &:first-of-type {
    padding-top: ${props => props.theme.pxToRem(12)};
  }

  p {
    font-size: ${props => props.theme.pxToRem(12)};
  }
`;

const SmallOr = styled.p`
  margin-top: ${props => props.theme.pxToRem(14)};
  &&&& {
    font-size: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledLockIcon = styled(Lock)`
  height: ${props => props.theme.pxToRem(16)};
  width: ${props => props.theme.pxToRem(16)};
  margin: ${props => props.theme.pxToRem(4)};
`;

const StyledUnlockIcon = styled(IconUnlock)`
  height: ${props => props.theme.pxToRem(16)};
  width: ${props => props.theme.pxToRem(16)};
  margin: ${props => props.theme.pxToRem(4)};
`;

const StyledLoginButton = styled(Button)`
  &&&& {
    font-family: 'Roboto'; /* fix for IE */
    margin-right: ${props => props.theme.pxToRem(12)};
    min-width: ${props => props.theme.pxToRem(103)};
  }
`;

const StyledRegisterLink = styled(Link)`
  &&&& {
    font-family: 'Roboto'; /* fix for IE */
    margin-left: ${props => props.theme.pxToRem(12)};
  }
`;
