import React from 'react';
import styled from 'styled-components';
import { DetailRowWrap, DetailHead, DetailContent } from './ProductItemDetails';
import { ConferenceSupportEmail } from 'constants/index';
import { Checkout } from 'mxp-schemas';
import { ButtonLink } from 'components/atoms';

interface Props {
  conferenceUserInfo?: Checkout.ConferenceUserInfo | null;
  isOnsiteConference: boolean;
  isOrderConfirmationPage: boolean;
  dateRange: string | null;
  location: string | null;
  isEventInNext48Hours: boolean;
  isProfilePurchasesPage: boolean;
}

export const ConferenceProductDetails: React.FC<Props> = ({
  conferenceUserInfo,
  isOnsiteConference,
  isOrderConfirmationPage,
  dateRange,
  location,
  isEventInNext48Hours,
  isProfilePurchasesPage,
}) => {
  return (
    <>
      {dateRange && (
        <DetailRowWrap>
          <DetailHead>Date</DetailHead>
          <DetailContent>{dateRange}</DetailContent>
        </DetailRowWrap>
      )}
      {location && (
        <DetailRowWrap>
          <DetailHead>Location</DetailHead>
          <DetailContent>{location}</DetailContent>
        </DetailRowWrap>
      )}
      {!isEventInNext48Hours && isProfilePurchasesPage && (
        <DetailRowWrap>
          <DetailHead>Updates</DetailHead>
          <DetailContent>
            Additional details may be sent to your inbox from ‘AICPAConferences.com Support’
            <StyledPhoneLink isTextLink testId="email-link" to={`mailto:${ConferenceSupportEmail.CONF_SUPPORT}`}>
              ({ConferenceSupportEmail.CONF_SUPPORT}).
            </StyledPhoneLink>
          </DetailContent>
        </DetailRowWrap>
      )}
      {isOrderConfirmationPage && (
        <>
          {conferenceUserInfo?.companyName && (
            <DetailRowWrap>
              <DetailHead>Company name</DetailHead>
              <DetailContent>{conferenceUserInfo.companyName}</DetailContent>
            </DetailRowWrap>
          )}
          {conferenceUserInfo?.jobTitle && (
            <DetailRowWrap>
              <DetailHead>Job title</DetailHead>
              <DetailContent>{conferenceUserInfo.jobTitle}</DetailContent>
            </DetailRowWrap>
          )}
          <DetailRowWrap>
            <DetailHead>Aid request</DetailHead>
            <DetailContent>{conferenceUserInfo?.adaRequirements?.join('; ') || 'No aid request'}</DetailContent>
          </DetailRowWrap>
          {isOnsiteConference && (
            <DetailRowWrap>
              <DetailHead>Dietary request</DetailHead>
              <DetailContent>
                {conferenceUserInfo?.dietaryRequirements?.join('; ') || 'No dietary request'}
              </DetailContent>
            </DetailRowWrap>
          )}
          <DetailRowWrap>
            <DetailHead>Additional information</DetailHead>
            <DetailContent>
              Registration confirmed. Before the event date, you’ll receive an email with planning tips and access
              instructions.
            </DetailContent>
          </DetailRowWrap>
        </>
      )}
    </>
  );
};

const StyledPhoneLink = styled(ButtonLink)`
  color: ${props => props.theme.buttonColors.primary};
  text-decoration: underline;
`;
