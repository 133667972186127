import styled from 'styled-components';
import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { PageCenterAdminSelectionContainer } from 'containers/PageCenterAdminSelectionContainer';
import { PageCenterAdminNavigationContainer } from 'containers/PageCenterAdminNavigationContainer';
import { PageCenterAdminSeatsManagementContainer } from 'containers/PageCenterAdminSeatsManagementContainer';
import { CenterAdminHeaderContainer } from 'containers/CenterAdminHeaderContainer';
import { PageCenterAdminMemberships } from 'components/pages/PageCenterAdminMemberships/PageCenterAdminMemberships';
import { PageCenterAdminInfo } from 'components/pages/PageCenterAdminInfo/PageCenterAdminInfo';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { HelpSection } from 'components/organisms/HelpSection/HelpSection';
import { Footer } from 'components/molecules/Footer/Footer';
import { Container, Grid, Divider } from 'components/atoms';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

export const PageCenterAdmin: React.FC = () => {
  const targetContainer = React.useRef<HTMLDivElement | null>(null);
  const containerContentRef = React.useRef<HTMLDivElement | null>(null);

  const renderPageCenterAdminSeatsManagementContainer = (props: RouteComponentProps): React.ReactNode => (
    <PageCenterAdminSeatsManagementContainer {...props} />
  );

  return (
    <>
      <HeaderPageWrapper contentRef={targetContainer} contentInnerRef={containerContentRef}>
        <CenterAdminHeaderContainer />
        <StyledContainer>
          <Grid stackable>
            <Grid.Row stretched>
              <Grid.Column tablet={16} computer={16}>
                <StyledContentContainer>
                  <Switch>
                    <Route
                      path={getPath(Routes.CENTER_ADMIN_MEMBERSHIPS_SEATS_MANAGEMENT)}
                      render={renderPageCenterAdminSeatsManagementContainer}
                    />
                    <Route path={getPath(Routes.CENTER_ADMIN_TAB_INFO)} component={PageCenterAdminInfo} />
                    <Route path={getPath(Routes.CENTER_ADMIN_MEMBERSHIPS)} component={PageCenterAdminMemberships} />
                    <Route
                      exact
                      path={getPath(Routes.CENTER_ADMIN_ROOT)}
                      // tslint:disable-next-line: jsx-no-lambda
                      render={props =>
                        props.match.params.orgId ? (
                          <PageCenterAdminNavigationContainer {...props} />
                        ) : (
                          <PageCenterAdminSelectionContainer {...props} />
                        )
                      }
                    />
                  </Switch>
                  <StyledDivider />
                  <HelpSection />
                </StyledContentContainer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </StyledContainer>
        <Footer />
      </HeaderPageWrapper>
    </>
  );
};

const StyledContainer = styled(Container)`
  &.ui.container {
    flex: 1 0 auto;
    display: flex;

    > .grid {
      flex: 1 1 auto;
    }
  }
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${props => props.theme.pxToRem(16)};
  margin-top: ${props => props.theme.pxToRem(32)};
  margin-bottom: ${props => props.theme.pxToRem(80)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(24)};
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-bottom: ${props => props.theme.pxToRem(30)};
    border-top-color: transparent;
    border-bottom-color: ${props => props.theme.colors.neutralGrey3};
    color: ${props => props.theme.colors.neutralGrey3};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(25)};
    }
  }
`;
