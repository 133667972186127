import React from 'react';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';

interface Props {
  email: string;
  index: string;
  handleRemove: (index: any) => void;
  hasErrors: boolean;
}

export const EmailTag: React.FC<Props> = ({ email, index, handleRemove, hasErrors }) => {
  const removeEmail = React.useCallback(() => {
    handleRemove(index);
  }, [handleRemove, index]);

  return (
    <Bubble key={email} hasError={hasErrors}>
      <BubbleText>{email}</BubbleText>
      <CloseButton onClick={removeEmail} data-testid={`remove-email-${email}`}>
        <StyledCloseIcon />
      </CloseButton>
    </Bubble>
  );
};

const BubbleText = styled.div`
  margin-top: ${props => props.theme.pxToRem(2)};
  max-width: ${props => props.theme.pxToRem(180)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Bubble = styled.div<ThemedStyledProps<{ hasError: boolean }, DefaultTheme>>`
  display: inline-flex;
  max-width: 
  height: ${props => props.theme.pxToRem(25)};
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  border-radius: ${props => props.theme.pxToRem(12.5)};
  background-color: ${props => props.theme.colors.neutralGrey2};
  margin-right: ${props => props.theme.pxToRem(10)};
  margin-bottom: ${props => props.theme.pxToRem(8)};
  padding: 0 ${props => props.theme.pxToRem(8)} 0 ${props => props.theme.pxToRem(13)};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(22)};
  height: ${props => props.theme.pxToRem(22)};
  margin-left: ${props => props.theme.pxToRem(8)};
  margin-bottom: 0;
  margin-top: ${props => props.theme.pxToRem(1)};
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.neutralGrey4};
  width: ${props => props.theme.pxToRem(22)};
  height: ${props => props.theme.pxToRem(22)};
`;
