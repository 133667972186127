import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import { AddressForm } from 'components/pages/PageCheckout/AddressForm';
import { Divider } from 'components/atoms';
import { emptyFunc, emptyArray } from 'utils';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { ProfileHeading } from 'components/molecules/ProfileHeading/ProfileHeading';
import { CheckoutCountriesListHash } from 'mxp-utils';

// selectors
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import {
  isB2BSelector,
  checkoutLoadingStateSelector,
  adminShippingAddressSelector,
  adminBillingAddressSelector,
  isSalesforceAddressFetchedSelector,
  isSecondaryAddressNeededSelector,
  adminSmartyValidationReasonSelector,
  adminSmartyBillingValidationReasonSelector,
  isSecondaryBillingAddressNeededSelector,
  adminIsShippingEmbargoedSelector,
  adminIsBillingEmbargoedSelector,
} from 'modules/admin/selectors';
import { USE_BYPASS_B2B_ADDRESS_VALIDATION } from 'modules/featureToggle/constants';

// actions & helpers
import {
  setShippingAddress,
  setBillingAddress,
  setBypassValidation,
  setBypassValidationBilling,
} from 'modules/admin/actions';

export const Address: React.FC<{ setNextDisabled: (isDisabled: boolean) => void }> = ({ setNextDisabled }) => {
  const dispatch = useDispatch();

  // selectors
  const useBypassB2bAddressValidation = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_BYPASS_B2B_ADDRESS_VALIDATION)
  );
  const isB2B = useSelector(isB2BSelector);
  const loading = useSelector(checkoutLoadingStateSelector);
  const shippingAddress = useSelector(adminShippingAddressSelector);
  const billingAddress = useSelector(adminBillingAddressSelector);
  const isSalesforceAddressFetched = useSelector(isSalesforceAddressFetchedSelector);
  const isSecondaryAddressNeeded = useSelector(isSecondaryAddressNeededSelector);
  const isSecondaryBillingAddressNeeded = useSelector(isSecondaryBillingAddressNeededSelector);
  const smartyValidationReason = useSelector(adminSmartyValidationReasonSelector);
  const smartyBillingValidationReason = useSelector(adminSmartyBillingValidationReasonSelector);
  const isShippingEmbargoed = useSelector(adminIsShippingEmbargoedSelector);
  const isBillingEmbargoed = useSelector(adminIsBillingEmbargoedSelector);
  const isUSShippingAddress: boolean = Boolean(shippingAddress.country === CheckoutCountriesListHash.USA.ISOAlpha3Code);
  const isUSBillingAddress: boolean = Boolean(billingAddress.country === CheckoutCountriesListHash.USA.ISOAlpha3Code);

  const isShippingFormValid: boolean = Boolean(
    shippingAddress &&
      shippingAddress.company &&
      shippingAddress.addressLine1 &&
      shippingAddress.city &&
      shippingAddress.state &&
      (shippingAddress.zipCode || !isUSShippingAddress) &&
      shippingAddress.country &&
      !isShippingEmbargoed
  );

  const isBillingFormValid: boolean = Boolean(
    billingAddress &&
      billingAddress.company &&
      billingAddress.addressLine1 &&
      billingAddress.state &&
      billingAddress.city &&
      (billingAddress.zipCode || !isUSBillingAddress) &&
      billingAddress.country &&
      !isBillingEmbargoed
  );
  const buttonIsDisabled: boolean =
    !isShippingFormValid ||
    !isBillingFormValid ||
    isSecondaryAddressNeeded ||
    Boolean(isShippingEmbargoed || isBillingEmbargoed);

  React.useEffect(() => {
    setNextDisabled(buttonIsDisabled);
  }, [buttonIsDisabled, setNextDisabled]);

  // handlers
  const setShippingAddressOnChange = (modifier: Partial<State.Address>) => {
    dispatch(setShippingAddress(modifier));
  };
  const setBillingAddressOnChange = (modifier: Partial<State.Address>) => {
    dispatch(setBillingAddress(modifier));
  };

  const setBypassValidationOnChange = (modifier: boolean) => {
    dispatch(setBypassValidation(modifier));
  };

  const setBypassBillingValidationOnChange = (modifier: boolean) => {
    dispatch(setBypassValidationBilling(modifier));
  };

  return !loading && isSalesforceAddressFetched ? (
    <>
      <PreviousPage backText="Back to build order" />
      <StyledProfileHeading title="Address" />
      <Grid stackable>
        <StyledRow>
          <StyledColumn computer={10} tablet={16}>
            <div>
              <AddressBlock>
                <AddressForm
                  title="Ship to attention"
                  description={
                    'Ship to address references Salesforce Account Record data.\nAny edits made here will not be reflected in Salesforce.'
                  }
                  address={shippingAddress}
                  setAddress={setShippingAddressOnChange}
                  addressAutocompleteItems={emptyArray}
                  getAddressAutocompleteItems={emptyFunc}
                  clearAddressAutocompleteItems={emptyFunc}
                  savedAddress={[]}
                  savedAddressChecked={false}
                  toggleSavedAddressChecked={emptyFunc}
                  isLoadingAutocompleteItems={false}
                  isSecondaryAddressNeeded={isSecondaryAddressNeeded}
                  shippingAddressValidationReason={smartyValidationReason}
                  isB2B={isB2B}
                  isShippingForm={true}
                  isEmbargoed={isShippingEmbargoed}
                  setBypassValidationOnChange={setBypassValidationOnChange}
                  useBypassB2bAddressValidation={useBypassB2bAddressValidation}
                  btnIsDisabled={buttonIsDisabled}
                />
              </AddressBlock>
              <Divider />
              <AddressBlock>
                <AddressForm
                  title="Bill to attention"
                  description={
                    'Bill to address references Salesforce Account Record data.\nAny edits made here will not be reflected in Salesforce.'
                  }
                  address={billingAddress}
                  setAddress={setBillingAddressOnChange}
                  addressAutocompleteItems={emptyArray}
                  getAddressAutocompleteItems={emptyFunc}
                  clearAddressAutocompleteItems={emptyFunc}
                  savedAddress={[]}
                  savedAddressChecked={false}
                  toggleSavedAddressChecked={emptyFunc}
                  isLoadingAutocompleteItems={false}
                  isB2B={isB2B}
                  billingAddressValidationReason={smartyBillingValidationReason}
                  isShippingForm={false}
                  isSecondaryBillingAddressNeeded={isSecondaryBillingAddressNeeded}
                  isEmbargoed={isBillingEmbargoed}
                  setBypassValidationOnChange={setBypassBillingValidationOnChange}
                  useBypassB2bAddressValidation={useBypassB2bAddressValidation}
                  btnIsDisabled={buttonIsDisabled}
                />
              </AddressBlock>
            </div>
          </StyledColumn>
        </StyledRow>
      </Grid>
    </>
  ) : (
    <Dimmer active={true} inverted>
      <Loader size="small" inverted>
        Loading
      </Loader>
    </Dimmer>
  );
};

const StyledRow = styled(Grid.Row)`
  &&&&& {
    padding-top: 0;
  }
`;

const StyledProfileHeading = styled(ProfileHeading)`
  margin-top: ${props => props.theme.pxToRem(-8)};
`;

const StyledColumn = styled(Grid.Column)`
  &&&&&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }
`;

const AddressBlock = styled.div`
  margin-top: ${props => props.theme.pxToRem(30)};
  margin-bottom: ${props => props.theme.pxToRem(30)};
  p:first-of-type {
    white-space: pre-line;
  }
`;
