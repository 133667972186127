import {
  Button,
  ButtonEnums,
  ButtonLink,
  Checkbox,
  Divider,
  FeedPlaceholder,
  LabeledIcon,
  Link,
  NotificationBanner,
  NotificationBannerEnums,
  OnlyDesktop,
} from 'components/atoms';
import { BenefitsModal } from 'components/organisms';
import { MembershipPackageAccordionStatus, PackageMembership, Routes } from 'constants/index';
import {
  addMembershipPackageCredential,
  setCredentialProductId,
  setIsComingFromPropPage,
  setIsCredentialsJourney,
  setIsRenewalsJourney,
  setMembershipEvent,
  setPropPageURL,
  setSectionProductId,
} from 'modules/membership/actions';
import {
  activeMembershipSubscriptionSelector,
  clickedMembershipUpgradeSelector,
  currentMembershipProduct,
  inactiveMembershipProduct,
  isCimaCgmaAffiliateSelector,
  isCimaCandidateTypeSelector,
  isCimaRenewalSeasonSelector,
  isRenewalSeasonSelector,
  membershipTypesFetchedSelector,
  userMembershipPackageSelector,
  isCimaMembershipLapsedSelector,
  isCimaPqCandidateRenewalSelector,
  isRenewalsJourneySelector,
  isCimaPQCandidateLapsedSelector,
  isRenewalButtonSelector,
  clickedCimaMembershipRenewalInProfileSelector,
  clickedCimaMembershipRenewalInFeedSelector,
  isMembershipRenewedSelector,
} from 'modules/membership/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import {
  productCurrencySelector,
  productsListDataFetchedSelector,
  hasFcmaCredentialProductSelector,
  hasMipCredentialProductSelector,
} from 'modules/products/selectors';
import { UserMemberTypes } from 'modules/user/constants';
import {
  customerInactiveCredentialsSelector,
  isUserSuspendedSelector,
  isAicpaMemberSelector,
  isAuthSelector,
  isCimaMemberSelector,
  isUserMemberSuspendedSelector,
  userMemberTypeSelector,
  userTierSelector,
  isUserMemberLapsedSelector,
  cimaMembershipTermSelector,
  userHasMultipleTermsSelector,
} from 'modules/user/selectors';
import moment from 'moment-timezone';
import { Contentful, MembershipTypes as Membership, Product } from 'mxp-schemas';
import { Admin as AdminUtils, Utils } from 'mxp-utils';
import React, { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import { getPath, getUpdatedPrices, getCurrentLocation } from 'utils';
import { getIsLowerEnvironment } from 'utils/env';
import { StyledImage, TitleImageDesktop } from './MembershipTypeSelectionButton';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';
import { EligibilityCriteriaModal } from './EligibilityCriteriaModal';
import { EligibilityCriteriaCard } from './EligibilityCriteriaCard';

enum ButtonCallToActionText {
  APPLY_NOW = 'Apply Now',
  RENEW = 'Renew',
  UPGRADE = 'Upgrade',
  JOIN_NOW = 'Join Now',
  ELIGIBILITY = `Yes, I'm Eligible`,
  APPLY_CIMA_MIP = 'Apply for Membership in Practice',
  BECOME_FCMA = 'Apply now',
  PAUSE_STUDIES = 'Pause my studies',
}
const membershipButtonTitle = (
  isUserMember: boolean,
  isUserMemberSuspended: boolean,
  isCurrent: boolean,
  isRenewalSeason: boolean,
  isPropPage: boolean,
  productType: string,
  isCimaMipJourney: boolean,
  isFCMAPropPageJourney: boolean,
  isCimaRenewalSeason?: boolean,
  isAicpaMember?: boolean,
  isCimaMember?: boolean,
  isCimaPqCandidateRenewal?: boolean,
  isCimaCandidateType?: boolean,
  isCimaMembershipLapsed?: boolean,
  isUserMemberLapsed?: boolean,
  isCimaPQCandidateLapsed?: boolean
) => {
  const isCenterMembershipPropPage = isPropPage && productType === Product.ProductType.CENTER_MEMBERSHIP;
  const isCredentialPropPage = isPropPage && productType === Product.ProductType.CREDENTIAL;
  const isSectionPropPage = isPropPage && productType === Product.ProductType.SECTION;
  if (isCimaMipJourney) return ButtonCallToActionText.APPLY_NOW;
  if (isFCMAPropPageJourney) return ButtonCallToActionText.BECOME_FCMA;
  if (isCenterMembershipPropPage) return ButtonCallToActionText.APPLY_NOW;
  // credential prop page
  if (isCredentialPropPage) {
    return ButtonCallToActionText.APPLY_NOW;
  }
  // section prop page
  if (isSectionPropPage) {
    return ButtonCallToActionText.JOIN_NOW;
  }
  if (!isUserMember || isUserMemberLapsed) {
    return ButtonCallToActionText.ELIGIBILITY;
  }
  if (isCurrent && (isUserMember || isUserMemberSuspended || isCimaMembershipLapsed || isUserMemberLapsed)) {
    if (
      (isRenewalSeason && isAicpaMember) ||
      (isCimaRenewalSeason && isCimaMember) ||
      isCimaMembershipLapsed ||
      isUserMemberLapsed
    ) {
      return ButtonCallToActionText.RENEW;
    }
  }

  if (!isCurrent && ((isCimaPqCandidateRenewal && isCimaCandidateType) || isCimaPQCandidateLapsed)) {
    return ButtonCallToActionText.PAUSE_STUDIES;
  }

  return ButtonCallToActionText.UPGRADE;
};
interface MembershipTypeSelectionCard {
  id?: string;
  slug?: string;
  name?: string;
  membershipKey?: string;
  title?: string;
  applyLink?: string;
  description?: string;
  prices?: Product.Price[];
  benefits?: Contentful.MembershipRestriction.BenefitsSubBlock[];
  benefitsReference?: string;
  propPageReference?: string;
  eligibility?: string;
  image?: Contentful.ContentfulImage;
  isRecommended?: boolean;
  isCurrent?: boolean;
  selectMembership: (
    id: string,
    slug: string,
    membershipType: string[],
    isVariant: boolean,
    membershipKey?: string
  ) => void;
  isWideView?: boolean;
  isUserMember?: boolean;
  productType?: string[];
  isVariant?: boolean;
  membershipProductsLoaded?: boolean;
  isCenterMembershipJourney?: boolean;
  isCenterMembershipLoading?: boolean;
  isPropPage?: boolean;
  productTitle?: string;
  benefitDescription?: string;
  isCimaMipJourney?: boolean;
  isFCMAPropPageJourney?: boolean;
  navigateToCimaMipJourney?: () => Promise<void>;
  navigateToFCMAApplicationJourney?: () => Promise<void>;
  isFCMACartLoading?: boolean;
  benefitsListDescription?: string;
  customPriceLabel?: string;
  preChecked?: boolean;
  onCloseSelectionCard?: () => void;
  isConsistCenterProduct?: boolean;
  selectedProduct?: string;
}

const FormattedPrice: React.FC<{ prices?: Product.Price[]; isAicpaMembershipJourneyPage?: boolean }> = ({
  prices,
  isAicpaMembershipJourneyPage,
}) => {
  const currency = useSelector(productCurrencySelector);
  const tier = useSelector(userTierSelector);
  if (prices?.length) {
    const updatedPrices = getUpdatedPrices(prices!, currency.label, tier);
    const price = updatedPrices.sort(
      (a: Product.Price, b: Product.Price) => (a ? a.amount : 0) - (b ? b.amount : 0)
    )?.[0];
    const centPrice = Utils.centPriceToString(Utils.toCentsFormat(price?.amount), price.currency);
    return isAicpaMembershipJourneyPage ? (
      <StyledLargePriceLabel>{centPrice}</StyledLargePriceLabel>
    ) : (
      <StyledText>{centPrice}</StyledText>
    );
  }
  return null;
};

const Declaration: React.FC<{
  eligibility: string;
  slug?: string;
  setIsCheckedCallBack: Dispatch<SetStateAction<boolean>>;
  isCimaMipJourney?: boolean;
  isFCMAPropPageJourney?: boolean;
  checked?: boolean;
}> = ({
  eligibility,
  slug,
  setIsCheckedCallBack,
  isCimaMipJourney = false,
  isFCMAPropPageJourney = false,
  checked,
}) => {
  if (isCimaMipJourney) return <ReactMarkdown children={eligibility as any} />;
  if (isFCMAPropPageJourney) return <ReactMarkdown children={eligibility as any} />;

  return (
    <>
      <StyledCheckbox
        checked={checked}
        testId={`eligibility-${slug}`}
        onClick={setIsCheckedCallBack}
        label={
          <label>
            <ReactMarkdown children={eligibility as any} />
          </label>
        }
      />
      <Spacing />
    </>
  );
};
const Benefits: React.FC<{
  slug?: string;
  isCenterMembership?: boolean;
  benefits?: Contentful.MembershipRestriction.BenefitsSubBlock[];
  benefitsReference?: string;
  setOpenModal?: () => void;
  isCimaMipJourney?: boolean;
  isFCMAPropPageJourney?: boolean;
}> = ({
  slug,
  benefits = [],
  benefitsReference,
  setOpenModal,
  isCenterMembership = false,
  isCimaMipJourney = false,
  isFCMAPropPageJourney = false,
}) => {
  const isCimaCredentialJourney = Boolean(isCimaMipJourney || isFCMAPropPageJourney);
  const productBenefits = isCimaCredentialJourney ? benefits : benefits.slice(0, 4);
  return (
    <>
      <ul>
        {productBenefits.map(({ header }) =>
          header ? (
            <StyledLabeledIcon icon={<StyledCheckIcon />} label={header} mobileLabel={header} key={header} />
          ) : null
        )}
      </ul>
      <p />
      {!isCimaCredentialJourney && (
        <>
          {benefitsReference ? (
            <StyledLink testId={`benefits-page-${slug}`} to={benefitsReference} isExternal={true}>
              {isCenterMembership ? 'Learn More' : 'See all benefits'}
            </StyledLink>
          ) : benefits.length > 4 ? (
            <StyledButtonSeeAllBenefits
              testId={`benefits-modal-${slug}`}
              onClick={setOpenModal}
              variant={ButtonEnums.variants.link}
            >
              {isCenterMembership ? 'Learn More' : 'See all benefits'}
            </StyledButtonSeeAllBenefits>
          ) : null}
        </>
      )}
      <Spacing />
      <OnlyDesktop>
        <Divider />
      </OnlyDesktop>
    </>
  );
};

const MembershipDescriptionHeader: React.FC = () => (
  <StyledMembershipHeader>Who is this account meant for</StyledMembershipHeader>
);

const ProceedButtons: React.FC<{ applyLink?: any; onClickSelectMembership?: any; onCloseSelectionCard?: any }> = ({
  applyLink,
  onClickSelectMembership,
  onCloseSelectionCard,
}) => (
  <>
    {applyLink ? (
      <Link to={{ pathname: `${applyLink}` }} overrideAnalytics={true} target="_blank">
        <StyledButtonLink
          to={applyLink}
          testId={`select-membership-link`}
          size={ButtonEnums.sizes.medium}
          variant={ButtonEnums.variants.primary}
        >
          Join Now
        </StyledButtonLink>
      </Link>
    ) : (
      <StyledButtonLink
        size={ButtonEnums.sizes.medium}
        variant={ButtonEnums.variants.primary}
        testId="yes-iam-eligible-btn"
        onClick={onClickSelectMembership}
      >
        Yes, I'm eligible
      </StyledButtonLink>
    )}
    <StyledButtonLink
      size={ButtonEnums.sizes.medium}
      variant={ButtonEnums.variants.primaryNegative}
      testId="close-btn"
      onClick={onCloseSelectionCard}
    >
      Close
    </StyledButtonLink>
  </>
);

const DeclationBlock: React.FC<{
  eligibility?: any;
  isAicpaMembershipJourneyPage?: any;
  slug?: any;
  isFCMAPropPageJourney?: any;
  isCenterMembershipJourney?: any;
  isCenterMembershipPropPage?: any;
  isSuspendedAndIsCenterMembership?: any;
  setIsChecked?: any;
  isChecked?: any;
  isCimaMipJourney?: any;
  buttonLabel?: any;
  customPriceLabel?: any;
  prices?: any;
}> = ({
  eligibility,
  isAicpaMembershipJourneyPage,
  slug,
  isFCMAPropPageJourney,
  isCenterMembershipJourney,
  isCenterMembershipPropPage,
  isSuspendedAndIsCenterMembership,
  setIsChecked,
  isChecked,
  isCimaMipJourney,
  buttonLabel,
  customPriceLabel,
  prices,
}) => (
  <>
    {eligibility && !isAicpaMembershipJourneyPage && (
      <>
        <Declaration
          slug={slug}
          eligibility={eligibility}
          setIsCheckedCallBack={setIsChecked.bind(null, !isChecked)}
          isCimaMipJourney={isCimaMipJourney}
          isFCMAPropPageJourney={isFCMAPropPageJourney}
          checked={isChecked}
        />
      </>
    )}
    <Spacing />

    {isSuspendedAndIsCenterMembership && (
      <StyledNotificationBanner
        testId="payment-tech-error"
        childrenTestId="payment-tech-error-children"
        variant={NotificationBannerEnums.variant.red}
        icon={<StyledIconErrorSuspension />}
      >
        <SpacingNotification>
          You do not have access to this product because you are suspended due to Ethics reasons. For any questions,
          please contact{' '}
          <Link isExternal testId="error-email" to="mailto:ethics@aicpa.org">
            {' '}
            <StyledUnderlined>ethics@aicpa.org</StyledUnderlined>
          </Link>{' '}
          or call{' '}
          <Link isExternal testId="error-phone" to="tel:1-888-777-7077">
            <StyledUnderlined>1-888-777-7077</StyledUnderlined>
          </Link>
          , using option 2, then option 3.
        </SpacingNotification>
      </StyledNotificationBanner>
    )}
  </>
);

const LearnMoreBlock: React.FC<{
  isCenterMembershipJourney?: any;
  isCenterMembershipPropPage?: any;
  isUserLoggedOut?: any;
  isCenterMembershipDisabled?: any;
  CenterNonPartnerNotification?: any;
  propPageReference?: any;
  isVariant?: any;
  isSamePage?: any;
  isAicpaMembershipJourneyPage?: any;
  slug?: any;
  applyLink?: any;
  isUserSuspendedByEthics?: any;
  name?: any;
}> = ({
  isCenterMembershipJourney,
  isCenterMembershipPropPage,
  isUserLoggedOut,
  isCenterMembershipDisabled,
  CenterNonPartnerNotification,
  propPageReference,
  isVariant,
  isSamePage,
  isAicpaMembershipJourneyPage,
  slug,
  applyLink,
  isUserSuspendedByEthics,
  name,
}) => (
  <>
    {(isCenterMembershipJourney || isCenterMembershipPropPage) &&
      !isUserLoggedOut &&
      isCenterMembershipDisabled &&
      CenterNonPartnerNotification}
    {propPageReference && !isVariant && !isSamePage && !isAicpaMembershipJourneyPage && (
      <StyledLearnMoreLink
        testId={`learn-more-link-${slug}`}
        to={propPageReference}
        size={ButtonEnums.sizes.medium}
        variant={ButtonEnums.variants.secondary}
      >
        Learn More
      </StyledLearnMoreLink>
    )}
    {/* TODO APPLY LINK NEEDS TO BE CORRECTLY SET IN CONTENTFUL */}
    {isVariant && (
      <StyledApplyForLink
        testId={`apply-for-${slug}`}
        to={`${applyLink}`}
        size={ButtonEnums.sizes.medium}
        variant={ButtonEnums.variants.primary}
        disabled={isUserSuspendedByEthics}
      >
        Apply For {name}
      </StyledApplyForLink>
    )}
  </>
);

export const MembershipTypeSelectionCardDesktop: React.FC<MembershipTypeSelectionCard> = memo(
  ({
    id = '',
    slug = '',
    name = '',
    membershipKey = '',
    description,
    applyLink = '',
    prices,
    benefits = [],
    benefitsReference,
    propPageReference = '',
    eligibility,
    isCurrent = false,
    selectMembership,
    isWideView,
    isVariant,
    productType = [],
    membershipProductsLoaded = false,
    isCenterMembershipJourney = false,
    isCenterMembershipLoading = false,
    isPropPage = false,
    benefitDescription,
    productTitle,
    isCimaMipJourney = false,
    isFCMAPropPageJourney = false,
    navigateToCimaMipJourney,
    navigateToFCMAApplicationJourney,
    isFCMACartLoading = false,
    benefitsListDescription,
    customPriceLabel = '',
    preChecked,
    onCloseSelectionCard,
    isConsistCenterProduct = false,
    selectedProduct = '',
  }) => {
    // DESKTOP VERSION
    const dispatch = useDispatch();
    const location = useLocation();

    // SELECTORS

    const { type } = useSelector(userMembershipPackageSelector);
    const isAuth = useSelector(isAuthSelector);
    const isAdmin = useSelector(isAdminPortalSelector);
    const isImpersonation = Boolean(isAuth) && isAdmin;
    const userHasFcmaCredential = useSelector(hasFcmaCredentialProductSelector);
    const isRenewalsJourney = useSelector(isRenewalsJourneySelector);
    const cimaMembershipTerms = useSelector(cimaMembershipTermSelector);
    const userHasCimaRegularMembership = cimaMembershipTerms?.some(
      (item: any) => item?.membershipTermType === Membership.MembershipKeys.REGULAR
    );
    const hasMipCredentialProduct = useSelector(hasMipCredentialProductSelector);
    const membershipTier = useSelector(activeMembershipSubscriptionSelector);
    const current = useSelector(currentMembershipProduct);
    const isUserMemberSuspended = useSelector(isUserMemberSuspendedSelector);
    const isCimaMembershipLapsed = useSelector(isCimaMembershipLapsedSelector);
    const inactive = useSelector(inactiveMembershipProduct);

    const [isOpenModal, setOpenModal] = useState(false);
    const [isChecked, setIsChecked] = useState(!Boolean(eligibility));
    const [loadComplete, setLoadComplete] = useState(false);
    const [showEligibilityModal, setShowEligibilityModal] = useState(false);
    const isAicpaMembershipJourneyPage =
      location?.pathname === Membership.MembershipRoute.PACKAGE && productType[0] === Product.ProductType.MEMBERSHIP;
    const setOpenCloseModalCallBack = useCallback(() => setOpenModal(!isOpenModal), [isOpenModal]);
    const isRenewalSeason = useSelector(isRenewalSeasonSelector);
    const isCimaRenewalSeason = useSelector(isCimaRenewalSeasonSelector);
    const isAicpaMember = useSelector(isAicpaMemberSelector);
    const isCimaMember = useSelector(isCimaMemberSelector);
    const isMembershipUpgradeClicked = useSelector(clickedMembershipUpgradeSelector);
    const isMembershipRenewInProfileClicked = useSelector(clickedCimaMembershipRenewalInProfileSelector);
    const isMembershipRenewInFeedClicked = useSelector(clickedCimaMembershipRenewalInFeedSelector);
    const productListDataFetched = useSelector(productsListDataFetchedSelector);
    const membershipTypesFetched = useSelector(membershipTypesFetchedSelector);
    const isUserSuspendedByEthics = useSelector(isUserSuspendedSelector);
    const isCimaCgmaAffiliate = useSelector(isCimaCgmaAffiliateSelector);
    const isUserMemberLapsed = useSelector(isUserMemberLapsedSelector);
    const isCimaPQCandidateLapsed = useSelector(isCimaPQCandidateLapsedSelector);
    const { useSuspendedRestriction } = useSelector(featureTogglesSelector);
    const isCimaCgmaAffiliateRenewal = isCimaRenewalSeason && isCimaCgmaAffiliate && !isMembershipUpgradeClicked;
    // lapsed credential
    const customerInactiveCredentials = useSelector(customerInactiveCredentialsSelector);
    const isCimaPqCandidateRenewal = useSelector(isCimaPqCandidateRenewalSelector);
    const isCimaCandidateType = useSelector(isCimaCandidateTypeSelector);
    const showRenewButton = useSelector(isRenewalButtonSelector);
    const isCimaRegularType = AdminUtils.isCimaRegularType(
      current?.membership?.membershipBody as string,
      current?.membership?.membershipTerm?.membershipTermType as string
    );
    const isRenewalClicked = [isMembershipRenewInFeedClicked, isMembershipRenewInProfileClicked].some(
      condition => condition
    );
    const isCimaRegularRenewal = [isCimaRegularType, isRenewalClicked].every(condition => condition);

    const isAlreadyMember = [
      Membership.MembershipKeys.REGULAR,
      Membership.MembershipKeys.CANDIDATE,
      Membership.MembershipKeys.AFFILIATE,
      Membership.MembershipKeys.CGMA_AFFILIATE,
      Membership.MembershipKeys.PRE_CANDIDATE,
      Membership.MembershipKeys.RETIRED,
      Membership.MembershipKeys.INTERNATIONAL_ASSOCIATE,
      Membership.MembershipKeys.CGMA_AFFILIATE,
    ];
    const isUserMember = isAlreadyMember.some(
      mem =>
        current?.ctOrder?.variant?.attributes?.membershipKey?.key === mem ||
        inactive?.ctOrder?.variant?.attributes?.membershipKey?.key === mem ||
        membershipTier?.variant?.attributes?.membershipKey?.key === mem
    );

    // HOOKS

    const isUserRegularMember = useMemo(() => {
      return (
        current?.ctOrder?.variant?.attributes?.membershipKey?.key === Membership.MembershipKeys.REGULAR ||
        inactive?.ctOrder?.variant?.attributes?.membershipKey?.key === Membership.MembershipKeys.REGULAR ||
        membershipTier?.variant?.attributes?.membershipKey?.key === Membership.MembershipKeys.REGULAR ||
        current?.membership?.membershipTerm?.membershipTermType === Membership.MembershipKeys.REGULAR
      );
    }, [current, inactive, membershipTier]);
    const isUserLoggedOut = useSelector(userMemberTypeSelector) === UserMemberTypes.LOGGED_OUT;
    const isCenterMembershipPropPage = isPropPage && productType[0] === Product.ProductType.CENTER_MEMBERSHIP;

    const toggleShowEligibilityModal = () => {
      setShowEligibilityModal(!showEligibilityModal);
    };

    const destinationURL = useMemo(() => {
      const currentHash = location?.hash.replace('#', '');
      const currentLocation = getCurrentLocation(location);
      if (isCenterMembershipPropPage || isCenterMembershipJourney) {
        return getPath(Routes.CENTER_MEMBERSHIP_FORM);
      }
      if (currentLocation.includes('membership/landing/become-an-aicpa-member')) {
        return getPath(Routes.MEMBERSHIP_FORM);
      }
      if (currentLocation.includes('membership/landing/fcma-credential') && isImpersonation) {
        return getPath(Routes.FCMA_CREDENTIAL_APPLICATION_FORM_PERSONAL);
      }
      if (
        currentLocation.includes('membership/landing/become-a-member-in-practice') &&
        isImpersonation &&
        userHasCimaRegularMembership
      ) {
        return getPath(Routes.CIMA_MIP_APPLICATION_MAIN_PAGE);
      }
      if ((userHasFcmaCredential && !isRenewalsJourney) || hasMipCredentialProduct) {
        return getPath(Routes.MY_CREDENTIALS);
      }
      if (
        currentLocation === getPath(Routes.CIMA_MEMBERSHIP_PACKAGE) &&
        currentHash === MembershipPackageAccordionStatus.Level
      ) {
        return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Skill}`;
      }
      if (currentLocation.includes('center-membership')) {
        return `${getPath(Routes.CENTER_MEMBERSHIP_FORM)}#${MembershipPackageAccordionStatus.Organization}`;
      }
      if (currentLocation.includes(getPath(Routes.MEMBERSHIP_FORM))) {
        return `${getPath(Routes.MEMBERSHIP_FORM)}#${MembershipPackageAccordionStatus.Tier}`;
      }
      return currentLocation;
    }, [
      location,
      hasMipCredentialProduct,
      isImpersonation,
      isRenewalsJourney,
      userHasCimaRegularMembership,
      userHasFcmaCredential,
      isCenterMembershipPropPage,
      isCenterMembershipJourney,
    ]);

    const isSamePage: boolean = location?.pathname === propPageReference;

    const buttonLabel = membershipButtonTitle(
      isUserMember,
      isUserMemberSuspended,
      isCurrent,
      isRenewalSeason,
      isPropPage,
      productType[0],
      isCimaMipJourney,
      isFCMAPropPageJourney,
      isCimaRenewalSeason,
      isAicpaMember,
      isCimaMember,
      isCimaPqCandidateRenewal,
      isCimaCandidateType,
      isCimaMembershipLapsed,
      isUserMemberLapsed,
      isCimaPQCandidateLapsed
    );
    const onClickSelectMembership = useCallback(async () => {
      // continue CIMA MiP credential membership application
      if (isCimaMipJourney && navigateToCimaMipJourney) {
        await navigateToCimaMipJourney();
        return;
      }
      // continue FCMA credential membership application
      if (isFCMAPropPageJourney && navigateToFCMAApplicationJourney) {
        await navigateToFCMAApplicationJourney();
        return;
      }
      // it will only apply if not in membership package
      // TODO: will modified in the future
      if (location?.pathname !== Membership.MembershipRoute.PACKAGE) {
        if (!isAuth) {
          dispatch(setMembershipEvent('isUserLoggedOutClickedApplyMembership', true));
        }
        if (!isVariant && productType[0] === Product.ProductType.CREDENTIAL) {
          dispatch(setCredentialProductId(id));
          dispatch(setPropPageURL('credential', location?.pathname));
          if (isPropPage && customerInactiveCredentials.length) {
            dispatch(addMembershipPackageCredential('', slug));
          }
        }
        if (!isVariant && productType[0] === Product.ProductType.SECTION) {
          dispatch(setPropPageURL('section', location?.pathname));
        }
        if (isVariant && (!productType[0] || productType[0] === Product.ProductType.CREDENTIAL)) {
          dispatch(setPropPageURL('variant', location?.pathname));
          dispatch(addMembershipPackageCredential('', slug)); // Primary set the product slug/sku here and we'll add id for this one on MembershipCredentialPanel
        }
        dispatch(setIsComingFromPropPage(isVariant));
        if (productType[0] === Product.ProductType.MEMBERSHIP && isRenewalSeason && isUserMember && isCurrent) {
          // set renewals journey
          dispatch(setIsRenewalsJourney(true));
        }
      }

      if (productType[0] === Product.ProductType.CREDENTIAL) {
        if (buttonLabel === ButtonCallToActionText.APPLY_NOW) {
          dispatch(setIsCredentialsJourney(true));
        }
      } else if (productType[0] === Product.ProductType.SECTION) {
        dispatch(setMembershipEvent('isClickedSectionsJourney', true));
        dispatch(setSectionProductId(id));
      }
      selectMembership(id, slug, productType, isVariant ?? false, membershipKey);
    }, [
      dispatch,
      selectMembership,
      membershipKey,
      location,
      id,
      slug,
      productType,
      isVariant,
      isAuth,
      isRenewalSeason,
      isUserMember,
      isCurrent,
      isCimaMipJourney,
      isFCMAPropPageJourney,
      navigateToCimaMipJourney,
      navigateToFCMAApplicationJourney,
      isPropPage,
      customerInactiveCredentials,
      buttonLabel,
    ]);

    const isMembershipPackagePage: boolean = location?.pathname === PackageMembership.MEMBERSHIP_PACKAGE;
    const informationNotLoaded = (!prices && !isVariant && getIsLowerEnvironment) || !loadComplete;

    const isUserHasMultipleTerms = useSelector(userHasMultipleTermsSelector);
    const isMembershipRenewed: boolean = useSelector(isMembershipRenewedSelector);

    const isCenterMembershipDisabled = isUserMember
      ? false
      : isUserRegularMember
      ? false
      : !isChecked || !AdminUtils.isCPEAType(slug);
    const isSuspendedAndIsCenterMembership: boolean =
      isUserSuspendedByEthics && !isMembershipPackagePage && useSuspendedRestriction;
    const isEthicsSuspended: boolean = Boolean(useSuspendedRestriction && isUserSuspendedByEthics);

    const renewalSeasonToUse =
      current.membership?.membershipBody === Membership.MembershipBody.CIMA ? isCimaRenewalSeason : isRenewalSeason;

    // RENEWAL
    const isRenewalDisabled =
      renewalSeasonToUse &&
      (!isUserHasMultipleTerms ||
        !isMembershipRenewed ||
        moment(current?.membership?.membershipTerm.expiryDate).year() === moment().year() ||
        isCimaPQCandidateLapsed ||
        isUserMemberLapsed)
        ? !isChecked
        : true;

    const isMembershipDisabled = isCurrent && !isMembershipUpgradeClicked ? isRenewalDisabled : !isChecked;

    const isApplyBtnDisabled = isUserLoggedOut
      ? false
      : isCimaMipJourney
      ? false
      : isFCMAPropPageJourney
      ? false
      : isCenterMembershipJourney || isCenterMembershipPropPage
      ? isCenterMembershipDisabled
      : isMembershipDisabled;
    // INTERNAL COMPONENT(S)

    const EligibilityCheckButton = () => {
      return (
        <StyledEligibilityCheckWrapper>
          <StyledEligibilityHeader>
            Eligibility Check <StyledAsterisk>*</StyledAsterisk>
          </StyledEligibilityHeader>
          <StyledProceedNowButton
            disabled={false}
            testId="button-manage-preferences"
            variant={ButtonEnums.variants.primary}
            bordered
            onClick={toggleShowEligibilityModal}
          >
            Click to read and proceed
          </StyledProceedNowButton>
        </StyledEligibilityCheckWrapper>
      );
    };

    const ApplyNowLink = isAicpaMembershipJourneyPage ? (
      <EligibilityCheckButton />
    ) : (
      <Link to={{ pathname: `${applyLink}` }} overrideAnalytics={true} target="_blank">
        <StyledApplyNowButton
          to={applyLink}
          testId={`select-membership-link-${slug}`}
          disabled={!isChecked}
          size={ButtonEnums.sizes.medium}
          variant={ButtonEnums.variants.primary}
        >
          {isRenewalSeason && !isChecked
            ? isCurrent
              ? 'Renew'
              : 'Upgrade'
            : type.slug === slug
            ? 'Selected'
            : 'Join Now'}
        </StyledApplyNowButton>
      </Link>
    );

    const ApplyNowButton =
      !isAicpaMembershipJourneyPage || isWideView ? (
        <StyledApplyNowButton
          to={`${destinationURL}/${name}`}
          testId={`select-membership-${slug}`}
          onClick={onClickSelectMembership}
          disabled={isApplyBtnDisabled || isSuspendedAndIsCenterMembership}
          loading={(isCenterMembershipJourney && isCenterMembershipLoading) || isFCMACartLoading}
          size={ButtonEnums.sizes.medium}
          variant={ButtonEnums.variants.primary}
        >
          {isCenterMembershipJourney ? (type.slug === slug ? 'Selected' : 'Apply Now') : buttonLabel}
        </StyledApplyNowButton>
      ) : null;

    const CenterNonPartnerNotification = (
      <StyledNotificationBanner
        testId="center-non-partner-card"
        childrenTestId="center-non-partner-card"
        variant={NotificationBannerEnums.variant.blue}
        icon={<StyledIconError />}
      >
        All Partners/Owners that are CPAs and eligible for membership must be members.
      </StyledNotificationBanner>
    );

    // EFFECTS

    useEffect(() => setIsChecked(preChecked || !eligibility), [eligibility, preChecked]);

    useEffect(() => {
      const isNonMember = [!isMembershipRenewed, !isUserHasMultipleTerms, !isUserMember].every((flag: boolean) => flag);
      const isUpgradeOrRenewal = [isMembershipUpgradeClicked, isCimaPqCandidateRenewal, isCimaRegularRenewal].includes(
        true
      );
      const isTypeSelection = [productListDataFetched, membershipTypesFetched, isUpgradeOrRenewal].every(
        (flag: boolean) => flag
      );
      if (
        [
          isNonMember,
          membershipTier,
          isUserMemberSuspended,
          isTypeSelection,
          isCimaCgmaAffiliateRenewal,
          isUserMemberLapsed,
          showRenewButton?.isAicpaRenewal,
          membershipProductsLoaded,
        ].includes(true)
      ) {
        return setLoadComplete(true);
      }
    }, [
      isMembershipRenewed,
      isUserHasMultipleTerms,
      isUserMember,
      isUserMemberSuspended,
      membershipTier,
      isMembershipUpgradeClicked,
      productListDataFetched,
      membershipTypesFetched,
      isCimaCgmaAffiliateRenewal,
      isCimaPqCandidateRenewal,
      isUserMemberLapsed,
      showRenewButton,
      isCimaRegularRenewal,
      membershipProductsLoaded,
    ]);

    return (
      <>
        <BenefitsModal
          benefits={benefits}
          open={isOpenModal}
          onClose={setOpenCloseModalCallBack}
          benefitDescription={benefitDescription}
          productTitle={productTitle}
        />
        <EligibilityCriteriaModal
          open={showEligibilityModal}
          onClick={onClickSelectMembership}
          onClose={toggleShowEligibilityModal}
          criteria={String(eligibility)}
          to={`${destinationURL}/${name}`}
          applyLink={applyLink}
        />
        <StyledMembershipContent id="membershipContent" key={id}>
          {informationNotLoaded && !isConsistCenterProduct && !isCimaMipJourney && !isFCMAPropPageJourney ? (
            <FeedPlaceholder />
          ) : isWideView ? (
            <Grid columns={2}>
              <Grid.Column floated="left" width={7}>
                {!isCimaMipJourney && !isFCMAPropPageJourney && <ReactMarkdown children={description as any} />}
                {eligibility && (
                  <Declaration
                    slug={slug}
                    eligibility={eligibility}
                    setIsCheckedCallBack={setIsChecked.bind(null, !isChecked)}
                    isCimaMipJourney={isCimaMipJourney}
                    isFCMAPropPageJourney={isFCMAPropPageJourney}
                    checked={isChecked}
                  />
                )}
                <Spacing />
              </Grid.Column>
              <Grid.Column floated="right" width={7}>
                {!isCimaMipJourney && !isFCMAPropPageJourney && (
                  <StyledGridRow centered>
                    <WideScreenFormattedPrice>
                      {buttonLabel === ButtonCallToActionText.PAUSE_STUDIES ? (
                        <StyledText dangerouslySetInnerHTML={{ __html: customPriceLabel || '' }} />
                      ) : (
                        <FormattedPrice prices={prices} isAicpaMembershipJourneyPage={isAicpaMembershipJourneyPage} />
                      )}
                    </WideScreenFormattedPrice>
                    {!Boolean(informationNotLoaded) && (
                      <StyledTextBold className="wideBoldText">Benefits include</StyledTextBold>
                    )}
                  </StyledGridRow>
                )}
                {(isCimaMipJourney || isFCMAPropPageJourney) && (
                  <StyledTextBold className="benefits-header-single-variant-product">
                    {isCimaMipJourney ? (
                      'In addition to the prestige, you’ll have access to valuable tools and resources:'
                    ) : isFCMAPropPageJourney ? (
                      <label>
                        With years of experience and membership, you’re ready to apply to become a Fellow Chartered
                        Management Accountant.
                        <br />
                        <br />
                        In addition to the enhanced career opportunities that come with obtaining this designation,
                        you'll have the prestige of:
                      </label>
                    ) : (
                      'Benefits include:'
                    )}
                  </StyledTextBold>
                )}
                {isCimaMipJourney && <span dangerouslySetInnerHTML={{ __html: benefitsListDescription! }} />}
                <Benefits
                  benefits={benefits}
                  benefitsReference={benefitsReference}
                  setOpenModal={setOpenCloseModalCallBack}
                  isCenterMembership={isCenterMembershipJourney}
                  isCimaMipJourney={isCimaMipJourney}
                  isFCMAPropPageJourney={isFCMAPropPageJourney}
                />
              </Grid.Column>
              <Grid.Row verticalAlign="bottom" centered>
                <CenterNotification>
                  {!isFCMAPropPageJourney && isSuspendedAndIsCenterMembership && (
                    <StyledNotificationBanner
                      testId="payment-tech-error"
                      childrenTestId="payment-tech-error-children"
                      variant={NotificationBannerEnums.variant.red}
                      icon={<StyledIconErrorSuspension />}
                    >
                      <SpacingNotification>
                        You do not have access to this product because you are suspended due to Ethics reasons. For any
                        questions, please contact{' '}
                        <Link isExternal testId="error-email" to="mailto:ethics@aicpa.org">
                          {' '}
                          <StyledUnderlined>ethics@aicpa.org</StyledUnderlined>
                        </Link>{' '}
                        or call{' '}
                        <Link isExternal testId="error-phone" to="tel:1-888-777-7077">
                          <StyledUnderlined>1-888-777-7077</StyledUnderlined>
                        </Link>
                        , using option 2, then option 3.
                      </SpacingNotification>
                    </StyledNotificationBanner>
                  )}
                </CenterNotification>
                {informationNotLoaded && !isCimaMipJourney && !isFCMAPropPageJourney ? null : (
                  <>{applyLink ? ApplyNowLink : ApplyNowButton}</>
                )}
                {(isCenterMembershipJourney || isCenterMembershipPropPage) &&
                  !isUserLoggedOut &&
                  isCenterMembershipDisabled &&
                  CenterNonPartnerNotification}
              </Grid.Row>
              <GridRowBottom verticalAlign="bottom" centered>
                {propPageReference && !isSamePage && !isAicpaMembershipJourneyPage && (
                  <StyledLearnMoreLink
                    testId={`learn-more-link-${slug}`}
                    to={propPageReference}
                    size={ButtonEnums.sizes.medium}
                    variant={ButtonEnums.variants.secondary}
                  >
                    Learn More
                  </StyledLearnMoreLink>
                )}
              </GridRowBottom>
            </Grid>
          ) : !isAicpaMembershipJourneyPage ? (
            <>
              <ReactMarkdown children={description as any} />
              <Spacing />
              {informationNotLoaded && !isCimaMipJourney && !isFCMAPropPageJourney ? null : (
                <StyledTextBold>Benefits include</StyledTextBold>
              )}
              <Benefits
                benefits={benefits}
                benefitsReference={benefitsReference}
                setOpenModal={setOpenCloseModalCallBack}
                isCenterMembership={isCenterMembershipJourney}
              />

              {!isFCMAPropPageJourney && isSuspendedAndIsCenterMembership && (
                <StyledNotificationBanner
                  testId="payment-tech-error"
                  childrenTestId="payment-tech-error-children"
                  variant={NotificationBannerEnums.variant.red}
                  icon={<StyledIconErrorSuspension />}
                >
                  <SpacingNotification>
                    You do not have access to this product because you are suspended due to Ethics reasons. For any
                    questions, please contact{' '}
                    <Link isExternal testId="error-email" to="mailto:ethics@aicpa.org">
                      {' '}
                      <StyledUnderlined>ethics@aicpa.org</StyledUnderlined>
                    </Link>{' '}
                    or call{' '}
                    <Link isExternal testId="error-phone" to="tel:1-888-777-7077">
                      <StyledUnderlined>1-888-777-7077</StyledUnderlined>
                    </Link>
                    , using option 2, then option 3.
                  </SpacingNotification>
                </StyledNotificationBanner>
              )}
              {informationNotLoaded && !isConsistCenterProduct && <FeedPlaceholder />}
              {eligibility && !isAicpaMembershipJourneyPage && (
                <Declaration
                  slug={slug}
                  eligibility={eligibility}
                  setIsCheckedCallBack={setIsChecked.bind(null, !isChecked)}
                  checked={isChecked}
                />
              )}

              {informationNotLoaded && !isCimaMipJourney && !isFCMAPropPageJourney ? null : (
                <>{applyLink ? ApplyNowLink : ApplyNowButton}</>
              )}
              {(isCenterMembershipJourney || isCenterMembershipPropPage) &&
                !isUserLoggedOut &&
                isCenterMembershipDisabled &&
                !isEthicsSuspended &&
                CenterNonPartnerNotification}
              {propPageReference && !isSamePage && !isAicpaMembershipJourneyPage && (
                <StyledLearnMoreLink
                  testId={`learn-more-link-${slug}`}
                  to={propPageReference}
                  size={ButtonEnums.sizes.medium}
                  variant={ButtonEnums.variants.secondary}
                >
                  Learn More
                </StyledLearnMoreLink>
              )}
            </>
          ) : (
            <>
              <Grid container>
                <Grid.Column computer={7}>
                  <StyledContainerBody>
                    {eligibility && isAicpaMembershipJourneyPage && <MembershipDescriptionHeader />}
                    <ReactMarkdown children={description as any} />
                    <Spacing />
                    {informationNotLoaded && !isCimaMipJourney && !isFCMAPropPageJourney ? null : (
                      <StyledTextBold>Benefits included</StyledTextBold>
                    )}
                    <Benefits
                      benefits={benefits}
                      benefitsReference={benefitsReference}
                      setOpenModal={setOpenCloseModalCallBack}
                      isCenterMembership={isCenterMembershipJourney}
                    />
                    {!isFCMAPropPageJourney && isSuspendedAndIsCenterMembership && (
                      <StyledNotificationBanner
                        testId="payment-tech-error"
                        childrenTestId="payment-tech-error-children"
                        variant={NotificationBannerEnums.variant.red}
                        icon={<StyledIconErrorSuspension />}
                      >
                        <SpacingNotification>
                          You do not have access to this product because you are suspended due to Ethics reasons. For
                          any questions, please contact{' '}
                          <Link isExternal testId="error-email" to="mailto:ethics@aicpa.org">
                            {' '}
                            <StyledUnderlined>ethics@aicpa.org</StyledUnderlined>
                          </Link>{' '}
                          or call{' '}
                          <Link isExternal testId="error-phone" to="tel:1-888-777-7077">
                            <StyledUnderlined>1-888-777-7077</StyledUnderlined>
                          </Link>
                          , using option 2, then option 3.
                        </SpacingNotification>
                      </StyledNotificationBanner>
                    )}
                    {informationNotLoaded && !isConsistCenterProduct && <FeedPlaceholder />}
                    {eligibility && !isAicpaMembershipJourneyPage && (
                      <Declaration
                        slug={slug}
                        eligibility={eligibility}
                        setIsCheckedCallBack={setIsChecked.bind(null, !isChecked)}
                        checked={isChecked}
                      />
                    )}

                    {(isCenterMembershipJourney || isCenterMembershipPropPage) &&
                      !isUserLoggedOut &&
                      isCenterMembershipDisabled &&
                      !isEthicsSuspended &&
                      CenterNonPartnerNotification}
                    {propPageReference && !isSamePage && !isAicpaMembershipJourneyPage && (
                      <StyledLearnMoreLink
                        testId={`learn-more-link-${slug}`}
                        to={propPageReference}
                        size={ButtonEnums.sizes.medium}
                        variant={ButtonEnums.variants.secondary}
                      >
                        Learn More
                      </StyledLearnMoreLink>
                    )}
                  </StyledContainerBody>
                </Grid.Column>
                <Grid.Column computer={9}>
                  {eligibility && (
                    <StyledEligibilityCard>
                      <EligibilityCriteriaCard criteria={String(eligibility)} />
                    </StyledEligibilityCard>
                  )}
                </Grid.Column>
                <StyledButtonWidth>
                  <ProceedButtons
                    applyLink={applyLink}
                    onClickSelectMembership={onClickSelectMembership}
                    onCloseSelectionCard={onCloseSelectionCard}
                  />
                </StyledButtonWidth>
              </Grid>
            </>
          )}
        </StyledMembershipContent>
      </>
    );
  }
);
export const MembershipTypeSelectionCardMobile: React.FC<MembershipTypeSelectionCard> = memo(
  ({
    id = '',
    slug = '',
    description,
    membershipKey = '',
    name = '',
    title = '',
    applyLink = '',
    prices,
    eligibility,
    benefits = [],
    benefitsReference,
    propPageReference = '',
    isCurrent = false,
    isRecommended = false,
    selectMembership,
    image,
    isVariant,
    productType = [],
    isCenterMembershipJourney = false,
    isCenterMembershipLoading = false,
    isPropPage = false,
    benefitDescription,
    productTitle,
    isCimaMipJourney = false,
    isFCMAPropPageJourney = false,
    navigateToCimaMipJourney,
    navigateToFCMAApplicationJourney,
    isFCMACartLoading = false,
    customPriceLabel = '',
    preChecked,
    selectedProduct = '',
    onCloseSelectionCard,
  }) => {
    // MOBILE VERSION
    const [isChecked, setIsChecked] = useState(!Boolean(eligibility));
    const isRenewalSeason = useSelector(isRenewalSeasonSelector);
    const [showEligibilityModal, setShowEligibilityModal] = useState(false);
    const isCimaRenewalSeason = useSelector(isCimaRenewalSeasonSelector);
    const isAicpaMember = useSelector(isAicpaMemberSelector);
    const isCimaMember = useSelector(isCimaMemberSelector);
    const isUserMemberSuspended = useSelector(isUserMemberSuspendedSelector);
    const isCimaMembershipLapsed = useSelector(isCimaMembershipLapsedSelector);
    const [isOpenModal, setOpenModal] = useState(false);
    const setOpenCloseModalCallBack = useCallback(() => setOpenModal(!isOpenModal), [isOpenModal]);
    const current = useSelector(currentMembershipProduct);
    const inactive = useSelector(inactiveMembershipProduct);
    const membershipTier = useSelector(activeMembershipSubscriptionSelector);
    const isAuth = useSelector(isAuthSelector);
    const isAdmin = useSelector(isAdminPortalSelector);
    const isImpersonation = Boolean(isAuth) && isAdmin;
    const userHasFcmaCredential = useSelector(hasFcmaCredentialProductSelector);
    const isRenewalsJourney = useSelector(isRenewalsJourneySelector);
    const cimaMembershipTerms = useSelector(cimaMembershipTermSelector);
    const { useSuspendedRestriction, useNewSignUpOnly } = useSelector(featureTogglesSelector);
    const useNewMembershipAICPA = useNewSignUpOnly;
    const userHasCimaRegularMembership = cimaMembershipTerms?.some(
      (item: any) => item?.membershipTermType === Membership.MembershipKeys.REGULAR
    );
    const PriceLabelMobile = () => {
      return buttonLabel === ButtonCallToActionText.PAUSE_STUDIES ? (
        <StyledText dangerouslySetInnerHTML={{ __html: customPriceLabel || '' }} />
      ) : (
        <FormattedPrice prices={prices} isAicpaMembershipJourneyPage={isAicpaMembershipJourneyPage} />
      );
    };

    const hasMipCredentialProduct = useSelector(hasMipCredentialProductSelector);
    const isUserSuspendedByEthics = useSelector(isUserSuspendedSelector);
    const isUserMemberLapsed = useSelector(isUserMemberLapsedSelector);
    const isCimaPQCandidateLapsed = useSelector(isCimaPQCandidateLapsedSelector);
    const isAlreadyMember = [
      Membership.MembershipKeys.REGULAR,
      Membership.MembershipKeys.CANDIDATE,
      Membership.MembershipKeys.AFFILIATE,
      Membership.MembershipKeys.CGMA_AFFILIATE,
      Membership.MembershipKeys.PRE_CANDIDATE,
      Membership.MembershipKeys.RETIRED,
      Membership.MembershipKeys.INTERNATIONAL_ASSOCIATE,
    ];
    const isUserMember = isAlreadyMember.some(
      mem =>
        current?.ctOrder?.variant?.attributes?.membershipKey?.key === mem ||
        inactive?.ctOrder?.variant?.attributes?.membershipKey?.key === mem ||
        membershipTier?.variant?.attributes?.membershipKey?.key === mem
    );
    const isUserRegularMember = useMemo(() => {
      return (
        current?.ctOrder?.variant?.attributes?.membershipKey?.key === Membership.MembershipKeys.REGULAR ||
        inactive?.ctOrder?.variant?.attributes?.membershipKey?.key === Membership.MembershipKeys.REGULAR ||
        membershipTier?.variant?.attributes?.membershipKey?.key === Membership.MembershipKeys.REGULAR ||
        current?.membership?.membershipTerm?.membershipTermType === Membership.MembershipKeys.REGULAR
      );
    }, [current, inactive, membershipTier]);
    const isUserLoggedOut = useSelector(userMemberTypeSelector) === UserMemberTypes.LOGGED_OUT;
    const isCenterMembershipPropPage = isPropPage && productType[0] === Product.ProductType.CENTER_MEMBERSHIP;
    const { type } = useSelector(userMembershipPackageSelector);
    const dispatch = useDispatch();
    const location = useLocation();
    const destinationURL = useMemo(() => {
      const currentHash = location?.hash.replace('#', '');
      const currentLocation = getCurrentLocation(location);
      if (isCenterMembershipPropPage || isCenterMembershipJourney) {
        return getPath(Routes.CENTER_MEMBERSHIP_FORM);
      }
      if (currentLocation.includes('membership/landing/become-an-aicpa-member')) {
        return getPath(Routes.MEMBERSHIP_FORM);
      }
      if (currentLocation.includes('membership/landing/fcma-credential') && isImpersonation) {
        return getPath(Routes.FCMA_CREDENTIAL_APPLICATION_FORM_PERSONAL);
      }
      if (
        currentLocation.includes('membership/landing/become-a-member-in-practice') &&
        isImpersonation &&
        userHasCimaRegularMembership
      ) {
        return getPath(Routes.CIMA_MIP_APPLICATION_MAIN_PAGE);
      }
      if ((userHasFcmaCredential && !isRenewalsJourney) || hasMipCredentialProduct) {
        return getPath(Routes.MY_CREDENTIALS);
      }
      if (
        currentLocation === getPath(Routes.CIMA_MEMBERSHIP_PACKAGE) &&
        currentHash === MembershipPackageAccordionStatus.Level
      ) {
        return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Skill}`;
      }
      if (currentLocation.includes('center-membership')) {
        return `${getPath(Routes.CENTER_MEMBERSHIP_FORM)}#${MembershipPackageAccordionStatus.Organization}`;
      }
      if (currentLocation.includes(getPath(Routes.MEMBERSHIP_FORM))) {
        return `${getPath(Routes.MEMBERSHIP_FORM)}#${MembershipPackageAccordionStatus.Tier}`;
      }
      return currentLocation;
    }, [
      location,
      hasMipCredentialProduct,
      isImpersonation,
      isRenewalsJourney,
      userHasCimaRegularMembership,
      userHasFcmaCredential,
      isCenterMembershipPropPage,
      isCenterMembershipJourney,
    ]);
    const isSamePage: boolean = location?.pathname === propPageReference;
    const isCimaPqCandidateRenewal = useSelector(isCimaPqCandidateRenewalSelector);
    const isCimaCandidateType = useSelector(isCimaCandidateTypeSelector);
    const isMembershipPackagePage: boolean = location?.pathname === PackageMembership.MEMBERSHIP_PACKAGE;
    const isSuspendedAndIsCenterMembership: boolean =
      isUserSuspendedByEthics && !isMembershipPackagePage && useSuspendedRestriction;

    useEffect(() => setIsChecked(preChecked || !eligibility), [eligibility, preChecked]);
    const toggleShowEligibilityModal = () => {
      setShowEligibilityModal(!showEligibilityModal);
    };

    const isAicpaMembershipJourneyPage =
      location?.pathname === Membership.MembershipRoute.PACKAGE && productType[0] === Product.ProductType.MEMBERSHIP;

    const buttonLabel = membershipButtonTitle(
      isUserMember,
      isUserMemberSuspended,
      isCurrent,
      isRenewalSeason,
      isPropPage,
      productType[0],
      isCimaMipJourney,
      isFCMAPropPageJourney,
      isCimaRenewalSeason,
      isAicpaMember,
      isCimaMember,
      isCimaPqCandidateRenewal,
      isCimaCandidateType,
      isCimaMembershipLapsed,
      isUserMemberLapsed,
      isCimaPQCandidateLapsed
    );
    const EligibilityCheckButton = () => {
      return (
        <StyledEligibilityCheckWrapper>
          <StyledEligibilityHeader>
            Eligibility Check <StyledAsterisk>*</StyledAsterisk>
          </StyledEligibilityHeader>

          <StyledProceedNowButton
            disabled={false}
            testId="button-manage-preferences"
            variant={ButtonEnums.variants.primary}
            bordered
            onClick={toggleShowEligibilityModal}
          >
            Click to read and proceed
          </StyledProceedNowButton>
        </StyledEligibilityCheckWrapper>
      );
    };

    const ApplyNowLink = isAicpaMembershipJourneyPage ? (
      <EligibilityCheckButton />
    ) : (
      <StyledApplyNowButton
        to={applyLink}
        testId={`select-membership-link-${slug}`}
        disabled={!isChecked || isSuspendedAndIsCenterMembership}
      >
        {isRenewalSeason && !isChecked ? (isCurrent ? 'Renew' : 'Upgrade') : 'Join Now'}
      </StyledApplyNowButton>
    );
    //

    const onClickSelectMembership = useCallback(async () => {
      // continue CIMA MiP credential membership application
      if (isCimaMipJourney && navigateToCimaMipJourney) {
        await navigateToCimaMipJourney();
        return;
      }
      // continue FCMA credential membership application
      if (isFCMAPropPageJourney && navigateToFCMAApplicationJourney) {
        await navigateToFCMAApplicationJourney();
        return;
      }
      // it will only apply if not in membership package
      // TODO: will modified in the future
      if (location?.pathname !== Membership.MembershipRoute.PACKAGE) {
        if (!isAuth) {
          dispatch(setMembershipEvent('isUserLoggedOutClickedApplyMembership', true));
        }
        if (!isVariant && productType[0] === Product.ProductType.CREDENTIAL) {
          dispatch(setCredentialProductId(id));
          dispatch(setPropPageURL('credential', location?.pathname));
        }
        if (!isVariant && productType[0] === Product.ProductType.SECTION) {
          dispatch(setPropPageURL('section', location?.pathname));
        }
        if (isVariant && !productType[0]) {
          dispatch(setPropPageURL('variant', location?.pathname));
        }
        dispatch(setIsComingFromPropPage(isVariant));
        if (productType[0] === Product.ProductType.MEMBERSHIP && isRenewalSeason && isUserMember && isCurrent) {
          // set renewals journey
          dispatch(setIsRenewalsJourney(true));
        }
      }
      if (productType[0] === Product.ProductType.CREDENTIAL) {
        if (buttonLabel === ButtonCallToActionText.APPLY_NOW) {
          dispatch(setIsCredentialsJourney(true));
        }
      } else if (productType[0] === Product.ProductType.SECTION) {
        dispatch(setMembershipEvent('isClickedSectionsJourney', true));
        dispatch(setSectionProductId(id));
      }
      selectMembership(id, slug, productType, isVariant ?? false, membershipKey);
    }, [
      dispatch,
      selectMembership,
      membershipKey,
      location,
      id,
      slug,
      productType,
      isVariant,
      isAuth,
      isRenewalSeason,
      isUserMember,
      isCurrent,
      isCimaMipJourney,
      isFCMAPropPageJourney,
      navigateToCimaMipJourney,
      navigateToFCMAApplicationJourney,
      buttonLabel,
    ]);
    const isMembershipDisabled = isCurrent ? (isRenewalSeason ? !isChecked : true) : !isChecked;
    const isEthicsSuspended: boolean = Boolean(useSuspendedRestriction && isUserSuspendedByEthics);
    const isCenterMembershipDisabled = isUserMember
      ? false
      : isUserRegularMember
      ? false
      : !isChecked || !AdminUtils.isCPEAType(slug);

    const ApplyNowButton = !isAicpaMembershipJourneyPage ? (
      <StyledApplyNowButton
        to={`${destinationURL}/${name}`}
        testId={`select-membership-${slug}`}
        onClick={onClickSelectMembership}
        disabled={
          isUserLoggedOut
            ? false
            : isEthicsSuspended
            ? true
            : isCimaMipJourney
            ? false
            : isFCMAPropPageJourney
            ? false
            : isCenterMembershipJourney
            ? isCenterMembershipDisabled
            : isMembershipDisabled
        }
        loading={(isCenterMembershipJourney && isCenterMembershipLoading) || isFCMACartLoading}
        size={ButtonEnums.sizes.medium}
        variant={ButtonEnums.variants.primary}
      >
        {isCenterMembershipJourney ? (type.slug === slug ? 'Selected' : 'Apply Now') : buttonLabel}
      </StyledApplyNowButton>
    ) : (
      <EligibilityCheckButton />
    );
    const CenterNonPartnerNotification = (
      <StyledNotificationBanner
        testId="center-non-partner-card"
        childrenTestId="center-non-partner-card"
        variant={NotificationBannerEnums.variant.blue}
        icon={<StyledIconError />}
      >
        All Partners/Owners that are CPAs and eligible for membership must be members.
      </StyledNotificationBanner>
    );

    return (
      <>
        <BenefitsModal
          benefits={benefits}
          open={isOpenModal}
          onClose={setOpenCloseModalCallBack}
          benefitDescription={benefitDescription}
          productTitle={productTitle}
        />
        <EligibilityCriteriaModal
          open={showEligibilityModal}
          onClick={onClickSelectMembership}
          onClose={toggleShowEligibilityModal}
          criteria={String(eligibility)}
          to={`${destinationURL}/${name}`}
          applyLink={applyLink}
        />
        <StyledCard selected={isChecked} useNewMembershipAICPA={useNewMembershipAICPA}>
          {useNewMembershipAICPA ? (
            <StyledDiv>
              {description && (
                <>
                  <StyledTextExtra>
                    {eligibility && isAicpaMembershipJourneyPage && <MembershipDescriptionHeader />}
                    <ReactMarkdown children={description as any} />
                  </StyledTextExtra>
                  <Spacing />
                </>
              )}
              <StyledTextBold>Benefits Included</StyledTextBold>
              {!isVariant && (
                <>
                  <Benefits
                    benefits={benefits}
                    benefitsReference={benefitsReference}
                    setOpenModal={setOpenCloseModalCallBack}
                    isCenterMembership={isCenterMembershipJourney}
                    isCimaMipJourney={isCimaMipJourney}
                    isFCMAPropPageJourney={isFCMAPropPageJourney}
                  />
                  <Divider />
                </>
              )}
              {eligibility && (
                <StyledEligibilityCard>
                  <EligibilityCriteriaCard criteria={String(eligibility)} />
                </StyledEligibilityCard>
              )}
              <Spacing />
              <DeclationBlock
                eligibility={eligibility}
                isAicpaMembershipJourneyPage={isAicpaMembershipJourneyPage}
                slug={slug}
                isFCMAPropPageJourney={isFCMAPropPageJourney}
                isCenterMembershipJourney={isCenterMembershipJourney}
                isCenterMembershipPropPage={isCenterMembershipPropPage}
                isSuspendedAndIsCenterMembership={isSuspendedAndIsCenterMembership}
                setIsChecked={setIsChecked}
                isChecked={isChecked}
                isCimaMipJourney={isCimaMipJourney}
                buttonLabel={buttonLabel}
                customPriceLabel={customPriceLabel}
                prices={prices}
              />

              {!isCenterMembershipJourney && !isCenterMembershipPropPage && isAicpaMembershipJourneyPage && (
                <PriceLabelMobile />
              )}
              <LearnMoreBlock
                isCenterMembershipJourney={isCenterMembershipJourney}
                isCenterMembershipPropPage={isCenterMembershipPropPage}
                isUserLoggedOut={isUserLoggedOut}
                isCenterMembershipDisabled={isCenterMembershipDisabled}
                CenterNonPartnerNotification={CenterNonPartnerNotification}
                propPageReference={propPageReference}
                isVariant={isVariant}
                isSamePage={isSamePage}
                isAicpaMembershipJourneyPage={isAicpaMembershipJourneyPage}
                slug={slug}
                applyLink={applyLink}
                isUserSuspendedByEthics={isUserSuspendedByEthics}
                name={name}
              />
              <Grid columns={1}>
                <Grid.Column>
                  <ProceedButtons
                    applyLink={applyLink}
                    onClickSelectMembership={onClickSelectMembership}
                    onCloseSelectionCard={onCloseSelectionCard}
                  />
                </Grid.Column>
              </Grid>
            </StyledDiv>
          ) : (
            <>
              <h2 className="ui header">
                <Grid columns={3}>
                  <Grid.Column mobile={5}>
                    {image && (
                      <StyledImage src={image.url} alt={image.altText} useNewMembershipAICPA={useNewMembershipAICPA} />
                    )}
                  </Grid.Column>
                  <StyledGridColumn mobile={8}>
                    <TitleImageDesktop heading={name} title={title} useNewMembershipAICPA={useNewMembershipAICPA} />
                  </StyledGridColumn>
                  <Grid.Column mobile={3}>
                    {isRecommended ? (
                      <StyledSelectionHint isRecommended={isRecommended} isCurrent={isCurrent}>
                        Recommended
                      </StyledSelectionHint>
                    ) : (
                      isCurrent && (
                        <StyledSelectionHint isRecommended={isRecommended} isCurrent={isCurrent}>
                          Current
                        </StyledSelectionHint>
                      )
                    )}
                  </Grid.Column>
                </Grid>
              </h2>
              {description && (
                <>
                  <StyledTextExtra>
                    <ReactMarkdown children={description as any} />
                  </StyledTextExtra>
                  <Spacing />
                </>
              )}
              <StyledLabel>Benefits Include</StyledLabel>
              {!isVariant && (
                <>
                  <Benefits
                    benefits={benefits}
                    benefitsReference={benefitsReference}
                    setOpenModal={setOpenCloseModalCallBack}
                    isCenterMembership={isCenterMembershipJourney}
                    isCimaMipJourney={isCimaMipJourney}
                    isFCMAPropPageJourney={isFCMAPropPageJourney}
                  />
                  <Divider />
                </>
              )}
              <Spacing />
              <DeclationBlock
                eligibility={eligibility}
                isAicpaMembershipJourneyPage={isAicpaMembershipJourneyPage}
                slug={slug}
                isFCMAPropPageJourney={isFCMAPropPageJourney}
                isCenterMembershipJourney={isCenterMembershipJourney}
                isCenterMembershipPropPage={isCenterMembershipPropPage}
                isSuspendedAndIsCenterMembership={isSuspendedAndIsCenterMembership}
                setIsChecked={setIsChecked}
                isChecked={isChecked}
                isCimaMipJourney={isCimaMipJourney}
                buttonLabel={buttonLabel}
                customPriceLabel={customPriceLabel}
                prices={prices}
              />
              {!isVariant ? (applyLink ? ApplyNowLink : ApplyNowButton) : null}
              <LearnMoreBlock
                isCenterMembershipJourney={isCenterMembershipJourney}
                isCenterMembershipPropPage={isCenterMembershipPropPage}
                isUserLoggedOut={isUserLoggedOut}
                isCenterMembershipDisabled={isCenterMembershipDisabled}
                CenterNonPartnerNotification={CenterNonPartnerNotification}
                propPageReference={propPageReference}
                isVariant={isVariant}
                isSamePage={isSamePage}
                isAicpaMembershipJourneyPage={isAicpaMembershipJourneyPage}
                slug={slug}
                applyLink={applyLink}
                isUserSuspendedByEthics={isUserSuspendedByEthics}
                name={name}
              />
            </>
          )}
        </StyledCard>
      </>
    );
  }
);

// STYLING

const StyledUnderlined = styled.label`
  color: ${props => props.theme.colors.primaryPurple} !important;
  text-decoration: underline !important;
  cursor: pointer;
`;
const StyledButtonSeeAllBenefits = styled(Button)`
  &&&& {
    font-size: ${props => props.theme.fontSizes.s} !important;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.primaryPurple};
    font-weight: ${props => props.theme.fontWeights.regular};
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
`;
const Spacing = styled.div`
  margin: ${props => `${props.theme.pxToRem(40)} ${props.theme.pxToRem(20)}`};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `${props.theme.pxToRem(20)} 0`};
  }
`;
const SpacingNotification = styled.div`
  margin: auto;
`;

const CenterNotification = styled.div`
  position: relative;
  left: ${props => `${props.theme.pxToRem(218)}`};
  text-align: left !important;
`;

const StyledMembershipContent = styled.div`
  margin: ${props => `${props.theme.pxToRem(20)} ${props.theme.pxToRem(10)} ${props.theme.pxToRem(40)}`};
  font-size: ${props => props.theme.fontSizes.s} !important;
  color: ${({ theme }) => theme.colors.neutralGrey8};

  .benefits-header-single-variant-product {
    font-size: ${props => props.theme.fontSizes.s} !important;
    font-weight: ${props => props.theme.fontWeights.bold} !important;
  }
`;
const StyledCheckIcon = styled(Checkmark)`
  &&& {
    path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
    width: ${props => props.theme.pxToRem(24)};
    height: ${props => props.theme.pxToRem(24)};
    margin-right: ${props => props.theme.pxToRem(8)};
  }
`;
const StyledText = styled.p`
  font-size: ${props => props.theme.pxToRem(22)};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.neutralGrey5};
`;
const StyledTextBold = styled.p`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.primaryPurple};
  margin-top: ${props => props.theme.pxToRem(40)};
  &.wideBoldText {
    margin-top: 0 !important;
  }
`;
const StyledCard = styled.div<{ selected: boolean; useNewMembershipAICPA: boolean }>`
  ${props =>
    props.useNewMembershipAICPA
      ? `
    ${props.theme.mediaQueries.desktopOnly} {
      width: 100%;
      margin: ${`${props.theme.pxToRem(25)} ${props.theme.pxToRem(0)} ${props.theme.pxToRem(25)} ${props.theme.pxToRem(
        0
      )}`};
      padding: ${props.theme.pxToRem(25)};
      border-radius: ${props.theme.pxToRem(4)};
      box-shadow: 0 ${props.theme.pxToRem(2)} $ props.theme.pxToRem(14)} 0
        ${props.theme.colors.neutralGrey6};
      background-color: ${props.theme.colors.neutralWhite};
      z-index: -1;
      color: ${props.theme.colors.neutralGrey8};
      text-align: left !important;
      background-origin: border-box;
      ${
        props.selected &&
        `
        border: ${props.theme.colors.primaryPurple} ${props.theme.pxToRem(2)} solid;
        `
      }
    }

    ${props.theme.mediaQueries.mobileOnly} {
      width: 100%;
    border: none;
    text-align: left;
    background-color: ${props.theme.colors.neutralGrey2};
    border-radius: 0;
    box-shadow: 0;
    position: relative;
    bottom: ${props.theme.pxToRem(15)};
    }

    ${props.theme.mediaQueries.tabletOnly} {
      width: 100%;
    }
  `
      : `
  margin: ${`${props.theme.pxToRem(25)} ${props.theme.pxToRem(0)} ${props.theme.pxToRem(25)} ${props.theme.pxToRem(
    0
  )}`};
  padding: ${props.theme.pxToRem(25)};
  border-radius: ${props.theme.pxToRem(4)};
  box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(14)} 0
    ${props.theme.colors.neutralGrey6};
  background-color: ${props.theme.colors.neutralWhite};
  z-index: -1;
  color: ${props.theme.colors.neutralGrey8};
  text-align: left !important;
  background-origin: border-box;
  ${
    props.selected &&
    `
    border: ${props.theme.colors.primaryPurple} ${props.theme.pxToRem(2)} solid;
    `
  }
  ${props.theme.mediaQueries.desktopOnly} {
    width: ${props.theme.pxToRem(517)};
  }
  ${props.theme.mediaQueries.mobileOnly} {
    width: ${props.theme.pxToRem(300)};
    border: none;
  }

  ${props.theme.mediaQueries.tabletOnly} {
    width: ${props.theme.pxToRem(600)};
  }
  `}
`;
const StyledCheckbox = styled(Checkbox)`
  font-size: ${props => props.theme.fontSizes.s} !important;
`;
const StyledTextExtra = styled.p`
  font-size: ${props => props.theme.fontSizes.s} !important;
  font-weight: ${props => props.theme.fontWeights.regular};
`;
const StyledLabeledIcon = styled(LabeledIcon)`
  margin: ${props => `0 ${props.theme.pxToRem(10)} 0 ${props.theme.pxToRem(-40)}`};
  padding-bottom: ${props => props.theme.pxToRem(5)};
  font-size: ${props => props.theme.fontSizes.s} !important;
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s} !important;
  }
`;
const StyledLink = styled(Link)`
  &&&& {
    font-size: ${props => props.theme.fontSizes.s} !important;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.primaryPurple};
    font-weight: ${props => props.theme.fontWeights.regular};
    text-decoration: underline;
  }
`;
const StyledApplyNowButton = styled(Button)<{ disabled: boolean }>`
  &&&& {
    width: ${props => props.theme.pxToRem(340)};
    margin-bottom: ${props => props.theme.pxToRem(10)};
    &:disabled {
      background-color: ${props => props.theme.colors.neutralGrey4};
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      margin-bottom: ${props => props.theme.pxToRem(10)};
      position: relative;
    }
  }
`;
const StyledGridRow = styled(Grid.Row)`
  margin-left: 0;
`;
const StyledLearnMoreLink = styled(ButtonLink)`
  &&&& {
    width: ${props => props.theme.pxToRem(340)};
    margin-bottom: ${props => props.theme.pxToRem(10)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      margin-bottom: ${props => props.theme.pxToRem(10)};
      position: relative;
    }
  }
`;
const StyledApplyForLink = styled(ButtonLink)`
  &&&& {
    width: ${props => props.theme.pxToRem(340)};
    margin-bottom: ${props => props.theme.pxToRem(10)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      font-size: ${props => props.theme.fontSizes.xs};
      margin-bottom: ${props => props.theme.pxToRem(10)};
      position: relative;
    }
  }
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-top: ${props => props.theme.pxToRem(15)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(250)};
  }
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: ${props => props.theme.pxToRem(337)};
  }
`;
const StyledIconError = styled(IconError)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  display: inline;
  transform: rotateX(180deg);
`;

const StyledIconErrorSuspension = styled(IconError)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  display: inline;
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin: ${props => `${props.theme.pxToRem(10)} ${props.theme.pxToRem(-2)} auto`};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `${props.theme.pxToRem(12)} ${props.theme.pxToRem(-9)} auto`};
  }
`;
const GridRowBottom = styled(Grid.Row)`
  margin: -4% 0 0 0;
`;
const WideScreenFormattedPrice = styled.div`
  > p {
    margin-bottom: ${props => props.theme.pxToRem(10)} !important;
  }
`;

const StyledProceedNowButton = styled(Button)<{ disabled: boolean }>`
  &&&& {
    width: 100%;
    display: block;
    margin-bottom: ${props => props.theme.pxToRem(10)};
    &:disabled {
      background-color: ${props => props.theme.colors.neutralGrey4};
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      margin-bottom: ${props => props.theme.pxToRem(10)};
      position: relative;
      font-size: 0.9rem;
    }
    font-weight: bold;
  }
`;

const StyledEligibilityCheckWrapper = styled.div`
  background-color: rgb(103, 33, 97, 0.1);
  width: ${props => props.theme.pxToRem(340)};
  padding: ${props => props.theme.pxToRem(20)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledLargePriceLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralBlack};
  margin-bottom: ${props => props.theme.pxToRem(10)};
  display: block;
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.l};
  }
`;

const StyledMembershipHeader = styled.div`
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

const StyledEligibilityHeader = styled.div`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.m};
  margin-bottom ${props => props.theme.pxToRem(10)};
`;

const StyledAsterisk = styled.span`
  color: rgb(255, 0, 0);
`;

const StyledContainerBody = styled.div`
  margin: 0;
  padding: 0;
`;

const StyledEligibilityCard = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralGrey3};
`;

const StyledGridColumn = styled(Grid.Column)`
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `0 0 0 ${props.theme.pxToRem(-30)}`};
    width: 100%;
  }
`;

const StyledButtonWidth = styled.div`
  width: 150%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.pxToRem(5)};
`;

const StyledButtonLink = styled(Button)`
  &&&& {
    width: ${props => props.theme.pxToRem(200)};
    padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(20)};
    margin-bottom: ${props => props.theme.pxToRem(20)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
    }

    ${props => props.theme.mediaQueries.desktopOnly} {
      margin-left: ${props => props.theme.pxToRem(16)};
      margin-right: ${props => props.theme.pxToRem(16)};
    }
  }
`;

const StyledDiv = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(25)};
  }
`;

const StyledLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledSelectionHint = styled.div<{ isRecommended?: boolean; isCurrent?: boolean }>`
  object-fit: contain;
  margin: ${props => props.theme.pxToRem(-12)} 0 0 ${props => props.theme.pxToRem(-25)};
  min-width: ${props =>
    `${props.isRecommended ? props.theme.pxToRem(110) : ''} ${props.isCurrent ? props.theme.pxToRem(82) : ''}`};
  min-height: ${props => props.theme.pxToRem(20)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  background-color: ${props =>
    `${props.isRecommended ? props.theme.colors.secondaryTeal : ''} ${
      props.isCurrent ? props.theme.colors.primaryLightPurple : ''
    }`};
  color: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(3)};
  padding: ${props => `0 ${props.theme.pxToRem(5)} ${props.theme.pxToRem(-50)} ${props.theme.pxToRem(6)}`};
  text-align: center;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.pxToRem(1)};
`;
