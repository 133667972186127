import { Divider } from 'components/atoms';
import React from 'react';
import styled from 'styled-components';

export const OrganizationFirmInformationStripedTable: React.FC<{ heading: string; children: React.ReactNode }> = ({
  heading,
  children,
}) => (
  <>
    <Container>
      <Subheading>{heading}</Subheading>
      <StripedTable>{children}</StripedTable>
      <Divider className="divider" />
    </Container>
  </>
);

const Container = styled.div`
  max-width: 240px;
  &:last-child {
    .divider {
      display: none;
    }
  }
`;

const StripedTable = styled.table`
  width: 100%;
`;

export const StripedTableRow = styled.tr`
  &:nth-child(odd) {
    background-color: ${props => props.theme.colors.neutralGrey1};
  }
`;

export const StripedTableCell = styled.td`
  padding: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(16)};
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.2em;
  label {
    display: block;
  }
`;

const Subheading = styled.h4`
  &&&& {
    margin: ${props => props.theme.pxToRem(28)} 0;
  }
`;
