import React, { useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { Responsive, ResponsiveProps } from 'semantic-ui-react';
import { FeedTargetableLink, Button, ButtonEnums, HomeFeedSectionTitle } from 'components/atoms';

interface Props {
  title?: string;
  text?: string;
  browseAllLink?: string;
  links: Common.IExternalLinks[];
  testId?: string;
  className?: string;
  isMultipleLines?: boolean;
  setBurger?: (state: boolean) => void;
  browseAllLinkTitle?: string;
}

export const FeedTargetableLinks: React.FC<Props> = React.memo(props => {
  const mobileListLimit: number = 3;
  const [showLinksLimit, setShowLinksLimit] = useState(Infinity);
  const {
    title,
    text,
    browseAllLink,
    links = [],
    testId,
    className,
    isMultipleLines,
    setBurger,
    browseAllLinkTitle,
  } = props;
  const hasLinks: boolean = Boolean(links && links.length);

  const [handleResponsiveDebouncedHandler, cancel] = useDebouncedCallback(
    (e: any, data: ResponsiveProps) => {
      if (data.maxWidth && data.maxWidth >= data.width && showLinksLimit !== mobileListLimit) {
        setShowLinksLimit(mobileListLimit);
      } else if (showLinksLimit !== Infinity) {
        setShowLinksLimit(Infinity);
      }
    },
    500,
    { maxWait: 2000 }
  );

  // Cancel subscription on component unmount
  React.useEffect(() => cancel, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleShowMoreLinks = React.useCallback(() => {
    setShowLinksLimit(showLinksLimit === mobileListLimit ? Infinity : mobileListLimit);
  }, [showLinksLimit, setShowLinksLimit]);

  return (
    <StyledWrapper data-testid={`feed-external-links-${testId}`} className={className}>
      {title && <HomeFeedSectionTitle testId={`fel-title-${testId}`} title={title} isMultipleLines={isMultipleLines} />}
      {text && <StyledText data-testid={`fel-text-${testId}`}>{text}</StyledText>}

      {links.slice(0, showLinksLimit).map(item => (
        <FeedTargetableLink
          key={`unique-link-${item.title}`}
          section={title}
          testId={item.title}
          title={item.title}
          link={item.link}
          iconPurple={true}
          setBurger={setBurger}
        />
      ))}

      {links.length > mobileListLimit && (
        <Responsive maxWidth={Responsive.onlyTablet.maxWidth} fireOnMount onUpdate={handleResponsiveDebouncedHandler}>
          <StyledShowMoreContainer>
            <StyledButton
              iconName={showLinksLimit === mobileListLimit ? 'plus' : 'minus'}
              iconPosition={ButtonEnums.iconPosition.left}
              testId="feed-external-links-show-more"
              onClick={toggleShowMoreLinks}
            >
              {showLinksLimit === mobileListLimit ? 'View more' : 'View less'}
            </StyledButton>
          </StyledShowMoreContainer>
        </Responsive>
      )}
      {hasLinks && browseAllLink && (
        <FeedTargetableLink
          testId="browse-all-links"
          title={browseAllLinkTitle || ''}
          section={title}
          iconPurple={true}
          link={browseAllLink}
        />
      )}
    </StyledWrapper>
  );
});

const StyledText = styled.div`
  ${props => `
    line-height: 1.57;
    font-size: ${props.theme.fontSizes.xs};
    color: ${props.theme.colors.neutralGrey8};
    margin: 0 0 12px;
  `}
`;

const StyledWrapper = styled.div`
  margin: 0 0 36px;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 44px 0;
  }
`;

const StyledShowMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 12px 21px;
  overflow: hidden;

  .ui.button {
    background-color: ${props => props.theme.colors.neutralWhite};
    padding: 0 0 2px 0;
    padding-right: 0;
  }

  &::after {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    margin-left: 10px;
    margin-right: -100%;
    border-top: 1px solid ${props => props.theme.colors.neutralGrey3};
  }

  ${props => props.theme.mediaQueries.desktopOnly} {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    background-color: transparent;
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.medium};

    i {
      color: ${props => props.theme.colors.primaryPurple};
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
`;
