import moment from 'moment-timezone';
import { MomentHelpers, getPath, getMomentDateTimeInfo, hasTruthyValue, areAllTruthy } from 'utils';
import { Product, Orders, User, MembershipTypes, PearsonVue, ExemptionLevels } from 'mxp-schemas';
import { Routes, BookStatus } from 'constants/index';
import { Admin as AdminUtils, User as UserUtils } from 'mxp-utils';

export const calculateAvailabilityDaysBasedOnEndDate = (accessEndDate: string) => {
  const accessEndDateMoment = moment(String(accessEndDate).substring(0, 10));
  const todaysDateMoment = moment(new Date().toISOString().substring(0, 10));
  return accessEndDateMoment.diff(todaysDateMoment, 'days');
};

export const getAvailabilityDate = (accessEndDate: string, isCartPage: boolean) => {
  const formattedAccessEndDate = moment(accessEndDate ? accessEndDate.split('T')[0] : accessEndDate).format(
    'MMM DD, YYYY'
  );

  const availabilityInDays = calculateAvailabilityDaysBasedOnEndDate(accessEndDate);
  const availabilityBasedOnAccessEndDate: string = UserUtils.conditionalFunction(
    isCartPage,
    MomentHelpers.daysToMonthYear(availabilityInDays),
    formattedAccessEndDate
  );

  return availabilityBasedOnAccessEndDate;
};

export const dateRangeBuilder = (
  {
    startDate,
    startTime,
    endDate,
    endTime,
    timeZone,
  }: {
    startDate: string | null;
    startTime: string | null;
    endDate: string | null;
    endTime: string | null;
    timeZone: string | null;
  },
  type: string,
  format?: string,
  count?: number
): string | null => {
  if (type === Product.ProductType.CONFERENCE) {
    return startDate && endDate && startDate === endDate
      ? `${endDate}`
      : startDate && endDate
      ? `${startDate.split(',')[0]} - ${endDate}`
      : null;
  }
  const isMultidayWebcast = format === Product.AvailableFormat.MULTI_DAY;
  const isSeriesWebcast = format === Product.AvailableFormat.SERIES;
  const webcastCountLabel = UserUtils.conditionalFunction(
    isMultidayWebcast,
    `${count}-part webcast`,
    `${count}-part series`
  );
  const invalidDate = startDate?.includes('Invalid');
  if (isMultidayWebcast) {
    return !invalidDate ? `Starts ${startDate}, ${startTime}-${endTime} ${timeZone} (${webcastCountLabel})` : '';
  }

  if (isSeriesWebcast) {
    return UserUtils.conditionalFunction(!invalidDate, `Next start: ${startDate} (${webcastCountLabel})`, '');
  }

  return UserUtils.conditionalFunction(
    areAllTruthy(startDate, startTime, endTime, timeZone),
    `${startDate} - ${startTime} - ${endTime} ${timeZone}`,
    null
  );
};

export const getFlattendCardVariantLabel = (variant: Product.Variant, productType: string): string => {
  const variantDateTimeInfo = getMomentDateTimeInfo(variant.sku || '', variant.startDateTime, variant.endDateTime);
  const variantDateRange: string =
    variant.startDateTime && variant.endDateTime ? dateRangeBuilder(variantDateTimeInfo, productType) || '' : '';
  return variant.format?.label && variant.examType?.label
    ? `${variant.format?.label} - ${variant.examType?.label}`
    : variant.format?.label && variantDateRange
    ? `${variant.format?.label} | ${variantDateRange}`
    : variant.format?.label
    ? `${variant.format?.label}`
    : variantDateRange
    ? `${variantDateRange}`
    : `${Product.PRODUCT_TYPES_NAMES[productType]}`;
};
export const getDiscontinuedProductTypeFromFormat = (format: Product.KeyLabel | undefined): Product.ProductType => {
  switch (format?.key) {
    case Product.DiscontinuedProductFormat.WEBCAST:
    case Product.DiscontinuedProductFormat.WEBCAST_MULTI_DAY:
    case Product.DiscontinuedProductFormat.WEBCAST_SERIES:
    case Product.DiscontinuedProductFormat.WEBCAST_SUBSCRIPTION:
      return Product.ProductType.WEBCAST;
    case Product.DiscontinuedProductFormat.PUBLICATION_EBOOK:
    case Product.DiscontinuedProductFormat.PUBLICATION_PAPERBACK:
    case Product.DiscontinuedProductFormat.PUBLICATION_HARDCOVER:
    case Product.DiscontinuedProductFormat.PUBLICATION_SUBSCRIPTION:
      return Product.ProductType.PUBLICATION;
    case Product.DiscontinuedProductFormat.CONFERENCE_FLEX_PASS:
    case Product.DiscontinuedProductFormat.CONFERENCE_ONLINE:
    case Product.DiscontinuedProductFormat.CONFERENCE_ONSITE:
      return Product.ProductType.CONFERENCE;
    case Product.DiscontinuedProductFormat.COURSE_ONLINE:
    case Product.DiscontinuedProductFormat.COURSE_ONLINE_EXAM:
    case Product.DiscontinuedProductFormat.COURSE_SUBSCRIPTION:
    case Product.DiscontinuedProductFormat.COURSE_TEXT:
      return Product.ProductType.COURSE;
    case Product.DiscontinuedProductFormat.EXAM_ON_DEMAND:
    case Product.DiscontinuedProductFormat.EXAM_PROCTORED:
      return Product.ProductType.EXAM;
    default:
      return Product.ProductType.WEBCAST;
  }
};

export const getAboutFormatLink = (productType: string, formatLabel: string): string => {
  switch (productType) {
    case Product.ProductType.WEBCAST:
      return getPath(Routes.WEBCAST_DETAILS);
    case Product.ProductType.COURSE:
      return getPath(Routes.COURSE_DETAILS);
    case Product.ProductType.PUBLICATION:
      return getPath(Routes.PUBLICATION_DETAILS);
    case Product.ProductType.CONFERENCE:
      return getPath(Routes.CONFERENCE_DETAILS);
    case Product.ProductType.EXAM:
      if (formatLabel.toUpperCase() === Product.AvailableFormat.SCHEDULED.toUpperCase()) {
        return getPath(Routes.EXAM_DETAILS);
      }
      return getPath(Routes.COURSE_DETAILS);
    case Product.ProductType.SUBSCRIPTION:
    default:
      return getPath(Routes.SUBSCRIPTION_DETAILS);
  }
};

export const getTrackingUrl = (carrierName: Orders.Carriers, trackingId: string): string => {
  switch (carrierName) {
    case Orders.Carriers.FEDEX:
      return `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingId}`;
    case Orders.Carriers.USPS:
      return `https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${trackingId}`;
    default:
      return '';
  }
};

export enum ModalVariants {
  INELIGIBLE_REGULAR = 'ineligible-regular',
  INELIGIBLE_RETIRED = 'ineligible-retired',
  INELIGIBLE_AFFILIATE = 'ineligible-affiliate',
  CANNOT_PURCHASE = 'cannot-purchase',
  INCOMPLETE_CERTBA = 'incomplete-certba',
  ALL_EXAMS_PASSED = 'all-exams-passed',
  GEOLOCATION_ERROR = 'geolocation-error',
  EXAMS_CANCELLATION_WARNING = 'exams-cancellation-warning',
  FLP_PROCEED = 'flp-proceed',
  ACH_ERROR = 'ach-error',
  FLP_NO_EXCEPTION = 'flp-no-exception',
  HAS_ETHICS_VIOLATION = 'has-ethics-violation',
}

export const isPERMgmtLevelFailed = (studentProgression: User.StudentProgression): boolean => {
  const failOrRemainingStatusList = [
    User.SubjectBlockStatus.Bookable,
    User.SubjectBlockStatus.Booked,
    User.SubjectBlockStatus.Locked,
    User.SubjectBlockStatus.Active,
    User.SubjectBlockStatus.Failed,
  ];

  const qualificationLevels = (studentProgression?.qualificationLevels || []).filter(qualification => {
    if (
      hasTruthyValue(
        qualification?.name === MembershipTypes.CimaQualificationLevelPathValue.ManagementLevel,
        qualification?.name === MembershipTypes.CimaQualificationLevelPathValue.ChineseManagementLevel
      )
    ) {
      return qualification.blocks.filter(exam => exam.type === User.SubjectBlockType.CaseStudy);
    } else {
      // Return falsy if no qualifications match.
      // Covers instances where user does not have correct qualifications
      return false;
    }
  });

  if (qualificationLevels.length === 0) return true; // Cover the case where user does not have a correct qualification

  // Checks whether user has failed / not yet completed their Management Level
  return qualificationLevels
    .map(exams => {
      return exams.blocks
        .map(exam => {
          return (
            failOrRemainingStatusList.includes(exam.status) ||
            // Covers the case where a user has waived their exam exemption, but has not yet passed the exam
            (exam.status === User.SubjectBlockStatus.Waived && exam.resultStatus !== User.ResultStatus.Complete) ||
            exam.ratificationStatus === PearsonVue.ExamResultRatificationStatus.pendingRatification ||
            exam.ratificationStatus === PearsonVue.ExamResultRatificationStatus.onHold
          );
        })
        .includes(true);
    })
    .includes(true);
};

export const isCPQStratLevelUnlocked = (studentProgression: User.StudentProgression) => {
  const qualificationLevelBlocks = studentProgression?.qualificationLevels?.filter((quali: any) => {
    if (quali.name === MembershipTypes.CimaQualificationLevelPathValue.ChineseStrategicLevel) {
      return quali.blocks.filter((exam: any) => exam.type === PearsonVue.ExamType.caseStudy);
    }
  });
  // Checks whether Chinese Strategic Level is active or unlocked
  return Boolean(
    // add here if there are other possible status to show PER banner once Strategic level got unlock in Chinese PQ.
    qualificationLevelBlocks?.find((item: any) =>
      [BookStatus.ACTIVE, BookStatus.COMPLETED, BookStatus.EXEMPTED].includes(item.status)
    )
  );
};

export const FLPMapping = (studentProgression: any) => {
  // Assume that null values are currently at Level 1, workaround for PQ Cert BA users as they don't currently generate studentProgression values
  const studentEntryLevel = studentProgression?.exemptionsStatus?.entryPointLevel || 'Level 1';
  const FLPLevelsValues = Object.values(ExemptionLevels.FLPLevels);
  const FLPIndex = FLPLevelsValues.indexOf(UserUtils.conditionalFunction(studentEntryLevel, studentEntryLevel, ''));
  const failOrRemainingStatusList = [
    User.SubjectBlockStatus.Bookable,
    User.SubjectBlockStatus.Locked,
    User.SubjectBlockStatus.Active,
    User.SubjectBlockStatus.Failed,
  ];

  // Returns relevant exam blocks
  const qualificationLevelExamBlocks = studentProgression?.qualificationLevels
    .find((quali: any) => {
      // Get Qualification Level based on PQ Entry Point Level
      return quali.name === Object.values(ExemptionLevels.LevelNamePQ)[FLPIndex];
    })
    ?.blocks.filter((exam: any) => {
      if (
        [
          ExemptionLevels.FLPLevels.Level_1,
          ExemptionLevels.FLPLevels.Level_2,
          ExemptionLevels.FLPLevels.Level_4,
          ExemptionLevels.FLPLevels.Level_6,
        ].includes(studentEntryLevel)
      ) {
        return exam.type === User.SubjectBlockType.ObjectiveTest;
      }
      return exam.type === User.SubjectBlockType.CaseStudy;
    });

  const isFailOrRemainingExams = qualificationLevelExamBlocks
    ?.map((exam: any) => {
      return failOrRemainingStatusList.includes(exam.status);
    })
    .includes(true);

  let finalFLPIndex = FLPIndex;

  // If the FLP Level is less than Level 7
  if (FLPIndex < 6) {
    finalFLPIndex = isFailOrRemainingExams ? FLPIndex : FLPIndex + 1;
  }

  return finalFLPIndex;
};

export const getExemptionLevelOverride = (level: number, exemptionInfo: any) => {
  const FLPLevel = Object.values(ExemptionLevels.FLPLevels)[level];
  return exemptionInfo?.find((item: any) => item.name === FLPLevel);
};

export const getRejoiningFeeSlug = (
  inactiveMembershipsBody: MembershipTypes.MembershipBody,
  inactiveMembershipsTermType: MembershipTypes.MembershipKeys,
  learningPathway: MembershipTypes.Pathway | null | undefined,
  isFCMALapsed: boolean,
  isUserMemberLapsed: boolean,
  currentLearningPathway: string
): string => {
  let slug: string = '';

  // CIMA
  // Pathway is skipped during reactivation of Apprentice
  const isLapsedAndSubscribedToCimaApprenticeCandidate = AdminUtils.isCimaApprenticeCandidateProductType(
    learningPathway as string,
    inactiveMembershipsTermType
  );
  const isSubscribedToLapsedCimaAffiliate = AdminUtils.isCimaAffiliateType(
    inactiveMembershipsBody,
    inactiveMembershipsTermType
  );
  const isSubscribedToLapsedCimaRetired = AdminUtils.isCimaRetiredType(
    inactiveMembershipsBody,
    inactiveMembershipsTermType
  );
  const isSubscribedToLapsedCimaRegular = AdminUtils.isCimaRegularType(
    inactiveMembershipsBody,
    inactiveMembershipsTermType
  );
  const isSubscribedToCimaCgmaAffiliate = AdminUtils.isCimaCgmaAffiliateType(
    inactiveMembershipsBody,
    inactiveMembershipsTermType
  );

  const createSlugForCima = () => {
    if (
      checkAndSetSlug(
        areAllTruthy(
          isLapsedAndSubscribedToCimaApprenticeCandidate || isLapsedCandidateAndPqCurrentPathway,
          isUserMemberLapsed
        ),
        Product.Fee.CIMA_REJOINING_FEE_CANDIDATE
      ) ||
      checkAndSetSlug(isSubscribedToLapsedCimaRegular, Product.Fee.CIMA_REJOINING_FEE_REGULAR) ||
      checkAndSetSlug(isSubscribedToLapsedCimaAffiliate, Product.Fee.CIMA_REJOINING_FEE_AFFILIATE) ||
      checkAndSetSlug(isSubscribedToLapsedCimaRetired, Product.Fee.CIMA_REJOINING_FEE_RETIRED) ||
      checkAndSetSlug(isSubscribedToCimaCgmaAffiliate, Product.Fee.CIMA_REJOINING_FEE_CGMA_AFFILIATE)
    ) {
      // CIMA Candidate PQ or Apprentice
      //  CIMA Regular, Affiliate, Retired, or CGMA Affiliate
    }
  };
  const createSlugForInactiveAicpa = () => {
    if (
      checkAndSetSlug(
        inactiveMembershipsTermType === MembershipTypes.MembershipKeys.REGULAR,
        Product.Fee.AICPA_REJOINING_FEE_REGULAR
      ) ||
      checkAndSetSlug(
        inactiveMembershipsTermType === MembershipTypes.MembershipKeys.CANDIDATE,
        Product.Fee.AICPA_REJOINING_FEE_CANDIDATE
      ) ||
      checkAndSetSlug(
        inactiveMembershipsTermType === MembershipTypes.MembershipKeys.AFFILIATE,
        Product.Fee.AICPA_REJOINING_FEE_AFFILIATE
      ) ||
      checkAndSetSlug(
        inactiveMembershipsTermType === MembershipTypes.MembershipKeys.INTERNATIONAL_ASSOCIATE,
        Product.Fee.AICPA_REJOINING_FEE_INTERNATIONAL
      ) ||
      checkAndSetSlug(
        inactiveMembershipsTermType === MembershipTypes.MembershipKeys.RETIRED,
        Product.Fee.AICPA_REJOINING_FEE_RETIRED
      )
    ) {
      // AICPA Regular, Candidate, Affiliate, International, Student Affiliate, or Retired
    }
  };

  // Adding these flags since PQ can switch to FLP during lapsed and vice versa
  const isLapsedCandidateAndPqCurrentPathway =
    AdminUtils.isCimaPQCandidateProductType(currentLearningPathway, inactiveMembershipsTermType) ||
    AdminUtils.isChinesePQCandidateProductType(learningPathway as string, inactiveMembershipsTermType);

  const isLapsedAndFlpCurrentPathway = AdminUtils.isLapsedAndFlpCurrentPathway(
    isUserMemberLapsed,
    currentLearningPathway
  );

  const setSlug = (productFee: string): void => {
    slug = productFee;
  };

  const checkAndSetSlug = (condition: boolean, productFee: string): boolean => {
    if (condition) {
      setSlug(productFee);
      return true;
    }
    return false;
  };

  if (isFCMALapsed) {
    setSlug(Product.Fee.FCMA_REJOINING_FEE);
  } else if (
    checkAndSetSlug(
      areAllTruthy(
        isLapsedAndFlpCurrentPathway,
        inactiveMembershipsTermType === MembershipTypes.MembershipKeys.CANDIDATE
      ),
      Product.Fee.FLP_REJOINING_FEE
    )
  ) {
    // CIMA FLP
  } else if (inactiveMembershipsBody === MembershipTypes.MembershipBody.CIMA) {
    createSlugForCima();
  } else if (inactiveMembershipsBody === MembershipTypes.MembershipBody.AICPA) {
    createSlugForInactiveAicpa();
  }

  return slug;
};

export const getFLPModalConfirmPath = (
  portfolio: MembershipTypes.PERPortfolio,
  studentProgression: State.StudentProgression,
  pathway: any,
  FLPModalVariant?: any
) => {
  const showPEROverviewPage =
    areAllTruthy(portfolio, portfolio?.status, portfolio?.status !== MembershipTypes.PERPortfolioStatus.COMPLETED) ||
    areAllTruthy(
      !portfolio,
      !isPERMgmtLevelFailed(studentProgression),
      pathway === MembershipTypes.Pathway.PQ || pathway === MembershipTypes.Pathway.FLP
    );

  if (FLPModalVariant === ModalVariants.ALL_EXAMS_PASSED) {
    if (showPEROverviewPage) return Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT;

    return Routes.CIMA_EXAMS_DASHBOARD_ROOT;
  }

  const showMembershipPage = FLPModalVariant?.toLowerCase() === ModalVariants.INELIGIBLE_REGULAR.toLowerCase();
  if (showMembershipPage) return Routes.MY_MEMBERSHIPS;

  if (FLPModalVariant === ModalVariants.HAS_ETHICS_VIOLATION) return Routes.FEED;

  return Routes.FEED;
};

export const isProductForRenewal = (accessEndDate: string) => {
  if (!accessEndDate) return false;
  // Check Product is for Renewal for the next 3 months or 90 days
  const nowAfterThreeMonths = moment().add(3, 'months');
  return moment(accessEndDate.split('T')[0]).isSameOrBefore(nowAfterThreeMonths);
};

export const calculateAvailabilityDate = (
  isFlpType: boolean | undefined,
  isCartPage: boolean,
  flpTerm: string | undefined,
  isSection: boolean,
  isCredential: boolean,
  isMembership: boolean,
  isCenterMembership: boolean,
  isSubscription: boolean,
  membershipEndDate: any,
  item: Common.ProductItemData,
  isPQ?: any,
  isMembershipReactivationJourney: boolean = false,
  isCimaRenewalSeason?: any,
  activeMembership?: any
) => {
  const isFlpCart = [isFlpType, isCartPage].every((flag: boolean | undefined) => flag);
  const isFcmaOrMipItem = [Product.CredentialProductSlug.FCMA, Product.CredentialProductSlug.MIP].includes(
    item?.productLink as Product.CredentialProductSlug
  );
  const getDate = [
    isSection,
    areAllTruthy(isCredential, !isFcmaOrMipItem),
    isMembership,
    isCenterMembership,
    isFlpType,
    isSubscription,
  ].includes(true);

  if (areAllTruthy(isPQ, isMembership, isCartPage)) {
    const availabilityDate = getAvailabilityDate(
      moment(String(item.accessEndDate))
        .subtract(areAllTruthy(isCimaRenewalSeason, !activeMembership) ? 0 : 1, 'years')
        .toISOString(),
      isCartPage
    );
    if (parseFloat(availabilityDate) > 0) {
      return availabilityDate;
    }
  }
  if (isFlpCart) return flpTerm?.toLowerCase() as string;

  if (getDate) {
    // We want FLP to pick the endDate from Zuora while the known issue
    // with the incorrect dates coming from CT is being investigated
    const availabilityDate = isFlpType
      ? getAvailabilityDate(membershipEndDate || item.zuoraTermEndDate || item.accessEndDate, isCartPage)
      : getAvailabilityDate(membershipEndDate || item.accessEndDate || item.zuoraTermEndDate, isCartPage);

    return availabilityDate;
  }

  return getAvailabilityDate(item.accessEndDate, isCartPage);
};
