import React, { FC } from 'react';
import styled from 'styled-components';

export interface IRenewalDonationsListRow {
  title: string;
  price: number;
}
export const RenewalDonationsListRow: FC<IRenewalDonationsListRow> = ({ title, price }) => {
  return (
    <li>
      <PositionPriceContainer>
        <PricePosition underline>{title}</PricePosition>
        <PricePosition>${price}</PricePosition>
      </PositionPriceContainer>
    </li>
  );
};

const PositionPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.pxToRem(16)};
  padding-bottom: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.pxToRem(16)};
  text-align: left;
`;

const PricePosition = styled.div<{ underline?: boolean }>`
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.medium};
`;
