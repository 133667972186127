import React from 'react';
import { Product } from 'mxp-schemas';
import ReactDOMServer from 'react-dom/server';
import { CountriesListWithDialCode, Utils } from 'mxp-utils';

// FixMe: To many loops, must be optimized
export const highlight = (text: string, searches: string[], Wrapper: any) => {
  const elements: React.ReactNodeArray = [];
  text.split(' ').forEach((word, i) => {
    const match = searches.find(search => word.toLowerCase().indexOf(search.toLowerCase()) !== -1);

    if (match) {
      elements.push(<Wrapper key={i}>{word}</Wrapper>);
    } else {
      elements.push(word);
    }
  });

  return elements.map((element, i) => (
    <React.Fragment key={i}>
      {i !== 0 ? ' ' : ''}
      {element}
    </React.Fragment>
  ));
};

export const singleWordHighlight = (word: string, search: string, Wrapper: any) => {
  const index = word.toLowerCase().indexOf(search.toLowerCase());
  if (index === -1) {
    return word;
  }

  const beginning = word.slice(0, index);
  const ending = word.slice(index + search.length, word.length);
  return (
    <React.Fragment>
      {beginning}
      <Wrapper>{search}</Wrapper>
      {ending}
    </React.Fragment>
  );
};

export const priceToFloat = (price: string | undefined, forcePositive = true) => {
  if (!price) return 0;

  if (!forcePositive) {
    // Removing negative here since float can be negative or positive
    const cleanNegative = price && price.replace(/[^0-9\.-]+/g, ''); // eslint-disable-line
    return parseFloat(cleanNegative);
  }
  const clean = price && price.replace(/[^0-9\.-]+/g, ''); // eslint-disable-line
  return parseFloat(clean);
};

export const formattedPriceToDecimal = (price: string | undefined) => {
  if (!price) return '0';

  return price && price.replace(/(-|\$|,)/g, '');
};

export const formattedAnyPriceToDecimal = (price: string | undefined) => {
  if (!price) return '0';

  return price && price.replace(/(-|[$€£]|,)/g, '');
};

export const productTypeToLabel = (type: Product.ProductType | '') => {
  return Product.PRODUCT_TYPES_NAMES[type] || type;
};

export const subscriptionTypeToLabel = (type: string) => {
  return Product.SUBSCRIPTION_PRODUCT_TYPE_NAMES[type];
};

export const availableFormatToLabel = (type: string) => {
  return Product.AVAILABLE_FORMAT_NAMES[type] || type;
};

export const discontinuedAvailableFormatToLabel = (type: string) => {
  return Product.DISCONTINUED_AVAILABLE_FORMAT_NAMES[type] || type;
};

export const productTypeToSlug = (type: Product.ProductType) => {
  return Product.ProductTypesSlugsMap[type];
};

export const donationTypeToSlug = (type: Product.ProductType) => {
  return Product.DonationTypesSlugsMap[type];
};

export const isJSON = (text: string) => {
  try {
    JSON.parse(text);
  } catch (e) {
    return false;
  }
  return true;
};

export const trimNonNumeric = (text: any) => {
  if (!text) return 0;
  return text.replace(/\D/g, '');
};

export const isValidHTML = (html: string) => {
  if (typeof html !== 'string') {
    return true;
  }
  return Utils.isValidHtml(html);
};

export const isValidIframe = (html: string) => {
  if (typeof html !== 'string') {
    return true;
  }
  const validIframeTags = Boolean(html.match(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g));

  return validIframeTags;
};

export const isEmptyString = (value: string = '') => {
  return value.trim() === '';
};

export const isValidRichText = (text: any): boolean => {
  if (!text) return false;
  return Boolean(
    Utils.stripHtml(text)
      .replace(/&nbsp;/g, ' ')
      .trim()
  );
};

export const isNumericOrNA = (text: string): boolean => {
  return /(^[0-9]*$)|(^n\/a$)/gi.test(text);
};

export const richTextToPlainText = (text: string = ''): string => {
  return Utils.stripHtml(text).replace(/&nbsp;/g, ' ');
};

export const escapeString = (text: any) => {
  // Replace special characters with their HTML entities
  const escapedText = text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/=/g, '&equals;');

  // Convert Unicode characters outside the ASCII range to their numeric entity
  const unicodeRange = /[\u00A0-\u9999<>&]/gim;
  const formattedText = escapedText.replace(unicodeRange, (match: any) => `&#${match.charCodeAt(0)};`);

  // Preserve style attributes
  const preserveStyle = (match: any, p1: any, p2: any) => `${p1}${p2.replace(/&equals;/g, '=')}`;
  const withPreservedStyle = formattedText.replace(/(style=)(&quot;[^\r\n]*&quot;)/g, preserveStyle);

  return withPreservedStyle;
};

export const unescapeString = (text: string) => {
  if (text === undefined) {
    return '';
  }
  // Replace < and > characters inside HTML tags with their entities
  const escapedText = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  // Parse the escaped text using DOMParser
  const doc = new DOMParser().parseFromString(escapedText, 'text/html');
  let textContent = doc.documentElement.textContent || '';

  // Replace HTML entities back to their original characters
  textContent = textContent
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&nbsp;|&amp;nbsp;/g, ' ') // Remove &nbsp; and &amp;nbsp;
    .replace(/&amp;/g, '&'); // Remove &amp;

  // Preserve spaces after <p>, <li>, <li style="...">, <p style="...">, <span>, and <span style="...">
  const tagsToPreserveSpaces = ['p', 'li', 'span'];

  for (const tag of tagsToPreserveSpaces) {
    const tagWithStyle = `${tag}(\\s+style="[^"]*")?`;
    const tagRegex = new RegExp(`<${tagWithStyle}>(\\s*)`, 'g');

    textContent = textContent.replace(tagRegex, (match, style, spaces) => {
      const tagWithSpaces = style ? `<${tag}${style}>` : `<${tag}>`;
      return `${tagWithSpaces}${spaces.replace(/ /g, '&nbsp;')}`;
    });
  }

  // Handle nested escape codes
  while (textContent.includes('&amp;')) {
    textContent = textContent.replace(/&amp;/g, '&');
  }

  return textContent;
};

export const markupToString = (value: any) => Utils.stripHtml(ReactDOMServer.renderToStaticMarkup(value));

export const isLetterAndHyphen = (value: string) => /^[A-Za-z- ]+$/.test(value);

export const isStringEmptyOrNull = (value: any) => value === '' || value === null || value === undefined;

export const transformStringValueNullOrUndefined = (value: any) => {
  if (value === null || value === undefined) return '';
  return value;
};

export const transformCountryValue = (value?: string) => {
  // To check for 2 uppercase alphabet
  const regex = /^[A-Z]{0,2}$/;

  if (value && regex.test(value)) {
    return CountriesListWithDialCode?.find(countryList => countryList.code === value);
  }

  return CountriesListWithDialCode?.find(countryList => countryList.dialCode === value);
};

export const getCountryCodeByDialCode = (dialCode: string) => {
  return CountriesListWithDialCode?.find((country: any) => dialCode === country.dialCode)?.code || '';
};

// Gets file extension from URL, or return false if there's no extension
export const getFileExtension = (url?: any) => {
  // Extension starts after the first dot after the last slash
  const fileExtensionStart = url.indexOf('.', url.lastIndexOf('/') + 1);
  if (fileExtensionStart === -1) {
    return false;
  }
  const fileExtension = url.substr(fileExtensionStart + 1);
  // end of extension must be one of: end-of-string or question-mark or hash-mark
  const fileExtensionEnd = fileExtension.search(/$|[?#]/);
  return fileExtension.substring(0, fileExtensionEnd);
};

export const transformFileName = (response?: any) => {
  const file = response?.payload?.file.replace(/\.[^\/.]+$/, '').replace(/_/g, ' '); // eslint-disable-line
  const fileName = file.replace(/(^\w{1})|(\s\w{1})/g, (letter: string) => letter.toUpperCase());

  const reflectiveStatementFileName = response?.payload?.file.includes('reflective');
  const portfolioFileName = response?.payload?.file.includes('portfolio');

  let returnFileName;
  if (portfolioFileName) {
    returnFileName = fileName.slice(0, 21);
  } else if (reflectiveStatementFileName) {
    returnFileName = fileName.slice(0, 22);
  } else {
    returnFileName = fileName.slice(0, 14);
  }

  return `Your ${returnFileName} was successfully uploaded`;
};

// To check if value contains characters from a - z and has special characters.
export const validateGraduationYear = (value: any) =>
  /[a-z]/i.test(value) || /[!@#$%^&*`~()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value); // eslint-disable-line
