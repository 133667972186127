import { Product } from 'mxp-schemas';
import { AllUSRegions } from 'mxp-utils';

export enum AddressValidation {
  suggested = 'suggested',
  existing = 'existing',
}

export const handleConfirmAddress = async (
  addressValidationOption: AddressValidation,
  areShippingAndBillingAddressesInvalid: boolean,
  nextStep: boolean,
  isShippingAddressValid: boolean,
  isBillingAddressValid: boolean,
  isSuggestedShippingAddressAccepted: boolean,
  setLoadingButtonType: (modifier: AddressValidation) => void,
  handleFirstAddressConfirmation: (isSuggestedAddress: boolean) => void,
  handleUserConfirmationOfShippingAddress: (isSuggestedShippingAddressAccepted: boolean) => Promise<State.Address | undefined>,
  handleUserConfirmationOfBillingAddress: (isSuggestedBillingAddressAccepted: boolean) => void,
  completeValidationOrGoToPayment: (customAddress: State.Address | undefined) => void
) => {
  const isSuggested = addressValidationOption === AddressValidation.suggested;
  setLoadingButtonType(addressValidationOption);
  let customShippingAddress: State.Address | undefined;

  // When user has both an 'invalid' shipping and billing address,
  // They are not allowed to proceed to the next section (payment screen),
  // without first confirming both addresses
  if (areShippingAndBillingAddressesInvalid && !nextStep) {
    handleFirstAddressConfirmation(isSuggested);
    return;
  }

  if (!isShippingAddressValid) {
    // isSuggestedShippingAddressAccepted is only relevant when there are multiple addresses,
    // and the second address is covered by addressValidationOption
    const shippingAddressIsSuggested = areShippingAndBillingAddressesInvalid
      ? isSuggestedShippingAddressAccepted
      : isSuggested;
    customShippingAddress = await handleUserConfirmationOfShippingAddress(shippingAddressIsSuggested);
  }
  if (!isBillingAddressValid) handleUserConfirmationOfBillingAddress(isSuggested);
  completeValidationOrGoToPayment(customShippingAddress);
};

export const getAddressValidationBypassFlags = (
  isAicpaMember: boolean | undefined,
  cartSelectedMembership: State.LineItem | undefined,
  shippingAddress?: State.Address,
  billingAddress?: State.Address
): State.AddressValidationBypassFlags => {
  const cartSelectedMembershipBody = cartSelectedMembership?.variant?.attributes?.membershipBody?.key;
  const isMembershipInCartAICPA = cartSelectedMembershipBody === Product.ProductMembershipBody.AICPA;
  const isAICPAMemberOrProduct = isAicpaMember || isMembershipInCartAICPA;

  const isShippingAddressInValidationRequiredRegion =
    shippingAddress?.country && AllUSRegions.includes(shippingAddress?.country);
  const isBillingAddressInValidationRequiredRegion =
    billingAddress?.country && AllUSRegions.includes(billingAddress?.country);

  const addressValidationFlags: State.AddressValidationBypassFlags = {
    allowShippingAddressValidationBypass: !isShippingAddressInValidationRequiredRegion && !isAICPAMemberOrProduct,
    allowBillingAddressValidationBypass: !isBillingAddressInValidationRequiredRegion && !isAICPAMemberOrProduct,
  };

  return addressValidationFlags;
};

export const getModalText = (isShippingAddressInvalid: boolean, isBillingAddressInvalid: boolean) => {
  const areShippingAndBillingInvalid = isShippingAddressInvalid && isBillingAddressInvalid;

  const invalidAddresses = [];
  if (isShippingAddressInvalid) invalidAddresses.push('shipping');
  if (isBillingAddressInvalid) invalidAddresses.push('billing');
  const invalidAddressesText = invalidAddresses.join(' and ');
  const pluralSuffix = areShippingAndBillingInvalid ? 'es' : '';
  const linkingVerb = areShippingAndBillingInvalid ? 'are' : 'is';

  const modalText = `Sorry, your address${pluralSuffix} ${linkingVerb} not recognised. Please go back and edit your ${invalidAddressesText} address${pluralSuffix}, or continue with the address${pluralSuffix} you entered.`;
  return modalText;
};
