import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { AccordionTitleProps, Accordion, Transition } from 'semantic-ui-react';
import { Container } from 'components/atoms';
import { ExamProficiencyResults } from '../ExamProficiencyResults/ExamProficiencyResults';
import { User } from 'mxp-schemas';
import { getExamResultStatusIcon } from 'utils/index';
import { isDistinction } from 'utils';

interface Props {
  allExamsResults: User.AllStudentExamResults;
}

export const ExamCardResults: React.FC<Props> = React.memo(({ allExamsResults }) => {
  const [activeIndices, setActiveIndices] = useState(
    allExamsResults.exams
      ? allExamsResults.exams?.map((exam, index: number) => ({
          stepNumber: index,
          isOpen: false,
        }))
      : [
          {
            stepNumber: -1,
            isOpen: false,
          },
        ]
  );

  const handleAccordionClick = useCallback((e, { index }: AccordionTitleProps) => {
    if (index !== undefined) {
      setActiveIndices(currData => {
        return currData?.map(currentIndex => {
          const newActiveIndex = {
            stepNumber: currentIndex.stepNumber,
            isOpen: currentIndex.isOpen,
          };

          if (currentIndex.stepNumber === +index) {
            newActiveIndex.isOpen = !currentIndex.isOpen;
          }

          return newActiveIndex;
        });
      });
    }
  }, []);

  return (
    <StyledContainer>
      <Accordion>
        <StyledHeaderTitleLevel>{allExamsResults?.qualificationLevel}</StyledHeaderTitleLevel>
        {allExamsResults?.exams?.map((exam: User.Exams, index: number) => {
          const isResultDistinction = isDistinction(exam);
          return (
            <>
              <Accordion.Title
                key={exam.id}
                index={index}
                active={activeIndices[index].isOpen}
                onClick={handleAccordionClick}
              >
                <StyledLiContainer>
                  <StyledTitle>{`${exam.subjectName} ${
                    exam.syllabus?.length ? `(${exam.syllabus})` : ''
                  }`}</StyledTitle>
                  <StyledInfoPillContainer>
                    <StyledDate>{exam.examDate}</StyledDate>
                    <StyledStatusContainer>
                      <StyledSectionStatus color={exam.status}>
                        <StyledIcons>
                          {exam?.status && <img src={getExamResultStatusIcon(exam.status)} alt={exam.status} />}
                        </StyledIcons>
                        <span>{isResultDistinction ? 'Distinction' : exam.status}</span>
                      </StyledSectionStatus>
                      <span>{activeIndices[index].isOpen ? '-' : '+'}</span>
                    </StyledStatusContainer>
                  </StyledInfoPillContainer>
                </StyledLiContainer>
              </Accordion.Title>
              <StyledLines />

              <Transition visible={activeIndices[index].isOpen} animation="scale" duration={500}>
                <Accordion.Content>
                  <StyledContentContainer>
                    <StyledLabel>YOUR EXAM</StyledLabel>
                    <ExamProficiencyResults examSectionRecords={exam} key={exam.id} />
                  </StyledContentContainer>
                  <StyledContentContainer>
                    <StyledLabel>PAST ATTEMPTS</StyledLabel>
                    {exam.previousExams.map((prev: any) => {
                      return (
                        <StyledContentContainer key={prev?.id}>
                          <ExamProficiencyResults examSectionRecords={prev} />
                        </StyledContentContainer>
                      );
                    })}
                  </StyledContentContainer>
                </Accordion.Content>
              </Transition>
            </>
          );
        })}
      </Accordion>
    </StyledContainer>
  );
});

const getBackgroundColor = (color: string, theme: any) => {
  switch (color) {
    case User.StudentExamResultStatus.PASS:
      return css`
        background-color: ${theme.colors.interfaceGreen};
      `;
    case User.StudentExamResultStatus.FAIL:
      return css`
        background-color: #ac8208;
      `;
    case User.StudentExamResultStatus.NO_SHOW:
      return css`
        background-color: ${theme.colors.neutralBlack};
      `;
    case User.StudentExamResultStatus.NDA_REFUSED:
      return css`
        background-color: ${theme.colors.neutralBlack};
        width: ${props => props.theme.pxToRem(110)};
      `;
    default:
      return css``;
  }
};

const StyledContainer = styled(Container)`
  font-family: Roboto;
  margin-top: ${props => props.theme.pxToRem(40)};
  margin-bottom: ${props => props.theme.pxToRem(80)};
`;

const StyledLiContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.theme.pxToRem(10)};
  padding-bottom: ${props => props.theme.pxToRem(8)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    display: block;
    margin-left: ${props => props.theme.pxToRem(-5)};
  }
`;

const StyledContentContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)};
  width: 100%;
`;

const StyledLabel = styled.div`
  font-size: ${props => props.theme.pxToRem(12)};
  margin-top: ${props => props.theme.pxToRem(10)};
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

const StyledInfoPillContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &&&& {
    span {
      color: ${props => props.theme.colors.neutralGrey9};
      font-size: ${props => props.theme.fontSizes.xs};
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${props => props.theme.pxToRem(25)};
  }
`;

const StyledTitle = styled.div`
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.thin};
  margin-bottom: ${props => props.theme.pxToRem(10)};
  line-height: 1.33;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(25)};
    font-size: ${props => props.theme.pxToRem(20)};
  }
`;

const StyledLines = styled.div`
  height: ${props => props.theme.pxToRem(1)} !important;
  border-radius: ${props => props.theme.pxToRem(4)};
  background-image: linear-gradient(
    87deg,
    ${props => props.theme.colors.neutralGrey8},
    ${props => props.theme.colors.neutralGrey8} 100%
  );
  width: ${props => props.theme.pxToRem(60)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.pxToRem(235)}
  height: ${props => props.theme.pxToRem(24)}
  font-size: ${props => props.theme.fontSizes.xxs};
  border-radius: ${props => props.theme.pxToRem(10)};
  margin-right: ${props => props.theme.pxToRem(20)};
  background: ${props => props.theme.colors.neutralGrey1};
`;

const StyledStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const StyledSectionStatus = styled.div<{ color: string }>`
 display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: ${props => props.theme.fontSizes.xs};
  border-radius: ${props => props.theme.pxToRem(20)};
  width: ${props => props.theme.pxToRem(89)}
  height: ${props => props.theme.pxToRem(20)}
  padding-left: ${props => props.theme.pxToRem(5)};
  padding-right:${props => props.theme.pxToRem(10)};
  margin-right:${props => props.theme.pxToRem(15)};
  color: ${props => props.theme.colors.neutralWhite};
  background: ${props => props.theme.colors.interfaceGreen};

  &&&& {
    span {
      color: ${props => props.theme.colors.neutralWhite};
      font-size: ${props => props.theme.fontSizes.xxs};
    }
  }

  ${({ color, theme }) => getBackgroundColor(color, theme)}
`;

const StyledIcons = styled.div`
  width: ${props => props.theme.pxToRem(15)};
  height: ${props => props.theme.pxToRem(15)};
  display: flex;
`;

const StyledHeaderTitleLevel = styled.div`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.xl};
  line-height: 1.38;
  letter-spacing: normal;
  margin-bottom: ${props => props.theme.pxToRem(30)}
  color: ${props => props.theme.colors.neutralGrey8};
   ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(10)};
    margin-right: ${props => props.theme.pxToRem(10)};
    position: relative;
    left: ${props => props.theme.pxToRem(-20)};
    width: 100%;
  }
`;
