import { topicsSlugIdMapSelector, subtopicsSlugIdMapSelector } from './selectors';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Helpers
// ------------------------------------

/*
 * Convert topic filter slugs to topic ids
 */
export const convertTopicsSlugsToIds = (topics: string | string[], state: State.Root): string[] | undefined => {
  if (!topics) return;
  const topicsSlugs: string[] = Array.isArray(topics) ? topics : [topics];
  // Call topicsSlugIdMapSelector only if topicsSlugs exist
  const topicsSlugIdMap: { [key: string]: string } = topicsSlugIdMapSelector(state);
  // Get topics ids array from slugs if exist
  return topicsSlugs.map(slug => topicsSlugIdMap[slug]).filter(Boolean);
};

/*
 * Convert subtopic filter slugs to subtopic ids
 */
export const convertSubtopicsSlugsToIds = (subtopics: string | string[], state: State.Root): string[] | undefined => {
  if (!subtopics) return;
  const subtopicsSlugs: string[] = Array.isArray(subtopics) ? subtopics : [subtopics];
  // Call subtopicsSlugIdMapSelector only if subtopicsSlugs exist
  const subtopicsSlugIdMap: { [key: string]: string } = subtopicsSlugIdMapSelector(state);
  // Get subtopics ids array from slugs if exist
  const subtopicIds = subtopicsSlugs.map(slug => subtopicsSlugIdMap[slug]).filter(Boolean);

  if (!subtopicIds.length) return;

  return subtopicIds;
};

/*
 * slug id hash map creator
 */
export const slugIdMapCreator = (topics: any[] | null): { [key: string]: string } => {
  if (!topics) return emptyObject;
  return topics.reduce((acc: any, topic: State.Topic | State.Subtopic) => {
    acc[topic.slug] = topic.id;
    return acc;
  }, {});
};
