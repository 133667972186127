import React from 'react';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { ReactComponent as Cancel } from 'resources/images/ic-cancel.svg';
import { Button, ButtonEnums } from 'components/atoms';

interface FilterTagProps {
  name: string;
  id: string;
  filterName: string;
}

interface CloudTagProps extends FilterTagProps {
  changeFilter: (name: string | string[], value: string | string[]) => void;
}

interface ContentSideFilterProps {
  topicFilterSelectedItems: FilterTagProps[];
  subtopicFilterSelectedItems: FilterTagProps[];
  categoryFilterSelectedItems: FilterTagProps[];
  contentTypeFilterSelectedItems: FilterTagProps[];
  premiumContentSelectedItems: FilterTagProps[];
  cpeCreditFilterSelectedItems: FilterTagProps[];
  getNoticedFilterSelectedItems: FilterTagProps[];
  webcastPassFilterSelectedItems: FilterTagProps[];
  nasbaFieldSelectedItems?: FilterTagProps[];
  nasbaProgramSelectedItems?: FilterTagProps[];
  availableFormatSelectedItems?: FilterTagProps[];
  eventDateSelectedItems?: FilterTagProps[];
  contentSourceSelectedItems?: FilterTagProps[];
  groupSelectedItems?: FilterTagProps[];
  changeFilter: (name: string | string[], value: string | string[]) => void;
  clearFilters: () => void;
  resultsFetched: boolean;
  industryFilterSelectedItems: FilterTagProps[];
  skillsFilterSelectedItems: FilterTagProps[];
  subskillsFilterSelectedItems: FilterTagProps[];
  trendsFilterSelectedItems: FilterTagProps[];
  jobRolesFilterSelectedItems: FilterTagProps[];
  careerStagesFilterSelectedItems: FilterTagProps[];
  contentLanguagesFilterSelectedItems: FilterTagProps[];
  locationsFilterSelectedItems: FilterTagProps[];
  countriesFilterSelectedItems: FilterTagProps[];
  statesFilterSelectedItems: FilterTagProps[];
}

const CloudTag: React.FC<CloudTagProps> = React.memo(({ id, name, filterName, changeFilter }) => {
  const handleDeleteTagClick = React.useCallback(() => {
    changeFilter(filterName, id);
  }, [filterName, id, changeFilter]);

  return (
    <StyledButtonTag
      testId={`remove-filter-${id}`}
      variant={ButtonEnums.variants.standAloneLink}
      size={ButtonEnums.sizes.medium}
      icon={<StyledCancelTag />}
      iconPosition={ButtonEnums.iconPosition.right}
      onClick={handleDeleteTagClick}
    >
      {name}
    </StyledButtonTag>
  );
});

export const ContentFilterCloud: React.FC<ContentSideFilterProps> = ({
  topicFilterSelectedItems,
  subtopicFilterSelectedItems,
  categoryFilterSelectedItems,
  contentTypeFilterSelectedItems,
  cpeCreditFilterSelectedItems,
  getNoticedFilterSelectedItems,
  webcastPassFilterSelectedItems,
  premiumContentSelectedItems,
  nasbaFieldSelectedItems = [],
  nasbaProgramSelectedItems = [],
  availableFormatSelectedItems = [],
  eventDateSelectedItems = [],
  contentSourceSelectedItems = [],
  groupSelectedItems = [],
  resultsFetched,
  changeFilter,
  clearFilters,
  industryFilterSelectedItems,
  skillsFilterSelectedItems,
  subskillsFilterSelectedItems,
  trendsFilterSelectedItems,
  jobRolesFilterSelectedItems,
  careerStagesFilterSelectedItems,
  contentLanguagesFilterSelectedItems,
  locationsFilterSelectedItems,
  countriesFilterSelectedItems,
  statesFilterSelectedItems,
}) => {
  const tags: FilterTagProps[] = [
    ...topicFilterSelectedItems,
    ...subtopicFilterSelectedItems,
    ...categoryFilterSelectedItems,
    ...contentTypeFilterSelectedItems,
    ...premiumContentSelectedItems,
    ...cpeCreditFilterSelectedItems,
    ...getNoticedFilterSelectedItems,
    ...webcastPassFilterSelectedItems,
    ...nasbaFieldSelectedItems,
    ...nasbaProgramSelectedItems,
    ...availableFormatSelectedItems,
    ...eventDateSelectedItems,
    ...contentSourceSelectedItems,
    ...groupSelectedItems,
    ...industryFilterSelectedItems,
    ...skillsFilterSelectedItems,
    ...subskillsFilterSelectedItems,
    ...trendsFilterSelectedItems,
    ...jobRolesFilterSelectedItems,
    ...careerStagesFilterSelectedItems,
    ...contentLanguagesFilterSelectedItems,
    ...locationsFilterSelectedItems,
    ...countriesFilterSelectedItems,
    ...statesFilterSelectedItems,
  ];

  return (
    <>
      {resultsFetched && tags.length ? (
        <Label.Group>
          {tags.map((tag: FilterTagProps) => (
            <CloudTag
              key={tag.id}
              id={tag.id}
              name={tag.name}
              filterName={tag.filterName}
              changeFilter={changeFilter}
            />
          ))}
          <StyledButton
            testId={`clear-all-filters`}
            variant={ButtonEnums.variants.standAloneLink}
            size={ButtonEnums.sizes.medium}
            icon={<StyledCancel />}
            iconPosition={ButtonEnums.iconPosition.left}
            onClick={clearFilters}
          >
            Clear all filters
          </StyledButton>
        </Label.Group>
      ) : null}
    </>
  );
};

const StyledCancel = styled(Cancel)`
  &&&&&&& {
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
    color: ${props => props.theme.colors.primaryPurple};
    margin-bottom: ${props => props.theme.pxToRem(-3)};
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: 0;
  }
`;

const StyledCancelTag = styled(Cancel)`
  &&&&&&& {
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
    color: ${props => props.theme.colors.primaryPurple};
    margin-bottom: ${props => props.theme.pxToRem(-4)};
    margin-right: 0;
    margin-left: ${props => props.theme.pxToRem(9)};
    flex: none;
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.medium};
    display: inline-block;
    margin-top: 1rem;
    margin-left: 0;
    margin-bottom: 2rem;
    display: block;
  }
`;
const StyledButtonTag = styled(Button)`
  &&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
    font-size: ${props => props.theme.fontSizes.xs};
    line-height: 1.57;
    margin: 0 ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(8)} 0;
    padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(12)};
    border-radius: 0;
    background-color: ${props => props.theme.colors.neutralGrey2};
    text-align: left;
    color: ${props => props.theme.colors.primaryPurple};

    &:hover {
      background-color: ${props => props.theme.colors.primaryPurple};
      color: ${props => props.theme.colors.neutralGrey2};

      span {
        text-decoration: none;
      }
      && svg {
        color: ${props => props.theme.colors.neutralGrey2};
      }
    }
  }
`;
