// ------------------------------------
// Constants
// ------------------------------------
export const initialState: State.UploadedDocument = {
  uploadedDocuments: [],
  uploadedDocumentsFetched: false,
  uploadedDocumentsFrontloadRequest: false,
};

export const apprenticeGatewayMathEnglish = 'MathsandEnglishGCSELevel2certificates';

export const requiredDocumentGateway = [
  'GraduationCertificate',
  'AcademicModuleTranscripts',
  'UniversityRegistration',
  'MathsandEnglishGCSELevel2certificates',
];
