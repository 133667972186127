import styled from 'styled-components';
import React from 'react';

interface Props {
  text: string | JSX.Element;
}

export const CenterAdminDataTableMessageViewText: React.FC<Props> = ({ text }) => {
  return <StyledMessageViewText>{text}</StyledMessageViewText>;
};

const StyledMessageViewText = styled.p`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
`;
