import React, { useState } from 'react';
import styled from 'styled-components';
import { InputWithValidation } from 'components/molecules';
import { Checkbox, Dropdown } from 'components/atoms';
import { CheckboxProps as SemanticUICheckboxProps, StrictDropdownProps } from 'semantic-ui-react';
import { Utils, InquiryForm as InquiryFormUtils } from 'mxp-utils';
import { IAmInterestedInTrainingForOptions } from 'constants/index';
import { InquiryForm } from 'mxp-schemas';
import { MobileInput } from 'components/atoms/Input/MobileInput';
import { CountriesDropdown } from '../AuthenticationModal/CountriesDropdown';
import { emptyArray } from 'utils';

interface InquiryFormMembershipProps {
  setFormValid: (valid: boolean) => void;
  form: InquiryForm.Membership;
  setForm: (form: InquiryForm.Membership) => void;
  onFormStart: () => void;
}

export const InquiryFormMembership: React.FC<InquiryFormMembershipProps> = ({
  form,
  setForm,
  setFormValid,
  onFormStart,
}) => {
  const [countrySelected, setCountrySelected] = useState('');
  const [checkIWorkInBAI, setIWorkInBAI] = useState(false);
  const [checkIWorkInTax, setIWorkInTax] = useState(false);
  const [checkIWorkInASF, setIWorkInASF] = useState(false);
  const [checkIWorkInOthers, setIWorkInOthers] = useState(false);
  const [checkEmailReceived, setCheckEmailReceived] = useState(true);
  const isinquiryRoleEmpty = !Boolean(form.inquiryRole.length);
  const isCountryForEmpty = !Boolean(countrySelected);
  const checkState = countrySelected === 'US' ? true : false;
  const isStateForEmpty = checkState ? false : !Boolean(form.state.length);
  const isFirstNameEmpty = !Boolean(form.firstName.length);
  const isIWorkInEmpty = !Boolean(form.iWorkIn.length);
  const isFirstNameValid = Utils.isShorterThan(form.firstName, InquiryForm.FieldLengths.FIRST_NAME);
  const isFirstNameLegal = !Boolean(/^$|^[\p{L} '-]+$/u.test(form.firstName) || null);
  const isLastNameEmpty = !Boolean(form.lastName.length);
  const isLastNameValid = Utils.isShorterThan(form.lastName, InquiryForm.FieldLengths.LAST_NAME);
  const isLastNameLegal = !Boolean(/^$|^[\p{L} '-]+$/u.test(form.lastName) || null);
  const isEmailEmpty = !Boolean(form.email.length);
  const isEmailValid =
    Utils.isEmailFormatValid(form.email) && Utils.isShorterThan(form.email, InquiryForm.FieldLengths.EMAIL);
  const [phoneObject, setPhoneObject] = useState({
    phoneNumber: '',
    countryCode: '',
    dialCode: '',
  });
  const isPhoneEmpty = !Boolean(phoneObject.phoneNumber.length);
  const isCountryCodeEmpty = !Boolean(phoneObject.countryCode.length);
  const isPhoneValid =
    (isPhoneEmpty && isCountryCodeEmpty) ||
    (isPhoneEmpty && !isCountryCodeEmpty) ||
    (!isPhoneEmpty && !isCountryCodeEmpty);
  const mobileErrorMessage = isCountryCodeEmpty && !isPhoneEmpty ? 'Please enter country code' : null;
  form.phone = phoneObject.dialCode + phoneObject.phoneNumber;
  checkState ? (form.receivedEmail = true) : (form.receivedEmail = checkEmailReceived);
  const [stateOptions, setStateOptions] = useState(emptyArray);
  const [isStateDropdownDisabled, setStateDropdownDisabled] = useState(true);

  const isStateOptions = React.useCallback(() => {
    return Boolean(stateOptions?.length);
  }, [stateOptions]);

  const isStateValid = stateOptions === undefined ? true : false;
  const stateValidation = isStateValid ? false : isStateForEmpty;

  React.useEffect(() => {
    const formValid =
      !isEmailEmpty &&
      isEmailValid &&
      !isFirstNameEmpty &&
      isFirstNameValid &&
      !isFirstNameLegal &&
      !isLastNameEmpty &&
      !isLastNameLegal &&
      isLastNameValid &&
      (isPhoneEmpty || isPhoneValid) &&
      !isinquiryRoleEmpty &&
      !isCountryForEmpty &&
      !stateValidation &&
      !isIWorkInEmpty;
    setFormValid(formValid);
  }, [
    form,
    isEmailValid,
    isEmailEmpty,
    isFirstNameEmpty,
    isFirstNameValid,
    isFirstNameLegal,
    isLastNameEmpty,
    isLastNameValid,
    isLastNameLegal,
    isStateValid,
    isPhoneEmpty,
    isPhoneValid,
    isinquiryRoleEmpty,
    isCountryForEmpty,
    isIWorkInEmpty,
    stateValidation,
    setFormValid,
  ]);

  React.useEffect(() => {
    !isStateOptions() ? setStateDropdownDisabled(true) : setStateDropdownDisabled(false);
  }, [isStateOptions]);

  const handleDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedCountry: string = data.value as string;
      setForm({ ...form, country: selectedCountry, state: '' });
      setCountrySelected(selectedCountry);
      setStateOptions(InquiryFormUtils.getStatesByCountry(selectedCountry));
    },
    [setForm, form]
  );

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, firstName: event.target.value });
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, lastName: event.target.value });
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, email: event.target.value });
  };

  const handleIamSelectedDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedinquiryRole: string = data.value as string;
      setForm({ ...form, inquiryRole: selectedinquiryRole });
    },
    [setForm, form]
  );

  const handleStateDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedState: string = data.value as string;
      setForm({ ...form, state: selectedState });
    },
    [setForm, form]
  );

  const handleIWorkInChangeBAI = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      if (data.checked) {
        setForm({ ...form, iWorkIn: `${form.iWorkIn} Business and Industry;` });
      } else {
        setForm({ ...form, iWorkIn: form.iWorkIn.replace('Business and Industry;', '') });
      }
      setIWorkInBAI(Boolean(data.checked));
    },
    [setForm, form]
  );

  const handleIWorkInChangeTAX = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      if (data.checked) {
        setForm({ ...form, iWorkIn: `${form.iWorkIn} Tax;` });
      } else {
        setForm({ ...form, iWorkIn: form.iWorkIn.replace('Tax;', '') });
      }
      setIWorkInTax(Boolean(data.checked));
    },
    [setForm, form]
  );

  const handleIWorkInChangeASF = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      if (data.checked) {
        setForm({ ...form, iWorkIn: `${form.iWorkIn} A Small Firm;` });
      } else {
        setForm({ ...form, iWorkIn: form.iWorkIn.replace('A Small Firm;', '') });
      }
      setIWorkInASF(Boolean(data.checked));
    },
    [setForm, form]
  );

  const handleIWorkInChangeOthers = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      if (data.checked) {
        setForm({ ...form, iWorkIn: `${form.iWorkIn} Other;` });
      } else {
        setForm({ ...form, iWorkIn: form.iWorkIn.replace('Other;', '') });
      }
      setIWorkInOthers(Boolean(data.checked));
    },
    [setForm, form]
  );

  const handleTellMeMoreChange = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      setForm({ ...form, receivedEmail: Boolean(data.checked) });
      setCheckEmailReceived(Boolean(data.checked));
    },
    [setForm, form, setCheckEmailReceived]
  );

  const handleSmsAgreeChange = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      setForm({ ...form, receivedSms: Boolean(data.checked) });
    },
    [setForm, form]
  );

  const fieldRequired = 'Required';
  const fieldMaxLength = (length: number) => `Sorry, that's too long. Must be fewer than ${length} characters.`;
  return (
    <>
      <InputWithValidation
        labelName={'First Name'}
        name={InquiryForm.FieldIds.FIRST_NAME}
        type="text"
        value={form.firstName}
        testId={InquiryForm.FieldIds.FIRST_NAME}
        labelId={InquiryForm.FieldIds.FIRST_NAME}
        onChange={handleFirstNameChange}
        onClick={onFormStart}
        isCorrect={isFirstNameValid || isFirstNameEmpty}
        showEmptyError={(isFirstNameEmpty && !isEmailEmpty) || (isFirstNameEmpty && !isPhoneEmpty)}
        onlyLegalCharacters={isFirstNameLegal}
        infoMessage={fieldRequired}
        placeholder="Enter first name here"
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.FIRST_NAME)}`}
      />
      <InputWithValidation
        labelName={'Last Name'}
        name={InquiryForm.FieldIds.LAST_NAME}
        type="text"
        value={form.lastName}
        testId={InquiryForm.FieldIds.LAST_NAME}
        labelId={InquiryForm.FieldIds.LAST_NAME}
        onChange={handleLastNameChange}
        isCorrect={isLastNameValid || isLastNameEmpty}
        showEmptyError={(isLastNameEmpty && !isEmailEmpty) || (isLastNameEmpty && !isPhoneEmpty)}
        onlyLegalCharacters={isLastNameLegal}
        infoMessage={fieldRequired}
        placeholder="Enter last name here"
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.LAST_NAME)}`}
      />
      <InputWithValidation
        labelName={'Email Address'}
        name={InquiryForm.FieldIds.EMAIL}
        type="email"
        value={form.email}
        testId={InquiryForm.FieldIds.EMAIL}
        labelId={InquiryForm.FieldIds.EMAIL}
        onChange={handleEmailChange}
        isCorrect={isEmailValid || isEmailEmpty}
        infoMessage={fieldRequired}
        placeholder="Enter email here"
        errorMessage={'Please enter a valid email address.'}
        isCorrectIconShown
      />
      <StyledMobileInput phoneObject={phoneObject} setPhoneObject={setPhoneObject} errorMessage={mobileErrorMessage} />
      <CountriesDropdown onChange={handleDropdownChange} title="Country" countrySelected={countrySelected} />
      <DropDownContainer>
        <Label>State/Province</Label>
        <StyledDropdown
          testId={InquiryForm.FieldIds.STATE}
          onChange={handleStateDropdownChange}
          options={stateOptions}
          value={form.state}
          placeholder="Select state/province"
          search={true}
          disabled={isStateDropdownDisabled}
        />
      </DropDownContainer>
      <DropDownContainer>
        <Label>I am a...</Label>
        <StyledDropdown
          testId={InquiryForm.FieldIds.INQUIRY_ROLE}
          onChange={handleIamSelectedDropdownChange}
          options={IAmInterestedInTrainingForOptions}
          value={form.inquiryRole}
          placeholder="I am a..."
          errorMessage="Please select the relevant option"
        />
      </DropDownContainer>
      <Label>I work in</Label>
      <CheckBoxContainer>
        <StyledCheckbox
          width="18"
          height="18"
          type="checkbox"
          testId={`BAI`}
          label={`Business and Industry`}
          value="Business and Industry"
          checked={checkIWorkInBAI}
          onChange={handleIWorkInChangeBAI}
        />

        <StyledCheckbox
          width="18"
          height="18"
          type="checkbox"
          testId={`TAX`}
          label={`Tax`}
          value="Tax"
          checked={checkIWorkInTax}
          onChange={handleIWorkInChangeTAX}
        />
      </CheckBoxContainer>
      <CheckBoxContainer>
        <StyledCheckbox
          width="18"
          height="18"
          type="checkbox"
          testId={`SF`}
          label={`A Small Firm`}
          value="A Small Firm"
          checked={checkIWorkInASF}
          onChange={handleIWorkInChangeASF}
        />
        <StyledAlignCheckBox>
          <StyledCheckbox
            width="18"
            height="18"
            type="checkbox"
            testId={`OTHER`}
            label={`Other`}
            value="Other"
            checked={checkIWorkInOthers}
            onChange={handleIWorkInChangeOthers}
          />
        </StyledAlignCheckBox>
      </CheckBoxContainer>
      {!checkState && (
        <StyledPermissionCheckboxWrapper>
          <StyledCheckbox
            width="18"
            height="18"
            type="checkbox"
            testId={InquiryForm.FieldIds.RECEIVED_EMAIL}
            label={'I would like to receive email updates about AICPA and CIMA news, offers and products & services.'}
            checked={form.receivedEmail}
            onChange={handleTellMeMoreChange}
          />
        </StyledPermissionCheckboxWrapper>
      )}
      <StyledCheckbox
        width="18"
        height="18"
        type="checkbox"
        testId={InquiryForm.FieldIds.RECEIVED_SMS}
        label={'I agree to receive SMS messages. Text and data rates may apply.'}
        checked={form.receivedSms}
        onChange={handleSmsAgreeChange}
      />
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  &&&&& {
    object-fit: contain;
    line-height: 1;
    margin-top: ${props => props.theme.pxToRem(5)};
    margin-bottom: ${props => props.theme.pxToRem(23)};
    margin-right: ${props => props.theme.pxToRem(16)};
    > label {
      font-size: ${props => props.theme.fontSizes.xs};
      color: ${props => props.theme.colors.neutralGrey8};
      line-height: 1.57;
      font-weight: ${props => props.theme.fontWeights.light};
      padding-left: ${props => props.theme.pxToRem(24)};
    }
  }
`;

const StyledPermissionCheckboxWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(26)};
`;

const DropDownContainer = styled.div`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(26)};
  }
`;
const CheckBoxContainer = styled.div`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(-4)};
  }
`;
const StyledAlignCheckBox = styled.label`
  &&&& {
    padding-left: ${props => props.theme.pxToRem(57)};
  }
`;

const Label = styled.label`
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledMobileInput = styled(MobileInput)`
  &&& {
    width: ${props => props.theme.pxToRem(260)} !important;
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: auto !important;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      width: 100%;
      height: ${props.theme.pxToRem(48)};
      padding-right: ${props.theme.pxToRem(10)};
      background-color: ${props.theme.colors.neutralWhite};
      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }
      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.primaryPurple};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }
      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.primaryPurple};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }
      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.interfaceBlue};
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
    }
  `}
`;
