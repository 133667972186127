import { createSelector } from 'reselect';
import { selectedLocationIdSelector } from '../layouts/selectors';
import { slugIdMapCreator } from './helpers';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.locations,
  locationsRoot => locationsRoot
);

export const locationsFetchedSelector = createSelector(
  rootSelector,
  (locations: State.Locations): boolean => locations.locationsFetched
);

export const locationsHashSelector = createSelector(
  rootSelector,
  (locationsRoot: State.Locations): State.LocationsHash | null => locationsRoot.locationsHash
);

const locationsSelector = createSelector(
  locationsHashSelector,
  (locationsHash: State.LocationsHash | null): State.Location[] | null => locationsHash && Object.values(locationsHash)
);

export const locationsSlugIdMapSelector = createSelector(locationsSelector, slugIdMapCreator);

export const locationsSlugsStrSelector = createSelector(
  locationsSlugIdMapSelector,
  (skillsSlugIdMap: { [key: string]: string }): string => Object.keys(skillsSlugIdMap || emptyObject).join('|')
);

export const locationsSortedSelector = createSelector(
  locationsSelector,
  (locations: State.Location[] | null): State.Location[] | null =>
    locations && [...locations].sort((a, b) => (a.sort && b.sort && a.sort - b.sort) || -1)
);

export const locationNameByLocationSlugSelectorFactory = (locationSlug: string) =>
  createSelector(
    [locationsHashSelector, locationsSlugIdMapSelector],
    (locationsHash: State.LocationsHash | null, locationsSlugIdMap: { [key: string]: string }): string => {
      if (!locationSlug || !locationsHash || !locationsSlugIdMap) return '';
      const locationId: string = locationsSlugIdMap[locationSlug];
      if (!locationId) return '';
      const location: State.Location = locationsHash[locationId];
      return location ? location.name : '';
    }
  );

export const selectedLocationNameSelector = createSelector(
  [locationsHashSelector, selectedLocationIdSelector],
  (locationsHash: State.LocationsHash | null, selectedLocationId: string | null): string | null => {
    if (!locationsHash || !selectedLocationId || !locationsHash[selectedLocationId]) return null;
    return locationsHash[selectedLocationId].name;
  }
);

export const countriesHashSelector = createSelector(
  locationsSelector,
  (locations: State.Location[] | null): State.CountriesHash | null => {
    if (!locations) return null;
    return locations.reduce(
      (acc: State.CountriesHash, location: State.Location) => ({ ...acc, ...location.countries }),
      {}
    );
  }
);

export const countriesSelector = createSelector(
  countriesHashSelector,
  (countries: State.CountriesHash | null): State.Country[] | null => {
    return countries && Object.values(countries);
  }
);

export const statesHashSelector = createSelector(
  countriesSelector,
  (countries: State.Country[] | null): State.StatesHash | null => {
    if (!countries) return null;
    return countries.reduce((acc: State.StatesHash, country: State.Country) => ({ ...acc, ...country.states }), {});
  }
);

export const statesSelector = createSelector(
  statesHashSelector,
  (states: State.StatesHash | null): State.State[] | null => {
    return states && Object.values(states);
  }
);

export const countriesSlugIdMapSelector = createSelector(countriesSelector, slugIdMapCreator);

export const statesSlugIdMapSelector = createSelector(statesSelector, slugIdMapCreator);

export const countriesHashByLocationIdSelectorFactory = (countryId: string | null) =>
  createSelector(locationsHashSelector, (locationsHash: State.LocationsHash | null): State.CountriesHash | null => {
    if (!locationsHash || !countryId || !locationsHash[countryId]) return null;
    return locationsHash[countryId].countries;
  });
