import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Button, ButtonEnums } from 'components/atoms';
import { ReactComponent as ArrowBack } from 'resources/images/ic-arrow-back.svg';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';

interface PrevRouteState {
  prevRoute?: string;
}

const PreviousPageWithRouter: React.FC<
  RouteComponentProps & {
    backText?: string;
    previousRoute?: string;
    className?: string;
    route?: string;
    onClick?: () => void;
    additionalStates?: { [key: string]: string | boolean };
    isSuccess?: any;
    isPageAddress?: any;
    isPagePayment?: any;
  }
> = React.memo(
  ({
    history,
    previousRoute,
    backText = 'Previous page',
    className,
    route,
    onClick,
    additionalStates,
    isSuccess,
    isPageAddress,
    isPagePayment,
  }) => {
    const key = history?.location?.key;
    const locationState = history?.location?.state as PrevRouteState;
    const prevRoute = locationState?.prevRoute || previousRoute;

    const back = history.goBack;
    const push = history.push;

    const previousPage = React.useCallback(() => {
      let destinationRoute = route;

      if (route) {
        push(route, ...(additionalStates ? [additionalStates] : []));
      } else if (!key) {
        destinationRoute = getPath(Routes.HOME, { withoutParams: true });
        push(destinationRoute);
      } else {
        destinationRoute = prevRoute;
        back();
      }

      handleEvent({ clickValue: `button:link:int::${backText}:${destinationRoute}` }, NAV_CLICK);
      if (onClick) {
        onClick();
      }
    }, [back, push, key, route, onClick, additionalStates, backText, prevRoute]);

    return (
      <StyledButton
        testId={'go-to-previous-page'}
        variant={ButtonEnums.variants.link}
        size={ButtonEnums.sizes.small}
        icon={isSuccess || isPagePayment || isPageAddress ? <Checkmark /> : <ArrowBack />}
        iconPosition={ButtonEnums.iconPosition.left}
        onClick={previousPage}
        className={className}
        overrideAnalytics={true}
      >
        {backText}
      </StyledButton>
    );
  }
);
export const PreviousPage = withRouter(PreviousPageWithRouter);

const StyledButton = styled(Button)`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-weight: ${props => props.theme.fontWeights.light};

    svg {
      margin-left: 0;
      path {
        fill: ${props => props.theme.colors.primaryPurple};
      }
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(32)};
    }
  }
`;
