import React, { RefObject, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router';
import { Divider, Loader, Grid, Responsive } from 'semantic-ui-react';
import { User, Content } from 'mxp-schemas';
import { User as UserUtils } from 'mxp-utils';
import { useToggleSavedItem } from 'hooks/useToggleSavedItem';
import { useGoogleAds } from 'hooks/useGoogleAds';
import { ModalOnboardingFinishContainer } from 'containers/ModalOnboardingFinishContainer';
import { ContentTopicsFollowContainer } from 'containers/ContentTopicsFollowContainer';
import { ContentSkillsFollowContainer } from 'containers/ContentSkillsFollowContainer';
import { ContentIndustriesFollowContainer } from 'containers/ContentIndustriesFollowContainer';
import { ContentTrendsFollowContainer } from 'containers/ContentTrendsFollowContainer';
import { ArticleFeedbackContainer as ArticleFeedback } from 'containers/ArticleFeedbackContainer';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { ContentHolder, RelatedContent } from 'components/organisms';
import { ExternalAdBanner, ExternalAdBannerType } from 'components/organisms/ExternalAdBanner/ExternalAdBanner';
import { BlogBreadcrumb } from 'components/molecules/Breadcrumb/BlogBreadcrumb';
import { HeaderCopySave } from 'components/molecules/HeaderCopySave/HeaderCopySave';
import { SocialSharing } from 'components/molecules/SocialSharing/SocialSharing';
import { Footer } from 'components/molecules/Footer';
import { Button, ButtonEnums, OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms';
import { PageHelmet } from 'components/atoms/PageHelmet/PageHelmet';
import { MembersExclusive } from './MembersExclusive';
import { emptyArray, getPath, getContentUrl, Scroller, emptyObject } from 'utils';
import { FilterNames, Routes } from 'constants/index';
import { useScrollPosition } from 'utils/useScrollPosition';
import { Copy, Bookmark, Bookmarked } from 'components/atoms/svg';
import { MyProfileLink } from 'components/molecules';

import { firmMembershipsHashSelector } from 'modules/startup/selectors';

export interface Props {
  contentItem?: State.ContentItem | null;
  contentLoading?: boolean;
  relatedContent: State.ContentCardItem[] | null;
  hideContentDetails: boolean;
  isContentSaved: boolean;
  category: string;
  categoryDisplayName: string;
  slug: string;
  type: string;
  burgerExpanded: boolean;
  shouldFetchLockedNotification: boolean;
  isLocked?: boolean;
  contentIsExclusive: Common.ContentExclusive;
  isAuth: boolean | null;
  paywallNotificationFetched: boolean;
  getContentPageData(slug: string, category: string, type: string): Promise<void>;
  resetContentModule(): void;
  resetCurrentContentId(): void;
  copy(copiedItem: State.CopiedItem): void;
  navigate(path: string): void;
  getPaywallNotification(): Promise<void>;
  topicSlug?: string;
  subtopicSlug?: string;
  topicName?: string;
  trendSlug?: string;
  trendName?: string;
  industrySlug?: string;
  industryName?: string;
  skillSlug?: string;
  subskillSlug?: string;
  skillName?: string;
  isUserOnBoarded: boolean;
  appInitialized: boolean;
}

export const PageContent = ({
  contentLoading,
  contentItem,
  resetContentModule,
  relatedContent,
  resetCurrentContentId,
  hideContentDetails,
  isContentSaved,
  slug,
  type,
  category,
  categoryDisplayName,
  burgerExpanded,
  copy,
  navigate,
  isLocked = false,
  contentIsExclusive,
  isAuth,
  topicSlug,
  subtopicSlug,
  topicName,
  isUserOnBoarded,
}: Props) => {
  useGoogleAds(contentItem?.externalAdsConfig);
  React.useEffect(() => {
    Scroller.scrollToTop();

    return () => {
      resetContentModule();
      resetCurrentContentId();
    };
  }, [resetContentModule, resetCurrentContentId]);

  const [topButtonsPos, setTopButtonsPosition] = useState({ x: 0, y: 0 });
  const [bottomButtonsPos, setBottomButtonsPosition] = useState({ x: 0, y: 0 });

  const topButtonsRef = useRef() as RefObject<HTMLDivElement>;
  const bottomButtonsRef = useRef() as RefObject<HTMLDivElement>;
  const topButtonsY = topButtonsPos.y;
  const bottomButtonsY = bottomButtonsPos.y;

  useScrollPosition(
    ({ currPos }) => {
      setTopButtonsPosition(currPos);
    },
    [],
    topButtonsRef,
    false,
    200
  );

  useScrollPosition(
    ({ currPos }) => {
      setBottomButtonsPosition(currPos);
    },
    [],
    bottomButtonsRef,
    false,
    200
  );

  const {
    id = '',
    title = '',
    description = '',
    externalUrl = '',
    contentSource = '',
  } = (contentItem || emptyObject) as State.ContentItem;

  const handleToggleContentSave = useToggleSavedItem({
    id,
    isSaved: isContentSaved,
    contentSource,
    title,
    description,
    slug,
    externalUrl,
  });

  const handleCopy = () => {
    copy({ id, copiedText: window.location.href, notificationText: title });
  };

  const feedbackRef = useRef() as RefObject<HTMLDivElement>;

  const sortByRoleTitle = (a: Common.SectionMembership, b: Common.SectionMembership) => {
    if (a.title > b.title) {
      return 1;
    }
    if (b.title > a.title) {
      return -1;
    }
    return 0;
  };

  const firmMembershipsHash = useSelector(firmMembershipsHashSelector);

  const contentRoles: Common.SectionMembership[] =
    contentItem && Array.isArray(contentItem.roles)
      ? contentItem.roles
          .filter(
            (role: User.MembershipIdsEnum) =>
              UserUtils.fullRoleNameMap[role] && UserUtils.membershipMapNames[role].indexOf('Override') < 0
          )
          .map(role => {
            const premiumFilter = `${FilterNames.PREMIUM}=${UserUtils.searchKeysForPremiumContentFilter[role]}`;
            const firmMembershipLink = firmMembershipsHash[role]?.propPageLink;
            return {
              oktaRole: UserUtils.membershipMapNames[role],
              title: UserUtils.fullRoleNameMap[role],
              link:
                UserUtils.sectionMembershipMap[role]?.link ||
                firmMembershipLink ||
                `${getPath(Routes.SEARCH).slice(0, getPath(Routes.SEARCH).indexOf(':'))}?${premiumFilter}`,
            };
          })
          .sort(sortByRoleTitle)
      : emptyArray;

  const renderContentItem = () => {
    return contentItem ? (
      <>
        <ContentHolder
          isContentSaved={isContentSaved}
          toggleSavedItem={handleToggleContentSave}
          toggleCopy={handleCopy}
          content={contentItem}
          data-testid="content-holder"
          hideContentDetails={hideContentDetails}
          topButtonsRef={topButtonsRef}
          navigate={navigate}
          isLocked={isLocked}
          isContentExclusive={contentIsExclusive}
          isAuth={isAuth}
          topicSlug={topicSlug}
          subtopicSlug={subtopicSlug}
          topicName={topicName}
          isUserOnBoarded={isUserOnBoarded}
        />
        {!hideContentDetails && !isLocked && (
          <>
            <Divider />
            <ButtonWrapper ref={bottomButtonsRef}>
              <StyledCopySaveBox
                aria-label={`Copy ${contentItem.title ? contentItem.title : ''}`}
                testId="content-bottom-copy-button"
                onClick={handleCopy}
                variant={ButtonEnums.variants.iconWithCircle}
                tabIndex={0}
              >
                <OffScreenSpan>{contentItem.title ? contentItem.title : ''}</OffScreenSpan>
                <Copy />
              </StyledCopySaveBox>
              <StyledCopySaveBox
                aria-label={`Bookmark ${contentItem.title ? contentItem.title : ''}`}
                testId="content-bottom-save-button"
                active={isContentSaved}
                onClick={handleToggleContentSave}
                variant={ButtonEnums.variants.iconWithCircle}
                tabIndex={0}
              >
                <OffScreenSpan>{contentItem.title ? contentItem.title : ''}</OffScreenSpan>
                {isContentSaved ? <Bookmarked /> : <Bookmark />}
              </StyledCopySaveBox>
              <SocialSharing title={contentItem.title} />
            </ButtonWrapper>
            <Divider />
            <OnlyDesktopCSS>
              <ArticleFeedback contentId={contentItem.id} />
            </OnlyDesktopCSS>
            <div ref={feedbackRef}>
              <OnlyMobileCSS>
                <ArticleFeedback contentRef={feedbackRef} contentId={contentItem.id} />
              </OnlyMobileCSS>
            </div>
          </>
        )}
      </>
    ) : (
      <h3>Content not Found</h3>
    );
  };

  const isHeroImageVisible = contentItem?.image;

  const renderImage = () => {
    if (!isHeroImageVisible) return null;
    return (
      <FullWidthImage
        data-testid="top-image-holder"
        data-mobile-width={Responsive.onlyTablet.maxWidth}
        data-image-source={`${contentItem?.image?.url}&`}
      >
        <img alt={(contentItem as State.ContentItem).image.altText} />
      </FullWidthImage>
    );
  };

  // re-render same content url with missing type
  if (contentItem && category !== Content.CategorySlugs.HELP && !type) {
    const url = getContentUrl(contentItem);
    if (url.includes(slug)) return <Redirect to={url} />;
  }

  const isAICPAInsightsBlog: boolean = contentItem?.contentSource === Content.BlogContentSource;

  const renderBlogBreadcrumb = (): React.ReactNode => <BlogBreadcrumb blogTitle={contentItem?.title || ''} />;

  return (
    <>
      <PageHelmet
        meta={{
          title: contentItem?.title,
          description: contentItem?.description,
        }}
        openGraph={{
          title: contentItem?.title,
          description: contentItem?.description,
          type:
            contentItem?.contentType?.name === 'Article' || contentItem?.contentType?.name === 'Video'
              ? contentItem?.contentType?.name
              : undefined,
          image: contentItem?.image?.url && `https:${contentItem.image.url}`,
          passUrl: true,
        }}
        isNoIndex={contentItem?.noIndex}
      />
      <div>
        {!burgerExpanded && (
          // this div fixes scrolling in ssr
          <div>
            <HeaderCopySave
              top={topButtonsY}
              bottom={bottomButtonsY}
              title={contentItem?.title ? contentItem.title : ''}
              categorySlug={category}
              categoryDisplayName={categoryDisplayName}
              contentId={contentItem?.id ? contentItem.id : ''}
              toggleSavedItem={handleToggleContentSave}
              handleCopyToggle={handleCopy}
              isContentSaved={isContentSaved}
            />
          </div>
        )}
        <HeaderPageWrapper>
          {contentLoading ? (
            <StyledContentLoading>
              <Loader active />
            </StyledContentLoading>
          ) : (
            <div>
              <>
                {isAICPAInsightsBlog && renderBlogBreadcrumb()}
                {contentItem?.externalAdsConfig?.topAdBanner && <ExternalAdBanner type={ExternalAdBannerType.TOP} />}
                {renderImage()}
                {!burgerExpanded && (
                  <ContentPageWrapper data-testid="content-page-wrapper">
                    {!hideContentDetails ? (
                      <Grid columns={2}>
                        <Grid.Column computer={10} tablet={16} mobile={16} data-testid="content-page-content">
                          {renderContentItem()}
                        </Grid.Column>
                        <Grid.Column computer={6} tablet={16} mobile={16} data-testid="content-page-topics">
                          <>
                            {contentItem && (
                              <>
                                <MembersExclusive items={contentRoles} topics={contentItem.topics} />
                                {Boolean(contentItem?.topics?.length) && <ContentTopicsFollowContainer />}
                                {Boolean(contentItem?.industries?.length) && <ContentIndustriesFollowContainer />}
                                {Boolean(contentItem?.skills?.length) && <ContentSkillsFollowContainer />}
                                {Boolean(contentItem?.trends?.length) && <ContentTrendsFollowContainer />}
                                <MyProfileLink />
                              </>
                            )}
                            {contentItem?.externalAdsConfig?.squareAdBanner && (
                              <ExternalAdBanner type={ExternalAdBannerType.SQUARE} />
                            )}
                          </>
                        </Grid.Column>
                      </Grid>
                    ) : (
                      renderContentItem()
                    )}
                  </ContentPageWrapper>
                )}
              </>
              {!hideContentDetails && <RelatedContent relatedContent={relatedContent} />}
              <div>
                {contentItem?.externalAdsConfig?.bottomAdBanner && (
                  <ExternalAdBanner type={ExternalAdBannerType.BOTTOM} />
                )}
              </div>
              <Footer />
              {hideContentDetails && <SocialSharing title={contentItem?.title} isSticky />}
            </div>
          )}
        </HeaderPageWrapper>
        <ModalOnboardingFinishContainer />
      </div>
    </>
  );
};

const OffScreenSpan = styled.span`
  border: 0 !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`;

const FullWidthImage = styled.div`
  width: 100%;

  img {
    display: block;
    content: url(${(props: any) => `${props['data-image-source']}w=1200&h=180&fit=thumb`});
    width: 100%;
    height: ${props => props.theme.pxToRem(180)};
    object-fit: cover;
    border: 0;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 0 0 ${props => props.theme.pxToRem(28)};

    img {
      content: url(${(props: any) => `${props['data-image-source']}w=${props['data-mobile-width']}&h=100&fit=thumb`});
      height: ${props => props.theme.pxToRem(100)};
    }
  }
`;

const ContentPageWrapper = styled.div`
  padding: ${props => props.theme.pxToRem(52)} ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(40)};
  max-width: ${props => props.theme.pxToRem(1140)};
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(28)} ${props => props.theme.pxToRem(15)} ${props => props.theme.pxToRem(30)};
  }
`;

const StyledContentLoading = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -5.5625rem;
  font-size: ${props => props.theme.fontSizes.l};
`;

const ButtonWrapper = styled.div`
  display: flex;
  button:not(:last-of-type) {
    margin-right: ${props => props.theme.pxToRem(20)};
  }
`;

const StyledCopySaveBox = styled(Button)`
  &&&& {
    ${props => props.theme.mediaQueries.computerMin} {
      &&:focus {
        border-color: transparent;
        background: ${props => props.theme.colors.primaryLightPurple};
        outline: none;
        path {
          fill: ${props => props.theme.colors.neutralWhite};
        }
      }
    }
  }
`;

export default PageContent;
