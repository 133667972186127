import React from 'react';
import { GlobalModalDimmerStyle, Modal, Button, ButtonEnums, ClapSpinner } from 'components/atoms';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';

import { useHistory } from 'react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { ReactComponent as Print } from 'resources/images/ic-print.svg';
import { Copy } from 'components/atoms/svg';
import { copy } from 'modules/copy/actions';
import { useDispatch } from 'react-redux';
import { theme } from 'theme';

interface Props {
  visible: boolean;
  loading: boolean;
  hide: () => void;

  fileName?: string;
}

export const SendToPrintModal: React.FC<Props> = ({ visible, hide, fileName, loading }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleExitClicked = React.useCallback(() => hide(), [hide]);
  const handleConfirmClick = React.useCallback(() => {
    history.push(getPath(Routes.ADMIN_INVOICES));
  }, [history]);

  const handleCopyClick = React.useCallback(() => {
    dispatch(
      copy({
        notificationText: fileName,
        title: 'File name copied',
        copiedText: fileName,
        id: 'filenamecopy',
      })
    );
  }, [fileName, dispatch]);

  const confirmNode = (
    <Button
      size={ButtonEnums.sizes.medium}
      testId="send-emails"
      variant={ButtonEnums.variants.primary}
      onClick={handleConfirmClick}
      disabled={loading}
    >
      Back to Invoice
    </Button>
  );

  return (
    <>
      <GlobalModalDimmerStyle />
      <StyledModal
        closeOnDimmerClick={false}
        open={visible}
        onClose={handleExitClicked}
        centered
        icon={<StyledPrint />}
        showCloseCross
        heading={!loading ? <>Your invoices have been sent</> : ' '}
        confirmNode={confirmNode}
        success
      >
        {loading ? (
          <StyledClapSpinnerContainer>
            <ClapSpinner
              size={45}
              frontColor={theme.colors.primaryPurple}
              backColor={theme.colors.primaryPurple}
              loading
            />
          </StyledClapSpinnerContainer>
        ) : (
          <>
            <InfoTxt>File name:</InfoTxt>
            <FileNameTxt>{fileName}</FileNameTxt>
            <FileNameTxt>
              <StyledButton
                icon={<Copy color={theme.colors.primaryPurple} />}
                iconPosition={ButtonEnums.iconPosition.left}
                testId={`copy-file-name`}
                onClick={handleCopyClick}
                variant={ButtonEnums.variants.primaryToggle}
              >
                Copyfile name
              </StyledButton>
            </FileNameTxt>
          </>
        )}
      </StyledModal>
    </>
  );
};

const StyledPrint = styled(Print)`
  margin-left: ${props => props.theme.pxToRem(5)};
  margin-top: ${props => props.theme.pxToRem(8)};
  & path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledButton = styled(Button)<any>`
  &&&& {
    height: ${props => props.theme.pxToRem(40)};
    padding: ${props => props.theme.pxToRem(6)} ${props => props.theme.pxToRem(24)};
    text-align: center;
    display: inline-flex;
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.neutralGrey8};
    margin-top: ${props => props.theme.pxToRem(10)};

    > svg {
      width: ${props => props.theme.pxToRem(24)};
      height: ${props => props.theme.pxToRem(24)};
      margin-right: ${props => props.theme.pxToRem(5)};
      margin-top: ${props => props.theme.pxToRem(-4)};
      fill: ${props => props.theme.colors.primaryPurple};
    }

    ${props => props.theme.mediaQueries.desktopOnly} {
      &:hover {
        > svg {
          fill: ${props => props.theme.colors.neutralWhite};
        }
      }
    }
  }
`;

const StyledModal = styled(Modal)<ThemedStyledProps<{ success: boolean }, DefaultTheme>>`
  max-width: ${props => props.theme.pxToRem(605)};
  &&&&&&& {
    > .content {
      padding-bottom: 0 !important;
      padding-right: ${props => props.theme.pxToRem(20)} !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-right: 0 !important;
      }
    }
    > .header {

      padding-right: ${props => props.theme.pxToRem(20)} !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-right: 0 !important;
      }
    }

    > .actions {
      border-top:${props => props.success && 'none'};
    }
    // 20 on right and top to fit close in the corner
    padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} ${props =>
  props.theme.pxToRem(40)} ${props => props.theme.pxToRem(40)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-left: ${props => props.theme.pxToRem(20)};
    }
  }
  }
`;

const StyledClapSpinnerContainer = styled.div`
  top: ${props => props.theme.pxToRem(130)};
  left: 0;
  display: flex;
  width: 100%;
  height: ${props => props.theme.pxToRem(100)};
  justify-content: center;
  align-items: center;
`;

const FileNameTxt = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  text-align: center;
`;
const InfoTxt = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  text-align: center;
  margin-top: ${props => props.theme.pxToRem(10)};
`;
