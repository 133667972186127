import React from 'react';
import styled from 'styled-components';

export const CimaMembershipPackagePanelWrapper: React.FC = React.memo(({ children }) => (
  <PanelWrapper>{children}</PanelWrapper>
));

const PanelWrapper = styled.div`
  position: relative;
`;
