import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'components/atoms/Modal/Modal';
import { ButtonEnums, Button } from 'components/atoms/Button/Button';
import { MembershipCard } from 'components/organisms';
import { EntryPointLevelExamList } from 'components/organisms/MembershipCard/MembershipCard';
import { ExemptionLevels, MembershipTypes } from 'mxp-schemas';
import { useLocation } from 'react-router';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { currentJourneyLearningPathwaySelector } from 'modules/user/selectors';
/**
 * CIMA sign up: Regular FLP Level Modal
 * @category CIMA Membership
 * CBUILD-130/
 */

export interface ModalCardProps {
  levelName: string;
  levelDescription: string;
  listTitle?: string;
  entryPointLevelExamList: EntryPointLevelExamList[];
  cardIndex: number;
  title?: string;
  sku?: string;
  tierCode?: string;
}
interface Props {
  entryPointDataList?: ModalCardProps[];
  open: boolean;
  confirmButtonLabel?: string;
  testId?: string;
  modalHeader?: string;
  modalDescription?: string;
  isPQ?: boolean;
  isApprenticeModal?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  onSetActiveLevel?: (levelId: string) => void;
  isSelectable?: boolean;
  onCardSelected?: (sku: string) => void;
  setSubmitExemption?: any;
  handleSelectApprenticeLevel?: (level: string) => void;
  currentEntryLevelPointName?: string | '';
  currentActiveLevel?: string;
  isFLP?: boolean;
  isOfflineExemptionCalculator?: boolean;
}
export const ModalEntryPointLevel: React.FC<Props> = ({
  entryPointDataList,
  open,
  confirmButtonLabel,
  testId,
  modalHeader,
  modalDescription,
  isPQ = true,
  onCancel,
  onConfirm,
  onSetActiveLevel = null,
  isApprenticeModal,
  isSelectable = true,
  onCardSelected,
  setSubmitExemption,
  handleSelectApprenticeLevel,
  currentEntryLevelPointName,
  currentActiveLevel,
  isFLP = false,
  isOfflineExemptionCalculator,
}) => {
  const activeLevelInit = {
    cardIndex: '',
    entryPointLevelExamList: [],
    id: '',
    levelDescription: '',
    levelName: '',
    listTitle: '',
  };
  const [activeLevel, setActiveLevel] = useState(activeLevelInit);
  const location = useLocation();
  const isProfilePage = location.pathname === getPath(Routes.MY_PROFILE_ROOT);
  const currentJourneyLearningPathway = useSelector(currentJourneyLearningPathwaySelector);

  const onLevelSelect = (entryPointLevelValue: any) => {
    setActiveLevel(entryPointLevelValue);

    if (onSetActiveLevel) {
      onSetActiveLevel(entryPointLevelValue.levelName === activeLevel.levelName ? activeLevel : entryPointLevelValue);
    }

    setSubmitExemption({
      id: entryPointLevelValue.id,
      name: entryPointLevelValue.levelName,
      description: entryPointLevelValue.levelDescription,
      masterExamReference: entryPointLevelValue.entryPointLevelExamList,
      title: entryPointLevelValue.title,
    });
  };

  const confirmButton = (
    <Button
      className="confirm-button"
      size={ButtonEnums.sizes.small}
      variant={ButtonEnums.variants.primary}
      testId="confirm"
      onClick={onConfirm}
      type="button"
    >
      {confirmButtonLabel}
    </Button>
  );

  useEffect(() => {
    if (!open) setActiveLevel(activeLevelInit); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const filterLevel = currentEntryLevelPointName
    ? entryPointDataList?.filter(
        (f: any) => Number(f.levelName.replace(/^\D+/g, '')) <= Number(currentEntryLevelPointName.replace(/^\D+/g, ''))
      )
    : entryPointDataList;
  const activeLevelName = Boolean(activeLevel.levelName) ? activeLevel.levelName : currentActiveLevel;
  const currentLevel = Number(currentEntryLevelPointName?.replace(/^\D+/g, ''));
  return (
    <StyledModal
      isApprenticeModal={isApprenticeModal}
      className="regular-modal"
      open={open}
      onClose={onCancel}
      size="small"
      heading={modalHeader}
      testId={testId}
      showCloseCross
      {...(!isApprenticeModal && { confirmNode: confirmButton })}
      isOfflineExemptionCalculatorModal={isOfflineExemptionCalculator}
    >
      {!isApprenticeModal && <StyledParagraph className="modalDescription">{modalDescription}</StyledParagraph>}
      <MembershipCardContainer
        isPQ={isPQ}
        isApprenticeModal={isApprenticeModal}
        isOfflineExemptionCalculatorModal={isOfflineExemptionCalculator}
      >
        {filterLevel?.map((item, key) => {
          const titleHeader = Number(item.levelName?.substring(6));
          const levelValidate = Object.values(ExemptionLevels.ExemptionLevels).find(
            (level: any) => level === titleHeader && level
          );
          const levelNameValidate = Object.values(
            currentJourneyLearningPathway === MembershipTypes.Pathway.CHINESE_PQ
              ? ExemptionLevels.LevelNameChinesePQ
              : ExemptionLevels.LevelName
          )[titleHeader - 1];
          const levelNameFLPValidate = Object.values(ExemptionLevels.LevelNameFLP)[titleHeader - 1];

          // if entryPointLevelExamList is empty
          if (!item.entryPointLevelExamList.length) {
            return null;
          }
          return (
            <MembershipCard
              key={key}
              headerTitle={
                item.levelName === ''
                  ? ''
                  : titleHeader === levelValidate
                  ? currentJourneyLearningPathway === MembershipTypes.Pathway.FLP || isFLP
                    ? levelNameFLPValidate
                    : levelNameValidate
                  : isProfilePage || isApprenticeModal
                  ? item.levelName
                  : ExemptionLevels.LevelName.Level_1
              }
              isCardSelected={isSelectable ? false : item.levelName === activeLevelName}
              slug=""
              isCima
              isApprentice={isApprenticeModal}
              entryPointLevelExamList={item.entryPointLevelExamList}
              handleClick={
                isProfilePage && isPQ
                  ? handleSelectApprenticeLevel?.bind(item.levelName, item.levelName)
                  : isApprenticeModal
                  ? onCardSelected?.bind(item, item?.sku as string, item.tierCode)
                  : onLevelSelect.bind(item, item)
              }
              isPQ={isPQ}
              isModalEntryPointLevelView={true}
              currentLevel={Object.values(ExemptionLevels.LevelNameFLP)[currentLevel - 1]}
              isOfflineExemptionCalculator={isOfflineExemptionCalculator}
            />
          );
        })}
        {isOfflineExemptionCalculator && !filterLevel?.length && (
          <StyledText>No exemptions for your current level</StyledText>
        )}
      </MembershipCardContainer>
    </StyledModal>
  );
};

interface MembershipCardContainer {
  isPQ?: boolean;
  isApprenticeModal?: boolean;
  isOfflineExemptionCalculatorModal?: boolean;
}
const StyledModal = styled(Modal)`
  position: relative;
  box-sizing: border-box;
  &&&&&&& {
    border-radius: ${props => props.theme.pxToRem(2)};
    width: ${props => props.theme.pxToRem(1200)};
    box-sizing: border-box;
    padding: ${props => props.theme.pxToRem(36)} ${props => props.theme.pxToRem(40)} ${props => props.theme.pxToRem(19)};
    ${props =>
      props.isApprenticeModal &&
      `
      width: ${props.theme.pxToRem(1100)};
    `}
    > .content {
      padding-bottom: 0 !important;
    }
    > .actions {
      border-top: none;
      padding: 0 !important;
    }
    ${props =>
      props.isOfflineExemptionCalculatorModal &&
      `
      width: ${props.theme.pxToRem(1048)};
    `}
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props => props.theme.pxToRem(36)} ${props => props.theme.pxToRem(20)}
        ${props => props.theme.pxToRem(19)};
      width: 90%;
    }
    > .header {
      font-size: ${props => props.theme.fontSizes.xxl};
      ${props => props.theme.mediaQueries.mobileOnly} {
        margin-top: ${props => props.theme.pxToRem(30)};
      }
    }
    .content {
      margin: ${props => props.theme.pxToRem(5)} auto auto;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }
    button {
      position: absolute;
      top: ${props => props.theme.pxToRem(24)};
      right: ${props => props.theme.pxToRem(24)};
    }
    div > button {
      position: relative;
      top: 0;
      right: 0;
      width: ${props => props.theme.pxToRem(202)};
      height: ${props => props.theme.pxToRem(34)};
      padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(32)};
      margin: auto ${props => props.theme.pxToRem(6)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100% !important;
        height: ${props => props.theme.pxToRem(40)};
        font-size: ${props => props.theme.fontSizes.s};
        border-radius: ${props => props.theme.pxToRem(4)};
        margin: 0 auto !important;
      }
    }
    .ui[class*='two column'].grid > .column:not(.row),
    .ui[class*='two column'].grid > .row > .column {
      width: 100% !important;
    }
  }
`;
const MembershipCardContainer = styled.div<MembershipCardContainer>`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: ${props => props.theme.pxToRem(57)} 0 ${props => props.theme.pxToRem(48)};
  gap: ${props => props.theme.pxToRem(20)};
  ${props =>
    props.isApprenticeModal &&
    `
    margin: ${props.theme.pxToRem(8)} 0 0;
  `}
  ${props =>
    props.isOfflineExemptionCalculatorModal &&
    `
    margin-top: 0;
  `}
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
    gap: ${props => props.theme.pxToRem(5)};
    margin: 0;
  }
`;
const StyledParagraph = styled.p`
  margin: ${props => props.theme.pxToRem(24)} 0 ${props => props.theme.pxToRem(14)};
  line-height: 2;
  text-align: center;
`;

const StyledText = styled.p`
  line-height: 2;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.l};
`;
