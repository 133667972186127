/* tslint:disable:no-object-literal-type-assertion */
import React, { useState, memo } from 'react';
import styled from 'styled-components';
import { InputWithValidation, DatePicker, ProfileHeading } from 'components/molecules';
import { RadioButton, Label, RichTextEditor, Flex } from 'components/atoms';
import { MembershipTypes } from 'mxp-schemas';
import { Grid } from 'semantic-ui-react';
import { isEmptyString, isValidRichText, richTextToPlainText, unescapeString } from 'utils/StringHelpers';
import moment from 'moment-timezone';
import { calculateDateDiff, partTimeDatesToYearsMonths } from 'utils/dateHelper';
import { usePageContext } from 'components/pages/PagePracticalExperienceRequirement/PageContext';

type DatesType = Date | string | number;
interface RoleType {
  employmentType: MembershipTypes.PracticalExperienceEmploymentType | null;
  jobTitle: string;
  numberOfDaysPerWeek?: string;
  startDate: DatesType;
  endDate: DatesType;
  summary: string;
}
interface Props {
  role: RoleType;
  onChangeData?: (value: RoleType) => void;
  onSetRoleData?: (value: Partial<State.PracticalExperienceRequirementEmployment>) => void;
  roleData?: any;
  // -- new
  onSetRoleFormData?: (value: Partial<State.PracticalExperienceRequirementEmployment>) => void;
}

export const UserRoleForm: React.FC<Props> = memo(
  ({ onChangeData, role, onSetRoleData, roleData, onSetRoleFormData }) => {
    const { isSupervisorValidationAllowed } = usePageContext();
    const [fieldsTouched, setFieldsTouched] = useState(new Map());
    const JOB_TITLE_FIELD_ID = 'jobTitle';
    const EMPLOYMENT_TYPE_FIELD_ID = 'employmentType';
    const NUMBER_OF_DAYS_FIELD_ID = 'numberOfDaysPerWeek';
    const START_DATE_FIELD_ID = 'startDate';
    const END_DATE_FIELD_ID = 'endDate';
    const SUMMARY_FIELD_ID = 'summary';
    const DATE_FORMAT = 'YYYY-MM-DD';
    const requiredFieldErrorMessage = 'Please complete this mandatory field.';
    const roleDataSummaryToPlainText = Boolean(richTextToPlainText(roleData.summary).length >= 800);

    const handleInputChange = React.useCallback(
      (key: string, value: any): void => {
        if (key === NUMBER_OF_DAYS_FIELD_ID) {
          const re = /^([0-9]*\.)?[0-9]*$/;
          if (!re.test(value)) {
            return;
          }
        }

        if (value?.length) {
          setFieldsTouched(fields => new Map(fields.set(key, true)));
        }

        onSetRoleData?.({ [key]: value });
      },
      [onSetRoleData, setFieldsTouched]
    );

    const numberOfDaysField = () => {
      return !isEmptyString(roleData?.numberOfDaysPerWeek);
    };

    return (
      <StyledContainer>
        <StyledProfileHeading title="My role" hasBorder={false} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <InputWithValidation
                onChange={e => handleInputChange(JOB_TITLE_FIELD_ID, e.target.value)} // tslint:disable-line jsx-no-lambda
                name={JOB_TITLE_FIELD_ID}
                testId={JOB_TITLE_FIELD_ID}
                type="text"
                labelName="My job title"
                labelId={JOB_TITLE_FIELD_ID}
                value={roleData?.jobTitle}
                errorMessage={requiredFieldErrorMessage}
                isCorrect={!isEmptyString(roleData?.jobTitle)}
                isCorrectIconShown={!fieldsTouched.get(JOB_TITLE_FIELD_ID) || !isEmptyString(roleData?.jobTitle)}
                placeholder="Enter job title"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              computer={6}
              mobile={7}
              tablet={7}
              onClick={(
                e: any // tslint:disable-line jsx-no-lambda
              ) =>
                handleInputChange(EMPLOYMENT_TYPE_FIELD_ID, MembershipTypes.PracticalExperienceEmploymentType.FULL_TIME)
              }
            >
              <Flex>
                <StyledRadioButton
                  testId="cpaAccountingFirm"
                  checked={roleData.employmentType === MembershipTypes.PracticalExperienceEmploymentType.FULL_TIME}
                  smallerIcon
                />
                <StyledRadioLabel>Full time</StyledRadioLabel>
              </Flex>
            </Grid.Column>
            <Grid.Column
              computer={6}
              mobile={7}
              tablet={7}
              onClick={(
                e: any // tslint:disable-line jsx-no-lambda
              ) =>
                handleInputChange(EMPLOYMENT_TYPE_FIELD_ID, MembershipTypes.PracticalExperienceEmploymentType.PART_TIME)
              } // tslint:disable-line jsx-no-lambda
            >
              <Flex>
                <StyledRadioButton
                  testId="accountingEducator"
                  checked={roleData.employmentType === MembershipTypes.PracticalExperienceEmploymentType.PART_TIME}
                  smallerIcon
                />
                <StyledRadioLabel>Part time</StyledRadioLabel>
              </Flex>
            </Grid.Column>
          </Grid.Row>
          {roleData.employmentType === MembershipTypes.PracticalExperienceEmploymentType.PART_TIME && (
            <Grid.Row>
              <Grid.Column width={16}>
                <InputWithValidation
                  onChange={e => handleInputChange(NUMBER_OF_DAYS_FIELD_ID, e.target.value)} // tslint:disable-line jsx-no-lambda
                  name={NUMBER_OF_DAYS_FIELD_ID}
                  testId={NUMBER_OF_DAYS_FIELD_ID}
                  type="text"
                  labelName="Number of days/week (on average)"
                  labelId={NUMBER_OF_DAYS_FIELD_ID}
                  value={roleData.numberOfDaysPerWeek || ''}
                  errorMessage={requiredFieldErrorMessage}
                  isCorrect={numberOfDaysField()}
                  isCorrectIconShown={
                    !fieldsTouched.get(NUMBER_OF_DAYS_FIELD_ID) || !isEmptyString(roleData?.numberOfDaysPerWeek)
                  }
                  allowedValidationOnload={!isSupervisorValidationAllowed}
                  placeholder="Enter number of days per week"
                />
              </Grid.Column>
            </Grid.Row>
          )}
          {!roleData?.employmentType && fieldsTouched.get(EMPLOYMENT_TYPE_FIELD_ID) && (
            <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
          )}

          <Grid.Row>
            <Grid.Column computer={6} mobile={16} tablet={16}>
              <StyledLabel>Start date</StyledLabel>
              <StyledDatePicker
                id={START_DATE_FIELD_ID}
                startDate={roleData.startDate ? (moment(roleData.startDate).toDate() as unknown as string) : ''}
                onDateChangeFunc={
                  (date: moment.Moment | null) =>
                    handleInputChange(START_DATE_FIELD_ID, date?.format(DATE_FORMAT) || '') // tslint:disable-line jsx-no-lambda
                }
                errorMessage={requiredFieldErrorMessage}
                isCorrect={fieldsTouched.get(START_DATE_FIELD_ID) && !isEmptyString(roleData?.startDate)}
                hasValidationError={fieldsTouched.get(START_DATE_FIELD_ID) && isEmptyString(roleData?.startDate)}
                onClose={() => setFieldsTouched(fields => new Map(fields.set(START_DATE_FIELD_ID, true)))} // tslint:disable-line jsx-no-lambda
              />
            </Grid.Column>
            <Grid.Column computer={6} mobile={16} tablet={16}>
              <StyledLabel>End date</StyledLabel>
              <StyledDatePicker
                id={END_DATE_FIELD_ID}
                startDate={roleData.endDate ? (moment(roleData.endDate).toDate() as unknown as string) : ''}
                onDateChangeFunc={
                  (date: moment.Moment | null) => handleInputChange(END_DATE_FIELD_ID, date?.format(DATE_FORMAT) || '') // tslint:disable-line jsx-no-lambda
                }
                errorMessage={requiredFieldErrorMessage}
                isCorrect={fieldsTouched.get(END_DATE_FIELD_ID) && !isEmptyString(roleData?.endDate)}
                hasValidationError={fieldsTouched.get(END_DATE_FIELD_ID) && isEmptyString(roleData?.endDate)}
                onClose={() => setFieldsTouched(fields => new Map(fields.set(END_DATE_FIELD_ID, true)))} // tslint:disable-line jsx-no-lambda
              />
            </Grid.Column>
            <CustomGridColumn computer={4} mobile={16} tablet={16}>
              <StyledSpan>
                {roleData.employmentType === MembershipTypes.PracticalExperienceEmploymentType.FULL_TIME ? (
                  `${calculateDateDiff(roleData.startDate, roleData.endDate)} in total`
                ) : (
                  <>
                    {`${partTimeDatesToYearsMonths(
                      [{ startDate: roleData.startDate.toString(), endDate: roleData.endDate.toString() }],
                      roleData.numberOfDaysPerWeek
                    )} in total`}
                  </>
                )}
              </StyledSpan>
            </CustomGridColumn>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <RichTextEditor
                testId={SUMMARY_FIELD_ID}
                label="Summary of roles and responsibilities"
                height={250}
                data={unescapeString(role.summary ?? roleData.summary)}
                isCorrect={
                  fieldsTouched.get(SUMMARY_FIELD_ID) && isValidRichText(roleData.summary) && roleDataSummaryToPlainText
                }
                errorMessage={requiredFieldErrorMessage}
                onBlur={() => setFieldsTouched(fields => new Map(fields.set(SUMMARY_FIELD_ID, true)))} // tslint:disable-line jsx-no-lambda
                getCurrentValue={(data: string) => handleInputChange(SUMMARY_FIELD_ID, data)} // tslint:disable-line jsx-no-lambda
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </StyledContainer>
    );
  }
);

const StyledContainer = styled.div`
  width: 50%;
  margin-top: ${props => props.theme.pxToRem(15)};
  margin-bottom: ${props => props.theme.pxToRem(55)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const CustomGridColumn = styled(Grid.Column)`
  &&&&& {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const StyledSpan = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly}  {
    font-size: ${props => props.theme.fontSizes.xxs};
  }
`;

const StyledProfileHeading = styled(ProfileHeading)`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.l};
  margin-bottom: ${props => props.theme.pxToRem(5)};
`;

const StyledDatePicker = styled(DatePicker)`
  &&&& {
    height: ${props => props.theme.pxToRem(42)};
    margin-bottom: ${props => props.theme.pxToRem(10)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(25)};
    }
  }
`;

const StyledRadioButton = styled(RadioButton)`
  &&&&&& {
    min-width: ${props => props.theme.pxToRem(18)};
    cursor: ${(props: any) => (props.theme.disabled ? 'default' : 'pointer')};
    margin-right: ${props => props.theme.pxToRem(8)};
  }
`;

const StyledRadioLabel = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: ${props => props.theme.pxToRem(22)};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-left: ${props => props.theme.pxToRem(5)};
  margin-right: ${props => props.theme.pxToRem(75)};
`;

const StyledLabel = styled(Label)`
  &&& {
    display: block !important;
    width: 100% !important;
    height: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.light};
    color: ${props => props.theme.colors.neutralGrey8};
    display: inline-block;
    width: 30%;
    margin-bottom: ${props => props.theme.pxToRem(14)};
    ${props => props.theme.mediaQueries.mobileOnly}  {
      display: block;
      width: 100%;
    }
  }
`;

const StyledErrorMessage = styled.p`
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.interfaceRed};
`;
