import React from 'react';
import styled from 'styled-components';
import { TableSearchInput } from 'components/atoms/TableSearchInput';
import { Table, TablePlaceholder } from 'components/atoms';
import { Pagination } from 'components/organisms/CombinedFiltersPaginationView/Pagination';

interface Props {
  searchValue: string;
  searchHandler(event: React.SyntheticEvent): void;
  searchPlaceholder: string;
  headerData: string[];
  isLoading: boolean;
  isEmpty: boolean;
  emptyTableMessage: string;
  isShowPagination: boolean;
  paginationCount: number;
  paginationLimit: number;
  paginationOffset: number;
  paginationChangeHandler(currentPage: any): void;
}

export const CenterAdminSeatManagementDataTable: React.FC<Props> = ({
  searchValue,
  searchHandler,
  searchPlaceholder,
  headerData,
  children,
  isLoading,
  isEmpty,
  emptyTableMessage,
  isShowPagination,
  paginationCount,
  paginationLimit,
  paginationOffset,
  paginationChangeHandler,
}) => {
  return (
    <>
      <TableSearchInput value={searchValue} onChange={searchHandler} placeholder={searchPlaceholder} />

      <StyledTable stackable>
        <Table.Header>
          <Table.Row>
            {headerData.map(header => (
              <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {children}
          {isLoading && (
            <Table.Row>
              <Table.Cell style={{ height: 60, verticalAlign: 'middle', textAlign: 'center' }} rowSpan={5} colSpan={3}>
                <TablePlaceholder />
              </Table.Cell>
            </Table.Row>
          )}
          {isEmpty && (
            <Table.Row>
              <Table.Cell style={{ height: 60, verticalAlign: 'middle', textAlign: 'center' }} rowSpan={5} colSpan={3}>
                {emptyTableMessage}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </StyledTable>

      {isShowPagination && (
        <PaginationContainer>
          <Pagination
            testId={`pagination`}
            total={paginationCount}
            page={paginationOffset / paginationCount + 1}
            perPage={paginationLimit}
            onPageChange={paginationChangeHandler}
          />
        </PaginationContainer>
      )}
    </>
  );
};

const StyledTable = styled(Table)`
  &&&& {
    border: 0;
    margin: 0;
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-bottom: ${props => props.theme.pxToRem(5)};

    tr {
      :nth-of-type(2n) {
        background-color: rgba(247, 247, 247, 0.3);
        + td {
          background-color: rgba(247, 247, 247, 0.3);
        }
      }
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-top: ${props => props.theme.pxToRem(24)};
        padding-bottom: ${props => props.theme.pxToRem(24)};
        box-shadow: 0 ${props => props.theme.pxToRem(-1)} 0 0 ${props => props.theme.colors.neutralGrey3} inset !important;
        margin-top: ${props => props.theme.pxToRem(16)};
        :first-child {
          padding-top: 0;
        }
      }
    }

    thead tr {
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-bottom: 0 !important;
      }
      th {
        ${props => props.theme.mediaQueries.mobileOnly} {
          :first-child {
            padding-top: ${props => props.theme.pxToRem(24)} !important;
          }
          :last-child {
            padding-bottom: ${props => props.theme.pxToRem(24)} !important;
          }
          font-size: ${props => props.theme.fontSizes.s};
        }
      }
    }

    tr th {
      padding: ${props => props.theme.pxToRem(10)};
      border-bottom-color: ${props => props.theme.colors.neutralGrey3};
      background-color: ${props => props.theme.colors.neutralGrey1};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.medium};
      white-space: nowrap;
      vertical-align: middle;
      ${props => props.theme.mediaQueries.mobileOnly} {
        font-size: ${props => props.theme.fontSizes.s};
        :nth-child(2) {
          text-align: left;
        }
      }
    }

    tr td {
      padding: ${props => props.theme.pxToRem(10)};
      border-top-color: ${props => props.theme.colors.neutralGrey3};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.light};
      vertical-align: middle;
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;
        font-size: ${props => props.theme.fontSizes.s} !important;
        :first-child {
          font-weight: ${props => props.theme.fontWeights.regular};
        }
        :nth-child(2) {
          text-align: left;
        }
      }
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      border-width: 0;
      margin-top: 0;
    }
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
