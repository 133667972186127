import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { StaticContext, useHistory } from 'react-router';
import { SingleColumnPageTemplate } from 'components/templates/SingleColumn/SingleColumn';
import { Link, Heading, Paragraph } from 'components/atoms';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { useSelector } from 'react-redux';
import { bypassHomeRedirectionSelector } from 'modules/startup/selectors';

interface LocationState {
  fetchError?: boolean;
}

export const NotFound: React.FC<Partial<RouteComponentProps<{}, StaticContext, LocationState>>> = props => {
  const fetchError = props?.location?.state?.fetchError;
  const history = useHistory();
  const bypassHomeAutoRedirection = useSelector(bypassHomeRedirectionSelector);

  useEffect(() => {
    // Added bypass condition to prevent redirection of page to home during entry of SFMC link when logged out
    if (!bypassHomeAutoRedirection) {
      setTimeout(() => {
        // Optimize. Auto redirects to /home page
        history.push('/home');
      }, 5000);
    }
  }, [history, bypassHomeAutoRedirection]);

  let render = (
    <>
      <Heading as="h2">Page can't be found</Heading>
      <Paragraph>
        Unfortunately we can't find the page you were looking for. You can return to the homepage by pressing the button
        below.
      </Paragraph>
      <Link to={getPath(Routes.FEED)} testId="return-to-home">
        Return to home
      </Link>
    </>
  );

  if (fetchError) {
    render = (
      <>
        <Heading as="h2">System error</Heading>
        <Paragraph>Please check your connection and try again.</Paragraph>
      </>
    );
  }

  return <SingleColumnPageTemplate addHeight>{render}</SingleColumnPageTemplate>;
};
