import { Routes } from 'constants/index';
import { CenterAdminTableType } from 'constants/center-admin';

const checkMismatchingFields = <T extends {}>(original: T, edited: T): CenterAdmin.EditedFieldInfo[] =>
  Object.keys(original).reduce<CenterAdmin.EditedFieldInfo[]>((acc, key: string) => {
    const originalValue = (original as any)[key];
    const editedValue = (edited as any)[key];
    if (originalValue !== editedValue) {
      acc.push({ name: key, originalValue });
    }
    return acc;
  }, []);

export const combineHashWithEditedHash = <T extends {}>(
  original: CenterAdmin.Hash<T>,
  edited: CenterAdmin.Hash<T>
): CenterAdmin.Hash<CenterAdmin.WithEditedInfo<T>> =>
  Object.keys(original || {}).reduce<CenterAdmin.Hash<CenterAdmin.WithEditedInfo<T>>>(
    (acc, key: string) => {
      const o = original[key];
      const e = edited[key];
      if (e) {
        const editedFields = checkMismatchingFields(o, e);
        if (editedFields.length) {
          acc[key] = { ...o, ...e, editedFields };
        }
      }
      return acc;
    },
    { ...original }
  );

export const routeByTableType = (type: CenterAdmin.CenterAdminTableType) => {
  return Routes.CENTER_ADMIN_MEMBERSHIPS_TAB_INVOICES;
};
