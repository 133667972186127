import React from 'react';
import styled from 'styled-components';
import { DefaultInlineLinkStyles, InlineLinkOnDarkBgStyles } from 'components/atoms/Link/Link';
import { Link, Container } from 'components/atoms';
import { Utils } from 'mxp-utils';

interface Props {
  ctaText?: string;
  ctaUrl?: string;
  image?: string;
  isBgColorDark: boolean;
}

export const SubBlockQuickLinks: React.FC<Props> = ({ ctaText, ctaUrl, image, isBgColorDark }) => {
  return (
    <>
      {ctaText && ctaUrl && image && (
        <StyledLink to={ctaUrl || '#'} buttonText={Utils.stripHtml(ctaText)}>
          <StyledIconContainer>
            <StyledImage tabIndex={0} src={image} alt="icon" />
          </StyledIconContainer>
          <StyledParagraph tabIndex={0} dangerouslySetInnerHTML={{ __html: ctaText }} isBgColorDark={isBgColorDark} />
        </StyledLink>
      )}
    </>
  );
};

const StyledLink = styled(Link)`
  &&&& {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.bold};
    position: static;
    text-decoration: none;
    color: black !important;
    &:hover {
      color: ${props => props.theme.colors.primaryPurple} !important;
      cursor: pointer;
    }
  }
`;

const StyledIconContainer = styled(Container)`
  &&&& {
    width: ${props => props.theme.pxToRem(76)};
    height: ${props => props.theme.pxToRem(76)};
    background-color: #f7f7f7;
    align-content: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin: auto;
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => props.theme.pxToRem(76)} !important;
      height: ${props => props.theme.pxToRem(76)} !important;
      justify-content: center !important;
      margin: auto !important;
    }
    ${props => props.theme.mediaQueries.tabletOnly} {
      width: ${props => props.theme.pxToRem(76)} !important;
      height: ${props => props.theme.pxToRem(76)} !important;
      justify-content: center !important;
      margin: auto !important;
    }
  }
`;

const StyledImage = styled.img`
  height: ${props => props.theme.pxToRem(24)} !important;
  width: ${props => props.theme.pxToRem(24)} !important;
  margin: auto;
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  margin-top: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  text-align: center;
  p {
    margin: 0;
    line-height: 1.33;
  }
  a {
    ${DefaultInlineLinkStyles};
    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
    p {
      line-height: 1.5;
    }
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
    p {
      line-height: 1.5;
    }
  }
`;
