import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ModalAddressValidation } from 'components/organisms';
import { isAddressValidationModalOpenSelector } from 'modules/layouts/selectors';
import { toggleModalAddressValidationOpen } from 'modules/layouts';
// RAVE
import {
  smartyShippingAddressSelector,
  smartyValidationReasonSelector,
  smartyValidationFetchedSelector,
  shippingAddressSelector,
  loadingSelector,
  billingAddressSelector,
  smartyValidationBillingReasonSelector,
  smartyBillingAddressSelector,
} from 'modules/checkout/selectors';
import {
  setSecondaryAddressNeeded,
  setShippingAddress,
  goToPaymentAction,
  setSavedShippingAddressEdited,
  setSavedBillingAddressEdited,
  setBillingAddress,
} from 'modules/checkout/reducers';
// B2B
import {
  checkoutLoadingStateSelector,
  adminShippingAddressSelector,
  adminSmartyValidationFetchedSelector,
  adminSmartyShippingAddressSelector,
  adminSmartyValidationReasonSelector,
  adminSmartyBillingAddressSelector,
  adminSmartyBillingValidationReasonSelector,
  adminBillingAddressSelector,
} from 'modules/admin/selectors';
import {
  setSecondaryAddressNeeded as adminSetSecondaryAddressNeeded,
  setShippingAddress as adminSetShippingAddress,
  addressProceedToNext,
  setBillingAddress as adminSetBillingAddress,
} from 'modules/admin/actions';

const mapActionCreators = (dispatch: Dispatch, { isRaveCheckout }: { isRaveCheckout: boolean }) => ({
  toggleModalAddressValidation(
    toggle: boolean,
    allowUserToBypassAddressValidation: State.AddressValidationBypassFlags
  ): void {
    dispatch(
      toggleModalAddressValidationOpen({
        isAddressValidationModalOpen: toggle,
        allowUserToBypassAddressValidation: allowUserToBypassAddressValidation,
      })
    );
  },
  setSecondaryAddressFlag(value: boolean): void {
    dispatch(isRaveCheckout ? setSecondaryAddressNeeded(value) : adminSetSecondaryAddressNeeded(value));
  },

  async setShippingAddress(modifier: Partial<State.Address>): Promise<void> {
    await dispatch(isRaveCheckout ? setShippingAddress(modifier) : adminSetShippingAddress(modifier));
  },

  async setBillingAddress(modifier: Partial<State.Address>): Promise<void> {
    await dispatch(isRaveCheckout ? setBillingAddress(modifier) : adminSetBillingAddress(modifier));
  },

  async goToPayment(isFreeCart?: boolean): Promise<void> {
    isRaveCheckout
      ? await dispatch(goToPaymentAction(isFreeCart)).catch(console.error)
      : await dispatch(addressProceedToNext());
  },
  setSavedShippingAddressEdited(payload: boolean): void {
    dispatch(setSavedShippingAddressEdited(payload));
  },
  setSavedBillingAddressEdited(payload: boolean): void {
    dispatch(setSavedBillingAddressEdited(payload));
  },
});

const mapStateToProps = (state: State.Root, { isRaveCheckout }: { isRaveCheckout: boolean }) => {
  const isAddressValidationModalOpen = isAddressValidationModalOpenSelector(state);
  const loading = isRaveCheckout ? loadingSelector(state) : checkoutLoadingStateSelector(state);
  const smartyValidationFetched = isRaveCheckout
    ? smartyValidationFetchedSelector(state)
    : adminSmartyValidationFetchedSelector(state);
  const shippingSuggestions = isRaveCheckout
    ? smartyShippingAddressSelector(state)
    : adminSmartyShippingAddressSelector(state);
  const shippingAddress = isRaveCheckout ? shippingAddressSelector(state) : adminShippingAddressSelector(state);
  const reason = isRaveCheckout ? smartyValidationReasonSelector(state) : adminSmartyValidationReasonSelector(state);

  const billingAddress = isRaveCheckout ? billingAddressSelector(state) : adminBillingAddressSelector(state);
  const billingReason = isRaveCheckout
    ? smartyValidationBillingReasonSelector(state)
    : adminSmartyBillingValidationReasonSelector(state);
  const billingSuggestions = isRaveCheckout
    ? smartyBillingAddressSelector(state)
    : adminSmartyBillingAddressSelector(state);

  return {
    billingAddress,
    billingReason,
    loading,
    smartyValidationFetched,
    isAddressValidationModalOpen,
    shippingSuggestions,
    shippingAddress,
    reason,
    isRaveCheckout,
    billingSuggestions,
  };
};

export const ModalAddressValidationContainer = connect(mapStateToProps, mapActionCreators)(ModalAddressValidation);
