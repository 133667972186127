import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { allocate, setError, setProcessing } from '../modules/shareAllocation';

import { errorSelector, processingSelector } from '../modules/shareAllocation/selectors';
import { PageShareAllocation } from 'components/pages/PageShareAllocation';

const mapActionCreators = (dispatch: Dispatch) => ({
  allocate(): void {
    dispatch(allocate());
  },

  reset(): void {
    dispatch(setError(null));
    dispatch(setProcessing(false));
  },
});

const mapStateToProps = (state: State.Root) => {
  return {
    processing: processingSelector(state),
    error: errorSelector(state),
  };
};

export const PageShareAllocationContainer = connect(mapStateToProps, mapActionCreators)(PageShareAllocation);
