import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { payInvoicesSelector } from 'modules/clientAdmin/selectors';
import { resetPayInvoicesModule } from 'modules/clientAdmin/actions';
import { ButtonEnums, Button, Divider, Grid, OnlyDesktop } from 'components/atoms';
import { InvoicesPaymentConfirmation } from 'components/molecules/InvoicesPaymentConfirmation/InvoicesPaymentConfirmation';
import { HelpSection } from 'components/organisms/HelpSection/HelpSection';
import { SingleColumnPageTemplate } from 'components/templates/SingleColumn/SingleColumn';
import { getPath, isServer } from 'utils';
import { Routes } from 'constants/index';
import { ReactComponent as Print } from 'resources/images/ic-print.svg';

export const PageInvoicesPaymentConfirmation: React.FC = () => {
  const payInvoices = useSelector(payInvoicesSelector);
  const { result, loading } = payInvoices;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!result) {
      dispatch(push(generatePath(getPath(Routes.ORG_ROOT))));
    }
    return () => dispatch(resetPayInvoicesModule());
  }, [result, dispatch]);

  const handlePrintClick = React.useCallback(() => {
    if (!isServer) window.print();
  }, []);

  return (
    <SingleColumnPageTemplate>
      {!loading && result ? (
        <Grid>
          <Grid.Row>
            <Grid.Column computer={11} mobile={16}>
              <Wrapper>
                <OnlyDesktop>
                  <StyledButton
                    testId="receipt-confirmed-print"
                    variant={ButtonEnums.variants.link}
                    size={ButtonEnums.sizes.large}
                    icon={<Print />}
                    iconPosition={ButtonEnums.iconPosition.left}
                    onClick={handlePrintClick}
                  >
                    Print order confirmation
                  </StyledButton>
                </OnlyDesktop>
                <StyledPageTitle>Payment Confirmed</StyledPageTitle>
                <Divider />
                <InvoicesPaymentConfirmation invoicesPayment={result} />
                <StyledHelpSection />
              </Wrapper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <Loader active>Loading</Loader>
      )}
    </SingleColumnPageTemplate>
  );
};

const StyledPageTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes.xl};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.light};
  height: ${props => props.theme.pxToRem(44)};
  &&& {
    margin: ${props => `${props.theme.pxToRem(18)} 0 ${props.theme.pxToRem(10)} 0`};
    color: ${props => props.theme.colors.neutralGrey8};
  }
  grid-column: 1;
  grid-row: 1;
`;

const StyledHelpSection = styled(HelpSection)`
  max-width: ${props => props.theme.pxToRem(645)};
  margin-top: ${props => props.theme.pxToRem(33)};
  margin-bottom: ${props => props.theme.pxToRem(78)};
`;

const StyledButton = styled(Button)`
  &&&& {
    position: absolute;
    top: ${props => props.theme.pxToRem(10)};
    right: 0;

    > svg path {
      fill: ${props => props.theme.colors.primaryDarkPurple};
    }

    @media print {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: ${props => props.theme.pxToRem(40)} 0 ${props => props.theme.pxToRem(80)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(24)} 0 ${props => props.theme.pxToRem(40)};
  }
`;
