import React, { useState } from 'react';
import styled from 'styled-components';
import { generatePath } from 'react-router';
import { Checkout } from 'mxp-schemas';
import { ButtonEnums, Button } from 'components/atoms';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

interface Props {
  orgId: string;
  title: string;
  address?: Checkout.Address;
  selectOrganization: (path: string) => void;
}

export const CenterAdminSelectionItem: React.FC<Props> = ({ orgId, title, selectOrganization, address }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const path = generatePath(getPath(Routes.CENTER_ADMIN_ROOT), { orgId });
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    selectOrganization(path);
  };

  return (
    <StyledListItem>
      <StyledCompanyInfo>
        <StyledCompanyTitle>{title}</StyledCompanyTitle>
        {address && (
          <StyledAddress>
            {`${address.addressLine1}., ${address.city}, ${address.state}, ${address.zipCode}`}
          </StyledAddress>
        )}
      </StyledCompanyInfo>

      <StyledButton
        loading={loading}
        size={ButtonEnums.sizes.medium}
        variant={ButtonEnums.variants.primary}
        testId={`view-organization-${orgId}-button`}
        onClick={handleButtonClick}
        iconName="chevron right"
        iconPosition={ButtonEnums.iconPosition.right}
      >
        View firm
      </StyledButton>
    </StyledListItem>
  );
};

const StyledListItem = styled.li`
  height: ${props => props.theme.pxToRem(120)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => props.theme.pxToRem(10)};
  padding: 0 ${props => props.theme.pxToRem(40)};
  box-shadow: 0 0 ${props => props.theme.pxToRem(8)} 0 ${props => props.theme.colors.neutralGrey3};
  margin-bottom: ${props => props.theme.pxToRem(20)};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledCompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCompanyTitle = styled.h4`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.l};
  margin-top: 0;
`;

const StyledAddress = styled.div`
  line-height: 1.33;
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey4};
`;

const StyledButton = styled(Button)`
  width: ${props => props.theme.pxToRem(130)};
`;
