import React, { useState, useCallback } from 'react';
import { Orders, Product } from 'mxp-schemas';
import { Modal, Button, ButtonEnums, DayPickerRange, ClapSpinner } from 'components/atoms';
import styled from 'styled-components';
import { theme } from 'theme';
import { MomentHelpers, getAvailabilityDate } from 'utils';
import { ReactComponent as EventsIcon } from 'resources/images/events.svg';
import moment from 'moment-timezone';

interface ModalExtendAccessProps {
  isOpen: boolean;
  loading: boolean;
  product: Common.ProductItemData | undefined;
  confirm: (args: Orders.ProductAccessExtensionB2C) => void;
  toggleOpen: () => void;
  aicpaId: string;
  creditMemo: (productSKU?: string, orderNumber?: string) => void;
}

export const ModalExtendAccess: React.FC<ModalExtendAccessProps> = ({
  isOpen,
  loading,
  product,
  confirm,
  toggleOpen,
  aicpaId,
  creditMemo,
}) => {
  const {
    productType = '',
    zuoraTermEndDate = '',
    accessEndDate = '',
    title = '',
    sku = '',
    orderNumber,
    productId,
    zuoraTermStartDate,
    accessStartDate,
  } = product || {};

  const expiresOn = (
    [
      Product.ProductType.SECTION,
      Product.ProductType.CREDENTIAL,
      Product.ProductType.MEMBERSHIP,
      Product.ProductType.CENTER_MEMBERSHIP,
      Product.ProductType.FLP,
      Product.ProductType.SUBSCRIPTION,
    ] as string[]
  ).includes(productType)
    ? getAvailabilityDate(zuoraTermEndDate || accessEndDate, false)
    : getAvailabilityDate(accessEndDate, false);

  const [extendEndDate, setExtendEndDate] = useState('');

  const isConfirmDisabled = loading || !extendEndDate;

  const handleExtendAccess = useCallback(
    endDate => {
      setExtendEndDate(endDate);
    },
    [setExtendEndDate]
  );

  const handleCancel = useCallback(() => {
    toggleOpen();
    setExtendEndDate('');
  }, [toggleOpen]);

  const handleConfirm = useCallback(() => {
    const apiParam: any = [];
    const prevDate = moment(accessEndDate).format('YYYY-MM-DD');
    const newDate = moment(extendEndDate);
    const daysExtended = newDate.diff(prevDate, 'days');
    apiParam.push({
      productId,
      orderNumber,
      accessStartDate,
      accessEndDate,
      zuoraTermStartDate,
      zuoraTermEndDate,
      daysExtended,
      extendEndDate,
      sku,
    });
    confirm(apiParam);
    setExtendEndDate('');
    creditMemo(product?.sku, product?.orderNumber);
  }, [
    extendEndDate,
    confirm,
    productId,
    orderNumber,
    accessStartDate,
    accessEndDate,
    zuoraTermStartDate,
    zuoraTermEndDate,
    sku,
    product,
    creditMemo,
  ]);

  const cancelNode = (
    <Button
      size={ButtonEnums.sizes.medium}
      testId="extend-access-cancel"
      variant={ButtonEnums.variants.secondary}
      onClick={handleCancel}
      disabled={loading}
    >
      Cancel
    </Button>
  );

  const confirmNode = (
    <Button
      size={ButtonEnums.sizes.medium}
      testId="extend-access-confirm"
      variant={ButtonEnums.variants.primary}
      onClick={handleConfirm}
      disabled={isConfirmDisabled}
    >
      Yes, extend access
    </Button>
  );

  return (
    <StyledModal
      size="tiny"
      heading={'Extend Access'}
      icon={<StyledEventsIcon />}
      open={isOpen}
      testId="extend-access-modal"
      centered={false} // For IE11.
      cancelNode={cancelNode}
      confirmNode={confirmNode}
    >
      {loading || !product ? (
        <StyledClapSpinnerContainer>
          <ClapSpinner
            size={45}
            frontColor={theme.colors.primaryPurple}
            backColor={theme.colors.primaryPurple}
            loading={loading}
          />
        </StyledClapSpinnerContainer>
      ) : (
        <>
          <SubHeader>
            Access to <b>{title}</b> expires on <b>{expiresOn}</b>
            <br />
            Please select which date you would like to extend to:
          </SubHeader>

          <StyledDayPicker
            key={`day-picker-${sku}`}
            selectedStartDate={MomentHelpers.convertToMoment(expiresOn, 'MMM DD, YYYY').format('YYYY-MM-DD')}
            selectedEndDate={extendEndDate}
            extendAccess
            handleExtendAccess={handleExtendAccess}
          />
        </>
      )}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
      ${props => `padding: ${props.theme.pxToRem(40)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(10)}`}
    }
    padding: ${props => props.theme.pxToRem(46)} ${props => props.theme.pxToRem(50)} 0;
    width: ${props => props.theme.pxToRem(590)};
    border-radius: ${props => props.theme.pxToRem(2)};
    text-align: center;
    box-shadow: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(20)} 0 rgba(0, 0, 0, 0.3);
    div.header {
      font-weight: ${props => props.theme.fontWeights.light};
      line-height: 1.33;
      color: ${props => props.theme.colors.neutralGrey8};
    }
    div.content {
      color: ${props => props.theme.colors.neutralGrey8};
      padding: 0;
      margin-top: ${props => props.theme.pxToRem(7)};
      line-height: 1.57;
      b {
        font-weight: ${props => props.theme.fontWeights.bold};
      }
    }
    div.actions {
      padding: ${props => props.theme.pxToRem(20)} !important;
    }
  }
`;

const StyledClapSpinnerContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: ${props => props.theme.pxToRem(350)};
  justify-content: center;
  align-items: center;
`;

const StyledEventsIcon = styled(EventsIcon)`
  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const SubHeader = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(34)};
`;

const StyledDayPicker = styled(DayPickerRange)`
  display: flex;
  justify-content: center;
`;
