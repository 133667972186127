import React from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import { handleEvent, DOWNLOAD_EVENT } from 'utils/Analytics';
import { formatPrice } from 'utils';
import { Invoices } from 'mxp-schemas';
import { ButtonEnums, Button, Table, Checkbox, ClapSpinner } from 'components/atoms';
import { ReactComponent as GetApp } from 'resources/images/icon-dev-ic-get-app.svg';

import { CheckboxProps } from 'semantic-ui-react';

interface Props {
  invoice: State.InvoiceTableRow;
  downloadId: string | null;
  downloadError: boolean;
  isDueInvoice: boolean;
  downloadFileClick: (id: string) => void;
  payInvoiceClick: (id: string) => void;
  isB2C?: boolean;
  handleInvoiceSelect: (checked: boolean | undefined, id: string) => void;
}

export const InvoicesTableRow: React.FC<Props> = React.memo(
  ({
    invoice,
    downloadId,
    downloadError,
    isDueInvoice,
    downloadFileClick,
    payInvoiceClick,
    handleInvoiceSelect,
    isB2C = false,
  }) => {
    const handleDownloadFileClick = React.useCallback(() => {
      handleEvent({ filename: `${invoice?.invoiceNumber}.pdf` }, DOWNLOAD_EVENT);
      downloadFileClick(invoice.id);
    }, [invoice, downloadFileClick]);

    const isPaid: boolean = invoice?.status === Invoices.InvoiceStatus.PAID;

    return (
      <Table.Row key={invoice.id}>
        <StyledTableCell>
          <CheckboxStyled
            width="18"
            height="18"
            type="checkbox"
            testId={`select-all-invoices-checkbox`}
            checked={invoice.isSelected}
            onChange={(e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) =>
              // tslint:disable-next-line jsx-no-lambda
              handleInvoiceSelect(data.checked, invoice.id!)
            }
            disabled={isPaid}
          />
        </StyledTableCell>

        <Table.Cell>{invoice.invoiceDate}</Table.Cell>
        <Table.Cell>{formatPrice(invoice.amount, invoice.currency)}</Table.Cell>
        <Table.Cell>{invoice.invoiceNumber}</Table.Cell>
        <Table.Cell>
          {invoice.status === Invoices.InvoiceStatus.OVERDUE ? (
            <StyledSpan>{invoice.status}</StyledSpan>
          ) : (
            invoice.status
          )}
        </Table.Cell>
        <Table.Cell>{invoice.DueDateOverride__c ? invoice.DueDateOverride__c : invoice.dueDate}</Table.Cell>
        <Table.Cell>
          {downloadId === invoice.id && !downloadError ? (
            <StyledSpinner>
              <ClapSpinner size={24} frontColor={theme.colors.primaryPurple} backColor={theme.colors.primaryPurple} />
            </StyledSpinner>
          ) : (
            <StyledDownloadButton
              testId={invoice.id}
              variant={ButtonEnums.variants.link}
              size={ButtonEnums.sizes.large}
              icon={<GetApp />}
              iconPosition={ButtonEnums.iconPosition.left}
              onClick={handleDownloadFileClick}
            >
              Download
            </StyledDownloadButton>
          )}
        </Table.Cell>
      </Table.Row>
    );
  }
);

const StyledSpinner = styled.div`
  > div {
    margin: 0 auto;
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: initial;
    }
  }
`;

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.interfaceRed};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledDownloadButton = styled(Button)`
  display: flex;
  margin-left: auto;
  svg path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: initial;
  }
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    > label {
      font-size: ${props => props.theme.fontSizes.s};
      color: ${props => props.theme.colors.neutralGrey8};
      font-weight: ${props => props.theme.fontWeights.light};
    }
  }
`;

const StyledTableCell = styled(Table.Cell)`
  &&&&& {
    vertical-align: inherit;
  }
`;
