import React from 'react';
import styled from 'styled-components/macro';
import { Button, ButtonEnums, ButtonSizes, OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms';
import { ReactComponent as Cancel } from 'resources/images/ic-cancel.svg';
import { ProductItemData } from 'mxp-schemas/src/types/products';
import { CartCompoundAnalytics, getOrderTypeAnalytics } from 'utils/Analytics/helpers';
import { CART_REMOVE_BUNDLE_EVENT, handleEvent } from 'utils/Analytics';

interface Props {
  bundleId: string;
  products: ProductItemData[];
  itemCountInThisBundle: number;
  removeCartItems?: (bundleItemsIds: string[]) => void;
}

export const RemoveBundleButton: React.FC<Props> = ({ bundleId, products, itemCountInThisBundle, removeCartItems }) => {
  const bundleItemIds = React.useMemo(
    () => products.filter(product => product.bundleId === bundleId).map(product => product.lineItemId),
    [products, bundleId]
  );

  const bundleItemSku = React.useMemo(
    () => products.filter(product => product.bundleId === bundleId).map(product => product.masterVariantSKU),
    [products, bundleId]
  );

  const handleRemoveBundleFromCart = React.useCallback(() => {
    if (removeCartItems?.(bundleItemIds)) {
      removeCartItems(bundleItemIds);
    }

    const payload: CartCompoundAnalytics = {
      category: [],
      id: bundleItemIds,
      bundleId: [bundleId],
      bundleSku: [(bundleItemSku && bundleItemSku[0]) || ''],
      name: [],
      price: [],
      quantity: [itemCountInThisBundle],
      sku: [(bundleItemSku && bundleItemSku[0]) || ''],
      orderType: [
        getOrderTypeAnalytics({
          isStandingOrder: false,
          isBundle: !!bundleId,
          isFreeTrial: false,
          isB2BMembership: false,
          isB2CMembership: false,
        }),
      ],
      productBundleIds: [],
      productBundleSkus: [],
      shippingCost: [],
      shippingLocation: [],
      shippingType: [],
      freeTrialEndDate: [],
      productTax: [],
    };
    handleEvent(payload, CART_REMOVE_BUNDLE_EVENT);
  }, [bundleItemIds, removeCartItems, itemCountInThisBundle, bundleId, bundleItemSku]);

  const renderButton = (buttonSize: ButtonSizes) => (
    <StyledButton
      testId={`cart-remove-bundle-${bundleId}`}
      variant={ButtonEnums.variants.link}
      size={buttonSize}
      icon={<Cancel />}
      iconPosition={ButtonEnums.iconPosition.left}
      onClick={handleRemoveBundleFromCart}
    >
      Remove Bundle (Contains {itemCountInThisBundle} items)
    </StyledButton>
  );

  return (
    <>
      <OnlyMobileCSS>{renderButton(ButtonEnums.sizes.large)}</OnlyMobileCSS>
      <OnlyDesktopCSS>{renderButton(ButtonEnums.sizes.small)}</OnlyDesktopCSS>
    </>
  );
};

const StyledButton = styled(Button)`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(16)};

    svg {
      margin-left: 0;
      path {
        fill: ${props => props.theme.colors.primaryPurple};
      }
    }
  }
`;
