import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, generatePath } from 'react-router';
import styled from 'styled-components';
import {
  Link,
  AicpaLogo,
  OnlyDesktopCSS,
  OnlyMobileCSS,
  Table,
  Container,
  Divider,
  Heading,
  ResultCounter,
  TablePlaceholder,
} from 'components/atoms';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { getPath, formatPrice } from 'utils';
import { Routes } from 'constants/index';
import { ReactComponent as Cross } from 'resources/images/ic-cancel.svg';
import { getOrganizationOrdersDetails } from 'modules/admin/actions';
import {
  organizationOrderDetailsList,
  organizationOrderDetailsListLoading,
  selectedCurrency,
} from 'modules/admin/selectors';

export const PageOrderDetails: React.FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { accountId, accountNumber, legalEntity, orderNumber } = useParams() as any;

  const orderListLoading: boolean = useSelector(organizationOrderDetailsListLoading);
  const orderDetails: State.OrganizationOrderDetail[] = useSelector(organizationOrderDetailsList);
  const currency = useSelector(selectedCurrency);

  useEffect(() => {
    dispatch(getOrganizationOrdersDetails(orderNumber));
  }, [dispatch, orderNumber]);

  const handleLogoClick = () => {
    history.push(getPath(Routes.ADMIN_ORGANIZATION));
  };

  return (
    <StyledBackground>
      <StyledHeaderDiv>
        <Container>
          <OnlyDesktopCSS>
            <StyledContainer fluid>
              <StyledAicpaLogo overrideAnalytics={true} onClick={handleLogoClick} />
            </StyledContainer>
          </OnlyDesktopCSS>
          <OnlyMobileCSS>
            <StyledMobileContainer>
              <StyledMobileTop>
                <StyledAicpaLogo overrideAnalytics={true} onClick={handleLogoClick} />
              </StyledMobileTop>
            </StyledMobileContainer>
          </OnlyMobileCSS>
        </Container>
      </StyledHeaderDiv>

      <Container>
        <StyledPreviousPage backText="Back to orders" />
        <StyledPreferencesHeading as="h1">{`Order #${orderNumber}`}</StyledPreferencesHeading>
        <StyledDivider />

        {orderListLoading ? (
          <TablePlaceholder />
        ) : (
          <>
            <ResultCounter
              count={orderDetails?.length || 0}
              boldedText={`product${orderDetails?.length !== 1 ? 's' : ''}`}
              normalText={`below are in this order${orderDetails?.length === 0 ? '.' : ''}`}
              className="table-header"
            />
            <StyledTable striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Product Name</Table.HeaderCell>
                  <Table.HeaderCell>Provision By</Table.HeaderCell>
                  <Table.HeaderCell>List Price</Table.HeaderCell>
                  <Table.HeaderCell>Discount Price</Table.HeaderCell>
                  <Table.HeaderCell>Quantity</Table.HeaderCell>
                  <Table.HeaderCell>Item Subtotal</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {orderDetails?.map((order: State.OrganizationOrderDetail, index) => {
                  const discountedPrice =
                    parseFloat(order?.UnitPrice) - parseFloat(order?.RV_Discount__c) / parseFloat(order?.Quantity);
                  return (
                    <Table.Row key={`${order?.Id} ${index}`}>
                      <Table.Cell>{order?.RV_ProductName__c}</Table.Cell>
                      <Table.Cell>{order?.RV_Provisioning__c}</Table.Cell>
                      <Table.Cell>{formatPrice(parseFloat(order?.UnitPrice), currency)}</Table.Cell>

                      <Table.Cell>{formatPrice(discountedPrice, currency)}</Table.Cell>

                      <Table.Cell>{order?.Quantity}</Table.Cell>

                      <Table.Cell>{formatPrice(discountedPrice * parseFloat(order?.Quantity), currency)}</Table.Cell>

                      <Table.Cell>
                        <Link
                          testId="test-link"
                          to={generatePath(getPath(Routes.ADMIN_ORDER_REFUND_CANCEL), {
                            accountId,
                            accountNumber,
                            legalEntity,
                            orderNumber,
                            productId: order.Id,
                          })}
                        >
                          {order.AvailableSeats > 0 && order?.subscriptionStatus?.toLowerCase() === 'active' && (
                            <div style={{ display: 'flex' }}>
                              <StyledCrossIcon />
                              <p>Refund / Cancel</p>
                            </div>
                          )}
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </StyledTable>
          </>
        )}
      </Container>
    </StyledBackground>
  );
};

const StyledBackground = styled.div`
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const StyledTable = styled(Table)`
  &&&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(64)};
    margin-top: ${props => props.theme.pxToRem(24)};

    tr td {
      font-size: ${props => props.theme.fontSizes.xs};
    }
  }
`;

const StyledAicpaLogo = styled(AicpaLogo)`
  cursor: pointer;
`;

const StyledContainer = styled(Container)`
  &&&& {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledMobileContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledMobileTop = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(14)};
    margin-bottom: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledPreferencesHeading = styled(Heading)`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  border-bottom: none;
`;

const StyledHeaderDiv = styled.div`
  background-color: ${props => props.theme.colors.neutralWhite};
  padding-top: ${props => props.theme.pxToRem(15)};
  padding-bottom: ${props => props.theme.pxToRem(25)};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
    border-top-color: transparent;
    border-bottom-color: ${props => props.theme.colors.neutralGrey3};
    color: ${props => props.theme.colors.neutralGrey3};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(25)};
    }
  }
`;

const StyledCrossIcon = styled(Cross)`
  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
`;

const StyledPreviousPage = styled(PreviousPage)`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(30)};
  }
`;
