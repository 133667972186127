import React from 'react';
import { Divider, Link } from 'components/atoms';
import styled from 'styled-components';
import { User as UserUtils } from 'mxp-utils';
import { User } from 'mxp-schemas';
import { useSelector } from 'react-redux';
import { sectionsHashSelector } from 'modules/startup/selectors';

interface Props {
  items: Common.SectionMembership[] | null | undefined;
  topics?: State.ContentTopic[];
}

export const MembersExclusive: React.FC<Props> = ({ items, topics }) => {
  const sectionsHash = useSelector(sectionsHashSelector);

  if (!items || !items.length) {
    return null;
  }
  const finalItems = items.reduce((result: Common.SectionMembership[], item: Common.SectionMembership) => {
    const forensicLink = sectionsHash[User.FVSSectionMembershipIdsEnum.FORENSIC]?.propPageLink;
    const valuationLink = sectionsHash[User.FVSSectionMembershipIdsEnum.VALUATION]?.propPageLink;
    // FVS Section Member
    if (
      item.oktaRole === UserUtils.sectionMembershipMap[User.MembershipIdsEnum.MRUSR0018]?.oktaRole ||
      item.oktaRole === UserUtils.sectionMembershipMap[User.MembershipIdsEnum.MRUSR0019]?.oktaRole
    ) {
      if (topics?.some(topic => topic.slug === 'forensic-services')) {
        result.push({
          title: UserUtils.fvsSectionMembershipMap.Forensic.sectionTitle,
          link: forensicLink,
          oktaRole: item.oktaRole,
        });
      }
      if (topics?.some(topic => topic.slug === 'valuation-services')) {
        result.push({
          title: UserUtils.fvsSectionMembershipMap.Valuation.sectionTitle,
          link: valuationLink,
          oktaRole: item.oktaRole,
        });
      }
      return result;
    }
    result.push(item);
    return result;
  }, []);

  return (
    <>
      <StyledSmallHeading tabIndex={0}>Exclusively for</StyledSmallHeading>
      <Divider />
      <StyledWrapper data-testid="section-members-exclusive">
        {finalItems.map((item: Common.SectionMembership) => (
          <StyledSectionLink data-testid={item.title} to={item.link} key={item.title}>
            {item.title}
          </StyledSectionLink>
        ))}
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(48)};
`;

const StyledSmallHeading = styled.h2`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.s};
  margin: 0;
  line-height: 1.5;
`;

const StyledSectionLink = styled(Link)`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  display: block;
`;
