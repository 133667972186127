import React from 'react';
import styled from 'styled-components';

interface ThumbProps {
  down?: boolean;
  testId?: string;
  action?: (...args: any[]) => void;
  highlight?: boolean;
}

const StyledCircle = styled.circle<ThumbProps>`
  fill: ${(props: any) =>
    props.highlight
      ? props.down
        ? props.theme.colors.interfaceRed
        : props.theme.colors.interfaceGreen
      : props.theme.colors.neutralWhite};
  stroke: ${(props: any) => props.theme.colors.neutralGrey3};
`;

const StyledPath = styled.path<ThumbProps>`
  fill: ${(props: { highlight?: boolean; theme: any }) =>
    props.highlight ? props.theme.colors.neutralWhite : props.theme.colors.primaryPurple};
`;

const StyledSvg = styled.svg<ThumbProps>`
  ${(props: any) => (props.down ? 'transform: rotate(180deg);' : '')}

  ${props => props.theme.mediaQueries.computerMin} {
    &:hover {
      circle {
        fill: ${(props: { theme: any }) => props.theme.colors.primaryLightPurple};
      }
      path {
        fill: ${(props: { theme: any }) => props.theme.colors.neutralWhite};
      }
    }
  }
`;

export const Thumb: React.FC<ThumbProps> = React.memo(({ highlight, down, action, testId }) => {
  return (
    <StyledSvg data-testid={testId} down={down} viewBox="0 0 48 48" onClick={action}>
      <StyledCircle highlight={highlight} down={down} strokeWidth="1" fillRule="nonzero" cx="24" cy="24" r="23.5" />
      <StyledPath
        highlight={highlight}
        d="
			  M11,34.5
			  L16,34.5
			  L16,20.5
			  L11,20.5
			  L11,34.5
			  Z
			  M36.8,22.2 
			  C36.8,20 36,19.8 35, 19.4
			  L27.2,19.4 
			  L28.2,14 
			  C28.2,14 28.4,13 28,12.6
			  L26.6,11.2
			  L18.4,19.4 
			  C18.4,19.4 18.2,20 18.2,21 
			  L18.2,32 
			  C18.2,34 19.2,34.5 21,34.5
			  L31,34.5 
			  C32,34.5 33,33.5 33,33.5
			  L36.6,25 
			  C36.6,25 36.9,24 36.8,24 
			  L36.8,22.2
			  Z"
      />
    </StyledSvg>
  );
});
