import React from 'react';
import { Content, Contentful } from 'mxp-schemas';
import { generatePath } from 'react-router-dom';
import querystring from 'query-string';
import styled from 'styled-components';
import { MenuItem, DropdownProps } from 'semantic-ui-react';
import { DateFilter } from 'components/molecules/DateFilter/DateFilter';
import { Heading, Link, Tab, Grid, Button, ButtonVariants, ButtonEnums } from 'components/atoms';
import { DefaultInlineLinkStyles, InlineLinkOnDarkBgStyles, ExternalLinkStylesDark } from 'components/atoms/Link/Link';
import { DropdownSearch } from './DropdownSearch';
import { ContentTypes, FilterNames, Routes } from 'constants/index';
import { emptyObject, emptyArray, getPath, MomentHelpers, omitProps, isMobileViewPort } from 'utils';
import { handleEvent, NAV_CLICK } from 'utils/Analytics';
import { ReactComponent as CpeLearningIcon } from 'resources/images/cpeLearning.svg';
import { ReactComponent as EventsIcon } from 'resources/images/events.svg';
import { IC_OPEN_IN_NEW_WHITE } from 'resources/images';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  isMobile: boolean;
  headingType: 'h1' | 'h2';
  topicOptions: Common.DropdownOptionProps[];
  contentTypeOptions: Common.DropdownOptionProps[];
  cpeCreditsOptions: Common.DropdownOptionProps[];
  eventDateFilterItems: Common.FilterItemProps[];
  fetchSearchContent: (
    options: { onlyProducts: boolean; onlyEvents: boolean },
    customFilters?: { [key: string]: string | undefined }
  ) => Promise<void>;
  navigate: (path: string) => void;
  setSearchReferrer: (referrer: string) => void;
}

export const BlockIntegratedSearch: React.FC<Props> = ({
  modularBlock,
  headingType,
  topicOptions,
  contentTypeOptions,
  cpeCreditsOptions,
  eventDateFilterItems,
  fetchSearchContent,
  setSearchReferrer,
  navigate,
}) => {
  const block = modularBlock as Contentful.ModularContent.IntegratedSearchBlock;
  const isMobile: boolean = isMobileViewPort();
  const TabsMap = { All: 0, ConferencesWebcasts: 1 };

  const [activeIndex, setActiveIndex] = React.useState<number>(TabsMap.All);
  const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<{ [key: string]: string | undefined }>(emptyObject);
  // dateFilters stored separately to skip fetchSearchContent query
  const [dateFilters, setDateFilters] = React.useState<{ [key: string]: string | undefined }>(emptyObject);

  const onlyProducts: boolean = activeIndex === TabsMap.All;
  const onlyEvents: boolean = activeIndex === TabsMap.ConferencesWebcasts;
  const dateFilterValue: string = `${MomentHelpers.getDate(dateFilters[FilterNames.EVENT_START_DATE]) || ''}${
    dateFilters[FilterNames.EVENT_END_DATE]
      ? ` - ${MomentHelpers.getDate(dateFilters[FilterNames.EVENT_END_DATE])}`
      : ''
  }`;

  React.useEffect(() => {
    fetchSearchContent({ onlyProducts, onlyEvents }, filters);
  }, [onlyProducts, onlyEvents, filters, fetchSearchContent]);

  const handleSelectedTab = React.useCallback((index: number) => {
    // Clear filters if tab changed
    setFilters(emptyObject);
    setDateFilters(emptyObject);
    // close calendar
    setShowCalendar(false);
    // set new tab index
    setActiveIndex(index);
  }, []);

  const toggleShowCalendar = React.useCallback(() => {
    if (isMobile) return; // Absolute positioned DateFilter visible only on Desktop, mobile one controlled by DropdownSearchModal
    setShowCalendar(!showCalendar);
  }, [isMobile, showCalendar]);

  const closeCalendar = React.useCallback(() => setShowCalendar(false), []);

  const handleDropdownChange = React.useCallback(
    (e: React.SyntheticEvent, data: DropdownProps): void => {
      const nextDateFiltersState = {
        [FilterNames.EVENT_START_DATE]: undefined,
        [FilterNames.EVENT_END_DATE]: undefined,
      };
      if (data.name === FilterNames.EVENT_START_DATE) {
        if (!data.open) {
          // cross icon date filter dropdown click handler
          setDateFilters(nextDateFiltersState);
        } else {
          closeCalendar();
        }
      } else {
        // rest date filter if other dropdown values changed
        setDateFilters(nextDateFiltersState);
        setFilters({ ...filters, [data.name]: data.value || undefined });
      }
    },
    [filters, closeCalendar]
  );

  const handleDateFilterChange = React.useCallback(
    (filterName: string, value: string): void => {
      if (filterName === FilterNames.EVENT_END_DATE && !value) return;
      const shouldResetEndDateValue: boolean = filterName === FilterNames.EVENT_START_DATE;
      setDateFilters({
        ...dateFilters,
        [filterName]: value || undefined,
        ...(shouldResetEndDateValue && { [FilterNames.EVENT_END_DATE]: '' }),
      });
    },
    [dateFilters]
  );

  const handleClickSearch = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      // merge filters to generate correct URL
      const mergedFilters = { ...filters, ...dateFilters };
      const selectedFilters = Object.keys(mergedFilters).reduce((acc: any, key: string) => {
        if (mergedFilters[key]) acc[key] = mergedFilters[key];
        return acc;
      }, {});

      const eventTarget = event.target as HTMLAnchorElement;
      const isTopicSelected: boolean = Boolean(selectedFilters[FilterNames.TOPIC_LEVEL0]);
      const isTypeSelected: boolean = Boolean(selectedFilters[FilterNames.CONTENT_TYPE]);
      const isCpeCreditSelected: boolean = Boolean(selectedFilters[FilterNames.CPE_CREDIT]);
      const isDateSelected: boolean = Boolean(
        selectedFilters[FilterNames.EVENT_START_DATE] || selectedFilters[FilterNames.EVENT_END_DATE]
      );

      const topicSlug: string = selectedFilters[FilterNames.TOPIC_LEVEL0];
      const categorySlug: string = Content.CategorySlugs.CPE_LEARNING;

      // analytics
      const buttonEventValue = `button:search:int:${eventTarget.textContent}:NA`;

      if (isTopicSelected && !isTypeSelected && !isCpeCreditSelected && !isDateSelected) {
        const topicSelectedPayload = `all:${topicSlug}:all:all`;
        setSearchReferrer(topicSelectedPayload);
        handleEvent({ clickValue: buttonEventValue, searchFilter: topicSelectedPayload }, NAV_CLICK);
        return navigate(`${generatePath(getPath(Routes.PRODUCT_AGGS_BY_TOPIC_PAGE), { topicSlug })}`);
      }

      if (
        isTypeSelected &&
        !isTopicSelected &&
        !isCpeCreditSelected &&
        !isDateSelected &&
        selectedFilters[FilterNames.CONTENT_TYPE] !== ContentTypes.WEBCASTS &&
        selectedFilters[FilterNames.CONTENT_TYPE] !== ContentTypes.CONFERENCE
      ) {
        const productTypeSlug: string = selectedFilters[FilterNames.CONTENT_TYPE];
        const typeSelectedPayload = `all:all:${productTypeSlug}:all`;
        setSearchReferrer(typeSelectedPayload);
        handleEvent({ clickValue: buttonEventValue, searchFilter: typeSelectedPayload }, NAV_CLICK);
        return navigate(`${generatePath(getPath(Routes.PRODUCT_AGGS_BY_TYPE_PAGE), { productTypeSlug })}`);
      }

      if (
        selectedFilters[FilterNames.CONTENT_TYPE] === ContentTypes.WEBCASTS ||
        selectedFilters[FilterNames.CONTENT_TYPE] === ContentTypes.CONFERENCE
      ) {
        const searchQueryStringWebcastOrConference: string = querystring.stringify(selectedFilters);
        const eventDateRange =
          selectedFilters[FilterNames.EVENT_START_DATE] && selectedFilters[FilterNames.EVENT_END_DATE]
            ? `${selectedFilters[FilterNames.EVENT_START_DATE]}:${selectedFilters[FilterNames.EVENT_END_DATE]}`
            : selectedFilters[FilterNames.EVENT_START_DATE] || selectedFilters[FilterNames.EVENT_END_DATE] || null;
        const eventSelectedPayload = `events:${selectedFilters[FilterNames.TOPIC_LEVEL0] || 'all'}:${
          selectedFilters[FilterNames.CONTENT_TYPE] || 'all'
        }:${eventDateRange || 'all'}`;
        setSearchReferrer(eventSelectedPayload);
        handleEvent({ clickValue: buttonEventValue, searchFilter: eventSelectedPayload }, NAV_CLICK);
        return navigate(
          `${generatePath(getPath(Routes.PRODUCT_AGGS_CALENDAR))}?${searchQueryStringWebcastOrConference}`
        );
      }

      const anySelectedPayload = `all:${selectedFilters[FilterNames.TOPIC_LEVEL0] || 'all'}:${
        selectedFilters[FilterNames.CONTENT_TYPE] || 'all'
      }:${selectedFilters[FilterNames.CPE_CREDIT] || 'all'}`;
      setSearchReferrer(anySelectedPayload);
      handleEvent({ clickValue: buttonEventValue, searchFilter: anySelectedPayload }, NAV_CLICK);
      const searchQueryString: string = querystring.stringify(omitProps(selectedFilters, [FilterNames.TOPIC_LEVEL0]));
      return navigate(
        `${generatePath(getPath(Routes.CATEGORY_AGGS_PAGE_CIMA), { categorySlug, topicSlug })}?${searchQueryString}`
      );
    },
    [filters, dateFilters, navigate, setSearchReferrer]
  );

  const SearchButton: React.FC = () => (
    <StyledButton
      variant={ButtonVariants.primary}
      size={ButtonEnums.sizes.large}
      onClick={handleClickSearch}
      testId="integrated-search"
      overrideAnalytics
    >
      Search
    </StyledButton>
  );

  const renderTopicDropdownSearch = (): React.ReactNode => (
    <StyledDropdownSearch
      name={FilterNames.TOPIC_LEVEL0}
      title="Topic"
      clearable
      options={topicOptions}
      placeholder="Select topic"
      value={filters[FilterNames.TOPIC_LEVEL0] || ''}
      onChange={handleDropdownChange}
      // close calendar if other dropdown in focus
      onFocus={onlyEvents ? closeCalendar : undefined}
    />
  );

  const renderContentTypeDropdownSearch = (): React.ReactNode => (
    <StyledDropdownSearch
      name={FilterNames.CONTENT_TYPE}
      title="Type"
      clearable
      options={contentTypeOptions}
      placeholder="Select type"
      value={filters[FilterNames.CONTENT_TYPE] || ''}
      onChange={handleDropdownChange}
      // close calendar if other dropdown in focus
      onFocus={onlyEvents ? closeCalendar : undefined}
    />
  );

  const panes = [
    {
      menuItem: (
        <MenuItem tabIndex={0} key={TabsMap.All} data-testid="menu-item-all">
          <StyledCpeLearningIcon />
          All
        </MenuItem>
      ),
      name: 'all-options',
      onClick: () => handleSelectedTab(TabsMap.All),
      render: () => (
        <Tab.Pane>
          <StyledTabContentGrid>
            <StyledGridRow as={Grid.Row}>
              <StyledGridColumn computer={4} data-testid="topic-filter-item">
                {renderTopicDropdownSearch()}
              </StyledGridColumn>
              <StyledGridColumn computer={4} data-testid="content-type-filter-item">
                {renderContentTypeDropdownSearch()}
              </StyledGridColumn>
              <StyledGridColumn computer={4} data-testid="cpe-credit-filter-item">
                <StyledDropdownSearch
                  name={FilterNames.CPE_CREDIT}
                  title="CPE Credits"
                  clearable
                  options={cpeCreditsOptions}
                  placeholder="Select credits earned"
                  value={filters[FilterNames.CPE_CREDIT] || ''}
                  onChange={handleDropdownChange}
                />
              </StyledGridColumn>
              <StyledGridColumn computer={4}>
                <SearchButton />
              </StyledGridColumn>
            </StyledGridRow>
          </StyledTabContentGrid>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <MenuItem tabIndex={0} key={TabsMap.ConferencesWebcasts} data-testid="menu-item-conferences">
          <StyledEventsIcon />
          Conferences & Webcasts
        </MenuItem>
      ),
      onClick: () => handleSelectedTab(TabsMap.ConferencesWebcasts),
      render: () => (
        <Tab.Pane>
          <StyledTabContentGrid>
            <StyledGridRow>
              <StyledGridColumn computer={4} data-testid="content-type-filter-item-events">
                {renderContentTypeDropdownSearch()}
              </StyledGridColumn>
              <StyledGridColumn computer={4} data-testid="topic-filter-item-events">
                {renderTopicDropdownSearch()}
              </StyledGridColumn>
              <StyledGridColumn computer={4} data-testid="date-filter-item-events">
                <StyledDropdownSearch
                  name={FilterNames.EVENT_START_DATE} // we are using EVENT_START_DATE as a key here
                  title="Date"
                  open={showCalendar}
                  placeholder="Select a date range"
                  onOpen={toggleShowCalendar}
                  onClose={toggleShowCalendar}
                  closeOnBlur={false}
                  value={dateFilterValue}
                  clearable
                  options={
                    dateFilterValue
                      ? [{ key: dateFilterValue, text: dateFilterValue, value: dateFilterValue }]
                      : emptyArray
                  }
                  onChange={handleDropdownChange}
                  // Next props required only for DropdownSearchModal -> DateFilter
                  selectedStartDate={dateFilters[FilterNames.EVENT_START_DATE] || ''}
                  selectedEndDate={dateFilters[FilterNames.EVENT_END_DATE] || ''}
                  eventDateFilterItems={eventDateFilterItems}
                  handleDateFilterChange={handleDateFilterChange}
                />
                {showCalendar && (
                  <StyledDateFilter
                    items={eventDateFilterItems}
                    numberOfMonths={2}
                    hideTitle
                    hideLegend
                    selectedStartDate={dateFilters[FilterNames.EVENT_START_DATE] || ''}
                    selectedEndDate={dateFilters[FilterNames.EVENT_END_DATE] || ''}
                    handleChange={handleDateFilterChange}
                  />
                )}
              </StyledGridColumn>
              <StyledGridColumn computer={4}>
                <SearchButton />
              </StyledGridColumn>
            </StyledGridRow>
          </StyledTabContentGrid>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <MainColumn>
        {block.header && (
          <StyledHeading tabIndex={0} as={headingType} dangerouslySetInnerHTML={{ __html: block.header }} />
        )}
        {block.description && <StyledParagraph tabIndex={0} dangerouslySetInnerHTML={{ __html: block.description }} />}

        <StyledTabContainer>
          <StyledTab panes={panes} activeIndex={activeIndex} />
        </StyledTabContainer>
        <BottomTextLineWrapper>
          {block.landingPageSubBlocks?.[0]?.description && (
            <StyledBottomDescription
              dangerouslySetInnerHTML={{ __html: block.landingPageSubBlocks[0].description }}
              tabIndex={0}
            />
          )}

          {block.landingPageSubBlocks?.[0]?.ctaUrl && block.landingPageSubBlocks?.[0]?.ctaText && (
            <>
              &nbsp;
              <span>
                <StyledLink testId="integrated-search" to={block.landingPageSubBlocks[0].ctaUrl}>
                  {block.landingPageSubBlocks[0].ctaText}
                </StyledLink>
              </span>
            </>
          )}
        </BottomTextLineWrapper>
      </MainColumn>
    </>
  );
};

const StyledHeading = styled(Heading)`
  text-align: center;
  min-height: auto;
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.35;

  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }

  p {
    margin: 0;
    line-height: 1.35;
  }

  a {
    ${InlineLinkOnDarkBgStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.xl};
    font-weight: ${props => props.theme.fontWeights.medium};
    line-height: 1.38;

    p {
      line-height: 1.38;
    }
  }
`;

const StyledParagraph = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(40)};
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;
  line-height: 1.33;

  p {
    margin: 0;
    line-height: 1.33;
  }

  a {
    ${InlineLinkOnDarkBgStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(32)};
  }
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: ${props => props.theme.pxToRem(740)};
    margin: auto;
    margin-top: 0;
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledTabContainer = styled.div`
  padding: ${props => props.theme.pxToRem(14)} 0 0;
  border-radius: ${props => props.theme.pxToRem(5)};
  background-color: ${props => props.theme.colors.neutralWhite};
  box-shadow: 0 0 ${props => props.theme.pxToRem(7)} 0 rgba(130, 130, 130, 0.5);

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(24)} 0;
    border-radius: 0;
    margin: auto ${props => props.theme.pxToRem(-18)};
    box-shadow: none;
  }
`;

const StyledTab = styled(Tab)`
  .ui.segment.active.tab {
    margin: 0;
  }

  .ui.menu .item > img:not(.ui) {
    width: ${props => props.theme.pxToRem(20)};
  }

  .ui.text.menu {
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: ${props => props.theme.pxToRem(24)};

    .item {
      margin-right: ${props => props.theme.pxToRem(29)};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.s};
      font-weight: ${props => props.theme.fontWeights.light};
      line-height: 1.5;

      &.active {
        color: ${props => props.theme.colors.neutralGrey8};
      }

      :last-child {
        margin-right: 0;
      }

      ${props => props.theme.mediaQueries.mobileOnly} {
        margin-right: ${props => props.theme.pxToRem(32)};
        font-size: ${props => props.theme.fontSizes.m};
        line-height: 1.33;
      }
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: calc(100% - ${props => props.theme.pxToRem(40)});
      padding-left: 0;
      margin-right: ${props => props.theme.pxToRem(20)};
      margin-left: ${props => props.theme.pxToRem(20)};
    }
  }
`;

const StyledButton = styled(Button)`
  align-self: center;
  width: 100%;

  &&&& {
    margin: 0 ${props => props.theme.pxToRem(24)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => props.theme.pxToRem(265)};
      margin: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(55)} 0
        ${props => props.theme.pxToRem(55)};
    }
    ${props => props.theme.mediaQueries.computerMin} {
      &:focus {
        background-color: ${props => props.theme.colors.primaryLightPurple};
        color: ${props => props.theme.colors.neutralWhite};
      }
    }
  }
`;

const StyledCpeLearningIcon = styled(CpeLearningIcon)`
  height: ${props => props.theme.pxToRem(20)};
  margin-right: ${props => props.theme.pxToRem(6)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-right: ${props => props.theme.pxToRem(9)};
  }
`;

const StyledEventsIcon = styled(EventsIcon)`
  height: ${props => props.theme.pxToRem(20)};
  margin-right: ${props => props.theme.pxToRem(6)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-right: ${props => props.theme.pxToRem(9)};
  }

  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledTabContentGrid = styled(Grid)`
  &&&& {
    margin: 0;
  }
`;

const StyledGridRow = styled(Grid.Row)`
  padding: 0 !important;
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&&&&& {
    display: inline-flex;
    justify-content: space-between;
    padding: 0 !important;

    ${props => props.theme.mediaQueries.mobileOnly} {
      flex-direction: column;
    }
  }
`;

const StyledDropdownSearch = styled(DropdownSearch)`
  &&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: ${props => props.theme.pxToRem(15)} 0;

      .ds-title {
        margin-top: 0;
      }

      svg {
        top: ${props => props.theme.pxToRem(-12)};
      }

      &&& div.text {
        min-height: auto;
      }
    }
  }
`;

const StyledDateFilter = styled(DateFilter)`
  & div.DayPicker.DayPicker_1.DayPicker__horizontal.DayPicker__horizontal_2 {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 3000;
    transform: translate3d(-50%, 100%, 0);

    .DayPickerNavigation {
      padding: 0.75rem 22px 0;
    }
  }
`;

const BottomTextLineWrapper = styled.div`
  justify-content: center;
  margin: ${props => props.theme.pxToRem(32)} 0;
  text-align: center;
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-bottom: ${props => props.theme.pxToRem(0)};
  }
`;

const StyledBottomDescription = styled.div`
  display: inline;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  text-align: center;

  p {
    display: inline;
  }

  a {
    ${DefaultInlineLinkStyles};
  }
`;

const StyledLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
`;
const MainColumn = styled.div`
  .rich-text-external-link {
    ${ExternalLinkStylesDark}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${IC_OPEN_IN_NEW_WHITE});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;
