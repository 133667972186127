import React, { memo } from 'react';
import { Grid } from 'semantic-ui-react';
import { LabeledData } from 'components/atoms';
import { MembershipTypes } from 'mxp-schemas';

interface Props {
  practicalExpReqRecord: MembershipTypes.PracticalExperienceRequirementSkillsCoreActivity;
  recordType: MembershipTypes.PERRecordType;
}

export const PracticalExpReqSkillsAndActivitiesDetails: React.FC<Props> = memo(
  ({ practicalExpReqRecord, recordType }) => {
    const data = practicalExpReqRecord;
    const reccordTypes = MembershipTypes.PERRecordType;

    return (
      <>
        <Grid.Row>
          <Grid.Column width={16}>
            <LabeledData
              label={recordType === reccordTypes.CORE_ACTIVITY ? 'Core Activity' : 'Skills and behaviours'}
              data={data.activityType || ''}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <LabeledData label="Situation" data={data.situation || ''} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <LabeledData label="Task" data={data.task || ''} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <LabeledData label="Action" data={data.action || ''} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <LabeledData label="Result" data={data.result || ''} />
          </Grid.Column>
        </Grid.Row>
      </>
    );
  }
);
