import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'components/atoms';

interface StockProps {
  availableQuantity?: number;
  className?: string;
  isInProductDetails?: boolean;
  productInterestFormUrl?: string;
}

export const WarningLowStock: React.FC<StockProps> = ({ availableQuantity, className, isInProductDetails }) => {
  return (
    <StyledTextContainer className={className} isWarning isInProductDetails={isInProductDetails}>
      Low in stock ({availableQuantity} left)
    </StyledTextContainer>
  );
};

export const WarningOutOfStock: React.FC<StockProps> = ({ className, isInProductDetails, productInterestFormUrl }) => (
  <StyledTextContainer className={className} isInProductDetails={isInProductDetails}>
    Currently out of stock
    {isInProductDetails ? (
      <>
        ,{' '}
        <>
          please remove item <span>to continue</span>
        </>
      </>
    ) : (
      ''
    )}{' '}
    {productInterestFormUrl && (
      <StyledProductInterestText>
        Want to{' '}
        <Link isExternal testId="product-interest-link" to={productInterestFormUrl} target="blank">
          join the waiting list?
        </Link>{' '}
      </StyledProductInterestText>
    )}
  </StyledTextContainer>
);

export const WarningNeedToAdjustQuantity: React.FC<StockProps> = ({
  className,
  availableQuantity,
  isInProductDetails,
}) => (
  <StyledTextContainer className={className} isInProductDetails={isInProductDetails}>
    {availableQuantity} in stock. <span>Please adjust the quantity</span>
  </StyledTextContainer>
);

const StyledTextContainer = styled.div<{ isWarning?: boolean; isInProductDetails?: boolean }>`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => (props.isWarning ? props.theme.colors.secondaryOrange : props.theme.colors.interfaceRed)};
  margin-bottom: ${props => props.theme.pxToRem(15)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    line-height: 1.5;
    margin-bottom: ${props => props.theme.pxToRem(15)};
  }

  ${props =>
    props.isInProductDetails &&
    `
    text-align: right;
    margin: 0;
    white-space: wrap;
    > span {
      white-space: nowrap;
    }
    /* width: ${props.theme.pxToRem(138)}; */
  /* width: ${props.theme.pxToRem(188)}; */
  ${props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props.theme.pxToRem(93)};
    text-align: start;
    > span {
      white-space: nowrap;
    }
  }
   
`}
`;

const StyledProductInterestText = styled.p`
  color: ${props => props.theme.colors.neutralGrey8};
  &:first-child {
    margin-top: ${props => props.theme.pxToRem(10)};
  }
`;

export const notAvailableTxt = 'This product is not available at the moment.';
