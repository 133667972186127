import React from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';
import { Grid } from 'semantic-ui-react';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const MipSuccessPracticeCheckModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      testId="success-modal"
      heading="Success!"
      icon={<StyledCheckmark />}
      cancelNode={
        <StyledButton
          size={ButtonEnums.sizes.small}
          testId="return-to-"
          variant={ButtonEnums.variants.primary}
          onClick={onClose}
        >
          OK
        </StyledButton>
      }
    >
      <Grid stackable centered columns={3}>
        <StyledGridRow>
          Thank you for confirming you will not be practicing under the auspices of CIMA. Your decision does not affect
          your overall CIMA qualification, status or privileges and does not affect your entitlement to use your
          designatory letters
        </StyledGridRow>
      </Grid>
    </StyledModal>
  );
};

const StyledButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(350)};
  }
`;

const StyledModal = styled(Modal)`
  &&&& {
    background: ${props => props.theme.colors.neutralGrey1};
    min-height: ${props => props.theme.pxToRem(264)};
    max-width: ${props => props.theme.pxToRem(590)};
    margin-top: ${props => props.theme.pxToRem(48)};
    > .header {
      h2 {
        min-height: ${props => props.theme.pxToRem(64)};
        max-width: ${props => props.theme.pxToRem(440)};
        padding: ${props => props.theme.pxToRem(10)};
        margin: auto;
        text-align: center;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }
  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding-bottom: ${props => props.theme.pxToRem(2)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-top: ${props => props.theme.pxToRem(10)};
    }
  }
`;

const StyledCheckmark = styled(Checkmark)`
  &&& {
    path {
      fill: ${props => props.theme.colors.interfaceGreen};
    }
  }
`;
