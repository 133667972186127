import React from 'react';
import { Icon as SemanticIcon, StrictIconProps } from 'semantic-ui-react';
import styled from 'styled-components/macro';

interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
}

export const Icon: React.FC<StrictIconProps> = props => <StyledIcon {...props} />;

export const IconButton: React.FC<Props> = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
);

const StyledIcon = styled(SemanticIcon)`
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
`;

const StyledButton = styled.button`
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  background: transparent;
  border: none;
`;
