import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { emptyFunc } from 'utils';
import { ReactComponent as MediaMissing } from 'resources/images/media-missing.svg';

interface TopicItemProps {
  id: string;
  name: string;
  onClick(id: string): void;
  selected: boolean;
  selectedCount: number | null;
  selectedTopicId: string | null;
  logoUrl?: string | null;
  hideSelectedCount?: boolean;
}

export const TopicItem: React.SFC<TopicItemProps> = React.memo(
  ({ id, name, onClick, selected, selectedCount, logoUrl, hideSelectedCount }) => {
    const handleTopicItemClick = React.useCallback(() => {
      onClick(id);
    }, [id, onClick]);

    return (
      <StyledTopicItem onClick={handleTopicItemClick} selected={selected} data-testid={`topic-item-${id}`}>
        <StyledLogoContainer data-testid={`logo-topic-item-${id}`}>
          {logoUrl ? <StyledImg src={logoUrl} alt="topic logo" /> : <MediaMissing />}
        </StyledLogoContainer>
        <StyledTitle data-testid={`name-topic-item-${id}`}>{name}</StyledTitle>
        {!hideSelectedCount && (
          <StyledTopicCount data-testid={`count-topic-item-${id}`}>
            {selected && `${selectedCount} Selected`}
          </StyledTopicCount>
        )}
        <StyledCheckContainer>
          <Icon name="check" data-testid={`selected-topic-item-${id}`} />
        </StyledCheckContainer>
        {/* Hidden input checkbox required for testers  */}
        <StyledCheckbox
          type="checkbox"
          name={`topic-checkbox-${id}`}
          id={`topic-checkbox-${id}`}
          data-testid={`checkbox-topic-item-${id}`}
          checked={selected}
          onChange={emptyFunc}
          value={id}
        />
      </StyledTopicItem>
    );
  }
);
const StyledCheckContainer = styled.div`
  ${props => `
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: ${props.theme.pxToRem(8)};
    right: ${props.theme.pxToRem(8)};
    width: ${props.theme.pxToRem(20)};
    height: ${props.theme.pxToRem(20)};
    background-color: ${props.theme.colors.neutralGrey4};
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s;

    & .check {
      color: ${props.theme.colors.neutralWhite};
      width: ${props.theme.pxToRem(16)};
      height: ${props.theme.pxToRem(16)};
      margin: 0.15rem 0 0 0;
      font-size: ${props.theme.fontSizes.xs};
    }
  `}
`;

const StyledTopicItem = styled.button<any>`
  ${props => `
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${props.theme.pxToRem(20)} ${props.theme.pxToRem(10)};
    width: ${props.theme.pxToRem(170)};
    height: ${props.theme.pxToRem(170)};
    border-radius: ${props.theme.pxToRem(8)};
    margin: ${props.theme.pxToRem(10)};
    overflow: hidden;
    box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
    background-color: ${props.theme.colors.neutralWhite};
    color: ${props.selected ? props.theme.colors.secondaryTeal : props.theme.colors.neutralBlack};
    border: solid 1px ${props.theme.colors.neutralWhite};
    cursor: pointer;
    outline: none;
    user-select:none;
    transition: border-color 0.3s;
    flex-wrap: wrap;
  `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(158)};
    height: ${props => props.theme.pxToRem(158)};
  }

  ${props => props.theme.mediaQueries.computerMin} {
    &:hover {
      border-color: ${props => props.theme.colors.neutralGrey4};
      box-shadow: none;

      ${StyledCheckContainer} {
        opacity: 1;
      }
    }
  }

  ${props =>
    props.selected &&
    `
    ${props.theme.mediaQueries.computerMin} {
      &:hover {
        border-color: ${props.theme.colors.secondaryTeal};
        box-shadow: none;
      }
    }

    ${StyledCheckContainer} {
      opacity: 1;
      background-color: ${props.theme.colors.secondaryTeal};
    }
  `};
`;

const StyledLogoContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.theme.pxToRem(60)};
  height: ${props => props.theme.pxToRem(60)};
`;

const StyledTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  line-height: 1.22;
  text-align: center;
  width: 100%;
`;

const StyledTopicCount = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.secondaryTeal};
  line-height: 1.57;
  text-align: center;

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xxs};
  }
`;

const StyledCheckbox = styled.input`
  visibility: hidden;
  position: absolute;
`;

const StyledImg = styled.img`
  width: ${props => props.theme.pxToRem(50)};
  height: ${props => props.theme.pxToRem(33)};
`;
