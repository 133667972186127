import React, { useCallback } from 'react';
import styled from 'styled-components';

import { isMobileViewPort } from 'utils';
import { Invoices } from 'mxp-schemas';
import { Checkbox, Table } from 'components/atoms';
import { InvoicesTableRow } from 'components/molecules/InvoicesTableRow/InvoicesTableRow';
import { Pagination } from 'components/organisms/CombinedFiltersPaginationView/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  isMultipleInvoicesSelectedSelector,
  isAllInvoiceItemsSelectedSelector,
  invoiceToShowDetailsSelectorFactory,
} from 'modules/admin/selectors';
import { toggleSelectAllInvoiceItems } from 'modules/admin/actions';

import { useParams } from 'react-router';

export const InvoicesTable: React.FC<{
  tableName: string;
  invoices: State.InvoiceTableRow[] | null;
  handleDownloadClick: (id: string) => void;
  handlePayClick: (id: string) => void;
  downloadId: string | null;
  downloadError: boolean;
  isB2C?: boolean;
  handleInvoiceSelect?: (e: any, id: string) => void | undefined;
  usePagination?: boolean;
  itemCount?: number;
  offset?: number;
  limit?: number;
  setPagination?: ({ type, modifier: { offset } }: { type: string; modifier: { offset: number } }) => void;
}> = ({
  tableName,
  invoices,
  handleDownloadClick,
  handlePayClick,
  downloadId,
  downloadError,
  handleInvoiceSelect,
  isB2C,
  usePagination,
  itemCount,
  offset,
  limit,
  setPagination,
}) => {
  const isDueTable: boolean = tableName === Invoices.InvoiceKeys.DUE_NOW;
  const isMobile = isMobileViewPort();
  const dispatch = useDispatch();
  const { invoiceNumber } = useParams<{ invoiceNumber: string }>();
  const isAllInvoiceItemsSelected = useSelector(isAllInvoiceItemsSelectedSelector);
  const isMultipleInvoiceItemsSelected = useSelector(isMultipleInvoicesSelectedSelector);
  const invoice: State.SearchInvoicesResult | null = useSelector(invoiceToShowDetailsSelectorFactory(invoiceNumber));
  const isPaid: boolean = invoice?.status === Invoices.InvoiceStatus.PAID;

  const handleSelectAllInvoiceItems = React.useCallback(() => {
    dispatch(toggleSelectAllInvoiceItems(isMultipleInvoiceItemsSelected, isAllInvoiceItemsSelected));
  }, [dispatch, isMultipleInvoiceItemsSelected, isAllInvoiceItemsSelected]);
  const setPage = useCallback(
    (currentPage: number) => {
      if (setPagination) {
        setPagination({ type: tableName, modifier: { offset: (limit ?? 0) * (currentPage - 1) } });
      }
    },
    [limit, tableName, setPagination]
  );

  return (
    <StyledTable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>
            <CheckboxStyled
              width="18"
              height="18"
              type="checkbox"
              testId={`select-all-invoices-checkbox`}
              onChange={handleSelectAllInvoiceItems}
              indeterminate={isMultipleInvoiceItemsSelected && !isAllInvoiceItemsSelected}
              checked={isAllInvoiceItemsSelected}
              disabled={isPaid}
            />
          </Table.HeaderCell>

          <Table.HeaderCell>Invoice Date</Table.HeaderCell>
          <Table.HeaderCell>Total Price</Table.HeaderCell>
          <Table.HeaderCell>Invoice #</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Due Date</Table.HeaderCell>
          {!isMobile && <Table.HeaderCell>&nbsp;</Table.HeaderCell>}
          {isDueTable && !isMobile && <Table.HeaderCell>&nbsp;</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {invoices?.map((invoiceData: State.InvoiceTableRow) => (
          <InvoicesTableRow
            key={invoiceData.id}
            invoice={invoiceData}
            downloadId={downloadId}
            downloadError={downloadError}
            downloadFileClick={handleDownloadClick}
            handleInvoiceSelect={handleInvoiceSelect!}
            payInvoiceClick={handlePayClick}
            isDueInvoice={isDueTable}
            isB2C={isB2C}
          />
        ))}
      </Table.Body>
      {usePagination && typeof offset === 'number' && itemCount && limit && itemCount / limit > 1 && (
        <PaginationContainer>
          <Pagination
            testId={`pagination`}
            total={itemCount}
            page={offset / limit + 1}
            perPage={limit}
            onPageChange={setPage}
          />
        </PaginationContainer>
      )}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  &&&&& {
    th,
    td {
      padding: 1em 0.6em;
    }
    margin-bottom: 2em;
    ${props => props.theme.mediaQueries.mobileOnly} {
      tr {
        margin-top: 0;
        padding-top: 1.5rem;
      }
      thead {
        display: block;
        font-weight: ${props => props.theme.fontWeights.medium};
        th {
          font-size: ${props => props.theme.fontSizes.s};
        }
        tr {
          background-color: ${props => props.theme.colors.neutralGrey1};
        }
        tr:first-child {
          box-shadow: 0 0 0 0 !important;
        }
      }
      tbody {
        tr {
          td:first-child {
            font-weight: ${props => props.theme.fontWeights.light};
          }
          td:nth-child(2) {
            font-weight: ${props => props.theme.fontWeights.medium};
          }
        }
      }
    }
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    > label {
      font-size: ${props => props.theme.fontSizes.s};
      color: ${props => props.theme.colors.neutralGrey8};
      font-weight: ${props => props.theme.fontWeights.light};
    }

    &.ui.checkbox {
      display: block;
    }

    &.ui.checkbox input:indeterminate ~ label:after {
      opacity: 1;
      color: ${props => props.theme.colors.neutralWhite};
      background-color: ${props => props.theme.colors.primaryPurple};
      border: ${props => props.theme.pxToRem(-1)} solid ${props => props.theme.colors.primaryPurple};
      border-radius: ${props => props.theme.pxToRem(2)};
      width: ${({ width }) => `${width}px`};
      height: ${({ height }) => `${height}px`};
    }
  }
`;
