import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Product, User } from 'mxp-schemas';
import {
  ListParagraph,
  TextParagraph,
  TitleSizes,
  CustomParagraph,
  ListContentLinksParagraph,
} from './ProductParagraphs';
import { arrayIncludes, getPath, availableFormatToLabel, emptyArray } from 'utils';
import { RelatedProductBlock } from 'components/organisms/RelatedProductBlock';
import UpcomingWebcastDatesInfo from './UpcomingWebcastDatesInfo';
import { PricingInfo } from './PricingInfo';
import { ConferencePricing } from './ConferencePricing';
import { BulkPurchasingBlock } from 'components/organisms/BulkPurchasingBlock';
import CreditInfo from './CreditInfo';
import { getNasbaFieldOfStudy } from 'mxp-utils/dist/lib/utils';
import { ButtonEnums, ButtonLink, Link, Grid } from 'components/atoms';
import { ProductBadges } from './ProductBadges';
import { Routes } from 'constants/index';
import { AccessibilityBlock } from './AccessibilityBlock';
import { BundleContents } from './BundleContents';
import { ConferenceAgendaBlock } from 'components/molecules/ProductItem/ConferenceAgendaBlock';
import { ReactComponent as AgendaIcon } from 'resources/images/icon-homepage-memberblock-advocacy.svg';
import { ReactComponent as SpeakerIcon } from 'resources/images/icon-homepage-memberblock-community.svg';
import { VideoPlayer } from 'components/molecules/VideoPlayer/VideoPlayer';
import { ProductFreeTrialBlock } from 'components/organisms/ProductFreeTrialBlock/ProductFreeTrialBlock';
import { AnimationBlock, ANIMATIONTYPES } from 'components/molecules/AnimationBlock/AnimationBlock';
import { TrustSpotWidget } from 'components/organisms/TrustSpotWidget/TrustSpotWidget';
import { isUserFromRestrictedCountrySelector } from 'modules/products/selectors';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_INSTRUCTOR_SPEAKER_FIELDS } from 'modules/featureToggle/constants';

interface Props {
  productItem: Product.ProductItem;
  productTypeLabel: string;
  isMultiDayWebcastFormat: boolean;
  isWebcastSeriesFormat: boolean;
  isPhSubscriptionMagazine: boolean;
  formattedProductChildrenInfo?: Product.FormattedProductChildren[];
  contentProductChildrenInfo: any;
  bulkPurchasingInfo: any;
  bundleProductsBlock: any;
  calculatedAccessDuration: string;
  attendanceOptions?: Product.ConferenceAttendanceOptions[];
  isAuth: boolean | null;
  isWebcast: boolean;
  allPrices?: Product.PriceForRole[];
  contentCreditInfo: any;
  contentAccessRow: any;
  variantsCreditsInfo: Product.VariantCreditInfo[];
  variantsAvailabilityInfo: Product.VariantCreditInfo[];
  yellowBookHoursRange: string;
  additionalDetails: Product.Variant;
  availableFormats: string[];
  isPublication: boolean;
  isCourse: boolean;
  standingOrderEligible?: boolean;
  standingOrderDiscount?: Product.StandingOrderDiscount;
  isStandingOrderVariantInStock?: boolean;
  isbn: string[] | null;
  isExistingCartItem?: boolean;
  premiumContentItems: Array<Partial<State.ContentCardItem>>;
  belongsToBundle: boolean;
  bundleItemVariantPrices: State.VariantsPriceInfoForUserType[];
  bundleItemsOutOfStockInfo: State.BundleItemVariantOutOfStock[][];

  setBundleElementRefs: (bundleElementRefs: Common.BundleElement[]) => void;
  setSelectedBundleProductVariant: (variant: Common.BundleProductVariant) => void;
  videos?: Product.VideoOptions[];
  cerosContent?: Product.AnimationBlock;
  turtlContent?: Product.AnimationBlock;
  accessProvisionedItem: (product: Product.ProductItem) => void;
  maxProductFreeTrialDays: number;
  freeTrialBlock: any;
  handleBuyLongestFreeTrialClick: () => void;
  isConference: boolean;
  isPremiumContent?: boolean;
  premiumContentTo: string;
  userEmail?: string;
  fvsMembership?: User.MembershipIdsEnum[] | [];
  oktaId?: string;
  oplRedirectUrl: string;
  oplHidUrl: string;
  ratingsAndReviewWidget: any;
  sku: string | undefined;
  fullUrl: string;
  isProductFetched: boolean;
  isUserSuspendedByEthics?: boolean;
}

export const ProductDetailedInformation: React.FC<Props> = React.memo(
  ({
    productItem,
    productTypeLabel,
    isMultiDayWebcastFormat,
    isWebcastSeriesFormat,
    isPhSubscriptionMagazine,
    formattedProductChildrenInfo,
    contentProductChildrenInfo,
    bulkPurchasingInfo,
    bundleProductsBlock,
    calculatedAccessDuration,
    attendanceOptions,
    isAuth,
    isWebcast,
    allPrices,
    contentCreditInfo,
    contentAccessRow,
    variantsCreditsInfo,
    variantsAvailabilityInfo,
    yellowBookHoursRange,
    additionalDetails,
    availableFormats,
    isPublication,
    isCourse,
    standingOrderEligible,
    standingOrderDiscount,
    isStandingOrderVariantInStock,
    isbn,
    isExistingCartItem,
    premiumContentItems,
    belongsToBundle,
    bundleItemVariantPrices,
    bundleItemsOutOfStockInfo,

    setBundleElementRefs,
    setSelectedBundleProductVariant,
    videos,
    accessProvisionedItem,
    maxProductFreeTrialDays,
    freeTrialBlock,
    handleBuyLongestFreeTrialClick,
    isConference,
    isPremiumContent,
    premiumContentTo,
    userEmail,
    fvsMembership,
    oktaId,
    oplRedirectUrl,
    oplHidUrl,
    cerosContent,
    turtlContent,
    ratingsAndReviewWidget,
    sku,
    fullUrl,
    isProductFetched,
    isUserSuspendedByEthics,
  }) => {
    const isBundle = productItem?.productType === Product.ProductType.BUNDLE;
    const isSubscription: boolean = productItem?.productType === Product.ProductType.SUBSCRIPTION;
    const isOPL: boolean =
      isSubscription &&
      productItem?.subscriptionProductType?.key === Product.SubscriptionProductType.PUBLICATION &&
      !productItem.showQuantity;
    const isFormatOnsiteOnly: boolean =
      availableFormats.length === 1 && availableFormats[0] === availableFormatToLabel(Product.AvailableFormat.ONSITE);
    const isCourseSubscriptionType: boolean =
      productItem?.subscriptionProductType?.key === Product.SubscriptionProductType.COURSE;

    const isContribution: boolean = productItem.productType === Product.ProductType.CONTRIBUTION;

    const cancellationPolicy = isConference
      ? getPath(Routes.CONFERENCE_CANCELLATION_POLICY)
      : getPath(Routes.REFUND_POLICY);

    const overviewTitle = (type: string) =>
      type === Product.ProductType.CONFERENCE
        ? 'Conference Details'
        : type === Product.ProductType.BUNDLE
        ? 'Bundle Details'
        : type === Product.ProductType.CONTRIBUTION
        ? 'About'
        : 'Product Details';

    const overviewSubtitle = (type: string) => {
      if (shouldHaveSubtitleLink(type)) {
        return 'This webcast is part of the ';
      }
      return '';
    };
    const shouldHaveSubtitleLink = (type: string) =>
      arrayIncludes([Product.ProductType.WEBCAST], type) &&
      productItem?.parentProductSlug?.slug &&
      productItem?.parentProductSlug?.name;
    const overviewSubtitleLink = (type: string) => {
      if (shouldHaveSubtitleLink(type)) {
        return productItem?.parentProductSlug?.slug;
      }
      return '';
    };
    const overviewSubtitleLinkName = (type: string) => {
      if (shouldHaveSubtitleLink(type)) {
        return productItem?.parentProductSlug?.name;
      }
      return '';
    };
    const audienceTitle = (type: string) =>
      arrayIncludes([Product.ProductType.CONFERENCE], type) ? 'Who Should Attend' : 'Who Will Benefit';

    const notEqualToProductTypes: boolean = ![
      Product.ProductType.EXAM,
      Product.ProductType.BUNDLE,
      Product.ProductType.CONTRIBUTION,
    ].includes(productItem.productType as Product.ProductType);

    const isUserFromRestrictedCountry = useSelector(isUserFromRestrictedCountrySelector);
    const useInstructorSpeakerFields = useSelector(state =>
      getFeatureToggleByKeySelector(state as State.Root, USE_INSTRUCTOR_SPEAKER_FIELDS)
    );

    return (
      <>
        {!isContribution && (
          <StyledProductBadges>
            <ProductBadges
              isExam={Boolean(Product.ProductType.EXAM === productItem.productType)}
              hasStandingOrders={Boolean(isStandingOrderVariantInStock && standingOrderEligible) || false}
              standingOrderDiscount={standingOrderDiscount as Product.StandingOrderDiscount}
              hasCertificateProgram={productItem.hasCertificateProgram || false}
              hasLinkedProducts={Boolean(productItem.linkedProducts?.length) || false}
              linkedProducts={productItem.linkedProducts as Product.ProductItem[]}
              hasBadge={productItem.hasBadge || false}
              productChildrenScrollTarget={bulkPurchasingInfo}
              bundleBlockScrollTarget={bundleProductsBlock}
              belongsToBundle={belongsToBundle}
              maxProductFreeTrialDays={maxProductFreeTrialDays}
              freeTrialBlockScrollTarget={freeTrialBlock}
              ratingsAndReviewChildrenScrollTarget={ratingsAndReviewWidget}
            />
          </StyledProductBadges>
        )}
        {productItem.linkedProducts && !isPhSubscriptionMagazine && (
          <StyledBlockWrapper>
            {(productItem.linkedProducts as Product.ProductItem[]).map((linkedProduct: Product.ProductItem) => (
              <RelatedProductBlock
                key={linkedProduct.slug}
                productTypeLabel={productTypeLabel}
                linkedProduct={linkedProduct}
              />
            ))}
          </StyledBlockWrapper>
        )}
        {productItem.discounts && isPhSubscriptionMagazine && (
          <TextParagraph
            testId="discounts-block"
            title={'Discounts'}
            contentText={productItem.discounts}
            titleSize={TitleSizes.LARGE}
            hasMarkdown
          />
        )}
        {Boolean(premiumContentItems?.length) && (
          <ListContentLinksParagraph
            contentItems={premiumContentItems}
            testId={'premium content-list'}
            subtitle="Click on any of the below to see a preview of the content."
            title="This topic subscription includes"
          />
        )}
        {cerosContent?.content && (
          <AnimationBlockWrapper>
            <Grid.Row>
              <AnimationBlock oembed={cerosContent?.content} type={ANIMATIONTYPES.CEROS} />
            </Grid.Row>
          </AnimationBlockWrapper>
        )}
        {productItem.overview && (
          <TextParagraph
            testId="overview-block"
            title={overviewTitle(productItem.productType)}
            subtitle={overviewSubtitle(productItem.productType)}
            subtitleLink={overviewSubtitleLink(productItem.productType)}
            subtitleLinkName={overviewSubtitleLinkName(productItem.productType)}
            contentText={productItem.overview}
            titleSize={TitleSizes.LARGE}
            hasMarkdown
            isContribution={isContribution}
            isConference={isConference}
          />
        )}
        {turtlContent?.content && (
          <>
            <AnimationBlockWrapper>
              <Grid.Row>
                <AnimationBlock
                  oembed={turtlContent?.content}
                  isLandingPage={Boolean(Object.keys(turtlContent || {}).length === 0)}
                  header={turtlContent?.header}
                  descriptionText={turtlContent?.descriptionText}
                  type={ANIMATIONTYPES.TURTL}
                />
              </Grid.Row>
            </AnimationBlockWrapper>
          </>
        )}
        {videos && videos?.length > 0 && <VideoPlayer video={videos[0]} testId="video-player" isProductPage={true} />}
        {/* Video block will be added after this block */}
        {productItem.audiences && productItem.audiences.length && !isPhSubscriptionMagazine ? (
          <ListParagraph
            testId="audiences-block"
            title={audienceTitle(productItem.productType)}
            listItems={productItem.audiences}
            titleSize={TitleSizes.MEDIUM}
          />
        ) : null}
        {productItem?.keyTopics?.length && !isPhSubscriptionMagazine ? (
          <ListParagraph testId="key-topics-block" title="Key Topics" listItems={productItem.keyTopics} />
        ) : null}
        {productItem?.outcomes?.length && !isPhSubscriptionMagazine ? (
          <ListParagraph testId="learning-outcomes-block" title="Learning Outcomes" listItems={productItem.outcomes} />
        ) : null}
        {!!maxProductFreeTrialDays && (
          <ProductFreeTrialBlock
            isUserFromRestrictedCountry={isUserFromRestrictedCountry}
            handleButtonClick={handleBuyLongestFreeTrialClick}
            maxProductFreeTrialDays={maxProductFreeTrialDays}
            contentRef={freeTrialBlock}
            isExistingCartItem={isExistingCartItem}
            productItem={productItem}
            isPremiumContent={isPremiumContent}
            premiumContentTo={premiumContentTo}
            isOPL={isOPL}
            userEmail={userEmail}
            fvsMembership={fvsMembership}
            oktaId={oktaId}
            oplRedirectUrl={oplRedirectUrl}
            oplHidUrl={oplHidUrl}
          />
        )}
        {(isMultiDayWebcastFormat || isWebcastSeriesFormat) && formattedProductChildrenInfo && (
          <UpcomingWebcastDatesInfo
            formattedProductChildrenInfo={formattedProductChildrenInfo}
            title={isWebcastSeriesFormat ? `Upcoming Webcast Series Dates` : `Upcoming Webcast Dates`}
            contentRef={contentProductChildrenInfo}
            isMultiDayWebcast={isMultiDayWebcastFormat}
            isWebcastSeries={isWebcastSeriesFormat}
            calculatedAccessDuration={calculatedAccessDuration}
          />
        )}
        {productItem.subscriptionInformation && isPhSubscriptionMagazine && (
          <TextParagraph
            testId="subscription-info-block"
            title={'Subscription information'}
            contentText={productItem.subscriptionInformation}
            titleSize={TitleSizes.LARGE}
            hasMarkdown
          />
        )}
        {(productItem.agenda || productItem.speakersLink) && (
          <>
            <TextParagraph
              testId="agenda-block"
              title={'Conference Agenda'}
              contentText={'Explore each day’s sessions and activities, plus our lineup of expert speakers.'}
              titleSize={TitleSizes.LARGE}
              hasMarkdown
            />
            <Grid columns="2" style={{ marginTop: '12px' }}>
              {productItem.agenda && (
                <Grid.Column>
                  <ConferenceAgendaBlock
                    testId="agenda-link"
                    to={productItem.agenda}
                    ctaText={'Agenda'}
                    icon={<AgendaIcon />}
                  />
                </Grid.Column>
              )}
              {productItem.speakersLink && (
                <Grid.Column>
                  <ConferenceAgendaBlock
                    testId="speaker-link"
                    to={productItem.speakersLink}
                    ctaText={'Speakers'}
                    icon={<SpeakerIcon />}
                  />
                </Grid.Column>
              )}
            </Grid>
          </>
        )}
        {additionalDetails?.locations && arrayIncludes([Product.ProductType.CONFERENCE], productItem.productType) && (
          <>
            <TextParagraph
              testId="location-block"
              title={'Location'}
              contentText={additionalDetails.locations || ''}
              titleSize={TitleSizes.LARGE}
            />
            <TextParagraph testId="address-block" contentText={additionalDetails.address || ''} hasMarkdown />
          </>
        )}
        {notEqualToProductTypes && !isPhSubscriptionMagazine && (
          <CreditInfo
            contentRef={contentCreditInfo}
            accessRef={contentAccessRow}
            creditsInfo={variantsCreditsInfo}
            nasbaField={getNasbaFieldOfStudy(productItem.nasbaFieldOfStudy || emptyArray)}
            level={(productItem.programLevel && productItem.programLevel.label) || ''}
            prerequisites={productItem.prerequisites || ''}
            access={variantsAvailabilityInfo}
            duration={
              (!arrayIncludes([Product.ProductType.CONFERENCE], productItem.productType) && productItem.duration) || ''
            }
            yellowBookHours={yellowBookHoursRange}
            hasBadge={productItem.hasBadge}
            isWebcast={isWebcast}
          />
        )}
        {productItem.justification && !isPhSubscriptionMagazine && (
          <TextParagraph
            testId="justification-block"
            title={'Why attend'}
            contentText={productItem.justification}
            titleSize={TitleSizes.MEDIUM}
            hasMarkdown
          />
        )}

        {attendanceOptions && arrayIncludes([Product.ProductType.CONFERENCE], productItem.productType) && (
          <ConferencePricing attendanceOptions={attendanceOptions} isAuth={isAuth} />
        )}
        {!attendanceOptions?.length && notEqualToProductTypes && (
          <PricingInfo
            allPrices={allPrices}
            isAuth={isAuth}
            isWebcast={isWebcast}
            standingOrderEligible={standingOrderEligible}
            isStandingOrderVariantInStock={isStandingOrderVariantInStock}
            standingOrderDiscount={standingOrderDiscount}
          />
        )}
        {isBundle && Array.isArray(productItem.bundleProducts) && (
          <BundleContents
            productItems={productItem.bundleProducts}
            bundleDiscountPercent={productItem.bundleDiscountPercent || 0}
            setSelectedBundleProductVariant={setSelectedBundleProductVariant}
            setBundleElementRefs={setBundleElementRefs}
            bundleItemVariantPrices={bundleItemVariantPrices}
            bundleItemsOutOfStockInfo={bundleItemsOutOfStockInfo}
            isExistingCartItem={isExistingCartItem}
            isAuth={isAuth}
            accessProvisionedItem={accessProvisionedItem}
            isUserSuspendedByEthics={isUserSuspendedByEthics}
          />
        )}
        {!isContribution && !isBundle && (
          <BulkPurchasingBlock
            link={productItem?.bulkPurchasingLink}
            productType={productItem.productType}
            productSlug={productItem?.slug}
            contentRef={bulkPurchasingInfo}
            productTypeLabel={productTypeLabel}
          />
        )}
        {isbn?.[0] && (
          <CustomParagraph
            testId="isbn-block"
            title="ISBN"
            child={
              <StyledContainer>
                {isbn.map((variantIsbn: any, idx: number) => {
                  return (
                    <StyledRow key={`${idx}`} hasBg={!!(idx % 2)}>
                      <StyledCell>{variantIsbn}</StyledCell>
                    </StyledRow>
                  );
                })}
              </StyledContainer>
            }
            titleSize={TitleSizes.LARGE}
          />
        )}
        {(!isSubscription || isOPL) && productItem?.authors?.length && (useInstructorSpeakerFields ? !isWebcast : true)
          ? productItem.authors.map((author, i) => (
              <TextParagraph
                testId="authors-block"
                key={author.name}
                title={
                  i === 0
                    ? getAuthorTitle(
                        productItem.productType as Product.ProductType,
                        productItem.subscriptionProductType?.key,
                        (productItem.authors && productItem.authors.length) || 0,
                        useInstructorSpeakerFields
                      )
                    : ''
                }
                subtitle={author.name}
                summary={author.credentials}
                contentText={author.bio || ''}
                titleSize={TitleSizes.LARGE}
              />
            ))
          : null}
        {useInstructorSpeakerFields && isWebcast && productItem?.instructors?.length
          ? productItem.instructors.map((instructor, i) => (
              <TextParagraph
                testId="instructors-block"
                key={instructor.name}
                title={i === 0 ? 'Instructors' : ''}
                subtitle={instructor.name}
                summary={instructor.credentials}
                contentText={instructor.bio || ''}
                titleSize={TitleSizes.LARGE}
              />
            ))
          : null}
        {useInstructorSpeakerFields && isWebcast && productItem?.speakers?.length
          ? productItem.speakers
              .filter(
                speaker =>
                  !productItem?.instructors?.some(
                    instructor => instructor?.name?.toLowerCase() === speaker?.name?.toLowerCase()
                  )
              )
              .map((speaker, i) => (
                <TextParagraph
                  testId="speakers-block"
                  key={speaker.name}
                  title={i === 0 ? 'Speakers' : ''}
                  subtitle={speaker.name}
                  summary={speaker.credentials}
                  contentText={speaker.bio || ''}
                  titleSize={TitleSizes.LARGE}
                />
              ))
          : null}
        {(!isSubscription || isPhSubscriptionMagazine || isCourseSubscriptionType || isOPL) &&
        productItem.publisher &&
        productItem.publisher.length > 0
          ? productItem.publisher.map((publisher, i) => (
              <TextParagraph
                testId="publisher-block"
                key={publisher.name}
                title={
                  i === 0
                    ? getPublisherTitle(
                        productItem.productType as Product.ProductType,
                        productItem.subscriptionProductType?.key
                      )
                    : ''
                }
                subtitle={publisher.name}
                summary={publisher.credentials || ''}
                contentText={publisher.bio || ''}
                titleSize={TitleSizes.LARGE}
              />
            ))
          : null}
        {Boolean(productItem.closedCaptioning?.label) && !isPhSubscriptionMagazine && !isBundle && (
          <AccessibilityBlock label={productItem.closedCaptioning?.label as Product.CloseCaptionedEnum} />
        )}
        {!isContribution && (
          <TrustSpotWidget
            contentRef={ratingsAndReviewWidget}
            sku={sku}
            productItem={productItem}
            url={fullUrl}
            isProductFetched={isProductFetched}
          />
        )}
        {additionalDetails?.details && arrayIncludes([Product.ProductType.CONFERENCE], productItem.productType) && (
          <>
            <TextParagraph
              testId="accommodation-block"
              title={'Accommodations'}
              contentText={additionalDetails.details || ''}
              titleSize={TitleSizes.LARGE}
              hasMarkdown
            />
          </>
        )}
        {productItem.sysReq && (
          <TextParagraph
            testId="system-req-block"
            title="System Requirements"
            contentText={productItem.sysReq}
            titleSize={TitleSizes.LARGE}
          />
        )}

        {/* There are talks to remove sysReq from CT entirely - started with conference & publication */}

        {arrayIncludes([Product.ProductType.CONFERENCE], productItem.productType) && !isFormatOnsiteOnly && (
          <CustomParagraph
            testId="system-req-block"
            title="System Requirements"
            child={
              <>
                View our system requirements{' '}
                <StyledButtonLink
                  variant={ButtonEnums.variants.link}
                  testId={'system-req-block'}
                  to={getPath(Routes.CONFERENCE_SYSTEM_REQUIREMENTS)}
                >
                  {'here'}
                </StyledButtonLink>
              </>
            }
            titleSize={TitleSizes.MEDIUM}
          />
        )}

        {(isPublication || isCourse) && (
          <CustomParagraph
            testId="shipping-block"
            title="Shipping and delivery"
            child={
              <>
                <Link testId="shipping-block" to={getPath(Routes.SHIPPING_DELIVERY)} target="blank">
                  Shipping costs
                </Link>
                &nbsp; and sales taxes will be added later during checkout
              </>
            }
            titleSize={TitleSizes.LARGE}
          />
        )}

        {!isContribution && (
          <CustomParagraph
            testId="cancellation-block"
            title="Cancellation Policy"
            child={
              <>
                View our Cancellation policy{' '}
                <Link testId={'cancellation-block-link'} to={cancellationPolicy}>
                  {'here'}
                </Link>
              </>
            }
            titleSize={TitleSizes.LARGE}
          />
        )}
      </>
    );
  }
);

const getAuthorTitle = (
  productType: string | undefined,
  subscriptionProductType: string | undefined,
  listLength: number,
  useInstructorAndAuthorField?: boolean
) => {
  const plural = listLength > 1 ? 's' : '';
  if (
    arrayIncludes(
      [Product.ProductType.CONFERENCE, ...(!useInstructorAndAuthorField ? [Product.ProductType.WEBCAST] : [])],
      productType
    )
  ) {
    return `Speakers`;
  }
  if (productType === Product.ProductType.COURSE) {
    return `Author(s)`;
  }
  if (
    productType === Product.ProductType.PUBLICATION ||
    (productType === Product.ProductType.SUBSCRIPTION &&
      subscriptionProductType === Product.SubscriptionProductType.PUBLICATION)
  ) {
    return `About the author(s)`;
  }
  return `Author${plural}`;
};
const getPublisherTitle = (productType: string | undefined, subscriptionProductType: string | undefined) => {
  if (
    productType === Product.ProductType.SUBSCRIPTION &&
    subscriptionProductType === Product.SubscriptionProductType.PUBLICATION
  ) {
    return `About the publisher`;
  }

  return `Publisher`;
};

const StyledBlockWrapper = styled.div`
  padding-top: ${props => props.theme.pxToRem(32)};
  border-bottom: solid 1px ${props => props.theme.colors.neutralGrey2};
`;

const StyledProductBadges = styled.div`
  margin-top: ${props => props.theme.pxToRem(42)};
  margin-bottom: ${props => props.theme.pxToRem(42)};
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    font-weight: ${props => props.theme.fontWeights.light};
    color: ${props => props.theme.colors.primaryPurple};
    font-size: ${props => props.theme.fontSizes.m};
    &&&&:hover {
      text-decoration: underline;
    }
  }
`;

const StyledContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(15)};
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
`;

const StyledRow = styled.div<{ hasBg: boolean }>`
  display: flex;
  background-color: ${props => (props.hasBg ? 'rgba(247, 247, 247, 0.3)' : props.theme.colors.neutralWhite)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-width: ${props => props.theme.pxToRem(450)};
  }
`;

const StyledCell = styled.div`
  display: flex;
  flex: 1;
  height: ${props => props.theme.pxToRem(64)};
  padding: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.5;
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-weight: ${props => props.theme.fontWeights.regular};
    min-width: ${props => props.theme.pxToRem(200)};
  }
`;

const AnimationBlockWrapper = styled.div`
  padding-top: ${props => props.theme.pxToRem(16.5)};
`;
