import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { default as request } from 'utils/GraphQLClient';

import { GET_UPLOADED_DOCUMENT, DELETE_UPLOADED_DOCUMENT } from 'mxp-graphql-queries';
import { personAccountIdSelector } from 'modules/user/selectors';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchUploadedDocuments: any = createAction(
  'uploadedDocuments/GET_UPLOADED_DOCUMENT',
  async () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const accountId = personAccountIdSelector(state);
    return request(GET_UPLOADED_DOCUMENT, { accountId })
      .then((response: any) => {
        return response?.getUploadedDocs;
      })
      .catch((err: any) => {
        return err;
      });
  }
);

export const deleteUploadedDoc: any = createAction(
  'uploadedDocuments/DELETE_UPLOADED_DOCUMENT',
  (id: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(DELETE_UPLOADED_DOCUMENT, {
      id,
    }).then((response: any) => {
      return response.deleteUploadedDoc;
    });
  }
);
