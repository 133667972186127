import React from 'react';
import styled from 'styled-components';
import { Invoices } from 'mxp-schemas';
import { formatPrice } from 'utils';
import { Table, Checkbox } from 'components/atoms';
import { useDispatch } from 'react-redux';
import { toggleSelectedSubscription } from 'modules/admin/actions';

interface Props {
  invoice: State.SearchInvoicesResult;
  subscriptions: State.SubscriptionsResult;
  cancelInvoiceClick?: (invoiceNumber: string) => void;
  legalEntity?: string;
}

export const SubscriptionsDetailsTableRow: React.FC<Props> = React.memo(
  ({ invoice, subscriptions, cancelInvoiceClick, legalEntity }) => {
    const dispatch = useDispatch();

    const handleSelectInvoice = React.useCallback(() => {
      dispatch(toggleSelectedSubscription(subscriptions.subscriptionName));
    }, [dispatch, subscriptions]);

    const isPaid: boolean = invoice?.status === Invoices.InvoiceStatus.PAID;

    return (
      <Table.Row>
        <StyledTableCell>
          <CheckboxStyled
            width="18"
            height="18"
            type="checkbox"
            testId={`select-all-invoices-checkbox`}
            checked={subscriptions.isSelected}
            onChange={handleSelectInvoice}
            disabled={isPaid || subscriptions.status === 'Cancelled'}
          />
        </StyledTableCell>
        <StyledTableCell>{subscriptions.subscriptionName}</StyledTableCell>
        <StyledTableCell>{formatPrice(subscriptions.chargeAmount || 0, invoice.currency)}</StyledTableCell>
        <StyledTableCell>{subscriptions.status}</StyledTableCell>
      </Table.Row>
    );
  }
);

const CheckboxStyled = styled(Checkbox)`
  &&& {
    > label {
      font-size: ${props => props.theme.fontSizes.s};
      color: ${props => props.theme.colors.neutralGrey8};
      font-weight: ${props => props.theme.fontWeights.light};
    }
  }
`;

const StyledTableCell = styled(Table.Cell)`
  &&&&& {
    vertical-align: inherit;
  }
`;
