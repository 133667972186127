import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TabCenterAdminInfoContainer } from 'components/App/lazy-imports';
import { getPath } from 'utils';
import { compareTabWithPath } from 'utils/routes';
import { Routes } from 'constants/index';

export const PageCenterAdminInfo: React.FC<RouteComponentProps> = ({ location }) => {
  const handleCompareTabWithPath = React.useCallback(
    (comparison: string) => compareTabWithPath(location.pathname, comparison),
    [location.pathname]
  );

  return <>{handleCompareTabWithPath(getPath(Routes.CENTER_ADMIN_TAB_INFO)) && <TabCenterAdminInfoContainer />}</>;
};
