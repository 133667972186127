import { educationQualificationSelector } from 'modules/exemptionEducational/selectors';
import { professionalQualificationSelector } from 'modules/exemptionProfessional/selectors';
import { workExperienceRequiredSelector } from 'modules/exemptionWorkExperience/selectors';
import { createSelector } from 'reselect';
import { ExemptionLevels, Salesforce } from 'mxp-schemas';
import { professionalDataNames } from './constants';
import { validateYearQualify } from 'modules/exemptionReference/helpers';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.personLevelExemption,
  (personLevelExemption: State.PersonLevelExemptionStatus): State.PersonLevelExemptionStatus => personLevelExemption
);

export const personLevelExemptionSelector = rootSelector;

export const professionalQualificationDataSelector = createSelector(
  personLevelExemptionSelector,
  (personLevelExemption: State.PersonLevelExemptionStatus): State.ProfessionalQualification[] | undefined =>
    personLevelExemption?.professionalQualification
);

export const educationalQualificationDataSelector = createSelector(
  personLevelExemptionSelector,
  (personLevelExemption: State.PersonLevelExemptionStatus): State.EducationalQualification[] | undefined =>
    personLevelExemption?.educationalQualification
);

export const workExperienceDataSelector = createSelector(
  personLevelExemptionSelector,
  (personLevelExemption: State.PersonLevelExemptionStatus): State.WorkExperience[] | undefined =>
    personLevelExemption?.workExperience
);

export const pleEducationQualificationSelector = createSelector(
  (state: State.Root) => state.personLevelExemption,
  (personLevelExemption: State.PersonLevelExemptionStatus): any[] => {
    const data = personLevelExemption?.personLevelExemptionRecords?.educRecord;
    return data || [];
  }
);
export const pleProfessionalBodiesSelector = createSelector(
  (state: State.Root) => state.personLevelExemption,
  (personLevelExemption: State.PersonLevelExemptionStatus): Salesforce.ProfessionalRecords[] => {
    const data = personLevelExemption?.personLevelExemptionRecords?.profRecord;
    return data || [];
  }
);

export const numberOfExperienceDataSelector = createSelector(
  workExperienceDataSelector,
  (numberOfExperienceData: State.WorkExperience[] | undefined): any => {
    if (numberOfExperienceData?.length) {
      const numberofExperience =
        parseInt(numberOfExperienceData?.[0].experienceofWorking, 10) +
        parseInt(numberOfExperienceData?.[0].yearsofAccountingExperience, 10);
      return numberofExperience.toString();
    }
  }
);

export const maxNumberOfExperienceDataSelector = createSelector(
  personLevelExemptionSelector,
  (personLevelExemption: State.PersonLevelExemptionStatus): any => {
    const numberofExperience = Math.max(
      parseInt(
        String(
          personLevelExemption?.workExperience?.[0]?.experienceofWorking || personLevelExemption?.experienceofWorking
        ),
        10
      ),
      parseInt(
        String(
          personLevelExemption?.workExperience?.[0]?.yearsofAccountingExperience ||
            personLevelExemption?.yearsofAccountingExperience
        ),
        10
      )
    );
    return numberofExperience.toString();
  }
);

export const personLevelExemptionFetchedSelector = createSelector(
  personLevelExemptionSelector,
  (personLevelExemption: State.PersonLevelExemptionStatus): boolean | undefined =>
    personLevelExemption?.personLevelExemptionFetched
);

export const userHasBeenLicensedBeforeSelector = createSelector(
  personLevelExemptionSelector,
  (personLevelExemption: State.PersonLevelExemptionStatus): boolean =>
    personLevelExemption?.professionalQualification?.[0]?.selectedPassedUSCPAExam ===
    ExemptionLevels.USCpaExamEnum.YES_LICENSED_BEFORE
);

export const professionalBodyExemptionLevelSelector = createSelector(
  professionalQualificationSelector,
  professionalQualificationDataSelector,
  (professionalBodyList: any, professionalQualifications: State.ProfessionalQualification[] | undefined): number => {
    if (!!professionalBodyList?.length && !!professionalQualifications && !!professionalQualifications?.length) {
      const professionalData =
        professionalQualifications?.map((data: State.ProfessionalQualification) => {
          return professionalBodyList?.find((record: any) => {
            const isYearQualified: boolean = validateYearQualify(
              data?.yearQualify,
              `${new Date(record?.SFS_EXR_EligibleFromYear__c).getFullYear()}`,
              `${new Date(record?.SFS_EXR_EligibleUntilYear__c).getFullYear()}`
            );
            return record?.Id === data?.exemptionKey && isYearQualified;
          });
        }) || [];
      const highestLevel = professionalData?.map((a: any) => a?.SFS_EXR_ExemptionLevelKey__r?.Name?.substr(-1));
      return Math.max(...highestLevel) || 1;
    }
    return 1;
  }
);

export const educationExemptionLevelSelector = createSelector(
  educationQualificationSelector,
  educationalQualificationDataSelector,
  (educationReferenceList: any, educationalQualifications: State.EducationalQualification[] | undefined): number => {
    if (!!educationReferenceList?.length && !!educationalQualifications && !!educationalQualifications.length) {
      const educationalData =
        educationalQualifications?.map((data: State.EducationalQualification) => {
          return educationReferenceList?.find((record: any) => {
            const isYearQualified: boolean = validateYearQualify(
              data?.graduationYear,
              `${new Date(record?.SFS_EXR_EligibleFromYear__c).getFullYear()}`,
              `${new Date(record?.SFS_EXR_EligibleUntilYear__c).getFullYear()}`
            );
            return record?.Id === data?.exemptionKey && isYearQualified;
          });
        }) || [];
      const highestLevel = educationalData?.map((a: any) => a?.SFS_EXR_ExemptionLevelKey__r?.Name?.substr(-1));
      return Math.max(...highestLevel) || 1;
    }
    return 1;
  }
);

export const workExemptionLevelSelector = createSelector(
  workExperienceRequiredSelector,
  workExperienceDataSelector,
  (workExpReferenceList: any, workExperience: any): number => {
    if (!!workExpReferenceList.length && !!workExperience && !!workExperience.length) {
      const workExpData =
        workExpReferenceList?.filter((workRecord: any) => {
          return workExperience?.[0]?.exemptionKey === workRecord?.Id;
        }) || [];
      const highestLevel = workExpData?.map((a: any) => a?.SFS_EXR_ExemptionLevelKey__r?.Name?.substr(-1));
      return Math.max(...highestLevel) || 1;
    }
    return 1;
  }
);

export const offlineExemptionStatusSelector = createSelector(
  rootSelector,
  (
    personLevelExemption: State.PersonLevelExemptionStatus
  ): State.PersonLevelExemptionStatus['hasActiveOfflineExemptionCalculation'] =>
    personLevelExemption.hasActiveOfflineExemptionCalculation
);

export const isUserCpaLicensedSelector = createSelector(
  [pleProfessionalBodiesSelector, userHasBeenLicensedBeforeSelector],
  (pleProfessionalBodies: Salesforce.ProfessionalRecords[], userHasBeenLicensedBefore: boolean): boolean =>
    pleProfessionalBodies?.find(
      (record: Salesforce.ProfessionalRecords) =>
        record?.profBodyName === professionalDataNames.US_STATE_BOARDS_OF_ACCOUNTANCY &&
        record?.profQualificationName === professionalDataNames.CERTIFIED_PUBLIC_ACCOUNTANT
    )?.selectedPassedUSCPAExam === ExemptionLevels.USCpaExamEnum.YES_LICENSED_BEFORE || userHasBeenLicensedBefore
);
