import { createAction } from 'redux-actions';
import { default as request } from 'utils/GraphQLClient';
import { CREATE_MIP_AML, QUERY_MIP_AML } from 'mxp-graphql-queries';
import { amlActionNames } from './constants';
import { initialMipAml } from '../mip/constants';
import { Dispatch } from 'redux';

// ------------------------------------
// Actions
// ------------------------------------
export const setIsPdfFlag: any = createAction('AML/SET_IS_PDF_FLAG');

export const isAmlLoading = createAction(amlActionNames.IS_LOADING);

export const isRenewal = createAction(amlActionNames.IS_RENEWAL);

export const createAML: any = createAction(amlActionNames.CREATE_AML, (amlForms: any) => async () => {
  const response = await request(CREATE_MIP_AML, { aml: amlForms });

  return response;
});

export const getAML: any = createAction(amlActionNames.GET_AML, () => async (dispatch: Dispatch) => {
  dispatch(isAmlLoading());
  return request(QUERY_MIP_AML).then((response: any) => {
    if (response?.getMipAml?.id) {
      return { ...response.getMipAml, success: true };
    }
    return { ...initialMipAml, success: false };
  });
});
