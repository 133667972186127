import { MembershipTypes, User } from 'mxp-schemas';
import { ModalVariants } from './productItemHelpers';
import { getPath, areAllTruthy } from 'utils';
import { User as UserUtils } from 'mxp-utils';
import { Routes } from 'constants/index';

/**
 * sanitizeSearchString
 * @param searchString Search term/string
 * Strip search query of special characters outside the whitelist: !@£$%&()'";<>,.?[]{}+-
 */
export const sanitizeSearchString = (searchString: string): string => {
  return searchString ? searchString.replace(/[^a-zA-Z0-9\s!@£$%&()'";<>,.?[\]{}+-]/g, '') : '';
};

/**
 * encodePercentChar
 * @param stringWithUnencodedPercentChars
 * Encoded a string which contains % symbol(s) that it not part of an encoding character
 * i.e. %24%%24 should be %24%25%24
 */
export const encodePercentChar = (stringWithUnencodedPercentChars: string): string => {
  return stringWithUnencodedPercentChars ? stringWithUnencodedPercentChars.replace(/%(?!\d)/gm, '%25') : '';
};

export const isCreateSalesforceOrganization = (
  supervisorEmployer: MembershipTypes.PracticalExperienceRequirementEmployer | undefined,
  userEmployer?: MembershipTypes.PracticalExperienceRequirementEmployer | undefined
) => {
  const {
    id: userEmployerId,
    name: userEmployerName,
    type: userEmployerType,
    addressLine1: userEmployerAddressLine1,
    city: userEmployerCity,
    state: userEmployerState,
    postalCode: userEmployerPostalCode,
    country: userEmployerCountry,
  } = userEmployer || {};
  const {
    id: supervisorEmployerId,
    name: supervisorEmployerName,
    addressLine1: supervisorEmployerAddressLine1,
    city: supervisorEmployerCity,
    state: supervisorEmployerState,
    postalCode: supervisorEmployerPostalCode,
    country: supervisorEmployerCountry,
  } = supervisorEmployer || {};

  const isCreateUserOrganization =
    !Boolean(userEmployerId) &&
    Boolean(userEmployerName) &&
    Boolean(userEmployerType) &&
    Boolean(userEmployerAddressLine1) &&
    Boolean(userEmployerCity) &&
    Boolean(userEmployerState) &&
    Boolean(userEmployerPostalCode) &&
    Boolean(userEmployerCountry);

  // Supervisor form does not have Business Type Field
  const isCreateSupervisorOrganization =
    !Boolean(supervisorEmployerId) &&
    Boolean(supervisorEmployerName) &&
    Boolean(supervisorEmployerAddressLine1) &&
    Boolean(supervisorEmployerCity) &&
    Boolean(supervisorEmployerState) &&
    Boolean(supervisorEmployerPostalCode) &&
    Boolean(supervisorEmployerCountry);

  return {
    isCreateUserOrganization,
    isCreateSupervisorOrganization,
  };
};

// This function/condition checking if the value of the licenseNumber is equal to default value which is "000"
// if true it will display empty string else it will display the updated value of the licenseNumber.
export const licenseCertificateNumber = (licenseInfo: any) => {
  if (licenseInfo?.licenseNumber === '000') {
    return '';
  }
  return licenseInfo?.licenseNumber;
};

export const getTypeValue = (employeeData: any, searchTempStorage: any) => {
  if (employeeData?.name && !employeeData?.type && searchTempStorage?.businessName) {
    return MembershipTypes.OrganizationType.OTHER;
  }
  return employeeData?.type || '';
};
export const handleInputChange = (onChangeData: any, setFieldValues: any) => (e: any, data: any) => {
  if (onChangeData) {
    onChangeData({ [data?.name]: data?.value || data?.checked });
  }

  setFieldValues((prevFieldValues: any) => ({
    ...prevFieldValues,
    [data?.name]: data?.value || data?.checked,
  }));
};

export const flpValidation = (
  isCimaMember: boolean | undefined,
  learningPathway: any,
  membershipTermType: string,
  studentProgression: any,
  isUserCountryEmbargoed: boolean,
  userHasEthicsViolation?: boolean,
  userInfo?: {
    isCimaFlp?: boolean;
    isUserMembershipLapsed?: boolean;
  }
) => {
  const isCimaFlp = userInfo?.isCimaFlp;
  const isUserMembershipLapsed = userInfo?.isUserMembershipLapsed;
  const isAllExamsPassed = UserUtils.conditionalFunction(
    Boolean(studentProgression?.qualificationLevels?.length),
    studentProgression?.qualificationLevels?.every(
      (level: any) =>
        level.status === User.QualificationLevelStatus.Completed ||
        level.status === User.QualificationLevelStatus.Exempted
    ),
    false
  );

  if (userHasEthicsViolation) {
    return ModalVariants.HAS_ETHICS_VIOLATION;
  }

  if (!(isCimaMember || learningPathway === MembershipTypes.Pathway.FLP)) {
    return getPath(Routes.CIMA_MEMBERSHIP_PROMO_CODE);
  }

  const isAffiliate = membershipTermType === User.MembershipTypes.AFFILIATE;
  const isRetired = membershipTermType === User.MembershipTypes.RETIRED;
  const isRegular = membershipTermType === User.MembershipTypes.REGULAR;

  if (isAffiliate || isRetired || isRegular) {
    return getMembershipIneligibilityVariant(isAffiliate, isRetired, isRegular);
  }

  if (areAllTruthy(learningPathway === MembershipTypes.Pathway.PQ, !isUserMembershipLapsed)) {
    return UserUtils.conditionalFunction(
      isAllExamsPassed,
      ModalVariants.ALL_EXAMS_PASSED,
      getPath(Routes.MY_MEMBERSHIPS)
    );
  }

  if (areAllTruthy(learningPathway === MembershipTypes.Pathway.FLP, !isUserMembershipLapsed)) {
    if (isUserCountryEmbargoed) {
      return ModalVariants.GEOLOCATION_ERROR;
    }

    if (!hasActiveQualificationLevels(studentProgression?.qualificationLevels)) {
      return ModalVariants.ALL_EXAMS_PASSED;
    }

    return UserUtils.conditionalFunction(isCimaFlp, getPath(Routes.MY_MEMBERSHIPS), getPath(Routes.MY_MEMBERSHIPS));
  }

  return getPath(Routes.CIMA_MEMBERSHIP_PROMO_CODE);
};

const getMembershipIneligibilityVariant = (isAffiliate: boolean, isRetired: boolean, isRegular: boolean) => {
  return UserUtils.conditionalFunction(
    isAffiliate,
    ModalVariants.INELIGIBLE_AFFILIATE,
    UserUtils.conditionalFunction(isRetired, ModalVariants.INELIGIBLE_RETIRED, ModalVariants.INELIGIBLE_REGULAR)
  );
};

const hasActiveQualificationLevels = (qualificationLevels: any[]) => {
  return qualificationLevels?.some((quali: any) => quali.status === User.QualificationLevelStatus.Active);
};
