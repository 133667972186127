import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { getLocation, getSearch, push } from 'connected-react-router';
import queryString, { parse } from 'query-string';
import { User as UserUtils } from 'mxp-utils';
import { Content, Product } from 'mxp-schemas';
import { emptyArray, emptyObject, MomentHelpers } from 'utils';
import { default as request } from 'utils/GraphQLClient';
import { convertSubtopicsSlugsToIds, convertTopicsSlugsToIds } from 'modules/topics/helpers';
import { convertIndustriesSlugsToIds } from 'modules/industries/helpers';
import { convertTrendsSlugsToIds } from 'modules/trends/helpers';
import { convertSkillsSlugsToIds, convertSubskillsSlugsToIds } from 'modules/skills/helpers';
import { convertContentLanguagesSlugsToIds } from 'modules/contentLanguages/helpers';
import { convertCareerStagesSlugsToIds } from 'modules/careerStages/helpers';
import { convertJobRolesSlugsToIds } from 'modules/jobRoles/helpers';
import {
  convertLocationsSlugsToIds,
  convertCountriesSlugsToIds,
  convertStatesSlugsToIds,
} from 'modules/locations/helpers';
import {
  QUERY_CONTENT_BY_SEARCH,
  QUERY_SEARCH_HISTORY,
  QUERY_SEARCH_SUGGESTIONS,
  REMOVE_SEARCH_TERM,
  GET_PRODUCT_BY_SKU,
} from 'mxp-graphql-queries';
import { productTypeCalendarSelector } from 'modules/productTypes/selectors';
import { searchPromotionalBannerProductSelector } from 'modules/search/selectors';
import {
  isPageCategorySelector,
  isPageSearchSelector,
  pageCategoryCategorySlugSelector,
  pageCategorySubtopicSlugSelector,
  pageCategoryTopicSlugSelector,
  pageSearchSearchQuerySelector,
  isPremiumContentPageSelector,
  pagePremiumContentTopicSlugSelector,
  pagePremiumContentCategorySlugSelector,
} from 'modules/router';
import {
  ContentTypes,
  DefaultPagination,
  SearchHistoryLimit,
  FilterIds,
  FilterIdToNameMap,
  FilterNames,
} from 'constants/index';
import {
  pageCategoryContentLanguageSlugSelector,
  pageCategoryCareerStageSlugSelector,
  pageCategoryJobRoleSlugSelector,
  pageCategoryLocationSlugSelector,
  pageCategoryCountrySlugSelector,
  pageCategoryStateSlugSelector,
} from 'modules/router/selectors';

export const loading: any = createAction('search/LOADING');
export const resetSearchModule: any = createAction('search/RESET');
export const resetSkipSearchFrontloadRequest: any = createAction('search/RESET_SKIP_FRONTLOAD');

interface SearchContentProps {
  isGridView: boolean;
  onlyProducts: boolean;
  onlyEvents: boolean;
  customFilters: { [key: string]: string };
}

export const fetchCalendarSearchContent: any = createAction(
  'search/FETCH_CALENDAR_CONTENT',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const productTypeCalendar = productTypeCalendarSelector(state);
    const customFilters = {
      [FilterNames.CONTENT_TYPE]: productTypeCalendar.productTypeKeys?.map(
        (productTypeKey: Product.ProductType) => Product.ProductTypesSlugsMap[productTypeKey]
      ),
    };
    return dispatch(fetchSearchContent({ isGridView: true, customFilters }));
  }
);

export const fetchPromotionalBannerProduct: any = createAction(
  'search/FETCH_PROMOTIONAL_BANNER_PRODUCT',
  (productSlug: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const promotionalBannerProduct = searchPromotionalBannerProductSelector(state);

    if (promotionalBannerProduct && promotionalBannerProduct.slug === productSlug) {
      return Promise.resolve(promotionalBannerProduct);
    }

    return request(QUERY_CONTENT_BY_SEARCH, {
      searchQuery: productSlug,
      sortBy: DefaultPagination.List.SORT_BY,
      page: DefaultPagination.List.PAGE,
      perPage: DefaultPagination.List.MIN_PER_PAGE,
      filters: {},
      options: { onlyProducts: false, onlyEvents: false, skipSaveSearchHistory: true },
    }).then(({ getContentBySearch }) => {
      const product = getContentBySearch?.results.find((result: State.ContentCardItem) => result.slug === productSlug);
      if (!product) {
        return null;
      }
      return product;
    });
  }
);

export const fetchSearchContent: any = createAction(
  'search/FETCH_CONTENT',
  (
      {
        isGridView = false,
        onlyProducts = false,
        onlyEvents = false,
        customFilters = emptyObject as any,
      }: SearchContentProps = emptyObject as SearchContentProps
    ) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();

      const search = getSearch(state);
      const {
        sortBy = isGridView ? DefaultPagination.Grid.SORT_BY : DefaultPagination.List.SORT_BY,
        page = isGridView ? DefaultPagination.Grid.PAGE : DefaultPagination.List.PAGE,
        perPage = isGridView ? DefaultPagination.Grid.MIN_PER_PAGE : DefaultPagination.List.MIN_PER_PAGE,
        topic: searchTopic = customFilters[FilterNames.TOPIC_LEVEL0],
        subtopic: searchSubtopic,
        industry: searchIndustry = customFilters[FilterNames.INDUSTRY],
        trend: searchTrend = customFilters[FilterNames.TREND],
        skill: searchSkill = customFilters[FilterNames.SKILL_LEVEL_0],
        subskill: searchSubskill,
        contentLanguage: searchContentLanguage,
        careerStage: searchCareerStage,
        jobRole: searchJobRole,
        location: searchLocation = customFilters[FilterNames.LOCATION_LEVEL_0],
        locationByCountry: searchCountry,
        locationByState: searchState,
        category: searchCategory,
        type = customFilters[FilterNames.CONTENT_TYPE],
        premium,
        credits = customFilters[FilterNames.CPE_CREDIT],
        getNoticed,
        includedInWebcastPass,
        nasbaField,
        nasbaProgram,
        availableFormat,
        eventStartDate = customFilters[FilterNames.EVENT_START_DATE],
        eventEndDate = customFilters[FilterNames.EVENT_END_DATE],
        contentSource,
        groups,
      } = parse(search, {
        arrayFormat: 'comma',
        parseNumbers: true,
      });
      const isPageSearch: boolean = isPageSearchSelector(state);
      const isPageCategory: boolean = isPageCategorySelector(state);
      const isPagePremiumContent: boolean = isPremiumContentPageSelector(state);

      // PageCategory support taxonomies search queries and url params at the same time
      const topic: string[] | string = searchTopic || (isPageCategory && pageCategoryTopicSlugSelector(state));
      const subtopic: string[] | string = (isPageCategory && pageCategorySubtopicSlugSelector(state)) || searchSubtopic;
      const industry: string[] | string = searchIndustry;
      const trend: string[] | string = searchTrend;
      const skill: string[] | string = searchSkill || (isPageCategory && pageCategoryTopicSlugSelector(state));
      const subskill: string[] | string = (isPageCategory && pageCategorySubtopicSlugSelector(state)) || searchSubskill;
      const contentLanguage: string[] | string =
        (isPageCategory && pageCategoryContentLanguageSlugSelector(state)) || searchContentLanguage;
      const careerStage: string[] | string =
        (isPageCategory && pageCategoryCareerStageSlugSelector(state)) || searchCareerStage;
      const jobRole: string[] | string = (isPageCategory && pageCategoryJobRoleSlugSelector(state)) || searchJobRole;
      const location: string[] | string = (isPageCategory && pageCategoryLocationSlugSelector(state)) || searchLocation;
      const country: string[] | string = (isPageCategory && pageCategoryCountrySlugSelector(state)) || searchCountry;
      const stateTaxonomy: string[] | string = (isPageCategory && pageCategoryStateSlugSelector(state)) || searchState;

      const category = isPageCategory
        ? pageCategoryCategorySlugSelector(state)
        : isPagePremiumContent
        ? pagePremiumContentCategorySlugSelector(state)
        : searchCategory;

      const premiumFromSlug: string | undefined = isPagePremiumContent
        ? pagePremiumContentTopicSlugSelector(state)
        : undefined;

      const topicsIds: string[] | undefined = convertTopicsSlugsToIds(topic, state);
      const subtopicsIds: string[] | undefined = convertSubtopicsSlugsToIds(subtopic, state);
      const industryIds: string[] | undefined = convertIndustriesSlugsToIds(industry, state);
      const trendIds: string[] | undefined = convertTrendsSlugsToIds(trend, state);
      const skillIds: string[] | undefined = convertSkillsSlugsToIds(skill, state);
      const subskillIds: string[] | undefined = convertSubskillsSlugsToIds(subskill, state);
      const locationIds: string[] | undefined = convertLocationsSlugsToIds(location, state);
      const contentLanguageIds: string[] | undefined = convertContentLanguagesSlugsToIds(contentLanguage, state);
      const careerStageIds: string[] | undefined = convertCareerStagesSlugsToIds(careerStage, state);
      const jobRoleIds: string[] | undefined = convertJobRolesSlugsToIds(jobRole, state);
      const countryIds: string[] | undefined = convertCountriesSlugsToIds(country, state);
      const stateIds: string[] | undefined = convertStatesSlugsToIds(stateTaxonomy, state);
      const searchQuery: string = isPageSearch ? pageSearchSearchQuerySelector(state)?.replace(/\+/g, ' ') : '';

      const premiumFilterSlugToIds = (list: string | string[]): string[] | null => {
        if (!list) return null;
        const convertedList = Array.isArray(list) ? list : [list];
        return convertedList.reduce((acc: string[], item: string) => {
          const newFilterItem = UserUtils.premiumContentSearchItems[item];
          if (newFilterItem) {
            newFilterItem.ids.forEach(id => acc.push(id));
          }
          return acc;
        }, []);
      };

      const premiumFilter = premiumFilterSlugToIds(premium || premiumFromSlug);
      const cpeCredits = Array.isArray(credits)
        ? credits.map((credit: string) => parseInt(credit, 10))
        : credits || credits === 0
        ? [parseInt(credits, 10)]
        : undefined;

      const hasFilters: boolean =
        topicsIds ||
        subtopicsIds ||
        industryIds ||
        trendIds ||
        skillIds ||
        subskillIds ||
        contentLanguageIds ||
        careerStageIds ||
        jobRoleIds ||
        locationIds ||
        countryIds ||
        stateIds ||
        category ||
        type ||
        premium ||
        premiumFromSlug ||
        cpeCredits?.length ||
        getNoticed ||
        includedInWebcastPass ||
        nasbaField ||
        nasbaProgram ||
        availableFormat ||
        eventStartDate ||
        eventEndDate ||
        contentSource ||
        groups;

      const filters: any = hasFilters && {
        topic: topicsIds,
        subtopic: subtopicsIds,
        industry: industryIds,
        trend: trendIds,
        skill: skillIds,
        subskill: subskillIds,
        contentLanguage: contentLanguageIds,
        careerStage: careerStageIds,
        jobRole: jobRoleIds,
        location: locationIds,
        country: countryIds,
        state: stateIds,
        category: category !== Content.CategorySlugs.ALL ? category : undefined,
        type,
        premium: premiumFilter || undefined,
        credits: cpeCredits,
        getNoticed,
        webcastPass: includedInWebcastPass,
        nasbaField,
        nasbaProgram,
        availableFormat,
        eventStartDate: eventStartDate && MomentHelpers.getISOString(eventStartDate),
        eventEndDate: eventEndDate && MomentHelpers.getFullDay(eventEndDate),
        contentSource,
        groups,
      };

      if (filters?.topic?.length === 0) {
        delete filters.topic;
      }
      return request(QUERY_CONTENT_BY_SEARCH, {
        searchQuery,
        sortBy,
        page,
        perPage,
        filters,
        options: { onlyProducts, onlyEvents },
      });
    }
);

export const fetchSearchSuggestions: any = createAction('search/FETCH_SUGGESTIONS', (searchQuery: string) => () => {
  return request(QUERY_SEARCH_SUGGESTIONS, { searchQuery });
});

export const clearSuggestions: any = createAction('search/CLEAR_SUGGESTIONS');

export const changeFilter: any = createAction(
  'search/CHANGE_FILTER',
  (name: string | string[], value: string | string[], options: any = {}) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const { search, pathname }: any = getLocation(state as any);
      const parsedQuery = queryString.parse(search, { arrayFormat: 'comma' });
      const isArray = Array.isArray(name);
      const names: string[] = isArray ? (name as string[]) : [name as string];
      const values: string[] = isArray ? (value as string[]) : [value as string];
      const updatedQuery = names.reduce((acc: any, item: string, indx: number) => {
        const val = values[indx];
        const filterName = item;
        const filterValue: string | string[] =
          parsedQuery[filterName] !== undefined && parsedQuery[filterName] !== null
            ? parsedQuery[filterName]
            : emptyArray;
        const filterValues: string[] = Array.isArray(filterValue)
          ? filterValue.map(v => v.toString())
          : [filterValue.toString()];
        const nextFilterValues = filterValues.includes(val)
          ? filterValues.filter(i => i !== val)
          : options.replaceValues // if true, use replace logic instead of toggle
          ? [val]
          : [...filterValues, val];
        let query = {
          ...(Object.keys(acc).length ? acc : parsedQuery),
          [filterName]: nextFilterValues,
          ...(parsedQuery.page !== undefined && filterName !== 'page' && { page: 1 }),
        };
        if (filterName === 'type' && !nextFilterValues.includes(ContentTypes.COURSE)) {
          query = { ...query, [FilterNames.AVAILABLE_FORMAT]: [] };
        }

        if (filterName === 'type' && !nextFilterValues.includes(ContentTypes.WEBCASTS)) {
          query = {
            ...query,
            [FilterNames.EVENT_START_DATE]: [],
            [FilterNames.EVENT_END_DATE]: [],
            [FilterNames.WEBCAST_PASS]: [],
          };
        }

        return query;
      }, {});
      const searchStr: string = `?${queryString.stringify(updatedQuery, { arrayFormat: 'comma' })}`;
      dispatch(push({ pathname: pathname.replace(/%/g, '%25'), search: searchStr.replace(/%/g, '%25') }));
    }
);

export const changeFilterCima: any = createAction(
  'search/CHANGE_FILTER',
  (searchObject: any, options: any = {}) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const { pathname }: any = getLocation(state as any);
      const searchStr: string = `?${queryString.stringify(searchObject, { arrayFormat: 'comma' })}`;
      dispatch(push({ pathname: pathname.replace(/%/g, '%25'), search: searchStr.replace(/%/g, '%25') }));
    }
);

export const clearFilters: any = createAction(
  'search/CLEAR_FILTERS',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { search, pathname }: any = getLocation(state as any);
    const { page, perPage, sortBy } = queryString.parse(search, { arrayFormat: 'comma', parseNumbers: true });

    const searchStr: string = `?${queryString.stringify({ page, perPage, sortBy }, { arrayFormat: 'comma' })}`;
    dispatch(loading());
    dispatch(push({ pathname, search: searchStr }));
  }
);

export const clearFilter: any = createAction(
  'search/CLEAR_FILTER',
  (filterName: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { search, pathname }: any = getLocation(state as any);
    const queryKeyValues = queryString.parse(search, {
      arrayFormat: 'comma',
      parseNumbers: true,
    });
    let newQueryString: string = '';
    // calendar is a special case that needs eventDate to be cleared of start&end date
    if (filterName === FilterIdToNameMap[FilterIds.eventDate]) {
      const {
        [FilterNames.EVENT_START_DATE]: startDateValue,
        [FilterNames.EVENT_END_DATE]: endDateValue,
        ...remainingKeys
      } = queryKeyValues;
      newQueryString = remainingKeys;
    } else {
      const { [filterName]: clearedFilter, ...remainingKeys } = queryKeyValues;
      newQueryString = remainingKeys;
    }
    const updatedQueryString: string = `?${queryString.stringify(newQueryString, {
      arrayFormat: 'comma',
    })}`;
    dispatch(loading());
    dispatch(push({ pathname, search: updatedQueryString }));
  }
);

export const getSearchHistory: any = createAction('search/GET_HISTORY', () => () => {
  return request(QUERY_SEARCH_HISTORY, { numberResults: SearchHistoryLimit });
});

export const removeSearchTerm: any = createAction('search/REMOVE_HISTORY', (deletedItem: string) => () => {
  return request(REMOVE_SEARCH_TERM, { searchTerm: deletedItem, numberResults: SearchHistoryLimit });
});

export const setSearchReferrer: any = createAction('search/SET_SEARCH_REFERRER', (referrer: string) => () => {
  return referrer;
});

export const setSkipUserInfoRefresh: any = createAction('search/SET_SKIP_USER_INFO_REFRESH', (skip: boolean) => () => {
  return skip;
});

export const getESProductBySku: any = createAction('search/GET_PRODUCT_BY_SKU', (sku: string) => () => {
  return request(GET_PRODUCT_BY_SKU, { sku });
});
