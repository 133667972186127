import { Cart } from 'mxp-schemas';
import { emptyArray } from './emptyItems';

export const getAllDiscountsForType = (discounts: Cart.DiscountDetails[], type: Cart.DISCOUNT_TYPE) =>
  discounts?.filter(discount => discount.discountType === type) || emptyArray;

export const getTestIdTypeLabel = (discount: Omit<Cart.DiscountDetails, 'discountAmount'>) => {
  return discount.discountType === Cart.DISCOUNT_TYPE.MEMBERSHIP_DISCOUNT ? 'member' : 'promo-code';
};

export const getDiscountLabel = (discount: Omit<Cart.DiscountDetails, 'discountAmount'>) => {
  const middle = discount.discountType === Cart.DISCOUNT_TYPE.MEMBERSHIP_DISCOUNT ? 'Member' : discount.label;
  const isPromoDiscount = discount.discountType === Cart.DISCOUNT_TYPE.CART_PROMO_DISCOUNT;
  return isPromoDiscount ? `Promo ${middle}` : `Total ${middle} Discount `;
};
