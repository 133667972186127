import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
import { MembershipTypes } from 'mxp-schemas';
import { modifyMembershipAccordion } from 'modules/membership/actions';
import {
  membershipAddonsSelector,
  membershipCredentialSelector,
  membershipLoadingSelector,
  membershipSectionSelector,
} from 'modules/membership/selectors';
import { AddNewMembershipProduct } from 'components/organisms/AddNewMembershipProduct/AddNewMembershipProduct';
import {
  RENEWAL_TIER,
  RENEWAL_MEMBERSHIP_TYPE,
  TWL_MEMBERSHIP_TIER,
  RETIRED_MEMBERSHIP_TIER,
  CPA_EXAM_AFFILIATE_TIER,
  INTERNAIONAL_AFFILIATE_TIER,
} from 'resources/images';
import { MEMBERSHIP_TYPE, SELECTED_TIRE, TRowProps, TMultiRowProps } from 'components/pages/PageRenewYourMembership';
import { RenewMembershipOptions } from 'constants/index';

export interface IRenewMemberShipCardRowProps {
  type: RenewMembershipOptions;
  rowProps: TRowProps | TMultiRowProps[];
  isUsedForTier?: boolean;
  serviceTierFieldsToBeShown: string[];
  handleUpdateClick: () => void;
  isUpdateAvailable: boolean;
  isRenewMemberShipCardActive: boolean;
}

export const camelCaseToWords = (key: string): string => {
  const result = key.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const ROW_HEADERS: { [key: string]: string[] } = {
  [RenewMembershipOptions.MEMBERSHIP_CREDENTIALS]: ['selectedCredentials', 'value', 'status'],
  [RenewMembershipOptions.MEMBERSHIP_SECTIONS]: ['selectedSections', 'value', 'status'],
  [RenewMembershipOptions.ADD_ONS]: ['selectedAdd-ons', 'value', 'status'],
};

export const RenewMemberShipCardRow: FC<IRenewMemberShipCardRowProps> = ({
  type,
  rowProps,
  isUsedForTier,
  serviceTierFieldsToBeShown,
  isUpdateAvailable,
  handleUpdateClick,
  isRenewMemberShipCardActive,
}) => {
  const dispatch = useDispatch();
  const membershipLoading = useSelector(membershipLoadingSelector);
  const { list: credentialsList } = useSelector(membershipCredentialSelector);
  const { listOfProductWithPrice: sectionList } = useSelector(membershipSectionSelector);
  const { list: addonsList } = useSelector(membershipAddonsSelector);

  // No existing credentials/sections/addons
  if (!rowProps || !isUpdateAvailable) {
    const { list = [] } = (() => {
      switch (type) {
        case RenewMembershipOptions.MEMBERSHIP_CREDENTIALS:
          return {
            list: credentialsList,
          };

        case RenewMembershipOptions.ADD_ONS:
          return {
            list: addonsList,
          };

        case RenewMembershipOptions.MEMBERSHIP_SECTIONS:
          return { list: sectionList };

        default:
          return { list: [] };
      }
    })();

    const handleAddNewClick = () => {
      dispatch(modifyMembershipAccordion(type));
    };

    return (
      <AddNewMembershipProduct
        type={type}
        isLoading={membershipLoading}
        list={list}
        onClickHandler={handleAddNewClick}
        updateCollapsibleStatus={handleUpdateClick}
        isShowList={!isUpdateAvailable}
        isRenewMemberShipCardActive={isRenewMemberShipCardActive}
      />
    );
  }

  // Render multi row credentials/sections/addons
  if (Array.isArray(rowProps)) {
    return (
      <Container>
        {ROW_HEADERS[type].map((title: string, index) => {
          const hasLeftBorder = title === 'status';
          return (
            <RowContainer key={title} hasLeftBorder={hasLeftBorder} name={title}>
              <RowCaption color={theme.colors.neutralGrey10} fontWeight={theme.fontWeights.regular}>
                {camelCaseToWords(title)}
              </RowCaption>
              {rowProps.map(item => {
                title = index === 0 ? 'name' : title;
                const value = item[title as keyof TMultiRowProps];
                const showAsIncluded = title === 'value' && item.isIncluded;

                return <RowData key={title} title={title} value={value} showAsIncluded={showAsIncluded} type={type} />;
              })}
            </RowContainer>
          );
        })}
      </Container>
    );
  }

  const rowTitles = rowProps && Object.keys(rowProps);
  const rowValues = rowProps && Object.values(rowProps);

  const renderServiceTierIcons = (selectedTier: string | number | undefined) => {
    switch (selectedTier) {
      case 'TLW':
        return TWL_MEMBERSHIP_TIER;
      case 'Retired':
        return RETIRED_MEMBERSHIP_TIER;
      case 'CPA Exam Candidate Affiliate':
        return CPA_EXAM_AFFILIATE_TIER;
      case 'International Associate':
        return INTERNAIONAL_AFFILIATE_TIER;
      case 'Regular':
      default:
        return RENEWAL_TIER;
    }
  };

  return (
    <Container>
      {rowTitles?.map((title, index) => {
        const doesThisFieldShouldBeShown = serviceTierFieldsToBeShown?.includes(title);
        const tierIcon = renderServiceTierIcons(rowProps?.[title]);
        if (isUsedForTier && !doesThisFieldShouldBeShown) {
          return <></>;
        }
        const value = rowValues?.[index];
        const hasLeftBorder = rowTitles && title === 'status';

        return (
          <RowContainer key={title} hasLeftBorder={hasLeftBorder} name={title}>
            <RowCaption color={theme.colors.neutralGrey10} fontWeight={theme.fontWeights.regular}>
              {rowTitles && camelCaseToWords(title)}
            </RowCaption>
            <RowData title={title} value={value} tierIcon={tierIcon} />
          </RowContainer>
        );
      })}
    </Container>
  );
};

interface RowDataProps {
  title: string;
  tierIcon?: string;
  value?: string | number | boolean;
  showAsIncluded?: boolean;
  type?: RenewMembershipOptions;
}

const RowData = ({ title, tierIcon, value, showAsIncluded, type }: RowDataProps) => {
  return (
    <RowImgContainer>
      {title === MEMBERSHIP_TYPE && (
        <img src={RENEWAL_MEMBERSHIP_TYPE} alt="Renew Membership" data-testid="membershipType-img" loading="lazy" />
      )}
      {title === SELECTED_TIRE && <img src={tierIcon} alt="Renew Tire" data-testid="selectedTier-img" loading="lazy" />}
      {(!showAsIncluded || type !== RenewMembershipOptions.ADD_ONS) && (
        <RowCaption
          color={
            value === MembershipTypes.MembershipState.EXPIRED
              ? `${theme.colors.interfaceRed}`
              : `${theme.colors.neutralGrey8}`
          }
          fontWeight={theme.fontWeights.medium}
        >
          {value}
        </RowCaption>
      )}
      {showAsIncluded && <IncludedTag type={type}>Included</IncludedTag>}
    </RowImgContainer>
  );
};

const getColumnWidth = (name: string) => {
  switch (name) {
    case 'status':
      return '10%';

    case 'employmentStatus':
    case 'role':
    case 'value':
    case 'validUntil':
      return '20%';

    case 'selectedTier':
      return '30%';

    default:
      return '70%';
  }
};

const ContainerMobileStyles = css`
  width: 57%;
  text-align: left;
  margin-bottom: 0;
`;

const Container = styled.div`
  padding: ${props => `${props.theme.pxToRem(24)} ${props.theme.pxToRem(12)}`};

  display: flex;
  overflow-x: scroll;

  > div:not(:first-child) {
    padding-left: ${props => props.theme.pxToRem(8)};
  }

  > div > div {
    ${props => props.theme.mediaQueries.mobileMaxOnly} {
      ${ContainerMobileStyles}
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      ${ContainerMobileStyles}
    }
  }
  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    flex-direction: column;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
  }
`;

const RowContainerMobileStyles = css`
  display: flex;
  padding: ${props => props.theme.pxToRem(8)} 0;
  align-items: flex-start;
  gap: ${props => props.theme.pxToRem(8)};
  align-self: stretch;
  border-bottom: ${props => props.theme.pxToRem(1)} dotted ${props => props.theme.colors.lightGrey};
  line-height: ${props => props.theme.pxToRem(38)};
`;

const RowContainer = styled.div<{ hasLeftBorder?: boolean; name?: string }>`
  ${props => props.theme.mediaQueries.desktopOnly} {
    ${props =>
      props.hasLeftBorder &&
      `
        border-left: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3};
    `}

    ${props => props.name && `width: ${getColumnWidth(props.name)}`};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    ${RowContainerMobileStyles}
  }
`;

const RowCaption = styled.div<{ color: string; fontWeight: number }>`
  font-size: ${props => props.theme.pxToRem(16)};
  line-height: ${props => props.theme.pxToRem(19)};
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};
  margin-bottom: ${props => props.theme.pxToRem(5)};
`;

const RowImgContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img {
    margin-right: ${props => props.theme.pxToRem(8)};
    ${props => props.theme.mediaQueries.mobileMaxOnly} {
      margin-right: ${props => props.theme.pxToRem(4)};
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-right: ${props => props.theme.pxToRem(4)};
    }
  }
`;

const IncludedTag = styled.div<{ type: RenewMembershipOptions | undefined }>`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.xs};
  border-radius: 12px;
  background-color: ${props => props.theme.colors.secondaryTeal};
  padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(8)}`};
  margin-bottom: ${props => props.theme.pxToRem(12)};
  text-align: center !important;
  width: ${props => props.theme.pxToRem(69)} !important;
  height: ${props => props.theme.pxToRem(26)} !important;
  line-height: 1.33;

  ${props =>
    props.type !== RenewMembershipOptions.ADD_ONS &&
    `
      margin-left: ${props.theme.pxToRem(5)};
    `};
`;
