import { createAction } from 'redux-actions';
import { QUERY_STATIC_LANDING_PAGE } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';

export const resetModule: any = createAction('staticLandingPage/RESET_MODULE');
export const resetSkipStaticLandingPageFrontloadRequest: any = createAction('staticLandingPage/RESET_SKIP_FRONTLOAD');

export const fetchStaticLandingPageContent: any = createAction(
  'content/FETCH_STATIC_LANDING_PAGE',
  (slug: string) => () => {
    return request(QUERY_STATIC_LANDING_PAGE, { slug });
  }
);
