import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { Location } from 'history';
import styled from 'styled-components';

import { getPath } from 'utils';
import { handleEvent, EVENT_CLICK } from 'utils/Analytics';
import { Invoices } from 'mxp-schemas';
import { Menu } from 'components/atoms';
import { Routes } from 'constants/index';

interface Props {
  activeTab: Invoices.InvoiceKeys;
  setTab: (tab: Invoices.InvoiceKeys) => void;
  location: Location;
  handleTabChange: (tab: Invoices.InvoiceKeys) => void;
  isB2C?: boolean;
}

export const InvoicesTab: React.FC<Props> = ({ activeTab, location, setTab, isB2C = false, handleTabChange }) => {
  const dispatch = useDispatch();
  const checkUrlHash = React.useCallback(() => {
    if (location?.hash) {
      const tabHash = String(location?.hash).replace('#', '').toUpperCase();
      // redirect if invalid hash value
      if (!(Object.values(Invoices.InvoiceKeys) as string[]).includes(tabHash)) {
        const path = isB2C ? Routes.PROFILE_RECEIPTS : Routes.ORG_INVOICES;
        dispatch(push(generatePath(getPath(path))));
      }
      setTab(tabHash as Invoices.InvoiceKeys);
    }
  }, [location, setTab]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (location?.hash !== getPath(Routes.SEARCH_HASH)) checkUrlHash();
  }, [checkUrlHash, location]);
  const dueNowLabel = 'Due Now';
  const paymentHistoryLabel = 'Payment History';
  const invoiceTabs = React.useMemo(
    () => [
      { label: dueNowLabel, key: Invoices.InvoiceKeys.DUE_NOW },
      { label: paymentHistoryLabel, key: Invoices.InvoiceKeys.PAYMENT_HISTORY },
    ],
    []
  );

  const handleTabChangeHandler = React.useCallback(
    (_: React.MouseEvent<HTMLAnchorElement>, data: any) => {
      const { name } = data;
      handleTabChange(name);
      // analytics
      const analyticsText = isB2C ? 'text:b2c-invoices:int' : 'text:b2b-invoices:int';
      if (name === Invoices.InvoiceKeys.DUE_NOW) {
        return handleEvent({ clickValue: `${analyticsText}:${dueNowLabel}` }, EVENT_CLICK);
      }
      handleEvent({ clickValue: `${analyticsText}:${paymentHistoryLabel}` }, EVENT_CLICK);
    },
    [isB2C, handleTabChange]
  );

  return (
    <StyledMenu data-testid={`${isB2C ? 'b2c' : 'b2b'}-invoice-tabs`} pointing secondary isB2C={isB2C}>
      {invoiceTabs &&
        invoiceTabs.map(item => (
          <Menu.Item
            data-testid={item.key}
            key={item.key}
            name={item.key}
            content={item.label}
            active={activeTab === item.key}
            onClick={handleTabChangeHandler}
          />
        ))}
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)<{ isB2C: boolean }>`
  &&&&&&& {
    margin-top: 0;
    border-bottom: ${props =>
      props.isB2C ? `solid ${props.theme.pxToRem(1)} ${props.theme.colors.neutralGrey3}` : 'none'};
  }
`;
