import React from 'react';
import styled from 'styled-components';

export const MembershipPackagePanelWrapper: React.FC = React.memo(({ children }) => (
  <PanelWrapper>{children}</PanelWrapper>
));

const PanelWrapper = styled.div`
  position: relative;
  padding-left: ${props => props.theme.pxToRem(8)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: 0;
  }
`;
