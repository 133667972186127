import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Link } from 'components/atoms';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

interface Props {
  className?: string;
  isPurchaseSummary?: boolean;
  isContribution?: boolean;
  showDefault?: boolean;
  isNotForProfitSub?: boolean;
}

export const NotificationForAnonymousUser: React.FC<Props> = React.memo(
  ({
    isPurchaseSummary = false,
    className = '',
    isContribution = false,
    showDefault = true,
    isNotForProfitSub = false,
  }) => {
    const location = useLocation();
    const { pathname } = location;

    const to = { pathname, hash: getPath(Routes.LOGIN_HASH), state: { prevRoute: pathname } };
    const linkRegistration = { pathname, hash: getPath(Routes.REGISTRATION_HASH), state: { prevRoute: pathname } };

    return (
      <>
        {isContribution && !isPurchaseSummary && (
          <StyledWrapper className={className}>
            <Link to={to} testId="log-in-text">
              Log in
            </Link>
            &nbsp;to confirm your AICPA member status.
          </StyledWrapper>
        )}
        {isContribution && isPurchaseSummary && !showDefault && (
          <StyledWrapper className={className}>
            <br />
            <Link to={to} testId="log-in-text">
              Log in
            </Link>
            &nbsp;to confirm your AICPA member status. Not a member?&nbsp;
            <Link to={linkRegistration} testId="log-in-text">
              Join the AICPA
            </Link>
            <br />
          </StyledWrapper>
        )}
        {!isContribution && isNotForProfitSub && <></>}
        {((!isContribution && !isNotForProfitSub) || (isContribution && showDefault && !isNotForProfitSub)) && (
          <StyledWrapper className={className}>
            Do you have an AICPA or CIMA membership?&nbsp;
            {isPurchaseSummary && <br />}
            <Link to={to} testId="log-in-text">
              Log in
            </Link>
            &nbsp;to apply your member discount.
          </StyledWrapper>
        )}
      </>
    );
  }
);

const StyledWrapper = styled.div`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xs};
`;
