import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { Button, ButtonEnums } from 'components/atoms';
import { ReactComponent as KeyboardArrowRight } from 'resources/images/icon-dev-ic-keyboard-arrow-right.svg';

interface Props {
  route: string;
  label: string;
  goToInternal: (route: string) => void;
  goToSubMenu?: (() => void) | null;
}

export const ListMenuMobileItem: React.FC<Props> = ({ route, label, goToInternal, goToSubMenu }) => {
  const goTo = useCallback(() => {
    if (goToSubMenu) {
      goToSubMenu();
      return;
    }
    goToInternal(route);
  }, [goToInternal, goToSubMenu, route]);

  return (
    <StyledListItem>
      <StyledButtonMenu
        testId={`user-settings-${label}`}
        variant={ButtonEnums.variants.link}
        size={ButtonEnums.sizes.large}
        icon={<KeyboardArrowRight />}
        iconPosition={ButtonEnums.iconPosition.right}
        onClick={goTo}
      >
        {label}
      </StyledButtonMenu>
    </StyledListItem>
  );
};

const StyledListItem = styled.li`
  border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};

  :last-child {
    border-bottom-color: transparent;
  }
`;

const StyledButtonMenu = styled(Button)`
  &&&& {
    width: 100%;
    justify-content: space-between;
    padding-top: ${props => props.theme.pxToRem(20)};
    padding-right: ${props => props.theme.pxToRem(19)};
    padding-bottom: ${props => props.theme.pxToRem(20)};
    border-radius: 0;
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 1.33;
  }
`;
