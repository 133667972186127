import React, { ReactNodeArray } from 'react';
import { Grid } from 'semantic-ui-react';

interface Props {
  children: ReactNodeArray;
}
// TODO: Pass in optional props to set column widths
export const ThreeColumn: React.SFC<Props> = ({ children }) => {
  return (
    <Grid stackable columns={3}>
      <Grid.Column only="computer" computer={3}>
        {children[0]}
      </Grid.Column>
      <Grid.Column computer={9} mobile={16}>
        {children[1]}
      </Grid.Column>
      <Grid.Column computer={4} mobile={16}>
        {children[2]}
      </Grid.Column>
    </Grid>
  );
};
