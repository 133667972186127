import React from 'react';
import { GlobalModalDimmerStyle, Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';

import { ReactComponent as Exclamation } from 'resources/images/ic-exclamation.svg';

import { SeatManagement } from 'mxp-schemas';
import { EVENT_CLICK, handleEvent } from 'utils/Analytics';

interface Props {
  visible: boolean;
  lineItems: SeatManagement.ContractLineItem[] | null;
  deallocateLineItems: (
    serviceContract: SeatManagement.ServiceContract,
    lineItems: SeatManagement.ContractLineItem[],
    pendingList?: string[]
  ) => void;
  setVisibility: (visible: boolean) => void;
  deallocateLoading: boolean;
  serviceContract: SeatManagement.ServiceContract | null;
  pendingList?: string[];
}

export const ConfirmSeatDeallocateModal: React.FC<Props> = ({
  visible,
  lineItems,
  setVisibility,
  deallocateLineItems,
  deallocateLoading,
  serviceContract,
  pendingList,
}) => {
  const multiRemove = Boolean(lineItems && lineItems?.length > 1);

  const confirmMessage = (
    <>
      {multiRemove ? <b>{lineItems?.length} users </b> : <b>{lineItems?.[0].contactEmail} </b>}
      will lose access to this product. Do you confirm this change?
    </>
  );

  const removeSeatButtonTxt = `Yes, remove seat${multiRemove ? `s` : ``}`;

  const handleDeallocateConfirm = React.useCallback(() => {
    if (serviceContract && lineItems?.length) {
      deallocateLineItems(serviceContract, lineItems, pendingList);

      handleEvent({ clickValue: `button:b2b-seat-management:int:modal-complete-${removeSeatButtonTxt}` }, EVENT_CLICK);
    }
  }, [deallocateLineItems, serviceContract, lineItems, removeSeatButtonTxt, pendingList]);

  const handleCancel = React.useCallback(() => {
    setVisibility(false);
  }, [setVisibility]);

  const cancelNode = (
    <ButtonStyled
      size={ButtonEnums.sizes.medium}
      testId="send-invitations"
      variant={ButtonEnums.variants.primary}
      onClick={handleDeallocateConfirm}
      loading={deallocateLoading}
    >
      {removeSeatButtonTxt}
    </ButtonStyled>
  );
  const confirmNode = (
    <ButtonStyled
      size={ButtonEnums.sizes.medium}
      testId="send-cancel"
      variant={ButtonEnums.variants.secondary}
      onClick={handleCancel}
    >
      No, return to previous page
    </ButtonStyled>
  );

  return (
    <>
      <GlobalModalDimmerStyle />
      <StyledModal
        closeOnDimmerClick={false}
        open={visible}
        centered
        icon={<Exclamation />}
        heading={<>Are you sure?</>}
        confirmNode={confirmNode}
        cancelNode={cancelNode}
      >
        <ModalContent>{confirmMessage}</ModalContent>
      </StyledModal>
    </>
  );
};

const ButtonStyled = styled(Button)`
  &&&&&& {
    min-width: ${props => props.theme.pxToRem(190)};
  }
`;

const StyledModal = styled(Modal)`
  max-width: ${props => props.theme.pxToRem(605)};
  &&&&&&& {
    > .content {
      max-width: ${props => props.theme.pxToRem(430)};
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    > .header {

    }
    
    > .actions {
      padding-top: ${props => props.theme.pxToRem(10)} !important;
      flex-direction: row-reverse;  //mobile desk button order is reversed
      
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
      
      > button { 
        margin:  ${props => props.theme.pxToRem(10)};
        width:  ${props => props.theme.pxToRem(210)}
      }
    }
  }
  }
`;

const ModalContent = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: ${props => props.theme.pxToRem(4)};
`;
