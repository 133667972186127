import React from 'react';
import { ParagraphTitle, TitleSizes } from './ProductParagraphs';
import styled from 'styled-components';
import { Link, LinkEnums } from 'components/atoms/Link/Link';
import { Product } from 'mxp-schemas';

interface Props {
  formattedProductChildrenInfo: Product.FormattedProductChildren[];
  title: string;
  contentRef?: () => void;
  isMultiDayWebcast: boolean;
  isWebcastSeries: boolean;
  calculatedAccessDuration: string;
}

const UpcomingWebcastDatesInfo: React.FC<Props> = React.memo(
  ({
    formattedProductChildrenInfo,
    title,
    contentRef,
    isMultiDayWebcast,
    isWebcastSeries,
    calculatedAccessDuration,
  }: Props) => (
    <Wrap data-testid="upcoming-webcast-dates-info">
      <StyledParagraphTitle titleSize={TitleSizes.LARGE} ref={contentRef}>
        {title}
      </StyledParagraphTitle>
      {isWebcastSeries && (
        <StyledHeading>
          <StyledHeadingText isNotLastParagraph={true}>
            Your purchase includes access to the entire series for {calculatedAccessDuration}. If you can't attend on a
            date below, choose an alternate date within this period to earn CPE credit.
          </StyledHeadingText>
          <StyledHeadingText isNotLastParagraph={false}>
            More dates are added regularly. Visit this page for the latest schedule.
          </StyledHeadingText>
        </StyledHeading>
      )}
      <StyledListContainer>
        {formattedProductChildrenInfo?.map((variant: Product.FormattedProductChildren, key: number) => (
          <React.Fragment key={`fragment${key}`}>
            {isWebcastSeries && (
              <StyledRowHeader key={`${key}`} hasBg={true}>
                Included in the series
              </StyledRowHeader>
            )}
            {variant?.productChildren?.map((childProduct: Product.FormattedChildProduct, index: number) => (
              <StyledRow key={`${key}${index}`} hasBg={!!(key % 2)}>
                {isMultiDayWebcast && (
                  <StyledCellMain isRepeating={Boolean(index !== 0)}>
                    {index === 0 && variant.parentDateRange}
                  </StyledCellMain>
                )}
                <StyledCellSecondary>
                  {((isWebcastSeries || (isMultiDayWebcast && childProduct.soldSeparately !== false)) &&
                    childProduct.slug &&
                    childProduct.isPublishedOnProductLevel && (
                      <StyledLink
                        data-testid={`child-product-link-${childProduct.slug}`}
                        type={LinkEnums.type.standaloneLink}
                        to={childProduct.slug}
                      >
                        {childProduct.name}
                      </StyledLink>
                    )) ||
                    childProduct.name}
                </StyledCellSecondary>
                <StyledCellSecondary>{childProduct.dateRange}</StyledCellSecondary>
              </StyledRow>
            ))}
          </React.Fragment>
        ))}
      </StyledListContainer>
    </Wrap>
  )
);

export default UpcomingWebcastDatesInfo;

const Wrap = styled.div`
  padding-top: ${props => props.theme.pxToRem(18)};
  margin-top: ${props => props.theme.pxToRem(20)};
`;

const StyledListContainer = styled.div`
  margin: ${props => props.theme.pxToRem(16)} 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow-y: auto;
  }
`;

const StyledRow = styled.div<{ hasBg: boolean }>`
  display: flex;
  background-color: ${props => (props.hasBg ? 'rgba(247, 247, 247, 0.3)' : props.theme.colors.neutralWhite)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-width: ${props => props.theme.pxToRem(450)};
  }
`;

const StyledCellMain = styled.div<{ isRepeating?: boolean }>`
  display: flex;
  flex: 1;
  height: ${props => props.theme.pxToRem(64)};
  padding: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.5;
  border-top: ${props => (props.isRepeating ? 'none' : '1px solid rgba(34,36,38,.1)')};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-weight: ${props => props.theme.fontWeights.regular};
    min-width: ${props => props.theme.pxToRem(200)};
  }
`;

const StyledCellSecondary = styled.div<{ isDouble?: boolean }>`
  display: flex;
  flex: ${props => (props.isDouble ? '2 1 12%' : '1')};
  padding: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  border-top: 1px solid rgba(34, 36, 38, 0.1);

  ${props => props.theme.mediaQueries.mobileOnly} {
    min-width: ${props => (props.isDouble ? props.theme.pxToRem(250) : props.theme.pxToRem(125))};
  }
`;

const StyledParagraphTitle = styled(ParagraphTitle)`
  line-height: 1.33;
  padding-bottom: ${props => props.theme.pxToRem(8)};
`;

const StyledLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: normal;
`;

const StyledHeading = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.78;
`;

const StyledHeadingText = styled.div<{ isNotLastParagraph: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.78;
  padding-bottom: ${props => (props.isNotLastParagraph ? props.theme.pxToRem(8) : 0)};
`;

const StyledRowHeader = styled(StyledRow)`
  height: ${props => props.theme.pxToRem(44)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;
  padding: ${props => `${props.theme.pxToRem(11)}`} ${props => `${props.theme.pxToRem(20)}`}
    ${props => `${props.theme.pxToRem(11)}`} ${props => `${props.theme.pxToRem(20)}`};
`;
