import { Button, ButtonEnums, ButtonLink, OnlyDesktopCSS } from 'components/atoms';
import { DeprovisionButton } from 'components/molecules/DeprovisionButton/DeprovisionButton';
import { ProductsListParent } from 'components/molecules/ProductsList/ProductsList';
import { ReturnProductItemModal } from 'components/molecules/ReturnProductItemModal/ReturnProductItemModal';
import { ModalRenewOnProfile } from 'components/organisms/ModalRenewOnProfile/ModalRenewOnProfile';
import { getPremiumContentCategorySlug } from 'components/pages/PageProduct/pageProductHelper';
import { MembershipPackageAccordionStatus, Routes } from 'constants/index';
import {
  productAccessConfigSelector,
  shipStationReturnPortalLinkConfigSelector,
  bridgeUrlConfigSelector,
  flpPlatformCGMALinkConfigSelector,
  cimaStudyLinkConfigSelector,
  mipRenewalSeasonStartConfigSelector,
  mipRenewalSeasonEndConfigSelector,
} from 'modules/app/selectors';
import { USE_BRIDGE_RELEASE, BYPASS_RENEWAL_BUTTON } from 'modules/featureToggle/constants';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { cancelSOrderModalMetaSelector } from 'modules/layouts/selectors';
import {
  setCredentialsItemRenewals,
  setIsRenewalsJourney,
  setSectionsCredentialsRenewal,
  setMembershipEvent,
} from 'modules/membership/actions';
import {
  currentMembershipProduct,
  isMembershipForRenewalSelector,
  isRenewalSeasonSelector,
  activeMipCredentialsSubscriptionSelector,
} from 'modules/membership/selectors';
import { getMipContact, getMipPracticeRequirements, updatePracticeRequirements } from 'modules/mip/action';
import {
  productsListDataSelector,
  hasMipCredentialProductSelector,
  bridgeWebconferencesSelector,
  lineItemsSelector,
  hasMultipleMipCredentialsSelector,
} from 'modules/products/selectors';
import {
  credentialsHashSelector,
  firmMembershipsHashSelector,
  rootSelector,
  sectionsHashSelector,
} from 'modules/startup/selectors';
import {
  customerCredentialsSelector,
  customerMembershipsSelector,
  customerSectionsSelector,
  isAuthSelector,
  isUserCredentialHolderSelector,
  isUserFirmMemberSelector,
  isUserSectionMemberSelector,
  quoteStatusAndIsMembershipPaidByFirmSelector,
} from 'modules/user/selectors';
import moment from 'moment-timezone';
import { MembershipTypes, Orders, Product, User, B2B } from 'mxp-schemas';
import { User as UserUtils } from 'mxp-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ReactComponent as Cancel } from 'resources/images/ic-cancel.svg';
import styled from 'styled-components';
import {
  getMembershipPackagePath,
  getPath,
  getPremiumContentToLink,
  getTrackingUrl,
  MomentHelpers,
  isServer,
  areAllTruthy,
  hasTruthyValue,
} from 'utils';
import { EVENT_CLICK, handleEvent, NAV_CLICK } from 'utils/Analytics';
import {
  mipSelector,
  attestationPracticeRequirementRootSelector,
  isMipRenewalSeasonSelector,
} from 'modules/mip/selectors';
import { getCurrentLocation } from 'utils/routes';
import { getAML } from 'modules/amlforms/index';
import { mipRenewal, mipRenewalPayload } from 'utils/mipRenewal';
import { amlFormSelector } from 'modules/amlforms/selector';
import { createMipContact } from 'modules/mip/actions';
import { createAML } from 'modules/amlforms';
import { openModalExtendAccess } from 'modules/admin/actions';
import { adminClientRoleSelector } from 'modules/admin/selectors';
import { useMipRenewalSeasonDates } from 'hooks/useMipRenewalSeasonDates';

interface Props {
  isMobile: boolean;
  parent: ProductsListParent;
  isActiveProduct: boolean;
  isDiscontinued: boolean;
  isConference: boolean;
  isWebcast: boolean;
  isCertificate: boolean;
  isSection: boolean;
  isCredential: boolean;
  isCenterMembership: boolean;
  isCourse: boolean;
  isPremiumContent: boolean;
  isOPL: boolean;
  isMembership: boolean;
  isFlpType?: boolean;
  isAnyPhysicalProduct: boolean;
  isAdminPortal?: boolean;
  isExamScheduled: boolean;
  isAccessNowDisabled?: boolean;
  hasDomainString: boolean;
  userEmail?: string;
  fvsMembership?: User.MembershipIdsEnum[] | [];
  taxMembership?: User.MembershipIdsEnum[] | [];
  nfpMembership?: User.MembershipIdsEnum[] | [];
  pfpMembership?: User.MembershipIdsEnum[] | [];
  oktaId?: string;
  item: Common.ProductItemData;
  productSlug?: string;
  link: string;
  isProfilePurchasesPage: boolean;
  isStackedInColumn: boolean;
  handleRemoveItem: () => void;
  navigate?: (payload: Common.PushPayload) => void;
  deprovision?: (orderId: string, sku: string) => void;
  accessItem?: (item: Common.ProductItemData) => void;
  setCancelSOrderModalMeta?: (data: State.CancelSOrderModalMeta) => void;
  setCancelStandingOrderTarget?: (orderNumber?: string, sku?: string) => void;
  renderActionContributionButtons: () => React.ReactNode;
  isEventInNext48Hours: boolean;
  isContribution: boolean;
  isEditContributionAmountToggled: boolean;
  handleCancelRefundModal?: () => void;
  isExamCreditType?: boolean;
  getRenewalSeason?: () => void;
  getMipRenewalSeasonStartDate?: () => void;
  getMipRenewalSeasonEndDate?: () => void;
  isExtendAccess: boolean;
}

export const ProductItemButton: React.FC<Props> = ({
  isDiscontinued,
  isActiveProduct,
  isMobile,
  parent,
  isSection,
  isCredential,
  isCenterMembership,
  isConference,
  isWebcast,
  isCourse,
  isCertificate,
  isPremiumContent,
  isOPL,
  isMembership,
  isFlpType,
  isAnyPhysicalProduct,
  hasDomainString,
  userEmail,
  fvsMembership,
  taxMembership,
  nfpMembership,
  pfpMembership,
  oktaId,
  isAdminPortal = false,
  isExamScheduled,
  isAccessNowDisabled,
  item,
  productSlug,
  link,
  isProfilePurchasesPage,
  isStackedInColumn,
  accessItem,
  deprovision,
  navigate,
  handleRemoveItem,
  setCancelSOrderModalMeta,
  setCancelStandingOrderTarget,
  renderActionContributionButtons,
  isEventInNext48Hours,
  isContribution,
  isEditContributionAmountToggled,
  handleCancelRefundModal,
  isExamCreditType,
  getRenewalSeason,
  getMipRenewalSeasonStartDate,
  getMipRenewalSeasonEndDate,
  isExtendAccess,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const prevRoute: string = getCurrentLocation(location);

  const isAdmin: boolean | null = useSelector(isAuthSelector);
  const { oplRedirectUrl, oplHidUrl }: State.ProductAccess = useSelector(productAccessConfigSelector);
  const shipStationReturnPortalLink: string = useSelector(shipStationReturnPortalLinkConfigSelector);
  const cancelSoTarget = useSelector(cancelSOrderModalMetaSelector);

  const isZuoraSubscriptionCancelled = item.subscriptionStatus === Product.ZuoraSubscriptionStatus.CANCELLED;

  const useBridgeRelease = useSelector(state => getFeatureToggleByKeySelector(state as State.Root, USE_BRIDGE_RELEASE));
  const bypassRenewalButton = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, BYPASS_RENEWAL_BUTTON)
  );
  const isQuoteMembershipPaidByFirm = useSelector(quoteStatusAndIsMembershipPaidByFirmSelector)?.isPaidByFirm;
  const currentMembership = useSelector(currentMembershipProduct);
  const membershipTerm = useSelector(customerMembershipsSelector);
  const credentialsTerm = useSelector(customerCredentialsSelector);
  const sectionsTerm = useSelector(customerSectionsSelector);
  const isRenewalSeason = useSelector(isRenewalSeasonSelector);
  const isMembershipForRenewal = useSelector(isMembershipForRenewalSelector);

  const isUserSectionMember = useSelector(isUserSectionMemberSelector);
  const isUserCredentialHolder = useSelector(isUserCredentialHolderSelector);
  const isUserFirmMember = useSelector(isUserFirmMemberSelector);

  const isFCMACredential = productSlug === Product.CredentialProductSlug.FCMA;

  const isMipCredential = useSelector(hasMipCredentialProductSelector);
  const hasActiveMipCredential = useSelector(activeMipCredentialsSubscriptionSelector);

  const pathwaySelector = useSelector(rootSelector);
  const pathWayParams = pathwaySelector.siteEntry.params;

  const isMipRenewalSeason = useSelector(isMipRenewalSeasonSelector);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [isProductPaidByFirm, setProductPaidByFirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const mipRenewalSeasonStart = useSelector(mipRenewalSeasonStartConfigSelector);
  const mipRenewalSeasonEnd = useSelector(mipRenewalSeasonEndConfigSelector);
  const { mipRenewalSeasonStartDate, mipRenewalSeasonEndDate } = useMipRenewalSeasonDates();
  const mipData = useSelector(mipSelector);
  const productItem = useSelector(lineItemsSelector);
  const practiceRequirementAttestation = useSelector(attestationPracticeRequirementRootSelector);
  const aml = useSelector(amlFormSelector);
  const isAssignedProduct = !!item?.orderConfirmationAddress?.length;

  const isImpersonation = areAllTruthy(Boolean(isAdmin), isAdminPortal);

  const hasMultipleMipCredentials = useSelector(hasMultipleMipCredentialsSelector);

  const {
    contentRoleId,
    sku,
    orderNumber,
    ratePlanId,
    isAutoRenewable,
    isOnlyProductOnSubscription,
    orderId,
    subscriptionStartDate,
    subscriptionNumber,
    cartLineItemState,
    trackingId,
    carrierName,
    shipmentDate,
    standingOrderStatus,
    quantity,
    productType,
    productId,
    conferenceAccessLink,
    webcastAccessLink,
    autoRenewEnabled,
    shouldRemindRenewal,
    zuoraTermStartDate,
    isInGracePeriod,
    isFreeTrial,
    zuoraTermEndDate,
  } = item;

  const isShipped: boolean = cartLineItemState === Orders.LineItemStates.SHIPPED;
  const isDeprovisionedProduct = cartLineItemState === Orders.LineItemStates.CANCELLED_AND_DEPROVISIONED;
  const isAwaitingShipment = cartLineItemState === Orders.LineItemStates.AWAITING_SHIPMENT;
  const isActiveStandingOrder: boolean = standingOrderStatus === Product.StandingOrderStatus.ACTIVE;
  const isCancelledStandingOrder: boolean = standingOrderStatus === Product.StandingOrderStatus.CANCELLED;
  const isLegacy: boolean = cartLineItemState === Orders.LineItemStates.LEGACYPROVISIONED;
  const isSubscription: boolean = productType === Product.ProductType.SUBSCRIPTION;
  const isFee: boolean = productType === Product.ProductType.FEE;
  const isCGMACredential = productSlug === Product.CredentialProductSlug.CGMA;
  // if the section or credential has more than one term record it means it has done renewal before
  const isSectionCredentialsHasMultipleTerms = React.useMemo(() => {
    if (productType === Product.ProductType.SECTION) {
      const selectedSection = sectionsTerm.find(data => data.sku === sku);
      return selectedSection?.sectionTerms ? selectedSection?.sectionTerms.length > 1 : false;
    }
    if (productType === Product.ProductType.CREDENTIAL) {
      const selectedCredential = credentialsTerm.find(data => data.sku === sku);
      if (sku === MembershipTypes.CimaMip.MIP) return hasMultipleMipCredentials;
      return selectedCredential?.credentialTerms ? selectedCredential?.credentialTerms.length > 1 : false;
    }
    return false;
  }, [productType, sku, sectionsTerm, credentialsTerm, hasMultipleMipCredentials]);

  const checkIfProductIsForRenewal = useCallback((date: string | null | undefined) => {
    // Check Product is for Renewal for the next 3 months or 90 days
    const currentDate = moment().add(3, 'months').format('YYYY-MM-DD');
    return moment(date).format('YYYY-MM-DD') <= currentDate;
  }, []);

  const sectionsHash = useSelector(sectionsHashSelector);
  const credentialsHash = useSelector(credentialsHashSelector);
  const firmMembershipHash = useSelector(firmMembershipsHashSelector);
  const productListData = useSelector(productsListDataSelector);
  const bridgeWebconferences = useSelector(bridgeWebconferencesSelector);
  const bridgeUrlConfig = useSelector(bridgeUrlConfigSelector);
  const flpPlatformLink = useSelector(flpPlatformCGMALinkConfigSelector);
  const cimaStudyLink = useSelector(cimaStudyLinkConfigSelector);
  const clientAdminRole = useSelector(adminClientRoleSelector);

  const getSectionTopicPageLink = () => {
    switch (sku) {
      case Product.SectionProductSKU.TAX:
        if (isUserSectionMember) {
          const taxTopicPageAccessLink =
            sectionsHash[User.MembershipIdsEnum.MRUSR0002]?.accessNowLink ||
            UserUtils.sectionMembershipMap[User.MembershipIdsEnum.MRUSR0002]?.link;
          return taxTopicPageAccessLink;
        }
        break;
      case Product.SectionProductSKU.FVS:
        if (isUserSectionMember) {
          const fvsTopicPageAccessLink =
            sectionsHash[User.MembershipIdsEnum.MRUSR0018]?.accessNowLink ||
            UserUtils.sectionMembershipMap[User.MembershipIdsEnum.MRUSR0018]?.link;
          return fvsTopicPageAccessLink;
        }
        break;
      case Product.SectionProductSKU.PFP:
        if (isUserSectionMember) {
          const pfpTopicPageAccessLink =
            sectionsHash[User.MembershipIdsEnum.MRUSR0004]?.accessNowLink ||
            UserUtils.sectionMembershipMap[User.MembershipIdsEnum.MRUSR0004]?.link;
          return pfpTopicPageAccessLink;
        }
        break;
      case Product.SectionProductSKU.NFP:
        if (isUserSectionMember) {
          const nfpTopicPageAccessLink =
            sectionsHash[User.MembershipIdsEnum.MRUSR0033]?.accessNowLink ||
            UserUtils.sectionMembershipMap[User.MembershipIdsEnum.MRUSR0004]?.link;
          return nfpTopicPageAccessLink;
        }
        break;
    }
  };

  const getCredentialTopicPageLink = () => {
    switch (productSlug) {
      case Product.CredentialProductSlug.PFS:
        if (isUserCredentialHolder) {
          const pfsTopicPageAccessLink =
            credentialsHash[User.MembershipIdsEnum.MRUSR0022]?.accesNowLink ||
            UserUtils.credentialMembershipMap[User.MembershipIdsEnum.MRUSR0022]?.link;
          return pfsTopicPageAccessLink;
        }
        break;
      case Product.CredentialProductSlug.CITP:
        if (isUserCredentialHolder) {
          const citpTopicPageAccessLink =
            credentialsHash[User.MembershipIdsEnum.MRUSR0026]?.link ||
            UserUtils.credentialMembershipMap[User.MembershipIdsEnum.MRUSR0026]?.link;
          return citpTopicPageAccessLink;
        }
        break;
      case Product.CredentialProductSlug.CEIV:
        if (isUserCredentialHolder) {
          const ceivTopicPageAccessLink =
            credentialsHash[User.MembershipIdsEnum.MRUSR0041]?.link ||
            UserUtils.credentialMembershipMap[User.MembershipIdsEnum.MRUSR0041]?.link;
          return ceivTopicPageAccessLink;
        }
        break;
      case Product.CredentialProductSlug.CVFI:
        if (isUserCredentialHolder) {
          const cvfiTopicPageAccessLink =
            credentialsHash[User.MembershipIdsEnum.MRUSR0043]?.accessNowLink ||
            UserUtils.credentialMembershipMap[User.MembershipIdsEnum.MRUSR0043]?.link;
          return cvfiTopicPageAccessLink;
        }
        break;
      case Product.CredentialProductSlug.CFF:
        if (isUserCredentialHolder) {
          const cffTopicPageAccessLink =
            credentialsHash[User.MembershipIdsEnum.MRUSR0045]?.accessNowLink ||
            UserUtils.credentialMembershipMap[User.MembershipIdsEnum.MRUSR0045]?.link;
          return cffTopicPageAccessLink;
        }
        break;
      case Product.CredentialProductSlug.ABV:
        if (isUserCredentialHolder) {
          const abvTopicPageAccessLink =
            credentialsHash[User.MembershipIdsEnum.MRUSR0047]?.accessNowLink ||
            UserUtils.credentialMembershipMap[User.MembershipIdsEnum.MRUSR0047]?.link;
          return abvTopicPageAccessLink;
        }
        break;
      case Product.CredentialProductSlug.CGMA:
        if (isUserCredentialHolder) {
          const cgmaAccessLink = credentialsHash[User.MembershipIdsEnum.MRUSR0028]?.accessNowLink;
          return cgmaAccessLink;
        }
        break;
      case Product.CredentialProductSlug.FCMA:
      case Product.CredentialProductSlug.MIP:
        return getPath(Routes.MY_CREDENTIALS);
    }
  };

  const getCentersTopicPageLink = () => {
    switch (productSlug) {
      case Product.CentersMembershipProductSlug.PCPS:
        if (isUserFirmMember) {
          const pcpsTopicPageAccessLink =
            firmMembershipHash[User.MembershipIdsEnum.MRUSR0006]?.accessNowLink ||
            UserUtils.firmMembershipMap[User.MembershipIdsEnum.MRUSR0006]?.accessLink;
          return pcpsTopicPageAccessLink;
        }
        break;
      case Product.CentersMembershipProductSlug.GAQC:
        if (isUserFirmMember) {
          const gaqcTopicPageAccessLink =
            firmMembershipHash[User.MembershipIdsEnum.MRUSR0008]?.accessNowLink ||
            UserUtils.firmMembershipMap[User.MembershipIdsEnum.MRUSR0008]?.accessLink;
          return gaqcTopicPageAccessLink;
        }
        break;
      case Product.CentersMembershipProductSlug.EBPAQC:
        if (isUserFirmMember) {
          const ebpaqcTopicPageAccessLink =
            firmMembershipHash[User.MembershipIdsEnum.MRUSR0010]?.accessNowLink ||
            UserUtils.firmMembershipMap[User.MembershipIdsEnum.MRUSR0010]?.accessLink;
          return ebpaqcTopicPageAccessLink;
        }
        break;
      case Product.CentersMembershipProductSlug.CAQ:
        if (isUserFirmMember) {
          const caqTopicPageAccessLink =
            firmMembershipHash[User.MembershipIdsEnum.MRUSR0012]?.accessNowLink ||
            UserUtils.firmMembershipMap[User.MembershipIdsEnum.MRUSR0012]?.accessLink;
          return caqTopicPageAccessLink;
        }
        break;
      case Product.CentersMembershipProductSlug.CPEA:
        if (isUserFirmMember) {
          const cpeaTopicPageAccessLink =
            firmMembershipHash[User.MembershipIdsEnum.MRUSR0031]?.accessNowLink ||
            UserUtils.firmMembershipMap[User.MembershipIdsEnum.MRUSR0031]?.accessLink;
          return cpeaTopicPageAccessLink;
        }
        break;
    }
  };

  let topicPageAccessLink: string = '';

  if (isSection) {
    topicPageAccessLink = getSectionTopicPageLink();
  }
  if (isCredential) {
    topicPageAccessLink = getCredentialTopicPageLink();
  }
  if (isCenterMembership) {
    topicPageAccessLink = getCentersTopicPageLink();
  }

  const moreThan31dayLeftUntilAccessEndDate = React.useMemo(() => {
    const expiryDate = moment(item.zuoraTermEndDate || item.accessEndDate);
    const today = moment();
    return !expiryDate || expiryDate.diff(today, 'days') > 31;
  }, [item.zuoraTermEndDate, item.accessEndDate]);
  const allowCancelButton = (!isSubscription || moreThan31dayLeftUntilAccessEndDate) && !isFreeTrial;

  const isMembershipPaidByFirm =
    currentMembership.membership?.membershipTerm?.isPaidByFirm || isQuoteMembershipPaidByFirm;

  const isMembershipAlreadyRenew = React.useMemo(() => {
    if (isMembershipForRenewal && isRenewalSeason) return false;
    return true;
  }, [isMembershipForRenewal, isRenewalSeason]);

  const isPaidByFirm = React.useMemo(() => {
    let owner = false;
    if (productType === Product.ProductType.MEMBERSHIP) {
      membershipTerm
        .filter(term => term.membershipTerm?.productId === sku)
        .forEach(term => {
          owner = Boolean(term.membershipTerm.isPaidByFirm);
        });
      return owner;
    }
    if (productType === Product.ProductType.CREDENTIAL) {
      credentialsTerm
        ?.filter(term => term?.sku === sku)
        .forEach(term => {
          owner = Boolean(!!term?.credentialTerms?.length && term?.credentialTerms[0]?.isPaidByFirm);
        });
      return owner;
    }
    if (productType === Product.ProductType.SECTION) {
      sectionsTerm
        .filter(term => term.sku === sku)
        .forEach(term => {
          owner = Boolean(term.sectionTerms[0]?.isPaidByFirm);
        });
    }
    return owner;
  }, [sku, productType, membershipTerm, credentialsTerm, sectionsTerm]);

  let isAccessButtonDisabled: boolean = false;

  if (ProductsListParent.PURCHASES_PROFILE && isEventInNext48Hours) {
    isAccessButtonDisabled = !conferenceAccessLink;
  } else if (ProductsListParent.PURCHASES_PROFILE) {
    isAccessButtonDisabled = !isEventInNext48Hours;
  }

  useEffect(() => {
    setProductPaidByFirm(isPaidByFirm);
    getRenewalSeason?.();
    getMipRenewalSeasonStartDate?.();
    getMipRenewalSeasonEndDate?.();
  }, [isPaidByFirm, getRenewalSeason, getMipRenewalSeasonStartDate, getMipRenewalSeasonEndDate]); // eslint-disable-line react-hooks/exhaustive-deps

  // This is for "Renew Credential" Button to appear on the credential profile in MiP credential
  // Check for MiP Renew Credentials

  useEffect(() => {
    (async () => {
      if (
        productType === Product.ProductType.CREDENTIAL &&
        (productSlug === MembershipTypes.CimaCredentialProductSlug.CIMA_MIP || item.sku === MembershipTypes.CimaMip.MIP)
      ) {
        await dispatch(getMipContact());
        await dispatch(getAML());
        setLoading(false);
      }
    })();
  }, [setLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleExternalButtonLinkAnalytics = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonCtaText = (event.target as HTMLAnchorElement).textContent;
    handleEvent(
      {
        clickValue: `button:profile-purchases:ext:${buttonCtaText}`,
      },
      NAV_CLICK
    );
  }, []);

  const handleBuyAgainEvent = React.useCallback(() => {
    handleEvent(
      {
        clickValue: 'button:profile-purchases:int:buy-again',
      },
      NAV_CLICK
    );
  }, []);

  const handleAccessNowEvent = React.useCallback(() => {
    handleEvent(
      {
        clickValue: 'button:profile-purchases:int:access-now',
      },
      NAV_CLICK
    );
  }, []);

  const goToPremiumContentCategory = React.useCallback(() => {
    const categorySlug = getPremiumContentCategorySlug(contentRoleId as User.MembershipIdsEnum);
    const path = getPremiumContentToLink(categorySlug);
    if (navigate) {
      navigate({ pathname: path });
      if (isProfilePurchasesPage) {
        handleAccessNowEvent();
      }
    }
  }, [navigate, contentRoleId, handleAccessNowEvent, isProfilePurchasesPage]);

  const goToProductPage = React.useCallback(() => {
    if (navigate) {
      navigate({ pathname: link });
      handleBuyAgainEvent();
    }
  }, [navigate, link, handleBuyAgainEvent]);

  const goToWalletPage = React.useCallback(() => {
    if (navigate) {
      navigate({ pathname: getPath(Routes.PROFILE_WALLET) });
    }
  }, [navigate]);

  const goToReceiptsPage = React.useCallback(() => {
    if (navigate) {
      navigate({ pathname: getPath(Routes.PROFILE_RECEIPTS) });
    }
  }, [navigate]);

  const goToCancelPage = React.useCallback(() => {
    if (navigate) {
      navigate({
        pathname: getPath(Routes.ADMIN_CANCEL),
        state: {
          sku,
          orderId,
          orderNumber,
          ratePlanId,
          isAutoRenewable,
          isOnlyProductOnSubscription,
          subscriptionStartDate,
          subscriptionNumber,
          isContribution,
          zuoraTermStartDate,
        },
      });
    }
  }, [
    sku,
    orderId,
    orderNumber,
    ratePlanId,
    isAutoRenewable,
    isOnlyProductOnSubscription,
    subscriptionStartDate,
    subscriptionNumber,
    isContribution,
    zuoraTermStartDate,
    navigate,
  ]);

  const goToProfileMembershipsPage = React.useCallback(() => {
    if (navigate) {
      navigate({ pathname: getPath(Routes.MY_MEMBERSHIPS) });
    }
  }, [navigate]);

  const closeRenewModal = () => {
    setShowRenewModal(false);
  };

  const goToExamDashboard = React.useCallback(() => {
    if (navigate) {
      navigate({ pathname: getPath(Routes.CIMA_EXAMS_DASHBOARD_ROOT) });
    }
  }, [navigate]);

  const handleDeprovisioning = React.useCallback(() => {
    if (deprovision) {
      deprovision(orderId as string, sku as string);
    }
  }, [deprovision, orderId, sku]);

  const handleProvisionAccess = React.useCallback(() => {
    const found = productListData?.lineItems.find(i => i.id === item.lineItemId);
    /**
     * Ideally. All newly purchased bridge products should have their attribute.bridgeId
     * And old purchases should have their bridgeId in custom.fields.
     * Either should be empty, but the products were wrongly updated in CT snapshots so its not possible to change them anymore
     */

    // const bridgeId = found?.variant?.attributes?.bridgeId || found?.custom?.fields?.bridgeId;
    // const bridgeUserGroupId = found?.variant?.attributes?.bridgeUserGroupId || found?.custom?.fields?.bridgeUserGroupId;
    // const bridgeLiveTrainingSessionId = found?.variant?.attributes?.bridgeLiveTrainingSessionId || found?.custom?.fields?.bridgeLiveTrainingSessionId;
    // const bridgeType = found?.variant?.attributes?.bridgeType?.key || found?.custom?.fields?.bridgeType;
    const bridgeId = found?.custom?.fields?.bridgeId || found?.variant?.attributes?.bridgeId;
    const bridgeUserGroupId = found?.custom?.fields?.bridgeUserGroupId || found?.variant?.attributes?.bridgeUserGroupId;
    const bridgeLiveTrainingSessionId =
      found?.custom?.fields?.bridgeLiveTrainingSessionId || found?.variant?.attributes?.bridgeLiveTrainingSessionId;
    const bridgeType = found?.custom?.fields?.bridgeType || found?.variant?.attributes?.bridgeType?.key;
    const url = bridgeUrlConfig;
    const flpSkus: any = found?.variant?.attributes?.isFlpCourse;

    let bridgeAccessUrl = '';
    if (accessItem) {
      if (found && bridgeId && useBridgeRelease) {
        switch (bridgeType) {
          case User.BridgeTypes.PROGRAM:
            bridgeAccessUrl = `${url}/learner/programs/${bridgeId}`;
            break;
          case User.BridgeTypes.COURSE:
            bridgeAccessUrl = `${url}/learner/courses/${bridgeId}/launch`;
            break;
          case User.BridgeTypes.LIVE_COURSE:
            const match = bridgeWebconferences?.find(i => i.live_course_session_id === bridgeLiveTrainingSessionId);
            const conferenceUrl = match?.meeting_url;
            bridgeAccessUrl = conferenceUrl || '';
            break;
          default:
        }
        window.open(bridgeAccessUrl);
      } else if (found && bridgeUserGroupId && useBridgeRelease) {
        // Subscriptions like cpExpress will have bridgeUserGroupId. Direct to learning library
        bridgeAccessUrl = `${url}/learner/library`;
        window.open(bridgeAccessUrl);
      } else if (flpSkus || found?.productType === Product.ProductType.FLP) {
        window.open(flpPlatformLink);
      } else if (found?.productType === Product.ProductType.EXAM_CREDIT) {
        history.push(getPath(Routes.CIMA_EXAMS_DASHBOARD_ROOT));
      } else if (found?.productType === Product.ProductType.KAPLAN) {
        window.open(cimaStudyLink);
      } else {
        accessItem(item);
      }

      if (isProfilePurchasesPage) {
        handleAccessNowEvent();
      }
    }
  }, [
    accessItem,
    item,
    handleAccessNowEvent,
    isProfilePurchasesPage,
    productListData,
    bridgeUrlConfig,
    useBridgeRelease,
    flpPlatformLink,
    cimaStudyLink,
    bridgeWebconferences,
    history,
  ]);

  const goToWebAccessLink = () => {
    if (isServer) return;
    window.open(`${webcastAccessLink}`);
  };

  const handleRenewPath = React.useCallback(async () => {
    dispatch(setMembershipEvent('isClickedMembershipRenewal', true));
    if (navigate) {
      if (productType === Product.ProductType.CREDENTIAL) {
        history.push(getMembershipPackagePath(MembershipPackageAccordionStatus.Credentials));
      } else if (productType === Product.ProductType.SECTION) {
        history.push(getMembershipPackagePath(MembershipPackageAccordionStatus.Sections));
      } else {
        navigate({ pathname: getPath(Routes.MEMBERSHIP_FORM) });
      }
    }
  }, [history, productType, navigate, dispatch]);

  const handleRenewClick = React.useCallback(() => {
    if (productType === Product.ProductType.CREDENTIAL || productType === Product.ProductType.SECTION) {
      if (productType === Product.ProductType.CREDENTIAL) {
        dispatch(setCredentialsItemRenewals(item?.productId, item?.sku));
      }
      dispatch(setIsRenewalsJourney(true));
      dispatch(setMembershipEvent('isClickedCredentialsSectionsRenewal', true));
      dispatch(setMembershipEvent('isClickedMembershipRenewal', false));
      dispatch(setMembershipEvent('isClickedFcmaPropPage', false));
    }

    if (!isPaidByFirm) {
      if (!!isMembershipPaidByFirm) {
        if (isMembershipAlreadyRenew) {
          handleRenewPath();
          dispatch(setSectionsCredentialsRenewal({ isTriggered: true, productType }));
        } else setShowRenewModal(true);
      } else setShowRenewModal(true);
    } else setShowRenewModal(true);
  }, [
    isPaidByFirm,
    isMembershipPaidByFirm,
    isMembershipAlreadyRenew,
    handleRenewPath,
    setShowRenewModal,
    dispatch,
    productType,
    item,
  ]);

  const handleOpenCancelStandingOrderModal = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const buttonCtaText = (event.target as HTMLAnchorElement).textContent;

      if (setCancelSOrderModalMeta) setCancelSOrderModalMeta({ orderNumber, sku, productId } as any);
      if (!cancelSoTarget) {
        handleEvent(
          {
            clickValue: `button:profile-purchases:int:${buttonCtaText}`,
          },
          EVENT_CLICK
        );
      }
      handleEvent(
        {
          clickValue: `button:profile-purchases:int:cancel-standing-order:modal-${cancelSoTarget ? 'complete-' : ''}${
            cancelSoTarget ? buttonCtaText : 'start'
          }`,
        },
        EVENT_CLICK
      );
    },
    [productId, orderNumber, sku, setCancelSOrderModalMeta, cancelSoTarget]
  );
  const handleOpenModalExtendAccess = React.useCallback(() => {
    dispatch(openModalExtendAccess(item));
  }, [item, dispatch]);

  const [isReturnModalOpen, setReturnModalOpen] = React.useState(false);
  const toggleReturnModalOpen = React.useCallback(() => {
    setReturnModalOpen(!isReturnModalOpen);
    if (!isReturnModalOpen) {
      handleEvent(
        {
          clickValue: `button:profile-purchases:int:Return item`,
        },
        EVENT_CLICK
      );
    }
    handleEvent(
      {
        clickValue: `button:profile-purchases:int:return-item:modal-${
          isReturnModalOpen ? 'complete-Go back' : 'start'
        }`,
      },
      EVENT_CLICK
    );
  }, [isReturnModalOpen]);

  const redirectMainApplicationPage = useCallback(async () => {
    setLoading(true);
    await dispatch(getRenewalSeason?.());
    await dispatch(getMipPracticeRequirements());
    await dispatch(getMipContact());

    const isMipRenewal = await mipRenewal(
      mipRenewalSeasonStart,
      mipRenewalSeasonEnd,
      mipData,
      productItem,
      isMipRenewalSeason,
      pathwaySelector
    );
    const latestMipRecord = mipData?.contactDetails?.practiceList[0];
    const isMipRenewalPracticeRecordAlreadyExists =
      latestMipRecord &&
      moment(latestMipRecord?.createdDate).isBetween(mipRenewalSeasonStartDate, mipRenewalSeasonEndDate);
    if (isMipRenewal && latestMipRecord && !isMipRenewalPracticeRecordAlreadyExists) {
      const renewalPayload = mipRenewalPayload(mipData, practiceRequirementAttestation, aml);
      await dispatch(createMipContact(renewalPayload.contactPayload));
      await dispatch(getMipContact());
      await dispatch(updatePracticeRequirements(renewalPayload.practiceRequirementPayload));
      await dispatch(createAML({ ...renewalPayload.amlData, isCreateAMLRenewal: true }));
      setLoading(false);
    } else {
      setLoading(false);
    }

    history.push({
      pathname: `${getPath(Routes.CIMA_MIP_APPLICATION_MAIN_PAGE)}`,
      search: `?isMipRenewal=true`,
      state: { prevRoute },
    });
  }, [
    aml,
    isMipRenewalSeason,
    mipData,
    pathwaySelector,
    practiceRequirementAttestation,
    productItem,
    mipRenewalSeasonEnd,
    mipRenewalSeasonStart,
    history,
    prevRoute,
    setLoading,
    dispatch,
    getRenewalSeason,
    mipRenewalSeasonStartDate,
    mipRenewalSeasonEndDate,
  ]);

  // Note:
  // This is only for preventing users who just bought MIP credential to renew this product. Not stated in FD
  const currentDay = moment();
  const mipCredential = credentialsTerm?.find(credential => credential?.sku === MembershipTypes.CimaMip.MIP);
  const latestMipTermDate = moment(mipCredential?.credentialTerms?.[0]?.expiryDate);
  const monthDiff = Math.floor(latestMipTermDate.diff(currentDay, 'months', true));
  const isMipRenewed: boolean = UserUtils.conditionalFunction(mipCredential, monthDiff > 6, false);

  // This button should be visible and active only during the renewal/grace period window
  // from 1st Nov of the year till 28th Mar (MIP renewal season) of next year as per FD
  const showMipRenewalButton = areAllTruthy(
    hasActiveMipCredential,
    isMipRenewalSeason,
    productType === Product.ProductType.CREDENTIAL,
    item.sku === MembershipTypes.CimaMip.MIP,
    !isMipRenewed
  );

  const renderCancelButton = useCallback(
    (onClickHandler: any): React.ReactNode => (
      <>
        {!isZuoraSubscriptionCancelled && (
          <StyledActionButton
            loading={isMipCredential ? loading : false}
            testId={`cancel-${sku}`}
            variant={
              pathWayParams?.isMipRenewal === 'true' ||
              (isMipRenewalSeason && productType === Product.ProductType.CREDENTIAL && isMipCredential)
                ? ButtonEnums.variants.primary
                : ButtonEnums.variants.secondary
            }
            size={isProfilePurchasesPage ? ButtonEnums.sizes.small : ButtonEnums.sizes.medium}
            bordered
            to={
              pathWayParams?.isMipRenewal === 'true' ||
              (isMipRenewalSeason && productType === Product.ProductType.CREDENTIAL)
                ? `${getPath(Routes.CIMA_MIP_APPLICATION_MAIN_PAGE)}?isMipRenewal=true`
                : undefined
            }
            onClick={
              pathWayParams?.isMipRenewal === 'true' ||
              (isMipRenewalSeason && productType === Product.ProductType.CREDENTIAL && isMipCredential)
                ? redirectMainApplicationPage
                : handleCancelRefundModal
            }
            data-is-stacked-in-column={isStackedInColumn}
            type="button"
          >
            {hasTruthyValue(pathWayParams?.isMipRenewal === 'true', showMipRenewalButton)
              ? 'Renew Credential'
              : 'Cancel/Refund product'}
          </StyledActionButton>
        )}
      </>
    ),
    [
      isProfilePurchasesPage,
      sku,
      isStackedInColumn,
      handleCancelRefundModal,
      pathWayParams,
      redirectMainApplicationPage,
      isMipRenewalSeason,
      isZuoraSubscriptionCancelled,
      productType,
      loading,
      isMipCredential,
      showMipRenewalButton,
    ]
  );

  const isReviewPaymentCardButtonVisible =
    isSubscription && isProfilePurchasesPage && ((autoRenewEnabled && shouldRemindRenewal) || isInGracePeriod);
  const renderReviewPaymentCardButton = (): React.ReactNode => {
    return (
      <StyledActionButton
        testId={`review-payment-card-${sku}`}
        variant={ButtonEnums.variants.secondary}
        size={isMobile ? ButtonEnums.sizes.large : ButtonEnums.sizes.small}
        bordered
        onClick={goToWalletPage}
        data-is-stacked-in-column={isStackedInColumn}
        data-mobile-small-text
        type="button"
      >
        Review payment card
      </StyledActionButton>
    );
  };

  const isViewInvoiceButtonVisible = isSubscription && isProfilePurchasesPage && isInGracePeriod && !isFreeTrial;
  const renderViewInvoiceButton = (): React.ReactNode => {
    return (
      <StyledActionButton
        testId={`view-invoice-${sku}`}
        variant={ButtonEnums.variants.secondary}
        size={isMobile ? ButtonEnums.sizes.large : ButtonEnums.sizes.small}
        bordered
        onClick={goToReceiptsPage}
        data-is-stacked-in-column={isStackedInColumn}
        data-mobile-small-text
        type="button"
      >
        View invoice
      </StyledActionButton>
    );
  };

  const renderAccessTopicPageButton = (): React.ReactNode => {
    // this is for lower environment this toggle must be off on production (bypassRenewalButton)
    const isMipAccessButton: boolean = hasTruthyValue(
      hasTruthyValue(
        productSlug === MembershipTypes.CimaCredentialProductSlug.CIMA_MIP,
        item.sku === MembershipTypes.CimaMip.MIP
      ), // has MIP
      pathWayParams?.isMipRenewal === 'true' && isMipRenewalSeason // remain old implementation
    );

    return (
      <>
        {isMipAccessButton ? (
          ''
        ) : (
          <StyledButtonLink
            testId={`access-now-${sku}`}
            variant={ButtonEnums.variants.secondary}
            size={
              isProfilePurchasesPage
                ? ButtonEnums.sizes.small
                : isMobile
                ? ButtonEnums.sizes.large
                : ButtonEnums.sizes.medium
            }
            to={topicPageAccessLink ? topicPageAccessLink : ''}
            data-is-stacked-in-column={isStackedInColumn}
            external={isImpersonation ? false : !isCredential}
            bordered
            overrideAnalytics
            onClick={handleExternalButtonLinkAnalytics}
          >
            Access Now
          </StyledButtonLink>
        )}
      </>
    );
  };

  const renderAccessNowButton = (onClickHandler?: any): React.ReactNode => {
    return (
      <>
        {isConference ? (
          <StyledButtonLink
            testId={`access-now-${sku}`}
            variant={ButtonEnums.variants.secondary}
            size={
              isProfilePurchasesPage
                ? ButtonEnums.sizes.small
                : isMobile
                ? ButtonEnums.sizes.large
                : ButtonEnums.sizes.medium
            }
            to={conferenceAccessLink ?? ''}
            data-is-stacked-in-column={isStackedInColumn}
            external
            bordered
            overrideAnalytics
            onClick={handleExternalButtonLinkAnalytics}
            disabled={isAccessButtonDisabled}
          >
            Access Now
          </StyledButtonLink>
        ) : (
          !isContribution &&
          !isFee &&
          !isCertificate && (
            <StyledActionButton
              testId={`access-now-${sku}`}
              variant={ButtonEnums.variants.secondary}
              disabled={isAccessNowDisabled}
              size={
                isProfilePurchasesPage
                  ? ButtonEnums.sizes.small
                  : isMobile
                  ? ButtonEnums.sizes.large
                  : ButtonEnums.sizes.medium
              }
              bordered
              onClick={onClickHandler}
              data-is-stacked-in-column={isStackedInColumn}
              type={onClickHandler ? 'button' : 'submit'}
            >
              Access Now
            </StyledActionButton>
          )
        )}
        {isViewInvoiceButtonVisible && renderViewInvoiceButton()}
      </>
    );
  };
  const isCgmaOrAcma = [
    Product.CIMA_CREDENTIALS_SKU.CGMA.toLowerCase(),
    Product.CIMA_CREDENTIALS_SKU.ACMA.toLowerCase(),
  ].includes(sku!.toLowerCase());

  // this is for lower environment this toggle must be off on production (bypassRenewalButton)
  const showRenewalButton: boolean = hasTruthyValue(
    checkIfProductIsForRenewal(zuoraTermEndDate), // 90days
    areAllTruthy(bypassRenewalButton, !isCgmaOrAcma) // bypass 90 days and exclude CGMA AND ACMA
  );
  const renderRenewButton = (onClickHandler?: any): React.ReactNode => {
    return (
      <>
        {!isContribution && !isFCMACredential && showRenewalButton && (
          <StyledActionButton
            testId={`renew-${sku}`}
            variant={ButtonEnums.variants.primary}
            size={
              isProfilePurchasesPage
                ? ButtonEnums.sizes.small
                : isMobile
                ? ButtonEnums.sizes.large
                : ButtonEnums.sizes.medium
            }
            bordered
            onClick={onClickHandler}
            data-is-stacked-in-column={isStackedInColumn}
            type={onClickHandler ? 'button' : 'submit'}
          >
            Renew
          </StyledActionButton>
        )}
      </>
    );
  };

  const renderBuyAgainButton = (): React.ReactNode => (
    <StyledActionButton
      testId={`buy-again-${sku}`}
      variant={isCancelledStandingOrder ? ButtonEnums.variants.primary : ButtonEnums.variants.secondary}
      size={isProfilePurchasesPage ? ButtonEnums.sizes.small : ButtonEnums.sizes.medium}
      bordered
      onClick={goToProductPage}
      data-is-stacked-in-column={isStackedInColumn}
      overrideAnalytics={isCancelledStandingOrder}
    >
      {isFreeTrial || isAssignedProduct ? 'Buy full access' : 'Buy again'}
    </StyledActionButton>
  );

  const renderPhysicalProductsButtons = useCallback((): React.ReactNode => {
    const isItemReturnable: boolean = Boolean(
      quantity! < 10 && shipmentDate && MomentHelpers.getDiff(new Date().toISOString(), shipmentDate, 'days') <= 30
    );
    return (
      <>
        {isShipped && carrierName && trackingId && !isSubscription && (
          <>
            <StyledButtonLink
              testId={`track-your-order-${sku}`}
              variant={ButtonEnums.variants.secondary}
              size={ButtonEnums.sizes.small}
              bordered
              external
              to={getTrackingUrl(carrierName as Orders.Carriers, trackingId as string) as string}
              data-is-stacked-in-column={isStackedInColumn}
              overrideAnalytics
              onClick={handleExternalButtonLinkAnalytics}
            >
              Track your order
            </StyledButtonLink>
            {isItemReturnable && (
              <>
                {isActiveStandingOrder ? (
                  <StyledActionButton
                    testId={`return-item-${sku}`}
                    variant={ButtonEnums.variants.secondary}
                    size={ButtonEnums.sizes.small}
                    onClick={toggleReturnModalOpen}
                    data-is-stacked-in-column={isStackedInColumn}
                    bordered
                  >
                    Return item
                  </StyledActionButton>
                ) : (
                  <StyledButtonLink
                    testId={`return-item-${sku}`}
                    variant={ButtonEnums.variants.secondary}
                    size={ButtonEnums.sizes.small}
                    to={shipStationReturnPortalLink}
                    data-is-stacked-in-column={isStackedInColumn}
                    external
                    bordered
                    overrideAnalytics
                    onClick={handleExternalButtonLinkAnalytics}
                  >
                    Return item
                  </StyledButtonLink>
                )}
              </>
            )}
            {renderCancelButton(goToCancelPage)}
          </>
        )}
        {isActiveStandingOrder && (
          <StyledActionButton
            testId={`purchases-cancel-standing-order-${sku}`}
            variant={ButtonEnums.variants.secondary}
            size={ButtonEnums.sizes.small}
            iconPosition={ButtonEnums.iconPosition.left}
            onClick={handleOpenCancelStandingOrderModal}
            bordered
            data-is-stacked-in-column={isStackedInColumn}
          >
            Cancel standing order
          </StyledActionButton>
        )}
        {(isCancelledStandingOrder || (!isActiveProduct && isSubscription && !isDiscontinued)) &&
          !isMipRenewalSeason &&
          renderBuyAgainButton()}

        {isReviewPaymentCardButtonVisible && renderReviewPaymentCardButton()}
        {isViewInvoiceButtonVisible && renderViewInvoiceButton()}

        {isAdmin &&
          isAdminPortal &&
          !isDeprovisionedProduct &&
          !isAwaitingShipment &&
          allowCancelButton &&
          isMipRenewalSeason &&
          renderCancelButton(goToCancelPage)}
        <ReturnProductItemModal
          open={isReturnModalOpen}
          onConfirmUrl={shipStationReturnPortalLink}
          onCancel={toggleReturnModalOpen}
        />
      </>
    );
  }, [isMipRenewalSeason]); // eslint-disable-line react-hooks/exhaustive-deps

  const showExtendAccessButton =
    clientAdminRole === B2B.AgentRole.GEC &&
    isAdminPortal &&
    isExtendAccess &&
    !isInGracePeriod &&
    !!subscriptionNumber;
  const renderExtendAccessButton = (): React.ReactNode => (
    <StyledActionButton
      testId={`extend-access-${sku}`}
      variant={ButtonEnums.variants.secondary}
      size={ButtonEnums.sizes.small}
      bordered
      onClick={handleOpenModalExtendAccess}
      data-is-stacked-in-column={isStackedInColumn}
    >
      Extend Access
    </StyledActionButton>
  );

  const renderButton = (): React.ReactNode => {
    switch (parent) {
      case ProductsListParent.CART_PAGE:
        return (
          <StyledCartActionWrapper>
            <StyledRemoveWrapper>
              {item.title !== 'Late Fee' ? (
                <StyledButton
                  testId={`cart-remove-${sku}`}
                  variant={ButtonEnums.variants.link}
                  size={isMobile ? ButtonEnums.sizes.large : ButtonEnums.sizes.small}
                  icon={<Cancel />}
                  iconPosition={ButtonEnums.iconPosition.left}
                  onClick={handleRemoveItem}
                >
                  Remove Item
                </StyledButton>
              ) : null}
            </StyledRemoveWrapper>
            {isContribution && isEditContributionAmountToggled && (
              <StyledContributionActionWrapper>{renderActionContributionButtons()}</StyledContributionActionWrapper>
            )}
          </StyledCartActionWrapper>
        );
      case ProductsListParent.PURCHASES_PROFILE:
        if (
          areAllTruthy(
            showMipRenewalButton,
            hasTruthyValue(
              productSlug === MembershipTypes.CimaCredentialProductSlug.CIMA_MIP,
              item.sku === MembershipTypes.CimaMip.MIP
            )
          )
        ) {
          return renderCancelButton(goToCancelPage);
        }
        if (isAnyPhysicalProduct) {
          return renderPhysicalProductsButtons();
        }
        if (!isActiveProduct && isDiscontinued) {
          if (isAdmin && allowCancelButton && !isConference) {
            return renderCancelButton(goToCancelPage);
          }
        }
        if (isPremiumContent && isActiveProduct) {
          return (
            <>
              {renderAccessNowButton(goToPremiumContentCategory)}
              {isReviewPaymentCardButtonVisible && renderReviewPaymentCardButton()}
              {isLegacy && !isDiscontinued && !isMipRenewalSeason && renderBuyAgainButton()}
              {!isAssignedProduct &&
                isAdmin &&
                isAdminPortal &&
                allowCancelButton &&
                renderCancelButton(goToCancelPage)}
              {showExtendAccessButton && renderExtendAccessButton()}
            </>
          );
        }
        if ((isOPL || hasDomainString) && isActiveProduct) {
          return (
            <StyledForm action={oplRedirectUrl} method="post" target="_blank">
              <input type="hidden" name="hidUsername" value={userEmail} />
              <input type="hidden" name="hidOktaId" value={oktaId} />
              <input type="hidden" name="hidOktaRoleId" value={fvsMembership} />
              <input type="hidden" name="hidSourceSiteCode" value="RAVE" />
              <input type="hidden" name="hidURL" value={oplHidUrl} />
              {renderAccessNowButton()}
              {isReviewPaymentCardButtonVisible && renderReviewPaymentCardButton()}
              {isLegacy && !isDiscontinued && !isMipRenewalSeason && renderBuyAgainButton()}
              {!isAssignedProduct && isAdminPortal && allowCancelButton && renderCancelButton(goToCancelPage)}
              {showExtendAccessButton && renderExtendAccessButton()}
            </StyledForm>
          );
        }
        if (isConference && !isAdminPortal && isActiveProduct) {
          return (
            <>
              {renderAccessNowButton()}
              {isReviewPaymentCardButtonVisible && renderReviewPaymentCardButton()}
            </>
          );
        }
        if (isAdminPortal && isConference) {
          return (
            <DeprovisionButton
              testId={`access-now-${sku}`}
              isProfilePurchasesPage={isProfilePurchasesPage}
              handleDeprovisioning={handleDeprovisioning}
              text={'Remove'}
            />
          );
        }
        if (productType === Product.ProductType.EVENT && isActiveProduct) {
          return (
            <>
              {isAdminPortal && renderAccessTopicPageButton()}
              {isAdminPortal && renderCancelButton(goToCancelPage)}
            </>
          );
        }
        if ((isSection || isCredential || isCenterMembership) && isActiveProduct) {
          return (
            <>
              {isRenewalSeason &&
                (checkIfProductIsForRenewal(zuoraTermEndDate) ||
                  (isRenewalSeason && !isSectionCredentialsHasMultipleTerms)) && // if renewal season and initialPurchase of section or credential
                !isMipCredential &&
                !(isProfilePurchasesPage && isCenterMembership) &&
                renderRenewButton(handleRenewClick)}
              {!isFCMACredential && !isCGMACredential && renderAccessTopicPageButton()}
              {showRenewModal && (
                <ModalRenewOnProfile
                  isOpen={showRenewModal}
                  handleClose={closeRenewModal}
                  handleSubmit={handleRenewPath}
                  isPaidByFirm={isProductPaidByFirm}
                />
              )}
              {isAdminPortal &&
                allowCancelButton &&
                !isMipCredential &&
                !isFCMACredential &&
                renderCancelButton(goToCancelPage)}
            </>
          );
        }
        if (!isConference && isActiveProduct && productType !== Product.ProductType.CERTIFICATE) {
          return (
            <>
              {isWebcast && webcastAccessLink
                ? renderAccessNowButton(goToWebAccessLink)
                : !isContribution &&
                  !isExamScheduled &&
                  !isAnyPhysicalProduct &&
                  !webcastAccessLink &&
                  renderAccessNowButton(handleProvisionAccess)}
              {isReviewPaymentCardButtonVisible && renderReviewPaymentCardButton()}
              {isLegacy && !isDiscontinued && !isMipRenewalSeason && renderBuyAgainButton()}
              {!isAssignedProduct &&
                isAdminPortal &&
                allowCancelButton &&
                !isFCMACredential &&
                renderCancelButton(goToCancelPage)}
              {showExtendAccessButton && renderExtendAccessButton()}
            </>
          );
        }
        if (!isActiveProduct && !isDiscontinued) {
          return (
            <>
              {!isMipRenewalSeason && !isFCMACredential && renderBuyAgainButton()}
              {isAdminPortal && allowCancelButton && !isFCMACredential && renderCancelButton(goToCancelPage)}
            </>
          );
        }
        break;
      case ProductsListParent.ORDER_CONFIRMATION:
        if (isOPL) {
          return (
            <form action={oplRedirectUrl} method="post" target="_blank">
              <input type="hidden" name="hidUsername" value={userEmail} />
              <input type="hidden" name="hidOktaId" value={oktaId} />
              <input type="hidden" name="hidOktaRoleId" value={fvsMembership} />
              <input type="hidden" name="hidSourceSiteCode" value="RAVE" />
              <input type="hidden" name="hidURL" value={oplHidUrl} />

              {renderAccessNowButton()}
            </form>
          );
        }
        if (isPremiumContent) {
          return renderAccessNowButton(goToPremiumContentCategory);
        }
        if (isSection || isCredential || isCenterMembership) {
          return renderAccessTopicPageButton();
        }
        if (isExamCreditType) {
          return renderAccessNowButton(goToExamDashboard);
        }
        if (!isConference && !isExamScheduled && !isAnyPhysicalProduct && !isCertificate) {
          if (isMembership || isFlpType) {
            return renderAccessNowButton(goToProfileMembershipsPage);
          }
          return renderAccessNowButton(handleProvisionAccess);
        }
    }
  };
  return <>{renderButton() as JSX.Element}</>;
};

const StyledButton = styled(Button)`
  &&&& {
    svg path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

export const StyledActionButton = styled(Button)<{
  ['data-is-stacked-in-column']?: boolean;
  ['data-mobile-small-text']?: boolean;
}>`
  &&&&& {
    width: ${props => (props['data-is-stacked-in-column'] ? props.theme.pxToRem(186) : props.theme.pxToRem(170))};
    margin-top: ${props => props.theme.pxToRem(24)};
    margin-right: ${props => (props['data-is-stacked-in-column'] ? 0 : props.theme.pxToRem(20))};
    ${props => props.theme.mediaQueries.desktopOnly} {
      margin-top: ${props => (props['data-is-stacked-in-column'] ? 0 : props.theme.pxToRem(10))};
      margin-bottom: ${props => props['data-is-stacked-in-column'] && props.theme.pxToRem(12)};
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props =>
        props['data-is-stacked-in-column'] ? props.theme.pxToRem(12) : props.theme.pxToRem(15)};
      width: ${props => props['data-is-stacked-in-column'] && props.theme.pxToRem(194)};
      margin-top: ${props => props['data-is-stacked-in-column'] && 0};
      ${props =>
        props['data-mobile-small-text'] &&
        `
        font-size:${props.theme.fontSizes.xs};
        line-height: 1;
        min-height: ${props.theme.pxToRem(34)};
        padding: ${props.theme.pxToRem(1)} ${props.theme.pxToRem(6)};
      `};
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)<{ ['data-is-stacked-in-column']?: boolean }>`
  &&&& {
    width: ${props => (props['data-is-stacked-in-column'] ? props.theme.pxToRem(186) : props.theme.pxToRem(170))};
    margin-bottom: ${props => props.theme.pxToRem(12)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => props['data-is-stacked-in-column'] && props.theme.pxToRem(194)};
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledCartActionWrapper = styled.div`
  display: flex;
`;

const StyledRemoveWrapper = styled.div`
  flex: 1;
`;

const StyledContributionActionWrapper = styled(OnlyDesktopCSS)`
  margin-left: auto;
`;
