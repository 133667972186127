import React, { RefObject } from 'react';
import styled from 'styled-components';
import { SurveyOption, SurveyOptionTypeEnum } from 'constants/index';
import { ArticleFeedbackResponse } from 'components/molecules/ArticleFeedback/ArticleFeedbackResponse';
import { ArticleFeedbackPrompt } from 'components/molecules/ArticleFeedback/ArticleFeedbackPrompt';
import { ArticleFeedbackSuccess } from 'components/molecules/ArticleFeedback/ArticleFeedbackSuccess';
import { Scroller } from 'utils/index';

interface ArticleFeedbackProps {
  contentId: string;
  contentRef?: RefObject<HTMLDivElement>;
  values: State.UserFeedback | null;
  submitFeedback: (contentId: string, payload: State.UserFeedbackRecord) => void;
  getUserContentFeedbackValues: (contentId: string) => void;
}

enum ArticleFeedbackState {
  Prompt,
  Negative,
  Positive,
  Success,
}

export const ArticleFeedback: React.FC<ArticleFeedbackProps> = React.memo(
  ({ submitFeedback, values, getUserContentFeedbackValues, contentId, contentRef }) => {
    const testId: string = 'article-feedback';

    React.useEffect(() => {
      if (!values || !values.hasOwnProperty(contentId)) {
        getUserContentFeedbackValues(contentId);
      }
    }, [values, contentId, getUserContentFeedbackValues]);

    const [state, setState] = React.useState<ArticleFeedbackState>(ArticleFeedbackState.Prompt);
    const actionUp = React.useCallback(() => {
      submitFeedback(contentId, { rating: true });
      setState(ArticleFeedbackState.Success);
    }, [submitFeedback, setState, contentId]);
    const actionDown = React.useCallback(() => {
      setState(ArticleFeedbackState.Negative);
    }, [setState]);

    const handleEditFeedbackClick = React.useCallback(() => {
      if (!values || !values[contentId]) {
        return;
      }
      setState(values[contentId].rating ? ArticleFeedbackState.Positive : ArticleFeedbackState.Negative);
    }, [values, contentId]);

    const submit = React.useCallback(
      (payload: { rating: boolean; option: string; description: string }) => {
        submitFeedback(contentId, {
          rating: false,
          ...payload,
        });
        setState(ArticleFeedbackState.Success);
        Scroller.scrollToTarget(contentRef, { useWindow: true });
      },
      [submitFeedback, setState, contentId, contentRef]
    );

    const highlightValue = React.useMemo(() => values && values[contentId] && values[contentId].rating, [
      values,
      contentId,
    ]);

    switch (state) {
      case ArticleFeedbackState.Prompt:
        return (
          <StyledArticleFeedback>
            <ArticleFeedbackPrompt
              testId={`${testId}-prompt`}
              actionUp={actionUp}
              actionDown={actionDown}
              isUpHighlighted={highlightValue === true}
              isDownHighlighted={highlightValue === false}
            />
          </StyledArticleFeedback>
        );
      case ArticleFeedbackState.Positive:
      case ArticleFeedbackState.Negative:
        const options: SurveyOption[] = [
          {
            type: SurveyOptionTypeEnum.Radio,
            title: 'Sorry to hear that. What went wrong?',
            values: {
              'It was too long, confusing, or poorly written': {
                text: `It was too long, confusing, or poorly written`,
                activeForm: [],
              },
              // tslint:disable-next-line
              "It didn't answer my question": {
                // tslint:disable-next-line
                text: "It didn't answer my question",
                activeForm: [],
              },
              'The headline was misleading': {
                text: `The headline was misleading`,
                activeForm: [],
              },
              'It is inaccurate': {
                text: `It is inaccurate`,
                activeForm: [],
              },
              'There are broken links': {
                text: `There are broken links`,
                activeForm: [],
              },
              'Other (Please specify)': {
                text: `Other (Please specify)`,
                activeForm: [
                  {
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
            },
          },
        ];
        return (
          <StyledArticleFeedback>
            <ArticleFeedbackPrompt
              testId={`${testId}-prompt`}
              actionUp={actionUp}
              actionDown={actionDown}
              isUpHighlighted={state === ArticleFeedbackState.Positive}
              isDownHighlighted={state === ArticleFeedbackState.Negative}
            />
            <ArticleFeedbackResponse options={options} testId="article-feedback-negative" onSubmit={submit} />
          </StyledArticleFeedback>
        );
      case ArticleFeedbackState.Success:
        return (
          <StyledArticleFeedback>
            <ArticleFeedbackSuccess testId="article-feedback-success" editFeedback={handleEditFeedbackClick} />
          </StyledArticleFeedback>
        );
      default:
        return <></>;
    }
  }
);

const StyledArticleFeedback = styled.div`
  margin-top: ${props => props.theme.pxToRem(39)};
`;
