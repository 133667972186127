import React from 'react';
import styled from 'styled-components';
import { Divider } from 'components/atoms';
import { LockIconWrapper } from 'components/molecules';
import { LogInRegisterButtons } from 'components/molecules/LogInRegisterButtons';

export const PageLandingAuth: React.FC = () => (
  <StyledAuthContainer>
    <StyledDividerWrapper key={`notification-wrapper`} data-testid={`landing-page-block-lock-notification-divider`}>
      <StyledDivider />
      <StyledLockIconWrapper />
      <StyledDivider />
    </StyledDividerWrapper>
    <StyledWrapper>
      <StyledSubheading>RESERVED FOR AICPA.ORG REGISTERED USERS</StyledSubheading>
      <StyledHeading>Log in with your AICPA.org account, or register</StyledHeading>
      <StyledButtonsContainer>
        <LogInRegisterButtons logInButtonTestId="uw-login" registerButtonTestId="uw-register" />
      </StyledButtonsContainer>
    </StyledWrapper>
  </StyledAuthContainer>
);

const StyledHeading = styled.div`
  margin: ${props => props.theme.pxToRem(8)} auto ${props => props.theme.pxToRem(40)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledSubheading = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey4};
`;

const StyledAuthContainer = styled.div`
  width: 60%;
  margin: ${props => props.theme.pxToRem(30)} auto;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 90%;
  }
`;

const StyledWrapper = styled.div`
  text-align: center;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledLockIconWrapper = styled(LockIconWrapper)`
  padding: ${props => props.theme.pxToRem(23)};
`;

const StyledDividerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.theme.pxToRem(20)};
  margin-bottom: ${props => props.theme.pxToRem(20)};

  ${props => props.theme.mediaQueries.computerMin} {
    margin-bottom: ${props => props.theme.pxToRem(30)};
  }
`;

const StyledDivider = styled(Divider)`
  width: 40%;

  ${props => props.theme.mediaQueries.tabletMin} {
    width: 45%;
  }
`;

const StyledButtonsContainer = styled.div`
  width: ${props => props.theme.pxToRem(300)};
  margin: ${props => props.theme.pxToRem(10)} auto;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(270)};
  }
`;
