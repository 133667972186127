import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  TabCenterAdminMembershipsManageContainer,
  TabCenterAdminMembershipsInvoicesContainer,
} from 'components/App/lazy-imports';
import { getPath } from 'utils';
import { compareTabWithPath } from 'utils/routes';
import { Routes } from 'constants/index';

export const PageCenterAdminMemberships: React.FC<RouteComponentProps> = ({ location }) => {
  const handleCompareTabWithPath = React.useCallback(
    (comparison: string) => compareTabWithPath(location.pathname, comparison),
    [location.pathname]
  );

  return (
    <>
      {handleCompareTabWithPath(getPath(Routes.CENTER_ADMIN_MEMBERSHIPS_TAB_MANAGE)) && (
        <TabCenterAdminMembershipsManageContainer />
      )}
      {handleCompareTabWithPath(getPath(Routes.CENTER_ADMIN_MEMBERSHIPS_TAB_INVOICES)) && (
        <TabCenterAdminMembershipsInvoicesContainer />
      )}
    </>
  );
};
