import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setAdminClientRole } from 'modules/admin/actions';
import { HeaderAdmin } from 'components/App/AdminApp';
import { FilterRadioLabel } from 'components/molecules/FilterRadioLabel';
import {
  Button,
  ButtonEnums,
  Container,
  Divider,
  Heading,
  Link,
  NotificationBanner,
  NotificationBannerEnums,
} from 'components/atoms';
import { B2B } from 'mxp-schemas';
import { ReactComponent as ErrorComponent } from 'resources/images/ic-error.svg';

export const PreLogin: React.FC<{ unauthorized: boolean }> = ({ unauthorized }) => {
  const dispatch = useDispatch();
  const [selectedRadio, setSelectedRadio] = useState<string | null>(null);

  const roleNamesMap = new Map<string, B2B.AgentRole>([
    ['Client & Partner Services', B2B.AgentRole.B2B_OPS],
    ['Firm Billing', B2B.AgentRole.FIRM_BILLING],
    ['Centers Membership', B2B.AgentRole.CENTERS_MEMBERSHIP],
    ['Finance', B2B.AgentRole.FINANCE],
    ['Global Engagement Center (GEC)', B2B.AgentRole.GEC],
    ['Operations', B2B.AgentRole.RAVE_OPS],
  ] as [string, B2B.AgentRole][]);

  const handleProceedToLoginButtonClick = React.useCallback(() => {
    const role = roleNamesMap.get(selectedRadio || '');
    dispatch(setAdminClientRole(role));
  }, [selectedRadio, roleNamesMap, dispatch]);

  const handleChange = React.useCallback(
    (id: string | undefined) => {
      setSelectedRadio(id || null);
    },
    [setSelectedRadio]
  );

  const radioButtons = [...roleNamesMap.keys()].map((item, index) => (
    <RadioWrap key={index} handleChange={handleChange} name={item} selection={selectedRadio} />
  ));

  return (
    <>
      <HeaderAdmin />
      <StyledContainer>
        <StyledPreferencesHeading as="h1">Products & Purchases Console</StyledPreferencesHeading>
        <Divider />
        {unauthorized && (
          <StyledNotificationBanner
            testId="error-authorization"
            childrenTestId="error-authorization-text"
            variant={NotificationBannerEnums.variant.red}
            icon={<ErrorComponent />}
          >
            Sorry, our records indicate you are not assigned to this role. Please select another role and try again or
            contact the IT helpdesk at{' '}
            <Link isExternal testId="error-email" to="mailto:help@aicpa-cima.com">
              help@aicpa-cima.com
            </Link>
            .
          </StyledNotificationBanner>
        )}
        <Intro>To help us get you started, please identify your role:</Intro>
        {radioButtons}
        <StyledButton
          testId="proceed-to-login"
          size={ButtonEnums.sizes.medium}
          variant={ButtonEnums.variants.primary}
          onClick={handleProceedToLoginButtonClick}
          disabled={!Boolean(selectedRadio)}
        >
          Proceed to login
        </StyledButton>
      </StyledContainer>
    </>
  );
};

const RadioWrap: React.FC<{
  handleChange: (id: string | undefined) => void;
  name: string;
  selection: string | null;
}> = ({ name, handleChange, selection }) => {
  const checked = name === selection;

  return (
    <FilterRadioLabelStyled
      testId={name}
      text={name}
      id={name}
      checked={checked}
      onChange={handleChange}
      reverseLabelAndRadio
      smallerIcon
    />
  );
};

const StyledContainer = styled(Container)`
  text-align: left;
  max-width: ${props => props.theme.pxToRem(640)};
`;

const FilterRadioLabelStyled = styled(FilterRadioLabel)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledButton = styled(Button)`
  &&&&&&&& {
    min-width: ${props => props.theme.pxToRem(166)};
  }
`;

const StyledText = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const Intro = styled(StyledText)`
  margin-top: ${props => props.theme.pxToRem(34)};
  margin-bottom: ${props => props.theme.pxToRem(36)};
`;

const StyledPreferencesHeading = styled(Heading)`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  border-bottom: none;
  margin-bottom: 0px;
  margin-top: 0px;
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(24)};

  svg {
    min-width: 24px;
  }
  max-width: ${props => props.theme.pxToRem(360)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(16)};
  }
`;
