import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.apprentice,
  (apprentice: State.Apprentice): State.Apprentice => apprentice
);

export const apprenticeSelector = rootSelector;
