// ------------------------------------
// Constants
// ------------------------------------
export const initialState: State.SavedItems = {
  contentIds: null,
  top: null,
  loading: false,
  meta: {
    total: 0,
    perPage: 0,
    page: 0,
    aggs: null,
  },
  results: [],
  resultsFetched: false,
};
