import { Action, handleActions } from 'redux-actions';
import moment from 'moment-timezone';

import {
  getAdminFeatureToggles,
  toggleAdminFeature,
  searchUser,
  cancelOrder,
  setProductCancellationStatus,
  getOrganizationAdmins,
  getOrganizationOrders,
  getOrganizationOrdersDetails,
  addProductToCart,
  setCartLoading,
  changeListPrice,
  changeDiscountPrice,
  changeField,
  editDescription,
  editBundleName,
  editBundleSubStart,
  editBundleOccurrenceDate,
  editBundlePeriod,
  editBundlePeriodType,
  changeProvisioning,
  removeProductFromCart,
  updateToggleStatus,
  openAllProducts,
  closeAllProducts,
  setAdminClientRole,
  checkAdminSession,
  getOrganizationAddress,
  checkoutLoading,
  setShippingAddress,
  setBillingAddress,
  setSecondaryAddressNeeded,
  resetCheckoutModule,
  setSwapAdminModalOpen,
  setSwapAdminModalLoading,
  setAdminToRemove,
  swapAdmins,
  changeOpportunityNumber,
  changePoNumber,
  changeInvoicePresentation,
  changeSellingCompany,
  changeClientAdmin,
  setInvoiceInClientAdminProfile,
  setSendConfirmationEmailState,
  setSendConfirmationEmails,
  searchInvoices,
  searchInvoicesLoading,
  changeInvoiceDate,
  toggleSelectInvoice,
  getAdminInvoiceFile,
  setDownloadingId,
  toggleSelectAllInvoices,
  setSingleInvoiceSelected,
  payInvoices,
  resetAdminInvoicesResultModule,
  resetAdminInvoicesPayResultModule,
  getB2BOrderPreview,
  createB2BOrder,
  relatedEntitiesLoading,
  removeAdmin,
  addressProceedToNext,
  resetCartModule,
  resetImpersonatedUserPasswordLoading,
  resetImpersonatedUserPassword,
  getCompleteAddressFormValidation,
  setSecondaryBillingAddressNeeded,
  setShippingEmbargoEdited,
  setBillingEmbargoEdited,
  checkForAdminRelatedEntities,
  setBypassValidation,
  setBypassValidationBilling,
  cancelRefundOrder,
  getInvoiceItems,
  toggleSelectAllInvoiceItems,
  toggleSelectInvoiceItem,
  resetInvoiceItems,
  invoiceItemsLoading,
  setPaginationAction,
  changeCurrencyCode,
  setLegalEntity,
  setSalesforceAccountNumber,
  changeRequestedAction,
  onSeatNumberBlur,
  setSelectedProduct,
  setSeatNumber,
  setCancellationDate,
  setRefundAmount,
  setTotalRefund,
  setReason,
  selectMultipleInvoice,
  resetLegalEntity,
  removeOrganizationOrderDetailList,
  setOrganizationZuoraAccountId,
  getTransactionHistory,
  getRecentDownloadedReport,
  resetBulkRenewalState,
  downloadReport,
  generateBulkRenewalReport,
  getRecentTransaction,
  getBulkRenewalAccess,
  toggleSelectedSubscription,
  getSubscriptions,
  resetSubscriptions,
  subscriptionListLoading,
  resetOrganizationOrderRefundCancel,
  processRenewal,
  setTransactionHistoryTableLoading,
  setReportHistoryTableLoading,
  setModalExtendAccessLoading,
  openModalExtendAccess,
  confirmModalExtendAccess,
  resetModalExtendAccess,
  extensionRaiseCreditMemo,
} from './actions';
import { ADMIN_AUTH_STATUS, AdminTableType } from 'constants/index';
import { B2B, Product } from 'mxp-schemas';
import { fromCentsFormat } from 'utils';
const initialState: State.Admin = {
  featureToggles: {},
  gecAgent: undefined,
  adminAuthStatus: null,
  adminClientRole: null,
  impersonatedUser: {
    impersonatedUserId: '',
    loading: false,
  },
  productCancellationStatus: {
    success: false,
    error: false,
    empty: true,
  },
  organizationAdmins: {
    list: [],
    loading: true,
    swapAdminModalOpen: false,
    swapAdminModalLoading: false,
    swapAdminSuccess: false,
    adminToRemove: null,
  },
  organizationOrders: {
    list: [],
    loading: true,
    error: null,
  },
  organizationOrdersDetails: {
    list: [],
    loading: true,
    error: null,
  },
  cart: {
    items: [],
    loading: false,
  },
  checkout: {
    loading: false,
    salesTax: null,
    shippingAddress: {
      addressLine1: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      bypassValidation: false,
    },
    billingAddress: {
      addressLine1: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      bypassValidation: false,
    },
    isShippingAddressValid: null,
    isBillingAddressValid: null,
    isShippingEmbargoed: null,
    isBillingEmbargoed: null,
    isSecondaryAddressNeeded: null,
    salesforceAddressFetched: null,
    isSecondaryBillingAddressNeeded: null,
    displayBypassValidation: null,
    displayBypassValidationBilling: null,
    smartystreetsValidation: {
      validationNeeded: false,
      shippingAddress: {
        valid: false,
        suggestions: [],
        reason: '',
      },
      billingAddress: {
        valid: false,
        suggestions: [],
        reason: '',
      },
      smartystreetsValidationFetched: false,
      billingValidationNeeded: false,
    },
  },
  invoiceDetails: {
    opportunityNumber: '',
    poNumber: '',
    invoicePresentation: B2B.InvoicePresentationOptions.LINE_ITEM_DETAIL,
    clientAdmin: '',
    displayInvoiceInClientAdminProfile: false,
    sendConfirmationEmail: {
      state: false,
      to: '',
    },
    sellingCompanyCode: '1000',
    invoiceDate: '',
  },
  invoices: {
    results: [],
    loading: false,
    downloadingId: null,
    downloadingError: false,
    pay: {
      result: null,
      loading: false,
      error: null,
    },
  },
  invoiceItems: {
    list: [],
    isInvoiceItemsFetched: false,
    loading: false,
    error: null,
  },
  subscriptionsList: {
    list: [],
    isSubscriptionsFetched: false,
    loading: false,
    error: null,
  },
  resetImpersonatedUserPassword: {
    loading: false,
    error: '',
    code: '',
  },
  isBillingAddressValid: null,
  isShippingAddressValid: null,
  pagination: {
    [AdminTableType.INVOICES]: {
      offset: 0,
      limit: 10,
      totalCount: 0,
    },
    [AdminTableType.INVOICE_ITEMS]: {
      offset: 0,
      limit: 10,
      totalCount: 0,
    },
  },
  currency: 'USD',
  legalEntity: null,
  salesforceAccountNumber: '',
  zuoraAccountId: '',
  organizationOrderRefundCancel: {
    seatNumber: '',
    cancellationDate: '',
    refundAmount: '',
    totalRefund: '',
    reason: '',
    requestedAction: '',
    selectedProduct: null,
    orderNumber: '',
    seatNumberDisabled: true,
    cancellationDateDisabled: true,
    totalRefundDisabled: true,
    isValidSeatNumber: false,
    isValidCancellationDate: false,
    isValidRefundAmount: false,
    previewCancelOrder: null,
  },
  invoicesSelected: [],
  bulkRenewal: {
    bulkRenewalHistory: [
      {
        uniqueID: '',
        date: '',
        generatedBy: '',
        status: '',
      },
    ],
    reportHistory: [
      {
        uniqueID: '',
        selections: '',
        generatedBy: '',
        date: '',
        time: '',
      },
    ],
    generateBulkRenewalReport: {
      uniqueID: '',
      action: '',
      status: '',
    },
    processBulkRenewal: {
      id: '',
      action: '',
      status: '',
    },
    recentTransaction: {
      uniqueID: '',
      action: 'renewal',
      status: 'renewal completed',
    },
    preSignedUrl: null,
    shouldHaveBulkRenewalAccess: false,
    isTransactionHistoryLoading: false,
    isReportHistoryLoading: false,
  },
  subscriptionsSelected: [],
  extendAccess: {
    loading: false,
  },
  creditMemo: {
    productSKU: '',
    orderNumber: '',
  },
};

const cancelStatusHandler = (state: State.Admin, action: any): State.Admin => {
  return {
    ...state,
    productCancellationStatus: action.payload,
    ...(state.adminClientRole === B2B.AgentRole.B2B_OPS && {
      organizationOrderRefundCancel: {
        ...state.organizationOrderRefundCancel,
        seatNumber: '',
        cancellationDate: '',
        refundAmount: '',
        totalRefund: '',
        reason: '',
        requestedAction: '',
        selectedProduct: null,
        orderNumber: '',
        seatNumberDisabled: true,
        cancellationDateDisabled: true,
        totalRefundDisabled: true,
        isValidSeatNumber: false,
        isValidCancellationDate: false,
        previewCancelOrder: null,
      },
    }),
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [checkAdminSession]: {
    next: (state: State.Admin, action: Action<{ adminAuthStatus: ADMIN_AUTH_STATUS }>): State.Admin => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  [getAdminFeatureToggles]: {
    // FIXME strong time any
    next: (state: any, action: any): State.Admin => ({
      ...state,
      featureToggles: {
        ...action.payload.adminFeatureToggles,
        ...state.featureToggles,
      },
    }),
  },
  [toggleAdminFeature]: {
    // FIXME strong time any
    next: (state: any, action: any): State.FeatureToggle => {
      const { key, toggleCheck } = action.payload;
      return {
        ...state,
        featureToggles: {
          ...state.featureToggles,
          [key]: toggleCheck,
        },
      };
    },
  },
  [setPaginationAction]: {
    next: (
      state: State.Admin,
      action: Action<{
        type: State.AdminTableType;
        modifier: Partial<State.Pagination>;
      }>
    ): State.Admin => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          [action.payload.type]: {
            ...state.pagination[action.payload.type],
            ...action.payload.modifier,
          },
        },
      };
    },
  },
  [searchUser]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        impersonatedUser: {
          ...state.impersonatedUser,
          impersonatedUserId: action.payload,
        },
      };
    },
  },
  [cancelOrder]: {
    next: cancelStatusHandler,
  },
  [cancelRefundOrder]: {
    next: cancelStatusHandler,
  },
  [setProductCancellationStatus]: {
    next: cancelStatusHandler,
  },
  [resetOrganizationOrderRefundCancel]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrderRefundCancel: {
          ...state.organizationOrderRefundCancel,
          seatNumber: '',
          cancellationDate: '',
          refundAmount: '',
          totalRefund: '',
          reason: '',
          requestedAction: '',
          selectedProduct: null,
          orderNumber: '',
          seatNumberDisabled: true,
          cancellationDateDisabled: true,
          totalRefundDisabled: true,
          isValidSeatNumber: false,
          isValidCancellationDate: false,
          previewCancelOrder: null,
        },
      };
    },
  },
  [getOrganizationAdmins]: {
    next: (state: State.Admin, action: any): State.Admin => {
      const {
        payload: { organizationAdminUsers },
      } = action;
      return {
        ...state,
        organizationAdmins: {
          ...state.organizationAdmins,
          list: organizationAdminUsers,
          loading: false,
        },
      };
    },
  },

  [setSwapAdminModalOpen]: {
    next: (state: State.Admin, action: Action<boolean>): State.Admin => {
      return {
        ...state,
        organizationAdmins: {
          ...state.organizationAdmins,
          swapAdminModalOpen: action.payload,
          swapAdminSuccess: false, // reset previous
        },
      };
    },
  },
  [setSwapAdminModalLoading]: {
    next: (state: State.Admin, action: Action<boolean>): State.Admin => {
      return {
        ...state,
        organizationAdmins: {
          ...state.organizationAdmins,
          swapAdminModalLoading: action.payload,
        },
      };
    },
  },
  [setAdminToRemove]: {
    next: (state: State.Admin, action: Action<State.OrganizationAdmin>): State.Admin => {
      return {
        ...state,
        organizationAdmins: {
          ...state.organizationAdmins,
          adminToRemove: action.payload,
        },
      };
    },
  },

  [swapAdmins]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        organizationAdmins: {
          ...state.organizationAdmins,
          swapAdminModalOpen: false,
          swapAdminModalLoading: false,
          swapAdminSuccess: true,
        },
      };
    },
    throw: (state: State.Admin): State.Admin => {
      return {
        ...state,
        organizationAdmins: {
          ...state.organizationAdmins,
          swapAdminModalOpen: false,
          swapAdminModalLoading: false,
        },
      };
    },
  },
  [getOrganizationOrders]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrders: {
          list: action.payload,
          loading: false,
          error: null,
        },
      };
    },
    throw: (state: State.Admin, { payload: error }: { payload: CustomErrors.GraphQLError }): State.Admin => {
      return {
        ...state,
        organizationOrders: {
          ...state.organizationOrders,
          loading: false,
          error,
        },
      };
    },
  },
  [getOrganizationOrdersDetails]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrdersDetails: {
          list: action.payload,
          loading: false,
          error: null,
        },
      };
    },
    throw: (state: State.Admin, { payload: error }: { payload: CustomErrors.GraphQLError }): State.Admin => {
      return {
        ...state,
        organizationOrdersDetails: {
          ...state.organizationOrdersDetails,
          loading: false,
          error,
        },
      };
    },
  },
  [removeOrganizationOrderDetailList]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrdersDetails: {
          list: [],
          loading: true,
          error: null,
        },
      };
    },
  },
  [changeRequestedAction]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrderRefundCancel: {
          ...state.organizationOrderRefundCancel,
          ...(action.payload.seatNumber !== undefined && { seatNumber: action.payload.seatNumber }),
          cancellationDate: '',
          refundAmount: action.payload.refundAmount,
          fullRefundAmount: action.payload.fullRefundAmount,
          requestedAction: action.payload.requestAction,
          seatNumberDisabled: action.payload.seatNumberDisabled,
          cancellationDateDisabled: action.payload.cancellationDateDisabled,
          totalRefundDisabled: action.payload.totalRefundDisabled,
          isValidSeatNumber: action.payload.isValidSeatNumber,
          isValidCancellationDate: action.payload.isValidCancellationDate,
          isValidRefundAmount: action.payload.isValidRefundAmount,
          previewCancelOrder: action.payload.previewCancelOrder,
        },
      };
    },
  },
  [onSeatNumberBlur]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrderRefundCancel: {
          ...state.organizationOrderRefundCancel,
          cancellationDate: '',
          refundAmount: action?.payload?.refundAmount,
          fullRefundAmount: action?.payload?.fullRefundAmount,
          seatNumberDisabled: action?.payload?.seatNumberDisabled,
          cancellationDateDisabled: action?.payload?.cancellationDateDisabled,
          totalRefundDisabled: action?.payload?.totalRefundDisabled,
          isValidSeatNumber: action?.payload?.isValidSeatNumber,
          isValidCancellationDate: action?.payload?.isValidCancellationDate,
          isValidRefundAmount: action?.payload?.isValidRefundAmount,
          previewCancelOrder: action?.payload?.previewCancelOrder,
        },
      };
    },
  },
  [setSelectedProduct]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrderRefundCancel: {
          ...state.organizationOrderRefundCancel,
          selectedProduct: action.payload.selectedProduct,
        },
      };
    },
  },
  [setSeatNumber]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrderRefundCancel: {
          ...state.organizationOrderRefundCancel,
          seatNumber: action.payload.value,
          isValidSeatNumber: action.payload.value !== '' ? action.payload.isValidSeatNumber : false,
          ...(action.payload.refundAmount && { refundAmount: action.payload.refundAmount }),
        },
      };
    },
  },
  [setCancellationDate]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrderRefundCancel: {
          ...state.organizationOrderRefundCancel,
          cancellationDate: action.payload.value,
          isValidCancellationDate: action.payload.isValidSeatNumber,
        },
      };
    },
  },
  [setRefundAmount]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrderRefundCancel: {
          ...state.organizationOrderRefundCancel,
          refundAmount: action.payload.value,
          isValidRefundAmount: action.payload.isValidRefundAmount,
        },
      };
    },
  },
  [setTotalRefund]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrderRefundCancel: {
          ...state.organizationOrderRefundCancel,
          totalRefund: action.payload.value,
        },
      };
    },
  },
  [setReason]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        organizationOrderRefundCancel: {
          ...state.organizationOrderRefundCancel,
          reason: action.payload.value,
        },
      };
    },
  },
  [setCartLoading]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        cart: {
          ...state.cart,
          loading: action.payload,
        },
      };
    },
  },
  [addProductToCart]: {
    next: (state: State.Admin, action: { payload: { searchProduct: B2B.ProductDetails | null } }): State.Admin => {
      const {
        payload: { searchProduct },
      } = action;

      // Note: we handle if the price come as undefined
      const isEmptyPrice = !Boolean(searchProduct?.listPrice.amount);
      const isProductNonStore = Product.ProductType.NON_STORE === searchProduct?.productType;
      const isProductOnSite = Product.ProductType.ONSITE === searchProduct?.productType;
      const isNonStoreRecurringProduct = isProductNonStore && searchProduct?.isManualSubscription;
      const date = moment(searchProduct?.startDateTime || new Date()).format('MM/DD/YYYY');

      if (!searchProduct) {
        return {
          ...state,
          cart: {
            ...state.cart,
            loading: false,
          },
        };
      }

      return {
        ...state,
        cart: {
          items: [
            ...state.cart.items,
            {
              productDetails: searchProduct,
              userInput: {
                price: isEmptyPrice ? 0 : fromCentsFormat(searchProduct.listPrice.amount),
                quantity: 1,
                description: searchProduct.name,
                provisionBy: isProductNonStore || isProductOnSite ? '' : B2B.ProvisionBy.AICPA,
                additionalAttrs: {
                  bundleName: '',
                  subscriptionStart: isNonStoreRecurringProduct ? date : moment().format('MM/DD/YYYY'),
                  period: 1,
                  periodType: 'Year',
                  occurrenceDate: date,
                },
                isOpen: false,
                projectNumber: searchProduct.projectNumber,
                teams: searchProduct.teams,
              },
            },
          ],
          loading: false,
        },
      };
    },
    throw: (state: State.Admin): State.Admin => {
      return {
        ...state,
        cart: {
          ...state.cart,
          loading: false,
        },
      };
    },
  },
  [removeProductFromCart]: {
    next: (state: State.Admin, action: { payload: { index: number } }) => {
      return {
        ...state,
        cart: {
          items: state.cart.items.filter((item, index) => {
            return action.payload.index !== index;
          }),
          loading: false,
        },
      };
    },
  },
  [changeListPrice]: {
    next: (state: State.Admin, action: { payload: { index: number; amount: string; currency: string } }) => {
      return {
        ...state,
        cart: {
          items: state.cart.items.map((item, index) => {
            if (action.payload.index !== index) return item;
            return {
              ...item,
              productDetails: {
                ...item.productDetails,
                listPrice: { amount: action.payload.amount, currency: action.payload.currency },
              },
            };
          }),
          loading: false,
        },
      };
    },
  },
  [changeDiscountPrice]: {
    next: (state: State.Admin, action: { payload: { index: number; price: string } }) => {
      return {
        ...state,
        cart: {
          items: state.cart.items.map((item, index) => {
            if (action.payload.index !== index) return item;
            return { ...item, userInput: { ...item.userInput, price: action.payload.price } };
          }),
          loading: false,
        },
      };
    },
  },
  [changeCurrencyCode]: {
    next: (state: State.Admin, action: { payload: { currency: string } }) => {
      return {
        ...state,
        currency: action.payload.currency,
      };
    },
  },
  [setOrganizationZuoraAccountId]: {
    next: (state: State.Admin, action: { payload: { value: string } }) => {
      return {
        ...state,
        zuoraAccountId: action.payload.value,
      };
    },
  },
  [setLegalEntity]: {
    next: (state: State.Admin, action: { payload: { value: string } }) => {
      return {
        ...state,
        legalEntity: action.payload.value,
      };
    },
  },
  [setSalesforceAccountNumber]: {
    next: (state: State.Admin, action: { payload: { value: string } }) => {
      return {
        ...state,
        salesforceAccountNumber: action.payload.value,
      };
    },
  },
  [changeField]: {
    next: (state: State.Admin, action: { payload: { index: number; value: string; field: string } }) => {
      return {
        ...state,
        cart: {
          items: [
            ...state.cart.items.map((item, index) => {
              if (action.payload.index !== index) return item;
              return { ...item, userInput: { ...item.userInput, [action.payload.field]: action.payload.value } };
            }),
          ],
          loading: false,
        },
      };
    },
  },
  [editDescription]: {
    next: (state: State.Admin, action: { payload: { index: number; description: string } }) => {
      return {
        ...state,
        cart: {
          items: [
            ...state.cart.items.map((item, index) => {
              if (action.payload.index !== index) return item;
              return { ...item, userInput: { ...item.userInput, description: action.payload.description } };
            }),
          ],
          loading: false,
        },
      };
    },
  },
  [editBundleName]: {
    next: (state: State.Admin, action: { payload: { index: number; bundleName: string } }) => {
      return {
        ...state,
        cart: {
          items: [
            ...state.cart.items.map((item, index) => {
              if (action.payload.index !== index) return item;
              return {
                ...item,
                userInput: {
                  ...item.userInput,
                  additionalAttrs: {
                    ...item.userInput?.additionalAttrs,
                    bundleName: action.payload.bundleName,
                  },
                },
              };
            }),
          ],
          loading: false,
        },
      };
    },
  },
  [editBundleSubStart]: {
    next: (state: State.Admin, action: { payload: { index: number; startDate: string } }) => {
      return {
        ...state,
        cart: {
          items: [
            ...state.cart.items.map((item, index) => {
              if (action.payload.index !== index) return item;
              return {
                ...item,
                userInput: {
                  ...item.userInput,
                  additionalAttrs: {
                    ...item.userInput?.additionalAttrs,
                    subscriptionStart: action.payload.startDate,
                  },
                },
              };
            }),
          ],
          loading: false,
        },
      };
    },
  },
  [editBundleOccurrenceDate]: {
    next: (state: State.Admin, action: { payload: { index: number; occDate: string } }) => {
      return {
        ...state,
        cart: {
          items: [
            ...state.cart.items.map((item, index) => {
              if (action.payload.index !== index) return item;
              return {
                ...item,
                userInput: {
                  ...item.userInput,
                  additionalAttrs: {
                    ...item.userInput?.additionalAttrs,
                    occurrenceDate: action.payload.occDate,
                  },
                },
              };
            }),
          ],
          loading: false,
        },
      };
    },
  },
  [editBundlePeriod]: {
    next: (state: State.Admin, action: { payload: { index: number; period: number } }) => {
      return {
        ...state,
        cart: {
          items: [
            ...state.cart.items.map((item, index) => {
              if (action.payload.index !== index) return item;
              return {
                ...item,
                userInput: {
                  ...item.userInput,
                  additionalAttrs: {
                    ...item.userInput?.additionalAttrs,
                    period: action.payload.period,
                  },
                },
              };
            }),
          ],
          loading: false,
        },
      };
    },
  },
  [editBundlePeriodType]: {
    next: (state: State.Admin, action: { payload: { index: number; periodType: string } }) => {
      return {
        ...state,
        cart: {
          items: [
            ...state.cart.items.map((item, index) => {
              if (action.payload.index !== index) return item;
              return {
                ...item,
                userInput: {
                  ...item.userInput,
                  additionalAttrs: {
                    ...item.userInput?.additionalAttrs,
                    periodType: action.payload.periodType,
                  },
                },
              };
            }),
          ],
          loading: false,
        },
      };
    },
  },
  [updateToggleStatus]: {
    next: (state: State.Admin, action: { payload: { index: number } }) => {
      return {
        ...state,
        cart: {
          items: [
            ...state.cart.items.map((item, index) => {
              if (action.payload.index !== index) return item;
              return { ...item, userInput: { ...item.userInput, isOpen: !item.userInput.isOpen } };
            }),
          ],
          loading: false,
        },
      };
    },
  },
  [openAllProducts]: {
    next: (state: State.Admin) => {
      return {
        ...state,
        cart: {
          items: [...state.cart.items.map(item => ({ ...item, userInput: { ...item.userInput, isOpen: true } }))],
          loading: false,
        },
      };
    },
  },
  [closeAllProducts]: {
    next: (state: State.Admin) => {
      return {
        ...state,
        cart: {
          items: [...state.cart.items.map(item => ({ ...item, userInput: { ...item.userInput, isOpen: false } }))],
          loading: false,
        },
      };
    },
  },
  [changeProvisioning]: {
    next: (state: State.Admin, action: { payload: { index: number; provisionBy: B2B.ProvisionBy } }) => {
      return {
        ...state,
        cart: {
          items: state.cart.items.map((item, index) => {
            if (action.payload.index !== index) return item;
            return {
              ...item,
              userInput: {
                ...item.userInput,
                provisionBy: action.payload.provisionBy,
                additionalAttrs: {
                  bundleName: '',
                  subscriptionStart: moment().format('MM/DD/YYYY'),
                  period: 1,
                  periodType: 'Year',
                  occurrenceDate: moment(item.productDetails.startDateTime || new Date()).format('MM/DD/YYYY'),
                },
              },
            };
          }),
          loading: false,
        },
      };
    },
  },
  [setAdminClientRole]: {
    next: (state: State.Admin, action: Action<B2B.AgentRole>): State.Admin => {
      return {
        ...state,
        adminClientRole: action.payload,
        adminAuthStatus: null,
      };
    },
  },
  [getOrganizationAddress]: {
    next: (
      state: State.Admin,
      action: { payload: { billingAddress: State.Address; shippingAddress: State.Address } }
    ): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          billingAddress: action.payload.billingAddress,
          shippingAddress: action.payload.shippingAddress,
          salesforceAddressFetched: true,
          loading: false,
        },
      };
    },
    throw: (state: State.Admin): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          salesforceAddressFetched: true,
          loading: false,
        },
      };
    },
  },
  [changeOpportunityNumber]: {
    next: (state: State.Admin, action: { payload: { opportunityNumber: string } }) => {
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          opportunityNumber: action.payload.opportunityNumber,
        },
      };
    },
  },
  [changePoNumber]: {
    next: (state: State.Admin, action: { payload: { poNumber: string } }) => {
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          poNumber: action.payload.poNumber,
        },
      };
    },
  },
  [changeInvoicePresentation]: {
    next: (state: State.Admin, action: { payload: { value: B2B.InvoicePresentationOptions } }) => {
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          invoicePresentation: action.payload.value,
        },
      };
    },
  },
  [changeSellingCompany]: {
    next: (state: State.Admin, action: { payload: { value: B2B.InvoicePresentationOptions } }) => {
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          sellingCompanyCode: action.payload.value,
        },
      };
    },
  },
  [changeClientAdmin]: {
    next: (state: State.Admin, action: { payload: { contactId: string } }) => {
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          clientAdmin: action.payload.contactId,
        },
      };
    },
  },
  [checkoutLoading]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          loading: !state.checkout.loading,
        },
      };
    },
  },
  [setInvoiceInClientAdminProfile]: {
    next: (state: State.Admin, action: { payload: { value: string } }) => {
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          displayInvoiceInClientAdminProfile: action.payload.value,
        },
      };
    },
  },
  [setShippingAddress]: {
    next: (state: State.Admin, action: Action<Partial<State.Address>>): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          shippingAddress: {
            ...state.checkout.shippingAddress,
            ...action.payload,
          },
          isSecondaryAddressNeeded:
            state.checkout.isSecondaryAddressNeeded && action.payload.addressLine2 !== undefined
              ? null
              : state.checkout.isSecondaryAddressNeeded,
          smartystreetsValidation:
            state.checkout.smartystreetsValidation !== null
              ? {
                  ...state.checkout.smartystreetsValidation,
                  billingAddress: { ...state.checkout.smartystreetsValidation.billingAddress },
                  billingValidationNeeded: state.checkout.smartystreetsValidation.billingValidationNeeded,
                  shippingAddress: { valid: true, reason: '', suggestions: [] },
                  smartystreetsValidationFetched: state.checkout.smartystreetsValidation.smartystreetsValidationFetched,
                  validationNeeded: false,
                }
              : null,
        },
      };
    },
  },
  [setSendConfirmationEmailState]: {
    next: (state: State.Admin, action: { payload: { value: string } }) => {
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          sendConfirmationEmail: { ...state.invoiceDetails.sendConfirmationEmail, state: action.payload.value },
        },
      };
    },
  },
  [setBillingAddress]: {
    next: (state: State.Admin, action: Action<Partial<State.Address>>): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          billingAddress: {
            ...state.checkout.billingAddress,
            ...action.payload,
          },
          isBillingAddressValid: null,
          isSecondaryBillingAddressNeeded:
            state.checkout.isSecondaryBillingAddressNeeded && action.payload.addressLine2 !== undefined
              ? null
              : state.checkout.isSecondaryBillingAddressNeeded,
          smartystreetsValidation:
            state.checkout.smartystreetsValidation !== null
              ? {
                  ...state.checkout.smartystreetsValidation,
                  billingAddress: { valid: true, reason: '', suggestions: [] },
                  billingValidationNeeded: false,
                  shippingAddress: { ...state.checkout.smartystreetsValidation.shippingAddress },
                  smartystreetsValidationFetched: state.checkout.smartystreetsValidation.smartystreetsValidationFetched,
                  validationNeeded: state.checkout.smartystreetsValidation.validationNeeded,
                }
              : null,
        },
      };
    },
  },
  [addressProceedToNext]: {
    next: (
      state: State.Admin,
      action: Action<{
        smartystreetsValidation: State.SmartyAddressValidationState | null;
        proceed: boolean;
        corrected: boolean;
        isBillingForm: boolean;
        autoCorrection: boolean;
        proceedBilling: boolean;
      }>
    ): State.Admin => {
      const { proceed, proceedBilling } = action.payload;
      return {
        ...state,
        checkout: {
          ...state.checkout,
          loading: false,
        },
        isShippingAddressValid: proceed,
        isBillingAddressValid: proceedBilling,
      };
    },
  },

  [getCompleteAddressFormValidation]: {
    next: (
      state: State.Admin,
      action: Action<{
        smartystreetsValidation: State.SmartyAddressValidationState | null;
        isShippingAddressValid: boolean;
        isBillingAddressValid: boolean;
        isShippingEmbargoed: boolean;
        isBillingEmbargoed: boolean;
      }>
    ): State.Admin => {
      const { isBillingAddressValid, isShippingEmbargoed, isBillingEmbargoed } = action.payload;
      return {
        ...state,
        checkout: {
          ...state.checkout,
          loading: false,
          smartystreetsValidation: {
            ...(action.payload.smartystreetsValidation as State.SmartyAddressValidationState),
            smartystreetsValidationFetched: true,
          },
          isShippingAddressValid: action.payload.isShippingAddressValid,
          isBillingAddressValid,
          isShippingEmbargoed,
          isBillingEmbargoed,
        },
      };
    },
  },
  [setSecondaryAddressNeeded]: {
    next: (state: State.Admin, action: { payload: boolean }): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          isSecondaryAddressNeeded: action.payload,
        },
      };
    },
  },
  [setSecondaryBillingAddressNeeded]: {
    next: (state: State.Admin, action: { payload: boolean }): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          isSecondaryBillingAddressNeeded: action.payload,
        },
      };
    },
  },
  [setShippingEmbargoEdited]: {
    next: (state: State.Admin, action: { payload: boolean }): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          isShippingEmbargoed: !action.payload,
        },
      };
    },
  },
  [setBillingEmbargoEdited]: {
    next: (state: State.Admin, action: { payload: boolean }): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          isBillingEmbargoed: !action.payload,
        },
      };
    },
  },
  [setBypassValidation]: {
    next: (state: State.Admin, action: { payload: boolean }): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          shippingAddress: {
            ...state.checkout.shippingAddress,
            bypassValidation: action.payload,
          },
        },
      };
    },
  },
  [setBypassValidationBilling]: {
    next: (state: State.Admin, action: { payload: boolean }): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          billingAddress: {
            ...state.checkout.billingAddress,
            bypassValidation: action.payload,
          },
        },
      };
    },
  },
  [resetCheckoutModule]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          salesforceAddressFetched: null,
          isShippingAddressValid: null,
          isSecondaryAddressNeeded: null,
          smartystreetsValidation: null,
        },
      };
    },
  },
  [setSendConfirmationEmails]: {
    next: (state: State.Admin, action: { payload: { emails: string } }) => {
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          sendConfirmationEmail: { ...state.invoiceDetails.sendConfirmationEmail, to: action.payload.emails },
        },
      };
    },
  },
  [searchInvoices]: {
    next: (state: State.Admin, action: Action<State.InvoiceTableRow[] | null>) => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          results:
            action.payload?.map((invoice: State.InvoiceTableRow) => ({
              ...invoice,
              isSelected: false,
            })) || action.payload,
          loading: false,
        },
      };
    },
  },
  [searchInvoicesLoading]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: !state.invoices.loading,
        },
      };
    },
  },
  [changeInvoiceDate]: {
    next: (state: State.Admin, action: { payload: { value: boolean } }) => {
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          invoiceDate: action.payload.value,
        },
      };
    },
  },
  [getInvoiceItems]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        invoiceItems: {
          ...state.invoiceItems,
          list:
            action.payload?.list?.map((invoiceItem: State.InvoiceItemResult) => ({
              ...invoiceItem,
              isSelected: false,
            })) || action.payload,
          loading: false,
          isInvoiceItemsFetched: true,
        },
      };
    },
  },
  [getSubscriptions]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        subscriptionsList: {
          ...state.subscriptionsList,
          list:
            action.payload?.list?.map((subscription: State.SubscriptionsResult) => ({
              ...subscription,
              isSelected: false,
            })) || action.payload,
          loading: false,
          isSubscriptionsFetched: true,
        },
      };
    },
  },
  [toggleSelectAllInvoiceItems]: {
    next: (
      state: State.Admin,
      action: { payload: { isMultipleInvoiceItemsSelected: boolean; isAllInvoiceItemsSelected: boolean } }
    ) => {
      const { isMultipleInvoiceItemsSelected, isAllInvoiceItemsSelected } = action.payload;
      return {
        ...state,
        invoiceItems: {
          ...state.invoiceItems,
          list: (state.invoiceItems.list as State.InvoiceItemResult[]).map((invoiceItem: State.InvoiceItemResult) => ({
            ...invoiceItem,
            isSelected: isAllInvoiceItemsSelected || !isMultipleInvoiceItemsSelected ? !invoiceItem.isSelected : false,
          })),
        },
      };
    },
  },
  [toggleSelectInvoiceItem]: {
    next: (state: State.Admin, action: { payload: { id: string } }) => {
      return {
        ...state,
        invoiceItems: {
          ...state.invoiceItems,
          list: (state.invoiceItems.list as State.InvoiceItemResult[]).map((invoiceItem: State.InvoiceItemResult) => {
            if (invoiceItem.id === action.payload.id) {
              return { ...invoiceItem, isSelected: !invoiceItem.isSelected };
            }
            return invoiceItem;
          }),
        },
      };
    },
  },
  [toggleSelectedSubscription]: {
    next: (state: State.Admin, action: { payload: { subscriptionName: string } }) => {
      return {
        ...state,
        subscriptionsList: {
          ...state.subscriptionsList,
          list: (state.subscriptionsList.list as State.SubscriptionsResult[]).map(
            (subscription: State.SubscriptionsResult) => {
              if (subscription.subscriptionName === action.payload.subscriptionName) {
                return { ...subscription, isSelected: !subscription.isSelected };
              }
              return subscription;
            }
          ),
        },
      };
    },
  },
  [resetSubscriptions]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        subscriptionsList: {
          ...initialState.subscriptionsList,
        },
      };
    },
  },

  [subscriptionListLoading]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        subscriptionsList: {
          ...state.subscriptionsList,
          loading: true,
        },
      };
    },
  },

  [resetInvoiceItems]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        invoiceItems: {
          ...initialState.invoiceItems,
        },
      };
    },
  },
  [invoiceItemsLoading]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        invoiceItems: {
          ...state.invoiceItems,
          loading: true,
        },
      };
    },
  },

  [getAdminInvoiceFile]: {
    next: (state: State.Admin): State.Admin => ({
      ...state,
      invoices: {
        ...state.invoices,
        downloadingId: null,
        downloadingError: false,
      },
    }),
    throw: (state: State.Admin): State.Admin => ({
      ...state,
      invoices: {
        ...state.invoices,
        downloadingError: true,
      },
    }),
  },
  [setDownloadingId]: {
    next: (state: State.Admin, action: any): State.Admin => ({
      ...state,
      invoices: {
        ...state.invoices,
        downloadingId: action.payload,
        downloadingError: false,
      },
    }),
  },
  [toggleSelectInvoice]: {
    next: (state: State.Admin, action: { payload: { invoiceNumber: string } }) => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          results: state.invoices.results?.map((invoice: State.SearchInvoicesResult) => {
            if (invoice.invoiceNumber === action.payload.invoiceNumber) {
              return { ...invoice, isSelected: !invoice.isSelected };
            }
            return invoice;
          }),
        },
      };
    },
  },
  [toggleSelectAllInvoices]: {
    next: (
      state: State.Admin,
      action: { payload: { isMultipleInvoicesSelected: boolean; isAllInvoicesSelected: boolean } }
    ) => {
      const { isMultipleInvoicesSelected, isAllInvoicesSelected } = action.payload;
      return {
        ...state,
        invoices: {
          results: state.invoices.results?.map((invoice: State.SearchInvoicesResult) => {
            return {
              ...invoice,
              isSelected: isAllInvoicesSelected || !isMultipleInvoicesSelected ? !invoice.isSelected : false,
            };
          }),
        },
      };
    },
  },
  [setSingleInvoiceSelected]: {
    next: (state: State.Admin, action: { payload: { invoiceId: string } }) => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          results: state.invoices.results?.map((invoice: State.SearchInvoicesResult) => {
            if (invoice.id === action.payload.invoiceId) {
              return { ...invoice, isSelected: true };
            }
            return { ...invoice, isSelected: false };
          }),
        },
      };
    },
  },
  [payInvoices]: {
    next: (state: State.Admin, action: Action<State.InvoicesPaymentResponse>): State.Admin => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          pay: {
            result: action.payload,
            loading: false,
            error: null,
          },
        },
      };
    },
    throw: (state: State.Admin, { payload: error }: { payload: CustomErrors.GraphQLError }): State.Admin => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          pay: {
            result: null,
            loading: false,
            error,
          },
        },
      };
    },
  },
  [resetAdminInvoicesResultModule]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          results: [],
          loading: false,
          downloadingId: null,
          downloadingError: false,
        },
      };
    },
  },
  [resetAdminInvoicesPayResultModule]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          pay: {
            result: null,
            loading: false,
            error: null,
          },
        },
      };
    },
  },
  [getB2BOrderPreview]: {
    next: (state: State.Admin, action: { payload: { taxAmount: number } }): State.Admin => ({
      ...state,
      checkout: {
        ...state.checkout,
        salesTax: action.payload,
        loading: false,
      },
    }),
    throw: (state: State.Admin): State.Admin => ({
      ...state,
      checkout: {
        ...state.checkout,
        loading: false,
      },
    }),
  },
  [createB2BOrder]: {
    next: (state: State.Admin, action: { payload: { orderNumber: string } }): State.Admin => ({
      ...state,
      cart: initialState.cart,
      invoiceDetails: initialState.invoiceDetails,
      checkout: {
        ...state.checkout,
        loading: false,
      },
    }),
    throw: (state: State.Admin): State.Admin => ({
      ...state,
      checkout: {
        ...state.checkout,
        loading: false,
      },
    }),
  },
  [checkForAdminRelatedEntities]: {
    next: (state: State.Admin, action: { payload: boolean }): State.Admin => ({
      ...state,
      organizationAdmins: {
        ...state.organizationAdmins,
        adminToRemove: {
          ...(state.organizationAdmins.adminToRemove as State.OrganizationAdminState),
          hasRelatedEntities: action.payload,
          loading: false,
        },
      },
    }),
    throw: (state: State.Admin): State.Admin => ({
      ...state,
      organizationAdmins: {
        ...state.organizationAdmins,
        adminToRemove: {
          ...(state.organizationAdmins.adminToRemove as State.OrganizationAdminState),
          loading: false,
        },
      },
    }),
  },
  [relatedEntitiesLoading]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        organizationAdmins: {
          ...state.organizationAdmins,
          adminToRemove: {
            ...(state.organizationAdmins.adminToRemove as State.OrganizationAdminState),
            loading: !state.organizationAdmins.adminToRemove?.loading,
          },
        },
      };
    },
  },
  [removeAdmin]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        organizationAdmins: {
          ...state.organizationAdmins,
          swapAdminModalOpen: false,
          swapAdminModalLoading: false,
          swapAdminSuccess: true,
        },
      };
    },
    throw: (state: State.Admin): State.Admin => {
      return {
        ...state,
        organizationAdmins: {
          ...state.organizationAdmins,
          swapAdminModalOpen: false,
          swapAdminModalLoading: false,
        },
      };
    },
  },
  [resetCartModule]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        cart: { ...initialState.cart },
      };
    },
  },
  [resetImpersonatedUserPasswordLoading]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        resetImpersonatedUserPassword: {
          ...state.resetImpersonatedUserPassword,
          loading: !state.resetImpersonatedUserPassword.loading,
        },
      };
    },
  },
  [resetImpersonatedUserPassword]: {
    next: (state: State.Admin, action: { payload: { code: string } }): State.Admin => {
      return {
        ...state,
        resetImpersonatedUserPassword: {
          ...state.resetImpersonatedUserPassword,
          code: action.payload.code,
          error: '',
        },
      };
    },
    throw: (state: State.Admin, action: { payload: { error: string } }): State.Admin => {
      return {
        ...state,
        resetImpersonatedUserPassword: {
          ...state.resetImpersonatedUserPassword,
          error: action.payload.error,
        },
      };
    },
  },
  [selectMultipleInvoice]: {
    next: (state: State.Admin, action: any) => {
      const { invoicesSelected } = action.payload;
      return {
        ...state,
        invoicesSelected: [...invoicesSelected],
      };
    },
  },
  [resetLegalEntity]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        legalEntity: null,
      };
    },
  },
  [getTransactionHistory]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        bulkRenewal: {
          ...state.bulkRenewal,
          bulkRenewalHistory: action.payload,
        },
      };
    },
  },
  [getRecentDownloadedReport]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        bulkRenewal: {
          ...state.bulkRenewal,
          reportHistory: action.payload,
        },
      };
    },
  },
  [downloadReport]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        bulkRenewal: {
          ...state.bulkRenewal,
          preSignedUrl: action.payload.preSignedUrl,
        },
      };
    },
  },
  [getRecentTransaction]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        bulkRenewal: {
          ...state.bulkRenewal,
          recentTransaction: action.payload,
        },
      };
    },
  },
  [generateBulkRenewalReport]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        bulkRenewal: {
          ...state.bulkRenewal,
          generateBulkRenewalReport: {
            uniqueID: action.payload.id,
            action: action.payload.action,
            status: action.payload.status,
          },
        },
      };
    },
  },
  [getBulkRenewalAccess]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        bulkRenewal: {
          ...state.bulkRenewal,
          shouldHaveBulkRenewalAccess: action.payload,
        },
      };
    },
  },
  [processRenewal]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        bulkRenewal: {
          ...state.bulkRenewal,
          processBulkRenewal: action.payload,
        },
      };
    },
  },
  [setTransactionHistoryTableLoading]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        bulkRenewal: {
          ...state.bulkRenewal,
          isTransactionHistoryLoading: action.payload,
        },
      };
    },
  },
  [setReportHistoryTableLoading]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        bulkRenewal: {
          ...state.bulkRenewal,
          isReportHistoryLoading: action.payload,
        },
      };
    },
  },
  [resetBulkRenewalState]: {
    next: (state: State.Admin, action: any) => {
      return {
        ...state,
        bulkRenewal: {
          ...initialState.bulkRenewal,
          bulkRenewalHistory: state.bulkRenewal.bulkRenewalHistory,
          reportHistory: state.bulkRenewal.reportHistory,
          shouldHaveBulkRenewalAccess: state.bulkRenewal.shouldHaveBulkRenewalAccess,
          processBulkRenewal: state.bulkRenewal.processBulkRenewal,
          isTransactionHistoryLoading: state.bulkRenewal.isTransactionHistoryLoading,
          isReportHistoryLoading: state.bulkRenewal.isReportHistoryLoading,
        },
      };
    },
  },
  [extensionRaiseCreditMemo]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        creditMemo: {
          productSKU: action.payload.productSKU,
          orderNumber: action.payload.orderNumber,
        },
      };
    },
  },
  [setModalExtendAccessLoading]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        extendAccess: {
          ...state.extendAccess,
          loading: true,
        },
      };
    },
  },
  [openModalExtendAccess]: {
    next: (state: State.Admin, action: Action<Common.ProductItemData>): State.Admin => {
      return {
        ...state,
        extendAccess: {
          loading: false,
          product: action.payload,
        },
      };
    },
  },
  [confirmModalExtendAccess]: {
    next: (state: State.Admin, action: any): State.Admin => {
      return {
        ...state,
        extendAccess: {
          ...state.extendAccess,
          loading: false,
          ...action.payload,
        },
      };
    },
  },
  [resetModalExtendAccess]: {
    next: (state: State.Admin): State.Admin => {
      return {
        ...state,
        extendAccess: {
          loading: false,
        },
      };
    },
  },
};

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions(ACTION_HANDLERS as any, initialState);
