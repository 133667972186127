import React from 'react';
import styled from 'styled-components';

interface Props {
  time: string;
}

export const ConsumeTime: React.SFC<Props> = React.memo(({ time }) => (
  <StyledConsumeTime tabIndex={0} data-testid="consume-time">
    ・{time}
  </StyledConsumeTime>
));

const StyledConsumeTime = styled.small`
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey7};
`;
