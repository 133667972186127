import React from 'react';
import styled from 'styled-components';

interface Props {
  pasteExceed?: boolean;
  charMaxCount?: number;
  charCount?: number;
}

export const SpecialCharacterIndicator: React.FC<Props> = ({ pasteExceed = false, charMaxCount, charCount }) => {
  return (
    <StyledCharCount pasteExceed={pasteExceed}>{!pasteExceed ? `${charCount} / ${charMaxCount}` : ``}</StyledCharCount>
  );
};

const StyledCharCount = styled.span<{ pasteExceed: boolean }>`
  position: relative;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  right: ${props => props.theme.pxToRem(-390)};
`;
