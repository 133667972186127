import React from 'react';
interface Props {
  ssoLink: string;
  htmlText: string;
  submit: boolean;
}

export class PearsonVue extends React.Component<Props> {
  componentDidMount() {
    if (this.props.submit) {
      (document as any).autoForm.submit();
    }
  }

  render() {
    return (
      <form name="autoForm" action={this.props.ssoLink} method="post">
        <div dangerouslySetInnerHTML={{ __html: this.props.htmlText }} />;
      </form>
    );
  }
}
