import { handleActions } from 'redux-actions';
import { attestationPracticeRequirementInitialState } from './constants';
import { getMipAttestationPracticeRequirement } from './action';
import { isServer } from 'utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [getMipAttestationPracticeRequirement]: {
    next: (
      state: State.AttestationPracticeRequirementReference,
      action: any
    ): State.AttestationPracticeRequirementReference => ({
      ...state,
      attestationPracticeReference: action.payload.getMipAttestation[0],
      attestationPracticeFetched: true,
      attestationPracticeFrontloadRequest: isServer,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, attestationPracticeRequirementInitialState);
