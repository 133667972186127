import styled from 'styled-components/macro';
import React from 'react';
import { ReactMarkdownStyled } from './ProductParagraphs';
import { AnchorMarkdownRenderer } from 'components/atoms';

const StyledUl = styled.ul`
  padding-left: ${props => props.theme.pxToRem(18)};
`;

const StyledLi = styled.li`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.57;
`;

const AccordionSubtitle = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s}; /* s = 16 */
  font-weight: ${props => props.theme.fontWeights.regular}; /* regular = 400 */
  line-height: 1.5;
`;

const AccordionTextBlock = styled.div`
  margin-top: ${props => props.theme.pxToRem(12)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
`;

export const AccordionListParagraph: React.FC<{ title: string; listItems: string[] }> = React.memo(
  ({ title, listItems }) => {
    return (
      <>
        <AccordionSubtitle>{title}</AccordionSubtitle>
        <StyledUl>
          {listItems.map((item: string, i: number) => (
            <StyledLi key={i}>{item}</StyledLi>
          ))}
        </StyledUl>
      </>
    );
  }
);

const AccordionReactMarkdownStyled = styled(ReactMarkdownStyled as any)`
  h2 {
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.medium};
  }
`;

export const AccordionTextParagraph: React.FC<{ title: string; contentText: string; summary: string }> = React.memo(
  ({ title, contentText, summary }) => {
    return (
      <>
        <AccordionSubtitle>{title}</AccordionSubtitle>
        {summary && <AccordionTextBlock>{summary}</AccordionTextBlock>}
        <AccordionTextBlock>
          <AccordionReactMarkdownStyled
            renderers={{
              link: AnchorMarkdownRenderer,
            }}
            children={contentText}
          />
        </AccordionTextBlock>
      </>
    );
  }
);
