import { createSelector } from 'reselect';
import { Contentful } from 'mxp-schemas';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.offlinePageContent,
  (offlinePageContent: State.LandingPagesContent): State.LandingPagesContent => offlinePageContent
);

export const offlinePageFetchedSelector = createSelector(
  rootSelector,
  (offlinePageContent: State.LandingPagesContent): boolean => offlinePageContent.landingPageFetched
);

export const offlinePageSelector = createSelector(
  rootSelector,
  (offlinePageContent: State.LandingPagesContent): Contentful.LandingPages.ParsedPage | null =>
    offlinePageContent.landingPage
);

export const skipOfflinePageFrontloadRequestSelector = createSelector(
  rootSelector,
  (offlinePageContent: State.LandingPagesContent): boolean => offlinePageContent.skipLandingPageFrontloadRequest
);
