import { handleActions, Action } from 'redux-actions';
import { initialState, amlActionNames } from './constants';
import { createAML, setIsPdfFlag } from './actions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [amlActionNames.CREATE_AML]: {
    next: (state: State.AMLForms, action: any): State.AMLForms => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    throw: (state: State.AMLForms): State.AMLForms => ({
      ...state,
      isLoading: true,
    }),
  },
  [amlActionNames.GET_AML]: {
    next: (state: State.AMLForms, action: any): State.AMLForms => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    throw: (state: State.AMLForms): State.AMLForms => ({
      ...state,
      isLoading: true,
    }),
  },
  [amlActionNames.IS_LOADING]: (state: State.AMLForms) => ({
    ...state,
    loading: true,
  }),
  [amlActionNames.IS_RENEWAL]: (state: State.AMLForms) => ({
    ...state,
    isRenewal: true,
  }),
  [setIsPdfFlag]: {
    next: (state: State.AMLForms, action: Action<boolean>): State.AMLForms => ({
      ...state,
      isPdf: action.payload,
    }),
  },
};
export { setIsPdfFlag, createAML };
// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions(ACTION_HANDLERS, initialState);

export * from './constants';
export * from './actions';
