import { createSelector } from 'reselect';
import { selectedTopicIdSelector } from '../layouts/selectors';
import { slugIdMapCreator } from './helpers';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.topics,
  topicsRoot => topicsRoot
);

export const topicsFetchedSelector = createSelector(
  rootSelector,
  (topics: State.Topics): boolean => topics.topicsFetched
);

export const topicsHashSelector = createSelector(
  rootSelector,
  (topicsRoot: State.Topics): State.TopicsHash | null => topicsRoot.topicsHash
);

export const topicsSelector = createSelector(
  topicsHashSelector,
  (topicsHash: State.TopicsHash | null): State.Topic[] | null => topicsHash && Object.values(topicsHash)
);

export const topicsSlugIdMapSelector = createSelector(topicsSelector, slugIdMapCreator);

export const topicsSlugsStrSelector = createSelector(
  topicsSlugIdMapSelector,
  (topicsSlugIdMap: { [key: string]: string }): string => Object.keys(topicsSlugIdMap || emptyObject).join('|')
);

export const topicsSortedSelector = createSelector(
  topicsSelector,
  (topics: State.Topic[] | null): State.Topic[] | null =>
    topics && [...topics].sort((a, b) => (a.sort && b.sort && a.sort - b.sort) || -1)
);

export const topicNameByTopicSlugSelectorFactory = (topicSlug: string) =>
  createSelector(
    [topicsHashSelector, topicsSlugIdMapSelector],
    (topicsHash: State.TopicsHash | null, topicsSlugIdMap: { [key: string]: string }): string => {
      if (!topicSlug || !topicsHash || !topicsSlugIdMap) return '';
      const topicId: string = topicsSlugIdMap[topicSlug];
      if (!topicId) return '';
      const topic: State.Topic = topicsHash[topicId];
      return topic ? topic.name : '';
    }
  );

export const selectedTopicNameSelector = createSelector(
  [topicsHashSelector, selectedTopicIdSelector],
  (topicsHash: State.TopicsHash | null, selectedTopicId: string | null): string | null => {
    if (!topicsHash || !selectedTopicId || !topicsHash[selectedTopicId]) return null;
    return topicsHash[selectedTopicId].name;
  }
);

export const subtopicsHashSelector = createSelector(
  topicsSelector,
  (topics: State.Topic[] | null): State.SubtopicsHash | null => {
    if (!topics) return null;
    return topics.reduce((acc: State.SubtopicsHash, topic: State.Topic) => ({ ...acc, ...topic.subtopics }), {});
  }
);

export const subtopicsSelector = createSelector(subtopicsHashSelector, (subtopicsHash: State.SubtopicsHash | null):
  | State.Subtopic[]
  | null => {
  return subtopicsHash && Object.values(subtopicsHash);
});

export const subtopicsSlugIdMapSelector = createSelector(subtopicsSelector, slugIdMapCreator);

export const subtopicsHashByTopicIdSelectorFactory = (topicId: string | null) =>
  createSelector(topicsHashSelector, (topicsHash: State.TopicsHash | null): State.SubtopicsHash | null => {
    if (!topicsHash || !topicId || !topicsHash[topicId]) return null;
    return topicsHash[topicId].subtopics;
  });
