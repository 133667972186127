import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { ReactComponent as IconCheckCircle } from 'resources/images/icon-dev-ic-check-circle.svg';
import { Button, ButtonEnums, NotificationBanner, NotificationBannerEnums } from 'components/atoms';
import { getPath } from 'utils';
import { Routes, MembershipPackageAccordionStatus } from 'constants/index';

export enum PersonOrganisationsCards_STATUS {
  CAN_APPLY = 'CanApply',
  CONTACT = 'Contact',
  ALREADY_MEMBER = 'AlreadyMember',
  NEEDS_PARTNER = 'NeedsPartner',
}

interface Props {
  centerMembershipType?: string;
  company: string;
  employer: {
    city: string;
    street: string;
    state: string;
    cityCode: string;
    phoneNumber: string;
  };
  admin?: {
    name: string;
    email: string;
  };
  status: string;
  isUnderReview?: boolean;
  handleProceed?: () => void;
  handleCentersTeamModal?: () => void;
  handleInvite?: () => void;
  handleDeclare?: () => void;
  isOrgNotUS?: boolean;
}

export const PersonOrganisationsCards: React.FC<Props> = ({
  centerMembershipType = 'pcps',
  company,
  employer,
  admin,
  status,
  isUnderReview,
  handleProceed,
  handleCentersTeamModal,
  handleInvite,
  handleDeclare,
  isOrgNotUS,
}) => {
  const destinationURL = getPath(Routes.CENTER_MEMBERSHIP_FORM);
  return (
    <MainContainer cardMembershipType={status}>
      <StyledDiv>
        <StyledCompany>
          {company} {isUnderReview && <StyledUnderReview>{`Under Review`}</StyledUnderReview>}
        </StyledCompany>
        <TitleDivider />
        {status === PersonOrganisationsCards_STATUS.CONTACT && !isOrgNotUS && (
          <StyledNotificationBanner
            testId="organization-card-contact"
            childrenTestId="organization-card-contact"
            variant={NotificationBannerEnums.variant.blue}
            icon={<StyledIconError />}
          >
            Please contact the {centerMembershipType.toUpperCase()} team to apply for a new{' '}
            {centerMembershipType.toUpperCase()} membership.
          </StyledNotificationBanner>
        )}
        {status === PersonOrganisationsCards_STATUS.ALREADY_MEMBER && (
          <StyledNotificationBanner
            testId="organization-card-already-member"
            childrenTestId="organization-already-member"
            variant={NotificationBannerEnums.variant.green}
            icon={<StyledIconCheckCircle />}
          >
            This firm/organization is already a member of <StyledBold>{centerMembershipType.toUpperCase()}</StyledBold>
          </StyledNotificationBanner>
        )}
        {status === PersonOrganisationsCards_STATUS.NEEDS_PARTNER && (
          <StyledNotificationBanner
            testId="organization-card-needs-partner"
            childrenTestId="organization-needs-partner"
            variant={NotificationBannerEnums.variant.green}
            icon={<StyledIconCheckCircle />}
          >
            You must be a partner/owner to apply for <StyledBold>{centerMembershipType.toUpperCase()}</StyledBold>
          </StyledNotificationBanner>
        )}

        <StyledBox>
          <StyledCardHeader>
            <StyledCardTitle>Employer</StyledCardTitle>
          </StyledCardHeader>
          <StyledCardText isTextTitle={false}>{employer.street}</StyledCardText>
          <StyledCardText isTextTitle={false}>{employer.city}</StyledCardText>
          <StyledCardText isTextTitle={false}>{`${employer.state} ${employer.cityCode}`}</StyledCardText>
          <StyledCardText isTextTitle={false}>{employer.phoneNumber}</StyledCardText>
        </StyledBox>
      </StyledDiv>

      <StyledDiv>
        {status === PersonOrganisationsCards_STATUS.ALREADY_MEMBER && (
          <StyledBox isAdmin={true}>
            <StyledCardHeader>
              <StyledCardTitle>Administrator</StyledCardTitle>
              <StyledTag>{centerMembershipType.toUpperCase()}</StyledTag>
            </StyledCardHeader>
            <StyledCardText isTextTitle={true}>{admin?.name}</StyledCardText>
            <StyledCardText isTextTitle={false}>{admin?.email}</StyledCardText>
          </StyledBox>
        )}
        <StyledButtonContainer>
          {status === PersonOrganisationsCards_STATUS.CAN_APPLY && (
            <StyledButton
              variant={ButtonEnums.variants.primary}
              bordered
              testId="test-id"
              onClick={handleProceed}
              to={`${destinationURL}#${MembershipPackageAccordionStatus.CPA}/${company}`}
            >
              Proceed with this selection
            </StyledButton>
          )}
          {status === PersonOrganisationsCards_STATUS.CONTACT && (
            <StyledButton
              variant={ButtonEnums.variants.primary}
              bordered
              testId="test-id"
              to={`${destinationURL}#${MembershipPackageAccordionStatus.CPA}/${company}`}
              onClick={handleCentersTeamModal}
            >
              {isOrgNotUS ? 'Proceed with this selection' : `Contact ${centerMembershipType.toUpperCase()} Team`}
            </StyledButton>
          )}
          {status === PersonOrganisationsCards_STATUS.NEEDS_PARTNER && (
            <StyledButton
              variant={ButtonEnums.variants.primary}
              bordered
              testId="test-id"
              onClick={handleInvite}
              to={`${destinationURL}/${company}`}
            >
              Send an invitation to a partner/owner
            </StyledButton>
          )}
          {status === PersonOrganisationsCards_STATUS.NEEDS_PARTNER && (
            <div style={{ marginTop: '8px' }}>
              <StyledButton
                variant={ButtonEnums.variants.secondary}
                bordered
                testId="test-id"
                onClick={handleDeclare}
                to={`${destinationURL}/${company}`}
              >
                I declare myself as partner/owner
              </StyledButton>
            </div>
          )}
        </StyledButtonContainer>
      </StyledDiv>
    </MainContainer>
  );
};

interface MainContainerProps {
  cardMembershipType?: string;
}

interface StyledBoxProps {
  isAdmin?: boolean;
}

interface StyledCardTextProps {
  isTextTitle: boolean;
}

const StyledUnderReview = styled.span`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.neutralWhite};
  background-color: ${props => props.theme.colors.secondaryOrange};
  padding: ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(10)};
  border-radius: ${props => props.theme.pxToRem(4)};
  margin-left: ${props => props.theme.pxToRem(6)};
  vertical-align: top;
  white-space: nowrap;
  box-sizing: border-box;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
`;

const MainContainer = styled.div<MainContainerProps>`
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.neutralWhite};
  padding: ${props => props.theme.pxToRem(36)} 0 ${props => props.theme.pxToRem(32)};
  display: flex;
  flex-direction: row;
  justify-content: space-space-evenly;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 95%;
  height: auto;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
  }
`;

const StyledDiv = styled.div`
  box-sizing: border-box;
  width: 47%;
  padding-left: ${props => props.theme.pxToRem(20)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(5)};
    width: 100%;
  }
`;

const TitleDivider = styled.div`
  margin-top: ${prop => prop.theme.pxToRem(25)};
`;

const StyledButtonContainer = styled.div`
  float: right;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledBox = styled.div<StyledBoxProps>`
  box-sizing: border-box;
  margin-top: ${props => props.theme.pxToRem(5)};
  min-height: ${props => props.theme.pxToRem(270)};
  min-width: ${props => props.theme.pxToRem(300)};
  background: ${props => props.theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(4)};
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${props => props.theme.colors.neutralGrey3};

  ${({ isAdmin }) =>
    isAdmin &&
    css`
      margin-top: ${prop => prop.theme.pxToRem(144)};
    `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(5)};
    width: 100%;
    height: auto;
  }
`;

const StyledCardHeader = styled.div`
  border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  height: ${props => props.theme.pxToRem(60)};
  margin: 0 0 ${props => props.theme.pxToRem(1)};
  padding-top: ${props => props.theme.pxToRem(20)};
  padding-left: ${props => props.theme.pxToRem(25)};
  width: auto;
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledCardTitle = styled.label`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
`;

const StyledBold = styled.label`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const StyledCardText = styled.p<StyledCardTextProps>`
  margin-top: ${props => props.theme.pxToRem(7)};
  margin-left: ${props => props.theme.pxToRem(25)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isTextTitle }) =>
    isTextTitle
      ? css`
          font-weight: ${props => props.theme.fontWeights.bold};
          font-size: ${props => props.theme.fontSizes.s};
          line-height: 1.5;
        `
      : css`
          font-weight: ${props => props.theme.fontWeights.light};
          font-size: ${props => props.theme.fontSizes.xs};
          line-height: 1.57;
        `}
`;

const StyledCompany = styled.p`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 1.33;

  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: center;
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const StyledTag = styled.span`
  min-height: ${props => props.theme.pxToRem(25)};
  min-width: ${props => props.theme.pxToRem(65)};
  font-size: ${props => props.theme.fontSizes.s};
  background-color: ${props => props.theme.colors.neutralGrey1};
  font-weight: ${props => props.theme.fontWeights.bold};
  text-align: center;
  border-radius: ${props => props.theme.pxToRem(2)};
  opacity: 0.95;
  backdrop-filter: blur(2px);
  margin-left: ${props => props.theme.pxToRem(20)};
`;

const StyledIconError = styled(IconError)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  display: inline;
  transform: rotateX(180deg);
`;

const StyledIconCheckCircle = styled(IconCheckCircle)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  display: inline;
`;

const StyledButton = styled(Button)`
  &&&&& {
    object-fit: contain;
    font-size: ${props => props.theme.fontSizes.xs};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      font-size: ${props => props.theme.fontSizes.xxs};
    }
  }
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-top: ${props => props.theme.pxToRem(15)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;
