import React, { useState } from 'react';
import { Container, NotificationBanner, NotificationBannerEnums } from 'components/atoms';
import styled from 'styled-components';
import { getPath } from 'utils';
import { Routes, adminSearchInvoiceScreens } from 'constants/index';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { SearchInvoicesForm } from 'components/admin/invoices/SearchInvoicesForm';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { AdminInvoiceSearchBackButton } from 'components/admin/atoms/AdminInvoiceSearchBackButton/AdminInvoiceSearchBackButton';
import { resetLegalEntity, searchInvoices, setLegalEntity } from 'modules/admin/actions';
import { isFirmBillingSelector } from 'modules/admin/selectors';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { ProfileHeading } from 'components/molecules';
import { ZuoraTypes } from 'mxp-schemas';
import { HeaderSteps } from 'components/molecules/HeaderSteps/HeaderSteps';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';

export const AdminInvoicesSearchPage: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const isFirmBilling = useSelector(isFirmBillingSelector);
  const onSubmit = ({
    uid,
    legalEntity,
    invoiceNumber,
  }: {
    uid: string;
    legalEntity: string;
    invoiceNumber: string;
  }) => {
    setShowError(false);
    setSearchTerm(uid || invoiceNumber);
    dispatch(setLegalEntity(legalEntity));

    return dispatch(searchInvoices(uid, legalEntity, invoiceNumber)).then(
      (action: { payload: ZuoraTypes.InvoiceTableRow[] | null }) => {
        if (!action.payload && uid) {
          setShowError(true);
          dispatch(resetLegalEntity());
          return;
        }

        dispatch(
          push({
            pathname: generatePath(getPath(Routes.ADMIN_INVOICES_SEARCH_RESULTS), {
              searchTerm: uid || invoiceNumber,
              ...(!invoiceNumber
                ? {
                    legalEntity,
                  }
                : { legalEntity: action.payload?.[0]?.legalEntity }),
            }),
          })
        );
      }
    );
  };

  return (
    <>
      <HeaderSteps stepIndex={0} labels={adminSearchInvoiceScreens} />
      <Container>
        {isFirmBilling ? (
          <AdminInvoiceSearchBackButton text="Back to invoice" to={getPath(Routes.ADMIN_INVOICES)} />
        ) : (
          <PreviousPage backText="Back to invoice" />
        )}
        <StyledProfileHeading title="Search Invoice" />
        {showError && (
          <StyledNotificationBanner
            variant={NotificationBannerEnums.variant.red}
            testId="no-invoices-found-notification-banner"
            childrenTestId="notification-children"
            icon={<StyledIconError />}
          >
            Sorry no invoices found for {searchTerm}. Please try again.
          </StyledNotificationBanner>
        )}
        <SearchInvoicesForm onSubmit={onSubmit} submitText="Search" />
      </Container>
    </>
  );
};

const StyledProfileHeading = styled(ProfileHeading)`
  margin-top: ${props => props.theme.pxToRem(-8)};
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(25)};
`;

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;
