import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Loader, CheckboxProps as SemanticUICheckboxProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { InquiryFormBulkOrderSearchInput } from './InquiryFormBulkOrderSearchInput';
import { InquiryFormBulkOrderAddressFields } from './InquiryFormBulkOrderAddressFields';
import { InputWithValidation } from 'components/molecules';
import { Divider, Checkbox, RadioButton, Link } from 'components/atoms';
import { getFlattendCardVariantLabel } from 'utils/productItemHelpers';
import { extractContentCardItemPrices } from 'utils/priceHelpers';
import { formatPriceToTwoDecimalPoints, fromCentsFormat } from 'utils';
import { ExtLinks } from 'constants/index';
import { InquiryForm, Product, User as UserTypes } from 'mxp-schemas';
import { Utils } from 'mxp-utils';
import { UserMemberTypes } from 'modules/user/constants';

interface InquiryFormBulkOrderProps {
  form: InquiryForm.BulkOrder;
  setForm: (form: InquiryForm.BulkOrder) => void;
  setFormValid: (valid: boolean) => void;
  onFormStart: () => void;
  fetchProductSearchResults: (searchQuery: string) => void;
  updateSearchSelection: (searchSelection: State.InquiryFormSearchResult | null) => void;
  fetchProductItem: (slug: string) => void;
  getMarketingPreferences: () => void;
  searchLoading: boolean;
  searchResults: State.InquiryFormSearchResult[];
  searchSelection: State.InquiryFormSearchResult | null;
  productItem: Product.ProductItem;
  productItemFilteredVariants: Product.Variant[];
  isProductFetched: boolean;
  marketingPreferences: UserTypes.MarketingPreferences;
  marketingPreferencesLoading: boolean;
  marketingPreferencesError: State.UserError | null;
}

export const InquiryFormBulkOrder: React.FC<InquiryFormBulkOrderProps> = ({
  form,
  setForm,
  setFormValid,
  onFormStart,
  fetchProductSearchResults,
  updateSearchSelection,
  fetchProductItem,
  getMarketingPreferences,
  searchLoading,
  searchResults,
  searchSelection,
  productItem,
  productItemFilteredVariants,
  isProductFetched,
  marketingPreferences,
  marketingPreferencesLoading,
  marketingPreferencesError,
}) => {
  const [showNoResultsMessage, setShowNoResultsMessage] = useState(false);

  const [searchString, setSearchString] = useState('');

  React.useEffect(() => {
    getMarketingPreferences();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!marketingPreferencesLoading && !marketingPreferencesError) {
      setForm({ ...form, marketingOptIn: marketingPreferences.isSubscribedToMarketing });
    }
  }, [marketingPreferences, marketingPreferencesLoading, marketingPreferencesError]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateSearchString = React.useCallback(
    (value: string) => {
      setSearchString(value);
    },
    [setSearchString]
  );

  const MIN_TOTAL_QUANTITY: number = 2;
  const MAX_TOTAL_QUANTITY: number = 5;

  const { productSlug } = useParams();

  const productId: string = productItem?.productId || '';

  React.useEffect(() => {
    if (productSlug) {
      fetchProductItem(productSlug);
    }
  }, [productSlug, fetchProductItem]);

  React.useEffect(() => {
    if (productItem && productSlug && productItem.slug === productSlug) {
      const selection: State.InquiryFormSearchResult = {
        title: productItem.name,
        id: productItem.productId,
        slug: productItem.slug || '',
        description: Product.PRODUCT_TYPES_NAMES[productItem.productType],
      };
      updateSearchString(selection.title);
      updateSearchSelection(selection);
    }
  }, [productItem, productSlug, updateSearchString, updateSearchSelection]);

  const emptyAddress: State.Address = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  };
  const [shippingAddress, setShippingAddress] = useState({ ...emptyAddress });
  const [isShippingAddressValid, setShippingAddressValid] = useState(false);
  const [billingAddress, setBillingAddress] = useState({ ...emptyAddress });
  const [isBillingAddressValid, setBillingAddressValid] = useState(true);
  const [useShippingAddressForBilling, setUseShippingAddressForBilling] = useState(true);

  const [selectedVariantMemberPrice, setSelectedVariantMemberPrice] = useState(0);
  const [selectedVariantNonMemberPrice, setSelectedVariantNonMemberPrice] = useState(0);

  const memberQuantity: number = parseInt(form.memberQuantity || '0', 10);
  const nonMemberQuantity: number = parseInt(form.nonMemberQuantity || '0', 10);
  const totalMemberAndNonMemberQuantity = memberQuantity + nonMemberQuantity;
  const subTotal: string = formatPriceToTwoDecimalPoints(
    memberQuantity * selectedVariantMemberPrice + nonMemberQuantity * selectedVariantNonMemberPrice
  );

  const isVariantSkuEmpty = !Boolean(form.variantSku.length);
  const isTotalValid = Boolean(
    totalMemberAndNonMemberQuantity >= MIN_TOTAL_QUANTITY && totalMemberAndNonMemberQuantity <= MAX_TOTAL_QUANTITY
  );
  const isMemberQuantityEmpty = !Boolean(form.memberQuantity.length);
  const isMemberQuantityValid =
    Utils.isNumericString(form.memberQuantity) &&
    memberQuantity >= 0 &&
    memberQuantity <= MAX_TOTAL_QUANTITY &&
    isTotalValid;
  const isNonMemberQuantityEmpty = !Boolean(form.nonMemberQuantity.length);
  const isNonMemberQuantityValid =
    Utils.isNumericString(form.nonMemberQuantity) &&
    nonMemberQuantity >= 0 &&
    memberQuantity <= MAX_TOTAL_QUANTITY &&
    isTotalValid;

  const isTitleEmpty = !Boolean(form.title.length);
  const isTitleValid = Utils.isShorterThan(form.title, InquiryForm.FieldLengths.TITLE);
  const isCompanyEmpty = !Boolean(form.company.length);
  const isCompanyValid = Utils.isShorterThan(form.company, InquiryForm.FieldLengths.COMPANY);
  const isPhoneEmpty = !Boolean(form.phone.length);
  const isPhoneValid = Utils.isShorterThan(form.phone, 255);

  React.useEffect(() => {
    const formValid =
      !isVariantSkuEmpty &&
      isTotalValid &&
      (isMemberQuantityEmpty || isMemberQuantityValid) &&
      (isNonMemberQuantityEmpty || isNonMemberQuantityValid) &&
      !isTitleEmpty &&
      isTitleValid &&
      !isCompanyEmpty &&
      isCompanyValid &&
      !isPhoneEmpty &&
      isPhoneValid &&
      isShippingAddressValid &&
      (useShippingAddressForBilling || isBillingAddressValid);
    setFormValid(formValid);
  }, [
    form,
    isVariantSkuEmpty,
    isTotalValid,
    isMemberQuantityEmpty,
    isMemberQuantityValid,
    isNonMemberQuantityEmpty,
    isNonMemberQuantityValid,
    isTitleEmpty,
    isTitleValid,
    isCompanyEmpty,
    isCompanyValid,
    isPhoneEmpty,
    isPhoneValid,
    isShippingAddressValid,
    useShippingAddressForBilling,
    isBillingAddressValid,
    setFormValid,
  ]);

  const getVariantPrice = (
    variantSku: string,
    membershipType: string,
    membershipId: string
  ): { price: number | undefined; currency: string | undefined } | null => {
    const selectedVariant = productItemFilteredVariants.find((variant: Product.Variant) => variant.sku === variantSku);

    const isBulkOrder = true;

    const prices = selectedVariant?.prices
      ? extractContentCardItemPrices(selectedVariant.prices, membershipType, [membershipId], isBulkOrder)
      : null;

    if (prices && !prices.startPrice && prices.endPrice) {
      return { price: fromCentsFormat(prices.endPrice), currency: prices?.endCurrency };
    }
    if (prices && prices.startPrice && !prices.endPrice) {
      return { price: fromCentsFormat(prices.startPrice), currency: prices?.startCurrency };
    }
    if (prices && prices.startPrice && prices.endPrice) {
      return membershipType === UserMemberTypes.MEMBER
        ? { price: fromCentsFormat(prices.endPrice), currency: prices?.endCurrency }
        : { price: fromCentsFormat(prices.startPrice), currency: prices?.startCurrency };
    }
    return null;
  };

  const [debounceSearchResults] = useDebouncedCallback(
    (search: string) => {
      setShowNoResultsMessage(true);
      fetchProductSearchResults(search.trim());
    },
    500,
    { maxWait: 2000 }
  );

  const handleSearchSelectionChange = React.useCallback(
    (selection: State.InquiryFormSearchResult) => {
      updateSearchSelection(selection);
      fetchProductItem(selection.slug);
    },
    [updateSearchSelection, fetchProductItem]
  );

  const handleClearSearch = () => {
    if (searchSelection) {
      updateSearchSelection(null);
    }
    setForm({
      ...form,
      variantSku: '',
      memberQuantity: '',
      nonMemberQuantity: '',
      memberPrice: '',
      nonMemberPrice: '',
    });
  };

  const concatenateAddress = (address: State.Address) => Object.values(address).filter(Boolean).join(', ') || '';

  React.useEffect(() => {
    const actualBillingAddress: State.Address = useShippingAddressForBilling ? shippingAddress : billingAddress;
    setForm({
      ...form,
      shippingAddress: concatenateAddress(shippingAddress),
      billingAddress: concatenateAddress(actualBillingAddress),
    });
  }, [billingAddress, shippingAddress, useShippingAddressForBilling]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleUseShippingAddressForBilling = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      setUseShippingAddressForBilling(Boolean(data.checked));
    },
    [setUseShippingAddressForBilling]
  );

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, title: event.target.value });
  };
  const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, company: event.target.value });
  };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, phone: event.target.value });
  };
  const handleVariantSelectionChange =
    (sku: string, memberPrice: number | undefined, nonMemberPrice: number | undefined) => async () => {
      if (form.variantSku !== sku) {
        setSelectedVariantMemberPrice(memberPrice || 0);
        setSelectedVariantNonMemberPrice(nonMemberPrice || 0);

        setForm({
          ...form,
          variantSku: sku,
          memberQuantity: '',
          nonMemberQuantity: '',
          memberPrice: memberPrice ? memberPrice : '',
          nonMemberPrice: nonMemberPrice ? nonMemberPrice : '',
        });
      }
    };
  const handleMemberQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, memberQuantity: event.target.value ? parseInt(event.target.value, 10).toString() : '' });
  };
  const handleNonMemberQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, nonMemberQuantity: event.target.value ? parseInt(event.target.value, 10).toString() : '' });
  };

  const fieldMaxLength = (length: number) => `Sorry, that's too long. Must be fewer than ${length} characters.`;

  const telephone: string = '1-800-634-6780';

  const registrationTelephone: string = '1-888-777-7077';

  return (
    <>
      <StyledSubtitle isFirst>
        To place an order to attend a live event within the next 2 business days, please call us at{' '}
        <Link isExternal testId="bulk-order-registration-phone-link" to={`tel:${registrationTelephone}`}>
          {registrationTelephone}
        </Link>{' '}
        to ensure registration.
      </StyledSubtitle>
      <StyledSubtitle>
        Looking to register 6 or more people? Contact our Group Sales team at{' '}
        <Link isExternal testId="bulk-order-phone-link" to={`tel:${telephone}`}>
          {telephone}
        </Link>{' '}
        (option 1) or send us a{' '}
        <Link isExternal testId="bulk-order-note-link" to={`${ExtLinks.BULK_PURCHASE_LINK}${productId}`}>
          note
        </Link>
        .
      </StyledSubtitle>
      <StyledFieldGroupHeadingContainer>
        <StyledFieldGroupHeading>Select your product</StyledFieldGroupHeading>
      </StyledFieldGroupHeadingContainer>
      <InquiryFormBulkOrderSearchInput
        placeholder="Enter the title or keyword"
        searchResults={searchResults}
        isResultSelected={Boolean(searchSelection)}
        searchLoading={searchLoading}
        showNoResultsMessage={showNoResultsMessage}
        searchString={searchString}
        setSearchString={updateSearchString}
        onFocus={onFormStart}
        fetchResults={debounceSearchResults}
        handleResultSelection={handleSearchSelectionChange}
        handleClearSelection={handleClearSearch}
      />
      {searchSelection && !isProductFetched ? (
        <StyledContentLoading>
          <StyledLoader active />
        </StyledContentLoading>
      ) : (
        <div>
          {searchSelection && isProductFetched && (
            <>
              {productItemFilteredVariants.length ? (
                <>
                  <StyledVariantGroupHeading>Select from the following options</StyledVariantGroupHeading>
                  {productItemFilteredVariants.map((variant: Product.Variant, index: number) => {
                    const sku: string = variant.sku || '';

                    const memberPrice = getVariantPrice(
                      sku,
                      UserMemberTypes.MEMBER,
                      UserTypes.MembershipIdsEnum.MRUSR0001
                    );
                    const nonMemberPrice = getVariantPrice(
                      sku,
                      UserMemberTypes.NONMEMBER,
                      UserTypes.MembershipIdsEnum.NON_MEMBER
                    );

                    const isSelected: boolean = sku === form.variantSku;

                    const variantLabel = getFlattendCardVariantLabel(variant, productItem.productType);

                    const variantPriceLabel: string =
                      memberPrice?.price && nonMemberPrice?.price
                        ? memberPrice?.price === nonMemberPrice?.price
                          ? formatPriceToTwoDecimalPoints(memberPrice?.price, memberPrice?.currency)
                          : `${formatPriceToTwoDecimalPoints(
                              Math.min(memberPrice?.price, nonMemberPrice?.price),
                              memberPrice?.currency === nonMemberPrice?.currency ? memberPrice?.currency : undefined // We are expecting the currencies to match, this is just an additional check
                            )} - ${formatPriceToTwoDecimalPoints(
                              Math.max(memberPrice?.price, nonMemberPrice?.price),
                              memberPrice?.currency === nonMemberPrice?.currency ? memberPrice?.currency : undefined // We are expecting the currencies to match, this is just an additional check
                            )}`
                        : memberPrice?.price
                        ? formatPriceToTwoDecimalPoints(memberPrice?.price, memberPrice?.currency)
                        : nonMemberPrice?.price
                        ? formatPriceToTwoDecimalPoints(nonMemberPrice?.price, nonMemberPrice?.currency)
                        : '';

                    return (
                      <div key={index}>
                        <FlexWrapRadio
                          onClick={handleVariantSelectionChange(sku, memberPrice?.price, nonMemberPrice?.price)}
                        >
                          <StyledRadioButton checked={isSelected} />
                          <StyledOptionText checked={isSelected}>
                            {variantLabel} <StyledBold>({variantPriceLabel})</StyledBold>
                          </StyledOptionText>
                        </FlexWrapRadio>
                        {index !== productItemFilteredVariants.length - 1 && <StyledVariantsDivider />}
                      </div>
                    );
                  })}
                </>
              ) : (
                <StyledNoVariantsText>This product is not available at the moment.</StyledNoVariantsText>
              )}
              {form.variantSku && (
                <StyledQuantityContainer>
                  <StyledQuantityInput
                    labelName={`Number of AICPA members to enroll (${form.memberPrice}/each)`}
                    name={InquiryForm.FieldIds.MEMBER_QUANTITY}
                    type="number"
                    value={form.memberQuantity}
                    testId={InquiryForm.FieldIds.MEMBER_QUANTITY}
                    labelId={InquiryForm.FieldIds.MEMBER_QUANTITY}
                    onChange={handleMemberQuantityChange}
                    isCorrect={isMemberQuantityValid || isMemberQuantityEmpty}
                    errorMessage={`Total member + nonmember quantity must be between 2 and 5`}
                  />
                  <StyledQuantityInput
                    labelName={`Number of nonmembers to enroll (${form.nonMemberPrice}/each)`}
                    name={InquiryForm.FieldIds.NON_MEMBER_QUANTITY}
                    type="number"
                    value={form.nonMemberQuantity}
                    testId={InquiryForm.FieldIds.NON_MEMBER_QUANTITY}
                    labelId={InquiryForm.FieldIds.NON_MEMBER_QUANTITY}
                    onChange={handleNonMemberQuantityChange}
                    isCorrect={isNonMemberQuantityValid || isNonMemberQuantityEmpty}
                    errorMessage={`Total member + nonmember quantity must be between 2 and 5`}
                  />
                </StyledQuantityContainer>
              )}
              {!!totalMemberAndNonMemberQuantity && (
                <StyledSubTotalWrapper>
                  <StyledSubTotal>Subtotal (excludes sales tax)</StyledSubTotal>
                  <StyledSubTotal>
                    <StyledBold>{subTotal}</StyledBold>
                  </StyledSubTotal>
                  <StyledSubTotalFootnote>
                    Sales tax will be applied once your order has been processed.
                  </StyledSubTotalFootnote>
                </StyledSubTotalWrapper>
              )}
            </>
          )}
        </div>
      )}
      <StyledDivider />
      <StyledFieldGroupHeadingContainer>
        <StyledFieldGroupHeading>Shipping address</StyledFieldGroupHeading>
        <StyledFieldGroupSubheading>
          Your shipping address may affect your final price depending on whether you need to pay sales tax.
        </StyledFieldGroupSubheading>
      </StyledFieldGroupHeadingContainer>
      <InquiryFormBulkOrderAddressFields
        addressType={'shipping-address'}
        address={shippingAddress}
        setAddress={setShippingAddress}
        setAddressValid={setShippingAddressValid}
      />
      <Divider />
      <StyledFieldGroupHeadingContainer>
        <StyledFieldGroupHeading>Billing address</StyledFieldGroupHeading>
        <StyledFieldGroupSubheading>Your billing address will be shown on your invoice.</StyledFieldGroupSubheading>
      </StyledFieldGroupHeadingContainer>
      <StyledCheckbox
        width="18"
        height="18"
        type="checkbox"
        testId={InquiryForm.FieldIds.BULK_ORDER_USE_SHIPPING_ADDRESS_FOR_BILLING}
        label={'Use same as shipping address'}
        checked={useShippingAddressForBilling}
        onChange={toggleUseShippingAddressForBilling}
      />
      {!useShippingAddressForBilling && (
        <InquiryFormBulkOrderAddressFields
          addressType={'billing-address'}
          address={billingAddress}
          setAddress={setBillingAddress}
          setAddressValid={setBillingAddressValid}
        />
      )}
      <Divider />
      <StyledFieldGroupHeadingContainer>
        <StyledFieldGroupHeading>My details</StyledFieldGroupHeading>
        <StyledFieldGroupSubheading>
          Please provide the information below to complete your order.
        </StyledFieldGroupSubheading>
      </StyledFieldGroupHeadingContainer>
      <InputWithValidation
        labelName={'Job Title'}
        name={InquiryForm.FieldIds.TITLE}
        type="text"
        value={form.title}
        testId={InquiryForm.FieldIds.TITLE}
        labelId={InquiryForm.FieldIds.TITLE}
        onChange={handleTitleChange}
        isCorrect={isTitleValid || isTitleEmpty}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.TITLE)}`}
      />
      <InputWithValidation
        labelName={'Name of Organization'}
        name={InquiryForm.FieldIds.COMPANY}
        type="text"
        value={form.company}
        testId={InquiryForm.FieldIds.COMPANY}
        labelId={InquiryForm.FieldIds.COMPANY}
        onChange={handleCompanyChange}
        isCorrect={isCompanyValid || isCompanyEmpty}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.COMPANY)}`}
      />
      <InputWithValidation
        labelName={'Telephone Number'}
        name={InquiryForm.FieldIds.PHONE}
        type="text"
        value={form.phone}
        testId={InquiryForm.FieldIds.PHONE}
        labelId={InquiryForm.FieldIds.PHONE}
        onChange={handlePhoneChange}
        isCorrect={isPhoneValid || isPhoneEmpty}
        errorMessage={''}
      />
    </>
  );
};

const StyledContentLoading = styled.div`
  > div {
    margin: 0 auto;
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: initial;
    }
  }
`;

const StyledLoader = styled(Loader)`
  &&&&&& {
    position: relative;
    margin-top: ${props => props.theme.pxToRem(30)};
  }
`;

const StyledSubtitle = styled.div<{ isFirst?: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-top: ${props => (props.isFirst ? 0 : props.theme.pxToRem(18))};
`;

const StyledFieldGroupHeadingContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(39)};
  margin-bottom: ${props => props.theme.pxToRem(32)};
`;

const StyledFieldGroupHeading = styled.h2`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(8)};
  }
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledFieldGroupSubheading = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledVariantGroupHeading = styled.h3`
  margin-top: ${props => props.theme.pxToRem(33)} !important;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledSubTotalWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(16)};
`;

const StyledSubTotal = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledSubTotalFootnote = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const FlexWrapRadio = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(11)};
  :hover {
    font-weight: ${props => props.theme.fontWeights.bold};
    cursor: pointer;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  margin: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(12)} 0 0;
  div {
    height: ${props => props.theme.pxToRem(8)};
    width: ${props => props.theme.pxToRem(8)};
  }
`;

const StyledOptionText = styled.div<{ checked?: boolean }>`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  margin-top: ${props => props.theme.pxToRem(11)};
  font-weight: ${props => (props.checked ? props.theme.fontWeights.medium : props.theme.fontWeights.light)};
  :hover {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
`;

const StyledBold = styled.b`
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledVariantsDivider = styled(Divider)`
  &&& {
    margin: 0;
  }
`;

const StyledNoVariantsText = styled.div`
  color: ${props => props.theme.colors.interfaceRed};
  margin-top: ${props => props.theme.pxToRem(4)};
  font-size: ${props => props.theme.fontSizes.xxs};
`;

const StyledQuantityContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(28)};
`;

const StyledQuantityInput = styled(InputWithValidation)`
  &&&&&& {
    width: ${props => props.theme.pxToRem(100)};

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const StyledDivider = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(41)};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  &&&&& {
    object-fit: contain;
    line-height: 1;
    margin-top: ${props => props.theme.pxToRem(5)};
    margin-bottom: ${props => props.theme.pxToRem(27)};

    > label {
      font-size: ${props => props.theme.fontSizes.xs};
      color: ${props => props.theme.colors.neutralGrey8};
      line-height: 1.57;
      font-weight: ${props => props.theme.fontWeights.light};
      padding-left: ${props => props.theme.pxToRem(24)};
    }
  }
`;
