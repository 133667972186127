import React, { useState } from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { Button, ButtonEnums, Container, Grid, ButtonLink, OnlyMobileCSS, OnlyDesktopCSS } from 'components/atoms';
import { ContentCard } from 'components/molecules/ContentCard/ContentCard';
import { ReactComponent as ExpandMore } from 'resources/images/ic-expand-more.svg';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';
import { Product } from 'mxp-schemas';
import { ContentCardsCarousel } from '../ContentCardsCarousel';
import { isMobileViewPort } from 'utils';

interface Props {
  bundleProducts: State.ContentCardItem[];
  contentRef?: () => void;
}

export const BundleProductsBlock: React.FC<Props> = ({ bundleProducts, contentRef }: Props) => {
  const [showAllBundles, setShowAllBundles] = useState(false);
  const cardCount: number = bundleProducts.length;
  const isMobile = isMobileViewPort();
  const showHeadingAside = Boolean(!isMobile && cardCount < 3);

  const toggleShowMoreBundles = React.useCallback((): void => {
    setShowAllBundles(!showAllBundles);
  }, [showAllBundles]);

  const renderBrowseAllButton = () => (
    <StyledBrowseAllButtonLink
      testId={`browse-all-bundles`}
      variant={ButtonEnums.variants.secondaryNegative}
      size={ButtonEnums.sizes.medium}
      to={generatePath(getPath(Routes.PRODUCT_AGGS_BY_TYPE_PAGE), { productTypeSlug: Product.ProductSlug.BUNDLE })}
      bordered
    >
      Find your best bundle
    </StyledBrowseAllButtonLink>
  );

  const renderBundlesHeading = (isAside: boolean) => (
    <>
      <StyledBundlesHeading isCenter={!isAside}>
        Our top picks for additional learning - and savings
      </StyledBundlesHeading>
      <StyledBundlesSubtitle isCenter={!isAside}>
        We’ve paired this product in CPE &amp; Learning bundles to help you meet your learning goals. As a bonus,
        bundles include even more savings.
      </StyledBundlesSubtitle>
    </>
  );

  const defaultCardsPerRow: number = 4;

  return (
    <StyledWrapper ref={contentRef} id="bundles-block" data-testid="bundles-block">
      <OnlyDesktopCSS>
        <Container>
          {!showHeadingAside && renderBundlesHeading(showHeadingAside)}
          <Grid data-testid="products-content-holder">
            <Grid.Row columns="equal">
              {showHeadingAside && (
                <Grid.Column width={8}>
                  <AsideHeadingContainer>
                    {renderBundlesHeading(showHeadingAside)}
                    <StyledBrowseAllButtonContainer isAside={showHeadingAside}>
                      {renderBrowseAllButton()}
                    </StyledBrowseAllButtonContainer>
                  </AsideHeadingContainer>
                </Grid.Column>
              )}
              {bundleProducts?.map((product: State.ContentCardItem, index: number) => {
                const doubleWidthCard = Boolean(cardCount < defaultCardsPerRow && cardCount % 2 !== 0 && index === 0);
                return (
                  (showAllBundles || index < defaultCardsPerRow) && (
                    <StyledColumn key={`bundle-content-card-${product.id}`} width={doubleWidthCard ? 8 : 4}>
                      <ContentCard
                        isBgColorDark
                        contentCardItem={product}
                        isDoubleWidthCard={doubleWidthCard}
                        testid={`promoted-product-item-${product.id}`}
                        isBundleCard
                        hasLargeTitleText={doubleWidthCard}
                      />
                    </StyledColumn>
                  )
                );
              })}
            </Grid.Row>
          </Grid>
          {!showHeadingAside &&
            (cardCount > defaultCardsPerRow && !showAllBundles ? (
              <StyledViewMoreButtonContainer>
                <StyledViewMoreButton
                  testId={`toggle-view-more-bundles`}
                  variant={ButtonEnums.variants.cloud}
                  size={ButtonEnums.sizes.medium}
                  onClick={toggleShowMoreBundles}
                  bordered
                >
                  View more bundles&nbsp;
                  <ExpandMore />
                </StyledViewMoreButton>
              </StyledViewMoreButtonContainer>
            ) : (
              <StyledBrowseAllButtonContainer>{renderBrowseAllButton()}</StyledBrowseAllButtonContainer>
            ))}
        </Container>
      </OnlyDesktopCSS>
      <OnlyMobileCSS>
        {renderBundlesHeading(showHeadingAside)}
        <Grid>
          <Grid.Row>
            {bundleProducts?.length === 1 ? (
              <Grid.Column computer={16} tablet={16}>
                <ContentCard
                  isBgColorDark
                  contentCardItem={bundleProducts[0]}
                  testid={`promoted-product-item-${bundleProducts[0].id}`}
                  isBundleCard
                />
              </Grid.Column>
            ) : (
              <Grid.Column computer={16} tablet={16}>
                <ContentCardsCarousel
                  isBundleCard
                  testId="bundle-products-carousel"
                  itemTestId="bundle-product-content-card"
                  content={bundleProducts}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
        <StyledBrowseAllButtonContainer>{renderBrowseAllButton()}</StyledBrowseAllButtonContainer>
      </OnlyMobileCSS>
    </StyledWrapper>
  );
};

const StyledViewMoreButtonContainer = styled.div`
  width: ${props => props.theme.pxToRem(230)};
  margin: ${props => props.theme.pxToRem(20)} auto;
`;

const StyledBrowseAllButtonContainer = styled.div<{ isAside?: boolean }>`
  width: ${props => props.theme.pxToRem(265)};
  margin: ${props => props.theme.pxToRem(32)} ${props => (props.isAside ? 0 : 'auto')} 0;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(36)} auto 0;
  }
`;

const StyledViewMoreButton = styled(Button)`
  &&&&&& {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    background-color: transparent;
    color: ${props => props.theme.colors.neutralWhite};
    box-shadow: none;
  }
`;

const StyledBrowseAllButtonLink = styled(ButtonLink)`
  &&&&&& {
    width: 100%;
  }
`;

const StyledWrapper = styled.section`
  padding: ${props => props.theme.pxToRem(48)} 0 ${props => props.theme.pxToRem(64)};
  border-top: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey2};
  background-image: linear-gradient(74deg, #672d89, #9c2463 100%);
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(32)} 0 ${props => props.theme.pxToRem(48)};
  }
`;

const StyledBundlesHeading = styled.h2<{ isCenter?: boolean }>`
  &&& {
    max-width: ${props => props.theme.pxToRem(550)};
    text-align: ${props => (props.isCenter ? 'center' : 'left')};
    margin: 0 auto ${props => props.theme.pxToRem(20)};
    color: ${props => props.theme.colors.neutralWhite};
    font-size: ${props => props.theme.fontSizes.xl};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: ${props => props.theme.pxToRem(44)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: center;
      margin: 0 auto ${props => props.theme.pxToRem(24)};
      padding: 0 ${props => props.theme.pxToRem(20)};
      font-size: ${props => props.theme.fontSizes.l};
      line-height: 1.33;
    }
  }
`;

const StyledBundlesSubtitle = styled.h4<{ isCenter?: boolean }>`
  &&& {
    max-width: ${props => props.theme.pxToRem(693)};
    text-align: ${props => (props.isCenter ? 'center' : 'left')};
    margin: 0 auto ${props => (props.isCenter ? props.theme.pxToRem(20) : props.theme.pxToRem(32))};
    color: ${props => props.theme.colors.neutralWhite};
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: 1.33;

    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: center;
      margin: 0 auto ${props => props.theme.pxToRem(32)};
      padding: 0 ${props => props.theme.pxToRem(20)};
      font-size: ${props => props.theme.fontSizes.s};
      line-height: 1.5;
    }
  }
`;

const AsideHeadingContainer = styled.div`
  margin: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(24)} 0;
  max-width: ${props => props.theme.pxToRem(455)};
`;

const StyledColumn = styled(Grid.Column)`
  padding-top: ${props => props.theme.pxToRem(10)};
  padding-bottom: ${props => props.theme.pxToRem(10)};
`;
