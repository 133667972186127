import React, { useCallback, useState, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Input } from 'components/atoms/Input/Input';
import { Dropdown } from 'components/atoms/Dropdown/Dropdown';
import { InputWithValidation } from 'components/molecules/InputWithValidation/InputWithValidation';
import { CountriesListWithDialCode } from 'mxp-utils';
import { splitPhoneIntoParts } from 'utils/splitPhoneIntoParts';

interface Props {
  placeholder?: string;
  disabled?: boolean;
  handleCenterPhoneNumberOnChange: (dialCode: string, phoneNumber: string) => void;
  phoneNumber?: string;
}

interface TriggerText {
  countryCode: string;
  dialCode: string;
}

export const EmploymentPhoneInput: React.FC<Props> = ({
  placeholder,
  disabled = false,
  handleCenterPhoneNumberOnChange,
  phoneNumber,
}) => {
  const countriesOptions = useMemo(
    () =>
      Object.values(CountriesListWithDialCode).map((country: any) => ({
        key: country.code,
        value: country.code,
        text: `${country.name} (${country.dialCode})`,
        value2: country.dialCode,
      })),
    []
  );

  const [initialValidation, setInitialValidation] = useState(true);
  const [isPhoneInitializedWithValue, setIsPhoneInitializedWithValue] = useState(false);

  const [phoneObject, setPhoneObject] = useState({
    countryCode: 'US',
    phoneNumber: '',
    dialCode: '+1',
  });

  const [triggerText, setTriggerText] = useState<TriggerText>({
    countryCode: phoneObject.countryCode,
    dialCode: phoneObject.dialCode,
  });

  const isPhoneEmpty = !Boolean(phoneObject.phoneNumber.length);

  // initializes phone input if phone number already exists
  useEffect(() => {
    if (phoneNumber && !isPhoneInitializedWithValue) {
      // assuming phone number will have area code + extension with format of: (880) +1 8015559864 (ext.805)
      const { dialCode, phone } = splitPhoneIntoParts(phoneNumber);

      setPhoneObject({
        ...phoneObject,
        dialCode: dialCode || phoneObject.dialCode,
        phoneNumber: phone || phoneObject.phoneNumber,
      });
      setIsPhoneInitializedWithValue(true);
    }
  }, [phoneNumber, setPhoneObject, phoneObject, setIsPhoneInitializedWithValue, isPhoneInitializedWithValue]);

  const handleCountryCodeChange = useCallback(
    (event: any, data: any) => {
      const { value } = data;
      const { key, value2 } = data?.options?.find((o: { value: string }) => o.value === value);

      setTriggerText({
        countryCode: key,
        dialCode: value2,
      });

      setPhoneObject({ ...phoneObject, countryCode: key, dialCode: value2 });
      handleCenterPhoneNumberOnChange(value2, phoneObject.phoneNumber);
    },
    [phoneObject, setPhoneObject, handleCenterPhoneNumberOnChange]
  );

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (/[a-z]/i.test(value)) return; // eslint-disable-line

    if (/[!@#$%^&*`~()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)) return; // eslint-disable-line

    setPhoneObject({ ...phoneObject, phoneNumber: value });

    if (value) {
      handleCenterPhoneNumberOnChange(phoneObject.dialCode, value);
    } else {
      handleCenterPhoneNumberOnChange('', '');
    }
  };

  useEffect(() => {
    setTriggerText({
      countryCode: phoneObject.countryCode,
      dialCode: phoneObject.dialCode,
    });

    if (phoneObject.phoneNumber !== '') {
      setInitialValidation(false);
    }
  }, [phoneObject]);

  const trigger = (
    <StyledTrigger>
      <StyledTriggerCountryCode disabled={disabled}>{triggerText.countryCode}</StyledTriggerCountryCode>&nbsp;
      <StyledTriggerDialCode disabled={disabled}>{triggerText.dialCode}</StyledTriggerDialCode>
    </StyledTrigger>
  );

  return (
    <StyledContainer>
      <StyledDropdown
        name="countryCode"
        value={phoneObject.countryCode}
        scrolling
        data-testid="country-code"
        options={countriesOptions}
        placeholder="Select country code"
        onChange={handleCountryCodeChange}
        trigger={trigger}
        disabled={disabled}
      />

      {disabled ? (
        <StyledInputDisabled
          testId="phoneNumber"
          name="phoneNumber"
          value={phoneObject.phoneNumber}
          onChange={handlePhoneNumberChange}
          disabled
        />
      ) : (
        <StyledInputWithValidation
          name="phoneNumber"
          placeholder={placeholder}
          type="text"
          value={phoneObject.phoneNumber}
          testId="phoneNumber"
          labelId="phoneNumber"
          onChange={handlePhoneNumberChange}
          isCorrect={!isPhoneEmpty || initialValidation}
          errorMessage="Please input a valid phone number"
          isCorrectIconShown
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${props => props.theme.pxToRem(3)};
`;

interface StyledTriggerProps {
  disabled: boolean;
}

const StyledTriggerCountryCode = styled.span<StyledTriggerProps>`
  margin-left: ${props => props.theme.pxToRem(15)};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${props => props.theme.colors.neutralGrey5};
    `}
`;

const StyledTriggerDialCode = styled.span<StyledTriggerProps>`
  margin-right: ${props => props.theme.pxToRem(15)};
  color: ${props => props.theme.colors.neutralGrey5};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${props => props.theme.colors.neutralGrey5};
    `}
`;

const StyledInputWithValidation = styled(InputWithValidation)`
  &&& {
    width: 72%;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 70%;
    }

    input {
      border-radius: 0 ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(5)} 0;
    }

    div:nth-child(3) {
      flex: 1 100%;
    }
  }
`;

const StyledInputDisabled = styled(Input)`
  &&& {
    width: ${props => props.theme.pxToRem(400)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 80%;
    }

    input {
      border-radius: 0 ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(5)} 0;
    }
    div:nth-child(3) {
      flex: 1 100%;
    }
  }

  &&&.disabled {
    cursor: not-allowed;
    opacity: 1;

    input {
      border-color: ${props => props.theme.colors.neutralGrey4};
      background-color: ${props => props.theme.colors.neutralGrey2};
      color: ${props => props.theme.colors.neutralGrey5};
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      height: ${props.theme.pxToRem(43.5)};
      width: 25%;
      border-radius: 5px 0 0 5px;
      padding-right: ${props.theme.pxToRem(10)};
      color: ${props.theme.colors.neutralBlack};
      border: solid ${props.theme.pxToRem(1)} ${props.theme.colors.neutralGrey4};
 
      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(5)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &&&.disabled {
        opacity: 1;
        background-color: ${props.theme.colors.neutralGrey2};
        cursor: not-allowed;

        input {
          color: ${props.theme.colors.neutralGrey5};
        }
      }
 
      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }
 
      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.neutralBlack};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }
 
      &&&.ui.dropdown > .menu {
        max-height: ${props.theme.pxToRem(250)};
        width: ${props.theme.pxToRem(400)} !important;
      }
 
      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: 1px solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: 1px solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.neutralBlack};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }
 
      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: 1px solid blue;
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
    }
  `}
`;

const StyledTrigger = styled.div`
  display: flex;
  align-content: space-between;
`;
