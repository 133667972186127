import React, { useState } from 'react';
import { Accordion, StrictAccordionProps, StrictAccordionTitleProps, Grid, List, Divider } from 'semantic-ui-react';
import { ReactComponent as ArrowUp } from 'resources/images/icon-arrow-up.svg';
import { ReactComponent as ArrowDown } from 'resources/images/icon-arrow-down.svg';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
interface Item {
  id: number;
  heading: string | React.ReactNode;
  content?: React.ReactNode;
  disabled?: boolean;
}

export interface ExpandableProps extends StrictAccordionProps {
  items: Item[];
  divided?: boolean;
  defaultOpen?: number | number[] | boolean;
  alwaysOpen?: number[];
  expandIcon?: React.ReactNode;
  collapseIcon?: React.ReactNode;
  title?: string;
  openAll?: React.ReactNode;
  closeAll?: React.ReactNode;
  titleTestId?: string;
  openAllTestId?: string;
  closeAllTestId?: string;
  showAll?: boolean;
  isBgColorDark?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>, data: StrictAccordionTitleProps) => void;
}

export const Expandable: React.FC<ExpandableProps> = props => {
  const {
    items,
    title,
    expandIcon,
    collapseIcon,
    divided,
    showAll,
    openAll,
    closeAll,
    openAllTestId,
    closeAllTestId,
    titleTestId,
    defaultOpen,
    alwaysOpen,
    isBgColorDark = false,
    onClick = () => null,
  } = props;

  const getOpenItems = (passedItems: Item[], passedOpen?: number | number[] | boolean) =>
    passedOpen
      ? passedItems.reduce((acc: number[], passedItem: Item) => {
          switch (typeof passedOpen) {
            case 'boolean':
              acc.push(passedItem.id);
              break;
            case 'number':
              if (passedItem.id === passedOpen) {
                acc.push(passedItem.id);
              }
              break;
            case 'object':
              if (Array.isArray(passedOpen) && passedOpen.indexOf(passedItem.id) > -1) {
                acc.push(passedItem.id);
              }
              break;
          }
          return acc;
        }, alwaysOpen || [])
      : alwaysOpen || [];

  const [activeItems, setActiveItems] = useState<number[]>(getOpenItems(items, defaultOpen));

  React.useEffect(() => {
    setActiveItems(getOpenItems(items, defaultOpen));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSingleOpen = (e: React.MouseEvent<HTMLDivElement>, titleProps: any) => {
    const isCheckboxClick = String(e.target).includes('HTMLInputElement');
    onClick(e, titleProps);
    const { index } = titleProps;
    if (typeof index !== 'number') return;

    const stripHtml = (value: string): string => value.replace(/<[^>]*>?/gm, '');
    const { windowPathname } = getPageNameAndSiteSection();
    const headingTextProp = titleProps?.headingText?.props;
    const accordionTitle = stripHtml(
      headingTextProp?.dangerouslySetInnerHTML?.__html || // for html value content
        headingTextProp?.item?.name || // for the user preferences content
        // fallback values
        (e?.target as HTMLElement)?.textContent ||
        String(index)
    );
    if (activeItems.includes(index) && !isCheckboxClick) {
      handleEvent({ clickValue: `button:link:int::ACCORDION-CLOSE:${accordionTitle}:${windowPathname}` }, NAV_CLICK);
      setActiveItems(activeItems.filter((item: number) => item !== index));
    } else if (isCheckboxClick && (titleTestId?.includes('topics') || titleTestId?.includes('skills'))) {
      handleEvent({ clickValue: `button:link:int::ACCORDION-OPEN:${accordionTitle}:${windowPathname}` }, NAV_CLICK);
    } else {
      handleEvent({ clickValue: `button:link:int::ACCORDION-OPEN:${accordionTitle}:${windowPathname}` }, NAV_CLICK);
      setActiveItems([...activeItems, index]);
    }
  };

  const getIcon = (id: number): React.ReactNode => {
    return activeItems.includes(id) ? collapseIcon || <ActiveIcon /> : expandIcon || <InactiveIcon />;
  };

  const toggleShowAll = React.useCallback(() => {
    setActiveItems(items.map(item => item.id));
  }, [setActiveItems, items]);

  const toggleHideAll = React.useCallback(() => {
    setActiveItems([]);
  }, [setActiveItems]);

  return (
    <>
      {!!title && items.length > 0 && (
        <>
          <Grid>
            <Control floated="left" mobile={16} computer={8} data-testid={titleTestId}>
              {title}
            </Control>
            <Control verticalAlign="bottom" floated="right" textAlign="right" width={8}>
              <List horizontal divided>
                <List.Item onClick={toggleShowAll} data-testid={openAllTestId}>
                  {openAll}
                </List.Item>
                <List.Item onClick={toggleHideAll} data-testid={closeAllTestId}>
                  {closeAll}
                </List.Item>
              </List>
            </Control>
          </Grid>
          <Divider />
        </>
      )}
      <Accordion>
        {items &&
          items.map(item => (
            <Panel key={item.id} divided={divided} disabled={item?.disabled}>
              <AccordionTitle
                active={activeItems.includes(item.id)}
                index={item.id}
                onClick={handleSingleOpen}
                data-testid={`expandable_title_${item.id}`}
                headingText={item.heading}
              >
                <ContentContainer isBgColorDark={isBgColorDark}>
                  {item.heading}
                  {item?.disabled ? null : <IconContainer>{getIcon(item.id)}</IconContainer>}
                </ContentContainer>
              </AccordionTitle>

              <Accordion.Content
                active={activeItems.includes(item.id) || showAll}
                index={item.id}
                data-testid={`expandable_content_${item.id}`}
              >
                {item.content}
              </Accordion.Content>
            </Panel>
          ))}
      </Accordion>
    </>
  );
};

const Panel = styled.div<
  ThemedStyledProps<{ divided: boolean | undefined; disabled?: boolean | undefined }, DefaultTheme>
>`
  border-bottom: ${props => (props.divided ? `1px solid ${props.theme.colors.neutralGrey3}` : null)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const AccordionTitle = styled(Accordion.Title)`
  &&&&& {
    font-size: ${props => props.theme.fontSizes.m};
    padding: 20px 0 10px;
    z-index: -1;
  }
`;

const ContentContainer = styled.div<{ isBgColorDark: boolean }>`
  display: flex;
  justify-content: space-between;
  svg path {
    stroke: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.primaryPurple)};
  }
`;

const IconContainer = styled.div`
  align-self: center;
`;

const Control = styled(Grid.Column)`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  ${props => props.theme.mediaQueries.mobileOnly} {
    &&& + div.right {
      display: none;
    }
  }
`;

const ActiveIcon = styled(ArrowUp)`
  fill: ${props => props.theme.colors.primaryDarkPurple};
  width: 1.5rem;
  height: 1.5rem;
`;

const InactiveIcon = styled(ArrowDown)`
  fill: ${props => props.theme.colors.primaryPurple};
  width: 1.5rem;
  height: 1.5rem;
`;
