export enum cimaSupervisorActionNames {
  IS_LOADING = 'membership/LOADING',
  GET_SUPERVISOR_DASHBOARD = 'cimaSupervisor/GET_SUPERVISOR_DASHBOARD',
  GET_MEMBER_DETAILS = 'cimaSupervisor/GET_MEMBER_DETAILS',
  GET_EMPLOYMENT_INFORMATION = 'cimaSupervisor/GET_EMPLOYMENT_INFORMATION',
  GET_CORE_ACTIVITIES = 'cimaSupervisor/GET_CORE_ACTIVITIES',
  GET_SKILL_BEHAVIOUR = 'cimaSupervisor/GET_SKILL_BEHAVIOUR',
  UPDATED_VIEW_INFO_STATUS = 'cimaSupervisor/UPDATED_VIEW_INFO_STATUS',
  UPDATE_FEEDBACK = 'cimaSupervisor/UPDATE_FEEDBACK',
  RESET_UPDATE_RESULT_FEEDBACK = 'cimaSupervisor/RESET_UPDATE_RESULT_FEEDBACK',
  WITHDRAW_SUBMISSION = 'cimaSupervisor/WITHDRAW_SUBMISSION',
  SAVE_TOKEN = 'cimaSupervisor/SAVE_TOKEN',
  SAVE_REVIEWER_DETAILS = 'cimaSupervisor/SAVE_REVIEWER_DETAILS',
  SAVE_REVIEWER_DETAILS_LOADING = 'cimaSupervisor/SAVE_REVIEWER_DETAILS_LOADING',
  SAVE_REVIEWER_DETAILS_SUCCESS = 'cimaSupervisor/SAVE_REVIEWER_DETAILS_SUCCESS',
  SET_MODULE_STATUS = 'cimaSupervisor/SET_MODULE_STATUS',
  GET_AUDIT_FEEDBACK = 'cimaSupervisor/GET_AUDIT_FEEBACK',
  SAVE_PER_MODULE_ID = 'cimaSupervisor/SAVE_PER_MODULE_ID',
  LOADING = 'cimaSupervisor/LOADING',
  SEND_SUPERVISOR_REVIEW = 'cimaSupervisor/SEND_SUPERVISOR_REVIEW',
  SEND_DIFFERENT_SUPERVISOR_NOTIFICATION = 'cimaSupervisor/SEND_DIFFERENT_SUPERVISOR_NOTIFICATION',
  EPA_UPDATE_EPA_AND_COMMENTS = 'cimaSupervisor/EPA_UPDATE_EPA_AND_COMMENTS',
  UPDATE_EXAM_CREDIT_FROM_EPA = 'cimaSupervisor/UPDATE_EXAM_CREDIT_FROM_EPA',
  SET_STATE = 'cimaSupervisor/SET_STATE',
  VALIDATE_SUPERVISOR_TOKEN = 'user/VALIDATE_SUPERVISOR_TOKEN',
  GET_CIMA_REVIEWER_DASHBOARD = 'cimaSupervisor/GET_CIMA_REVIEWER_DASHBOARD',
  GET_CIMA_REVIEWER_DASHBOARD_FROM_EXPIRED_LINK = 'cimaSupervisor/GET_CIMA_REVIEWER_DASHBOARD_FROM_EXPIRED_LINK',
}

export enum PERBanner {
  APPROVED = 'Your approval has been sent',
  WITHDRAWN = 'Your decline has been sent',
  RFI = 'Your request has been sent',
}

export interface SaveReviewerDetailsPayload {
  firstName: string;
  lastName: string;
  email: string;
  linkedInUrl: string;
  userId: string;
  financialBody: string;
}

export const initialState: State.CimaSupervisor = {
  loading: false,
  perSubmission: [],
  dashboard: [],
  supervisor: {
    email: '',
    employer: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      id: '',
      name: '',
      postalCode: '',
      state: '',
      type: '',
    },
    firstName: '',
    id: '',
    individualId: '',
    isCimaAssessor: false,
    jobTitle: '',
    lastName: '',
    linkedInUrl: '',
    name: '',
  },
  memberDetails: {
    name: '',
    email: '',
    phoneNumber: '',
    coreActivities: [],
    skillsAndBehaviours: [],
    employmentHistory: [],
    id: '',
  },
  employmentInformation: {
    bussinessName: '',
    organizationType: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    postalCode: '',
    jobTitle: '',
    startDate: '',
    endDate: '',
    numberOfDaysPerWeek: '',
    feedback: {
      id: '',
      requestedDate: '',
      achievements: '',
      areasOfImprovement: '',
      status: '',
      submissionDate: '',
      perModuleId: '',
    },
    employmentType: '',
    summary: '',
  },
  coreActivities: {
    id: '',
    activityType: '',
    situation: '',
    task: '',
    action: '',
    result: '',
    status: '',
    submissionDate: '',
    feedback: {
      id: '',
      requestedDate: '',
      achievements: '',
      areasOfImprovement: '',
      status: '',
      submissionDate: '',
      perModuleId: '',
    },
  },
  skillBehaviour: {
    id: '',
    activityType: '',
    situation: '',
    task: '',
    action: '',
    result: '',
    status: '',
    submissionDate: '',
    feedback: {
      id: '',
      requestedDate: '',
      achievements: '',
      areasOfImprovement: '',
      status: '',
      submissionDate: '',
      perModuleId: '',
    },
  },
  perModuleStatus: [],
  perModuleId: '',
  isWithdrawnSuccess: false,
  updateEpaSuccess: false,
  isLoadingUpdateEPA: false,
  isSaveDraftLoading: false,
  isApprovedOrRFILoading: false,
  isCimaReviewerDashboardLoading: false,
  saveReviewerDetailsLoading: false,
  saveReviewerDetailsSuccess: false,
};

export enum Epa2SupervisorDeclaration {
  LEVEL4 = `I can confirm that the reflective statement included in this submission was completed independently by the apprentice and is a true representation of the work they have undertaken towards completion of the L4 Professional Accounting Technician End -Point Assessment. I confirm that the evidence presented in the portfolio has been reviewed by myself for authenticity. The information included in the submission complies with client and business confidentiality agreements. Any sensitive or personal information has been redacted.;I consent to the information included in the submission to be used solely by CIMA and/or CIMA's representatives for the purpose of end-point assessment.`,
  LEVEL7 = `I can confirm that the project report included in this submission was completed independently by the apprentice and is a true representation of the work they have undertaken towards completion of the L7 Accountancy Professional End -Point Assessment. I confirm that the information included in the submission complies with client and business confidentiality agreements. Any sensitive or personal information has been redacted.;I consent to the information included in the submission to be used solely by CIMA and/or CIMA’s representatives for the purpose of end-point assessment.`,
}
