import React from 'react';
import { LeftSectionLinks } from 'components/pages/PageToolkit/LeftSectionLinks';

interface Props {
  item: any;
  idx: number;
}

export const RenderList: React.FC<Props> = React.memo(({ item, idx }) => {
  if (!item || !item.list || !item.list.length) {
    return null;
  }
  return (
    <LeftSectionLinks
      testId={`toolkitPageLeftSideLinks-${idx}`}
      firstItemsCount={5}
      title={item.title}
      items={item.list.filter((obj: any) => obj)}
    />
  );
});
