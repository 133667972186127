import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { getUserSyllabusByPersonAccountId, getStudentExamHistoryData } from 'modules/user/actions';
import {
  examSyllabusSelector,
  allExamResultsSelector,
  isSyllabusLoadingSelector,
  isAllExamResultLoadingSelector,
} from 'modules/user/selectors';
import { PageCimaAllExamResults } from 'components/pages/PageCimaAllExamResults';

const mapActionCreators = (dispatch: Dispatch) => ({
  getUserSyllabus(): void {
    dispatch(getUserSyllabusByPersonAccountId());
  },
  getExamHistory(): void {
    dispatch(getStudentExamHistoryData());
  },
});

const mapStateToProps = (state: State.Root) => ({
  allExamsResults: allExamResultsSelector(state),
  userSyllabus: examSyllabusSelector(state),
  isSyllabusLoading: isSyllabusLoadingSelector(state),
  isExamHistoryResultLoading: isAllExamResultLoadingSelector(state),
});

export const PageCimaAllExamResultsContainer = connect(mapStateToProps, mapActionCreators)(PageCimaAllExamResults);
