import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ContentIndustriesFollow, FollowIndustryIF } from 'components/organisms/ContentIndustriesFollow';
import { userIndustriesPrefSelector, userPrefSelector } from 'modules/user/selectors';
import { toggleIndustryPrefAndUpdateGoldenRecord } from 'modules/user/actions';
import { contentIndustriesFollowSelector } from 'modules/content/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  toggleIndustryPref(industryId: string, isOnEvent: boolean): void {
    dispatch(toggleIndustryPrefAndUpdateGoldenRecord(industryId, isOnEvent));
  },
});

const mapStateToProps = (initState: State.Root) => {
  const industries: FollowIndustryIF[] = contentIndustriesFollowSelector(initState);

  return (state: State.Root) => ({
    userIndustriesPref: userIndustriesPrefSelector(state),
    userPref: userPrefSelector(state),
    industries,
  });
};

export const ContentIndustriesFollowContainer = connect(mapStateToProps, mapActionCreators)(ContentIndustriesFollow);
