import { Salesforce } from 'mxp-schemas';
import { NO_VALUE_STRING, TRenewMembershipDetailsTableData } from '../types/types';

export const getAccountTypeData = (
  customerMemberships: Salesforce.FirmMembership[]
): TRenewMembershipDetailsTableData['accountType'] => {
  const data: TRenewMembershipDetailsTableData['accountType'] = {
    membershipType: customerMemberships[0].membershipTerm?.membershipTermType || NO_VALUE_STRING,
    validUntil: customerMemberships[0].membershipTerm.expiryDate || NO_VALUE_STRING,
    status: customerMemberships[0].status || NO_VALUE_STRING,
  };
  return data;
};
