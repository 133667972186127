import * as Sentry from '@sentry/browser';
import { handleActions, Action } from 'redux-actions';
import {
  // invoices
  getInvoices,
  resetModule,
  getInvoiceFile,
  setDownloadingId,
  setSelectedPaymentOption,
  setSelectedPaymentAmountOption,
  setSelectedPaymentAmountOptionCima,
  getInvoice,
  getMultiEntityInvoices,
  getInvoiceLoading,
  setInvoiceCheckoutPage,
  resetInvoicePaymentConfirmationData,
  getInvoicePaymentConfirmationResult,
  getInvoicePaymentConfirmationResultLoading,
  goToInvoiceCheckoutErrorPage,
  isValidAmountCheckLoading,
  payInvoices,
  resetFullInvoiceDetailsModule,
  resetPayInvoicesModule,
  // seatManagement
  getServiceContracts,
  seatMgmtLoading,
  addEmailToSeatManagementEmailsList,
  removeEmailFromSeatManagementEmailsList,
  showSeatManagementEmailInviteModal,
  hideSeatManagementEmailInviteModal,
  setDeallocationModalVisibility,
  setSelectedSeatsToDeallocate,
  setDealloctionLoading,
  deallocateLineItems,
  sendEmailPrintInvoices,
  setSendEmailPrintInvoicesLoading,
  resetSendEmailPrintInvoices,
  // firmInformation
  getOrganizations,
  getClientTuitionProviders,
  getOrganizationAdminsByRole,
  setOrganizationAdminsLoading,
  seatManageInviteUpdate,
  getSeatManagementInvite,
  seatManagementInviteCancel,
  extendProductAccess,
  setExtendProductAccessIsLoading,
  setExtendProductAccessDetails,
  getRecentExtensionProcessStatus,
  toggleProductAccessExtensionModal,
  setPaginationAction,
} from 'modules/clientAdmin/actions';
import { ClientAdminActionNames, initialState } from 'modules/clientAdmin/constants';
import { SeatManagement, Invoices } from 'mxp-schemas';

if ([getInvoices, getServiceContracts, resetModule].includes(undefined)) {
  const message: string = 'clientAdmin module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [getInvoices]: {
    next: (state: State.ClientAdmin, action: Action<State.InvoiceTableRow[]>): State.ClientAdmin => ({
      ...state,
      invoices: {
        ...state.invoices,
        invoicesList: action.payload,
        invoicesListFetched: true,
        error: null,
      },
    }),
    throw: (
      state: State.ClientAdmin,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.ClientAdmin => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          invoicesListFetched: true,
          error,
        },
      };
    },
  },

  [getInvoiceFile]: {
    next: (state: State.ClientAdmin): State.ClientAdmin => ({
      ...state,
      invoices: {
        ...state.invoices,
        downloadingId: null,
        downloadingError: false,
      },
    }),
    throw: (state: State.ClientAdmin): State.ClientAdmin => ({
      ...state,
      invoices: {
        ...state.invoices,
        downloadingError: true,
      },
    }),
  },
  [setDownloadingId]: (state: State.ClientAdmin, action: any): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      downloadingId: action.payload,
      downloadingError: false,
    },
  }),
  [getServiceContracts]: {
    next: (state: State.ClientAdmin, action: Action<State.ServiceContract[]>): State.ClientAdmin => {
      return {
        ...state,
        seatManagement: {
          ...state.seatManagement,
          serviceContractsList: {
            list: action.payload,
            loading: false,
            error: null,
          },
        },
      };
    },
    throw: (
      state: State.ClientAdmin,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.ClientAdmin => {
      return {
        ...state,
        seatManagement: {
          ...state.seatManagement,
          serviceContractsList: {
            ...state.seatManagement.serviceContractsList,
            loading: false,
            error,
          },
        },
      };
    },
  },
  [getSeatManagementInvite]: {
    next: (state: State.ClientAdmin, action: Action<State.AllocatedSeatDynamoDbRecord[]>): State.ClientAdmin => {
      return {
        ...state,
        seatManagement: {
          ...state.seatManagement,
          seatManagementEmailInvite: {
            ...state.seatManagement.seatManagementEmailInvite,
            list: [...state.seatManagement.seatManagementEmailInvite.list]
              .concat(action.payload)
              .reduce((prev, curr) => {
                const exist = prev.find((obj: any) => obj.token === curr.token);
                const isInviteUpdate = state.seatManagement.seatManagementEmailInvite.isInviteUpdate;

                if (exist) {
                  return prev;
                }

                if (isInviteUpdate) {
                  return action.payload;
                }

                return [...prev, curr];
              }, []),
            loading: false,
            isInviteUpdate: false,
            error: null,
          },
        },
      };
    },
    throw: (
      state: State.ClientAdmin,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.ClientAdmin => {
      return {
        ...state,
        seatManagement: {
          ...state.seatManagement,
          seatManagementEmailInvite: {
            ...state.seatManagement.seatManagementEmailInvite,
            loading: false,
            isInviteUpdate: false,
            error,
          },
        },
      };
    },
  },
  [seatManagementInviteCancel]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      seatManagementEmailInvite: {
        ...state.seatManagement.seatManagementEmailInvite,
        isInviteUpdate: true,
        loading: false,
      },
    },
  }),
  [seatManageInviteUpdate]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      seatManagementEmailInvite: {
        ...state.seatManagement.seatManagementEmailInvite,
        isInviteUpdate: true,
        loading: false,
      },
    },
  }),
  [seatMgmtLoading]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      serviceContractsList: {
        ...state.seatManagement.serviceContractsList,
        loading: true,
      },
    },
  }),
  [addEmailToSeatManagementEmailsList]: (state: State.ClientAdmin, action: any): State.ClientAdmin => {
    if (!action.payload) {
      return { ...state };
    }
    return {
      ...state,
      seatManagement: {
        ...state.seatManagement,
        seatManagementRecipientsEmailsList: {
          ...state.seatManagement.seatManagementRecipientsEmailsList,
          emails: [...state.seatManagement.seatManagementRecipientsEmailsList.emails, action.payload],
        },
      },
    };
  },

  [removeEmailFromSeatManagementEmailsList]: (state: State.ClientAdmin, action: any): State.ClientAdmin => {
    return {
      ...state,
      seatManagement: {
        ...state.seatManagement,
        seatManagementRecipientsEmailsList: {
          ...state.seatManagement.seatManagementRecipientsEmailsList,
          emails: [
            ...state.seatManagement.seatManagementRecipientsEmailsList.emails.filter(
              // tslint:disable-next-line:variable-name
              (_email, index) => index !== action.payload
            ),
          ],
        },
      },
    };
  },
  [resetModule]: (): State.ClientAdmin => initialState,

  [getInvoiceLoading]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      payment: {
        ...state.invoices.payment,
        fullInvoiceDetails: {
          ...state.invoices.payment.fullInvoiceDetails,
          loading: true,
        },
      },
    },
  }),

  [getMultiEntityInvoices]: (state: State.ClientAdmin, action: Action<State.Invoice[]>): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      payment: {
        ...state.invoices.payment,
        fullInvoiceDetails: {
          ...state.invoices.payment.fullInvoiceDetails,
          cimaInvoiceDetails: action.payload,
          invoiceDetails: null,
          loading: false,
          error: null,
        },
      },
    },
  }),

  [getInvoice]: (state: State.ClientAdmin, action: Action<State.Invoice>): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      payment: {
        ...state.invoices.payment,
        fullInvoiceDetails: {
          ...state.invoices.payment.fullInvoiceDetails,
          invoiceDetails: action.payload,
          loading: false,
          error: null,
        },
      },
    },
  }),

  [setInvoiceCheckoutPage]: (state: State.ClientAdmin, action: Action<string | null>): State.ClientAdmin => {
    const nextState: State.ClientAdmin = {
      ...state,
      invoices: {
        ...state.invoices,
        payment: {
          ...state.invoices.payment,
          payInvoice: {
            ...state.invoices.payment.payInvoice,
            pageStep: action.payload,
          },
        },
      },
    };
    return nextState;
  },
  [setPaginationAction]: (
    state: State.ClientAdmin,
    action: Action<{
      type: Invoices.InvoiceKeys;
      modifier: Partial<State.Pagination>;
    }>
  ): State.ClientAdmin => {
    const { type, modifier } = action.payload;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        [type]: {
          ...(state?.pagination && state?.pagination[type]),
          ...modifier,
        },
      },
    };
  },

  [setSelectedPaymentOption]: (state: State.ClientAdmin, action: Action<string>): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      payment: {
        ...state.invoices?.payment,
        payInvoice: {
          ...state.invoices?.payment?.payInvoice,
          selectedPaymentOption: action.payload,
        },
      },
    },
  }),

  [isValidAmountCheckLoading]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      payment: {
        ...state.invoices.payment,
        fullInvoiceDetails: {
          ...state.invoices.payment.fullInvoiceDetails,
          isValidAmountCheckLoading: true,
        },
      },
    },
  }),

  [setSelectedPaymentAmountOption]: {
    next: (state: State.ClientAdmin, action: any): State.ClientAdmin => ({
      ...state,
      invoices: {
        ...state.invoices,
        payment: {
          ...state.invoices.payment,
          fullInvoiceDetails: {
            ...state.invoices.payment.fullInvoiceDetails,
            invoiceDetails: state.invoices.payment.fullInvoiceDetails.invoiceDetails && {
              ...state.invoices.payment.fullInvoiceDetails.invoiceDetails,
              amount: action.payload.amount || 0,
            },
            isValidAmountCheckLoading: false,
            isValidAmountCheckError: false,
          },
          payInvoice: {
            ...state.invoices.payment.payInvoice,
            selectedPaymentAmountOption: action.payload.amountOption,
          },
        },
      },
    }),
    throw: (state: State.ClientAdmin, action: any): State.ClientAdmin => ({
      ...state,
      invoices: {
        ...state.invoices,
        payment: {
          ...state.invoices.payment,
          fullInvoiceDetails: {
            ...state.invoices.payment.fullInvoiceDetails,
            isValidAmountCheckLoading: false,
            isValidAmountCheckError: true,
          },
        },
      },
    }),
  },
  [setSelectedPaymentAmountOptionCima]: {
    next: (state: State.ClientAdmin, action: any): State.ClientAdmin => ({
      ...state,
      invoices: {
        ...state.invoices,
        payment: {
          ...state.invoices.payment,
          fullInvoiceDetails: {
            ...state.invoices.payment.fullInvoiceDetails,
            cimaInvoiceDetails: action.payload?.cimaInvoice,
          },
          payInvoice: {
            ...state.invoices.payment.payInvoice,
            selectedPaymentAmountOption: action.payload.amountOption,
          },
        },
      },
    }),
    throw: (state: State.ClientAdmin, action: any): State.ClientAdmin => ({
      ...state,
      invoices: {
        ...state.invoices,
        payment: {
          ...state.invoices.payment,
          fullInvoiceDetails: {
            ...state.invoices.payment.fullInvoiceDetails,
            isValidAmountCheckLoading: false,
            isValidAmountCheckError: true,
          },
        },
      },
    }),
  },
  [sendEmailPrintInvoices]: {
    next: (state: State.ClientAdmin, action: any): State.ClientAdmin => ({
      ...state,
      invoices: {
        ...state.invoices,
        sendEmailPrint: {
          ...state.invoices.sendEmailPrint,
          result: action.payload,
          loading: false,
        },
      },
    }),
    throw: (state: State.ClientAdmin): State.ClientAdmin => ({
      ...state,
      invoices: {
        ...state.invoices,
        sendEmailPrint: {
          ...state.invoices.sendEmailPrint,
          result: null,
          loading: false,
        },
      },
    }),
  },

  [setSendEmailPrintInvoicesLoading]: (state: State.ClientAdmin, action: any): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      sendEmailPrint: {
        ...state.invoices.sendEmailPrint,
        loading: action.payload,
      },
    },
  }),
  [resetSendEmailPrintInvoices]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      sendEmailPrint: {
        ...initialState.invoices.sendEmailPrint,
      },
    },
  }),

  [payInvoices]: {
    next: (state: State.ClientAdmin, action: Action<State.InvoicesPaymentResponse>): State.ClientAdmin => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          payment: {
            ...state.invoices.payment,
            payInvoices: {
              result: action.payload,
              loading: false,
              error: null,
            },
          },
        },
      };
    },
    throw: (
      state: State.ClientAdmin,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.ClientAdmin => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          payment: {
            ...state.invoices.payment,
            payInvoices: {
              result: null,
              loading: false,
              error,
            },
          },
        },
      };
    },
  },

  [resetFullInvoiceDetailsModule]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      payment: {
        ...state.invoices.payment,
        fullInvoiceDetails: {
          ...initialState.invoices.payment.fullInvoiceDetails,
        },
      },
    },
  }),

  [resetPayInvoicesModule]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      payment: {
        ...state.invoices.payment,
        payInvoices: {
          ...initialState.invoices.payment.payInvoices,
        },
      },
    },
  }),

  [getInvoicePaymentConfirmationResultLoading]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      payment: {
        ...state.invoices.payment,
        payInvoice: {
          ...state.invoices.payment.payInvoice,
          loading: true,
        },
      },
    },
  }),

  [getInvoicePaymentConfirmationResult]: (
    state: State.ClientAdmin,
    action: Action<State.Invoice>
  ): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      payment: {
        ...state.invoices.payment,
        payInvoice: {
          ...state.invoices.payment.payInvoice,
          invoicePaymentResult: action.payload,
          loading: false,
          error: null,
        },
      },
    },
  }),

  [resetInvoicePaymentConfirmationData]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    invoices: {
      ...state.invoices,
      payment: {
        ...state.invoices.payment,
        payInvoice: {
          loading: false,
          error: null,
          selectedPaymentOption: null,
          selectedPaymentAmountOption: null,
          invoicePaymentResult: null,
          pageStep: null,
        },
      },
    },
  }),
  [ClientAdminActionNames.SHOW_MANAGEMENT_RECIPIENTS_EMAILS_MODAL]: (
    state: State.ClientAdmin,
    action: Action<SeatManagement.ServiceContract>
  ): State.ClientAdmin => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      seatManagementRecipientsEmailsList: {
        ...state.seatManagement.seatManagementRecipientsEmailsList,
        modalVisible: true,
        contractToAssignSeatFor: action.payload,
      },
    },
  }),
  [ClientAdminActionNames.HIDE_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_MODAL]: (
    state: State.ClientAdmin
  ): State.ClientAdmin => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      seatManagementRecipientsEmailsList: {
        ...state.seatManagement.seatManagementRecipientsEmailsList,
        modalVisible: false,
        contractToAssignSeatFor: undefined,
      },
    },
  }),
  [ClientAdminActionNames.SEAT_MANAGEMENT_ASSIGN_LOADING]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      seatManagementRecipientsEmailsList: {
        ...state.seatManagement.seatManagementRecipientsEmailsList,
        loadingAssignment: true,
      },
    },
  }),
  [ClientAdminActionNames.SEAT_MANAGEMENT_ALLOCATE_SEATS]: {
    next: (state: State.ClientAdmin, action: any): State.ClientAdmin => ({
      ...state,
      seatManagement: {
        ...state.seatManagement,
        seatManagementRecipientsEmailsList: {
          ...state.seatManagement.seatManagementRecipientsEmailsList,
          loadingAssignment: false,
          assignmentResult: action.payload,
          error: null,
        },
      },
    }),
    throw: (
      state: State.ClientAdmin,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.ClientAdmin => ({
      ...state,
      seatManagement: {
        ...state.seatManagement,
        seatManagementRecipientsEmailsList: {
          ...state.seatManagement.seatManagementRecipientsEmailsList,
          loadingAssignment: false,
          error,
        },
      },
    }),
  },
  [ClientAdminActionNames.SEAT_MANAGEMENT_EMAIL_LIST_RESET_MODULE]: (state: State.ClientAdmin): State.ClientAdmin => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      seatManagementRecipientsEmailsList: initialState.seatManagement.seatManagementRecipientsEmailsList,
    },
  }),
  [setDeallocationModalVisibility]: (state: State.ClientAdmin, action: Action<boolean>): State.ClientAdmin => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      seatManagementDeallocation: {
        ...state.seatManagement.seatManagementDeallocation,
        modalVisible: action.payload,
      },
    },
  }),

  [setDealloctionLoading]: (state: State.ClientAdmin, action: Action<boolean>): State.ClientAdmin => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      seatManagementDeallocation: {
        ...state.seatManagement.seatManagementDeallocation,
        deallocateLoading: action.payload,
      },
    },
  }),

  [setSelectedSeatsToDeallocate]: (
    state: State.ClientAdmin,
    action: Action<{
      contract: SeatManagement.ServiceContract;
      lineItemsToDeallocate: SeatManagement.ContractLineItem[];
      pendingList?: string[];
    } | null>
  ): State.ClientAdmin => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      seatManagementDeallocation: {
        ...state.seatManagement.seatManagementDeallocation,
        selectedSeatsToDeallocate: action.payload,
      },
    },
  }),
  [deallocateLineItems]: {
    next: (state: State.ClientAdmin): State.ClientAdmin => ({
      ...state,
      seatManagement: {
        ...state.seatManagement,
        seatManagementDeallocation: {
          ...state.seatManagement.seatManagementDeallocation,
          deallocateLoading: false,
          selectedSeatsToDeallocate: null,
          modalVisible: false,
          error: null,
        },
      },
    }),
    throw: (
      state: State.ClientAdmin,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.ClientAdmin => ({
      ...state,
      seatManagement: {
        ...state.seatManagement,
        seatManagementDeallocation: {
          ...state.seatManagement.seatManagementDeallocation,
          deallocateLoading: false,
          selectedSeatsToDeallocate: null,
          modalVisible: false,
          error,
        },
      },
    }),
  },
  [getOrganizations]: (state: State.ClientAdmin, action: Action<State.Organization[]>): State.ClientAdmin => ({
    ...state,
    firmInformation: {
      ...state.firmInformation,
      organizations: {
        list: action.payload,
        isFetched: true,
        error: null,
      },
    },
  }),
  [getClientTuitionProviders]: (state: State.ClientAdmin, action: Action<State.Organization[]>): State.ClientAdmin => ({
    ...state,
    firmInformation: {
      ...state.firmInformation,
      organizations: {
        list: action.payload,
        isFetched: true,
        error: null,
      },
    },
  }),
  [getOrganizationAdminsByRole]: (
    state: State.ClientAdmin,
    action: Action<{ organizationAdmins: CenterAdmin.Contact[][] }>
  ): State.ClientAdmin => {
    return {
      ...state,
      firmInformation: {
        ...state.firmInformation,
        organizationAdmins: {
          list: action.payload.organizationAdmins,
          isFetched: true,
        },
      },
    };
  },
  [setOrganizationAdminsLoading]: (state: State.ClientAdmin): State.ClientAdmin => {
    return {
      ...state,
      firmInformation: {
        ...state.firmInformation,
        organizationAdmins: {
          ...state.firmInformation.organizationAdmins,
          isFetched: false,
        },
      },
    };
  },
  [setExtendProductAccessIsLoading]: {
    next: (state: State.ClientAdmin, action: any) => {
      return {
        ...state,
        seatManagement: {
          ...state.seatManagement,
          productAccessExtension: {
            ...state.seatManagement.productAccessExtension,
            isLoading: action.payload,
          },
        },
      };
    },
  },
  [setExtendProductAccessDetails]: {
    next: (state: State.ClientAdmin, action: any) => {
      return {
        ...state,
        seatManagement: {
          ...state.seatManagement,
          productAccessExtension: {
            ...state.seatManagement.productAccessExtension,
            status: action.payload.status,
            date: action.payload.date,
            userLength: action.payload.userLength,
            productName: action.payload.productName,
            existingEndDate: action.payload.existingEndDate,
          },
        },
      };
    },
  },
  [toggleProductAccessExtensionModal]: {
    next: (state: State.ClientAdmin, action: any) => {
      return {
        ...state,
        seatManagement: {
          ...state.seatManagement,
          productAccessExtension: {
            ...state.seatManagement.productAccessExtension,
            ongoingExtensionModalIsOpen: !state.seatManagement.productAccessExtension.ongoingExtensionModalIsOpen,
          },
        },
      };
    },
  },
  [getRecentExtensionProcessStatus]: {
    next: (state: State.ClientAdmin, action: any) => {
      return {
        ...state,
        seatManagement: {
          ...state.seatManagement,
          productAccessExtension: {
            ...state.seatManagement.productAccessExtension,
            productAccessExtension: action.payload,
          },
        },
      };
    },
  },
};

export {
  getInvoices,
  getInvoiceFile,
  setDownloadingId,
  resetModule,
  getInvoiceLoading,
  getInvoice,
  setInvoiceCheckoutPage,
  setSelectedPaymentOption,
  setSelectedPaymentAmountOption,
  setSelectedPaymentAmountOptionCima,
  isValidAmountCheckLoading,
  payInvoices,
  getInvoicePaymentConfirmationResultLoading,
  getInvoicePaymentConfirmationResult,
  goToInvoiceCheckoutErrorPage,
  resetInvoicePaymentConfirmationData,
  getServiceContracts,
  seatMgmtLoading,
  showSeatManagementEmailInviteModal,
  hideSeatManagementEmailInviteModal,
  getOrganizations,
  getClientTuitionProviders,
  getOrganizationAdminsByRole,
  setOrganizationAdminsLoading,
  getSeatManagementInvite,
  extendProductAccess,
  setExtendProductAccessIsLoading,
  setExtendProductAccessDetails,
  getRecentExtensionProcessStatus,
  toggleProductAccessExtensionModal,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
