import React, { useState } from 'react';
import styled, { ThemedStyledProps, DefaultTheme, css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { savedItemsSelector } from 'modules/savedItems';
import { savedBlogPostsSelector } from 'modules/savedBlogPosts';
import { copy } from 'modules/copy/actions';
import { useToggleSavedItem } from 'hooks/useToggleSavedItem';
import { useProductsPriceCurrency } from 'hooks/useProductsPriceCurrency';
import { ContentLock } from 'components/molecules/ContentLock/ContentLock';
import { ProductDetails } from 'components/molecules/ProductDetails/ProductDetails';
import {
  LazyImage,
  Button,
  ButtonEnums,
  Category,
  CardThumbnail,
  DatePublished,
  ExternalSourceLabel,
  CardTitleSubtitle,
  Link,
  LinkEnums,
  OnlyDesktop,
  OnlyMobile,
  ContentSource,
} from 'components/atoms';
import { ContentTypes } from 'constants/index';
import { MomentHelpers, getContentUrl, getFullContentUrl, arrayIncludes } from 'utils';
import { CLICK, handleEvent } from 'utils/Analytics';
import { Bookmark, Bookmarked, Copy } from 'components/atoms/svg';
import {
  userMemberTypeSelector,
  isAuthSelector,
  userLocationSelector,
  isUserSuspendedSelector,
} from 'modules/user/selectors';
import { Product, Content } from 'mxp-schemas';
import { productCurrencySelector } from 'modules/products/selectors';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';

interface Props {
  className?: string;
  contentCardItem: State.ContentCardItem;
  isAggregationCard?: boolean;
  isDoubleWidthCard?: boolean;
  isAggregationHeroCard?: boolean;
  isInCarousel?: boolean;
  testid?: string;
  isHeroCard?: boolean;
  isEditorialHeroCard?: boolean;
  isSmallCard?: boolean;
  isBgColorDark?: boolean;
  isCampaignCard?: boolean;
  isBundleCard?: boolean;
  hasLargeTitleText?: boolean;
  isTransparentCard?: boolean;
}

export const ContentCard: React.FC<Props> = React.memo(
  ({
    className,
    contentCardItem,
    isAggregationCard = false,
    isDoubleWidthCard = false,
    isInCarousel = false,
    testid,
    isEditorialHeroCard = false,
    isHeroCard = false,
    isAggregationHeroCard = false,
    isSmallCard = false,
    isBgColorDark = false,
    isCampaignCard = false,
    isBundleCard = false,
    hasLargeTitleText = false,
    isTransparentCard = false,
  }) => {
    const dispatch = useDispatch();

    const {
      id,
      title,
      slug,
      externalUrl,
      description,
      timeToConsume,
      contentCategory,
      image,
      contentType,
      contentfulType,
      credits,
      productType,
      level,
      variantsCount,
      startDate,
      endDate,
      availableFormat,
      conferenceCity,
      conferenceState,
      dateCreated,
      contentSource,
      downloads,
      roles,
      matchedRoles,
      isLocked,
      prices,
      parentProductInfo,
      topicalSubscriptions,
      bundleCardInfo,
      bundleInfo,
      fundName,
    } = contentCardItem;

    const currency = useSelector(productCurrencySelector);
    const { country } = useSelector(userLocationSelector);
    const { useSuspendedRestriction } = useSelector(featureTogglesSelector);
    const userEthicsStatus = useSelector(isUserSuspendedSelector);
    const isUserSuspendedByEthics: boolean = Boolean(useSuspendedRestriction && userEthicsStatus);
    const savedItems: string[] | null = useSelector(savedItemsSelector);
    const savedBlogPosts: string[] | null = useSelector(savedBlogPostsSelector);
    const membershipType = useSelector(userMemberTypeSelector);
    const isAuth = useSelector(isAuthSelector);
    const isSaved: boolean = arrayIncludes([...(savedItems || []), ...(savedBlogPosts || [])], id);

    const handleBookmarkButtonClick = useToggleSavedItem({
      id,
      isSaved,
      contentSource,
      title,
      description,
      slug,
      externalUrl,
    });
    const isContribution: boolean = productType?.includes(Product.ProductType.CONTRIBUTION);
    const isProduct: boolean = contentType.slug === ContentTypes.PRODUCT;

    const { priceRange, currencyLabel, currencySign } = useProductsPriceCurrency(prices, currency.label, country);

    const fileSize: string = downloads && downloads.length ? downloads[0].fileSize : '';
    const duration: string = React.useMemo(
      (): string => (timeToConsume ? MomentHelpers.getSecondsToMinutes(timeToConsume) : ''),
      [timeToConsume]
    );
    // Will check if the content is download template
    const isDownloadTemplate: boolean = contentfulType === ContentTypes.DOWNLOAD;
    const consumptionValue = MomentHelpers.getTimeToConsumeOrSize({
      time: timeToConsume,
      category: contentfulType,
      downloads,
    });

    const isAICPAInsightsBlog: boolean = contentSource === Content.BlogContentSource;

    const handleLinkClick = React.useCallback((): void => {
      const bundleUpsellPrefix = isBundleCard || bundleInfo ? 'bundle-upsell:' : '';
      handleEvent({ clickValue: `${bundleUpsellPrefix}product-card:link:int:${title}:/${slug}` }, CLICK);
    }, [title, slug, isBundleCard, bundleInfo]);

    const handleCopy = React.useCallback(
      (event: React.SyntheticEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const url = getFullContentUrl(contentCardItem);

        dispatch(copy({ id, copiedText: url, notificationText: title }));
      },
      [id, title, contentCardItem, dispatch]
    );

    // Dynamically remove image behind Category area to make it transparent
    const [imgClip, setImgClip] = useState('');

    const headerRef: any = React.useRef();
    const categoryRef: any = React.useRef();

    const handleClipResize = () => {
      if (categoryRef.current && headerRef.current) {
        const imgH = headerRef.current.getBoundingClientRect().height || 0;
        const imgW = headerRef.current.getBoundingClientRect().width || 0;
        const catH = categoryRef.current.getBoundingClientRect().height || 0;
        const catW = categoryRef.current.getBoundingClientRect().width || 0;
        setImgClip(
          `polygon(0 0, ${imgW}px 0, ${imgW}px ${imgH}px, ${catW}px ${imgH}px, ${catW}px ${imgH - catH}px, 0 ${
            imgH - catH
          }px)`
        );
      }
    };

    React.useEffect(() => {
      handleClipResize();
      window.addEventListener('resize', handleClipResize);
      return () => window.removeEventListener('resize', handleClipResize);
    }, []);

    const renderImage = (): React.ReactNode => {
      if (!image?.url) return null;
      const urlParamsJoiner = image.url?.includes('?') ? '&' : '?';
      const defaultSrc: string = `${image.url}${urlParamsJoiner}w=${!isSmallCard ? 530 : 75}&h=${
        !isSmallCard ? 280 : 75
      }&fit=thumb`;
      const defaultImageRatio: string = `${!isSmallCard ? 52.83 : 100}%`;

      return isDoubleWidthCard ? (
        <>
          <OnlyDesktop
            as={StyledLazyImage}
            src={`${image.url}${urlParamsJoiner}w=1100&h=280&fit=thumb`}
            alt={image.altText}
            imageRatio="26.42%"
            clipPath={imgClip}
            stretchByWidth
          />
          <OnlyMobile
            as={StyledLazyImage}
            src={defaultSrc}
            alt={image.altText}
            imageRatio={defaultImageRatio}
            clipPath={imgClip}
            stretchByWidth
          />
        </>
      ) : (
        <StyledLazyImage
          src={defaultSrc}
          alt={image.altText}
          imageRatio={defaultImageRatio}
          clipPath={imgClip}
          stretchByWidth
        />
      );
    };

    return (
      <StyledContainer
        className={className}
        isAggregationCard={isAggregationCard}
        isDoubleWidthCard={isDoubleWidthCard}
        isInCarousel={isInCarousel}
        isHeroCard={isHeroCard}
        data-testid={testid}
        isSmallCard={isSmallCard}
        isBundleCard={isBundleCard}
        isTransparentCard={isTransparentCard}
        isBgColorDark={isBgColorDark}
      >
        {!isProduct && (
          <ContentLock
            isLocked={isLocked}
            testId={testid}
            matchedRoles={matchedRoles}
            contentRoles={roles}
            isTextHidden={isSmallCard}
            userStatus={membershipType}
            restrictionDetails={contentCardItem.restrictionDetails}
            topicalSubscriptions={topicalSubscriptions}
          />
        )}
        <Link
          underline={LinkEnums.underline.noUnderlineOnHover}
          type={LinkEnums.type.standaloneLink}
          isExternal={Boolean(externalUrl)}
          to={getContentUrl(contentCardItem)}
          onClick={handleLinkClick}
          testId={testid}
          target={isDownloadTemplate ? '_blank' : ''}
          aria-label={title}
        >
          <StyledCardItem isSmallCard={isSmallCard}>
            <StyledHeader tabIndex={0} isSmallCard={isSmallCard} ref={headerRef}>
              {renderImage()}
              <StyledCardThumbnail isSmallCard={isSmallCard}>
                {contentfulType && isSmallCard && <CardThumbnail contentType={contentfulType} />}
              </StyledCardThumbnail>
              <Category
                categoryName={isProduct && productType ? productType.join(', ') : contentCategory?.name}
                categorySlug={contentCategory?.slug}
                isProduct={isProduct}
                noCategoryName={isSmallCard || isAggregationCard || isHeroCard}
                isHeroCard={isHeroCard}
                thumbnail
                isBundleCard={isBundleCard}
                isBgColorDark={isBgColorDark}
                categoryRef={categoryRef}
              />
            </StyledHeader>
            <StyledBody
              isHeroCard={isHeroCard}
              isAggregationCard={isAggregationCard}
              leftAlignedBottomItems={isEditorialHeroCard || isHeroCard}
              isSmallCard={isSmallCard}
              isBgColorDark={isBgColorDark}
              isCampaignCard={isCampaignCard}
              isBundleCard={isBundleCard}
              isTransparentCard={isTransparentCard}
            >
              <CardTitleSubtitle
                testId="card-title-subtitle"
                title={title}
                isContentCard
                lines={isSmallCard ? 2 : isAggregationCard || isHeroCard ? 2 : 4}
                hasLargeTitleText={hasLargeTitleText}
                isContribution={isContribution}
                fundName={fundName}
              />
              {isProduct && (
                <ProductDetails
                  currencySign={currencySign}
                  currencyLabel={currencyLabel}
                  studyLevel={level}
                  startDate={startDate}
                  endDate={endDate}
                  fileFormat={availableFormat}
                  priceRange={priceRange}
                  credits={credits}
                  conferenceCity={conferenceCity}
                  conferenceState={conferenceState}
                  variantsCount={variantsCount}
                  isRelatedContent
                  parentProductSessions={parentProductInfo?.sessions}
                  upcomingParentProductDates={parentProductInfo?.upcomingDates}
                  isBundleCard={isBundleCard || !!bundleInfo}
                  isAggregationCard={isAggregationCard}
                  userMemberType={membershipType}
                  isAuth={isAuth}
                  bundleCardInfo={bundleCardInfo}
                  bundleInfo={bundleInfo}
                  isContribution={isContribution}
                  isBgColorDark={isBgColorDark}
                  isUserSuspendedByEthics={isUserSuspendedByEthics}
                />
              )}
              <StyledBottom
                isLeftAligned={isHeroCard || isAggregationHeroCard || isAggregationCard || isEditorialHeroCard}
                isAggregationCard={isAggregationCard}
                isSmallCard={isSmallCard}
                isBgColorDark={isBgColorDark}
                isCampaignCard={isCampaignCard}
                isHeroCard={isHeroCard}
              >
                <StyledDetails isSmallCard={isSmallCard}>
                  {!isProduct && (
                    <StyledDatePublished
                      isAggregationCard={isAggregationCard}
                      isHeroCard={isHeroCard}
                      isAggregationHeroCard={isAggregationHeroCard}
                      isEditorialHeroCard={isEditorialHeroCard}
                      isExternalArticle={Boolean(externalUrl)}
                      isSmallCard={isSmallCard}
                      isCampaignCard={isCampaignCard}
                      isBgColorDark={isBgColorDark}
                      tabIndex={0}
                    >
                      {dateCreated && <DatePublished date={dateCreated} />}
                      {consumptionValue &&
                        (isAggregationCard || isHeroCard || isAggregationHeroCard || isEditorialHeroCard) && (
                          <>
                            {isSmallCard && <StyledDotSeparator />}
                            {isSmallCard && (
                              <ConsumptionValue
                                isExternal={!!externalUrl}
                                isSmallCard={isSmallCard}
                                isStacked={!isSmallCard && isAICPAInsightsBlog}
                                tabIndex={0}
                              >
                                {consumptionValue}
                              </ConsumptionValue>
                            )}
                          </>
                        )}
                      {contentSource &&
                        isAICPAInsightsBlog &&
                        !isSmallCard &&
                        (isAggregationCard || isHeroCard || isAggregationHeroCard || isEditorialHeroCard) && (
                          <StyledContentSource isStacked={!!(dateCreated && consumptionValue)}>
                            <ContentSource contentSource={contentSource} />
                          </StyledContentSource>
                        )}
                    </StyledDatePublished>
                  )}
                  {contentfulType && (duration || fileSize) && (
                    <CardThumbnail isBody timeToConsume={duration} contentType={contentfulType} fileSize={fileSize} />
                  )}
                  {!isSmallCard && externalUrl && (
                    <>
                      {isSmallCard && <StyledDotSeparator />}
                      <StyledExternalSourceLabel
                        isAggregationCard={isAggregationCard}
                        isHeroCard={isHeroCard}
                        isAggregationHeroCard={isAggregationHeroCard}
                        isEditorialHeroCard={isEditorialHeroCard}
                        label={contentSource}
                        isSmallCard={isSmallCard}
                        isBgColorDark={isBgColorDark}
                        to={getContentUrl(contentCardItem)}
                      />
                    </>
                  )}
                </StyledDetails>
                {Boolean((!isSmallCard && !isProduct) || isAggregationCard || isHeroCard || isAggregationHeroCard) && (
                  <ButtonWrapper>
                    <CopyButton
                      isProduct={isProduct}
                      aria-label={`Copy ${title}`}
                      testId={`copy-button-${id}`}
                      onClick={handleCopy}
                      variant={
                        (isHeroCard && !isCampaignCard) ||
                        (isHeroCard && isCampaignCard && isBgColorDark) ||
                        isBgColorDark
                          ? ButtonEnums.variants.iconWhiteWithCircle
                          : ButtonEnums.variants.iconWithCircle
                      }
                    >
                      <OffScreenSpan>{title}</OffScreenSpan>
                      <Copy />
                    </CopyButton>
                    {!isProduct && (
                      <StyledBookmarkButton
                        aria-label={`Bookmark ${title}`}
                        testId={`bookmark-button-${id}`}
                        onClick={handleBookmarkButtonClick}
                        active={isSaved}
                        variant={
                          (isHeroCard && !isCampaignCard) ||
                          (isHeroCard && isCampaignCard && isBgColorDark) ||
                          isBgColorDark
                            ? ButtonEnums.variants.iconWhiteWithCircle
                            : ButtonEnums.variants.iconWithCircle
                        }
                      >
                        <OffScreenSpan>{title}</OffScreenSpan>
                        {isSaved ? <Bookmarked /> : <Bookmark />}
                      </StyledBookmarkButton>
                    )}
                  </ButtonWrapper>
                )}
              </StyledBottom>
            </StyledBody>
          </StyledCardItem>
        </Link>
      </StyledContainer>
    );
  }
);

const OffScreenSpan = styled.span`
  border: 0 !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`;

const StyledContainer = styled.div<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  -webkit-tap-highlight-color: transparent;
  height: 95%;
  min-height: 300px;
  border-radius: 0.0625rem;
  width: 100%;
  box-shadow: ${props => (props.isAggregationCard ? 'none' : '0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.1)')};
  background-color: ${props =>
    props.isHeroCard || props.isBundleCard || props.isTransparentCard
      ? 'rgba(255, 255, 255, 0.1)'
      : props.theme.colors.neutralWhite};
  display: block;
  position: relative;
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-height: 1px;
    ${props => (props.isInCarousel ? '' : `margin: 0 0 ${props.theme.pxToRem(30)} 0`)};
  }
  ${props =>
    props.isSmallCard &&
    `
    min-height: auto
    &&& > div:first-child {
      top: 0;
      left: 0;
    }
  `}

  &:hover {
    outline: 1px solid
      ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.primaryPurple)};

    a > article > div:last-child > div:first-child > div {
      color: ${props => props.theme.colors.primaryPurple};
    }

    ${props =>
      (props.isHeroCard || props.isBundleCard || props.isTransparentCard) &&
      `
        background-color: rgba(255, 255, 255, 0.2);
        outline-color: rgba(255, 255, 255, 0.6);
    `}
  }
`;

interface StyledDetailsProps {
  isSmallCard: boolean;
}

const StyledDetails = styled.div<StyledDetailsProps>`
  width: ${props => (props.isSmallCard ? 100 : 60)}%;
`;

interface StyledCardItemProps {
  isSmallCard: boolean;
}

const StyledCardItem = styled.article<StyledCardItemProps>`
  ${props => !props.isSmallCard && 'display: block;'}
  ${props =>
    props.isSmallCard &&
    `
    display: flex
  `}
`;

interface StyledHeaderProps {
  isSmallCard: boolean;
}

const StyledHeader = styled.div<StyledHeaderProps>`
  position: relative;
  width: ${props => (props.isSmallCard ? props.theme.pxToRem(75) : 'auto')};
`;

interface StyledCardThumbnailProps {
  isSmallCard: boolean;
}

const StyledCardThumbnail = styled.div<StyledCardThumbnailProps>`
  &&& {
    position: absolute;
    top: ${props => (props.isSmallCard ? 0 : '0.625rem')};
    right: ${props => (props.isSmallCard ? 0 : '0.625rem')};
    ${props =>
      props.isSmallCard &&
      `
      &&& > div > div {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.5rem;
        width: 1.5rem;
      }
      &&& > div > div > i {
          padding: 0;
          margin: 0;
        }
    `}
  }
`;

interface StyledBodyProps {
  isSmallCard: boolean;
  isAggregationCard?: boolean;
  leftAlignedBottomItems?: boolean;
  isHeroCard?: boolean;
  isBgColorDark?: boolean;
  isCampaignCard?: boolean;
  isBundleCard?: boolean;
  isTransparentCard?: boolean;
}

const StyledBody = styled.div<StyledBodyProps>`
  padding: ${props =>
    props.isAggregationCard
      ? `${props.theme.pxToRem(8)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(48)}`
      : `${props.theme.pxToRem(8)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(32)}`};
  padding-bottom: ${props => (props.leftAlignedBottomItems ? props.theme.pxToRem(56) : props.theme.pxToRem(32))};
  margin-top: ${props => props.theme.pxToRem(10)};
  ${props =>
    (props.isHeroCard || props.isBundleCard || props.isTransparentCard) &&
    `
      &&&&&&&&&&&& * {color: ${
        props.isCampaignCard && !props.isBgColorDark ? props.theme.colors.neutralGrey8 : props.theme.colors.neutralWhite
      }
        }
  `}
  ${props =>
    props.isSmallCard &&
    `
    width: ${props.theme.pxToRem(265 - 75)}
    padding: ${props.theme.pxToRem(5)} ${props.theme.pxToRem(10)} 0;
    margin-top: ${props.theme.pxToRem(10)};
    display: flex;
    flex-direction: column;
    &&& > div:first-child {
      font-size: ${props.theme.pxToRem(14)}
      padding-bottom: 0
      margin-bottom: auto
    }
  `}
`;

interface StyledBottomProps {
  isAggregationCard?: boolean;
  isLeftAligned?: boolean;
  isSmallCard?: boolean;
  isBgColorDark?: boolean;
  isCampaignCard?: boolean;
  isHeroCard?: boolean;
}

const StyledBottom = styled.div<StyledBottomProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;

  ${props =>
    !props.isSmallCard &&
    `    left: 1.25rem;
    overflow: hidden;
  `};

  ${props =>
    props.isAggregationCard &&
    `
      right: ${props.theme.pxToRem(10)};
    `}
  ${props => props.isLeftAligned && 'text-align: left;'}

  ${props =>
    props.isHeroCard &&
    `
    &&&& {
      svg path {
        fill: ${
          props.isCampaignCard && !props.isBgColorDark
            ? props.theme.colors.neutralGrey7
            : props.theme.colors.neutralWhite
        }
    }
  `}
`;

interface StyledDatePublishedProps {
  isAggregationCard: boolean;
  isHeroCard: boolean;
  isAggregationHeroCard: boolean;
  isEditorialHeroCard: boolean;
  isExternalArticle: boolean;
  isSmallCard: boolean;
  isCampaignCard: boolean;
  isBgColorDark: boolean;
}

const StyledDatePublished = styled.div<StyledDatePublishedProps>`
  ${props => !props.isSmallCard && `width: ${props.isExternalArticle ? '100%' : 'auto'}`}
  font-size: ${props => props.theme.fontSizes.xxs};
  small {
    color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey7)};
  }

  ${props =>
    (props.isAggregationCard || props.isHeroCard || props.isAggregationHeroCard || props.isEditorialHeroCard) &&
    css`
      line-height: 1.33;
      color: ${props.theme.colors.neutralGrey7};
    `};
  ${props =>
    props.isSmallCard &&
    `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
  ${props =>
    props.isHeroCard &&
    `
      &&&&&&&&&&&& * {color: ${
        props.isCampaignCard && !props.isBgColorDark ? props.theme.colors.neutralGrey7 : props.theme.colors.neutralWhite
      }
        }
  `}
`;

const StyledExternalSourceLabel = styled(ExternalSourceLabel)<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  ${props =>
    (props.isAggregationCard ||
      props.isAggregationHeroCard ||
      props.isHeroCard ||
      props.isEditorialHeroCard ||
      props.isTransparentCard) &&
    `
    margin-top: ${props.theme.pxToRem(6)};
  `};

  ${props =>
    props.isSmallCard &&
    `
    padding: 0;
    margin: 0;
  `}
`;

interface ConsumptionValueProps {
  isExternal: boolean;
  isSmallCard: boolean;
  isStacked?: boolean;
}

const ConsumptionValue = styled.div<ConsumptionValueProps>`
  font-size: ${props => props.theme.fontSizes.xxs};
  margin-top: ${props => (props.isExternal || props.isSmallCard || props.isStacked ? '0' : props.theme.pxToRem(6))};
`;

const StyledContentSource = styled.div<{ isStacked: boolean }>`
  margin-top: ${props => (props.isStacked ? '0' : props.theme.pxToRem(6))};
`;

const ButtonWrapper = styled.div`
  button {
    margin-bottom: 0.3rem !important;
  }
  & {
    display: flex;
    line-height: 0;
  }
`;

const CopyButton = styled(Button)<{ isProduct: boolean }>`
  &&&& {
    margin-right: ${props => (!props.isProduct ? '1rem' : '0.3rem')};
    margin-right: 1rem;
    ${props => props.theme.mediaQueries.computerMin} {
      &&:focus {
        border-color: transparent;
        background: ${props => props.theme.colors.primaryLightPurple};
        outline: none;
        path {
          fill: ${props =>
            props.variant === ButtonEnums.variants.iconWhiteTone
              ? props.theme.colors.primaryIndicatorPurple
              : props.theme.colors.neutralWhite};
        }
      }
    }
  }
`;

const StyledBookmarkButton = styled(Button)`
  &&&& {
    ${props => props.theme.mediaQueries.computerMin} {
      &&:focus {
        border-color: transparent;
        background: ${props => props.theme.colors.primaryLightPurple};
        outline: none;
        path {
          fill: ${props =>
            props.variant === ButtonEnums.variants.iconWhiteTone
              ? props.theme.colors.primaryIndicatorPurple
              : props.theme.colors.neutralWhite};
        }
      }
    }
  }
`;

const StyledDotSeparator = styled.div`
  width: ${props => props.theme.pxToRem(3)};
  height: ${props => props.theme.pxToRem(3)};
  border-radius: 50%;
  background-color: ${props => props.theme.colors.neutralGrey7};
`;

const StyledLazyImage = styled(LazyImage)<{ clipPath: string }>`
  ${props => props.clipPath && `clip-path: ${props.clipPath};`}
`;
