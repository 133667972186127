import { createSelector } from 'reselect';
import { currentContentIdSelector } from 'modules/layouts/selectors';
import { userRolesSelector, isAuthSelector } from 'modules/user/selectors';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';
import { staticLandingPageAccessRestrictionSelector } from 'modules/staticLandingPagesContent/selectors';

import { emptyObject, emptyArray } from 'utils';
import { User, Content, Contentful } from 'mxp-schemas';
import { User as UserUtils } from 'mxp-utils';
import { skillsHashSelector } from 'modules/skills/selectors';
import { industriesHashSelector } from 'modules/industries/selectors';
import { trendsHashSelector } from 'modules/trends/selectors';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root): State.Content => state.content,
  (content: State.Content): State.Content => content
);

export const contentLoadingSelector = createSelector(
  rootSelector,
  (content: State.Content): boolean => content.loading
);

export const isContentFetchedSelector = createSelector(
  rootSelector,
  (content: State.Content): boolean | null => content.isContentFetched
);

export const contentHashSelector = createSelector(
  rootSelector,
  (content: State.Content): State.ContentHash | null => content.contentHash
);

export const contentSelector = createSelector(
  contentHashSelector,
  (contentHash: State.ContentHash | null): State.ContentItem[] => Object.values(contentHash || emptyObject)
);

export const contentPageItemSelector = createSelector(
  [contentHashSelector, currentContentIdSelector],
  (contentHash: State.ContentHash | null, id: string | null): State.ContentItem | null => {
    return id && contentHash && contentHash[id] && contentHash[id].hasAllProps ? contentHash[id] : null;
  }
);

export const contentMetaSelector = createSelector(rootSelector, (content: State.Content): State.Meta => content.meta);

// FixMe. It seems like unnecessary selector, logic can be switched to contentLoadingSelector only
export const contentAllLoadedSelector = createSelector(
  [contentMetaSelector, contentHashSelector],
  (meta: State.Meta, contentHash: State.ContentHash | null): boolean => {
    return contentHash ? Object.keys(contentHash).length >= meta.total : false;
  }
);

export const contentAggregationSelector = createSelector(
  contentMetaSelector,
  (meta: State.Meta): State.ContentAggs | null => meta.aggs
);

export const relatedContentSelector = createSelector(
  rootSelector,
  (content: State.Content): State.ContentCardItem[] | null => content.relatedContent
);

export const aggsPageContentSelector = createSelector(
  rootSelector,
  (content: State.Content): State.AggsPageContent | null => content.aggsPageContent
);

export const toolkitPageContentSelector = createSelector(
  rootSelector,
  (content: State.Content): State.ToolkitPageContent | null => content.toolkitPageContent
);
export const productAggPageContentSelector = createSelector(
  rootSelector,
  (content: State.Content): State.ProductAggsPageContent => content.productAggPageContent
);

export const brandedImageSelectorFactory = (imageKey: string) =>
  createSelector(
    rootSelector,
    (content: State.Content): State.BrandImageItem[] | undefined => content.brandImages[imageKey]
  );

export const contentRoleSelector = createSelector(
  contentPageItemSelector,
  (content: State.ContentItem | null): User.MembershipIdsEnum[] => (content ? content.roles : emptyArray)
);

export const contentMemberSelector = createSelector(
  [contentPageItemSelector, staticLandingPageAccessRestrictionSelector],
  (
    content: State.ContentItem | null,
    staticLandingContent: State.ContentItem | null
  ): {
    [key: string]: User.MembershipIdsEnum[];
  } => {
    const hasContent = Boolean(content) ? content : staticLandingContent;
    const contentMember: { [key: string]: User.MembershipIdsEnum[] } | undefined = Array.isArray(hasContent?.roles)
      ? hasContent?.roles.reduce((acc: { [key: string]: User.MembershipIdsEnum[] }, role) => {
          const membership = UserUtils.membershipMapToRoleType(role);
          return {
            ...acc,
            [membership]: [...(acc[membership] || emptyArray), role],
          };
        }, {})
      : emptyObject;
    return contentMember || emptyObject;
  }
);

export const contentIsExclusiveSelector = createSelector(
  [contentMemberSelector, featureTogglesSelector, contentPageItemSelector],
  (
    contentMember: { [key: string]: User.MembershipIdsEnum[] },
    featureToggles: State.FeatureToggleByKey,
    content: State.ContentItem | null
  ): Common.ContentExclusive => {
    const isFirmMemberExclusive: boolean = Boolean(contentMember.Firm);
    const isSectionExclusive: boolean = contentMember.Section && !contentMember.Credentials;
    const isTopicalSubExclusive: boolean =
      contentMember.TopicalSubscription && !(contentMember.Credentials || contentMember.Section);
    const isMemberExclusive: boolean =
      contentMember.AICPAMembership && !(contentMember.Credentials || contentMember.Section);
    const isCredentialExclusive: boolean = contentMember.Credentials && !contentMember.Section;
    const isRegisteredExclusive: boolean = !!(
      Object.keys(contentMember).length === 0 &&
      content &&
      Array.isArray(content.roles) &&
      content.roles.length === 0 &&
      content.isLocked !== Content.ContentLockStatus.NO_LOCK
    );

    const isFreeContent: boolean = !!(content?.isLocked === Content.ContentLockStatus.NO_LOCK);
    const isSectionAndCredentialExclusive: boolean =
      contentMember.Credentials &&
      contentMember.Credentials.length > 0 &&
      contentMember.Section &&
      contentMember.Section.length > 0;
    return {
      isFreeContent,
      isSectionExclusive,
      isMemberExclusive,
      isCredentialExclusive,
      isRegisteredExclusive,
      isSectionAndCredentialExclusive,
      isTopicalSubExclusive,
      isFirmMemberExclusive,
    };
  }
);

export const contentIsLockedSelector = createSelector(
  [contentPageItemSelector, staticLandingPageAccessRestrictionSelector],
  (content: State.ContentItem | null, staticLandingContent: State.ContentItem | null): boolean => {
    const hasContent = Boolean(content) ? content : staticLandingContent;
    return hasContent?.isLocked === Content.ContentLockStatus.LOCKED;
  }
);

export const contentSuppressContentSelector = createSelector(
  [userRolesSelector, featureTogglesSelector, isAuthSelector, contentIsLockedSelector, contentRoleSelector],
  (
    userAccessRoles: string[],
    featureToggles: State.FeatureToggleByKey,
    isAuth: boolean | null,
    isLocked: boolean,
    contentRoles: User.MembershipIdsEnum[]
  ): boolean => {
    return (
      isLocked &&
      ((!isAuth && contentRoles.length > 0) ||
        (!!isAuth &&
          (userAccessRoles.length === 0 ||
            (userAccessRoles.length === 1 &&
              userAccessRoles[0] !== User.MembershipIdsEnum.MRUSR0001 &&
              userAccessRoles[0] !== User.MembershipIdsEnum.MRUKR0001))))
    );
  }
);

export const topicContentListSelector = createSelector(
  aggsPageContentSelector,
  (content: State.AggsPageContent | null): Contentful.AggregationPages.TopicContentList[] | [] =>
    content?.topicContentList || emptyArray
);

export const industryContentListSelector = createSelector(
  aggsPageContentSelector,
  (content: State.AggsPageContent | null): Contentful.AggregationPages.IndustryContentList[] | [] =>
    content?.industryContentList || emptyArray
);

export const trendContentListSelector = createSelector(
  aggsPageContentSelector,
  (content: State.AggsPageContent | null): Contentful.AggregationPages.TrendContentList[] | [] =>
    content?.trendContentList || emptyArray
);

export const skillContentListSelector = createSelector(
  aggsPageContentSelector,
  (content: State.AggsPageContent | null): Contentful.AggregationPages.SkillContentList[] | [] =>
    content?.skillContentList || emptyArray
);

// react-frontload with react-loadable call method twice
export const skipContentFrontloadRequestSelector = createSelector(
  rootSelector,
  (content: State.Content): boolean => content.skipContentFrontloadRequest
);

export const contentBlogsHashSelector = createSelector(
  rootSelector,
  (content: State.Content): State.ContentHash | null => content.blogsContentHash
);

export const contentBlogsSelector = createSelector(
  contentBlogsHashSelector,
  (contentBlogsHash: State.ContentHash | null): State.ContentItem[] => Object.values(contentBlogsHash || emptyObject)
);

export const contentBlogsMetaSelector = createSelector(
  rootSelector,
  (content: State.Content): State.Meta => content.blogsMeta
);

export const contentBlogsLoadingSelector = createSelector(
  rootSelector,
  contentBlogsMetaSelector,
  contentBlogsHashSelector,
  (content: State.Content, blogsMeta: State.Meta, contentBlogsHash: State.ContentHash | null): boolean => {
    const hasContentBlogs = contentBlogsHash ? Object.keys(contentBlogsHash).length >= blogsMeta.total : false;
    return hasContentBlogs && content.blogsLoading;
  }
);

export const contentAllBlogsLoadedSelector = createSelector(
  [contentBlogsMetaSelector, contentBlogsHashSelector],
  (blogsMeta: State.Meta, blogsContentHash: State.ContentHash | null): boolean => {
    return blogsContentHash ? Object.keys(blogsContentHash).length >= blogsMeta.total : false;
  }
);

export const contentSubskillsFollowSelector = createSelector(
  [skillsHashSelector, contentPageItemSelector],
  (skillsHash: State.SkillsHash | null, contentPageItem: State.ContentItem | null) => {
    if (!skillsHash || !contentPageItem || !contentPageItem.subskills) return emptyArray;
    return contentPageItem.subskills.reduce((acc: any[], item: any) => {
      if (!item || !item.name) return acc;

      let parentId;
      for (const skill in skillsHash) {
        if (Object.keys(skillsHash[skill].subskills).includes(item.id)) parentId = skill;
      }

      let parentSlug;
      for (const skill in skillsHash) {
        if (Object.keys(skillsHash[skill].subskills).includes(item.id)) {
          parentSlug = skillsHash[skill].slug;
        }
      }

      acc.push({
        id: item.id,
        slug: item.slug,
        name: item.name,
        skillId: parentId,
        skillSlug: parentSlug,
      });
      return acc;
    }, []);
  }
);

export const contentSkillsFollowSelector = createSelector(
  [skillsHashSelector, contentPageItemSelector],
  (skillsHash: State.SkillsHash | null, contentPageItem: State.ContentItem | null) => {
    if (!skillsHash || !contentPageItem || !contentPageItem.skills) return emptyArray;

    return contentPageItem.skills.reduce((acc: any[], { id }: { id: string }) => {
      const skill: State.Skill = skillsHash[id];
      if (skill && skill.name) {
        acc.push({ id, name: skill.name, sort: skill.sort, slug: skill.slug });
      }
      return acc;
    }, []);
  }
);

export const contentIndustriesFollowSelector = createSelector(
  [industriesHashSelector, contentPageItemSelector],
  (industriesHash: State.IndustriesHash | null, contentPageItem: State.ContentItem | null) => {
    if (!industriesHash || !contentPageItem || !contentPageItem.industries) return emptyArray;

    return contentPageItem.industries.reduce((acc: any[], { id }: { id: string }) => {
      const industry: State.Industry = industriesHash[id];
      if (industry && industry.name) {
        acc.push({ id, name: industry.name, sort: industry.sort, slug: industry.slug });
      }
      return acc;
    }, []);
  }
);

export const contentTrendsFollowSelector = createSelector(
  [trendsHashSelector, contentPageItemSelector],
  (trendsHash: State.TrendsHash | null, contentPageItem: State.ContentItem | null) => {
    if (!trendsHash || !contentPageItem || !contentPageItem.trends) return emptyArray;

    return contentPageItem.trends.reduce((acc: any[], { id }: { id: string }) => {
      const trend: State.Trend = trendsHash[id];
      if (trend && trend.name) {
        acc.push({ id, name: trend.name, sort: trend.sort, slug: trend.slug });
      }
      return acc;
    }, []);
  }
);

export const isComingFromFcmaPropPageSelector = createSelector(
  rootSelector,
  (content: State.Content): boolean => content.isComingFromFcmaPropPageJourney
);

export const getDownloadDocumentsSelector = createSelector(
  rootSelector,
  (content: State.Content): any => content.downloadDocuments
);

export const rawRichTextSelector = createSelector(
  rootSelector,
  (content: State.Content): State.RawRichTextBySlug | null => content.rawRichTextContent
);

export const getPromotedProductsSelector = createSelector(
  rootSelector,
  (content: State.Content): State.PromotedProductsCard[] | null => content.promotedProducts
);
