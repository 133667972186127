import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { matchPath, RouteComponentProps, useLocation } from 'react-router';
import {
  isB2BSelector,
  isOperationsSelector,
  isFirmBillingSelector,
  isCenterMembershipSelector,
  getAdminFeatureToggleByKeySelector,
  getBulkRenewalAccessSelector,
} from 'modules/admin/selectors';
import { PageAdminLookUpUser } from './PageAdminLookUpUser';
import { PageAdminLookUpOrg } from './PageAdminLookUpOrg';
import { PageAdminHomeInvoice } from './PageAdminHomeInvoice';
import { PageAdminLookUpOperations } from './PageAdminLookUpOperations';
import { Container, Heading } from 'components/atoms';
import { Menu } from 'components/atoms/Menu/Menu';
import { Divider } from 'components/atoms/Divider/Divider';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { USE_BULK_RENEWAL_TAB } from 'modules/admin/adminFeatureToggles';
import { PageAdminBulkRenewalContainer } from 'containers/PageAdminBulkRenewalContainer';

enum Tabs {
  USER = 'User',
  ORGANIZATION = 'Organization',
  INVOICE = 'Transaction Print',
  OPERATIONS = 'Operations',
  RENEWAL = 'Bulk Renewal',
}

export const PageAdminLookUp: React.FC<RouteComponentProps> = ({ history }) => {
  const isB2B = useSelector(isB2BSelector);
  const isFirmBilling = useSelector(isFirmBillingSelector);
  const isCenterMembership = useSelector(isCenterMembershipSelector);

  const isOperations = useSelector(isOperationsSelector);

  const useBulkRenewalTab = useSelector(state =>
    getAdminFeatureToggleByKeySelector(state as State.Root, USE_BULK_RENEWAL_TAB)
  );

  const shouldHaveBulkRenewalAccess = useSelector(getBulkRenewalAccessSelector);
  const showBulkRenewalTab = useBulkRenewalTab && shouldHaveBulkRenewalAccess;
  const { pathname } = useLocation();

  const isInvoiceTab = Boolean(
    matchPath(pathname, { path: getPath(Routes.ADMIN_INVOICES), exact: true, strict: false })
  );

  const isUserTab = Boolean(matchPath(pathname, { path: getPath(Routes.ADMIN_USER), exact: true, strict: false }));

  const isBulkRenewalTab = Boolean(
    matchPath(pathname, { path: getPath(Routes.ADMIN_BULK_RENEWAL), exact: true, strict: false })
  );

  const isOrganizationTab = Boolean(
    matchPath(pathname, { path: getPath(Routes.ADMIN_ORGANIZATION_ROOT), exact: true, strict: false })
  );

  const isOperationsTab = Boolean(
    matchPath(pathname, { path: getPath(Routes.ADMIN_OPERATIONS), exact: true, strict: false })
  );

  // tslint:disable-next-line:no-empty
  const push = history?.push || (() => {});

  const [heading, setHeading] = useState('');
  const [activeItem, setActive] = useState(Tabs.USER);

  const handleTabChange = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
      switch (data.name) {
        case Tabs.INVOICE:
          return push(getPath(Routes.ADMIN_INVOICES));
        case Tabs.USER:
          return push(getPath(Routes.ADMIN_USER));
        case Tabs.ORGANIZATION:
          return push(getPath(Routes.ADMIN_ORGANIZATION_ROOT));
        case Tabs.RENEWAL:
          return push(getPath(Routes.ADMIN_BULK_RENEWAL));
        case Tabs.OPERATIONS:
          push(getPath(Routes.ADMIN_OPERATIONS));
      }
    },
    [push]
  );

  const menuTabs = isOperations
    ? [{ label: Tabs.OPERATIONS, key: Tabs.OPERATIONS }]
    : isFirmBilling || isCenterMembership
    ? [
        { label: Tabs.USER, key: Tabs.USER },
        { label: Tabs.ORGANIZATION, key: Tabs.ORGANIZATION },
        { label: Tabs.INVOICE, key: Tabs.INVOICE },
        showBulkRenewalTab ? { label: Tabs.RENEWAL, key: Tabs.RENEWAL } : null,
      ]
    : [
        { label: Tabs.USER, key: Tabs.USER },
        ...(isB2B ? [{ label: Tabs.ORGANIZATION, key: Tabs.ORGANIZATION }] : []),
        { label: Tabs.INVOICE, key: Tabs.INVOICE },
        showBulkRenewalTab ? { label: Tabs.RENEWAL, key: Tabs.RENEWAL } : null,
      ];

  const activeTab = React.useMemo(() => {
    switch (true) {
      case isUserTab:
        setActive(Tabs.USER);
        return <PageAdminLookUpUser />;
      case isInvoiceTab:
        setActive(Tabs.INVOICE);
        return <PageAdminHomeInvoice />;
      case isOrganizationTab:
        setActive(Tabs.ORGANIZATION);
        return <PageAdminLookUpOrg />;
      case isOperationsTab:
        setActive(Tabs.OPERATIONS);
        return <PageAdminLookUpOperations />;
      case isBulkRenewalTab:
        setActive(Tabs.RENEWAL);
        return <PageAdminBulkRenewalContainer />;
      default:
        setActive(isOperations ? Tabs.OPERATIONS : Tabs.USER);
        return isOperations ? <PageAdminLookUpOperations /> : <PageAdminLookUpUser />;
    }
  }, [isUserTab, isInvoiceTab, isOrganizationTab, isOperationsTab, isOperations, isBulkRenewalTab]);

  React.useEffect(() => {
    switch (true) {
      case isInvoiceTab:
        return setActive(Tabs.INVOICE);
      case isUserTab:
        return setActive(Tabs.USER);
      case isOrganizationTab:
        return setActive(Tabs.ORGANIZATION);
      case isOperationsTab:
        return setActive(Tabs.OPERATIONS);
      case isBulkRenewalTab:
        return setActive(Tabs.RENEWAL);
      default:
        setActive(isOperations ? Tabs.OPERATIONS : Tabs.USER);
    }
  }, [
    setActive,
    isUserTab,
    isInvoiceTab,
    isOrganizationTab,
    isOperationsTab,
    isOperations,
    isFirmBilling,
    isCenterMembership,
    isBulkRenewalTab,
  ]);

  React.useEffect(() => {
    switch (true) {
      case isFirmBilling:
        return setHeading('Firm Admin Console');
      case isCenterMembership:
        return setHeading('Centers Admin Console');
      default:
        setHeading('Products & Purchases Console');
    }
  }, [isFirmBilling, isCenterMembership]);

  return (
    <StyledContainer>
      <StyledPreferencesHeading as="h1">{heading}</StyledPreferencesHeading>
      <Divider />
      <StyledMenu data-testid="tabs-menu" pointing secondary>
        {menuTabs.map(item => {
          if (!item) return null;
          return (
            <Menu.Item
              data-testid={item?.key}
              key={item?.key}
              name={item?.key}
              content={item?.label}
              active={activeItem === item?.key}
              onClick={handleTabChange}
            />
          );
        })}
      </StyledMenu>
      {activeTab}
    </StyledContainer>
  );
};

const StyledMenu = styled(Menu)`
  max-width: 100%;
  &&&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(25)};
  }
`;

const StyledContainer = styled(Container)`
  text-align: left;
  max-width: ${props => props.theme.pxToRem(640)};
`;

const StyledPreferencesHeading = styled(Heading)`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  border-bottom: none;
  margin-bottom: 0px;
  margin-top: 0px;
`;
