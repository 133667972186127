import React from 'react';
interface UnSaveProps {
  size?: number;
  color?: string;
  className?: string;
}

export const UnSave = React.memo<UnSaveProps>(({ size = 24, color = '#000', className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" className={className}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M9.466 3v2H7v13l5-2.18L17 18v-6.524h2V21l-7-3-7 3 .01-16c0-1.1.89-2 1.99-2h2.466zm10.201-2L21 2.334 18.333 5 21 7.667 19.667 9 17 6.334 14.333 9 13 7.667 15.667 5 13 2.334 14.333 1 17 3.667 19.667 1z"
    />
  </svg>
));
