import React, { useEffect, useMemo, useState } from 'react';
import { Loader, Popup } from 'semantic-ui-react';
import { useParams, generatePath } from 'react-router';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { SubHeading } from 'components/molecules/SubHeading/SubHeading';
import { ResultCounter } from 'components/atoms/ResultCounter/ResultCounter';
import { Table } from 'components/atoms/Table/Table';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  organizationAdminsList,
  organizationAdminsStatus,
  isFirmBillingSelector,
  isCenterMembershipSelector,
} from 'modules/admin/selectors';
import {
  getOrganizationAdmins,
  setAdminToRemove,
  setSwapAdminModalOpen,
  checkForAdminRelatedEntities,
} from 'modules/admin/actions';
import { NotificationBanner, NotificationBannerEnums } from 'components/atoms';
import { ReactComponent as IconCheckCircle } from 'resources/images/icon-dev-ic-check-circle.svg';
import { ReactComponent as Cancel } from 'resources/images/ic-cancel.svg';
import { Salesforce } from 'mxp-schemas';

export const ClientAdminsTab: React.FC<{ showNotification: boolean }> = ({ showNotification }) => {
  const { accountId, accountNumber, legalEntity } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizationAdmins(accountId));
  }, [dispatch, accountId]);
  const adminsStatus = useSelector(organizationAdminsStatus);
  const admins = useSelector(organizationAdminsList);
  const isFirmBilling = useSelector(isFirmBillingSelector);
  const isCenterMembership = useSelector(isCenterMembershipSelector);

  const [accountNumberColumnName, setAccountNumberColumnName] = useState('');
  const [showAdminRole, setShowAdminRole] = useState(false);

  const adminsData = useMemo(() => {
    const filteredAdminsData = admins.filter((centerAdmin, index) => {
      return (
        index === admins.findIndex(admin => admin.id === centerAdmin.id && admin.adminType === centerAdmin.adminType)
      );
    });

    return filteredAdminsData
      .map(admin => {
        if (admin.firmMembershipTypes?.length) {
          return admin.firmMembershipTypes.map(type => {
            return { ...admin, firmMembershipType: type };
          });
        }
        return admin;
      })
      .flat();
  }, [admins]);

  const removeDisabled =
    Boolean(adminsData?.length === 1) &&
    Boolean(
      adminsData?.some(
        ({ adminType, hasAssignedMembership }) =>
          (adminType === Salesforce.AccountContactRole.CENTERS_ADMIN && hasAssignedMembership) ||
          adminType === Salesforce.AccountContactRole.CLIENT_ADMIN
      )
    );
  const handleRemove = React.useCallback(
    (id: string, adminType?: string, firmMembershipType?: string) => {
      const adminToRemove = adminsData.find(admin =>
        firmMembershipType ? admin.firmMembershipType === firmMembershipType : admin.id === id
      );
      dispatch(setAdminToRemove(adminToRemove));
      dispatch(checkForAdminRelatedEntities(adminType));
      dispatch(setSwapAdminModalOpen(true));
    },
    [adminsData, dispatch]
  );

  const goToAddAdmin = React.useCallback(() => {
    dispatch(
      push({
        pathname: generatePath(getPath(Routes.ADMIN_ADD_CLIENT_ADMIN), { accountId, accountNumber, legalEntity }),
      })
    );
  }, [accountId, accountNumber, legalEntity, dispatch]);

  React.useEffect(() => {
    switch (true) {
      case isFirmBilling:
        setAccountNumberColumnName('Account Number');
        setShowAdminRole(true);
        return;
      case isCenterMembership:
        setAccountNumberColumnName('Account Number');
        setShowAdminRole(true);
        return;
      default:
        setAccountNumberColumnName('AICPA UID');
        setShowAdminRole(false);
    }
  }, [isFirmBilling, isCenterMembership]);

  if (adminsStatus) return <Loader active>Loading</Loader>;
  return (
    <>
      <SubHeading
        title="Client admins"
        withAction={adminsData.length > 0}
        buttonLabel="Add client admin"
        onAction={goToAddAdmin}
      />
      {showNotification && (
        <StyledNotificationBanner
          variant={NotificationBannerEnums.variant.green}
          testId="notification-banner"
          childrenTestId="notification-children"
          icon={<StyledIconSuccess />}
        >
          Success! Your changes have been saved.
        </StyledNotificationBanner>
      )}
      <ResultCounter
        count={adminsData.length}
        boldedText={`client admin${adminsData.length !== 1 ? 's' : ''}`}
        normalText={`${adminsData.length > 0 ? 'below' : ''} are in this organization`}
        className="table-header"
      />
      {adminsData.length === 0 ? (
        <>
          <StyledNoDataText>
            Click the button below to associate an existing
            <br /> user with this client
          </StyledNoDataText>
          <AddClientAdminButton goToAddAdmin={goToAddAdmin} />
        </>
      ) : (
        <StyledTable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>{accountNumberColumnName}</Table.HeaderCell>
              <Table.HeaderCell>Email Address</Table.HeaderCell>
              <Table.HeaderCell>Center Membership Type</Table.HeaderCell>
              {showAdminRole && <Table.HeaderCell>Admin Role</Table.HeaderCell>}
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {adminsData.map(admin => (
              <Table.Row key={admin.id}>
                <Table.Cell>{`${admin.firstName} ${admin.lastName}`}</Table.Cell>
                <Table.Cell>{admin.aicpaId}</Table.Cell>
                <Table.Cell>{admin.email}</Table.Cell>
                <Table.Cell>
                  {admin.firmMembershipType !== Salesforce.AccountContactRole.CLIENT_ADMIN &&
                  admin.firmMembershipType !== Salesforce.AccountContactRole.FIRM_BILLING_ADMIN
                    ? admin.firmMembershipType
                    : ''}
                </Table.Cell>
                {showAdminRole && <Table.Cell>{admin.adminType}</Table.Cell>}
                <Table.Cell>
                  <RemoveAdminButton
                    id={admin.id}
                    handleRemove={handleRemove}
                    goToAddAdmin={goToAddAdmin}
                    removeDisabled={removeDisabled}
                    adminType={admin.adminType}
                    firmMembershipType={admin.firmMembershipType}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </StyledTable>
      )}
    </>
  );
};

const RemoveAdminButton: React.FC<{
  isMobile?: boolean;
  removeDisabled: boolean;
  handleRemove: (id: string, adminType?: string, firmMembershipType?: string) => void;
  id: string;
  goToAddAdmin: () => void;
  adminType?: string;
  firmMembershipType?: string;
}> = ({ isMobile = false, handleRemove, id, goToAddAdmin, removeDisabled, adminType, firmMembershipType }) => {
  const onClick = React.useCallback(() => {
    if (!removeDisabled) {
      handleRemove(id, adminType, firmMembershipType);
    }
  }, [id, handleRemove, removeDisabled, adminType, firmMembershipType]);

  const buttonTrigger = (
    <div>
      <StyledAdminRemoveButton handleRemove={onClick} id={id} isMobile={isMobile} isDisabled={removeDisabled} />
    </div>
  );

  return (
    <Popup
      content={
        <PopupContentWrap>
          <PopupContent>
            Client Admin is required for all orders. There are no additional Client admins associated with this
            organization. Please create a new Client Admin to continue.
          </PopupContent>
          <AddClientAdminButton goToAddAdmin={goToAddAdmin} />
        </PopupContentWrap>
      }
      key={id}
      position={'bottom left'}
      hoverable
      disabled={!removeDisabled}
      trigger={buttonTrigger}
      hideOnScroll
    />
  );
};

interface AddClientAdminButtonProps {
  goToAddAdmin: () => void;
}

const AddClientAdminButton: React.FC<AddClientAdminButtonProps> = ({ goToAddAdmin }) => {
  return (
    <StyledButton
      size={ButtonEnums.sizes.medium}
      testId="create-user"
      variant={ButtonEnums.variants.primary}
      onClick={goToAddAdmin}
    >
      Add client admin
    </StyledButton>
  );
};

const AdminRemoveButton: React.FC<{
  isMobile?: boolean;
  handleRemove: (id: string) => void;
  id: string;
  className?: string;
}> = ({ isMobile = false, handleRemove, id, className }) => {
  const onClick = React.useCallback(() => {
    handleRemove(id);
  }, [id, handleRemove]);

  return (
    <StyledRemoveButton
      testId={`remove-button-${id}`}
      variant={ButtonEnums.variants.link}
      size={isMobile ? ButtonEnums.sizes.medium : ButtonEnums.sizes.small}
      icon={<StyledCancel />}
      iconPosition={ButtonEnums.iconPosition.left}
      onClick={onClick}
      className={className}
    >
      Remove
    </StyledRemoveButton>
  );
};

const StyledCancel = styled(Cancel)`
  &&&&&&& {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => props.theme.pxToRem(24)};
      height: ${props => props.theme.pxToRem(24)};
    }
  }
`;

const StyledNoDataText = styled.p`
  font-size: ${props => props.theme.pxToRem(18)};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-top: ${props => props.theme.pxToRem(16)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const PopupContent = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(12)};
`;

const PopupContentWrap = styled.div`
  padding: ${props => props.theme.pxToRem(10)};
`;

const StyledTable = styled(Table)`
  &&&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(64)};
    margin-top: ${props => props.theme.pxToRem(24)};
  }
`;

const StyledAdminRemoveButton = styled(AdminRemoveButton)<{ isDisabled: boolean }>`
  &&&&&&& {
    color: ${props => (props.isDisabled ? props.theme.colors.neutralGrey4 : props.theme.colors.neutralGrey8)};
    svg path {
      fill: ${props => (props.isDisabled ? props.theme.colors.neutralGrey4 : props.theme.colors.primaryPurple)};
    }
  }
`;

const StyledRemoveButton = styled(Button)`
  &&&& {
    &:hover {
      color: ${props => props.theme.colors.neutralGrey8};
    }
    font-size: ${props => props.theme.pxToRem(14)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.pxToRem(16)};
    }
    svg path {
      fill: ${props => props.theme.colors.primaryPurple};
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: -6px;
    }
  }
`;

const StyledButton = styled(Button)`
  width: ${props => props.theme.pxToRem(173)};
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  &&&&& {
    margin-bottom: ${props => props.theme.pxToRem(25)};
    padding-top: ${props => props.theme.pxToRem(21)};
    padding-bottom: ${props => props.theme.pxToRem(21)};
  }
`;

const StyledIconSuccess = styled(IconCheckCircle)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;
