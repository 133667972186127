import React, { useState, useEffect } from 'react';
import { MenuItem, Container } from 'semantic-ui-react';
import { Link, Tab } from 'components/atoms';
import styled from 'styled-components';
import { ReactComponent as IconArrowBack } from 'resources/images/ic-arrow-back.svg';
import { Salesforce } from 'mxp-schemas';
import { generatePath } from 'react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

interface Content {
  head: string;
  body: any;
  index?: (num?: number) => void;
}

interface Props {
  title: string;
  content: Content[];
  onTabClick: (head: string, key?: number) => void;
  activeIndex: number;
  backlabel?: string;
  to?: string;
  selectedTuitionProvider?: Salesforce.Organization | null;
}

export const ProfileTabHeader: React.FC<Props> = ({
  title,
  content,
  onTabClick,
  activeIndex,
  backlabel,
  to,
  selectedTuitionProvider,
}) => {
  const [tabIndex, setTabIndex] = useState(activeIndex);
  useEffect(() => {
    setTabIndex(activeIndex);
  }, [activeIndex]);

  const goToTab = (key: number, head: string) => {
    setTabIndex(key);
    handleTabClick(head, key);
  };

  const handleTabClick = (head: string, key?: number) => {
    if (onTabClick) {
      onTabClick(head, key);
    }
  };

  const linkUrl: string | undefined = selectedTuitionProvider
    ? generatePath(getPath(Routes.TUITION_PROVIDER_ROOT), { orgId: selectedTuitionProvider.id || '/' })
    : to;

  return (
    <>
      <StyledDiv>
        <Container>
          <StyledLink to={linkUrl || ''}>
            <StyledSpan>
              <StyledIconArrow />
              {selectedTuitionProvider ? `Back to ${selectedTuitionProvider.name} Profile` : backlabel}
            </StyledSpan>
          </StyledLink>
          <StyledTitle>{title}</StyledTitle>
        </Container>
      </StyledDiv>
      <Container>
        <StyledTab
          activeIndex={tabIndex}
          panes={content.map((item: { head: string; body: string }, key) => {
            return {
              menuItem: (
                <StyledMenuItem data-testid={item.head} key={key}>
                  {item.head}
                </StyledMenuItem>
              ),
              onClick: () => goToTab(key, item.head),
              render: () => <StyleTabPane>{item.body}</StyleTabPane>,
            };
          })}
        />
      </Container>
    </>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 246px;
  padding: 45px 0 66px;
  box-shadow: inset 0 0 10px 0 ${props => props.theme.colors.neutralGrey3};
  background-color: ${props => props.theme.colors.neutralGrey1};
`;

const StyledLink = styled(Link)`
  &&&&&& {
    text-decoration: none;
  }
`;

const StyleTabPane = styled(Tab.Pane)`
  &&&&&& {
    height: 100%;
  }
`;

const StyledTitle = styled.span`
  width: 100%;
  height: ${props => props.theme.pxToRem(54)};
  margin: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(188)} ${props => props.theme.pxToRem(66)} 0;
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(40)};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: var(--neutral-grey-8);
  display: block;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(80)};
  }
`;

const StyledSpan = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const StyledTab = styled(Tab)`
  .ui.text.menu {
    margin-top: ${props => props.theme.pxToRem(64)};
    justify-content: flex-start;
    //reverse scrollbar
    overflow: auto;
    transform: rotateX(180deg);
    border-top: ${props => `${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3}`};
    border-bottom: unset;
  }
  .ui.text.menu * {
    transform: rotateX(180deg);
  }
  margin-top: -105px;
`;

const StyledIconArrow = styled(IconArrowBack)`
  color: ${props => props.theme.colors.primaryPurple};
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  bottom: ${props => props.theme.pxToRem(-3)};
  position: relative;
  margin-right: ${props => props.theme.pxToRem(8)};
`;

const StyledMenuItem = styled(MenuItem)`
  &&&&& {
    margin-right: ${props => props.theme.pxToRem(60)};
  }
`;
