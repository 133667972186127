import { careerStagesSlugIdMapSelector } from './selectors';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Helpers
// ------------------------------------

/*
 * Convert careerStages filter slugs to careerStages ids
 */
export const convertCareerStagesSlugsToIds = (
  careerStages: string | string[],
  state: State.Root
): string[] | undefined => {
  if (!careerStages) return;
  const careerStagesSlugs: string[] = Array.isArray(careerStages) ? careerStages : [careerStages];
  // Call careerStagesSlugIdMapSelector only if careerStagesSlugs exist
  const careerStagesSlugIdMap: { [key: string]: string } = careerStagesSlugIdMapSelector(state);
  // Get careerStages ids array from slugs if exist
  return careerStagesSlugs.map(slug => careerStagesSlugIdMap[slug]).filter(Boolean);
};

/*
 * slug id hash map creator
 */
export const slugIdMapCreator = (careerStages: any[] | null): { [key: string]: string } => {
  if (!careerStages) return emptyObject;
  return careerStages.reduce((acc: any, careerStage: State.CareerStage) => {
    acc[careerStage.slug] = careerStage.id;
    return acc;
  }, {});
};
