import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  Button,
  ButtonEnums,
  Heading,
  Link,
  NotificationBanner,
  NotificationBannerEnums,
  Container,
  Grid,
  Modal,
} from 'components/atoms';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { InquiryFormB2B } from 'components/organisms/InquiryForm/InquiryFormB2B';
import { InquiryFormAdSales } from 'components/organisms/InquiryForm/InquiryFormAdSales';
import { InquiryFormChannel } from 'components/organisms/InquiryForm/InquiryFormChannel';
import { InquiryFormBulkOrderContainer } from 'containers/InquiryFormBulkOrderContainer';
import { InquiryFormBulkOrderFooter } from 'components/organisms/InquiryForm/InquiryFormBulkOrderFooter';
import { InquiryFormProductLandingPage } from 'components/organisms/InquiryForm/InquiryFormProductLandingPage';
import { InquiryFormCSM } from 'components/organisms/InquiryForm/InquiryFormCSM';
import { InquiryFormMembership } from 'components/organisms/InquiryForm/InquiryFormMembership';
import { InquiryFormCIMAMembership } from 'components/organisms/InquiryForm/InquiryFormCIMAMembership';
import { InquiryFormContentGating } from 'components/organisms/InquiryForm/InquiryFormContentGating';
import { InquiryFormOracleCampaign } from 'components/organisms/InquiryForm/InquiryFormOracleCampaign';
import { InquiryFormLeadCapture } from 'components/organisms/InquiryForm/InquiryFormLeadCapture';
import { InquiryFormLeadCaptureRegional } from 'components/organisms/InquiryForm/InquiryFormLeadCaptureRegional';
import { Contentful as ContentfulTypes, InquiryForm } from 'mxp-schemas';
import { IC_OPEN_IN_NEW_PURPLE } from 'resources/images';
import { ExternalLinkStyles, DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { ActionResultModal } from 'components/organisms/FirmDataTable/ActionResultModal';
import { ActionResult } from 'constants/firm-admin';
import { FormSuccessModal } from 'components/organisms/FormSuccessModal/FormSuccessModal';
import { ExtLinks } from 'constants/index';
interface UserData extends State.Profile {
  phone: string;
}
interface Props {
  modularBlock: ContentfulTypes.ModularContent.Block;
  headingType: 'h1' | 'h2';
  submit: (inquiryForm: InquiryForm.FormTypes) => void;
  startFormFill: () => void;
  endFormFill: (text: string) => void;
  error: boolean;
  loading: boolean;
  success: boolean;
  userData: UserData;
  queryParams: any;
  errorMessage: string;
  dialCode: string;
  countryCode: string;
}

export const BlockInquiryForm: React.FC<Props> = ({
  modularBlock,
  headingType,
  submit,
  startFormFill,
  endFormFill,
  error,
  loading,
  success,
  errorMessage,
  userData,
  queryParams,
  dialCode,
  countryCode,
}) => {
  const block = modularBlock as ContentfulTypes.ModularContent.InquiryFormBlock;
  const campaignId: string = block.campaignId || '';
  const getQueryParam = React.useCallback(
    (param: string) => {
      return queryParams[param] || '';
    },
    [queryParams]
  );
  const defaultInquiryFormB2BState: InquiryForm.B2B = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    stateCode: '',
    title: '',
    company: '',
    phone: '',
    comments: '',
    marketingOptIn: false,
    campaignId,
    type: 'b2b',
    utmCampaign: getQueryParam('utm_campaign'),
    utmMedium: getQueryParam('utm_medium'),
  };

  const defaultInquiryFormAdSalesState: InquiryForm.AdSales = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    stateCode: '',
    title: '',
    company: '',
    phone: '',
    comments: '',
    marketingOptIn: false,
    mediaAdvertise: '',
    campaignId,
    type: 'adSales',
    utmCampaign: getQueryParam('utm_campaign'),
    utmMedium: getQueryParam('utm_medium'),
  };
  const defaultInquiryFormChannelState: InquiryForm.Channel = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    areaOfInterest: '',
    numberOfMembers: '',
    marketSegment: '',
    company: '',
    marketingOptIn: false,
    campaignId,
    type: 'channel',
  };
  const defaultInquiryFormBulkOrderState: InquiryForm.BulkOrder = {
    firstName: userData.firstName || '',
    lastName: userData.lastName || '',
    email: userData.email || '',
    aicpaUid: userData.AICPAUID || '',
    variantSku: '',
    memberQuantity: '',
    nonMemberQuantity: '',
    memberPrice: '',
    nonMemberPrice: '',
    shippingAddress: '',
    billingAddress: '',
    title: '',
    company: '',
    phone: '',
    marketingOptIn: false,
    campaignId,
    type: 'bulkOrder',
  };
  const defaultInquiryFormProductLandingPageState: InquiryForm.ProductLandingPage = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    stateCode: '',
    title: '',
    company: '',
    phone: '',
    teamSize: '',
    marketingOptIn: false,
    campaignId,
    utmCampaign: getQueryParam('utm_campaign'),
    utmMedium: getQueryParam('utm_medium'),
    type: 'productLandingPage',
  };
  const defaultInquiryFormContentGatingState: InquiryForm.ContentGating = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    stateCode: '',
    title: '',
    company: '',
    marketingOptIn: false,
    campaignId,
    utmCampaign: getQueryParam('utm_campaign'),
    utmMedium: getQueryParam('utm_medium'),
    type: 'contentGating',
  };
  const defaultInquiryFormCSMState: InquiryForm.CSM = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    stateCode: '',
    title: '',
    company: '',
    phone: '',
    teamSize: '',
    tierPreference: '',
    marketingOptIn: false,
    campaignId,
    utmCampaign: getQueryParam('utm_campaign'),
    utmMedium: getQueryParam('utm_medium'),
    type: 'csm',
  };
  const defaultInquiryFormOracleCampaignState: InquiryForm.OracleCampaign = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    stateCode: '',
    title: '',
    company: '',
    phone: '',
    teamSize: '',
    preferredCertifications: '',
    marketingOptIn: false,
    campaignId,
    utmCampaign: getQueryParam('utm_campaign'),
    utmMedium: getQueryParam('utm_medium'),
    type: 'oracleCampaign',
  };

  const defaultInquiryFormMembershipState: InquiryForm.Membership = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    marketingOptIn: false,
    trainingFor: '',
    inquiryRole: '',
    type: 'membership',
    country: '',
    state: '',
    iWorkIn: '',
    receivedEmail: true,
    receivedSms: false,
    campaignId,
    utmCampaign: campaignId,
    utmMedium: getQueryParam('utm_medium'),
  };

  const defaultInquiryFormCIMAMembershipState: InquiryForm.CIMAMembership = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    currentStatus: '',
    location: '',
    receivedEmail: true,
    receivedSms: false,
    type: 'cimaMembership',
    productSku: '',
    countryCode: '',
    dialCode: '',
    campaignId,
    utmCampaign: campaignId,
    utmMedium: getQueryParam('utm_medium'),
  };

  const defaultInquiryFormLeadCaptureState: InquiryForm.LeadCapture = {
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    title: '',
    company: '',
    phone: '',
    comments: '',
    numberOfMembers: '',
    type: 'leadCapture',
  };

  const defaultInquiryFormLeadRegionalCaptureState: InquiryForm.RegionalLeadCapture = {
    firstName: userData.firstName || '',
    lastName: userData.lastName || '',
    email: userData.email || '',
    phone: userData.phone || '',
    dialCode,
    countryCode,
    country: '',
    GDPR: false,
    type: 'regionalLeadCapture',
  };

  const inquiryFormType: string = block.inquiryForm || '';
  const [isInquiryFormB2BValid, setInquiryFormB2BValid] = useState(false);
  const [inquiryFormB2B, setInquiryFormB2B] = useState(defaultInquiryFormB2BState);
  const [isInquiryFormAdSalesValid, setInquiryFormAdSalesValid] = useState(false);
  const [inquiryFormAdSales, setInquiryFormAdSales] = useState(defaultInquiryFormAdSalesState);
  const [isInquiryFormChannelValid, setInquiryFormChannelValid] = useState(false);
  const [inquiryFormChannel, setInquiryFormChannel] = useState(defaultInquiryFormChannelState);
  const [isInquiryFormBulkOrderValid, setInquiryFormBulkOrderValid] = useState(false);
  const [inquiryFormBulkOrder, setInquiryFormBulkOrder] = useState(defaultInquiryFormBulkOrderState);
  const [isInquiryFormProductLandingPageValid, setInquiryFormProductLandingPageValid] = useState(false);
  const [inquiryFormProductLandingPage, setInquiryFormProductLandingPage] = useState(
    defaultInquiryFormProductLandingPageState
  );
  const [isInquiryFormContentGatingValid, setInquiryFormContentGatingValid] = useState(false);
  const [inquiryFormContentGating, setInquiryFormContentGating] = useState(defaultInquiryFormContentGatingState);
  const [isInquiryFormCSMValid, setInquiryFormCSMValid] = useState(false);
  const [inquiryFormCSM, setInquiryFormCSM] = useState(defaultInquiryFormCSMState);
  const [isInquiryFormOracleCampaignValid, setInquiryFormOracleCampaignValid] = useState(false);
  const [inquiryFormOracleCampaign, setInquiryFormOracleCampaign] = useState(defaultInquiryFormOracleCampaignState);
  const [isInquiryFormMembershipValid, setInquiryFormMembershipValid] = useState(false);
  const [inquiryFormMembership, setInquiryFormMembership] = useState(defaultInquiryFormMembershipState);
  const [isInquiryFormCIMAMembershipValid, setInquiryFormCIMAMembershipValid] = useState(false);
  const [inquiryFormCIMAMembership, setInquiryFormCIMAMembership] = useState(defaultInquiryFormCIMAMembershipState);
  const [isInquiryFormLeadCaptureValid, setInquiryFormLeadCaptureValid] = useState(false);
  const [inquiryFormLeadCapture, setInquiryFormLeadCapture] = useState(defaultInquiryFormLeadCaptureState);
  const [isInquiryFormRegionalLeadCaptureValid, setInquiryFormRegionalLeadCaptureValid] = useState(false);
  const [inquiryFormRegionalLeadCapture, setInquiryFormRegionalLeadCapture] = useState(
    defaultInquiryFormLeadRegionalCaptureState
  );
  const [formStarted, setFormStarted] = useState(false);
  const [leadCaptureModalOpen, setLeadCaptureModalOpen] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState('');
  const isBulkOrderForm: boolean = inquiryFormType === ContentfulTypes.InquiryFormType.BULK_ORDER;
  const isContentGatingForm: boolean = inquiryFormType === ContentfulTypes.InquiryFormType.CONTENT_GATING;
  const isRegionalLeadCaptureForm: boolean = inquiryFormType === ContentfulTypes.InquiryFormType.REGIONAL_LEAD_CAPTURE;

  const altErrorTextForms: string[] = [
    ContentfulTypes.InquiryFormType.PRODUCT_LANDING_PAGE,
    ContentfulTypes.InquiryFormType.CONTENT_GATING,
    ContentfulTypes.InquiryFormType.CSM,
    ContentfulTypes.InquiryFormType.ORACLE_CAMPAIGN,
  ];
  const showAltErrorText = altErrorTextForms.includes(inquiryFormType);

  const submitInquiryForm = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      endFormFill((event.target as HTMLAnchorElement).textContent || '');
      switch (inquiryFormType) {
        case ContentfulTypes.InquiryFormType.B2B:
          submit(inquiryFormB2B);
          break;
        case ContentfulTypes.InquiryFormType.CHANNEL:
          submit(inquiryFormChannel);
          break;
        case ContentfulTypes.InquiryFormType.BULK_ORDER:
          if (inquiryFormBulkOrder.memberQuantity === '0' || inquiryFormBulkOrder.memberQuantity === '') {
            inquiryFormBulkOrder.memberPrice = '0';
          }
          if (inquiryFormBulkOrder.nonMemberQuantity === '0' || inquiryFormBulkOrder.nonMemberQuantity === '') {
            inquiryFormBulkOrder.nonMemberPrice = '0';
          }
          submit(inquiryFormBulkOrder);
          break;
        case ContentfulTypes.InquiryFormType.PRODUCT_LANDING_PAGE:
          submit(inquiryFormProductLandingPage);
          break;
        case ContentfulTypes.InquiryFormType.CONTENT_GATING:
          submit(inquiryFormContentGating);
          break;
        case ContentfulTypes.InquiryFormType.CSM:
          submit(inquiryFormCSM);
          break;
        case ContentfulTypes.InquiryFormType.ORACLE_CAMPAIGN:
          submit(inquiryFormOracleCampaign);
          break;
        case ContentfulTypes.InquiryFormType.MEMBERSHIP:
          submit(inquiryFormMembership);
          break;
        case ContentfulTypes.InquiryFormType.CIMA_MEMBERSHIP:
          submit(inquiryFormCIMAMembership);
          break;
        case ContentfulTypes.InquiryFormType.LEAD_CAPTURE:
          submit(inquiryFormLeadCapture);
          setInquiryFormLeadCapture(defaultInquiryFormLeadCaptureState);
          break;
        case ContentfulTypes.InquiryFormType.REGIONAL_LEAD_CAPTURE:
          submit(inquiryFormRegionalLeadCapture);
          break;
        case ContentfulTypes.InquiryFormType.AD_SALES:
          submit(inquiryFormAdSales);
          break;
        default:
          return;
      }
    },
    [
      inquiryFormType,
      inquiryFormB2B,
      inquiryFormChannel,
      inquiryFormBulkOrder,
      inquiryFormProductLandingPage,
      inquiryFormContentGating,
      inquiryFormCSM,
      inquiryFormOracleCampaign,
      inquiryFormMembership,
      inquiryFormCIMAMembership,
      inquiryFormLeadCapture,
      inquiryFormRegionalLeadCapture,
      defaultInquiryFormLeadCaptureState,
      inquiryFormAdSales,
      submit,
      endFormFill,
    ]
  );
  const onFormStartHandler = React.useCallback(() => {
    if (!formStarted) {
      setFormStarted(true);
      startFormFill();
    }
  }, [formStarted, setFormStarted, startFormFill]);

  const inquiryForm = React.useCallback(() => {
    switch (inquiryFormType) {
      case ContentfulTypes.InquiryFormType.B2B:
        return (
          <InquiryFormB2B
            form={inquiryFormB2B}
            setForm={setInquiryFormB2B}
            setFormValid={setInquiryFormB2BValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.CHANNEL:
        return (
          <InquiryFormChannel
            form={inquiryFormChannel}
            setForm={setInquiryFormChannel}
            setFormValid={setInquiryFormChannelValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.BULK_ORDER:
        return (
          <InquiryFormBulkOrderContainer
            form={inquiryFormBulkOrder}
            setForm={setInquiryFormBulkOrder}
            setFormValid={setInquiryFormBulkOrderValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.PRODUCT_LANDING_PAGE:
        return (
          <InquiryFormProductLandingPage
            form={inquiryFormProductLandingPage}
            setForm={setInquiryFormProductLandingPage}
            setFormValid={setInquiryFormProductLandingPageValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.CONTENT_GATING:
        return (
          <InquiryFormContentGating
            form={inquiryFormContentGating}
            setForm={setInquiryFormContentGating}
            setFormValid={setInquiryFormContentGatingValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.CSM:
        return (
          <InquiryFormCSM
            form={inquiryFormCSM}
            setForm={setInquiryFormCSM}
            setFormValid={setInquiryFormCSMValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.ORACLE_CAMPAIGN:
        return (
          <InquiryFormOracleCampaign
            form={inquiryFormOracleCampaign}
            setForm={setInquiryFormOracleCampaign}
            setFormValid={setInquiryFormOracleCampaignValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.MEMBERSHIP:
        return (
          <InquiryFormMembership
            form={inquiryFormMembership}
            setForm={setInquiryFormMembership}
            setFormValid={setInquiryFormMembershipValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.CIMA_MEMBERSHIP:
        return (
          <InquiryFormCIMAMembership
            form={inquiryFormCIMAMembership}
            setForm={setInquiryFormCIMAMembership}
            setFormValid={setInquiryFormCIMAMembershipValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.LEAD_CAPTURE:
        return (
          <InquiryFormLeadCapture
            form={inquiryFormLeadCapture}
            setForm={setInquiryFormLeadCapture}
            setFormValid={setInquiryFormLeadCaptureValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.REGIONAL_LEAD_CAPTURE:
        return (
          <InquiryFormLeadCaptureRegional
            form={inquiryFormRegionalLeadCapture}
            setForm={setInquiryFormRegionalLeadCapture}
            setFormValid={setInquiryFormRegionalLeadCaptureValid}
            onFormStart={onFormStartHandler}
          />
        );
      case ContentfulTypes.InquiryFormType.AD_SALES:
        return (
          <InquiryFormAdSales
            form={inquiryFormAdSales}
            setForm={setInquiryFormAdSales}
            setFormValid={setInquiryFormAdSalesValid}
            onFormStart={onFormStartHandler}
          />
        );
      default:
        return <></>;
    }
  }, [
    inquiryFormType,
    inquiryFormB2B,
    inquiryFormChannel,
    inquiryFormBulkOrder,
    inquiryFormProductLandingPage,
    inquiryFormContentGating,
    inquiryFormCSM,
    inquiryFormOracleCampaign,
    inquiryFormMembership,
    inquiryFormCIMAMembership,
    inquiryFormLeadCapture,
    inquiryFormRegionalLeadCapture,
    inquiryFormAdSales,
    onFormStartHandler,
  ]);

  const isSubmitButtonEnabled = React.useCallback(() => {
    switch (inquiryFormType) {
      case ContentfulTypes.InquiryFormType.B2B:
        return isInquiryFormB2BValid;
      case ContentfulTypes.InquiryFormType.CHANNEL:
        return isInquiryFormChannelValid;
      case ContentfulTypes.InquiryFormType.BULK_ORDER:
        return isInquiryFormBulkOrderValid;
      case ContentfulTypes.InquiryFormType.PRODUCT_LANDING_PAGE:
        return isInquiryFormProductLandingPageValid;
      case ContentfulTypes.InquiryFormType.CONTENT_GATING:
        return isInquiryFormContentGatingValid;
      case ContentfulTypes.InquiryFormType.CSM:
        return isInquiryFormCSMValid;
      case ContentfulTypes.InquiryFormType.ORACLE_CAMPAIGN:
        return isInquiryFormOracleCampaignValid;
      case ContentfulTypes.InquiryFormType.MEMBERSHIP:
        return isInquiryFormMembershipValid;
      case ContentfulTypes.InquiryFormType.CIMA_MEMBERSHIP:
        return isInquiryFormCIMAMembershipValid;
      case ContentfulTypes.InquiryFormType.LEAD_CAPTURE:
        return isInquiryFormLeadCaptureValid;
      case ContentfulTypes.InquiryFormType.REGIONAL_LEAD_CAPTURE:
        return isInquiryFormRegionalLeadCaptureValid;
      case ContentfulTypes.InquiryFormType.AD_SALES:
        return isInquiryFormAdSalesValid;
      default:
        return false;
    }
  }, [
    inquiryFormType,
    isInquiryFormB2BValid,
    isInquiryFormChannelValid,
    isInquiryFormBulkOrderValid,
    isInquiryFormProductLandingPageValid,
    isInquiryFormContentGatingValid,
    isInquiryFormCSMValid,
    isInquiryFormOracleCampaignValid,
    isInquiryFormMembershipValid,
    isInquiryFormCIMAMembershipValid,
    isInquiryFormLeadCaptureValid,
    isInquiryFormRegionalLeadCaptureValid,
    isInquiryFormAdSalesValid,
  ]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const onCloseModalEvent = React.useCallback(() => {
    setLeadCaptureModalOpen(!leadCaptureModalOpen);
  }, [setLeadCaptureModalOpen, leadCaptureModalOpen]);

  const submitButtonText = () => {
    if (isBulkOrderForm) return 'Submit order';
    if (isRegionalLeadCaptureForm) return 'Contact me';
    return 'Submit';
  };

  const telephone = '1-888-777-7077';

  React.useEffect(() => {
    if (isContentGatingForm && success && block.submitUrl) window.open(block.submitUrl, '_blank');
    if (success || error) setLeadCaptureModalOpen(true);
    setCustomErrorMessage(errorMessage);
  }, [success, block.submitUrl, isContentGatingForm, error, errorMessage]);
  if (block.inquiryForm === 'Center Membership') {
    return (
      <>
        <Container>
          <Grid centered>
            {block.header && (
              <StyledHeading tabIndex={0} as={headingType} dangerouslySetInnerHTML={{ __html: block.header }} />
            )}
          </Grid>
          <Grid centered>
            {block.description && (
              <StyledCenterParagraph tabIndex={0} dangerouslySetInnerHTML={{ __html: block.description }} />
            )}
          </Grid>
        </Container>
      </>
    );
  }
  return (
    <>
      {block?.inquiryForm === 'Membership' ? (
        <MemberStyledContainer>
          {success ? (
            <>
              <InquiryTitleMessage>Thanks for reaching out!</InquiryTitleMessage>
              <InquiryText>
                Keep an eye on your inbox. We'll drop you an email from time to time to tell you more about AICPA
                membership, resources and its benefits. If you've got any questions in the meantime, get in touch at
                futuremembers@aicpa-cima.com or call on +1-919-533-8002.
              </InquiryText>
            </>
          ) : (
            <>
              <MemberStyledHeaderContainer>
                {block.header && (
                  <StyledHeading tabIndex={0} as={headingType} dangerouslySetInnerHTML={{ __html: block.header }} />
                )}
                {block.description && (
                  <StyledParagraph tabIndex={0} dangerouslySetInnerHTML={{ __html: block.description }} />
                )}
              </MemberStyledHeaderContainer>
              <MemberInquiryFormStyle onSubmit={handleSubmit}>
                <StyledInquiryForm>{inquiryForm()}</StyledInquiryForm>
                <CenterDiv>
                  <SubmitButtonMembership
                    size={ButtonEnums.sizes.medium}
                    onClick={submitInquiryForm}
                    testId="submit-enquiry-form"
                    variant={ButtonEnums.variants.primary}
                    disabled={!isSubmitButtonEnabled()}
                    loading={loading}
                  >
                    Submit
                  </SubmitButtonMembership>
                </CenterDiv>
                <StyledExtraInfo>
                  Your privacy is important to us. To learn more about the ways in which we will process and secure the
                  personal data submitted through this form, please{' '}
                  <StyledUnderlined>
                    <Link isExternal testId="bulk-order-note-link" to={`${ExtLinks.PRIVACY_TERMS}`}>
                      click here{' '}
                    </Link>{' '}
                  </StyledUnderlined>
                  to view our Privacy Policy.
                </StyledExtraInfo>

                {isBulkOrderForm && <InquiryFormBulkOrderFooter />}
              </MemberInquiryFormStyle>

              {error && (
                <NotificationBannerContainer>
                  <NotificationBanner
                    testId="error-incorrect-pass-banner"
                    childrenTestId="error-incorrect-pass-children"
                    variant={NotificationBannerEnums.variant.red}
                    icon={<StyledIconError />}
                  >
                    {customErrorMessage
                      ? customErrorMessage
                      : 'Uh oh. There was an error submitting your inquiry. Please try again.'}
                  </NotificationBanner>
                </NotificationBannerContainer>
              )}
            </>
          )}
        </MemberStyledContainer>
      ) : block?.inquiryForm === 'CIMA Membership' ? (
        <MemberStyledContainer>
          {success ? (
            <>
              <FormSuccessModal />
              <InquiryTitleMessage>Thanks for contacting us!</InquiryTitleMessage>
              <InquiryText>We will get back to you as soon as possible.</InquiryText>
            </>
          ) : (
            <>
              <MemberStyledHeaderContainer>
                {block.header && <StyledHeading as={headingType} dangerouslySetInnerHTML={{ __html: block.header }} />}
                {block.description && <StyledParagraph dangerouslySetInnerHTML={{ __html: block.description }} />}
              </MemberStyledHeaderContainer>
              <MemberInquiryFormStyle onSubmit={handleSubmit}>
                <StyledInquiryForm>{inquiryForm()}</StyledInquiryForm>
                <CenterDiv>
                  <SubmitButtonMt
                    size={ButtonEnums.sizes.medium}
                    onClick={submitInquiryForm}
                    testId="submit-enquiry-form"
                    variant={ButtonEnums.variants.primary}
                    disabled={!isSubmitButtonEnabled()}
                    loading={loading}
                  >
                    Go
                  </SubmitButtonMt>
                </CenterDiv>
                <StyledExtraInfo>
                  Your privacy is important to us. To learn more about the ways in which we will process and secure the
                  personal data submitted through this form, please{' '}
                  <StyledUnderlined>
                    <Link isExternal testId="bulk-order-note-link" to={`${ExtLinks.PRIVACY_TERMS}`}>
                      click here{' '}
                    </Link>{' '}
                  </StyledUnderlined>
                  to view our Privacy Policy.
                </StyledExtraInfo>
                {isBulkOrderForm && <InquiryFormBulkOrderFooter />}
              </MemberInquiryFormStyle>

              {error && (
                <NotificationBannerContainer>
                  <NotificationBanner
                    testId="error-incorrect-pass-banner"
                    childrenTestId="error-incorrect-pass-children"
                    variant={NotificationBannerEnums.variant.red}
                    icon={<StyledIconError />}
                  >
                    Uh oh. There was an error submitting your inquiry. Please try again.
                  </NotificationBanner>
                </NotificationBannerContainer>
              )}
            </>
          )}
        </MemberStyledContainer>
      ) : block?.inquiryForm === 'Lead Capture' ? (
        <MemberStyledContainer>
          {(success || error) && (
            <Modal
              open={leadCaptureModalOpen}
              size="tiny"
              testId="lead-capture-result"
              onClose={onCloseModalEvent}
              cancelNode={
                <Button
                  className="cancel"
                  size={ButtonEnums.sizes.small}
                  variant={ButtonEnums.variants.secondary}
                  testId="cancel"
                  onClick={onCloseModalEvent}
                >
                  Okay
                </Button>
              }
            >
              {error && (
                <ActionResultModal
                  resultTitle="Form failed to submit"
                  status={ActionResult.Fail}
                  showResult
                  resultHelperText="Uh oh. There was an error submitting your inquiry. Please try again."
                />
              )}
              {success && (
                <ActionResultModal
                  resultTitle="Thanks for contacting us!"
                  status={ActionResult.Success}
                  showResult
                  resultHelperText="We will get back to you as soon as possible."
                />
              )}
            </Modal>
          )}

          <MemberStyledHeaderContainer>
            {block.header && <StyledHeading as={headingType} dangerouslySetInnerHTML={{ __html: block.header }} />}
            {block.description && <StyledParagraph dangerouslySetInnerHTML={{ __html: block.description }} />}
          </MemberStyledHeaderContainer>
          <MemberInquiryFormStyle onSubmit={handleSubmit}>
            <StyledInquiryForm>{inquiryForm()}</StyledInquiryForm>
            <CenterDiv>
              <SubmitButtonMt
                size={ButtonEnums.sizes.medium}
                onClick={submitInquiryForm}
                testId="submit-enquiry-form"
                variant={ButtonEnums.variants.primary}
                disabled={!isSubmitButtonEnabled()}
                loading={loading}
              >
                Send Inquiry
              </SubmitButtonMt>
            </CenterDiv>
          </MemberInquiryFormStyle>
        </MemberStyledContainer>
      ) : (
        <StyledContainer>
          {success ? (
            isBulkOrderForm ? (
              <>
                <InquiryTitleMessage tabIndex={0}>Thank you for your purchase!</InquiryTitleMessage>
                <InquiryText tabIndex={0}>
                  Your order is being processed and will be completed within 2 business days.
                </InquiryText>
                <InquiryText tabIndex={0}>
                  A confirmation email including information on accessing your product and invoice will be sent to{' '}
                  <b>{userData.email}</b>.
                </InquiryText>
                <InquiryText tabIndex={0}>
                  Please note: If you are placing an order to attend a live event within the next 2 business days,
                  please call us at{' '}
                  <StyledLink isExternal testId="live-event-place-order-phone-link" to={`tel:${telephone}`}>
                    {telephone}
                  </StyledLink>{' '}
                  to ensure your registration has been completed prior to the start time.
                </InquiryText>
              </>
            ) : (
              <>
                <InquiryTitleMessage tabIndex={0}>Thanks for contacting us!</InquiryTitleMessage>
                <InquiryText tabIndex={0}>We will get back to you as soon as possible.</InquiryText>
              </>
            )
          ) : (
            <>
              <StyledHeaderContainer>
                {block.header && (
                  <StyledHeading tabIndex={0} as={headingType} dangerouslySetInnerHTML={{ __html: block.header }} />
                )}
                {block.description && (
                  <StyledParagraph tabIndex={0} dangerouslySetInnerHTML={{ __html: block.description }} />
                )}
              </StyledHeaderContainer>

              <form onSubmit={handleSubmit}>
                <StyledInquiryForm>{inquiryForm()}</StyledInquiryForm>
                <SubmitButton
                  size={ButtonEnums.sizes.medium}
                  onClick={submitInquiryForm}
                  testId="submit-enquiry-form"
                  variant={ButtonEnums.variants.primary}
                  disabled={!isSubmitButtonEnabled()}
                  loading={loading}
                >
                  {submitButtonText()}
                </SubmitButton>
                {isBulkOrderForm && <InquiryFormBulkOrderFooter />}
              </form>

              {error && (
                <NotificationBannerContainer>
                  <NotificationBanner
                    testId="error-incorrect-pass-banner"
                    childrenTestId="error-incorrect-pass-children"
                    variant={NotificationBannerEnums.variant.red}
                    icon={<StyledIconError />}
                  >
                    {showAltErrorText
                      ? 'Failed to submit the form'
                      : 'Uh oh. There was an error submitting your inquiry'}
                    . Please try again.
                  </NotificationBanner>
                </NotificationBannerContainer>
              )}
            </>
          )}
        </StyledContainer>
      )}
    </>
  );
};

const StyledInquiryForm = styled.div`
  display: flex-column;
`;

const MemberStyledContainer = styled.div`
  background-color: transparent;
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: ${props => props.theme.pxToRem(575)};
  }
`;
const StyledContainer = styled.div`
  background-color: transparent;
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: ${props => props.theme.pxToRem(375)};
  }
`;

const InquiryTitleMessage = styled.div`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.xl};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.l};
    text-align: center;
  }
`;

const InquiryText = styled.div`
  padding-top: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.m};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};
    text-align: center;
  }
`;

const MemberInquiryFormStyle = styled.form`
  margin: 0 auto;
  width: 375px;
  max-width: 100%;
  button {
    margin-top: 15px;
  }
`;

const SubmitButtonMt = styled(Button)`
  &&&& {
    padding-left: ${props => props.theme.pxToRem(70)};
    padding-right: ${props => props.theme.pxToRem(70)};
    margin-top: ${props => props.theme.pxToRem(30)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(23)};
    }
  }
`;

const SubmitButtonMembership = styled(Button)`
  &&&& {
    padding-left: ${props => props.theme.pxToRem(70)};
    padding-right: ${props => props.theme.pxToRem(70)};
    margin-top: ${props => props.theme.pxToRem(30)};
    width: 100%;
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(23)};
    }
  }
`;

const CenterDiv = styled.div`
  width: 100%;
  text-align: center;
`;
const SubmitButton = styled(Button)`
  &&&& {
    padding-left: ${props => props.theme.pxToRem(25)};
    padding-right: ${props => props.theme.pxToRem(25)};
    margin-top: 15px;
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(23)};
    }
  }
`;
const MemberStyledHeaderContainer = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(25)};
  margin: 0 auto;
  h2 {
    font-size: 32px;
    text-align: center;
    font-weight: 300;
  }
  b {
    font-weight: bold;
  }
  p {
    text-align: center;
  }
`;
const StyledHeaderContainer = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(25)};
  margin: 0 auto;
  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
  .rich-text-external-link {
    ${ExternalLinkStyles}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${IC_OPEN_IN_NEW_PURPLE});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.pxToRem(18)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.medium};
  text-align: left;
  p {
    line-height: 1.38;
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.l};
    text-align: center;
    p {
      line-height: 1.33;
    }
  }
`;
const StyledUnderlined = styled.label`
  text-decoration: underline;
  color: #72246c;
`;

const StyledParagraph = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: left;

  p {
    margin: 0;
    line-height: 1.33;
  }

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};
    text-align: center;
    p {
      line-height: 1.5;
    }
  }
`;

const StyledCenterParagraph = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;

  p {
    margin: 0;
    line-height: 1.33;
  }

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};
    text-align: center;
    p {
      line-height: 1.5;
    }
  }
`;

const NotificationBannerContainer = styled.div`
  padding: ${props => props.theme.pxToRem(10)} 0;
`;

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;

const StyledLink = styled(Link)`
  &&& {
    color: ${props => props.theme.colors.primaryPurple};
    text-decoration: underline;

    &:hover,
    &:focus {
      cursor: pointer;
      color: ${props => props.theme.colors.primaryLightPurple};
      text-decoration: underline;
    }
  }
`;

const StyledExtraInfo = styled.p`
  margin-bottom: ${props => props.theme.pxToRem(16)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
  margin-top: ${props => props.theme.pxToRem(8)};
  text-align: center;
`;
