export enum WelcomeBannerCarousel {
  WIDTH = 1500,
  LAPTOP_WIDTH = 1300,
  TABLET_WIDTH = 500,
  MOBILE_WIDTH = 300,
  QUALITY = 65,
}

export enum PromoCards {
  WIDTH = 360,
  QUALITY = 95,
}
