import React from 'react';
import { List as SemanticUIList, ListProps as SemanticUIListProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { Checkbox } from 'components/atoms';

interface ListProps extends SemanticUIListProps {
  filterName: string;
  items: Common.FilterItemProps[];
  handleChange?: (name: string, value: string) => void;
  selectedIds: string[];
  isMobileView?: boolean;
}

export const FilterList: React.FC<ListProps> = ({
  filterName,
  handleChange,
  className,
  items,
  selectedIds,
  isMobileView,
}) => {
  const handleCheckboxClick = React.useCallback(
    (e: any, data: any) => {
      if (handleChange) handleChange(filterName, data.value);
    },
    [filterName, handleChange]
  );

  if (!items || !items.length) return null;

  return (
    <ScrollWrapper isMobileView={isMobileView}>
      <ListContainer className={className}>
        <SemanticUIList divided verticalAlign="middle" data-testid={`${filterName}_list`}>
          {items.map(({ name, resultCount, id }) => {
            const checked: boolean = selectedIds.includes(id);
            const testId: string = `${filterName}_item_${id}`;
            return (
              <ListItem key={id}>
                <StyledLabel htmlFor={testId} checked={checked}>
                  {name}&nbsp;({resultCount})
                </StyledLabel>
                <StyledCheckbox checked={checked} onChange={handleCheckboxClick} testId={testId} value={id} />
              </ListItem>
            );
          })}
        </SemanticUIList>
      </ListContainer>
    </ScrollWrapper>
  );
};

const ScrollWrapper = styled.div`
  ${(props: ListProps) => (props.isMobileView ? '' : 'max-height: 202px;')}
  overflow-y: auto;
  border: 1px solid ${props => props.theme.colors.neutralGrey3};
`;

const ListContainer = styled.div`
  padding: 10px 10px 10px 15px;
`;

const ListItem = styled(SemanticUIList.Item)`
  &&&&& {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
`;

const StyledLabel: any = styled.label`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${({ theme, checked }: any) => (checked ? theme.fontWeights.bold : theme.fontWeights.regular)};
  line-height: 18px;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCheckbox: any = styled(Checkbox)`
  width: 24px;
  height: 24px;
  margin-left: auto;
`;
