import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Button, ButtonEnums, Divider, Modal, OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms';
import { removeMembershipPackageCredential } from 'modules/membership';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  headerText?: string;
  bodyText?: string | React.ReactNode;
  productId?: string;
  isNotAllowedToClose?: boolean;
}

export const ModalError: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  headerText,
  bodyText,
  productId,
  isNotAllowedToClose,
}) => {
  const dispatch = useDispatch();

  const handleButtonClick = useCallback(() => {
    if (productId) dispatch(removeMembershipPackageCredential(productId));

    handleClose();
  }, [handleClose, dispatch, productId]);

  return (
    <>
      <StyledModal size="small" heading={headerText || ''} open={isOpen}>
        {!isNotAllowedToClose && (
          <CloseButton>
            <StyledCloseIcon onClick={handleButtonClick} />
          </CloseButton>
        )}
        <Spacer />
        <CenteredText>{bodyText || ''}</CenteredText>
        <DividerWithBottomMargin />
        {!isNotAllowedToClose && (
          <CenteredButtons>
            <DesktopStyled>
              <StyledSubmitButton onClick={handleButtonClick} />
            </DesktopStyled>
            <MobileStyled>
              <StyledSubmitButton onClick={handleButtonClick} />
            </MobileStyled>
          </CenteredButtons>
        )}
      </StyledModal>
    </>
  );
};

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;
const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

interface ButtonProps {
  onClick: () => void;
}

const StyledSubmitButton: React.FC<ButtonProps> = ({ onClick }) => (
  <StyledButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId="understand-credentials-error"
  >
    I understand
  </StyledButton>
);

const StyledButton = styled(Button)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
