import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { notificationsSelector } from 'modules/notifications/selectors';
import { Link } from 'components/atoms/Link/Link';
import { SurveyCheck, Save, UnSave } from 'components/atoms/svg';
import { theme } from 'theme';
import { Routes } from 'constants/index';
import { getPath } from 'utils';

export const Notifications: React.FC<{ className?: string }> = ({ className }) => {
  const notifications: State.NotificationItem[] = useSelector(notificationsSelector);
  const notificationIconMap = React.useMemo(
    () => ({
      copy: <SurveyCheck color={theme.colors.primaryPurple} size={30} />,
      save: <Save color={theme.colors.primaryPurple} size={30} />,
      unsave: <UnSave color={theme.colors.primaryPurple} size={30} />,
    }),
    []
  );

  const getNotificationTitleByType = React.useCallback((notification: State.NotificationItem): React.ReactNode => {
    switch (notification.type) {
      case 'copy':
      case 'unsave':
        return (
          <div aria-live="assertive" aria-atomic="true" data-testid={`notification-title-${notification.id}`}>
            {notification.title}
          </div>
        );
      case 'save':
        return (
          <div aria-live="assertive" aria-atomic="true" data-testid={`notification-title-${notification.id}`}>
            {notification.title}
            <StyledLink to={getPath(Routes.PROFILE_SAVED_ITEMS)}>My Saved Items</StyledLink>
          </div>
        );
      default:
        return null;
    }
  }, []);

  if (!notifications?.length) return null;
  return (
    <NotificationWrapper className={className}>
      {notifications.map((notification: State.NotificationItem) => (
        <Notification key={notification.id} data-testid={`notification-${notification.id}`}>
          <IconWrapper>{notificationIconMap[notification.type]}</IconWrapper>
          <VerticalDivider />
          <Wrapper>
            {getNotificationTitleByType(notification)}
            <Text data-testid={`notification-text-${notification.id}`}>{notification.text}</Text>
          </Wrapper>
        </Notification>
      ))}
    </NotificationWrapper>
  );
};

const Notification = styled.div`
  align-items: center;
  border-radius: ${props => props.theme.pxToRem(5)};
  border: 1px solid ${props => props.theme.colors.neutralGrey3};
  display: flex;
  padding: ${props => props.theme.pxToRem(8)} 0;
  background-color: ${props => props.theme.colors.neutralGrey1};
  transition: opacity 1s linear;
  &:not(:last-child) {
    margin: 0 0 ${props => props.theme.pxToRem(12)};
  }
  animation-name: appear;
  animation-duration: 1s;
  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const NotificationWrapper = styled.div`
  bottom: ${props => props.theme.pxToRem(24)};
  position: fixed;
  z-index: 2;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: calc(50% - ${props => props.theme.pxToRem(200)});
  flex-direction: column;
  width: ${props => props.theme.pxToRem(400)};
  height: ${props => props.theme.pxToRem(100)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 90%;
    margin-left: 5%;
  }
`;

const IconWrapper = styled.div`
  margin: 0 ${props => props.theme.pxToRem(26)};
  display: flex;
  align-items: center;
`;

const VerticalDivider = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey3};
  width: ${props => props.theme.pxToRem(1)};
  flex-shrink: 0;
  height: ${props => props.theme.pxToRem(78)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => `${props.theme.pxToRem(16)} ${props.theme.pxToRem(20)}`};
  width: calc(100% - ${props => props.theme.pxToRem(82)});
`;

const Text = styled.div`
  margin: ${props => props.theme.pxToRem(2)} 0;
  color: ${props => props.theme.colors.primaryPurple};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledLink = styled(Link)`
  font-weight: ${props => props.theme.fontWeights.medium};
`;
