import { Checkout } from 'mxp-schemas';

export const USE_PAYPAL = 'usePaypal';
export const FEATURE_TOGGLE_DASHBOARD = 'isToggleOn';
export const USE_OFFLINE_PAYMENT_OPTIONS = 'useOfflinePaymentOptions';
export const USE_CIMA_MEMBERSHIP_RELEASE = 'useCimaRelease';
export const IS_SALESFORCE_OFFLINE = 'isSalesforceOffline';
export const IS_SITE_OFFLINE = 'isSiteOffline';
export const SHOW_SLICER = 'showSlicer';
export const USE_SITE_BANNER_IN_CHECKOUT = 'useSiteBannerInCheckout';
export const USE_MY_PROFILE = 'useMyProfile';
export const USE_SITE_BANNER = 'useSiteBanner';
export const USE_BYPASS_B2B_ADDRESS_VALIDATION = 'bypassB2BAddressValidation';
export const USE_BYPASS_B2C_ADDRESS_VALIDATION = 'bypassB2CAddressValidation';
export const USE_SUSPENDED_ETHICS_STATUS_RESTRICTION = 'useSuspendedRestriction';
export const USE_VITAL_SOURCE_PRICE_CORRECTION = 'useVitalSourcePriceCorrection';
export const CheckoutCountriesListToggles = Checkout.CheckoutCountriesListToggle;
export const RegistrationCountriesListToggles = [
  { toggleName: 'allowRussiaRegistration', ISOAlpha2: 'RU' },
  { toggleName: 'allowBelarusRegistration', ISOAlpha2: 'BY' },
];
export const USE_POST_MEMBERSHIP_GO_LIVE = 'postMembershipGoLive';
export const USE_CREDENTIAL_DIRECTORY = 'useCredentialDirectory';
export const USE_PRODUCTS_PRICE_CURRENCY = 'useProductsPriceCurrency';
export const USE_SPRINT_1_CR = 'useSprint1CR';
export const USE_DISABLE_PA_CREATION = 'useDisablePAcreation';
export const USE_FLP_ADDONS = 'useFLPAddons';
export const USE_CGMA_AFFILIATE_ADDONS = 'useCgmaAffiliateAddons';
export const USE_BRIDGE_RELEASE = 'useBridgeRelease';
export const BLOCK_CIMA_MIGRATED_MEMBERS_LOGIN = 'blockCimaMigratedMembersLogin';
export const USE_RE_CAPTCHA_DISABLED = 'useReCaptchaDisabled';
export const HIDE_FINANCIAL_STATEMENT = 'hideFinancialStatement';
export const USE_CR_276 = 'useCR276ApprenticeRoster';
export const USE_CR_215 = 'useCR215ExamResultDisplay';
export const USE_CR_205_OFFLINE_EXEMPTION_CALCULATOR = 'useCR205OfflineExemptionCalculator';
export const USE_CR_723_REMOVE_PRORATION = 'useCR723RemoveProration';
export const SHOW_MIGRATED_ACMA_CGMA_CREDENTIALS = 'showMigratedAcmaAndCgmaCredentials';
export const BYPASS_RENEWAL_BUTTON = 'bypassRenewalButton';
export const USE_NEW_MEMBERSHIP_AICPA = 'useNewMembershipAICPA';
export const USE_ADD_EMPLOYER_ENHANCEMENT = 'useAddEmployerEnhancement';
export const USE_FLP_UPGRADES = 'useFLPUpgrades';
export const USE_PROGRESS_BAR = 'useProgressBar';
export const USE_CR_682 = 'useCR682FcmaRenewalEnhancement';
export const USE_CR_16_CIMA_WEBSITES = 'useCR16CimaWebsites';
export const USE_AICPA_AR = 'useAICPAAR';
export const USE_CIMA_AR = 'useCIMAAR';
export const USE_FLP_EMBARGO_OVERRIDE = 'useFLPEmbargoOverride';
export const USE_INSTRUCTOR_SPEAKER_FIELDS = 'useInstructorSpeakerFields';
export const USE_REMOVE_PER_MODULE = 'useRemovePERModule';
export const USE_FIRM_ROSTER_CASE_INSENSITIVE_SEARCH = 'useFirmRosterCaseInsensitiveSearch';
export const USE_NEW_SIGNUP_ONLY = 'useNewSignUpOnly';
export const USE_BULK_RENEWAL_FILTER = 'useBulkRenewalDateFilter';
