/**
 * Used to identify if color is dark/light in nature. Helpful in dynamically setting font color to be contrastive.
 * @param bgColor
 * @returns {string}
 */
export const isColorDark = (inputColor?: string): boolean => {
  if (!inputColor) {
    return true;
  }

  const color = inputColor.charAt(0) === '#' ? inputColor.substring(1, 7) : inputColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? false : true;
};
