import { createAction } from 'redux-actions';
import { default as request } from 'utils/GraphQLClient';
import {
  CREATE_CASE,
  MUTATE_SEND_GDPR_REQUEST,
  MUTATE_SEND_CALIFORNIA_LAW,
  GET_EXISTING_CASE,
} from 'mxp-graphql-queries';
import { CaseActionNames } from './constants';
import { getSmartyStreetsValidation } from 'modules/checkout/helpers';

// ------------------------------------
// Actions
// ------------------------------------

export const createCCPACase: any = createAction(CaseActionNames.CALIFORNIA_LAW, (caseInfo: any) => {
  return request(CREATE_CASE, { ...caseInfo, isCCPL: true });
});

export const createGDPRCase: any = createAction(CaseActionNames.GDPR, (caseInfo: any) => {
  return request(CREATE_CASE, { ...caseInfo, isCCPL: false });
});

export const sendGDPREmail: any = createAction(CaseActionNames.SEND_GDPR_EMAIL, (payload: any) => {
  return request(MUTATE_SEND_GDPR_REQUEST, payload);
});

export const sendCaliforniaLawEmail: any = createAction(CaseActionNames.CALIFORNIA_LAW_EMAIL, (payload: any) => {
  return request(MUTATE_SEND_CALIFORNIA_LAW, payload);
});

export const fetchExistingCase: any = createAction(CaseActionNames.GET_EXISTING_CASE, (email: string) => () => {
  return request(GET_EXISTING_CASE, { email });
});

export const caseAddressValidation: any = createAction(
  CaseActionNames.ADDRESS_VALIDATION,
  (address: State.Address) => () => {
    return getSmartyStreetsValidation(address);
  }
);
