import React, { useState, useCallback, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { PromoCode, PromoTitle, StyledInput } from 'components/molecules/PromoCode/PromoCode';
import { DownloadPaymentSummary } from 'components/atoms/DownloadPaymentSummary/DownloadPaymentSummary';
import {
  Divider,
  OnlyDesktopCSS,
  SupportedPayments,
  OnlyMobileCSS,
  FeedPlaceholder,
  MembershipChangePackage,
  Link,
  Modal,
  ButtonEnums,
  Button,
} from 'components/atoms';
import { StickyFooter } from './StickyFooter';
import { PriceSummary } from './PriceSummary';
import { ItemsInfo } from './ItemsInfo';
import { Cart, Checkout, Product, Shipping, MembershipTypes } from 'mxp-schemas';
import { Routes, StorageNames } from 'constants/index';
import IconClose from 'resources/images/ic-close.svg';
import { getPath, getAllDiscountsForType } from 'utils';
import { useLocation } from 'react-router';
import { Lock } from 'components/atoms/svg';
import { activeMembershipSubscriptionSelector } from 'modules/membership/selectors';
import { cimaMembershipTermSelector, aicpaMembershipTermSelector, userEmailSelector } from 'modules/user/selectors';
import {
  hasCimaCertificateInCartSelector,
  hasAicpaCertificateInCartSelector,
  hasFcmaCredentialSelector,
  hasPromocodeExemptionSelector,
  cartCurrencySelector,
  cartPONumberSelector,
} from 'modules/cart/selectors';
import { constantsSelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { getLocalStorageItem } from 'utils/localStorage';
import { fetchDataOfSpecificInvite } from 'modules/membership';
import { ProceedButtonLink } from './ProceedButtonLink';
interface CartSummaryProps {
  itemsPriceInfo: Cart.CartItemPriceInfo[];
  totalPriceInfo: Cart.CartTotalPriceInfo;
  cartShippingInfo: Shipping.ShippingPrice;
  hideProceedButton?: boolean;
  appliedPromoCode?: string;
  isAppliedPromoCodeTargetShipping: boolean;
  cartError: Cart.Errors;
  pageStep: string | null;
  isAuth: boolean | null;
  disableCheckoutButton?: boolean;
  hasBasicPhysicalProductInCart: boolean;
  isPageCart: boolean;
  getCartFetched?: boolean;
  isCartFetched: boolean;
  setHideHeader?: (hide: boolean) => void;
  addPromoCode: (promoCode: string) => void;
  removePromoCode: () => void;
  resetCartError: () => void;
  removeCartItems?: (lineItemId: string[]) => void;
  updatePONumber?: (poNumber: string) => void;
  isContributionOnly?: boolean;
  hasMembershipProduct?: boolean;
  hasSectionProduct?: boolean;
  hasCredentialProduct?: boolean;
  cartLineItems?: Cart.LineItem[];
  isAdmin?: boolean;
  isPageCheckout?: boolean;
  isCenterMembershipJourney?: boolean;
  membershipRefund?: number;
  membershipApplicationType?: Product.MembershipApplicationType;
  inviteData?: State.InviteData;
  hasCimaMembershipBodyProduct?: boolean;
  hasAicpaMembershipBodyProduct?: boolean;
  onCheckoutError?: () => void;
  checkoutLoading?: boolean;
  isCartLoading: boolean;
  hasAicpaProtectedProduct?: boolean;
  hasCimaProtectedProduct?: boolean;
  hasCenterMembershipProduct?: boolean;
  hasFcmaCredential?: boolean;
  isUserSuspendedByEthics?: boolean;
  isPromoCodeChangesAllowed?: boolean;
  cartEntityLabel?: { urlLink: string; fullEntityName: string };
  hasCredentialInCart?: boolean;
  isCartAicpaEntity?: boolean;
  expressRenewalPage?: boolean;
  useNewMembershipAICPA?: boolean;
}
export const CartSummary: React.FC<CartSummaryProps> = ({
  itemsPriceInfo,
  totalPriceInfo,
  cartShippingInfo,
  hideProceedButton = false,
  appliedPromoCode,
  isAppliedPromoCodeTargetShipping,
  cartError,
  pageStep,
  isAuth,
  hasBasicPhysicalProductInCart,
  disableCheckoutButton = false,
  isPageCart,
  getCartFetched = true,
  isCartFetched,
  setHideHeader,
  addPromoCode,
  removePromoCode,
  resetCartError,
  removeCartItems,
  updatePONumber,
  isContributionOnly,
  hasMembershipProduct,
  cartLineItems,
  isAdmin,
  isCenterMembershipJourney = false,
  membershipRefund,
  membershipApplicationType,
  hasSectionProduct,
  hasCredentialProduct,
  inviteData,
  hasCimaMembershipBodyProduct,
  hasAicpaMembershipBodyProduct,
  onCheckoutError,
  checkoutLoading,
  isCartLoading,
  hasAicpaProtectedProduct,
  hasCimaProtectedProduct,
  hasCenterMembershipProduct,
  hasFcmaCredential = false,
  isPromoCodeChangesAllowed,
  cartEntityLabel,
  hasCredentialInCart,
  isCartAicpaEntity,
  isPageCheckout,
  useNewMembershipAICPA = false,
}) => {
  // Open Error Modal if Cart have both AICPA and CIMA products (CBUILD-1034)
  const dispatch = useDispatch();
  const [isModalOpen, setModalStatus] = useState(false);
  const [getPromoCode, setPromoCode] = useState('');
  const AppliedPONumber = useSelector(cartPONumberSelector);
  const [PONumber, setPONumber] = useState<string>(AppliedPONumber);
  const [showMembershipAdjustmentModal, setShowMembershipAdjustmentModal] = useState(false);
  const handlePONumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPONumber(event.target.value);
  };

  const handlePONumberClick = React.useCallback(() => {
    if (AppliedPONumber) {
      if (updatePONumber) {
        updatePONumber('');
      }
      setPONumber('');
    } else if (PONumber && updatePONumber) {
      updatePONumber(PONumber);
    }
  }, [PONumber, updatePONumber, AppliedPONumber]);

  const setOpenCloseModalCallBack = useCallback(() => setModalStatus(!isModalOpen), [isModalOpen]);
  const location = useLocation();
  const currentLocation = location.pathname;

  const isFcma = useSelector(hasFcmaCredentialSelector);
  const constants = useSelector(constantsSelector);
  const aicpaUrlLink = constants?.[CONSTANTS.AICPA_URL_LINK];
  const aicpaUrlTitle = constants?.[CONSTANTS.AICPA_URL_TITLE];
  const cimaUrlLink = constants?.[CONSTANTS.CIMA_URL_LINK];
  const cimaUrlTitle = constants?.[CONSTANTS.CIMA_URL_TITLE];
  const isPromocodeExemptionChecker = useSelector(hasPromocodeExemptionSelector);

  const userEmail = useSelector(userEmailSelector);
  const currency = useSelector(cartCurrencySelector);

  const toggleMembershipAdjustmentModal = () => {
    setShowMembershipAdjustmentModal(!showMembershipAdjustmentModal);
  };

  React.useEffect(() => {
    if (!Boolean(inviteData?.inviteId)) {
      const inviteId = getLocalStorageItem(StorageNames.inviteId);
      const inviteEmail = getLocalStorageItem(StorageNames.inviteEmail);
      if (Boolean(inviteId) && userEmail === inviteEmail) dispatch(fetchDataOfSpecificInvite(inviteId, true));
    }
  }, [dispatch, inviteData, userEmail]);

  const to: string = useMemo(() => {
    const path = generatePath(getPath(isAuth ? Routes.CHECKOUT_PAGE : Routes.LOGIN_HASH));
    if (path.includes('#')) {
      // or we can use !isAuth
      return `${currentLocation}${path}`;
    }
    return path;
  }, [isAuth, currentLocation]);

  const [isSummaryOpen, setSummaryOpen] = useState(false);
  const handleToggleSummary = useCallback(() => {
    if (setHideHeader) {
      setHideHeader(!isSummaryOpen);
    }
    setSummaryOpen(!isSummaryOpen);
  }, [isSummaryOpen, setSummaryOpen, setHideHeader]);

  const isImpersonated = Boolean(isAuth) && isAdmin;
  if (!(itemsPriceInfo && totalPriceInfo)) return null;

  const headTitle = isCenterMembershipJourney ? 'My Cart' : useNewMembershipAICPA ? 'Purchase summary' : 'My Basket';
  const { discounts } = totalPriceInfo;
  const promoDiscount = getAllDiscountsForType(discounts, Cart.DISCOUNT_TYPE.CART_PROMO_DISCOUNT)[0];
  const useSingleLineStickyFooter = !hideProceedButton;
  const isUserPaymentStep = pageStep === Checkout.CheckoutStep.PAYMENT;
  const isImpersonationPaymentStep =
    pageStep === Checkout.CheckoutStep.ADMIN_PAYMENT_OPTIONS ||
    pageStep === Checkout.CheckoutStep.ADMIN_OFFLINE_PAYMENT;
  const isPaymentStep = isUserPaymentStep || isImpersonationPaymentStep;

  const renderPriceSummary = (): React.ReactNode => (
    <PriceSummary
      isPageCart={isPageCart}
      totalPriceInfo={totalPriceInfo}
      cartShippingInfo={cartShippingInfo}
      isAuth={isAuth}
      hasBasicPhysicalProductInCart={hasBasicPhysicalProductInCart}
      changeTextOnAddressPage={hideProceedButton}
      pageStep={pageStep}
      isContributionOnly={isContributionOnly}
      hasMembershipProduct={hasMembershipProduct}
      isCenterMembershipJourney={isCenterMembershipJourney}
      membershipRefund={membershipRefund}
      isPaymentStep={isPaymentStep}
      currency={currency}
    />
  );

  const renderPromoCode = (): React.ReactNode => {
    if (!isPromoCodeChangesAllowed) return;
    return (
      <>
        <PromoCodeStyled
          appliedPromoCode={appliedPromoCode}
          isAppliedPromoCodeTargetShipping={isAppliedPromoCodeTargetShipping}
          isPageCart={isPageCart}
          addPromo={addPromoCode}
          removePromo={removePromoCode}
          cartError={cartError}
          resetCartError={resetCartError}
          promoDiscount={promoDiscount}
          isPromocodeExemptionAvail={isPromocodeExemptionChecker}
          setPromoCode={setPromoCode}
          initialSentCodeValue={getPromoCode}
        />
        <DividerWithBorderB />
      </>
    );
  };

  const renderPONumber = (): React.ReactNode => {
    return (
      <PONumberWrap>
        <PromoTitle>Purchase Order #</PromoTitle>
        <StyledInput
          action={{
            content: AppliedPONumber ? 'Remove' : 'Apply',
            onClick: handlePONumberClick,
            'data-testid': AppliedPONumber ? 'remove-po-number' : 'add-po-number',
          }}
          name="Purchase Order #"
          type="text"
          value={PONumber}
          placeholder="Enter Purchase Order #"
          maxLength={60}
          testId="Purchase Order #"
          labelId="Purchase Order #"
          autoComplete="off"
          onChange={handlePONumberChange}
        />
      </PONumberWrap>
    );
  };
  const cartEntity = {
    urlLink: isCartAicpaEntity ? aicpaUrlLink : cimaUrlLink,
    entityFullName: isCartAicpaEntity ? aicpaUrlTitle : cimaUrlTitle,
  };
  const entityLink = cartEntityLabel?.urlLink ?? cartEntity?.urlLink;
  const entityName = cartEntityLabel?.fullEntityName ?? cartEntity?.entityFullName;

  const isCartShown = !inviteData?.isPaidByFirm && !inviteData?.isPaidByFLP;

  const shouldAdjustMembership =
    location.pathname === getPath(Routes.CART_PAGE) && (hasMembershipProduct || hasCenterMembershipProduct);
  const isCheckoutButtonDisabled =
    (!shouldAdjustMembership && (hasCredentialInCart || hasSectionProduct)) || disableCheckoutButton;

  const onClickProceedToCheckOut = async (
    onClickProceedToCheckOutEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickProceedToCheckOutEvent.preventDefault();
    if (shouldAdjustMembership) {
      toggleMembershipAdjustmentModal();
    } else {
      setModalStatus(!isModalOpen);
    }
  };

  const handleToggleMembershipAdjustmentModal = () => {
    toggleMembershipAdjustmentModal();
  };
  const renderPurchaseLink = () => (
    <>
      <StyledP>
        You are currently purchasing from <br />
        <StyledA target="_blank" href={entityLink}>
          {entityName}
        </StyledA>
      </StyledP>
      <DividerWithBorderB />
    </>
  );

  const renderDesktopDesign = () => (
    <>
      {/* Display only cart summary if it's not paid by firm */}
      {isCartShown && (
        <>
          {getCartFetched && isCartFetched && !checkoutLoading && !isCartLoading ? (
            <StyledBox useNewMembershipAICPA={useNewMembershipAICPA}>
              <Title useNewMembershipAICPA={useNewMembershipAICPA}>{headTitle}</Title>
              {renderPurchaseLink()}
              <PaddedContainer>
                <ItemsInfo
                  itemsPriceInfo={itemsPriceInfo}
                  removeCartItems={removeCartItems}
                  cartLineItems={cartLineItems}
                  isImpersonated={isImpersonated}
                  isCenterMembershipJourney={isCenterMembershipJourney}
                  isPaymentStep={isPaymentStep}
                  useNewMembershipAICPA={useNewMembershipAICPA}
                />
              </PaddedContainer>
              <DividerWithBorderB />
              {renderPromoCode()}
              {isPageCheckout && renderPONumber()}
              <PaddedContainer>{renderPriceSummary()}</PaddedContainer>
              {!hideProceedButton && (
                <>
                  <Divider0Margin />
                  {(hasCimaMembershipBodyProduct && hasAicpaMembershipBodyProduct) ||
                  (hasAicpaProtectedProduct && hasCimaProtectedProduct) ? (
                    <StyledButtonWrapper>
                      <Button
                        onClick={onClickProceedToCheckOut}
                        testId="checkout-button-modal-error"
                        iconPosition={ButtonEnums.iconPosition.left}
                        icon={<Lock color="#fff" />}
                        variant={ButtonEnums.variants.primary}
                        disabled={isCheckoutButtonDisabled}
                      >
                        Proceed to Checkout
                      </Button>
                    </StyledButtonWrapper>
                  ) : (
                    !isFcma && (
                      <ProceedButtonWrap
                        onError={onCheckoutError}
                        to={to}
                        label="Proceed to Checkout"
                        disableCheckoutButton={isCheckoutButtonDisabled}
                        cartLineItems={cartLineItems}
                        shouldAdjustMembership={shouldAdjustMembership}
                        toggleMembershipAdjustmentModal={handleToggleMembershipAdjustmentModal}
                      />
                    )
                  )}
                </>
              )}
              {(hasMembershipProduct || hasSectionProduct || hasCredentialProduct) && (
                <div>
                  {isPaymentStep && (
                    <DownloadPaymentSummary
                      totalPriceInfo={totalPriceInfo}
                      appliedPromoCode={appliedPromoCode}
                      withIcon={true}
                    />
                  )}
                  {!hasFcmaCredential && !isCenterMembershipJourney && (
                    <MembershipChangePackage membershipApplicationType={membershipApplicationType} />
                  )}
                </div>
              )}
            </StyledBox>
          ) : (
            <StyledBox>
              <PaddedContainer>
                <FeedPlaceholder withoutImage amount={7} />
              </PaddedContainer>
            </StyledBox>
          )}
        </>
      )}

      {isCenterMembershipJourney && (
        <StyledLink testId={`test-link-center-membership-package`} to={getPath(Routes.CENTER_MEMBERSHIP_FORM)}>
          Adjust my membership
        </StyledLink>
      )}
      {isCartShown && <SupportedPaymentsStyled />}
    </>
  );

  const renderMobileDesign = () => (
    <>
      {isSummaryOpen && (
        <SummaryContainerMobile>
          <MobileSummaryHead>
            {headTitle} <StyledIcon src={IconClose} alt="burger" onClick={handleToggleSummary} />
          </MobileSummaryHead>
          <SummaryItemListMobile>
            <PaddedSidesContainer>
              <DividerNoTop />
              <ItemsInfo
                itemsPriceInfo={itemsPriceInfo}
                removeCartItems={removeCartItems}
                cartLineItems={cartLineItems}
                isImpersonated={isImpersonated}
              />
            </PaddedSidesContainer>
            <DividerNoSides />
            {isCartShown && renderPromoCode()}
            {isPageCheckout && renderPONumber()}
            <PaddedSidesContainer>{renderPriceSummary()}</PaddedSidesContainer>
            {(hasMembershipProduct || hasSectionProduct || hasCredentialProduct) && (
              <div>
                {isCartShown && !hasFcmaCredential && (
                  <MembershipChangePackage membershipApplicationType={membershipApplicationType} />
                )}
                {isPaymentStep && (
                  <DownloadPaymentSummary
                    totalPriceInfo={totalPriceInfo}
                    appliedPromoCode={appliedPromoCode}
                    withIcon={true}
                  />
                )}
              </div>
            )}
          </SummaryItemListMobile>
        </SummaryContainerMobile>
      )}
      <StickyFooter
        handleToggleSummary={handleToggleSummary}
        to={to}
        hideProceedButton={hideProceedButton}
        totalPriceInfo={totalPriceInfo}
        isSummaryOpen={isSummaryOpen}
        useSingleLineLayout={useSingleLineStickyFooter}
        disableCheckoutButton={disableCheckoutButton}
        isModalError={hasCimaMembershipBodyProduct && hasAicpaMembershipBodyProduct}
        openErrorModal={setOpenCloseModalCallBack}
      />
    </>
  );

  return (
    <>
      <OnlyDesktopCSS>{renderDesktopDesign()}</OnlyDesktopCSS>
      <OnlyMobileCSS>{renderMobileDesign()}</OnlyMobileCSS>
      <StyledModal
        heading="You cannot proceed"
        testId="cart-error-modal"
        open={isModalOpen}
        onClose={setOpenCloseModalCallBack}
        size="small"
        confirmNode={
          <Button
            testId="close-modal-button"
            variant={ButtonEnums.variants.primary}
            size={ButtonEnums.sizes.small}
            onClick={setOpenCloseModalCallBack}
          >
            I understand
          </Button>
        }
        showCloseCross
      >
        You cannot proceed because you have added 2 products of conflicting entities.
      </StyledModal>
      <StyledModal
        heading="You cannot proceed"
        testId="membership-adjustment-modal"
        open={showMembershipAdjustmentModal}
        onClose={handleToggleMembershipAdjustmentModal}
        size="large"
        confirmNode={
          isCenterMembershipJourney ? (
            <StyledLink testId={`test-link-center-membership-package`} to={getPath(Routes.CENTER_MEMBERSHIP_FORM)}>
              Adjust my membership
            </StyledLink>
          ) : (
            <MembershipChangePackage membershipApplicationType={membershipApplicationType} />
          )
        }
        showCloseCross
      >
        You need to adjust your membership before checkout. Click the 'Adjust my membership' link to proceed.
      </StyledModal>
    </>
  );
};

const ProceedButtonWrap: React.FC<{
  to: string;
  label: string;
  disableCheckoutButton?: boolean;
  onError?: () => void;
  cartLineItems?: Cart.LineItem[];
  shouldAdjustMembership?: boolean;
  toggleMembershipAdjustmentModal: () => void;
}> = ({
  to,
  label,
  disableCheckoutButton,
  onError,
  cartLineItems,
  shouldAdjustMembership,
  toggleMembershipAdjustmentModal,
}) => {
  const membershipTypeSubscription = useSelector(activeMembershipSubscriptionSelector);
  // Check if user is a CIMA Regular member
  const cimaMembershipTerms = useSelector(cimaMembershipTermSelector);
  const userHasCimaRegularMembership = cimaMembershipTerms?.some(
    (item: any) => item?.membershipTermType === MembershipTypes.MembershipKeys.REGULAR
  );
  // Check is user had added a CIMA Certificate in Cart
  const userHasCimaCertificateInCart = useSelector(hasCimaCertificateInCartSelector);

  // Check if user is a CIMA Regular member
  const aicpaMembershipTerms = useSelector(aicpaMembershipTermSelector);
  const userHasAicpaRegularMembership = aicpaMembershipTerms?.some(
    (item: any) => item?.membershipTermType === MembershipTypes.MembershipKeys.REGULAR
  );
  // Check is user had added an AICPA Certificate in Cart
  const userHasAicpaCertificateInCart = useSelector(hasAicpaCertificateInCartSelector);

  const handleButtonClick = useCallback(
    async (handleButtonClickEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (shouldAdjustMembership) {
        handleButtonClickEvent.preventDefault();
        toggleMembershipAdjustmentModal();
      } else {
        const hasAICPACertificate = cartLineItems?.find(
          (cartLineItem: Cart.LineItem) =>
            cartLineItem?.name.includes('AICPA') && cartLineItem?.name.includes('Certificate')
        );

        // If User has AICPA Regular Membership, prompt error message AND cart has AICPA Certificate
        if (
          MembershipTypes.MembershipKeys.REGULAR !==
            membershipTypeSubscription?.variant?.attributes?.membershipKey?.key &&
          hasAICPACertificate
        ) {
          handleButtonClickEvent.preventDefault();
          if (onError) onError();
        }

        // If user doesn't have a CIMA Regular membership and bought a CIMA Certificate, it will prompt an error message
        if (!userHasCimaRegularMembership && userHasCimaCertificateInCart) {
          handleButtonClickEvent.preventDefault();
          if (onError) onError();
        }

        // If user doesn't have an AICPA Regular membership and bought an AICPA Certificate, it will prompt an error message
        if (!userHasAicpaRegularMembership && userHasAicpaCertificateInCart) {
          handleButtonClickEvent.preventDefault();
          if (onError) onError();
        }
      }
    },
    [
      cartLineItems,
      membershipTypeSubscription,
      onError,
      userHasAicpaCertificateInCart,
      userHasAicpaRegularMembership,
      userHasCimaCertificateInCart,
      userHasCimaRegularMembership,
      shouldAdjustMembership,
      toggleMembershipAdjustmentModal,
    ]
  );

  return (
    <StyledButtonWrapper>
      <ProceedButtonLink
        onClick={handleButtonClick}
        to={to}
        label={label}
        disableCheckoutButton={disableCheckoutButton}
      />
    </StyledButtonWrapper>
  );
};

const FOOTER_HEIGHT = 70;
const HEAD_HEIGHT = 84;
const sectionPadding = css`
  padding: ${props => props.theme.pxToRem(20)};
`;
const PaddedContainer = styled.div`
  ${sectionPadding}
`;
const PaddedSidesContainer = styled.div`
  padding-left: ${props => props.theme.pxToRem(20)};
  padding-right: ${props => props.theme.pxToRem(20)};
`;
const MobileSummaryHead = styled.div`
  padding-top: ${props => props.theme.pxToRem(29)};
  padding-left: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(HEAD_HEIGHT)};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.25;
  color: ${props => props.theme.colors.neutralGrey8};
`;
const DividerNoTop = styled(Divider)`
  &&& {
    margin-top: 0;
  }
`;
const DividerNoSides = styled(Divider)`
  &&& {
    margin-left: 0;
    margin-right: 0;
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: 0;
    }
  }
`;

const Divider0Margin = styled(Divider)`
  &&& {
    margin: 0;
  }
`;

const DividerWithBorderB = styled.div`
  margin: 0 1.25rem 0 1.25rem;
  border-bottom: 1px dashed ${props => props.theme.colors.lightGrey};
`;

const StyledIcon = styled.img`
  float: right;
  margin-right: ${props => props.theme.pxToRem(20)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)}; // ie11 support
`;

const SummaryContainerMobile = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  background-color: ${props => props.theme.colors.neutralGrey1};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 1.38;
  z-index: 1;
`;
const SummaryItemListMobile = styled.div`
  height: calc(100% - ${FOOTER_HEIGHT + HEAD_HEIGHT}px);
  overflow: auto;
`;
const PromoCodeStyled = styled(PromoCode)`
  padding-left: ${props => `${props.theme.pxToRem(20)}`};
  padding-right: ${props => `${props.theme.pxToRem(20)}`};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: 0;
  }
`;

const PONumberWrap = styled.div`
  padding: ${props => `${props.theme.pxToRem(20)}`};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: 0;
  }
`;

const StyledBox = styled.div<{ 'data-no-background'?: boolean; useNewMembershipAICPA?: boolean }>`
  margin-top: ${props => props.theme.pxToRem(80)};
  max-width: ${props => props.theme.pxToRem(360)};
  background: ${props =>
    props['data-no-background']
      ? 'transparent'
      : props.useNewMembershipAICPA
      ? props.theme.colors.neutralWhite
      : props.theme.colors.neutralGrey1};
  border-radius: 4px;
  backdrop-filter: blur(4px);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: ${props => props.theme.pxToRem(1)};
`;
const StyledButtonWrapper = styled.div`
  padding: ${props => props.theme.pxToRem(20)};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 auto;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;
const Title = styled.h3<{ useNewMembershipAICPA: boolean }>`
  ${sectionPadding}
  margin: 0;
  color: ${props => (props.useNewMembershipAICPA ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  font-weight: ${props => (props.useNewMembershipAICPA ? props.theme.fontWeights.bold : props.theme.fontWeights.light)};
  line-height: 1.33;
  font-size: ${props => props.theme.fontSizes.m};
  background-color: ${props => (props.useNewMembershipAICPA ? props.theme.colors.neutralGrey9 : 'inherit')};
`;
const SupportedPaymentsStyled = styled(SupportedPayments)`
  margin-top: ${props => props.theme.pxToRem(24)};
`;
const StyledLink = styled(Link)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.xs};
    line-height: 1.57;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.primaryPurple};
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: center;
      align-items: center;
      margin-left: 30%;
    }
  }
`;

const StyledModal = styled(Modal)`
  &&&&&&& {
    border-radius: ${props => props.theme.pxToRem(2)};
    width: ${props => props.theme.pxToRem(590)};
    box-sizing: border-box;
    padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(19)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props => props.theme.pxToRem(36)} ${props => props.theme.pxToRem(20)}
        ${props => props.theme.pxToRem(19)};
      width: 90%;
    }

    > .content {
      padding: ${props => props.theme.pxToRem(18)} 0 ${props => props.theme.pxToRem(28)}!important;
      text-align: center !important;
    }
  }
`;

const StyledP = styled.p`
  ${sectionPadding}
  padding-bottom: ${props => props.theme.pxToRem(8)};
  margin: 0;
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.33;
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledA = styled.a`
  color: ${props => props.theme.colors.neutralBlack};
  font-weight: ${props => props.theme.fontWeights.medium};
  text-decoration: underline;
`;
