import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, generatePath } from 'react-router-dom';
import { SingleColumnPageTemplate } from 'components/templates/SingleColumn/SingleColumn';
import { Grid, Paragraph, ButtonLink, ButtonEnums } from 'components/atoms';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

export const PageCenterAdminInvoiceError: React.FC<RouteComponentProps> = () => {
  const renderButton = (): React.ReactNode => (
    <StyledButtonLink
      testId="error-go-to-invoices"
      variant={ButtonEnums.variants.primary}
      size={ButtonEnums.sizes.large}
      to={generatePath(getPath(Routes.CENTER_ADMIN_ROOT))}
    >
      Back to Center Admin
    </StyledButtonLink>
  );

  // const phoneContact = '888-777-7077';
  // const emailContact = 'service@aicpa.org';

  const renderBody = (): React.ReactNode => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column computer={8}>
            <Wrapper>
              <StyledH1 data-testid="error-title">Uh oh. Something went wrong.</StyledH1>
              <StyledParagraph testId="error-sorry-text">
                We're sorry. We can't process your payment at this time.
              </StyledParagraph>
              {/* <StyledParagraph testId="error-please-contact-text">
                Please contact our dedicated group sales team at&nbsp;
                <Link isExternal testId="error-phone" to={`tel:${phoneContact}`}>
                  {phoneContact}
                </Link>
                ,&nbsp;option 1, or&nbsp;
                <Link isExternal testId="error-email" to={`mailto:${emailContact}`}>
                  {emailContact}
                </Link>
                . We’re open 8am - 5pm Eastern (US time).
              </StyledParagraph> */}
              {renderButton()}
            </Wrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return <SingleColumnPageTemplate>{renderBody()}</SingleColumnPageTemplate>;
};

const Wrapper = styled.div`
  margin: ${props => props.theme.pxToRem(40)} 0;
`;

const StyledH1 = styled.h1`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${props => props.theme.pxToRem(22)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  :last-of-type {
    margin-bottom: ${props => props.theme.pxToRem(32)};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    width: ${props => props.theme.pxToRem(220)};
  }
`;
