import { Link, LinkEnums } from 'components/atoms';
import { Routes } from 'constants/index';
import React from 'react';
import styled from 'styled-components';
import { getPath } from 'utils';

export const InquiryFormBulkOrderFooter: React.FC = () => {
  return (
    <StyledFooterContainer>
      <StyledTermsText>
        By pressing submit you are agreeing to purchase in accordance with our standard{' '}
        <StyledLink
          type={LinkEnums.type.standaloneLink}
          to={getPath(Routes.TERMS_CONDITIONS)}
          testId="bulk-order-footer-terms-and-conditions"
        >
          terms and conditions
        </StyledLink>
        .
      </StyledTermsText>
    </StyledFooterContainer>
  );
};

const StyledFooterContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(8)};
  margin-bottom: ${props => props.theme.pxToRem(80)};
`;

const StyledTermsText = styled.p`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledLink = styled(Link)`
  display: inline-block;
`;
