import React from 'react';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { useLocation, generatePath, matchPath } from 'react-router';
import { useDispatch } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';
import { Salesforce } from 'mxp-schemas';
import { CenterAdminNavigationList1, CenterAdminNavigationList2, Routes } from 'constants/index';
import { Heading, ButtonLink, ButtonEnums, Link, Menu, OnlyDesktopCSS } from 'components/atoms';
import { compareTabWithPath, getPath } from 'utils/routes';
import { ReactComponent as BackArrowIcon } from 'resources/images/ic-arrow-back.svg';

interface Props {
  id: string;
  selectedOrganization: Salesforce.Organization | null;
  getOrganizations: () => void;
  selectedCenterMembership: CenterAdmin.FirmMembership | null;
}

export const CenterAdminHeader: React.FC<Props> = ({
  id,
  selectedOrganization,
  getOrganizations,
  selectedCenterMembership,
}) => {
  React.useEffect(() => {
    if (!selectedOrganization) getOrganizations();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();
  const location = useLocation();
  const path: string = location.pathname;

  const selectedMembershipType: string = selectedCenterMembership ? selectedCenterMembership.firmMembershipType : '';

  const isCenterAdminRootPage = Boolean(
    matchPath(path, { path: getPath(Routes.CENTER_ADMIN_ROOT), exact: true }) && !id
  );
  const isCenterAdminNavigationPage = Boolean(
    matchPath(path, { path: getPath(Routes.CENTER_ADMIN_ROOT), exact: true }) && id
  );
  const isCenterAdminInfoPage = Boolean(matchPath(path, { path: getPath(Routes.CENTER_ADMIN_TAB_INFO), exact: false }));
  const isCenterAdminMembershipsPage = Boolean(
    matchPath(path, { path: getPath(Routes.CENTER_ADMIN_MEMBERSHIPS), exact: false })
  );
  const isCenterAdminSeatsManagementPage = Boolean(
    matchPath(path, { path: getPath(Routes.CENTER_ADMIN_MEMBERSHIPS_SEATS_MANAGEMENT), exact: false })
  );

  const title: string =
    selectedOrganization && isCenterAdminNavigationPage
      ? selectedOrganization.name
      : isCenterAdminSeatsManagementPage
      ? 'Benefits access management'
      : isCenterAdminInfoPage
      ? 'Firm information'
      : isCenterAdminMembershipsPage
      ? 'Center memberships'
      : 'Center administrator profile';

  const subTitle: string =
    selectedOrganization && isCenterAdminNavigationPage
      ? `${selectedOrganization.name} ${selectedOrganization?.billingAddress.state}`
      : isCenterAdminSeatsManagementPage
      ? selectedMembershipType
      : '';

  const linkTitle: string = isCenterAdminSeatsManagementPage
    ? `Back to Centers Membership`
    : isCenterAdminInfoPage || isCenterAdminMembershipsPage
    ? `Back to Admin Profile`
    : `Back to Firm Selection`;

  const linkUrl: string = isCenterAdminSeatsManagementPage
    ? generatePath(getPath(Routes.CENTER_ADMIN_MEMBERSHIPS_TAB_MANAGE), { orgId: id })
    : isCenterAdminInfoPage || isCenterAdminMembershipsPage
    ? generatePath(getPath(Routes.CENTER_ADMIN_ROOT), { orgId: id })
    : generatePath(getPath(Routes.CENTER_ADMIN_ROOT));

  const handleCompareTabWithPath = React.useCallback(
    (comparison: string) => compareTabWithPath(path, comparison),
    [path]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const centerAdminInfoList = React.useMemo(
    () =>
      id
        ? CenterAdminNavigationList1.map(item => ({
            label: item.label,
            key: generatePath(getPath(item.key), { orgId: id }),
          }))
        : [],
    [id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const centerAdminMembershipsList = React.useMemo(
    () =>
      id
        ? CenterAdminNavigationList2.map(item => ({
            label: item.label,
            key: generatePath(getPath(item.key), { orgId: id }),
          }))
        : [],
    [id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const buttonTitle: string = 'Switch to my user profile';

  const buttonUrl: string = getPath(Routes.MY_PROFILE_ROOT);

  const handleClick = React.useCallback(
    (e: string, a: any) => {
      dispatch(push(a.name, { orgId: id }));
    },
    [dispatch, id]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledFullWidthContainer
      data-testid="center-header"
      data-addbottompadding={isCenterAdminRootPage || isCenterAdminNavigationPage}
    >
      <Container>
        <StyledGridContainer columns={16} container stackable>
          <Grid.Column computer={16} mobile={16}>
            <StyledFlexContainer>
              <StyledFlexLeftSection>
                {!isCenterAdminRootPage && (
                  <StyledLink testId="test" to={linkUrl} isExternal={false}>
                    <StyledBackArrowIcon /> {linkTitle}
                  </StyledLink>
                )}
                <StyledHeading as="h1">{title}</StyledHeading>
                {!isCenterAdminRootPage && <StyledSubheading>{subTitle}</StyledSubheading>}
              </StyledFlexLeftSection>
              <StyledFlexRightSection>
                {(isCenterAdminRootPage || isCenterAdminNavigationPage) && (
                  <OnlyDesktopCSS>
                    <StyledButtonLink
                      testId={`user-profile-button`}
                      variant={ButtonEnums.variants.primary}
                      size={ButtonEnums.sizes.medium}
                      to={buttonUrl}
                    >
                      {buttonTitle}
                    </StyledButtonLink>
                  </OnlyDesktopCSS>
                )}
              </StyledFlexRightSection>
            </StyledFlexContainer>

            {isCenterAdminSeatsManagementPage ? (
              <></>
            ) : isCenterAdminInfoPage ? (
              <StyledMenu data-testid="tabs-menu" pointing secondary>
                {centerAdminInfoList.map(item => (
                  <Menu.Item
                    data-testid={item.key}
                    key={item.key}
                    name={item.key}
                    content={item.label}
                    active={handleCompareTabWithPath(item.key)}
                    onClick={handleClick}
                  />
                ))}
              </StyledMenu>
            ) : isCenterAdminMembershipsPage ? (
              <StyledMenu data-testid="tabs-menu" pointing secondary>
                {centerAdminMembershipsList.map(item => (
                  <Menu.Item
                    data-testid={item.key}
                    key={item.key}
                    name={item.key}
                    content={item.label}
                    active={handleCompareTabWithPath(item.key)}
                    onClick={handleClick}
                  />
                ))}
              </StyledMenu>
            ) : (
              <></>
            )}
          </Grid.Column>
        </StyledGridContainer>
      </Container>
    </StyledFullWidthContainer>
  );
};

const StyledFullWidthContainer = styled(Container)<{
  'data-addbottompadding': boolean;
}>`
  &&& {
    width: 100vw;
    min-height: ${props => props.theme.pxToRem(217)};
    padding: ${props => props.theme.pxToRem(28)} 0
      ${props => props.theme.pxToRem(props['data-addbottompadding'] ? 28 : 0)} 0;
    box-shadow: inset 0 0 10px 0 ${props => props.theme.colors.neutralGrey3};
    background-color: ${props => props.theme.colors.neutralGrey1};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

const StyledGridContainer = styled(Grid)`
  padding-top: ${props => props.theme.pxToRem(44)};
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  min-height: ${props => props.theme.pxToRem(143)};
`;

const StyledFlexLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledFlexRightSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledHeading = styled(Heading)`
  margin-top: ${props => props.theme.pxToRem(22)};
  margin-bottom: 0;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  min-height: auto; // fix for Safari
`;

const StyledSubheading = styled.div`
  margin-top: ${props => props.theme.pxToRem(8)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  min-height: auto; // fix for Safari
`;

const StyledMenu = styled(Menu)`
  &&&&&&& {
    margin-top: ${props => props.theme.pxToRem(20)};
    margin-bottom: 0;

    & > .active.item {
      background: inherit;
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    width: auto;
    height: ${props => props.theme.pxToRem(40)};
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(24)};
    margin-top: ${props => props.theme.pxToRem(62)};
  }
`;

const StyledLink = styled(Link)`
  &&& {
    color: ${props => props.theme.colors.neutralBlack};
    line-height: 1.75;
    font-weight: ${props => props.theme.fontWeights.medium};
    font-size: ${props => props.theme.fontSizes.xs};
    text-decoration: none;
  }
`;

const StyledBackArrowIcon = styled(BackArrowIcon)`
  fill: ${props => props.theme.colors.primaryPurple};
  width: ${props => props.theme.pxToRem(18)};
  margin-right: ${props => props.theme.pxToRem(4)};
  vertical-align: middle;
  & > path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;
