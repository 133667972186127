import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationEmploymentForm } from 'components/molecules/ApplicationEmploymentForm/ApplicationEmploymentForm';
import {
  customerProfileFetchedSelector,
  customerProfileFetchSuccessSelector,
  employmentDataUpdateLoadingSelector,
  employerAlreadyExistsSelector,
  employersSelector,
  searchOrganizationsByWildcardLoadingSelector,
  searchResultOrganizationsSelector,
  searchOrganizationsCitiesLoadingSelector,
  searchResultOrganizationsCitiesSelector,
  newEmployerSelector,
  employmentDataFromPromoFetchedSelector,
  employmentDataFromPromoFetchSuccessSelector,
  applicationSelector,
  isOrganizationAddressValidSelector,
  organizationSSValidationReasonSelector,
  organizationSSSuggestionsSelector,
  organizationSSValidationFetchedSelector,
  organizationIsSecondaryAddressNeededSelector,
  organizationSmartystreetsValidationSkippedSelector,
  isOrganizationAddressValidationLoadingSelector,
  isUserMemberSelector,
} from 'modules/user/selectors';
import {
  selectedMembershipTypeSelector,
  appliedOrganizationPromoCodeSelector,
  getMembershipApplicationTypeSelector,
  cartCredentialsSelector,
} from 'modules/cart/selectors';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { getApplicationProgressRoute } from 'constants/index';
import {
  upsertEmploymentData,
  upsertEmploymentDataLoading,
  searchOrganizationsByWildcardLoading,
  searchOrganizationsByWildcard,
  searchOrganizationsCitiesLoading,
  searchOrganizationsCities,
  getOrganizationByNameAndCity,
  checkIfOrganizationExists,
  clearNewEmployerData,
  getOrganizationDataByAccountNumber,
  updateApplication,
  validateOrganizationAddress,
  validateOrganizationAddressLoading,
  clearSmartyStreetsSecondaryAddressValidation,
  setOrganizationSuggestedAddress,
  processTLW,
  setNewEmployerJobTitle,
  getMyProfile,
} from 'modules/user/actions';
import { Product, User as UserTypes } from 'mxp-schemas';
import { toggleModalAddressValidationOpen } from 'modules/layouts';
import { isAddressValidationModalOpenSelector } from 'modules/layouts/selectors';
import {
  isCenterMembershipJourneySelector,
  isCimaMembershipJourneySelector,
  isMembershipJourneySelector,
  membershipInviteDataSelector,
} from 'modules/membership/selectors';
import { setIsOpenEmploymentModalFromInvite } from 'modules/membership';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_NEW_MEMBERSHIP_AICPA } from 'modules/featureToggle/constants';

const mapActionCreators = (dispatch: Dispatch) => ({
  updateEmploymentData(
    employmentData: State.NewAccountPersonAccount,
    application: State.Application,
    membershipApplicationType: Product.MembershipApplicationType,
    createNew = false,
    hasExistingEmployers = false,
    isCenterMembershipJourney = false,
    isForModal = false,
    isPaperBilling?: boolean,
    shouldRedirectToCredentialPage = false
  ): void {
    const applicationRoute = getApplicationProgressRoute(
      shouldRedirectToCredentialPage
        ? UserTypes.MembershipApplicationStages.CREDENTIALS
        : UserTypes.MembershipApplicationStages.DONATION,
      membershipApplicationType
    );

    if (hasExistingEmployers && !isCenterMembershipJourney && !isForModal && !!applicationRoute) {
      dispatch(
        updateApplication({
          ...application,
          applicationProgress: UserTypes.MembershipApplicationStages.DONATION,
        })
      );
      dispatch(push(isPaperBilling ? `${getPath(applicationRoute)}?isPaperBilling=true` : getPath(applicationRoute)));
    } else {
      const preventRedirect = isCenterMembershipJourney || isForModal;

      if (isForModal) {
        dispatch(
          updateApplication({
            ...application,
            employmentRole: employmentData.jobTitle,
          })
        );
      }

      dispatch(upsertEmploymentDataLoading());
      dispatch(
        upsertEmploymentData(
          employmentData,
          createNew,
          preventRedirect,
          isForModal,
          false,
          isForModal,
          shouldRedirectToCredentialPage
        )
      );
    }
  },
  searchOrganizationsByWildcard(businessName: string, businessZipCode?: string, zoomInfoSearch?: boolean): void {
    dispatch(searchOrganizationsByWildcardLoading());
    dispatch(searchOrganizationsByWildcard(businessName, businessZipCode, zoomInfoSearch));
  },
  searchOrganizationsCities(accountName: string, wildcardCityName: string, zoomInfoSearch?: boolean): void {
    dispatch(searchOrganizationsCitiesLoading());
    dispatch(searchOrganizationsCities(accountName, wildcardCityName, zoomInfoSearch));
  },
  getOrganizationByNameAndCity(accountName: string, cityName: string): void {
    dispatch(searchOrganizationsCitiesLoading());
    dispatch(getOrganizationByNameAndCity(accountName, cityName));
  },
  clearNewEmployerData(): void {
    dispatch(clearNewEmployerData());
  },
  async checkIfOrganizationExists(accountName: string): Promise<boolean> {
    dispatch(searchOrganizationsByWildcardLoading());
    return dispatch(checkIfOrganizationExists(accountName));
  },
  getOrganizationDataFromPromo(accountNumber: string): void {
    dispatch(getOrganizationDataByAccountNumber(accountNumber));
  },
  toggleModalAddressValidation(toggle: boolean): void {
    dispatch(toggleModalAddressValidationOpen({isAddressValidationModalOpen: toggle}));
  },
  async validateAddress(address: State.Address): Promise<void> {
    dispatch(validateOrganizationAddressLoading());
    return dispatch(validateOrganizationAddress(address));
  },
  clearSmartyStreetsSecondaryAddressValidation(): void {
    dispatch(clearSmartyStreetsSecondaryAddressValidation());
  },
  async setSuggestedAddress(address: Partial<State.Address>): Promise<void> {
    await dispatch(setOrganizationSuggestedAddress(address));
  },
  async processTLW(tlwInfo: {
    id?: string;
    aicpaUid: string;
    tlwReason: string;
    tlwEndDate: string;
    employmentStatus: string;
  }): Promise<void> {
    await dispatch(processTLW(tlwInfo));
  },
  async setNewEmployerJobTitle(jobTitle: string) {
    await dispatch(setNewEmployerJobTitle(jobTitle));
  },
  async getMyProfile(): Promise<void> {
    await dispatch(getMyProfile());
  },
  async setIsOpenEmploymentModalFromInvite(isOpen: boolean): Promise<void> {
    await dispatch(setIsOpenEmploymentModalFromInvite(isOpen));
  },
});

const mapStateToProps = (state: State.Root, ownProps: any) => ({
  employers: employersSelector(state),
  customerProfileFetched: customerProfileFetchedSelector(state),
  customerProfileFetchSuccess: customerProfileFetchSuccessSelector(state),
  employmentDataUpdateLoading: employmentDataUpdateLoadingSelector(state),
  employerAlreadyExists: employerAlreadyExistsSelector(state),
  selectedMembershipType: selectedMembershipTypeSelector(state),
  searchOrganizationsByWildcardLoading: searchOrganizationsByWildcardLoadingSelector(state),
  searchResultOrganizations: searchResultOrganizationsSelector(state),
  searchOrganizationsCitiesLoading: searchOrganizationsCitiesLoadingSelector(state),
  searchResultOrganizationsCities: searchResultOrganizationsCitiesSelector(state),
  newEmployer: newEmployerSelector(state),
  organizationPromoCode: appliedOrganizationPromoCodeSelector(state),
  employmentDataFromPromoFetched: employmentDataFromPromoFetchedSelector(state),
  employmentDataFromPromoFetchSuccess: employmentDataFromPromoFetchSuccessSelector(state),
  application: applicationSelector(state),
  isOrganizationAddressValid: isOrganizationAddressValidSelector(state),
  organizationSSValidationReason: organizationSSValidationReasonSelector(state),
  organizationSSSuggestions: organizationSSSuggestionsSelector(state),
  organizationSSValidationFetched: organizationSSValidationFetchedSelector(state),
  isAddressValidationModalOpen: isAddressValidationModalOpenSelector(state),
  organizationIsSecondaryAddressNeeded: organizationIsSecondaryAddressNeededSelector(state),
  organizationSmartystreetsValidationSkipped: organizationSmartystreetsValidationSkippedSelector(state),
  isOrganizationAddressValidationLoading: isOrganizationAddressValidationLoadingSelector(state),
  isCenterMembershipJourney: isCenterMembershipJourneySelector(state),
  isCimaMembershipJourney: isCimaMembershipJourneySelector(state),
  isMembershipJourney: isMembershipJourneySelector(state),
  toggleCreateOrganization: ownProps.toggleCreateOrganization,
  togglePartnerDeclareAcknowledgement: ownProps.togglePartnerDeclareAcknowledgement,
  acknowledgementConfirmed: ownProps.acknowledgementConfirmed,
  isAcknowledgementConfirmed: ownProps.isAcknowledgementConfirmed,
  isForModal: ownProps.isForModal ?? false,
  handleModalClose: ownProps.handleModalClose ?? false,
  handleSelectedEmployment: ownProps.handleSelectedEmployment ?? false,
  inviteData: membershipInviteDataSelector(state),
  membershipApplicationType: getMembershipApplicationTypeSelector(state),
  isUserMember: isUserMemberSelector(state),
  cartCredentials: cartCredentialsSelector(state),
  useNewMembershipAICPA: getFeatureToggleByKeySelector(state, USE_NEW_MEMBERSHIP_AICPA),
});

export const ApplicationEmploymentFormContainer = connect(
  mapStateToProps,
  mapActionCreators
)(ApplicationEmploymentForm);
