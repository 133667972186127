import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Container, ButtonEnums, Button, ButtonLink, Heading, Divider, OnlyDesktop } from 'components/atoms';
import { Grid } from 'components/atoms/Grid/Grid';
import { ReactComponent as Print } from 'resources/images/ic-print.svg';
import { BackLink } from 'components/molecules/BackLink/BackLink';
import styled from 'styled-components';
import { getPath, MomentHelpers, isServer, formatPrice } from 'utils';
import { Routes } from 'constants/index';
import { invoicesPaymentConfirmationSelector } from 'modules/admin/selectors';
import { push } from 'connected-react-router';
import { resetAdminInvoicesPayResultModule } from 'modules/admin/actions';

export const AdminInvoicesConfirmationPage: React.FC = () => {
  const confirmation = useSelector(invoicesPaymentConfirmationSelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!confirmation) {
      dispatch(push(generatePath(getPath(Routes.ADMIN_ROOT))));
    }
    return () => dispatch(resetAdminInvoicesPayResultModule());
  }, [dispatch, confirmation]);

  const handlePrintClick = React.useCallback(() => {
    if (!isServer) window.print();
  }, []);

  const invoiceNumbers = confirmation?.invoiceNumbers;
  const totalBalance = confirmation?.totalBalance || 0;
  const totalAmountPaid = confirmation?.totalAmountPaid || 0;
  const paymentDate = confirmation?.paymentDate;
  const currency = confirmation?.currency;

  return (
    <Container>
      <BackLink testId="back-to-products-purchases-console" to={generatePath(getPath(Routes.ADMIN_ROOT))}>
        Back to Products & Purchases Console
      </BackLink>
      <Grid>
        <Grid.Row>
          <Grid.Column computer={11} mobile={16}>
            <Wrapper>
              <Header>
                <StyledHeading as="h1">Payment Confirmed</StyledHeading>
                <OnlyDesktop>
                  <StyledButton
                    testId="payment-confirmed-print"
                    variant={ButtonEnums.variants.link}
                    size={ButtonEnums.sizes.large}
                    icon={<Print />}
                    iconPosition={ButtonEnums.iconPosition.left}
                    onClick={handlePrintClick}
                  >
                    Print Confirmation
                  </StyledButton>
                </OnlyDesktop>
              </Header>
              <Divider />
              <SummaryDetails>
                <Fields>
                  <DateComponent>Payment Date: {MomentHelpers.getDate(paymentDate)}</DateComponent>
                  <Text>Invoice Numbers:</Text>
                  {invoiceNumbers?.map((invoice: string) => (
                    <Text key={invoice}>{invoice}</Text>
                  ))}
                  <Text>Thank you for your payment.</Text>
                </Fields>
              </SummaryDetails>
              <InvoiceSummaryTitle data-testid="invoice-summary-title">Invoice summary</InvoiceSummaryTitle>
              <Divider />
              <SummaryDetails>
                <Fields>
                  <BoldText>Total Invoice Balance:</BoldText>
                  <BoldText>Amount Paid:</BoldText>
                </Fields>
                <Data>
                  <Text>{formatPrice(totalBalance, currency)}</Text>
                  <Text>{formatPrice(totalAmountPaid, currency)}</Text>
                </Data>
              </SummaryDetails>
              <StyledButtonLink
                variant={ButtonEnums.variants.primary}
                to={generatePath(getPath(Routes.ADMIN_ROOT))}
                testId="invoice-payment-confirmation-back-button"
              >
                Back to Products & Purchases Console
              </StyledButtonLink>
            </Wrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

const Header = styled.div`
  display: flex;
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&&&&& {
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(16)};
    margin-top: ${props => props.theme.pxToRem(40)};
  }
`;

const SummaryDetails = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 14rem;
  font-size: ${props => props.theme.fontSizes.xs};
`;

const Text = styled.div`
  font-weight: ${props => props.theme.fontWeights.light};
  padding: ${props => props.theme.pxToRem(8)} 0;
`;

const DateComponent = styled.div`
  font-weight: ${props => props.theme.fontWeights.light};
  padding: ${props => props.theme.pxToRem(16)} 0;
`;

const Fields = styled.div`
  display: flex-column;
`;

const Data = styled.div`
  display: flex-column;
`;

const BoldText = styled.div`
  font-weight: ${props => props.theme.fontWeights.medium};
  padding: ${props => props.theme.pxToRem(8)} 0;
`;

const InvoiceSummaryTitle = styled.div`
  padding: ${props => props.theme.pxToRem(21)} 0 ${props => props.theme.pxToRem(3)} 0;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.33;
`;

const Wrapper = styled.div`
  position: relative;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(24)} 0 ${props => props.theme.pxToRem(40)};
  }
`;

const StyledHeading = styled(Heading)`
  font-weight: ${props => props.theme.fontWeights.light};
  margin: 0;
  flex: 1;
`;

const StyledButton = styled(Button)`
  &&&& {
    svg {
      color: ${props => props.theme.colors.primaryDarkPurple};
      margin-top: 0.25rem;
    }
    flex: 1;
    margin-top: ${props => props.theme.pxToRem(5)};
  }
`;
