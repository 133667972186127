import React from 'react';
import styled from 'styled-components';

interface LabelProps {
  children: any;
  htmlFor?: string;
}

const StyledLabel = styled.label`
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-right: 0.4rem;
`;

export const Label: React.FC<LabelProps> = ({ children, ...props }) => <StyledLabel {...props}>{children}</StyledLabel>;
