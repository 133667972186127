import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { PageHelmet } from 'components/atoms/PageHelmet/PageHelmet';
import { SearchQueryModal } from 'components/organisms';
import { getZIndexFor, Z_INDEX_ORDER } from 'utils/getZIndexFor';

interface SearchModalPropsIF {
  isAuth: boolean | null;
  close(): void;
  suggestions: SuggestionsIF;
  fetchSuggestions(searchQuery: string): void;
  deleteSearchHistoryItem(searchQuery: string): void;
  getSearchHistory(): void;
  clearSuggestions: () => void;
  recentSearches: string[];
  showModal: boolean;
  navigate: (path: string) => void;
}

interface SuggestionsIF {
  results: State.SuggestionResult[];
  searchQuery: string;
}

export const SearchModal: React.FC<SearchModalPropsIF> = ({
  isAuth,
  close,
  fetchSuggestions,
  suggestions,
  clearSuggestions,
  recentSearches,
  deleteSearchHistoryItem,
  getSearchHistory,
  showModal,
  navigate,
}) => {
  if (!showModal) return null;
  const { results, searchQuery } = suggestions;
  return (
    <React.Fragment>
      <PageHelmet title={'Search'} />
      <StyledSearchModal showModal={showModal}>
        <StyledOverlay />
        <StyledOverflowRemover />
        <SearchQueryModal
          isAuth={isAuth}
          close={close}
          fetchSuggestions={fetchSuggestions}
          suggestions={results}
          suggestionSource={searchQuery}
          clearSuggestions={clearSuggestions}
          recentSearches={recentSearches}
          deleteSearchHistoryItem={deleteSearchHistoryItem}
          getSearchHistory={getSearchHistory}
          navigate={navigate}
        />
      </StyledSearchModal>
    </React.Fragment>
  );
};

const StyledOverflowRemover = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`;

const StyledSearchModal = styled.div<{ showModal: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${getZIndexFor(Z_INDEX_ORDER.SearchModal)};
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  opacity: 0.98;
`;
