import styled from 'styled-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { setDataAction, setPaginationAction } from 'modules/centerAdmin';
import {
  dataSelectorFactory,
  dataAllIdsSelectorFactory,
  paginationSelectorFactory,
  isNoSearchAndFiltersInPaginationSelectorFactory,
} from 'modules/centerAdmin/selectors';
import { TableSearchInput } from 'components/atoms/TableSearchInput';
import { Pagination } from 'components/organisms/CombinedFiltersPaginationView/Pagination';
import { Table, Divider, Checkbox, TablePlaceholder } from 'components/atoms';
import { TableHeaders, CenterAdminTableType } from 'constants/center-admin';
import { CenterAdminDataTableMessageViewText } from './CenterAdminDataTableMessageViewText';

const EmptyTable: React.FC<{ colSpan: number; children: React.ReactNode }> = ({ colSpan, children }) => (
  <Table.Row>
    <Table.Cell style={{ height: 110, verticalAlign: 'middle', textAlign: 'center' }} rowSpan={5} colSpan={colSpan}>
      {children}
    </Table.Cell>
  </Table.Row>
);

interface Props {
  type: CenterAdmin.CenterAdminTableType;
  children: React.ReactNode[];
  totalAmount: number;
  showHeaderCheckbox?: boolean;
  sendRequest: () => Promise<void>;
  additionalHeaderNode?: React.ReactNode;
  additionalHeaderControlNode?: React.ReactNode;
  hideFilters?: boolean;
  messageEmptyTable?: string | JSX.Element;
}

export const CenterAdminDataTable: React.FC<Props> = ({
  type,
  children,
  totalAmount,
  showHeaderCheckbox = false,
  sendRequest,
  additionalHeaderNode,
  additionalHeaderControlNode,
  hideFilters = false,
  messageEmptyTable,
}) => {
  const headers = TableHeaders[type];
  const dispatch = useDispatch();

  const { selectedIds, isLoading }: CenterAdmin.Data<any> = useSelector(dataSelectorFactory(type));
  const [isPaidInvoiceMemo, setIsPaidInvoiceMemo] = useState<boolean | null>(null);
  const allIds: string[] = useSelector(dataAllIdsSelectorFactory(type));
  const isNoSearchAndFiltersInPagination = useSelector(isNoSearchAndFiltersInPaginationSelectorFactory(type));
  const { limit, offset, query, filters }: CenterAdmin.Pagination = useSelector(paginationSelectorFactory(type));

  const [searchInput, setSearchInput] = useState<string>(query);

  const allIdsAreSelected = useMemo(
    () => Boolean(allIds?.length) && allIds.every(id => selectedIds.includes(id)),
    [allIds, selectedIds]
  );

  const onHeaderCheckboxChange = React.useCallback(() => {
    const nextSelectedIds = allIdsAreSelected
      ? selectedIds.filter(id => !allIds.includes(id))
      : [...new Set([...allIds, ...selectedIds])];

    dispatch(setDataAction({ type, modifier: { selectedIds: nextSelectedIds } }));
  }, [dispatch, type, allIds, allIdsAreSelected, selectedIds]);

  const [setDebouncedQuery, cancelDebouncedQuery] = useDebouncedCallback(
    () => dispatch(setPaginationAction({ type, modifier: { query: searchInput } })),
    1000
  );

  const handleSearchInputChange = useCallback(
    (event: React.SyntheticEvent) => {
      const value: string = (event.target as HTMLInputElement).value;
      setSearchInput(value);
      setDebouncedQuery();
    },
    [setDebouncedQuery]
  );

  useEffect(() => {
    if (type === CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES) {
      if (isPaidInvoiceMemo === filters.isPaidInvoice) return;
      setIsPaidInvoiceMemo(Boolean(filters.isPaidInvoice));
      sendRequest();
    }
    return cancelDebouncedQuery;
  }, [limit, offset, query, filters]); // eslint-disable-line

  const setPage = useCallback(
    currentPage => {
      dispatch(setPaginationAction({ type, modifier: { offset: limit * (currentPage - 1) } }));
    },
    [type, limit, dispatch]
  );

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <EmptyTable colSpan={headers.length + Number(showHeaderCheckbox)}>
          <TablePlaceholder />
        </EmptyTable>
      );
    }

    if ((totalAmount > 0 && children?.length) || !messageEmptyTable) {
      return children.map(child =>
        React.isValidElement(child) ? React.cloneElement(child, { type, id: child.key }) : child
      );
    }

    const emptySearchResults = (
      <StyledEmptySearchResultContainer>
        <StyledEmptySearchResultText>No results found for</StyledEmptySearchResultText>
        <StyledEmptySearchResultValue>{query}</StyledEmptySearchResultValue>
        <StyledEmptySearchResultText>Tips for your next search:</StyledEmptySearchResultText>
        <StyledEmptySearchResultList>
          <li>Check the spelling of your keyword</li>
          <li>Try searching using another keyword</li>
        </StyledEmptySearchResultList>
      </StyledEmptySearchResultContainer>
    );

    return (
      <EmptyTable colSpan={headers.length + Number(showHeaderCheckbox)}>
        <CenterAdminDataTableMessageViewText
          text={
            isNoSearchAndFiltersInPagination && totalAmount === 0
              ? messageEmptyTable
              : query
              ? emptySearchResults
              : 'No Records Found'
          }
        />
      </EmptyTable>
    );
  };

  return (
    <div>
      <StyledDivider />
      {additionalHeaderNode && <StyledAdditionalHeaderNode>{additionalHeaderNode}</StyledAdditionalHeaderNode>}
      <StyledControlsContainer>
        <TableSearchInput value={searchInput} onChange={handleSearchInputChange} placeholder="Search Invoice" />
      </StyledControlsContainer>
      <StyledTable stackable>
        <Table.Header>
          <Table.Row>
            {showHeaderCheckbox && (
              <Table.HeaderCell key="checkbox-0">
                <CheckboxStyled
                  width="18"
                  height="18"
                  type="checkbox"
                  testId="select-all-checkbox"
                  checked={allIdsAreSelected}
                  onChange={onHeaderCheckboxChange}
                  disabled={!allIds?.length || isLoading}
                />
              </Table.HeaderCell>
            )}
            {headers.map((item: CenterAdmin.DataTableHeader) => (
              <Table.HeaderCell key={item.title}>{item.title}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderTableBody()}</Table.Body>
      </StyledTable>
      {!isLoading && totalAmount / limit > 1 && (
        <PaginationContainer>
          <Pagination
            testId={`pagination`}
            total={totalAmount}
            page={offset / limit + 1}
            perPage={limit}
            onPageChange={setPage}
          />
        </PaginationContainer>
      )}
    </div>
  );
};

const StyledDivider = styled(Divider)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-bottom: ${props => props.theme.pxToRem(30)};
  }
`;

const StyledTable = styled(Table)`
  &&&& {
    border: 0;
    margin: 0;
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-bottom: ${props => props.theme.pxToRem(5)};

    tr {
      :nth-of-type(2n) {
        background-color: rgba(247, 247, 247, 0.3);
        + td {
          background-color: rgba(247, 247, 247, 0.3);
        }
      }

      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-top: ${props => props.theme.pxToRem(24)};
        padding-bottom: ${props => props.theme.pxToRem(24)};
        box-shadow: 0 ${props => props.theme.pxToRem(-1)} 0 0 ${props => props.theme.colors.neutralGrey3} inset !important;
        margin-top: ${props => props.theme.pxToRem(16)};

        :first-child {
          padding-top: 0;
        }
      }
    }

    thead tr {
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-bottom: 0 !important;
      }
      th {
        ${props => props.theme.mediaQueries.mobileOnly} {
          :first-child {
            padding-top: ${props => props.theme.pxToRem(24)} !important;
          }
          :last-child {
            padding-bottom: ${props => props.theme.pxToRem(24)} !important;
          }
          font-size: ${props => props.theme.fontSizes.s};
        }
      }
    }

    tr th {
      padding: ${props => props.theme.pxToRem(10)};
      border-bottom-color: ${props => props.theme.colors.neutralGrey3};
      background-color: ${props => props.theme.colors.neutralGrey1};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.medium};
      white-space: nowrap;
      vertical-align: middle;

      ${props => props.theme.mediaQueries.mobileOnly} {
        font-size: ${props => props.theme.fontSizes.s};

        :nth-child(2) {
          text-align: left;
        }
      }
    }

    tr td {
      padding: ${props => props.theme.pxToRem(10)};
      border-top-color: ${props => props.theme.colors.neutralGrey3};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.light};
      vertical-align: middle;

      ${props => props.theme.mediaQueries.mobileOnly} {
        padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;
        font-size: ${props => props.theme.fontSizes.s} !important;

        :first-child {
          font-weight: ${props => props.theme.fontWeights.regular};
        }

        :nth-child(2) {
          text-align: left;
        }
      }
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      border-width: 0;
      margin-top: 0;
    }
  }
`;

const StyledControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    color: ${props => props.theme.colors.neutralGrey8};
    font-weight: ${props => props.theme.fontWeights.light};
    vertical-align: middle;
  }
`;

const StyledAdditionalHeaderNode = styled.div`
  position: relative;
  top: ${props => props.theme.pxToRem(-10)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledEmptySearchResultContainer = styled.div`
  text-align: left;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  padding: ${props => props.theme.pxToRem(16)} 0;
`;

const StyledEmptySearchResultText = styled.span`
  display: block;
`;

const StyledEmptySearchResultValue = styled.span`
  display: block;
  font-size: ${props => props.theme.fontSizes.l};
  line-height: 2.25;
  color: ${props => props.theme.colors.neutralBlack};
`;

const StyledEmptySearchResultList = styled.ul`
  margin: 0;
  padding-left: ${props => props.theme.pxToRem(20)};
  line-height: 1.33;
`;
