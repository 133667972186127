import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { Table, Divider, Button, ButtonEnums } from 'components/atoms';
import { CenterAdminSeatManagementDataTable } from 'components/organisms/CenterAdminDataTable/CenterAdminSeatManagementDataTable';
import { RemoveConfirmationModal } from './RemoveConfirmationModal';
import { RemoveSuccessModal } from './RemoveSuccessModal';

interface Props {
  setContractLineItemModifiers: (modifiers: { query?: string; offset?: number; limit?: number }) => void;
  getCenterMemberships: (orgId: string) => void;
  getServiceContracts: () => void;
  removeContractLineItems: (lineItemIds: string[], emails: string[]) => void;
  selectedCenterMembership: CenterAdmin.FirmMembership | null;
  contractLineItemList: CenterAdmin.ContractLineItem[];
  contractLineItemModifiers: CenterAdmin.ContractLineItemModifiers;
  contractLineItemIsFetched: boolean;
}

export const PageCenterAdminSeatsManagement: React.FC<Props> = ({
  setContractLineItemModifiers,
  selectedCenterMembership,
  getCenterMemberships,
  getServiceContracts,
  removeContractLineItems,
  contractLineItemList,
  contractLineItemModifiers,
  contractLineItemIsFetched,
}) => {
  const { orgId } = useParams<{ orgId: string }>();

  const { query, offset, limit } = contractLineItemModifiers;
  const [searchInput, setSearchInput] = useState<string>(query);
  const [selectedRecord, setSelectedRecord] = useState<{ id: string; email: string }>({
    id: '',
    email: '',
  });
  const [isRemoveConfirmationModalOpen, setIsRemoveConfirmationModalOpen] = useState(false);
  const [isRemoveSuccessModalOpen, setIsRemoveSuccessModalOpen] = useState(false);

  const membershipType: string = selectedCenterMembership ? selectedCenterMembership.firmMembershipType : '';

  const [setDebouncedQuery, cancelDebouncedQuery] = useDebouncedCallback(() => {
    setContractLineItemModifiers({ query: searchInput });
  }, 1000);

  useEffect(() => {
    if (!selectedCenterMembership) getCenterMemberships(orgId);
  }, [selectedCenterMembership, orgId, getCenterMemberships]);

  useEffect(() => {
    getServiceContracts();
    return cancelDebouncedQuery;
  }, [getServiceContracts, cancelDebouncedQuery]);

  const handleSearchInputChange = useCallback(
    (event: React.SyntheticEvent) => {
      const value: string = (event.target as HTMLInputElement).value;
      setSearchInput(value);
      setDebouncedQuery();
    },
    [setDebouncedQuery]
  );

  const handlePaginationChange = useCallback(
    currentPage => {
      setContractLineItemModifiers({ offset: limit * (currentPage - 1) });
    },
    [limit, setContractLineItemModifiers]
  );

  const handleSelectRecordForRemove = (id: string, email: string) => {
    setSelectedRecord({ id, email });
    setIsRemoveConfirmationModalOpen(true);
  };

  const handleRemoveConfirmationCancel = (event?: React.SyntheticEvent<HTMLElement>) => {
    event?.preventDefault();
    setSelectedRecord({ id: '', email: '' });
    setIsRemoveConfirmationModalOpen(false);
  };

  const handleRemoveConfirmationSubmit = () => {
    // dispatch remove contact line item here
    setIsRemoveConfirmationModalOpen(false);
    setIsRemoveSuccessModalOpen(true);
    removeContractLineItems([selectedRecord.id], [selectedRecord.email]);
  };

  const toggleCloseModal = () => {
    setIsRemoveSuccessModalOpen(false);
  };

  return (
    <>
      <RemoveConfirmationModal
        open={isRemoveConfirmationModalOpen}
        onCancel={handleRemoveConfirmationCancel}
        onConfirmation={handleRemoveConfirmationSubmit}
        membershipType={membershipType}
        email={selectedRecord.email}
      />

      <RemoveSuccessModal
        open={isRemoveSuccessModalOpen}
        onCancel={toggleCloseModal}
        onReturn={toggleCloseModal}
        membershipType={membershipType}
      />

      <StyledSubtitle>Seats Management - {membershipType}</StyledSubtitle>
      <StyledNormalText>Manage benefit access for individuals within your business.</StyledNormalText>

      <StyledDivider />

      <CenterAdminSeatManagementDataTable
        searchValue={searchInput}
        searchHandler={handleSearchInputChange}
        searchPlaceholder={`Search ${membershipType} Seat Management`}
        headerData={['Name', 'Email Address', '']}
        isLoading={!contractLineItemIsFetched}
        isEmpty={contractLineItemIsFetched && !contractLineItemList.length}
        emptyTableMessage={`You have no data`}
        isShowPagination={contractLineItemIsFetched && Boolean(limit) && contractLineItemList.length / limit > 1}
        paginationCount={contractLineItemList.length}
        paginationLimit={limit}
        paginationOffset={offset}
        paginationChangeHandler={handlePaginationChange}
      >
        {contractLineItemList.map((data: CenterAdmin.ContractLineItem) => {
          const removeSelectedRecord = () => handleSelectRecordForRemove(data.contractLineItemId, data.contactEmail);

          return (
            <Table.Row key={data.contactEmail}>
              <Table.Cell>{data.contactName}</Table.Cell>
              <Table.Cell>{data.contactEmail}</Table.Cell>
              <Table.Cell>
                <Button
                  variant={ButtonEnums.variants.link}
                  testId="test-id"
                  iconName="remove circle"
                  iconPosition={ButtonEnums.iconPosition.left}
                  size={ButtonEnums.sizes.small}
                  onClick={removeSelectedRecord}
                >
                  Remove
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </CenterAdminSeatManagementDataTable>
    </>
  );
};

const StyledSubtitle = styled.div`
  font-size: ${props => props.theme.fontSizes.l};
  margin-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledNormalText = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
  width: ${props => props.theme.pxToRem(700)};
`;

const StyledDivider = styled(Divider)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-bottom: ${props => props.theme.pxToRem(30)};
  }
`;
