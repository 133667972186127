import { GraphQLClient as GraphQLClientRequest } from 'graphql-request';
import { parse as gqlParse } from 'graphql';
import { isServer } from 'utils';
import { getAuthHelper } from 'utils/auth';
import { handleEvent, ERROR } from 'utils/Analytics';
import { serverConfig, getPreloadedConfig } from '../config';
import { store } from '../store';
import { logout, invalidateSession } from 'modules/user/actions';
import jwtDecode from 'jwt-decode';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { isAuthSelector } from 'modules/user/selectors';
class GraphQLClient {
  private static token: string | undefined;
  private static client: any = null;

  private static RESPONSE_STATUSES = {
    NOT_AUTHORIZED: 403,
  };
  private static ERROR_QUERY_EXEMPTIONS = ['uploadDocument', 'uploadDocumentCima'];

  private static async getToken(): Promise<string | undefined> {
    if (isServer) return;
    const authHelper = getAuthHelper();
    const token = await authHelper.getValidAccessToken();
    return token;
  }

  static updateHeaders(header?: { type: string; value: any }) {
    const existingCustomHeaders = GraphQLClient.client?.options?.headers?.customHeaders;
    // Merge new and existing custom headers
    if (header?.type === 'customHeaders' && header?.value) {
      const combinedHeaders = {
        ...JSON.parse(existingCustomHeaders || '{}'),
        ...JSON.parse(header.value),
      };
      GraphQLClient.client.options.headers.customHeaders = JSON.stringify(combinedHeaders);
    }
  }

  private static async getClient(header?: { type: string; value: any }) {
    const isAdmin = isAdminPortalSelector(store.getState());
    const isAuth = isAuthSelector(store.getState());
    const isImpersonation = Boolean(isAuth) && isAdmin;

    if (GraphQLClient.token && GraphQLClient.client && !isImpersonation) {
      const parsedToken: any = jwtDecode(GraphQLClient.token);
      const now: number = Date.now();
      const ifTokenExpires = parsedToken.exp * 1000 <= now;

      if (!ifTokenExpires) return GraphQLClient.client;
    }

    const token: string | undefined = await GraphQLClient.getToken();
    if (GraphQLClient.client && GraphQLClient.token === token) {
      // there is a client and the token didn't change
      return GraphQLClient.client;
    }
    if (typeof token === undefined) {
      // tslint:disable-next-line:no-console
      console.log(`Token is undefined`);
      return;
    }

    GraphQLClient.token = token;
    const headers: { [key: string]: any } = {};
    if (token) {
      // set Authorization header only in case if token exists
      headers.authorization = `Bearer ${token}`;
    }

    if (header?.type && header?.value) {
      headers[header.type] = header.value;
    }

    // ssr side actions execution require absolute url
    const url: string = isServer ? serverConfig.graphqlUrl : getPreloadedConfig()?.graphqlUrl || '/graphql';
    GraphQLClient.client = new GraphQLClientRequest(url, { headers });
    GraphQLClient.updateHeaders(header);
    return GraphQLClient.client;
  }

  static async request(query: string, variables?: any, header?: { type: string; value: any }) {
    const client = await GraphQLClient.getClient(header);
    if (!client) {
      // tslint:disable-next-line:no-console
      console.log(`Unexpected error encountered while processing the request . Please refresh the page.`);
      handleEvent(
        { error: 'Unexpected error encountered while processing the request . Please refresh the page.' },
        ERROR
      );
      throw new Error('Unexpected error encountered while processing the request . Please refresh the page.');
    }
    return (
      client
        .request(query, variables)
        // graphql response interceptor
        .catch((err: any) => {
          const status = err.response && err.response.status;
          const queryDefString = JSON.stringify(gqlParse(query)?.definitions?.[0]);
          // tslint:disable-next-line:no-console
          console.log(`Error Response: ${JSON.stringify(err)}`);
          handleEvent({ error: `Error Response: ${JSON.stringify(err)}` }, ERROR);
          if (
            status === GraphQLClient.RESPONSE_STATUSES.NOT_AUTHORIZED &&
            !GraphQLClient.ERROR_QUERY_EXEMPTIONS.includes(JSON.parse(queryDefString)?.name?.value)
          ) {
            store.dispatch(invalidateSession());
            store.dispatch(logout());
          }
          err.primaryApplicationError = err.response && err.response.errors && err.response.errors[0];
          return Promise.reject(err);
        })
    );
  }
}

export const updateContext = (headers: any) => {
  return GraphQLClient.updateHeaders({ type: 'customHeaders', value: JSON.stringify(headers) });
};
export default GraphQLClient.request;
