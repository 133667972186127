import React from 'react';
import styled from 'styled-components';
import { Placeholder } from 'semantic-ui-react';

export const TablePlaceholder: React.FC = () => (
  <>
    {[...Array(5).keys()].map(i => (
      <StyledTablePlaceholder key={i}>
        <Placeholder fluid>
          <Placeholder.Line length="full" />
        </Placeholder>
      </StyledTablePlaceholder>
    ))}
  </>
);

const StyledTablePlaceholder = styled.div`
  padding: ${props => props.theme.pxToRem(28)} ${props => props.theme.pxToRem(20)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(20)} 0;
  }

  :nth-child(2n - 2) {
    background-color: rgba(247, 247, 247, 0.3);
  }

  .ui.placeholder .line {
    height: 100%;
  }
`;
