import { SurveyOption, SurveyOptionTypeEnum } from 'constants/index';

export const negativeOptions: SurveyOption[] = [
  {
    type: SurveyOptionTypeEnum.Radio,
    title: 'What can we help you with?',
    values: {
      'I can’t find what I’m looking for.': {
        text: `I can’t find what I’m looking for.`,
        activeForm: [
          {
            type: SurveyOptionTypeEnum.Radio,
            title: 'What can we help you find?',
            values: {
              'My AICPA store purchases.': {
                text: 'My AICPA store purchases.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'Information on CPE / upcoming events / conferences.': {
                text: 'Information on CPE / upcoming events / conferences.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'Information about a specific topic.': {
                text: 'Information about a specific topic.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'Specific technical information.': {
                text: 'Specific technical information.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'My section materials.': {
                text: 'My section materials.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'Peer review.': {
                text: 'Peer review.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              Other: {
                text: 'Other.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
            },
          },
        ],
      },
      'The content and experience are not personalized enough.': {
        text: `The content and experience are not personalized enough.`,
        activeForm: [
          {
            title: 'How could we improve this?',
            type: SurveyOptionTypeEnum.Radio,
            values: {
              'More fields for personalization.': {
                text: 'More fields for personalization.',
                activeForm: [
                  {
                    title: 'What fields would you like:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'Better content relevance.': {
                text: 'Better content relevance.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'Better understanding of my current situation.': {
                text: 'Better understanding of my current situation.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
            },
          },
        ],
      },
      'This content could be better.': {
        text: `This content could be better.`,
        activeForm: [
          {
            title: 'How could we improve this content?',
            type: SurveyOptionTypeEnum.Radio,
            values: {
              'There is not enough of it.': {
                text: 'There is not enough of it.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'It’s too long.': {
                text: 'It’s too long.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'It’s hard to understand.': {
                text: 'It’s hard to understand.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'It’s too short.': {
                text: 'It’s too short.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'There is not enough detail.': {
                text: 'There is not enough detail.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'The content should be more current, useful, or relevant.': {
                text: 'The content should be more current, useful, or relevant.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              Other: {
                text: 'Other.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
            },
          },
        ],
      },
      'I’m having a technical issue.': {
        text: `I’m having a technical issue.`,
        activeForm: [
          {
            title: 'Tell us more:',
            type: SurveyOptionTypeEnum.Radio,
            values: {
              'The site does not work right on my browser / device.': {
                text: 'The site does not work right on my browser / device.',
                activeForm: [
                  {
                    title: 'Sorry about that. Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'I can’t download file / documents.': {
                text: 'I can’t download file / documents.',
                activeForm: [
                  {
                    title: 'Sorry about that. Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              'There is a broken link.': {
                text: 'There is a broken link.',
                activeForm: [
                  {
                    title: 'Please tell us what link needs to be fixed.',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
              Other: {
                text: 'Other.',
                activeForm: [
                  {
                    title: 'Tell us more:',
                    type: SurveyOptionTypeEnum.TextArea,
                  },
                ],
              },
            },
          },
        ],
      },
      'I have some other feedback.': {
        text: `I have some other feedback.`,
        activeForm: [
          {
            title: 'Please tell us your feedback.',
            type: SurveyOptionTypeEnum.TextArea,
          },
        ],
      },
    },
  },
];

export const positiveOptions: SurveyOption[] = [
  {
    type: SurveyOptionTypeEnum.TextArea,
    title: 'Thanks! Anything else we should know?',
  },
];
