import React, { useState, useCallback } from 'react';
import { Salesforce } from 'mxp-schemas';
import { FilterRadioLabel } from 'components/molecules';
import { Button, ButtonSizes, ButtonVariants, ToggleButton } from 'components/atoms';
import styled from 'styled-components';

interface Props {
  testId: string;
  additionalAddresses: Array<Partial<Salesforce.ContactPointAddress>>;
  checked?: string;
  onChange: () => void;
  defaultText: string;
  collapsedText: string;
  hideToggleButton?: boolean;
  onDeleteAddress?: () => void;
  isLoading?: boolean;
  fromProfile?: boolean;
}

export const AdditionalAddresses: React.FC<Props> = ({
  testId,
  checked,
  onChange,
  defaultText,
  collapsedText,
  additionalAddresses,
  hideToggleButton,
  onDeleteAddress,
  isLoading = false,
  fromProfile = false,
}) => {
  const [areAllVisible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => {
    setVisibility(!areAllVisible);
  }, [areAllVisible]);

  return (
    <StyledContainer>
      {!hideToggleButton && (
        <StyledToggleButton testId={`show-hide-toggle-${testId}`} onClick={toggleVisibility} isOpen={areAllVisible}>
          {areAllVisible ? collapsedText : defaultText}
        </StyledToggleButton>
      )}
      <p />
      {!hideToggleButton ? (
        <>
          {areAllVisible &&
            additionalAddresses.map(
              ({
                id,
                firstName,
                lastName,
                company,
                addressLine1,
                addressLine2,
                city,
                state,
                zipCode,
                country,
                addressType,
              }) => {
                const nameExists: boolean = Boolean(firstName || lastName);
                return (
                  <React.Fragment key={`${id}_${zipCode}_${addressLine1}`}>
                    <FilterRadioLabel
                      className="radio"
                      checked={id === checked}
                      testId={`${id}_${testId}`}
                      onChange={onChange.bind(null, id)}
                      reverseLabelAndRadio
                    >
                      <div>
                        {nameExists && (
                          <>
                            {firstName} {lastName}
                            <br />
                          </>
                        )}
                        {company && (
                          <>
                            {company} <br />
                          </>
                        )}
                        {addressLine1} <br />
                        {addressLine2 && (
                          <>
                            {addressLine2} <br />
                          </>
                        )}
                        {city}, {state} <br />
                        {zipCode}
                        <br />
                        {country}
                        <br />
                        {addressType}
                        {id && fromProfile && (
                          <>
                            <br />
                            <Button
                              testId={`delete_${id}`}
                              disabled={id !== checked}
                              onClick={onDeleteAddress?.bind(null, id)}
                              loading={isLoading}
                            >
                              Delete
                            </Button>
                          </>
                        )}
                      </div>
                    </FilterRadioLabel>
                  </React.Fragment>
                );
              }
            )}
        </>
      ) : (
        <>
          {additionalAddresses.map(
            ({
              id,
              firstName,
              lastName,
              company,
              addressLine1,
              addressLine2,
              city,
              state,
              zipCode,
              country,
              addressType,
            }) => {
              const nameExists: boolean = Boolean(firstName || lastName);
              return (
                <React.Fragment key={`${id}_${zipCode}_${addressLine1}`}>
                  <FilterRadioLabel
                    className="radio"
                    checked={id === checked}
                    testId={`${id}_${testId}`}
                    onChange={onChange.bind(null, id)}
                    reverseLabelAndRadio
                  >
                    <div>
                      {nameExists && (
                        <>
                          {firstName} {lastName}
                          <br />
                        </>
                      )}
                      {company && (
                        <>
                          {company} <br />
                        </>
                      )}
                      {addressLine1} <br />
                      {addressLine2 && (
                        <>
                          {addressLine2} <br />
                        </>
                      )}
                      {city}, {state} <br />
                      {zipCode}
                      <br />
                      {country}
                      <br />
                      {addressType}
                      {id && (
                        <>
                          <br />
                          <Button
                            testId={`delete_${id}`}
                            disabled={id !== checked}
                            onClick={onDeleteAddress?.bind(null, id)}
                            loading={isLoading}
                            variant={ButtonVariants.primary}
                            size={ButtonSizes.large}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </div>
                  </FilterRadioLabel>
                </React.Fragment>
              );
            }
          )}
        </>
      )}
    </StyledContainer>
  );
};

const StyledToggleButton = styled(ToggleButton)`
  font-size: 0.9rem;
  font-weight: bold;
`;

const StyledContainer = styled.div`
  padding-left: ${props => props.theme.pxToRem(25)};
  padding-bottom: ${props => props.theme.pxToRem(25)};
`;
