import React, { useState, useCallback } from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Modal, Button, ButtonEnums, Checkbox } from 'components/atoms';
import { RadioOption } from 'components/molecules/RadioOption/RadioOption';
import { Grid, TextArea } from 'semantic-ui-react';
import { DropdownWithLabel } from 'components/molecules/DropdownWithLabel/DropdownWithLabel';
import { MipDownloadApplicationModal } from './MipDownloadApplicationModal';
import { NotEligibleMembershipTypeModal } from './NotEligibleMembershipTypeModal';
import { MipSuccessPracticeCheckModal } from './MipSuccessPracticeCheckModal';

interface Props {
  open: boolean;
  onClose(): void;
  isSubscribedToCimaRegular?: boolean;
}

export const MipCimaPracticeCheckModal: React.FC<Props> = ({ open, onClose, isSubscribedToCimaRegular }) => {
  const supervisorOptions = [
    {
      key: 1,
      text: 'ACCA',
      value: 'ACCA',
    },
    {
      key: 2,
      text: 'AAT',
      value: 'AAT',
    },
    {
      key: 3,
      text: 'AIA',
      value: 'AIA',
    },
    {
      key: 4,
      text: 'ATT',
      value: 'ATT',
    },
    {
      key: 5,
      text: 'CILEx',
      value: 'CILEx',
    },
    {
      key: 6,
      text: 'CIOT',
      value: 'CIOT',
    },
    {
      key: 7,
      text: 'CLC',
      value: 'CLC',
    },
    {
      key: 8,
      text: 'Department for the Economy Northern Ireland',
      value: 'Department for the Economy Northern Ireland',
    },
    {
      key: 9,
      text: 'Faculty of Advocates',
      value: 'Faculty of Advocates',
    },
    {
      key: 10,
      text: 'Faculty Office of the Archbishop of Canterbury',
      value: 'Faculty Office of the Archbishop of Canterbury',
    },
    {
      key: 11,
      text: 'FCA',
      value: 'FCA',
    },
    {
      key: 12,
      text: 'Gambling Commission',
      value: 'Gambling Commission',
    },
    {
      key: 13,
      text: 'General Council of the Bar',
      value: 'General Council of the Bar',
    },
    {
      key: 14,
      text: 'General Council of the Bar of Northern Ireland',
      value: 'General Council of the Bar of Northern Ireland',
    },
    {
      key: 15,
      text: 'HMRC',
      value: 'HMRC',
    },
    {
      key: 16,
      text: 'IAB',
      value: 'IAB',
    },
    {
      key: 17,
      text: 'ICAEW',
      value: 'ICAEW',
    },
    {
      key: 18,
      text: 'ICAI',
      value: 'ICAI',
    },
    {
      key: 19,
      text: 'ICAS',
      value: 'ICAS',
    },
    {
      key: 20,
      text: 'IFA',
      value: 'IFA',
    },
    {
      key: 21,
      text: 'Law Society of Northern Ireland',
      value: 'Law Society of Northern Ireland',
    },
    {
      key: 22,
      text: 'Law Society of Scotland',
      value: 'Law Society of Scotland',
    },
    {
      key: 23,
      text: 'SRA',
      value: 'SRA',
    },

    {
      key: 24,
      text: 'Other',
      value: 'Other',
    },
  ];

  const [enableButton, setEnableButton] = useState(false);
  const [inputFieldDisabled, setInputFieldDisabled] = useState(true);
  const UK_AML_INPUT_FIELD_ID = 'supervisor';
  const [showInput, setShowInput] = useState(false);
  const [confirmDisable, setConfirmDisabled] = useState(true);
  const [checkedYes, setCheckedYes] = useState(false);
  const [checkedNo, setCheckedNo] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [supervisor, setSupervisor] = useState('');
  const [supervisorDisabled, setSupervisorDisabled] = useState(true);

  const handleCheckboxClick = useCallback(() => {
    setEnableButton(!enableButton);
    setInputFieldDisabled(enableButton);
  }, [enableButton, setEnableButton]);

  const handleSupervisorChange = useCallback(
    (e: any, data: any) => {
      const value = String(data.value);
      setSupervisor(value);
      if (value === 'Other') {
        setSupervisorDisabled(false);
        setConfirmDisabled(true);
      } else {
        setSupervisorDisabled(true);
        setConfirmDisabled(false);
      }
    },
    [setSupervisor, setConfirmDisabled]
  );

  const handleOtherSupervisor = useCallback(
    (e: any) => {
      const value = e.target.value;
      if (value.length > 0) {
        setConfirmDisabled(false);
      }
    },
    [setConfirmDisabled]
  );

  const onClickConfirmation = () => {
    if (checkedYes) {
      setShowDownload(true);
    } else {
      setShowSuccess(true);
    }
  };

  const showDisplay = () => {
    setConfirmDisabled(true);
    setCheckedYes(false);
    setShowInput(true);
    setCheckedNo(true);
  };
  const hideInput = () => {
    setCheckedYes(true);
    setConfirmDisabled(false);
    setShowInput(false);
    setCheckedNo(false);
  };
  const closeShowDownload = () => {
    onClose();
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      heading="Please confirm if you intend to practice with CIMA."
      testId="confirmation-details-modal"
      showCloseCross
      cancelNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          testId="close-practice-confirmation"
          onClick={onClose}
        >
          Cancel
        </Button>
      }
      confirmNode={
        <Button
          size={ButtonEnums.sizes.small}
          testId="confirm-practice"
          variant={ButtonEnums.variants.primary}
          onClick={onClickConfirmation}
          disabled={confirmDisable}
        >
          Confirm
        </Button>
      }
    >
      <RadioContainer>
        <RadioOptionYes checked={checkedYes} label="Yes" value="Yes" onClick={hideInput} test-id="yes-radio" />
        <RadioOption checked={checkedNo} label="No" value="No" onClick={showDisplay} test-id="no-radio" />
      </RadioContainer>
      {showDownload &&
        (isSubscribedToCimaRegular ? (
          <MipDownloadApplicationModal open={showDownload} onClose={closeShowDownload} />
        ) : (
          <NotEligibleMembershipTypeModal onClose={closeShowDownload} />
        ))}
      {showInput && (
        <>
          <NoteContainer>
            <p>
              {' '}
              <StyledCheckbox
                id="consent"
                testId="consent"
                checked={enableButton}
                onChange={handleCheckboxClick}
                label=""
                width={18}
                height={18}
              />{' '}
              I declare that I do not intend to practice under the auspices of CIMA and will not represent myself as a
              CIMA Member in Practice in any way.
            </p>

            <StyledNotice>
              CIMA does not have reciprocal practising certificate arrangements with other accountancy bodies. If you
              are a practising certificate holder with another professional body and also wish to promote your services
              as a CIMA Member in Practice (MiP), you need to complete the CIMA Member in Practice application process.
            </StyledNotice>

            <StyledNotice>
              If you prefer to practice via the licensing arrangement of another body only, then you must relinquish any
              CIMA MiP status and remove any reference to that from your website and business stationery. Please also
              note that you are not entitled to use the CIMA logo.
            </StyledNotice>

            <StyledNotice>
              <strong>Anti-money laundering supervision:</strong> By choosing to practice under another body, you must
              also be supervised by them (or HMRC as UK default) for anti-money laundering/counter-terrorism financing
              (AML/CTF) compliance. As part of CIMA's AML/CTF responsibility, it does conduct checks to verify AML/CTF
              supervision of its members with other bodies.
            </StyledNotice>

            <StyledNotice>
              Your decision not to practice under the auspices of CIMA does not affect your overall CIMA qualification,
              status or privileges and does not affect your entitlement to use your designatory letters.
            </StyledNotice>
          </NoteContainer>

          <Grid stackable centered columns={3}>
            <StyledGridRow>
              <Grid.Column width={14}>
                <StyledDropdown
                  scrolling
                  placeholder="Select a supervisor"
                  testId="select-supervisor"
                  name={UK_AML_INPUT_FIELD_ID}
                  title="Please select your AML supervisory body from the below list."
                  options={supervisorOptions}
                  disabled={inputFieldDisabled}
                  onChange={handleSupervisorChange}
                  value={supervisor}
                />
              </Grid.Column>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column width={14}>
                <StyledSpan>
                  If your AML supervisory body is not shown in the dropdown list above, please specify below.
                </StyledSpan>
                <StyledTextArea
                  placeholder="Enter your Text Here"
                  onChange={handleOtherSupervisor}
                  disabled={supervisorDisabled}
                />
              </Grid.Column>
            </StyledGridRow>
          </Grid>
        </>
      )}
      {showSuccess && <MipSuccessPracticeCheckModal open={showSuccess} onClose={onClose} />}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &&&& > button {
    position: absolute !important;
    top: ${props => props.theme.pxToRem(16)}!important;
    right: ${props => props.theme.pxToRem(16)}!important;
  }

  &&&& {
    width: ${props => props.theme.pxToRem(590)};
    background: ${props => props.theme.colors.neutralWhite};
    margin-top: ${props => props.theme.pxToRem(48)};
    border-radius: ${props => props.theme.pxToRem(2)};
    padding: ${props => props.theme.pxToRem(37)} 0 ${props => props.theme.pxToRem(20)}!important;
    box-sizing: border-box;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    > .content {
      margin: 0 !important;
      text-align: center;
      box-sizing: border-box;
      padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(74)}
        ${props => props.theme.pxToRem(30)}!important;
    }

    > .header {
      margin: 0 ${props => props.theme.pxToRem(74)};
      text-align: center;
      display: table;
      h2 {
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }

    > .actions {
      margin: 0 ${props => props.theme.pxToRem(20)};
    }
  }

  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const RadioContainer = styled.p<ThemedStyledProps<{ isRegularFont?: boolean | void }, DefaultTheme>>`
  margin-top: ${props => props.theme.pxToRem(26)};
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const RadioOptionYes = styled(RadioOption)`
  margin-right: ${props => props.theme.pxToRem(36)};
`;

const StyledCheckbox = styled(Checkbox)`
  &.ui.checkbox input:checked ~ label:after {
    background-size: contain;
    background-position: center;
    background-color: ${props => props.theme.colors.primaryPurple};
    border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralBlack};
    border-radius: ${props => props.theme.pxToRem(2)};
  }
  margin-right: ${props => props.theme.pxToRem(5)};
`;

const NoteContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${props => props.theme.colors.neutralGrey6};

  > p:first-child {
    margin: ${props => props.theme.pxToRem(25)} 0;
    font-size: ${props => props.theme.fontSizes.xs};
    text-align: left !important;
    display: flex;
    align-items: flex-start;
    gap: ${props => props.theme.pxToRem(10)};
  }
  > p:last-child {
    margin-bottom: ${props => props.theme.pxToRem(50)};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding: 0 0 ${props => props.theme.pxToRem(10)} 0;
  }
`;

const StyledDropdown = styled(DropdownWithLabel)`
  &&&.ui.search.dropdown {
    border-radius: ${props => props.theme.pxToRem(4)};
  }
  &&&.ui.dropdown .menu {
    height: auto;
  }
  &&&.ui.search.dropdown > input.search {
    cursor: pointer;
  }
  &&&.ui.default.dropdown:not(.button) > .text,
  &&&.ui.dropdown:not(.button) > .default.text,
  &&&.ui.search.dropdown > .text,
  &&&.ui.dropdown .menu > .item {
    color: ${props => props.theme.colors.primaryPurple};
    font-weight: ${props => props.theme.fontWeights.regular};
  }
  &&&.ui.dropdown .menu > * {
    white-space: normal;
  }
  &&&.disabled {
    &&&.ui.dropdown {
      border-color: ${props => props.theme.colors.neutralGrey8};
      background-color: ${props => props.theme.colors.neutralGrey3};
    }
    &&&.ui.dropdown .menu > .item {
      color: ${props => props.theme.colors.neutralGrey8};
    }
  }
`;

const StyledSpan = styled.label`
  font-weight: ${props => props.theme.fontWeights.regular};
  font-size: ${props => props.theme.pxToRem(16)};
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
    height: ${props => props.theme.pxToRem(150)};
    max-width: 100%;
    min-width: 100%;
    border-color: ${props => props.theme.colors.neutralGrey4};
    font-size: ${props => props.theme.fontSizes.s};
    color: ${props => props.theme.colors.neutralGrey8};
    padding: ${props => props.theme.pxToRem(12)};
    border-radius: ${props => props.theme.pxToRem(4)};
  }

  &&&&&&:focus,
  &&&&&&:active {
    outline: none;
    border-color: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledNotice = styled.p`
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.neutralGrey5};
  text-align: left !important;
`;
