import * as Sentry from '@sentry/browser';
import { handleActions } from 'redux-actions';
import { Product } from 'mxp-schemas';
import { initialState } from './constants';
import { submitInquiryForm, loading, fetchProductSearchResults, searchLoading, updateSearchSelection } from './actions';

if ([submitInquiryForm, loading].includes(undefined)) {
  const message: string = 'inquiryForm module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [submitInquiryForm]: (state: State.InquiryForm, action: any): State.InquiryForm => ({
    ...state,
    error: !action.payload.success,
    loading: false,
    success: action.payload.success,
    errorMessage: action.payload.errorMessage,
  }),
  [loading]: (state: State.InquiryForm): State.InquiryForm => ({
    ...state,
    loading: !state.loading,
  }),
  [fetchProductSearchResults]: (state: State.InquiryForm, action: any): State.InquiryForm => ({
    ...state,
    searchResults: action.payload.map((item: any) => ({
      title: item.title,
      slug: item.slug,
      id: item.id,
      description: Product.PRODUCT_TYPES_NAMES[item.productType],
    })),
    searchLoading: false,
  }),
  [searchLoading]: (state: State.InquiryForm): State.InquiryForm => ({
    ...state,
    searchLoading: !state.searchLoading,
  }),
  [updateSearchSelection]: (state: State.InquiryForm, action: any): State.InquiryForm => ({
    ...state,
    searchSelection: action.payload,
  }),
};

export { submitInquiryForm, loading };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
