import { createSelector } from 'reselect';

const rootSelector = createSelector(
  (state: State.Root) => state.tuitionProvider,
  (tuitionProvider: State.TuitionProvider): State.TuitionProvider => tuitionProvider
);

export const studentDetailsSelector = createSelector(
  rootSelector,
  (tuitionProvider: State.TuitionProvider): State.StudentDetails => tuitionProvider.getStudentDetails
);

export const isLoadingSelector = createSelector(
  rootSelector,
  (tuitionProvider: State.TuitionProvider): boolean => tuitionProvider.loading
);

export const isUpdateLoadingSelector = createSelector(
  rootSelector,
  (tuitionProvider: State.TuitionProvider): boolean => tuitionProvider.updateLoading
);
