// ------------------------------------
// Constants
// ------------------------------------
export const initialState: State.ExemptionProfessional = {
  exempReference: [],
  exemptReferenceFetched: false,
  exemptReferenceFrontloadRequest: false,
  selectedPassedUSCPAExam: '',
  allProfessionalExperienceReference: [],
};

export const gecCountriesList: any = [
  { country: 'Africa', gecEmail: 'Exemptions.Africa@aicpa-cima.com' },
  { country: 'North Asia', gecEmail: 'Exemptions.NorthAsia@aicpa-cima.com' },
  { country: 'United Kingdom', gecEmail: 'Student.Admissions@aicpa-cima.com' },
  { country: 'America', gecEmail: 'Student.Admissions@aicpa-cima.com' },
  { country: 'Europe', gecEmail: 'Student.Admissions@aicpa-cima.com' },
  { country: 'APAC', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Afghanistan', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Australia', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Bangladesh', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Bhutan', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Brunei Darussalam', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Cambodia', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'China', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Hong Kong SAR, China', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Macao SAR, China', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Cook Islands', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Fiji', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'India', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Indonesia', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Japan', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Kiribati', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: `"Lao People's Democratic Republic"`, gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Malaysia', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Maldives', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Marshall Islands', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Micronesia (Federated States of)', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Mongolia', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Myanmar', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Nauru', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Nepal', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'New Zealand', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Pakistan', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Palau', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Papua New Guinea', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Philippines', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Korea, Republic of', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'American Samoa', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Singapore', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Solomon Islands', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Sri Lanka', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Thailand', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Tonga', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Tuvalu', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Vanuatu', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
  { country: 'Viet Nam', gecEmail: 'APAC.Exemptions@aicpa-cima.com' },
];

export enum USCpaExamEnum {
  NOT_PASSED_US_CPA_EXAM = `No, I'm not`,
  YES_NOT_YET_LICENSED = 'Yes, and I am not yet licensed',
  YES_LICENSED_BEFORE = 'Yes, and I have been licensed before',
}

export enum RecordTypePage {
  PROFESSIONAL_QUALIFICATION = `professionalQualification`,
  EDUCATIONAL_QUALIFICATION = `educationalQualification`,
}
