// Each table pagination and controls meta stored to redux separately
export enum CenterAdminTableType {
  CENTER_MEMBERSHIP_INVOICES = 'centerMembershipInvoices',
  MANAGE_CENTER_MEMBERSHIP_INVOICES = 'manageCentermembershipInvoices',
}

export const TableHeaders: {
  [key in CenterAdminTableType]: CenterAdmin.DataTableHeader[];
} = {
  [CenterAdminTableType.MANAGE_CENTER_MEMBERSHIP_INVOICES]: [
    { title: 'Name' },
    { title: 'Account ID' },
    { title: 'Type' },
    { title: 'Email address' },
    { title: 'Position' },
    { title: 'Country' },
    { title: 'State' },
    { title: 'City' },
    { title: 'Office location' },
  ],
  [CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES]: [
    { title: 'Invoice Date' },
    { title: 'Total Price' },
    { title: 'Invoice #' },
    { title: 'Status' },
    { title: 'Due Date' },
    { title: '' },
    { title: ' ' },
  ],
};

export interface AddonCardData {
  title: string;
  priceFormatted: string;
  centPrice: number;
  channelId: string;
  expiryDate: string;

  productId: string;
  variantSku: string;
  addonType: AddonCardType;

  isActive: boolean; // true if subscription is provisioned. Either invoiced or already paid.
  isPaid: boolean; // true if invoice paid by the firm OR the member. Makes sense only for isActive===true.
  isIncludedInFirmBilling: boolean; // true if invoice paid by the firm. Makes sense only for isPaid===true.

  requestedAddToQuote: boolean; // true if should be included in the quote. If isActive===true buying renewal, otherwise (isActive===false) buying new subscription.
  requestedRemoveFromFirmBilling: boolean; // true if remove from firm billing requested
  requestedAddToFirmBilling: boolean; // true if remove from firm billing requested
}

export type AddonCardType = 'add-on' | 'section' | 'credential' | 'benefit';

export enum Stage {
  Confirmation,
  ChangesSuccess,
  RemovalSuccess,
  Fail,
}

export enum ActionResult {
  Success,
  Fail,
}
