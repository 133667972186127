import { InquiryFormActionNames } from 'modules/inquiryForm/constants';
import { EventDescribor } from 'utils/Analytics/types';

export const InquiryFormAnalyticsConsts: {
  [key: string]: EventDescribor;
} = {
  [InquiryFormActionNames.SUBMIT_INQUIRY_FORM]: {
    name: `event_click`,
    accessors: [
      {
        name: 'click',
        path: '',
        processor: (a: any, b: State.Root) => {
          const path = b?.router?.location?.pathname?.replace(/\//g, ':') || '';
          const success = b?.inquiryForm?.success;
          return `button:${path}:int:form-submit-${!success ? 'un' : ''}successful`;
        },
      },
    ],
  },
  [InquiryFormActionNames.INQUIRY_FORM_START]: {
    name: `event_click`,
    accessors: [
      {
        name: 'click',
        path: '',
        processor: (a: any, b: State.Root) => {
          const path = b?.router?.location?.pathname?.replace(/\//g, ':') || '';
          return `button:${path}:int:form-start`;
        },
      },
    ],
  },
  [InquiryFormActionNames.INQUIRY_FORM_COMPLETE]: {
    name: `event_click`,
    accessors: [
      {
        name: 'click',
        path: '',
        processor: (a: string, b: State.Root) => {
          const path = b?.router?.location?.pathname?.replace(/\//g, ':') || '';
          return `button:${path}:int:form-complete-${a}`;
        },
      },
    ],
  },
};
