import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components/macro';
import {
  Button as StyledButton,
  ButtonEnums,
  Divider,
  Modal,
  OnlyDesktopCSS,
  OnlyMobileCSS,
  ButtonVariants,
  Link,
  LinkEnums,
} from 'components/atoms';

import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { MembershipTypes } from 'mxp-schemas';
import { isAuthSelector } from 'modules/user/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { useHistory } from 'react-router';
import { setIsCredentialsJourney, setMembershipEvent } from 'modules/membership';

interface ModalProps {
  ethicsStatusSelector: MembershipTypes.EthicsStatusEnum;
  hasFirmBillingInvite: boolean;
  isCima?: boolean;
  isUserMember?: boolean;
  currentJourney?: string | undefined;
}

export const ModalEthicsAndFirmBillingInvite: React.FC<ModalProps> = ({
  ethicsStatusSelector,
  hasFirmBillingInvite,
  isCima,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isAdmin = useSelector(isAdminPortalSelector);
  const isAuth = useSelector(isAuthSelector);
  const isImpersonated = Boolean(isAuth) && isAdmin;

  const hasEthicsCase = [
    MembershipTypes.EthicsStatusEnum.TERMINATED,
    MembershipTypes.EthicsStatusEnum.REMEDIATION,
    MembershipTypes.EthicsStatusEnum.SANCTIONED,
    MembershipTypes.EthicsStatusEnum.UNDER_INVESTIGATION,
    MembershipTypes.EthicsStatusEnum.SUSPENDED,
  ].includes(ethicsStatusSelector);

  const toggleSectionsCredentialsJourney = () => {
    dispatch(setIsCredentialsJourney(false));
    dispatch(setMembershipEvent('isClickedSectionsJourney', false));
  };
  const goToPreviousPage = () => {
    history.goBack();
    toggleSectionsCredentialsJourney();
  };

  const onModalCloseBtnClick = () => {
    toggleSectionsCredentialsJourney();
    if (isImpersonated || hasEthicsCase) {
      // TODO: Change Association global homepage (prop page?)
      history.push(getPath(Routes.ROOT));
    } else {
      // TODO: change to CIMA prop page route
      history.push(getPath(Routes.FEED));
    }
  };

  const onUnderstandButtonClicked = () => {
    // TODO: Change Association global homepage (prop page?)
    history.push(getPath(Routes.ROOT));
  };

  const modalHeading = hasEthicsCase
    ? 'It appears that you are unable to proceed'
    : hasFirmBillingInvite
    ? 'Pending Invite from Sponsoring Organization'
    : 'Something has gone wrong';
  // tslint:disable-next-line: no-shadowed-variable

  const renderModalBody = () =>
    hasEthicsCase
      ? renderEthicsError()
      : hasFirmBillingInvite
      ? renderPendingInviteError(!isCima)
      : renderGenericError();

  return (
    <>
      <StyledModal size="tiny" heading={modalHeading} open={hasEthicsCase || hasFirmBillingInvite}>
        <CloseButton>
          <StyledCloseIcon onClick={isCima ? onModalCloseBtnClick : goToPreviousPage} />
        </CloseButton>
        <Spacer />
        {renderModalBody()}
        <DividerWithBottomMargin />
        <CenteredButtons>
          <DesktopStyled>
            <UnderstandButton onClick={isCima ? onUnderstandButtonClicked : goToPreviousPage} isCima={isCima} />
          </DesktopStyled>
          <MobileStyled>
            <UnderstandButton onClick={isCima ? onUnderstandButtonClicked : goToPreviousPage} />
          </MobileStyled>
        </CenteredButtons>
      </StyledModal>
    </>
  );
};

const renderPendingInviteError = (isAicpa?: boolean) => (
  <CenteredText data-testid="pending-invite-modal">
    You cannot proceed with this purchase as you have an invite pending from{' '}
    {isAicpa
      ? 'your employer to sign up for AICPA Membership'
      : 'a sponsoring organization to sign up for the CGMA Professional Qualification'}
    . Please check your email inbox for this invite and sign up using the link within.
    <br />
    <br />
    {isAicpa
      ? "If you have any questions, please speak with your organization's Admin or contact us for assistance"
      : 'If you wish to decline the invitation from your sponsoring organization, please speak with your designated organization Admin or contact us to be removed from the Organization Billing Roster.'}
    {' Contact us '}
    <Link type={LinkEnums.type.standaloneLink} isExternal to={getPath(Routes.CONTACT_US)} testId="ei-question-us-phone">
      here
    </Link>
  </CenteredText>
);

const renderEthicsError = () => (
  <CenteredText data-testid="ethics-modal">
    <p>
      Before you can complete the sign-up, there are open questions about the status of your account that may need to be
      addressed.
    </p>
    <p>
      {'Please contact us '}
      <Link
        type={LinkEnums.type.standaloneLink}
        isExternal
        to={getPath(Routes.CONTACT_US)}
        testId="ei-question-us-phone"
      >
        here
      </Link>
      {' for more information.'}
    </p>
  </CenteredText>
);

const renderGenericError = () => (
  <CenteredText data-testid="generic-error-modal">
    <p>There has been an error that is preventing you from proceeding with sign-up.</p>
    <p>
      {'Please '}
      <Link
        type={LinkEnums.type.standaloneLink}
        isExternal
        to={getPath(Routes.CONTACT_US)}
        testId="ei-question-us-phone"
      >
        contact us
      </Link>
      {' so we can assist with troubleshooting the error. We apologize for the inconvenience.'}
    </p>
  </CenteredText>
);

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;
const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

interface UnderstandButtonProps {
  onClick: () => void;
  variant?: ButtonVariants;
  isCima?: boolean;
}

const UnderstandButton: React.FC<UnderstandButtonProps> = ({ onClick, isCima = false }) => (
  <StyledUnderstandButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId="understand-ethics-modal"
  >
    {isCima ? 'Got it!' : 'I understand'}
  </StyledUnderstandButton>
);

const StyledUnderstandButton = styled(StyledButton)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
