import React from 'react';
import styled from 'styled-components';
import { generatePath } from 'react-router-dom';
import { Routes } from 'constants/index';
import { Link } from 'components/atoms/Link/Link';
import { CollapsableLinkList } from 'components/atoms/CollapsableLinkList/CollapsableLinkList';
import { getPath } from 'utils';
import { getShortcutLabel } from 'utils/getShortcutLabel';

interface Props {
  items: State.ShortcutDetail[];
  firstItemsCount?: number;
  setBurger?: (state: boolean) => void;
}

enum shortCutLinkTypes {
  Topic = 'topic',
  Skill = 'skill',
  Industry = 'industry',
  Trend = 'trend',
}

export const Shortcuts: React.FC<Props> = React.memo(({ items, firstItemsCount = 3, setBurger }) => {
  const closeMobileMenu = React.useCallback(() => {
    if (setBurger) setBurger(false);
  }, [setBurger]);

  const shortcutArray = items
    .map(item => {
      return item;
    })
    .filter(item => item);

  const renderItem = (item: State.ShortcutDetail) => {
    const isToolkitShortcut = item.subtopicSlug && item.subtopicSlug.indexOf('$TOOLKIT$') > -1;
    const isStaticLandingPageShortcut = item.subtopicSlug && item.subtopicSlug.indexOf('$LANDING$') > -1;

    const to: string = !isToolkitShortcut
      ? !isStaticLandingPageShortcut
        ? item.categorySlug
          ? generatePath(getPath(Routes.CATEGORY_AGGS_PAGE_CIMA), {
              topicSlug: item.topicSlug || undefined,
              subtopicSlug: item.subtopicSlug || undefined,
              categorySlug: item.categorySlug || undefined,
            })
          : item.linkType === shortCutLinkTypes.Skill
          ? generatePath(getPath(Routes.SKILL_AGGS_PAGE), {
              skillSlug: item.topicSlug,
              subskillSlug: item.subtopicSlug || undefined,
            })
          : item.linkType === shortCutLinkTypes.Industry
          ? generatePath(getPath(Routes.INDUSTRY_AGGS_PAGE), {
              industrySlug: item.topicSlug,
            })
          : item.linkType === shortCutLinkTypes.Trend
          ? generatePath(getPath(Routes.TREND_AGGS_PAGE), {
              trendSlug: item.topicSlug,
            })
          : generatePath(getPath(Routes.TOPIC_AGGS_PAGE), {
              topicSlug: item.topicSlug,
              subtopicSlug: item.subtopicSlug || undefined,
            })
        : `/${item.subtopicSlug && item.subtopicSlug.replace('$LANDING$', 'landing')}`
      : // TODO BAD_SHORTCUTS this is a hack don't do this at home kids!
        // we should have a universal shortcut system, not this
        `/${item.subtopicSlug && item.subtopicSlug.replace('$TOOLKIT$', 'toolkit')}`;

    const subtopicName: string = isToolkitShortcut || isStaticLandingPageShortcut ? item.topicSlug : item.subtopicName;

    return (
      <StyledListItem key={`${item.categorySlug || ''} ${item.topicSlug || ''} ${item.subtopicSlug || ''}`}>
        <StyledLink to={item?.slicedAggsFilter ? `${to}?${item?.slicedAggsFilter}` : to} onClick={closeMobileMenu}>
          {getShortcutLabel({
            categorySlug: item.categorySlug,
            categoryName: item.categoryName,
            topicName: item.topicName,
            subtopicName,
          })}
        </StyledLink>
      </StyledListItem>
    );
  };

  return (
    <CollapsableLinkList
      title="My Shortcuts"
      renderItem={renderItem}
      items={shortcutArray}
      firstItemsCount={firstItemsCount}
      expandedText="Hide shortcuts"
      collapsedText="View all shortcuts"
    />
  );
});

const StyledListItem = styled.li`
  margin-bottom: 0.625rem;
`;

const StyledLink = styled(Link)`
  display: block;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-height: 2.25rem;
  font-size: ${props => props.theme.fontSizes.xs};
  -webkit-tap-highlight-color: transparent;
`;
