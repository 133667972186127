import React from 'react';
import styled from 'styled-components';
import { ExternalSourceLabel, DatePublished, Link, LinkEnums } from 'components/atoms';
import { Grid } from 'semantic-ui-react';

interface LongLinkProps {
  to: string;
  source?: string;
  children: string;
  date?: string;
  onClick?: () => void;
}

export const LongLink: React.FC<LongLinkProps> = ({ children, date, to, source, onClick }) => {
  const isExternal = to.startsWith('http');
  return (
    <StyledLink to={to} isExternal={isExternal} onClick={onClick} type={LinkEnums.type.standaloneLink}>
      <Grid columns={3} verticalAlign="middle" style={{ padding: '12px 12px' }}>
        <Grid.Column width={11}>{children}</Grid.Column>
        <Grid.Column width={3}>{date && <DatePublished date={date} />}</Grid.Column>
        <Grid.Column width={2}>{isExternal && source && <ExternalSourceLabel label={source} />}</Grid.Column>
      </Grid>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  display: block;
  cursor: pointer;
  color: ${props => props.theme.colors.neutralGrey8};
  border-radius: 4px;
  padding: 0.5rem;

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.primaryFadedPurple};
    color: ${props => props.theme.colors.primaryDarkPurple};
    font-weight: ${props => props.theme.fontWeights.medium};
    text-decoration: none;

    div {
      color: ${props => props.theme.colors.primaryDarkPurple};
      font-weight: ${props => props.theme.fontWeights.medium};
    }

    .icon {
      fill: ${props => props.theme.colors.primaryPurple};
    }

    small {
      color: ${props => props.theme.colors.primaryDarkPurple};
    }
  }

  &:focus {
    outline: none;
  }
`;
