import { connect } from 'react-redux';
import { FeatureToggleWrapper } from 'components/organisms/FeatureToggleWrapper/FeatureToggleWrapper';
import { getFeatureToggleByKeySelector, toggleDashboardSelector } from 'modules/featureToggle/selectors';
import { FEATURE_TOGGLE_DASHBOARD } from 'modules/featureToggle/constants';

const mapStateToProps = (state: State.Root, { toggle, showWhenOff }: { toggle: string; showWhenOff?: boolean }) => {
  const featureFlagStatus: boolean = getFeatureToggleByKeySelector(state, toggle);
  const show = (featureFlagStatus && !showWhenOff) || (!featureFlagStatus && showWhenOff);
  if (toggle === FEATURE_TOGGLE_DASHBOARD && toggleDashboardSelector(state)) return { show: true };
  return {
    show,
  };
};

export const FeatureToggleWrapperContainer = connect(mapStateToProps)(FeatureToggleWrapper);
