import { MomentHelpers } from './MomentHelpers';

export const getDefaultCard = (cards: State.CreditCard[]): State.CreditCard | null =>
  cards.find(card => card.defaultPaymentMethod) || null;
export const getCardById = (cards: State.CreditCard[], id: string | null): State.CreditCard | null =>
  cards.find(card => card.id === id) || null;
export const getDefaultCardOrAny = (cards: State.CreditCard[]): State.CreditCard | null => {
  return getDefaultCard(cards) || cards[0];
};
export const cardsCompare = (card1: State.CreditCard, card2: State.CreditCard): number =>
  card1.defaultPaymentMethod
    ? -1
    : card2.defaultPaymentMethod
    ? 1
    : MomentHelpers.isCardExpired(card1)
    ? 1
    : MomentHelpers.isCardExpired(card2)
    ? -1
    : 0;
