import React from 'react';
import { useSelector } from 'react-redux';
import { User as UserUtils } from 'mxp-utils';
import { User } from 'mxp-schemas';
import styled from 'styled-components';
import { ButtonEnums, ButtonLink, Divider, FeedTargetableLink } from 'components/atoms';
import { LockIconWrapper, LockIconWrapperSizes } from 'components/molecules/LockIconWrapper/LockIconWrapper';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';
import { accessRestrictionsHashSelector } from 'modules/startup/selectors';

interface ContentSuppressorProps {
  contentRoles?: string[];
  isContentExclusive: Common.ContentExclusive;
  isAuth: boolean | null;
  notifications?: State.PaywallNotifications;
  toggleLoginReload: (shouldReload: boolean) => void;
}

export const ContentSuppressor: React.FC<ContentSuppressorProps> = React.memo(
  ({ contentRoles = [], isContentExclusive, isAuth = false, notifications, toggleLoginReload }) => {
    const accessRestrictionsHash = useSelector(accessRestrictionsHashSelector);

    const aicpaMemberRole: UserUtils.SuppressContentMembership =
      UserUtils.contentSuppressMembershipMap[User.MembershipIdsEnum.MRUSR0001];

    const getAICPAMemberRoleLink = () => {
      return accessRestrictionsHash[User.MembershipIdsEnum.MRUSR0001]?.propPageLink;
    };

    const transformedRoleList = contentRoles.reduce((acc: UserUtils.SuppressContentMembership[], role: string) => {
      const transformedRole = UserUtils.contentSuppressMembershipMap[role];
      if (transformedRole) {
        transformedRole.link = accessRestrictionsHash[role]?.propPageLink;
        const roleNotification: State.PaywallNotification | undefined = notifications && notifications[role];
        if (roleNotification && roleNotification.learnMoreLink) {
          transformedRole.link = roleNotification.learnMoreLink;
        }
        if (!acc.some(addedRole => addedRole.title === transformedRole.title)) {
          acc.push(transformedRole);
        }
      }
      return acc;
    }, []);

    enum ConnectorType {
      and = 'and',
      or = 'or',
    }

    const titleSelector = (title: string, index: number, arrLength: number, type: ConnectorType) => {
      if (index === 0) {
        return title;
      }
      if (index === arrLength - 1) {
        return ` ${type} ${title}`;
      }
      if (index < arrLength) {
        return `, ${title}`;
      }
    };

    const renderTitle1 = () => {
      if (isContentExclusive.isRegisteredExclusive) {
        return [aicpaMemberRole.title1];
      }
      return transformedRoleList
        .slice(0, 3)
        .map((role, index, arr) => titleSelector(role.title1, index, arr.length, ConnectorType.and));
    };

    const renderTitle2 = () => {
      if (isContentExclusive.isRegisteredExclusive) {
        return [aicpaMemberRole.title2];
      }
      return transformedRoleList
        .slice(0, 3)
        .map((role, index, arr) => titleSelector(role.title2, index, arr.length, ConnectorType.or));
    };

    const renderDiscoverMore = () => {
      if (isContentExclusive.isRegisteredExclusive || isContentExclusive.isMemberExclusive) {
        return aicpaMemberRole.title3;
      }
      const newList: UserUtils.SuppressContentMembership[] = [aicpaMemberRole].concat(transformedRoleList).slice(0, 4);
      return newList.map((role, index) => {
        if (newList.length === 2) {
          return index === 0 ? `${role.title3}` : ` and ${role.title3}`;
        }
        return index === 0 ? `${role.title3}` : index === 1 || index === 2 ? `, ${role.title3}` : ` and ${role.title3}`;
      });
    };

    const handleToggleLoginReload = () => {
      toggleLoginReload(true);
    };

    const renderLearnMoreList = () => {
      if (isContentExclusive.isRegisteredExclusive || isContentExclusive.isMemberExclusive) {
        return (
          <FeedTargetableLink
            key="AICPA-membership-learn-more"
            title="Learn more"
            link={getAICPAMemberRoleLink()}
            testId="AICPA-membership-learn-more"
          />
        );
      }
      const newList: UserUtils.SuppressContentMembership[] = [aicpaMemberRole];
      return newList
        .concat(transformedRoleList)
        .slice(0, 4)
        .map((item, index) => {
          const hideNumbers: boolean = index > 1;
          return (
            <StyledListItem key={item.title3}>
              <StyledListContainer>
                <StyledCount hideNumbers={hideNumbers}>{!hideNumbers && `${index + 1}.`}</StyledCount>
                <StyledListTitle>
                  {hideNumbers && <StyledOr>OR </StyledOr>}
                  {item.title3}
                </StyledListTitle>
              </StyledListContainer>
              {item.link && <FeedTargetableLink title="Learn more" link={item.link} testId={item.title3} />}
            </StyledListItem>
          );
        });
    };

    return (
      <StyledWrapper>
        <StyledHeader>
          <LockIconWrapper size={LockIconWrapperSizes.small} />
          <StyledHeaderTitle> RESERVED FOR {renderTitle1()}</StyledHeaderTitle>
        </StyledHeader>
        <StyledTitle>
          This content is exclusive to <span>{renderTitle1()}.</span>
        </StyledTitle>
        {!isAuth && (
          <>
            <StyledAlreadyMember>Already a {renderTitle2()}?</StyledAlreadyMember>
            <StyledLoginText>Log in with your AICPA account.</StyledLoginText>
            <StyledLoginButtonLink
              testId="login-button"
              variant={ButtonEnums.variants.primary}
              size={ButtonEnums.sizes.medium}
              to={getPath(Routes.LOGIN_HASH)}
              bordered
              onClick={handleToggleLoginReload}
            >
              Log in
            </StyledLoginButtonLink>
          </>
        )}
        <StyledDivider />
        {!isAuth && <StyledNotMember>Not a {renderTitle2()}?</StyledNotMember>}
        <StyledDiscoverText>Discover the benefits of {renderDiscoverMore()}.</StyledDiscoverText>
        {renderLearnMoreList()}
      </StyledWrapper>
    );
  }
);

const StyledWrapper = styled.div`
  margin-bottom: 10%;
`;

const StyledHeader = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
  color: ${props => props.theme.colors.neutralGrey5};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  text-transform: uppercase;
`;

const StyledTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(24)};

  & span {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAlreadyMember = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(8)};
`;

const StyledLoginText = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(24)};
`;

const StyledLoginButtonLink = styled(ButtonLink)`
  &&&& {
    min-width: ${props => props.theme.pxToRem(170)};
    margin-bottom: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledDivider = styled(Divider)`
  width: 100%;
  &&& {
    margin: ${props => props.theme.pxToRem(22)} 0;
  }
`;

const StyledNotMember = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-top: ${props => props.theme.pxToRem(16)};
  margin-bottom: ${props => props.theme.pxToRem(8)};
`;

const StyledDiscoverText = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(16)};
`;
const StyledListItem = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.57;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const StyledCount = styled.span<{ hideNumbers: boolean }>`
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey4};
  margin-right: ${props => props.theme.pxToRem(5)};
  min-width: ${props => props.theme.pxToRem(12)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    display: ${props => (props.hideNumbers ? 'none' : 'inline-block')};
  }
`;
const StyledListTitle = styled.span`
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-right: ${props => props.theme.pxToRem(14)};
  flex: 1;
`;

const StyledListContainer = styled.div`
  display: flex;
`;

const StyledOr = styled.span`
  font-weight: ${props => props.theme.fontWeights.medium};
`;
