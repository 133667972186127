import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Product } from 'mxp-schemas';
import { productCurrencySelector, hasExistingZuoraPurchaseSelector } from 'modules/products/selectors';
import { updatePreferredCurrency } from 'modules/products/actions';
import { hasPreferredCurrencySelector } from 'modules/user/selectors';
import { cartDetailItemsSelector } from 'modules/cart/selectors';
import { removeCartItems } from 'modules/cart';
import {
  AicpaLogo,
  LabeledIcon,
  OnlyDesktopCSS,
  OnlyMobileCSS,
  Link,
  LinkEnums,
  CimaCurrencySelectionModal,
  Dropdown,
} from 'components/atoms';
import { IC_MENU, IC_SEARCH, SVG_FILTERS, IC_PUBLIC } from 'resources/images';
import { FeatureToggleWrapperContainer as FeatureToggleWrapper } from 'containers/FeatureToggleWrapperContainer';
import { Routes } from 'constants/index';
import { HEADER_EXTERNAL_LINK, HEADER_EVENT, handleEvent, PREFIXES } from 'utils/Analytics';
import { FEATURE_TOGGLE_DASHBOARD } from 'modules/featureToggle/constants';
import { getPath } from 'utils/index';
import { ArrowBackSVG } from 'components/atoms/svg';
import { theme } from 'theme';
import { ReactComponent as FilterDefs } from 'resources/images/filter-defs.svg';
import { ReactComponent as Help } from 'resources/images/icon-dev-ic-help.svg';
import { ReactComponent as ShoppingCart } from 'resources/images/icon-dev-ic-shopping-cart.svg';
import IconClose from 'resources/images/ic-close.svg';

interface Props {
  currentPath?: string;
  isClientAdmin: boolean;
  navigate: (path: string) => void;
  setBurger: (expanded: boolean) => void;
  setSearchModal: (expanded: boolean) => void;
  setUserSettingsExpanded: (expanded: boolean) => void;
  burgerExpanded: boolean;
  closeUserSettingsExpanded: () => void;
  closeAcrossLinksExpanded: () => void;
  closeOrgProfileExpanded: () => void;
  userSettingsExpanded?: boolean;
  orgProfileExpanded: boolean;
  acrossLinksExpanded: boolean;
  megaHistoryLast: string;
  backMegaHistory: () => void;
  clearMegaHistory: () => void;
  numberOfItemsInCart: number;
}

export const HeaderGlobal = React.memo(
  ({
    currentPath,
    isClientAdmin,
    navigate,
    setBurger,
    setSearchModal,
    userSettingsExpanded,
    orgProfileExpanded,
    closeUserSettingsExpanded,
    closeOrgProfileExpanded,
    burgerExpanded,
    closeAcrossLinksExpanded,
    setUserSettingsExpanded,
    acrossLinksExpanded,
    megaHistoryLast,
    backMegaHistory,
    clearMegaHistory,
    numberOfItemsInCart,
  }: Props) => {
    const toggleMainMenu = React.useCallback(() => {
      if (burgerExpanded) {
        closeUserSettingsExpanded();
        closeAcrossLinksExpanded();
        if (isClientAdmin) {
          closeOrgProfileExpanded();
        }
      }
      setBurger(!burgerExpanded);
      clearMegaHistory();
    }, [
      setBurger,
      burgerExpanded,
      clearMegaHistory,
      closeUserSettingsExpanded,
      closeAcrossLinksExpanded,
      closeOrgProfileExpanded,
      isClientAdmin,
    ]);
    const dispatch = useDispatch();
    const currentCurrency = useSelector(productCurrencySelector);
    const hasExistingZuoraPurchase = useSelector(hasExistingZuoraPurchaseSelector);
    const hasPreferredCurrency = useSelector(hasPreferredCurrencySelector);
    const cartDetailItems = useSelector(cartDetailItemsSelector);
    const hideCurrencyDrown = Boolean(hasExistingZuoraPurchase || hasPreferredCurrency);

    const currencyOptions = React.useMemo(() => Object.values(Product.PRODUCT_CURRENCY_OPTIONS), []);

    const [isCurrencySelectionModalOpen, setIsCurrencySelectionModalOpen] = React.useState(false);
    const [selectedCurrency, setSelectedCurrency] = React.useState(currentCurrency);

    const onCurrencySelectionModalClose = React.useCallback(() => {
      setIsCurrencySelectionModalOpen(!isCurrencySelectionModalOpen);
    }, [isCurrencySelectionModalOpen]);

    const onCurrencySelectionModalConfirm = React.useCallback(() => {
      if (cartDetailItems.length) {
        dispatch(removeCartItems(cartDetailItems.map(productItem => productItem.lineItemId)));
      }
      dispatch(updatePreferredCurrency(selectedCurrency, true));
      setIsCurrencySelectionModalOpen(!isCurrencySelectionModalOpen);
    }, [dispatch, isCurrencySelectionModalOpen, selectedCurrency, cartDetailItems]);

    const handleCurrencyDropdownChange = React.useCallback(
      (event: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
        const { value } = data;
        const { key } = data?.options?.find((o: { value: string }) => o.value === value);

        setSelectedCurrency({ label: value, sign: key });
        setIsCurrencySelectionModalOpen(!isCurrencySelectionModalOpen);
      },
      [setSelectedCurrency, isCurrencySelectionModalOpen]
    );

    const goToHome = React.useCallback(() => {
      if (burgerExpanded) {
        toggleMainMenu();
      }
      navigate(getPath(Routes.FEED));
    }, [navigate, toggleMainMenu, burgerExpanded]);

    const open = React.useCallback(() => {
      handleEvent({ value: `${PREFIXES.HEADER}:search box` }, HEADER_EVENT);
      setSearchModal(true);
      navigate(getPath(Routes.SEARCH_HASH));

      if (burgerExpanded) {
        toggleMainMenu();
      }
    }, [setSearchModal, navigate, toggleMainMenu, burgerExpanded]);

    const showBackNav =
      orgProfileExpanded || userSettingsExpanded || acrossLinksExpanded || megaHistoryLast !== getPath(Routes.ROOT);

    const goBack = React.useCallback(() => {
      if (isClientAdmin && orgProfileExpanded) {
        setUserSettingsExpanded(!userSettingsExpanded);
        closeOrgProfileExpanded();
        return;
      }
      closeUserSettingsExpanded();
      closeAcrossLinksExpanded();
      backMegaHistory();
    }, [
      userSettingsExpanded,
      setUserSettingsExpanded,
      closeUserSettingsExpanded,
      closeAcrossLinksExpanded,
      closeOrgProfileExpanded,
      backMegaHistory,
      orgProfileExpanded,
      isClientAdmin,
    ]);

    const handleLinkClick = (route: Routes) => (): void => {
      const path = getPath(route);
      const name = path.includes('help') ? 'Help' : 'Cart';

      handleEvent({ text: `${PREFIXES.HEADER}:${name}:${path}` }, HEADER_EXTERNAL_LINK);

      closeUserSettingsExpanded();
      closeAcrossLinksExpanded();
      if (isClientAdmin) {
        closeOrgProfileExpanded();
      }
      setBurger(false);
      clearMegaHistory();
    };

    const menuTriggerIcon = burgerExpanded ? IconClose : IC_MENU;

    return (
      <ContainerStyled fluid data-testid="header-global">
        {isCurrencySelectionModalOpen && (
          <CimaCurrencySelectionModal
            selectedCurrency={selectedCurrency.label}
            onCancel={onCurrencySelectionModalClose}
            onConfirmation={onCurrencySelectionModalConfirm}
          />
        )}

        <FeatureToggleWrapper toggle={FEATURE_TOGGLE_DASHBOARD}>
          <StyledFeatureToggle to={getPath(Routes.FEATURE_TOGGLES)} aria-label="Feature toggle link" />
        </FeatureToggleWrapper>
        <Container>
          <GridStyled columns={2}>
            <ColumnStyled>
              <MenuSegment basic textAlign="left">
                <OnlyMobileCSS>
                  {showBackNav ? (
                    <ArrowBackSVGStyled onClick={goBack} color={theme.colors.primaryPurple} data-testid="arrow-back" />
                  ) : (
                    <AicpaLogoStyled to={Routes.FEED} onClick={goToHome} />
                  )}
                </OnlyMobileCSS>
              </MenuSegment>
            </ColumnStyled>
            <ColumnIconsStyled textAlign="right">
              <FlexSegment basic>
                {!hideCurrencyDrown && (
                  <StyledDropdownDiv>
                    <StyledDropdownIcon src={IC_PUBLIC} alt="public" />
                    <StyledDropdownLabelText>Currency:</StyledDropdownLabelText>
                    <StyledDropdown
                      closeOnEscape
                      selectOnBlur={false}
                      onChange={handleCurrencyDropdownChange}
                      options={currencyOptions}
                      placeholder="Select a currency"
                      value={currentCurrency.label}
                      scrolling
                    />
                  </StyledDropdownDiv>
                )}
                <StyledLink
                  testId="cart-link"
                  to={getPath(Routes.CART_PAGE)}
                  onClick={handleLinkClick(Routes.CART_PAGE)}
                  type={LinkEnums.type.standaloneLinkOnDarkBg}
                >
                  <StyledLabeledIcon
                    label={numberOfItemsInCart ? `Cart (${numberOfItemsInCart})` : 'Cart'}
                    icon={<StyledShoppingCart />}
                  />
                </StyledLink>
                <OnlyDesktopCSS>
                  <Link
                    testId="help-link"
                    to={getPath(Routes.HELP)}
                    onClick={handleLinkClick(Routes.HELP)}
                    type={LinkEnums.type.standaloneLinkOnDarkBg}
                  >
                    <StyledLabeledIcon icon={<StyledHelp />} label="Help" />
                  </Link>
                </OnlyDesktopCSS>
                <StyledOnlyMobile>
                  <FlexSegmentMobileIcons basic>
                    <StyledSearchIcon
                      icon={<StyledIcon src={IC_SEARCH} alt="search" />}
                      label="Search"
                      onClick={open}
                    />
                    <StyledIcon src={menuTriggerIcon} alt="burger" onClick={toggleMainMenu} />
                  </FlexSegmentMobileIcons>
                </StyledOnlyMobile>
              </FlexSegment>
            </ColumnIconsStyled>
          </GridStyled>
        </Container>
        <FilterDefs />
      </ContainerStyled>
    );
  }
);

const StyledFeatureToggle = styled(Link)`
  position: absolute;
  z-index: 100;
  width: ${props => props.theme.pxToRem(10)};
  height: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xxs};
`;

const StyledIcon = styled.img`
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(40)}; // ie11 support
  margin-right: ${props => props.theme.pxToRem(4)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(32)};
    height: ${props => props.theme.pxToRem(32)}; // ie11 support
    margin-left: ${props => props.theme.pxToRem(10)};
    margin-right: 0;
    filter: brightness(0) invert(1) url(${SVG_FILTERS.PRIMARY_PURPLE});
  }
`;

const ArrowBackSVGStyled = styled(ArrowBackSVG)`
  width: ${props => props.theme.pxToRem(32)};
  height: ${props => props.theme.pxToRem(32)};
  margin-top: ${props => props.theme.pxToRem(28)};
`;

const GridStyled = styled(Grid)`
  &&& {
    margin-top: 0;
  }
`;

const StyledLabeledIcon = styled(LabeledIcon)`
  cursor: pointer;

  &&&& {
    margin-left: ${props => props.theme.pxToRem(12)};
    color: ${props => props.theme.colors.neutralWhite};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 0;
    }
  }
`;

const FlexSegment = styled(Segment)`
  &&&& {
    display: inline-flex;
    padding: 0 ${props => props.theme.pxToRem(12)} 0;
    line-height: ${props => props.theme.pxToRem(40)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: 0;
    }
  }
`;

const FlexSegmentMobileIcons = styled(FlexSegment as any)`
  &&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: 0;
    }
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const MenuSegment = styled(Segment)`
  &&&& {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const AicpaLogoStyled = styled(AicpaLogo)`
  margin-top: ${props => props.theme.pxToRem(22)};
  cursor: pointer;
  height: ${props => props.theme.pxToRem(47)};
  width: ${props => props.theme.pxToRem(130)};
`;

const StyledSearchIcon = styled(LabeledIcon)`
  &&&& {
    img {
      width: ${props => props.theme.pxToRem(32)};
      height: ${props => props.theme.pxToRem(32)};
    }
  }
`;

const ColumnStyled = styled(Grid.Column)`
  &&&&&&&&&&&&&&& {
    padding: 0;
  }
`;

const ColumnIconsStyled = styled(Grid.Column)`
  &&&&&&&&&&&&&&& {
    padding-top: 0;
    padding-bottom: 0;

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-top: ${props => props.theme.pxToRem(29)};
    }
  }
`;

const ContainerStyled = styled(Container)`
  &&& {
    width: 100%;
    height: ${props => props.theme.pxToRem(40)};
    box-shadow: inset 0 ${props => props.theme.pxToRem(-13)} 0px ${props => props.theme.pxToRem(-10)}
      rgba(255, 255, 255, 0.3);
    background-image: linear-gradient(to left, #9c2463, ${props => props.theme.colors.primaryPurple});

    ${props => props.theme.mediaQueries.mobileOnly} {
      height: ${props => props.theme.pxToRem(90)};
      margin-left: 0 !important;
      margin-right: 0 !important;
      background-image: none;
      box-shadow: none;
    }
  }
`;

const StyledOnlyMobile = styled(OnlyMobileCSS)`
  display: flex;
  align-items: center;
`;

const StyledHelp = styled(Help)`
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  margin-right: ${props => props.theme.pxToRem(4)};

  > path {
    fill: ${props => props.theme.colors.neutralWhite};
  }
`;

const StyledShoppingCart = styled(ShoppingCart)`
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  margin-right: ${props => props.theme.pxToRem(4)};

  > path {
    fill: ${props => props.theme.colors.neutralWhite};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(32)};
    height: ${props => props.theme.pxToRem(32)};
    margin-right: 0;

    > path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyledDropdown = styled(Dropdown).attrs(props => ({
  'aria-label': 'Choose currency',
}))`
  &.dropdown.active {
    border: 0;
  }

  &&&& {
    border: 0;
    color: ${props => props.theme.colors.neutralWhite};
  }

  &&&.ui.dropdown > .menu {
    width: ${props => props.theme.pxToRem(140)} !important;
  }

  &&&.ui.dropdown .menu > .item {
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.light};
    padding: 0.6rem 1rem !important;
    border-top: 1px solid ${props => props.theme.colors.neutralGrey1};
    line-height: 1.57;
    z-index: 1000;

    &:first-of-type {
      border-top: none;
    }

    &:hover {
      background: ${props => props.theme.colors.neutralGrey1};
      color: ${props => props.theme.colors.neutralBlack};
      font-weight: ${props => props.theme.fontWeights.regular};
    }
  }

  &&&.ui.dropdown > .text {
    color: ${props => props.theme.colors.neutralWhite};
    font-size: ${props => props.theme.fontSizes.s};
    margin-right: ${props => props.theme.pxToRem(4)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    &&& {
      width: 100%;
      border: 0;
      color: ${props => props.theme.colors.primaryPurple};
    }

    &&&.ui.dropdown > .text {
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyledDropdownLabelText = styled.div`
  color: ${props => props.theme.colors.neutralWhite};
  padding-right: ${props => props.theme.pxToRem(8)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-weight: ${props => props.theme.fontWeights.bold};
    color: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledDropdownIcon = styled.img`
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(40)}; // ie11 support
  margin-right: ${props => props.theme.pxToRem(4)};
  filter: brightness(0) invert(1);

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(32)};
    height: ${props => props.theme.pxToRem(32)}; // ie11 support
    margin-left: ${props => props.theme.pxToRem(10)};
    margin-right: 0;
    filter: brightness(0) invert(1) url(${SVG_FILTERS.NEUTRAL_WHITE});
  }
`;

const StyledDropdownDiv = styled.div`
  display: flex;
  width: ${props => props.theme.pxToRem(260)};
  margin-right: ${props => props.theme.pxToRem(8)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(200)};
    margin-right: ${props => props.theme.pxToRem(30)};
    display: none;
  }
`;
