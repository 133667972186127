export const range = (start: number, end: number) => Array.from(Array(end - start + 1), (_, i) => start + i);

export const arrayIncludes = <T>(array: null | T[], value: T) => {
  const length = array == null ? 0 : array.length;
  return !!length && array!.indexOf(value) > -1;
};

export const flattenArray = (array: any[]): any[] => {
  if (Array.isArray(array)) {
    const result: any[] = [];
    const stack: any[] = ([] as any[]).concat(array);

    while (stack.length) {
      const next = stack.pop();
      if (Array.isArray(next)) {
        stack.push.apply(stack, next);
      } else {
        result.push(next);
      }
    }
    return result.reverse();
  }
  return array;
};
