import React from 'react';
import styled from 'styled-components';
import { InputWithValidation } from 'components/molecules';
import { Dropdown } from 'components/atoms';
import { StrictDropdownProps } from 'semantic-ui-react';
import { Utils, InquiryForm as InquiryFormUtils } from 'mxp-utils';
import { InquiryForm } from 'mxp-schemas';

interface InquiryFormLeadCaptureProps {
  setFormValid: (valid: boolean) => void;
  form: InquiryForm.LeadCapture;
  setForm: (form: InquiryForm.LeadCapture) => void;
  onFormStart: () => void;
}

export const InquiryFormLeadCapture: React.FC<InquiryFormLeadCaptureProps> = ({
  form,
  setForm,
  setFormValid,
  onFormStart,
}) => {
  const isFirstNameEmpty = !Boolean(form.firstName.length);
  const isFirstNameValid = Utils.isShorterThan(form.firstName, InquiryForm.FieldLengths.FIRST_NAME);
  const isLastNameEmpty = !Boolean(form.lastName.length);
  const isLastNameValid = Utils.isShorterThan(form.lastName, InquiryForm.FieldLengths.LAST_NAME);
  const isEmailEmpty = !Boolean(form.email.length);
  const isEmailValid =
    Utils.isEmailFormatValid(form.email) && Utils.isShorterThan(form.email, InquiryForm.FieldLengths.EMAIL);
  const isCountryEmpty = !Boolean(form.country.length);
  const isTitleEmpty = !Boolean(form.title.length);
  const isTitleValid = Utils.isShorterThan(form.title, InquiryForm.FieldLengths.TITLE);
  const isCompanyEmpty = !Boolean(form.company.length);
  const isCompanyValid = Utils.isShorterThan(form.company, InquiryForm.FieldLengths.COMPANY);
  const isPhoneEmpty = !Boolean(form.phone.length);
  const isNumberOfMembersEmpty = !Boolean(form.numberOfMembers.length);
  const isNumberOfMembersValid =
    Utils.isNumericString(form.numberOfMembers) && Utils.isShorterThan(form.numberOfMembers, 10);
  const isCommentsEmpty = !Boolean(form.comments.length);
  const isCommentsValid = Utils.isShorterThan(form.comments, InquiryForm.FieldLengths.COMMENTS);

  React.useEffect(() => {
    const formValid =
      !isEmailEmpty &&
      isEmailValid &&
      !isFirstNameEmpty &&
      isFirstNameValid &&
      !isLastNameEmpty &&
      isLastNameValid &&
      !isCountryEmpty &&
      !isTitleEmpty &&
      isTitleValid &&
      !isCompanyEmpty &&
      isCompanyValid &&
      (isNumberOfMembersEmpty || isNumberOfMembersValid) &&
      (isCommentsEmpty || isCommentsValid);
    setFormValid(formValid);
  }, [
    form,
    isEmailValid,
    isEmailEmpty,
    isFirstNameEmpty,
    isFirstNameValid,
    isLastNameEmpty,
    isLastNameValid,
    isCountryEmpty,
    isTitleEmpty,
    isTitleValid,
    isCompanyEmpty,
    isCompanyValid,
    isPhoneEmpty,
    isNumberOfMembersEmpty,
    isNumberOfMembersValid,
    isCommentsEmpty,
    isCommentsValid,
    setFormValid,
  ]);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, firstName: event.target.value });
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, lastName: event.target.value });
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, email: event.target.value });
  };
  const handleCountryDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedCountry: string = data.value as string;
      setForm({ ...form, country: selectedCountry });
    },
    [setForm, form]
  );
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, title: event.target.value });
  };
  const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, company: event.target.value });
  };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, phone: event.target.value });
  };
  const handleNumberOfMembersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, numberOfMembers: event.target.value });
  };
  const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, comments: event.target.value });
  };
  const onKeyPress = (event: any) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  const fieldRequired = 'Required';
  const fieldMaxLength = (length: number) => `Sorry, that's too long. Must be fewer than ${length} characters.`;
  return (
    <>
      <Title> Connect with our team</Title>
      <Subtitle>
        Find out more about organisation billing and see how you can access your organisation’s payments.
      </Subtitle>
      <InputWithValidation
        labelName={'Organization Name'}
        name={InquiryForm.FieldIds.COMPANY}
        type="text"
        value={form.company}
        testId={InquiryForm.FieldIds.COMPANY}
        labelId={InquiryForm.FieldIds.COMPANY}
        onChange={handleCompanyChange}
        isCorrect={isCompanyValid || isCompanyEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.COMPANY)}`}
      />
      <InputWithValidation
        labelName={'Job Title'}
        name={InquiryForm.FieldIds.TITLE}
        type="text"
        value={form.title}
        testId={InquiryForm.FieldIds.TITLE}
        labelId={InquiryForm.FieldIds.TITLE}
        onChange={handleTitleChange}
        isCorrect={isTitleValid || isTitleEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.TITLE)}`}
      />
      <InputWithValidation
        labelName={'First Name'}
        name={InquiryForm.FieldIds.FIRST_NAME}
        type="text"
        value={form.firstName}
        testId={InquiryForm.FieldIds.FIRST_NAME}
        labelId={InquiryForm.FieldIds.FIRST_NAME}
        onChange={handleFirstNameChange}
        onClick={onFormStart}
        isCorrect={isFirstNameValid || isFirstNameEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.FIRST_NAME)}`}
      />
      <InputWithValidation
        labelName={'Last Name'}
        name={InquiryForm.FieldIds.LAST_NAME}
        type="text"
        value={form.lastName}
        testId={InquiryForm.FieldIds.LAST_NAME}
        labelId={InquiryForm.FieldIds.LAST_NAME}
        onChange={handleLastNameChange}
        isCorrect={isLastNameValid || isLastNameEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.LAST_NAME)}`}
      />

      <InputWithValidation
        labelName={'Email Address'}
        name={InquiryForm.FieldIds.EMAIL}
        type="email"
        value={form.email}
        testId={InquiryForm.FieldIds.EMAIL}
        labelId={InquiryForm.FieldIds.EMAIL}
        onChange={handleEmailChange}
        isCorrect={isEmailValid || isEmailEmpty}
        infoMessage={fieldRequired}
        errorMessage={'Please enter a valid email address.'}
        isCorrectIconShown
      />
      <DropDownContainer>
        <Label>Location</Label>
        <StyledDropdown
          testId={InquiryForm.FieldIds.LOCATION}
          onChange={handleCountryDropdownChange}
          options={InquiryFormUtils.LeadCaptureFormCountryList}
          value={form.country}
          placeholder="Select Location"
          errorMessage="Please select a location from the dropdown."
          error={isCountryEmpty}
          search={true}
        />
      </DropDownContainer>
      <InputWithValidation
        labelName={'Phone Number'}
        name={InquiryForm.FieldIds.PHONE}
        onKeyPress={onKeyPress}
        type="text"
        value={form.phone}
        testId={InquiryForm.FieldIds.PHONE}
        labelId={InquiryForm.FieldIds.PHONE}
        onChange={handlePhoneChange}
        isCorrect
        errorMessage={'Please enter a valid mobile number'}
      />
      <InputWithValidation
        labelName={'Number of individuals to pay for (approximate)'}
        name={InquiryForm.FieldIds.TEAM_SIZE}
        type="text"
        value={form.numberOfMembers}
        testId={InquiryForm.FieldIds.TEAM_SIZE}
        labelId={InquiryForm.FieldIds.TEAM_SIZE}
        onChange={handleNumberOfMembersChange}
        isCorrect={isNumberOfMembersValid || isNumberOfMembersEmpty}
        errorMessage={''}
      />
      <InputWithValidation
        labelName={'Other / Comments'}
        name={InquiryForm.FieldIds.COMMENTS}
        type="text"
        value={form.comments}
        testId={InquiryForm.FieldIds.COMMENTS}
        labelId={InquiryForm.FieldIds.COMMENTS}
        onChange={handleCommentsChange}
        isCorrect={isCommentsValid || isCommentsEmpty}
        errorMessage={fieldMaxLength(InquiryForm.FieldLengths.COMMENTS)}
      />
    </>
  );
};

const DropDownContainer = styled.div`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(26)};
  }
`;

const Label = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const Title = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(12)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.light};
  }
`;

const Subtitle = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(7)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.light};
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      width: 100%;
      height: ${props.theme.pxToRem(48)};
      padding-right: ${props.theme.pxToRem(10)};
      background-color: ${props.theme.colors.neutralWhite};

      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }

      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.primaryPurple};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }

      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.primaryPurple};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }

      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.interfaceBlue};
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
    }
  `}
`;
