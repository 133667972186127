import { EventDescribor, UTAG_TYPES } from 'utils/Analytics/types';
import { getPageNameAccessor } from 'utils/Analytics/helpers';
import { addTopicNames, addIndustryNames, lockedContentAccessors } from '../content/analytics';

export const SearchAnalyticsConsts: {
  [key: string]: EventDescribor;
} = {
  'search/FETCH_CONTENT': {
    name: 'search',
    accessors: [
      ...getPageNameAccessor(),
      ...lockedContentAccessors(),
      {
        name: 'search_type',
        path: '',
        processor: (a, b) => (b.router.location.search ? 'navigated' : 'intended'),
      },
      {
        name: 'search_value',
        path: '',
        processor: (a, b) => {
          if (b.router.location.search) return '';
          const query = b.router.location.pathname.split('/')[2];
          if (query && query.length > 0) return query;
          return 'no search query';
        },
      },
      {
        name: 'search_total',
        path: 'getContentBySearch.meta.total',
      },
      {
        name: 'search_per_page',
        path: 'getContentBySearch.meta.perPage',
      },
      {
        name: 'search_page',
        path: 'getContentBySearch.meta.page',
      },
      {
        name: 'content_topics',
        path: '',
        processor: addTopicNames,
      },
      {
        name: 'content_industries',
        path: '',
        processor: addIndustryNames,
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
};
