import React from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms/';
import { RadioOption } from 'components/molecules/RadioOption/RadioOption';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';

interface Props {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  optionYes: boolean;
  optionNo: boolean;
  submit: (event: any) => void;
}

export const AutoRenewModal: React.FC<Props> = ({ open, onClose, onClick, optionNo, optionYes, submit }) => (
  <Modal
    open={open}
    onClose={onClose}
    heading="Confirm subscription auto-renewal"
    testId="auto-renew-modal"
    oneOption
    confirmNode={
      <StyledButtonLink
        size={ButtonEnums.sizes.small}
        variant={ButtonEnums.variants.primary}
        testId="confirm-button"
        onClick={submit}
      >
        Confirm
      </StyledButtonLink>
    }
  >
    <StyledText>Confirm auto-renewal opt-in for your subscription</StyledText>
    <RadioContainer>
      <RadioOptionYes checked={optionYes} label="Yes" value="Yes" onClick={onClick} test-id="yes-radio" />
      <RadioOption checked={optionNo} label="No" value="No" onClick={onClick} test-id="no-radio" />
    </RadioContainer>
  </Modal>
);

const StyledButtonLink = styled(Button)`
  &&&&& {
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};
  }
`;

const StyledText = styled.p`
  padding-top: ${props => props.theme.pxToRem(30)};
  text-align: center;
`;

const RadioContainer = styled.p<ThemedStyledProps<{ isRegularFont?: boolean | void }, DefaultTheme>>`
  margin-top: ${props => props.theme.pxToRem(26)};
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const RadioOptionYes = styled(RadioOption)`
  margin-right: ${props => props.theme.pxToRem(36)};
`;
