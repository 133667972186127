import { useSelector } from 'react-redux';
import { mipRenewalSeasonStartConfigSelector, mipRenewalSeasonEndConfigSelector } from 'modules/app/selectors';
import { useMemo } from 'react';
import { mipRenewalSeasonDatesCalculator } from 'utils/mipRenewal';

export const useMipRenewalSeasonDates = () => {
  const mipRenewalSeasonStart = useSelector(mipRenewalSeasonStartConfigSelector);
  const mipRenewalSeasonEnd = useSelector(mipRenewalSeasonEndConfigSelector);

  const mipRenewalSeasonDates = useMemo(() => {
    return mipRenewalSeasonDatesCalculator(mipRenewalSeasonStart, mipRenewalSeasonEnd);
  }, [mipRenewalSeasonStart, mipRenewalSeasonEnd]);

  return mipRenewalSeasonDates;
};
