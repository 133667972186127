import React from 'react';
import styled from 'styled-components';
import { MomentHelpers } from 'utils/MomentHelpers';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';

interface Props {
  card?: State.CreditCard | null;
  year?: number;
  month?: number;
  testId: string;
  className?: string;
}

export const ExpiryLabel: React.FC<Props> = ({ year = 1990, month = 1, testId, className }) => {
  const yearFormated: string = year.toString().slice(-2);
  const monthFormated: string = month < 10 ? `0${month}` : month?.toString();

  const expiryText = (): React.ReactNode =>
    MomentHelpers.isYearMonthExpired(year, month) ? (
      <StyledExpired className={className}>
        <StyledIcon data-testid={`icon-${testId}`} />
        Expired {monthFormated}/{yearFormated}
      </StyledExpired>
    ) : (
      <div className={className}>
        Expires: {monthFormated}/{yearFormated}
      </div>
    );

  return expiryText() as JSX.Element;
};

const StyledExpired = styled.div`
  color: ${props => props.theme.colors.interfaceRed};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.xxs};
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-content: center;
`;

const StyledIcon = styled(IconError)`
  && {
    fill: ${props => props.theme.colors.interfaceRed};
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
    margin: auto ${props => `${props.theme.pxToRem(2)}`} auto auto;
  }
`;
