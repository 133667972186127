import styled from 'styled-components';
import React from 'react';

interface Props {
  className?: string;
  horizontal?: boolean;
}

export const SupportedPayments: React.FC<Props> = ({ className, horizontal = false }) => (
  <Wrapper className={className} horizontal={horizontal}>
    <Text horizontal={horizontal}>We accept</Text>
    <PaymentTypes horizontal={horizontal}>
      <PaymentType>
        <img src="/visa.png" alt="Visa" />
      </PaymentType>
      <PaymentType>
        <img src="/mastercard.png" alt="Mastercard" />
      </PaymentType>
      <PaymentType>
        <img src="/amex.png" alt="American Express" />
      </PaymentType>
      <PaymentType>
        <StyledPaypalContainer>
          <img src="/paypal-full.png" alt="Paypal" />
        </StyledPaypalContainer>
      </PaymentType>
      <PaymentType>
        <img src="/diners-club.png" alt="Diners club" />
      </PaymentType>
      <PaymentType>
        <img src="/discover.png" alt="Discover" />
      </PaymentType>
      <PaymentType>
        <img src="/jcb.png" alt="JCB" />
      </PaymentType>
    </PaymentTypes>
  </Wrapper>
);

const Wrapper = styled.div<Props>`
  max-width: ${props => (props.horizontal ? props.theme.pxToRem(375) : props.theme.pxToRem(360))};
  display: flex;
  flex-direction: ${props => (props.horizontal ? 'row' : 'column')};
  align-items: center;
`;

const Text = styled.p<Props>`
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.5;
  text-align: center;
  margin-bottom: ${props => (props.horizontal ? props.theme.pxToRem(0) : props.theme.pxToRem(2))};
  ${props => (props.horizontal ? `font-weight: ${props.theme.fontWeights.light}; ` : '')};
`;

const PaymentTypes = styled.div<Props>`
  display: flex;
  justify-content: space-around;
  padding: ${props => (props.horizontal ? `0 ${props.theme.pxToRem(5)}` : `0 ${props.theme.pxToRem(10)}`)};
`;

const PaymentType = styled.div`
  max-width: ${props => props.theme.pxToRem(44)};
  height: ${props => props.theme.pxToRem(28)};
  margin: 0.2rem;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const StyledPaypalContainer = styled.div`
  background: ${props => props.theme.colors.neutralGrey1};
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey2};
  border-radius: ${props => props.theme.pxToRem(2)};
  width: ${props => props.theme.pxToRem(41.25)};
  height: auto;
  padding: ${props => props.theme.pxToRem(1)} ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(1)}
    ${props => props.theme.pxToRem(2)};
`;
