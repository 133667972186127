import React from 'react';
import styled from 'styled-components';
import { AicpaLogo, Container } from 'components/atoms';
import { ReactComponent as IconStepperCircle } from 'resources/images/icon-dev-ic-stepper-circle.svg';
import { ReactComponent as IconCheckCircle } from 'resources/images/icon-dev-ic-check-circle.svg';
import { ReactComponent as IconStepperTwo } from 'resources/images/icon-dev-ic-stepper-two.svg';
import { ReactComponent as IconStepperThree } from 'resources/images/icon-dev-ic-stepper-three.svg';
import { ReactComponent as IconStepperFour } from 'resources/images/icon-dev-ic-stepper-four.svg';

interface Props {
  stepIndex?: number;
  labels?: string[];
}

export const HeaderSteps: React.FC<Props> = ({ stepIndex, labels }) => {
  const buildSteps = (step: any, stepsLabels: any) => {
    const elements: any = [];

    stepsLabels.forEach((value: any, index: any) => {
      let stepIcon;
      if (index === step) stepIcon = <IconStepperCircle />;
      else if (index < step) stepIcon = <IconCheckCircle />;
      else if (index === 1) stepIcon = <IconStepperTwo />;
      else if (index === 2) stepIcon = <IconStepperThree />;
      else if (index === 3) stepIcon = <IconStepperFour />;

      elements.push(
        <StyledListItem key={index} className={index === step ? 'current' : 'done'}>
          {stepIcon}
          <span>{value}</span>
        </StyledListItem>
      );
    });

    return elements;
  };

  return (
    <StyledHeader>
      <Container>
        <Wrapper>
          <StyledAicpaLogo overrideAnalytics={true} />

          <StyledList>{buildSteps(stepIndex, labels)}</StyledList>
        </Wrapper>
      </Container>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.neutralWhite};
  margin-bottom: ${props => props.theme.pxToRem(32)};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.theme.pxToRem(90)};
  margin-right: ${props => props.theme.pxToRem(-30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-right: ${props => props.theme.pxToRem(-10)};
  }
`;

const StyledAicpaLogo = styled(AicpaLogo)`
  height: ${props => props.theme.pxToRem(42)};
`;

const StyledList = styled.ul`
  position: relative;
  display: flex;
  padding-left: 0;
  margin: 0;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  list-style: none;

  ::after {
    position: absolute;
    content: '';
    z-index: 0;
    width: 100%;
    height: 100%;
    bottom: 50%;
    left: 0%;
    border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  }
`;

const StyledListItem = styled.li`
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.pxToRem(12)};
  margin-right: ${props => props.theme.pxToRem(24)};
  background-color: ${props => props.theme.colors.neutralWhite};

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(6)};
    margin-right: ${props => props.theme.pxToRem(12)};

    :not(.current) {
      span {
        display: none;
      }

      > svg {
        margin-right: 0;
      }
    }
  }

  > svg {
    margin-right: ${props => props.theme.pxToRem(10)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-right: ${props => props.theme.pxToRem(4)};
    }
  }

  &.current {
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.medium};
    line-height: 1.57;

    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.fontSizes.xxs};
      line-height: 1.5;
      letter-spacing: ${props => props.theme.pxToRem(0.22)};
    }
  }

  &.done,
  &.current {
    > svg path {
      fill: ${props => props.theme.colors.secondaryTeal};
    }
  }
  :not(.current):not(.done) {
    > svg path {
      fill: ${props => props.theme.colors.neutralGrey5};
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;
