import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Divider, Icon, OnlyDesktopCSS, OnlyMobileCSS, SupportedPayments } from 'components/atoms';
import { formatPrice } from 'utils';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { useSelector } from 'react-redux';
import { productCurrencySelector } from 'modules/products/selectors';
import { currencySelector } from 'modules/firmAdmin/selectors';
import {
  isPageFirmBillingInvoiceCheckoutSelector,
  isPaypalProceedInvoiceCheckoutSelector,
} from 'modules/router/selectors';

interface Props {
  testId?: string;
  amountToPay?: number | null;
  className?: string;
  setHideHeader?: (hide: boolean) => void;
  isAdminInvoicesPayment?: boolean;
  invoices: State.Invoice[] | null;
  isOrgInvoiceCheckout?: boolean;
}

export const InvoiceSummary: React.FC<Props> = ({
  testId,
  amountToPay,
  className,
  setHideHeader,
  isAdminInvoicesPayment = false,
  invoices,
  isOrgInvoiceCheckout = false,
}) => {
  const [isSummaryOpen, setSummaryOpen] = useState(false);
  const amount = invoices && invoices.reduce((x: number, y: any) => x + y.amount, 0);
  const isPageFirmBillingInvoiceCheckout = useSelector(state =>
    isPageFirmBillingInvoiceCheckoutSelector(state as State.Root)
  );
  const isPaypalProceedInvoiceCheckout = useSelector(isPaypalProceedInvoiceCheckoutSelector);
  const productCurrency = useSelector(state => productCurrencySelector(state as State.Root));
  const orgCurrency = useSelector(state => currencySelector(state as State.Root));
  const currency: any =
    invoices?.length && (isOrgInvoiceCheckout || isAdminInvoicesPayment || isPaypalProceedInvoiceCheckout)
      ? invoices?.[0]?.currency
      : isPageFirmBillingInvoiceCheckout
      ? orgCurrency
      : productCurrency?.label || '';
  const handleToggleSummary = React.useCallback(() => {
    if (setHideHeader) {
      setHideHeader(!isSummaryOpen);
    }
    setSummaryOpen(!isSummaryOpen);
  }, [isSummaryOpen, setSummaryOpen, setHideHeader]);

  const invoiceSummaryTitle = isAdminInvoicesPayment ? 'Payment Summary' : 'My Invoice Summary';

  return (
    <>
      <OnlyDesktopCSS>
        <StyledDiv data-testid={testId} className={className}>
          <StyledTitle data-testid="invoice-summary-title">{invoiceSummaryTitle}</StyledTitle>
          <TitleDivider />
          {invoices?.map(invoice => {
            return (
              <InvoiceDetails
                key={invoice.invoiceNumber}
                invoiceNumber={invoice.invoiceNumber}
                amount={formatPrice(invoice.balance || 0, currency)}
              />
            );
          })}
          <DividerWrapper>
            <TotalDivider />
          </DividerWrapper>
          <StyledInvoiceTotalDetails>
            <StyledInvoiceTotalText>Total:</StyledInvoiceTotalText>
            <StyledInvoiceTotalAmount>{formatPrice(amountToPay || amount || 0, currency)}</StyledInvoiceTotalAmount>
          </StyledInvoiceTotalDetails>
        </StyledDiv>
        <StyledSupportedPayments />
      </OnlyDesktopCSS>
      {!isAdminInvoicesPayment && (
        <OnlyMobileCSS>
          <StickyFooterStyled onClick={handleToggleSummary}>
            {isSummaryOpen && (
              <>
                <OpenInvoiceSummaryWrapper>
                  <OpenInvoiceSummaryTitle>{invoiceSummaryTitle}</OpenInvoiceSummaryTitle>
                  <StyledIconClose onClick={handleToggleSummary} />
                </OpenInvoiceSummaryWrapper>
                <StyledDivider />
                {invoices?.map(invoice => {
                  return (
                    <InvoiceDetails
                      key={invoice.invoiceNumber}
                      invoiceNumber={invoice.invoiceNumber}
                      amount={formatPrice(invoice.balance || 0, currency)}
                    />
                  );
                })}
                <DividerWrapper>
                  <TotalDivider />
                </DividerWrapper>
              </>
            )}
            <MobileStickyFooter
              handleToggleSummary={handleToggleSummary}
              isSummaryOpen={isSummaryOpen}
              total={(formatPrice(amountToPay || 0), currency)} // tslint:disable-line: ban-comma-operator
              title={invoiceSummaryTitle}
            />
          </StickyFooterStyled>
        </OnlyMobileCSS>
      )}
    </>
  );
};

const InvoiceDetails: React.FC<{
  amount?: string | null;
  invoiceNumber?: string | null;
  // tslint:disable-next-line: ban-comma-operator
}> = ({ invoiceNumber, amount }) => {
  return (
    <>
      <StyledInvoiceDetails>
        <StyledInvoiceNumber>Invoice #{invoiceNumber}</StyledInvoiceNumber>
        <StyledInvoiceAmount>{amount}</StyledInvoiceAmount>
      </StyledInvoiceDetails>
    </>
  );
};

const MobileStickyFooter: React.FC<{
  handleToggleSummary: () => void;
  isSummaryOpen: boolean;
  total?: string | null;
  title: string;
  // tslint:disable-next-line: ban-comma-operator
}> = ({ handleToggleSummary, isSummaryOpen, total, title }) => {
  return (
    <StickyFooterWrapper>
      <div onClick={handleToggleSummary} data-testid="trigger-collapsible-total">
        <AngleFooterIcon fitted name={!isSummaryOpen ? 'angle up' : 'angle down'} />
      </div>
      <FlexWrapText>{title}</FlexWrapText>
      <FlexWrapAmount>{total}</FlexWrapAmount>
    </StickyFooterWrapper>
  );
};

const StickyFooterStyled = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  background-color: ${props => props.theme.colors.neutralGrey1};
  border-top: solid 1px ${props => props.theme.colors.neutralGrey3};
`;

const OpenInvoiceSummaryTitle = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const FlexWrapAmount = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
`;

const FlexWrapText = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 0;
`;

const StickyFooterWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(20)};
  }
`;

const OpenInvoiceSummaryWrapper = styled.div`
  align-items: center;
  padding: ${props => props.theme.pxToRem(40)} ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(6)};
  display: flex;
  justify-content: space-between;
`;

const StyledDivider = styled(Divider)`
  color: ${props => props.theme.colors.neutralGrey1};
`;

const AngleFooterIcon = styled(Icon)`
  &&& {
    font-size: ${props => props.theme.fontSizes.m};
    color: ${props => props.theme.colors.primaryPurple};
    padding-right: ${props => props.theme.pxToRem(16)};
  }
`;
const StyledIconClose = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;

const StyledDiv = styled.div`
  max-width: ${props => props.theme.pxToRem(360)};
  backdrop-filter: blur(${props => props.theme.pxToRem(4)});
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.neutralGrey1};
`;

const StyledTitle = styled.div`
  padding: ${props => props.theme.pxToRem(21)} 0 ${props => props.theme.pxToRem(3)} ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.pxToRem(18)};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.33;
`;

const StyledInvoiceDetails = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  display: flex;
  justify-content: space-between;
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.regular};
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(4)};
  }
`;

const StyledInvoiceNumber = styled.div`
  padding: ${props => props.theme.pxToRem(7)} ${props => props.theme.pxToRem(16)};
`;

const StyledInvoiceAmount = styled.div`
  padding: ${props => props.theme.pxToRem(7)} ${props => props.theme.pxToRem(16)};
`;

const StyledInvoiceTotalDetails = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  display: flex;
  justify-content: space-between;
`;

const StyledInvoiceTotalText = styled.div`
  padding: ${props => props.theme.pxToRem(0)} 0 ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(16)};
`;

const StyledInvoiceTotalAmount = styled.div`
  padding: ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(16)} 0;
`;

const TitleDivider = styled(Divider)`
  color: ${props => props.theme.colors.neutralGrey3};
`;

const TotalDivider = styled(Divider)`
  color: ${props => props.theme.colors.neutralGrey3};
  max-width: ${props => props.theme.pxToRem(328)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    &&& {
      margin: ${props => props.theme.pxToRem(20)} 0 0 0;
    }
  }
`;

const DividerWrapper = styled.div`
  justify-content: space-around;
  padding: 0 ${props => props.theme.pxToRem(16)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(20)};
  }
`;

const StyledSupportedPayments = styled(SupportedPayments)`
  margin-top: ${props => props.theme.pxToRem(24)};
`;
