import React from 'react';
import styled from 'styled-components';
import { ContentCard } from 'components/molecules/ContentCard/ContentCard';
import { ButtonEnums, ButtonLink, Grid, Heading, Link, LinkEnums } from 'components/atoms';
import { DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { getContentUrl } from 'utils';
import { Content, Contentful } from 'mxp-schemas';
import { ReactComponent as ExternalIcon } from 'resources/images/ic-open-in-new.svg';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}

export const BlockList: React.FC<Props> = React.memo(({ modularBlock, headingType }) => {
  const block = modularBlock as Contentful.ModularContent.ListBlock;
  const isDisplayText: boolean = block.displayType === Content.DisplayType.TEXT;

  const renderListItems = (item: any, contentType: string) => {
    const isExternalLink = Boolean(new RegExp(/^http(s)?:\/\//).test(getContentUrl(item)));
    switch (contentType) {
      case Content.ContentType.DYNAMIC_LIST:
        return (
          <StyledLinkBlock key={item.id}>
            {isExternalLink && <StyledExternalIcon />}
            <StyledLinkTitle
              isExternal={!!item.externalUrl}
              type={LinkEnums.type.standaloneLink}
              to={getContentUrl(item)}
            >
              {item.title}
            </StyledLinkTitle>
            <StyledLinkParagraph>{item.description}</StyledLinkParagraph>
          </StyledLinkBlock>
        );
      case Content.ContentType.LISTS:
        return (
          <StyledLinkBlock key={`link-${item.id}`}>
            {isExternalLink && <StyledExternalIcon />}
            <StyledLinkTitle isExternal={item.url} type={LinkEnums.type.standaloneLink} to={getContentUrl(item)}>
              {item.title || item.name}
            </StyledLinkTitle>
            <StyledLinkParagraph>{item.description}</StyledLinkParagraph>
          </StyledLinkBlock>
        );
      default:
        return null;
    }
  };

  const dynamicListButtons = (item: any) => {
    return (
      <>
        {item?.ctaUrl && (
          <StyledButtonBlock isDisplayText={isDisplayText}>
            <StyledButtonLink
              size={ButtonEnums.sizes.medium}
              variant={ButtonEnums.variants.primary}
              testId="search"
              to={item.ctaUrl}
            >
              {item.linkText}
            </StyledButtonLink>
          </StyledButtonBlock>
        )}
        {item.pageLink && item.pageLinkDisplayText && (
          <StyledButtonBlock isDisplayText={isDisplayText}>
            <StyledButtonLink
              size={ButtonEnums.sizes.medium}
              variant={ButtonEnums.variants.secondary}
              testId="page-link"
              to={item.pageLink}
            >
              {item.pageLinkDisplayText}
            </StyledButtonLink>
          </StyledButtonBlock>
        )}
      </>
    );
  };

  const renderImageItems = (item: State.ContentCardItem, contentType: string) => {
    switch (contentType) {
      case Content.ContentType.DYNAMIC_LIST:
        return (
          <ImageLayoutColumn key={`dynamicList-image-${item.id}`} rows={3}>
            <ContentCard contentCardItem={item} testid="dynamic-list-content-card" isHeroCard />
          </ImageLayoutColumn>
        );
      case Content.ContentType.LISTS:
        return (
          <ImageLayoutColumn key={`list-image-${item.id}`} rows={3}>
            <SmallImageCard type={LinkEnums.type.standaloneLink} to={getContentUrl(item)} isExternal={!!item.url}>
              <NameLine>{item.title || item.name}</NameLine>
            </SmallImageCard>
          </ImageLayoutColumn>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {block.header && <StyledHeading as={headingType} dangerouslySetInnerHTML={{ __html: block.header }} />}
      {block.description && <StyledParagraph dangerouslySetInnerHTML={{ __html: block.description }} />}
      {block.staticOrDynamicList?.map((item: any) => (
        <ListLayout key={item.id}>
          {block.displayType === Content.DisplayType.TEXT &&
            item.list?.map((listItem: any) => renderListItems(listItem, item.contentType))}
          {block.displayType === Content.DisplayType.IMAGE && (
            <ImageLayout>
              <ImageLayoutRow columns={3}>
                {item.list?.map((listItem: any) => renderImageItems(listItem, item.contentType))}
              </ImageLayoutRow>
            </ImageLayout>
          )}
          {item.contentType === Content.ContentType.DYNAMIC_LIST && dynamicListButtons(item)}
        </ListLayout>
      ))}
    </>
  );
});

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => (props['data-is-single-column'] ? props.theme.pxToRem(16) : props.theme.pxToRem(24))};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;

  p {
    line-height: 1.38;
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.l};
    line-height: 1.33;
  }
`;

const StyledParagraph = styled.div`
  &&&& {
    font-weight: ${props => props.theme.fontWeights.light};
    font-size: ${props => props.theme.fontSizes.m};
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};
  }
`;

const ImageLayout = styled(Grid)`
  &&& {
    width: 100%;
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const ImageLayoutRow = styled(Grid.Row)`
  &&&& {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }
`;

const ImageLayoutColumn = styled(Grid.Column)`
  padding-bottom: ${props => props.theme.pxToRem(48)};
`;

const SmallImageCard = styled(Link)`
  display: block;
  width: 100%;
  border-radius: ${props => props.theme.pxToRem(4)};

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.primaryFadedPurple};
    color: ${props => props.theme.colors.primaryDarkPurple};
    outline: none;
    text-decoration: none;

    p,
    div {
      color: ${props => props.theme.colors.primaryDarkPurple};
    }

    .icon {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const NameLine = styled.p`
  margin: 0 0 ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledLinkBlock = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  padding-bottom: ${props => props.theme.pxToRem(42)};
`;

const StyledLinkTitle = styled(Link)`
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledLinkParagraph = styled.p`
  line-height: 1.78;
  color: ${props => props.theme.colors.neutralGrey8};
  padding-top: ${props => props.theme.pxToRem(8)};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: 10%;
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    margin: 0 ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} 0;
  }
`;

const StyledButtonBlock = styled.div<{ isDisplayText: boolean }>`
  display: ${props => (props.isDisplayText ? 'block' : 'inline-block')};
`;

const StyledExternalIcon = styled(ExternalIcon)`
  vertical-align: bottom;
  margin-bottom: ${props => props.theme.pxToRem(2)};
  padding-right: ${props => props.theme.pxToRem(4)};
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  object-fit: contain;
`;

const ListLayout = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(40)};
`;
