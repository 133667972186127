import { MembershipTypes, Salesforce, User } from 'mxp-schemas';
// ------------------------------------
// Constants
// ------------------------------------
export enum UserActionNames {
  LOADING = 'user/LOADING',
  SESSION_EXISTS = 'user/SESSION_EXISTS',
  SET_AUTH = 'user/SET_AUTH',
  GET_GOLDEN_RECORD = 'user/GET_GOLDEN_RECORD',
  GET_USER_STATE = 'user/GET_USER_STATE',
  SET_USER_STATE = 'user/SET_USER_STATE',
  CREATE_GOLDEN_RECORD = 'user/CREATE_GOLDEN_RECORD',
  CREATE_GOLDEN_RECORD_CIMA = 'user/CREATE_GOLDEN_RECORD_CIMA',
  LOGIN = 'user/LOGIN',
  LOGIN_SUCCESS = 'user/LOGIN_SUCCESS',
  LOGOUT = 'user/LOGOUT',
  CHECK_USER_EXISTS = 'user/CHECK_USER_EXISTS',
  GET_USER_BY_AICPAID_LASTNAME = 'user/GET_USER_BY_AICPAID_LASTNAME',
  UPDATE_GOLDEN_RECORD = 'user/UPDATE_GOLDEN_RECORD',
  TOGGLE_SUBTOPIC_PREF = 'user/TOGGLE_SUBTOPIC_PREF',
  SELECT_ALL_SUBTOPICS = 'user/SELECT_ALL_SUBTOPICS',
  CLEAR_ALL_SUBTOPICS = 'user/CLEAR_ALL_SUBTOPICS',
  BACKUP_PREF = 'user/BACKUP_PREF',
  RESET_PREF = 'user/RESET_PREF',
  CLEAR_PREF = 'user/CLEAR_PREF',
  BACKUP_SKILL_PREF = 'user/BACKUP_SKILL_PREF',
  RESET_SKILL_PREF = 'user/RESET_SKILL_PREF',
  CLEAR_SKILL_PREF = 'user/CLEAR_SKILL_PREF',
  TOGGLE_TOPIC_PREF_UPDATE_GOLDEN_RECORD = 'user/TOGGLE_TOPIC_PREF_UPDATE_GOLDEN_RECORD',
  TOGGLE_SUBTOPIC_PREF_UPDATE_GOLDEN_RECORD = `user/TOGGLE_SUBTOPIC_PREF_UPDATE_GOLDEN_RECORD`,
  TOGGLE_INDUSTRY_PREF_UPDATE_GOLDEN_RECORD = 'user/TOGGLE_INDUSTRY_PREF_UPDATE_GOLDEN_RECORD',
  TOGGLE_TREND_PREF_UPDATE_GOLDEN_RECORD = 'user/TOGGLE_TREND_PREF_UPDATE_GOLDEN_RECORD',
  TOGGLE_SKILL_PREF_UPDATE_GOLDEN_RECORD = 'user/TOGGLE_SKILL_PREF_UPDATE_GOLDEN_RECORD',
  TOGGLE_SUBSKILL_PREF_UPDATE_GOLDEN_RECORD = `user/TOGGLE_SUBSKILL_PREF_UPDATE_GOLDEN_RECORD`,
  GET_CONTENT_FEEDBACK = 'user/GET_CONTENT_FEEDBACK',
  PUT_CONTENT_FEEDBACK = `user/PUT_CONTENT_FEEDBACK`,
  PUT_SURVEY_FEEDBACK = 'user/PUT_SURVEY_FEEDBACK',
  TOGGLE_USER_SHORTCUTS = `user/TOGGLE_USER_SHORTCUTS`,
  GOTO_PROFILE_PREFERENCES = `user/GOTO_PROFILE_PREFERENCES`,
  INVALIDATE_SESSION = 'user/INVALIDATE_SESSION',
  CREATE_USER = 'user/CREATE_USER',
  SET_RESOLVE_EMAIL = 'user/SET_RESOLVE_EMAIL',
  CREATE_USER_SUCCESS = 'user/CREATE_USER_SUCCESS',
  CREATE_USER_FAILED = 'user/CREATE_USER_FAILED',
  LOGIN_FAIL = 'user/LOGIN_FAIL',
  GET = 'user/GET',
  GET_NET_FORUM_USER = 'user/GET_NET_FORUM_USER',
  TOGGLE_NET_FORUM_LOADING = 'user/TOGGLE_NET_FORUM_LOADING',
  SET_USER_EXISTS_ERROR = 'user/SET_USER_EXISTS_ERROR',
  GET_LOCAL = 'user/GET_LOCAL',
  GET_USER_PLATFORM = 'user/GET_USER_PLATFORM',
  SEND_RESET_PASSWORD_EMAIL = 'user/SEND_RESET_PASSWORD_EMAIL',
  RESET_USER_PASSWORD = 'user/RESET_USER_PASSWORD',
  SET_USER_ERROR = 'user/SET_USER_ERROR',
  SET_USER_PASSWORD_UPDATED = 'user/SET_USER_PASSWORD_UPDATED',
  RESET = 'user/RESET',
  UPDATE_USER_PROFILE = 'user/UPDATE_USER_PROFILE',
  CHANGE_PASSWORD = 'user/CHANGE_PASSWORD',
  GENERATE_TEMPORARY_PASSWORD = 'user/GENERATE_TEMPORARY_PASSWORD',
  GET_USER_MARKETING_PREFERENCES = 'user/GET_USER_MARKETING_PREFERENCES',
  UPDATE_USER_MARKETING_PREFERENCES = 'user/UPDATE_USER_MARKETING_PREFERENCES',
  GET_SFMC_LINK = 'user/GET_SFMC_LINK',
  TOGGLE_MARKETING_PREFERENCES_LOADING = 'user/TOGGLE_MARKETING_PREFERENCES_LOADING',
  REFRESH_USER_DATA = 'user/REFRESH_USER_DATA',
  REFRESH_USER_DATA_PREMIUM_CONTENT = 'user/REFRESH_USER_DATA_PREMIUM_CONTENT',
  ADD_EMAIL_TO_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_LIST = 'user/ADD_EMAIL_TO_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_LIST',
  REMOVE_EMAIL_FROM_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_LIST = 'user/REMOVE_EMAIL_FROM_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_LIST',
  FETCH_CPA_STATUS = 'user/FETCH_CPA_STATUS',
  SET_CPA_STATUS = 'user/SET_CPA_STATUS',
  CPA_STATUS_LOADING = 'user/CPA_STATUS_LOADING',
  SEND_UPDATED_PASSWORD_EMAIL = 'user/SEND_UPDATED_PASSWORD_EMAIL',
  SEND_UPDATED_RECOVERY_QUESTION_EMAIL = 'user/SEND_UPDATED_RECOVERY_QUESTION_EMAIL',
  GET_MY_PROFILE = 'user/GET_MY_PROFILE',
  UPDATE_PERSON_ACCOUNT = 'user/UPDATE_PERSON_ACCOUNT',
  UPDATE_PERSON_ACCOUNT_LOADING = 'user/UPDATE_PERSON_ACCOUNT_LOADING',
  UPGRADE_LEARNING_PATHWAY = 'user/UPGRADE_LEARNING_PATHWAY',
  DEPROVISION_EXAM_CREDITS = 'user/DEPROVISION_EXAM_CREDITS',
  CREATE_PERSON_QUALIFICATION_LEVEL = 'user/CREATE_PERSON_QUALIFICATION_LEVEL',
  SET_STUDENT_LEARNING_PATHWAY_TO_SWITCH = 'user/SET_STUDENT_LEARNING_PATHWAY_TO_SWITCH',
  VALIDATE_ADDRESS = 'user/VALIDATE_ADDRESS',
  VALIDATE_PERSON_ADDRESS = 'user/VALIDATE_PERSON_ADDRESS',
  VALIDATE_PERSON_ADDRESS_LOADING = 'user/VALIDATE_PERSON_ADDRESS_LOADING',
  VALIDATE_ORGANIZATION_ADDRESS = 'user/VALIDATE_ORGANIZATION_ADDRESS',
  VALIDATE_ORGANIZATION_ADDRESS_LOADING = 'user/VALIDATE_ORGANIZATION_ADDRESS_LOADING',
  PROCESS_QUALIFICATION_LOADING = 'user/PROCESS_QUALIFICATION_LOADING',
  PROCESS_QUALIFICATION = 'user/PROCESS_QUALIFICATION',
  QUERY_EMPLOYMENT = 'user/GET_EMPLOYMENT_DATA',
  PROCESS_EMPLOYMENT_LOADING = 'user/PROCESS_EMPLOYMENT_LOADING',
  RESET_EMPLOYMENT_EFFECT = 'user/RESET_EMPLOYMENT_EFFECT',
  PROCESS_EMPLOYMENT = 'user/PROCESS_EMPLOYMENT',
  UPDATE_EMPLOYMENT = 'user/UPDATE_EMPLOYMENT',
  REMOVE_EMPLOYMENT = 'user/REMOVE_EMPLOYMENT',
  QUERY_ORGANIZATIONS_BY_WILDCARD = 'user/QUERY_ACCOUNTS_BY_WILDCARD',
  QUERY_ORGANIZATIONS_BY_WILDCARD_LOADING = 'user/QUERY_ACCOUNTS_BY_WILDCARD_LOADING',
  QUERY_ORGANIZATIONS_CITIES = 'user/QUERY_ORGANIZATIONS_CITIES',
  QUERY_ORGANIZATIONS_CITIES_LOADING = 'user/QUERY_ORGANIZATIONS_CITIES_LOADING',
  QUERY_ORGANIZATIONS_CITIES_STATUS = 'user/QUERY_ORGANIZATIONS_CITIES_STATUS',
  QUERY_ORGANIZATION_BY_NAME_AND_CITY = 'user/QUERY_ORGANIZATION_BY_NAME_AND_CITY',
  QUERY_ORGANIZATION_IF_EXISTS = 'user/QUERY_ORGANIZATION_IF_EXISTS',
  QUERY_UNIVERSITIES_BY_WILDCARD = 'user/QUERY_UNIVERSITIES_BY_WILDCARD',
  QUERY_UNIVERSITIES_LOADING = 'user/QUERY_UNIVERSITIES_LOADING',
  QUERY_UNIVERSITY_DETAILS = 'user/QUERY_UNIVERSITY_DETAILS',
  QUERY_UNIVERSITY_DETAILS_LOADING = 'user/QUERY_UNIVERSITY_DETAILS_LOADING',
  CLEAR_NEW_EMPLOYER_DATA = 'user/CLEAR_NEW_EMPLOYER_DATA',
  CLEAR_EMPLOYERDATA_EMPLOYERS = 'user/CLEAR_EMPLOYERDATA_EMPLOYERS',
  QUERY_ORGANIZATIONS_BY_ACCOUNT_NUMBER = 'user/QUERY_ORGANIZATIONS_BY_ACCOUNT_NUMBER',
  QUERY_FLP_RESELLER_BY_ACCOUNT_NUMBER = 'user/QUERY_FLP_RESELLER_BY_ACCOUNT_NUMBER',
  QUERY_TRAINING_PROVIDER_BY_ACCOUNT_NUMBER = 'user/QUERY_TRAINING_PROVIDER_BY_ACCOUNT_NUMBER',
  CLEAR_ORGANIZATION_FROM_PROMO_CODE = 'user/CLEAR_ORGANIZATION_FROM_PROMO_CODE',
  QUERY_SEARCH_EMPLOYER = 'user/QUERY_SEARCH_EMPLOYER',

  UPDATE_APPLICATION = 'user/UPDATE_APPLICATION',
  RESET_APPLICATION = 'user/RESET/APPLICATION',
  GET_APPLICATION = 'user/GET/APPLICATION',
  UPDATE_MEMBERSHIP_APPLICATION_PART = 'user/UPDATE_MEMBERSHIP_APPLCIATION_PART',
  UPDATE_CENTER_APPLICATION = 'user/UPDATE_CENTER_APPLICATION',
  TOGGLE_APPLICATION_INITIAL_LOAD = 'user/TOGGLE_APPLICATION_INITIAL_LOAD',
  SET_APPLICATION_INITIAL_LOAD = 'user/SET_APPLICATION_INITIAL_LOAD',
  CLEAR_SMARTY_STREETS_SECONDARY_ADDRESS_VALIDATION = 'user/CLEAR_SMARTY_STREETS_SECONDARY_ADDRESS_VALIDATION',
  SET_SUGGESTED_ADDRESS = 'user/SET_SUGGESTED_ADDRESS',
  SET_EMPLOYER_SUGGESTED_ADDRESS = 'user/SET_EMPLOYER_SUGGESTED_ADDRESS',
  ACCEPT_FIRM_MEMBERSHIP_INVITE = 'user/ACCEPT_FIRM_MEMBERSHIP_INVITE',
  PROCESS_USER_TLW = 'user/PROCESS_USER_TLW',
  REMOVE_USER_TLW = 'user/REMOVE_USER_TLW',

  // center membership
  SET_MEMBERSHIP_PACKAGE_ORGANIZATION = 'user/SET_MEMBERSHIP_PACKAGE_ORGANIZATION',
  SET_MEMBERSHIP_PACKAGE_ORGANIZATION_ISSUER = 'user/SET_MEMBERSHIP_PACKAGE_ORGANIZATION_ISSUER',
  SET_MEMBERSHIP_PACKAGE_ORGANIZATION_RESET = 'user/SET_MEMBERSHIP_PACKAGE_ORGANIZATION_RESET',
  SET_MEMBERSHIP_APPLICATION_QUESTION = 'user/SET_MEMBERSHIP_APPLICATION_QUESTION',
  SET_MEMBERSHIP_APPLICATION_ADMIN_DETAIL = 'user/SET_MEMBERSHIP_APPLICATION_ADMIN_DETAIL',
  SET_MEMBERSHIP_APPLICATION_ORGANIZATION_DETAIL = 'user/SET_MEMBERSHIP_APPLICATION_ORGANIZATION_DETAIL',
  UPDATE_ORGANIZATION_DETAILS = 'user/UPDATE_ORGANIZATION_DETAILS',
  UPDATE_APPLICATION_HEAR_ABOUT_US = 'user/UPDATE_APPLICATION_HEAR_ABOUT_US',
  UPDATE_ATTESTATION = 'user/UPDATE_ATTESTATION',
  SUBMIT_ATTESTATION = 'user/SUBMIT_ATTESTATION',
  CLEAR_ATTESTATION = 'user/CLEAR_ATTESTATION',
  SEND_PARTNER_INVITE = 'user/SEND_PARTNER_INVITE',
  FETCH_MEMBERSHIP_JOURNEY = 'user/FETCH_MEMBERSHIP_JOURNEY',
  UPDATE_ACCOUNT_AS_PARTNER = 'user/UPDATE_ACCOUNT_AS_PARTNER',

  UPDATE_PERSONAL_INFORMATION = 'user/UPDATE_PERSONAL_INFORMATION',
  UPDATE_PERSONAL_INFORMATION_LOADING = 'user/UPDATE_PERSONAL_INFORMATION_LOADING',
  CREATE_FIRM_MEMBERSHIP_WITH_ATTESTATION = 'user/CREATE_FIRM_MEMBERSHIP_WITH_ATTESTATION',
  UPDATE_APPLICATION_PART = 'user/UPDATE_APPLICATION_PART',
  UPDATE_CURRENT_LEARNING_PATHWAY = 'user/UPDATE_CURRENT_LEARNING_PATHWAY',
  SKIP_EMPLOYEMENT = 'user/SKIP_EMPLOYEMENT',
  UPDATE_PERSON_ACCOUNT_STATUS = 'user/UPDATE_PERSON_ACCOUNT_STATUS',
  GET_SECURITY_QUESTIONS = 'user/GET_SECURITY_QUESTIONS',
  UPDATE_SECURITY_RESPONSE = 'user/UPDATE_SECURITY_RESPONSE',
  QUERY_ORGANIZATIONS_BY_TRAINING_PROVIDER = 'user/QUERY_ORGANIZATIONS_BY_TRAINING_PROVIDER',
  UPDATE_TUITION_PROVIDER_STATUS = 'user/UPDATE_TUITION_PROVIDER_STATUS',
  DOCUMENT_UPLOAD = 'user/DOCUMENT_UPLOAD',
  OPEN_PV_DASHBOARD = 'user/OPEN_PV_DASHBOARD',
  GET_STUDENT_PROGRESSION = 'user/GET_STUDENT_PROGRESSION',
  RESET_STUDENT_EXAM_SECTION_RESULTS = 'user/RESET_STUDENT_EXAM_SECTION_RESULTS',
  GET_STUDENT_EXAM_SECTION_RESULTS = 'user/GET_STUDENT_EXAM_SECTION_RESULTS',
  GET_STUDENT_EXAM_HISTORY = 'user/GET_STUDENT_EXAM_HISTORY',
  SET_NEW_EMPLOYER_JOB_TITLE = 'SET_NEW_EMPLOYER_JOB_TITLE',
  GET_EPA2_SUBMISSIONS = 'user/GET_EPA2_SUBMISSIONS',
  UPDATE_EPA2L4_CASE_STATUS = 'user/UPDATE_EPA2L4_CASE_STATUS',
  SET_CURRENT_USER_PRIMARY_ADDRESS = 'SET_CURRENT_USER_PRIMARY_ADDRESS',
  CREATE_ATTESTATION = 'user/CREATE_ATTESTATION',
  CREATE_ATTESTATIONS = 'user/CREATE_ATTESTATIONS',
  GET_EPA2L7_SUBMISSIONS = 'user/GET_EPA2L7_SUBMISSIONS',
  UPDATE_EPA2L7_CASE_STATUS = 'user/UPDATE_EPA2L7_CASE_STATUS',
  SET_EVENT_REGISTRATION_DATA = 'user/SET_EVENT_REGISTRATION_DATA',
  CREATE_EVENT_FORM = 'user/CREATE_EVENT_FORM',
  CREATE_EVENT_FORM_LOADING = 'user/CREATE_EVENT_FORM_LOADING',
  CREATE_EVENT_FORM_SUCCESS = 'user/CREATE_EVENT_FORM_SUCCESS',
  UPDATE_ADDRESS_BOOK = 'user/UPDATE_ADDRESS_BOOK',
  UPDATE_USER_ADDRESS_LIST = 'user/UPDATE_USER_ADDRESS_LIST',
  PROCESS_LEAVING_CREDENTIAL_APPLICATION_PAGE = 'user/PROCESS_LEAVING_CREDENTIAL_APPLICATION_PAGE',
  CREATE_SPECIALCONSIDERATION = 'user/CREATE_SPECIALCONSIDERATION',
  CREATE_SPECIALCONSIDERATION_LOADING = 'user/CREATE_SPECIALCONSIDERATION_LOADING',
  CREATE_SPECIALCONSIDERATION_SUCCESS = 'user/CREATE_SPECIALCONSIDERATION_SUCCESS',
  MUTATE_UPDATE_EMPLOYMENT_DATA_SHARING = 'user/MUTATE_UPDATE_EMPLOYMENT_DATA_SHARING',
  TOGGLE_SUBSKILL_PREF = 'user/TOGGLE_SUBSKILL_PREF',
  SELECT_ALL_SUBSKILLS = 'user/SELECT_ALL_SUBSKILLS',
  CLEAR_ALL_SUBSKILLS = 'user/CLEAR_ALL_SUBSKILLS',
  CHECK_USER_COUNTRY_IF_EMBARGOED = 'user/CHECK_USER_COUNTRY_IF_EMBARGOED',

  GET_USER_LOCATION = 'user/GET_USER_LOCATION',
  UPDATE_PROFESSIONAL_STATUS = 'user/UPDATE_PROFESSIONAL_STATUS',
  UPDATE_YEAR_OF_GRADUATION = 'user/UPDATE_YEAR_OF_GRADUATION',
  SET_REGISTRATION_LOADING = 'user/SET_REGISTRATION_LOADING',
  SET_PREFERRED_CURRENCY = 'user/SET_PREFERRED_CURRENCY',

  GET_FLP_VALIDATION_RESULT = 'user/GET_FLP_VALIDATION_RESULT',
  UPDATE_ACCOUNT_UNIVERSITY_DETAILS = 'user/UPDATE_ACCOUNT_UNIVERSITY_DETAILS',
  GET_ETHICS_STATUS = 'user/GET_ETHICS_STATUS',
  GET_USER_SYLLABUS = 'user/GET_USER_SYLLABUS',
}

export enum MipCountry {
  UK = 'United Kingdom',
  IRELAND = 'Ireland',
}

export enum UserMemberTypes {
  LOGGED_OUT = 'loggedOut',
  NONMEMBER = 'nonmember',
  MEMBER = 'member',
  PREMIUM = 'premium',
}

export const initialState: State.User = {
  data: {
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    clientAdminOf: [],
    firmBillingClientAdminOf: [],
    centersClientAdminOf: [],
    AICPAUID: '',
    tuitionProviderClientAdminOf: [],
  },
  preferences: {},
  industryPreferences: {},
  trendPreferences: {},
  skillPreferences: {},
  previousPreferences: {},
  previousSkillPreferences: {},
  shortcuts: [],
  error: {},
  isAuth: null,
  userFetched: false,
  goldenRecordFetched: false,
  feedback: null,
  loading: false,
  invalidatedSession: false,
  forgotEmail: '',
  platform: null,
  resetEmailSendSuccess: false,
  recoveryQuestionEmailSendSuccess: false,
  userPasswordUpdated: false,
  netforumProfile: {
    firstName: '',
    lastName: '',
    email: '',
    aicpauid: '',
    loading: false,
    fetched: false,
  },
  marketingPreferences: {
    preferences: {
      isSubscribedToMarketing: true,
      thirdParty: true,
      contactViaEmail: true,
      contactViaPhone: true,
      contactViaPost: true,
    },
    loading: false,
    error: {},
  },
  cpaStatus: {
    isLicensedCPA: null,
    cpaStatusFetched: false,
    loading: false,
  },
  customerOrderHistory: [],
  memberships: [],
  suspendedCredentials: [],
  inactiveMemberships: [],
  credentials: [],
  inactiveCredentials: [],
  sections: [],
  inactiveSections: [],
  allAddresses: [],
  specialConsiderationsExams: {
    results: [],
  },
  createSpecialConsiderationLoading: false,
  createSpecialConsiderationSuccess: false,
  specialConsiderationInfo: {
    firstName: '',
    lastName: '',
    appointmentId: '',
    examKey: '',
    examDate: '',
    examDeliveryMethod: '',
    testCenter: '',
    scReason: '',
    pVCaseId: '',
    reasonText: '',
    documentType: '',
    problemEncountered: '',
    problemStartDate: '',
    problemLength: '',
    problemImpact: '',
    proctorReportFlag: true,
    proctorAdvice: '',
    attestationText: '',
    personAccountId: '',
    contactId: '',
  },
  EPA2Submissions: {
    reflectiveStatements: [],
    portfolioEvidence: [],
    canUserAccess: {
      result: null,
      reasons: [],
    },
    canUserSubmit: {
      result: null,
      reasons: [],
    },
    checkExamCreditAndExternalValidator: {
      result: false,
      reasons: [],
    },
  },
  EPA2L7Submissions: {
    projectReportData: [],
    canUserAccess: {
      result: null,
      reasons: [],
    },
    canUserSubmit: {
      result: null,
      reasons: [],
    },
    checkExamCreditAndExternalValidator: {
      result: false,
      reasons: [],
    },
  },
  epa2L4CaseStatusUpdate: {
    statusUpdate: {
      Id: '',
      Status: '',
    },
  },
  epa2L7CaseStatusUpdate: {
    statusUpdate: {
      Id: '',
      Status: '',
    },
  },
  personAccountData: {
    id: '',
    individualId: '',
    aicpaId: '',
    primaryEmail: {
      id: '',
      emailAddress: '',
      emailType: Salesforce.EmailType.PERSONAL,
      isPrimary: true,
    },
    secondaryEmail: {
      id: '',
      emailAddress: '',
      emailType: Salesforce.EmailType.PERSONAL,
      isPrimary: false,
    },
    primaryPhone: {
      id: '',
      phoneNumber: '',
      countryCode: '+1',
      phoneType: Salesforce.PhoneType.WORK,
      isPrimary: true,
    },
    secondaryPhone: {
      id: '',
      phoneNumber: '',
      countryCode: '+1',
      phoneType: Salesforce.PhoneType.MOBILE,
      isPrimary: false,
    },
    firstName: '',
    lastName: '',
    address: {
      id: '',
      firstName: '',
      lastName: '',
      company: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'United States',
      isPrimary: true,
      addressType: '',
    },
    gender: '',
    genderSpecific: '',
    genderConsent: false,
    ethnicity: '',
    ethnicitySpecific: '',
    ethnicityConsent: false,
    birthDate: '',
    linkedinURL: '',
    uniqueLearningNumber: '',
    learningPathway: null,
    personAccountDataUpdateSuccess: false,
    personAccountDataUpdateLoading: false,
    ethicsStatus: MembershipTypes.EthicsStatusEnum.NONE,
    smartystreetsValidation: null,
    isAddressValid: false,
    isSecondaryAddressNeeded: false,
    smartystreetsValidationSkipped: false,
    isAdressValidationLoading: false,
    suggestedAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      county: '',
      state: '',
      zipCode: '',
      country: '',
    },
    isHonorary: false,
    isCPA: false,
    accountNumber: '',
    isADFMigratedUser: false,
  },
  qualificationData: {
    cpaLicenseListData: {
      cpaLicense: [
        {
          id: '',
          licenseNumber: '',
          licenseStatus: '',
          licenseIssueDate: '',
          licenseState: '',
          personAccountId: '',
        },
      ],
      cpaLicenseDataUpdateSuccess: false,
      cpaLicenseDataUpdateLoading: false,
    },
    cpaLicenseData: {
      id: '',
      licenseNumber: '',
      licenseStatus: '',
      licenseIssueDate: '',
      licenseState: '',
      personAccountId: '',
      cpaLicenseDataUpdateSuccess: false,
      cpaLicenseDataUpdateLoading: false,
    },
    candidateData: {
      university: {
        id: '',
        name: '',
        type: '',
        creditHoldStatus: false,
        hasAICPAUid: false,
        AICPAUid: '',
        isFirmBillingEnabled: false,
        billingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: '' },
        shippingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: '' },
        email: '',
      },
      searchResultUniversities: [],
      searchUniversitiesLoading: false,
    },
  },
  employmentData: {
    employers: [],
    newEmployer: {
      personAccountId: '',
      relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
      jobTitle: '',
      organization: {
        id: '',
        name: '',
        type: '',
        creditHoldStatus: false,
        hasAICPAUid: false,
        AICPAUid: '',
        isFirmBillingEnabled: false,
        billingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: 'USA' },
        shippingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: '' },
        phone: '',
        profileEmployerAddress: null,
        profileEmployerPhone: null,
        email: '',
        peerReviewEnrollmentStatus: '',
      },
      dataSharingConsent: true,
    },
    employerAlreadyExists: false,
    employmentDataUpdateSuccess: false,
    employmentDataUpdateLoading: false,
    searchResultOrganizations: null,
    searchOrganizationsByWildcardLoading: false,
    searchResultOrganizationsCities: null,
    searchOrganizationsCitiesLoading: false,
    employmentDataFromPromoFetched: false,
    employmentDataFromPromoFetchSuccess: false,
    smartystreetsValidation: null,
    isAddressValid: false,
    isSecondaryAddressNeeded: false,
    smartystreetsValidationSkipped: false,
    isAdressValidationLoading: false,
    suggestedAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      county: '',
      state: '',
      zipCode: '',
      country: '',
    },
    isEmploymentPageSkipped: false,
    newFlpReseller: {
      personAccountId: '',
      relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
      jobTitle: '',
      organization: {
        id: '',
        name: '',
        type: '',
        creditHoldStatus: false,
        hasAICPAUid: false,
        AICPAUid: '',
        isFirmBillingEnabled: false,
        billingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: 'USA' },
        shippingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: '' },
        phone: '',
        profileEmployerAddress: null,
        profileEmployerPhone: null,
        email: '',
      },
    },
    flpResellerDataFromPromoFetched: false,
    flpResellerDataFromPromoFetchSuccess: false,
    newTrainingProvider: {
      personAccountId: '',
      relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
      jobTitle: '',
      status: '',
      organization: {
        id: '',
        name: '',
        type: '',
        creditHoldStatus: false,
        hasAICPAUid: false,
        AICPAUid: '',
        isFirmBillingEnabled: false,
        billingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: 'USA' },
        shippingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: '' },
        phone: '',
        profileEmployerAddress: null,
        profileEmployerPhone: null,
        email: '',
        tuitionProviderType: '',
      },
      dataSharingConsent: false,
    },
    trainingProviderDataFromPromoFetched: false,
    trainingProviderDataFromPromoFetchSuccess: false,
  },
  application: {
    id: '',
    personAccountId: '',
    applicationProgress: '',
    employmentRole: '',
    status: MembershipTypes.ApplicationStatusEnum.NOT_SUBMITTED,
    applicationPart: {
      id: '',
      welcomeKit: false,
      hearAboutUsMembershipQuestion: '',
      passedCPAExam: false,
      intendedToStudyCPA: false,
      cpaExamDate: '',
      intlAccountingBody: '',
      candidateEmployment: Salesforce.CandidateEmploymentOptions.A_STUDENT,
      passedCPAExamAttestation: false,
      holdCPALicenseAttestation: false,
      heldCPALicenseInPastAttestation: false,
      affiliateReason: '',
      productKeyId: '',
      fcmaDisciplinedAttestation: false,
      fcmaNotConvictedAttestation: false,
      fcmaUndertakingDqAttestation: false,
      fcmaBankruptcyPetitionAttestation: false,
      fcmaUndischargedBankruptAttestation: false,
      fcmaInsolvencyAttestation: false,
    },
  },
  applications: [
    {
      id: '',
      personAccountId: '',
      applicationProgress: '',
      employmentRole: '',
      status: MembershipTypes.ApplicationStatusEnum.NOT_SUBMITTED,
      applicationPart: {
        id: '',
        welcomeKit: false,
        hearAboutUsMembershipQuestion: '',
        passedCPAExam: false,
        intendedToStudyCPA: false,
        cpaExamDate: '',
        intlAccountingBody: '',
        candidateEmployment: Salesforce.CandidateEmploymentOptions.A_STUDENT,
        passedCPAExamAttestation: false,
        holdCPALicenseAttestation: false,
        heldCPALicenseInPastAttestation: false,
        affiliateReason: '',
        productKeyId: '',
        fcmaDisciplinedAttestation: false,
        fcmaNotConvictedAttestation: false,
        fcmaUndertakingDqAttestation: false,
        fcmaBankruptcyPetitionAttestation: false,
        fcmaUndischargedBankruptAttestation: false,
        fcmaInsolvencyAttestation: false,
      },
    },
  ],
  applicationInitialLoad: true,
  attestation: [],
  eventRegistrationData: {
    id: '',
    personAccountId: '',
    firstName: '',
    lastName: '',
    emailContactId: '',
    jobTitle: '',
    employer: '',
    pricePaid: '',
    methodOfPayment: '',
    event: '',
    eventDateAndTime: '',
    eventLocation: '',
    dateOfBooking: '',
  },
  centerMembershipApplication: {
    organization: {
      id: '',
      name: '',
      type: '',
      creditHoldStatus: false,
      hasAICPAUid: false,
      AICPAUid: '',
      isFirmBillingEnabled: false,
      billingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: '' },
      shippingAddress: { addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: '' },
      phone: '',
      profileEmployerAddress: null,
      profileEmployerPhone: null,
      numberOfCpa: '',
      PCAOBNumOfIssuers: '',
      relationshipType: '',
      isPCAOBOrganization: false,
      email: '',
    },
    applicationObject: {
      id: '',
      personAccountId: '',
      applicationProgress: '',
      submitDate: '',
      applicationPart: {
        id: '',
        welcomeKit: false,
        hearAboutUsMembershipQuestion: '',
      },
    },
    accountPersonAccountId: '',
    adminDetail: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
    },
    organizationUpdateSuccess: false,
  },
  profileFetched: false,
  profileFetchedSuccess: false,
  eventDataFetchedSuccess: false,
  createEventRegistrationSuccess: false,
  createEventRegistrationLoading: false,
  hasFirmBillingInvite: false,
  tlw: {
    id: '',
    isTLW: false,
    employmentStatus: '',
    tlwReason: '',
    tlwEndDate: '',
  },
  quoteStatusAndIsMembershipPaidByFirm: null,
  hasFirmBillingRelation: false,
  hasCimaFirmBillingRelation: false,
  hasActiveQuoteFromFirm: false,
  securityQuestion: {
    security: {
      question: '',
      userResponse: '',
      answer: '',
    },
    loading: true,
    error: {},
  },
  studentProgression: {
    exemptionsStatus: null,
    gatewayStatus: null,
    certificates: [],
    qualificationLevels: [],
    upcomingExamBooking: [],
    learningPathwayToSwitch: null,
    isPathwaySwitched: false,
  },
  studentExamSectionResults: {
    examName: '',
    examResultType: '',
    epaDetails: {
      isEpa1: false,
      isEpa2: false,
    },
    publicationStatus: '',
    ratificationStatus: '',
    status: User.StudentExamResultStatus.ERROR,
    overallExamScore: 0,
    distinction: false,
    maxExamScore: 0,
    examDate: '',
    publicationDate: '',
    examCode: '',
    examSectionResults: [],
    receivedDate: '',
    qualificationLevel: '',
  },
  exams: [],
  sfmcLink: '',
  zuoraCutOverDate: '',
  userUnpaidFirmBillingInvoices: [],
  userLocation: {
    country: 'US',
  },
  isUserCountryEmbargoed: false,
  professionalStatus: null,
  yearOfGraduation: '',
  registrationLoading: false,
  currentJourneyLearningPathway: '',
  hasPreferredCurrency: false,
  allExamResults: {
    allExams: {
      results: [],
      loading: false,
    },
    syllabus: {
      results: [],
      loading: false,
    },
  },
};
