import React from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import TruncateMarkup from 'react-truncate-markup'; // requires single child, hence usage of <div>
import { highlight } from 'utils/StringHelpers';
import { ContentTypes } from 'constants/index';
import { ReactComponent as IconVolumeUp } from 'resources/images/ic-volume-up.svg';
import { ReactComponent as IconFileDownload } from 'resources/images/ic-file-download.svg';
import { ReactComponent as IconPlay } from 'resources/images/ic-play-arrow.svg';

interface Props {
  title: string;
  subtitle?: string;
  subtitleLinesCount?: number;
  searchString?: string;
  isContentCard?: boolean;
  testId: string;
  lines?: number;
  contentType?: string;
  isSearchResult?: boolean;
  hasLargeTitleText?: boolean;
  isContribution?: boolean;
  fundName?: string;
  isBgColorDark?: boolean;
}

export const CardTitleSubtitle: React.FC<Props> = ({
  isBgColorDark = false,
  title,
  subtitle = '',
  subtitleLinesCount = 2,
  searchString,
  isContentCard,
  testId,
  lines,
  contentType = '',
  isSearchResult = false,
  hasLargeTitleText = false,
  isContribution = false,
  fundName = '',
}): any => {
  const renderTypeIcon = React.useCallback(
    () =>
      ({
        [ContentTypes.VIDEO]: <StyledIconPlay />,
        [ContentTypes.PODCAST]: <StyledIconVolumeUp />,
        [ContentTypes.DOWNLOAD]: <StyledIconFileDownload />,
      }[contentType]),
    [contentType]
  );

  return (
    <StyledContainer isBgColorDark={isBgColorDark}>
      {title && (
        <CardTitle
          data-testid={testId}
          isContentCard={isContentCard}
          hasLargeTitleText={hasLargeTitleText}
          isBgColorDark={isBgColorDark}
          tabIndex={0}
        >
          {isSearchResult && renderTypeIcon()}
          <TruncateMarkup ellipsis={'…'} lines={lines || 2}>
            <div style={{ display: 'inline' }} className="card-title">
              {/* has to be as inline style as truncate markup otherwise sets display:block */}
              {searchString ? highlight(title, searchString.split(' '), StyledHighlight) : title}
            </div>
          </TruncateMarkup>
        </CardTitle>
      )}
      {subtitle && (
        <CardSubtitle tabIndex={0} isBgColorDark={isBgColorDark}>
          <TruncateMarkup ellipsis={'…'} lines={subtitleLinesCount}>
            <div>{searchString ? highlight(subtitle, searchString.split(' '), StyledHighlight) : subtitle}</div>
          </TruncateMarkup>
        </CardSubtitle>
      )}

      {isContribution && fundName && (
        <CardSubtitle tabIndex={0} isBgColorDark={isBgColorDark}>
          <TruncateMarkup ellipsis={'…'} lines={2}>
            <div>{fundName}</div>
          </TruncateMarkup>
        </CardSubtitle>
      )}
    </StyledContainer>
  );
};

const CardTitle = styled.div<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  margin-bottom: ${props => props.theme.pxToRem(8)};
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};

  font-size: ${props =>
    props.hasLargeTitleText || !props.isContentCard ? props.theme.fontSizes.l : props.theme.fontSizes.m};

  line-height: 1.33;
  display: inline-block;
  align-items: center;
  & > svg {
    flex-shrink: 0;
  }
`;

const CardSubtitle = styled.div<{ isBgColorDark?: boolean }>`
  margin-bottom: ${props => props.theme.pxToRem(12)};
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
`;

const StyledHighlight = styled.span`
  font-weight: bold;
`;

const StyledIconFileDownload = styled(IconFileDownload)`
  position: relative;
  top: ${props => props.theme.pxToRem(4)};
  path {
    fill: ${props => props.theme.colors.neutralBlack};
  }
`;

const StyledIconPlay = styled(IconPlay)`
  position: relative;
  top: ${props => props.theme.pxToRem(4)};
  path {
    fill: ${props => props.theme.colors.neutralBlack};
  }
`;

const StyledIconVolumeUp = styled(IconVolumeUp)`
  position: relative;
  top: ${props => props.theme.pxToRem(4)};
  path {
    fill: ${props => props.theme.colors.neutralBlack};
  }
`;

const StyledContainer = styled.div<{ isBgColorDark?: boolean }>`
  &&&&&&&&&&&&&& {
    color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  }
`;
