import { createSelector } from 'reselect';
import { Invoices } from 'mxp-schemas';
import moment from 'moment-timezone';
import { match as MatchProps } from 'react-router';
import { createMatchSelector } from 'connected-react-router';
import { emptyArray, emptyObject, isEmailFormatValid } from 'utils';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';
export interface SeatManagementEmailRecipient {
  email: string;
  hasErrors: boolean;
  index: number;
}
// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.clientAdmin,
  (clientAdmin: State.ClientAdmin): State.ClientAdmin => clientAdmin
);

const firmInformationSelector = createSelector(
  rootSelector,
  (clientAdmin: State.ClientAdmin): State.ClientFirmInformation => clientAdmin.firmInformation
);

const invoicesSelector = createSelector(
  rootSelector,
  (clientAdmin: State.ClientAdmin): State.Invoices => clientAdmin.invoices
);

const seatManagementSelector = createSelector(
  rootSelector,
  (clientAdmin: State.ClientAdmin): State.SeatManagement => clientAdmin.seatManagement
);

export const dueInvoicesListSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): State.InvoiceTableRow[] | null =>
    invoices.invoicesList?.filter((invoice: State.InvoiceTableRow) => invoice.status !== Invoices.InvoiceStatus.PAID) ||
    []
);

export const paginationSelector = createSelector(
  rootSelector,
  (
    clientAdmin: State.ClientAdmin
  ): {
    [key in Invoices.InvoiceKeys]: State.Pagination;
  } => clientAdmin?.pagination
);

export const paidInvoicesListSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): State.InvoiceTableRow[] | null =>
    invoices.invoicesList?.filter((invoice: State.InvoiceTableRow) => invoice.status === Invoices.InvoiceStatus.PAID) ||
    []
);

export const invoicesListErrorSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): CustomErrors.GraphQLError | null => invoices?.error
);

export const invoicesListFetchedSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): boolean => invoices?.invoicesListFetched
);

export const seatManagementInviteSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): State.AllocatedSeatDynamoDbRecord[] | [] =>
    seatManagement?.seatManagementEmailInvite?.list?.length ? seatManagement.seatManagementEmailInvite.list : []
);

export const productAccessExtensionStatusSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): string => seatManagement?.productAccessExtension.status
);

export const productAccessExtensionIsLoadingSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): boolean => seatManagement?.productAccessExtension.isLoading
);

export const productAccessExtensionDateSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): string => seatManagement?.productAccessExtension.date
);

export const productAccessExtensionUserLengthSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): string => seatManagement?.productAccessExtension.userLength
);

export const productAccessExtensionProductNameSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): string => seatManagement?.productAccessExtension.productName
);

export const productAccessExtensionExistingEndDateSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): string => seatManagement?.productAccessExtension.existingEndDate
);

export const ongoingExtensionModalIsOpenSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): boolean => seatManagement?.productAccessExtension.ongoingExtensionModalIsOpen
);

export const seatManagementIsInviteUpdateSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement) => seatManagement.seatManagementEmailInvite.isInviteUpdate
);

export const serviceContractsListSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): State.ServiceContract[] | [] =>
    seatManagement?.serviceContractsList?.list?.length ? seatManagement.serviceContractsList.list : []
);

export const serviceContractsListErrorSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): CustomErrors.GraphQLError | null => seatManagement?.serviceContractsList.error
);

export const serviceContractsListLoadingSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement): boolean => seatManagement?.serviceContractsList.loading
);

export const serviceContractsActiveInactiveSelector = createSelector(
  serviceContractsListSelector,
  (contracts: State.ServiceContract[]): { active: State.ServiceContract[]; inactive: State.ServiceContract[] } => {
    const now = moment();
    return contracts.reduce(
      (acc: { active: State.ServiceContract[]; inactive: State.ServiceContract[] }, current: State.ServiceContract) => {
        if (now.isAfter(current.endDatetime)) {
          return { ...acc, inactive: [...acc.inactive, current] };
        }
        return { ...acc, active: [...acc.active, current] };
      },
      { active: [], inactive: [] }
    );
  }
);

export const downloadingIdSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): string | null => invoices.downloadingId
);

export const downloadingErrorSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): boolean => invoices.downloadingError
);

export const pageStepSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): string | null => invoices?.payment?.payInvoice?.pageStep || null
);

export const invoiceIdSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): string | null => invoices?.payment?.fullInvoiceDetails?.invoiceDetails?.id || null
);

export const selectedPaymentOptionSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): string | null => invoices?.payment?.payInvoice?.selectedPaymentOption || null
);

export const selectedPaymentAmountOptionSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): string | null => invoices?.payment?.payInvoice?.selectedPaymentAmountOption || null
);

export const isValidAmountCheckLoadingSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): boolean => invoices?.payment?.fullInvoiceDetails?.isValidAmountCheckLoading
);

export const isValidAmountCheckErrorSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): boolean => invoices?.payment?.fullInvoiceDetails?.isValidAmountCheckError
);

export const getInvoiceLoadingSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): boolean | null => invoices?.payment?.fullInvoiceDetails?.loading || null
);

export const fullInvoiceDetailsSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): State.Invoice | null => invoices?.payment?.fullInvoiceDetails?.invoiceDetails || null
);

export const fullCimaInvoiceDetailsSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): State.Invoice[] => invoices?.payment?.fullInvoiceDetails?.cimaInvoiceDetails
);

export const invoicesTotalBalanceSelector = createSelector(
  fullCimaInvoiceDetailsSelector,
  (invoices: State.Invoice[]): number => {
    return invoices.reduce((total: number, invoice: State.Invoice) => {
      return total + invoice.balance;
    }, 0);
  }
);

export const getInvoicePaymentConfirmationResultLoadingSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): boolean | null => invoices?.payment?.payInvoice?.loading || null
);

export const invoicePaymentConfirmationSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): State.InvoicePaymentConfirmation | null => {
    const invoicePaymentResult = invoices?.payment?.payInvoice?.invoicePaymentResult;

    if (!invoicePaymentResult) {
      return null;
    }

    const totalInvoiceAmount = invoicePaymentResult.amount;
    const currentTotalInvoiceBalance = invoicePaymentResult.balance;
    const invoiceBalance = currentTotalInvoiceBalance === 0 ? totalInvoiceAmount : currentTotalInvoiceBalance;
    const paidAmount =
      currentTotalInvoiceBalance === 0
        ? totalInvoiceAmount
        : totalInvoiceAmount && invoiceBalance
        ? totalInvoiceAmount - invoiceBalance
        : 0;
    const remainingBalance = totalInvoiceAmount && paidAmount ? totalInvoiceAmount - paidAmount : 0;

    const invoicePaymentResultTransformed = {
      id: invoicePaymentResult.id,
      invoiceDate: invoicePaymentResult.invoiceDate,
      dueDate: invoicePaymentResult.dueDate,
      amount: invoicePaymentResult.amount,
      balance: invoicePaymentResult.balance,
      invoiceNumber: invoicePaymentResult.invoiceNumber,
      accountId: invoicePaymentResult.accountId,
      invoiceBalance,
      paidAmount,
      remainingBalance,
      paymentDate: invoicePaymentResult.paymentDate,
    };

    return invoicePaymentResultTransformed;
  }
);

export const payInvoicesSelector = createSelector(
  invoicesSelector,
  (invoices: State.Invoices): State.PayInvoicesResult => invoices.payment.payInvoices
);

export const seatManagementRecipientsSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement) => seatManagement.seatManagementRecipientsEmailsList
);

export const seatManagementDeallocationSelector = createSelector(
  seatManagementSelector,
  (seatManagement: State.SeatManagement) => seatManagement.seatManagementDeallocation
);

const seatManagementRecipientsEmailsSelector = createSelector(
  [seatManagementRecipientsSelector],
  seatManagementList => seatManagementList.emails
);

export const seatManagementContractToAssign = createSelector(
  [seatManagementRecipientsSelector],
  seatManagementList => seatManagementList.contractToAssignSeatFor
);

export const seatManagementAssignLoading = createSelector(
  [seatManagementRecipientsSelector],
  seatManagementList => seatManagementList.loadingAssignment
);

export const seatManagementAssignErrorSelector = createSelector(
  [seatManagementRecipientsSelector],
  seatManagementList => Boolean(seatManagementList.error)
);

export const seatManagementLimitSelector = createSelector([seatManagementContractToAssign], contractToAssignSeatFor =>
  contractToAssignSeatFor
    ? contractToAssignSeatFor.totalQuantity -
      Number(contractToAssignSeatFor.cancelledQuantity) -
      contractToAssignSeatFor.allocatedQuantity
    : 0
);

export const seatManagementInviteModalVisible = createSelector([seatManagementRecipientsSelector], seatManagementList =>
  Boolean(seatManagementList.contractToAssignSeatFor && seatManagementList.modalVisible)
);

export const seatManagementListErrorsWarningsSelector = createSelector(
  [seatManagementRecipientsEmailsSelector, seatManagementLimitSelector],
  (
    seatManagementList: string[],
    limit: number
  ): {
    errors: string[];
    warnings: string[];
    emailList: SeatManagementEmailRecipient[];
  } => {
    const errors = new Set<string>();
    const warnings = new Set<string>();
    const emailList = seatManagementList.map((email, index): SeatManagementEmailRecipient => {
      let hasErrors = false;
      if (!isEmailFormatValid(email)) {
        errors.add('The email format does not look right. Please check for errors.');
        hasErrors = true;
      }
      // Check for duplicates
      if (seatManagementList.slice(index + 1).includes(email) || seatManagementList.slice(0, index).includes(email)) {
        errors.add('The same email address has been entered twice. Please delete one of them.');
        hasErrors = true;
      }
      return {
        email,
        hasErrors,
        index,
      };
    });
    if (seatManagementList.length >= limit) {
      warnings.add(`${seatManagementList.length} users will receive this email. You have reached your seat limits`);
    }
    return { errors: [...errors], warnings: [...warnings], emailList };
  }
);

export const seatManagementAssignmentResultsSelector = createSelector(
  [seatManagementRecipientsSelector],
  seatManagementRecipients => seatManagementRecipients.assignmentResult
);

export const seatManagementAssignmentResultsExistSelector = createSelector(
  [seatManagementAssignmentResultsSelector],
  assignmentResult => Boolean(assignmentResult)
);

export const deallocationModalVisibilitySelector = createSelector(
  [seatManagementDeallocationSelector],
  seatManagementDeallocation => Boolean(seatManagementDeallocation.modalVisible)
);

export const deallocationLoadingSelector = createSelector(
  [seatManagementDeallocationSelector],
  seatManagementDeallocation => seatManagementDeallocation.deallocateLoading
);

export const selectedSeatsToDeallocateSelector = createSelector(
  [seatManagementDeallocationSelector],
  (seatManagementDeallocation): State.SeatsToDeallocateInfo => {
    const { selectedSeatsToDeallocate } = seatManagementDeallocation;
    const contract = selectedSeatsToDeallocate?.contract || null;
    const lineItemsToDeallocate = selectedSeatsToDeallocate?.lineItemsToDeallocate || null;
    const pendingList = selectedSeatsToDeallocate?.pendingList || undefined;
    return { contract, lineItemsToDeallocate, pendingList };
  }
);

export const deallocateSeatsErrorSelector = createSelector(
  [seatManagementDeallocationSelector],
  seatManagementDeallocation => seatManagementDeallocation.error
);

export const invoiceSelector = createSelector(
  [invoicesSelector, downloadingIdSelector],
  (invoices: State.Invoices, invoiceId: string | null): State.InvoiceTableRow | null =>
    invoices?.invoicesList.find((item: State.InvoiceTableRow) => item.id === invoiceId) || null
);

export const sendEmailPrintResultSelector = createSelector(
  [invoicesSelector],
  (invoices: State.Invoices) => invoices.sendEmailPrint.result
);
export const sendEmailPrintLoadingSelector = createSelector(
  [invoicesSelector],
  (invoices: State.Invoices) => invoices.sendEmailPrint.loading
);

export const clientOrganizationsSelector = createSelector(
  firmInformationSelector,
  (firmInformation: State.ClientFirmInformation): any => firmInformation.organizations || emptyObject
);

export const clientOrganizationsListSelector = createSelector(
  clientOrganizationsSelector,
  (clientOrganizations: any): State.Organization[] => clientOrganizations.list || emptyArray
);

export const clientOrganizationsIsFetchedSelector = createSelector(
  clientOrganizationsSelector,
  (clientOrganizations: any): boolean => clientOrganizations.isFetched
);

export const clientOrganizationAdminsSelector = createSelector(
  firmInformationSelector,
  (firmInformation: State.ClientFirmInformation): any => firmInformation.organizationAdmins || emptyObject
);

export const clientOrganizationAdminsListSelector = createSelector(
  clientOrganizationAdminsSelector,
  (clientOrganizationAdmins: any): State.Contact[][] => clientOrganizationAdmins.list || emptyArray
);

export const clientOrganizationAdminsIsFetchedSelector = createSelector(
  clientOrganizationAdminsSelector,
  (clientOrganizationAdmins: any): boolean => clientOrganizationAdmins.isFetched
);

interface B2BFirmInformationParams {
  orgId: string;
}

const b2bFirmInformationMatchSelector: any = createMatchSelector(getPath(Routes.ORG_FIRM_INFORMATION));

export const selectedClientOrganizationIdSelector = createSelector(
  [b2bFirmInformationMatchSelector],
  (match: MatchProps<B2BFirmInformationParams>): string => match?.params?.orgId || ''
);

export const selectedClientOrganizationSelector = createSelector(
  [selectedClientOrganizationIdSelector, clientOrganizationsListSelector],
  (orgId: string, clientOrganizations: State.Organization[]): State.Organization | null =>
    clientOrganizations.find((clientOrganization: State.Organization) => clientOrganization.id === orgId) || null
);

const tuitionProviderAdminMatchSelector: any = createMatchSelector(getPath(Routes.TUITION_PROVIDER_ROOT));

export const selectedClientTuitionProviderIdSelector = createSelector(
  [tuitionProviderAdminMatchSelector],
  (match: MatchProps<{ orgId?: string }>): string => match?.params?.orgId || ''
);

export const selectedClientTuitionProviderSelector = createSelector(
  [selectedClientTuitionProviderIdSelector, clientOrganizationsListSelector],
  (orgId: string, clientOrganizations: State.Organization[]): State.Organization | null =>
    clientOrganizations.find((clientOrganization: State.Organization) => clientOrganization.id === orgId) || null
);
