import React from 'react';
import styled from 'styled-components';
import { Link } from 'components/atoms';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { Product } from 'mxp-schemas';

interface Props {
  isBold?: boolean | undefined;
  standingOrderDiscount?: Product.StandingOrderDiscount;
}

export const StandingOrderNotification: React.FC<Props> = ({ isBold, standingOrderDiscount }) => {
  if (!standingOrderDiscount || (!standingOrderDiscount.new && !standingOrderDiscount.recurrent)) return null;
  return (
    <StyledWrapper isBold={isBold}>
      <StyledText isBold={isBold}>Standing order option:</StyledText>&nbsp;
      <Link testId="standing-order-link" to={getPath(Routes.STANDING_ORDERS)} target="blank">
        Save {standingOrderDiscount.new ? `${standingOrderDiscount.new}% now` : null}
        {standingOrderDiscount.new && standingOrderDiscount.recurrent ? ` and ` : null}
        {standingOrderDiscount.recurrent ? `${standingOrderDiscount.recurrent}% on future shipments` : null}
      </Link>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<{ isBold?: boolean }>`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xs};
  margin: ${props => (props.isBold ? '1.25rem 0' : '0')};
`;

const StyledText = styled.p<{ isBold?: boolean }>`
  font-weight: ${props => (props.isBold ? 'normal' : '300')};
  display: inline-block;
  margin: 0;
`;
