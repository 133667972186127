import React from 'react';
import styled from 'styled-components';

import { NotificationBanner, NotificationBannerEnums } from 'components/atoms/NotificationBanner/NotificationBanner';

import { ReactComponent as IconError } from 'resources/images/ic-error.svg';

interface Props {
  label: string;
}

export const ErrorNotification: React.FC<Props> = ({ label }) => {
  return (
    <StyledNotificationBanner
      variant={NotificationBannerEnums.variant.red}
      testId="notification-banner"
      childrenTestId="notification-children"
      icon={<StyledIconError />}
    >
      {label}
    </StyledNotificationBanner>
  );
};

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(25)};
`;

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;
