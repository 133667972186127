import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { GET_POPULAR_SEARCHES } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';

// ------------------------------------
// Constants
// ------------------------------------

const initialState: State.PopularSearches = {
  popularSearches: {
    links: [],
    name: '',
  },
};

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.popularSearches,
  (popularSearches: State.PopularSearches): State.PopularSearches => popularSearches
);

export const popularSearchesSelector = createSelector(rootSelector, (popularSearches: State.PopularSearches):
  | State.InternalLink[]
  | null => (popularSearches ? popularSearches.popularSearches.links : null));

// ------------------------------------
// Actions
// ------------------------------------

export enum PopularSearchesActionNames {
  GET_POPULAR_SEARCHES = 'search/GET_POPULAR_SEARCHES',
}

export const getPopularSearches: any = createAction(PopularSearchesActionNames.GET_POPULAR_SEARCHES, () => () =>
  request(GET_POPULAR_SEARCHES)
);

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [getPopularSearches]: {
    next: (state: State.PopularSearches, action: any): State.PopularSearches => {
      const response = action.payload;
      const popularSearches = response && response.popularSearches;

      return {
        ...state,
        popularSearches: popularSearches || initialState.popularSearches,
      };
    },
  },
};

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions(ACTION_HANDLERS, initialState);
