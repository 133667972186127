import React from 'react';
import { ContentfulHelpers } from 'mxp-utils';
import { RenderFeaturedContentBlock } from 'components/pages/PageToolkit/RenderFeaturedContentBlock';
import { RenderPromotionalComponent } from 'components/pages/PageToolkit/RenderPromotionalComponent';
import { RenderToolkitPageBlock } from 'components/pages/PageToolkit/RenderToolkitPageBlock';
import { RenderList } from 'components/pages/PageToolkit/RenderList';

interface BlockRenderToolkitPage {
  block: State.ToolkitMainColumn | State.ToolkitLeftColumn;
  idx: number;
  showDivider?: boolean;
  isFullWidth?: boolean;
  topicSlug: string;
  topicName: string;
  subtopicSlug: string;
  key: string;
}

export const BlockRendererToolkitBlock: React.FC<BlockRenderToolkitPage> = ({
  block,
  idx,
  showDivider,
  isFullWidth,
  topicSlug,
  topicName,
  subtopicSlug,
  key,
}) => {
  const contentType = Array.isArray(block) ? block[0].contentType : block.contentType;
  switch (contentType) {
    case ContentfulHelpers.CONTENT_TYPES.TOOLKIT_PAGE_BLOCK:
      return (
        <RenderToolkitPageBlock
          key={`toolkit-page-block-${idx}`}
          item={block as State.ToolkitPageBlock}
          idx={idx}
          showDivider={showDivider}
        />
      );
    case ContentfulHelpers.CONTENT_TYPES.FEATURED_CONTENT_BLOCK:
      return (
        <RenderFeaturedContentBlock
          key={`main-featured-section-${idx}`}
          item={block as State.FeaturedContentBlock}
          idx={idx}
          topicSlug={topicSlug}
          topicName={topicName}
          subtopicSlug={subtopicSlug}
          showDivider={showDivider}
        />
      );
    case ContentfulHelpers.CONTENT_TYPES.PROMOTIONAL_COMPONENT_BLOCK:
      return (
        <RenderPromotionalComponent
          item={block}
          showDivider={showDivider}
          idx={idx}
          isFullWidth={isFullWidth}
          key={`${key}-${idx}`}
          testId={`${key}-${idx}`}
        />
      );
    case ContentfulHelpers.CONTENT_TYPES.LISTS:
      return <RenderList item={block} idx={idx} key={`left-link-section-${idx}`} />;
    default:
      return null;
  }
};
