import React, { useState } from 'react';
import {
  Divider,
  GlobalModalDimmerStyle,
  Input,
  Modal,
  NotificationBanner,
  NotificationBannerEnums,
  OnlyDesktop,
  OnlyMobile,
  Button,
  ButtonEnums,
  File,
  AcceptedFiles,
} from 'components/atoms';
import { CimaFileUploadTable } from 'components/molecules/CimaFileUploadTable/CimaFileUploadTable';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';

import { SeatManagementEmailRecipient } from 'modules/clientAdmin/selectors';
import { ReactComponent as IconEmail } from 'resources/images/ic-email.svg';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { TextArea } from 'semantic-ui-react';
import { ServiceContract } from 'mxp-schemas/src/types/seatManagement';
import { EVENT_CLICK, handleEvent } from 'utils/Analytics';

enum ModalState {
  Initial,
  Loading,
  Failure,
  Success,
}
interface Props {
  visible: boolean;
  limit: number;
  errors: string[];
  warnings: string[];
  emailList: SeatManagementEmailRecipient[];
  removeEmailFromList: (index: number) => void;
  addEmailToList: (email: string) => void;
  serviceContractToAssign?: ServiceContract;
  sendEmails: (serviceContractsIds: string[], endUserEmails: string[]) => void;
  hide: (clearModule: boolean) => void;
  seatAssignLoading: boolean;
  seatSuccessfullyAssigned: boolean;
  seatManagementAssignError: boolean;
}

const INITIAL_VALUE = 'INITIAL_VALUE';
const PLACEHOLDER_VALUE = 'Please use commas to separate email addresses.';

export const EmailInviteForm: React.FC<Props> = ({
  emailList,
  removeEmailFromList,
  addEmailToList,
  errors,
  warnings,
  limit,
  visible,
  hide,
  serviceContractToAssign,
  sendEmails,
  seatAssignLoading,
  seatSuccessfullyAssigned,
  seatManagementAssignError,
}) => {
  let state = ModalState.Initial;
  if (seatAssignLoading) {
    state = ModalState.Loading;
  } else if (seatManagementAssignError) {
    state = ModalState.Failure;
  } else if (seatSuccessfullyAssigned) {
    state = ModalState.Success;
  }
  const [headEmail, setHeadEmail] = useState(INITIAL_VALUE);
  const initialCSVEmails: string[] = [];
  const [csvEmails, setCsvEmails] = useState(initialCSVEmails);
  const [isShowExceedingBanner, setIsShowExceedingBanner] = useState(false);
  const isExceedingAllocationSeatLimit = isShowExceedingBanner;

  const emailHeadInputRef = React.createRef() as any;

  const hasEmailList = Boolean(emailList?.length);

  const confirmNodeTxt = state === ModalState.Success ? `Back to ${'Seat Management'}` : 'Send Invitation';

  const exceededMsg = `You have exceeded the limit for seats for this product. Please check number of seats available for this product upload the file again with the correct number of users.`;

  const handleAddEmail = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (['Enter', 'Tab', ',', ' ', ';', 'Escape'].includes(e.key) || e.type === 'blur') {
        e.preventDefault();

        const email = e.currentTarget.value.trim();
        e.currentTarget.value = '';
        if (email) {
          setIsShowExceedingBanner(false);
          addEmailToList(email);
          setHeadEmail('');
        }
      }
    },
    [addEmailToList, setHeadEmail]
  );

  const downloadTemplate = () => {
    window.open('/resources/download/seat-management-allocation-template');
  };

  const deleteHandler = () => {
    emailList.forEach(() => {
      removeEmailFromList(0);
    });

    emailList.forEach((recipient: SeatManagementEmailRecipient) => {
      if (!csvEmails.includes(recipient.email)) {
        addEmailToList(recipient.email);
      }
    });

    setIsShowExceedingBanner(false);
    setCsvEmails([]);
    setFileUploadedList([]);
  };

  const handleInputAreaClick = React.useCallback(() => {
    if (emailHeadInputRef?.current) {
      emailHeadInputRef.current.focus();
    }
  }, [emailHeadInputRef]);

  const handleExitClicked = React.useCallback(() => hide(true), [hide]);
  const handleSendEmailsClick = React.useCallback(() => {
    if (state === ModalState.Success) {
      hide(false);
    } else if (serviceContractToAssign) {
      const allEmails: string[] = emailList.map(emailListItem => emailListItem.email).concat(csvEmails);
      const uniqueEmails: any[] = [...new Set(allEmails)];
      sendEmails([serviceContractToAssign.id], uniqueEmails);
      handleEvent({ clickValue: `button:b2b-seat-management:int:modal-complete-${confirmNodeTxt}` }, EVENT_CLICK);
    }
  }, [hide, serviceContractToAssign, emailList, sendEmails, state, confirmNodeTxt, csvEmails]);

  // handle switch focus on emailList changes from text area to normal input (only used on mobile)
  React.useEffect(() => {
    if (emailHeadInputRef?.current) {
      emailHeadInputRef.current.focus();
    }
  }, [emailHeadInputRef, emailList]);

  React.useEffect(() => {
    if (visible) {
      setCsvEmails([]);
      setFileUploadedList([]);
      setIsShowExceedingBanner(false);
    }
  }, [visible]);

  const csvEmailsList: string[] = [];

  const [fileUploadedList, setFileUploadedList]: any = useState([]);
  const getUploadedFiles = (list: any) => {
    setFileUploadedList(list);
    if (list.length) {
      const reader = new FileReader();
      reader.onload = async e => {
        const lines: string[] = (e?.target?.result as string).split(/\r?\n/);

        const csvRecords = lines.slice(1, -1); // Remove first and last element in the array, The first element is header and last element is string comma.

        if (csvRecords?.length) {
          const csvEmailCount: number = csvRecords?.length;
          const currentEmailCount: number = emailList?.length;
          const totalEmails: number = currentEmailCount + csvEmailCount;

          if (totalEmails > limit) {
            setIsShowExceedingBanner(true);
          }

          // Prevent the exceeding limit of seat allocation invite email
          csvRecords?.slice(0, Number(limit) - currentEmailCount).map((emails: string) => {
            const email = emails.split(',');
            if (email[1]) csvEmailsList.push(email[1]);
            return addEmailToList(email[1]);
          });
          setCsvEmails(csvEmailsList);
        }
      };
      reader.readAsText(list[0].file);
    }
  };

  const onHeadEmailChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHeadEmail(event.target.value);
  }, []);

  const confirmNode =
    (emailList?.length || fileUploadedList?.length) && state !== ModalState.Failure ? (
      <Button
        size={ButtonEnums.sizes.medium}
        testId="send-invitations"
        disabled={seatAssignLoading || !Boolean((emailList.length || fileUploadedList?.length) && !errors.length)}
        variant={ButtonEnums.variants.primary}
        onClick={handleSendEmailsClick}
        loading={seatAssignLoading}
      >
        {confirmNodeTxt}
      </Button>
    ) : null;

  return (
    <>
      <GlobalModalDimmerStyle />
      <StyledModal
        closeOnDimmerClick={false}
        open={visible}
        onClose={handleExitClicked}
        centered
        icon={<IconEmail />}
        showCloseCross
        heading={
          state === ModalState.Failure ? (
            <>An error occurred!</>
          ) : state === ModalState.Success ? (
            <>Your email has been sent!</>
          ) : (
            <>
              Please enter up to <b>{limit} Email Addresses</b>
            </>
          )
        }
        confirmNode={confirmNode}
        success={state === ModalState.Success}
      >
        {state === ModalState.Failure ? (
          <FlexWrapMain>Please try again later.</FlexWrapMain>
        ) : state === ModalState.Success ? (
          <FlexWrapMainStyled>
            Your seats will be assigned once the user accesses the link sent via email to them.
          </FlexWrapMainStyled>
        ) : (
          <>
            <FlexWrapMain onClick={handleInputAreaClick}>
              <ModalContent>To: </ModalContent>
              <FlexWrap>
                {emailList.map(emailListItem => (
                  <EmailBubble
                    hasErrors={emailListItem.hasErrors}
                    email={emailListItem.email}
                    index={emailListItem.index}
                    handleRemove={removeEmailFromList}
                    handleEdit={setHeadEmail}
                    key={emailListItem.index}
                  />
                ))}
                <InputHeadField
                  handleAddEmail={handleAddEmail}
                  value={headEmail}
                  handleChange={onHeadEmailChange}
                  inputRef={emailHeadInputRef}
                  hasEmailList={hasEmailList}
                />
              </FlexWrap>
            </FlexWrapMain>
            {!hasEmailList && <StyledDivider />}
            <Banners>
              {isExceedingAllocationSeatLimit && (
                <StyledNotificationBanner
                  variant={NotificationBannerEnums.variant.red}
                  testId="checkout--warning"
                  childrenTestId="checkout-warning--text"
                  icon={<StyledIconError />}
                  key={exceededMsg}
                >
                  <>{exceededMsg}</>
                </StyledNotificationBanner>
              )}
              {errors.map(error => (
                <StyledNotificationBanner
                  variant={NotificationBannerEnums.variant.red}
                  testId="checkout--warning"
                  childrenTestId="checkout-warning--text"
                  icon={<StyledIconError />}
                  key={error}
                >
                  <>{error}</>
                </StyledNotificationBanner>
              ))}

              {warnings.map(warning => (
                <StyledNotificationBanner
                  variant={NotificationBannerEnums.variant.blue}
                  testId="checkout--warning"
                  childrenTestId="checkout-warning--text"
                  icon={<StyledIconError />}
                  key={warning}
                >
                  <>{warning}</>
                </StyledNotificationBanner>
              ))}
            </Banners>
            <StyledParagraphDrop>You may also drop in a .csv file</StyledParagraphDrop>
            <StyledParagraphDownload>
              <StyledButtonLink
                size={ButtonEnums.sizes.medium}
                variant={ButtonEnums.variants.link}
                testId={`download-template`}
                onClick={downloadTemplate}
              >
                Download Template
              </StyledButtonLink>
            </StyledParagraphDownload>
            {fileUploadedList.map((file: any, id: string) => {
              return (
                <CimaFileUploadTable
                  key={id}
                  title="Your uploaded documents:"
                  fileName={file.name}
                  fileType={file.file.type}
                  isOpenRemoveModal={deleteHandler}
                />
              );
            })}
            {/* File format to be uploaded must be a .CSV no larger than 4MB */}
            {!fileUploadedList.length && (
              <FileUpload
                customFileType={[AcceptedFiles.csv]}
                filesAccepted={[AcceptedFiles.csv, AcceptedFiles.xls]}
                maxFiles={1}
                isDocumentUpload={true}
                returnFileItems={getUploadedFiles}
                fileSizeLimit={4}
              />
            )}
          </>
        )}
      </StyledModal>
    </>
  );
};

interface EmailBubble {
  email: string;
  index: number;
  handleRemove: (index: number) => void;
  handleEdit: (value: string) => void;
  hasErrors: boolean;
}

const EmailBubble: React.FC<EmailBubble> = ({ email, index, handleRemove, handleEdit, hasErrors }) => {
  const removeEmail = React.useCallback(() => {
    handleRemove(index);
  }, [handleRemove, index]);

  const editEmail = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const emailAddress = (event.target as HTMLAnchorElement)?.textContent;
      if (emailAddress) {
        handleEdit(emailAddress);
        handleRemove(index);
      }
    },
    [handleEdit, handleRemove, index]
  );

  return (
    <Bubble key={email} hasError={hasErrors}>
      <BubbleText onClick={editEmail}>{email}</BubbleText>
      <CloseButton onClick={removeEmail} data-testid={`remove-email-${email}`}>
        <StyledCloseIcon />
      </CloseButton>
    </Bubble>
  );
};

interface InputHeadFieldProps {
  handleAddEmail: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: any) => void;
  hasEmailList: boolean;
  value: string;
  inputRef: any;
}

const InputHeadField: React.FC<InputHeadFieldProps> = ({
  handleAddEmail,
  handleChange,
  value,
  inputRef,
  hasEmailList,
}) => {
  const isInitial = value === INITIAL_VALUE;

  const charForWidthCalc = isInitial ? PLACEHOLDER_VALUE.length + 6 : Math.max(value.length, 4) || 1;

  const valueToDisplay = isInitial ? '' : value;
  const placeholderToDisplay = isInitial ? PLACEHOLDER_VALUE : '';

  return (
    <>
      <OnlyMobileStyled>
        {/*// textArea is used only to display placeholder on mobile */}
        {isInitial || !hasEmailList ? (
          <TextAreaStyled
            placeholder={placeholderToDisplay}
            value={valueToDisplay}
            type="email"
            onKeyDown={handleAddEmail}
            onChange={handleChange}
            onBlur={handleAddEmail}
            testId={'email-input'}
            rows={2}
            width={'100%'}
          />
        ) : (
          <StyledInput
            charNum={charForWidthCalc}
            value={valueToDisplay}
            type="email"
            onKeyDown={handleAddEmail}
            onChange={handleChange}
            onBlur={handleAddEmail}
            testId={'email-input'}
            inputRef={inputRef}
            placeholder={placeholderToDisplay}
          />
        )}
      </OnlyMobileStyled>

      <OnlyDesktop>
        <StyledInput
          charNum={charForWidthCalc}
          value={valueToDisplay}
          type="email"
          onKeyDown={handleAddEmail}
          onChange={handleChange}
          onBlur={handleAddEmail}
          testId={'email-input'}
          inputRef={inputRef}
          placeholder={placeholderToDisplay}
        />
      </OnlyDesktop>
    </>
  );
};

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  align-self: flex-start;
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(24)};
`;

const OnlyMobileStyled = styled(OnlyMobile)`
  width: 100%;
`;

const TextAreaStyled = styled(TextArea)`
  width: 100%;
`;

const StyledModal = styled(Modal)<ThemedStyledProps<{ success: boolean }, DefaultTheme>>`
  max-width: ${props => props.theme.pxToRem(790)};
  &&&&&&& {
    > .content {
      padding-bottom: 0 !important;
      padding-right: ${props => props.theme.pxToRem(20)} !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-right: 0 !important;
      }
    }
    > .header {

      padding-right: ${props => props.theme.pxToRem(20)} !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-right: 0 !important;
      }
    }

    > .actions {
      border-top:${props => props.success && 'none'};
    }
    // 20 on right and top to fit close in the corner
    padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} ${props =>
  props.theme.pxToRem(40)} ${props => props.theme.pxToRem(40)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-left: ${props => props.theme.pxToRem(20)};
    }
  }
  }
`;

const StyledDivider = styled(Divider)`
  &&&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(-16)};
  }
`;

const StyledInput = styled(Input)<ThemedStyledProps<{ charNum: number }, DefaultTheme>>`
  &&&& > input {
    :focus {
      border: none;
    }
    height: ${props => props.theme.pxToRem(20)};
    border: none;

    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.colors.neutralGrey8};
    line-height: ${props => props.theme.pxToRem(24)};
    padding: 0;
    width: ${props => props.charNum}ch;

    :-ms-input-placeholder {
      color: ${props => props.theme.colors.neutralGrey8} !important;
      font-weight: ${props => props.theme.fontWeights.light} !important;
      font-size: ${props => props.theme.fontSizes.m} !important;
    }

    ::-ms-input-placeholder {
      color: ${props => props.theme.colors.neutralGrey8} !important;
      font-weight: ${props => props.theme.fontWeights.light} !important;
      font-size: ${props => props.theme.fontSizes.m} !important;
    }

    ::placeholder {
      color: ${props => props.theme.colors.neutralGrey8} !important;
      font-weight: ${props => props.theme.fontWeights.light} !important;
      font-size: ${props => props.theme.fontSizes.m} !important;
    }
  }

  margin-top: ${props => props.theme.pxToRem(3)};
`;

const FlexWrapMain = styled.div`
  display: flex;
  max-width: ${props => props.theme.pxToRem(521)};
  margin: ${props => props.theme.pxToRem(46)} auto 0;
`;

const FlexWrapMainStyled = styled(FlexWrapMain)`
  max-width: ${props => props.theme.pxToRem(361)};
  font-size: ${props => props.theme.fontSizes.s};
  text-align: center;
`;

const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: ${props => props.theme.pxToRem(2)};
  width: 100%;
`;

const Banners = styled.div`
  margin-top: ${props => props.theme.pxToRem(18)};
`;

const ModalContent = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-right: ${props => props.theme.pxToRem(4)};
`;

const BubbleText = styled.div`
  margin-top: ${props => props.theme.pxToRem(2)};
`;

const Bubble = styled.div<ThemedStyledProps<{ hasError: boolean }, DefaultTheme>>`
  display: flex;
  height: ${props => props.theme.pxToRem(25)};
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  border-radius: ${props => props.theme.pxToRem(12.5)};
  background-color: ${props => (props.hasError ? 'rgba(240, 27, 41, 0.1)' : props.theme.colors.neutralGrey2)};
  margin-right: ${props => props.theme.pxToRem(10)};
  margin-bottom: ${props => props.theme.pxToRem(8)};
  padding: 0 ${props => props.theme.pxToRem(8)} 0 ${props => props.theme.pxToRem(13)};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(22)};
  height: ${props => props.theme.pxToRem(22)};
  margin-left: ${props => props.theme.pxToRem(8)};
  margin-bottom: 0;
  margin-top: ${props => props.theme.pxToRem(1)};
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.neutralGrey4};
  width: ${props => props.theme.pxToRem(22)};
  height: ${props => props.theme.pxToRem(22)};
`;

const FileUpload = styled(File)`
  &&&&&& {
    margin-top: 0px;
    div {
      left: 0;
      span {
        font-size: ${props => props.theme.pxToRem(16)};
      }
    }
  }
`;

const StyledParagraphDrop = styled.p`
  margin-top: 55px;
  text-align: left;
  font-size: ${props => props.theme.pxToRem(16)};
`;

const StyledParagraphDownload = styled.p`
  text-align: left;
  margin-bottom: 0px;
`;

const StyledButtonLink = styled(Button)`
  &&&&&&&& {
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(16)} ${props.theme.pxToRem(20)} 0`};
    margin: 0 ${props => `${props.theme.pxToRem(5)}`};
    display: inline;
    font-size: ${props => props.theme.fontSizes.s};
  }
`;
