import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TabFirmInfoBasicCompanyDetailsContainer } from 'components/App/lazy-imports';
import { getPath } from 'utils';
import { compareTabWithPath } from 'utils/routes';
import { Routes } from 'constants/index';

export const PageFirmInfo: React.FC<RouteComponentProps> = ({ location }) => {
  const handleCompareTabWithPath = React.useCallback(
    (comparison: string) => compareTabWithPath(location.pathname, comparison),
    [location.pathname]
  );

  return (
    <>
      {handleCompareTabWithPath(getPath(Routes.FIRM_INFO_TAB_BASIC_COMPANY_DETAILS)) && (
        <TabFirmInfoBasicCompanyDetailsContainer />
      )}
    </>
  );
};
