import React from 'react';
import styled from 'styled-components';
import { ContentCardList } from 'components/organisms/ContentCardList';
import { StyledDivider } from 'components/pages/PageToolkit/StyledDivider';
import { ContentfulHelpers } from 'mxp-utils';
import { useSelector } from 'react-redux';
import { staticLandingPageContentTypeSlugSelector } from 'modules/staticLandingPagesContent/selectors';

interface Props {
  item: State.FeaturedContentBlock;
  idx: number;
  topicSlug: string;
  topicName: string;
  subtopicSlug: string;
  showDivider?: boolean;
}

export const RenderFeaturedContentBlock: React.FC<Props> = React.memo(
  ({ item, idx, topicSlug, topicName, subtopicSlug, showDivider = false }) => {
    const contentTypeSlug = useSelector(staticLandingPageContentTypeSlugSelector);
    const isCimaMipPropPage = ContentfulHelpers.isCimaMipContentType(contentTypeSlug);
    const isFCMAContentType = ContentfulHelpers.isFCMAContentType(contentTypeSlug);

    if (!item.contentList) {
      return null;
    }
    return (
      <StyledContentContainer
        key={`featured-content-${idx}`}
        className={isCimaMipPropPage || isFCMAContentType ? 'cimaFeaturedContent' : ''}
      >
        <StyledMargin>
          <ContentCardList
            contentList={item.contentList}
            topicSlug={topicSlug}
            topicName={topicName}
            subtopicSlug={subtopicSlug}
            customHeadline={item.title}
            hideMobileLink
            customDescription={item.contentDescription}
          />
        </StyledMargin>
        {!isCimaMipPropPage && !isFCMAContentType && <>{showDivider && <StyledDivider />}</>}
      </StyledContentContainer>
    );
  }
);

const StyledContentContainer = styled.div`
  &.cimaFeaturedContent {
    box-sizing: border-box;
    background-image: linear-gradient(
      65deg,
      ${props => props.theme.colors.primaryPurple},
      ${props => props.theme.colors.primaryLightPurple} 99%
    );
    padding: ${props => props.theme.pxToRem(112)} 0 ${props => props.theme.pxToRem(112)}!important;

    > section {
      margin: 0 auto;
      box-sizing: border-box;
      width: ${props => props.theme.pxToRem(808)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 90%;
      }
    }
  }

  & > section {
    margin: 0;
    border-bottom: none;
  }
`;

const StyledMargin = styled.div`
  margin: 2% 7%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 2%;
  }
`;
