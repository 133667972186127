// ------------------------------------
// Constants
// ------------------------------------

import { Mip } from 'mxp-schemas';

export enum amlActionNames {
  IS_LOADING = 'aml/LOADING',
  CREATE_AML = 'createAML/CREATE_AML',
  GET_AML = 'getAML/GET_AML',
  IS_RENEWAL = 'aml/ISRENEWAL',
}
export const directorPartner: Mip.DirectorPartner = {
  id: '',
  isDeleted: false,
  directorName: '',
  regulatedServices: '',
  theSupervisor: '',
};

export const beneficialOwnerDetails: Mip.BeneficialOwnerDetails = {
  id: '',
  amlCTFSupervisor: '',
  nameOfBeneficial: '',
  position: '',
  servicesRegulated: false,
};

export const initialState: State.AMLForms = {
  recordTypeId: '',
  accountingServices: false,
  amlAssessments: '',
  amlCTFSupervisorName: '',
  annualTurnover: '',
  assessmentProcess: false,
  assetServiceProvider: false,
  beneficialName: '',
  beneficialOfficers: false,
  beneficialOwners: false,
  breaches: false,
  businessRelationship: false,
  businessRules: [],
  businessType: '',
  cddInformation: '',
  cddProcesses: false,
  changesInRisk: false,
  cimaMoneyLaundering: false,
  clientBaseComposed: '',
  clientsAsADirector: '',
  clientsBusiness: false,
  clientsRejected: '',
  clientTypeOrService: '',
  clientType: [],
  companiesFormed: '',
  companies: false,
  countryOfPractice: '',
  criminalityCheck: '',
  designatedPerson: false,
  directorName: '',
  disclosuresHotline: false,
  doCompleteChecks: '',
  dueDiligence: '',
  electronicProviders: false,
  financialServices: false,
  franchiseName: '',
  franchise: false,
  higherRiskClients: '',
  identifyOwnership: '',
  irelandFieldName: '',
  launderingOfficer: false,
  licensesFromOFSI: false,
  licenses: false,
  mLRiskWithCashIntensive: false,
  mLRiskWithPayroll: false,
  nameOfLaundering: '',
  numberOfClients: '',
  numberOfCompanies: '',
  numberOfStaff: '',
  numSARSPracticeMade: '',
  offerInUK: '',
  officeAndLocation: '',
  offshoreTCSP: [],
  partnersInPractice: false,
  policyPractice: false,
  positionPractice: '',
  practiceProvides: [],
  receivedTrainingPeps: false,
  registeredAMLCTF: false,
  registeredOffice: '',
  registeredSupervision: false,
  registrationName: '',
  registrationNumber: '',
  regulatedOrganization: false,
  regulatedServices: false,
  residentOverseas: false,
  riskAssessment: false,
  riskWithClient: '',
  secureRecordsCDD: false,
  sanctionChecks: false,
  sanctionExplain: '',
  serviceInUK: false,
  singleMultipleOffice: '',
  sizeofClientBase: '',
  specifyCountry: '',
  specifyObligations: false,
  supervisedAMLCTF: false,
  supervisor: '',
  suspiciousActivity: false,
  systemAndControl: '',
  tcspServices: '',
  terroristFinancingThreats: '',
  theSupervisor: '',
  trainingOnPEPsRecords: false,
  trainingProvidedToStaff: '',
  trainingUndertaken: '',
  trustChecked: '',
  typesOfIndustries: [],
  ukFieldName: '',
  ukRegulation: false,
  financialCompliance: false,
  ifNoExplainWhy: '',
  recordsOfSanctions: false,
  reportedToOFSI: false,
  isLoading: true,
  isPdf: false,
  isRenewal: false,
  checkComplyAML: false,
  servicesProvidedOutsideCIMA: '',
  specifySectors: '',
  specifyJurisdictions: '',
  specifyPracticeProvides: '',
  submissionsHMRC: '',
  anyHigherRiskClients: '',
  specTrainingUndertaken: '',
  addDirectorInfo: '',
  beneficialOwnerList: [beneficialOwnerDetails],
  directorPartners: [directorPartner],
  diligenceMeasure: '',
  natureService: '',
};
