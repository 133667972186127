import { isActiveCheckDates } from 'modules/products/helpers';
import { fromCentsFormat, formatPrice } from 'utils';
import { TRenewMembershipDetailsTableData } from '../types/types';
import { User } from 'mxp-schemas';
import { Product as ProductUtils } from 'mxp-utils';

export const getAddons = (
  existingAddons: State.LineItem[],
  userSelectedAddons?: any,
  membershipRelatedAddons?: any,
  isForSignUp?: boolean,
  currency?: State.ProductCurrency
): TRenewMembershipDetailsTableData['addOns'] => {
  const now = new Date();

  if (existingAddons.length) {
    return existingAddons.map(item => ({
      name: item.name,
      value: formatPrice(fromCentsFormat(item.totalPrice?.centAmount), item.totalPrice?.currencyCode),
      status: isActiveCheckDates(item as any, now) ? 'Active' : 'Expired',
      isIncluded: !item.totalPrice?.centAmount,
    }));
  }

  if (userSelectedAddons && isForSignUp) {
    const includedAddOn = membershipRelatedAddons?.filter(
      (membershipRelatedAddons: any) => membershipRelatedAddons?.isIncluded === true
    );

    const userSelectedAddOnsDetails = userSelectedAddons.map((userSelectedAddon: any) => {
      return membershipRelatedAddons?.filter(
        (membershipRelatedAddons: any) => membershipRelatedAddons?.productId === userSelectedAddon?.productId
      )?.[0];
    });

    const allSelectedAddOns = [...userSelectedAddOnsDetails, ...includedAddOn];

    return allSelectedAddOns?.map(selectedAddOn => {
      const price =
        ProductUtils.getProductPrice(
          selectedAddOn,
          selectedAddOn.variants[0].sku || '',
          [User.MembershipIdsEnum.MRUSR0001],
          currency?.label
        )?.priceFinal?.amount ?? 0;

      return {
        name: selectedAddOn.name,
        status: '',
        value: formatPrice(fromCentsFormat(price), currency?.label),
        isIncluded: selectedAddOn?.isIncluded,
      };
    });
  }
};
