import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { getPersonLevelExemptions } from './action';

const ACTION_HANDLERS: any = {
  [getPersonLevelExemptions]: {
    next: (state: State.PersonLevelExemptions, action: any): State.PersonLevelExemptions => ({
      ...state,
      getPersonLevelExemptions: action.payload,
      isFetched: true,
    }),
    throw: (state: any): any => ({
      ...state,
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);

export * from './selectors';
export * from './action';
export * from './constants';
