import React from 'react';
import styled, { keyframes } from 'styled-components';
import { theme } from 'theme';

interface BallProps extends moveProps {
  frontColor: string;
  ballSize: number;
  size: number;
  index: number;
}

interface moveProps {
  translateX: number;
  translateY: number;
  backColor: string;
}

interface ClapSpinnerProps {
  size: number;
  frontColor: string;
  backColor: string;
  loading?: boolean;
}

export const ClapSpinner: React.FC<ClapSpinnerProps> = ({ size, frontColor, backColor, loading }) => {
  const radius = size / 2;
  const countBalls = 7;
  const ballSize = size / 5;
  const angle = 360 / countBalls;

  return loading ? (
    <Wrapper size={size}>
      {getBalls({
        countBalls,
        radius,
        angle,
        frontColor,
        backColor,
        size,
        ballSize,
      })}
    </Wrapper>
  ) : null;
};

const getBalls = ({ countBalls, radius, angle, frontColor, backColor, size, ballSize }: any) => {
  const balls = [];
  const offset = ballSize / 2;

  for (let i = 0; i < countBalls; i++) {
    const translateX = Math.sin((angle * i * Math.PI) / 180) * radius - offset;
    const translateY = Math.cos((angle * i * Math.PI) / 180) * radius - offset;

    balls.push(
      <Ball
        frontColor={frontColor}
        backColor={backColor}
        ballSize={ballSize}
        size={size}
        translateX={translateX}
        translateY={translateY}
        key={i.toString()}
        index={i}
      />
    );
  }

  return balls;
};

const rotate = keyframes`
  50% {
    transform: rotate(180deg) scale(1.125);
  }
  100%{
    transform: rotate(360deg);
  }
`;

const move = (moveProps: moveProps) => keyframes`
  0% {
    transform: translateX(${moveProps.translateX}px) translateY(${moveProps.translateY}px) scale(1.25);
  }
  5% {
    transform: translateX(${moveProps.translateX}px) translateY(${moveProps.translateY}px) scale(1.75);
  }
  50% {
    transform: translateX(${moveProps.translateX}px) translateY(${moveProps.translateY}px) scale(.25);
  }
  55% {
    background-color: ${moveProps.backColor};
    transform: translateX(${moveProps.translateX}px) translateY(${moveProps.translateY}px) scale(1);
  }
`;

const Wrapper = styled.div<{ size: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.pxToRem(props.size)};
  height: ${props => props.theme.pxToRem(props.size)};
  animation: ${rotate} 1.5s linear infinite;
`;

const Ball = styled.div<BallProps>`
  position: absolute;
  top: ${props => props.theme.pxToRem(props.size / 2)};
  left: ${props => props.theme.pxToRem(props.size / 2)};
  width: ${props => props.theme.pxToRem(props.ballSize)};
  height: ${props => props.theme.pxToRem(props.ballSize)};
  border-radius: 50%;
  background-color: ${props => props.frontColor};
  transform: translateX(${props => `${props.translateX}px`}) translateY(${props => `${props.translateY}px`});
  animation: ${props => move(props)} 2.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
  animation-delay: ${props => props.index * 0.2}s;
`;

ClapSpinner.defaultProps = {
  loading: true,
  size: 30,
  backColor: theme.colors.primaryPurple,
  frontColor: theme.colors.primaryPurple,
};
