import React, { useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import TruncateMarkup from 'react-truncate-markup';
import { Divider } from 'components/atoms/Divider/Divider';
import { Cart, Product } from 'mxp-schemas';
import {
  getTestIdTypeLabel,
  fromCentsFormat,
  formatPrice,
  getSessionStorageItem,
  areAllTruthy,
  hasTruthyValue,
} from 'utils';
import { User as UserUtils } from 'mxp-utils';
import { ReactComponent as InfoBubbleInverted } from 'resources/images/info-bubble-inverted.svg';
import { Link, Button, ButtonEnums } from 'components/atoms';
import { useSelector } from 'react-redux';
import {
  isMipRenewalSelector,
  isRegionalPathwayCartSelector,
  isCartForFcmaRenewalSelector,
  shouldRemoveProrationSelector,
} from 'modules/cart/selectors';
import { productsListDataFetchedSelector } from 'modules/products/selectors';
import { IC_TRASH } from 'resources/images';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_CR_682 } from 'modules/featureToggle/constants';
import { StorageNames } from '../../../constants';

interface ItemProps extends Cart.CartItemPriceInfo {
  isLast: boolean;
  removeCartItems?: (lineItemId: string[]) => any;
  cartLineItems?: Cart.LineItem[];
  isImpersonated?: boolean;
  isCenterMembershipJourney?: boolean;
  isPaymentStep?: boolean;
  donationLineItems?: Cart.LineItem[];
  useNewMembershipAICPA?: boolean;
}

export const discountColor = 'secondaryTeal';

export const Item: React.FC<ItemProps> = ({
  productName,
  productType,
  formattedPrice,
  formattedProratedPrice,
  hasDiscounts,
  discounts,
  isLast,
  quantity,
  formattedBasePriceWithQuantity,
  formattedNetPriceWithQuantity,
  tierName,
  proration,
  donationNames,
  lineItemId,
  removeCartItems,
  cartLineItems,
  isImpersonated,
  isCenterMembershipJourney = false,
  isPaymentStep = false,
  price,
  donationLineItems,
  useNewMembershipAICPA = false,
}) => {
  const isRegionalPathwayCart = useSelector(isRegionalPathwayCartSelector);
  const isMipRenewal = useSelector(isMipRenewalSelector);
  const productsListDataFetched = useSelector(productsListDataFetchedSelector);
  const shouldRemoveProration = useSelector(shouldRemoveProrationSelector);
  const useCR862FcmaRenewalEnhancement = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_CR_682)
  );
  const isCartForFcmaRenewal = useSelector(isCartForFcmaRenewalSelector);
  const [showBanner, setShowBanner] = React.useState<boolean>(false);
  const isMembership = productType === Product.ProductType.MEMBERSHIP;
  const isCentersMembership = productType === Product.ProductType.CENTER_MEMBERSHIP;
  const isCredentialOrSection =
    productType === Product.ProductType.CREDENTIAL || productType === Product.ProductType.SECTION;
  const isCentAmountZero = price.centAmount === 0;
  const currency = price?.currencyCode;
  const productSlug = cartLineItems
    ?.filter(value => value.id === lineItemId)
    .map(value => {
      return value.productSlug;
    });
  const attributes: any = cartLineItems
    ?.filter(value => value.id === lineItemId)
    .map(value => {
      return value.variant?.attributes;
    });

  // TODO: add check for CIMA build when applying late fees
  const isLateFee = String(productSlug) === Product.Fee.AICPA_LATE_FEE;
  const isCimaLateFee = String(productSlug) === Product.Fee.CIMA_LATE_FEE;

  const handleRemoveLateFee = React.useCallback(() => {
    if (removeCartItems?.([lineItemId])) removeCartItems([lineItemId]);
  }, [removeCartItems, lineItemId]);

  const transformCpaRange = (minCpas: number = 0, maxCpas: number = 0) => {
    const OPEN_AND_6_MORE = 'Open and 6+ CPAs';
    return minCpas === 0 && maxCpas === 0
      ? OPEN_AND_6_MORE // show Open and 6+ label
      : minCpas === maxCpas
      ? `${minCpas} CPA${minCpas > 1 ? 's' : ''}` // show minCpa value (single digit)
      : !maxCpas
      ? `${minCpas}+ CPAs` // show minCpa value with plus sign
      : `${minCpas} - ${maxCpas} CPAs`; // show minCpa and maxCpa range
  };

  const productInCartIsFCMA =
    isRegionalPathwayCart &&
    productType === Product.ProductType.CREDENTIAL &&
    productName.includes(Product.CredentialKey.FCMA);

  const isAllowToAddFcmaInCart = getSessionStorageItem(StorageNames.isAllowToAddFcmaInCart);
  const isFcmaInCartForRenewal = areAllTruthy(
    useCR862FcmaRenewalEnhancement,
    productType === Product.ProductType.CREDENTIAL,
    productName.includes(Product.CredentialKey.FCMA),
    // isAllowToAddFcmaInCart - FCMA Added via Confirm Designation (Session Storage)
    // Added isCartForFcmaRenewal as a handler, incase user logged out and access the checkout url directly with FCMA already added Cart and isAllowToAddFcmaInCart was cleared upon logged out.
    hasTruthyValue(isAllowToAddFcmaInCart, isCartForFcmaRenewal)
  );
  const fcmaRenewalProductPrice = UserUtils.conditionalFunction(
    proration,
    formattedProratedPrice || formattedPrice,
    formatPrice(fromCentsFormat(price?.centAmount), currency)
  );

  React.useEffect(() => {
    setShowBanner(!isMipRenewal && productsListDataFetched);
  }, [isMipRenewal, productsListDataFetched]);

  const getProductPrice = useCallback(() => {
    if (hasTruthyValue(isMembership, isCredentialOrSection, isCentersMembership)) {
      if (isFcmaInCartForRenewal) return `${fcmaRenewalProductPrice}`;
      if (isCentAmountZero) {
        return productInCartIsFCMA ? '' : `${formatPrice(fromCentsFormat(price?.centAmount), currency)}`;
      }
      return formattedProratedPrice || formattedPrice;
    }
    return formattedPrice;
  }, [
    currency,
    formattedPrice,
    formattedProratedPrice,
    isCentAmountZero,
    isCentersMembership,
    isCredentialOrSection,
    isFcmaInCartForRenewal,
    fcmaRenewalProductPrice,
    isMembership,
    price,
    productInCartIsFCMA,
  ]);
  const handleItemRemove = React.useCallback(
    (event: any) => {
      const lineItemToRemove = event.currentTarget.dataset.arg;
      if (removeCartItems) {
        removeCartItems([lineItemToRemove]);
      }
    },
    [removeCartItems]
  );

  const RenderNewDonationValues = React.useCallback(() => {
    if (donationLineItems) {
      const donationIndividualValue = donationLineItems?.map(lineItem => {
        return {
          price: formatPrice(
            fromCentsFormat(lineItem.membershipPrice.value.centAmount),
            lineItem.membershipPrice.value.currencyCode
          ),
          name: lineItem.name,
          lineItemId: lineItem.id,
        };
      });

      return donationIndividualValue?.map(donation => (
        <StyledSpan key={donation.name}>
          {` • ${donation.name}`} <span>{donation.price}</span>
          {!isPaymentStep && <StyledImgIcon src={IC_TRASH} data-arg={donation.lineItemId} onClick={handleItemRemove} />}
        </StyledSpan>
      ));
    }
  }, [donationLineItems, handleItemRemove, isPaymentStep]);

  const RenderBoldPrice = React.useCallback(() => {
    const hasDonationsData = !!donationLineItems;
    if (hasDonationsData && useNewMembershipAICPA) {
      return null;
    }
    return <BoldDetail data-testid={`product-price`}>{getProductPrice()}</BoldDetail>;
  }, [donationLineItems, useNewMembershipAICPA, getProductPrice]);

  return (
    <>
      <SpaceBetweenFlex>
        <TruncateMarkup ellipsis={'…'} lines={2}>
          {isCenterMembershipJourney ? (
            <CenterBoldDetailName data-testid={`product-name`}>{productName}</CenterBoldDetailName>
          ) : (
            <BoldDetailName data-testid={`product-name`} name={productName}>
              {!productInCartIsFCMA && productName}
            </BoldDetailName>
          )}
        </TruncateMarkup>
        {isCenterMembershipJourney && isCentersMembership ? (
          <CenterBoldDetail data-testid={`product-price`}>{formattedProratedPrice || formattedPrice}</CenterBoldDetail>
        ) : (
          RenderBoldPrice()
        )}
      </SpaceBetweenFlex>
      {isCenterMembershipJourney ? (
        <StyledCenterMembershipSpan>
          {transformCpaRange(attributes[0]?.minCpas, attributes[0]?.maxCpas)}
        </StyledCenterMembershipSpan>
      ) : (
        <>{tierName && <StyledSpan>{` - ${tierName}`}</StyledSpan>}</>
      )}
      {proration && proration?.monthsProrated > 0 && !isCentAmountZero && showBanner && !shouldRemoveProration && (
        <StyledProrationDiv>
          <StyledInfoBubble />
          <div>
            {isPaymentStep
              ? `Cost is prorated for ${proration.monthsProrated} months (exp. ${proration.expirationDate}), `
              : `The total cost for today will be prorated for ${proration.monthsProrated} months at checkout (exp. ${proration.expirationDate}), `}
            {!useNewMembershipAICPA && (
              <Link isExternal to="https://www.aicpa.org/membership/dues/dues-bill-prorate.html">
                Tell me why
              </Link>
            )}
          </div>
        </StyledProrationDiv>
      )}
      {!useNewMembershipAICPA &&
        donationNames?.map((donationName: string) => (
          <StyledSpan key={donationName}>{` - ${donationName}`}</StyledSpan>
        ))}

      {useNewMembershipAICPA && RenderNewDonationValues()}

      {quantity > 1 && (
        <SpaceBetweenFlex>
          <PriceLabel data-testid={`quantity-label`}>Quantity</PriceLabel>
          <PriceLabel data-testid={`quantity-count`}>{quantity}</PriceLabel>
        </SpaceBetweenFlex>
      )}

      {hasDiscounts && (
        <>
          {!isMembership && !isCentersMembership && !isCredentialOrSection && (
            <SpaceBetweenFlex>
              <PriceLabel data-testid={`non-member-label`}>Nonmember Price</PriceLabel>
              <PriceDetail data-testid={`non-member-price`}>{formattedBasePriceWithQuantity}</PriceDetail>
            </SpaceBetweenFlex>
          )}
          {discounts.map(discount => {
            const testIdType = getTestIdTypeLabel(discount);
            const testIdTypeLabel = `discount-${testIdType}`;
            const addWordDiscount = Boolean(
              discount.discountType === Cart.DISCOUNT_TYPE.MEMBERSHIP_DISCOUNT ||
                discount.discountType === Cart.DISCOUNT_TYPE.AUTO_APPLIED_DISCOUNT
            );
            return (
              <>
                {!productInCartIsFCMA && (
                  <SpaceBetweenFlex key={discount.label}>
                    <PriceLabel data-testid={`${testIdTypeLabel}-label`}>
                      {discount.label + (addWordDiscount ? ' Discount' : '')}
                    </PriceLabel>
                    <PriceDetail isDiscount data-testid={`${testIdTypeLabel}-value`}>
                      {discount.formattedDiscountAmount}
                    </PriceDetail>
                  </SpaceBetweenFlex>
                )}
              </>
            );
          })}
        </>
      )}
      {quantity > 1 && (
        <SpaceBetweenFlex>
          <PriceLabel data-testid={`net-price-label`}>Net Price</PriceLabel>
          <PriceLabel data-testid={`net-price-price`} isBold={true}>
            {formattedNetPriceWithQuantity}
          </PriceLabel>
        </SpaceBetweenFlex>
      )}
      {(isLateFee || isCimaLateFee) &&
        isImpersonated &&
        !isPaymentStep && ( // only allow remove of late fee before payment page since we want to always get the price in order preview api.
          <StyledButton
            testId={`remove-code`}
            variant={ButtonEnums.variants.link}
            size={ButtonEnums.sizes.small}
            onClick={handleRemoveLateFee}
          >
            Remove
          </StyledButton>
        )}
      {!isLast && !productInCartIsFCMA ? useNewMembershipAICPA ? <DividerWithBorderB /> : <Divider /> : null}
    </>
  );
};

const StyledImgIcon = styled.img`
  cursor: pointer;
`;

const BoldDetail = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const BoldDetailName = styled.span<{ name?: string }>`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;
  max-width: ${props => props.theme.pxToRem(244)};
  color: ${props => (props.name === 'Donations' ? props.theme.colors.primaryPurple : 'inherit')};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const CenterBoldDetail = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 1.57;
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const CenterBoldDetailName = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 1.57;
  max-width: ${props => props.theme.pxToRem(244)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

export const SpaceBetweenFlex = styled.div<{ subtotal?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${props =>
    props.subtotal &&
    `
    height: ${props.theme.pxToRem(25)};
  `}
`;

const priceDetail = css`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.5;
`;
export const PriceLabel = styled.span<{ isBold?: boolean }>`
  ${priceDetail}
  font-weight: ${props => props.theme.fontWeights.light};
  ${props =>
    props.isBold &&
    `
    font-weight: ${props.theme.fontWeights.medium};
  `}
`;

export const PriceDetail = styled.span<{ isDiscount?: boolean }>`
  ${priceDetail}
  ${props =>
    props.isDiscount
      ? `
    color: ${props.theme.colors[discountColor]};
    font-weight: ${props.theme.fontWeights.medium};
  `
      : 'text-decoration: line-through;'}
`;

const StyledSpan = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  display: flex;
  position: relative;
  img {
    position: absolute;
    right: 0;
  }
  span {
    position: absolute;
    right: ${props => props.theme.pxToRem(25)};
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;

const StyledCenterMembershipSpan = styled.span`
  margin-top: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  display: flex;
`;

const StyledProrationDiv = styled.div`
  display: flex;
  margin-top: ${props => props.theme.pxToRem(16)};
  margin-bottom: ${props => props.theme.pxToRem(16)};
  max-width: ${props => props.theme.pxToRem(252)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.interfaceBlue};
  background-color: rgba(65, 182, 230, 0.1);
  padding-bottom: ${props => props.theme.pxToRem(7)};
  padding-top: ${props => props.theme.pxToRem(7)};
  padding-right: ${props => props.theme.pxToRem(7)};
  border-radius: ${props => props.theme.pxToRem(4)};
`;

const StyledInfoBubble = styled(InfoBubbleInverted)`
  width: auto;
  height: 100%;
  fill: ${props => props.theme.colors.interfaceBlue};
  padding-right: ${props => props.theme.pxToRem(8)};
  padding-left: ${props => props.theme.pxToRem(8)};
`;

const StyledButton = styled(Button)`
  &&&& {
    font-size: ${props => props.theme.fontSizes.xxs};
    font-weight: ${props => props.theme.fontWeights.regular};
    color: ${props => props.theme.colors.primaryPurple};
  }
`;

const DividerWithBorderB = styled.div`
  margin: 0 1.25rem 0 1.25rem;
  border-bottom: 1px dashed ${props => props.theme.colors.lightGrey};
`;
