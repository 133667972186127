import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Link, Button, ButtonEnums, LinkEnums } from 'components/atoms';
import { Container, Divider, Grid } from 'semantic-ui-react';
import { MembershipTypes, Directory } from 'mxp-schemas';
import { ReactComponent as ArrowBack } from 'resources/images/ic-arrow-back.svg';
import { ReactComponent as RedFlag } from 'resources/images/red-flag.svg';

interface DirectoryResultProps {
  testId: string;
  searchType?: number;
  resultsTitle?: string;
  name: string;
  location?: string;
  organization?: string;
  to?: string;
  showFullDetails?: boolean;
  title?: string;
  cpaLicense?: string;
  primaryContactNumber?: string;
  primaryEmailAddress?: string;
  linkedInProfile?: string;
  areaExpertise?: string[];
  membershipType?: string;
  CredentialsHeld?: string[];
  tuitionProvider?: string;
  amlSupervisoryStatus?: string;
  disciplinaryAction?: string;
  topicSlug?: string;
  setViewingDetails(data: any): void;
  data?: any;
  onClickBack(): void;
  isSearchingMember?: boolean;
}

enum DirectoryResultEnums {
  NA = 'N/A',
  NA_NA = 'N/A , N/A',
  YES = 'Yes',
  NO = 'NO',
  UNDEFINED1 = 'undefined ',
  UNDEFINED2 = ' undefined',
  CIMA_REGULAR = 'CIMA Regular',
}

export const DirectoryResult: React.FC<DirectoryResultProps> = ({
  testId,
  searchType,
  resultsTitle,
  name,
  location,
  organization,
  to = '',
  showFullDetails,
  title,
  cpaLicense,
  primaryContactNumber,
  primaryEmailAddress,
  linkedInProfile,
  areaExpertise,
  membershipType,
  CredentialsHeld,
  tuitionProvider,
  amlSupervisoryStatus,
  disciplinaryAction,
  topicSlug = '',
  setViewingDetails,
  data,
  onClickBack,
}) => {
  const onClickView = useCallback(() => {
    setViewingDetails(data);
  }, [setViewingDetails, data]);

  const isAMLApplicable = useCallback(() => {
    return Boolean(
      (searchType === 2 ||
        (searchType === 1 &&
          (CredentialsHeld?.includes(Directory.Credentials.MIP) ||
            CredentialsHeld?.includes(Directory.Credentials.MIP_SINGULAR)))) &&
        amlSupervisoryStatus &&
        amlSupervisoryStatus !== DirectoryResultEnums.NA &&
        amlSupervisoryStatus !== DirectoryResultEnums.NA_NA
    );
  }, [CredentialsHeld, searchType, amlSupervisoryStatus]);
  // TODO -> Add disciplinary action flag once back end CR is done

  return (
    <Container data-testid={testId} fluid>
      {showFullDetails ? (
        <>
          <StyledAggregation>
            <StyledAggregationHeader>
              <Button testId="go-back-results" variant={ButtonEnums.variants.link} onClick={onClickBack}>
                <StyledGrid>
                  <StyledGridLink>
                    <ArrowBack />
                  </StyledGridLink>
                  <StyledGridLink className="link-content">
                    <span>{topicSlug}</span>
                  </StyledGridLink>
                </StyledGrid>
              </Button>
              {resultsTitle && (
                <>
                  <StyledName>{resultsTitle}</StyledName>
                  <Divider />
                </>
              )}
              <StyledName>{name}</StyledName>
            </StyledAggregationHeader>
          </StyledAggregation>
          <StyledGridSection>
            {title && title !== DirectoryResultEnums.NA && title !== DirectoryResultEnums.NA_NA && (
              <StyledGridRow>
                <StyledGridColumn width={8}>
                  <StyledText>Title</StyledText>
                </StyledGridColumn>
                <StyledGridColumn width={8}>
                  <StyledInfo>{title}</StyledInfo>
                </StyledGridColumn>
              </StyledGridRow>
            )}
            {organization && organization !== DirectoryResultEnums.NA && organization !== DirectoryResultEnums.NA_NA && (
              <StyledGridRow>
                <StyledGridColumn width={8}>
                  <StyledText>Organization</StyledText>
                </StyledGridColumn>
                <StyledGridColumn width={8}>
                  <StyledInfo>{organization}</StyledInfo>
                </StyledGridColumn>
              </StyledGridRow>
            )}
            {location && location !== DirectoryResultEnums.NA && location !== DirectoryResultEnums.NA_NA && (
              <StyledGridRow>
                <StyledGridColumn width={8}>
                  <StyledText>Location</StyledText>
                </StyledGridColumn>
                <StyledGridColumn width={8}>
                  <StyledInfo>{location}</StyledInfo>
                </StyledGridColumn>
              </StyledGridRow>
            )}
            {tuitionProvider &&
              tuitionProvider !== DirectoryResultEnums.NA &&
              tuitionProvider !== DirectoryResultEnums.NA_NA && (
                <StyledGridRow>
                  <StyledGridColumn width={8}>
                    <StyledText>Tuition Provider</StyledText>
                  </StyledGridColumn>
                  <StyledGridColumn width={8}>
                    <StyledInfo>{tuitionProvider}</StyledInfo>
                  </StyledGridColumn>
                </StyledGridRow>
              )}
            {cpaLicense && cpaLicense !== DirectoryResultEnums.NA && cpaLicense !== DirectoryResultEnums.NA_NA && (
              <StyledGridRow>
                <StyledGridColumn width={8}>
                  <StyledText>CPA license</StyledText>
                </StyledGridColumn>
                <StyledGridColumn width={8}>
                  <StyledInfo>{cpaLicense}</StyledInfo>
                </StyledGridColumn>
              </StyledGridRow>
            )}
            {(primaryContactNumber || primaryEmailAddress || linkedInProfile) && <Divider />}
            {primaryContactNumber &&
              primaryContactNumber !== DirectoryResultEnums.NA &&
              primaryContactNumber !== DirectoryResultEnums.NA_NA && (
                <StyledGridRow>
                  <StyledGridColumn width={8}>
                    <StyledText>Primary contact number</StyledText>
                  </StyledGridColumn>
                  <StyledGridColumn width={8}>
                    <StyledInfo>{primaryContactNumber}</StyledInfo>
                  </StyledGridColumn>
                </StyledGridRow>
              )}
            {primaryEmailAddress &&
              primaryEmailAddress !== DirectoryResultEnums.NA &&
              primaryEmailAddress !== DirectoryResultEnums.NA_NA && (
                <StyledGridRow>
                  <StyledGridColumn width={8}>
                    <StyledText>Primary email address</StyledText>
                  </StyledGridColumn>
                  <StyledGridColumn width={8}>
                    <StyledInfo>{primaryEmailAddress}</StyledInfo>
                  </StyledGridColumn>
                </StyledGridRow>
              )}
            {linkedInProfile &&
              linkedInProfile !== DirectoryResultEnums.NA &&
              linkedInProfile !== DirectoryResultEnums.NA_NA && (
                <StyledGridRow>
                  <StyledGridColumn width={8}>
                    <StyledText>LinkedIn profile URL</StyledText>
                  </StyledGridColumn>
                  <StyledGridColumn width={8}>
                    <StyledLink
                      testId="linkedin-profile-link"
                      to={linkedInProfile}
                      isExternal={true}
                      type={LinkEnums.type.standaloneLink}
                    >
                      {linkedInProfile}
                    </StyledLink>
                  </StyledGridColumn>
                </StyledGridRow>
              )}
            {(membershipType || CredentialsHeld?.length || areaExpertise) && <Divider />}
            {membershipType &&
              membershipType !== DirectoryResultEnums.NA &&
              membershipType !== DirectoryResultEnums.NA_NA &&
              membershipType !== DirectoryResultEnums.UNDEFINED1 &&
              membershipType !== DirectoryResultEnums.UNDEFINED2 && (
                <StyledGridRow>
                  <StyledGridColumn width={8}>
                    <StyledText>Membership type</StyledText>
                  </StyledGridColumn>
                  <StyledGridColumn width={8}>
                    <StyledInfo>
                      {membershipType === DirectoryResultEnums.CIMA_REGULAR ? 'CIMA Member' : membershipType}
                    </StyledInfo>
                  </StyledGridColumn>
                </StyledGridRow>
              )}
            {!!CredentialsHeld?.length && (
              <StyledGridRow>
                <StyledGridColumn width={8}>
                  <StyledText>Credential(s)/designation(s)</StyledText>
                </StyledGridColumn>
                <StyledGridColumn width={8}>
                  {CredentialsHeld?.map((credHeld: string) => (
                    <>
                      <StyledInfo>{credHeld}</StyledInfo>
                      <br />
                    </>
                  ))}
                </StyledGridColumn>
              </StyledGridRow>
            )}
            {!!areaExpertise?.length && (
              <StyledGridRow>
                <StyledGridColumn width={8}>
                  <StyledText>Area(s) of expertise</StyledText>
                </StyledGridColumn>
                <StyledGridColumn width={8}>
                  <StyledInfo>
                    {areaExpertise?.map((area: string) => (
                      <>
                        <StyledInfo>{area}</StyledInfo>
                        <br />
                      </>
                    ))}
                  </StyledInfo>
                </StyledGridColumn>
              </StyledGridRow>
            )}
            {isAMLApplicable() && (
              <StyledGridRow>
                <StyledGridColumn width={8}>
                  <StyledText>Anti-money laundering (AML) supervisory status</StyledText>
                </StyledGridColumn>
                <StyledGridColumn width={8}>
                  <StyledInfo>
                    {amlSupervisoryStatus === DirectoryResultEnums.YES
                      ? 'Supervised by another AML Supervisory body'
                      : DirectoryResultEnums.NO
                      ? 'Supervised by CIMA'
                      : 'N/A'}
                  </StyledInfo>
                </StyledGridColumn>
              </StyledGridRow>
            )}
            {disciplinaryAction &&
              disciplinaryAction !== DirectoryResultEnums.NA &&
              disciplinaryAction !== DirectoryResultEnums.NA_NA && (
                <StyledGridRow>
                  <StyledGridColumn width={8}>
                    <StyledText>Disciplinary action</StyledText>
                  </StyledGridColumn>
                  <StyledGridColumn width={8}>
                    <StyledFlagContainer>
                      <RedFlag />
                    </StyledFlagContainer>

                    <StyledInfo>
                      {disciplinaryAction === MembershipTypes.EthicsStatusEnum.SANCTIONED ||
                      disciplinaryAction === MembershipTypes.EthicsStatusEnum.REMEDIATION ? (
                        <RedFlag />
                      ) : null}
                    </StyledInfo>
                  </StyledGridColumn>
                </StyledGridRow>
              )}
          </StyledGridSection>
        </>
      ) : (
        <Container>
          <StyledHeader>{name}</StyledHeader>
          {location && <StyledDescription>Location: {location}</StyledDescription>}
          {organization && <StyledDescription>Firm/organization: {organization}</StyledDescription>}
          {to && (
            <StyledLink testId="view-details-link" to={to} onClick={onClickView} isExternal={false}>
              View details
            </StyledLink>
          )}
        </Container>
      )}
    </Container>
  );
};

const StyledAggregationHeader = styled.div`
  padding: ${props => props.theme.pxToRem(30)};
`;

const StyledAggregation = styled.div`
  position: relative;
  padding-top: ${props => props.theme.pxToRem(30)};
`;

const StyledName = styled.div`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xxl};
  padding-top: ${props => props.theme.pxToRem(12)};
`;

const StyledDescription = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
  padding-bottom: ${props => props.theme.pxToRem(5)};
`;

const StyledHeader = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  padding-bottom: ${props => props.theme.pxToRem(10)};
`;

const StyledLink = styled(Link)`
  &&& {
    font-size: ${props => props.theme.fontSizes.xs};
    padding-top: ${props => props.theme.pxToRem(10)};
    color: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  padding-top: ${props => props.theme.pxToRem(0)} !important;
  padding-bottom: ${props => props.theme.pxToRem(2)} !important;
`;

const StyledText = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledGridSection = styled(Grid)`
  position: relative;
  padding-right: 55% !important;
  margin-top: ${props => props.theme.pxToRem(10)} !important;
  margin-left: ${props => props.theme.pxToRem(15)} !important;
  margin-bottom: ${props => props.theme.pxToRem(50)} !important;
  ${props => props.theme.mediaQueries.mobileOnly}  {
    padding-right: ${props => props.theme.pxToRem(30)} !important;
  }
`;

const StyledGrid = styled(Grid)`
  align-items: center !important;
`;
const StyledInfo = styled.div`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xs};
  display: inline-block;
`;

const StyledGridColumn = styled(Grid.Column)`
  padding: ${props => props.theme.pxToRem(10)};
`;

const StyledGridLink = styled(Grid.Column)`
  &.link-content {
    width: 80% !important;
    padding-left: ${props => props.theme.pxToRem(10)} !important;
  }
`;

const StyledFlagContainer = styled.div`
  width: ${props => props.theme.pxToRem(20)};
`;
