import React from 'react';
import styled from 'styled-components';
import { OnlyDesktop, OnlyMobile, AicpaLogo, Divider, Container } from 'components/atoms';
import { IC_ACCORDION_LEFT } from 'resources/images';
import { matchPath, useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { MiniUserWidgetContainer } from 'containers/UserWidgetContainer';
import { isRenewalButtonSelector, membershipProgressBarSelector } from 'modules/membership/selectors';
import { isUserMemberSelector } from 'modules/user/selectors';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';
import { ProgressBar } from 'components/atoms/MembershipsJourneyStepperBar/ProgressBar';
import { ProgressBarMapping } from 'components/atoms/MembershipsJourneyStepperBar/ProgressBarMapping';

interface LogoProps {
  handleLogoClick: () => void;
}

const DesktopLogo: React.FC<LogoProps> = ({ handleLogoClick }) => {
  return <StyledPMHAicpaCimaLogoWhite onClick={handleLogoClick} />;
};
const MobileLogo: React.FC<LogoProps> = ({ handleLogoClick }) => {
  return <StyledPMHAicpaCimaLogoWhiteMobile onClick={handleLogoClick} />;
};

export const PageMembershipHeader: React.FC<{}> = () => {
  const history = useHistory();
  const { pathname: currPath, hash: currHash } = useLocation();

  const isPreSignUp = Boolean(matchPath(currPath, { path: getPath(Routes.PRE_SIGN_UP), exact: true, strict: false }));
  const isPackageBuilder = Boolean(
    matchPath(currPath, { path: getPath(Routes.MEMBERSHIP_FORM), exact: true, strict: false })
  );

  const currPathName = isPackageBuilder
    ? currPath.replace(currPath.split('#')[1], '')
    : currPath.replace(currPath.split('/')[3], '');

  const { isAicpaRenewal } = useSelector(isRenewalButtonSelector);
  const isUserMember = useSelector(isUserMemberSelector);
  const newMember = !isUserMember;
  const { isMembershipForRenewal } = useSelector(membershipProgressBarSelector);
  const { useNewSignUpOnly } = useSelector(featureTogglesSelector);

  const onBackClick = React.useCallback(() => {
    if (newMember && useNewSignUpOnly) {
      history.push(getPath(Routes.PRE_SIGN_UP));
    }
    if (isAicpaRenewal || isPreSignUp) {
      history.push(getPath(Routes.ROOT));
    }
  }, [history, newMember, isAicpaRenewal, isPreSignUp, useNewSignUpOnly]);

  const handleLogoClick = () => {
    history.push(getPath(Routes.ROOT));
  };

  const desktopView = (
    <StyledPMHHeaderWrapper>
      <StyledPMHHeaderWrapperContainer>
        <DesktopLogo handleLogoClick={handleLogoClick} />
        <StyledPMHMiniUserWidget showLogoutLink isWhite={false} />
      </StyledPMHHeaderWrapperContainer>
    </StyledPMHHeaderWrapper>
  );

  const mobileView = (
    <StyledPMHHeaderWrapper>
      <StyledPMHHeaderWrapperContainer>
        <MobileLogo handleLogoClick={handleLogoClick} />
        <StyledPMHMiniUserWidget showLogoutLink isWhite={false} />
      </StyledPMHHeaderWrapperContainer>
    </StyledPMHHeaderWrapper>
  );

  return (
    <React.Fragment>
      <OnlyDesktop minWidth={768}>{desktopView}</OnlyDesktop>
      <OnlyMobile maxWidth={767}>{mobileView}</OnlyMobile>
      <ProgressBar />
      <OnlyDesktop>
        <StyledPMHPageBackLinkContainer>
          <StyledPMHPageBackLink onClick={onBackClick}>
            {/* tslint:disable-line */}
            <StyledPMHBackIcon src={IC_ACCORDION_LEFT} /> Back
          </StyledPMHPageBackLink>
        </StyledPMHPageBackLinkContainer>
      </OnlyDesktop>
      <OnlyMobile>
        <ProgressBarMapping
          isMembershipForRenewal={isMembershipForRenewal}
          isPackageBuilder={isPackageBuilder}
          currPath={currPath}
          currHash={currHash}
          currPathName={currPathName}
        />
        <Container>{!isPackageBuilder && <Divider />}</Container>
      </OnlyMobile>
    </React.Fragment>
  );
};

const StyledPMHMiniUserWidget = styled(MiniUserWidgetContainer)`
  color: ${props => props.theme.colors.neutralWhite} !important;
  text-decoration: none;
`;

const StyledPMHAicpaCimaLogoWhite = styled(AicpaLogo)`
  cursor: pointer;
`;

const StyledPMHAicpaCimaLogoWhiteMobile = styled(AicpaLogo)`
  cursor: pointer;
  float: left !important;
  height: ${props => props.theme.pxToRem(34)};
  width: ${props => props.theme.pxToRem(48)};
`;

const StyledPMHHeaderWrapper = styled.div`
  &&&& {
    background: ${props => props.theme.colors.neutralWhite};

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)};
    }
  }
`;

const StyledPMHHeaderWrapperContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: calc(100% - 31rem);
  }
`;

const StyledPMHPageBackLinkContainer = styled.div`
  background-color: ${props => props.theme.colors.neutralWhite};
`;

const StyledPMHPageBackLink = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSizes.xs}
  font-weight: ${props => props.theme.fontWeights.regular};
  text-decoration: none;
  font-family:'Roboto';
  color: ${props => props.theme.colors.primaryPurple} !important;
  padding: ${props => props.theme.pxToRem(12)};
  width: calc(100% - 29rem);
  margin: 0 auto;
  > svg {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    margin-right: ${props => props.theme.pxToRem(4)};
    path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
  cursor: pointer;
`;

const StyledPMHBackIcon = styled.img`
  cursor: pointer;
  height: ${props => props.theme.pxToRem(18)};
  width: ${props => props.theme.pxToRem(18)};
`;
