import { Salesforce } from 'mxp-schemas';
import { GET_ORGANIZATION_BY_ACCOUNT_ID } from 'mxp-graphql-queries';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { MenuItem, Placeholder } from 'semantic-ui-react';
import { useHistory, useParams, generatePath, RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { swapAdminSuccessSelector, isFirmBillingSelector, isCenterMembershipSelector } from 'modules/admin/selectors';
import { BackLink } from 'components/molecules/BackLink/BackLink';
import { Container, Heading, Tab, Grid } from 'components/atoms';
import { ClientAdminsTab } from './ClientAdminsTab';
import { OrdersTab, Address } from './OrdersTab';
import { default as request } from 'utils/GraphQLClient';
import { emptyObject, getPath } from 'utils';
import { Routes } from 'constants/index';
import { RemoveClientAdminsModalContainer } from 'containers/RemoveClientAdminsModalContainer';

const GreyHeader: React.FC<{ title: string; className: string }> = ({ title, className, children }) => (
  <div className={className}>
    <Container>
      <StyledGrid>
        <Grid.Row>
          <Grid.Column width={3}>
            <BackLink testId="search" to={getPath(Routes.ADMIN_ROOT)}>
              {`Back to search `}
            </BackLink>
          </Grid.Column>
          <Grid.Column width={13}>
            <StyledRow>
              <StyledPreferencesHeading as="h1">
                {title || (
                  <Placeholder fluid>
                    <StyledPlaceholderLine length="full" />
                    <StyledPlaceholderLine length="medium" />
                  </Placeholder>
                )}
              </StyledPreferencesHeading>
              {children}
            </StyledRow>
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
    </Container>
  </div>
);

const StyledRow = styled(Grid.Row)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledPlaceholderLine = styled(Placeholder.Line)`
  &&&& {
    background-color: ${props => props.theme.colors.neutralGrey1};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

export const OrganizationProfile: React.FC<RouteComponentProps> = ({ location }) => {
  const { accountId, accountNumber, legalEntity } = useParams<{
    accountId: string;
    accountNumber: string;
    legalEntity: string;
  }>();
  const [organizationName, setOrganizationName] = useState('');
  const [creditHoldStatus, setCreditHoldStatus] = useState<boolean | null>(null);
  const [addresses, setAddresses] = useState<{ shippingAddress: Address; billingAddress: Address }>({
    shippingAddress: {},
    billingAddress: {},
  });
  const [hasAICPAUid, setHasAICPAUid] = useState<boolean | null>(null);
  const [taxExemption, setTaxExemption] = useState(true);
  const dispatch = useDispatch();
  const {
    push: historyPush,
    location: { hash: routeHash, pathname: locationPathname },
  } = useHistory();
  const isFirmBilling = useSelector(isFirmBillingSelector);
  const isCenterMembership = useSelector(isCenterMembershipSelector);
  const [selectedTab, setSelectedTab] = useState(
    routeHash === '#client-admins' ? (isFirmBilling || isCenterMembership ? 0 : 1) : 0
  );
  const { assignAdminSuccess } = (location.state as any) || emptyObject;
  const swapAdminSuccess = useSelector(swapAdminSuccessSelector);

  useEffect(() => {
    request(GET_ORGANIZATION_BY_ACCOUNT_ID, { accountId })
      .then((data: { organization: Salesforce.Organization | null }) => {
        if (!data.organization) {
          dispatch(push(getPath(Routes.ADMIN_ROOT)));
          return;
        }
        setOrganizationName(data.organization.name);
        setCreditHoldStatus(data.organization.creditHoldStatus);
        setHasAICPAUid(data.organization.hasAICPAUid);
        setTaxExemption(data.organization.taxExemption as boolean);
        setAddresses({
          billingAddress: data.organization.billingAddress,
          shippingAddress: data.organization.shippingAddress,
        });
      })
      .catch(() => {
        dispatch(push(getPath(Routes.ADMIN_ERROR_PAGE)));
      });
  }, [accountId, dispatch]);

  const panes =
    isFirmBilling || isCenterMembership
      ? [
          {
            menuItem: (
              <MenuItem key="1" data-testid="mi-client-admins">
                Client admins
              </MenuItem>
            ),
            render: () => null,
            onClick: () => {
              historyPush({ pathname: locationPathname, hash: 'client-admins' });
              setSelectedTab(0);
            },
          },
        ]
      : [
          {
            menuItem: (
              <MenuItem key="1" data-testid="mi-orders">
                Orders
              </MenuItem>
            ),
            render: () => null,
            onClick: () => {
              historyPush({ pathname: locationPathname, hash: 'orders' });
              setSelectedTab(0);
            },
          },
          {
            menuItem: (
              <MenuItem key="2" data-testid="mi-client-admins">
                Client admins
              </MenuItem>
            ),
            render: () => null,
            onClick: () => {
              historyPush({ pathname: locationPathname, hash: 'client-admins' });
              setSelectedTab(1);
            },
          },
        ];

  const onAddOrder = () => {
    historyPush({
      pathname: generatePath(getPath(Routes.ADMIN_CREATE_ORDER), { accountId, accountNumber, legalEntity }),
      state: { activeScreenIndex: 0 },
    });
  };

  const tabs =
    isFirmBilling || isCenterMembership
      ? [<ClientAdminsTab key="client-admins" showNotification={assignAdminSuccess || swapAdminSuccess} />]
      : [
          <OrdersTab
            key="orders"
            goToAddOrder={onAddOrder}
            creditHoldStatus={creditHoldStatus}
            hasAICPAUid={hasAICPAUid}
            addresses={addresses}
            taxExemption={taxExemption}
          />,
          <ClientAdminsTab key="client-admins" showNotification={assignAdminSuccess || swapAdminSuccess} />,
        ];
  return (
    <>
      <StyledGreyHeader className="grey-header" title={organizationName}>
        <StyledTab panes={panes} activeIndex={selectedTab} />
      </StyledGreyHeader>
      <Container>{tabs[selectedTab]}</Container>
      <RemoveClientAdminsModalContainer />
    </>
  );
};

// GreyHeader Components
const StyledGreyHeader = styled(GreyHeader)`
  position: relative;
  display: flex;
  min-height: ${props => props.theme.pxToRem(160)};
  margin-top: ${props => props.theme.pxToRem(-31)};
  margin-bottom: ${props => props.theme.pxToRem(40)};
  padding-top: ${props => props.theme.pxToRem(40)};
  box-shadow: ${props => `inset 0 0 0.625rem 0 ${props.theme.colors.neutralGrey3}`};
  background-color: ${props => props.theme.colors.neutralGrey1};
`;

const StyledGrid = styled(Grid)`
  &&&& {
    margin: 0;
    height: 100%;
    .row {
      padding: 0;
    }
  }
`;

const StyledTab = styled(Tab)`
  overflow-x: auto;

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-right: ${props => props.theme.pxToRem(-18)};
    margin-left: ${props => props.theme.pxToRem(-18)};
  }

  .ui.text.menu {
    display: inline-flex;

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-right: ${props => props.theme.pxToRem(18)};
      padding-left: ${props => props.theme.pxToRem(18)};
    }

    .item {
      margin-right: ${props => props.theme.pxToRem(60)};

      ${props => props.theme.mediaQueries.mobileOnly} {
        margin-right: ${props => props.theme.pxToRem(40)};
      }

      :last-child {
        margin-right: 0;
      }
    }
  }
`;

// Common components
const StyledPreferencesHeading = styled(Heading)`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  margin-top: 0;
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(29)};
  }
`;
