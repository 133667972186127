import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AdminApp } from 'components/App/AdminApp';
import { isAuthSelector } from 'modules/user/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { isAdminLogged, getAdminFeatureToggleByKeySelector, adminClientRoleSelector } from 'modules/admin/selectors';
import { IS_ADMIN_SITE_OFFLINE } from 'modules/admin/adminFeatureToggles';
import { checkAdminClientRoleChosen, checkAdminSession } from 'modules/admin/actions';

const mapActionCreators = (dispatch: Dispatch) => ({
  checkAdminSession(): void {
    dispatch(checkAdminSession());
  },
  checkAdminClientRoleChosen(): void {
    dispatch(checkAdminClientRoleChosen());
  },
});

const mapStateToProps = (state: State.Root) => ({
  isAdminPortal: isAdminPortalSelector(state),
  isAdminSiteOffline: getAdminFeatureToggleByKeySelector(state, IS_ADMIN_SITE_OFFLINE),
  // IMPORTANT isAuthSelector represents impersonation!!
  isImpersonating: isAuthSelector(state),
  adminAuthStatus: isAdminLogged(state),
  adminClientRole: adminClientRoleSelector(state),
});

export const AdminAppContainer = connect(mapStateToProps, mapActionCreators)(AdminApp);
