import React from 'react';
import { Salesforce } from 'mxp-schemas';
import {
  OrganizationFirmInformationSection,
  OrganizationFirmInformationSimpleTable,
  OrganizationFirmInformationStripedTable,
  SimpleTableRow,
  SimpleTableCell,
  StripedTableRow,
  StripedTableCell,
} from 'components/organisms/OrganizationFirmInformation/';
import { TablePlaceholder } from 'components/atoms';
import { getFirmNaming } from 'utils';

interface Props {
  selectedOrganization: Salesforce.Organization | null;
  organizationAdmins: Salesforce.Contact[][];
  organizationAdminsIsFetched: boolean;
  getOrganizations: () => void;
  getOrganizationAdmins: () => void;
  useCimaFeatures: boolean;
}

export const OrganizationFirmInformation: React.FC<Props> = ({
  selectedOrganization,
  organizationAdmins,
  organizationAdminsIsFetched,
  getOrganizations,
  getOrganizationAdmins,
  useCimaFeatures,
}) => {
  React.useEffect(() => {
    if (!selectedOrganization) getOrganizations();
  }, [selectedOrganization, getOrganizations]);

  React.useEffect(() => {
    getOrganizationAdmins();
  }, [getOrganizationAdmins]);

  if (!selectedOrganization || !organizationAdmins) return <TablePlaceholder />;

  const {
    name,
    billingAddress: { addressLine1, city, state, zipCode, country },
    phone,
    type,
  } = selectedOrganization as Salesforce.Organization;

  const [b2bAdmins, firmBillingAdmins, centerAdmins] = organizationAdmins;

  return (
    <>
      <OrganizationFirmInformationSection heading={`${getFirmNaming(useCimaFeatures)} details`}>
        <OrganizationFirmInformationSimpleTable>
          <SimpleTableRow>
            <SimpleTableCell>Organization Name</SimpleTableCell>
            <SimpleTableCell>{name}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>Organization Type</SimpleTableCell>
            <SimpleTableCell>{type}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>Address</SimpleTableCell>
            <SimpleTableCell>{addressLine1}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>City</SimpleTableCell>
            <SimpleTableCell>{city}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>State/Province</SimpleTableCell>
            <SimpleTableCell>{state}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>Zip/Postal Code</SimpleTableCell>
            <SimpleTableCell>{zipCode}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>Country</SimpleTableCell>
            <SimpleTableCell>{country}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>Phone Number</SimpleTableCell>
            <SimpleTableCell>{phone}</SimpleTableCell>
          </SimpleTableRow>
        </OrganizationFirmInformationSimpleTable>
      </OrganizationFirmInformationSection>
      {organizationAdminsIsFetched && organizationAdmins.length > 0 ? (
        <OrganizationFirmInformationSection heading="Admins">
          <OrganizationFirmInformationStripedTable heading="B2B Admins">
            {b2bAdmins.map(({ id, firstName, lastName, email }) => (
              <StripedTableRow key={id}>
                <StripedTableCell>
                  <label>{`${firstName} ${lastName}`}</label>
                  <label>{`${email}`}</label>
                </StripedTableCell>
              </StripedTableRow>
            ))}
          </OrganizationFirmInformationStripedTable>

          <OrganizationFirmInformationStripedTable heading="Firm Billing Admins">
            {firmBillingAdmins.map(({ id, firstName, lastName, email }) => (
              <StripedTableRow key={id}>
                <StripedTableCell>
                  <label>{`${firstName} ${lastName}`}</label>
                  <label>{`${email}`}</label>
                </StripedTableCell>
              </StripedTableRow>
            ))}
          </OrganizationFirmInformationStripedTable>

          <OrganizationFirmInformationStripedTable heading="Center Admins">
            {centerAdmins.map(({ id, firstName, lastName, email }) => (
              <StripedTableRow key={id}>
                <StripedTableCell>
                  <label>{`${firstName} ${lastName}`}</label>
                  <label>{`${email}`}</label>
                </StripedTableCell>
              </StripedTableRow>
            ))}
          </OrganizationFirmInformationStripedTable>
        </OrganizationFirmInformationSection>
      ) : (
        <TablePlaceholder />
      )}
    </>
  );
};
