import React from 'react';
import styled from 'styled-components';
import { SocialIcon } from '../SocialIcon/SocialIcon';
import { SocialIconType, SocialIconLink } from '../../../constants';

interface Props {
  title: string;
  testId: string;
  icons: SocialIconType[];
}

export const SocialIconsGroup: React.FC<Props> = ({ title, testId, icons }) => {
  const onClickHandler = (url: string) => {
    window.open(url);
  };

  return (
    <StyledWrapper data-testid={testId}>
      <StyledTitle>{title}</StyledTitle>
      {!!icons.length &&
        icons.map((icon: SocialIconType) => {
          return (
            <SocialIcon
              key={`social-icon-${icon}`}
              url={SocialIconLink[icon]}
              icon={icon}
              onClickHandler={onClickHandler}
            />
          );
        })}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin: 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-top: ${props => props.theme.pxToRem(0)};
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledTitle = styled.h4`
  font-size: ${props => props.theme.fontSizes.l};
  line-height: ${props => props.theme.pxToRem(24)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: normal;
  margin: 0 0 ${props => props.theme.pxToRem(20)};
  padding-top: ${props => props.theme.pxToRem(32)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-top: ${props => props.theme.pxToRem(0)};
  }
`;
