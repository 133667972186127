import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { CaliforniaLaw } from 'components/pages/PageProfile/CaliforniaLaw';
import { isAuthSelector } from 'modules/user/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({});

const mapStateToProps = (state: State.Root) => ({
  isAuth: isAuthSelector(state),
});

export const CaliforniaFormContainer = connect(mapStateToProps, mapActionCreators)(CaliforniaLaw);
