import React from 'react';
import { StrictDropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { InputWithValidation } from 'components/molecules';
import { Dropdown } from 'components/atoms';
import { InquiryForm as InquiryFormUtils, Utils } from 'mxp-utils';
import { InquiryForm } from 'mxp-schemas';

interface InquiryFormBulkOrderAddressFieldsProps {
  addressType: string;
  address: State.Address;
  setAddressValid: (isValid: boolean) => void;
  setAddress: (address: State.Address) => void;
}

export const InquiryFormBulkOrderAddressFields: React.FC<InquiryFormBulkOrderAddressFieldsProps> = ({
  addressType,
  address,
  setAddressValid,
  setAddress,
}) => {
  const isLine1Valid = Utils.isShorterThan(address.addressLine1, InquiryForm.FieldLengths.BULK_ORDER_ADDRESS_FIELD);
  const isLine2Valid = Utils.isShorterThan(
    address.addressLine2 || '',
    InquiryForm.FieldLengths.BULK_ORDER_ADDRESS_FIELD
  );
  const isCityValid = Utils.isShorterThan(address.city, InquiryForm.FieldLengths.BULK_ORDER_ADDRESS_FIELD);
  const isStateValid = Utils.isShorterThan(address.state, InquiryForm.FieldLengths.BULK_ORDER_ADDRESS_FIELD);
  const isZipValid = Utils.isShorterThan(address.zipCode, InquiryForm.FieldLengths.BULK_ORDER_ADDRESS_FIELD);
  const isLine1Empty = !Boolean(address.addressLine1.length);
  const isLine2Empty = !Boolean(address.addressLine2?.length);
  const isCityEmpty = !Boolean(address.city.length);
  const isStateEmpty = !Boolean(address.state.length);
  const isZipEmpty = !Boolean(address.zipCode.length);
  const isCountryEmpty = !Boolean(address.country.length);

  React.useEffect(() => {
    const addressValid =
      !isLine1Empty &&
      isLine1Valid &&
      (isLine2Empty || isLine2Valid) &&
      !isCityEmpty &&
      isCityValid &&
      !isStateEmpty &&
      isStateValid &&
      !isZipEmpty &&
      isZipValid &&
      !isCountryEmpty;
    setAddressValid(addressValid);
  }, [
    isLine1Empty,
    isLine2Empty,
    isCityEmpty,
    isStateEmpty,
    isZipEmpty,
    isCountryEmpty,
    isLine1Valid,
    isLine2Valid,
    isCityValid,
    isStateValid,
    isZipValid,
    setAddressValid,
  ]);

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress({ ...address, [event.target.name]: event.target.value });
  };
  const handleCountryDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedCountry: string = data.value as string;
      setAddress({ ...address, country: selectedCountry });
    },
    [address, setAddress]
  );
  const fieldMaxLength = (length: number) => `Sorry, that's too long. Must be fewer than ${length} characters.`;

  return (
    <>
      <InputWithValidation
        labelName={'Address Line 1'}
        name="addressLine1"
        type="text"
        value={address.addressLine1}
        testId={`${addressType}-line-1`}
        labelId={`${addressType}-line-1-label`}
        onChange={handleAddressChange}
        isCorrect={isLine1Valid || isLine1Empty}
        placeholder="Street address or P.O. Box"
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.BULK_ORDER_ADDRESS_FIELD)}`}
      />
      <InputWithValidation
        labelName={'Address Line 2 (Optional)'}
        name="addressLine2"
        type="text"
        value={address.addressLine2 || ''}
        testId={`${addressType}-line-2`}
        labelId={`${addressType}-line-2-label`}
        onChange={handleAddressChange}
        isCorrect={isLine2Valid || isLine2Empty}
        placeholder="Apartment, suite, unit, building, floor, etc."
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.BULK_ORDER_ADDRESS_FIELD)}`}
      />
      <InputWithValidation
        labelName={'City'}
        name="city"
        type="text"
        value={address.city}
        testId={`${addressType}-city`}
        labelId={`${addressType}-city`}
        onChange={handleAddressChange}
        isCorrect={isCityValid || isCityEmpty}
        placeholder="New York"
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.BULK_ORDER_ADDRESS_FIELD)}`}
      />
      <InputWithValidation
        labelName={'State/Province'}
        name="state"
        type="text"
        value={address.state}
        testId={`${addressType}-state`}
        labelId={`${addressType}-state`}
        onChange={handleAddressChange}
        isCorrect={isStateValid || isStateEmpty}
        placeholder="NY"
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.BULK_ORDER_ADDRESS_FIELD)}`}
      />
      <InputWithValidation
        labelName="ZIP/Postal Code"
        name="zipCode"
        type="text"
        value={address.zipCode}
        testId={`${addressType}-zip`}
        labelId={`${addressType}-zip`}
        onChange={handleAddressChange}
        isCorrect={isZipValid || isZipEmpty}
        placeholder="10105-0015"
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.BULK_ORDER_ADDRESS_FIELD)}`}
      />
      <DropDownContainer>
        <Label>Country/Region</Label>
        <StyledDropdown
          name="country"
          testId={InquiryForm.FieldIds.COUNTRY_CODE}
          onChange={handleCountryDropdownChange}
          options={InquiryFormUtils.CountriesList}
          value={address.country}
          placeholder="Select country/region"
          search={true}
        />
      </DropDownContainer>
    </>
  );
};

const DropDownContainer = styled.div`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(26)};
  }
`;

const Label = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      width: 100%;
      height: ${props.theme.pxToRem(48)};
      padding-right: ${props.theme.pxToRem(10)};
      background-color: ${props.theme.colors.neutralWhite};

      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }

      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.primaryPurple};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }

      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.primaryPurple};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }

      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.interfaceBlue};
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
    }
  `}
`;
