import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Button, ButtonEnums, Heading, Grid, Input, Expandable } from 'components/atoms';
import { DropdownWithLabel } from 'components/molecules';
import { Divider } from 'semantic-ui-react';
import { CheckoutCountriesListHash, countriesWithStates } from 'mxp-utils';
import ReCAPTCHA from 'react-google-recaptcha';
import { Directory } from 'mxp-schemas';
import { Plus, Minus } from 'components/atoms/svg';
import { IndividualSearch } from 'mxp-schemas/src/types/membership';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';

interface Props {
  directoryInformation: string[];
  changeIndividualFilter(individualSearchObject: IndividualSearch): any;
  loading: boolean;
  useReCaptchaDisabled?: boolean;
  reCAPTCHAKey?: string;
}

const optionCountries = Object.values(CheckoutCountriesListHash).map((country: any, index) => {
  return { key: index + 1, value: country.ISOAlpha3Code, text: country.text, image: country.image };
});

optionCountries.unshift({ key: 0, value: '', text: 'N/A', image: IconClose });

export const MemberCredentialHolderDirectory: React.FC<Props> = ({
  changeIndividualFilter,
  useReCaptchaDisabled,
  reCAPTCHAKey,
}) => {
  // This data structure allows all for accordions to be populated concurrently and searched independently
  const defaultState: any = { 1: '', 2: '', 3: '', 4: '' };

  // Accordion open state
  const accordionState: any = { 1: false, 2: false, 3: false, 4: false };

  // Variables for forms
  // State update called for picklists, useRef used for text entry to keep form fast
  const firstName = React.useRef<any>({ 1: '', 2: '', 3: '', 4: '' });
  const lastName = React.useRef<any>({ 1: '', 2: '', 3: '', 4: '' });
  const [credentialType, setCredentialType] = useState(defaultState);
  const [membershipTypeValue, setMembershipTypeValue] = useState(defaultState);
  const [country, setCountry] = useState(defaultState);
  const state = React.useRef<any>({ 1: '', 2: '', 3: '', 4: '' });
  const city = React.useRef<any>({ 1: '', 2: '', 3: '', 4: '' });
  const zipCode = React.useRef<any>({ 1: '', 2: '', 3: '', 4: '' });
  const [expertiseArea, setExpertiseArea] = useState(defaultState);
  const [within, setWithin] = useState(defaultState);
  const company = React.useRef<any>({ 1: '', 2: '', 3: '', 4: '' });
  const [stateCheck, setStateCheck] = useState<number>(0);

  // State variables
  const [isOpenCaptcha, setIsOpenCaptcha] = useState(accordionState);
  const [openAccordion, setOpenAccordion] = useState(accordionState);

  // Listener to trigger field validations for pasted text
  const handlePaste = (searchType: any, field: string) => (event: any) => {
    const pastedText = event.clipboardData.getData('Text');
    if (field === 'lastName') {
      lastName.current[searchType] = pastedText;
    }
    if (field === 'firstName') {
      firstName.current[searchType] = pastedText;
    }
    if (field === 'state') {
      state.current[searchType] = pastedText;
    }
    if (field === 'zipCode') {
      zipCode.current[searchType] = pastedText;
    }
    if (pastedText.length >= 2) {
      setStateCheck(stateCheck + 1);
    }
  };

  // Input function handlers
  const handleFirstNameInputChange = (searchType: number) => (event: any, data: any) => {
    firstName.current[searchType] = data.value;
    if (firstName.current[searchType].length <= 1 && searchType !== 4) {
      setStateCheck(stateCheck + 1);
    }
  };
  const handleLastNameInputChange = (searchType: number) => (event: any, data: any) => {
    lastName.current[searchType] = data.value;
    if (lastName.current[searchType].length <= 2) {
      setStateCheck(stateCheck + 1);
    }
  };
  const handleCredentialTypeDropdownSelection = (searchType: number) => (event: any, data: any) =>
    setCredentialType({ ...credentialType, [searchType]: data.value });
  const handleMembershipTypeDropdownSelection = (searchType: number) => (event: any, data: any) =>
    setMembershipTypeValue({ ...membershipTypeValue, [searchType]: data.value });
  const handleCountryDropdownSelection = (searchType: number) => (event: any, data: any) => {
    setCountry({ ...country, [searchType]: data.value });
  };
  const handleStateInputChange = (searchType: number) => (event: any, data: any) => {
    state.current[searchType] = data.value;
    if (state.current[searchType].length <= 2 && country[searchType] === 'USA') {
      setStateCheck(stateCheck + 1);
    }
  };
  const handleCityInputChange = (searchType: number) => (event: any, data: any) => {
    city.current[searchType] = data.value;
    if (city.current[searchType].length <= 1) {
      setStateCheck(stateCheck + 1);
    }
  };
  const handleZipCodeInputChange = (searchType: number) => (event: any, data: any) => {
    zipCode.current[searchType] = data.value;
    if (zipCode.current[searchType].length <= 2) {
      setStateCheck(prevState => prevState + 1);
    }
  };
  const handleExpertiseDropdownSelection = (searchType: number) => (event: any, data: any) =>
    setExpertiseArea({ ...expertiseArea, [searchType]: data.value });
  const handleMileRadiusDropdownSelection = (searchType: number) => (event: any, data: any) =>
    setWithin({ ...within, [searchType]: data.value });
  const handleCompanyInputChange = (searchType: number) => (event: any, data: any) => {
    company.current[searchType] = data.value;
    if (company.current[searchType].length <= 1) {
      setStateCheck(stateCheck + 1);
    }
  };

  const setAccordionOpen = (searchType: number) => {
    setOpenAccordion({ ...openAccordion, [searchType]: !openAccordion[searchType] });
  };

  const getSearchObject = useCallback(
    (searchType: number) => {
      return {
        searchType,
        firstName: firstName.current[searchType] ? firstName.current[searchType] : null,
        lastName: lastName.current[searchType] ? lastName.current[searchType] : null,
        country: country[searchType] ? country[searchType] : null,
        city: city.current[searchType] ? city.current[searchType] : null,
        state: state.current[searchType] ? state.current[searchType] : null,
        within: !zipCode.current[searchType] ? null : within[searchType] ? within[searchType] : null,
        zipCode: zipCode.current[searchType] ? zipCode.current[searchType] : null,
        company: company.current[searchType] ? company.current[searchType] : null,
        expertiseArea: expertiseArea[searchType] ? expertiseArea[searchType] : null,
        membershipType: membershipTypeValue[searchType] ? membershipTypeValue[searchType] : null,
        credential: credentialType[searchType] ? credentialType[searchType] : null,
        fromForm: true,
        pageNumber: 1,
        pageSize: 12,
      };
    },
    [
      firstName,
      lastName,
      country,
      city,
      state,
      zipCode,
      within,
      company,
      expertiseArea,
      membershipTypeValue,
      credentialType,
    ]
  );

  // Search function
  const handleOnSearch = useCallback(
    async (searchType: number) => {
      const searchObject: IndividualSearch = getSearchObject(searchType);

      // Remove unused variables from querystring (clean URL)
      for (const key in searchObject) {
        if (!searchObject[key as keyof IndividualSearch]) {
          delete searchObject[key as keyof IndividualSearch];
        }
      }

      // Pick URL params and apply filters
      await changeIndividualFilter(searchObject);
    },
    [changeIndividualFilter, getSearchObject] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const handleOpenCaptcha = useCallback(
    (searchType: number) => {
      setIsOpenCaptcha({ ...isOpenCaptcha, [searchType]: !isOpenCaptcha[searchType] });
    },
    [setIsOpenCaptcha, isOpenCaptcha]
  );

  // Check field validations
  const checkFieldValidations = useCallback(
    (searchType: number) => {
      // For all validations, we need a state selected if the country is USA

      // First accordion: Needs credential type AND EITHER last name OR country OR zipcode
      if (searchType === 1) {
        return (credentialType[1] && lastName.current[1].length >= 2 && country[1] !== 'USA') ||
          (credentialType[1] && country[1] && country[1] !== 'USA') ||
          (credentialType[1] && country[1] === 'USA' && state.current[1]) ||
          (credentialType[1] && zipCode.current[1] && country[1] !== 'USA')
          ? false
          : true;
      }

      // Any field optional but state disabled, and last name must be longer than 2 letters
      if (searchType === 2) {
        return Boolean(lastName.current[2] && lastName.current[2].length < 2) ? true : false;
      }

      // Same as above but last name mandatory
      if (searchType === 3) {
        return lastName.current[3].length < 2 ? true : false;
      }

      // Same as above but last name mandatory
      if (searchType === 4) {
        return lastName.current[4].length < 2 ? true : false;
      }
    },
    [credentialType, lastName, country, state, zipCode]
  );

  const isNotUSA = (int: number) => country[int] !== 'USA';
  const isStateDisabled = (int: number) => !countriesWithStates.includes(country[int]);

  const isZipcodeDisabled = (int: number) => (zipCode.current[int] ? false : true);

  const handleSearchClick = (int: number) =>
    useReCaptchaDisabled ? handleOnSearch.bind(null, int) : handleOpenCaptcha.bind(null, int);

  const renderReCaptcha = (searchType: number) => {
    return <ReCAPTCHA sitekey={reCAPTCHAKey} onChange={handleOnSearch.bind(null, searchType)} />;
  };

  // Render accordion
  return (
    <StyledContainer>
      <Expandable
        title=""
        onClick={setAccordionOpen.bind(null, 1)}
        expandIcon={<Plus testId="topics-expand-icon" />}
        collapseIcon={<Minus testId="topics-collapse-icon" />}
        divided
        items={[
          {
            id: 1,
            heading: 'Find a credential/designation holder',
            content: (
              <StyledExpandableContainer>
                <StyledGrid columns="equal">
                  <StyledGridColumn>
                    <StyledHeading as="h4">
                      All credential/designation holders must be members of the AICPA or CIMA and meet specific
                      experience, education, and ethics requirements. Credential/designation holders are bound by high
                      professional and ethical standards and are required to continue professional development.
                    </StyledHeading>
                  </StyledGridColumn>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledDropdown
                        title="*Credential/designation name"
                        placeholder="Select"
                        value={credentialType[1]}
                        options={Directory.DirectoryCredentialType}
                        onChange={handleCredentialTypeDropdownSelection(1)}
                      />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledText>
                      Select at least one of the following required fields: Name, location, or ZIP/postal code.
                    </StyledText>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="*Last name"
                        labelId="last-name"
                        onChange={handleLastNameInputChange(1)}
                        testId="credential-holder-last-name"
                        onPaste={handlePaste(1, 'lastName')}
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="First name"
                        labelId="first-name"
                        onChange={handleFirstNameInputChange(1)}
                        data-custom={1}
                        testId="credential-holder-first-name"
                      />
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledText>or</StyledText>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledDropdown
                        title="*Location"
                        placeholder="Select"
                        options={optionCountries}
                        value={country[1]}
                        onChange={handleCountryDropdownSelection(1)}
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledInput
                        labelName={isNotUSA(1) ? 'State' : 'State *'}
                        disabled={isStateDisabled(1)}
                        value={isNotUSA(1) ? '' : undefined}
                        labelId="state"
                        onChange={handleStateInputChange(1)}
                        onPaste={handlePaste(1, 'state')}
                        testId="credential-holder-state"
                      />
                      {!state.current[1] && !isNotUSA(1) && (
                        <StyledErrorMessage>This field is required</StyledErrorMessage>
                      )}
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="City"
                        labelId="city"
                        onChange={handleCityInputChange(1)}
                        testId="credential-holder-city"
                      />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledText>If you are searching within the U.S., please provide the state.</StyledText>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledText>OR</StyledText>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="*ZIP/postal code"
                        labelId="zipcode"
                        onChange={handleZipCodeInputChange(1)}
                        onPaste={handlePaste(1, 'zipCode')}
                        testId="credential-holder-city"
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledDropdown
                        title="Mile radius"
                        placeholder="Select"
                        disabled={isZipcodeDisabled(1)}
                        value={zipCode.current[1] ? within[1] : null}
                        options={Directory.MileRadius}
                        onChange={handleMileRadiusDropdownSelection(1)}
                      />
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledText>Optional:</StyledText>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledDropdown
                        title="Area of expertise"
                        placeholder="Select"
                        value={expertiseArea[1]}
                        options={Directory.ExpertiseArea}
                        onChange={handleExpertiseDropdownSelection(1)}
                      />
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                    <StyledEmptyGridColumn />
                  </StyledGridRow>

                  <StyledGridRow>
                    <StyledGridColumn>
                      <Divider />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledEmptyGridColumn />
                    <StyledGridColumn>
                      {isOpenCaptcha[1] ? (
                        renderReCaptcha(1)
                      ) : (
                        <StyledSearchButton
                          disabled={checkFieldValidations(1)}
                          testId="search-button"
                          size={ButtonEnums.sizes.small}
                          onClick={handleSearchClick(1)}
                        >
                          Search
                        </StyledSearchButton>
                      )}
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                </StyledGrid>
              </StyledExpandableContainer>
            ),
          },
        ]}
      />
      {!openAccordion[1] && (
        <StyledExpandableHeading as="h4">
          Search to secure the services of or verify an AICPA or CIMA credential/designation holder
        </StyledExpandableHeading>
      )}
      <Expandable
        title=""
        onClick={setAccordionOpen.bind(null, 2)}
        expandIcon={<Plus testId="topics-expand-icon" />}
        collapseIcon={<Minus testId="topics-collapse-icon" />}
        divided
        items={[
          {
            id: 2,
            heading: 'Find a CIMA Member in Practice (MiP)',
            content: (
              <StyledExpandableContainer>
                <StyledGrid columns="equal">
                  <StyledGridRow>
                    <StyledHeading as="h4">
                      All CIMA members who work in practice must hold a current CIMA Practising Certificate and remain
                      compliant with Member in Practice (MiP) rules. MiPs are bound by high professional and ethical
                      standards, are required to continue professional development and are supervised for anti-money
                      laundering compliance.
                    </StyledHeading>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledText>
                      Select at least one of the following fields: Name, location, or ZIP/postal code, area of expertise
                      or company name.
                    </StyledText>
                  </StyledGridRow>
                  <StyledGridRow>
                    {' '}
                    <StyledGridColumn>
                      <StyledDropdown
                        title="Location"
                        placeholder="Select"
                        options={optionCountries}
                        value={country[2]}
                        onChange={handleCountryDropdownSelection(2)}
                      />
                    </StyledGridColumn>
                    {/* <StyledGridRow> */}
                    <StyledGridColumn>
                      <StyledInput
                        labelName={'State'}
                        labelId="state"
                        onChange={handleStateInputChange(2)}
                        disabled={isStateDisabled(2)}
                        onPaste={handlePaste(2, 'state')}
                        testId="credential-holder-state"
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="City"
                        labelId="city"
                        onChange={handleCityInputChange(2)}
                        testId="credential-holder-city"
                      />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="ZIP/postal code"
                        labelId="zipcode"
                        onChange={handleZipCodeInputChange(2)}
                        onPaste={handlePaste(2, 'zipCode')}
                        testId="credential-holder-city"
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledDropdown
                        title="Mile radius"
                        placeholder="Select"
                        disabled={isZipcodeDisabled(2)}
                        value={zipCode.current[2] ? within[2] : null}
                        options={Directory.MileRadius}
                        onChange={handleMileRadiusDropdownSelection(2)}
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledDropdown
                        title="Area of expertise"
                        placeholder="Select"
                        value={expertiseArea[2]}
                        options={Directory.ExpertiseArea}
                        onChange={handleExpertiseDropdownSelection(2)}
                      />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="First name"
                        labelId="first-name"
                        onChange={handleFirstNameInputChange(2)}
                        testId="credential-holder-first-name"
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="Last name"
                        labelId="last-name"
                        onPaste={handlePaste(2, 'lastName')}
                        onChange={handleLastNameInputChange(2)}
                        testId="credential-holder-last-name"
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="Company"
                        labelId="company"
                        onChange={handleCompanyInputChange(2)}
                        testId="credential-holder-company"
                      />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <Divider />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledEmptyGridColumn />
                    <StyledGridColumn>
                      {isOpenCaptcha[2] ? (
                        renderReCaptcha(2)
                      ) : (
                        <StyledSearchButton
                          disabled={checkFieldValidations(2)}
                          testId="search-button"
                          size={ButtonEnums.sizes.small}
                          onClick={handleSearchClick(2)}
                        >
                          Search
                        </StyledSearchButton>
                      )}
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                </StyledGrid>
              </StyledExpandableContainer>
            ),
          },
        ]}
      />
      {!openAccordion[2] && (
        <StyledExpandableHeading as="h4">
          Search to find an accountant with a verified CIMA practising certificate
        </StyledExpandableHeading>
      )}
      <Expandable
        title=""
        onClick={setAccordionOpen.bind(null, 3)}
        expandIcon={<Plus testId="topics-expand-icon" />}
        collapseIcon={<Minus testId="topics-collapse-icon" />}
        divided
        items={[
          {
            id: 3,
            heading: 'Find an AICPA or CIMA member',
            content: (
              <StyledExpandableContainer>
                <StyledGrid columns="equal">
                  <StyledGridColumn>
                    <StyledHeading as="h4">
                      Members compose an influential body of accounting and finance professionals across 192 countries
                      and territories. Required to continue professional development, members are bound by high
                      professional and ethical standards to maintain active AICPA or CIMA membership in good standing.
                    </StyledHeading>
                  </StyledGridColumn>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="*Last name"
                        labelId="last-name"
                        onChange={handleLastNameInputChange(3)}
                        onPaste={handlePaste(3, 'lastName')}
                        testId="credential-holder-last-name"
                      />
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledText>Optional:</StyledText>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="First name"
                        labelId="first-name"
                        onChange={handleFirstNameInputChange(3)}
                        testId="credential-holder-first-name"
                      />
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledDropdown
                        title="Location"
                        placeholder="Select"
                        options={optionCountries}
                        value={country[3]}
                        onChange={handleCountryDropdownSelection(3)}
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledInput
                        labelName={'State'}
                        labelId="state"
                        disabled={isStateDisabled(3)}
                        onPaste={handlePaste(3, 'state')}
                        onChange={handleStateInputChange(3)}
                        testId="credential-holder-state"
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="City"
                        labelId="city"
                        onChange={handleCityInputChange(3)}
                        testId="credential-holder-city"
                      />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledDropdown
                        clearable
                        title="Member type"
                        placeholder="Select"
                        options={Directory.MembershipDirectoryTypes} // change to correct array
                        onChange={handleMembershipTypeDropdownSelection(3)}
                        disabled={undefined}
                      />
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <Divider />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledEmptyGridColumn />
                    <StyledGridColumn>
                      {isOpenCaptcha[3] ? (
                        renderReCaptcha(3)
                      ) : (
                        <StyledSearchButton
                          disabled={checkFieldValidations(3)}
                          testId="search-button"
                          size={ButtonEnums.sizes.small}
                          onClick={handleSearchClick(3)}
                        >
                          Search
                        </StyledSearchButton>
                      )}
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                </StyledGrid>
              </StyledExpandableContainer>
            ),
          },
        ]}
      />
      {!openAccordion[3] && (
        <StyledExpandableHeading as="h4">
          Search to verify the status of an AICPA or CIMA member.
        </StyledExpandableHeading>
      )}
      <Expandable
        title=""
        onClick={setAccordionOpen.bind(null, 4)}
        expandIcon={<Plus testId="topics-expand-icon" />}
        collapseIcon={<Minus testId="topics-collapse-icon" />}
        divided
        items={[
          {
            id: 4,
            heading: 'Find a CGMA Candidate ',
            content: (
              <StyledExpandableContainer>
                <StyledGrid columns="equal">
                  <StyledGridColumn>
                    <StyledHeading as="h4">
                      Verify the progress and status of a CGMA candidate as they progress through the CGMA syllabus.
                    </StyledHeading>
                  </StyledGridColumn>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="*Last name"
                        labelId="last-name"
                        onChange={handleLastNameInputChange(4)}
                        onPaste={handlePaste(4, 'lastName')}
                        testId="credential-holder-last-name"
                      />
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledText>Optional:</StyledText>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="First name"
                        labelId="first-name"
                        onChange={handleFirstNameInputChange(4)}
                        testId="credential-holder-first-name"
                      />
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <StyledDropdown
                        title="Location"
                        placeholder="Select"
                        options={optionCountries}
                        value={country[4]}
                        onChange={handleCountryDropdownSelection(4)}
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledInput
                        labelName={'State'}
                        labelId="state"
                        disabled={isStateDisabled(4)}
                        onChange={handleStateInputChange(4)}
                        onPaste={handlePaste(4, 'state')}
                        testId="credential-holder-state"
                      />
                    </StyledGridColumn>
                    <StyledGridColumn>
                      <StyledInput
                        labelName="City"
                        labelId="city"
                        onChange={handleCityInputChange(4)}
                        testId="credential-holder-city"
                      />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledGridColumn>
                      <Divider />
                    </StyledGridColumn>
                  </StyledGridRow>
                  <StyledGridRow>
                    <StyledEmptyGridColumn />
                    <StyledGridColumn>
                      {isOpenCaptcha[4] ? (
                        renderReCaptcha(4)
                      ) : (
                        <StyledSearchButton
                          disabled={checkFieldValidations(4)}
                          testId="search-button"
                          size={ButtonEnums.sizes.small}
                          onClick={handleSearchClick(4)}
                        >
                          Search
                        </StyledSearchButton>
                      )}
                    </StyledGridColumn>
                    <StyledEmptyGridColumn />
                  </StyledGridRow>
                </StyledGrid>
              </StyledExpandableContainer>
            ),
          },
        ]}
      />
      {!openAccordion[4] && (
        <StyledExpandableHeading as="h4">
          Search to verify if someone is currently studying for the CGMA designation and find individuals who have
          completed the required examinations but have not yet proceeded to CIMA membership.
        </StyledExpandableHeading>
      )}
    </StyledContainer>
  );
};

const StyledErrorMessage = styled.p`
  color: ${props => props.theme.colors.interfaceRed};
  font-size: ${props => props.theme.fontSizes.xs};
`;

const StyledText = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-left: ${props => props.theme.pxToRem(10)}; ;
`;

const StyledSearchButton = styled(Button)`
  &&&& {
    width: ${props => props.theme.pxToRem(181)};
    height: ${props => props.theme.pxToRem(48)};
    border: ${props => props.theme.pxToRem(0)};
    background-color: ${props => props.theme.colors.primaryPurple};
    color: ${props => props.theme.colors.neutralWhite};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 0 auto;
      margin-right: 0 auto;
    }
  }
`;

const StyledHeading = styled(Heading)`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-top: ${props => props.theme.pxToRem(5)};
  margin-bottom: ${props => props.theme.pxToRem(5)};
`;

const StyledExpandableHeading = styled(Heading)`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-top: ${props => props.theme.pxToRem(5)};
`;

const StyledGridColumn = styled(Grid.Column)`
  ${props => props.theme.mediaQueries.mobileOnly} {
    &&&& {
      width: 100% !important;
      align-items: center;
      justify-content: center;
    }
  }
`;

const StyledEmptyGridColumn = styled(Grid.Column)`
  ${props => props.theme.mediaQueries.mobileOnly} {
    &&&& {
      width: 100% !important;
      padding: 0 0 0 0 !important
      height: ${props => props.theme.pxToRem(0)};
    }
  }
`;

const StyledGridRow = styled(Grid.Row)`
  ${props => props.theme.mediaQueries.mobileOnly} {
    &&&& {
      align-items: center;
      justify-content: center;
    }
  }
`;

const StyledGrid = styled(Grid)`
  background-color: ${props => props.theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(5)};
  padding: ${props => props.theme.pxToRem(15)} ${props => props.theme.pxToRem(75)} !important;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 2.5% !important;
  }
`;

const StyledInput = styled(Input)`
  &&&.ui.input > input {
    width: ${props => props.theme.pxToRem(233)};
    margin-bottom: ${props => props.theme.pxToRem(10)};
    :focus {
      border-color: ${props => props.theme.colors.primaryPurple};
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(5)};
    }
  }
  &&&.disabled {
    input {
      border-color: ${props => props.theme.colors.neutralGrey8};
      background-color: ${props => props.theme.colors.neutralGrey3};
    }
  }
`;

const StyledDropdown = styled(DropdownWithLabel)`
  &&&.ui.search.dropdown {
    border-radius: ${props => props.theme.pxToRem(4)};
    width: ${props => props.theme.pxToRem(233)};
  }
  &&&.ui.dropdown,
  &&&.ui.dropdown .menu .menu {
    max-width: ${props => props.theme.pxToRem(233)};
    height: auto;
  }
  &&&.ui.search.dropdown > input.search {
    cursor: pointer;
  }
  &&&.ui.default.dropdown:not(.button) > .text,
  &&&.ui.dropdown:not(.button) > .default.text,
  &&&.ui.search.dropdown > .text,
  &&&.ui.dropdown .menu > .item {
    color: ${props => props.theme.colors.primaryPurple};
    max-width: ${props => props.theme.pxToRem(220)};
  }
  &&&.ui.dropdown .menu > * {
    white-space: normal;
  }
  &&&.disabled {
    &&&.ui.dropdown {
      border-color: ${props => props.theme.colors.neutralGrey8};
      background-color: ${props => props.theme.colors.neutralGrey3};
    }
  }
`;

const StyledContainer = styled.div`
  box-shadow: ${props => `0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(14)} 0 ${props.theme.colors.neutralGrey3}`};
  padding: ${props => props.theme.pxToRem(50)} ${props => props.theme.pxToRem(50)};
  border-radius: ${props => props.theme.pxToRem(10)};
`;

const StyledExpandableContainer = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;
