import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { handleEvent, DOWNLOAD_EVENT } from 'utils/Analytics';
import download from 'downloadjs';
import { staticLandingPageSelector } from 'modules/staticLandingPagesContent/selectors';
import { constantsSelector, getConstantByKeySelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { getDownloadDocumentsSelector } from 'modules/content/selectors';
import { ContentfulHelpers } from 'mxp-utils';
import { getDownloadContentItemBySlug } from 'modules/content/actions';
import { getFileExtension } from 'utils/StringHelpers';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CimaFcmaApplicationFormModal: React.FC<Props> = (props: Props) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const getFcmaRecord = useSelector(state => staticLandingPageSelector(state as State.Root));
  const fcmaConstantSlug = useSelector(getConstantByKeySelector(CONSTANTS.FCMA_MEMBERSHIP_LANDING_PAGE));
  const dispatch = useDispatch();
  const constants = useSelector(constantsSelector);
  const fcmaDocument = constants?.[CONSTANTS.FCMA_FILE_DOWNLOAD];
  const downloadDocumentLink = useSelector(getDownloadDocumentsSelector);
  const emailContact = useSelector(getConstantByKeySelector(CONSTANTS.FCMA_FELLOWSHIP_EMAIL));

  useEffect(() => {
    dispatch(getDownloadContentItemBySlug(fcmaDocument, ContentfulHelpers.CONTENT_TYPES.DOWNLOAD));
  }, [dispatch, fcmaDocument]);

  // trigger PDF download if user is a CIMA regular membership member for less than 10 years
  const handleDownloadClick = useCallback(async () => {
    const isFCMAJourney = getFcmaRecord?.slug === fcmaConstantSlug;

    if (isFCMAJourney) {
      const fcmaFileLink = downloadDocumentLink?.[0]?.fileLink;

      // If there are no file extension, make zip as default
      const fcmaFileName = `${downloadDocumentLink?.[0]?.name}.${
        getFileExtension(fcmaFileLink) ? getFileExtension(fcmaFileLink).toString() : 'zip'
      }`;

      handleEvent({ filename: fcmaFileName }, DOWNLOAD_EVENT);
      setIsButtonLoading(true);
      const getFcmaFile = await fetch(fcmaFileLink);
      const getFileResponse = await getFcmaFile?.blob();
      await download(getFileResponse, fcmaFileName);
      setIsButtonLoading(false);
    }
    props.onClose();
  }, [props, downloadDocumentLink, fcmaConstantSlug, getFcmaRecord]);

  return (
    <StyledModal
      showCloseCross={true}
      testId="fcma-modal"
      heading="Download the application"
      open={props.isOpen}
      onClose={props.onClose}
      confirmNode={
        <Button
          size={ButtonEnums.sizes.small}
          testId="start-download"
          variant={ButtonEnums.variants.primary}
          onClick={handleDownloadClick}
          loading={isButtonLoading}
        >
          Download
        </Button>
      }
      cancelNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          testId="cancel-download"
          onClick={props.onClose}
        >
          Cancel
        </Button>
      }
    >
      <StyledText>
        Ensure you have all the documentation ready before you begin. After completing this application, please save
        your completed documents and email them to{' '}
        <StyledContactLink href={`mailto:${emailContact}`}>{emailContact}</StyledContactLink>.
      </StyledText>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
&&&& {
    width: ${props => props.theme.pxToRem(590)};
    background: ${props => props.theme.colors.neutralWhite};
    margin-top: ${props => props.theme.pxToRem(48)};
    border-radius: ${props => props.theme.pxToRem(2)};
    box-sizing: border-box;
  }

  &&& .ui.modal ${props => props.theme.mediaQueries.mobileOnly} {
    width: 95%;
`;

const StyledText = styled.p`
  text-align: center;
  line-height: 1.57;
  margin-top: ${props => props.theme.pxToRem(28)};
  padding: 0 ${props => props.theme.pxToRem(40)} 0 ${props => props.theme.pxToRem(40)};
`;

const StyledContactLink = styled.a`
  color: ${props => props.theme.buttonColors.primary};
`;
