import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/atoms';
import { DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { TwoColumn } from 'components/templates';

interface Props {
  title: string;
  text: string;
}

export const TermsAndConditions = React.memo<Props>(({ title, text }) => {
  if (!text) {
    return null;
  }
  return (
    <StyledContainer>
      <Container>
        <TwoColumn mobileLeftWidth={16} mobileRightWidth={16} computerLeftWidth={4} computerRightWidth={12}>
          <></>
          <>
            <StyledHeader tabIndex={0}>{title}</StyledHeader>
            <StyledDescription tabIndex={0} dangerouslySetInnerHTML={{ __html: text }} />
          </>
        </TwoColumn>
      </Container>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey1};
  padding: ${props => props.theme.pxToRem(40)} 0;
  box-shadow: inset 0 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.colors.neutralGrey3};
`;

const StyledHeader = styled.h3`
  font-size: ${props => props.theme.fontSizes.xxs};

  ${props => props.theme.mediaQueries.computerMin} {
    font-size: ${props => props.theme.fontSizes.xs};
    line-height: 1.375;
  }
`;

const StyledDescription = styled.p`
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.neutralBlack};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.125;

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.computerMin} {
    font-size: ${props => props.theme.fontSizes.xs};
    color: ${props => props.theme.colors.neutralGrey8};
    line-height: 1.375;
  }
`;
