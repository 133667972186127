import React, { memo } from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { LabeledData } from 'components/atoms';
import { ProfileHeading } from 'components/molecules/ProfileHeading/ProfileHeading';
import { MembershipTypes } from 'mxp-schemas';
import { unescapeString } from 'utils/StringHelpers';

interface Props {
  practicalExpReqRecord: MembershipTypes.PERFeedback;
}

export const PracticalExpReqFeedbackDetails: React.FC<Props> = memo(({ practicalExpReqRecord }) => {
  const data = practicalExpReqRecord;

  return (
    <>
      <StyledGrid>
        <Grid.Row>
          <Grid.Column width={16}>
            <StyledProfileHeading title="Feedback" hasBorder={false} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <LabeledData label="What went well" data={unescapeString(data?.achievements ?? '') || ''} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <LabeledData label="What could be improved" data={unescapeString(data?.areasOfImprovement ?? '') || ''} />
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
    </>
  );
});

const StyledProfileHeading = styled(ProfileHeading)`
  border-top: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  margin-top: ${props => props.theme.pxToRem(20)};
  padding-top: ${props => props.theme.pxToRem(20)};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.l};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledGrid = styled(Grid)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-bottom: ${props => props.theme.pxToRem(100)};
  }
`;
