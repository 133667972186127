import React from 'react';
import styled, { css } from 'styled-components';
import { Divider, TextArea } from 'semantic-ui-react';
import { Modal } from 'components/atoms/Modal/Modal';
import { ButtonEnums, Button, Link } from 'components/atoms';

interface Props {
  open: boolean;
  onCancel?: () => void;
  confirmButtonLabel?: string;
  onConfirm: () => void;
  className?: string;
  isConfirmationInProgress?: boolean;
  testId?: string;
  header?: string;
  additionalInformation: AdditionalInformation;
  setAdditionalInformation: ({ question1, question2 }: AdditionalInformation) => void;
}

interface AdditionalInformation {
  question1: string;
  question2: string;
}

export const PartnerDeclareAdditionalInfo: React.FC<Props> = ({
  open,
  onCancel,
  testId,
  header,
  onConfirm,
  confirmButtonLabel,
  isConfirmationInProgress,
  additionalInformation,
  setAdditionalInformation,
}) => {
  const isAdditionalInformationValid =
    Boolean(additionalInformation.question1.length) && Boolean(additionalInformation.question2.length);
  const link = 'https://www.aicpa.org/research/standards/auditattest/sqcs.html';
  const isExternalLink = Boolean(new RegExp(/^http(s)?:\/\//).test(link));

  const handleQuestion1Change = React.useCallback(
    (event: any) => {
      setAdditionalInformation({ ...additionalInformation, question1: event.target.value });
    },
    [additionalInformation, setAdditionalInformation]
  );

  const handleQuestion2Change = React.useCallback(
    (event: any) => {
      setAdditionalInformation({ ...additionalInformation, question2: event.target.value });
    },
    [additionalInformation, setAdditionalInformation]
  );

  const onConfirmEvent = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onConfirm();
    },
    [onConfirm]
  );

  const renderButton = isAdditionalInformationValid ? (
    <StyledButton
      className="confirm"
      size={ButtonEnums.sizes.small}
      variant={ButtonEnums.variants.primary}
      testId="confirm"
      onClick={onConfirmEvent}
      disabled={isConfirmationInProgress}
      loading={isConfirmationInProgress}
      type="button"
    >
      {confirmButtonLabel}
    </StyledButton>
  ) : (
    <StyledButton disabled={true} testId="confirm">
      {confirmButtonLabel}
    </StyledButton>
  );

  return (
    <StyledModal
      className="partners-modal"
      open={open}
      onClose={onCancel}
      size="small"
      heading={header}
      testId={testId}
      showCloseCross
      confirmNode={renderButton}
    >
      <StyledParagraph>
        Additional information is required before we can create your public accounting firm. Please provide the
        following information, if available:
      </StyledParagraph>

      <StyledList>
        <StyledListItem>
          <StyledParagraph>
            If the public accounting firm you are creating is the result of a purchase, sale, merger, dissolution, or
            other firm change of a previously existing firm, please provide the names and AICPA firm numbers (if known)
            of all firms involved in that change,
          </StyledParagraph>
        </StyledListItem>
        <StyledTextArea value={additionalInformation.question1} onChange={handleQuestion1Change} />
        <StyledListItem>
          <StyledParagraph>
            The names and AICPA member numbers (if available) of all Partners in this firm or any related firms in part
            A above.
          </StyledParagraph>
        </StyledListItem>
        <StyledTextArea value={additionalInformation.question2} onChange={handleQuestion2Change} />
      </StyledList>

      <StyledDivider />

      <StyledList>
        <StyledListItem isSecondary={true}>
          <StyledEndParagraph>
            Partners may not use this title, having different titles, and/or may or may not have ownership in the firm.
            See AICPA Statements on Quality Control Standards for the full definition of Partner.
            <StyledLink to={link} isExternal={isExternalLink}>
              {link}
            </StyledLink>
          </StyledEndParagraph>
        </StyledListItem>
      </StyledList>
    </StyledModal>
  );
};

interface StyledListItemProps {
  isSubItem?: boolean;
  isSecondary?: boolean;
}

const StyledModal = styled(Modal)`
  position: relative;
  box-sizing: border-box;

  &&&&&&& {
    border-radius: ${props => props.theme.pxToRem(2)};
    width: ${props => props.theme.pxToRem(590)};
    box-sizing: border-box;
    padding-left: ${props => props.theme.pxToRem(28)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
    }

    .header {
      line-height: 1.33;
      margin-top: ${props => props.theme.pxToRem(12)};
      h2 {
        margin-top: ${props => props.theme.pxToRem(17)};
      }
    }

    .content {
      margin: ${props => props.theme.pxToRem(5)} auto auto;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }

    p {
      max-width: 100%;
    }
    .actions {
      padding: ${props => `${props.theme.pxToRem(20)}`} 0 0 !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        flex-direction: column-reverse;
        justify-content: center;
      }
    }

    button {
      position: absolute;
      top: ${props => props.theme.pxToRem(24)};
      right: ${props => props.theme.pxToRem(24)};
    }

    div > button {
      position: relative;
      top: 0;
      right: 0;
      width: ${props => props.theme.pxToRem(202)};
      height: ${props => props.theme.pxToRem(34)};
      padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};

      margin: auto ${props => props.theme.pxToRem(6)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100% !important;
        height: ${props => `${props.theme.pxToRem(40)}`};
        max-width: ${props => `${props.theme.pxToRem(296)}`};
        font-size: ${props => props.theme.fontSizes.s};
        border-radius: ${props => `${props.theme.pxToRem(4)}`};
        margin: 0 auto !important;
      }
    }
  }
`;

const StyledParagraph = styled.span`
  margin-top: ${props => props.theme.pxToRem(20)};
  line-height: 1.5;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
  text-align: justify;
`;

const StyledEndParagraph = styled.span`
  margin-top: ${props => props.theme.pxToRem(30)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  text-align: justify;
  color: ${props => props.theme.colors.neutralGrey4};
`;

const StyledList = styled.ol`
  margin: ${props => props.theme.pxToRem(5)};
  padding: ${props => props.theme.pxToRem(5)};
`;

const StyledListItem = styled.li<StyledListItemProps>`
  margin-top: ${props => props.theme.pxToRem(10)};
  font-weight: ${props => props.theme.fontWeights.bold};
  padding-left: ${props => props.theme.pxToRem(10)};

  ${({ isSubItem }) =>
    isSubItem &&
    css`
      margin-left: ${props => props.theme.pxToRem(15)};
    `}

  ${({ isSecondary }) =>
    isSecondary &&
    css`
      font-weight: ${props => props.theme.fontWeights.regular} !important;
      color: ${props => props.theme.colors.neutralGrey4} !important;
      font-size: ${props => props.theme.pxToRem(8)};
      padding-left: 0 !important;
    `}
`;

const StyledButton = styled(Button)`
  &&&&& {
    min-width: ${props => props.theme.pxToRem(320)};
    object-fit: contain;
    font-size: ${props => props.theme.fontSizes.xs};
    margin: 0 auto;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      font-size: ${props => props.theme.fontSizes.xxs};
    }
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(18)};
    margin-left: ${props => props.theme.pxToRem(8)};
    width: ${props => props.theme.pxToRem(425)};
    height: ${props => props.theme.pxToRem(130)};
    border-color: ${props => props.theme.colors.neutralGrey4};
    font-size: ${props => props.theme.fontSizes.s};
    color: ${props => props.theme.colors.neutralGrey8};
    padding: ${props => props.theme.pxToRem(12)};
    border-radius: 4px;
  }

  &&&&&&:focus,
  &&&&&&:active {
    outline: none;
    border-color: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: ${props => props.theme.pxToRem(18)};
  color: ${props => props.theme.colors.neutralGrey1};
`;

const StyledLink = styled(Link)`
  display: block;
  &&& {
    color: ${props => props.theme.colors.primaryPurple};
    text-decoration: none;

    :hover {
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
`;
