import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Helpers
// ------------------------------------

const initialState: State.Groups = {
  groupHash: null,
  groupsFetched: false,
  error: null,
};
// ------------------------------------
// Actions
// ------------------------------------
export const getGroupHash: any = createAction('groups/GET_GROUPS_HASH', (groups: State.Groups) => () => {
  return groups;
});

const ACTION_HANDLERS = {
  [getGroupHash]: {
    next: (state: State.Groups, action: any): State.Groups => ({
      ...state,
      groupHash: action.payload,
      groupsFetched: true,
    }),

    throw: (state: State.Groups, action: any): State.Groups => ({
      ...state,
      error: action.payload, // TODO
    }),
  },
};
// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
