import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { ReactComponent as ArrowBack } from 'resources/images/ic-arrow-back.svg';

interface Props {
  text: string;
  to: LocationDescriptor<{ prevRoute: string }>;
}

export const AdminInvoiceSearchBackButton: React.FC<Props> = ({ text, to }) => (
  <Link to={to}>
    <StyledBackButton
      testId="search-invoices-back"
      variant={ButtonEnums.variants.link}
      size={ButtonEnums.sizes.small}
      icon={<ArrowBack />}
      iconPosition={ButtonEnums.iconPosition.left}
    >
      {text}
    </StyledBackButton>
  </Link>
);

const StyledBackButton = styled(Button)`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-weight: ${props => props.theme.fontWeights.light};
    svg {
      margin-left: 0;
      path {
        fill: ${props => props.theme.colors.primaryPurple};
      }
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(32)};
    }
  }
`;
