import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Salesforce, MembershipTypes } from 'mxp-schemas';
import { Admin as AdminUtils } from 'mxp-utils';
import { employersSelector, isAuthSelector, employmentDataUpdateLoadingSelector } from 'modules/user/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { setMembershipPackageOrganization, sendPartnerInvite, updateAccountAsPartner } from 'modules/user/actions';
import { setMembershipEvent } from 'modules/membership/actions';
import { userMembershipTypeSelector } from 'modules/membership/selectors';
import { getOrganizationMembershipsSelector } from 'modules/centerAdmin/selectors';
import { MembershipPackageAccordionStatus } from '../../../constants';
import { getCenterMembershipPackagePath } from 'utils';
import { CenterMembershipCard } from 'components/atoms';
import {
  PersonOrganisationsCards,
  PersonOrganisationsCards_STATUS,
  PartnersInvitationSent,
  PartnersSendInvitation,
  ContactAuditQualityCentersTeam,
  CaqCentersTeamStatusEnum,
  PartnerDeclareAcknowledgement,
  PartnerDeclareAdditionalInfo,
} from 'components/molecules';

interface Props {
  applEmploymentFormContainer: React.ReactElement;
}

export const CenterMembershipOrganizationSelection: React.FC<Props> = ({ applEmploymentFormContainer }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const unfilteredEmployers: Omit<Salesforce.AccountPersonAccount, 'personAccount'>[] = useSelector(employersSelector);
  const updateEmployerLoading = useSelector(employmentDataUpdateLoadingSelector);
  // Remove duplicate organization ids
  const employers = unfilteredEmployers.filter(
    (v, i, a) => a.findIndex(t => t.organization.id === v.organization.id) === i
  );

  const organizationMemberships: Salesforce.FirmMembership[] = useSelector(getOrganizationMembershipsSelector);
  const userMembershipTypeChoice = useSelector(userMembershipTypeSelector) as string;
  const [createOrganization, setCreateOrganization] = useState(false);
  const [isUnderReview, setIsUnderReview] = useState(false);

  // toggle pop-up
  const [partnerSendInvitationModalOpen, setPartnerSendInvitationModalOpen] = useState(false);
  const [partnerInvitationSentModalOpen, setPartnerInvitationSentModalOpen] = useState(false);
  const [partnerDeclareAcknowledgeOpen, setPartnerDeclareAcknowledgeOpen] = useState(false);
  const [partnerDeclareAdditionalInfoOpen, setPartnerDeclareAdditionalInfoOpen] = useState(false);
  const [isAcknowledgementConfirmed, setisAcknowledgementConfirmed] = useState(false);
  const [centersTeamNonUsModalOpen, setCentersTeamNonUsModalOpen] = useState(false);
  const [centersTeamGenericModalOpen, setCentersTeamGenericModalOpen] = useState(false);

  const [activeCompany, setActiveCompany] = useState('');

  // pop-up state
  const [partnerInformation, setPartnerInformation] = useState({
    email: '',
  });
  const [partnerOption, setPartnerOption] = useState({ optionValueA: false, optionValueB: false });
  const [partnerAdditionalInformation, setPartnerAdditionalInformation] = React.useState({
    question1: '',
    question2: '',
  });

  const toggleCreateOrganization = () => {
    setCreateOrganization(!createOrganization);
  };

  const acknowledgementConfirmed = () => {
    setisAcknowledgementConfirmed(!isAcknowledgementConfirmed);
  };
  // TODO - for future use, disabled for now
  const togglePartnerDeclareAcknowledgement = (company: string = '', isBusinessNew: boolean | null = true) => {
    if (Boolean(isBusinessNew)) {
      setActiveCompany(company);
      setPartnerDeclareAcknowledgeOpen(!partnerDeclareAcknowledgeOpen);
    } else {
      setisAcknowledgementConfirmed(true);
    }
  };
  const togglePartnerAdditionalInfo = () => {
    setPartnerDeclareAcknowledgeOpen(false);
    setPartnerDeclareAdditionalInfoOpen(!partnerDeclareAdditionalInfoOpen);
  };
  const togglePartnerSendInvitationModal = () => {
    setPartnerSendInvitationModalOpen(!partnerSendInvitationModalOpen);
  };
  const togglePartnerInvitationSentModal = () => {
    setPartnerInvitationSentModalOpen(!partnerInvitationSentModalOpen);
  };
  const toggleCentersTeamNonUsOrCaq = () => {
    setCentersTeamNonUsModalOpen(!centersTeamNonUsModalOpen);
  };
  const toggleCentersTeamGeneric = () => {
    setCentersTeamGenericModalOpen(!centersTeamGenericModalOpen);
  };
  const onPartnerSendInvitationCancel = () => {
    togglePartnerSendInvitationModal();
  };
  const onCentersTeamNonUsModalCancel = () => {
    setCentersTeamNonUsModalOpen(!centersTeamNonUsModalOpen);
  };
  const onCentersTeamGenericModalCancel = () => {
    setCentersTeamGenericModalOpen(!centersTeamGenericModalOpen);
  };
  const onPartnerDeclareAcknowledgementCancel = () => {
    togglePartnerDeclareAcknowledgement();
  };
  const onPartnerDeclareAdditionalInfoCancel = () => {
    setPartnerDeclareAcknowledgeOpen(false);
    togglePartnerAdditionalInfo();
  };
  const onPartnerDeclareAcknowledgementConfirm = () => {
    if (partnerOption.optionValueB) {
      setPartnerDeclareAdditionalInfoOpen(prev => !prev);
    }
    setisAcknowledgementConfirmed(true);
    setPartnerDeclareAcknowledgeOpen(false);
  };

  const onPartnerDeclareAdditionalInfoConfirm = () => {
    togglePartnerAdditionalInfo();
    setPartnerDeclareAcknowledgeOpen(false);
    acknowledgementConfirmed();
    if (partnerOption.optionValueB) {
      // Set under review only only when option B is selected?
      setIsUnderReview(true);
    }
    setPartnerOption({ optionValueA: false, optionValueB: false });
    setPartnerAdditionalInformation({ question1: '', question2: '' });
  };
  const onPartnerSentConfirm = () => {
    togglePartnerInvitationSentModal();
    setPartnerInformation({ email: '' });
  };

  const toggleAccountAsPartner = async (organization: any) => {
    // update Account as a partner if relationshipType is Employee
    const employerEmployeeType = unfilteredEmployers.find(
      unfilteredEmployer =>
        unfilteredEmployer.organization.id === organization.id &&
        unfilteredEmployer.relationshipType === MembershipTypes.RelationshipTypeEnum.EMPLOYEE
    );
    if (employerEmployeeType?.id) {
      await dispatch(updateAccountAsPartner(employerEmployeeType.id));
    }
  };

  const partnerStatus = PersonOrganisationsCards_STATUS.CAN_APPLY;
  const nonPartnerStatus = PersonOrganisationsCards_STATUS.NEEDS_PARTNER;
  const alreadyMemberStatus = PersonOrganisationsCards_STATUS.ALREADY_MEMBER;
  const caqTeamStatus = PersonOrganisationsCards_STATUS.CONTACT;

  const isAdmin = useSelector(isAdminPortalSelector);
  const isAuth = useSelector(isAuthSelector);
  const isImpersonated = Boolean(isAuth) && isAdmin;

  return (
    <StyledOrganizationContainer>
      {/*
    - Moved the component outside employers.map()
    - Modal should appear when creating the 1st organization
  */}
      {partnerDeclareAcknowledgeOpen && (
        <PartnerDeclareAcknowledgement
          onCancel={onPartnerDeclareAcknowledgementCancel}
          optionState={partnerOption}
          setOptionState={setPartnerOption}
          testId="partner-declare-acknowledgement"
          open={partnerDeclareAcknowledgeOpen}
          setIsUnderReview={setIsUnderReview}
          header={'Acknowledgement'}
          onConfirm={onPartnerDeclareAcknowledgementConfirm}
          confirmButtonLabel="Confirm"
          firmName={activeCompany}
        />
      )}

      {employers && (
        <>
          {employers
            .map(employer => {
              const orgMembership = organizationMemberships.find(
                (membership: any) =>
                  membership.accountKey === employer.organization.id ||
                  membership.admin.parentId === employer.organization.id ||
                  (employer.organization.parentId &&
                    (membership.accountKey === employer.organization.parentId ||
                      membership.admin.parentId === employer.organization.parentId))
              );
              return {
                ...employer,
                ...(orgMembership && {
                  admin: orgMembership.admin,
                }),
                hasMembership: Boolean(orgMembership),
              };
            })
            .map(({ id, jobTitle, organization, admin, hasMembership, hasRejectedApplication }: any) => {
              if (hasRejectedApplication) return <></>;
              const company = organization.name;
              const underReview = organization.underReview;
              const employer = {
                street: organization.billingAddress.addressLine1,
                city: organization.billingAddress.city,
                cityCode: organization.billingAddress.zipCode,
                state: organization.billingAddress.state,
                phoneNumber: organization.phone,
              };
              const organizationUserChoice: any = {
                ...organization,
                jobTitle,
              };

              // Will allows us to "Proceed to this Firm" to the Organization if:
              // it is a USA address
              // user selects CAQ
              // is not a Government type
              // Public Accounting type for all Center Membership
              // Consulting Firm for PCPS and CPEA only
              const employerCountry = organization.billingAddress.country;
              const employerType = organization.type;
              const isCountryUS = Boolean(employerCountry === 'USA' || employerCountry === 'US');
              const isNonUSorCAQ = Boolean(isCountryUS || AdminUtils.isCAQType(userMembershipTypeChoice));
              const isGov = Boolean(employerType === 'Government');
              const isCpeaCaqCondition = Boolean(
                AdminUtils.isCPEAType(userMembershipTypeChoice) && employerType === 'Other Professional Organization'
              );
              const isPcpsCaqConditions = Boolean(
                AdminUtils.isPCPSType(userMembershipTypeChoice) &&
                  employerType !== 'Consulting Firm' &&
                  employerType !== 'Public Accounting'
              );
              const isCaqConditions = Boolean(
                (AdminUtils.isGAQCType(userMembershipTypeChoice) ||
                  AdminUtils.isCAQType(userMembershipTypeChoice) ||
                  AdminUtils.isEBPAQCType(userMembershipTypeChoice)) &&
                  !(employerType === 'Public Accounting' || employerType === 'Government')
              );

              const onPartnerSendInvitationConfirm = () => {
                togglePartnerSendInvitationModal();
                togglePartnerInvitationSentModal();
                dispatch(setMembershipPackageOrganization(organizationUserChoice));
                dispatch(sendPartnerInvite(partnerInformation));
              };
              const handlePartnerProceed = () => {
                dispatch(setMembershipPackageOrganization(organizationUserChoice));
                dispatch(setMembershipEvent('isClickedCenterMembershipJourney', true));
                history.push(getCenterMembershipPackagePath(MembershipPackageAccordionStatus.CPA));
              };
              const handleNonPartnerInvite = () => {
                togglePartnerSendInvitationModal();
              };
              const onCaqTeamNonUsOrCaq = () => {
                toggleCentersTeamNonUsOrCaq();
              };
              const onCaqTeamGeneric = () => {
                toggleCentersTeamGeneric();
              };
              const handleNonPartnerDeclare = async () => {
                setActiveCompany(company);
                await toggleAccountAsPartner(organization);
              };

              return (
                <React.Fragment key={id}>
                  <>
                    {partnerDeclareAdditionalInfoOpen && (
                      <PartnerDeclareAdditionalInfo
                        onCancel={onPartnerDeclareAdditionalInfoCancel}
                        additionalInformation={partnerAdditionalInformation}
                        setAdditionalInformation={setPartnerAdditionalInformation}
                        testId="partner-additional-information"
                        open={partnerDeclareAdditionalInfoOpen}
                        header={'Acknowledgement'}
                        onConfirm={onPartnerDeclareAdditionalInfoConfirm}
                        confirmButtonLabel="Confirm"
                      />
                    )}
                    {partnerSendInvitationModalOpen && (
                      <PartnersSendInvitation
                        onCancel={onPartnerSendInvitationCancel}
                        personObject={partnerInformation}
                        setPersonObject={setPartnerInformation}
                        testId="partner-send-invitation"
                        open={partnerSendInvitationModalOpen}
                        header={'Invite a partner/owner in your firm/organization to apply for center membership.'}
                        onConfirm={onPartnerSendInvitationConfirm}
                        confirmButtonLabel="Send invitation"
                      />
                    )}
                    {centersTeamNonUsModalOpen && (
                      <ContactAuditQualityCentersTeam
                        onCancel={onCentersTeamNonUsModalCancel}
                        testId="partner-invitation-sent"
                        open={centersTeamNonUsModalOpen}
                        header={'You cannot proceed with this purchase'}
                        onClose={onCentersTeamNonUsModalCancel}
                        closeButtonLabel="I understand"
                        status={CaqCentersTeamStatusEnum.NON_US_OR_CAQ}
                        centerMembershipType={userMembershipTypeChoice}
                      />
                    )}
                    {centersTeamGenericModalOpen && (
                      <ContactAuditQualityCentersTeam
                        onCancel={onCentersTeamGenericModalCancel}
                        testId="partner-invitation-sent"
                        open={centersTeamGenericModalOpen}
                        header={'You cannot proceed with this purchase'}
                        onClose={onCentersTeamGenericModalCancel}
                        closeButtonLabel="I understand"
                        status={CaqCentersTeamStatusEnum.GENERIC_CONDITIONS}
                        centerMembershipType={userMembershipTypeChoice}
                      />
                    )}
                    {partnerInvitationSentModalOpen && (
                      <PartnersInvitationSent
                        testId="partner-invitation-sent"
                        open={partnerInvitationSentModalOpen}
                        header={'Your invitation has been sent'}
                        onConfirm={onPartnerSentConfirm}
                        confirmButtonLabel="Return to center membership overview"
                      />
                    )}
                    <>
                      {(() => {
                        if (AdminUtils.isCPEAType(userMembershipTypeChoice) && !hasMembership) {
                          if (!isImpersonated && (!isCountryUS || isCpeaCaqCondition)) {
                            return (
                              <PersonOrganisationsCards
                                centerMembershipType={userMembershipTypeChoice}
                                company={company}
                                employer={employer}
                                status={caqTeamStatus}
                                handleCentersTeamModal={!isCountryUS ? onCaqTeamNonUsOrCaq : onCaqTeamGeneric}
                                isUnderReview={underReview}
                                isOrgNotUS={!isCountryUS && !isGov}
                              />
                            );
                          }
                          return (
                            <PersonOrganisationsCards
                              company={company}
                              employer={employer}
                              status={partnerStatus}
                              handleProceed={handlePartnerProceed}
                              isUnderReview={underReview}
                            />
                          );
                        }
                        if (jobTitle === MembershipTypes.RolesNames.Partner && !hasMembership) {
                          if (!isImpersonated && (!isNonUSorCAQ || isPcpsCaqConditions || isCaqConditions)) {
                            return (
                              <PersonOrganisationsCards
                                centerMembershipType={userMembershipTypeChoice}
                                company={company}
                                employer={employer}
                                status={caqTeamStatus}
                                isUnderReview={underReview}
                                handleCentersTeamModal={isNonUSorCAQ ? onCaqTeamGeneric : onCaqTeamNonUsOrCaq}
                                {...(!isCountryUS && !isGov && { isOrgNotUS: true })}
                              />
                            );
                          }
                          return (
                            <PersonOrganisationsCards
                              company={company}
                              employer={employer}
                              status={partnerStatus}
                              isUnderReview={underReview}
                              handleProceed={handlePartnerProceed}
                            />
                          );
                        }
                        if (hasMembership) {
                          return (
                            <PersonOrganisationsCards
                              centerMembershipType={userMembershipTypeChoice}
                              company={company}
                              admin={admin}
                              employer={employer}
                              status={alreadyMemberStatus}
                              isUnderReview={underReview}
                            />
                          );
                        }
                        return (
                          <PersonOrganisationsCards
                            centerMembershipType={userMembershipTypeChoice}
                            company={company}
                            employer={employer}
                            status={nonPartnerStatus}
                            handleInvite={handleNonPartnerInvite}
                            handleDeclare={handleNonPartnerDeclare}
                            isUnderReview={underReview}
                          />
                        );
                      })()}
                    </>
                  </>
                </React.Fragment>
              );
            })}
        </>
      )}
      {createOrganization ? (
        <StyledFirmCard>
          {React.cloneElement(applEmploymentFormContainer, {
            toggleCreateOrganization,
            togglePartnerDeclareAcknowledgement,
            isAcknowledgementConfirmed,
            acknowledgementConfirmed,
            isUnderReview,
            setIsUnderReview,
            onCancelClick: toggleCreateOrganization,
          })}
        </StyledFirmCard>
      ) : (
        <CenterMembershipCard
          title={'Cannot find your firm?'}
          description={
            'Start by searching for your employer’s name. If it does not pull up, you can create a new firm/organization record.'
          }
          loading={updateEmployerLoading}
          buttonLabel={'Search or create a Firm'}
          handleButtonChange={toggleCreateOrganization}
        />
      )}
    </StyledOrganizationContainer>
  );
};
const StyledOrganizationContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralBlack};
  margin-top: ${props => props.theme.pxToRem(-50)};
  margin-left: ${props => props.theme.pxToRem(30)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(310)};
    margin-top: ${props => props.theme.pxToRem(35)};
    margin-left: ${props => props.theme.pxToRem(22)};
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    width: 40%;
  }
`;

const StyledFirmCard = styled.div`
  box-sizing: border-box;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  min-height: ${props => props.theme.pxToRem(250)};
  background-color: ${props => props.theme.colors.neutralWhite};
  width: 95%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;
