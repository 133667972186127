import React from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory, matchPath } from 'react-router';
import { Container, Grid } from 'semantic-ui-react';
import { SearchQueryResultList } from 'components/molecules/SearchQueryResultList/SearchQueryResultList';
import { SearchHistory } from 'components/molecules/SearchHistory/SearchHistory';
import { Divider, Heading, OnlyDesktop, OnlyMobile, SearchInput, IconButton } from 'components/atoms';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { ArrowBackSVG } from 'components/atoms/svg';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { theme } from 'theme';

interface Props {
  isAuth: boolean | null;
  suggestionSource: string;
  suggestions: State.SuggestionResult[];
  recentSearches: string[];
  close: () => void;
  fetchSuggestions: (searchQuery: string) => void;
  clearSuggestions: () => void;
  deleteSearchHistoryItem: (searchQuery: string) => void;
  getSearchHistory: () => void;
  navigate: (path: string) => void;
}

export const SearchQueryModal: React.FC<Props> = React.memo(
  ({
    isAuth,
    close,
    suggestions,
    fetchSuggestions,
    suggestionSource,
    clearSuggestions,
    recentSearches,
    deleteSearchHistoryItem,
    getSearchHistory,
    navigate,
  }) => {
    const history = useHistory();
    const hash: string = history && history.location && history.location.hash;
    // Will check if contentpage
    const currentPath = history.location.pathname;
    const isContentPage = Boolean(
      matchPath(currentPath, { path: getPath(Routes.CONTENT_PAGE), exact: false, strict: false })
    );

    // Will get maincontent element to focus when the search modal is closed
    const mainContentDom = document.getElementById('mainContent');
    const contentTitleDom = document.getElementById('contentTitle');

    const searchNavigate = (value: string) => {
      const encodedValue = encodeURIComponent(value.replace(/%/g, '%25')).replace(/%20/g, '+');
      history.push(`/search/${encodedValue}`);
      close();
    };
    // Will set to main content if clicked by mouse
    const onMouseClick = React.useCallback(() => {
      if (isContentPage) {
        setTimeout(() => {
          mainContentDom?.focus();
        }, 200);
      }
    }, [mainContentDom, isContentPage]);

    const onCloseModal = React.useCallback(() => {
      clearSuggestions();
      close();
      if (isContentPage) {
        contentTitleDom?.focus();
      } else {
        mainContentDom?.focus();
      }
    }, [clearSuggestions, close, mainContentDom, contentTitleDom, isContentPage]);

    const [debounceSuggestions, cancel] = useDebouncedCallback(
      (search: string) => {
        fetchSuggestions(search.trim());
      },
      500,
      { maxWait: 2000 }
    );

    React.useEffect(() => cancel, [cancel]);

    React.useEffect(() => {
      if (!hash || hash !== getPath(Routes.SEARCH_HASH)) {
        close();
        cancel();
      }
    }, [cancel, hash, close]);

    React.useEffect(() => {
      if (isAuth) getSearchHistory();
    }, [isAuth, getSearchHistory]);

    const Input: any = (
      <SearchInput
        testId="search-input-header"
        icon="search"
        handleSubmit={searchNavigate}
        fetchSuggestions={debounceSuggestions}
        clearSuggestions={clearSuggestions}
        autoFocus
        navigate={navigate}
        isInModal
      />
    );

    return (
      <StyledModal>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <OnlyMobileFlex>
                  <BackIcon
                    color={theme.colors.primaryPurple}
                    size={32}
                    onClick={close}
                    data-testid="close-search-modal"
                  />
                  {Input}
                </OnlyMobileFlex>
                <OnlyDesktop>{Input}</OnlyDesktop>
                <OnlyMobile as={StyledDivider} />
                <SearchQueryResultList
                  suggestions={suggestions}
                  suggestionSource={suggestionSource}
                  searchNavigate={searchNavigate}
                />
              </Grid.Column>
            </Grid.Row>
            {recentSearches.length > 0 && (
              <>
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column computer={16}>
                    <OnlyDesktop>
                      <StyledDivider />
                    </OnlyDesktop>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column computer={8} mobile={16} data-testid="search-history-wrapper">
                    <Heading as="h2">Your recent searches</Heading>
                    <SearchHistory
                      onItemClick={searchNavigate}
                      onItemDeletion={deleteSearchHistoryItem}
                      recentSearches={recentSearches}
                      testId="search-history"
                    />
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
          </Grid>
        </Container>
        <OnlyDesktop>
          <StyledIconButton onClick={onCloseModal} data-testid="close-search-modal" aria-label="Close Search Modal">
            <IconClose onMouseDown={onMouseClick} />
          </StyledIconButton>
        </OnlyDesktop>
      </StyledModal>
    );
  }
);

const StyledModal = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  padding-top: 65px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-top: 1rem;
    padding-bottom: 5rem;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.neutralGrey1};
  border-radius: 50%;

  > svg {
    fill: ${props => props.theme.colors.primaryPurple};
    width: ${props => props.theme.pxToRem(32)};
    height: ${props => props.theme.pxToRem(32)};
    flex-shrink: 0;
  }
`;

const BackIcon = styled(ArrowBackSVG)`
  margin-top: 20px;
`;

const OnlyMobileFlex = styled(OnlyMobile)`
  display: flex;
  height: 70px;
`;

const StyledDivider = styled(Divider)`
  margin-right: -1rem !important;
  margin-left: -1rem !important;
`;
