import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { fetchUploadedDocuments, deleteUploadedDoc } from './actions';
import { isServer } from 'utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchUploadedDocuments]: {
    next: (state: State.UploadedDocument, action: any): State.UploadedDocument => ({
      ...state,
      uploadedDocuments: action.payload,
      uploadedDocumentsFetched: true,
      uploadedDocumentsFrontloadRequest: isServer,
    }),
    throw: (state: State.UploadedDocument): State.UploadedDocument => ({
      ...state,
      uploadedDocumentsFetched: false,
    }),
  },
  [deleteUploadedDoc]: {
    next: (state: State.UploadedDocument, action: any): State.UploadedDocument => ({
      ...state,
      uploadedDocuments: action.payload,
      uploadedDocumentsFetched: true,
      uploadedDocumentsFrontloadRequest: isServer,
    }),
    throw: (state: State.UploadedDocument): State.UploadedDocument => ({
      ...state,
      uploadedDocumentsFetched: false,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);

export * from './selectors';
export * from './constants';
export * from './actions';
