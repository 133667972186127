import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Grid, Icon } from 'semantic-ui-react';
import { User } from 'mxp-schemas';
import { Button, ButtonEnums, ButtonVariants, CardOutlay, Link } from 'components/atoms';
import { ProductModal } from 'components/organisms';
import { ReactComponent as IconCertCheck } from 'resources/images/icon-dev-ic-certificate-program.svg';
import { handleEvent } from 'utils/Analytics';
import { PRODUCT_VIEW } from 'utils/Analytics/constants';
import { ProductCompoundAnalytics } from 'utils/Analytics/helpers';
import { isServer, getPath } from 'utils';
import { getLineItemProductUrl } from 'mxp-utils';
import { Routes } from 'constants/index';

export interface EntryPointLevelExamList {
  entryPointLevelExam: string;
}

interface Props {
  headerTitle: string;
  description?: string;
  label?: string;
  slug?: string;
  formattedPrice?: string;
  isCardSelected?: boolean;
  isCurrent?: boolean;
  isFree?: boolean;
  isEnable?: boolean;
  handleClick?: () => void;
  entryPointLevelExamList?: EntryPointLevelExamList[];
  headerNumber?: number;
  isCima?: boolean;
  isPQ?: boolean;
  isApprentice?: boolean;
  listTitle?: string;
  productDetails?: any;
  isExistingMember?: boolean;
  disabled?: boolean;
  boughtOn?: string;
  isModalEntryPointLevelView?: boolean;
  isUserSuspendedByEthics?: boolean;
  currentLevel?: string;
  isOfflineExemptionCalculator?: boolean;
  shouldShowNewPackageBuilder?: boolean;
  isUsedForAddOns?: boolean;
  isHideLearnMore?: boolean;
  isImpersonation?: boolean;
  isAddNewMembershipProduct?: boolean;
  showAsIncluded?: boolean;
  isRenewal?: boolean;
}

export const MembershipCard: React.FC<Props> = React.memo(
  ({
    headerTitle,
    description,
    label,
    formattedPrice,
    isCardSelected = false,
    isCurrent = false,
    isFree = false,
    isEnable = true,
    handleClick,
    slug,
    entryPointLevelExamList,
    headerNumber,
    isCima = false,
    isPQ = false,
    isApprentice = false,
    listTitle,
    productDetails,
    isExistingMember,
    disabled,
    boughtOn,
    isModalEntryPointLevelView = false,
    isUserSuspendedByEthics,
    currentLevel,
    isOfflineExemptionCalculator,
    shouldShowNewPackageBuilder = false,
    isUsedForAddOns = false,
    isHideLearnMore = false,
    isImpersonation = false,
    isAddNewMembershipProduct = false,
    showAsIncluded,
    isRenewal = false,
  }) => {
    const [isOpenProductModal, setIsOpenProductModal] = useState(false);
    const lineItemProductUrl = getLineItemProductUrl({ ...productDetails, productSlug: productDetails?.slug });
    const handleModal = useCallback(() => {
      setIsOpenProductModal(!isOpenProductModal);
      if (!isOpenProductModal && !isServer) {
        const { name, productType, productId, variants: [{ sku = '', prices }] = [] } = productDetails;

        const membershipPrice = prices?.find(
          (price: any) => price.channel?.applicableUserRoles[0] === User.MembershipIdsEnum.MRUSR0001
        );

        // if we dont have MRUSR0001
        const { variants: [{ prices: [{ amount = 0 } = {}] = [] } = {}] = [] } = productDetails;

        const priceAmount = Number(membershipPrice?.amount / 100 || amount / 100 || 0).toFixed(2);
        const pageName = `aicpa${(window as any).location.pathname.replace(/\//g, ':')}${(
          window as any
        ).location.hash.replace(/#/g, ':')}`;

        const payload: ProductCompoundAnalytics = {
          name: [name || ''],
          category: [productType || ''],
          id: [productId || ''],
          sku: [sku || ''],
          price: [+priceAmount || 0],
          pageName,
        };

        handleEvent(payload, PRODUCT_VIEW);
      }
    }, [isOpenProductModal, productDetails]);

    const renderSelectToBasketButton = (): React.ReactNode => {
      const isUsedForAddOnsandIncluded = showAsIncluded || (productDetails?.isIncluded && isUsedForAddOns);
      const shouldShowIncludedAddOnLabel = isUsedForAddOnsandIncluded && shouldShowNewPackageBuilder;

      const selectedButtonText = isFree && shouldShowNewPackageBuilder ? 'Claimed' : 'Added';
      const unselectedButtonText = isFree && shouldShowNewPackageBuilder ? 'Claim now' : 'Add to cart';

      const selectToBasetButtonLabelWhenCardSelected = isCima ? 'Selected' : selectedButtonText;
      const selectToBasetButtonLabelWhenCardNotSelected = isCima ? 'Select' : unselectedButtonText;

      return (
        <>
          {shouldShowIncludedAddOnLabel ? (
            <StyledIncludedAddOnProductButton
              testId="included-add-on-product"
              icon={<StyledIcon name="check" size="small" />}
              iconPosition={ButtonEnums.iconPosition.left}
              variant={ButtonVariants.primary}
            >
              Included
            </StyledIncludedAddOnProductButton>
          ) : (
            <SelectToBasketButton
              testId="select-to-basket-button"
              isCardSelected={isCardSelected}
              isFree={isFree}
              isCima={isCima}
              disabled={!isEnable || isUserSuspendedByEthics}
              icon={isCardSelected ? <StyledIcon name="check" size="small" /> : <StyledIcon name="plus" size="small" />}
              iconPosition={ButtonEnums.iconPosition.left}
              onClick={handleClick}
              textValue={headerTitle}
              shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
              isHideLearnMore={isHideLearnMore}
              isImpersonation={isImpersonation}
            >
              {isCardSelected ? selectToBasetButtonLabelWhenCardSelected : selectToBasetButtonLabelWhenCardNotSelected}
            </SelectToBasketButton>
          )}
        </>
      );
    };

    const renderMembershipCardCTA = (): React.ReactNode => {
      const disabledAndBoughtOn = disabled && boughtOn;
      return disabledAndBoughtOn ? <label>Bought on {boughtOn}</label> : <>{renderSelectToBasketButton()}</>;
    };

    const freeSectionLabel =
      isFree && shouldShowNewPackageBuilder ? 'Included' : isFree && !shouldShowNewPackageBuilder ? 'Free' : '';

    return (
      <MainCardWrapper
        isCima={isCima}
        isCardSelected={isCardSelected}
        isFree={isFree}
        isApprentice={isApprentice}
        shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
      >
        {isOpenProductModal && (
          <ProductModal
            isOpenModal={isOpenProductModal}
            headerText={productDetails?.name || ''}
            description={productDetails?.description || ''}
            amount={productDetails?.priceInfoForUser || ''}
            overview={productDetails?.overview || ''}
            authors={productDetails?.authors || []}
            publishers={productDetails?.publisher || []}
            availability={productDetails?.availability || 0}
            format={productDetails?.format?.label || ''}
            handleModal={handleModal}
            imageUrl={productDetails?.variant?.images?.[0]?.imageUrl || ''}
            productType={productDetails.productType}
            subscriptionProductType={productDetails?.subscriptionProductType?.label}
          />
        )}
        <StyledMembershipCard
          isCardSelected={isCardSelected}
          isFree={isFree}
          isEnable={isEnable}
          isCurrent={isCurrent}
          isCima={isCima}
          isPQ={isPQ}
          isDisabled={disabled}
          isApprentice={isApprentice}
          isModalEntryPointLevelView={isModalEntryPointLevelView}
          isOfflineExemptionCalculator={isOfflineExemptionCalculator}
          shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
          isAddNewMembershipProduct={isAddNewMembershipProduct}
        >
          {(headerTitle === currentLevel && !isFree) ||
            (isOfflineExemptionCalculator && (
              <CurrentWrapper>
                <StyledCurrentTextWrapper
                  isCima={isCima}
                  isOfflineExemptionCalculator={isOfflineExemptionCalculator}
                  isCardSelected={isCardSelected}
                  isFree={isFree}
                  isModalEntryPointLevelView={isModalEntryPointLevelView}
                >
                  <StyledCurrentText isCima={isCima}>
                    {isOfflineExemptionCalculator
                      ? 'EXEMPT'
                      : isCima
                      ? isModalEntryPointLevelView
                        ? 'YOUR RECOMMENDED LEVEL'
                        : 'YOUR ACTUAL LEVEL'
                      : 'CURRENT'}
                  </StyledCurrentText>
                </StyledCurrentTextWrapper>
              </CurrentWrapper>
            ))}
          {shouldShowNewPackageBuilder && (
            <PurpleHeaderDiv isAddNewMembershipProduct={isAddNewMembershipProduct}>{headerTitle}</PurpleHeaderDiv>
          )}
          {!shouldShowNewPackageBuilder && (
            <StyledHeaderDiv>
              <StyledCardOutlay isCima={isCima} type={isCima ? 'cima-header' : 'customize-header'} isFree={isFree}>
                {headerTitle}
              </StyledCardOutlay>
            </StyledHeaderDiv>
          )}
          <BodyCardWrapper
            isCima={isCima}
            isCardSelected={isCardSelected}
            isFree={isFree}
            shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
          >
            <StyledDescription
              isCima={isCima}
              isCardSelected={isCardSelected}
              isFree={isFree}
              isApprentice={isApprentice}
              isHideLearnMore={isHideLearnMore}
              shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
              isAddNewMembershipProduct={isAddNewMembershipProduct}
            >
              {label ? `${label}: ` : ''}
              {description}
              <ListTitle>{listTitle}</ListTitle>
              {isCima &&
                entryPointLevelExamList?.map((item: { entryPointLevelExam: string }, key) => {
                  return (
                    <ItemListWrapper key={key}>
                      {isApprentice ? '-' : <StyledIconCheck />} <p>{item.entryPointLevelExam}</p>
                    </ItemListWrapper>
                  );
                })}
            </StyledDescription>
            {!isHideLearnMore && (
              <LearnMoreWrapper isCima={isCima} isCardSelected={isCardSelected} isFree={isFree}>
                {!isCima && (
                  <>
                    {isExistingMember ? (
                      <StyledLearnMore
                        testId={`product-learn-more-${slug}`}
                        to={lineItemProductUrl}
                        isExternal={true}
                        isCardSelected={isCardSelected}
                        isFree={isFree}
                        shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
                      >
                        {shouldShowNewPackageBuilder && <StyledLearnMoreText>Learn more</StyledLearnMoreText>}
                        {!shouldShowNewPackageBuilder && 'Learn more'}
                      </StyledLearnMore>
                    ) : (
                      <StyledLearnMoreButton
                        variant={ButtonEnums.variants.link}
                        testId={`product-learn-more-${slug}`}
                        onClick={handleModal}
                        isCardSelected={isCardSelected}
                        isFree={isFree}
                      >
                        {shouldShowNewPackageBuilder && <StyledLearnMoreText>Learn more</StyledLearnMoreText>}
                        {!shouldShowNewPackageBuilder && 'Learn more'}
                      </StyledLearnMoreButton>
                    )}
                  </>
                )}
              </LearnMoreWrapper>
            )}
            <FooterCardWrapper
              isCardSelected={isCardSelected}
              isFree={isFree}
              isEnable={isEnable}
              isCurrent={isCurrent}
              isCima={isCima}
              isPQ={isPQ}
              isApprentice={isApprentice}
              shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
            >
              <Grid columns={2}>
                <Grid.Row style={{ paddingTop: 0 }}>
                  {!isCima && (
                    <Grid.Column shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}>
                      <StyledFormattedPrice
                        shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
                        isCardSelected={isCardSelected}
                        isFree={isFree}
                      >
                        {!isFree || (isFree && isRenewal && shouldShowNewPackageBuilder) ? (
                          <>
                            {shouldShowNewPackageBuilder && !isAddNewMembershipProduct && (
                              <CostLabel shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}>Cost</CostLabel>
                            )}{' '}
                            {formattedPrice}{' '}
                          </>
                        ) : (
                          freeSectionLabel
                        )}
                      </StyledFormattedPrice>
                    </Grid.Column>
                  )}
                  {!isOfflineExemptionCalculator && (
                    <Grid.Column
                      isCardSelected={isCardSelected}
                      isFree={isFree}
                      isApprentice={isApprentice}
                      shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
                    >
                      {!isApprentice ? (
                        <>{renderMembershipCardCTA()}</>
                      ) : (
                        <StyledButton
                          size={ButtonEnums.sizes.small}
                          variant={ButtonEnums.variants.primary}
                          testId="confirm"
                          onClick={handleClick}
                          to={
                            isApprentice
                              ? `${getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_PROFESSIONAL)}/${headerTitle}`
                              : undefined
                          }
                          type="button"
                        >
                          That's Me
                        </StyledButton>
                      )}
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid>
            </FooterCardWrapper>
          </BodyCardWrapper>
        </StyledMembershipCard>
      </MainCardWrapper>
    );
  }
);

interface StyledMembershipCardProps {
  isCardSelected: boolean;
  isCurrent?: boolean;
  isFree: boolean;
  isEnable?: boolean;
  isCima?: boolean;
  isPQ?: boolean;
  isApprentice?: boolean;
  isDisabled?: boolean;
  isModalEntryPointLevelView?: boolean;
  isOfflineExemptionCalculator?: boolean;
  shouldShowNewPackageBuilder?: boolean;
  isUsedForAddOns?: boolean;
  isHideLearnMore?: boolean;
  isImpersonation?: boolean;
  isAddNewMembershipProduct?: boolean;
}

const StyledLearnMoreButton = styled(Button)<StyledMembershipCardProps>`
  z-index: 1;
  position: relative;
  top: ${props => !props.isCima && `${props.theme.pxToRem(-15)}`};
  padding-top: ${props => props.theme.pxToRem(15)};
  font-weight: ${props => props.theme.fontWeights.regular} !important;
  font-size: ${props => props.theme.fontSizes.s} !important;

  ${props =>
    Boolean(props.isCardSelected && props.isFree) &&
    css`
      color: ${props.theme.colors.neutralWhite} !important;
    `}

  ${props =>
    Boolean(props.isCardSelected && !props.isFree) &&
    css`
      color: ${props.theme.colors.primaryPurple};
    `}

      ${props =>
    Boolean(!props.isCardSelected && props.isFree) &&
    css`
      color: ${props.theme.colors.neutralWhite} !important;
    `}

    ${props =>
    Boolean(!props.isCardSelected && !props.isFree) &&
    css`
      color: ${props.theme.colors.primaryPurple};
    `}


  &&& {
    span {
      text-decoration: none !important;
    }

    ${props =>
      props.shouldShowNewPackageBuilder &&
      props.isFree &&
      css`
        color: ${props.theme.colors.primaryPurple} !important;
      `}
  }
`;

const activeEnableButtonStyles = css`
  border: ${props => props.theme.pxToRem(1)} solid #00857d;
  color: ${props => props.theme.colors.neutralWhite};
  background-color: ${props => props.theme.colors.secondaryTeal};
`;

const inactiveDisableButtonStyles = css`
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralWhite};
  color: ${props => props.theme.colors.neutralWhite};
  background-color: transparent;
`;

const defaultButtonStyles = css`
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryPurple};
  color: ${props => props.theme.colors.primaryPurple};
  background-color: ${props => props.theme.colors.neutralWhite};
`;

const linearGradientBackgroundStyles = css`
  background-image: linear-gradient(49deg, #6a2d87 34%, #a93d69 101%),
    linear-gradient(to bottom, ${props => props.theme.colors.neutralWhite}, #fff);
`;

const MainCardWrapper = styled.div<StyledMembershipCardProps>`
  ${props =>
    props.isCima
      ? `
      box-sizing: border-box;
      width: auto;
      left: 0;
      position: relative;
      padding: 0;
  `
      : css`
          width: 100% !important;
          height: ${props.shouldShowNewPackageBuilder ? 'auto' : `${props.theme.pxToRem(304)}`} !important;
        `}
`;

const StyledCardOutlay = styled(CardOutlay)<{ isCima: boolean }>`
  ${props =>
    !props.isCima &&
    css`
      padding-bottom: ${props.theme.pxToRem(28)};
      font-family: 'Roboto';
      font-size: ${props.theme.fontSizes.m};
      font-weight: ${props.theme.fontWeights.medium};
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      color: ${props.theme.colors.neutralGrey8};
      width: 100%;
    `}
`;

const CurrentWrapper = styled.div`
  display: flex;
  width: 100%;
  top: 10px;
  right: 10px;
  justify-content: right;
  position: absolute;
`;

const StyledCurrentTextWrapper = styled.div<StyledMembershipCardProps>`
  position: relative;
  text-align: center;

  ${props =>
    props.isOfflineExemptionCalculator &&
    css`
      width: auto;
      padding: 0 10px;
      border-radius: 5px;
    `}

  ${props =>
    !props.isOfflineExemptionCalculator &&
    !props.isCima &&
    css`
      width: ${props.theme.pxToRem(341)};
      height: ${props.theme.pxToRem(24)};
      border-radius: ${`${props.theme.pxToRem(6)} ${props.theme.pxToRem(6)} 0 0`};
      background-color: ${props.theme.colors.raspberryMagenta};

      ${props.theme.mediaQueries.desktopOnly} {
        top: ${props.theme.pxToRem(-139)};
      }

      ${props.theme.mediaQueries.mobileOnly} {
        bottom: ${props.theme.pxToRem(127)};
        width: 100%;
        position: absolute;
      }
    `}

  ${props => props.isModalEntryPointLevelView && `background-color: ${props.theme.colors.secondaryTeal};`}

  ${props =>
    props.isApprentice &&
    `
    border-radius: ${props.theme.pxToRem(8)}!important;
  `}
`;

const StyledCurrentText = styled.span<{ isCima: boolean }>`
  color: ${props => props.theme.colors.neutralWhite};
  font-family: 'Roboto';
  font-size: ${props => (props.isCima ? `${props.theme.pxToRem(10)}` : `${props.theme.fontSizes.xs}`)};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledMembershipCard = styled.div<StyledMembershipCardProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: ${props => props.theme.pxToRem(6)};
  position: relative;
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);
  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
     &&&& div, span, a {
        color: ${theme.colors.neutralGrey4};
      }
    `}

  ${props =>
    Boolean((props.isCurrent || props.isCardSelected) && props.isFree) &&
    css`
      ${linearGradientBackgroundStyles}
    `}

    ${props =>
    Boolean(props.isCurrent || props.isCardSelected) &&
    props.isModalEntryPointLevelView &&
    css`
      border-top: none;
    `}

    ${props =>
    Boolean(props.isCurrent || props.isCardSelected) &&
    !props.isModalEntryPointLevelView &&
    css`
      border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.primaryLightPurple};
      background-color: ${props.theme.colors.neutralWhite};
    `}

  ${({ isCurrent, isCardSelected, isFree, isEnable, theme, isModalEntryPointLevelView }) =>
    isCurrent || isCardSelected
      ? isFree
        ? css`
            ${linearGradientBackgroundStyles}
          `
        : isModalEntryPointLevelView
        ? css`
            border-top: none;
          `
        : css`
            border: ${theme.pxToRem(1)} solid ${theme.colors.primaryLightPurple};
            background-color: ${theme.colors.neutralWhite};
          `
      : isFree
      ? isEnable
        ? css`
            ${linearGradientBackgroundStyles}
          `
        : css`
            ${linearGradientBackgroundStyles}
            opacity: 0.5;
          `
      : css`
          background-color: ${theme.colors.neutralWhite};
        `}
  ${props =>
    props.shouldShowNewPackageBuilder &&
    css`
      background-image: none;
      background-color: ${props.theme.colors.neutralWhite};
    `}

  ${props => props.theme.mediaQueries.desktopOnly} {
    padding: ${props => `${props.theme.pxToRem(20)}`};
    box-sizing: border-box;
    ${props =>
      props.isCima
        ? css`
            height: ${props.theme.pxToRem(252)};
            margin: ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} 0;
          `
        : css`
            height: ${props.shouldShowNewPackageBuilder ? 'auto' : `${props.theme.pxToRem(304)}`};
            margin: 0 ${props.theme.pxToRem(-1)};
          `}

    ${props =>
      (props.isCima || props.isPQ || props.isApprentice) &&
      `
        position: relative;
        width: ${props.theme.pxToRem(360)};
        margin: 0;
        display: flex;
      `}

    ${props =>
      props.isPQ &&
      `
        width: ${props.theme.pxToRem(548)};
      `}

    ${props =>
      props.isApprentice &&
      `
      width: ${props.theme.pxToRem(500)};
      border-radius: ${props.theme.pxToRem(8)}!important;
      padding: ${props.theme.pxToRem(30)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(10)};
    `}

  ${props =>
      props.isOfflineExemptionCalculator &&
      css`
        height: ${props.theme.pxToRem(200)};
        width: ${props.theme.pxToRem(432)};
        margin: ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} 0;
      `}
      ${props =>
      props.shouldShowNewPackageBuilder &&
      `
        padding: 0 !important;
      `}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    padding: ${props =>
      `${props.theme.pxToRem(20)} ${props.theme.pxToRem(19.5)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)}`};

    ${props =>
      props.isCima
        ? `
        height: auto;
        width: auto;
      `
        : css`
            height: ${props.shouldShowNewPackageBuilder ? 'auto' : `${props.theme.pxToRem(304)}`};
          `}

    ${props =>
      props.shouldShowNewPackageBuilder &&
      `
      padding: 0;
      `}
  }
  ${props => props.theme.mediaQueries.tabletOnly} {
    position: relative;
  }
`;

const BodyCardWrapper = styled.div<StyledMembershipCardProps>`
  flex: 1;
  width: 100%;
  text-align: left !important;
  height: ${props => props.theme.pxToRem(50)} !important;
  ${props =>
    props.isCima &&
    `
      height: auto;
    `}
  ${props =>
    props.shouldShowNewPackageBuilder &&
    `
      height: auto;
      padding: ${props.theme.pxToRem(20)} !important;
  padding-bottom: 0 !important;
    `}
`;

const FooterCardWrapper = styled.div<StyledMembershipCardProps>`
  width: 100%;
  text-align: left;
  flex-direction: column;
  margin: 0 ${props => props.theme.pxToRem(100)} ${props => props.theme.pxToRem(11.9)} 0;
  padding-top: ${props => props.theme.pxToRem(30)};
  position: relative;
  ${props =>
    props.isApprentice &&
    `
      top: 0;
  `}
  ${props =>
    props.shouldShowNewPackageBuilder &&
    `
    margin-top: ${props.theme.pxToRem(50)} 
    border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3};
  `}
`;

const StyledDescription = styled.div<StyledMembershipCardProps>`
  font-family: 'Roboto';
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  position: relative;
  left: 0;
  margin: 0 0 ${props => props.theme.pxToRem(24)};
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  hyphens: auto !important;

  ${props =>
    props.isCima
      ? css`
          -webkit-line-clamp: 4 !important;
          line-height: 1.2;
          font-size: ${props.theme.fontSizes.xxs};
          font-weight: ${props.theme.fontWeights.light};
        `
      : css`
          -webkit-line-clamp: 2 !important;
          line-height: 1.25;
          font-size: ${props.theme.fontSizes.s};
          font-weight: ${props.theme.fontWeights.regular};
        `}

  ${props =>
    !props.isCima &&
    props.shouldShowNewPackageBuilder &&
    `
          -webkit-line-clamp: 4 !important ;
          height: ${props.theme.pxToRem(80)};
        `}

  ${props => css`
    color: ${props.theme.colors.neutralGrey8};
  `}  
 
  ${props =>
    props.isCardSelected &&
    props.isFree &&
    css`
      color: ${props.theme.colors.neutralWhite};
    `}  

    ${props =>
    props.shouldShowNewPackageBuilder &&
    props.isFree &&
    css`
      color: ${props.theme.colors.neutralGrey8};
    `}
    ${props =>
    props.isAddNewMembershipProduct &&
    css`
      min-height: ${p => p.theme.pxToRem(80)};
    `}
`;

const StyledFormattedPrice = styled.span<StyledMembershipCardProps>`
  position: relative;
  left: 0;
  top: ${props => props.theme.pxToRem(7)};
  width: ${props => props.theme.pxToRem(72)};
  height: ${props => props.theme.pxToRem(44)};
  font-family: 'Roboto';
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;

  ${props =>
    props.shouldShowNewPackageBuilder &&
    `
      font-size: ${props.theme.fontSizes.l};
      ${props.theme.mediaQueries.mobileOnly} {
        font-size: ${props.theme.pxToRem(21)};
      }
    `}
  ${props =>
    props.isCardSelected
      ? props.isFree
        ? css`
            color: ${props.theme.colors.neutralWhite};
          `
        : css`
            color: ${props.theme.colors.neutralGrey8};
          `
      : props.isFree
      ? css`
          color: ${props.theme.colors.neutralWhite};
        `
      : css`
          color: ${props.theme.colors.neutralGrey8};
        `}
        
    ${props =>
    props.shouldShowNewPackageBuilder &&
    props.isFree &&
    css`
      color: ${props.theme.colors.neutralGrey8};
    `}
`;

const StyledLearnMore = styled(Link)<StyledMembershipCardProps>`
  z-index: 1;
  position: relative;
  top: ${props => !props.isCima && `${props.theme.pxToRem(-20)}`};
  left: 0;
  font-family: 'Roboto';
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;

  ${props =>
    props.isCardSelected
      ? props.isFree
        ? css`
            color: ${props.theme.colors.neutralWhite};
          `
        : css`
            color: ${props.theme.colors.primaryPurple};
          `
      : props.isFree
      ? css`
          color: ${props.theme.colors.neutralWhite};
        `
      : css`
          color: ${props.theme.colors.primaryPurple};
        `}
`;

const LearnMoreWrapper = styled.div<StyledMembershipCardProps>`
  text-align: left;
  height: 0;
  margin: 0 0 ${props => props.theme.pxToRem(10)} 0;
  width: 100%;

  ${props =>
    props.isCima &&
    `
      height: 0;
    `}
`;

const StyledHeaderDiv = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  position: relative;
`;

const SelectToBasketButton = styled(Button)<StyledMembershipCardProps>`
  font-family: 'Roboto';
  width: ${props => (props.isCima ? `${props.theme.pxToRem(149)}` : `max-content`)};
  height: ${props => (props.isCima ? `${props.theme.pxToRem(40)}` : `${props.theme.pxToRem(34)}`)};
  border-radius: ${props => props.theme.pxToRem(2)};
  cursor: ${props => props.isHideLearnMore && !props.isImpersonation && `default !important`};
  pointer-events: ${props => props.isHideLearnMore && !props.isImpersonation && `none`};
  position: relative;
  ${props =>
    props.shouldShowNewPackageBuilder &&
    `
    height: auto !important;
    font-size: ${props.theme.fontSizes.s} !important;
    padding: ${props.theme.pxToRem(14)} ${props.theme.pxToRem(24)}!important;
  `}
  ${props => props.theme.mediaQueries.desktopOnly} {
    left: ${props => props.isCima && `${props.theme.pxToRem(-40)}`};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    left: ${props => props.isCima && `${props.theme.pxToRem(-20)}`};
  }
  ${props =>
    props.isCima &&
    `
    width: ${props.theme.pxToRem(147)};

    ${props.theme.mediaQueries.desktopOnly} {
      left: 0;
    }
    ${props.theme.mediaQueries.mobileOnly} {
      left: 0;
      width: 100%;
    }
  `}

  &&& {
    border-radius: ${props => props.theme.pxToRem(2)};
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.medium};
    padding: ${props => (props.isCima ? 0 : `0 ${props.theme.pxToRem(14)} 0 ${props.theme.pxToRem(12)};`)};
    float: ${props => !props.isCima && 'right'};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: ${props => !props.isCima && `${props.theme.pxToRem(35)}`};
      ${props =>
        props.shouldShowNewPackageBuilder &&
        ` margin-left: 0;
        `}
    }

    ${props =>
      props.isCardSelected && props.isEnable
        ? props.isFree && !props.shouldShowNewPackageBuilder
          ? css`
              ${activeEnableButtonStyles}

              &:hover {
                ${activeEnableButtonStyles}
              }

              &:focus {
                ${activeEnableButtonStyles}
              }
            `
          : css`
              ${activeEnableButtonStyles}

              &:hover {
                ${activeEnableButtonStyles}
              }
              &:focus {
                ${activeEnableButtonStyles}
              }
            `
        : props.isFree && !props.shouldShowNewPackageBuilder
        ? css`
            ${inactiveDisableButtonStyles}

            &:hover {
              ${inactiveDisableButtonStyles}
            }

            &:focus {
              ${inactiveDisableButtonStyles}
            }
          `
        : css`
            ${defaultButtonStyles}

            &:hover {
              ${defaultButtonStyles}
            }
            &:focus {
              ${defaultButtonStyles}
            }
          `}
  }

  ${props =>
    props.shouldShowNewPackageBuilder &&
    props.isFree &&
    css`
      ${activeEnableButtonStyles}

      &:hover {
        ${activeEnableButtonStyles}
      }
      &:focus {
        ${activeEnableButtonStyles}
      }
    `}
`;

const StyledIncludedAddOnProductButton = styled(Button)`
  border: ${props => props.theme.pxToRem(0)};
  color: ${props => props.theme.colors.neutralWhite};
  background-color: ${props => props.theme.colors.secondaryTeal} !important;
  width: ${props => props.theme.pxToRem(145.467)};
  height: ${props => `${props.theme.pxToRem(46)}`};
  cursor: default !important;
  margin-left: ${props => `${props.theme.pxToRem(-12)}`} !important;
  float: right;
`;

const StyledIcon = styled(Icon)`
  font-weight: ${props => props.theme.fontWeights.light};
  margin: ${props => `0 ${props.theme.pxToRem(8)}`};
`;

const StyledIconCheck = styled(IconCertCheck)`
  display: block;
`;

const ItemListWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: ${props => props.theme.pxToRem(10)};
`;

const StyledButton = styled(Button)`
  width: 100%;
  display: block;
  margin: auto;
`;

const ListTitle = styled.p`
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-top: ${props => props.theme.pxToRem(12)}!important;
  margin-bottom: ${props => props.theme.pxToRem(4)}!important;
`;

const CostLabel = styled.span<{ shouldShowNewPackageBuilder?: boolean }>`
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.s};
  font-style: normal;
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: normal;
  letter-spacing: ${props => props.theme.pxToRem(-0.32)};
  ${props =>
    props.shouldShowNewPackageBuilder &&
    `
      ${props.theme.mediaQueries.mobileOnly} {
        font-size: ${props.theme.pxToRem(15)};
      }
    `}
`;

const PurpleHeaderDiv = styled.div<{ isAddNewMembershipProduct?: boolean }>`
padding: ${props => `${props.theme.pxToRem(15)} ${props.theme.pxToRem(19)}`}
${props =>
  props.isAddNewMembershipProduct
    ? `min-height: ${props.theme.pxToRem(80)};
       font-size: ${props.theme.pxToRem(21)};
      `
    : `height: ${props.theme.pxToRem(86)};
       font-size: ${props.theme.fontSizes.l};
      `}
flex-shrink: 0;
background-color: ${props => props.theme.colors.primaryPurple};
color: ${props => props.theme.colors.neutralWhite};
width: 100%;
font-style: normal;
font-weight: ${props => props.theme.fontWeights.regular};
line-height: normal;
border-radius: ${props => props.theme.pxToRem(4)}
letter-spacing: ${props => props.theme.pxToRem(-0.48)}
${props => props.theme.mediaQueries.mobileOnly} {
  text-align: left;
  }
`;

const StyledLearnMoreText = styled.u`
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.s};
  font-style: normal;
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: normal;
  letter-spacing: ${props => props.theme.pxToRem(-0.32)};
`;
