import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

export const createNotification: any = createAction('notifications/CREATE');

export const removeNotification: any = createAction('notifications/REMOVE');

export const removeAllNotifications: any = createAction('notifications/REMOVE_ALL');

export const toggleNotification: any = (notificationItem: State.NotificationItem) => (
  dispatch: Dispatch,
  getState: () => State.Root
) => {
  const state: State.Root = getState();

  if (state.notifications.timeoutId) {
    clearTimeout(state.notifications.timeoutId);
  }

  dispatch(removeAllNotifications());

  const timeoutId = setTimeout(() => {
    dispatch(removeNotification(notificationItem));
  }, 2000);

  dispatch(createNotification({ notificationItem, timeoutId }));
};
