import React from 'react';
import { ContentfulHelpers } from 'mxp-utils';
import { BlockStepper } from 'components/atoms/BlockStepper/BlockStepper';
import { BlockWelcome } from 'components/organisms/BlockRenderer/BlockWelcome';
import { BlockContent } from 'components/organisms/BlockRenderer/BlockContent';
import { BlockInquiryFormContainer } from 'containers/BlockInquiryFormContainer';
import { BlockMarketing } from 'components/organisms/BlockRenderer/BlockMarketing';
import { BlockBenefit } from 'components/organisms/BlockRenderer/BlockBenefit';
import { BlockTileList } from 'components/organisms/BlockRenderer/BlockTileList';
import { BlockLongForm } from 'components/organisms/BlockRenderer/BlockLongForm';
import { BlockContentProductPromotion } from 'components/organisms/BlockRenderer/BlockContentProductPromotion';
import { BlockVideo } from 'components/organisms/BlockRenderer/BlockVideo';
import { BlockAccordion } from 'components/organisms/BlockRenderer/BlockAccordion';
import { BlockMembershipProducts } from 'components/organisms/BlockRenderer/BlockMembershipProducts';
import { BlockTable } from 'components/organisms/BlockRenderer/BlockTable';
import { BlockList } from 'components/organisms/BlockRenderer/BlockList';
import { BlockGroupMembers } from 'components/organisms/BlockRenderer/BlockGroupMembers';
import { BlockRecommendedProducts } from 'components/organisms/BlockRenderer/BlockRecommendedProducts';
import { BlockIntegratedSearchContainer } from 'containers/BlockIntegratedSearchContainer';
import { Contentful as ContentfulTypes } from 'mxp-schemas';
import { LandingPageBlock } from 'components/molecules/LandingPageBlock/LandingPageBlock';
import { BlockCerosAnimation } from 'components/organisms/BlockRenderer/BlockCerosAnimation';
import { BlockTurtlAnimation } from 'components/organisms/BlockRenderer/BlockTurtlAnimation';
import { TestimonialCarousel } from 'components/atoms/TestimonialCarousel/TestimonialCarousel';
import { BlockBlueToadPageTurner } from 'components/organisms/BlockRenderer/BlockBlueToadPageTurner';
import { BenefitsBecomeMember } from 'components/pages/PageBenefitsBecomeMember/index';
import { BlockSyllabus } from 'components/organisms/BlockRenderer/BlockSyllabus';
import { BlockPromoCard } from 'components/organisms/BlockRenderer/BlockPromoCard';
import { BlockQuickLinks } from './BlockQuickLinks';
import { BlockWelcomeCarousel } from 'components/organisms/BlockRenderer/BlockWelcomeCarousel';
import { useSelector } from 'react-redux';
import { staticLandingPageContentTypeSlugSelector } from 'modules/staticLandingPagesContent/selectors';
import { BlockStats } from 'components/organisms/BlockRenderer/BlockStats';
import { BlockEditorialHero } from 'components/organisms/BlockRenderer/BlockEditorialHero';

interface Props {
  block: ContentfulTypes.ModularContent.Block;
  isFullPagePagination: boolean;
  isUserOnBoarded: boolean;
  withHeader?: boolean;
  withFooter?: boolean;
  withExternalAd?: boolean;
  headerHeight?: number;
  footerHeight?: number;
  targetContainer?: any;
  containerContentRef?: any;
  isFirstBlock?: boolean;
  isMobile?: boolean;
  isAuth: boolean | null;
  isToolkitPage?: boolean;
  isLandingPage?: boolean;
  isLocked?: boolean;
  isStaticLandingPageFetched?: boolean;
  isStaticLandingPage?: boolean;
  isInHeader?: boolean;
  isArticlePage?: boolean;
  isHomePage?: boolean;
}

export const BlockRendererLandingPageBlock: React.FC<Props> = ({
  block,
  isFullPagePagination,
  isUserOnBoarded,
  withHeader,
  withFooter,
  withExternalAd,
  headerHeight,
  footerHeight,
  targetContainer,
  containerContentRef,
  isFirstBlock,
  isMobile,
  isAuth,
  isToolkitPage,
  isLandingPage,
  isLocked,
  isStaticLandingPageFetched,
  isStaticLandingPage,
  isInHeader,
  isArticlePage,
  isHomePage,
}) => {
  const contentTypeSlug = useSelector(staticLandingPageContentTypeSlugSelector);
  const isCimaMipPropPage = ContentfulHelpers.isCimaMipContentType(contentTypeSlug);

  const renderPage = (): React.ReactNode => {
    switch (block.contentType) {
      case ContentfulHelpers.CONTENT_TYPES.SYLLABUS_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockSyllabus}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.TESTIMONIALS_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={TestimonialCarousel}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.STATS_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockStats}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.STEPPER_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockStepper}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.MEMBERSHIP_PRODUCT_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockMembershipProducts}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isCimaMipJourney={isCimaMipPropPage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockWelcome}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isStaticLandingPage={isStaticLandingPage}
            isInHeader={isInHeader}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.PROMOCARD_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockPromoCard}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.MARKETING_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockMarketing}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.LAST_FOUR_MODIFIED_PRODUCTS_BLOCK:
      case ContentfulHelpers.CONTENT_TYPES.TRENDING_CONTENT_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockContent}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.BENEFITS_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={isStaticLandingPageFetched || isArticlePage ? BenefitsBecomeMember : BlockBenefit}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isArticlePage={isArticlePage}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.INTEGRATED_SEARCH_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockIntegratedSearchContainer}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isMobile={isMobile}
            isFirstBlock={isFirstBlock}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.PRODUCT_MARKETING_PROMOTIONS_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockContentProductPromotion}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isMobile={isMobile}
            isFirstBlock={isFirstBlock}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.VIDEO_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockVideo}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.ACCORDION_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockAccordion}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.TABLE_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockTable}
            modularBlock={block}
            isUserOnBoarded={isUserOnBoarded}
            isFullPagePagination={isFullPagePagination}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.LIST_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockList}
            modularBlock={block}
            isUserOnBoarded={isUserOnBoarded}
            isFullPagePagination={isFullPagePagination}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.TOPIC_LIST_BLOCK:
      case ContentfulHelpers.CONTENT_TYPES.PRODUCT_TYPE_LIST_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockTileList}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.LONG_FORM_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockLongForm}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isMobile={isMobile}
            isFirstBlock={isFirstBlock}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.INQUIRY_FORM_BLOCK:
        const blockRequiresAuth =
          (block as ContentfulTypes.ModularContent.InquiryFormBlock).inquiryForm ===
          ContentfulTypes.InquiryFormType.BULK_ORDER;
        return (
          <LandingPageBlock
            BlockComponent={BlockInquiryFormContainer}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            blockRequiresAuth={blockRequiresAuth}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isToolkitPage={isToolkitPage}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.RECOMMENDED_PRODUCTS_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockRecommendedProducts}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isMobile={isMobile}
            isFirstBlock={isFirstBlock}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.GROUP_MEMBERS_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockGroupMembers}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.CEROS_ANIMATION_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockCerosAnimation}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isLandingPage={isLandingPage}
          />
        );

      case ContentfulHelpers.CONTENT_TYPES.TURTL_ANIMATION_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockTurtlAnimation}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isLandingPage={isLandingPage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.BLUETOAD_PAGETURNER_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockBlueToadPageTurner}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isLandingPage={isLandingPage}
            isLocked={isLocked}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.QUICKLINKS_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockQuickLinks}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK_CAROUSEL:
        return (
          <LandingPageBlock
            BlockComponent={BlockWelcomeCarousel}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      case ContentfulHelpers.CONTENT_TYPES.EDITORIAL_HERO_BLOCK:
        return (
          <LandingPageBlock
            BlockComponent={BlockEditorialHero}
            modularBlock={block}
            isFullPagePagination={isFullPagePagination}
            isUserOnBoarded={isUserOnBoarded}
            withHeader={withHeader}
            withFooter={withFooter}
            withExternalAd={withExternalAd}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            targetContainer={targetContainer}
            containerContentRef={containerContentRef}
            isFirstBlock={isFirstBlock}
            isMobile={isMobile}
            isAuth={isAuth}
            isHomePage={isHomePage}
          />
        );
      default:
        return null;
    }
  };

  return renderPage() as JSX.Element;
};
