import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { createApprenticePathway, getApprenticePathway } from './actions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [createApprenticePathway]: {
    next: (state: State.Apprentice, action: any): State.Apprentice => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    throw: (state: State.Apprentice): State.Apprentice => ({
      ...state,
      isLoading: true,
    }),
  },
  [getApprenticePathway]: {
    next: (state: State.Apprentice, action: any): State.Apprentice => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    throw: (state: State.Apprentice): State.Apprentice => ({
      ...state,
      isLoading: true,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);

export * from './selectors';
export * from './constants';
export * from './actions';
