import React from 'react';
import { CreditCardTypes, OtherPaymentTypes } from 'constants/index';
import styled from 'styled-components';

interface Props {
  type?: string | null;
}

export const OtherPaymentIcon: React.FC<Props> = ({ type }: Props) => {
  const project = (): React.ReactNode => {
    switch (type) {
      case CreditCardTypes.VISA:
        return <StyledIcon src="/visa.png" alt="Visa" />;
      case CreditCardTypes.MASTERCARD:
        return <StyledIcon src="/mastercard.png" alt="Mastercard" />;
      case CreditCardTypes.DISCOVER:
        return <StyledIcon src="/discover.png" alt="Discover" />;
      case CreditCardTypes.AMERICANEXPRESS:
        return <StyledIcon src="/amex.png" alt="American Express" />;
      case OtherPaymentTypes.PAYPAL:
        return <StyledIcon src="/paypal.png" alt="Paypal" />;
      case OtherPaymentTypes.ACH:
        return <StyledIcon src="/ach.png" alt="Ach" />;
      case OtherPaymentTypes.BANKTRANSFER:
        return <StyledIcon src="/banktransfer.png" alt="Bank Transfer" />;
      default:
        return <>'N/A'</>;
    }
  };
  return project() as JSX.Element;
};

const StyledIcon = styled.img`
  max-width: ${props => props.theme.pxToRem(44)};
  height: ${props => props.theme.pxToRem(28)};
  width: 100%;
`;
