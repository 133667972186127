import React from 'react';
import styled from 'styled-components';
import { Link, NotificationBanner, NotificationBannerEnums } from 'components/atoms';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

interface Props {
  addressInvalid?: boolean;
  b2b?: boolean;
  b2bc?: boolean | null;
}

export const InvalidAddressBanner: React.FC<Props> = React.memo(({ addressInvalid, b2b, b2bc }) => {
  return (
    <StyledNotificationBanner
      variant={NotificationBannerEnums.variant.red}
      testId="checkout--warning"
      childrenTestId="checkout-warning--text"
      icon={<StyledIconError />}
    >
      Sorry, we’re unable to verify your {addressInvalid ? 'shipping address' : 'billing address'}.{' '}
      {b2b || b2bc ? (
        <>
          Please contact your customer to confirm the address. If the customer address is valid, please raise an{' '}
          <Link isExternal testId="error-email" to="mailto:help@aicpa-cima.com">
            IT helpdesk ticket
          </Link>
          .
        </>
      ) : (
        <>
          Please edit the address and try again. If your address is valid and you are still experiencing this error,
          please contact us through the chat icon on the right or schedule a chat appointment through our{' '}
          <Link isExternal to={getPath(Routes.HELP)} testId="ei-question-us-phone">
            help page
          </Link>
          .
        </>
      )}
    </StyledNotificationBanner>
  );
});

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  width: 71%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  align-self: flex-start;
  align-self: flex-start !important;
  margin-top: ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(30)};
  }
`;
