import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Product } from 'mxp-schemas';
import { Modal } from 'components/atoms/Modal/Modal';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';

const getCurrencyText = (selectedCurrency: Product.ProductCurrencyLabel) => {
  const currency = {
    [Product.ProductCurrencyLabel.USD]: 'Dollars ($)',
    [Product.ProductCurrencyLabel.GBP]: 'British pounds (£)',
    [Product.ProductCurrencyLabel.EUR]: 'Euro (€)',
  };
  return currency[selectedCurrency];
};

interface Props {
  onCancel: () => void;
  onConfirmation: () => void;
  selectedCurrency?: Product.ProductCurrencyLabel;
}

export const CimaCurrencySelectionModal: React.FC<Props> = ({
  onCancel,
  onConfirmation,
  selectedCurrency = Product.ProductCurrencyLabel.USD,
}) => {
  const currencyText = useMemo(() => getCurrencyText(selectedCurrency), [selectedCurrency]);

  return (
    <StyledModal
      showCloseCross
      open
      onClose={onCancel}
      heading="Your currency selection will be locked"
      testId="currency-selection-modal"
      confirmNode={
        <StyledButton
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          testId="currency-selection-continue-button"
          onClick={onConfirmation}
        >
          I understand
        </StyledButton>
      }
    >
      <StyledParagraph>
        You selected&nbsp;
        <StyledCurrency>{currencyText}</StyledCurrency>. You can change your preferred currency until the completion of
        your first purchase. The currency will then be locked for future purchases.
      </StyledParagraph>
      <StyledParagraph>
        Please note: if you currently have items in your cart with a different currency, they will be deleted
        automatically and you will need to add them again.
      </StyledParagraph>
    </StyledModal>
  );
};

const StyledParagraph = styled.p`
  line-height: 1.57;
  text-align: center;
`;

const StyledModal = styled(Modal)`
  &&&&& {
    width: ${props => props.theme.pxToRem(560)};
    background: ${props => props.theme.colors.neutralWhite};
    margin-top: ${props => props.theme.pxToRem(48)};
    border-radius: ${props => props.theme.pxToRem(2)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 95%;
    }
  }
`;

const StyledButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(250)};
    height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledCurrency = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
`;
