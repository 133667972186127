import React from 'react';
import { Grid, Placeholder, Segment } from 'semantic-ui-react';

export const CardListPlaceholder: React.FC<{}> = () => (
  <Grid columns={3} stackable>
    {[...Array(3).keys()].map(item => (
      <Grid.Column key={item}>
        <Segment raised>
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line length="medium" />
              <Placeholder.Line length="short" />
            </Placeholder.Paragraph>
          </Placeholder>
        </Segment>
      </Grid.Column>
    ))}
  </Grid>
);
