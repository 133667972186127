import React from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';
import { Grid } from 'semantic-ui-react';

export interface Props {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  isLoading?: boolean;
}

export const ConfirmationRemoveModal: React.FC<Props> = ({ open, onClose, onConfirm, isLoading }) => {
  const heading = <>{`Are you sure you want to remove this membership product?`}</>;
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      heading={heading}
      testId="confirmation-details-modal"
      showCloseCross
      cancelNode={
        <StyledButton
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          testId="cancel-refund"
          onClick={onClose}
          disabled={isLoading}
          className="confirmRemovalModalButtons"
        >
          Cancel and go back to cart
        </StyledButton>
      }
      confirmNode={
        <StyledButton
          size={ButtonEnums.sizes.small}
          testId="confirm-refund"
          variant={ButtonEnums.variants.primary}
          onClick={onConfirm}
          loading={isLoading}
          className="confirmRemovalModalButtons"
        >
          Confirm removal
        </StyledButton>
      }
    >
      <Grid stackable centered>
        <StyledGridRow>
          Once the removal will be confirmed, <StyledBold>all products from the cart will be removed.</StyledBold>
          <br />
          Removing items from your cart deletes any membership information provided
        </StyledGridRow>
      </Grid>
    </StyledModal>
  );
};

const StyledButton = styled(Button)`
  &&&&&&& {
    width: ${props => props.theme.pxToRem(220)} !important;
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    background: ${props => props.theme.colors.neutralGrey1};
    min-height: ${props => props.theme.pxToRem(333)};
    max-width: ${props => props.theme.pxToRem(790)};
    margin-top: ${props => props.theme.pxToRem(48)};
    > .header {
      h2 {
        min-height: ${props => props.theme.pxToRem(64)};
        max-width: ${props => props.theme.pxToRem(440)};
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }
  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding-bottom: ${props => props.theme.pxToRem(10)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-top: ${props => props.theme.pxToRem(10)};
    }
  }
`;

const StyledBold = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: 0 ${props => props.theme.pxToRem(3)} 0 ${props => props.theme.pxToRem(3)};
`;
