import React from 'react';
import styled from 'styled-components';

interface Props {
  name: string;
}

export const SearchQueryResultItem = React.memo(({ name }: Props) => (
  <StyledSearchQueryResultItem>
    <span>{name}</span>
    <StyledSearchQueryResultItemCount>48 results</StyledSearchQueryResultItemCount>
  </StyledSearchQueryResultItem>
));

const StyledSearchQueryResultItem = styled.li`
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.neutralGrey2};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledSearchQueryResultItemCount = styled.span`
  font-style: italic;
`;
