import React from 'react';
import { Grid, Divider } from 'semantic-ui-react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { ExpandableProps } from './Expandable';

export const NonExpandable: React.FC<ExpandableProps> = props => {
  const { items, title, divided, titleTestId, isBgColorDark = false } = props;

  return (
    <>
      {!!title && items.length > 0 && (
        <>
          <Grid>
            <Control floated="left" mobile={16} computer={8} data-testid={titleTestId}>
              {title}
            </Control>
          </Grid>
          <Divider />
        </>
      )}
      {items &&
        items.map(item => (
          <Panel key={item.id} divided={divided}>
            <ContentContainer isBgColorDark={isBgColorDark}>{item.heading}</ContentContainer>
          </Panel>
        ))}
    </>
  );
};

const Panel = styled.div<ThemedStyledProps<{ divided: boolean | undefined }, DefaultTheme>>`
  border-bottom: ${props => (props.divided ? `1px solid ${props.theme.colors.neutralGrey3}` : null)};
`;

const ContentContainer = styled.div<{ isBgColorDark: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 10px;
  svg path {
    stroke: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.primaryPurple)};
  }
`;

const Control = styled(Grid.Column)`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  ${props => props.theme.mediaQueries.mobileOnly} {
    &&& + div.right {
      display: none;
    }
  }
`;
