import { CenterAdminTableType } from 'constants/center-admin';

export enum CenterAdminActionNames {
  GET_ORGANIZATIONS = 'center-admin/GET_ORGANIZATIONS',
  GET_ORGANIZATION_ADMINS_BY_ROLE = 'center-admin/GET_ORGANIZATION_ADMINS_BY_ROLE',
  GET_CENTER_MEMBERSHIPS = 'center-admin/GET_CENTER_MEMBERSHIPS',
  GET_ALL_CENTER_MEMBERSHIPS = 'center-admin/GET_ALL_CENTER_MEMBERSHIPS',
  CLEAR_ALL_CENTER_MEMBERSHIPS = 'center-admin/CLEAR_ALL_CENTER_MEMBERSHIPS',
  SET_CENTER_MEMBERSHIPS_FILTERS = 'center-admin/SET_CENTER_MEMBERSHIPS_FILTERS',
  GET_ORGANIZATION_MEMBERSHIPS_BY_HIERARCHY = 'center-admin/GET_ORGANIZATION_MEMBERSHIPS_BY_HIERARCHY',
  GET_ORGANIZATION_MEMBERSHIPS = 'center-admin/GET_ORGANIZATION_MEMBERSHIPS',
  SET_DATA = 'center-admin/SET_DATA',
  SET_PAGINATION = 'center-admin/SET_PAGINATION',
  GET_CENTER_MEMBERSHIP_INVOICES = 'center-admin/GET_CENTER_MEMBERSHIP_INVOICES',
  SET_MODIFIED_HASH = 'center-admin/SET_MODIFIED_HASH',
  FETCH_CONTRACT_LINE_ITEMS = 'center-admin/FETCH_CONTRACT_LINE_ITEMS',
  SET_CONTRACT_LINE_ITEMS_MODIFIERS = 'center-admin/SET_CONTRACT_LINE_ITEMS_MODIFIERS',
  GET_INVOICE_FILE = 'center-admin/GET_INVOICE_FILE',
  SET_INVOICE_DOWNLOADING_ID = 'center-admin/SET_INVOICE_DOWNLOADING_ID',
  SET_IS_LOADING = 'center-admin/SET_IS_LOADING',
  SET_SELECTED_INVOICE = 'center-admin/SET_SELECTED_INVOICE',
  GET_SELECTED_INVOICE = 'center-admin/GET_SELECTED_INVOICE',
  INVOICES_GET_HOSTED_PAGE_SIGNATURE = 'center-admin/INVOICES_GET_HOSTED_PAGE_SIGNATURE',
  INVOICES_MAKE_INVOICE_PAYMENT = 'center-admin/INVOICES_MAKE_INVOICE_PAYMENT',
  INVOICES_GET_INVOICE_PAYMENT_CONFIRMATION_RESULT = 'center-admin/INVOICES_GET_INVOICE_PAYMENT_CONFIRMATION_RESULT',
  SEND_FIRM_SUB = 'user/SEND_FIRM_SUB',
  PROVISION_SEAT = 'PROVISION_SEAT',
  SET_PROVISION_SEAT_LOADING = 'SET_PROVISION_SEAT_LOADING',
  SET_PROVISION_SEAT_ERROR = 'SET_PROVISION_SEAT_ERROR',
  GET_CENTER_SERVICE_CONTRACTS = 'center-admin/GET_CENTER_SERVICE_CONTRACTS',
  REMOVE_CONTRACT_LINE_ITEMS = 'center-admin/REMOVE_CONTRACT_LINE_ITEMS',
}

export const initData = {
  hash: {},
  count: 0,
  selectedIds: [],
  modifiedHash: {},
  isLoading: true,
  isFetched: false,
  downloadingId: null,
  downloadingError: false,
};

export const initialState: CenterAdmin.Root = {
  data: {
    [CenterAdminTableType.MANAGE_CENTER_MEMBERSHIP_INVOICES]: initData,
    [CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES]: initData,
  },
  pagination: {
    [CenterAdminTableType.MANAGE_CENTER_MEMBERSHIP_INVOICES]: {
      offset: 0,
      limit: 10,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: true,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
      },
    },
    [CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES]: {
      offset: 0,
      limit: 50,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: true,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        isPaidInvoice: false,
        isPaidInvoiceFetchedData: false,
      },
    },
  },
  organizations: {
    list: [],
    isOrganizationsFetched: false,
    error: null,
  },
  organizationAdmins: [],
  centerMemberships: {
    list: [],
    filters: {
      membershipStatus: 'active',
    },
    isFetched: false,
  },
  selectedCenterMembership: null,
  organizationMemberships: [],
  seatManagement: {
    serviceContractsList: [],
    contractLineItemList: [],
    contractLineItemModifiers: {
      query: '',
      limit: 10,
      offset: 0,
    },
    contractLineItemIsFetched: false,
  },
  selectedInvoice: null,
  paymentResult: null,
  provisioning: {
    loading: false,
    errors: null,
  },
};
