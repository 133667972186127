import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonEnums, Input } from 'components/atoms';
import { SuccessNotification } from '../../atoms/SuccessNotification/SuccessNotification';
import { ErrorNotification } from '../../atoms/ErrorNotification/ErrorNotification';
import { default as request } from 'utils/GraphQLClient';
import { CLONE_VARIANT_BY_SKU } from 'mxp-graphql-queries';

export const PageAdminLookUpOperations = () => {
  const [sku, setSku] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setSuccess('');
    setError('');
    setLoading(true);
    request(CLONE_VARIANT_BY_SKU, { sku })
      .then((data: { cloneVariantBySku: string }) => {
        setSuccess(`Variant successfully cloned as ${data.cloneVariantBySku}`);
        setLoading(false);
      })
      .catch(e => {
        if (e.response?.errors?.[0]?.message?.includes('SKU-clone already exists')) {
          setError(
            `Variant cannot be cloned as ${sku}-clone already exists. Please update this in CommerceTools before proceeding.`
          );
        } else {
          setError(`Uh oh. Something went wrong and your changes have not been saved.`);
        }
        setLoading(false);
      });
  };

  const handleInputChange = (event: React.SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    setSku(value);
  };

  return (
    <form onSubmit={onSubmit}>
      <StyledText data-testid="clone-product-sku-prompt-text">
        Enter a SKU to duplicate all fields, images, and prices.
        <br />
        Cloned variants will be 'Unpublished' in CommerceTools
      </StyledText>
      <StyledInputContainer>
        <StyledRegularInput
          labelName="Product SKU"
          testId="clone-product-sku-input"
          labelId="clone-product-sku-label"
          value={sku}
          onChange={handleInputChange}
          placeholder="Enter product SKU"
        />
      </StyledInputContainer>
      <SearchButton
        size={ButtonEnums.sizes.medium}
        onClick={onSubmit}
        testId="clone-product-button"
        variant={ButtonEnums.variants.primary}
        disabled={!sku || loading}
        loading={loading}
      >
        Clone
      </SearchButton>
      {success && (
        <StyledNotificationContainer>
          <SuccessNotification label={success} />
        </StyledNotificationContainer>
      )}
      {error && (
        <StyledNotificationContainer>
          <ErrorNotification label={error} />
        </StyledNotificationContainer>
      )}
    </form>
  );
};

const StyledInputContainer = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(40)};
  max-width: ${props => props.theme.pxToRem(265)};
`;

const StyledRegularInput = styled(Input)`
  margin-bottom: ${props => props.theme.pxToRem(0)};
  width: 100%;
`;

const SearchButton = styled(Button)`
  width: ${props => props.theme.pxToRem(98)};
`;

const StyledNotificationContainer = styled.div`
  margin: ${props => props.theme.pxToRem(20)} 0;
`;

const StyledText = styled.div`
  max-width: ${props => props.theme.pxToRem(720)};
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.pxToRem(14)};
  line-height: 1.71;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(17)};
`;
