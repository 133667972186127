import React, { RefObject } from 'react';
import { List } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import {
  Grid,
  Button,
  ButtonEnums,
  OnlyDesktop,
  OnlyMobile,
  ButtonLink,
  OnlyMobileCSS,
  Divider,
} from 'components/atoms';
import { ArrowDown, CPEProduct } from 'components/atoms/svg';
import { ProductThumbnail, ProductThumbSize } from 'components/molecules/ProductThumbnail/ProductThumbnail';
import { NotificationForAnonymousUser } from 'components/molecules/NotificationForAnonymousUser/NotificationForAnonymousUser';
import { AccessOPLProduct } from 'components/molecules/AccessOPLProduct/AccessOPLProduct';
import { Content, Product, User as UserTypes } from 'mxp-schemas';
import { Scroller, arrayIncludes, FREE_LABEL, isServer } from 'utils';
import { CART_CTA_TEXT, PRODUCT_CART_STATUS, SOURCE } from 'constants/index';
import { ProductPrice } from 'components/molecules/ProductPrice/ProductPrice';
import { PriceRangeForAllUserTypes } from 'components/pages/PageProduct/PriceRangeForAllUserTypes';
import { UserMemberTypes } from 'modules/user/constants';
import { ProductHeroDetailsTable } from './ProductHeroDetailsTable';
import { ReactComponent as ExternalIcon } from 'resources/images/ic-open-in-new.svg';
import { theme } from 'theme';
import { ProductBundlePriceRange } from './ProductBundlePriceRange';
import { WebcastPassPopup, WebcastPassPosition } from './WebcastPassPopup';

import {
  productCurrencySelector,
  isUserFromRestrictedCountrySelector,
  productHasAvailablePreferredCurrencySelector,
} from 'modules/products/selectors';
import { useSelector } from 'react-redux';
import { isUserMemberSelector } from 'modules/user/selectors';

interface PriceRangeProps {
  priceRange: Product.PriceRange;
  productType: string | undefined;
  isAuth: boolean | null;
  className?: string;
  isFreeTrialSelected: boolean;
  hasNonMemberPrice?: boolean;
  isUserSuspendedByEthics?: boolean;
  isNotForProfitSub?: boolean;
}

const PriceRange = ({
  priceRange,
  productType,
  isAuth,
  className = '',
  isFreeTrialSelected = false,
  hasNonMemberPrice,
  isNotForProfitSub,
}: PriceRangeProps) => {
  const isContribution: boolean = productType === Product.ProductType.CONTRIBUTION;
  const currency = useSelector(productCurrencySelector);

  return (
    <>
      {priceRange?.minPrice && priceRange?.maxPrice && !isContribution && (
        <StyledPriceInfo className={className}>
          <StyledFinalPrice>
            {isFreeTrialSelected ? (
              <StyledFreePrice>{FREE_LABEL}</StyledFreePrice>
            ) : hasNonMemberPrice ? (
              `${priceRange.minPrice.transformedPrice?.replace(
                '.00',
                ''
              )} - ${priceRange.maxPrice.transformedPrice?.replace('.00', '')}`
            ) : (
              priceRange.minPrice.transformedPrice?.replace('.00', '')
            )}
          </StyledFinalPrice>
        </StyledPriceInfo>
      )}

      {isContribution && (
        <StyledPriceInfo className={className}>
          <StyledFinalPrice>Minimum contribution: {currency.sign}1.00</StyledFinalPrice>
        </StyledPriceInfo>
      )}

      {!isAuth && (
        <StyledNotificationForAnonymousUser
          isContribution={isContribution}
          showDefault={!isContribution}
          isNotForProfitSub={isNotForProfitSub}
        />
      )}
    </>
  );
};

const PriceRangeForUser = ({ priceRanges, isAuth }: { priceRanges: any; isAuth: boolean }) => {
  const { member, nonMember } = priceRanges;
  const showMembershipDiscount = member.low !== nonMember.low || member.high !== nonMember.high;

  return (
    <>
      <StyledPriceInfo>
        {showMembershipDiscount && (
          <StyledFullPrice>{`${nonMember.low?.replace('.00', '')} - ${nonMember.high?.replace(
            '.00',
            ''
          )}`}</StyledFullPrice>
        )}
        <StyledFinalPrice>{`${member.low?.replace('.00', '')} - ${member.high?.replace('.00', '')}`}</StyledFinalPrice>
      </StyledPriceInfo>
      {!isAuth && <StyledNotificationForAnonymousUser />}
    </>
  );
};

type ProductHeroProps = Partial<Product.ProductItem> & {
  productItem: Product.ProductItem;
  calculatedAccessDuration: string;
  productButtonStatus: PRODUCT_CART_STATUS;
  cartLoading: boolean;
  cartTransitionError: boolean;
  showCC?: boolean | undefined | null;
  optionScrollTarget: RefObject<HTMLDivElement>;
  creditInfoScrollTarget: RefObject<HTMLDivElement>;
  accessRowScrollTarget: RefObject<HTMLDivElement>;
  productChildrenInfoScrollTarget: RefObject<HTMLDivElement>;
  pricing?: Product.PriceInfo;
  priceRangeForUser?: Product.FormattedPriceRanges | null;
  priceRange: Product.PriceRange;
  dateRange?: any;
  location?: string;
  selectedVariant?: number;
  isProductWithMultipleOptions: boolean;
  isAvailableForSale: boolean;
  isAuth: boolean | null;
  productTypeLabel: string;
  productFormatLabel: string;
  sku: string | null;
  examFormatLabel: string | null;
  thirdPartyLink?: string;
  userMemberType: UserMemberTypes;
  pageButtonHandler: (
    productCartStatus: PRODUCT_CART_STATUS,
    productType: string,
    cartValidation: Common.CartValidation | null
  ) => void;
  isPhysicalProduct: boolean;
  sessions?: number | null;
  isMultiDayWebcast?: boolean;
  isWebcastSeries?: boolean;
  isPhSubscriptionMagazine: boolean;
  isPublication: boolean;
  isCourse: boolean;
  isBundle: boolean;
  isTransferableProductType: boolean;
  isPremiumContent: boolean;
  premiumContentTo: string;
  productQuantity: number;
  userPlatform?: UserTypes.UserPlatform | null;
  isPhSubscriptionCourse: boolean;
  handleProvisionAccess: () => void;
  productSku?: string | undefined;
  publicationYear: string | undefined;
  hasOnlineConference: boolean;
  isFreeTrialSelected: boolean;
  isOPL: boolean;
  oplRedirectUrl: string;
  oplHidUrl: string;
  userEmail?: string;
  fvsMembership?: UserTypes.MembershipIdsEnum[] | [];
  oktaId?: string;
  isUserSuspendedByEthics?: boolean;
  allPrices?: Product.PriceForRole[];
};

export const ProductHero = ({
  productItem,
  calculatedAccessDuration,
  credits,
  productButtonStatus,
  cartLoading,
  cartTransitionError,
  showCC,
  optionScrollTarget,
  creditInfoScrollTarget,
  accessRowScrollTarget,
  productChildrenInfoScrollTarget,
  pricing,
  priceRangeForUser,
  dateRange,
  location,
  isProductWithMultipleOptions,
  isAvailableForSale,
  priceRange,
  isAuth,
  productTypeLabel,
  productFormatLabel,
  sku,
  examFormatLabel,
  thirdPartyLink,
  userMemberType,
  isPhysicalProduct,
  pageButtonHandler,
  sessions,
  isMultiDayWebcast,
  isWebcastSeries,
  isPhSubscriptionMagazine,
  isPublication,
  isCourse,
  isBundle,
  isTransferableProductType,
  isPremiumContent,
  premiumContentTo,
  productQuantity,
  userPlatform,
  isPhSubscriptionCourse,
  handleProvisionAccess,
  productSku,
  publicationYear,
  hasOnlineConference,
  isFreeTrialSelected,
  isOPL,
  oplRedirectUrl,
  oplHidUrl,
  userEmail,
  fvsMembership,
  oktaId,
  isUserSuspendedByEthics,
  allPrices,
}: ProductHeroProps) => {
  const {
    subscriptionProductType,
    productId,
    name = '',
    description = '',
    variants,
    productType,
    programLevel,
    nasbaFieldOfStudy,
    categories,
    authors,
    publisher,
    isSubscribed,
    slug,
    speakers,
    instructors,
  } = productItem;
  const subscriptionType = subscriptionProductType?.key;
  const isNotForProfitSub: boolean = Boolean(slug === 'not-for-profit-topic-subscription');

  const isUserFromRestrictedCountry = useSelector(isUserFromRestrictedCountrySelector);
  const isUserMember = useSelector(isUserMemberSelector);

  const productHasAvailablePreferredCurrency = useSelector(productHasAvailablePreferredCurrencySelector);
  const productHasNoMatchingPreferredCurrency = !productHasAvailablePreferredCurrency;

  const scrollToOptionsHandler = () => {
    Scroller.scrollToTarget(optionScrollTarget, { useWindow: true });
  };

  const { priceFull = null, priceFinal = null, discountAmount = null } = pricing || {};
  const coverSrc = variants?.[0]?.images?.[0]?.imageUrl || '';
  const cartValidation = { quantityValue: productQuantity, isChecked: false };
  const hasNonMemberPrice = pricing?.hasNonMemberPrice;

  const cartHandler = () => {
    pageButtonHandler(productButtonStatus, productType, cartValidation);
  };

  const getProductTypeIcon = () => {
    const { ProductType, SubscriptionProductType } = Product;
    if (
      arrayIncludes(
        [ProductType.CONFERENCE, ProductType.PUBLICATION, ProductType.EXAM, ProductType.COURSE],
        productType
      ) ||
      (productType === ProductType.SUBSCRIPTION &&
        [SubscriptionProductType.PUBLICATION, SubscriptionProductType.COURSE].includes(
          subscriptionType as Product.SubscriptionProductType
        ))
    ) {
      return <CPEProduct size={16} testId="cpe-product-hero-icon" />;
    }
    return null;
  };

  const heroLabelBuilder = () => {
    if (!productTypeLabel) return;
    const icon = getProductTypeIcon();
    return icon ? (
      <StyledProductTypeContainer>
        <StyledProductTypeIcon>{icon}</StyledProductTypeIcon>
        <StyledProductType data-testid={`category-box-${productType}`}>{productTypeLabel}</StyledProductType>
      </StyledProductTypeContainer>
    ) : (
      <StyledProductType data-testid={`category-box-${productType}`}>{productTypeLabel}</StyledProductType>
    );
  };

  const isExam = arrayIncludes([Product.ProductType.EXAM], productType);
  const isContribution: boolean = productType === Product.ProductType.CONTRIBUTION;
  const disableNonMemberPurchase = !pricing?.hasNonMemberPrice && !isUserMember && !isBundle;
  const isSlugDonation: boolean = slug === 'aicpa-foundation';
  const redirectParams = `icid=${SOURCE}:${Content.CategorySlugs.CPE_LEARNING}:${productId}:${CART_CTA_TEXT[productButtonStatus]}`;
  // To check if the thirdPartyLink has query params
  const hasQuery = thirdPartyLink?.includes('?');
  const hasOneVariantIsOnStock = !isProductWithMultipleOptions && variants[0].availability?.isOnStock;
  const isDisabled: boolean =
    (isPhysicalProduct && !hasOneVariantIsOnStock) || productHasNoMatchingPreferredCurrency || disableNonMemberPurchase;
  // because Add To Cart button is displayed only in case there is one variant
  const productAvailableQuantity = variants?.[0].availability?.availableQuantity;
  const enteredQtyIsMoreThanAvailable = Boolean(productAvailableQuantity && productAvailableQuantity < productQuantity);
  const hasWebcastPass = productItem.hasWebcastPass || false;

  if (!isServer) {
    const element = document.querySelector('#dd-container');
    if (element && isContribution && isSlugDonation) {
      const getDonationElement = document.querySelector('#donation_id');
      if (getDonationElement) {
        getDonationElement.appendChild(element);
        const style = document.createElement('style');
        style.setAttribute('id', 'donation-style');
        style.innerHTML = `
      .donation-hide{
        visibility:visible !important;
        }`;
        document.body.appendChild(style);
      }
    }
  }

  const getDoubleDonation = (
    <div id="dd-container">
      <a href="https://doublethedonation.com/matching-grant-resources/matching-gift-basics/">Matching Gift</a> and
      <a href="https://doublethedonation.com/matching-grant-resources/volunteer-grant-basics/">Volunteer Grant</a>{' '}
      information provided by <br />
      <a href="https://doublethedonation.com">
        <img alt="Powered by Double the Donation" src="https://doublethedonation.com/api/img/powered-by.png" />
      </a>
    </div>
  );

  return (
    <Grid columns={2}>
      <StyledThumbnailColumn computer={5} mobile={16}>
        <ProductThumbnail
          coverSrc={coverSrc}
          imageRatio="143%"
          title={name || ''}
          productType={productType as Product.ProductType}
          subscriptionProductType={subscriptionType as Product.SubscriptionProductType}
          size={ProductThumbSize.LARGE}
          subtitle={categories?.filter(category => category.isMain).map(category => category.name)[0] || ''}
          alt={variants?.[0]?.images?.[0]?.imageLabel || ''}
        />
      </StyledThumbnailColumn>
      <Grid.Column computer={11} mobile={16}>
        <Grid.Row>
          {heroLabelBuilder()}
          <StyledProductTitle>{name}</StyledProductTitle>
          <StyledProductDescription>{description}</StyledProductDescription>

          {isBundle && (
            <ProductBundlePriceRange
              productItem={productItem}
              userMemberType={userMemberType}
              isAuth={!!isAuth}
              isUserSuspendedByEthics={isUserSuspendedByEthics}
            />
          )}

          {!isBundle &&
            (!isAuth ? (
              <StyledPriceRange
                priceRange={priceRange}
                productType={productType}
                isAuth={isAuth}
                isFreeTrialSelected={isFreeTrialSelected}
                hasNonMemberPrice={hasNonMemberPrice}
                isUserSuspendedByEthics={isUserSuspendedByEthics}
                isNotForProfitSub={isNotForProfitSub}
              />
            ) : isContribution ? (
              <StyledPriceRange
                priceRange={priceRange}
                productType={productType}
                isAuth={isAuth}
                isFreeTrialSelected={isFreeTrialSelected}
              />
            ) : priceRangeForUser && !isExam ? (
              isPublication || isCourse ? (
                <PriceRangeForAllUserTypes priceRanges={priceRangeForUser} userMemberType={userMemberType} />
              ) : (
                <PriceRangeForUser priceRanges={priceRangeForUser} isAuth={isAuth} />
              )
            ) : (
              <ProductPrice
                priceFull={priceFull}
                priceFinal={priceFinal}
                discountAmount={discountAmount}
                isAuth={isAuth}
                hasNonMemberPrice={hasNonMemberPrice}
                isUserSuspendedByEthics={isUserSuspendedByEthics}
                userMemberType={userMemberType}
                allPrices={allPrices}
              />
            ))}

          <OnlyDesktop>
            {isContribution && isSlugDonation && <StyledWidget id="donation_id">{getDoubleDonation}</StyledWidget>}
            {!isBundle && !isContribution && isProductWithMultipleOptions && (
              <StyledViewOptionsButton
                testId="view-options"
                variant={ButtonEnums.variants.cloud}
                onClick={scrollToOptionsHandler}
                icon={<ArrowDown />}
                iconPosition={ButtonEnums.iconPosition.left}
              >
                View options
              </StyledViewOptionsButton>
            )}
          </OnlyDesktop>
        </Grid.Row>
        <OnlyDesktop>
          <Grid.Row>
            <StyledCTAList horizontal>
              <List.Item>
                {!isBundle &&
                  !isContribution &&
                  !isProductWithMultipleOptions &&
                  isAvailableForSale &&
                  (thirdPartyLink && !isPhysicalProduct ? (
                    isTransferableProductType ? (
                      <React.Fragment>
                        <StyledButtonLink
                          fluid
                          external
                          testId="continue-to-checkout-hero"
                          variant={ButtonEnums.variants.primary}
                          icon={<StyledExternalIcon color={theme.colors.neutralWhite} />}
                          iconPosition={ButtonEnums.iconPosition.left}
                          onClick={cartHandler}
                          to={
                            productType !== Product.ProductType.CONFERENCE
                              ? thirdPartyLink
                              : hasQuery
                              ? `${thirdPartyLink}&${redirectParams}`
                              : `${thirdPartyLink}?${redirectParams}`
                          }
                          disabled={isDisabled}
                        >
                          Continue to checkout
                        </StyledButtonLink>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <StyledButtonLink
                          fluid
                          external
                          testId="book-with-partner-hero"
                          variant={ButtonEnums.variants.primary}
                          onClick={cartHandler}
                          to={
                            productType !== Product.ProductType.CONFERENCE
                              ? thirdPartyLink
                              : hasQuery
                              ? `${thirdPartyLink}&${redirectParams}`
                              : `${thirdPartyLink}?${redirectParams}`
                          }
                          disabled={isDisabled}
                        >
                          {CART_CTA_TEXT[productButtonStatus]}
                        </StyledButtonLink>
                      </React.Fragment>
                    )
                  ) : (
                    !isContribution && (
                      <React.Fragment>
                        {isPremiumContent && isSubscribed ? (
                          <StyledButtonLink
                            fluid
                            external
                            testId="access-now-hero"
                            variant={ButtonEnums.variants.primary}
                            to={premiumContentTo}
                            disabled={isDisabled}
                          >
                            Access Now
                          </StyledButtonLink>
                        ) : isOPL && isSubscribed ? (
                          <StyledAccessOPLProduct
                            testId={`access-now-hero-${sku}`}
                            userEmail={userEmail}
                            oktaId={oktaId}
                            fvsMembership={fvsMembership}
                            oplRedirectUrl={oplRedirectUrl}
                            oplHidUrl={oplHidUrl}
                            isProductHero
                          />
                        ) : isSubscribed ? (
                          <StyledButton
                            testId="access-now-hero"
                            variant={ButtonEnums.variants.primary}
                            onClick={handleProvisionAccess}
                          >
                            Access Now
                          </StyledButton>
                        ) : (
                          <StyledButton
                            testId="add-to-cart-hero"
                            loading={cartLoading}
                            variant={ButtonEnums.variants.primary}
                            onClick={cartHandler}
                            disabled={
                              isUserFromRestrictedCountry ||
                              cartTransitionError ||
                              isDisabled ||
                              !productQuantity ||
                              enteredQtyIsMoreThanAvailable
                            }
                          >
                            {CART_CTA_TEXT[productButtonStatus]}
                          </StyledButton>
                        )}
                      </React.Fragment>
                    )
                  ))}
              </List.Item>
              {hasWebcastPass && (
                <List.Item>
                  <WebcastPassPopup position={WebcastPassPosition.DESKTOP} />
                </List.Item>
              )}
            </StyledCTAList>
          </Grid.Row>
        </OnlyDesktop>
        <OnlyMobile>{hasWebcastPass && <WebcastPassPopup position={WebcastPassPosition.MOBILE} />}</OnlyMobile>
        {!isContribution && (
          <Grid.Row>
            <ProductHeroDetailsTable
              productItem={productItem}
              isExam={isExam}
              isCourse={isCourse}
              isMultiDayWebcast={isMultiDayWebcast}
              isProductWithMultipleOptions={isProductWithMultipleOptions}
              isWebcastSeries={isWebcastSeries}
              isPhSubscriptionMagazine={isPhSubscriptionMagazine}
              productFormatLabel={
                isBundle ? Product.AVAILABLE_FORMAT_NAMES[Product.AvailableFormat.MULTIPLE] : productFormatLabel
              }
              examFormatLabel={examFormatLabel}
              dateRange={dateRange}
              location={location}
              nasbaFieldOfStudy={nasbaFieldOfStudy}
              programLevel={programLevel}
              credits={credits}
              authors={authors}
              publisher={publisher}
              sessions={sessions}
              calculatedAccessDuration={calculatedAccessDuration}
              showCC={showCC}
              sku={sku}
              creditInfoScrollTarget={creditInfoScrollTarget}
              productChildrenInfoScrollTarget={productChildrenInfoScrollTarget}
              accessRowScrollTarget={accessRowScrollTarget}
              productType={productType}
              productSku={productSku}
              isPublication={isPublication}
              publicationYear={publicationYear}
              hasOnlineConference={hasOnlineConference}
              speakers={speakers as any}
              instructors={instructors as any}
            />
          </Grid.Row>
        )}
        {isContribution && (
          <OnlyMobileCSS>
            {isContribution && isSlugDonation && <StyledWidget id="donation_id">{getDoubleDonation}</StyledWidget>}
            <Divider />
          </OnlyMobileCSS>
        )}
      </Grid.Column>
    </Grid>
  );
};

const StyledNotificationForAnonymousUser = styled(NotificationForAnonymousUser)`
  margin-top: ${props => props.theme.pxToRem(4)};
  margin-bottom: ${props => props.theme.pxToRem(25)};
  font-size: ${props => props.theme.fontSizes.xs};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(12)};
    margin-bottom: ${props => props.theme.pxToRem(18)};
  }
`;

const StyledProductTypeContainer = styled.div`
  display: flex;
`;

const StyledProductTypeIcon = styled.div`
  padding-right: ${props => props.theme.pxToRem(3)};
  line-height: 1;
`;

const StyledThumbnailColumn = styled(Grid.Column)`
  ${props => props.theme.mediaQueries.mobileOnly} {
    &&&&& {
      display: flex;
      justify-content: center;
    }
  }
`;

const StyledViewOptionsButton = styled(Button)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(26)};
    margin-bottom: ${props => props.theme.pxToRem(32)};
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    display: inline-block;
    width: ${props => props.theme.pxToRem(164)};
  }
`;

const StyledPriceInfo = styled.div<{ isAuth?: boolean }>`
  margin-bottom: ${props => (props.isAuth ? props.theme.pxToRem(26) : props.theme.pxToRem(4))};
`;

const StyledFullPrice = styled.span`
  margin-right: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  text-decoration: line-through;
`;

const StyledFinalPrice = styled.span`
  font-size: ${props => props.theme.fontSizes.m};
`;

const StyledPriceRange = styled(PriceRange)`
  margin-bottom: 7px;
`;

const StyledProductType = styled.div`
  color: ${props => props.theme.colors.neutralGrey5};
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
  text-transform: uppercase;
`;

const StyledProductTitle = styled.h1`
  margin-top: 0;
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledProductDescription = styled.p`
  margin-bottom: ${props => props.theme.pxToRem(12)};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
`;

const StyledButtonLink = styled(ButtonLink)`
  max-width: ${props => props.theme.pxToRem(225)};
`;

const StyledExternalIcon = styled(ExternalIcon)`
  path {
    fill: ${props => props.theme.colors.neutralWhite};
  }
  &&&&&& {
    margin-right: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledFreePrice = styled.span`
  color: ${props => props.theme.colors.secondaryTeal};
`;

const StyledAccessOPLProduct = styled(AccessOPLProduct)`
  display: flex;
  flex-direction: column;
`;

const StyledCTAList = styled(List)`
  &&&& {
    display: flex;
    align-items: center;
  }
`;

const StyledWidget = styled.div`
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-top: ${props => props.theme.pxToRem(30)};
  }
`;
