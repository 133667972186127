import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.exemptionEngineResults,
  (exemptionEngineResults: State.ExemptionEngineResult): State.ExemptionEngineResult => exemptionEngineResults
);

export const selectedExemptionSelector = createSelector(
  rootSelector,
  (exemptionEngineResults: State.ExemptionEngineResult): State.ExemptionEngineResult['selectedExemptReference'] =>
    exemptionEngineResults?.prevSelectedExemptReference || exemptionEngineResults.selectedExemptReference
);

export const exemptionLevelEntrySelector = createSelector(
  rootSelector,
  (exemptionEngineResults: State.ExemptionEngineResult): State.ExemptionEngineResult['exempReference'] =>
    exemptionEngineResults.exempReference
);

export const selectedExemptReferenceLevelSelector = createSelector(
  rootSelector,
  (exemptionEngineResults: State.ExemptionEngineResult): string => exemptionEngineResults.selectedExemptReferenceLevel
);

export const exemptionEngineResultsSelector = rootSelector;

export const prevSelectedExemptReferenceSelector = createSelector(
  rootSelector,
  (exemptionEngineResults: State.ExemptionEngineResult): State.ExemptionEngineResult['prevSelectedExemptReference'] =>
    exemptionEngineResults.prevSelectedExemptReference
);
