import React from 'react';
import styled from 'styled-components';
import { Button, Grid } from 'semantic-ui-react';
import { Link, LinkEnums } from 'components/atoms';
interface Props {
  main?: string | React.ReactNode;
  sub?: string | React.ReactNode;
  viewAudit?: () => void;
  isCTA?: boolean;
  redirectonLink?: {
    status?: boolean;
    path?: string;
    currentTabPath?: () => void;
  };
  ctaText?: string;
}

export const InvitationNotifAudit: React.FC<Props> = ({
  main,
  sub,
  isCTA = true,
  redirectonLink = { status: false, path: '', currentTabPath: () => null },
  ctaText = 'View invitation',
}) => {
  const viewAuditTxtAndLink = (): React.ReactNode => {
    return Boolean(redirectonLink?.status) ? (
      <StyledTextLink
        onClick={redirectonLink?.currentTabPath}
        underline={LinkEnums.underline.noUnderlineOnHover}
        target={'_blank'}
        testId="view-invitation-details"
        to={redirectonLink?.path as string}
      >
        {ctaText}
      </StyledTextLink>
    ) : (
      <> {ctaText}</>
    );
  };

  return (
    <>
      <StyledDiv>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <StyledContainer>
              <StyledFlexColumn>
                <StyledHeading>{main}</StyledHeading>
                <StyledSubHeading>{sub}</StyledSubHeading>
              </StyledFlexColumn>
            </StyledContainer>
            {isCTA && (
              <StyledButtonContainer>
                <StyledButton>{viewAuditTxtAndLink()}</StyledButton>
              </StyledButtonContainer>
            )}
          </Grid.Column>
        </Grid>
      </StyledDiv>
    </>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  margin: 0 0 ${props => props.theme.pxToRem(24)};
  border-radius: ${props => props.theme.pxToRem(8)};
  background-image: linear-gradient(to left, #72246c, #3a5dae);
  ${props => props.theme.mediaQueries.tabletMin} {
    padding: ${props => props.theme.pxToRem(32)} ${props => props.theme.pxToRem(40)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(25)} ${props => props.theme.pxToRem(25)};
  }
`;

const StyledContainer = styled.div`
  &&&& {
    div:last-child .divider {
      display: none;
    }
  }
`;

const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled.label`
  margin: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(0)};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.25;
  color: ${props => props.theme.colors.neutralWhite};
`;

const StyledSubHeading = styled.label`
  font-weight: 300;
  font-weight: ${props => props.theme.fontWeights.light};
  margin: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(0)};
  font-size: ${props => props.theme.fontSizes.s};
  ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralWhite};
  ${props => props.theme.mediaQueries.computerMin} {
    width: ${props => props.theme.pxToRem(630)};
  }
`;

const StyledButtonContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(8)};
  &&&& {
    .button:not(:first-child) {
      display: none;
    }
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    width: auto;
    height: ${props => props.theme.pxToRem(40)};
    padding: ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(20)};
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    border: 1px solid ${props => props.theme.colors.neutralWhite};
    background: ${props => props.theme.colors.neutralWhite};
    color: ${props => props.theme.colors.primaryPurple};
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: ${props => props.theme.pxToRem(30)};
  }
`;

const StyledTextLink = styled(Link)`
  &&&& {
    color: ${props => props.theme.colors.primaryPurple};
    text-decoration: none;
  }
`;
