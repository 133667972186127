import React, { Children, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { User, MembershipTypes } from 'mxp-schemas';
import { User as UserUtils } from 'mxp-utils';
import { Contentful } from 'mxp-schemas/src/types';

export interface DefaultTab {
  id: any;
  sku?: string;
  title?: string;
  minCpas: number;
  maxCpas: number;
  minIssuers: number;
  maxIssuers: number;

  cpa_range?: string;
  cpa_title?: string;
  issuer_range?: string;
  issuers_title?: string;
  price?: string;
  isEnabled?: boolean;
  benefits?: Contentful.MembershipRestriction.BenefitsSubBlock[];
}

export interface SecondaryTab {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  benefits?: Contentful.MembershipRestriction.BenefitsSubBlock[];
  isEnabled?: boolean;
}

interface Props {
  membershipType?: string;
  defaultTabs?: DefaultTab[];
  secondaryTabs?: SecondaryTab[];
  defaultTierId?: string;
  onTabChange?: (tierId: string) => void;
}

const OPEN_AND_6_MORE = 'Open and 6+';
const tierLabels = ['Sole Practitioner', 'Smaller-Firm', 'Full Membership', 'Business & Industry', 'Academia'];

export const FirmMembershipTabSelector: React.FC<Props> = ({
  membershipType = '',
  defaultTabs,
  secondaryTabs,
  defaultTierId = '',
  onTabChange = null,
}) => {
  const [activeTab, setActiveTab] = React.useState('');

  const handleTabChange = (tabValue: string) => {
    setActiveTab(tabValue);
    if (onTabChange) onTabChange(tabValue);
  };

  useEffect(() => {
    setActiveTab(defaultTierId);
  }, [defaultTierId]);

  return (
    <MainDiv>
      <StyledTabContainer>
        {defaultTabs && (
          <>
            {Children.toArray(
              defaultTabs.map((tab: DefaultTab, index) => {
                const isFullMembership = tab.minCpas === 0 && tab.maxCpas === 0;
                return (
                  <StyledTabItem
                    key={tab.sku}
                    active={tab.sku === activeTab}
                    isEnabled={Boolean(tab.isEnabled)}
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => tab.isEnabled && handleTabChange(tab.sku || '')}
                  >
                    {tab.title &&
                      UserUtils.contentSuppressMembershipMap[User.MembershipIdsEnum.MRUSR0031].acronym ===
                        membershipType.toUpperCase() && <StyledTabText bold={true}>{tab.title}</StyledTabText>}

                    {UserUtils.contentSuppressMembershipMap[User.MembershipIdsEnum.MRUSR0031].acronym ===
                      membershipType.toUpperCase() && (
                      <StyledTabText bold={true} active={tab.sku === activeTab}>
                        {tierLabels[index]}
                      </StyledTabText>
                    )}

                    {/* <-------- CHANGED THIS BLOCK OF CODE BASED FROM THE COMMENTS ON RBUILD-14648 --------> */}
                    {membershipType !== MembershipTypes.CenterMembershipType.CAQ && (
                      <>
                        <StyledTabText bold={true} active={tab.sku === activeTab}>
                          {
                            isFullMembership
                              ? OPEN_AND_6_MORE // show Open and 6+ label
                              : tab.minCpas === tab.maxCpas
                              ? `${tab.minCpas}` // show minCpa value (single digit)
                              : tab.maxCpas === 0
                              ? `${tab.minCpas}+` // show minCpa value with plus sign
                              : `${tab.minCpas} - ${tab.maxCpas}` // show minCpa and maxCpa range
                          }
                        </StyledTabText>
                        <StyledTabText bold={false} active={tab.sku === activeTab}>{`CPA${
                          tab.minCpas > 1 || tab.maxCpas > 1 || isFullMembership ? 's' : ''
                        }`}</StyledTabText>
                      </>
                    )}

                    {UserUtils.contentSuppressMembershipMap[User.MembershipIdsEnum.MRUSR0012].acronym ===
                      membershipType.toUpperCase() && (
                      <div>
                        <StyledPlusDiv>
                          <StyledPlusSign isActive={tab.sku === activeTab} name="plus circle" size="small" />
                        </StyledPlusDiv>
                        <StyledTabText bold={true} active={tab.sku === activeTab}>
                          {
                            tab.minIssuers === tab.maxIssuers
                              ? tab.minIssuers // show maxIssuers value (single digit)
                              : tab.maxIssuers === 0
                              ? `${tab.minIssuers}+` // show minIssuers value with plus sign
                              : `${tab.minIssuers} - ${tab.maxIssuers}` // show minIssuers and maxIssuers range
                          }
                        </StyledTabText>
                        <StyledTabText bold={false} active={tab.sku === activeTab}>
                          {`Issuer audit${tab.minIssuers > 1 || tab.maxIssuers > 1 ? 's' : ''}`}
                        </StyledTabText>
                      </div>
                    )}
                  </StyledTabItem>
                );
              })
            )}
          </>
        )}

        {secondaryTabs && (
          <>
            {Children.toArray(
              secondaryTabs.map((tab: SecondaryTab) => (
                <StyledTabItem
                  tabHeader={true}
                  key={tab.id}
                  active={tab.title === activeTab}
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => handleTabChange(tab.title)}
                >
                  <StyledTabHeaderText>{tab.title}</StyledTabHeaderText>
                  <TextCenterDiv>
                    <StyledTabText bold={true}>{tab.subtitle}</StyledTabText>
                    <StyledTabText bold={false}>{tab.description}</StyledTabText>
                  </TextCenterDiv>
                </StyledTabItem>
              ))
            )}
          </>
        )}
      </StyledTabContainer>
    </MainDiv>
  );
};

interface StyledTabItemProps {
  active: boolean;
  tabHeader?: boolean;
  isEnabled?: boolean;
}

interface StyledTabTextProps {
  bold: boolean;
  active?: boolean;
}

// tslint:disable: no-shadowed-variable
const MainDiv = styled.div`
  width: 100%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    height: auto;
  }
`;

const StyledTabHeaderText = styled.div`
  background: ${props => props.theme.colors.neutralBlack};
  width: 100%;
  min-height: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.pxToRem(8)};
  text-align: center;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledTabContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border-radius: ${props => props.theme.pxToRem(9)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.pxToRem(3)};
  }
`;

const StyledTabItem = styled.div<StyledTabItemProps>`
  flex: 1;
  padding-top: ${props => props.theme.pxToRem(20)};
  user-select: none;
  cursor: ${props => (props.isEnabled ? 'pointer' : 'not-allowed')};

  ${({ tabHeader }) =>
    tabHeader
      ? css`
          text-align: center;
        `
      : css`
          padding-left: ${props => props.theme.pxToRem(5)};
        `};

  ${({ active }) =>
    active
      ? css`
          background-image: linear-gradient(73deg, #6a2d87 35%, #a93d69 101%);
          min-height: ${props => props.theme.pxToRem(110)};
          border-radius: ${props => props.theme.pxToRem(9)};
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -12px;
            width: 0;
            height: 0;
            border-top: solid 10px #6a2d87;
            border-left: solid 12px transparent;
            border-right: solid 12px transparent;
          }
        `
      : css`
          background: ${props => props.theme.colors.neutralWhite};
          margin-bottom: ${props => props.theme.pxToRem(18)};
          min-height: ${props => props.theme.pxToRem(110)};
          ${props => props.theme.mediaQueries.mobileOnly} {
            border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
          }
          ${props => props.theme.mediaQueries.desktopOnly} {
            border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
          }
          ${props => props.theme.mediaQueries.tabletOnly} {
            border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
          }
        `};

  ${props => props.theme.mediaQueries.mobileOnly} {
    height: ${props => props.theme.pxToRem(100)};
    margin-bottom: ${props => props.theme.pxToRem(5)};
    padding-top: ${props => props.theme.pxToRem(10)};
  }
`;

const StyledTabText = styled.p<StyledTabTextProps>`
  font-size: ${props => props.theme.fontSizes.xs};
  text-align: center;
  margin-bottom: ${props => props.theme.pxToRem(5)};
  color: ${props => props.theme.colors.neutralWhite};

  ${({ bold }) =>
    bold
      ? css`
          font-weight: ${props => props.theme.fontWeights.bold};
        `
      : css`
          font-weight: ${props => props.theme.fontWeights.light};
        `}

  ${({ active }) =>
    active
      ? css`
          color: ${props => props.theme.colors.neutralWhite};
        `
      : css`
          color: ${props => props.theme.colors.neutralGrey7};
        `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xxs};
    margin: ${props => props.theme.pxToRem(2)};
    text-align: center;
  }
`;

const TextCenterDiv = styled.div`
  margin-top: ${props => props.theme.pxToRem(36)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: 0;
  }
`;

const StyledPlusDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${props => props.theme.pxToRem(5)} 0 ${props => props.theme.pxToRem(10)} 0;
`;

const StyledPlusSign = styled(Icon)<{ isActive: boolean }>`
  color: ${props => (props.isActive ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey7)};
`;
