import React, { useState } from 'react';
import { TextArea } from 'semantic-ui-react';
import { Modal } from 'components/atoms/Modal/Modal';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import styled from 'styled-components';
import { ReactComponent as InfoBubble } from 'resources/images/ic-unsuccessful.svg';

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirmation: (value: string) => void;
  productDescription: string;
}

export const EditProductDescriptionModal: React.FC<Props> = ({
  open,
  onCancel,
  onConfirmation,
  productDescription,
}) => {
  const maxCharInDescription = 100;
  const turnRedColorOn = 10;
  const initLeftChars = maxCharInDescription - productDescription.length;

  const [leftChars, setLeftChars] = useState(initLeftChars);
  const [desc, setDesc] = useState(productDescription);

  const isDescChangedOrEmpty = () => {
    const isStateDescEmpty = desc.length === 0 && desc === '';
    const isPropDescEmpty = productDescription.length === 0 && productDescription === '';
    const isThereChange = isStateDescEmpty && !isPropDescEmpty;
    const isPropEqualStateDesc = desc === productDescription;
    const isBothDescEmpty = isStateDescEmpty && isPropDescEmpty;
    return isThereChange || isPropEqualStateDesc || isBothDescEmpty;
  };

  const handleDescriptionChanges = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLeftChars(maxCharInDescription - value.length);
    setDesc(value);
  };

  const handleConfirmation = () => onConfirmation(desc);

  return (
    <Modal
      open={open}
      size="tiny"
      onClose={onCancel}
      heading="Edit product description"
      testId="edit-product-desc-modal"
      icon={<StyledInfoBubble />}
      cancelNode={
        <StyledButtonLink
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          testId="return-to-previous-page"
          onClick={onCancel}
          to=""
        >
          Return to previous page
        </StyledButtonLink>
      }
      confirmNode={
        <StyledButtonLink
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          testId="confirm-details"
          disabled={isDescChangedOrEmpty()}
          onClick={handleConfirmation}
          to=""
        >
          Confirm detail
        </StyledButtonLink>
      }
    >
      <StyledSubHeaderText>Please provide line item description to appear on the invoice</StyledSubHeaderText>
      <StyledTextArea onChange={handleDescriptionChanges} defaultValue={desc} maxLength={maxCharInDescription} />
      <StyledCharsLeft isRed={leftChars <= turnRedColorOn}>{leftChars} characters left</StyledCharsLeft>
    </Modal>
  );
};

const StyledSubHeaderText = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-top: ${props => props.theme.pxToRem(14)};
  line-height: 1.5;
`;

const StyledCharsLeft = styled.p<{ isRed: boolean }>`
  ${props =>
    props.isRed &&
    `
      &&&&& {
        color: red;
      }
	`}

  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledTextArea = styled(TextArea)`
  width: ${props => props.theme.pxToRem(500)};
  height: ${props => props.theme.pxToRem(130)};
  border-color: ${props => props.theme.colors.neutralGrey4};
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: ${props => props.theme.pxToRem(12)};
  border-radius: 4px;
  resize: none;

  &&&&&&:focus,
  &&&&&&:active {
    outline: none;
    border-color: ${props => props.theme.colors.interfaceBlue};
  }
`;

const StyledButtonLink = styled(Button)`
  &&&&&&&& {
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(16)}`};
    margin: 0 ${props => `${props.theme.pxToRem(5)}`};
    width: ${props => props.theme.pxToRem(197)};
    height: ${props => props.theme.pxToRem(34)};
  }
`;

const StyledInfoBubble = styled(InfoBubble)`
  fill: ${props => props.theme.colors.interfaceRed};
`;
