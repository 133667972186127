import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { Checkbox } from 'semantic-ui-react';
import { FilterRadioLabel } from 'components/molecules/FilterRadioLabel/FilterRadioLabel';
import { MyProfileLink } from 'components/molecules';

import IconFilterList from 'resources/images/filter-list.svg';
import IconClose from 'resources/images/icon-close.svg';
import { Content } from 'mxp-schemas';
import { Modal } from 'components/atoms/Modal/Modal';
import { Expandable, ButtonLink, Divider } from 'components/atoms';

import { ReactComponent as Cross } from 'resources/images/icon-live-ic-clear.svg';

interface HomeFeedFilterProps {
  categories: State.CategoriesItem[];
  topics: State.TopicOption[];
  subtopics: string[];
  industries: State.IndustryOption[];
  trends: State.TrendOption[];
  skills: State.SkillOption[];
  subskills: string[];
  activeIndustryId: string;
  activeTrendId: string;
  activeSkillId: string;
  activeTopicName: string;
  activeIndustryName: string;
  activeSkillName: string;
  activeTrendName: string;
  activeCategoryName: string;
  aggregations: State.ContentAggs | null;
  changeFilterCima: (value: TaxonomySearchFilters) => void;
}

interface TaxonomySearchFilters {
  category: string[];
  topic: string[];
  industry: string[];
  trend: string[];
  skill: string[];
}

export const HomeFeedFilter: React.FC<HomeFeedFilterProps> = props => {
  const {
    categories,
    topics,
    industries,
    trends,
    skills,
    activeTopicName,
    activeCategoryName,
    aggregations,
    changeFilterCima,
  } = props;
  const [isDropdownOpen, setToggleState] = useState(false);
  const [taxonomySearchFilters, setTaxonomySearchFilters] = useState<TaxonomySearchFilters>({
    category: [],
    topic: [],
    industry: [],
    trend: [],
    skill: [],
  });
  const [checkboxStatus, setCheckboxStatus] = useState<any>(true);

  const toggleFilterDropdown = useCallback(() => {
    setToggleState(!isDropdownOpen);
  }, [isDropdownOpen]);

  const handleTaxonomyClick = useCallback(() => {
    changeFilterCima(taxonomySearchFilters);
  }, [changeFilterCima, taxonomySearchFilters]);

  const toggleSearchTaxonomy = (variable: any, row: keyof typeof taxonomySearchFilters) => {
    if (taxonomySearchFilters[row].indexOf(variable) !== -1) {
      const taxonomyFiltersCopy = { ...taxonomySearchFilters };
      taxonomyFiltersCopy[row] = taxonomyFiltersCopy[row].filter((item: string) => item !== variable);
      setTaxonomySearchFilters({ ...taxonomyFiltersCopy });
    } else {
      const taxonomyFiltersCopy = { ...taxonomySearchFilters };
      taxonomyFiltersCopy[row] = [...taxonomyFiltersCopy[row], variable];
      setTaxonomySearchFilters({ ...taxonomyFiltersCopy });
    }
  };

  const toggleSearchCategory = (variable: any, row: keyof typeof taxonomySearchFilters) => {
    const taxonomyFiltersCopy = { ...taxonomySearchFilters };
    taxonomyFiltersCopy[row] = variable === 'all' ? [''] : [variable];
    setTaxonomySearchFilters({ ...taxonomyFiltersCopy });
  };

  useEffect(() => {
    if (checkboxStatus === false) {
      setCheckboxStatus(true);
    }
  }, [checkboxStatus]);

  const renderCheckBoxes = (tags: any[], filterName: keyof typeof taxonomySearchFilters) => {
    const taxonomyAggType: string = filterName === 'industry' ? 'industries' : `${filterName}s`;
    const checkboxArray: any[] = [];
    return tags.map((tag, index) => {
      checkboxArray[index] = taxonomySearchFilters[filterName].includes(tag.slug) ? true : undefined;
      return (
        <CheckboxStyled
          key={index + tag.name}
          label={tag.name}
          value={tag.slug}
          checked={checkboxStatus ? checkboxArray[index] : checkboxStatus}
          onChange={() => {
            // tslint:disable-next-line jsx-no-lambda
            toggleSearchTaxonomy(tag.slug, filterName);
          }}
          disabled={
            !aggregations ||
            !aggregations[taxonomyAggType as keyof typeof aggregations] ||
            !aggregations[taxonomyAggType as keyof typeof aggregations][tag.id]
          }
        />
      );
    });
  };

  const renderCategories = (categoriesToRender: any[]) => {
    return categoriesToRender.map((category, index) => {
      const isCategoryAll: boolean = Boolean(category.slug === Content.CategorySlugs.ALL);
      return (
        <StyledRadioLabelCima key={category.slug}>
          <StyledFilterRadioLabel
            key={category.slug}
            testId="filter-category"
            text={
              category.name.length > 20
                ? `${category.name.slice(0, 16)}... (${
                    aggregations?.categories[category.slug] ? aggregations?.categories[category.slug] : 0
                  })`
                : category.name === 'All'
                ? `${category.name}`
                : `${category.name} (${
                    aggregations?.categories[category.slug] ? aggregations?.categories[category.slug] : 0
                  })`
            }
            onChange={() => {
              toggleSearchCategory(category.slug, 'category');
            }} // tslint:disable-line jsx-no-lambda
            id={category.slug}
            reverseLabelAndRadio
            checked={
              taxonomySearchFilters.category[0] === category.slug ||
              (taxonomySearchFilters.category.length === 0 && category.slug === Content.CategorySlugs.ALL) ||
              (taxonomySearchFilters.category[0] === '' && category.slug === Content.CategorySlugs.ALL)
            }
            disabled={
              !isCategoryAll && (!aggregations || !aggregations.categories || !aggregations.categories[category.slug])
            }
          />
        </StyledRadioLabelCima>
      );
    });
  };

  const renderDropdown = () => {
    if (!isDropdownOpen) return null;
    return (
      <>
        <Modal
          testId="home-feed-filter-modal"
          // tslint:disable-next-line jsx-no-lambda
          onClose={() => {
            toggleFilterDropdown();
          }}
          isFullScreenOnMobile
          showCloseCross
          isCloseButtonPrimary
          confirmNode={
            <StyledPrimaryButtonLink
              testId="home-feed-filter-modal-confirm-button"
              to="#"
              // tslint:disable-next-line jsx-no-lambda
              onClick={() => {
                handleTaxonomyClick();
                toggleFilterDropdown();
              }}
            >
              Apply
            </StyledPrimaryButtonLink>
          }
          cancelNode={
            <>
              <StyledSecondaryButtonLink
                testId="home-feed-filter-modal-cancel-button"
                to="#"
                // tslint:disable-next-line jsx-no-lambda
                onClick={() => {
                  setTaxonomySearchFilters({ category: [], topic: [], industry: [], trend: [], skill: [] });
                  setCheckboxStatus(false);
                }}
              >
                <StyledBadge>
                  <StyledCross />
                </StyledBadge>
                Clear filters
              </StyledSecondaryButtonLink>
            </>
          }
          open={isDropdownOpen}
        >
          <StyledFiltersDiv>
            <h3>Filters</h3>
            <StyledCategoriesDiv>
              <h4>Categories</h4>
              <StyledRadioButtonDiv>{renderCategories(categories)}</StyledRadioButtonDiv>
            </StyledCategoriesDiv>
            <StyledExpandable
              items={[
                {
                  id: 1,
                  heading: (
                    <>
                      {topics.length > 0 ? (
                        <CheckboxStyled
                          label={`Topics (${topics.length})`}
                          checked={checkboxStatus ? Boolean(taxonomySearchFilters.topic.length) : checkboxStatus}
                        />
                      ) : (
                        <CheckboxStyled disabled label={`Topics (${topics.length})`} />
                      )}
                    </>
                  ),
                  content: (
                    <>
                      <StyledCounter>{taxonomySearchFilters.topic.length} selected</StyledCounter>
                      <StyledCheckBoxContainer>{renderCheckBoxes(topics, 'topic')}</StyledCheckBoxContainer>
                      <Divider width="auto" />
                    </>
                  ),
                  disabled: topics.length ? false : true,
                },
                {
                  id: 2,
                  heading: (
                    <>
                      {industries.length > 0 ? (
                        <CheckboxStyled
                          label={`Industries (${industries.length})`}
                          checked={checkboxStatus ? Boolean(taxonomySearchFilters.industry.length) : checkboxStatus}
                        />
                      ) : (
                        <CheckboxStyled disabled label={`Industries (${industries.length})`} />
                      )}
                    </>
                  ),
                  content: (
                    <>
                      <StyledCounter>{taxonomySearchFilters.industry.length} selected</StyledCounter>
                      <StyledCheckBoxContainer>{renderCheckBoxes(industries, 'industry')}</StyledCheckBoxContainer>
                      <Divider width="auto" />
                    </>
                  ),
                  disabled: industries.length ? false : true,
                },
                {
                  id: 3,
                  heading: (
                    <>
                      {skills.length > 0 ? (
                        <CheckboxStyled
                          label={`Skills (${skills.length})`}
                          checked={checkboxStatus ? Boolean(taxonomySearchFilters.skill.length) : checkboxStatus}
                        />
                      ) : (
                        <CheckboxStyled disabled label={`Skills (${skills.length})`} />
                      )}
                    </>
                  ),
                  content: (
                    <>
                      <StyledCounter>{taxonomySearchFilters.skill.length} selected</StyledCounter>
                      <StyledCheckBoxContainer>{renderCheckBoxes(skills, 'skill')}</StyledCheckBoxContainer>
                      <Divider width="auto" />
                    </>
                  ),
                  disabled: skills.length ? false : true,
                },
                {
                  id: 4,
                  heading: (
                    <>
                      {trends.length > 0 ? (
                        <CheckboxStyled
                          label={`Trends (${trends.length})`}
                          checked={checkboxStatus ? Boolean(taxonomySearchFilters.trend.length) : checkboxStatus}
                        />
                      ) : (
                        <CheckboxStyled disabled label={`Trends (${trends.length})`} />
                      )}
                    </>
                  ),
                  content: (
                    <>
                      <StyledCounter>{taxonomySearchFilters.trend.length} selected</StyledCounter>
                      <StyledCheckBoxContainer>{renderCheckBoxes(trends, 'trend')}</StyledCheckBoxContainer>
                      <Divider width="auto" />
                    </>
                  ),
                  disabled: trends.length ? false : true,
                },
              ]}
              expandIcon={<StyledExpandableIcons>+</StyledExpandableIcons>}
              collapseIcon={<StyledExpandableIcons>-</StyledExpandableIcons>}
            />
          </StyledFiltersDiv>
        </Modal>
      </>
    );
  };

  return (
    <StyledHomeFeedFilter>
      <StyledButtonWrapper data-testid="filter-buttons">
        <StyledButton onClick={toggleFilterDropdown} isDropdownOpen={isDropdownOpen} data-testid="filter-open-button">
          <StyledButtonFilterText>
            <StyledImg
              src={isDropdownOpen ? IconClose : IconFilterList}
              isDropdownOpen={isDropdownOpen}
              alt="Filter list"
            />
            {isDropdownOpen ? 'Hide filters' : 'Show filters'}
          </StyledButtonFilterText>
        </StyledButton>
        <StyledFilterByText data-testid="filter-by-text">
          <strong>Filtered by: </strong>
          {activeCategoryName || 'All Categories'}, {activeTopicName || 'All Topics I am following'}
        </StyledFilterByText>
        <StyledProfileLink data-testid="filter-profile-link">
          <MyProfileLink size="xs" />
        </StyledProfileLink>
      </StyledButtonWrapper>
      <Divider />
      {renderDropdown()}
    </StyledHomeFeedFilter>
  );
};

const CheckboxStyled = styled(Checkbox)`
  &&& {
    > label {
      font-size: ${props => props.theme.fontSizes.s};
      color: ${props => props.theme.colors.neutralGrey8};
      font-weight: ${props => props.theme.fontWeights.light};
    }

    &.ui.checkbox {
      display: block;
      margin-bottom: ${props => props.theme.pxToRem(10)};
    }

    &.ui.checkbox input:checked ~ label:after {
      opacity: 1;
      color: ${props => props.theme.colors.neutralWhite};
      background-color: ${props => props.theme.colors.primaryDarkPurple};
      border: ${props => props.theme.pxToRem(-1)} solid ${props => props.theme.colors.primaryDarkPurple};
      border-radius: ${props => props.theme.pxToRem(2)};
      width: ${({ width }) => `${width}px`};
      height: ${({ height }) => `${height}px`};
    }
  }
`;
const StyledExpandable = styled(Expandable)`
  z-index: -1;
`;

const StyledRadioButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledCheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  position: relative;
  margin-top: ${props => props.theme.pxToRem(-30)};
  left: 5%;
  width: 120%;
  > div {
    width: 45%;
    margin: ${props => props.theme.pxToRem(2)};
  }
`;

const StyledExpandableIcons = styled.h2`
  color: ${({ theme }) => theme.colors.primaryPurple};
`;

const StyledHomeFeedFilter = styled.div`
  width: 100%;
`;

const StyledButtonWrapper = styled.div`
  ${props => `
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    ${props.theme.mediaQueries.mobileOnly} {
      flex-wrap: wrap;
    }
  `}
`;

const StyledButton = styled.button<{ isDropdownOpen: boolean }>`
  ${props => `
    padding: 8px 12px;
    border-radius: 2px;
    border: solid 1px
      ${props.isDropdownOpen ? props.theme.colors.primaryDarkPurple : props.theme.colors.neutralGrey3};
    outline: none;
    background-color: ${props.theme.colors.neutralWhite};
    cursor: pointer;
    margin: 0 12px 0 0;
    ${props.theme.mediaQueries.mobileOnly} {
      margin: 0;
    }
  `}
`;

const StyledButtonFilterText = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralGrey8};
  white-space: nowrap;
`;

const StyledImg: any = styled.img`
  width: 0.875rem;
  height: 0.875rem;
  vertical-align: middle;
  margin-right: ${props => props.theme.pxToRem(5)};
`;

const StyledFilterByText = styled.div`
  ${props => `
    letter-spacing: 0.22px;
    color: ${props.theme.colors.neutralGrey8};
    font-size: ${props.theme.fontSizes.xxs};
    line-height: 1.5;
    margin-right: auto;
    ${props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      order: 3;
      display: block;
      margin: 15px 0 0;
    }
  `}
`;

const StyledProfileLink = styled.div`
  margin-left: 12px;
  ${props => `
    ${props.theme.mediaQueries.mobileOnly} {
      margin: 0;
    }
  `}
`;

const StyledFiltersDiv = styled.div`
  text-align: center;
  padding: ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(100)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(0)};
  }
`;

const StyledRadioLabelCima = styled.div`
  /* width: 33%; */
  width: ${props => props.theme.pxToRem(200)};
  margin-bottom: ${props => props.theme.pxToRem(-10)}

  :not(::first-child) {
    margin: ${props => props.theme.pxToRem(10)} 0;
  }
`;

const StyledCategoriesDiv = styled.div`
  text-align: left;
`;

const StyledPrimaryButtonLink = styled(ButtonLink)`
  background-color: ${props => props.theme.colors.primaryPurple};
  color: ${props => props.theme.colors.neutralWhite};
  padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(20)};
  :hover {
    background-color: ${props => props.theme.colors.primaryDarkPurple};
    color: ${props => props.theme.colors.neutralWhite};
  }
`;

const StyledSecondaryButtonLink = styled(ButtonLink)`
  color: ${props => props.theme.colors.neutralBlack};
  padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(20)};
  :hover {
    color: ${props => props.theme.colors.neutralGrey8};
  }
`;

const StyledFilterRadioLabel = styled(FilterRadioLabel)`
  width: ${props => props.theme.pxToRem(20)}
  height: ${props => props.theme.pxToRem(20)}
  &&& {
    > label {
      font-size: ${props => props.theme.fontSizes.s};
      color: ${props => props.theme.colors.neutralGrey8};
      font-weight: ${props => props.theme.fontWeights.light};
    }

    &.ui.filterRadioLabel {
      display: block;
      margin-bottom: ${props => props.theme.pxToRem(10)};
    }

    &.ui.filterRadioLabel input:checked ~ label:after {
      opacity: 1;
      color: ${props => props.theme.colors.neutralWhite};
      background-color: ${props => props.theme.colors.primaryDarkPurple};
      border: ${props => props.theme.pxToRem(-1)} solid ${props => props.theme.colors.primaryDarkPurple};
      border-radius: ${props => props.theme.pxToRem(2)};
    }
  }
`;

const StyledCounter = styled.div`
  position: relative;
  justify-content: right;
  display: grid;
  bottom: ${props => props.theme.pxToRem(43)};
  right: ${props => props.theme.pxToRem(30)};
`;

const StyledBadge = styled.div`
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  margin: 0 0 0 0;
  border-radius: 50%;
  z-index: 1;
  border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.primaryPurple};
  background-color: ${props => props.theme.colors.primaryPurple};
  position: absolute;
  bottom: ${props => props.theme.pxToRem(28)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    position: relative;
    top: ${props => props.theme.pxToRem(19)};
  }
`;

const StyledCross = styled(Cross)`
  width: ${props => props.theme.pxToRem(19)};
  position: relative;
  bottom: ${props => props.theme.pxToRem(5)};
`;
