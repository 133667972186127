import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { SupervisorActionNames } from './constants';
import { Salesforce } from 'mxp-schemas';
import request from 'utils/GraphQLClient';
import { MUTATE_CREATE_FEEDBACK, MUTATE_UPDATE_FEEDBACK, SEND_STUDENT_RECEIVE_NOTIFICATION } from 'mxp-graphql-queries';

// ------------------------------------
// Actions
// ------------------------------------

export const createFeedBack: any = createAction(
  SupervisorActionNames.CREATE_FEEDBACK,
  (newFeedbackDetails: Salesforce.NewFeedback) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    const response = await request(MUTATE_CREATE_FEEDBACK, newFeedbackDetails);
    return response.feedbackDetails;
  }
);

export const updateFeedBack: any = createAction(
  SupervisorActionNames.UPDATE_FEEDBACK,
  (feedbackDetails: Salesforce.NewFeedback) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    const response = await request(MUTATE_UPDATE_FEEDBACK, feedbackDetails);
    return response.feedbackDetails;
  }
);

export const sendStudentEmailNotification: any = createAction(
  SupervisorActionNames.SEND_STUDENT_EMAIL_NOTIFICATION,
  (status: string, caseId: string, employerValidator: string) => async () => {
    return request(SEND_STUDENT_RECEIVE_NOTIFICATION, { status, caseId, employerValidator })
      .then((response: any) => {
        if (response?.sendStudentReceiveNotification) {
          return { ...response, success: true };
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const setLoading: any = createAction(SupervisorActionNames.SET_LOADING, (loading: boolean) => () => {
  return loading;
});

export const setError: any = createAction(SupervisorActionNames.SET_ERROR, (error: CustomErrors.GraphQLError) => () => {
  return error;
});
