import React from 'react';
import styled from 'styled-components';

interface SeparatorProps {
  children?: string;
}

export const Separator: React.FC<SeparatorProps> = ({ children }) => (
  <StyledSeparator>{children && <Label>{children}</Label>}</StyledSeparator>
);

const StyledSeparator = styled.div`
  height: ${props => props.theme.pxToRem(1)};
  background: ${props => props.theme.colors.neutralGrey3};
  margin-top: ${props => (props.children ? props.theme.pxToRem(47) : props.theme.pxToRem(17))};
  margin-bottom: ${props => (props.children ? props.theme.pxToRem(33) : props.theme.pxToRem(25))};
  position: relative;
`;

const Label = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey7};
  font-weight: ${props => props.theme.fontWeights.light};
  position: absolute;
  right: 50%;
  top: -${props => props.theme.pxToRem(9)};
  padding-left: ${props => props.theme.pxToRem(9)};
  padding-right: ${props => props.theme.pxToRem(9)};
  background: ${props => props.theme.colors.neutralWhite};
`;
