import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { Paragraph, Heading } from 'components/atoms';
import { Thumb } from 'components/atoms/svg';

interface ArticleFeedbackProps {
  testId: string;
  isUpHighlighted?: boolean;
  isDownHighlighted?: boolean;
  actionUp?: (...args: any[]) => void;
  actionDown?: (...args: any[]) => void;
  isNegative?: boolean;
  heading?: string;
  subheading?: string;
}

export const ArticleFeedbackPrompt: React.FC<ArticleFeedbackProps> = React.memo(
  ({
    actionUp,
    actionDown,
    testId,
    isUpHighlighted = false,
    isDownHighlighted = false,
    heading = 'What did you think of this?',
    subheading = 'Every bit of feedback you provide will help us improve your experience',
  }) => {
    return (
      <StyledWrapper data-testid={testId}>
        <StyledHeading tabIndex={0} as="h2">
          {heading}
        </StyledHeading>
        <StyledParagraph>{subheading}</StyledParagraph>
        <div>
          <StyledThumbBox aria-label="Like">
            <Thumb action={actionUp} testId="article-feedback-up" highlight={isUpHighlighted} />
          </StyledThumbBox>
          <StyledThumbBox aria-label="Dislike">
            <Thumb down action={actionDown} testId="article-feedback-down" highlight={isDownHighlighted} />
          </StyledThumbBox>
        </div>
      </StyledWrapper>
    );
  }
);

const StyledWrapper = styled.div`
  justify-content: center;
  text-align: center;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.pxToRem(3)};
  font-size: ${props => props.theme.fontSizes.l};
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledThumbBox = styled(Button)`
  &&&& {
    display: inline-block;
    background: none;
    padding: 0;
    margin: 0px ${props => props.theme.pxToRem(9)};

    &:hover,
    &:focus,
    &:active {
      > svg {
        circle {
          fill: ${props => props.theme.colors.primaryLightPurple};
        }
        path {
          fill: ${props => props.theme.colors.neutralWhite};
        }
      }
    }

    > svg {
      width: ${props => props.theme.pxToRem(48)};
      height: ${props => props.theme.pxToRem(48)}; /* IE11 support */
    }
  }
`;
