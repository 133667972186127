import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { emptyFunc } from '../../../utils';

interface Props {
  id: string;
  name: string;
  selected: boolean;
  toggleSubtopic(id: string): void;
}

export const SubtopicListItem: React.SFC<Props> = React.memo(props => {
  const { id, name, selected, toggleSubtopic } = props;

  const handleSubtopicClick = React.useCallback((): void => {
    toggleSubtopic(id);
  }, [id, toggleSubtopic]);

  const className = selected ? 'selected' : '';

  return (
    <StyledListItem
      key={id}
      onClick={handleSubtopicClick}
      className={className}
      data-testid={`subtopic-item-${id}`}
      selected={selected}
    >
      <div data-testid={`name-subtopic-item-${id}`}>{name}</div>
      <StyledCheckContainer className={className} data-testid={`check-container-subtopic-item-${id}`}>
        <Icon className="check" name={selected ? 'check circle' : 'circle'} data-testid={`check-subtopic-item-${id}`} />
      </StyledCheckContainer>
      <StyledRadio
        type="radio"
        name={`subtopics-radio-${id}`}
        id={`subtopics-radio-${id}`}
        data-testid={`radio-subtopic-item-${id}`}
        checked={selected}
        onChange={emptyFunc}
        value={id}
      />
    </StyledListItem>
  );
});

const StyledListItem = styled.button<any>`
  ${props => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${props.theme.pxToRem(10)} ${props.theme.pxToRem(20)};
    margin-bottom: ${props.theme.pxToRem(16)};
    border-radius: ${props.theme.pxToRem(8)};
    cursor: pointer;
    box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
    border: solid 1px transparent;
    background-color: ${props.theme.colors.neutralWhite};
    font-size: ${props.theme.fontSizes.s};
    line-height: 1.5;
    width: 100%;
    outline: transparent;
    text-align: left;
    color: ${props.selected ? props.theme.colors.secondaryTeal : props.theme.colors.neutralGrey8};
    i {
      color: ${props.selected ? props.theme.colors.secondaryTeal : 'transparent'};
    }

    &:hover {
      border: solid 1px ${props.selected ? props.theme.colors.secondaryTeal : props.theme.colors.neutralGrey4};
      box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(6)} 0 transparent;
      color: ${props.selected ? props.theme.colors.secondaryTeal : props.theme.colors.neutralGrey8};

      i {
        color: ${props.selected ? props.theme.colors.secondaryTeal : props.theme.colors.neutralGrey4};
      }
    }

  ${props.theme.mediaQueries.mobileOnly} {
    &:hover,
    &:focus {
      border: solid 1px ${props.selected ? props.theme.colors.secondaryTeal : 'transparent'};
      box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(6)} 0 ${
    props.selected ? 'transparent' : 'rgba(0, 0, 0, 0.1)'
  };
      color: ${props.selected ? props.theme.colors.secondaryTeal : props.theme.colors.neutralGrey8};

      i {
        color: ${props.selected ? props.theme.colors.secondaryTeal : 'transparent'};
      }
    }
  }
 `}
`;

const StyledCheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.l};

  i.icon {
    height: auto;
    margin: 0;
  }
`;

const StyledRadio = styled.input`
  display: none;
`;
