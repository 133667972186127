import React, { FC } from 'react';
import styled from 'styled-components';

export interface IHeadingWithSubtitle {
  title: string;
  titleColor?: string;
  titleFontSize?: number;
  titleFontWeight?: number;
  subtitle?: string;
  marginBottom?: number;
  marginTop?: number;
}

export const HeadingWithSubtitle: FC<IHeadingWithSubtitle> = ({
  title,
  titleColor,
  titleFontSize,
  titleFontWeight,
  subtitle,
  marginBottom,
  marginTop,
}) => (
  <Container marginBottom={marginBottom} marginTop={marginTop}>
    <Title titleColor={titleColor} titleFontSize={titleFontSize} titleFontWeight={titleFontWeight}>
      {title}
    </Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Container>
);

const Container = styled.div<{ marginBottom?: number; marginTop?: number }>`
  color: ${props => props.theme.colors.neutralGrey8};
  width: auto;
  margin-bottom: ${props =>
    props.marginBottom ? `${props.theme.pxToRem(props.marginBottom)}` : `${props.theme.pxToRem(0)}`};
  margin-top: ${props => (props.marginTop ? `${props.theme.pxToRem(props.marginTop)}` : `${props.theme.pxToRem(0)}`)};
`;

const Title = styled.div<{ titleColor?: string; titleFontSize?: number; titleFontWeight?: number }>`
  font-size: ${props => (props.titleFontSize ? props.theme.pxToRem(props.titleFontSize) : props.theme.pxToRem(0))};
  font-weight: ${props => (props.titleFontWeight ? props.titleFontWeight : props.theme.fontWeights.regular)};
  line-height: ${props => props.theme.pxToRem(38)};
  color: ${props => (props.titleColor ? props.titleColor : 'inherit')};
  text-align: left;
`;
const Subtitle = styled.div`
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: ${props => props.theme.pxToRem(19)};
  text-align: left;
`;
