import React, { FC } from 'react';
import styled from 'styled-components';
import { Input, Button } from 'components/atoms';

export const RenewalPromoCode: FC = () => {
  return (
    <>
      <SectionTitle>Have a promo code?</SectionTitle>
      <PositionContainer>
        <PositionTitle fontSize={14}>Apply a promo code below to get a get discount</PositionTitle>
        <PromoCodeContainer>
          <PromoInput testId="renewal-promocode-input" labelName="Enter promotional code" />
          <PromoButton testId="renewal-promocode">Apply code</PromoButton>
        </PromoCodeContainer>
      </PositionContainer>
    </>
  );
};

const SectionTitle = styled.div`
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.medium};
  letter-spacing: -0.32px;
  color: ${props => props.theme.colors.primaryPurple};
`;

const PositionTitle = styled.div<{ fontSize?: number }>`
  display: flex;
  letter-spacing: -0.32px;
  color: ${props => props.theme.colors.neutralGrey10};
  margin-bottom: ${props => props.theme.pxToRem(8)};
  font-size: ${props => (props.fontSize ? props.theme.pxToRem(props.fontSize) : 'inherit')};
  text-align: left;
`;

const PositionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PromoCodeContainer = styled.div`
  display: flex;
`;

const PromoInput = styled(Input)`
  max-height: ${props => props.theme.pxToRem(40)};
  margin-right: ${props => props.theme.pxToRem(10)};
  max-width: 46%;
`;

const PromoButton = styled(Button)`
  max-height: ${props => props.theme.pxToRem(40)} !important;
  background-color: ${props => props.theme.colors.primaryPurple} !important;
  color: ${props => props.theme.colors.neutralWhite} !important;
`;
