import React from 'react';
import { Route, matchPath } from 'react-router-dom';
import { Redirect, RouteProps, match as MatchProps } from 'react-router';
import { Routes } from 'constants/index';
import { getPath } from 'utils';

interface Props extends RouteProps {
  isAuth: boolean | null;
}

interface PrevRouteState {
  prevRoute: string;
  hash: string;
}

export const NonAuthRoute: React.FC<Props> = ({ component: Component, isAuth, ...routeProps }) => {
  // 1. Render requested route when user is unauthenticated
  if (!isAuth) {
    return <Route {...routeProps} component={Component} />;
  }

  const { location } = routeProps;
  const redirectUri: string = (location?.state as PrevRouteState | undefined)?.prevRoute || getPath(Routes.FEED);
  const match: MatchProps<{ slug: string }> | null = matchPath(redirectUri, {
    path: getPath(Routes.HOME),
    exact: true,
  });
  // if home route slug exist we should keep deep link as it is
  const isRedirectToHome: boolean = Boolean(match && !match.params.slug);

  // 2. Redirect to feed if the user is logged in
  return (
    <Redirect
      to={{
        pathname: isRedirectToHome ? getPath(Routes.FEED) : redirectUri,
        hash: (location?.state as PrevRouteState)?.hash,
      }}
    />
  );
};
