import React from 'react';
import styled from 'styled-components';
import { Container, Grid } from 'semantic-ui-react';
import { OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms/ResponsiveHelpers/ResponsiveHelpers';
import { Heading } from 'components/atoms/Heading/Heading';
import { Content } from 'mxp-schemas';

export const BlogBanner: React.FC = () => {
  const lines = [
    'The official blog for AICPA & CIMA featuring posts from staff on a variety of topics affecting the accounting profession',
  ];

  return (
    <Banner>
      <Container>
        <OnlyDesktopCSS>
          <Container fluid>
            <StyledGrid columns={16}>
              <Grid.Column computer={16} mobile={16}>
                <Title as="h1">{Content.BlogContentSource}</Title>
                <Text>{lines[0]}</Text>
                <Text>{lines[1]}</Text>
              </Grid.Column>
            </StyledGrid>
          </Container>
        </OnlyDesktopCSS>

        <OnlyMobileCSS>
          <StyledMobileWrapper>
            <Title as="h1">{Content.BlogContentSource}</Title>
            <Text>{lines.join(' ').toString()}</Text>
          </StyledMobileWrapper>
        </OnlyMobileCSS>
      </Container>
    </Banner>
  );
};

const Banner = styled(Container)`
  &&& {
    width: 100vw;
    padding: ${props => props.theme.pxToRem(20)} 0;
    background: linear-gradient(77deg, #672d89, #9c2463);
    color: ${props => props.theme.colors.neutralWhite};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

const StyledGrid = styled(Grid)`
  &&& {
    margin: 0;
  }
`;

const Title = styled(Heading)`
  margin-bottom: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeights.light};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xl};
  }
`;

const Text = styled.p`
  margin: 0;
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.thin};
  line-height: ${props => props.theme.pxToRem(26)};
`;

const StyledMobileWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;
