import React from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Input, NotificationBanner, NotificationBannerEnums, Button, ButtonEnums } from 'components/atoms';
import { useSelector } from 'react-redux';
import { DropdownWithLabel, InputWithValidation } from '../../molecules';
import { Form, StrictDropdownProps, Dropdown, DropdownProps, DropdownItemProps, Icon } from 'semantic-ui-react';
import { Routes, EmploymentFormErrorMessage } from 'constants/index';
import { MembershipTypes, User } from 'mxp-schemas';
import { Admin as AdminUtils, CheckoutCountriesListHash } from 'mxp-utils';
import { isCenterMembershipSelector, userMembershipTypeSelector } from 'modules/membership/selectors';
import { getPath } from 'utils';
import { Link, LinkEnums } from 'components/atoms/Link/Link';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { EmploymentPhoneInput } from 'components/atoms/Input/EmploymentPhoneInput';
import { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';

interface DropdownOption {
  key: number;
  text: string;
  value: string;
  sfOrgId?: string;
}

const optionsOrganizationType = Object.values(MembershipTypes.OrganizationType).map((value: any, index) => {
  return { key: index, value, text: value };
});

const optionsCountryList = Object.values(CheckoutCountriesListHash).map((country: any, index) => {
  return { key: index, value: country.ISOAlpha3Code, text: country.text, image: country.image };
});

interface Props {
  employerAlreadyExists: boolean;
  selectedMembershipType: string;
  searchOrganizationsByWildcardLoading: boolean;
  searchOrganizationsCitiesLoading: boolean;
  isAddressDetailsEditable: boolean;
  handleEditChange: () => void;
  employmentForm: State.NewAccountPersonAccount;
  handleCreateNewClick: () => Promise<void>;
  handleItemClick: (event: React.SyntheticEvent, data: DropdownItemProps) => void;
  handlePhoneNumberOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSetShippingAddressChange: (event: React.SyntheticEvent<Element, Event>, { checked }: any) => void;
  handleAddressCountryOnChange: (event: React.SyntheticEvent, data: StrictDropdownProps) => void;
  handleAddressPostalCodeOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddressStateOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddressCityOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddressLine2OnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddressLine1OnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOrganizationTypeOnChange: (event: React.SyntheticEvent, data: StrictDropdownProps) => void;
  handleNewBusinessChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchBusinessCity: () => void;
  handleSearchCityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchAgainBusinessCity: () => void;
  handleSearchAgainBusinessName: () => void;
  handleBusinessCityChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  handleBusinessChange: (event: React.ChangeEvent<HTMLInputElement>, data: DropdownProps) => void;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>, { searchQuery }: { searchQuery: string }) => void;
  searchFn: (event: React.ChangeEvent<HTMLInputElement>, searchQuery: string) => DropdownOption[];
  handleSearchSFBusinessNameWithZipCode: () => void;
  handleSearchZIBusinessNameWithZipCode: () => void;
  phoneNumber: string;
  businessPostalCode?: string;
  businessState?: string;
  businessCity?: string;
  addressLine2ErrorMessage: string;
  isAddressLine2IsValid: boolean;
  isBusinessNameValid: boolean;
  businessAddressLine2?: string;
  businessAddressLine1?: string;
  address?: State.Address;
  businessType?: string;
  businessName?: string;
  role?: string;
  refDropdownBusinessName: any;
  refNewBusiness: any;
  businessCityElementId: string;
  businessNameElementId: string;
  isShippingAddress: boolean;
  existingEmployerFromSearch: boolean;
  hasPromoCode: boolean;
  stateBusinessList: { value: null; searchQuery: string; filteredList: DropdownOption[] };
  businessCities: DropdownOption[];
  isBusinessNew: boolean;
  isCenterMembershipJourney?: boolean;
  isForModal: boolean;
  isPaidByFirm?: boolean;
  hasExistingEmployers?: boolean;
  canEditEmploymentInfo?: boolean;
  handleEditPrimaryEmployerClick: any;
  onChangeRole: (event: React.SyntheticEvent, data: StrictDropdownProps) => void;
  onStatusChange: (event: React.SyntheticEvent, data: StrictDropdownProps) => void;
  tlwData: State.TLWInfoList;
  onTLWChange: (event: React.SyntheticEvent, data: StrictDropdownProps) => void;
  renderTLWReasonSelection: boolean;
  renderRoleSelection: boolean;
  renderEmploymentDetails: boolean;
  isSearchingExternal?: boolean;

  employers: Omit<State.AccountPersonAccount, 'personAccount'>[];
  employerSelectionUseCase: User.EmployerSelectionUseCaseEnum;
  handleUpdateEmployerSelectionUseCase: (useCase: User.EmployerSelectionUseCaseEnum) => void;
  handleSelectItemConnectedEmployer: (employer: Omit<State.AccountPersonAccount, 'personAccount'> | undefined) => void;
  handleSelectItemSearchedEmployer: (name: string, sfOrgId?: string) => void;
  handleSearchNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchZipCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchTempStorage: {
    businessName: string;
    businessCity: string;
    businessZipCode: string;
  };
  handleBackToSearchConnectedOrganization: () => void;
  handleBackToSearchOrganizationSFZoomInfo: () => void;
  handleCenterPhoneNumberOnChange?: (dialCode: string, workPhoneNumber: string) => void;
  addressFormErrors: {
    line1?: boolean;
    city?: boolean;
    state?: boolean;
    zipCode?: boolean;
  };
  onCloseHandler: (value: any) => void;
  isClose: {
    businessName?: boolean;
    role?: boolean;
    workPhoneNumber?: boolean;
  };
}

export const EmploymentForm: React.FC<Props> = ({
  employerAlreadyExists,
  selectedMembershipType,
  searchOrganizationsByWildcardLoading,
  searchOrganizationsCitiesLoading,
  isAddressDetailsEditable,
  handleEditChange,
  employmentForm,
  handleCreateNewClick,
  handleItemClick,
  handlePhoneNumberOnChange,
  handleSetShippingAddressChange,
  handleAddressCountryOnChange,
  handleAddressPostalCodeOnChange,
  handleAddressStateOnChange,
  handleAddressCityOnChange,
  handleAddressLine2OnChange,
  handleAddressLine1OnChange,
  handleOrganizationTypeOnChange,
  handleNewBusinessChange,
  handleSearchBusinessCity,
  handleSearchCityChange,
  handleSearchAgainBusinessCity,
  handleSearchAgainBusinessName,
  handleBusinessCityChange,
  handleBusinessChange,
  handleSearchChange,
  searchFn,
  phoneNumber,
  handleSearchSFBusinessNameWithZipCode,
  handleSearchZIBusinessNameWithZipCode,
  canEditEmploymentInfo,
  businessPostalCode,
  businessState,
  businessCity,
  addressLine2ErrorMessage,
  isAddressLine2IsValid,
  isBusinessNameValid,
  businessAddressLine2,
  businessAddressLine1,
  address,
  businessType,
  businessName,
  role,
  refDropdownBusinessName,
  refNewBusiness,
  businessCityElementId,
  businessNameElementId,
  isShippingAddress,
  existingEmployerFromSearch,
  hasPromoCode,
  stateBusinessList,
  businessCities,
  isBusinessNew,
  isCenterMembershipJourney,
  isForModal,
  isPaidByFirm,
  hasExistingEmployers,
  handleEditPrimaryEmployerClick,
  onChangeRole,
  onStatusChange,
  tlwData,
  onTLWChange,
  renderTLWReasonSelection,
  renderRoleSelection,
  renderEmploymentDetails,
  isSearchingExternal,

  employers,
  employerSelectionUseCase,
  handleUpdateEmployerSelectionUseCase,
  handleSelectItemConnectedEmployer,
  handleSelectItemSearchedEmployer,
  handleSearchNameChange,
  handleSearchZipCodeChange,
  searchTempStorage,
  handleBackToSearchConnectedOrganization,
  handleBackToSearchOrganizationSFZoomInfo,
  handleCenterPhoneNumberOnChange,
  addressFormErrors,
  onCloseHandler,
  isClose,
}) => {
  const userMembershipTypeChoice = useSelector(userMembershipTypeSelector) as string;
  const isCenterMemberJourney = useSelector(isCenterMembershipSelector);
  const employmentStatusOptions = MembershipTypes.EmploymentStatus.slice(0, 2);
  const { pageNameNoHash } = getPageNameAndSiteSection();

  const showCMBanner = isCenterMembershipJourney
    ? Boolean(
        AdminUtils.allInvalidOrgs(businessType) ||
          (AdminUtils.isPCPSType(userMembershipTypeChoice) && AdminUtils.pcpsInvalidOrgs(businessType)) ||
          (AdminUtils.similarGroupValidOrgs(userMembershipTypeChoice) &&
            (AdminUtils.pcpsInvalidOrgs(businessType) ||
              businessType === MembershipTypes.OrganizationType.CONSULTING_FIRM))
      )
    : false;

  return (
    <StyledForm isForModal={isForModal}>
      {isForModal ? (
        <>
          <StyledLabel>
            Employment Status<span style={{ color: 'red' }}>*</span>
          </StyledLabel>
          <StyledDropdown
            selection
            value={employmentForm.status}
            options={employmentStatusOptions}
            onChange={onStatusChange}
            placeholder="Select an option"
          />

          {renderTLWReasonSelection && (
            <>
              <br />
              <StyledLabel>Please quickly explain why you are currently TLW</StyledLabel>
              <StyledDropdown
                selection
                value={tlwData.tlwReason}
                options={MembershipTypes.TLWReason}
                onChange={onTLWChange}
                placeholder="Select"
              />
            </>
          )}

          {/* USER SEARCHES ORGANIZATION FROM CONNECTED EMPLOYERS */}
          {employerSelectionUseCase === User.EmployerSelectionUseCaseEnum.SEARCH_TO_CONNECTED_EMPLOYERS &&
            renderRoleSelection && (
              <>
                {employers?.length > 0 && (
                  <>
                    <br />
                    <StyledLabel>
                      Employer<span style={{ color: 'red' }}>*</span>
                    </StyledLabel>
                    <StyledDropdown
                      searchInput={{ id: businessNameElementId }}
                      placeholder="Select Employer"
                      value={
                        employmentForm.organization.id &&
                        `${employmentForm.organization.name} - ${employmentForm.jobTitle}`
                      }
                      text={
                        employmentForm.organization.id &&
                        `${employmentForm.organization.name} - ${employmentForm.jobTitle}`
                      }
                      className="selection"
                    >
                      <Dropdown.Menu key="business-menu">
                        {employers.map(employer => {
                          return (
                            <Dropdown.Item
                              key={employer.id}
                              text={`${employer.organization.name} - ${employer.jobTitle}`}
                              value={employer.id}
                              // tslint:disable-next-line jsx-no-lambda
                              onClick={() => handleSelectItemConnectedEmployer(employer)}
                            />
                          );
                        })}
                      </Dropdown.Menu>
                    </StyledDropdown>
                  </>
                )}
                <StyledResetLink
                  // tslint:disable-next-line jsx-no-lambda
                  onClick={() =>
                    handleUpdateEmployerSelectionUseCase(
                      User.EmployerSelectionUseCaseEnum.SEARCH_TO_SALESFORCE_ZOOMINFO
                    )
                  }
                >
                  Search or add existing employer
                </StyledResetLink>
              </>
            )}

          {/* USER SEARCHES ORGANIZATION FROM SALESFORCE OR ZOOM INFO */}
          {employerSelectionUseCase === User.EmployerSelectionUseCaseEnum.SEARCH_TO_SALESFORCE_ZOOMINFO &&
            renderRoleSelection && (
              <>
                <br />
                {!canEditEmploymentInfo && (
                  <StyledResetLink onClick={handleBackToSearchConnectedOrganization}>Go Back</StyledResetLink>
                )}
                <StyledLabel>Search Organization:</StyledLabel>
                <StyledSearchOrganizationContainer>
                  <StyledSearchOrganizationFormContainer>
                    <StyledLabel>
                      Business Name<span style={{ color: 'red' }}>*</span>
                    </StyledLabel>
                    <StyledInputWithValidation
                      className="business-name"
                      name="Business-input"
                      placeholder="Business Name"
                      testId="search-business-name"
                      isCorrect={isBusinessNameValid}
                      type="text"
                      value={searchTempStorage.businessName}
                      validationMessageDisabled={isClose.businessName}
                      errorMessage={
                        <StyledMessage>
                          {EmploymentFormErrorMessage.BUSINESS_NAME_ERROR_MESSAGE}
                          <StyledCloseButton
                            testId="onclose-error-message"
                            className="error-message"
                            onClick={() => onCloseHandler('businessName')} // tslint:disable-line jsx-no-lambda
                          >
                            <span>&times;</span>
                          </StyledCloseButton>
                        </StyledMessage>
                      }
                      onChange={handleSearchNameChange}
                    />
                  </StyledSearchOrganizationFormContainer>
                  <StyledSearchOrganizationFormContainer>
                    <StyledLabel>Business Zip Code</StyledLabel>
                    <StyledInput
                      className="business-zipcode"
                      placeholder="Business Zip Code"
                      testId="search-business-zipcode"
                      value={searchTempStorage.businessZipCode}
                      onChange={handleSearchZipCodeChange}
                    />
                  </StyledSearchOrganizationFormContainer>
                  <StyledSearchButton
                    testId="search-business-button"
                    variant={ButtonEnums.variants.secondary}
                    iconPosition={ButtonEnums.iconPosition.left}
                    iconName="search"
                    others={{ pageName: `${pageNameNoHash}:employment` }}
                    onClick={handleSearchSFBusinessNameWithZipCode}
                  >
                    Search
                  </StyledSearchButton>
                </StyledSearchOrganizationContainer>

                {/* HIDE'S THE RESULT IF A SEARCHED ITEM IS CLICKED */}
                {!canEditEmploymentInfo && (
                  <>
                    <StyledAdvancedSearch>
                      if you don't see your organization, please use&nbsp;
                      <StyledAdvancedSearchLink onClick={handleSearchZIBusinessNameWithZipCode}>
                        Advanced Search
                      </StyledAdvancedSearchLink>
                    </StyledAdvancedSearch>
                    <br />
                    {/* SEARCH RESULTS FROM SF AND ZOOM INFO */}
                    Search results:
                    <ul>
                      {stateBusinessList.filteredList.map(option => {
                        return (
                          <li key={option.key}>
                            <StyledLink
                              // tslint:disable-next-line jsx-no-lambda
                              onClick={() => handleSelectItemSearchedEmployer(option.text, option.sfOrgId)}
                            >
                              {option.text}
                            </StyledLink>
                          </li>
                        );
                      })}
                      {/* SHOW THE ADD NEW LINK AFTER SEARCH IN ZOOM INFO */}
                      {isSearchingExternal && (
                        <li>
                          <StyledLink onClick={handleCreateNewClick}>Add new</StyledLink>
                        </li>
                      )}
                    </ul>
                  </>
                )}
              </>
            )}

          {/* EMPLOYER DETAILS SHOW'S IF THE USER CLICKED AN ITEM FROM SEARCH OR CLICKED CREATE MANUALLY */}
          {/* DISABLE'S THE ORGANIZATION RELATED FIELDS IF THE CLICKED SEARCH IS COMING FROM SALESFORCE */}
          {/* ENABLE'S THE ORGANIZATION RELATED FIELDS IF THE CLICKED SEARCH IS COMING FROM ZOOM INFO */}
          {canEditEmploymentInfo && !renderTLWReasonSelection && (
            <>
              <br />
              <StyledResetLink onClick={handleBackToSearchOrganizationSFZoomInfo}>Go Back</StyledResetLink>
              {(employmentForm.organization.name || isBusinessNew) && (
                <>
                  <StyledVerticalLine>
                    <StyledLabel>
                      Your role <span style={{ color: 'red' }}>*</span>
                    </StyledLabel>
                    <StyledDropdown
                      selection
                      name="role-selection"
                      value={employmentForm.jobTitle}
                      options={MembershipTypes.Roles}
                      onChange={onChangeRole}
                      placeholder="Select from list"
                    />
                    {!employmentForm.jobTitle && (
                      <ValidationMessage hasError={true} absoluteErrorMessage={false} hidden={isClose.role}>
                        {EmploymentFormErrorMessage.ROLE_ERROR_MESSAGE}
                        <StyledCloseButton
                          testId="onclose-error-message"
                          className="error-message"
                          onClick={() => onCloseHandler('role')} // tslint:disable-line jsx-no-lambda
                        >
                          <span>&times;</span>
                        </StyledCloseButton>
                      </ValidationMessage>
                    )}
                    <br />

                    <StyledLabel>
                      Name <span style={{ color: 'red' }}>*</span>
                    </StyledLabel>
                    <StyledInput
                      inputRef={refNewBusiness}
                      placeholder="New Business Names"
                      testId="new-business-name"
                      value={employmentForm.organization.name}
                      onChange={handleNewBusinessChange}
                      disabled={!isAddressDetailsEditable && !isBusinessNew}
                    />
                    <br />

                    <StyledLabel>
                      Organization Type <span style={{ color: 'red' }}>*</span>
                    </StyledLabel>
                    <StyledDropdown
                      selection
                      value={employmentForm?.organization?.type}
                      options={optionsOrganizationType?.filter(
                        orgType => orgType?.value !== MembershipTypes.OrganizationType.PUBLIC_ACCOUNTING
                      )}
                      onChange={handleOrganizationTypeOnChange}
                      disabled={!isBusinessNew}
                    />
                  </StyledVerticalLine>
                  <StyledLabelBusinessCity>Business City</StyledLabelBusinessCity>
                  <StyledDropdown
                    searchInput={{ autoFocus: !isBusinessNew, id: businessCityElementId }}
                    placeholder="Search Business City"
                    options={businessCities}
                    search
                    onChange={handleBusinessCityChange}
                    selectOnNavigation
                    selectOnBlur={false}
                    selection
                    onSearchChange={handleSearchCityChange}
                    loading={!!searchOrganizationsCitiesLoading}
                    icon={{ className: 'search', onClick: () => handleSearchBusinessCity() }}
                    disabled={Boolean(businessCity)}
                    value={businessCity}
                    text={!isBusinessNew ? businessCity : ''}
                  />
                  {((employmentForm?.organization?.name && employmentForm?.organization?.id) ||
                    isBusinessNew ||
                    hasPromoCode) && (
                    <>
                      {!isBusinessNew && !hasPromoCode && (
                        <StyledResetLink onClick={handleSearchAgainBusinessCity}>Search again</StyledResetLink>
                      )}
                      <StyledVerticalLine>
                        <StyledLabelContainerWithEdit>
                          <StyledLabel>
                            Business Address Line 1 <span style={{ color: 'red' }}>*</span>
                          </StyledLabel>
                          {!isBusinessNew && !hasPromoCode && <StyledEdit onClick={handleEditChange}>Edit</StyledEdit>}
                        </StyledLabelContainerWithEdit>
                        <StyledInput
                          placeholder="Address Line 1"
                          testId="address-line-1"
                          value={businessAddressLine1}
                          onChange={handleAddressLine1OnChange}
                          disabled={!isAddressDetailsEditable && !isBusinessNew}
                          hasError={addressFormErrors.line1}
                        />
                        <StyledLabel>Business Address Line 2 (optional)</StyledLabel>
                        <StyledInputWithValidation
                          type="text"
                          name="address-line-2"
                          placeholder="Address Line 2"
                          testId="address-line-2"
                          value={businessAddressLine2 || ''}
                          onChange={handleAddressLine2OnChange}
                          disabled={!isAddressDetailsEditable && !isBusinessNew}
                          isCorrect={isAddressLine2IsValid}
                          errorMessage={addressLine2ErrorMessage}
                        />
                        <StyledContainerCity>
                          <StyledLabel>
                            City <span style={{ color: 'red' }}>*</span>
                          </StyledLabel>
                          <StyledInput
                            placeholder="City"
                            testId="address-city"
                            value={businessCity}
                            onChange={handleAddressCityOnChange}
                            disabled={!isAddressDetailsEditable && !isBusinessNew}
                            hasError={addressFormErrors.city}
                          />
                        </StyledContainerCity>
                        <StyledContainerZIPCountry>
                          <StyledContainerState>
                            <StyledLabel>State</StyledLabel>
                            <StyledInput
                              placeholder="State"
                              testId="state"
                              value={businessState}
                              onChange={handleAddressStateOnChange}
                              disabled={!isAddressDetailsEditable && !isBusinessNew}
                              hasError={addressFormErrors.state}
                            />
                          </StyledContainerState>
                          <div>
                            <StyledLabel>ZIP/Postal Code</StyledLabel>
                            <StyledInput
                              placeholder="ZIP/Postal Code"
                              testId="code"
                              value={businessPostalCode}
                              onChange={handleAddressPostalCodeOnChange}
                              disabled={!isAddressDetailsEditable && !isBusinessNew}
                              hasError={addressFormErrors.zipCode}
                            />
                          </div>
                        </StyledContainerZIPCountry>
                        <StyledLabel>Country</StyledLabel>
                        <StyledCountryContainer>
                          <StyledCountryDropdown
                            scrolling
                            selection
                            selected={address?.country}
                            testId="country"
                            options={optionsCountryList}
                            onChange={handleAddressCountryOnChange}
                            disabled={!isAddressDetailsEditable && !isBusinessNew}
                          />
                        </StyledCountryContainer>
                        <StyledCheckBox
                          height="18"
                          width="18"
                          id="checkbox"
                          testId="setShippingAddress"
                          label={businessName ? 'Make this my primary contact address' : 'Set as shipping address'}
                          checked={isShippingAddress}
                          onClick={handleSetShippingAddressChange}
                          disabled={!isAddressDetailsEditable && !isBusinessNew}
                        />
                        <StyledContainerPhoneNumber>
                          <StyledLabel>
                            Work Phone Number <span style={{ color: 'red' }}>*</span>
                          </StyledLabel>
                          {isCenterMembershipJourney && handleCenterPhoneNumberOnChange ? (
                            <EmploymentPhoneInput
                              handleCenterPhoneNumberOnChange={handleCenterPhoneNumberOnChange}
                              placeholder="Work phone no."
                              disabled={isSearchingExternal ? false : !isAddressDetailsEditable && !isBusinessNew}
                            />
                          ) : (
                            <>
                              <StyledInputWithValidation
                                placeholder="Work phone no."
                                testId="workphonenumber"
                                name="workphonenumber"
                                isCorrect={phoneNumber.length}
                                type="text"
                                value={phoneNumber}
                                onChange={handlePhoneNumberOnChange}
                                disabled={isSearchingExternal ? false : !isAddressDetailsEditable && !isBusinessNew}
                                validationMessageDisabled={isClose.workPhoneNumber}
                                errorMessage={
                                  <ValidationMessage hasError={true} absoluteErrorMessage={false}>
                                    {EmploymentFormErrorMessage.PHONE_ERROR_MESSAGE}
                                    <StyledCloseButton
                                      testId="onclose-error-message"
                                      className="error-message"
                                      onClick={() => onCloseHandler('workPhoneNumber')} // tslint:disable-line jsx-no-lambda
                                    >
                                      <span>&times;</span>
                                    </StyledCloseButton>
                                  </ValidationMessage>
                                }
                              />
                            </>
                          )}
                        </StyledContainerPhoneNumber>
                        <StyledAnyEdits>
                          *Any edits made to the company address will only be saved to your profile. To make changes to
                          the master company address, contact our customer service team here.
                        </StyledAnyEdits>
                      </StyledVerticalLine>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        (!hasExistingEmployers || isCenterMembershipJourney) && (
          <>
            {!isCenterMembershipJourney && (
              <>
                {role && selectedMembershipType === User.MembershipTypes.REGULAR && (
                  <StyledRoleContainer>
                    <StyledLabel>Your role</StyledLabel>
                    <StyledInput testId="role" value={role} disabled />
                    <StyledChangeRoleSpan>
                      If you{' '}
                      <Link underline={LinkEnums.underline.noUnderlineOnHover} to={getPath(Routes.MEMBERSHIP_FORM)}>
                        change your role
                      </Link>
                      , you will have to make your tiers and products selection again.
                    </StyledChangeRoleSpan>
                  </StyledRoleContainer>
                )}
              </>
            )}
            {!hasPromoCode && (
              <>
                {isCenterMembershipJourney && (
                  <>
                    <StyledCMHeading>Search or create a new employer record</StyledCMHeading>
                    <StyledDescription>
                      When you start entering a business name, suggestions appear. If you see the correct business,
                      click “confirm.”
                    </StyledDescription>
                    <StyledDescription>
                      If the firm/organization does not appear, click the “Create new” button.
                    </StyledDescription>
                    <StyledDescription>
                      If you create a new record, you will be able to search for the firm/organization type.
                    </StyledDescription>
                    <br />
                  </>
                )}
                {(!isBusinessNew && isCenterMembershipJourney) || !hasExistingEmployers ? (
                  <>
                    {!(
                      (businessName || isBusinessNew) &&
                      !searchOrganizationsByWildcardLoading &&
                      !employerAlreadyExists
                    ) && (
                      <>
                        <StyledLabelBusinessName>Business name</StyledLabelBusinessName>
                        <StyledDropdown
                          // onBlur={clearSearch}
                          ref={refDropdownBusinessName}
                          searchInput={{ id: businessNameElementId }}
                          placeholder="Search Business Name"
                          search={searchFn}
                          onChange={handleBusinessChange}
                          selectOnNavigation
                          selectOnBlur={false}
                          onSearchChange={handleSearchChange}
                          loading={!!searchOrganizationsByWildcardLoading}
                          icon={<Icon name="search" onClick={handleSearchSFBusinessNameWithZipCode} />}
                          disabled={
                            (businessName || isBusinessNew) &&
                            !searchOrganizationsByWildcardLoading &&
                            !employerAlreadyExists
                          }
                          value={businessName}
                          text={!isBusinessNew ? businessName : ''}
                          className="selection"
                        >
                          <Dropdown.Menu key="business-menu">
                            {stateBusinessList.filteredList.map(option => {
                              return (
                                <Dropdown.Item
                                  key={option.key}
                                  text={option.text}
                                  value={option.value}
                                  onClick={handleItemClick}
                                />
                              );
                            })}
                            <StyledDropdownMenuItem text={'+ Create New'} onClick={handleCreateNewClick} />
                          </Dropdown.Menu>
                        </StyledDropdown>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {(businessName || isBusinessNew) &&
              !searchOrganizationsByWildcardLoading &&
              (!employerAlreadyExists || existingEmployerFromSearch) && (
                <>
                  <StyledLabel>{isCenterMembershipJourney ? 'Business name' : 'Name'}</StyledLabel>
                  <StyledInput
                    inputRef={refNewBusiness}
                    placeholder="New Business Name"
                    testId="new-business-name"
                    value={businessName}
                    onChange={handleNewBusinessChange}
                    disabled={!isAddressDetailsEditable && !isBusinessNew}
                  />

                  {!isPaidByFirm && (
                    <StyledResetLink onClick={handleSearchAgainBusinessName} hidden={hasPromoCode}>
                      Search again
                    </StyledResetLink>
                  )}
                  <StyledVerticalLine>
                    {!isCenterMembershipJourney && (
                      <>
                        <StyledLabel>Your role</StyledLabel>
                        <StyledDropdown
                          selection
                          name="role-selection"
                          value={employmentForm.jobTitle}
                          options={MembershipTypes.Roles}
                          onChange={onChangeRole}
                          placeholder="Select from list"
                        />
                        {!employmentForm.jobTitle && (
                          <ValidationMessage hasError={true} absoluteErrorMessage={false}>
                            Please select a role from the dropdown menu in order to continue
                          </ValidationMessage>
                        )}
                        <br />
                      </>
                    )}

                    <StyledLabel>Organization type</StyledLabel>
                    <StyledDropdown
                      selection
                      value={businessType}
                      placeholder="Select firm/organization type"
                      options={optionsOrganizationType?.filter(orgType =>
                        isCenterMemberJourney
                          ? orgType
                          : orgType?.value !== MembershipTypes.OrganizationType.PUBLIC_ACCOUNTING
                      )}
                      onChange={handleOrganizationTypeOnChange}
                      disabled={!isBusinessNew}
                    />
                    <>
                      {(() => {
                        if (isCenterMembershipJourney) {
                          if (showCMBanner) {
                            return (
                              <StyledNotificationBanner
                                testId="organization-type-error"
                                childrenTestId="membership-application-employment-error-children"
                                icon={<StyledIconError />}
                                variant={NotificationBannerEnums.variant.blue}
                              >
                                This firm/organization type is not eligible for the center membership chosen. Please
                                contact the center team with any questions.
                              </StyledNotificationBanner>
                            );
                          }
                        }
                      })()}
                    </>
                    {businessType === MembershipTypes.OrganizationType.PUBLIC_ACCOUNTING && (
                      <StyledNotificationBanner
                        testId="organization-type-error"
                        childrenTestId="membership-application-employment-error-children"
                        icon={<StyledIconError />}
                        variant={NotificationBannerEnums.variant.blue}
                      >
                        {/* Creating a new public accounting firm requires additional information about your firm's
                        practice. */}
                        Firms subject to Peer Review must complete further attestation.
                      </StyledNotificationBanner>
                    )}
                  </StyledVerticalLine>
                  {!hasPromoCode && !showCMBanner && !isCenterMembershipJourney && (
                    <>
                      <StyledLabelBusinessCity>Company's location</StyledLabelBusinessCity>
                      <StyledDropdown
                        searchInput={{ autoFocus: !isBusinessNew, id: businessCityElementId }}
                        placeholder="Search Business City"
                        options={businessCities}
                        search
                        onChange={handleBusinessCityChange}
                        selectOnNavigation
                        selectOnBlur={false}
                        selection
                        onSearchChange={handleSearchCityChange}
                        loading={!!searchOrganizationsCitiesLoading}
                        icon={{ className: 'search', onClick: () => handleSearchBusinessCity() }}
                        disabled={Boolean(businessCity) || isBusinessNew}
                        value={businessCity}
                        text={!isBusinessNew ? businessCity : ''}
                      />
                    </>
                  )}
                  {(isPaidByFirm ||
                    (businessName && employmentForm?.organization?.id) ||
                    isBusinessNew ||
                    isSearchingExternal ||
                    hasPromoCode) &&
                    !showCMBanner && (
                      <>
                        {!isBusinessNew && !hasPromoCode && !isCenterMembershipJourney && (
                          <StyledResetLink onClick={handleSearchAgainBusinessCity}>Search again</StyledResetLink>
                        )}
                        <StyledVerticalLine>
                          {isCenterMembershipJourney && !isBusinessNew ? (
                            <>
                              <StyledCenterOrgTitle>Organization Address</StyledCenterOrgTitle>
                              <StyledCenterDiv>
                                <StyledLabel>Business Address Line 1</StyledLabel>
                                <StyledInput
                                  placeholder="Address Line 1"
                                  testId="address-line-1"
                                  value={employmentForm.organization.billingAddress.addressLine1 || ''}
                                  disabled={true}
                                />
                              </StyledCenterDiv>

                              <StyledCenterDiv>
                                <StyledLabel>Business Address Line 2 (optional)</StyledLabel>
                                <StyledInput
                                  placeholder="Address Line 2"
                                  testId="address-line-2"
                                  value={employmentForm.organization.billingAddress.addressLine2 || ''}
                                  disabled={true}
                                />
                              </StyledCenterDiv>

                              <StyledContainerCity>
                                <StyledLabel>City</StyledLabel>
                                <StyledInput
                                  placeholder="City"
                                  testId="address-city"
                                  value={employmentForm.organization.billingAddress.city || ''}
                                  disabled={true}
                                />
                              </StyledContainerCity>
                              <StyledContainerZIPCountry>
                                <StyledContainerState>
                                  <StyledLabel>State</StyledLabel>
                                  <StyledInput
                                    placeholder="State"
                                    testId="state"
                                    value={employmentForm.organization.billingAddress.state || ''}
                                    disabled={true}
                                  />
                                </StyledContainerState>
                                <div>
                                  <StyledLabel>ZIP/Postal Code</StyledLabel>
                                  <StyledInput
                                    placeholder="ZIP/Postal Code"
                                    testId="code"
                                    value={employmentForm.organization.billingAddress.zipCode}
                                    disabled={true}
                                  />
                                </div>
                              </StyledContainerZIPCountry>

                              <StyledContainerPhoneNumber>
                                <StyledLabel>Phone Number</StyledLabel>
                                <StyledInput
                                  placeholder="Work phone no."
                                  testId="workphonenumber"
                                  value={employmentForm.organization.phone}
                                  onChange={handlePhoneNumberOnChange}
                                  disabled={!isAddressDetailsEditable && !isBusinessNew}
                                />
                              </StyledContainerPhoneNumber>
                            </>
                          ) : (
                            <>
                              <StyledLabelContainerWithEdit>
                                <StyledLabel>Business address line 1</StyledLabel>
                                {!isBusinessNew && !hasPromoCode && (
                                  <StyledEdit onClick={handleEditChange}>Edit</StyledEdit>
                                )}
                              </StyledLabelContainerWithEdit>
                              <StyledInput2
                                placeholder="Address line 1"
                                testId="address-line-1"
                                value={businessAddressLine1}
                                onChange={handleAddressLine1OnChange}
                                disabled={!isAddressDetailsEditable && !isBusinessNew}
                              />
                              <StyledLabel>Business address line 2 (if applicable)</StyledLabel>
                              <StyledInputWithValidation
                                type="text"
                                name="address-line-2"
                                placeholder="Address line 2"
                                testId="address-line-2"
                                value={businessAddressLine2 || ''}
                                onChange={handleAddressLine2OnChange}
                                disabled={isSearchingExternal ? false : !isAddressDetailsEditable && !isBusinessNew}
                                isCorrect={isAddressLine2IsValid}
                                errorMessage={addressLine2ErrorMessage}
                              />
                              <StyledContainerCity>
                                <StyledLabel>City</StyledLabel>
                                <StyledInput
                                  placeholder="City"
                                  testId="address-city"
                                  value={businessCity}
                                  onChange={handleAddressCityOnChange}
                                  disabled={isSearchingExternal ? false : !isAddressDetailsEditable && !isBusinessNew}
                                />
                              </StyledContainerCity>
                              <StyledContainerZIPCountry>
                                <StyledContainerState>
                                  <StyledLabel>State</StyledLabel>
                                  <StyledInput
                                    placeholder="State"
                                    testId="state"
                                    value={businessState}
                                    onChange={handleAddressStateOnChange}
                                    disabled={isSearchingExternal ? false : !isAddressDetailsEditable && !isBusinessNew}
                                  />
                                </StyledContainerState>
                                <div>
                                  <StyledLabel>ZIP/Postal Code</StyledLabel>
                                  <StyledInput
                                    placeholder="ZIP/Postal Code"
                                    testId="code"
                                    value={businessPostalCode}
                                    onChange={handleAddressPostalCodeOnChange}
                                    disabled={isSearchingExternal ? false : !isAddressDetailsEditable && !isBusinessNew}
                                  />
                                </div>
                              </StyledContainerZIPCountry>
                              <StyledLabel>Country</StyledLabel>
                              <StyledCountryContainer>
                                <StyledCountryDropdown
                                  scrolling
                                  selection
                                  selected={address?.country}
                                  testId="country"
                                  options={optionsCountryList}
                                  onChange={handleAddressCountryOnChange}
                                  disabled={isSearchingExternal ? false : !isAddressDetailsEditable && !isBusinessNew}
                                />
                              </StyledCountryContainer>
                              <StyledCheckBox
                                height="18"
                                width="18"
                                id="checkbox"
                                testId="setShippingAddress"
                                label="Set as shipping address"
                                checked={isShippingAddress}
                                onClick={handleSetShippingAddressChange}
                                disabled={isSearchingExternal ? false : !isAddressDetailsEditable && !isBusinessNew}
                              />
                              <StyledContainerPhoneNumber>
                                <StyledLabel>Work Phone Number</StyledLabel>

                                {isCenterMembershipJourney && handleCenterPhoneNumberOnChange ? (
                                  <EmploymentPhoneInput
                                    handleCenterPhoneNumberOnChange={handleCenterPhoneNumberOnChange}
                                    placeholder="Work phone no."
                                    disabled={isSearchingExternal ? false : !isAddressDetailsEditable && !isBusinessNew}
                                  />
                                ) : (
                                  <StyledInput
                                    placeholder="Work phone no."
                                    testId="workphonenumber"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberOnChange}
                                    disabled={isSearchingExternal ? false : !isAddressDetailsEditable && !isBusinessNew}
                                  />
                                )}
                              </StyledContainerPhoneNumber>
                              <StyledAnyEdits>
                                *Any edits made to the company address will only be saved to your profile. To make
                                changes to the master company address, contact us by selecting the chat icon to the
                                right of your screen or calling 888-777-7077. The Global Engagement Center is open
                                9:00am–8:00pm ET Monday through Thursday, and 9:00am–6:00pm ET on Friday.
                              </StyledAnyEdits>
                            </>
                          )}
                        </StyledVerticalLine>
                      </>
                    )}
                </>
              )}
          </>
        )
      )}
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  ${props =>
    props.isForModal
      ? css`
          &&&& {
            position: relative;
            top: ${props.theme.pxToRem(-30)};
            width: ${props.theme.pxToRem(520)};
            ${props.theme.mediaQueries.mobileOnly} {
              width: ${props.theme.pxToRem(296)};
            }
          }
        `
      : css`
          &&&& {
            max-width: ${props.theme.pxToRem(455)};
            ${props.theme.mediaQueries.mobileOnly} {
              width: ${props.theme.pxToRem(296)};
            }
          }
        `}

  .ui.selection.active.dropdown,
  .ui.selection.active.dropdown .menu {
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.primaryPurple}!important;
  }

  .ui.selection.dropdown .menu {
    width: calc(100% - 1.5px) !important;
    min-width: calc(100% + 1px) !important;
  }

  .ui.dropdown .menu > .item:hover {
    color: ${props => props.theme.colors.primaryPurple}!important;
  }
`;

const StyledLabelBusinessName = styled.label`
  margin-bottom: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralGrey8};
  display: block;
`;

const StyledCMHeading = styled.h3`
  margin-bottom: ${props => props.theme.pxToRem(20)};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledDropdownMenuItem = styled(Dropdown.Item)`
  &&&&& {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.primaryPurple};

    &:hover {
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyledVerticalLine = styled.div`
  margin-top: ${props => props.theme.pxToRem(24)};
  border-left: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey5};
  display: inline-block;
  width: 100%;
  padding-left: ${props => props.theme.pxToRem(23)};
`;

const StyledLabelBusinessCity = styled.label`
  margin-top: ${props => props.theme.pxToRem(32)};
  margin-bottom: ${props => props.theme.pxToRem(8)};

  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};

  display: block;
`;

const StyledEdit = styled.div`
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.primaryPurple};
  margin-left: auto;
`;

const StyledAnyEdits = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledCheckBox = styled(Checkbox)`
  ${props => `
    &&&.ui.checkbox label {
      font-size: ${props.theme.fontSizes.xs};
      font-weight: ${props.theme.fontWeights.light};
    }
  `};
  width: ${props => props.theme.pxToRem(400)};
  height: ${props => props.theme.pxToRem(22)};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  display: inline-block;
  margin-bottom: ${props => props.theme.pxToRem(19)};
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  &&&&&.disabled {
    opacity: 1;
    background-color: ${props => props.theme.colors.neutralGrey2};
    input {
      color: ${props => props.theme.colors.neutralGrey8};
    }
  }

  &&&.ui.dropdown .menu > .item b {
    display: none;
  }

  &&&.ui.dropdown:not(.button) > .default.text {
    color: ${props =>
      props.name === 'role-selection' ? props.theme.colors.primaryPurple : props.theme.colors.neutralGrey8};
  }

  &&&&& {
    height: ${props => props.theme.pxToRem(44)};
    display: flex;
    color: ${props => props.theme.colors.primaryPurple};
    div {
      align-self: center;
    }
    i {
      padding-top: ${props => props.theme.pxToRem(12)};
    }
  }
`;

const StyledCountryDropdown = styled(DropdownWithLabel)`
  .divider.text {
    cursor: pointer !important;

    .ui.image {
      margin-top: ${props => props.theme.pxToRem(-4)} !important;
    }
  }
`;

const StyledCountryContainer = styled.div`
  max-width: ${props => props.theme.pxToRem(336)};
  margin-bottom: ${props => props.theme.pxToRem(10)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(296)};
  }
`;

const StyledLabel = styled.label`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(8)};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledInput = styled(({ hasError, ...rest }) => <Input {...rest} />)`
  &&& {
    display: flex;
    margin-bottom: ${props => props.theme.pxToRem(7)};
  }

  &&&.disabled {
    cursor: not-allowed;
    opacity: 1;
    input {
      border-color: ${props => props.theme.colors.neutralGrey4};
      background-color: ${props => props.theme.colors.neutralGrey2};
    }
  }

  ${({ hasError, theme }) =>
    hasError &&
    `
      &&&& > input {
        :focus {
          border: solid ${theme.pxToRem(1)} ${theme.colors.interfaceRed};
        }
         border: solid ${theme.pxToRem(1)} ${theme.colors.interfaceRed};
      }
    `}
`;

const StyledInput2 = styled(Input)`
  &&&&& {
    margin-bottom: ${props => props.theme.pxToRem(19)};
  }
`;

const StyledChangeRoleSpan = styled.span`
  font-size: ${props => props.theme.fontSizes.xxs};
`;

const StyledRoleContainer = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(30)};
`;

const StyledLabelContainerWithEdit = styled.div`
  display: flex;
`;

const StyledContainerCity = styled.div`
  max-width: 100%;
  margin-bottom: ${props => props.theme.pxToRem(19)};
`;

const StyledContainerZIPCountry = styled.div`
  display: flex;
  column-gap: ${props => props.theme.pxToRem(20)};
  width: 100%;
`;

const StyledContainerState = styled.div`
  margin-right: ${props => props.theme.pxToRem(16)};
`;

const StyledContainerPhoneNumber = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.pxToRem(19)};
`;

const StyledInputWithValidation = styled(InputWithValidation)`
  &&& {
    display: flex;
    margin-bottom: ${props => props.theme.pxToRem(7)};
  }

  &&&.disabled {
    cursor: not-allowed;
    opacity: 1;
    input {
      border-color: ${props => props.theme.colors.neutralGrey4};
      background-color: ${props => props.theme.colors.neutralGrey2};
    }
  }

  &&&.business-name {
    width: 100%;
  }
`;

const StyledResetLink = styled.div`
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  margin-top: ${props => props.theme.pxToRem(30)};
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.primaryPurple};
`;

const StyledAdvancedSearch = styled.div`
  margin-top: ${props => props.theme.pxToRem(30)};
  font-size: ${props => props.theme.pxToRem(14)};
`;

const StyledAdvancedSearchLink = styled.label`
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.primaryPurple};
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-top: ${props => props.theme.pxToRem(15)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;

const StyledSearchOrganizationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: ${props => props.theme.pxToRem(10)};
  column-gap: ${props => props.theme.pxToRem(10)};
`;

const StyledSearchOrganizationFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

const StyledSearchButton = styled(Button)`
  flex-basis: ${props => props.theme.pxToRem(130)} !important;
  margin-top: ${props => props.theme.pxToRem(34)} !important;
  display: flex;

  ${props => props.theme.mediaQueries.tabletOnly} {
    margin-top: ${props => props.theme.pxToRem(57)} !important;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(57)} !important;
  }
`;

const StyledLink = styled.label`
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.primaryPurple};
`;

const StyledCenterDiv = styled.div`
  margin: ${props => props.theme.pxToRem(20)} 0;
`;

const StyledCenterOrgTitle = styled.p`
  margin: ${props => props.theme.pxToRem(40)} 0 ${props => props.theme.pxToRem(15)} 0;
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.33;
`;

const StyledDescription = styled.p`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.s};
`;
interface ValidationMessageProps {
  hasError?: boolean;
  absoluteErrorMessage?: boolean;
}

const ValidationMessage = styled.div<ThemedStyledProps<ValidationMessageProps, DefaultTheme>>`
  margin-bottom: ${props => props.theme.pxToRem(4)};
  color: ${props => props.theme.colors.neutralGrey5};
  font-size: ${props => props.theme.fontSizes.xxs};
  background-color: #e7e7e7;
  margin-top: ${props => props.theme.pxToRem(5)};
  ${({ hasError, theme, absoluteErrorMessage }) =>
    hasError &&
    `
      color: ${theme.colors.interfaceRed};
      position: ${absoluteErrorMessage ? 'absolute' : 'static'}
    `}
`;

const StyledMessage = styled.div`
  width: 100%;
  background-color: #e7e7e7;
  position: absolute;
`;

const StyledCloseButton = styled(Button)`
  width: 0 !important;
  height: 0 !important;

  &&&& {
    background: none;
    border: none;
    float: right;
    padding: 0;
    margin-right: 1rem;

    :hover {
      background: none;
    }
  }
`;
