import React, { useState } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';

import { default as request } from 'utils/GraphQLClient';
import { ASSIGN_ADMIN } from 'mxp-graphql-queries';

import styled from 'styled-components';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { Container, Heading, Divider, NotificationBanner, NotificationBannerEnums } from 'components/atoms';
import { ButtonEnums, Button } from 'components/atoms/Button/Button';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import { BackLink } from 'components/molecules/BackLink/BackLink';
import { Table } from 'components/atoms/Table/Table';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { useHistory } from 'react-router';
import { User } from 'mxp-schemas';
import { useSelector } from 'react-redux';
import { isFirmBillingSelector, isCenterMembershipSelector } from 'modules/admin/selectors';

export const AdminClientAdminResultsPage: React.SFC<RouteComponentProps> = ({ match, location }) => {
  const [showError, setShowError] = useState(false);
  const [isLoading, setLoadingStatus] = useState(false);

  const isFirmBilling = useSelector(isFirmBillingSelector);
  const isCenterMembership = useSelector(isCenterMembershipSelector);

  const accountNumberColumnName = isFirmBilling || isCenterMembership ? 'Account Number' : 'AICPA UID';
  const adminRole = isFirmBilling ? 'Firm Billing' : isCenterMembership ? 'Centers Membership' : '';
  const showAdminRole = isFirmBilling || isCenterMembership;

  const { push: historyPush } = useHistory();
  const { accountId, accountNumber, legalEntity } = match.params as any;
  const { results } = (location?.state as { results: User.UserDetails[] }) || { results: [] };

  const onAssign = (user: User.UserDetails) => async () => {
    setLoadingStatus(true);
    if (isAdmin(user)) {
      return;
    }

    const {
      profile: { email, AICPAUID: aicpaId = '' },
    } = user;

    try {
      await request(ASSIGN_ADMIN, { email, aicpaId, accountId });
    } catch (e) {
      setLoadingStatus(false);
      setShowError(true);
      return;
    }

    historyPush({
      pathname: generatePath(getPath(Routes.ADMIN_ORGANIZATION), { accountId, accountNumber, legalEntity }),
      hash: 'client-admins',
      state: { assignAdminSuccess: true },
    });
  };

  const isAdmin = (user: User.UserDetails) => {
    const {
      profile: { clientAdminOf, firmBillingClientAdminOf, centersClientAdminOf },
    } = user;
    return Array.isArray(clientAdminOf) && !isFirmBilling && !isCenterMembership
      ? clientAdminOf.includes(accountId)
      : Array.isArray(firmBillingClientAdminOf) && isFirmBilling
      ? firmBillingClientAdminOf.includes(accountId)
      : Array.isArray(centersClientAdminOf) && isCenterMembership
      ? centersClientAdminOf.includes(accountId)
      : false;
  };

  return (
    <Container>
      <BackLink
        testId="add-client-admin"
        to={generatePath(getPath(Routes.ADMIN_ADD_CLIENT_ADMIN), { accountId, accountNumber, legalEntity })}
      >
        Back to add client admin
      </BackLink>
      <StyledHeading as="h1">{results.length} User Found</StyledHeading>
      <StyledDivider />
      {showError && (
        <StyledNotificationBanner
          variant={NotificationBannerEnums.variant.red}
          testId="notification-banner"
          childrenTestId="notification-children"
          icon={<StyledIconError />}
        >
          Uh oh. Something went wrong and your changes have not been saved. Please try again.
        </StyledNotificationBanner>
      )}
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Name</Table.HeaderCell>
            <Table.HeaderCell width={4}>{accountNumberColumnName}</Table.HeaderCell>
            <Table.HeaderCell width={6}>Email Address</Table.HeaderCell>
            {showAdminRole && <Table.HeaderCell width={4}>Admin Role</Table.HeaderCell>}
            <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {results.map((result: User.UserDetails) => {
            const isAssigned = isAdmin(result);
            return (
              <Table.Row key="key">
                <Table.Cell>{`${result.profile.firstName} ${result.profile.lastName}`}</Table.Cell>
                <Table.Cell>{result.profile.AICPAUID}</Table.Cell>
                <Table.Cell>{result.profile.email}</Table.Cell>
                {showAdminRole && <Table.Cell>{adminRole}</Table.Cell>}
                <Table.Cell>
                  {results.length === 1 && (
                    <StyledButton
                      isAssigned={isAssigned}
                      bordered
                      iconName={isAssigned ? 'check' : 'plus'}
                      iconPosition={ButtonEnums.iconPosition.left}
                      testId={`assign${result.profile.email}`}
                      variant={ButtonEnums.variants.primaryToggle}
                      onClick={onAssign(result)}
                      loading={isLoading}
                      disabled={isAssigned}
                    >
                      {isAssigned ? 'Assigned' : 'Assign'}
                    </StyledButton>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <StyledButtonLink
        size={ButtonEnums.sizes.medium}
        variant={ButtonEnums.variants.primary}
        testId="return-to-admin-page"
        to={generatePath(getPath(Routes.ADMIN_ADD_CLIENT_ADMIN), { accountId, accountNumber, legalEntity })}
      >
        Search again
      </StyledButtonLink>
    </Container>
  );
};

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(25)};
`;

const StyledButton = styled(Button)<{ isAssigned: boolean }>`
  &&&&&& {
    background-color: ${props => (props.isAssigned ? props.theme.colors.primaryPurple : '')};
    font-size: ${props => (props.isAssigned ? props.theme.pxToRem(14) : '')};
    font-weight: ${props => (props.isAssigned ? props.theme.fontWeights.light : '')};
    color: ${props => (props.isAssigned ? props.theme.colors.neutralWhite : '')};
  }
`;

const StyledHeading = styled(Heading)`
  font-weight: ${props => props.theme.fontWeights.light};
  margin-top: 0;
`;

const StyledDivider = styled(Divider)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(31)};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(39)};
    width: ${props => props.theme.pxToRem(142)};
  }
`;

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;
