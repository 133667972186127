import React from 'react';
import styled from 'styled-components/macro';
import { OnlyMobileCSS, OnlyDesktopCSS } from 'components/atoms/ResponsiveHelpers/ResponsiveHelpers';

interface Props {
  icon: React.ReactNode;
  label: string;
  mobileLabel?: string;
  labelFirst?: boolean;
  className?: string;
  onClick?: () => void;
}

export const LabeledIcon: React.FC<Props> = React.memo(
  ({ label, onClick, className, icon, mobileLabel, labelFirst }) => {
    const role: string | undefined = onClick ? 'button' : undefined;
    return (
      <Wrapper className={className} role={role} onClick={onClick} data-testid={`labeled-icon-${label}`}>
        {!labelFirst && (
          <>
            <OnlyDesktopCSS>
              <VerticallyCentered>
                <span>{icon}</span>
                <span>{label}</span>
              </VerticallyCentered>
            </OnlyDesktopCSS>
            <OnlyMobileCSS>
              <VerticallyCentered>
                <span>{icon}</span>
                {mobileLabel && <span>{mobileLabel}</span>}
              </VerticallyCentered>
            </OnlyMobileCSS>
          </>
        )}
        {labelFirst && (
          <>
            <OnlyDesktopCSS>
              <VerticallyCentered>
                <span>{label}</span>
                <span>{icon}</span>
              </VerticallyCentered>
            </OnlyDesktopCSS>
            <OnlyMobileCSS>
              <VerticallyCentered>
                {mobileLabel && <span>{mobileLabel}</span>}
                <span>{icon}</span>
              </VerticallyCentered>
            </OnlyMobileCSS>
          </>
        )}
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  display: flex;
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};

  & span > img {
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

const VerticallyCentered = styled.div`
  display: flex;
  align-items: center;
`;
