import { getLocation } from 'connected-react-router';
import { getMembershipJourney, getPageNameAccessor, isMembershipRenewalHandler } from 'utils/Analytics/helpers';
import { EventDescribor, UTAG_TYPES } from 'utils/Analytics/types';
import { productTypesSlugsStrSelector } from 'modules/productTypes/selectors';
import { topicsSlugsStrSelector } from 'modules/topics/selectors';
import { getReferrerSelector } from 'modules/search/selectors';
import { membershipRelatedAddonsSelector, membershipSectionSelector } from 'modules/membership/selectors';
import { productCurrencySelector } from 'modules/products/selectors';
import { ProductCurrencyLabel } from 'mxp-schemas/dist/types/products';
import { User } from 'mxp-schemas';
import { Product } from 'mxp-utils';
import { membershipActionNames } from './constants';

const priceFloat = (amount: number) => {
  const format = Number(amount / 100).toFixed(2);
  return +format || 0;
};

export const variantsPriceInfoForUser = (
  data: State.ProductCredential,
  currency: State.ProductCurrencyLabel = ProductCurrencyLabel.USD
) => {
  if (data && data.variants && data.variants[0]) {
    const priceInfoForUser = Product.getProductPrice(
      data,
      data?.variants[0]?.sku ?? '',
      [User.MembershipIdsEnum.MRUSR0001],
      currency
    );
    const { priceFinal: { amount = 0 } = {} } = priceInfoForUser;
    return amount ? priceFloat(amount) : 0;
  }
  return 0;
};

const genericAccessors = [
  {
    name: 'membership_journey',
    path: '',
    processor: getMembershipJourney,
  },
  {
    name: 'is_membership_renewal',
    path: '',
    processor: isMembershipRenewalHandler,
  },
  {
    name: 'topics',
    path: '',
    processor: (a: any, b: State.Root) => {
      const topics = topicsSlugsStrSelector(b);
      const { pathname: currentPathname } = getLocation(b);
      const aggType = currentPathname.split('/')?.[2] || '';
      return topics.includes(aggType) ? aggType : currentPathname.split('/')?.[1];
    },
  },
  {
    name: 'type',
    path: '',
    processor: (a: any, b: State.Root) => {
      const productTypes = productTypesSlugsStrSelector(b);
      const { pathname: currentPathname } = getLocation(b);
      const aggType = currentPathname.split('/')?.[2] || '';
      return productTypes.includes(aggType) ? aggType : currentPathname.split('/')?.[1];
    },
  },
  {
    name: 'search_type',
    path: '',
    processor: (a: any, b: State.Root) => {
      const referrer = getReferrerSelector(b);
      return referrer ? 'intended' : 'navigated';
    },
  },
  {
    name: 'search_referrer',
    path: '',
    processor: (a: any, b: State.Root) => {
      return getReferrerSelector(b);
    },
  },
];

const getProductIdsRaw = (a: any) => {
  const items = a?.map((item: any) => item?.id || '');
  return items || [];
};

const getProductIds = (a: any) => {
  const items = a?.map((item: any) => item?.productId || item?.id || '');
  return items || [];
};

const getProductPrices = (a: any, b: State.Root) => {
  const currency = productCurrencySelector(b)?.label || 'USD';
  const items = a?.map((item: State.MembershipProductBlock) => {
    const membershipPrice = item.prices?.find(price => price.currency === currency);
    const { prices: [{ amount = 0 } = {}] = [] } = item;

    return membershipPrice?.amount || amount || 0;
  });
  return items || [];
};

const getProductPricesUsingTransformed = (a: any) => {
  const items = a?.map((item: any) => {
    return priceFloat(item?.transformedPrice[0]?.price?.amount);
  });
  return items || [];
};

const getProductPricesUsingVariant = (a: any, b: State.Root) => {
  const currency = productCurrencySelector(b)?.label || 'USD';
  const items = a?.map((item: any) => {
    if (item.isFree) return 0;

    const membershipPrice = variantsPriceInfoForUser(item, currency) || 0;
    const { variants: [{ prices: [{ amount = 0 }] = [] } = {}] = [] } = item;

    return membershipPrice || priceFloat(amount) || 0;
  });
  return items || [];
};

const getProductNames = (a: any) => {
  const items = a?.map((item: any) => item.name || '');
  return items || [];
};

const getProductCategories = (a: any) => {
  const items = a?.map((item: any) => item.productType || '');
  return items.flat() || [];
};

const combineFreeAndNonFreeProductsSection = (a: any, b: State.Root) => {
  const withPriceProducts = membershipSectionSelector(b)?.listOfProductWithPrice;
  const freeProducts = membershipSectionSelector(b)?.listOfFreeProduct.map(item => {
    return { ...item, isFree: true };
  });

  const combined = [...freeProducts, ...withPriceProducts];
  return combined;
};

const customStorefrontAccessors = (hash?: string) => [
  ...getPageNameAccessor(hash),
  ...genericAccessors,
  {
    name: 'sec_product_id',
    path: '',
    processor: (a: State.MembershipTierBlock[]) => getProductIds(a),
  },
  {
    name: 'sec_product_name',
    path: '',
    processor: (a: State.MembershipTierBlock[]) => getProductNames(a),
  },
  {
    name: 'sec_product_category',
    path: '',
    processor: (a: State.MembershipTierBlock[]) => getProductCategories(a),
  },
  {
    name: 'sec_product_price',
    path: '',
    processor: (a: State.MembershipTierBlock[]) => getProductPricesUsingTransformed(a),
  },
];

export const MembershipAnalyticsConsts: {
  [key: string]: EventDescribor;
} = {
  [membershipActionNames.STOREFRONT_AGGREGATION_VIEW_SKILL]: {
    name: 'storefront_aggregation_view',
    accessors: customStorefrontAccessors(),
    type: UTAG_TYPES.VIEW,
  },
  [membershipActionNames.STOREFRONT_AGGREGATION_VIEW_TIER]: {
    name: 'storefront_aggregation_view',
    accessors: customStorefrontAccessors('tier'),
    type: UTAG_TYPES.VIEW,
  },
  [membershipActionNames.STOREFRONT_AGGREGATION_VIEW_CREDENTIAL]: {
    name: 'storefront_aggregation_view',
    accessors: [
      ...getPageNameAccessor('credentials'),
      ...genericAccessors,
      {
        name: 'sec_product_category',
        path: '',
        processor: (a: State.MembershipTierBlock[]) => getProductCategories(a),
      },
      {
        name: 'sec_product_name',
        path: '',
        processor: (a: State.MembershipTierBlock[]) => getProductNames(a),
      },
      {
        name: 'sec_product_id',
        path: '',
        processor: (a: State.MembershipTierBlock[]) => getProductIds(a),
      },
      {
        name: 'sec_product_price',
        path: '',
        processor: (a: State.MembershipTierBlock[], b: State.Root) => getProductPricesUsingVariant(a, b),
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  [membershipActionNames.STOREFRONT_AGGREGATION_VIEW_CIMA_TYPE]: {
    name: 'storefront_aggregation_view',
    accessors: [
      ...getPageNameAccessor(),
      ...genericAccessors,
      {
        name: 'sec_product_name',
        path: '',
        processor: (a: any, b: State.Root) => getProductNames(a?.filteredCmsProducts),
      },
      {
        name: 'sec_product_id',
        path: '',
        processor: (a: any, b: State.Root) => getProductIdsRaw(a?.filteredCmsProducts),
      },
      {
        name: 'sec_product_category',
        path: '',
        processor: (a: any, b: State.Root) => getProductCategories(a?.filteredCmsProducts),
      },
      {
        name: 'sec_product_price',
        path: '',
        processor: (a: any, b: State.Root) => getProductPrices(a?.filteredCmsProducts, b),
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  [membershipActionNames.STOREFRONT_AGGREGATION_VIEW_TYPE]: {
    name: 'storefront_aggregation_view',
    accessors: [
      ...getPageNameAccessor('type'),
      ...genericAccessors,
      {
        name: 'sec_product_id',
        path: '',
        processor: (a, b: State.Root) => getProductIdsRaw(a),
      },
      {
        name: 'sec_product_name',
        path: '',
        processor: (a, b: State.Root) => getProductNames(a),
      },
      {
        name: 'sec_product_price',
        path: '',
        processor: (a, b: State.Root) => getProductPrices(a, b),
      },

      {
        name: 'sec_product_category',
        path: '',
        processor: (a, b: State.Root) => getProductCategories(a),
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  [membershipActionNames.GET_MEMBERSHIP_SECTIONS]: {
    name: 'storefront_aggregation_view',
    accessors: [
      ...getPageNameAccessor('sections'),
      ...genericAccessors,
      {
        name: 'sec_product_name',
        path: '',
        processor: (a, b: State.Root) => getProductNames(combineFreeAndNonFreeProductsSection(a, b)),
      },
      {
        name: 'sec_product_id',
        path: '',
        processor: (a, b: State.Root) => getProductIds(combineFreeAndNonFreeProductsSection(a, b)),
      },
      {
        name: 'sec_product_category',
        path: '',
        processor: (a: any, b: State.Root) => getProductCategories(combineFreeAndNonFreeProductsSection(a, b)),
      },
      {
        name: 'sec_product_price',
        path: '',
        processor: (a, b) => getProductPricesUsingVariant(combineFreeAndNonFreeProductsSection(a, b), b),
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  [membershipActionNames.GET_MEMBERSHIP_RELATED_ADDONS]: {
    name: 'storefront_aggregation_view',
    accessors: [
      ...getPageNameAccessor('addons'),
      ...genericAccessors,
      {
        name: 'sec_product_name',
        path: '',
        processor: (a, b: State.Root) => getProductNames(membershipRelatedAddonsSelector(b)?.list),
      },
      {
        name: 'sec_product_id',
        path: '',
        processor: (a, b) => getProductIds(membershipRelatedAddonsSelector(b)?.list),
      },
      {
        name: 'sec_product_category',
        path: '',
        processor: (a, b: State.Root) => getProductCategories(membershipRelatedAddonsSelector(b)?.list),
      },
      {
        name: 'sec_product_price',
        path: '',
        processor: (a, b) => getProductPricesUsingVariant(membershipRelatedAddonsSelector(b)?.list, b),
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
};
