import React from 'react';
import { DetailsWrap, DetailRowWrap, DetailHead, DetailContent } from './ProductItemDetails';

interface Props {
  renderContributionAmountInput: () => React.ReactNode;
  renderEditContributionButton: () => React.ReactNode;
  renderActionContributionButtons: () => React.ReactNode;
  isEditContributionAmountToggled: boolean;
}

export const ContributionProductDetails: React.FC<Props> = ({
  renderContributionAmountInput,
  renderEditContributionButton,
  renderActionContributionButtons,
  isEditContributionAmountToggled,
}) => {
  return (
    <>
      {!isEditContributionAmountToggled && <DetailRowWrap>{renderEditContributionButton()}</DetailRowWrap>}
      {isEditContributionAmountToggled && (
        <DetailsWrap isRow isContribution>
          <DetailRowWrap isContribution>
            <DetailHead>Amount</DetailHead>
            <DetailContent>{renderContributionAmountInput()}</DetailContent>
          </DetailRowWrap>
          <DetailRowWrap>{renderActionContributionButtons()}</DetailRowWrap>
        </DetailsWrap>
      )}
    </>
  );
};
