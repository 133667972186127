import { publicEncrypt } from 'crypto';
import { Salesforce, User as UserTypes } from 'mxp-schemas';
import { Utils } from 'mxp-utils';

export const getPasswordBufferString = (value: string, pubKey: string) => {
  const buffer = publicEncrypt(
    {
      key: pubKey, // returned from graphql
      padding: 4,
      oaepHash: 'sha1',
    },
    Buffer.from(value, 'utf8')
  );

  return buffer.toString('base64');
};

export const isTokenProblemError = (error: any) =>
  error?.errorCode === UserTypes.UserErrorCodes.RESET_PASS_TOKEN_INCORRECT ||
  error?.errorCode === UserTypes.UserErrorCodes.RESET_PASS_TOKEN_EXPIRED;

export const getPersonAccountFlags = (
  cpaLicense: Salesforce.CPALicense,
  cpaLicenseList: { cpaLicense: Salesforce.CPALicense[] }
): Salesforce.PersonAccountFlags | null => {
  const findCpaLicense = cpaLicense?.id
    ? cpaLicenseList.cpaLicense.find((license: Salesforce.CPALicense) => license?.id === cpaLicense?.id)
    : {};

  if (Utils.isEqualHelper(findCpaLicense, cpaLicense)) {
    return null;
  }

  return {
    isNewUser: false,
    isProfileUpdate: false,
    modifiedRecords: [cpaLicense?.id],
    thirdPartyChangedFields: [Salesforce.thirdPartyFieldsType.CPA_LICENSE],
  };
};
