import moment from 'moment-timezone';
import { getPath } from 'utils';
import {
  CimaMipApplicationProgressRoutesMap,
  CimaMipApplicationFormList,
  CimaMipMainApplicationSubHeading,
  CimaMipMainApplicationFormInstructions,
  CimaMipMainApplicationHeading,
} from 'constants/index';

export const dataChecker = (items?: any) => {
  return items !== undefined && items !== '' && items !== null;
};

export const dataConvertion = (items: any) => {
  return items !== undefined && items !== '' && items !== null ? items : undefined;
};

export const dataConvertionCheckbox = (item: any) => {
  return typeof item === 'string' ? (item === 'true' ? true : false) : item;
};

export const checkIfMipRenewalToday = (
  accessEndDate?: any,
  renewalSeasonStartDate?: any,
  renewalSeasonEndDate?: any
) => {
  const mipCredentialAccessEndYear = new Date(accessEndDate).getFullYear();
  const today = moment();

  const mipRenewalSeasonStart = moment(renewalSeasonStartDate, 'MM-DD');
  const mipRenewalSeasonEnd = moment(renewalSeasonEndDate, 'MM-DD');

  const mipRenewalSeasonStartMonthDay = moment(renewalSeasonStartDate).format('MM-DD');
  const mipRenewalSeasonEndMonthDay = moment(renewalSeasonEndDate).format('MM-DD');

  const mipRenewalStartDate = mipRenewalSeasonStart.isAfter(mipRenewalSeasonEnd)
    ? moment(`${mipCredentialAccessEndYear}-${mipRenewalSeasonStartMonthDay}`)
    : moment(`${mipCredentialAccessEndYear - 1}-${mipRenewalSeasonStartMonthDay}`);
  const mipRenewalEndDate = moment(`${mipCredentialAccessEndYear}-${mipRenewalSeasonEndMonthDay}`);
  const isMipRenewalToday = today.isBetween(mipRenewalStartDate, mipRenewalEndDate);

  return isMipRenewalToday;
};

export const isSubmitButton = (
  isMipRenewalSeason: any,
  hasMipCredential: any,
  mipData: any,
  aml: any,
  workHistory: any
) => {
  const isCommonComplete =
    !mipData?.contactDetails?.practiceList?.[0]?.isCompleteContact ||
    !mipData?.practiceRequirement?.isCompletePracticeRequirement ||
    !aml?.isCompleteAml;

  return isMipRenewalSeason && hasMipCredential
    ? isCommonComplete
    : isCommonComplete || !workHistory?.isWorkHistoryComplete;
};

export const cardRenewOrNot = (isMipRenewalSeason: any, hasMipCredential: any, formCard: any) => {
  return isMipRenewalSeason && hasMipCredential
    ? formCard.filter((f: any) => f.cardHeading !== CimaMipApplicationFormList.WORK_HISTORY)
    : formCard;
};

export const isFileItemTrue = (fileItem: any) => {
  return fileItem.isComplete === true || fileItem.isComplete === 'true';
};

export const isFileItemFalse = (fileItem: any) => {
  return fileItem.isComplete === false || fileItem.isComplete === 'false' || !fileItem.isComplete;
};

export const getPathApplicationProgress = (application: any) => {
  return getPath(CimaMipApplicationProgressRoutesMap[application.applicationProgress || '']);
};

export const formHeader = (isMipRenewalSeason: any, hasMipCredential: any) => {
  return isMipRenewalSeason && hasMipCredential
    ? CimaMipMainApplicationHeading.MIP_HEADING_RENEWAL
    : CimaMipMainApplicationHeading.MIP_HEADING_NEW;
};

export const formSubHeader = (isMipRenewalSeason: any, hasMipCredential: any) => {
  return isMipRenewalSeason && hasMipCredential
    ? CimaMipMainApplicationSubHeading.MIP_SUBHEADING_RENEWAL
    : CimaMipMainApplicationSubHeading.MIP_SUBHEADING_NEW;
};

export const formInstruction = (isMipRenewalSeason: any, hasMipCredential: any) => {
  return isMipRenewalSeason && hasMipCredential
    ? CimaMipMainApplicationFormInstructions.MIP_FORM_INSTRUCTIONS_RENEWAL
    : CimaMipMainApplicationFormInstructions.MIP_FORM_INSTRUCTIONS_NEW;
};

export const formCardComplete = (isMipRenewalSeason: any, hasMipCredential: any, formCard: any) => {
  return !(isMipRenewalSeason && hasMipCredential) ? formCard.length : formCard.length - 1;
};

export const submitText = (checked: any) => {
  return checked.length === 4
    ? 'Please submit application'
    : 'Please complete each of the sections  to submit the application';
};
