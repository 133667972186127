import { User } from 'mxp-schemas';
import { TRenewMembershipDetailsTableData } from '../types/types';
import { isActiveCheckDates } from 'modules/products/helpers';
import { fromCentsFormat, formatPrice, includedSectionClaimed } from 'utils';
import { Product as ProductUtils } from 'mxp-utils';

export const getMembershipsSections = (
  existingSections: State.LineItem[],
  existingFreeSection: State.LineItem[],
  hasSelectedSection: boolean,
  userChoiceSections: State.UserChoiceSectionProductWithPrice[],
  userChoicesFreeSection: State.UserChoiceSectionFreeProduct[],
  sectionsList: State.SectionWithPriceProduct[],
  currency: State.ProductCurrency,
  benefitsList: any
): TRenewMembershipDetailsTableData['membershipSections'] => {
  const now = new Date();

  const userSectionCompilation = userChoiceSections.concat(userChoicesFreeSection);

  const addedSections = sectionsList.filter(item => {
    return userSectionCompilation.some(choice => choice.productId === item.productId);
  });

  const hasExistingSections = existingFreeSection.length || existingSections.length;

  if (!hasSelectedSection && hasExistingSections) {
    const sectionsToBeDisplayed = userSectionCompilation;
    return sectionsToBeDisplayed.map((section: any) => {
      const sectionDetails = sectionsList.filter((benefit: any) => benefit.productId === section.productId);

      const price = sectionDetails?.length
        ? ProductUtils.getProductPrice(
            sectionDetails[0],
            sectionDetails[0].variants[0].sku || '',
            [User.MembershipIdsEnum.MRUSR0001],
            currency.label
          )?.priceFinal?.amount ?? 0
        : 0;
      return {
        name: sectionDetails.length ? sectionDetails[0].name : section.name,
        value: formatPrice(fromCentsFormat(price), section.totalPrice?.currencyCode),
        isIncluded: existingFreeSection.length
          ? includedSectionClaimed(existingFreeSection, benefitsList, section.productId)
          : false,
        status: isActiveCheckDates(section as any, now) ? 'Active' : 'Expired',
      };
    });
  }

  if (hasSelectedSection && addedSections.length) {
    const sectionsToBeDisplayed = userSectionCompilation;
    return addedSections.map(item => {
      const sku = (sectionsToBeDisplayed as any).find((choice: any) => choice.productId === item.productId)?.sku;
      const price =
        ProductUtils.getProductPrice(
          item,
          sku || item.variants[0].sku || '',
          [User.MembershipIdsEnum.MRUSR0001],
          currency.label
        )?.priceFinal?.amount ?? 0;

      // Will check if this section is already bought by user for included flag
      const isSectionIncluded = userChoicesFreeSection?.some(
        (freeSection: any) => freeSection.productId === item.productId
      );

      return {
        name: item.name,
        value: formatPrice(fromCentsFormat(price), currency.label),
        status: 'Active',
        isIncluded: isSectionIncluded,
      };
    });
  }
  return undefined;
};
