import React, { ReactElement } from 'react';
import { RenewMembershipOptions } from 'constants/index';
import { Users, Package, Layers, Award, CertificateAward } from 'components/atoms/svg';

export const setIcon = (item: string): ReactElement<any, any> | undefined => {
  if (item === RenewMembershipOptions.ACCOUNT_TYPE) {
    return <Users />;
  }
  if (item === RenewMembershipOptions.ADD_ONS) {
    return <Package />;
  }
  if (item === RenewMembershipOptions.MEMBERSHIP_SECTIONS) {
    return <Layers />;
  }
  if (item === RenewMembershipOptions.MEMBERSHIP_CREDENTIALS) {
    return <CertificateAward />;
  }
  if (item === RenewMembershipOptions.SERVICE_TIER) {
    return <Award />;
  }
};
