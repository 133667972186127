import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Loader } from 'semantic-ui-react';
import { useHistory, useLocation, useParams, generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { Routes, MembershipPackageAccordionStatus } from '../../../constants';
import { getPath } from 'utils';
import { MiniUserWidgetContainer } from 'containers/UserWidgetContainer';
import { Link, Heading, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { ReactComponent as AicpaCimaLogoWhite } from 'resources/images/aicpa-cima-logo-white.svg';
import { ReactComponent as AicpaCimaLogoWhiteMobile } from 'resources/images/aicpa-cima-logo-white-mobile.svg';
import { ReactComponent as ArrowBack } from 'resources/images/ic-arrow-back.svg';
import {
  MembershipPackageAccordion,
  MembershipPackageAccordionItemProps,
} from '../PageMembershipPackage/PageMembershipPackage';
import { CenterMembershipTierSelection } from 'components/molecules';
import { getCenterMembershipByIdSelector, getSelectedOrganizationByIdSelector } from 'modules/centerAdmin/selectors';
import { membershipLoadingSelector, membershipTypesSelector } from 'modules/membership/selectors';
import { Admin as AdminUtils } from 'mxp-utils';
import {
  setMembershipJourneyType,
  fetchMembershipTiers,
  fetchMembershipTypes,
  setCenterMembershipPackageType,
} from 'modules/membership/actions';
import { MembershipTypes, Product } from 'mxp-schemas';
import { EVENT_CLICK, handleEvent } from 'utils/Analytics';

export const PageCenterMembershipRenewal: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [activeIndex, setActiveIndex] = useState(1);
  const [maxIndex, setMaxIndex] = useState(1);

  const { centerMembershipId, orgId }: any = useParams();
  const membershipLoading = useSelector(membershipLoadingSelector);
  const { list } = useSelector(membershipTypesSelector);
  const selectedCenterMembership = useSelector(getCenterMembershipByIdSelector(centerMembershipId));
  const selectedOrganization = useSelector(getSelectedOrganizationByIdSelector(orgId));
  const numberOfCpa = selectedCenterMembership?.numberOfCpa ?? '';
  const slug = AdminUtils.transformFirmMembershipTypeToSlug(
    selectedCenterMembership?.firmMembershipType || ''
  ).toLowerCase();
  const centerMembershipEndDate = selectedCenterMembership?.membershipTerm.expiryDate;

  const ACCORDION_DATA: MembershipPackageAccordionItemProps[] = [
    {
      key: MembershipPackageAccordionStatus.CPA,
      title: `View your tier`,
      content: {
        content: (
          <CenterMembershipTierSelection
            isRenewal={true}
            renewalNumberOfCpa={numberOfCpa}
            centerMembershipEndDate={centerMembershipEndDate}
          />
        ),
      },
    },
  ];
  const [accordionData, setData] = useState(ACCORDION_DATA);

  const handleLogoClick = () => {
    history.push(getPath(Routes.ROOT));
  };

  const handleAccordionClick = () => void 0;

  // MembershipPackage Accordion props
  const props = {
    data: accordionData,
    activeIndex,
    maxIndex,
    onClick: handleAccordionClick,
    isCenterMembershipRenewal: true,
    ...(AdminUtils.isCAQType(slug) && {
      centerRenewalIssuer: selectedOrganization?.PCAOBNumOfIssuers,
      isCAQType: true,
    }),
  };

  useEffect(() => {
    const destination = getPath(Routes.CENTER_MEMBERSHIP_FORM);
    const membershipStatus = MembershipPackageAccordionStatus.Type;

    handleEvent(
      { clickValue: `button:renew-membership:int:modal-start:${destination}#${membershipStatus}` },
      EVENT_CLICK
    );
  }, []);

  useEffect(() => {
    (async () => {
      // SET center membership journey type
      dispatch(setMembershipJourneyType(MembershipTypes.MembershipJourneyType.CENTER_MEMBERSHIP_RENEWAL));
      // FETCH membership types (state.membership.list)
      await dispatch(fetchMembershipTypes(Product.MembershipApplicationType.AICPA_CENTER_MEMBERSHIP));

      // FIND membership product block by slug
      const foundMembershipBlockBySlug = list?.find((type: any) => type.slug.toLowerCase() === slug.toLowerCase());
      // SET membership selected package type
      await dispatch(setCenterMembershipPackageType(foundMembershipBlockBySlug?.id, slug));
      // get tiers and also product item
      await dispatch(fetchMembershipTiers(true, slug));
    })();
  }, [dispatch, slug, list]);

  useEffect(() => {
    if (!selectedCenterMembership) {
      dispatch(push(generatePath(getPath(Routes.CENTER_ADMIN_MEMBERSHIPS_TAB_MANAGE), { orgId })));
    }
  }, [dispatch, selectedCenterMembership, orgId]);

  useEffect(() => {
    const index = accordionData.findIndex(({ key }) => key === location.hash.replace('#', ''));

    if (index !== activeIndex) {
      setActiveIndex(index < 0 ? 0 : index);
    }

    if (index > maxIndex) {
      setMaxIndex(index < 0 ? 0 : index);
    }
  }, [location, accordionData, maxIndex, activeIndex]);

  useEffect(() => {
    setData(currData => {
      const pillValue: string[] = [numberOfCpa];
      return currData.map((panelData, i) => ({
        ...panelData,
        pillValue: pillValue[i],
      }));
    });
  }, [numberOfCpa]);

  if (membershipLoading) return <Loader active content="Loading" />;

  return (
    <StyledBackground>
      <Helmet>
        <title>Center Membership Renewal | AICPA & CIMA</title>
      </Helmet>
      <StyledHeader />
      <StyledHeaderContainer fluid>
        <OnlyDesktop minWidth={768}>
          <StyledContainer fluid>
            <StyledAicpaLogoWhite onClick={handleLogoClick} />
            <StyledMiniUserWidget showLogoutLink isMembershipJourney />
          </StyledContainer>
        </OnlyDesktop>
        <OnlyMobile maxWidth={767}>
          <StyledContainer fluid>
            <StyledAicpaCimaLogoWhiteMobile onClick={handleLogoClick} />
            <StyledMiniUserWidget showLogoutLink isMembershipJourney />
          </StyledContainer>
        </OnlyMobile>

        <StyledContainer fluid>
          <StyledBackLink to={generatePath(getPath(Routes.CENTER_ADMIN_MEMBERSHIPS_TAB_MANAGE), { orgId })}>
            <ArrowBack /> Back
          </StyledBackLink>
        </StyledContainer>
        <StyledHeading as="h1">
          Renew your <StyledTextBold>center membership</StyledTextBold>
        </StyledHeading>
        <StyledSubHeading>
          {'After you renew the selected membership, you will have the opportunity to renew another center membership'}
        </StyledSubHeading>
        <StyledAccordionContainer>
          <MembershipPackageAccordion {...props} />
        </StyledAccordionContainer>
      </StyledHeaderContainer>
    </StyledBackground>
  );
};

const StyledHeader = styled.section`
  height: ${props => props.theme.pxToRem(449)};
  width: 150%;
  border-radius: 0 0 65% 65%/0 0 100% 100%;
  background-image: linear-gradient(73deg, #6a2d87 35%, #a93d69 101%);
  transform: translateX(-16.5%);
  ${props => props.theme.mediaQueries.mobileOnly} {
    height: ${props => props.theme.pxToRem(504.4)};
    border-radius: 0 0 200% 200%/0 0 100% 100%;
  }
`;

const StyledAccordionContainer = styled.div`
  margin-left: 0 auto !important;
  margin-right: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledHeaderContainer = styled(Container)`
  top: 0;
  position: absolute;
  margin: ${props => `0 0 ${props.theme.pxToRem(1030)}`};
  padding: ${props =>
    `${props.theme.pxToRem(15)} ${props.theme.pxToRem(160)} ${props.theme.pxToRem(49)} ${props.theme.pxToRem(160)}`};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `0 0 ${props.theme.pxToRem(-5)}`};
    left: 0;
    text-align: center;
    padding: ${props =>
      `${props.theme.pxToRem(18)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(13.4)} ${props.theme.pxToRem(10)}`};
  }
`;

const StyledMiniUserWidget = styled(MiniUserWidgetContainer)`
  color: ${props => props.theme.colors.neutralWhite} !important;
  text-decoration: none;
`;

const StyledAicpaLogoWhite = styled(AicpaCimaLogoWhite)`
  cursor: pointer;
  height: ${props => props.theme.pxToRem(34)};
  width: ${props => props.theme.pxToRem(288)};
`;

const StyledAicpaCimaLogoWhiteMobile = styled(AicpaCimaLogoWhiteMobile)`
  cursor: pointer;
  float: left !important;
  height: ${props => props.theme.pxToRem(34)};
  width: ${props => props.theme.pxToRem(48)};
`;

const StyledContainer = styled(Container)`
  &&&& {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    left: 0;
  }
`;

const StyledBackground = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey1};
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const StyledHeading = styled(Heading)`
  text-align: center !important;
  color: ${props => props.theme.colors.neutralWhite};
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xxl};
  font-family: ${props => props.theme.fontFamily};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xl};
  }
`;

const StyledTextBold = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const StyledBackLink = styled(Link)`
  display: inline-flex;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  text-decoration: none;
  color: ${props => props.theme.colors.neutralWhite} !important;
  margin: ${props => `${props.theme.pxToRem(25)} 0 ${props.theme.pxToRem(-50)} 0`};
  > svg {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    margin-right: ${props => props.theme.pxToRem(4)};
    path {
      fill: ${props => props.theme.colors.neutralWhite};
    }
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `${props.theme.pxToRem(50)} 0 0 0`};
  }
`;

const StyledSubHeading = styled.div`
  color: ${({ theme }) => theme.colors.neutralWhite} !important;
  font-weight: ${props => props.theme.fontWeights.light} !important;
  text-align: center;
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSizes.s};
  padding-bottom: ${props => props.theme.pxToRem(50)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-bottom: ${props => props.theme.pxToRem(30)};
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;
