import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';
import { Modal } from 'components/atoms/Modal/Modal';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { NotificationBanner, NotificationBannerEnums } from 'components/atoms/NotificationBanner/NotificationBanner';
import { Link } from 'components/atoms/Link/Link';
import { useDropzone } from 'react-dropzone';
import {
  uploadDocument,
  getDocumentExamExemption,
  removeExemptionDocument,
  getDocumentUpload,
} from 'modules/documentUpload/actions';
import { uploadedDocumentsSelector } from 'modules/uploadedDocuments/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { errorSelector } from 'modules/documentUpload/selectors';
import { applicationPartSelector, personAccountDataSelector } from 'modules/user/selectors';
import { getPersonLevelExemptionId, personLevelExemptionSelector } from 'modules/personLevelExemption';
import { deleteUploadedDoc, fetchUploadedDocuments } from 'modules/uploadedDocuments';
import { ErrorType } from 'modules/documentUpload/constants';
import { ReactComponent as IconCheckCircle } from 'resources/images/icon-dev-ic-check-circle.svg';
import { Mip } from 'mxp-schemas';
import { Utils } from 'mxp-utils';

/**
 * Input File
 * @category Document Management
 * MART-1218
 */

export enum FileSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum AcceptedFiles {
  png = 'image/png',
  jpeg = 'image/jpeg',
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  gif = 'image/gif',
  mp4 = 'video/mp4',
  rar = 'application/vnd.rar',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv = 'text/csv',
}

export interface FileProps {
  testId?: string;
  className?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  size?: FileSizes;
  filesAccepted?: AcceptedFiles[];
  fileSizeLimit?: number;
  entityName?: string;
  isSavingToS3?: boolean;
  maxFiles: number;
  isFileTableShow?: boolean;
  isCimaFileUploadView?: boolean;
  actionItemId?: (id: any) => void;
  returnFileItems?: (list: any) => void;
  fileTemplateLink?: string;
  header?: string;
  isCimaFileExemption?: boolean;
  cimaExemptionUploadedFiles?: any[];
  isCustomizedErrorMessage?: boolean;
  fileSizeErrorMessage?: string;
  fileTypeErrorMessage?: string;
  hasUploadSuccessMessage?: boolean;
  uploadSuccessMessage?: string;
  setIsFileUploaded?: (acceptedFile: any) => void;
  isFileUploadRequired?: boolean;
  isFileUploadRequiredErrorMessage?: string;
  fetchedFile?: Mip.File;
  amlFile?: any[];
  setFileUploadReset?: (fileUploadReset: any) => void;
  responseDocuName?: string;
  isDocumentUpload?: boolean;
  customFileType?: AcceptedFiles[];
  isCredential?: boolean;
  isCimaErrorMessage?: boolean;
}

const formatBytesToMB = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const mbValue = (bytes / 1000000).toFixed(2);
  return Number(mbValue);
};

const acceptedFilesDescriptionObject = [
  { description: 'png', format: 'image/png' },
  { description: 'jpeg', format: 'image/jpeg' },
  { description: 'pdf', format: 'application/pdf' },
  { description: 'doc', format: 'application/msword' },
  { description: 'docx', format: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { description: 'gif', format: 'image/gif' },
  { description: 'mp4', format: 'video/mp4' },
  { description: 'rar', format: 'application/vnd.rar' },
  { description: 'xls', format: 'application/vnd.ms-excel' },
  { description: 'xlsx', format: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { description: 'csv', format: 'text/csv' },
];

export const File: React.FC<FileProps> = ({
  size,
  placeholder,
  filesAccepted,
  fileSizeLimit,
  entityName,
  isSavingToS3,
  maxFiles,
  className,
  isFileTableShow,
  returnFileItems,
  actionItemId,
  isCimaFileUploadView,
  fileTemplateLink,
  header,
  isCimaFileExemption,
  cimaExemptionUploadedFiles,
  isCustomizedErrorMessage,
  fileSizeErrorMessage,
  fileTypeErrorMessage,
  hasUploadSuccessMessage,
  uploadSuccessMessage,
  setIsFileUploaded,
  isFileUploadRequired,
  isFileUploadRequiredErrorMessage,
  fetchedFile,
  amlFile,
  setFileUploadReset,
  responseDocuName,
  isDocumentUpload,
  customFileType,
  isCredential,
  isCimaErrorMessage,
}) => {
  const { uploadedDocumentsFetched } = useSelector(uploadedDocumentsSelector);
  const dispatch = useDispatch();
  const uploadError = useSelector(errorSelector);
  const applicationPart = useSelector(applicationPartSelector);
  const personAccount = useSelector(personAccountDataSelector);
  const personLevelExemption: any = useSelector(personLevelExemptionSelector);
  const { id: personLevelExemptionKey } = personLevelExemption;
  const [errors, setErrors]: any = useState([]);
  const [acceptedFileItems, setAcceptedFileItems]: any = useState(fetchedFile ? [fetchedFile] : amlFile ? amlFile : []);
  const [filesSaved, setFilesSaved]: any = useState([]);
  const [uploading, setUploading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [deleteId, setDeleteId]: any = useState('');
  const acceptedFilesExtensions = filesAccepted ? filesAccepted.join(', ') : '';
  const [maxError, setMaxError] = useState(false);
  const [otherUploadError, setOtherUploadError] = useState(false);
  const [cimaExemption, setCimaExemption]: any = useState(null);
  const [filesOnDropZone, setFilesOnDropZone]: any = useState([]);
  const [failedUploadFiles, setFailedUploadFiles]: any[] = useState([]);
  // eslint-disable-next-line
  const [files, setFiles]: any = useState([]);
  const [disableUpload, setDisableUpload]: any = useState(false);
  const [exemptionFileName, setExemptionFileName]: any = useState('');
  const alreadyUploadedFiles = amlFile ? amlFile?.map(item => item.id) : [];
  const acceptedFilesDescriptionArray = acceptedFilesDescriptionObject.map(item => item.format);
  const isCredentialApplication = isDocumentUpload && isCredential === false;
  const isRenderLoading = !isDocumentUpload && !uploadedDocumentsFetched;
  const uploadedDocumentsData: any =
    cimaExemption || isCimaFileExemption
      ? cimaExemptionUploadedFiles
      : fetchedFile
      ? [fetchedFile]
      : amlFile
      ? amlFile
      : [];

  const acceptedFilesDescription = customFileType?.length // Display the file format of CustomFileType
    ? customFileType.map((fileType: any) => {
        const fileDescription = acceptedFilesDescriptionObject.find(file => file.format === fileType);
        if (fileDescription) return fileDescription.description;
        return null;
      })
    : filesAccepted?.length
    ? filesAccepted.map((fileType: any) => {
        const fileDescription = acceptedFilesDescriptionObject.find(file => file.format === fileType);
        if (fileDescription) return fileDescription.description;
        return null;
      })
    : [];

  const handleDownloadExamExemption = useCallback(
    async (fileName: string) => {
      await dispatch(getDocumentExamExemption(fileName)).then((response: any) => {
        if (response?.payload?.length) {
          window.open(response?.payload, '_blank');
        }
      });
    },
    [dispatch]
  );

  const sizeLengthValidator = (file: any) => {
    const formattedSize = formatBytesToMB(file.size);
    const fileLimitMB = fileSizeLimit || 20;

    const fileFullName = header ? `${header} - ${file.name.split(' ').join('_')}` : file.name.split(' ').join('_');

    // In Salesforce, the maximum length for the Contact Last Name' field is 80. (https://help.salesforce.com/s/articleView?id=000384760&type=1)
    if (fileFullName.length >= 80) {
      const error = {
        code: ErrorType.FILE_NAME_TOO_LONG,
        message: `Sorry, your file name is too long. Please rename your file before uploading`,
      };
      return error;
    }

    if (formattedSize > fileLimitMB) {
      let errorMessage;
      if (isCustomizedErrorMessage) {
        errorMessage = `${fileSizeErrorMessage}`;
      } else if (isCimaFileUploadView) {
        errorMessage = `The file you have selected exceeds file size requirements. Please upload a smaller file (less than ${fileLimitMB}MB).`;
      } else {
        errorMessage = `The file you have selected exceeds file size requirements, please upload a smaller file (less than ${fileLimitMB}MB)`;
      }
      const error = {
        code: ErrorType.FILE_TOO_LARGE,
        message: errorMessage,
      };

      return error;
    }

    return null;
  };

  const handleDownloadFile = useCallback(
    async (fileName: string) => {
      const downloadLink = await dispatch(getDocumentUpload(fileName));
      // Document Download Link
      if (downloadLink?.response?.payload?.length) {
        window.open(downloadLink?.response?.payload, '_blank');
      }
    },
    [dispatch]
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles,
    accept: acceptedFilesExtensions,
    noClick: disableUpload || uploading || files?.length >= maxFiles,
    disabled: disableUpload || uploading || files?.length >= maxFiles,
    noDrag: disableUpload || uploading || files?.length >= maxFiles,
    validator: sizeLengthValidator,
    // tslint:disable-next-line: no-shadowed-variable
    onDrop: (droppedFile: any, fileRejections: any) => {
      setMaxError(false);
      setOtherUploadError(false);
      setFailedUploadFiles([]);
      setUploading(false);
      setRemoving(false);
      const fileItems: any[] = [];
      if (!!fileRejections?.length) return droppedFile.splice(0, droppedFile?.length);
      droppedFile.forEach((file: any) => {
        const { name } = file;
        fileItems.push({ id: Utils.generateRandomInt().toString(16).slice(2), name, file });
      });
      if (isDocumentUpload) setAcceptedFileItems(fileItems);
      setFilesOnDropZone([...fileItems]);
    },
  });

  useEffect(() => {
    if (maxError || files?.length > maxFiles) {
      const error = {
        code: ErrorType.TOO_MANY_FILES,
        message: `Sorry, you have already uploaded the maximum number of files (${maxFiles})`,
      };
      setErrors([error]);
      setFilesOnDropZone([]);
      if (files?.length >= maxFiles) {
        setDisableUpload(true);
      } else {
        setDisableUpload(false);
      }
    }

    if (!!fileRejections.length && !maxError) {
      fileRejections.forEach((file: any) => {
        file?.errors?.forEach((fileError: any) => {
          if (fileError.code === ErrorType.TOO_MANY_FILES) {
            fileError.message = `Sorry, you have already uploaded the maximum number of files (${maxFiles})`;
          }

          if (fileError.code === ErrorType.FILE_INVALID_TYPE) {
            fileError.message =
              isCustomizedErrorMessage && fileTypeErrorMessage
                ? fileTypeErrorMessage
                : `The file you have selected is not in the correct format. Please upload a new file ( ${acceptedFilesDescription?.join(
                    ', '
                  )} )`;
          }
        });
        setErrors(file?.errors);
      });
      setFilesOnDropZone([]);
    }
    if (
      !isCimaErrorMessage &&
      !otherUploadError &&
      !uploadError &&
      !fileRejections.length &&
      !maxError &&
      files?.length <= maxFiles
    ) {
      setErrors([]);
      setDisableUpload(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections, maxFiles, files, maxError, otherUploadError, uploadError, isCimaErrorMessage]);

  useEffect(() => {
    if (uploadError || otherUploadError || isCimaErrorMessage) {
      setErrors([
        {
          code: ErrorType.SOMETHING_WENT_WRONG,
          message: 'Sorry, something went wrong with this file please try another.',
        },
      ]);
    }
  }, [uploadError, otherUploadError, isCimaErrorMessage]);

  useEffect(() => {
    dispatch(fetchUploadedDocuments());
    dispatch(getPersonLevelExemptionId());
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      if (isCredentialApplication || !!errors?.length || !filesOnDropZone?.length || fetchedFile) return;
      const addedFiles: any[] = [];
      let isMax = false;
      let otherError = false;
      let inProgress = 0;
      filesOnDropZone.forEach((item: any, index: number) => {
        const { name, file, id } = item;
        const saveName = header
          ? `${header?.replace('/', '')} - ${name.split(' ').join('_')}`
          : name.split(' ').join('_');
        if (
          filesSaved.includes(id) ||
          [...files, ...addedFiles]?.includes(saveName) ||
          alreadyUploadedFiles?.includes(id) ||
          !acceptedFilesDescriptionArray.includes(file?.type)
        ) {
          return delete filesOnDropZone[index];
        }

        (async () => {
          if (isSavingToS3 && item && !uploading && !removing) {
            if (files?.length + addedFiles?.length + 1 > maxFiles) {
              isMax = true;
            } else {
              setUploading(true);
              setFilesSaved([...filesSaved, id]);
              addedFiles.push(saveName);
              inProgress += 1;
              try {
                await dispatch(
                  uploadDocument(
                    saveName,
                    entityName || 'Document',
                    file,
                    applicationPart?.id || '',
                    String(file?.size) || '',
                    personAccount?.id || '',
                    file.type,
                    String(personLevelExemptionKey) || ''
                  )
                ).catch((error: any) => {
                  const failedDocName = error?.request?.variables?.fileName || saveName;
                  setFailedUploadFiles((docs: any) => [...docs, failedDocName]);
                  otherError = true;
                });
              } catch (err) {
                otherError = true;
                throw new Error(JSON.stringify(err));
              }
            }
            inProgress -= 1;
            if (inProgress <= 0) {
              const responseData = await dispatch(fetchUploadedDocuments());
              if (setIsFileUploaded && !!responseData?.payload?.length && responseDocuName) {
                const responseDocuFile = responseData?.payload?.filter((list: any) =>
                  list?.Name ? list.Name.includes(responseDocuName) : null
                );
                if (responseDocuFile?.length > 0 && responseDocuFile[0]?.Id) {
                  const responseFile = {
                    id: responseDocuFile[0]?.Id || '',
                    name: name || '',
                    file: {
                      type: name || '',
                    },
                  };
                  setIsFileUploaded(responseFile);
                }
              }
              setFilesOnDropZone([]);
              setMaxError(isMax);
              setOtherUploadError(otherError);
              return setUploading(false);
            }
          }
        })();
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSavingToS3,
      entityName,
      filesSaved,
      acceptedFileItems,
      dispatch,
      setFilesSaved,
      applicationPart,
      personAccount,
      header,
      personLevelExemptionKey,
      fetchedFile,
      files,
      filesOnDropZone,
      maxFiles,
      errors,
      isDocumentUpload,
    ]
  );

  useEffect(() => {
    if (!failedUploadFiles?.length || !uploadedDocumentsData?.length || uploading || isDocumentUpload) return;
    setRemoving(true);
    let inProgress = 0;
    uploadedDocumentsData?.forEach((fileItem: any) => {
      (async () => {
        inProgress += 1;
        const fileName = fileItem?.Name || fileItem?.name;
        const fileId = fileItem?.Id || fileItem?.id;
        if (failedUploadFiles?.includes(fileName)) {
          await dispatch(deleteUploadedDoc(fileId))
            .then(() => {
              inProgress -= 1;
            })
            .catch((err: any) => {
              throw new Error(JSON.stringify(err));
            });
        }
        if (inProgress <= 0) {
          await dispatch(fetchUploadedDocuments()).then(() => {
            setFailedUploadFiles([]);
          });
          setRemoving(false);
        }
      })();
    });
  }, [failedUploadFiles, uploadedDocumentsData, uploading, isDocumentUpload, dispatch]);
  const [initialValidationState, setInitialValidationState] = useState(true);

  const onClickDelete = useCallback(
    (id: string) => {
      if (actionItemId) {
        actionItemId(id);
      }
      if (acceptedFileItems.length > 0) {
        setInitialValidationState(false);
      }
      if (!uploading && !removing) {
        const filteredFileItems = acceptedFileItems.filter((fileItem: any) => fileItem.id !== id);
        setAcceptedFileItems(filteredFileItems);
        setDeleteId(id);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAcceptedFileItems, acceptedFileItems, actionItemId, setInitialValidationState, uploading, removing]
  );

  useEffect(
    () => {
      setMaxError(false);
      fileRejections.splice(0, fileRejections?.length);
      setOtherUploadError(false);
      setUploading(false);
      if (deleteId && !uploading && !cimaExemption) {
        setRemoving(true);
        dispatch(deleteUploadedDoc(deleteId)).finally(() => {
          dispatch(fetchUploadedDocuments());
          setRemoving(false);
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteId, dispatch]
  );

  useEffect(() => {
    if (returnFileItems) {
      returnFileItems(acceptedFileItems);
    }
  }, [returnFileItems, acceptedFileItems]);

  const DisplayIconFile = (file: any) => {
    switch (file) {
      case 'image/png':
        return '/cima-images.png';
      case 'image/jpeg':
        return '/cima-images.png';
      case 'application/pdf':
        return '/cima-pdf.png';
      case 'application/msword':
        return '/cima-docs.png';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '/cima-docs.png';
      default:
        return '/cima-images.png';
    }
  };

  useEffect(() => {
    const amlData = fetchedFile ? [fetchedFile] : amlFile ? amlFile : [];
    if (uploading || removing || isRenderLoading) return;
    if (!cimaExemption && isCimaFileExemption) setCimaExemption(true);
    const accepted: any = [];
    if (cimaExemption || isCimaFileExemption) {
      const relatedFiles =
        cimaExemptionUploadedFiles &&
        cimaExemptionUploadedFiles
          ?.filter(
            (exemptionFile: { Name: string; SFS_DOC_DocumentType__c: string }) =>
              header?.replace('/', '') === exemptionFile.SFS_DOC_DocumentType__c ||
              (exemptionFile?.Name?.match(
                header?.replace('/', '') === 'Certificate' ? /^Certificate/ : header?.replace('/', '')!
              ) &&
                ![...failedUploadFiles].includes(exemptionFile?.Name))
          )
          .map(item => {
            accepted.push(item);
            return item.Name;
          });
      setFiles(relatedFiles);
    } else {
      const relatedFiles =
        amlData &&
        amlData
          ?.filter(item => ![...failedUploadFiles].includes(item?.name))
          .map(item => {
            accepted.push(item);
            return item.name;
          });
      setFiles(relatedFiles);
    }
    setAcceptedFileItems(accepted);
  }, [
    cimaExemptionUploadedFiles,
    uploading,
    removing,
    cimaExemption,
    isCimaFileExemption,
    isRenderLoading,
    fetchedFile,
    amlFile,
    header,
    failedUploadFiles,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCimaFileUpload = () => {
    const cimaFileTypesDescription = `Please make sure your files do not exceed ${fileSizeLimit || '20'}MB and ${
      Boolean(isDocumentUpload) ? 'and in .pdf format only' : 'are in one of these formats:'
    } ${acceptedFilesDescription
      ?.map((fileType: string | null, i: number) => {
        if (fileType === 'jpeg') return `.jpg`;
        if (i + 1 !== acceptedFilesDescription.length) return `.${fileType}`;
        return Boolean(isDocumentUpload) ? '' : `or .${fileType}`;
      })
      .join(', ')}.`;

    return (
      <StyledSection size={size} maxFiles={maxFiles}>
        {!uploading &&
          !!errors.length &&
          errors.map((error: { message: string; code: string }) => {
            return (
              <StyledNotificationBanner
                key={error.code}
                variant={NotificationBannerEnums.variant.red}
                testId="notification-banner"
                childrenTestId="notification-children"
                icon={<StyledIconError />}
              >
                {error.message}
              </StyledNotificationBanner>
            );
          })}

        {!!acceptedFileItems.length &&
          hasUploadSuccessMessage &&
          !errors.length(
            <StyledNotificationBanner
              variant={NotificationBannerEnums.variant.green}
              testId="notification-banner"
              childrenTestId="notification-children"
              icon={<StyledIconCheckCircle />}
            >
              {uploadSuccessMessage ? uploadSuccessMessage : 'File successfully uploaded and have been stored'}
            </StyledNotificationBanner>
          )}
        <StyledDropZone {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />

          <StyledFileClip>
            <StyledFileIcon src="/cima-clip.png" alt="file" />
            <StyledFileClipDiv>
              {!isCimaFileUploadView ? (
                <span>
                  <strong>Add Multiple files</strong> or drop files here
                </span>
              ) : (
                <span>
                  <strong>Add</strong> or drop file here
                </span>
              )}
            </StyledFileClipDiv>
          </StyledFileClip>

          <StyledSpan>
            {placeholder ?? cimaFileTypesDescription}
            <br />
            {!isCimaFileUploadView ? (
              <StyledLinkDocument to="fileTemplateLink">Download the CSV template here</StyledLinkDocument>
            ) : (
              <StyledSpanDocument>Upload documents here</StyledSpanDocument>
            )}
          </StyledSpan>
          <Loader active={uploading || isRenderLoading} />
        </StyledDropZone>
      </StyledSection>
    );
  };

  const renderDefaultFileUpload = () => {
    return (
      <StyledSection size={size} maxFiles={maxFiles}>
        {!uploading &&
          !!errors.length &&
          errors.map((error: { message: string; code: string }) => {
            return (
              <StyledNotificationBanner
                key={error.code}
                variant={NotificationBannerEnums.variant.red}
                testId="notification-banner"
                childrenTestId="notification-children"
                icon={<StyledIconError />}
              >
                {error.message}
              </StyledNotificationBanner>
            );
          })}
        {!!acceptedFileItems.length && hasUploadSuccessMessage && !errors.length && (
          <StyledNotificationBanner
            variant={NotificationBannerEnums.variant.green}
            testId="notification-banner"
            childrenTestId="notification-children"
            icon={<StyledIconCheckCircle />}
          >
            {uploadSuccessMessage ? uploadSuccessMessage : 'File successfully uploaded and have been stored'}
          </StyledNotificationBanner>
        )}
        {!initialValidationState && isFileUploadRequired && !errors.length && !acceptedFileItems.length && (
          <StyledNotificationBanner
            variant={NotificationBannerEnums.variant.red}
            testId="notification-banner"
            childrenTestId="notification-children"
            icon={<StyledIconError />}
          >
            {isFileUploadRequiredErrorMessage || `Please upload a file (Required)`}
          </StyledNotificationBanner>
        )}
        <StyledDropZone {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />

          <StyledFileClip>
            <StyledFileIcon src="/cima-clip.png" alt="file" />
            <StyledFileClipDiv>
              {isCimaFileUploadView ? (
                <span>
                  <strong>Add Multiple files</strong> or drop files here
                </span>
              ) : (
                <span>
                  <strong>Add file</strong> or drop files here
                </span>
              )}
            </StyledFileClipDiv>
          </StyledFileClip>
          <StyledSpan>
            {placeholder ??
              `Please make sure your files do not exceed ${
                fileSizeLimit || '20'
              }MB and are in one of these formats: ${acceptedFilesDescription?.join(', ')}. `}
            <br />
            {isCimaFileUploadView ? (
              <StyledLinkDocument to="fileTemplateLink">Download the CSV template here</StyledLinkDocument>
            ) : (
              <StyledSpanDocument>Upload documents here</StyledSpanDocument>
            )}
          </StyledSpan>
          <Loader active={uploading || isRenderLoading} />
        </StyledDropZone>
      </StyledSection>
    );
  };

  // Delete Files Modal
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const setOpenCloseDeleteModalCallback = useCallback(() => {
    setDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const setOpenCloseDeleteModalCallbackDel = useCallback((event: any, fileName: string) => {
    setDeleteModalOpen(!isDeleteModalOpen);
    setExemptionFileName(fileName);
    setDeleteId(event.target.value);
    setRemoving(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDeleteModalConfirm = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (uploading) return;
      setRemoving(true);
      if (deleteId) {
        await Promise.all([
          dispatch(deleteUploadedDoc(deleteId)),
          dispatch(removeExemptionDocument(exemptionFileName)),
        ]);
      }
      await dispatch(fetchUploadedDocuments());
      setDeleteModalOpen(!isDeleteModalOpen);
      setRemoving(false);
    },
    [dispatch, cimaExemptionUploadedFiles, deleteId, uploading] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <StyledDeleteModal
        open={isDeleteModalOpen}
        onClose={setOpenCloseDeleteModalCallback}
        heading="Are you sure you want to remove this file?"
        testId="delete-exempt-file-modal"
        showCloseCross
        cancelNode={
          <Button
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.secondary}
            testId="cancel-deletion"
            onClick={setOpenCloseDeleteModalCallback}
          >
            Cancel
          </Button>
        }
        confirmNode={
          <Button
            className={removing ? 'loading' : ''}
            size={ButtonEnums.sizes.small}
            testId="confirm-deletion"
            variant={ButtonEnums.variants.primary}
            onClick={onDeleteModalConfirm}
          >
            Remove file
          </Button>
        }
      >
        If you are looking to replace this file with a new file, then continue with the removal and re-upload a new
        file.
      </StyledDeleteModal>

      <Container className={className}>
        {(isFileTableShow || !!acceptedFileItems.length) && !cimaExemption && (
          <StyledDiv>
            {!!acceptedFileItems.length && <StyledFileListSpan>Your uploaded documents:</StyledFileListSpan>}
            <br />
            {acceptedFileItems.map(
              (fileItem: any) =>
                ![...failedUploadFiles].includes(fileItem?.Name) && (
                  <StyledUl key={fileItem.id}>
                    <StyledItemTableDiv>
                      <IconImg
                        src={DisplayIconFile(
                          !fetchedFile
                            ? fileItem.file.type
                            : acceptedFilesDescriptionObject
                                .find(f2 => f2.format === fetchedFile?.file?.type)
                                ?.format.toString()
                        )}
                        alt="icon"
                      />
                      <StyledFileListSpan
                        {...(isCimaFileUploadView && { className: 'cimaPrice' })}
                        onClick={handleDownloadFile.bind(null, fileItem?.name)}
                      >
                        {fileItem.name}
                      </StyledFileListSpan>
                    </StyledItemTableDiv>
                    <StyledDeleteSpan
                      onClick={onClickDelete.bind(null, fileItem.id)}
                      {...(isCimaFileUploadView && { className: 'cimaPrice' })}
                    >
                      Delete
                    </StyledDeleteSpan>
                  </StyledUl>
                )
            )}
          </StyledDiv>
        )}

        {cimaExemption && !!acceptedFileItems.length && (
          <StyledDiv>
            <StyledFileListSpan>Your uploaded documents:</StyledFileListSpan>
            <br />
            {acceptedFileItems?.map(
              (exemptionFile: {
                Name: string;
                SFS_DOC_DocumentType__c: string;
                Id: string;
                SFS_DOC_XfilesPath__c: string;
              }) => {
                return (
                  <>
                    {(header?.replace('/', '') === exemptionFile.SFS_DOC_DocumentType__c ||
                      exemptionFile?.Name?.match(
                        header?.replace('/', '') === 'Certificate' ? /^Certificate/ : header?.replace('/', '')!
                      )) &&
                      ![...failedUploadFiles].includes(exemptionFile?.Name) && (
                        <StyledUl key={exemptionFile?.Id}>
                          <StyledItemTableDiv onClick={handleDownloadExamExemption.bind(null, exemptionFile?.Name)}>
                            <IconImg src={DisplayIconFile(exemptionFile?.Name.slice(-3))} alt="icon" />
                            <StyledFileListSpan>{exemptionFile?.Name}</StyledFileListSpan>
                          </StyledItemTableDiv>
                          <StyledDeleteSpan
                            value={exemptionFile?.Id} // tslint:disable-next-line
                            onClick={(event: React.SyntheticEvent) =>
                              setOpenCloseDeleteModalCallbackDel(event, exemptionFile?.Name)
                            } // tslint:disable-line jsx-no-lambda
                          >
                            Remove
                          </StyledDeleteSpan>
                        </StyledUl>
                      )}
                  </>
                );
              }
            )}
          </StyledDiv>
        )}

        {isCimaFileUploadView ? renderCimaFileUpload() : renderDefaultFileUpload()}
      </Container>
    </>
  );
};
const StyledSpanDocument = styled.span`
  position: relative;
  z-index: 0;
  color: ${props => props.theme.colors.primaryLightPurple};
  text-decoration: underline;
`;

const StyledUl = styled.ul`
  color: ${props => props.theme.colors.primaryDarkPurple};
  padding-left: 0;
  text-decoration: underline;
  font-weight: initial;
  justify-content: space-between;
  align-content: center;
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fontSizes.m};
`;

const StyledDeleteSpan = styled.button`
  text-decoration: underline;
  float: right;
  cursor: pointer;
  border: none;
  background: none;
`;

const StyledFileListSpan = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledDiv = styled.div`
  border-radius: 10px;
  border-bottom: 0 !important;
  background-color: ${props => props.theme.colors.neutralGrey1};
  padding: ${props => props.theme.pxToRem(50)};
  margin-top: ${props => props.theme.pxToRem(20)};
  width: 100%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(40)} ${props => props.theme.pxToRem(15)};
  }

  .cimaPrice {
    font-weight: ${props => props.theme.fontWeights.light}!important;
  }
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin: ${props => props.theme.pxToRem(45)};
  ${props => props.theme.pxToRem(45)};
  ${props => props.theme.pxToRem(0)};
  ${props => props.theme.pxToRem(45)};
  width: fit-content;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(40)} ${props => props.theme.pxToRem(20)} 0;
  }
`;

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;

// Example Benefit Name
const StyledSpan = styled.span`
  cursor: pointer;
  height: ${props => props.theme.pxToRem(64)};
  margin: 0 ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(16)};
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: gray;
  display: block;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 0;
  }
`;

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
`;

const StyledSection = styled.div<FileProps>`
  ${({ size, theme }) => {
    let width = 700;
    if (size === FileSizes.small) {
      width = 500;
    } else if (size === FileSizes.large) {
      width = 1000;
    }
    return `width: ${theme.pxToRem(width)};`;
  }}
  height: auto;
  margin: ${props => props.theme.pxToRem(24)} 0 0;
  border-radius: ${props => props.theme.pxToRem(10)};
  border: solid ${props => props.theme.pxToRem(0.5)} #bababa;
  background-color: #f9fafb;
  position: relative;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledDropZone = styled.div`
  position: relative;
  box-sizing: border-box;
  display: block;
  left: 0;
  right: 0;
  text-align: center;
  margin: ${props => props.theme.pxToRem(50)} ${props => props.theme.pxToRem(80)};
  z-index: 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(15)};
    margin: ${props => props.theme.pxToRem(50)} auto;
  }
`;

const StyledFileClip = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  position: relative;
  margin-bottom: ${props => props.theme.pxToRem(25)};
`;

const StyledFileIcon = styled.img`
  width: ${props => props.theme.pxToRem(52)};
  height: ${props => props.theme.pxToRem(52)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(41)};
    height: ${props => props.theme.pxToRem(39)};
  }
`;

const StyledFileClipDiv = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  font-size: ${props => props.theme.fontSizes.l};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const StyledItemTableDiv = styled.div`
  align-content: center;
  text-align: center;
  display: flex;
  cursor: pointer;
`;

const IconImg = styled.img`
  width: ${props => props.theme.pxToRem(25)};
  height: ${props => props.theme.pxToRem(25)};
  margin-right: ${props => props.theme.pxToRem(10)};
`;

const StyledLinkDocument = styled(Link)`
  &&& {
    color: ${props => props.theme.colors.primaryLightPurple};
    text-decoration: underline;
  }
`;

const StyledDeleteModal = styled(Modal)`
  &&&& {
    width: ${props => props.theme.pxToRem(590)};
    background: ${props => props.theme.colors.neutralWhite};
    margin-top: ${props => props.theme.pxToRem(48)};
    border-radius: ${props => props.theme.pxToRem(2)};
    box-sizing: border-box;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    .content {
      margin: 0 !important;
      text-align: center;
      padding: ${props => props.theme.pxToRem(28)} 0 ${props => props.theme.pxToRem(32)} !important;
    }

    > .header {
      margin: ${props => props.theme.pxToRem(-14)} auto 0;
      text-align: center;
      display: table;
      h2 {
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }

  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const StyledIconCheckCircle = styled(IconCheckCircle)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  display: inline;
`;
