import { createSelector } from 'reselect';
import { FEATURE_TOGGLE_DASHBOARD, CheckoutCountriesListToggles, RegistrationCountriesListToggles } from './constants';
import { clientConfig } from '../../config';

const rootSelector = createSelector(
  (state: State.Root) => state.featureToggle,
  (featureToggle: State.FeatureToggle): State.FeatureToggle => featureToggle
);

export const featureTogglesSelector = createSelector(
  rootSelector,
  (featureToggle: State.FeatureToggle): State.FeatureToggleByKey => featureToggle.featureToggles
);

export const toggleDashboardSelector = createSelector(
  featureTogglesSelector,
  (featureToggleByKey: State.FeatureToggleByKey): boolean => {
    /**
     * For local dev usage - no need to have isToggleOn entry in your own contentful space
     * Please ensure REACT_APP_TOGGLE_DASHBOARD=true in .env
     */
    if (clientConfig.isFBE === 'true' || clientConfig.showTogglePage === 'true') return true;
    return !!featureToggleByKey[FEATURE_TOGGLE_DASHBOARD];
  }
);

export const getFeatureToggleByKeySelector = createSelector(
  featureTogglesSelector,
  (_: any, key: string): string => key,
  (featureToggles: State.FeatureToggleByKey, key: string): boolean => featureToggles[key] || false
);

export const checkoutCountriesListTogglesSelector = createSelector(
  featureTogglesSelector,
  (featureToggles: State.FeatureToggleByKey): string[] =>
    Object.values(CheckoutCountriesListToggles).reduce((filters: string[], toggle) => {
      const isToggleEnabled = featureToggles[toggle] || false;
      if (!isToggleEnabled) {
        filters.push(toggle);
      }
      return filters;
    }, [])
);

export const registrationRestrictedCountriesSelector = createSelector(
  featureTogglesSelector,
  (featureToggles: State.FeatureToggleByKey): string[] =>
    Object.values(RegistrationCountriesListToggles).reduce((restrictedCountries: string[], toggle) => {
      const isToggleEnabled = featureToggles[toggle.toggleName] || false;
      if (!isToggleEnabled) {
        restrictedCountries.push(toggle.ISOAlpha2);
      }
      return restrictedCountries;
    }, [])
);
