import { App, Contentful } from 'mxp-schemas';
import { handleActions, Action } from 'redux-actions';
import { initialState, membershipActionNames } from './constants';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [membershipActionNames.IS_LOADING]: (state: State.Membership) => ({
    ...state,
    loading: true,
  }),
  [membershipActionNames.IS_NOT_LOADING]: (state: State.Membership) => ({
    ...state,
    loading: false,
  }),
  [membershipActionNames.SET_MEMBERSHIP_JOURNEY_TYPE]: (state: State.Membership, action: any): State.Membership => ({
    ...state,
    journeyType: action.payload,
  }),
  [membershipActionNames.GET_MEMBERSHIP_TYPES]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      types: {
        ...state.types,
        list: action.payload.list,
        error: null,
        isFetched: true,
        ...(action.payload.joiningFee && { joiningFee: action.payload.joiningFee }),
      },
    }),
    throw: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      types: {
        ...state.types,
        error: action.payload,
      },
    }),
  },
  [membershipActionNames.SET_MEMBERSHIP_TYPES]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      types: {
        ...state.types,
        list: action.payload,
        error: null,
      },
    }),
  },
  [membershipActionNames.GET_MEMBERSHIP_TIERS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      product: {
        ...action.payload,
      },
    }),
    throw: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      product: state.product && {
        ...state.product,
      },
    }),
  },
  [membershipActionNames.GET_MEMBERSHIP_CREDENTIALS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      credentials: {
        ...state.credentials,
        list: action.payload.list,
        categories: action.payload.categories,
        error: null,
      },
    }),
    throw: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      credentials: {
        ...state.credentials,
        error: action.payload,
      },
    }),
  },
  [membershipActionNames.GET_MEMBERSHIP_SECTIONS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      sections: {
        ...state.sections,
        listOfFreeProduct: action.payload.freeProduct,
        listOfProductWithPrice: action.payload.productWithPrice,
        benefitQuantity: action.payload.benefitQuantity,
        categories: action.payload.categories,
        error: null,
        isFetched: true,
      },
    }),
    throw: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      sections: {
        ...state.sections,
        error: action.payload,
      },
    }),
  },
  [membershipActionNames.GET_MEMBERSHIP_RELATED_ADDONS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      addons: {
        ...state.addons,
        list: action.payload.list,
        categories: action.payload.categories,
        error: null,
        isFetched: true,
      },
    }),
    throw: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      addons: {
        ...state.addons,
        error: action.payload,
      },
    }),
  },
  [membershipActionNames.GET_ALL_PRODUCTS_PATHWAY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      pathways: {
        ...state.pathways,
        list: action.payload.list,
        categories: action.payload.categories,
        error: null,
        isFetched: true,
      },
    }),
    throw: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      pathways: {
        ...state.pathways,
        error: action.payload,
      },
    }),
  },
  [membershipActionNames.RESET_MEMBERSHIP_USER_CHOICESLUG]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        credentials: action.payload.type === 'credentials' ? [] : state.userChoice.credentials,
        sectionFreeProduct: action.payload.type === 'sections' ? [] : state.userChoice.sectionFreeProduct,
        sectionProductWithPrice: action.payload.type === 'sections' ? [] : state.userChoice.sectionProductWithPrice,
        addons: action.payload.type === 'addons' ? [] : state.userChoice.addons,
      },
    }),
  },
  [membershipActionNames.RESET_MEMBERSHIP_USER_CHOICE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: initialState.userChoice,
    }),
  },
  [membershipActionNames.ADD_MEMBERSHIP_TO_CART]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      addCartStatus: {
        ...state.addCartStatus,
        success: true,
        error: null,
      },
    }),
    throw: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      addCartStatus: {
        ...state.addCartStatus,
        success: false,
        error: action.payload,
      },
    }),
  },
  [membershipActionNames.SET_MEMBERSHIP_PACKAGE_TYPE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        type: { id: action.payload.id, slug: action.payload.slug },
        tier: Boolean(action.payload.resetTier) ? '' : state.userChoice.tier,
        sectionFreeProduct: [],
        ...(action.payload.shouldRemoveSection && { sectionProductWithPrice: [] }),
        addons: Boolean(action.payload.retainAddOns) ? state.userChoice.addons : [],
      },
    }),
  },
  [membershipActionNames.SET_CENTER_MEMBERSHIP_PACKAGE_TYPE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        type: action.payload,
      },
    }),
  },
  [membershipActionNames.SET_MEMBERSHIP_PACKAGE_TIER]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        tier: action.payload.sku,
        sectionFreeProduct: Boolean(action.payload.shouldRetainFreeProducts) ? state.userChoice.sectionFreeProduct : [],
        addons: Boolean(action.payload.isRetainAddons) ? state.userChoice.addons : [],
      },
    }),
  },
  [membershipActionNames.FILTER_MEMBERSHIP_PACKAGE_RELATED_ADDON_BY_CATEGORY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      addons: {
        ...state.addons,
        categories: action.payload.updateSelectedCategory,
        list: action.payload.filteredProducts,
      },
    }),
  },
  [membershipActionNames.FILTER_MEMBERSHIP_PACKAGE_RELATED_PATHWAY_BY_CATEGORY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      pathways: {
        ...state.addons,
        categories: action.payload.updateSelectedCategory,
        list: action.payload.filteredProducts,
      },
    }),
  },
  [membershipActionNames.FILTER_MEMBERSHIP_PACKAGE_SECTION_BY_CATEGORY]: {
    next: (state: State.Membership, action: any): State.Membership => {
      return {
        ...state,
        sections: {
          ...state.sections,
          categories: action.payload.updateSelectedCategory,
          listOfProductWithPrice: action.payload.filteredProducts,
        },
      };
    },
  },
  [membershipActionNames.FILTER_MEMBERSHIP_CREDENTIAL_BY_CATEGORY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      credentials: {
        ...state.credentials,
        categories: action.payload.updateSelectedCategory,
        list: action.payload.filteredProducts,
      },
    }),
  },
  [membershipActionNames.ADD_MEMBERSHIP_PACKAGE_SECTION_FREE_PRODUCT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        sectionFreeProduct: [
          ...state.userChoice.sectionFreeProduct,
          ...(state.userChoice.sectionFreeProduct.map(a => a.sku).includes(action.payload.sku) ? [] : [action.payload]),
        ],
      },
    }),
  },
  [membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_SECTION_FREE_PRODUCT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        sectionFreeProduct: [...state.userChoice.sectionFreeProduct].filter(
          section => section.productId !== action.payload
        ),
      },
    }),
  },
  [membershipActionNames.ADD_MEMBERSHIP_PACKAGE_RELATED_ADDON]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        addons: [
          ...state.userChoice.addons,
          ...(state.userChoice.addons.map(a => a.sku).includes(action.payload.sku) ? [] : [action.payload]),
        ],
      },
    }),
  },
  [membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_RELATED_ADDON]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        addons: action.payload ? [...state.userChoice.addons].filter(addon => addon.sku !== action.payload) : [],
      },
    }),
  },
  [membershipActionNames.ADD_MEMBERSHIP_PACKAGE_RELATED_PATHWAY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        credentials: [], // Delayed Befits for ACMA and FCMA will be added on addMembershipToCart actions
        pathways: action.payload,
      },
    }),
  },
  [membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_RELATED_PATHWAY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        pathways: [],
        selectedPathwayBundleId: '',
      },
    }),
  },
  [membershipActionNames.SET_SELECTED_PATHWAY_BUNDLE_ID]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        selectedPathwayBundleId: action.payload || '',
      },
    }),
  },
  [membershipActionNames.SET_MEMBERSHIP_PRODUCT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      product: {
        ...action.payload,
      },
    }),
  },
  [membershipActionNames.ADD_CREDENTIAL_PRODUCT_ID_IN_USERCHOICE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        credentialProductId: action.payload,
      },
    }),
  },
  [membershipActionNames.ADD_SECTION_PRODUCT_ID_IN_USERCHOICE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        sectionProductId: action.payload,
      },
    }),
  },
  [membershipActionNames.ADD_FLP_VARIANT_IN_USERCHOICE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        flpVariant: action.payload,
      },
    }),
  },
  [membershipActionNames.ADD_MEMBERSHIP_PACKAGE_SECTION_WITH_PRICE_PRODUCT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      sections: {
        ...state.sections,
      },
      userChoice: {
        ...state.userChoice,
        sectionProductWithPrice: [
          ...state.userChoice.sectionProductWithPrice,
          ...(state.userChoice.sectionProductWithPrice.map(a => a.sku).includes(action.payload.sku)
            ? []
            : [action.payload]),
        ],
      },
    }),
  },
  [membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_SECTION_WITH_PRICE_PRODUCT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        sectionProductWithPrice: [...state.userChoice.sectionProductWithPrice].filter(
          section => section.sku !== action.payload
        ),
      },
    }),
  },
  [membershipActionNames.ADD_MEMBERSHIP_PACKAGE_CREDENTIAL]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        credentials: [
          ...state.userChoice.credentials,
          ...(state.userChoice.credentials.map(a => a.sku).includes(action.payload.sku) ? [] : [action.payload]),
        ],
      },
    }),
  },
  [membershipActionNames.SET_CREDENTIAL_APPLICATION_VALUE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      credentialQuestions: {
        ...state.credentialQuestions,
        ...action.payload,
      },
    }),
  },
  [membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_CREDENTIAL]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        credentials: [...state.userChoice.credentials].filter(credential => credential.productId !== action.payload),
      },
    }),
  },
  [membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_CREDENTIAL_BY_SKU]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        credentials: [...state.userChoice.credentials].filter(credential => credential.sku !== action.payload),
      },
    }),
  },
  [membershipActionNames.SEE_MORE_MEMBERSHIP_PACKAGE_RELATED_ADDON_PRODUCT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      addons: {
        ...state.addons,
        list: action.payload,
      },
    }),
  },
  [membershipActionNames.SEE_MORE_MEMBERSHIP_PACKAGE_RELATED_PATHWAY_PRODUCT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      pathways: {
        ...state.pathways,
        list: action.payload,
      },
    }),
  },
  [membershipActionNames.SEE_MORE_MEMBERSHIP_PACKAGE_SECTION_PRODUCT_WITH_PRICE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      sections: {
        ...state.sections,
        listOfProductWithPrice: action.payload,
      },
    }),
  },
  [membershipActionNames.SEE_MORE_MEMBERSHIP_PACKAGE_CREDENTIAL_PRODUCT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      credentials: {
        ...state.credentials,
        list: action.payload,
      },
    }),
  },
  [membershipActionNames.MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_SECTION_CATEGORY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      sections: {
        ...state.sections,
        categories: state.sections.categories.map(category =>
          category.name === action.payload ? { ...category, page: category.page + 1 } : category
        ),
        error: null,
      },
    }),
  },
  [membershipActionNames.MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_ADDON_CATEGORY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      addons: {
        ...state.addons,
        categories: state.addons.categories.map(category =>
          category.name === action.payload ? { ...category, page: category.page + 1 } : category
        ),
        error: null,
      },
    }),
  },
  [membershipActionNames.MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_PATHWAY_CATEGORY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      pathways: {
        ...state.pathways,
        categories: state.pathways.categories.map(category =>
          category.name === action.payload ? { ...category, page: category.page + 1 } : category
        ),
        error: null,
      },
    }),
  },
  [membershipActionNames.MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_CREDENTIAL_CATEGORY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      credentials: {
        ...state.credentials,
        categories: state.credentials.categories.map(category =>
          category.name === action.payload ? { ...category, page: category.page + 1 } : category
        ),
        error: null,
      },
    }),
  },
  [membershipActionNames.SHOW_MEMBERSHIP_FIRST_PAGE_OF_CREDENTIAL_PRODUCTS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      credentials: {
        ...state.credentials,
        list: action.payload,
        error: null,
      },
    }),
  },
  [membershipActionNames.SHOW_MEMBERSHIP_FIRST_PAGE_OF_ADDON_PRODUCTS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      addons: {
        ...state.addons,
        list: action.payload,
        error: null,
      },
    }),
  },
  [membershipActionNames.SHOW_MEMBERSHIP_FIRST_PAGE_OF_SECTION_PRODUCTS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      sections: {
        ...state.sections,
        listOfProductWithPrice: action.payload,
        error: null,
      },
    }),
  },
  [membershipActionNames.GET_MEMBERSHIP_RELATED_BENEFITS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      benefits: {
        ...state.benefits,
        list: action.payload,
        isFetched: true,
        success: true,
        loading: false,
        errors: null,
      },
    }),
  },
  [membershipActionNames.GET_MEMBERSHIP_BENEFIT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      benefits: {
        ...state.benefits,
        list: action.payload.getProductsByType,
        isFetched: true,
        success: action.payload.success,
        loading: false,
        errors: action?.payload?.error,
      },
    }),
  },
  [membershipActionNames.GET_SELECTED_BENEFITS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      benefits: {
        ...state.benefits,
        userSelected: [...state.benefits.userSelected, ...action.payload],
      },
    }),
  },
  [membershipActionNames.SET_SELECTED_SKU_BENEFITS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      benefits: {
        ...state.benefits,
        skuSelected: [...state.benefits.skuSelected, ...action.payload],
      },
    }),
  },
  [membershipActionNames.SET_SELECTED_MEMBERSHIP_BENEFITS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      benefits: {
        ...state.benefits,
        membershipSelected: action.payload,
      },
    }),
  },
  [membershipActionNames.GET_RENEWAL_SEASON_INFO]: {
    next: (
      state: State.Membership,
      action: Action<{ getRenewalSeasonInfo: App.GetRenewalSeasonInfoResponse }>
    ): State.Membership => {
      return {
        ...state,
        isRenewalSeason: action.payload.getRenewalSeasonInfo.isRenewalSeason,
        isCimaRenewalSeason: action.payload.getRenewalSeasonInfo.isCimaRenewalSeason,
      };
    },
  },
  [membershipActionNames.CHANGE_MEMBERSHIP_FILTER]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      individual: {
        ...state.individual,
        individualSearchObject: action.payload?.individualSearchObject,
      },
    }),
  },
  [membershipActionNames.SEARCH_INDIVIDUAL]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      individual: {
        individualSearchObject: action.payload?.individualSearchObject,
        searchType: action.payload.searchType,
        list: action.payload.searchIndividual?.individualResults,
        totalSize: action.payload?.searchIndividual?.totalSize,
        isFetched: true,
        success: action.payload.success,
        loading: action.payload.loading,
        errors: action?.payload?.error,
      },
    }),
  },
  [membershipActionNames.GET_DATA_OF_SPECIFIC_INVITE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      inviteData: {
        ...state.inviteData,
        inviteId: action.payload?.inviteId,
        isPaidByFirm: action.payload?.isPaidByFirm,
        type: action.payload?.type,
        tier: action.payload?.tier,
        organizationId: action.payload?.organizationId,
        organization: action.payload?.organization,
        error: null,
        position: action.payload?.position,
        status: action.payload?.status,
        isFLP: action.payload?.isFLP,
        isPaidByFLP: action.payload?.isPaidByFLP,
        flpSubscriptionType: action?.payload?.flpSubscriptionType,
        duration: action?.payload?.duration,
      },
    }),
    throw: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      inviteData: {
        ...state.inviteData,
        error: action.payload,
      },
    }),
  },
  [membershipActionNames.CHECK_IF_CREDENTIAL_ELIGIBLE_FOR_MEMBERSHIP]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      membershipEligibleCredential: {
        ...state.membershipEligibleCredential,
        membershipTypeSlug: action.payload.membershipTypeSlug,
        productId: action.payload.productId,
        tier: action.payload.tierName,
        eligible: action.payload.eligible,
      },
    }),
  },
  [membershipActionNames.CHECK_SELECTED_SECTION_IF_IT_IS_PART_OF_MEMBERSHIP_BENEFIT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      membershipEligibleSection: {
        ...state.membershipEligibleSection,
        membershipTypeSlug: action.payload.membershipTypeSlug,
        tier: action.payload.tierName,
        eligible: action.payload.eligible,
      },
    }),
  },
  [membershipActionNames.SET_UPDATE_APPRENTICE_LEVEL]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      isUpdateApprenticeLevel: true,
    }),
  },
  [membershipActionNames.GET_PRACTICAL_EXPERIENCE_REQUIREMENT]: {
    next: (state: State.Membership, action: any): State.Membership => {
      if (action.payload?.success) {
        return {
          ...state,
          practicalExperienceRequirement: {
            ...state.practicalExperienceRequirement,
            portfolio: action.payload.portfolio,
            employment: action.payload.module.employment,
            skills: action.payload.module.skillsAndBehavior,
            activities: action.payload.module.coreActivity,
            perFetchSuccess: true,
          },
          loading: !action.payload.endLoading,
        };
      }
      return { ...state, ...(action?.payload?.endLoading && { loading: !action.payload.endLoading }) };
    },
  },
  [membershipActionNames.UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_RECORD]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        ...action.payload,
      },
    }),
  },
  [membershipActionNames.UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        employment: {
          ...state.practicalExperienceRequirement.employment,
          ...action.payload.employment,
        },
      },
    }),
  },
  [membershipActionNames.UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_ACTIVITIES]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        activities: {
          ...state.practicalExperienceRequirement.activities,
          ...action.payload.activities,
        },
      },
    }),
  },
  [membershipActionNames.UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_SKILLS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        skills: {
          ...state.practicalExperienceRequirement.skills,
          ...action.payload.skills,
        },
      },
    }),
  },
  [membershipActionNames.QUERY_ORGANIZATIONS_BY_WILDCARD_LOADING]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        searchOrganizationsByWildcardLoading: true,
        employerAlreadyExists: false,
      },
    }),
  },
  [membershipActionNames.QUERY_ORGANIZATIONS_BY_WILDCARD]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        searchResultOrganizations:
          action.payload?.searchOrganizationsByName || state.practicalExperienceRequirement.searchResultOrganizations,
        searchOrganizationsByWildcardLoading: false,
        employerAlreadyExists: false,
      },
    }),
  },
  [membershipActionNames.QUERY_ORGANIZATIONS_CITIES_LOADING]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        searchOrganizationsCitiesLoading: true,
      },
    }),
  },
  [membershipActionNames.QUERY_ORGANIZATIONS_CITIES]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        searchResultOrganizationsCities:
          action.payload?.searchOrganizationsByName ||
          state.practicalExperienceRequirement.searchResultOrganizationsCities,
        searchOrganizationsCitiesLoading: false,
      },
    }),
  },
  [membershipActionNames.QUERY_ORGANIZATION_BY_NAME_AND_CITY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployer: {
          ...state.practicalExperienceRequirement.newEmployer,
          organization: action.payload,
        },
        searchOrganizationsCitiesLoading: false,
      },
    }),
  },
  [membershipActionNames.RESET_EMPLOYMENT_EFFECT]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployer: {
          ...state.practicalExperienceRequirement.newEmployer,
          organization: initialState.practicalExperienceRequirement.newEmployer.organization,
        },
      },
    }),
  },
  [membershipActionNames.GET_SELECTED_CREDENTIALS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      credentials: {
        ...state.credentials,
        userSelected: action.payload,
      },
    }),
  },
  [membershipActionNames.SET_INVITE_DATA_STATUS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      inviteData: {
        ...state.inviteData,
        status: action.payload,
      },
    }),
  },
  [membershipActionNames.SET_COMING_FROM_PROP_PAGE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      isComingFromPropPage: action.payload,
    }),
  },
  [membershipActionNames.SET_IS_CREDENTIALS_JOURNEY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      isCredentialsJourney: action.payload,
    }),
  },
  [membershipActionNames.SET_IS_RENEWALS_JOURNEY]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      isRenewalsJourney: action.payload,
    }),
  },
  [membershipActionNames.SET_HAS_SELECTED_CREDENTIAL]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      hasSelectedCredential: action.payload,
    }),
  },
  [membershipActionNames.SET_IS_FLP_UPGRADE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      isFLPUpgrade: action.payload,
    }),
  },
  [membershipActionNames.SET_IS_FLP_SWITCH]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      isFLPSwitch: action.payload,
    }),
  },
  [membershipActionNames.SET_FLP_PERSON_EXAM]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      flpPersonExam: action.payload,
    }),
  },
  [membershipActionNames.ADD_CART_LOADING]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      addCartLoading: action.payload,
    }),
  },
  [membershipActionNames.SET_SECTIONS_CREDENTIALS_RENEWAL]: {
    next: (state: State.Membership, action: any): State.Membership => {
      const { isTriggered, productType } = action.payload;
      return {
        ...state,
        sectionsCredentialsRenewal: {
          ...state.sectionsCredentialsRenewal,
          isTriggered,
          productType,
        },
      };
    },
  },
  [membershipActionNames.SET_CREDENTIALS_ITEM_RENEWAL]: {
    next: (state: State.Membership, action: any): State.Membership => {
      return {
        ...state,
        renewals: {
          ...state.renewals,
          credentials: action.payload,
        },
      };
    },
  },
  [membershipActionNames.SET_FLP_DISCOUNTED_PRICE]: {
    next: (state: State.Membership, action: any): State.Membership => {
      return {
        ...state,
        userChoice: {
          ...state.userChoice,
          externalPrice: action.payload,
        },
      };
    },
  },
  [membershipActionNames.SET_MEMBERSHIP_ITEM_RENEWAL]: {
    next: (state: State.Membership, action: any): State.Membership => {
      return {
        ...state,
        renewals: {
          ...state.renewals,
          membership: action.payload,
        },
      };
    },
  },
  [membershipActionNames.SET_MEMBERSHIP_EVENT]: {
    next: (state: State.Membership, action: any): State.Membership => {
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.event]: action.payload.value,
        },
      };
    },
  },
  [membershipActionNames.SET_PROP_PAGE_URL]: {
    next: (state: State.Membership, action: any): State.Membership => {
      return {
        ...state,
        propPageUrl: {
          ...state.propPageUrl,
          [action.payload.key]: action.payload.value,
        },
      };
    },
  },
  [membershipActionNames.REMOVE_PROP_PAGE_URL]: {
    next: (state: State.Membership): State.Membership => {
      return {
        ...state,
        propPageUrl: {
          credential: '',
          section: '',
          variant: '',
        },
      };
    },
  },
  [membershipActionNames.GET_CIMA_MEMBERSHIP_TYPES]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      types: {
        ...state.types,
        filteredCIMAMembershipTypes: action.payload.filteredCmsProducts,
        allCIMAMembershipTypes: action.payload.allCmsProducts,
        error: null,
        isFetched: true,
      },
    }),
    throw: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      types: {
        ...state.types,
        error: action.payload,
      },
    }),
  },
  [membershipActionNames.SET_ROLE_FORM_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,

      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        formDataEmployment: {
          ...state.practicalExperienceRequirement.formDataEmployment,
          ...action.payload,
        },
      },
    };
  },
  [membershipActionNames.SET_EMPLOYER_FORM_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        formDataEmployment: {
          ...state.practicalExperienceRequirement.formDataEmployment,
          employer: {
            ...state.practicalExperienceRequirement.formDataEmployment.employer,
            ...action.payload,
          },
        },
      },
    };
  },
  [membershipActionNames.SET_EMPLOYER_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,

      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployment: {
          ...state.practicalExperienceRequirement.newEmployment,
          employer: {
            ...state.practicalExperienceRequirement.newEmployment.employer,
            ...action.payload,
          },
        },
      },
    };
  },
  [membershipActionNames.CLEAR_EMPLOYMENT_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployment: {
          ...state.practicalExperienceRequirement.newEmployment,
          employer: initialState.practicalExperienceRequirement.newEmployment.employer,
        },
      },
    };
  },
  [membershipActionNames.CLEAR_EXISTING_EMPLOYMENT_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        formDataEmployment: {
          ...state.practicalExperienceRequirement.formDataEmployment,
          employer: initialState.practicalExperienceRequirement.formDataEmployment.employer,
        },
      },
    };
  },
  [membershipActionNames.CLEAR_NEW_EMPLOYMENT_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployment: initialState.practicalExperienceRequirement.newEmployment,
      },
    };
  },
  [membershipActionNames.CLEAR_EMPLOYMENT_ADDRESS_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployment: {
          ...state.practicalExperienceRequirement.newEmployment,
          employer: {
            ...state.practicalExperienceRequirement.newEmployment.employer,
            addressLine1: '',
            addressLine2: '',
            state: '',
            postalCode: '',
            city: '',
          },
        },
      },
    };
  },
  [membershipActionNames.CLEAR_EXISTING_EMPLOYMENT_ADDRESS_DATA]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        formDataEmployment: {
          ...state.practicalExperienceRequirement.formDataEmployment,
          employer: {
            ...state.practicalExperienceRequirement.formDataEmployment.employer,
            addressLine1: '',
            addressLine2: '',
            state: '',
            postalCode: '',
            city: '',
          },
        },
      },
    };
  },

  [membershipActionNames.SET_EMPLOYMENT_ROLE_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployment: {
          ...state.practicalExperienceRequirement.newEmployment,
          ...action.payload,
        },
      },
    };
  },

  [membershipActionNames.SET_EMPLOYMENT_SUPERVISOR_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployment: {
          ...state.practicalExperienceRequirement.newEmployment,
          supervisor: {
            ...state.practicalExperienceRequirement.newEmployment.supervisor,
            ...action.payload,
          },
        },
      },
    };
  },

  [membershipActionNames.CLEAR_SUPERVISOR_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployment: {
          ...state.practicalExperienceRequirement.newEmployment,
          supervisor: initialState.practicalExperienceRequirement.newEmployment.supervisor,
        },
      },
    };
  },

  [membershipActionNames.CLEAR_SUPERVISOR_EMPLOYMENT_DATA]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployment: {
          ...state.practicalExperienceRequirement.newEmployment,
          supervisor:
            state.practicalExperienceRequirement.newEmployment.supervisor !== undefined
              ? {
                  ...state.practicalExperienceRequirement.newEmployment.supervisor,
                  employer: initialState.practicalExperienceRequirement.newEmployment.supervisor?.employer,
                }
              : undefined,
        },
      },
    };
  },
  [membershipActionNames.CLEAR_SUPERVISOR_EMPLOYER_ADDRESS]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployment: {
          ...state.practicalExperienceRequirement.newEmployment,
          supervisor:
            state.practicalExperienceRequirement.newEmployment.supervisor !== undefined
              ? {
                  ...state.practicalExperienceRequirement.newEmployment.supervisor,
                  employer:
                    state.practicalExperienceRequirement.newEmployment.supervisor?.employer !== undefined
                      ? {
                          ...state.practicalExperienceRequirement.newEmployment.supervisor?.employer,
                          addressLine1: '',
                          addressLine2: '',
                          state: '',
                          postalCode: '',
                          city: '',
                        }
                      : undefined,
                }
              : undefined,
        },
      },
    };
  },
  [membershipActionNames.SET_EMPLOYMENT_SUPERVISOR_EMPLOYER_DATA]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        newEmployment: {
          ...state.practicalExperienceRequirement.newEmployment,
          supervisor:
            state.practicalExperienceRequirement.newEmployment.supervisor !== undefined
              ? {
                  ...state.practicalExperienceRequirement.newEmployment.supervisor,
                  employer:
                    state.practicalExperienceRequirement.newEmployment.supervisor?.employer !== undefined
                      ? {
                          ...state.practicalExperienceRequirement.newEmployment.supervisor?.employer,
                          ...action?.payload,
                        }
                      : undefined,
                }
              : undefined,
        },
      },
    };
  },

  [membershipActionNames.SET_FORM_DATA_EMPLOYMENT]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,

      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        formDataEmployment: {
          ...state.practicalExperienceRequirement.formDataEmployment,
          ...action.payload,
        },
      },
    };
  },
  [membershipActionNames.SET_EMPLOYMENT_SUPERVISOR_FORM_DATA]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        formDataEmployment: {
          ...state.practicalExperienceRequirement.formDataEmployment,
          supervisor: {
            ...state.practicalExperienceRequirement.formDataEmployment.supervisor,
            ...action.payload,
          },
        },
      },
    };
  },

  [membershipActionNames.SET_EMPLOYMENT_SUPERVISOR_EMPLOYER_FORM_DATA]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        formDataEmployment: {
          ...state.practicalExperienceRequirement.formDataEmployment,
          supervisor:
            state.practicalExperienceRequirement.formDataEmployment.supervisor !== undefined
              ? {
                  ...state.practicalExperienceRequirement.formDataEmployment.supervisor,
                  employer:
                    state.practicalExperienceRequirement.formDataEmployment.supervisor?.employer !== undefined
                      ? {
                          ...state.practicalExperienceRequirement.formDataEmployment.supervisor?.employer,
                          ...action?.payload,
                        }
                      : undefined,
                }
              : undefined,
        },
      },
    };
  },

  [membershipActionNames.CLEAR_SUPERVISOR_FORM_DATA]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        formDataEmployment: {
          ...state.practicalExperienceRequirement.formDataEmployment,
          supervisor: initialState.practicalExperienceRequirement.formDataEmployment.supervisor,
        },
      },
    };
  },

  [membershipActionNames.CLEAR_SUPERVISOR_EMPLOYMENT_FORM_DATA]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        formDataEmployment: {
          ...state.practicalExperienceRequirement.formDataEmployment,
          supervisor:
            state.practicalExperienceRequirement.formDataEmployment.supervisor !== undefined
              ? {
                  ...state.practicalExperienceRequirement.formDataEmployment.supervisor,
                  employer: initialState.practicalExperienceRequirement.formDataEmployment.supervisor?.employer,
                }
              : undefined,
        },
      },
    };
  },

  [membershipActionNames.CLEAR_SUPERVISOR_EMPLOYER_ADDRESS_FORM_DATA]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      practicalExperienceRequirement: {
        ...state.practicalExperienceRequirement,
        formDataEmployment: {
          ...state.practicalExperienceRequirement.formDataEmployment,
          supervisor:
            state.practicalExperienceRequirement.formDataEmployment.supervisor !== undefined
              ? {
                  ...state.practicalExperienceRequirement.formDataEmployment.supervisor,
                  employer:
                    state.practicalExperienceRequirement.formDataEmployment.supervisor?.employer !== undefined
                      ? {
                          ...state.practicalExperienceRequirement.formDataEmployment.supervisor?.employer,
                          addressLine1: '',
                          addressLine2: '',
                          state: '',
                          postalCode: '',
                          city: '',
                        }
                      : undefined,
                }
              : undefined,
        },
      },
    };
  },

  [membershipActionNames.SET_IS_OPEN_EMPLOYMENT_MODAL_FROM_INVITE]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      isOpenEmploymentModalFromInvite: action?.payload,
    };
  },

  [membershipActionNames.SET_CHANGE_MY_LEARNING_PATH]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      userChoice: {
        ...state.userChoice,
        changeMyLearningPath: action.payload,
      },
    };
  },

  [membershipActionNames.SET_IS_LEARNING_PATH_TO_CHANGE_MATCHED_PREVIOUSLY_SELECTED_PATH]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      userChoice: {
        ...state.userChoice,
        isLearningPathToChangeMatchedPreviouslySelectedPath: action.payload,
      },
    };
  },

  [membershipActionNames.SET_ENTRY_LEVELS]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      entryLevels: {
        ...action.payload.getEntryLevels,
      },
    };
  },

  [membershipActionNames.SET_OFFLINE_EXEMPTIONS_ENTRY_LEVELS]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      offlineExemptionsEntryLevels: action.payload,
    };
  },

  [membershipActionNames.ADD_MEMBERSHIP_SELECTED_BENEFIT]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      benefits: {
        ...state.benefits,
        selectedBenefits: action.payload,
      },
    };
  },

  [membershipActionNames.GET_MEMBERSHIP_INVITE_DATA]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      loading: false,
      loggedOutInviteData: {
        ...state.loggedOutInviteData,
        lastName: action.payload?.lastName,
        firstName: action.payload?.firstName,
        email: action.payload?.email,
        isAlreadyHaveAccount: action.payload?.isAlreadyHaveAccount,
      },
    }),
  },

  [membershipActionNames.SET_HAS_NOT_MEET_MINIMUM_REQUIREMENTS]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      hasNotMetMinimumRequirements: action?.payload,
    };
  },

  [membershipActionNames.SET_IS_ALLOW_TO_ADD_FCMA_IN_CART]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      isAllowToAddFcmaInCart: action?.payload,
    };
  },

  [membershipActionNames.SET_HAS_SELECTED_FCMA_DESIGNATION]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      hasSelectedFcmaDesignation: action?.payload,
    };
  },

  [membershipActionNames.SET_IS_CIMA_MEMBERSHIP_PAGE_JOURNEY]: (
    state: State.Membership,
    action: any
  ): State.Membership => {
    return {
      ...state,
      isCimaMembershipPageJourney: {
        isComingFromCimaMembershipPage: action.payload?.isComingFromCimaMembershipPage,
        previousPath: action.payload?.previousPath,
      },
    };
  },
  [membershipActionNames.SET_IS_FLP_MODAL_OPEN]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      flpModal: {
        isOpen: action.payload?.isOpen,
        modalVariant: action.payload?.modalVariant,
      },
    };
  },
  [membershipActionNames.SET_FCMA_IN_USER_CHOICE]: {
    next: (state: State.Membership, action: any): State.Membership => {
      return {
        ...state,
        userChoice: {
          ...state.userChoice,
          credentials: [action.payload],
        },
      };
    },
  },
  [membershipActionNames.GET_ATTESTATIONS]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      attestations: action.payload.queryAttestations,
    };
  },
  [membershipActionNames.SET_ATTESTATION_IS_FETCHING]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      attestationsIsFetched: action.payload,
    };
  },
  [membershipActionNames.SET_ATTESTATION]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      attestationsToQuery: {
        ...state.attestationsToQuery,
        [action.payload.categoryName as Contentful.Attestations.CategoryType]: [action.payload.categoryValue],
      },
    };
  },
  [membershipActionNames.SET_RENEWAL_PRODUCTS]: (state: State.Membership, action: any): State.Membership => {
    return {
      ...state,
      renewalProducts: {
        ...state.renewalProducts,
        ...action?.payload,
      },
    };
  },
  [membershipActionNames.SET_HAS_SELECTED_SECTION]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      hasSelectedSection: action.payload,
    }),
  },
  [membershipActionNames.SET_HAS_SELECTED_TIER]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      hasSelectedTier: action.payload,
    }),
  },
  [membershipActionNames.SET_HAS_SELECTED_TYPE]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      hasSelectedType: action.payload,
    }),
  },
  [membershipActionNames.SET_HAS_SELECTED_ADDONS]: {
    next: (state: State.Membership, action: any): State.Membership => ({
      ...state,
      hasSelectedAddOns: action.payload,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export const membershipReducer = handleActions(ACTION_HANDLERS, initialState);
