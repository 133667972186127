import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.documentUpload,
  (document: State.DocumentUpload): State.DocumentUpload => document
);

export const processingSelector = createSelector(
  rootSelector,
  (document: State.DocumentUpload): boolean => document?.loading
);

export const errorSelector = createSelector(
  rootSelector,
  (document: State.DocumentUpload): CustomErrors.GraphQLError | null => document?.error
);
