import React from 'react';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { generatePath, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Invoices } from 'mxp-schemas';
import { getPath } from 'utils';
import { getSubscriptions, resetSubscriptions, toggleSelectAllSubscriptions } from 'modules/admin/actions';
import {
  isFirmBillingSelector,
  invoiceToShowDetailsSelectorFactory,
  subscriptionsListSelector,
  subscriptionsLoadingSelector,
  isMultipleSubscriptionListSelectedSelector,
  isAllSubscriptionsSelectedSelector,
  isSubscriptionsFetchedSelector,
} from 'modules/admin/selectors';
import { ButtonEnums, Checkbox, Container } from 'components/atoms';
import { Table } from 'components/atoms/Table/Table';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { ProfileHeading } from 'components/molecules';
import { HeaderSteps } from 'components/molecules/HeaderSteps/HeaderSteps';
import { HeaderAdmin } from 'components/App/AdminApp';
import { NoSearchResults } from 'components/admin/organisms/NoSearchResults';
import { adminSearchInvoiceScreens, Routes } from 'constants/index';
import { TablePlaceholder } from 'components/atoms/TablePlaceholder/TablePlaceholder';
import { ReactComponent as RemoveIcon } from 'resources/images/ic-cancel.svg';
import { CancelInvoiceModal } from 'components/admin/molecules/CancelInvoiceModal/CancelInvoiceModal';
import { SubscriptionsDetailsTableRow } from 'components/admin/molecules/SubscriptionDetailsTableRow/SubscriptionDetailsTableRow';

export const AdminInvoicesDetailsPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { searchTerm, invoiceNumber, legalEntity } = useParams();
  const dispatch = useDispatch();
  const isSubscriptionsFetched = useSelector(isSubscriptionsFetchedSelector);

  const isAllSubscriptionsSelected = useSelector(isAllSubscriptionsSelectedSelector);
  const isMultipleSubscriptionsSelected = useSelector(isMultipleSubscriptionListSelectedSelector);

  const isFirmBilling = useSelector(isFirmBillingSelector);
  // const invoiceItems: State.InvoiceItemResult[] = useSelector(invoiceItemsListSelector);
  const subscriptions: State.SubscriptionsResult[] = useSelector(subscriptionsListSelector);
  const invoice: State.SearchInvoicesResult | null = useSelector(invoiceToShowDetailsSelectorFactory(invoiceNumber));
  const isLoading = useSelector(subscriptionsLoadingSelector);
  const isPaid: boolean = invoice?.status === Invoices.InvoiceStatus.PAID;
  const isRemoveMultipleInvoiceItemsButtonEnabled = isMultipleSubscriptionsSelected && !isPaid;

  const searchPageRoute: string = searchTerm
    ? generatePath(getPath(Routes.ADMIN_INVOICES_SEARCH_RESULTS), {
        searchTerm,
        legalEntity,
      })
    : '';
  React.useEffect(() => {
    if (invoiceNumber) {
      dispatch(getSubscriptions(invoiceNumber, legalEntity));
    }
  }, [dispatch, invoiceNumber, searchTerm, legalEntity]);

  const goToSearchPage = React.useCallback(() => {
    dispatch(
      push({
        pathname: searchPageRoute,
      })
    );
  }, [dispatch, searchPageRoute]);

  const resetInvoiceItemsHandler = React.useCallback(() => {
    dispatch(resetSubscriptions());
    goToSearchPage();
  }, [dispatch, goToSearchPage]);

  const showNoResultsError = !Boolean(subscriptions?.length) && isSubscriptionsFetched;

  const handleSelectSubscriptionsItems = React.useCallback(() => {
    dispatch(toggleSelectAllSubscriptions(isMultipleSubscriptionsSelected, isAllSubscriptionsSelected));
  }, [dispatch, isMultipleSubscriptionsSelected, isAllSubscriptionsSelected]);

  const handleCancelInvoiceClick = React.useCallback(
    (invoiceNumberVal: string) => {
      if (isFirmBilling) {
        dispatch(
          push(generatePath(getPath(Routes.ADMIN_INVOICES_DETAILS), { searchTerm, invoiceNumber: invoiceNumberVal }))
        );
      }
    },
    [dispatch, isFirmBilling, searchTerm]
  );

  const onsuccessCallBack = () => {
    dispatch(resetSubscriptions());
    dispatch(dispatch(getSubscriptions(invoiceNumber, legalEntity)));
  };

  return (
    <>
      {showNoResultsError ? (
        <>
          <HeaderAdmin />
          <NoSearchResults
            backLinkText="Back to search invoice"
            lookUpQuery={searchTerm}
            tipsList={[
              'Check the spelling of your invoice number or AICPA UID',
              'Try searching using another invoice number or AICPA UID',
            ]}
            previousPage={goToSearchPage}
          />
        </>
      ) : (
        <>
          <HeaderSteps stepIndex={1} labels={adminSearchInvoiceScreens} />
          <Container>
            <PreviousPage
              backText="Back to search Results"
              route={searchPageRoute}
              onClick={resetInvoiceItemsHandler}
            />
            <StyledProfileHeading title={invoiceNumber} />
            <StyledControlsContainer>
              <StyledIconContainer>
                {invoice && (
                  <CancelInvoiceModal
                    invoice={{ ...invoice, legalEntity }}
                    cancelInvoiceBtnTestId="remove-multiple-invoice-items"
                    cancelInvoiceBtnText="Remove Item"
                    cancelInvoiceBtnIcon={<StyledRemoveIcon />}
                    cancelInvoiceBtnVariant={ButtonEnums.variants.link}
                    disabled={!isRemoveMultipleInvoiceItemsButtonEnabled}
                    onSuccess={onsuccessCallBack}
                  />
                )}
              </StyledIconContainer>
            </StyledControlsContainer>
            {subscriptions?.length && !isLoading && invoice ? (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>
                      <CheckboxStyled
                        width="18"
                        height="18"
                        type="checkbox"
                        testId={`select-all-invoices-checkbox`}
                        onChange={handleSelectSubscriptionsItems}
                        indeterminate={isMultipleSubscriptionsSelected && !isAllSubscriptionsSelected}
                        checked={isAllSubscriptionsSelected}
                        disabled={isPaid}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>Subscription</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Cost</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {subscriptions?.map((subscription: State.SubscriptionsResult) => (
                    <SubscriptionsDetailsTableRow
                      key={subscription.subscriptionName}
                      subscriptions={subscription}
                      cancelInvoiceClick={handleCancelInvoiceClick}
                      invoice={invoice}
                    />
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <TablePlaceholder />
            )}
          </Container>
        </>
      )}
    </>
  );
};

const StyledControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledProfileHeading = styled(ProfileHeading)`
  margin-top: ${props => props.theme.pxToRem(-8)};
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    > label {
      font-size: ${props => props.theme.fontSizes.s};
      color: ${props => props.theme.colors.neutralGrey8};
      font-weight: ${props => props.theme.fontWeights.light};
    }

    &.ui.checkbox {
      display: block;
    }

    &.ui.checkbox input:indeterminate ~ label:after {
      opacity: 1;
      color: ${props => props.theme.colors.neutralWhite};
      background-color: ${props => props.theme.colors.primaryPurple};
      border: ${props => props.theme.pxToRem(-1)} solid ${props => props.theme.colors.primaryPurple};
      border-radius: ${props => props.theme.pxToRem(2)};
      width: ${({ width }) => `${width}px`};
      height: ${({ height }) => `${height}px`};
    }
  }
`;

const StyledIconContainer = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  &&& {
    height: ${props => props.theme.pxToRem(18)};
    width: ${props => props.theme.pxToRem(18)};
    margin-right: ${props => props.theme.pxToRem(10)};
    path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;
