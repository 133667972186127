import React, { FC } from 'react';
import styled from 'styled-components';

export interface ICustomDividerProps {
  borderStyle?: 'dotted' | 'dashed' | 'solid';
  borderColor?: string;
  width?: string;
}

export const CustomDivider: FC<ICustomDividerProps> = ({ borderStyle, borderColor, width }) => (
  <Divider borderStyle={borderStyle} borderColor={borderColor} width={width} />
);

const Divider = styled.div<{
  borderStyle: ICustomDividerProps['borderStyle'];
  borderColor: ICustomDividerProps['borderColor'];
  width: ICustomDividerProps['width'];
}>`
  border: ${props => props.theme.pxToRem(1)} ${props => (props.borderStyle ? props.borderStyle : 'dashed')};
  border-color: ${props => (props.borderColor ? props.borderColor : props.theme.colors.neutralGrey9)};
  width: ${props => (props.width ? props.width : '100%')};
`;
