import React from 'react';
import { isServer } from 'utils';

export enum ScriptLoadingState {
  Idle = 'idle',
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
  Blocked = 'blocked',
}

export const useScript = (src: string, className = 'C0004') => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  // idle - empty script
  // loading - script not loaded yet
  // ready - script loaded
  // error - unable to load
  const [status, setStatus] = React.useState<ScriptLoadingState>(
    src ? ScriptLoadingState.Loading : ScriptLoadingState.Idle
  );

  const OneTrustDepGroups: string = isServer ? '' : (window as any).OnetrustActiveGroups ?? '';

  React.useEffect(() => {
    if (isServer || !src) {
      setStatus(ScriptLoadingState.Idle);
      return;
    }
    let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"][data-use-script="true"]`);
    // Check OneTrust Active Group
    let OneTrustActiveGroupsStr: string = '';

    if (window !== undefined && 'OnetrustActiveGroups' in window) {
      OneTrustActiveGroupsStr = (window as any).OnetrustActiveGroups;
    }

    // if previous script load was unsuccessful, retry load
    if (script?.getAttribute('data-status') === ScriptLoadingState.Error && script.parentNode) {
      script.parentNode.removeChild(script);
      script = null;
    }
    if (!script) {
      if (OneTrustActiveGroupsStr === '' || OneTrustActiveGroupsStr.split(',').includes(className)) {
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.defer = true;
        script.setAttribute('data-status', ScriptLoadingState.Loading);
        script.setAttribute('data-use-script', 'true'); // just a marker that tag was created by useScript
        script.setAttribute('class', `optanon-category-${className}`);
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: Event) => {
          if (script) {
            const dataStatus: ScriptLoadingState =
              event?.type === 'load' ? ScriptLoadingState.Ready : ScriptLoadingState.Error;
            script.setAttribute('data-status', dataStatus);
            script.removeEventListener('load', setAttributeFromEvent);
            script.removeEventListener('error', setAttributeFromEvent);
          }
          return;
        };

        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // FallBack and Block Status for  Scripts
        setStatus(ScriptLoadingState.Blocked);
      }
    } else {
      setStatus(String(script?.getAttribute('data-status')) as ScriptLoadingState);
    }

    const setStateFromEvent = (event: Event) =>
      setStatus(event?.type === 'load' ? ScriptLoadingState.Ready : ScriptLoadingState.Error);

    if (script) {
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
    }

    return () => {
      if (!isServer && script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src, OneTrustDepGroups, className]);

  return status;
};
