// ------------------------------------
// Constants
// ------------------------------------

export enum ShareAllocationActionNames {
  SET_PROCESSING = 'shareAllocation/SET_PROCESSING',
  SET_ERROR = 'shareAllocation/SET_ERROR',
  ALLOCATE = 'shareAllocation/ALLOCATE',
}

export const initialState: State.ShareAllocation = {
  error: null,
  processing: false,
};
