import { Routes } from 'constants/index';

export type AllowedTealiumValues = string | number | string[] | number[];

export interface Modifier {
  [key: string]: AllowedTealiumValues;
}

export const UTAG_TYPES = {
  VIEW: 'view',
  LINK: 'link',
};

export interface Accessor {
  path: string;
  name: string;
  default?: string;
  processor?: (a: any, state?: any) => AllowedTealiumValues;
}

export interface EventDescribor {
  name?: string; // name of the event
  accessors: Accessor[]; // the array of the items to update in the data layer
  type?: string; // page view or event
  route?: Routes; // should this event fire on a particular route SSR?
  hideNulls?: boolean | string[]; // remove unnecessary null values in the data layer
}
