import React from 'react';
import styled from 'styled-components';
import { Salesforce, B2B } from 'mxp-schemas';
import { TablePlaceholder } from 'components/atoms';
import { FirmAdmin as FirmAdminUtils } from 'mxp-utils';
import { FirmSelectionItem } from './FirmSelectionItem';
import { ToggleSwitch } from 'components/atoms/ToggleSwitch/ToggleSwitch';

interface Props {
  isOrganizationsFetched: boolean;
  organizationsList: Salesforce.Organization[];
  firmBillingClientAdmin: string[];
  getOrganizations: () => void;
  initFirmRosterDynamoTable: (orgId: string, path: string, orgCapabilityType: string) => Promise<void>;
}

export const PageFirmSelection: React.FC<Props> = ({
  isOrganizationsFetched,
  organizationsList,
  firmBillingClientAdmin,
  getOrganizations,
  initFirmRosterDynamoTable,
}) => {
  const [selectedEntity, setSelectedEntity] = React.useState<Salesforce.LegalEntity>(
    Salesforce.LegalEntity.ASSOCIATION
  );

  // if the selected entity is AICPA then after changing of toggle it will select the CIMA, then vice versa
  const toggleSelectLegalEntity = () =>
    setSelectedEntity(
      selectedEntity === Salesforce.LegalEntity.CIMA ? Salesforce.LegalEntity.ASSOCIATION : Salesforce.LegalEntity.CIMA
    );

  React.useEffect(getOrganizations, [getOrganizations]);

  const TreeNode = (props: { organizations: Salesforce.Organization[] }) => {
    return (
      <>
        {props.organizations?.map((organization: Salesforce.Organization, index: number) => (
          <div key={organization.id}>
            <FirmSelectionItem
              orgCapabilityType={
                organization.organizationCapabilities?.type ===
                FirmAdminUtils.parseType(B2B.OrganizationCapabilityType.CIMA).sf
                  ? B2B.OrganizationCapabilityType.CIMA
                  : B2B.OrganizationCapabilityType.AICPA
              }
              key={organization.id}
              orgId={organization.id}
              title={`${organization.name} ${organization.billingAddress.state}`}
              address={`${organization.billingAddress.addressLine1}, ${organization.billingAddress.city}, ${organization.billingAddress.zipCode}`}
              locations={organization.branches?.length}
              initFirmRosterDynamoTable={
                firmBillingClientAdmin.includes(organization.id) ? initFirmRosterDynamoTable : undefined
              }
            />
            {organization?.branches && (
              <div style={{ marginLeft: '30px', marginTop: '20px', marginBottom: '20px' }}>
                <TreeNode organizations={organization?.branches} />
              </div>
            )}
          </div>
        ))}
      </>
    );
  };

  if (!isOrganizationsFetched) return <TablePlaceholder />;
  return (
    <>
      <>
        <StyledTitle>Select your role</StyledTitle>
        <ToggleSwitch
          text1={Salesforce.LegalEntity.CIMA}
          text2={Salesforce.LegalEntity.ASSOCIATION}
          value={selectedEntity === Salesforce.LegalEntity.ASSOCIATION ? 1 : 0}
          testId={'table-toggle'}
          handleChange={toggleSelectLegalEntity}
        />
      </>

      <StyledList>
        <TreeNode
          organizations={organizationsList?.filter(org => selectedEntity === org.organizationCapabilities?.type)}
        />
      </StyledList>
    </>
  );
};

const StyledTitle = styled.h3`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.l};
  margin-bottom: ${props => props.theme.pxToRem(32)};
`;

const StyledList = styled.ul`
  padding: 0;
`;
