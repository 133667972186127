import React from 'react';
import styled from 'styled-components';
import { Title } from './Title';

interface Props {
  title: string;
  desc: string;
}

export const CenterAdminTabHeader: React.FC<Props> = ({ title, desc }) => (
  <Header>
    <div>
      <Title title={title} details={desc} />
    </div>
  </Header>
);

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
