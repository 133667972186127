import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from '../Button/Button';
import { useDispatch } from 'react-redux';
import {
  setIsCredentialsJourney,
  setIsRenewalsJourney,
  setMembershipEvent,
  setSectionsCredentialsRenewal,
} from 'modules/membership';

interface ButtonRenewalLinkProps extends ButtonProps {
  testId: string;
  to: string;
  isSectionRenewal?: boolean;
  isCredentialRenewal?: boolean;
  isAicpaRenewal?: boolean;
}

export const ButtonRenewalLink: React.FC<ButtonRenewalLinkProps> = ({
  children,
  testId,
  to,
  isSectionRenewal,
  isCredentialRenewal,
  isAicpaRenewal,
  ...props
}) => {
  const dispatch = useDispatch();
  const isAnchor = to.startsWith('#');

  const handlePath = useCallback(() => {
    if (isSectionRenewal && !isAicpaRenewal) {
      dispatch(setIsRenewalsJourney(true));
      dispatch(setSectionsCredentialsRenewal({ isTriggered: true, productType: 'section-type' }));
    }
    if (isCredentialRenewal && !isAicpaRenewal) {
      dispatch(setSectionsCredentialsRenewal({ isTriggered: true, productType: 'credential-type' }));
      dispatch(setIsCredentialsJourney(true));
      dispatch(setIsRenewalsJourney(true));
      dispatch(setMembershipEvent('isClickedCredentialsSectionsRenewal', true));
    }
    if (isAicpaRenewal) {
      dispatch(setIsRenewalsJourney(true));
      dispatch(setMembershipEvent('isClickedCredentialsSectionsRenewal', true));
    }
  }, [isAicpaRenewal, isSectionRenewal, isCredentialRenewal, dispatch]);

  return isAnchor ? (
    <Button tabIndex={0} testId={testId} as="a" href={to} {...props} onClick={handlePath}>
      {children}
    </Button>
  ) : (
    <Button tabIndex={0} testId={testId} as={Link} to={to} {...props} onClick={handlePath}>
      {children}
    </Button>
  );
};
