import React from 'react';
import { Content } from 'mxp-schemas';
import styled from 'styled-components';
import { Lock } from 'components/atoms/svg';
import { ReactComponent as IconUnlock } from 'resources/images/ic-lock-off.svg';

interface Props {
  lockStatus: string;
}

export const LockStateIcon: React.FC<Props> = React.memo(({ lockStatus }) => {
  switch (lockStatus) {
    case Content.ContentLockStatus.LOCKED:
      return <StyledLockIcon />;
    case Content.ContentLockStatus.UNLOCKED:
      return <StyledUnlockIcon />;
    default:
      return <></>;
  }
});

const StyledLockIcon = styled(Lock)`
  position: relative;
  top: ${props => props.theme.pxToRem(6)};
  margin-right: ${props => props.theme.pxToRem(6)};
  height: ${props => props.theme.pxToRem(25)};
  width: ${props => props.theme.pxToRem(25)};
`;

const StyledUnlockIcon = styled(IconUnlock)`
  position: relative;
  margin-right: ${props => props.theme.pxToRem(6)};
  top: ${props => props.theme.pxToRem(6)};
  height: ${props => props.theme.pxToRem(25)};
  width: ${props => props.theme.pxToRem(25)};
`;
