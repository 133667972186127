import React from 'react';

interface FeatureToggleWrapperProps {
  toggle: string;
  children: any;
  show: boolean | undefined;
}

export const FeatureToggleWrapper: React.FC<FeatureToggleWrapperProps> = React.memo(({ children, show }) =>
  show ? <>{children}</> : null
);
