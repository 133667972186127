import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Container } from 'components/atoms';
import { ReactComponent as Tick } from 'resources/images/icon-live-ic-done.svg';
import { ReactComponent as Cross } from 'resources/images/icon-live-ic-clear.svg';
import { User } from 'mxp-schemas';
import { learningPathwaySelector } from 'modules/user/selectors';
import { isExamScoreShown, isDistinction } from 'utils';

interface Props {
  data: User.StudentExamSectionResults;
}

export const CimaExamResultsSummary: React.FC<Props> = React.memo(({ data }: any) => {
  const learningPathway = useSelector(learningPathwaySelector);
  const showExamScore = isExamScoreShown(data, learningPathway);
  const isResultDistinction = isDistinction(data);

  return (
    <StyledExamSummary>
      YOUR EXAM
      <StyledSummaryContainer>
        <StyledResult>
          Result
          <StyledMiniBadge color={data.status}>
            {data.status === User.StudentExamResultStatus.PASS ? (
              <StyledTick />
            ) : data.status === User.StudentExamResultStatus.FAIL ? (
              <StyledCross />
            ) : null}
          </StyledMiniBadge>
          {data.status === User.StudentExamResultStatus.NO_SHOW ||
          data.status === User.StudentExamResultStatus.NDA_REFUSED ? (
            <StyledNoShowResultData>{data.status}</StyledNoShowResultData>
          ) : (
            <StyledResultData>{isResultDistinction ? 'Distinction' : data.status}</StyledResultData>
          )}
        </StyledResult>
        {showExamScore ? (
          <StyledScore>
            Score
            <StyledScoreData>
              {data.status === User.StudentExamResultStatus.NO_SHOW ||
              data.status === User.StudentExamResultStatus.NDA_REFUSED
                ? '-'
                : data.overallExamScore}
            </StyledScoreData>
          </StyledScore>
        ) : null}
        <StyledDateTime>
          Date and Time
          {data.examDate && <StyledDateTimeData>{data.examDate}</StyledDateTimeData>}
        </StyledDateTime>
      </StyledSummaryContainer>
    </StyledExamSummary>
  );
});

const StyledSummaryContainer = styled.div`
  display: flex;
  font-weight: ${props => props.theme.fontWeights.bold};
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: block;
  }
`;

const StyledTick = styled(Tick)`
  position: relative;
  bottom: ${props => props.theme.pxToRem(4)};
  left: ${props => props.theme.pxToRem(1)};
  width: 90%;
  height: 90%;
`;

const StyledCross = styled(Cross)`
  position: relative;
  bottom: ${props => props.theme.pxToRem(4)};
  left: ${props => props.theme.pxToRem(1)};
  width: 90%;
  height: 90%;
`;

const StyledMiniBadge = styled.div<{ color: string }>`
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
  margin: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(0)};
  border-radius: 50%;
  position: relative;
  bottom: ${props => props.theme.pxToRem(2)};

  ${({ color }) =>
    color === 'Pass' &&
    css`
      border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.secondaryTeal};
      background-color: ${props => props.theme.colors.secondaryTeal};
    `}

  ${({ color }) =>
    color === 'Fail' &&
    css`
      border: solid ${props => props.theme.pxToRem(1)} #ac8208;
      background-color: #ac8208;
    `}
`;

const StyledExamSummary = styled(Container)`
  width: ${props => props.theme.pxToRem(303)};
  height: ${props => props.theme.pxToRem(122)};
  margin: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(192)} ${props => props.theme.pxToRem(14)} 0;
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(12)};
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(1.22)};
  color: ${props => props.theme.colors.neutralGrey8};
  ${props => props.theme.mediaQueries.mobileOnly} {
    height: auto;
  }
`;

const StyledResult = styled.div`
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => props.theme.pxToRem(14)} ${props => props.theme.pxToRem(83)} ${props => props.theme.pxToRem(4)} 0;
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
  display: inline;
  color: ${props => props.theme.colors.neutralGrey8};
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: block;
    margin-bottom: ${props => props.theme.pxToRem(48)};
  }
`;

const StyledNoShowResultData = styled.div`
  height: ${props => props.theme.pxToRem(24)};
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: 300;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralGrey8};
  position: relative;
  bottom: ${props => props.theme.pxToRem(32)};
`;

const StyledResultData = styled.div`
  width: ${props => props.theme.pxToRem(52)};
  height: ${props => props.theme.pxToRem(24)};
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: 300;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralGrey8};
  position: relative;
  bottom: ${props => props.theme.pxToRem(32)};
  left: ${props => props.theme.pxToRem(26)};
`;

const StyledScore = styled.div`
  width: ${props => props.theme.pxToRem(46)};
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => props.theme.pxToRem(14)} ${props => props.theme.pxToRem(83)} ${props => props.theme.pxToRem(4)} 0;
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralGrey8};
  display: inline;
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: block;
    margin-bottom: ${props => props.theme.pxToRem(48)};
  }
`;

const StyledScoreData = styled.div`
  width: ${props => props.theme.pxToRem(27)};
  height: ${props => props.theme.pxToRem(24)};
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: 300;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralGrey8};
  position: relative;
  top: ${props => props.theme.pxToRem(4)};
`;

const StyledDateTime = styled.div`
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => props.theme.pxToRem(14)} ${props => props.theme.pxToRem(83)} ${props => props.theme.pxToRem(4)} 0;
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralGrey8};
  display: inline;
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: block;
    margin-bottom: ${props => props.theme.pxToRem(48)};
  }
`;

const StyledDateTimeData = styled.div`
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(52)};
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: 300;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralGrey8};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(300)};
  }
`;
