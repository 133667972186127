import { CheckoutCountriesListHash } from 'mxp-utils';
import { Salesforce } from 'mxp-schemas';

export enum AddressActionNames {
  ADD_ADDRESS = 'addressBook/ADD_ADDRESS',
  GET_COMPLETE_ADDRESS_VALIDATION = 'addressBook/GET_COMPLETE_ADDRESS_VALIDATION',
  SAVE_NEW_ADDRESS = 'addressBook/SAVE_NEW_ADDRESS',
  SET_SAVED_SHIPPING_ADDRESS_EDIT = 'address/SET_SAVED_SHIPPING_ADDRESS_EDIT',
  SET_SECONDARY_ADDRESS_NEEDED = 'addressBook/SET_SECONDARY_ADDRESS_NEEDED',
  SET_PROFILE_CACHE_ADDRESS = 'addressBook/SET_PROFILE_CACHE_ADDRESS',
  DELETE_ADDRESS = `addressBook/DELETE_ADDRESS`,
  LOADING = `addressBook/LOADING`,
}

export const addressDefault: State.AddressState = {
  profileCacheAddress: {
    firstName: '',
    lastName: '',
    company: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: CheckoutCountriesListHash.USA.ISOAlpha3Code,
    addressType: Object.values(Salesforce.HomeAddressType)[0] as any,
    isPrimary: true,
  },
  loading: false,
  isProfileCacheAddressValid: true,
  isSecondaryAddressNeeded: false,
  isEmbargoed: false,
  clientAddresses: [],
  smartystreetsValidation: null,
};
