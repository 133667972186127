import React from 'react';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import styled from 'styled-components';
import { InfoIconPopup } from '../InfoIconPopup';

interface Props {
  id: string;
  testId: string;
  checked: boolean;
  onChange: (event: React.SyntheticEvent<HTMLElement>) => void;
  label: string;
  infoLabel?: string;
  subInfoData?: string;
}

export const Consent: React.FC<Props> = ({ id, testId, checked, onChange, label, infoLabel, subInfoData }) => {
  return (
    <StyledCheckboxContainer>
      <StyledCheckbox id={id} testId={testId} checked={checked} onChange={onChange} label={label} />
      {infoLabel && <InfoIconPopup data={infoLabel} subData={subInfoData} size="l" minWidth={315} />}
    </StyledCheckboxContainer>
  );
};

const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.pxToRem(15)};
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: ${props => props.theme.pxToRem(15)};
  padding-right: ${props => props.theme.pxToRem(10)};
  &.ui.checkbox label {
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.light};
    color: ${props => props.theme.colors.neutralGrey8};
    height: 100%;
  }
  &.ui.checkbox input:checked ~ label {
    font-weight: ${props => props.theme.fontWeights.light};
  }
  &.ui.checkbox input ~ label {
    line-height: ${props => props.theme.pxToRem(22)};
  }
  &.ui.checkbox label:before {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
  }
  &.ui.checkbox input:checked ~ label:before {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
  }
  &.ui.checkbox input:checked ~ label:after {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
  }
  &.ui.checkbox input:focus ~ label:before {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
  }
`;
