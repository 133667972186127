import React from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components';

interface Props {
  isOpen: boolean;
  heading: string;
  description: string;
  isLoading?: boolean;
  onClose: () => void;
  handleModalConfirm: () => void;
  handleModalCancel: () => void;
}

export const NoMembershipModal: React.FC<Props> = ({
  isOpen,
  heading,
  description,
  isLoading,
  onClose,
  handleModalConfirm,
  handleModalCancel,
}) => {
  const confirmNode = (
    <StyledButton
      size={ButtonEnums.sizes.medium}
      testId="cancel"
      variant={ButtonEnums.variants.primary}
      onClick={handleModalConfirm}
      loading={isLoading}
    >
      Confirm
    </StyledButton>
  );

  const cancelNode = (
    <StyledButton
      size={ButtonEnums.sizes.medium}
      testId="proceed"
      variant={ButtonEnums.variants.secondary}
      onClick={handleModalCancel}
    >
      Cancel
    </StyledButton>
  );

  return (
    <StyledModal
      showCloseCross={true}
      testId="no-membership-modal"
      heading={heading}
      open={isOpen}
      onClose={onClose}
      confirmNode={confirmNode}
      cancelNode={cancelNode}
    >
      <StyledText>{description}</StyledText>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  & {
    max-width: ${props => props.theme.pxToRem(590)};
  }
  && .ui.modal ${props => props.theme.mediaQueries.mobileOnly} {
    width: 95%;
  }
`;

const StyledText = styled.p`
  &&& {
    line-height: 1.57;
    padding: ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(16)} 0
      ${props => props.theme.pxToRem(20)};
    font-weight: ${props => props.theme.fontWeights.light};
    font-size: ${props => props.theme.fontSizes.xs};
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(170)};
    height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;
