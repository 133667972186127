import React from 'react';
import styled from 'styled-components';
import { Heading, Grid, Container } from 'components/atoms';
import { isColorDark } from 'utils/colorHelpers';
import { Expandable } from 'components/atoms/Expandable/Expandable';
import { Plus, Minus } from 'components/atoms/svg';
import { Contentful } from 'mxp-schemas';
import { BlockAccordionComponentList } from './BlockAccordionComponentList';
import {
  ExternalLinkStyles,
  ExternalLinkStylesDark,
  DefaultInlineLinkStyles,
  InlineLinkOnDarkBgStyles,
} from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';
interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
  isFullPagePagination: boolean;
}

export const BlockAccordion: React.FC<Props> = ({ modularBlock, headingType, isFullPagePagination }) => {
  const block = modularBlock as Contentful.ModularContent.AccordionBlock;
  const isBgColorDark: boolean = isColorDark(block.bgColor);

  const getBodyContent = (component: any): React.ReactNode => {
    return (
      <StyledContainer>
        <StyledAccordionDescription
          isBgColorDark={isBgColorDark}
          dangerouslySetInnerHTML={{ __html: component.description || '' }}
          tabIndex={0}
        />
        <BlockAccordionComponentList components={component.componentList} isBgColorDark={isBgColorDark} />
      </StyledContainer>
    );
  };

  return (
    <MainColumn isBgColorDark={isBgColorDark}>
      <StyledGrid isFullPagePagination={isFullPagePagination}>
        <Grid.Row verticalAlign="middle" centered={true}>
          <StyledGridColumn width={11} textAlign={'center'}>
            <StyledHeaderDescription isBgColorDark={isBgColorDark}>
              {block.header && (
                <StyledHeading
                  as={headingType}
                  dangerouslySetInnerHTML={{ __html: block.header }}
                  isBgColorDark={isBgColorDark}
                  tabIndex={0}
                />
              )}
              {block.description && (
                <StyledParagraph
                  dangerouslySetInnerHTML={{ __html: block.description }}
                  isBgColorDark={isBgColorDark}
                  tabIndex={0}
                />
              )}
            </StyledHeaderDescription>
          </StyledGridColumn>
          {block?.landingPageSubBlocks?.map((item: Contentful.LandingPages.LandingPageSubBlock, idx: number) => (
            <StyledExpandableWrapper key={`landingPage-block-accordion-${idx}`} width={16}>
              <Expandable
                isBgColorDark={isBgColorDark}
                items={[
                  {
                    id: 1,
                    heading: (
                      <StyledAccordionTitle
                        isBgColorDark={isBgColorDark}
                        dangerouslySetInnerHTML={{ __html: item.header || '' }}
                        tabIndex={0}
                      />
                    ),
                    content: getBodyContent(item),
                  },
                ]}
                expandIcon={<Plus testId={`landingpage-expand-icon-${1}`} />}
                collapseIcon={<Minus testId={`landingpage-collapse-icon-${1}`} />}
              />
            </StyledExpandableWrapper>
          ))}
        </Grid.Row>
      </StyledGrid>
    </MainColumn>
  );
};
const MainColumn = styled(Container)<{
  isBgColorDark: boolean;
}>`
  .rich-text-external-link {
    ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${props => (props.isBgColorDark ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&&& {
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledHeaderDescription = styled.div<{ isBgColorDark: boolean }>`
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
`;

const StyledHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  margin-bottom: ${props => props.theme.pxToRem(16)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.l};
    line-height: 1.33;
  }
  p {
    line-height: 1.38;
    margin: 0;
  }
  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  p {
    line-height: 1.33;
  }
  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(12)};
    font-size: ${props => props.theme.fontSizes.s};
    line-height: 1.5;
  }
`;

const StyledExpandableWrapper = styled(Grid.Column)`
  &&&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(-1)}!important;
    border-top: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: 0 !important;
    }
    &:last-child {
      border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
    }
  }
`;

const StyledAccordionTitle = styled.h3<{ isBgColorDark: boolean }>`
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
  margin: 0;
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const StyledAccordionDescription = styled.div<{ isBgColorDark: boolean }>`
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.light};
    color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  }

  p {
    line-height: ${props => props.theme.pxToRem(32)};
    max-width: ${props => props.theme.pxToRem(930)};
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
  & table {
    width: 100%;
    text-align: left;
    margin-bottom: ${props => props.theme.pxToRem(20)};
    border-collapse: collapse;
    color: black;

    tbody {
      tr:nth-child(odd) {
        background-color: ${props => props.theme.colors.neutralWhite};
      }
    }
    th {
      padding: ${props => props.theme.pxToRem(6)} ${props => props.theme.pxToRem(12)};
      font-size: ${props => props.theme.fontSizes.xs};
    }

    tr {
      background-color: ${props => props.theme.colors.neutralGrey1};
    }

    td {
      padding: ${props => props.theme.pxToRem(6)} ${props => props.theme.pxToRem(12)};
      border-bottom: 1px solid ${props => props.theme.colors.neutralGrey3};
      font-size: ${props => props.theme.fontSizes.s};
    }
  }
`;

const StyledContainer = styled.div`
  &&&&& {
    padding-bottom: ${props => props.theme.pxToRem(32)};
  }
`;

const StyledGrid = styled(Grid)<{ isFullPagePagination: boolean }>`
  &&&&& {
    overflow-y: ${props => (props.isFullPagePagination ? 'auto' : 'visible')};
    margin-left: 0;
    margin-right: 0;
  }
`;
