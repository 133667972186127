import { App, User } from 'mxp-schemas';
import * as Sentry from '@sentry/browser';
import { handleActions, Action, combineActions } from 'redux-actions';
import {
  setDataAction,
  setModifiedHashAction,
  setPaginationAction,
  setIsLoading,
  getFirmMembers,
  generateQuote,
  invalidateQuote,
  initQuoteGeneration,
  getOrganizations,
  getOrganizationAdminsByRole,
  initFirmRosterDynamoTable,
  saveTableChanges,
  getFirmMembersInvites,
  toggleIsPaidByFirm,
  uploadFirmRosterClientAdminsInvites,
  upgradeFirmRosterEmployeeInvites,
  removeFirmRosterMember,
  removeMembershipInvite,
  sendFirmRosterInviteReminder,
  sendMembershipUpgradeReminder,
  getCSVData,
  fetchAddons,
  fetchSections,
  fetchCredentials,
  resetTableState,
  saveFirmBillingQuoteItems,
  removeFirmBillingQuoteItems,
  getFirmBillingInvoices,
  generateInvoice,
  getInvoiceFile,
  getGenerateInvoiceModalInfo,
  applyDiscountCodeToQuote,
  removeDiscountCodeFromQuote,
  setPersonalAddonsModalUserId,
  getRenewalSeason,
  saveFirmBillingBulkModalQuoteItems,
  toggleIsGenerateInvoiceModalOpen,
  setModifiedPositionAction,
  getFirmAdminOrganizationDetails,
  setIsRefreshFirmData,
  setFirmAdminEvent,
} from 'modules/firmAdmin/actions';
import { initialState, initData, FirmAdminActionNames } from 'modules/firmAdmin/constants';
import { FirmAdminTableType } from 'constants/firm-admin';
import { emptyObject } from 'utils';

if (
  [
    setDataAction,
    setPaginationAction,
    getFirmMembers,
    generateQuote,
    invalidateQuote,
    initQuoteGeneration,
    getOrganizations,
    getOrganizationAdminsByRole,
    initFirmRosterDynamoTable,
    getFirmMembersInvites,
    uploadFirmRosterClientAdminsInvites,
    removeFirmRosterMember,
    removeMembershipInvite,
    sendFirmRosterInviteReminder,
    sendMembershipUpgradeReminder,
    getCSVData,
    fetchSections,
    fetchCredentials,
    fetchAddons,
    resetTableState,
    saveFirmBillingQuoteItems,
    removeFirmBillingQuoteItems,
    getFirmBillingInvoices,
    upgradeFirmRosterEmployeeInvites,
    generateInvoice,
    getInvoiceFile,
    applyDiscountCodeToQuote,
    removeDiscountCodeFromQuote,
    setPersonalAddonsModalUserId,
    getRenewalSeason,
  ].includes(undefined)
) {
  const message: string = 'firmAdmin module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [combineActions(getFirmMembers, setDataAction, getFirmMembersInvites, getFirmBillingInvoices) as any]: (
    state: FirmAdmin.Root,
    action: Action<{
      type: FirmAdmin.FirmAdminTableType;
      modifier: Partial<FirmAdmin.Data<FirmAdmin.FirmMember | FirmAdmin.Invite | FirmAdmin.FirmBillingInvoice>>;
    }>
  ): FirmAdmin.Root => {
    const isGetFirmBillingInvoiceAction = action.type === FirmAdminActionNames.GET_FIRM_BILLING_INVOICES;
    const { type, modifier } = action.payload;
    const stateData = state.data[type];
    const statePagination = state.pagination[type];
    const modifierHash = modifier.hash;
    const modifierDataLoaded = modifier.isDataLoaded;
    const isDataLoaded = isGetFirmBillingInvoiceAction ? true : modifierDataLoaded ?? stateData.isDataLoaded;
    return {
      ...state,
      data: {
        ...state.data,
        [type]: {
          ...stateData,
          ...modifier,
          // Readme. toDisplay is synthetic property for pagination feature
          ...(modifierHash && {
            hash: {
              ...Object.keys(stateData.hash || emptyObject).reduce((acc: any, key: string) => {
                if (!stateData.selectedIds.includes(key)) return acc;
                return { ...acc, [key]: { ...stateData.hash[key], toDisplay: false } };
              }, {}),

              // TODO. Looping twice, could be pushed as array
              ...Object.keys(modifierHash || emptyObject).reduce(
                (acc: any, key: string) => ({ ...acc, [key]: { ...modifierHash[key], toDisplay: true } }),
                {}
              ),
            },
          }),
          isFetched: true,
          isLoading: false,
          isDataLoaded,
        },
      },
      pagination: isGetFirmBillingInvoiceAction
        ? {
            ...state.pagination,
            [type]: {
              ...statePagination,
              filters: {
                ...statePagination.filters,
                isPaidInvoiceFetchedData: statePagination.filters.isPaidInvoice,
              },
            },
          }
        : { ...state.pagination },
    };
  },
  [setIsLoading]: (state: FirmAdmin.Root, action: Action<FirmAdmin.FirmAdminTableType>): FirmAdmin.Root => {
    const type = action.payload;
    const stateData = state.data[type];
    return {
      ...state,
      data: {
        ...state.data,
        [type]: {
          ...stateData,
          isLoading: true,
          hash: {},
        },
      },
    };
  },
  [setModifiedHashAction]: (
    state: FirmAdmin.Root,
    action: Action<{
      type: FirmAdmin.FirmAdminTableType;
      userId: string;
      modifier: Partial<FirmAdmin.FirmMember>;
    }>
  ): FirmAdmin.Root => {
    const { type, userId, modifier } = action.payload;
    const modifiedHash = state.data[type].modifiedHash;

    const nextModifiedItem = Object.keys(modifier).reduce((acc, key: string) => {
      if ((modifier as any)[key] === undefined) {
        delete acc[key];
        return acc;
      }
      return { ...acc, [key]: (modifier as any)[key] };
    }, modifiedHash[userId] || {});

    if (Object.values(nextModifiedItem).filter(Boolean).length) {
      modifiedHash[userId] = nextModifiedItem;
    } else {
      delete modifiedHash[userId];
    }

    return {
      ...state,
      data: {
        ...state.data,
        [type]: {
          ...state.data[type],
          modifiedHash: { ...modifiedHash },
        },
      },
    };
  },

  [setPaginationAction]: (
    state: FirmAdmin.Root,
    action: Action<{
      type: FirmAdmin.FirmAdminTableType;
      modifier: Partial<FirmAdmin.Pagination>;
    }>
  ): FirmAdmin.Root => {
    const { type, modifier } = action.payload;
    const { selectedIds, modifiedHash } = state.data[type];

    const isFirmBillingInvoicesTable =
      type === FirmAdminTableType.FIRM_BILLING_INVOICES || type === FirmAdminTableType.FIRM_BILLING_INVOICES_CIMA;
    const isOffsetPropertyExist = modifier.hasOwnProperty('offset');

    return {
      ...state,
      data:
        // FIRM_BILLING_INVOICES table uses client side search
        isFirmBillingInvoicesTable
          ? state.data
          : {
              ...state.data,
              [type]: {
                ...state.data[type],
                selectedIds: isOffsetPropertyExist ? selectedIds : [],
                modifiedHash: isOffsetPropertyExist ? modifiedHash : {},
                isLoading: true,
                isFetched: false,
              },
            },
      pagination: {
        ...state.pagination,
        [type]: {
          ...state.pagination[type],
          ...modifier,
          offset: isOffsetPropertyExist ? modifier.offset : 0,
        },
      },
    };
  },

  [resetTableState]: (
    state: FirmAdmin.Root,
    action: Action<{
      type: FirmAdmin.FirmAdminTableType;
    }>
  ) => {
    const copiedInitData = JSON.parse(JSON.stringify(initData));
    const copiedInitPagination = JSON.parse(JSON.stringify(initialState.pagination[action.payload.type]));
    return {
      ...state,
      data: {
        ...state.data,
        [action.payload.type]: copiedInitData,
      },
      pagination: {
        ...state.pagination,
        [action.payload.type]: copiedInitPagination,
      },
    };
  },

  [getOrganizations]: (
    state: FirmAdmin.Root,
    action: Action<{ organizations: FirmAdmin.Organization[] }>
  ): FirmAdmin.Root => ({
    ...state,
    organizations: {
      ...state.organizations,
      list: action.payload.organizations,
      isOrganizationsFetched: true,
    },
  }),

  [getFirmAdminOrganizationDetails]: (
    state: FirmAdmin.Root,
    action: Action<{ getFirmAdminOrganizationDetails: User.FirmAdminTypes }>
  ): FirmAdmin.Root => ({
    ...state,
    orgDetails: {
      ...state.orgDetails,
      ...action.payload.getFirmAdminOrganizationDetails,
      orgStatus: action.payload.getFirmAdminOrganizationDetails?.orgStatus || User.OrgStatus.INITIALIZED,
      isFetched: true,
    },
  }),

  [setIsRefreshFirmData]: (state: FirmAdmin.Root, action: any): FirmAdmin.Root => ({
    ...state,
    orgDetails: {
      ...state.orgDetails,
      isRefreshRequested: action.payload,
      orgStatus: User.OrgStatus.INITIALIZED,
    },
  }),

  [getOrganizationAdminsByRole]: (
    state: FirmAdmin.Root,
    action: Action<{ organizationAdmins: FirmAdmin.Contact[][] }>
  ): FirmAdmin.Root => ({
    ...state,
    organizationAdmins: {
      ...state.organizationAdmins,
      list: action.payload.organizationAdmins,
      isOrganizationAdminsFetched: true,
    },
  }),

  [saveTableChanges]: (state: FirmAdmin.Root, action: Action<FirmAdmin.FirmAdminTableType>): FirmAdmin.Root => ({
    ...state,
    data: {
      ...state.data,
      [action.payload]: { ...state.data[action.payload], modifiedHash: {} },
    },
  }),

  [fetchSections]: {
    next: (state: FirmAdmin.Root, action: Action<FirmAdmin.TransformedProduct[]>): FirmAdmin.Root => ({
      ...state,
      sections: action.payload,
    }),
  },

  [fetchCredentials]: {
    next: (state: FirmAdmin.Root, action: Action<FirmAdmin.TransformedProduct[]>): FirmAdmin.Root => ({
      ...state,
      credentials: action.payload,
    }),
  },

  [fetchAddons]: {
    next: (state: FirmAdmin.Root, action: Action<FirmAdmin.TransformedProduct[]>): FirmAdmin.Root => ({
      ...state,
      addons: action.payload,
    }),
  },

  [combineActions(
    toggleIsPaidByFirm,
    removeFirmRosterMember,
    removeMembershipInvite,
    sendFirmRosterInviteReminder,
    sendMembershipUpgradeReminder
  ) as any]: (state: FirmAdmin.Root, action: Action<FirmAdmin.FirmAdminTableType>): FirmAdmin.Root => ({
    ...state,
    data: {
      ...state.data,
      [action.payload]: { ...state.data[action.payload], selectedIds: [] },
    },
  }),

  [initFirmRosterDynamoTable]: (state: FirmAdmin.Root): FirmAdmin.Root => {
    const copiedInitData = JSON.parse(JSON.stringify(initialState));

    return {
      ...state,
      data: copiedInitData.data,
      pagination: copiedInitData.pagination,
    };
  },

  [setPersonalAddonsModalUserId]: (state: FirmAdmin.Root, action: Action<string | null>): FirmAdmin.Root => ({
    ...state,
    personalAddonsModalUserId: action.payload,
  }),

  [getRenewalSeason]: {
    next: (state: FirmAdmin.Root, action: Action<App.GetRenewalSeasonInfoResponse>): FirmAdmin.Root => ({
      ...state,
      isRenewalSeason: action.payload.isRenewalSeason,
    }),
  },

  [toggleIsGenerateInvoiceModalOpen]: (state: FirmAdmin.Root): FirmAdmin.Root => ({
    ...state,
    isGenerateInvoiceModalOpened: !state.isGenerateInvoiceModalOpened,
  }),

  [setFirmAdminEvent]: {
    next: (state: FirmAdmin.Root, action: any): FirmAdmin.Root => {
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.event]: action.payload.value,
        },
      };
    },
  },
};

export {
  setDataAction,
  setModifiedHashAction,
  setPaginationAction,
  getFirmMembers,
  generateQuote,
  invalidateQuote,
  initQuoteGeneration,
  getOrganizations,
  getOrganizationAdminsByRole,
  initFirmRosterDynamoTable,
  saveTableChanges,
  getFirmMembersInvites,
  toggleIsPaidByFirm,
  uploadFirmRosterClientAdminsInvites,
  upgradeFirmRosterEmployeeInvites,
  removeFirmRosterMember,
  removeMembershipInvite,
  sendFirmRosterInviteReminder,
  sendMembershipUpgradeReminder,
  getCSVData,
  fetchAddons,
  fetchSections,
  fetchCredentials,
  resetTableState,
  saveFirmBillingQuoteItems,
  removeFirmBillingQuoteItems,
  getFirmBillingInvoices,
  generateInvoice,
  getInvoiceFile,
  getGenerateInvoiceModalInfo,
  applyDiscountCodeToQuote,
  removeDiscountCodeFromQuote,
  setPersonalAddonsModalUserId,
  saveFirmBillingBulkModalQuoteItems,
  toggleIsGenerateInvoiceModalOpen,
  setModifiedPositionAction,
  getFirmAdminOrganizationDetails,
  setIsRefreshFirmData,
  setFirmAdminEvent,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
