import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicationSelector, isUserMemberSelector } from 'modules/user/selectors';
import { goToPreviousRoute } from 'modules/layouts';
import styled from 'styled-components/macro';
import {
  Button as StyledButton,
  ButtonEnums,
  Divider,
  Modal,
  OnlyDesktopCSS,
  OnlyMobileCSS,
  ButtonVariants,
} from 'components/atoms';

import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { StrictDropdownProps } from 'semantic-ui-react';
import { MembershipTypes } from 'mxp-schemas';
import { updateApplication } from 'modules/user/actions';
import { DropdownWithLabel } from 'components/molecules';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
interface Props {
  isOpenModal: boolean;
  toggleRoleSelection: (isOpen: boolean) => void;
}

export const RoleModal: React.FC<Props> = ({ isOpenModal, toggleRoleSelection }) => {
  const dispatch = useDispatch();
  const application = useSelector(applicationSelector);
  const isUserMember = useSelector(isUserMemberSelector);
  const isNewUser = !isUserMember;

  const [roleSelected, setRole]: any = useState('');
  const [employmentStatusSelected, setEmploymentStatus]: any = useState('');
  const [isOpen, setOpen]: any = useState(false);
  const [options, setOptions]: any = useState([]);

  const isDisabled = !roleSelected || !employmentStatusSelected;

  useEffect(() => {
    if (isNewUser) {
      setOptions([MembershipTypes.EmploymentStatus[1]]);
    } else if (isUserMember) {
      setOptions(MembershipTypes.EmploymentStatus.slice(1, 3));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOpen(isOpenModal);
  }, [isOpenModal]);

  const goToPreviousPage = () => {
    dispatch(goToPreviousRoute());
  };

  const onClickConfirm = () => {
    dispatch(
      updateApplication({
        ...application,
        applicationProgress: '',
        status: MembershipTypes.ApplicationStatusEnum.NOT_SUBMITTED,
        employmentRole: roleSelected,
      })
    );
    toggleRoleSelection(false);
    onCloseModal();
  };

  const onChangeRole = useCallback((event: React.SyntheticEvent, data: StrictDropdownProps) => {
    setRole(data.value);
  }, []);

  const onChangeEmploymentStatus = useCallback((event: React.SyntheticEvent, data: StrictDropdownProps) => {
    setEmploymentStatus(data.value);
  }, []);

  const onCloseModal = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <StyledModal size="tiny" heading="Which role applies to you?" open={isOpen}>
        <CloseButton>
          <StyledCloseIcon onClick={goToPreviousPage} />
        </CloseButton>
        <Spacer />
        <CenteredText>
          You’ll receive content specific to this role, including information about how you can excel in it and advance
          your career. Please note: This role cannot be changed later in the application process.
        </CenteredText>
        <StyledCenter>
          <StyledDropdownWithLabel
            onChange={onChangeRole}
            placeholder="Select Role from list"
            options={MembershipTypes.Roles}
            value={roleSelected}
          />
          <StyledDropdownWithLabel
            onChange={onChangeEmploymentStatus}
            placeholder="Select Employment Status from list"
            options={options}
            value={employmentStatusSelected}
          />
        </StyledCenter>
        <DividerWithBottomMargin />
        <CenteredButtons>
          <DesktopStyled>
            <UnderstandButton onClick={onClickConfirm} isDisabled={isDisabled} />
          </DesktopStyled>
          <MobileStyled>
            <UnderstandButton onClick={onClickConfirm} isDisabled={isDisabled} />
          </MobileStyled>
        </CenteredButtons>
      </StyledModal>
    </>
  );
};

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;
const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledDropdownWithLabel = styled(DropdownWithLabel)`
  padding: ${props => props.theme.pxToRem(8)};
  margin-top: ${props => props.theme.pxToRem(15)};
`;

const StyledCenter = styled.div`
  text-align: center;
`;
interface ModalButtonProps {
  onClick: () => void;
  isDisabled: boolean;
  variant?: ButtonVariants;
}

const { pageName } = getPageNameAndSiteSection();
const UnderstandButton: React.FC<ModalButtonProps> = ({ onClick, isDisabled }) => (
  <StyledUnderstandButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId="understand-ethics-modal"
    disabled={isDisabled}
    others={{ pageName: `${pageName}:employment` }}
  >
    Confirm
  </StyledUnderstandButton>
);

const StyledUnderstandButton = styled(StyledButton)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
