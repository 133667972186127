import { Button, ButtonEnums, Modal } from 'components/atoms';
import { cancelRefundOrder } from 'modules/admin/actions';
import { organizationOrderRefundCancelSelector, isB2BSelector } from 'modules/admin/selectors';
import { Product } from 'mxp-schemas';
import React, { Dispatch, memo, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconExclamation } from 'resources/images/ic-exclamation.svg';
import styled from 'styled-components';
import { FirmAdmin as FirmAdminUtils } from 'mxp-utils';
import { areAllTruthy } from 'utils';

interface Props {
  isOpen: boolean;
  requestAction: string;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: () => void;
}

interface ModalBodyProps {
  requestAction: string;
  refundAmount: number;
}

const ModalBody: React.FC<ModalBodyProps> = ({ requestAction, refundAmount }) => {
  switch (requestAction) {
    case Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION:
      return (
        <>
          <StyledCenteredDiv>
            A total refund {refundAmount ? <StyledBold>{refundAmount}</StyledBold> : ''}
            (incl. sales tax) will be issued for this product.
          </StyledCenteredDiv>
        </>
      );

    case Product.RefundOrCancellationEnums.CANCEL_ONLY:
      return <StyledCenteredDiv>This product will be cancelled.</StyledCenteredDiv>;

    default:
      return (
        <>
          <StyledCenteredDiv>
            A total refund {refundAmount ? <StyledBold>{refundAmount?.toFixed(2)}</StyledBold> : ''}
            (incl. sales tax) will be issued and this product will be cancelled.
          </StyledCenteredDiv>
        </>
      );
  }
};

export const ProductCancellationOrRefundModal: React.FC<Props> = memo(
  ({ isOpen, setIsModalOpen, requestAction, onSubmit }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const { requestedAction, reason, refundAmount, previewCancelOrder, selectedProduct, seatNumber } = useSelector(
      organizationOrderRefundCancelSelector
    );
    const isB2B = useSelector(isB2BSelector);
    const taxAmount = FirmAdminUtils.valueToDefault(previewCancelOrder?.taxAmount, 0);
    const totalTaxAmount =
      requestedAction !== Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION ? taxAmount : 0;

    const totalRefundAmount = FirmAdminUtils.valueToDefault(
      Number(refundAmount) && Number(refundAmount) + totalTaxAmount,
      0
    );

    const submitRequest = async () => {
      setLoading(true);
      const {
        SubTypeSKU,
        subscriptionNumber,
        Id, // SC-RV_OrderProduct__c
        subscriptionStartDate,
        ratePlanProductId,
        isAutoRenewable,
        isOnlyProductOnSubscription,
        zuoraTermEndDate,
        productType,
        AccountId,
        OrderItemSKU,
        ratePlanId,
        AssignedSeats,
        AvailableSeats,
        CancelledSeats,
      } = selectedProduct as any;

      const cancelAction = requestedAction;
      const discountedPrice =
        Number(selectedProduct?.UnitPrice) -
        Number(selectedProduct?.RV_Discount__c) / Number(selectedProduct?.Quantity);
      const amount = totalRefundAmount.toFixed(2);
      const totalCancelledQuantity = (
        Number(FirmAdminUtils.valueToDefault(CancelledSeats, '0')) +
        Number(FirmAdminUtils.valueToDefault(seatNumber, '0'))
      ).toString();
      const cancelZuoraPayload = [
        {
          ratePlanId,
          subscriptionStartDate,
          subscriptionNumber,
          isOnlyProductOnSubscription: isB2B ? true : isOnlyProductOnSubscription, // default to true for B2B refund/cancel exam credits
          isAutoRenewable,
          subscriptionEndDate: zuoraTermEndDate,
          shouldCancelFromStartDate: true,
          amount,
          discountedPrice,
          sku: SubTypeSKU || OrderItemSKU,
          isEndOfLastInvoicePeriod: zuoraTermEndDate ? false : true, // termType - EVERGREEN
          firmAccountId: AccountId,
          zuoraProductId: ratePlanProductId,
          cancelledQuantity: totalCancelledQuantity,
          isB2B,
          seatNumber: Number(FirmAdminUtils.valueToDefault(seatNumber, '0')),
          orderProductId: Id,
          refundAction: requestedAction,
          assignedSeats: Number(AssignedSeats),
          availableSeats: Number(AvailableSeats),
          reason,
        },
      ];

      const refundZuoraPayload = [
        {
          subscriptionNumber,
          reason,
          amount,
          skuName: OrderItemSKU,
          zuoraProductId: ratePlanProductId,
          firmAccountId: AccountId,
        },
      ];

      const details = {
        orderId: ratePlanProductId,
        cancelPayload: cancelZuoraPayload,
        refundPayload: {
          zuoraPayload: refundZuoraPayload,
          cancelAction,
        },
        cancelAction,
        isMembership: (productType === 'membership-type' || productType === 'subscription-type') && true,
        productSKU: FirmAdminUtils.valueToDefault(SubTypeSKU, OrderItemSKU),
        firmAccountId: AccountId,
      };

      await dispatch(cancelRefundOrder(details));
      setLoading(false);
      onSubmit();
    };

    const cancelRefundLabel = areAllTruthy(
      requestAction,
      requestAction === Product.RefundOrCancellationEnums.CANCEL_ONLY
    )
      ? 'cancel'
      : 'refund and cancel';
    const cancellationOrIssueRefundLabel = areAllTruthy(
      requestAction,
      requestAction === Product.RefundOrCancellationEnums.CANCEL_ONLY
    )
      ? 'Cancellation'
      : 'Issue Refund & Cancel Product';

    return (
      <StyledModal
        open={isOpen}
        onClose={() => setIsModalOpen(false)} // tslint:disable-line jsx-no-lambda
        testId="prod-cancel-refund-modal"
        heading={
          areAllTruthy(requestAction, requestAction === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION)
            ? 'Issue Refund'
            : cancellationOrIssueRefundLabel
        }
        icon={<StyledIconExclamation />}
        cancelNode={
          <StyledButton
            size={ButtonEnums.sizes.small}
            testId="return-prev-page"
            variant={ButtonEnums.variants.secondary}
            disabled={loading}
            onClick={() => setIsModalOpen(false)} // tslint:disable-line jsx-no-lambda
          >
            No, return to previous page
          </StyledButton>
        }
        confirmNode={
          <StyledButton
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.primary}
            testId="refund-cancel"
            loading={loading}
            disabled={loading}
            onClick={submitRequest}
          >
            {`Yes, ${
              areAllTruthy(
                requestAction,
                requestAction === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION
              )
                ? 'issue refund'
                : cancelRefundLabel
            }`}
          </StyledButton>
        }
      >
        <ModalBody {...{ requestAction }} refundAmount={totalRefundAmount} />
      </StyledModal>
    );
  }
);

const StyledModal = styled(Modal)`
  &&&&& {
    width: ${props => props.theme.pxToRem(600)};
    padding: 0;
  }
`;

const StyledIconExclamation = styled(IconExclamation)`
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  path {
    fill: ${props => props.theme.colors.interfaceRed};
  }
`;

const StyledBold = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: 0 ${props => props.theme.pxToRem(3)} 0 ${props => props.theme.pxToRem(3)};
`;

const StyledButton = styled(Button)`
  &&&&& {
    min-width: ${props => props.theme.pxToRem(190)};
    font-size: ${props => props.theme.fontSizes.xs};
    margin-right: ${props => props.theme.pxToRem(20)};
    margin-top: ${props => props.theme.pxToRem(10)};
    margin-bottom: 0;
  }
`;

const StyledCenteredDiv = styled.div`
  text-align: center;
  margin-bottom: ${props => props.theme.pxToRem(8)};
`;
