import React from 'react';

interface Package {
  size?: number;
  color?: string;
  className?: string;
}

export const Package = React.memo<Package>(({ size = 24, color = '#FFFFFF', className }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 9.40094L7.5 4.21094"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 15.9979V7.99795C20.9996 7.64722 20.9071 7.30276 20.7315 6.99911C20.556 6.69546 20.3037 6.44331 20 6.26795L13 2.26795C12.696 2.09241 12.3511 2 12 2C11.6489 2 11.304 2.09241 11 2.26795L4 6.26795C3.69626 6.44331 3.44398 6.69546 3.26846 6.99911C3.09294 7.30276 3.00036 7.64722 3 7.99795V15.9979C3.00036 16.3487 3.09294 16.6931 3.26846 16.9968C3.44398 17.3004 3.69626 17.5526 4 17.7279L11 21.7279C11.304 21.9035 11.6489 21.9959 12 21.9959C12.3511 21.9959 12.696 21.9035 13 21.7279L20 17.7279C20.3037 17.5526 20.556 17.3004 20.7315 16.9968C20.9071 16.6931 20.9996 16.3487 21 15.9979Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.26953 6.96094L11.9995 12.0109L20.7295 6.96094"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M12 22.08V12" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
));
