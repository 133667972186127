import React, { useState } from 'react';
import styled from 'styled-components';
import { InputWithValidation, DropdownWithLabel } from 'components/molecules';
import { Button, ButtonEnums, Input } from 'components/atoms';
import { Separator } from 'components/admin/atoms/Separator/Separator';
import { Utils } from 'mxp-utils';
import { Salesforce } from 'mxp-schemas';

const AICPA_UID_FIELD = 'aicpa-uid';
const INVOICE_NUMBER_FIELD = 'invoice-number';

interface SearchInvoicesFormProps {
  onSubmit: ({
    uid,
    legalEntity,
    invoiceNumber,
  }: {
    uid: string;
    legalEntity: string;
    invoiceNumber: string;
  }) => Promise<() => void>;
  submitText: string;
}

export const SearchInvoicesForm: React.FC<SearchInvoicesFormProps> = ({ onSubmit, submitText }) => {
  const [loading, setLoading] = useState(false);
  const defaultField = { value: '', isCorrect: true, isEmpty: true };
  const [uid, setUid] = useState(defaultField);
  const [salesforceLegalEntity, setSalesforceLegalEntity] = useState(defaultField);
  const [invoiceNumber, setInvoiceNumber] = useState(defaultField);

  const handleSubmit = React.useCallback(
    (event: any) => {
      event.preventDefault();
      setLoading(true);

      onSubmit({
        uid: uid.value,
        invoiceNumber: invoiceNumber.value,
        legalEntity: salesforceLegalEntity.value,
      })
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [uid, salesforceLegalEntity, invoiceNumber, onSubmit]
  );

  const handleUidChange = React.useCallback(
    ({ target: { value } }: any) => {
      setUid({
        value,
        isCorrect: true,
        isEmpty: Boolean(value.length === 0),
      });
      setInvoiceNumber(defaultField);
    },
    [defaultField]
  );

  const handleLegalEntityChange = (e: any, data: any) =>
    setSalesforceLegalEntity({
      value: data.value,
      isCorrect: Boolean(data.value.length),
      isEmpty: Boolean(data.value.length === 0),
    });

  const handleInvoiceNumberChange = React.useCallback(
    ({ target: { value } }: any) => {
      const isEmpty = Boolean(value.length === 0);
      setInvoiceNumber({
        value,
        isCorrect: Utils.hasNumericChar(value) || isEmpty,
        isEmpty: Boolean(value.length === 0),
      });
      setUid(defaultField);
      setSalesforceLegalEntity(defaultField);
    },
    [defaultField]
  );

  const legalEntityOptions = Object.values(Salesforce.LegalEntity).map((value: any, index) => {
    return { key: index, value: value.toLowerCase(), text: value };
  });
  const isFormValid =
    (!uid.isEmpty && uid.isCorrect && !salesforceLegalEntity.isEmpty && salesforceLegalEntity.isCorrect) ||
    (!invoiceNumber.isEmpty && invoiceNumber.isCorrect);

  return (
    <form onSubmit={handleSubmit}>
      <StyledText data-testid="preferences-text">
        To begin, please look up the customer using the AICPA{'/CIMA'} ID,
        <br /> or search using the invoice number.
      </StyledText>

      <StyledInputContainer>
        <StyledRegularInput
          labelName={'AICPA/CIMA UID'}
          name={AICPA_UID_FIELD}
          type="text"
          value={uid.value}
          testId={AICPA_UID_FIELD}
          labelId={AICPA_UID_FIELD}
          onChange={handleUidChange}
          placeholder="e.g. 200000001"
        />
      </StyledInputContainer>

      <StyledDropdownContainer>
        <StyledDropdown
          onChange={handleLegalEntityChange}
          testId="legal-entity"
          title="Legal Entity"
          selected={salesforceLegalEntity.value || ''}
          options={legalEntityOptions}
          placeholder={'Select legal entity'}
        />
      </StyledDropdownContainer>

      <SeparatorContainer>
        <Separator>OR</Separator>
      </SeparatorContainer>

      <StyledInputContainer>
        <InputWithValidation
          labelName="Invoice Number"
          name={INVOICE_NUMBER_FIELD}
          type="text"
          value={invoiceNumber.value}
          testId={INVOICE_NUMBER_FIELD}
          labelId={INVOICE_NUMBER_FIELD}
          onChange={handleInvoiceNumberChange}
          isCorrect={invoiceNumber.isCorrect}
          errorMessage={'Please enter a valid invoice number'}
          placeholder="e.g. INV32XXXXXXXXXXX"
        />
      </StyledInputContainer>

      <SubmitButton
        size={ButtonEnums.sizes.medium}
        onClick={handleSubmit}
        testId="search-invoices"
        variant={ButtonEnums.variants.primary}
        disabled={!isFormValid || loading}
        loading={loading}
      >
        {submitText}
      </SubmitButton>
    </form>
  );
};

const StyledText = styled.div`
  max-width: 720px;
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.pxToRem(14)};
  line-height: 1.71;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(17)};
`;

const StyledInputContainer = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(11)};
  max-width: ${props => props.theme.pxToRem(265)};
`;

const StyledRegularInput = styled(Input)`
  margin-bottom: ${props => props.theme.pxToRem(0)};
  width: 100%;
`;

const SubmitButton = styled(Button)`
  &&&& {
    padding-left: ${props => props.theme.pxToRem(25)};
    padding-right: ${props => props.theme.pxToRem(25)};
  }
`;

const StyledDropdownContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(25)};
`;

const StyledDropdown = styled(DropdownWithLabel)`
  ${props => `
    &&& {
      width: ${props.theme.pxToRem(265)};
      padding: ${props.theme.pxToRem(12)};
      height: ${props.theme.pxToRem(46)};
      margin-right: ${props.theme.pxToRem(12)};
      border-radius: ${props.theme.pxToRem(5)};
      margin-bottom: ${props.theme.pxToRem(0)};
   }
  `}
`;

const SeparatorContainer = styled.div`
  max-width: ${props => props.theme.pxToRem(265)};
`;
