import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { OrganizationSeats } from 'components/pages/PageOrganization/OrganizationSeats';
import {
  serviceContractsActiveInactiveSelector,
  serviceContractsListErrorSelector,
  serviceContractsListLoadingSelector,
  serviceContractsListSelector,
  seatManagementInviteSelector,
  seatManagementInviteModalVisible,
  seatManagementIsInviteUpdateSelector,
  productAccessExtensionIsLoadingSelector,
  productAccessExtensionStatusSelector,
  productAccessExtensionDateSelector,
  productAccessExtensionUserLengthSelector,
  productAccessExtensionProductNameSelector,
  productAccessExtensionExistingEndDateSelector,
  ongoingExtensionModalIsOpenSelector,
} from 'modules/clientAdmin/selectors';

import { ServiceContract } from 'mxp-schemas/src/types/seatManagement';
import {
  setDeallocationModalVisibility,
  setSelectedSeatsToDeallocate,
  getServiceContracts,
  getSeatManagementInvite,
  showSeatManagementEmailInviteModal,
  seatManagementInviteCancel,
  extendProductAccess,
  toggleProductAccessExtensionModal,
  getRecentExtensionProcessStatus,
} from 'modules/clientAdmin/actions';

import { SeatManagement } from 'mxp-schemas';

const mapActionCreators = (dispatch: Dispatch) => ({
  getContracts(): void {
    dispatch(getServiceContracts()).catch((e: any) => console.error(e));
  },
  showSeatAssignmentModal(contract: ServiceContract): void {
    dispatch(showSeatManagementEmailInviteModal(contract));
  },
  getSeatManagementInvite(orderNumber: string): void {
    dispatch(getSeatManagementInvite(orderNumber));
  },
  handleInviteCancel(inviteId: string): void {
    dispatch(seatManagementInviteCancel(inviteId));
  },
  showDeallocationModal(): void {
    dispatch(setDeallocationModalVisibility(true));
  },
  getRecentExtensionProcessStatus(contractId: string): boolean {
    return dispatch(getRecentExtensionProcessStatus(contractId));
  },
  setSelectedSeatsToDeallocate(
    payload: {
      contract: SeatManagement.ServiceContract;
      lineItemsToDeallocate: SeatManagement.ContractLineItem[];
      pendingList?: string[];
    } | null
  ): void {
    dispatch(setSelectedSeatsToDeallocate(payload));
  },
  extendProductAccess(args: SeatManagement.ProductAccessExtension): void {
    dispatch(extendProductAccess(args));
  },
  toggleProductAccessExtensionModal(): void {
    dispatch(toggleProductAccessExtensionModal());
  },
});

const mapStateToProps = (state: State.Root) => ({
  contractList: serviceContractsListSelector(state),
  loading: serviceContractsListLoadingSelector(state),
  error: serviceContractsListErrorSelector(state),
  activeInactiveContracts: serviceContractsActiveInactiveSelector(state),
  seatManagementInviteList: seatManagementInviteSelector(state),
  isOpenModal: seatManagementInviteModalVisible(state),
  isInviteUpdate: seatManagementIsInviteUpdateSelector(state),
  isProductAccessExtensionIsLoading: productAccessExtensionIsLoadingSelector(state),
  status: productAccessExtensionStatusSelector(state),
  productAccessExtensionDate: productAccessExtensionDateSelector(state),
  userLength: productAccessExtensionUserLengthSelector(state),
  productName: productAccessExtensionProductNameSelector(state),
  existingEndDate: productAccessExtensionExistingEndDateSelector(state),
  ongoingExtensionModalIsOpen: ongoingExtensionModalIsOpenSelector(state),
});

export const OrganizationSeatsContainer = connect(mapStateToProps, mapActionCreators)(OrganizationSeats);
