import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import { DropdownWithLabel } from 'components/molecules/DropdownWithLabel/DropdownWithLabel';
import styled from 'styled-components/macro';
import { Grid, TextArea } from 'semantic-ui-react';
import { MembershipTypes } from 'mxp-schemas';

/**
 * Resignation & Termination Modal
 * @category Resignation & Termination
 *
 */

export interface Props {
  membershipName: string;
  open: boolean;
  isSubscriptionProduct?: boolean;
  onClose(): void;
  onConfirm(data: any): void;
}

export const ResignationOrTerminationModal: React.FC<Props> = ({ membershipName, open, onClose, onConfirm }) => {
  const [requiredActionNotSelected, setRequiredActionNotSelected] = useState(true);
  const [notMetConditions, setNotMetConditions] = useState(true);
  const [reasonFieldDisabled, setReasonFieldDisabled] = useState(true);
  const REQUIRED_ACTION_FIELD_ID = 'required_action';
  const REASON_FIELD_ID = 'reason';
  const RequiredActionOptions = MembershipTypes.ResignationOrTerminationRequiredActions;
  const ReasonOptions = MembershipTypes.ResignationOrTerminationReasons;
  const [reason, setReason] = useState('');
  const [requiredAction, setRequiredAction] = useState('');
  const [comment, setComment] = useState('');

  const handleRequiredActionChange = (e: any, data: any) => {
    setRequiredAction(data.value);
    setReasonFieldDisabled(false);
  };

  const handleReasonChange = (e: any, data: any) => {
    setReason(data.value);
    setNotMetConditions(false);
  };

  const handleCloseModal = useCallback(() => {
    onClose();
    setRequiredAction('');
    setReason('');
  }, [onClose, setRequiredAction, setReason]);

  useEffect(() => {
    if (requiredActionNotSelected) {
      setRequiredActionNotSelected(true);
    }
  }, [requiredActionNotSelected]);

  const onClickConfirm = useCallback(() => {
    onConfirm({
      reason,
      requiredAction,
      comment,
    });
  }, [onConfirm, reason, requiredAction, comment]);

  const onChangeComment = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComment(value);
    },
    [setComment]
  );

  return (
    <StyledModal
      open={open}
      onClose={handleCloseModal}
      size="tiny"
      testId="resignation-or-termination-center-membership-modal"
      heading="Resignation/termination"
      confirmNode={
        <Button
          size={ButtonEnums.sizes.small}
          testId="confirm-button"
          variant={ButtonEnums.variants.primary}
          onClick={onClickConfirm}
          disabled={notMetConditions}
        >
          Confirm
        </Button>
      }
      showCloseCross
    >
      <Grid stackable centered columns={3}>
        <StyledGridRow>
          <StyledSection>
            <StyledBold>Membership:</StyledBold>
            {membershipName}
          </StyledSection>
        </StyledGridRow>
        <StyledGridRow>
          <Grid.Column width={14}>
            <StyledDropdown
              scrolling
              placeholder="Select an action"
              testId={REQUIRED_ACTION_FIELD_ID}
              name={REQUIRED_ACTION_FIELD_ID}
              title="Action required"
              options={RequiredActionOptions}
              onChange={handleRequiredActionChange}
            />
          </Grid.Column>
        </StyledGridRow>
        <StyledGridRow>
          <Grid.Column width={14}>
            <StyledDropdown
              scrolling
              placeholder="Select a Reason"
              testId={REASON_FIELD_ID}
              name={REASON_FIELD_ID}
              title="Reason"
              options={ReasonOptions}
              onChange={handleReasonChange}
              disabled={reasonFieldDisabled}
            />
          </Grid.Column>
        </StyledGridRow>
        <StyledGridRow>
          <Grid.Column width={14}>
            <StyledSpan>Comment (optional)</StyledSpan>
            <StyledTextArea onChange={onChangeComment} placeholder="Enter your Text Here" />
          </Grid.Column>
        </StyledGridRow>
      </Grid>
    </StyledModal>
  );
};

const StyledSpan = styled.label`
  font-weight: 400;
  font-size: ${props => props.theme.pxToRem(16)};
`;

const StyledModal = styled(Modal)`
  &&&& {
    background: ${props => props.theme.colors.neutralGrey1};
    min-height: ${props => props.theme.pxToRem(682)};
    max-width: ${props => props.theme.pxToRem(590)};
    margin-top: ${props => props.theme.pxToRem(48)};
    > .header {
      h2 {
        min-height: ${props => props.theme.pxToRem(64)};
        max-width: ${props => props.theme.pxToRem(440)};
        padding: ${props => props.theme.pxToRem(10)};
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }
  button > svg {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding: 0 0 ${props => props.theme.pxToRem(10)} 0;
  }
`;

const StyledSection = styled.div`
  min-width: ${props => props.theme.pxToRem(250)};
  border-radius: ${props => props.theme.pxToRem(2)};
  padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(43)};
  background-color: ${props => props.theme.colors.neutralGrey1};
  color: ${props => props.theme.colors.neutralGrey8};
  text-align: center;
  justify-content: center;
  align-items: center;
  word-wrap: break-word;
  font-size: ${props => props.theme.fontSizes.xs};
`;

const StyledBold = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: 0 ${props => props.theme.pxToRem(3)} 0 ${props => props.theme.pxToRem(3)};
`;

const StyledDropdown = styled(DropdownWithLabel)`
  &&&.ui.search.dropdown {
    border-radius: ${props => props.theme.pxToRem(4)};
  }
  &&&.ui.dropdown .menu {
    height: auto;
  }
  &&&.ui.search.dropdown > input.search {
    cursor: pointer;
  }
  &&&.ui.default.dropdown:not(.button) > .text,
  &&&.ui.dropdown:not(.button) > .default.text,
  &&&.ui.search.dropdown > .text,
  &&&.ui.dropdown .menu > .item {
    color: ${props => props.theme.colors.primaryPurple};
    font-weight: ${props => props.theme.fontWeights.regular};
  }
  &&&.ui.dropdown .menu > * {
    white-space: normal;
  }
  &&&.disabled {
    &&&.ui.dropdown {
      border-color: ${props => props.theme.colors.neutralGrey8};
      background-color: ${props => props.theme.colors.neutralGrey3};
    }
    &&&.ui.dropdown .menu > .item {
      color: ${props => props.theme.colors.neutralGrey8};
    }
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
    height: ${props => props.theme.pxToRem(150)};
    max-width: 100%;
    min-width: 100%;
    border-color: ${props => props.theme.colors.neutralGrey4};
    font-size: ${props => props.theme.fontSizes.s};
    color: ${props => props.theme.colors.neutralGrey8};
    padding: ${props => props.theme.pxToRem(12)};
    border-radius: 4px;
  }

  &&&&&&:focus,
  &&&&&&:active {
    outline: none;
    border-color: ${props => props.theme.colors.primaryPurple};
  }
`;
