import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FeedPlaceholder, ButtonEnums, Loader } from 'components/atoms';
import { ModalEmploymentInformation, BenefitsModal, ModalError } from 'components/organisms';
import { MembershipPackageAccordionStatus, Routes } from '../../../constants';
import { MEMBERSHIP_TIER_HEADER_COLORS } from '../../../modules/membership/constants';
import { addMembershipToCart, fetchMembershipTiers, setMembershipPackageTier } from 'modules/membership';
import { Admin as AdminUtils } from 'mxp-utils';
import {
  tiersWithUserApplicablePricingSelector,
  isRenewalSelector,
  activeMembershipSubscriptionSelector,
  userMembershipPackageSelector,
  isCimaMembershipJourneySelector,
  membershipLoadingSelector,
  roleSelectionSelector,
  membershipTierSelector,
  isFLPSwitchSelector,
  isFLPUpgradeSelector,
  currentMembershipProduct,
  membershipInviteDataSelector,
  isOpenEmploymentModalFromInviteSelector,
  userMembershipFLPVariantSelector,
  isCimaRenewalSelector,
  isCimaRetiredLapsedSelector,
  membershipSubscriptionsFetchedSelector,
  membershipSubscriptionLineItemsSelector,
  selectedCimaMembershipKeyByUserChoiceSelector,
  isCimaPqCandidateRenewalSelector,
  isCimaPQCandidateLapsedSelector,
  userMembershipChangeMyLearningPathSelector,
  filteredCIMAMembershipTypesSelector,
  membershipProductListDataSelector,
  isCimaFLPLapsedSelector,
  membershipTiersSelector,
  isFlpRenewalSelector,
  isCimaCgmaAffiliateSelector,
  inactiveMembershipProduct,
  clickedMembershipUpgradeSelector,
  membershipProductSelector,
  isCimaRegularRenewalSelector,
  activeFlpSubscriptionSelector,
  isUpgradeToFlpRegularSelector,
  isCimaCgmaAffiliateLapsedSelector,
  isMembershipJourneySelector,
  isAddFcmaCredentialInCartSelector,
  isRenewalButtonSelector,
} from 'modules/membership/selectors';
import { productCurrencySelector, flpProductListDataSelector } from 'modules/products/selectors';
import { isPaperBillingSelector } from 'modules/startup/selectors';
import {
  personAccountIsHonorarySelector,
  newEmployerJobTitleSelector,
  personAccountIsCPASelector,
  currentJourneyLearningPathwaySelector,
  learningPathwaySelector,
  userTierSelector,
  personAccountDataSelector,
  applicationSelector,
  isUserMemberSelector,
  isUserMemberLapsedSelector,
  hasInactiveCIMAMembershipBodySelector,
  tlwSelector,
} from 'modules/user/selectors';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { ButtonProps, Table } from 'semantic-ui-react';
import {
  getMembershipPackagePath,
  getPath,
  getCimaMembershipPackagePath,
  formatPrice,
  fromCentsFormat,
  formattedAnyPriceToDecimal,
  checkIfTierInPriceTiers,
  trimNonNumeric,
  getCurrentLocation,
  getUniqueArrayValues,
  groupArrayValuesBy,
  areAllTruthy,
  hasTruthyValue,
} from 'utils';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';
import { ReactComponent as Removed } from 'resources/images/ic-close.svg';

import { DropdownWithLabel } from 'components/molecules';
import { MembershipTypes, User, Product, ExemptionLevels, Salesforce } from 'mxp-schemas';
import { setFlpDiscountedPRice, setFLPVariant, setFlpPersonExam, setHasSelectedTier } from 'modules/membership/actions';
import { selectedPassedUSCPAExamSelector } from 'modules/exemptionProfessional/selectors';
import { USCpaExamEnum } from 'modules/exemptionProfessional/constants';
import { getProductsListData } from 'modules/products/actions';
import { getOrderPreview } from 'modules/checkout/actions';
import moment from 'moment-timezone';
import { personLevelExemptionSelector } from 'modules/personLevelExemption';
import { matchPath } from 'react-router-dom';
import { fetchMembershipPackagesAndTiers } from 'utils/fetchMembershipPackagesAndTiers';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { getPageNameAndSiteSection, currentLearningPathway } from 'utils/Analytics/helpers';
import { RetiredQualificationModal } from 'components/molecules/ApplicationPersonalForm/RetiredQualificationModal';
import { exemptionEngineResultsSelector } from 'modules/exemptionEngine/selector';
import { hasPromocodeExemptionSelector } from 'modules/cart/selectors';
import { membershipActionNames } from 'modules/membership/constants';
import { MembershipAccordionHeading } from 'components/molecules/MembershipAccordionHeading/MembershipAccordionHeading';
import { featureTogglesSelector, getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_CR_682, USE_CR_723_REMOVE_PRORATION } from 'modules/featureToggle/constants';

interface MembershipPackagePanelProps {
  status?: MembershipPackageAccordionStatus;
  goToApplication?: boolean;
  goToSummaryPage?: () => void;
  isCima?: boolean;
  isAddonHidden?: boolean;
  isFLPProductType?: boolean;
  isNotShowingRoleModal?: boolean;
  shouldShowNewPackageBuilder?: boolean;
  shouldShowCTAButtons?: boolean;
}
interface SelectedColumnData {
  tierSKU: string;
  discPrice?: any;
  index?: number;
  name?: any;
  buttonLabel?: any;
}

interface ApplyButtonProps {
  sku: string;
  index?: number;
  name?: string;
  duration?: any;
  transformedPrice?: any;
  isFLPProductSelected?: boolean;
  otherVariants?: any;
}
const pricingGroupName = 'Pricing';
export const MembershipTierPanel: React.FC<MembershipPackagePanelProps> = ({
  status,
  goToApplication = false,
  isCima = false,
  isAddonHidden,
  goToSummaryPage,
  isFLPProductType = false,
  isNotShowingRoleModal = false,
  shouldShowNewPackageBuilder = false,
  shouldShowCTAButtons = true,
}) => {
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState<any>('');
  const [retiredModalOpen, setRetiredModalOpen] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { push: historyPush } = useHistory();
  const handleModalOnClose = () => {
    historyPush(getPath(Routes.MEMBERSHIP_FORM));
  };
  const [preSelectedTier, setPreselectedTier] = useState('');
  const [proratedRefundAmount, setProratedRefundAmount] = useState(0);
  const [prorationLoading, setProrationLoading] = useState(true);
  const [isGetOrderPreviewSuccess, setGetOrderPreviewSuccess] = useState(true);
  const [selectedColumnData, setSelectedColumnData] = useState<SelectedColumnData>();

  // SELECTORS
  const isFLPUpgrade = useSelector(isFLPUpgradeSelector);
  const isFLPSwitch = useSelector(isFLPSwitchSelector);
  const isCPA = useSelector(personAccountIsCPASelector);
  const { list } = useSelector(tiersWithUserApplicablePricingSelector);
  const getUserPersonlLevelExemption = useSelector(personLevelExemptionSelector);

  const tierCountry = useSelector(userTierSelector);
  const isRenewal = useSelector(isRenewalSelector);
  const isCimaMembershipJourney = useSelector(isCimaMembershipJourneySelector);
  const membershipLoading = useSelector(membershipLoadingSelector);
  const isPaperBilling = useSelector(isPaperBillingSelector);
  const { tier: tierName, type: userChoiceType } = useSelector(userMembershipPackageSelector);
  const userChoiceTypeSlug = userChoiceType?.slug;
  const isHonorary = useSelector(personAccountIsHonorarySelector);
  const membershipTierData = useSelector(membershipTierSelector);
  const newEmployerJobTitle = useSelector(newEmployerJobTitleSelector);
  const isCimaPqCandidateRenewal = useSelector(isCimaPqCandidateRenewalSelector);
  const isSubscribedToCgmaAffiliate = useSelector(isCimaCgmaAffiliateSelector);
  const isCimaCgmaAffiliateLapsed = useSelector(isCimaCgmaAffiliateLapsedSelector);
  const isForUpgrade = useSelector(clickedMembershipUpgradeSelector);
  const { isAicpaRenewal } = useSelector(isRenewalButtonSelector);

  const currentMembership: any = useSelector(membershipSubscriptionLineItemsSelector);
  const currentFLPMembership: any = useSelector(flpProductListDataSelector);
  const membershipSubscriptionsFetched = useSelector(membershipSubscriptionsFetchedSelector);
  const roleSelectionSelectorStatus = useSelector(roleSelectionSelector);
  const membershipTier = useSelector(activeMembershipSubscriptionSelector);
  const activeFlpSubscription = useSelector(activeFlpSubscriptionSelector);
  const inviteData = useSelector(membershipInviteDataSelector);
  const isOpenEmploymentModalFromInvite = useSelector(isOpenEmploymentModalFromInviteSelector);
  const [isOpenBenefitsModal, setIsOpenBenefitsModal] = useState(false);
  const [selectedBenefits, setSelectedBenefits]: any = useState();
  const [selectedTier, setSelectedTier]: any = useState();
  const [selectedVariant, setSelectedVariant]: any = useState([]);
  const currMembership = useSelector(currentMembershipProduct);
  const selectedPassedUSCPAExam = useSelector(selectedPassedUSCPAExamSelector);
  const currFLPVariant: any = useSelector(userMembershipFLPVariantSelector);
  const learningPathway = useSelector(learningPathwaySelector);
  const isCimaRenewal = useSelector(isCimaRenewalSelector);
  const selectedCimaMembershipKeyByUserChoice = useSelector(selectedCimaMembershipKeyByUserChoiceSelector);
  const personalDetail = useSelector(personAccountDataSelector);
  const isPromocodeAvailable = useSelector(hasPromocodeExemptionSelector);
  const hasInactiveCIMAMembershipBody = useSelector(hasInactiveCIMAMembershipBodySelector);
  const exemptionEngineResults = useSelector(exemptionEngineResultsSelector);
  const isSubscribedToCimaPq = AdminUtils.isCimaPqPathway(learningPathway as string);
  const isCimaPQCandidateLapsed = useSelector(isCimaPQCandidateLapsedSelector);
  const { id: personAccountId } = useSelector(personAccountDataSelector);
  const { employmentStatus, tlwReason } = useSelector(tlwSelector);
  const membershipProduct = useSelector(membershipProductSelector);
  const membershipProductKey = membershipProduct?.membershipKey;
  const membershipProductSlug = membershipProduct?.slug;
  const application = useSelector(applicationSelector);
  const employmentRole = application?.employmentRole;
  const isUserMember = useSelector(isUserMemberSelector);
  const isSubscribedToFlp = AdminUtils.isFlpPathway(learningPathway as string);
  const isSubscribedToApprenticeship = AdminUtils.isApprenticePathway(learningPathway as string);
  const membershipTierList = useSelector(membershipProductListDataSelector);
  const changeMyLearningPath: boolean = useSelector(userMembershipChangeMyLearningPathSelector);
  const filteredCIMAMembershipTypes = useSelector(filteredCIMAMembershipTypesSelector);
  const membershipTiers = useSelector(membershipTiersSelector);
  const isCimaFLPLapsed = useSelector(isCimaFLPLapsedSelector);
  const isFlpRenewal = useSelector(isFlpRenewalSelector);
  const isUserMemberLapsed = useSelector(isUserMemberLapsedSelector);
  const isCimaRegularRenewal = useSelector(isCimaRegularRenewalSelector);
  const isUpgradeToFlpRegular = useSelector(isUpgradeToFlpRegularSelector);

  const isJourneyForRenewal: boolean = isAicpaRenewal;
  const isJourneyForUpgrade: boolean = isForUpgrade;

  const useCR862FcmaRenewalEnhancement = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_CR_682)
  );

  const useRemoveProration = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_CR_723_REMOVE_PRORATION)
  );

  const isAddFcmaCredentialInCart = useSelector(isAddFcmaCredentialInCartSelector);

  const { useNewMembershipAICPA } = useSelector(featureTogglesSelector);
  const isMembership = useSelector(isMembershipJourneySelector);

  const useAVSLayout = areAllTruthy(isMembership, shouldShowNewPackageBuilder);
  const useAVSLayoutForRenewal = areAllTruthy(isJourneyForRenewal, useNewMembershipAICPA);
  const useAVSLayoutForUpgrade = areAllTruthy(isJourneyForUpgrade, useNewMembershipAICPA);
  const isUserAMember = areAllTruthy(isUserMember, useNewMembershipAICPA);

  const [isMembershipTierLoaded, setIsMembershipTierLoaded] = useState(false);
  const { membership: inactiveMembership } = useSelector(inactiveMembershipProduct);
  const candidateMembershipKey = filteredCIMAMembershipTypes?.find(
    type => type.membershipKey === MembershipTypes.MembershipKeys.CANDIDATE
  );

  // The following functions are built to address sonarqube code complexity
  // Move to utils
  const ifThisThenReturnThat = (ifThis: any, returnThat: any, otherwiseDoThis?: any) => {
    if (ifThis) {
      return returnThat;
    }
    return typeof otherwiseDoThis === 'function' ? otherwiseDoThis() : otherwiseDoThis;
  };

  const ifThisORThat = (ifThis: any, orThat: any) => ifThis || orThat;

  const ifAllOfTheseAreTrue = (args: any) => {
    const keys = Object.keys(args);
    const keyLength = keys.length;
    return keys.filter(i => Boolean(args[i])).length === keyLength;
  };

  const currentMembershipSku = ifThisORThat(currMembership.membership?.membershipTerm?.membershipTermTier, '');
  const [isMembershipTierEventTriggered, setIsMembershipTierEventTriggered] = useState(false);

  const location = useLocation();
  const isAICPAMembershipJourney = Boolean(
    matchPath(location.pathname, {
      path: getPath(Routes.MEMBERSHIP_FORM),
      exact: true,
      strict: false,
    })
  );

  useEffect(() => {
    if (hasInactiveCIMAMembershipBody) {
      const personExam: any[] = [];
      exemptionEngineResults?.exempReference?.lowerExemptionLevelRef.forEach((master: any) => {
        return master.masterExamReference.forEach((subject: any) => {
          return personExam.push({
            personAccountId,
            reason: subject.name.trim(),
            subjectId: subject.id,
            ...(isPromocodeAvailable && { isPromoCode: true }),
          });
        });
      });
      dispatch(setFlpPersonExam(personExam));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let currentJourneyLearningPathway = useSelector(currentJourneyLearningPathwaySelector);

  if (isAICPAMembershipJourney) {
    currentJourneyLearningPathway = '';
  }

  const currentJourney = currentLearningPathway(currentJourneyLearningPathway, isFLPProductType);
  isFLPProductType = currentJourney?.isFLP;

  const isForFlpRegularUpgrade = useMemo(() => {
    return (
      currMembership?.membership?.membershipTerm?.membershipTermType === MembershipTypes.MembershipKeys.AFFILIATE &&
      isSubscribedToFlp &&
      isForUpgrade
    );
  }, [currMembership, isSubscribedToFlp, isForUpgrade]);

  const isUserSelectedRegularOrRetired = ifThisThenReturnThat(
    selectedCimaMembershipKeyByUserChoice === MembershipTypes.MembershipKeys.REGULAR,
    selectedCimaMembershipKeyByUserChoice === MembershipTypes.MembershipKeys.RETIRED
  );

  const isFlpCurrentJourney = ifThisThenReturnThat(
    currentJourneyLearningPathway,
    AdminUtils.isFlpPathway(currentJourneyLearningPathway),
    () => userChoiceType.slug === MembershipTypes.CIMALearningPathwaySlug.FLP
  );

  // Lapsed Selector
  const isCimaRetiredLapsed = useSelector(isCimaRetiredLapsedSelector);
  const currentCurrency = useSelector(productCurrencySelector);
  // Validation for Recommended in Package Selector

  const isPassedUSCPAExam = ifThisORThat(
    selectedPassedUSCPAExam === USCpaExamEnum.YES_NOT_YET_LICENSED,
    selectedPassedUSCPAExam === USCpaExamEnum.YES_LICENSED_BEFORE
  );
  const isNotPassedUSCPAExam = ifThisORThat(
    selectedPassedUSCPAExam === USCpaExamEnum.NOT_PASSED_US_CPA_EXAM,
    selectedPassedUSCPAExam === ''
  );

  // Remove option more than 1 year in FLP renewals
  const isFLPRenewal = () => {
    const cimaReleaseRenewal = isCima && isCimaRenewal;
    const cimaFLP =
      learningPathway === MembershipTypes.Pathway.FLP && currentJourneyLearningPathway === MembershipTypes.Pathway.FLP;
    return cimaReleaseRenewal && cimaFLP;
  };

  const flpRenewalsOption = isFLPRenewal();

  const isAicpaMember = useMemo(() => {
    return currMembership?.membership?.membershipBody === MembershipTypes.MembershipBody.AICPA;
  }, [currMembership]);
  const isAicpaMemberLapsed = ifAllOfTheseAreTrue({
    isUserMemberLapsed,
    inactiveAndAICPAMember: inactiveMembership?.membershipBody === MembershipTypes.MembershipBody.AICPA,
  });

  const lapsedAicpaWithRemoveProrationCR = isAicpaMemberLapsed && useRemoveProration;

  const isAicpaRegularMember = useMemo(() => {
    return currMembership?.membership?.membershipTerm?.membershipTermType === MembershipTypes.MembershipKeys.REGULAR;
  }, [currMembership]);
  const isAicpaRegularMemberLapsed = ifAllOfTheseAreTrue({
    isUserMemberLapsed,
    inactiveReguarMembershp:
      inactiveMembership?.inactiveTerms?.[0]?.membershipTermType === MembershipTypes.MembershipKeys.REGULAR,
  });

  const nonMember = !(
    currMembership?.membership?.membershipBody === MembershipTypes.MembershipBody.AICPA ||
    currMembership?.membership?.membershipBody === MembershipTypes.MembershipBody.CIMA
  );

  const isAicpaMemberForFLPSignup = ifAllOfTheseAreTrue({
    isAicpaMember,
    notLearningPathway: !learningPathway,
    flpJourneyOrProduct: isFLPProductType || isFlpCurrentJourney,
  });

  // Will only show in CIMA FLP Signup, FLP Renewals, Switch PQ to FLP, and AICPA Regular-FLP

  const toggleFlpDropdown =
    isCima &&
    (isFLPProductType || (!isForFlpRegularUpgrade && !isUserSelectedRegularOrRetired && isFlpCurrentJourney)) &&
    (nonMember || // CIMA Signup
      learningPathway === MembershipTypes.Pathway.FLP || // FLP Renewals
      learningPathway === MembershipTypes.Pathway.PQ || // Switch PQ to FLP
      isAicpaMember); // To allow cross-over scenarios for AICPA member as long as with FLP package.
  const isFLPLearningPathway = Boolean(learningPathway === MembershipTypes.Pathway.FLP);
  const tenureMonths: number = currentFLPMembership ? Number(trimNonNumeric(currentFLPMembership?.variant.sku)) : 0;
  const currentPackageName = ifThisThenReturnThat(
    currentFLPMembership,
    `${currentFLPMembership?.name.split(' -')[0]} Package`,
    ''
  );
  const isShowEmploymentModal = useMemo(() => {
    return (
      (roleSelectionSelectorStatus && !Boolean(inviteData?.inviteId)) ||
      (Boolean(inviteData?.inviteId) &&
        isOpenEmploymentModalFromInvite &&
        userChoiceType?.slug === User.B2BMembershipTypes.REGULAR)
    );
  }, [roleSelectionSelectorStatus, inviteData, isOpenEmploymentModalFromInvite, userChoiceType]);
  useEffect(() => {
    if (Boolean(tierName)) {
      setPreselectedTier(tierName);
    }
  }, [tierName]);
  // LAPSED Redirect next Step If Having Only one Selection
  useEffect(() => {
    const hasOnlyOneItem = list && list.length === 1;
    if (hasOnlyOneItem && isCimaRetiredLapsed) {
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Addons, isPaperBilling));
    }
  }, [isCimaRetiredLapsed, list, isPaperBilling, history]);
  useEffect(() => {
    const fetchData = async () => {
      if (membershipSubscriptionsFetched) {
        await dispatch(getProductsListData());
      }
    };
    fetchData();
  }, [membershipSubscriptionsFetched, dispatch]);

  useEffect(() => {
    // This hook is an onMount data fetch.
    // Logic for data initialization goes here
    (async () => {
      // Since membershipTiers.list is being reset after leaving this page (when a package is selected for FLP),
      // we have to reinitialize those items when we are being redirected back here when user navigates via back buttons
      // this only happens on FLP journey as there an overwriting of userChoice.type object in the base code
      if (!isFLPUpgrade) {
        if (isFLPProductType && !membershipTiers?.list?.length) {
          const dependenciesAndCheckItems = {
            candidateMembershipKey,
            isSubscribedToCimaPq,
            isCimaPQCandidateLapsed,
            personAccountId,
            application,
            currentJourneyLearningPathway,
            isFLPUpgrade,
            isFLPSwitch,
            isUserMember,
            isSubscribedToFlp,
            isSubscribedToApprenticeship,
            membershipTierList,
            changeMyLearningPath,
            isCimaFLPLapsed,
            isCimaRenewal,
            dispatch: (func: any) => {
              dispatch(func);
            },
            // not necessary in refetching during back redirection. setting to null
            setSelectedPathwayId: null,
            setIsLoadingPathway: null,
            redirectCallback: null,
          };
          // Only pass the mandatory dependencies and check items to let the function recall the package builder data
          await fetchMembershipPackagesAndTiers(dependenciesAndCheckItems);
        }
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredList = useMemo(
    () =>
      list.filter(item => {
        if (hasTruthyValue(inviteData?.isPaidByFirm, inviteData?.isPaidByFLP) && inviteData?.isFLP) {
          return inviteData?.flpSubscriptionType === item?.slug;
        }

        if (
          ((!isForFlpRegularUpgrade &&
            !isUserSelectedRegularOrRetired &&
            currentJourneyLearningPathway === MembershipTypes.Pathway.FLP) ||
            userChoiceType.slug === MembershipTypes.CIMALearningPathwaySlug.FLP ||
            inviteData?.isFLP) &&
          isCimaMembershipJourney
        ) {
          // if person is aicpa regular member and selected is licensed before or not passed exam
          if (
            ((isAicpaMember && isAicpaRegularMember) || (isAicpaMemberLapsed && isAicpaRegularMemberLapsed)) &&
            selectedPassedUSCPAExam === USCpaExamEnum.YES_LICENSED_BEFORE
          ) {
            return !item.includedMembershipType && !item.includedCimaMembershipType && !item.isStandard;
          }

          // if a person is an AICPA regular member, irrespective of the option he selects,
          // we display FLP without membership
          if ((isAicpaMember && isAicpaRegularMember) || (isAicpaMemberLapsed && isAicpaRegularMemberLapsed)) {
            return !item.includedMembershipType && !item.isStandard;
          }
          // any other scenario, display FLP with membership
          // if AICPA member
          if (isAicpaMember || isAicpaMemberLapsed) {
            if (inviteData?.organization?.type === MembershipTypes.OrganizationType.FLP_UNIVERSITY) {
              return item.includedMembershipType && item.isStandard && item?.isForUniversity;
            }
            return item.includedMembershipType && item.isStandard && !item?.isForUniversity;
          }
          // CIMA or non member
          if (
            getUserPersonlLevelExemption?.professionalQualification?.[0]?.selectedPassedUSCPAExam ===
            ExemptionLevels.USCpaExamEnum.YES_LICENSED_BEFORE
          ) {
            return item.cpaHolderMembership;
          }
          if (isUserMemberLapsed) {
            if (inviteData?.organization?.type === MembershipTypes.OrganizationType.FLP_UNIVERSITY) {
              return item.includedCimaMembershipType && item.isStandard && item?.isForUniversity;
            }
            return item.includedCimaMembershipType && item.isStandard && !item?.isForUniversity;
          }

          return item.includedCimaMembershipType && item.isStandard;
        }
        // If current learning pathway is not FLP
        if (isFLPSwitch || isFLPUpgrade) {
          if (
            [USCpaExamEnum.YES_LICENSED_BEFORE, USCpaExamEnum.YES_NOT_YET_LICENSED].includes(
              selectedPassedUSCPAExam as USCpaExamEnum
            )
          ) {
            return !item.includedCimaMembershipType && !item.isStandard && !item.isForUniversity;
          }
          return !item.includedCimaMembershipType && item.isStandard && !item.isForUniversity;
        }

        const yearDiff = moment().diff(personalDetail.birthDate, 'years');
        const isRetired = moment(personalDetail.birthDate).isValid() && yearDiff > 65;
        // if CGMA Affiliate user is not greater than 65 years old
        if (selectedCimaMembershipKeyByUserChoice === MembershipTypes.MembershipKeys.CGMA_AFFILIATE && !isRetired) {
          return item.tierCode !== MembershipTypes.TierCode.RETIRED;
        }
        // display CORE tier only if Cima Candidate
        if (
          areAllTruthy(
            hasTruthyValue(isCimaPqCandidateRenewal, isSubscribedToCgmaAffiliate, isCimaCgmaAffiliateLapsed),
            selectedCimaMembershipKeyByUserChoice === MembershipTypes.MembershipKeys.CANDIDATE
          )
        ) {
          return item.tierCode === MembershipTypes.TierCode.CORE;
        }
        // display current tier only for Cgma Affiliate Renewal
        if (
          areAllTruthy(
            isSubscribedToCgmaAffiliate,
            selectedCimaMembershipKeyByUserChoice === MembershipTypes.MembershipKeys.CGMA_AFFILIATE
          )
        ) {
          return item.tierCode === currentMembershipSku;
        }
        return item;
      }),
    [
      list,
      currentJourneyLearningPathway,
      isCimaPqCandidateRenewal,
      isSubscribedToCgmaAffiliate,
      isAicpaMember,
      isAicpaRegularMember,
      isCimaMembershipJourney,
      isFLPSwitch,
      isFLPUpgrade,
      selectedPassedUSCPAExam,
      userChoiceType.slug,
      personalDetail.birthDate,
      selectedCimaMembershipKeyByUserChoice,
      getUserPersonlLevelExemption,
      isAicpaMemberLapsed,
      isAicpaRegularMemberLapsed,
      isForFlpRegularUpgrade,
      isUserMemberLapsed,
      inviteData,
      isUserSelectedRegularOrRetired,
      currentMembershipSku,
      isCimaCgmaAffiliateLapsed,
    ]
  );

  const { benefitNames, benefitGroups } = useMemo(() => {
    const benefitList = filteredList
      .map(({ benefits }) => benefits)
      .reduce((prev, curr) => {
        if (Array.isArray(curr)) {
          return [...prev, ...curr];
        }
        return prev;
      }, [])
      .map(benefit => ({ group: '', ...benefit }));
    const uniqueBenefitList = getUniqueArrayValues(benefitList, ({ header, group }: any) => `${header}-_-${group}`);
    return {
      benefitNames: uniqueBenefitList.map(({ header }) => header),
      benefitGroups: groupArrayValuesBy(uniqueBenefitList, 'group'),
    };
  }, [filteredList]);
  const checkCurrency = useCallback(
    (transformedPrice: any) => {
      const prices = transformedPrice?.map((p: any) => p.price);
      const ifTierInPriceTiers = checkIfTierInPriceTiers(prices, currentCurrency?.label, tierCountry);

      const price =
        transformedPrice?.find((val: any) =>
          ifTierInPriceTiers
            ? val?.price?.custom?.fields?.country_grouping?.includes(tierCountry) &&
              val?.price?.currency === currentCurrency.label
            : val?.price?.currency === currentCurrency.label
        ) || transformedPrice[0];

      return price;
    },
    [currentCurrency, tierCountry]
  );
  const discountedPrice = useCallback(
    (priceObject: any) => {
      const currentPQMembership = currentMembership.filter(
        (lineItem: any) => lineItem.productType === Product.ProductType.MEMBERSHIP
      )[0];
      if (currentMembership.length !== 0 || currentFLPMembership !== undefined) {
        const currentMembershipItem = isFLPLearningPathway ? currentFLPMembership : currentPQMembership;
        const fullPrice = checkCurrency(priceObject)?.price?.amount / 100;
        const updatedPrice = (fullPrice - proratedRefundAmount) * 100;
        const discPrice = checkCurrency(priceObject);
        discPrice.transformedPrice = formatPrice(
          fromCentsFormat(updatedPrice > 0 ? updatedPrice : 0),
          currentMembershipItem?.membershipPrice?.value?.currencyCode
        );
      }
      return [...priceObject];
    },
    [currentMembership, checkCurrency, isFLPLearningPathway, currentFLPMembership, proratedRefundAmount]
  );
  const tableData = useMemo(
    () =>
      filteredList.map(tier => ({
        ...tier,
        id: tier.id,
        name: tier.name,
        description: tier?.description,
        sub: tier?.sub,
        upsell: tier?.upsell,
        slug: tier.slug,
        sku: tier.sku,
        imageUrl: tier?.image?.url,
        isRecommendedPackage: tier?.isRecommendedPackage,
        transformedPrice:
          isFLPProductType && (isFLPSwitch || isFLPUpgrade)
            ? discountedPrice(tier.transformedPrice)
            : tier.transformedPrice,
        otherVariants: tier.otherVariants
          ? tier.otherVariants.map((variantTier: any) => ({
              ...variantTier,
              transformedPrice:
                isFLPProductType && (isFLPSwitch || isFLPUpgrade)
                  ? discountedPrice(variantTier.transformedPrice)
                  : variantTier.transformedPrice,
            }))
          : [],
        benefitsIncluded: tier?.benefits,
        ...Object.fromEntries(
          benefitNames.map(benefitName => [benefitName, !!tier.benefits?.find(b => b?.header === benefitName)])
        ),
        isFLPProductSelected: tier?.slug === inviteData?.flpSubscriptionType,
        duration: inviteData?.duration,
      })),
    [filteredList, discountedPrice, inviteData, isFLPProductType, isFLPSwitch, isFLPUpgrade, benefitNames]
  );

  const handleSelectedEmployment = (value: any) => {
    setSelectedEmploymentStatus(value);
  };

  const hasTLWData = useMemo(() => {
    return tableData.find(item => item.name === MembershipTypes.RoleCategoryEnum.TLW);
  }, [tableData]);

  const hasAlreadySelectedTier = useMemo(() => {
    return useAVSLayout || useAVSLayoutForRenewal ? Boolean(tierName) : false;
  }, [tierName, useAVSLayout, useAVSLayoutForRenewal]);

  useEffect(() => {
    if (!tableData.length || isMembershipTierEventTriggered) return;

    // CIMA
    if (isFLPProductType && isCimaMembershipJourney) {
      handleEvent(tableData, membershipActionNames.STOREFRONT_AGGREGATION_VIEW_SKILL);
      setIsMembershipTierEventTriggered(true);
    }

    // AICPA
    if (!membershipProductKey) return;

    // REGULAR MEMBERSHIP
    if (membershipProductKey === MembershipTypes.MembershipKeys.REGULAR) {
      if (!selectedEmploymentStatus) return;

      if (
        // Non TLW
        (employmentRole &&
          !employmentStatus &&
          !hasTLWData &&
          selectedEmploymentStatus === MembershipTypes.EmploymentStatusEnumText.CURRENTLY_WORKING) ||
        // TLW
        (employmentStatus &&
          tlwReason &&
          !employmentRole &&
          !!hasTLWData &&
          selectedEmploymentStatus === MembershipTypes.EmploymentStatusEnumText.LEFT_WORKFORCE)
      ) {
        handleEvent(tableData, membershipActionNames.STOREFRONT_AGGREGATION_VIEW_TIER);
        setIsMembershipTierEventTriggered(true);
      }
    }

    // NON REGULAR MEMBERSHIP
    if (
      membershipProductKey !== MembershipTypes.MembershipKeys.REGULAR &&
      userChoiceTypeSlug === membershipProductSlug
    ) {
      handleEvent(tableData, membershipActionNames.STOREFRONT_AGGREGATION_VIEW_TIER);
      setIsMembershipTierEventTriggered(true);
    }
  }, [
    tableData,
    employmentStatus,
    employmentRole,
    tlwReason,
    membershipProductKey,
    membershipProductSlug,
    userChoiceTypeSlug,
    hasTLWData,
    selectedEmploymentStatus,
    isFLPProductType,
    isCimaMembershipJourney,
    isMembershipTierEventTriggered,
  ]);

  useEffect(() => {
    if (areAllTruthy(!isCimaRetiredLapsed, isMembershipTierLoaded, !areAllTruthy(isAicpaMember, isFLPProductType))) {
      return;
    }
    const isMembershipLapsed = isCimaRetiredLapsed;
    if (!isMembershipLapsed) {
      if (hasTruthyValue(isFLPSwitch, isFLPUpgrade)) {
        dispatch(fetchMembershipTiers(false, '', true, false));
      } else if (userChoiceType?.slug) {
        const isFlpJourney = areAllTruthy(
          !isForFlpRegularUpgrade,
          !isUserSelectedRegularOrRetired,
          isFlpCurrentJourney
        );

        const isFLPProduct = areAllTruthy(isFLPProductType, !isUpgradeToFlpRegular);
        dispatch(fetchMembershipTiers(false, false, hasTruthyValue(isFLPProduct, isFlpJourney)));
      } else {
        if (!isCimaMembershipJourney) {
          history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Type));
        } else if (isCimaMembershipJourney && inviteData?.isFLP) {
          dispatch(fetchMembershipTiers(false, '', true, false));
        }
      }
      // you cant do tier upgrades outside of renewal season
      const isUpgradeOutsideRenewal = areAllTruthy(userChoiceType?.id === membershipTier?.productId, !isRenewal);
      if (areAllTruthy(isUpgradeOutsideRenewal, isAicpaMember, !isFLPProductType)) {
        history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Type));
      }
    } else {
      dispatch(fetchMembershipTiers());
    }
    setIsMembershipTierLoaded(true);
  }, [
    isUpgradeToFlpRegular,
    isFLPSwitch,
    isFLPUpgrade,
    history,
    isRenewal,
    membershipTier,
    userChoiceType,
    isFLPProductType,
    dispatch,
    isCimaRetiredLapsed,
    isAicpaMember,
    isAicpaRegularMember,
    isFlpCurrentJourney,
    inviteData,
    isCimaMembershipJourney,
    isForFlpRegularUpgrade,
    isUserSelectedRegularOrRetired,
    isMembershipTierLoaded,
  ]);
  useEffect(() => {
    (async () => {
      if (isFLPSwitch || isFLPUpgrade) {
        try {
          const proratedPrices = await dispatch(getOrderPreview());
          const negativeInvoice = proratedPrices?.payload?.previewResult?.invoices.find(
            (inv: any) => inv.amountWithoutTax < 0
          );
          const totalRefund = proratedPrices?.payload?.previewResult?.creditMemos
            ? proratedPrices?.payload?.previewResult?.creditMemos[0]?.amountWithoutTax
            : negativeInvoice
            ? negativeInvoice.amountWithoutTax * -1
            : 0;
          setProratedRefundAmount(totalRefund);
          setProrationLoading(false);
        } catch (e) {
          setProrationLoading(false);
          setGetOrderPreviewSuccess(false);
        }
      }
    })();
  }, [dispatch, isFLPSwitch, isFLPUpgrade]);
  const currencyFormat = (transformedPrice: any) => {
    const price = checkCurrency(transformedPrice);
    return price?.transformedPrice.replace('.00', '');
  };

  const triggerAnalyticsClickEvent = useCallback(
    (buttonLabel: string, name: string, path?: string) => {
      const { pageName } = getPageNameAndSiteSection();
      const currentLocation = getCurrentLocation(location);
      const currentHash = location?.hash.replace('#', '');
      let destination = path || currentLocation;

      if (
        currentLocation === getPath(Routes.CIMA_MEMBERSHIP_PACKAGE) &&
        currentHash === MembershipPackageAccordionStatus.Skill
      ) {
        if (isRenewal) {
          if (currentJourneyLearningPathway === MembershipTypes.Pathway.FLP) {
            destination = `${getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS)}`;
          }
          if (currentJourneyLearningPathway === MembershipTypes.Pathway.PQ) {
            destination = `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Addons}`;
          }
        } else {
          destination = `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Summary}`;
        }
      }

      if (
        currentLocation === getPath(Routes.MEMBERSHIP_FORM) &&
        currentHash === MembershipPackageAccordionStatus.Tier &&
        !isRenewal
      ) {
        destination = `${getPath(Routes.MEMBERSHIP_FORM)}#${MembershipPackageAccordionStatus.Sections}`;
      }
      handleEvent({ clickValue: `button:link:int::${buttonLabel}:${destination}/${name}`, pageName }, NAV_CLICK);
    },
    [location, isRenewal, currentJourneyLearningPathway]
  );
  const handleClick = useCallback(
    (tierSKU: string, discPrice?: any, index?: any, name?: any, buttonLabel?: any) =>
      async (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, _data: ButtonProps) => {
        let selectedTierSKU = tierSKU;
        let selectedDiscPrice = discPrice;
        if (isFLPProductType) {
          const checkVariant = selectedVariant.find((a: any) => a.index === index);
          if (checkVariant) {
            await dispatch(setFLPVariant({ index, checkVariant }));
            selectedTierSKU = checkVariant.sku;
            selectedDiscPrice = checkVariant.transformedPrice;
          }
        }
        if (isCima && (isFLPSwitch || isFLPUpgrade)) {
          const flpDiscountedPrice: any = formattedAnyPriceToDecimal(checkCurrency(selectedDiscPrice).transformedPrice);
          dispatch(setFlpDiscountedPRice(flpDiscountedPrice * 100));
        }
        if (goToApplication) {
          (async () => {
            await dispatch(addMembershipToCart());
          })();
          history.replace(getPath(Routes.APPLICATION_FORM));
        } else {
          await dispatch(setMembershipPackageTier(selectedTierSKU, isFLPProductType));
          if (status) {
            triggerAnalyticsClickEvent(buttonLabel, name);
            history.replace(getMembershipPackagePath(status));
          } else {
            if (
              isAddonHidden &&
              !(
                (isCimaPqCandidateRenewal || isSubscribedToCgmaAffiliate) &&
                selectedCimaMembershipKeyByUserChoice === MembershipTypes.MembershipKeys.CANDIDATE
              ) &&
              goToSummaryPage
            ) {
              triggerAnalyticsClickEvent(buttonLabel, name);
              goToSummaryPage();
            } else {
              const membershipPath = isCimaMembershipJourney
                ? inviteData?.isPaidByFLP || inviteData?.isPaidByFirm
                  ? getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Summary, isPaperBilling)
                  : areAllTruthy(useCR862FcmaRenewalEnhancement, isAddFcmaCredentialInCart)
                  ? getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Designation, isPaperBilling)
                  : getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Addons, isPaperBilling)
                : getMembershipPackagePath(MembershipPackageAccordionStatus.Credentials);
              triggerAnalyticsClickEvent(buttonLabel, name, membershipPath);
              if (isCimaMembershipJourney && (inviteData?.isPaidByFLP || inviteData?.isPaidByFirm)) {
                history.replace(membershipPath, { showSummaryPage: true });
              } else {
                history.replace(membershipPath);
              }
            }
          }
        }
      },
    [
      history,
      dispatch,
      isAddonHidden,
      goToSummaryPage,
      status,
      goToApplication,
      isCimaMembershipJourney,
      isPaperBilling,
      isFLPProductType,
      isFLPSwitch,
      isFLPUpgrade,
      isCima,
      selectedVariant,
      inviteData,
      checkCurrency,
      isCimaPqCandidateRenewal,
      isSubscribedToCgmaAffiliate,
      selectedCimaMembershipKeyByUserChoice,
      triggerAnalyticsClickEvent,
      useCR862FcmaRenewalEnhancement,
      isAddFcmaCredentialInCart,
    ]
  );

  const onClickApplyButton = useCallback(
    (tierSKU: string, discPrice?: any, index?: any, name?: any, buttonLabel?: any) => {
      setSelectedColumnData({ tierSKU, discPrice, index, name, buttonLabel });

      if (useAVSLayoutForRenewal) {
        dispatch(setMembershipPackageTier(tierSKU, false));
      }
    },
    [setSelectedColumnData, dispatch, useAVSLayoutForRenewal]
  );
  const handleConfirm = useCallback(async () => {
    let selectedTierSKU = selectedColumnData?.tierSKU;
    if (isFLPProductType) {
      const checkVariant = selectedVariant.find((a: any) => a.index === selectedColumnData?.index);
      if (checkVariant) {
        await dispatch(setFLPVariant({ index: selectedColumnData?.index, checkVariant }));
        selectedTierSKU = checkVariant.sku;
      }
    }
    if (goToApplication) {
      (async () => {
        await dispatch(addMembershipToCart());
      })();
      history.replace(getPath(Routes.APPLICATION_FORM));
    } else {
      await dispatch(setMembershipPackageTier(selectedTierSKU || tierName, isFLPProductType));
      if (status) {
        triggerAnalyticsClickEvent(selectedColumnData?.buttonLabel, selectedColumnData?.name);
        history.replace(getMembershipPackagePath(status));
      } else {
        if (isAddonHidden && goToSummaryPage) {
          triggerAnalyticsClickEvent(selectedColumnData?.buttonLabel, selectedColumnData?.name);
          goToSummaryPage();
        } else {
          const membershipPath = getMembershipPackagePath(MembershipPackageAccordionStatus.Credentials);
          triggerAnalyticsClickEvent(selectedColumnData?.buttonLabel, selectedColumnData?.name, membershipPath);
          history.replace(membershipPath);
        }
      }

      dispatch(setHasSelectedTier(true));
    }
  }, [
    history,
    dispatch,
    isAddonHidden,
    goToSummaryPage,
    status,
    goToApplication,
    isFLPProductType,
    selectedVariant,
    triggerAnalyticsClickEvent,
    selectedColumnData,
    tierName,
    // useNewMembershipAICPA,
  ]);

  const handleClose = useCallback(() => {
    history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Type));
  }, [history]);

  const handleVariantChange = useCallback(
    index => async (e: React.SyntheticEvent, data: any) => {
      // For fix price for every dropdown change
      const checkVariant = selectedVariant.find((a: any) => a.index === index);
      if (checkVariant) {
        const updatedData = selectedVariant.map((x: any) => (x.index === index ? { index, ...data.value } : x));
        setSelectedVariant(updatedData);
      } else {
        setSelectedVariant([...selectedVariant, { index, ...data.value }]);
      }
      // To set final variant after choosing from dropdown
      await dispatch(setFLPVariant({ index, ...data.value }));
    },
    [dispatch, selectedVariant]
  );
  const setOpenCloseBenefitsModal = useCallback(() => {
    setIsOpenBenefitsModal(!isOpenBenefitsModal);
  }, [isOpenBenefitsModal]);
  const onClickLearnMore = useCallback(
    (benefits: any, name: string) => {
      setIsOpenBenefitsModal(!isOpenBenefitsModal);
      if (benefits) setSelectedBenefits(benefits);
      if (name) setSelectedTier(name);
    },
    [isOpenBenefitsModal]
  );
  const displayTransformPrice = (index: any, transformedPrice: any) => {
    return isCima && isFLPProductType
      ? selectedVariant.find((val: any) => val.index === index)
        ? currencyFormat(selectedVariant.find((val: any) => val.index === index).transformedPrice)
        : currencyFormat(transformedPrice)
      : `${currencyFormat(transformedPrice)}/year`;
  };

  const ifInviteDataSlugIsInList = useMemo(() => {
    return tableData?.some((data: any) => data?.slug === inviteData?.flpSubscriptionType);
  }, [inviteData, tableData]);

  const loadingOrNoMembershipTiers = ifThisORThat(membershipLoading, !membershipTiers?.list?.length);
  const showFeedPlaceholder = ifThisORThat(loadingOrNoMembershipTiers, !tableData.length);

  if (showFeedPlaceholder) {
    return <FeedPlaceholder />;
  }
  const onCloseModal = () => {
    alert('Closed');
  };

  const userDiffAge = moment().diff(personalDetail.birthDate, 'years');
  const isUserRetired = moment(personalDetail.birthDate).isValid() && userDiffAge > 64;
  const handleModalOnUpdateSelection = () => {
    setRetiredModalOpen(false);
  };

  const calculateVariantIndex = (duration: string) => {
    if (duration) {
      return Boolean(duration?.includes('12'))
        ? 0 // 12 Months
        : Boolean(duration?.includes('24'))
        ? 1 // 24 Months
        : 2; // 36 Months
    }

    // defaults to 0 (12 Months) if duration is blank
    return 0;
  };

  const isRenewButtonText = (sku: string): boolean => {
    return [
      sku === membershipTier?.variant?.sku, // for active memberships
      sku === inactiveMembership?.inactiveTerms?.[0]?.productId && // for non active memberships, only check if there's no active memberships
        membershipTier?.subscriptionStatus !== Salesforce.MembershipStatus.ACTIVE,
      sku === activeFlpSubscription?.variant?.sku,
      isCimaRegularRenewal,
    ].includes(true);
  };

  const renderLoading = () => {
    return (
      <LoaderWrapper>
        <Loader size="small" active content="" inline />
      </LoaderWrapper>
    );
  };
  const showModalError = ifAllOfTheseAreTrue({
    isCPA,
    isAicpaMember,
    isAicpaRegularMember: !isAicpaRegularMember,
    passedAndLiscensed: selectedPassedUSCPAExam === USCpaExamEnum.YES_LICENSED_BEFORE,
  });

  (() => {
    if (showModalError) {
      return (
        <ModalError
          isOpen={true}
          handleClose={onCloseModal}
          bodyText="You are not allowed to purchase, You need to be Regular Member first"
        />
      );
    }
  })();

  (() => {
    if (userChoiceType?.slug === User.B2BMembershipTypes.RETIRED && !isUserRetired) {
      return (
        <RetiredQualificationModal
          retiredModalOpen={retiredModalOpen}
          onClose={handleModalOnClose}
          onClickUpdateSelection={handleModalOnUpdateSelection}
        />
      );
    }
  })();

  const getColors = (headerTitle: string) => {
    const coloredHeaders = ['Core', 'Essential', 'Lead', 'Select'];
    const hasColor = coloredHeaders.includes(headerTitle);
    let color = MEMBERSHIP_TIER_HEADER_COLORS.purple;
    if (hasColor) {
      switch (headerTitle) {
        case 'Core':
          color = MEMBERSHIP_TIER_HEADER_COLORS.lightBlue;
          break;
        case 'Essential':
          color = MEMBERSHIP_TIER_HEADER_COLORS.green;
          break;
        case 'Lead':
          color = MEMBERSHIP_TIER_HEADER_COLORS.darkBlue;
          break;
        case 'Select':
          color = MEMBERSHIP_TIER_HEADER_COLORS.purple;
          break;
        default:
          break;
      }
    }
    return {
      hasColor,
      color,
    };
  };

  const containsNumbers = (str: any) => {
    return /\d/.test(str);
  };

  // this is for making the amount on discounts card bigger and bolder
  const formatDiscountCardText = (text: string) => {
    const myArray = text.split(' ');
    const newText = myArray
      .map(word => {
        if (containsNumbers(word)) {
          return `<b>${word}</b>`;
        }
        return word;
      })
      .join(' ');
    return { __html: newText };
  };

  const isColumnSelectedChecker = (sku?: string) => {
    if (!selectedColumnData) {
      return preSelectedTier === sku;
    }
    return selectedColumnData?.tierSKU === sku;
  };

  const checkSkuIsForRenewal = (sku: string, selectedSku: any, userMembershipSku: any): boolean => {
    const currentSlugIsEqualToSelected = userMembershipSku?.name === selectedSku?.name?.replace(' Package', '');
    if (!selectedSku || !currentSlugIsEqualToSelected) return true;

    const buttonSkuIsEqualToSelectedSku = selectedSku.sku === sku;
    const currentSkuIsEqualToCurrent = userMembershipSku?.variant?.sku === selectedSku.sku;
    return buttonSkuIsEqualToSelectedSku && currentSkuIsEqualToCurrent;
  };
  const getButtonText = (sku: string) => {
    // Will check if the current SKU is equal to user selected FLP sku
    const isForRenewalFLP = checkSkuIsForRenewal(sku, currFLPVariant, currentFLPMembership);

    const jobData = MembershipTypes.Roles.find(role => newEmployerJobTitle === role.value);
    const ifPartner = jobData && jobData?.category === MembershipTypes.RoleCategoryEnum.PARTNER;
    const ifDowngrade = membershipTierData?.downgrade?.some((downgradeSku: any) => downgradeSku === sku) && ifPartner;
    const isLapsedRenewalOrUpgrade = hasTruthyValue(
      isRenewal,
      isCimaRenewal,
      isFlpRenewal,
      isUserMemberLapsed,
      isForFlpRegularUpgrade
    );

    const currentCTATierLabel =
      isAicpaMemberForFLPSignup || lapsedAicpaWithRemoveProrationCR
        ? 'Select'
        : isLapsedRenewalOrUpgrade
        ? isRenewButtonText(sku) && isForRenewalFLP
          ? 'Renew'
          : (isCimaRenewal && (ifDowngrade || isCimaPqCandidateRenewal)) ||
            inviteData?.inviteId ||
            isCimaPQCandidateLapsed ||
            isUserMemberLapsed
          ? 'Select'
          : 'Upgrade'
        : 'Select';

    const newCTATierLabel =
      !isAicpaMemberForFLPSignup &&
      isLapsedRenewalOrUpgrade &&
      isRenewButtonText(sku) &&
      isForRenewalFLP &&
      !lapsedAicpaWithRemoveProrationCR
        ? 'Renew'
        : 'Select';

    const buttonText = shouldShowNewPackageBuilder ? newCTATierLabel : currentCTATierLabel;

    return buttonText;
  };
  const getButtonProps = ({
    sku,
    index,
    name,
    duration,
    transformedPrice,
    isFLPProductSelected,
    otherVariants,
  }: ApplyButtonProps) => {
    const buttonText = getButtonText(sku);
    const variantMonths = selectedVariant.find((skill: any) => skill.index === index)?.flpTerm?.key;
    const isNotZeroNetPrice = Number(trimNonNumeric(displayTransformPrice(index, transformedPrice))) > 0;
    const isSamePackage = currentPackageName === name;

    const isDisabledButton = inviteData?.inviteId
      ? isCima &&
        ifInviteDataSlugIsInList && // check if the inviteSlug is in the list
        inviteData?.isPaidByFLP && // check if paid by flp
        !isFLPProductSelected // check if this is the product selected
      : (!isNotZeroNetPrice && isFLPUpgrade) || // check if 0 net Price
        (isCima && (isFLPUpgrade || isFLPSwitch) && prorationLoading);
    const isSelectedDuration = isCima && !isDisabledButton && inviteData?.isFLP;
    const variantIndex = !isSelectedDuration ? 0 : calculateVariantIndex(duration || '');
    const isNotValidPackage = !variantMonths
      ? Number(otherVariants[otherVariants?.length > 1 ? variantIndex : 0]?.flpTerm?.key) <= tenureMonths &&
        isSamePackage // Invalid package if default month is less than or equal the current package and same package is selected
        ? true
        : Number(otherVariants[otherVariants?.length > 1 ? variantIndex : 0]?.flpTerm?.key) < tenureMonths &&
          !isSamePackage // Invalid package if not same package and default month is less than current package
      : variantMonths <= tenureMonths && isSamePackage // Invalid package if the selected month is less than or equal to current package and the selected packages is same as current package
      ? true
      : variantMonths < tenureMonths && !isSamePackage; // Invalid package if the selected month is less than and the selected packages is not same as current package
    // check if this is the product selected
    return {
      buttonText,
      isNotValidPackage,
      variantIndex,
      isSelectedDuration,
      isDisabledButton: hasTruthyValue(isDisabledButton, areAllTruthy(isNotValidPackage, isFLPUpgrade)),
    };
  };

  const renderTableCell = () => {
    return isAICPAMembershipJourney ? (
      <StyledTableCell
        isAICPAMembershipJourney={isAICPAMembershipJourney}
        useAVSLayoutForRenewal={useAVSLayoutForRenewal}
      >
        Get access to the right tools to thrive at every stage of their career.
      </StyledTableCell>
    ) : (
      <StyledTableCell />
    );
  };

  const renderEmploymentModalInfo = () => {
    return (
      ((!isNotShowingRoleModal && roleSelectionSelectorStatus && !isHonorary && !inviteData?.inviteId) ||
        isOpenEmploymentModalFromInvite) &&
      !hasAlreadySelectedTier && (
        <ModalEmploymentInformation
          isOpenModal={isShowEmploymentModal}
          handleSelectedEmployment={handleSelectedEmployment}
        />
      )
    );
  };

  const renderAccordionHeading = () => {
    return (
      (useAVSLayout || useAVSLayoutForRenewal) &&
      isShowEmploymentModal && (
        <MembershipAccordionHeading modalProps={{ isShowEmploymentModal, handleSelectedEmployment }} />
      )
    );
  };

  return (
    <Container isCimaMembershipJourney={isCimaMembershipJourney}>
      {renderEmploymentModalInfo()}
      {tableData.map(
        ({ name }) =>
          isOpenBenefitsModal && (
            <BenefitsModal
              benefits={selectedBenefits}
              open={isOpenBenefitsModal}
              onClose={setOpenCloseBenefitsModal}
              productTitle={selectedTier || name}
              benefitDescription={''}
            />
          )
      )}
      {!!tableData.length && (
        <div>
          <Scroller>
            {isFLPProductType && isCima && (
              <StyledWrappedText>
                {
                  'Prices displayed here exclude tax/VAT. Your final price including tax/VAT will be calculated during checkout. Should you change your mind later, you will have the option to upgrade to a different package during your subscription period.'
                }
              </StyledWrappedText>
            )}

            {renderAccordionHeading()}

            <ShadowedDiv
              isAICPAMembershipJourney={isAICPAMembershipJourney}
              useAVSLayoutForRenewal={useAVSLayoutForRenewal}
            >
              <StyledTable basic="very" unstackable="true" isAICPAMembershipJourney={isAICPAMembershipJourney}>
                <StyledTableHeader>
                  {renderTableCell()}
                  {tableData.map(
                    (
                      {
                        slug,
                        name,
                        imageUrl,
                        sku,
                        transformedPrice,
                        isFLPProductSelected,
                        duration,
                        otherVariants,
                        isRecommendedPackage,
                      },
                      index
                    ) => {
                      const isFLPSelected =
                        isCima && // feature toggle for cima
                        ifInviteDataSlugIsInList && // check if the inviteSlug is in the list
                        isFLPProductSelected;

                      const { buttonText, variantIndex, isSelectedDuration, isDisabledButton } = getButtonProps({
                        sku,
                        index,
                        name,
                        duration,
                        transformedPrice,
                        isFLPProductSelected,
                        otherVariants,
                      });

                      return (
                        <StyledTableCell
                          isAICPAMembershipJourney={isAICPAMembershipJourney}
                          key={slug + index}
                          useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                        >
                          <StackedHeaderContent isAICPAMembershipJourney={isAICPAMembershipJourney}>
                            {isCima &&
                            (isFLPUpgrade || isFLPSwitch) &&
                            ((isPassedUSCPAExam && isRecommendedPackage) ||
                              (isNotPassedUSCPAExam && isRecommendedPackage)) ? (
                              <CardTopWrapper>
                                <CurrentWrapper className="line-green">
                                  <StyledCurrentTextWrapper className="text-green">
                                    <StyledCurrentText>{'Recommended'}</StyledCurrentText>
                                  </StyledCurrentTextWrapper>
                                </CurrentWrapper>
                              </CardTopWrapper>
                            ) : isFLPSelected ? (
                              <CardTopWrapper>
                                <CurrentWrapper className="line-green">
                                  <StyledCurrentTextWrapper className="text-green">
                                    <StyledCurrentText>SELECTED BY SPONSOR</StyledCurrentText>
                                  </StyledCurrentTextWrapper>
                                </CurrentWrapper>
                              </CardTopWrapper>
                            ) : (
                              <></>
                            )}

                            {!isFLPSelected &&
                              (sku === membershipTier?.variant?.sku ||
                                sku === inactiveMembership?.inactiveTerms?.[0]?.productId) &&
                              isUserMemberLapsed &&
                              !lapsedAicpaWithRemoveProrationCR && (
                                <CurrentWrapper>
                                  <StyledCurrentTextWrapper>
                                    <StyledCurrentText>CURRENT</StyledCurrentText>
                                  </StyledCurrentTextWrapper>
                                </CurrentWrapper>
                              )}
                            {imageUrl && (
                              <StyledImage
                                isAICPAMembershipJourney={isAICPAMembershipJourney}
                                src={imageUrl}
                                alt="tier"
                              />
                            )}
                            <StyledHeader
                              isAICPAMembershipJourney={isAICPAMembershipJourney}
                              customColor={getColors(name).color}
                              hasCustomColor={getColors(name).hasColor}
                            >
                              {name}
                            </StyledHeader>
                            {!isAICPAMembershipJourney && (
                              <StyledApplyButton
                                disabled={isDisabledButton}
                                testId={`button-${slug}`}
                                onClick={handleClick(
                                  isFLPProductType
                                    ? currFLPVariant && currFLPVariant.index === index
                                      ? currFLPVariant.sku
                                      : isSelectedDuration
                                      ? otherVariants[otherVariants?.length > 1 ? variantIndex : 0]?.sku
                                      : sku
                                    : slug,
                                  isFLPProductType && currFLPVariant
                                    ? currFLPVariant.transformedPrice
                                    : transformedPrice,
                                  index,
                                  name,
                                  buttonText
                                )}
                                overrideAnalytics={true}
                                isActive={!!preSelectedTier ? preSelectedTier === sku : false}
                                useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                              >
                                {buttonText}
                              </StyledApplyButton>
                            )}
                          </StackedHeaderContent>
                        </StyledTableCell>
                      );
                    }
                  )}
                </StyledTableHeader>
                {!isAICPAMembershipJourney && isFLPProductType && isCima && (
                  <StyledTableRow>
                    <StyledTableCell />
                    {tableData.map(({ slug, description }, index) => (
                      <StyledTableCell key={`${slug}`} useAVSLayoutForRenewal={useAVSLayoutForRenewal}>
                        <StyledText>{description}</StyledText>
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                )}
                <StyledTableBody>
                  {Object.entries(benefitGroups).map(([groupName, benefits]: any, benefitGroupsIndex) => {
                    return (
                      groupName !== pricingGroupName && (
                        <StyledTableBody
                          isAICPAMembershipJourney={isAICPAMembershipJourney}
                          shouldAddSpace={isAICPAMembershipJourney && benefitGroupsIndex === 0}
                        >
                          {isAICPAMembershipJourney && (
                            <StyledGroupNameRow>
                              {groupName && (
                                <StyledTitleCell isAICPAMembershipJourney={isAICPAMembershipJourney}>
                                  {groupName}
                                </StyledTitleCell>
                              )}
                              {tableData.map(
                                (
                                  { slug, name, sku, transformedPrice, otherVariants, duration, isFLPProductSelected },
                                  index
                                ) => {
                                  const { buttonText, isDisabledButton } = getButtonProps({
                                    sku,
                                    index,
                                    name,
                                    duration,
                                    transformedPrice,
                                    isFLPProductSelected,
                                    otherVariants,
                                  });

                                  const isColumnSelected = isColumnSelectedChecker(sku);

                                  const handleOnclickSelect = () => {
                                    onClickApplyButton(
                                      isFLPProductType
                                        ? currFLPVariant && currFLPVariant.index === index
                                          ? currFLPVariant.sku
                                          : false
                                          ? otherVariants[0]?.sku
                                          : sku
                                        : slug,
                                      isFLPProductType && currFLPVariant
                                        ? currFLPVariant.transformedPrice
                                        : transformedPrice,
                                      index,
                                      name,
                                      buttonText
                                    );
                                  };

                                  return (
                                    <StyledTableCell
                                      isAICPAMembershipJourney={isAICPAMembershipJourney}
                                      key={`table-cell-${slug}`}
                                      isColumnSelected={isColumnSelected}
                                      useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                                      isJourneyForUpgrade={useAVSLayoutForUpgrade}
                                      isUserMember={isUserAMember}
                                    >
                                      {isAICPAMembershipJourney && benefitGroupsIndex === 1 && (
                                        <StyledApplyButton
                                          isAICPAMembershipJourney={isAICPAMembershipJourney}
                                          key={`button-${slug}`}
                                          disabled={isDisabledButton}
                                          testId={`button-${slug}`}
                                          onClick={handleOnclickSelect}
                                          overrideAnalytics={true}
                                          useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                                          isRenewal={isRenewal}
                                          isForUpgrade={useAVSLayoutForUpgrade}
                                          isUserMember={isUserAMember}
                                        >
                                          {buttonText}
                                        </StyledApplyButton>
                                      )}
                                    </StyledTableCell>
                                  );
                                }
                              )}
                            </StyledGroupNameRow>
                          )}
                          {benefits.map(({ header }: any) => {
                            return (
                              <>
                                {header && !isAICPAMembershipJourney && (
                                  <StyledTableRow key={header}>
                                    <StyledTableCell
                                      isAICPAMembershipJourney={isAICPAMembershipJourney}
                                      useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                                    >
                                      {header}
                                    </StyledTableCell>
                                    {tableData.map(data => (
                                      <StyledTableCell
                                        isAICPAMembershipJourney={isAICPAMembershipJourney}
                                        testId="data"
                                        key={`${header}-${data.slug}`}
                                        useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                                      >
                                        {header && (data as any)[header] ? <StyledCheckIcon /> : <StyledRemovedIcon />}
                                      </StyledTableCell>
                                    ))}
                                  </StyledTableRow>
                                )}
                                {header && isAICPAMembershipJourney && (
                                  <StyledTableRow key={header}>
                                    <StyledTableCell
                                      isAICPAMembershipJourney={isAICPAMembershipJourney}
                                      useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                                    >
                                      {header}
                                    </StyledTableCell>
                                    {tableData.map(data => {
                                      const isColumnSelected = isColumnSelectedChecker(data.sku);
                                      return (
                                        <StyledTableCell
                                          isColumnSelected={isColumnSelected}
                                          testId="data"
                                          key={`${header}-${data.slug}`}
                                          isAICPAMembershipJourney={isAICPAMembershipJourney}
                                          useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                                        >
                                          {header && (data as any)[header] && (
                                            <IconWrapper
                                              isColumnSelected={isColumnSelected}
                                              backgroundColor={getColors(data.name).color}
                                            >
                                              <StyledRoundCheckIcon isColumnSelected={isColumnSelected} />
                                            </IconWrapper>
                                          )}
                                        </StyledTableCell>
                                      );
                                    })}
                                  </StyledTableRow>
                                )}
                              </>
                            );
                          })}
                        </StyledTableBody>
                      )
                    );
                  })}
                  {isFLPProductType && isCima && (
                    <StyledTableRow isAICPAMembershipJourney={isAICPAMembershipJourney}>
                      <StyledTitleCell isAICPAMembershipJourney={isAICPAMembershipJourney}>{''}</StyledTitleCell>
                      {tableData.map(({ slug, upsell }, index) => (
                        <StyledTableCell
                          isAICPAMembershipJourney={isAICPAMembershipJourney}
                          key={`${slug}`}
                          useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                        >
                          <StyledText>{upsell}</StyledText>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  )}
                  {toggleFlpDropdown && (
                    <>
                      <StyledTableRow>
                        <StyledTableCell
                          isAICPAMembershipJourney={isAICPAMembershipJourney}
                          useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                        >
                          Months in programme
                        </StyledTableCell>
                        {tableData.map(({ slug, otherVariants, isFLPProductSelected, duration }, index) => {
                          const isDisabledButton = inviteData?.inviteId
                            ? isCima && // feature toggle for cima
                              ifInviteDataSlugIsInList && // check if the inviteSlug is in the list
                              inviteData?.isPaidByFLP && // check if paid by flp
                              !isFLPProductSelected // check if this is the product selected
                            : (isFLPUpgrade || isFLPSwitch) && prorationLoading; // flp switch or upgrade check if order preview proration is still loading

                          const isSelectedDuration =
                            isCima && !isDisabledButton && inviteData?.isFLP && inviteData?.isPaidByFLP;

                          const variantIndex = !isSelectedDuration ? 0 : calculateVariantIndex(duration || '');

                          return (
                            <StyledTableCell
                              isAICPAMembershipJourney={isAICPAMembershipJourney}
                              key={`price-${slug}`}
                              useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                            >
                              {toggleFlpDropdown && (
                                <StyledDropdownContainer>
                                  <StyledDropdownWithLabel
                                    disabled={isDisabledButton || isSelectedDuration}
                                    options={
                                      flpRenewalsOption && !isFLPUpgrade
                                        ? otherVariants
                                            .filter((item: any) => parseInt(item.flpTerm.key, 10) <= 12)
                                            .map((variant: any) => ({
                                              key: variant.flpTerm.key,
                                              text: variant.flpTerm.label,
                                              value: variant,
                                            }))
                                        : otherVariants.map((variant: any) => ({
                                            key: variant.flpTerm.key,
                                            text: variant.flpTerm.label,
                                            value: variant,
                                          }))
                                    }
                                    defaultValue={otherVariants[otherVariants?.length > 1 ? variantIndex : 0]}
                                    onChange={handleVariantChange(index)}
                                  />
                                </StyledDropdownContainer>
                              )}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell
                          isAICPAMembershipJourney={isAICPAMembershipJourney}
                          useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                        >
                          Years in programme
                        </StyledTableCell>
                        {tableData.map(({ slug, sub }) => (
                          <StyledTableCell
                            isAICPAMembershipJourney={isAICPAMembershipJourney}
                            key={`price-${slug}`}
                            useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                          >
                            <StyledTextItalic dangerouslySetInnerHTML={{ __html: sub || '' }} />
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    </>
                  )}
                </StyledTableBody>
                <StyledTableBody addBorderBottom isAICPAMembershipJourney={isAICPAMembershipJourney}>
                  <StyledGroupNameRow>
                    <StyledTitleCell isAICPAMembershipJourney={isAICPAMembershipJourney}>
                      {toggleFlpDropdown ? 'Your price' : 'Total Cost'}
                    </StyledTitleCell>
                    {tableData.map(
                      (
                        {
                          slug,
                          sku,
                          transformedPrice,
                          otherVariants,
                          benefitsIncluded,
                          name,
                          duration,
                          isFLPProductSelected,
                        },
                        index
                      ) => {
                        const isColumnSelected = isColumnSelectedChecker(sku);
                        const isDisabledButton =
                          isCima && // feature toggle for cima
                          ifInviteDataSlugIsInList && // check if the inviteSlug is in the list
                          inviteData?.isPaidByFLP && // check if paid by flp
                          !isFLPProductSelected && // check if this is the product selected
                          inviteData?.inviteId; // check if FB process

                        const isSelectedDuration =
                          isCima &&
                          !isDisabledButton &&
                          inviteData?.isFLP &&
                          inviteData?.isPaidByFLP &&
                          inviteData?.inviteId; // check if FB process

                        const variantIndex = !isSelectedDuration ? 0 : calculateVariantIndex(duration || '');

                        return (
                          <StyledTableCell
                            isAICPAMembershipJourney={isAICPAMembershipJourney}
                            isColumnSelected={isColumnSelected}
                            key={`price-${slug}`}
                            useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                          >
                            <StyledTextSmall>
                              {isGetOrderPreviewSuccess ? (
                                <StyledTextLarge
                                  isColumnSelected={isColumnSelected}
                                  customColor={getColors(name).color}
                                  hasCustomColor={getColors(name).hasColor}
                                >
                                  {isCima && (isFLPUpgrade || isFLPSwitch)
                                    ? !prorationLoading
                                      ? displayTransformPrice(
                                          index,
                                          inviteData?.inviteId && otherVariants.length // check if FB process
                                            ? otherVariants[variantIndex].transformedPrice
                                            : transformedPrice
                                        )
                                      : renderLoading()
                                    : displayTransformPrice(
                                        index,
                                        inviteData?.inviteId && otherVariants.length // check if FB process
                                          ? otherVariants[otherVariants?.length > 1 ? variantIndex : 0].transformedPrice
                                          : transformedPrice
                                      )}
                                </StyledTextLarge>
                              ) : (
                                'Oops! Something went wrong. Please try again.'
                              )}
                            </StyledTextSmall>
                          </StyledTableCell>
                        );
                      }
                    )}
                  </StyledGroupNameRow>
                </StyledTableBody>
                <StyledTableBody isAICPAMembershipJourney={isAICPAMembershipJourney}>
                  {isAICPAMembershipJourney && (
                    <StyledGroupNameRow>
                      <StyledTitleCell isAICPAMembershipJourney={isAICPAMembershipJourney} />
                      {tableData.map(({ slug, sku, benefitsIncluded, name }) => {
                        return (
                          <StyledTableCell
                            isAICPAMembershipJourney={isAICPAMembershipJourney}
                            key={`price-${slug}`}
                            useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                          >
                            <>
                              {Object.entries(benefitGroups).map(([groupName, benefits]: any) => {
                                return (
                                  groupName === pricingGroupName &&
                                  benefits.map(
                                    ({ header }: any) =>
                                      (tableData.find(data => data.sku === sku) as any)?.[header] && (
                                        <StyledDiscountSection
                                          dangerouslySetInnerHTML={formatDiscountCardText(header)}
                                          backGroundColor={getColors(name).color}
                                        />
                                      )
                                  )
                                );
                              })}
                              {isAICPAMembershipJourney && (
                                <StyledViewDetailsButton
                                  variant={ButtonEnums.variants.link}
                                  testId={`learn-more-${slug}`}
                                  onClick={onClickLearnMore.bind(true, benefitsIncluded, name)}
                                >
                                  Learn more
                                </StyledViewDetailsButton>
                              )}
                            </>
                            {!toggleFlpDropdown && <Spacing />}
                          </StyledTableCell>
                        );
                      })}
                    </StyledGroupNameRow>
                  )}
                  {!isAICPAMembershipJourney && (
                    <StyledTableRow>
                      <StyledTableCell />
                      {tableData.map(
                        (
                          { slug, name, sku, transformedPrice, isFLPProductSelected, duration, otherVariants },
                          index
                        ) => {
                          const { buttonText, variantIndex, isSelectedDuration, isDisabledButton } = getButtonProps({
                            sku,
                            index,
                            name,
                            duration,
                            transformedPrice,
                            isFLPProductSelected,
                            otherVariants,
                          });

                          return (
                            <StyledTableCell
                              verticalAlign="bottom"
                              key={`footer-${slug}`}
                              useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                            >
                              <StyledHeader isAICPAMembershipJourney={isAICPAMembershipJourney}>{name}</StyledHeader>
                              <StyledApplyButton
                                disabled={isDisabledButton || !isGetOrderPreviewSuccess}
                                testId={`button-${slug}`}
                                onClick={handleClick(
                                  isFLPProductType
                                    ? currFLPVariant && currFLPVariant.index === index
                                      ? currFLPVariant.sku
                                      : isSelectedDuration
                                      ? otherVariants[otherVariants?.length > 1 ? variantIndex : 0]?.sku
                                      : sku
                                    : slug,
                                  isFLPProductType && currFLPVariant
                                    ? currFLPVariant.transformedPrice
                                    : transformedPrice,
                                  index,
                                  name,
                                  buttonText
                                )}
                                overrideAnalytics={true}
                                isActive={!!preSelectedTier ? preSelectedTier === sku : false}
                                useAVSLayoutForRenewal={useAVSLayoutForRenewal}
                              >
                                {buttonText}
                              </StyledApplyButton>
                            </StyledTableCell>
                          );
                        }
                      )}
                    </StyledTableRow>
                  )}
                </StyledTableBody>
              </StyledTable>
              {(isFLPUpgrade || isFLPSwitch) && (
                <>
                  <StyledRefundRulesText>
                    Refund guidelines: You will automatically receive a prorated credit from your cancelled CIMA
                    Candidate membership or existing Finance Leadership Program (FLP) subscription. This credit will be
                    applied against the balance of your new FLP subscription.
                  </StyledRefundRulesText>
                  <StyledRefundRulesText>
                    Note that refunds will not be issued for any exam credits or customisations you previously
                    purchased.
                  </StyledRefundRulesText>
                  <StyledRefundRulesText>All prices are shown excluding VAT/tax.</StyledRefundRulesText>
                </>
              )}
            </ShadowedDiv>
            {isAICPAMembershipJourney && shouldShowCTAButtons && (
              <StyledButtonContainer>
                <StyledButton
                  testId="membership-tier-btn-continue"
                  disabled={!selectedColumnData && !preSelectedTier}
                  onClick={handleConfirm}
                >
                  Continue
                </StyledButton>
                <StyledButton testId="membership-tier-btn-close" onClick={handleClose}>
                  {useAVSLayout ? 'Go back' : 'Close'}
                </StyledButton>
              </StyledButtonContainer>
            )}
          </Scroller>
        </div>
      )}
    </Container>
  );
};
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => `${props.theme.pxToRem(40)} 0`};
  width: 100%;
`;
const StyledDropdownWithLabel = styled(DropdownWithLabel)`
  margin: ${props => props.theme.pxToRem(20)} 0;
`;
const StyledDropdownContainer = styled.div`
  width: ${props => props.theme.pxToRem(150)};
  margin: auto;
`;
const StyledImage = styled.img<{ isAICPAMembershipJourney?: boolean }>`
  align-self: center;
  ${props =>
    props.isAICPAMembershipJourney
      ? css`
          width: ${props.theme.pxToRem(62)};
          height: ${props.theme.pxToRem(62)};
        `
      : css`
          width: ${props.theme.pxToRem(110)};
          height: ${props.theme.pxToRem(110)};
        `}
  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    width: auto;
    height: ${props => props.theme.pxToRem(32)};
  }
`;
const Spacing = styled.div`
  margin: ${props => `${props.theme.pxToRem(40)} ${props.theme.pxToRem(20)}`};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `${props.theme.pxToRem(20)} 0`};
  }
`;
const StackedHeaderContent = styled.div<{ isAICPAMembershipJourney?: boolean }>`
  height: 100%;
  min-height: ${props => props.theme.pxToRem(props.isAICPAMembershipJourney ? 100 : 265)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const StyledHeader = styled.div<{ customColor?: string; hasCustomColor?: boolean; isAICPAMembershipJourney?: boolean }>`
  color: ${props => (props.hasCustomColor ? props.customColor : props.theme.colors.primaryPurple)};
  font-size: ${props => (props.isAICPAMembershipJourney ? props.theme.fontSizes.s : props.theme.fontSizes.l)};
  font-weight: ${props =>
    props.isAICPAMembershipJourney ? props.theme.fontWeights.bold : props.theme.fontWeights.medium};
  text-transform: capitalize;
  font-family: 'Roboto';
  text-align: center;
`;
const StyledCheckIcon = styled(Checkmark)`
  &&& {
    path {
      fill: ${props => props.theme.colors.interfaceGreen};
    }
  }
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => `0 ${props.theme.pxToRem(5)} 0 0`};
  object-fit: contain;
`;
const StyledRemovedIcon = styled(Removed)`
  &&& {
    path {
      fill: ${props => props.theme.colors.neutralGrey4};
    }
  }
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => `0 ${props.theme.pxToRem(5)} 0 0`};
  object-fit: contain;
`;

const StyledTable = styled(Table)<{ isAICPAMembershipJourney?: boolean }>`
  color: ${props => props.theme.colors.primaryPurple};
  margin: ${props => `${props.theme.pxToRem(21)} ${props.theme.pxToRem(18)} ${props.theme.pxToRem(20)}`};
  border-radius: ${props => props.theme.pxToRem(4)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-height: ${props => props.theme.pxToRem(760)};
    width: ${props => props.theme.pxToRem(400)};
    margin: ${props => `${props.theme.pxToRem(15)} 0 ${props.theme.pxToRem(20)}`};
    padding: 0;
  }
  thead {
    display: flex;
    align-items: end;
    ${props => props.theme.mediaQueries.mobileOnly} {
      display: flex;
      align-items: center;
    }
    td {
      ${props => props.theme.mediaQueries.mobileOnly} {
        height: ${props => props.theme.pxToRem(115)};
      }
    }
  }

  thead,
  tbody,
  tr,
  td {
    border: none !important;
  }

  ${props =>
    props.isAICPAMembershipJourney &&
    `
    td {
      border-bottom: ${`${props.theme.pxToRem(1)} dotted ${props.theme.colors.lightSilver}`} !important;
    }
    thead td {
      border-bottom: ${`${props.theme.pxToRem(1)} solid ${props.theme.colors.primaryPurple}`} !important;
    }
  `};

  ${props =>
    !props.isAICPAMembershipJourney &&
    `
    td {
      border: ${`${props.theme.pxToRem(1)} solid ${props.theme.colors.lightSilver}`} !important;
      border-top: none !important;
      border-right: none !important;
    }
    thead td:first-child {
      border: none !important;
    }
    td:first-child {
      border-left: none !important;
    }
    tbody:last-child tr:last-child td {
      border-bottom: none !important;
    }
  `};
`;
const StyledTableRow = styled(Table.Row)`
  display: flex;
  td {
    text-align: center !important;
    :first-child {
      text-align: left !important;
    }
  }
`;

const Container = styled.div<{ isCimaMembershipJourney: boolean }>`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: inherit;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: 0 !important;
    width: 100%;
  }
`;

const StyledDiscountSection = styled.div<{ backGroundColor: string }>`
  width: 100%;
  margin-bottom: ${props => props.theme.pxToRem(10)};
  padding: ${props => props.theme.pxToRem(8)};
  text-align: center;
  border-radius: ${props => props.theme.pxToRem(4)};
  box-shadow: ${props => `0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(4)} 0`}
    ${props => props.theme.colors.neutralGrey6};
  color: ${props => props.theme.colors.neutralWhite};
  background-color: ${props => props.backGroundColor};
  font-size: ${props => props.theme.pxToRem(10)};
  font-weight: ${props => props.theme.fontWeights.regular};
  letter-spacing: ${props => props.theme.pxToRem(-0.2)};
  b {
    font-size: ${props => props.theme.fontSizes.s} !important;
    letter-spacing: ${props => props.theme.pxToRem(-0.32)};
  }
  :first-child {
    margin-top: ${props => props.theme.pxToRem(11.5)};
  }
`;
const ShadowedDiv = styled.div<{ isAICPAMembershipJourney?: boolean; useAVSLayoutForRenewal?: boolean }>`
  box-shadow: ${props =>
    props.isAICPAMembershipJourney
      ? 'none'
      : `0  ${props.theme.pxToRem(2)} ${props.theme.pxToRem(14)} 0
    ${props.theme.colors.neutralGrey6}`};
  padding: ${props => props.theme.pxToRem(props.isAICPAMembershipJourney ? 0 : 15)};
  background-color: ${props => props.theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(4)};

  ${props =>
    props.useAVSLayoutForRenewal
      ? `padding-left: ${props.isAICPAMembershipJourney && props.theme.pxToRem(11)}`
      : `padding-left: ${props.isAICPAMembershipJourney && props.theme.pxToRem(15)}`};
  ${props =>
    !props.isAICPAMembershipJourney &&
    `
    display: flex;
    flex-direction: column;
    `}
  ${props =>
    props.isAICPAMembershipJourney &&
    `
      ${props.theme.mediaQueries.mobileOnly} {
      box-shadow: none;
      max-width: 100%;
      padding-left: 0;
      overflow-x: scroll;
    `}
`;
const StyledTableHeader = styled(Table.Header)<{ isCimaMembershipJourney: boolean }>`
  ${props =>
    props.isCimaMembershipJourney &&
    `
  display: flex;
  flex-direction: column;
  tr {
    display: flex;
    flex: 1;
  }
  td {
    flex: 1;
    :first-child {
      flex: 1;
      max-width: ${props.theme.pxToRem(214)};
      min-width: ${props.theme.pxToRem(196)};
      border-left: none;
      height: unset;
    }
  }
  `}
`;

const StyledTableBody = styled(Table.Body)<{
  shouldAddSpace?: boolean;
  addBorderBottom?: boolean;
  isAICPAMembershipJourney?: boolean;
}>`
  ${props =>
    props.isAICPAMembershipJourney &&
    `
    :last-child tr td {
      border-bottom: none !important;
    }
    tr {
      :last-child td {
        padding-bottom: ${props.shouldAddSpace && props.theme.pxToRem(50)};
        border-bottom: ${`${props.theme.pxToRem(1)} solid ${props.theme.colors.primaryPurple}`} !important;
      }
      :first-child td {
        border-bottom: ${!props.addBorderBottom && 'none'} !important;
      }
    }
  `}
`;

const tableCellWidth = (useAVSLayoutForRenewal?: boolean, isJourneyForUpgrade?: boolean, isUserMember?: boolean) => {
  switch (true) {
    case useAVSLayoutForRenewal:
      return '70px';
    case isJourneyForUpgrade:
    case !isUserMember:
      return '120px';
    default:
      return '214px';
  }
};

const StyledTableCell = styled(Table.Cell)<{
  isColumnSelected?: boolean;
  isAICPAMembershipJourney?: boolean;
  useAVSLayoutForRenewal?: boolean;
  isJourneyForRenewal?: boolean;
  isJourneyForUpgrade?: boolean;
  isUserMember?: boolean;
}>`
  color: ${props => (props.isColumnSelected ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  font-family: 'Roboto';
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  background-color: ${props =>
    props.isColumnSelected ? props.theme.colors.primaryPurple : props.theme.colors.neutralWhite} !important;
  padding: ${props => `0 ${props.theme.pxToRem(props.isAICPAMembershipJourney ? 7 : 15)}`};
  width: ${props => tableCellWidth(props.useAVSLayoutForRenewal, props.isJourneyForUpgrade, props.isUserMember)};

  align-items: center;
  :first-child {
    width: ${props => props.theme.pxToRem(props.isAICPAMembershipJourney ? 485 : 300)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      background-color: ${props => props.theme.colors.neutralWhite} !important;
      width: ${props => props.theme.pxToRem(112)} !important;
      left: ${props => props.theme.pxToRem(0)};
      position: sticky;
      margin-left: ${props => props.theme.pxToRem(10)};
      z-index: 1000;
    }
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(80)};
  }
`;
const StyledViewDetailsButton = styled(Button)`
  color: ${({ theme }) => theme.colors.primaryPurple};
  &&& {
    span {
      text-decoration: none !important;
      font-size: ${props => props.theme.fontSizes.xs} !important;
    }
  }
`;
const StyledTextSmall = styled.div`
  color: ${props => props.theme.colors.neutralGrey5};
  font-size: ${props => props.theme.fontSizes.xxs};
`;
const StyledTextLarge = styled.div<{ isColumnSelected?: boolean; customColor?: string; hasCustomColor?: boolean }>`
  color: ${props =>
    props.isColumnSelected
      ? props.theme.colors.neutralWhite
      : props.hasCustomColor
      ? props.customColor
      : props.theme.colors.neutralGrey5};
  font-family: 'Roboto';
  text-align: center;
  font-size: ${props => props.theme.pxToRem(20)};
  letter-spacing: ${props => props.theme.pxToRem(-0.4)};
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-bottom: ${props => props.theme.pxToRem(34)};
  word-break: break-all;
`;
const StyledText = styled.span`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xs};
  text-align: left !important;
`;
const StyledTextItalic = styled.span`
  color: ${props => props.theme.colors.neutralGrey6};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-style: italic;
  text-align: center !important;
`;
const StyledWrappedText = styled.span`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.s};
  text-align: left !important;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-left: auto;
  margin-bottom: ${props => props.theme.pxToRem(15)};
`;

const applyButtonMargin = (isRenewal?: boolean, isForUpgrade?: boolean) => {
  switch (true) {
    case isRenewal:
      return '-4px';
    case isForUpgrade:
      return '50px';
    default:
      return '5px';
  }
};

const applyButtonWidth = (
  isRenewal?: boolean,
  isUserMember?: boolean,
  useAVSLayoutForRenewal?: boolean,
  isForUpgrade?: boolean
) => {
  switch (true) {
    case useAVSLayoutForRenewal:
      return '60px';
    case isForUpgrade:
      return '110px';
    case !isUserMember:
    default:
      return 'auto';
  }
};

const StyledApplyButton = styled(Button)<{
  isActive?: boolean;
  isAICPAMembershipJourney?: boolean;
  useAVSLayoutForRenewal?: boolean;
  isRenewal?: boolean;
  isForUpgrade?: boolean;
  isUserMember?: boolean;
}>`
  position: relative;
  top: ${props => props.theme.pxToRem(props.isAICPAMembershipJourney ? -35 : 10)};
  margin-left: ${props => applyButtonMargin(props.isRenewal, props.isForUpgrade)}!important;
  font-size: ${props => props.theme.fontSizes.s} !important;
  ${props =>
    props.useAVSLayoutForRenewal
      ? `padding: ${props.theme.pxToRem(12)} ${props.theme.pxToRem(4.5)} !important`
      : `padding: ${props.theme.pxToRem(0)} ${props.theme.pxToRem(10.5)}`};
  
  width: ${props =>
    applyButtonWidth(props.isRenewal, props.isUserMember, props.useAVSLayoutForRenewal, props.isForUpgrade)};
  font-family: 'Roboto';
  border-radius: ${props => props.theme.pxToRem(4)};
  border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.primaryPurple} !important;
  color: ${props => props.theme.colors.primaryPurple} !important;
  background-color: ${props =>
    props.isAICPAMembershipJourney ? props.theme.colors.neutralWhite : 'transparent'} !important;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(100)};
  }
  ${({ isActive, isAICPAMembershipJourney }) =>
    isActive &&
    !isAICPAMembershipJourney &&
    css`
      background-color: ${props => props.theme.colors.primaryPurple} !important;
      color: ${props => props.theme.colors.neutralWhite} !important;
    `};

  ${props =>
    !props.isAICPAMembershipJourney &&
    `
      margin-bottom: ${props.theme.pxToRem(20)} !important;
      :hover,
      :focus {
      background-color: ${props.theme.colors.primaryPurple} !important;
      color: ${props.theme.colors.neutralWhite} !important;
    `}
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(64)};
    margin: 0 !important;
    padding-top: ${props => props.theme.pxToRem(10)};
    padding-bottom:  padding-top: ${props => props.theme.pxToRem(10)};
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;
const StyledTitleCell = styled(StyledTableCell)<{ isAICPAMembershipJourney?: boolean }>`
  color: ${props => props.theme.colors.primaryPurple} !important;
  text-align: left !important;
  width: ${props => props.theme.pxToRem(props.isAICPAMembershipJourney ? 485 : 300)} !important;
  text-transform: capitalize;
  position: sticky;
  z-index: 1;
  font-size: ${props => (props.isAICPAMembershipJourney ? props.theme.fontSizes.s : props.theme.pxToRem(22))};
  font-weight: ${props => props.theme.fontWeights.medium} !important;
`;
const Scroller = styled.div`
  overflow: visible !important;
  scrollbar-width: thin;
  width: 100%;
`;
const CurrentWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute !important;
  align-items: center;
  padding: ${props =>
    `${props.theme.pxToRem(11)} ${props.theme.pxToRem(11)} ${props.theme.pxToRem(11)} ${props.theme.pxToRem(10)}`};
  > .text-green {
    background-color: ${props => props.theme.colors.secondaryTeal};
    margin-top: ${props => `${props.theme.pxToRem(5)}`};
  }
`;
const StyledCurrentTextWrapper = styled.div`
  width: ${props => props.theme.pxToRem(128)};
  height: ${props => props.theme.pxToRem(26)};
  border-radius: ${props => props.theme.pxToRem(4)};
  background-color: ${props => props.theme.colors.primaryLightPurple};
  position: absolute !important;
  align-items: center;
  text-align: center;
  ${props => props.theme.mediaQueries.desktopOnly} {
    left: 26%;
    top: ${props => props.theme.pxToRem(-15)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    left: 45%;
    top: ${props => props.theme.pxToRem(-35)};
  }
`;
const StyledCurrentText = styled.span`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.pxToRem(10)};
`;
const StyledRefundRulesText = styled.p`
  text-align: left;
  padding-top: 1.25rem;
`;
const CardTopWrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;
  > .line-red {
    &:after {
      background-color: ${props => props.theme.colors.primaryLightPurple};
    }
  }
  > .line-green {
    &:after {
      background-color: ${props => props.theme.colors.secondaryTeal};
    }
  }
`;

const StyledGroupNameRow = styled(Table.Row)`
  display: flex;
  text-align: left;
`;
const IconWrapper = styled.div<{ isColumnSelected?: boolean; backgroundColor: string }>`
  height: ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  margin: auto;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background-color: ${props => (props.isColumnSelected ? props.theme.colors.neutralWhite : props.backgroundColor)};
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${props => props.theme.mediaQueries.mobileOnly} {
    position: sticky;
  }
`;

const StyledButton = styled(Button)`
  font-size: ${props => props.theme.fontSizes.s} !important;
  height: ${props => props.theme.pxToRem(40)};
  border-radius: ${props => props.theme.pxToRem(4)};
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: ${props => props.theme.pxToRem(200)};
    margin: ${props => props.theme.pxToRem(25.5)} !important;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(150)};
    margin: ${props => props.theme.pxToRem(5)} !important;
  }

  :first-child {
    color: ${props => props.theme.colors.neutralWhite} !important;
    background-color: ${props => props.theme.colors.primaryPurple} !important;
  }
  :last-child {
    color: ${props => props.theme.colors.primaryPurple} !important;
    background-color: ${props => props.theme.colors.neutralWhite} !important;
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.primaryPurple} !important;
  }
`;

const StyledRoundCheckIcon = styled(Checkmark)<{ isColumnSelected: boolean }>`
  &&& {
    path {
      fill: ${props => (props.isColumnSelected ? props.theme.colors.primaryPurple : props.theme.colors.neutralWhite)};
    }
  }
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  margin: auto;
`;
