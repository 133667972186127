import React from 'react';
import styled from 'styled-components';
import { Modal } from 'components/atoms/Modal/Modal';
import { ButtonEnums, Button } from 'components/atoms/Button/Button';

/**
 * Firm membership sign up: Contact Audit Quality Centers Team
 * @category Center Membership
 * MART-841
 */

export enum CaqCentersTeamStatusEnum {
  NON_US_OR_CAQ = 'NonUsOrCaqModal',
  GOVERNMENT = 'GaqcGovenmentModal',
  GENERIC_CONDITIONS = 'GenericConditions',
}

interface Props {
  open: boolean;
  onCancel?: () => void;
  closeButtonLabel?: string;
  onClose: () => void;
  className?: string;
  status: string;

  testId?: string;
  header?: string;
  auditQualityCentersTeamEmail?: string;
  centerMembershipType: string;
}

export const ContactAuditQualityCentersTeam: React.FC<Props> = ({
  open,
  onCancel,
  testId,
  header,
  onClose,
  closeButtonLabel,
  centerMembershipType,
  status,
}) => {
  const onCloseEvent = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onClose();
    },
    [onClose]
  );

  const contactEmail = centerMembershipType === 'caq' ? 'membership@thecaq.org' : `${centerMembershipType}@aicpa.org`;

  return (
    <StyledModal
      className="partners-modal"
      open={open}
      onClose={onCancel}
      size="small"
      heading={header}
      testId={testId}
      showCloseCross
      confirmNode={
        <Button
          className="close"
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          testId="close"
          onClick={onCloseEvent}
          type="button"
        >
          {closeButtonLabel}
        </Button>
      }
    >
      <StyledParagraph>
        {status === CaqCentersTeamStatusEnum.NON_US_OR_CAQ && (
          <p>
            Non US CPA firms wishing to apply for Center Membership Type is ineligible Center membership is US only.
          </p>
        )}

        {status === CaqCentersTeamStatusEnum.GOVERNMENT && (
          <p>
            You are not currently eligible to enroll in a center membership. Please reach out to GAQC for more
            information: (<a href={`mailto:${contactEmail}`}>{contactEmail}</a>).
          </p>
        )}

        {status === CaqCentersTeamStatusEnum.GENERIC_CONDITIONS && (
          <p>
            This organization type is not eligible for the {centerMembershipType.toUpperCase()} Center Membership
            chosen. Please contact the center team (<a href={`mailto:${contactEmail}`}>{contactEmail}</a>) with any
            questions.
          </p>
        )}
      </StyledParagraph>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: relative;
  box-sizing: border-box;

  &&&&&&& {
    border-radius: ${props => props.theme.pxToRem(2)};
    width: ${props => props.theme.pxToRem(590)};
    box-sizing: border-box;
    padding: ${props => props.theme.pxToRem(36)} ${props => props.theme.pxToRem(80)} ${props => props.theme.pxToRem(19)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props => props.theme.pxToRem(36)} ${props => props.theme.pxToRem(20)}
        ${props => props.theme.pxToRem(19)};
      width: 90%;
    }

    .header {
      line-height: 1.33;
      margin-top: ${props => props.theme.pxToRem(12)};
      h2 {
        margin-top: ${props => props.theme.pxToRem(17)};
      }
    }

    .content {
      margin: ${props => props.theme.pxToRem(5)} auto auto;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }

    p {
      max-width: 100%;
    }
    .actions {
      padding: ${props => `${props.theme.pxToRem(20)}`} 0 0 !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        flex-direction: column-reverse;
        justify-content: center;
      }
    }

    button {
      position: absolute;
      top: ${props => props.theme.pxToRem(24)};
      right: ${props => props.theme.pxToRem(24)};
    }

    div > button {
      position: relative;
      top: 0;
      right: 0;
      width: ${props => props.theme.pxToRem(202)};
      height: ${props => props.theme.pxToRem(34)};
      padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};

      margin: auto ${props => props.theme.pxToRem(6)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100% !important;
        height: ${props => `${props.theme.pxToRem(40)}`};
        max-width: ${props => `${props.theme.pxToRem(296)}`};
        font-size: ${props => props.theme.fontSizes.s};
        border-radius: ${props => `${props.theme.pxToRem(4)}`};
        margin: 0 auto !important;
      }
    }
  }
`;

const StyledParagraph = styled.p`
  line-height: 1.57;
  margin-top: ${props => props.theme.pxToRem(0)};
`;
