import { createAction } from 'redux-actions';
import { push, getLocation } from 'connected-react-router';
import { Dispatch } from 'redux';
import { selectedTopicIdSelector, reloadAfterLoginSelector, selectedSkillIdSelector } from 'modules/layouts/selectors';
import {
  toggleModalOnboardingFinishedOpen,
  toggleModalTopicUnfollow,
  toggleModalAddressValidationOpen,
} from 'modules/layouts';
import { topicsHashSelector } from 'modules/topics/selectors';
import {
  userPrefSelector,
  userShortcutsSelector,
  isAuthSelector,
  personAccountIdSelector,
  newEmployerSelector,
  applicationSelector,
  attestationSelector,
  cpaLicenseDataSelector,
  personAccountDataSelector,
  centerMembershipPackageOrganizationSelector,
  centerMembershipPackageOrganizationIdSelector,
  centerMembershipApplicationAdminDetailSelector,
  centerMembershipApplicationObjectSelector,
  isUserMemberSelector,
  userPartnerDetailSelector,
  userOktaIdSelector,
  employersSelector,
  cpaLicenseListDataSelector,
  userIndustryPrefSelector,
  userTrendPrefSelector,
  userSkillPrefSelector,
  learningPathwayToSwitchSelector,
  learningPathwaySelector,
  isEPA1CompletedSelector,
  currentJourneyLearningPathwaySelector,
  EPA2SubmissionsSelector,
  isCimaMemberSelector,
  cimaMembershipsTermTypeSelector,
  studentProgressionSelector,
  isUserCountryEmbargoedSelector,
  isAicpaMemberSelector,
} from 'modules/user/selectors';
import { studentDetailsSelector } from 'modules/tuitionprovider/selectors';
import { entryPageSelector, isAdminPortalSelector } from 'modules/app/selectors';
import {
  GET_PUB_KEY,
  CREATE_USER,
  QUERY_GOLDEN_RECORD,
  QUERY_USER_STATE,
  MUTATE_USER_STATE,
  MUTATE_GOLDEN_RECORD_PREFERENCES,
  MUTATE_GOLDEN_RECORD_CIMA_PREFERENCES,
  MUTATE_GOLDEN_RECORD_EMAIL,
  QUERY_USER_FEEDBACK_BY_CONTENT_ID,
  MUTATE_USER_FEEDBACK_BY_CONTENT_ID,
  MUTATE_USER_FEEDBACK_BY_SURVEY,
  MUTATE_GOLDEN_RECORD_SHORTCUTS,
  QUERY_USER_BY_ID,
  GET_USER_PLATFORM,
  FORGOT_PASSWORD,
  RESOLVE_EMAIL,
  QUERY_NET_FORUM_USER,
  UPDATE_RECOVERY_QUESTION,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_NOTIFICATION_EMAIL,
  QUERY_MARKETING_PREFERENCES,
  MUTATE_MARKETING_PREFERENCES,
  QUERY_CPA_STATUS,
  MUTATE_CPA_STATUS,
  MUTATE_UPDATED_PASSWORD_MAIL,
  QUERY_MY_PROFILE,
  MUTATE_PERSON_ACCOUNT,
  MUTATE_PROCESS_QUALIFICATION,
  QUERY_ORGANIZATIONS_BY_NAME,
  SEARCH_EMPLOYER,
  MUTATE_EMPLOYMENT,
  MUTATE_UPDATE_EMPLOYMENT,
  MUTATE_REMOVE_EMPLOYMENT,
  QUERY_ORGANIZATION_BY_ACCOUNT_NUMBER,
  MUTATE_APPLICATION,
  MUTATE_ORGANIZATION,
  MUTATE_PERSONAL_INFORMATION,
  MUTATE_FIRM_MEMBERSHIP_WITH_ATTESTATION,
  MUTATE_SEND_PARTNER_INVITE,
  GET_APPLICATION_BY_ID,
  UPDATE_ACCOUNT_PERSON_ACCOUNT_JOB_TITLE,
  MUTATE_UPDATE_PERSON_ACCOUNT_STATUS,
  GET_SECURITY_QUESTION,
  MUTATE_SECURITY_RESPONSE,
  ACCEPT_FIRM_MEMBERSHIP_INVITE,
  OPEN_PV_DASHBOARD,
  GET_EPA2_SUBMISSIONS,
  QUERY_STUDENT_EXAM_PROGRESSION,
  QUERY_STUDENT_EXAM_SECTION_RESULTS,
  MUTATE_TLW,
  REMOVE_TLW,
  QUERY_SFMC_LINK,
  CREATE_ATTESTATION,
  CREATE_ATTESTATIONS,
  GET_EPA2L7_SUBMISSIONS,
  MUTATE_EPA2L4_CASE_STATUS,
  MUTATE_EPA2L7_CASE_STATUS,
  CREATE_EVENT_REGISTRATION,
  PROCESS_LEAVING_CREDENTIAL_APPLICATION_PAGE,
  GET_EXAMINATIONS,
  CREATE_SPECIALCONSIDERATION,
  MUTATE_UPGRADE_LEARNING_PATHWAY,
  MUTATE_UPDATE_EMPLOYMENT_DATA_SHARING,
  QUERY_CHECK_USER_COUNTRY_IF_EMBARGOED,
  GET_USER_LOCATION,
  GET_APPLICATION,
  SEND_DIFFERENT_EPA_SUPERVISOR_EMAIL,
  MUTATE_CURRENT_LEARNING_PATHWAY,
  UPDATE_ACCOUNT_UNIVERSITY_DETAILS,
  GET_USER_SYLLABUS,
  QUERY_STUDENT_EXAM_HISTORY,
  MUTATE_DEPROVISION_EXAM_CREDITS,
} from 'mxp-graphql-queries';
import { getPath, getPaperBillingPath, getSessionStorageItem, flpValidation, getMembershipPackagePath } from 'utils';
import { getAuthHelper } from 'utils/auth';
import { default as request, updateContext } from 'utils/GraphQLClient';
import { getLocalStorageItem, clearLocalStorage } from 'utils/localStorage';
import { clearSessionStorage, STORE_KEY } from 'utils/sessionStorage';
import {
  getApplicationProgressRoute,
  Routes,
  StorageNames,
  MEMBERSHIP_APPLICATION_ROUTES,
  FCMA_CREDENTIAL_APPLICATION_ROUTES,
  MembershipPackageAccordionStatus,
} from 'constants/index';
import { loginRequiredMetaData, addMetaData, triggerOnBoardingMetaData } from 'middlewares';
import { generatePath } from 'react-router-dom';
import { UserActionNames, initialState } from './constants';
import { rehydrateUserData } from 'modules/app';
import {
  User as UserTypes,
  Checkout,
  SmartyStreets,
  Salesforce,
  MembershipTypes,
  Cart,
  Product,
  PearsonVue,
  User,
  SFMC,
} from 'mxp-schemas';
import { getPasswordBufferString, getPersonAccountFlags } from './helpers';
import { ConsumerAuthHelper } from 'utils/auth/consumer';
import { isPremiumContentPageSelector } from '../router';
import { skipUserInfoRefreshSelector } from '../search/selectors';
import { CheckoutCountriesListHash, Admin as AdminUtils, CountriesList, Utils } from 'mxp-utils';
import { getAddressValidationScenario, getSmartyStreetsValidation } from 'modules/checkout/helpers';
import {
  addMembershipToCart,
  setCenterMembershipPackageType,
  setMembershipJourneyType,
  setMembershipPackageTier,
} from 'modules/membership';
import { getProductsListData, updatePreferredCurrency } from 'modules/products/actions';
import { setSavedItems } from 'modules/savedItems/actions';
import { setSavedBlogPosts } from 'modules/savedBlogPosts/actions';
import {
  isCenterMembershipJourneySelector,
  membershipInviteDataSelector,
  userMembershipPackageSelector,
  userMembershipTierSelector,
  userMembershipTypeSelector,
  currentMembershipProduct,
  isCenterMembershipRenewalSelector,
  isCimaMembershipJourneySelector,
  isCimaMembershipPageJourneySelector,
  isUserRegularAicpaMemberSelector,
  isCimaRegularMembershipSelector,
  hasExistingCredentialSelector,
  isUserNotFLPandSwitchOrUpgradeSelector,
} from 'modules/membership/selectors';
import {
  isPaperBillingSelector,
  getActionSelector,
  getTokenSelector,
  getInviteIdSelector,
} from 'modules/startup/selectors';
import {
  getMembershipApplicationTypeSelector,
  itemsPriceDataCartSelector,
  selectedMembershipSelector,
  cartCredentialsSelector,
} from 'modules/cart/selectors';
import { loading, removeCartItems } from 'modules/cart/actions';
import { addSalesforceAddress } from 'modules/address/actions';
import { skillsHashSelector } from 'modules/skills/selectors';
import {
  hasExistingZuoraPurchaseSelector,
  itemsPriceDataProductSelector,
  isCurrencyToggledByDropdownSelector,
} from 'modules/products/selectors';
import { store } from '../../store';
import { setIsCimaMembershipPageJourney, setIsFlpModalOpen, setMembershipEvent } from 'modules/membership/actions';
import { pleProfessionalBodiesSelector } from 'modules/personLevelExemption';
import { USCpaExamEnum } from 'mxp-schemas/dist/types/exemptionLevels';
import { selectedPassedUSCPAExamSelector } from 'modules/exemptionProfessional/selectors';

const genericToggleSubtopic = (
  preferences: State.skillPreferences,
  topicId: string,
  subtopicId: string,
  isOnEvent: boolean,
  options: { deleteTopic: boolean } = { deleteTopic: false }
) => {
  // next subtopics state calculation
  const subtopics = preferences[topicId] || [];
  const nextSubtopics = isOnEvent ? [...subtopics, subtopicId] : subtopics.filter(elem => elem !== subtopicId);

  return nextSubtopics.length
    ? { ...preferences, [topicId]: nextSubtopics }
    : Object.keys(preferences).reduce((acc: any, key: string) => {
        if (key === topicId) {
          if (options.deleteTopic) return acc;
          acc[key] = [];
          return acc;
        }
        acc[key] = preferences[key];
        return acc;
      }, {});
};

const getUserLocalStateAction = () => async (): Promise<State.Profile | null> => {
  const authHelper = getAuthHelper();
  const localUserData: State.Profile | null = getLocalStorageItem(StorageNames.userData) as State.Profile | null;
  if (!localUserData) return null;
  const userTokenData: { sub: string } | null = await authHelper.getUserTokenData();
  if (!userTokenData) return null;
  if (localUserData.email !== userTokenData.sub) return null;
  return localUserData;
};

// ------------------------------------
// Actions
// ------------------------------------
export const userLoading: any = createAction(UserActionNames.LOADING);

export const checkSession: any = createAction(UserActionNames.SESSION_EXISTS, () => async (dispatch: Dispatch) => {
  const authHelper = getAuthHelper();
  const isAuth = await authHelper.sessionExists();

  if (isAuth === UserTypes.SessionType.NO_SESSION) {
    const sessionRetainValues = [StorageNames.learningPathway, StorageNames.userPreferredCurrency];
    clearSessionStorage(STORE_KEY, sessionRetainValues);
    clearLocalStorage();
  }
  if (isAuth === UserTypes.SessionType.OKTA_SESSION) {
    return { isAuth: true, session: UserTypes.SessionType.OKTA_SESSION };
  }
  const returnAuth =
    isAuth === UserTypes.SessionType.EXTERNAL_SESSION
      ? { isAuth: false, session: UserTypes.SessionType.EXTERNAL_SESSION }
      : { isAuth: false, session: UserTypes.SessionType.NO_SESSION };
  return returnAuth;
});

export const getUserPlatform: any = createAction(
  UserActionNames.GET_USER_PLATFORM,
  (email: string) => async (dispatch: Dispatch) => {
    dispatch(userLoading());
    const platform = await request(GET_USER_PLATFORM, { email });

    return platform?.getUserPlatform;
  }
);

export const sendResetPasswordEmail: any = createAction(
  UserActionNames.SEND_RESET_PASSWORD_EMAIL,
  (email: string) => async () => {
    const result = await request(FORGOT_PASSWORD, { email });
    return result;
  }
);

export const sendRecoveryQuestionEmail: any = createAction(
  UserActionNames.SEND_UPDATED_RECOVERY_QUESTION_EMAIL,
  (sectionUpdated: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();

    const { userId, firstName, lastName, email } = state.user.data;
    const oktaId = userOktaIdSelector(state);

    const recoveryQuestion: UserTypes.RecoveryQuestion = {
      subscriberKey: userId,
      emailAddress: email,
      firstName,
      lastName,
      oktaId,
      sectionUpdated,
    };
    return request(UPDATE_RECOVERY_QUESTION, { recoveryQuestion })
      .then((response: any) => {
        if (response?.sendRecoveryQuestionEmail) {
          return { ...response, sectionUpdated };
        }
        return false;
      })
      .catch(err => {
        return false;
      });
  }
);

export const resetUserPassword: any = createAction(
  UserActionNames.RESET_USER_PASSWORD,
  (recoveryToken: string, newPassword: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(userLoading());
      const authHelper = getAuthHelper();
      if (!(authHelper instanceof ConsumerAuthHelper)) {
        return Promise.reject(`Can't change password if not consumer`);
      }
      return await authHelper.resetPassword(recoveryToken, newPassword);
    } catch (error) {
      if (error.errorCode === 'E0000105') {
        return Promise.reject({ errorCode: UserTypes.UserErrorCodes.RESET_PASS_TOKEN_EXPIRED });
      }
      if (error.errorCode === 'E0000011') {
        return Promise.reject({ errorCode: UserTypes.UserErrorCodes.RESET_PASS_TOKEN_INCORRECT });
      }
      if (error.errorCode === 'E0000080') {
        return Promise.reject({ errorCode: UserTypes.UserErrorCodes.RESET_PASS_PREVIOUSLY_USED });
      }
    }
  }
);

export const setAuth: any = createAction(UserActionNames.SET_AUTH);

export const getGoldenRecord: any = createAction(
  UserActionNames.GET_GOLDEN_RECORD,
  () =>
    async (
      dispatch: Dispatch,
      getState: () => State.Root
    ): Promise<{
      preferences: State.Preferences | null;
      industryPreferences: State.industryPreferences | null;
      trendPreferences: State.trendPreferences | null;
      skillPreferences: State.skillPreferences | null;
      shortcuts: State.Shortcut[] | null;
    }> => {
      const { getGoldenRecordByUserId: response }: any = await request(QUERY_GOLDEN_RECORD).catch(() => ({
        preferences: {},
        industryPreferences: {},
        trendPreferences: {},
        skillPreferences: {},
        shortcuts: [],
      }));
      const preferences: State.Preferences | null = response && response.preferences;
      const industryPreferences: State.industryPreferences | null = response && response.industryPreferences;
      const trendPreferences: State.trendPreferences | null = response && response.trendPreferences;
      const skillPreferences: State.skillPreferences | null = response && response.skillPreferences;
      const shortcuts: State.Shortcut[] | null = response && response.shortcuts;

      const state: State.Root = getState();
      const { pathname } = getLocation(state);
      const isRoutePreferences: boolean = pathname === getPath(Routes.PREFERENCES);

      if (preferences && industryPreferences && trendPreferences && skillPreferences && isRoutePreferences) {
        dispatch(push(getPath(Routes.FEED))); // sessionExist === true, hasPreferences === true redirect
      }

      return { preferences, industryPreferences, trendPreferences, skillPreferences, shortcuts };
    }
);

export const getUserState: any = createAction(
  UserActionNames.GET_USER_STATE,
  () => async (): Promise<Record<string, any> | null> => {
    return request(QUERY_USER_STATE)
      .then((response: any) => {
        return JSON.parse(response.getUserState);
      })
      .catch(err => {
        return null;
      });
  }
);

export const setUserState: any = createAction(
  UserActionNames.SET_USER_STATE,
  (valueMap: Record<string, any>) => async (): Promise<Record<string, any> | null> => {
    return request(MUTATE_USER_STATE, { valueMap: JSON.stringify(valueMap) })
      .then((response: any) => {
        return JSON.parse(response.setUserState);
      })
      .catch(err => {
        return null;
      });
  }
);

export const createGoldenRecord: any = createAction(
  UserActionNames.CREATE_GOLDEN_RECORD,
  (userPref: State.Preferences | null = null, options: { preventRedirect: boolean } = { preventRedirect: false }) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(userLoading());
      const state: State.Root = getState();
      const { state: locationState } = getLocation(state as any) as any;
      const feedPath: string = getPath(Routes.FEED);
      const redirectUri: string = locationState?.prevRoute || feedPath;
      const preferences: State.Preferences = userPref || userPrefSelector(state);
      return request(MUTATE_GOLDEN_RECORD_PREFERENCES, { preferences }).then(response => {
        if (options.preventRedirect) return response;
        dispatch(push(redirectUri));
        if (redirectUri !== feedPath) {
          dispatch(toggleModalOnboardingFinishedOpen());
        }
        return response;
      });
    }
);

export const createGoldenRecordCima: any = createAction(
  UserActionNames.CREATE_GOLDEN_RECORD_CIMA,
  (
      topicPref: State.Preferences | null = null,
      industryPref: State.industryPreferences | null = null,
      trendPref: State.trendPreferences | null = null,
      skillPref: State.skillPreferences | null = null,
      options: { preventRedirect: boolean; preferenceType?: string } = {
        preventRedirect: false,
      }
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(userLoading());
      const state: State.Root = getState();
      const { state: locationState } = getLocation(state as any) as any;
      const feedPath: string = getPath(Routes.FEED);
      const redirectUri: string = locationState?.prevRoute || feedPath;
      const preferences: State.Preferences = topicPref || userPrefSelector(state);
      const industryPreferences: State.industryPreferences = industryPref || userIndustryPrefSelector(state);
      const trendPreferences: State.trendPreferences = trendPref || userTrendPrefSelector(state);
      const skillPreferences: State.skillPreferences = skillPref || userSkillPrefSelector(state);
      return request(MUTATE_GOLDEN_RECORD_CIMA_PREFERENCES, {
        preferences,
        industryPreferences,
        trendPreferences,
        skillPreferences,
      }).then(res => {
        const response = { ...res, type: options?.preferenceType };

        if (options.preventRedirect) return response;
        dispatch(push(redirectUri));
        if (redirectUri !== feedPath) {
          dispatch(toggleModalOnboardingFinishedOpen());
        }
        return response;
      });
    }
);

// FixMe. loginFail and loginSuccess are created only for analytics events
export const loginFail: any = createAction(UserActionNames.LOGIN_FAIL);
export const loginSuccess: any = createAction(UserActionNames.LOGIN_SUCCESS, (payload: any) => payload);
export const setUserPasswordUpdated: any = createAction(UserActionNames.SET_USER_PASSWORD_UPDATED);
export const setUserError: any = createAction(UserActionNames.SET_USER_ERROR);

export const login: any = createAction(
  UserActionNames.LOGIN,
  (email: string, password: string, remember: boolean, redirectToOnboarding?: boolean) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(userLoading());
      const state: State.Root = getState();
      const location = getLocation(state) as any;
      const reloadAfterLogin: boolean = reloadAfterLoginSelector(state);
      const pathBeforeRehydration: string = location?.state?.prevRoute || location.pathname;
      const inviteId = getInviteIdSelector(state);
      const authHelper = getAuthHelper();
      const cimaMembershipPageJourney = isCimaMembershipPageJourneySelector(state);
      const isFromOfflineExemptionCalculator = pathBeforeRehydration === getPath(Routes.EXEMPTION_CALCULATOR_RESULT);
      const shouldRedirectToOnboarding =
        redirectToOnboarding &&
        !cimaMembershipPageJourney.isComingFromCimaMembershipPage &&
        !isFromOfflineExemptionCalculator;
      if (!(authHelper instanceof ConsumerAuthHelper)) {
        return Promise.reject(`Can't login if not consumer`);
      }
      const user = await authHelper.signInUser(email, password);

      if (user.errorCode || user.status === UserTypes.UserErrorCodes.LOCKED_OUT) {
        // but if there is an error, then we need to handle that.
        dispatch(loginFail());
        return Promise.reject({
          errorCode: user.errorCode || user.status,
          status: user.xhr ? user.xhr.status : 500,
        });
      }

      if (user.status === UserTypes.UserErrorCodes.PASSWORD_EXPIRED) {
        const result = await request(FORGOT_PASSWORD, { email, returnRecoveryTokenOnly: true });
        dispatch(push(`${generatePath(getPath(Routes.RESET_PASSWORD))}#${result?.forgotPassword?.recoveryToken}`));
        return user;
      }

      // If previous page is Vergic Calendar do a hard refresh as Vergic currently has issues on SPA
      // TODO: Follow up with Vergic. To be removed once Calendar is working on SPA
      if (pathBeforeRehydration === getPath(Routes.VERGIC_CALENDAR)) {
        (window as any).location.href = getPath(Routes.VERGIC_CALENDAR);
      }

      dispatch(setAuth(true));
      await dispatch(rehydrateUserData(true));

      if (inviteId) dispatch(push(`${getPath(Routes.MEMBERSHIP_FORM)}?inviteId=${inviteId}`));
      if (shouldRedirectToOnboarding) {
        dispatch(push(getPath(Routes.CIMA_ONBOARDING_PRACTICE_AREA_PREFERENCES)));
      }

      // if reloadAfterLogin is active then reload the page
      if (reloadAfterLogin || pathBeforeRehydration.includes('?') || isFromOfflineExemptionCalculator) {
        dispatch(push(pathBeforeRehydration));
      }

      // If previous page is part of application process redirect to home
      const blockedPathBeforeRehydration = [
        ...Object.keys(MEMBERSHIP_APPLICATION_ROUTES).map(route => getPath(route as Routes)),
        // FCMA application routes
        ...Object.keys(FCMA_CREDENTIAL_APPLICATION_ROUTES).map(route => getPath(route as Routes)),
      ];

      if (blockedPathBeforeRehydration.includes(pathBeforeRehydration)) {
        (window as any).location.href = getPath(Routes.HOME, { withoutParams: true });
      }

      if (state.membership.userChoice.type?.id && location.state.prevRoute === getPath(Routes.MEMBERSHIP_FORM)) {
        dispatch(setMembershipEvent('isUserLoggedOutClickedApplyMembership', false));
        dispatch(push(getMembershipPackagePath(MembershipPackageAccordionStatus.Tier)));
      }

      dispatch(loginSuccess(user));
      return user;
    }
);

export const logout: any = createAction(UserActionNames.LOGOUT, () => async (dispatch: Dispatch): Promise<void> => {
  const sessionRetainValues = [StorageNames.learningPathway, StorageNames.userPreferredCurrency];
  clearSessionStorage(STORE_KEY, sessionRetainValues);
  clearLocalStorage();

  const authHelper = getAuthHelper();
  await authHelper.signOutUser();
  // Logging out users will force a re-direct app-reload do not anything beyond this point
});

export const getLocalUser: any = createAction(UserActionNames.GET_LOCAL, getUserLocalStateAction);

export const getFlpValidationResult: any = createAction(
  UserActionNames.GET_FLP_VALIDATION_RESULT,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const cimaMembershipPageJourney = isCimaMembershipPageJourneySelector(state);
    const isCimaMember = isCimaMemberSelector(state);
    const { learningPathway } = personAccountDataSelector(state);
    const membershipTermType = cimaMembershipsTermTypeSelector(state);
    const studentProgression = studentProgressionSelector(state);
    const isUserCountryEmbargoed = isUserCountryEmbargoedSelector(state);

    const flpValidationResult = flpValidation(
      isCimaMember,
      learningPathway,
      membershipTermType,
      studentProgression,
      isUserCountryEmbargoed
    );

    if (cimaMembershipPageJourney.isComingFromCimaMembershipPage) {
      if (flpValidationResult.includes('/')) {
        dispatch(push(flpValidationResult));
      } else {
        dispatch(push(cimaMembershipPageJourney.previousPath));
        dispatch(setIsFlpModalOpen(true, flpValidationResult));
      }
      dispatch(setIsCimaMembershipPageJourney(false, ''));
    }
  }
);

export const getUser: any = createAction(
  UserActionNames.GET,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const { payload } = await dispatch(getMyProfile());
    dispatch(getProductsListData());
    dispatch(getFlpValidationResult());
    // Change to set since saveditems is already returned from profile
    dispatch(setSavedItems(payload.savedItems));
    dispatch(setSavedBlogPosts(payload.savedItemsBlogs));

    return payload;
  }
);

export const getNetforumProfile: any = createAction(
  UserActionNames.GET_NET_FORUM_USER,
  () => async (dispatch: Dispatch) => {
    dispatch(toggleNetforumLoading(true));

    const { netforumProfile } = await request(QUERY_NET_FORUM_USER);

    return netforumProfile;
  }
);

export const getMarketingPreferences: any = createAction(
  UserActionNames.GET_USER_MARKETING_PREFERENCES,
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(toggleMarketingPreferencesLoading(true));
      const marketingPreferencesResponse = await request(QUERY_MARKETING_PREFERENCES);

      return marketingPreferencesResponse?.getMarketingPreferences;
    } catch (error) {
      return Promise.reject({
        errorCode: UserTypes.UserErrorCodes.CONTACT_PREFERENCES_ERROR,
      });
    }
  }
);

export const updateMarketingPreferences: any = createAction(
  UserActionNames.UPDATE_USER_MARKETING_PREFERENCES,
  (marketingPreferences: UserTypes.MarketingPreferences) => async (dispatch: Dispatch) => {
    try {
      dispatch(toggleMarketingPreferencesLoading(true));
      const marketingPreferencesResponse = await request(MUTATE_MARKETING_PREFERENCES, marketingPreferences);
      return marketingPreferencesResponse?.updateMarketingPreferences;
    } catch (error) {
      return Promise.reject({
        errorCode: UserTypes.UserErrorCodes.CONTACT_PREFERENCES_ERROR,
      });
    }
  }
);
export const refreshUserDataForPremiumContent: any = createAction(
  UserActionNames.REFRESH_USER_DATA_PREMIUM_CONTENT,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const isPagePremiumContent: boolean = isPremiumContentPageSelector(state);
    const skipRefresh: boolean = skipUserInfoRefreshSelector(state);
    if (isPagePremiumContent && !skipRefresh) {
      try {
        await dispatch(refreshUserData());
      } catch (error) {
        return Promise.reject({
          errorCode: 'Problems refreshing user data',
        });
      }
    }
  }
);

export const refreshUserData: any = createAction(
  UserActionNames.REFRESH_USER_DATA,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const isAuth: boolean | null = isAuthSelector(state);
    if (isAuth) {
      try {
        await dispatch(checkSession());
        await dispatch(getLocalUser());
        await dispatch(getUser());
      } catch (error) {
        return Promise.reject({
          errorCode: 'Problems refreshing user data',
        });
      }
    }
  }
);

export const toggleMarketingPreferencesLoading: any = createAction(
  UserActionNames.TOGGLE_MARKETING_PREFERENCES_LOADING,
  (status: boolean) => async (dispatch: Dispatch) => {
    return status;
  }
);

export const toggleNetforumLoading: any = createAction(
  UserActionNames.TOGGLE_NET_FORUM_LOADING,
  (status: boolean) => async (dispatch: Dispatch) => {
    return status;
  }
);

export const checkUserExists: any = createAction(
  UserActionNames.CHECK_USER_EXISTS,
  (email: string) => async (dispatch: Dispatch) => {
    const registrationPlatform = await request(GET_USER_PLATFORM, { email });
    return {
      userExists:
        registrationPlatform?.getUserPlatform === UserTypes.UserPlatform.LEGACY ||
        registrationPlatform?.getUserPlatform === UserTypes.UserPlatform.RAVE,
    };
  }
);

export const clearUserError: any = createAction('user/CLEAR_USER_ERROR');
export const clearUserForgotEmail: any = createAction('user/CLEAR_USER_FORGOT_EMAIL');
export const clearUserPlatform: any = createAction('user/CLEAR_USER_PLATFORM');
export const clearResetEmailSendSuccess: any = createAction('user/CLEAR_RESET_EMAIL_SEND_SUCCESS');

export const getUserByAICPAIdAndLastName: any = createAction(
  UserActionNames.GET_USER_BY_AICPAID_LASTNAME,
  (id: number, lastName: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(userLoading());
      const result = await request(QUERY_USER_BY_ID, { id, lastName });
      return result?.getUserByAICPAIdAndLastName;
    } catch (e) {
      const errorCode = e?.primaryApplicationError?.message;
      return Promise.reject({
        errorCode,
      });
    }
  }
);

export const updateGoldenRecord: any = createAction(
  UserActionNames.UPDATE_GOLDEN_RECORD,
  (user: State.Profile) => (dispatch: Dispatch) => {
    dispatch(userLoading());
    const payload = {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    };
    return request(MUTATE_GOLDEN_RECORD_EMAIL, payload);
  }
);

export const toggleSubtopicPref: any = createAction(
  UserActionNames.TOGGLE_SUBTOPIC_PREF,
  (subtopicId: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const selectedTopicId: string | null = selectedTopicIdSelector(state);
    const preferences = userPrefSelector(state);

    if (!selectedTopicId) return preferences;

    const subtopics = preferences[selectedTopicId] || [];
    const isSubtopicIdInArray: boolean = subtopics.includes(subtopicId);
    const isOnEvent = !isSubtopicIdInArray;

    return genericToggleSubtopic(preferences, selectedTopicId, subtopicId, isOnEvent, { deleteTopic: true });
  }
);

export const selectAllSubtopics: any = createAction(
  UserActionNames.SELECT_ALL_SUBTOPICS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const selectedTopicId: string | null = selectedTopicIdSelector(state);
    if (!selectedTopicId) return;
    const topicsHash: State.TopicsHash | null = topicsHashSelector(state);
    if (!topicsHash) return;
    const subtopics: string[] = Object.keys(topicsHash[selectedTopicId].subtopics);
    return { [selectedTopicId]: subtopics };
  }
);

export const clearAllSubtopics: any = createAction(
  UserActionNames.CLEAR_ALL_SUBTOPICS,
  () => (dispatch: Dispatch, getState: () => State.Root) => selectedTopicIdSelector(getState())
);

export const backupSubtopics: any = createAction(UserActionNames.BACKUP_PREF);

export const resetSubtopics: any = createAction(UserActionNames.RESET_PREF);

export const clearUserPref: any = createAction(UserActionNames.CLEAR_PREF);

export const backupSubskills: any = createAction(UserActionNames.BACKUP_SKILL_PREF);

export const resetSubskills: any = createAction(UserActionNames.RESET_SKILL_PREF);

export const clearUserSkillPref: any = createAction(UserActionNames.CLEAR_SKILL_PREF);

export const toggleTopicPrefAndUpdateGoldenRecord: any = createAction(
  UserActionNames.TOGGLE_TOPIC_PREF_UPDATE_GOLDEN_RECORD,
  (
      topicId: string,
      isOnEvent: boolean,
      config: { confirmedToUnfollowTopicThatHasSubtopics: boolean } = {
        confirmedToUnfollowTopicThatHasSubtopics: false,
      }
    ) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const preferences: State.Preferences = userPrefSelector(state);
      const isUnfollowingLastTopic = !isOnEvent && Object.keys(preferences).length === 1;
      const isUnfollowingTopicThatHasSubtopics = !isOnEvent && Boolean(preferences?.[topicId].length);

      if (
        !config.confirmedToUnfollowTopicThatHasSubtopics &&
        (isUnfollowingLastTopic || isUnfollowingTopicThatHasSubtopics)
      ) {
        dispatch(
          toggleModalTopicUnfollow({
            topicId,
            subtopicId: null,
            isLastItem: isUnfollowingLastTopic,
          })
        );
        return preferences;
      }

      const modifiedPreferences = isOnEvent
        ? { ...preferences, [topicId]: [] }
        : Object.keys(preferences).reduce((acc: any, key: string) => {
            if (key === topicId) return acc;
            acc[key] = preferences[key];
            return acc;
          }, {});

      return request(MUTATE_GOLDEN_RECORD_PREFERENCES, { preferences: modifiedPreferences }).then(
        () => modifiedPreferences
      );
    },
  addMetaData(loginRequiredMetaData, triggerOnBoardingMetaData())
);

export const toggleSubtopicPrefAndUpdateGoldenRecord: any = createAction(
  UserActionNames.TOGGLE_SUBTOPIC_PREF_UPDATE_GOLDEN_RECORD,
  (topicId: string, subtopicId: string, isOnEvent: boolean) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const preferences: State.Preferences = userPrefSelector(state);
    const isUnfollowingLastSubtopic = !isOnEvent && preferences[topicId].length === 1;

    if (isUnfollowingLastSubtopic) {
      dispatch(
        toggleModalTopicUnfollow({
          topicId,
          subtopicId,
          isLastItem: true,
        })
      );
      return preferences;
    }
    const modifiedPreferences: State.Preferences = genericToggleSubtopic(preferences, topicId, subtopicId, isOnEvent);
    return request(MUTATE_GOLDEN_RECORD_PREFERENCES, { preferences: modifiedPreferences }).then(
      () => modifiedPreferences
    );
  },
  addMetaData(loginRequiredMetaData, triggerOnBoardingMetaData())
);

export const toggleIndustryPrefAndUpdateGoldenRecord: any = createAction(
  UserActionNames.TOGGLE_INDUSTRY_PREF_UPDATE_GOLDEN_RECORD,
  (industryId: string, isOnEvent: boolean) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const industryPreferences: State.industryPreferences = { ...userIndustryPrefSelector(state) };

    if (!isOnEvent) {
      delete industryPreferences[industryId];
    } else {
      industryPreferences[industryId] = [];
    }

    return request(MUTATE_GOLDEN_RECORD_CIMA_PREFERENCES, {
      industryPreferences,
    }).then(() => industryPreferences);
  },
  addMetaData(loginRequiredMetaData, triggerOnBoardingMetaData())
);

export const toggleTrendPrefAndUpdateGoldenRecord: any = createAction(
  UserActionNames.TOGGLE_TREND_PREF_UPDATE_GOLDEN_RECORD,
  (trendId: string, isOnEvent: boolean) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const trendPreferences: State.trendPreferences = { ...userTrendPrefSelector(state) };

    if (!isOnEvent) {
      delete trendPreferences[trendId];
    } else {
      trendPreferences[trendId] = [];
    }

    return request(MUTATE_GOLDEN_RECORD_CIMA_PREFERENCES, {
      trendPreferences,
    }).then(() => trendPreferences);
  },
  addMetaData(loginRequiredMetaData, triggerOnBoardingMetaData())
);

export const toggleSkillPrefAndUpdateGoldenRecord: any = createAction(
  UserActionNames.TOGGLE_SKILL_PREF_UPDATE_GOLDEN_RECORD,
  (
      skillId: string,
      isOnEvent: boolean,
      config: { confirmedToUnfollowSkillThatHasSubskills: boolean } = {
        confirmedToUnfollowSkillThatHasSubskills: false,
      }
    ) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const skillPreferences: State.skillPreferences = { ...userSkillPrefSelector(state) };

      if (!isOnEvent) {
        delete skillPreferences[skillId];
      } else {
        skillPreferences[skillId] = [];
      }

      return request(MUTATE_GOLDEN_RECORD_CIMA_PREFERENCES, {
        skillPreferences,
      }).then(() => skillPreferences);
    },
  addMetaData(loginRequiredMetaData, triggerOnBoardingMetaData())
);

export const toggleSubskillPrefAndUpdateGoldenRecord: any = createAction(
  UserActionNames.TOGGLE_SUBSKILL_PREF_UPDATE_GOLDEN_RECORD,
  (skillId: string, subskillId: string, isOnEvent: boolean) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const skillPreferences: State.skillPreferences = userSkillPrefSelector(state);

    if (!isOnEvent) {
      const subskills: string[] = skillPreferences[skillId].filter(id => id !== subskillId);
      skillPreferences[skillId] = subskills;
    } else {
      if (!skillPreferences[skillId]) {
        skillPreferences[skillId] = [subskillId];
      } else {
        const subskills: string[] = skillPreferences[skillId];
        subskills.push(subskillId);
        skillPreferences[skillId] = subskills;
      }
    }

    return request(MUTATE_GOLDEN_RECORD_CIMA_PREFERENCES, {
      skillPreferences,
    }).then(() => skillPreferences);
  },
  addMetaData(loginRequiredMetaData, triggerOnBoardingMetaData())
);

export const getUserFeedbackValues: any = createAction(
  UserActionNames.GET_CONTENT_FEEDBACK,
  (contentId: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const isAuth = isAuthSelector(state);

    if (!isAuth) return;

    return request(QUERY_USER_FEEDBACK_BY_CONTENT_ID, { contentId }).then(result => {
      return {
        ...result.getUserFeedbackByContentId,
        contentId,
      };
    });
  }
);

export const putUserFeedbackValues: any = createAction(
  UserActionNames.PUT_CONTENT_FEEDBACK,
  (contentId: string, feedback: State.UserFeedbackRecord) => async () => {
    return request(MUTATE_USER_FEEDBACK_BY_CONTENT_ID, { contentId, feedback }).then(result => ({
      ...result.updateUserFeedbackByContentId,
      contentId,
    }));
  },
  addMetaData(loginRequiredMetaData)
);

// This is specific for feedback surveys and we don't need to keep them on state. So no reducer.
export const putUserSurveyFeedbackValues: any = createAction(
  UserActionNames.PUT_SURVEY_FEEDBACK,
  (survey: string, feedback: State.UserFeedbackRecord) => async () => {
    return request(MUTATE_USER_FEEDBACK_BY_SURVEY, { survey, feedback }).then(result => {
      if (!result || !result.updateUserFeedbackByContentId) {
        return {};
      }
      return {
        ...result.updateUserFeedbackByContentId,
      };
    });
  },
  addMetaData(loginRequiredMetaData)
);

export const toggleUserShortcuts: any = createAction(
  UserActionNames.TOGGLE_USER_SHORTCUTS,
  ({
      topicSlug,
      isOnEvent,
      subtopicSlug = '',
      categorySlug = '',
      slicedAggsFilter = '',
    }: Common.ToggleUserShortcutsProps) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const shortcuts: State.Shortcut[] = userShortcutsSelector(state) ? userShortcutsSelector(state) : [];
      const index: number = shortcuts.findIndex(
        (item: State.Shortcut) =>
          `${item.categorySlug || ''}${item.topicSlug}${item.subtopicSlug || ''}` ===
          `${categorySlug}${topicSlug}${subtopicSlug}`
      );
      const isAlreadyAddedAsShortcut: boolean = index !== -1;
      const nextShortcuts: State.Shortcut[] = isOnEvent
        ? isAlreadyAddedAsShortcut
          ? shortcuts
          : shortcuts.concat({ topicSlug, subtopicSlug, categorySlug, slicedAggsFilter })
        : shortcuts.filter((item: State.Shortcut, i: number) => i !== index);

      return request(MUTATE_GOLDEN_RECORD_SHORTCUTS, { shortcuts: nextShortcuts }).then(
        result => result.updateGoldenRecord.shortcuts
      );
    },
  addMetaData(loginRequiredMetaData, triggerOnBoardingMetaData())
);

export const goToProfilePreferences: any = createAction(
  UserActionNames.GOTO_PROFILE_PREFERENCES,
  () => (dispatch: Dispatch) => {
    return dispatch(push(getPath(Routes.PROFILE_PREFERENCES)));
  },
  addMetaData(loginRequiredMetaData, triggerOnBoardingMetaData({ isActionSkippedBeforeOnBoarding: true }))
);

export const invalidateSession: any = createAction(UserActionNames.INVALIDATE_SESSION);

// FixMe. createUserFailure and createUserSuccess are created only for analytics events
export const createUserFailure: any = createAction(UserActionNames.CREATE_USER_FAILED);
export const createUserSuccess: any = createAction(UserActionNames.CREATE_USER_SUCCESS, (payload: any) => payload);

export const setRegistrationLoading: any = createAction(UserActionNames.SET_REGISTRATION_LOADING);

export const resolveEmail: any = createAction(UserActionNames.SET_RESOLVE_EMAIL, (email: string) => async () => {
  return request(RESOLVE_EMAIL, { email }).then((data: any) => {
    return Boolean(data?.resolveEmail);
  });
});

export const createUser: any = createAction(
  UserActionNames.CREATE_USER,
  (newUser: UserTypes.NewUser) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const entryPage = entryPageSelector(state);
    const action = getActionSelector(state);
    const token = getTokenSelector(state);

    try {
      dispatch(userLoading());
      const { getPubKey: pubKey } = await request(GET_PUB_KEY);
      const password: string = getPasswordBufferString(newUser.password, pubKey);
      const createUserResponse = await request(CREATE_USER, {
        user: { ...newUser, password, source: entryPage },
        dependencies: { action, token },
      });
      dispatch(login(newUser.email, newUser.password, false, true));
      const user = {
        email: newUser.email,
        firstname: newUser.firstName,
        lastname: newUser.lastName,
        id: createUserResponse.createUser.id,
        AICPAUID: createUserResponse.createUser.AICPAUID,
        exclusiveContent: newUser.exclusiveContent,
        businessContent: newUser.businessContent,
        isCPA: newUser.CPA,
        personContactId: createUserResponse.createUser.personContactId,
      };
      dispatch(createUserSuccess(user));
      return user;
    } catch (error) {
      dispatch(createUserFailure());
      dispatch(setRegistrationLoading(false));
      return Promise.reject({
        errorCode: UserTypes.UserErrorCodes.REGISTRATION_ERROR,
      });
    }
  }
);

export const changePassword: any = createAction(
  UserActionNames.CHANGE_PASSWORD,
  async (oldPassword: string, newPassword: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const { getPubKey: pubKey } = await request(GET_PUB_KEY);

    const encryptedOldPassword: string = getPasswordBufferString(oldPassword, pubKey);
    const encryptedNewPassword: string = getPasswordBufferString(newPassword, pubKey);
    const state = getState();
    const personDetails = personAccountDataSelector(state);
    const userDetails = {
      subscriberKey: personDetails.personContactId,
      emailAddress: personDetails.primaryEmail.emailAddress,
      oktaID: personDetails.oktaId,
      primaryAddress: personDetails.address?.addressLine1,
      firstName: personDetails.firstName,
      lastName: personDetails.lastName,
      primaryPhone: personDetails.primaryPhone?.phoneNumber,
      membershipType: state.user.data.memberType,
    };

    const response = await request(CHANGE_PASSWORD, {
      oldPassword: encryptedOldPassword,
      newPassword: encryptedNewPassword,
    });
    if (response.changePassword.success) {
      await request(CHANGE_PASSWORD_NOTIFICATION_EMAIL, { userDetails });
    }
    return response;
  }
);

export const fetchCPAStatus: any = createAction(UserActionNames.FETCH_CPA_STATUS, () => async () => {
  return request(QUERY_CPA_STATUS);
});

export const setCPAStatus: any = createAction(UserActionNames.SET_CPA_STATUS, (cpaFlag: boolean) => async () => {
  return request(MUTATE_CPA_STATUS, { cpaFlag });
});

export const cpaStatusLoading: any = createAction(UserActionNames.CPA_STATUS_LOADING);

export const sendUpdatedPasswordMail: any = createAction(
  UserActionNames.SEND_UPDATED_PASSWORD_EMAIL,
  (oktaId: string) => async () => {
    return request(MUTATE_UPDATED_PASSWORD_MAIL, { oktaId });
  }
);

export const getMyProfile: any = createAction(
  UserActionNames.GET_MY_PROFILE,
  (email: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const raveTraceId = localStorage.getItem('rave-trace-id');

    return request(QUERY_MY_PROFILE, {}, { type: 'customHeaders', value: `{"rave-trace-id":"${raveTraceId}"}` })
      .then((response: any) => {
        if (response?.getMyProfile) {
          return { ...response.getMyProfile, success: true };
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const getEPA2Submissions: any = createAction(
  UserActionNames.GET_EPA2_SUBMISSIONS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(GET_EPA2_SUBMISSIONS)
      .then((response: any) => {
        if (response?.getEPA2Submissions?.results) {
          return response.getEPA2Submissions;
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const getEPA2L7Submissions: any = createAction(
  UserActionNames.GET_EPA2L7_SUBMISSIONS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(GET_EPA2L7_SUBMISSIONS)
      .then((response: any) => {
        if (response?.getEPA2L7Submissions?.results) {
          return response.getEPA2L7Submissions;
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const updateAccountLoading: any = createAction(UserActionNames.UPDATE_PERSON_ACCOUNT_LOADING);
export const updatePersonAccount: any = createAction(
  UserActionNames.UPDATE_PERSON_ACCOUNT,
  (
      updateUser: Salesforce.PersonAccount,
      welcomeKit: boolean,
      preventRedirect = false,
      isMip = false,
      isMipContact = false
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const aicpaRegularMember = isUserRegularAicpaMemberSelector(state);
      const cimaRegularMember = isCimaRegularMembershipSelector(state);
      const suggestedAddress = updateUser.address;
      const application = applicationSelector(state);
      const isPaperBilling = isPaperBillingSelector(state);
      const learningPathway = learningPathwaySelector(state);
      const learningPathwayToSwitch = learningPathwayToSwitchSelector(state);
      const membershipApplicationType = getMembershipApplicationTypeSelector(state);

      const isCimaMembershipType = Boolean(membershipApplicationType === Product.MembershipApplicationType.CIMA);
      const isCimaPathway = Boolean(learningPathway) || Boolean(learningPathwayToSwitch);
      const pleProfBodies = pleProfessionalBodiesSelector(state);
      const profBodies = pleProfBodies?.filter(
        (item: any) => (item !== null && item?.profBodyName) || item?.selectedPassedUSCPAExam
      );
      const licensedCPA =
        selectedPassedUSCPAExamSelector(state) === USCpaExamEnum.YES_LICENSED_BEFORE ||
        (profBodies.length === 1 && profBodies[0].selectedPassedUSCPAExam === USCpaExamEnum.YES_LICENSED_BEFORE);

      const isCIMA = isCimaMembershipType || isCimaPathway;
      const isApprenticeL4SwitchingPathway =
        learningPathway === MembershipTypes.Pathway.APPRENTICE_L4 && Boolean(learningPathwayToSwitch);
      const isCimaPQSwitchingPathway =
        learningPathway === MembershipTypes.Pathway.PQ && Boolean(learningPathwayToSwitch);
      const applicationFormRoute = getApplicationProgressRoute(
        isApprenticeL4SwitchingPathway
          ? UserTypes.MembershipApplicationStages.ATTESTATION
          : isCimaPQSwitchingPathway || isCIMA
          ? licensedCPA && !aicpaRegularMember && !cimaRegularMember
            ? UserTypes.MembershipApplicationStages.CPA
            : UserTypes.MembershipApplicationStages.EMPLOYMENT
          : UserTypes.MembershipApplicationStages.QUALIFICATION,
        membershipApplicationType || (isCimaPathway && Product.MembershipApplicationType.CIMA)
      );

      let transformedSuggestedAddress;
      if (suggestedAddress?.addressLine1) {
        transformedSuggestedAddress = {
          ...(suggestedAddress.id && { id: suggestedAddress.id }),
          addressLine1: suggestedAddress.addressLine1 || updateUser.address.addressLine1,
          addressLine2: suggestedAddress.addressLine2 || updateUser.address.addressLine2,
          city: suggestedAddress.city || updateUser.address.city,
          state: suggestedAddress.state || updateUser.address.state,
          zipCode: suggestedAddress.zipCode || updateUser.address.zipCode,
          country: suggestedAddress.country || updateUser.address.country,
        };
      }

      if (updateUser.ethnicity !== 'None_of_the_above') updateUser.ethnicitySpecific = '';

      const personAccountInfo = {
        id: updateUser.id,
        individualId: updateUser.individualId,
        primaryEmail: updateUser.primaryEmail,
        secondaryEmail: updateUser.secondaryEmail,
        primaryPhone: updateUser.primaryPhone,
        secondaryPhone: updateUser.secondaryPhone,
        phones: updateUser.phones,
        address: transformedSuggestedAddress,
        firstName: updateUser.firstName,
        lastName: updateUser.lastName,
        gender: (updateUser.genderConsent ? updateUser.gender : '') || '',
        genderSpecific: (updateUser.genderConsent ? updateUser.genderSpecific : '') || '',
        genderConsent: updateUser.genderConsent,
        ethnicity: (updateUser.ethnicityConsent ? updateUser.ethnicity : '') || '',
        ethnicitySpecific: (updateUser.ethnicityConsent ? updateUser.ethnicitySpecific : '') || '',
        ethnicityConsent: updateUser.ethnicityConsent,
        birthDate: updateUser.birthDate,
        uniqueLearningNumber: (updateUser.uniqueLearningNumber ?? '') || '',

        // FCMA personal form fields
        ...(updateUser.salutation && { salutation: updateUser.salutation }),
        ...(updateUser.nationality && { nationality: updateUser.nationality }),
        ...(updateUser.cimaAssocDate && { cimaAssocDate: updateUser.cimaAssocDate }),
      };

      const personAccountFlags: Salesforce.PersonAccountFlags = {
        isNewUser: !updateUser?.id,
        thirdPartyChangedFields: [
          Salesforce.thirdPartyFieldsType.FIRSTNAME,
          Salesforce.thirdPartyFieldsType.LASTNAME,
          Salesforce.thirdPartyFieldsType.PRIMARY_EMAIL_ADD,
          Salesforce.thirdPartyFieldsType.SECONDARY_EMAIL_ADD,
          Salesforce.thirdPartyFieldsType.PRIMARY_PHONE,
          Salesforce.thirdPartyFieldsType.SECONDARY_PHONE,
          Salesforce.thirdPartyFieldsType.PRIMARY_ADDRESS,
        ],
      };

      if (isMip && !isMipContact) {
        await dispatch(
          updateApplication({
            ...application,
            applicationProgress: '',
            applicationPart: {
              ...application.applicationPart,
            },
          })
        );
      }
      const { pathname } = getLocation(state);
      return request(MUTATE_PERSON_ACCOUNT, { personAccountInfo, personAccountFlags })
        .then((response: any) => {
          if (response?.updatePersonAccount) {
            if (!preventRedirect) {
              dispatch(
                updateApplication({
                  ...application,
                  applicationProgress: isApprenticeL4SwitchingPathway
                    ? UserTypes.MembershipApplicationStages.ATTESTATION
                    : isCimaPQSwitchingPathway || isCIMA
                    ? licensedCPA && !aicpaRegularMember && !cimaRegularMember
                      ? UserTypes.MembershipApplicationStages.CPA
                      : getPath(Routes.CIMA_MIP_CHECKOUT_ADDRESS) === pathname ||
                        getPath(Routes.CIMA_MIP_CHECKOUT_MIP_DETAILS) === pathname
                      ? UserTypes.MembershipApplicationStages.ADDRESS
                      : UserTypes.MembershipApplicationStages.CREDENTIALS
                    : UserTypes.MembershipApplicationStages.QUALIFICATION,
                  applicationPart: {
                    ...application.applicationPart,
                    welcomeKit,
                  },
                })
              );
              if (!!applicationFormRoute) {
                dispatch(push(getPaperBillingPath(applicationFormRoute, isPaperBilling)));
              }
            }
            return { ...response, success: true };
          }
          return { success: false };
        })
        .catch(() => {
          return { success: false };
        });
    }
);

export const updatePersonalInformationLoading: any = createAction(UserActionNames.UPDATE_PERSONAL_INFORMATION_LOADING);
export const updatePersonalInformation: any = createAction(
  UserActionNames.UPDATE_PERSONAL_INFORMATION,
  (
      updateUser: Salesforce.PersonAccount,
      cpaLicense: Salesforce.CPALicense[],
      personAccountFlags: Salesforce.PersonAccountFlags
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const cpaLicenseData = cpaLicenseDataSelector(state);
      const personAccountData = personAccountDataSelector(state);
      const personAccountId = personAccountIdSelector(state);
      const applicationInfo = applicationSelector(state);

      const primaryEmail: Salesforce.ContactPointEmail = {
        id: updateUser.primaryEmail.id || personAccountData.primaryEmail.id || '',
        emailAddress: updateUser.primaryEmail.emailAddress || personAccountData.primaryEmail.emailAddress || '',
        emailType: updateUser.primaryEmail.emailType || personAccountData.primaryEmail.emailType || 'Personal',
        isPrimary: updateUser.primaryEmail.isPrimary || personAccountData.primaryEmail.isPrimary || true,
      };
      const secondaryEmail: Salesforce.ContactPointEmail = {
        id: updateUser.secondaryEmail.id || personAccountData.secondaryEmail.id || '',
        emailAddress: updateUser.secondaryEmail.emailAddress || '',
        emailType: updateUser.secondaryEmail.emailType || personAccountData.secondaryEmail.emailType || '',
        isPrimary: updateUser.secondaryEmail.isPrimary ? updateUser.secondaryEmail.isPrimary : '' || false,
      };
      const primaryPhone: Salesforce.ContactPointPhone = {
        id: updateUser.primaryPhone.id || personAccountData.primaryPhone.id || '',
        phoneNumber: updateUser.primaryPhone.phoneNumber || personAccountData.primaryPhone.phoneNumber || '',
        countryCode: updateUser.primaryPhone.countryCode || personAccountData.primaryPhone.countryCode || '',
        phoneType: updateUser.primaryPhone.phoneType || personAccountData.primaryPhone.phoneType || '',
        isPrimary: updateUser.primaryPhone.isPrimary || personAccountData.primaryPhone.isPrimary || true,
      };
      const secondaryPhone: Salesforce.ContactPointPhone = {
        id: updateUser.secondaryPhone.id || personAccountData.secondaryPhone.id || '',
        phoneNumber: updateUser.secondaryPhone.phoneNumber || '',
        countryCode: updateUser.secondaryPhone.countryCode || personAccountData.secondaryPhone.countryCode || '',
        phoneType: updateUser.secondaryPhone.phoneType || personAccountData.secondaryPhone.phoneType || '',
        isPrimary: updateUser.secondaryPhone.isPrimary || personAccountData.secondaryPhone.isPrimary || false,
      };

      const address: Salesforce.ContactPointAddress = {
        ...(updateUser.address || personAccountData.address),
        lat: updateUser?.address?.lat?.toString() || personAccountData?.address?.lat?.toString(),
        long: updateUser?.address?.long?.toString() || personAccountData?.address?.long?.toString(),
      };

      // Change to match Salesforce Picklist values
      // TODO: gender dropdown needs refactoring to match salesforce picklist
      if (updateUser.gender === MembershipTypes.Gender.GENDERQUEER_OR_NONBINARY) updateUser.gender = 'Transgender';
      if (updateUser.gender === MembershipTypes.Gender.AGENDER) updateUser.gender = 'Nonbinary';
      if (updateUser.gender === MembershipTypes.Gender.PREFER_NO_TO_SHARE) updateUser.gender = 'I prefer not to say';
      if (updateUser.gender !== 'I’d like to type a response') updateUser.genderSpecific = '';
      if (updateUser.ethnicity !== 'None_of_the_above') updateUser.ethnicitySpecific = '';

      const personAccountInfo = {
        id: updateUser.id || personAccountData.id,
        individualId: updateUser.individualId || personAccountData.individualId,
        primaryEmail,
        secondaryEmail,
        emails: updateUser.emails,
        primaryPhone,
        secondaryPhone,
        phones: updateUser.phones,
        address,
        firstName: updateUser.firstName || personAccountData.firstName,
        lastName: updateUser.lastName || personAccountData.lastName,
        gender: updateUser.gender || '',
        genderSpecific: updateUser.genderSpecific || '',
        genderConsent: updateUser.genderConsent || false,
        ethnicity: updateUser.ethnicity || '',
        ethnicitySpecific: updateUser.ethnicitySpecific || '',
        ethnicityConsent: updateUser.ethnicityConsent || false,
        birthDate: updateUser.birthDate || personAccountData.birthDate || '',
        linkedinURL: updateUser.linkedinURL || personAccountData.linkedinURL || '',
        ethicsStatus:
          updateUser.ethicsStatus || personAccountData.ethicsStatus || MembershipTypes.EthicsStatusEnum.NONE,
        learningPathway: updateUser?.learningPathway,
        nationalIdentityNumber: updateUser?.nationalIdentityNumber,
        alternativeIdType: updateUser?.alternativeIdType,
        hasNationalId: updateUser?.hasNationalId,
        alternativeIdNumber: updateUser?.alternativeIdNumber,
        hasDisability: updateUser?.hasDisability,
        disabilityType: updateUser?.disabilityType,
      };

      const mapCpaLicense: Salesforce.CPALicense[] = cpaLicense.map(item => {
        return {
          ...item,
          personAccountId,
          licenseStatus: item.licenseStatus || '',
        };
      });

      const cpaLicenseInfo: Salesforce.CPALicense[] = [...(mapCpaLicense || cpaLicenseData)]
        .filter(
          (item: Salesforce.CPALicense) =>
            !(
              item.licenseIssueDate === '' &&
              item.licenseNumber === '' &&
              item.licenseState === '' &&
              item.licenseStatus === ''
            )
        )
        .map((item: Salesforce.CPALicense) => ({
          ...item,
          licenseIssueDate: item.licenseIssueDate || '',
          licenseNumber: item.licenseNumber || '',
          licenseState: item.licenseState || '',
          licenseStatus: item.licenseStatus || '',
        }));

      return request(MUTATE_PERSONAL_INFORMATION, {
        personAccountInfo,
        cpaLicenseInfo,
        applicationInfo,
        personAccountFlags,
      })
        .then((response: any) => {
          if (response?.updatePersonAccount) {
            return { ...response, success: true };
          }
          return { success: false };
        })
        .catch(err => {
          return { success: false };
        });
    }
);

export const validateAddress: any = createAction(
  UserActionNames.VALIDATE_ADDRESS,
  (address: Checkout.Address) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const isUsa =
      address.country === CheckoutCountriesListHash.USA.text ||
      address.country === CheckoutCountriesListHash.USA.ISOAlpha3Code;

    const response = isUsa
      ? await dispatch(getUSAddressFormValidation(address, {}))
      : await dispatch(getIntlAddressValidation(address, {}));

    const resAddress = response.payload?.smartystreetsValidation?.shippingAddress;
    const reason = resAddress?.reason || '';
    const scenario: SmartyStreets.ValidationCheckoutToggle = getAddressValidationScenario(reason);

    if (
      scenario !== SmartyStreets.ValidationCheckoutToggle.INVALID &&
      scenario !== SmartyStreets.ValidationCheckoutToggle.SECONDARY &&
      scenario === SmartyStreets.ValidationCheckoutToggle.MODAL
    ) {
      const openModal = true;
      dispatch(toggleModalAddressValidationOpen({ isAddressValidationModalOpen: openModal }));
    }

    return {
      ...response.payload,
      secondaryAddressNeeded: scenario === SmartyStreets.ValidationCheckoutToggle.SECONDARY,
    };
  }
);

export const validatePersonAddressLoading: any = createAction(UserActionNames.VALIDATE_PERSON_ADDRESS_LOADING);
export const validatePersonAddress: any = createAction(
  UserActionNames.VALIDATE_PERSON_ADDRESS,
  (address: Checkout.Address) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const response = await dispatch(validateAddress(address));
    return response.payload;
  }
);

export const validateOrganizationAddressLoading: any = createAction(
  UserActionNames.VALIDATE_ORGANIZATION_ADDRESS_LOADING
);
export const validateOrganizationAddress: any = createAction(
  UserActionNames.VALIDATE_ORGANIZATION_ADDRESS,
  (address: Checkout.Address) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const response = await dispatch(validateAddress(address));
    return response.payload;
  }
);

export const getUSAddressFormValidation: any = createAction(
  'user/GET_US_ADDRESS_VALIDATION',
  (address: State.Address) => () => {
    return getSmartyStreetsValidation(address);
  }
);

export const getIntlAddressValidation: any = createAction(
  'user/GET_INTL_ADDRESS_VALIDATION',
  (address: State.Address) => () => {
    return getSmartyStreetsValidation(address);
  }
);

export const processQualificationLoading: any = createAction(UserActionNames.PROCESS_QUALIFICATION_LOADING);
export const processQualification: any = createAction(
  UserActionNames.PROCESS_QUALIFICATION,
  (cpaLicense: Salesforce.CPALicense, applicationConsent: Salesforce.ApplicationPart, redirectToDonations: boolean) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const cpaLicenseList = cpaLicenseListDataSelector(state);
      const hasCredentials = cartCredentialsSelector(state);
      const isUserMember = isUserMemberSelector(state);

      const personAccountFlags = getPersonAccountFlags(cpaLicense, cpaLicenseList);

      const personAccountId = personAccountIdSelector(state);
      const application = applicationSelector(state);
      const isPaperBilling = isPaperBillingSelector(state);

      const membershipApplicationType = getMembershipApplicationTypeSelector(state);
      const applicationEmploymentRoute = getApplicationProgressRoute(
        UserTypes.MembershipApplicationStages.EMPLOYMENT,
        membershipApplicationType
      );

      const applicationCredentialRoute = getApplicationProgressRoute(
        UserTypes.MembershipApplicationStages.CREDENTIALS,
        membershipApplicationType
      );

      const applicationDonationRoute = getApplicationProgressRoute(
        UserTypes.MembershipApplicationStages.DONATION,
        membershipApplicationType
      );

      const cpaLicenseInfo = [
        {
          id: cpaLicense.id ? cpaLicense.id : '',
          licenseNumber: cpaLicense.licenseNumber ? cpaLicense.licenseNumber : '',
          licenseIssueDate: cpaLicense.licenseIssueDate ? cpaLicense.licenseIssueDate : '',
          licenseState: cpaLicense.licenseState ? cpaLicense.licenseState : '',
          licenseStatus: cpaLicense.licenseStatus ? cpaLicense.licenseStatus : '',
          personAccountId: cpaLicense.personAccountId ? cpaLicense.personAccountId : '',
        },
      ];
      const donationRedirection = () => {
        if (!redirectToDonations) {
          return UserTypes.MembershipApplicationStages.EMPLOYMENT;
        }
        return UserTypes.MembershipApplicationStages.DONATION;
      };
      const applicationInfo: Salesforce.Application = {
        id: application.id,
        personAccountId,
        applicationProgress: donationRedirection(),
        ...(applicationConsent && {
          applicationPart: {
            id: application.applicationPart.id,
            ...applicationConsent,
          },
        }),
      };

      dispatch(updateApplicationPart(applicationConsent));
      return request(MUTATE_PROCESS_QUALIFICATION, { cpaLicenseInfo, applicationInfo, personAccountFlags })
        .then((response: any) => {
          if (response?.processQualification) {
            if (redirectToDonations && !!applicationDonationRoute) {
              if (!!hasCredentials?.length && !isUserMember) {
                // If there's a credential redirect to there first
                dispatch(push(getPaperBillingPath(applicationCredentialRoute, isPaperBilling)));
              } else {
                dispatch(push(getPaperBillingPath(applicationDonationRoute, isPaperBilling)));
              }
            } else {
              if (!!applicationEmploymentRoute) {
                dispatch(push(getPaperBillingPath(applicationEmploymentRoute, isPaperBilling)));
              }
            }
            return { ...response, success: true, skipEmploymentPage: redirectToDonations };
          }
          return { success: false };
        })
        .catch(() => {
          return { success: false };
        });
    }
);

export const updateApplicationPart: any = createAction(
  UserActionNames.UPDATE_APPLICATION_PART,
  (applicationConsent: Salesforce.ApplicationPart) => async (dispatch: Dispatch) => {
    return applicationConsent;
  }
);

export const updateCurrentLearningPathway: any = createAction(
  UserActionNames.UPDATE_CURRENT_LEARNING_PATHWAY,
  (slug: string, personAccountId: string, applicationId: string) => async (dispatch: Dispatch) => {
    let pathway: string = '';
    switch (slug) {
      case MembershipTypes.CIMALearningPathwaySlug.CIMA_PQ:
        pathway = MembershipTypes.Pathway.PQ;
        break;
      case MembershipTypes.CIMALearningPathwaySlug.FLP:
        pathway = MembershipTypes.Pathway.FLP;
        break;
      case MembershipTypes.CIMALearningPathwaySlug.CHINESE_PQ:
        pathway = MembershipTypes.Pathway.CHINESE_PQ;
        break;
      case MembershipTypes.CIMALearningPathwaySlug.APPRENTICESHIP:
      case MembershipTypes.TierCode.APPRENTICE_L4:
        pathway = MembershipTypes.Pathway.APPRENTICE_L4;
        break;
      case MembershipTypes.TierCode.APPRENTICE_L7:
        pathway = MembershipTypes.Pathway.APPRENTICE_L7;
        break;
    }

    if (!pathway) {
      return { success: false };
    }

    return request(MUTATE_CURRENT_LEARNING_PATHWAY, {
      learningPathway: pathway,
      personAccountId,
      applicationId,
    })
      .then((response: any) => {
        return { ...response.updateLearningPathwayApplication, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const processTLW: any = createAction(
  UserActionNames.PROCESS_USER_TLW,
  (tlw: { id?: string; aicpaUid: string; tlwReason: string; tlwEndDate: string; employmentStatus: string }) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const tlwInfo = {
        id: tlw.id,
        tlwReason: tlw.tlwReason,
        tlwEndDate: tlw.tlwEndDate,
        aicpaUid: tlw.aicpaUid,
      };
      return request(MUTATE_TLW, { tlwInfo })
        .then((response: any) => {
          if (response) {
            return {
              id: response?.processTLW?.id,
              tlwReason: tlwInfo.tlwReason,
              tlwEndDate: tlwInfo.tlwEndDate,
              employmentStatus: tlw.employmentStatus,
              isTLW: true,
            };
          }
          return { success: false };
        })
        .catch(() => {
          return { success: false };
        });
    }
);

export const removeTLW: any = createAction(
  UserActionNames.REMOVE_USER_TLW,
  (id: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(REMOVE_TLW, { id })
      .then((response: any) => {
        return { success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const searchOrganizationsByWildcardLoading: any = createAction(
  UserActionNames.QUERY_ORGANIZATIONS_BY_WILDCARD_LOADING
);

export const searchEmployer: any = createAction(
  UserActionNames.QUERY_SEARCH_EMPLOYER,
  (searchEmployerForm: any) => async (dispatch: Dispatch) => {
    return request(SEARCH_EMPLOYER, {
      employerDetails: {
        ...searchEmployerForm,
      },
    })
      .then((response: any) => {
        return { ...response, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const searchOrganizationsByWildcard: any = createAction(
  UserActionNames.QUERY_ORGANIZATIONS_BY_WILDCARD,
  (
      accountName: string,
      zipCode?: string,
      zoomInfoSearch?: boolean,
      tuitionProviderType?: string,
      isFromEmployment?: boolean
    ) =>
    async (dispatch: Dispatch) => {
      return request(QUERY_ORGANIZATIONS_BY_NAME, {
        accountName,
        isWildcard: true,
        returnAccountNameOnly: false,
        zoomInfoSearch,
        tuitionProviderType,
        zipCode,
        isFromEmployment,
      })
        .then((response: any) => {
          return { ...response, success: true };
        })
        .catch(() => {
          return { success: false };
        });
    }
);

export const searchOrganizationsCitiesLoading: any = createAction(UserActionNames.QUERY_ORGANIZATIONS_CITIES_LOADING);
export const searchOrganizationsCities: any = createAction(
  UserActionNames.QUERY_ORGANIZATIONS_CITIES,
  (accountName: string, cityName: string, zoomInfoSearch?: boolean, tuitionProviderType?: string) =>
    async (dispatch: Dispatch) => {
      return request(QUERY_ORGANIZATIONS_BY_NAME, {
        accountName,
        cityName,
        isCityNameWildcard: true,
        returnCityOnly: true,
        zoomInfoSearch,
        tuitionProviderType,
      })
        .then((response: any) => {
          return { ...response, success: true };
        })
        .catch(() => {
          return { success: false };
        });
    }
);

export const getOrganizationByNameAndCity: any = createAction(
  UserActionNames.QUERY_ORGANIZATION_BY_NAME_AND_CITY,
  (accountName: string, cityName: string) => async (dispatch: Dispatch) => {
    return request(QUERY_ORGANIZATIONS_BY_NAME, {
      accountName,
      cityName,
    })
      .then((response: any) => {
        return response.searchOrganizationsByName[0];
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const clearNewEmployerData: any = createAction(UserActionNames.CLEAR_NEW_EMPLOYER_DATA);
export const clearEmployerDataEmployers: any = createAction(UserActionNames.CLEAR_EMPLOYERDATA_EMPLOYERS);

export const upsertEmploymentDataLoading: any = createAction(UserActionNames.PROCESS_EMPLOYMENT_LOADING);

export const resetEmploymentDataEffect: any = createAction(UserActionNames.RESET_EMPLOYMENT_EFFECT);

export const upsertEmploymentData: any = createAction(
  UserActionNames.UPDATE_EMPLOYMENT,
  (
      employmentInfo: State.NewAccountPersonAccount,
      createNew = false,
      preventRedirect = false,
      isUpdateEmployment = false,
      isTrainingProvider = false,
      isForEmploymentModal = false,
      shouldRedirectToCredentialPage = false,
      isChinesePathwayJourney = false
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const storedNewEmployer = newEmployerSelector(state);
      employmentInfo.personAccountId = personAccountIdSelector(state);
      const application = applicationSelector(state);
      const isCenterMembershipJourney = isCenterMembershipJourneySelector(state);
      const { isPaidByFirm } = membershipInviteDataSelector(state);
      const isPaperBilling = isPaperBillingSelector(state);
      const currentUserEmployers = employersSelector(state);
      const membershipAppTypeSelector = getMembershipApplicationTypeSelector(state);
      const learningPathway = learningPathwaySelector(state);
      const learningPathwayToSwitch = learningPathwayToSwitchSelector(state);
      const isCimaMembershipJourney = isCimaMembershipJourneySelector(state);
      const isUserNotFLPandSwitchOrUpgrade = isUserNotFLPandSwitchOrUpgradeSelector(state);

      // firm billing
      const inviteData = membershipInviteDataSelector(state);

      const membershipApplicationType =
        !membershipAppTypeSelector && isCimaMembershipJourney && (learningPathway || learningPathwayToSwitch)
          ? Product.MembershipApplicationType.CIMA
          : membershipAppTypeSelector;

      const isCIMA = membershipApplicationType === Product.MembershipApplicationType.CIMA;
      let applicationFormRoute = getApplicationProgressRoute(
        isUserNotFLPandSwitchOrUpgrade(shouldRedirectToCredentialPage, isTrainingProvider, membershipApplicationType),
        membershipApplicationType
      );

      if (isChinesePathwayJourney) {
        applicationFormRoute = getApplicationProgressRoute(
          UserTypes.MembershipApplicationStages.DONATION,
          membershipApplicationType
        );
      }

      const isAccountExisting = Boolean(employmentInfo?.organization?.id);
      let userProfileEmployerAddress;
      let userProfileEmployerPhone;
      let variables = {};

      // if the organization in the form exist in the current user's connected employers
      if (createNew && !isCenterMembershipJourney && currentUserEmployers) {
        const existingEmployer = currentUserEmployers.some(
          (employer: any) =>
            employer.organization.id === employmentInfo.organization.id &&
            (inviteData?.inviteId ? employer?.relationshipType === employmentInfo?.relationshipType : true)
        );

        // If employer already exists
        if (existingEmployer) {
          return { employerExists: true, success: true };
        }
      }

      if (isAccountExisting) {
        // Update Employment Role
        if (!createNew && isUpdateEmployment) {
          return request(MUTATE_UPDATE_EMPLOYMENT, { employmentInfo })
            .then((response: any) => {
              if (response?.processEmployment || response?.updateEmployment) {
                if (!preventRedirect) {
                  dispatch(
                    updateApplication({
                      ...application,
                      applicationProgress: isCIMA
                        ? isTrainingProvider
                          ? UserTypes.MembershipApplicationStages.DOCUMENT
                          : UserTypes.MembershipApplicationStages.TRAINING
                        : UserTypes.MembershipApplicationStages.DONATION,
                    })
                  );
                  dispatch(push(getPaperBillingPath(applicationFormRoute, isPaperBilling)));
                }
                return { ...response, success: true };
              }
              return { success: false };
            })
            .catch(err => {
              return { success: false };
            });
        }

        // Check if user was trying to modify address of the employer
        if (
          !Utils.isEqualHelper(
            storedNewEmployer.organization.billingAddress,
            employmentInfo.organization.billingAddress
          )
        ) {
          userProfileEmployerAddress = {
            addressLine1: employmentInfo.organization.billingAddress.addressLine1,
            addressLine2: employmentInfo.organization.billingAddress.addressLine2,
            city: employmentInfo.organization.billingAddress.city,
            country: employmentInfo.organization.billingAddress.country,
            state: employmentInfo.organization.billingAddress.state,
            zipCode: employmentInfo.organization.billingAddress.zipCode,
          };
        }
        // Check if user was trying to modify phone of the employer
        if (storedNewEmployer.organization.phone !== employmentInfo.organization.phone) {
          userProfileEmployerPhone = employmentInfo.organization.phone;

          // Interpolates the extension and/or areaCode if exist in the phone number
          if (employmentInfo?.organization?.extension || employmentInfo?.organization?.areaCode) {
            const hasExtension = !!employmentInfo?.organization?.extension;
            const hasAreaCode = !employmentInfo?.organization?.areaCode;
            // following this format: (area code)+phone+(extension)
            // e.g.: (880) +1 8015559864 (ext.805)
            userProfileEmployerPhone = `${
              hasAreaCode ? `(${employmentInfo?.organization?.areaCode}) ` : ''
            }${userProfileEmployerPhone}${hasExtension ? ` (ext.${employmentInfo?.organization?.extension})` : ''}`;
          }
        }
        // check if is Paid by firm
        variables =
          isPaidByFirm || isTrainingProvider
            ? { ...variables, employmentInfo }
            : {
                ...variables,
                employmentInfo,
                userProfileEmployerAddress,
                userProfileEmployerPhone,
                isForEmploymentModal,
              };
      } else {
        variables = { ...variables, employmentInfo };
      }

      const firstParam: any = Boolean(Object.keys(variables || {}).length === 0) ? employmentInfo : variables;
      const employmentData = inviteData?.inviteId
        ? {
            ...firstParam,
            employmentInfo: {
              ...firstParam.employmentInfo,
              dataSharingConsent: true,
            },
          }
        : firstParam;

      return request(MUTATE_EMPLOYMENT, { ...employmentData, isForCenterMembership: isCenterMembershipJourney })
        .then((response: any) => {
          if (response?.processEmployment) {
            if (!preventRedirect) {
              dispatch(
                updateApplication({
                  ...application,
                  applicationProgress: isCIMA
                    ? isTrainingProvider
                      ? UserTypes.MembershipApplicationStages.DOCUMENT
                      : UserTypes.MembershipApplicationStages.TRAINING
                    : UserTypes.MembershipApplicationStages.DONATION,
                })
              );
              dispatch(push(getPaperBillingPath(applicationFormRoute, isPaperBilling)));
            }
            return { ...response, success: true };
          }
          return { success: false };
        })
        .catch(() => {
          return { success: false };
        });
    }
);

export const removeEmploymentData: any = createAction(
  UserActionNames.REMOVE_EMPLOYMENT,
  (id: string, accountId: string) => async () => {
    return request(MUTATE_REMOVE_EMPLOYMENT, { id, accountId })
      .then((response: any) => {
        if (response?.removeEmployment) {
          const res = { employers: [...response.removeEmployment], success: true };
          return res;
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const upsertEmploymentDataStatus: any = createAction(
  UserActionNames.UPDATE_PERSON_ACCOUNT_STATUS,
  (id: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const personAccountId = personAccountIdSelector(state);

    return request(MUTATE_UPDATE_PERSON_ACCOUNT_STATUS, {
      id,
      personAccountId,
    })
      .then((response: any) => {
        if (response?.updateAccountPersonAccountStatus) {
          return { ...response, success: true };
        }
        return { success: false };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const updateEmploymentDataSharing: any = createAction(
  UserActionNames.MUTATE_UPDATE_EMPLOYMENT_DATA_SHARING,
  (employmentDataSharing: Salesforce.EmploymentSharingUpdate) => async () => {
    return request(MUTATE_UPDATE_EMPLOYMENT_DATA_SHARING, employmentDataSharing);
  }
);

export const searchUniversitiesByWildcardLoading: any = createAction(UserActionNames.QUERY_UNIVERSITIES_LOADING);
export const searchUniversitiesByWildcard: any = createAction(
  UserActionNames.QUERY_UNIVERSITIES_BY_WILDCARD,
  (accountName: string, tuitionProviderType?: Salesforce.TuitionProviderType) => async (dispatch: Dispatch) => {
    return request(QUERY_ORGANIZATIONS_BY_NAME, {
      accountName,
      isWildcard: true,
      returnAccountNameOnly: true,
      isUniversity: true,
      tuitionProviderType,
    })
      .then((response: any) => {
        return { ...response, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const processLeavingCredentialApplicationPage: any = createAction(
  UserActionNames.PROCESS_LEAVING_CREDENTIAL_APPLICATION_PAGE,
  (finraNumber: string = '', enhancedMemberDirectoryConsent: boolean = false) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const { id: sfAccountId } = personAccountDataSelector(state);
      const application = applicationSelector(state);

      return request(PROCESS_LEAVING_CREDENTIAL_APPLICATION_PAGE, {
        applicationInfo: {
          ...application,
          applicationProgress: User.MembershipApplicationStages.ATTESTATION,
        },
        sfAccountId,
        finraNumber,
        enhancedMemberDirectoryConsent,
      });
    }
);

export const updateApplication: any = createAction(
  UserActionNames.UPDATE_APPLICATION,
  (application: Salesforce.Application, fromCredentialPage: boolean = false) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const personAccountId = personAccountIdSelector(state);
      const isUserMember = isUserMemberSelector(state);
      const currentMembership = currentMembershipProduct(state);
      const selectedMembership = selectedMembershipSelector(state);
      const isCenterMembershipRenewal = isCenterMembershipRenewalSelector(state);
      const hasCredentialInCart = cartCredentialsSelector(state)?.length || false;
      const hasExistingCredential = hasExistingCredentialSelector(state);
      const isAicpaMember = isAicpaMemberSelector(state);
      const isCredentialSignUp = isAicpaMember && !hasExistingCredential && hasCredentialInCart;

      // if renewal no need create new application only in type change.
      if (
        isUserMember &&
        (currentMembership?.ctOrder?.productId === selectedMembership?.productId || isCenterMembershipRenewal) &&
        !isCredentialSignUp
      ) {
        return;
      }

      const applicationInfo = {
        ...application,
        personAccountId: application.personAccountId || personAccountId,
      };

      // Is User an AICPA member, with no Existing credentials and has credentials in cart this means user is in sign up stage for credentials
      if (isCredentialSignUp && !fromCredentialPage) {
        applicationInfo.applicationProgress = User.MembershipApplicationStages.CREDENTIALS;
      }

      return request(MUTATE_APPLICATION, {
        applicationInfo,
      });
    }
);

export const getApplications: any = createAction(
  UserActionNames.GET_APPLICATION,
  (id: string, status: string = '') =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const personAccountId = personAccountIdSelector(state);
      const res = await request(GET_APPLICATION, {
        id,
        personAccountId,
        status,
      });
      const transformed = res.getApplications.map((application: any) => {
        const { progress } = application;
        delete application?.progress;
        delete application?.jobTitle;
        return { ...application, applicationProgress: progress };
      })[res.getApplications.length - 1];
      return transformed;
    }
);

export const acceptFirmMembershipInvite: any = createAction(
  UserActionNames.ACCEPT_FIRM_MEMBERSHIP_INVITE,
  (inviteId: string, applicationId?: string, customerPayingSku?: string) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      // update context first before accepting firm invite to get the updated context before creating zuora account.
      const state = getState();
      const inviteData = membershipInviteDataSelector(state);
      const customHeaders = {
        existingEntity: inviteData?.organization?.organizationCapabilities?.type?.toLowerCase() || 'association',
        currency: {
          label: inviteData?.organization?.currency || 'USD',
        },
      };
      await updateContext(customHeaders);

      const accept = await request(ACCEPT_FIRM_MEMBERSHIP_INVITE, { inviteId, applicationId, customerPayingSku });
      if (removeCartItems) dispatch(removeCartItems());
      return accept;
    }
);

export const abandonApplication: any = createAction(
  UserActionNames.RESET_APPLICATION,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const application = applicationSelector(state);

    dispatch(
      updateApplication({
        ...application,
        applicationProgress: '',
        status: MembershipTypes.ApplicationStatusEnum.ABANDONED,
      })
    );
  }
);

export const updateMembershipApplicationPartTier: any = createAction(
  UserActionNames.UPDATE_MEMBERSHIP_APPLICATION_PART,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const userChoice = userMembershipPackageSelector(state);
    const application = applicationSelector(state);

    dispatch(
      updateApplication({
        ...application,
        applicationPart: {
          ...application.applicationPart,
          productKeyId: userChoice.tier || userChoice.flpVariant,
        },
      })
    );
  }
);

export const getUniversityDetails: any = createAction(
  UserActionNames.QUERY_UNIVERSITY_DETAILS,
  (accountName: string, university: any) => async (dispatch: Dispatch) => {
    if (university && !accountName) {
      return university;
    }
    return request(QUERY_ORGANIZATIONS_BY_NAME, {
      accountName,
      isUniversity: true,
    })
      .then((response: any) => {
        // tslint:disable-next-line: no-shadowed-variable
        const university = response.searchOrganizationsByName[0];
        const address = university.shippingAddress;
        return {
          ...university,
          ...(address && {
            address,
          }),
          success: true,
        };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const checkIfOrganizationExists: any = createAction(
  UserActionNames.QUERY_ORGANIZATION_IF_EXISTS,
  (accountName: string) => async (dispatch: Dispatch) => {
    if (!accountName) return false;
    const res = await request(QUERY_ORGANIZATIONS_BY_NAME, {
      accountName,
      returnAccountNameOnly: true,
    });
    return Boolean(res.searchOrganizationsByName?.length);
  }
);

export const getOrganizationDataByAccountNumber: any = createAction(
  UserActionNames.QUERY_ORGANIZATIONS_BY_ACCOUNT_NUMBER,
  async (accountNumber: string, fromPromoCode: boolean) => async (dispatch: Dispatch) => {
    return request(QUERY_ORGANIZATION_BY_ACCOUNT_NUMBER, { accountNumber })
      .then((response: any) => {
        if (fromPromoCode) {
          dispatch(getUniversityDetails(null, response?.getOrganizationByAccountNumberOrAccountId));
        }
        return { ...response, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const toggleApplicationInitialLoad: any = createAction(UserActionNames.TOGGLE_APPLICATION_INITIAL_LOAD);
export const setApplicationInitialLoad: any = createAction(
  UserActionNames.SET_APPLICATION_INITIAL_LOAD,
  (applicationInitialLoad: boolean) => applicationInitialLoad
);
export const clearSmartyStreetsSecondaryAddressValidation: any = createAction(
  UserActionNames.CLEAR_SMARTY_STREETS_SECONDARY_ADDRESS_VALIDATION
);

export const setPersonalSuggestedAddress: any = createAction(
  UserActionNames.SET_SUGGESTED_ADDRESS,
  (address: Partial<State.Address>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return address;
  }
);

export const setOrganizationSuggestedAddress: any = createAction(
  UserActionNames.SET_EMPLOYER_SUGGESTED_ADDRESS,
  (address: Partial<State.Address>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return address;
  }
);

export const updateAttestation: any = createAction(
  UserActionNames.UPDATE_ATTESTATION,
  (attestation: string[]) => () => attestation
);
export const clearAttestation: any = createAction(UserActionNames.CLEAR_ATTESTATION);

// center membership
export const setMembershipPackageOrganization: any = createAction(
  UserActionNames.SET_MEMBERSHIP_PACKAGE_ORGANIZATION,
  (data: any) => data
);

export const setMembershipPackageOrganizationIssuer: any = createAction(
  UserActionNames.SET_MEMBERSHIP_PACKAGE_ORGANIZATION_ISSUER,
  async (numberOfCpa: string, PCAOBNumOfIssuers: string, isPCAOBOrganization: boolean) => () => ({
    numberOfCpa,
    PCAOBNumOfIssuers,
    isPCAOBOrganization,
  })
);

interface AdminPayload {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export const setMembershipApplicationAdminDetail: any = createAction(
  UserActionNames.SET_MEMBERSHIP_APPLICATION_ADMIN_DETAIL,
  (adminDetail: AdminPayload) => () => {
    const { id, firstName, lastName, email } = adminDetail;

    return { id, firstName, lastName, email };
  }
);

export const setMembershipApplicationOrganizationDetail: any = createAction(
  UserActionNames.SET_MEMBERSHIP_APPLICATION_ORGANIZATION_DETAIL,
  (
      numberOfCpaOwners?: string,
      numberOfNonCpaOwners?: string,
      numberOfTotalGovtAudits?: string,
      numberOfSingleAudits?: string
    ) =>
    () => ({
      numberOfCpaOwners,
      numberOfNonCpaOwners,
      numberOfTotalGovtAudits,
      numberOfSingleAudits,
    })
);

export const setMembershipApplicationQuestion: any = createAction(
  UserActionNames.SET_MEMBERSHIP_APPLICATION_QUESTION,
  (hearAboutUsMembershipQuestion: string) => () => hearAboutUsMembershipQuestion
);

export const updateOrganizationDetails: any = createAction(
  UserActionNames.UPDATE_ORGANIZATION_DETAILS,
  (
      orgIDFromParams?: string,
      orgDetailsFromParams?: {
        totalNumberOfCPAs: string;
        isPCAOBOrganization: boolean;
        PCAOBNumOfIssuers: string;
      }
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const membershipOrganization = centerMembershipPackageOrganizationSelector(state);

      const {
        id,
        numberOfCpa,
        numberOfCpaOwners,
        numberOfNonCpaOwners,
        numberOfTotalGovtAudits,
        numberOfSingleAudits,
        isPCAOBOrganization,
        PCAOBNumOfIssuers,
      } = membershipOrganization;

      const organizationUpdate = {
        numberOfOwnerCPAs: numberOfCpaOwners,
        numberOfNonOwnerCPAs: numberOfNonCpaOwners,
        totalNumberOfCPAs: numberOfCpa,
        numberOfTotalGovtAudits,
        numberOfSingleAudits,
        PCAOBNumOfIssuers,
        isPCAOBOrganization,
      };

      return request(MUTATE_ORGANIZATION, {
        organizationId: orgIDFromParams || id,
        update: orgIDFromParams ? orgDetailsFromParams : organizationUpdate,
      })
        .then((response: any) => {
          return true;
        })
        .catch((error: any) => {
          return false;
        });
    }
);

export const updateAccountAsPartner: any = createAction(
  UserActionNames.UPDATE_ACCOUNT_AS_PARTNER,
  (accountPersonAccountId: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    try {
      await request(UPDATE_ACCOUNT_PERSON_ACCOUNT_JOB_TITLE, {
        id: accountPersonAccountId,
        relationshipType: MembershipTypes.RelationshipTypeEnum.EMPLOYEE,
        jobTitle: MembershipTypes.JotTitleEnum.PARTNER,
      });

      const updatedEmployers = employersSelector(state).map(employer => {
        if (employer.id === accountPersonAccountId) {
          employer.relationshipType = MembershipTypes.RelationshipTypeEnum.EMPLOYEE;
          employer.jobTitle = MembershipTypes.JotTitleEnum.PARTNER;
        }
        return employer;
      });

      return updatedEmployers;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const createFirmMembershipWithAttestation: any = createAction(
  UserActionNames.CREATE_FIRM_MEMBERSHIP_WITH_ATTESTATION,
  (applicationProgress?: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const partnerAccountId = personAccountIdSelector(state);
    const organizationAccountId = centerMembershipPackageOrganizationIdSelector(state);
    const adminAccountDetail = centerMembershipApplicationAdminDetailSelector(state);
    const relationshipType = `${Salesforce.AccountContactRole.ADMIN}`;
    const adminType = `${Salesforce.AdminType.CENTERS_ADMIN}`;
    const attestationTexts = attestationSelector(state);
    const tierSku = userMembershipTierSelector(state);
    const { hearAboutUsMembershipQuestion } = centerMembershipApplicationObjectSelector(state).applicationPart || '';

    return request(MUTATE_FIRM_MEMBERSHIP_WITH_ATTESTATION, {
      partnerAccountId,
      organizationAccountId,
      relationshipType,
      adminType,
      adminAccountDetail,
      attestationTexts,
      applicationProgress,
      tierSku,
      hearAboutUsMembershipQuestion,
    })
      .then((response: any) => response.createFirmMembershipWithAttestation)
      .catch((error: any) => ({ success: false, adminDetail: {}, applicationObject: {}, accountPersonAccount: '' }));
  }
);

export const sendPartnerInvite: any = createAction(
  UserActionNames.SEND_PARTNER_INVITE,
  (partnerDetails: any) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const userPartnerDetails = userPartnerDetailSelector(state);
    const { id, firstName, lastName } = userPartnerDetails;
    const { name } = centerMembershipPackageOrganizationSelector(state);
    const membershipType = userMembershipTypeSelector(state);

    const partnerInvite: UserTypes.PartnerInvite = {
      subscriberKey: id,
      emailAddress: partnerDetails.email,
      firstName,
      lastName,
      orgName: name,
      referrerName: name,
      centerName: name,
      url: window.location.href,
      membershipType,
    };
    return request(MUTATE_SEND_PARTNER_INVITE, { partnerInvite })
      .then((response: any) => {
        if (response?.sendPartnerInvite) {
          return { ...response, success: true };
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const fetchMembershipJourney: any = createAction(
  UserActionNames.FETCH_MEMBERSHIP_JOURNEY,
  async (applicationId: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const application: any = await request(GET_APPLICATION_BY_ID, { applicationId })
      .then((response: any) => {
        return { ...response, success: true };
      })
      .catch(error => {
        return { success: false };
      });

    if (application.success) {
      const { numOfIssuers, numberOfCpa, organization, productId, slug, sku } =
        application.getCenterMembershipApplicationById;
      dispatch(setMembershipJourneyType(MembershipTypes.MembershipJourneyType.MEMBERSHIP_SIGN_UP));
      dispatch(setCenterMembershipPackageType(productId, slug));
      dispatch(setMembershipPackageTier(sku));
      dispatch(setMembershipPackageOrganization(organization));
      dispatch(setMembershipPackageOrganizationIssuer(numberOfCpa, numOfIssuers));
      dispatch(addMembershipToCart());
    }
  }
);

export const getSpecialConsiderationsExams: any = createAction('user/GET_EXAMINATIONS', () => (dispatch: Dispatch) => {
  return request(GET_EXAMINATIONS)
    .then((response: any) => {
      const examinationsList = response.getExaminations.records;
      const examList: any[] = [];

      if (examinationsList) {
        Object.values(examinationsList).forEach((exam: any, key: number) => {
          examList.push({
            key,
            text: exam.Name,
            value: exam.Id,
          });
        });
      }

      return examList;
    })
    .catch(err => {
      return { success: false };
    });
});

export const getSecurityQuestion: any = createAction(
  UserActionNames.GET_SECURITY_QUESTIONS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const isAdminPortal: boolean = isAdminPortalSelector(state);
    return request(GET_SECURITY_QUESTION)
      .then((response: any) => {
        if (response) {
          return {
            ...response.getSecurityQuestion,
            answer: isAdminPortal ? response.getSecurityQuestion.answer : '', // only return security answer if admin porta/impersonation
            success: true,
          };
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const updateSecurityResponse: any = createAction(
  UserActionNames.UPDATE_SECURITY_RESPONSE,
  (securityQuestion: Salesforce.SecurityQuestion) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(MUTATE_SECURITY_RESPONSE, { securityQuestion })
      .then((response: any) => {
        if (response) {
          return { ...response.updateSecurityResponse, success: true };
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

// fetch FLP Reseller Details (based from discount code's custom field)
export const getFlpResellerDataByAccountNumber: any = createAction(
  UserActionNames.QUERY_FLP_RESELLER_BY_ACCOUNT_NUMBER,
  (accountNumber: string) => async () => {
    return request(QUERY_ORGANIZATION_BY_ACCOUNT_NUMBER, { accountNumber })
      .then((response: any) => {
        return { ...response, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

// fetch Training Provider Details (based from discount code's custom field)
export const getTrainingProviderDataByAccountNumber: any = createAction(
  UserActionNames.QUERY_TRAINING_PROVIDER_BY_ACCOUNT_NUMBER,
  (accountNumber: string) => async () => {
    return request(QUERY_ORGANIZATION_BY_ACCOUNT_NUMBER, { accountNumber })
      .then((response: any) => {
        return { ...response, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

// reset Organization Details (FLP Reseller/Training Provider/Organization) based from promo code
export const clearOrgazanizationFromPromoCode: any = createAction(
  UserActionNames.CLEAR_ORGANIZATION_FROM_PROMO_CODE,
  (fieldName: Cart.DISCOUNT_CODE_CUSTOM_FIELD) => async () => {
    const employmentFields = {
      [Cart.DISCOUNT_CODE_CUSTOM_FIELD.ORGANIZATION]: {
        employmentDataFromPromoFetched: false,
        employmentDataFromPromoFetchSuccess: false,
        newEmployer: initialState.employmentData.newEmployer,
      },
      [Cart.DISCOUNT_CODE_CUSTOM_FIELD.TUITION_PROVIDER]: {
        trainingProviderDataFromPromoFetched: false,
        trainingProviderDataFromPromoFetchSuccess: false,
        newTrainingProvider: initialState.employmentData.newTrainingProvider,
      },
      [Cart.DISCOUNT_CODE_CUSTOM_FIELD.FLP_RESELLER]: {
        flpResellerDataFromPromoFetched: false,
        flpResellerDataFromPromoFetchSuccess: false,
        newFlpReseller: initialState.employmentData.newFlpReseller,
      },
    };
    return employmentFields[fieldName];
  }
);

export const searchOrganizationsByNewTrainingProvider: any = createAction(
  UserActionNames.QUERY_ORGANIZATIONS_BY_TRAINING_PROVIDER,
  (accountName: string, cityName?: string, tuitionProviderType?: string) => async (dispatch: Dispatch) => {
    return request(QUERY_ORGANIZATIONS_BY_NAME, {
      accountName,
      cityName,
      tuitionProviderType,
    })
      .then((response: any) => {
        return response.searchOrganizationsByName[0];
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const searchNewTrainingProviderCities: any = createAction(
  UserActionNames.QUERY_ORGANIZATIONS_CITIES,
  (accountName: string, cityName: string) => async (dispatch: Dispatch) => {
    return request(QUERY_ORGANIZATIONS_BY_NAME, {
      accountName,
      cityName,
      isCityNameWildcard: true,
      returnCityOnly: true,
    })
      .then((response: any) => {
        return { ...response, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

// TODO: add this to index.ts
// TODO: add more functionality to this action
export const saveCimaUploadData: any = createAction(
  UserActionNames.DOCUMENT_UPLOAD,
  async (file: any) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const application = applicationSelector(state);
    const isPaperBilling = isPaperBillingSelector(state);
    const isLearningPathwaySwitch = learningPathwayToSwitchSelector(state);
    const learningPathway = learningPathwaySelector(state);
    const isEPA1Completed = isEPA1CompletedSelector(state);
    const currentJourneyLearningPathway = currentJourneyLearningPathwaySelector(state);

    const { pathname } = getLocation(state);
    const isApprentice =
      AdminUtils.isApprenticePathway(currentJourneyLearningPathway) ||
      AdminUtils.isApprenticePathway(learningPathway as string) ||
      AdminUtils.isApprenticePathway(isLearningPathwaySwitch as string);
    const isDocumentUploadPath = getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DOCUMENT) === pathname;
    const isApprenticeORL7Upgrade =
      isApprentice || (learningPathway === MembershipTypes.Pathway.APPRENTICE_L7 && isEPA1Completed);
    const documentUploadPath = () => {
      if (isApprenticeORL7Upgrade) {
        return UserTypes.MembershipApplicationStages.DOCUMENT_GATEWAY;
      }
      return UserTypes.MembershipApplicationStages.DONATION;
    };
    const gatewayUploadPath = () => {
      if (isApprenticeORL7Upgrade) {
        // is Gateway Upload Path
        return UserTypes.MembershipApplicationStages.ATTESTATION;
      }
      return UserTypes.MembershipApplicationStages.DONATION;
    };
    // Save user membership progress
    dispatch(
      updateApplication({
        ...application,
        applicationProgress: isDocumentUploadPath ? documentUploadPath() : gatewayUploadPath(),
      })
    );
    const paperBillingRoute = () => {
      if (isDocumentUploadPath) {
        if (isApprenticeORL7Upgrade) {
          return Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_GATEWAY_DOCUMENT;
        }
        return Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS;
      }
      if (isApprenticeORL7Upgrade) {
        return Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION;
      }
      return Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS;
    };
    // Redirect User to the next page
    // if Apprentice user redirected to gateway otherwise goto donation
    const path: Routes = paperBillingRoute();
    dispatch(push(getPaperBillingPath(path, isPaperBilling)));
  }
);

export const openPersonVue: any = createAction(
  UserActionNames.OPEN_PV_DASHBOARD,
  (actionType: PearsonVue.SSO_ACTIONS, registrationId?: string) => async (dispatch: Dispatch) => {
    return request(OPEN_PV_DASHBOARD, { actionType, registrationId })
      .then((response: any) => {
        return { ...response, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const getStudentProgressionData: any = createAction(
  UserActionNames.GET_STUDENT_PROGRESSION,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(userLoading);
    const state = getState();
    const personAccountData = personAccountDataSelector(state);

    try {
      const response = await request(QUERY_STUDENT_EXAM_PROGRESSION, { sfPersonAccountId: personAccountData.id });

      return { ...response, success: true };
    } catch (error) {
      return Promise.reject((error as any)?.response?.message);
    }
  }
);

export const getStudentExamHistoryData: any = createAction(
  UserActionNames.GET_STUDENT_EXAM_HISTORY,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(userLoading);
    const state = getState();
    const personAccountData = personAccountDataSelector(state);

    try {
      const response = await request(QUERY_STUDENT_EXAM_HISTORY, { sfPersonAccountId: personAccountData.id });

      return { ...response, success: true };
    } catch (error) {
      return Promise.reject((error as any)?.response?.message);
    }
  }
);

export const resetStudentExamSectionResults: any = createAction(UserActionNames.RESET_STUDENT_EXAM_SECTION_RESULTS);
export const getStudentExamSectionResultsData: any = createAction(
  UserActionNames.GET_STUDENT_EXAM_SECTION_RESULTS,
  (examUrlSlug: string) => async (dispatch: Dispatch) => {
    dispatch(userLoading());
    try {
      const response = await request(QUERY_STUDENT_EXAM_SECTION_RESULTS, { examUrlSlug });
      return { ...response, success: true };
    } catch (error) {
      return Promise.reject((error as any)?.response?.message);
    }
  }
);

export const setStudentLearningPathwayToSwitch: any = createAction(
  UserActionNames.SET_STUDENT_LEARNING_PATHWAY_TO_SWITCH
);

export const setNewEmployerJobTitle: any = createAction(UserActionNames.SET_NEW_EMPLOYER_JOB_TITLE);

export const getSFMCLink: any = createAction(
  UserActionNames.GET_SFMC_LINK,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(QUERY_SFMC_LINK)
      .then((response: any) => {
        if (response) {
          return { ...response, success: true };
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const setCurrentUserPrimaryAddress: any = createAction(
  UserActionNames.SET_CURRENT_USER_PRIMARY_ADDRESS,
  (address: {
      addressLine1: string;
      addressLine2?: string;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      addressType?: string;
    }) =>
    async (dispatch: Dispatch) => {
      const { addressLine1, addressLine2, city, state, zipCode, country, addressType } = address;
      try {
        await dispatch(
          addSalesforceAddress({ addressLine1, addressLine2, city, state, zipCode, country }, true, addressType)
        );
        return { ...address, success: true };
      } catch (error) {
        return { success: false };
      }
    }
);

export const createAttestation: any = createAction(
  UserActionNames.CREATE_ATTESTATION,
  (attestationText: string) => async () => {
    const result = await request(CREATE_ATTESTATION, { attestationText });
    return result;
  }
);

export const createAttestations: any = createAction(
  UserActionNames.CREATE_ATTESTATIONS,
  (attestations: { attestations: Array<{ attestationText: string; recordType: string }> }) => async () => {
    const result = await request(CREATE_ATTESTATIONS, { attestations });
    return result;
  }
);

export const updateEPA2L4CaseStatus: any = createAction(
  UserActionNames.UPDATE_EPA2L4_CASE_STATUS,
  (Id: string, Status: string, SubmissionDate: string, sku: string) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      try {
        const state = getState();
        const epa2Submissions = EPA2SubmissionsSelector(state);
        const { studentDetails, employerDetails, employerValidatorDetails } = studentDetailsSelector(state);
        const response = await request(MUTATE_EPA2L4_CASE_STATUS, { Id, Status, SubmissionDate, sku });
        if (response.updateEPA2L4CaseStatus.success) {
          const emailPayload: SFMC.DifferentEpaSupervisor = {
            caseId: epa2Submissions?.case?.id,
            emailAddress: employerValidatorDetails?.email || epa2Submissions?.case?.externalValidator?.email,
            supervisorFirstName:
              employerValidatorDetails?.firstName || epa2Submissions?.case?.externalValidator?.firstName,
            supervisorLastName:
              employerValidatorDetails?.lastName || epa2Submissions?.case?.externalValidator?.lastName,
            apprenticeULN: studentDetails.apprenticeGatewayDetails.apprenticeULN,
            apprenticeEmployerName: employerDetails?.name,
          };
          dispatch(sendDifferentEpaSupervisorEmailNotification(emailPayload));

          dispatch(getEPA2Submissions());
          return {
            ...response.updateEPA2L4CaseStatus,
            success: true,
            message: MembershipTypes.EPA2Message.SUBMITTED,
          };
        }
        return {
          success: false,
          message: MembershipTypes.EPA2Message.EXTERNAL_VALIDATOR,
        };
      } catch (error) {
        return {
          success: false,
          message: MembershipTypes.EPA2Message.EXTERNAL_VALIDATOR,
        };
      }
    }
);

export const sendDifferentEpaSupervisorEmailNotification: any = createAction(
  'customer/SEND_DIFFERENT_EPA_SUPERVISOR_EMAIL',
  (emailPayload: SFMC.DifferentEpaSupervisor) => {
    return request(SEND_DIFFERENT_EPA_SUPERVISOR_EMAIL, emailPayload);
  }
);

export const updateEPA2L7CaseStatus: any = createAction(
  UserActionNames.UPDATE_EPA2L7_CASE_STATUS,
  (Id: string, Status: string, SubmissionDate: string, sku: string) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      try {
        const response = await request(MUTATE_EPA2L7_CASE_STATUS, { Id, Status, SubmissionDate, sku });
        if (response.updateEPA2L7CaseStatus.success) {
          dispatch(getEPA2L7Submissions());
          return {
            ...response.updateEPA2L7CaseStatus,
            success: true,
            message: MembershipTypes.EPA2Message.SUBMITTED,
          };
        }
        return {
          success: false,
          message: MembershipTypes.EPA2Message.EXTERNAL_VALIDATOR,
        };
      } catch {
        return {
          success: false,
          message: MembershipTypes.EPA2Message.EXTERNAL_VALIDATOR,
        };
      }
    }
);

export const setEventRegistrationData: any = createAction(
  UserActionNames.SET_EVENT_REGISTRATION_DATA,
  (eventTypeItem: Cart.LineItem) => {
    const rootState = store.getState();
    const priceInfo: any = itemsPriceDataCartSelector(rootState)?.[0] || itemsPriceDataProductSelector(rootState)?.[0];
    const data: any = eventTypeItem;
    const priceData: any = {
      formattedBasePrice: priceInfo?.formattedBasePrice,
      formattedBasePriceWithQuantity: priceInfo?.formattedBasePriceWithQuantity,
      formattedNetPriceWithQuantity: priceInfo?.formattedNetPriceWithQuantity,
      formattedPrice: priceInfo?.formattedPrice,
      formattedTotalDiscount: priceInfo?.formattedTotalDiscount,
      centAmount: priceInfo?.price?.centAmount || 0,
      currencyCode: priceInfo?.price?.currencyCode,
    };
    try {
      if (eventTypeItem) {
        return { eventTypeItem: [{ ...data[0], price: { ...priceData } }], success: true };
      }
      return { success: false };
    } catch {
      return { success: false };
    }
  }
);

export const createSpecialConsiderationLoading: any = createAction(UserActionNames.CREATE_SPECIALCONSIDERATION_LOADING);
export const createSpecialConsiderationSuccess: any = createAction(UserActionNames.CREATE_SPECIALCONSIDERATION_SUCCESS);
export const createSpecialConsideration: any = createAction(
  UserActionNames.CREATE_SPECIALCONSIDERATION,
  (specialConsideration: Salesforce.SpecialConsideration) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const specialConsiderationInfo: Salesforce.SpecialConsideration = {
      firstName: specialConsideration.firstName,
      lastName: specialConsideration.lastName,
      appointmentId: specialConsideration.appointmentId,
      examKey: specialConsideration.examKey,
      examDate: specialConsideration.examDate,
      examDeliveryMethod: specialConsideration.examDeliveryMethod,
      testCenter: specialConsideration.testCenter,
      scReason: specialConsideration.scReason,
      pVCaseId: specialConsideration.pVCaseId,
      reasonText: specialConsideration.reasonText,
      documentType: specialConsideration.documentType,
      problemEncountered: specialConsideration.problemEncountered,
      problemStartDate: specialConsideration.problemStartDate,
      problemLength: specialConsideration.problemLength,
      problemImpact: specialConsideration.problemImpact,
      proctorReportFlag: specialConsideration.proctorReportFlag,
      proctorAdvice: specialConsideration.proctorAdvice,
      attestationText: specialConsideration.attestationText,
      personAccountId: specialConsideration.personAccountId,
      contactId: specialConsideration.contactId,
    };
    return request(CREATE_SPECIALCONSIDERATION, { specialConsiderationInfo })
      .then((response: any) => {
        if (response) {
          dispatch(createSpecialConsiderationSuccess());
          return { ...response, success: true };
        }
        return { success: false };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const createEventRegistrationLoading: any = createAction(UserActionNames.CREATE_EVENT_FORM_LOADING);
export const createEventRegistrationSuccess: any = createAction(UserActionNames.CREATE_EVENT_FORM_SUCCESS);
export const createEventRegistration: any = createAction(
  UserActionNames.CREATE_EVENT_FORM,
  (createEventForm: Salesforce.EventRegistration, orderNumber: string | undefined) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const personAccountId = personAccountIdSelector(state);
      const eventRegistrationInfo: Salesforce.EventRegistration = {
        id: '',
        personAccountId,
        firstName: createEventForm.firstName,
        lastName: createEventForm.lastName,
        emailContactId: createEventForm.emailContactId,
        jobTitle: createEventForm.jobTitle,
        employer: createEventForm.employer,
        pricePaid: createEventForm.pricePaid,
        methodOfPayment: createEventForm.methodOfPayment,
        event: createEventForm.event,
        eventDateAndTime: createEventForm.eventDateAndTime,
        eventLocation: createEventForm.eventLocation,
        dateOfBooking: createEventForm.dateOfBooking,
      };
      return request(CREATE_EVENT_REGISTRATION, { eventRegistrationInfo })
        .then((response: any) => {
          if (response) {
            dispatch(createEventRegistrationSuccess());
            dispatch(push(generatePath(getPath(Routes.CHECKOUT_PAGE), { orderNumber })));
            return { ...response, eventRegistrationInfo, success: true };
          }
          return { success: false };
        })
        .catch(() => {
          return { success: false };
        });
    }
);
export const updateAddressBook: any = createAction(UserActionNames.UPDATE_ADDRESS_BOOK);

export const updateUserAddressList: any = createAction(
  UserActionNames.UPDATE_USER_ADDRESS_LIST,
  (id: string) => async () => {
    return id;
  }
);

export const deprovisionExamCredits: any = createAction(
  UserActionNames.DEPROVISION_EXAM_CREDITS,
  (props: Salesforce.DeprovisionExamCreditsOnPathwaySwitchDetails) => async () => {
    return request(MUTATE_DEPROVISION_EXAM_CREDITS, {
      props,
    })
      .then((response: any) => {
        return { ...response, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const upgradeLearningPathway: any = createAction(
  UserActionNames.UPGRADE_LEARNING_PATHWAY,
  (upgradeLearningPathwayDetails: Salesforce.UpgradeLearningPathwayDetails) => async (dispatch: Dispatch) => {
    return request(MUTATE_UPGRADE_LEARNING_PATHWAY, {
      upgradeLearningPathwayDetails,
    })
      .then((response: any) => {
        dispatch(getStudentProgressionData());
        return { ...response, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

const genericToggleSubskill = (
  skillPreferences: State.skillPreferences,
  skillId: string,
  subskillId: string,
  isOnEvent: boolean,
  options: { deleteSkill: boolean } = { deleteSkill: false }
) => {
  // next subskills state calculation
  const subskills = skillPreferences[skillId] || [];
  const nextSubskills = isOnEvent ? [...subskills, subskillId] : subskills.filter(elem => elem !== subskillId);

  return nextSubskills.length
    ? { ...skillPreferences, [skillId]: nextSubskills }
    : Object.keys(skillPreferences).reduce((acc: any, key: string) => {
        if (key === skillId) {
          if (options.deleteSkill) return acc;
          acc[key] = [];
          return acc;
        }
        acc[key] = skillPreferences[key];
        return acc;
      }, {});
};

export const toggleSubskillPref: any = createAction(
  UserActionNames.TOGGLE_SUBSKILL_PREF,
  (subskillId: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const selectedSkillId: string | null = selectedSkillIdSelector(state);
    const skillPreferences = userSkillPrefSelector(state);

    if (!selectedSkillId) return skillPreferences;

    const subskills = skillPreferences[selectedSkillId] || [];
    const isSubskillIdInArray: boolean = subskills.includes(subskillId);
    const isOnEvent = !isSubskillIdInArray;

    return genericToggleSubskill(skillPreferences, selectedSkillId, subskillId, isOnEvent, { deleteSkill: true });
  }
);

export const selectAllSubskills: any = createAction(
  UserActionNames.SELECT_ALL_SUBSKILLS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const selectedSkillId: string | null = selectedSkillIdSelector(state);
    if (!selectedSkillId) return;
    const skillsHash: State.SkillsHash | null = skillsHashSelector(state);
    if (!skillsHash) return;
    const subskills: string[] = Object.keys(skillsHash[selectedSkillId].subskills);
    return { [selectedSkillId]: subskills };
  }
);

export const clearAllSubskills: any = createAction(
  UserActionNames.CLEAR_ALL_SUBSKILLS,
  () => (dispatch: Dispatch, getState: () => State.Root) => selectedSkillIdSelector(getState())
);

export const checkUserCountryIfEmbargoed: any = createAction(
  UserActionNames.CHECK_USER_COUNTRY_IF_EMBARGOED,
  (userCountry: string) => async () => {
    return request(QUERY_CHECK_USER_COUNTRY_IF_EMBARGOED, { userCountry });
  }
);

export const getUserLocation: any = createAction(
  UserActionNames.GET_USER_LOCATION,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();

    return request(GET_USER_LOCATION, {}).then((response: any) => {
      // set cookie country
      const isAmerica = CountriesList.some(
        (c: Checkout.Country) => c.key === response?.getUserLocation?.country && c.isAmerica
      );

      const hasExistingZuoraPurchase = hasExistingZuoraPurchaseSelector(state);
      const isCurrencyToggledByDropdown = isCurrencyToggledByDropdownSelector(state);
      // if user has preferred currency, saved on session
      const userPreferredCurrencySession = getSessionStorageItem(StorageNames.userPreferredCurrency) as any;

      // save the user preferred currency session
      if (userPreferredCurrencySession && !hasExistingZuoraPurchase && !isCurrencyToggledByDropdown) {
        dispatch(updatePreferredCurrency(userPreferredCurrencySession?.currency, true));
      }

      // if country is not America, no existing purchase and no preferred currency, set to currency GBP
      if (!isAmerica && !hasExistingZuoraPurchase && !isCurrencyToggledByDropdown && !userPreferredCurrencySession) {
        dispatch(
          updatePreferredCurrency({
            label: Product.ProductCurrencyLabel.GBP,
            sign: Product.ProductCurrencySign.POUND,
          })
        );
      }
      return { ...response.getUserLocation };
    });
  }
);

export const updateProfessionalStatus: any = createAction(
  UserActionNames.UPDATE_PROFESSIONAL_STATUS,
  (status: User.ProfessionalStatus) => async () => status
);

export const updateYearOfGraduation: any = createAction(
  UserActionNames.UPDATE_YEAR_OF_GRADUATION,
  (year: string) => async () => year
);

export const setHasPreferredCurrency: any = createAction(UserActionNames.SET_PREFERRED_CURRENCY);

export const updateAccountUniversityDetails: any = createAction(
  UserActionNames.UPDATE_ACCOUNT_UNIVERSITY_DETAILS,
  (yearOfGraduation: string, conditionalExemptionStudent: boolean) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const personAccountId = personAccountIdSelector(state);
      return request(UPDATE_ACCOUNT_UNIVERSITY_DETAILS, {
        id: personAccountId,
        yearOfGraduation,
        conditionalExemptionStudent,
      })
        .then((response: any) => {
          if (response?.updateAccountUniversityDetails) {
            return {
              ...response,
              personAccountId,
              yearOfGraduation,
              conditionalExemptionStudent,
              success: true,
            };
          }
          return { success: false };
        })
        .catch(() => {
          return { success: false };
        });
    }
);

export const getUserSyllabusByPersonAccountId: any = createAction(
  UserActionNames.GET_USER_SYLLABUS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    dispatch(loading());
    const personAccountData = personAccountDataSelector(state);

    return request(GET_USER_SYLLABUS, { sfId: personAccountData.id })
      .then((response: any) => {
        if (response?.getSyllabusByPersonAccountId) {
          return { syllabus: response?.getSyllabusByPersonAccountId, success: true };
        }

        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);
