import React from 'react';
import styled from 'styled-components';
import { Link } from 'components/atoms/Link/Link';
import { CollapsableLinkList } from 'components/atoms/CollapsableLinkList/CollapsableLinkList';
import { LockStateIcon } from 'components/pages/PageToolkit/LockStateIcon';
import { ReactComponent as OpenInNew } from 'resources/images/ic-open-in-new.svg';
import { getContentUrl } from 'utils';
import { handleEvent, CLICK, EXTERNAL_LINK_EVENT } from 'utils/Analytics';
import { RichTextBlocks } from 'mxp-utils';
interface Props {
  title: string;
  items: State.ShortcutDetail[];
  testId: string;
  firstItemsCount?: number;
}

export const LeftSectionLinks: React.FC<Props> = ({ title, testId, items, firstItemsCount = 3 }) => {
  const handleExternalLink = React.useCallback(
    (name: string, target: string | undefined, isExternal: boolean = true): void => {
      if (isExternal) {
        handleEvent({ text: `link-list:${name}:${target}` }, EXTERNAL_LINK_EVENT);
      } else {
        handleEvent({ value: `link-list:${name}:${target}`, href: target }, CLICK);
      }
    },
    []
  );

  const renderItem = React.useCallback(
    (item, indx) => {
      const isExternal = RichTextBlocks.isExternal(item.url);
      const linkTo = item.url || getContentUrl(item);
      const callback = () => handleExternalLink(item.title, linkTo, isExternal);

      return (
        <StyledItemWrapper key={`wrapper-${testId}-${indx}`} isExternal={isExternal}>
          <StyledLink
            testId={`link-${testId}-${indx}`}
            key={`link-${testId}-${indx}`}
            to={linkTo}
            isExternal={isExternal}
            onClick={callback}
          >
            <LockStateIcon lockStatus={item.isLocked} />
            {isExternal && <StyledIcon data-testid={`icon-${testId}-${indx}`} isExternal={isExternal} />}
            {item.title || item.name}
          </StyledLink>
        </StyledItemWrapper>
      );
    },
    [handleExternalLink, testId]
  );
  return (
    <CollapsableLinkList
      title={title}
      renderItem={renderItem}
      items={items}
      firstItemsCount={firstItemsCount}
      expandedText="Hide Links"
      collapsedText="View All Links"
      isToolkit
    />
  );
};

const StyledItemWrapper = styled.div<{ isExternal: boolean }>`
  padding: ${props => `${props.theme.pxToRem(12)} 0 ${props.theme.pxToRem(12)} ${props.theme.pxToRem(15.5)}`};
  margin-bottom: ${props => props.theme.pxToRem(-8)};
  &:hover {
    background-color: ${props => props.theme.colors.secondaryLightPurple};
    cursor: pointer;

    a {
      color: #7c176f;
    }

    svg {
      path {
        fill: ${props => props.theme.colors.primaryPurple};
      }
    }
  }
`;

const StyledIcon = styled(OpenInNew)<{ isExternal: boolean }>`
  vertical-align: bottom;
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
  line-height: ${props => props.theme.pxToRem(16)};
  display: inline-block;
  margin-right: ${props => props.theme.pxToRem(5)};
  path {
    fill: ${props => props.theme.colors.neutralGrey5};
  }
`;

const StyledLink = styled(Link)<{ isExternal: boolean }>`
  display: inline-block;
  width: 100%;
  max-height: ${props => props.theme.pxToRem(40)};
  text-decoration: none;
  font-size: ${props => props.theme.pxToRem(16)};
  color: ${props => props.theme.colors.neutralBlack};
  line-height: 1;
  &:hover {
    color: ${props => props.theme.colors.primaryPurple};
    text-decoration: none;
  }
`;
