import { User } from 'mxp-schemas';
import { User as UserUtils } from 'mxp-utils';
import { premiumContentSearchItems, onlySubscriptionRoles } from 'mxp-utils/dist/lib/user';
import { PRODUCT_CART_STATUS } from '../../../constants';
import { getPremiumContentToLink } from 'utils';

export interface AccessMessageInfo {
  message: string;
  category: string;
}

export const getAlreadyHasAccessInfo = (
  userRoles: User.MembershipIdsEnum[],
  productContentRoleId: User.MembershipIdsEnum,
  productIncludedMembership: User.MembershipIdsEnum[]
): AccessMessageInfo => {
  const includedMembershipMatches = userRoles.reduce(
    (acc: User.MembershipIdsEnum[], userRole: User.MembershipIdsEnum) => {
      if (productIncludedMembership?.includes(userRole)) {
        acc.push(userRole);
      }
      return acc;
    },
    []
  );

  const inludedMembersip = includedMembershipMatches[0];
  const contentRoleMatch = userRoles?.includes(productContentRoleId) ? productContentRoleId : '';

  if (inludedMembersip) {
    const categorySlugRoleBase = productContentRoleId || inludedMembersip;
    return {
      message: `This content is a benefit of your ${UserUtils.membershipDiscountMapNames[inludedMembersip]} membership.`,
      category: getPremiumContentCategorySlug(categorySlugRoleBase),
    };
  }

  if (contentRoleMatch) {
    return {
      message: `You have already purchased the ${UserUtils.membershipMapNames[contentRoleMatch]}.`,
      category: getPremiumContentCategorySlug(contentRoleMatch),
    };
  }

  return { message: '', category: '' };
};

export const getPremiumContentCategorySlug = (subscriptionRole: User.MembershipIdsEnum) => {
  const filtered = getPremiumContentCategory(subscriptionRole);
  const result = filtered[0][0];
  return result;
};

export const getProductStatus = (
  didCartTransitionFail: boolean,
  thirdPartyLink: string | undefined,
  isPhysicalProduct: boolean,
  isExistingCartItem: boolean,
  alreadyHasAccess: boolean,
  isConference: boolean
): PRODUCT_CART_STATUS => {
  if (didCartTransitionFail) {
    return PRODUCT_CART_STATUS.UNKNOWN;
  }

  if (thirdPartyLink && !isPhysicalProduct) {
    if (isConference) {
      return PRODUCT_CART_STATUS.THIRD_PARTY_PRODUCT_CONFERENCE;
    }
    return PRODUCT_CART_STATUS.THIRD_PARTY_PRODUCT;
  }

  if (isExistingCartItem) {
    return PRODUCT_CART_STATUS.IN_CART;
  }

  if (alreadyHasAccess) {
    return PRODUCT_CART_STATUS.ACCESS_NOW;
  }

  return PRODUCT_CART_STATUS.NOT_IN_CART;
};

export const getTopicalSubscriptionUserRoles = (userRoles: User.MembershipIdsEnum[]) => {
  const result = userRoles.reduce((acc: User.MembershipIdsEnum[], role) => {
    if (onlySubscriptionRoles.includes(role)) {
      acc.push(role);
    }
    return acc;
  }, []);
  return result;
};

export const getPremiumContentCategoryTitle = (subscriptionRole: User.MembershipIdsEnum) => {
  const filtered = getPremiumContentCategory(subscriptionRole);
  const result = filtered[0][1].title;
  return result;
};

export const getPremiumContentCategory = (subscriptionRole: User.MembershipIdsEnum) => {
  const entries = Object.entries(premiumContentSearchItems);
  const filtered = entries.filter(entry => entry[1].ids.includes(subscriptionRole));
  return filtered;
};

export const getTopicalSubscriptionLinks = (subscriptionRoles: User.MembershipIdsEnum[]) => {
  return subscriptionRoles.map(role => {
    const categorySlug = getPremiumContentCategorySlug(role as User.MembershipIdsEnum);
    const title = getPremiumContentCategoryTitle(role);
    const link = getPremiumContentToLink(categorySlug);
    if (title.includes(' Subscription' || ' subscription')) {
      const newTitle = title.replace(' Subscription' || ' subscription', '');
      return { title: newTitle, link };
    }
    return { title, link };
  });
};

export const checkPhoneAndAddress = (business: any, initialData: any) => {
  const address = business.billingAddress || initialData.employmentData.newEmployer.organization.billingAddress!;
  const phone = business.phone || initialData.employmentData.newEmployer.organization.phone!;
  return { address, phone };
};
