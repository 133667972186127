import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import { UserWidgetContainer } from 'components/App/lazy-imports';
import { AicpaLogo, OnlyDesktop, SearchInput } from 'components/atoms';
import { Routes } from 'constants/index';
import { HEADER_EVENT, PREFIXES, handleEvent } from 'utils/Analytics';
import { getPath } from 'utils';
import { headerAndFooterEventPayload } from 'utils/Analytics/helpers';

interface Props {
  navigate: (path: string) => void;
  clearSuggestions: () => void;
  setSearchModal: (expanded: boolean) => void;
}

export const HeaderSearch = React.memo(({ navigate, clearSuggestions, setSearchModal }: Props) => {
  const goToHome = React.useCallback(() => {
    navigate(getPath(Routes.FEED));
  }, [navigate]);

  const open = React.useCallback(() => {
    const payload = headerAndFooterEventPayload(PREFIXES.HEADER, Routes.SEARCH_HASH, 'search box');
    handleEvent(payload, HEADER_EVENT);
    setSearchModal(true);
    navigate(getPath(Routes.SEARCH_HASH));
  }, [navigate, setSearchModal]);

  return (
    <OnlyDesktop>
      <Container>
        <Container fluid data-testid="header-row-search">
          <StyledGrid columns={3}>
            <Grid.Column computer={4} mobile={4}>
              <LogoStyled to={Routes.FEED} onClick={goToHome} />
            </Grid.Column>
            <Grid.Column computer={8} mobile={8}>
              <SearchInput
                testId="search-input-header"
                icon="search"
                onFocus={open}
                clearSuggestions={clearSuggestions}
                navigate={navigate}
              />
            </Grid.Column>
            <Grid.Column computer={4} mobile={4}>
              <UserWidgetContainer />
            </Grid.Column>
          </StyledGrid>
        </Container>
      </Container>
    </OnlyDesktop>
  );
});

const StyledGrid = styled(Grid)`
  &&& {
    display: flex;
    align-items: center;
    height: ${props => props.theme.pxToRem(89)};
    margin: 0;
  }
`;

interface ILogoStyled {
  onClick(event: React.MouseEvent<HTMLDivElement>): void;
}

const LogoStyled = styled(AicpaLogo)<ILogoStyled>`
  cursor: pointer;
`;
