import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { matchPath } from 'react-router-dom';
import { PackageMembershipApplicationSteps, Routes } from 'constants/index';
import { getPath } from 'utils';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { nameReplacementSteps, pathCheckout, pathReplacementName, paths, previousSteps } from './ProgressBarUtils';
import { useSelector } from 'react-redux';
import { isRenewalsJourneySelector } from 'modules/membership/selectors';
import { Button, ButtonEnums } from '../Button/Button';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';
import { Product } from 'mxp-schemas';

interface Props {
  isMembershipForRenewal?: boolean;
  isPackageBuilder?: boolean;
  currPath?: string;
  currHash?: string;
  currPathName?: any;
  isCredentialsJourney?: boolean;
  isSectionsJourney?: boolean;
  isCredentialNonMemberJourney?: boolean;
  isPageAddress?: any;
  isPagePayment?: any;
  pageCheckoutSteps?: any;
  backToAddress?: () => void;
  isPageAttestation?: boolean;
  membershipApplicationType?: Product.MembershipApplicationType;
  isPaperBilling?: boolean;
}

interface StepperMembershipProps {
  title: string | undefined;
  prevStep?: any;
  isActive: boolean;
  isSuccess: boolean;
}

interface MembershipStepperBarProps {
  steps: StepperMembershipProps[];
  className?: string;
  isMembershipForRenewal?: any;
  isPageAddress?: any;
  isPagePayment?: any;
  backToAddress?: () => void;
}

interface DivProps {
  id?: any;
  isActive: boolean;
  isSuccess?: boolean;
  isPageAddress?: any;
  isPagePayment?: any;
  isFirst?: boolean;
  isMembershipForRenewal?: boolean;
  theme: DefaultTheme;
}

const ProgressBarContent: React.FC<MembershipStepperBarProps> = ({
  steps,
  className,
  isMembershipForRenewal,
  isPageAddress,
  isPagePayment,
  backToAddress,
}) => {
  const isRenewalsJourney = useSelector(isRenewalsJourneySelector);
  return (
    <StyledStepper className={className}>
      {isRenewalsJourney && (
        <StyledStepperHeader>Renew your current membership or explore your options.</StyledStepperHeader>
      )}
      <StepperCircleRow>
        {steps.map(({ title, isActive, isSuccess, prevStep }, i, arr) => {
          let index = i;

          return (
            <StepperIcons key={`icons-${title}`}>
              <StepperCircle
                id={i}
                isFirst={i === 0}
                isActive={isActive}
                isSuccess={isSuccess}
                isPageAddress={isPageAddress}
                isPagePayment={isPagePayment}
                isMembershipForRenewal={isMembershipForRenewal}
              >
                {!isPageAddress && i === 2 && isActive && !isMembershipForRenewal ? (
                  <Button
                    variant={ButtonEnums.variants.link}
                    testId="checkout-payment-back-button"
                    onClick={backToAddress}
                  >
                    <Checkmark width={15} height={15} />
                  </Button>
                ) : isSuccess ? (
                  <StyledPreviousPage
                    route={prevStep}
                    backText=""
                    isSuccess={isSuccess}
                    isPageAddress={isPageAddress}
                    isPagePayment={isPagePayment}
                  />
                ) : (
                  <StepperNumber id={i} isActive={isActive} isPageAddress={isPageAddress} isPagePayment={isPagePayment}>
                    {(index += 1)}
                  </StepperNumber>
                )}
              </StepperCircle>
              {i < arr.length - 1 && (
                <StepperLine
                  id={i}
                  isActive={isActive}
                  isSuccess={isSuccess}
                  isPageAddress={isPageAddress}
                  isPagePayment={isPagePayment}
                  isMembershipForRenewal={isMembershipForRenewal}
                />
              )}
            </StepperIcons>
          );
        })}
      </StepperCircleRow>
      <StepperTitleRow>
        {steps.map(({ title, isActive, isSuccess }, i) => (
          <StepperTitle
            id={i}
            isActive={isActive}
            isSuccess={isSuccess}
            isFirst={i === 0}
            key={`stepper-${title}`}
            isPageAddress={isPageAddress}
            isPagePayment={isPagePayment}
            isMembershipForRenewal={isMembershipForRenewal}
          >
            {title}
          </StepperTitle>
        ))}
      </StepperTitleRow>
    </StyledStepper>
  );
};

export const ProgressBarMapping: React.FC<Props> = ({
  isMembershipForRenewal,
  currPathName,
  currPath,
  currHash,
  isCredentialsJourney,
  isSectionsJourney,
  isPageAddress,
  isPagePayment,
  backToAddress,
  membershipApplicationType,
  isPaperBilling,
}) => {
  const isCredentialsApplicationpage = matchPath(currPathName, {
    path: getPath(Routes.CREDENTIALS_APPLICATION_FORM),
    exact: true,
    strict: false,
  });

  if (isCredentialsApplicationpage) {
    // eslint-disable-next-line
    currPath = getPath(Routes.MEMBERSHIP_FORM);
    currPathName = getPath(Routes.MEMBERSHIP_FORM);
  }

  const packageMembershipApplicationSteps: StepperMembershipProps[] = React.useMemo(
    () =>
      PackageMembershipApplicationSteps.map(({ name, pathName }, i, arr) => {
        const path = getPath(pathName);
        const currPageId = PackageMembershipApplicationSteps.findIndex(el => {
          return (
            getPath(el.pathName) === currPathName.substring(0, currPathName.length - 1) ||
            paths(getPath(el.pathName), isMembershipForRenewal) === currPathName ||
            pathReplacementName(getPath(el.pathName), isMembershipForRenewal) === currPathName ||
            pathCheckout(getPath(el.pathName)) === currPathName
          );
        });

        return {
          title: nameReplacementSteps(name, isMembershipForRenewal),
          isActive:
            currPathName.substring(0, currPathName.length - 1) === path ||
            currPathName === paths(path, isMembershipForRenewal) ||
            currPathName === pathReplacementName(path, isMembershipForRenewal) ||
            pathCheckout(path) === currPathName,
          isSuccess: i < currPageId,
          prevStep: previousSteps(
            i,
            isMembershipForRenewal,
            isCredentialsJourney,
            isSectionsJourney,
            membershipApplicationType,
            isPaperBilling
          ),
        };
      }),
    // eslint-disable-next-line
    [currPath, currHash]
  );

  return (
    <ProgressBarContent
      steps={packageMembershipApplicationSteps}
      isMembershipForRenewal={isMembershipForRenewal}
      isPageAddress={isPageAddress}
      isPagePayment={isPagePayment}
      backToAddress={backToAddress}
    />
  );
};

const StyledStepper = styled.div`
  margin: 0 ${props => props.theme.pxToRem(5)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(25)};
    display: flex;
    flex-direction: column;
  }
`;

const StepperTitle = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  font-weight: ${({ id, isPageAddress, isPagePayment, isActive, isSuccess, isMembershipForRenewal, theme }: DivProps) =>
    !isMembershipForRenewal
      ? (id === 3 && isPagePayment) || (id === 2 && isPageAddress)
        ? theme.fontWeights.black
        : isSuccess || isPagePayment
        ? theme.fontWeights.medium
        : isActive
        ? theme.fontWeights.black
        : theme.fontWeights.medium
      : isActive
      ? theme.fontWeights.black
      : theme.fontWeights.medium};
  margin-bottom: ${props => props.theme.pxToRem(11)};
  margin-right: ${({ theme }: DivProps) => theme.pxToRem(20)};
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ id, isPageAddress, isPagePayment, isActive, isSuccess, isMembershipForRenewal, theme }: DivProps) =>
    !isMembershipForRenewal
      ? (id === 3 && isPagePayment) || (id === 2 && isPageAddress)
        ? theme.colors.neutralWhite
        : isSuccess || isPagePayment
        ? theme.colors.lightGrey
        : isActive
        ? theme.colors.neutralWhite
        : theme.colors.lightGrey
      : isActive
      ? theme.colors.neutralWhite
      : isSuccess
      ? theme.colors.lightGrey
      : theme.colors.lightGrey};
  width: ${({ theme }: DivProps) => theme.pxToRem(90)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    position: relative;
    left: 0;
    width: auto;
    margin-right: ${({ id, theme }: DivProps) => (id === 3 || id === 4 ? theme.pxToRem(10) : 0)}

    color: ${({ isActive, isSuccess, theme }: DivProps) =>
      isActive ? theme.colors.primaryPurple : isSuccess ? theme.colors.interfaceGreen : theme.colors.neutralWhite};
  }
`;

const StepperCircle = styled.div`
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
  border-radius: ${props => props.theme.pxToRem(16)};
  border: ${({ id, isPageAddress, isPagePayment, isMembershipForRenewal, isActive, isSuccess, theme }: DivProps) =>
    !isMembershipForRenewal
      ? (id === 3 && isPagePayment) || (id === 2 && isPageAddress)
        ? theme.colors.neutralWhite
        : isSuccess || isPagePayment
        ? theme.colors.successGreen
        : isActive || isPagePayment
        ? theme.colors.neutralWhite
        : theme.colors.lightGrey
      : isActive
      ? theme.colors.neutralWhite
      : isSuccess
      ? theme.colors.successGreen
      : theme.colors.lightGrey}
    ${props => props.theme.pxToRem(4)} solid !important;
  background-color: ${({
    id,
    isActive,
    isSuccess,
    isPageAddress,
    isPagePayment,
    isMembershipForRenewal,
    theme,
  }: DivProps) =>
    !isMembershipForRenewal
      ? (id === 3 && isPagePayment) || (id === 2 && isPageAddress)
        ? theme.colors.primaryPurple
        : isSuccess || isPagePayment
        ? theme.colors.successGreen
        : isActive
        ? theme.colors.primaryPurple
        : theme.colors.lightGrey
      : isActive
      ? theme.colors.primaryPurple
      : isSuccess
      ? theme.colors.successGreen
      : theme.colors.lightGrey}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isSuccess }: DivProps) => (isSuccess ? 'pointer' : 'default')} !important;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: 0;

    border: ${({ isActive, isSuccess, theme }: DivProps) =>
      isActive ? theme.colors.primaryPurple : isSuccess ? theme.colors.successGreen : theme.colors.lightGrey}
      ${props => props.theme.pxToRem(4)} solid !important;
  }
`;

const StepperLine = styled.div`
  width: ${({ theme }: DivProps) => theme.pxToRem(80)};
  height: ${props => props.theme.pxToRem(4)};
  background-color: ${({
    id,
    isMembershipForRenewal,
    isActive,
    isSuccess,
    isPageAddress,
    isPagePayment,
    theme,
  }: DivProps) =>
    !isMembershipForRenewal
      ? isPagePayment
        ? theme.colors.successGreen
        : isPageAddress && isActive
        ? theme.colors.primaryPurple
        : isSuccess
        ? theme.colors.successGreen
        : theme.colors.lightGrey
      : isActive
      ? theme.colors.primaryPurple
      : isSuccess
      ? theme.colors.successGreen
      : theme.colors.lightGrey};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${({ theme }: DivProps) => theme.pxToRem(22)};
    flex: 1;
    margin: 0;
  }
`;

const StepperIcons = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex: 1;
    margin: 0;
  }
`;

const StepperTitleRow = styled.div`
  display: flex;

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-top: ${props => props.theme.pxToRem(5)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-right: ${props => props.theme.pxToRem(8)};
    justify-content: space-around;
  }
`;

const StepperCircleRow = styled.div`
  display: flex;

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-left: ${props => props.theme.pxToRem(22)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(15)};
  }
`;

const StepperNumber = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ id, isPagePayment, isPageAddress, isActive, theme }: DivProps) =>
    isActive || (id === 3 && isPagePayment) || (id === 2 && isPageAddress)
      ? theme.colors.neutralWhite
      : theme.colors.neutralBlack};
`;

const StyledPreviousPage = styled(PreviousPage)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(23)};

    svg {
      margin-left: ${props => props.theme.pxToRem(3)};
      cursor: pointer;
      width: ${props => props.theme.pxToRem(15)};
      height: ${props => props.theme.pxToRem(15)};
      path {
        fill: ${props => props.theme.colors.neutralWhite};
      }
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(25)};
    }
  }
`;

const StyledStepperHeader = styled.div`
  display: none;
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: block;
    margin-left: ${props => props.theme.pxToRem(20)};
    margin-bottom: ${props => props.theme.pxToRem(24)};
  }
`;
