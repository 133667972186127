import { push } from 'connected-react-router';
import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { SUBMIT_ENQUIRY_FORM, QUERY_CONTENT_BY_SEARCH, CREATE_NEW_LEAD } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';
import { InquiryFormActionNames } from './constants';
import { constructQueryString } from './helpers';
import { Content, InquiryForm } from 'mxp-schemas';
import { getProductItem } from 'modules/products/actions';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

export const submitInquiryForm: any = createAction(
  InquiryFormActionNames.SUBMIT_INQUIRY_FORM,
  (inquiryForm: InquiryForm.FormTypes) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const queryString: string = constructQueryString(inquiryForm);

    const type: string = inquiryForm.type;
    const isMembership: boolean = type === 'membership';

    dispatch(loading());

    if (isMembership) {
      const membershipInquiryForm = inquiryForm as InquiryForm.Membership;
      const result = await request(CREATE_NEW_LEAD, {
        firstName: membershipInquiryForm?.firstName,
        lastName: membershipInquiryForm?.lastName,
        phone: membershipInquiryForm?.phone,
        email: membershipInquiryForm?.email,
        inquiryRole: membershipInquiryForm?.inquiryRole,
        countryCode: membershipInquiryForm?.country,
        state: membershipInquiryForm?.state,
        iWorkIn: membershipInquiryForm?.iWorkIn,
        receivedEmail: membershipInquiryForm?.receivedEmail,
        receivedSms: membershipInquiryForm?.receivedSms,
      });
      return result.createNewLeadExistingIndividual;
    } else {
      return request(SUBMIT_ENQUIRY_FORM, { queryString, type })
        .then(({ submitEnquiryForm: { success } }: { submitEnquiryForm: { success: boolean } }) => {
          if (success) {
            return { success: true, errorMessage: '' };
          }
          return { success: false, errorMessage: '' };
        })
        .catch(() => {
          return dispatch(push(getPath(Routes.NOT_FOUND), { fetchError: true }));
        });
    }
  }
);

export const loading: any = createAction(InquiryFormActionNames.LOADING);
export const startFormFill: any = createAction(InquiryFormActionNames.INQUIRY_FORM_START);
export const endFormFill: any = createAction(InquiryFormActionNames.INQUIRY_FORM_COMPLETE, (text: string) => text);

export const searchLoading: any = createAction(InquiryFormActionNames.SEARCH_LOADING);

export const fetchProductSearchResults: any = createAction(
  InquiryFormActionNames.FETCH_PRODUCT_SEARCH_RESULTS,
  (searchQuery: string) => (dispatch: Dispatch) => {
    dispatch(searchLoading());

    const query = {
      searchQuery,
      sortBy: 'relevancy',
      page: 1,
      perPage: 10,
      filters: {
        category: Content.CategorySlugs.CPE_LEARNING,
      },
      options: {
        onlyProducts: true,
        onlyEvents: false,
      },
    };
    return request(QUERY_CONTENT_BY_SEARCH, query)
      .then((response: any) => {
        if (!response.getContentBySearch?.results?.length) {
          return [];
        }

        return Promise.resolve(response.getContentBySearch.results);
      })
      .catch(() => {
        // fail silently
        return [];
      });
  }
);

export const updateSearchSelection: any = createAction(
  InquiryFormActionNames.UPDATE_SEARCH_SELECTION,
  (searchResult: State.InquiryFormSearchResult | null) => searchResult
);

export const fetchProductItem: any = createAction(
  InquiryFormActionNames.FETCH_PRODUCT_ITEM,
  (slug: string) => (dispatch: Dispatch) => {
    dispatch(getProductItem(slug)).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  }
);
