import React from 'react';
interface Props {
  size?: number;
  color?: string;
  className?: string;
}

export const Lock = React.memo<Props>(({ size = 24, color = '#72246c', className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" className={className}>
    <path
      fill={color}
      d="M17 8.667h-.833V7c0-2.3-1.867-4.167-4.167-4.167A4.168 4.168 0 0 0 7.833 7v1.667H7c-.917 0-1.667.75-1.667 1.666v8.334c0 .916.75 1.666 1.667 1.666h10c.917 0 1.667-.75 1.667-1.666v-8.334c0-.916-.75-1.666-1.667-1.666zm-5 7.5c-.917 0-1.667-.75-1.667-1.667s.75-1.667 1.667-1.667 1.667.75 1.667 1.667-.75 1.667-1.667 1.667zm2.583-7.5H9.417V7A2.586 2.586 0 0 1 12 4.417 2.586 2.586 0 0 1 14.583 7v1.667z"
    />
  </svg>
));
