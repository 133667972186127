import { getHash } from 'connected-react-router';
import React from 'react';
import { Button as SemanticUIButton, Icon, SemanticICONS, StrictButtonProps } from 'semantic-ui-react';
import { store } from '../../../store';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import { Scroller, isExternal as isExt } from 'utils';
import { handleEvent, NAV_CLICK, EVENT_CLICK, EXTERNAL_LINK_EVENT } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
import { useSelector } from 'react-redux';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';

export enum ButtonSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}
export enum ButtonVariants {
  primary = 'primary',
  primaryNegative = 'primary-negative',
  cloud = 'cloud',
  primaryToggle = 'primary-toggle',
  link = 'link',
  standAloneLink = 'standAloneLink',
  white = 'white',
  secondary = 'secondary',
  secondaryNegative = 'secondary-negative',
  icon = 'icon',
  iconWhiteTone = 'icon-white-tone',
  iconWhiteWithCircle = 'icon-white-with-circle',
  iconWithCircle = 'icon-with-circle',
}

enum ButtonIconPosition {
  right = 'right',
  left = 'left',
}

export const ButtonEnums = {
  iconPosition: ButtonIconPosition,
  sizes: ButtonSizes,
  variants: ButtonVariants,
};

export interface ButtonProps extends StrictButtonProps {
  iconName?: SemanticICONS;
  icon?: React.ReactNode;
  iconPosition?: ButtonIconPosition;
  testId: string;
  size?: ButtonSizes;
  variant?: ButtonVariants;
  bordered?: boolean;
  toggle?: boolean;
  to?: string;
  href?: string;
  target?: string;
  external?: boolean;
  fluid?: boolean;
  className?: string;
  type?: string;
  overrideAnalytics?: boolean;
  download?: string;
  disabled?: boolean;
  currentPath?: string;
  viewTextForAnalytics?: string;
  textValue?: string;
  buttonText?: string;
  isTextLink?: boolean;
  others?: any;
  isLoading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  iconName,
  icon,
  iconPosition,
  testId,
  variant,
  bordered,
  className = '',
  size = ButtonSizes.medium,
  fluid,
  overrideAnalytics = false,
  viewTextForAnalytics = '',
  external,
  isTextLink = false,
  ...props
}) => {
  const { useNewMembershipAICPA } = useSelector(featureTogglesSelector);
  // to get the current page data before any location change happen in redux router
  const { pageName, siteSection } = getPageNameAndSiteSection();
  // analytics
  const handleButtonEvent = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (overrideAnalytics) return;
      const state: State.Root = store?.getState();
      const hashValue = getHash(state);
      const toOrHref = props?.to || props?.href;
      const hashScroll = String(toOrHref).charAt(0) === '#';
      const { routerPathname, windowPathname } = getPageNameAndSiteSection();
      const currentLocation = routerPathname || windowPathname;
      const destination = !hashScroll
        ? toOrHref || props?.currentPath || `${currentLocation}${hashValue}`
        : currentLocation;
      const buttonText = props?.buttonText || (event.target as HTMLAnchorElement)?.textContent || '';
      const isExternal = external || isExt(destination);
      const destinationType = isExternal ? 'ext' : 'int';
      const source = isTextLink ? 'text' : 'button';

      const isValue = props?.textValue ? `/${props?.textValue}` : '';

      if (hashScroll) {
        handleEvent(
          {
            clickValue: `${source}:link:${destinationType}::${buttonText}:${destination}${isValue}`,
          },
          EVENT_CLICK
        );
      }
      if (destination) {
        if (isExternal) {
          return handleEvent(
            {
              text: `${source}:link:${destinationType}::${buttonText}${
                viewTextForAnalytics ? `:${viewTextForAnalytics}` : ''
              }`,
              href: destination,
              buttonText,
            },
            EXTERNAL_LINK_EVENT
          );
        }
        const payload = {
          clickValue: `${source}:link:${destinationType}::${buttonText}:${destination}${isValue}`,
          pageName,
          siteSection,
        };
        return handleEvent(props.others ? { ...payload, ...props.others } : payload, NAV_CLICK, state);
      }

      return handleEvent({ clickValue: `${source}::${buttonText}` }, EVENT_CLICK);
    },
    [props, overrideAnalytics, viewTextForAnalytics, pageName, siteSection, external, isTextLink]
  );

  const handleOnClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
      if (props.disabled) {
        event.preventDefault();
      } else {
        const isAnchor = props?.to?.startsWith('#') || props?.href?.startsWith('#');
        if (isAnchor) {
          event.preventDefault();
          Scroller.scrollIntoElement(props?.to || props?.href || '');
        }

        if (props?.onClick) props.onClick(event, data);

        handleButtonEvent(event);
      }
    },
    [props, handleButtonEvent]
  );

  const renderIcon = (): React.ReactNode => {
    if (!icon && !iconName) return null;
    return icon || <Icon name={iconName} data-testid={`icon-${testId}`} />;
  };
  return (
    <StyledButton
      {...props}
      // Fix for console Warning: Received `false` for a non-boolean attribute.
      bordered={bordered ? 'bordered' : ''}
      fluid={fluid}
      data-testid={`button-${testId}`}
      variant={variant}
      size={size}
      className={className}
      onClick={handleOnClick}
      useNewMembershipAICPA={useNewMembershipAICPA}
    >
      {iconPosition === ButtonIconPosition.left && renderIcon()}
      {children && <span>{children}</span>}
      {iconPosition === ButtonIconPosition.right && renderIcon()}
    </StyledButton>
  );
};

const StyledButton = styled(SemanticUIButton)<ThemedStyledProps<ButtonProps, DefaultTheme>>`
  ${({ variant, size, theme, bordered, fluid, disabled, useNewMembershipAICPA }) =>
    variant === ButtonVariants.primary &&
    `
      &&&& {
        background-color: ${theme.colors.primaryPurple};
        color: ${theme.colors.neutralWhite};
        ${bordered ? `border: ${theme.pxToRem(1)} solid ${theme.colors.primaryPurple}` : ''};
        font-weight: ${theme.fontWeights.medium};
        transition: all 0.3s;
        margin: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: ${theme.pxToRem(22)}; // for IE11
        ${styleByButtonSize(size, theme)}
        ${fluid ? 'width: 100%' : ''};

        i.icon {
          height: auto;
          margin: 0 ${theme.pxToRem(8)};
        }

        > svg {
          ${svgStyleByButtonSize(size, theme)};
          margin-right: ${theme.pxToRem(4)};
        }
        // TODO: this is a temp fix for buttons with 'as' attribute until we update semantic ui, should be reverted for the sake of simplicity after update. fix/AP-5222-physical-product
        ${
          !disabled
            ? `
        &:active,
        &:focus {
          background-color: ${theme.colors.primaryPurple};
          color: ${theme.colors.neutralWhite};
          ${bordered ? `border: 1px solid ${theme.colors.primaryPurple}` : ''};
        }

        ${theme.mediaQueries.computerMin} {
          &:hover {
            background-color: ${theme.colors.primaryLightPurple};
            color: ${theme.colors.neutralWhite};
            ${bordered ? `border: ${theme.pxToRem(1)} solid ${theme.colors.primaryLightPurple}` : ''};
          }
        }
        `
            : ''
        };

        &:disabled {
          color: ${disabledStyles(theme, useNewMembershipAICPA)};
          border-color: ${theme.colors.neutralGrey4};
          background-color: ${theme.colors.neutralGrey4};
          opacity: 1 !important;
        }

        ${
          disabled ?
          `
          color: ${disabledStyles(theme, useNewMembershipAICPA)};
          border-color: ${theme.colors.neutralGrey4};
          background-color: ${theme.colors.neutralGrey4};
          opacity: 1 !important;
          cursor: default;
        ` : ''
        }
      }
    `}
  ${({ variant, size, theme, fluid, disabled, useNewMembershipAICPA }) =>
    variant === ButtonVariants.secondary &&
    `
      &&&& {
        background-color: ${theme.colors.neutralWhite};
        color: ${theme.colors.primaryPurple};
        border: ${theme.pxToRem(1)} solid ${theme.colors.primaryPurple};
        font-weight: ${theme.fontWeights.medium};
        transition: all 0.3s;
        margin: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: ${theme.pxToRem(22)}; // for IE11
        ${styleByButtonSize(size, theme)}
        ${fluid ? 'width: 100%' : ''};

        i.icon {
          height: auto;
          margin: 0 ${theme.pxToRem(8)};
        }

        > svg {
          ${svgStyleByButtonSize(size, theme)};
          margin-right: ${theme.pxToRem(4)};
        }

        ${
          !disabled
            ? `
        &:active,
        &:focus {
          background-color: ${theme.colors.primaryPurple};
          color: ${theme.colors.neutralWhite};
          border: ${theme.pxToRem(1)} solid ${theme.colors.primaryPurple};
        }

        ${theme.mediaQueries.computerMin} {
          &:hover {
            background-color: ${theme.colors.primaryLightPurple};
            color: ${theme.colors.neutralWhite};
            border-color: transparent;
          }
        }
        `
            : ''
        };

        &:disabled {
          border-color: ${theme.colors.neutralGrey4};
          background-color: transparent;
          color: ${theme.colors.neutralGrey4};
          opacity: 1 !important;
        }

        ${
          disabled
            ? `
          color: ${disabledStyles(theme, useNewMembershipAICPA)};
          border-color: ${theme.colors.neutralGrey4};
          background-color: ${theme.colors.neutralGrey4};
          opacity: 1 !important;
          cursor: default;
        `
            : ''
        }
      }
    `}

  ${({ variant, size, theme, fluid, useNewMembershipAICPA }) =>
    variant === ButtonVariants.secondaryNegative &&
    `
      &&&& {
        background-color: transparent;
        color: ${theme.colors.neutralWhite};
        border: ${theme.pxToRem(1)} solid ${theme.colors.neutralWhite};
        font-weight: ${theme.fontWeights.medium};
        transition: all 0.3s;
        margin: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: ${theme.pxToRem(22)}; // for IE11
        ${styleByButtonSize(size, theme)}
        ${fluid ? 'width: 100%' : ''};

        i.icon {
          height: auto;
          margin: 0 ${theme.pxToRem(8)};
        }

        > svg {
          ${svgStyleByButtonSize(size, theme)};
          margin-right: ${theme.pxToRem(4)};
        }

        &:active,
        &:focus{
          background-color: transparent;
          color: ${theme.colors.neutralWhite};
          border: ${theme.pxToRem(1)} solid ${theme.colors.neutralWhite};
        }

        ${theme.mediaQueries.computerMin} {
          &:hover {
            background-color: ${theme.colors.neutralWhite};
            color: ${theme.colors.primaryPurple};
            border-color: transparent;
          }
        }

        &:disabled {
          border-color: ${theme.colors.neutralGrey4};
          background-color: ${theme.colors.neutralGrey4};
          opacity: 1 !important;
          color: ${disabledStyles(theme, useNewMembershipAICPA)};
        }
      }
    `}

${({ variant, size, theme, fluid, useNewMembershipAICPA }) =>
    variant === ButtonVariants.primaryNegative &&
    `
      &&&& {
        background-color: transparent;
        color: ${theme.colors.primaryPurple};
        border: ${theme.pxToRem(1)} solid ${theme.colors.primaryPurple};
        font-weight: ${theme.fontWeights.medium};
        transition: all 0.3s;
        margin: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: ${theme.pxToRem(22)}; // for IE11
        ${styleByButtonSize(size, theme)}
        ${fluid ? 'width: 100%' : ''};

        i.icon {
          height: auto;
          margin: 0 ${theme.pxToRem(8)};
        }

        > svg {
          ${svgStyleByButtonSize(size, theme)};
          margin-right: ${theme.pxToRem(4)};
        }

        &:active,
        &:focus{
          background-color: transparent;
          color: ${theme.colors.primaryPurple};
          border: ${theme.pxToRem(1)} solid ${theme.colors.primaryPurple};
        }

        ${theme.mediaQueries.computerMin} {
          &:hover {
            background-color: ${theme.colors.primaryPurple};
            color: ${theme.colors.neutralWhite};
            border-color: transparent;
          }
        }

        &:disabled {
          border-color: ${theme.colors.neutralGrey4};
          background-color: ${theme.colors.neutralGrey4};
          opacity: 1 !important;
          color: ${disabledStyles(theme, useNewMembershipAICPA)};
        }
      }
    `}

  ${({ variant, size, theme, fluid }) =>
    variant === ButtonVariants.link &&
    `
      &&&& {
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        color: ${theme.colors.primaryPurple};
        font-weight: ${theme.fontWeights.medium};
        transition: all 0.3s;
        margin: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        padding: 0;

        span {
          text-decoration: underline;
        }
        ${linkStyleByButtonSize(size, theme)};

        ${fluid ? 'width: 100%' : ''};

        i.icon {
          height: auto;
          margin: 0 ${theme.pxToRem(4)} !important;
          color: ${theme.colors.primaryPurple};
        }

        > svg {
          ${smallSvgStyleByButtonSize(size, theme)}
          margin: 0 ${theme.pxToRem(4)};
        }

        &:active,
        &:focus {
          background-color: transparent;
          color: ${theme.colors.interfaceBlue};
          span {
            text-decoration: none;
          }
        }

        ${theme.mediaQueries.computerMin} {
          &:hover {
            background-color: transparent;
            color: ${theme.colors.interfaceBlue};

            span {
              text-decoration: none;
            }
          }
        }

        &:disabled {
          color: ${theme.colors.neutralGrey4};
          opacity: 1 !important;
          border-color: ${theme.colors.neutralGrey4};

          i.icon {
            color: ${theme.colors.neutralGrey4};
          }
        }
      }
    `}
  ${({ variant, size, theme, fluid }) =>
    variant === ButtonVariants.standAloneLink &&
    `
      &&&& {
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        color: ${theme.colors.neutralGrey8};
        font-weight: ${theme.fontWeights.medium};
        transition: all 0.3s;
        margin: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        padding: 0;
        ${linkStyleByButtonSize(size, theme)};
        ${fluid ? 'width: 100%' : ''};

        i.icon {
          height: auto;
          margin: 0 ${theme.pxToRem(4)} !important;
          color: ${theme.colors.primaryPurple};
        }

        > svg {
          ${smallSvgStyleByButtonSize(size, theme)}
          margin: 0 ${theme.pxToRem(4)};
        }

        &:active,
        &:focus {
          background-color: transparent;
          color: ${theme.colors.primaryPurple};
          span {
            text-decoration: none;
          }
        }

        ${theme.mediaQueries.computerMin} {
          &:hover {
            background-color: transparent;
            color: ${theme.colors.primaryPurple};

            span {
              text-decoration: underline;
            }
          }
        }

        &:disabled {
          color: ${theme.colors.neutralGrey4};
          opacity: 1 !important;
          border-color: ${theme.colors.neutralGrey4};

          i.icon {
            color: ${theme.colors.neutralGrey4};
          }
        }
      }
    `}
  // FixMe. Must be refactored as 'primary' and 'secondary'
  ${({ variant, theme, bordered, useNewMembershipAICPA }) =>
    variant === ButtonVariants.primaryToggle &&
    `
      &&&& {
        display: flex;
        width: auto;
        min-width: ${theme.pxToRem(112)}
        white-space: nowrap;
        padding: 0.625rem;
        border: ${theme.pxToRem(1)} solid ${bordered ? theme.colors.primaryPurple : 'transparent'};
        border-radius: 0.125rem;
        margin: 0 0 0 0.625rem;
        background-color: ${theme.colors.neutralWhite};
        color: ${theme.colors.primaryPurple};
        font-size: ${theme.fontSizes.xs};
        font-weight: ${theme.fontWeights.medium};
        padding: 0.75rem 0.9375rem;
        margin: 0;

        &.toggle.active {
          background-color: ${theme.colors.primaryPurple} !important;
        }

        ${theme.mediaQueries.computerMin} {
          &.toggle:hover {
            border: ${theme.pxToRem(1)} solid ${bordered ? theme.colors.primaryLightPurple : 'transparent'};
            background-color: ${theme.colors.primaryLightPurple} !important;
            color: ${theme.colors.neutralWhite} !important;
          }

          &.toggle.active:hover {
            border: ${theme.pxToRem(1)} solid ${bordered ? theme.colors.interfaceRed : 'transparent'};
            background-color: ${theme.colors.interfaceRed} !important;
          }
        }

        &:disabled {
          color: ${disabledStyles(theme, useNewMembershipAICPA)};
          border-color: ${theme.colors.neutralGrey4};
          background-color: ${theme.colors.neutralGrey4};
          opacity: 1 !important;
        }
      }
    `}

  // FixMe. Must be refactored as 'primary' and 'secondary'
  ${props =>
    props.variant === ButtonVariants.white &&
    `
      &&& {
        background-color: ${props.theme.colors.neutralWhite};
        color: ${props.theme.colors.primaryPurple};
        ${props.bordered ? `border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.primaryPurple}` : ''};

        &:hover {
          background-color: ${props.theme.colors.neutralWhite};
          color: ${props.theme.colors.primaryLightPurple};
          ${props.bordered ? ` border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.primaryLightPurple}` : ''};
        }
      }
    `}

    ${props =>
    props.variant === ButtonVariants.iconWithCircle &&
    `
      &&& {
        border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3};
        background-color: ${props.theme.colors.neutralWhite};
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        display: table;
        cursor: pointer;
        margin-top: 1rem;
        margin-bottom: 1rem;

        svg {
          height: 2.3rem;
        }

        &:focus {
          outline: none;
          background-color: ${props.theme.colors.neutralWhite};
        }

        &:hover {
          background: ${props.theme.colors.neutralWhite};
        }

        &.active {
          background-color: ${props.theme.colors.primaryPurple};
          border-color: transparent;

          svg, svg path {
            fill: ${props.theme.colors.neutralWhite};
          }
        }

        ${props.theme.mediaQueries.computerMin} {
          &&:hover {
            border-color: transparent;
            background: ${props.theme.colors.primaryLightPurple};
            outline: none;
            path {
              fill: ${props.theme.colors.neutralWhite};
            }
          }
        }

        &:disabled {
          color: ${props.useNewMembershipAICPA ? props.theme.colors.neutralGrey8 : props.theme.colors.neutralWhite};
          border-color: ${props.theme.colors.neutralGrey4};
          background-color: ${props.theme.colors.neutralGrey4};
          opacity: 1 !important;
        }
      }
    `}

    ${props =>
    (props.variant === ButtonVariants.icon ||
      props.variant === ButtonVariants.iconWhiteTone ||
      props.variant === ButtonVariants.iconWhiteWithCircle) &&
    `
      &&&& {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        line-height: 0;
        margin: 0;

        path {
          fill: ${props.theme.colors.neutralGrey4};
        }

        &:focus {
          outline: none;
        }

        &:hover {
          background: none;
        }

        &.active {
          svg, svg path {
            fill: ${
              props.variant === ButtonVariants.iconWhiteTone
                ? props.theme.colors.neutralWhite
                : props.theme.colors.primaryDarkPurple
            };
          }
        }

        ${props.theme.mediaQueries.computerMin} {
          &&&&:hover {
              path {
                fill: ${
                  props.variant === ButtonVariants.iconWhiteTone
                    ? props.theme.colors.primaryIndicatorPurple
                    : props.theme.colors.primaryLightPurple
                };
              }
            }
          }
        }

        &:disabled {
          border-color: ${props.theme.colors.neutralGrey4};
          background-color: ${props.theme.colors.neutralGrey4};
          opacity: 1 !important;
        }
      }
    `}

    ${props =>
    props.variant === ButtonVariants.iconWhiteTone &&
    `
        &&&& {
          svg, svg path {
            fill: ${props.theme.colors.neutralWhite}
          }
        }
      `}

    ${props =>
    props.variant === ButtonVariants.iconWhiteWithCircle &&
    `
        &&&& {
          border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralWhite};
          border-radius: 50%;
          width: 2.5rem;
          height: 2.5rem;

          svg, svg path {
            fill: ${props.theme.colors.neutralWhite};
          }

          &:hover, &:focus {
            border-color: ${props.theme.colors.primaryIndicatorPurple};
          }

          &.active {
            svg, svg path {
              fill: ${props.theme.colors.neutralWhite};
            }
          }

          ${props.theme.mediaQueries.computerMin} {
            &&&&:hover {
                path {
                  fill: ${props.theme.colors.primaryIndicatorPurple};
                }
              }
            }
          }
        }
      `}

      ${props =>
    props.variant === ButtonVariants.cloud &&
    `
          &&&& {
            font-weight: ${props.theme.fontWeights.regular};
            border-radius: ${props.theme.pxToRem(32)};
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
            font-size: ${props.theme.pxToRem(18)};
            color: ${props.theme.colors.neutralGrey8};
            background: ${props.theme.colors.neutralWhite};
            display: flex;
            align-items: center;
          }
        `}

        &&&&&& {
    font-family: ${props => props.theme.fontFamily};
  }
`;

function styleByButtonSize(size: ButtonSizes | undefined, theme: DefaultTheme) {
  let styles = '';
  if (size === ButtonSizes.small) {
    styles = `
		border-radius: ${theme.pxToRem(2)};
		padding: ${theme.pxToRem(5)};
		min-height: ${theme.pxToRem(34)};
		font-size: ${theme.fontSizes.xs};
	  `;
  } else if (size === ButtonSizes.medium) {
    styles = `
		border-radius: ${theme.pxToRem(4)};
		padding: ${theme.pxToRem(8)};
		min-height: ${theme.pxToRem(40)};
		font-size: ${theme.fontSizes.s};
	  `;
  } else if (size === ButtonSizes.large) {
    styles = `
		border-radius: ${theme.pxToRem(4)};
		padding: ${theme.pxToRem(12)};
		min-height: ${theme.pxToRem(48)};
		font-size: ${theme.fontSizes.m};
	  `;
  }
  return styles;
}

function svgStyleByButtonSize(size: ButtonSizes | undefined, theme: DefaultTheme) {
  let styles = '';
  if (size === ButtonSizes.small || size === ButtonSizes.medium) {
    styles = `
      flex: 0 0 ${theme.pxToRem(20)};
      width: ${theme.pxToRem(20)};
      height: ${theme.pxToRem(20)};
      `;
  } else if (size === ButtonSizes.large) {
    styles = `
      flex: 0 0 ${theme.pxToRem(24)};
      width: ${theme.pxToRem(24)};
      height: ${theme.pxToRem(24)};
      `;
  }
  return styles;
}

function smallSvgStyleByButtonSize(size: ButtonSizes | undefined, theme: DefaultTheme) {
  let styles = '';
  if (size === ButtonSizes.small || size === ButtonSizes.medium) {
    styles = `
      flex: 0 0 ${theme.pxToRem(18)};
      width: ${theme.pxToRem(18)};
      height: ${theme.pxToRem(18)};
      `;
  } else if (size === ButtonSizes.large) {
    styles = `
      flex: 0 0 ${theme.pxToRem(24)};
      width: ${theme.pxToRem(24)};
      height: ${theme.pxToRem(24)};
      `;
  }
  return styles;
}

function linkStyleByButtonSize(size: ButtonSizes | undefined, theme: DefaultTheme) {
  let styles = '';
  if (size === ButtonSizes.small || size === ButtonSizes.medium) {
    styles = `
      min-height: ${theme.pxToRem(20)};
      font-size: ${theme.fontSizes.xs};
      `;
  } else if (size === ButtonSizes.large) {
    styles = `
      min-height: ${theme.pxToRem(24)};
      font-size: ${theme.fontSizes.s};
      `;
  }
  return styles;
}

function disabledStyles(theme: DefaultTheme, toggle?: boolean | undefined){
  return toggle ? theme.colors.neutralGrey8 : theme.colors.neutralWhite;
}
