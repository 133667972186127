import { createAction } from 'redux-actions';
// ------------------------------------
// Actions
// ------------------------------------

export const getIndustriesHash: any = createAction(
  'industries/GET_INDUSTRIES_HASH',
  (industries: State.Industries) => () => {
    return industries;
  }
);
