import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPromotedProducts } from 'modules/content/actions';
import { getPromotedProductsSelector } from 'modules/content/selectors';
import { PromotedProductCards } from './PromotedProductCards';

interface Props {
  items?: any;
  setBurger: (state: boolean) => void;
  globalHistory: any;
  clearMegaHistory: () => void;
}

export const PromotedProducts: React.FC<Props> = ({ items, setBurger, globalHistory, clearMegaHistory }) => {
  const dispatch = useDispatch();
  const products: string[] = items?.[0]?.productSlug || [];
  const getPromotedProductsData: State.PromotedProductsCard[] = useSelector(getPromotedProductsSelector) || [];

  useEffect(() => {
    (async () => {
      if (products?.length && !getPromotedProductsData?.length) await dispatch(getPromotedProducts(products));
    })();
  }, [dispatch, getPromotedProductsData, products]);

  return (
    <>
      {getPromotedProductsData?.map((item: State.PromotedProductsCard) => {
        return (
          <>
            <PromotedProductCards
              item={item}
              setBurger={setBurger}
              globalHistory={globalHistory}
              clearMegaHistory={clearMegaHistory}
            ></PromotedProductCards>
          </>
        );
      })}
    </>
  );
};
