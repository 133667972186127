import React from 'react';
import { Modal, Button, ButtonEnums, Link } from 'components/atoms';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { cimaMembershipTermSelector } from 'modules/user/selectors';
import { MembershipTypes } from 'mxp-schemas';
import { Routes } from 'constants/index';
import { getPath } from 'utils';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
}

export const MipApplyCimaRegularModal: React.FC<Props> = React.memo(({ onClose, onConfirm }) => {
  // Check if user is a CIMA Regular member
  const cimaMembershipTerms = useSelector(cimaMembershipTermSelector);
  const userHasCimaRegularMembership = cimaMembershipTerms?.some(
    (item: any) => item?.membershipTermType === MembershipTypes.MembershipKeys.REGULAR
  );

  return (
    <StyledModal
      open={true}
      onClose={onClose}
      heading={!userHasCimaRegularMembership ? 'It appears you are not eligible.' : 'Apply for a membership'}
      testId="apply-mip-member-modal"
      showCloseCross
      cancelNode={
        !userHasCimaRegularMembership ? (
          <Button
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.primary}
            testId="no-follow"
            onClick={onClose}
          >
            I understand
          </Button>
        ) : (
          <Button
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.secondary}
            testId="cancel-apply"
            onClick={onClose}
          >
            Cancel
          </Button>
        )
      }
      confirmNode={
        !userHasCimaRegularMembership ? null : (
          <Button
            size={ButtonEnums.sizes.small}
            testId="confirm-apply"
            variant={ButtonEnums.variants.primary}
            onClick={onConfirm}
            iconPosition={ButtonEnums.iconPosition.left}
            className="downloadButton"
          >
            Apply
          </Button>
        )
      }
    >
      {!userHasCimaRegularMembership ? (
        <>
          Your membership type is not eligible to apply for member in practice status. For more information, select the
          chat icon to the right of your screen, or <Link to={getPath(Routes.CONTACT_US)}>visit our help page</Link> for
          frequently asked questions and other contact information.
        </>
      ) : (
        <>You need to apply for a CIMA regular membership to buy this credential.</>
      )}
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  &&&& > button {
    position: absolute !important;
    top: ${props => props.theme.pxToRem(16)}!important;
    right: ${props => props.theme.pxToRem(16)}!important;
  }

  &&&& {
    width: ${props => props.theme.pxToRem(590)};
    background: ${props => props.theme.colors.neutralWhite};
    margin-top: ${props => props.theme.pxToRem(48)};
    border-radius: ${props => props.theme.pxToRem(2)};
    padding: ${props => props.theme.pxToRem(37)} 0 ${props => props.theme.pxToRem(20)}!important;
    box-sizing: border-box;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    > .content {
      margin: 0 !important;
      text-align: center;
      box-sizing: border-box;
      padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(20)}!important;
    }

    > .header {
      margin: 0 auto;
      text-align: center;
      display: table;
      h2 {
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }

    > .actions {
      margin: 0 ${props => props.theme.pxToRem(20)};
    }
  }

  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;
