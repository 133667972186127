import React from 'react';
import { theme } from 'theme';

interface Props {
  size: number;
  color?: string;
  testId?: string;
}

export const ClosedCaption = React.memo<Props>(({ size = 24, color = theme.colors.neutralBlack, testId, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    data-testid={testId}
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M19 4H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 7H9.5v-.5h-2v3h2V13H11v1c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v1zm7 0h-1.5v-.5h-2v3h2V13H18v1c0 .55-.45 1-1 1h-3c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v1z"
    />
  </svg>
));
