import React from 'react';
import styled from 'styled-components';
import { Button, Grid } from 'semantic-ui-react';
import { IC_CHECK_AUDIT } from 'resources/images';
import { Link, LinkEnums } from 'components/atoms';

interface Props {
  main?: string | React.ReactNode;
  sub?: string | React.ReactNode;
  redirectonLink?: string;
  viewAudit?: () => void;
}

export const AuditNotifBanners: React.FC<Props> = ({ main, sub, redirectonLink }) => {
  const viewAudit = () => (
    <StyledButton>
      <StyledTextLink
        underline={LinkEnums.underline.noUnderlineOnHover}
        target={'_blank'}
        testId="view-audit-details"
        to={redirectonLink as string}
      >
        View audit details
      </StyledTextLink>
    </StyledButton>
  );

  return (
    <>
      <StyledDiv>
        <Grid>
          <Grid.Column mobile={16} tablet={2} computer={1}>
            <StyledFlexColumnCenter>
              <StyledCircle>
                <StyledCheckHolder>
                  <IconSuccessAuditHolder src={IC_CHECK_AUDIT} loading="lazy" />
                </StyledCheckHolder>
              </StyledCircle>
            </StyledFlexColumnCenter>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={14} computer={15}>
            <StyleColumnDetails>
              <StyledFlexColumn>
                <StyledHeading>{main}</StyledHeading>
                <StyledSubHeading>{sub}</StyledSubHeading>
              </StyledFlexColumn>
              <StyledButtonContainer>{viewAudit()}</StyledButtonContainer>
            </StyleColumnDetails>
          </Grid.Column>
        </Grid>
      </StyledDiv>
    </>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  margin: 0 0 ${props => props.theme.pxToRem(24)};
  border-radius: ${props => props.theme.pxToRem(8)};
  background-image: linear-gradient(to left, #72246c, #3a5dae);
  ${props => props.theme.mediaQueries.tabletMin} {
    padding: ${props => props.theme.pxToRem(32)} ${props => props.theme.pxToRem(40)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(25)} ${props => props.theme.pxToRem(25)};
  }
`;

const StyleColumnDetails = styled.div`
  ${props => props.theme.mediaQueries.tabletMin} {
    padding-left: ${props => props.theme.pxToRem(30)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: ${props => props.theme.pxToRem(0)};
  }
`;

const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFlexColumnCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(0)};
  }
`;

const StyledHeading = styled.label`
  margin: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(0)};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.25;
  color: ${props => props.theme.colors.neutralWhite};
`;

const StyledSubHeading = styled.label`
  font-weight: 300;
  font-weight: ${props => props.theme.fontWeights.light};
  margin: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(0)};
  font-size: ${props => props.theme.fontSizes.s};
  ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralWhite};
`;

const StyledButtonContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(8)};
  &&&& {
    .button:not(:first-child) {
      display: none;
    }
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    width: auto;
    height: ${props => props.theme.pxToRem(40)};
    padding: ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(20)};
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    border: 1px solid ${props => props.theme.colors.neutralWhite};
    background: ${props => props.theme.colors.neutralWhite};
    color: ${props => props.theme.colors.primaryPurple};
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: ${props => props.theme.pxToRem(30)};
  }
`;

const StyledCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.secondaryTeal};
  border-radius: 50%;
`;

const StyledCheckHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.pxToRem(5)};
`;

const StyledTextLink = styled(Link)`
  &&&& {
    color: ${props => props.theme.colors.primaryPurple};
    text-decoration: none;
  }
`;

const IconSuccessAuditHolder = styled.img``;
