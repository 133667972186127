import React from 'react';
import styled from 'styled-components';
import { Divider, DropdownProps, DropdownItemProps, TransitionablePortal } from 'semantic-ui-react';
import { Modal } from 'components/atoms/Modal/Modal';
import { DateFilter } from 'components/molecules/DateFilter/DateFilter';
import { FilterNames } from 'constants/index';

interface Props {
  isModalOpen: boolean;
  name: string;
  title: string;
  placeholder: string;
  options: DropdownItemProps[] | undefined;
  toggleDropdownModal: () => void;
  onChange: (e: React.SyntheticEvent, data: DropdownProps) => void;
  // Next props required only for DropdownSearchModal -> DateFilter
  selectedStartDate?: string;
  selectedEndDate?: string;
  eventDateFilterItems?: Common.FilterItemProps[];
  handleDateFilterChange?: (filterName: string, value: string) => void;
}

export const DropdownSearchModal: React.FC<Props> = React.memo(
  ({
    isModalOpen,
    name,
    title,
    placeholder,
    options,
    toggleDropdownModal,
    onChange,
    // Next props required only for DropdownSearchModal -> DateFilter
    selectedStartDate,
    selectedEndDate,
    eventDateFilterItems,
    handleDateFilterChange,
  }) => {
    const isDateFilterDropdown: boolean = name === FilterNames.EVENT_START_DATE;

    const handleItemClick = React.useCallback(
      (e: React.SyntheticEvent) => {
        const value: string = (e.target as any).getAttribute('data-value');
        onChange(e, { name, value });
        if (!isDateFilterDropdown) toggleDropdownModal();
      },
      [name, onChange, toggleDropdownModal, isDateFilterDropdown]
    );

    return (
      <TransitionablePortal open={isModalOpen} transition={{ animation: 'slide left', duration: 300 }}>
        <StyledModal
          testId={`bis-${name}`}
          heading={title}
          open
          oneOption
          isFullScreenOnMobile
          onClose={toggleDropdownModal}
        >
          <b>{placeholder}</b>
          <StyledModalDivider />
          {isDateFilterDropdown ? (
            <DateFilter
              hideTitle
              items={eventDateFilterItems}
              selectedStartDate={selectedStartDate as string}
              selectedEndDate={selectedEndDate as string}
              handleChange={handleDateFilterChange}
            />
          ) : (
            <StyledList>
              {options?.map(option => (
                <li onClick={handleItemClick} key={option.key} data-value={option.value}>
                  {option.text}
                </li>
              ))}
            </StyledList>
          )}
        </StyledModal>
      </TransitionablePortal>
    );
  }
);

const StyledModal = styled(Modal)`
  &&&&&& {
    padding: ${props => `${props.theme.pxToRem(32)} ${props.theme.pxToRem(16)}`};
    border-top: 5px solid ${props => props.theme.colors.primaryPurple};
    overflow-y: scroll; /* enable scroll if content take more then full height */

    button > svg {
      fill: ${props => props.theme.colors.primaryPurple};
    }

    .header > h2 {
      text-align: left;
    }

    .content {
      b {
        font-size: ${props => props.theme.fontSizes.s};
        font-weight: ${props => props.theme.fontWeights.medium};
        line-height: 1.5;
      }
    }
  }
`;

const StyledList = styled.ul`
  padding-left: 0;
  margin: 0;
  list-style: none;

  > li {
    padding: ${props => `${props.theme.pxToRem(18)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(18)}`} 0;
    border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
    margin-left: ${props => props.theme.pxToRem(20)};
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: 1.33;

    :first-child {
      padding-top: ${props => props.theme.pxToRem(3)};
    }

    :last-child {
      border-bottom: 0;
    }
  }
`;

const StyledModalDivider = styled(Divider)`
  &&&&& {
    border-top: 0;
    border-bottom-color: ${props => props.theme.colors.neutralGrey3};
    margin: ${props => props.theme.pxToRem(15)} 0;
  }
`;
