import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Grid, Divider } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { ExitSurveyContainer as ExitSurvey } from 'containers/ExitSurveyContainer';
import { Heading, OnlyMobile, OnlyDesktop, Button, ButtonEnums } from 'components/atoms';
import { TellUsSurveyContainer as TellUsSurvey } from 'components/App/lazy-imports';
import { ICON_ARROW_UP, ICON_ARROW_DOWN } from 'resources/images';
import { handleEvent, PREFIXES, EXTERNAL_LINK_EVENT } from 'utils/Analytics';
import { getSessionStorageItem } from 'utils/sessionStorage';
import { ExtLinks, StorageNames, TopicSlugs } from 'constants/index';

interface Props {
  buttonTestId?: string;
}

export const LegacyBanner: React.FC<Props> = () => {
  const history = useHistory();

  const bannerText = React.useMemo(
    () => ({
      EXPLORING: `AICPA Coronavirus Resource Center`,
      FEEDBACK: 'Update on the Coronavirus and impact for Association activities.',
      PREVIOUS_BUTTON: 'Learn More',
    }),
    []
  );

  const [showBanner] = useState(() => {
    if (getSessionStorageItem(StorageNames.showLegacyBanner)) {
      return getSessionStorageItem(StorageNames.showLegacyBanner) === 'true';
    }
    return true;
  });

  const [showTellUsSurvey, setTellUsSurveyVisibility] = React.useState<boolean>(false);
  const [showExitSurvey, setExitSurveyVisibility] = React.useState<boolean>(false);

  const ButtonCtaLegacy: React.FC<Props> = ({ buttonTestId }) => {
    return (
      <StyledButton
        testId={`${buttonTestId}-cta-legacy`}
        onClick={navigateToCorona}
        variant={ButtonEnums.variants.secondaryNegative}
        size={ButtonEnums.sizes.medium}
      >
        {bannerText.PREVIOUS_BUTTON}
      </StyledButton>
    );
  };

  const navigateToLegacy = React.useCallback(() => {
    window.location.href = ExtLinks.LEGACY;
    handleEvent(
      {
        text: `${PREFIXES.HEADER}:aicpa.org:${bannerText.PREVIOUS_BUTTON}`,
        href: ExtLinks.LEGACY,
      },
      EXTERNAL_LINK_EVENT
    );
  }, [bannerText.PREVIOUS_BUTTON]);

  const navigateToCorona = () => {
    history.push(TopicSlugs.COVID);
  };

  const handleTellUsSurveyClose = React.useCallback((): void => {
    setTellUsSurveyVisibility(false);
  }, []);

  // Component will unmount effect
  React.useEffect(() => {
    window.onpopstate = () => {
      if (showExitSurvey) {
        setExitSurveyVisibility(false);
      }

      if (showTellUsSurvey) {
        setTellUsSurveyVisibility(false);
      }
    };
  }, [showExitSurvey, showTellUsSurvey]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!showBanner) return null;
  return (
    <Banner data-testid="legacy-banner">
      <Container>
        <StyledGridContainer columns={16} container>
          <Grid.Column computer={14} mobile={14}>
            <StyledInnerGridContainer>
              <Grid.Column computer={11} mobile={14}>
                <HiddenInput id="toggle" type="checkbox" />
                <Title as="h2">
                  <Label htmlFor="toggle">{bannerText.EXPLORING}</Label>
                </Title>
                <ExpandableContent id="expand">
                  <OnlyMobile>
                    <Divider />
                  </OnlyMobile>
                  <Text>{bannerText.FEEDBACK}&nbsp;</Text>
                  <OnlyMobile>
                    <ButtonCtaLegacy buttonTestId="mobile" />
                  </OnlyMobile>
                </ExpandableContent>
              </Grid.Column>
            </StyledInnerGridContainer>
          </Grid.Column>
          <OnlyDesktop>
            <Grid.Column>
              <ButtonCtaLegacy buttonTestId="desktop" />
            </Grid.Column>
          </OnlyDesktop>
        </StyledGridContainer>
      </Container>
      <ExitSurvey show={showExitSurvey} navigateToLegacy={navigateToLegacy} />
      <TellUsSurvey show={showTellUsSurvey} onClose={handleTellUsSurveyClose} />
    </Banner>
  );
};

const Banner = styled(Container)`
  &&& {
    width: 100vw;
    padding: ${props => props.theme.pxToRem(28)} 0;
    background: ${props =>
      `linear-gradient(77deg, ${props.theme.colors.secondaryDarkBlue}, ${props.theme.colors.interfaceBlue})`};
    color: ${props => props.theme.colors.neutralWhite};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

const Title = styled(Heading)`
  margin-top: 0;
  margin-bottom: ${props => props.theme.pxToRem(4)};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const Text = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    margin: ${props => props.theme.pxToRem(20)} 0;
  }

  &&&&:hover {
    background-color: ${props => props.theme.colors.neutralWhite};
    color: ${props => props.theme.colors.secondaryDarkBlue};
    border-color: ${props => props.theme.colors.secondaryDarkBlue};
  }
`;

const StyledGridContainer = styled(Grid)`
  flex-wrap: nowrap;
`;

const StyledInnerGridContainer = styled(Grid)`
  &&& {
    align-items: center;
  }
`;

const HiddenInput = styled.input`
  display: none;
  visibility: hidden;

  ${props => props.theme.mediaQueries.mobileOnly} {
    &#toggle:checked ~ #expand {
      height: auto;
      overflow: visible;
    }

    &#toggle:checked ~ h2 > label {
      &::before {
        background-image: url(${ICON_ARROW_UP});
      }
    }
  }
`;

const Label = styled.label`
  ${props => props.theme.mediaQueries.mobileOnly} {
    &::before {
      position: absolute;
      left: ${props => props.theme.pxToRem(-15)};
      content: '';
      width: ${props => props.theme.pxToRem(18)};
      height: ${props => props.theme.pxToRem(15)};
      background-image: url(${ICON_ARROW_DOWN});
      filter: invert(100%);
      transition: transform 0.3s ease-in-out;
    }
  }
`;

const ExpandableContent = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow: hidden;
    height: 0;
  }
`;
