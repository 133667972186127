import { createAction } from 'redux-actions';

// ------------------------------------
// Actions
// ------------------------------------
export const getSkillsHash: any = createAction('skills/GET_SKILLS_HASH', (skills: State.Skill) => () => {
  return skills;
});

// export const toggleSubskill: any = createAction('topics/TOGGLE_SUBSKILL');
// TODO - SUBTOPIC EDITING
