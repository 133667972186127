// Text encoding needed for OKTA SDK;
import 'fast-text-encoding';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { ConnectedRouter } from 'connected-react-router';
import { Frontload } from 'react-frontload';
import { AppWrapperContainer as App } from './containers/AppWrapperContainer';
import { configureStore } from './store';
import 'semantic-ui-css/semantic.min.css';
import { ThemeProvider } from 'theme/ThemeProvider';

const preloadedState = (window as any).__PRELOADED_STATE__;
export const { store, history } = configureStore('/', preloadedState);

window.onload = () => {
  Loadable.preloadReady()
    .then(() =>
      ReactDOM.hydrate(
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ThemeProvider>
              <Frontload>
                <App />
              </Frontload>
            </ThemeProvider>
          </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
      )
    )
    .catch(console.error);
};
