import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { PageOrganizationNavigation } from 'components/pages/PageOrganizationNavigation/PageOrganizationNavigation';
import { getOrganizations } from 'modules/clientAdmin/index';
import { clientOrganizationsListSelector, clientOrganizationsIsFetchedSelector } from 'modules/clientAdmin/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  getOrganizations(): void {
    dispatch(getOrganizations()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
});

const mapStateToProps = (state: State.Root) => ({
  organizations: clientOrganizationsListSelector(state),
  organizationsIsFetched: clientOrganizationsIsFetchedSelector(state),
});

export const PageOrganizationNavigationContainer = connect(
  mapStateToProps,
  mapActionCreators
)(PageOrganizationNavigation);
