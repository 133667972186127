import { createAction } from 'redux-actions';
import { QUERY_EXEMPTION_ENGINE } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';

export const fetchExemptionEngine: any = createAction(
  'membership/FETCH_EXEMPTION_ENGINE',
  (id: string, learningPathway: string, isAicpaRegular: boolean, flpSwitchUpgrade: boolean, entrypointLevel?: string) =>
    async () => {
      return request(QUERY_EXEMPTION_ENGINE, {
        id,
        learningPathway,
        isAicpaRegular,
        flpSwitchUpgrade,
        entrypointLevel,
      });
    }
);

export const setSelectedExemption: any = createAction(
  'exemption/SET_SELECTED_EXEMPTION',
  (exempt: string): string => exempt
);

export const setSelectedExemptReferenceLevel: any = createAction(
  'exemption/SET_SELECTED_EXEMPT_REFERENCE_LEVEL',
  (selectedExemptReferenceLevel: string): string => selectedExemptReferenceLevel
);

export const setPrevSelectedExemptReference: any = createAction(
  'exemption/SET_PREV_SELECTED_EXEMPT_REFERENCE',
  (setPrevSelectedExemptReferenceLevel: string): string => setPrevSelectedExemptReferenceLevel
);
