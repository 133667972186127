import { Salesforce } from 'mxp-schemas';
import * as Sentry from '@sentry/browser';
import { handleActions, Action } from 'redux-actions';
import {
  getOrganizations,
  getOrganizationAdminsByRole,
  getCenterMemberships,
  getAllCenterMemberships,
  clearAllCenterMemberships,
  setCenterMembershipsFilters,
  getOrganizationMemberships,
  setIsLoading,
  setDataAction,
  setModifiedHashAction,
  setPaginationAction,
  getCenterMembershipInvoices,
  fetchContractLineItems,
  setContractLineItemModifiers,
  setInvoiceDownloadingId,
  getInvoiceFile,
  setSelectedInvoice,
  getHostedPageSignature,
  payInvoice,
  getInvoicePaymentConfirmationResult,
  provisionSeat,
  setProvisioningLoading,
  setProvisioningError,
  getServiceContracts,
  removeContractLineItems,
} from 'modules/centerAdmin/actions';
import { initialState } from 'modules/centerAdmin/constants';
import { CenterAdminTableType } from 'constants/center-admin';
import { setCenterMembershipList } from 'modules/admin/actions';

if ([getOrganizations].includes(undefined)) {
  const message: string = 'centerAdmin module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [getOrganizations]: (
    state: CenterAdmin.Root,
    action: Action<{ organizations: CenterAdmin.Organization[] }>
  ): CenterAdmin.Root => ({
    ...state,
    organizations: {
      ...state.organizations,
      list: action.payload.organizations,
      isOrganizationsFetched: true,
    },
  }),
  [getOrganizationAdminsByRole]: (
    state: CenterAdmin.Root,
    action: Action<{ organizationAdmins: CenterAdmin.Contact[][] }>
  ): CenterAdmin.Root => ({
    ...state,
    organizationAdmins: action.payload.organizationAdmins,
  }),
  [getCenterMemberships]: (
    state: CenterAdmin.Root,
    action: Action<{ centerMemberships: CenterAdmin.FirmMembership[] }>
  ): CenterAdmin.Root => ({
    ...state,
    centerMemberships: {
      ...state.centerMemberships,
      list: action.payload.centerMemberships,
      isFetched: true,
    },
  }),
  [getAllCenterMemberships]: (
    state: CenterAdmin.Root,
    action: Action<{ centerMemberships: CenterAdmin.FirmMembership[] }>
  ): CenterAdmin.Root => ({
    ...state,
    centerMemberships: {
      ...state.centerMemberships,
      list: action.payload.centerMemberships,
      isFetched: true,
    },
  }),
  [clearAllCenterMemberships]: (state: CenterAdmin.Root): CenterAdmin.Root => ({
    ...state,
    centerMemberships: {
      ...state.centerMemberships,
      list: [],
      isFetched: false,
    },
  }),
  [setCenterMembershipsFilters]: (
    state: CenterAdmin.Root,
    action: Action<CenterAdmin.CenterMembershipsFilters>
  ): CenterAdmin.Root => ({
    ...state,
    centerMemberships: {
      ...state.centerMemberships,
      filters: {
        ...state.centerMemberships.filters,
        membershipStatus: action.payload.membershipStatus,
      },
    },
  }),
  [getOrganizationMemberships]: (
    state: CenterAdmin.Root,
    action: Action<Salesforce.FirmMembership[]>
  ): CenterAdmin.Root => ({
    ...state,
    organizationMemberships: action.payload,
  }),
  [setDataAction]: (
    state: CenterAdmin.Root,
    action: Action<{
      type: CenterAdmin.CenterAdminTableType;
      modifier: Partial<CenterAdmin.Data<CenterAdmin.CenterMembershipInvoice>>;
    }>
  ): CenterAdmin.Root => ({
    ...state,
    data: {
      ...state.data,
      [action.payload.type]: {
        ...state.data[action.payload.type],
        ...action.payload.modifier,
        isFetched: true,
        isLoading: false,
      },
    },
  }),
  [setIsLoading]: (state: CenterAdmin.Root, action: Action<CenterAdmin.CenterAdminTableType>): CenterAdmin.Root => {
    const type = action.payload;
    const stateData = state.data[type];
    return {
      ...state,
      data: {
        ...state.data,
        [type]: {
          ...stateData,
          isLoading: true,
          hash: {},
        },
      },
    };
  },
  [getCenterMembershipInvoices]: (
    state: CenterAdmin.Root,
    action: Action<{
      type: CenterAdmin.CenterAdminTableType;
      modifier: Partial<CenterAdmin.Data<CenterAdmin.CenterMembershipInvoice>>;
    }>
  ): CenterAdmin.Root => {
    const { type, modifier } = action.payload;
    const statePagination = state.pagination[type];
    return {
      ...state,
      data: {
        ...state.data,
        [type]: {
          ...state.data[type],
          ...modifier,
          isFetched: true,
          isLoading: false,
          isDataLoaded: true,
        },
      },
      pagination: {
        ...state.pagination,
        [type]: {
          ...statePagination,
          filters: {
            ...statePagination.filters,
            isPaidInvoiceFetchedData: statePagination.filters.isPaidInvoice,
          },
        },
      },
    };
  },
  [setPaginationAction]: (
    state: CenterAdmin.Root,
    action: Action<{
      type: CenterAdmin.CenterAdminTableType;
      modifier: Partial<CenterAdmin.Pagination>;
    }>
  ): CenterAdmin.Root => {
    const isCenterInvoicesTable = action.payload.type === CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES;
    const isOffsetPropertyExist = action.payload.modifier.hasOwnProperty('offset');
    const { selectedIds, modifiedHash } = state.data[action.payload.type];
    return {
      ...state,
      data:
        // FIRM_BILLING_INVOICES table uses client side search
        isCenterInvoicesTable
          ? state.data
          : {
              ...state.data,
              [action.payload.type]: {
                ...state.data[action.payload.type],
                selectedIds: isOffsetPropertyExist ? selectedIds : [],
                modifiedHash: isOffsetPropertyExist ? modifiedHash : {},
                isLoading: true,
                isFetched: false,
              },
            },
      pagination: {
        ...state.pagination,
        [action.payload.type]: {
          ...state.pagination[action.payload.type],
          ...action.payload.modifier,
          offset: isOffsetPropertyExist ? action.payload.modifier.offset : 0,
        },
      },
    };
  },
  [getServiceContracts]: (
    state: CenterAdmin.Root,
    action: Action<CenterAdmin.ServiceContract[]>
  ): CenterAdmin.Root => ({
    ...state,
    seatManagement: {
      ...state.seatManagement,
      serviceContractsList: action.payload,
    },
  }),
  [fetchContractLineItems]: (state: CenterAdmin.Root, action: Action<string>): CenterAdmin.Root => {
    return {
      ...state,
      seatManagement: {
        ...state.seatManagement,
        contractLineItemList:
          state.seatManagement.serviceContractsList.find(c => c.orderProductId === action.payload)?.contractLineItems ??
          [],
        contractLineItemIsFetched: true,
      },
    };
  },
  [setContractLineItemModifiers]: (
    state: CenterAdmin.Root,
    action: Action<CenterAdmin.ContractLineItemModifiers>
  ): CenterAdmin.Root => {
    const { query, offset, limit } = action.payload;
    return {
      ...state,
      seatManagement: {
        ...state.seatManagement,
        contractLineItemModifiers: {
          ...state.seatManagement.contractLineItemModifiers,
          query,
          ...(Boolean(offset) && { offset }),
          ...(Boolean(limit) && { limit }),
        },
      },
    };
  },
  [removeContractLineItems]: (state: CenterAdmin.Root, action: Action<string>): CenterAdmin.Root => {
    return {
      ...state,
      seatManagement: {
        ...state.seatManagement,
        contractLineItemList: state.seatManagement.contractLineItemList.filter(
          i => !action.payload.includes(i.contractLineItemId)
        ),
      },
    };
  },
  [setInvoiceDownloadingId]: (state: CenterAdmin.Root, action: any): CenterAdmin.Root => {
    return {
      ...state,
      data: {
        ...state.data,
        [CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES]: {
          ...state.data[CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES],
          downloadingId: action.payload,
        },
      },
    };
  },
  [getInvoiceFile]: (state: CenterAdmin.Root): CenterAdmin.Root => {
    return {
      ...state,
      data: {
        ...state.data,
        [CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES]: {
          ...state.data[CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES],
          downloadingId: null,
          downloadingError: false,
        },
      },
    };
  },
  [setSelectedInvoice]: (
    state: CenterAdmin.Root,
    action: Action<{ invoiceId: string; type: CenterAdmin.CenterAdminTableType }>
  ): CenterAdmin.Root => {
    return {
      ...state,
      selectedInvoice: state.data[action.payload.type].hash[action.payload.invoiceId],
    };
  },
  [getInvoicePaymentConfirmationResult]: (
    state: CenterAdmin.Root,
    action: Action<CenterAdmin.InvoicePaymentConfirmation>
  ): CenterAdmin.Root => ({
    ...state,
    paymentResult: action.payload,
  }),
  [payInvoice]: (
    state: CenterAdmin.Root,
    action: Action<CenterAdmin.InvoicePaymentConfirmation>
  ): CenterAdmin.Root => ({
    ...state,
    paymentResult: action.payload,
  }),
  [provisionSeat]: {
    next: (state: CenterAdmin.Root): CenterAdmin.Root => ({
      ...state,
      provisioning: {
        ...state.provisioning,
        loading: false,
        errors: null,
      },
    }),
    throw: (
      state: CenterAdmin.Root,
      { payload: errors }: { payload: CustomErrors.GraphQLError }
    ): CenterAdmin.Root => ({
      ...state,
      provisioning: {
        ...state.provisioning,
        loading: false,
        errors,
      },
    }),
  },
  [setProvisioningLoading]: (state: CenterAdmin.Root, action: { payload: boolean }): CenterAdmin.Root => ({
    ...state,
    provisioning: {
      ...state.provisioning,
      loading: action.payload,
      errors: null,
    },
  }),
  [setProvisioningError]: (
    state: CenterAdmin.Root,
    action: { payload: CustomErrors.GraphQLError }
  ): CenterAdmin.Root => ({
    ...state,
    provisioning: {
      ...state.provisioning,
      loading: false,
      errors: action.payload,
    },
  }),
  [setCenterMembershipList]: (
    state: CenterAdmin.Root,
    action: Action<{ data: CenterAdmin.FirmMembership[] }>
  ): CenterAdmin.Root => ({
    ...state,
    centerMemberships: {
      ...state.centerMemberships,
      list: action.payload.data,
    },
  }),
};

export {
  getOrganizations,
  getOrganizationAdminsByRole,
  getCenterMemberships,
  getAllCenterMemberships,
  clearAllCenterMemberships,
  setDataAction,
  setModifiedHashAction,
  setPaginationAction,
  getCenterMembershipInvoices,
  fetchContractLineItems,
  setContractLineItemModifiers,
  getInvoiceFile,
  setSelectedInvoice,
  getHostedPageSignature,
  payInvoice,
  provisionSeat,
  setProvisioningLoading,
  setProvisioningError,
  getServiceContracts,
  removeContractLineItems,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
