import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as ContentPremium } from 'resources/images/icon-dev-ic-content-premium.svg';
import { ReactComponent as ContentMember } from 'resources/images/icon-dev-ic-content-member.svg';
import { ReactComponent as ContentRegistered } from 'resources/images/icon-dev-ic-content-reg.svg';

interface Props {
  isContentExclusive: Common.ContentExclusive;
}

export const ExclusiveTag: React.FC<Props> = ({ isContentExclusive }) => {
  if (
    !isContentExclusive.isFirmMemberExclusive &&
    !isContentExclusive.isMemberExclusive &&
    !isContentExclusive.isSectionExclusive &&
    !isContentExclusive.isCredentialExclusive &&
    !isContentExclusive.isSectionAndCredentialExclusive &&
    !isContentExclusive.isRegisteredExclusive
  ) {
    return null;
  }

  return (
    <>
      <StyledTag tabIndex={0} data-testid="content-premium-tag">
        {isContentExclusive.isMemberExclusive ? (
          <StyledContentMember />
        ) : isContentExclusive.isRegisteredExclusive ? (
          <StyledContentRegistered />
        ) : (
          <StyledContentPremium />
        )}
        <StyledBoldText>
          {isContentExclusive.isMemberExclusive
            ? 'AICPA MEMBER '
            : isContentExclusive.isFirmMemberExclusive
            ? 'FIRM MEMBER '
            : isContentExclusive.isSectionExclusive
            ? 'SECTION '
            : isContentExclusive.isCredentialExclusive
            ? 'CREDENTIAL '
            : isContentExclusive.isSectionAndCredentialExclusive
            ? 'SECTION + CREDENTIAL '
            : 'FREE ACCOUNT'}
        </StyledBoldText>
        <StyledText>
          {isContentExclusive.isMemberExclusive || isContentExclusive.isRegisteredExclusive ? 'ACCESS' : ' EXCLUSIVE'}
        </StyledText>
      </StyledTag>
    </>
  );
};

const StyledTag = styled.div`
  ${props => `
    font-size: ${props.theme.fontSizes.xxs};
    padding: ${props.theme.pxToRem(5)};
    display: inline-flex;
    color: ${props.theme.colors.neutralBlack};
    backdrop-filter: blur(2px);
    opacity: 0.95;
    border-radius: ${props.theme.pxToRem(1)};
    background-color: ${props.theme.colors.neutralGrey1};
    margin: ${props.theme.pxToRem(12)} 0 ${props.theme.pxToRem(3)};
`}
`;

const iconStyling = css`
  ${props => `
    width: ${props.theme.pxToRem(14)};
    height: ${props.theme.pxToRem(14)};
    path {
      fill: ${props.theme.colors.primaryPurple};
    }
    &&& {
      margin-right: ${props.theme.pxToRem(5)};
    }
  `}
`;

const StyledContentPremium = styled(ContentPremium)`
  ${iconStyling}
`;

const StyledContentMember = styled(ContentMember)`
  ${iconStyling}
`;

const StyledContentRegistered = styled(ContentRegistered)`
  ${iconStyling}
`;

const StyledBoldText = styled.p`
  font-weight: ${props => props.theme.fontWeights.medium};
  margin: 0;
  ${props => props.theme.mediaQueries.ieOnly} {
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;
const StyledText = styled.p`
  padding-left: ${props => props.theme.pxToRem(3)};
`;
