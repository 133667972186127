import React from 'react';
import styled from 'styled-components/macro';
import { UserMemberTypes } from 'modules/user/constants';
import { zeroToLabel, formatPriceToTwoDecimalPoints } from 'utils';
import { Product } from 'mxp-schemas';

interface Props {
  prices: Product.ProductCardPrice;
  isContribution?: boolean;
  currencyLabel?: Product.ProductCurrencyLabel;
  currencySign?: Product.ProductCurrencySign;
  isUserSuspendedByEthics?: boolean;
}

export const CardPrice: React.FC<Props> = React.memo(
  ({
    prices,
    isContribution = false,
    currencyLabel = Product.ProductCurrencyLabel.USD,
    currencySign = Product.ProductCurrencySign.DOLLAR,
    isUserSuspendedByEthics,
  }) => {
    const isIdenticalPrice = prices.startPrice === prices.endPrice;
    const isZeroEndPrice = prices.endPrice === 0 && !isUserSuspendedByEthics;
    const isZeroStartPrice = prices.startPrice === 0;
    const missingStartPrice = !prices.startPrice && !isZeroStartPrice;
    const missingEndPrice = !prices.endPrice && !isZeroEndPrice;
    const zeroStartPrice = zeroToLabel(prices.startPrice as number);
    const zeroEndPrice = zeroToLabel(prices.endPrice as number);
    const startPrice = formatPriceToTwoDecimalPoints(prices.startPrice || 0, currencyLabel);
    const endPrice = formatPriceToTwoDecimalPoints(prices.endPrice || 0, currencyLabel);
    const membership = prices.membership;
    const isLoggedOutUser = membership === UserMemberTypes.LOGGED_OUT;
    const isNonMemberUser = membership === UserMemberTypes.NONMEMBER;
    const isMemberUser = !isLoggedOutUser && !isNonMemberUser;

    // fail safely
    if (missingStartPrice && missingEndPrice) return <></>;
    if (isContribution) {
      return (
        <>
          <StyledFinalPrice tabIndex={0}>Minimum contribution: {currencySign}1.00</StyledFinalPrice>
        </>
      );
    }
    if (missingStartPrice) {
      return <>{isZeroEndPrice ? <StyledFreePriceTeal tabIndex={0}>{zeroEndPrice}</StyledFreePriceTeal> : endPrice}</>;
    }
    if (missingEndPrice) {
      return (
        <>{isZeroStartPrice ? <StyledFreePriceTeal tabIndex={0}>{zeroStartPrice}</StyledFreePriceTeal> : startPrice}</>
      );
    }

    // identical prices
    if (isIdenticalPrice) {
      return (
        <>{isZeroEndPrice ? <StyledFreePriceTeal tabIndex={0}>{zeroStartPrice}</StyledFreePriceTeal> : startPrice}</>
      );
    }
    return (
      <>
        {isLoggedOutUser && (
          <>
            {startPrice}&nbsp;-&nbsp;
            {endPrice}
          </>
        )}
        {isNonMemberUser && (
          <>
            <StyledFinalPrice tabIndex={0} isNonMemberUser={isNonMemberUser}>
              From {isZeroStartPrice ? zeroStartPrice : startPrice}
            </StyledFinalPrice>
            &nbsp;{`(${endPrice} for members)`}
          </>
        )}
        {isMemberUser && isUserSuspendedByEthics && (
          <>
            <StyledFinalPrice tabIndex={0}>
              &nbsp;From {isZeroEndPrice ? <StyledFreePriceTeal>{zeroEndPrice}</StyledFreePriceTeal> : startPrice}
            </StyledFinalPrice>
            <div>&nbsp;{`(${endPrice} for members)`}</div>
          </>
        )}
        {isMemberUser && !isUserSuspendedByEthics && (
          <>
            <s>{`${startPrice}`}</s>
            <StyledFinalPrice tabIndex={0}>
              &nbsp;From {isZeroEndPrice ? <StyledFreePriceTeal>{zeroEndPrice}</StyledFreePriceTeal> : endPrice}
            </StyledFinalPrice>
            &nbsp;{`(${membership} price)`}
          </>
        )}
      </>
    );
  }
);

const StyledFreePriceTeal = styled.span`
  color: ${props => props.theme.colors.secondaryTeal};
`;

const StyledFinalPrice = styled.span<{ isNonMemberUser?: boolean }>`
  ${props =>
    props.isNonMemberUser &&
    `
      display: block;
  `}
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
`;
