import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import request from 'utils/GraphQLClient';
import { QUERY_PERSON_LEVEL_EXEMPTIONS } from 'mxp-graphql-queries';
import { personAccountIdSelector } from 'modules/user/selectors';
import { membershipActionNames } from 'modules/membership/constants';

export const getPersonLevelExemptions: any = createAction(
  membershipActionNames.GET_PERSON_LEVEL_EXEMPTIONS,
  async () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const accountId = personAccountIdSelector(state);
    return request(QUERY_PERSON_LEVEL_EXEMPTIONS, { accountId }).then((response: any) => {
      return response?.getPersonLevelExemptions;
    });
  }
);
