import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { productTypeToSlug, getPath, getMembershipPackagePath } from 'utils';
import { Product as ProductTypes } from 'mxp-schemas';
import { AccordionPanelProps, Grid, Label } from 'semantic-ui-react';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import {
  Button,
  ButtonEnums,
  FeedPlaceholder,
  NotificationBannerEnums,
  NotificationBanner,
  Link,
} from 'components/atoms';
import { MembershipPackagePanelWrapper, MembershipCard } from 'components/organisms';
import {
  addMembershipPackageSectionFreeProduct,
  addMembershipPackageSectionWithPriceProduct,
  removeMembershipPackageSectionFreeProduct,
  removeMembershipPackageSectionWithPriceProduct,
  fetchMembershipSections,
} from 'modules/membership';
import {
  membershipSectionRootSelector,
  membershipSectionSelector,
  currentMembershipProduct,
  userMembershipSectionProductWithPriceSelector,
  userMembershipSectionFreeProductSelector,
  isRenewalSelector,
  existingSectionSelector,
  sectionsCredentialsRenewalSelector,
  membershipEventSelector,
  isRenewalSeasonSelector,
  clickedSectionRenewalSelector,
  userMembershipPackageSelector,
  tiersWithUserApplicablePricingSelector,
} from 'modules/membership/selectors';
import { productsListDataFetchedSelector } from 'modules/products/selectors';
import { getProductsListData } from 'modules/products/actions';

import { useDispatch, useSelector } from 'react-redux';
import { MembershipPackageAccordionStatus, Routes } from 'constants/index';
import { useHistory } from 'react-router';

import { customerSectionsSelector, isUserSuspendedSelector } from 'modules/user/selectors';
import moment from 'moment-timezone';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';
import { handleEvent } from 'utils/Analytics';
import { membershipActionNames } from 'modules/membership/constants';
import { IC_INFO } from 'resources/images';
interface MembershipPackagePanelProps extends AccordionPanelProps {
  handleClick: () => void;
  index?: number;
  shouldShowNewPackageBuilder?: boolean;
}

export const MembershipSectionPanel: React.FC<MembershipPackagePanelProps> = ({
  handleClick,
  index,
  shouldShowNewPackageBuilder,
}) => {
  const ALL = 'All';
  const [selectedCategory, setSelectedCategory] = useState(ALL);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSectionsLoading, setIsSectionsLoading] = useState(false);
  const [isAddExistingSectionsLoaded, setIsAddExistingSectionsLoaded] = useState(false);
  const [isMembershipSectionEventTriggered, setIsMembershipSectionEventTriggered] = useState(false);
  const { useSuspendedRestriction } = useSelector(featureTogglesSelector);
  const dispatch = useDispatch();
  const isUserSuspendedByEthics = useSelector(isUserSuspendedSelector);
  const isEthicsSuspended: boolean = Boolean(useSuspendedRestriction && isUserSuspendedByEthics);
  const { benefitQuantity, categories, listOfFreeProduct, listOfProductWithPrice } =
    useSelector(membershipSectionSelector);

  const userChoicesSectionProductWithPrice = useSelector(userMembershipSectionProductWithPriceSelector);
  const userChoicesSectionProductFreePrice = useSelector(userMembershipSectionFreeProductSelector);
  const isRenewal = useSelector(isRenewalSelector);
  const existingSection = useSelector(existingSectionSelector);
  const current = useSelector(currentMembershipProduct);
  const membershipEvent = useSelector(membershipEventSelector);
  const membershipSections = useSelector(membershipSectionRootSelector);

  const sectionsCredentialsRenewal = useSelector(sectionsCredentialsRenewalSelector);
  const productsListDataFetched = useSelector(productsListDataFetchedSelector);
  const isClickedSectionRenewal = useSelector(clickedSectionRenewalSelector);

  const isRenewalSeason = useSelector(isRenewalSeasonSelector);

  const userChoice = useSelector(userMembershipPackageSelector);

  const { list } = useSelector(tiersWithUserApplicablePricingSelector);
  const freeProductCount = useMemo(() => {
    return userChoicesSectionProductFreePrice.length;
  }, [userChoicesSectionProductFreePrice]);

  const history = useHistory();

  const productsFreeProduct = useMemo(() => {
    return listOfFreeProduct.map(product => {
      const selected = userChoicesSectionProductFreePrice.some(choice => choice.productId === product.productId);

      return {
        ...product,
        selected,
        enabled: freeProductCount < benefitQuantity || selected,
      };
    });
  }, [benefitQuantity, freeProductCount, listOfFreeProduct, userChoicesSectionProductFreePrice]);

  const NUM_PER_PAGE = 6;
  const filteredProducts = useMemo(() => {
    return listOfProductWithPrice.filter(product =>
      selectedCategory === ALL ? true : product?.categories?.some(c => c.name === selectedCategory)
    );
  }, [listOfProductWithPrice, selectedCategory]);

  const [renewedSections, setRenewedSections]: any = useState([]);

  const checkIfNotAlreadyRenewed = useCallback(
    (date: string) => {
      const currentYear = moment().year();
      const nextYear = moment().add(1, 'year').year();
      if (membershipEvent.isClickedCredentialsSectionsRenewal || membershipEvent.isClickedMembershipRenewal) {
        return moment(date).year() <= nextYear;
      }
      const isSameYear = moment(date).year() === currentYear;

      // If from prop page and first time purchase section
      if (membershipEvent.isClickedSectionsJourney && isSameYear) {
        return false;
      }

      return isSameYear;
    },
    [membershipEvent]
  );

  const customerSections = useSelector(customerSectionsSelector);

  // pre-select existing sections
  useEffect(() => {
    const preSelectedSections =
      membershipSections?.listOfProductWithPrice.length &&
      membershipSections?.listOfProductWithPrice.filter(section => {
        const found = userChoice?.sectionProductWithPrice.find(
          userSelectedSection => userSelectedSection.productId === section.productId
        );
        return Boolean(found);
      });

    setRenewedSections(preSelectedSections);

    if (!!existingSection.length && !isAddExistingSectionsLoaded && membershipSections?.listOfProductWithPrice.length) {
      let renewedSect: any = [];

      setIsAddExistingSectionsLoaded(true);
      existingSection.forEach(
        (section: {
          productId: string;
          variant: { sku: string };
          zuoraTermEndDate: string;
          sectionTerms: any[];
          subscriptionStatus: string;
        }) => {
          const ifNotLapsed = customerSections.some((sect: any) => sect.sku === section.variant.sku);
          const sectionMatchedProduct = membershipSections.listOfProductWithPrice.find(
            item => item.variants[0].sku === section.variant.sku
          );
          if (ifNotLapsed && isRenewal) {
            // if the section has more than one term record it means it has done renewal before
            const isSectionHasMultipleTerms = section?.sectionTerms ? section?.sectionTerms.length > 1 : false;
            if (
              (section?.zuoraTermEndDate && checkIfNotAlreadyRenewed(section.zuoraTermEndDate)) || // if expired or suspended via date
              (isRenewalSeason && !isSectionHasMultipleTerms && membershipEvent.isClickedCredentialsSectionsRenewal) // or if renewal season and doesn't have a history of renewal
            ) {
              dispatch(
                addMembershipPackageSectionWithPriceProduct(
                  sectionMatchedProduct?.productId || section.productId,
                  section.variant.sku
                )
              );
            } else {
              if (
                (!renewedSect.some((renewSect: any) => renewSect.productId === section.productId) ||
                  !membershipEvent.isClickedCredentialsSectionsRenewal) &&
                section.subscriptionStatus
              ) {
                dispatch(removeMembershipPackageSectionWithPriceProduct(section.variant.sku));
                renewedSect = [...renewedSect, section];
              }
            }
          }
        }
      );
      setRenewedSections(renewedSect);
    }
  }, [
    dispatch,
    checkIfNotAlreadyRenewed,
    existingSection,
    isAddExistingSectionsLoaded,
    membershipEvent,
    customerSections,
    isRenewal,
    isRenewalSeason,
    membershipSections,
    userChoice,
  ]);

  const hasSelectedAllFreeProducts = freeProductCount === benefitQuantity;

  const productsWithPrice = useMemo(() => {
    const data = filteredProducts.slice(currentPage, currentPage + NUM_PER_PAGE).map(product => ({
      ...product,
      selected: userChoicesSectionProductWithPrice.some(choice => choice.sku === product.variants[0].sku),
      enabled:
        !userChoicesSectionProductFreePrice.some(choice => choice.productId === product.productId) &&
        hasSelectedAllFreeProducts,
    }));

    if (isClickedSectionRenewal) {
      return data?.filter(item => {
        const sectionFound = customerSections.some(section => section.sku === item.variants?.[0]?.sku);
        // only display active sections.
        if (sectionFound) {
          return item;
        }
      });
    }
    return data;
  }, [
    hasSelectedAllFreeProducts,
    userChoicesSectionProductFreePrice,
    currentPage,
    filteredProducts,
    userChoicesSectionProductWithPrice,
    isClickedSectionRenewal,
    customerSections,
  ]);

  const productCategoryCount = categories.length;

  const selectedTierName = useMemo(() => {
    return list.find(listItem => listItem.sku === userChoice.tier)?.name;
  }, [list, userChoice.tier]);

  useEffect(() => {
    (async () => {
      setIsSectionsLoading(true);
      await dispatch(getProductsListData());
      setIsSectionsLoading(false);
    })();
  }, [dispatch, sectionsCredentialsRenewal]);

  useEffect(() => {
    dispatch(fetchMembershipSections());
  }, [dispatch, productsListDataFetched]);

  useEffect(() => {
    if (productsWithPrice.length && !isMembershipSectionEventTriggered) {
      handleEvent(productsWithPrice, membershipActionNames.STOREFRONT_AGGREGATION_VIEW_SECTION);
      setIsMembershipSectionEventTriggered(true);
    }
  }, [productsWithPrice, isMembershipSectionEventTriggered]);

  const handleSectionWithPriceButtonClick = useCallback(
    (productId: string, sku: string, selected: boolean) => {
      selected
        ? dispatch(removeMembershipPackageSectionWithPriceProduct(sku))
        : dispatch(addMembershipPackageSectionWithPriceProduct(productId, sku));
    },
    [dispatch]
  );

  const handleMembershipSectionFreeProductButtonClick = useCallback(
    (args: {
      productId: string;
      sku: string;
      group: string;
      selected: boolean;
      groupSku: string;
      benefitName: string;
    }) => {
      const { selected, productId } = args;
      if (freeProductCount >= benefitQuantity) {
        if (selected) {
          dispatch(removeMembershipPackageSectionFreeProduct(productId));
        }
      } else {
        selected
          ? dispatch(removeMembershipPackageSectionFreeProduct(productId))
          : dispatch(addMembershipPackageSectionFreeProduct(args));
      }
    },
    [dispatch, freeProductCount, benefitQuantity]
  );

  const filterByCategory = useCallback(
    (categoryName: string) => {
      if (productCategoryCount >= 2) {
        setSelectedCategory(categoryName);
        setCurrentPage(0);
      }
    },
    [productCategoryCount]
  );

  const hasNextPage = useMemo(() => {
    return filteredProducts.length > NUM_PER_PAGE;
  }, [filteredProducts]);

  const handleSeeMore = useCallback(() => {
    if (filteredProducts.length > currentPage + NUM_PER_PAGE) {
      setCurrentPage(currentPage + NUM_PER_PAGE);
    } else {
      setCurrentPage(0);
    }
  }, [filteredProducts, currentPage]);

  const goBack = useCallback(() => {
    history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Tier));
  }, [history]);

  const destinationURL = getPath(Routes.MEMBERSHIP_FORM);
  const membershipStatus = MembershipPackageAccordionStatus.Addons;

  if (isSectionsLoading) {
    return <FeedPlaceholder />;
  }

  const hasFreeProducts = productsFreeProduct.length > 0;

  const NewFreeSectionDescriptions = (
    <>
      <ChooseFreeProductTextWrapper>
        <StyledFreeSectionItem style={index === 0 ? { color: 'white' } : {}}>
          Sections enable members to connect, network, and access specialized resouces and information
          <StyledInfoBubble src={IC_INFO} />
        </StyledFreeSectionItem>
        <StyledAdditonalInfo style={index === 0 ? { color: 'white' } : {}}>
          You selected the <b>{selectedTierName}</b> package which <b>includes one section of your choice.</b>
        </StyledAdditonalInfo>
      </ChooseFreeProductTextWrapper>
    </>
  );

  const OldFreeSectionDescriptions = (
    <>
      <ChooseFreeProductTextWrapper>
        <StyledChooseFreeProductText style={index === 0 ? { color: 'white' } : {}}>
          Choose the section(s) you’d like, at no additional cost
        </StyledChooseFreeProductText>
      </ChooseFreeProductTextWrapper>
      <DetailWrapper>
        <StyledIconError />
        <StyledDetailText style={index === 0 ? { color: 'white' } : {}}>
          The number of free sections you may choose depends on your selected membership tier. Please review tier
          benefits for more details.
        </StyledDetailText>
      </DetailWrapper>
      <InstructionWrapper>
        {hasSelectedAllFreeProducts && (
          <StyledInstructionText style={index === 0 ? { color: 'white' } : {}}>
            You have selected the maximum number of authorized products. To be able to select other products, you must
            first deselect some.
          </StyledInstructionText>
        )}
      </InstructionWrapper>
    </>
  );

  return (
    <MembershipPackagePanelWrapper>
      {benefitQuantity !== 0 && (
        <>
          <Grid>
            <SectionGridColumn mobile={16} tablet={8} computer={shouldShowNewPackageBuilder ? 12 : 10}>
              {shouldShowNewPackageBuilder ? NewFreeSectionDescriptions : OldFreeSectionDescriptions}
            </SectionGridColumn>

            <StyledGridColumn mobile={16} tablet={5} computer={shouldShowNewPackageBuilder ? 4 : 6}>
              <FreeProductsWrapper>
                <StyledFreeProductCountText style={index === 0 ? { color: 'white' } : {}}>
                  {freeProductCount}
                </StyledFreeProductCountText>
                <StyledFreeProductText style={index === 0 ? { color: 'white' } : {}}>
                  /{benefitQuantity}
                  {shouldShowNewPackageBuilder ? `Included Sections` : `free product(s) selected`}
                </StyledFreeProductText>
              </FreeProductsWrapper>
            </StyledGridColumn>
          </Grid>
        </>
      )}
      <RelatedSectionGrid shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}>
        {productsFreeProduct.map((data: State.SectionFreeProduct) => (
          <StyledGridMembershipCardColumn mobile={16} tablet={6} computer={5} key={data.variants[0].sku}>
            {/* TODO: Will add 'Current' flag for existing subscriptions to be renew */}
            <MembershipCard
              headerTitle={data.name}
              description={data.description ?? ''}
              formattedPrice={'0'}
              isFree={true}
              isCardSelected={data.selected}
              slug={`${productTypeToSlug(data.productType as ProductTypes.ProductType)}/${data.slug}`}
              label={data.label}
              isEnable={data.enabled}
              handleClick={handleMembershipSectionFreeProductButtonClick.bind(null, {
                productId: data.productId as string,
                sku: data.variants[0].sku as string,
                group: data.includedInBenefit?.value?.[0].id as string,
                selected: !!data.selected as boolean,
                groupSku: data.includedInBenefit?.value?.[0]?.obj?.masterData?.current?.masterVariant?.sku as string,
                benefitName: data.name as string,
              })}
              productDetails={data}
              isExistingMember={Boolean(current?.membership)}
              isUserSuspendedByEthics={isEthicsSuspended}
              shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
            />
          </StyledGridMembershipCardColumn>
        ))}
      </RelatedSectionGrid>
      <br />
      {!shouldShowNewPackageBuilder && (
        <RemainingProductTextWrapper>
          <RemainingProductText>
            You may also be interested in purchasing additional section memberships
          </RemainingProductText>
        </RemainingProductTextWrapper>
      )}
      {shouldShowNewPackageBuilder && hasFreeProducts && (
        <>
          <StyledPurpleDivider />
          <StyledPurpleText>You can choose any additonal sections</StyledPurpleText>
        </>
      )}
      {shouldShowNewPackageBuilder && !hasFreeProducts && (
        <SectionsDescriptionWrapper>
          Sections allow members to connect, network, and access resources and information related to their particular
          area of focus
        </SectionsDescriptionWrapper>
      )}
      <br />
      {!shouldShowNewPackageBuilder && (
        <CategoriesWrapper>
          {categories.map((category: State.ProductCategories) => {
            return !!category?.name ? (
              <StyledButton
                key={category.name}
                testId={`${category.name}`}
                isSelected={category.name === selectedCategory}
                onClick={filterByCategory.bind(null, category.name)}
                to={`${destinationURL}#${membershipStatus}`}
                textValue={category.name}
              >
                {category.name}
              </StyledButton>
            ) : null;
          })}
        </CategoriesWrapper>
      )}
      <br />
      <RelatedSectionGrid shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}>
        {productsWithPrice.map((data: State.SectionWithPriceProduct) => {
          const sectionMatchedProduct = membershipSections.listOfProductWithPrice.find(
            item => item.variants[0].sku === data.variants[0].sku
          );
          const renewedSect = renewedSections?.find((r: any) => r.productId === data.productId || r.name === data.name);
          const isCurrent: boolean =
            isRenewal &&
            existingSection.some((section: { name: string }) => section.name === data.name) &&
            (membershipEvent.isClickedCredentialsSectionsRenewal || membershipEvent.isClickedMembershipRenewal);
          // Disable the add to cart button if outside renewal and section is already purchased
          const isAddToCartDisable: boolean = (() => {
            if (!existingSection.length || (isRenewal && !membershipEvent.isClickedSectionsJourney)) return false;
            return existingSection.some(
              (existingSec: { variant: { sku: string } }) => existingSec.variant.sku === data.variants[0].sku
            );
          })();
          return (
            <StyledGridMembershipCardColumn mobile={16} tablet={6} computer={5} key={data.variants[0].sku}>
              <MembershipCard
                boughtOn={renewedSect?.zuoraTermStartDate || ''}
                disabled={renewedSections.some(
                  (renewed: any) => renewed.productId === data.productId || renewed.name === data.name
                )}
                headerTitle={data.name}
                description={data.description ?? ''}
                formattedPrice={
                  data.variants[0].price?.transformedPrice ? `${data.variants[0].price?.transformedPrice}` : '0'
                }
                slug={`${productTypeToSlug(data.productType as ProductTypes.ProductType)}/${data.slug}`}
                label={data.label}
                isCardSelected={data.selected}
                isEnable={!isAddToCartDisable && data.enabled}
                isCurrent={isCurrent}
                handleClick={handleSectionWithPriceButtonClick.bind(
                  null,
                  sectionMatchedProduct?.productId || data.productId,
                  data.variants[0].sku as string,
                  !!data.selected
                )}
                productDetails={data}
                isExistingMember={current?.membership ? true : false}
                isUserSuspendedByEthics={isEthicsSuspended}
                shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
              />
            </StyledGridMembershipCardColumn>
          );
        })}
      </RelatedSectionGrid>
      {isEthicsSuspended && (
        <StyledNotificationBanner
          testId="payment-tech-error"
          childrenTestId="payment-tech-error-children"
          variant={NotificationBannerEnums.variant.red}
          icon={<StyledIconErrorSuspension />}
        >
          You do not have access to this product because you are suspended due to Ethics reasons. For any questions,
          please contact{' '}
          <Link isExternal testId="error-email" to="mailto:ethics@aicpa.org">
            {' '}
            <StyledUnderlined>ethics@aicpa.org</StyledUnderlined>
          </Link>{' '}
          or call{' '}
          <Link isExternal testId="error-phone" to="tel:1-888-777-7077">
            <StyledUnderlined>1-888-777-7077</StyledUnderlined>
          </Link>
          , using option 2, then option 3.
        </StyledNotificationBanner>
      )}
      {hasNextPage && (
        <SeeMoreWrapper>
          <StyledLabel onClick={handleSeeMore}>See more</StyledLabel>
        </SeeMoreWrapper>
      )}
      {!shouldShowNewPackageBuilder && (
        <ConfirmButtonWrapper>
          {isRenewal ? (
            <StyledConfirmButton
              disabled={!hasSelectedAllFreeProducts}
              testId={'confirm-btn'}
              onClick={handleClick}
              to={`${destinationURL}#${membershipStatus}`}
              size={ButtonEnums.sizes.small}
              variant={ButtonEnums.variants.primary}
            >
              Confirm
            </StyledConfirmButton>
          ) : (
            <StyledConfirmButton
              disabled={!hasSelectedAllFreeProducts}
              testId={'confirm-btn'}
              onClick={handleClick}
              to={`${destinationURL}#${membershipStatus}`}
              size={ButtonEnums.sizes.small}
              variant={ButtonEnums.variants.primary}
            >
              Next
            </StyledConfirmButton>
          )}
        </ConfirmButtonWrapper>
      )}
      {shouldShowNewPackageBuilder && (
        <StyledCTAButtonWrapper>
          <Button
            testId="membership-section-btn-continue"
            onClick={handleClick}
            to={`${destinationURL}#${membershipStatus}`}
            variant={ButtonEnums.variants.primary}
          >
            Continue
          </Button>
          <Button testId="membership-section-btn-cancel" variant={ButtonEnums.variants.secondary} onClick={goBack}>
            Go back
          </Button>
        </StyledCTAButtonWrapper>
      )}
    </MembershipPackagePanelWrapper>
  );
};

interface StyledButtonProps {
  isSelected: boolean;
}
const StyledUnderlined = styled.label`
  color: ${props => props.theme.colors.primaryPurple} !important;
  text-decoration: underline !important;
  cursor: pointer;
`;

const activeCategoryButtonStyles = css`
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryPurple};
  color: ${props => props.theme.colors.neutralWhite};
  background-color: ${props => props.theme.colors.primaryPurple};
`;

const wrapperWidthResponsiveStyles = css`
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: 100%;
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    &&& {
      width: 74%;
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 35%;
  }
`;

const SectionGridColumn = styled(Grid.Column)`
  ${props => props.theme.mediaQueries.tabletOnly} {
    height: ${props => props.theme.pxToRem(80)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    height: ${props => props.theme.pxToRem(100)};
  }
`;

const ChooseFreeProductTextWrapper = styled.div`
  position: relative;
  text-align: left;
  ${wrapperWidthResponsiveStyles}
`;

const StyledFreeSectionItem = styled.p`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
  text-align: left;
  margin: 0;
`;

const StyledAdditonalInfo = styled.p`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.colors.primaryPurple};
  text-align: left;
  margin: 0;
`;

const StyledChooseFreeProductText = styled.span`
  width: ${props => props.theme.pxToRem(269)};
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => `0 ${props.theme.pxToRem(418)} ${props.theme.pxToRem(3)} 0`};
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
  text-align: left;
`;

const DetailWrapper = styled.div`
  text-align: left;
  ${wrapperWidthResponsiveStyles}
`;

const CategoriesWrapper = styled.div`
  width: 100%;
  text-align: left;
  position: relative;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-bottom: ${props => props.theme.pxToRem(20)};
    margin-left: ${props => props.theme.pxToRem(-8)};
    width: 35%;
  }
`;

const InstructionWrapper = styled.div`
  width: 100%;
  text-align: left;
  position: relative;

  ${wrapperWidthResponsiveStyles}
  ${props => props.theme.mediaQueries.desktopOnly} {
    top: 0;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    top: ${props => props.theme.pxToRem(36)};
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    top: ${props => props.theme.pxToRem(50)};
  }
`;

const StyledDetailText = styled.span`
  width: ${props => props.theme.pxToRem(473)};
  height: ${props => props.theme.pxToRem(14)};
  margin: ${props => `${props.theme.pxToRem(1)} 0 ${props.theme.pxToRem(1)} ${props.theme.pxToRem(4)}`};
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey6};
`;

const StyledIconError = styled(IconError)`
  &&&& {
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
    margin: ${props => `0 ${props.theme.pxToRem(4)} 0 0`};
    color: ${props => props.theme.colors.interfaceBlue};
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledInstructionText = styled.span`
  width: ${props => props.theme.pxToRem(493)};
  height: ${props => props.theme.pxToRem(36)};
  margin: ${props => `${props.theme.pxToRem(10)} ${props.theme.pxToRem(194)} 0 0`};
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.22px;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const FreeProductsWrapper = styled.span`
  width: ${props => props.theme.pxToRem(224)};
  height: ${props => props.theme.pxToRem(24)};
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  position: relative;

  ${props => props.theme.mediaQueries.tabletOnly} {
    top: ${props => props.theme.pxToRem(35)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    top: ${props => props.theme.pxToRem(60)};
  }
`;

const StyledGridColumn = styled(Grid.Column)`
    position: relative;
    ${props => props.theme.mediaQueries.desktopOnly} {
      text-align: right;
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: left;
      top: ${props => props.theme.pxToRem(-40)};
    }
  }
`;

const StyledFreeProductCountText = styled.span`
  font-size: ${props => props.theme.pxToRem(26)};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.primaryDarkPurple};
`;

const StyledFreeProductText = styled.span`
  font-weight: ${props => props.theme.fontWeights.light};
`;

const RemainingProductTextWrapper = styled.div`
  position: relative;
  text-align: left;
`;

const RemainingProductText = styled.span`
  width: ${props => props.theme.pxToRem(855)};
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => `0 0 ${props.theme.pxToRem(20)} ${props.theme.pxToRem(-2)}`};
  font-family: 'Roboto';
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledButton = styled(Button)<StyledButtonProps>`
  &&& {
    font-weight: ${props => props.theme.fontWeights.regular};
    border-radius: ${props => props.theme.pxToRem(6)};
    align-items: center;
    padding: ${props =>
      `${props.theme.pxToRem(8)} ${props.theme.pxToRem(14)} ${props.theme.pxToRem(8)} ${props.theme.pxToRem(14)}`};
    border-radius: ${props => props.theme.pxToRem(20)};
    margin: ${props =>
      `${props.theme.pxToRem(-5)} ${props.theme.pxToRem(10)} ${props.theme.pxToRem(24)} ${props.theme.pxToRem(-3)}`};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: ${props =>
        `${props.theme.pxToRem(-5)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(12)} ${props.theme.pxToRem(0)}`};
    }

    &:hover {
      ${activeCategoryButtonStyles}
    }

    &:focus {
      ${activeCategoryButtonStyles}
    }

    ${props =>
      props.isSelected
        ? css`
            ${activeCategoryButtonStyles}
          `
        : css`
            color: ${props.theme.colors.primaryPurple};
            background-color: ${props.theme.colors.neutralGrey2};
          `}
  }
`;

const StyledLabel = styled(Label)`
  &&& {
    background-color: transparent;
    color: ${props => props.theme.colors.primaryPurple};

    &:hover {
      cursor: pointer;
    }
  }
`;

const SeeMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;

  ${props => props.theme.mediaQueries.desktopOnly} {
    top: ${props => props.theme.pxToRem(40)};
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    &&& {
      top: ${props => props.theme.pxToRem(70)};
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    top: ${props => props.theme.pxToRem(40)};
  }

  ${wrapperWidthResponsiveStyles}
`;

const StyledConfirmButton = styled(Button)`
  &&& {
    width: ${props => props.theme.pxToRem(116)};
    height: ${props => props.theme.pxToRem(24)};
    border-radius: ${props => props.theme.pxToRem(4)};
    background-color: ${props => props.theme.colors.primaryPurple};
    color: ${props => props.theme.colors.neutralWhite};
    line-height: 1.57 !important;

    &:hover {
      ${activeCategoryButtonStyles}
    }
  }
  ${props => props.theme.mediaQueries.tabletOnly} {
    position: relative;
    top: ${props => props.theme.pxToRem(25)};
  }
`;

const StyledPurpleDivider = styled.div`
  border: ${props => props.theme.pxToRem(2)} solid ${props => props.theme.colors.primaryPurple};
  margin: ${props => props.theme.pxToRem(25)} 0;
`;

const StyledPurpleText = styled.p`
  color: ${p => p.theme.colors.primaryPurple};
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-top: ${props => props.theme.pxToRem(20)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(312)};
  }
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: ${props => props.theme.pxToRem(1075)};
  }
`;

const StyledIconErrorSuspension = styled(IconError)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  display: inline;
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin: ${props => `${props.theme.pxToRem(10)} ${props.theme.pxToRem(-2)} auto`};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `${props.theme.pxToRem(12)} ${props.theme.pxToRem(-9)} auto`};
  }
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.theme.pxToRem(50)};
  ${wrapperWidthResponsiveStyles}
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(116)};
    height: ${props => props.theme.pxToRem(34)};
    margin-bottom: 0;
  }
`;

const StyledGridMembershipCardColumn = styled(Grid.Column)`
  padding: 0 !important;
  &&& {
    margin-right: 0 !important;
  }
`;

const RelatedSectionGrid = styled(Grid)<{ shouldShowNewPackageBuilder?: boolean }>`
  gap: ${props => props.theme.pxToRem(16)};
  &&& {
    margin-left: 0;
    margin-right: 0;
  }

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin: ${props => `1 0 ${props.theme.pxToRem(20)} 0`} !important;
    width: auto;
    padding: 0 !important;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    top: ${props => props.theme.pxToRem(-10)};
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    &&& {
      left: 0;
      top: ${props => props.theme.pxToRem(-10)};
    }
  }
`;

const StyledCTAButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.pxToRem(50)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    flex-direction: column;
    margin-top: ${props => props.theme.pxToRem(40)};
  }
  &&&& {
    button {
      font-size: ${props => props.theme.pxToRem(20)} !important;
      ${props => props.theme.mediaQueries.desktopOnly} {
        width: ${props => props.theme.pxToRem(200)};
        margin: ${props => props.theme.pxToRem(25.5)} !important;
      }
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100%;
        margin-top: ${props => props.theme.pxToRem(16)} !important;
      }
    }
  }
`;

const StyledInfoBubble = styled.img`
  fill: ${props => props.theme.colors.primaryPurple};
  display: inline-block;
  vertical-align: middle;
  padding-left: ${props => props.theme.pxToRem(5)};
`;

const SectionsDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  line-height: normal;
  letter-spacing: ${props => props.theme.pxToRem(-0.32)};
  font-size: ${props => props.theme.fontSizes.s};
  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: left;
  }
`;
