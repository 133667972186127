import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import request from 'utils/GraphQLClient';
import { MUTATE_UPDATE_MARKETING_CONSENT, QUERY_MARKETING_CONSENT } from 'mxp-graphql-queries';

// ------------------------------------
// Actions
// ------------------------------------

export const getMarketingData: any = createAction(
  'consents/GET_CONSENTS',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    return request(QUERY_MARKETING_CONSENT).then(response => {
      const { id, sms, ...rest } = response.getMarketingData;
      const individualConsent = {
        channel: {
          sms,
        },
        ...rest,
      };
      return individualConsent;
    });
  }
);

export const updateMarketingData: any = createAction(
  'consents/UPDATE_CONSENTS',
  (data: Partial<State.IndividualConsent>) => (dispatch: Dispatch, getState: () => State.Root) => {
    return request(MUTATE_UPDATE_MARKETING_CONSENT, { marketingInfo: data }).then(response => {
      const { sms, id, ...rest }: any = data;
      const individualConsent = {
        channel: {
          sms,
        },
        ...rest,
      };
      return individualConsent;
    });
  }
);

export const resetConsentModule: any = createAction('consents/RESET_CONSENTS');
