import { COMPANY_NAME, Routes, fullRoutes, MembershipPackageAccordionStatus } from 'constants/index';
import { Location } from 'history';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';

const fallBackRoute = Routes.FEED;

export const getRouterRoute = (url: string): match<{}> | null => {
  // Remove query parameters from the URL
  const urlPath = url.split('?')[0];

  return Object.keys(Routes).reduce(
    (prev: match<{}> | null, key: string) =>
      prev ||
      matchPath(urlPath, {
        path: getPath(Routes[key as keyof typeof Routes]),
        exact: true,
      }),
    null
  );
};

/**
 * function: getMatchingPath
 * Given a path from the react router (e.g. a parameterized one), find which route definition it came from
 */
const getMatchingPath = (path: string) => Object.keys(fullRoutes).find(key => fullRoutes[key].path === path);

export const getRoute = (url: string, useFallback = true) => {
  const matchingRoute = getRouterRoute(url);
  const pathString = matchingRoute?.path ?? (useFallback ? getPath(fallBackRoute) : '');
  return getMatchingPath(pathString);
};

export const isRoutePublic = (route: string) => {
  const matchingPath = fullRoutes[route];
  if (matchingPath && matchingPath.hasOwnProperty('public')) {
    return matchingPath.public as boolean;
  }
  return false;
};

export const getRouteAnalytics = (route: string) => {
  const matchingPath = fullRoutes[route];
  if (matchingPath && matchingPath.hasOwnProperty('useAnalytics')) {
    return matchingPath.useAnalytics as boolean;
  }
  return true;
};

export const getPageTitles = (path: string) => {
  const matchingPath = fullRoutes[getMatchingPath(path) as string];
  if (matchingPath && typeof matchingPath.title === 'string') {
    return (i?: any): string => matchingPath.title as string;
  }
  if (matchingPath && typeof matchingPath.title === 'function') {
    return matchingPath.title as (i?: any) => string;
  }
  return (i?: any): string => `${COMPANY_NAME}`;
};

export const getPath = (route: Routes, { withoutParams = false }: any = {}): string => {
  const path: string = fullRoutes[route]?.path || fullRoutes[fallBackRoute].path;
  return withoutParams ? path.slice(0, path.indexOf('/:')) : path;
};

export const getMembershipPackagePath = (status: MembershipPackageAccordionStatus) =>
  `${getPath(Routes.MEMBERSHIP_FORM)}#${status}`;

export const getCimaMembershipPackagePath = (status: MembershipPackageAccordionStatus, isPaperBilling?: boolean) => {
  return isPaperBilling
    ? `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}?isPaperBilling=true#${status}`
    : `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${status}`;
};

export const getCenterMembershipPackagePath = (status: MembershipPackageAccordionStatus) =>
  `${getPath(Routes.CENTER_MEMBERSHIP_FORM)}#${status}`;

export const getCurrentLocation = (location?: Location): string => {
  return location ? `${location.pathname}${location.search || ''}${location.hash || ''}` : '';
};

export const redirectToOktaExternalLogin = (oktaRedirectUrl: string, location?: Location): void => {
  const prevRoute: string = getCurrentLocation(location);
  window.location.replace(`${oktaRedirectUrl}${prevRoute}`);
};

export const compareTabWithPath = (path: string, comparison: string): boolean => {
  const comparisonSplit = comparison.split('/');
  const pathSplit = path.split('/');
  return comparisonSplit.pop() === pathSplit.pop();
};

export const getPaperBillingPath = (route: Routes, isPaperBilling: boolean): string => {
  return isPaperBilling ? `${getPath(route)}?isPaperBilling=true` : getPath(route);
};

export const previousAddOnsState = (
  route: Routes,
  accordion: MembershipPackageAccordionStatus,
  isPaperBilling: boolean
) => {
  return isPaperBilling ? `${getPath(route)}?isPaperBilling=true` : `${getPath(Routes.MEMBERSHIP_FORM)}#${accordion}`;
};
