// TODO: This needs refactoring

import React from 'react';
import styled, { css } from 'styled-components';
import { ContentCardsCarousel } from 'components/organisms/ContentCardsCarousel';
import { ContentCard } from 'components/molecules';
import {
  ButtonLink,
  Heading,
  ButtonVariants,
  Grid,
  ButtonEnums,
  OnlyDesktopCSS,
  OnlyMobileCSS,
} from 'components/atoms';
import { isColorDark } from 'utils/colorHelpers';
import { LANDING_PAGES_CONSTS } from '../../../constants';
import { Contentful } from 'mxp-schemas';
import {
  DefaultInlineLinkStyles,
  ExternalLinkStyles,
  ExternalLinkStylesDark,
  InlineLinkOnDarkBgStyles,
} from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';
import { setSessionStorageItem, getPath } from 'utils';
import { Routes, StorageNames } from 'constants/index';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { isCimaMembershipLandingPageSelector } from 'modules/router/selectors';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
  isFullPagePagination: boolean;
  blockHeight: string;
  isFirstSlide: boolean;
  isLastSlide: boolean;
}

export const BlockContentProductPromotion: React.FC<Props> = ({
  headingType,
  modularBlock,
  isFullPagePagination,
  blockHeight,
  isFirstSlide,
  isLastSlide,
}) => {
  const block = modularBlock as Contentful.ModularContent.ContentAndProductPromotionBlock;
  const products = block?.products ?? [];
  const contents = block?.contentReference ?? [];
  const pageContents = contents.concat(products).splice(0, LANDING_PAGES_CONSTS.CONTENTS_AMOUNT);
  const isProductsOrContent: boolean = Boolean(pageContents?.length);
  const isImage: boolean = Boolean(block.image);
  const isBgColorDark: boolean = isColorDark(block.bgColor);
  const isFirstOrLastSlide = isFirstSlide || isLastSlide ? 770 : 940;
  const imageUrl: string = isFullPagePagination
    ? `${block.image}&fit=thumb&w=955&h=${isFirstOrLastSlide}`
    : `${block.image}&fit=thumb&w=955&h=448`;

  const noProductOrContentWithImage: boolean = Boolean(!isProductsOrContent && isImage);
  const isOneProductOrContent: boolean = Boolean(pageContents?.length === 1);

  const isTwoProductOrContent: boolean = Boolean(pageContents?.length === 2);
  const isThreeAndMoreProductOrContent: boolean = Boolean(pageContents && pageContents?.length > 2);
  const isTwoColumns: boolean = noProductOrContentWithImage || isOneProductOrContent || isTwoProductOrContent;
  const cannotbeAlign: boolean = !noProductOrContentWithImage && !isOneProductOrContent && !isTwoProductOrContent;
  const isLeftAligned: boolean = Boolean(block.contentAlignment || block.contentAlignment === null);
  const history = useHistory();
  const isCimaMembershipLandingPage = useSelector(isCimaMembershipLandingPageSelector);

  const handleButtonOnClick = () => {
    if (isCimaMembershipLandingPage) {
      setSessionStorageItem({ [StorageNames.previousPage]: history.location.pathname });
      history.push(getPath(Routes.CIMA_MEMBERSHIP_PROMO_CODE));
    }
  };

  const renderCtaButton = (): React.ReactNode => {
    if (!block.ctaUrl || !block.ctaText) return;
    return (
      <StyledButtonLink
        size={ButtonEnums.sizes.medium}
        variant={isBgColorDark ? ButtonVariants.secondaryNegative : ButtonVariants.primary}
        bordered
        to={block.ctaUrl}
        testId="product-promo-block"
        onClick={handleButtonOnClick}
      >
        {block.ctaText}
      </StyledButtonLink>
    );
  };

  const renderHeaderBodyDesktop = (): React.ReactNode => {
    return (
      <StyledGridColumn
        width={!isTwoColumns ? 11 : 7}
        textAlign={isTwoColumns ? 'left' : 'center'}
        floated={isTwoColumns ? (isLeftAligned ? 'left' : 'right') : undefined}
        is-left-aligned={isLeftAligned ? 1 : 0}
        no-product-with-image={noProductOrContentWithImage ? 1 : 0}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <StyledHeaderDescription
          isBgColorDark={isBgColorDark}
          dataFullPage={isFullPagePagination}
          noProductOrContentWithImage={noProductOrContentWithImage}
          isLeftAligned={isLeftAligned}
          tabIndex={0}
        >
          {block.header && (
            <StyledHeading
              as={headingType}
              dangerouslySetInnerHTML={{ __html: block.header }}
              data-is-single-column={!isTwoColumns}
              isBgColorDark={isBgColorDark}
              tabIndex={0}
            />
          )}
          {block.description && (
            <StyledParagraph
              tabIndex={0}
              dangerouslySetInnerHTML={{ __html: block.description }}
              isBgColorDark={isBgColorDark}
            />
          )}
          {!isThreeAndMoreProductOrContent && <StyledButtonWrapper>{renderCtaButton()}</StyledButtonWrapper>}
        </StyledHeaderDescription>
      </StyledGridColumn>
    );
  };

  const renderImage = (): React.ReactNode => {
    if (noProductOrContentWithImage) {
      return (
        <StyledGridColumnForImage
          floated="right"
          is-left-aligned={isLeftAligned ? 1 : 0}
          no-product-with-image={noProductOrContentWithImage ? 1 : 0}
          width={8}
          style={{ display: 'flex' }}
        >
          <StyledOnlyDesktop>
            <Image
              noProductOrContentWithImage={noProductOrContentWithImage}
              isLeftAligned={isLeftAligned}
              alt=""
              isFullPagePagination={isFullPagePagination}
              src={imageUrl}
              style={{ display: 'block', objectFit: 'cover' }}
              blockHeight={blockHeight}
              tabIndex={0}
              loading="lazy"
            />
          </StyledOnlyDesktop>
        </StyledGridColumnForImage>
      );
    }

    if (isOneProductOrContent || isTwoProductOrContent) {
      return (
        <Grid.Column>
          <OnlyDesktopCSS style={{ width: '100%' }}>
            <Grid data-testid="products-content-holder">
              <Grid.Row columns="equal">
                {pageContents?.map((product: any) => (
                  <Grid.Column key={product.id}>
                    <ContentCard
                      isCampaignCard={true}
                      isBgColorDark={isBgColorDark}
                      contentCardItem={product}
                      isDoubleWidthCard={isOneProductOrContent}
                      isHeroCard
                      testid={`promoted-product-item-${product.id}`}
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </OnlyDesktopCSS>
        </Grid.Column>
      );
    }
  };

  const renderCannotBeAligned = (): React.ReactNode => {
    return (
      <StyledGrid is-three-and-more={isThreeAndMoreProductOrContent ? 1 : 0}>
        <Grid.Row
          columns={isTwoColumns ? 2 : 1}
          verticalAlign="middle"
          centered={!isTwoColumns}
          style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
        >
          {/* Header, Description, Button column */}
          <StyledGridColumn
            width={!isTwoColumns ? 11 : 7}
            textAlign={isTwoColumns ? 'left' : 'center'}
            floated={isTwoColumns ? 'left' : undefined}
            style={{ display: 'flex', justifyContent: 'center' }}
            is-three-and-more={isThreeAndMoreProductOrContent ? 1 : 0}
            data-full-page-pagination={isFullPagePagination ? 1 : 0}
          >
            <StyledHeaderDescription isBgColorDark={isBgColorDark}>
              {block.header && (
                <StyledHeading
                  as={headingType}
                  dangerouslySetInnerHTML={{ __html: block.header }}
                  data-is-single-column={!isTwoColumns}
                  is-three-and-more={isThreeAndMoreProductOrContent}
                  isBgColorDark={isBgColorDark}
                  tabIndex={0}
                />
              )}
              {block.description && (
                <StyledParagraph
                  dangerouslySetInnerHTML={{ __html: block.description }}
                  is-three-and-more={isThreeAndMoreProductOrContent}
                  isBgColorDark={isBgColorDark}
                  tabIndex={0}
                />
              )}

              {!isThreeAndMoreProductOrContent && <StyledButtonWrapper>{renderCtaButton()}</StyledButtonWrapper>}
              {isThreeAndMoreProductOrContent && <OnlyMobileCSS> {renderCtaButton()}</OnlyMobileCSS>}
            </StyledHeaderDescription>
          </StyledGridColumn>

          {noProductOrContentWithImage && (
            <Grid.Column floated="right">
              <OnlyMobileCSS>
                <Image alt="" src={`${block.image}&fit=thumb&w=375&h=400`} loading="lazy" />
              </OnlyMobileCSS>
            </Grid.Column>
          )}

          {isThreeAndMoreProductOrContent && (
            <OnlyDesktopCSS style={{ justifyContent: 'center', width: '100%' }}>
              <StyledGrid centered>
                <StyledGridRow columns={4}>
                  {pageContents?.map((product: any, index: number) => {
                    const isDoubleWidth = Boolean(pageContents?.length === 3 && index === 0);
                    return (
                      <Grid.Column key={product.id} computer={isDoubleWidth ? 8 : 4}>
                        <ContentCard
                          isCampaignCard={true}
                          isBgColorDark={isBgColorDark}
                          contentCardItem={product}
                          isDoubleWidthCard={isDoubleWidth}
                          isHeroCard
                          testid={`promoted-product-item-${product.id}`}
                        />
                      </Grid.Column>
                    );
                  })}
                </StyledGridRow>
                {renderCtaButton()}
              </StyledGrid>
            </OnlyDesktopCSS>
          )}
        </Grid.Row>
      </StyledGrid>
    );
  };

  return (
    <Wrapper>
      <OnlyDesktopCSS>
        {isTwoColumns && (
          <StyledGrid>
            <Grid.Row
              columns={isTwoColumns ? 2 : 1}
              verticalAlign="middle"
              centered={!isTwoColumns}
              style={{ padding: 0 }}
            >
              {isLeftAligned ? renderHeaderBodyDesktop() : renderImage()}
              {isLeftAligned ? renderImage() : renderHeaderBodyDesktop()}
            </Grid.Row>
          </StyledGrid>
        )}
        {cannotbeAlign && renderCannotBeAligned()}
      </OnlyDesktopCSS>
      <OnlyMobileCSS>{renderCannotBeAligned()}</OnlyMobileCSS>
      {/* Products render for mobile */}
      <OnlyMobileCSS as={Grid.Row}>
        {pageContents && (
          <>
            {isOneProductOrContent ? (
              pageContents.map((product: any) => (
                <Grid.Column key={product.id} style={{ width: '100%', marginTop: '16px', padding: 0 }}>
                  <StyledMobileContentCard
                    isCampaignCard
                    isBgColorDark={isBgColorDark}
                    contentCardItem={product}
                    isDoubleWidthCard={isOneProductOrContent}
                    isHeroCard
                    testid={`promoted-product-item-${product.id}`}
                  />
                </Grid.Column>
              ))
            ) : (
              <StyledContentCardsCarousel
                isCampaignCard={true}
                isBgColorDark={isBgColorDark}
                content={pageContents as any}
                heroCategoryCarousel
                itemTestId="product-promo-block-content-card"
              />
            )}
          </>
        )}
      </OnlyMobileCSS>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: auto 0;
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&&& {
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(20)};
  }
`;

const StyledGrid = styled(Grid)`
  &&&&& {
    width: 100%;
    margin: 0;
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: center;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    ${props => props.theme.mediaQueries.desktopOnly} {
      ${props =>
        Boolean(!props['is-left-aligned'] && props['no-product-with-image']) &&
        `
        margin-left: 0%;
        padding-left: ${props.theme.pxToRem(80)};
      `}

      ${props =>
        Boolean(props['is-left-aligned'] && props['no-product-with-image']) &&
        `
        margin-left: auto;
        margin-right: 0%;
        padding-right: ${props.theme.pxToRem(68.9)};

      `}
    }
  }
`;

const StyledGridColumnForImage = styled(Grid.Column)`
  &&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: center;
    }

    ${props =>
      props['no-product-with-image'] &&
      `
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      height: 100%;
    `}
  }
`;

const StyledHeaderDescription = styled.div<{
  isBgColorDark: boolean;
  dataFullPage?: boolean;
  noProductOrContentWithImage?: boolean;
  isLeftAligned?: boolean;
}>`
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  .rich-text-external-link {
    ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${props => (props.isBgColorDark ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }

  .rich-text-internal-link {
    ${DefaultInlineLinkStyles};
  }
`;

const StyledHeading = styled(Heading)<any>`
  margin-bottom: ${props =>
    props['is-three-and-more']
      ? 0
      : props['data-is-single-column']
      ? props.theme.pxToRem(16)
      : props.theme.pxToRem(24)};

  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  p {
    line-height: 1.38;
    margin: 0;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.l};
    line-height: 1.33;
  }
`;

const StyledParagraph = styled.div<any>`
  &&& {
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.light};

    p {
      line-height: 1.33;
      margin-top: ${props => (props['is-three-and-more'] ? props.theme.pxToRem(16) : 0)};
    }

    a {
      ${DefaultInlineLinkStyles};

      ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(24)};
      font-size: ${props => props.theme.fontSizes.s};
      line-height: 1.5;

      li > p {
        text-align: left;
      }
    }
  }
`;

const StyledButtonWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(32)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: 0;
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding: 0;
    margin: ${props => props.theme.pxToRem(40)} 0;
  }
`;

const StyledMobileContentCard = styled(ContentCard)`
  margin-bottom: 0;
`;

const StyledContentCardsCarousel = styled(ContentCardsCarousel)`
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(40)};
  }
`;

const Image = styled.img<{
  noProductOrContentWithImage?: boolean;
  isLeftAligned?: boolean;
  isFullPagePagination?: boolean;
  blockHeight?: string;
}>`
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: -12%;
    margin-bottom: -10.8%;
    width: ${props => props.theme.pxToRem(375)};
    height: ${props => props.theme.pxToRem(400)};
  }
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: 100%;

    ${props =>
      !props.isFullPagePagination &&
      css`
        height: ${props.theme.pxToRem(448)};
      `}

    ${props =>
      props.isFullPagePagination &&
      css`
        height: ${props.blockHeight};
      `}
  }
`;

const StyledOnlyDesktop = styled(OnlyDesktopCSS)`
  height: 100%;
  width: 100%;
`;
