import React from 'react';
import styled from 'styled-components/macro';
import { Product } from 'mxp-schemas';
import { BundleContentsItem } from 'components/molecules/BundleContentsItem/BundleContentsItem';
import { ParagraphTitle, TitleSizes } from './ProductParagraphs';
import { Divider } from 'components/atoms';

interface Props {
  productItems: Product.ProductItem[];
  bundleDiscountPercent: number;
  isAuth: boolean | null;
  isExistingCartItem?: boolean;
  setSelectedBundleProductVariant: (variant: Common.BundleProductVariant) => void;
  setBundleElementRefs: (bundleElementRefs: Common.BundleElement[]) => void;
  bundleItemVariantPrices: State.VariantsPriceInfoForUserType[];
  bundleItemsOutOfStockInfo: State.BundleItemVariantOutOfStock[][];
  accessProvisionedItem: (product: Product.ProductItem) => void;
  isUserSuspendedByEthics?: boolean;
}

export const BundleContents: React.FC<Props> = ({
  productItems,
  bundleDiscountPercent,
  isAuth,
  isExistingCartItem,
  setSelectedBundleProductVariant,
  setBundleElementRefs,
  bundleItemVariantPrices,
  bundleItemsOutOfStockInfo,
  accessProvisionedItem,
  isUserSuspendedByEthics,
}) => {
  const bundleElements: Common.BundleElement[] = React.useMemo(() => {
    const lastElementIndex = productItems.length - 1;
    return productItems.map((productItem: Product.ProductItem, index: number) => {
      const isLastElement = index === lastElementIndex;
      const blockRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
      return {
        nodeEl: (
          <div ref={blockRef} key={productItem.productId}>
            <BundleContentsItemContainer>
              <BundleContentsItem
                bundleDiscountPercent={bundleDiscountPercent}
                productItem={productItem}
                indexWithinBundle={index}
                setSelectedBundleProductVariant={setSelectedBundleProductVariant}
                bundleItemVariantPrices={bundleItemVariantPrices}
                bundleItemsOutOfStockInfo={bundleItemsOutOfStockInfo}
                isExistingCartItem={isExistingCartItem}
                isAuth={isAuth}
                accessProvisionedItem={accessProvisionedItem}
                isUserSuspendedByEthics={isUserSuspendedByEthics}
              />
            </BundleContentsItemContainer>

            {!isLastElement && <Divider />}
          </div>
        ),
        id: productItem.productId,
        blockRef,
      };
    });
  }, [
    productItems,
    bundleDiscountPercent,
    setSelectedBundleProductVariant,
    bundleItemVariantPrices,
    bundleItemsOutOfStockInfo,
    isExistingCartItem,
    isAuth,
    accessProvisionedItem,
    isUserSuspendedByEthics,
  ]);

  React.useEffect(() => {
    setBundleElementRefs(bundleElements);
  }, [bundleElements]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <ParagraphTitleStyled titleSize={TitleSizes.LARGE}>What’s included in this bundle</ParagraphTitleStyled>
      {bundleElements.map(bundleElement => bundleElement.nodeEl)}
    </Container>
  );
};

const Container = styled.div`
  margin-top: ${props => props.theme.pxToRem(40)};
`;

const ParagraphTitleStyled = styled(ParagraphTitle as any)`
  &&& {
    padding-bottom: ${props => props.theme.pxToRem(12)};
  }
`;

const BundleContentsItemContainer = styled.div`
  padding-top: ${props => props.theme.pxToRem(28)};
`;
