import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Container, Grid, Divider } from 'semantic-ui-react';
import { ExitSurveyContainer as ExitSurvey } from 'containers/ExitSurveyContainer';
import { Heading, OnlyMobile, OnlyDesktop, Button, ButtonEnums } from 'components/atoms';
import { TellUsSurveyContainer as TellUsSurvey } from 'components/App/lazy-imports';
import { ICON_ARROW_UP, ICON_ARROW_DOWN } from 'resources/images';
import { handleEvent, PREFIXES, EXTERNAL_LINK_EVENT } from 'utils/Analytics';
import { getPath } from 'utils';
import { isEmptyString } from 'utils/StringHelpers';
import { ExtLinks, Routes } from 'constants/index';
import { getConstantByKeySelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';

interface Props {
  buttonTestId?: string;
}

export const MembershipBanner: React.FC<Props> = (props: Props) => {
  const title = useSelector(getConstantByKeySelector(CONSTANTS.MEMBERSHIP.BANNER.HEADER));
  const description = useSelector(getConstantByKeySelector(CONSTANTS.MEMBERSHIP.BANNER.SUB_HEADER));
  const ctaText = useSelector(getConstantByKeySelector(CONSTANTS.MEMBERSHIP.BANNER.BUTTON));
  const ctaURL = generatePath(getPath(Routes.MEMBERSHIP_FORM));

  const [showBanner] = useState(() => {
    return Boolean(title || description || ctaText);
  });

  const bannerText = React.useMemo(
    () => ({
      title: title || '',
      description: description || '',
      ctaText: ctaText || '',
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [showTellUsSurvey, setTellUsSurveyVisibility] = React.useState<boolean>(false);
  const [showExitSurvey, setExitSurveyVisibility] = React.useState<boolean>(false);

  const ButtonCtaLegacy: React.FC<Props> = ({ buttonTestId }) => {
    return bannerText.ctaText ? (
      <StyledButton
        testId={`${buttonTestId}-cta-legacy`}
        onClick={navigateToGoToLink}
        variant={ButtonEnums.variants.secondaryNegative}
        size={ButtonEnums.sizes.medium}
      >
        {bannerText.ctaText}
      </StyledButton>
    ) : null;
  };

  const navigateToGoToLink = React.useCallback(() => {
    if (ctaURL) {
      window.open(ctaURL.trim(), '_blank')?.focus();
    }
    handleEvent(
      {
        text: `${PREFIXES.HEADER}:aicpa.org:${bannerText.ctaText}`,
        href: ctaURL.trim(),
      },
      EXTERNAL_LINK_EVENT
    );
  }, [bannerText.ctaText]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigateToLegacy = React.useCallback(() => {
    window.location.href = ExtLinks.LEGACY;
    handleEvent(
      {
        text: `${PREFIXES.HEADER}:aicpa.org:${bannerText.ctaText}`,
        href: ctaURL.trim(),
      },
      EXTERNAL_LINK_EVENT
    );
  }, [bannerText.ctaText]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTellUsSurveyClose = React.useCallback((): void => {
    setTellUsSurveyVisibility(false);
  }, []);

  // Component will unmount effect
  React.useEffect(() => {
    window.onpopstate = () => {
      if (showExitSurvey) {
        setExitSurveyVisibility(false);
      }

      if (showTellUsSurvey) {
        setTellUsSurveyVisibility(false);
      }
    };
  }, [showExitSurvey, showTellUsSurvey]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!showBanner) return null;
  return (
    <Banner data-testid="membership-banner">
      <Container>
        <StyledGridContainer columns={16} container>
          <Grid.Column computer={12} mobile={14}>
            <StyledInnerGridContainer>
              <StyledGridColumn computer={11} mobile={14}>
                <HiddenInput
                  id="toggle"
                  isEmptyValue={isEmptyString(bannerText.title) || isEmptyString(bannerText.description)}
                  type="checkbox"
                />
                <Title as="h2">
                  <Label
                    htmlFor="toggle"
                    isEmptyValue={isEmptyString(bannerText.title) || isEmptyString(bannerText.description)}
                  >
                    {bannerText.title}
                  </Label>
                </Title>
                {!isEmptyString(bannerText.title) && !isEmptyString(bannerText.description) ? (
                  <ExpandableContent id="expand">
                    <OnlyMobile>
                      <StyledDivider />
                    </OnlyMobile>
                    <Text>{bannerText.description}&nbsp;</Text>
                    <OnlyMobile>
                      <ButtonCtaLegacy buttonTestId="mobile" />
                    </OnlyMobile>
                  </ExpandableContent>
                ) : (
                  <>
                    <Text>{bannerText.description}&nbsp;</Text>
                    <OnlyMobile>
                      <ButtonCtaLegacy buttonTestId="mobile" />
                    </OnlyMobile>
                  </>
                )}
              </StyledGridColumn>
            </StyledInnerGridContainer>
          </Grid.Column>
          <OnlyDesktop>
            <Grid.Column>
              <ButtonCtaLegacy buttonTestId="desktop" />
            </Grid.Column>
          </OnlyDesktop>
        </StyledGridContainer>
      </Container>
      <ExitSurvey show={showExitSurvey} navigateToLegacy={navigateToLegacy} />
      <TellUsSurvey show={showTellUsSurvey} onClose={handleTellUsSurveyClose} />
    </Banner>
  );
};

const Banner = styled(Container)`
  &&& {
    width: 100vw;
    padding: ${props => props.theme.pxToRem(28)} 0;
    background: ${props =>
      `linear-gradient(77deg, ${props.theme.colors.secondaryDarkBlue}, ${props.theme.colors.interfaceBlue})`};
    color: ${props => props.theme.colors.neutralWhite};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

const Title = styled(Heading)`
  margin-top: 0;
  margin-bottom: ${props => props.theme.pxToRem(4)};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const Text = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    margin: ${props => props.theme.pxToRem(20)} 0;
  }

  &&&&:hover {
    background-color: ${props => props.theme.colors.neutralWhite};
    color: ${props => props.theme.colors.secondaryDarkBlue};
    border-color: ${props => props.theme.colors.secondaryDarkBlue};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs} !important;
    margin: 0 !important;
  }
`;

const StyledGridContainer = styled(Grid)`
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const StyledInnerGridContainer = styled(Grid)`
  &&& {
    align-items: center;
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  &&& {
    flex: 1;
  }
`;

const HiddenInput = styled.input<{ isEmptyValue: boolean }>`
  display: none;
  visibility: hidden;

  ${props => props.theme.mediaQueries.mobileOnly} {
    &#toggle:checked ~ #expand {
      height: auto;
      overflow: visible;
    }

    &#toggle:checked ~ h2 > label {
      &::before {
        background-image: ${props => (props.isEmptyValue ? `none` : `url(${ICON_ARROW_UP})`)};
      }
    }
  }
`;

const Label = styled.label<{ isEmptyValue: boolean }>`
  ${props => props.theme.mediaQueries.mobileOnly} {
    &::before {
      position: absolute;
      left: ${props => props.theme.pxToRem(-15)};
      content: '';
      width: ${props => props.theme.pxToRem(18)};
      height: ${props => props.theme.pxToRem(15)};
      background-image: ${props => (props.isEmptyValue ? `none` : `url(${ICON_ARROW_DOWN})`)};
      filter: invert(100%);
      transition: transform 0.3s ease-in-out;
    }
  }
`;

const ExpandableContent = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow: hidden;
    height: 0;
  }
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }
`;
