import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import moment from 'moment-timezone';

import Helmet from 'react-helmet';
import styled from 'styled-components';
import { push } from 'connected-react-router';

import { ZuoraTypes } from 'mxp-schemas';
import { getPath, formatPrice } from 'utils';
import { Routes } from 'constants/index';

import { Container, Link, AicpaLogo, Input, Button, ButtonEnums } from 'components/atoms';
import { ProfileHeading } from 'components/molecules/ProfileHeading/ProfileHeading';
import { ReactComponent as ArrowBack } from 'resources/images/ic-arrow-back.svg';
import { WarningModal } from 'components/admin/molecules/WarningModal/WarningModal';
import { Footer } from 'components/molecules/Footer/Footer';
import { cancelProductPreview, cancelSubscriptionPreview, deprovision, cancelOrder } from 'modules/admin/actions';

const ORDER_NUMBER = 'ORDER_NUMBER';
const ORDER_DATE = 'ORDER_DATE';
const PRICE = 'PRICE';
const DISCOUNT = 'DISCOUNT';

interface CancelPreviewResponse {
  payload: { previewCancelOrder: ZuoraTypes.CancelPreviewDetails };
}

export const CancelPage: React.FC<RouteComponentProps> = ({ location }) => {
  const [isModalOpen, setModalStatus] = useState(false);
  const [zuoraDetails, setZuoraDetails] = useState({ refundAmount: 0, pricePaid: 0, discountApplied: 0 });
  const [isCancelPreviewInProgress, setCancelPreviewInProgress] = useState(false);
  const [isCancellationInProgress, setIsCancellationInProgress] = useState(false);
  const dispatch = useDispatch();

  const {
    sku,
    orderId,
    orderNumber,
    ratePlanId,
    isAutoRenewable,
    isOnlyProductOnSubscription,
    subscriptionStartDate,
    subscriptionNumber,
    zuoraTermStartDate,
  } = location && (location.state as any);

  useEffect(() => {
    setCancelPreviewInProgress(true);
    const cancelPayload = {
      subscriptionNumber,
      subscriptionStartDate,
      isAutoRenewable,
      shouldCancelFromStartDate: true,
      ...(!isOnlyProductOnSubscription && { ratePlanId }),
    };
    if (isOnlyProductOnSubscription) {
      dispatch(cancelSubscriptionPreview(cancelPayload)).then((response: CancelPreviewResponse) => {
        setZuoraDetails(response.payload.previewCancelOrder);
        setCancelPreviewInProgress(false);
      });
    } else {
      dispatch(cancelProductPreview(cancelPayload)).then((response: CancelPreviewResponse) => {
        setZuoraDetails(response.payload.previewCancelOrder);
        setCancelPreviewInProgress(false);
      });
    }
  }, [
    isOnlyProductOnSubscription,
    isAutoRenewable,
    ratePlanId,
    subscriptionNumber,
    subscriptionStartDate,
    zuoraTermStartDate,
    dispatch,
  ]);

  if (!location || !location.state) {
    return <Redirect to={getPath(Routes.PROFILE_PURCHASES)} />;
  }

  const inputs = [
    {
      labelName: 'Order number',
      name: ORDER_NUMBER,
      type: 'text',
      value: orderNumber,
      testId: ORDER_NUMBER,
      labelId: ORDER_NUMBER,
    },
    {
      labelName: 'Order date',
      name: ORDER_DATE,
      type: 'text',
      value: moment(zuoraTermStartDate).format('DD/MM/YYYY'),
      testId: ORDER_DATE,
      labelId: ORDER_DATE,
    },
    {
      labelName: 'Price paid (incl. sales tax)',
      name: PRICE,
      type: 'text',
      value: formatPrice(zuoraDetails.pricePaid),
      testId: PRICE,
      labelId: PRICE,
    },
    {
      labelName: 'Discount applied (incl. sales tax)',
      name: DISCOUNT,
      type: 'text',
      value: formatPrice(zuoraDetails.discountApplied),
      testId: DISCOUNT,
      labelId: DISCOUNT,
    },
  ];

  const toggleModal = () => {
    setModalStatus(!isModalOpen);
  };

  const onConfirmCancellation = async () => {
    setIsCancellationInProgress(true);
    await dispatch(
      cancelOrder({
        subscriptionNumber,
        subscriptionStartDate: zuoraTermStartDate,
        ratePlanId,
        isAutoRenewable,
        isOnlyProductOnSubscription,
      })
    );
    dispatch(deprovision(orderId, sku));
    toggleModal();
    dispatch(push(getPath(Routes.PROFILE_PURCHASES)));
    setIsCancellationInProgress(false);
  };

  if (isCancelPreviewInProgress) return <Loader active content="Loading" />;

  return (
    <StyledContainer>
      <Helmet>
        <title>Cancellation</title>
      </Helmet>
      <StyledHeaderContainer fluid data-testid="header-row-search">
        <Container>
          <Link testId="test-admin-home" to={getPath(Routes.ADMIN_ROOT)}>
            <LogoStyled overrideAnalytics={true} />
          </Link>
        </Container>
      </StyledHeaderContainer>
      <StyledContent>
        <Container>
          <StyledContentContainer>
            <StyledBackLink to={getPath(Routes.PROFILE_PURCHASES)}>
              <ArrowBack /> Back to Purchases
            </StyledBackLink>

            <ProfileHeading title="Cancel product" />
            {inputs.map(input => (
              <StyleDisabledInput
                key={input.name}
                labelName={input.labelName}
                name={input.name}
                type={input.type}
                value={input.value}
                testId={input.testId}
                labelId={input.labelId}
                disabled
              />
            ))}
            <br />
            <StyledButton
              size={ButtonEnums.sizes.medium}
              onClick={toggleModal}
              testId="search-user"
              variant={ButtonEnums.variants.primary}
            >
              Cancel product with ${zuoraDetails.refundAmount} refund
            </StyledButton>
          </StyledContentContainer>
        </Container>
      </StyledContent>
      <Footer />
      <StyledWaringModal
        open={isModalOpen}
        header="Are you sure?"
        testId="cancel-product"
        cancelButtonLabel="No, return to purchases"
        onConfirm={onConfirmCancellation}
        isConfirmationInProgress={isCancellationInProgress}
        confirmButtonLabel="Yes, cancel now"
        description={`This product will be cancelled and a refund of ${formatPrice(
          zuoraDetails.pricePaid
        )} will be issued.`}
        onCancelLink={getPath(Routes.PROFILE_PURCHASES)}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledHeaderContainer = styled(Container)`
  &&& {
    padding-top: ${props => props.theme.pxToRem(13)};
    padding-bottom: ${props => props.theme.pxToRem(13)};
    display: flex;
    align-items: center;
    height: ${props => props.theme.pxToRem(81)};
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey3};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: ${props => props.theme.pxToRem(31)};
    width: 100%;
  }
`;

const LogoStyled = styled(AicpaLogo)`
  cursor: pointer;
`;

const StyledContent = styled.div`
  margin-bottom: 1rem;
`;

const StyledBackLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  text-decoration: none;

  > svg {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    margin-right: ${props => props.theme.pxToRem(4)};

    path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyleDisabledInput = styled(Input)`
  cursor: not-allowed;
  max-width: ${props => props.theme.pxToRem(175)};
  margin-bottom: ${props => props.theme.pxToRem(25)};

  &&&.disabled {
    input {
      border-color: ${props => props.theme.colors.neutralGrey4};
      background-color: ${props => props.theme.colors.neutralGrey2};
    }
  }
`;

const StyledButton = styled(Button)`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(7)};
    margin-bottom: ${props => props.theme.pxToRem(85)};
    padding-left: ${props => props.theme.pxToRem(15)};
    padding-right: ${props => props.theme.pxToRem(15)};
  }
`;

const StyledContentContainer = styled.div`
  h1 {
    margin-top: ${props => props.theme.pxToRem(15)};
  }
  max-width: ${props => props.theme.pxToRem(743)};
`;

const StyledWaringModal = styled(WarningModal)`
  &&&& {
    top: ${props => props.theme.pxToRem(130)};
    width: ${props => props.theme.pxToRem(590)};
    height: ${props => props.theme.pxToRem(330)};
    border-radius: ${props => props.theme.pxToRem(2)};

    .header {
      margin-top: ${props => props.theme.pxToRem(10)};
      h2 {
        margin-top: ${props => props.theme.pxToRem(17)};
      }
    }

    .content {
      margin-top: ${props => props.theme.pxToRem(22)};
    }

    p {
      max-width: 100%;
    }

    .cancel {
      padding: 0;
      margin-right: ${props => props.theme.pxToRem(14)};
    }

    .confirm,
    .cancel {
      width: ${props => props.theme.pxToRem(190)};
      height: ${props => props.theme.pxToRem(34)};
    }
  }
`;
