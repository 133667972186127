import moment from 'moment-timezone';
type DateType = Date | string | number;

export const getDateSpan = (startDate: Date | string | number, endDate: Date | string | number): State.DateSpan => {
  let months;
  const d1 = new Date(startDate);
  const d2 = new Date(endDate);
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  const year = Math.floor(months / 12);
  const month = months % 12;
  return {
    year,
    month,
  };
};

export const dateSpanToWords = (dateSpan: State.DateSpan[]): string => {
  let month = 0;
  let year = 0;
  let res = '';
  dateSpan.forEach(item => {
    month += item.month || 0;
    year += item.year || 0;
  });
  if (month > 12) {
    year += Math.floor(month / 12);
    month = month % 12;
  }
  res = month > 0 ? `${month} month${month > 1 ? 's' : ''}` : '';
  res = year > 0 ? `${year} year${year > 1 ? `s ${res}` : ` ${res}`}` : '';
  return res;
};

export const datesToYearsMonths = (dates: State.Dates[]): string => {
  if (dates && dates.length) {
    let totalMonths = 0;
    dates.forEach((date: State.Dates) => {
      totalMonths += Math.round(moment(date.endDate).diff(date.startDate, 'month', true));
    });
    const months = Math.floor(totalMonths % 12);
    const years = Math.floor(totalMonths / 12);
    const yearsLabel = years ? `${years} ${years > 1 ? 'years' : 'year'}` : '';
    const monthsLabel = months ? `${months} ${months > 1 ? 'months' : 'month'}` : '';

    return totalMonths >= 12 ? `${yearsLabel} ${monthsLabel}` : monthsLabel;
  }

  return '';
};

export const partTimeDatesToYearsMonths = (dates: State.Dates[], numberOfDaysPerWeek: any): string => {
  if (dates && dates.length) {
    let totalMonths = 0;
    dates.forEach((date: State.Dates) => {
      totalMonths += Math.round(moment(date.endDate).diff(date.startDate, 'month', true));
    });
    const partTimeTotalMonths = Math.round((numberOfDaysPerWeek / 5) * totalMonths);
    const months = Math.round(partTimeTotalMonths % 12);
    const years = Math.floor(partTimeTotalMonths / 12);
    const yearsLabel = years ? `${years} ${years > 1 ? 'years' : 'year'}` : '';
    const monthsLabel = months ? `${months} ${months > 1 ? 'months' : 'month'}` : '';

    return partTimeTotalMonths >= 12 ? `${yearsLabel} ${monthsLabel}` : monthsLabel;
  }

  return '';
};

export const formatMonthsToYears = (totalMonths: any): string => {
  const months = Math.floor(totalMonths % 12);
  const years = Math.floor(totalMonths / 12);
  const yearsLabel = years ? `${years} ${years > 1 ? 'years' : 'year'}` : '';
  const monthsLabel = months ? `${months} ${months > 1 ? 'months' : 'month'}` : '';

  return totalMonths >= 12 ? `${yearsLabel} ${monthsLabel}` : monthsLabel;
};

export const examBookingDate = (appointmentDetails: any): string => {
  const { examDateTime, appointmentTimeZone, examScheduleText } = appointmentDetails;
  if (!examDateTime) {
    return '';
  }

  if (appointmentTimeZone) {
    return `${moment.tz(examDateTime, appointmentTimeZone).format('D MMMM, YYYY dddd h:mm A')}`;
  }

  // for Data migrated exam booking
  return moment(examScheduleText, 'DD/MM/YYYY hh:mm').format('D MMMM, YYYY dddd h:mm A');
};

export const formatDateOrEmptyString = (date: string | undefined | null) => {
  return date ? moment(date).format('DD/MM/YYYY') : '';
};

export const calculateDateDiff = (startDate: DateType, endDate: DateType) => {
  let dateDifference = dateSpanToWords([getDateSpan(startDate, endDate)]);
  const monthDifference = moment(endDate).diff(moment(startDate), 'months', true);
  const lengthDifference = moment.duration(moment(endDate).diff(moment(startDate)));
  const numMonth = -lengthDifference.months();
  const numYear = -lengthDifference.years();

  if (startDate && endDate && !dateDifference) {
    dateDifference =
      monthDifference <= 1
        ? `${numYear} year ${numMonth} month`
        : `${numYear} year ${monthDifference.toFixed()} months`;
  }
  return dateDifference;
};
