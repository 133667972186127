import React from 'react';
import styled from 'styled-components';
import { Grid, Flex, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { Contentful } from 'mxp-schemas';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}

export const BlockStats: React.FC<Props> = ({ modularBlock, headingType }) => {
  const block = modularBlock as Contentful.ModularContent.StatsBlock;
  return (
    <>
      <OnlyDesktop>
        <MainColumn bgcolor={block.bgColor || ''}>
          <Flex>
            {block.stat1LargeText && (
              <StyledSuperLargeText as={headingType} dangerouslySetInnerHTML={{ __html: block.stat1LargeText }} />
            )}
            <StyledGrid>
              <Grid.Column>
                {block.stat2LargeText && (
                  <StyledLargeText as={headingType} dangerouslySetInnerHTML={{ __html: block.stat2LargeText }} />
                )}
                {block.stat2SmallText && (
                  <StyledSmallText as={headingType} dangerouslySetInnerHTML={{ __html: block.stat2SmallText }} />
                )}
              </Grid.Column>
            </StyledGrid>
            <StyledGrid>
              <Grid.Column>
                {block.stat3LargeText && (
                  <StyledLargeText as={headingType} dangerouslySetInnerHTML={{ __html: block.stat3LargeText }} />
                )}
                {block.stat3SmallText && (
                  <StyledSmallText as={headingType} dangerouslySetInnerHTML={{ __html: block.stat3SmallText }} />
                )}
              </Grid.Column>
            </StyledGrid>
            <StyledGrid>
              <Grid.Column>
                {block.stat4LargeText && (
                  <StyledLargeText as={headingType} dangerouslySetInnerHTML={{ __html: block.stat4LargeText }} />
                )}
                {block.stat4SmallText && (
                  <StyledSmallText as={headingType} dangerouslySetInnerHTML={{ __html: block.stat4SmallText }} />
                )}
              </Grid.Column>
            </StyledGrid>
          </Flex>
        </MainColumn>
      </OnlyDesktop>
      <OnlyMobile>
        <MainColumnMobile bgcolor={block.bgColor || ''}>
          {block.stat1LargeText && (
            <StyledSuperLargeText1 as={headingType} dangerouslySetInnerHTML={{ __html: block.stat1LargeText }} />
          )}
          <Grid.Column>
            <StyledGrid>
              {block.stat2LargeText && (
                <StyledLargeText1 as={headingType} dangerouslySetInnerHTML={{ __html: block.stat2LargeText }} />
              )}
              {block.stat2SmallText && (
                <StyledSmallText1 as={headingType} dangerouslySetInnerHTML={{ __html: block.stat2SmallText }} />
              )}
            </StyledGrid>
            <StyledGrid>
              {block.stat3LargeText && (
                <StyledLargeText2 as={headingType} dangerouslySetInnerHTML={{ __html: block.stat3LargeText }} />
              )}
              {block.stat3SmallText && (
                <StyledSmallText2 as={headingType} dangerouslySetInnerHTML={{ __html: block.stat3SmallText }} />
              )}
            </StyledGrid>
            <StyledGrid>
              {block.stat4LargeText && (
                <StyledLargeText3 as={headingType} dangerouslySetInnerHTML={{ __html: block.stat4LargeText }} />
              )}
              {block.stat4SmallText && (
                <StyledSmallText3 as={headingType} dangerouslySetInnerHTML={{ __html: block.stat4SmallText }} />
              )}
            </StyledGrid>
          </Grid.Column>
        </MainColumnMobile>
      </OnlyMobile>
    </>
  );
};

const MainColumn = styled.div<{ bgcolor?: string }>`
  width: 100% !important;
  height: ${props => props.theme.pxToRem(175)} !important;
  margin-inline: auto;
  padding-top: ${props => props.theme.pxToRem(30)} !important;
  background-color: ${props => props.bgcolor || '#f4f5f8'};
`;
const MainColumnMobile = styled.div<{ bgcolor?: string }>`
  text-align: center
  align-items: center;
  padding: auto !important;
  margin: auto;
  margin-inline: auto;     
  margin: ${props => props.theme.pxToRem(70)} ${props => props.theme.pxToRem(1)} ${props =>
  props.theme.pxToRem(100)}  !important;;
  padding: ${props => props.theme.pxToRem(66)} ${props => props.theme.pxToRem(12)} ${props =>
  props.theme.pxToRem(61)} ${props => props.theme.pxToRem(13)}  !important;
  width: auto;
  height: ${props => props.theme.pxToRem(647)} !important;
  background-color: ${props => props.bgcolor || '#f4f5f8'};

`;

const StyledSuperLargeText = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  padding-top: ${props => props.theme.pxToRem(15)} !important;
  height: ${props => props.theme.pxToRem(80)} !important;
  margin: auto;
  p {
    margin-left: ${props => props.theme.pxToRem(90)};
    width: ${props => props.theme.pxToRem(243)};
  }
`;

const StyledLargeText = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  margin: auto;
  p {
    margin-left: ${props => props.theme.pxToRem(10)} !important;
    margin-right: ${props => props.theme.pxToRem(40)} !important;
  }
`;
const StyledSmallText = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  margin: auto;
  p {
    margin-left: ${props => props.theme.pxToRem(10)} !important;
    margin-right: ${props => props.theme.pxToRem(20)} !important;
  }
`;
const StyledSuperLargeText1 = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-inline: auto;
  margin: ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(60)} !important;
`;
const StyledLargeText1 = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-bottom: ${props => props.theme.pxToRem(-30)} !important;
  border-top: ${props => props.theme.pxToRem(1)} solid lightgrey;
  p {
    margin: ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(50)} ${props => props.theme.pxToRem(8)} !important;
    object-fit: contain;
  }
`;

const StyledSmallText1 = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  text-align: center;
  p {
    padding-top: ${props => props.theme.pxToRem(4)} !important;
    padding-bottom: ${props => props.theme.pxToRem(15)} !important;
    margin-right: ${props => props.theme.pxToRem(22)} !important;
    margin-left: ${props => props.theme.pxToRem(22)} !important;
  }
`;
const StyledLargeText2 = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  border-top: ${props => props.theme.pxToRem(1)} solid lightgrey;
  p {
    margin: ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(91)} ${props => props.theme.pxToRem(8)} !important;
    object-fit: contain !important;
  }
`;
const StyledSmallText2 = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  p {
    margin: ${props => props.theme.pxToRem(-24)} ${props => props.theme.pxToRem(50)} ${props => props.theme.pxToRem(38)} !important;
  }
`;
const StyledLargeText3 = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  border-top: ${props => props.theme.pxToRem(1)} solid lightgrey;
  p {
    margin: ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(91)}
      ${props => props.theme.pxToRem(8)}!important;
    object-fit: contain !important;
  }
`;
const StyledSmallText3 = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  p {
    margin: ${props => props.theme.pxToRem(-24)} ${props => props.theme.pxToRem(50)} 0 !important;
  }
`;

const StyledGrid = styled.div`
  ${props => props.theme.mediaQueries.desktopOnly} {
    border-left: ${props => props.theme.pxToRem(1)} solid lightgrey;
    width: ${props => props.theme.pxToRem(350)};
    height: ${props => props.theme.pxToRem(90)};
    margin: auto;
  }
`;
