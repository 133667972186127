// ------------------------------------
// Constants
// ------------------------------------
import { Product } from 'mxp-schemas';

export enum productActionNames {
  ACCESS_PROVISIONED_PRODUCT = 'products/ACCESS_PROVISIONED_PRODUCT',
  GET_PRODUCT_ITEM = 'products/GET_PRODUCT_ITEM',
  GET_PRODUCTS_LIST_DATA = 'products/GET_PRODUCTS_LIST_DATA',
  GET_RELATED_CONTENT = 'products/GET_RELATED_CONTENT',
  GET_PREMIUM_CONTENT_ITEMS = 'products/GET_PREMIUM_CONTENT_ITEMS',
  CANCEL_STANDING_ORDER = 'products/CANCEL_STANDING_ORDER',
  RESET_MODULE = 'products/RESET_MODULE',
  GET_CONTRIBUTIONS = 'products/GET_CONTRIBUTIONS',
  GET_MEMBERSHIPS = 'products/GET_MEMBERSHIPS',
  GET_WEBCAST_PASS = 'products/GET_WEBCAST_PASS',
  GET_CONTENTFUL_PRODUCT = 'products/GET_CONTENTFUL_PRODUCT',
  UPDATE_PREFERRED_CURRENCY = 'products/UPDATE_PREFERRED_CURRENCY',
  GET_PREFERRED_CURRENCY = 'products/GET_PREFERRED_CURRENCY',
  UPDATE_CANCELLED_PRODUCT = 'products/UPDATE_CANCELLED_PRODUCT',
  SET_CLAIM_GIFT_AID = 'products/SET_CLAIM_GIFT_AID',
  GET_PRODUCT_BY_SKU = 'products/GET_PRODUCT_BY_SKU',
}

export const initialState: State.Products = {
  isProductFetched: false,
  isRelatedProductFetched: false,
  isProductViewTriggered: false,
  selectedDonationPrice: '',
  selectedItemSKUs: [],
  productItem: null,
  relatedContent: null,
  productsListData: null,
  productListByTypes: {
    memberships: [],
    sections: [],
    credentials: [],
    otherProducts: [],
  },
  error: null,
  productsListDataFetched: false,
  premiumContentItems: [],
  isFreeTrialSelected: false,
  contributions: [],
  contributionsFetched: false,
  contributionsFetchSuccess: false,
  claimGiftAid: false,
  memberships: [],
  membershipsLoading: false,
  membershipsLoaded: false,
  webcastPass: null,
  isWebcastPassFetched: false,
  benefitsAccessLinks: null,
  currency: {
    label: Product.ProductCurrencyLabel.USD,
    sign: Product.ProductCurrencySign.DOLLAR, // '£',
  },
  hasExistingZuoraPurchase: false,
  entities: {
    association: '',
    cima: '',
  },
  isCurrencyToggledByDropdown: false,
  fetchedProductBySku: null,
};
