import { MembershipAddOnsProductSkus, MembershipRegularTypeSkus } from 'modules/membership/constants';
import { User } from 'mxp-schemas';
import { Product as ProductUtils } from 'mxp-utils';
import { formatPrice, fromCentsFormat } from 'utils';

export const membershipTypeSKUTitles = (slug: string, membershipTypes: any) => {
  return membershipTypes.find((type: any) => slug === type.slug)?.name;
};

export const allUserChoiceSections = (
  hasSelectedSection: boolean,
  userChoiceSections: any,
  userChoicesFreeSection: any,
  sectionsList: State.SectionWithPriceProduct[],
  currency: State.ProductCurrency
) => {
  if (hasSelectedSection) {
    const userSections: any[] = userChoiceSections.concat(userChoicesFreeSection);

    return userSections?.map(item => {
      const sku = item?.sku;
      const productId = item?.productId;
      const sectionDetails = sectionsList.filter((section: any) => section.productId === productId);
      const price =
        ProductUtils.getProductPrice(
          sectionDetails?.[0],
          sku || sectionDetails?.[0].variants[0].sku || '',
          [User.MembershipIdsEnum.MRUSR0001],
          currency.label
        )?.priceFinal?.amount ?? 0;

      const isIncluded = userChoicesFreeSection?.some((section: any) => section.productId === productId);
      return {
        name: sectionDetails?.[0]?.name,
        value: formatPrice(fromCentsFormat(price), currency.label),
        status: 'Active',
        isIncluded,
      };
    });
  }
  return undefined;
};

export const shouldAddOnProductBeIncluded = (tierSku: string, productSku: string | undefined): boolean => {
  if (!tierSku || !productSku) return false;

  const tierToSkuMapping: Record<string, string[]> = {
    [MembershipRegularTypeSkus.ESSENTIAL]: [MembershipAddOnsProductSkus.CPEXPRESS],
    [MembershipRegularTypeSkus.SELECT]: [MembershipAddOnsProductSkus.ANNUAL_WEBCAST_PASS],
    [MembershipRegularTypeSkus.PARTNER_SPECIALIST]: [MembershipAddOnsProductSkus.ANNUAL_WEBCAST_PASS],
    [MembershipRegularTypeSkus.PARTNER_ADVANCE]: [MembershipAddOnsProductSkus.ANNUAL_WEBCAST_PASS],
    [MembershipRegularTypeSkus.CORE]: [],
    [MembershipRegularTypeSkus.PARTNER_CORE]: [],
    [MembershipRegularTypeSkus.LEAD]: [MembershipAddOnsProductSkus.ANNUAL_WEBCAST_PASS],
  };

  return tierToSkuMapping?.[tierSku]?.includes(productSku);
};
