// Each table pagination and controls meta stored to redux separately
export enum FirmAdminTableType {
  MANAGE_FIRM_ROSTER = 'manageFirmRoster',
  MANAGE_FIRM_BILLING = 'manageFirmBilling',
  MEMBERS_INVITES = 'membersInvites',
  UPGRADABLE_MEMBERS = 'upgradableMembers',
  MEMBERSHIPS_AND_ADDONS = 'membershipsAndAddons',
  FIRM_BILLING_INVOICES = 'firmBillingInvoices',
  FIRM_BILLING_INVOICES_CIMA = 'firmBillingInvoicesCima',
  FLP_ORGANIZATION_BILLING = 'flpOrganizationBilling',
  FLP_ORGANIZATION_ROSTER = 'flpOrganizationRoster',
  FLP_ADD_SUBSCRIPTION = 'flpAddSubscription',
  FLP_UPGRADE_SUBSCRIPTION = 'flpUpgradeSubscription',
  FLP_UPGRADE_SUBSCRIPTION_MODAL = 'flpUpgradeSubscriptionModal',
  FLP_ORGANIZATION_ADDONS = 'flpOrganizationAddons',
  CIMA_ORGANIZATION_ROSTER = 'cimaOrganizationRoster',
  CIMA_ORGANIZATION_BILLING = 'cimaOrganizationBilling',
}

const FirmBillingHeaders = [
  { title: 'Name' },
  { title: 'Account ID' },
  { title: 'Type' },
  { title: 'Status' },
  { title: 'Email Address' },
  { title: 'Position' },
  { title: 'Country' },
  { title: 'State' },
  { title: 'City' },
  { title: 'Office location' },
];

const FirmRosterBillingHeaders = [
  { title: 'Name' },
  { title: 'Account ID' },
  { title: 'Type' },
  { title: 'Email Address' },
  { title: 'Position' },
  { title: 'Country' },
  { title: 'State' },
  { title: 'City' },
  { title: 'Office location' },
];

const FlpFirmRosterBillingHeaders = [
  { title: 'Name' },
  { title: 'Account ID' },
  { title: 'Type' },
  { title: 'Duration' },
  { title: 'Status' },
  { title: 'Email Address' },
  { title: 'Position' },
  { title: 'Location' },
  { title: 'City' },
  { title: 'Office location' },
];

const CimaOrganizationRosterHeaders = [
  { title: 'Name' },
  { title: 'Account ID' },
  { title: 'Type' },
  { title: 'Email Address' },
  { title: 'Position' },
  { title: 'Location' },
  { title: 'City' },
  { title: 'Office location' },
];

const CimaOrganizationBillingHeaders = [
  { title: 'Name' },
  { title: 'Account ID' },
  { title: 'Type' },
  { title: 'Status' },
  { title: 'Email Address' },
  { title: 'Position' },
  { title: 'Location' },
  { title: 'City' },
  { title: 'Office location' },
];

const AicpaFlpOrganizationRoster = [
  { title: 'Name' },
  { title: 'Account ID' },
  { title: 'Type' },
  { title: 'Duration' },
  { title: 'Email Address' },
  { title: 'Position' },
  { title: 'Country' },
  { title: 'State' },
  { title: 'City' },
  { title: 'Office location' },
];

const FlpFirmRosterHeaders = [
  { title: 'Name' },
  { title: 'Account ID' },
  { title: 'Type' },
  { title: 'Duration' },
  { title: 'Email Address' },
  { title: 'Position' },
  { title: 'Location' },
  { title: 'City' },
  { title: 'Office location' },
];

export const TableHeaders = (
  isCima: boolean,
  isFlp?: boolean
): {
  [key in FirmAdminTableType]: FirmAdmin.DataTableHeader[];
} => ({
  [FirmAdminTableType.MANAGE_FIRM_ROSTER]: isFlp ? AicpaFlpOrganizationRoster : FirmRosterBillingHeaders,
  [FirmAdminTableType.MANAGE_FIRM_BILLING]: FirmBillingHeaders,
  [FirmAdminTableType.FLP_ORGANIZATION_BILLING]: FlpFirmRosterBillingHeaders,
  [FirmAdminTableType.FLP_ORGANIZATION_ROSTER]: FlpFirmRosterHeaders,
  [FirmAdminTableType.CIMA_ORGANIZATION_ROSTER]: CimaOrganizationRosterHeaders,
  [FirmAdminTableType.CIMA_ORGANIZATION_BILLING]: CimaOrganizationBillingHeaders,
  [FirmAdminTableType.MEMBERS_INVITES]: [
    { title: 'Name' },
    { title: 'Email address' },
    { title: `Paid by ${isCima ? 'organization' : 'firm'}?` },
    { title: 'Position' },
    { title: 'Type' },
    { title: 'Tier' },
    { title: 'Date initiated' },
    { title: 'Status' },
  ],
  [FirmAdminTableType.UPGRADABLE_MEMBERS]: [
    { title: 'Name' },
    { title: 'Email address' },
    { title: `Paid by ${isCima ? 'organization' : 'firm'}?` },
    { title: 'Position' },
    { title: 'Membership Type' },
    { title: 'Membership Tier' },
  ],
  [FirmAdminTableType.MEMBERSHIPS_AND_ADDONS]: [
    { title: 'Name' },
    { title: 'Account ID' },
    { title: 'Type' },
    { title: 'Position' },
    { title: 'Tier' },
    { title: 'Expiry date' },
    { title: 'Status' },
    { title: 'Add-ons' },
    { title: 'Cost' },
    { title: 'Quote / Invoice' },
  ],
  [FirmAdminTableType.FIRM_BILLING_INVOICES]: [
    { title: 'Invoice Date' },
    { title: 'Total Price' },
    { title: 'Invoice #' },
    { title: 'Invoice Type' },
    { title: 'Branch' },
    { title: 'Status' },
    { title: 'Due Date' },
    { title: '' },
    { title: ' ' },
  ],
  [FirmAdminTableType.FIRM_BILLING_INVOICES_CIMA]: [
    { title: 'Invoice Date' },
    { title: 'Total Price' },
    { title: 'Invoice #' },
    { title: 'Invoice Type' },
    { title: 'Location' },
    { title: 'Status' },
    { title: 'Order #' },
    { title: 'Due Date' },
    { title: '' },
    { title: ' ' },
  ],
  [FirmAdminTableType.FLP_ORGANIZATION_BILLING]: [
    { title: 'Name' },
    { title: 'Account ID' },
    { title: 'Type' },
    { title: 'Duration' },
    { title: 'Status' },
    { title: 'Email Address' },
    { title: 'Position' },
    { title: 'Location' },
    { title: 'City' },
    { title: 'Office location' },
  ],
  [FirmAdminTableType.FLP_ADD_SUBSCRIPTION]: [
    { title: 'Name' },
    { title: 'Email Address' },
    { title: `Paid by ${isCima ? 'Organization' : 'Firm'}?` },
    { title: 'FLP subscription type' },
    { title: 'Duration' },
    { title: 'Date Initiated' },
    { title: 'Status' },
  ],
  [FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION]: [
    { title: 'Name' },
    { title: 'Email Address' },
    { title: `Paid by ${isCima ? 'Organization' : 'Firm'}?` },
    { title: 'FLP subscription type' },
    { title: 'Duration' },
    { title: 'Date Initiated' },
  ],
  [FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION_MODAL]: [
    { title: 'Name' },
    { title: 'Email Address' },
    { title: `Paid by ${isCima ? 'Organization' : 'Firm'}?` },
    { title: 'FLP subscription type' },
    { title: 'Duration' },
  ],
  [FirmAdminTableType.FLP_ORGANIZATION_ADDONS]: [
    { title: 'Name' },
    { title: 'Account ID' },
    { title: 'Product type' },
    { title: 'Duration' },
    { title: 'Expiry date' },
    { title: 'Status' },
    { title: 'Add-ons' },
    { title: 'Cost' },
    { title: 'Invoice' },
  ],
});

export enum Stage {
  Confirmation,
  ChangesSuccess,
  RemovalSuccess,
  Fail,
}

export enum ActionResult {
  Success,
  Fail,
}

export enum PersonalAddonsModalTabs {
  ALL = 'All',
  SECTIONS = 'Sections',
  CREDENTIALS = 'Credentials',
  OTHER = 'Other',
}

export enum BulkAddonsModalActionsTypes {
  AddProduct = 'Add new',
  RenewProduct = 'Include in Firm Billing',
  RemoveProduct = 'Remove from Firm Billing/ Delete',
}

export enum FirmBillingMembershipActionTypes {
  UPGRADE_MEMBERSHIP = 'Upgrade Memberships',
  ADD_NEW_MEMBERS = 'Add New Members',
  ADD_NEW_FLP_SUBSCRIPTION = 'Add new FLP subscription',
}

export enum FirmAdminTableState {
  Downloading = 'Downloading members data...',
}

export enum MembershipEnrollmentProductNames {
  CIMA_MEMBERSHIP_ENROLLMENT_FEE = 'CIMA Membership Enrollment Fee',
  AICPA_MEMBERSHIP_ENROLLMENT_FEE = 'AICPA Membership Enrollment Fee',
}
