import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { theme } from 'theme';
import { Icon, Grid } from 'semantic-ui-react';
import { Product as ProductUtils } from 'mxp-utils';
import { User } from 'mxp-schemas';
import { productCurrencySelector } from 'modules/products/selectors';
import { membershipSectionRootSelector } from 'modules/membership/selectors';
import { setHasSelectedCredential, setHasSelectedSection } from 'modules/membership/actions';
import { Button, ButtonEnums } from 'components/atoms';
import { Info } from 'components/atoms/svg';
import { RenewMembershipOptions } from 'constants/index';
import { AddNewAddons } from './AddNewAddons';
import { AddNewCredentials } from './AddNewCredentials';
import { AddNewSections } from './AddNewSections';

interface IAddNewMembershipProductProps {
  type: RenewMembershipOptions;
  isLoading: boolean;
  list: State.CredentialProducts[] | State.AddOnProducts[] | State.SectionWithPriceProduct[] | [];
  isShowList?: boolean;
  onClickHandler: () => void;
  updateCollapsibleStatus: () => void;
  isRenewMemberShipCardActive: boolean;
}

export const AddNewMembershipProduct: React.FC<IAddNewMembershipProductProps> = ({
  type,
  isLoading,
  list,
  isShowList,
  onClickHandler,
  updateCollapsibleStatus,
  isRenewMemberShipCardActive,
}) => {
  const dispatch = useDispatch();
  const currency = useSelector(productCurrencySelector);
  const { listOfFreeProduct: sectionsListOfFreeProduct } = useSelector(membershipSectionRootSelector);

  const [showList, setShowList] = useState(isShowList);
  const { noExistingText, addNewButtonText } = getAddNewMembershipProductParams(type);

  const handleAddNewClick = useCallback(() => {
    updateCollapsibleStatus();
    onClickHandler();
    setShowList(true);
  }, [updateCollapsibleStatus, onClickHandler]);

  const variantsPriceInfoForUser = useCallback(
    (data: State.ProductCredential) => {
      const priceInfoForUser = ProductUtils.getProductPrice(
        data,
        data.variants[0].sku ?? '',
        [User.MembershipIdsEnum.MRUSR0001],
        currency.label
      );
      return priceInfoForUser.priceFinal?.amount ?? 0;
    },
    [currency.label]
  );

  if (isRenewMemberShipCardActive) {
    switch (type) {
      case RenewMembershipOptions.MEMBERSHIP_CREDENTIALS:
        return (
          <AddNewCredentials
            list={list}
            buttonGroup={
              <ButtonGroup
                dispatch={dispatch}
                updateCollapsibleStatus={updateCollapsibleStatus}
                setShowList={setShowList}
                type={type}
              />
            }
            currency={currency}
            dispatch={dispatch}
            variantsPriceInfoForUser={variantsPriceInfoForUser}
          />
        );

      case RenewMembershipOptions.ADD_ONS:
        return (
          <AddNewAddons
            list={list}
            currency={currency}
            dispatch={dispatch}
            variantsPriceInfoForUser={variantsPriceInfoForUser}
          />
        );

      case RenewMembershipOptions.MEMBERSHIP_SECTIONS:
        return (
          <AddNewSections
            list={list}
            freeList={sectionsListOfFreeProduct}
            currency={currency}
            dispatch={dispatch}
            variantsPriceInfoForUser={variantsPriceInfoForUser}
            buttonGroup={
              <ButtonGroup
                dispatch={dispatch}
                updateCollapsibleStatus={updateCollapsibleStatus}
                setShowList={setShowList}
                type={type}
              />
            }
          />
        );
    }
  }

  return (
    <Container>
      {!(showList && isLoading) && <NoExistingText>{noExistingText}</NoExistingText>}
      <AddNewBtn
        testId="add-a-new-membership-product-btn"
        icon={<Icon name="plus" size="small" />}
        iconPosition={ButtonEnums.iconPosition.left}
        onClick={handleAddNewClick}
        variant={ButtonEnums.variants.secondary}
        size={ButtonEnums.sizes.small}
        loading={isLoading}
      >
        {addNewButtonText}
      </AddNewBtn>
    </Container>
  );
};

export interface AddNewComponentProps {
  list: IAddNewMembershipProductProps['list'];
  freeList?: IAddNewMembershipProductProps['list'];
  currency: State.ProductCurrency;
  dispatch: Dispatch;
  variantsPriceInfoForUser: (data: State.ProductCredential) => number;
  buttonGroup?: JSX.Element;
}

export interface HandleAddToCartProps {
  productId: string;
  isSelected: boolean;
  sku?: string;
  isCGMA?: boolean;
  cgmaVariant?: State.Variant | null;
  credentialKey?: string;
}

const ButtonGroup = ({ updateCollapsibleStatus, setShowList, dispatch, type }: any) => {
  const handleContinue = useCallback(() => {
    updateCollapsibleStatus();
    setShowList(false);

    if (type === RenewMembershipOptions.MEMBERSHIP_CREDENTIALS) {
      dispatch(setHasSelectedCredential(true));
    }
    if (type === RenewMembershipOptions.MEMBERSHIP_SECTIONS) {
      dispatch(setHasSelectedSection(true));
    }
  }, [dispatch, updateCollapsibleStatus, setShowList, type]);

  const handleBack = useCallback(() => {
    if (type === RenewMembershipOptions.MEMBERSHIP_SECTIONS) {
      updateCollapsibleStatus();
      setShowList(false);
    }
  }, [updateCollapsibleStatus, setShowList, type]);
  return (
    <StyledButtonGroup>
      <Button
        testId="add-a-new-membership-product-continue"
        onClick={handleContinue}
        variant={ButtonEnums.variants.primary}
        size={ButtonEnums.sizes.medium}
      >
        Continue
      </Button>
      <Button
        testId="add-a-new-membership-product-back"
        onClick={handleBack}
        variant={ButtonEnums.variants.secondary}
        size={ButtonEnums.sizes.medium}
      >
        Go back
      </Button>
    </StyledButtonGroup>
  );
};

const getAddNewMembershipProductParams = (type: RenewMembershipOptions) => {
  switch (type) {
    case RenewMembershipOptions.MEMBERSHIP_CREDENTIALS:
      return {
        noExistingText: 'Looks like you don’t have any credentials selected yet',
        addNewButtonText: 'Add a new credential',
      };

    case RenewMembershipOptions.ADD_ONS:
      return {
        noExistingText: (
          <>
            Customise your membership further by selecting additional add-ons if desired.
            <InfoIcon color={theme.colors.neutralWhite} />
          </>
        ),
        addNewButtonText: 'Add a new add-on',
      };

    case RenewMembershipOptions.MEMBERSHIP_SECTIONS:
      return {
        noExistingText: (
          <StyledParagraph>
            Access a network of experts and resources tailored to your professional knowledge and growth.
          </StyledParagraph>
        ),
        addNewButtonText: 'Add a new Section',
      };

    default:
      return { noExistingText: '', addNewButtonText: '' };
  }
};

const Container = styled.div`
  padding-top: ${props => props.theme.pxToRem(24)};
  padding-bottom: ${props => props.theme.pxToRem(31)};
  padding-left: ${props => props.theme.pxToRem(10)};
  padding-right: ${props => props.theme.pxToRem(45)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddNewBtn = styled(Button)`
  &&&& {
    padding: ${props => `${props.theme.pxToRem(5)} ${props.theme.pxToRem(24)}`};
  }
`;

export const StyledGrid = styled(Grid)`
  &&&& {
    margin: 0;
  }
`;

export const StyledGridColumn = styled(Grid.Column)`
  min-width: ${props => props.theme.pxToRem(320)};
  max-width: ${props => props.theme.pxToRem(350)};
`;

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.pxToRem(22)};
  padding: ${props => props.theme.pxToRem(24)};

  &&&& > button {
    padding: ${props => `${props.theme.pxToRem(5)} ${props.theme.pxToRem(54)}`};
  }
`;

const NoExistingText = styled.p`
  text-align: center;
`;

const InfoIcon = styled(Info)`
  border-radius: 50%;
  background-color: ${theme.colors.primaryPurple};
  vertical-align: text-bottom;
  margin-left: ${theme.pxToRem(5)};
`;

const StyledParagraph = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
`;
