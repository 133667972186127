import React, { useState, useCallback } from 'react';
import { AccordionTitleProps } from 'semantic-ui-react';
import { CimaExamResultsSections } from 'components/molecules/CimaExamResultsSections';
import { User } from 'mxp-schemas';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
interface Props {
  data: User.StudentExamSectionResults;
  isAllExams?: boolean;
}

export const CimaExamResultsSectionPanel: React.FC<Props> = React.memo(({ data, isAllExams }) => {
  const [activeIndices, setActiveIndices] = useState(
    data.examSectionResults
      ? data.examSectionResults?.map((exam, index: number) => ({
          stepNumber: index,
          isOpen: false,
        }))
      : [
          {
            stepNumber: -1,
            isOpen: false,
          },
        ]
  );

  const { windowPathname } = getPageNameAndSiteSection();

  const handleAccordionClick = useCallback(
    (e, { index }: AccordionTitleProps) => {
      const title = e?.target?.textContent || index;
      if (index !== undefined) {
        setActiveIndices(currData => {
          return currData?.map(currentIndex => {
            const newActiveIndex = {
              stepNumber: currentIndex.stepNumber,
              isOpen: currentIndex.isOpen,
            };

            if (currentIndex.stepNumber === +index) {
              newActiveIndex.isOpen = !currentIndex.isOpen;

              if (currentIndex.isOpen) {
                handleEvent({ clickValue: `button:link:int::ACCORDION-CLOSE:${title}:${windowPathname}` }, NAV_CLICK);
              } else {
                handleEvent({ clickValue: `button:link:int::ACCORDION-OPEN:${title}:${windowPathname}` }, NAV_CLICK);
              }
            }

            return newActiveIndex;
          });
        });
      }
    },
    [windowPathname]
  );

  if (!data) return <></>;

  return (
    <CimaExamResultsSections
      data={data}
      activeIndices={activeIndices}
      onClick={handleAccordionClick}
      isAllExams={isAllExams}
    />
  );
});
