import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import {
  CREATE_AML_ATTESTATION,
  CREATE_MIP_ATTESTATION,
  MUTATE_SEND_ORDER_CONFIRMATION_MIP,
  CREATE_PRACTICE_HISTORY,
  GET_WORK_HISTORY,
  GET_MIP_PRACTICE_DETAILS,
  UPDATE_MIP_AML,
  QUERY_MIP_AML_ATTESTATION,
  QUERY_MIP_ATTESTATION,
  QUERY_MIP_CONTACT,
  UPDATE_PRACTICE_REQUIREMENT,
  CREATE_WORK_HISTORY,
  GET_RENEWAL_SEASON_INFO,
  DELETE_MIP_RECORDS,
} from 'mxp-graphql-queries';
import { mipState, mipStateRenewal } from './constants';
import { default as request } from 'utils/GraphQLClient';

export const createAmlAttestation: any = createAction('createAmlAttestation/CREATE_AML_ATTESTATION', (aml: any) => {
  return request(CREATE_AML_ATTESTATION, { aml });
});

export const createMipAttestation: any = createAction('createMipAttestation/CREATE_MIP_ATTESTATION', (mip: any) => {
  return request(CREATE_MIP_ATTESTATION, { mip });
});

export const sendMipOrderConfirmationMail: any = createAction(
  'sendMipOrderConfirmationMail/MUTATE_SEND_ORDER_CONFIRMATION_MIP',
  (payload: any) => {
    return request(MUTATE_SEND_ORDER_CONFIRMATION_MIP, { payload });
  }
);

export const createPracticeRequirements: any = createAction(
  'createPracticeRequirements/CREATE_PRACTICE_HISTORY',
  (practiceRequirement: any) => {
    return request(CREATE_PRACTICE_HISTORY, { practiceRequirement });
  }
);

export const getMipPracticeRequirementsLoading: any = createAction(
  'getMipPracticeRequirements/GET_MIP_PRACTICE_DETAILS_LOADING'
);

export const getMipPracticeRequirements: any = createAction(
  'getMipPracticeRequirements/GET_MIP_PRACTICE_DETAILS',
  () => async (dispatch: Dispatch) => {
    return request(GET_MIP_PRACTICE_DETAILS, {}).then((response: any) => {
      const mipMapping = response?.getMipDetails?.map((details: any) => {
        return {
          id: details.Id,
          name: details.Name,
          personAccountKey: details.SFS_MPD_PersonAccountKey__c,
          website: details.SFS_MPD_Website__c,
          totalOfStaff: details.SFS_MPD_TotalNumberOfStaff__c,
          primaryPractice: details.SFS_MPD_SetPrimaryPractice__c,
          practiceName: details.SFS_MPD_PrimaryPracticeName__c,
          bodyName: details.SFS_MPD_PreofessionalBodyName__c,
          functionalSpecialism: details.SFS_MPD_FunctionalSpecialism__c,
          brokerInsurerName: details.SFS_MPD_BrokerInsurerName__c,
          policyNumber: details.SFS_MPD_PolicyNumber__c,
          expiryDate: details.SFS_MPD_ExpiryDate__c,
          fax: details.SFS_MPD_Fax__c?.split('-'),
          nominatedPerson: details.SFS_MPD_NominatedPerson__c,
          isCompleteContact: details.SFS_MPD_ContactComplete__c,
          isCompletePracticeRequirement: details.SFS_MPD_PracticeComplete__c,
          continuityLastName: details.SFS_MPD_LastName__c,
          continuityFirstName: details.SFS_MPD_FirstName__c,
          continuityPracticeName: details.SFS_MPD_ConPracticeName__c,
          continuityMobileNumber: details.SFS_MPD_MobileNumber__c,
          continuityFax: details.SFS_MPD_ContinuityFax__c,
          continuityAddressLine1: details.SFS_MPD_AddressLine1__c,
          continuityAddressLine2: details.SFS_MPD_AddressLine2__c,
          continuityAddressLine3: details.SFS_MPD_AddressLine3__c,
          continuityCity: details.SFS_MPD_City__c,
          continuityCountryState: details.SFS_MPD_CountryState__c,
          continuityPostalCode: details.SFS_MPD_PostalCode__c,
          continuityLocation: details.SFS_MPD_Location__c,
          continuityFaxAreaCode: details.SFS_MPD_Fax_AreaCode__c,
          continuityMobileAreaCode: details.SFS_MPD_Mobile_AreaCode__c,
          createdDate: details.CreatedDate,
        };
      });
      return mipMapping;
    });
  }
);

export const getAllPractice: any = createAction(
  'getMipPracticeRequirements/GET_MIP_PRACTICE_DETAILS',
  () => async (dispatch: Dispatch) => {
    return request(GET_MIP_PRACTICE_DETAILS, {}).then((response: any) => {
      const mipMapping = response.getMipDetails.map((details: any) => {
        return {
          id: details.Id,
          name: details.Name,
          personAccountKey: details.SFS_MPD_PersonAccountKey__c,
          website: details.SFS_MPD_Website__c,
          totalOfStaff: details.SFS_MPD_TotalNumberOfStaff__c,
          primaryPractice: details.SFS_MPD_SetPrimaryPractice__c,
          practiceName: details.SFS_MPD_PrimaryPracticeName__c,
          bodyName: details.SFS_MPD_PreofessionalBodyName__c,
          functionalSpecialism: details.SFS_MPD_FunctionalSpecialism__c,
          brokerInsurerName: details.SFS_MPD_BrokerInsurerName__c,
          policyNumber: details.SFS_MPD_PolicyNumber__c,
          expiryDate: details.SFS_MPD_ExpiryDate__c,
          fax: details.SFS_MPD_Fax__c?.split('-'),
          nominatedPerson: details.SFS_MPD_NominatedPerson__c,
          isCompleteContact: details.SFS_MPD_ContactComplete__c,
          isCompletePracticeRequirement: details.SFS_MPD_PracticeComplete__c,
        };
      });
      return mipMapping;
    });
  }
);

export const getMipWorkHistory: any = createAction('getMipWorkHistory/GET_WORK_HISTORY', () => {
  return request(GET_WORK_HISTORY, {}).then((response: any) => response.getWorkHistory);
});

export const updateAML: any = createAction('updateAML/UPDATE_MIP_AML', (amlForms: any) => async () => {
  const response = await request(UPDATE_MIP_AML, { aml: amlForms });
  return response;
});

export const getMipAmlAttestation: any = createAction('getMipAmlAttestation/QUERY_MIP_AML_ATTESTATION', () => {
  return request(QUERY_MIP_AML_ATTESTATION, {});
});

export const getMipAttestation: any = createAction('getMipAttestation/QUERY_MIP_ATTESTATION', () => {
  return request(QUERY_MIP_ATTESTATION, {});
});

export const getMipAttestationPracticeRequirement: any = createAction(
  'getMipAttestationPracticeRequirement/QUERY_MIP_PRACTICE_REQUIREMENT_ATTESTATION',
  async (practiceRequirementId?: any) => {
    return request(QUERY_MIP_ATTESTATION, { practiceRequirementId });
  }
);

export const getMipContactLoading: any = createAction('getMipContact/QUERY_MIP_CONTACT_LOADING');

export const getMipContact: any = createAction('getMipContact/QUERY_MIP_CONTACT', async () => {
  return request(QUERY_MIP_CONTACT, {});
});

export const updatePracticeRequirements: any = createAction(
  'updatePracticeRequirements/UPDATE_PRACTICE_REQUIREMENT',
  (practiceRequirement: any) => async () => {
    const response = await request(UPDATE_PRACTICE_REQUIREMENT, { practiceRequirement });
    return response;
  }
);

export const upsertWorkHistory: any = createAction(
  'upsertWorkHistory/CREATE_WORK_HISTORY',
  (workHistory: any) => async () => {
    const res = await request(CREATE_WORK_HISTORY, { workHistory });
    return res.upsertWorkHistory.body;
  }
);

export const getRenewalSeason: any = createAction('membershipInPractice/GET_RENEWAL_SEASON_INFO', () => {
  return request(GET_RENEWAL_SEASON_INFO).then(response => {
    const { getRenewalSeasonInfo } = response;
    const isRenewalSeason = getRenewalSeasonInfo.hasOwnProperty('isMipRenewalSeason')
      ? getRenewalSeasonInfo.isMipRenewalSeason
      : getRenewalSeasonInfo.isRenewalSeason;

    return { isRenewalSeason };
  });
});

export const getMipRenewalSeasonStartDate: any = createAction('membershipInPractice/GET_RENEWAL_SEASON_INFO', () => {
  return request(GET_RENEWAL_SEASON_INFO).then(response => {
    const { getRenewalSeasonInfo } = response;
    const mipStartDate = getRenewalSeasonInfo.hasOwnProperty('mipStartDate')
      ? getRenewalSeasonInfo.mipStartDate
      : getRenewalSeasonInfo.startDate;

    return { mipStartDate };
  });
});

export const getMipRenewalSeasonEndDate: any = createAction('membershipInPractice/GET_RENEWAL_SEASON_INFO', () => {
  return request(GET_RENEWAL_SEASON_INFO).then(response => {
    const { getRenewalSeasonInfo } = response;
    const mipEndDate = getRenewalSeasonInfo.hasOwnProperty('mipEndDate')
      ? getRenewalSeasonInfo.mipEndDate
      : getRenewalSeasonInfo.endDate;

    return { mipEndDate };
  });
});

export const setMipLoading: any = createAction('setMipLoading/GET_MIP_LOADING');

export const deleteMipRecords: any = createAction(
  'membershipInPractice/DELETE_MIP_RECORDS;',
  async (isMipRenewalSeason?: boolean) => {
    const response = await request(DELETE_MIP_RECORDS);
    if (response) {
      return isMipRenewalSeason ? mipStateRenewal : mipState;
    }
  }
);
