import React from 'react';
import { CimaApplicationDownload } from 'components/molecules/CimaApplicationDownload/CimaApplicationDownload';

import styled from 'styled-components/macro';

interface ApplicationRecordProps {
  title: string;
  items?: any[];
  downloadLink?: any;
}

export const CimaSupervisorRecord: React.FC<ApplicationRecordProps> = ({ title, items = [] }) => {
  return (
    <StyledContainer>
      <StyledSection>
        <StyledSpan>{title}</StyledSpan>
      </StyledSection>

      <StyledSection>
        {items.map((item: any, index: number) => {
          return (
            <StyledDivider key={index}>
              <CimaApplicationDownload title={item.title} downloadLink={item.downloadLink} />
            </StyledDivider>
          );
        })}
      </StyledSection>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(50)};
`;

const StyledSection = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)};
`;

const StyledDivider = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledSpan = styled.div`
  font-size: ${props => props.theme.pxToRem(24)};
  margin-bottom: ${props => props.theme.pxToRem(10)};
  font-weight: ${props => props.theme.fontWeights.bold};
`;
