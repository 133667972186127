import React from 'react';
import { theme } from 'theme';
interface Props {
  size?: number;
  color?: string;
}
export const Bookmark = React.memo<Props>(({ size = 24, color = theme.colors.primaryPurple, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"
    />
  </svg>
));
