import React from 'react';
import styled from 'styled-components/macro';
import { Heading, Grid, Container, ButtonLink, ButtonEnums } from 'components/atoms';
import { DefaultInlineLinkStyles, InlineLinkOnDarkBgStyles } from 'components/atoms/Link/Link';
import { SubBlockSyllabus } from './SubBlockSyllabus';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful } from 'mxp-schemas';
import { IC_CHECK_GREEN } from 'resources/images';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}

export const BlockSyllabus: React.FC<Props> = ({ modularBlock, headingType }) => {
  const block = modularBlock as Contentful.ModularContent.SyllabusBlock;
  const isBgColorDark: boolean = isColorDark(block.bgColor);

  return (
    <>
      <StyledContainer container id="cgma">
        <Grid.Column computer={8} mobile={16}>
          <StyledLeftContainer text>
            {block.header && (
              <StyledHeading
                as={headingType}
                dangerouslySetInnerHTML={{ __html: block.header }}
                isBgColorDark={isBgColorDark}
              />
            )}
            {block.description && (
              <StyledParagraph dangerouslySetInnerHTML={{ __html: block.description }} isBgColorDark={isBgColorDark} />
            )}
            {block.ctaText && block.ctaUrl && (
              <StyledTextLink href={block.ctaUrl} isBgColorDark={isBgColorDark}>
                {block.ctaText}
              </StyledTextLink>
            )}
            {block?.xmptUrl && (
              <ButtonLink
                testId="find-my-exemption-button-link"
                to={block.xmptUrl}
                variant={ButtonEnums.variants.secondary}
                size={ButtonEnums.sizes.medium}
              >
                {block?.xmptText}
              </ButtonLink>
            )}
          </StyledLeftContainer>
        </Grid.Column>
        <Grid.Column computer={8} mobile={16}>
          <StyledRightContainer>
            <StyledUl>
              {block?.landingPageSubBlocks?.map((item: Contentful.LandingPages.LandingPageSubBlock, index) => (
                <StyledLi key={item.id}>
                  <StyledGridRow>
                    <StyledFlexContainer>
                      <StyledInnerDiv>
                        <StyledIconContainer id="StyledIcon" />
                        <StyledDividerContainer id="StyledDivider" />
                      </StyledInnerDiv>
                      <SubBlockSyllabus
                        index={index + 1}
                        testId={`block-syllabus-${item.id}`}
                        header={item.header}
                        description={item.description}
                      />
                    </StyledFlexContainer>
                  </StyledGridRow>
                </StyledLi>
              ))}
            </StyledUl>
          </StyledRightContainer>
        </Grid.Column>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled(Grid)`
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  padding-bottom: ${props => props.theme.pxToRem(44)} !important;
  background-color: ${props => props.theme.colors.neutralGrey1};

  ${props => props.theme.mediaQueries.tabletOnly} {
    flex-direction: column !important;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledIconContainer = styled.div`
  height: ${props => props.theme.pxToRem(20)};
  width: ${props => props.theme.pxToRem(20)};
  margin-top: ${props => props.theme.pxToRem(39)};
  margin-bottom: ${props => props.theme.pxToRem(12)};
  background: url(${IC_CHECK_GREEN});
`;

const StyledInnerDiv = styled.div`
  margin-right: ${props => props.theme.pxToRem(20)};
`;

const StyledDividerContainer = styled.div`
  width: ${props => props.theme.pxToRem(20)};
  height: calc(100% - 18px);
  background: repeating-linear-gradient(
      to bottom,
      ${props => props.theme.colors.primaryPurple} 0 12px,
      transparent 10px 22px
    )
    50%/2px 100% no-repeat;
`;

const StyledRightContainer = styled.div`
  width: 100%;
  padding-right: ${props => props.theme.pxToRem(30)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    margin: 0 auto;
  }
`;

const StyledLeftContainer = styled(Container)`
  padding-left: ${props => props.theme.pxToRem(30)};
  width: 100% !important;
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding-left: 0;
  }
`;

const StyledGridRow = styled(Grid.Row)`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  padding: 0 !important;
`;

const StyledHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: left;
  width: 100%;
  height: auto;

  p {
    margin: 0;
    line-height: 1.38;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(335)};
    font-size: ${props => props.theme.fontSizes.l};

    p {
      line-height: 1.33;
      text-align: center;
    }
  }
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: left;
  width: 100%;
  height: auto;

  p {
    margin: 0;
    line-height: 1.33;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(335)};
    font-size: ${props => props.theme.fontSizes.s};
    text-align: center;

    p {
      line-height: 1.5;
    }
  }
`;

const StyledTextLink = styled.a<{ isBgColorDark: boolean }>`
  width: ${props => props.theme.pxToRem(244)};
  height: ${props => props.theme.pxToRem(30)};
  margin-top: ${props => props.theme.pxToRem(16)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.67;
  cursor: pointer;
  color: ${props => props.theme.colors.primaryDarkPurple} !important;

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
    text-align: center;
  }
`;

const StyledUl = styled.ul`
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(10)};
`;

const StyledLi = styled.li`
  list-style: none;
  &:last-child #StyledDivider {
    background: none;
  }
`;
