import { FirmAdminTableType } from 'constants/firm-admin';
import { User } from 'mxp-schemas';

// ------------------------------------
// Constants
// ------------------------------------
export enum FirmAdminActionNames {
  SET_DATA = 'firm-admin/SET_DATA',
  SET_IS_LOADING = 'firm-admin/SET_IS_LOADING',
  SET_MODIFIED_HASH = 'firm-admin/SET_MODIFIED_HASH',
  SET_MODIFIED_POSITION = 'firm-admin/SET_MODIFIED_POSITION',
  SET_PAGINATION = 'firm-admin/SET_PAGINATION',
  GET_FIRM_MEMBERS = 'firm-admin/GET_MEMBERS',
  GET_ORGANIZATIONS = 'firm-admin/GET_ORGANIZATIONS',
  GET_ORGANIZATION_ADMINS_BY_ROLE = 'GET_ORGANIZATION_ADMINS_BY_ROLE',
  INIT_FIRM_ROSTER_DYNAMO_TABLE = 'firm-admin/INIT_FIRM_ROSTER_DYNAMO_TABLE',
  SAVE_TABLE_CHANGES = 'firm-admin/SAVE_TABLE_CHANGES',
  GET_FIRM_MEMBERS_INVITES = 'firm-admin/GET_FIRM_MEMBERS_INVITES',
  GET_FLP_SUBSCRIPTIONS_INVITES = 'firm-admin/GET_FLP_SUBSCRIPTIONS_INVITES',
  GET_MOCK_INVOICES = 'firm-admin/GET_MOCK_INVOICES',
  GET_CSV_DATA = 'firm-admin/GET_CSV_DATA',
  TOGGLE_IS_PAID_BY_FIRM = 'firm-admin/TOGGLE_IS_PAID_BY_FIRM',
  IMPORT_B2B_CLIENT_ADMINS = 'firm-admin/IMPORT_B2B_CLIENT_ADMINS',
  REMOVE_FIRM_ROSTER_MEMBER = 'firm-admin/REMOVE_FIRM_ROSTER_MEMBER',
  REMOVE_MEMBERSHIP_INVITE = 'firm-admin/REMOVE_MEMBERSHIP_INVITE',
  SEND_FIRM_ROSTER_INVITE_REMINDER = 'firm-admin/SEND_FIRM_ROSTER_INVITE_REMINDER',
  SEND_MEMBERSHIP_UPGRADE_REMINDER = 'firm-admin/SEND_MEMBERSHIP_UPGRADE_REMINDER',
  GET_MEMBERSHIP_SECTIONS = 'firm-admin/GET_MEMBERSHIP_SECTIONS',
  GET_MEMBERSHIP_RELATED_ADDONS = 'firm-admin/GET_MEMBERSHIP_RELATED_ADDONS',
  GET_MEMBERSHIP_CREDENTIALS = 'firm-admin/GET_MEMBERSHIP_CREDENTIALS',
  RESET_TABLE_STATE = 'firm-admin/RESET_TABLE_STATE',
  SAVE_FIRM_BILLING_QUOTE_ITEMS = 'firm-admin/SAVE_FIRM_BILLING_QUOTE_ITEMS',
  REMOVE_FIRM_BILLING_QUOTE_ITEMS = 'firm-admin/REMOVE_FIRM_BILLING_QUOTE_ITEMS',
  GENERATE_QUOTE = 'firm-admin/GENERATE_QUOTE',
  INVALIDATE_QUOTE = 'firm-admin/INVALIDATE_QUOTE',
  INIT_QUOTE_GENERATION = 'firm-admin/INIT_QUOTE_GENERATION',
  GET_FIRM_BILLING_INVOICES = 'firm-admin/GET_FIRM_BILLING_INVOICES',
  UPGRADE_FIRM_ROSTER_INVITE = 'firm-admin/UPGRADE_FIRM_ROSTER_INVITE',
  GENERATE_INVOICE = 'GENERATE_INVOICE',
  GET_INVOICE_FILE = 'firm-admin/GET_INVOICE_FILE',
  GET_GENERATE_INVOICE_MODAL_INFO = 'firm-admin/GET_GENERATE_INVOICE_MODAL_INFO',
  APPLY_DISCOUNT_CODE_TO_QUOTE = 'firm-admin/APPLY_DISCOUNT_CODE_TO_QUOTE',
  REMOVE_DISCOUNT_CODE_FROM_QUOTE = 'firm-admin/REMOVE_DISCOUNT_CODE_FROM_QUOTE',
  SET_PERSONAL_ADDONS_MODAL_USER_ID = 'firm-admin/SET_PERSONAL_ADDONS_MODAL_USER_ID',
  GET_RENEWAL_SEASON_INFO = 'firm-admin/GET_RENEWAL_SEASON_INFO',
  SAVE_FIRM_BILLING_BULK_MODAL_QUOTE_ITEMS = 'firm-admin/SAVE_FIRM_BILLING_BULK_MODAL_QUOTE_ITEMS',
  SET_IS_GENERATE_INVOICE_MODAL_OPEN = 'firm-admin/SET_IS_GENERATE_INVOICE_MODAL_OPEN',
  GET_FIRM_ADMIN_ORGANIZATION_DETAILS = 'firm-admin/GET_FIRM_ADMIN_ORGANIZATION_DETAILS',
  SET_IS_REFRESH_FIRM_DATA = 'firm-admin/SET_IS_REFRESH_FIRM_DATA',
  SET_FIRM_ADMIN_EVENT = 'firm-admin/SET_FIRM_ADMIN_EVENT',
}

export const initData = {
  hash: {},
  count: 0,
  selectedIds: [],
  modifiedHash: {},
  isLoading: true,
  isFetched: false,
};

export enum FirmAdminMembershipTierSKUS {
  STAFFCORE = 'MEMREGSC22',
  PARTNERCORE = 'MEMREGPC22',
}

export const initialState: FirmAdmin.Root = {
  data: {
    [FirmAdminTableType.MANAGE_FIRM_ROSTER]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.MANAGE_FIRM_BILLING]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.MEMBERS_INVITES]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.UPGRADABLE_MEMBERS]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.MEMBERSHIPS_AND_ADDONS]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.FIRM_BILLING_INVOICES]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.FIRM_BILLING_INVOICES_CIMA]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.FLP_ORGANIZATION_BILLING]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.FLP_ADD_SUBSCRIPTION]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION_MODAL]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.FLP_ORGANIZATION_ADDONS]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.CIMA_ORGANIZATION_ROSTER]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.CIMA_ORGANIZATION_BILLING]: JSON.parse(JSON.stringify(initData)),
    [FirmAdminTableType.FLP_ORGANIZATION_ROSTER]: JSON.parse(JSON.stringify(initData)),
  },
  pagination: {
    [FirmAdminTableType.MANAGE_FIRM_ROSTER]: {
      offset: 0,
      limit: 100,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: null,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
      },
    },
    [FirmAdminTableType.MEMBERS_INVITES]: {
      offset: 0,
      limit: 10,
      query: '',
      filters: {
        membershipType: '',
        membershipTier: '',
        isPaidByFirm: null,
        isUpgradeMembership: false,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
      },
    },
    [FirmAdminTableType.UPGRADABLE_MEMBERS]: {
      offset: 0,
      limit: 5,
      query: '',
      filters: {
        membershipType: '',
        membershipTier: '',
        isPaidByFirm: true,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        subscriptionStatus: [User.MembershipSubscriptionStatus.RENEWAL],
      },
    },
    [FirmAdminTableType.MANAGE_FIRM_BILLING]: {
      offset: 0,
      limit: 100,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: true,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        isFLPView: false,
        isBillingView: true,
        learningPathway: null,
      },
    },
    [FirmAdminTableType.MEMBERSHIPS_AND_ADDONS]: {
      offset: 0,
      limit: 100,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: true,
        isPaidByFlp: false,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        isFLPView: false,
        isBillingView: true,
      },
    },
    [FirmAdminTableType.FIRM_BILLING_INVOICES]: {
      offset: 0,
      limit: 50,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: null,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        isPaidInvoice: false,
        // is the data fetched isPaidInvoice true or false
        isPaidInvoiceFetchedData: false,
      },
    },
    [FirmAdminTableType.FIRM_BILLING_INVOICES_CIMA]: {
      offset: 0,
      limit: 50,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: null,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        isPaidInvoice: false,
        // is the data fetched isPaidInvoice true or false
        isPaidInvoiceFetchedData: false,
      },
    },
    [FirmAdminTableType.FLP_ORGANIZATION_BILLING]: {
      offset: 0,
      limit: 100,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: false,
        isPaidByFlp: true,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        learningPathway: null,
        applicationPartLearningPathwaysFLP: null,
        applicationPartLearningPathwaysPQ: null,
        isFLPView: true,
        isBillingView: true,
      },
    },
    [FirmAdminTableType.FLP_ADD_SUBSCRIPTION]: {
      offset: 0,
      limit: 10,
      query: '',
      filters: {
        membershipType: '',
        membershipTier: '',
        isPaidByFirm: null,
        isUpgradeMembership: false,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
      },
    },
    [FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION]: {
      offset: 0,
      limit: 5,
      query: '',
      filters: {
        membershipType: '',
        membershipTier: '',
        isPaidByFirm: null,
        isPaidByFlp: true,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        subscriptionStatus: [User.MembershipSubscriptionStatus.RENEWAL],
        isFlpUpgradeModalView: null,
      },
    },
    [FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION_MODAL]: {
      offset: 0,
      limit: 5,
      query: '',
      filters: {
        membershipType: '',
        membershipTier: '',
        isPaidByFirm: null,
        isPaidByFlp: true,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        subscriptionStatus: [User.MembershipSubscriptionStatus.RENEWAL],
        isFlpUpgradeModalView: true,
      },
    },
    [FirmAdminTableType.FLP_ORGANIZATION_ADDONS]: {
      offset: 0,
      limit: 100,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFlp: true,
        isPaidByFirm: false,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        subscriptionStatus: [],
        flpSubscriptionDuration: '',
        expiryDays: '',
        flpSubscriptionType: '',
        isFLPView: true,
        isBillingView: true,
      },
    },
    [FirmAdminTableType.CIMA_ORGANIZATION_ROSTER]: {
      offset: 0,
      limit: 100,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: null,
        isPaidByFlp: null,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
      },
    },
    [FirmAdminTableType.FLP_ORGANIZATION_ROSTER]: {
      offset: 0,
      limit: 100,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: null,
        isPaidByFlp: null,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
      },
    },
    [FirmAdminTableType.CIMA_ORGANIZATION_BILLING]: {
      offset: 0,
      limit: 100,
      query: '',
      filters: {
        membershipTier: '',
        membershipType: '',
        isPaidByFirm: true,
        isPaidByFlp: false,
        branchCountry: '',
        branchState: '',
        branchCity: '',
        officeLocation: '',
        learningPathway: null,
        applicationPartLearningPathwaysFLP: null,
        applicationPartLearningPathwaysPQ: null,
        isFLPView: false,
        isBillingView: true,
      },
    },
  },
  organizations: {
    list: [],
    isOrganizationsFetched: false,
    error: null,
  },
  organizationAdmins: {
    list: [],
    isOrganizationAdminsFetched: false,
    error: null,
  },
  addons: null,
  sections: null,
  credentials: null,
  personalAddonsModalUserId: null,
  isRenewalSeason: null,
  isGenerateInvoiceModalOpened: false,
  orgDetails: {
    organizationId: '',
    isOrganizationDataLoaded: false,
    isSubscriptionsDataLoaded: false,
    lastRefreshDate: '',
    isRefreshRequested: false,
    orgStatus: User.OrgStatus.INITIALIZED,
    isFetched: false,
  },
  events: {
    isRefreshFirmButtonClicked: false,
  },
};
