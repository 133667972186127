import React from 'react';
import { isServer } from 'utils';
import { vergicConfigSelector } from 'modules/app/selectors';
import { useSelector } from 'react-redux';
import { isCartFetchedSelector } from 'modules/cart/selectors';

export const VergicCalendar: React.FC = React.memo(() => {
  const { vergicUrl, vergicId }: State.VergicConfig = useSelector(vergicConfigSelector);
  const isCartFetched: boolean | undefined = useSelector(isCartFetchedSelector);

  React.useEffect(() => {
    if (isServer || !vergicUrl || !vergicId || !isCartFetched) return;

    const body = document.querySelector('body');
    if (!body) return;

    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('defer', 'true');
    script.setAttribute('id', 'vergic-chat');
    script.setAttribute('src', `${vergicUrl}/${vergicId}/engage.js`);
    document.body.appendChild(script);
  }, [isCartFetched]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="vergic-calendar-aicpa" />
      <div className="phone2web-meeting" />
    </>
  );
});
