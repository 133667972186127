import React, { useState } from 'react';
import { ShowPasswordInput } from 'components/molecules/ShowPasswordInput/ShowPasswordInput';
import { Utils } from 'mxp-utils';
import styled from 'styled-components';
import { ReactComponent as Correct } from 'resources/images/icon-dev-ic-check-circle.svg';
import { ReactComponent as Wrong } from 'resources/images/ic-cancel.svg';
import { InputProps } from 'components/atoms/Input/Input';

interface PasswordValidationMessageWithIconProps {
  message: string;
  isValid: boolean;
}

const PasswordValidationMessageWithIcon: React.FC<PasswordValidationMessageWithIconProps> = ({ isValid, message }) => (
  <FlexWrap>
    {isValid ? <CorrectIcon /> : <WrongIcon />}
    <PasswordValidationMessage hasError={!isValid}>{message}</PasswordValidationMessage>
  </FlexWrap>
);

const PASS_NAME_FIELD_ID = 'password';

interface PasswordInputProps extends InputProps {
  autoFocus?: boolean;
  showRules?: boolean;
  labelName: string;
  otherError?: string;
  value: string;
  getIsFocused?: (focused: boolean) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setIsPasswordValid?: (valid: boolean) => void;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  autoFocus,
  showRules = true,
  labelName = 'Create password',
  otherError,
  value,
  getIsFocused,
  onChange,
  setIsPasswordValid,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false);
  const handleBlur = React.useCallback(() => {
    setFocused(false);
    if (getIsFocused) {
      getIsFocused(false);
    }
  }, [getIsFocused, setFocused]);
  const handleFocus = React.useCallback(() => {
    setFocused(true);
    if (getIsFocused) {
      getIsFocused(true);
    }
  }, [getIsFocused, setFocused]);

  const isPassLengthLongValid = Utils.isLongerThan(value, 7);
  const isPassLengthShortValid = Utils.isShorterThan(value, 15);
  const passHasLengthValid = isPassLengthLongValid && isPassLengthShortValid;

  const passHasUppercase = Utils.hasUppercase(value);
  const passHasLowercase = Utils.hasLowercase(value);

  const passHasNumericChar = Utils.hasNumericChar(value);
  const passHasSpecialChar = Utils.hasSpecialChar(value);

  const passValid =
    passHasLengthValid && passHasUppercase && passHasLowercase && passHasSpecialChar && passHasNumericChar;

  React.useEffect(() => {
    if (setIsPasswordValid) setIsPasswordValid(passValid);
  }, [setIsPasswordValid, passValid]);

  const showInfo = showRules && (isFocused || (!isFocused && value && !passValid));
  const showOtherError = Boolean(otherError?.length);

  return (
    <>
      <StyledPasswordInput
        {...props}
        fluid
        labelName={labelName}
        name={PASS_NAME_FIELD_ID}
        value={value}
        testId={PASS_NAME_FIELD_ID}
        labelId={PASS_NAME_FIELD_ID}
        autoComplete="off"
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        isNew
        autoFocus={autoFocus}
        hasError={showOtherError}
        isLogin={false}
      />
      <PasswordValidationMessageWrap>
        {showOtherError && <PasswordValidationMessage hasError>{otherError}</PasswordValidationMessage>}
        {showInfo && !showOtherError && (
          <>
            <PasswordValidationMessage hasError={!passValid}>Password must contain:</PasswordValidationMessage>
            <PasswordValidationMessageWithIcon message="Between 8-14 characters" isValid={passHasLengthValid} />
            <PasswordValidationMessageWithIcon message="At least 1 uppercase (A) letter" isValid={passHasUppercase} />
            <PasswordValidationMessageWithIcon message="At least 1 lowercase (a) letter" isValid={passHasLowercase} />
            <PasswordValidationMessageWithIcon message="At least 1 number (0-9)" isValid={passHasNumericChar} />
            <PasswordValidationMessageWithIcon
              message="At least 1 special character (such as !, %, @, #)"
              isValid={passHasSpecialChar}
            />
          </>
        )}
      </PasswordValidationMessageWrap>
    </>
  );
};

const FlexWrap = styled.div`
  display: flex;
`;

export const StyledPasswordInput = styled(({ hasError, children, ...rest }) => (
  <ShowPasswordInput {...rest}>{children}</ShowPasswordInput>
))`
  margin-bottom: ${props => props.theme.pxToRem(6)};
  ${({ hasError, theme }) =>
    hasError &&
    `
		&&&& > input {
		:focus {
			border: solid ${theme.pxToRem(1)} ${theme.colors.interfaceRed};
		}
		border: solid ${theme.pxToRem(1)} ${theme.colors.interfaceRed};
		}
	`}
`;

const PasswordValidationMessageWrap = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const PasswordValidationMessage = styled(({ hasError, children, ...rest }) => <div {...rest}>{children}</div>)`
  margin-bottom: ${props => props.theme.pxToRem(4)};
  color: ${props => props.theme.colors.neutralGrey7};
  font-size: ${props => props.theme.fontSizes.xxs};
`;

const CorrectIcon = styled(Correct)`
  margin-right: ${props => props.theme.pxToRem(3)};
  display: inline-block;
  width: ${props => props.theme.pxToRem(12)};
  height: ${props => props.theme.pxToRem(12)};
  color: ${props => props.theme.colors.interfaceGreen};
`;

const WrongIcon = styled(Wrong)`
  &&&&&&& {
    display: inline-block;
    margin-left: ${props => props.theme.pxToRem(-2)};
    margin-right: ${props => props.theme.pxToRem(-1)};
    height: ${props => props.theme.pxToRem(12)};
    color: ${props => props.theme.colors.interfaceRed};
  }
`;
