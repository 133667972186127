import React from 'react';
import styled from 'styled-components';

import { NotificationBanner, NotificationBannerEnums } from 'components/atoms/NotificationBanner/NotificationBanner';

import { ReactComponent as IconCheckCircle } from 'resources/images/icon-dev-ic-check-circle.svg';

interface Props {
  label: string | React.ReactNode;
}

const SuccessNotification: React.FC<Props> = ({ label }) => (
  <StyledNotificationBanner
    variant={NotificationBannerEnums.variant.green}
    testId="notification-banner"
    childrenTestId="notification-children"
    icon={<StyledIconSuccess />}
  >
    {label}
  </StyledNotificationBanner>
);

export { SuccessNotification };

const StyledNotificationBanner = styled(NotificationBanner)`
  &&&&& {
    margin-bottom: ${props => props.theme.pxToRem(25)};
    padding-top: ${props => props.theme.pxToRem(21)};
    padding-bottom: ${props => props.theme.pxToRem(21)};
  }
`;

const StyledIconSuccess = styled(IconCheckCircle)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;
