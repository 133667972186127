import React, { useState } from 'react';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment-timezone';
import { MomentHelpers } from 'utils/MomentHelpers';
import { OnlyDesktop, OnlyMobile } from 'components/atoms';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { ReactComponent as ArrowRight, ReactComponent as ArrowLeft } from 'resources/images/icon-arrow-up.svg';

export interface DayPickerRangeProps {
  className?: string;
  selectedStartDate?: string;
  selectedEndDate?: string;
  numberOfMonths?: number;
  extendAccess?: boolean;
  handleExtendAccess?: (date: string) => void;
}

export const DayPickerRange: React.FC<DayPickerRangeProps> = ({
  className,
  selectedStartDate,
  selectedEndDate,
  numberOfMonths = 1,
  extendAccess = false,
  handleExtendAccess,
}) => {
  const START_DATE = 'startDate';
  const END_DATE = 'endDate';
  const DATE_FORMAT = 'YYYY-MM-DD';
  const momentInstance = moment();

  const [startDateMoment, setStartDateMoment] = useState<moment.Moment | null>(
    selectedStartDate ? MomentHelpers.convertToMoment(selectedStartDate, DATE_FORMAT) : null
  );
  const [endDateMoment, setEndDateMoment] = useState<moment.Moment | null>(
    selectedEndDate ? MomentHelpers.convertToMoment(selectedEndDate, DATE_FORMAT) : null
  );

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(extendAccess ? END_DATE : START_DATE);

  const [currentMonth, setCurrentMonth] = useState<moment.Moment>(
    selectedStartDate ? (startDateMoment as moment.Moment) : momentInstance
  );

  const isBeforeThisMonth = React.useCallback(() => {
    return momentInstance.isSame(currentMonth, 'month');
  }, [currentMonth, momentInstance]);

  const [isStartSelected, setIsStartSelected] = useState(false);

  const arrowLeftOnClickFunc = React.useCallback(e => isBeforeThisMonth() && e.stopPropagation(), [isBeforeThisMonth]);

  const leftArrow = <StyledArrowLeft onClick={arrowLeftOnClickFunc} />;
  const rightArrow = <StyledArrowRight />;

  const handleDatesChange = React.useCallback(
    ({ startDate, endDate }: any) => {
      const endDateFormatted = endDate?.format(DATE_FORMAT) || '';

      // extend access for purchases
      if (handleExtendAccess) {
        handleExtendAccess(endDateFormatted);
        setEndDateMoment(endDate);
        setIsStartSelected(true);
        return;
      }

      setStartDateMoment(startDate);
      setEndDateMoment(endDate);
    },
    [handleExtendAccess]
  );

  const onFocusChangeFunc = React.useCallback(
    (input: FocusedInputShape | null) => {
      const INPUT_DATE = extendAccess ? END_DATE : START_DATE;
      setFocusedInput(!input ? INPUT_DATE : input);
    },
    [extendAccess]
  );

  const isDayHighlightedFunc = React.useCallback((day: any) => day.isSame(momentInstance, 'day'), [momentInstance]);

  const isOutsideRangeFunc = React.useCallback(
    (day: moment.Moment) => day.isBefore(extendAccess ? startDateMoment : momentInstance, 'day'),
    [extendAccess, startDateMoment, momentInstance]
  );

  const renderDayContentsFunc = React.useCallback(
    (day: moment.Moment) => {
      const showCalendarEventDot = extendAccess && startDateMoment?.format(DATE_FORMAT) === day.format(DATE_FORMAT);
      return (
        <>
          <span className="CalendarEventText">{day.format('D')}</span>
          {!day.isBefore(momentInstance, 'day') && showCalendarEventDot && <span className="CalendarEventDot" />}
        </>
      );
    },
    [extendAccess, startDateMoment, momentInstance]
  );

  const setVisibleMonthFunc = React.useCallback((month: moment.Moment) => {
    setCurrentMonth(month);
  }, []);

  const initialVisibleMonthFunc = React.useCallback(
    () => startDateMoment || momentInstance,
    [startDateMoment, momentInstance]
  );

  const getDayWidthForMobile = React.useCallback((): number => {
    const dayWith = Math.floor((window.innerWidth - 66) / 7);
    return (dayWith > 48 ? 48 : dayWith) as number;
  }, []);

  return (
    <DayPickerContainer className={className} extendAccess={extendAccess} isStartSelected={isStartSelected}>
      <OnlyDesktop>
        <DayPickerRangeController
          initialVisibleMonth={initialVisibleMonthFunc}
          startDate={startDateMoment}
          endDate={endDateMoment}
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={onFocusChangeFunc}
          isDayHighlighted={isDayHighlightedFunc}
          numberOfMonths={numberOfMonths}
          hideKeyboardShortcutsPanel
          navNext={rightArrow}
          navPrev={leftArrow}
          daySize={31}
          noBorder
          isOutsideRange={isOutsideRangeFunc}
          renderDayContents={renderDayContentsFunc}
          onPrevMonthClick={setVisibleMonthFunc}
          onNextMonthClick={setVisibleMonthFunc}
        />
      </OnlyDesktop>
      <OnlyMobile>
        <DayPickerRangeController
          startDate={startDateMoment}
          endDate={endDateMoment}
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={onFocusChangeFunc}
          isDayHighlighted={isDayHighlightedFunc}
          numberOfMonths={numberOfMonths}
          hideKeyboardShortcutsPanel
          navNext={rightArrow}
          navPrev={leftArrow}
          daySize={getDayWidthForMobile()}
          noBorder
          isOutsideRange={isOutsideRangeFunc}
          renderDayContents={renderDayContentsFunc}
          initialVisibleMonth={initialVisibleMonthFunc}
          onPrevMonthClick={setVisibleMonthFunc}
          onNextMonthClick={setVisibleMonthFunc}
        />
      </OnlyMobile>
    </DayPickerContainer>
  );
};

const DayPickerContainer = styled.div<{ extendAccess: boolean; isStartSelected: boolean }>`
  .DayPicker_transitionContainer {
    border-radius: ${props => props.theme.pxToRem(2)};
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey2};
    min-height: ${props => props.theme.pxToRem(310)};
  }
  .DayPicker_weekHeader_li {
    font-size: 0;
  }
  .DayPicker_weekHeader_li::first-letter {
    visibility: visible;
    font-size: ${props => props.theme.fontSizes.xxs};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: 1.5;
    letter-spacing: ${props => props.theme.pxToRem(0.22)};
    color: ${props => props.theme.colors.neutralGrey8};
  }
  .DayPickerNavigation__horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${props => props.theme.pxToRem(36)};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: ${props => props.theme.pxToRem(12)};
  }
  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: ${props => props.theme.pxToRem(40)};
  }
  .DayPickerNavigation__vertical {
    ${props => props.theme.mediaQueries.mobileOnly} {
      display: none;
    }
  }
  .DayPicker_weekHeader {
    height: ${props => props.theme.pxToRem(40)};
    background-color: ${props => props.theme.colors.neutralGrey1};
    display: flex;
    align-items: center;
    top: ${props => props.theme.pxToRem(48)};
  }
  .DayPicker_weekHeader_ul {
    white-space: nowrap;
  }
  .DayPickerNavigation_button {
    display: inline-block;
    width: ${props => props.theme.pxToRem(24)};
    height: ${props => props.theme.pxToRem(24)};
  }
  .CalendarMonth_caption {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    line-height: 1.5;
    color: ${props => props.theme.colors.neutralGrey8};
    padding-top: ${props => props.theme.pxToRem(12)};
    padding-bottom: ${props => props.theme.pxToRem(68)};
  }
  .CalendarDay {
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: 1.57;
    color: ${props => props.theme.colors.neutralGrey8};
    border: none;
    position: relative;
    outline: none;
    &:hover {
      background-color: ${props => props.theme.colors.neutralWhite};
      border: none;
      color: inherit;
    }
    & > .CalendarEventText {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      left: 0;
      z-index: 2;
    }
    &:not(.CalendarDay__blocked_out_of_range):hover {
      & > .CalendarEventText {
        color: ${props => props.theme.colors.neutralGrey8};
        background-color: ${props => props.theme.colors.neutralGrey2};
        ${props => props.theme.mediaQueries.mobileOnly} {
          color: ${props => props.theme.colors.neutralWhite};
          background-color: ${props => props.theme.colors.primaryPurple};
        }
      }
    }
    &.CalendarDay__blocked_out_of_range {
      color: ${props => props.theme.colors.neutralGrey4};
    }
    & > .CalendarEventDot {
      width: ${props => props.theme.pxToRem(4)};
      height: ${props => props.theme.pxToRem(4)};
      border-radius: 50%;
      background-color: #aa7ca7;
      position: absolute;
      left: calc(50% - ${props => props.theme.pxToRem(2)});
      bottom: ${props => props.theme.pxToRem(3)};
      z-index: 2;
      ${props => props.theme.mediaQueries.mobileOnly} {
        bottom: ${props => props.theme.pxToRem(6)};
      }
    }
    &.CalendarDay__highlighted_calendar {
      font-weight: ${props => props.theme.fontWeights.medium};
      color: ${props => props.theme.colors.primaryPurple};
      background-color: ${props => props.theme.colors.neutralWhite};
    }
    &.CalendarDay__selected_start,
    &.CalendarDay__selected_end {
      position: relative;
      background-color: ${props => props.theme.colors.neutralWhite};
      font-weight: ${props => props.theme.fontWeights.medium};
      & > .CalendarEventText {
        color: ${props => props.theme.colors.neutralWhite};
        background-color: ${props => props.theme.colors.primaryPurple};
      }
      &:hover {
        & > .CalendarEventText {
          background-color: ${props => props.theme.colors.primaryLightPurple};
          color: ${props => props.theme.colors.neutralWhite};
        }
        ${props => props.theme.mediaQueries.mobileOnly} {
          & > .CalendarEventText {
            color: ${props => props.theme.colors.neutralWhite};
            background-color: ${props => props.theme.colors.primaryPurple};
          }
        }
      }
      &::after {
        content: '';
        width: 50%;
        height: 100%;
        background-color: rgba(114, 36, 108, 0.12);
        top: 0;
        z-index: 1;
      }
    }
    &:not(.CalendarDay__selected_start_no_selected_end).CalendarDay__selected_start {
      &::after {
        position: absolute;
        right: 0;
      }
    }
    &.CalendarDay__selected_end {
      &::after {
        position: absolute;
        left: 0;
      }
    }
    &.CalendarDay__selected_span {
      background-color: rgba(114, 36, 108, 0.12);
    }
    &.CalendarDay__hovered_span,
    &.CalendarDay__hovered_span:hover {
      background: none;
      border: initial;
      color: initial;
    }
  }
  ${props =>
    props.extendAccess &&
    `
    .CalendarDay {
      &.CalendarDay__selected_start,
      &.CalendarDay__selected_end {
        & > .CalendarEventText {
        background: none;
        color: #282937;
        }
        &:hover {
          & > .CalendarEventText {
            background-color: #efefef;
            color: #282937;
          }
        }
        &::after {
          width: 100%;
        }
      }
    }
    `}
  ${props =>
    props.isStartSelected &&
    `
    .CalendarDay {
      &.CalendarDay__selected_start {
        &::after {
          position: absolute;
          left: 0;
        }
      }
    }
    `}
`;

const StyledArrowRight = styled(ArrowRight)`
  fill: ${props => props.theme.colors.primaryPurple};
  transform: rotate(90deg);
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledArrowLeft = styled(ArrowLeft)`
  fill: ${props => props.theme.colors.primaryPurple};
  transform: rotate(270deg);
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;
