import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ContentSkillsFollow, FollowSkillIF, FollowSubskillIF } from 'components/organisms/ContentSkillsFollow';
import { userSubskillsPrefSelector, userSkillsPrefSelector, userPrefSelector } from 'modules/user/selectors';
import { toggleSkillPrefAndUpdateGoldenRecord, toggleSubskillPrefAndUpdateGoldenRecord } from 'modules/user/actions';
import { contentSubskillsFollowSelector, contentSkillsFollowSelector } from 'modules/content/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  toggleSkillPref(skillId: string, isOnEvent: boolean): void {
    dispatch(toggleSkillPrefAndUpdateGoldenRecord(skillId, isOnEvent));
  },

  toggleSubskillPref(skillId: string, subskillId: string, isOnEvent: boolean): void {
    dispatch(toggleSubskillPrefAndUpdateGoldenRecord(skillId, subskillId, isOnEvent));
  },
});

const mapStateToProps = (initState: State.Root) => {
  const skills: FollowSkillIF[] = contentSkillsFollowSelector(initState);
  const subskills: FollowSubskillIF[] = contentSubskillsFollowSelector(initState);

  return (state: State.Root) => ({
    userSkillsPref: userSkillsPrefSelector(state),
    userSubskillsPref: userSubskillsPrefSelector(state),
    userPref: userPrefSelector(state),
    skills,
    subskills,
  });
};

export const ContentSkillsFollowContainer = connect(mapStateToProps, mapActionCreators)(ContentSkillsFollow);
