import React, { useEffect, useCallback, useState, useMemo, useRef, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getCimaMembershipPackagePath, getPath, getContentUrl, getModalData } from 'utils';
import { Admin as AdminUtils, ContentfulHelpers, Utils } from 'mxp-utils';
import { MembershipPackageAccordionStatus } from '../../../constants';
import { FeedPlaceholder, Grid, NotificationBanner, NotificationBannerEnums } from 'components/atoms';
import {
  fetchMembershipTiers,
  setMembershipPackageTier,
  fetchExemptionLevel,
  setFlpPersonExam,
} from 'modules/membership/actions';
import { QUERY_MEMBERSHIP_PRODUCT_BLOCK } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import {
  fetchExemptionEngine,
  setSelectedExemption,
  setPrevSelectedExemptReference,
} from 'modules/exemptionEngine/action';
import { hasPromocodeExemptionSelector } from 'modules/cart/selectors';
import {
  personAccountDataSelector,
  studentProgressionSelector,
  isAuthSelector,
  currentJourneyLearningPathwaySelector,
  aicpaMembershipSelector,
  isCimaMemberSelector,
  activeQualificationLevelSelector,
} from 'modules/user/selectors';
import {
  membershipLoadingSelector,
  membershipTiersSelector,
  membershipTypesSelector,
  isFLPUpgradeSelector,
  isFLPSwitchSelector,
  isFLPSwitchOrUpgradeSelector,
  membershipInviteDataSelector,
} from 'modules/membership/selectors';
import { isAdminPortalSelector, constantsSelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { CimaMembershipTypeCard } from 'components/molecules/CimaMembershipTypeCard/CimaMembershipTypeCard';
import { Routes } from 'constants/index';
import { MembershipTypes, Content, ExemptionLevels, User } from 'mxp-schemas';
import { FLPMapping, getExemptionLevelOverride } from 'utils/productItemHelpers';
import { selectedExemptionSelector } from 'modules/exemptionEngine/selector';
import { maxNumberOfExperienceDataSelector, numberOfExperienceDataSelector } from 'modules/personLevelExemption';
import { push } from 'connected-react-router';
import { selectedPassedUSCPAExamSelector } from 'modules/exemptionProfessional/selectors';
import { USCpaExamEnum } from 'modules/exemptionProfessional/constants';

interface CimaMembershipEntryPointPanelProps {
  setSwitchedEntryPoint?: Dispatch<SetStateAction<string>>;
}

export const CimaMembershipEntryPointPanel: React.FC<CimaMembershipEntryPointPanelProps> = ({
  setSwitchedEntryPoint,
}) => {
  // TODO: remove later when entry point is available
  const dispatch = useDispatch();
  const history = useHistory();
  const membershipLoading = useSelector(membershipLoadingSelector);
  const aicpaMembershipBody = useSelector(aicpaMembershipSelector);
  const membershipTiers = useSelector(membershipTiersSelector).list;
  const personAccountData = useSelector(personAccountDataSelector);
  const { list } = useSelector(membershipTypesSelector);
  const selectedPassedUSCPAExam = useSelector(selectedPassedUSCPAExamSelector);
  const isPromocodeAvailable = useSelector(hasPromocodeExemptionSelector);

  // firm billing
  const inviteData = useSelector(membershipInviteDataSelector);

  const regionalProductsDetails = useRef<any>(null);

  const handleSelectType = useCallback(
    (id: string, typeSlug: string) => {
      history.push(getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_PROFESSIONAL));
    },
    [history]
  );
  const currentJourneyLearningPathway = useSelector(currentJourneyLearningPathwaySelector);
  const isCimaPqCurrentJourney = AdminUtils.isCimaPqPathway(currentJourneyLearningPathway);
  const isChinesePathwayCurrentJourney = AdminUtils.isChinesePathway(currentJourneyLearningPathway);
  const isApprenticeshipCurrentJourney = AdminUtils.isApprenticePathway(currentJourneyLearningPathway);
  const isFlpCurrentJourney = AdminUtils.isFlpPathway(currentJourneyLearningPathway);
  const isFlpToPqMembershipJourney =
    personAccountData?.learningPathway === MembershipTypes.Pathway.FLP && isCimaPqCurrentJourney;

  const showPremiumProductCard =
    isCimaPqCurrentJourney &&
    !isChinesePathwayCurrentJourney &&
    !isApprenticeshipCurrentJourney &&
    !isFlpCurrentJourney;
  const numberOfExp = useSelector(numberOfExperienceDataSelector);
  const maxNumberOfExp = useSelector(maxNumberOfExperienceDataSelector);
  const apprenticeshipProduct = list.find(
    pathway => pathway.slug === MembershipTypes.CIMALearningPathwaySlug.APPRENTICESHIP
  );
  const flpAndCimaPQ = list.find(
    pathway => pathway.slug === MembershipTypes.LearningPathwaySlug[currentJourneyLearningPathway]
  );
  const constants = useSelector(constantsSelector);
  const isAdmin = useSelector(isAdminPortalSelector);
  const isAuth = useSelector(isAuthSelector);
  const isImpersonated = Boolean(isAuth) && isAdmin;
  const isCimaMember = useSelector(isCimaMemberSelector);
  // From backend
  const isFLPUpgrade = useSelector(isFLPUpgradeSelector);
  const isFLPSwitch = useSelector(isFLPSwitchSelector);
  const flpSwitchUpgrade = useSelector(isFLPSwitchOrUpgradeSelector);
  const studentProgression = useSelector(studentProgressionSelector);
  const selectedExemption = useSelector(selectedExemptionSelector);
  const activeQualificationLevel = useSelector(activeQualificationLevelSelector);
  const isChinesePQPathway = currentJourneyLearningPathway === MembershipTypes.Pathway.CHINESE_PQ;

  const completedExam: any[] = [];
  const completedLevels: any = Boolean(studentProgression?.qualificationLevels.length)
    ? studentProgression?.qualificationLevels
        ?.filter((qualification: any) => qualification?.status === User.QualificationLevelStatus.Completed)
        .map((qualificationLevel: any) => {
          // For Management Level Only
          if (qualificationLevel?.entryPointLevel === 4) {
            return completedExam.push(5, 4);
          }
          // For Operational Only
          if (qualificationLevel?.entryPointLevel === 2) {
            return completedExam.push(3, 2);
          }
          // For Foundation Level exams will be created
          if (qualificationLevel?.entryPointLevel === 1) {
            return;
          }
          return completedExam.push(qualificationLevel?.entryPointLevel);
        })
    : null;

  const initState = {
    id: '',
    name: '',
    title: '',
    description: '',
    exemptionLevelKey: '',
    lowerExemptionLevelRef: [],
    masterExamReference: [],
    highestExemptionReference: {},
  };
  const [exemptionInfo, setExemptionInfo] = useState(initState);
  const [lowerLevel, setLowerLevel] = useState([]);
  const [parentExemption, setParentExemption] = useState(initState);
  const [FLPEntryLevel, setFLPEntryLevel] = useState(0);
  const [currentLevel, setCurrentLevel]: any = useState(undefined);
  const [isLevelAssigned, setIsLevelAssigned]: any = useState(false);
  // Input for choose your package
  const [selectedExemptionMaster, setSelectedExemptionMaster] = useState(initState);
  const [handler, sethandler] = useState(0);
  const personExam: any[] = [];
  const isAicpaRegularMember = useMemo(() => {
    return aicpaMembershipBody?.membershipTerm?.membershipTermType === MembershipTypes.MembershipKeys.REGULAR;
  }, [aicpaMembershipBody]);

  const isLicensedBefore =
    isFlpCurrentJourney && selectedPassedUSCPAExam === USCpaExamEnum.YES_LICENSED_BEFORE ? true : isAicpaRegularMember;

  const isRegionalPathway = useMemo(
    () =>
      Number.parseInt(maxNumberOfExp, 10) >=
      Number.parseInt(constants?.[CONSTANTS.CIMA_REGIONAL_QUALIFIED_YEARS_OF_EXPERIENCE], 10),
    [constants, maxNumberOfExp]
  );

  const membershipCandidateSku = membershipTiers?.find(tiers => tiers.tierCode === MembershipTypes.TierCode.CORE)?.sku;

  const setUserSelectedExemption = useCallback(
    (exemption: string) => {
      dispatch(setSelectedExemption(exemption));
    },
    [dispatch]
  );

  useEffect(() => {
    // moved all entrypoint level calculation to backend
    // if account is not active/qualification level is not available
    // set currentLevel to null
    setCurrentLevel(activeQualificationLevel);
    setIsLevelAssigned(true);
  }, [activeQualificationLevel]);

  useEffect(() => {
    if (isRegionalPathway && !regionalProductsDetails?.current && showPremiumProductCard) {
      (async () => {
        await request(QUERY_MEMBERSHIP_PRODUCT_BLOCK(constants?.[CONSTANTS.CIMA_PREMIUM_PRODUCT_REFERENCE_SLUG])).then(
          async (data: any) => {
            const productDetails = data?.block?.[0] || {};
            const cmsProductsData = productDetails?.cmsProducts?.[0] || [];

            regionalProductsDetails.current = {
              ...cmsProductsData,
              hasDiscount: false,
              title: '',
              name: productDetails?.header || 'Premier programmes',
              description: productDetails?.description,
              entryPoint: {
                description: productDetails?.description,
              },
              modalDescription: '',
            };
          }
        );
      })();
    }
  }, [isRegionalPathway, regionalProductsDetails, constants, numberOfExp, showPremiumProductCard, inviteData]);

  useEffect(() => {
    if (!isLevelAssigned) return;
    if (handler === 0) {
      dispatch(
        fetchExemptionEngine(
          personAccountData.id,
          currentJourneyLearningPathway,
          Boolean(isLicensedBefore),
          flpSwitchUpgrade || isFlpToPqMembershipJourney,
          isCimaMember && Number(currentLevel) ? `${currentLevel}` : null
        )
      )
        .then((data: any) => {
          const flpExemptionInfo = flpSwitchUpgrade
            ? getExemptionLevelOverride(
                FLPMapping(studentProgression),
                data?.payload?.exemptionEngine?.lowerExemptionLevelRef
              )
            : initState;

          // Always get the lowest level if the pathway isChinesePQPathway (Management CS)
          const chinesePQExemption = {
            id: data?.payload?.exemptionEngine?.lowerExemptionLevelRef?.[0]?.id,
            name: data?.payload?.exemptionEngine?.lowerExemptionLevelRef?.[0]?.name,
            description: data?.payload?.exemptionEngine?.lowerExemptionLevelRef?.[0]?.description,
            title: data?.payload?.exemptionEngine?.lowerExemptionLevelRef?.[0]?.title,
            exemptionLevelKey: data?.payload?.exemptionEngine?.lowerExemptionLevelRef?.[0]?.exemptionLevelKey,
            masterExamReference: data?.payload?.exemptionEngine?.lowerExemptionLevelRef?.[0]?.masterExamReference,
          };
          const hasLowerExemptionLevelRefForChinesePQ = [isChinesePQPathway, !!chinesePQExemption?.id].every(
            flag => flag
          );

          if (hasLowerExemptionLevelRefForChinesePQ) {
            setExemptionInfo({
              highestExemptionReference: data?.payload?.exemptionEngine?.highestExemptionReference,
              lowerExemptionLevelRef: data?.payload?.exemptionEngine?.lowerExemptionLevelRef,
              ...chinesePQExemption,
            });
          } else {
            setExemptionInfo({
              id: flpSwitchUpgrade && flpExemptionInfo ? flpExemptionInfo?.id : data?.payload?.exemptionEngine?.id,
              name:
                flpSwitchUpgrade && flpExemptionInfo ? flpExemptionInfo?.name : data?.payload?.exemptionEngine?.name,
              description:
                flpSwitchUpgrade && flpExemptionInfo
                  ? flpExemptionInfo?.description
                  : data?.payload?.exemptionEngine?.description,
              title:
                flpSwitchUpgrade && flpExemptionInfo ? flpExemptionInfo?.title : data?.payload?.exemptionEngine?.title,
              exemptionLevelKey: data?.payload?.exemptionEngine?.exemptionLevelKey,
              highestExemptionReference: data?.payload?.exemptionEngine?.highestExemptionReference,
              lowerExemptionLevelRef: data?.payload?.exemptionEngine?.lowerExemptionLevelRef,
              masterExamReference:
                flpSwitchUpgrade && flpExemptionInfo
                  ? flpExemptionInfo?.masterExamReference
                  : data?.payload?.exemptionEngine?.masterExamReference,
            });
          }

          setSelectedExemptionMaster({
            id: data?.payload?.exemptionEngine?.id,
            name: data?.payload?.exemptionEngine?.name,
            description: data?.payload?.exemptionEngine?.description,
            title: data?.payload?.exemptionEngine?.title,
            exemptionLevelKey: data?.payload?.exemptionEngine?.exemptionLevelKey,
            highestExemptionReference: data?.payload?.exemptionEngine?.highestExemptionReference,
            lowerExemptionLevelRef: data?.payload?.exemptionEngine?.lowerExemptionLevelRef,
            masterExamReference: data?.payload?.exemptionEngine?.masterExamReference,
          });

          if (hasLowerExemptionLevelRefForChinesePQ) {
            setUserSelectedExemption(chinesePQExemption.title);
          } else {
            setUserSelectedExemption(data?.payload?.exemptionEngine?.title);
          }

          data?.payload?.exemptionEngine?.lowerExemptionLevelRef
            .filter(
              (lowerExemptions: any) => !completedExam.includes(Utils.replaceStringToNumber(lowerExemptions?.name))
            )
            .forEach((master: any) => {
              return master.masterExamReference.forEach((subject: any) => {
                return personExam.push({
                  personAccountId: personAccountData.id,
                  reason: subject.name.trim(),
                  subjectId: subject.id,
                  ...(isPromocodeAvailable && { isPromoCode: true }),
                });
              });
            });

          dispatch(setFlpPersonExam(personExam));
        })
        .catch((err: any) => {
          setExemptionInfo(initState);
        });
    }
    sethandler(1);
  }, [
    isLevelAssigned,
    initState,
    personAccountData.id,
    isFlpCurrentJourney,
    dispatch,
    handler,
    setUserSelectedExemption,
    flpSwitchUpgrade,
    studentProgression,
    selectedExemption,
    currentJourneyLearningPathway,
    isLicensedBefore,
    currentLevel,
    isFLPSwitch,
    isPromocodeAvailable,
    personExam,
    completedExam,
    completedLevels,
    isFlpToPqMembershipJourney,
    isCimaMember,
    isChinesePQPathway,
    selectedExemptionMaster,
  ]);

  useEffect(() => {
    if (selectedExemptionMaster) {
      dispatch(setPrevSelectedExemptReference(selectedExemptionMaster?.title));
    }
  }, [selectedExemptionMaster, dispatch]);

  useEffect(() => {
    if (selectedExemptionMaster) {
      dispatch(setPrevSelectedExemptReference(selectedExemptionMaster?.title));
    }
  }, [selectedExemptionMaster, dispatch]);
  useEffect(() => {
    setParentExemption({
      ...exemptionInfo,
    });
    setFLPEntryLevel(Number(currentLevel));
  }, [exemptionInfo, studentProgression, flpSwitchUpgrade, currentLevel]);

  useEffect(() => {
    if (list?.length === 0 || (!flpAndCimaPQ && !apprenticeshipProduct)) {
      dispatch(
        push({
          pathname: getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY),
        })
      );
    }
  }, [flpAndCimaPQ, apprenticeshipProduct, list, dispatch]);
  const handleSubmit = useCallback(async () => {
    if (isImpersonated && isChinesePathwayCurrentJourney) {
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Pathway));
    } else if (isFLPUpgrade || isFLPSwitch) {
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Skill));
    } else if (isCimaPqCurrentJourney) {
      dispatch(setMembershipPackageTier(membershipCandidateSku));
      if (!inviteData?.isPaidByFirm) {
        history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Addons), {
          skippedAccordionKey: MembershipPackageAccordionStatus.Pathway,
        });
      } else {
        history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Summary), { showSummaryPage: true });
      }
    } else if (isApprenticeshipCurrentJourney) {
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Summary), { showSummaryPage: true });
    } else {
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Skill));
    }
    dispatch(
      fetchExemptionLevel(
        selectedExemptionMaster?.id,
        currentJourneyLearningPathway,
        isCimaMember && Number(currentLevel) ? `${currentLevel}` : null
      )
    )
      .then((rec: any) => {
        const { lowerExemptionLevelRef } = rec?.payload?.getExemptionLevelReferenceById;
        if (lowerExemptionLevelRef.length >= 1) {
          const loweLevel = lowerExemptionLevelRef?.filter(
            (lowerExemptions: any) => !completedExam.includes(Utils.replaceStringToNumber(lowerExemptions?.name))
          );

          loweLevel.forEach((master: any) => {
            return master.masterExamReference.forEach((subject: any) => {
              return personExam.push({
                personAccountId: personAccountData.id,
                reason: subject.name.trim(),
                subjectId: subject.id,
                ...(isPromocodeAvailable && { isPromoCode: true }),
              });
            });
          });
        }

        dispatch(setFlpPersonExam(personExam));
      })
      .catch((err: any) => {
        return err;
      });
  }, [
    dispatch,
    isChinesePathwayCurrentJourney,
    isImpersonated,
    personAccountData.id,
    personExam,
    history,
    isCimaPqCurrentJourney,
    isApprenticeshipCurrentJourney,
    selectedExemptionMaster,
    isFLPUpgrade,
    isFLPSwitch,
    membershipCandidateSku,
    inviteData,
    currentJourneyLearningPathway,
    isPromocodeAvailable,
    currentLevel,
    completedExam,
    isCimaMember,
  ]);

  const recommendedSubmitHandler = useCallback(async () => {
    if (isImpersonated && isRegionalPathway) {
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Pathway));
    } else if (!isImpersonated && isRegionalPathway) {
      history.push(
        getContentUrl({
          contentCategory: { name: '', slug: Content.CategorySlugs.RESOURCES },
          contentfulType: ContentfulHelpers.CONTENT_TYPES.STATIC_LANDING_PAGE,
          slug: constants?.[CONSTANTS.CIMA_REGIONAL_PATHWAY_PROP_PAGE_SLUG],
        })
      );
    } else if (isCimaPqCurrentJourney) {
      dispatch(setMembershipPackageTier(membershipCandidateSku));
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Addons));
    } else if (isApprenticeshipCurrentJourney) {
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Summary), { showSummaryPage: true });
    } else {
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Skill));
    }

    setSelectedExemptionMaster(exemptionInfo);
    setUserSelectedExemption(exemptionInfo?.title);

    dispatch(
      fetchExemptionLevel(
        exemptionInfo?.id,
        currentJourneyLearningPathway,
        Number(currentLevel) ? `${currentLevel}` : null
      )
    )
      .then((rec: any) => {
        const { lowerExemptionLevelRef } = rec?.payload?.getExemptionLevelReferenceById;
        if (lowerExemptionLevelRef.length >= 1) {
          const loweLevel = lowerExemptionLevelRef.filter(
            (lowerExemptions: any) => !completedExam.includes(Utils.replaceStringToNumber(lowerExemptions?.name))
          );

          loweLevel.forEach((master: any) => {
            return master.masterExamReference.forEach((subject: any) => {
              return personExam.push({
                personAccountId: personAccountData.id,
                reason: subject.name,
                subjectId: subject.id,
                ...(isPromocodeAvailable && { isPromoCode: true }),
              });
            });
          });
        }

        dispatch(setFlpPersonExam(personExam));
      })
      .catch((err: any) => {
        return err;
      });
  }, [
    dispatch,
    setUserSelectedExemption,
    personAccountData.id,
    personExam,
    history,
    isCimaPqCurrentJourney,
    isApprenticeshipCurrentJourney,
    exemptionInfo,
    constants,
    isImpersonated,
    isRegionalPathway,
    membershipCandidateSku,
    currentJourneyLearningPathway,
    isPromocodeAvailable,
    currentLevel,
    completedExam,
  ]);
  useEffect(() => {
    if (isCimaPqCurrentJourney || isApprenticeshipCurrentJourney) dispatch(fetchMembershipTiers());
  }, [dispatch, isCimaPqCurrentJourney, isApprenticeshipCurrentJourney]);

  useEffect(() => {
    setLowerLevel(exemptionInfo?.lowerExemptionLevelRef);
  }, [exemptionInfo]);
  useEffect(() => {
    if (isFLPUpgrade) {
      dispatch(fetchMembershipTiers());
    }
  }, [dispatch, isFLPUpgrade]);

  if (membershipLoading) {
    return <FeedPlaceholder />;
  }

  const removeDuplicateCards = (data: any, key: any) => {
    return [...new Map(data?.map((level: any) => [key(level), level]))?.values()];
  };
  const filteredLowerLevel = removeDuplicateCards(lowerLevel, (level: any) => level.name);

  const modalDescription =
    'You can adjust your entry point if you would like to study a previous level, or if your employer has requested that you do so. Note: By selecting a previous entry point, you will relinquish your right to revert to a higher entry point in the future. Upon sign-up, your exemptions cannot be re-evaluated.';
  const tempModalData = getModalData(filteredLowerLevel, isFlpCurrentJourney);

  const entryPointModalExamList = exemptionInfo?.masterExamReference
    ?.filter((f: any) => f?.name)
    ?.map((master: any) => {
      return {
        id: master.id,
        entryPointLevelExam: master.name,
      };
    });
  const entryPointModalDataList = [
    ...tempModalData,
    {
      id: exemptionInfo?.id,
      cardIndex: exemptionInfo?.name?.replace(/^\D+/g, ''),
      title: exemptionInfo?.title,
      levelName: exemptionInfo?.name,
      levelDescription: exemptionInfo?.description,
      listTitle: exemptionInfo?.description,
      entryPointLevelExamList: entryPointModalExamList,
    },
  ].sort((a, b) => (a.cardIndex > b.cardIndex ? 1 : -1));

  const modalHeader = tempModalData?.length >= 1 ? `Select another entry point` : 'No Lower Level';

  const setNewEntryPoint = (newEntryPoint: string) => {
    if (isFLPSwitch || isFLPUpgrade) {
      if (!newEntryPoint) {
        setSwitchedEntryPoint?.(exemptionInfo?.title);
      } else {
        setSwitchedEntryPoint?.(newEntryPoint);
      }
    }
  };

  const apprenticeMaxLevel = 6;
  return (
    <StyledContainer>
      {isApprenticeshipCurrentJourney && (
        <StyledNotificationBanner
          testId="notification-banner"
          childrenTestId="notification-children"
          closeActionTestId="notification-close"
          variant={
            Number(exemptionInfo?.name.replace(/^\D+/g, '')) === apprenticeMaxLevel
              ? NotificationBannerEnums.variant.red
              : NotificationBannerEnums.variant.blue
          }
          icon={<StyledIconError />}
        >
          {Number(exemptionInfo?.name.replace(/^\D+/g, '')) === apprenticeMaxLevel
            ? 'Based on your exemption level, you may not be eligible for apprenticeship funding.'
            : 'Your learning path has already been chosen by your training provider.'}
        </StyledNotificationBanner>
      )}
      <Grid>
        <Grid.Row className="flex-row" columns={2}>
          {isApprenticeshipCurrentJourney && exemptionInfo?.name ? (
            <React.Fragment>
              <Grid.Column className="flex-column">
                <CimaMembershipTypeCard
                  {...apprenticeshipProduct}
                  isCardSelected={true}
                  isEntryPointLevel={true}
                  selectMembership={handleSelectType}
                  entryPoint={{
                    headerTitle: parentExemption.name === '' ? '' : Utils.replaceStringToNumber(exemptionInfo?.name),
                    description: exemptionInfo?.description,
                    entryPointLevelExamList:
                      exemptionInfo?.masterExamReference?.length >= 1
                        ? exemptionInfo?.masterExamReference?.map((master: any) => {
                            return {
                              entryPointLevelExam: master.name,
                            };
                          })
                        : [],
                    isSelected: true,
                  }}
                  productTypeSubNotes="Sponsored by your agreed employer"
                  exemptionInfo={exemptionInfo}
                  setSelectedExemptionMaster={setSelectedExemptionMaster}
                  selectedLevelSubmitHandler={handleSubmit}
                  isApprenticeshipCard={true}
                  setUserSelectedExemption={setUserSelectedExemption}
                />
              </Grid.Column>
              {showPremiumProductCard && !isFLPUpgrade && !isFLPSwitch && isRegionalPathway && (
                <Grid.Column className="flex-column">
                  {exemptionInfo?.name && regionalProductsDetails.current ? (
                    <CimaMembershipTypeCard
                      name={'Premier programmes'}
                      isCardSelected={false}
                      isEntryPointLevel={true}
                      selectMembership={handleSelectType}
                      hasDiscount
                      isRecommended
                      modalHeader={modalHeader}
                      modalDescription={modalDescription}
                      entryPointDataList={tempModalData}
                      entryPoint={{
                        headerTitle:
                          parentExemption.name === '' ? '' : Utils.replaceStringToNumber(exemptionInfo?.name),
                        description: exemptionInfo?.description,
                        entryPointLevelExamList:
                          exemptionInfo?.masterExamReference?.length >= 1
                            ? exemptionInfo?.masterExamReference?.map((master: any) => {
                                return {
                                  entryPointLevelExam: master.name,
                                };
                              })
                            : [],
                        isSelected: true,
                      }}
                      exemptionInfo={exemptionInfo}
                      setSelectedExemptionMaster={setSelectedExemptionMaster}
                      setUserSelectedExemption={setUserSelectedExemption}
                      selectedLevelSubmitHandler={recommendedSubmitHandler}
                      isRegionalPathway={isRegionalPathway}
                      {...(isRegionalPathway && regionalProductsDetails.current)}
                    />
                  ) : (
                    <FeedPlaceholder />
                  )}
                </Grid.Column>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* Chinese PQ / Regional Pathway Card */}
              <Grid.Column className="flex-column">
                {exemptionInfo?.name ? (
                  <CimaMembershipTypeCard
                    {...flpAndCimaPQ}
                    isCardSelected={true}
                    isEntryPointLevel={true}
                    selectMembership={handleSelectType}
                    hasDiscount
                    modalHeader={modalHeader}
                    modalDescription={modalDescription}
                    entryPointDataList={entryPointModalDataList}
                    levelNameValidateText={
                      flpSwitchUpgrade ? Object.values(ExemptionLevels.LevelNameFLP)[FLPEntryLevel + 1] : ''
                    }
                    entryPoint={{
                      headerTitle: flpSwitchUpgrade
                        ? FLPEntryLevel
                        : parentExemption?.name
                        ? Utils.replaceStringToNumber(parentExemption.name)
                        : '',
                      description: parentExemption?.name ? parentExemption.description : exemptionInfo?.description,
                      entryPointLevelExamList: !!parentExemption.masterExamReference.length
                        ? parentExemption?.masterExamReference?.map((data: any) => {
                            return {
                              id: data.id,
                              entryPointLevelExam: data?.name ? data?.name : data?.entryPointLevelExam,
                            };
                          })
                        : [],
                      isSelected: true,
                    }}
                    setParentExemption={setParentExemption}
                    exemptionInfo={exemptionInfo}
                    setSelectedExemptionMaster={setSelectedExemptionMaster}
                    selectedLevelSubmitHandler={handleSubmit}
                    setUserSelectedExemption={setUserSelectedExemption}
                    setNewEntryPoint={setNewEntryPoint}
                    activeLevel={selectedExemptionMaster.name}
                  />
                ) : (
                  <FeedPlaceholder />
                )}
              </Grid.Column>
              {!isFLPUpgrade &&
                !isFLPSwitch &&
                isRegionalPathway &&
                showPremiumProductCard &&
                !inviteData?.isPaidByFLP &&
                !inviteData?.isPaidByFirm && (
                  <Grid.Column className="flex-column">
                    {exemptionInfo?.name && regionalProductsDetails.current ? (
                      <CimaMembershipTypeCard
                        {...flpAndCimaPQ}
                        name={isRegionalPathway ? 'Premier programmes' : flpAndCimaPQ?.name}
                        isCardSelected={false}
                        isEntryPointLevel={true}
                        selectMembership={handleSelectType}
                        hasDiscount
                        isRecommended
                        modalHeader={modalHeader}
                        modalDescription={modalDescription}
                        entryPointDataList={tempModalData}
                        entryPoint={{
                          headerTitle:
                            parentExemption.name === '' ? '' : Utils.replaceStringToNumber(exemptionInfo?.name),
                          description: exemptionInfo?.description,
                          entryPointLevelExamList:
                            exemptionInfo?.masterExamReference?.length >= 1
                              ? exemptionInfo?.masterExamReference.map((master: any) => {
                                  return {
                                    entryPointLevelExam: master.name,
                                  };
                                })
                              : [],
                          isSelected: true,
                        }}
                        exemptionInfo={exemptionInfo}
                        setSelectedExemptionMaster={setSelectedExemptionMaster}
                        setUserSelectedExemption={setUserSelectedExemption}
                        selectedLevelSubmitHandler={recommendedSubmitHandler}
                        isRegionalPathway={isRegionalPathway}
                        {...(isRegionalPathway && regionalProductsDetails.current)}
                      />
                    ) : (
                      <FeedPlaceholder />
                    )}
                  </Grid.Column>
                )}
            </React.Fragment>
          )}
        </Grid.Row>
      </Grid>
    </StyledContainer>
  );
};
const StyledContainer = styled.div`
  min-width: ${props => props.theme.pxToRem(850)};
  min-height: ${props => props.theme.pxToRem(200)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-width: 100% !important;
    width: 100% !important;
  }
`;
const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(25)};
`;
const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  align-self: flex-start;
  ${props => props.theme.mediaQueries.mobileOnly} {
    align-self: center;
  }
  transform: rotateX(180deg);
`;
