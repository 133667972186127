import React from 'react';
import styled from 'styled-components';
import { singleWordHighlight } from '../../../utils/StringHelpers';
import { Divider } from '../../atoms';

interface ListPropsIF {
  suggestions: State.SuggestionResult[];
  suggestionSource: string;
  searchNavigate(search: string): void;
}

interface ItemPropsIF {
  suggestion: State.SuggestionResult;
  suggestionSource: string;
  searchNavigate(search: string): void;
}

export const SearchQueryResultList: React.FC<ListPropsIF> = ({ suggestions, suggestionSource, searchNavigate }) => (
  <StyledSearchQueryResultList data-testid="search-query-suggestion-list">
    {suggestions &&
      suggestions.map((suggestion: any) => (
        <React.Fragment key={suggestion.value}>
          <SearchQueryResultItem
            suggestion={suggestion}
            suggestionSource={suggestionSource}
            searchNavigate={searchNavigate}
          />
          <Divider />
        </React.Fragment>
      ))}
  </StyledSearchQueryResultList>
);

const SearchQueryResultItem: React.FC<ItemPropsIF> = ({ suggestion, suggestionSource, searchNavigate }) => {
  const handleClick = React.useCallback(() => {
    searchNavigate(suggestion.value);
  }, [searchNavigate, suggestion.value]);

  return (
    <StyledSearchQueryResult onClick={handleClick}>
      <span>{singleWordHighlight(suggestion.value, suggestionSource, SuggestionHighlight)}</span>
      <StyledOccurrence>
        {suggestion.occurrence} {suggestion.occurrence > 1 ? 'results' : 'result'}
      </StyledOccurrence>
    </StyledSearchQueryResult>
  );
};

const StyledSearchQueryResultList = styled.ul`
  padding-left: 0;
  list-style: none;
  line-height: normal;
`;

const StyledSearchQueryResult = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 20px 44px;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  cursor: pointer;
`;

const StyledOccurrence = styled.span`
  font-weight: ${props => props.theme.fontWeights.light}
  color: ${props => props.theme.colors.neutralGrey7};
  min-width: 3rem;
`;

const SuggestionHighlight = styled.span`
  font-weight: ${props => props.theme.fontWeights.light};
`;
