import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { getTopicsHash } from './actions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [getTopicsHash]: {
    next: (state: State.Topics, action: any): State.Topics => ({
      ...state,
      topicsHash: action.payload,
      topicsFetched: true,
    }),

    throw: (state: State.Topics, action: any): State.Topics => ({
      ...state,
      error: action.payload, // TODO
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
