import { createSelector } from 'reselect';
import { Contentful } from 'mxp-schemas';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.staticLandingPagesContent,
  (staticLandingPagesContent: State.StaticLandingPagesContent): State.StaticLandingPagesContent =>
    staticLandingPagesContent
);

export const staticLandingPageFetchedSelector = createSelector(
  rootSelector,
  (staticLandingPagesContent: State.StaticLandingPagesContent): boolean =>
    staticLandingPagesContent.staticLandingPageFetched
);

export const staticLandingPageSelector = createSelector(
  rootSelector,
  (staticLandingPagesContent: State.StaticLandingPagesContent): Contentful.StaticLandingPages.IParsedPage | any =>
    staticLandingPagesContent.staticLandingPage
);

export const staticLandingPageMainColumnSelector = createSelector(
  staticLandingPageSelector,
  (staticLandingPagesContent: Contentful.StaticLandingPages.IParsedPage): any | [] =>
    staticLandingPagesContent.mainColumn
);

export const staticLandingPageAccessRestrictionSelector = createSelector(
  staticLandingPageSelector,
  (staticLandingContent: State.ContentItem | null): any | null => staticLandingContent?.accessRestriction
);

export const skipStaticLandingPageFrontloadRequestSelector = createSelector(
  rootSelector,
  (staticLandingPagesContent: State.StaticLandingPagesContent): boolean =>
    staticLandingPagesContent.skipStaticLandingPageFrontloadRequest
);

export const staticLandingPageContentTypeSlugSelector = createSelector(
  staticLandingPageAccessRestrictionSelector,
  (staticLandingContentTypeBlock: any | null): string =>
    (staticLandingContentTypeBlock?.contentType?.slug as string) || ''
);
