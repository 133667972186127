import React from 'react';
import { Modal, Button as StyledButton, ButtonEnums, OnlyDesktopCSS, OnlyMobileCSS, Divider } from 'components/atoms';
import styled from 'styled-components/macro';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { MembershipPackageAccordionStatus } from '../../../constants';
import { useHistory } from 'react-router';
import { getMembershipPackagePath, getCimaMembershipPackagePath, getPath } from 'utils';
import { useSelector } from 'react-redux';
import { isCimaMembershipJourneySelector } from 'modules/membership/selectors';
import { Routes } from 'constants/index';
interface Props {
  visible: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  isUserMember?: boolean;
  isSection?: boolean;
  onClickConfirmButton?: () => void;
}

export const ModalApplyMembership: React.FC<Props> = ({
  visible,
  setVisibility,
  isUserMember = false,
  isSection = false,
  onClickConfirmButton,
}) => {
  const history = useHistory();
  const isCimaMembershipJourney = useSelector(isCimaMembershipJourneySelector);

  const onClickConfirm = React.useCallback(async () => {
    history.push(
      isCimaMembershipJourney
        ? getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Type)
        : getMembershipPackagePath(MembershipPackageAccordionStatus.Type)
    );
    setVisibility(!visible);
    if (onClickConfirmButton) onClickConfirmButton();
  }, [history, isCimaMembershipJourney, visible, setVisibility, onClickConfirmButton]);

  const onClickCancel = React.useCallback(() => {
    history.push(getPath(Routes.ROOT));
  }, [history]);

  return (
    <StyledModal size="tiny" heading={isSection ? 'Become an AICPA member.' : 'Apply for a membership'} open={visible}>
      <CloseButton>
        <StyledCloseIcon onClick={onClickCancel} />
      </CloseButton>
      <Spacer />
      <CenteredText>
        {isSection ? (
          <>
            <StyledText>To join this section, you must first be an AICPA member.</StyledText>
            <br />
            <br />
            <StyledText>
              If you are not eligible for an AICPA membership, you can still purchase a subscription.
            </StyledText>
            <br />
            <br />
            <StyledText style={{ fontWeight: 'bold' }}>What is AICPA membership?</StyledText>
            <br />
            <StyledText>
              AICPA membership provides you with I access to the latest industry news. technical updates and quality
              CPE. Most importantly. you'll be connected to a I global network of more than 696.000 members. students
              and engaged professionals to help drive the profession forward.
            </StyledText>
            <br />
            <br />
            <StyledText style={{ fontWeight: 'bold' }}>What is a subscription?</StyledText>
            <br />
            <StyledText>
              A subscription is a package of essential resources, tools and guidance developed and constantly updated by
              industry I experts and available to non-AICPA members as an annual subscription.
            </StyledText>
          </>
        ) : (
          <StyledText>You need to apply for a membership to buy this product.</StyledText>
        )}
      </CenteredText>
      <DividerWithBottomMargin />
      <CenteredButtons>
        <DesktopStyled>
          <CancelButton onClick={onClickCancel} />
          <ApplyButton onClick={onClickConfirm} />
        </DesktopStyled>
        <MobileStyled>
          <CancelButton onClick={onClickCancel} />
          <ApplyButton onClick={onClickConfirm} />
        </MobileStyled>
      </CenteredButtons>
    </StyledModal>
  );
};

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;

const StyledText = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;
export interface ModalButtonProps {
  onClick: () => void;
}

const ApplyButton: React.FC<ModalButtonProps> = ({ onClick }) => (
  <StyledApplyButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId="apply-button-modal"
  >
    Apply
  </StyledApplyButton>
);

const CancelButton: React.FC<ModalButtonProps> = ({ onClick }) => (
  <StyledCancelButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.secondary}
    testId="cancel-button-modal"
  >
    Cancel
  </StyledCancelButton>
);

const StyledApplyButton = styled(StyledButton)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledCancelButton = styled(StyledButton)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
  margin-right: ${props => props.theme.pxToRem(15)} !important;
`;

const StyledModal = styled(Modal)`
  width: ${props => props.theme.pxToRem(590)};
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
