import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { ToggleButton } from 'components/atoms/ToggleButton/ToggleButton';

interface ShowHideItemsProps {
  items: any[];
  numberOfItemsShownWhenCollapsed: number;
  renderedItem: (item: string, index: boolean) => JSX.Element;
  openText: string;
  collapsedText: string;
  testId: string;
}

export const ShowHideItems: React.FC<ShowHideItemsProps> = ({
  items,
  renderedItem,
  numberOfItemsShownWhenCollapsed,
  openText,
  collapsedText,
  testId,
}) => {
  const [areAllVisible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => {
    setVisibility(!areAllVisible);
  }, [areAllVisible]);

  const itemsToDisplay: any[] = items.slice(0, areAllVisible ? items.length : numberOfItemsShownWhenCollapsed);

  return (
    <>
      {items.length > numberOfItemsShownWhenCollapsed && (
        <StyledToggleButton testId={`show-hide-toggle-${testId}`} onClick={toggleVisibility} isOpen={areAllVisible}>
          {areAllVisible ? collapsedText : openText}
        </StyledToggleButton>
      )}
      {itemsToDisplay.map((item: string, index: number) => renderedItem(item, index === itemsToDisplay.length - 1))}
    </>
  );
};

const StyledToggleButton = styled(ToggleButton)`
  font-size: 0.9rem;
  font-weight: bold;
`;
