import { handleActions } from 'redux-actions';
import { initialState, apprenticeRosterActionNames } from './constants';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [apprenticeRosterActionNames.FETCH_APPRENTICE_ROSTER]: {
    next: (state: State.ApprenticeRoster, action: any) => {
      return {
        ...state,
        allRoster: action.payload,
      };
    },
  },
  [apprenticeRosterActionNames.GET_APPRENTICEROSTER_DETAILS]: {
    next: (state: State.ApprenticeRoster, action: any) => {
      return {
        ...state,
        roster: action.payload,
      };
    },
  },
  [apprenticeRosterActionNames.RESET_RECORDS]: {
    next: (state: State.ApprenticeRoster, action: any) => {
      return {
        ...state,
        roster: [],
      };
    },
  },
  [apprenticeRosterActionNames.IS_LOADING]: {
    next: (state: State.ApprenticeRoster, action: any) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
  [apprenticeRosterActionNames.TOTAL_RECORDS]: {
    next: (state: State.ApprenticeRoster, action: any) => {
      return {
        ...state,
        totalRecords: action.payload,
      };
    },
  },
  [apprenticeRosterActionNames.RE_INITIALIZE]: {
    next: (state: State.ApprenticeRoster, action: any) => {
      return {
        ...state,
        reInitialize: action.payload,
      };
    },
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
