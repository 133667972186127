import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { addressReducer } from 'modules/address';
import adminReducer from 'modules/admin';
import appReducer from 'modules/app'; // Refactored
import userReducer from 'modules/user';
import topicsReducer from 'modules/topics';
import industriesReducer from 'modules/industries';
import trendsReducer from 'modules/trends';
import skillsReducer from 'modules/skills';
import locationsReducer from 'modules/locations';
import jobRolesReducer from 'modules/jobRoles';
import careerStagesReducer from 'modules/careerStages';
import contentLanguagesReducer from 'modules/contentLanguages';
import categoriesReducer from 'modules/categories';
import productTypesReducer from 'modules/productTypes';
import layoutsReducer from 'modules/layouts'; // Refactored
import contentReducer from 'modules/content/reducers';
import searchReducer from 'modules/search';
import headerReducer from 'modules/header';
import trendingAcrossReducer from 'modules/trendingAcross';
import featureToggleReducer from 'modules/featureToggle';
import savedItemsReducer from 'modules/savedItems';
import savedBlogPostsReducer from 'modules/savedBlogPosts';
import notificationsReducer from 'modules/notifications';
import popularSearchesReducer from 'modules/popularSearches';
import checkoutReducer from 'modules/checkout/reducers';
import productsReducer from 'modules/products/reducers';
import cartReducer from 'modules/cart';
import receiptsReducer from 'modules/receipts';
import individualConsentsReducer from 'modules/consents';
import landingPagesContentReducer from 'modules/landingPagesContent';
import staticLandingPagesContentReducer from 'modules/staticLandingPagesContent';
import deviceReducer from 'modules/device';
import clientAdminReducer from 'modules/clientAdmin';
import shareAllocationReducer from 'modules/shareAllocation';
import inquiryFormReducer from 'modules/inquiryForm';
import { membershipReducer } from 'modules/membership/reducers';
import groupsReducer from 'modules/groups';
import firmAdminReducer from 'modules/firmAdmin';
import centerAdminReducer from 'modules/centerAdmin';
import { startup } from 'modules/startup';
import contractLineItem from 'modules/contractLineItem';
import document from 'modules/documentUpload';
import supervisor from 'modules/supervisor';
import exemptionReferenceReducer from 'modules/exemptionReference';
import exemptionProfessionalReferenceReducer from 'modules/exemptionProfessional';
import exemptionEducationalReferenceReducer from 'modules/exemptionEducational';
import exemptionWorkExperienceReferenceReducer from 'modules/exemptionWorkExperience';
import exemptionEngineReferenceReducer from 'modules/exemptionEngine';
import cimaSupervisorReducer from 'modules/cimasupervisor';
import requiredDocumentsReducer from 'modules/requiredDocuments';
import uploadedDocumentsReducer from 'modules/uploadedDocuments';
import personLevelExemptionReducer from 'modules/personLevelExemption';
import tuitionProviderReducer from 'modules/tuitionprovider/reducers';
import apprenticeRosterReducer from 'modules/apprenticeRoster';
import apprenticeReducer from 'modules/apprentice';
import amlReducer from 'modules/amlforms';
import mipReducer from 'modules/mip';
import mip from 'modules/mip/mipContact';
import attestationPracticeReqReferenceReducer from 'modules/mip/attestationIndex';
import allRequiredDocumentsReferenceReducer from 'modules/allRequiredDocuments';
import personLevelExemptionsReducer from 'modules/personLevelExemptions';
import offlinePageContentReducer from 'modules/offlinePageContent';
import pickListReducer from 'modules/pickLists/reducers'

export const createRootReducer = (history: any) => {
  return combineReducers({
    address: addressReducer,
    admin: adminReducer,
    router: connectRouter(history),
    app: appReducer,
    user: userReducer,
    topics: topicsReducer,
    industries: industriesReducer,
    trends: trendsReducer,
    skills: skillsReducer,
    locations: locationsReducer,
    jobRoles: jobRolesReducer,
    careerStages: careerStagesReducer,
    contentLanguages: contentLanguagesReducer,
    categories: categoriesReducer,
    productTypes: productTypesReducer,
    layouts: layoutsReducer,
    content: contentReducer,
    search: searchReducer,
    header: headerReducer,
    trendingAcross: trendingAcrossReducer,
    featureToggle: featureToggleReducer,
    savedItems: savedItemsReducer,
    savedBlogPosts: savedBlogPostsReducer,
    notifications: notificationsReducer,
    popularSearches: popularSearchesReducer,
    products: productsReducer,
    checkout: checkoutReducer,
    cart: cartReducer,
    receipts: receiptsReducer,
    individualConsent: individualConsentsReducer,
    landingPagesContent: landingPagesContentReducer,
    staticLandingPagesContent: staticLandingPagesContentReducer,
    device: deviceReducer,
    clientAdmin: clientAdminReducer,
    shareAllocation: shareAllocationReducer,
    inquiryForm: inquiryFormReducer,
    membership: membershipReducer,
    groups: groupsReducer,
    firmAdmin: firmAdminReducer,
    centerAdmin: centerAdminReducer,
    startup,
    contractLineItem,
    document,
    supervisor,
    exemptionReference: exemptionReferenceReducer,
    exemptionProfessional: exemptionProfessionalReferenceReducer,
    exemptionEducational: exemptionEducationalReferenceReducer,
    exemptionWorkExperience: exemptionWorkExperienceReferenceReducer,
    exemptionEngineResults: exemptionEngineReferenceReducer,
    cimaSupervisor: cimaSupervisorReducer,
    requiredDocument: requiredDocumentsReducer,
    uploadedDocument: uploadedDocumentsReducer,
    personLevelExemption: personLevelExemptionReducer,
    tuitionProvider: tuitionProviderReducer,
    apprenticeRoster: apprenticeRosterReducer,
    apprentice: apprenticeReducer,
    aml: amlReducer,
    mip: mipReducer,
    mipContactReference: mip,
    attesPracticeRequirementsReference: attestationPracticeReqReferenceReducer,
    allRequiredDocuments: allRequiredDocumentsReferenceReducer,
    personLevelExemptions: personLevelExemptionsReducer,
    offlinePageContent: offlinePageContentReducer,
    pickLists: pickListReducer,
  });
};
