import React from 'react';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import { ContentfulHelpers } from 'mxp-utils';
import { emptyObject, isServer } from 'utils';
import { OnlyDesktopCSS } from 'components/atoms';
import { LANDING_PAGES_CONSTS } from '../../../constants';
import { Contentful } from 'mxp-schemas';
import { DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { useSelector } from 'react-redux';
import { staticLandingPageContentTypeSlugSelector } from 'modules/staticLandingPagesContent/selectors';

interface Props {
  testId: string;
  style?: { height: string };
  blockContentType: string;
  bgColor: string | undefined;
  image: string | undefined;
  isFullPagePagination: boolean;
  children: React.ReactNode;
  id?: string;
  modularBlock: Contentful.ModularContent.Block;
  isToolkitPage?: boolean;
  isStaticLandingPage?: boolean;
  isInHeader?: boolean;
  isArticlePage?: boolean | undefined;
  isCimaMipJourney?: boolean;
  isHomePage?: boolean;
}

export const BlockBackgroundDecorator: React.FC<Props> = ({
  testId,
  style = emptyObject,
  blockContentType,
  bgColor = '',
  image = '',
  isFullPagePagination = false,
  children,
  id,
  modularBlock,
  isToolkitPage = false,
  isStaticLandingPage = false,
  isInHeader = false,
  isArticlePage = false,
  isCimaMipJourney = false,
  isHomePage,
}) => {
  const block = modularBlock as any;
  const isCurvedBackground: boolean = [
    ContentfulHelpers.CONTENT_TYPES.INTEGRATED_SEARCH_BLOCK,
    ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK,
  ].includes(blockContentType);
  const isIntegratedSearch: boolean = blockContentType === ContentfulHelpers.CONTENT_TYPES.INTEGRATED_SEARCH_BLOCK;
  const isInquiryFormBlock: boolean = blockContentType === ContentfulHelpers.CONTENT_TYPES.INQUIRY_FORM_BLOCK;
  const elementId: string | undefined = id?.replace('#', '');
  const products = block?.products ?? [];
  const contents = block?.contentReference ?? [];
  const pageContents = contents.concat(products).splice(0, LANDING_PAGES_CONSTS.CONTENTS_AMOUNT);
  const isProductsOrContent: boolean = Boolean(pageContents?.length);
  const isImage: boolean = Boolean(block?.image);
  const noProductOrContentWithImage: boolean = Boolean(!isProductsOrContent && isImage);
  const isInHeaderStaticLanding = isStaticLandingPage && isInHeader;

  const contentTypeSlug = useSelector(staticLandingPageContentTypeSlugSelector);
  const isCimaMipPropPage = ContentfulHelpers.isCimaMipContentType(contentTypeSlug);

  React.useEffect(() => {
    if (!isServer) {
      const { hash } = window.location;
      if (elementId && hash === id) {
        const element = document.getElementById(elementId);
        if (element) element.scrollIntoView();
      }
    }
  }, [id, elementId]);

  return isInquiryFormBlock ? (
    <>
      {block?.inquiryForm === 'Membership' ? (
        <MemberStyledBlockWrapper
          data-block-style={blockContentType}
          style={{ style }}
          id={elementId}
          bgColor={bgColor}
        >
          <MemberStyledBlockFormContainer
            data-block-style={blockContentType}
            style={style}
            id={elementId}
            bgColor={bgColor}
          >
            {children}
          </MemberStyledBlockFormContainer>
        </MemberStyledBlockWrapper>
      ) : (
        <StyledBlockWrapper data-block-style={blockContentType} style={style} id={elementId} bgColor={bgColor}>
          <StyledBlockFormContainer tabIndex={0} data-testid={testId}>
            {children}
          </StyledBlockFormContainer>

          {!isToolkitPage && (
            <OnlyDesktopCSS>
              <StyledBlockImageContainer tabIndex={0} data-image-src={image} data-testid={testId}>
                <Container />
              </StyledBlockImageContainer>
            </OnlyDesktopCSS>
          )}
        </StyledBlockWrapper>
      )}
    </>
  ) : (
    <StyledBlockContainer
      data-bg-color={bgColor}
      data-full-page-pagination={isFullPagePagination}
      data-image-src={!isCurvedBackground ? image : ''}
      data-block-style={blockContentType}
      style={style}
      data-testid={testId}
      id={elementId}
      data-is-in-header-static-landing={isInHeaderStaticLanding}
      data-is-in-landing={isHomePage}
    >
      {isCimaMipJourney && isCimaMipPropPage && <StyledGradientHeader />}
      {isCurvedBackground && (
        <StyledCurvedContainer
          data-image-src={image}
          data-is-full-page={isFullPagePagination}
          data-is-int-search={isIntegratedSearch}
          data-block-style={blockContentType}
          data-is-in-header-static-landing={isInHeaderStaticLanding}
          data-is-in-landing={isHomePage}
        >
          <div>
            <div />
          </div>
        </StyledCurvedContainer>
      )}
      <StyledContainer
        data-block-style={blockContentType}
        data-full-page-pagination={isFullPagePagination}
        no-product-or-content-with-image={noProductOrContentWithImage ? 1 : 0}
        is-image={isImage ? 1 : 0}
        data-is-in-header-static-landing={isInHeaderStaticLanding}
        is-article-page={isArticlePage}
        data-is-in-landing={isHomePage}
      >
        {children}
      </StyledContainer>
    </StyledBlockContainer>
  );
};

const StyledBlockContainer = styled.div<any>`
  height: ${props => props.qsi_sc_loading_icon && props.theme.pxToRem(96)};

  position: relative;
  background-color: ${props =>
    props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.STATS_BLOCK
      ? props.theme.colors.neutralWhite
      : props['data-bg-color'] || props.theme.colors.neutralWhite};
  background-image: ${props =>
    props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.STATS_BLOCK ||
    props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.LONG_FORM_BLOCK
      ? props.theme.colors.neutralWhite
      : props['data-bg-color'] || 'linear-gradient(76deg, #672d89, #9c2463 100%)'};
  /* Full Page Pagination Requires static height */
  ${props =>
    props['data-full-page-pagination'] &&
    `
    min-height: ${
      props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.QUICKLINKS_BLOCK
        ? props.theme.pxToRem(182)
        : props.theme.pxToRem(300)
    };
    height: 100vh;
  `}
  ${props => props.theme.mediaQueries.desktopOnly} {
    ${props =>
      props['data-full-page-pagination'] &&
      props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.STATS_BLOCK &&
      `
      width: 100%
      padding: 0;
      background-color: ${props.theme.colors.neutralWhite} !important;

    `}
    ${props =>
      props['data-full-page-pagination'] &&
      props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.LONG_FORM_BLOCK &&
      `
      max-height: ${props.theme.pxToRem(408)}
      height: 100vh;
    `}
    ${props =>
      props['data-full-page-pagination'] &&
      props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.STATS_BLOCK &&
      `
      width: 100%
      padding: 0;
      background-color: ${props['data-bg-color'] || props.theme.colors.neutralWhite};
    `}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    ${props =>
      props['data-full-page-pagination'] &&
      props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.STATS_BLOCK &&
      `
      width: auto;
      padding: auto !important;
      margin: auto !important;
      background-color: ${props.theme.colors.neutralWhite} !important;
    `}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    ${props =>
      props['data-full-page-pagination'] &&
      props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.STATS_BLOCK &&
      `
      width: auto;
      padding: auto !important;
      margin: auto !important;
      background-color: ${props['data-bg-color'] || props.theme.colors.neutralWhite};
    `}
  }

  .rich-text-internal-link {
    ${DefaultInlineLinkStyles};
  }

  ${props =>
    props['data-image-src'] &&
    props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.PRODUCT_MARKETING_PROMOTIONS_BLOCK &&
    props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.VIDEO_BLOCK &&
    props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.LONG_FORM_BLOCK &&
    props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.ACCORDION_BLOCK &&
    `
    background-image: url(${props['data-image-src']}&fit=thumb&w=1440&h=940);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    ${props =>
      props['data-image-src'] &&
      props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.PRODUCT_MARKETING_PROMOTIONS_BLOCK &&
      props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.VIDEO_BLOCK &&
      props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.LONG_FORM_BLOCK &&
      props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.ACCORDION_BLOCK &&
      `background-image: url(${props['data-image-src']}&fit=thumb&w=375&h=677);`}

    ${props =>
      props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.CEROS_ANIMATION_BLOCK &&
      `padding-top: 2.5% !important;
         background-image:none`}

      ${props =>
      (props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.TURTL_ANIMATION_BLOCK ||
        props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.BLUETOAD_PAGETURNER_BLOCK) &&
      `padding-top: 0% !important;`}
  }
  ${props => props.theme.mediaQueries.desktopOnly} {
    ${props =>
      (props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.CEROS_ANIMATION_BLOCK ||
        props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.TURTL_ANIMATION_BLOCK ||
        props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.BLUETOAD_PAGETURNER_BLOCK) &&
      `padding: 0;
       background-image:none
    `}
  }

  ${props =>
    props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK_CAROUSEL &&
    `padding-top: 0;
    `}

  ${props =>
    props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK &&
    props['data-is-in-header-static-landing'] &&
    `
      padding-bottom: ${props.theme.pxToRem(0)};
    `}
`;

const getDesktopHeight = (props: any) => {
  return props['data-is-full-page']
    ? props['data-is-int-search']
      ? '39vh'
      : '45vh'
    : props['data-is-int-search']
    ? '46vh'
    : '45vh';
};

const getWideScreenHeight = (props: any) => {
  return props['data-is-full-page']
    ? props['data-is-int-search']
      ? '39vh'
      : '48vh'
    : props['data-is-int-search']
    ? '29vh'
    : '36vh';
};

const StyledCurvedContainer = styled.div<any>`
  position: absolute;
  left: 0;
  width: 100%;
  height: ${getDesktopHeight};
  ${props =>
    props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK &&
    props['data-is-in-header-static-landing'] &&
    `
      height: ${props.theme.pxToRem(300)} !important;
      width: 100%;
    `}
  overflow: visible;

  ${props => props.theme.mediaQueries.wideScreen} {
    height: ${getWideScreenHeight};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    ${props =>
      props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK &&
      props['data-is-in-header-static-landing'] &&
      `
        height: ${props.theme.pxToRem(490)} !important;
      `}
  }

  > div {
    background-color: transparent;
    width: 200vw;
    line-height: 200vh;
    overflow: visible;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    margin: 0 50%;
    text-align: center;
    pointer-events: none;

    ${props =>
      props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK &&
      props['data-is-in-header-static-landing'] &&
      `
        height: 100%;
        width: 100%;
        left: 50%;
        border-radius: unset;
        line-height: unset !important;
        margin: unset;

        ${props.theme.mediaQueries.mobileOnly} {
        height:  ${props.theme.pxToRem(490)};
        }
      `}

    ${props => props.theme.mediaQueries.mobileOnly} {
      line-height: 100vh;
      ${props =>
        props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK &&
        props['data-is-in-header-static-landing'] &&
        `
        `}
    }

    > div {
      display: inline-block;
      width: 100vw;
      height: ${getDesktopHeight};
      background-color: transparent;
      vertical-align: bottom;
      background-size: cover;
      background-position: center top;
      background-image: ${props => props['data-image-src'] && `url(${props['data-image-src']}&fit=thumb&w=1440&h=460)`};
      ${props =>
        props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK &&
        props['data-is-in-header-static-landing'] &&
        `
          filter: brightness(80%);
          height: 100% !important;
          width: 100%;
        `}
      ${props => props.theme.mediaQueries.mobileOnly} {
        background-image: ${props => props['data-image-src'] && `url(${props['data-image-src']}&fit=thumb&w=375&h=216)`}
    }
  }
`;

const StyledContainer = styled(Container)`
  &&& {
    position: relative;
    height: ${props =>
      props['data-full-page-pagination']
        ? '100%'
        : props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK &&
          props['data-is-in-header-static-landing']
        ? `${props.theme.pxToRem(300)}`
        : 'auto'};

    ${props =>
      props['data-block-style'] !== ContentfulHelpers.CONTENT_TYPES.STATS_BLOCK &&
      `
        display: flex;
      `}
    flex-direction: column;
    width: ${props =>
      props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK_CAROUSEL ? '100% !important' : '80%'};
    justify-content: center;
    ${props => props.theme.mediaQueries.desktopOnly} {
      ${props =>
        !props['data-full-page-pagination'] && {
          padding: (() => {
            if (props['data-is-in-landing']) {
              if (
                props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.PROMOCARD_BLOCK ||
                props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.EDITORIAL_HERO_BLOCK
              ) {
                return `${props.theme.pxToRem(0)} ${props.theme.pxToRem(20)}`;
              }
              return `${props.theme.pxToRem(0)}`;
            }
            switch (props['data-block-style']) {
              case ContentfulHelpers.CONTENT_TYPES.PROMOCARD_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.EDITORIAL_HERO_BLOCK:
                return `${props.theme.pxToRem(20)}`;
              case ContentfulHelpers.CONTENT_TYPES.TOPIC_LIST_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.PRODUCT_TYPE_LIST_BLOCK:
                return `${props.theme.pxToRem(40)} ${props.theme.pxToRem(0)}`;
              case ContentfulHelpers.CONTENT_TYPES.ACCORDION_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.TABLE_BLOCK:
                return `${props.theme.pxToRem(80)} ${props.theme.pxToRem(0)}`;
              case ContentfulHelpers.CONTENT_TYPES.TRENDING_CONTENT_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.LAST_FOUR_MODIFIED_PRODUCTS_BLOCK:
                return `${props.theme.pxToRem(40)} ${props.theme.pxToRem(0)} ${props.theme.pxToRem(40)} 0`;
              case ContentfulHelpers.CONTENT_TYPES.INTEGRATED_SEARCH_BLOCK:
                return `${props.theme.pxToRem(40)} ${props.theme.pxToRem(0)} ${props.theme.pxToRem(40)} 0`;
              case ContentfulHelpers.CONTENT_TYPES.PRODUCT_MARKETING_PROMOTIONS_BLOCK:
                return `${props.theme.pxToRem(10)}`;
              case ContentfulHelpers.CONTENT_TYPES.LONG_FORM_BLOCK:
                return `${props.theme.pxToRem(40)} 0 ${props.theme.pxToRem(40)} 0`;
              case ContentfulHelpers.CONTENT_TYPES.GROUP_MEMBERS_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.VIDEO_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.CEROS_ANIMATION_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.TURTL_ANIMATION_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.BLUETOAD_PAGETURNER_BLOCK:
                return `${props.theme.pxToRem(0)}`;
              case ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK:
                return props['data-is-in-header-static-landing']
                  ? props['is-image']
                    ? `${props.theme.pxToRem(40)} 0 ${props.theme.pxToRem(50)} 0`
                    : `${props.theme.pxToRem(40)} 0 ${props.theme.pxToRem(45)} 0`
                  : `${props.theme.pxToRem(110)} ${props.theme.pxToRem(0)}`;
              case ContentfulHelpers.CONTENT_TYPES.QUICKLINKS_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK_CAROUSEL:
                return `0 0 0 0`;
              case ContentfulHelpers.CONTENT_TYPES.BENEFITS_BLOCK:
                return props['is-article-page']
                  ? `${props.theme.pxToRem(0)}`
                  : `${props.theme.pxToRem(110)} ${props.theme.pxToRem(0)}`;
              default:
                return `${props.theme.pxToRem(110)} ${props.theme.pxToRem(0)}`;
            }
          })(),
          width: (() => {
            switch (props['data-block-style']) {
              case ContentfulHelpers.CONTENT_TYPES.STATS_BLOCK:
                return '100%';
              case ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK_CAROUSEL:
                return '100%';
              default:
                return '80%';
            }
          })(),
          height: (() => {
            switch (props['data-block-style']) {
              case ContentfulHelpers.CONTENT_TYPES.INTEGRATED_SEARCH_BLOCK:
                return props.theme.pxToRem(448);
              case ContentfulHelpers.CONTENT_TYPES.MARKETING_BLOCK:
                return props.theme.pxToRem(940);
              case ContentfulHelpers.CONTENT_TYPES.LONG_FORM_BLOCK:
                return '100%';
              case ContentfulHelpers.CONTENT_TYPES.PRODUCT_TYPE_LIST_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.TOPIC_LIST_BLOCK:
                return '100%';
            }
          })(),
          'min-height': (() => {
            switch (props['data-block-style']) {
              case ContentfulHelpers.CONTENT_TYPES.LAST_FOUR_MODIFIED_PRODUCTS_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.TRENDING_CONTENT_BLOCK:
                return props.theme.pxToRem(588);
              case ContentfulHelpers.CONTENT_TYPES.PRODUCT_MARKETING_PROMOTIONS_BLOCK:
                return props.theme.pxToRem(448);
              case ContentfulHelpers.CONTENT_TYPES.PRODUCT_TYPE_LIST_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.TOPIC_LIST_BLOCK:
                return props.theme.pxToRem(604);
              case ContentfulHelpers.CONTENT_TYPES.VIDEO_BLOCK:
                return props.theme.pxToRem(448);
            }
          })(),
        }}
      ${props =>
        props['data-full-page-pagination'] && {
          padding: (() => {
            switch (props['data-block-style']) {
              case ContentfulHelpers.CONTENT_TYPES.LONG_FORM_BLOCK:
                return `${props.theme.pxToRem(73)} 0 ${props.theme.pxToRem(63)} 0`;
              case ContentfulHelpers.CONTENT_TYPES.QUICKLINKS_BLOCK:
              case ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK_CAROUSEL:
                return `${props.theme.pxToRem(0)}`;
              default:
                return `${props.theme.pxToRem(0)} ${props.theme.pxToRem(0)} ${props.theme.pxToRem(150)}`;
            }
          })(),
          width: (() => {
            switch (props['data-block-style']) {
              case ContentfulHelpers.CONTENT_TYPES.STATS_BLOCK:
                return '100%';
              default:
                return '80%';
            }
          })(),
        }}
    }
    ${props => props.theme.mediaQueries.desktopOnly} {
      ${props =>
        props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.PRODUCT_MARKETING_PROMOTIONS_BLOCK &&
        props['no-product-or-content-with-image'] &&
        `
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        width: 100% !important;
      `}
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      ${props =>
        props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK &&
        props['data-is-in-header-static-landing']
          ? `height: ${props.theme.pxToRem(490)}`
          : ''}
      padding-top: 10%;
      ${props =>
        props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.PRODUCT_MARKETING_PROMOTIONS_BLOCK &&
        `padding-bottom: ${props['is-image'] ? 0 : '10%'}`}
      ${props =>
        (props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.CEROS_ANIMATION_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.TURTL_ANIMATION_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK_CAROUSEL) &&
        `padding-top: 0!important }`}
      ${props =>
        props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.QUICKLINKS_BLOCK &&
        `padding-top: 0 !important;
         padding-bottom: 0 !important; }`}
      ${props =>
        (props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.TOPIC_LIST_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.PRODUCT_TYPE_LIST_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.EDITORIAL_HERO_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.ACCORDION_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.VIDEO_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.LAST_FOUR_MODIFIED_PRODUCTS_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.TRENDING_CONTENT_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.TABLE_BLOCK ||
          props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.BENEFITS_BLOCK) &&
        `padding-bottom: ${props.theme.pxToRem(40)}`}
        
      ${props =>
        props['data-block-style'] === ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK &&
        props['data-is-in-header-static-landing'] &&
        (props['is-image'] ? `padding: 0` : `padding: ${props.theme.pxToRem(40)} 0 ${props.theme.pxToRem(45)} 0`)}
    }
  }
`;

const MemberStyledBlockWrapper = styled.div<any>`
  display: flex;
  background-color: ${props => (props?.bgColor ? props.bgColor : props.theme.colors.aliceBlue)};
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
`;

const StyledBlockWrapper = styled.div<any>`
  display: flex;
  background-color: ${props => (props?.bgColor ? props.bgColor : props.theme.colors.aliceBlue)};
  justify-content: space-between;
`;

const MemberStyledBlockFormContainer = styled.div<any>`
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: ${props => `${props.theme.pxToRem(80)}`};
  padding-bottom: ${props => `${props.theme.pxToRem(80)}`};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    padding: 10% 5% 10% 5%;
  }
`;
const StyledBlockFormContainer = styled.div<any>`
  position: relative;
  margin: 0 10%;
  padding-top: ${props => `${props.theme.pxToRem(80)}`};
  padding-bottom: ${props => `${props.theme.pxToRem(80)}`};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    padding: 10% 5% 10% 5%;
  }
`;

const StyledBlockImageContainer = styled.div<any>`
  position: relative;

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-left: ${props => `${props.theme.pxToRem(105)}`};
  }

  ${props =>
    props['data-image-src'] &&
    `
    background-image: url(${props['data-image-src']}&fit=thumb&w=1440&h=940);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
   `}
`;

const StyledGradientHeader = styled.section`
  position: absolute;
  height: ${props => props.theme.pxToRem(449)};
  width: 150%;
  border-radius: 0 0 65% 65%/0 0 100% 100%;
  background-image: linear-gradient(73deg, #6a2d87 35%, #a93d69 101%);
  transform: translateX(-16.5%);
  ${props => props.theme.mediaQueries.mobileOnly} {
    height: ${props => props.theme.pxToRem(504.4)};
    border-radius: 0 0 200% 200%/0 0 100% 100%;
  }
`;
