import { createSelector } from 'reselect';
import { SalesforceResponses } from 'mxp-schemas';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.exemptionProfessional,
  (exemptionProfessional: State.ExemptionProfessional): State.ExemptionProfessional => exemptionProfessional
);

export const professionalQualificationFetchSelector = createSelector(
  rootSelector,
  (exemptionProfessional: State.ExemptionProfessional): boolean => exemptionProfessional.exemptReferenceFetched
);

export const professionalQualificationrontloadRequestSelector = createSelector(
  rootSelector,
  (exemptionProfessional: State.ExemptionProfessional): boolean => exemptionProfessional.exemptReferenceFrontloadRequest
);

export const selectedPassedUSCPAExamSelector = createSelector(
  rootSelector,
  (exemptionProfessional: State.ExemptionProfessional): string => exemptionProfessional?.selectedPassedUSCPAExam || ''
);

export const professionalExempReferenceSelector = createSelector(
  rootSelector,
  (exemptionProfessional: State.ExemptionProfessional): SalesforceResponses.SalesforceExemptionIdProfessional | any =>
    exemptionProfessional.exempReference
);

export const professionalQualificationSelector = createSelector(
  rootSelector,
  (exemptionProfessional: State.ExemptionProfessional): SalesforceResponses.SalesforceExemptionIdProfessional | any =>
    exemptionProfessional?.allProfessionalExperienceReference
);
