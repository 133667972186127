import React from 'react';
import styled from 'styled-components';
import { Container, ButtonEnums, Button } from 'components/atoms';
import { formatPrice, fromCentsFormat } from 'utils';
import { getZIndexFor, Z_INDEX_ORDER } from 'utils/getZIndexFor';
import { ReactComponent as KeyboardArrowRight } from 'resources/images/icon-dev-ic-keyboard-arrow-right.svg';
import { B2B } from 'mxp-schemas';
import { User as UserUtils } from 'mxp-utils';
import { useSelector } from 'react-redux';
import { selectedCurrency } from 'modules/admin/selectors';

interface Props {
  cart: B2B.CartItem[];
  salesTax: {
    isValueShown: boolean;
    value: number;
    placeholderText: string;
  };
  next: {
    text: string;
    handler: () => void;
    isDisabled: boolean;
    isLoading: boolean;
  };
}

export const CalculationStickyFooter: React.FC<Props> = ({ cart, salesTax, next }) => {
  let currency = '';
  const userSelectedCurrency = useSelector(selectedCurrency);
  // Total Discount:
  let totalListPrice = 0;
  // Note: That help us to calculate Total Discount and exclude NA list prices
  let totalDiscountPrice = 0;

  let subtotal = 0;

  cart.forEach((item: B2B.CartItem) => {
    const userInputQuantity = Number(item.userInput.quantity) || 0;
    const userInputPrice = Number(item.userInput.price);
    const { prices } = item.productDetails;

    const selectedPrice: any = prices?.find((priceItem: any) => {
      const { currencyCode } = priceItem.value;
      return (
        currencyCode === userSelectedCurrency &&
        (priceItem.channel.obj.key === UserUtils.CORRECT_NON_MEMBER_CHANNEL_KEY ||
          priceItem.channel.obj.key === UserUtils.INCORRECT_NON_MEMBER_CHANNEL_KEY)
      );
    });

    if (item.productDetails.listPrice.amount) {
      totalListPrice = selectedPrice
        ? totalListPrice + userInputQuantity * selectedPrice?.value.centAmount
        : totalListPrice + userInputQuantity * item.productDetails.listPrice.amount;
      totalDiscountPrice = totalDiscountPrice + userInputQuantity * userInputPrice;
    }

    subtotal = subtotal + userInputQuantity * userInputPrice;

    currency = userSelectedCurrency;
  });

  totalListPrice = fromCentsFormat(totalListPrice);
  const totalDiscount = totalDiscountPrice > totalListPrice ? 0 : totalDiscountPrice - totalListPrice;
  const formattedTotalDiscount = formatPrice(totalDiscount, currency);

  const formattedSubtotal = formatPrice(subtotal, currency);
  const formattedTotal = formatPrice(subtotal + salesTax.value, currency);

  return (
    <StyledCalculationFooter>
      <StyledContainer>
        <StyledPriceInfo>
          <PriceDetail title="Total Discount" text={formattedTotalDiscount} isGreen />
          <PriceDetail title="Subtotal" text={formattedSubtotal} />
          <PriceDetail
            title="Sales Tax"
            text={salesTax.isValueShown ? formatPrice(salesTax.value, currency) : salesTax.placeholderText}
          />
          <>{salesTax.isValueShown && <PriceDetail title="Total" text={formattedTotal} isBold />}</>
        </StyledPriceInfo>
        <StyledNextButton
          onClick={next.handler}
          testId="profile-products-banner-button"
          variant={ButtonEnums.variants.primary}
          size={ButtonEnums.sizes.medium}
          icon={<KeyboardArrowRight />}
          iconPosition={ButtonEnums.iconPosition.right}
          bordered
          disabled={next.isDisabled}
          loading={next.isLoading}
        >
          {next.text}
        </StyledNextButton>
      </StyledContainer>
    </StyledCalculationFooter>
  );
};

const PriceDetail: React.FC<{ title: string; text: string; isGreen?: boolean; isBold?: boolean }> = ({
  title,
  text,
  isGreen = false,
  isBold = false,
}) => (
  <StyledPriceDetail>
    <StyledHeaderSticky isBold={isBold}>{title}</StyledHeaderSticky>
    <StyledTextSticky isGreen={isGreen} isBold={isBold}>
      {text}
    </StyledTextSticky>
  </StyledPriceDetail>
);

const StyledPriceDetail = styled.div`
  margin-right: ${props => props.theme.pxToRem(90)};
`;

const StyledContainer = styled(Container)`
  &&& {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledPriceInfo = styled.div`
  display: flex;
`;

const StyledCalculationFooter = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  height: ${props => props.theme.pxToRem(110)};

  background-color: ${props => props.theme.colors.neutralGrey1};
  z-index: ${getZIndexFor(Z_INDEX_ORDER.CalculationFooter)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    position: relative;
  }
`;

const StyledHeaderSticky = styled.h4<{ isBold?: boolean }>`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => (props.isBold ? props.theme.fontWeights.bold : props.theme.fontWeights.light)};
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(8)};
`;

const StyledTextSticky = styled.p<{ isGreen?: boolean; isBold?: boolean }>`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => (props.isBold ? props.theme.fontWeights.bold : props.theme.fontWeights.medium)};
  color: ${props => (props.isGreen ? props.theme.colors.secondaryTeal : props.theme.colors.neutralGrey8)};
  line-height: 0.71;
  margin: 0;
`;

const StyledNextButton = styled(Button)`
  &&&& {
    width: ${props => props.theme.pxToRem(214)};
  }
`;
