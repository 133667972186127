import React from 'react';
import styled from 'styled-components';
import { Link, LinkEnums } from 'components/atoms';
import { ReactComponent as OpenInNew } from 'resources/images/ic-open-in-new.svg';
import { handleEvent, HEADER_EVENT, EXTERNAL_LINK_EVENT } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
interface LinkListProps {
  links: State.Level2Type[];
  testId: string;
}

export const LinkList: React.FC<LinkListProps> = ({ links, testId }) => {
  const handleExternalLink = React.useCallback(
    (name: string, title: string | undefined, target: string | undefined, isExternal: boolean = true): void => {
      if (isExternal) {
        handleEvent({ text: `mega-menu:${name || title}:${target}` }, EXTERNAL_LINK_EVENT);
      } else {
        const { pageName, siteSection } = getPageNameAndSiteSection();
        handleEvent(
          {
            clickValue: `button:link:int:header:mega-menu:${name || title}:${target}`,
            value: `mega-menu:${name || title}:${target}`,
            href: target,
            pageName,
            siteSection,
          },
          HEADER_EVENT
        );
      }
    },
    []
  );
  return (
    <LinkListWrapper data-testid={testId}>
      {links.map((link: State.Level2Type, index: number) => {
        if (!link.url) return null;
        const isExternal = link.url.startsWith('http');
        return (
          <StyledLink
            type={LinkEnums.type.standaloneLink}
            testId={`link-${testId}-${index}`}
            key={link.id}
            to={link.url}
            isExternal={isExternal}
            onClick={() => handleExternalLink(link.name, link.title, link.url, link.externalUrl ? true : false)} // tslint:disable-line jsx-no-lambda
          >
            {isExternal && <StyledIcon data-testid={`icon-${testId}-${index}`} />}
            {link.name || link.title}
          </StyledLink>
        );
      })}
    </LinkListWrapper>
  );
};

const StyledIcon = styled(OpenInNew)`
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  display: inline-block;
  margin-right: 5px;
`;

const StyledLink = styled(Link)`
  width: 33.3333%;
  font-size: ${props => props.theme.fontSizes.s};
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.neutralGrey8};
  padding: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(12)};
  border-radius: 4px;
  &:hover,
  &:focus {
    outline: none;
    color: ${props => props.theme.colors.primaryPurple};
    background: ${props => props.theme.colors.primaryFadedPurple};
    font-weight: ${props => props.theme.fontWeights.medium};
    text-decoration: none;

    .icon {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const LinkListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${props => props.theme.pxToRem(8)};
`;
