/* tslint:disable jsx-no-lambda */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { Checkbox, ButtonVariants, Button, ButtonEnums, Link } from 'components/atoms';
import styled from 'styled-components';
import { constantsSelector } from 'modules/app/selectors';
import { personAccountDataSelector } from 'modules/user/selectors';
import { usePageContext } from 'components/pages/PagePracticalExperienceRequirement/PageContext';
import { CONSTANTS } from 'modules/app/constants';
import { getDownloadDocumentsSelector } from 'modules/content/selectors';
import { ContentfulHelpers } from 'mxp-utils';
import { getDownloadContentItemBySlug } from 'modules/content/actions';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { Modal } from 'components/atoms/Modal/Modal';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_REMOVE_PER_MODULE } from 'modules/featureToggle/constants';

interface Props {
  onPreview?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | any) => void;
  onCheckConfirm?: (event: React.FormEvent<HTMLInputElement>, text: string) => void;
  setSupervisorOnEdit?: () => void;
  setSupervisorFormDataOnEdit?: () => void;
  saveDraft?: () => void;
  isSaving?: boolean;
  isChecked?: boolean;
  showPERButtons?: boolean;
  hasError?: boolean;
  hasErrorForSaveDraft?: boolean;
  isValidToSubmitPER?: boolean;
}

export const AttestationWithButtons: React.FC<Props> = ({
  onCheckConfirm = () => null,
  isSaving,
  isChecked,
  saveDraft,
  setSupervisorOnEdit,
  setSupervisorFormDataOnEdit,
  showPERButtons,
  hasError = false,
  hasErrorForSaveDraft = false,
  isValidToSubmitPER = true,
}) => {
  const [isConfirmChecked, setIsConfirmChecked] = useState(isChecked || false);
  const personAccountData = useSelector(personAccountDataSelector);
  const constants = useSelector(constantsSelector);
  const dispatch = useDispatch();
  const declarationForm = constants?.[CONSTANTS.PER_DECLARATION_FROM];
  const downloadDocumentLink = useSelector(getDownloadDocumentsSelector)?.[0]?.fileLink || '';

  useEffect(() => {
    dispatch(getDownloadContentItemBySlug(declarationForm, ContentfulHelpers.CONTENT_TYPES.DOWNLOAD));
  }, [dispatch, declarationForm]);

  const {
    isPageReadOnly,
    showModal,
    setShowModal,
    isSubmitted,
    isSupervisorReadOnly,
    setIsSupervisorReadOnly,
    isFormValid,
    setIsChangingSupervisor,
    setIsFromDashboard,
    setIsPageReadOnly,
    setIsEmployerEdit,
    setIsRoleEdit,
    setIsSupervisorEdit,
    isEmployerEdit,
    isFromDashboard,
    isChangingSupervisor,
  } = usePageContext();

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const attestationId = 'attestation';
  const useRemovePERModuleToggle: boolean = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_REMOVE_PER_MODULE)
  );
  const [isShowRestrictSubmitPERModal, setIsShowRestrictSubmitPERModal] = useState(false);

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (useRemovePERModuleToggle && !isValidToSubmitPER) {
      setIsShowRestrictSubmitPERModal(true);
      return;
    }

    if (!isSaving) {
      const { windowPathname } = getPageNameAndSiteSection();
      const buttonText = (e?.target as HTMLAnchorElement)?.textContent;
      if ((isPageReadOnly || isSupervisorReadOnly) && !isChangingSupervisor) {
        setShowModal(!showModal);
        handleEvent({ clickValue: `button:link:int::${buttonText}:${windowPathname}` }, NAV_CLICK);
      } else if (saveDraft) {
        handleEvent(
          {
            clickValue: `button:link:int::${buttonText}:${
              windowPathname.includes(getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT))
                ? getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT)
                : windowPathname
            }`,
          },
          NAV_CLICK
        );
        saveDraft();
      }
    }
  };

  const handlePreview = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isSaving) {
      if (isSubmitted) setShowModal(true);
      else {
        setIsFromDashboard(false);
        setIsPageReadOnly(!isPageReadOnly);
        setIsSupervisorReadOnly(!isSupervisorReadOnly);
        setIsChangingSupervisor(false);
        setShowModal(false);
        if (isFromDashboard || isEmployerEdit) {
          setIsEmployerEdit(true);
          setIsRoleEdit(true);
          setIsSupervisorEdit(true);
        }
      }
    }
  };

  const handleCheckConfirm = (e: React.FormEvent<HTMLInputElement>) => {
    setIsConfirmChecked(!isConfirmChecked);
    const attestationElement = document.getElementById(attestationId)!;
    onCheckConfirm(e, attestationElement.innerText);
  };

  const handleToggleSupervisor = (e: any) => {
    if (useRemovePERModuleToggle && !isValidToSubmitPER) {
      setIsShowRestrictSubmitPERModal(true);
      return;
    }
    const { windowPathname } = getPageNameAndSiteSection();
    const buttonText = (e?.target as HTMLAnchorElement)?.textContent;
    handleEvent({ clickValue: `button:link:int::${buttonText}:${windowPathname}` }, NAV_CLICK);
    if (isChangingSupervisor) {
      setIsInitialLoad(true);
      setIsSupervisorReadOnly(true);
      setIsChangingSupervisor(false);
      setIsFromDashboard(true);
    } else {
      setIsInitialLoad(false);
      setIsSupervisorReadOnly(!isSupervisorReadOnly);
      setIsChangingSupervisor(true);
      setIsFromDashboard(false);
      setSupervisorOnEdit?.();
      setSupervisorFormDataOnEdit?.();
    }
  };

  const handleOnClick = isChangingSupervisor ? handleToggleSupervisor : handleSave;

  const buttonSaveText = () => {
    if ((!isSubmitted && isPageReadOnly) || (isSubmitted && isSupervisorReadOnly)) {
      return 'Confirm';
    }
    if (isChangingSupervisor) {
      return 'Return';
    }
    return 'Save draft';
  };

  const handleRestrictSubmitPERModalClose = () => {
    setIsShowRestrictSubmitPERModal(false);
  };

  const renderRestrictSubmitPERModal = (): React.ReactNode => {
    return (
      <Modal
        showCloseCross
        open
        onClose={handleRestrictSubmitPERModalClose}
        heading="Error"
        testId="cannot-submit-per-modal"
        size="tiny"
      >
        <StyledParagraph>You are not allowed to change your PER records.</StyledParagraph>
      </Modal>
    );
  };

  return (
    <>
      <StyledAttestation>
        <Checkbox
          id="checkbox"
          testId="test-id"
          checked={isConfirmChecked}
          onChange={handleCheckConfirm}
          disabled={isSubmitted ? isInitialLoad : isPageReadOnly}
        />
        <StyledConfirmation id={attestationId}>
          I, <StyledName>{`${personAccountData.firstName} ${personAccountData.lastName}`}</StyledName> hereby declare
          that all information provided is true and I attest to the{' '}
          <StyledLink testId="declaration-link" to={downloadDocumentLink} isExternal={true}>
            declaration form.
          </StyledLink>
        </StyledConfirmation>
      </StyledAttestation>

      <StyledButtonGrid>
        <Grid.Row>
          {isSubmitted && isInitialLoad ? (
            <>
              <Grid.Column computer={3} mobile={16} tablet={5}>
                <Button
                  fluid={true}
                  size={ButtonEnums.sizes.medium}
                  variant={ButtonVariants.secondary}
                  testId="save-personal-details"
                  onClick={handleToggleSupervisor}
                  overrideAnalytics={true}
                >
                  Edit supervisor details
                </Button>
              </Grid.Column>
            </>
          ) : (
            !showPERButtons && (
              <>
                <Grid.Column computer={3} mobile={16} tablet={5}>
                  <Button
                    disabled={isPageReadOnly ? !(isConfirmChecked && isFormValid) : hasErrorForSaveDraft}
                    fluid={true}
                    size={ButtonEnums.sizes.medium}
                    variant={ButtonVariants.primary}
                    testId="save-personal-details"
                    overrideAnalytics={true}
                    onClick={handleOnClick}
                    loading={isSaving}
                  >
                    {buttonSaveText()}
                  </Button>
                </Grid.Column>
                <Grid.Column computer={3} mobile={16} tablet={5}>
                  <Button
                    fluid={true}
                    size={ButtonEnums.sizes.medium}
                    variant={ButtonVariants.secondary}
                    testId="quit-personal-details"
                    onClick={handlePreview}
                    disabled={
                      isPageReadOnly
                        ? !(isConfirmChecked && isFormValid)
                        : hasError || !isFormValid || !isConfirmChecked
                    }
                  >
                    {(!isSubmitted && isPageReadOnly) || (isSubmitted && isSupervisorReadOnly)
                      ? 'Return to edit'
                      : isChangingSupervisor
                      ? 'Change supervisor'
                      : 'Preview'}
                  </Button>
                </Grid.Column>
              </>
            )
          )}
        </Grid.Row>
        {isShowRestrictSubmitPERModal && renderRestrictSubmitPERModal()}
      </StyledButtonGrid>
    </>
  );
};

const StyledAttestation = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
`;

const StyledName = styled.span`
  font-weight: 500;
`;

const StyledConfirmation = styled.div`
  width: ${props => props.theme.pxToRem(609)};
  height: ${props => props.theme.pxToRem(48)};
  margin: 0 0 0 ${props => props.theme.pxToRem(18)};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledButtonGrid = styled(Grid)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(50)};
    margin-bottom: ${props => props.theme.pxToRem(200)};
  }
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primaryPurple};
  text-decoration: underline;
`;

const StyledParagraph = styled.p`
  line-height: 1.57;
  text-align: center;
`;
