import React from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { Divider } from 'components/atoms';
import { HomeFeedSectionTitle } from 'components/atoms/HomeFeedSectionTitle/HomeFeedSectionTitle';

interface Props {
  title: string;
  items: any[];
  renderItem: (item: any, index?: number) => JSX.Element;
  firstItemsCount?: number;
  expandedText?: string;
  collapsedText?: string;
  isToolkit?: boolean;
}

export const CollapsableLinkList: React.FC<Props> = React.memo(
  ({
    title,
    items,
    renderItem,
    firstItemsCount = 3,
    expandedText = 'Expand',
    collapsedText = 'Collapse',
    isToolkit = false,
  }) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleButtonClick = React.useCallback(() => {
      setExpanded(!expanded);
    }, [expanded]);

    if (!items || !items.length) return null;
    return (
      <>
        <StyledWrapper isToolkit={isToolkit}>
          <HomeFeedSectionTitle title={title} testId={`${title.replace(' ', '-')}-Title`} isToolkit={isToolkit} />
          <StyledList isToolkit={isToolkit}>
            {items.slice(0, expanded ? Infinity : firstItemsCount).map(renderItem)}
            {items.length > firstItemsCount && (
              <StyledMoreListItem>
                <Button
                  testId="view-all-shortcuts"
                  variant={ButtonEnums.variants.link}
                  size={ButtonEnums.sizes.small}
                  iconName={expanded ? 'minus' : 'plus'}
                  iconPosition={ButtonEnums.iconPosition.left}
                  onClick={handleButtonClick}
                >
                  {expanded ? expandedText : collapsedText}
                </Button>
              </StyledMoreListItem>
            )}
          </StyledList>
        </StyledWrapper>
        <StyledDivider />
      </>
    );
  }
);

const StyledWrapper = styled.div<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  padding: ${props => (props.isToolkit ? '0' : `${props.theme.pxToRem(24)} 0 ${props.theme.pxToRem(30)}`)};
`;

const StyledList = styled.ul<{ isToolkit: boolean }>`
  padding-left: 0;
  margin: 0 auto ${props => props.theme.pxToRem(12)};
  list-style: none;

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: ${props => (!props.isToolkit ? props.theme.pxToRem(20) : '0')};
    margin-bottom: ${props => props.theme.pxToRem(24)};
  }
`;

const StyledMoreListItem = styled.li`
  position: relative;
  height: auto;
  margin-top: ${props => props.theme.pxToRem(6)};
`;

const StyledDivider = styled(Divider)`
  &:last-child {
    display: none;
  }
`;
