/* eslint-disable complexity */
import React, { useState } from 'react';
import { Dropdown, Form, InputProps as SemanticUIInputProps } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  Input,
  Checkbox,
  OnlyMobile,
  OnlyDesktop,
  NotificationBanner,
  NotificationBannerEnums,
  Link,
  Grid,
  Divider,
} from 'components/atoms';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { FilterRadioLabel } from 'components/molecules/FilterRadioLabel/FilterRadioLabel';
import { SavedAddressCard } from './SavedAddressCard';
import { AdditionalAddresses } from './AdditionalAddresses';
import { CheckoutCountriesListHash, CheckoutCountriesFilteredListHash, User as UserUtils } from 'mxp-utils';
import { Checkout, SmartyStreets, Salesforce } from 'mxp-schemas';
import { useSelector } from 'react-redux';
import { newAddressKey } from 'modules/checkout/constants';
import {
  addressLine2Selector,
  billingAddressSelector,
  savedShippingAddressEditedSelector,
  shippingAddressSelector,
} from 'modules/checkout/selectors';
import { isCartLoadingSelector, membershipLineItemSelector } from 'modules/cart/selectors';
import { checkoutCountriesListTogglesSelector, featureTogglesSelector } from 'modules/featureToggle/selectors';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import {
  isAuthSelector,
  centerMembershipApplicationAdminDetailSelector,
  isAicpaMemberSelector,
} from 'modules/user/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { isCenterMembershipJourneySelector } from 'modules/membership/selectors';
import { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import { ReactComponent as Wrong } from 'resources/images/ic-cancel.svg';
import { InvalidAddressBanner } from 'components/atoms/InvalidAddressBanner/InvalidAddressBanner';
import { getAddressValidationBypassFlags } from 'components/organisms/ModalAddressValidation/ModalAddressValidationHelpers';
import { formatValidAddress, manageValidationBypassState } from './helpers';

interface Props {
  title: string;
  description: string;
  address: Salesforce.ContactPointAddress | State.Address;
  addressAutocompleteItems: State.AddressAutocompleteItem[];
  sameShippingAddressForBilling?: boolean;
  savedAddress: Array<State.Address | Salesforce.ContactPointAddress>;
  savedAddressChecked: boolean;
  isLoadingAutocompleteItems: boolean;
  shippingAddressValidationReason?: string | null;
  isSecondaryAddressNeeded?: boolean;
  isB2B?: boolean;
  billingAddressValidationReason?: string | null;
  isShippingForm?: boolean;
  isSecondaryBillingAddressNeeded?: boolean;
  isEmbargoed: boolean | null;
  useBypassB2bAddressValidation?: boolean;
  removeValidationBypass?: boolean;
  btnIsDisabled?: boolean;
  hideToggle?: boolean;
  isLoading?: boolean;
  fromProfile?: boolean;
  useBypassB2CAddressValidation?: boolean;
  isNotDisplayingOtherFields?: boolean;
  isPersonalForm?: boolean;
  useAvsDesigns?: boolean;
  isAicpaMembershipAVS?: boolean;

  setAddress: (modifier: Partial<State.Address>) => void;
  setRemoveValidationBypass?: (modifier: boolean) => void;
  getAddressAutocompleteItems?: (value: string) => void;
  clearAddressAutocompleteItems?: () => void;
  toggleSameShippingAddressForBilling?: () => void;
  toggleSavedAddressChecked: (id: string | undefined) => void;
  setBypassValidationOnChange?: (modifier: boolean) => void;
  handleDeleteAddress?: (id: string | undefined) => Promise<void> | undefined;
}

export const AddressForm: React.FC<Props> = React.memo(
  ({
    title,
    description,
    address,
    sameShippingAddressForBilling,
    setAddress,
    toggleSameShippingAddressForBilling,
    savedAddress,
    savedAddressChecked,
    toggleSavedAddressChecked,
    shippingAddressValidationReason,
    isSecondaryAddressNeeded,
    isB2B,
    billingAddressValidationReason,
    isShippingForm,
    isSecondaryBillingAddressNeeded,
    isEmbargoed,
    setBypassValidationOnChange,
    useBypassB2bAddressValidation,
    removeValidationBypass,
    setRemoveValidationBypass,
    btnIsDisabled,
    hideToggle,
    handleDeleteAddress,
    isLoading,
    fromProfile = false,
    useBypassB2CAddressValidation,
    isNotDisplayingOtherFields,
    isPersonalForm,
    useAvsDesigns = false,
    isAicpaMembershipAVS = false,
  }) => {
    const addressLine2 = useSelector(addressLine2Selector);

    const savedShippingAddressEdited = useSelector(savedShippingAddressEditedSelector);
    const isCartLoading: boolean = useSelector(isCartLoadingSelector);
    const isImpersonation = useSelector(isAuthSelector);
    const isAdminPortal = useSelector(isAdminPortalSelector);
    const isB2BC = isAdminPortal && isImpersonation;
    const isCenterMembershipJourney = useSelector(isCenterMembershipJourneySelector);
    const { useNewMembershipAICPA } = useSelector(featureTogglesSelector);

    const isBillingForm: boolean = Boolean(toggleSameShippingAddressForBilling);
    const addressType = isShippingForm ? 'shipping' : 'billing';
    const alternateAddressMessage: string = `Use a different ${addressType} address`;
    const hasSavedAddress: boolean = Boolean(savedAddress.length);

    const isExistingAicpaMember = useSelector(isAicpaMemberSelector);
    const cartSelectedMembership = useSelector(membershipLineItemSelector);
    const shippingAddress: State.Address = useSelector(shippingAddressSelector);
    const billingAddress: State.Address = useSelector(billingAddressSelector);
    const { allowShippingAddressValidationBypass, allowBillingAddressValidationBypass } =
      getAddressValidationBypassFlags(isExistingAicpaMember, cartSelectedMembership, shippingAddress, billingAddress);

    const isShippingAddressInvalid: boolean = Boolean(
      shippingAddressValidationReason === SmartyStreets.ValidationReason.INVALID
    );
    const isBillingAddressInvalid: boolean = Boolean(
      billingAddressValidationReason === SmartyStreets.ValidationReason.INVALID
    );
    const admin = useSelector(centerMembershipApplicationAdminDetailSelector);
    const isSmartyInvalid: boolean = Boolean(
      isShippingForm || isPersonalForm ? isShippingAddressInvalid : isBillingAddressInvalid
    );
    const isSmartySecondaryNeeded: boolean = isShippingForm
      ? Boolean(isShippingAddressInvalid || isSecondaryAddressNeeded)
      : Boolean(isBillingAddressInvalid || isSecondaryBillingAddressNeeded);

    const isUsTerritory = CheckoutCountriesListHash[address.country]?.isUsTerritory;

    const isUSCountry: boolean = Boolean(
      address.country && (address.country === CheckoutCountriesListHash.USA.ISOAlpha3Code || isUsTerritory)
    );

    const isCANCountry: boolean = Boolean(
      address.country && address.country === CheckoutCountriesListHash.CAN.ISOAlpha3Code
    );

    const isUKCountry: boolean = Boolean(
      address.country && address.country === CheckoutCountriesListHash.GBR.ISOAlpha3Code
    );

    const icon = isEmbargoed && <StyledWrongIcon />;

    const [selectedAddress, setSelectedAddress] = useState('');
    const [newAddressChecked, setNewAddressChecked] = useState(false);
    const [isBypassValidationChecked, setIsBypassValidationChecked] = useState(false);

    // Identifier for input validation
    const [inputInvalidIdentifier, setInputInvalidIdentifier]: any = useState(null);

    // If SmartyStreets returns invalid, set all the input validation to true
    React.useEffect(() => {
      if (isSmartyInvalid) {
        setInputInvalidIdentifier({
          ...inputInvalidIdentifier,
          addressLine1: true,
          city: true,
          state: true,
          zipCode: true,
        });
      }
    }, [isSmartyInvalid, setInputInvalidIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (!savedAddressChecked) {
        if (typeof window === undefined) return;
        const input: HTMLInputElement | null = window.document.querySelector(
          `#${addressType}-form--address-line-1 input`
        );
        if (input) input.setAttribute('autocomplete', 'none'); // Disable browser: Save and fill addresses
      }
    }, [addressType, savedAddressChecked]);

    const handleRadioButtonClick = React.useCallback(
      (id?: string) => {
        if (isCartLoading) return;

        const resetAddress = () => {
          setAddress({
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
          });
        };

        if (setRemoveValidationBypass) {
          manageValidationBypassState(
            isBypassValidationChecked,
            removeValidationBypass || false,
            setRemoveValidationBypass
          );
        }

        const setCheckedAddress = (addressData: string) => {
          toggleSavedAddressChecked(id);
          setSelectedAddress(addressData);
        };

        const handleNewAddress = () => {
          setCheckedAddress('');
          setNewAddressChecked(true);

          if (useNewMembershipAICPA) {
            resetAddress();
          }
        };

        const handleExistingAddress = () => {
          setCheckedAddress(id || '');
          setNewAddressChecked(false);
        };

        if (id === newAddressKey) {
          handleNewAddress();
        } else {
          handleExistingAddress();
        }
      },
      [
        toggleSavedAddressChecked,
        useNewMembershipAICPA,
        setAddress,
        isCartLoading,
        isBypassValidationChecked,
        removeValidationBypass,
        setRemoveValidationBypass,
      ]
    );

    const handleBypassValidationOnclick = React.useCallback(
      (e: any, data: SemanticUIInputProps): void => {
        if (setBypassValidationOnChange) setBypassValidationOnChange(Boolean(data?.checked));
        setIsBypassValidationChecked(prevChecked => {
          const newChecked = !prevChecked;
          if (setRemoveValidationBypass) {
            manageValidationBypassState(newChecked, removeValidationBypass || false, setRemoveValidationBypass);
          }
          return newChecked;
        });
      },
      [setBypassValidationOnChange, setIsBypassValidationChecked, removeValidationBypass, setRemoveValidationBypass]
    );

    const handleInputChange = React.useCallback(
      (e: any, data: SemanticUIInputProps): void => {
        // If there's an input change set the specific input field into false to remove the error highlight
        setInputInvalidIdentifier({ ...inputInvalidIdentifier, [data.name]: false });
        const dataValue = data.name === 'addressLine1' ? formatValidAddress(data.value) : data.value || data.checked;
        setAddress({
          [data.name]: dataValue,
        });
      },
      [setAddress, inputInvalidIdentifier]
    );

    const handleAddressDelete = React.useCallback(
      (id?: string): void => {
        if (handleDeleteAddress !== undefined) {
          handleDeleteAddress(id);
        }
      },
      [handleDeleteAddress]
    );

    // IF THERE IS ONLY ONE SAVED ADDRESS AND IT IS NOT VALID
    const isInvalidSingleSavedAddress: boolean =
      hasSavedAddress &&
      savedAddress.length === 1 &&
      savedAddress.some(
        (data: State.Address | Salesforce.ContactPointAddress) =>
          !data?.addressLine1 ||
          !data?.city ||
          !data?.state ||
          ((isUSCountry || isCANCountry || isUKCountry) && !data?.zipCode) ||
          !data?.country
      );

    const savedShippingAddressIsSelectedAndEdited = !isBillingForm && savedAddressChecked && savedShippingAddressEdited;
    const showAddressForm =
      !hasSavedAddress || savedShippingAddressIsSelectedAndEdited || newAddressChecked || isInvalidSingleSavedAddress;
    const showRadioButtons =
      hasSavedAddress && !savedShippingAddressIsSelectedAndEdited && !isInvalidSingleSavedAddress;
    const showBypassAddressValidationCheckbox =
      (isB2B && useBypassB2bAddressValidation) || (isB2BC && useBypassB2CAddressValidation);

    const labelSameShippingAddressForCheckbox: string = 'Use same as shipping address';
    const testIdSameShippingAddressForCheckbox: string = `${addressType}-form--checkbox`;

    const bypassAddressValidationForCheckbox: string =
      'My customer confirms this is a valid address and I want to bypass address validation ';

    const toggles = useSelector(checkoutCountriesListTogglesSelector);

    const countryList: Checkout.CountriesListHash = toggles.length
      ? CheckoutCountriesFilteredListHash(toggles, isB2B)
      : CheckoutCountriesListHash;

    const addressTypeOptions = React.useMemo(
      () =>
        Object.values(Salesforce.HomeAddressType).map((type: any) => ({
          key: type,
          text: type,
          value: type,
        })),
      []
    );

    const countriesOptions = React.useMemo(
      () =>
        Object.values(countryList).map((country: any) => ({
          key: country.key,
          text: country.textUI ? country.textUI : country.text,
          value: country.ISOAlpha3Code,
        })),
      [countryList]
    );

    const primaryAddress =
      savedAddress?.find(add => (add as Salesforce.ContactPointAddress)?.isPrimary) || savedAddress?.[0];
    const otherAddresses = savedAddress?.filter(add => !(add as Salesforce.ContactPointAddress)?.isPrimary) || [];

    React.useEffect(() => {
      if (primaryAddress) {
        const primaryAddressKey = primaryAddress.id === newAddressKey ? '' : primaryAddress.id || '';
        toggleSavedAddressChecked(primaryAddress.id);
        setSelectedAddress(primaryAddressKey);
        setNewAddressChecked(primaryAddress.id === newAddressKey);
      }
    }, [primaryAddress]); // eslint-disable-line react-hooks/exhaustive-deps

    const optionalData = UserUtils.conditionalFunction(isB2B, '(optional)', '');
    const companyField = (optional: boolean) => (
      <Input
        fluid
        labelName={`Company ${optionalData}`}
        testId="billing-address-form--company"
        labelId="billing-address-form--company"
        name="company"
        value={address.company || ''}
        onChange={handleInputChange}
      />
    );

    const addressLabel = 'Use an address from your address book';

    return (
      <StyledAddressWrapper>
        <StyledTitle>{title}</StyledTitle>
        <StyledAdditionalText useAvsDesigns={useAvsDesigns}>{description}</StyledAdditionalText>
        {isCenterMembershipJourney && (
          <StyledAdminLabel>{`Admin: ${admin?.firstName} ${admin?.lastName}`}</StyledAdminLabel>
        )}

        {!isPersonalForm && !allowShippingAddressValidationBypass && isShippingAddressInvalid && (
          <InvalidAddressBanner addressInvalid={isShippingAddressInvalid} b2b={isB2B} b2bc={isB2BC} />
        )}
        {!isPersonalForm && !allowBillingAddressValidationBypass && isBillingAddressInvalid && (
          <InvalidAddressBanner addressInvalid={isShippingAddressInvalid} b2b={isB2B} b2bc={isB2BC} />
        )}
        {isEmbargoed && !savedAddressChecked && (
          <StyledNotificationBanner
            variant={NotificationBannerEnums.variant.red}
            testId="checkout--warning"
            childrenTestId="checkout-warning--text"
            icon={<StyledIconError />}
          >
            {isB2B ? (
              <>
                Sorry, we're unable to process orders to this address due to sanctions related to this location. Please
                contact the finance team with additional questions.
              </>
            ) : (
              <>
                Sorry, we’re unable to process orders to your address. Try a different address, or{' '}
                <Link testId="error-embargoed" to={getPath(Routes.HOME, { withoutParams: true })} isExternal>
                  return to your homepage
                </Link>{' '}
                for accounting news and resources.
              </>
            )}
          </StyledNotificationBanner>
        )}
        {showRadioButtons && (
          <>
            <SavedAddressCard
              testId={`${addressType}--radio-use-saved-address`}
              address={primaryAddress}
              checked={
                (savedAddressChecked && !selectedAddress && !newAddressChecked) ||
                ((!selectedAddress || selectedAddress === primaryAddress?.id) && !newAddressChecked)
              }
              onChange={handleRadioButtonClick.bind(null, primaryAddress?.id)}
              useAvsDesigns={useAvsDesigns}
            />
            {savedAddress.length > 1 && (
              <>
                {hideToggle ? (
                  <AdditionalAddresses
                    testId="addressForm"
                    additionalAddresses={otherAddresses}
                    onChange={handleRadioButtonClick}
                    defaultText={addressLabel}
                    collapsedText={addressLabel}
                    checked={selectedAddress}
                    hideToggleButton={true}
                    onDeleteAddress={handleAddressDelete}
                    isLoading={isLoading}
                    fromProfile={fromProfile}
                  />
                ) : (
                  <AdditionalAddresses
                    testId="addressForm"
                    additionalAddresses={otherAddresses}
                    onChange={handleRadioButtonClick}
                    defaultText={addressLabel}
                    collapsedText={addressLabel}
                    checked={selectedAddress}
                    onDeleteAddress={handleAddressDelete}
                    isLoading={isLoading}
                    fromProfile={fromProfile}
                  />
                )}
              </>
            )}
            <FilterRadioLabelWrapper>
              <FilterRadioLabel
                className="radio"
                reverseLabelAndRadio
                testId={`${addressType}--radio-add-different-address`}
                useAvsDesigns={useAvsDesigns}
                text={
                  useAvsDesigns
                    ? title.toLowerCase() === 'shipping address'
                      ? 'Add a new shipping address'
                      : title.toLowerCase() === 'billing address'
                      ? 'Add a new billing address'
                      : 'Add a new address'
                    : isCenterMembershipJourney
                    ? alternateAddressMessage
                    : 'Add a new address'
                }
                checked={newAddressChecked}
                onChange={handleRadioButtonClick.bind(null, newAddressKey)}
              />
            </FilterRadioLabelWrapper>
          </>
        )}
        {showAddressForm && (
          <StyledForm>
            {isBillingForm && (
              <>
                <OnlyMobile>
                  <CheckboxStyled
                    width="24"
                    height="24"
                    type="checkbox"
                    testId={testIdSameShippingAddressForCheckbox}
                    label={labelSameShippingAddressForCheckbox}
                    checked={sameShippingAddressForBilling}
                    onChange={toggleSameShippingAddressForBilling}
                  />
                </OnlyMobile>
                <OnlyDesktop>
                  <CheckboxStyled
                    width="18"
                    height="18"
                    type="checkbox"
                    testId={testIdSameShippingAddressForCheckbox}
                    label={labelSameShippingAddressForCheckbox}
                    checked={sameShippingAddressForBilling}
                    onChange={toggleSameShippingAddressForBilling}
                  />
                </OnlyDesktop>
              </>
            )}
            {(!sameShippingAddressForBilling || !isBillingForm || hasSavedAddress) &&
              (useAvsDesigns ? (
                <>
                  <StyledDividerM24 />
                  {isAicpaMembershipAVS && <SectionSubHeader>Contact Details</SectionSubHeader>}
                  <InputsWrapper100 isNotDisplayingOtherFields={isNotDisplayingOtherFields}>
                    <Grid columns={2}>
                      <Grid.Row>
                        <Grid.Column>
                          <AddressInputSignUp
                            fluid
                            labelName={
                              isAicpaMembershipAVS ? (
                                <>
                                  Address Line 1&nbsp;<RequiredLabel>*</RequiredLabel>
                                </>
                              ) : (
                                `Address Line 1`
                              )
                            }
                            testId={`${addressType}-form--address-line-1`}
                            labelId={`${addressType}-form--address-line-1`}
                            type="text"
                            name="addressLine1"
                            value={address.addressLine1 || ''}
                            onChange={handleInputChange}
                            autoComplete="none"
                            placeholder={'Street address or P.O. Box'}
                            data-needs-validation={Boolean(inputInvalidIdentifier?.addressLine1)}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <AddressInputSignUp
                            fluid
                            labelName="Address Line 2 (Optional)"
                            testId={`${addressType}-form--address-line-2`}
                            labelId={`${addressType}-form--address-line-2`}
                            type="text"
                            name="addressLine2"
                            value={address.addressLine2 || ''}
                            onChange={handleInputChange}
                            autoComplete="none"
                            placeholder={'Apartment, suite, unit, building, floor, etc.'}
                            data-needs-validation={isSmartySecondaryNeeded}
                          />
                          {(isSecondaryAddressNeeded || isSecondaryBillingAddressNeeded) && (
                            <MissingInput>
                              <span>Please enter a suite or apartment number</span>
                            </MissingInput>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <AddressInputSignUp
                            fluid
                            labelName={
                              isAicpaMembershipAVS ? (
                                <>
                                  State/Province/County&nbsp;<RequiredLabel>*</RequiredLabel>
                                </>
                              ) : (
                                `State/Province/County`
                              )
                            }
                            testId={`${addressType}-form--state`}
                            labelId={`${addressType}-form--state`}
                            type="text"
                            name="state"
                            value={address.state || ''}
                            onChange={handleInputChange}
                            autoComplete="none"
                            placeholder={'NY'}
                            data-needs-validation={Boolean(inputInvalidIdentifier?.state)}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <AddressInputSignUp
                            fluid
                            labelName={`ZIP/Postal Code${
                              isUSCountry || isCANCountry || isUKCountry ? '' : ' (Optional)'
                            }`}
                            testId={`${addressType}-form--zip-postal-code`}
                            labelId={`${addressType}-form--zip-postal-code`}
                            type="tel"
                            name="zipCode"
                            value={address.zipCode || ''}
                            onChange={handleInputChange}
                            autoComplete="none"
                            placeholder={'10105-0015'}
                            data-needs-validation={Boolean(inputInvalidIdentifier?.zipCode)}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <AddressInputSignUp
                            fluid
                            labelName={
                              isAicpaMembershipAVS ? (
                                <>
                                  City&nbsp;<RequiredLabel>*</RequiredLabel>
                                </>
                              ) : (
                                `City`
                              )
                            }
                            testId={`${addressType}-form--city`}
                            labelId={`${addressType}-form--city`}
                            type="text"
                            name="city"
                            value={address.city || ''}
                            onChange={handleInputChange}
                            autoComplete="none"
                            placeholder={'New York'}
                            data-needs-validation={isEmbargoed || Boolean(inputInvalidIdentifier?.city)}
                            icon={icon}
                          />
                          {isEmbargoed && !savedAddressChecked && (
                            <ValidationMessage hasError={isEmbargoed} absoluteErrorMessage={true}>
                              Restricted city
                            </ValidationMessage>
                          )}
                        </Grid.Column>
                        <Grid.Column>
                          <>
                            <StyledLabel htmlFor={`${addressType}-form--country`}>
                              {isNotDisplayingOtherFields ? 'Country' : 'Country/Region'}
                            </StyledLabel>
                            <StyledDropdown
                              name="country"
                              fluid
                              search
                              clearable
                              selection
                              minCharacters={1}
                              placeholder="Select country/region"
                              value={address.country || ''}
                              options={countriesOptions}
                              id={`${addressType}-country`}
                              data-testid={`${addressType}-country`}
                              onChange={handleInputChange}
                            />
                          </>
                        </Grid.Column>
                      </Grid.Row>
                      {UserUtils.conditionalFunction(
                        useAvsDesigns,
                        <Grid.Row>
                          <Grid.Column>
                            <>
                              <StyledLabel htmlFor={`${addressType}-form--type`}>
                                {UserUtils.conditionalFunction(
                                  isNotDisplayingOtherFields,
                                  'Type of Address',
                                  'Address Type'
                                )}
                              </StyledLabel>
                              <StyledDropdown
                                name="addressType"
                                fluid
                                selection
                                placeholder="Select Address Type"
                                value={(address as Salesforce.ContactPointAddress).addressType}
                                options={addressTypeOptions}
                                id={`${addressType}-type`}
                                data-testid={`${addressType}-type`}
                                onChange={handleInputChange}
                              />
                            </>
                          </Grid.Column>
                          <Grid.Column>
                            <CheckboxStyled
                              name="isPrimary"
                              width="24"
                              height="24"
                              type="checkbox"
                              testId={`${addressType}--is-primary-address`}
                              label="Set as primary address"
                              checked={(address as Salesforce.ContactPointAddress).isPrimary || false}
                              onChange={handleInputChange}
                              disabled={!savedAddress?.length || savedAddress?.[0] === null}
                            />
                          </Grid.Column>
                        </Grid.Row>,
                        <Grid.Row>
                          <Grid.Column>
                            <CheckboxStyled
                              name="isPrimary"
                              width="24"
                              height="24"
                              type="checkbox"
                              testId={`${addressType}--is-primary-address`}
                              label="Set as primary address"
                              checked={(address as Salesforce.ContactPointAddress).isPrimary || false}
                              onChange={handleInputChange}
                              disabled={!savedAddress?.length || savedAddress?.[0] === null}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <>
                              <StyledLabel htmlFor={`${addressType}-form--type`}>
                                {UserUtils.conditionalFunction(
                                  isNotDisplayingOtherFields,
                                  'Type of Address',
                                  'Address Type'
                                )}
                              </StyledLabel>
                              <StyledDropdown
                                name="addressType"
                                fluid
                                selection
                                placeholder="Select Address Type"
                                value={(address as Salesforce.ContactPointAddress).addressType}
                                options={addressTypeOptions}
                                id={`${addressType}-type`}
                                data-testid={`${addressType}-type`}
                                onChange={handleInputChange}
                              />
                            </>
                          </Grid.Column>
                        </Grid.Row>
                      )}
                    </Grid>
                  </InputsWrapper100>
                </>
              ) : (
                <InputsWrapper isNotDisplayingOtherFields={isNotDisplayingOtherFields}>
                  {(isBillingForm || isB2B) && !isNotDisplayingOtherFields && (
                    <>
                      {isB2B && companyField(false)}
                      <Input
                        fluid
                        labelName={`First Name${isB2B ? ' (optional)' : ''}`}
                        name="firstName"
                        value={address.firstName || ''}
                        testId="billing-address-form--first-name"
                        labelId="billing-address-form--first-name"
                        onChange={handleInputChange}
                      />
                      <Input
                        fluid
                        labelName={`Last Name${isB2B ? ' (optional)' : ''}`}
                        testId="billing-address-form--last-name"
                        labelId="billing-address-form--last-name"
                        name="lastName"
                        value={address.lastName || ''}
                        onChange={handleInputChange}
                      />
                      {!isB2B && companyField(true)}
                    </>
                  )}

                  <AddressInput
                    fluid
                    labelName="Address Line 1"
                    testId={`${addressType}-form--address-line-1`}
                    labelId={`${addressType}-form--address-line-1`}
                    type="text"
                    name="addressLine1"
                    value={address.addressLine1 || ''}
                    onChange={handleInputChange}
                    autoComplete="none"
                    placeholder={'Street address or P.O. Box'}
                    data-needs-validation={Boolean(inputInvalidIdentifier?.addressLine1)}
                  />
                  <AddressInput
                    fluid
                    labelName={addressLine2}
                    testId={`${addressType}-form--address-line-2`}
                    labelId={`${addressType}-form--address-line-2`}
                    type="text"
                    name="addressLine2"
                    value={address.addressLine2 || ''}
                    onChange={handleInputChange}
                    autoComplete="none"
                    placeholder={'Apartment, suite, unit, building, floor, etc.'}
                    data-needs-validation={isSmartySecondaryNeeded}
                  />
                  {(isSecondaryAddressNeeded || isSecondaryBillingAddressNeeded) && (
                    <MissingInput>
                      <span>Please enter a suite or apartment number</span>
                    </MissingInput>
                  )}
                  <AddressInput
                    fluid
                    labelName="City"
                    testId={`${addressType}-form--city`}
                    labelId={`${addressType}-form--city`}
                    type="text"
                    name="city"
                    value={address.city || ''}
                    onChange={handleInputChange}
                    autoComplete="none"
                    placeholder={'New York'}
                    data-needs-validation={isEmbargoed || Boolean(inputInvalidIdentifier?.city)}
                    icon={icon}
                  />
                  {isEmbargoed && !savedAddressChecked && (
                    <ValidationMessage hasError={isEmbargoed} absoluteErrorMessage={true}>
                      Restricted city
                    </ValidationMessage>
                  )}
                  <StyledWrapper isNotDisplayingOtherFields={isNotDisplayingOtherFields}>
                    <InputAndLabelWrapper>
                      <AddressInput
                        fluid
                        labelName="State/Province/County"
                        testId={`${addressType}-form--state`}
                        labelId={`${addressType}-form--state`}
                        type="text"
                        name="state"
                        value={address.state || ''}
                        onChange={handleInputChange}
                        autoComplete="none"
                        placeholder={'NY'}
                        data-needs-validation={Boolean(inputInvalidIdentifier?.state)}
                      />
                    </InputAndLabelWrapper>
                    <InputAndLabelWrapper>
                      <AddressInput
                        fluid
                        labelName={`ZIP/Postal Code${isUSCountry || isCANCountry || isUKCountry ? '' : ' (optional)'}`}
                        testId={`${addressType}-form--zip-postal-code`}
                        labelId={`${addressType}-form--zip-postal-code`}
                        type="tel"
                        name="zipCode"
                        value={address.zipCode || ''}
                        onChange={handleInputChange}
                        autoComplete="none"
                        placeholder={'10105-0015'}
                        data-needs-validation={Boolean(inputInvalidIdentifier?.zipCode)}
                      />
                    </InputAndLabelWrapper>
                  </StyledWrapper>
                  <>
                    <StyledLabel htmlFor={`${addressType}-form--country`}>
                      {isNotDisplayingOtherFields ? 'Country' : 'Country/Region'}
                    </StyledLabel>
                    <StyledDropdown
                      name="country"
                      fluid
                      search
                      clearable
                      selection
                      minCharacters={1}
                      placeholder="Select country/region"
                      value={address.country || ''}
                      options={countriesOptions}
                      id={`${addressType}-country`}
                      data-testid={`${addressType}-country`}
                      onChange={handleInputChange}
                    />
                  </>
                  <>
                    <CheckboxStyled
                      name="isPrimary"
                      width="24"
                      height="24"
                      type="checkbox"
                      testId={`${addressType}--is-primary-address`}
                      label="Set as primary address"
                      checked={(address as Salesforce.ContactPointAddress).isPrimary || false}
                      onChange={handleInputChange}
                      disabled={!savedAddress?.length || savedAddress?.[0] === null}
                    />
                    <>
                      <StyledLabel htmlFor={`${addressType}-form--type`}>
                        {isNotDisplayingOtherFields ? 'Type of Address' : 'Address Type'}
                      </StyledLabel>
                      <StyledDropdown
                        name="addressType"
                        fluid
                        selection
                        placeholder="Select Address Type"
                        value={(address as Salesforce.ContactPointAddress).addressType}
                        options={addressTypeOptions}
                        id={`${addressType}-type`}
                        data-testid={`${addressType}-type`}
                        onChange={handleInputChange}
                      />
                    </>
                  </>
                  {showBypassAddressValidationCheckbox && (
                    <CheckboxStyled
                      name="bypassValidation"
                      width="18"
                      height="18"
                      type="checkbox"
                      testId={testIdSameShippingAddressForCheckbox}
                      label={bypassAddressValidationForCheckbox}
                      checked={(address as State.Address).bypassValidation}
                      onChange={handleBypassValidationOnclick}
                      disabled={btnIsDisabled}
                    />
                  )}
                </InputsWrapper>
              ))}
          </StyledForm>
        )}
      </StyledAddressWrapper>
    );
  }
);

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  align-self: flex-start;
  align-self: flex-start !important;
  margin-top: ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(30)};
  }
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  width: 71%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const MissingInput = styled.div`
  margin: ${props => props.theme.pxToRem(5)} 0 ${props => props.theme.pxToRem(-20)} 0;
  color: ${props => props.theme.colors.interfaceRed};
  font-size: ${props => props.theme.fontSizes.xxs};
`;

const AddressInput = styled(Input)<{ 'data-needs-validation': boolean }>`
  padding-bottom: ${props => props.theme.pxToRem(4)};
  &&&& {
    input {
      ${props => props['data-needs-validation'] && `border-color: ${props.theme.colors.interfaceRed}`}
    }
  }
`;

const AddressInputSignUp = styled(Input)<{ 'data-needs-validation': boolean }>`
  &&&& {
    input {
      line-height: normal;
      ${props => props['data-needs-validation'] && `border-color: ${props.theme.colors.interfaceRed}`}
    }
  }
`;

const StyledForm = styled(Form)`
  label {
    color: ${props => props.theme.colors.neutralGrey8};
    line-height: 1.5;
    font-family: ${props => props.theme.fontFamily};
    letter-spacing: normal;
    margin-top: ${props => props.theme.pxToRem(24)};
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledTitle = styled.h2`
  font-size: ${props => props.theme.fontSizes.l};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.regular};
  margin: ${props => props.theme.pxToRem(15)} auto ${props => props.theme.pxToRem(8)};
`;

const StyledAdditionalText = styled.p<{ useAvsDesigns: boolean }>`
  &&&& {
    font-size: ${props => props.theme.fontSizes.xs};
    color: ${props => props.theme.colors.neutralGrey8};
    line-height: 1.57;
    font-weight: ${props => props.theme.fontWeights.light};
    margin-bottom: ${props => (props.useAvsDesigns ? props.theme.pxToRem(16) : props.theme.pxToRem(33))};
    margin-top: 0;
  }
`;

const StyledAddressWrapper = styled.div`
  &&&& {
    padding: ${props => props.theme.pxToRem(10)} 0;
  }
`;

const InputsWrapper = styled.div<{ isNotDisplayingOtherFields?: boolean }>`
  width: ${props => (props?.isNotDisplayingOtherFields ? '100%' : '71%')};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const InputsWrapper100 = styled.div<{ isNotDisplayingOtherFields?: boolean }>`
  width: 100%;
  label {
    margin-top: 10px !important;
    font-size: ${props => props.theme.fontSizes.xs} !important;
  }
`;

const StyledLabel = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey7};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
`;

const StyledAdminLabel = styled.p`
  color: ${props => props.theme.colors.neutralGrey7};
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledDropdown = styled(Dropdown)`
  &&&& {
    &#shipping-address-country,
    &#billing-address-country {
      width: 80%;
    }

    padding: ${props => props.theme.pxToRem(9)} ${props => props.theme.pxToRem(15)};
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey4};
    color: ${props => props.theme.colors.neutralGrey8};
    line-height: 1.5;

    &.ui.selection.active.dropdown {
      border-color: ${props => props.theme.colors.primaryPurple};

      .menu {
        border-color: ${props => props.theme.colors.primaryPurple};
      }
    }
  }
`;

const StyledWrapper = styled.div<{ isNotDisplayingOtherFields?: boolean }>`
  display: flex;
  width: ${props => (props?.isNotDisplayingOtherFields ? '100%' : '80%')};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const InputAndLabelWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  :first-of-type {
    margin-right: ${props => props.theme.pxToRem(20)};
  }
`;

const FilterRadioLabelWrapper = styled.div`
  display: flex;
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    > label {
      font-size: ${props => props.theme.fontSizes.xs};
      color: ${props => props.theme.colors.neutralGrey8};
      font-weight: ${props => props.theme.fontWeights.light};
    }
  }
`;

interface ValidationMessageProps {
  hasError?: boolean;
  absoluteErrorMessage?: boolean;
}

const ValidationMessage = styled.div<ThemedStyledProps<ValidationMessageProps, DefaultTheme>>`
  margin-bottom: ${props => props.theme.pxToRem(4)};
  color: ${props => props.theme.colors.neutralGrey5};
  font-size: ${props => props.theme.fontSizes.xxs};
  ${({ hasError, theme, absoluteErrorMessage }) =>
    hasError &&
    `
      color: ${theme.colors.interfaceRed};
      position: ${absoluteErrorMessage ? 'absolute' : 'static'}
    `}
`;

const StyledWrongIcon = styled(Wrong)`
  &&&&&&& {
    position: absolute;
    right: ${props => props.theme.pxToRem(12)};
    top: ${props => props.theme.pxToRem(12)};
    width: ${props => props.theme.pxToRem(23)};
    height: ${props => props.theme.pxToRem(23)};
    color: ${props => props.theme.colors.interfaceRed};
  }
`;

const StyledDividerM24 = styled(Divider)`
  &&&& {
    margin: ${props => props.theme.pxToRem(24)} 0;
  }
`;

const SectionSubHeader = styled.h4`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.primaryPurple};
`;

const RequiredLabel = styled.label`
  color: ${props => props.theme.colors.interfaceRed}!important;
`;
