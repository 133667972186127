import { createSelector } from 'reselect';

const rootSelector = createSelector(
  (state: State.Root) => state.header,
  (header: State.Header): State.Header => header
);

export const megamenuSelector = createSelector(
  rootSelector,
  (header: State.Header): State.Level0Type[] => header.megamenu
);

export const megamenuFetchedSelector = createSelector(
  rootSelector,
  (header: State.Header): boolean => header.megamenuFetched
);
