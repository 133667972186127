import { Dispatch } from 'redux';
import { isAuthSelector } from 'modules/user/selectors';
import { setSessionStorageItem, getSessionStorageItem } from 'utils/sessionStorage';
import { handleEvent } from 'utils/Analytics';
import { showLogin } from 'modules/layouts';
import { StorageNames } from 'constants/index';

export const loginRequiredMetaData = { isLoginRequired: true };

const saveActionAndRedirectToLogin = (type: string, payload: any) => {
  const actionsToDispatchAfterLogin: { type: string; payload: any }[] =
    (getSessionStorageItem(StorageNames.actionsToDispatchAfterLogin) as any) || [];

  setSessionStorageItem({
    [StorageNames.actionsToDispatchAfterLogin]: [...actionsToDispatchAfterLogin, { type, payload: [...payload] }],
  });
};

// ------------------------------------
// Middleware
// ------------------------------------
export const loginRequiredMiddleware = ({ dispatch, getState }: { dispatch: Dispatch; getState: () => State.Root }) => (
  next: any
) => (action: { type: string; payload: any; meta: any }) => {
  const { type, meta } = action;
  const state: State.Root = getState();
  const isAuth: boolean | null = isAuthSelector(state);

  // Skip location change page_view event for dynamic routes.
  const isLocationChangeEvent: boolean = type === '@@router/LOCATION_CHANGE';
  if (isLocationChangeEvent) {
    return next(action);
  }

  if (meta && meta.isLoginRequired && !isAuth) {
    const { payload } = meta;
    saveActionAndRedirectToLogin(type, payload);
    dispatch(showLogin({ redirectToLoginPage: false }));
    handleEvent(payload, type, state);
    return;
  }

  return next(action);
};
