import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import { Modal, ButtonVariants } from 'components/atoms';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { ApplicationEmploymentFormContainer } from 'containers/MembershipApplication/ApplicationEmploymentFormContainer';
import { Table } from 'semantic-ui-react';
import { isUserMemberSelector } from 'modules/user/selectors';
import { Routes } from 'constants/index';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
interface Props {
  isOpenModal: boolean;
  handleSelectedEmployment?: (value: any) => void;
  stayOnClose?: boolean;
}
export const ModalEmploymentInformation: React.FC<Props> = ({ isOpenModal, handleSelectedEmployment, stayOnClose }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isUserMember = useSelector(isUserMemberSelector);

  const heading = isUserMember ? 'Are your employment details still correct?' : 'Which role applies to you?';
  const headerInfo = isUserMember
    ? 'Your selection will be final. You will no longer be able to change it for this application.'
    : `You’ll receive content specific to this role, including information about how you can excel in it and advance your career. Please note: This role cannot be changed later in the application process.`;

  const goToPreviousPage = () => {
    setOpen(false);
    if (stayOnClose) {
      return;
    }
    dispatch(push(getPath(Routes.MEMBERSHIP_FORM)));
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    setOpen(isOpenModal);
  }, [setOpen, isOpenModal]);

  return (
    <>
      <StyledModal size="tiny" heading={heading} open={isOpen}>
        <CloseButton>
          <StyledCloseIcon onClick={goToPreviousPage} />
        </CloseButton>
        <Spacer />
        <CenteredText>{headerInfo}</CenteredText>
        <StyledTableRow>
          <ApplicationEmploymentFormContainer
            isForModal={isOpen}
            handleModalClose={handleClose}
            handleSelectedEmployment={handleSelectedEmployment}
          />
        </StyledTableRow>
      </StyledModal>
    </>
  );
};

interface ModalButtonProps {
  onClick: () => void;
  isDisabled: boolean;
  variant?: ButtonVariants;
}

const StyledTableRow = styled(Table.Row)<ThemedStyledProps<{ expanded: boolean }, DefaultTheme>>`
&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      box-shadow: ${props => (!props.expanded ? '0 -0.0625rem 0 0 #e5e5e6 inset !important' : 'none !important')};
    }
  }

&&&&&& {
  width: ${props => props.theme.pxToRem(520)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(296)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  font-weight: ${props => props.theme.fontWeights.medium};
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    width: ${props => props.theme.pxToRem(620)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => props.theme.pxToRem(336)};
    }
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    > .header {
      h2 {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100%;
      }
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    right: ${props => props.theme.pxToRem(-144)};
    top: ${props => props.theme.pxToRem(8)};
  }
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
