import React from 'react';
import { theme } from 'theme';
interface Props {
  size?: number;
  color?: string;
}

export const DownloadArrow = React.memo<Props>(({ size = 24, color = theme.colors.primaryPurple, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...props}>
    <path fill={color} fillRule="evenodd" d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
  </svg>
));
