import { User, Content, Product, Orders, Checkout } from 'mxp-schemas';
import { ReactComponent as ProfessionalInsightIcon } from 'resources/images/professional-insight.svg';
import { ReactComponent as NewsIcon } from 'resources/images/news.svg';
import { ReactComponent as ResourcesIcon } from 'resources/images/resources.svg';
import { ReactComponent as CPEAndLearningIcon } from 'resources/images/cpeLearning.svg';
import { theme } from 'theme';

const categoryList: Content.CategorySlugs[] = Object.values(Content.CategorySlugs);
export const categoryListStr: string = categoryList.join('|');

export const COMPANY_NAME = 'AICPA & CIMA';

type Noop = (i?: any) => string;

export type PageTitleValues = TitleValues | null | undefined;

const getFirmNaming = (isCima?: boolean) => (isCima ? 'Organization' : 'Firm');

interface Route {
  path: string;
  title?: string | Noop;
  useAnalytics?: boolean;
  public?: boolean;
}

export interface PageTitleItemProps {
  [key: string]: (values?: PageTitleValues) => string;
}

// fixMe the order is important as PageTitle is using matchPath

export enum Routes {
  ROOT = 'ROOT',
  HOME = 'HOME',
  CAMPAIGNS = 'CAMPAIGNS',
  STATIC_LANDING_PAGES = 'STATIC_LANDING_PAGES',
  FEED = 'FEED',
  LOGIN = 'LOGIN',
  FORGOT_EMAIL = 'FORGOT_EMAIL',
  PREFERENCES = 'PREFERENCES',
  // profile routes
  PROFILE_ROOT = 'PROFILE_ROOT',
  PROFILE_PREFERENCES = 'PROFILE_PREFERENCES',
  PROFILE_PURCHASES = 'PROFILE_PURCHASES',
  PROFILE_RECEIPTS = 'PROFILE_RECEIPTS',
  PROFILE_RECEIPT_CHECKOUT = 'PROFILE_RECEIPT_CHECKOUT',
  PROFILE_RECEIPT_CHECKOUT_ERROR = 'PROFILE_RECEIPT_CHECKOUT_ERROR',
  PROFILE_RECEIPT_PAYMENT_CONFIRMATION = 'PROFILE_RECEIPT_PAYMENT_CONFIRMATION',
  PROFILE_WALLET = 'PROFILE_WALLET',
  PROFILE_CONTACT_PREFERENCES = 'PROFILE_CONTACT_PREFERENCES',
  PROFILE_SAVED_ITEMS = 'PROFILE_SAVED_ITEMS',

  // my profile routes
  MY_PROFILE_ROOT = 'MY_PROFILE_ROOT',
  MY_PERSONAL_DETAILS = 'MY_PERSONAL_DETAILS',
  MY_CONTACT_DETAILS = 'MY_CONTACT_DETAILS',
  MY_EMPLOYMENT_DETAILS = 'MY_EMPLOYMENT_DETAILS',
  MY_TUITION_PROVIDER = 'MY_TUITION_PROVIDER',
  PROFILE_LOGIN_SETTINGS = 'PROFILE_LOGIN_SETTINGS',
  PROFILE_SECURITY_QUESTION = 'PROFILE_SECURITY_QUESTION',
  MY_TOPIC_PREFERENCES = 'MY_TOPIC_PREFERENCES',
  MY_PRACTICE_AREAS = 'MY_PRACTICE_AREAS',
  MY_INDUSTRIES = 'MY_INDUSTRIES',
  MY_TRENDS = 'MY_TRENDS',
  MY_JOBROLES = 'MY_JOBROLES',
  MY_CAREERSTAGES = 'MY_CAREERSTAGES',
  MY_CONTENTLANGUAGES = 'MY_CONTENTLANGUAGES',
  MY_SKILLS = 'MY_SKILLS',
  MY_LOCATIONS = 'MY_LOCATIONS',
  MY_CONSENTS = 'MY_CONSENTS',
  MY_MARKETING_PREFERENCES = 'MY_MARKETING_PREFERENCES',
  MY_PAYMENT_METHODS = 'MY_PAYMENT_METHODS',
  MY_RECEIPTS = 'MY_RECEIPTS',
  MY_PURCHASES = 'MY_PURCHASES',
  MY_CREDENTIALS = 'MY_CREDENTIALS',
  MY_SECTIONS = 'MY_SECTIONS',
  MY_MEMBERSHIPS = 'MY_MEMBERSHIPS',
  CIMA_EXAMS_DASHBOARD_ROOT = 'CIMA_EXAMS_DASHBOARD_ROOT',
  CIMA_EXAM_BOOK_PERSONAL_DETAILS = 'CIMA_EXAM_BOOK_PERSONAL_DETAILS',
  CIMA_EXAM_BOOK_TERMS_CONDITIONS = 'CIMA_EXAM_BOOK_TERMS_CONDITIONS',

  // CIMAs Exam Exemption
  CIMA_EXAMS_EXEMPTIONS_FILE_UPLOAD = 'CIMA_EXAMS_EXEMPTIONS_FILE_UPLOAD',
  CIMA_EXAMS_EXEMPTIONS_VIEW_FILES = 'CIMA_EXAMS_EXEMPTIONS_VIEW_FILES',

  // CIMAs Exam Gateway
  CIMA_EXAMS_GATEWAY_FILE_UPLOAD = 'CIMA_EXAMS_GATEWAY_FILE_UPLOAD',
  CIMA_EXAMS_GATEWAY_VIEW_FILES = 'CIMA_EXAMS_GATEWAY_VIEW_FILES',

  // CIMAs Post-Exams
  CIMA_POST_EXAMS_SPECIAL_CONSIDERATIONS = 'CIMA_POST_EXAMS_SPECIAL_CONSIDERATIONS',

  // B2B organization profile routes
  ORG_ROOT = 'ORG_ROOT',
  ORG_FIRM_INFORMATION = 'ORG_FIRM_INFORMATION',
  ORG_SEAT_MANAGEMENT = 'ORG_SEAT_MANAGEMENT',
  ORG_INVOICES = 'ORG_INVOICES',
  ORG_INVOICE_CHECKOUT = 'ORG_INVOICE_CHECKOUT',
  ORG_INVOICE_PAYMENT_CONFIRMATION = 'ORG_INVOICE_PAYMENT_CONFIRMATION',
  ORG_INVOICES_PAYMENT_CONFIRMATION = 'ORG_INVOICES_PAYMENT_CONFIRMATION',
  ORG_INVOICE_ERROR = 'ORG_INVOICE_ERROR',

  // B2B organization invoice paypal routes
  ORG_INVOICE_CHECKOUT_PAYPAL_PROCEED = 'ORG_INVOICE_CHECKOUT_PAYPAL_PROCEED',

  // Firm Roster & Billing profile routes
  FIRM_ROOT = 'FIRM_ROOT',
  FIRM_INFO = 'FIRM_INFO',
  FIRM_INFO_TAB_BASIC_COMPANY_DETAILS = 'FIRM_INFO_TAB_BASIC_COMPANY_DETAILS',
  FIRM_ROSTER = 'FIRM_ROSTER',
  FIRM_ROSTER_TAB_MANAGE = 'FIRM_ROSTER_TAB_MANAGE',
  FIRM_ROSTER_TAB_MEMBERS = 'FIRM_ROSTER_TAB_MEMBERS',
  FIRM_ROSTER_TAB_SUPPORT = 'FIRM_ROSTER_TAB_SUPPORT',
  FIRM_ROSTER_TAB_FLP_SUBSCRIPTIONS = 'FIRM_ROSTER_TAB_FLP_SUBSCRIPTIONS',
  FIRM_BILLING = 'FIRM_BILLING',
  FIRM_BILLING_TAB_MANAGE = 'FIRM_BILLING_TAB_MANAGE',
  FIRM_BILLING_TAB_MEMBERSHIPS = 'FIRM_BILLING_TAB_MEMBERSHIPS',
  FIRM_BILLING_TAB_INVOICES = 'FIRM_BILLING_TAB_INVOICES',
  FIRM_BILLING_INVOICE_CHECKOUT = 'FIRM_BILLING_INVOICE_CHECKOUT',
  FIRM_BILLING_INVOICE_CONFIRMATION = 'FIRM_BILLING_INVOICE_CONFIRMATION',
  FIRM_BILLING_TAB_FLP_ORGANIZATION = 'FIRM_BILLING_TAB_FLP_ORGANIZATION',
  FIRM_BILLING_TAB_SUPPORT = 'FIRM_BILLING_TAB_SUPPORT',
  FIRM_BILLING_INVOICE_CHECKOUT_PAYPAL_PROCEED = 'FIRM_BILLING_INVOICE_CHECKOUT_PAYPAL_PROCEED',
  // Center Admin
  CENTER_ADMIN_ROOT = 'CENTER_ADMIN_ROOT',
  CENTER_ADMIN_TAB_INFO = 'CENTER_ADMIN_TAB_INFO',
  CENTER_ADMIN_MEMBERSHIPS = 'CENTER_ADMIN_MEMBERSHIPS',
  CENTER_ADMIN_MEMBERSHIPS_TAB_MANAGE = 'CENTER_ADMIN_MEMBERSHIPS_TAB_MANAGE',
  CENTER_ADMIN_MEMBERSHIPS_TAB_INVOICES = 'CENTER_ADMIN_MEMBERSHIPS_TAB_INVOICES',
  CENTER_ADMIN_MEMBERSHIPS_SEATS_MANAGEMENT = 'CENTER_ADMIN_MEMBERSHIPS_SEATS_MANAGEMENT',
  CENTER_ADMIN_INVOICE_PAYMENT = 'CENTER_ADMIN_INVOICE_PAYMENT',
  CENTER_ADMIN_INVOICE_PAYMENT_ERROR = 'CENTER_ADMIN_INVOICE_PAYMENT_ERROR',
  CENTER_ADMIN_INVOICE_PAYMENT_CONFIRMATION = 'CENTER_ADMIN_INVOICE_PAYMENT_CONFIRMATION',

  // Tuition Provider
  TUITION_PROVIDER_PERSONAL_DETAILS = 'TUITION_PROVIDER_PERSONAL_DETAILS',
  TUITION_PROVIDER_EMPLOYER_DETAILS = 'TUITION_PROVIDER_EMPLOYER_DETAILS',
  TUITION_PROVIDER_APPRENTICE_ROSTER = 'TUITION_PROVIDER_APPRENTICE_ROSTER',
  TUITION_PROVIDER_ROOT = 'TUITION_PROVIDER_ROOT',
  TUITION_PROVIDER_TAB_INFO = 'TUITION_PROVIDER_TAB_INFO',

  // Paypal
  PAYPAL_PAYMENT_CHECKOUT_PROCEED = 'PAYPAL_PAYMENT_CHECKOUT_PROCEED',
  PAYPAL_PAYMENT_CHECKOUT_CANCELLED = 'PAYPAL_PAYMENT_CHECKOUT_CANCELLED',

  ABOUT = 'ABOUT',
  HELP = 'HELP',
  SITE_MAP = 'SITE_MAP',
  SHIPPING_DELIVERY = 'SHIPPING_DELIVERY',
  CONTACT_US = 'CONTACT_US',
  CONTACT_US_AICPA = 'CONTACT_US_AICPA',
  CONTACT_US_CIMA = 'CONTACT_US_CIMA',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_CONDITIONS = 'TERMS_CONDITIONS',
  PRIVACY_CALIFORNIA_FORM = 'PRIVACY_CALIFORNIA_FORM',
  PRIVACY_GDPR = 'PRIVACY_GDPR',
  TERMS_CONDITIONS_SUBSCRIPTIONS = 'TERMS_CONDITIONS_SUBSCRIPTIONS',
  TERMS_CONDITIONS_STANDING_ORDERS = 'TERMS_CONDITIONS_STANDING_ORDERS',
  TERMS_CONDITIONS_CONFERENCE = 'TERMS_CONDITIONS_CONFERENCE',
  CONFERENCE_COVID_WAIVER = 'CONFERENCE_COVID_WAIVER',
  CONFERENCE_SAFETY_PROTOCOLS = 'CONFERENCE_SAFETY_PROTOCOLS',
  WEBSITE_ACCESSIBILITY = 'WEBSITE_ACCESSIBILITY',
  WEBCAST_DETAILS = 'WEBCAST_DETAILS',
  PUBLICATION_DETAILS = 'PUBLICATION_DETAILS',
  CONFERENCE_DETAILS = 'CONFERENCE_DETAILS',
  EXAM_DETAILS = 'EXAM_DETAILS',
  COURSE_DETAILS = 'COURSE_DETAILS',
  SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS',
  WHAT_TO_EXPECT_FROM_SUBSCRIPTIONS = 'WHAT_TO_EXPECT_FROM_SUBSCRIPTIONS',
  SEARCH_HASH = 'SEARCH_HASH',
  BLANK_HASH = 'BLANK_HASH',
  EXIT_SURVEY_HASH = 'EXIT_SURVEY_HASH',
  TELLUS_SURVEY_HASH = 'TELLUS_SURVEY_HASH',
  NOT_FOUND = 'NOT_FOUND',
  FEATURE_TOGGLES = 'FEATURE_TOGGLES',
  SEARCH = 'SEARCH',
  TOPIC_AGGS_PAGE = 'TOPIC_AGGS_PAGE',
  INDUSTRY_AGGS_PAGE = 'INDUSTRY_AGGS_PAGE',
  TREND_AGGS_PAGE = 'TREND_AGGS_PAGE',
  SKILL_AGGS_PAGE = 'SKILL_AGGS_PAGE',
  STOREFRONT_PAGE = 'STOREFRONT_PAGE',
  PRODUCT_PAGE = 'PRODUCT_PAGE',
  PRODUCT_PAGE_OLD = 'PRODUCT_PAGE_OLD',
  PRODUCT_AGGS_BY_TOPIC_PAGE = 'PRODUCT_AGGS_BY_TOPIC_PAGE',
  PRODUCT_AGGS_BY_TYPE_PAGE = 'PRODUCT_AGGS_BY_TYPE_PAGE',
  PRODUCT_AGGS_CALENDAR = 'PRODUCT_AGGS_CALENDAR',
  CART_PAGE = 'CART_PAGE',
  UNFOLLOW_UNSUCCESSFUL_HASH = 'UNFOLLOW_UNSUCCESSFUL_HASH',
  CHECKOUT_PAGE = 'CHECKOUT_PAGE',
  ORDER_ERROR_PAGE = 'ORDER_ERROR_PAGE',
  CONTENT_PAGE_OLD = 'CONTENT_PAGE_OLD',
  CONTENT_PAGE = 'CONTENT_PAGE',
  CONTENT_PAGE_CIMA = 'CONTENT_PAGE_CIMA',
  PRODUCT_ACCESS_ERROR_PAGE = 'PRODUCT_ACCESS_ERROR_PAGE',
  TOOLKIT_PAGE = 'TOOLKIT_PAGE',
  REFUND_POLICY = 'REFUND_POLICY',
  REGISTRATION_HASH = 'REGISTRATION_HASH',
  LOGIN_HASH = 'LOGIN_HASH',
  CONFERENCE_SYSTEM_REQUIREMENTS = 'CONFERENCE_SYSTEM_REQUIREMENTS',
  CONFERENCE_CANCELLATION_POLICY = 'CONFERENCE_CANCELLATION_POLICY',
  REGISTRATION = 'REGISTRATION',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  PROFILE_PERSONAL_DETAILS = 'PROFILE_PERSONAL_DETAILS',
  CATEGORY_AGGS_PAGE = 'CATEGORY_AGGS_PAGE',
  CATEGORY_AGGS_PAGE_CIMA = 'CATEGORY_AGGS_PAGE_CIMA',
  PREMIUM_CONTENT_AGGS_PAGE = 'PREMIUM_CONTENT_AGGS_PAGE',
  DIGITAL_BADGES = 'DIGITAL_BADGES',
  STANDING_ORDERS = 'STANDING_ORDERS',
  SHARE_ALLOCATION = 'SHARE_ALLOCATION',
  CPE_MANAGER = 'CPE_MANAGER',
  ANNUAL_WEBCAST = 'ANNUAL_WEBCAST',
  DONATION_PAGE = 'DONATION_PAGE',
  VERGIC_CALENDAR = 'VERGIC_CALENDAR',
  NASBA_SPONSORSHIP = 'NASBA_SPONSORSHIP',
  DOUBLE_DONATION = 'DOUBLE_DONATION',
  CONTENT_PAGE_PREFIX = 'CONTENT_PAGE_PREFIX',

  // membership routes
  MEMBERSHIP_FORM = 'MEMBERSHIP_FORM',
  APPLICATION_FORM = 'APPLICATION_FORM',
  APPLICATION_FORM_START = 'APPLICATION_FORM_START',
  APPLICATION_FORM_PERSONAL = 'APPLICATION_FORM_PERSONAL',
  APPLICATION_FORM_QUALIFICATION = 'APPLICATION_FORM_QUALIFICATION',
  APPLICATION_FORM_EMPLOYMENT = 'APPLICATION_FORM_EMPLOYMENT',
  APPLICATION_FORM_DONATIONS = 'APPLICATION_FORM_DONATIONS',
  APPLICATION_FORM_ATTESTATION = 'APPLICATION_FORM_ATTESTATION',
  MEMBERSHIP_BENEFIT = 'MEMBERSHIP_BENEFIT',
  CREDENTIALS_APPLICATION_FORM = 'CREDENTIALS_APPLICATION_FORM',
  INVITE_THANKYOU_PAGE = 'INVITE_THANKYOU_PAGE',
  CONFIRMATION_BENEFIT = 'CONFIRMATION_BENEFIT',
  MEMBERSHIP_OVERVIEW = 'MEMBERSHIP_OVERVIEW',

  // new membership routes
  PRE_SIGN_UP = 'PRE_SIGN_UP',
  PAGE_PAYMENT = 'PAGE_PAYMENT',

  // cima membership routes
  CIMA_MEMBERSHIP_STUDYPATH = 'CIMA_MEMBERSHIP_STUDYPATH',
  CIMA_MEMBERSHIP_STUDYPATH_PATHWAY = 'CIMA_MEMBERSHIP_STUDYPATH_PATHWAY',
  CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS = 'CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS',
  CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_PROFESSIONAL = 'CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_PROFESSIONAL',
  CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_EDUCATIONAL = 'CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_EDUCATIONAL',
  CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_WORK = 'CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_WORK',
  CIMA_MEMBERSHIP_PACKAGE = 'CIMA_MEMBERSHIP_PACKAGE',
  CIMA_MEMBERSHIP_APPLICATION = 'CIMA_MEMBERSHIP_APPLICATION',
  CIMA_MEMBERSHIP_APPLICATION_FORM = 'CIMA_MEMBERSHIP_APPLICATION_FORM',
  CIMA_MEMBERSHIP_APPLICATION_FORM_START = 'CIMA_MEMBERSHIP_APPLICATION_FORM_START',
  CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL = 'CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL',
  CIMA_MEMBERSHIP_APPLICATION_FORM_CPA = 'CIMA_MEMBERSHIP_APPLICATION_FORM_CPA',
  CIMA_MEMBERSHIP_APPLICATION_FORM_EMPLOYMENT = 'CIMA_MEMBERSHIP_APPLICATION_FORM_EMPLOYMENT',
  CIMA_MEMBERSHIP_APPLICATION_FORM_TRAINING = 'CIMA_MEMBERSHIP_APPLICATION_FORM_TRAINING',
  CIMA_MEMBERSHIP_APPLICATION_FORM_DOCUMENT = 'CIMA_MEMBERSHIP_APPLICATION_FORM_DOCUMENT',
  CIMA_MEMBERSHIP_APPLICATION_FORM_GATEWAY_DOCUMENT = 'CIMA_MEMBERSHIP_APPLICATION_FORM_GATEWAY_DOCUMENT',
  CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS = 'CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS',
  CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION = 'CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION',
  CIMA_MEMBERSHIP_PROMO_CODE = 'CIMA_MEMBERSHIP_PROMO_CODE',
  CIMA_MEMBERSHIP_EXAM_FILE_UPLOAD = 'CIMA_MEMBERSHIP_EXAM_FILE_UPLOAD',
  CIMA_MEMBERSHIP_DOCUMENT_FILE_UPLOAD = 'CIMA_MEMBERSHIP_DOCUMENT_FILE_UPLOAD',
  CIMA_MEMBERSHIP_REGIONAL_PATHWAY = 'CIMA_MEMBERSHIP_REGIONAL_PATHWAY',

  // offline exemption calculator
  EXEMPTION_CALCULATOR_ROOT = 'EXEMPTION_CALCULATOR_ROOT',
  EXEMPTION_CALCULATOR_PROFESSIONAL = 'EXEMPTION_CALCULATOR_PROFESSIONAL',
  EXEMPTION_CALCULATOR_WORK = 'EXEMPTION_CALCULATOR_WORK',
  EXEMPTION_CALCULATOR_EDUCATIONAL = 'EXEMPTION_CALCULATOR_EDUCATIONAL',
  EXEMPTION_CALCULATOR_SUMMARY = 'EXEMPTION_CALCULATOR_SUMMARY',
  EXEMPTION_CALCULATOR_RESULT = 'EXEMPTION_CALCULATOR_RESULT',

  // fcma membership routes
  FCMA_CREDENTIAL_APPLICATION_FORM_PERSONAL = 'FCMA_CREDENTIAL_APPLICATION_FORM_PERSONAL',
  FCMA_CREDENTIAL_APPLICATION_FORM_EMPLOYMENT = 'FCMA_CREDENTIAL_APPLICATION_FORM_EMPLOYMENT',
  FCMA_CREDENTIAL_APPLICATION_FORM_DONATIONS = 'FCMA_CREDENTIAL_APPLICATION_FORM_DONATIONS',
  FCMA_CREDENTIAL_APPLICATION_FORM_ATTESTATION = 'FCMA_CREDENTIAL_APPLICATION_FORM_ATTESTATION',
  CIMA_FCMA_CREDENTIAL_ROUTE = 'CIMA_FCMA_CREDENTIAL_ROUTE',
  CIMA_ACMA_CREDENTIAL_ROUTE = 'CIMA_ACMA_CREDENTIAL_ROUTE',

  // center membership
  CENTER_MEMBERSHIP = 'CENTER_MEMBERSHIP',
  CENTER_MEMBERSHIP_FORM = 'CENTER_MEMBERSHIP_FORM',
  CENTER_MEMBERSHIP_APPLICATION_FORM = 'CENTER_MEMBERSHIP_APPLICATION_FORM',
  CENTER_MEMBERSHIP_FORM_START = 'CENTER_MEMBERSHIP_FORM_START',
  CENTER_MEMBERSHIP_FORM_ADMIN = 'CENTER_MEMBERSHIP_FORM_ADMIN',
  CENTER_MEMBERSHIP_FORM_FIRM = 'CENTER_MEMBERSHIP_FORM_FIRM',
  CENTER_MEMBERSHIP_FORM_ATTESTATION = 'CENTER_MEMBERSHIP_FORM_ATTESTATION',
  CENTER_MEMBERSHIP_FORM_SHIPPING = 'CENTER_MEMBERSHIP_FORM_SHIPPING',
  CENTER_MEMBERSHIP_FORM_PAYMENT = 'CENTER_MEMBERSHIP_FORM_PAYMENT',
  CENTER_MEMBERSHIP_RENEWAL = 'CENTER_MEMBERSHIP_RENEWAL',
  CENTER_MEMBERSHIP_PROVISIONING = 'CENTER_MEMBERSHIP_PROVISIONING',

  // cima exam results routes
  CIMA_EXAM_RESULTS = 'CIMA_EXAM_RESULTS',
  CIMA_ALL_EXAM_RESULTS = 'CIMA_ALL_EXAM_RESULTS',

  // admin routes
  ADMIN_ROOT = 'ADMIN_ROOT',
  ADMIN_NO_FETCH_RESULTS = 'ADMIN_NO_FETCH_RESULTS',
  ADMIN_ERROR_PAGE = 'ADMIN_ERROR_PAGE',
  ADMIN_CANCEL = 'ADMIN_CANCEL',
  ADMIN_ADD_CLIENT_ADMIN = 'ADMIN_ADD_CLIENT_ADMIN',
  ADMIN_CLIENT_ADMIN_RESULTS = 'ADMIN_CLIENT_ADMIN_RESULTS',
  ADMIN_ORGANIZATION = 'ADMIN_ORGANIZATION',
  ADMIN_ORDER_DETAILS = 'ADMIN_ORDER_DETAILS',
  ADMIN_ORDER_REFUND_CANCEL = 'ADMIN_ORDER_REFUND_CANCEL',
  ADMIN_CREATE_ORDER = 'ADMIN_CREATE_ORDER',
  ADMIN_INVOICES = 'ADMIN_INVOICES',
  ADMIN_ORGANIZATION_ROOT = 'ADMIN_ORGANIZATION_ROOT',
  ADMIN_USER = 'ADMIN_USER',
  ADMIN_OPERATIONS = 'ADMIN_OPERATIONS',
  ADMIN_INVOICES_SEARCH = 'ADMIN_INVOICES_SEARCH',
  ADMIN_INVOICES_SEARCH_RESULTS = 'ADMIN_INVOICES_SEARCH_RESULTS',
  ADMIN_INVOICES_PAYMENT = 'ADMIN_INVOICES_PAYMENT',
  ADMIN_INVOICES_PAYMENT_CONFIRMATION = 'ADMIN_INVOICES_PAYMENT_CONFIRMATION',
  ADMIN_INVOICES_PAYMENT_ERROR = 'ADMIN_INVOICES_PAYMENT_ERROR',
  ADMIN_INVOICES_FILTER = 'ADMIN_INVOICES_FILTER',
  ADMIN_INVOICES_DETAILS = 'ADMIN_INVOICES_DETAILS',
  ADMIN_BULK_RENEWAL = 'ADMIN_BULK_RENEWAL',

  // practical experience requirement routes
  PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT = 'PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT',
  PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT = 'PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT',
  PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES = 'PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES',
  PRACTICAL_EXPERIENCE_REQUIREMENT_SKILLS_BEHAVIORS = 'PRACTICAL_EXPERIENCE_REQUIREMENT_SKILLS_BEHAVIORS',
  PRACTICAL_EXPERIENCE_REQUIREMENT_SUPERVISOR = 'PRACTICAL_EXPERIENCE_REQUIREMENT_SUPERVISOR',

  // cima exams dashboard routes
  CIMA_EXAMS_DASHBOARD_EPA2L4 = 'CIMA_EXAMS_DASHBOARD_EPA2L4',
  CIMA_EXAMS_DASHBOARD_EPA2L7 = 'CIMA_EXAMS_DASHBOARD_EPA2L7',
  CIMA_DASHBOARD_LEARN_MORE = 'CIMA_DASHBOARD_LEARN_MORE',
  CIMA_EXAM_HISTORY = 'CIMA_EXAM_HISTORY',

  // cima supervisor dashboard routes
  CIMA_REVIEWER_ROOT = 'CIMA_REVIEWER_ROOT',
  CIMA_REVIEWER_DETAILS = 'CIMA_REVIEWER_DETAILS',
  CIMA_REVIEWER_EXPIRED = 'CIMA_REVIEWER_EXPIRED',
  PRACTICAL_EXPERIENCE_REQUIREMENT = 'PRACTICAL_EXPERIENCE_REQUIREMENT',
  PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT_REVIEW = 'PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT_REVIEW',
  PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES_REVIEW = 'PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES_REVIEW',
  PRACTICAL_EXPERIENCE_REQUIREMENT_SKILL_BEHAVIOUR_REVIEW = 'PRACTICAL_EXPERIENCE_REQUIREMENT_SKILL_BEHAVIOUR_REVIEW',
  END_POINT_ASSESSMENT = 'END_POINT_ASSESSMENT',
  PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT_REVIEW_TEST = 'PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT_REVIEW_TEST',

  BLOG_FEED = 'BLOG_FEED',

  MEMBER_CREDENTIAL_DIRECTORY = 'MEMBER_CREDENTIAL_DIRECTORY',
  MEMBER_CREDENTIAL_DIRECTORY_RESULTS = 'MEMBER_CREDENTIAL_DIRECTORY_RESULTS',

  EVENT_REGISTRATION = 'EVENT_REGISTRATION',

  // CIMA MiP Application Routes
  CIMA_MIP_ROOT = 'CIMA_MIP_ROOT',
  CIMA_MIP_APPLICATION_MAIN_PAGE = 'CIMA_MIP_APPLICATION_MAIN_PAGE',
  CIMA_MIP_CONTACT_APPLICATION_PAGE = 'CIMA_MIP_CONTACT_APPLICATION_PAGE',
  CIMA_MIP_WORK_HISTORY_APPLICATION_PAGE = 'CIMA_MIP_WORK_HISTORY_APPLICATION_PAGE',
  CIMA_MIP_PRACTICE_REQUIREMENTS_APPLICATION_PAGE = 'CIMA_MIP_PRACTICE_REQUIREMENTS_APPLICATION_PAGE',

  CIMA_MIP_AML_APPLICATION = 'CIMA_MIP_AML_APPLICATION',
  CIMA_MIP_AML_APPLICATION_CTF_RETURN_FORM = 'CIMA_MIP_AML_APPLICATION_CTF_RETURN_FORM',
  CIMA_MIP_AML_APPLICATION_CTF_RETURN_RESPONSE_SUMMARY = 'CIMA_MIP_AML_APPLICATION_CTF_RETURN_RESPONSE_SUMMARY',
  CIMA_MIP_AML_APPLICATION_ATTESTATION = 'CIMA_MIP_AML_APPLICATION_ATTESTATION',

  CIMA_MIP_CHECKOUT = 'CIMA_MIP_CHECKOUT',
  CIMA_MIP_CHECKOUT_ATTESTATION = 'CIMA_MIP_CHECKOUT_ATTESTATION',
  CIMA_MIP_CHECKOUT_MIP_DETAILS = 'CIMA_MIP_CHECKOUT_MIP_DETAILS',
  CIMA_MIP_CHECKOUT_ADDRESS = 'CIMA_MIP_CHECKOUT_ADDRESS',
  CIMA_MIP_CHECKOUT_PAYMENT = 'CIMA_MIP_CHECKOUT_PAYMENT',

  // CIMA Renewal Application Routes
  CIMA_MIP_RENEWAL_ROOT = 'CIMA_MIP_RENEWAL_ROOT',
  CIMA_MIP_RENEWAL_APPLICATION_MAIN_PAGE = 'CIMA_MIP_RENEWAL_APPLICATION_MAIN_PAGE',
  CIMA_MIP_RENEWAL_CONTACT_APPLICATION_PAGE = 'CIMA_MIP_RENEWAL_CONTACT_APPLICATION_PAGE',
  CIMA_MIP_RENEWAL_WORK_HISTORY_APPLICATION_PAGE = 'CIMA_MIP_RENEWAL_WORK_HISTORY_APPLICATION_PAGE',
  CIMA_MIP_RENEWAL_PRACTICE_REQUIREMENTS_APPLICATION_PAGE = 'CIMA_MIP_RENEWAL_PRACTICE_REQUIREMENTS_APPLICATION_PAGE',

  CIMA_MIP_RENEWAL_AML_APPLICATION = 'CIMA_MIP_RENEWAL_AML_APPLICATION',
  CIMA_MIP_RENEWAL_AML_APPLICATION_CTF_RETURN_FORM = 'CIMA_MIP_RENEWAL_AML_APPLICATION_CTF_RETURN_FORM',
  CIMA_MIP_RENEWAL_AML_APPLICATION_CTF_RETURN_RESPONSE_SUMMARY = 'CIMA_MIP_RENEWAL_AML_APPLICATION_CTF_RETURN_RESPONSE_SUMMARY',
  CIMA_MIP_RENEWAL_AML_APPLICATION_ATTESTATION = 'CIMA_MIP_RENEWAL_AML_APPLICATION_ATTESTATION',

  CIMA_MIP_RENEWAL_CHECKOUT = 'CIMA_MIP_RENEWAL_CHECKOUT',
  CIMA_MIP_RENEWAL_CHECKOUT_ATTESTATION = 'CIMA_MIP_RENEWAL_CHECKOUT_ATTESTATION',
  CIMA_MIP_RENEWAL_CHECKOUT_MIP_DETAILS = 'CIMA_MIP_RENEWAL_CHECKOUT_MIP_DETAILS',
  CIMA_MIP_RENEWAL_CHECKOUT_ADDRESS = 'CIMA_MIP_RENEWAL_CHECKOUT_ADDRESS',
  CIMA_MIP_RENEWAL_CHECKOUT_PAYMENT = 'CIMA_MIP_RENEWAL_CHECKOUT_PAYMENT',

  CIMA_ONBOARDING_PRACTICE_AREA_PREFERENCES = 'CIMA_ONBOARDING_PRACTICE_AREA_PREFERENCES',
  CIMA_ONBOARDING_INDUSTRY_PREFERENCES = 'CIMA_ONBOARDING_INDUSTRY_PREFERENCES',
  CIMA_ONBOARDING_SKILL_PREFERENCES = 'CIMA_ONBOARDING_SKILL_PREFERENCES',
  CIMA_PER_SUPERVISOR_GUIDANCE_LINK = 'CIMA_PER_SUPERVISOR_GUIDANCE_LINK',
  CIMA_PER_APPLICANT_DECLARATION = 'CIMA_PER_APPLICANT_DECLARATION',
  CIMA_PER_AUDIT_PROCESS = 'CIMA_PER_AUDIT_PROCESS',

  CIMA_EPA_APPLICANT_DECLARATION = 'CIMA_EPA_APPLICANT_DECLARATION',
  CIMA_EPA_APPLICANT_TERMS_CONDITIONS = 'CIMA_EPA_APPLICANT_TERMS_CONDITIONS',
  CIMA_SPECIAL_CONSIDERATION_POLICY = 'CIMA_SPECIAL_CONSIDERATION_POLICY',
}

const CENTER_ADMIN_ROUTES = {
  [Routes.CENTER_ADMIN_ROOT]: {
    path: '/center-membership/admin/:orgId?',
    title: `Center Membership | ${COMPANY_NAME}`,
  },
  [Routes.CENTER_ADMIN_TAB_INFO]: {
    path: '/center-membership/admin/:orgId/info',
    title: `Center Membership | Firm Information | ${COMPANY_NAME}`,
  },
  [Routes.CENTER_ADMIN_MEMBERSHIPS]: {
    path: '/center-membership/admin/:orgId/memberships',
    title: `Center Memberships | ${COMPANY_NAME}`,
  },
  [Routes.CENTER_ADMIN_MEMBERSHIPS_TAB_MANAGE]: {
    path: '/center-membership/admin/:orgId/memberships/manage',
    title: `Center Memberships | Manage Memberships | ${COMPANY_NAME}`,
  },
  [Routes.CENTER_ADMIN_MEMBERSHIPS_TAB_INVOICES]: {
    path: '/center-membership/admin/:orgId/memberships/invoices',
    title: `Center Memberships | Manage Invoices | ${COMPANY_NAME}`,
  },
  [Routes.CENTER_ADMIN_MEMBERSHIPS_SEATS_MANAGEMENT]: {
    path: '/center-membership/admin/:orgId/memberships/manage/:membershipId/seats-management',
    title: `Center Memberships | Seats Management | ${COMPANY_NAME}`,
  },
  [Routes.CENTER_ADMIN_INVOICE_PAYMENT]: {
    path: '/center-membership/admin/invoices/:invoiceId/payment',
    title: `Center Memberships | Pay Invoice`,
  },
  [Routes.CENTER_ADMIN_INVOICE_PAYMENT_ERROR]: {
    path: '/center-membership/admin/invoices/:invoiceId/payment/error',
    title: `Center Memberships | Error`,
  },
  [Routes.CENTER_ADMIN_INVOICE_PAYMENT_CONFIRMATION]: {
    path: '/center-membership/admin/invoices/:invoiceId/payment/confirmation',
    title: `Center Memberships | Confirmation`,
  },
};

const TUITION_PROVIDER_ROUTES = {
  [Routes.TUITION_PROVIDER_APPRENTICE_ROSTER]: {
    path: '/tuition-provider/apprentice-roster',
    title: `Apprentice Roster | ${COMPANY_NAME}`,
  },
  [Routes.TUITION_PROVIDER_PERSONAL_DETAILS]: {
    path: '/tuition-provider/personal-details/:studentId?/:orgId?',
    title: `Personal Details | ${COMPANY_NAME}`,
  },
  [Routes.TUITION_PROVIDER_EMPLOYER_DETAILS]: {
    path: '/tuition-provider/employer-details/:studentId?/:orgId?',
    title: `Employer Details | ${COMPANY_NAME}`,
  },
  [Routes.TUITION_PROVIDER_ROOT]: {
    path: '/tuition-provider/admin/:orgId?',
    title: `Tuition Provider | ${COMPANY_NAME}`,
  },
  [Routes.TUITION_PROVIDER_APPRENTICE_ROSTER]: {
    path: '/tuition-provider/admin/:orgId/roster/manage',
    title: `Tuition Provider | ${COMPANY_NAME}`,
  },
  [Routes.TUITION_PROVIDER_TAB_INFO]: {
    path: '/tuition-provider/admin/:orgId/info',
    title: `Tuition Provider | ${COMPANY_NAME}`,
  },
};

// cima membership routes
export const CIMA_MEMBERSHIP_ROUTES = {
  [Routes.CIMA_MEMBERSHIP_STUDYPATH]: {
    path: '/cima-membership/studypath',
  },
  [Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY]: {
    path: '/cima-membership/studypath/pathway',
  },
  [Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_PROFESSIONAL]: {
    path: '/cima-membership/studypath/professional',
  },
  [Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_EDUCATIONAL]: {
    path: '/cima-membership/studypath/educational',
  },
  [Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_WORK]: {
    path: '/cima-membership/studypath/work',
  },
  [Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS]: {
    path: '/cima-membership/studypath/exemptions',
  },
  [Routes.CIMA_MEMBERSHIP_PACKAGE]: {
    path: '/cima-membership/package',
  },
  [Routes.CIMA_MEMBERSHIP_APPLICATION_FORM]: {
    path: '/cima-membership/application',
  },
  [Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_START]: {
    path: '/cima-membership/application/start',
    title: `CIMA Membership - Start | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL]: {
    path: '/cima-membership/application/personal',
    title: `CIMA Membership - Personal | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_CPA]: {
    path: '/cima-membership/application/cpa',
    title: `CIMA Membership - CPA | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_EMPLOYMENT]: {
    path: '/cima-membership/application/employment',
    title: `CIMA Membership - Employment | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_TRAINING]: {
    path: '/cima-membership/application/training',
    title: `CIMA Membership - Training | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DOCUMENT]: {
    path: '/cima-membership/application/document',
    title: `CIMA Membership - Document | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_GATEWAY_DOCUMENT]: {
    path: '/cima-membership/application/document-gateway',
    title: `CIMA Membership - Document Gateway | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS]: {
    path: '/cima-membership/application/donations',
    title: `CIMA Membership - Donations | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION]: {
    path: '/cima-membership/application/attestation',
    title: `CIMA Membership - Attestation | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_DOCUMENT_FILE_UPLOAD]: {
    path: '/cima-membership/application/documentupload',
    title: `CIMA Membership - Document Upload | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_PROMO_CODE]: {
    path: '/cima-membership/promotion',
    title: `CIMA Membership - Promotion Code`,
  },
  [Routes.CIMA_MEMBERSHIP_EXAM_FILE_UPLOAD]: {
    path: '/cima-dashboard/EPA2/:assessmentTypeId/file-upload/:fileId',
    title: `EPA Level 7 Project Report | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_MEMBERSHIP_REGIONAL_PATHWAY]: {
    path: '/resources/landing/regional-pathway-cima',
    title: `CIMA Membership - Regional Pathway | ${COMPANY_NAME}`,
  },
};

export const EXEMPTION_CALCULATOR_ROUTES = {
  [Routes.EXEMPTION_CALCULATOR_ROOT]: {
    path: '/exemption-calculator',
  },
  [Routes.EXEMPTION_CALCULATOR_PROFESSIONAL]: {
    path: '/exemption-calculator/professional',
  },
  [Routes.EXEMPTION_CALCULATOR_WORK]: {
    path: '/exemption-calculator/work',
  },
  [Routes.EXEMPTION_CALCULATOR_EDUCATIONAL]: {
    path: '/exemption-calculator/educational',
  },
  [Routes.EXEMPTION_CALCULATOR_SUMMARY]: {
    path: '/exemption-calculator/summary',
  },
  [Routes.EXEMPTION_CALCULATOR_RESULT]: {
    path: '/exemption-calculator/result',
  },
};

const CENTER_MEMBERSHIP_ROUTES = {
  [Routes.CENTER_ADMIN_TAB_INFO]: {
    path: '/center-membership/admin/:orgId/info',
    title: `Center Membership | Firm Information | ${COMPANY_NAME}`,
  },
  [Routes.CENTER_MEMBERSHIP_FORM]: {
    path: '/center-membership/package',
    useAnalytics: false,
  },
  [Routes.CENTER_MEMBERSHIP_APPLICATION_FORM]: {
    path: '/center-membership/application',
  },
  [Routes.CENTER_MEMBERSHIP_FORM_START]: {
    path: '/center-membership/application/start',
    useAnalytics: false,
  },
  [Routes.CENTER_MEMBERSHIP_FORM_ADMIN]: {
    path: '/center-membership/application/admin',
  },
  [Routes.CENTER_MEMBERSHIP_FORM_FIRM]: {
    path: '/center-membership/application/firm',
  },
  [Routes.CENTER_MEMBERSHIP_FORM_ATTESTATION]: {
    path: '/center-membership/application/attestation',
  },
  [Routes.CENTER_MEMBERSHIP_RENEWAL]: {
    path: '/center-membership/renewal/admin/:centerMembershipId/organization/:orgId',
  },
  [Routes.CENTER_MEMBERSHIP_PROVISIONING]: {
    path: '/center-membership/provisioning/:accessToken',
  },
};

// ANYTHING IN THIS ARRAY GETS FORCED TO HOMEPAGE IF THE USER LOGS IN AGAIN HERE.
export const MEMBERSHIP_APPLICATION_ROUTES = {
  [Routes.APPLICATION_FORM]: {
    path: '/membership/application',
    useAnalytics: false,
  },
  [Routes.APPLICATION_FORM_START]: {
    path: '/membership/application/start',
    title: `Membership - Start | ${COMPANY_NAME}`,
    useAnalytics: false,
  },
  [Routes.APPLICATION_FORM_PERSONAL]: {
    path: '/membership/application/personal',
    title: `Membership - Personal | ${COMPANY_NAME}`,
  },
  [Routes.APPLICATION_FORM_QUALIFICATION]: {
    path: '/membership/application/qualification',
    title: `Membership - Qualification | ${COMPANY_NAME}`,
  },
  [Routes.APPLICATION_FORM_EMPLOYMENT]: {
    path: '/membership/application/employment',
    title: `Membership - Employment | ${COMPANY_NAME}`,
  },
  [Routes.INVITE_THANKYOU_PAGE]: {
    path: '/membership/application/thankyou',
  },
  [Routes.APPLICATION_FORM_DONATIONS]: {
    path: '/membership/application/donations',
    title: `Membership - Donations | ${COMPANY_NAME}`,
  },
  [Routes.APPLICATION_FORM_ATTESTATION]: {
    path: '/membership/application/attestation',
    title: `Membership - Attestation | ${COMPANY_NAME}`,
  },
};

export const MEMBERSHIP_ROUTES = {
  [Routes.PRE_SIGN_UP]: {
    path: '/membership/pre-sign-up',
  },
  [Routes.MEMBERSHIP_FORM]: {
    path: '/membership/package',
    useAnalytics: false,
  },
  [Routes.MEMBERSHIP_BENEFIT]: {
    path: `/membership/benefits/:productSku`,
  },
  [Routes.MEMBER_CREDENTIAL_DIRECTORY]: {
    path: '/directories',
  },
  [Routes.MEMBER_CREDENTIAL_DIRECTORY_RESULTS]: {
    path: '/directories/results/:slug',
  },
  [Routes.CREDENTIALS_APPLICATION_FORM]: {
    path: '/credentials/application',
  },
  [Routes.CONFIRMATION_BENEFIT]: {
    path: `/membership/confirmation/benefits`,
  },
  [Routes.MEMBERSHIP_OVERVIEW]: {
    path: `/membership/landing/membership-overview`,
  },
  ...MEMBERSHIP_APPLICATION_ROUTES,
};

// ANYTHING IN THIS ARRAY GETS FORCED TO HOMEPAGE IF THE USER LOGS IN AGAIN HERE.
export const FCMA_CREDENTIAL_APPLICATION_ROUTES = {
  [Routes.FCMA_CREDENTIAL_APPLICATION_FORM_PERSONAL]: {
    path: '/fcma-application/personal',
    title: 'Membership - Personal',
  },
  [Routes.FCMA_CREDENTIAL_APPLICATION_FORM_EMPLOYMENT]: {
    path: '/fcma-application/employment',
    title: 'Membership - Employment',
  },
  [Routes.FCMA_CREDENTIAL_APPLICATION_FORM_DONATIONS]: {
    path: '/fcma-application/donations',
    title: 'Membership - Donations',
  },
  [Routes.FCMA_CREDENTIAL_APPLICATION_FORM_ATTESTATION]: {
    path: '/fcma-application/attestation',
    title: 'Membership - Attestation',
  },
};

export const PRACTICAL_EXPERIENCE_REQUIREMENT_ROUTES = {
  [Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT]: {
    path: '/practical-experience-requirement',
    title: `PER Overview | ${COMPANY_NAME}`,
  },
  [Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT]: {
    path: '/practical-experience-requirement/employment/:perId?',
    title: `PER - Employment | ${COMPANY_NAME}`,
  },
  [Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES]: {
    path: '/practical-experience-requirement/core-activities/:perId?',
    title: `PER - Core Work Activities | ${COMPANY_NAME}`,
  },
  [Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_SKILLS_BEHAVIORS]: {
    path: '/practical-experience-requirement/skills-behaviors/:perId?',
    title: `PER - Skills & Behaviors | ${COMPANY_NAME}`,
  },
  [Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_SUPERVISOR]: {
    path: '/practical-experience-requirement/supervisor',
    title: `PER - Supervisor Overview | ${COMPANY_NAME}`,
  },
};

// cima dashboard routes
const CIMA_EXAMS_DASHBOARD_ROUTES = {
  [Routes.CIMA_EXAMS_DASHBOARD_ROOT]: {
    path: '/cima-dashboard',
    title: `Exams Dashboard | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_EXAM_BOOK_PERSONAL_DETAILS]: {
    path: '/exam/personal-details',
    title: `Exam Book - Personal Details | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_EXAM_BOOK_TERMS_CONDITIONS]: {
    path: '/exam/terms-and-conditions',
    title: `Exam Book - Terms & Conditions | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_EXAM_RESULTS]: {
    path: '/cima-dashboard/results/:slug',
    title: `Exam Results | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_ALL_EXAM_RESULTS]: {
    path: '/cima-dashboard/all-results',
    title: `Exam Results | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_DASHBOARD_LEARN_MORE]: {
    path: '/cima-dashboard/:pathway/:level',
    title: 'Qualification Level - Learn More',
  },
  [Routes.CIMA_EXAMS_DASHBOARD_EPA2L4]: {
    path: '/cima-dashboard/EPA2/L4',
    title: `Level 4 EPA2 | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_EXAMS_DASHBOARD_EPA2L7]: {
    path: '/cima-dashboard/EPA2/L7',
    title: `EPA Level 7 Project Report | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_EXAM_HISTORY]: {
    path: '/cima-dashboard/all-results',
    title: `Exam History | ${COMPANY_NAME}`,
  },
};

// cima exam exemption routes
const CIMA_EXAMS_EXEMPTION_ROUTES = {
  [Routes.CIMA_EXAMS_EXEMPTIONS_FILE_UPLOAD]: {
    path: '/cima-exam-exemptions/file-upload',
    title: `Educational Gateway Exemptions`,
  },
  [Routes.CIMA_EXAMS_EXEMPTIONS_VIEW_FILES]: {
    path: '/cima-exam-exemptions/view-files',
    title: `Educational Gateway Exemptions`,
  },
};

// cima exam gateway routes
const CIMA_EXAMS_GATEWAY_ROUTES = {
  [Routes.CIMA_EXAMS_GATEWAY_FILE_UPLOAD]: {
    path: '/cima-exam-gateway/file-upload',
    title: `Apprentice Requirements`,
  },
  [Routes.CIMA_EXAMS_GATEWAY_VIEW_FILES]: {
    path: '/cima-exam-gateway/view-files',
    title: `Apprentice Requirements`,
  },
};

const CIMA_POST_EXAMS_ROUTES = {
  [Routes.CIMA_POST_EXAMS_SPECIAL_CONSIDERATIONS]: {
    path: '/support/special-considerations',
    title: `CIMA Post-Exams Special Considerations`,
  },
};

const CIMA_FCMA_CREDENTIAL_ROUTE = {
  [Routes.CIMA_FCMA_CREDENTIAL_ROUTE]: {
    path: `/cpe-learning/credential/${Product.CredentialProductSlug.FCMA}`,
    title: `CIMA FCMA Credential`,
  },
};

const CIMA_ACMA_CREDENTIAL_ROUTE = {
  [Routes.CIMA_ACMA_CREDENTIAL_ROUTE]: {
    path: '/cpe-learning/credential/cima-acma',
    title: `CIMA ACMA Credential`,
  },
};

// cima mip application routes
const CIMA_MIP_APPLICATION_ROUTES = {
  [Routes.CIMA_MIP_ROOT]: {
    path: '/cima-mip',
    title: `CIMA MiP Page`,
  },
  [Routes.CIMA_MIP_APPLICATION_MAIN_PAGE]: {
    path: '/cima-mip/main-application',
    title: `MiP Application Page`,
  },
  [Routes.CIMA_MIP_CONTACT_APPLICATION_PAGE]: {
    path: '/cima-mip/contact-application',
    title: `MiP Contact Application Page`,
  },
  [Routes.CIMA_MIP_WORK_HISTORY_APPLICATION_PAGE]: {
    path: '/cima-mip/work-history-application',
    title: `MiP Work History Application Page`,
  },
  [Routes.CIMA_MIP_PRACTICE_REQUIREMENTS_APPLICATION_PAGE]: {
    path: '/cima-mip/practice-requirements-application',
    title: `MiP Practice Requirements Application Page`,
  },

  // cima aml application
  [Routes.CIMA_MIP_AML_APPLICATION]: {
    path: '/cima-mip/aml-application',
    title: `MiP AML Application`,
  },
  [Routes.CIMA_MIP_AML_APPLICATION_CTF_RETURN_FORM]: {
    path: '/cima-mip/aml-application/aml-ctf-return-form',
    title: `MiP AML application - AML / CTF Return Form`,
  },
  [Routes.CIMA_MIP_AML_APPLICATION_CTF_RETURN_RESPONSE_SUMMARY]: {
    path: '/cima-mip/aml-application/aml-ctf-annual-return-response-summary',
    title: `MiP AML application - AML / CTF Annual Return responses summary`,
  },
  [Routes.CIMA_MIP_AML_APPLICATION_ATTESTATION]: {
    path: '/cima-mip/aml-application/aml-attestation',
    title: `MiP AML application - Attestation`,
  },

  // cima mip checkout routes
  [Routes.CIMA_MIP_CHECKOUT]: {
    path: '/cima-mip/mip-checkout/',
    title: `MiP Checkout`,
  },

  [Routes.CIMA_MIP_CHECKOUT_ATTESTATION]: {
    path: '/cima-mip/mip-checkout/attestation',
    title: `MiP Checkout - Attestation`,
  },

  [Routes.CIMA_MIP_CHECKOUT_MIP_DETAILS]: {
    path: '/cima-mip/mip-checkout/member-in-practice-details',
    title: `MiP Checkout - Member in practice details`,
  },

  [Routes.CIMA_MIP_CHECKOUT_ADDRESS]: {
    path: '/cima-mip/mip-checkout/address',
    title: `MiP Checkout - Address`,
  },

  [Routes.CIMA_MIP_CHECKOUT_PAYMENT]: {
    path: '/cima-mip/mip-checkout/payment',
    title: `MiP Checkout - Payment`,
  },
};

// cima mip application routes
const CIMA_MIP_RENEWAL_APPLICATION_ROUTES = {
  [Routes.CIMA_MIP_RENEWAL_ROOT]: {
    path: '/cima-mip-renewal',
    title: `CIMA MIP Renewal Page`,
  },
  [Routes.CIMA_MIP_RENEWAL_APPLICATION_MAIN_PAGE]: {
    path: '/cima-mip-renewal/main-application',
    title: `Renewal Application Page`,
  },
  [Routes.CIMA_MIP_RENEWAL_CONTACT_APPLICATION_PAGE]: {
    path: '/cima-mip-renewal/contact-application',
    title: `Renewal Contact Application Page`,
  },
  [Routes.CIMA_MIP_RENEWAL_PRACTICE_REQUIREMENTS_APPLICATION_PAGE]: {
    path: '/cima-mip-renewal/practice-requirements-application',
    title: `Renewal Practice Requirements Application Page`,
  },

  // cima aml application
  [Routes.CIMA_MIP_RENEWAL_AML_APPLICATION]: {
    path: '/cima-mip-renewal/aml-application',
    title: `Renewal AML Application`,
  },
  [Routes.CIMA_MIP_RENEWAL_AML_APPLICATION_CTF_RETURN_FORM]: {
    path: '/cima-mip-renewal/aml-application/aml-ctf-return-form',
    title: `Renewal AML application - AML / CTF Return Form`,
  },
  [Routes.CIMA_MIP_RENEWAL_AML_APPLICATION_CTF_RETURN_RESPONSE_SUMMARY]: {
    path: '/cima-mip-renewal/aml-application/aml-ctf-annual-return-response-summary',
    title: `Renewal AML application - AML / CTF Annual Return responses summary`,
  },
  [Routes.CIMA_MIP_RENEWAL_AML_APPLICATION_ATTESTATION]: {
    path: '/cima-mip-renewal/aml-application/aml-attestation',
    title: `Renewal AML application - Attestation`,
  },

  // cima mip checkout routes
  [Routes.CIMA_MIP_RENEWAL_CHECKOUT]: {
    path: '/cima-mip-renewal/mip-checkout/',
    title: `Renewal Checkout`,
  },

  [Routes.CIMA_MIP_RENEWAL_CHECKOUT_ATTESTATION]: {
    path: '/cima-mip-renewal/mip-checkout/attestation',
    title: `Renewal Checkout - Attestation`,
  },

  [Routes.CIMA_MIP_RENEWAL_CHECKOUT_MIP_DETAILS]: {
    path: '/cima-mip-renewal/mip-checkout/member-in-practice-details',
    title: `Renewal Checkout - Member in practice details`,
  },

  [Routes.CIMA_MIP_RENEWAL_CHECKOUT_ADDRESS]: {
    path: '/cima-mip-renewal/mip-checkout/address',
    title: `Renewal Checkout - Address`,
  },

  [Routes.CIMA_MIP_RENEWAL_CHECKOUT_PAYMENT]: {
    path: '/cima-mip-renewal/mip-checkout/payment',
    title: `Renewal Checkout - Payment`,
  },
};

const CIMA_REVIEWER_ROUTES = {
  [Routes.CIMA_REVIEWER_ROOT]: {
    path: `/reviewer/dashboard/:token?`,
    title: `Supervisor Overview | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_REVIEWER_DETAILS]: {
    path: `/reviewer/your-details/:token?`,
    title: `Supervisor - Your Details | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_REVIEWER_EXPIRED]: {
    path: `/reviewer/error`,
    title: `Supervisor Link Expired | ${COMPANY_NAME}`,
  },
  [Routes.PRACTICAL_EXPERIENCE_REQUIREMENT]: {
    path: `/reviewer/practical-experience-requirement/:caseNumber/:token`,
    title: `Supervisor - Practical Experience Requirement | ${COMPANY_NAME}`,
  },
  [Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT_REVIEW]: {
    path: `/reviewer/employment/:caseNumber/:id/:token`,
    title: `Supervisor - PER - Employment | ${COMPANY_NAME}`,
  },
  [Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES_REVIEW]: {
    path: `/reviewer/core-activities/:caseNumber/:id/:token`,
    title: `Supervisor - PER - Core Activities | ${COMPANY_NAME}`,
  },
  [Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_SKILL_BEHAVIOUR_REVIEW]: {
    path: `/reviewer/skill-behaviour/:caseNumber/:id/:token`,
    title: `Supervisor - PER - Skill Behaviour | ${COMPANY_NAME}`,
  },
  [Routes.END_POINT_ASSESSMENT]: {
    path: `/reviewer/end-point-assessment/:caseNumber/:token`,
    title: `Supervisor - EPA | ${COMPANY_NAME}`,
  },
};

export enum MembershipPackageAccordionStatus {
  Type = 'type',
  Tier = 'tier',
  Credentials = 'credentials',
  Sections = 'sections',
  Addons = 'addons',
  // CENTER MEMBERSHIP
  Organization = 'organization',
  CPA = 'cpas',
  // cima membership,
  Pathway = 'pathway',
  Level = 'level',
  Skill = 'skill',
  Summary = 'summary',
  // Additional Accordion For FCMA Renewal
  Designation = 'designation',
}

const ACCOUNT_ROUTES = {
  [Routes.PROFILE_ROOT]: {
    path: '/account',
    title: `My Profile | ${COMPANY_NAME}`,
  },
  [Routes.PROFILE_PERSONAL_DETAILS]: {
    path: '/account/personal-details',
    title: `Personal Details | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.PROFILE_PREFERENCES]: {
    path: '/profile/topic-preferences',
    title: `Preferences | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.PROFILE_PURCHASES]: {
    path: '/profile/purchases/:type',
    title: `Purchases | ${COMPANY_NAME}`,
  },
  [Routes.PROFILE_WALLET]: {
    path: '/account/wallet',
    title: `Wallet | ${COMPANY_NAME}`,
  },
  [Routes.PROFILE_RECEIPTS]: {
    path: '/account/receipts',
    title: `Receipts | ${COMPANY_NAME}`,
  },
  [Routes.PROFILE_RECEIPT_CHECKOUT]: {
    path: '/account/receipts/:invoiceId/checkout/:legalEntity?',
    title: `Checkout | ${COMPANY_NAME}`,
  },
  [Routes.PROFILE_RECEIPT_PAYMENT_CONFIRMATION]: {
    path: '/account/receipts/:invoiceId/confirmation',
    title: `Checkout | ${COMPANY_NAME}`,
  },
  [Routes.PROFILE_CONTACT_PREFERENCES]: {
    path: '/account/contact-preferences',
    title: `Contact Preferences | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.PROFILE_SAVED_ITEMS]: {
    path: '/account/saved',
    title: `Saved Items | ${COMPANY_NAME}`,
  },
  [Routes.ORG_ROOT]: {
    path: '/account/organization',
    title: `Organization | ${COMPANY_NAME}`,
  },
  [Routes.ORG_FIRM_INFORMATION]: {
    path: '/account/organization/firm-information/:orgId',
    title: `Firm Information | ${COMPANY_NAME}`,
  },
  [Routes.ORG_SEAT_MANAGEMENT]: {
    path: '/account/organization/assign',
    title: `Seat Management | ${COMPANY_NAME}`,
  },
  [Routes.ORG_INVOICE_CHECKOUT_PAYPAL_PROCEED]: {
    path: '/account/organization/invoice/:invoiceId/checkout/paypal/:proceed',
    title: `Checkout Paypal - Proceed | ${COMPANY_NAME}`,
  },
  [Routes.ORG_INVOICES]: {
    path: '/account/organization/invoices',
    title: `Invoices | ${COMPANY_NAME}`,
  },
  [Routes.ORG_INVOICE_CHECKOUT]: {
    path: '/account/organization/invoice/:invoiceId/checkout',
    title: `Checkout | ${COMPANY_NAME}`,
  },
  [Routes.ORG_INVOICE_PAYMENT_CONFIRMATION]: {
    path: '/account/organization/invoice/:invoiceId/confirmation',
    title: `Confirmation | ${COMPANY_NAME}`,
  },
  [Routes.ORG_INVOICES_PAYMENT_CONFIRMATION]: {
    path: '/account/organization/invoices/confirmation',
    title: `Confirmation | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_ROOT]: {
    path: '/account/firm/:orgId?',
    title: (values: PageTitleValues): string => `${getFirmNaming(values?.useCimaFeatures)} Rosters | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_INFO]: {
    path: '/account/firm/:orgId/info',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Information | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_INFO_TAB_BASIC_COMPANY_DETAILS]: {
    path: '/account/firm/:orgId/info/basic-company-details',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Information | Basic Company Details | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_ROSTER]: {
    path: '/account/firm/:orgId/roster',
    title: (values: PageTitleValues): string => `${getFirmNaming(values?.useCimaFeatures)} Rosters | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_ROSTER_TAB_MANAGE]: {
    path: '/account/firm/:orgId/roster/manage',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Rosters | Manage ${getFirmNaming(
        values?.useCimaFeatures
      )} Roster  ${COMPANY_NAME}`,
  },
  [Routes.FIRM_ROSTER_TAB_MEMBERS]: {
    path: '/account/firm/:orgId/roster/members',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Rosters | Add New Members | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_ROSTER_TAB_SUPPORT]: {
    path: '/account/firm/:orgId/roster/support',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Rosters | ${getFirmNaming(
        values?.useCimaFeatures
      )} Roster Support | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_ROSTER_TAB_FLP_SUBSCRIPTIONS]: {
    path: '/account/firm/:orgId/roster/flp-subscriptions',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Rosters | Add new FLP subscription | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_BILLING]: {
    path: '/account/firm/:orgId/billing',
    title: (values: PageTitleValues): string => `${getFirmNaming(values?.useCimaFeatures)} Billing | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_BILLING_TAB_MANAGE]: {
    path: '/account/firm/:orgId/billing/manage',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Billing | Manage ${getFirmNaming(
        values?.useCimaFeatures
      )} Billing | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_BILLING_TAB_MEMBERSHIPS]: {
    path: '/account/firm/:orgId/billing/memberships',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Billing | Memberships And Add-on | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_BILLING_TAB_FLP_ORGANIZATION]: {
    path: '/account/firm/:orgId/billing/flpOrganization',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Billing | FLP Organization Billing | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_BILLING_TAB_INVOICES]: {
    path: '/account/firm/:orgId/billing/invoices',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Billing | View And Pay Invoices | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_BILLING_INVOICE_CHECKOUT]: {
    path: '/account/firm/:orgId/checkout/:invoiceId',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Billing | Invoice Checkout | ${COMPANY_NAME}`,
  },

  [Routes.FIRM_BILLING_INVOICE_CHECKOUT_PAYPAL_PROCEED]: {
    path: '/account/firm/:orgId/checkout/:invoiceId/paypal/:proceed',
    title: `Checkout Firm admin Paypal - Proceed | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_BILLING_INVOICE_CONFIRMATION]: {
    path: '/account/firm/:orgId/confirmation/:invoiceId',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Billing | Confirmation | ${COMPANY_NAME}`,
  },
  [Routes.FIRM_BILLING_TAB_SUPPORT]: {
    path: '/account/firm/:orgId/billing/support',
    title: (values: PageTitleValues): string =>
      `${getFirmNaming(values?.useCimaFeatures)} Billing | ${getFirmNaming(
        values?.useCimaFeatures
      )} Billing Support | ${COMPANY_NAME}`,
  },
};

const PROFILE_ROTUES = {
  [Routes.MY_PROFILE_ROOT]: {
    path: '/profile',
    title: `My Profile | ${COMPANY_NAME}`,
  },
  [Routes.MY_PERSONAL_DETAILS]: {
    path: '/profile/personal',
    title: `My Personal Details | ${COMPANY_NAME}`,
  },
  [Routes.MY_CONTACT_DETAILS]: {
    path: '/profile/contact',
    title: `My Contact Details | ${COMPANY_NAME}`,
  },
  [Routes.MY_EMPLOYMENT_DETAILS]: {
    path: '/profile/employment',
    title: `My Employment Details | ${COMPANY_NAME}`,
  },
  [Routes.MY_TUITION_PROVIDER]: {
    path: '/profile/tuition',
    title: `My Tuition Provider | ${COMPANY_NAME}`,
  },
  [Routes.PROFILE_LOGIN_SETTINGS]: {
    path: '/profile/login-settings',
    title: `Login Settings | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.PROFILE_SECURITY_QUESTION]: {
    path: '/profile/security-question',
    title: `Security Question | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.MY_TOPIC_PREFERENCES]: {
    path: '/profile/topic-preferences',
    title: `My Topic Preferences | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.MY_PRACTICE_AREAS]: {
    path: '/profile/topic-preferences',
    title: `My Practice Areas | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.MY_INDUSTRIES]: {
    path: '/profile/industry-preferences',
    title: `My Industries | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.MY_TRENDS]: {
    path: '/profile/trend-preferences',
    title: `My Trends | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.MY_SKILLS]: {
    path: '/profile/skill-preferences',
    title: `My Skills | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.MY_CONSENTS]: {
    path: '/profile/consents',
    title: `My Consents | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.MY_MARKETING_PREFERENCES]: {
    path: '/profile/marketing-preferences',
    title: `My Marketing Preferences | ${COMPANY_NAME}`,
  },
  [Routes.MY_PAYMENT_METHODS]: {
    path: '/profile/payment-methods',
    title: `My Payment Methods | ${COMPANY_NAME}`,
  },
  [Routes.MY_RECEIPTS]: {
    path: '/profile/transactions',
    title: `My Transactions | ${COMPANY_NAME}`,
  },
  [Routes.MY_PURCHASES]: {
    path: '/profile/purchases/:type?',
  },
  [Routes.MY_CREDENTIALS]: {
    path: '/profile/purchases/credentials',
  },
  [Routes.MY_MEMBERSHIPS]: {
    path: '/profile/purchases/memberships',
  },
  [Routes.MY_SECTIONS]: {
    path: '/profile/purchases/sections',
  },
};

export const fullRoutes: {
  [key: string]: Route;
} = {
  [Routes.ROOT]: {
    public: true,
    path: '/',
    title: `AICPA & CIMA | ${COMPANY_NAME}`,
    useAnalytics: false,
  },
  [Routes.HOME]: {
    public: true,
    path: '/home/:slug?',
    title: `AICPA & CIMA | ${COMPANY_NAME}`,
  },
  [Routes.CAMPAIGNS]: {
    public: true,
    path: '/campaigns/:slug?/:productSlug?',
    title: `${COMPANY_NAME} | Home`,
  },
  [Routes.STATIC_LANDING_PAGES]: {
    public: true,
    path: `/:category(${categoryListStr})/landing/:slug`,
    title: (values: PageTitleValues): string =>
      `${values && values.title ? values.title : ''} | ${
        values && values.category ? values.category : ''
      } | ${COMPANY_NAME}`,
    useAnalytics: false,
  },
  [Routes.FEED]: {
    path: '/feed',
    title: `My ${COMPANY_NAME} feed`,
  },
  [Routes.LOGIN]: {
    path: '/login',
    title: `Login | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.FORGOT_EMAIL]: {
    path: '/forgot-email',
    title: `Forgot email | ${COMPANY_NAME}`,
  },
  [Routes.PREFERENCES]: {
    path: '/preferences',
    title: `Select topics | ${COMPANY_NAME}`,
  },
  [Routes.PROFILE_RECEIPT_CHECKOUT_ERROR]: {
    path: '/error/receipts/:invoiceId',
    title: `Error | ${COMPANY_NAME}`,
  },
  [Routes.ORG_INVOICE_ERROR]: {
    path: '/error/invoice/:invoiceId',
    title: `Checkout Error | ${COMPANY_NAME}`,
  },
  [Routes.ABOUT]: {
    path: '/about/landing/about',
  },
  [Routes.PRIVACY_CALIFORNIA_FORM]: {
    path: '/about/california-form',
  },
  [Routes.PRIVACY_GDPR]: {
    path: '/about/gdpr-form',
    title: `Privacy Policy | ${COMPANY_NAME}`,
  },
  [Routes.HELP]: {
    path: '/help',
    title: `Help | ${COMPANY_NAME}`,
  },
  [Routes.SITE_MAP]: {
    path: '/help/sitemap',
    title: `Sitemap | ${COMPANY_NAME}`,
  },
  [Routes.CONTACT_US]: {
    path: '/resources/landing/contact-us',
    title: `Contact Us | ${COMPANY_NAME}`,
  },
  [Routes.CONTACT_US_AICPA]: {
    path: '/help/contact-aicpa',
    title: `Contact Us | ${COMPANY_NAME}`,
  },
  [Routes.CONTACT_US_CIMA]: {
    path: '/help/contact-cima',
    title: `Contact Us | ${COMPANY_NAME}`,
  },
  [Routes.PRIVACY_POLICY]: {
    path: '/help/privacy-policy',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.TERMS_CONDITIONS]: {
    path: '/help/terms-and-conditions',
    title: `Terms & Conditions | ${COMPANY_NAME}`,
  },
  [Routes.TERMS_CONDITIONS_SUBSCRIPTIONS]: {
    path: '/help/what-to-expect-from-subscriptions',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.TERMS_CONDITIONS_STANDING_ORDERS]: {
    path: '/help/standing-orders',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.TERMS_CONDITIONS_CONFERENCE]: {
    path: '/help/aicpa-and-cima-conferences-terms-and-conditions',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.CONFERENCE_COVID_WAIVER]: {
    path: '/help/covid-19-assumption-of-risk-waiver-and-release',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.CONFERENCE_SAFETY_PROTOCOLS]: {
    path: '/help/aicpa-and-cima-safety-protocols',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.WEBSITE_ACCESSIBILITY]: {
    path: '/help/website-accessibility',
    title: `Accessibility | ${COMPANY_NAME}`,
  },
  [Routes.WEBCAST_DETAILS]: {
    path: '/help/webcast-details',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.PUBLICATION_DETAILS]: {
    path: '/help/publication-details',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.CONFERENCE_DETAILS]: {
    path: '/help/online-conference-details',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.EXAM_DETAILS]: {
    path: '/help/kryterion-exam-details',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.COURSE_DETAILS]: {
    path: '/help/course-details',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.SUBSCRIPTION_DETAILS]: {
    path: '/help/subscription-details',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.WHAT_TO_EXPECT_FROM_SUBSCRIPTIONS]: {
    path: '/help/what-to-expect-from-subscriptions',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.REFUND_POLICY]: {
    path: '/help/refund-policy',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.CONFERENCE_SYSTEM_REQUIREMENTS]: {
    path: '/help/conference-system-requirements',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.CONFERENCE_CANCELLATION_POLICY]: {
    path: '/help/conference-cancellation-policy',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.DIGITAL_BADGES]: {
    path: '/help/digital-badges',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.STANDING_ORDERS]: {
    path: '/help/standing-orders',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.SHIPPING_DELIVERY]: {
    path: '/help/shipping-delivery',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.VERGIC_CALENDAR]: {
    path: `/help/appointments`,
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.NASBA_SPONSORSHIP]: {
    path: `/help/important-notice-regarding-cpe-credit`,
  },
  [Routes.SEARCH_HASH]: {
    path: '#search',
  },
  [Routes.DOUBLE_DONATION]: {
    path: '/aicpa/double-donation',
  },
  [Routes.BLANK_HASH]: {
    path: '#',
  },
  [Routes.EXIT_SURVEY_HASH]: {
    path: '#beforeyouleave',
    useAnalytics: false, // changing this wont actually help. Hash based paths are excluded from analytics
  },
  [Routes.REGISTRATION_HASH]: {
    path: '#registration',
    useAnalytics: false, // changing this wont actually help. Hash based paths are excluded from analytics
  },
  [Routes.LOGIN_HASH]: {
    path: '#login',
    useAnalytics: false, // changing this wont actually help. Hash based paths are excluded from analytics
  },
  [Routes.TELLUS_SURVEY_HASH]: {
    path: '#tellusmore',
    useAnalytics: false, // changing this wont actually help. Hash based paths are excluded from analytics
  },
  [Routes.NOT_FOUND]: {
    public: true,
    path: '/404',
  },
  [Routes.FEATURE_TOGGLES]: {
    path: '/feature-toggles',
  },
  [Routes.SEARCH]: {
    public: true,
    path: '/search/:searchQuery?',
    title: `Search | ${COMPANY_NAME}`,
  },
  [Routes.SHARE_ALLOCATION]: {
    path: '/share/:productSlug/:token',
  },
  [Routes.TOPIC_AGGS_PAGE]: {
    public: true,
    path: '/topic/:topicSlug/:subtopicSlug?',
  },
  [Routes.INDUSTRY_AGGS_PAGE]: {
    public: true,
    path: '/industry/:industrySlug/',
  },
  [Routes.TREND_AGGS_PAGE]: {
    public: true,
    path: '/trend/:trendSlug/',
  },
  [Routes.SKILL_AGGS_PAGE]: {
    public: true,
    path: '/skill/:skillSlug/:subskillSlug?',
  },
  [Routes.TOOLKIT_PAGE]: {
    public: true,
    path: `/:category(${categoryListStr})/toolkit/:slug`,
    useAnalytics: false,
  },
  [Routes.STOREFRONT_PAGE]: {
    public: true,
    path: `/${Content.CategorySlugs.CPE_LEARNING}`,
    title: `AICPA & CIMA Learning Products | ${COMPANY_NAME}`,
    useAnalytics: false,
  },
  [Routes.PRODUCT_PAGE]: {
    public: true,
    path: `/${Content.CategorySlugs.CPE_LEARNING}/:productTypeSlug/:slug`,
    title: (values: PageTitleValues): string => {
      return `${values?.title || ''} | ${values?.format ? `${values.format}s` : ''} | ${COMPANY_NAME}`;
    },
    useAnalytics: false,
  },
  [Routes.PRODUCT_AGGS_BY_TOPIC_PAGE]: {
    public: true,
    path: `/${Content.CategorySlugs.CPE_LEARNING}/:topicSlug`,
    useAnalytics: false,
  },
  [Routes.PRODUCT_AGGS_BY_TYPE_PAGE]: {
    public: true,
    path: `/${Content.CategorySlugs.CPE_LEARNING}/:productTypeSlug`,
    useAnalytics: false,
  },
  [Routes.PRODUCT_AGGS_CALENDAR]: {
    public: true,
    path: `/${Content.CategorySlugs.CPE_LEARNING}/${Product.ProductSlug.CALENDAR}`,
  },
  [Routes.PRODUCT_PAGE_OLD]: {
    public: true,
    path: `/${Content.CategorySlugs.CPE_LEARNING}/:slug`,
    title: (values: PageTitleValues): string => {
      return `${values && values.title ? values.title : ''} | ${
        values && values.format ? values.format : ''
      } | ${COMPANY_NAME}`;
    },
    useAnalytics: false,
  },
  [Routes.CART_PAGE]: {
    path: '/account/cart',
    title: `My Cart | ${COMPANY_NAME}`,
    useAnalytics: false,
  },
  [Routes.UNFOLLOW_UNSUCCESSFUL_HASH]: {
    path: '#unfollowunsuccessful',
  },
  [Routes.PAYPAL_PAYMENT_CHECKOUT_PROCEED]: {
    path: '/paypal/checkout/:proceed?',
    title: `Paypal Checkout - Payment`,
  },
  [Routes.PAYPAL_PAYMENT_CHECKOUT_CANCELLED]: {
    path: '/paypal/checkout/:cancelled?',
    title: `Paypal Checkout - Payment`,
  },
  [Routes.CHECKOUT_PAGE]: {
    path: '/checkout/:orderNumber?',
    title: `Checkout | ${COMPANY_NAME}`,
    useAnalytics: false,
  },
  [Routes.PAGE_PAYMENT]: {
    path: '/checkout',
    title: `Payment | ${COMPANY_NAME}`,
  },
  [Routes.EVENT_REGISTRATION]: {
    path: '/event-registration/:orderNumber?',
    title: `Event Registration | ${COMPANY_NAME}`,
  },
  [Routes.ORDER_ERROR_PAGE]: {
    path: '/error/:cartId?',
    title: `Order Error | ${COMPANY_NAME}`,
  },
  [Routes.PRODUCT_ACCESS_ERROR_PAGE]: {
    path: '/account/access-error',
  },
  [Routes.CONTENT_PAGE_OLD]: {
    public: true,
    path: `/:category(${categoryListStr})/:slug`,
    title: (values: PageTitleValues): string =>
      `${values?.title || ''}${
        values?.category !== Content.CategorySlugs.HELP ? ` | ${values?.category || ''}` : ''
      } | ${COMPANY_NAME}`,
    useAnalytics: false,
  },
  [Routes.CONTENT_PAGE]: {
    public: true,
    path: `/:category(${categoryListStr})/:type(${Content.typeList.join('|')})/:slug`,
    title: (values: PageTitleValues): string =>
      `${values && values.title ? values.title : ''} | ${
        values && values.category ? values.category : ''
      } | ${COMPANY_NAME}`,
    useAnalytics: false,
  },
  [Routes.CONTENT_PAGE_PREFIX]: {
    public: true,
    path: `/content/:category(${categoryListStr})/:type(${Content.typeList.join('|')})/:slug`,
    title: (values: PageTitleValues): string => {
      return `${values && values.title ? values.title : ''} | ${
        values && values.category ? values.category : ''
      } | ${COMPANY_NAME}`;
    },
    useAnalytics: false,
  },
  [Routes.CONTENT_PAGE_CIMA]: {
    path: `/:category(${categoryListStr}/:type(${Content.typeList.join('|')})/:slug`,
    title: (values: PageTitleValues): string =>
      `${values && values.title ? values.title : ''} | ${
        values && values.category ? values.category : ''
      } | ${COMPANY_NAME}`,
    useAnalytics: false,
  },
  [Routes.REGISTRATION]: {
    path: '/register',
    title: `Registration | ${COMPANY_NAME}`,
    useAnalytics: true,
  },
  [Routes.FORGOT_PASSWORD]: {
    path: '/forgot-password',
    title: 'Forgot Password',
    useAnalytics: true,
  },
  [Routes.RESET_PASSWORD]: {
    path: '/forgot-password/reset',
    title: 'Reset Password',
    useAnalytics: false,
  },
  [Routes.CATEGORY_AGGS_PAGE]: {
    public: true,
    path: `/category/:categorySlug(${categoryListStr})/:topicSlug?/:subtopicSlug?`,
  },
  [Routes.CATEGORY_AGGS_PAGE_CIMA]: {
    path: `/category/:categorySlug(${categoryListStr})/:topicSlug?/:subtopicSlug?`,
    public: true,
  },
  [Routes.PREMIUM_CONTENT_AGGS_PAGE]: {
    path: `/premium-content/:categorySlug(${categoryListStr})/:topicSlug?`,
  },
  [Routes.ADMIN_ROOT]: {
    path: `/admin`,
  },
  [Routes.ADMIN_USER]: {
    path: `/admin/user`,
  },
  [Routes.ADMIN_INVOICES]: {
    path: `/admin/invoices`,
  },
  [Routes.ADMIN_BULK_RENEWAL]: {
    path: `/admin/bulk-renewal`,
  },
  [Routes.ADMIN_OPERATIONS]: {
    path: `/admin/operations`,
  },
  [Routes.ADMIN_NO_FETCH_RESULTS]: {
    path: `/admin/no-fetch-results`,
  },
  [Routes.ADMIN_ERROR_PAGE]: {
    path: `/admin/error-page`,
  },
  [Routes.ADMIN_CANCEL]: {
    path: `/admin/cancel`,
  },
  [Routes.ADMIN_ADD_CLIENT_ADMIN]: {
    path: '/admin/organization/:accountId/:accountNumber?/:legalEntity?/client-admins/add',
  },
  [Routes.ADMIN_CLIENT_ADMIN_RESULTS]: {
    path: '/admin/organization/:accountId/:accountNumber?/:legalEntity?/client-admins',
  },
  [Routes.ADMIN_ORGANIZATION_ROOT]: {
    path: '/admin/organization',
  },
  [Routes.ADMIN_ORGANIZATION]: {
    path: '/admin/organization/:accountId/:accountNumber?/:legalEntity?',
  },
  [Routes.ADMIN_ORDER_DETAILS]: {
    path: '/admin/organization/order/details/:accountId/:accountNumber?/:legalEntity?/:orderNumber?',
  },
  [Routes.ADMIN_ORDER_REFUND_CANCEL]: {
    path: '/admin/organization/:accountId/:accountNumber/:legalEntity/:orderNumber/:productId',
  },
  [Routes.ADMIN_CREATE_ORDER]: {
    path: `/admin/organization/:accountId/order/create/:accountNumber?/:legalEntity?`,
  },
  [Routes.ADMIN_INVOICES_SEARCH]: {
    path: `/admin/invoices/search`,
  },
  [Routes.ADMIN_INVOICES_SEARCH_RESULTS]: {
    path: `/admin/invoices/search/:searchTerm/:legalEntity?`,
  },
  [Routes.ADMIN_INVOICES_PAYMENT]: {
    path: `/admin/invoices/payment`,
  },
  [Routes.ADMIN_INVOICES_PAYMENT_CONFIRMATION]: {
    path: `/admin/invoices/confirmation`,
  },
  [Routes.ADMIN_INVOICES_PAYMENT_ERROR]: {
    path: `/admin/invoices/error`,
  },
  [Routes.ADMIN_INVOICES_FILTER]: {
    path: `/admin/invoices/filter`,
  },
  [Routes.ADMIN_INVOICES_DETAILS]: {
    path: `/admin/invoices/detail/:searchTerm/:invoiceNumber/:legalEntity?`,
  },
  [Routes.CPE_MANAGER]: {
    path: `/cpe-learning/course/aicpa-mycpe-manager`,
  },
  [Routes.ANNUAL_WEBCAST]: {
    path: `/cpe-learning/webcast/annual-webcast-pass`,
  },
  [Routes.DONATION_PAGE]: {
    path: `/${Content.CategorySlugs.DONATIONS}/:productTypeSlug/:slug`,
    title: (values: PageTitleValues): string => {
      return `${values?.title || ''} | ${values?.format ? `${values.format}s` : ''} | ${COMPANY_NAME}`;
    },
    useAnalytics: false,
  },
  [Routes.BLOG_FEED]: {
    path: `/blog`,
  },
  [Routes.CIMA_PER_APPLICANT_DECLARATION]: {
    path: '/help/cima-per-applicant-declaration',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_EPA_APPLICANT_DECLARATION]: {
    path: '/resources/download/cima-epa-applicant-declaration',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_EPA_APPLICANT_TERMS_CONDITIONS]: {
    path: '/resources/download/cimas-end-point-assessment-epa2-terms-and-conditions',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_PER_AUDIT_PROCESS]: {
    path: '/help/per-audit-process',
  },
  [Routes.CIMA_PER_SUPERVISOR_GUIDANCE_LINK]: {
    path: '/help/cima-per-supervisor-guidance-link',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },
  [Routes.CIMA_ONBOARDING_PRACTICE_AREA_PREFERENCES]: {
    path: '/onboarding/topic-preferences',
  },
  [Routes.CIMA_ONBOARDING_INDUSTRY_PREFERENCES]: {
    path: '/onboarding/industry-preferences',
  },
  [Routes.CIMA_ONBOARDING_SKILL_PREFERENCES]: {
    path: '/onboarding/skill-preferences',
  },
  [Routes.CIMA_SPECIAL_CONSIDERATION_POLICY]: {
    path: '/resources/download/cimas-special-consideration-policy',
    title: (values: PageTitleValues): string => `${values?.title || ''} | ${COMPANY_NAME}`,
  },

  ...MEMBERSHIP_ROUTES,
  ...FCMA_CREDENTIAL_APPLICATION_ROUTES,
  ...CIMA_FCMA_CREDENTIAL_ROUTE,
  ...CIMA_ACMA_CREDENTIAL_ROUTE,
  ...CENTER_MEMBERSHIP_ROUTES,
  ...CIMA_MEMBERSHIP_ROUTES,
  ...EXEMPTION_CALCULATOR_ROUTES,
  ...CIMA_REVIEWER_ROUTES,
  ...ACCOUNT_ROUTES,
  ...PROFILE_ROTUES,
  ...CENTER_ADMIN_ROUTES,
  ...PRACTICAL_EXPERIENCE_REQUIREMENT_ROUTES,
  ...CIMA_EXAMS_DASHBOARD_ROUTES,
  ...CIMA_EXAMS_EXEMPTION_ROUTES,
  ...CIMA_EXAMS_GATEWAY_ROUTES,
  ...TUITION_PROVIDER_ROUTES,
  ...CIMA_POST_EXAMS_ROUTES,
  ...CIMA_MIP_APPLICATION_ROUTES,
  ...CIMA_MIP_RENEWAL_APPLICATION_ROUTES,
};

interface TitleValues {
  title?: string;
  format?: string;
  category?: string;
  searchQuery?: string;
  numberOfCartItems?: number;
  useCimaFeatures?: boolean;
}

export const getMyCartTitle = (numOfItems: number) => {
  const prefix = 'My Cart';
  const countDistinction = numOfItems > 1 ? 's' : '';
  const items = numOfItems > 0 ? `: ${numOfItems} Item${countDistinction}` : '';
  return `${prefix}${items}`;
};

export const ExtLinks = {
  LEGACY: 'https://www.aicpa.org/',
  LEGACY_STORE: 'https://www.aicpastore.com/',
  LEGACY_BROWSE: 'https://www.aicpastore.com/AST/AICPA_CPA2BiZ_Nav/Responsive_Top_Nav/CPE.jsp',
  LEGACY_HELP: 'https://www.aicpa.org/help.html',
  LEGACY_CART: 'https://www.aicpastore.com/checkout/shopping_cart.jsp',
  LEGACY_PROFILE: 'https://account.aicpa.org/eWeb/memberpage.aspx?Site=aicpa',
  LEGACY_PURCHASES_CPE:
    'https://www.aicpastore.com/browse/garedirect.jsp?REQURI=http://www.aicpastore.com/AST/AICPA_CPA2BiZ_Nav/Top/Order_Online/My_Account.jsp',
  LEGACY_TERMS_CONDITIONS: `https://www.aicpa.org/privacyandterms/website-terms-conditions.html`,
  LEGACY_PURCHASES_DESKTOP:
    'https://www.aicpastore.com/browse/garedirect.jsp?REQURI=http://www.aicpastore.com/AST/AICPA_CPA2BiZ_Nav/Top/Order_Online/My_Account.jsp',
  LEGACY_PURCHASES_MOBILE: 'https://www.aicpastore.com/AST/AICPA_CPA2BiZ_Nav/Top/Order_Online/My_Account.jsp',
  LEGACY_MY_ACCOUNT: 'https://account.aicpa.org/eWeb/memberpage.aspx?Site=aicpa',
  LEGACY_FORGOT_EMAIL: 'https://login.aicpa.org/ForgotUsername',
  LEGACY_FORGOT_PASSWORD: 'https://login.aicpa.org/ForgotPassword',
  STORE_SEARCH: (searchTerm: string) =>
    `https://www.aicpastore.com/search/results.jsp?SEARCH_INPUT%3C%3EsearchFor=${searchTerm}`,
  LEGACY_ORG_SEARCH: (searchTerm: string) => `https://www.aicpa.org/search.html?source=AICPA&q=${searchTerm}`,
  PRIVACY_POLICY: 'https://www.aicpa.org/privacyandterms/privacy.html',
  SECTIONS_BROWSE_ALL: 'https://www.aicpa.org/membership/section-credential-membership.html',
  CREDENTIALS_PROMO: 'https://www.aicpa-cima.com/membership/landing/credentials',
  LEGACY_PEER_REVIEW: 'https://www.aicpa.org/interestareas/peerreview.html',
  LEGACY_PRODUCTS_HISTORY: 'https://www.aicpastore.com/AST/AICPA_CPA2BiZ_Nav/Top/Order_Online/My_Account.jsp',
  LEGACY_ORDER_HISTORY: 'https://www.aicpastore.com/myaccount/order_history.jsp',
  LEGACY_SECTIONS: 'https://www.aicpa.org/membership/sections.html',
  LEGACY_FIRM_MEMBERSHIPS: 'https://www.aicpa.org/membership/join/firm-memberships.html',
  LEGACY_REGISTER: 'https://account.aicpa.org/eweb/dynamicpage.aspx?webcode=CreateAccountCheck',
  LEGACY_YOUR_PROFILE: ' https://account.aicpa.org/eWeb/DynamicPage.aspx?webcode=tabOnlineComm',
  LEGACY_PERSONAL_INFO: 'https://account.aicpa.org/eWeb/DynamicPage.aspx?webcode=tabPersonalInfo',
  BULK_PURCHASE_LINK: 'https://cloud.e2.aicpa-cima.com/conferences-b2b',
  CONFERENCES_LINK: 'https://aicpaconferences.com/',
  FACEBOOK: 'https://www.facebook.com/AICPA/',
  TWITTER: 'https://twitter.com/AICPA',
  LINKEDIN: 'https://www.linkedin.com/company/aicpa',
  INSTAGRAM: 'https://www.instagram.com/theaicpa/',
  AICPA_RESEARCH: 'https://aicpa-cima.optimalworkshop.com/optimalsort/h42181z3',
  CONTACT_GEC: 'https://www.cimaglobal.com/email-us/',
  PRIVACY_TERMS: 'https://www.aicpa.org/help/privacy-policy',
  CIMA_STUDY: 'https://cimastudy.com/',
  AICPA_CIMA_HELP: 'https://www.aicpa-cima.com/help',
  DD_MATCHING_GIFT_BASICS: 'https://doublethedonation.com/matching-grant-resources/matching-gift-basics/',
  DD_GRANT_BASICS: 'https://doublethedonation.com/matching-grant-resources/volunteer-grant-basics/',
  DOUBLE_DONATION: 'https://doublethedonation.com',
  DD_POWERED_BY: 'https://doublethedonation.com/api/img/powered-by.png',
  DD_PLUGIN_URL: 'https://doublethedonation.com/api/js/ddplugin.js',
  CPE_LEARNING_COURSE_LINK: 'https://www.aicpa-cima.com/resources/landing/business-solutions',
  CPE_LEARNING_WEBCAST_LINK: 'https://cloud.e2.aicpa.org/annual-webcast-pass',
  CPE_LEARNING_EXAM_LINK: 'https://www.aicpa-cima.com/cpe-learning/exam',
};

export const MiPApplicationEmail = {
  MIP_APPLICATION: 'mailto:mip.applications@aicpa-cima.com',
};

export const SalesEmails = {
  SALES_SUPPORT: 'client.support@aicpa-cima.com',
  GROUP_CONFERENCE: 'groupconference@aicpa-cima.com',
};

export const ConferenceSupportEmail = {
  CONF_SUPPORT: 'support@digitellinc.com',
};

export const TopicSlugs = {
  COVID: '/resources/toolkit/aicpa-coronavirus-resource-center',
};
export const PackageMembership = {
  MEMBERSHIP_PACKAGE: '/membership/package',
};

export const CampaignSlugs = {
  BULK_ORDER_FORM: 'bulk-order-form',
  DIGITAL_TRANSFORMATION: 'digital-transformation',
  FIRM_LEAD_CAPTURE_FORM: 'organization-billing-contact-us',
};

export const CategoryDecorationMap: any = {
  DEFAULT: { icon: ProfessionalInsightIcon, color: theme.colors.secondaryOrange },
  BUNDLE: { icon: CPEAndLearningIcon, color: theme.colors.secondaryDarkBlue },
  [Content.CategorySlugs.PROFESSIONAL_INSIGHTS]: { icon: ProfessionalInsightIcon, color: theme.colors.secondaryOrange },
  [Content.CategorySlugs.NEWS]: { icon: NewsIcon, color: theme.colors.interfaceBlue },
  [Content.CategorySlugs.RESOURCES]: { icon: ResourcesIcon, color: theme.colors.secondaryYellow },
  [Content.CategorySlugs.CPE_LEARNING]: { icon: CPEAndLearningIcon, color: theme.colors.secondaryTeal },
};

export const MembershipEnrollmentFees: string[] = [
  Product.MembershipEnrollmentFeeSku.AICPA_MEMBERSHIP_ENROLLMENT_FEE_SKU,
  Product.MembershipEnrollmentFeeSku.CIMA_MEMBERSHIP_ENROLLMENT_FEE_SKU,
];

export enum SortByKeyNames {
  RELEVANCY = 'Relevancy',
  NEWEST_FIRST = 'NewestFirst',
  OLDEST_FIRST = 'OldestFirst',
  ALPHABETICAL = 'Alphabetical',
}

export const sortByOptions = {
  [SortByKeyNames.RELEVANCY]: {
    key: 1,
    text: 'Relevancy',
    value: 'relevancy',
  },
  [SortByKeyNames.NEWEST_FIRST]: {
    key: 2,
    text: 'Newest First',
    value: 'newest-first',
  },
  [SortByKeyNames.OLDEST_FIRST]: {
    key: 3,
    text: 'Oldest First',
    value: 'oldest-first',
  },
  [SortByKeyNames.ALPHABETICAL]: {
    key: 4,
    text: 'Alphabetical (A-Z)',
    value: 'alphabetical',
  },
};

export enum FilterActiveKeys {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum ProfileListSortByKeyNames {
  THREE_MONTHS = '3 months',
  SIX_MONTHS = '6 months',
  ONE_YEAR = '1 year',
  ALL_TIME = 'All time',
}
export enum ProfileListSortByValues {
  THREE_MONTHS = '3-months',
  SIX_MONTHS = '6-months',
  ONE_YEAR = '1-year',
  ALL_TIME = 'all-time',
}

export interface ProfileListSortByOption {
  key: number;
  text: ProfileListSortByKeyNames;
  value: ProfileListSortByValues;
  disabled: boolean;
}

export type ProfileListSortByOptions = {
  [key in ProfileListSortByKeyNames]: ProfileListSortByOption;
};

export const profileListSortByOptions: ProfileListSortByOptions = {
  [ProfileListSortByKeyNames.THREE_MONTHS]: {
    key: 1,
    text: ProfileListSortByKeyNames.THREE_MONTHS,
    value: ProfileListSortByValues.THREE_MONTHS,
    disabled: false,
  },
  [ProfileListSortByKeyNames.SIX_MONTHS]: {
    key: 2,
    text: ProfileListSortByKeyNames.SIX_MONTHS,
    value: ProfileListSortByValues.SIX_MONTHS,
    disabled: false,
  },
  [ProfileListSortByKeyNames.ONE_YEAR]: {
    key: 3,
    text: ProfileListSortByKeyNames.ONE_YEAR,
    value: ProfileListSortByValues.ONE_YEAR,
    disabled: false,
  },
  [ProfileListSortByKeyNames.ALL_TIME]: {
    key: 4,
    text: ProfileListSortByKeyNames.ALL_TIME,
    value: ProfileListSortByValues.ALL_TIME,
    disabled: false,
  },
};

export interface DateFilterItemProps {
  id: string;
  name: string;
  resultCount?: number;
}

enum ViewTypes {
  LIST = 'List',
  GRID = 'Grid',
}

export const DefaultPagination = {
  [ViewTypes.LIST]: {
    PAGE: 1,
    MIN_PER_PAGE: 10,
    PER_PAGE_OPTIONS: [10, 25, 50],
    SORT_BY: sortByOptions.Relevancy.value,
  },
  [ViewTypes.GRID]: {
    PAGE: 1,
    MIN_PER_PAGE: 12,
    PER_PAGE_OPTIONS: [12, 24, 48],
    SORT_BY: sortByOptions.NewestFirst.value,
  },
  MAX_PAGES: 5,
};

export const ContentTypes = {
  PODCAST: 'podcast',
  VIDEO: 'video',
  ARTICLE: 'article',
  PRODUCT: 'product',
  DOWNLOAD: 'downloadTemplate',
  COURSE: 'course',
  WEBCASTS: 'webcast',
  TOOLKIT: 'toolkitPageTemplate',
  PUBLICATION: 'publication',
  CONFERENCE: 'conference',
  EXAM: 'exam',
};

export const ContentTypesURL = {
  [ContentTypes.PODCAST]: ContentTypes.PODCAST,
  [ContentTypes.VIDEO]: ContentTypes.VIDEO,
  [ContentTypes.ARTICLE]: ContentTypes.ARTICLE,
  [ContentTypes.DOWNLOAD]: 'download',
  [ContentTypes.TOOLKIT]: 'toolkit',
};

/**
 * Names map for session storage, local storage, remote storage
 */
export const StorageNames = {
  hideCookieBanner: 'hideCookieBanner',
  userData: 'userData',
  legacyNavigationCount: 'legacy-navigation-count',
  showLegacyBanner: 'show-legacy-banner',
  checkout: 'checkout',
  actionsToDispatchAfterLogin: 'actionsToDispatchAfterLogin',
  anonCartInfo: 'anonCartInfo',
  adminImpersonationToken: 'adminImpersonationToken',
  adminClientRole: 'adminClientRole',
  invoice: 'invoice',
  isLegacyImpersonation: 'isLegacyImpersonation',
  learningPathway: 'learningPathway',
  runFlpValidation: 'runFlpValidation',
  userPreferredCurrency: 'userPreferredCurrency',
  isDeleteCardBeingProcessed: 'isDeleteCardBeingProcessed',
  inviteId: 'inviteId',
  inviteEmail: 'inviteEmail',
  faCancelInvoice: 'faCancelInvoice',
  previousPage: 'previousPage',
  assessorToken: 'assessorToken',
  perModuleId: 'perModuleId',
  assessorMemberData: 'assessorMemberData',
  isAllowToAddFcmaInCart: 'isAllowToAddFcmaInCart',
  activeCart: 'activeCart',
};

export enum ImpersonationOptions {
  AICPA = 'aicpa-cima.com',
}

export enum SESSION_STORE_NAMES {
  ADMIN_SESSION_STORE_KEY = 'ADMIN_SESSION_STORE_KEY',
  AICPA_DATA = 'AICPA_DATA',
  FB_ADMIN = 'FB_ADMIN',
}

export const FilterNames = {
  TOPIC_LEVEL0: 'topic',
  TOPIC_LEVEL1: 'subtopic',
  INDUSTRY: 'industry',
  TREND: 'trend',
  SKILL_LEVEL_0: 'skill',
  SKILL_LEVEL_1: 'subskill',
  CATEGORY: 'category',
  CONTENT_TYPE: 'type',
  PREMIUM: 'premium',
  SORT_BY: 'sortBy',
  PAGE: 'page',
  PER_PAGE: 'perPage',
  CPE_CREDIT: 'credits',
  NASBA_FIELD_STUDY: 'nasbaField',
  NASBA_PROGRAM_LEVEL: 'nasbaProgram',
  AVAILABLE_FORMAT: 'availableFormat',
  EVENT_START_DATE: 'eventStartDate',
  EVENT_END_DATE: 'eventEndDate',
  GET_NOTICED: 'getNoticed',
  WEBCAST_PASS: 'includedInWebcastPass',
  CONTENT_SOURCE: 'contentSource',
  GROUPS: 'groups',
  JOBROLE: 'jobRole',
  CAREERSTAGE: 'careerStage',
  CONTENTLANGUAGE: 'contentLanguage',
  LOCATION_LEVEL_0: 'location',
  LOCATION_LEVEL_1: 'locationByCountry',
  LOCATION_LEVEL_2: 'locationByState',
};

export enum FilterIds {
  category = 0,
  topic = 1,
  subtopic = 2,
  credits = 3,
  type = 4,
  premium = 5,
  nasbaField = 6,
  nasbaProgram = 7,
  availableFormat = 8,
  eventDate = 9,
  getNoticed = 10,
  webcastPass = 11,
  contentSource = 12,
  groups = 13,
  industry = 14,
  trend = 15,
  skill = 16,
  subskill = 17,
  jobRole = 18,
  careerStage = 19,
  contentLanguage = 20,
  location = 21,
  locationByCountry = 22,
  locationByState = 23,
}

export const FilterIdToNameMap = {
  [FilterIds.category]: FilterNames.CATEGORY,
  [FilterIds.topic]: FilterNames.TOPIC_LEVEL0,
  [FilterIds.subtopic]: FilterNames.TOPIC_LEVEL1,
  [FilterIds.industry]: FilterNames.INDUSTRY,
  [FilterIds.trend]: FilterNames.TREND,
  [FilterIds.skill]: FilterNames.SKILL_LEVEL_0,
  [FilterIds.subskill]: FilterNames.SKILL_LEVEL_1,
  [FilterIds.credits]: FilterNames.CPE_CREDIT,
  [FilterIds.type]: FilterNames.CONTENT_TYPE,
  [FilterIds.getNoticed]: FilterNames.GET_NOTICED,
  [FilterIds.webcastPass]: FilterNames.WEBCAST_PASS,
  [FilterIds.premium]: FilterNames.PREMIUM,
  [FilterIds.nasbaField]: FilterNames.NASBA_FIELD_STUDY,
  [FilterIds.nasbaProgram]: FilterNames.NASBA_PROGRAM_LEVEL,
  [FilterIds.availableFormat]: FilterNames.AVAILABLE_FORMAT,
  [FilterIds.eventDate]: 'eventDate',
  [FilterIds.contentSource]: FilterNames.CONTENT_SOURCE,
  [FilterIds.groups]: FilterNames.GROUPS,
  [FilterIds.jobRole]: FilterNames.JOBROLE,
  [FilterIds.careerStage]: FilterNames.CAREERSTAGE,
  [FilterIds.contentLanguage]: FilterNames.CONTENTLANGUAGE,
  [FilterIds.location]: FilterNames.LOCATION_LEVEL_0,
  [FilterIds.locationByCountry]: FilterNames.LOCATION_LEVEL_1,
  [FilterIds.locationByState]: FilterNames.LOCATION_LEVEL_2,
};

export const SearchHistoryLimit = 10;

export enum SurveyOptionTypeEnum {
  Input = 0,
  Radio = 1,
  TextArea = 2,
  Link = 3,
}

export interface SurveyOption {
  values?: {
    [key: string]: {
      text: string;
      next?: number;
      activeForm?: SurveyOption[];
    };
  };
  title?: string;
  type: SurveyOptionTypeEnum;
  link?: string;
  text?: string;
}

export enum ThumbDirection {
  UP = 'up',
  DOWN = 'down',
}

export const PAYMENT_PROCESSING_TIMEOUT = 3000;

export enum PRODUCT_CART_STATUS {
  UNKNOWN = 1,
  IN_CART,
  NOT_IN_CART,
  THIRD_PARTY_PRODUCT,
  THIRD_PARTY_PRODUCT_CONFERENCE,
  ACCESS_NOW,
}

export const CART_CTA_TEXT = {
  [PRODUCT_CART_STATUS.UNKNOWN]: 'Please refresh',
  [PRODUCT_CART_STATUS.IN_CART]: 'View in Cart',
  [PRODUCT_CART_STATUS.NOT_IN_CART]: 'Add to Cart',
  [PRODUCT_CART_STATUS.THIRD_PARTY_PRODUCT]: 'Book with our partner',
  [PRODUCT_CART_STATUS.THIRD_PARTY_PRODUCT_CONFERENCE]: 'Start registration',
  [PRODUCT_CART_STATUS.ACCESS_NOW]: 'Access now',
};

export enum UserDropdownOptionLabels {
  MyProfile = 'Profile',
  MyContentPreferences = 'MyContentPreferences',
  MyCommunications = 'Preferences',
  MyStudies = 'Studies',
  MyPreferences = 'Preferences',
  MyPurchases = 'Purchases',
  MyWallet = 'Wallet',
  MyReceipts = 'Receipts',
  MyContactPreferences = 'Contact Preferences',
  MySavedItems = 'Saved Items',
  MyOrganizationProfile = 'Organization Profile', // TODO: Delete this key before cima go live
  MyBusinessProfile = 'Business profile',
  FirmAdmin = 'Firm Admin Profile',
  CenterMembershipAdmin = 'My centers admin profile',
  TuitionProvider = 'Tuition provider profile',
  LogOut = 'Log Out',
}

export enum PostExamFieldIds {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  APPOINTMENT_ID = 'appointmentId',
  EXAM_REQUIRES_SPECIAL_CONSIDERATION = 'examRequiresSpecialConsideration',
  EXAM_DATE = 'examDate',
  EXAM_DELIVERY = 'examDelivery',
  TEST_CENTER = 'testCenter',
  REASON_SPECIAL_CONSIDERATION_OPTION = 'reasonSpecialConsiderationOption',
  PEARSON_VUE_CASE_ID = 'pearsonVueCaseId',
  REASON_SPECIAL_CONSIDERATION_REQUEST = 'reasonSpecialConsiderationRequest',
  DOCUMENTATION_TYPE = 'documentationType',
  DETAIL_PROBLEM_ENCOUNTERED = 'detailProbEncountered',
  DETAIL_PROBLEM_STARTED = 'detailProbStarted',
  DETAIL_PROBLEM_LASTED = 'detailProbLasted',
  DETAIL_PROBLEM_AFFECTED = 'detailProbAffect',
  ADVICE_TAKEN = 'adviceTaken',
  REPORT = 'report',
}

export enum OrganizationSectionLabels {
  SeatManagement = 'Seat Management',
  Invoices = 'Invoices',
  FirmRoster = 'Firm Roster',
}

export const rolesForCardTags: string[] = [
  User.MembershipIdsEnum.MRUSR0001,
  User.MembershipIdsEnum.MRUKR0001,
  User.MembershipIdsEnum.NON_MEMBER,
  User.MembershipIdsEnum.LOGGED_IN,
  User.MembershipIdsEnum.FREE_TRIAL,
];
export interface PaginationInt {
  total: number;
  from: number;
  to: number;
  perPage: number;
  pageNumber: number;
}

export enum ADMIN_AUTH_STATUS {
  UNAUTHENTICATED,
  UNAUTHORIZED,
  AUTHORIZED,
}

export const adminSearchInvoiceScreens: string[] = ['Search', 'Select', 'Payment'];

export const SOURCE = 'futureaicpa';

export enum ContentRestriction {
  FREE = 'free',
  REGISTERED = 'registered',
  MEMBER = 'member',
  PREMIUM = 'premium',
  PREMIUM_SUBSCRIPTION = 'premium_subscription',
}

export enum UserHomepage {
  UNKNOWN = 'unknown',
  LOGGED_OUT = 'logged out',
  LOGGED_IN = 'logged in',
  NOT_ONBOARDED = 'not-onboarded',
  ONBOARDED = 'onboarded',
}

export enum CreditCardTypes {
  VISA = 'Visa',
  MASTERCARD = 'MasterCard',
  AMERICANEXPRESS = 'AmericanExpress',
  DISCOVER = 'Discover',
}

export enum OtherPaymentTypes {
  PAYPAL = 'Paypal',
  ACH = 'Ach',
  BANKTRANSFER = 'BankTransfer',
}

export enum CreditCardOperations {
  SET_DEFAULT = 'setDefault',
  REMOVE = 'remove',
  ADD = 'add',
  NONE = 'none',
  SET_DEFAULT_AFTER_ADDING = 'setDefaultAfterAdding',
  REPLACE_AS_DEFAULT = 'replaceAsDefault',
}

export enum OrganizationChannels {
  RESELLER = 'Reseller',
}

enum PhysicalProductsLineItemStateName {
  AWAITING_SHIPMENT = 'Processing',
  SHIPPED = 'Shipped',
  REFUND_PROCESSED = 'Refund processed',
}
export const PhysicalProductsLineItemStatesNamesMap: {
  [key in Orders.LineItemStates]?: PhysicalProductsLineItemStateName;
} = {
  [Orders.LineItemStates.INITIAL]: PhysicalProductsLineItemStateName.AWAITING_SHIPMENT,
  [Orders.LineItemStates.AWAITING_SHIPMENT]: PhysicalProductsLineItemStateName.AWAITING_SHIPMENT,
  [Orders.LineItemStates.SHIPPED]: PhysicalProductsLineItemStateName.SHIPPED,
  [Orders.LineItemStates.CANCELLED_AND_DEPROVISIONED]: PhysicalProductsLineItemStateName.REFUND_PROCESSED,
};

export enum ChannelInquiryFormAreaOfInterestValues {
  PROFESSIONAL_DEVELOPMENT = 'Professional development (CPD/CPE)',
  QUALIFICATIONS_CREDENTIALS = 'Qualifications/Credentials',
  MEMBERSHIPS = 'Memberships',
  LICENSING_PERMISSIONS = 'Licensing and permissions',
  TEACHING = 'Teaching',
  CHANNEL_PARTNERSHIP = 'Channel partnership',
  OTHER = 'Other/Not sure',
}

export const channelInquiryFormAreaOfInterestOptions = [
  {
    key: 1,
    text: ChannelInquiryFormAreaOfInterestValues.PROFESSIONAL_DEVELOPMENT,
    value: ChannelInquiryFormAreaOfInterestValues.PROFESSIONAL_DEVELOPMENT,
  },
  {
    key: 2,
    text: ChannelInquiryFormAreaOfInterestValues.QUALIFICATIONS_CREDENTIALS,
    value: ChannelInquiryFormAreaOfInterestValues.QUALIFICATIONS_CREDENTIALS,
  },
  {
    key: 3,
    text: ChannelInquiryFormAreaOfInterestValues.MEMBERSHIPS,
    value: ChannelInquiryFormAreaOfInterestValues.MEMBERSHIPS,
  },
  {
    key: 4,
    text: ChannelInquiryFormAreaOfInterestValues.LICENSING_PERMISSIONS,
    value: ChannelInquiryFormAreaOfInterestValues.LICENSING_PERMISSIONS,
  },
  {
    key: 5,
    text: ChannelInquiryFormAreaOfInterestValues.TEACHING,
    value: ChannelInquiryFormAreaOfInterestValues.TEACHING,
  },
  {
    key: 6,
    text: ChannelInquiryFormAreaOfInterestValues.CHANNEL_PARTNERSHIP,
    value: ChannelInquiryFormAreaOfInterestValues.CHANNEL_PARTNERSHIP,
  },
  {
    key: 7,
    text: ChannelInquiryFormAreaOfInterestValues.OTHER,
    value: ChannelInquiryFormAreaOfInterestValues.OTHER,
  },
];

export enum ChannelInquiryFormMarketSegmentValues {
  ACCOUNTING = 'Accounting',
  PARTNER = 'Partner',
  FINANCE = 'Finance',
  GENERAL_BUSINESS = 'General business',
  STUDENTS = 'Students',
  INDUSTRY_SPECIFIC = 'Industry specific',
  GOVT = 'Government',
  PROFESSIONAL_ORGANIZATION = 'Professional organization',
  ACADEMIA = 'Academia',
  OTHER = 'Other',
}

export const channelInquiryFormMarketSegmentOptions = [
  {
    key: 1,
    text: ChannelInquiryFormMarketSegmentValues.ACCOUNTING,
    value: ChannelInquiryFormMarketSegmentValues.ACCOUNTING,
  },
  {
    key: 2,
    text: ChannelInquiryFormMarketSegmentValues.PARTNER,
    value: ChannelInquiryFormMarketSegmentValues.PARTNER,
  },
  {
    key: 3,
    text: ChannelInquiryFormMarketSegmentValues.FINANCE,
    value: ChannelInquiryFormMarketSegmentValues.FINANCE,
  },
  {
    key: 4,
    text: ChannelInquiryFormMarketSegmentValues.GENERAL_BUSINESS,
    value: ChannelInquiryFormMarketSegmentValues.GENERAL_BUSINESS,
  },
  {
    key: 5,
    text: ChannelInquiryFormMarketSegmentValues.STUDENTS,
    value: ChannelInquiryFormMarketSegmentValues.STUDENTS,
  },
  {
    key: 6,
    text: ChannelInquiryFormMarketSegmentValues.INDUSTRY_SPECIFIC,
    value: ChannelInquiryFormMarketSegmentValues.INDUSTRY_SPECIFIC,
  },
  {
    key: 7,
    text: ChannelInquiryFormMarketSegmentValues.GOVT,
    value: ChannelInquiryFormMarketSegmentValues.GOVT,
  },
  {
    key: 8,
    text: ChannelInquiryFormMarketSegmentValues.PROFESSIONAL_ORGANIZATION,
    value: ChannelInquiryFormMarketSegmentValues.PROFESSIONAL_ORGANIZATION,
  },
  {
    key: 9,
    text: ChannelInquiryFormMarketSegmentValues.ACADEMIA,
    value: ChannelInquiryFormMarketSegmentValues.ACADEMIA,
  },
  {
    key: 10,
    text: ChannelInquiryFormMarketSegmentValues.OTHER,
    value: ChannelInquiryFormMarketSegmentValues.OTHER,
  },
];

export const TeamSizeOptions = [
  {
    key: '0',
    text: '5 - 20',
    value: '5-20',
  },
  {
    key: '1',
    text: '21 - 50',
    value: '21-50',
  },
  {
    key: '2',
    text: '51 - 100',
    value: '51-100',
  },
  {
    key: '3',
    text: '101 - 200',
    value: '101-200',
  },
  {
    key: '4',
    text: '201 +',
    value: '201+',
  },
];

export const IAmInterestedInTrainingForOptions = [
  {
    key: '0',
    text: 'Qualified and licensed CPA in good standing',
    value: 'Qualified and licensed CPA in good standing',
  },
  {
    key: '1',
    text: 'Qualified CPA who has not been licensed',
    value: 'Qualified CPA who has not been licensed',
  },
  {
    key: '2',
    text: 'Accounting or finance professional without a CPA license',
    value: 'Accounting or finance professional without a CPA license',
  },
  {
    key: '3',
    text: 'Member of an accounting association outside of the USA',
    value: 'Member of an accounting association outside of the USA',
  },
  {
    key: '4',
    text: 'CPA exam candidate',
    value: 'CPA exam candidate',
  },
  {
    key: '5',
    text: 'College student with an interest in a career as a CPA',
    value: 'College student with an interest in a career as a CPA',
  },
];

export const TierPreferenceOptions = [
  {
    key: '0',
    text: 'Tier 1 - Enhance',
    value: 'Tier 1 - Enhance',
  },
  {
    key: '1',
    text: 'Tier 2 - Evolve',
    value: 'Tier 2 - Evolve',
  },
  {
    key: '2',
    text: 'Tier 3 - Transform',
    value: 'Tier 3 - Transform',
  },
  {
    key: '3',
    text: 'Undecided',
    value: 'Undecided',
  },
];

export const PreferredCertificationsOptions = [
  {
    key: '0',
    text: 'Operational Excellence',
    value: 'Operational Excellence',
  },
  {
    key: '1',
    text: 'Digital intelligence',
    value: 'Digital intelligence',
  },
  {
    key: '2',
    text: 'Business Influence',
    value: 'Business Influence',
  },
  {
    key: '3',
    text: 'All of the above',
    value: 'All of the above',
  },
  {
    key: '4',
    text: 'Undecided',
    value: 'Undecided',
  },
];

export const LANDING_PAGES_CONSTS = {
  CONTENTS_AMOUNT: 4,
  EDITORIAL_HERO_AMOUNT: 6,
};

export enum FundEIN {
  BENEVOLENT = '13-6168775',
  AICPA = '13-6169602',
}

export enum SocialIconType {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN',
  INSTAGRAM = 'INSTAGRAM',
}

export const SocialIconLink = {
  [SocialIconType.FACEBOOK]: ExtLinks.FACEBOOK,
  [SocialIconType.TWITTER]: ExtLinks.TWITTER,
  [SocialIconType.LINKEDIN]: ExtLinks.LINKEDIN,
  [SocialIconType.INSTAGRAM]: ExtLinks.INSTAGRAM,
};

export interface MembershipApplicationStep {
  name: string;
  pathName: Routes;
  showInRenewals?: boolean;
  endPathName?: MembershipPackageAccordionStatus;
}

export const CentreMembershipApplicationSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.CENTER_MEMBERSHIP_FORM_START,
    name: 'Start',
  },
  {
    pathName: Routes.CENTER_MEMBERSHIP_FORM_ADMIN,
    name: 'Admin',
  },
  {
    pathName: Routes.CENTER_MEMBERSHIP_FORM_FIRM,
    name: 'Firm',
  },
  {
    pathName: Routes.CENTER_MEMBERSHIP_FORM_ATTESTATION,
    name: 'Attestation',
  },
];

export const CimaExamsExemptionsPages: MembershipApplicationStep[] = [
  {
    pathName: Routes.CIMA_EXAMS_EXEMPTIONS_FILE_UPLOAD,
    name: 'CIMA Exam Exemption Upload Files',
  },
  {
    pathName: Routes.CIMA_EXAMS_EXEMPTIONS_VIEW_FILES,
    name: 'CIMA Exam Exemption View Files',
  },
];

export const CimaExamsGatewayPages: MembershipApplicationStep[] = [
  {
    pathName: Routes.CIMA_EXAMS_GATEWAY_FILE_UPLOAD,
    name: 'CIMA Exam Gateway Upload Files',
  },
  {
    pathName: Routes.CIMA_EXAMS_GATEWAY_VIEW_FILES,
    name: 'CIMA Exam Gateway View Files',
  },
];

export const MembershipApplicationSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.APPLICATION_FORM_START,
    name: 'Start',
  },
  {
    pathName: Routes.APPLICATION_FORM_PERSONAL,
    name: 'Personal',
  },
  {
    pathName: Routes.APPLICATION_FORM_QUALIFICATION,
    name: 'Qualification',
  },
  {
    pathName: Routes.APPLICATION_FORM_EMPLOYMENT,
    name: 'Employment',
  },
  {
    pathName: Routes.APPLICATION_FORM_DONATIONS,
    name: 'Donations',
    showInRenewals: true,
  },
  {
    pathName: Routes.APPLICATION_FORM_ATTESTATION,
    name: 'Attestation',
    showInRenewals: true,
  },
];

export const CimaMembershipApplicationSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_START,
    name: 'Start',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL,
    name: 'Personal information',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_CPA,
    name: 'CPA',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_EMPLOYMENT,
    name: 'Employment',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_TRAINING,
    name: 'Training provider',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DOCUMENT,
    name: 'Documents upload',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_GATEWAY_DOCUMENT,
    name: 'Gateway',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS,
    name: 'Donation',
    showInRenewals: true,
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION,
    name: 'Attestation',
    showInRenewals: true,
  },
];

export const PackageMembershipApplicationSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.MEMBERSHIP_FORM,
    name: 'Customize',
  },
  {
    pathName: Routes.APPLICATION_FORM,
    name: 'Profile Setup',
  },
  {
    pathName: Routes.PAGE_PAYMENT,
    name: 'Address',
  },
  {
    pathName: Routes.CHECKOUT_PAGE,
    name: 'Payment',
  },
];

export const FcmaCredentialpApplicationSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.FCMA_CREDENTIAL_APPLICATION_FORM_PERSONAL,
    name: 'Personal',
  },
  {
    pathName: Routes.FCMA_CREDENTIAL_APPLICATION_FORM_EMPLOYMENT,
    name: 'Employment',
  },
  {
    pathName: Routes.FCMA_CREDENTIAL_APPLICATION_FORM_DONATIONS,
    name: 'Donations',
    showInRenewals: true,
  },
  {
    pathName: Routes.FCMA_CREDENTIAL_APPLICATION_FORM_ATTESTATION,
    name: 'Attestation',
    showInRenewals: true,
  },
];

export const CimaMembershipStudyPathSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY,
    name: 'Learning pathway',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_PROFESSIONAL,
    name: 'Professional qualification',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_EDUCATIONAL,
    name: 'Educational qualification',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_WORK,
    name: 'Work experience',
  },
];

export const ExemptionCalculatorSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.EXEMPTION_CALCULATOR_PROFESSIONAL,
    name: 'Professional qualification',
  },
  {
    pathName: Routes.EXEMPTION_CALCULATOR_EDUCATIONAL,
    name: 'Educational qualification',
  },
  {
    pathName: Routes.EXEMPTION_CALCULATOR_WORK,
    name: 'Work experience',
  },
  {
    pathName: Routes.EXEMPTION_CALCULATOR_SUMMARY,
    name: 'Exemption Summary',
  },
];

export const CimaMembershipPackageSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.CIMA_MEMBERSHIP_PACKAGE,
    name: 'Determine your entry point',
    endPathName: MembershipPackageAccordionStatus.Level,
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_PACKAGE,
    name: 'Choose your learning materials and assessments',
    endPathName: MembershipPackageAccordionStatus.Skill,
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_PACKAGE,
    name: 'Regional Pathway',
    endPathName: MembershipPackageAccordionStatus.Pathway,
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_PACKAGE,
    name: 'Customise your package',
    endPathName: MembershipPackageAccordionStatus.Addons,
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_PACKAGE,
    name: 'Summary of your selections',
    endPathName: MembershipPackageAccordionStatus.Summary,
  },
];

export const CimaMembershipSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.CIMA_MEMBERSHIP_STUDYPATH,
    name: 'Learning pathway',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_PACKAGE,
    name: 'Entry point and Programme',
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM,
    name: 'Application',
  },
];

export const CimaFlpApplicationSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL,
    name: 'Personal',
    showInRenewals: true,
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_EMPLOYMENT,
    name: 'Employment',
    showInRenewals: true,
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS,
    name: 'Donations',
    showInRenewals: true,
  },
  {
    pathName: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION,
    name: 'Attestation',
    showInRenewals: true,
  },
];

export const PracticalExperienceRequirementRoutes: MembershipApplicationStep[] = [
  {
    pathName: Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT,
    name: 'User-Overview',
  },
  {
    pathName: Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT,
    name: 'Employment',
  },
  {
    pathName: Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES,
    name: 'CoreActivities',
  },
  {
    pathName: Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_SKILLS_BEHAVIORS,
    name: 'SkillsBehaviors',
  },
];

export const CimaMipAmlApplicationSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.CIMA_MIP_AML_APPLICATION_CTF_RETURN_FORM,
    name: 'AML/CTF annual return form',
  },
  {
    pathName: Routes.CIMA_MIP_AML_APPLICATION_CTF_RETURN_RESPONSE_SUMMARY,
    name: 'AML/CTF annual return responses summary',
  },

  {
    pathName: Routes.CIMA_MIP_AML_APPLICATION_ATTESTATION,
    name: 'Attestation',
  },
];

export const CimaMipCheckoutSteps: MembershipApplicationStep[] = [
  {
    pathName: Routes.CIMA_MIP_CHECKOUT_ATTESTATION,
    name: 'Attestation',
  },
  {
    pathName: Routes.CIMA_MIP_CHECKOUT_MIP_DETAILS,
    name: 'Member in practice details',
  },
  {
    pathName: Routes.CIMA_MIP_CHECKOUT_ADDRESS,
    name: 'Address',
  },
  {
    pathName: Routes.CIMA_MIP_CHECKOUT_PAYMENT,
    name: 'Payment',
  },
];

export const MembershipApplicationStepsMaps = {
  [Product.MembershipApplicationType.AICPA]: MembershipApplicationSteps,
  [Product.MembershipApplicationType.CIMA]: CimaMembershipApplicationSteps,
  [Product.MembershipApplicationType.AICPA_CENTER_MEMBERSHIP]: CentreMembershipApplicationSteps,
  [Product.MembershipApplicationType.CIMA_MIP]: CimaMipAmlApplicationSteps,
  [Product.MembershipApplicationType.CIMA_FLP]: CimaFlpApplicationSteps,
};

export const OrganizationNavigationList = [
  { label: 'Seats Management', key: Routes.ORG_SEAT_MANAGEMENT },
  { label: 'Invoices', key: Routes.ORG_INVOICES },
];

export const CenterAdminNavigationList1 = [{ label: 'Firm details', key: Routes.CENTER_ADMIN_TAB_INFO }];

export const CenterAdminNavigationList2 = [
  { label: 'View firm/organization memberships', key: Routes.CENTER_ADMIN_MEMBERSHIPS_TAB_MANAGE },
  { label: 'View and pay invoices', key: Routes.CENTER_ADMIN_MEMBERSHIPS_TAB_INVOICES },
];

export const TuitionProviderNavigationList1 = [{ label: 'Firm details', key: Routes.TUITION_PROVIDER_TAB_INFO }];

export const TuitionProviderNavigationList2 = [
  { label: 'Manage apprentice register', key: Routes.TUITION_PROVIDER_APPRENTICE_ROSTER },
];

export const FirmInformationNavigationList = [
  { label: 'Basic Company Details', key: Routes.FIRM_INFO_TAB_BASIC_COMPANY_DETAILS },
];

export const FirmRosterNavigationList = [
  { label: 'Manage Firm Roster', key: Routes.FIRM_ROSTER_TAB_MANAGE },
  { label: 'Add and Upgrade Members', key: Routes.FIRM_ROSTER_TAB_MEMBERS },
  { label: 'Firm Roster Support', key: Routes.FIRM_ROSTER_TAB_SUPPORT },
];

export const FirmBillingNavigationList = [
  { label: 'Manage Firm Billing', key: Routes.FIRM_BILLING_TAB_MANAGE },
  { label: 'Memberships and add-ons', key: Routes.FIRM_BILLING_TAB_MEMBERSHIPS },
  { label: 'Firm Billing Support', key: Routes.FIRM_BILLING_TAB_SUPPORT },
  { label: 'View and pay invoices', key: Routes.FIRM_BILLING_TAB_INVOICES },
];

export const CimaFirmRosterNavigationList = [
  { label: 'Manage Organization Roster', key: Routes.FIRM_ROSTER_TAB_MANAGE },
  { label: 'Add and Upgrade Members', key: Routes.FIRM_ROSTER_TAB_MEMBERS },
  { label: 'Organization Roster Support', key: Routes.FIRM_ROSTER_TAB_SUPPORT },
];

export const CimaFirmBillingNavigationList = [
  { label: 'Manage Organization Billing', key: Routes.FIRM_BILLING_TAB_MANAGE },
  { label: 'Memberships and add-ons', key: Routes.FIRM_BILLING_TAB_MEMBERSHIPS },
  { label: 'Organization Billing Support', key: Routes.FIRM_BILLING_TAB_SUPPORT },
  { label: 'View and pay invoices', key: Routes.FIRM_BILLING_TAB_INVOICES },
];

export const CimaFlpFirmRosterNavigationList = [
  { label: 'Manage Organization Roster', key: Routes.FIRM_ROSTER_TAB_MANAGE },
  { label: 'Add and Upgrade Members', key: Routes.FIRM_ROSTER_TAB_MEMBERS },
  { label: 'Add new FLP subscription', key: Routes.FIRM_ROSTER_TAB_FLP_SUBSCRIPTIONS },
  { label: 'Organization Roster Support', key: Routes.FIRM_ROSTER_TAB_SUPPORT },
];

export const CimaFlpFirmBillingNavigationList = [
  { label: 'Manage Organization Billing', key: Routes.FIRM_BILLING_TAB_MANAGE },
  { label: 'FLP Organization Billing', key: Routes.FIRM_BILLING_TAB_FLP_ORGANIZATION },
  { label: 'Memberships and add-ons', key: Routes.FIRM_BILLING_TAB_MEMBERSHIPS },
  { label: 'Organization Billing Support', key: Routes.FIRM_BILLING_TAB_SUPPORT },
  { label: 'View and pay invoices', key: Routes.FIRM_BILLING_TAB_INVOICES },
];
export const AicpaFlpFirmRosterNavigationList = [
  { label: 'Manage Firm Roster', key: Routes.FIRM_ROSTER_TAB_MANAGE },
  { label: 'Add and Upgrade Members', key: Routes.FIRM_ROSTER_TAB_MEMBERS },
  { label: 'Add new FLP subscription', key: Routes.FIRM_ROSTER_TAB_FLP_SUBSCRIPTIONS },
  { label: 'Firm Roster Support', key: Routes.FIRM_ROSTER_TAB_SUPPORT },
];

export const AicpaFlpFirmBillingNavigationList = [
  { label: 'Manage Firm Billing', key: Routes.FIRM_BILLING_TAB_MANAGE },
  { label: 'FLP Firm Billing', key: Routes.FIRM_BILLING_TAB_FLP_ORGANIZATION },
  { label: 'Memberships and add-ons', key: Routes.FIRM_BILLING_TAB_MEMBERSHIPS },
  { label: 'Firm Billing Support', key: Routes.FIRM_BILLING_TAB_SUPPORT },
  { label: 'View and pay invoices', key: Routes.FIRM_BILLING_TAB_INVOICES },
];

export enum IconList {
  SEARCH_ICON = 'SearchIcon',
}

export const ApplicationProgressRoutesMap: { [key: string]: Routes } = {
  [User.MembershipApplicationStages.PRE_SIGN_UP]: Routes.PRE_SIGN_UP,
  [User.MembershipApplicationStages.PACKAGE_BUILDER]: Routes.MEMBERSHIP_FORM,
  [User.MembershipApplicationStages.APPLICATION]: Routes.APPLICATION_FORM,
  [User.MembershipApplicationStages.START]: Routes.APPLICATION_FORM_START,
  [User.MembershipApplicationStages.PERSONAL]: Routes.APPLICATION_FORM_PERSONAL,
  [User.MembershipApplicationStages.QUALIFICATION]: Routes.APPLICATION_FORM_QUALIFICATION,
  [User.MembershipApplicationStages.EMPLOYMENT]: Routes.APPLICATION_FORM_EMPLOYMENT,
  [User.MembershipApplicationStages.DONATION]: Routes.APPLICATION_FORM_DONATIONS,
  [User.MembershipApplicationStages.ATTESTATION]: Routes.APPLICATION_FORM_ATTESTATION,
  [Checkout.CheckoutStep.ADDRESS]: Routes.APPLICATION_FORM_ATTESTATION,
  [User.MembershipApplicationStages.CREDENTIALS]: Routes.CREDENTIALS_APPLICATION_FORM,
};

export const CimaApplicationProgressRoutesMap: { [key: string]: Routes } = {
  [User.MembershipApplicationStages.PACKAGE_BUILDER]: Routes.CIMA_MEMBERSHIP_PACKAGE,
  [User.MembershipApplicationStages.APPLICATION]: Routes.CIMA_MEMBERSHIP_APPLICATION,
  [User.MembershipApplicationStages.START]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_START,
  [User.MembershipApplicationStages.PERSONAL]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL,
  [User.MembershipApplicationStages.CPA]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_CPA,
  [User.MembershipApplicationStages.EMPLOYMENT]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_EMPLOYMENT,
  [User.MembershipApplicationStages.TRAINING]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_TRAINING,
  [User.MembershipApplicationStages.DOCUMENT]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DOCUMENT,
  [User.MembershipApplicationStages.DOCUMENT_GATEWAY]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_GATEWAY_DOCUMENT,
  [User.MembershipApplicationStages.DONATION]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS,
  [User.MembershipApplicationStages.ATTESTATION]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION,
  [Checkout.CheckoutStep.ADDRESS]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION,
  [User.MembershipApplicationStages.CREDENTIALS]: Routes.CREDENTIALS_APPLICATION_FORM,
};

export const CenterMembershipApplicationProgressRoutesMap: { [key: string]: Routes } = {
  [User.MembershipApplicationStages.PACKAGE_BUILDER]: Routes.CENTER_MEMBERSHIP_FORM,
  [User.MembershipApplicationStages.APPLICATION]: Routes.CENTER_MEMBERSHIP_APPLICATION_FORM,
  [User.MembershipApplicationStages.START]: Routes.CENTER_MEMBERSHIP_FORM_START,
  [User.MembershipApplicationStages.ADMIN]: Routes.CENTER_MEMBERSHIP_FORM_ADMIN,
  [User.MembershipApplicationStages.ORGANIZATION]: Routes.CENTER_MEMBERSHIP_FORM_FIRM,
  [User.MembershipApplicationStages.ATTESTATION]: Routes.CENTER_MEMBERSHIP_FORM_ATTESTATION,
};

export const CimaMipApplicationProgressRoutesMap: { [key: string]: Routes } = {
  [User.MembershipApplicationStages.APPLICATION]: Routes.CIMA_MIP_APPLICATION_MAIN_PAGE,
  [User.MembershipApplicationStages.ATTESTATION]: Routes.CIMA_MIP_CHECKOUT_ATTESTATION,
  [User.MembershipApplicationStages.SUMMARY]: Routes.CIMA_MIP_CHECKOUT_MIP_DETAILS,
  [User.MembershipApplicationStages.ADDRESS]: Routes.CIMA_MIP_CHECKOUT_ADDRESS,
  [User.MembershipApplicationStages.PAYMENT]: Routes.CIMA_MIP_CHECKOUT_PAYMENT,
};

export const CimaFlpApplicationProgressRoutesMap: { [key: string]: Routes } = {
  [User.MembershipApplicationStages.PACKAGE_BUILDER]: Routes.CIMA_MEMBERSHIP_PACKAGE,
  [User.MembershipApplicationStages.PERSONAL]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL,
  [User.MembershipApplicationStages.EMPLOYMENT]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_EMPLOYMENT,
  [User.MembershipApplicationStages.DONATION]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS,
  [User.MembershipApplicationStages.ATTESTATION]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION,
  [Checkout.CheckoutStep.ADDRESS]: Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION,
  [User.MembershipApplicationStages.CREDENTIALS]: Routes.CREDENTIALS_APPLICATION_FORM,
};

export const MembershipApplicationProgressRouteMaps = {
  [Product.MembershipApplicationType.AICPA]: ApplicationProgressRoutesMap,
  [Product.MembershipApplicationType.CIMA]: CimaApplicationProgressRoutesMap,
  [Product.MembershipApplicationType.AICPA_CENTER_MEMBERSHIP]: CenterMembershipApplicationProgressRoutesMap,
  [Product.MembershipApplicationType.CIMA_MIP]: CimaMipApplicationProgressRoutesMap,
  [Product.MembershipApplicationType.CIMA_FLP]: CimaFlpApplicationProgressRoutesMap,
};

export const getApplicationProgressRoute = (
  applicationStage: User.MembershipApplicationStages,
  membershipApplicationType: Product.MembershipApplicationType = Product.MembershipApplicationType.AICPA,
  forceCimaURL?: boolean
) => {
  if (forceCimaURL && membershipApplicationType === Product.MembershipApplicationType.CIMA) {
    return MembershipApplicationProgressRouteMaps[Product.MembershipApplicationType.CIMA][applicationStage];
  }
  return MembershipApplicationProgressRouteMaps[membershipApplicationType][applicationStage];
};

export const currentStatusOptions = [
  {
    key: '0',
    text: 'In school',
    value: 'In school',
  },
  {
    key: '1',
    text: 'At college',
    value: 'At college',
  },
  {
    key: '2',
    text: 'At university (Undergrad)',
    value: 'At university (Undergrad)',
  },
  {
    key: '3',
    text: 'At university (Postgrad)',
    value: 'At university (Postgrad)',
  },
  {
    key: '4',
    text: 'In employment',
    value: 'In employment',
  },
  {
    key: '5',
    text: 'Looking for employment',
    value: 'Looking for employment',
  },
  {
    key: '6',
    text: 'Student of another professional body',
    value: 'Student of another professional body',
  },
  {
    key: '7',
    text: 'Member of another professional body',
    value: 'Member of another professional body',
  },
];

export enum AdminTableType {
  INVOICES = 'invoices',
  INVOICE_ITEMS = 'invoiceItems',
}

export const productAggsPageTitles: { [key: string]: string } = {
  [Product.ProductSlug.COURSE]: 'Courses',
  [Product.ProductSlug.PUBLICATION]: 'Publications',
  [Product.ProductSlug.BUNDLE]: 'Bundles',
  [Product.ProductSlug.CERTIFICATE_PROGRAM]: 'Certificate Programs',
  [Product.ProductSlug.CONFERENCE]: 'Conferences',
  [Product.ProductSlug.WEBCAST]: 'Webcasts',
  [Product.ProductSlug.EXAM]: 'Exams',
};

export const functionalSpecialismList = [
  {
    text: 'Book-keeping/accounting',
    value: 'Book-keeping/accounting',
    check: false,
  },
  {
    text: 'Business planning/budgeting',
    value: 'Business planning/budgeting',
    check: false,
  },
  {
    text: 'Business process improvement',
    value: 'Business process improvement',
    check: false,
  },
  {
    text: 'Business tax planning/advice',
    value: 'Business tax planning/advice',
    check: false,
  },
  {
    text: 'Cash flow management/treasury',
    value: 'Cash flow management/treasury',
    check: false,
  },
  {
    text: 'Company secretarial matters',
    value: 'Company secretarial matters',
    check: false,
  },
  {
    text: 'Contribution/profit analysis',
    value: 'Contribution/profit analysis',
    check: false,
  },
  {
    text: 'Cost reduction',
    value: 'Cost reduction',
    check: false,
  },
  {
    text: 'Costing/accounting systems',
    value: 'Costing/accounting systems',
    check: false,
  },
  {
    text: 'TCSP',
    value: 'TCSP',
    check: false,
  },
  {
    text: 'External financial reporting',
    value: 'External financial reporting',
    check: false,
  },
  {
    text: 'Funding to businesses',
    value: 'Funding to businesses',
    check: false,
  },
  {
    text: 'Interim management',
    value: 'Interim management',
    check: false,
  },
  {
    text: 'Internal audit/risk analysis',
    value: 'Internal audit/risk analysis',
    check: false,
  },
  {
    text: 'Management performance reports',
    value: 'Management performance reports',
    check: false,
  },
  {
    text: 'Payroll/NI/PAYE administration',
    value: 'Payroll/NI/PAYE administration',
    check: false,
  },
  {
    text: 'Personal tax planning/advice',
    value: 'Personal tax planning/advice',
    check: false,
  },
  {
    text: 'Other',
    value: 'Other',
    check: false,
  },
];

export const CimaEducationalConsent = {
  label: 'Share my personal data with my educational institution',
  infoLabel:
    'I understand that my personal data will be shared for the purposes of helping my educational institution support my progress/membership or, where agreed, pay fees on my behalf. The personal data shared may include contact information such as name, email, address, or contact ID, as well as membership and exam information including exam results, exemptions information, progression information, membership status and details of outstanding fees/account balances.',
};

export const ApplicationPartAffiliateReasons = [
  {
    label: 'I am an owner or a professional staff member of a CPA accounting firm.',
    value: 'I am an owner or a professional staff member of a CPA accounting firm',
  },
  {
    label: 'I am a full-time business or accounting educator.',
    value: 'I am a full-time business or accounting educator.',
  },
  {
    label: 'I am a corporate or government finance professional.',
    value: 'I am a corporate or government finance professional.',
  },
  {
    label: 'I have management or governance responsibilities.',
    value: 'I have management or governance responsibilities.',
  },
  { label: 'I am eligible to obtain AICPA credential.', value: 'I am eligible to obtain AICPA credential.' },
  {
    label: 'I do not meet any of the above but agree to be governed and complied with AICPA by law.',
    value: 'I do not meet any of the above but agree to be governed and complied with AICPA by law.',
  },
];

export enum CimaMipApplicationFormList {
  CONTACT_DETAILS = 'Contact details',
  WORK_HISTORY = 'Work history',
  PRACTICE_REQUIREMENTS = 'Practice requirements',
  AML_RETURN = 'Anti-Money Laundering (AML) return',
}

export enum CimaMipMainApplicationHeading {
  MIP_HEADING_NEW = 'Member in practice application',
  MIP_HEADING_RENEWAL = 'Member in practice renewal application',
}

export enum CimaMipMainApplicationSubHeading {
  MIP_SUBHEADING_NEW = 'Status of MiP application',
  MIP_SUBHEADING_RENEWAL = 'Renewing member in practice status',
}

export enum CimaMipMainApplicationFormInstructions {
  MIP_FORM_INSTRUCTIONS_NEW = 'To achieve this status, you must provide all the required documents (listed below). Ensure they are completed in full. Your application form and supporting documents will be carefully reviewed. Please note: You could be contacted for further information to support your application. ',
  MIP_FORM_INSTRUCTIONS_RENEWAL = 'To qualify as a CIMA Member in Practice, you are required to provide the referenced documents below and add additional details in the forms provided. Once you have uploaded all documents, you will be asked to submit your application, which will then be reviewed by a CIMA Member in Practice.',
}

export enum ExemptionCardType {
  GATEWAY = 'Gateway',
  EXEMPTION = 'Exemption',
}

export enum BulkRenewalCheckBoxType {
  HONORARY = 'Honorary Membership',
  STUDENT = 'Student Membership',
  I_HAVE_DOWNLOADED = 'I have downloaded and reviewed this report',
  USE_DATE_QUERY = 'Process members with ‘Active Status’',
}

export enum BulkRenewalMembershipType {
  HONORARY = 'honorary',
  STUDENT = 'student',
  HONORARY_AND_STUDENT = 'honorary and student',
}

export enum BulkRenewalAndReportActions {
  DOWNLOAD = 'download',
  RENEWAL = 'renewal',
}

export enum BulkRenewalAndReportStatus {
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed',
  RENEWAL_FAILED = 'failed',
  RENEWAL_COMPLETED_WITH_ERROR = 'renewal completed with error',
  RENEWAL_COMPLETED = 'renewal completed',
  COMPLETE_BUT_HAVE_FAILED = 'completed but have failed',
}

export enum BulkRenewalTableStatus {
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  COMPLETE_WITH_ERRORS = 'Complete with errors',
  FAILED = 'Failed',
}

export enum BulkRenewalReportBannerMessage {
  GENERATION_IN_PROGRESS = 'This report is being generated. Once this is complete you will see a download link on this page. Please download and review it.',
  GENERATION_SUCCESSFUL = 'Your report has been generated. Please click download link above to review the report.',
  BULK_RENEWAL_ERROR = 'Bulk renew of honorary selected memberships has failed. Please try this process again.',
  PRESIGNEDURL_ERROR = 'Download report of selected memberships has failed. Please try this process again.',
  BULK_RENEWAL_IN_PROGRESS = 'Bulk renew of honorary and/or student memberships is currently in progress. You will receive an email confirmation once this is complete.',
}

export enum BulkRenewalInstructions {
  GENERATE_NEW_REPORT = `After making your selections, click 'Generate new report' below to download a file with the list of memberships ready for bulk renew.`,
  CLICK_BULK_RENEW = `Click the 'Bulk Renew' button below to start the process. Please make sure you have selected all necessary membership types as this process may take some time and you will not be able to trigger another bulk renew until the initial process is complete.`,
}

// add here if there are other possible status to show PER banner once Strategic level got unlock in Chinese PQ.
export enum BookStatus {
  ACTIVE = 'Active',
  FAILED = 'Failed',
  BOOKABLE = 'Bookable',
  COMPLETED = 'Completed',
  EXEMPTED = 'Exempted',
}

export const CurrentJourney = {
  CREDENTIALS: 'credentials',
  SECTIONS: 'sections',
};

export enum AppPersonalFormErrorMessage {
  PHONE_ERROR_MESSAGE = 'Please enter a valid phone number. (Digits only, no punctuation is needed).',
  BIRTHDATE_ERROR_MESSAGE = 'Your date of birth does not meet the eligibility requirements. Please check the eligibility criteria.',
}

export enum AppCpaLicenseFormErrorMessage {
  LICENSE_CERTIFICATE_ERROR_MESSAGE = 'Please enter a valid License/certificate number.',
  ISSUE_DATE_ERROR_MESSAGE = 'please enter a valid issue date',
}

export enum EmploymentFormErrorMessage {
  BUSINESS_NAME_ERROR_MESSAGE = 'The business name should be a minimum of three characters.',
  ROLE_ERROR_MESSAGE = 'Please select a role from the dropdown menu in order to continue.',
  PHONE_ERROR_MESSAGE = 'Please enter a valid phone number consisting of digits only.',
}

export enum PageExpReqSkillActivityType {
  ACTIVITIES = 'activities',
  SKILLS = 'skills',
}

export enum RenewMembershipOptions {
  ACCOUNT_TYPE = 'accountType',
  ADD_ONS = 'addOns',
  MEMBERSHIP_SECTIONS = 'membershipSections',
  MEMBERSHIP_CREDENTIALS = 'membershipCredentials',
  SERVICE_TIER = 'serviceTier',
}

// Character limit for Benefit Sub Block Headers to prevent overflowing text.
export const BenefitHeaderCharacterLimit = 26;

export const apprenticeFileUploadDataList = [
  {
    fileNameId: 1,
    fileName: 'Graduation Certificate',
    fileNameHeader: 'Upload your Graduation Certificate',
    fileDescription: `Show graduation Certificate/recent letter confirming completion of your degree.<br />
Upload your file in the box below:`,
  },
  {
    fileNameId: 2,
    fileName: 'Academic Module Transcripts',
    fileNameHeader: 'Upload your Academic Module Transcripts',
    fileDescription: `Show academic module transcripts/mark sheets for all years of your degree programme.<br />
Upload your file in the box below:`,
  },
  {
    fileNameId: 3,
    fileName: 'Proof of University Registration',
    fileNameHeader: 'Upload your University Registration',
    fileDescription: `Show proof of university registration (conditional exemptions only).<br />
Upload your file in the box below:`,
  },
  {
    fileNameId: 4,
    fileName: 'Maths and English GCSE/Level 2 certificates',
    fileNameHeader: 'Upload your Maths and English GCSE/Level 2 certificates',
    fileDescription: `Show proof of Maths and English GCSE/Level 2 certificates.<br />
Upload your file in the box below:`,
  },
];

export const fileUploadDataList = [
  {
    fileNameId: 1,
    fileName: 'Graduation Certificate',
    fileNameHeader: 'Graduation Certificate',
    fileDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    fileTemplateUrl: '/root',
    fileTemplateDescription: 'a',
  },
];
