import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { ContractLineItemActionNames } from './constants';
import { Salesforce, Orders } from 'mxp-schemas';
import request from 'utils/GraphQLClient';
import { CREATE_CONTRACT_LINE_ITEM, DELETE_CONTRACT_LINE_ITEM } from 'mxp-graphql-queries';

// ------------------------------------
// Actions
// ------------------------------------

export const selectBenefit: any = createAction(
  ContractLineItemActionNames.CREATE_CONTRACT_LINE_ITEM,
  (data: Salesforce.ContractLineItem[], membershipBody: string, subscriptionData: Orders.SubscriptionItems) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(setLoading(true));
      return request(CREATE_CONTRACT_LINE_ITEM, { data, membershipBody, subscriptionData }).then((response: any) => {
        return response.selectBenefit;
      });
    }
);

export const unselectBenefit: any = createAction(
  ContractLineItemActionNames.DELETE_CONTRACT_LINE_ITEM,
  (ids: string[], subscriptionData: Orders.SubscriptionItems) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setLoading(true));
    return request(DELETE_CONTRACT_LINE_ITEM, { ids, subscriptionData }).then((response: any) => {
      return response.unselectBenefit;
    });
  }
);

export const setLoading: any = createAction(ContractLineItemActionNames.SET_LOADING, (loading: boolean) => () => {
  return loading;
});

export const setError: any = createAction(
  ContractLineItemActionNames.SET_ERROR,
  (error: CustomErrors.GraphQLError) => () => {
    return error;
  }
);
