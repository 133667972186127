import React from 'react';
import { Container } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import { Link, LinkEnums } from 'components/atoms';
import { TwoColumn } from 'components/templates/TwoColumn/TwoColumn';
import { Routes } from 'constants/index';
import { getPath, isServer } from 'utils';
import { FOOTER_EVENT, PREFIXES, handleEvent } from 'utils/Analytics';
import { headerAndFooterEventPayload } from 'utils/Analytics/helpers';
import { store } from '../../../store';
import { getLocation } from 'connected-react-router';

interface Props {
  setBurger?: (state: boolean) => void;
  className?: string;
}

export const Footer = React.memo<Props>(({ setBurger, className }) => {
  const state: State.Root = store?.getState();
  const { pathname } = getLocation(state);
  const prevRouteState = { state: { prevRoute: pathname } };

  // footer event handling
  const handleFooterEvent = (route: Routes) => (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isServer) return;
    const textValue = (event?.target as HTMLButtonElement)?.innerText;
    const sanitizedText = textValue.replace('|', '');
    const payload = headerAndFooterEventPayload(PREFIXES.FOOTER, route, sanitizedText);
    handleEvent(payload, FOOTER_EVENT);
    closeMobileMenu();
  };

  const closeMobileMenu = () => {
    if (setBurger) setBurger(false);
  };

  return (
    <FooterContainer className={className}>
      <Container>
        <StyledTwoColumn>
          <StyleFooterText data-testid="footer-text">
            This site is brought to you by the Association of International Certified Professional Accountants, the
            global voice of the accounting and finance profession, founded by the American Institute of CPAs and The
            Chartered Institute of Management Accountants.
          </StyleFooterText>
          <LinkWrapper>
            <LinkContainer>
              {
                <>
                  <StyledLink
                    type={LinkEnums.type.standaloneLinkOnDarkBg}
                    to={{ pathname: getPath(Routes.ABOUT), ...prevRouteState }}
                    testId="footer-about"
                    overrideAnalytics={true}
                    onClick={handleFooterEvent(Routes.ABOUT)}
                  >
                    About
                    <StyledLinkBreak>|</StyledLinkBreak>
                  </StyledLink>
                  <StyledLink
                    type={LinkEnums.type.standaloneLinkOnDarkBg}
                    to={{ pathname: getPath(Routes.TERMS_CONDITIONS), ...prevRouteState }}
                    testId="footer-terms-and-conditions"
                    overrideAnalytics={true}
                    onClick={handleFooterEvent(Routes.TERMS_CONDITIONS)}
                  >
                    Terms &amp; Conditions
                    <StyledLinkBreak>|</StyledLinkBreak>
                  </StyledLink>
                  <StyledLink
                    type={LinkEnums.type.standaloneLinkOnDarkBg}
                    to={{ pathname: getPath(Routes.WEBSITE_ACCESSIBILITY), ...prevRouteState }}
                    testId="footer-website-accessibility"
                    overrideAnalytics={true}
                    onClick={handleFooterEvent(Routes.WEBSITE_ACCESSIBILITY)}
                  >
                    Accessibility
                    <StyledLinkBreak>|</StyledLinkBreak>
                  </StyledLink>
                  <StyledLink
                    type={LinkEnums.type.standaloneLinkOnDarkBg}
                    to={{ pathname: getPath(Routes.PRIVACY_POLICY), ...prevRouteState }}
                    testId="footer-privacy"
                    overrideAnalytics={true}
                    onClick={handleFooterEvent(Routes.PRIVACY_POLICY)}
                    target={'_blank'}
                  >
                    Privacy Policy
                    <StyledLinkBreak>|</StyledLinkBreak>
                  </StyledLink>
                  <StyledLink
                    type={LinkEnums.type.standaloneLinkOnDarkBg}
                    to={{ pathname: getPath(Routes.CONTACT_US), ...prevRouteState }}
                    testId="footer-contact"
                    overrideAnalytics={true}
                    onClick={handleFooterEvent(Routes.CONTACT_US)}
                  >
                    Contact
                    <StyledLinkBreak>|</StyledLinkBreak>
                  </StyledLink>
                  <StyledLink
                    type={LinkEnums.type.standaloneLinkOnDarkBg}
                    to={{ pathname: getPath(Routes.HELP), ...prevRouteState }}
                    testId="footer-help"
                    overrideAnalytics={true}
                    onClick={handleFooterEvent(Routes.HELP)}
                  >
                    Help
                    <StyledLinkBreak>|</StyledLinkBreak>
                  </StyledLink>
                  <StyledLink
                    type={LinkEnums.type.standaloneLinkOnDarkBg}
                    to={{ pathname: getPath(Routes.SITE_MAP), ...prevRouteState }}
                    testId="footer-site-map"
                    overrideAnalytics={true}
                    onClick={handleFooterEvent(Routes.SITE_MAP)}
                  >
                    Site Map
                  </StyledLink>
                </>
              }
            </LinkContainer>

            <h3>
              <StyledLink
                type={LinkEnums.type.standaloneLinkOnDarkBg}
                to={{ pathname: getPath(Routes.PRIVACY_CALIFORNIA_FORM), ...prevRouteState }}
                testId="footer-california-law"
                onClick={handleFooterEvent(Routes.PRIVACY_CALIFORNIA_FORM)}
              >
                CA Do Not Sell or Share My Personal Information
              </StyledLink>
            </h3>
          </LinkWrapper>
        </StyledTwoColumn>
      </Container>
    </FooterContainer>
  );
});

const StyledTwoColumn = styled(TwoColumn)`
  &&&&& > .column:nth-child(2) {
    padding-left: 0 !important;
    ${props => props.theme.mediaQueries.mobileMaxOnly} {
      padding-right: ${props => props.theme.pxToRem(70)} !important;
    }
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  text-decoration: none;
`;

const StyleFooterText = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralWhite};
`;

const FooterContainer = styled.div`
  flex-shrink: 0;
  width: 100%;
  padding: 1.25rem 0;
  margin-top: auto;
  background: ${props => props.theme.colors.neutralGrey8};
  flex-shrink: 0;
`;

const LinkWrapper = styled.div`
  position: relative;
  height: 100%;
  h3 {
    margin-top: 5px;
    a {
      font-size: ${props => props.theme.pxToRem(14)};
      text-decoration: none;
      font-weight: bold;
    }
  }
`;

const LinkContainer = styled.div`
  right: 0;
  /* ${props => props.theme.mediaQueries.desktopOnly} {
    position: absolute;
    bottom: 0;
  } */
`;

const StyledLinkBreak = styled.span`
  color: ${props => props.theme.colors.neutralWhite};
  opacity: 0.2;
  padding: 0 8px;
`;
