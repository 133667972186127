import React from 'react';
import styled from 'styled-components';

export const ResultCounter: React.FC<{ count: number; className: string; boldedText: string; normalText: string }> = ({
  className,
  count,
  boldedText,
  normalText,
}) => (
  <StyledContainer className={className}>
    <p>
      <BoldText>
        {count} {boldedText}
      </BoldText>
      &nbsp;
      {normalText}
    </p>
  </StyledContainer>
);

const StyledContainer = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const BoldText = styled.span`
  font-weight: ${props => props.theme.fontWeights.medium};
`;
