import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { EmailInviteForm } from 'components/molecules/Form/EmailInviteForm';
import {
  addEmailToSeatManagementEmailsList,
  removeEmailFromSeatManagementEmailsList,
  hideSeatManagementEmailInviteModal,
  allocateSeats,
} from 'modules/clientAdmin/actions';
import {
  seatManagementListErrorsWarningsSelector,
  seatManagementLimitSelector,
  seatManagementInviteModalVisible,
  seatManagementContractToAssign,
  seatManagementAssignLoading,
  seatManagementAssignmentResultsExistSelector,
  seatManagementAssignErrorSelector,
} from 'modules/clientAdmin/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  addEmailToList(email: string): void {
    dispatch(addEmailToSeatManagementEmailsList(email));
  },
  removeEmailFromList(index: number): void {
    dispatch(removeEmailFromSeatManagementEmailsList(index));
  },
  hide(clearModule: boolean): void {
    dispatch(hideSeatManagementEmailInviteModal(clearModule));
  },
  sendEmails(serviceContractsIds: string[], endUserEmails: string[]): void {
    dispatch(allocateSeats(serviceContractsIds, endUserEmails));
  },
});

const mapStateToProps = (state: State.Root) => ({
  ...seatManagementListErrorsWarningsSelector(state),
  limit: seatManagementLimitSelector(state),
  visible: seatManagementInviteModalVisible(state),
  serviceContractToAssign: seatManagementContractToAssign(state),
  seatAssignLoading: seatManagementAssignLoading(state),
  seatSuccessfullyAssigned: seatManagementAssignmentResultsExistSelector(state),
  seatManagementAssignError: seatManagementAssignErrorSelector(state),
});

export const EmailInviteFormContainer = connect(mapStateToProps, mapActionCreators)(EmailInviteForm);
