import React from 'react';
import styled from 'styled-components';

interface BenefitCardProps {
  testId: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  to?: string;
}

export const BenefitCard: React.FC<BenefitCardProps> = ({ testId, imageUrl, title, description }) => {
  return (
    <Container data-testid={testId}>
      <StyledCard>
        <StyledHeader>
          {imageUrl && <StyledImage src={imageUrl} alt="icon" />}
          {title}
        </StyledHeader>
        <StyledDescription>{description}</StyledDescription>
      </StyledCard>
      <StyledBottom />
    </Container>
  );
};

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  position: relative;
  border-radius: ${props => props.theme.pxToRem(10)};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(10)} 0
    ${props => props.theme.colors.neutralGrey3};
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.theme.pxToRem(360)};
  min-height: ${props => props.theme.pxToRem(164)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    height: auto;
    margin-bottom: ${props => props.theme.pxToRem(15)};
    text-overflow: ellipsis;
  }
`;

const StyledCard = styled.div`
  border-radius: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(4)} 0 0`};
  width: auto;
  min-height: ${props => props.theme.pxToRem(164)};
  margin: ${props => `0 ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} 0`};
  padding: ${props => `${props.theme.pxToRem(36)} 0 0`};
  ${props => props.theme.mediaQueries.mobileOnly} {
    border-radius: ${props => `${props.theme.pxToRem(10)} ${props.theme.pxToRem(10)} 0 0`};
    width: auto;
    height: auto;
    padding: ${props => `${props.theme.pxToRem(36)} ${props.theme.pxToRem(18)} 0`};
  }
`;

const StyledBottom = styled.div`
  width: auto;
  height: ${props => props.theme.pxToRem(8)};
  border-radius: ${props => `0 0 ${props.theme.pxToRem(8)} ${props.theme.pxToRem(8)}`};
  background-image: linear-gradient(to right, ${props => props.theme.colors.primaryPurple} 3%, rgba(154, 32, 94, 0.8));
  margin: ${props => `${props.theme.pxToRem(-20)} 0 ${props.theme.pxToRem(50)} 0`};
`;

const StyledImage = styled.img`
  display: inline-block;
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  margin-right: ${props => props.theme.pxToRem(10)};
  vertical-align: middle;
`;

const StyledDescription = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  padding-left: ${props => props.theme.pxToRem(26)};
  font-weight: ${props => props.theme.fontWeights.light};
  padding-bottom: ${props => props.theme.pxToRem(15)};
`;

const StyledHeader = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  margin: ${props => `0 0 ${props.theme.pxToRem(12)} ${props.theme.pxToRem(26)}`};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  padding-bottom: ${props => props.theme.pxToRem(10)};
`;
