import { MembershipTypes, User } from 'mxp-schemas';
import PassedIcon from 'resources/images/icon-live-ic-done.svg';
import FailedIcon from 'resources/images/icon-live-ic-clear.svg';
import NoShowIcon from 'resources/images/icon-live-ic-event-busy.svg';

export const getExamResultStatusIcon = (status: string) => {
  switch (status) {
    case User.StudentExamResultStatus.PASS:
      return PassedIcon;
    case User.StudentExamResultStatus.FAIL:
      return FailedIcon;
    case User.StudentExamResultStatus.NO_SHOW:
      return NoShowIcon;
    case User.StudentExamResultStatus.NDA_REFUSED:
      return NoShowIcon;
    default:
      return NoShowIcon;
  }
};

export const isExamScoreShown = (examResult: User.Exams, learningPathway: any) => {
  const isApprenticeL4EPA1 = Boolean(
    learningPathway === MembershipTypes.Pathway.APPRENTICE_L4 && examResult.epaDetails.isEpa1
  );
  const isApprenticeEPA2 =
    (examResult?.subjectName && examResult?.subjectName.includes('EPA2')) ||
    examResult?.epaDetails?.isEpa2 ||
    (examResult?.examName && examResult?.examName.includes('EPA2'));
  return Boolean(!isApprenticeL4EPA1 && !isApprenticeEPA2);
};

export const isDistinction = (examResult: User.StudentExamSectionResults) => {
  return Boolean(examResult.distinction && examResult.epaDetails.isEpa1);
};
