import React from 'react';
import styled from 'styled-components';
import { Button, ButtonEnums, Modal, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';

interface Props {
  isOnboardingFinishedModalOpen: boolean;
  closeModal: () => void;
  goToFeed: () => void;
}

export const ModalOnboardingFinish: React.FC<Props> = ({ goToFeed, closeModal, isOnboardingFinishedModalOpen }) => (
  <StyledModal
    size="tiny"
    icon={<StyledIconUnsuccessful />}
    heading="Your personalised homepage is ready"
    open={isOnboardingFinishedModalOpen}
    testId="onboarding-finish"
    cancelNode={
      <>
        <OnlyDesktopStyled>
          <FeedButton action={goToFeed} />
        </OnlyDesktopStyled>
        <OnlyMobileStyled>
          <ReturnButton action={closeModal} />
        </OnlyMobileStyled>
      </>
    }
    confirmNode={
      <>
        <OnlyDesktopStyled>
          <ReturnButton action={closeModal} />
        </OnlyDesktopStyled>
        <OnlyMobile>
          <FeedButton action={goToFeed} />
        </OnlyMobile>
      </>
    }
  >
    <CenteredText>What would you like to do next?</CenteredText>
  </StyledModal>
);

interface ModalButtonProps {
  action: () => void;
}
const ReturnButton: React.FC<ModalButtonProps> = ({ action }) => (
  <Button
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    bordered
    onClick={action}
    testId="return"
  >
    Return to where I left off
  </Button>
);

const FeedButton: React.FC<ModalButtonProps> = ({ action }) => (
  <Button size={ButtonEnums.sizes.small} variant={ButtonEnums.variants.secondary} onClick={action} testId="go-home">
    View my homepage
  </Button>
);

const StyledIconUnsuccessful = styled(Checkmark)`
  &&& {
    g {
      use {
        fill: ${props => props.theme.colors.interfaceGreen};
      }
    }
  }
`;

const OnlyDesktopStyled = styled(OnlyDesktop)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const OnlyMobileStyled = styled(OnlyMobile)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }

      button {
      width: 170px;
        ${props => props.theme.mediaQueries.mobileOnly} {
          width: 100%;
        }
    }
  }
`;

const CenteredText = styled.div`
  text-align: center;
`;
