import styled from 'styled-components';
import { Responsive } from 'semantic-ui-react';
import React from 'react';
import { isServer } from 'utils';

// Don't use (deprecated)
export const OnlyMobile = (props: any) => (
  <Responsive minWidth={Responsive.onlyMobile.minWidth} maxWidth={Responsive.onlyComputer.minWidth} {...props} />
);
export const OnlyDesktop = (props: any) => (
  <Responsive minWidth={isServer ? Responsive.onlyMobile.minWidth : Responsive.onlyComputer.minWidth} {...props} />
);

// FIX to use with SSR (deprecated)
export const OnlyMobileSafe = (props: any) => (
  <div>
    <Responsive minWidth={Responsive.onlyMobile.minWidth} maxWidth={Responsive.onlyComputer.minWidth} {...props} />
  </div>
);

export const OnlyDesktopSafe = (props: any) => (
  <div>
    <Responsive minWidth={isServer ? 0 : Responsive.onlyComputer.minWidth} {...props} />
  </div>
);

// Safe to use with SSR
export const OnlyMobileCSS = ({ children, as, ...props }: any) => (
  <StyledOnlyMobileCSS as={as || 'div'} {...props}>
    {children}
  </StyledOnlyMobileCSS>
);

const StyledOnlyMobileCSS = styled.div`
  &&&& {
    display: none;

    ${props => props.theme.mediaQueries.mobileOnly} {
      display: inherit;
    }
  }
`;

// Safe to use with SSR
export const OnlyDesktopCSS = ({ children, as, ...props }: any) => (
  <StyledOnlyDesktopCSS as={as || 'div'} {...props}>
    {children}
  </StyledOnlyDesktopCSS>
);

const StyledOnlyDesktopCSS = styled.div`
  &&&& {
    display: inherit;

    ${props => props.theme.mediaQueries.mobileOnly} {
      display: none;
    }
  }
`;
