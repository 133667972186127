import { Salesforce } from 'mxp-schemas';

export enum tuitionProviderActionNames {
  UPDATE_APPRENTICE_GATEWAY_DETAILS = 'tuitionProvider/UPDATE_APPRENTICE_GATEWAY_DETAILS',
  GET_PERSONAL_DETAILS = 'tuitionProvider/GET_PERSONAL_DETAILS',
  SET_LOADING = 'tuitionProvider/SET_LOADING',
  SET_UPDATE_LOADING = 'tuitionProvider/SET_UPDATE_LOADING',
  UPDATE_EMPLOYER_INFORMATION = 'tuitionProvider/UPDATE_EMPLOYER_INFORMATION',
  UPDATE_EMPLOYER_VALIDATOR = 'tuitionProvider/UPDATE_EMPLOYER_VALIDATOR',
  REPLACE_EMPLOYER_VALIDATOR = 'tuitionProvider/REPLACE_EMPLOYER_VALIDATOR',
  MY_TUITION_PROVIDER_EMAIL_NOTIFCATION = 'tuitionProvider/MY_TUITION_PROVIDER_EMAIL_NOTIFCATION',
}

export const initialState: State.TuitionProvider = {
  updateApprenticeGatewayDetailsSuccess: false,
  getPersonalDetailsSuccess: false,
  getStudentDetails: {
    employerDetails: {
      address: {
        addressLine1: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
      },
      id: '',
      name: 'ABC Company',
      phone: '',
      organizationType: '',
    },
    employerValidatorDetails: {
      businessAddress: {
        addressLine1: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
      },
      email: '',
      firstName: '',
      id: '',
      jobTitle: '',
      lastName: '',
      linkedInUrl: '',
      phone: '',
    },
    studentDetails: {
      address: {
        addressLine1: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
      },
      apprenticeGatewayDetails: {
        actualGatewayCompletionDate: '',
        apprenticeshipStatus: '',
      },
      birthDate: '',
      cimaContactId: '',
      email: {
        emailAddress: '',
        emailType: Salesforce.EmailType.PERSONAL,
        id: '',
        isPrimary: false,
      },
      ethnicity: '',
      firstName: '',
      gender: '',
      id: '',
      jobTitle: '',
      primaryEmployer: '',
      employerRelationshipType: '',
      lastName: '',
      middleName: '',
      price: '',
      title: '',
      type: '',
      personContactId: '',
    },
  },
  loading: false,
  updateLoading: false,
};
