import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router';
import { User, MembershipTypes, Product, Salesforce, PearsonVue as PvType, Checkout } from 'mxp-schemas';
import { studentProgressionSelector } from 'modules/user/selectors';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { ExamCard, EXAM_CARD_SIZE, PearsonVue } from 'components/atoms';
import { CimaFlpCompetencyCards } from 'components/molecules/CimaFlpCompetencyCards/CimaFlpCompetencyCards';
import moment from 'moment-timezone';
import { pearsonVueSSOLinkConfigSelector, constantsSelector } from 'modules/app/selectors';
import { openPersonVue } from 'modules/user/actions';
import { isLastMembershipLapsedSelector } from 'modules/membership/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { examBookingDate } from 'utils/dateHelper';
import { EXTERNAL_LINK_EVENT, NAV_CLICK, handleEvent } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';

interface Props {
  blocks: User.SubjectBlock[] | undefined;
  cardSize: EXAM_CARD_SIZE;
  handleOpenWaiveExamModal: () => void;
  onExamSelected: (examBlock: User.SubjectBlock) => void;
}

export const CimaRenderBlock: React.FC<Props> = ({ blocks, cardSize, handleOpenWaiveExamModal, onExamSelected }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const studentProgression = useSelector(studentProgressionSelector);
  const pearsonVueSSOLink: string = useSelector(pearsonVueSSOLinkConfigSelector);
  const isMembershipLapsed = useSelector(isLastMembershipLapsedSelector);
  const constants = useSelector(constantsSelector);

  const [ssoPV, setSsoPv] = useState(false);
  const [htmlText, setHtmlText] = useState<string>('');

  const gateway = studentProgression?.gatewayStatus;
  const EPA2Type = 'Documents';

  const formatExamCountryAddress = (testCenterAddress: Checkout.Address | null | undefined): string => {
    if (testCenterAddress) {
      return `${testCenterAddress.state ? `${testCenterAddress.state}, ` : ''}
      ${testCenterAddress?.country}`;
    }

    return '';
  };

  const getRemainingHours = (examDate: string): number => parseInt(`${moment(examDate).diff(moment(), 'hours')}`, 10);
  const scheduleAndCancelPeriod: number = 48;

  const isBookableOrWaivedAndNotComplete = (exam: User.SubjectBlock): boolean => {
    return (
      exam.overrideStatus === PvType.ExamResultOverrideStatus.voided ||
      exam.status === User.SubjectBlockStatus.Bookable ||
      (exam.status === User.SubjectBlockStatus.Waived &&
        exam.resultStatus !== User.ResultStatus.Complete &&
        !exam.appointmentDetails)
    );
  };

  return (
    <React.Fragment>
      {blocks?.map(exam => {
        const examCardTitle: string = exam.subjectName ?? (exam.examUrlSlug as string).replace(/-/g, ' ');
        const isGatewayNotPassed = Boolean(gateway?.status !== User.GatewayStatus.Passed);

        const handleClickEvent = (destination?: any, event?: any) => {
          const { pageName, siteSection, windowPathname } = getPageNameAndSiteSection();
          const buttonText = event?.target?.innerText || event?.target?.textContent || '';

          if (destination && destination.includes('http')) {
            handleEvent({ text: `button:link:ext::${buttonText}`, href: destination, buttonText }, EXTERNAL_LINK_EVENT);
            return;
          }
          handleEvent(
            {
              clickValue: `button:link:int::${buttonText}:${examCardTitle}:${destination || windowPathname}`,
              pageName,
              siteSection,
            },
            NAV_CLICK
          );
        };

        const handleTakeOnlineExamClick = async (event?: any) => {
          const { getPVSession, success } = await OpenPvDashboard(
            PvType.SSO_ACTIONS.VIEW_APPOINTMENT_ACTIVITY_FOR_REGISTRATION_ID,
            exam.appointmentDetails?.pvBookingId,
            dispatch
          );

          setSsoPv(success);
          setHtmlText(getPVSession);
          handleClickEvent(pearsonVueSSOLink, event);
        };

        const handleRescheduleClick = async (event?: any) => {
          const { getPVSession, success } = await OpenPvDashboard(
            PvType.SSO_ACTIONS.RESCHEDULE_EXAM,
            exam.appointmentDetails?.pvBookingId,
            dispatch
          );
          setSsoPv(success);
          setHtmlText(getPVSession);
          handleClickEvent(pearsonVueSSOLink, event);
        };

        const handleCancelExamClick = async (event?: any) => {
          const { getPVSession, success } = await OpenPvDashboard(
            PvType.SSO_ACTIONS.CANCEL_EXAM,
            exam.appointmentDetails?.pvBookingId,
            dispatch
          );
          setSsoPv(success);
          setHtmlText(getPVSession);
          handleClickEvent(pearsonVueSSOLink, event);
        };

        const handleViewResultsClick = async (event?: any) => {
          const destinationPath = generatePath(getPath(Routes.CIMA_EXAM_RESULTS), {
            slug: exam.examUrlSlug,
          });
          handleClickEvent(destinationPath, event);
          history.push(destinationPath);
        };

        const handleWaiveExemptionClick = (selectedExam: User.SubjectBlock, event?: any) => {
          handleOpenWaiveExamModal();
          onExamSelected(selectedExam);
          handleClickEvent(null, event);
        };

        const handleBookNowClick = (event?: any) => {
          const destinationPath = getPath(Routes.CIMA_EXAM_BOOK_PERSONAL_DETAILS);
          handleClickEvent(destinationPath, event);
          history.push(destinationPath);
        };

        const handleAccessNowClick = (event?: any) => {
          const apprenticeshipSummaryRoute =
            exam.pathway === MembershipTypes.Pathway.APPRENTICE_L4
              ? Routes.CIMA_EXAMS_DASHBOARD_EPA2L4
              : Routes.CIMA_EXAMS_DASHBOARD_EPA2L7;

          const redirectUrl =
            exam.pathway === MembershipTypes.Pathway.APPRENTICE_L4
              ? constants?.[CONSTANTS.CIMA_EXAMS_DASHBOARD.EPA_4]
              : constants?.[CONSTANTS.CIMA_EXAMS_DASHBOARD.EPA_7];

          const destinationPath = getPath(apprenticeshipSummaryRoute);
          handleClickEvent(isGatewayNotPassed ? redirectUrl : destinationPath, event);

          if (isGatewayNotPassed) {
            window.open(redirectUrl);
          } else {
            history.push(destinationPath);
          }
        };

        const handleLearnMoreClick = (event?: any) => {
          const redirectUrl =
            exam.pathway === MembershipTypes.Pathway.APPRENTICE_L4
              ? constants?.[CONSTANTS.CIMA_EXAMS_DASHBOARD.EPA_4]
              : constants?.[CONSTANTS.CIMA_EXAMS_DASHBOARD.EPA_7];

          handleClickEvent(redirectUrl, event);
          if (redirectUrl) {
            window.open(redirectUrl);
          }
        };

        const handleBuyExamCreditsClick = (event?: any) => {
          if (Boolean(exam.productId)) {
            const destinationPath = generatePath(getPath(Routes.PRODUCT_PAGE), {
              productTypeSlug: Product.ProductSlug.EXAM_CREDIT,
              slug: exam.productId,
            });
            handleClickEvent(destinationPath, event);
            history.push(destinationPath);
          }
        };

        if (exam.status === User.SubjectBlockStatus.Exempted) {
          let props;
          if (exam.type === User.SubjectBlockType.FLP) {
            props = {
              flpId: exam.id,
              description: exam.name,
              flpCompetencyModuleList: exam?.flpCompetency ?? [],
              isExempted: !isMembershipLapsed,
              handleWaiveExemptionClick: (event?: any) => {
                handleWaiveExemptionClick(exam, event);
              },
              blockStatus: exam.status,
              isNonWaivable: exam.isNonWaivable,
            };
            return (
              <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
                <CimaFlpCompetencyCards {...props} />
              </StyledExamFlexItem>
            );
          }

          props = {
            type: exam.type,
            subjectTitle: examCardTitle,
            status: MembershipTypes.CimaExamCardStatus.EXEMPT,
            cardSize: EXAM_CARD_SIZE.SMALL,
            overrideStatus: exam.overrideStatus,
            handleWaiveExemptionClick: (event?: any) => {
              handleWaiveExemptionClick(exam, event);
            },
            isNonWaivable: exam.isNonWaivable,
            showViewResults: exam.showViewResults,
          };

          return (
            <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
              <ExamCard {...props} />
            </StyledExamFlexItem>
          );
        }

        if (isBookableOrWaivedAndNotComplete(exam)) {
          let props;
          if (exam.examRecordType === Salesforce.ExamRecordType.EPA2) {
            props = {
              type: EPA2Type,
              subjectTitle:
                exam.pathway === MembershipTypes.Pathway.APPRENTICE_L7
                  ? 'EPA2 Project Report'
                  : 'EPA2 Reflective Statement and Portfolio',
              status:
                exam.resultStatus === User.ResultStatus.Failed
                  ? MembershipTypes.CimaExamCardStatus.EPA_FAILED
                  : MembershipTypes.CimaExamCardStatus.EPA_DEFAULT,
              cardSize,
              isGatewayNotPassed,
              handleViewResultsClick,
              handleAccessNowClick,
              showCredits: false,
              epaButtonLabel: isGatewayNotPassed ? 'Learn More' : 'Access Now',
              overrideStatus: exam.overrideStatus,
              showViewResults: exam.showViewResults,
            };

            return (
              <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
                <ExamCard {...props} />
              </StyledExamFlexItem>
            );
          }

          if ([User.ResultStatus.InProgress, User.ResultStatus.Failed].includes(exam.resultStatus as any)) {
            props = {
              type: exam.type,
              subjectTitle: examCardTitle,
              cardSize,
              ...(exam.ratificationStatus === PvType.ExamResultRatificationStatus.pendingRatification ||
              exam.ratificationStatus === PvType.ExamResultRatificationStatus.onHold
                ? { status: MembershipTypes.CimaExamCardStatus.RESULTS_PENDING }
                : {
                    status: MembershipTypes.CimaExamCardStatus.FAILED,
                    availableCredits: `${exam.usedExamAvailable}`,
                    usedCredits: `${exam.usedExamCredits}`,
                    ratificationStatus: exam.ratificationStatus,
                    overrideStatus: exam.overrideStatus,
                    handleBuyExamCreditsClick,
                    handleBookNowClick,
                    handleViewResultsClick,
                  }),
              overrideStatus: exam.overrideStatus,
              showViewResults: exam.showViewResults,
            };
            return (
              <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
                <ExamCard {...props} />
              </StyledExamFlexItem>
            );
          }

          if (exam.resultStatus === User.ResultStatus.Pending) {
            props = {
              type: exam.type,
              subjectTitle: examCardTitle,
              status: MembershipTypes.CimaExamCardStatus.RESULTS_PENDING,
              cardSize,
              overrideStatus: exam.overrideStatus,
              showViewResults: exam.showViewResults,
            };

            return (
              <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
                <ExamCard {...props} />
              </StyledExamFlexItem>
            );
          }

          if (exam.resultStatus === User.ResultStatus.Failed) {
            props = {
              type: exam.type,
              subjectTitle: exam.name,
              status: MembershipTypes.CimaExamCardStatus.FAILED,
              cardSize,
              overrideStatus: exam.overrideStatus,
              showViewResults: exam.showViewResults,
            };

            return (
              <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
                <ExamCard {...props} />
              </StyledExamFlexItem>
            );
          }

          props = {
            type: exam.type,
            subjectTitle: examCardTitle,
            status:
              exam.ratificationStatus === PvType.ExamResultRatificationStatus.pendingRatification ||
              exam.ratificationStatus === PvType.ExamResultRatificationStatus.onHold
                ? MembershipTypes.CimaExamCardStatus.RESULTS_PENDING
                : MembershipTypes.CimaExamCardStatus.EXAM_NOT_BOOKED,
            availableCredits: `${exam.usedExamAvailable}`,
            usedCredits: `${exam.usedExamCredits}`,
            cardSize,
            handleBookNowClick,
            handleBuyExamCreditsClick,
            overrideStatus: exam.overrideStatus,
            showViewResults: exam.showViewResults,
          };

          // FLP competency that is opened
          return exam.type === User.SubjectBlockType.FLP ? (
            <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
              <CimaFlpCompetencyCards
                flpId={exam.id}
                description={exam.name}
                flpCompetencyModuleList={exam?.flpCompetency}
                blockStatus={exam.status}
              />
            </StyledExamFlexItem>
          ) : (
            <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
              <ExamCard {...props} />
            </StyledExamFlexItem>
          );
        }
        if (exam.status === User.SubjectBlockStatus.Booked && !exam.appointmentDetails) {
          let props;
          props = {
            type: exam.type,
            subjectTitle: examCardTitle,
            status:
              exam.ratificationStatus === PvType.ExamResultRatificationStatus.pendingRatification ||
              exam.ratificationStatus === PvType.ExamResultRatificationStatus.onHold
                ? MembershipTypes.CimaExamCardStatus.RESULTS_PENDING
                : MembershipTypes.CimaExamCardStatus.FAILED,
            cardSize,
            overrideStatus: exam.overrideStatus,
            showViewResults: exam.showViewResults,
          };
          return (
            <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
              <ExamCard {...props} />
            </StyledExamFlexItem>
          );
        }

        if (exam.status === User.SubjectBlockStatus.Locked) {
          let props;

          if (exam.type === User.SubjectBlockType.FLP) {
            props = {
              flpId: exam.id,
              description: exam.name,
              flpCompetencyModuleList: exam?.flpCompetency ?? [],
              isLocked: false,
              isExempted: Boolean(exam?.promoCodeExemptionStatus?.length),
              blockStatus: exam.status,
              isNonWaivable: exam.isNonWaivable,
            };
            return (
              <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
                <CimaFlpCompetencyCards {...props} />
              </StyledExamFlexItem>
            );
          }

          if (exam.examRecordType === Salesforce.ExamRecordType.EPA2) {
            props = {
              type: EPA2Type,
              subjectTitle:
                exam.pathway === MembershipTypes.Pathway.APPRENTICE_L7
                  ? 'EPA2 Project Report'
                  : 'EPA2 Reflective Statement and Portfolio',
              status: MembershipTypes.CimaExamCardStatus.EPA_LOCKED,
              epaButtonLabel: 'Learn More',
              handleLearnMoreClick,
              isGatewayNotPassed,
              cardSize,
              overrideStatus: exam.overrideStatus,
              showViewResults: exam.showViewResults,
            };

            return (
              <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
                <ExamCard {...props} />
              </StyledExamFlexItem>
            );
          }

          props = {
            type: exam.type,
            subjectTitle: examCardTitle,
            status: MembershipTypes.CimaExamCardStatus.LOCKED,
            cardSize,
            overrideStatus: exam.overrideStatus,
            isExempted: Boolean(
              exam?.promoCodeExemptionStatus?.length &&
                exam?.promoCodeExemptionStatus === User.SubjectBlockStatus.Exempted
            ),
            isNonWaivable: exam.isNonWaivable,
            showViewResults: exam.showViewResults,
          };

          return (
            <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
              <ExamCard {...props} />
            </StyledExamFlexItem>
          );
        }

        if (
          (exam.status === User.SubjectBlockStatus.Booked || exam.status === User.SubjectBlockStatus.Waived) &&
          exam.appointmentDetails
        ) {
          let props;

          if (exam.appointmentDetails.deliveryFormat === User.ExamDeliveryFormat.OnlineExam) {
            props = {
              type: exam.type,
              subjectTitle: exam.appointmentDetails?.examName, // changed to examUrlSlug instead of exam.name to display if choosen exam is Online or not.
              bookedExamDate: examBookingDate(exam.appointmentDetails),
              status: MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED,
              cardSize,
              handleTakeOnlineExamClick,
              handleRescheduleClick,
              handleCancelExamClick,
              overrideStatus: exam.overrideStatus,
              showRescheduleAndCancel:
                getRemainingHours(exam.appointmentDetails.examDateTime) <= scheduleAndCancelPeriod,
              showViewResults: exam.showViewResults,
            };

            return (
              <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
                <ExamCard {...props} />
              </StyledExamFlexItem>
            );
          }

          const bookedExamAddress = `${exam.appointmentDetails.testCenterAddress?.addressLine1}, ${
            exam.appointmentDetails.testCenterAddress?.addressLine2
              ? `${exam.appointmentDetails.testCenterAddress?.addressLine2},`
              : ''
          }${exam.appointmentDetails.testCenterAddress?.city}`;

          props = {
            type: exam.type,
            subjectTitle: exam.appointmentDetails?.examName || exam.name,
            bookedExamDate: examBookingDate(exam.appointmentDetails),
            bookedExamAddress,
            bookedExamAddressCountry: formatExamCountryAddress(exam.appointmentDetails.testCenterAddress),
            status: MembershipTypes.CimaExamCardStatus.EXAM_BOOKED,
            cardSize,
            overrideStatus: exam.overrideStatus,
            handleRescheduleClick,
            handleCancelExamClick,
            showRescheduleAndCancel: getRemainingHours(exam.appointmentDetails.examDateTime) <= scheduleAndCancelPeriod,
            showViewResults: exam.showViewResults,
          };

          return (
            <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
              <ExamCard {...props} />
            </StyledExamFlexItem>
          );
        }

        if (
          exam.status === User.SubjectBlockStatus.Completed ||
          exam.resultStatus === User.ResultStatus.Complete // exam status is waived and exam results ratified
        ) {
          const props = {
            type: exam.type,
            subjectTitle: examCardTitle,
            cardSize,
            ...(exam.ratificationStatus === PvType.ExamResultRatificationStatus.pendingRatification ||
            exam.ratificationStatus === PvType.ExamResultRatificationStatus.onHold
              ? { status: MembershipTypes.CimaExamCardStatus.RESULTS_PENDING }
              : {
                  status:
                    exam.overrideStatus === PvType.ExamResultOverrideStatus.voided
                      ? MembershipTypes.CimaExamCardStatus.EPA_DEFAULT
                      : MembershipTypes.CimaExamCardStatus.PASSED,
                  passedDate: exam.completionDate,
                  handleBuyExamCreditsClick,
                  handleViewResultsClick,
                  handleBookNowClick,
                }),
            overrideStatus: exam.overrideStatus,
            availableCredits: `${exam.usedExamAvailable}`,
            usedCredits: `${exam.usedExamCredits}`,
            showViewResults: exam.showViewResults,
          };

          return exam.type === User.SubjectBlockType.FLP ? (
            <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
              <CimaFlpCompetencyCards
                flpId={exam.id}
                description={exam.name}
                flpCompetencyModuleList={exam?.flpCompetency}
                blockStatus={exam.status}
              />
            </StyledExamFlexItem>
          ) : (
            <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
              <ExamCard {...props} />
            </StyledExamFlexItem>
          );
        }

        if (exam.status === User.SubjectBlockStatus.Booked && exam.type === User.SubjectBlockType.FLP) {
          return (
            <StyledExamFlexItem cardSize={cardSize} key={exam.name}>
              <CimaFlpCompetencyCards
                flpId={exam.id}
                description={exam.name}
                flpCompetencyModuleList={exam?.flpCompetency}
                blockStatus={exam.status}
              />
            </StyledExamFlexItem>
          );
        }

        return;
      })}
      {htmlText && <PearsonVue ssoLink={pearsonVueSSOLink} htmlText={htmlText} submit={ssoPV} />}
    </React.Fragment>
  );
};

const OpenPvDashboard = async (action: PvType.SSO_ACTIONS, registrationId: string | undefined, dispatch: any) => {
  const resp = await dispatch(openPersonVue(action, registrationId));
  const { getPVSession, success } = resp.payload;
  return { getPVSession, success };
};

const StyledExamFlexItem = styled.div<{ cardSize: EXAM_CARD_SIZE }>`
  flex: 0 1 32.5%;

  ${({ cardSize }) =>
    cardSize !== EXAM_CARD_SIZE.MEDIUM &&
    cardSize !== EXAM_CARD_SIZE.LARGE &&
    css`
      display: flex;
    `}

  ${({ cardSize }) =>
    cardSize === EXAM_CARD_SIZE.MEDIUM &&
    css`
      flex: 0 0 47% !important;
    `}

  ${({ cardSize }) =>
    cardSize === EXAM_CARD_SIZE.LARGE &&
    css`
      flex: 1 1 0 !important;
    `}
`;
