import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/browser';
import { isServer } from 'utils';
import { User as UserTypes } from 'mxp-schemas';

import { AuthHelper } from './base';
export class ExternalAuthHelper extends AuthHelper {
  constructor() {
    super('', {}, false);
  }
  async sessionExists() {
    if (isServer) return null;
    const existingToken = await this.getValidAccessToken();
    if (existingToken) {
      return UserTypes.SessionType.EXTERNAL_SESSION;
    }
    return UserTypes.SessionType.NO_SESSION;
  }
  async getValidAccessToken(): Promise<string | undefined> {
    try {
      const extToken = localStorage.getItem('externalToken');
      if (extToken && window.location.pathname.includes('token')) {
        return extToken;
      }
      return;
    } catch (error) {
      Sentry.captureException(error);
      return;
    }
  }
  async getUserTokenData(): Promise<{ sub: string } | null> {
    const token: string | undefined = await this.getValidAccessToken();
    if (!token) return null;
    const parsedToken: { sub: string } = jwtDecode(token);
    if (!parsedToken) return null;
    return parsedToken;
  }
  async signOutUser(): Promise<void> {
    return this.signOutUserFromOkta();
  }
  async signOutUserFromOkta(): Promise<void> {
    if (isServer) return;
    const token = await this.authClient.tokenManager.get(this.oktaStorageKey);
    if (token) {
      await this.authClient.revokeAccessToken(token);
      window.location.replace(window.location.origin);
    }
  }
}
