import * as Sentry from '@sentry/browser';
import { handleActions } from 'redux-actions';
import { loadConstants, resetAppInitialized, sendMessage, rehydrateUserData } from 'modules/app/actions';
import { clientConfig as config, env, version } from '../../config';
import { Contentful } from 'mxp-schemas';

const initialState: State.App = {
  version,
  env,
  config,
  constants: null,
  appInitialized: false,
};

if ([resetAppInitialized, sendMessage, rehydrateUserData].includes(undefined)) {
  const message: string = 'app module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [loadConstants]: (state: State.App, { payload }: { payload: Contentful.Constants.Constants }): State.App => ({
    ...state,
    constants: payload,
  }),
  [resetAppInitialized]: (state: State.App): State.App => ({
    ...state,
    appInitialized: false,
  }),
  [rehydrateUserData]: (state: State.App): State.App => ({
    ...state,
    appInitialized: true,
  }),
};

export { sendMessage, rehydrateUserData };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
