import React, { useEffect, useState } from 'react';
import { Button, ButtonEnums, Input, Checkbox } from 'components/atoms';
import { InputWithValidation } from 'components/molecules/InputWithValidation/InputWithValidation';
import { ProfileHeading } from 'components/molecules/ProfileHeading/ProfileHeading';
import styled from 'styled-components';
import { Table } from 'components/atoms/Table/Table';
import { generatePath } from 'react-router';
import { getPath, formatPrice, fromCentsFormat } from 'utils';
import { BackLink } from 'components/molecules/BackLink/BackLink';
import { Routes } from 'constants/index';
import {
  addProductToCart,
  changeListPrice,
  changeDiscountPrice,
  changeField,
  changeProvisioning,
  removeProductFromCart,
  editDescription,
  updateToggleStatus,
  openAllProducts,
  closeAllProducts,
} from 'modules/admin/actions';
import {
  b2bCartLoadingStateSelector,
  b2bCartSelector,
  checkoutLoadingStateSelector,
  selectedCurrency,
} from 'modules/admin/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Cancel } from 'resources/images/ic-cancel.svg';
import { Dropdown, DropdownProps } from 'components/atoms/Dropdown/Dropdown';
import { B2B, Product } from 'mxp-schemas';
import { Order as OrderUtils, User as UserUtils } from 'mxp-utils';
import { isAccessiblePeriodSelectionValid } from './CreateOrder.helpers';
import { EditProductDescriptionModal } from 'components/admin/molecules/EditProductDescriptionModal/EditProductDescriptionModal';
import moment from 'moment-timezone';

import { ReactComponent as ArrowDown } from 'resources/images/icon-dev-ic-keyboard-arrow-down.svg';
import { ReactComponent as ArrowUp } from 'resources/images/icon-dev-ic-keyboard-arrow-up.svg';
import { Plus, Minus } from 'components/atoms/svg';
import { AdditionalProductAttr } from './AdditionalProductAttr';
import { Grid, List, Dimmer, Loader } from 'semantic-ui-react';

const provisioningOptions = [
  {
    key: 1,
    text: 'AICPA.org',
    value: B2B.ProvisionBy.AICPA,
  },
  {
    key: 2,
    text: 'Bill only',
    value: B2B.ProvisionBy.BILL_ONLY,
  },
];

const hasQuantityError = (item: B2B.CartItem) => {
  const isInvalidQuantity = item.userInput.quantity < 1;
  const isStockValidated =
    item.productDetails.isPhysicalProduct && item.userInput.provisionBy === B2B.ProvisionBy.AICPA;
  const notEnoughInStock =
    item.productDetails.availableQuantity < item.userInput.quantity || item.productDetails.availableQuantity === 0;

  return isInvalidQuantity || (isStockValidated && notEnoughInStock);
};

const hasAttrDateError = (item: B2B.CartItem) => {
  const isProvByBillOnly = item.userInput?.provisionBy === 'bill-only';
  const isSubscription = OrderUtils.isSubscription(item);

  const isProductNonStore = item.productDetails.productType === Product.ProductType.NON_STORE;
  const isNonStoreRecurringProduct = isProductNonStore && item.productDetails.isManualSubscription;
  const isNonStoreOneOffProduct = isProductNonStore && !item.productDetails.isManualSubscription;

  const getSubscriptionStart = item.userInput?.additionalAttrs?.subscriptionStart || '';
  const getOccurrenceStart = item.userInput?.additionalAttrs?.occurrenceDate || '';

  let isNotValid: boolean = false;
  let isEmpty: boolean = false;

  if (isNonStoreRecurringProduct || (isProvByBillOnly && isSubscription)) {
    const isWithinAYear: boolean = moment(getSubscriptionStart).diff(moment(), 'months', true) < 12;
    isNotValid = !moment(getSubscriptionStart, 'MM/DD/YYYY', true).isValid() || !isWithinAYear;
    isEmpty = !Boolean(getSubscriptionStart.length);
  }

  if (isNonStoreOneOffProduct || (isProvByBillOnly && !isSubscription)) {
    isNotValid = !moment(getOccurrenceStart, 'MM/DD/YYYY', true).isValid();
    isEmpty = !Boolean(getOccurrenceStart.length);
  }

  return isNotValid || isEmpty;
};

const hasAttrPeriodError = (item: B2B.CartItem) => {
  const isProductNonStore = item.productDetails.productType === Product.ProductType.NON_STORE;
  const isNonStoreRecurringProduct = isProductNonStore && item.productDetails.isManualSubscription;

  const isProvByBillOnly = item.userInput?.provisionBy === B2B.ProvisionBy.BILL_ONLY;
  const isSubscription = OrderUtils.isSubscription(item);

  const period = item.userInput?.additionalAttrs?.period;
  const periodType = item.userInput?.additionalAttrs?.periodType;

  let isNotValid: boolean = false;
  let isEmpty: boolean = false;

  if (isNonStoreRecurringProduct || (isProvByBillOnly && isSubscription)) {
    isNotValid = !isAccessiblePeriodSelectionValid(period, periodType);
    isEmpty = !Boolean(period || periodType?.length);
  }

  return isNotValid || isEmpty;
};

interface BuildProps {
  accountId: string;
  accountNumber?: string;
  legalEntity?: string;
  setNextDisabled: (isDisabled: boolean) => void;
}

export const Build: React.FC<BuildProps> = ({ accountId, accountNumber, legalEntity, setNextDisabled }) => {
  const dispatch = useDispatch();
  const loading = useSelector(b2bCartLoadingStateSelector);
  const fetchAddressLoading = useSelector(checkoutLoadingStateSelector);
  const cart = useSelector(b2bCartSelector);
  const [productSku, setProductSku] = useState({ value: '', isEmpty: true });
  const [errorAddingProduct, setErrorAddingProduct] = useState('');
  const [selectedSku, setSelectedSku] = useState(null);
  const currency = useSelector(selectedCurrency);

  const cartItemIndex = cart.findIndex((item: B2B.CartItem) => item.productDetails.sku === selectedSku);
  useEffect(() => {
    dispatch(removeProductFromCart(cartItemIndex));
  }, [dispatch, cartItemIndex]);
  const quantityError = cart.some((item: B2B.CartItem) => {
    return hasQuantityError(item);
  });
  const attrDateError = cart.some((item: B2B.CartItem) => {
    return hasAttrDateError(item);
  });
  const attrPeriodError = cart.some((item: B2B.CartItem) => {
    return hasAttrPeriodError(item);
  });
  const disableNextBtn = Boolean(quantityError) || Boolean(attrDateError) || Boolean(attrPeriodError);
  setNextDisabled(disableNextBtn);
  const disableButton = productSku.isEmpty || Boolean(errorAddingProduct) || Boolean(quantityError);

  const handleProductSkuChange = ({ target: { value } }: any) => {
    setErrorAddingProduct('');
    setProductSku({
      value,
      isEmpty: Boolean(value.length === 0),
    });
  };
  const onDiscountPriceChange =
    (index: number) =>
    ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
      const isProductNonStore = Product.ProductType.NON_STORE === cart[index].productDetails.productType;
      const isProductOnSite = Product.ProductType.ONSITE === cart[index].productDetails.productType;

      if (isProductOnSite || isProductNonStore) {
        if (!/^-?(0{0,1}|[1-9]\d*)(?:\.\d{0,2})?$/.test(value)) return;
      } else {
        if (!/^(0{0,1}|[1-9]\d*)(?:\.\d{0,2})?$/.test(value)) return;
      }

      dispatch(changeDiscountPrice(index, value));
    };

  const onFieldChange =
    (index: number, field: string) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (field === 'quantity' && !/^\d*$/.test(value)) return;
      dispatch(changeField(index, value, field));
    };

  const onProvisioningChange =
    (index: number) => (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      dispatch(changeProvisioning(index, data.value));
    };

  const onRemoval = (index: number) => () => {
    dispatch(removeProductFromCart(index));
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    setErrorAddingProduct('');
    dispatch(addProductToCart(productSku.value))
      .then(({ payload: { searchProduct } }: any) => {
        const currencyPrice = searchProduct.prices.find((item: any) => {
          return (
            item.value.currencyCode === currency &&
            (item.channel.obj.key === UserUtils.CORRECT_NON_MEMBER_CHANNEL_KEY ||
              item.channel.obj.key === UserUtils.INCORRECT_NON_MEMBER_CHANNEL_KEY)
          );
        });
        if (!currencyPrice) {
          setSelectedSku(searchProduct.sku);
          return setErrorAddingProduct('Selected currency for this product does not exist. Try again.');
        }
        if (!searchProduct) return setErrorAddingProduct('Product SKU does not exist. Try again.');
        setProductSku({ value: '', isEmpty: true });
      })
      .catch(() => {
        setErrorAddingProduct('Uh oh. Something went wrong. Try again.');
      });
  };

  const [isEditProductDescModalOpen, setEditProductDescModal] = useState(false);
  const [cartIndex, setCartIndex] = useState(0);
  const [cartDescription, setCartDescription] = useState('');

  const toggleEditProductDescModal = () => {
    setEditProductDescModal(!isEditProductDescModalOpen);
  };

  const onConfirmation = (value: string) => {
    toggleEditProductDescModal();
    dispatch(editDescription(cartIndex, value));
  };

  const onDescriptionEdit = (index: number) => () => {
    setCartIndex(index);
    setCartDescription(cart[index].userInput.description);

    toggleEditProductDescModal();
  };

  const openAll = () => {
    dispatch(openAllProducts());
  };

  const closeAll = () => {
    dispatch(closeAllProducts());
  };

  const handleToggleAction = (index: number) => () => {
    dispatch(updateToggleStatus(index));
  };

  return !fetchAddressLoading ? (
    <>
      {isEditProductDescModalOpen && (
        <EditProductDescriptionModal
          open={isEditProductDescModalOpen}
          onCancel={toggleEditProductDescModal}
          onConfirmation={onConfirmation}
          productDescription={cartDescription}
        />
      )}

      <BackLink
        testId="orders"
        to={generatePath(getPath(Routes.ADMIN_ORGANIZATION), { accountId, accountNumber, legalEntity })}
      >
        Back to orders
      </BackLink>
      <StyledProfileHeading title="Build order" />
      <Grid>
        <Grid.Column floated="left" mobile={16} computer={8}>
          <StyledParagraphInOrder>
            <Bold>
              {cart.length} product{cart.length !== 1 && 's'}
            </Bold>{' '}
            {cart.length === 1 ? 'is' : 'are'} in this order
          </StyledParagraphInOrder>
          <CheckboxStyled
            width="15"
            height="15"
            type="checkbox"
            testId={`combined-b2b-checkbox`}
            label="I want to combine B2B order with Firm Billing order"
          />
        </Grid.Column>
        <Grid.Column verticalAlign="top" floated="right" textAlign="right" width={8} mobile={16} computer={8}>
          <List horizontal divided>
            <List.Item>
              <StyledOpenAllBlock data-testid="test-open-all-block" onClick={openAll}>
                <Plus stroke="#fff" background="#72246c" borderRadius="11" width={18} height={18} />
                <StyledToggleSpan>Open all</StyledToggleSpan>
              </StyledOpenAllBlock>
            </List.Item>
            <List.Item>
              <StyledCloseAllBlock data-testid="test-close-all-block" onClick={closeAll}>
                <Minus stroke="#fff" background="#72246c" borderRadius="11" width={18} height={18} />
                <StyledToggleSpan>Close all</StyledToggleSpan>
              </StyledCloseAllBlock>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid>
      <StyledTable hasItems={Boolean(cart.length)}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={6}>Product Name</Table.HeaderCell>
            {Boolean(cart.length) && (
              <>
                <Table.HeaderCell width={1}>Provision By</Table.HeaderCell>
                <RightAlignedHeaderCell width={2}>List Price</RightAlignedHeaderCell>
                <Table.HeaderCell width={2}>Discount Price ($)</Table.HeaderCell>
                <Table.HeaderCell width={1}>Quantity</Table.HeaderCell>
                <Table.HeaderCell width={2}>Promo Code</Table.HeaderCell>
                <Table.HeaderCell width={2}>Promo Code Reason</Table.HeaderCell>
                <RightAlignedHeaderCell width={3}>Item Subtotal</RightAlignedHeaderCell>
                <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {cart.map((item: B2B.CartItem, index: number) => {
            const currencyPrice = item.productDetails.prices?.map((cartItem: any) => {
              return {
                price: cartItem?.value?.centAmount,
                currency: cartItem?.value?.currencyCode,
                channel: { obj: { key: cartItem?.channel?.obj?.key } },
              };
            });
            const itemPrice = currencyPrice?.find((currencyItem: any) => {
              return (
                currencyItem.currency === currency &&
                (currencyItem.channel.obj.key === UserUtils.CORRECT_NON_MEMBER_CHANNEL_KEY ||
                  currencyItem.channel.obj.key === UserUtils.INCORRECT_NON_MEMBER_CHANNEL_KEY)
              );
            });
            return !itemPrice ? (
              <></>
            ) : (
              <ProductRow
                key={item.productDetails.sku}
                item={item}
                selectedPrice={itemPrice}
                index={index}
                onDescriptionEdit={onDescriptionEdit(index)}
                onProvisioningChange={onProvisioningChange(index)}
                onDiscountPriceChange={onDiscountPriceChange(index)}
                onQuantityChange={onFieldChange(index, 'quantity')}
                onPromoCodeChange={onFieldChange(index, 'promoCode')}
                onPromoCodeReasonChange={onFieldChange(index, 'promoReason')}
                onRemoval={onRemoval(index)}
                handleToggleAction={handleToggleAction(index)}
              />
            );
          })}
        </Table.Body>
      </StyledTable>
      <StyledTableFooter>
        <StyledInputWrapper>
          <InputWithValidation
            type="text"
            value={productSku.value}
            testId="test-create-order-input"
            onChange={handleProductSkuChange}
            placeholder="Enter product SKU"
            name="email"
            isCorrect={!Boolean(errorAddingProduct)}
            errorMessage={errorAddingProduct}
          />
        </StyledInputWrapper>
        <StyledAddButton
          size={ButtonEnums.sizes.medium}
          onClick={onSubmit}
          testId="search-user"
          variant={ButtonEnums.variants.secondary}
          disabled={disableButton || loading}
          loading={loading}
        >
          Add product
        </StyledAddButton>
      </StyledTableFooter>
    </>
  ) : (
    <Dimmer active={true} inverted>
      <Loader size="small" inverted>
        Loading
      </Loader>
    </Dimmer>
  );
};

const StyledOpenAllBlock = styled.div`
  padding-right: 1rem;
  color: ${props => props.theme.colors.primaryPurple};
  margin-bottom: ${props => props.theme.pxToRem(16)};
`;

const StyledCloseAllBlock = styled.div`
  padding-left: 1rem;
  color: ${props => props.theme.colors.primaryPurple};
`;

const StyledToggleSpan = styled.span`
  position: relative;
  bottom: 0.25rem;
  padding: 0 0.5rem;
  cursor: pointer;
  opacity: 0.9;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;
`;

const NotApplicable = () => <GrayText>N/A</GrayText>;

interface QuantityInfoProps {
  item: B2B.CartItem;
}

const QuantityInfo: React.FC<QuantityInfoProps> = ({ item }) => {
  if (item.userInput.quantity < 1) {
    return <StyledQuantityInfo isError>Invalid quantity</StyledQuantityInfo>;
  }
  if (!(item.productDetails.isPhysicalProduct && item.userInput.provisionBy === B2B.ProvisionBy.AICPA)) {
    return null;
  }

  if (item.productDetails.availableQuantity === 0) {
    return <StyledQuantityInfo isError>Out of stock.</StyledQuantityInfo>;
  }

  if (item.productDetails.availableQuantity >= item.userInput.quantity) {
    return <StyledQuantityInfo>{`${item.productDetails.availableQuantity} in stock.`}</StyledQuantityInfo>;
  }

  if (item.productDetails.availableQuantity < item.userInput.quantity) {
    return (
      <StyledQuantityInfo isError>
        {`${item.productDetails.availableQuantity} in stock`} <br /> {'Adjust quantity.'}
      </StyledQuantityInfo>
    );
  }
  return null;
};

interface QuantityInputProps {
  item: B2B.CartItem;
  onQuantityChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

interface PromoInputProps {
  testId: string;
  item: B2B.CartItem;
  onPromoChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

enum PromoInputTypes {
  PROMO_CODE = 'promo-code',
  PROMO_CODE_REASON = 'promo-code-reason',
}

const QuantityInput: React.FC<QuantityInputProps> = ({ item, onQuantityChange }) => (
  <>
    <StyledQuantityInput
      name={`quantity-${item.productDetails.sku}`}
      type="text"
      value={item.userInput?.quantity}
      testId={`quantity-${item.productDetails.sku}`}
      onChange={onQuantityChange}
      autoFocus={false}
      isError={hasQuantityError(item)}
    />
    <QuantityInfo item={item} />
  </>
);

const PromoInput: React.FC<PromoInputProps> = ({ testId, item, onPromoChange }) => (
  <>
    <StyledQuantityInput
      name={`${testId}-${item.productDetails.sku}`}
      type="text"
      value={
        (testId === PromoInputTypes.PROMO_CODE && item.userInput.promoCode) ||
        (testId === PromoInputTypes.PROMO_CODE_REASON && item.userInput.promoReason) ||
        ''
      }
      testId={`${testId}-${item.productDetails.sku}`}
      autoFocus={false}
      onChange={onPromoChange}
    />
  </>
);

interface B2CProductRowProps {
  item: B2B.CartItem;
  index: number;
  selectedPrice: any;
  onDescriptionEdit: () => void;
  onProvisioningChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onDiscountPriceChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onQuantityChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onPromoCodeChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onPromoCodeReasonChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onRemoval: () => void;
  handleToggleAction: () => void;
}

const ProductRow: React.FC<B2CProductRowProps> = ({
  item,
  index,
  selectedPrice,
  onDescriptionEdit,
  onProvisioningChange,
  onDiscountPriceChange,
  onQuantityChange,
  onPromoCodeChange,
  onPromoCodeReasonChange,
  onRemoval,
  handleToggleAction,
}) => {
  const isOpen = Boolean(item.userInput?.isOpen);
  const isProductNonStore = Product.ProductType.NON_STORE === item.productDetails.productType;
  const isNonStoreHasEmptyListPrice = isProductNonStore && !Boolean(item.productDetails?.listPrice?.amount);
  const isProductOnSite = Product.ProductType.ONSITE === item.productDetails.productType;
  // const userSelectedCurrency = useSelector(selectedCurrency);
  const dispatch = useDispatch();
  const isBelowAndNotEmptyListPrice =
    !Boolean(item.userInput?.price < fromCentsFormat(item.productDetails.listPrice.amount)) &&
    !isNonStoreHasEmptyListPrice;

  React.useEffect(() => {
    if (
      isBelowAndNotEmptyListPrice ||
      (isProductNonStore && item.userInput?.price === fromCentsFormat(item.productDetails.listPrice.amount))
    ) {
      dispatch(changeDiscountPrice(index, fromCentsFormat(selectedPrice.price)));
    }
    dispatch(changeListPrice(index, selectedPrice.price, selectedPrice.currency));
    // eslint-disable-next-line
  }, [index, selectedPrice.price, selectedPrice.currency, dispatch]);

  return (
    <>
      <Table.Row>
        <Table.Cell>
          <SemiBold>{item.userInput.description}</SemiBold>
          <EditButton
            testId={`edit-${item.productDetails.sku}`}
            variant={ButtonEnums.variants.link}
            onClick={onDescriptionEdit}
          >
            Edit
          </EditButton>
          <StyledFormat>{`#${item.productDetails.sku} | ${item.productDetails.format}`}</StyledFormat>
        </Table.Cell>
        <Table.Cell>
          {isProductNonStore || isProductOnSite ? (
            <NotApplicable />
          ) : (
            <StyledDropdown
              testId={`dropdown-provision-by-${item.productDetails.sku}`}
              onChange={onProvisioningChange}
              options={provisioningOptions}
              value={item.userInput?.provisionBy}
            />
          )}
        </Table.Cell>
        <RightAlignedCell>
          {isNonStoreHasEmptyListPrice || isProductOnSite ? (
            <NotApplicable />
          ) : (
            formatPrice(fromCentsFormat(selectedPrice.price || 0), selectedPrice.currency)
          )}
        </RightAlignedCell>
        <Table.Cell>
          <StyledDiscountPriceInput
            name={`discount-price-${item.productDetails.sku}`}
            type="text"
            value={item.userInput?.price}
            testId={`discount-price-${item.productDetails.sku}`}
            onChange={onDiscountPriceChange}
            autoFocus={false}
          />
        </Table.Cell>
        <Table.Cell>
          <QuantityInput item={item} onQuantityChange={onQuantityChange} />
        </Table.Cell>
        <Table.Cell>
          <PromoInput testId={PromoInputTypes.PROMO_CODE} item={item} onPromoChange={onPromoCodeChange} />
        </Table.Cell>
        <Table.Cell>
          <PromoInput testId={PromoInputTypes.PROMO_CODE_REASON} item={item} onPromoChange={onPromoCodeReasonChange} />
        </Table.Cell>
        <RightAlignedCell>
          {formatPrice((item.userInput?.quantity || 0) * (item.userInput?.price || 0), selectedPrice.currency)}
        </RightAlignedCell>
        <Table.Cell>
          <StyledRemoveButton
            testId={`remove-product-${item.productDetails.sku}`}
            variant={ButtonEnums.variants.link}
            size={ButtonEnums.sizes.small}
            icon={<Cancel />}
            iconPosition={ButtonEnums.iconPosition.left}
            onClick={onRemoval}
          >
            Remove
          </StyledRemoveButton>
        </Table.Cell>
        <Table.Cell>
          <StyledToggleArrows
            data-testid={`toggle-action-arrows-${item.productDetails.sku}`}
            isOpen={isOpen}
            onClick={handleToggleAction}
          >
            <ArrowDown className="arr-down" />
            <ArrowUp className="arr-up" />
          </StyledToggleArrows>
        </Table.Cell>
      </Table.Row>
      <StyledHiddenRow isOpen={isOpen}>
        <Table.Cell colSpan={8}>
          <AdditionalProductAttr item={item} index={index} />
        </Table.Cell>
      </StyledHiddenRow>
    </>
  );
};

const StyledHiddenRow = styled(Table.Row)<{ isOpen: boolean }>`
  display: none;

  ${props =>
    props.isOpen &&
    `
    & {
      display:table-row;
    }
    `}
`;

const StyledToggleArrows = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  margin-bottom: ${props => props.theme.pxToRem(-3)};

  .arr-down,
  .arr-up {
    pointer-events: none;

    & > path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }

  .arr-down {
    display: block;
  }
  .arr-up {
    display: none;
  }

  ${props =>
    props.isOpen &&
    `
    .arr-down {
      display: none;
    }
    .arr-up {
      display: block;
    }`}
`;

const StyledQuantityInfo = styled.div<{ isError?: boolean }>`
  margin-top: ${props => props.theme.pxToRem(6)};
  font-size: ${props => props.theme.fontSizes.xxs};
  ${props =>
    props.isError &&
    `
    border-color: ${props.theme.colors.interfaceRed};
    color: ${props.theme.colors.interfaceRed};
    font-weight: ${props.theme.fontWeights.regular};
    `}
`;

const StyledQuantityInput = styled(Input)<{ isError: boolean }>`
  max-width: ${props => props.theme.pxToRem(75)};
  ${props =>
    props.isError &&
    `
    &&&& > input {
      &:focus {
        border-color: ${props.theme.colors.interfaceRed};
      }
      border-color: ${props.theme.colors.interfaceRed};
    }
  `}
`;

const StyledProfileHeading = styled(ProfileHeading)`
  margin-top: ${props => props.theme.pxToRem(-8)};
`;

const StyledParagraphInOrder = styled.p`
  margin: ${props => props.theme.pxToRem(31)} 0 0};
  &&&&{
    span:nth-child(3){
      font-size: ${props => props.theme.pxToRem(16)};
    }
  }
`;

const StyledInputWrapper = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(0)};
  margin-right: ${props => props.theme.pxToRem(20)};
  width: ${props => props.theme.pxToRem(245)};
`;

const StyledAddButton = styled(Button)`
  width: ${props => props.theme.pxToRem(138)};
  height: ${props => props.theme.pxToRem(44)};
`;

const StyledRemoveButton = styled(Button)`
  &&&& {
    font-size: ${props => props.theme.pxToRem(14)};
    svg path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      font-size: ${props.theme.pxToRem(14)};
      width: ${props.theme.pxToRem(112)};
      height: ${props.theme.pxToRem(48)};
      padding: ${props.theme.pxToRem(10)};

      &&&.ui.dropdown > .text {
        overflow: hidden;
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `}
`;

const StyledTableFooter = styled.div`
  padding: ${props => `${props.theme.pxToRem(9)} ${props.theme.pxToRem(20)}`};
  padding-bottom: 0;
  border-bottom: 1px solid ${props => props.theme.colors.neutralGrey3};
  display: flex;
`;

const StyledTable = styled(Table)<{ hasItems: boolean }>`
  &&&& {
    tr {
      :nth-of-type(2n) {
        background: none;
      }
      :nth-of-type(3n) {
        background-color: ${props => props.theme.colors.fadedGray};
      }
      :nth-of-type(4n) {
        background-color: ${props => props.theme.colors.fadedGray};
      }
    }
  }
  ${props =>
    !props.hasItems &&
    `
      &&&& {
        border: none;
      }
  `}
`;

const Bold = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const RightAlignedCell = styled(Table.Cell)`
  &&& {
    text-align: right;
  }
`;

const RightAlignedHeaderCell = styled(Table.HeaderCell)`
  &&& {
    text-align: right;
  }
`;

const EditButton = styled(Button)`
  &&&& {
    margin-left: ${props => props.theme.pxToRem(4)};
    color: ${props => props.theme.colors.primaryPurple};
    font-weight: ${props => props.theme.fontWeights.regular};
    text-decoration: underline;
  }
`;

const SemiBold = styled.span`
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledFormat = styled.div`
  margin-top: ${props => props.theme.pxToRem(4)};
`;

const StyledDiscountPriceInput = styled(Input)`
  max-width: ${props => props.theme.pxToRem(120)};
`;

const GrayText = styled.span`
  color: ${props => props.theme.colors.neutralGrey4};
`;

const CheckboxStyled = styled(Checkbox)`
  &&&& {
    > label {
      font-size: ${props => props.theme.fontSizes.xs};
      color: ${props => props.theme.colors.neutralGrey8};
      font-weight: ${props => props.theme.fontWeights.light};
      margin-top: ${props => props.theme.pxToRem(10)};
    }
    input:checked ~ label {
      font-weight: ${props => props.theme.fontWeights.light};
    }
  }
`;
