import React from 'react';
import { Placeholder } from 'semantic-ui-react';

interface Props {
  amount?: number;
  withoutImage?: boolean;
}

export const FeedPlaceholder: React.FC<Props> = ({ amount = 5, withoutImage = false }) => (
  <>
    {[...Array(amount).keys()].map(item => (
      <Placeholder key={item} fluid>
        <Placeholder.Header image={!withoutImage}>
          <Placeholder.Line length="full" />
          <Placeholder.Line length="long" />
        </Placeholder.Header>
      </Placeholder>
    ))}
  </>
);
