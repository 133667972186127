/* eslint-disable complexity */
import { Button, ButtonEnums, FeedPlaceholder } from 'components/atoms';
import { MembershipCard, MembershipPackagePanelWrapper, ModalCredentialPathWay } from 'components/organisms';
import {
  addMembershipPackageCredential,
  filterMembershipCredentialByCategory,
  membershipPackageUpdatePageOfSpecificCredentialCategory,
  removeMembershipPackageCredential,
  removeMembershipPackageCredentialBySku,
  seeMoreMembershipCredentialProduct,
  showMembershipFirstPageOfCredentials,
} from 'modules/membership';
import {
  existingCredentialSelector,
  isComingFromPropPageSelector,
  isCredentialsJourneySelector,
  isRenewalSelector,
  isRenewalsJourneySelector,
  membershipCredentialSelector,
  membershipEligibleCredentialSelector,
  membershipEventSelector,
  sectionsCredentialsRenewalSelector,
  userMembershipCredentialProductIdSelector,
  userMembershipCredentialSelector,
  practicalExperienceRequirementStatusSelector,
} from 'modules/membership/selectors';
import {
  customerInactiveCredentialsSelector,
  customerSuspendedCredentialsSelector,
  examsPassSelector,
} from 'modules/user/selectors';
import { MembershipTypes, Product as ProductTypes, User } from 'mxp-schemas';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { AccordionPanelProps, Grid, Label } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { getPath, productTypeToSlug, areAllTruthy, formatPrice, fromCentsFormat } from 'utils';

import { setCredentialProductId } from 'modules/membership/actions';
import { getProductsListData } from 'modules/products/actions';
import {
  flpProductListDataSelector,
  productCurrencySelector,
  productsListDataFetchedSelector,
} from 'modules/products/selectors';
import { Product } from 'mxp-utils';

import { eligibilityExamChecker } from 'components/pages/PageMembershipPackage/PageMembershipPackage';
import { MembershipPackageAccordionStatus, Routes } from 'constants/index';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { membershipActionNames } from 'modules/membership/constants';
import { handleEvent } from 'utils/Analytics';

interface MembershipPackagePanelProps extends AccordionPanelProps {
  handleClick: () => void;
  setErrorCredentialProps: Dispatch<
    SetStateAction<{
      headerText: string;
      bodyText: string;
      productId: string;
    }>
  >;
  decimal: number;
  upgradeMembershipModalOpen?: boolean;
  applyMembershipModalOpen?: boolean;
  hasSelected: boolean;
  useNewMembershipAICPA: boolean;
}

export const MembershipCredentialPanel: React.FC<MembershipPackagePanelProps> = ({
  handleClick,
  decimal,
  upgradeMembershipModalOpen,
  applyMembershipModalOpen,
  setErrorCredentialProps,
  setHasErrorInCredentials,
  hasSelected,
  useNewMembershipAICPA,
}) => {
  const ALL = 'All';
  const [selectedCategory, setSelectedCategory] = useState(ALL);
  const [isOpen, setOpen]: any = useState(false);
  const [selectedCredentialProductId, setSelectedCredentialProductId] = useState('');
  const [isCredentialLoading, setIsCredentialLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isAddExistingCredentialsLoaded, setIsAddExistingCredentialsLoaded] = useState(false);
  const [isCategoryEventTriggered, setIsCategoryEventTriggered] = useState(false);

  const [ifHasLapsedCredential, setIfHasLapsedCredential] = useState(false);
  const [lapsedReinstatementPathway, setLapsedReinstatementPathway]: any = useState();
  const [selectedCredentialId, setSelectedCredentialId]: any = useState('');

  const dispatch = useDispatch();
  const { list: relateCredentialProductList, categories } = useSelector(membershipCredentialSelector);

  const userChoicesCredentials = useSelector(userMembershipCredentialSelector);
  const isRenewal = useSelector(isRenewalSelector);
  const { productId: productID } = useSelector(membershipEligibleCredentialSelector);
  const existingCredential = useSelector(existingCredentialSelector);
  const isComingFromPropPage = useSelector(isComingFromPropPageSelector);
  const sectionsCredentialsRenewal = useSelector(sectionsCredentialsRenewalSelector);
  const productsListDataFetched = useSelector(productsListDataFetchedSelector);
  const userMembershipCredentialProductId = useSelector(userMembershipCredentialProductIdSelector);
  const isCredentialsJourney = useSelector(isCredentialsJourneySelector);
  const examsPassedByUser = useSelector(examsPassSelector);

  const membershipEvent = useSelector(membershipEventSelector);

  // lapsed credential
  const customerInactiveCredentials = useSelector(customerInactiveCredentialsSelector);

  // suspended credential
  const suspendedCredentials = useSelector(customerSuspendedCredentialsSelector);
  const hasSuspendedCredentials: boolean = suspendedCredentials?.length > 0;

  const perStatus = useSelector(practicalExperienceRequirementStatusSelector);
  const isPERStatusCompleted = perStatus === MembershipTypes.PERPortfolioStatus.COMPLETED;
  const productListDataHasFLP = useSelector(flpProductListDataSelector);

  // renewals journey
  const isRenewalsJourney = useSelector(isRenewalsJourneySelector);
  const [activeCredentials, setActiveCredentials] = useState([]);
  const [renewedCredentials, setRenewedCredentials]: any = useState([]);
  const currency = useSelector(productCurrencySelector);

  // this function is horrible. refactor tasks:
  // dont have copy/pasted functions which also exist in MembershipSectionPanel.tsx
  // this logic is probably already existing inside selectors, so re-use from there
  // dont do logic like this in the UI! this belongs in the selector (or backend) and should be a property on the product
  const checkIfNotAlreadyRenewed = useCallback(
    (date: string) => {
      const currentYear = moment().year();
      const nextYear = moment().add(1, 'year').year();
      if (membershipEvent.isClickedCredentialsSectionsRenewal || membershipEvent.isClickedMembershipRenewal) {
        return moment(date).year() <= nextYear;
      }
      const isSameYear = moment(date).year() === currentYear;

      // If from prop page and first time purchase credentials
      if (isCredentialsJourney && isSameYear) {
        return false;
      }

      return isSameYear;
    },
    [membershipEvent, isCredentialsJourney]
  );

  useEffect(() => {
    const activeCredentialsArray: any = [];
    const renewedCreds: any = [];

    existingCredential.forEach(
      (credential: { productId: string; variant: { sku: string }; zuoraTermEndDate: string }) => {
        if (checkIfNotAlreadyRenewed(credential.zuoraTermEndDate)) {
          activeCredentialsArray.push(credential);
        } else {
          if (!renewedCreds.some((renewCred: any) => renewCred.productId === credential.productId)) {
            if (credential?.zuoraTermEndDate) {
              dispatch(removeMembershipPackageCredential(credential.productId));
              renewedCreds.push(credential);
            }
          }
        }
      }
    );

    setRenewedCredentials(renewedCreds);
    setActiveCredentials(activeCredentialsArray);
  }, [customerInactiveCredentials, existingCredential, checkIfNotAlreadyRenewed, dispatch]);

  // check if userChoice have the right sku but Id is not valid
  useEffect(() => {
    if (!isUpdated && relateCredentialProductList.length > 0) {
      const checkIfUserChoiceHaveItemWithAnEmptyId = userChoicesCredentials.filter(cred => !cred.productId && cred.sku);

      const addedCredentials = {
        productId: '',
        sku: '',
        numOfExams: '',
        eligibleExams: [],
        alternativeExams: [],
      };

      if (checkIfUserChoiceHaveItemWithAnEmptyId.length) {
        const addedCredHasSlugValueAsSKUArr: State.CredentialProducts[] = [];
        // 1. When There is/are items in the userChoice Cred that don't have productId this means it comes from Credentials Prop Page remove this from user choice
        checkIfUserChoiceHaveItemWithAnEmptyId.forEach(item => {
          dispatch(removeMembershipPackageCredentialBySku(item.sku));
        });

        // 2. Add the product with the right sku and with its right productId, numOfExams, eligibleExams, alternativeExams
        checkIfUserChoiceHaveItemWithAnEmptyId.forEach(item => {
          const product = relateCredentialProductList.find((prod: State.CredentialProducts) =>
            prod.variants?.some(variant => variant.sku === item.sku)
          );

          if (product?.productId) {
            addedCredentials.productId = product?.productId;
            const selectedVariant = product?.variants.find(variant => variant?.sku === item.sku);
            addedCredentials.numOfExams = selectedVariant?.num_of_exams || '';
            addedCredentials.eligibleExams = selectedVariant?.eligibleExams || [];
            addedCredentials.alternativeExams = selectedVariant?.alternativeExams || [];

            const { productId, numOfExams, eligibleExams, alternativeExams } = addedCredentials;
            dispatch(
              addMembershipPackageCredential(productId, item.sku, numOfExams, eligibleExams, '', alternativeExams)
            );
          } else {
            // Add the sku for the items added in userChoice that are presented as slug
            const addedCredHasSlugValueAsSKU = relateCredentialProductList.find(
              (prod: State.CredentialProducts) => prod?.slug === item.sku
            );
            if (addedCredHasSlugValueAsSKU) {
              addedCredHasSlugValueAsSKUArr.push(addedCredHasSlugValueAsSKU);
            }
          }
        });

        const { hasErrorInCredentials, ...others } = eligibilityExamChecker(
          [
            ...userChoicesCredentials,
            { ...addedCredentials, num_of_exams: addedCredentials.numOfExams },
          ] as State.UserChoiceCredential[],
          examsPassedByUser as State.ExamResponse[]
        );

        if (hasErrorInCredentials) {
          setErrorCredentialProps({ ...others });
          setHasErrorInCredentials(hasErrorInCredentials);
        }

        // If there's  added item that is included in users inactive credential then preselect the Reinstatement for this credential
        addedCredHasSlugValueAsSKUArr.forEach(data => {
          let ifLapsedCredential = false;
          customerInactiveCredentials?.map((inactiveCredential: any) => {
            data.variants?.map((variant: any) => {
              if (variant?.sku === inactiveCredential?.sku && !ifHasLapsedCredential) {
                setIfHasLapsedCredential(true);
                ifLapsedCredential = true;
              }
            });
          });

          const reinstatementPathwayLapsed = ifLapsedCredential
            ? data.variants.find((v: any) => v.pathwayKey?.key === MembershipTypes.CredentialPathwayKeys.reinstatement)
            : null;

          if (ifLapsedCredential && reinstatementPathwayLapsed) {
            setLapsedReinstatementPathway(reinstatementPathwayLapsed);
            setSelectedCredentialId(data.productId);
          }
        });
      }
      setIsUpdated(true);
    }
    // eslint-disable-next-line
  }, [relateCredentialProductList, customerInactiveCredentials]);

  const credentialProducts = useMemo(() => {
    if (
      isRenewalsJourney ||
      membershipEvent.isClickedMembershipUpgrade ||
      membershipEvent.isUserLoggedOutClickedApplyMembership
    ) {
      return relateCredentialProductList.map(product => ({
        ...product,
        selected: userChoicesCredentials.some(choice => choice.productId === product.productId),
      }));
    }

    return relateCredentialProductList
      .filter((product: State.CredentialProducts) => product.visible)
      .map(product => ({
        ...product,
        selected: userChoicesCredentials.some(choice => choice.productId === product.productId),
      }));
  }, [relateCredentialProductList, userChoicesCredentials, isRenewalsJourney, membershipEvent]);

  const hasNextPage = useMemo(() => {
    const NUM_PER_PAGE = !isRenewalsJourney ? 6 : 10;
    const credentialCategory = categories.filter(({ name }) => name === selectedCategory);

    const numbPages = Math.ceil(
      selectedCategory === ALL
        ? relateCredentialProductList.length / NUM_PER_PAGE
        : relateCredentialProductList.filter(product =>
            product.categories?.some(({ name }) => name === selectedCategory)
          ).length / NUM_PER_PAGE
    );

    return (credentialCategory[0]?.page ?? 0) < numbPages;
  }, [categories, relateCredentialProductList, selectedCategory, isRenewalsJourney]);

  const productCategoryCount = categories.length;

  useEffect(() => {
    dispatch(showMembershipFirstPageOfCredentials());
  }, [dispatch, productsListDataFetched]);

  // Re-fetch product list data if trigger comes from credentials renewal
  useEffect(() => {
    (async () => {
      setIsCredentialLoading(true);
      await dispatch(getProductsListData());
      setIsCredentialLoading(false);
    })();
  }, [dispatch, sectionsCredentialsRenewal, membershipEvent]);

  useEffect(() => {
    if (
      !!existingCredential.length &&
      !isAddExistingCredentialsLoaded &&
      !isCredentialsJourney &&
      relateCredentialProductList.length
    ) {
      let renewedCreds: any = [];

      setIsAddExistingCredentialsLoaded(true);
      existingCredential.forEach(
        (credential: { productId: string; variant: { sku: string }; zuoraTermEndDate: string }) => {
          const findProductFromCredsBySku = relateCredentialProductList?.find(item =>
            item.variants?.some(variant => variant?.sku === credential.variant.sku)
          );
          const checkProductIdEquality = findProductFromCredsBySku?.productId === credential.productId; // having the same id
          if (credential?.zuoraTermEndDate && checkIfNotAlreadyRenewed(credential.zuoraTermEndDate)) {
            const credentialID = checkProductIdEquality ? credential.productId : findProductFromCredsBySku?.productId;
            if (!credentialID) return;
            dispatch(addMembershipPackageCredential(credentialID, credential.variant.sku));
          } else {
            if (!renewedCreds.some((renewCred: any) => renewCred.productId === credential.productId)) {
              if (credential?.zuoraTermEndDate) {
                dispatch(removeMembershipPackageCredential(credential.productId));
                renewedCreds = [...renewedCreds, credential];
              }
            }
          }
        }
      );
      setRenewedCredentials(renewedCreds);
    }
  }, [
    dispatch,
    existingCredential,
    isAddExistingCredentialsLoaded,
    customerInactiveCredentials,
    isCredentialsJourney,
    renewedCredentials,
    checkIfNotAlreadyRenewed,
    relateCredentialProductList,
  ]);

  useEffect(() => {
    if (!!productID && decimal === 1) {
      setSelectedCredentialProductId(productID);
      setOpen(!isOpen);
    }
  }, [productID, isComingFromPropPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (credentialProducts?.length && selectedCredentialProductId) {
      const ifCGMACredential = credentialProducts?.find(
        (credentialProduct: any) =>
          credentialProduct.productId === selectedCredentialProductId &&
          credentialProduct.credentialKey === MembershipTypes.CredentialKeys.CGMA
      );
      if (ifCGMACredential?.productId) {
        setOpen(false);
      }
    }
  }, [credentialProducts, selectedCredentialProductId, dispatch]);

  useEffect(() => {
    if (isComingFromPropPage) setOpen(false);
    else {
      if (!selectedCredentialProductId && userMembershipCredentialProductId) {
        setSelectedCredentialProductId(userMembershipCredentialProductId);
        setOpen(true);
        dispatch(setCredentialProductId(''));
      }
    }
  }, [isComingFromPropPage, userMembershipCredentialProductId, selectedCredentialProductId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((upgradeMembershipModalOpen && !isComingFromPropPage) || applyMembershipModalOpen) {
      setOpen(false);
    }
  }, [upgradeMembershipModalOpen, isComingFromPropPage, applyMembershipModalOpen]);

  const handleMembershipCardCredentialButtonClick = useCallback(
    (details: {
      productId: string;
      selected: boolean;
      isCGMA?: boolean;
      cgmaVariant?: State.Variant | null;
      credentialKey?: MembershipTypes.CredentialKeys;
    }) => {
      const { productId, selected, isCGMA, cgmaVariant, credentialKey } = details;
      let isSelectedLapsedCredential = false;
      const shouldAddMembershipPackageCredential =
        ifHasLapsedCredential && lapsedReinstatementPathway && selectedCredentialId === productId;

      if (selected) {
        dispatch(removeMembershipPackageCredential(productId));
      } else {
        if (isCGMA && cgmaVariant) {
          dispatch(
            addMembershipPackageCredential(
              productId,
              cgmaVariant?.sku,
              cgmaVariant?.num_of_exams,
              cgmaVariant?.eligibleExams,
              cgmaVariant?.pathway_name,
              cgmaVariant?.alternativeExams,
              credentialKey
            )
          );
        }
        if (shouldAddMembershipPackageCredential) {
          dispatch(addMembershipPackageCredential(productId, lapsedReinstatementPathway?.sku));
          isSelectedLapsedCredential = true;
        }
      }
      const shouldOpen = !isCGMA && !isSelectedLapsedCredential && !selected;
      setSelectedCredentialProductId(productId);
      setOpen(shouldOpen);
    },
    [
      dispatch,
      setOpen,
      setSelectedCredentialProductId,
      ifHasLapsedCredential,
      lapsedReinstatementPathway,
      selectedCredentialId,
    ]
  );

  const filterByCategory = useCallback(
    (categoryName: string) => {
      if (productCategoryCount >= 2) {
        setSelectedCategory(categoryName);
        dispatch(filterMembershipCredentialByCategory(categoryName));
      }
    },
    [dispatch, productCategoryCount]
  );

  const variantsPriceInfoForUser = useCallback(
    (data: State.ProductCredential) => {
      const priceInfoForUser = Product.getProductPrice(
        data,
        data.variants[0].sku ?? '',
        [User.MembershipIdsEnum.MRUSR0001],
        currency.label
      );
      return priceInfoForUser.priceFinal?.amount ?? 0;
    },
    [currency.label]
  );

  const shouldDisableCardIfAlreadyRenewed = useMemo(() => {
    return renewedCredentials.some((renewed: any) => renewed.productId === selectedCredentialProductId);
  }, [renewedCredentials, selectedCredentialProductId]);

  const handleSeeMore = useCallback(() => {
    dispatch(membershipPackageUpdatePageOfSpecificCredentialCategory(selectedCategory));
    dispatch(seeMoreMembershipCredentialProduct(selectedCategory));
  }, [dispatch, selectedCategory]);

  const destinationURL = getPath(Routes.MEMBERSHIP_FORM);
  const membershipStatus = MembershipPackageAccordionStatus.Sections;

  useEffect(() => {
    const filteredCredentialProducts = relateCredentialProductList?.filter((product: State.ProductCredential) => {
      if ((isRenewalsJourney || hasSuspendedCredentials) && !isCredentialsJourney) {
        const isForRenewalCredential =
          activeCredentials &&
          activeCredentials.some((activeCredential: any) => activeCredential.productId === product.productId);
        if (!isForRenewalCredential) return;
      }
      return true;
    });

    if (filteredCredentialProducts.length && !isCategoryEventTriggered) {
      handleEvent(filteredCredentialProducts, membershipActionNames.STOREFRONT_AGGREGATION_VIEW_CREDENTIAL);
      setIsCategoryEventTriggered(true);
    }
  }, [
    relateCredentialProductList,
    activeCredentials,
    isRenewalsJourney,
    hasSuspendedCredentials,
    isCredentialsJourney,
    isCategoryEventTriggered,
  ]);

  if (isCredentialLoading) {
    return <FeedPlaceholder />;
  }

  const isConfirmBtnDisabled = areAllTruthy(
    useNewMembershipAICPA,
    !hasSelected,
    !renewedCredentials?.length,
    isCredentialsJourney
  );

  return (
    <MembershipPackagePanelWrapper>
      {!shouldDisableCardIfAlreadyRenewed && (
        <ModalCredentialPathWay
          isRenewal={isRenewal}
          isOpen={isOpen}
          setOpen={setOpen}
          selectedCredentialProductId={selectedCredentialProductId}
        />
      )}
      <CategoriesWrapper>
        {categories.map((category: State.ProductCategories) => {
          if (isRenewalsJourney) {
            return !!category?.name && category.name === 'All' ? (
              <StyledButton
                key={category.name}
                testId={`${category.name}`}
                isSelected={category.selected}
                onClick={filterByCategory.bind(null, category.name)}
                to={`${destinationURL}#${membershipStatus}`}
                textValue={category.name}
              >
                {category.name}
              </StyledButton>
            ) : null;
          }

          return !!category?.name ? (
            <StyledButton
              key={category.name}
              testId={`${category.name}`}
              isSelected={category.selected}
              onClick={filterByCategory.bind(null, category.name)}
              to={`${destinationURL}#${membershipStatus}`}
              textValue={category.name}
            >
              {category.name}
            </StyledButton>
          ) : null;
        })}
      </CategoriesWrapper>
      <br />
      <CredentialGrid>
        {credentialProducts.map(data => {
          const currentCredential = existingCredential?.some(
            (credential: { name: string }) => credential.name === data.name
          );
          const ifCGMA = data.credentialKey === MembershipTypes.CredentialKeys.CGMA;

          const inActiveCredentialsSkus: string[] = [];

          let ifLapsedCredential = false;
          customerInactiveCredentials?.map((inactiveCredential: any) => {
            inActiveCredentialsSkus.push(inactiveCredential?.sku);
            data.variants?.map((variant: any) => {
              if (variant?.sku === inactiveCredential?.sku && !ifHasLapsedCredential) {
                setIfHasLapsedCredential(true);
                ifLapsedCredential = true;
              }
            });
          });

          const reinstatementPathwayLapsed = ifLapsedCredential
            ? data.variants.find((v: any) => v.pathwayKey?.key === MembershipTypes.CredentialPathwayKeys.reinstatement)
            : null;

          if (ifLapsedCredential && reinstatementPathwayLapsed && !lapsedReinstatementPathway) {
            setLapsedReinstatementPathway(reinstatementPathwayLapsed);
            setSelectedCredentialId(data.productId);
          }
          if ((isRenewalsJourney || hasSuspendedCredentials) && !isCredentialsJourney) {
            // if renewals journey
            // if user has suspended credentials entry point coming from suspension email
            // then display only the active/suspended credentials (suspended credentials are also considered active)
            // isCredentialsJourney is only true if we apply for a new or upgrade credential
            const isForRenewalCredential = activeCredentials?.some(
              (activeCredential: any) => activeCredential.productId === data.productId
            );
            if (!isForRenewalCredential) return <></>;
          }
          const renewedCred = renewedCredentials.find(
            (r: any) => r.productId === data.productId || r.name === data.name
          );

          // Disable the add to cart button if outside renewal and credential is already purchased and credential is not lapsed
          const isAddToCartDisable: boolean = (() => {
            if (!existingCredential.length || isRenewal) return false;
            return existingCredential.some(
              (existingCred: { variant: { sku: string } }) =>
                existingCred.variant.sku === data.variants[0].sku &&
                !inActiveCredentialsSkus.includes(existingCred.variant.sku)
            );
          })();

          // Do not render CGMA Credential card if current user has FLP and has PER status not completed
          if (productListDataHasFLP && !isPERStatusCompleted && ifCGMA) {
            return null;
          }

          return (
            <StyledGridMembershipCardColumn mobile={16} tablet={6} computer={5} key={data.variants[0].sku}>
              <MembershipCard
                boughtOn={renewedCred?.zuoraTermStartDate || ''}
                disabled={renewedCredentials.some(
                  (renewed: any) => renewed.productId === data.productId || renewed.name === data.name
                )}
                headerTitle={data.name}
                description={data.description ?? ''}
                formattedPrice={formatPrice(fromCentsFormat(variantsPriceInfoForUser(data)), currency.label)}
                isCardSelected={data.selected}
                isCurrent={isRenewal && currentCredential ? currentCredential : false}
                slug={`${productTypeToSlug(data.productType as ProductTypes.ProductType)}/${data.slug}`}
                handleClick={handleMembershipCardCredentialButtonClick.bind(null, {
                  productId: data.productId,
                  selected: !!data.selected,
                  isCGMA: ifCGMA,
                  cgmaVariant: ifCGMA ? data?.variants[0] : null,
                  credentialKey: data.credentialKey as MembershipTypes.CredentialKeys | undefined,
                })}
                productDetails={data}
                isExistingMember={true}
                isEnable={!isAddToCartDisable}
              />
            </StyledGridMembershipCardColumn>
          );
        })}
      </CredentialGrid>
      {hasNextPage && (
        <SeeMoreWrapper>
          <StyledLabel onClick={handleSeeMore}>See more</StyledLabel>
        </SeeMoreWrapper>
      )}
      <ConfirmButtonWrapper hasNextPage={hasNextPage}>
        {isRenewal ? (
          <StyledConfirmButton
            testId={'confirm-btn'}
            onClick={handleClick}
            to={`${destinationURL}#${membershipStatus}`}
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.primary}
            disabled={isConfirmBtnDisabled}
          >
            Confirm
          </StyledConfirmButton>
        ) : (
          <StyledConfirmButton
            testId={'confirm-btn'}
            onClick={handleClick}
            to={`${destinationURL}#${membershipStatus}`}
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.primary}
            disabled={isConfirmBtnDisabled}
          >
            Next
          </StyledConfirmButton>
        )}
      </ConfirmButtonWrapper>
    </MembershipPackagePanelWrapper>
  );
};

interface StyledButtonProps {
  isSelected: boolean;
}

const activeCategoryButtonStyles = css`
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryPurple};
  color: ${props => props.theme.colors.neutralWhite};
  background-color: ${props => props.theme.colors.primaryPurple};
`;

const CategoriesWrapper = styled.div`
  text-align: left;
`;

const CredentialGrid = styled(Grid)`
  gap: ${props => props.theme.pxToRem(16)};
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin: ${props => `1 0 ${props.theme.pxToRem(20)} 0`} !important;
    width: auto;
    padding: 0 !important;
  }
`;

const StyledButton = styled(Button)<StyledButtonProps>`
  &&& {
    font-weight: ${props => props.theme.fontWeights.regular};
    border-radius: ${props => props.theme.pxToRem(6)};
    align-items: center;
    padding: ${props =>
      `${props.theme.pxToRem(8)} ${props.theme.pxToRem(14)} ${props.theme.pxToRem(8)} ${props.theme.pxToRem(14)}`};
    border-radius: ${props => props.theme.pxToRem(20)};

    margin: ${props =>
      `${props.theme.pxToRem(5)} ${props.theme.pxToRem(10)} ${props.theme.pxToRem(10)} ${props.theme.pxToRem(-4)}`};

    &:hover {
      ${activeCategoryButtonStyles}
    }

    &:focus {
      ${activeCategoryButtonStyles}
    }

    ${props =>
      props.isSelected
        ? css`
            ${activeCategoryButtonStyles}
          `
        : css`
            color: ${props.theme.colors.primaryPurple};
            background-color: ${props.theme.colors.neutralGrey2};
          `}
  }
`;

const StyledLabel = styled(Label)`
  &&& {
    font-family: 'Roboto';
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.regular};
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    background-color: transparent;
    color: ${props => props.theme.colors.primaryPurple};
    margin-left: ${props => props.theme.pxToRem(-14)} !important;

    &:hover {
      cursor: pointer;
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: left;
      padding-left: 0;
      margin-left: ${props => props.theme.pxToRem(-11)} !important;
    }
  }
`;

const SeeMoreWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(34)};
  padding-left: 0;

  ${props => props.theme.mediaQueries.desktopOnly} {
    top: ${props => props.theme.pxToRem(40)};
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    &&& {
      top: ${props => props.theme.pxToRem(70)};
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: left;
    padding-left: 0;
  }
`;

const StyledConfirmButton = styled(Button)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(-85)};
    width: ${props => props.theme.pxToRem(116)};
    height: ${props => props.theme.pxToRem(24)};
    border-radius: ${props => props.theme.pxToRem(4)};
    background-color: ${props => props.theme.colors.primaryPurple};
    color: ${props => props.theme.colors.neutralWhite};

    &:hover {
      ${activeCategoryButtonStyles}
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(-11)} !important;
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    position: relative;
    top: ${props => props.theme.pxToRem(25)};
  }
`;

const ConfirmButtonWrapper = styled.div<{ hasNextPage: boolean }>`
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(10)};
    text-align: left;
  }
  ${props => props.theme.mediaQueries.desktopOnly} {
    display: flex;
    justify-content: center;
    margin-top: ${props => props.theme.pxToRem(props.hasNextPage ? -26 : 34)};
  }
`;

const StyledGridMembershipCardColumn = styled(Grid.Column)`
  padding: 0 !important;
`;
