import styled from 'styled-components';
import { Table as SemanticUITable } from 'semantic-ui-react';

const StyledSemanticUITable: any = styled(SemanticUITable)`
  &&&& {
    border-style: solid;
    border-width: 0 0 ${props => props.theme.pxToRem(1)} 0;
    border-color: ${props => props.theme.colors.neutralGrey3};
    border-radius: 0;
    margin: 0;

    tr {
      :nth-child(2n) {
        background-color: ${props => props.theme.colors.fadedGray};
      }

      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-top: ${props => props.theme.pxToRem(24)};
        padding-bottom: ${props => props.theme.pxToRem(24)};
        box-shadow: 0 ${props => props.theme.pxToRem(-1)} 0 0 ${props => props.theme.colors.neutralGrey3} inset !important;
        margin-top: ${props => props.theme.pxToRem(16)};

        :first-child {
          padding-top: 0;
        }
      }
    }

    tr th {
      padding: ${props => props.theme.pxToRem(11)} ${props => props.theme.pxToRem(20)};
      border-bottom-color: ${props => props.theme.colors.neutralGrey3};
      background-color: ${props => props.theme.colors.neutralGrey1};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.medium};
      line-height: 1.57;
    }

    tr td {
      padding: ${props => props.theme.pxToRem(21)} ${props => props.theme.pxToRem(20)};
      vertical-align: baseline;
      border-top-color: ${props => props.theme.colors.neutralGrey3};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.light};
      line-height: 1.5;

      ${props => props.theme.mediaQueries.mobileOnly} {
        padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;

        :first-child {
          font-weight: ${props => props.theme.fontWeights.regular};
        }
      }
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      border-width: 0;
      margin-top: 0;
    }
  }
`;

StyledSemanticUITable.Body = SemanticUITable.Body;
StyledSemanticUITable.Cell = SemanticUITable.Cell;
StyledSemanticUITable.Row = SemanticUITable.Row;
StyledSemanticUITable.Header = SemanticUITable.Header;
StyledSemanticUITable.HeaderCell = SemanticUITable.HeaderCell;

export const Table = StyledSemanticUITable;
