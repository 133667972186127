import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { ButtonLink, ButtonEnums, MembershipStepperNotificationHeader } from 'components/atoms';
import styled from 'styled-components';
import { ReactComponent as InfoBubbleInverted } from 'resources/images/info-bubble-inverted.svg';

const POP_UP_TEXT = `You'll be guided through the application with prompts asking about your educational experience, achievements,
and work history. We ask these questions to advise on the best learning pathway and point of entry into the
CGMA syllabus.`;

export const MembershipApplicationInfo: React.FC<any> = ({
  backButton,
  applicationProcessInfoHeader,
  membershipRequirementsHeader,
  membershipRequirements,
  proceedButtonPath,
  proceedButtonOnClickFunc,
  showTooltip,
}) => {
  return (
    <StyledContainer>
      {backButton}
      <StyledWelcome>{applicationProcessInfoHeader}</StyledWelcome>
      <StyledParagraph>
        {membershipRequirementsHeader}
        {showTooltip && (
          <StyledLabelTooltip>
            <Popup content={POP_UP_TEXT} trigger={<StyledInfoBubble />} position="bottom center" style={popupStyle} />
            <StyledIcon name="info circle" />
          </StyledLabelTooltip>
        )}
      </StyledParagraph>
      <StyledList>
        {membershipRequirements.map((item: string) => (
          <li key={item}>
            <span>{item}</span>
          </li>
        ))}
      </StyledList>
      <MembershipStepperNotificationHeader />
      <StyledButton
        size={ButtonEnums.sizes.small}
        variant={ButtonEnums.variants.primary}
        testId="membership-start"
        to={proceedButtonPath}
        iconName="arrow right"
        iconPosition={ButtonEnums.iconPosition.right}
        onClick={proceedButtonOnClickFunc}
      >
        Let's get started
      </StyledButton>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  * {
    font-family: ${props => props.theme.fontFamily};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledWelcome = styled.div`
  font-size: ${props => props.theme.fontSizes.xl};
  max-width: ${props => props.theme.pxToRem(645)};
  margin-bottom: ${props => props.theme.pxToRem(16)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
  }
`;

const StyledParagraph = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  max-width: ${props => props.theme.pxToRem(560)};
  margin-bottom: ${props => props.theme.pxToRem(16)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
  }
`;

const StyledList = styled.ul`
  margin-bottom: ${props => props.theme.pxToRem(16)};
  > li {
    font-size: ${props => props.theme.fontSizes.xxs};
    > span {
      padding-left: ${props => props.theme.pxToRem(5)};
      font-size: ${props => props.theme.fontSizes.s};
    }
  }
`;

const StyledButton = styled(ButtonLink)`
  &&&&& {
    height: ${props => props.theme.pxToRem(48)};
    font-size: ${props => props.theme.fontSizes.m};
    min-width: ${props => props.theme.pxToRem(300)};
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(8)};
    i {
      color: ${props => props.theme.colors.neutralWhite};
      position: relative;
      left: ${props => props.theme.pxToRem(30)};
    }
    > span {
      color: ${props => props.theme.colors.neutralWhite};
      margin-left: ${props => props.theme.pxToRem(30)};
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      i {
        left: ${props => props.theme.pxToRem(60)};
      }
    }
  }
`;

const popupStyle = {
  cursor: 'pointer',
};

const StyledLabelTooltip = styled.label`
  font: normal ${props => props.theme.pxToRem(16)} Roboto;
  line-height: 1.5;
  letter-spacing: normal;
  position: relative;
  svg {
    position: absolute;
    top: ${props => props.theme.pxToRem(3)};
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
    margin-left: ${props => props.theme.pxToRem(6)};
  }
`;

const StyledInfoBubble = styled(InfoBubbleInverted)`
  width: auto;
  height: 100%;
  fill: ${props => props.theme.colors.interfaceBlue};
  padding-right: ${props => props.theme.pxToRem(8)};
  padding-left: ${props => props.theme.pxToRem(8)};
`;

const StyledIcon = styled(Icon)<{ isCursorPointer?: boolean; isFloatRight?: boolean }>`
  &&&& {
    color: ${props => props.theme.colors.primaryPurple};
    width: ${props => props.theme.pxToRem(26)};
    height: ${props => props.theme.pxToRem(18)};
    font-size: ${props => props.theme.fontSizes.s};
    margin-right: ${props => props.theme.pxToRem(10)};
    cursor: ${props => (props.isCursorPointer ? 'pointer' : 'default')};
    ${props => (props.isFloatRight ? ' float: right' : '')};
  }
`;
