import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  Button as StyledButton,
  ButtonEnums,
  Divider,
  Modal,
  OnlyDesktopCSS,
  OnlyMobileCSS,
  ButtonVariants,
} from 'components/atoms';

import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';

interface Props {
  isOpenModal: boolean;
  title?: string;
  bodyText?: string;
  textWithLink?: string;
  onClickCheckout(): any;
  handleModal(): any;
  loading: boolean;
}

export const MembershipBenefitModal: React.FC<Props> = ({
  isOpenModal,
  title,
  bodyText,
  textWithLink,
  onClickCheckout,
  handleModal,
  loading,
}) => {
  const [isOpen, setOpen]: any = useState(false);

  useEffect(() => {
    setOpen(isOpenModal);
  }, [isOpenModal]);

  const onCheckout = useCallback(() => {
    onClickCheckout();
  }, [onClickCheckout]);

  const onCloseModal = useCallback(() => {
    setOpen(false);
    handleModal();
  }, [handleModal]);

  return (
    <StyledModal size="small" heading={title} open={isOpen}>
      <CloseButton>
        <StyledCloseIcon onClick={onCloseModal} />
      </CloseButton>
      <Spacer />
      <CenteredText>
        {bodyText}
        {textWithLink && (
          <>
            <span dangerouslySetInnerHTML={{ __html: textWithLink }} />
          </>
        )}
      </CenteredText>
      <DividerWithBottomMargin />
      <CenteredButtons>
        <DesktopStyled>
          <GoBackButton onClick={onCloseModal} isDisabled={loading} />
          <CheckoutButton onClick={onCheckout} isLoading={loading} />
        </DesktopStyled>
        <MobileStyled>
          <GoBackButton onClick={onCloseModal} isDisabled={loading} />
          <CheckoutButton onClick={onCheckout} isLoading={loading} />
        </MobileStyled>
      </CenteredButtons>
    </StyledModal>
  );
};

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;
const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

interface ModalButtonProps {
  onClick: () => void;
  variant?: ButtonVariants;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const GoBackButton: React.FC<ModalButtonProps> = ({ onClick, isDisabled }) => (
  <StyledGoBackButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.secondary}
    testId="understand-ethics-modal"
    disabled={isDisabled}
  >
    Go back and continue making selections
  </StyledGoBackButton>
);

const StyledGoBackButton = styled(StyledButton)`
  height: ${props => props.theme.pxToRem(34)};
  margin: ${props => props.theme.pxToRem(5)};
  object-fit: contain;
`;

const CheckoutButton: React.FC<ModalButtonProps> = ({ onClick, isLoading }) => (
  <StyledCheckoutButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId="understand-ethics-modal"
    loading={isLoading}
  >
    Checkout with my selections
  </StyledCheckoutButton>
);

const StyledCheckoutButton = styled(StyledButton)`
  height: ${props => props.theme.pxToRem(34)};
  margin: 0 0 0 ${props => props.theme.pxToRem(10)};
  object-fit: contain;
`;
const StyledModal = styled(Modal)`
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      height: ${props => props.theme.pxToRem(34)};
      margin: ${props => props.theme.pxToRem(5)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(10)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
