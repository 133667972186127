import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

interface Props {
  iconName: string;
  navTitle: string;
  mainLinkTo: string;
  mainLinkText?: string;
  children: any;
}

export const NavigationBox: React.FC<Props> = ({ iconName, navTitle, mainLinkTo, mainLinkText, children }) => (
  <StyledNavBox>
    <StyledNavTop>
      <StyledNavTitle>
        <StyledIcon name={iconName} />
        {navTitle}
      </StyledNavTitle>
      {children}
    </StyledNavTop>
    <StyledNavBottom />
  </StyledNavBox>
);

const StyledNavBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${props => props.theme.pxToRem(290)};
  width: ${props => props.theme.pxToRem(350)};
  box-shadow: 0 0 ${props => props.theme.pxToRem(6)} 0 ${props => props.theme.colors.neutralGrey3};
  border-radius: ${props => props.theme.pxToRem(14)};
  padding-top: ${props => props.theme.pxToRem(25)};
  padding-bottom: 0;
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

const StyledNavTop = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${props => props.theme.pxToRem(24)};
`;

const StyledNavBottom = styled.div`
  height: ${props => props.theme.pxToRem(30)};
  padding: ${props => props.theme.pxToRem(15)} ${props => props.theme.pxToRem(24)};
  border-bottom-left-radius: ${props => props.theme.pxToRem(14)};
  border-bottom-right-radius: ${props => props.theme.pxToRem(14)};
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    height: ${props => props.theme.pxToRem(5)};
    width: 100%;
    background-color: ${props => props.theme.colors.primaryPurple};
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
  }
`;

const StyledNavTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.primaryPurple};
`;
