import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { default as request } from 'utils/GraphQLClient';
import { personAccountIdSelector } from 'modules/user/selectors';
import { GET_REQUIRED_DOCUMENT } from 'mxp-graphql-queries';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchRequiredDocuments: any = createAction(
  'requiredDocuments/GET_REQUIRED_DOCUMENT',
  async (exemptionReferenceId: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const accountId = personAccountIdSelector(state);
    return request(GET_REQUIRED_DOCUMENT, { accountId, exemptionReferenceId }).then((response: any) => {
      return response.getRequiredDocument;
    });
  }
);
