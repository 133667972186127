import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { MembershipTypes, PearsonVue as PvType } from 'mxp-schemas';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { FeedTargetableLink } from 'components/atoms/FeedTargetableLink/FeedTargetableLink';
import { Container } from 'components/atoms/Container/Container';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { ExamCardNotification } from './ExamCardNotification';
import { Admin as AdminUtils } from 'mxp-utils';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import {
  cimaMembershipsTermTypeSelector,
  cimaMembershipSelector,
  learningPathwaySelector,
} from 'modules/user/selectors';

export enum EXAM_CARD_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface Props {
  type?: string;
  subjectTitle?: string;
  status: MembershipTypes.CimaExamCardStatus;
  availableCredits?: string;
  usedCredits?: string;
  handleWaiveExemptionClick?: (event?: any) => void;
  handleBookNowClick?: (event?: any) => void;
  handleBuyExamCreditsClick?: (event?: any) => void;
  handleTakeOnlineExamClick?: (event?: any) => void;
  handleRescheduleClick?: (event?: any) => void;
  handleCancelExamClick?: (event?: any) => void;
  handleViewResultsClick?: (event?: any) => void;
  handleAccessNowClick?: (event?: any) => void;
  bookedExamDate?: string;
  bookedExamAddress?: string;
  bookedExamAddressCountry?: string;
  passedDate?: string;
  flpPlatformLink?: string;
  showCredits?: boolean;
  isGatewayNotPassed?: boolean;
  epaButtonLabel?: string;
  cardSize?: EXAM_CARD_SIZE;
  ratificationStatus?: string;
  overrideStatus?: string;
  showRescheduleAndCancel?: boolean;
  handleLearnMoreClick?: (event?: any) => void;
  isExempted?: boolean;
  isNonWaivable?: boolean;
  showViewResults?: boolean;
}

export const ExamCard: React.FC<Props> = ({
  type,
  subjectTitle,
  status,
  availableCredits,
  usedCredits,
  handleWaiveExemptionClick,
  handleBookNowClick,
  handleBuyExamCreditsClick,
  handleTakeOnlineExamClick,
  handleRescheduleClick,
  handleCancelExamClick,
  handleViewResultsClick,
  handleAccessNowClick,
  handleLearnMoreClick,
  bookedExamDate,
  bookedExamAddress,
  bookedExamAddressCountry,
  passedDate,
  flpPlatformLink,
  showCredits = true,
  epaButtonLabel,
  isGatewayNotPassed,
  cardSize = EXAM_CARD_SIZE.SMALL,
  ratificationStatus,
  overrideStatus,
  showRescheduleAndCancel,
  isExempted = false,
  isNonWaivable = false,
  showViewResults,
}) => {
  // Identify CIMA CGMA Affiliate
  const currentMembershipKey = useSelector(cimaMembershipsTermTypeSelector);
  const cimaMemBody = useSelector(cimaMembershipSelector)?.membershipBody || '';
  const isCgmaAffiliate = AdminUtils.isCimaCgmaAffiliateType(cimaMemBody, currentMembershipKey);

  const showPassedFailedNotBookedDetails = [
    MembershipTypes.CimaExamCardStatus.PASSED,
    MembershipTypes.CimaExamCardStatus.EXAM_NOT_BOOKED,
    MembershipTypes.CimaExamCardStatus.FAILED,
    MembershipTypes.CimaExamCardStatus.EPA_DEFAULT,
    MembershipTypes.CimaExamCardStatus.EPA_LOCKED,
    MembershipTypes.CimaExamCardStatus.WAIVED,
  ].includes(status);

  const handleWaiveExemptionButtonClick = useCallback(
    (event?: any) => {
      if (handleWaiveExemptionClick) handleWaiveExemptionClick(event);
    },
    [handleWaiveExemptionClick]
  );

  const handleBookNowButtonClick = useCallback(
    (event?: any) => {
      if (handleBookNowClick) handleBookNowClick(event);
    },
    [handleBookNowClick]
  );

  const handleTakeOnlineExamButtonClick = useCallback(
    (event?: any) => {
      if (handleTakeOnlineExamClick) handleTakeOnlineExamClick(event);
    },
    [handleTakeOnlineExamClick]
  );

  const handleRescheduleButtonClick = useCallback(
    (event?: any) => {
      if (handleRescheduleClick) handleRescheduleClick(event);
    },
    [handleRescheduleClick]
  );

  const handleCancelExamButtonClick = useCallback(
    (event?: any) => {
      if (handleCancelExamClick) handleCancelExamClick(event);
    },
    [handleCancelExamClick]
  );

  const handleViewResultsButtonClick = useCallback(
    (event?: any) => {
      if (handleViewResultsClick) handleViewResultsClick(event);
    },
    [handleViewResultsClick]
  );

  const handleAccessNowButtonClick = useCallback(
    (event?: any) => {
      if (handleAccessNowClick) handleAccessNowClick(event);
    },
    [handleAccessNowClick]
  );
  const handleLearnMoreButtonClick = useCallback(
    (event?: any) => {
      if (handleLearnMoreClick) handleLearnMoreClick(event);
    },
    [handleLearnMoreClick]
  );

  const isFlpCardVariant = Boolean(
    status === MembershipTypes.CimaExamCardStatus.FLP || status === MembershipTypes.CimaExamCardStatus.FLP_COMPLETE
  );

  const isBookNowButtonVisible =
    Boolean(
      (status !== MembershipTypes.CimaExamCardStatus.EPA_FAILED &&
        status === MembershipTypes.CimaExamCardStatus.EXAM_NOT_BOOKED) ||
        status === MembershipTypes.CimaExamCardStatus.FAILED ||
        (status !== MembershipTypes.CimaExamCardStatus.EXEMPT &&
          status !== MembershipTypes.CimaExamCardStatus.LOCKED &&
          status !== MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED &&
          status !== MembershipTypes.CimaExamCardStatus.EXAM_BOOKED &&
          status !== MembershipTypes.CimaExamCardStatus.EPA_LOCKED &&
          status !== MembershipTypes.CimaExamCardStatus.PASSED &&
          status !== MembershipTypes.CimaExamCardStatus.RESULTS_PENDING &&
          status !== MembershipTypes.CimaExamCardStatus.EPA_DEFAULT &&
          status !== MembershipTypes.CimaExamCardStatus.EPA_FAILED)
    ) && !isCgmaAffiliate;
  const isAccessNowButtonVisible = Boolean(
    [
      MembershipTypes.CimaExamCardStatus.EPA_LOCKED,
      MembershipTypes.CimaExamCardStatus.EPA_DEFAULT,
      MembershipTypes.CimaExamCardStatus.EPA_FAILED,
    ].includes(status)
  );

  const isExamCreditsVisible = Boolean(
    ([
      MembershipTypes.CimaExamCardStatus.EXAM_NOT_BOOKED,
      MembershipTypes.CimaExamCardStatus.FAILED,
      MembershipTypes.CimaExamCardStatus.WAIVED,
    ].includes(status) &&
      showCredits) ||
      (!isAccessNowButtonVisible && overrideStatus === PvType.ExamResultOverrideStatus.voided)
  );

  const learningPathway = useSelector(learningPathwaySelector);

  const isWaiveExemptionLinkVisible =
    !isNonWaivable &&
    (isExempted ||
      (learningPathway !== MembershipTypes.Pathway.APPRENTICE_L4 &&
        learningPathway !== MembershipTypes.Pathway.APPRENTICE_L7 &&
        status === MembershipTypes.CimaExamCardStatus.EXEMPT));

  return (
    <StyledCard status={status} isFlpCard={isFlpCardVariant} cardSize={cardSize}>
      <StyledExamTitleDiv>
        <StyledExamTitle status={status}>{type}</StyledExamTitle>
        <ExamCardNotification status={status} isGatewayNotPassed={isGatewayNotPassed} isExempted={isExempted} />
      </StyledExamTitleDiv>
      {(cardSize === EXAM_CARD_SIZE.SMALL || cardSize === EXAM_CARD_SIZE.MEDIUM) && (
        <>
          <StyledSubjectTitle status={status}>{subjectTitle}</StyledSubjectTitle>
          {isGatewayNotPassed && (
            <StyledAlertDiv>
              <StyledIconError />
              <StyledAlertText>Please contact your tuition provider to pass your gateway</StyledAlertText>
            </StyledAlertDiv>
          )}
          {(status === MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED ||
            status === MembershipTypes.CimaExamCardStatus.EXAM_BOOKED) && (
            <>
              <StyledExamBookedDate>{bookedExamDate}</StyledExamBookedDate>
              {bookedExamAddress && (
                <>
                  <StyledExamBookedAddress>{bookedExamAddress}</StyledExamBookedAddress>
                  <StyledExamBookedAddress>{bookedExamAddressCountry}</StyledExamBookedAddress>
                </>
              )}
            </>
          )}
          {isExamCreditsVisible && (
            <StyledCreditsContainer>
              <StyledCreditTitle>EXAM CREDITS</StyledCreditTitle>

              <StyledCreditDetailsRow>
                <div>
                  <StyledCredit isBold={true}>{availableCredits}</StyledCredit>
                  <StyledCredit>Available | </StyledCredit>
                  <StyledCredit isBold={true}>{usedCredits}</StyledCredit>
                  <StyledCredit>
                    used{' '}
                    <IconPopup
                      data={
                        'If you have a credit for this exam it will show here. If you do not have a credit, you will pay for your exam on the Pearson Vue website when you book.'
                      }
                      subData={''}
                    />
                  </StyledCredit>
                </div>
              </StyledCreditDetailsRow>
            </StyledCreditsContainer>
          )}
          <StyledButtonContainer cardSize={cardSize}>
            {(status === MembershipTypes.CimaExamCardStatus.FLP ||
              status === MembershipTypes.CimaExamCardStatus.FLP_COMPLETE) && (
              <FeedTargetableLink testId="flp-platform-link" title="Go to FLP Platform" link={flpPlatformLink ?? ''} />
            )}

            {isWaiveExemptionLinkVisible && (
              <StyledWaiveExemption
                overrideAnalytics={true}
                variant={ButtonEnums.variants.link}
                testId={'waive-exemption-button'}
                onClick={handleWaiveExemptionButtonClick}
              >
                Waive exemption
              </StyledWaiveExemption>
            )}

            {isBookNowButtonVisible && (
              <StyledBookNowButton
                overrideAnalytics={true}
                variant={ButtonEnums.variants.primary}
                testId={'book-now-button'}
                onClick={handleBookNowButtonClick}
              >
                Book now
              </StyledBookNowButton>
            )}

            {isAccessNowButtonVisible && (
              <StyledBookNowButton
                variant={
                  [
                    MembershipTypes.CimaExamCardStatus.EPA_DEFAULT,
                    MembershipTypes.CimaExamCardStatus.EPA_FAILED,
                  ].includes(status) && !isGatewayNotPassed
                    ? ButtonEnums.variants.primary
                    : ButtonEnums.variants.secondary
                }
                overrideAnalytics={true}
                testId={'access-now-button'}
                onClick={
                  status === MembershipTypes.CimaExamCardStatus.EPA_LOCKED && type === 'Documents'
                    ? handleLearnMoreButtonClick
                    : handleAccessNowButtonClick
                }
              >
                {epaButtonLabel}
              </StyledBookNowButton>
            )}

            {[
              MembershipTypes.CimaExamCardStatus.EPA_FAILED,
              MembershipTypes.CimaExamCardStatus.FAILED,
              MembershipTypes.CimaExamCardStatus.PASSED,
            ].includes(status) &&
              showViewResults &&
              overrideStatus !== PvType.ExamResultOverrideStatus.voided &&
              ratificationStatus !== PvType.ExamResultRatificationStatus.onHold &&
              overrideStatus !== PvType.ExamResultOverrideStatus.technicalError && (
                <StyledBookNowButton
                  overrideAnalytics={true}
                  variant={ButtonEnums.variants.secondary}
                  testId={'view-results-button'}
                  onClick={handleViewResultsButtonClick}
                >
                  View Results
                </StyledBookNowButton>
              )}

            {overrideStatus !== PvType.ExamResultOverrideStatus.voided &&
              status === MembershipTypes.CimaExamCardStatus.PASSED && (
                <StyledPassedDate>{bookedExamDate}</StyledPassedDate>
              )}

            {(status === MembershipTypes.CimaExamCardStatus.EXAM_BOOKED ||
              status === MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED) && (
              <>
                {handleTakeOnlineExamClick && (
                  <StyledButtonWhiteLink
                    overrideAnalytics={true}
                    variant={ButtonEnums.variants.link}
                    testId={'take-online-exam-button'}
                    onClick={handleTakeOnlineExamButtonClick}
                  >
                    Take online exam
                  </StyledButtonWhiteLink>
                )}
                {!showRescheduleAndCancel && (
                  <>
                    <StyledButtonWhiteLink
                      overrideAnalytics={true}
                      variant={ButtonEnums.variants.link}
                      testId={'reschedule-button'}
                      onClick={handleRescheduleButtonClick}
                    >
                      Reschedule exam
                    </StyledButtonWhiteLink>
                    <StyledButtonWhiteLink
                      overrideAnalytics={true}
                      variant={ButtonEnums.variants.link}
                      testId={'cancel-exam-button'}
                      onClick={handleCancelExamButtonClick}
                    >
                      Cancel exam
                    </StyledButtonWhiteLink>
                  </>
                )}
              </>
            )}
          </StyledButtonContainer>
        </>
      )}
      {cardSize === EXAM_CARD_SIZE.LARGE && (
        <Container>
          <StyledLargeDiv
            isBooked={
              status === MembershipTypes.CimaExamCardStatus.EXAM_BOOKED ||
              status === MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED
            }
            cardSize={cardSize}
          >
            <StyledSubjectTitle status={status}>{subjectTitle}</StyledSubjectTitle>
            <StyledBookedDetailsDiv>
              {(status === MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED ||
                status === MembershipTypes.CimaExamCardStatus.EXAM_BOOKED) && (
                <>
                  <StyledExamBookedDateLarge>{bookedExamDate}</StyledExamBookedDateLarge>
                  {bookedExamAddress && (
                    <>
                      <StyledExamBookedAddressLarge>{bookedExamAddress}</StyledExamBookedAddressLarge>
                      <StyledExamBookedAddressLarge>{bookedExamAddressCountry}</StyledExamBookedAddressLarge>
                    </>
                  )}
                </>
              )}
            </StyledBookedDetailsDiv>
            <StyledBookedButtonDiv>
              {(status === MembershipTypes.CimaExamCardStatus.EXAM_BOOKED ||
                status === MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED) && (
                <StyledOnlineBookedDiv>
                  {status === MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED && (
                    <StyledButtonWhiteLink
                      overrideAnalytics={true}
                      variant={ButtonEnums.variants.link}
                      testId={'take-online-exam-button'}
                      onClick={handleTakeOnlineExamButtonClick}
                    >
                      Take online exam
                    </StyledButtonWhiteLink>
                  )}
                  {!showRescheduleAndCancel && (
                    <>
                      <StyledButtonWhiteLink
                        overrideAnalytics={true}
                        variant={ButtonEnums.variants.link}
                        testId={'reschedule-button'}
                        onClick={handleRescheduleButtonClick}
                      >
                        Reschedule exam
                      </StyledButtonWhiteLink>
                      <StyledButtonWhiteLink
                        overrideAnalytics={true}
                        variant={ButtonEnums.variants.link}
                        testId={'cancel-exam-button'}
                        onClick={handleCancelExamButtonClick}
                      >
                        Cancel exam
                      </StyledButtonWhiteLink>
                    </>
                  )}
                </StyledOnlineBookedDiv>
              )}
            </StyledBookedButtonDiv>
          </StyledLargeDiv>
          {isWaiveExemptionLinkVisible && (
            <StyledWaiveExemption
              overrideAnalytics={true}
              variant={ButtonEnums.variants.link}
              testId={'waive-exemption-button'}
              onClick={handleWaiveExemptionButtonClick}
            >
              Waive exemption
            </StyledWaiveExemption>
          )}
          {showPassedFailedNotBookedDetails && (
            <StyledLargeDiv
              isBooked={
                status === MembershipTypes.CimaExamCardStatus.EXAM_BOOKED ||
                status === MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED
              }
            >
              {status === MembershipTypes.CimaExamCardStatus.PASSED && <HiddenSpan>Passed</HiddenSpan>}
              {(status === MembershipTypes.CimaExamCardStatus.EXAM_NOT_BOOKED ||
                status === MembershipTypes.CimaExamCardStatus.FAILED ||
                status === MembershipTypes.CimaExamCardStatus.WAIVED) &&
                showCredits && (
                  <StyledCreditsContainer>
                    <StyledCreditTitle>EXAM CREDITS</StyledCreditTitle>

                    <StyledCreditDetailsRow>
                      <div>
                        <StyledCredit isBold={true}>{availableCredits}</StyledCredit>
                        <StyledCredit>Available | </StyledCredit>
                        <StyledCredit isBold={true}>{usedCredits}</StyledCredit>
                        <StyledCredit>
                          used{' '}
                          <IconPopup
                            data={
                              'If you have a credit for this exam it will show here. If you do not have a credit, you will pay for your exam on the Pearson Vue website when you book.'
                            }
                            subData={''}
                          />
                        </StyledCredit>
                      </div>
                    </StyledCreditDetailsRow>
                  </StyledCreditsContainer>
                )}

              {!isCgmaAffiliate && (
                <StyledLargeButtonContainer passed={status === MembershipTypes.CimaExamCardStatus.PASSED}>
                  {((status !== MembershipTypes.CimaExamCardStatus.EPA_FAILED &&
                    status === MembershipTypes.CimaExamCardStatus.EXAM_NOT_BOOKED) ||
                    status === MembershipTypes.CimaExamCardStatus.FAILED ||
                    status === MembershipTypes.CimaExamCardStatus.WAIVED) && (
                    <StyledBookNowButtonL
                      overrideAnalytics={true}
                      variant={ButtonEnums.variants.primary}
                      testId={'book-now-button'}
                      onClick={handleBookNowButtonClick}
                    >
                      Book now
                    </StyledBookNowButtonL>
                  )}

                  {[
                    MembershipTypes.CimaExamCardStatus.FAILED,
                    MembershipTypes.CimaExamCardStatus.PASSED,
                    MembershipTypes.CimaExamCardStatus.EPA_FAILED,
                  ].includes(status) &&
                    showViewResults && (
                      <StyledBookNowButtonL
                        overrideAnalytics={true}
                        variant={ButtonEnums.variants.secondary}
                        testId={'view-results-button'}
                        onClick={handleViewResultsButtonClick}
                      >
                        View Results
                      </StyledBookNowButtonL>
                    )}
                  {status === MembershipTypes.CimaExamCardStatus.PASSED && (
                    <StyledPassedDate>{bookedExamDate}</StyledPassedDate>
                  )}
                </StyledLargeButtonContainer>
              )}
            </StyledLargeDiv>
          )}
        </Container>
      )}
    </StyledCard>
  );
};

interface StyledCardProps {
  status: MembershipTypes.CimaExamCardStatus;
  isFlpCard?: boolean;
  cardSize: EXAM_CARD_SIZE;
}

interface StyledTextProps {
  isBold?: boolean;
  status?: MembershipTypes.CimaExamCardStatus;
}

const StyledCard = styled.div<StyledCardProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%
  border-radius: ${props => props.theme.pxToRem(8)};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);
  padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(16)};
  width: 100%;

  ${({ cardSize }) =>
    cardSize === EXAM_CARD_SIZE.MEDIUM &&
    css`
      height: ${props => props.theme.pxToRem(261)};
    `}

  ${({ isFlpCard }) =>
    isFlpCard &&
    css`
      min-height: ${props => props.theme.pxToRem(30)};
    `}

  ${({ status }) =>
    (status === MembershipTypes.CimaExamCardStatus.EXEMPT || status === MembershipTypes.CimaExamCardStatus.LOCKED) &&
    css`
      background: ${props => props.theme.colors.neutralGrey1};
    `}

	${({ status }) =>
    (status === MembershipTypes.CimaExamCardStatus.EXAM_BOOKED ||
      status === MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED) &&
    css`
      background: linear-gradient(77deg, #3a5dae, #763aae);
    `}

    ${props => props.theme.mediaQueries.mobileOnly} {
    height: 100%;
  }
`;

const StyledExamTitle = styled.span<StyledTextProps>`
  text-transform: uppercase;
  line-height: ${props => props.theme.pxToRem(16)};
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
  font-size: ${props => props.theme.fontSizes.xs};

  ${({ status }) =>
    (status === MembershipTypes.CimaExamCardStatus.EXAM_BOOKED ||
      status === MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED) &&
    css`
      color: ${props => props.theme.colors.neutralWhite};
    `}
`;

const StyledCreditTitle = styled.p`
  letter-spacing: ${props => props.theme.pxToRem(2)};
  font-size: ${props => props.theme.fontSizes.xxs};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledSubjectTitle = styled.p<StyledTextProps>`
  line-height: 1.33;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.bold};

  ${({ status }) =>
    (status === MembershipTypes.CimaExamCardStatus.EXAM_BOOKED ||
      status === MembershipTypes.CimaExamCardStatus.ONLINE_EXAM_BOOKED) &&
    css`
      color: ${props => props.theme.colors.neutralWhite};
    `}
`;

const StyledExamBookedDate = styled.p`
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralWhite};
  margin: 0 0 ${props => props.theme.pxToRem(12)} 0;
`;

const StyledExamBookedDateLarge = styled.span`
  line-height: 1.33;
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralWhite};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledExamBookedAddress = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralWhite};
  margin: 0 0 ${props => props.theme.pxToRem(12)} 0;

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};
    width: 100%;
  }
`;

const StyledExamBookedAddressLarge = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralWhite};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledPassedDate = styled.p`
  margin-top: ${props => props.theme.pxToRem(6)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey4};
`;

const StyledCredit = styled.span<StyledTextProps>`
  line-height: 1.33;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: ${props => props.theme.pxToRem(4)};
  display: inline-flex;
  gap: ${props => props.theme.pxToRem(6)};

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: ${props => props.theme.fontWeights.bold} !important;
    `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};
  }
`;

const StyledExamTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.pxToRem(12)};
`;

const StyledWaiveExemption = styled(Button)`
  &&&&& {
    display: block;
    margin-top: 0;

    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.fontSizes.s};
      text-align: justify;
    }
  }
`;

const StyledButtonWhiteLink = styled(Button)`
  &&&&& {
    display: block;
    color: ${props => props.theme.colors.neutralWhite};
    margin-top: ${props => props.theme.pxToRem(16)};
    font-weight: ${props => props.theme.fontWeights.light};
    margin-top: 0;

    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.fontSizes.s};
      text-align: justify;
    }
  }
`;

const StyledIconError = styled(IconError)`
  margin: 0;
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  color: ${props => props.theme.colors.primaryPurple};
`;

const StyledCreditsContainer = styled.div<{ cardSize?: EXAM_CARD_SIZE }>`
  display: flex;
  flex-direction: column;
  margin: ${props => props.theme.pxToRem(4)} 0 ${props => props.theme.pxToRem(14)} 0;
  padding: ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(14)} ${props => props.theme.pxToRem(18)}
    ${props => props.theme.pxToRem(18)};
  background-color: ${props => props.theme.colors.neutralGrey1};

  ${({ cardSize }) =>
    cardSize === EXAM_CARD_SIZE.LARGE &&
    css`
      width: ${props => props.theme.pxToRem(400)};
    `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(25)};
    width: 100%;
  }
`;

const StyledCreditDetailsRow = styled.div`
  display: flex;
  gap: ${props => props.theme.pxToRem(10)};
  flex-wrap: wrap;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledButtonContainer = styled.div<{ cardSize: EXAM_CARD_SIZE }>`
  align-items: flex-end;
  flex: 1;
  display: flex;
  gap: ${props => props.theme.pxToRem(14)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    margin: ${props => props.theme.pxToRem(15)} 0 0 0;
  }
`;

const StyledBookNowButton = styled(Button)`
  &&&&& {
    font-size: ${props => props.theme.fontSizes.xs};
    width: ${props => props.theme.pxToRem(130)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
    }
  }
`;

const StyledBookNowButtonL = styled(Button)`
  &&&&& {
    font-size: ${props => props.theme.fontSizes.xs};
    height: ${props => props.theme.pxToRem(14)};
    width: ${props => props.theme.pxToRem(170)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
    }
  }
`;

const StyledLargeButtonContainer = styled.div<{ passed: boolean }>`
  padding-top: ${props => `${props.passed ? props.theme.pxToRem(0) : props.theme.pxToRem(30)}`};
  margin-top: ${props => `${props.passed ? props.theme.pxToRem(-35) : props.theme.pxToRem(0)}`};
  margin: ${props => props.theme.pxToRem(15)} 0 0 0;
  display: flex;
  gap: ${props => props.theme.pxToRem(14)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
    width: 100%;
  }
`;

const HiddenSpan = styled.span`
  color: ${props => props.theme.colors.neutralWhite};
`;

const StyledBookedButtonDiv = styled.div``;

const StyledBookedDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.pxToRem(10)};
`;

const StyledOnlineBookedDiv = styled.div`
  display: flex;
  gap: ${props => props.theme.pxToRem(14)};
`;

const StyledLargeDiv = styled.div<{ isBooked: boolean; cardSize?: EXAM_CARD_SIZE }>`
  margin-top: ${props => `${props.isBooked ? props.theme.pxToRem(0) : props.theme.pxToRem(15)}`};
  display: flex;
  justify-content: space-between;

  ${({ cardSize }) =>
    cardSize === EXAM_CARD_SIZE.LARGE &&
    css`
      margin-bottom: ${props => props.theme.pxToRem(14)};
    `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
  }
`;

const StyledAlertDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 ${props => props.theme.pxToRem(14)} 0;
`;

const StyledAlertText = styled.span`
  margin: 0 ${props => props.theme.pxToRem(5)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralBlack};
`;

const IconPopup: React.FC<{ data: string; subData: string }> = React.memo(({ data, subData }) => {
  const popupTrigger = () => (
    <StyledIconWrapper>
      <StyledIconError />
    </StyledIconWrapper>
  );

  const popupContent = () => (
    <PopContainer>
      <p>{data}</p>
      <p>{subData} </p>
    </PopContainer>
  );

  return (
    <StyledPopup
      content={popupContent()}
      key=""
      position="bottom right"
      trigger={popupTrigger()}
      hoverable
      hideOnScroll
    />
  );
});

const StyledPopup = styled(Popup)`
  &&&& {
    padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(20)};
    min-width: ${props => props.theme.pxToRem(405)};
  }
`;

const PopContainer = styled.div`
  > p:first-child {
    font-size: ${props => props.theme.fontSizes.s};
    color: ${props => props.theme.colors.neutralGrey5};
  }
  > p:last-child {
    font-size: ${props => props.theme.fontSizes.s};
  }
`;

const StyledIconWrapper = styled.div`
  padding-right: ${props => props.theme.pxToRem(12)};
  padding-bottom: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(16)};
  width: ${props => props.theme.pxToRem(28)};
`;
