import React from 'react';
import styled from 'styled-components';
import { Container, OnlyMobileCSS, OnlyDesktopCSS } from 'components/atoms';
import { Footer } from 'components/molecules/Footer/Footer';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
interface Props {
  children: React.ReactNode;
  addHeight?: boolean;
  hideHeader?: boolean;
}

const contextRef: any = React.createRef();

export const SingleColumnPageTemplate: React.FC<Props> = ({ children, addHeight = false, hideHeader = false }) => (
  <>
    <StyledOnlyDesktopCSS>
      <StyledHeaderPageWrapper hideHeader={hideHeader}>
        <StyledContainer>{children}</StyledContainer>
        <StyledFooter />
      </StyledHeaderPageWrapper>
    </StyledOnlyDesktopCSS>

    <StyledOnlyMobileCSS add-height={addHeight}>
      <StyledHeaderPageWrapper contentRef={contextRef} hideHeader={hideHeader}>
        <StyledContainer>{children}</StyledContainer>
        <StyledFooter />
      </StyledHeaderPageWrapper>
    </StyledOnlyMobileCSS>
  </>
);

const StyledHeaderPageWrapper = styled(HeaderPageWrapper)`
  @media print {
    display: block;

    > div:first-child {
      display: none;
    }

    > div:last-child {
      display: block;
    }
  }
`;

const StyledContainer = styled(Container)`
  margin-top: ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(6)};
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: calc(67vh + ${props => props.theme.pxToRem(20)});
  }
`;

// Hide Footer for print
const StyledFooter = styled(Footer)`
  @media print {
    display: none;
  }
`;

const StyledOnlyDesktopCSS = styled(OnlyDesktopCSS)`
  height: ${props => props.theme.pxToRem(1)};
  min-height: 100%;
`;

const StyledOnlyMobileCSS = styled(OnlyMobileCSS)`
  ${props =>
    props['add-height'] &&
    `
  height: 100%;
`}
`;
