import React, { memo } from 'react';
import styled from 'styled-components';
import { Button, Flex, Grid, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { Contentful } from 'mxp-schemas';
import { Image } from 'semantic-ui-react';

interface MembershipTypeSelectionButtonProps {
  id?: string;
  name?: string;
  slug?: string;
  subtitle?: string;
  description?: string;
  image?: Contentful.ContentfulImage;
  isRecommended?: boolean;
  isCurrent?: boolean;
  selected: boolean;
  handleProductClick?: (slug: string) => void;
  onClick?: () => void;
  selectionCardShow?: boolean;
  isMembershipPackagePage?: boolean;
  useNewMembershipAICPA?: boolean;
}

export const TitleImageDesktop: React.FC<{
  heading?: string;
  title: string;
  selected?: boolean;
  useNewMembershipAICPA?: boolean;
}> = ({ heading, title, selected, useNewMembershipAICPA }) => {
  return (
    <HeadingsContainer useNewMembershipAICPA={useNewMembershipAICPA}>
      <LargeHeading className="large-heading">{heading}</LargeHeading>
      <SubHeading useNewMembershipAICPA={useNewMembershipAICPA}>{title}</SubHeading>
    </HeadingsContainer>
  );
};

export const TitleImageMobile: React.FC<{
  heading?: string;
  title: string;
  selected?: boolean;
  isRecommended?: any;
  isCurrent?: any;
  image?: any;
  useNewMembershipAICPA?: boolean;
}> = ({ heading, title, selected, isRecommended, isCurrent, image, useNewMembershipAICPA }) => {
  return (
    <Grid container columns={1}>
      <Grid.Column>
        <Flex>
          {image && <StyledImage src={image.url} alt={image.altText} useNewMembershipAICPA={useNewMembershipAICPA} />}
          <HeadingsContainer useNewMembershipAICPA={useNewMembershipAICPA}>
            <LargeHeading className="large-heading">{heading}</LargeHeading>
            <SubHeading useNewMembershipAICPA={useNewMembershipAICPA}>{title}</SubHeading>
          </HeadingsContainer>
        </Flex>
      </Grid.Column>
    </Grid>
  );
};

export const MembershipTypeSelectionButton: React.FC<MembershipTypeSelectionButtonProps> = memo(
  ({
    id,
    name,
    slug,
    subtitle = '',
    image,
    selected,
    isRecommended = false,
    isCurrent = false,
    handleProductClick,
    onClick,
    selectionCardShow,
    isMembershipPackagePage,
    useNewMembershipAICPA,
  }) => {
    return (
      <StyledDiv
        selected={selected}
        onClick={onClick}
        selectionCardShow={selectionCardShow}
        useNewMembershipAICPA={useNewMembershipAICPA}
      >
        <StyledCardButton
          active
          className={selected ? 'selected' : ''}
          isMembershipPackagePage={isMembershipPackagePage}
          key={id}
          testId={`membership-type-button-${name}`}
          onClick={handleProductClick?.bind(null, slug as string)}
          selected={selected}
        >
          {useNewMembershipAICPA ? (
            <>
              <OnlyDesktop>
                <Grid stackable columns={3}>
                  <Grid.Column computer={4}>
                    {image && (
                      <StyledImage src={image.url} alt={image.altText} useNewMembershipAICPA={useNewMembershipAICPA} />
                    )}
                  </Grid.Column>
                  <Grid.Column computer={10}>
                    <TitleImageDesktop
                      heading={name}
                      title={subtitle}
                      selected={selected}
                      useNewMembershipAICPA={useNewMembershipAICPA}
                    />
                  </Grid.Column>
                </Grid>
              </OnlyDesktop>
              <OnlyMobile>
                <TitleImageMobile
                  heading={name}
                  title={subtitle}
                  selected={selected}
                  isRecommended={isRecommended}
                  isCurrent={isCurrent}
                  image={image}
                  useNewMembershipAICPA={useNewMembershipAICPA}
                />
              </OnlyMobile>
            </>
          ) : (
            <Grid stackable columns={3}>
              <Grid.Column computer={4}>
                {image && (
                  <StyledImage src={image.url} alt={image.altText} useNewMembershipAICPA={useNewMembershipAICPA} />
                )}
              </Grid.Column>
              <Grid.Column computer={10}>
                <TitleImageDesktop
                  heading={name}
                  title={subtitle}
                  selected={selected}
                  useNewMembershipAICPA={useNewMembershipAICPA}
                />
              </Grid.Column>
            </Grid>
          )}
        </StyledCardButton>
      </StyledDiv>
    );
  }
);

const HeadingsContainer = styled.div<{ useNewMembershipAICPA?: boolean }>`
  color: ${({ theme }) => theme.colors.primaryPurple};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `0 ${props.theme.pxToRem(10)}`};
    width: fit-content;
    max-width: -moz-available;
    width: -webkit-fill-available;
    font-size: revert;
  }
`;

const LargeHeading = styled.p<{ selected?: boolean }>`
  width: 130%;
  font-size: ${props => props.theme.fontSizes.m};
  margin: ${props =>
    `${props.theme.pxToRem(5)} ${props.theme.pxToRem(130)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(1)}`};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.neutralBlack};
  line-height: 1.1;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    margin: ${props => `0 ${props.theme.pxToRem(20)} 0 0`};
    font-size: ${props => props.theme.pxToRem(16)};
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;

const SubHeading = styled.div<{ useNewMembershipAICPA?: boolean }>`
  ${props =>
    props.useNewMembershipAICPA
      ? `
  ${props.theme.mediaQueries.mobileOnly} {
    line-height: 1.2;
    width: 100%;
  }
  `
      : ` ${props.theme.mediaQueries.mobileOnly} {
    width: ${props.theme.pxToRem(230)};
    margin: ${`0 ${props.theme.pxToRem(20)} 0 0`};
    padding: ${`${props.theme.pxToRem(5)} ${props.theme.pxToRem(45)} 0 0`};
  }
  `}

  width: 132%;
  height: ${props => props.theme.pxToRem(60)};
  line-height: 1.4;
  font-size: ${props => props.theme.pxToRem(15)};
  color: ${({ theme }) => theme.colors.neutralGrey7};
  font-weight: ${props => props.theme.fontWeights.regular};
  margin: ${props => `0 ${props.theme.pxToRem(130)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(1)}`};

  ${props => props.theme.mediaQueries.tabletOnly} {
    width: fit-content;
    margin: ${props => `0 ${props.theme.pxToRem(20)} 0 0`};
    padding: ${props => `${props.theme.pxToRem(5)} ${props.theme.pxToRem(45)} 0 0`};
  }
`;

export const StyledImage = styled(Image)<{ useNewMembershipAICPA?: boolean }>`
  ${props =>
    props.useNewMembershipAICPA
      ? `

${props.theme.mediaQueries.desktopOnly} {
  min-width: ${props.theme.pxToRem(70)};
  object-fit: contain;
  position: relative;
  right: ${props.theme.pxToRem(12)};
}

${props.theme.mediaQueries.mobileOnly} {
  width: ${props.theme.pxToRem(65)};
  height: ${props.theme.pxToRem(65)};
  object-fit: contain;
}
`
      : `  object-fit: contain;
position: relative;
right: ${props.theme.pxToRem(12)};
min-width: ${props.theme.pxToRem(70)};

${props.theme.mediaQueries.mobileOnly} {
  width: ${props.theme.pxToRem(67)};
  height: ${props.theme.pxToRem(65)};
  margin: ${`${props.theme.pxToRem(-15)} ${props.theme.pxToRem(258)} 0 0`};
}

${props.theme.mediaQueries.tabletOnly} {
  width: ${props.theme.pxToRem(90)};
  height: ${props.theme.pxToRem(85)};
  margin-top: ${props.theme.pxToRem(-10)};`}
`;

const StyledCardButton = styled(Button)<{
  selected?: boolean;
  isMembershipPackagePage?: boolean;
}>`
  ${props =>
    props.isMembershipPackagePage
      ? `
  width: 100%;
  height: ${props.theme.pxToRem(137.7)};
  margin-bottom: ${props.theme.pxToRem(20)} !important;
  padding: ${`${props.theme.pxToRem(5)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(
    20
  )}`};
  background-color: ${props.theme.colors.neutralWhite} !important;
  border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3} !important;
  z-index: 1 !important;
  object-fit: contain;
  text-align: left !important;
  position: relative;
  box-shadow: 0 !important;
  &.selected,
  :active,
  :focus {
    border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.primaryPurple} !important;
  }
  `
      : `
  width: 100%;
  height: ${props.theme.pxToRem(137.7)};
  margin: ${`${props.theme.pxToRem(14)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(14)} 0`};
  margin-bottom: ${props.theme.pxToRem(20)} !important;
  padding: ${`${props.theme.pxToRem(5)} ${props.theme.pxToRem(74)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(
    20
  )}`};
  border-radius: ${props.theme.pxToRem(4)};
  border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3} !important;
  background-color: ${props.theme.colors.neutralWhite} !important;
  box-shadow: ${
    props.selected
      ? `${props.theme.pxToRem(10)} ${props.theme.pxToRem(10)} ${props.theme.pxToRem(10)} 0 ${
          props.theme.colors.neutralGrey6
        } !important`
      : `0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(10)} 0 ${props.theme.colors.neutralGrey6} !important`
  };
  z-index: 1;
  object-fit: contain;
  text-align: left !important;
  position: relative;
  &.selected,
  :active,
  :focus {
    border: ${props.theme.pxToRem(2)} solid ${props.theme.colors.primaryPurple} !important;
  }
  `};
`;

const StyledDiv = styled.div<{ selected?: boolean; selectionCardShow?: boolean; useNewMembershipAICPA?: boolean }>`
  margin-left: ${props => props.theme.pxToRem(18)};
  background-color: ${props =>
    props.selectionCardShow && props.selected
      ? `${props.theme.colors.neutralGrey2} !important`
      : `${props.theme.colors.neutralWhite} !important`};
  outline: ${props =>
    props.selectionCardShow && props.selected
      ? `${props.theme.pxToRem(10)} solid ${props.theme.colors.neutralGrey2} !important`
      : `${props.theme.pxToRem(10)} solid ${props.theme.colors.neutralWhite} !important`};

  ${props => props.theme.mediaQueries.mobileOnly} {
    ${props =>
      props.useNewMembershipAICPA &&
      `
    margin: 0;
    width: 100%;`}
  }
`;
