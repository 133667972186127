import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.requiredDocument,
  (requiredDocument: State.RequiredDocument): State.RequiredDocument => requiredDocument
);

export const requiredDocumentsSelector = rootSelector;
