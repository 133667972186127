import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { PageCenterAdminSelection } from 'components/pages/PageCenterAdminSelection/PageCenterAdminSelection';
import { getOrganizations } from 'modules/centerAdmin';
import { organizationsListSelector, isOrganizationsFetchedSelector } from 'modules/centerAdmin/selectors';
import { centersClientAdminSelector } from 'modules/user/selectors';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

const mapActionCreators = (dispatch: Dispatch) => ({
  getOrganizations(): void {
    dispatch(getOrganizations()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },

  selectOrganization(path: string): void {
    dispatch(push(path));
  },
});

const mapStateToProps = (state: State.Root) => ({
  organizationsList: organizationsListSelector(state),
  isOrganizationsFetched: isOrganizationsFetchedSelector(state),
  centersClientAdmin: centersClientAdminSelector(state),
});

export const PageCenterAdminSelectionContainer = connect(mapStateToProps, mapActionCreators)(PageCenterAdminSelection);
