import styled from 'styled-components';
import { Salesforce } from 'mxp-schemas';
import React from 'react';
import { generatePath } from 'react-router';
import { getPath } from 'utils';
import { CenterAdminNavigationList1, CenterAdminNavigationList2 } from 'constants/index';
import { Link } from 'components/atoms';
import { NavigationBox } from './NavigationBox';

interface Props {
  id: string;
  selectedOrganization: Salesforce.Organization | null;
  getOrganizations: () => void;
}

export const PageCenterAdminNavigation: React.FC<Props> = ({ id, selectedOrganization, getOrganizations }) => {
  React.useEffect(() => {
    if (!selectedOrganization) getOrganizations();
  }, [selectedOrganization, getOrganizations]);

  const centerAdminList1 = React.useMemo(
    () =>
      CenterAdminNavigationList1.map(item => ({
        text: item.label,
        link: generatePath(getPath(item.key), { orgId: id }),
      })),
    [id]
  );

  const centerAdminList2 = React.useMemo(
    () =>
      CenterAdminNavigationList2.map(item => ({
        text: item.label,
        link: generatePath(getPath(item.key), { orgId: id }),
      })),
    [id]
  );

  return (
    <>
      <StyledNavContainer>
        <NavigationBox iconName="user circle" navTitle="Firm information" mainLinkTo={centerAdminList1[0].link}>
          {centerAdminList1.map(item => (
            <StyledLink key={item.text} testId={`navigation${item.link}`} to={item.link} isExternal={false}>
              {item.text}
            </StyledLink>
          ))}
        </NavigationBox>

        <NavigationBox iconName="setting" navTitle="Center memberships" mainLinkTo="/">
          {centerAdminList2.map(item => (
            <StyledLink key={item.text} testId={`navigation${item.link}`} to={item.link} isExternal={false}>
              {item.text}
            </StyledLink>
          ))}
        </NavigationBox>
      </StyledNavContainer>
    </>
  );
};

const StyledNavContainer = styled.div`
  display: grid;
  align-items: stretch;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: ${props => props.theme.pxToRem(10)};
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primaryPurple};
  text-decoration: none;
  margin-bottom: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.fontSizes.s};
`;
