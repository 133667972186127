import React from 'react';
import styled from 'styled-components';
import { ExternalLinkStylesDark, ExternalLinkStyles } from 'components/atoms/Link/Link';
import { Contentful } from 'mxp-schemas';
import { Grid, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { Responsive } from 'semantic-ui-react';
import Carousel from 'react-multi-carousel';
import { isColorDark } from 'utils/colorHelpers';
import { SubBlockQuickLinks } from './SubBlockQuickLinks';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
}

export const BlockQuickLinks: React.FC<Props> = ({ modularBlock }) => {
  const { onlyMobile, onlyTablet } = Responsive;
  const block = modularBlock as Contentful.ModularContent.Quicklinks;
  const isBgColorDark: boolean = isColorDark(block.bgColor);

  const carouselContents = block?.landingPageSubBlocks
    ?.filter((item: Contentful.LandingPages.LandingPageSubBlock) => {
      return item?.ctaText && item?.ctaUrl && item?.image;
    })
    .map((item: Contentful.LandingPages.LandingPageSubBlock) => {
      return item;
    });

  const partialVisibilityGutterValueMobile = carouselContents && carouselContents?.length < 4 ? 0 : 15;
  const partialVisibilityGutterValueTablet = carouselContents && carouselContents?.length < 4 ? 0 : 30;

  const itemsValue = carouselContents && carouselContents?.length < 3 ? carouselContents?.length : 3;

  const responsive = {
    mobile: {
      breakpoint: {
        max: onlyMobile.maxWidth as number,
        min: onlyMobile.minWidth as number,
      },
      items: itemsValue,
      partialVisibilityGutter: partialVisibilityGutterValueMobile,
    },
    tablet: {
      breakpoint: {
        max: onlyTablet.maxWidth as number,
        min: onlyTablet.minWidth as number,
      },
      items: itemsValue,
      partialVisibilityGutter: partialVisibilityGutterValueTablet,
    },
  };

  return (
    <>
      <MainColumn isBgColorDark={isBgColorDark}>
        {/* Following div is fix for SSR. Do not remove! */}
        <div>
          <OnlyMobile>
            <Carousel
             arrows={false}
              responsive={responsive}
              swipeable={true}
              draggable={false}
              partialVisible={true}
            >
              {carouselContents?.map((item: Contentful.LandingPages.LandingPageSubBlock) => (
                  <SubBlockQuickLinks
                    ctaText={item?.ctaText}
                    ctaUrl={item?.ctaUrl}
                    image={item?.image}
                    isBgColorDark={isBgColorDark}
                  />
              ))}
            </Carousel>
          </OnlyMobile>
        </div>
        {/* Following div is fix for SSR. Do not remove! */}
        <div>
          <OnlyDesktop>
            {carouselContents && carouselContents?.length > 3 ? 
            <>
              <StyledDiv>
                {carouselContents?.map((item: Contentful.LandingPages.LandingPageSubBlock) => (
                  <SubBlockQuickLinks
                    ctaText={item?.ctaText}
                    ctaUrl={item?.ctaUrl}
                    image={item?.image}
                    isBgColorDark={isBgColorDark}
                  />
                ))}
              </StyledDiv>
            </>
            : 
            <>
              <StyledGrid columns="6" centered>
                {carouselContents?.map((item: Contentful.LandingPages.LandingPageSubBlock) => (
                  <Grid.Column key={item.id}>
                    <SubBlockQuickLinks
                      ctaText={item?.ctaText}
                      ctaUrl={item?.ctaUrl}
                      image={item?.image}
                      isBgColorDark={isBgColorDark}
                    />
                  </Grid.Column>
                ))}
              </StyledGrid>
            </>
            }
          </OnlyDesktop>
        </div>
      </MainColumn>
    </>
  );
};

const MainColumn = styled.div<{
  isBgColorDark: boolean;
}>`
  width: 100% !important;
  ${props => props.theme.mediaQueries.desktopOnly} {
    height: ${props => props.theme.pxToRem(182)} !important;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    height: ${props => props.theme.pxToRem(171)} !important;
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    height: ${props => props.theme.pxToRem(171)} !important;
  }

  .react-multi-carousel-item, .react-multi-carousel-item--active {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-top: ${props => props.theme.pxToRem(25)};
  }

  .rich-text-external-link {
    ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    vertical-align: middle;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledDiv = styled.div`
  ${props => props.theme.mediaQueries.desktopOnly} {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-top: ${props => props.theme.pxToRem(25)};
  }
`;

const StyledGrid = styled(Grid)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(10)};
  }
`;
