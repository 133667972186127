import React, { useState, useCallback } from 'react';
import { OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms/ResponsiveHelpers/ResponsiveHelpers';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import styled, { css } from 'styled-components';
import { ReactComponent as ArrowDown } from 'resources/images/icon-arrow-down.svg';
import { ReactComponent as ArrowUp } from 'resources/images/icon-arrow-up.svg';
import { Grid } from 'semantic-ui-react';
import moment from 'moment-timezone';

interface Props {
  isDisabled?: boolean;
  hasOptions?: boolean;
  optionsData?: any;
  title?: string;
  description?: string;
  price?: string;
  disabledAdd?: boolean;
  handleClick?: () => void;
  onAddBenefits: (data: any, isSelected: boolean, sku: string, dataVariant: any, serviceContractId: string) => void;
  data?: any;
  isSelected: boolean;
  sku: string;
  dataVariant: any;
  selectedDate?: string;
  isAdmin: boolean;
  ifExist: boolean;
  onUnselectBenefit: (cli: any, sku: string, benefitGroupId?: string) => void;
  cli?: any;
  loading: boolean;
  serviceContractId: string;
  onViewingProductDetails(productDetails: any, price: string): void;
  benefitGroupId?: string;
}

export const BenefitCard: React.FC<Props> = ({
  title,
  price,
  hasOptions,
  isDisabled,
  optionsData,
  disabledAdd = false,
  onAddBenefits,
  data,
  isSelected: selected = false,
  sku,
  dataVariant,
  selectedDate,
  isAdmin,
  ifExist,
  onUnselectBenefit,
  cli,
  loading,
  serviceContractId,
  onViewingProductDetails,
  benefitGroupId,
}) => {
  const [isSelected, setIsSelected] = useState(selected);
  const [isViewingOptions, setViewingOptions] = useState(false);
  const [isNeedToRefresh, setIsNeedToRefresh] = useState(false);

  const handleViewOptions = useCallback(() => {
    setViewingOptions(!isViewingOptions);
  }, [isViewingOptions]);

  const onClickAdd = useCallback(() => {
    setIsSelected(true);
    onAddBenefits(data, isSelected, sku, dataVariant, serviceContractId);
  }, [onAddBenefits, data, isSelected, sku, dataVariant, serviceContractId]);

  const onUnselect = useCallback(() => {
    onUnselectBenefit(cli, sku, benefitGroupId);
    setIsNeedToRefresh(true);
    setIsSelected(false);
  }, [cli, sku, benefitGroupId, onUnselectBenefit]);

  const onClickViewDetails = useCallback(() => {
    onViewingProductDetails(data, price || '0');
  }, [data, price, onViewingProductDetails]);

  return (
    <Container>
      <StyledOnlyDesktopCSS>
        <CardBenefits isDisabled={isDisabled}>
          <Grid>
            <Grid.Column computer={9} mobile={6} tablet={3}>
              <h3>{title}</h3>
              <p>
                <del>{price}</del> FREE with your membership
              </p>
              <StyledViewDetailsButton
                variant={ButtonEnums.variants.link}
                testId="test-id"
                onClick={onClickViewDetails}
              >
                View details
              </StyledViewDetailsButton>
            </Grid.Column>
            <Grid.Column width={4}>
              <StyledCreditsText>2 hours</StyledCreditsText>
            </Grid.Column>

            <Grid.Column computer={3} mobile={6} tablet={9}>
              <StyledContainer>
                {isAdmin && ifExist ? (
                  <StyledButton
                    variant={ButtonEnums.variants.secondary}
                    testId="test-id"
                    onClick={onUnselect}
                    loading={loading}
                    disabled={isNeedToRefresh}
                  >
                    {isNeedToRefresh ? 'Please Refresh' : 'Unselect'}
                  </StyledButton>
                ) : isDisabled ? (
                  <StyledP>
                    Benefit Selected {selectedDate && `on ${moment(selectedDate).format('DD/MM/YYYY')}`}
                  </StyledP>
                ) : hasOptions ? (
                  <StyledPart>
                    <StyledText>{isViewingOptions ? 'Hide' : 'View'} Options</StyledText>
                    <p onClick={handleViewOptions}>{isViewingOptions ? <ActiveIcon /> : <InactiveIcon />}</p>
                  </StyledPart>
                ) : (
                  <StyledButton
                    disabled={isSelected ? false : disabledAdd}
                    variant={ButtonEnums.variants.secondary}
                    testId="test-id"
                    onClick={isSelected ? onUnselect : onClickAdd}
                  >
                    {isSelected ? 'Added' : '+ Add'}
                  </StyledButton>
                )}
              </StyledContainer>
            </Grid.Column>
          </Grid>
        </CardBenefits>
        {isViewingOptions &&
          optionsData &&
          !!optionsData.length &&
          optionsData.map((option: any, index: number) => {
            return (
              <CardOption key={index}>
                <Grid>
                  <Grid.Column computer={9} mobile={6} tablet={3}>
                    {option.key}
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <StyledCreditsText />
                  </Grid.Column>
                  <Grid.Column computer={3} mobile={6} tablet={9}>
                    <StyledButton
                      disabled={isSelected ? false : disabledAdd}
                      variant={ButtonEnums.variants.secondary}
                      testId="test-id"
                      onClick={isSelected ? onUnselect : onClickAdd}
                    >
                      {isSelected ? 'Added' : '+ Add'}
                    </StyledButton>
                  </Grid.Column>
                </Grid>
              </CardOption>
            );
          })}
      </StyledOnlyDesktopCSS>
      <StyledOnlyMobileCSS>
        <CardBenefits isDisabled={isDisabled}>
          <Grid>
            <Grid.Column computer={9} mobile={6} tablet={3}>
              <h3>{title}</h3>
              <p>
                <del>{price}</del> FREE with your membership
              </p>
              <StyledViewDetailsButton
                variant={ButtonEnums.variants.link}
                testId="test-id"
                onClick={onClickViewDetails}
              >
                View details
              </StyledViewDetailsButton>
            </Grid.Column>
            <Grid.Column width={4}>
              <StyledCreditsText>2 hours</StyledCreditsText>
            </Grid.Column>
            <Grid.Column computer={3} mobile={6} tablet={9}>
              <StyledContainer>
                {isDisabled ? (
                  <StyledP>Benefit Selected on {moment(selectedDate).format('DD/MM/YYYY')}</StyledP>
                ) : hasOptions ? (
                  <StyledPart>
                    <StyledText>{isViewingOptions ? 'Hide' : 'View'} Options</StyledText>
                    <p onClick={handleViewOptions}>{isViewingOptions ? <ActiveIcon /> : <InactiveIcon />}</p>
                  </StyledPart>
                ) : (
                  <StyledButton
                    disabled={isSelected ? false : disabledAdd}
                    variant={ButtonEnums.variants.secondary}
                    testId="test-id"
                    onClick={isSelected ? onUnselect : onClickAdd}
                  >
                    {isSelected ? 'Added' : '+ Add'}
                  </StyledButton>
                )}
              </StyledContainer>
            </Grid.Column>
          </Grid>
        </CardBenefits>
        {isViewingOptions &&
          optionsData &&
          !!optionsData.length &&
          optionsData.map((option: any, index: number) => {
            return (
              <CardOption key={index}>
                <Grid>
                  <Grid.Column computer={9} mobile={6} tablet={3}>
                    {option.key}
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <StyledCreditsText />
                  </Grid.Column>
                  <Grid.Column computer={3} mobile={6} tablet={9}>
                    <StyledButton
                      disabled={isSelected ? false : disabledAdd}
                      variant={ButtonEnums.variants.secondary}
                      testId="test-id"
                      onClick={isSelected ? onUnselect : onClickAdd}
                    >
                      {isSelected ? 'Added' : '+ Add'}
                    </StyledButton>
                  </Grid.Column>
                </Grid>
              </CardOption>
            );
          })}
      </StyledOnlyMobileCSS>
    </Container>
  );
};

interface StyledBenefitCardProps {
  isDisabled?: boolean;
}

const StyledViewDetailsButton = styled(Button)`
  color: ${({ theme }) => theme.colors.primaryPurple};
`;
const StyledContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(30)};
`;
const StyledText = styled.p`
  color: ${({ theme }) => theme.colors.primaryPurple};
  font-weight: bold;
`;

const StyledCreditsText = styled.p`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
  }
`;
const StyledOnlyDesktopCSS = styled(OnlyDesktopCSS)``;
const StyledOnlyMobileCSS = styled(OnlyMobileCSS)``;

const StyledButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
`;

const CardBenefits = styled.div<StyledBenefitCardProps>`
  width: ${props => props.theme.pxToRem(1056)};
  height: ${props => props.theme.pxToRem(156)};
  margin: ${props => `0 ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} 0`};
  padding: ${props =>
    `${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)}`};
  border-radius: 4px;
  box-shadow: ${props =>
      `${props.theme.pxToRem(5)} ${props.theme.pxToRem(2)} ${props.theme.pxToRem(15)} ${props.theme.pxToRem(10)}`}
    rgba(0, 0, 0, 0.1);

  //
  ${props => props.theme.mediaQueries.mobileOnly} {
    border-radius: ${props => props.theme.pxToRem(4)};
    box-shadow: ${props =>
        `${props.theme.pxToRem(5)} ${props.theme.pxToRem(2)} ${props.theme.pxToRem(15)} ${props.theme.pxToRem(10)}`}
      rgba(0, 0, 0, 0.1);
    width: ${props => props.theme.pxToRem(335)};
    height: ${props => props.theme.pxToRem(288)};
    margin: ${props => `0 0 ${props.theme.pxToRem(16)}`};
    padding: ${props =>
      `${props.theme.pxToRem(16)} ${props.theme.pxToRem(19)} ${props.theme.pxToRem(16)} ${props.theme.pxToRem(20)}`};
    background-color: ${({ theme }) => theme.colors.neutralWhite};
  }

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          opacity: 0.4 !important;
        `
      : css``}
`;

const CardOption = styled.div`
  width: 95%;
  margin: ${props => `0 ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(35)}`};
  padding: ${props =>
    `${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)}`};
  border-radius: 4px;
  box-shadow: ${props => `0 ${props.theme.pxToRem(1)} ${props.theme.pxToRem(6)} ${props.theme.pxToRem(5)}`}
    rgba(0, 0, 0, 0.1);

  ${props => props.theme.mediaQueries.mobileOnly} {
    border-radius: ${props => props.theme.pxToRem(4)};
    box-shadow: ${props => `0 ${props.theme.pxToRem(1)} ${props.theme.pxToRem(6)} ${props.theme.pxToRem(5)}`}
      rgba(0, 0, 0, 0.1);
    width: ${props => props.theme.pxToRem(335)};
    height: ${props => props.theme.pxToRem(288)};
    margin: ${props => `0 0 ${props.theme.pxToRem(16)}`};
    padding: ${props =>
      `${props.theme.pxToRem(5)} ${props.theme.pxToRem(2)} ${props.theme.pxToRem(15)} ${props.theme.pxToRem(10)}`};
    background-color: ${({ theme }) => theme.colors.neutralWhite};
  }
`;

const StyledPart = styled.div`
  text-align: center;
`;

const StyledP = styled.p`
  text-align: center;
`;

const InactiveIcon = styled(ArrowDown)`
  fill: ${props => props.theme.colors.primaryPurple};
  width: 1.5rem;
  height: 1.5rem;
`;

const ActiveIcon = styled(ArrowUp)`
  fill: ${props => props.theme.colors.primaryPurple};
  width: 1.5rem;
  height: 1.5rem;
`;
