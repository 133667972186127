import { B2B } from 'mxp-schemas';

const WEEK: string = B2B.AccessiblePeriodTypes.WEEK;
const MONTH: string = B2B.AccessiblePeriodTypes.MONTH;
const YEAR: string = B2B.AccessiblePeriodTypes.YEAR;
const minMonth: number = 1;
const maxMonth: number = 60;
const maxYear: number = 5;

export const isAccessiblePeriodSelectionValid = (
  period: number | undefined,
  periodType: string | undefined
): boolean => {
  return (
    (periodType === WEEK && period === minMonth) ||
    (periodType === YEAR && period !== undefined && period >= minMonth && period <= maxYear) ||
    (periodType === MONTH && period !== undefined && period >= minMonth && period <= maxMonth)
  );
};

export const accessiblePeriodPlaceholder = (periodType: string | undefined): string => {
  if (periodType === WEEK) {
    return '1 max';
  }
  if (periodType === MONTH) {
    return '1-60';
  }

  return '1-5';
};
