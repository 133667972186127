import React from 'react';
import styled from 'styled-components';
import { CPECreditsLabel, Link, LinkEnums, ProductCategory } from 'components/atoms';
import { getProductTypeLabel } from 'modules/products/helpers';
import { Product } from 'mxp-schemas';
import { generatePath } from 'react-router';
import { Routes } from 'constants/index';
import { getPath, productTypeToSlug } from 'utils';

interface Props {
  productItem: Product.ProductItem;
  currentVariant?: string;
  children?: React.ReactNode;
  handleBundleItemLinkClick?: () => void;
}

export const ProductListItemHeader: React.FC<Props> = ({
  productItem,
  currentVariant,
  children,
  handleBundleItemLinkClick,
}) => {
  const { subscriptionProductType, productId, productType, variants, name, credits, slug } = productItem;

  const productCategories: string[] = [getProductTypeLabel(productItem)];

  const maxCredits = React.useMemo(() => Math.max(Number(credits || 0), ...variants?.map(v => Number(v.credits))), [
    credits,
    variants,
  ]);
  const currentVariantCredits = React.useMemo(
    () => (currentVariant ? Number(variants?.find(v => v.sku === currentVariant)?.credits) || 0 : 0),
    [currentVariant, variants]
  );
  const creditsToDisplay = currentVariantCredits || maxCredits;

  const subscriptionType = subscriptionProductType?.key as Product.SubscriptionProductType;
  const productURL = React.useMemo(
    () =>
      generatePath(getPath(Routes.PRODUCT_PAGE), {
        productTypeSlug: subscriptionType || productTypeToSlug(productType as Product.ProductType),
        slug,
      }),
    [productType, slug, subscriptionType]
  );

  return (
    <>
      <StyledInfoRow>
        {<ProductCategory category={productCategories} />}
        {!!creditsToDisplay && <CPECreditsLabel credits={String(creditsToDisplay)} />}
      </StyledInfoRow>
      <StyledTitleRow>
        <Link
          type={LinkEnums.type.standaloneLink}
          underline={LinkEnums.underline.noUnderlineOnHover}
          to={productURL}
          testId={`bundle-contents-item-${productId}`}
          onClick={handleBundleItemLinkClick}
        >
          <TitleStyled>{name}</TitleStyled>
        </Link>
        {children}
      </StyledTitleRow>
    </>
  );
};

const StyledInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

const StyledTitleRow = styled.div`
  display: flex;
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
  }
  justify-content: space-between;
  padding-top: ${props => props.theme.pxToRem(5)};
`;

const TitleStyled = styled.div`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.neutralGrey8};
`;
