import React from 'react';
import styled from 'styled-components';
import { Product } from 'mxp-schemas';
import { UserMemberTypes } from 'modules/user/constants';
import { FREE_LABEL } from 'utils';

interface Props {
  priceRanges: Product.FormattedPriceRanges | undefined;
  userMemberType: UserMemberTypes;
}

export const PriceRangeForAllUserTypes: React.FC<Props> = React.memo(
  ({
    priceRanges,
    userMemberType,
  }: {
    priceRanges: Product.FormattedPriceRanges | undefined;
    userMemberType: UserMemberTypes;
  }) => {
    if (!priceRanges) {
      return null;
    }

    const zeroDollarToLabel = (amount: string): string => {
      return amount === '$0' || amount === '$0.00' ? FREE_LABEL : amount;
    };

    return (
      <>
        <StyledPriceInfo>
          {[UserMemberTypes.NONMEMBER, UserMemberTypes.MEMBER, UserMemberTypes.PREMIUM].includes(userMemberType) ? (
            <StyledFinalPrice>
              From {`${priceRanges.nonMember.low?.replace('.00', '')}`}
              {priceRanges?.member?.low &&
                ` (${zeroDollarToLabel(priceRanges.member.low?.replace('.00', ''))} for members)`}
            </StyledFinalPrice>
          ) : (
            <StyledFinalPrice>
              {priceRanges.member.low?.replace('.00', '')}
              {priceRanges.member.high && ` - ${priceRanges.member.high?.replace('.00', '')}`}
            </StyledFinalPrice>
          )}
        </StyledPriceInfo>
      </>
    );
  }
);

const StyledPriceInfo = styled.div<{ isAuth?: boolean }>`
  margin-bottom: ${props => (props.isAuth ? props.theme.pxToRem(26) : props.theme.pxToRem(4))};
`;

const StyledFinalPrice = styled.span`
  font-size: ${props => props.theme.fontSizes.m};
`;
