import { InquiryForm } from 'mxp-schemas';

export const constructQueryString = (inquiryForm: InquiryForm.FormTypes): string => {
  const mapFormToSF = Object.entries(inquiryForm).map(([key, value]) => {
    if (!value) return '';
    if (key === 'type') return '';
    if (key === InquiryForm.Fields.MARKETING_OPT_IN) {
      return constructQueryStringPair(InquiryForm.SalesForceFieldMapper[key], value.toString());
    }
    if (key === InquiryForm.Fields.MEDIA_ADVERTISING) {
      return constructQueryStringPair(InquiryForm.SalesForceFieldMapper[key], value.replaceAll(',', ';'));
    }
    return constructQueryStringPair(InquiryForm.SalesForceFieldMapper[key], value);
  });
  return mapFormToSF.filter(Boolean).join('&');
};

const constructQueryStringPair = (key: string, value: string): string => {
  if (!key || !value) return '';
  return `${key}=${encodeURIComponent(value)}`;
};
