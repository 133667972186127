import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MembershipTypes, Content } from 'mxp-schemas';
import { ContentfulHelpers } from 'mxp-utils';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import {
  Button as StyledButton,
  ButtonEnums,
  Divider,
  Modal,
  OnlyDesktopCSS,
  OnlyMobileCSS,
  ButtonVariants,
  RadioButton,
  Label,
  Link,
} from 'components/atoms';
import { getContentUrl } from 'utils';

import { Grid } from 'semantic-ui-react';

import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { addMembershipPackageCredential, removeMembershipPackageCredential } from 'modules/membership/actions';
import {
  membershipCredentialGetByProductIdSelector,
  currentMembershipProduct,
  membershipProductSelector,
} from 'modules/membership/selectors';
import { customerCredentialsSelector, customerInactiveCredentialsSelector } from 'modules/user/selectors';

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCredentialProductId: string;
  isRenewal?: boolean;
}

export const ModalCredentialPathWay: React.FC<Props> = ({
  isOpen,
  setOpen,
  selectedCredentialProductId,
  isRenewal,
}) => {
  const [selectedPathWay, setSelectedPathway] = useState('');
  const [variantSku, setVariantSku] = useState('');
  const [isDisabled, setDisable] = useState(true);
  const [eligibleExams, setEligibleExams]: any = useState([]);
  const [alternativeExams, setAlternativeExams]: any = useState([]);
  const [numOfExams, setNumOfExams] = useState('');
  const [pathwayName, setPathwayName] = useState('');
  const [isLapsedCredentials, setIsLapsedCredentials] = useState(false);

  const [ifExistingUserCredential, setIfExistingUserCredential] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const credentials = useSelector(membershipCredentialGetByProductIdSelector(selectedCredentialProductId));

  const customerCredentials = useSelector(customerCredentialsSelector);
  const currentMembership = useSelector(currentMembershipProduct);
  const membershipProduct = useSelector(membershipProductSelector);
  const customerInactiveCredentials = useSelector(customerInactiveCredentialsSelector);

  const activeMembershipType =
    membershipProduct?.membershipKey || currentMembership?.ctOrder?.variant?.attributes?.membershipKey?.label || '';

  useEffect(() => {
    if (credentials) {
      credentials?.[0]?.variants?.map(variant => {
        const existingCredential = customerCredentials?.find(
          customerCredential => customerCredential?.sku === variant?.sku
        );

        if (!ifExistingUserCredential && existingCredential) setIfExistingUserCredential(true);
      });
    }
  }, [credentials, customerCredentials, ifExistingUserCredential]);

  useEffect(() => {
    if (customerInactiveCredentials && !!customerInactiveCredentials.length && credentials) {
      let ifHasLapsedCredential = false;
      let reinstatementPathway: any;

      customerInactiveCredentials?.map((inactiveCredential: any) => {
        credentials?.[0]?.variants?.map(variant => {
          if (variant?.sku === inactiveCredential?.sku) {
            ifHasLapsedCredential = true;
          }

          if (variant?.pathwayKey?.key === MembershipTypes.CredentialPathwayKeys.reinstatement) {
            reinstatementPathway = variant;
          }
        });
      });

      if (ifHasLapsedCredential && !isLapsedCredentials) {
        setOpen(false);
        setIsLapsedCredentials(true);
        if (reinstatementPathway) {
          // removed the previous pathway
          dispatch(removeMembershipPackageCredential(credentials?.[0]?.productId));
          // pre selected the reinstatement pathway
          dispatch(addMembershipPackageCredential(credentials?.[0]?.productId, reinstatementPathway?.sku || ''));
        }
      }
    }
  }, [customerInactiveCredentials, credentials, isLapsedCredentials, setOpen, dispatch]);

  const closeModal = useCallback(() => {
    setSelectedPathway('');
    setVariantSku('');
    setOpen(false);
    history.goBack();
  }, [setSelectedPathway, setVariantSku, setOpen, history]);

  const onClickConfirm = useCallback(() => {
    if (!isDisabled && selectedPathWay !== '' && variantSku !== '') {
      dispatch(
        addMembershipPackageCredential(
          selectedCredentialProductId,
          variantSku,
          numOfExams,
          eligibleExams,
          pathwayName,
          alternativeExams
        )
      );
      setSelectedPathway('');
      setVariantSku('');
      setOpen(!isOpen);
    }
  }, [
    dispatch,
    isDisabled,
    selectedPathWay,
    variantSku,
    selectedCredentialProductId,
    setOpen,
    isOpen,
    setSelectedPathway,
    setVariantSku,
    numOfExams,
    eligibleExams,
    pathwayName,
    alternativeExams,
  ]);

  const onChangeSelection = useCallback(
    (
      pathWay: string,
      sku: string,
      examsData: any,
      numOfExamsData: string,
      pathwayNameData: string,
      alternativeExamsData: any
    ) => {
      setDisable(false);
      setSelectedPathway(pathWay);
      setVariantSku(sku);
      setNumOfExams(numOfExamsData);
      setEligibleExams(examsData);
      setPathwayName(pathwayNameData);
      setAlternativeExams(alternativeExamsData);
    },
    [
      setSelectedPathway,
      setDisable,
      setVariantSku,
      setNumOfExams,
      setEligibleExams,
      setPathwayName,
      setAlternativeExams,
    ]
  );

  const pathWayCount = useCallback(() => {
    if (credentials.length) {
      return credentials[0].variants.length;
    }
  }, [credentials]);

  return (
    <>
      <StyledModal size="small" heading="Select your pathway" open={isOpen}>
        <CloseButton>
          <StyledCloseIcon onClick={closeModal} />
        </CloseButton>
        <Spacer />
        <CenteredText>
          {`Choose a track that best aligns with your schedule and goals. Which pathway for the ${
            credentials?.[0]?.name || ''
          } credential do you prefer?`}
        </CenteredText>
        <StyledGrid columns={1} divided pathWayCount={pathWayCount}>
          <PathWaysWrapper>
            {!!credentials.length &&
              credentials[0].variants.map(variant => {
                let ifPathwayIncludedInMembership = true;

                if (activeMembershipType && variant?.membershipTypes !== '' && variant?.membershipTypes?.length !== 0) {
                  ifPathwayIncludedInMembership = variant?.membershipTypes?.some(
                    (membershipType: any) => membershipType?.label?.['en-US'] === activeMembershipType
                  );
                }

                if (
                  (variant?.pathwayKey?.key === MembershipTypes.CredentialPathwayKeys.reinstatement &&
                    !ifExistingUserCredential) ||
                  !ifPathwayIncludedInMembership
                ) {
                  return <></>;
                }

                return (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <RadioButton
                          checked={selectedPathWay === variant.pathway_name}
                          onChange={onChangeSelection.bind(
                            null,
                            variant.pathway_name as string,
                            variant.sku as string,
                            variant.eligibleExams as any,
                            variant.num_of_exams as string,
                            variant.pathway_name as string,
                            variant.alternativeExams as string
                          )}
                        />
                        <StyledLabel fontStyle={selectedPathWay === variant.pathway_name ? 'bold' : 'normal'}>
                          {variant.pathway_name}
                        </StyledLabel>
                        <PathWayDescription>
                          {variant.pathway_description?.slice(0, 140)}
                          <StyledLearnMore
                            testId={`product-learn-more-${credentials?.[0]?.contentfulSlug}`}
                            to={getContentUrl({
                              contentCategory: { name: '', slug: Content.CategorySlugs.RESOURCES },
                              contentfulType: ContentfulHelpers.CONTENT_TYPES.STATIC_LANDING_PAGE,
                              slug: credentials?.[0]?.contentfulSlug,
                            })}
                            isExternal={true}
                          >
                            Learn More
                          </StyledLearnMore>
                        </PathWayDescription>
                      </Grid.Column>
                    </Grid.Row>
                    <br />
                    <br />
                  </>
                );
              })}
          </PathWaysWrapper>
        </StyledGrid>
        <DividerWithBottomMargin />
        <CenteredButtons>
          <DesktopStyled>
            <ConfirmButton onClick={onClickConfirm} isDisabled={isDisabled} />
          </DesktopStyled>
          <MobileStyled>
            <ConfirmButton onClick={onClickConfirm} isDisabled={isDisabled} />
          </MobileStyled>
        </CenteredButtons>
      </StyledModal>
    </>
  );
};

interface LabelProps {
  fontStyle: string;
}

const StyledLearnMore = styled(Link)`
  z-index: 1;
  position: relative;
  left: 0;
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${props => props.theme.colors.primaryPurple};
  margin-left: ${props => props.theme.pxToRem(2)};
`;

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  width: ${props => props.theme.pxToRem(538)};
  height: ${props => props.theme.pxToRem(69)};
  margin: ${props => props.theme.pxToRem(28)} ${props => props.theme.pxToRem(56.5)} ${props => props.theme.pxToRem(22)}
    ${props => props.theme.pxToRem(24.5)};
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.colors.neutralGrey8};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(310)};
    margin: ${props => props.theme.pxToRem(-5)} 0 ${props => props.theme.pxToRem(40)} 0;
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    width: ${props => props.theme.pxToRem(538)};
    margin: ${props => props.theme.pxToRem(28)} ${props => props.theme.pxToRem(56.5)}
      ${props => props.theme.pxToRem(22)} ${props => props.theme.pxToRem(24.5)};
  }
`;
const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledGrid = styled(Grid)<{ pathWayCount: number }>`
  &&& {
    height: ${({ theme, pathWayCount }) => theme.pxToRem(pathWayCount <= 3 ? 300 : (pathWayCount - 3) * 50 + 300)};
  }
`;

const PathWaysWrapper = styled.div`
  margin-left: 8%;
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const StyledLabel = styled(Label)<LabelProps>`
  position: relative;
  top: ${props => props.theme.pxToRem(-22)};
  left: ${props => props.theme.pxToRem(30)};

  ${props =>
    props.fontStyle === 'bold'
      ? css`
          font-weight: bold !important;
        `
      : css`
          font-weight: initial !important;
        `}
`;

const PathWayDescription = styled.span`
  position: absolute;
  left: ${props => props.theme.pxToRem(100)};
  width: ${props => props.theme.pxToRem(472)};
  height: ${props => props.theme.pxToRem(69)};
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-top: ${props => props.theme.pxToRem(10)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(340)};
    left: ${props => props.theme.pxToRem(10)};
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    width: ${props => props.theme.pxToRem(472)};
    margin-top: ${props => props.theme.pxToRem(10)};
    left: ${props => props.theme.pxToRem(100)};
  }
`;

interface ModalButtonProps {
  onClick: () => void;
  isDisabled: boolean;
  variant?: ButtonVariants;
}

const ConfirmButton: React.FC<ModalButtonProps> = ({ onClick, isDisabled }) => (
  <StyledConfirmButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId="path-way-modal"
    disabled={isDisabled}
  >
    Confirm
  </StyledConfirmButton>
);

const StyledConfirmButton = styled(StyledButton)`
  width: 170px !important;
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    ${props => props.theme.mediaQueries.desktopOnly} {
      width: ${props => props.theme.pxToRem(620)};
    }
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }
    ${props => props.theme.mediaQueries.tabletOnly} {
      width: ${props => props.theme.pxToRem(620)};
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100%;
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    left: 45%;
  }
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
