import { Product } from 'mxp-schemas';
import { arrayIncludes } from 'utils';

const accessText = (duration: string) =>
  `This is a digital product. With full paid access the content will be available to you for ${duration} after purchase date.`;

export const getAccessText = (
  productType: Product.ProductType,
  calculatedAccessDuration: string,
  availableFormats: string[],
  availability?: number
) => {
  const isConference = productType === Product.ProductType.CONFERENCE;
  const isPublication = productType === Product.ProductType.PUBLICATION;
  const isEbook = arrayIncludes([Product.AvailableFormat.EBOOK], availableFormats[0]?.toLowerCase());

  const noExpiryEbook = isPublication && isEbook && availability === 9999;

  if (!isConference && !calculatedAccessDuration) return '';
  if (isConference && !availableFormats?.length) return '';
  if (noExpiryEbook) return 'Lifetime';

  return accessText(calculatedAccessDuration);
};
