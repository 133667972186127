import React from 'react';
import styled from 'styled-components';
import { ButtonEnums } from 'components/atoms';
import { StyledActionButton } from 'components/molecules/ProductItem/ProductItemButton';

interface Props {
  testId: string;
  isProfilePurchasesPage: boolean;
  handleDeprovisioning: () => void;
  text: string;
}

export const DeprovisionButton: React.FC<Props> = ({ testId, handleDeprovisioning, isProfilePurchasesPage, text }) => {
  const [deprovisionClicked, setDeprovisionClicked] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const handleDeprovisioningClick = React.useCallback(() => {
    setButtonLoading(true);
    handleDeprovisioning();
    setTimeout(() => {
      setButtonLoading(false);
      setDeprovisionClicked(true);
    }, 3000);
  }, [handleDeprovisioning, setDeprovisionClicked]);
  return (
    <>
      <StyledActionButton
        testId={testId}
        variant={ButtonEnums.variants.secondary}
        size={isProfilePurchasesPage ? ButtonEnums.sizes.small : ButtonEnums.sizes.medium}
        bordered
        onClick={handleDeprovisioningClick}
        data-is-stacked-in-column={isProfilePurchasesPage}
        disabled={deprovisionClicked}
        loading={buttonLoading}
      >
        {text}
      </StyledActionButton>
      {deprovisionClicked && <Text>{'Refresh page to confirm removal'}</Text>}
    </>
  );
};

const Text = styled.span`
  font-size: ${props => props.theme.fontSizes.xxs};
  text-align: center;
`;
