import React from 'react';
import { Product } from 'mxp-schemas';
import { UserMemberTypes } from 'modules/user/constants';
import styled from 'styled-components';
import { centPriceToString } from 'utils';
import { NotificationForAnonymousUser } from 'components/molecules/NotificationForAnonymousUser';
import { productCurrencySelector } from 'modules/products/selectors';
import { getFormattedBundlePrice } from 'modules/products/helpers';
import { useSelector } from 'react-redux';

interface Props {
  productItem: Product.ProductItem;
  userMemberType: UserMemberTypes;
  isAuth: boolean;
  isUserSuspendedByEthics?: boolean;
}

export const ProductBundlePriceRange: React.FC<Props> = ({
  productItem,
  userMemberType,
  isAuth,
  isUserSuspendedByEthics,
}) => {
  const { bundleDiscountPercent = 0 } = productItem;
  const fractionAfterDiscount = (100 - bundleDiscountPercent) / 100;
  const isMember: boolean = userMemberType === UserMemberTypes.PREMIUM || userMemberType === UserMemberTypes.MEMBER;
  const currency = useSelector(productCurrencySelector)?.label;
  const bundlePrices = getFormattedBundlePrice(productItem?.bundlePrices, currency);
  const {
    standardNonMemberPriceFormatted,
    minPriceNonMemberBundleFormatted,
    maxPriceNonMemberBundleFormatted,
    minPriceMemberBundleFormatted,
    maxPriceMemberBundleFormatted,
  } = React.useMemo(() => {
    return {
      standardNonMemberPriceFormatted: centPriceToString(
        bundlePrices?.nonMember?.max?.amount || 0,
        bundlePrices?.nonMember?.max?.currency || currency
      ),
      maxPriceNonMemberBundleFormatted: centPriceToString(
        (bundlePrices?.nonMember?.max?.amount || 0) * fractionAfterDiscount,
        bundlePrices?.nonMember?.max?.currency || currency
      ),
      minPriceNonMemberBundleFormatted: centPriceToString(
        (bundlePrices?.nonMember?.min?.amount || 0) * fractionAfterDiscount,
        bundlePrices?.nonMember?.min?.currency || currency
      ),
      maxPriceMemberBundleFormatted: centPriceToString(
        (bundlePrices?.member?.max?.amount || 0) * fractionAfterDiscount,
        bundlePrices?.member?.max?.currency || currency
      ),
      minPriceMemberBundleFormatted: centPriceToString(
        (bundlePrices?.member?.min?.amount || 0) * fractionAfterDiscount,
        bundlePrices?.member?.min?.currency || currency
      ),
    };
  }, [bundlePrices, fractionAfterDiscount, currency]);

  const minPrice = isAuth
    ? isMember
      ? minPriceMemberBundleFormatted
      : minPriceNonMemberBundleFormatted
    : minPriceMemberBundleFormatted;
  const maxPrice = isMember ? maxPriceMemberBundleFormatted : maxPriceNonMemberBundleFormatted;

  return isUserSuspendedByEthics ? (
    <>
      <StyledPriceInfo>
        <StyledPriceInfoSuspended>{standardNonMemberPriceFormatted}</StyledPriceInfoSuspended>
        <StyledFinalPrice>
          {minPrice === maxPrice ? `(${minPrice}` : `(${minPrice} - ${maxPrice}`}
          {isMember && ' for members)'}
        </StyledFinalPrice>
      </StyledPriceInfo>
      <StyledDiscount>{bundleDiscountPercent}% bundle discount applied</StyledDiscount>
      {!isAuth && <StyledNotificationForAnonymousUser />}
    </>
  ) : (
    <>
      <StyledPriceInfo>
        <StyledFullPrice>{standardNonMemberPriceFormatted}</StyledFullPrice>
        <StyledFinalPrice>
          {minPrice === maxPrice ? minPrice : `${minPrice} - ${maxPrice}`}
          {isMember && ' (Member price)'}
        </StyledFinalPrice>
      </StyledPriceInfo>
      <StyledDiscount>{bundleDiscountPercent}% bundle discount applied</StyledDiscount>
      {!isAuth && <StyledNotificationForAnonymousUser />}
    </>
  );
};

const StyledNotificationForAnonymousUser = styled(NotificationForAnonymousUser)`
  margin-top: ${props => props.theme.pxToRem(-10)};
  margin-bottom: ${props => props.theme.pxToRem(26)};
`;

const StyledDiscount = styled.div`
  margin-right: ${props => props.theme.pxToRem(8)};
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.secondaryTeal};
`;

const StyledPriceInfo = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(4)};
`;

const StyledFullPrice = styled.span`
  margin-right: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  text-decoration: line-through;
`;

const StyledFinalPrice = styled.span`
  font-size: ${props => props.theme.fontSizes.m};
`;

const StyledPriceInfoSuspended = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
`;
