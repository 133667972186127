import { isServer } from 'utils';
import { getPreloadedConfig } from '../../config';

export const handleBingAdsConversion = async (payload: any) => {
  if (!isServer && payload && typeof (window as any).uetq !== 'undefined') {
    (window as any).uetq = (window as any).uetq || [];
    (window as any).uetq.push('event', '', { revenue_value: payload.cartAmount, currency: 'usd' });
  }
};

export const handleGoogleAdsConversion = async (payload: any) => {
  if (!isServer && payload && typeof (window as any).gtag === 'function') {
    const googleTagManagerTag = getPreloadedConfig().googleTagManagerTag;
    (window as any).gtag('event', 'conversion', {
      send_to: `${googleTagManagerTag.id}/${googleTagManagerTag.conversionLabel}`,
      value: payload.cartAmount,
      currency: 'USD',
      transaction_id: payload.orderNumber,
    });
  }
};

export const handleGoogleAdsRemarketing = async (payload: any) => {
  if (!isServer && payload && typeof (window as any).gtag === 'function') {
    const googleTagManagerTag = getPreloadedConfig().googleTagManagerTag;
    (window as any).gtag('event', 'page_view', {
      send_to: `${googleTagManagerTag.id}`,
      value: payload.price[0],
      items: [{ id: payload.id[0], google_business_vertical: 'retail' }],
    });
  }
};
