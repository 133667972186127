import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import Paginator from 'paginator';
import { ButtonProps } from 'semantic-ui-react';
import { Button, Icon } from 'components/atoms';
import { range } from 'utils';
import { DefaultPagination } from 'constants/index';

interface Props {
  className?: string;
  total: number;
  page: number;
  perPage: number;
  maxPages?: number;
  testId: string;
  resultsLength?: number;
  onPageError?(): void;
  onPageChange(pageNum: number): void;
}

export const Pagination: React.FC<Props> = ({
  className,
  total,
  page = 1,
  perPage,
  maxPages = DefaultPagination.MAX_PAGES,
  testId,
  resultsLength,
  onPageError,
  onPageChange,
}) => {
  const onPrevious = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
    const previous = Math.max(page - 1, 0);
    onPageChange(previous);
  };

  const onNext = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
    onPageChange(page + 1);
  };
  const onJumpTo = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
    const pageNumber =
      event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.arg
        ? event.currentTarget.dataset.arg
        : '1';
    const toPage: number = parseInt(pageNumber, 10);
    onPageChange(toPage);
  };

  const paginator = new Paginator(perPage, maxPages);
  const searchResultsPagination = paginator.build(total, page);

  if (page > searchResultsPagination.total_pages && searchResultsPagination.total_pages > 0 && onPageError) {
    onPageError();
  }

  const pagesToRender = range(searchResultsPagination.first_page, searchResultsPagination.last_page);

  let hasDotAfter = false;
  let hasDotBefore = false;
  const isWithinFirst2Pages = (currentPage: number, pages: number[]) => {
    const index = pages.indexOf(currentPage);
    return index !== -1 && index <= 1;
  };
  const isWithinLast2Pages = (currentPage: number, pages: number[]) => {
    const index = pages.indexOf(currentPage);
    return index !== -1 && index >= pages.length - 2;
  };
  /*
    Removing the first or last two elements and then either adding page 1 or the last possible page.
    So if you had 13 total items, with 1 item per page, you were on page 1 and you only wanted to show 10 pages
    the final array would look like [1,2,3,4,5,6,7,8,13]. If you were on page 13 the array would be [1,6,7,8,9,10,11,12,13].
    You'll notice that's only 9 elements, the 10th element is the dots, which is rendered whilst we're looping through this array.
    */
  if (searchResultsPagination.total_pages > maxPages) {
    const canShowDotAfter = isWithinFirst2Pages(page, pagesToRender);
    const canShowDotBefore = isWithinLast2Pages(page, pagesToRender);
    if (canShowDotAfter) {
      hasDotAfter = true;
      pagesToRender.pop();
      pagesToRender.pop();
      pagesToRender.push(searchResultsPagination.total_pages);
    } else if (canShowDotBefore) {
      hasDotBefore = true;
      pagesToRender.shift();
      pagesToRender.shift();
      pagesToRender.unshift(1);
    } else if (!canShowDotAfter && !canShowDotBefore) {
      hasDotAfter = false;
      hasDotBefore = false;
      pagesToRender.shift();
      pagesToRender.unshift(1);
      pagesToRender.pop();
      pagesToRender.push(searchResultsPagination.total_pages);
    }
  }

  return (
    <StyledPagination className={className}>
      <StyledList data-testid={testId}>
        <StyledListItem className="previous">
          <OnePageButton
            disabled={Math.floor(Number(page)) === 1 || resultsLength === 0}
            onClick={onPrevious}
            aria-label="Previous"
            testId="previous-page"
          >
            <Icon name="angle left" data-testid="page-arrow-down" />
          </OnePageButton>
        </StyledListItem>
        {pagesToRender.map((pageNum: number, i: number) => (
          <Fragment key={pageNum}>
            {i === pagesToRender.length - 1 && hasDotAfter && (
              <StyledListItem className="dots">
                <StyledFakeLink className="fake-link">...</StyledFakeLink>
              </StyledListItem>
            )}
            <StyledListItem>
              <StyledButton
                data-arg={pageNum}
                onClick={onJumpTo}
                className={pageNum === searchResultsPagination.current_page ? 'active link' : 'link'}
                testId="got-to-page"
                disabled={page === pageNum}
              >
                {pageNum}
              </StyledButton>
            </StyledListItem>
            {i === 0 && hasDotBefore && (
              <StyledListItem className="dots">
                <StyledFakeLink className="fake-link">...</StyledFakeLink>
              </StyledListItem>
            )}
          </Fragment>
        ))}
        <StyledListItem className="next">
          <OnePageButton
            disabled={
              page === searchResultsPagination.total_pages ||
              Math.floor(Number(page)) === pagesToRender.slice(-1)[0] ||
              resultsLength === 0
            }
            onClick={onNext}
            aria-label="Next"
            testId={'next-page'}
          >
            <Icon name="angle right" data-testid="page-arrow-down" />
          </OnePageButton>
        </StyledListItem>
      </StyledList>
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled.li`
  list-style: none;
  outline: solid 1px ${props => props.theme.colors.neutralGrey2};
  margin: 0 1px 1px 0;
  &:not(.next):not(.previous) {
    display: list-item;
  }
`;

const buttonStyle = css`
  &&&&& {
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    padding: 0px;
    padding-top: 1px;
    margin-right: 0;
    background: transparent;
    color: ${props => props.theme.colors.primaryPurple};
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.bold};
    line-height: 48px;
    text-decoration: none;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 46px;
    }
  }

  &&&&.active {
    background: ${props => props.theme.colors.neutralGrey1};
    color: ${props => props.theme.colors.neutralGrey8};
  }

  &&&&[disabled] {
    color: ${props => props.theme.colors.neutralGrey8};
    border: none;
  }
`;

const StyledButton = styled(Button)`
  ${buttonStyle}

  &&&&:not(.active):hover {
    border: 1px solid ${props => props.theme.colors.primaryPurple};
    padding-top: 0;
  }
`;

const StyledFakeLink = styled.span`
  ${buttonStyle}

  &&&&& {
    color: ${props => props.theme.colors.neutralGrey4};
    display: inline-flex;
    padding-bottom: ${props => props.theme.pxToRem(14)};
    padding-left: ${props => props.theme.pxToRem(5)};
    font-size: ${props => props.theme.pxToRem(25)};
    letter-spacing: ${props => props.theme.pxToRem(2)};
    overflow: hidden;
    height: ${props => props.theme.pxToRem(49)};
  }
`;

const OnePageButton = styled(StyledButton)`
  &&&& {
    margin: 0px;
    padding: 0;
    line-height: 48px;
    width: 48px;
    background: none;
    color: ${props => props.theme.colors.primaryPurple};

    // Semantic UI overrides
    &.ui.button:not(.icon) > .right.icon:not(.button):not(.dropdown),
    &.ui.button > .icon:not(.button) {
      margin: 0px;
      cursor: pointer;
    }

    &[disabled] {
      color: ${props => props.theme.colors.neutralGrey4};
      border: none;
    }
  }
`;
