import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getSearch } from 'connected-react-router';
import { parse } from 'query-string';
import { BlockInquiryForm } from 'components/organisms/BlockRenderer/BlockInquiryForm';
import { submitInquiryForm, startFormFill, endFormFill } from 'modules/inquiryForm/actions';
import {
  isErrorSelector,
  isSuccessSelector,
  isLoadingSelector,
  isErrorMessageSelector,
} from 'modules/inquiryForm/selectors';
import { userDataSelector, personAccountDataSelector } from 'modules/user/selectors';
import { InquiryForm } from 'mxp-schemas';
import { getCountryCodeByDialCode } from '../utils/StringHelpers';

const mapActionCreators = (dispatch: Dispatch) => ({
  submit(inquiryForm: InquiryForm.FormTypes): void {
    dispatch(submitInquiryForm(inquiryForm));
  },
  startFormFill(): void {
    dispatch(startFormFill());
  },
  endFormFill(text: string): void {
    dispatch(endFormFill(text));
  },
});
const mapStateToProps = (state: State.Root) => {
  const personAccountData = personAccountDataSelector(state);
  return {
    error: isErrorSelector(state),
    loading: isLoadingSelector(state),
    success: isSuccessSelector(state),
    errorMessage: isErrorMessageSelector(state),
    userData: {
      ...userDataSelector(state),
      phone: personAccountData.primaryPhone.phoneNumber,
    },
    dialCode: personAccountData.primaryPhone.countryCode as string,
    countryCode: getCountryCodeByDialCode(personAccountData.primaryPhone.countryCode as string),
    queryParams: parse(getSearch(state)),
  };
};

export const BlockInquiryFormContainer = connect(mapStateToProps, mapActionCreators)(BlockInquiryForm);
