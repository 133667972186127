import React from 'react';
import styled from 'styled-components';
import { Table } from 'semantic-ui-react';
import { SeatManagementTableRow } from './SeatManagementTableRow';
import { SeatManagement } from 'mxp-schemas';
import { ServiceContract } from 'mxp-schemas/src/types/seatManagement';

interface SeatManagementProps {
  serviceContracts: SeatManagement.ServiceContract[];
  seatManagementInvite: SeatManagement.AllocatedSeatDynamoDbRecord[] | any[];
  inactive: boolean;
  isOpenModal: boolean;
  isInviteUpdate: boolean;
  handleInviteCancel: (inviteId: string) => void;
  getSeatManagementInvite: (orderNumber: string) => void;
  showSeatAssignmentModal: (contract: ServiceContract) => void;
  showDeallocationModal: () => void;
  setSelectedSeatsToDeallocate(
    payload: {
      contract: SeatManagement.ServiceContract;
      lineItemsToDeallocate: SeatManagement.ContractLineItem[];
      pendingList?: string[];
    } | null
  ): void;
  eventDateFilterItems: Common.FilterItemProps[];
  eventStartDateSelectedItems: string[];
  eventEndDateSelectedItems: string[];
  changeFilter: (name: string, value: string) => void;
  extendProductAccess: (args: SeatManagement.ProductAccessExtension) => void;
  toggleProductAccessExtensionModal: () => void;
  getRecentExtensionProcessStatus: (contractId: string) => boolean;
}

export const SeatManagementTable: React.FC<SeatManagementProps> = ({
  serviceContracts,
  seatManagementInvite,
  inactive,
  isOpenModal,
  isInviteUpdate,
  handleInviteCancel,
  getSeatManagementInvite,
  showSeatAssignmentModal,
  showDeallocationModal,
  setSelectedSeatsToDeallocate,
  eventStartDateSelectedItems,
  eventEndDateSelectedItems,
  changeFilter,
  extendProductAccess,
  toggleProductAccessExtensionModal,
  getRecentExtensionProcessStatus,
}) => {
  return (
    <StyledTable stackable>
      <Table.Header>
        <Table.Row>
          <HeaderCellStyled>Seat Status</HeaderCellStyled>
          <HeaderCellStyled>CPE & Learning</HeaderCellStyled>
          <HeaderCellStyled>Expires on</HeaderCellStyled>
          <HeaderCellStyled>Actions</HeaderCellStyled>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {serviceContracts.map((contract: SeatManagement.ServiceContract) => (
          <SeatManagementTableRow
            contract={contract}
            inactive={inactive}
            isOpenModal={isOpenModal}
            isInviteUpdate={isInviteUpdate}
            handleInviteCancel={handleInviteCancel}
            seatManagementInvite={seatManagementInvite}
            getSeatManagementInvite={getSeatManagementInvite}
            key={contract.id}
            showSeatAssignmentModal={showSeatAssignmentModal}
            showDeallocationModal={showDeallocationModal}
            setSelectedSeatsToDeallocate={setSelectedSeatsToDeallocate}
            extendProductAccess={extendProductAccess}
            toggleProductAccessExtensionModal={toggleProductAccessExtensionModal}
            getRecentExtensionProcessStatus={getRecentExtensionProcessStatus}
          />
        ))}
      </Table.Body>
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  &&&& {
    border-style: solid;
    border-width: 0 0 ${props => props.theme.pxToRem(1)} 0;
    border-color: ${props => props.theme.colors.neutralGrey3};
    margin: 0;

    tr {
      :nth-of-type(2n) {
        background-color: rgba(247, 247, 247, 0.3);
        + td {
          background-color: rgba(247, 247, 247, 0.3);
        }
      }

      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-top: ${props => props.theme.pxToRem(24)};
        padding-bottom: ${props => props.theme.pxToRem(24)};
        box-shadow: 0 ${props => props.theme.pxToRem(-1)} 0 0 ${props => props.theme.colors.neutralGrey3} inset !important;
        margin-top: ${props => props.theme.pxToRem(16)};

        :first-child {
          padding-top: 0;
        }
      }
    }

    thead tr th {
      ${props => props.theme.mediaQueries.mobileOnly} {
        :first-child {
          padding-top: ${props => props.theme.pxToRem(24)} !important;
        }
        :last-child {
          padding-bottom: ${props => props.theme.pxToRem(24)} !important;
        }
        font-size: ${props => props.theme.fontSizes.s};
      }
    }
    thead tr {
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-bottom: 0 !important;
      }
    }

    tr th {
      padding: ${props => props.theme.pxToRem(11)} ${props => props.theme.pxToRem(20)};
      border-bottom-color: ${props => props.theme.colors.neutralGrey3};
      background-color: ${props => props.theme.colors.neutralGrey1};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.medium};
      line-height: 1.57;

      ${props => props.theme.mediaQueries.mobileOnly} {
        font-size: ${props => props.theme.fontSizes.s};
        :nth-child(2) {
          text-align: left;
        }
      }
    }

    tr td {
      padding: ${props => props.theme.pxToRem(21)} ${props => props.theme.pxToRem(20)};
      border-top-color: ${props => props.theme.colors.neutralGrey3};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.light};
      line-height: 1.5;

      ${props => props.theme.mediaQueries.mobileOnly} {
        padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;
        font-size: ${props => props.theme.fontSizes.s} !important;

        :first-child {
          font-weight: ${props => props.theme.fontWeights.regular};
        }

        :nth-child(2) {
          text-align: left;
        }
      }
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      border-width: 0;
      margin-top: 0;
    }
  }
`;

const HeaderCellStyled = styled(Table.HeaderCell)`
  &&&&&&& {
    text-align: left;
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.neutralGrey8};
  }
`;
