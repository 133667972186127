export interface TRenewMembershipDetailsTableData {
  accountType?: {
    membershipType: string;
    validUntil: string;
    status: string;
  };

  serviceTier?: {
    selectedTier: string;
    employmentStatus: string;
    role: string;
    value: string;
    status: string;
    reason: string;
    productId: string;
  };

  membershipSections?: TMultiRowProps[];

  membershipCredentials?: TMultiRowProps[];

  addOns?: TMultiRowProps[];
}

export interface TRenewalFee {
  title: string;
  price: number;
}

export interface TRenewalSummaryTableData {
  fee: {
    enrolment: TRenewalFee;
    selectedMembershipType: TRenewalFee;
    selectedTierType: TRenewalFee;
    membershipSections: TRenewalFee;
    membershipAddOns: TRenewalFee;
  };
  donations?: TRenewalFee[];
  costs: {
    subtotal: number;
    tax: number;
    total: number;
  };
}

export type TRowProps = { [key: string]: string | number } | undefined;

export const MEMBERSHIP_TYPE = 'membershipType';
export const SELECTED_TIRE = 'selectedTier';
export const NO_VALUE_STRING = '-';
export type NO_VALUE_STRING_TYPE = typeof NO_VALUE_STRING;

export interface TMultiRowProps {
  name: string;
  value: string;
  status: string;
  isIncluded?: boolean;
}
