import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { sendMessage } from 'modules/app';
import { getHostedPageSignature, payInvoice } from 'modules/centerAdmin/actions';
import { StepPayment } from 'components/pages/PageCenterAdminInvoicesPaymentPage/StepPayment';
import { selectedInvoiceSelector } from 'modules/centerAdmin/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  getHostedPageSignature(): Promise<void> {
    return dispatch(getHostedPageSignature());
  },

  payInvoice(paymentMethodId: string): void {
    dispatch(payInvoice(paymentMethodId));
  },

  sendMessage(message: string): void {
    dispatch(sendMessage(message));
  },
});

const mapStateToProps = (state: State.Root) => {
  const invoice = selectedInvoiceSelector(state);
  const accountId = invoice.accountId;

  return {
    isInvoicePayment: true,
    invoice,
    isAdminInvoicesPayment: true,
    adminInvoicesTotalAmountToPay: invoice.balance,
    accountId,
    amountOption: null,
  };
};

export const CenterAdminStepPaymentInvoiceContainer = connect(mapStateToProps, mapActionCreators)(StepPayment);
