import { functionalSpecialismList } from 'constants/index';
import { transformCountryValue } from 'utils';
import { CheckoutCountriesListHash } from 'mxp-utils';

export const practicePDF = async (data: any) => {
  try {
    const { jsPDF } = await import('jspdf');
    const doc = new jsPDF();
    const practice = data?.payload?.[0];
    // @typescript-eslint/no-non-null-assertion eslint-disable no-shadowed-variable
    const functionalSpecialism = practice.functionalSpecialism?.split(';');

    const funcitonalism = (labelH: any, labelV: any, checkbox: any) => {
      let list = 0;
      for (let i = 0; i < 90; i += 5) {
        doc.setFont('courier', 'normal');
        doc.setFontSize(7).text(`${functionalSpecialismList[list]?.text}`, labelH, labelV + i, { align: 'left' });
        const rectItems: any[] = [10, checkbox + i, 3, 3];
        if (functionalSpecialism?.indexOf(`${functionalSpecialismList[list]?.text}`) > -1) {
          rectItems.push('F');
        }
        doc.rect(...(rectItems as [number, number, number, number, string | null | undefined]));
        list += 1;
      }
    };

    const countriesListOptions = Object.values(CheckoutCountriesListHash).map((country: any, index) => {
      return {
        key: index,
        value: country.text,
        text: country.text,
        image: country.image,
        ISOAlpha3Code: country.ISOAlpha3Code,
      };
    });

    const selectedCountryLocation = countriesListOptions?.filter(
      (country: any) => country?.ISOAlpha3Code === practice?.continuityLocation
    );

    doc.setTextColor(40, 41, 55);
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(16).text(`Practice Requirements`, 10, 15, { align: 'left' });

    // gray line
    doc.setDrawColor(229, 229, 230);
    doc.setLineWidth(0.3);
    doc.line(10, 18, 130, 18);

    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(7).text('All the fields are mandatory if not mentionned otherwise.', 10, 28, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12).text('Functional specialisms', 10, 40, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Functional specialism(s) you intend to work in', 10, 50, { align: 'left' });

    funcitonalism(15, 60, 57.5);

    doc.setFont('courier', 'normal');
    doc
      .setFontSize(7)
      .text(
        'I hereby declare that all information provied is true and I test to be competent to work in all areas ticked.',
        15,
        150,
        { align: 'left' }
      );
    doc.rect(10, 147.5, 3, 3, 'F');

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12).text('Professional Indemnity Insurance (PII) details', 10, 160, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Name of insurer', 10, 170, { align: 'left' });
    doc.rect(10, 172, 105, 7);
    doc.setFontSize(7).text(`${practice.brokerInsurerName}`, 12, 176.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Policy Number', 10, 184, { align: 'left' });
    doc.rect(10, 186, 105, 7);
    doc.setFontSize(7).text(`${practice.policyNumber}`, 12, 190.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Expiry date (JJ/MM/AAAA)', 10, 198, { align: 'left' });
    doc.rect(10, 200, 105, 7);
    doc.setFontSize(7).text(`${practice.expiryDate}`, 12, 204.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12).text('Continuity arrangement details', 10, 219, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Last name', 10, 229, { align: 'left' });
    doc.rect(10, 231, 105, 7);
    doc.setFontSize(7).text(`${practice?.continuityLastName}`, 12, 235.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('First name', 10, 243, { align: 'left' });
    doc.rect(10, 245, 105, 7);
    doc.setFontSize(7).text(`${practice?.continuityFirstName}`, 12, 249.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Name of practice', 10, 257, { align: 'left' });
    doc.rect(10, 259, 105, 7);
    doc.setFontSize(7).text(`${practice?.continuityPracticeName}`, 12, 263.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Mobile number (optional)', 10, 271, { align: 'left' });
    doc.rect(10, 273, 15, 7);
    doc
      .setFontSize(7)
      .text(
        `${transformCountryValue(practice?.continuityMobileAreaCode)?.code || ''} ${
          transformCountryValue(practice?.continuityMobileAreaCode)?.dialCode || ''
        } `,
        12,
        277.5,
        { align: 'left' }
      );
    doc.rect(25, 273, 90, 7);
    doc.setFontSize(7).text(`${practice?.continuityMobileNumber || ''}`, 27, 277.5, { align: 'left' });

    doc.addPage('a4');

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Fax (optional)', 10, 15, { align: 'left' });
    doc.rect(10, 17, 15, 7);
    practice?.continuityFax
      ? doc
          .setFontSize(7)
          .text(
            `${transformCountryValue(practice?.continuityFaxAreaCode)?.code || ''} ${
              transformCountryValue(practice?.continuityFaxAreaCode)?.dialCode || ''
            } `,
            12,
            21.5,
            { align: 'left' }
          )
      : doc.setFontSize(7).text(`US +1`, 12, 21.5, { align: 'left' });
    doc.rect(25, 17, 90, 7);
    doc.setFontSize(7).text(`${practice?.continuityFax || ''}`, 27, 21.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Address Line 1', 10, 29, { align: 'left' });
    doc.rect(10, 31, 105, 7);
    doc.setFontSize(7).text(`${practice.continuityAddressLine1}`, 12, 35.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Address Line 2 (if applicable)', 10, 43, { align: 'left' });
    doc.rect(10, 45, 105, 7);
    doc.setFontSize(7).text(`${practice?.continuityAddressLine2 || ''}`, 12, 49.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Address Line 3 (if applicable)', 10, 57, { align: 'left' });
    doc.rect(10, 59, 105, 7);
    doc.setFontSize(7).text(`${practice?.continuityAddressLine3 || ''}`, 12, 63.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('City', 10, 71, { align: 'left' });
    doc.rect(10, 73, 105, 7);
    doc.setFontSize(7).text(`${practice.continuityCity}`, 12, 77.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('State / province (optional)', 10, 86, { align: 'left' });
    doc.rect(10, 88, 50, 7);
    doc.setFontSize(7).text(`${practice?.continuityCountryState || ''}`, 12, 92.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Postal code (optional)' || '', 65, 86, { align: 'left' });
    doc.rect(65, 88, 50, 7);
    doc.setFontSize(7).text(`${practice?.continuityPostalCode || ''}`, 67, 92.5, { align: 'left' });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Country', 10, 100, { align: 'left' });
    doc.rect(10, 102, 105, 7);
    doc
      .setFontSize(7)
      .text(`${(selectedCountryLocation?.length > 0 && selectedCountryLocation[0]?.text) || ''}`, 12, 106.5, {
        align: 'left',
      });

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('Is your nominated person a current CIMA member?', 10, 115, { align: 'left' });

    if (practice.nominatedPerson) {
      doc.setFillColor(0, 0, 0);
      doc.circle(13, 121, 2, 'FD');
      doc.setFontSize(7).text(`Yes`, 18, 121.7, { align: 'left' });

      doc.ellipse(30, 121, 2, 2);
      doc.setFontSize(7).text(`No`, 35, 121.7, { align: 'left' });
    } else {
      doc.ellipse(13, 121, 2, 2);
      doc.setFontSize(7).text(`Yes`, 18, 121.7, { align: 'left' });

      doc.setFillColor(0, 0, 0);
      doc.circle(30, 121, 2, 'FD');
      doc.setFontSize(7).text(`No`, 35, 121.7, { align: 'left' });
    }

    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8).text('CIMA contact ID (if known)', 10, 131, { align: 'left' });
    doc.rect(10, 133, 104, 7);
    doc.setFontSize(7).text(`${practice.bodyName}`, 12, 137.5, { align: 'left' });

    return doc.save(`Practice Requirements.pdf`);
  } catch (error) {
    return;
  }
};
