import React from 'react';
import styled from 'styled-components';
import { Header as SemanticUIHeader, HeaderProps as SemanticUIHeaderProps } from 'semantic-ui-react';

export const Heading: React.FC<SemanticUIHeaderProps> = ({ children, ...props }) => (
  <StyledHeading tabIndex={0} {...props}>
    {children}
  </StyledHeading>
);

const StyledHeading = styled(SemanticUIHeader)`
  font-weight: ${props => props.theme.fontWeights.light};
`;
