import React from 'react';
import styled from 'styled-components/macro';
import { Grid } from 'components/atoms';
import { AnimationBlock, ANIMATIONTYPES } from 'components/molecules/AnimationBlock/AnimationBlock';
import { useScript, ScriptLoadingState } from 'hooks/useScript';
import { isServer } from 'utils';
import { Loader } from 'components/atoms/Loader/Loader';

interface Props {
  modularBlock: Common.ModularContentBlock;
  isFullPagePagination?: boolean;
  isLandingPage?: boolean;
}

export const BlockCerosAnimation: React.FC<Props> = ({ modularBlock, isFullPagePagination, isLandingPage }) => {
  const { description } = (modularBlock as Common.AnimationBlock) || {};
  const cerosScript = useScript('https://view.ceros.com/scroll-proxy.min.js', 'C0001');
  const [iFrameLoading, setIFrameLoading] = React.useState(false);

  React.useEffect(() => {
    if (isServer || (cerosScript !== ScriptLoadingState.Ready && !description)) return;
    setIFrameLoading(true);
  }, [cerosScript, description]);

  return (
    <StyledGrid isFullPagePagination={isFullPagePagination} isLandingPage={isLandingPage}>
      <Grid.Row centered={!iFrameLoading}>
        {!iFrameLoading ? (
          <LoaderWrapper>
            <Loader size="small" active content="Loading" inline />
          </LoaderWrapper>
        ) : (
          <AnimationBlock oembed={description} type={ANIMATIONTYPES.CEROS} />
        )}
      </Grid.Row>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)<{ isFullPagePagination: boolean }>`
  &&&&& {
    overflow-y: ${props => (props.isFullPagePagination ? 'auto' : 'visible')};
    margin-left: 0;
    margin-right: 0;
    ${props =>
      props.isLandingPage &&
      !props.isFullPagePagination &&
      `
        padding-bottom: ${props.theme.pxToRem(20)};
        padding-top: ${props.theme.pxToRem(20)};

      `}
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => `${props.theme.pxToRem(40)} 0`};
`;
