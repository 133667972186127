import React from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';
import { Grid } from 'semantic-ui-react';

/**
 * Confirmation Modal
 * @category Resignation & Termination
 *
 */
export interface Props {
  membershipName: string;
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  isLoading?: boolean;
  isResignation?: boolean;
}

export const ConfirmationResignationTerminationModal: React.FC<Props> = ({
  membershipName,
  open,
  onClose,
  onConfirm,
  isLoading,
  isResignation,
}) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      heading={`Are you sure you want to ${isResignation ? `cancel your firm/organization's` : 'cancel your'}`}
      headingCenterBoldText={membershipName}
      headingCenterAfterBoldText="membership?"
      testId="confirmation-details-modal"
      showCloseCross
      cancelNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          testId="cancel-resignation"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
      }
      confirmNode={
        <Button
          size={ButtonEnums.sizes.small}
          testId="confirm-resignation"
          variant={ButtonEnums.variants.primary}
          onClick={onConfirm}
          loading={isLoading}
        >
          Confirm cancellation
        </Button>
      }
    >
      <Grid stackable centered columns={3}>
        <StyledGridRow />
        {membershipName && (
          <StyledGridRow>
            Your firm/organization's&nbsp;<StyledBold>{membershipName}</StyledBold> will be cancelled.
          </StyledGridRow>
        )}
      </Grid>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &&&& {
    background: ${props => props.theme.colors.neutralGrey1};
    min-height: ${props => props.theme.pxToRem(333)};
    max-width: ${props => props.theme.pxToRem(590)};
    margin-top: ${props => props.theme.pxToRem(48)};
    > .header {
      h2 {
        min-height: ${props => props.theme.pxToRem(64)};
        max-width: ${props => props.theme.pxToRem(440)};
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }
  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding-bottom: ${props => props.theme.pxToRem(10)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-top: ${props => props.theme.pxToRem(10)};
    }
  }
`;

const StyledBold = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: 0 ${props => props.theme.pxToRem(3)} 0 ${props => props.theme.pxToRem(3)};
`;
