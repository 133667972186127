import { locationsSlugIdMapSelector, countriesSlugIdMapSelector, statesSlugIdMapSelector } from './selectors';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Helpers
// ------------------------------------

/*
 * Convert locations filter slugs to locations ids
 */
export const convertLocationsSlugsToIds = (locations: string | string[], state: State.Root): string[] | undefined => {
  if (!locations) return;
  const locationsSlugs: string[] = Array.isArray(locations) ? locations : [locations];
  // Call locationsSlugIdMapSelector only if locationsSlug exist
  const locationsSlugIdMap: { [key: string]: string } = locationsSlugIdMapSelector(state);
  // Get locations ids array from slugs if exist
  return locationsSlugs.map(slug => locationsSlugIdMap[slug]).filter(Boolean);
};

/*
 * Convert countries filter slugs to countries ids
 */
export const convertCountriesSlugsToIds = (countries: string | string[], state: State.Root): string[] | undefined => {
  if (!countries) return;
  const countriesSlugs: string[] = Array.isArray(countries) ? countries : [countries];
  // Call countriesSlugIdMapSelector only if countriesSlug exist
  const countriesSlugIdMap: { [key: string]: string } = countriesSlugIdMapSelector(state);
  // Get countries ids array from slugs if exist
  return countriesSlugs.map(slug => countriesSlugIdMap[slug]).filter(Boolean);
};

export const convertStatesSlugsToIds = (states: string | string[], state: State.Root): string[] | undefined => {
  if (!states) return;
  const statesSlugs: string[] = Array.isArray(states) ? states : [states];
  // Call statesSlugIdMapSelector only if countriesSlug exist
  const statesSlugIdMap: { [key: string]: string } = statesSlugIdMapSelector(state);
  // Get states ids array from slugs if exist
  return statesSlugs.map(slug => statesSlugIdMap[slug]).filter(Boolean);
};

/*
 * slug id hash map creator
 */
export const slugIdMapCreator = (locations: any[] | null): { [key: string]: string } => {
  if (!locations) return emptyObject;
  return locations.reduce((acc: any, location: State.Location | State.Country) => {
    acc[location.slug] = location.id;
    return acc;
  }, {});
};
