import { CheckoutCountriesListHash } from 'mxp-utils';
import { Salesforce } from 'mxp-schemas';

export const newAddressKey = 'NEW';

// ------------------------------------
// Constants
// ------------------------------------
export const initialState: State.Checkout = {
  loading: false,
  success: false,
  accountId: null,
  legalEntity: null,
  hasDefaultPaymentMethod: false,
  shippingAddress: {
    firstName: '',
    lastName: '',
    company: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: CheckoutCountriesListHash.USA.ISOAlpha3Code,
    isPrimary: true,
    addressType: Salesforce.HomeAddressType.OFFICE_MAILING,
  },
  isShippingAddressValid: null,
  isSecondaryAddressNeeded: null,
  isSecondaryBillingAddressNeeded: null,
  isShippingEmbargoed: null,
  isBillingEmbargoed: null,
  smartystreetsValidation: {
    validationNeeded: false,
    billingValidationNeeded: false,
    shippingAddress: {
      valid: false,
      suggestions: [],
      reason: '',
    },
    billingAddress: {
      valid: false,
      suggestions: [],
      reason: '',
    },

    smartystreetsValidationFetched: false,
  },
  billingAddress: {
    firstName: '',
    lastName: '',
    company: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: CheckoutCountriesListHash.USA.ISOAlpha3Code,
    isPrimary: false,
    addressType: Salesforce.HomeAddressType.OFFICE_MAILING,
  },
  defaultBillingAddress: {
    firstName: '',
    lastName: '',
    company: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: CheckoutCountriesListHash.USA.ISOAlpha3Code,
  },
  sameShippingAddressForBilling: false,
  sameShippingAddressForPayment: true,
  zuoraAccountFetched: false,
  addressAutocompleteItems: [],
  creditCards: [],
  paymentMethods: {
    association: {
      zuoraId: '',
      defaultPaymentMethodId: '',
      paymentGateway: '',
      creditCards: [],
      paypal: [],
      ach: [],
      bankTransfer: [],
    },
    cima: {
      zuoraId: '',
      paymentGateway: '',
      creditCards: [],
      paypal: [],
      ach: [],
      bankTransfer: [],
    },
  },
  selectedPaymentMethodEntity: '',
  selectedCreditCardId: null,
  savedShippingAddressChecked: false,
  savedShippingAddressEdited: false,
  savedShippingAddress: null,
  savedBillingAddressChecked: false,
  savedBillingAddress: null,
  isLoadingAutocompleteItems: false,
  saveCreditCardForFuture: true,
  taxAmount: null,
  conferenceUserInfo: null,
  pageStep: '',
  savedCardUsed: null,
  creditHold: null,
  receipt: {
    payReceipt: {
      receipt: null,
      loading: false,
      error: null,
    },
    paymentResult: {
      loading: false,
      error: null,
      receiptPaymentResult: null,
    },
  },
  paymentVerifyResult: null,
  paymentTechCodeError: '',
  isBillingAddressValid: null,
  savedBillingAddressEdited: false,
  sendSuccessPreferredPaymentMethodEmail: false,
  paymentsId: [],
  proratedPrice: [],
  currency: '',
  mandate: {
    directDebit: '',
    ach_checkout: '',
    ach_wallet: '',
  },
  addPaypalSuccess: null,
};
