import { Responsive } from 'semantic-ui-react';
import { generatePath } from 'react-router';
import { startCase } from 'mxp-utils/dist/lib/utils';
import { Product, Content, MembershipTypes, Salesforce } from 'mxp-schemas';
import {
  ContentTypesURL,
  Routes,
  FirmRosterNavigationList,
  CimaFirmBillingNavigationList,
  CimaFirmRosterNavigationList,
  CimaFlpFirmRosterNavigationList,
  CimaFlpFirmBillingNavigationList,
  AicpaFlpFirmRosterNavigationList,
  AicpaFlpFirmBillingNavigationList,
  FirmBillingNavigationList,
} from 'constants/index';
import { productTypeToSlug, donationTypeToSlug } from 'utils';
import { parseUrl, stringifyUrl } from 'query-string';
import UAParser from 'ua-parser-js';
import { ContentfulHelpers } from 'mxp-utils';
import { getPath } from './routes';

export const isServer: boolean = typeof window === 'undefined';

export const isTest: boolean = process.env.NODE_ENV === 'test';

export const isValidEmail = (emailToValidate: string): boolean => {
  const emailRegexTest =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegexTest.test(emailToValidate);
};

/**
 * Is Mobile or Tablet check
 */
export const isMobileViewPort = (): boolean => {
  if (isServer) return false;
  const viewPortWidth: number = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  return Boolean(viewPortWidth && viewPortWidth <= Number(Responsive.onlyTablet.maxWidth!));
};

/**
 * Get Viewport Height
 */
export const getViewportHeight = (): number | null => {
  if (isServer) return null;
  return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
};

export const Scroller = {
  scrollToTop: (): void => {
    if (isServer) return;
    window.scrollTo(0, 0);
  },

  /**
   * Expects react ref as parameter
   */
  scrollToTarget: (target: any, params?: { useWindow: boolean }): void => {
    if (isServer || !target || !target.current) return;
    if (params && params.useWindow) window.scrollTo(0, target.current.offsetTop);

    target.current.scrollTop = 0;
  },

  scrollIntoElement: (id: string): void => {
    if (isServer) return;
    const el = document.getElementById(id.replace('#', ''));
    const isMobile = isMobileViewPort();
    if (el) {
      if (isMobile) {
        const headerOffset = -90;
        const scrollTo = el.getBoundingClientRect().top + window.pageYOffset + headerOffset;
        window.scrollTo({ top: scrollTo, behavior: 'smooth' });
      } else {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
};

export const getPromotedProducts = (level2: any[]) => {
  const promotedProducts = level2.filter((items: any) => {
    return items.contentType === Content.ListsType.PROMOTED_PRODUCTS;
  });

  return promotedProducts;
};

export const getFullContentUrl = (contentItem: State.ContentCardItem) => {
  return contentItem.externalUrl || `${window.location.origin}${getContentUrl(contentItem)}`;
};

export const getPromotedUrl = (item: any) => {
  const path = `${window.location.origin}/${Content.CategorySlugs.CPE_LEARNING}/${productTypeToSlug(
    item.productType as Product.ProductType
  )}/${item.slug}`;
  return path;
};

export const trimWithEllipsis = (text: string, maxLength: number, ellipsis = String.fromCharCode(8230)) => {
  return text.length > maxLength ? `${text.substring(0, maxLength - ellipsis.length)}${ellipsis}` : text;
};

export const getContentUrl = (contentItem: Partial<State.ContentCardItem>) => {
  const { contentCategory, contentfulType, slug, contentType } = contentItem;
  const isCPELearning = contentCategory?.slug === Content.CategorySlugs.CPE_LEARNING;
  const isDonations = contentCategory?.slug === Content.CategorySlugs.DONATIONS;
  const useContentPrefix = (isCPELearning || isDonations) && !contentItem.productType?.length;

  if (contentItem.externalUrl) {
    return contentItem.externalUrl;
  }
  if (contentItem.url) {
    return contentItem.url;
  }
  if (!contentCategory?.slug) {
    return `/${slug}`;
  }
  if (!contentfulType) {
    const contentCategorySlug = contentCategory?.slug || contentCategory;
    const contentTypeSlug = contentType?.slug || contentType;
    return `/${contentCategorySlug}${contentTypeSlug ? `/${contentTypeSlug}` : ''}/${slug}`;
  }
  if (contentfulType === ContentfulHelpers.CONTENT_TYPES.STATIC_LANDING_PAGE) {
    return `/${contentCategory.slug}/landing/${slug}`;
  }
  if (contentfulType === ContentfulHelpers.CONTENT_TYPES.ARTICLE) {
    return `/${contentCategory.slug}/${contentfulType}/${slug}`;
  }

  if (isCPELearning && !useContentPrefix) {
    const parsedContentType = productTypeToSlug(contentItem.productType?.[0] as Product.ProductType);
    return `/${Content.CategorySlugs.CPE_LEARNING}/${parsedContentType}/${slug}`;
  }

  if (isDonations && !useContentPrefix) {
    const parsedContentType = donationTypeToSlug(contentItem.productType?.[0] as Product.ProductType);
    return `/${Content.CategorySlugs.DONATIONS}/${parsedContentType}/${slug}`;
  }

  return ContentTypesURL[contentfulType] && ContentTypesURL[contentfulType] !== contentCategory.slug
    ? `${useContentPrefix ? '/content' : ''}/${contentCategory.slug}/${ContentTypesURL[contentfulType]}/${slug}`
    : `/${contentCategory.slug}/${slug}`;
};

/**
 * Get object without all keys except particular list
 */
export const pickProps = (object: any, propNames: string[]): object => {
  return propNames.reduce((acc: any, key: string) => {
    acc[key] = object[key];
    return acc;
  }, {});
};

/**
 * Get object without particular keys
 */
export const omitProps = (object: any, propNames: string[]): object => {
  return Object.keys(object).reduce((acc: any, key: string) => {
    if (propNames.includes(key)) return acc;
    acc[key] = object[key];
    return acc;
  }, {});
};

/**
 * filterNameNormalizer
 * @param name
 * Used to normalize filter names in search page
 *
 */
export const filterNameNormalizer = (name: string): string => {
  switch (name) {
    case 'downloadTemplate':
      return 'Download';
    case 'hasCertificateProgram':
      return 'Certificate Program';
    case 'hasBadge':
      return 'Digital Badge';
    case 'hasWebcastPass':
      return 'Webcast Pass';
    case 'onlineExam':
      return 'Online + Exam';
    case 'e-book':
      return 'E-book';
    case 'subscription':
      return 'Online Subscription';
    case 'on-demand':
      return 'On-demand';
  }
  return startCase(name.replace(/-(?:conference)?/gi, ' ').trim());
};

/**
 * getPromotionsParams
 * @param item
 * @param userRolesStr
 * Used to get necessary params for Promotional Component
 *
 */
export const getPromotionsParams = (
  item: State.PromotionalComponent,
  userRolesStr?: string
): State.PromotionalComponentParams => {
  const userHasRole: boolean = userRolesStr
    ? item.membership && item.membership.some((role: string) => userRolesStr.includes(role))
    : false;
  const header: string = userHasRole ? item.headerMembership : item.header;
  const summary: string = userHasRole ? item.summaryMembership : item.summary;
  const to: string = userHasRole ? item.ctaUrlMembership : item.ctaUrl;
  const label: string = userHasRole ? item.ctaTextMembership : item.ctaText;
  const hasButton: boolean = Boolean(label && to);
  return { header, summary, to, label, hasButton };
};

/**
 * getVariantsFormatLabel
 * @param productItem
 * Used to get variants format label
 *
 */
export const getVariantsFormatLabel = (productItem: Product.ProductItem): string => {
  const formatLabels = (productItem?.variants || []).reduce((acc: string[], variant: Product.Variant) => {
    if (variant.format && acc.indexOf(variant.format.label) === -1) {
      acc.push(variant.format.label);
    }
    return acc;
  }, []);
  return formatLabels.toString().replace(/,/g, ', ') || '';
};

/**
 * @param url
 * @param options.withoutProtocol
 * @param options.withoutQuery
 * @param options.withoutHash
 *
 */
export const stripUrlParts = (
  url: string | undefined,
  options?: { withoutProtocol?: boolean; withoutPath?: boolean; withoutQuery?: boolean; withoutHash?: boolean }
): string => {
  if (!Boolean(url)) return '';

  const parsedUrl: any = parseUrl(url, { parseFragmentIdentifier: !options?.withoutHash });

  if (options?.withoutProtocol) {
    parsedUrl.url = parsedUrl.url.replace(/(^\w+:|^)\/\//, '');
  }

  if (options?.withoutQuery) {
    parsedUrl.query = {};
  }

  return stringifyUrl(parsedUrl);
};

export const isSSOAuthSupported = () => {
  if (isServer) {
    return false;
  }
  const parser = new UAParser();
  const parserResults = parser.getResult();
  return !(parserResults.browser?.name === 'Safari' || parserResults.os?.name === 'iOS');
};

export const isUserOsMacos = () => {
  if (isServer) {
    return false;
  }
  const parser = new UAParser();
  const parserResults = parser.getResult();
  return parserResults?.os?.name === 'Mac OS';
};

export const getPremiumContentToLink = (category: string) => {
  return generatePath(getPath(Routes.PREMIUM_CONTENT_AGGS_PAGE), {
    topicSlug: category || undefined,
    categorySlug: 'all',
  });
};
export const isExternal = (item: string | undefined) => {
  if (!item) return false;
  return (
    Boolean(new RegExp(/^http(s)?:\/\//).test(item)) ||
    Boolean(new RegExp(/^mailto:/).test(item) || Boolean(new RegExp(/^tel:/).test(item))) ||
    Boolean(new RegExp(/^www/).test(item)) ||
    Boolean(new RegExp(/^ftp(s)?:\/\//).test(item))
  );
};

export const capitalized = (s: string | undefined) => {
  if (!s) return '';
  return String(s).charAt(0).toUpperCase() + String(s).slice(1);
};

export const getModuleNameFromPath = (path: string) => {
  const module = path.split('/')[2];
  switch (module) {
    case 'employment':
      return MembershipTypes.PERRecordType.EMPLOYMENT;
    case 'core-activities':
      return MembershipTypes.PERRecordType.CORE_ACTIVITY;
    case 'skills-behaviors':
      return MembershipTypes.PERRecordType.SKILLS_AND_BEHAVIOR;
    default:
      return null;
  }
};

export const docReady = (fn: any) => {
  // see if DOM is already available
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

export const getFirmNaming = (isCima?: boolean) => (isCima ? 'Organization' : 'Firm');

interface NavObj {
  label: string;
  key: Routes;
}

export const getNavKeys = (id: string, navList: NavObj[]) => {
  if (!id) return [];
  return navList.map(item => ({
    label: item.label,
    key: generatePath(getPath(item.key), { orgId: id || '/' }),
  }));
};

export const getNavList = (
  id: string,
  useFLPFeatures: boolean,
  useCimaFeatures: boolean,
  selectedOrganization?: Salesforce.Organization | null
) => {
  const isCimaFLP = useFLPFeatures && useCimaFeatures;
  const isAicpaFLP = useFLPFeatures && !useCimaFeatures;
  const isCimaNotFlp = !useFLPFeatures && useCimaFeatures;

  interface NavList {
    rosterNavList: NavObj[];
    billingNavList: NavObj[];
  }

  const removeNavigation = (navToRetain: string[], navListToFilter: NavList): NavObj[] => {
    const newBillingNavlist = navListToFilter.billingNavList.filter(nav => {
      return navToRetain.includes(nav.label);
    });
    return newBillingNavlist;
  };

  const navList: NavList = {
    rosterNavList: FirmRosterNavigationList,
    billingNavList: FirmBillingNavigationList,
  };

  // remove Manage Firm Billing' and 'Firm Billing Support' if aicpa firm billing type is 'FBE non renewing'
  if (selectedOrganization?.aicpaFirmBillingType === Salesforce.FirmBilling.FBE_NON_RENEWING) {
    navList.billingNavList = removeNavigation(['Memberships and add-ons', 'View and pay invoices'], navList);
  }
  if (!id) return { rosterNavList: [], billingNavList: [] };

  if (isCimaNotFlp) {
    navList.rosterNavList = CimaFirmRosterNavigationList;
    navList.billingNavList = CimaFirmBillingNavigationList;
    if (selectedOrganization?.cimaFirmBillingType === Salesforce.FirmBilling.FBE_NON_RENEWING) {
      navList.billingNavList = removeNavigation(['Memberships and add-ons', 'View and pay invoices'], navList);
    }
  }

  if (isAicpaFLP) {
    navList.rosterNavList = AicpaFlpFirmRosterNavigationList;
    navList.billingNavList = AicpaFlpFirmBillingNavigationList;
  }

  if (isCimaFLP) {
    navList.rosterNavList = CimaFlpFirmRosterNavigationList;
    navList.billingNavList = CimaFlpFirmBillingNavigationList;
  }

  return {
    rosterNavList: getNavKeys(id, navList.rosterNavList),
    billingNavList: getNavKeys(id, navList.billingNavList),
  };
};

export const executeIfConditionIsTrue = (condition: boolean, callbackFn: () => void) => {
  if (condition) {
    callbackFn();
  }
};

export const replaceHttpWithHttps = (inputString: string) => {
  // Use a regular expression with the 'g' flag to replace all occurrences of http: with https:
  return inputString.replace(/http:/g, 'https:');
};

export const replaceSpecificUrls = (url: string) => {
  // Use regular expressions to replace specific URLs
  return url
    .replace(/https:\/\/www\.aicpa-cima\.com(?![\w/])/g, 'https://www.aicpa-cima.com/home')
    .replace(/https:\/\/www\.aicpa-cima\.com\/esg/g, 'https://www.aicpa-cima.com/topic/sustainability-esg')
    .replace(
      /https:\/\/www\.aicpa-cima\.com\/future/g,
      'https://www.aicpa-cima.com/resources/landing/future-of-finance-2-0'
    );
};

export const getFirmHeaderTitle = (
  selectedOrganization: any,
  isFirmNavigationPage: boolean,
  isFirmInfoPages: boolean,
  isFirmBillingPages: boolean,
  isFirmRosterPages: boolean,
  useCimaFeatures: boolean
) => {
  const firmNaming = getFirmNaming(useCimaFeatures);
  if (selectedOrganization && isFirmNavigationPage) {
    return selectedOrganization.name;
  }
  if (isFirmInfoPages) {
    return `${firmNaming} Information`;
  }
  if (isFirmBillingPages) {
    return `${firmNaming} Billing `;
  }
  if (isFirmRosterPages) {
    return `${firmNaming} Roster `;
  }
  return 'Firm Selection';
};

export const getFirmHeaderButtonUrl = (
  isFirmBillingPages: boolean,
  isFirmRosterPages: boolean,
  firmRosterList: any,
  firmBillingList: any
) => {
  if (isFirmBillingPages && firmRosterList.length) {
    return firmRosterList[0].key;
  }
  if (isFirmRosterPages && firmBillingList.length) {
    return firmBillingList[0].key;
  }
  return getPath(Routes.MY_PROFILE_ROOT);
};

export const addTableSeparator = (inputString: string) => {
  // React Markdown Table Header
  if (inputString?.includes('| |')) {
    const lines = inputString.split('\n');

    // Find the line containing `---------- |`
    const separatorLineIndex = lines.findIndex(line => line.includes('---------- |'));

    // If the separator line is found, add '----------' after it
    if (separatorLineIndex !== -1) {
      lines[separatorLineIndex] += '---------- ';
    }

    // Join the lines back into a single string
    const resultString = lines.join('\n');
    return resultString;
  }

  return inputString;
};

export * from './ArrayHelpers';
export * from './emptyItems';
export * from './MomentHelpers';
export * from './routes';
export * from './sessionStorage';
export * from './StringHelpers';
export * from './ValidationHelper';
export * from './fakeState';
export * from './priceHelpers';
export * from 'mxp-utils/dist/lib/utils';
export * from './productItemHelpers';
export * from './discountHelpers';
export * from './errors';
export * from './masterConferenceObjBuilder';
export * from './getModalData';
export * from './examResultHelpers';
export * from './getBooleanValue';
export * from './membershipTierTypesAddOnsMapping';
export * from './membershipRenewalHelpers';
