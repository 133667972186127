import React from 'react';
import { Item } from './Item';
import { Cart } from 'mxp-schemas';
import styled from 'styled-components';

export const ItemsInfo: React.FC<{
  itemsPriceInfo: Cart.CartItemPriceInfo[];
  removeCartItems?: (lineItemId: string[]) => any;
  cartLineItems?: Cart.LineItem[];
  isImpersonated?: boolean;
  isCenterMembershipJourney?: boolean;
  isPaymentStep?: boolean;
  useNewMembershipAICPA?: boolean;
}> = ({
  itemsPriceInfo,
  removeCartItems,
  cartLineItems,
  isImpersonated,
  isCenterMembershipJourney = false,
  isPaymentStep = false,
  useNewMembershipAICPA = false,
}) => {
  // const [donations, setDonations] = useState<Cart.CartItemPriceInfo>();
  return (
    <>
      <StyledDetails>
        <MembershipDetails>Membership Details</MembershipDetails>
        {itemsPriceInfo.map((itemPriceInfo, index) => {
          return itemPriceInfo.productName !== 'Donations' ? (
            <Item
              {...itemPriceInfo}
              key={itemPriceInfo.lineItemId}
              isLast={itemsPriceInfo.length === index + 2}
              removeCartItems={removeCartItems}
              cartLineItems={cartLineItems}
              isImpersonated={isImpersonated}
              isCenterMembershipJourney={isCenterMembershipJourney}
              isPaymentStep={isPaymentStep}
              useNewMembershipAICPA={useNewMembershipAICPA}
            />
          ) : null;
        })}
      </StyledDetails>
      <StyledDonations>
        {itemsPriceInfo.map((itemPriceInfo, index) => {
          return itemPriceInfo.productName === 'Donations' ? (
            <Item
              {...itemPriceInfo}
              key={itemPriceInfo.lineItemId}
              isLast={itemsPriceInfo.length === index + 1}
              removeCartItems={removeCartItems}
              cartLineItems={cartLineItems}
              isImpersonated={isImpersonated}
              isCenterMembershipJourney={isCenterMembershipJourney}
              isPaymentStep={isPaymentStep}
              useNewMembershipAICPA={useNewMembershipAICPA}
            />
          ) : null;
        })}
      </StyledDonations>
    </>
  );
};

const MembershipDetails = styled.p`
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledDetails = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey1};
  padding: 16px 8px 16px 8px;
`;

const StyledDonations = styled.div`
  margin-top: 16px;
`;
