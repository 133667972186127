import React from 'react';
import { useSelector } from 'react-redux';
import { getUniquestAllowedUrl } from 'modules/startup/selectors';
import { isServer } from 'utils';

export const useEngagementMenu = () => {
  const Sites = useSelector(getUniquestAllowedUrl);

  let validPath;
  if (Sites !== undefined && Sites !== null && typeof window !== 'undefined') {
    validPath = !Sites?.Sites?.includes((window as any).location?.pathname);
  }

  const removeElement = React.useCallback(() => {
    const styleElement = document.querySelector('#uniquest-style');

    if (styleElement && styleElement.parentNode) styleElement.parentNode.removeChild(styleElement);
  }, []);

  React.useEffect(() => {
    if (isServer || !Sites) {
      return;
    }
    // create and append script
    const body = document.querySelector('body');
    if (!body) return;
    const element = document.querySelector('#embedded-service');
    if (!element) {
      const uniquestEm = document.createElement('script');
      uniquestEm.setAttribute('type', 'text/javascript');
      uniquestEm.setAttribute('defer', 'true');
      uniquestEm.setAttribute('id', 'uniquest-em');
      uniquestEm.setAttribute('src', 'https://uniquest-cima.my.salesforce.com/embeddedservice/menu/fab.min.js');

      uniquestEm.onload = () => {
        if (typeof (window as any)?.initESW === 'function') {
          (window as any)?.initESW('https://service.force.com');
        }
      };
      document.body.appendChild(uniquestEm);
    }

    const style = document.createElement('style');
    style.setAttribute('id', 'uniquest-style');
    // Modify style in uniquest Will not display on pages that is not included on the list.
    if (Sites !== undefined && Sites !== null) {
      if (!Sites?.Sites?.includes((window as any).location?.pathname)) {
        style.innerHTML = `
        .embedded-service {
          display:none !important
        }

      `;
        document.body.appendChild(style);
        return removeElement;
      }
    }

    style.innerHTML = `
      .fab {
        right: 30px !important
    
      }

      #esw-fab {
        z-index: 10000;
      }

      .esw-custom_icon {
        width: 32px;
        height: 33px;   
      }

      #esw-icon-3mi4K000000bxir,
      #esw-icon-3mi4K000000bxif, 
      #esw-icon-3mi4K000000bxic { 
        width: 32px; 
        height: 32px; 
      }

      .channelmenu {
        right: 30px !important
      }


    `;

    document.body.appendChild(style);
    // cleanup script on hook unmount
    return removeElement;
  }, [removeElement, validPath, Sites]);
};
