import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'components/atoms';
import { InputWithValidation } from 'components/molecules';
import { CountriesListWithDialCode, Utils } from 'mxp-utils';

interface Props {
  phoneObject: PhoneObject;
  setPhoneObject: ({ countryCode, dialCode, phoneNumber }: PhoneObject) => void;
  errorMessage: string | null;
  label?: string;
  name?: string;
  testId?: string;
  labelId?: string;
  isCorrect?: boolean;
  isValidated?: boolean;
}

interface PhoneObject {
  countryCode: string;
  phoneNumber: string;
  dialCode: string;
}

interface TriggerText {
  countryCode: string;
  dialCode: string;
}

export const MobileInput: React.FC<Props> = ({
  phoneObject,
  setPhoneObject,
  errorMessage,
  label,
  name,
  testId,
  labelId,
  isCorrect,
  isValidated,
  ...props
}) => {
  const countriesOptions = useMemo(
    () =>
      Object.values(CountriesListWithDialCode).map((country: any) => ({
        key: country.code,
        value: country.code,
        text: `${country.name} (${country.dialCode})`,
        value2: country.dialCode,
      })),
    []
  );

  const [triggerText, setTriggerText] = useState<TriggerText>({
    countryCode: phoneObject.countryCode,
    dialCode: phoneObject.dialCode,
  });
  const [initialValidation, setInitialValidation] = useState(true);

  const isPhoneEmpty = !Boolean(phoneObject.phoneNumber?.length);
  const isCountryCodeEmpty = !Boolean(phoneObject.countryCode?.length);
  const isPrimaryPhoneValid = Utils.isNumericString(phoneObject?.phoneNumber.toString());

  const handleCountryCodeChange = useCallback(
    (event: any, data: any) => {
      const { value } = data;
      const { key, value2 } = data?.options?.find((o: { value: string }) => o.value === value);

      // To differentiate Canada from US by adding asterisk (CBUILD-16318)
      const formatDialCode = key === 'CA' ? `${value2}*` : value2;

      setTriggerText({
        countryCode: key,
        dialCode: formatDialCode,
      });

      setPhoneObject({ ...phoneObject, countryCode: key, dialCode: formatDialCode });
    },

    [phoneObject, setPhoneObject]
  );

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhoneObject({ ...phoneObject, phoneNumber: value });
  };

  const trigger = (
    <>
      <div style={{ display: 'flex', alignContent: 'space-between' }}>
        <span style={{ marginLeft: '1rem' }}>{triggerText.countryCode}</span> &nbsp;{' '}
        <span style={{ color: 'GrayText', marginRight: '1rem' }}>{triggerText.dialCode.replace('*', '')}</span>
      </div>
    </>
  );

  React.useEffect(() => {
    setTriggerText({
      countryCode: phoneObject.countryCode,
      dialCode: phoneObject.dialCode,
    });

    if (phoneObject.phoneNumber !== '') {
      setInitialValidation(false);
    }
  }, [phoneObject]);

  return (
    <div>
      <Label>{label ?? 'Mobile number (optional)'}</Label>
      <DropDownContainer>
        <StyledDropdown
          name="countryCode"
          value={phoneObject.countryCode}
          scrolling
          data-testid="country-code"
          options={countriesOptions}
          placeholder="Select country code"
          onChange={handleCountryCodeChange}
          trigger={trigger}
          {...props}
        />
        <StyledInputContainer>
          <StyledInput
            labelName={''}
            name={name || 'phoneNumber'}
            placeholder="Enter phone number"
            type="text"
            value={phoneObject.phoneNumber}
            testId={testId || 'phoneNumber'}
            labelId={labelId || 'phoneNumber'}
            onChange={handlePhoneNumberChange}
            isCorrect={
              isValidated ??
              (isCorrect ||
                (!isPhoneEmpty && !isCountryCodeEmpty && isPrimaryPhoneValid) ||
                (!isCountryCodeEmpty && isPhoneEmpty) ||
                initialValidation)
            }
            errorMessage={errorMessage}
            isCorrectIconShown
            {...props}
          />
        </StyledInputContainer>
      </DropDownContainer>
    </div>
  );
};

const DropDownContainer = styled.div`
  &&&& {
    display: flex;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: calc(100% - ${props => props.theme.pxToRem(10)}) !important;
  }
`;

const StyledInput = styled(InputWithValidation)`
  &&& {
    width: ${props => props.theme.pxToRem(260)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: calc(100% - ${props => props.theme.pxToRem(5)});
    }

    input {
      border-radius: 0 5px 5px 0;
      height: ${props => props.theme.pxToRem(43.5)} !important;
    }
    div:nth-child(3) {
      flex: 1 100%;
    }
  }
`;

const Label = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledDropdown = styled(Dropdown)`
  &&&& {
    ${props => `
    &&& {
      height: ${props.theme.pxToRem(43.5)};
      width: 100px;
      border-radius: 5px 0 0 5px;
      padding-right: ${props.theme.pxToRem(10)};
      color: ${props.theme.colors.neutralBlack};
      border: solid ${props.theme.pxToRem(1)} ${props.theme.colors.neutralGrey4};
      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(5)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }
      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.neutralBlack};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }
      &&&.ui.dropdown > .menu {
        max-height: ${props.theme.pxToRem(250)};
        width: ${props.theme.pxToRem(360)} !important;
      }
      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: 1px solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: 1px solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.neutralBlack};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }
      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: 1px solid blue;
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
    }
  `}
  }
`;
