import React from 'react';
import styled from 'styled-components';
import { ButtonLink, ButtonEnums, Heading } from 'components/atoms';
import { ContentCardsCarousel } from 'components/organisms/ContentCardsCarousel';
import { Contentful } from 'mxp-schemas';
import { RichTextBlocks } from 'mxp-utils';
import { isColorDark } from 'utils/colorHelpers';
import {
  DefaultInlineLinkStyles,
  ExternalLinkStyles,
  ExternalLinkStylesDark,
  InlineLinkOnDarkBgStyles,
} from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
  isFullPagePagination: boolean;
  isUserOnBoarded: boolean;
}

export const BlockRecommendedProducts: React.FC<Props> = ({
  modularBlock,
  headingType,
  isFullPagePagination,
  isUserOnBoarded,
}) => {
  const block = modularBlock as Contentful.ModularContent.RecommendationBlock;
  const isBgColorDark: boolean = isColorDark(block?.bgColor);

  return (
    <>
      <StyledHeaderContainer isBgColorDark={isBgColorDark}>
        {/* On boarded user */}
        {isUserOnBoarded && (
          <>
            {block.headerOnboarded && (
              <StyledHeading
                as={headingType}
                dangerouslySetInnerHTML={{ __html: block.headerOnboarded }}
                isBgColorDark={isBgColorDark}
                tabIndex={0}
              />
            )}
            {block.descriptionOnboarded && (
              <StyledParagraph
                dangerouslySetInnerHTML={{ __html: block.descriptionOnboarded }}
                isBgColorDark={isBgColorDark}
                tabIndex={0}
              />
            )}
          </>
        )}

        {/* Non On boarded user */}
        {!isUserOnBoarded && (
          <>
            {block.headerNonOnboarded && (
              <StyledHeading
                as={headingType}
                dangerouslySetInnerHTML={{ __html: block.headerNonOnboarded }}
                isBgColorDark={isBgColorDark}
                tabIndex={0}
              />
            )}
            {block.descriptionNonOnboarded && (
              <StyledParagraph
                dangerouslySetInnerHTML={{ __html: block.descriptionNonOnboarded }}
                isBgColorDark={isBgColorDark}
                tabIndex={0}
              />
            )}
          </>
        )}
      </StyledHeaderContainer>

      <StyledContentCardsCarousel
        content={block?.products as any}
        itemTestId="recommended-block-content-card"
        data-full-page-pagination={isFullPagePagination}
      />

      {/* On boarded user */}
      {isUserOnBoarded && block.ctaTextOnboarded && block.ctaUrlOnboarded && (
        <StyledButtonWrapper>
          <ButtonLink
            testId="logged-out-home-benefit-block"
            to={block.ctaUrlOnboarded}
            variant={isBgColorDark ? ButtonEnums.variants.secondaryNegative : ButtonEnums.variants.primary}
            size={ButtonEnums.sizes.medium}
            external={RichTextBlocks.isExternal(block.ctaUrlOnboarded)}
          >
            {block.ctaTextOnboarded}
          </ButtonLink>
        </StyledButtonWrapper>
      )}

      {/* Non On boarded user */}
      {!isUserOnBoarded && block.ctaTextNonOnboarded && block.ctaUrlNonOnboarded && (
        <StyledButtonWrapper>
          <ButtonLink
            testId="logged-out-home-benefit-block"
            to={block.ctaUrlNonOnboarded}
            variant={isBgColorDark ? ButtonEnums.variants.secondaryNegative : ButtonEnums.variants.primary}
            size={ButtonEnums.sizes.medium}
            external={RichTextBlocks.isExternal(block.ctaUrlNonOnboarded)}
          >
            {block.ctaTextNonOnboarded}
          </ButtonLink>
        </StyledButtonWrapper>
      )}
    </>
  );
};

const StyledHeaderContainer = styled.div<{ isBgColorDark?: boolean }>`
  margin: 0 auto;
  color: ${props => (props?.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
  .rich-text-external-link {
    ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${props => (props.isBgColorDark ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
    svg path {
      fill: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey5)};
    }
  }

  .rich-text-internal-link {
    ${DefaultInlineLinkStyles};
  }
`;

const StyledHeading = styled(Heading)<{ isFullPagePagination?: boolean; isBgColorDark: boolean }>`
  margin-bottom: ${props => (props?.isFullPagePagination ? props.theme.pxToRem(24) : props.theme.pxToRem(16))};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;
  p {
    margin: 0;
    line-height: 1.38;
  }
  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.l};
    p {
      line-height: 1.33;
    }
  }
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;
  p {
    margin: 0;
    line-height: 1.33;
  }
  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};
    p {
      line-height: 1.5;
    }
  }
`;

const StyledContentCardsCarousel = styled(ContentCardsCarousel)`
  margin-top: ${props => props.theme.pxToRem(50)};
  margin-bottom: ${props => props.theme.pxToRem(50)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledButtonWrapper = styled.div`
  text-align: center;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;
