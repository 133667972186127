import { createSelector } from 'reselect';
import { Content } from 'mxp-schemas';
import { match as MatchProps } from 'react-router';
import { matchPath } from 'react-router-dom';
import { createMatchSelector, getLocation } from 'connected-react-router';
import {
  topicsHashSelector,
  topicsSlugIdMapSelector,
  subtopicsHashSelector,
  subtopicsSlugIdMapSelector,
} from 'modules/topics/selectors';
import { industriesHashSelector, industrySlugIdMapSelector } from 'modules/industries/selectors';
import { trendsHashSelector, trendSlugIdMapSelector } from 'modules/trends/selectors';
import {
  skillsHashSelector,
  skillsSlugIdMapSelector,
  subskillsHashSelector,
  subskillsSlugIdMapSelector,
} from 'modules/skills/selectors';
import { categoriesHashSelector } from 'modules/categories/selectors';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';
import { constantsSelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { staticLandingPageSelector } from 'modules/staticLandingPagesContent/selectors';

interface PageTaxonomyAggsParams {
  topicSlug: string;
  subtopicSlug: string;
  industrySlug: string;
  trendSlug: string;
  skillSlug: string;
  subskillSlug: string;
  locationSlug: string;
  languageSlug: string;
  careerStageSlug: string;
  jobRoleSlug: string;
  countrySlug: string;
  stateSlug: string;
}

interface PageCategoryParams extends PageTaxonomyAggsParams {
  categorySlug: string;
}
interface PageProductAggsParams extends PageTaxonomyAggsParams {
  productTypeSlug: string;
}

interface PageSearchParams {
  searchQuery: string;
}

interface AdminOrganizationParams {
  accountId: string;
}

const pageTopicAggsMatchSelector: any = createMatchSelector(getPath(Routes.TOPIC_AGGS_PAGE));
const pageIndustryAggsMatchSelector: any = createMatchSelector(getPath(Routes.INDUSTRY_AGGS_PAGE));
const pageTrendAggsMatchSelector: any = createMatchSelector(getPath(Routes.TREND_AGGS_PAGE));
const pageSkillAggsMatchSelector: any = createMatchSelector(getPath(Routes.SKILL_AGGS_PAGE));
const pageCategoryMatchSelector: any = createMatchSelector(getPath(Routes.CATEGORY_AGGS_PAGE));
const pagePremiumContentMatchSelector: any = createMatchSelector(getPath(Routes.PREMIUM_CONTENT_AGGS_PAGE));
const pageProductAggsByTopicMatchSelector: any = createMatchSelector(getPath(Routes.PRODUCT_AGGS_BY_TOPIC_PAGE));
const pageAdminOrganizationSelector: any = createMatchSelector(getPath(Routes.ADMIN_ORGANIZATION));

const pageSearchMatchSelector: any = createMatchSelector(getPath(Routes.SEARCH));

export const isPageSearchSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: getPath(Routes.SEARCH), exact: true }))
);

export const isPageFeedSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: getPath(Routes.FEED), exact: true }))
);

export const isPageCategorySelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: getPath(Routes.CATEGORY_AGGS_PAGE), exact: true }))
);

export const isPremiumContentPageSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: getPath(Routes.PREMIUM_CONTENT_AGGS_PAGE), exact: true }))
);

export const isPageStorefrontSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: getPath(Routes.STOREFRONT_PAGE), exact: true }))
);

export const isPageHomeSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(
    matchPath(location.pathname, { path: getPath(Routes.FEED), exact: true }) ||
      matchPath(location.pathname, { path: getPath(Routes.HOME) })
  )
);

export const isPageAdminInvoiceFilterSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: getPath(Routes.ADMIN_INVOICES_FILTER), exact: true }))
);

export const isPageBlogFeedSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: getPath(Routes.BLOG_FEED), exact: true }))
);

export const pageCategoryTopicSlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.topicSlug || ''
);

export const pageCategorySubtopicSlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.subtopicSlug || ''
);

export const pageCategoryIndustrySlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.industrySlug || ''
);

export const pageCategoryTrendSlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.trendSlug || ''
);

export const pageCategorySkillSlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.skillSlug || ''
);

export const pageCategorySubskillSlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.subskillSlug || ''
);

export const pageCategoryLocationSlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.locationSlug || ''
);

export const pageCategoryContentLanguageSlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.languageSlug || ''
);

export const pageCategoryCareerStageSlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.careerStageSlug || ''
);

export const pageCategoryJobRoleSlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.jobRoleSlug || ''
);

export const pageCategoryCountrySlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.countrySlug || ''
);

export const pageCategoryStateSlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.stateSlug || ''
);

export const pageCategoryCategorySlugSelector = createSelector(
  pageCategoryMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.categorySlug || ''
);

export const pagePremiumContentTopicSlugSelector = createSelector(
  pagePremiumContentMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.topicSlug || ''
);

export const pagePremiumContentCategorySlugSelector = createSelector(
  pagePremiumContentMatchSelector,
  (match: MatchProps<PageCategoryParams>): string => match?.params.categorySlug || ''
);

export const pageSearchSearchQuerySelector = createSelector(
  pageSearchMatchSelector,
  (match: MatchProps<PageSearchParams>): string => match?.params.searchQuery || ''
);

export const pageTopicAggsTopicSlugSelector = createSelector(
  pageTopicAggsMatchSelector,
  (match: MatchProps<PageTaxonomyAggsParams>): string => match?.params.topicSlug || ''
);

export const pageTopicAggsSubtopicSlugSelector = createSelector(
  pageTopicAggsMatchSelector,
  (match: MatchProps<PageTaxonomyAggsParams>): string => match?.params.subtopicSlug || ''
);

export const pageProductAggsTopicSlugSelector = createSelector(
  pageProductAggsByTopicMatchSelector,
  (match: MatchProps<PageProductAggsParams>): string => match?.params.topicSlug || ''
);

export const pageIndustryAggsIndustrySlugSelector = createSelector(
  pageIndustryAggsMatchSelector,
  (match: MatchProps<PageTaxonomyAggsParams>): string => match?.params.industrySlug || ''
);

export const pageTrendAggsTrendSlugSelector = createSelector(
  pageTrendAggsMatchSelector,
  (match: MatchProps<PageTaxonomyAggsParams>): string => match?.params.trendSlug || ''
);

export const pageSkillAggsSkillSlugSelector = createSelector(
  pageSkillAggsMatchSelector,
  (match: MatchProps<PageTaxonomyAggsParams>): string => match?.params.skillSlug || ''
);

export const pageSkillAggsSubskillSlugSelector = createSelector(
  pageSkillAggsMatchSelector,
  (match: MatchProps<PageTaxonomyAggsParams>): string => match?.params.subskillSlug || ''
);

export const pageAdminOrganizationAccountIdSelector = createSelector(
  pageAdminOrganizationSelector,
  (match: MatchProps<AdminOrganizationParams>): string => match?.params.accountId || ''
);

export const topicFromParamsSelectorFactory = (pageTopicSlugSelector: any) =>
  createSelector(
    [topicsHashSelector, topicsSlugIdMapSelector, pageTopicSlugSelector],
    (
      topicsHash: State.TopicsHash | null,
      topicsSlugIdMap: { [key: string]: string },
      slug: string | null
    ): State.Topic | null => {
      if (!slug || !topicsHash || !topicsSlugIdMap) return null;
      const id: string = topicsSlugIdMap[slug];
      if (!id) return null;
      const topic: State.Topic = topicsHash[id];
      return topic;
    }
  );

export const subtopicFromParamsSelectorFactory = (pageSubtopicSlugSelector: any) =>
  createSelector(
    [subtopicsHashSelector, subtopicsSlugIdMapSelector, pageSubtopicSlugSelector],
    (
      subtopicsHash: State.SubtopicsHash | null,
      subtopicsSlugIdMap: { [key: string]: string },
      slug: string | null
    ): State.Subtopic | null => {
      if (!slug || !subtopicsHash || !subtopicsSlugIdMap) return null;
      const id: string = subtopicsSlugIdMap[slug];
      if (!id) return null;
      const subtopic: State.Subtopic = subtopicsHash[id];
      return subtopic;
    }
  );

export const industryFromParamsSelectorFactory = (pageIndustrySlugSelector: any) =>
  createSelector(
    [industriesHashSelector, industrySlugIdMapSelector, pageIndustrySlugSelector],
    (
      industriesHash: State.IndustriesHash | null,
      industriesSlugIdMap: { [key: string]: string },
      slug: string | null
    ): State.Industry | null => {
      if (!slug || !industriesHash || !industriesSlugIdMap) return null;
      const id: string = industriesSlugIdMap[slug];
      if (!id) return null;
      const industry: State.Industry = industriesHash[id];
      return industry;
    }
  );

export const trendFromParamsSelectorFactory = (pageTrendSlugSelector: any) =>
  createSelector(
    [trendsHashSelector, trendSlugIdMapSelector, pageTrendSlugSelector],
    (
      trendsHash: State.TrendsHash | null,
      trendsSlugIdMap: { [key: string]: string },
      slug: string | null
    ): State.Trend | null => {
      if (!slug || !trendsHash || !trendsSlugIdMap) return null;
      const id: string = trendsSlugIdMap[slug];
      if (!id) return null;
      const trend: State.Trend = trendsHash[id];
      return trend;
    }
  );

export const skillFromParamsSelectorFactory = (pageSkillSlugSelector: any) =>
  createSelector(
    [skillsHashSelector, skillsSlugIdMapSelector, pageSkillSlugSelector],
    (
      skillsHash: State.SkillsHash | null,
      skillsSlugIdMap: { [key: string]: string },
      slug: string | null
    ): State.Skill | null => {
      if (!slug || !skillsHash || !skillsSlugIdMap) return null;
      const id: string = skillsSlugIdMap[slug];
      if (!id) return null;
      const skill: State.Skill = skillsHash[id];
      return skill;
    }
  );

export const subskillFromParamsSelectorFactory = (pageSubskillSlugSelector: any) =>
  createSelector(
    [subskillsHashSelector, subskillsSlugIdMapSelector, pageSubskillSlugSelector],
    (
      subskillsHash: State.SubskillsHash | null,
      subskillsSlugIdMap: { [key: string]: string },
      slug: string | null
    ): State.Subskill | null => {
      if (!slug || !subskillsHash || !subskillsSlugIdMap) return null;
      const id: string = subskillsSlugIdMap[slug];
      if (!id) return null;
      const subskill: State.Subskill = subskillsHash[id];
      return subskill;
    }
  );

export const categoryFromParamsSelectorFactory = (pageCategorySlugSelector: any) =>
  createSelector(
    [categoriesHashSelector, pageCategorySlugSelector],
    (categoriesHash: State.CategoriesHash, slug: string | null): State.CategoriesItem | null => {
      if (!slug || !categoriesHash) return null;
      if (slug === Content.CategorySlugs.ALL) {
        return {
          slug: Content.CategorySlugs.ALL,
          name: 'All',
          description:
            '[topic/subtopic/section] | Start here for the AICPA’s information and CPE, essential for accountants and finance professionals.',
        };
      }
      return categoriesHash[slug] || null;
    }
  );

export const isInvoicePaymentJourneySelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(
    matchPath(location.pathname, {
      path: [
        getPath(Routes.ORG_INVOICE_CHECKOUT),
        getPath(Routes.ORG_INVOICE_PAYMENT_CONFIRMATION),
        getPath(Routes.FIRM_BILLING_INVOICE_CHECKOUT),
        getPath(Routes.FIRM_BILLING_INVOICE_CONFIRMATION),
        getPath(Routes.FIRM_BILLING_INVOICE_CHECKOUT_PAYPAL_PROCEED),
      ],
      exact: true,
    })
  )
);

export const isOrgInvoiceCheckoutSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(
    matchPath(location.pathname, {
      path: [getPath(Routes.ORG_INVOICE_CHECKOUT)],
      exact: true,
    })
  )
);

export const isPaypalProceedInvoiceCheckoutSelector = createSelector(
  getLocation as any,
  (location: Location): boolean =>
    Boolean(
      matchPath(location.pathname, {
        path: [getPath(Routes.ORG_INVOICE_CHECKOUT_PAYPAL_PROCEED)],
        exact: true,
      })
    )
);

export const isPageFirmBillingInvoiceCheckoutSelector = createSelector(
  getLocation as any,
  (location: Location): boolean =>
    Boolean(
      matchPath(location.pathname, {
        path: [
          getPath(Routes.FIRM_BILLING_INVOICE_CHECKOUT),
          getPath(Routes.FIRM_BILLING_INVOICE_CONFIRMATION),
          getPath(Routes.FIRM_BILLING_INVOICE_CHECKOUT_PAYPAL_PROCEED),
        ],
        exact: true,
      })
    )
);

export const currentPathNamSelector = createSelector(
  getLocation as any,
  (location: Location): string => location.pathname
);

const querySelector = createSelector(
  (state: any) => state.router,
  (router: any): string => router.location.query
);

export const isFromPaypalSelector = createSelector(querySelector, (query: any): string => query?.fromPaypal);

export const legalEntityQuerySelector = createSelector(querySelector, (query: any): string => query?.legalEntity);
export const currencyQuerySelector = createSelector(querySelector, (query: any): string => query?.currency);

export const isPaypalProceedFirmBillingCheckoutSelector = createSelector(
  getLocation as any,
  (location: Location): boolean =>
    Boolean(
      matchPath(location.pathname, {
        path: [getPath(Routes.FIRM_BILLING_INVOICE_CHECKOUT_PAYPAL_PROCEED)],
        exact: true,
      })
    )
);

export const isCimaMembershipLandingPageSelector = createSelector(
  getLocation as any,
  constantsSelector,
  staticLandingPageSelector,
  (location: Location, constants, staticLandingPage): boolean =>
    Boolean(
      matchPath(location.pathname, {
        path: [getPath(Routes.STATIC_LANDING_PAGES)],
        exact: true,
      }) &&
        constants?.[CONSTANTS.CIMA_MEMBERSHIP_LANDING_PAGE_SLUG] &&
        constants[CONSTANTS.CIMA_MEMBERSHIP_LANDING_PAGE_SLUG] === staticLandingPage?.slug
    )
);
export const isPEREmploymentPageSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(
    matchPath(location.pathname, {
      path: [getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT_REVIEW)],
      exact: true,
    })
  )
);

export const isPERCoreActivityPageSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(
    matchPath(location.pathname, {
      path: [getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES_REVIEW)],
      exact: true,
    })
  )
);

export const isPERSkillsAndBehaviorPageSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(
    matchPath(location.pathname, {
      path: [getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_SKILL_BEHAVIOUR_REVIEW)],
      exact: true,
    })
  )
);

export const isCartPageSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(
    matchPath(location.pathname, {
      path: [getPath(Routes.CART_PAGE)],
      exact: true,
    })
  )
);

export const isPageCheckoutSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(
    matchPath(location.pathname, {
      path: [getPath(Routes.CHECKOUT_PAGE)],
      exact: true,
    })
  )
);
