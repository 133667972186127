import React from 'react';
import styled from 'styled-components';
import { WarningModal } from 'components/admin/molecules/WarningModal/WarningModal';

interface Props {
  impersonatedUserEmail: string;
  resetImpersonatedUserPasswordCode: string;
  isImpersonatedUserPasswordModalOpen: boolean;
  closeModal: () => void;
}

export const ModalResetImpersonatedUserPassword: React.FC<Props> = ({
  impersonatedUserEmail,
  resetImpersonatedUserPasswordCode,
  isImpersonatedUserPasswordModalOpen,
  closeModal,
}) => {
  const renderDescriptionNode = (): React.ReactNode => (
    <>
      <StyledText>The temporary password for {impersonatedUserEmail} is:</StyledText>
      <StyledText isPasswordCode>{resetImpersonatedUserPasswordCode}</StyledText>
      <StyledText>
        Note: The customer will receive an email confirmation of the password reset but not the actual temporary
        password.
      </StyledText>
    </>
  );
  return (
    <StyledWarningModal
      open={isImpersonatedUserPasswordModalOpen}
      onCancel={closeModal}
      header="You have generated a temporary password"
      testId="resetImpersonatedUserPassword"
      descriptionNode={renderDescriptionNode}
    />
  );
};

const StyledWarningModal = styled(WarningModal)`
  &&&& {
    top: ${props => props.theme.pxToRem(130)};
    width: ${props => props.theme.pxToRem(590)};
    height: ${props => props.theme.pxToRem(420)};
    border-radius: ${props => props.theme.pxToRem(2)};

    .header {
      margin-top: ${props => props.theme.pxToRem(10)};
      h2 {
        margin-top: ${props => props.theme.pxToRem(17)};
      }
    }

    .content {
      margin-top: ${props => props.theme.pxToRem(22)};
    }

    .cancel {
      padding: 0;
      margin-right: ${props => props.theme.pxToRem(14)};
    }

    .confirm,
    .cancel {
      width: ${props => props.theme.pxToRem(190)};
      height: ${props => props.theme.pxToRem(34)};
    }

    button > svg {
      fill: ${props => props.theme.colors.primaryDarkPurple};
    }
  }
`;

const StyledText = styled.p<{ isPasswordCode?: boolean }>`
  color: ${props => (props?.isPasswordCode ? props.theme.colors.primaryDarkPurple : props.theme.colors.neutralBlack)};
  font-size: ${props => (props?.isPasswordCode ? props.theme.fontSizes.l : props.theme.fontSizes.xs)};
  font-weight: ${props => (props?.isPasswordCode ? props.theme.fontWeights.bold : props.theme.fontWeights.light)};
  line-height: 1.57;
  text-align: center;
  max-width: 100%;
  margin: ${props => props.theme.pxToRem(10)} auto;
`;
