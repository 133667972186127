import { createAction, handleActions, Action } from 'redux-actions';
import { Product } from 'mxp-schemas';

// ------------------------------------
// Helpers
// ------------------------------------
const getInitialProductTypesHash = () =>
  Object.values(Product.ProductTypesSlugsMap).reduce((acc: State.ProductTypesHash, slug: string) => {
    acc[slug] = { name: '', slug, id: '' };
    return acc;
  }, {});

const initialState: State.ProductTypes = {
  productTypesHash: getInitialProductTypesHash(),
  productTypesFetched: false,
};
// ------------------------------------
// Actions
// ------------------------------------
export const getProductTypesHash: any = createAction('productTypes/GET_PRODUCT_TYPES_HASH');

const ACTION_HANDLERS = {
  [getProductTypesHash]: {
    next: (state: State.ProductTypes, action: Action<{ productTypes: State.ProductTypesHash }>): State.ProductTypes => {
      return {
        ...state,
        productTypesHash: {
          ...state.productTypesHash,
          ...action.payload.productTypes,
        },
        productTypesFetched: true,
      };
    },
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
