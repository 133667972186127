import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getOrganizations } from 'modules/centerAdmin';
import {
  selectedOrganizationSelector,
  selectedOrganizationIdSelector,
  selectedCenterMembershipSelector,
} from 'modules/centerAdmin/selectors';
import { CenterAdminHeader } from 'components/pages/PageCenterAdmin/CenterAdminHeader';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

const mapActionCreators = (dispatch: Dispatch) => ({
  getOrganizations(): void {
    dispatch(getOrganizations()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
});

const mapStateToProps = (state: State.Root) => ({
  selectedOrganization: selectedOrganizationSelector(state),
  id: selectedOrganizationIdSelector(state),
  selectedCenterMembership: selectedCenterMembershipSelector(state),
});

export const CenterAdminHeaderContainer = connect(mapStateToProps, mapActionCreators)(CenterAdminHeader);
