import React from 'react';
import styled from 'styled-components';
import { Sidebar } from 'semantic-ui-react';

interface Props {
  className?: string;
  isOpen: boolean;
  children: React.ReactElement;
  onClose(event: React.SyntheticEvent): void;
}

export const SlideInModal: React.SFC<Props> = ({ className, isOpen = false, children, onClose }) => {
  return (
    <StyledSidebar
      animation="overlay"
      direction="right"
      onHide={(event: React.SyntheticEvent) => onClose(event)} // tslint:disable-line:jsx-no-lambda
      visible={isOpen}
    >
      {children}
    </StyledSidebar>
  );
};

const StyledSidebar = styled(Sidebar)`
  &&&& {
    width: 50vw;
    padding: 65px 125px;
    background-color: ${props => props.theme.colors.neutralWhite};

    ${props => props.theme.mediaQueries.desktopOnly} {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }

    ${props => props.theme.mediaQueries.tabletMax} {
      padding: 30px 20px;
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100vw;
      padding: 30px 20px;
    }
  }

  &&&&& {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }
`;
