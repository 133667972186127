import * as Sentry from '@sentry/browser';
import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import {
  getProductItem,
  getRelatedContentList,
  setSelectedDonationPrice,
  setSelectedVariant,
  getProductsListData,
  resetProductsListData,
  getPremiumContentItems,
  resetProductsModule,
  setSelectedBundleProductVariant,
  getContributions,
  getMemberships,
  setMembershipsLoading,
  getWebcastPass,
  getContentfulProductData,
  getCurrency,
  updatePreferredCurrency,
  updateCancelledProduct,
  setClaimGiftAid,
  getProductBySku,
} from './actions';
import { Product, Cart } from 'mxp-schemas';

if ([getProductItem].includes(undefined)) {
  const message: string = 'product module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [setSelectedDonationPrice]: (state: State.Products, action: any): State.Products => ({
    ...state,
    selectedDonationPrice: action.payload,
  }),

  [setSelectedVariant]: (state: State.Products, action: any): State.Products => ({
    ...state,
    selectedItemSKUs: [action.payload.sku],
    isFreeTrialSelected: action.payload.isFreeTrialSelected,
  }),

  [setSelectedBundleProductVariant]: (
    state: State.Products,
    action: { payload: Common.BundleProductVariant }
  ): State.Products => {
    const newState = {
      ...state,
      selectedItemSKUs: [...state.selectedItemSKUs],
      isFreeTrialSelected: false,
    };
    newState.selectedItemSKUs[action.payload.index] = action.payload.sku;
    return newState;
  },

  [getProductItem]: {
    next: (state: State.Products, action: any): State.Products => {
      const productItem: Product.ProductItem = action.payload;
      if (Array.isArray(productItem?.bundleProducts)) {
        productItem.bundleProducts.sort((a, b) => (a.name > b.name ? 1 : -1));
      }

      return {
        ...state,
        productItem: action.payload,
        selectedItemSKUs: [],
        isProductFetched: true,
        isProductViewTriggered: state.isProductFetched,
      };
    },

    throw: (state: State.Products, action: any): State.Products => ({
      ...state,
      error: action.payload,
      selectedItemSKUs: [],
      isProductFetched: true,
    }),
  },
  [getPremiumContentItems]: {
    next: (state: State.Products, action: any): State.Products => {
      return {
        ...state,
        premiumContentItems: action.payload,
      };
    },
  },

  [getRelatedContentList]: {
    next: (state: State.Products, action: any): State.Products => ({
      ...state,
      relatedContent: action.payload,
      isRelatedProductFetched: !!state.relatedContent,
    }),
  },

  [getContentfulProductData]: {
    next: (state: State.Products, action: any): State.Products => {
      return {
        ...state,
        benefitsAccessLinks: action.payload,
      };
    },

    throw: (state: State.Products, action: any): State.Products => ({
      ...state,
      error: action.payload,
    }),
  },

  [getProductsListData]: {
    next: (state: State.Products, action: any): State.Products => {
      const memberships = action?.payload?.lineItems?.filter(
        (lineItem: Cart.LineItem) => lineItem.productType === Product.ProductType.MEMBERSHIP
      );
      const sections = action?.payload?.lineItems?.filter(
        (lineItem: Cart.LineItem) => lineItem.productType === Product.ProductType.SECTION
      );
      const credentials = action?.payload?.lineItems?.filter(
        (lineItem: Cart.LineItem) => lineItem.productType === Product.ProductType.CREDENTIAL
      );
      const otherProducts = action?.payload?.lineItems?.filter(
        (lineItem: Cart.LineItem) =>
          lineItem.productType !== Product.ProductType.MEMBERSHIP &&
          lineItem.productType !== Product.ProductType.SECTION &&
          lineItem.productType !== Product.ProductType.CREDENTIAL
      );

      return {
        ...state,
        productsListData: action.payload,
        productsListDataFetched: true,
        productListByTypes: {
          memberships,
          sections,
          credentials,
          otherProducts,
        },
      };
    },

    throw: (state: State.Products, action: any): State.Products => ({
      ...state,
      error: action.payload,
      productsListDataFetched: true,
    }),
  },

  [resetProductsListData]: (state: State.Products): State.Products => {
    return {
      ...state,
      productsListData: null,
      productsListDataFetched: false,
    };
  },
  [resetProductsModule]: (state: State.Products): State.Products => {
    const { webcastPass, isWebcastPassFetched, ...productsInitialState } = initialState;

    return {
      ...state,
      ...productsInitialState,
      currency: state.currency,
      hasExistingZuoraPurchase: state.hasExistingZuoraPurchase,
    };
  },

  [getContributions]: {
    next: (state: State.Products, action: any): State.Products => ({
      ...state,
      contributions: action.payload.membershipContributions,
      contributionsFetched: true,
      contributionsFetchSuccess: action.payload.success,
    }),
  },

  [setMembershipsLoading]: {
    next: (state: State.Products, action: any): State.Products => ({
      ...state,
      membershipsLoading: true,
    }),
  },

  [getMemberships]: {
    next: (state: State.Products, action: any): State.Products => ({
      ...state,
      memberships: action.payload.getMemberships || [],
      membershipsLoading: false,
      membershipsLoaded: action.payload.success,
    }),
  },

  [getWebcastPass]: {
    next: (state: State.Products, action: any): State.Products => {
      return {
        ...state,
        webcastPass: action.payload,
        isWebcastPassFetched: true,
      };
    },

    throw: (state: State.Products, action: any): State.Products => ({
      ...state,
      error: action.payload,
      isWebcastPassFetched: true,
    }),
  },

  [getCurrency]: {
    next: (state: State.Products, action: any): State.Products => {
      if (action?.payload?.getCurrency === null) {
        return {
          ...state,
          hasExistingZuoraPurchase: false,
        };
      }

      const currency =
        action?.payload?.getCurrency?.latestCurrency === Product.ProductCurrencyLabel.GBP
          ? { label: Product.ProductCurrencyLabel.GBP, sign: Product.ProductCurrencySign.POUND }
          : action?.payload?.getCurrency?.latestCurrency === Product.ProductCurrencyLabel.EUR
          ? { label: Product.ProductCurrencyLabel.EUR, sign: Product.ProductCurrencySign.EURO }
          : { label: Product.ProductCurrencyLabel.USD, sign: Product.ProductCurrencySign.DOLLAR };

      return {
        ...state,
        currency,
        hasExistingZuoraPurchase: true,
        existingEntity: action?.payload?.getCurrency?.latestEntity,
        entities: action?.payload?.getCurrency?.entities,
      };
    },

    throw: (state: State.Products, action: any): State.Products => ({
      ...state,
      hasExistingZuoraPurchase: false,
    }),
  },

  [updatePreferredCurrency]: {
    next: (state: State.Products, action: any): State.Products => ({
      ...state,
      currency: action.payload?.currency,
      isCurrencyToggledByDropdown: action.payload?.isCurrencyToggledByDropdown,
    }),
  },

  [updateCancelledProduct]: {
    next: (state: State.Products, action: any): State.Products => ({
      ...state,
      productsListData: {
        ...state.productsListData,
        lineItems: action.payload,
      },
    }),
  },
  [setClaimGiftAid]: {
    next: (state: State.Products, action: any): State.Products => ({
      ...state,
      claimGiftAid: action.payload,
    }),
  },
  [getProductBySku]: {
    next: (state: State.Products, action: any): State.Products => ({
      ...state,
      fetchedProductBySku: action.payload,
    }),
  },
};

export { getProductItem, getWebcastPass };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
