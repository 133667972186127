import { createAction } from 'redux-actions';

// ------------------------------------
// Actions
// ------------------------------------
export const getContentLanguagesHash: any = createAction(
  'skills/GET_CONTENTLANGUAGES_HASH',
  (contentLanguages: State.ContentLanguages) => () => {
    return contentLanguages;
  }
);
