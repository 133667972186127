import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { InquiryFormBulkOrder } from 'components/organisms/InquiryForm/InquiryFormBulkOrder';
import { fetchProductSearchResults, updateSearchSelection, fetchProductItem } from 'modules/inquiryForm/actions';
import { getMarketingPreferences } from 'modules/user/actions';
import { isSearchLoadingSelector, searchResultsSelector, searchSelectionSelector } from 'modules/inquiryForm/selectors';
import {
  filteredProductVariantsSelector,
  isProductFetchedSelector,
  productPageItemSelector,
} from 'modules/products/selectors';
import {
  marketingPreferencesErrorSelector,
  marketingPreferencesLoadingSelector,
  marketingPreferencesSelector,
} from 'modules/user/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  getMarketingPreferences(): void {
    dispatch(getMarketingPreferences());
  },
  fetchProductSearchResults(searchQuery: string): void {
    dispatch(fetchProductSearchResults(searchQuery));
  },
  updateSearchSelection(searchSelection: State.InquiryFormSearchResult | null): void {
    dispatch(updateSearchSelection(searchSelection));
  },
  fetchProductItem(slug: string): void {
    dispatch(fetchProductItem(slug));
  },
});
const mapStateToProps = (state: State.Root) => {
  return {
    searchLoading: isSearchLoadingSelector(state),
    searchResults: searchResultsSelector(state),
    searchSelection: searchSelectionSelector(state),
    productItem: productPageItemSelector(state),
    productItemFilteredVariants: filteredProductVariantsSelector(state),
    isProductFetched: isProductFetchedSelector(state),
    marketingPreferences: marketingPreferencesSelector(state),
    marketingPreferencesLoading: marketingPreferencesLoadingSelector(state),
    marketingPreferencesError: marketingPreferencesErrorSelector(state),
  };
};

export const InquiryFormBulkOrderContainer = connect(mapStateToProps, mapActionCreators)(InquiryFormBulkOrder);
