import React, { useState, useEffect, useRef, memo } from 'react';
import { Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';
import { Label } from 'components/atoms/Label/Label';

interface Props {
  data?: string;
  height?: number;
  label?: string;
  description?: string;
  isCorrect?: boolean;
  errorMessage?: string | React.ReactNode;
  charMaxCount?: number;
  testId?: string;
  getCurrentValue?: (data: any) => void;
  onBlur?: () => void;
  disable?: boolean;
}

interface RichText {
  CKEditor: any;
  ClassicEditor: any;
}

export const RichTextEditor: React.FC<Props> = memo(
  ({
    data,
    label,
    description,
    height,
    isCorrect = true,
    charMaxCount = 7000,
    errorMessage,
    testId,
    getCurrentValue,
    disable = false,
    onBlur,
  }) => {
    const editorRef = useRef<any>();
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const [pasteExceed, setPasteExceed] = useState(false);
    const { CKEditor, DecoupledEditor } =
      editorRef.current || ({} as RichText); /* tslint:disable-line:no-object-literal-type-assertion */

    useEffect(() => {
      const loadEditor = async () => {
        editorRef.current = {
          CKEditor: await import('@ckeditor/ckeditor5-react').then(editor => editor.CKEditor),
          DecoupledEditor: (await import('@ckeditor/ckeditor5-build-decoupled-document')).default,
        };
        setEditorLoaded(true);
      };
      loadEditor().then(error => console.error(error));
    }, []);

    return editorLoaded ? (
      <>
        {label && <StyledLabel>{label}</StyledLabel>}
        {description && <StyledDescription>{description}</StyledDescription>}
        <StyledCKEditorContainer height={height || 250} testId={testId || 'ckeditorId'} isCorrect={isCorrect}>
          <CKEditor
            onReady={(editor: any) => {
              // tslint:disable-line jsx-no-lambda

              // to prevent the style attribute of the li element from disappearing
              editor.model.schema.extend('listItem', {
                allowAttributes: '__style',
              });
              editor.conversion.for('upcast').attributeToAttribute({
                model: {
                  name: 'listItem',
                  key: '__style',
                },
                view: {
                  name: 'li',
                  key: 'style',
                  value: /[\s\S]+/,
                },
              });
              editor.conversion.for('downcast').add(
                (dispatcher: any) => {
                  dispatcher.on('attribute:__style:listItem', (evt: any, dataList: any, conversionApi: any) => {
                    const viewElement = conversionApi.mapper.toViewElement(dataList.item);
                    conversionApi.writer.setAttribute('style', dataList.attributeNewValue, viewElement);
                  });
                },
                { priority: 'highest' }
              );
              editor.ui
                .getEditableElement()
                .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
              editor.setData(data);
              if (data === '<p><br data-cke-filler="true"></p>') {
                setCharCount(0);
              }

              // to directly show data without HTML tags on page load
              if (getCurrentValue) getCurrentValue(editor.getData());
            }}
            editor={DecoupledEditor}
            data={data}
            config={{
              toolbarLocation: 'bottom',
              toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList', '|', 'outdent', 'indent'],
              resizeEnabled: true,
            }}
            onChange={async (event: any, editor: any) => {
              try {
                // tslint:disable-line jsx-no-lambda

                const txt = () => modelElementToPlainText(editor.model.document.getRoot());
                await setCharCount(txt().replace(/\n/g, '').replace('<p><br data-cke-filler="true"></p>', '').length);
                if ((await txt()) === '<p><br data-cke-filler="true"></p>') {
                  setCharCount(0);
                }

                editor.editing.view.document.on('keydown', (observer: any, evt: any) => {
                  if (pasteExceed) {
                    setPasteExceed(false);
                  }
                  if (
                    txt().replace(/\n/g, '').replace('<p><br data-cke-filler="true"></p>', '').length >= charMaxCount
                  ) {
                    if (parseInt(evt.keyCode, 10) !== 46 || parseInt(evt.keyCode, 10) !== 8) {
                      evt.preventDefault();
                    }
                  }
                });

                editor.editing.view.document.on('clipboardInput', (evt: any, inputData: any) => {
                  if (pasteExceed) {
                    setPasteExceed(false);
                  }
                  const dataTransfer = inputData.dataTransfer;
                  const content = dataTransfer.getData('text/plain');
                  if (
                    evt &&
                    txt().replace(/\n/g, '').replace('<p><br data-cke-filler="true"></p>', '').length +
                      content.length >=
                      charMaxCount
                  ) {
                    setPasteExceed(true);
                    evt.stop();
                  }
                });

                editor.ui.getEditableElement().onblur = () => {
                  try {
                    if (getCurrentValue) getCurrentValue(editor.getData());
                  } catch (error) {
                    console.error(error);
                  }
                };
              } catch (error) {
                console.error(error);
              }
            }}
            disabled={disable}
            onBlur={onBlur}
          />
          <StyledCharCount pasteExceed={pasteExceed || false}>
            {!pasteExceed ? (
              `${charCount} / ${charMaxCount}`
            ) : (
              <StyledError>Pasted content exceeds character limit</StyledError>
            )}
          </StyledCharCount>
        </StyledCKEditorContainer>
        {!isCorrect && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      </>
    ) : (
      <>
        <Placeholder length="full">
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
        <StyledPlaceholder>
          <Placeholder.Image />
        </StyledPlaceholder>
      </>
    );
  }
);

const StyledErrorMessage = styled.p`
  margin-top: ${props => props.theme.pxToRem(4)};
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.interfaceRed};
`;

const StyledCharCount = styled.span<{ pasteExceed: boolean }>`
  position: relative;
  ${props =>
    props.pasteExceed
      ? `
    display: grid;
    justify-content: right;
    right: ${props.theme.pxToRem(8)};`
      : `right: ${props.theme.pxToRem(-475)};`}
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  bottom: ${props => props.theme.pxToRem(-240)};
`;

const StyledDescription = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledLabel = styled(Label)`
  &&& {
    display: block !important;
    width: 100% !important;
    height: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.neutralGrey8};
    display: inline-block;
    width: 30%;
    ${props => props.theme.mediaQueries.mobileOnly}  {
      display: block;
      width: 100%;
    }
  }
`;

const StyledPlaceholder = styled(Placeholder)`
  &&&&&& {
    height: ${props => props.theme.pxToRem(35)};
    width: ${props => props.theme.pxToRem(205)};
  }
`;

const StyledError = styled.p`
  color: red;
  font-size: ${props => props.theme.fontSizes.xs};
`;

const StyledCKEditorContainer = styled.div<{ height: number; isCorrect: boolean; testId: string }>`
  &&&&& {
    height: ${props => props.theme.pxToRem(props.height)};
    display: flex !important;
    flex-direction: column-reverse !important;
    border: ;
    border-radius: ${props => props.theme.pxToRem(5)};

    border: solid ${props => props.theme.pxToRem(1)} ${props =>
  props.isCorrect ? props.theme.colors.neutralGrey4 : props.theme.colors.interfaceRed}};

    .ck.ck-content.ck-editor__editable {
      height: inherit;
      min-height: 85%;
    }
  }
`;

function modelElementToPlainText(element: any) {
  if (element.is('$text') || element.is('$textProxy')) {
    return element.data;
  }

  let text = '';
  let prev = null;

  for (const child of element.getChildren()) {
    const childText = modelElementToPlainText(child);

    // If last block was finish, start from new line.
    if (prev && prev.is('element')) {
      text += '\n';
    }

    text += childText;

    prev = child;
  }

  return text;
}
