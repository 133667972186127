import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { getTrendsHash } from './actions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [getTrendsHash]: {
    next: (state: State.Trends, action: any): State.Trends => ({
      ...state,
      trendsHash: action.payload,
      trendsFetched: true,
    }),

    throw: (state: State.Trends, action: any): State.Trends => ({
      ...state,
      error: action.payload, // TODO
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
