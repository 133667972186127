import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { OrganizationInvoices } from 'components/pages/PageOrganization/OrganizationInvoices';
import { getInvoices, resetModule, getInvoiceFile } from 'modules/clientAdmin';
import { setPaginationAction } from 'modules/clientAdmin/actions';
import {
  invoicesListFetchedSelector,
  invoicesListErrorSelector,
  dueInvoicesListSelector,
  paidInvoicesListSelector,
  downloadingErrorSelector,
  downloadingIdSelector,
  paginationSelector,
} from 'modules/clientAdmin/selectors';
import { ZuoraTypes } from 'mxp-schemas';

const mapActionCreators = (dispatch: Dispatch) => ({
  getInvoices(legalEntity?: ZuoraTypes.LegalEntity): void {
    dispatch(getInvoices(legalEntity)).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
  setPagination(obj: { type: string; modifier: { offset: number } }) {
    dispatch(setPaginationAction(obj));
  },
  resetModule(): void {
    dispatch(resetModule());
  },
  getInvoiceFile(id: string): void {
    dispatch(getInvoiceFile(id));
  },
  navigate(path: string): void {
    dispatch(push(path));
  },
});

const mapStateToProps = (state: State.Root) => ({
  pagination: paginationSelector(state),
  error: invoicesListErrorSelector(state),
  invoicesListFetched: invoicesListFetchedSelector(state),
  dueInvoicesList: dueInvoicesListSelector(state),
  paidInvoicesList: paidInvoicesListSelector(state),
  downloadId: downloadingIdSelector(state),
  downloadError: downloadingErrorSelector(state),
});

export const OrganizationInvoicesContainer = connect(mapStateToProps, mapActionCreators)(OrganizationInvoices);
