import React from 'react';
import { Modal } from 'components/atoms/Modal/Modal';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import styled from 'styled-components';

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirmation: () => void;
  membershipType: string;
  email: string;
}

export const RemoveConfirmationModal: React.FC<Props> = ({ open, onCancel, onConfirmation, membershipType, email }) => (
  <Modal
    open={open}
    onClose={onCancel}
    heading="Are you sure you want to remove the following member(s) from"
    headingCenterBoldText={membershipType}
    headingCenterAfterBoldText="membership?"
    testId="remove-member-confirmation-modal"
    cancelNode={
      <StyledButton
        size={ButtonEnums.sizes.small}
        variant={ButtonEnums.variants.secondary}
        testId="remove-member-confirmation-modal-cancel-button"
        onClick={onCancel}
      >
        Cancel
      </StyledButton>
    }
    confirmNode={
      <StyledButton
        size={ButtonEnums.sizes.small}
        variant={ButtonEnums.variants.primary}
        testId="remove-member-confirmation-modal-confirm-button"
        onClick={onConfirmation}
      >
        Confirm
      </StyledButton>
    }
  >
    <StyledText>{email} will lose access to this product. Do you confirm this change?</StyledText>
  </Modal>
);

const StyledButton = styled(Button)`
  &&&&& {
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};
    margin: 0 ${props => `${props.theme.pxToRem(5)}`};
    min-width: ${props => props.theme.pxToRem(200)};
  }
`;

const StyledText = styled.p`
  line-height: 1.57;
  text-align: center;
  max-width: ${props => props.theme.pxToRem(600)};
  margin: 0 auto;
`;
