import React from 'react';
import { createGlobalStyle, ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme } from './index';
import { normalize } from './normalize';
import globalStyleRules from './globalStyleRules';

const GlobalStyle = createGlobalStyle`${normalize} ${globalStyleRules}`;
export const ThemeProvider = (props: any) => {
  return (
    <StyledThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        {props.children}
      </>
    </StyledThemeProvider>
  );
};
