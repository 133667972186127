import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
// import CheckIconWhite from 'resources/images/icon-live-ic-done.png';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';

export interface StepperProps {
  title: string;
  isActive: boolean;
  isSuccess: boolean;
  isCIMA?: boolean;
  isPackageBuilder?: boolean;
}

export interface StepperBarProps {
  steps: StepperProps[];
  className?: string;
}

interface DivProps {
  isActive: boolean;
  isSuccess?: boolean;
  isFirst?: boolean;
  theme: DefaultTheme;
  isCIMA?: boolean;
  isPackageBuilder?: boolean;
}

export const StepperBar: React.FC<StepperBarProps> = ({ steps, className }) => {
  return (
    <StyledStepper className={className}>
      <StepperRow>
        {steps.map(({ title, isActive, isCIMA, isPackageBuilder }, i) => (
          <StepperTitle
            isActive={isActive}
            isFirst={i === 0}
            isCIMA={isCIMA}
            isPackageBuilder={isPackageBuilder}
            key={`stepper-${title}`}
          >
            {title}
          </StepperTitle>
        ))}
      </StepperRow>
      <StepperRow2>
        {steps.map(({ title, isActive, isSuccess, isCIMA }, i, arr) => (
          <StepperIcons key={`icons-${title}`}>
            <StepperCircle isFirst={i === 0} isActive={isActive} isSuccess={isSuccess}>
              {isSuccess ? <StyledCheckIconWhite /> : isActive ? <StyledCheckIcon /> : null}
            </StepperCircle>
            {i < arr.length - 1 && <StepperLine isActive={isActive} isSuccess={isSuccess} isCIMA={isCIMA} />}
          </StepperIcons>
        ))}
      </StepperRow2>
    </StyledStepper>
  );
};

const StyledStepper = styled.div`
  margin: 0 ${props => props.theme.pxToRem(5)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const StepperTitle = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  font-weight: ${({ isActive, isSuccess, theme }: DivProps) => (isActive && !isSuccess ? theme.fontWeights.bold : 300)};
  margin-bottom: ${props => props.theme.pxToRem(11)};
  margin-right: ${({ isCIMA, theme }: DivProps) => (isCIMA ? theme.pxToRem(0) : theme.pxToRem(20))};
  margin-left: ${({ isFirst, isCIMA, theme, isPackageBuilder }: DivProps) =>
    isCIMA
      ? isFirst
        ? theme.pxToRem(0)
        : isPackageBuilder
        ? theme.pxToRem(110)
        : theme.pxToRem(100)
      : theme.pxToRem(0)};
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${(props: Omit<DivProps, 'isSuccess'>) =>
    props.isActive ? props.theme.colors.neutralGrey8 : props.theme.colors.neutralGrey4};
  width: ${({ isCIMA, theme }: DivProps) => (isCIMA ? 'auto' : theme.pxToRem(90))};

  ${props => props.theme.mediaQueries.mobileOnly} {
    visibility: ${({ isActive }: DivProps) => (isActive ? 'visible' : 'hidden')};
    margin-right: ${props => props.theme.pxToRem(-3)};
    margin-bottom: ${props => props.theme.pxToRem(10)};
    width: auto;
    margin-left: ${props => props.theme.pxToRem(1)};
  }
`;

const StepperCircle = styled.div`
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
  border-radius: ${props => props.theme.pxToRem(16)};
  margin-left: ${({ isFirst, theme }: DivProps) => (isFirst ? theme.pxToRem(33) : theme.pxToRem(17))};
  margin-right: ${props => props.theme.pxToRem(17)};
  border: ${props => props.theme.pxToRem(1)} solid
    ${({ isActive, isSuccess, theme }: DivProps) =>
      isActive || isSuccess ? theme.colors.secondaryTeal : theme.colors.neutralGrey4};
  background-color: ${({ isSuccess, theme }: DivProps) =>
    isSuccess ? theme.colors.secondaryTeal : theme.colors.neutralWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${({ isFirst, theme }: DivProps) => (isFirst ? theme.pxToRem(0) : theme.pxToRem(17))};
  }
`;

const StepperLine = styled.div`
  width: ${({ isCIMA, theme }: DivProps) => (isCIMA ? theme.pxToRem(195) : theme.pxToRem(63))};
  height: ${props => props.theme.pxToRem(1)};
  background-color: ${({ isSuccess, theme }: DivProps) =>
    isSuccess ? theme.colors.secondaryTeal : theme.colors.neutralGrey4};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: auto;
    flex: 1;
    margin: 0;
  }
`;

const StepperIcons = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex: 1;
    margin: 0;
  }
`;

const StepperRow = styled.div`
  display: flex;
  ${props => props.theme.mediaQueries.mobileOnly} {
    justify-content: space-around;
  }
`;

const StepperRow2 = styled.div`
  display: flex;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: 8%;
    margin-right: ${props => props.theme.pxToRem(12)};
    align-items: center;
    & > div:last-child {
      flex: 0 2 auto;
    }
  }
`;

const StyledCheckIcon = styled(Checkmark)`
  &&& {
    path {
      fill: ${props => props.theme.colors.secondaryTeal};
    }
  }

  width: ${props => props.theme.pxToRem(13)};
  height: ${props => props.theme.pxToRem(13)};
`;

const StyledCheckIconWhite = styled(Checkmark)`
  width: ${props => props.theme.pxToRem(13)};
  height: ${props => props.theme.pxToRem(13)};
`;
