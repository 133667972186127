import React, { useState } from 'react';
import { Input, InputProps } from '../../atoms/Input/Input';

interface ShowPasswordInputProps extends InputProps {
  isNew?: boolean;
}

export const ShowPasswordInput: React.SFC<ShowPasswordInputProps> = ({ testId, isNew = false, ...props }) => {
  const [passwordVisibility, setVisibility] = useState(false);

  const handleToggle = (event: any) => {
    event.preventDefault();
    setVisibility(!passwordVisibility);
  };

  return (
    <Input
      {...props}
      autoComplete="Off"
      type={passwordVisibility ? 'text' : 'password'}
      onIconMouseDown={handleToggle}
      labelId={`input-password-${testId}`}
      iconName={props.value || props.hasIcon ? (passwordVisibility ? 'eye' : 'eye slash') : undefined}
      testId={`password-${testId}`}
    />
  );
};
