import { pickProps, omitProps, emptyObject } from 'utils';
import { SESSION_STORE_NAMES } from '../constants';

export const STORE_KEY = SESSION_STORE_NAMES.AICPA_DATA;

/**
 * Get a set of props from sessionStorage
 */
export const getSessionStorageItem = (
  name: string | string[],
  storeKey: string = STORE_KEY
): string | boolean | object | null => {
  if (typeof window === 'undefined' || !window.sessionStorage) return null;
  const sessionConfigStr: string | null = window.sessionStorage.getItem(storeKey);
  if (!sessionConfigStr) return null;
  const sessionConfig = JSON.parse(sessionConfigStr);
  if (Array.isArray(name)) return pickProps(sessionConfig, name);
  return sessionConfig[name] || null;
};

/**
 * Save a set of props to sessionStorage
 */
export const setSessionStorageItem = (valueMap: object, storeKey: string = STORE_KEY): void => {
  if (typeof window === 'undefined' || !window.sessionStorage) return;
  const sessionConfigStr: string | null = window.sessionStorage.getItem(storeKey);
  const sessionConfig = sessionConfigStr ? JSON.parse(sessionConfigStr) : emptyObject;
  const nextSessionConfig = {
    ...sessionConfig,
    ...valueMap,
  };
  window.sessionStorage.setItem(storeKey, JSON.stringify(nextSessionConfig));
};

/**
 * Remove a set of props from sessionStorage
 */
export const removeSessionStorageItem = (name: string | string[], storeKey: string = STORE_KEY): void => {
  if (typeof window === 'undefined' || !window.sessionStorage) return;
  const names: string[] = Array.isArray(name) ? name : [name];
  const sessionConfigStr: string | null = window.sessionStorage.getItem(storeKey);
  if (!sessionConfigStr) return;
  const sessionConfig = JSON.parse(sessionConfigStr);
  const nextConfig = omitProps(sessionConfig, names);
  if (Object.keys(nextConfig).length) {
    const nextSessionConfigStr: string = JSON.stringify(nextConfig);
    window.sessionStorage.setItem(storeKey, nextSessionConfigStr);
  } else {
    window.sessionStorage.removeItem(storeKey);
  }
};

/**
 * Clear sessionStorage
 */
export const clearSessionStorage = (storeKey: string = STORE_KEY, shouldRetainValues?: string[]): void => {
  if (typeof window === 'undefined' || !window.sessionStorage) return;
  const arrayOfShouldRetainValues = shouldRetainValues?.length
    ? shouldRetainValues?.reduce((acc: any[], item: string) => {
        const value = getSessionStorageItem(item) || '';
        return [...acc, ...(value && [{ [item]: value }])];
      }, [])
    : [];
  window.sessionStorage.removeItem(storeKey);
  arrayOfShouldRetainValues?.forEach((item: object) => setSessionStorageItem(item));
};
