import React from 'react';
import styled from 'styled-components';
import { User } from 'mxp-schemas';
import { ButtonEnums, Button } from 'components/atoms/Button/Button';

interface AccessOPLProductProps {
  userEmail?: string;
  oktaId?: string;
  fvsMembership?: User.MembershipIdsEnum[] | [];
  oplHidUrl?: string;
  oplRedirectUrl?: string;
  testId: string;
  isProductHero?: boolean;
  isPurchase?: boolean;
  isFreeTrial?: boolean;
}

export const AccessOPLProduct: React.FC<AccessOPLProductProps> = ({
  userEmail,
  oktaId,
  fvsMembership,
  oplHidUrl,
  oplRedirectUrl,
  testId,
  isProductHero = false,
  isPurchase = false,
  isFreeTrial = false,
}) => (
  <StyledForm action={oplRedirectUrl} method="post" target="_blank">
    <input type="hidden" name="hidUsername" value={userEmail} />
    <input type="hidden" name="hidOktaId" value={oktaId} />
    <input type="hidden" name="hidOktaRoleId" value={fvsMembership} />
    <input type="hidden" name="hidSourceSiteCode" value="RAVE" />
    <input type="hidden" name="hidURL" value={oplHidUrl} />
    {isProductHero || isFreeTrial || isPurchase ? (
      <StyledButton
        testId={testId}
        variant={ButtonEnums.variants.primary}
        iconPosition={isFreeTrial && ButtonEnums.iconPosition.left}
        bordered={isFreeTrial}
        fluid={isFreeTrial}
        isProductHero={isProductHero}
        isFreeTrial={isFreeTrial}
        isPurchase={isPurchase}
        type="submit"
      >
        Access Now
      </StyledButton>
    ) : (
      <Button testId={testId} variant={ButtonEnums.variants.link} type="submit">
        Access Now.
      </Button>
    )}
  </StyledForm>
);

const StyledForm = styled.form`
  display: inline-block;
`;

const StyledButton = styled(Button)<any>`
  ${props =>
    props.isProductHero &&
    `&&&& {
      display: inline-block;
      width: ${props.theme.pxToRem(164)};
    }`}
  ${props =>
    props.isFreeTrial &&
    `&&&& {
      width: auto;
      border-radius: ${props.theme.pxToRem(4)};
      font-size: ${props.theme.pxToRem(16)};
      padding: 0 ${props.theme.pxToRem(16)};
  
      svg {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: ${props.theme.pxToRem(12)};
      }
    }`}
  ${props =>
    props.isPurchase &&
    `&&&& {
      display: inline-block;
      width: ${props.theme.pxToRem(164)};
    }`}
`;
