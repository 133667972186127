import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { SuccessNotification } from 'components/admin/atoms/SuccessNotification/SuccessNotification';
import { SubHeading } from 'components/molecules/SubHeading/SubHeading';
import { ResultCounter } from 'components/atoms/ResultCounter/ResultCounter';
import { Table } from 'components/atoms/Table/Table';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { formatPrice, getPath } from 'utils';
import { useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  organizationOrderList,
  organizationOrderListLoading,
  b2bCartSelector,
  salesforceAccountNumberSelector,
  legalEntitySelector,
  selectedCurrency,
  organizationZuoraAccountIdSelector,
} from 'modules/admin/selectors';
import { getOrganizationOrders, changeCurrencyCode } from 'modules/admin/actions';
import { MomentHelpers } from 'utils/MomentHelpers';
import { TablePlaceholder } from 'components/atoms/TablePlaceholder/TablePlaceholder';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { Container } from 'components/atoms';
import { Dropdown, DropdownProps } from 'components/atoms/Dropdown/Dropdown';
import { Label } from 'components/atoms/Label/Label';
import { Link } from 'components/atoms/Link/Link';
import { generatePath } from 'react-router-dom';
import { B2B } from 'mxp-schemas';

import { Routes } from 'constants/index';
import { TableSearchInput } from 'components/atoms/TableSearchInput';
import { ReactComponent as Wrong } from 'resources/images/ic-cancel.svg';

export interface Address {
  addressLine1?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

interface Props {
  goToAddOrder: () => void;
  creditHoldStatus: boolean | null;
  hasAICPAUid: boolean | null;
  addresses: {
    shippingAddress: Address;
    billingAddress: Address;
  };
  taxExemption?: boolean;
}

export const OrdersTab: React.FC<Props> = ({
  goToAddOrder,
  creditHoldStatus,
  hasAICPAUid,
  addresses,
  taxExemption,
}) => {
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const location = useLocation() as any;
  const cart = useSelector(b2bCartSelector);
  const dispatch = useDispatch();
  const accountNumber = useSelector(salesforceAccountNumberSelector) as string;
  const legalEntity = useSelector(legalEntitySelector) as string;
  const currency = useSelector(selectedCurrency);
  const zuoraAccountId = useSelector(organizationZuoraAccountIdSelector);

  useEffect(() => {
    dispatch(getOrganizationOrders(accountId, accountNumber, legalEntity));
  }, [dispatch, accountId, accountNumber, legalEntity]);
  const orders: State.OrganizationOrder[] = useSelector(organizationOrderList);
  const orderListLoading: boolean = useSelector(organizationOrderListLoading);

  const { shippingAddress, billingAddress } = addresses;
  const shippingAddressIncomplete = Object.values(shippingAddress).some((field: string) => field === '');
  const billingAddressIncomplete = Object.values(billingAddress).some((field: string) => field === '');
  const disableOrderButton: boolean =
    creditHoldStatus === null ||
    creditHoldStatus ||
    hasAICPAUid === null ||
    !hasAICPAUid ||
    shippingAddressIncomplete ||
    billingAddressIncomplete;
  const orderCtaText = cart.length ? 'Continue order' : 'Create new order';
  const hasCurrentOrder = Boolean(orders?.length > 0);

  const onFieldChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeCurrencyCode(data.value));
  };

  const [searchOrderInput, setOrderSearchInput] = useState<string | React.ReactNode>('');
  const [isOrderValid, setIsOrderValid] = useState<string | React.ReactNode>(false);
  const [sortedOrders, setSortedOrders] = useState<State.OrganizationOrder[]>([]);

  const handleOrderSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchedOrderNumber: string = (event.target as HTMLInputElement).value;
      setOrderSearchInput(searchedOrderNumber);
      setIsOrderValid(orders?.some(order => order.orderNumber === searchedOrderNumber));
    },
    [orders, setOrderSearchInput, setIsOrderValid]
  );

  enum Orders {
    EMPTY_FIELD = 'N/A',
  }

  useEffect(() => {
    if (orders && orders.length > 0) {
      const descOrders = orders.sort((a, b) => {
        const dateA: any = new Date(a.dateCreated);
        const dateB: any = new Date(b.dateCreated);
        return dateB - dateA;
      });

      setSortedOrders(descOrders);
    }
  }, [orders]);

  return (
    <>
      <SubHeading
        title="Orders"
        withAction={orders?.length > 0 && !orderListLoading && !disableOrderButton}
        buttonLabel={orderCtaText}
        onAction={goToAddOrder}
      />
      {/* TODO MOVE THIS ON OTHER PAGE */}
      {location.state && location.state.orderNumber && (
        <SuccessNotification
          label={['Success!', <b key="notification"> Order # {location.state.orderNumber}</b>, ' has been saved.']}
        />
      )}
      {creditHoldStatus !== null && creditHoldStatus && hasAICPAUid !== null && hasAICPAUid && (
        <ErrorBanner
          testId="credit-hold-error-message"
          errorMessage="This account is on credit hold and new orders cannot be created until delinquent balance is paid. Please refer to Salesforce account notes for further details."
        />
      )}
      {hasAICPAUid !== null && !hasAICPAUid && (
        <ErrorBanner
          testId="no-aicpa-uid-error-message"
          errorMessage="This account has no AICPA UID and orders cannot be created. Please refer to Salesforce and ensure a UID has been added"
        />
      )}
      {(shippingAddressIncomplete || billingAddressIncomplete) && (
        <ErrorBanner
          testId="no-aicpa-uid-error-message"
          errorMessage={`This account contains missing or invalid information and new orders cannot be created. Please update the following fields in Salesforce before proceeding: ${
            billingAddressIncomplete
              ? `Billing Address (${Object.entries(billingAddress)
                  .filter(e => e[1] === '')
                  .map(e => e[0])
                  .join(', ')})`
              : null
          } ${
            shippingAddressIncomplete
              ? `Shipping Address (${Object.entries(shippingAddress)
                  .filter(e => e[1] === '')
                  .map(e => e[0])
                  .join(', ')})`
              : null
          }`}
        />
      )}
      {!orderListLoading && (
        <ResultCounter
          count={orders?.length || 0}
          boldedText={`order${orders?.length !== 1 ? 's' : ''}`}
          normalText={`associated to this organization${orders?.length === 0 ? '.' : ''}`}
          className="table-header"
        />
      )}

      {!orderListLoading && taxExemption && <Label>Tax exempt status: Yes</Label>}

      <SearchInputWrapper>
        <OrdersTableSearchInput
          value={searchOrderInput}
          onChange={handleOrderSearch}
          type="text"
          placeholder="Search Orders"
          validValue={!Boolean(searchOrderInput) ? true : isOrderValid}
        />
        {isOrderValid && <StyledWrongIcon />}
        {!isOrderValid && Boolean(searchOrderInput) && (
          <>
            <StyledWrongIconError />
            <SearchErrorMessage>You must provide the exact order number.</SearchErrorMessage>
          </>
        )}
      </SearchInputWrapper>

      {orderListLoading ? (
        <TablePlaceholder />
      ) : orders?.length > 0 ? (
        <>
          <StyledTable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Order #</Table.HeaderCell>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.HeaderCell>Client Admin</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Total (Inc. Sales Tax)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isOrderValid
                ? sortedOrders
                    ?.filter((order: State.OrganizationOrder) => order.orderNumber === searchOrderInput)
                    .map((order: State.OrganizationOrder) => {
                      return (
                        <Table.Row key={order.orderNumber}>
                          <Table.Cell>
                            <Link
                              testId="test-link"
                              to={generatePath(getPath(Routes.ADMIN_ORDER_DETAILS), {
                                accountId,
                                accountNumber,
                                legalEntity,
                                orderNumber: order.orderNumber,
                              })}
                            >
                              {order.orderNumber || Orders.EMPTY_FIELD}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>{MomentHelpers.getDate(order.dateCreated) || Orders.EMPTY_FIELD}</Table.Cell>

                          <Table.Cell>
                            {order.clientAdmin || <StyledClientAdminText>{Orders.EMPTY_FIELD}</StyledClientAdminText>}
                          </Table.Cell>

                          <Table.Cell>{order.status || Orders.EMPTY_FIELD}</Table.Cell>
                          <Table.Cell>
                            {formatPrice(order.totalAmountIncTax, currency) || Orders.EMPTY_FIELD}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })
                : sortedOrders?.map((order: State.OrganizationOrder) => {
                    return (
                      <Table.Row key={order.orderNumber}>
                        <Table.Cell>
                          <Link
                            testId="test-link"
                            to={generatePath(getPath(Routes.ADMIN_ORDER_DETAILS), {
                              accountId,
                              accountNumber,
                              legalEntity,
                              orderNumber: order.orderNumber,
                            })}
                          >
                            {order.orderNumber || Orders.EMPTY_FIELD}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{MomentHelpers.getDate(order.dateCreated) || Orders.EMPTY_FIELD}</Table.Cell>

                        <Table.Cell>
                          {order.clientAdmin || <StyledClientAdminText>{Orders.EMPTY_FIELD}</StyledClientAdminText>}
                        </Table.Cell>

                        <Table.Cell>{order.status || Orders.EMPTY_FIELD}</Table.Cell>
                        <Table.Cell>{formatPrice(order.totalAmountIncTax, currency) || Orders.EMPTY_FIELD}</Table.Cell>
                      </Table.Row>
                    );
                  })}
            </Table.Body>
          </StyledTable>
        </>
      ) : !disableOrderButton ? (
        <>
          <StyledContainer>
            {!zuoraAccountId && (
              <>
                <StyledNoDataText>
                  Select your currency and click on the button to create a new <br />
                  order for this organization.
                </StyledNoDataText>
                <StyledText data-testid="Currency">Currency</StyledText>
                <StyledDropdown
                  testId="currency-type-dropdown"
                  fluid
                  search
                  selection
                  options={B2B.currencyOptions}
                  placeholder={'Select currency'}
                  onChange={onFieldChange}
                  defaultValue={{ key: 1, text: 'Dollars ($)', value: 'USD' }}
                  disabled={hasCurrentOrder}
                />
              </>
            )}
            <StyledButton
              size={ButtonEnums.sizes.medium}
              testId="create-order"
              variant={ButtonEnums.variants.primary}
              onClick={goToAddOrder}
              disabled={disableOrderButton}
            >
              {orderCtaText}
            </StyledButton>
          </StyledContainer>
        </>
      ) : !disableOrderButton ? (
        <>
          <StyledNoDataText>Select the button below to create a new order for this organization.</StyledNoDataText>
          <StyledButton
            size={ButtonEnums.sizes.medium}
            testId="create-order"
            variant={ButtonEnums.variants.primary}
            onClick={goToAddOrder}
            disabled={disableOrderButton}
          >
            {orderCtaText}
          </StyledButton>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const ErrorBanner: React.FC<{ testId: string; errorMessage: string }> = ({ testId, errorMessage }) => (
  <ErrorContainer>
    <ErrorIconWrapper>
      <StyledIcon />
    </ErrorIconWrapper>
    <ErrorMessage data-testid={testId}>{errorMessage}</ErrorMessage>
  </ErrorContainer>
);

const StyledNoDataText = styled.p`
  font-size: ${props => props.theme.pxToRem(18)};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-top: ${props => props.theme.pxToRem(16)};
  margin-bottom: ${props => props.theme.pxToRem(24)};
`;

const StyledTable = styled(Table)`
  &&&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(64)};
    margin-top: ${props => props.theme.pxToRem(24)};

    tr td {
      font-size: ${props => props.theme.fontSizes.xs};
    }
  }
`;

const StyledButton = styled(Button)`
  width: ${props => props.theme.pxToRem(173)};
`;

const OrdersTableSearchInput = styled(TableSearchInput)`
  &&&&&& {
    input {
      border-color: ${props => !props.validValue && props.theme.colors.interfaceRed} !important;
    }
  }
`;

const SearchInputWrapper = styled.div`
  margin: ${props => props.theme.pxToRem(30)} auto ${props => props.theme.pxToRem(10)} auto;

  input {
    width: ${props => props.theme.pxToRem(550)};
    max-width: ${props => props.theme.pxToRem(550)} !important;
  }
`;

const SearchErrorMessage = styled.div`
  min-height: ${props => props.theme.pxToRem(18)};
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.interfaceRed};
  margin: ${props => props.theme.pxToRem(3)} 0 auto ${props => props.theme.pxToRem(10)};
  line-height: ${props => props.theme.pxToRem(18)};
`;

const StyledWrongIconError = styled(Wrong)`
  &&&&&&& {
    z-index: 1000;
    position: relative;
    width: ${props => props.theme.pxToRem(24)};
    height: ${props => props.theme.pxToRem(24)};
    right: ${props => props.theme.pxToRem(35)};
    top: ${props => props.theme.pxToRem(8)};
    color: ${props => props.theme.colors.interfaceRed};
`;

const StyledWrongIcon = styled(Wrong)`
  &&&&&&& {
    z-index: 1000;
    position: relative;
    width: ${props => props.theme.pxToRem(24)};
    height: ${props => props.theme.pxToRem(24)};
    right: ${props => props.theme.pxToRem(35)};
    top: ${props => props.theme.pxToRem(8)};
    color: ${props => props.theme.colors.neutralGrey5};
`;

const ErrorContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.pxToRem(21)} 0;
  border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.interfaceRed};
  border-radius: ${props => props.theme.pxToRem(4)};
  margin-bottom: ${props => props.theme.pxToRem(24)};
  background-color: rgba(232, 49, 71, 0.1);
`;

const ErrorMessage = styled.div`
  padding-top: ${props => props.theme.pxToRem(3)};
  font-size: ${props => props.theme.fontSizes.xs};
`;

const ErrorIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  padding: 0 ${props => props.theme.pxToRem(14)};
`;

const StyledIcon = styled(IconError)`
  && {
    color: ${props => props.theme.colors.interfaceRed};
  }
`;

const StyledText = styled.div`
  max-width: ${props => props.theme.pxToRem(720)};
  font-weight: ${props => props.theme.fontWeights.regular};
  font-size: ${props => props.theme.pxToRem(16)};
  line-height: 1.71;
  color: ${props => props.theme.colors.neutralGrey8};
  padding-bottom: ${props => props.theme.pxToRem(10)};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
`;

const StyledClientAdminText = styled.div`
  color: ${props => props.theme.colors.neutralGrey6};
`;

const StyledDropdown = styled(Dropdown)`
  &&&&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(40)};
    padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(15)};
    width: ${props => props.theme.pxToRem(280)};
    height: ${props => props.theme.pxToRem(46)};
    display: flex;
    color: ${props => props.theme.colors.primaryPurple};
    border: ${props => props.theme.colors.neutralBlack} ${props => props.theme.pxToRem(1)} solid;
    .divider.text {
      color: ${props => props.theme.colors.primaryPurple}!important;
    }
  }
`;

const StyledContainer = styled(Container)`
  text-align: left;
  max-width: ${props => props.theme.pxToRem(640)};
`;
