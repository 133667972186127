import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Product, Orders } from 'mxp-schemas';
import { ProductsListParent } from 'components/molecules/ProductsList/ProductsList';
import { StyledLink } from './ProductItem';
import { DetailRowWrap } from './ProductItemDetails';
import { RenewalToggle } from './RenewalToggle';
import {
  arrayIncludes,
  getPath,
  MomentHelpers,
  formatPriceToTwoDecimalPoints,
  areAllTruthy,
  hasTruthyValue,
} from 'utils';
import { Routes } from 'constants/index';
import { isPhysicalProduct as isPhysicalProductCheck, User as UserUtils } from 'mxp-utils';
import { useSelector } from 'react-redux';
import { hasFcmaCredentialTermSelector } from 'modules/membership/selectors';
import { USE_AICPA_AR, USE_CIMA_AR } from 'modules/featureToggle/constants';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';

interface Props {
  item: Common.ProductItemData;
  parentPage: ProductsListParent;
  isSubscription: boolean;
  isCredential?: boolean;
  isSection?: boolean;
  isActiveProduct: boolean;
  isCartPage: boolean;
  availabilityDate: string;
  viewTextForAnalytics?: string;
  currentMemProductStatus?: State.QuotesStatusAndPaidByFirm | null;
  toggleSubscriptionRenewal?: (enable: boolean, item: Common.ProductItemData, productName: string) => void;
}

export const ProductItemSubscriptionDetails: React.FC<Props> = ({
  item,
  parentPage,
  isSubscription,
  isCredential,
  isSection,
  isActiveProduct,
  isCartPage,
  availabilityDate,
  viewTextForAnalytics,
  currentMemProductStatus,
  toggleSubscriptionRenewal,
}) => {
  const {
    subscriptionStatus,
    productType,
    sku,
    autoRenew,
    autoRenewEnabled,
    accessEndDate,
    cartLineItemState,
    orderNumber,
    sourceSystem,
    renewalPrice,
    shouldRemindRenewal,
    isExtended,
    lastExtendedOn,
  } = item;
  const isOrderConfirmationPage: boolean = parentPage === ProductsListParent.ORDER_CONFIRMATION;
  const isProfilePurchasesPage: boolean = parentPage === ProductsListParent.PURCHASES_PROFILE;

  const isItemRefunded: boolean = cartLineItemState === Orders.LineItemStates.CANCELLED_AND_DEPROVISIONED;
  const isLegacy: boolean = cartLineItemState === Orders.LineItemStates.LEGACYPROVISIONED;
  const isSubscriptionStatusCancelled: boolean = subscriptionStatus === Product.ZuoraSubscriptionStatus.CANCELLED;

  const isAwaitingShipment: boolean = cartLineItemState === Orders.LineItemStates.AWAITING_SHIPMENT;

  const availabilityFromOrderDate = MomentHelpers.formatExpiryDate(accessEndDate);
  const isPhSubscription: boolean = isPhysicalProductCheck(item).isPhSubscriptionProduct;
  const isPhSubscriptionMagazine: boolean = Boolean(
    item?.subscriptionProductType?.key === Product.SubscriptionProductType.PUBLICATION && item.showQuantity
  );
  const hasFcmaCredentialTerm = useSelector(hasFcmaCredentialTermSelector);

  const isPaidByFirm = useMemo(() => currentMemProductStatus?.isPaidByFirm, [currentMemProductStatus]);

  const renderLinkWhatToExpect = (text: string): React.ReactNode => (
    <StyledLink
      testId={`cart-what-to-expect-link-${productType}-${sku}`}
      to={getPath(Routes.WHAT_TO_EXPECT_FROM_SUBSCRIPTIONS)}
    >
      {text}
    </StyledLink>
  );

  const formattedRenewalPrice = React.useMemo(() => {
    if (renewalPrice) {
      return formatPriceToTwoDecimalPoints(renewalPrice);
    }
  }, [renewalPrice]);

  const textSubscription: string = 'Subscription Terms & Conditions';
  const textSubscriptionAndRenewals: string = 'What to expect with subscriptions and auto-renewals';

  const isBlueMartiniOrder = arrayIncludes(sourceSystem || null, Orders.SourceSystems.BLUE_MARTINI);
  const isNetforumOrder = arrayIncludes(sourceSystem || null, Orders.SourceSystems.NETFORUM);

  const isAccessOnlyOrder: boolean | undefined =
    orderNumber?.startsWith('ACC') || isBlueMartiniOrder || isNetforumOrder;

  const isActiveRaveSubscription = isActiveProduct && !isSubscriptionStatusCancelled && !isLegacy;

  const isActiveLegacy = isActiveProduct && isLegacy;

  const isExpiredSubscription = isSubscription && !isActiveRaveSubscription && !isActiveLegacy;
  const regularSubscription = isSubscription && !isItemRefunded && !isAccessOnlyOrder;

  const isLastAccessDay =
    !autoRenewEnabled && String(accessEndDate).startsWith(new Date().toISOString().substring(0, 10));

  const isActiveCredentialOrSection = (isCredential || isSection) && isActiveProduct;

  const renderCartPageDetails = (): React.ReactNode => {
    return (
      <DetailRowWrap>
        <DetailHead>Subscription</DetailHead>
        <DetailContent>
          {!!autoRenew && (
            <div>
              Your subscription will auto-renew on <StyledMediumText>{availabilityFromOrderDate}</StyledMediumText>.
            </div>
          )}
          <div>
            Manage your subscription anytime in your purchase history.
            <br />
            {renderLinkWhatToExpect(textSubscriptionAndRenewals)}
          </div>
        </DetailContent>
      </DetailRowWrap>
    );
  };

  const renderConfirmationPageDetails = (): React.ReactNode => {
    return (
      <DetailRowWrap>
        <DetailHead>Subscription</DetailHead>
        <DetailContent>
          {autoRenewEnabled && (
            <div>
              Your subscription will auto-renew on <StyledMediumText>{availabilityFromOrderDate}</StyledMediumText>.
            </div>
          )}
          {isPhSubscription && (
            <div>
              The first issue of your {item.title} subscription will ship in 6 to{' '}
              {isPhSubscriptionMagazine ? '10' : '13'} weeks.
            </div>
          )}

          <div>
            To manage your subscription, go to your&nbsp;
            <StyledLink
              target="_blank"
              testId={`purchases-link-${productType}-${sku}`}
              to={getPath(Routes.PROFILE_PURCHASES)}
              viewTextForAnalytics={viewTextForAnalytics}
            >
              Purchases
            </StyledLink>
            &nbsp;on your Profile.
          </div>
        </DetailContent>
      </DetailRowWrap>
    );
  };

  const renderPurchasesPageDetailsForExpired = (): React.ReactNode => {
    return (
      <>
        <DetailRowWrap>
          <DetailHead>Expired</DetailHead>
          <DetailContent>
            {isExtended && !item?.orderConfirmationAddress ? (
              <span>
                {`${availabilityDate}`}
                <ExtensionDetails>{` Extended till ${moment(lastExtendedOn).format(
                  'MMM DD, YYYY'
                )} `}</ExtensionDetails>
              </span>
            ) : (
              availabilityDate
            )}
          </DetailContent>
        </DetailRowWrap>
        {item?.orderConfirmationAddress && !isActiveProduct && (
          <DetailRowWrap>
            <DetailHead>Assignee</DetailHead>
            <DetailContent>
              <span>
                This product was assigned to you by <b>{item?.orderConfirmationAddress}</b>
              </span>
            </DetailContent>
          </DetailRowWrap>
        )}
        <DetailRowWrap>
          <DetailHead>Subscription</DetailHead>
          <DetailContent>
            <div>
              Your subscription has expired. Start a new subscription to resume your benefits.
              <StyledList>
                <li>Get direct access to content updates</li>
                <li>Cancel anytime</li>
              </StyledList>
            </div>
          </DetailContent>
        </DetailRowWrap>
      </>
    );
  };

  const renderPurchasesPageDetailsForActiveLegacy = (): React.ReactNode => {
    return (
      <DetailRowWrap>
        <DetailHead>Subscription</DetailHead>
        <DetailContent>
          <>
            <div>Active</div>
            <div>To renew your subscription, select "Buy again."</div>
          </>
        </DetailContent>
      </DetailRowWrap>
    );
  };

  const renderPurchasesPageDetailsForAwaitingShipment = (): React.ReactNode => {
    return (
      <DetailRowWrap>
        <DetailHead>Subscription</DetailHead>
        <DetailContent>
          <div>{renderLinkWhatToExpect(textSubscription)}</div>
          <div>
            The first issue of your {item.title} subscription will ship 6 to {isPhSubscriptionMagazine ? '10' : '13'}{' '}
            weeks after order date. Your card won’t be charged until shipment.
          </div>
        </DetailContent>
      </DetailRowWrap>
    );
  };

  const renderPurchasesPageDetailsForLastDayOfSubscription = (): React.ReactNode => (
    <DetailRowWrap>
      <DetailHead>Subscription</DetailHead>
      <DetailContent>
        You have opted to cancel auto-renewal. Today is the last day for product access. To resume your access, start a
        new subscription tomorrow.
      </DetailContent>
    </DetailRowWrap>
  );

  const renderToggleCommentWithAROn = (): React.ReactNode => (
    <div>
      Your subscription will auto-renew on&nbsp;
      <StyledMediumText>{availabilityDate}</StyledMediumText> and your default payment card will be charged. We’ll send
      a reminder email 30 days in advance.
    </div>
  );

  const renderToggleCommentWithAROnWithReminder = (): React.ReactNode => (
    <div>
      Your subscription will renew on <StyledMediumText>{availabilityDate}</StyledMediumText>, and your default card
      will be charged <StyledMediumText>{formattedRenewalPrice}</StyledMediumText>. You can review and update your
      default card in your wallet.
    </div>
  );

  const renderToggleCommentWithAROff = (): React.ReactNode => (
    <div>Turn on auto-renew to keep your access to this subscription.</div>
  );

  const renderToggleCommentWithAROffWithReminder = (): React.ReactNode => (
    <div>
      Your subscription is due to expire. If you turn auto-renew on for this subscription your default card will be
      charged <StyledMediumText>{formattedRenewalPrice}</StyledMediumText> on{' '}
      <StyledMediumText>{availabilityDate}</StyledMediumText>.
    </div>
  );

  const isAcmaOrCgmaCredential = [Product.CIMA_CREDENTIALS_SKU.ACMA, Product.CIMA_CREDENTIALS_SKU.CGMA].includes(
    item?.sku as Product.CIMA_CREDENTIALS_SKU
  );

  const isFcmaCredentialItem = areAllTruthy(item?.productLink === Product.FCMAProductSlug, hasFcmaCredentialTerm);
  const showAutoRenewToggle = areAllTruthy(
    !isPaidByFirm,
    item.title !== 'CIMA Membership in Practice',
    !isAcmaOrCgmaCredential,
    !isFcmaCredentialItem
  );

  const isTermPastDated = moment(item?.zuoraTermEndDate).isSameOrAfter(moment());
  const isCredentialOrSection = hasTruthyValue(
    item.productType === Product.ProductType.CREDENTIAL,
    item.productType === Product.ProductType.SECTION
  );
  const autoRenewCondition: boolean = UserUtils.conditionalFunction(
    item.membershipBody === Product.ProductMembershipBody.AICPA,
    areAllTruthy(
      useSelector(state => getFeatureToggleByKeySelector(state as State.Root, USE_AICPA_AR)),
      isTermPastDated
    ),
    areAllTruthy(
      useSelector(state => getFeatureToggleByKeySelector(state as State.Root, USE_CIMA_AR)),
      isTermPastDated
    )
  );

  const renderPurchasesPageDetailsForShippedOrDigital = (): React.ReactNode => {
    return (
      <>
        {showAutoRenewToggle && (
          <>
            <RenewalToggle
              item={item}
              productName={item.title}
              autoRenewEnabled={autoRenewEnabled!}
              renewalOnText="Auto-renew is active"
              renewalOffText="Auto-renew is inactive."
              toggleSubscriptionRenewal={toggleSubscriptionRenewal}
              disabled={isCredentialOrSection ? !autoRenewCondition : false}
            />

            <DetailRowWrap>
              <DetailContent>
                <StyledLeftPadded>
                  {autoRenewEnabled && shouldRemindRenewal && renderToggleCommentWithAROnWithReminder()}
                  {autoRenewEnabled && !shouldRemindRenewal && renderToggleCommentWithAROn()}
                  {!autoRenewEnabled && shouldRemindRenewal && renderToggleCommentWithAROffWithReminder()}
                  {!autoRenewEnabled && !shouldRemindRenewal && renderToggleCommentWithAROff()}
                  <div>By selecting auto-renew, you agree to the {renderLinkWhatToExpect(textSubscription)}</div>
                </StyledLeftPadded>
              </DetailContent>
            </DetailRowWrap>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {isCartPage && isSubscription && renderCartPageDetails()}
      {isOrderConfirmationPage && isSubscription && renderConfirmationPageDetails()}
      {isProfilePurchasesPage && (
        <>
          {isExpiredSubscription && renderPurchasesPageDetailsForExpired()}
          {isActiveLegacy && renderPurchasesPageDetailsForActiveLegacy()}
          {((regularSubscription && isActiveRaveSubscription) || isActiveCredentialOrSection) && (
            <>
              {isAwaitingShipment && isPhSubscription && renderPurchasesPageDetailsForAwaitingShipment()}
              {!isAwaitingShipment &&
                (isLastAccessDay
                  ? renderPurchasesPageDetailsForLastDayOfSubscription()
                  : renderPurchasesPageDetailsForShippedOrDigital())}
            </>
          )}
        </>
      )}
    </>
  );
};

const DetailHead = styled.div`
  width: ${props => props.theme.pxToRem(95)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};
  height: ${props => props.theme.pxToRem(18)};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
  flex-shrink: 0;
`;

const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: ${props => props.theme.pxToRem(200)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
`;

const StyledMediumText = styled.div`
  display: inline;
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledLeftPadded = styled.div`
  padding-left: ${props => props.theme.pxToRem(53)};
  flex-grow: 1;
`;

const StyledList = styled.ul`
  padding-left: ${props => props.theme.pxToRem(16)};
  margin: ${props => props.theme.pxToRem(3)} 0;
`;

const ExtensionDetails = styled.span`
  color: ${props => props.theme.colors.interfaceGreen};
  font-weight: ${props => props.theme.fontWeights.bold};
`;
