import { EventDescribor, UTAG_TYPES } from './types';
import {
  getContentId,
  getMembershipJourney,
  getPageNameAccessor,
  getPageNameAndSiteSection,
  getSelectedPaymentMethod,
  getSessionCampaignIdAccessors,
  isMembershipRenewalHandler,
} from './helpers';
import { ContentAnalyticsConsts, lockedContentAccessors } from 'modules/content/analytics';
import { LayoutAnalyticsConsts } from 'modules/layouts/analytics';
import { ProductAnalyticsConsts } from 'modules/products/analytics';
import { SearchAnalyticsConsts } from 'modules/search/analytics';
import { UserAnalyticsConsts } from 'modules/user/analytics';
import { InquiryFormAnalyticsConsts } from 'modules/inquiryForm/analytics';
import { MembershipAnalyticsConsts } from 'modules/membership/analytics';
import { userPrefSelector, isAuthSelector } from 'modules/user/selectors';
import { getLocation } from 'connected-react-router';
import { Routes, UserHomepage } from 'constants/index';
import { getPath } from 'utils/routes';
import { store } from '../../store';
import { pageStepSelector } from 'modules/checkout/selectors';

export const CARD_EVENT = 'cardClick';
export const SEARCH_CARD_EVENT = 'cardClick_search';
export const TRENDING_EVENT = 'trendingClick';
export const TOP_MY_SAVED_EVENT = 'top_my_saved_click';
export const HEADER_EVENT = 'header_click';
export const FOOTER_EVENT = 'footer_click';
export const EXTERNAL_LINK_EVENT = 'external_link';
export const PASSWORD_RESET_LINK = 'password_reset_link';
export const HEADER_EXTERNAL_LINK = 'header_external_link';
export const SURVEY_FEEDBACK_REVIEW = 'survey_feedback_review';
export const SURVEY_FEEDBACK_SUBMIT = 'survey_feedback_submit';
export const ADD_SAVED_ITEM = 'add_saved_item';
export const REMOVE_SAVED_ITEM = 'remove_saved_item';
export const SEARCH_CORRECTION = 'search_correction';
export const SEARCH_FACET_UPDATE = 'search_facet_update';
export const SEARCH_PRODUCT_VIEW = 'search_product_view';
export const FEED_PRODUCT_VIEW = 'feed_product_view';
export const FEED_FACET_UPDATE = 'feed_facet_update';
export const AGGREGATION_PRODUCT_VIEW = 'aggregation_product_view';
export const DOWNLOAD_EVENT = 'download';
export const POPULAR_SEARCHES_LINK_EVENT = 'popular_searches_link_event';
export const CART_VIEW_EVENT = 'cart_view';
export const PAYMENT_CHECKOUT = 'payment_checkout';
export const PURCHASE_BUTTON_COMPLETE = 'purchase_button_complete';
export const PURCHASE_COMPLETE = 'purchase_complete';
export const ADD_PAYMENT_INFO = 'add_payment_info';
export const CART_REMOVE_EVENT = 'cart_remove';
export const CART_REMOVE_BUNDLE_EVENT = 'cart_remove_bundle';
export const CART_CHECKOUT_EVENT = 'cart_checkout';
export const TOOLKIT_VIEW = 'toolkit_view';
export const CLICK = 'click';
export const ADD_CART_FIRST = 'add_cart_first';
export const ADD_CART = 'add_cart';
export const PRODUCT_VIEW = 'product_view';
export const SUBSCRIPTION_UPDATE = 'subscription_button_click';
export const NAV_CLICK = 'nav_click';
export const EVENT_CLICK = 'event_click';
export const INVOICE_PAYMENT_CHECKOUT = 'invoice_payment_checkout';
export const INVOICE_PAYMENT_BUTTON_COMPLETE = 'invoice_payment_button_complete';
export const INVOICE_PAYMENT_COMPLETE = 'invoice_payment_complete';
export const CART_UPDATE_EVENT = 'cart_update_event';
export const PRINT = 'print';
export const ERROR = 'error';

export const PREFIXES = {
  HEADER: 'header',
  FOOTER: 'footer',
};

const handleMembershipRenewalAccessor = [
  {
    name: 'is_membership_renewal',
    path: '',
    processor: isMembershipRenewalHandler,
  },
];

const cardEventAccessors = [
  {
    name: 'card_select',
    path: 'card',
  },
  {
    name: 'card_description',
    path: 'description',
  },
  {
    name: 'click_referring_url',
    path: 'url',
  },
];

const cartEventAccessors = [
  {
    name: 'product_name',
    path: 'name',
  },
  {
    name: 'product_category',
    path: 'category',
  },
  {
    name: 'product_id',
    path: 'id',
  },
  {
    name: 'product_bundle_id',
    path: 'bundleId',
  },
  {
    name: 'product_bundle_sku',
    path: 'bundleSku',
  },
  {
    name: 'free_trial_end_date',
    path: 'freeTrialEndDate',
  },
  {
    name: 'product_sku',
    path: 'sku',
  },
  {
    name: 'product_tax',
    path: 'productTax',
  },
  {
    name: 'product_quantity',
    path: 'quantity',
  },
  {
    name: 'product_price',
    path: 'price',
  },
  {
    name: 'cart_amount',
    path: 'cartAmount',
  },
  {
    name: 'discount_amount',
    path: 'discountAmount',
  },
  {
    name: 'discount_reason',
    path: 'discountReason',
  },
  {
    name: 'click',
    path: 'click',
  },
  {
    name: 'product_order_type',
    path: 'orderType',
  },
  ...handleMembershipRenewalAccessor,
];

const shippingOptionAccessors = [
  {
    name: 'product_shipping_type',
    path: 'shippingType',
  },
  {
    name: 'product_shipping_location',
    path: 'shippingLocation',
  },
  {
    name: 'product_shipping_cost',
    path: 'shippingCost',
  },
  {
    name: 'product_tax',
    path: 'productTax',
  },
];

const productViewAccessors = [
  {
    name: 'product_category',
    path: 'category',
  },
  {
    name: 'product_id',
    path: 'id',
  },
  {
    name: 'product_quantity',
    path: 'quantity',
  },
  {
    name: 'product_price',
    path: 'price',
  },
  {
    name: 'product_name',
    path: 'name',
  },
  {
    name: 'product_location',
    path: 'location',
  },
  // premium content
  {
    name: 'content_accessed',
    path: 'aicpaContentAccessed',
  },
  {
    name: 'content_topics',
    path: 'topics',
  },
  {
    name: 'gated_content',
    path: 'aicpaGatedContent',
  },
];
const productViewMembershipAccessors = [
  {
    name: 'page_name',
    path: 'pageName',
  },
  {
    name: 'product_category',
    path: 'category',
  },
  {
    name: 'product_id',
    path: 'id',
  },

  {
    name: 'product_price',
    path: 'price',
  },
  {
    name: 'product_name',
    path: 'name',
  },
  {
    name: 'product_sku',
    path: 'sku',
  },
];

const productCartAccessors = [
  {
    name: 'product_name',
    path: 'name',
  },
  {
    name: 'product_category',
    path: 'category',
  },
  {
    name: 'product_id',
    path: 'id',
  },
  {
    name: 'product_bundle_id',
    path: 'bundleId',
  },
  {
    name: 'product_bundle_sku',
    path: 'bundleSku',
  },
  {
    name: 'free_trial_end_date',
    path: 'freeTrialEndDate',
  },
  {
    name: 'product_order_type',
    path: 'orderType',
  },
  {
    name: 'product_price',
    path: 'price',
  },
  {
    name: 'product_sku',
    path: 'sku',
  },
  {
    name: 'product_tax',
    path: 'productTax',
  },
  ...handleMembershipRenewalAccessor,
];

const productPaymentAccessors = [
  ...productCartAccessors,
  ...shippingOptionAccessors,
  {
    name: 'cart_amount',
    path: 'cartAmount',
  },
  {
    name: 'coupons_codes',
    path: 'couponsCodes',
  },
  {
    name: 'payment_type',
    path: 'paymentType',
  },
  {
    name: 'discount_amount',
    path: 'discountAmount',
  },
  {
    name: 'product_quantity',
    path: 'quantity',
  },
  {
    name: 'discount_reason',
    path: 'discountReason',
  },
  {
    name: 'order_number',
    path: 'orderNumber',
  },
  {
    name: 'card_payment_type',
    path: 'cardPaymentType',
  },
];

const invoicePaymentAccessors = [
  ...productCartAccessors,
  {
    name: 'payment_type',
    path: 'paymentType',
  },
  {
    name: 'discount_amount',
    path: 'discountAmount',
  },
  {
    name: 'product_quantity',
    path: 'quantity',
  },
  {
    name: 'coupon_codes',
    path: 'couponCodes',
  },
  {
    name: 'product_shipping_type',
    path: 'shippingType',
  },
  {
    name: 'product_shipping_cost',
    path: 'shippingCost',
  },
  {
    name: 'product_tax',
    path: 'productTax',
  },
  {
    name: 'card_payment_type',
    path: 'cardPaymentType',
  },
];

// attribute to get all products within a bundle
const allProductsData = [
  {
    name: 'product_bundle_ids',
    path: 'productBundleIds',
  },
  {
    name: 'product_bundle_skus',
    path: 'productBundleSkus',
  },
];

export const EVENTS_MAP: {
  [key: string]: EventDescribor;
} = {
  // non-redux events
  // TODO Ideally none of these would exist - all analytics should come from user interaction, thus from redux actions
  [CART_VIEW_EVENT]: {
    name: CART_VIEW_EVENT,
    accessors: [...getPageNameAccessor(), ...cartEventAccessors, ...shippingOptionAccessors, ...allProductsData],
    type: UTAG_TYPES.VIEW,
  },

  [CART_REMOVE_EVENT]: {
    name: CART_REMOVE_EVENT,
    accessors: [...cartEventAccessors, ...allProductsData],
  },
  [CART_REMOVE_BUNDLE_EVENT]: {
    name: CART_REMOVE_BUNDLE_EVENT,
    accessors: [...cartEventAccessors, ...allProductsData],
  },
  [CART_CHECKOUT_EVENT]: {
    name: CART_CHECKOUT_EVENT,
    accessors: [
      ...cartEventAccessors,
      ...shippingOptionAccessors,
      {
        name: 'page_name',
        path: '',
        processor: a => {
          if (a?.pageName) return a.pageName;
          const state: State.Root = store?.getState();
          const pageStep = pageStepSelector(state);
          const { pageName } = getPageNameAndSiteSection();
          return `${pageName}:${pageStep?.toLowerCase()}`;
        },
      },
      {
        name: 'site_section',
        path: '',
        processor: a => {
          if (a?.siteSection) return a.siteSection;
          const { siteSection } = getPageNameAndSiteSection();
          return siteSection;
        },
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  [PAYMENT_CHECKOUT]: {
    name: PAYMENT_CHECKOUT,
    accessors: [
      ...productPaymentAccessors,
      {
        name: 'payment_method_selected',
        path: '',
        processor: getSelectedPaymentMethod,
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  [PURCHASE_BUTTON_COMPLETE]: {
    name: PURCHASE_BUTTON_COMPLETE,
    accessors: productPaymentAccessors,
  },
  [PURCHASE_COMPLETE]: {
    name: PURCHASE_COMPLETE,
    accessors: productPaymentAccessors,
    type: UTAG_TYPES.VIEW,
  },
  [ADD_PAYMENT_INFO]: {
    name: ADD_PAYMENT_INFO,
    accessors: [...getPageNameAccessor()],
  },
  [CLICK]: {
    name: CLICK,
    accessors: [
      ...getPageNameAccessor(),
      {
        name: 'link_text',
        path: 'value',
      },
      {
        name: 'link_target',
        path: 'href',
      },
      {
        name: 'click',
        path: 'clickValue',
      },
      {
        name: 'product_search',
        path: 'searchFilter',
      },
    ],
  },
  [NAV_CLICK]: {
    name: NAV_CLICK,
    hideNulls: ['coupon_code', 'membership_journey', 'is_membership_renewal'],
    accessors: [
      ...getPageNameAccessor(),
      {
        name: 'page_name',
        path: '',
        processor: (a): any => {
          if (a?.pageName) return a.pageName;
          const { pageName } = getPageNameAndSiteSection();
          return pageName;
        },
      },
      {
        name: 'site_section',
        path: '',
        processor: (a): any => {
          if (a?.siteSection) return a.siteSection;
          const { siteSection } = getPageNameAndSiteSection();
          return siteSection;
        },
      },
      {
        name: 'click',
        path: 'clickValue',
      },
      {
        name: 'coupon_code',
        path: 'couponCode',
      },
      {
        name: 'product_search',
        path: 'searchFilter',
      },
      {
        name: 'content_topics',
        path: 'topics',
      },
      {
        name: 'membership_journey',
        path: '',
        processor: getMembershipJourney,
      },
      ...handleMembershipRenewalAccessor,
    ],
  },
  [EVENT_CLICK]: {
    name: EVENT_CLICK,
    hideNulls: ['membership_journey'],
    accessors: [
      {
        name: 'click',
        path: 'clickValue',
      },
      {
        name: 'module_version',
        path: 'moduleVersion',
      },
      {
        name: 'card_payment_type',
        path: 'cardPaymentType',
      },
      {
        name: 'membership_journey',
        path: '',
        processor: getMembershipJourney,
      },
      ...handleMembershipRenewalAccessor,
    ],
  },
  [CARD_EVENT]: {
    name: CARD_EVENT,
    accessors: cardEventAccessors,
  },
  [ADD_CART_FIRST]: {
    name: ADD_CART_FIRST,
    accessors: [
      ...productCartAccessors,
      ...allProductsData,
      {
        name: 'product_is_upsell',
        path: '',
        processor: a => a.isUpsell || false,
      },
    ],
  },
  [ADD_CART]: {
    name: ADD_CART,
    accessors: [
      ...productCartAccessors,
      ...allProductsData,
      {
        name: 'product_is_upsell',
        path: '',
        processor: a => a.isUpsell || false,
      },
    ],
  },
  [PRODUCT_VIEW]: {
    name: PRODUCT_VIEW,
    accessors: [...getPageNameAccessor(), ...productViewMembershipAccessors],
  },
  [SEARCH_CARD_EVENT]: {
    name: SEARCH_CARD_EVENT,
    accessors: cardEventAccessors,
  },
  [PASSWORD_RESET_LINK]: {
    name: PASSWORD_RESET_LINK,
    accessors: [
      {
        name: 'external_link_text',
        path: 'text',
      },
      {
        name: 'external_link_target',
        path: 'href',
      },
    ],
  },
  [SUBSCRIPTION_UPDATE]: {
    name: SUBSCRIPTION_UPDATE,
    accessors: [
      {
        name: 'click',
        path: 'click',
      },
    ],
  },
  [HEADER_EXTERNAL_LINK]: {
    name: HEADER_EXTERNAL_LINK,
    accessors: [
      {
        name: 'header_navigate',
        path: 'text',
      },
    ],
  },
  [HEADER_EVENT]: {
    name: HEADER_EVENT,
    accessors: [
      ...getPageNameAccessor(),
      {
        name: 'click',
        path: 'clickValue',
      },
      {
        name: 'header_event',
        path: 'value',
      },
      {
        name: 'path',
        path: 'href',
      },
    ],
  },
  [FOOTER_EVENT]: {
    name: FOOTER_EVENT,
    accessors: [
      ...getPageNameAccessor(),
      {
        name: 'click',
        path: 'clickValue',
      },
      {
        name: 'footer_event',
        path: 'value',
      },
      {
        name: 'path',
        path: 'href',
      },
    ],
  },
  [TRENDING_EVENT]: {
    name: TRENDING_EVENT,
    accessors: [
      ...getPageNameAccessor(),
      {
        name: 'trending_link_text',
        path: 'text',
      },
      {
        name: 'trending_link_target',
        path: 'href',
      },
    ],
  },
  [TOP_MY_SAVED_EVENT]: {
    name: TOP_MY_SAVED_EVENT,
    accessors: [
      {
        name: 'top_my_saved_link_text',
        path: 'text',
      },
    ],
  },
  [EXTERNAL_LINK_EVENT]: {
    name: EXTERNAL_LINK_EVENT,
    accessors: [
      {
        name: 'external_link_text',
        path: 'text',
      },
      {
        name: 'external_link_target',
        path: 'href',
      },
      {
        name: 'external_link_full',
        path: '',
        processor: a => {
          const { pageName } = getPageNameAndSiteSection();
          return `${pageName}:${a?.buttonText || a?.text}:${a?.href}`;
        },
      },
    ],
  },
  [SURVEY_FEEDBACK_REVIEW]: {
    name: SURVEY_FEEDBACK_REVIEW,
    accessors: [
      {
        name: 'survey_feedback_score',
        path: 'score',
      },
    ],
  },
  [SURVEY_FEEDBACK_SUBMIT]: {
    name: SURVEY_FEEDBACK_SUBMIT,
    accessors: [
      {
        name: 'survey_feedback_score',
        path: 'score',
      },
      {
        name: 'survey_feedback',
        path: 'feedback',
        processor: a => a.join('|'),
      },
    ],
  },
  [ADD_SAVED_ITEM]: {
    name: ADD_SAVED_ITEM,
    accessors: [
      {
        name: 'item_saved',
        path: 'item',
      },
      {
        name: 'item_saved_description',
        path: 'description',
      },
      {
        name: 'item_saved_url',
        path: 'url',
      },
    ],
  },
  [REMOVE_SAVED_ITEM]: {
    name: REMOVE_SAVED_ITEM,
    accessors: [
      {
        name: 'item_saved',
        path: 'item',
      },
      {
        name: 'item_saved_description',
        path: 'description',
      },
      {
        name: 'item_saved_url',
        path: 'url',
      },
    ],
  },
  [SEARCH_CORRECTION]: {
    name: SEARCH_CORRECTION,
    accessors: [
      {
        name: 'search_corrected_value',
        path: 'value',
      },
    ],
  },
  [SEARCH_FACET_UPDATE]: {
    name: SEARCH_FACET_UPDATE,
    accessors: [
      {
        name: 'search_facets',
        path: 'facets',
      },
    ],
  },
  [FEED_FACET_UPDATE]: {
    name: FEED_FACET_UPDATE,
    accessors: [
      {
        name: 'feed_facets',
        path: 'facets',
      },
    ],
  },
  [DOWNLOAD_EVENT]: {
    name: DOWNLOAD_EVENT,
    accessors: [
      {
        name: 'download_filename',
        path: 'filename',
      },
      {
        name: 'content_id',
        path: '',
        processor: getContentId,
      },
    ],
  },
  [POPULAR_SEARCHES_LINK_EVENT]: {
    name: 'aicpa_search_click_noresults_popular_items',
    accessors: [
      {
        name: 'popular_searches_link_text',
        path: 'text',
      },
      {
        name: 'popular_searches_link_target',
        path: 'href',
      },
    ],
  },
  [SEARCH_PRODUCT_VIEW]: {
    name: SEARCH_PRODUCT_VIEW,
    accessors: productViewAccessors,
  },
  [FEED_PRODUCT_VIEW]: {
    name: FEED_PRODUCT_VIEW,
    accessors: productViewAccessors,
  },
  [AGGREGATION_PRODUCT_VIEW]: {
    name: AGGREGATION_PRODUCT_VIEW,
    accessors: productViewAccessors,
  },
  [INVOICE_PAYMENT_CHECKOUT]: {
    name: INVOICE_PAYMENT_CHECKOUT,
    accessors: invoicePaymentAccessors,
  },
  [INVOICE_PAYMENT_BUTTON_COMPLETE]: {
    name: INVOICE_PAYMENT_BUTTON_COMPLETE,
    accessors: invoicePaymentAccessors,
  },
  [INVOICE_PAYMENT_COMPLETE]: {
    name: INVOICE_PAYMENT_COMPLETE,
    accessors: invoicePaymentAccessors,
  },
  [CART_UPDATE_EVENT]: {
    name: CART_UPDATE_EVENT,
    accessors: cartEventAccessors,
  },
  [PRINT]: {
    name: PRINT,
    accessors: [
      {
        name: 'print',
        path: 'print',
      },
    ],
  },
  [ERROR]: {
    name: ERROR,
    accessors: [
      {
        name: 'error',
        path: 'error',
      },
    ],
  },
  // Redux events
  '@@router/LOCATION_CHANGE': {
    name: 'page_view',
    hideNulls: ['is_membership_renewal', 'membership_journey'],
    accessors: [
      ...getPageNameAccessor(),
      ...lockedContentAccessors(),
      ...getSessionCampaignIdAccessors(),
      {
        name: 'site_property',
        path: 'false',
        default: 'rave plus',
      },
      {
        name: 'homepage_content',
        path: '',
        default: UserHomepage.UNKNOWN,
        processor: (a, state: State.Root) => {
          const userPreferences = userPrefSelector(state);
          const { pathname: currentPathname } = getLocation(state);
          const isLoggedIn = isAuthSelector(state);
          return isLoggedIn === null
            ? UserHomepage.UNKNOWN
            : currentPathname === getPath(Routes.HOME, { withoutParams: true })
            ? UserHomepage.LOGGED_OUT
            : currentPathname === getPath(Routes.FEED) && Object.keys(userPreferences).length
            ? UserHomepage.ONBOARDED
            : UserHomepage.NOT_ONBOARDED;
        },
      },
      {
        name: 'category',
        path: '',
        processor: (a, b) =>
          `${b?.router?.location?.pathname?.split('/')[1] || window.location.pathname.split('/')[1]}`,
      },
      {
        name: 'membership_journey',
        path: '',
        processor: getMembershipJourney,
      },
      ...handleMembershipRenewalAccessor,
    ],
    type: UTAG_TYPES.VIEW,
  },
  'copy/COPY_ITEM': {
    name: 'copy_event',
    accessors: [
      {
        name: 'copy_url',
        path: 'copiedText',
      },
    ],
  },
  ...ContentAnalyticsConsts,
  ...LayoutAnalyticsConsts,
  ...ProductAnalyticsConsts,
  ...SearchAnalyticsConsts,
  ...UserAnalyticsConsts,
  ...InquiryFormAnalyticsConsts,
  ...MembershipAnalyticsConsts,
};
