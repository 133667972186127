import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { PageCenterAdminSeatsManagement } from 'components/pages/PageCenterAdminSeatsManagement/PageCenterAdminSeatsManagement';
import {
  fetchContractLineItems,
  setContractLineItemModifiers,
  getCenterMemberships,
  getServiceContracts,
  removeContractLineItems,
} from 'modules/centerAdmin/index';
import {
  contractLineItemListSelector,
  contractLineItemModifiersSelector,
  contractLineItemIsFetchedSelector,
  selectedCenterMembershipSelector,
} from 'modules/centerAdmin/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  getServiceContracts(): void {
    dispatch(getServiceContracts())
      .then(() => {
        dispatch(fetchContractLineItems()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
      })
      .catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
  fetchContractLineItems(): void {
    dispatch(fetchContractLineItems()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
  removeContractLineItems(lineItemIds: string[], emails: string[]): void {
    dispatch(removeContractLineItems(lineItemIds, emails));
  },
  setContractLineItemModifiers(modifiers: { query?: string; offset?: number; limit?: number }): void {
    dispatch(setContractLineItemModifiers(modifiers));
  },
  getCenterMemberships(organizationId: string): void {
    dispatch(getCenterMemberships({ organizationId, membershipStatus: 'active' })).catch(() =>
      dispatch(push(getPath(Routes.NOT_FOUND)))
    );
  },
});

const mapStateToProps = (state: State.Root) => ({
  selectedCenterMembership: selectedCenterMembershipSelector(state),
  contractLineItemList: contractLineItemListSelector(state),
  contractLineItemModifiers: contractLineItemModifiersSelector(state),
  contractLineItemIsFetched: contractLineItemIsFetchedSelector(state),
});

export const PageCenterAdminSeatsManagementContainer = connect(
  mapStateToProps,
  mapActionCreators
)(PageCenterAdminSeatsManagement);
