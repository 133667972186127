import React from 'react';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { useGoogleAds } from 'hooks/useGoogleAds';
import { ModalOnboardingFinishContainer } from 'containers/ModalOnboardingFinishContainer';
import { TermsAndConditions } from 'components/pages/PageToolkit/TermsAndConditions';
import { NotFound } from 'components/App/lazy-imports';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper';
import { AggregationHero } from 'components/organisms/AggregationHero';
import { Footer } from 'components/molecules/Footer';
import { SocialSharing } from 'components/molecules/SocialSharing/SocialSharing';
import { PageHelmet } from 'components/atoms';
import { Scroller, emptyArray, emptyObject, isMobileViewPort } from 'utils';
import { TOOLKIT_VIEW, handleEvent } from 'utils/Analytics';
import { BlockToolkitPageRenderer } from 'components/organisms/BlockRenderer/BlockToolkitPageRenderer';
import { ExternalAdBanner, ExternalAdBannerType } from 'components/organisms/ExternalAdBanner/ExternalAdBanner';

export interface Props {
  loading: boolean;
  isContentFetched: boolean | null;
  isSubtopicPage: boolean;
  topicSlug: string;
  topicName: string;
  subtopicSlug: string;
  subtopicId: string;
  topic: State.Topic | null;
  subtopic: State.Subtopic | null;
  hasShortcut: boolean;
  toolkitPageContent: State.ToolkitPageContent | null;
  slug: string;
  burgerExpanded: boolean;
  isAuth: boolean;
  isUserOnBoarded: boolean;
  fetchToolkitPageContent(slug: string): void;
  toggleUserShortcuts: ({ topicSlug, isOnEvent, subtopicSlug }: Common.ToggleUserShortcutsProps) => void;
  resetCurrentContentId(): void;
  toggleLoginReload(shouldReload: boolean): void;
  contentIsExclusive: Common.ContentExclusive;
}

export enum ContentTypes {
  promotionalComponent = 'promotionalComponent',
  lists = 'lists',
  featuredContentBlock = 'featuredContentBlock',
  toolkitPageBlock = 'toolkitPageBlock',
  link = 'link',
  table = 'table',
  advancedTable = 'advancedTable',
  listBlock = 'blockList',
}

export const PageToolkit = ({
  loading,
  isContentFetched,
  topicSlug,
  topicName,
  subtopicSlug,
  subtopicId,
  hasShortcut,
  toggleUserShortcuts,
  toolkitPageContent,
  resetCurrentContentId,
  slug,
  burgerExpanded,
  toggleLoginReload,
  isAuth,
  isUserOnBoarded,
  contentIsExclusive,
}: Props) => {
  useGoogleAds(toolkitPageContent?.externalAdsConfig);
  const targetContainerRef: any = React.useRef();
  const containerContentRef: any = React.useRef();
  const isMobile: boolean = isMobileViewPort();

  React.useEffect(() => {
    if (slug) handleEvent(emptyObject, TOOLKIT_VIEW);
  }, [slug]);

  // Component did mount effect
  React.useEffect(() => {
    // Browser Back button protection. FixMe. CONTENT_PAGE Route must be switched to unique url
    if (slug) {
      Scroller.scrollToTarget(targetContainerRef);
      Scroller.scrollToTarget(containerContentRef);
    }
  }, [slug]);

  const handleShortcutClick = React.useCallback((): void => {
    // TODO BAD_SHORTCUTS this is a hack don't do this at home kids!
    // we should have a universal shortcut system, not this
    toggleUserShortcuts({
      topicSlug: toolkitPageContent?.title || '',
      isOnEvent: !hasShortcut,
      subtopicSlug: `${toolkitPageContent?.contentCategory?.slug || ''}/$TOOLKIT$/${slug}`,
    });
  }, [slug, toolkitPageContent, toggleUserShortcuts, hasShortcut]);

  const handleToggleLoginReload = (shouldReload: boolean) => {
    toggleLoginReload(shouldReload);
  };

  // Component will unmount effect
  React.useEffect(() => resetCurrentContentId, []); // eslint-disable-line react-hooks/exhaustive-deps

  const leftColumn: any[] = toolkitPageContent?.leftColumn || emptyArray;
  const mainColumn: State.ToolkitMainColumn[] = toolkitPageContent?.mainColumn || emptyArray;

  const renderRunningToolkitPageBlocks = (): React.ReactNode => {
    const blockRender = {
      mainColumn,
      leftColumn,
      topicSlug,
      topicName,
      subtopicSlug,
      loading,
      hasContent,
      targetContainer: targetContainerRef,
      containerContentRef,
      isMobile,
      isAuth,
      isUserOnBoarded,
      contentIsExclusive,
    };
    return <BlockToolkitPageRenderer blockRenderToolkitPages={blockRender} />;
  };

  if (!toolkitPageContent) {
    return !isContentFetched ? (
      <StyledContentLoading>
        <Loader active />
      </StyledContentLoading>
    ) : (
      <NotFound />
    );
  }

  const { title, description, topics } = toolkitPageContent;
  const firstTopic: State.TopicOption = topics?.length ? topics[0] : { name: '', id: '', slug: '', description: '' };
  const hasContent: boolean = Boolean(toolkitPageContent);

  const renderPage = (): React.ReactNode => {
    return (
      <HeaderPageWrapper contentRef={targetContainerRef} contentInnerRef={containerContentRef}>
        {loading && !isContentFetched ? (
          <StyledContentLoading>
            <Loader active />
          </StyledContentLoading>
        ) : (
          <div>
            <PageHelmet
              meta={{ title: toolkitPageContent.title, description: toolkitPageContent.description }}
              openGraph={{
                title: toolkitPageContent.title,
                description: toolkitPageContent.description,
                passUrl: true,
              }}
              isNoIndex={toolkitPageContent.noIndex}
            >
              <title>{toolkitPageContent && toolkitPageContent.title} | AICPA & CIMA</title>
            </PageHelmet>
            {toolkitPageContent?.externalAdsConfig?.topAdBanner && <ExternalAdBanner type={ExternalAdBannerType.TOP} />}
            <AggregationHero
              topicId={firstTopic.id}
              topicSlug={firstTopic.slug}
              topicName={firstTopic.name}
              subtopicId={subtopicId}
              subtopicSlug={slug}
              subtopicName={title}
              topicSummary={description}
              hasShortcut={hasShortcut}
              isSubtopicPage
              isToolkitPage
              showCopyLinkButton
              onShortcutClick={handleShortcutClick}
              toggleLoginReload={handleToggleLoginReload}
            />
            {!burgerExpanded && renderRunningToolkitPageBlocks()}
            <TermsAndConditions
              title="Terms and Conditions for Readers"
              text={toolkitPageContent.toolkitTermsAndConditions}
            />
            {toolkitPageContent?.externalAdsConfig?.bottomAdBanner && (
              <ExternalAdBanner type={ExternalAdBannerType.BOTTOM} />
            )}
            <Footer />
            <SocialSharing title={toolkitPageContent.title} isSticky />
          </div>
        )}
      </HeaderPageWrapper>
    );
  };

  return (
    <>
      <StyledDiv>{renderPage()}</StyledDiv>
      <ModalOnboardingFinishContainer />
    </>
  );
};

const StyledContentLoading = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -${props => props.theme.pxToRem(89)};
  font-size: ${props => props.theme.fontSizes.l};
`;

const StyledDiv = styled.div`
  ${props => props.theme.mediaQueries.desktopOnly} {
    height: ${props => props.theme.pxToRem(1)};
    min-height: 100%;
  }
`;
