import React from 'react';
import { Modal } from 'components/atoms/Modal/Modal';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import styled from 'styled-components';

interface Props {
  onCancel: () => void;
  onConfirmation: () => void;
}

export const FcmaEthicsModal: React.FC<Props> = ({ onCancel, onConfirmation }) => (
  <StyledModal
    showCloseCross={true}
    open={true}
    onClose={onCancel}
    heading="This purchase is currently unavailable"
    testId="fcma-ethics-modal"
    confirmNode={
      <StyledButton
        size={ButtonEnums.sizes.small}
        variant={ButtonEnums.variants.primary}
        testId="fcma-continue-button"
        onClick={onConfirmation}
      >
        I understand
      </StyledButton>
    }
  >
    <StyledParagraph>
      We are unable to process your application further. <br />
      For more information, select the chat icon to the right of your screen, or&nbsp;
      <a href="https://www.aicpa.org/about/help" target={'_blank'} rel="noopener noreferrer">
        visit our help page
      </a>
      &nbsp; for frequently asked questions and other contact information.
    </StyledParagraph>
  </StyledModal>
);

const StyledParagraph = styled.p`
  line-height: 1.57;
  text-align: center;
  margin-top: ${props => props.theme.pxToRem(0)};
`;

const StyledModal = styled(Modal)`
  &&&&& {
    width: ${props => props.theme.pxToRem(650)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
    }
  }
`;

const StyledButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(200)};
    height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;
