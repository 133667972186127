import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from '../Button/Button';
import { isExternal as isExt } from 'utils';

interface ButtonLinkProps extends ButtonProps {
  testId: string;
  to: string;
  external?: boolean;
  target?: string;
  fluid?: boolean;
  rel?: string;
  isTextLink?: boolean;
  useNewMembershipAICPA?: boolean;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  testId,
  to,
  external,
  target = '_blank',
  useNewMembershipAICPA=false,
  ...props
}) => {
  const isAnchor = to.startsWith('#');
  const isExternal = typeof external === 'undefined' ? isExt(to) : external;
  return isExternal || isAnchor ? (
    <Button
      tabIndex={0}
      testId={testId}
      as="a"
      href={to}
      external={isExternal}
      target={isExternal ? target : undefined}
      {...props}
    >
      {children}
    </Button>
  ) : (
    <Button tabIndex={0} testId={testId} as={Link} external={isExternal} to={to} {...props}>
      {children}
    </Button>
  );
};
