import React from 'react';
import styled from 'styled-components/macro';
import { Heading, ButtonLink, ButtonEnums, ButtonVariants, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { ContentCardsCarousel } from 'components/organisms/ContentCardsCarousel';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful } from 'mxp-schemas';
import {
  ExternalLinkStyles,
  ExternalLinkStylesDark,
  DefaultInlineLinkStyles,
  InlineLinkOnDarkBgStyles,
} from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
  isFullPagePagination: boolean;
}

export const BlockContent: React.FC<Props> = ({ modularBlock, headingType, isFullPagePagination }) => {
  const block = modularBlock as any;
  const isBgColorDark: boolean = isColorDark(block.bgColor);
  return (
    <>
      <StyledHeaderContainer isBgColorDark={isBgColorDark}>
        {block.header && (
          <StyledHeading
            as={headingType}
            dangerouslySetInnerHTML={{ __html: block.header }}
            data-full-page-pagination={isFullPagePagination}
            isBgColorDark={isBgColorDark}
            tabIndex={0}
          />
        )}
        {block.description && (
          <StyledParagraph
            tabIndex={0}
            dangerouslySetInnerHTML={{ __html: block.description }}
            isBgColorDark={isBgColorDark}
          />
        )}
      </StyledHeaderContainer>
      <StyledContentCardsCarousel
        content={(block.products || block.contents) as any}
        itemTestId="content-block-content-card"
        data-full-page-pagination={isFullPagePagination}
        isBgColorDark={isBgColorDark}
        transparentCarousel={isBgColorDark}
      />
      {block.ctaText && block.ctaUrl && (
        <>
          {/* Following div is fix for SSR. Do not remove! */}
          <StyledButtonWrapper>
            <OnlyDesktop>
              <ButtonLink
                testId="logged-out-home-content-block"
                to={block.ctaUrl}
                variant={isBgColorDark ? ButtonVariants.secondaryNegative : ButtonVariants.primary}
                bordered
                size={ButtonEnums.sizes.large}
                external={block.ctaUrl.includes('http')}
              >
                {block.ctaText}
              </ButtonLink>
            </OnlyDesktop>
          </StyledButtonWrapper>
          {/* Following div is fix for SSR. Do not remove! */}
          <StyledButtonWrapper>
            <OnlyMobile>
              <ButtonLink
                testId="logged-out-home-content-block-mobile"
                to={block.ctaUrl}
                variant={isBgColorDark ? ButtonVariants.secondaryNegative : ButtonVariants.primary}
                size={ButtonEnums.sizes.small}
                bordered
                external={block.ctaUrl.includes('http')}
              >
                {block.ctaText}
              </ButtonLink>
            </OnlyMobile>
          </StyledButtonWrapper>
        </>
      )}
    </>
  );
};

const StyledHeaderContainer = styled.div<{ isBgColorDark?: boolean }>`
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  margin: 0 auto;

  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
  .rich-text-external-link {
    ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${props => (props.isBgColorDark ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  margin-bottom: ${props => (props['data-full-page-pagination'] ? props.theme.pxToRem(24) : props.theme.pxToRem(16))};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;

  p {
    line-height: 1.38;
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.l};
    p {
      line-height: 1.33;
    }
  }
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;

  p {
    margin: 0 auto;
    line-height: 1.33;
    max-width: ${props => props.theme.pxToRem(693)};
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};
    p {
      line-height: 1.5;
    }
  }
`;

const StyledContentCardsCarousel = styled(ContentCardsCarousel)<any>`
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  margin-top: ${props => props.theme.pxToRem(50)};
  margin-bottom: ${props => props.theme.pxToRem(50)};

  ${props =>
    !props['data-full-page-pagination'] &&
    `
    margin-top: ${props.theme.pxToRem(40)};
    margin-bottom: ${props.theme.pxToRem(40)};
  `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledButtonWrapper = styled.div`
  > div {
    text-align: center;
  }
`;
