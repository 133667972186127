import React from 'react';
import { Container } from 'semantic-ui-react';
import { Link, Divider } from 'components/atoms';
import styled from 'styled-components';
import { ReactComponent as IconArrowBack } from 'resources/images/ic-arrow-back.svg';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
interface Props {
  title?: string;
  description: string;
  handleGoingBack?: string;
  isGoingBack?: boolean;
  className?: any;
}

export const CimaExamProfileHeader: React.FC<Props> = ({
  title = 'Back to EPA overview',
  description,
  isGoingBack,
  handleGoingBack,
  className,
}) => {
  return (
    <StyledCimaExamContainer className={className}>
      {isGoingBack && (
        <StyledLink to={handleGoingBack || getPath(Routes.HOME)}>
          <StyledSpan>
            <StyledIconArrow />
            {title}
          </StyledSpan>
        </StyledLink>
      )}
      <StyledTitle>{description}</StyledTitle>
      <StyledDivider />
    </StyledCimaExamContainer>
  );
};

const StyledCimaExamContainer = styled(Container)`
  &&&&&&& {
    width: 100%;
    height: ${props => props.theme.pxToRem(150)};
    margin: ${props => props.theme.pxToRem(50)} 0 ${props => props.theme.pxToRem(20)} 0;
    position: relative;
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: 0 !important;
      height: auto;
    }
  }
`;

const StyledLink = styled(Link)`
  &&&&&& {
    text-decoration: none;
  }
`;

const StyledTitle = styled.span`
  height: ${props => props.theme.pxToRem(54)};
  margin: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(188)} 0 0;
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(30)};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  width: 100%;
  display: block;
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.pxToRem(25)};
  }
`;

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledIconArrow = styled(IconArrowBack)`
  color: ${props => props.theme.colors.primaryPurple};
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  bottom: ${props => props.theme.pxToRem(-3)};
  position: relative;
  margin-right: ${props => props.theme.pxToRem(8)};
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    border-top-color: transparent;
    border-bottom-color: ${props => props.theme.colors.neutralGrey3};
    color: ${props => props.theme.colors.neutralGrey3};
  }
`;
