import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import {
  topicFilterItemsSelectorFactory,
  contentTypeFilterItemsSelectorFactory,
  cpeCreditFilterItemSelectorFactory,
  eventDateSelector,
} from 'modules/search/selectors';
import { fetchSearchContent, setSearchReferrer } from 'modules/search';
import { BlockIntegratedSearch } from 'components/organisms/BlockRenderer/BlockIntegratedSearch';
import { Product } from 'mxp-schemas';

const mapActionCreators = (dispatch: Dispatch) => ({
  async fetchSearchContent(
    options: { onlyProducts: boolean; onlyEvents: boolean },
    customFilters?: { [key: string]: string | null | undefined }
  ): Promise<void> {
    await dispatch(fetchSearchContent({ ...options, customFilters }));
  },
  setSearchReferrer(referrer: string): void {
    dispatch(setSearchReferrer(referrer));
  },
  navigate(path: string): void {
    dispatch(push(path));
  },
});

const mapStateToProps = () => {
  const topicFilterItemsSelector = topicFilterItemsSelectorFactory({ asDropdownOptions: true });
  const contentTypeFilterItemsSelector = contentTypeFilterItemsSelectorFactory({ asDropdownOptions: true });
  const cpeCreditFilterItemSelector = cpeCreditFilterItemSelectorFactory({ asDropdownOptions: true });

  return (state: State.Root) => {
    // temp disable contentType content
    const contentTypeFiltered = contentTypeFilterItemsSelector(state).filter(
      (item: { key: string; value: string; text: string }) =>
        item.key !== 'content' && item.key !== Product.ProductSlug.CONTRIBUTION
    );

    return {
      topicOptions: topicFilterItemsSelector(state),
      contentTypeOptions: contentTypeFiltered,
      cpeCreditsOptions: cpeCreditFilterItemSelector(state),
      eventDateFilterItems: eventDateSelector(state),
    };
  };
};

export const BlockIntegratedSearchContainer = connect(mapStateToProps, mapActionCreators)(BlockIntegratedSearch);
