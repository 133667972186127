import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { SeatManagement, Invoices, ZuoraTypes, B2B } from 'mxp-schemas';
import { generatePath } from 'react-router-dom';
import { default as request, updateContext } from 'utils/GraphQLClient';
import {
  GET_INVOICES,
  GET_SERVICE_CONTRACTS,
  GET_INVOICE_FILE,
  GET_INVOICE,
  GET_HOSTED_PAGE_SIGNATURE,
  MAKE_INVOICE_PAYMENT,
  GET_INVOICE_PAYMENT_CONFIRMATION_DETAILS,
  ALLOCATE_SEATS,
  DEALLOCATE_SEATS,
  SEND_PRINT_INVOICES,
  GET_CLIENT_ORGANIZATIONS_BY_ACCOUNT_IDS,
  GET_CLIENT_TUITION_PROVIDERS,
  ORGANIZATION_ADMIN_USERS_BY_ROLE,
  GET_SEAT_MANAGEMENT_INVITE,
  CANCEL_SEAT_INVITE,
  // GET_MULTI_ENTITY_INVOICES,
  PAY_INVOICES,
  CANCEL_ALL_SEAT_INVITE,
  EXTEND_PRODUCT_ACCESS_B2B,
  GET_RECENT_EXTENSION_PROCESS_STATUS,
} from 'mxp-graphql-queries';
import { ClientAdminActionNames } from './constants';
import download from 'downloadjs';
import { push, createMatchSelector } from 'connected-react-router';
import { emptyObject, getPath } from 'utils';
import { Routes, PAYMENT_PROCESSING_TIMEOUT } from 'constants/index';
import {
  fullInvoiceDetailsSelector,
  invoiceSelector,
  fullCimaInvoiceDetailsSelector,
  invoicesTotalBalanceSelector,
  selectedPaymentAmountOptionSelector,
} from './selectors';
import { toggleModalPaymentProcessingOpen } from 'modules/layouts';

import {
  isPageFirmBillingInvoiceCheckoutSelector,
  isOrgInvoiceCheckoutSelector,
  isPaypalProceedFirmBillingCheckoutSelector,
  legalEntityQuerySelector,
  currencyQuerySelector,
  isPaypalProceedInvoiceCheckoutSelector,
} from 'modules/router/selectors';
import {
  legalEntitySelector,
  isMultipleInvoiceSelectedSelector,
  selectedInvoicesSelector,
  isAdminInvoicesPaymentJourneySelector,
} from 'modules/admin/selectors';
import { cartCurrencySelector } from 'modules/cart/selectors';
import {
  cimaFeaturesSelector,
  currencySelector,
  organizationNavigationMatchSelector,
} from 'modules/firmAdmin/selectors';

// ------------------------------------
// Actions
// ------------------------------------

export const getServiceContracts: any = createAction(
  ClientAdminActionNames.SEAT_MANAGEMENT_GET_SERVICE_CONTRACTS,
  () => (dispatch: Dispatch) => {
    dispatch(seatMgmtLoading());
    dispatch(resetSeatManagementEmailsList());
    return request(GET_SERVICE_CONTRACTS).then(response => response.getServiceContracts);
  }
);

export const getInvoices: any = createAction(
  ClientAdminActionNames.INVOICES_GET_INVOICES,
  (legalEntity: ZuoraTypes.LegalEntity) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const association = ZuoraTypes.LegalEntity.ASSOCIATION;
    const existingEntity = legalEntity ? legalEntity.toLowerCase() : association;

    const customHeaders = {
      existingEntity,
    };
    await updateContext(customHeaders);
    return request(GET_INVOICES, { legalEntity }).then(response => {
      const invoices: State.InvoiceTableRow[] = response.getInvoices;
      return invoices;
    });
  }
);

export const getSeatManagementInvite: any = createAction(
  ClientAdminActionNames.GET_SEATS_MANAGEMENT_INVITE,
  (orderNumber: string) => () => {
    return request(GET_SEAT_MANAGEMENT_INVITE, { orderNumber }).then(response => response.getSeatManagementInvite);
  }
);

export const getInvoiceFile: any = createAction(
  ClientAdminActionNames.INVOICES_GET_INVOICE_FILE,
  (invoiceId: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setDownloadingId(invoiceId));
    const state: State.Root = getState();
    // Will check first on what page it is being used.
    const legalEntity = Boolean(organizationNavigationMatchSelector(state))
      ? cimaFeaturesSelector(state)
        ? ZuoraTypes.LegalEntity.CIMA
        : ZuoraTypes.LegalEntity.ASSOCIATION
      : legalEntitySelector(state) || ZuoraTypes.LegalEntity.ASSOCIATION;
    await updateContext({
      existingEntity: legalEntity,
    });
    const invoice: State.InvoiceTableRow | null = invoiceSelector(state);
    if (!invoice) return Promise.reject('Download Error: Invoice Not Found');
    return request(GET_INVOICE_FILE, { invoiceId: invoice.id }).then(response => {
      download(`data:application/pdf;base64,${response.getInvoiceFile}`, `${invoice.invoiceNumber}.pdf`);
    });
  }
);

export const allocateSeats: any = createAction(
  ClientAdminActionNames.SEAT_MANAGEMENT_ALLOCATE_SEATS,
  (serviceContractsIds: string[], endUserEmails: string[]) => (dispatch: Dispatch) => {
    dispatch(loadingAssignSeat());
    const payload = { serviceContractsIds, endUserEmails };
    return request(ALLOCATE_SEATS, payload).then(response => response.allocateSeats);
  }
);

export const setSelectedPaymentOption: any = createAction(ClientAdminActionNames.INVOICES_SET_SELECTED_PAYMENT_OPTION);
export const setPaginationAction: any = createAction(ClientAdminActionNames.INVOICES_SET_PAGINATION);

export const isValidAmountCheckLoading: any = createAction(ClientAdminActionNames.INVOICES_SET_IS_VALID_AMOUNT_LOADING);

export const setSelectedPaymentAmountOptionCima: any = createAction(
  ClientAdminActionNames.INVOICES_SET_SELECTED_PAYMENT_AMOUNT_OPTION_CIMA,
  (amountOption: string, amount: number | null) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isValidAmountCheckLoading());
    const state: State.Root = getState();
    const cimaInvoice: State.Invoice[] | null = fullCimaInvoiceDetailsSelector(state);
    if (cimaInvoice && amount) {
      cimaInvoice.forEach(items => {
        if (amount === null) {
          return Promise.reject('There is no amount entered.');
        }
        if (amount > items?.balance) {
          return Promise.reject('The amount entered is greater than the balance.');
        }
        items.amount = amount || 0;
      });
    }
    return { amountOption, amount, cimaInvoice };
  }
);

export const setSelectedPaymentAmountOption: any = createAction(
  ClientAdminActionNames.INVOICES_SET_SELECTED_PAYMENT_AMOUNT_OPTION,
  (amountOption: string, amount: number | null) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isValidAmountCheckLoading());
    const state: State.Root = getState();
    const invoice: State.Invoice | null = fullInvoiceDetailsSelector(state);
    if (invoice) {
      if (amount === null) {
        return Promise.reject('There is no amount entered.');
      }
      if (amount > invoice.balance) {
        return Promise.reject('The amount entered is greater than the balance.');
      }
    }
    return { amountOption, amount };
  }
);

export const getInvoiceLoading: any = createAction(ClientAdminActionNames.INVOICES_GET_INVOICE_LOADING);

export const getInvoice: any = createAction(
  ClientAdminActionNames.INVOICES_GET_INVOICE,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(getInvoiceLoading());
    const state: State.Root = getState();
    const isPageFirmBillingInvoiceCheckout = isPageFirmBillingInvoiceCheckoutSelector(state);
    const match: any = createMatchSelector(
      getPath(isPageFirmBillingInvoiceCheckout ? Routes.FIRM_BILLING_INVOICE_CHECKOUT : Routes.ORG_INVOICE_CHECKOUT)
    )(state);
    const isFirmBillingPaypalProceed = isPaypalProceedFirmBillingCheckoutSelector(state);
    const paramEntity = legalEntityQuerySelector(state);
    const paramCurrency = currencyQuerySelector(state);
    const invoiceId = match?.params?.invoiceId || null;
    // Will get the entity and currency from url query parameter if the page is redirection from paypal
    const legalEntity = isFirmBillingPaypalProceed
      ? paramEntity.toLowerCase()
      : cimaFeaturesSelector(state)
      ? ZuoraTypes.LegalEntity.CIMA
      : ZuoraTypes.LegalEntity.ASSOCIATION;
    const currency = isFirmBillingPaypalProceed ? paramCurrency : currencySelector(state);

    await updateContext({
      existingEntity: legalEntity,
      ...(isPageFirmBillingInvoiceCheckout && { currency: { label: currency } }),
    });

    return request(GET_INVOICE, { invoiceId }).then(response => {
      if (!response.getInvoice) {
        dispatch(goToInvoiceCheckoutErrorPage({ invoiceCheckoutError: true }));
        return Promise.reject('getInvoice: invoice not found');
      }
      const invoiceDetails = response.getInvoice;
      return invoiceDetails;
    });
  }
);

export const getMultiEntityInvoices: any = createAction(
  ClientAdminActionNames.GET_MULTI_ENTITY_INVOICES,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(getInvoiceLoading());
    const state: State.Root = getState();

    const legalEntity = legalEntitySelector(state) || ZuoraTypes.LegalEntity.ASSOCIATION;
    await updateContext({
      existingEntity: legalEntity,
    });

    const zuoraIds = isMultipleInvoiceSelectedSelector(state);

    const response = await request(GET_INVOICES, { legalEntity, filter: { id: zuoraIds } });
    if (!response.getInvoices) {
      dispatch(goToInvoiceCheckoutErrorPage({ invoiceCheckoutError: true }));
      return Promise.reject('getMultiEntityInvoices: invoices not found');
    }
    const multiEntityRes = response.getInvoices;
    return multiEntityRes;
  }
);

export const getInvoicePaymentConfirmationResultLoading: any = createAction(
  ClientAdminActionNames.INVOICES_GET_INVOICE_PAYMENT_CONFIRMATION_RESULT_LOADING
);

export const getInvoicePaymentConfirmationResult: any = createAction(
  ClientAdminActionNames.INVOICES_GET_INVOICE_PAYMENT_CONFIRMATION_RESULT,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(getInvoicePaymentConfirmationResultLoading());

    const state: State.Root = getState();
    const isPageFirmBillingInvoiceCheckout = isPageFirmBillingInvoiceCheckoutSelector(state);
    const match: any = createMatchSelector(
      getPath(
        isPageFirmBillingInvoiceCheckout
          ? Routes.FIRM_BILLING_INVOICE_CONFIRMATION
          : Routes.ORG_INVOICE_PAYMENT_CONFIRMATION
      )
    )(state);
    const invoiceId = match?.params?.invoiceId || null;

    return request(GET_INVOICE_PAYMENT_CONFIRMATION_DETAILS, { invoiceId }).then(response => {
      if (!response.getInvoicePaymentConfirmationDetails) {
        return Promise.reject('getInvoicePaymentConfirmationDetails: invoice not found');
      }
      const invoiceDetails = response.getInvoicePaymentConfirmationDetails;
      return invoiceDetails;
    });
  }
);

export const setInvoiceCheckoutPage: any = createAction(ClientAdminActionNames.INVOICES_SET_INVOICE_CHECKOUT_PAGE);

export const getHostedPageSignature: any = createAction(
  ClientAdminActionNames.INVOICES_GET_HOSTED_PAGE_SIGNATURE,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const isOrgInvoiceCheckout = isOrgInvoiceCheckoutSelector(state);
    const isAdminInvoicesPaymentJourney = isAdminInvoicesPaymentJourneySelector(state);
    const isPageFirmBillingInvoiceCheckout = isPageFirmBillingInvoiceCheckoutSelector(state);
    const orgCurrency = currencySelector(state);
    const legalEntity = (legalEntitySelector(state) as ZuoraTypes.LegalEntity) || ZuoraTypes.LegalEntity.ASSOCIATION;
    const invoiceDetails = fullCimaInvoiceDetailsSelector(state);
    const selectedInvoice = selectedInvoicesSelector(state);
    const selectedInvoiceCurrency = selectedInvoice[0]?.currency;
    const currency = invoiceDetails?.[0]?.currency;
    const cartCurrency = cartCurrencySelector(state);
    const invoiceCurrency = {
      label: isOrgInvoiceCheckout
        ? currency
        : isAdminInvoicesPaymentJourney
        ? selectedInvoiceCurrency
        : isPageFirmBillingInvoiceCheckout
        ? orgCurrency
        : cartCurrency,
    };

    const customHeaders = {
      currency: invoiceCurrency,
      addresses: {
        billingCountry: state.checkout.billingAddress.country,
        shippingCountry: state.checkout.shippingAddress.country,
      },
      ...(isOrgInvoiceCheckout && { existingEntity: legalEntity }),
      ...(isAdminInvoicesPaymentJourney && { existingEntity: selectedInvoice[0]?.legalEntity }), // to match the iframe's entity to the invoice entity
    };
    await updateContext(customHeaders);

    return request(GET_HOSTED_PAGE_SIGNATURE).then(response => response.getHostedPageSignature);
  }
);

export const payInvoice: any = createAction(
  ClientAdminActionNames.INVOICES_MAKE_INVOICE_PAYMENT,
  async (paymentMethodId: string, rootOrganizationId: string | null, paymentMethod?: string) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const startTime: number = Date.now();
      dispatch(toggleModalPaymentProcessingOpen());

      const getTimeoutDiff = (): number => {
        const endTime: number = Date.now();
        const diff: number = endTime - startTime;
        return diff > PAYMENT_PROCESSING_TIMEOUT ? 0 : PAYMENT_PROCESSING_TIMEOUT - diff;
      };
      const state: State.Root = getState();
      const invoice = fullInvoiceDetailsSelector(state);

      const payInvoiceParams: Invoices.PayInvoiceParams = {
        paymentMethodId,
        isB2B: true,
        saveCreditCard: false,
        amount: invoice?.amount,
        balance: invoice?.balance,
        invoiceId: invoice?.id,
        accountId: invoice?.accountId,
        invoiceNumber: invoice?.invoiceNumber,
        orderNumber: invoice?.orderNumber,
        installmentTerm: invoice?.installmentTerm,
        paymentTerm: invoice?.paymentTerm,
        rootOrganizationId,
        paymentMethod,
      };

      const isPageFirmBillingInvoiceCheckout = isPageFirmBillingInvoiceCheckoutSelector(state);
      if (isPageFirmBillingInvoiceCheckout) {
        const isFirmBillingPaypalProceed = isPaypalProceedFirmBillingCheckoutSelector(state);
        // Will get entity from URL if from paypal redirection
        const legalEntity = isFirmBillingPaypalProceed
          ? legalEntityQuerySelector(state).toLowerCase()
          : cimaFeaturesSelector(state)
          ? ZuoraTypes.LegalEntity.CIMA
          : ZuoraTypes.LegalEntity.ASSOCIATION;
        const currency = currencySelector(state);
        // setting firm entity
        payInvoiceParams.invoiceEntity = legalEntity;

        await updateContext({
          existingEntity: legalEntity,
          currency: {
            label: currency,
          },
        });
      }

      return request(MAKE_INVOICE_PAYMENT, payInvoiceParams)
        .then(res => {
          setTimeout(() => {
            dispatch(toggleModalPaymentProcessingOpen());
            const zuoraSuccess: boolean =
              res.payInvoice.success && res.payInvoice.status === Invoices.PaymentStatus.PROCESSED;

            if (zuoraSuccess) {
              dispatch(resetModule());
              dispatch(
                push(
                  rootOrganizationId
                    ? generatePath(getPath(Routes.FIRM_BILLING_INVOICE_CONFIRMATION), {
                        orgId: rootOrganizationId,
                        invoiceId: res.payInvoice.invoiceId,
                      })
                    : generatePath(getPath(Routes.ORG_INVOICE_PAYMENT_CONFIRMATION), {
                        invoiceId: res.payInvoice.invoiceId,
                      })
                )
              );
            } else {
              dispatch(goToInvoiceCheckoutErrorPage({ invoiceCheckoutError: true }));
              Promise.reject('Error paying for invoice');
            }
          }, getTimeoutDiff());
        })
        .catch(() => {
          setTimeout(() => {
            dispatch(toggleModalPaymentProcessingOpen());
            dispatch(goToInvoiceCheckoutErrorPage({ invoiceCheckoutError: true }));
          }, getTimeoutDiff());
        });
    }
);

// Make Multi Entity Invoices Payment
export const payInvoices: any = createAction(
  ClientAdminActionNames.PAY_INVOICES,
  (paymentMethodId: string, paymentMethod?: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const startTime: number = Date.now();
    dispatch(toggleModalPaymentProcessingOpen());

    const getTimeoutDiff = (): number => {
      const endTime: number = Date.now();
      const diff: number = endTime - startTime;
      return diff > PAYMENT_PROCESSING_TIMEOUT ? 0 : PAYMENT_PROCESSING_TIMEOUT - diff;
    };
    const state: State.Root = getState();
    const isOrgInvoice = isPaypalProceedInvoiceCheckoutSelector(state);
    const queryEntity = legalEntityQuerySelector(state);
    const legalEntity = (legalEntitySelector(state) as ZuoraTypes.LegalEntity) || ZuoraTypes.LegalEntity.ASSOCIATION;
    const invoices: State.Invoice[] = fullCimaInvoiceDetailsSelector(state);

    const accountId: string = invoices.find(invoice => invoice.accountId)?.accountId || '';
    const paymentOption: string | null = selectedPaymentAmountOptionSelector(state);
    const isPartial: boolean = Boolean(
      paymentOption === Invoices.PaymentAmountOptions.PARTIAL_PAYMENT && invoices?.length
    );
    const totalAmountToPay: number = isPartial ? invoices[0]?.amount : invoicesTotalBalanceSelector(state);
    // # if have multiple selected invoice and select partial payment, this inputed amount will be divide to all of invoices
    const partialAmountPerInvoice: number = totalAmountToPay / invoices?.length || 0;
    const totalAmount: number = isPartial ? partialAmountPerInvoice : invoicesTotalBalanceSelector(state);
    const invoicesToPay = invoices.map(invoice => {
      return {
        invoiceId: invoice.id,
        amount: isPartial ? totalAmount : invoice.balance,
        balance: invoice.balance,
        invoiceNumber: invoice.invoiceNumber,
        orderNumber: invoice.orderNumber,
        currency: invoice?.currency,
      };
    });
    const payInvoicesParams: Invoices.PayInvoicesParams = {
      paymentMethodId,
      accountId,
      totalAmount: totalAmountToPay,
      invoices: invoicesToPay,
      invoiceEntity: isOrgInvoice ? queryEntity : legalEntity,
      paymentMethod,
      isPartialPayment: isPartial,
    };

    try {
      const customHeaders = {
        existingEntity: legalEntity,
      };
      await updateContext(customHeaders);

      const res = await request(PAY_INVOICES, payInvoicesParams);
      setTimeout(() => {
        dispatch(toggleModalPaymentProcessingOpen());
        // Note: Disabled resetFullInvoiceDetailsModule to preserve Invoice Details.
        // dispatch(resetFullInvoiceDetailsModule());
        dispatch(push(generatePath(getPath(Routes.ORG_INVOICES_PAYMENT_CONFIRMATION))));
      }, getTimeoutDiff());
      return res.payInvoices;
    } catch {
      setTimeout(() => {
        dispatch(toggleModalPaymentProcessingOpen());
        dispatch(goToInvoiceCheckoutErrorPage({ invoiceCheckoutError: true }));
      }, getTimeoutDiff());
    }
  }
);

export const resetFullInvoiceDetailsModule: any = createAction(
  ClientAdminActionNames.RESET_FULL_INVOICE_DETAILS_MODULE
);
export const resetPayInvoicesModule: any = createAction(ClientAdminActionNames.RESET_PAY_INVOICES_MODULE);

export const resetInvoicePaymentConfirmationData: any = createAction(
  ClientAdminActionNames.INVOICES_RESET_INVOICE_PAYMENT_CONFIRMATION_DATA
);

export const goToInvoiceCheckoutErrorPage: any = createAction(
  ClientAdminActionNames.INVOICES_GO_TO_INVOICE_CHECKOUT_ERROR_PAGE,
  ({ invoiceCheckoutError = false }: { invoiceCheckoutError: boolean } = emptyObject as any) =>
    (dispatch: Dispatch, getState: () => State.Root): void => {
      const state: State.Root = getState();
      const isPageFirmBillingInvoiceCheckout = isPageFirmBillingInvoiceCheckoutSelector(state);
      const match: any = createMatchSelector(
        getPath(isPageFirmBillingInvoiceCheckout ? Routes.FIRM_BILLING_INVOICE_CHECKOUT : Routes.ORG_INVOICE_CHECKOUT)
      )(state);
      const invoiceId = match?.params?.invoiceId || null;
      const rootOrganizationId = match?.params?.orgId || null;

      dispatch(
        push({
          pathname: generatePath(getPath(Routes.ORG_INVOICE_ERROR), { invoiceId }),
          state: { invoiceCheckoutError, rootOrganizationId },
        })
      );
    }
);

export const seatMgmtLoading: any = createAction(ClientAdminActionNames.SEAT_MANAGEMENT_LOADING);

export const setDownloadingId: any = createAction(ClientAdminActionNames.INVOICES_SET_DOWNLOADING_ID);

export const resetModule: any = createAction(ClientAdminActionNames.INVOICES_RESET_MODULE);
export const addEmailToSeatManagementEmailsList: any = createAction(
  ClientAdminActionNames.ADD_EMAIL_TO_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_LIST
);

export const removeEmailFromSeatManagementEmailsList: any = createAction(
  ClientAdminActionNames.REMOVE_EMAIL_FROM_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_LIST
);

export const showSeatManagementEmailInviteModal = createAction(
  ClientAdminActionNames.SHOW_MANAGEMENT_RECIPIENTS_EMAILS_MODAL,
  (serviceContract: SeatManagement.ServiceContract) => async (dispatch: Dispatch) => {
    dispatch(resetSeatManagementEmailsList());
    return serviceContract;
  }
);

export const hideSeatManagementEmailInviteModal = createAction(
  ClientAdminActionNames.HIDE_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_MODAL
);

export const loadingAssignSeat = createAction(ClientAdminActionNames.SEAT_MANAGEMENT_ASSIGN_LOADING);
export const resetSeatManagementEmailsList: any = createAction(
  ClientAdminActionNames.SEAT_MANAGEMENT_EMAIL_LIST_RESET_MODULE
);

export const setDeallocationModalVisibility: any = createAction(
  ClientAdminActionNames.SET_DEALLOCATION_MODAL_VISIBILITY
);
export const setDealloctionLoading: any = createAction(ClientAdminActionNames.SET_DEALLOCATION_LOADING);
export const setSelectedSeatsToDeallocate: any = createAction(ClientAdminActionNames.SET_SELECTED_SEATS_TO_DEALLOCATE);
export const seatManageInviteUpdate: any = createAction(ClientAdminActionNames.SEAT_MANAGEMENT_INVITE_UPDATE);

export const deallocateLineItems: any = createAction(
  ClientAdminActionNames.DEALLOCATE_LINE_ITEMS,
  (
      serviceContract: SeatManagement.ServiceContract,
      lineItems: SeatManagement.ContractLineItem[],
      pendingList?: string[]
    ) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const lineItemIds = lineItems.map(item => item.contractLineItemId);
      const emails = lineItems.map(item => item.contactEmail);

      // deactivate other invite here when pendingList has items
      if (pendingList?.length) {
        dispatch(seatManagementInviteCancelAll(pendingList));
      }

      dispatch(setDealloctionLoading(true));
      return request(DEALLOCATE_SEATS, { lineItemIds, emails }).then(response => {
        if (!response.deAllocateSeats) {
          return Promise.reject('DEALLOCATE_SEATS: no response');
        }

        if (response.deAllocateSeats) {
          dispatch(seatManageInviteUpdate());
        }

        return response.deAllocateSeats;
      });
    }
);

export const setSendEmailPrintInvoicesLoading: any = createAction('admin/SET_SEND_PRINT_INVOICES_LOADING');
export const resetSendEmailPrintInvoices: any = createAction('admin/RESET_SEND_PRINT_INVOICES');

export const sendEmailPrintInvoices: any = createAction(
  'admin/SEND_PRINT_INVOICES',
  (sendPrintPayload: ZuoraTypes.InvoiceSearchPayload) => (dispatch: Dispatch) => {
    dispatch(resetSendEmailPrintInvoices());
    dispatch(setSendEmailPrintInvoicesLoading(true));
    return request(SEND_PRINT_INVOICES, {
      ...sendPrintPayload,
    })
      .then((response: { sendPrintInvoices: { success: boolean; fileName: string } }) => {
        const { sendPrintInvoices } = response;

        if (!sendPrintInvoices.success || (sendPrintPayload.SendToPrint && !sendPrintInvoices.fileName)) {
          dispatch(push(getPath(Routes.ADMIN_ERROR_PAGE)));
        }

        return sendPrintInvoices;
      })
      .catch(() => {
        dispatch(push(getPath(Routes.ADMIN_ERROR_PAGE)));
      });
  }
);

export const getInvoiceFormFile: any = createAction(
  ClientAdminActionNames.INVOICES_GET_INVOICE_FILE,
  (invoice: State.InvoiceTableRow) => (dispatch: Dispatch) => {
    dispatch(setDownloadingId(invoice.id));
    return request(GET_INVOICE_FILE, { invoiceId: invoice.id }).then(response => {
      download(`data:application/pdf;base64,${response.getInvoiceFile}`, `${invoice.invoiceNumber}.pdf`);
    });
  }
);

export const getOrganizations: any = createAction(
  ClientAdminActionNames.GET_ORGANIZATIONS,
  async () => (): Promise<State.Organization[] | any[]> => {
    const createDataTree = (organizationList: State.Organization[]) => {
      const organizationIdMap = organizationList.reduce<{ [key: string]: State.Organization }>((acc, organization) => {
        acc[organization.id] = { ...organization, branches: [] };
        return acc;
      }, {});
      const dataTree: State.Organization[] = [];
      organizationList.forEach((organization: State.Organization) => {
        if (organization.currentParentId) {
          organizationIdMap[organization.currentParentId].branches?.push(organizationIdMap[organization.id]);
        } else dataTree.push(organizationIdMap[organization.id]);
      });
      return dataTree as State.Organization[];
    };

    return request(GET_CLIENT_ORGANIZATIONS_BY_ACCOUNT_IDS).then(e => createDataTree(e?.clientOrganizations));
  }
);

export const getClientTuitionProviders: any = createAction(
  ClientAdminActionNames.GET_CLIENT_TUITION_PROVIDERS,
  () => async (): Promise<State.Organization[] | any[]> => {
    return request(GET_CLIENT_TUITION_PROVIDERS).then(response => response.clientTuitionProviders);
  }
);

export const getOrganizationAdminsByRole: any = createAction(
  ClientAdminActionNames.GET_ORGANIZATION_ADMINS_BY_ROLE,
  async () =>
    (
      dispatch: Dispatch,
      getState: () => State.Root
    ): Promise<{
      organizationAdmins: State.Contact[] | any;
    }> => {
      dispatch(setOrganizationAdminsLoading());
      const state = getState();
      const id: string = (createMatchSelector(getPath(Routes.ORG_FIRM_INFORMATION))(state)?.params as any)?.orgId;
      const roles = [B2B.AgentRole.CLIENT_ADMIN, B2B.AgentRole.FIRM_BILLING, B2B.AgentRole.CENTERS_MEMBERSHIP];
      return Promise.all(
        roles.map(role =>
          request(ORGANIZATION_ADMIN_USERS_BY_ROLE, { id, role }).then(e => e?.organizationAdminUsersByRole)
        )
      ).then(admins => ({ organizationAdmins: admins }));
    }
);

export const seatManagementInviteCancel: any = createAction(
  ClientAdminActionNames.SEAT_MANAGEMENT_INVITE_UPDATE,
  (inviteId: string) => async () => {
    return request(CANCEL_SEAT_INVITE, { inviteId }).then(response => response.cancelSeatInvite);
  }
);

export const setOrganizationAdminsLoading: any = createAction(ClientAdminActionNames.SET_ORGANIZATION_ADMINS_LOADING);

export const seatManagementInviteCancelAll: any = createAction(
  ClientAdminActionNames.SEAT_MANAGEMENT_INVITE_CANCEL_ALL,
  (inviteIdList?: string[]) => async () => {
    return request(CANCEL_ALL_SEAT_INVITE, { inviteIdList }).then(response => response.cancelSeatInvite);
  }
);

export const setExtendProductAccessIsLoading: any = createAction(
  ClientAdminActionNames.EXTEND_PRODUCT_ACCESS_IS_LOADING,
  (isLoading: boolean) => () => {
    return isLoading;
  }
);

export const setExtendProductAccessDetails: any = createAction(
  ClientAdminActionNames.EXTEND_PRODUCT_ACCESS_DETAILS,
  (status: string, date: string, userLength: string, productName: string, existingEndDate?: string) => () => {
    return { status, date, userLength, productName, existingEndDate };
  }
);

export const extendProductAccess: any = createAction(
  ClientAdminActionNames.EXTEND_PRODUCT_ACCESS,
  (payload: SeatManagement.ProductAccessExtension) => (dispatch: Dispatch) => {
    dispatch(setExtendProductAccessDetails(''));
    dispatch(setExtendProductAccessIsLoading(true));
    return request(EXTEND_PRODUCT_ACCESS_B2B, { payload: payload.apiParam })
      .then(response => {
        const status = response?.extendProductAccessB2B?.success;
        if (status === true) {
          dispatch(setExtendProductAccessIsLoading(false));
          dispatch(
            setExtendProductAccessDetails(
              'SUCCESS',
              payload.newEndDate,
              payload.selectedUsersCount,
              payload.productName,
              payload.currentEndDate
            )
          );
        } else {
          dispatch(setExtendProductAccessIsLoading(false));
          dispatch(
            setExtendProductAccessDetails(
              'FAILED',
              payload.newEndDate,
              payload.selectedUsersCount,
              payload.productName,
              payload.currentEndDate
            )
          );
        }
      })
      .catch(err => {
        const statusCode = err?.response?.status;
        if (statusCode === 400) {
          dispatch(setExtendProductAccessIsLoading(false));
          dispatch(
            setExtendProductAccessDetails(
              'ERROR-400',
              payload.newEndDate,
              payload.selectedUsersCount,
              payload.productName,
              payload.currentEndDate
            )
          );
        } else {
          dispatch(setExtendProductAccessIsLoading(false));
          dispatch(
            setExtendProductAccessDetails(
              'FAILED',
              payload.newEndDate,
              payload.selectedUsersCount,
              payload.productName,
              payload.currentEndDate
            )
          );
        }
      });
  }
);

export const toggleProductAccessExtensionModal: any = createAction(
  ClientAdminActionNames.TOGGLE_PRODUCT_ACCESS_EXTENSION_MODAL
);
export const getRecentExtensionProcessStatus: any = createAction(
  ClientAdminActionNames.GET_RECENT_EXTENSION_PROCESS_STATUS,
  (serviceContractId: string) => () => {
    return request(GET_RECENT_EXTENSION_PROCESS_STATUS, { serviceContractId }).then(
      res => res.getRecentExtensionProcessStatus
    );
  }
);
