import React from 'react';
import styled from 'styled-components';
import { LazyImage } from '../../../components/atoms';

interface Props {
  coverSrc: string;
  imageRatio: string;
}

export const ProductCover: React.SFC<Props> = ({ coverSrc, imageRatio }) => {
  return (
    <Cover>
      <LazyImage src={coverSrc} alt="product-image" imageRatio={imageRatio} />
    </Cover>
  );
};

const Cover = styled.div`
  border-radius: 0 0 16px;
  overflow: hidden;
  background-size: cover;
  display: block;
  height: 200px;
  width: 140px;
  box-shadow: 0 19px 10px -10px rgba(0, 0, 0, 0.1);
`;
