import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_NEW_MEMBERSHIP_AICPA } from 'modules/featureToggle/constants';
import { Modal, ButtonVariants, AnchorMarkdownRenderer } from 'components/atoms';
import ReactMarkdown from 'react-markdown';
import { ProductThumbnail, ProductThumbSize } from 'components/molecules/ProductThumbnail/ProductThumbnail';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { Divider as SemanticDivider, Segment, Grid } from 'semantic-ui-react';
import { Product } from 'mxp-schemas';
interface Props {
  isOpenModal: boolean;
  headerText?: string;
  description?: string;
  amount?: number;
  authors?: string[];
  format?: string;
  publishers?: string[];
  availability?: number;
  overview?: string;
  handleModal(): void;
  imageUrl?: string;
  productType: string;
  subscriptionProductType?: string;
  isMarkDown?: boolean;
}

export const ProductModal: React.FC<Props> = ({
  isOpenModal,
  headerText,
  description,
  format,
  publishers,
  authors,
  availability,
  overview,
  imageUrl,
  productType,
  handleModal,
  subscriptionProductType,
  isMarkDown = true,
}) => {
  const useNewMembershipAICPA = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_NEW_MEMBERSHIP_AICPA)
  );
  const [isOpen, setOpen]: any = useState(false);

  const productTypeValue = Product.ProductSlugsTypesMap[productType];
  const productTypeName = Product.ProductTypesSlugsMap[productType];

  const renderProductThumbnails = (): React.ReactNode => (
    <>
      <StyledProductThumbnail
        coverSrc={imageUrl || ''}
        size={ProductThumbSize.MEDIUM}
        title={headerText || ''}
        subtitle=""
        productType={productTypeValue}
      />
    </>
  );

  useEffect(() => {
    setOpen(isOpenModal);
  }, [isOpenModal]);

  const gridColumn = useMemo(() => {
    let columnCounter = 0;
    if (format) {
      columnCounter = columnCounter + 1;
    }
    if (authors && authors.length > 0) {
      columnCounter = columnCounter + 1;
    }
    if (publishers && publishers.length > 0) {
      columnCounter = columnCounter + 1;
    }
    if (availability && availability !== 0) {
      columnCounter = columnCounter + 1;
    }

    return columnCounter;
  }, [format, authors, publishers, availability]);

  return (
    <>
      <StyledModal size="large" open={isOpen}>
        <CloseButton>
          <StyledCloseIcon onClick={handleModal} />
        </CloseButton>
        <Spacer />
        <StyledGrid>
          <Grid.Column width={3}>{renderProductThumbnails()}</Grid.Column>
          <Grid.Column width={13}>
            <StyledHeaderProductType>
              {productTypeName?.toLocaleUpperCase() || subscriptionProductType?.toLocaleUpperCase()}
            </StyledHeaderProductType>
            <StyledHeader>{headerText}</StyledHeader>
            <StyledDescription>{description}</StyledDescription>
          </Grid.Column>
        </StyledGrid>
        {gridColumn > 0 && (
          <StyledSegment>
            <Grid columns={4} relaxed="very">
              <Grid.Column>
                <StyledSpan>Format</StyledSpan>
                <StyledP>{format}</StyledP>
              </Grid.Column>
              <Grid.Column>
                <StyledSpan>Author</StyledSpan>
                <StyledP>{authors && authors.join(', ')}</StyledP>
              </Grid.Column>
              <Grid.Column>
                <StyledSpan>Publisher</StyledSpan>
                <StyledP>{publishers && publishers.join(', ')}</StyledP>
              </Grid.Column>
              <Grid.Column>
                <StyledSpan>Availability</StyledSpan>
                <StyledP>{availability} Day(s)</StyledP>
              </Grid.Column>
            </Grid>
            <SemanticDivider vertical />
          </StyledSegment>
        )}
        {isMarkDown ? (
          <StyledDiv>
            {useNewMembershipAICPA ? (
              <ReactMarkdown renderers={{ link: AnchorMarkdownRenderer }} children={overview as any} />
            ) : (
              <ReactMarkdown children={overview as any} />
            )}
          </StyledDiv>
        ) : (
          <StyledDiv dangerouslySetInnerHTML={{ __html: overview || '' }} />
        )}
      </StyledModal>
    </>
  );
};

const StyledDiv = styled.div`
  margin: ${props => props.theme.pxToRem(50)} ${props => props.theme.pxToRem(50)};
`;

const StyledGrid = styled(Grid)`
  &&& {
    margin: 0 ${props => props.theme.pxToRem(50)} !important;
  }
`;

const StyledSegment = styled(Segment)`
  &&& {
    margin: ${props => props.theme.pxToRem(70)} ${props => props.theme.pxToRem(50)} 0 !important;
  }
`;

const StyledHeaderProductType = styled.p`
  margin: ${props => props.theme.pxToRem(3)} ${props => props.theme.pxToRem(22)} ${props => props.theme.pxToRem(9)} 0;
  font-size: ${props => props.theme.pxToRem(12)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
  color: ${props => props.theme.colors.neutralGrey5};
`;

const StyledProductThumbnail = styled(ProductThumbnail)``;

const StyledDescription = styled.p`
  margin: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(33)} 0;
  font-size: ${props => props.theme.pxToRem(24)};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledHeader = styled.p`
  margin: ${props => props.theme.pxToRem(9)} ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(8)} 0;
  font-size: ${props => props.theme.pxToRem(40)};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledSpan = styled.span`
  margin: 0 ${props => props.theme.pxToRem(12)} 0 0;
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledP = styled.p`
  margin: ${props => props.theme.pxToRem(22)} 0 0;
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

interface ModalButtonProps {
  onClick: () => void;
  isDisabled: boolean;
  variant?: ButtonVariants;
}

const StyledModal = styled(Modal)`
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
