import React from 'react';
import { Popup } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { CIMA_BADGE_COMPLETED, CIMA_BADGE_ONPROCESS, CIMA_BADGE_NOT_ELIGIBLE } from 'resources/images';

export enum BadgeStatus {
  COMPLETED = 'completed',
  NOT_ELIGIBLE = 'not_eligible',
  PROCESSING = 'processing',
}

export interface BadgeItemProps {
  status: string;
  name: string;
}

interface Props {
  data: BadgeItemProps[];
}

export const Badge: React.FC<Props> = ({ data }) => {
  return (
    <>
      <StyledHeader>MY BADGES</StyledHeader>
      <HozDiv>
        {data?.length > 0 &&
          data.map((badge: BadgeItemProps) => {
            const badgeImage =
              badge.status === BadgeStatus.COMPLETED
                ? CIMA_BADGE_COMPLETED
                : badge.status === BadgeStatus.PROCESSING
                ? CIMA_BADGE_ONPROCESS
                : CIMA_BADGE_NOT_ELIGIBLE;
            return (
              <Popup
                key={badge.name}
                content={badge.name}
                basic
                trigger={
                  <BoxBadge key={badge.name}>
                    <StyledBadgeDiv badgeStatus={badge.status}>
                      <img alt={badge.status} src={badgeImage} />
                    </StyledBadgeDiv>
                    <StyledBadgeName>{badge.name}</StyledBadgeName>
                  </BoxBadge>
                }
              />
            );
          })}
      </HozDiv>
    </>
  );
};

const BoxBadge = styled.div`
  box-sizing: border-box;
  width: ${props => props.theme.pxToRem(90)};
  min-height: ${props => props.theme.pxToRem(80)};
  display: flex;
  flex-direction: column;
`;

const StyledBadgeName = styled.p`
  font-size: ${props => props.theme.pxToRem(12)};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin: ${props => props.theme.pxToRem(9)};
  width: ${props => props.theme.pxToRem(50)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const StyledBadgeDiv = styled.div<{ badgeStatus: string }>`
  margin: 0 ${props => props.theme.pxToRem(10)};
  border-radius: ${props => props.theme.pxToRem(15)} ${props => props.theme.pxToRem(15)}
    ${props => props.theme.pxToRem(50)} ${props => props.theme.pxToRem(50)};
  height: ${props => props.theme.pxToRem(50)};
  width: ${props => props.theme.pxToRem(50)};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  ${({ badgeStatus }) =>
    badgeStatus === BadgeStatus.COMPLETED &&
    css`
      background-color: ${props => props.theme.colors.secondaryDarkBlue};
      border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.secondaryDarkBlue};
    `}

  ${({ badgeStatus }) =>
    badgeStatus === BadgeStatus.PROCESSING &&
    css`
      border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.secondaryDarkBlue};
    `}

    ${({ badgeStatus }) =>
    badgeStatus === BadgeStatus.NOT_ELIGIBLE &&
    css`
      background-color: transparent;
      border: ${props => props.theme.pxToRem(0)} solid #d1d1e0;
    `}
`;

const HozDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledHeader = styled.small`
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey5};
`;
