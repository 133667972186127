import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { customerMembershipsSelector, employmentDataSelector, tlwSelector } from 'modules/user/selectors';
import {
  existingCredentialSelector,
  userMembershipPackageSelector,
  membershipCredentialSelector,
  hasSelectedCredentialSelector,
  activeMembershipSubscriptionSelector,
  activeMembershipSubscriptionAddonsSelector,
  existingSectionSelector,
  userMembershipBenefitsSelector,
  isMembershipBenefitsFetchedSelector,
  hasSelectedSectionSelector,
  hasSelectedTierSelector,
  membershipSectionRootSelector,
  membershipTiersSelector,
  existingFreeSectionSelector,
} from 'modules/membership/selectors';
import {
  setMembershipPackageType,
  setMembershipPackageTier,
  addMembershipPackageCredential,
  addMembershipPackageSectionWithPriceProduct,
  fetchMembershipRelatedBenefits,
  addMembershipPackageRelatedAddon,
  fetchMembershipTypes,
  fetchMembershipTiers,
  addMembershipPackageSectionFreeProduct,
} from 'modules/membership/actions';
import { productCurrencySelector } from 'modules/products/selectors';
import { getAccountTypeData } from './helpers/getAccountTypeData';
import { getServiceTier } from './helpers/getServiceTier';
import { getMembershipCredentials } from './helpers/getMembershipCredentials';
import { getMembershipsSections } from './helpers/getMembershipsSections';
import { getAddons } from './helpers/getAddons';
import { isActiveCheckDates } from 'modules/products/helpers';
import { TRenewMembershipDetailsTableData } from './types/types';
import { RenewMembershipDetailsTable } from 'components/molecules/RenewMembershipDetailsTable/RenewMembershipDetailsTable';
import { MembershipRegularTypeSkus } from 'modules/membership/constants';
import { CartSummaryContainer } from 'containers/CartSummaryContainer';
import { appInitializedSelector } from 'modules/app/selectors';
import { fetchMembershipSections } from 'modules/membership';

interface IPageRenewYourMembershipProps {
  changeToUpdateProcess: () => void;
}

export const PageRenewYourMembership: FC<IPageRenewYourMembershipProps> = ({ changeToUpdateProcess }) => {
  const dispatch = useDispatch();
  const appInitialized = useSelector(appInitializedSelector);

  const customerMemberships = useSelector(customerMembershipsSelector);
  const employmentData = useSelector(employmentDataSelector);
  const tlwDetails = useSelector(tlwSelector);
  const currency = useSelector(productCurrencySelector);
  const activeMembershipSubscription = useSelector(activeMembershipSubscriptionSelector);
  const {
    credentials: userChoicesCredentials,
    type: userChoicesType,
    tier: userChoicesTier,
    sectionProductWithPrice: userChoicesSection,
    sectionFreeProduct: userChoicesFreeSection,
    addons: userChoicesAddons,
  } = useSelector(userMembershipPackageSelector);
  // Type & Tier
  const isValidSetMembershipTypeParams =
    !!activeMembershipSubscription?.productId &&
    !!activeMembershipSubscription?.productSlug &&
    !!activeMembershipSubscription?.variant?.sku;
  const isAddedMembershipType =
    userChoicesType.id === activeMembershipSubscription?.productId &&
    userChoicesType.slug === activeMembershipSubscription?.productSlug;
  const isAddedMembershipTier = activeMembershipSubscription?.variant?.sku === userChoicesTier;
  const membershipTiers = useSelector(membershipTiersSelector);
  const hasSelectedTier = useSelector(hasSelectedTierSelector);

  // Credentials
  const existingCredential = useSelector(existingCredentialSelector);
  const { list: credentialsList } = useSelector(membershipCredentialSelector);
  const { listOfProductWithPrice: sectionWithPriceList, listOfFreeProduct: sectionListOfFreeProduct } =
    useSelector(membershipSectionRootSelector);
  const hasSelectedCredential = useSelector(hasSelectedCredentialSelector);

  // Addons
  const existingAddons = useSelector(activeMembershipSubscriptionAddonsSelector);
  const now = new Date();
  const expiredAddons = existingAddons.filter(
    item => !!item.totalPrice?.centAmount && !isActiveCheckDates(item as any, now)
  );

  // Sections
  const existingSections = useSelector(existingSectionSelector);
  const existingFreeSection = useSelector(existingFreeSectionSelector);
  const { list: benefitsList } = useSelector(userMembershipBenefitsSelector);

  const isMembershipBenefitsFetched = useSelector(isMembershipBenefitsFetchedSelector);
  const hasSelectedSection = useSelector(hasSelectedSectionSelector);
  const isCustomerMembershipsExistAndNotEmpty = Array.isArray(customerMemberships) && customerMemberships.length > 0;

  const renewMembershipDetailsTableData: TRenewMembershipDetailsTableData = {
    accountType: isCustomerMembershipsExistAndNotEmpty ? getAccountTypeData(customerMemberships) : undefined,
    serviceTier: isCustomerMembershipsExistAndNotEmpty
      ? getServiceTier(
          userChoicesTier,
          customerMemberships,
          employmentData,
          tlwDetails,
          membershipTiers.list,
          currency,
          hasSelectedTier
        )
      : undefined,
    membershipCredentials: getMembershipCredentials(
      existingCredential,
      hasSelectedCredential,
      userChoicesCredentials,
      credentialsList,
      currency
    ),
    membershipSections: getMembershipsSections(
      existingSections,
      existingFreeSection,
      hasSelectedSection,
      userChoicesSection,
      userChoicesFreeSection,
      sectionWithPriceList,
      currency,
      benefitsList
    ),
    addOns: getAddons(existingAddons),
  };

  const serviceTierFieldsToBeShown = () => {
    switch (renewMembershipDetailsTableData?.serviceTier?.productId) {
      case MembershipRegularTypeSkus.CORE:
      case MembershipRegularTypeSkus.ESSENTIAL:
      case MembershipRegularTypeSkus.SELECT:
      case MembershipRegularTypeSkus.PARTNER_CORE:
      case MembershipRegularTypeSkus.PARTNER_SPECIALIST:
      case MembershipRegularTypeSkus.PARTNER_ADVANCE:
      case MembershipRegularTypeSkus.LEAD:
        return ['selectedTier', 'employmentStatus', 'role', 'value', 'status'];
      case MembershipRegularTypeSkus.RETIRED:
      case MembershipRegularTypeSkus.REGULAR_TLW:
        return ['selectedTier', 'employmentStatus', 'reason', 'value', 'status'];
      case MembershipRegularTypeSkus.EXAM_CANDIDATE:
      case MembershipRegularTypeSkus.INT_ASSOCIATE:
        return ['selectedTier', 'value', 'status'];
      default:
        return ['selectedTier', 'value', 'status'];
    }
  };

  useEffect(() => {
    dispatch(fetchMembershipTypes());
    dispatch(fetchMembershipTiers());
    dispatch(fetchMembershipSections());
  }, [dispatch]);

  useEffect(
    () => {
      // Add existing membership type and tier
      if (isValidSetMembershipTypeParams && !isAddedMembershipType && !isAddedMembershipTier) {
        dispatch(
          setMembershipPackageType(activeMembershipSubscription?.productId, activeMembershipSubscription?.productSlug)
        );
        dispatch(setMembershipPackageTier(activeMembershipSubscription?.variant?.sku));
      }

      // Add existing credentials
      if (existingCredential?.length) {
        existingCredential.forEach((item: any) => {
          const isAdded = userChoicesCredentials.some(
            choice => choice.sku === item.variant.sku && choice.productId === item.productId
          );
          if (!isAdded) {
            dispatch(addMembershipPackageCredential(item.productId, item.variant.sku));
          }
        });
      }

      // Add expiring addons
      if (expiredAddons?.length) {
        expiredAddons.forEach((item: any) => {
          const isAdded = userChoicesAddons.some(
            choice => choice.sku === item.variant.sku && choice.productId === item.productId
          );
          if (!isAdded) {
            dispatch(addMembershipPackageRelatedAddon(item.productId, item.variant.sku));
          }
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!isMembershipBenefitsFetched && !!activeMembershipSubscription?.variant?.sku) {
      dispatch(fetchMembershipRelatedBenefits(activeMembershipSubscription?.variant?.sku));
    }
  }, [isMembershipBenefitsFetched, activeMembershipSubscription, dispatch]);

  useEffect(() => {
    // Add existing sections
    existingSections.forEach((item: any) => {
      const isAdded = userChoicesSection.some(
        (choice: any) => choice.sku === item.variant.sku && choice.productId === item.productId
      );
      if (!isAdded) {
        dispatch(addMembershipPackageSectionWithPriceProduct(item.productId, item.variant.sku));
      }
    });

    existingFreeSection.forEach((item: any) => {
      const benefitsDetails = sectionListOfFreeProduct?.filter(benefit => benefit.productId === item.productId);

      const isAdded = userChoicesFreeSection.some(
        (choice: any) => choice.sku === item.variant.sku && choice.productId === item.productId
      );

      if (!isAdded && !userChoicesFreeSection.length && benefitsDetails?.length) {
        dispatch(
          addMembershipPackageSectionFreeProduct({
            productId: benefitsDetails?.[0]?.productId,
            sku: benefitsDetails?.[0]?.variants?.[0]?.sku,
            group: benefitsDetails?.[0]?.includedInBenefit?.value?.[0]?.obj?.id as string,
            groupSku: benefitsDetails?.[0]?.includedInBenefit?.value?.[0]?.obj?.masterData?.current?.masterVariant
              ?.sku as string,
            benefitName: benefitsDetails?.[0]?.name as string,
          })
        );
      }
    });
  }, [existingSections, existingFreeSection, dispatch, benefitsList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageContainer>
      <RenewMembershipDetailsTable
        changeToUpdateProcess={changeToUpdateProcess}
        renewMembershipDetailsTableData={renewMembershipDetailsTableData}
        serviceTierFieldsToBeShown={serviceTierFieldsToBeShown()}
      />
      <div>
        <CartSummaryContainer hideProceedButton={true} getCartFetched={appInitialized} />
      </div>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  background-color: ${props => props.theme.colors.neutralWhite};
  max-width: ${props => props.theme.pxToRem(1280)};
  display: flex;
  width: min(88vw);
  position: relative;
  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    flex-wrap: wrap-reverse;
    justify-content: center;
    width: 92vw;
    position: relative;
    left: calc(-46vw + 49%);
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-wrap: wrap-reverse;
    justify-content: center;
    width: 92vw;
    position: relative;
    left: calc(-46vw + 49%);
  }
`;
