import React from 'react';
import styled from 'styled-components';
import { RenderPromotionalComponent } from 'components//pages/PageToolkit/RenderPromotionalComponent';
import { RenderBlockList } from 'components/pages/PageToolkit/RenderBlockList';
import { LockStateIcon } from 'components//pages/PageToolkit/LockStateIcon';
import { ContentfulHelpers, RichTextBlocks } from 'mxp-utils';
import { Link, LinkEnums, DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { DownloadItem } from 'components/molecules/DownloadItem/DownloadItem';
import ReactMarkdown from 'react-markdown';
import { handleEvent, CLICK } from 'utils/Analytics';
import { addTableSeparator, getContentUrl } from 'utils';
import { ReactComponent as OpenInNew } from 'resources/images/ic-open-in-new.svg';
import { AnchorMarkdownRenderer } from 'components/atoms';
import gfm from 'remark-gfm';

interface Props {
  components: State.ContentCardItem[];
  isBgColorDark: boolean;
}

export const BlockAccordionComponentList: React.FC<Props> = React.memo(({ components, isBgColorDark }) => {
  const renderComponentList = (componentList: State.ContentCardItem[]): React.ReactNode => {
    return componentList?.map((component: State.ContentCardItem, index: number) => {
      const switchValue = component.contentType?.slug || component.contentType;
      const tableMarkdown = addTableSeparator(component?.tableMarkdown as any);

      const handleLink = () => {
        if (switchValue === ContentfulHelpers.CONTENT_TYPES.LINK) {
          handleEvent({ text: `toolkit-block-link:${component.description}:${component.url}` }, CLICK);
        } else {
          handleEvent({ text: `toolkit-accordian:${component.description}:${getContentUrl(component)}` }, CLICK);
        }
      };

      switch (switchValue) {
        case ContentfulHelpers.CONTENT_TYPES.PROMOTIONAL_COMPONENT_BLOCK:
          return (
            <RenderPromotionalComponent
              item={component}
              showDivider={false}
              idx={index}
              key={`toolkit-block-promotional-${index}`}
              testId={`toolkit-block-promotional-${index}`}
            />
          );
        case ContentfulHelpers.CONTENT_TYPES.TABLE:
          return (
            <StyledTable
              key={`toolkit-block-table-${index}`}
              dangerouslySetInnerHTML={{ __html: RichTextBlocks.createTable({ fields: component }) }}
            />
          );
        case ContentfulHelpers.CONTENT_TYPES.LIST_BLOCK:
          return <RenderBlockList item={component} idx={index} key={`list-block-${index}`} />;
        case ContentfulHelpers.CONTENT_TYPES.ADVANCE_TABLE:
          return (
            <StyledAdvancedTable
              plugin={[gfm]}
              renderers={{ link: AnchorMarkdownRenderer }}
              key={`toolkit-block-advancedtable-${index}`}
              className="ui striped table"
              children={tableMarkdown}
            />
          );
        case ContentfulHelpers.CONTENT_TYPES.LINK:
          const isExternal = RichTextBlocks.isExternal(component.url);

          return (
            <StyledLinkBlock key={`toolkit-block-link-${index}`}>
              <StyledLinkTitle
                isExternal={isExternal}
                type={!isBgColorDark ? LinkEnums.type.inlineLink : LinkEnums.type.inlineLinkOnDarkBg}
                to={component.url}
                onClick={handleLink}
              >
                {isExternal && (
                  <StyledIcon isBgColorDark={isBgColorDark} data-testid={`icon-${component.externalUrl}`} />
                )}
                {component.title}
              </StyledLinkTitle>
              <StyledLinkParagraph isBgColorDark={isBgColorDark}>{component.description}</StyledLinkParagraph>
            </StyledLinkBlock>
          );
        case ContentfulHelpers.CONTENT_TYPES.DOWNLOAD:
          return (
            <DownloadItem
              name={component.name ?? ''}
              fileNames={component.fileNames ?? ['']}
              buttonText={component.buttonText ?? ''}
              fileSize={component.fileSize ?? ''}
              fileLink={component.fileLink ?? ''}
            />
          );
        default:
          return (
            <React.Fragment key={`toolkit-block-links-${index}`}>
              <StyledAccordionLinkTitle
                isExternal={!!component.externalUrl}
                type={!isBgColorDark ? LinkEnums.type.inlineLink : LinkEnums.type.inlineLinkOnDarkBg}
                to={getContentUrl(component)}
                onClick={handleLink}
                isBgColorDark={isBgColorDark}
              >
                <LockStateIcon lockStatus={component.isLocked} />
                {component.title}
              </StyledAccordionLinkTitle>
              <StyledAccordionLinkParagraph tabIndex={0} isBgColorDark={isBgColorDark}>
                {component.description}
              </StyledAccordionLinkParagraph>
            </React.Fragment>
          );
      }
    });
  };

  return <>{renderComponentList(components)}</>;
});

const StyledIcon = styled(OpenInNew)<{ isBgColorDark: boolean }>`
  vertical-align: bottom;
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  line-height: ${props => props.theme.pxToRem(16)};
  display: inline-block;
  margin-right: ${props => props.theme.pxToRem(5)};
  margin-bottom: ${props => props.theme.pxToRem(4)};
  path {
    fill: ${props => props.isBgColorDark && props.theme.colors.neutralWhite};
  }
`;

const StyledTable = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow-x: auto;
  }
  & table {
    width: 100%;
    text-align: left;
    margin-bottom: ${props => props.theme.pxToRem(40)};
    border-collapse: collapse;

    tbody {
      tr:nth-child(odd) {
        background-color: ${props => props.theme.colors.neutralWhite};
      }
    }
    th {
      padding: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(23)};
      font-size: ${props => props.theme.fontSizes.xs};
    }

    tr {
      background-color: ${props => props.theme.colors.neutralGrey1};
    }

    td {
      padding: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(23)};
      border-bottom: 1px solid ${props => props.theme.colors.neutralGrey3};
      font-size: ${props => props.theme.fontSizes.s};
    }
  }
`;

const StyledAdvancedTable = styled(ReactMarkdown as any)`
  &&&& {
    text-align: center;
    border-style: solid;
    border-width: 0 0 ${props => props.theme.pxToRem(1)} 0;
    border-color: ${props => props.theme.colors.neutralGrey3};
    margin: 0;
    background: ${props => props.bgColor};
    a {
      font-weight: ${props => props.theme.fontWeights.medium};

      ${DefaultInlineLinkStyles};
    }

    table {
      width: 100%;
      border: 0;
      text-align: left;
      table-layout: fixed;
      overflow: hidden;
      thead tr {
        background-color: ${props => props.theme.colors.neutralGrey1};
      }
      tr {
        :nth-child(2n) {
          background-color: rgba(247, 247, 247, 0.3);
        }
        ${props => props.theme.mediaQueries.mobileOnly} {
          padding-top: ${props => props.theme.pxToRem(40)};
          padding-bottom: ${props => props.theme.pxToRem(24)};
          box-shadow: 0 ${props => props.theme.pxToRem(-1)} 0 0 ${props => props.theme.colors.neutralGrey3} inset !important;
        }
      }
      tr th {
        padding: ${props => props.theme.pxToRem(11)} ${props => props.theme.pxToRem(20)};
        color: ${props => props.theme.colors.neutralGrey8};
        font-size: ${props => props.theme.fontSizes.s};
        font-weight: ${props => props.theme.fontWeights.medium};
        line-height: 1.57;
        min-width: ${props => props.theme.pxToRem(190)};
        :nth-child(2) {
          text-align: left;
        }
        ${props => props.theme.mediaQueries.mobileOnly} {
          padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;
        }
      }
      tr td {
        padding: ${props => props.theme.pxToRem(21)} ${props => props.theme.pxToRem(20)};
        border-top-color: ${props => props.theme.colors.neutralGrey3};
        color: ${props => props.theme.colors.neutralGrey8};
        font-size: ${props => props.theme.fontSizes.s};
        font-weight: ${props => props.theme.fontWeights.light};
        line-height: 1.5;
        min-width: ${props => props.theme.pxToRem(190)};
        :nth-child(2) {
          text-align: left;
        }
        ${props => props.theme.mediaQueries.mobileOnly} {
          padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;
          :first-child {
            font-weight: ${props => props.theme.fontWeights.regular};
          }
          :nth-child(2) {
            text-align: left;
          }
        }
      }
      td {
        word-wrap: break-word;
        overflow: hidden;
      }
      ${props => props.theme.mediaQueries.mobileOnly} {
        border-width: 0;
        margin-top: 0;
      }
    }
  }
`;

const StyledLinkBlock = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  padding-bottom: ${props => props.theme.pxToRem(42)};
`;

const StyledLinkTitle = styled(Link)`
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledLinkParagraph = styled.p<{ isBgColorDark: boolean }>`
  line-height: 1.78;
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  padding-top: ${props => props.theme.pxToRem(8)};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: 10%;
`;

const StyledAccordionLinkTitle = styled(Link)<{ isBgColorDark: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.medium};

  svg path {
    fill: ${props => props.isBgColorDark && props.theme.colors.neutralWhite};
  }
`;

const StyledAccordionLinkParagraph = styled.p<{ isBgColorDark: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.78;
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  padding-bottom: ${props => props.theme.pxToRem(24)};
  padding-top: ${props => props.theme.pxToRem(8)};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: 10%;
`;
