import React from 'react';
import { Product, User } from 'mxp-schemas';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { productAccessConfigSelector } from 'modules/app/selectors';
import { productPageItemSelector } from 'modules/products/selectors';
import { accessProvisionedProduct } from 'modules/products/actions';
import { theme } from 'theme';
import { NotificationBanner, NotificationBannerEnums, Button, ButtonLink, ButtonEnums } from 'components/atoms';
import { Info } from 'components/atoms/svg';
import { AccessOPLProduct } from 'components/molecules/AccessOPLProduct/AccessOPLProduct';
import { AccessMessageInfo } from './pageProductHelper';
import { emptyArray, getPremiumContentToLink } from 'utils';

export const PremiumContentNotificationBanner: React.FC<AccessMessageInfo> = ({ category, message }) => {
  const to = React.useMemo(() => getPremiumContentToLink(category), [category]);

  if (!message) return null;
  return (
    <NotificationBannerStyled
      testId="info-content-access-banner"
      childrenTestId="info-content-access-children"
      variant={NotificationBannerEnums.variant.blue}
      icon={
        <InfoIcon>
          <Info color={theme.colors.neutralWhite} />
        </InfoIcon>
      }
    >
      {message}&nbsp;
      <ButtonLink
        testId="premium-content-notification-banner-access-link-button"
        external
        to={to}
        variant={ButtonEnums.variants.link}
      >
        Access this content now
      </ButtonLink>
    </NotificationBannerStyled>
  );
};

interface SubscriptionNotificationBannerProps {
  isPremiumContent: boolean;
  isOPL: boolean;
  userEmail?: string;
  fvsMembership?: User.MembershipIdsEnum[] | [];
  oktaId?: string;
}

export const SubscriptionNotificationBanner: React.FC<SubscriptionNotificationBannerProps> = ({
  isPremiumContent,
  isOPL,
  userEmail,
  fvsMembership,
  oktaId,
}) => {
  const productItem: Product.ProductItem = useSelector(productPageItemSelector);
  const isSubscribedProducts: Product.ProductItem[] = React.useMemo(() => {
    if (!productItem || isPremiumContent) return emptyArray;
    return [productItem, ...(productItem?.bundleProducts || emptyArray)].filter(i => i.isSubscribed);
  }, [productItem, isPremiumContent]);
  return (
    <>
      {isSubscribedProducts.map((product, index: number) => (
        <SubscriptionNotificationBannerItem
          key={product.productId}
          isRootProduct={product.productId === productItem.productId}
          isLast={index + 1 === isSubscribedProducts.length}
          index={index}
          product={product}
          isOPL={isOPL}
          userEmail={userEmail}
          fvsMembership={fvsMembership}
          oktaId={oktaId}
        />
      ))}
    </>
  );
};

const SubscriptionNotificationBannerItem: React.FC<{
  product: Product.ProductItem;
  isLast: boolean;
  isRootProduct: boolean;
  index: number;
  isOPL: boolean;
  userEmail?: string;
  fvsMembership?: User.MembershipIdsEnum[] | [];
  oktaId?: string;
}> = ({ product, isLast, isRootProduct, index, isOPL, userEmail, fvsMembership, oktaId }) => {
  const { oplRedirectUrl, oplHidUrl }: State.ProductAccess = useSelector(productAccessConfigSelector);
  const dispatch = useDispatch();

  const handleProvisionAccess = React.useCallback(() => {
    dispatch(accessProvisionedProduct(undefined, product));
  }, [dispatch, product]);

  const isCourse: boolean = product.productType === Product.ProductType.COURSE;
  const productTypeName = isCourse ? 'course' : 'subscription';

  return (
    <NotificationBannerStyled
      isLast={isLast}
      testId={`info-subscribed-product-access-banner-${index}`}
      childrenTestId={`info-subscribed-product-access-children-${index}`}
      variant={NotificationBannerEnums.variant.blue}
      icon={
        <InfoIcon>
          <Info color={theme.colors.neutralWhite} />
        </InfoIcon>
      }
    >
      {isRootProduct
        ? `You have an active ${productTypeName} for this product.`
        : `You have an active ${productTypeName} for ${product.name} product in this bundle.`}{' '}
      {isOPL ? (
        <AccessOPLProduct
          testId="info-subscribed-opl-product-access-link-button"
          userEmail={userEmail}
          oktaId={oktaId}
          fvsMembership={fvsMembership}
          oplRedirectUrl={oplRedirectUrl}
          oplHidUrl={oplHidUrl}
        />
      ) : (
        <Button
          testId="info-subscribed-product-access-link-button"
          onClick={handleProvisionAccess}
          variant={ButtonEnums.variants.link}
        >
          Access now.
        </Button>
      )}
    </NotificationBannerStyled>
  );
};

const NotificationBannerStyled = styled<any>(NotificationBanner)`
  &&&&& {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    margin: 0 0 ${props => props.theme.pxToRem(props.isLast ? 32 : 16)} 0;
  }
`;

const InfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.neutralWhite};
  border-radius: 50%;
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  background: ${props => props.theme.colors.interfaceBlue};
`;
