import React from 'react';
import { Product } from 'mxp-schemas';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Expandable } from 'components/atoms/Expandable/Expandable';
import { NotificationForAnonymousUser } from 'components/molecules/NotificationForAnonymousUser';
import { Icon } from 'semantic-ui-react';
import { ParagraphTitle, TitleSizes } from './ProductParagraphs';
import { Grid, Container } from 'components/atoms';
import { productCurrencySelector } from 'modules/products/selectors';
import { useSelector } from 'react-redux';
interface Props {
  attendanceOptions: Product.ConferenceAttendanceOptions[];
  isAuth: boolean | null;
}

export const ConferencePricing: React.FC<Props> = ({ attendanceOptions, isAuth }) => {
  const currency = useSelector(productCurrencySelector);
  const transformedAttendanceOptions = attendanceOptions.map(variant => {
    const priceRange = variant.prices?.map(price => {
      return {
        transformedPrice: parseFloat(price.transformedPrice.replace(currency.sign, '').replace(',', '')),
      };
    });

    const minimumPrice = priceRange
      ?.sort((a, b) => (a.transformedPrice < b.transformedPrice ? -1 : 1))
      .map(minPrice => {
        return {
          minPrice: minPrice.transformedPrice.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        };
      });

    const maximumPrice = priceRange
      ?.sort((a, b) => (a.transformedPrice > b.transformedPrice ? -1 : 1))
      .map(maxPrice => {
        return {
          maxPrice: maxPrice.transformedPrice.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        };
      });

    const startDateTime = moment(variant.prices?.[0]?.variantsWithAvailableFormatInfo?.[0]?.startDateTime).format(
      'MMM D, YYYY'
    );
    const endDateTime = moment(variant.prices?.[0]?.variantsWithAvailableFormatInfo?.[0]?.endDateTime).format(
      'MMM D, YYYY'
    );

    if (!variant.prices?.[0]?.variantsWithAvailableFormatInfo?.length) return null;
    return {
      formatOptionalText:
        variant.format && variant.prices?.[0]?.variantsWithAvailableFormatInfo?.[0]?.optionalText
          ? `${variant.format} - ${variant.prices?.[0]?.variantsWithAvailableFormatInfo?.[0]?.optionalText}`
          : `${variant.format} - ${variant.prices?.[0]?.variantsWithAvailableFormatInfo?.[0]?.optionalText}`?.replace(
              /-/g,
              ''
            ),
      date: `${startDateTime}-${endDateTime}`,
      priceRange:
        minimumPrice?.[0]?.minPrice !== maximumPrice?.[0]?.maxPrice
          ? `${currency.sign}${minimumPrice?.[0]?.minPrice} - ${currency.sign}${maximumPrice?.[0]?.maxPrice}`
          : `${currency.sign}${priceRange?.[0]?.transformedPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}`,
      prices: variant.prices?.map(price => {
        return {
          appliedDiscountName: price.appliedDiscountName,
          transformedPrice: price.transformedPrice,
        };
      }),
    };
  });

  return (
    <MainColumn>
      <StyledTitleConference>
        <StyledParagraphTitle titleSize={TitleSizes.LARGE}>Pricing</StyledParagraphTitle>
        {!isAuth && <StyledNotificationForAnonymousUser />}
      </StyledTitleConference>
      <StyledGrid>
        <Grid.Row verticalAlign="middle" centered={false}>
          {transformedAttendanceOptions.map((variant, variantIndex) => (
            <StyledExpandableWrapper key={`conference-pricing-accordion-${variantIndex}`} width={16}>
              <Expandable
                items={[
                  {
                    id: variantIndex,
                    heading: (
                      <StyledRowContent>
                        <StyledVariant>{`${variant?.formatOptionalText}`}</StyledVariant>
                        <StyledDate>{`${variant?.date}`}</StyledDate>
                        <StyledPriceRange>{`${variant?.priceRange}`}</StyledPriceRange>
                      </StyledRowContent>
                    ),
                    content: variant?.prices.map((price, idx) => (
                      <StyledContent key={`expandable-content-${idx}`}>
                        <StyledDiscount>{`${price.appliedDiscountName}`}</StyledDiscount>
                        <StyledPrice>{`${price.transformedPrice}`}</StyledPrice>
                      </StyledContent>
                    )),
                  },
                ]}
                expandIcon={<ArrowIcon name="angle down" />}
                collapseIcon={<ArrowIcon name="angle up" />}
              />
            </StyledExpandableWrapper>
          ))}
        </Grid.Row>
      </StyledGrid>
    </MainColumn>
  );
};

const StyledTitleConference = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    position: relative;
    right: ${props => props.theme.pxToRem(15)};
  }
`;
const StyledNotificationForAnonymousUser = styled(NotificationForAnonymousUser)`
  && {
    margin: ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(15)} 0;
    font-size: ${props => props.theme.fontSizes.m};
    line-height: 1.78;
  }
`;
const StyledParagraphTitle = styled(ParagraphTitle)`
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-top: ${props => props.theme.pxToRem(20)};
`;

const StyledContent = styled.div`
  display: flex;
  margin: 0 ${props => props.theme.pxToRem(-82)} 0 ${props => props.theme.pxToRem(200)};
  line-height: 2;
`;

const StyledDiscount = styled.div`
  display: flex;
  flex: 1;
  max-width: ${props => props.theme.pxToRem(250)};
  font-weight: ${props => props.theme.fontWeights.thin};
`;

const StyledPrice = styled.div`
  text-align: right;
  margin-left: ${props => props.theme.pxToRem(65)};
  width: ${props => props.theme.pxToRem(100)};
`;

const StyledRowContent = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${props => props.theme.fontSizes.s};
  margin: 0;
`;

const StyledDate = styled.div`
  width: ${props => props.theme.pxToRem(200)};
  margin-left: ${props => props.theme.pxToRem(20)};
  font-weight: ${props => props.theme.fontWeights.thin};
`;

const StyledVariant = styled.div`
  width: ${props => props.theme.pxToRem(180)};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledPriceRange = styled.div`
  text-align: right;
  width: ${props => props.theme.pxToRem(160)};
  font-weight: ${props => props.theme.fontWeights.regular};
  margin-left: ${props => props.theme.pxToRem(54)};
`;

const StyledGrid = styled(Grid)`
  &&&&& {
    margin-left: 0;
    margin-right: 0;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow-y: hidden;
    overflow-x: visible;
  }
`;

const StyledExpandableWrapper = styled(Grid.Column)`
  &&&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(-1)}!important;
    border-top: ${props => props.theme.pxToRem(2)} solid ${props => props.theme.colors.neutralGrey3};
    &:last-child {
      border-bottom: ${props => props.theme.pxToRem(2)} solid ${props => props.theme.colors.neutralGrey3};
    }
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-width: ${props => props.theme.pxToRem(641)};
  }
`;
const MainColumn = styled(Container)`
  font-size: ${props => props.theme.fontSizes.s};
`;
const ArrowIcon = styled(Icon)`
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.l};
`;
