import React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { Product as ProductUtils } from 'mxp-utils';
import {
  didCartTransitionFailSelector,
  hasWebcastPassInCartSelector,
  isCartLoadingSelector,
  numberOfItemsInCartSelector,
} from 'modules/cart/selectors';
import { webcastPassItemSelector, isWebcastPassFetchedSelector } from 'modules/products/selectors';
import { searchPromotionalBannerProductSelector } from 'modules/search/selectors';
import { userRolesSelector } from 'modules/user/selectors';
import { addCartItem } from 'modules/cart';
import { accessProvisionedProduct } from 'modules/products/actions';
import { Button, ButtonSizes, ButtonEnums, Link } from 'components/atoms';
import { getProductStatus } from './pageProductHelper';
import { getPath, priceToFloat } from 'utils';
import { ADD_CART, ADD_CART_FIRST, handleEvent, handleGoogleAdsRemarketing } from 'utils/Analytics';
import {
  CartCompoundAnalytics,
  getOrderTypeAnalytics,
  isB2BMembershipType,
  isB2CMembershipType,
} from 'utils/Analytics/helpers';
import { CART_CTA_TEXT, PRODUCT_CART_STATUS, Routes } from 'constants/index';
import { ReactComponent as WebcastPass } from 'resources/images/new-product-webcast.svg';

export enum WebcastPassPosition {
  DESKTOP = 'right center',
  MOBILE = 'bottom left',
  DESKTOPTOGGLE = 'top right',
  MOBILETOGGLE = 'bottom center',
}

interface Props {
  position: WebcastPassPosition;
  isToggle?: boolean;
}

export const WebcastPassPopup: React.FC<Props> = ({ position, isToggle = false }) => {
  const cartLoading = useSelector(isCartLoadingSelector);
  const cartTransitionError = useSelector(didCartTransitionFailSelector);
  const productItem = useSelector(webcastPassItemSelector);
  const hasWebcastPassInCart = useSelector(hasWebcastPassInCartSelector);
  const userRoles = useSelector(userRolesSelector);
  const numberOfItemsInCart = useSelector(numberOfItemsInCartSelector);
  const isWebcastPassFetched = useSelector(isWebcastPassFetchedSelector);
  const promotionalBannerProduct = useSelector(searchPromotionalBannerProductSelector);
  const dispatch = useDispatch();

  const isSubscribed = Boolean(productItem?.isSubscribed);
  const productButtonStatus: PRODUCT_CART_STATUS = getProductStatus(
    cartTransitionError || (isWebcastPassFetched && !productItem),
    undefined,
    false,
    hasWebcastPassInCart,
    isSubscribed,
    false
  );

  const productQuantity = 1;
  const cartValidation = { quantityValue: productQuantity, isChecked: false };

  const productAvailableQuantity = productItem?.variants[0].availability?.availableQuantity;
  const productIsOnStock = productItem?.variants[0].availability?.isOnStock;
  const enteredQtyIsMoreThanAvailable = Boolean(productAvailableQuantity && productAvailableQuantity < productQuantity);
  const isWebcastPassPublished = Boolean(productItem?.variants[0].published);
  const isDisabled = !productIsOnStock || enteredQtyIsMoreThanAvailable;

  const navigate = (path: string): void => {
    dispatch(push(path));
  };

  const pageButtonHandler = async (
    productCartStatus: PRODUCT_CART_STATUS,
    cartValidations: Common.CartValidation | null
  ) => {
    if (!productItem) return;

    const { variants } = productItem;
    const selectedVariantSKU = variants[0].sku;
    const variantsPricing = ProductUtils.userApplicableVariantsPricing(variants, userRoles, false);
    const variantsPriceInfo = ProductUtils.variantsPriceInfoForUser(variantsPricing);
    const pricing = variantsPriceInfo[selectedVariantSKU || 1];

    if (productCartStatus === PRODUCT_CART_STATUS.ACCESS_NOW) {
      dispatch(accessProvisionedProduct(undefined, productItem));
      return;
    }

    if (productCartStatus === PRODUCT_CART_STATUS.IN_CART) {
      navigate(getPath(Routes.CART_PAGE));
    } else {
      await dispatch(addCartItem(productItem.productId, selectedVariantSKU || '', cartValidations, false, null, false));

      const isB2BMembership = isB2BMembershipType(productItem.productType);
      const isB2CMembership = isB2CMembershipType(productItem.productType);

      const payload: CartCompoundAnalytics = {
        id: [productItem.productId],
        bundleId: [],
        name: [productItem.name],
        category: [productItem.productType],
        price: [pricing && pricing.priceFinal ? priceToFloat(pricing?.priceFinal?.formattedPrice) : 0],
        quantity: [productQuantity],
        sku: [selectedVariantSKU || ''],
        orderType: [
          getOrderTypeAnalytics({
            isStandingOrder: false,
            isBundle: false,
            isFreeTrial: false,
            isB2BMembership,
            isB2CMembership,
          }),
        ],
        shippingCost: [],
        shippingLocation: [],
        shippingType: [],
        productTax: [],
        freeTrialEndDate: [''],
      };

      if (numberOfItemsInCart) {
        handleEvent(payload, ADD_CART);
      } else {
        handleEvent(payload, ADD_CART_FIRST);
      }
      handleGoogleAdsRemarketing(payload);
    }
  };

  const cartHandler = () => {
    pageButtonHandler(productButtonStatus, cartValidation);
  };

  const renderPopupTrigger = (isToggled: boolean) => {
    const StyledTag = styled.div`
      font-size: ${props => props.theme.fontSizes.xxs};
      font-weight: ${props => props.theme.fontWeights.light};
      color: ${props => props.theme.colors.neutralBlack};
      text-transform: uppercase;
      letter-spacing: 0.22px;
      display: flex;
      align-items: center;
      width: max-content;
      padding: ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(4)};
      background-color: ${props => props.theme.colors.neutralGrey2};
      border-radius: ${props => props.theme.pxToRem(2)};

      svg {
        margin-right: ${props => props.theme.pxToRem(5)};
      }
    `;

    const StyledToggleTag = styled.div`
      display: flex;
      align-items: center;
      width: max-content;
      padding: 0 0 ${props => props.theme.pxToRem(6)} ${props => props.theme.pxToRem(4)};
    `;

    const StyledSpan = styled.span`
      text-decoration: underline;
      font-weight: ${props => props.theme.fontWeights.medium};
    `;

    if (!promotionalBannerProduct && !isWebcastPassPublished) return null;

    return isToggled ? (
      <StyledToggleTag>
        <StyledSpan>Webcast Pass</StyledSpan>
      </StyledToggleTag>
    ) : (
      <StyledTag>
        <WebcastPass /> {'Included in Webcast Pass'}
      </StyledTag>
    );
  };

  const renderPopupHeader = <StyledPopupHeader>Annual Webcast Pass</StyledPopupHeader>;

  const renderPopupContent = (
    <StyledPopupContent>
      <div>This subscription gives you access to a full year of travel-free unlimited CPE.</div>
      <StyledPopupContentCTA>
        <Button
          testId="popup-add-to-cart"
          variant={ButtonEnums.variants.primary}
          size={ButtonSizes.small}
          loading={cartLoading || !isWebcastPassFetched}
          onClick={cartHandler}
          disabled={cartTransitionError || isDisabled}
        >
          {CART_CTA_TEXT[productButtonStatus]}
        </Button>
        <span>Or</span>
        <Link testId="popup-cta" to={generatePath(getPath(Routes.ANNUAL_WEBCAST))} target="_blank">
          Find out more
        </Link>
      </StyledPopupContentCTA>
    </StyledPopupContent>
  );

  return isToggle ? (
    <StyledPopup
      key={'webcast-pass-popup-tag-toggle'}
      data-testid="webcast-pass-popup-toggle"
      trigger={renderPopupTrigger(isToggle)}
      header={renderPopupHeader}
      content={renderPopupContent}
      position={position}
      hoverable
      hideOnScroll
    />
  ) : (
    <StyledPopup
      key={'webcast-pass-popup-tag'}
      data-testid="webcast-pass-popup"
      trigger={renderPopupTrigger(isToggle)}
      header={renderPopupHeader}
      content={renderPopupContent}
      position={position}
      hoverable
      hideOnScroll
    />
  );
};

const StyledPopup = styled(Popup)`
  &&&& {
    max-width: ${props => props.theme.pxToRem(330)};
    padding: ${props => props.theme.pxToRem(24)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      max-width: calc(100% - 3em);
    }
  }
`;

const StyledPopupHeader = styled.div`
  font-family: 'Roboto';
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralGrey8};
  display: flex;
  align-items: center;
  padding-bottom: ${props => props.theme.pxToRem(12)};
  margin-bottom: ${props => props.theme.pxToRem(12)};
  border-bottom: 2px solid ${props => props.theme.colors.neutralGrey3};

  & svg {
    margin-right: ${props => props.theme.pxToRem(6)};
    path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyledPopupContent = styled.div`
  font-family: 'Roboto';
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledPopupContentCTA = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme.pxToRem(10)};

  span {
    margin: 0 ${props => props.theme.pxToRem(5)};
  }
`;
