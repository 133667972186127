import React from 'react';
import { ButtonLink } from '../ButtonLink/ButtonLink';
import { isExternal } from 'utils';

export const AnchorMarkdownRenderer: React.FC = (props: any) => {
  const external = isExternal(props?.href);
  const { title, alt, value } = props?.children[0]?.props;
  const linkText = value || title || alt || 'link';

  return (
    <ButtonLink
      {...props}
      as="a"
      buttonText={linkText}
      className={`${external ? 'external' : 'internal'}-link`}
      rel={external ? 'noopener noreferrer' : 'noreferrer'}
      to={props.href}
      isTextLink
      testId={`block-table-anchor-${linkText}`}
    >
      {props.children}
    </ButtonLink>
  );
};
