import { Salesforce, MembershipTypes } from 'mxp-schemas';
import { Epa2SupervisorDeclaration } from './constants';

// ------------------------------------
// Helpers
// ------------------------------------

/*
 * Generate response for update feedback
 */
export const generateResponse = (feedbackDetails: Salesforce.practicalExperienceAssessorFeedback): any => {
  return {
    success: true,
    updateResultMessage:
      feedbackDetails.feedbackStatus === MembershipTypes.PERFeedbackStatus.DRAFT
        ? ''
        : feedbackDetails.feedbackStatus === MembershipTypes.PERFeedbackStatus.APPROVED
        ? 'Your approval has been sent'
        : 'Your request has been sent',
  };
};

export const getEpa2DeclarationText = (epaLevel: MembershipTypes.EPALevel) => {
  if (epaLevel === MembershipTypes.EPALevel.LEVEL4) return Epa2SupervisorDeclaration.LEVEL4;
  if (epaLevel === MembershipTypes.EPALevel.LEVEL7) return Epa2SupervisorDeclaration.LEVEL7;
  return '';
};
export const formatPERDetails = (perDetails: any, isEmploymentPage: boolean, isPERCoreActivityPage: boolean): any => {
  if (isEmploymentPage) {
    return {
      details: {
        bussinessName: perDetails.bussinessName,
        organizationType: perDetails.organizationType,
        addressLineOne: perDetails.addressLineOne,
        employmentType: perDetails.employmentType,
        city: perDetails.city,
        state: perDetails.state,
        postalCode: perDetails.postalCode,
        jobTitle: perDetails.jobTitle,
        startDate: perDetails.startDate,
        endDate: perDetails.endDate,
        numberOfDaysPerWeek: perDetails.numberOfDaysPerWeek,
        summary: perDetails.summary,
      },
      isViewOnly: perDetails.isViewOnly,
      feedback: perDetails.feedback,
      auditId: perDetails.perAuditKey,
      profileHeadingText: `Employer's`,
      headerBackButtonText: 'Employment Information',
      testId: 'employment',
    };
  }
  return {
    details: {
      Situation: perDetails.situation,
      Task: perDetails.task,
      Action: perDetails.action,
      Result: perDetails.result,
    },
    isViewOnly: perDetails.isViewOnly,
    feedback: perDetails.feedback,
    auditId: perDetails.perAuditKey,
    profileHeadingText: 'Activity',
    headerBackButtonText: isPERCoreActivityPage ? 'Core Activity' : 'Skill & Behaviours',
    activityType: perDetails.activityType,
    testId: isPERCoreActivityPage ? 'core-act' : 'skill-behaviour',
  };
};
