import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { PageProduct, Props } from 'components/pages/PageProduct/PageProduct';
import { getPath, emptyObject } from 'utils';
import { Routes } from 'constants/index';
import { User as UserTypes, Content, Product } from 'mxp-schemas';
import { addCartItem, updateCartItem, removeCartItems } from 'modules/cart';
import {
  lineItemsSelector,
  isCartLoadingSelector,
  didCartTransitionFailSelector,
  isExistingCartItemSelector,
  numberOfItemsInCartSelector,
  variantLineItemForSwappingDonationSelector,
  defaultProductVariantWithAttestationSkuSelector,
} from 'modules/cart/selectors';
import {
  isAuthSelector,
  userMemberTypeSelector,
  userPlatformSelector,
  userRolesSelector,
  userEmailSelector,
  userFVSSectionMemberSelector,
  userOktaIdSelector,
  learningPathwaySelector,
} from 'modules/user/selectors';
import {
  getPremiumContentItems,
  getProductItem,
  getRelatedContentList,
  setInitialSelectedBundleProductVariants,
  setSelectedBundleProductVariant,
  setSelectedVariant,
  accessProvisionedProduct,
  setSelectedDonationPrice,
  setInitialSelectedContributionsVariants,
  getWebcastPass,
} from 'modules/products/actions';
import {
  allUserPricesForSelectedVariantSelector,
  isAvailableForSaleSelector,
  isProductWithMultipleOptionsSelectors,
  isProductFetchedSelector,
  productPageItemSelector,
  relatedProductSelector,
  selectedVariantPriceInfoForUserSelector,
  selectedVariantSKUSelector,
  showCCSelector,
  variantDateTimeSelector,
  priceRangeSelector,
  productFormatLabelSelector,
  productTypeLabelSelector,
  variantOptionPriceSelector,
  variantAdditionalDetails,
  attendanceOptionsSelector,
  priceRangeForUserSelector,
  firstDateRangeSelector,
  creditRangeSelector,
  yellowBookHoursRangeSelector,
  examMasterVariantSkuSelector,
  selectedExamVariantFormatLabelSelector,
  calculatedAccessDurationSelector,
  variantsCreditsInfoSelector,
  variantsAvailabilityInfoSelector,
  availableFormatsSelector,
  isTransferableProductTypeSelector,
  isProductTypeWithMultipleOptionsSelector,
  isPhysicalProductSelector,
  isPhPrSubscriptionSelector,
  thirdPartyLinkSelector,
  isPremiumContentSelector,
  formattedProductChildrenInfoSelector,
  productChildrenCountSelector,
  variantIsbnSelector,
  getProductIncludedMembershipSelector,
  getProductContentRoleIdSelector,
  premiumContentItemsSelector,
  bundleItemsSelectionInfoSelector,
  bundleSummedPricesSelector,
  productItemBundlesFormattedAsContentCardsSelector,
  variantsBundleItemsPriceInfoForUserSelector,
  variantsBundleItemsWithOutOfStockCheck,
  hasAttestationSelector,
  hasAttestationVariantsSelector,
  donationPriceSelector,
  isContributionSelector,
  isProductHasUserPriceSelector,
  maxProductFreeTrialDaysSelector,
  isFreeTrialSelectedSelector,
  isConferenceSelector,
  productHasOnlineVariantsSelector,
  freeTrialEndDateSelector,
  bundleProductsSelector,
} from 'modules/products/selectors';
import { fullUrlSelector, appInitializedSelector } from 'modules/app/selectors';
import { addBundleCartItemWithCheckForExistingVariants } from 'modules/cart/index';

const mapActionCreators = (dispatch: Dispatch) => ({
  async getProductPageData(slug: string): Promise<void> {
    await dispatch(getProductItem(slug)).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
    dispatch(setInitialSelectedBundleProductVariants());
    dispatch(setInitialSelectedContributionsVariants());
    await Promise.all([dispatch(getRelatedContentList()), dispatch(getPremiumContentItems())]);
  },
  addItemToCart(productId: string, variantSKU: string, cartValidation: object | null, freeTrial: boolean): void {
    const fromQueryParam = false;
    const asPartOfToBundleId = null;
    dispatch(addCartItem(productId, variantSKU, cartValidation, fromQueryParam, asPartOfToBundleId, freeTrial));
  },
  async swapItemInCart(
    productId: string,
    variantSKU: string,
    cartValidation: object | null,
    lineItemId: string | null | undefined
  ): Promise<void> {
    await dispatch(addCartItem(productId, variantSKU, cartValidation));
    dispatch(removeCartItems(lineItemId));
  },
  removeCartItems(lineItemId: string[]): void {
    dispatch(removeCartItems(lineItemId));
  },
  addBundleToCart(): void {
    dispatch(addBundleCartItemWithCheckForExistingVariants());
  },
  updateCartItem(quantity: number, standingOrder: boolean, lineItemId: string, donationPrice?: string): void {
    dispatch(updateCartItem(quantity, standingOrder, lineItemId, '', donationPrice));
  },
  navigate(path: string): void {
    dispatch(push(path));
  },
  setSelectedVariant(variantSKU: string, isFreeTrialSelected: boolean): void {
    dispatch(setSelectedVariant({ sku: variantSKU, isFreeTrialSelected }));
  },
  accessProvisionedItem(product: Product.ProductItem) {
    dispatch(accessProvisionedProduct(undefined, product));
  },
  setSelectedBundleProductVariant(variant: Common.BundleProductVariant): void {
    dispatch(setSelectedBundleProductVariant(variant));
  },
  setSelectedDonationPrice(selectedDonationPrice: string): void {
    dispatch(setSelectedDonationPrice(selectedDonationPrice));
  },
  async getWebcastPass(): Promise<void> {
    await dispatch(getWebcastPass());
  },
});

const mapStateToProps = (
  state: State.Root,
  ownProps: {
    match: {
      path: string;
      params: { productTypeSlug: string; slug: string };
    };
  }
) => {
  const { match } = ownProps;
  const { productTypeSlug = '', slug = '' } = match?.params || emptyObject;
  const userPlatform: UserTypes.UserPlatform | null = userPlatformSelector(state);
  const thirdPartyLink = thirdPartyLinkSelector(state);
  const isDonationURL: boolean = match?.path?.split('/')?.[1] === Content.CategorySlugs.DONATIONS;

  return {
    isProductFetched: isProductFetchedSelector(state),
    productItem: productPageItemSelector(state),
    cartItems: lineItemsSelector(state),
    relatedContent: relatedProductSelector(state),
    numberOfItemsInCart: numberOfItemsInCartSelector(state),
    slug,
    variantsDateTimeInfo: variantDateTimeSelector(state),
    variantsOptionPrice: variantOptionPriceSelector(state),
    priceRangeForUser: priceRangeForUserSelector(state),
    pricing: selectedVariantPriceInfoForUserSelector(state),
    isExistingCartItem: isExistingCartItemSelector(state),
    isCartLoading: isCartLoadingSelector(state),
    didCartTransitionFail: didCartTransitionFailSelector(state),
    showCC: showCCSelector(state),
    allPrices: allUserPricesForSelectedVariantSelector(state),
    selectedVariantSKU: selectedVariantSKUSelector(state),
    isAvailableForSale: isAvailableForSaleSelector(state),
    isProductWithMultipleOptions: isProductWithMultipleOptionsSelectors(state),
    priceRange: priceRangeSelector(state),
    isAuth: isAuthSelector(state),
    userEmail: userEmailSelector(state),
    fvsMembership: userFVSSectionMemberSelector(state),
    oktaId: userOktaIdSelector(state),
    productTypeSlug,
    productTypeLabel: productTypeLabelSelector(state),
    productFormatLabel: productFormatLabelSelector(state),
    attendanceOptions: attendanceOptionsSelector(state),
    creditRange: creditRangeSelector(state),
    yellowBookHoursRange: yellowBookHoursRangeSelector(state),
    dateRange: firstDateRangeSelector(state),
    additionalDetails: variantAdditionalDetails(state),
    fullUrl: fullUrlSelector(state),
    examMasterVariantSku: examMasterVariantSkuSelector(state),
    selectedExamVariantFormatLabel: selectedExamVariantFormatLabelSelector(state),
    userMemberType: userMemberTypeSelector(state),
    calculatedAccessDuration: calculatedAccessDurationSelector(state),
    variantsCreditsInfo: variantsCreditsInfoSelector(state),
    variantsAvailabilityInfo: variantsAvailabilityInfoSelector(state),
    availableFormats: availableFormatsSelector(state),
    isTransferableProductType: isTransferableProductTypeSelector(state),
    isProductTypeWithMultipleOptions: isProductTypeWithMultipleOptionsSelector(state),
    isPhysicalProduct: isPhysicalProductSelector(state),
    isPhPrSubscription: isPhPrSubscriptionSelector(state),
    isPremiumContent: isPremiumContentSelector(state),
    thirdPartyLink,
    formattedProductChildrenInfo: formattedProductChildrenInfoSelector(state),
    productChildrenCount: productChildrenCountSelector(state),
    isbn: variantIsbnSelector(state),
    userRoles: userRolesSelector(state),
    productIncludedMembership: getProductIncludedMembershipSelector(state),
    productContentRoleId: getProductContentRoleIdSelector(state),
    premiumContentItems: premiumContentItemsSelector(state),
    userPlatform,
    bundleItemsSelectionInfo: bundleItemsSelectionInfoSelector(state),
    bundleSummedPrices: bundleSummedPricesSelector(state),
    bundleProductsCardItems: productItemBundlesFormattedAsContentCardsSelector(state),
    bundleItemVariantPrices: variantsBundleItemsPriceInfoForUserSelector(state),
    bundleItemsOutOfStockInfo: variantsBundleItemsWithOutOfStockCheck(state),
    isDonationURL,
    hasAttestation: hasAttestationSelector(state),
    hasAttestationVariants: hasAttestationVariantsSelector(state),
    variantLineItemForSwappingDonation: variantLineItemForSwappingDonationSelector(state),
    defaultProductVariantWithAttestationSku: defaultProductVariantWithAttestationSkuSelector(state),
    donationPrice: donationPriceSelector(state),
    isContribution: isContributionSelector(state),
    isProductHasUserPrice: isProductHasUserPriceSelector(state),
    maxProductFreeTrialDays: maxProductFreeTrialDaysSelector(state),
    isFreeTrialSelected: isFreeTrialSelectedSelector(state),
    isConference: isConferenceSelector(state),
    hasOnlineConference: productHasOnlineVariantsSelector(state),
    freeTrialEndDate: freeTrialEndDateSelector(state),
    learningPathway: learningPathwaySelector(state),
    bundleProducts: bundleProductsSelector(state),
    appInitialized: appInitializedSelector(state),
  };
};

const frontload = async (props: Props) => {
  // TODO: This should be done or supressed in Routes not in here.
  if ((props as any)?.match?.url?.split('?')?.[0]?.startsWith(getPath(Routes.PRODUCT_AGGS_CALENDAR))) {
    return;
  }
  if (props.slug) {
    return props.getProductPageData(props.slug);
  }
};

const options = {
  onUpdate: true,
  _experimental_updateFunc: (prevProps: Props, newProps: Props) =>
    prevProps.slug !== newProps.slug ||
    prevProps.isAuth !== newProps.isAuth ||
    prevProps.appInitialized !== newProps.appInitialized,
};

export const PageProductContainer = connect(
  mapStateToProps,
  mapActionCreators
)(frontloadConnect(frontload, options)(PageProduct));
