import React from 'react';
import { generatePath } from 'react-router-dom';
import { ListMenuMobileItem } from './ListMenuMobileItem';
import { getPath } from 'utils';
import { Routes, UserDropdownOptionLabels, OrganizationSectionLabels } from 'constants/index';
import {
  isCentersClientAdminSelector,
  isClientAdminSelector,
  isFirmBillingClientAdminSelector,
  userPlatformSelector,
} from 'modules/user/selectors';
import { useSelector } from 'react-redux';
import { User } from 'mxp-schemas';

interface Props {
  label: UserDropdownOptionLabels | OrganizationSectionLabels;
  goToInternal: (route: string) => void;
  goToSubMenu?: (() => void) | null;
}

export const ListMenuMobile: React.FC<Props> = ({ label, goToInternal, goToSubMenu = null }) => {
  const platform = useSelector(userPlatformSelector);
  const isClientAdmin = useSelector(isClientAdminSelector);
  const isFirmBillingClientAdmin = useSelector(isFirmBillingClientAdminSelector);
  const isCentersClientAdmin = useSelector(isCentersClientAdminSelector);

  switch (label) {
    case UserDropdownOptionLabels.MyProfile:
      return <ListMenuMobileItem goToInternal={goToInternal} route={getPath(Routes.PROFILE_ROOT)} label={label} />;
    case UserDropdownOptionLabels.MyCommunications:
      return (
        <ListMenuMobileItem goToInternal={goToInternal} route={getPath(Routes.PROFILE_PREFERENCES)} label={label} />
      );
    case UserDropdownOptionLabels.MyPurchases:
      return <ListMenuMobileItem goToInternal={goToInternal} route={getPath(Routes.PROFILE_PURCHASES)} label={label} />;
    case UserDropdownOptionLabels.MyWallet:
      return <ListMenuMobileItem goToInternal={goToInternal} route={getPath(Routes.PROFILE_WALLET)} label={label} />;
    case UserDropdownOptionLabels.MyReceipts:
      return <ListMenuMobileItem goToInternal={goToInternal} route={getPath(Routes.PROFILE_RECEIPTS)} label={label} />;
    case UserDropdownOptionLabels.MySavedItems:
      return (
        <ListMenuMobileItem goToInternal={goToInternal} route={getPath(Routes.PROFILE_SAVED_ITEMS)} label={label} />
      );
    case UserDropdownOptionLabels.MyContactPreferences:
      return platform !== User.UserPlatform.LEGACY ? (
        <ListMenuMobileItem
          goToInternal={goToInternal}
          route={getPath(Routes.PROFILE_CONTACT_PREFERENCES)}
          label={label}
        />
      ) : null;
    case UserDropdownOptionLabels.MyOrganizationProfile:
      return isClientAdmin ? (
        <ListMenuMobileItem
          goToSubMenu={goToSubMenu}
          goToInternal={goToInternal}
          route={getPath(Routes.ORG_ROOT)}
          label={label}
        />
      ) : null;
    case UserDropdownOptionLabels.FirmAdmin:
      return isFirmBillingClientAdmin ? (
        <ListMenuMobileItem
          goToSubMenu={goToSubMenu}
          goToInternal={goToInternal}
          route={generatePath(getPath(Routes.FIRM_ROOT))}
          label={label}
        />
      ) : null;
    case UserDropdownOptionLabels.CenterMembershipAdmin:
      return isCentersClientAdmin ? (
        <ListMenuMobileItem
          goToSubMenu={goToSubMenu}
          goToInternal={goToInternal}
          route={generatePath(getPath(Routes.CENTER_ADMIN_ROOT))}
          label={label}
        />
      ) : null;

    case UserDropdownOptionLabels.TuitionProvider:
      return isCentersClientAdmin ? (
        <ListMenuMobileItem
          goToSubMenu={goToSubMenu}
          goToInternal={goToInternal}
          route={generatePath(getPath(Routes.TUITION_PROVIDER_ROOT))}
          label={label}
        />
      ) : null;

    // ORG PROFILE
    case OrganizationSectionLabels.SeatManagement:
      return (
        <ListMenuMobileItem goToInternal={goToInternal} route={getPath(Routes.ORG_SEAT_MANAGEMENT)} label={label} />
      );
    case OrganizationSectionLabels.Invoices:
      return <ListMenuMobileItem goToInternal={goToInternal} route={getPath(Routes.ORG_INVOICES)} label={label} />;
    default:
      return null;
  }
};
