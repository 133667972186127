import * as Sentry from '@sentry/browser';
import { handleActions } from 'redux-actions';
import {
  getDocumentUpload,
  getDocumentCima,
  getDocumentFiles,
  setError,
  setLoading,
  uploadDocument,
  uploadDocumentCima,
  getDocumentName,
  getDocumentExamExemption,
} from './actions';
import { initialState } from './constants';

if (
  [
    getDocumentUpload,
    getDocumentCima,
    getDocumentFiles,
    setLoading,
    setError,
    uploadDocument,
    uploadDocumentCima,
  ].includes(undefined)
) {
  const message: string = 'getAddress module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [getDocumentUpload]: {
    next: (state: State.DocumentUpload): State.DocumentUpload => ({
      ...state,
      loading: false,
      error: null,
    }),
    throw: (
      state: State.DocumentUpload,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.DocumentUpload => ({
      ...state,
      loading: false,
      error,
    }),
  },
  [getDocumentCima]: {
    next: (state: State.DocumentUpload): State.DocumentUpload => ({
      ...state,
      loading: false,
      error: null,
    }),
    throw: (
      state: State.DocumentUpload,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.DocumentUpload => ({
      ...state,
      loading: false,
      error,
    }),
  },
  [getDocumentFiles]: {
    next: (state: State.DocumentUpload): State.DocumentUpload => ({
      ...state,
      loading: false,
      error: null,
    }),
    throw: (
      state: State.DocumentUpload,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.DocumentUpload => ({
      ...state,
      loading: false,
      error,
    }),
  },
  [getDocumentExamExemption]: {
    next: (state: State.DocumentUpload): State.DocumentUpload => ({
      ...state,
      loading: false,
      error: null,
    }),
    throw: (
      state: State.DocumentUpload,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.DocumentUpload => ({
      ...state,
      loading: false,
      error,
    }),
  },
  [uploadDocument]: {
    next: (state: State.DocumentUpload): State.DocumentUpload => ({
      ...state,
      loading: false,
      error: null,
    }),
    throw: (
      state: State.DocumentUpload,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.DocumentUpload => ({
      ...state,
      loading: false,
      error,
    }),
  },
  [uploadDocumentCima]: {
    next: (state: State.DocumentUpload): State.DocumentUpload => ({
      ...state,
      loading: false,
      error: null,
    }),
    throw: (
      state: State.DocumentUpload,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.DocumentUpload => ({
      ...state,
      loading: false,
      error,
    }),
  },
  [setError]: (state: State.DocumentUpload, action: { payload: CustomErrors.GraphQLError }): State.DocumentUpload => ({
    ...state,
    error: action.payload,
  }),
  [setLoading]: (state: State.DocumentUpload, action: { payload: boolean }): State.DocumentUpload => ({
    ...state,
    loading: action.payload,
  }),
  [getDocumentName]: (state: State.DocumentUpload, action: { payload: string }): State.DocumentUpload => ({
    ...state,
    fileName: action.payload,
  }),
};

export { getDocumentUpload, setLoading, setError, uploadDocument, getDocumentName };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
