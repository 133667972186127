import { PageAdminBulkRenewal } from 'components/admin/pages/PageAdminLookUp/PageAdminBulkRenewal';
import { getRecentDownloadedReport, getRecentTransaction, getTransactionHistory } from 'modules/admin/actions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  bulkRenewalHistorySelector,
  isReportHistoryLoadingSelector,
  isTransactionHistoryLoadingSelector,
  preSignedUrlSelector,
  generateBulkRenewalReportSelector,
  recentTransactionSelector,
  reportHistorySelector,
} from 'modules/admin/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  async getTransactionHistory(): Promise<void> {
    await dispatch(getTransactionHistory());
  },

  async getRecentDownloadedReport(): Promise<void> {
    await dispatch(getRecentDownloadedReport());
  },

  async getRecentTransaction(): Promise<void> {
    await dispatch(getRecentTransaction());
  },
});

const mapStateToProps = (state: State.Root) => ({
  recentTransaction: recentTransactionSelector(state),
  bulkRenewalHistory: bulkRenewalHistorySelector(state),
  reportHistory: reportHistorySelector(state),
  preSignedUrl: preSignedUrlSelector(state),
  recentlyGenerated: generateBulkRenewalReportSelector(state),
  isTransactionHistoryLoading: isTransactionHistoryLoadingSelector(state),
  isReportHistoryLoading: isReportHistoryLoadingSelector(state),
});

export const PageAdminBulkRenewalContainer = connect(mapStateToProps, mapActionCreators)(PageAdminBulkRenewal);
