import React from 'react';
import styled from 'styled-components';
import { Container, Grid, Divider } from 'semantic-ui-react';
import { Survey } from 'components/molecules/Survey/Survey';
import { ArticleFeedbackPrompt } from 'components/molecules/ArticleFeedback/ArticleFeedbackPrompt';
import { positiveOptions, negativeOptions } from './TellUsSurveyData';
import { Modal, Heading, Button, ButtonEnums, IconButton } from 'components/atoms';
import { getPath } from 'utils';
import { SURVEY_FEEDBACK_REVIEW, SURVEY_FEEDBACK_SUBMIT, handleEvent } from 'utils/Analytics';
import { ThumbDirection, Routes } from 'constants/index';
import { SurveyCheck } from 'components/atoms/svg';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';

interface Props {
  submitFeedback: (...args: any[]) => void;
  show: boolean;
  onClose: () => void;
  navigate: (path: string) => void;
}

export const TellUsSurvey: React.FC<Props> = React.memo(({ submitFeedback, show, onClose, navigate }) => {
  React.useEffect(() => {
    if (show) navigate(getPath(Routes.TELLUS_SURVEY_HASH));
  }, [navigate, show]);

  const [thumbState, setThumbs] = React.useState<string>('');
  const [isSubmitted, setSubmitted] = React.useState<boolean>(false);

  const handleThumbsUp = React.useCallback(() => {
    setThumbs(ThumbDirection.UP);
    handleEvent({ score: ThumbDirection.UP }, SURVEY_FEEDBACK_REVIEW);
  }, [setThumbs]);

  const handleThumbsDown = React.useCallback(() => {
    setThumbs(ThumbDirection.DOWN);
    handleEvent({ score: ThumbDirection.DOWN }, SURVEY_FEEDBACK_REVIEW);
  }, [setThumbs]);

  const handleSubmit = React.useCallback(
    values => {
      submitFeedback('tellUs survey', values);
      handleEvent({ score: thumbState, feedback: values }, SURVEY_FEEDBACK_SUBMIT);
      setSubmitted(true);
    },
    [submitFeedback, setSubmitted, thumbState]
  );

  const closeModal = React.useCallback(() => {
    setSubmitted(false);
    setThumbs('');
    navigate(getPath(Routes.BLANK_HASH));
    onClose();
  }, [onClose, navigate]);

  const handleOnClose = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleOnCloseIcon = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <StyledModal size="fullscreen" open={show} testId="survey-tell-us-modal" onClose={handleOnClose}>
      <StyledHeaderBorder />
      <Grid>
        <StyledIconButton
          data-testid="close-survey-tell-us-modal"
          aria-label="Close Survey Tell Us Modal"
          onClick={handleOnCloseIcon}
        >
          <IconClose />
        </StyledIconButton>
        {!isSubmitted && (
          <>
            <Container>
              <StyledHeading>Tell us what you think about the new website.</StyledHeading>
              <ArticleFeedbackPrompt
                heading=""
                subheading=""
                testId="survey-tell-us-thumbs"
                actionDown={handleThumbsDown}
                actionUp={handleThumbsUp}
                isUpHighlighted={thumbState === ThumbDirection.UP}
                isDownHighlighted={thumbState === ThumbDirection.DOWN}
              />
              <StyledDivider />
            </Container>

            {thumbState === ThumbDirection.UP && (
              <Grid.Row centered>
                <StyledSurvey
                  isPositive
                  options={positiveOptions}
                  submit={handleSubmit}
                  testId="survey-tell-us-positive"
                />
              </Grid.Row>
            )}

            {thumbState === ThumbDirection.DOWN && (
              <Grid.Row centered>
                <StyledSurvey options={negativeOptions} submit={handleSubmit} testId="survey-tell-us-negative" />
              </Grid.Row>
            )}
          </>
        )}
        {isSubmitted && (
          <>
            <StyledSubmittedRow centered>
              <StyledCheckContainer>
                <StyledCheck />
              </StyledCheckContainer>
            </StyledSubmittedRow>
            <Grid.Row centered>
              <StyledSubmittedTitle>Thanks for your feedback!</StyledSubmittedTitle>
            </Grid.Row>
            <Grid.Row centered>
              <StyledSubmittedText>We will aim to incorporate this into our next update.</StyledSubmittedText>
            </Grid.Row>
            <Grid.Row centered>
              <StyledGoBackButton
                testId="survey-tell-us-back-button"
                onClick={handleOnClose}
                variant={ButtonEnums.variants.primary}
                bordered
              >
                Back to previous page
              </StyledGoBackButton>
            </Grid.Row>
          </>
        )}
      </Grid>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  &&& {
    &.fullscreen {
      width: 100% !important;
      top: 0;
      margin: 0 !important;
      border-radius: 0;
      overflow-y: scroll;
    }
    height: 100%;
  }
`;

const StyledHeaderBorder = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  backdrop-filter: blur(10px);
  background-image: linear-gradient(to right, #6a2d87, #a93d69);
`;

const StyledCheck = styled(SurveyCheck)`
  width: 52px;
  height: 52px;
`;

const StyledCheckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 1px ${props => props.theme.colors.neutralGrey2};
  background-color: ${props => props.theme.colors.neutralGrey1};
`;

const StyledDivider = styled(Divider)`
  &&& {
    margin: 5.5rem 0 4.5rem 0;
    width: 100%;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 3rem;
  height: 3rem;
  background-color: ${props => props.theme.colors.neutralGrey1};
  border-radius: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    flex-shrink: 0;
    fill: ${props => props.theme.colors.primaryPurple};
    width: ${props => props.theme.pxToRem(32)};
    height: ${props => props.theme.pxToRem(32)};
  }
`;

const StyledSubmittedTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledSubmittedText = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledHeading = styled(Heading)`
  &&& {
    padding-top: 3rem;
    padding-bottom: 0.5rem;
    font-size: ${props => props.theme.fontSizes.xl};
    color: ${props => props.theme.colors.neutralGrey8};
    text-align: center;
    font-weight: ${props => props.theme.fontWeights.light};
    font-family: ${props => props.theme.fontFamily};
  }
`;

const StyledSurvey = styled(Survey)`
  text-align: left;
`;

const StyledSubmittedRow = styled(Grid.Row)`
  margin-top: 20vh;
`;

const StyledGoBackButton = styled(Button)`
  &&&& {
    margin-top: 40px;
    padding: 0.6878em 1.313em;
  }
`;
