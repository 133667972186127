import React from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Link } from 'components/atoms';
import { ReactComponent as KeyboardArrowRight } from 'resources/images/icon-dev-ic-keyboard-arrow-right.svg';

interface Props {
  testId: string;
  to?: string;
  ctaText?: string;
  icon?: React.ReactNode;
}

export const ConferenceAgendaBlock: React.FC<Props> = ({ testId, to = '', ctaText = '', icon }) => {
  return (
    <Wrapper data-testid={testId}>
      <StyledLink to={to} isExternal={to.includes('http')} isBlockLink>
        <StyledSpan>
          {icon}
          <StyledText>{ctaText}</StyledText>
          <StyledIcon />
        </StyledSpan>
      </StyledLink>
    </Wrapper>
  );
};

const Wrapper = styled.div<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  position: relative;
  max-width: ${props => props.theme.pxToRem(360)};
  height: ${props => props.theme.pxToRem(126)};
  padding: ${props => `${props.theme.pxToRem(36)} ${props.theme.pxToRem(24)} ${props.theme.pxToRem(20)}`};
  border-style: solid;
  border-width: ${props => props.theme.pxToRem(1)};
  border-color: rgba(114, 36, 108, 0.4);
  border-radius: ${props => props.theme.pxToRem(10)};
  background-color: ${props => props.theme.colors.neutralWhite};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(335)};
    height: ${props => props.theme.pxToRem(117)};
  }
`;

const StyledLink = styled(Link)`
  position: static;
  display: block;
  margin-bottom: ${props => props.theme.pxToRem(24)};
  text-decoration: none;

  :hover,
  :active,
  :focus,
  :visited {
    text-decoration: none;
    color: ${props => props.theme.colors.neutralGrey8};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(6)};
  }
`;

const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

const StyledText = styled.div`
  margin-left: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.pxToRem(24)};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledIcon = styled(KeyboardArrowRight)`
  margin-left: auto;
  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;
