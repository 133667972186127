import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import styled, { ThemedStyledProps, DefaultTheme, css } from 'styled-components';
import { handleEvent, NAV_CLICK, EXTERNAL_LINK_EVENT } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
import { isExternal as isExt } from 'utils';

enum LinkUnderline {
  noUnderlineOnHover = 'noUnderlineOnHover',
}

enum LinkTypes {
  inlineLink = 'inlineLink',
  inlineLinkOnDarkBg = 'inlineLinkOnDarkBg',
  standaloneLink = 'standaloneLink',
  standaloneLinkOnDarkBg = 'standaloneLinkOnDarkBg',
  standaloneLinkOnDarkText = 'standaloneLinkOnDarkText',
}

export const LinkEnums = {
  underline: LinkUnderline,
  type: LinkTypes,
};

interface LinkProps {
  children?: any;
  to: LocationDescriptor<{ prevRoute: string }>;
  testId?: string;
  isExternal?: boolean;
  target?: string;
  rel?: string;
  underline?: LinkUnderline;
  className?: string;
  type?: LinkTypes;
  isBlockLink?: boolean; // is link wrapped around a component?
  isTextLink?: boolean;
  overrideAnalytics?: boolean;
  viewTextForAnalytics?: string;
  disabled?: boolean;
  buttonText?: string;
  textUnderline?: boolean;
  onClick?: (event?: any) => void;
}

export const Link: React.FC<LinkProps> = ({
  children,
  to,
  testId,
  isExternal = false,
  target,
  rel = 'noopener noreferrer',
  underline,
  className,
  type = LinkTypes.inlineLink,
  isBlockLink = false,
  isTextLink = false,
  overrideAnalytics = false,
  viewTextForAnalytics = '',
  disabled = false,
  buttonText,
  onClick,
  ...props
}) => {
  // to get the current page data before any location change happen in redux router
  const { pageName, siteSection } = getPageNameAndSiteSection();
  const destination = typeof to === 'object' ? `${to?.pathname}${to?.hash || ''}` : to;
  const external = isExternal || isExt(destination);

  // analytics
  const handleLinkEvent = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (overrideAnalytics) return;
      const source = isBlockLink ? 'card' : isTextLink ? 'text' : 'button';
      const destinationType = external ? 'ext' : 'int';
      const btnText = buttonText || (event.target as HTMLAnchorElement)?.textContent || 'NA';
      if (destination) {
        if (external) {
          return handleEvent(
            {
              text: `${source}:link:${destinationType}::${btnText}:${viewTextForAnalytics}`,
              href: destination,
              buttonText: btnText,
            },
            EXTERNAL_LINK_EVENT
          );
        }
        return handleEvent(
          {
            clickValue: viewTextForAnalytics
              ? `${source}:${viewTextForAnalytics}:${destinationType}:${btnText}`
              : `${source}:link:${destinationType}::${btnText}:${destination}`,
            pageName,
            siteSection,
          },
          NAV_CLICK
        );
      }
    },
    [
      external,
      isBlockLink,
      isTextLink,
      overrideAnalytics,
      viewTextForAnalytics,
      buttonText,
      pageName,
      siteSection,
      destination,
    ]
  );

  const handleOnClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (onClick) {
        onClick(event);
      }
      if (!overrideAnalytics) {
        handleLinkEvent(event);
      }
    },
    [onClick, handleLinkEvent, overrideAnalytics]
  );

  if (external) {
    return (
      <StyledExternalLink
        href={destination as string}
        onClick={handleOnClick}
        underline={underline}
        className={className}
        data-testid={`external-link-${testId}`}
        rel={rel}
        type={type}
        target={target || '_blank'}
        {...props}
      >
        {children}
      </StyledExternalLink>
    );
  }
  return (
    <StyledLink
      as={RouterLink}
      to={destination}
      data-testid={testId}
      className={className}
      onClick={handleOnClick}
      underline={underline}
      type={type}
      target={target}
      disabled={disabled}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export const DefaultInlineLinkStyles = css`
  color: ${props => props.theme.colors.primaryPurple};
  text-decoration: underline;

  :visited {
    color: ${props => props.theme.colors.primaryLightPurple};
  }

  :hover,
  :focus,
  :active {
    color: ${props => props.theme.colors.interfaceBlue};
    text-decoration: none;
  }
`;

export const InlineLinkOnDarkBgStyles = css`
  color: ${props => props.theme.colors.neutralWhite};
  text-decoration: underline;

  :visited {
    color: ${props => props.theme.colors.neutralWhite};
  }

  :hover,
  :focus,
  :active {
    color: ${props => props.theme.colors.interfaceBlue};
    text-decoration: none;
  }
`;

const StyledLink = styled.a<ThemedStyledProps<Partial<LinkProps>, DefaultTheme>>`
  cursor: pointer;

  ${props => props.type === LinkTypes.inlineLink && DefaultInlineLinkStyles};

  ${props => props.type === LinkTypes.inlineLinkOnDarkBg && InlineLinkOnDarkBgStyles};

  ${props =>
    props.type === LinkTypes.standaloneLink &&
    `
      color: ${props.theme.colors.primaryPurple};
      text-decoration: none;

      &:hover,
      &:focus {
        color: ${props.theme.colors.primaryPurple};
        text-decoration: ${props.underline === LinkUnderline.noUnderlineOnHover ? 'none' : 'underline'};
      }
  `};

  ${props =>
    props.type === LinkTypes.standaloneLinkOnDarkBg &&
    `
      color: ${props.theme.colors.neutralWhite};
      text-decoration: none;

      &:hover,
      &:focus {
        color: ${props.theme.colors.neutralWhite};
        text-decoration: ${props.underline === LinkUnderline.noUnderlineOnHover ? 'none' : 'underline'};
      }
  `};

  ${props =>
    props.type === LinkTypes.standaloneLinkOnDarkText &&
    `
      color: ${props.theme.colors.neutralBlack};
      text-decoration: none;

      &:hover,
      &:focus {
        color: ${props.theme.colors.neutralBlack};
        text-decoration: ${props.underline === LinkUnderline.noUnderlineOnHover ? 'none' : 'underline'};
      }
  `};

  ${props =>
    props.disabled &&
    `
      color: ${props.theme.colors.neutralGrey6} !important;
      pointer-events: none !important;
      cursor: not-allowed;
  `}
`;
export const ExternalLinkStyles = css`
  color: ${props => props.theme.colors.neutralGrey8};
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.primaryPurple};
    text-decoration: underline;
  }

  &:visited {
    cursor: pointer;
    color: ${props => props.theme.colors.primaryPurple};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ExternalLinkStylesDark = css`
  color: ${props => props.theme.colors.neutralWhite};
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.neutralWhite};
    text-decoration: underline;
  }

  &:visited {
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledExternalLink = styled.a<ThemedStyledProps<Partial<LinkProps>, DefaultTheme>>`
  ${props => (props.type === LinkTypes.inlineLinkOnDarkBg ? ExternalLinkStylesDark : ExternalLinkStyles)};
  ${props =>
    props?.textUnderline &&
    `
    text-decoration: underline;
  `};
`;
