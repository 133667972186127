import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { Edit } from '../svg/Edit';
import { RenewMemberShipCardRow } from '../RenewMemberShipCardRow/RenewMemberShipCardRow';
import { TRowProps, TMultiRowProps } from 'components/pages/PageRenewYourMembership/types/types';
import { Button } from '../Button/Button';
import { RenewMembershipOptions } from '../../../constants';
import { useDispatch } from 'react-redux';
import { modifyMembershipAccordion } from 'modules/membership/actions';
import { RenewTypeSelection } from 'components/molecules/RenewTypeSelection/RenewTypeSelection';
import { RenewTierSelection } from 'components/molecules/RenewTierSelection/RenewTierSelection';
import { accordionOptionsLocationMapping, getMembershipPackagePath } from 'utils';
import { useHistory } from 'react-router';

export interface IRenewMemberShipCardProps {
  type: RenewMembershipOptions;
  text: string;
  headerBackgroundColor: string;
  rowProps: TRowProps | TMultiRowProps[];
  icon?: React.ReactNode;
  isUpdateAvailable?: boolean;
  isMobile?: boolean;
  isRenewMemberShipCardActive: boolean;
  setRenewalCardStatusObj: any;
  renewalCardStatusObj: any;
  isUsedForTier?: boolean;
  serviceTierFieldsToBeShown: string[];
}

export const RenewMemberShipCard: FC<IRenewMemberShipCardProps> = ({
  type,
  text,
  headerBackgroundColor,
  rowProps,
  icon,
  isUpdateAvailable = true,
  isMobile = false,
  isRenewMemberShipCardActive = false,
  setRenewalCardStatusObj,
  renewalCardStatusObj,
  isUsedForTier = false,
  serviceTierFieldsToBeShown = [''],
}) => {
  const dispatch = useDispatch();
  const isServiceTier = type === RenewMembershipOptions.SERVICE_TIER;
  const isAccountType = type === RenewMembershipOptions.ACCOUNT_TYPE;
  const history = useHistory();

  const renderIcon = (): React.ReactNode => {
    if (!icon) return null;
    return <div data-testid="icon">{icon || <RenewalIcon />}</div>;
  };

  const closeAllPanelsFirst = useCallback(() => {
    const closedPanels = { ...renewalCardStatusObj };
    const keys = Object.keys(closedPanels);
    keys.forEach((key: any) => {
      closedPanels[key] = { ...closedPanels[key], cardStatus: false };
    });
    return closedPanels;
  }, [renewalCardStatusObj]);

  const handleUpdateClick = useCallback(
    // tslint:disable-next-line:no-shadowed-variable
    (type: RenewMembershipOptions) => {
      const currentStatus = renewalCardStatusObj[type].cardStatus;

      dispatch(modifyMembershipAccordion(type));
      setRenewalCardStatusObj({
        ...closeAllPanelsFirst(), // Only one panel can be active at a time
        [type]: {
          cardStatus: !currentStatus,
        },
      });

      const membershipPath = getMembershipPackagePath(accordionOptionsLocationMapping(type));
      history.replace(membershipPath);
    },
    [renewalCardStatusObj, dispatch, setRenewalCardStatusObj, closeAllPanelsFirst, history]
  );

  const handleContinueToOtherAccordion = useCallback(
    (curentType: RenewMembershipOptions, nextType: RenewMembershipOptions) => {
      const currentStatus = renewalCardStatusObj[curentType].cardStatus;

      dispatch(modifyMembershipAccordion(nextType));
      setRenewalCardStatusObj({
        ...closeAllPanelsFirst(),
        [curentType]: {
          cardStatus: !currentStatus,
        },
        [nextType]: {
          cardStatus: currentStatus,
        },
      });

      const membershipPath = getMembershipPackagePath(accordionOptionsLocationMapping(nextType));
      history.replace(membershipPath);
    },
    [renewalCardStatusObj, dispatch, setRenewalCardStatusObj, closeAllPanelsFirst, history]
  );

  const showTypeUpdate = isAccountType && isRenewMemberShipCardActive;
  const showTierUpdate = isServiceTier && isRenewMemberShipCardActive;

  return (
    <Container>
      <Header headerBackgroundColor={headerBackgroundColor}>
        <FlexContainer rightMargin={isMobile ? 5 : 16}>
          {renderIcon()}
          <div>{text}</div>
        </FlexContainer>
        {isUpdateAvailable ? (
          <FlexContainer rightMargin={8} data-testid="update">
            <Edit size={isMobile ? 16 : 24} />
            <StyledUpdateButton testId={'express-renewal-update'} onClick={handleUpdateClick.bind(null, type)}>
              Modify
            </StyledUpdateButton>
          </FlexContainer>
        ) : (
          <Icon name={isRenewMemberShipCardActive ? 'chevron up' : 'chevron down'} />
        )}
      </Header>
      <StyledDiv>
        {!showTypeUpdate && !showTierUpdate && (
          <RenewMemberShipCardRow
            type={type}
            rowProps={rowProps}
            isUsedForTier={isUsedForTier}
            serviceTierFieldsToBeShown={serviceTierFieldsToBeShown}
            handleUpdateClick={handleUpdateClick.bind(null, type)}
            isUpdateAvailable={isUpdateAvailable}
            isRenewMemberShipCardActive={isRenewMemberShipCardActive}
          />
        )}

        {showTypeUpdate && isRenewMemberShipCardActive && <RenewTypeSelection />}
        {showTierUpdate && isRenewMemberShipCardActive && (
          <RenewTierSelection
            handleOnClickContinue={handleContinueToOtherAccordion.bind(
              null,
              RenewMembershipOptions.SERVICE_TIER,
              RenewMembershipOptions.MEMBERSHIP_SECTIONS
            )}
            handleOnClickGoBack={handleUpdateClick.bind(null, RenewMembershipOptions.SERVICE_TIER)}
          />
        )}
      </StyledDiv>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${props => props.theme.colors.neutralWhite};
  margin-bottom: ${props => props.theme.pxToRem(42)};
`;

const StyledDiv = styled.div`
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)}
    ${props => props.theme.colors.neutralGrey3} !important;
  border-radius: 0 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(4)};
  clip-path: inset(
    0px ${props => props.theme.pxToRem(-16)} ${props => props.theme.pxToRem(-16)} ${props => props.theme.pxToRem(-16)}
  );
`;

const Header = styled.div<{ headerBackgroundColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.pxToRem(66)};
  border-radius: ${props => props.theme.pxToRem(4)};
  background-color: ${props => props.headerBackgroundColor};
  padding-left: ${props => props.theme.pxToRem(21)};
  padding-right: ${props => props.theme.pxToRem(21)};
  color: ${props => props.theme.colors.neutralWhite};
  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    font-size: ${props => props.theme.pxToRem(16)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.pxToRem(16)};
  }
`;

const FlexContainer = styled.div<{ rightMargin?: number }>`
  display: flex;
  > :first-child {
    margin-right: ${props => (props.rightMargin ? props.theme.pxToRem(props.rightMargin) : props.theme.pxToRem(20))};
  }
`;

const StyledUpdateButton = styled(Button)`
  background-color: transparent !important;
  color: ${props => props.theme.colors.neutralWhite} !important;
  margin: 0 !important;
  padding: 0 !important;
`;

const RenewalIcon = styled(Icon)`
  align-self: flex-start;
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;
