import { LOCATION_CHANGE } from 'connected-react-router';

// ------------------------------------
// Middleware
// ------------------------------------
export const refreshVergicMiddleware = (store: any) => (next: any) => async (action: any) => {
  const { type, payload } = action;

  const isLocationChangeEvent: boolean = type === LOCATION_CHANGE;

  if (isLocationChangeEvent && Boolean(payload?.location?.pathname)) {
    const maxRetries = 8;
    let tries = 1;

    const process = () => {
      if ((window as any).vngage?.evaluateOpportunities) {
        (window as any).vngage.evaluateOpportunities();
        clearInterval(interval);
      }
      if (tries >= maxRetries) {
        clearInterval(interval);
      }
      tries = tries + 1;
    };
    const interval = setInterval(process, 1000);
  }

  const n = await next(action);

  return n;
};
