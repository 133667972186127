import { createSelector } from 'reselect';
import { addressBookSelector } from '../user/selectors';

const rootSelector = createSelector(
  (state: State.Root) => state.address,
  (address: State.AddressState) => address
);

export const profileCacheAddressSelector = createSelector(rootSelector, state => state.profileCacheAddress);

export const addressSSValidationSelector = createSelector(
  rootSelector,
  state =>
    state.smartystreetsValidation || {
      validationNeeded: false,
      shippingAddress: { valid: false, reason: '', suggestions: [] },
    }
);

export const profileCacheSecondaryAddressNeededSelector = createSelector(rootSelector, state =>
  Boolean(state.isSecondaryAddressNeeded)
);

export const profileCacheEmbargoedSelector = createSelector(rootSelector, state => state.isEmbargoed);

export const baseCacheAddressSelector = createSelector(
  profileCacheAddressSelector,
  (address: State.SalesforceAddress): State.Address => ({
    addressLine1: address.addressLine1 || '',
    addressLine2: address.addressLine2 || '',
    city: address.city || '',
    state: address.state || '',
    zipCode: address.zipCode || '',
    country: address.country || '',
  })
);

export const fullAddressBookSelector = createSelector(
  addressBookSelector,
  rootSelector,
  (serverAddresses, address): State.SalesforceAddress[] => [...serverAddresses, ...address.clientAddresses]
);

export const addressLoadingSelector = createSelector(
  rootSelector,
  (address: State.AddressState): boolean => address?.loading
);
