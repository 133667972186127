import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Container, SemanticWIDTHS, Popup } from 'semantic-ui-react';
import download from 'downloadjs';
import { Contentful } from 'mxp-schemas';
import { Utils } from 'mxp-utils';
import { ReactComponent as WaveDotted } from 'resources/images/wave.svg';
import { ReactComponent as WavyDotted } from 'resources/images/wavy-dotted.svg';
import { ReactComponent as WaveDottedWhite } from 'resources/images/wavy-dotted-white.svg';
import { OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms/ResponsiveHelpers/ResponsiveHelpers';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { ExternalLinkStyles, DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE } from 'resources/images';
import { DOWNLOAD_EVENT, handleEvent } from 'utils/Analytics';
import { ReactComponent as GetApp } from 'resources/images/icon-dev-ic-get-app.svg';
import { ReactComponent as InfoBubble } from 'resources/images/info-bubble-inverted.svg';

interface Step {
  header: string;
  description: string;
}

interface Props {
  modularBlock: any;
}

interface DownloadItemProps {
  name: string;
  fileNames: string[];
  buttonText: string;
  fileSize: string;
  fileLink: string;
  isLocked?: boolean;
}

export const BlockStepper: React.FC<Props> = (props: Props) => {
  const { modularBlock } = props;
  const steps = modularBlock.landingPageSubBlocks || [];
  const downloads = modularBlock.downloads;
  const numberOfSteps = steps.length as SemanticWIDTHS;

  const [fileLoading, setFileLoading] = useState(false);

  const handleDownloadClick = React.useCallback((): void => {
    const fileNames = downloads.map((downloadable: DownloadItemProps) => downloadable.fileNames.join()).join();
    handleEvent({ filename: fileNames }, DOWNLOAD_EVENT);
    setFileLoading(true);
    Promise.all(
      downloads.map((item: DownloadItemProps) =>
        fetch(item.fileLink)
          .then(response => response.blob())
          .then(result => {
            download(result, item.fileNames.join());
          })
      )
    )
      .then(() => {
        setFileLoading(false);
      })
      .catch(() => {
        setFileLoading(false);
      });
  }, [downloads]);

  const toolTipMessage = `Please note, if you are a CIMA Member in good standing with ten years membership or more, you do not need to follow these steps. Simply click 'Apply Now' at the bottom of the page and the system will guide you.`;
  const popupStyle = {
    cursor: 'pointer',
  };
  return (
    <StyledContainer>
      <Grid centered>
        <StyledTitle bgcolor={modularBlock.bgColor || ''}>
          {modularBlock.title}{' '}
          {modularBlock.title === 'How do I apply?' && (
            <Popup content={toolTipMessage} trigger={<StyledInfoBubble />} position="bottom left" style={popupStyle} />
          )}
        </StyledTitle>
      </Grid>
      <Grid centered>
        <StyledDescription bgcolor={modularBlock.bgColor || ''}>{modularBlock.description}</StyledDescription>
      </Grid>
      <OnlyDesktopCSS>
        <Grid style={{ marginTop: '40px', width: '92%', margin: '0 auto' }} stackable>
          <Grid.Row columns={numberOfSteps || 1}>
            {steps.map((step: Step, index: number) => (
              <Grid.Column key={index}>
                <StyledStepContainer>
                  <StyledStepNumber bgcolor={modularBlock.bgColor || ''}>{index + 1} </StyledStepNumber>
                  <StyledStepTitle
                    bgcolor={modularBlock.bgColor || ''}
                    dangerouslySetInnerHTML={{ __html: step.header }}
                  />
                  <StyledStepDescription
                    bgcolor={modularBlock.bgColor || ''}
                    dangerouslySetInnerHTML={{ __html: step.description }}
                  />
                </StyledStepContainer>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
        {numberOfSteps === 3 ? (
          <StyledIconThreeDots />
        ) : modularBlock.bgColor ? (
          <StyledIcon />
        ) : (
          <StyledWaveIconWhite />
        )}
      </OnlyDesktopCSS>
      <OnlyMobileCSS>
        <Grid style={{ marginTop: '40px' }}>
          {steps.map((step: Step, index: number) => (
            <Grid.Row columns={2} key={index}>
              <Grid.Column key={index} style={{ flex: 1 }}>
                <StyledStepNumber bgcolor={modularBlock.bgColor || ''}>{index + 1}</StyledStepNumber>
              </Grid.Column>
              <Grid.Column key={index} style={{ flex: 6.625 }}>
                <StyledStepTitle
                  bgcolor={modularBlock.bgColor || ''}
                  dangerouslySetInnerHTML={{ __html: step.header }}
                />
                <StyledStepDescription
                  bgcolor={modularBlock.bgColor || ''}
                  dangerouslySetInnerHTML={{ __html: step.description }}
                />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </OnlyMobileCSS>
      {modularBlock.footerHeader && (
        <Grid centered>
          <StyledFooterHeader>{modularBlock.footerHeader}</StyledFooterHeader>
        </Grid>
      )}
      {modularBlock.footerParagraph && (
        <Grid centered>
          <StyledFooterParagraph>{Utils.stripHtml(modularBlock.footerParagraph)}</StyledFooterParagraph>
        </Grid>
      )}
      {modularBlock.ctaType === Contentful.LandingPages.BlockStepsCtaTypes.DOWNLOADABLE &&
        downloads &&
        downloads.length > 0 && (
          <Grid centered>
            <Button
              testId="download-button"
              variant={ButtonEnums.variants.secondary}
              size={ButtonEnums.sizes.medium}
              icon={<GetApp />}
              iconPosition={ButtonEnums.iconPosition.left}
              onClick={handleDownloadClick}
              loading={fileLoading}
              disabled={fileLoading}
            >
              {modularBlock.ctaText}
            </Button>
          </Grid>
        )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  margin-top: 0;
  justify-content: center;
`;

const StyledTitle = styled.label<{ bgcolor?: string }>`
  font-size: ${props => props.theme.fontSizes.xl};
  line-height: 1.38;
  font-weight: ${props => props.theme.fontWeights.light};
  ${props =>
    !props.bgcolor &&
    `
    color: ${props.theme.colors.neutralWhite};
  `}
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledFooterHeader = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  margin-top: ${props => props.theme.pxToRem(80)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledDescription = styled.label<{ bgcolor?: string }>`
  margin: ${props => props.theme.pxToRem(24)} 0 ${props => props.theme.pxToRem(30)};
  line-height: 1.33;
  font-size: ${props => props.theme.fontSizes.m};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  font-weight: ${props => props.theme.fontWeights.light};
  width: 70%;
  ${props =>
    !props.bgcolor &&
    `
    color: ${props.theme.colors.neutralWhite};
  `}
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledFooterParagraph = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.fontSizes.xs};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  font-weight: ${props => props.theme.fontWeights.light};
  width: 75%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;
const StyledStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;

  .rich-text-external-link {
    ${ExternalLinkStyles}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${IC_OPEN_IN_NEW_PURPLE});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledStepNumber = styled.div<{ bgcolor?: string }>`
  margin: ${props => `${props.theme.pxToRem(4)} 0 ${props.theme.pxToRem(2)}`};
  ${props =>
    !props.bgcolor
      ? `
    color: ${props.theme.colors.neutralWhite};
    `
      : `
    color: ${props.theme.colors.primaryLightPurple};
  `}
  font-size: ${props => props.theme.pxToRem(90)};
  font-weight: ${props => props.theme.fontWeights.bold};
  text-align: center;
  flex: 1;

  ${props => props.theme.mediaQueries.mobileOnly} {
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.pxToRem(70)};
  }
`;

const StyledStepTitle = styled.label<{ bgcolor?: string }>`
  line-height: 1.33;
  ${props =>
    !props.bgcolor
      ? `
    color: ${props.theme.colors.neutralWhite};
    `
      : `
    color: ${props.theme.colors.primaryLightPurple};
  `}
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.bold};
  text-align: center;
  flex: 1;

  a {
    ${DefaultInlineLinkStyles};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: left;
  }
`;

const StyledStepDescription = styled.div<{ bgcolor?: string }>`
  ${props =>
    !props.bgcolor &&
    `
    color: ${props.theme.colors.neutralWhite};
  `}
  margin-top: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.43;
  text-align: center;
  flex: 1;

  a {
    ${DefaultInlineLinkStyles};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: left;
    font-size: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledIcon = styled(WaveDotted)`
  width: 100%;
`;

const StyledWaveIconWhite = styled(WaveDottedWhite)`
  width: 100%;
`;

const StyledIconThreeDots = styled(WavyDotted)`
  width: 100%;
  align-content: center;
`;

const StyledInfoBubble = styled(InfoBubble)`
  &&&& {
    fill: ${props => props.theme.colors.primaryPurple};
    padding-top: ${props => props.theme.pxToRem(10)};
  }
`;
