import React, { createRef, useState } from 'react';
import { Grid, Loader, Sticky, Ref } from 'semantic-ui-react';
import { Product, User, Cart, MembershipTypes, Content } from 'mxp-schemas';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useGoogleAds } from 'hooks/useGoogleAds';
import { RelatedContent, BundleProductsBlock } from 'components/organisms';
import { UserMemberTypes } from 'modules/user/constants';
import { Footer } from 'components/molecules/Footer/Footer';
import { PurchaseSummary } from 'components/molecules/PurchaseSummary/PurchaseSummary';
import { BackToTop } from 'components/molecules/BackToTop/BackToTop';
import { SocialSharing } from 'components/molecules/SocialSharing/SocialSharing';
import { NoMembershipModal } from 'components/molecules/NoMembershipModal/NoMembershipModal';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper';
import {
  Divider,
  OnlyDesktopSafe,
  OnlyMobileSafe,
  Link,
  NotificationBanner,
  NotificationBannerEnums,
} from 'components/atoms';
import { PageHelmet } from 'components/atoms/PageHelmet/PageHelmet';
import { ProductHero } from './ProductHero';
import {
  getPath,
  isServer,
  priceToFloat,
  productTypeToSlug,
  donationTypeToSlug,
  Scroller,
  getPremiumContentToLink,
} from 'utils';
import { getStructuredData } from './getStructuredData';
import { CART_CTA_TEXT, PRODUCT_CART_STATUS, Routes } from 'constants/index';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import {
  EXTERNAL_LINK_EVENT,
  ADD_CART,
  ADD_CART_FIRST,
  handleEvent,
  handleGoogleAdsRemarketing,
} from 'utils/Analytics';
import {
  CartCompoundAnalytics,
  getOrderTypeAnalytics,
  isB2BMembershipType,
  isB2CMembershipType,
} from 'utils/Analytics/helpers';
import { NotFound } from 'components/App/lazy-imports';
import { ProductDetailedInformation } from './ProductDetailedInformation';
import { getAlreadyHasAccessInfo, getProductStatus } from './pageProductHelper';
import { useAutoAddProductSkuAndPromo } from 'hooks/useAutoAddProductSkuAndPromo';
import { BundleItemsSelectionInfo, BundleSummedPrices } from 'modules/products/selectors';
import { productAccessConfigSelector } from 'modules/app/selectors';
import { PremiumContentNotificationBanner, SubscriptionNotificationBanner } from './NotificationBanners';
import { ExternalAdBanner, ExternalAdBannerType } from 'components/organisms/ExternalAdBanner/ExternalAdBanner';
import { useHistory } from 'react-router';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';
import { isUserMemberSelector, isUserSuspendedSelector } from 'modules/user/selectors';

export interface Props {
  productItem: Product.ProductItem;
  slug: string;
  productTypeSlug: string;
  isProductFetched: boolean;
  variantsDateTimeInfo?: Product.VariantDateTimeInfo[] | null;
  variantsOptionPrice?: Product.VariantWithPriceInfo[] | null;
  pricing?: Product.PriceInfo;
  priceRange: Product.PriceRange;
  isExistingCartItem: boolean;
  isCartLoading: boolean;
  didCartTransitionFail: boolean;
  showCC?: boolean | undefined | null;
  relatedContent: State.ContentCardItem[] | null;
  allPrices?: Product.PriceForRole[];
  isAvailableForSale: boolean;
  isProductWithMultipleOptions: boolean;
  selectedVariantSKU?: string;
  numberOfItemsInCart: number;
  isAuth: boolean | null;
  userEmail?: string;
  fvsMembership?: User.MembershipIdsEnum[] | [];
  oktaId?: string;
  productTypeLabel: string;
  productFormatLabel: string;
  priceRangeForUser?: Product.FormattedPriceRanges | null;
  attendanceOptions?: Product.ConferenceAttendanceOptions[];
  additionalDetails: Product.Variant;
  creditRange: string;
  yellowBookHoursRange: string;
  dateRange: string;
  fullUrl: string;
  examMasterVariantSku: string | null;
  selectedExamVariantFormatLabel: string | null;
  userMemberType: UserMemberTypes;
  calculatedAccessDuration: string;
  variantsCreditsInfo: Product.VariantCreditInfo[];
  variantsAvailabilityInfo: Product.VariantCreditInfo[];
  availableFormats: string[];
  isTransferableProductType: boolean;
  isPremiumContent: boolean;
  isProductTypeWithMultipleOptions: boolean;
  isPhysicalProduct: boolean;
  isPhPrSubscription: boolean;
  thirdPartyLink: string | undefined;
  bulkPurchasingLink?: string;
  formattedProductChildrenInfo?: Product.FormattedProductChildren[];
  productChildrenCount?: number | null;
  isbn: string[] | null;
  userRoles: User.MembershipIdsEnum[];
  productIncludedMembership: User.MembershipIdsEnum[];
  productContentRoleId: User.MembershipIdsEnum;
  premiumContentItems: Array<Partial<State.ContentCardItem>>;
  cartItems: Cart.LineItem[];
  getProductPageData: (slug: string) => void;
  addItemToCart: (
    productId: string,
    sku: string,
    cartValidation: Common.CartValidation | null,
    freeTrial: boolean
  ) => void;
  swapItemInCart: (
    productId: string,
    sku: string,
    cartValidation: Common.CartValidation | null,
    lineItemId: string | null | undefined
  ) => Promise<void>;
  addBundleToCart: () => void;
  updateCartItem: (quantity: number, standingOrder: boolean, lineItemId: string, donationPrice?: string) => void;
  navigate: (path: string) => void;
  setSelectedVariant: (variantSKU: string, isFreeTrial: boolean) => void;
  setSelectedDonationPrice: (selectedPrice: string) => void;
  setSelectedBundleProductVariant: (variant: Common.BundleProductVariant) => void;
  accessProvisionedItem: (product: Product.ProductItem) => void;
  userPlatform?: User.UserPlatform | null;

  bundleItemsSelectionInfo: BundleItemsSelectionInfo[] | null;
  bundleSummedPrices: BundleSummedPrices;
  bundleProductsCardItems?: State.ContentCardItem[];
  bundleItemVariantPrices: State.VariantsPriceInfoForUserType[];
  bundleItemsOutOfStockInfo: State.BundleItemVariantOutOfStock[][];
  isDonationURL: boolean;
  hasAttestation: boolean;
  hasAttestationVariants: Product.Variant[];
  variantLineItemForSwappingDonation: string | null | undefined;
  defaultProductVariantWithAttestationSku?: string | null;
  donationPrice: string;
  isContribution: boolean;
  isProductHasUserPrice?: boolean | null;
  maxProductFreeTrialDays: number;
  isFreeTrialSelected: boolean;
  isConference: boolean;
  hasOnlineConference: boolean;
  freeTrialEndDate: string;
  getWebcastPass: () => void;
  learningPathway: MembershipTypes.Pathway | undefined | null;
  bundleProducts: Product.ProductItem[] | undefined;
  userLocation?: {
    country: string;
  };
  appInitialized: boolean;
}

export const PageProduct: React.FC<Props> = ({
  productItem,
  productTypeSlug,
  isProductFetched,
  variantsDateTimeInfo,
  variantsOptionPrice,
  pricing,
  priceRangeForUser,
  isExistingCartItem,
  isCartLoading,
  didCartTransitionFail,
  showCC,
  relatedContent,
  allPrices,
  isAvailableForSale,
  isProductWithMultipleOptions,
  selectedVariantSKU,
  numberOfItemsInCart,
  priceRange,
  isAuth,
  userEmail,
  fvsMembership,
  oktaId,
  productTypeLabel,
  productFormatLabel,
  attendanceOptions,
  creditRange,
  yellowBookHoursRange,
  dateRange,
  additionalDetails,
  fullUrl,
  examMasterVariantSku,
  selectedExamVariantFormatLabel,
  userMemberType,
  calculatedAccessDuration,
  variantsCreditsInfo,
  variantsAvailabilityInfo,
  availableFormats,
  isTransferableProductType,
  isProductTypeWithMultipleOptions,
  isPhysicalProduct,
  isPhPrSubscription,
  isPremiumContent,
  thirdPartyLink,
  formattedProductChildrenInfo,
  productChildrenCount,
  isbn,
  userRoles,
  productIncludedMembership,
  productContentRoleId,
  premiumContentItems,
  cartItems,
  userPlatform,
  addItemToCart,
  addBundleToCart,
  updateCartItem,
  swapItemInCart,
  navigate,
  setSelectedVariant,
  setSelectedDonationPrice,
  setSelectedBundleProductVariant,
  accessProvisionedItem,
  bundleItemsSelectionInfo,
  bundleSummedPrices,
  bundleProductsCardItems,
  bundleItemVariantPrices,
  bundleItemsOutOfStockInfo,
  isDonationURL,
  hasAttestation,
  hasAttestationVariants,
  variantLineItemForSwappingDonation,
  defaultProductVariantWithAttestationSku,
  donationPrice,
  isContribution,
  isProductHasUserPrice,
  maxProductFreeTrialDays,
  isFreeTrialSelected,
  isConference,
  hasOnlineConference,
  freeTrialEndDate,
  getWebcastPass,
  learningPathway,
  bundleProducts,
  userLocation,
  slug,
}) => {
  useGoogleAds(productItem?.externalAdsConfig);
  useAutoAddProductSkuAndPromo();
  const contextRef: any = createRef();
  const contentContainer: any = React.useRef();
  const contentCreditInfo: any = React.useRef();
  const contentAccessRow: any = React.useRef();
  const contentProductChildrenInfo: any = React.useRef();
  const bulkPurchasingInfo: any = React.useRef();
  const bundleProductsBlock: any = React.useRef();
  const freeTrialBlock: any = React.useRef();
  const ratingsAndReviewWidget: any = React.useRef();
  const history = useHistory();
  const hasWebcastPass = productItem?.hasWebcastPass || false;
  const [openNoMembershipModal, setOpenNoMembershipModal] = useState(false);

  React.useEffect(() => {
    if (hasWebcastPass) {
      getWebcastPass();
    }
  }, [hasWebcastPass, getWebcastPass]);

  const alreadyHasAccessInfo = getAlreadyHasAccessInfo(userRoles, productContentRoleId, productIncludedMembership);
  const { useSuspendedRestriction } = useSelector(featureTogglesSelector);
  const { oplRedirectUrl, oplHidUrl }: State.ProductAccess = useSelector(productAccessConfigSelector);
  // check user if member
  const isUserMember = useSelector(isUserMemberSelector);
  // check user if suspended
  const isUserSuspendedByEthics = useSelector(isUserSuspendedSelector);
  // check if member have suspended status
  const isUserMemberWithSuspendedEthics: boolean = Boolean(
    isUserMember && isUserSuspendedByEthics && useSuspendedRestriction
  );

  const premiumContentTo = React.useMemo(
    () => getPremiumContentToLink(alreadyHasAccessInfo.category),
    [alreadyHasAccessInfo]
  );
  const productStatus: PRODUCT_CART_STATUS = getProductStatus(
    didCartTransitionFail,
    thirdPartyLink,
    isPhysicalProduct,
    isExistingCartItem,
    Boolean(alreadyHasAccessInfo.message),
    isConference
  );

  const handleBuyLongestFreeTrialClick = React.useCallback(() => {
    if (productItem.isSubscribed) {
      handleProvisionAccess();
      return;
    }
    if (isExistingCartItem) {
      navigate(getPath(Routes.CART_PAGE));
      return;
    }
    if (variantsOptionPrice?.length) {
      const freeTrial = variantsOptionPrice
        .filter(v => v.isFreeTrial)
        .sort((a, b) => (b.freeTrialTerm || 0) - (a.freeTrialTerm || 0))[0];
      if (freeTrial) {
        setSelectedVariant(freeTrial.sku || '', true);
        addItemToCart(
          productItem.productId,
          freeTrial.sku || '',
          {
            quantityValue: 1,
            isChecked: false,
          },
          true
        );
      }
    }
  }, [setSelectedVariant, variantsOptionPrice, addItemToCart, isExistingCartItem, navigate, productItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectionChange = (variantSKU: string, isFreeTrial: boolean) => {
    setSelectedVariant(variantSKU, isFreeTrial);
  };

  const handlePriceChange = React.useCallback((selectedDonationPrice: string) => {
    setSelectedDonationPrice(selectedDonationPrice);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [isTopOfPage, setIsTopOfPage] = React.useState(false);
  const [productQuantity, setProductQuantity] = React.useState(1);
  const [bundleElementRefs, setBundleElementRefsState] = React.useState<Common.BundleElement[]>([]);

  const setBundleElementRefs = (bundleEle: Common.BundleElement[]) => setBundleElementRefsState(bundleEle);

  const quantityHandler = (value: number) => {
    setProductQuantity(value);
  };

  const handleProvisionAccess = React.useCallback(() => {
    if (accessProvisionedItem) accessProvisionedItem(productItem);
  }, [accessProvisionedItem, productItem]);

  const handlePurchaseButtonClick = (
    productCartStatus: PRODUCT_CART_STATUS,
    productType: string,
    cartValidation: Common.CartValidation | null,
    setIsUpdateCartPrice?: (status: boolean) => void | null,
    isUpdateCartPrice?: boolean,
    lineItemId?: string
  ) => {
    const examCreditProductsInBundle = bundleProducts?.filter(
      products => products.variants[0].productType === Product.ProductType.EXAM_CREDIT
    );

    if (!learningPathway && isBundle && examCreditProductsInBundle?.length) {
      setOpenNoMembershipModal(true);
      return;
    }

    if (productCartStatus === PRODUCT_CART_STATUS.THIRD_PARTY_PRODUCT) {
      handleEvent({ text: CART_CTA_TEXT[productCartStatus], href: thirdPartyLink }, EXTERNAL_LINK_EVENT);
      return;
    }

    if (productCartStatus === PRODUCT_CART_STATUS.THIRD_PARTY_PRODUCT_CONFERENCE) {
      handleEvent(
        { text: `conference-button:${CART_CTA_TEXT[productCartStatus]}:${thirdPartyLink}`, href: thirdPartyLink },
        EXTERNAL_LINK_EVENT
      );
      return;
    }

    if (productCartStatus === PRODUCT_CART_STATUS.ACCESS_NOW) {
      navigate(premiumContentTo);
      return;
    }

    if (productCartStatus === PRODUCT_CART_STATUS.IN_CART && !isUpdateCartPrice) {
      navigate(getPath(Routes.CART_PAGE));
    } else {
      if (isBundle) {
        addBundleToCart();
      } else {
        if (isUpdateCartPrice) {
          if (setIsUpdateCartPrice) setIsUpdateCartPrice(false);
          updateCartItem(1, false, lineItemId || '', isContribution ? donationPrice : '');
        } else {
          if (variantLineItemForSwappingDonation) {
            swapItemInCart(
              productItem.productId,
              selectedVariantSKU || '',
              cartValidation,
              variantLineItemForSwappingDonation
            );
          } else {
            addItemToCart(productItem.productId, selectedVariantSKU || '', cartValidation, isFreeTrialSelected);
          }
        }
      }

      const bundle =
        bundleSummedPrices && bundleSummedPrices.priceFinal
          ? priceToFloat(bundleSummedPrices?.priceFinal?.formattedPrice)
          : 0;
      const notBundle = pricing && pricing.priceFinal ? priceToFloat(pricing?.priceFinal?.formattedPrice) : 0;
      const sku = isBundle ? productItem.variants[0].sku || '' : selectedVariantSKU || '';

      const isB2BMembership = isB2BMembershipType(productType);
      const isB2CMembership = isB2CMembershipType(productType);

      const productBundle = productItem?.bundleProducts || [];
      const productBundleSkus = productBundle.map(item => item?.variants[0]?.sku || '');
      const productBundleIds = productBundle.map(item => item?.productId);

      const payload: CartCompoundAnalytics = {
        id: [productItem.productId],
        productBundleSkus,
        productBundleIds,
        bundleId: [isBundle ? productItem.productId : ''],
        bundleSku: [isBundle ? sku : ''],
        name: [productItem.name],
        category: [productItem.productType],
        price: [isBundle ? bundle : notBundle],
        quantity: [productQuantity],
        sku: [sku],
        orderType: [
          getOrderTypeAnalytics({
            isStandingOrder: false,
            isBundle,
            isFreeTrial: !!freeTrialEndDate,
            isB2BMembership,
            isB2CMembership,
          }),
        ],
        shippingCost: [],
        shippingLocation: [],
        shippingType: [],
        productTax: [],
        freeTrialEndDate: [freeTrialEndDate],
      };
      if (numberOfItemsInCart) {
        handleEvent(payload, ADD_CART);
      } else {
        handleEvent(payload, ADD_CART_FIRST);
      }
      handleGoogleAdsRemarketing(payload);
    }
  };
  const [hideHeader, setHideHeader] = useState(false);

  const isWrongProductTypeSlug =
    productTypeSlug &&
    ![productTypeToSlug(productItem?.productType as any), productItem?.subscriptionProductType?.key].includes(
      productTypeSlug
    );

  const isWrongDonationTypeSlug =
    productTypeSlug && donationTypeToSlug(productItem?.productType as any) !== productTypeSlug;

  if (
    isProductFetched &&
    (!productItem ||
      (!isDonationURL && isWrongProductTypeSlug) ||
      (isDonationURL && isWrongDonationTypeSlug) ||
      (!productTypeSlug && isContribution))
  ) {
    return <NotFound />;
  }

  const renderCartTransitionError = () => {
    if (!didCartTransitionFail) return null;

    if (!isTopOfPage) {
      Scroller.scrollToTop();
      setIsTopOfPage(true);
    }

    return (
      <ErrorContainer>
        <ErrorIconWrapper>
          <IconError />
        </ErrorIconWrapper>
        <ErrorMessage data-testid="add-to-cart-error-message">
          Unable to add item to cart. Please refresh to try again
        </ErrorMessage>
      </ErrorContainer>
    );
  };

  const isWebcast: boolean = productItem?.productType === Product.ProductType.WEBCAST;
  const isMultiDayWebcastFormat: boolean = isWebcast && productItem?.format?.key === Product.AvailableFormat.MULTI_DAY;
  const isWebcastSeriesFormat: boolean = isWebcast && productItem?.format?.key === Product.AvailableFormat.SERIES;
  const isSubscription = productItem?.productType === Product.ProductType.SUBSCRIPTION;
  const isBundle = productItem?.productType === Product.ProductType.BUNDLE;

  const isPhSubscriptionMagazine: boolean =
    isSubscription &&
    productItem?.subscriptionProductType?.key === Product.SubscriptionProductType.PUBLICATION &&
    productItem.showQuantity;
  const isPhSubscriptionCourse: boolean =
    isSubscription &&
    productItem?.subscriptionProductType?.key === Product.SubscriptionProductType.COURSE &&
    productItem.showQuantity;
  const isCourse: boolean = productItem?.productType === Product.ProductType.COURSE;
  const isPublication: boolean = productItem?.productType === Product.ProductType.PUBLICATION;
  const isOPL: boolean = Boolean(
    isSubscription &&
      productItem?.subscriptionProductType?.key === Product.SubscriptionProductType.PUBLICATION &&
      (productItem.showQuantity === undefined || !productItem.showQuantity)
  );

  const isStandingOrderVariantInStock = productItem?.variants?.some(
    item => item.isPhysicalProduct && item.availability?.isOnStock
  );

  const cartItemsList = cartItems?.filter((item: Cart.LineItem) => item.productId === productItem?.productId);

  const handleCloseModal = () => {
    setOpenNoMembershipModal(false);
  };

  const handleConfirmModal = async () => {
    await addBundleToCart();
    history.push(`/${Content.CategorySlugs.RESOURCES}/landing/cima-membership`);
  };

  return (
    <>
      <PageHelmet
        isNoIndex={productItem?.isHidden}
        meta={{
          title: productItem?.metaTitle,
          description: productItem?.metaDescription,
        }}
        openGraph={{
          title: productItem?.name,
          description: productItem?.description,
          image: productItem?.variants?.[0]?.images?.[0]?.imageUrl,
          passUrl: true,
        }}
      >
        {productItem && (
          <script type="application/ld+json">{`${getStructuredData(
            productItem,
            fullUrl,
            variantsDateTimeInfo
          )}`}</script>
        )}
      </PageHelmet>
      <NoMembershipModal
        isOpen={openNoMembershipModal}
        heading={'Membership needed'}
        description={'You need a membership to purchase this product.'}
        onClose={handleCloseModal}
        handleModalConfirm={handleConfirmModal}
        handleModalCancel={handleCloseModal}
        isLoading={isCartLoading}
      />
      <div>
        <HeaderPageWrapper hideHeader={hideHeader} stickMobileHeader={false}>
          {!isProductFetched ? (
            <StyledContentLoading>
              <Loader active />
            </StyledContentLoading>
          ) : (
            <div id="mainContent">
              {productItem?.externalAdsConfig?.topAdBanner && <ExternalAdBanner type={ExternalAdBannerType.TOP} />}
              <StyledPageWrapper>
                <ContentPageWrapper>
                  <PremiumContentNotificationBanner {...alreadyHasAccessInfo} />
                  <SubscriptionNotificationBanner
                    isPremiumContent={isPremiumContent}
                    isOPL={isOPL}
                    userEmail={userEmail}
                    fvsMembership={fvsMembership}
                    oktaId={oktaId}
                  />
                  {isUserMemberWithSuspendedEthics && (
                    <StyledNotificationBanner
                      testId="payment-tech-error"
                      childrenTestId="payment-tech-error-children"
                      variant={NotificationBannerEnums.variant.red}
                      icon={<StyledIconErrorSuspension />}
                    >
                      <SpacingNotification>
                        You do not have access to the discounted member price because you are suspended due to Ethics
                        reasons. For any questions, please contact{' '}
                        <Link isExternal testId="error-email" to="mailto:ethics@aicpa.org">
                          {' '}
                          <StyledUnderlined>ethics@aicpa.org</StyledUnderlined>
                        </Link>{' '}
                        or call{' '}
                        <Link isExternal testId="error-phone" to="tel:1-888-777-7077">
                          <StyledUnderlined>1-888-777-7077</StyledUnderlined>
                        </Link>
                        , using option 2, then option 3.
                      </SpacingNotification>
                    </StyledNotificationBanner>
                  )}
                  {renderCartTransitionError()}
                  <ProductHero
                    productItem={productItem}
                    calculatedAccessDuration={calculatedAccessDuration}
                    credits={creditRange}
                    pageButtonHandler={handlePurchaseButtonClick}
                    productButtonStatus={productStatus}
                    optionScrollTarget={contentContainer}
                    creditInfoScrollTarget={contentCreditInfo}
                    accessRowScrollTarget={contentAccessRow}
                    productChildrenInfoScrollTarget={contentProductChildrenInfo}
                    cartLoading={isCartLoading}
                    cartTransitionError={didCartTransitionFail}
                    showCC={showCC}
                    pricing={pricing}
                    priceRangeForUser={priceRangeForUser}
                    dateRange={dateRange}
                    location={additionalDetails.locations}
                    isProductWithMultipleOptions={isProductWithMultipleOptions}
                    isAvailableForSale={isAvailableForSale}
                    priceRange={priceRange}
                    isAuth={isAuth}
                    productTypeLabel={productTypeLabel}
                    productFormatLabel={productFormatLabel}
                    sku={selectedVariantSKU || examMasterVariantSku}
                    examFormatLabel={selectedExamVariantFormatLabel}
                    thirdPartyLink={thirdPartyLink}
                    userMemberType={userMemberType}
                    isPhysicalProduct={isPhysicalProduct}
                    sessions={productChildrenCount}
                    isMultiDayWebcast={isMultiDayWebcastFormat}
                    isWebcastSeries={isWebcastSeriesFormat}
                    isPhSubscriptionMagazine={isPhSubscriptionMagazine}
                    isPublication={isPublication}
                    isCourse={isCourse}
                    isBundle={isBundle}
                    isTransferableProductType={isTransferableProductType}
                    isPremiumContent={isPremiumContent}
                    premiumContentTo={premiumContentTo}
                    productQuantity={productQuantity}
                    userPlatform={userPlatform}
                    isPhSubscriptionCourse={isPhSubscriptionCourse}
                    handleProvisionAccess={handleProvisionAccess}
                    productSku={additionalDetails.sku}
                    publicationYear={additionalDetails.publicationYear}
                    hasOnlineConference={hasOnlineConference}
                    isFreeTrialSelected={isFreeTrialSelected}
                    isOPL={isOPL}
                    oplRedirectUrl={oplRedirectUrl}
                    oplHidUrl={oplHidUrl}
                    userEmail={userEmail}
                    fvsMembership={fvsMembership}
                    oktaId={oktaId}
                    isUserSuspendedByEthics={isUserMemberWithSuspendedEthics}
                    allPrices={allPrices}
                  />
                </ContentPageWrapper>
                <OnlyDesktopSafe>
                  <Divider />
                </OnlyDesktopSafe>
                <ContentPageWrapper ref={contentContainer}>
                  <Ref innerRef={contextRef}>
                    <Grid columns={2} stackable>
                      <StyledLeftGridColumn computer={10} tablet={16} mobile={16} data-testid="product-page-content">
                        <ProductDetailedInformation
                          productItem={productItem}
                          productTypeLabel={productTypeLabel}
                          isMultiDayWebcastFormat={isMultiDayWebcastFormat}
                          isWebcastSeriesFormat={isWebcastSeriesFormat}
                          isPhSubscriptionMagazine={isPhSubscriptionMagazine}
                          isPublication={isPublication}
                          isCourse={isCourse}
                          formattedProductChildrenInfo={formattedProductChildrenInfo}
                          contentProductChildrenInfo={contentProductChildrenInfo}
                          bulkPurchasingInfo={bulkPurchasingInfo}
                          bundleProductsBlock={bundleProductsBlock}
                          calculatedAccessDuration={calculatedAccessDuration}
                          attendanceOptions={attendanceOptions}
                          isAuth={isAuth}
                          isWebcast={isWebcast}
                          allPrices={allPrices}
                          contentCreditInfo={contentCreditInfo}
                          contentAccessRow={contentAccessRow}
                          variantsCreditsInfo={variantsCreditsInfo}
                          variantsAvailabilityInfo={variantsAvailabilityInfo}
                          yellowBookHoursRange={yellowBookHoursRange}
                          additionalDetails={additionalDetails}
                          availableFormats={availableFormats}
                          standingOrderEligible={productItem.standingOrderEligible}
                          standingOrderDiscount={productItem.standingOrderDiscount}
                          isStandingOrderVariantInStock={isStandingOrderVariantInStock}
                          isbn={isbn}
                          setSelectedBundleProductVariant={setSelectedBundleProductVariant}
                          premiumContentItems={premiumContentItems}
                          setBundleElementRefs={setBundleElementRefs}
                          belongsToBundle={!!bundleProductsCardItems?.length}
                          bundleItemVariantPrices={bundleItemVariantPrices}
                          bundleItemsOutOfStockInfo={bundleItemsOutOfStockInfo}
                          isExistingCartItem={isExistingCartItem}
                          videos={productItem.videos}
                          cerosContent={productItem.fetchCerosContent}
                          turtlContent={productItem.fetchTurtlContent}
                          accessProvisionedItem={accessProvisionedItem}
                          maxProductFreeTrialDays={maxProductFreeTrialDays}
                          freeTrialBlock={freeTrialBlock}
                          handleBuyLongestFreeTrialClick={handleBuyLongestFreeTrialClick}
                          isConference={isConference}
                          isPremiumContent={isPremiumContent}
                          premiumContentTo={premiumContentTo}
                          oplRedirectUrl={oplRedirectUrl}
                          oplHidUrl={oplHidUrl}
                          userEmail={userEmail}
                          fvsMembership={fvsMembership}
                          oktaId={oktaId}
                          ratingsAndReviewWidget={ratingsAndReviewWidget}
                          sku={selectedVariantSKU}
                          fullUrl={fullUrl}
                          isProductFetched={isProductFetched}
                          isUserSuspendedByEthics={isUserMemberWithSuspendedEthics}
                        />
                      </StyledLeftGridColumn>
                      <Grid.Column
                        computer={6}
                        tablet={16}
                        mobile={16}
                        data-testid="product-page-purchase-summary"
                        floated="right"
                      >
                        <StyledOnlyDesktopSafe>
                          <StyledSticky context={contextRef} offset={44}>
                            <PurchaseSummary
                              testId={`purchase-summary-box-${productItem.slug}`}
                              hasAttestation={hasAttestation}
                              hasAttestationVariants={hasAttestationVariants}
                              variantDateTime={variantsDateTimeInfo}
                              variantOptionPrice={variantsOptionPrice}
                              name={productItem.name}
                              date={dateRange}
                              location={additionalDetails.locations || ''}
                              key="purchase-summary-static"
                              pricing={pricing}
                              pageButtonHandler={handlePurchaseButtonClick}
                              quantityHandler={quantityHandler}
                              productButtonStatus={productStatus}
                              cartLoading={isCartLoading}
                              cartTransitionError={didCartTransitionFail}
                              onSelectionchange={handleSelectionChange}
                              onPriceChange={handlePriceChange}
                              isAvailableForSale={isAvailableForSale}
                              isProductWithMultipleOptions={isProductWithMultipleOptions}
                              selectedVariantSKU={selectedVariantSKU}
                              isAuth={isAuth}
                              priceRange={priceRange}
                              thirdPartyLink={thirdPartyLink}
                              isTransferableProductType={isTransferableProductType}
                              isProductTypeWithMultipleOptions={isProductTypeWithMultipleOptions}
                              productId={productItem.productId}
                              isPhysicalProduct={isPhysicalProduct}
                              sessions={productChildrenCount}
                              productChildrenInfoScrollTarget={contentProductChildrenInfo}
                              isMultiDayWebcast={isMultiDayWebcastFormat}
                              isPhSubscriptionMagazine={isPhSubscriptionMagazine}
                              isWebcastSeries={isWebcastSeriesFormat}
                              isPremiumContent={isPremiumContent}
                              premiumContentTo={premiumContentTo}
                              calculatedAccessDuration={calculatedAccessDuration}
                              alreadyHasAccessInfoMessage={alreadyHasAccessInfo.message}
                              updateCartItem={updateCartItem}
                              cartItemsList={cartItemsList}
                              bundleBlockRefs={bundleElementRefs}
                              bundleItemsSelectionInfo={bundleItemsSelectionInfo}
                              bundleSummedPrices={bundleSummedPrices}
                              isExistingCartItem={isExistingCartItem}
                              isPhPrSubscription={isPhPrSubscription}
                              isSubscription={isSubscription}
                              handleProvisionAccess={handleProvisionAccess}
                              defaultProductVariantWithAttestationSku={defaultProductVariantWithAttestationSku}
                              isProductHasUserPrice={isProductHasUserPrice}
                              isFreeTrialSelected={isFreeTrialSelected}
                              userEmail={userEmail}
                              fvsMembership={fvsMembership}
                              oktaId={oktaId}
                              isUserSuspendedByEthics={isUserMemberWithSuspendedEthics}
                            />
                          </StyledSticky>
                        </StyledOnlyDesktopSafe>
                      </Grid.Column>
                    </Grid>
                  </Ref>
                </ContentPageWrapper>
              </StyledPageWrapper>
              {!!bundleProductsCardItems?.length && (
                <BundleProductsBlock contentRef={bundleProductsBlock} bundleProducts={bundleProductsCardItems} />
              )}
              <RelatedContent relatedContent={relatedContent} />
              {/* Following div is fix for SSR. Do not remove! */}
              <div>
                {!isServer && (
                  <OnlyMobileSafe>
                    <PurchaseSummary
                      testId={`purchase-summary-box-${productItem.slug}`}
                      hasAttestation={hasAttestation}
                      hasAttestationVariants={hasAttestationVariants}
                      variantDateTime={variantsDateTimeInfo}
                      variantOptionPrice={variantsOptionPrice}
                      name={productItem.name}
                      date={dateRange}
                      location={additionalDetails.locations || ''}
                      key="purchase-summary-static"
                      pricing={pricing}
                      pageButtonHandler={handlePurchaseButtonClick}
                      quantityHandler={quantityHandler}
                      productButtonStatus={productStatus}
                      cartLoading={isCartLoading}
                      cartTransitionError={didCartTransitionFail}
                      onSelectionchange={handleSelectionChange}
                      onPriceChange={handlePriceChange}
                      isAvailableForSale={isAvailableForSale}
                      isProductWithMultipleOptions={isProductWithMultipleOptions}
                      selectedVariantSKU={selectedVariantSKU}
                      setHideHeader={setHideHeader}
                      isAuth={isAuth}
                      priceRange={priceRange}
                      thirdPartyLink={thirdPartyLink}
                      isTransferableProductType={isTransferableProductType}
                      isProductTypeWithMultipleOptions={isProductTypeWithMultipleOptions}
                      productId={productItem.productId}
                      isPhysicalProduct={isPhysicalProduct}
                      sessions={productChildrenCount}
                      productChildrenInfoScrollTarget={contentProductChildrenInfo}
                      isMultiDayWebcast={isMultiDayWebcastFormat}
                      isPhSubscriptionMagazine={isPhSubscriptionMagazine}
                      isWebcastSeries={isWebcastSeriesFormat}
                      isPremiumContent={isPremiumContent}
                      premiumContentTo={premiumContentTo}
                      calculatedAccessDuration={calculatedAccessDuration}
                      alreadyHasAccessInfoMessage={alreadyHasAccessInfo.message}
                      updateCartItem={updateCartItem}
                      cartItemsList={cartItemsList}
                      bundleBlockRefs={bundleElementRefs}
                      bundleItemsSelectionInfo={bundleItemsSelectionInfo}
                      bundleSummedPrices={bundleSummedPrices}
                      isExistingCartItem={isExistingCartItem}
                      isPhPrSubscription={isPhPrSubscription}
                      isSubscription={isSubscription}
                      handleProvisionAccess={handleProvisionAccess}
                      isProductHasUserPrice={isProductHasUserPrice}
                      isFreeTrialSelected={isFreeTrialSelected}
                      isUserSuspendedByEthics={isUserMemberWithSuspendedEthics}
                    />
                  </OnlyMobileSafe>
                )}
              </div>
              {productItem?.externalAdsConfig?.bottomAdBanner && (
                <ExternalAdBanner type={ExternalAdBannerType.BOTTOM} />
              )}
              <FooterStyled />
              <BackToTop showBelow={contentContainer?.current?.offsetTop} />
              <SocialSharing title={productItem.metaTitle} isSticky />
            </div>
          )}
        </HeaderPageWrapper>
      </div>
    </>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.pxToRem(12)} 0;
  border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.interfaceRed};
  border-radius: ${props => props.theme.pxToRem(4)};
  margin-bottom: ${props => props.theme.pxToRem(24)};
  background-color: ${props => props.theme.colors.neutralWhite};
`;

const ErrorMessage = styled.div`
  padding-top: ${props => props.theme.pxToRem(3)};
  font-size: ${props => props.theme.fontSizes.xs};
`;

const ErrorIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  padding: 0 ${props => props.theme.pxToRem(10)};
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledOnlyDesktopSafe = styled(OnlyDesktopSafe)`
  margin-top: ${props => props.theme.pxToRem(28)};
`;

const StyledSticky = styled(Sticky)`
  .ui.sticky {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
`;

const StyledPageWrapper = styled.div`
  padding-top: ${props => props.theme.pxToRem(32)};
`;

const ContentPageWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: ${props => props.theme.pxToRem(1140)};
  padding: 0 ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(40)};
  margin: 0 auto;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(28)} ${props => props.theme.pxToRem(15)} ${props => props.theme.pxToRem(30)};
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledLeftGridColumn = styled(Grid.Column)`
  &&&&& {
    /* Fix for scroll to credit info.
     Do not use 'initial' instead of 'static'.
     'initial' doesn't work in IE. */
    position: static;
  }
`;

const FooterStyled = styled(Footer)`
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(70)};
  }
`;

const StyledContentLoading = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.theme.pxToRem(-89)};
  font-size: ${props => props.theme.fontSizes.l};
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-top: ${props => props.theme.pxToRem(15)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  /* ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(295)};
  } */
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: ${props => props.theme.pxToRem(740)};
  }
`;

const StyledIconErrorSuspension = styled(IconError)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  display: inline;
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin: ${props => `${props.theme.pxToRem(10)} ${props.theme.pxToRem(-2)} auto`};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `${props.theme.pxToRem(12)} ${props.theme.pxToRem(-9)} auto`};
  }
`;

const SpacingNotification = styled.div`
  margin: auto;
`;

const StyledUnderlined = styled.label`
  color: ${props => props.theme.colors.primaryPurple} !important;
  text-decoration: underline !important;
  cursor: pointer;
`;
