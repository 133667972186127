// ------------------------------------
// Constants
// ------------------------------------

export const initialState: State.InquiryForm = {
  error: false,
  loading: false,
  success: false,
  searchLoading: false,
  searchResults: [],
  searchSelection: null,
  errorMessage: '',
};

export enum InquiryFormActionNames {
  INQUIRY_FORM_START = 'inquiryForm/INQUIRY_FORM_START',
  INQUIRY_FORM_COMPLETE = 'inquiryForm/INQUIRY_FORM_COMPLETE',
  SUBMIT_INQUIRY_FORM = 'inquiryForm/SUBMIT_INQUIRY_FORM',
  LOADING = 'inquiryForm/LOADING',
  FETCH_PRODUCT_SEARCH_RESULTS = 'inquiryForm/FETCH_PRODUCT_SEARCH_RESULTS',
  SEARCH_LOADING = 'inquiryForm/SEARCH_LOADING',
  UPDATE_SEARCH_SELECTION = 'inquiryForm/UPDATE_SEARCH_SELECTION',
  FETCH_PRODUCT_ITEM = 'inquiryForm/FETCH_PRODUCT_ITEM',
}
