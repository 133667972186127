import * as Sentry from '@sentry/browser';
import { handleActions } from 'redux-actions';
import { allocate, setError, setProcessing } from './actions';
import { initialState } from './constants';

if ([allocate, setProcessing, setError].includes(undefined)) {
  const message: string = 'shareAllocation module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [allocate]: {
    next: (state: State.ShareAllocation): State.ShareAllocation => ({
      ...state,
      processing: false,
      error: null,
    }),
    throw: (
      state: State.ShareAllocation,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.ShareAllocation => ({
      ...state,
      processing: false,
      error,
    }),
  },

  [setError]: (
    state: State.ShareAllocation,
    action: { payload: CustomErrors.GraphQLError }
  ): State.ShareAllocation => ({
    ...state,
    error: action.payload,
  }),
  [setProcessing]: (state: State.ShareAllocation, action: { payload: boolean }): State.ShareAllocation => ({
    ...state,
    processing: action.payload,
  }),
};

export { allocate, setProcessing, setError };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
