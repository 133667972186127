import React, { useState } from 'react';
import { Accordion, StrictAccordionProps } from 'semantic-ui-react';
import { ReactComponent as ArrowUp } from 'resources/images/icon-arrow-up.svg';
import { ReactComponent as ArrowDown } from 'resources/images/icon-arrow-down.svg';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
import { markupToString } from 'utils';

interface Props extends StrictAccordionProps {
  divided?: boolean;
  collapseIcon?: React.ReactNode;
  title?: string | React.ReactNode;
  titleForAnalytics?: string;
  content?: React.ReactNode;
  titleTestId?: string;
  isBgColorDark?: boolean;
  isToggleOn?: boolean;
}

export const AccordionCard: React.FC<Props> = ({
  title,
  titleForAnalytics,
  divided,
  content,
  isToggleOn,
  isBgColorDark = false,
}) => {
  const [toggle, setToggle] = useState(isToggleOn);
  const { windowPathname } = getPageNameAndSiteSection();

  return (
    <StyledAccordion>
      <Panel divided={divided}>
        <AccordionTitle
          onClick={() => {
            if (toggle) {
              handleEvent(
                {
                  clickValue: `button:link:int::ACCORDION-CLOSE:${
                    titleForAnalytics || markupToString(title)
                  }:${windowPathname}`,
                },
                NAV_CLICK
              );
            } else {
              handleEvent(
                {
                  clickValue: `button:link:int::ACCORDION-OPEN:${
                    titleForAnalytics || markupToString(title)
                  }:${windowPathname}`,
                },
                NAV_CLICK
              );
            }
            setToggle(!toggle);
          }} // tslint:disable-line jsx-no-lambda
          data-testid={`expandable_title_${title}`}
        >
          <ContentContainer isBgColorDark={isBgColorDark}>
            <StyledTitle>{title}</StyledTitle>
            <IconContainer>
              {toggle ? (
                <StyledToggle>
                  <StyledSpan>See less </StyledSpan>
                  <ActiveIcon />
                </StyledToggle>
              ) : (
                <>
                  <StyledToggle>
                    <StyledSpan>Learn more </StyledSpan>
                    <InactiveIcon />
                  </StyledToggle>
                </>
              )}
            </IconContainer>
          </ContentContainer>
        </AccordionTitle>
        <StyledContent data-testid={`expandable_content_${title}`} active={toggle}>
          {content}
        </StyledContent>
      </Panel>
    </StyledAccordion>
  );
};

const StyledToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Panel = styled.div<ThemedStyledProps<{ divided: boolean | undefined }, DefaultTheme>>`
  border-bottom: ${props => (props.divided ? `1px solid ${props.theme.colors.neutralGrey3}` : null)};
`;

const StyledSpan = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  text-decoration: underline;
  color: ${props => props.theme.colors.primaryPurple};
  margin-right: ${props => props.theme.pxToRem(10)};
`;

const StyledContent = styled(Accordion.Content)`
  padding: ${props => `${props.theme.pxToRem(20)} ${props.theme.pxToRem(55)}`} !important;
`;

const StyledTitle = styled.h3`
  margin: 0;
  font-size: ${props => props.theme.fontSizes.s};
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAccordion = styled(Accordion)`
  &&&&& {
    border-radius: ${props => props.theme.pxToRem(4)};
    box-shadow: ${props =>
      `0 ${props.theme.pxToRem(10)} ${props.theme.pxToRem(20)} 0 ${props.theme.colors.neutralGrey3}`};
    border-top: solid ${props => props.theme.pxToRem(5)} ${props => props.theme.colors.interfaceBlue};
    margin-bottom: ${props => props.theme.pxToRem(25)};
  }
`;

const AccordionTitle = styled(Accordion.Title)`
  &&&&& {
    font-size: ${props => props.theme.fontSizes.m};
    padding: ${props => `${props.theme.pxToRem(18)} ${props.theme.pxToRem(35)}`};
    z-index: -1;
    box-shadow: ${props => `0 0 ${props.theme.pxToRem(10)} 0 ${props.theme.colors.neutralGrey3}`};
  }
`;

const ContentContainer = styled.div<{ isBgColorDark: boolean }>`
  display: flex;
  justify-content: space-between;
  svg path {
    stroke: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.primaryPurple)};
  }
`;

const IconContainer = styled.div`
  align-self: center;
`;

const ActiveIcon = styled(ArrowUp)`
  fill: ${props => props.theme.colors.primaryDarkPurple};
  width: 1.5rem;
  height: 1.5rem;
`;

const InactiveIcon = styled(ArrowDown)`
  fill: ${props => props.theme.colors.primaryPurple};
  width: 1.5rem;
  height: 1.5rem;
`;
