import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getCimaMembershipPackagePath } from 'utils';
import styled from 'styled-components';
import { MembershipPackageAccordionStatus } from '../../../constants';
import { Grid } from 'semantic-ui-react';
import { MembershipTypeSelection } from 'components/molecules/MembershipTypeSelection/MembershipTypeSelection';
import { fetchCimaMembershipTypes, setMembershipPackageTier, setMembershipPackageType } from 'modules/membership';
import {
  cimaMembershipSelector,
  cimaMembershipsTermTypeSelector,
  isUserMemberSelector,
  isUserMemberSuspendedSelector,
} from 'modules/user/selectors';
import {
  membershipTypesSelector,
  isCimaMembershipLapsedSelector,
  isCimaPqCandidateRenewalSelector,
  activeMembershipSubscriptionSelector,
  currentMembershipProductSkuSelector,
  clickedMembershipUpgradeSelector,
  isCimaPQCandidateLapsedSelector,
} from 'modules/membership/selectors';
import { MembershipTypes } from 'mxp-schemas';
import { Admin as AdminUtils } from 'mxp-utils';
import { membershipActionNames } from 'modules/membership/constants';
import { handleEvent } from 'utils/Analytics';

export const CimaMembershipTypePanel: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isAnalyticsEventTriggered, setIsAnalyticsEventTriggered] = useState(false);
  const { filteredCIMAMembershipTypes } = useSelector(membershipTypesSelector);
  const isUserMember = useSelector(isUserMemberSelector);
  const isUserMemberSuspended = useSelector(isUserMemberSuspendedSelector);
  const isCimaMembershipLapsed = useSelector(isCimaMembershipLapsedSelector);
  const isCimaPqCandidateRenewal = useSelector(isCimaPqCandidateRenewalSelector);
  const activeMembershipSubscription = useSelector(activeMembershipSubscriptionSelector);
  const currentMembershipSku = useSelector(currentMembershipProductSkuSelector);
  const cimaMemBody = useSelector(cimaMembershipSelector)?.membershipBody as string;
  const currentMembershipType = useSelector(cimaMembershipsTermTypeSelector);
  const isSubscribedToCgmaAffiliate = AdminUtils.isCimaCgmaAffiliateType(cimaMemBody, currentMembershipType);
  const isForUpgrade = useSelector(clickedMembershipUpgradeSelector);
  const isCimaPQCandidateLapsed = useSelector(isCimaPQCandidateLapsedSelector);
  useEffect(() => {
    const dispatchFetchCimaMembershipTypes = async () => {
      const { payload } = await dispatch(fetchCimaMembershipTypes());

      if (!isAnalyticsEventTriggered) {
        handleEvent(payload, membershipActionNames.STOREFRONT_AGGREGATION_VIEW_CIMA_TYPE);
      }
      setIsAnalyticsEventTriggered(true);
    };

    dispatchFetchCimaMembershipTypes();
  }, [dispatch, isAnalyticsEventTriggered]);

  const handleSelectType = useCallback(
    (id: string, typeSlug: string, productType: string[], isVariant: boolean, membershipKey?: string) => {
      if (
        (isCimaPqCandidateRenewal || isCimaPQCandidateLapsed) &&
        ((membershipKey && membershipKey === MembershipTypes.MembershipKeys.CANDIDATE) ||
          typeSlug === activeMembershipSubscription?.productSlug)
      ) {
        // Cima PQ Candidate Renewal
        dispatch(setMembershipPackageTier(currentMembershipSku)); // pre select current sku
        history.replace(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Addons), {
          skippedAccordionKey: MembershipPackageAccordionStatus.Skill,
        });
      } else if (
        isSubscribedToCgmaAffiliate &&
        isForUpgrade &&
        membershipKey &&
        membershipKey === MembershipTypes.MembershipKeys.CANDIDATE
      ) {
        // CGMA Affiliate to Candidate Upgrade
        // filteredCIMAMembershipTypes?.[0] => filteredCIMAMembershipTypes returns only 1 type - Candidate
        // skus?.[0] => Cima PQ Candidate has only one available sku
        const cimaCandidateCoreSku = filteredCIMAMembershipTypes?.[0]?.skus?.[0];
        if (cimaCandidateCoreSku) {
          history.replace(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Addons));
          dispatch(setMembershipPackageTier(cimaCandidateCoreSku)); // pre select Candidate Core sku
        }
      } else {
        history.replace(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Skill));
      }
      dispatch(setMembershipPackageType(id, typeSlug));
    },
    [
      history,
      dispatch,
      isCimaPqCandidateRenewal,
      isSubscribedToCgmaAffiliate,
      isForUpgrade,
      activeMembershipSubscription,
      currentMembershipSku,
      filteredCIMAMembershipTypes,
      isCimaPQCandidateLapsed,
    ]
  );

  return (
    <div>
      <StyledGrid>
        <Grid.Column>
          <StyledTypeContainer>
            <MembershipTypeSelection
              products={filteredCIMAMembershipTypes}
              selectMembership={handleSelectType}
              isUserMember={isUserMember || isUserMemberSuspended || isCimaMembershipLapsed}
            />
          </StyledTypeContainer>
        </Grid.Column>
      </StyledGrid>
    </div>
  );
};

const StyledGrid = styled(Grid)`
  &&.ui.grid {
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
`;

const StyledTypeContainer = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;
