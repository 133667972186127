import React from 'react';
import styled, { css } from 'styled-components';
import { AICPA_LOGO_PRIDE, AICPA_CIMA_LOGO, AICPA_CIMA_LOGO_MOBILE } from 'resources/images';
import { isTest } from 'utils';
import { Routes } from 'constants/index';
import { HEADER_EVENT, PREFIXES, handleEvent } from 'utils/Analytics';
import { headerAndFooterEventPayload } from 'utils/Analytics/helpers';
import { OnlyDesktop, OnlyMobile } from 'components/atoms/ResponsiveHelpers/ResponsiveHelpers';

// pride month is June
const isPride = !isTest && new Date().getMonth() === 5;

interface Props {
  className?: string;
  to?: Routes;
  overrideAnalytics?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const AicpaLogo = ({ className, onClick, to = Routes.ROOT, overrideAnalytics = false }: Props) => {
  const onClickHandler = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (!overrideAnalytics) {
      const payload = headerAndFooterEventPayload(PREFIXES.HEADER, to);
      handleEvent(payload, HEADER_EVENT);
    }

    if (onClick) {
      onClick(event);
    }

    return;
  };

  return (
    <>
      <OnlyDesktop minWidth={768}>
        <StyledLogoDesktop
          isPride={isPride}
          className={className}
          src={isPride ? AICPA_LOGO_PRIDE : AICPA_CIMA_LOGO}
          alt="AICPA & CIMA logo"
          data-testid="aicpa-cima-logo"
          onClick={onClickHandler}
        />
      </OnlyDesktop>
      <OnlyMobile maxWidth={767}>
        <StyledLogoMobile
          className={className}
          src={isPride ? AICPA_LOGO_PRIDE : AICPA_CIMA_LOGO_MOBILE}
          alt="AICPA & CIMA logo"
          data-testid="aicpa-cima-logo"
          onClick={onClickHandler}
        />
      </OnlyMobile>
    </>
  );
};

const StyledLogoDesktop = styled.img<{ isPride: boolean }>`
  height: ${props => props.theme.pxToRem(47)};
  width: ${props => props.theme.pxToRem(230)};
  image-rendering: pixelated;
  ${(
    // tslint:disable-next-line: no-shadowed-variable
    { isPride }
  ) =>
    // tslint:disable-next-line: no-shadowed-variable
    isPride &&
    css`
      height: ${props => props.theme.pxToRem(75)};
    `}
`;

const StyledLogoMobile = styled.img`
  float: left;
  width: ${props => props.theme.pxToRem(49)};
  height: ${props => props.theme.pxToRem(48)};
`;
