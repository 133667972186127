import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment-timezone';
import { MomentHelpers } from 'utils/MomentHelpers';
import { ReactComponent as ArrowRight, ReactComponent as ArrowLeft } from 'resources/images/icon-arrow-up.svg';
import { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import { ReactComponent as CustomCalendarIcon } from 'resources/images/ic-avs-calendar.svg';

interface DateFilterProps {
  id: string;
  startDate: string;
  onDateChangeFunc: (date: moment.Moment | null) => void;
  hasValidationError?: boolean;
  isCorrect?: boolean;
  errorMessage?: string | React.ReactNode;
  validationMessageDisabled?: boolean;
  onClose?: () => void;
  className?: string;
  disabled?: boolean;
  isAvsDesign?: boolean;
  placeholder?: string;
  displayFormat?: string;
}

interface RenderMonthProps {
  month: any;
  onMonthSelect: (currentMonth: any, newMonthVal: string) => void;
  onYearSelect: (currentMonth: any, newYearVal: string) => void;
  isVisible: boolean;
}

const renderMonthElement = ({ month, onMonthSelect, onYearSelect }: RenderMonthProps) => {
  const years = [];
  for (let i = moment().year(); i >= moment().year() - 100; i -= 1) {
    years.push(
      <option value={i} key={`year-${i}`}>
        {i}
      </option>
    );
  }

  const onMonthChangeFunc = (e: any) => {
    onMonthSelect(month, e.target.value);
  };

  const onYearChangeFunc = (e: any) => {
    onYearSelect(month, e.target.value);
  };

  return (
    <RenderMonthContainer>
      <div>
        <select value={month.month()} onChange={onMonthChangeFunc}>
          {moment.months().map((label, value) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={onYearChangeFunc}>
          {years}
        </select>
      </div>
    </RenderMonthContainer>
  );
};

const renderMonthElementAscending = ({ month, onMonthSelect, onYearSelect }: RenderMonthProps) => {
  const years = [];
  for (let i = moment().year(); i <= moment().year() + 100; i += 1) {
    years.push(
      <option value={i} key={`year+${i}`}>
        {i}
      </option>
    );
  }

  const onMonthChangeFunc = (e: any) => {
    onMonthSelect(month, e.target.value);
  };

  const onYearChangeFunc = (e: any) => {
    onYearSelect(month, e.target.value);
  };

  return (
    <RenderMonthContainer>
      <div>
        <select value={month.month()} onChange={onMonthChangeFunc}>
          {moment.months().map((label, value) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={onYearChangeFunc}>
          {years}
        </select>
      </div>
    </RenderMonthContainer>
  );
};

export const DatePicker: React.FC<DateFilterProps> = ({
  id,
  startDate,
  hasValidationError = false,
  onDateChangeFunc,
  isCorrect,
  errorMessage,
  validationMessageDisabled,
  className,
  onClose,
  disabled = false,
  isAvsDesign = false,
  placeholder,
  displayFormat,
}) => {
  const DATE_FORMAT = 'YYYY-MM-DD';

  const startDateMoment: moment.Moment | null = startDate
    ? MomentHelpers.convertToMoment(startDate, DATE_FORMAT)
    : null;

  const [focus, setFocus] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const SingleDatePickerRef = useRef<any>(null);

  const leftArrow = <StyledArrowLeft />;
  const rightArrow = <StyledArrowRight />;

  const onFocusChangeFunc = React.useCallback(({ focused }: any) => setFocus(focused), []);
  const handleClose = React.useCallback(() => onClose?.(), [onClose]);
  const falseFunc = React.useCallback(() => false, []);
  const hasError = Boolean(!isCorrect && isCorrect !== undefined);
  const validationMessageValue = hasError ? errorMessage : '\u00A0';

  useEffect(() => {
    const loadSingleDatePicker = async () => {
      const SingleDatePickerImported = (await import('react-dates')).SingleDatePicker;
      SingleDatePickerRef.current = SingleDatePickerImported;
      setIsLoaded(true);
    };
    loadSingleDatePicker().catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SingleDatePickerComponent = SingleDatePickerRef.current;

  return (
    <FilterContainer
      hasValidationError={hasValidationError}
      className={className}
      disabled={disabled}
      focused={focus}
      isAvs={isAvsDesign}
    >
      {isLoaded && (
        <SingleDatePickerComponent
          id={`datePicker-${id}`}
          date={startDateMoment}
          onDateChange={onDateChangeFunc}
          focused={focus}
          onFocusChange={onFocusChangeFunc}
          hideKeyboardShortcutsPanel
          navNext={rightArrow}
          navPrev={leftArrow}
          daySize={35}
          noBorder
          isOutsideRange={falseFunc}
          numberOfMonths={1}
          renderMonthElement={renderMonthElement}
          showDefaultInputIcon
          inputIconPosition="after"
          hasError={hasError}
          disabled={disabled}
          onClose={handleClose}
          customInputIcon={isAvsDesign ? <StyledCustomedCalendarIcon /> : ''}
          displayFormat={displayFormat}
          placeholder={placeholder}
        />
      )}
      {!validationMessageDisabled && (
        <ValidationMessage hasError={hasError}>{validationMessageValue}</ValidationMessage>
      )}
    </FilterContainer>
  );
};

export const DatePickerAscending: React.FC<DateFilterProps> = ({
  id,
  startDate,
  hasValidationError = false,
  onDateChangeFunc,
  isCorrect,
  errorMessage,
  validationMessageDisabled,
  className,
  isAvsDesign = false,
}) => {
  const DATE_FORMAT = 'YYYY-MM-DD';

  const startDateMoment: moment.Moment | null = startDate
    ? MomentHelpers.convertToMoment(startDate, DATE_FORMAT)
    : null;

  const [focus, setFocus] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const SingleDatePickerRef = useRef<any>(null);

  const leftArrow = <StyledArrowLeft />;
  const rightArrow = <StyledArrowRight />;

  const onFocusChangeFunc = React.useCallback(({ focused }: any) => setFocus(focused), []);
  const falseFunc = React.useCallback(() => false, []);
  const hasError = Boolean(!isCorrect && isCorrect !== undefined);
  const validationMessageValue = hasError ? errorMessage : '\u00A0';

  useEffect(() => {
    const loadSingleDatePicker = async () => {
      const SingleDatePickerImported = (await import('react-dates')).SingleDatePicker;
      SingleDatePickerRef.current = SingleDatePickerImported;
      setIsLoaded(true);
    };
    loadSingleDatePicker().catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const SingleDatePickerComponent = SingleDatePickerRef.current;

  return (
    <FilterContainer hasValidationError={hasValidationError} className={className} focused={focus} isAvs={isAvsDesign}>
      {isLoaded && (
        <SingleDatePickerComponent
          id={`datePicker-${id}`}
          date={startDateMoment}
          onDateChange={onDateChangeFunc}
          focused={focus}
          onFocusChange={onFocusChangeFunc}
          hideKeyboardShortcutsPanel
          navNext={rightArrow}
          navPrev={leftArrow}
          daySize={35}
          noBorder
          isOutsideRange={falseFunc}
          numberOfMonths={1}
          renderMonthElement={renderMonthElementAscending}
          showDefaultInputIcon
          inputIconPosition="after"
          hasError={hasError}
          placeholder="mm-dd-yyyy"
        />
      )}
      {!validationMessageDisabled && (
        <ValidationMessage hasError={hasError}>{validationMessageValue}</ValidationMessage>
      )}
    </FilterContainer>
  );
};

interface ValidationMessageProps {
  hasError?: boolean;
}

const ValidationMessage = styled.div<ThemedStyledProps<ValidationMessageProps, DefaultTheme>>`
  margin-bottom: ${props => props.theme.pxToRem(4)};
  color: ${props => props.theme.colors.neutralGrey5};
  font-size: ${props => props.theme.fontSizes.xxs};
  ${({ hasError, theme }) =>
    hasError &&
    `
      color: ${theme.colors.interfaceRed};
      position: 'static'
    `}
`;

const FilterContainer = styled.div<{
  hasValidationError: boolean;
  disabled?: boolean;
  focused: boolean;
  isAvs?: boolean;
}>`
  width: 100%;
  .SingleDatePicker {
    width: inherit;
    border-radius: ${props => (props.isAvs ? props.theme.pxToRem(5) : props.theme.pxToRem(2))};
    border: solid ${props => props.theme.pxToRem(1)}
      ${props => (props.isAvs ? props.theme.colors.neutralGrey4 : props.theme.colors.primaryPurple)};
    border-color: ${props =>
      props.hasValidationError
        ? props.theme.colors.interfaceRed
        : props.focused
        ? props.theme.colors.primaryPurple
        : props.isAvs
        ? props.theme.colors.neutralGrey4
        : props.theme.colors.neutralGrey3};
    &:hover {
      border-color: ${props => props.theme.colors.primaryPurple};
    }
    padding-bottom: ${props => props.theme.pxToRem(2)};
    background-color: ${props => props.hasValidationError && props.theme.colors.interfaceRed};
  }
  .SingleDatePickerInput {
    display: flex;
    width: 100%;
  }
  .DateInput {
    width: inherit;
    ${props =>
      props.disabled &&
      `
      opacity: .45;
    `}
  }
  .SingleDatePickerInput {
    display: flex;
    width: 100%;
  }
  .DateInput {
    width: inherit;
  }
  .SingleDatePicker * {
    font-family: ${props => props.theme.fontFamily} !important;
    font-weight: ${props => props.theme.fontWeights.regular};
  }
  .SingleDatePicker_picker {
    z-index: 4;
  }
  .SingleDatePickerInput_calendarIcon_svg {
    & path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
  .DateInput_input {
    border: 0 !important;
  }
  .DayPicker_transitionContainer {
    border-radius: ${props => (props.isAvs ? props.theme.pxToRem(5) : props.theme.pxToRem(2))};
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey2};
    min-height: ${props => props.theme.pxToRem(310)};
  }
  .DayPicker_weekHeader_li {
    font-size: 0;
  }
  .DayPicker_weekHeader_li::first-letter {
    visibility: visible;
    font-size: ${props => props.theme.fontSizes.xxs};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: 1.5;
    letter-spacing: ${props => props.theme.pxToRem(0.22)};
    color: ${props => props.theme.colors.neutralGrey8};
  }
  .DayPickerNavigation__horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: ${props => props.theme.pxToRem(12)};
  }
  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: ${props => props.theme.pxToRem(40)};
  }
  .DayPicker_weekHeader {
    margin-top: 18px;
    height: ${props => props.theme.pxToRem(40)};
    background-color: ${props => props.theme.colors.neutralGrey1};
    display: flex;
    align-items: center;
    top: ${props => props.theme.pxToRem(48)};
  }
  .DayPicker_weekHeader_ul {
    white-space: nowrap;
  }
  .DayPickerNavigation_button {
    display: inline-block;
    width: ${props => props.theme.pxToRem(24)};
    height: ${props => props.theme.pxToRem(24)};
    margin-top: ${props => props.theme.pxToRem(24)};
  }
  .CalendarMonth_caption {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    line-height: 1.5;
    color: ${props => props.theme.colors.neutralGrey8};
    padding-top: ${props => props.theme.pxToRem(12)};
    padding-bottom: ${props => props.theme.pxToRem(68)};
  }
  .CalendarDay {
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: 1.57;
    color: ${props => props.theme.colors.neutralGrey8};
    border: none;
    outline: none;
    &:hover {
      background-color: ${props => props.theme.colors.neutralWhite};
      border: none;
      color: inherit;
    }
    &.CalendarDay__selected {
      color: ${props => props.theme.colors.neutralWhite};
      background-color: ${props => props.theme.colors.primaryPurple};
      font-weight: ${props => props.theme.fontWeights.medium};
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      left: 0;
      z-index: 2;
      &:hover {
        background-color: ${props => props.theme.colors.primaryLightPurple};
        color: ${props => props.theme.colors.neutralWhite};
      }
    }
    &.CalendarDay__highlighted_calendar {
      font-weight: ${props => props.theme.fontWeights.medium};
      color: ${props => props.theme.colors.primaryPurple};
      background-color: ${props => props.theme.colors.neutralWhite};
    }
    &.CalendarDay__selected_span {
      background-color: rgba(114, 36, 108, 0.12);
    }
    &.CalendarDay__hovered_span,
    &.CalendarDay__hovered_span:hover {
      background: none;
      border: initial;
      color: initial;
    }
  }
`;

const RenderMonthContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledArrowRight = styled(ArrowRight)`
  fill: ${props => props.theme.colors.primaryPurple};
  transform: rotate(90deg);
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledArrowLeft = styled(ArrowLeft)`
  fill: ${props => props.theme.colors.primaryPurple};
  transform: rotate(270deg);
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledCustomedCalendarIcon = styled(CustomCalendarIcon)`
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  margin: 0;
  padding: 0;
  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;
