import React, { useState } from 'react';
import { MembershipTypes } from 'mxp-schemas';
import { generatePath } from 'react-router';
import { useSelector } from 'react-redux';
import { DropdownItemProps, Dropdown as DropdownSU } from 'semantic-ui-react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import { Button, ButtonSizes, ButtonLink, ButtonEnums, Dropdown, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { DropdownProps } from 'components/atoms/Dropdown/Dropdown';
import { LogInRegisterButtons } from 'components/molecules/LogInRegisterButtons/LogInRegisterButtons';
import { ListMenuMobile } from './ListMenuMobile';
import { Trigger } from './Trigger';
import { getPath } from 'utils';

import { HEADER_EVENT, PREFIXES, handleEvent } from 'utils/Analytics';
import { Routes, UserDropdownOptionLabels, OrganizationSectionLabels } from 'constants/index';
import { IC_SCHOOL_DROPDOWN, MY_ORG_PROFILE, MY_PROFILE, MY_PURCHASES, MY_SAVED_ITEMS } from 'resources/images';
import { headerAndFooterEventPayload } from 'utils/Analytics/helpers';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';

interface Props {
  isAuth: boolean | null;
  isUserMember: boolean;
  isClientAdmin: boolean;
  isFirmBillingClientAdmin: boolean;
  isCentersClientAdmin: boolean;
  userName: string | undefined;
  userSettingsExpanded: boolean;
  orgProfileExpanded: boolean;
  mobileExpanded?: boolean;
  showLogoutLink?: boolean;
  logout: () => void;
  navigate: (path: string) => void;
  goToInternal: (route: string) => void;
  setUserSettingsExpanded: (expand: boolean) => void;
  setOrgProfileExpanded: (expand: boolean) => void;
  isUserMemberSuspended: boolean;
  isTuitionProviderClientAdmin: boolean;
  learningPathway: MembershipTypes.Pathway | null | undefined;
  hasActiveMembership?: boolean;
}

export const UserWidget = React.memo<Props>(
  ({
    isAuth,
    isUserMember,
    userName,
    userSettingsExpanded,
    showLogoutLink = false,
    isClientAdmin,
    isFirmBillingClientAdmin,
    isCentersClientAdmin,
    orgProfileExpanded,
    logout,
    navigate,
    goToInternal,
    setUserSettingsExpanded,
    setOrgProfileExpanded,
    isUserMemberSuspended,
    isTuitionProviderClientAdmin,

    learningPathway,
    hasActiveMembership,
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = React.useCallback(() => {
      setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const toggleSettings = React.useCallback(() => {
      goToInternal(getPath(Routes.MY_PROFILE_ROOT));
    }, [goToInternal]);

    const toggleOrgProfile = React.useCallback(() => {
      setUserSettingsExpanded(!userSettingsExpanded);
      setOrgProfileExpanded(!orgProfileExpanded);
    }, [setOrgProfileExpanded, orgProfileExpanded, setUserSettingsExpanded, userSettingsExpanded]);

    const handleDropdownOptionClick =
      (route: Routes) => (event: React.MouseEvent<HTMLDivElement>, data: DropdownItemProps) => {
        const textValue = (event?.target as HTMLButtonElement)?.innerText;
        const sanitizedText = textValue.replace('|', '');
        const payload = headerAndFooterEventPayload(PREFIXES.HEADER, route, sanitizedText);
        handleEvent(payload, HEADER_EVENT);
        navigate(data.value as string);
      };

    const renderDropdownOptionByKey = (key: UserDropdownOptionLabels) => {
      const testId = `user-dropdown-${key}`.replace(/ /g, '-').toLocaleLowerCase();

      switch (key) {
        case UserDropdownOptionLabels.MyProfile:
          return (
            <StyledMenuItem
              key={key}
              value={getPath(Routes.MY_PROFILE_ROOT)}
              onClick={handleDropdownOptionClick(Routes.MY_PROFILE_ROOT)}
              data-testid={testId}
            >
              <img src={MY_PROFILE} alt={key} />
              {key}
            </StyledMenuItem>
          );
        case UserDropdownOptionLabels.MyStudies:
          return (
            Boolean(learningPathway) && (
              <StyledMenuItem
                key={key}
                value={getPath(Routes.CIMA_EXAMS_DASHBOARD_ROOT)}
                onClick={handleDropdownOptionClick(Routes.CIMA_EXAMS_DASHBOARD_ROOT)}
                data-testid={testId}
              >
                <img src={IC_SCHOOL_DROPDOWN} alt={key} />
                {key}
              </StyledMenuItem>
            )
          );
        case UserDropdownOptionLabels.MyPurchases:
          return (
            <StyledMenuItem
              key={key}
              value={getPath(Routes.MY_PURCHASES)}
              onClick={handleDropdownOptionClick(Routes.MY_PURCHASES)}
              data-testid={testId}
            >
              <img src={MY_PURCHASES} alt={key} />
              {key}
            </StyledMenuItem>
          );
        case UserDropdownOptionLabels.MySavedItems:
          return (
            <StyledMenuItem
              key={key}
              value={getPath(Routes.PROFILE_SAVED_ITEMS)}
              onClick={handleDropdownOptionClick(Routes.PROFILE_SAVED_ITEMS)}
              data-testid={testId}
            >
              <img src={MY_SAVED_ITEMS} alt={key} />
              {key}
            </StyledMenuItem>
          );
        case UserDropdownOptionLabels.MyOrganizationProfile:
          return (
            isClientAdmin && (
              <StyledMenuItem
                key={UserDropdownOptionLabels.MyBusinessProfile} // TODO: Delete this condition and use only key before cima go live
                value={getPath(Routes.ORG_ROOT)}
                onClick={handleDropdownOptionClick(Routes.ORG_ROOT)}
                data-testid={testId}
              >
                <img src={MY_ORG_PROFILE} alt={key} />
                {UserDropdownOptionLabels.MyBusinessProfile}
              </StyledMenuItem>
            )
          );
        case UserDropdownOptionLabels.FirmAdmin:
          return (
            isFirmBillingClientAdmin && (
              <StyledMenuItem
                key={key}
                value={generatePath(getPath(Routes.FIRM_ROOT))}
                onClick={handleDropdownOptionClick(Routes.FIRM_ROOT)}
                data-testid={testId}
              >
                <img src={MY_ORG_PROFILE} alt={key} />
                {key}
              </StyledMenuItem>
            )
          );
        case UserDropdownOptionLabels.CenterMembershipAdmin:
          return (
            isCentersClientAdmin && (
              <StyledMenuItem
                key={key}
                value={generatePath(getPath(Routes.CENTER_ADMIN_ROOT))}
                onClick={handleDropdownOptionClick(Routes.CENTER_ADMIN_ROOT)}
                data-testid={testId}
              >
                <img src={MY_ORG_PROFILE} alt={key} />
                {key}
              </StyledMenuItem>
            )
          );
        case UserDropdownOptionLabels.TuitionProvider:
          return (
            isTuitionProviderClientAdmin && (
              <StyledMenuItem
                key={key}
                value={generatePath(getPath(Routes.TUITION_PROVIDER_ROOT))}
                onClick={handleDropdownOptionClick(Routes.TUITION_PROVIDER_ROOT)}
                data-testid={testId}
              >
                <img src={MY_ORG_PROFILE} alt={key} />
                {key}
              </StyledMenuItem>
            )
          );
        case UserDropdownOptionLabels.LogOut:
          return (
            <StyledMenuItem key={key} onClick={logout} data-testid={testId}>
              {key}
            </StyledMenuItem>
          );
      }
    };

    const renderLogInRegisterButtons = (): React.ReactNode => (
      <LogInRegisterButtons logInButtonTestId="uw-login" registerButtonTestId="uw-register" />
    );

    const renderListMenuMobile = (label: any) => {
      const commonProps = {
        key: label,
        label,
        goToInternal,
      };

      if (label === UserDropdownOptionLabels.MyOrganizationProfile) {
        return <ListMenuMobile {...commonProps} goToSubMenu={toggleOrgProfile} />;
      }

      return <ListMenuMobile {...commonProps} />;
    };

    const renderedListMenuMobiles = Object.values(UserDropdownOptionLabels).map(label => renderListMenuMobile(label));

    const { useNewSignUpOnly } = useSelector(featureTogglesSelector);
    const pathToRedirect = useNewSignUpOnly ? Routes.PRE_SIGN_UP : Routes.MEMBERSHIP_OVERVIEW;
    const to = getPath(pathToRedirect);

    return (
      <>
        <StyledOnlyDesktop>
          {isAuth ? (
            <>
              <WidgetContainer>
                {!isUserMember && !hasActiveMembership && !isUserMemberSuspended && (
                  <StyledButton
                    to={to}
                    testId="membership"
                    size={ButtonSizes.small}
                    variant={ButtonEnums.variants.primary}
                    bordered
                  >
                    Become a Member
                  </StyledButton>
                )}
                <StyledDropdown
                  data-is-open={isOpen}
                  onOpen={toggleDropdown}
                  onClose={toggleDropdown}
                  data-testid="user-dropdown"
                  trigger={<Trigger userName={userName} showLogoutLink={showLogoutLink} logout={logout} />}
                >
                  <DropdownSU.Menu>
                    {Object.values(UserDropdownOptionLabels).map(renderDropdownOptionByKey)}
                  </DropdownSU.Menu>
                </StyledDropdown>
              </WidgetContainer>
            </>
          ) : (
            renderLogInRegisterButtons()
          )}
        </StyledOnlyDesktop>
        <OnlyMobile>
          {isClientAdmin && orgProfileExpanded && (
            <MyAccountContainer>
              <SubMenuTitle>Organization Profile</SubMenuTitle>
              <StyledList>
                {Object.values(OrganizationSectionLabels).map(label => (
                  <ListMenuMobile key={label} label={label} goToInternal={goToInternal} />
                ))}
              </StyledList>
            </MyAccountContainer>
          )}
          {userSettingsExpanded && (
            <MyAccountContainer>
              <SubMenuTitle>Account</SubMenuTitle>
              <StyledList>{renderedListMenuMobiles}</StyledList>
            </MyAccountContainer>
          )}
          {!(orgProfileExpanded || userSettingsExpanded) && (
            <>
              <UserInfoContainer>
                {isAuth ? (
                  <>
                    <Greet>Hi, {userName}</Greet>
                    <GoToSubmenuButton
                      onClick={toggleSettings}
                      iconPosition={ButtonEnums.iconPosition.right}
                      iconName="angle right"
                      testId="user-settings"
                    >
                      Account Settings
                    </GoToSubmenuButton>
                  </>
                ) : (
                  renderLogInRegisterButtons()
                )}
              </UserInfoContainer>
            </>
          )}
        </OnlyMobile>
      </>
    );
  }
);

interface UserDropdownProps extends DropdownProps {
  'data-is-open': boolean;
}

const StyledOnlyDesktop = styled(OnlyDesktop)`
  height: 100%;
  padding-left: ${props => props.theme.pxToRem(20)};
  margin: ${props =>
    `${props.theme.pxToRem(-16)} ${props.theme.pxToRem(-50)} ${props.theme.pxToRem(-16)} ${props.theme.pxToRem(-12)}`};
`;

const StyledDropdown = styled(Dropdown)<ThemedStyledProps<UserDropdownProps, DefaultTheme>>`
  &&& {
    position: relative;
    width: calc(100% + ${props => props.theme.pxToRem(20)});
    max-width: ${props => props.theme.pxToRem(265)};
    height: ${props => props.theme.pxToRem(89)};
    padding-right: ${props => props.theme.pxToRem(34)};
    padding-left: ${props => props.theme.pxToRem(20)};
    margin-left: ${props => props.theme.pxToRem(-20)};
    border: 0;
    border-radius: ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(2)} 0 0;
    background-color: ${props => props.theme.colors.neutralWhite};
    ${props =>
      props['data-is-open'] &&
      `
       box-shadow: 0 ${props.theme.pxToRem(14)} ${props.theme.pxToRem(14)} 0 rgba(0, 0, 0, .1);

       ::before {
         display: none;
       }
    `}

    ::before {
      position: absolute;
      content: '';
      width: ${props => props.theme.pxToRem(1)};
      height: ${props => props.theme.pxToRem(50)};
      margin-left: ${props => props.theme.pxToRem(-20)};
      background-color: ${props => props.theme.colors.neutralGrey3};
    }

    > .menu {
      margin-right: 0;
      margin-left: 0;
      border: 0;
      box-shadow: 0 ${props => props.theme.pxToRem(14)} ${props => props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);

      ::before {
        border-color: ${props => props.theme.colors.neutralGrey2};
      }
    }
  }
`;

const StyledMenuItem = styled(DropdownSU.Item)`
  &&&&&& {
    position: relative;
    display: flex;
    align-items: center;
    height: ${props => props.theme.pxToRem(62)};
    padding: ${props => props.theme.pxToRem(18)} ${props => props.theme.pxToRem(20)} !important;
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 1.5;

    ::after {
      position: absolute;
      bottom: ${props => props.theme.pxToRem(-1)};
      left: ${props => props.theme.pxToRem(20)};
      content: '';
      width: calc(100% - 2 * ${props => props.theme.pxToRem(20)});
      height: ${props => props.theme.pxToRem(1)};
      background-color: ${props => props.theme.colors.neutralGrey2};
    }

    :last-of-type::after {
      display: none;
    }

    :hover {
      background-color: ${props => props.theme.colors.primaryLightPurple};
      color: ${props => props.theme.colors.neutralWhite};
      font-weight: ${props => props.theme.fontWeights.regular};

      ::after {
        display: none;
      }

      > img {
        filter: brightness(0) invert(1);
      }
    }

    > img {
      width: ${props => props.theme.pxToRem(20)};
      /* IE11 support */
      height: 100%;
      margin-right: ${props => props.theme.pxToRem(10)};
    }
  }
`;

const Greet = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const GoToSubmenuButton = styled(Button)`
  &&&& {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    border: none;
    background: none;
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.regular};
    text-align: left;

    i.icon {
      margin-right: 0;
      float: right;
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const UserInfoContainer = styled.div`
  height: ${props => props.theme.pxToRem(120)};
  padding: ${props => props.theme.pxToRem(20)};
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey2};
  background-color: ${props => props.theme.colors.neutralGrey1};
`;

const MyAccountContainer = styled.div`
  padding: ${props => props.theme.pxToRem(20)};
`;

const SubMenuTitle = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(25)};
  border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.25;
`;

const StyledList = styled.ul`
  padding-left: ${props => props.theme.pxToRem(20)};
  margin: 0;
  list-style: none;
`;

const StyledButton = styled(ButtonLink)`
  &&&& {
    font-size: ${props => props.theme.fontSizes.s};
    flex-basis: 80%;
    min-width: ${props => props.theme.pxToRem(160)};
    min-height: ${props => props.theme.pxToRem(45)};
    padding: ${props => props.theme.pxToRem(8)};
  }
`;
const WidgetContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  height: 100%;
  width: 100%;
`;
