import { addMembershipToCart, fetchMembershipTiers, setMembershipPackageTier } from 'modules/membership';
import { Admin as AdminUtils } from 'mxp-utils';
import { MembershipTypes } from 'mxp-schemas';
import {
  setIsLearningPathToChangeMatchedPreviouslySelectedPath,
  setMembershipEvent,
  setMembershipPackageType,
} from 'modules/membership/actions';
import { getPersonLevelExemptionId } from 'modules/personLevelExemption';

const sessionKey = 'selectedStudyPath';
export const storeSelectedPathInSession = (selectedStudyPath: {
  id: string;
  typeSlug: string;
  isApprentice?: boolean;
}) => {
  sessionStorage.setItem(sessionKey, JSON.stringify(selectedStudyPath));
};

// Use this function to fetch all membership package/tier related data.
// Call the fetchMembershipPackage without any arguments to refetch necessary records using useEffect didMount
// as it is needed in FLP when navigating back to the package selector page.
// Otherwise, pass the arguments if we want to initialize the membership packages upon selecting a pathway
export const fetchMembershipPackagesAndTiers = async (
  dependenciesAndCheckItems: {
    candidateMembershipKey: State.MembershipProductBlock | undefined;
    isSubscribedToCimaPq: boolean;
    isCimaPQCandidateLapsed: boolean;
    personAccountId: string;
    application: State.Application;
    currentJourneyLearningPathway: string;
    isFLPUpgrade: boolean;
    isFLPSwitch: boolean;
    isUserMember: boolean;
    isSubscribedToFlp: boolean;
    isSubscribedToApprenticeship: boolean;
    membershipTierList:
      | {
          type: string;
          tier: string | undefined;
          productSlug: string | undefined;
          orderDate: string | undefined;
        }[]
      | undefined;
    changeMyLearningPath: boolean;
    isCimaFLPLapsed: boolean;
    isCimaRenewal?: boolean;
    dispatch: (functionArg: () => void) => void;
    redirectCallback: (() => void) | null;
    setSelectedPathwayId: React.Dispatch<React.SetStateAction<string>> | null;
    setIsLoadingPathway: React.Dispatch<React.SetStateAction<boolean>> | null;
  },
  id?: string,
  typeSlug?: string,
  membershipType?: string[],
  isApprentice?: boolean
) => {
  const {
    candidateMembershipKey,
    isSubscribedToCimaPq,
    isCimaPQCandidateLapsed,
    currentJourneyLearningPathway,
    isFLPUpgrade,
    isFLPSwitch,
    isUserMember,
    isSubscribedToFlp,
    isSubscribedToApprenticeship,
    membershipTierList,
    changeMyLearningPath,
    isCimaFLPLapsed,
    isCimaRenewal,
    dispatch,
    redirectCallback,
    setSelectedPathwayId,
    setIsLoadingPathway,
  } = dependenciesAndCheckItems || {};

  if (id && typeSlug) {
    storeSelectedPathInSession({ id, typeSlug, isApprentice });
  }

  const selectedStudyPathObject =
    (sessionStorage.getItem(sessionKey) && JSON.parse(sessionStorage.getItem(sessionKey) as string)) || {};
  const ifFLPPathwaySelected = selectedStudyPathObject?.typeSlug === MembershipTypes.CIMALearningPathwaySlug.FLP;
  const isRenewalSwitchToFlp = isCimaRenewal && isSubscribedToCimaPq && ifFLPPathwaySelected;
  const isCimaPQCandidateLapsedSwitchToFlp = ifFLPPathwaySelected && isCimaPQCandidateLapsed;

  if (setSelectedPathwayId) {
    setSelectedPathwayId(id || '');
  }

  if (setIsLoadingPathway) {
    setIsLoadingPathway(true);
  }
  dispatch(setMembershipEvent('cimaRehydrateDataHookIsFetched', true));

  // If you have decided to change your learning path AND the current Selected Path matched the previously Selected Path
  // Trigger setMembershipPackageType action that retains the previously selected add-ons (userChoice.addOns) instead of resetting it to empty array
  if (
    changeMyLearningPath &&
    MembershipTypes.LearningPathwaySlug[currentJourneyLearningPathway] === selectedStudyPathObject?.typeSlug
  ) {
    dispatch(setIsLearningPathToChangeMatchedPreviouslySelectedPath(true));
    await dispatch(
      setMembershipPackageType(candidateMembershipKey?.id, candidateMembershipKey?.slug, undefined, undefined, true)
    );
  } else if (MembershipTypes.LearningPathwaySlug[currentJourneyLearningPathway] === selectedStudyPathObject?.typeSlug) {
    await dispatch(
      setMembershipPackageType(candidateMembershipKey?.id, candidateMembershipKey?.slug, true, false, true)
    );
  } else {
    if (!!candidateMembershipKey?.id) {
      await dispatch(setMembershipPackageType(candidateMembershipKey?.id, candidateMembershipKey?.slug));
    }
  }
  if (isCimaRenewal && isUserMember) {
    await dispatch(getPersonLevelExemptionId());
  }
  if (isFLPSwitch || isFLPUpgrade || isRenewalSwitchToFlp || isCimaPQCandidateLapsedSwitchToFlp) {
    await dispatch(fetchMembershipTiers(false, '', true, false));
  } else if (isCimaRenewal && !isSubscribedToFlp) {
    await dispatch(fetchMembershipTiers(false, '', false, true));
    if (isSubscribedToApprenticeship) {
      await dispatch(setMembershipPackageTier(membershipTierList?.[0].tier));
      await dispatch(addMembershipToCart());
    }
  }

  if (setIsLoadingPathway) {
    setIsLoadingPathway(false);
  }

  if (!selectedStudyPathObject?.isApprentice) {
    if ((isSubscribedToFlp && AdminUtils.isCimaPqPathway(currentJourneyLearningPathway)) || isCimaFLPLapsed) {
      await dispatch(fetchMembershipTiers(false, '', isCimaFLPLapsed));
    } else if (isSubscribedToFlp) {
      await dispatch(fetchMembershipTiers(false, '', true));
    }

    if (redirectCallback) {
      redirectCallback();
    }
  }
};
