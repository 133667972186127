import React from 'react';
import styled from 'styled-components';
import { Divider, Button, ButtonEnums, Link } from 'components/atoms';
import { ModalTopicUnfollowContainer } from 'containers/ModalTopicUnfollowContainer';
import { generatePath } from 'react-router-dom';
import { Routes } from 'constants/index';
import { getPath } from 'utils';

export interface FollowTopicIF {
  id: string;
  name: string;
  slug: string;
}

export interface FollowSubtopicIF extends FollowTopicIF {
  topicId: string;
  topicSlug: string;
}

export interface Props {
  topics: FollowTopicIF[];
  subtopics: FollowSubtopicIF[];
  userTopicsPref: string[];
  userSubtopicsPref: string[];
  toggleTopicPref: (topicId: string, isOnEvent: boolean) => void;
  toggleSubtopicPref: (topicId: string, subtopicId: string, isOnEvent: boolean) => void;
}

export const ContentTopicsFollow: React.FC<Props> = props => {
  const { topics, subtopics, userTopicsPref, userSubtopicsPref, toggleTopicPref, toggleSubtopicPref } = props;

  const handleToggleTopic = React.useCallback(
    (topicId: string, isOnEvent: boolean) => {
      toggleTopicPref(topicId, isOnEvent);
    },
    [toggleTopicPref]
  );

  return (
    <>
      <StyledSmallHeading tabIndex={0}>Mentioned in this article</StyledSmallHeading>

      <Divider />

      <StyledLargeHeading tabIndex={0}>Topics</StyledLargeHeading>
      {topics &&
        topics.map(topic => {
          const isActive: boolean = userTopicsPref.includes(topic.id);
          const isOnEvent: boolean = !isActive;

          return (
            <StyledTopicLine key={topic.id} data-testid={`topic-${topic.id}`}>
              <StyledTopicLink to={generatePath(getPath(Routes.TOPIC_AGGS_PAGE), { topicSlug: topic.slug })}>
                {topic.name}
              </StyledTopicLink>
              <StyledFollowButton
                active={isActive}
                testId={`follow-${topic.id}`}
                onClick={handleToggleTopic.bind(null, topic.id, isOnEvent)}
                iconName={isActive ? 'check' : 'plus'}
                iconPosition={ButtonEnums.iconPosition.left}
                variant={ButtonEnums.variants.primaryToggle}
                toggle
                bordered
              >
                {isActive ? 'Following' : 'Follow'}
              </StyledFollowButton>
            </StyledTopicLine>
          );
        })}

      <Divider />

      {subtopics && subtopics.length !== 0 && (
        <>
          <StyledLargeHeading tabIndex={0}>Subtopics</StyledLargeHeading>
          {subtopics.map(subtopic => {
            const isActive: boolean = userSubtopicsPref.includes(subtopic.id);
            const isOnEvent: boolean = !isActive;
            return (
              <StyledTopicLine key={subtopic.id} data-testid={`sub-${subtopic.id}`}>
                <StyledTopicLink
                  to={generatePath(getPath(Routes.TOPIC_AGGS_PAGE), {
                    topicSlug: subtopic.topicSlug,
                    subtopicSlug: subtopic.slug,
                  })}
                >
                  {subtopic.name}
                </StyledTopicLink>
                <StyledFollowButton
                  active={isActive}
                  testId={`follow-${subtopic.id}`}
                  onClick={toggleSubtopicPref.bind(null, subtopic.topicId, subtopic.id, isOnEvent)}
                  iconName={isActive ? 'check' : 'plus'}
                  iconPosition={ButtonEnums.iconPosition.left}
                  variant={ButtonEnums.variants.primaryToggle}
                  toggle
                  bordered
                >
                  {isActive ? 'Following' : 'Follow'}
                </StyledFollowButton>
              </StyledTopicLine>
            );
          })}
          <Divider />
        </>
      )}
      <ModalTopicUnfollowContainer />
    </>
  );
};

const StyledLargeHeading = styled.h3`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.l};
  margin: 20px 0 25px;
  line-height: 1.5;
`;

const StyledSmallHeading = styled.h2`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.s};
  margin: 0;
  line-height: 1.5;
`;

const StyledTopicLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledTopicLink = styled(Link)`
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledFollowButton = styled(Button)`
  ${props => props.theme.mediaQueries.computerMin} {
    &.toggle:focus {
      border: ${props => props.theme.pxToRem(1)} solid
        ${props => (props.bordered ? props.theme.colors.primaryLightPurple : 'transparent')};
      background-color: ${props => props.theme.colors.primaryLightPurple} !important;
      color: ${props => props.theme.colors.neutralWhite} !important;
    }

    &.toggle.active:hover {
      border: ${props => props.theme.pxToRem(1)} solid
        ${props => (props.bordered ? props.theme.colors.interfaceRed : 'transparent')};
      background-color: ${props => props.theme.colors.interfaceRed} !important;
    }
  }
`;
