import React from 'react';
import styled from 'styled-components';
import { generatePath } from 'react-router-dom';
import { Link, LinkEnums } from 'components/atoms/Link/Link';
import { Routes, FilterNames } from 'constants/index';
import { getPath } from 'utils';

interface CardTagProps {
  item?: State.ContentCardTag;
  role?: State.CategoriesItem;
  tagType?: string;
  onClick?: () => void;
}

export const CardTag: React.FC<CardTagProps> = React.memo(({ item, role, tagType, onClick }) => {
  if (role) {
    if (role.slug) {
      const premiumFilter: string = `${FilterNames.PREMIUM}=${role.slug}`;
      const roleTo: string = `${getPath(Routes.SEARCH).slice(0, getPath(Routes.SEARCH).indexOf(':'))}?${premiumFilter}`;
      return (
        <StyledRoleLink
          type={LinkEnums.type.standaloneLink}
          underline={LinkEnums.underline.noUnderlineOnHover}
          to={roleTo}
          testId={`role-tag-${role.name}`}
          onClick={onClick}
        >
          {role.name}
        </StyledRoleLink>
      );
    }
    return <StyledRole data-testid={`role-tag-${role.name}`}>{role.name}</StyledRole>;
  }
  if (!item) {
    return null;
  }
  const { name, topicSlug, industrySlug, trendSlug, skillSlug, slug } = item;
  const to: string =
    tagType === 'industry'
      ? generatePath(getPath(Routes.INDUSTRY_AGGS_PAGE), {
          industrySlug: industrySlug || slug,
        })
      : tagType === 'trend'
      ? generatePath(getPath(Routes.TREND_AGGS_PAGE), {
          trendSlug: trendSlug || slug,
        })
      : tagType === 'skill'
      ? generatePath(getPath(Routes.SKILL_AGGS_PAGE), {
          skillSlug: skillSlug || slug,
          subskillSlug: skillSlug && slug,
        })
      : generatePath(getPath(Routes.TOPIC_AGGS_PAGE), {
          topicSlug: topicSlug || slug,
          subtopicSlug: topicSlug && slug,
        });

  return (
    <StyledLink
      type={LinkEnums.type.standaloneLink}
      underline={LinkEnums.underline.noUnderlineOnHover}
      to={to}
      testId={`tag-${name}`}
    >
      {name}
    </StyledLink>
  );
});

const StyledRoleLink = styled(Link)`
  overflow: hidden;
  height: ${props => props.theme.pxToRem(20)};
  padding: 0 ${props => props.theme.pxToRem(8)};
  border-radius: ${props => props.theme.pxToRem(10)};
  margin-right: ${props => props.theme.pxToRem(4)};
  margin-bottom: ${props => props.theme.pxToRem(4)};
  background-color: ${props => props.theme.colors.membershipTagPurple};
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: ${props => props.theme.pxToRem(20)};
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  ${props => props.theme.mediaQueries.desktopOnly} {
    &:hover {
      background-color: ${props => props.theme.colors.primaryPurple};
      color: ${props => props.theme.colors.neutralWhite};
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    &:active {
      background-color: ${props => props.theme.colors.primaryPurple};
      color: ${props => props.theme.colors.neutralWhite};
    }
  }
`;

const StyledRole = styled.div`
  overflow: hidden;
  height: ${props => props.theme.pxToRem(20)};
  padding: 0 ${props => props.theme.pxToRem(8)};
  border-radius: ${props => props.theme.pxToRem(10)};
  margin-right: ${props => props.theme.pxToRem(4)};
  margin-bottom: ${props => props.theme.pxToRem(4)};
  background-color: ${props => props.theme.colors.membershipTagPurple};
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: ${props => props.theme.pxToRem(20)};
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  overflow: hidden;
  height: ${props => props.theme.pxToRem(20)};
  padding: 0 ${props => props.theme.pxToRem(8)};
  border-radius: ${props => props.theme.pxToRem(10)};
  margin-right: ${props => props.theme.pxToRem(4)};
  margin-bottom: ${props => props.theme.pxToRem(4)};
  background-color: ${props => props.theme.colors.neutralGrey2};
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: ${props => props.theme.pxToRem(20)};
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.primaryPurple};
    color: ${props => props.theme.colors.neutralWhite};
  }
`;
