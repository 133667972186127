import React from 'react';
import { Modal as SemanticUIModal } from 'semantic-ui-react';
import styled from 'styled-components';
import { AicpaLogo, Divider, OnlyMobile, OnlyDesktop, GlobalModalDimmerStyle } from 'components/atoms';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { LoginAndRegistrationContainer } from 'containers/LoginAndRegistrationContainer';
import { AuthenticationPageToShow } from 'modules/layouts/constants';

interface AuthenticationModalProps {
  closeAuthenticationModal: () => void;
  isAuthenticationModalVisible: boolean;
  pageToShow: AuthenticationPageToShow;
  clearUserError: () => void;
}

export const AuthenticationModal: React.FC<AuthenticationModalProps> = ({
  closeAuthenticationModal,
  isAuthenticationModalVisible,
  pageToShow,
  clearUserError,
}) => {
  const isLoginTabActive =
    pageToShow === AuthenticationPageToShow.LOGIN_MODAL || pageToShow === AuthenticationPageToShow.LOGIN_PAGE;

  const onClose = () => {
    closeAuthenticationModal();
    clearUserError();
  };

  return (
    <>
      <GlobalModalDimmerStyle isFullWidthOnMobile />
      <StyledModal
        closeOnDimmerClick={false}
        data-testid="authentication"
        onClose={onClose}
        open={isAuthenticationModalVisible}
        centered={false} // For IE11.
      >
        <ModalHeading>
          <OnlyMobile>
            <AicpaLogo overrideAnalytics={true} />
          </OnlyMobile>
          <CloseButton onClick={onClose} data-testid="modal-authentication-close">
            <StyledCloseIcon />
          </CloseButton>
        </ModalHeading>
        <OnlyMobile>
          <DividerWithNoBottomMargin />
        </OnlyMobile>
        <ModalContent>
          <CenteredOnlyDesktop>
            <AicpaLogo overrideAnalytics={true} />
          </CenteredOnlyDesktop>
          <Title>{isLoginTabActive ? 'Welcome back to aicpa-cima.com ' : 'Your new home for career success'}</Title>
          <LoginAndRegistrationContainer isModal />
        </ModalContent>
      </StyledModal>
    </>
  );
};

const CenteredOnlyDesktop = styled(OnlyDesktop)`
  display: flex;
  justify-content: center;
`;

const DividerWithNoBottomMargin = styled(Divider)`
  &&& {
    margin-bottom: 0;
  }
`;

const ModalContent = styled.div`
  padding: 2rem 1.25rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => `${props.theme.mediaQueries.desktopOnly} {
    padding: 0 ${props.theme.pxToRem(95)};
  }`}
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.neutralGrey8};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;
  margin-top: ${props => props.theme.pxToRem(40)};
  margin-bottom: ${props => props.theme.pxToRem(4)};
  line-height: 1.33;
`;

const ModalHeading = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1.25rem;

  ${props => props.theme.mediaQueries.mobileOnly} {
    justify-content: space-between;
  }
`;

const StyledModal = styled(SemanticUIModal)`
  .ui.page.modals.dimmer.visible.active {
    background-color: transparent;
    background-image: linear-gradient(to right, rgba(106, 45, 135, 0.9), rgba(169, 61, 105, 0.9));
    padding: 0;
  }
  &&&& button {
    font-family: ${props => props.theme.fontFamily};
  }
  &&&&&& {
    font-family: ${props => props.theme.fontFamily};
    background-color: ${props => props.theme.colors.neutralWhite};
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
    padding: ${props => props.theme.pxToRem(20)} 0;

    ${props => `${props.theme.mediaQueries.desktopOnly} {
      width: ${props.theme.pxToRem(590)};
    }`}

    ${props => `${props.theme.mediaQueries.mobileOnly} {
      border-radius: 0;
      margin: 0;
      width: 100%;
      min-height: 100%;
    }`}
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.neutralGrey4};

  ${props => props.theme.mediaQueries.mobileOnly} {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;
