import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { App } from 'components/App/App';
import {
  userSessionInvalidated,
  isDataExistSelector,
  isAuthSelector,
  userHasPrefSelector,
  isClientAdminSelector,
  isFirmBillingClientAdminSelector,
  isCentersClientAdminSelector,
  isLicensedCPASelector,
  cpaStatusFetchedSelector,
  isUserMemberSelector,
  isUserMemberSuspendedSelector,
  userHasIndustryPrefSelector,
  userHasTrendPrefSelector,
  userHasSkillPrefSelector,
  hasUserPerManagementLevelSelector,
} from 'modules/user/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { checkSession } from 'modules/user/actions';
import { productTypesSlugsStrSelector } from 'modules/productTypes/selectors';
import { topicsSlugsStrSelector } from 'modules/topics/selectors';
import {
  USE_CREDENTIAL_DIRECTORY,
  USE_CR_215,
  USE_CR_205_OFFLINE_EXEMPTION_CALCULATOR,
} from 'modules/featureToggle/constants';
import { toggleDashboardSelector, getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { removeSessionStorageItem } from 'utils';
import { isDevelopment } from 'utils/env';
import { StorageNames } from 'constants/index';
import { rehydrateUserData } from 'modules/app';
import { checkDeviceSSOAuthSupport, checkIsUserOsMacos } from 'modules/device/actions';
import { getStartupItems } from 'modules/startup';
import { getInviteIdSelector, isPaperBillingSelector } from 'modules/startup/selectors';

export const ssrLoadActions: any[] = [
  getStartupItems,
  // getBrandImages,
];

const mapActionCreators = (dispatch: Dispatch) => ({
  async appInit(): Promise<void> {
    await dispatch(checkDeviceSSOAuthSupport());
    await dispatch(checkIsUserOsMacos());
    const checkSessionAction = await dispatch(checkSession());
    const { isAuth, session }: { isAuth: boolean; session: string } = checkSessionAction.payload;
    removeSessionStorageItem(StorageNames.actionsToDispatchAfterLogin);
    // load data on the client side in development mode only
    // FixMe. isDevelopment can be replaced by actions reducers isFetched conditionals.
    if (isDevelopment && ssrLoadActions && ssrLoadActions.length) {
      // Ensure SSR actions are completed before app container loaded (e.g. feature flags are fetched properly)
      await Promise.all(ssrLoadActions.map(action => dispatch(action())));
    }
    dispatch(rehydrateUserData(isAuth, session));
  },
});

const mapStateToProps = (state: State.Root) => ({
  isAuth: isAuthSelector(state),
  isAdminPortal: isAdminPortalSelector(state),
  isClientAdmin: isClientAdminSelector(state),
  isFirmBillingClientAdmin: isFirmBillingClientAdminSelector(state),
  isCentersClientAdmin: isCentersClientAdminSelector(state),
  isDataExist: isDataExistSelector(state),
  hasPreferences: userHasPrefSelector(state),
  hasIndustryPreferences: userHasIndustryPrefSelector(state),
  hasTrendPreferences: userHasTrendPrefSelector(state),
  hasSkillPreferences: userHasSkillPrefSelector(state),
  useCR215ExamResultDisplay: getFeatureToggleByKeySelector(state, USE_CR_215),
  userSessionInvalidated: userSessionInvalidated(state),
  showTogglePage: toggleDashboardSelector(state),
  productTypesSlugsStr: productTypesSlugsStrSelector(state),
  topicsSlugsStr: topicsSlugsStrSelector(state),
  cpaStatusFetched: cpaStatusFetchedSelector(state),
  isLicensedCPA: isLicensedCPASelector(state),
  isUserMember: isUserMemberSelector(state),
  isUserMemberSuspended: isUserMemberSuspendedSelector(state),
  isPaperBilling: isPaperBillingSelector(state),
  inviteId: getInviteIdSelector(state),
  useCredentialDirectory: getFeatureToggleByKeySelector(state, USE_CREDENTIAL_DIRECTORY),
  useCR205OfflineExemptionCalculator: getFeatureToggleByKeySelector(state, USE_CR_205_OFFLINE_EXEMPTION_CALCULATOR),
  hasUserPerManagementLevel: hasUserPerManagementLevelSelector(state),
});

export const AppContainer = connect(mapStateToProps, mapActionCreators)(App);
