import { AicpaLogo, OnlyDesktopCSS, OnlyMobileCSS, StepperBar, CimaStepperBar } from 'components/atoms';
import { Routes, MembershipApplicationStep, MembershipApplicationStepsMaps } from '../../../constants';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getPath } from 'utils';
import { MiniUserWidgetContainer } from 'containers/UserWidgetContainer';
import styled from 'styled-components';
import { StepperProps } from 'components/atoms/StepperBar/StepperBar';
import { isUserMemberSelector, learningPathwaySelector, learningPathwayToSwitchSelector } from 'modules/user/selectors';
import { useSelector } from 'react-redux';
import { getMembershipApplicationTypeSelector } from 'modules/cart/selectors';
import { isAicpaAffiliateLapsedSelector, isCimaMembershipJourneySelector } from 'modules/membership/selectors';
import { Product } from 'mxp-schemas';
import { Admin as AdminUtils } from 'mxp-utils';
import { USE_NEW_SIGNUP_ONLY } from 'modules/featureToggle/constants';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
interface Props {
  isCenterMembershipJourney?: boolean;
  isCIMAJourney?: boolean;
  passedSteps?: MembershipApplicationStep[];
}

export const HeaderMembershipApplication: React.FC<Props> = ({ passedSteps }) => {
  const { pathname: currPath } = useLocation();
  const history = useHistory();

  const isUserMember = useSelector(isUserMemberSelector);
  const membershipApplicationTypeSelector = useSelector(getMembershipApplicationTypeSelector);
  const isCimaMembershipJourney = useSelector(isCimaMembershipJourneySelector);
  const learningPathway = useSelector(learningPathwaySelector);
  const learningPathwayToSwitch = useSelector(learningPathwayToSwitchSelector);
  const isAicpaAffiliateLapsed = useSelector(isAicpaAffiliateLapsedSelector);
  const isFlpPathway = AdminUtils.isFlpPathway(learningPathway as string);
  const membershipApplicationType =
    !membershipApplicationTypeSelector && isCimaMembershipJourney && learningPathway && learningPathwayToSwitch
      ? Product.MembershipApplicationType.CIMA
      : membershipApplicationTypeSelector;
  const isCimaPages =
    membershipApplicationType === Product.MembershipApplicationType.CIMA || (isAicpaAffiliateLapsed && isFlpPathway);
  const pageApplicationSteps =
    MembershipApplicationStepsMaps[
      membershipApplicationType || isCimaPages
        ? Product.MembershipApplicationType.CIMA
        : Product.MembershipApplicationType.AICPA
    ];

  const useNewMembershipAICPA = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_NEW_SIGNUP_ONLY)
  );

  const isSubscribedToCimaPq = AdminUtils.isCimaPqPathway(learningPathway as string);
  const isSubscribedToApprenticeship = AdminUtils.isApprenticePathway(learningPathway as string);

  const membershipPathFound =
    currPath !== getPath(Routes.APPLICATION_FORM_START) ||
    currPath !== getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_START) ||
    currPath !== getPath(Routes.CENTER_MEMBERSHIP_FORM_START);

  const handleLogoClick = () => {
    history.push(getPath(Routes.ROOT));
  };

  const applicationSteps: MembershipApplicationStep[] = useMemo(() => {
    if (membershipApplicationType === Product.MembershipApplicationType.CIMA && isUserMember) {
      if (isSubscribedToCimaPq) {
        return pageApplicationSteps.filter((step: MembershipApplicationStep) => step.showInRenewals);
      }
      if (isSubscribedToApprenticeship) {
        return pageApplicationSteps.filter(
          (step: MembershipApplicationStep) => step.showInRenewals && step.name !== 'Donations'
        );
      }
    }
    return pageApplicationSteps;
  }, [
    membershipApplicationType,
    isUserMember,
    pageApplicationSteps,
    isSubscribedToApprenticeship,
    isSubscribedToCimaPq,
  ]);

  const steps: StepperProps[] = useMemo(
    () =>
      (passedSteps ?? applicationSteps)
        .filter(({ name }) => name !== 'Start')
        .map(({ name, pathName }, i) => {
          const path = getPath(pathName);
          const currPageId = (passedSteps ?? applicationSteps).findIndex(el => getPath(el.pathName) === currPath) - 1;
          const orderNum = isCimaPages && !isUserMember ? 9 : 1;
          return {
            title: `${i + orderNum}. ${name}`,
            isActive: currPath === path,
            isSuccess: i < currPageId,
          };
        }) || [],
    // eslint-disable-next-line
    [currPath]
  );

  return (
    <>
      <OnlyDesktopCSS>
        <StyledContainer className="application-header">
          {isCimaPages && !isUserMember ? (
            <HiddenItemContainer>
              <StyledAicpaLogo onClick={handleLogoClick} />
            </HiddenItemContainer>
          ) : (
            <StyledAicpaLogo onClick={handleLogoClick} />
          )}
          {useNewMembershipAICPA ? (
            membershipPathFound && isCimaPages ? (
              <CimaStepperBar steps={steps} />
            ) : null
          ) : (
            membershipPathFound && <CimaStepperBar steps={steps} />
          )}
          {isCimaPages && !isUserMember ? (
            <HiddenItemContainer>
              <MiniUserWidgetContainer showLogoutLink />
            </HiddenItemContainer>
          ) : (
            <MiniUserWidgetContainer showLogoutLink />
          )}
        </StyledContainer>
      </OnlyDesktopCSS>
      <OnlyMobileCSS>
        <StyledMobileContainer>
          <StyledMobileTop>
            <StyledAicpaLogo onClick={handleLogoClick} />
            <MiniUserWidgetContainer showLogoutLink />
          </StyledMobileTop>
          {currPath !== getPath(Routes.APPLICATION_FORM_START) && (
            <StyledMobileBottom isCimaPages={isCimaPages} useNewMembershipAICPA={useNewMembershipAICPA}>
              {useNewMembershipAICPA ? isCimaPages ? <StepperBar steps={steps} /> : null : <StepperBar steps={steps} />}
            </StyledMobileBottom>
          )}
        </StyledMobileContainer>
      </OnlyMobileCSS>
    </>
  );
};

const StyledAicpaLogo = styled(AicpaLogo)`
  cursor: pointer;
`;

const HiddenItemContainer = styled.div`
  &&&& {
    cursor: none;
    visibility: hidden;
  }
`;

const StyledContainer = styled.div`
  &&&& {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: ${props => props.theme.pxToRem(-55)};
    margin-right: ${props => props.theme.pxToRem(-50)};
  }
`;

const StyledMobileContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledMobileTop = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(14)};
    margin-bottom: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledMobileBottom = styled.div<{ isCimaPages: boolean; useNewMembershipAICPA: boolean }>`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.neutralGrey1};
  padding: ${props => props.theme.pxToRem(8)} 0;
  margin: 0 ${props => props.theme.pxToRem(-22.5)};
  width: 100%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(8)};
    background: ${({ theme, isCimaPages, useNewMembershipAICPA }) =>
      useNewMembershipAICPA
        ? isCimaPages
          ? theme.colors.neutralGrey1
          : theme.colors.neutralWhite
        : theme.colors.neutralGrey1};
  }
`;
