import React, { memo } from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { IC_CANCEL, IC_ADD, IC_EDIT, IC_VIEW } from 'resources/images';
import { Grid } from 'components/atoms';
import { Label } from 'semantic-ui-react';
import { datesToYearsMonths, partTimeDatesToYearsMonths } from 'utils/dateHelper';
import { MembershipTypes } from 'mxp-schemas';
import moment from 'moment-timezone';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { useSelector } from 'react-redux';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_REMOVE_PER_MODULE } from 'modules/featureToggle/constants';

interface Props extends State.PracticalExperienceRecord {
  onEdit?: () => void;
  onView?: () => void;
  onAdd?: () => void;
  onWithdraw?: () => void;
  onRemove?: () => void;
  isCimaAssessor?: boolean;
  isReviewer?: boolean;
  isApprentice?: boolean;
  isUploadDisable?: boolean;
  isStudent?: boolean;
  portfolioStatus?: string | null;
  numberOfDaysPerWeek?: string | null;
  employmentType?: string;
}

export const PracticalExperienceRecord: React.FC<Props> = memo(
  ({
    label,
    status,
    jobTitle,
    startDate,
    endDate,
    numberOfDaysPerWeek,
    employmentType,
    onEdit,
    onView,
    onAdd,
    onWithdraw,
    onRemove,
    isCimaAssessor,
    isReviewer,
    isApprentice,
    isUploadDisable,
    isStudent,
    portfolioStatus,
  }) => {
    const Status = MembershipTypes.PracticalExperienceRequirementStatus;
    // added isStudent flag for student view
    // Uses the same logic to display PER banner
    const StatusForSupervisor = !isStudent
      ? [Status.APPROVED, Status.SIGNED_OFF, Status.ACTION_REQUIRED]
      : [Status.SUBMITTED, Status.APPROVED, Status.SIGNED_OFF];
    const StatusForAssessor = [Status.APPROVED, Status.ACTION_REQUIRED];
    const perPortfolioIsCompleted: boolean = portfolioStatus === MembershipTypes.PERPortfolioStatus.COMPLETED;
    const onViewFlag = (isCimaAssessor ? StatusForAssessor : StatusForSupervisor).includes(
      status as MembershipTypes.PracticalExperienceRequirementStatus
    );

    const onViewFlagStatus = onViewFlag ? onViewFlag : perPortfolioIsCompleted;
    const { pageName, siteSection } = getPageNameAndSiteSection();
    const useRemovePERModuleToggle: boolean = useSelector(state =>
      getFeatureToggleByKeySelector(state as State.Root, USE_REMOVE_PER_MODULE)
    );

    const getLabelRoute = (labelName: string | undefined) => {
      switch (labelName) {
        case 'Add employment information':
          return '/employment';
        case 'Add core work activity':
          return '/core-activities';
        case 'Add skill & behaviour':
          return '/skills-behaviors';
        default:
          return '/employment';
      }
    };

    return (
      <StyledItem>
        <StyledGrid>
          <StyledGridRow>
            <StyledGridColumn width={12}>
              <StyledSpan
                onClick={e => {
                  const buttonText = (e.target as HTMLAnchorElement)?.textContent?.trim();
                  const path = `${getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT)}${getLabelRoute(buttonText)}`;
                  handleEvent(
                    {
                      clickValue: `button:link:int::${buttonText}:${path}`,
                      pageName,
                      siteSection,
                    },
                    NAV_CLICK
                  );
                  if (onAdd) onAdd();
                }} // tslint:disable-line jsx-no-lambda
                isUploadDisable={isUploadDisable}
              >
                {status === Status.EMPTY && <StyledIcon src={IC_ADD} />}
                <StyledRecordLabel>{label}</StyledRecordLabel>
              </StyledSpan>
              {jobTitle && (
                <>
                  <StyledDivider>|</StyledDivider> {jobTitle}
                </>
              )}
              {!isUploadDisable ? (
                status !== Status.EMPTY ? (
                  <>
                    <StyledButtonLink
                      overrideAnalytics={true}
                      size={ButtonEnums.sizes.small}
                      variant={ButtonEnums.variants.white}
                      testId={`per-record-${label}-edit`}
                      onClick={onViewFlagStatus ? onView : onEdit}
                    >
                      {onViewFlagStatus ? (
                        <>
                          <StyledIcon src={IC_VIEW} /> View
                        </>
                      ) : (
                        <>
                          {isApprentice ? (
                            (status === Status.DRAFT ||
                              status === Status.PENDING_ASSESSMENT ||
                              status === Status.RFI) && (
                              <>
                                <StyledIcon src={IC_EDIT} /> Edit
                              </>
                            )
                          ) : status === Status.ACTION_REQUIRED ? (
                            <>
                              <StyledIcon src={IC_EDIT} /> Edit and resubmit
                            </>
                          ) : (
                            <>
                              <StyledIcon src={IC_EDIT} /> {isReviewer ? 'Review' : 'Edit'}
                            </>
                          )}
                        </>
                      )}
                    </StyledButtonLink>

                    {useRemovePERModuleToggle && status === Status.DRAFTED && (
                      <StyledButtonLink
                        size={ButtonEnums.sizes.small}
                        variant={ButtonEnums.variants.white}
                        testId={`per-record-${label}-remove`}
                        onClick={onRemove}
                      >
                        <StyledIcon src={IC_CANCEL} /> Remove
                      </StyledButtonLink>
                    )}

                    {status === Status.SUBMITTED && !isReviewer && (
                      <StyledButtonLink
                        size={ButtonEnums.sizes.small}
                        variant={ButtonEnums.variants.white}
                        testId={`per-record-${label}-withdraw`}
                        onClick={onWithdraw}
                      >
                        <StyledIcon src={IC_CANCEL} /> Withdraw submission
                      </StyledButtonLink>
                    )}
                  </>
                ) : null
              ) : null}
            </StyledGridColumn>
            <StyledGridColumn width={4}>
              {typeof isCimaAssessor === 'undefined' && (status === Status.DRAFTED || status === Status.DRAFT) && (
                <StyledLabel name="blue" horizontal>
                  Draft
                </StyledLabel>
              )}
              {typeof isCimaAssessor === 'undefined' &&
                (status === Status.PENDING_VALIDATION || status === Status.RFI) && (
                  <StyledLabel name="orange" horizontal>
                    Pending Validation
                  </StyledLabel>
                )}
              {typeof isCimaAssessor === 'undefined' && status === Status.SCREENING && (
                <StyledLabel name="orange" horizontal>
                  Screening
                </StyledLabel>
              )}
              {typeof isCimaAssessor === 'undefined' && status === Status.IN_ASSESSMENT && (
                <StyledLabel name="orange" horizontal>
                  In Assessment
                </StyledLabel>
              )}
              {typeof isCimaAssessor === 'undefined' && status === Status.IN_ARBITRATION && (
                <StyledLabel name="orange" horizontal>
                  In Assessment
                </StyledLabel>
              )}
              {typeof isCimaAssessor === 'undefined' && status === Status.PENDING_ASSESSMENT && (
                <StyledLabel name="orange" horizontal>
                  Pending Assessment
                </StyledLabel>
              )}
              {typeof isCimaAssessor !== 'undefined' && isCimaAssessor && status === Status.NOT_REQUESTED && (
                <StyledLabel name="blue" horizontal>
                  To be reviewed
                </StyledLabel>
              )}
              {typeof isCimaAssessor !== 'undefined' && isCimaAssessor && status === Status.SIGNED_OFF && (
                <StyledLabel name="blue" horizontal>
                  To be reviewed
                </StyledLabel>
              )}
              {!isCimaAssessor && !isReviewer && status === Status.SUBMITTED && (
                <StyledLabel name="blue" horizontal>
                  Submitted for Sign Off
                </StyledLabel>
              )}
              {!isCimaAssessor && isReviewer && status === Status.SUBMITTED && (
                <StyledLabel name="blue" horizontal>
                  To be reviewed
                </StyledLabel>
              )}
              {!isCimaAssessor && status === Status.SIGNED_OFF && (
                <StyledLabel name="yellow" horizontal>
                  Signed Off
                </StyledLabel>
              )}
              {!isCimaAssessor && (typeof isApprentice === 'undefined' || !isApprentice) && status === Status.DRAFT && (
                <StyledLabel name="yellow" horizontal>
                  In Progress
                </StyledLabel>
              )}
              {status === Status.ACTION_REQUIRED && (
                <StyledLabel name="orange" horizontal>
                  Action required
                </StyledLabel>
              )}
              {!isCimaAssessor && status === Status.APPROVED && (
                <StyledLabel name="green" horizontal>
                  Approved
                </StyledLabel>
              )}
              {isCimaAssessor && status === Status.APPROVED && (
                <StyledLabel name="green" horizontal>
                  Approved
                </StyledLabel>
              )}
              {isCimaAssessor && status === Status.DRAFT && (
                <StyledLabel name="yellow" horizontal>
                  In Progress
                </StyledLabel>
              )}
            </StyledGridColumn>
          </StyledGridRow>
          {startDate && endDate && (
            <StyledCustomRow>
              <StyledDescription>
                {`${moment(startDate).format('MMM YYYY')} - ${moment(endDate).format('MMM YYYY')}`}
                {
                  <>
                    <StyledDivider>|</StyledDivider>
                    {employmentType === MembershipTypes.PracticalExperienceEmploymentType.FULL_TIME ||
                    !numberOfDaysPerWeek ? (
                      datesToYearsMonths([{ startDate: startDate.toString(), endDate: endDate.toString() }])
                    ) : (
                      <>
                        {partTimeDatesToYearsMonths(
                          [{ startDate: startDate.toString(), endDate: endDate.toString() }],
                          numberOfDaysPerWeek
                        )}
                      </>
                    )}
                  </>
                }
              </StyledDescription>
            </StyledCustomRow>
          )}
        </StyledGrid>
      </StyledItem>
    );
  }
);

const StyledDivider = styled.span`
  margin-right: ${props => props.theme.pxToRem(12)};
  margin-left: ${props => props.theme.pxToRem(12)};
`;

const StyledRecordLabel = styled.span`
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledLabel = styled(Label)<ThemedStyledProps<{ name?: string }, DefaultTheme>>`
  &&&& {
    text-transform: uppercase;
    color: ${props => props.theme.colors.neutralWhite};
    font-size: ${props => props.theme.fontSizes.xs};
    background: ${props => {
      switch (props.name) {
        case 'blue':
          return props.theme.colors.secondaryDarkBlue;
        case 'grey':
          return props.theme.colors.neutralGrey6;
        case 'yellow':
          return props.theme.colors.secondaryYellow;
        case 'orange':
          return props.theme.colors.secondaryOrange;
        case 'green':
          return props.theme.colors.secondaryTeal;
        default:
          return props.theme.colors.neutralGrey5;
      }
    }} !important;
  }
`;

const StyledIcon = styled.img`
  margin-right: ${props => `${props.theme.pxToRem(5)}`};
`;

const StyledGrid = styled(Grid)`
  &&&&&& {
    margin: 0;
    width: 100%;
  }
`;

const StyledCustomRow = styled(Grid.Row)`
  &&&& {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 0;
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&& {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledGridColumn = styled(Grid.Row)`
  &&&& {
    display: flex;
    align-items: center;
  }
`;

const StyledSpan = styled.span<ThemedStyledProps<{ isUploadDisable?: boolean }, DefaultTheme>>`
  && {
    &:hover {
      ${props => `
        cursor: ${props.isUploadDisable ? 'default' : 'pointer'};
      `}
    }
    display: flex;
    align-items: center;
  }
`;

const StyledDescription = styled.span`
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralGrey7};
`;

const StyledButtonLink = styled(Button)`
  &&&&&&&& {
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(16)}`};
    margin: 0 ${props => `${props.theme.pxToRem(5)}`};
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      color: ${props => props.theme.colors.neutralGrey8} !important;
    }
  }
`;

const StyledItem = styled.div`
  padding: ${props => `${props.theme.pxToRem(20)}`};
  box-shadow: ${props => `0 0 ${props.theme.pxToRem(10)} 0 ${props.theme.colors.neutralGrey3}`};
  width: 100%;
  margin-bottom: ${props => `${props.theme.pxToRem(25)}`};
  border-radius: ${props => props.theme.pxToRem(8)};
  display: flex;
  align-items: center;
`;
