import { createSelector } from 'reselect';
import { selectedIndustryIdSelector } from '../layouts/selectors';
import { slugIdMapCreator } from './helpers';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.industries,
  industriesRoot => industriesRoot
);

export const industriesFetchedSelector = createSelector(
  rootSelector,
  (industries: State.Industries): boolean => industries.industriesFetched
);

export const industriesHashSelector = createSelector(
  rootSelector,
  (industriesRoot: State.Industries): State.IndustriesHash | null => industriesRoot.industriesHash
);

export const industriesSelector = createSelector(
  industriesHashSelector,
  (industriesHash: State.IndustriesHash | null): State.Industry[] | null =>
    industriesHash && Object.values(industriesHash)
);

export const industrySlugIdMapSelector = createSelector(industriesSelector, slugIdMapCreator);

export const industriesSlugsStrSelector = createSelector(
  industrySlugIdMapSelector,
  (industriesSlugIdMap: { [key: string]: string }): string => Object.keys(industriesSlugIdMap || emptyObject).join('|')
);

export const industriesSortedSelector = createSelector(
  industriesSelector,
  (industries: State.Industry[] | null): State.Industry[] | null =>
    industries && [...industries].sort((a, b) => (a.sort && b.sort && a.sort - b.sort) || -1)
);

export const industryNameByIndustrySlugSelectorFactory = (industrySlug: string) =>
  createSelector(
    [industriesHashSelector, industrySlugIdMapSelector],
    (industriesHash: State.IndustriesHash | null, industriesSlugIdMap: { [key: string]: string }): string => {
      if (!industrySlug || !industriesHash || !industriesSlugIdMap) return '';
      const industryId: string = industriesSlugIdMap[industrySlug];
      if (!industryId) return '';
      const industry: State.Industry = industriesHash[industryId];
      return industry ? industry.name : '';
    }
  );

export const selectedIndustryNameSelector = createSelector(
  [industriesHashSelector, selectedIndustryIdSelector],
  (industriesHash: State.IndustriesHash | null, selectedIndustryId: string | null): string | null => {
    if (!industriesHash || !selectedIndustryId || !industriesHash[selectedIndustryId]) return null;
    return industriesHash[selectedIndustryId].name;
  }
);
