import { createAction, handleActions } from 'redux-actions';
import { default as request } from 'utils/GraphQLClient';
import { GET_STARTUP_ITEMS } from 'mxp-graphql-queries';
import { Dispatch } from 'redux';
import { getCategoriesHash } from 'modules/categories';
import { getTopicsHash } from 'modules/topics/actions';
import { getIndustriesHash } from 'modules/industries/actions';
import { getTrendsHash } from 'modules/trends/actions';
import { getSkillsHash } from 'modules/skills/actions';
import { getLocationsHash } from 'modules/locations/actions';
import { getJobRolesHash } from 'modules/jobRoles/actions';
import { getCareerStagesHash } from 'modules/careerStages/actions';
import { getContentLanguagesHash } from 'modules/contentLanguages/actions';
import { getGroupHash } from 'modules/groups';
import { loadConstants } from 'modules/app/actions';

import { getRenewalSeason } from 'modules/membership/actions';
import { loadFeatureToggles } from 'modules/featureToggle/actions';
import { getMegaMenu } from 'modules/header/actions';
import { getPopularSites } from 'modules/trendingAcross';
import { getProductTypesHash } from 'modules/productTypes';

// ------------------------------------
// Actions
// ------------------------------------
// Will get startup items then relay the results to its respective actions
export const getStartupItems: any = createAction(
  'startup/GET_STARTUP_ITEMS',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const startupItems = await request(GET_STARTUP_ITEMS);
    if (Object.keys(startupItems.categories).length !== 0) await dispatch(getCategoriesHash(startupItems.categories));
    if (Object.keys(startupItems.topics).length !== 0) await dispatch(getTopicsHash(startupItems.topics));
    if (Object.keys(startupItems.industries).length !== 0) await dispatch(getIndustriesHash(startupItems.industries));
    if (Object.keys(startupItems.trends).length !== 0) await dispatch(getTrendsHash(startupItems.trends));
    if (Object.keys(startupItems.skills).length !== 0) await dispatch(getSkillsHash(startupItems.skills));
    if (Object.keys(startupItems.locations).length !== 0) await dispatch(getLocationsHash(startupItems.locations));
    if (Object.keys(startupItems.jobRoles).length !== 0) await dispatch(getJobRolesHash(startupItems.jobRoles));
    if (Object.keys(startupItems.careerStages).length !== 0) {
      await dispatch(getCareerStagesHash(startupItems.careerStages));
    }
    if (Object.keys(startupItems.contentLanguages).length !== 0) {
      await dispatch(getContentLanguagesHash(startupItems.contentLanguages));
    }
    if (Object.keys(startupItems.groups).length !== 0) await dispatch(getGroupHash(startupItems.groups));
    if (Object.keys(startupItems.firmMemberships).length !== 0) {
      await dispatch(setFirmMembershipsHash(startupItems.firmMemberships));
    }
    if (Object.keys(startupItems.sections).length !== 0) {
      await dispatch(setSectionsHash(startupItems.sections));
    }
    if (Object.keys(startupItems.accessRestrictions).length !== 0) {
      await dispatch(setAccessRestrictionsHash(startupItems.accessRestrictions));
    }
    if (Object.keys(startupItems.credentials).length !== 0) {
      await dispatch(setCredentialsHash(startupItems.credentials));
    }
    if (Object.keys(startupItems.topicalSubscriptions).length !== 0) {
      await dispatch(setTopicalSubscriptionsHash(startupItems.topicalSubscriptions));
    }
    if (Object.keys(startupItems.engagementMenuList).length !== 0) {
      await dispatch(setUniquestAllowedUrl(startupItems.engagementMenuList));
    }
    if (startupItems.constants?.length !== 0) await dispatch(loadConstants(startupItems.constants));

    // megamenu
    await Promise.all([
      dispatch(getMegaMenu(startupItems)),
      dispatch(getPopularSites(startupItems)),
      dispatch(loadFeatureToggles(startupItems)),
      dispatch(getProductTypesHash(startupItems)),
      dispatch(getRenewalSeason(startupItems)),
    ]);

    return startupItems;
  }
);

export const setSiteEntry: any = createAction('startup/SET_SITE_ENTRY');
const setFirmMembershipsHash: any = createAction('startup/SET_FIRM_MEMBERSHIPS_HASH');
const setSectionsHash: any = createAction('startup/SET_SECTIONS_HASH');
const setAccessRestrictionsHash: any = createAction('startup/SET_ACCESS_RESTRICTIONS_HASH');
const setCredentialsHash: any = createAction('startup/SET_CREDENTIALS_HASH');
const setTopicalSubscriptionsHash: any = createAction('startup/SET_TOPICAL_SUBSCRIPTIONS_HASH');
const setUniquestAllowedUrl: any = createAction('startup/SET_UNIQUEST_ALLOWED_URL');

const ACTION_HANDLERS = {
  [setSiteEntry]: (state: State.Startup, action: any): State.Startup => ({
    ...state,
    siteEntry: action.payload,
  }),
  [setFirmMembershipsHash]: (state: State.Startup, action: any): State.Startup => ({
    ...state,
    firmMembershipsHash: action.payload,
  }),
  [setSectionsHash]: (state: State.Startup, action: any): State.Startup => ({
    ...state,
    sectionsHash: action.payload,
  }),
  [setAccessRestrictionsHash]: (state: State.Startup, action: any): State.Startup => ({
    ...state,
    accessRestrictionsHash: action.payload,
  }),
  [setCredentialsHash]: (state: State.Startup, action: any): State.Startup => ({
    ...state,
    credentialsHash: action.payload,
  }),
  [setTopicalSubscriptionsHash]: (state: State.Startup, action: any): State.Startup => ({
    ...state,
    topicalSubscriptionsHash: action.payload,
  }),
  [setUniquestAllowedUrl]: (state: State.Startup, action: any): State.Startup => ({
    ...state,
    uniquestAllowedUrl: action.payload,
  }),
};

export const startup = handleActions(ACTION_HANDLERS, {
  siteEntry: {
    page: '',
    params: {},
  },
  firmMembershipsHash: {},
  sectionsHash: {},
  accessRestrictionsHash: {},
  credentialsHash: {},
  topicalSubscriptionsHash: {},
  uniquestAllowedUrl: null,
});
