import aicpaLogo from './aicpa-logo.svg';
import aicpaLogoPride from './aicpa-cima-logo-pride.svg';
import arrowForward from './arrow-forward.svg';
import checkmark from './checkmark.svg';
import checkmarkGrey from './checkmark-grey.svg';
import cpeLearning from './cpeLearning.svg';
import home from './icon-dev-ic-home.svg';
import icBookmarkBorder from './ic-bookmark-border.svg';
import icHelp from './icon-dev-ic-help.svg';
import icMenu from './ic-menu.svg';
import icOpenInNew from './ic-open-in-new.svg';
import icOpenInNewPurple from './ic-open-in-new-purple.svg';
import icOpenInNewWhite from './ic-open-in-new-white.svg';
import icSchool from './ic-school.svg';
import icSchoolDropdown from './ic-school-dropdown.svg';
import icSearch from './ic-search.svg';
import icShoppingCart from './icon-dev-ic-shopping-cart.svg';
import iconArrowUp from './icon-arrow-up.svg';
import iconArrowDown from './icon-arrow-down.svg';
import iconArrowRight from './icon-arrow-right.svg';
import myProfile from './my-profile.svg';
import myPurchases from './my-purchases.svg';
import mySavedItems from './my-saved-items.svg';
import mySavedIconCircle from './my-saved-icon-circle.svg';
import news from './news.svg';
import resources from './resources.svg';
import filterDefs from './filter-defs.svg';
import icArrowBack from './ic-arrow-back.svg';
import icClear from './icon-dev-ic-clear.svg';
import profileWhite from './profile-white.svg';
import icPrint from './ic-print.svg';
import icNavigationOrgProfile from './navigation-icons-profile-organization-profile.svg';
import icSave from './icon-save.svg';
import icCancel from './icon-live-ic-cancel.svg';
import icAdd from './icon-live-ic-add.svg';
import icEdit from './icon-live-ic-edit.svg';
import icView from './icon-live-ic-eye.svg';
import icCheck from './ic-check-grey.svg';
import icCheckGreen from './ic-check-teal.svg';
import icLock from './icon-live-ic-lock-on.svg';
import icCircle from './circle-outline-svgrepo-com.svg';
import badgeCompleted from './icon-brand-icon-award.png';
import badgeProccessing from './icon-brand-icon-award.svg';
import badgeNotEligible from './not_eligible.svg';
import icCheckAudit from './ic-check-audit.svg';
import icCrossAudit from './ic-cross-audit.svg';
import icPublic from './icon-ui-icons-ic-public.svg';
import icStudyHub from './ic-study-hub.svg';
import icCancelGray from './icon-live-ic-cancel-gray.svg';
import icAccordionDown from './accordion-down.svg';
import icAccordionLeft from './accordion-left.svg';
import icAccordionUpViolet from './accordion-up-violet.svg';
import aicpaCimaLogo from './aicpa-cima-logo.svg';
import aicpaCimaLogoMobile from './aicpa-cima-logo-mobile.svg';
import icUsers from './users.svg';
import icUsersPurple from './users-purple.svg';
import icAward from './award.svg';
import icAwardPurple from './award-purple.svg';
import icLayers from './layers.svg';
import icLayersPurple from './layers-purple.svg';
import icPackage from './package.svg';
import icPackagePurple from './package-purple.svg';
import icEditPad from './edit.svg';
import icInfo from './akar-icons_info-fill.svg';
import renewalTier from './renewalTier.jpg';
import renewalMembershipType from './renewalMembershipType.jpg';
import affiliateMembershipType from './affiliateMembershipType.png';
import candidateMembershipType from './candidateMembershipType.png';
import intAssocMembershipType from './intAssocMembershipType.png';
import retiredMembeshipType from './retiredMembeshipType.png';
import studentAffMembershipType from './studentAffMembershipType.png';
import icTlwMembershipTier from './tlw.png';
import icretiredMembershipTier from './retired.png';
import icCpaExamAffiliateMembershipTier from './cpa-exam-candidate-affiliate.png';
import icInternationalAffiliaateMembershipTier from './international-associate.png';
import icSignUpMacbook from './sign-up-macbook.png';
import icTrash from './trash-dontations.svg';
import icRenewalMacbook from './renewal-macbook.png';
import icSearchMagnify from './magnifier.svg';

export const IC_CANCEL_GRAY = icCancelGray;
export const IC_ACCORDION_DOWN = icAccordionDown;
export const IC_ACCORDION_LEFT = icAccordionLeft;
export const IC_ACCORDION_UP = icAccordionUpViolet;
export const IC_CLEAR = icClear;
export const AICPA_LOGO = aicpaLogo;
export const AICPA_CIMA_LOGO = aicpaCimaLogo;
export const AICPA_CIMA_LOGO_MOBILE = aicpaCimaLogoMobile;
export const AICPA_LOGO_PRIDE = aicpaLogoPride;
export const ARROW_FORWARD = arrowForward;
export const CHECKMARK = checkmark;
export const CHECKMARK_GREY = checkmarkGrey;
export const CPE_LEARNING = cpeLearning;
export const HOME = home;
export const IC_ARROW_BACK = icArrowBack;
export const IC_BOOKMARK_BORDER = icBookmarkBorder;
export const IC_HELP = icHelp;
export const IC_MENU = icMenu;
export const IC_OPEN_IN_NEW = icOpenInNew;
export const IC_OPEN_IN_NEW_PURPLE = icOpenInNewPurple;
export const IC_OPEN_IN_NEW_WHITE = icOpenInNewWhite;
export const IC_SCHOOL = icSchool;
export const IC_SCHOOL_DROPDOWN = icSchoolDropdown;
export const IC_SEARCH = icSearch;
export const IC_SHOPPING_CART = icShoppingCart;
export const ICON_ARROW_UP = iconArrowUp;
export const ICON_ARROW_DOWN = iconArrowDown;
export const ICON_ARROW_RIGHT = iconArrowRight;
export const MY_PROFILE = myProfile;
export const MY_PURCHASES = myPurchases;
export const MY_SAVED_ITEMS = mySavedItems;
export const MY_SAVED_ICON_CIRCLE = mySavedIconCircle;
export const MY_ORG_PROFILE = icNavigationOrgProfile;
export const NEWS = news;
export const RESOURCES = resources;
export const PROFILE_WHITE = profileWhite;
export const IC_PRINT = icPrint;
export const IC_SAVE = icSave;
export const IC_CANCEL = icCancel;
export const IC_ADD = icAdd;
export const IC_EDIT = icEdit;
export const IC_VIEW = icView;
export const IC_CHECK = icCheck;
export const IC_CHECK_GREEN = icCheckGreen;
export const IC_LOCK = icLock;
export const IC_CIRCLE_CLEAR = icCircle;
export const CIMA_BADGE_COMPLETED = badgeCompleted;
export const CIMA_BADGE_NOT_ELIGIBLE = badgeNotEligible;
export const CIMA_BADGE_ONPROCESS = badgeProccessing;
export const IC_CHECK_AUDIT = icCheckAudit;
export const IC_CROSS_AUDIT = icCrossAudit;
export const IC_PUBLIC = icPublic;
export const IC_STUDY_HUB = icStudyHub;
export const IC_USERS = icUsers;
export const IC_USERS_PURPLE = icUsersPurple;
export const IC_AWARD = icAward;
export const IC_AWARD_PURPLE = icAwardPurple;
export const IC_LAYERS = icLayers;
export const IC_LAYERS_PURPLE = icLayersPurple;
export const IC_PACKAGE = icPackage;
export const IC_PACKAGE_PURPLE = icPackagePurple;
export const IC_EDIT_PAD = icEditPad;
export const IC_INFO = icInfo;
export const RENEWAL_TIER = renewalTier;
export const RENEWAL_MEMBERSHIP_TYPE = renewalMembershipType;
export const AFFILIATE_MEMBERSHIP_TYPE = affiliateMembershipType;
export const CANDIDATE_MEMBERSHIP_TYPE = candidateMembershipType;
export const INT_ASSOC_MEMBERSHIP_TYPE = intAssocMembershipType;
export const STUDENT_AFF_MEMBERSHIP_TYPE = studentAffMembershipType;
export const RETIRED_MEMBERSHIP_TYPE = retiredMembeshipType;
export const TWL_MEMBERSHIP_TIER = icTlwMembershipTier;
export const RETIRED_MEMBERSHIP_TIER = icretiredMembershipTier;
export const CPA_EXAM_AFFILIATE_TIER = icCpaExamAffiliateMembershipTier;
export const INTERNAIONAL_AFFILIATE_TIER = icInternationalAffiliaateMembershipTier;
export const SIGN_UP_MACBOOK = icSignUpMacbook;
export const IC_TRASH = icTrash;
export const RENEWAL_MACBOOK = icRenewalMacbook;
export const IC_SEARCH_MAGNIFY = icSearchMagnify;

// to be used together
export const FILTER_DEFS = filterDefs;
export enum SVG_FILTERS {
  PRIMARY_PURPLE = '#primary-purple',
  NEUTRAL_WHITE = '#neutral-white',
}
