import React from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';
import { Grid } from 'semantic-ui-react';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';

/**
 * Success Modal
 * @category Refunds & Cancellations
 * RBUILD-8850
 */

export interface Props {
  paymentId: string;
  open: boolean;
  isRefundWithCancellation?: boolean;
  isRefundWithoutCancellation?: boolean;
  isRefundOnlyNoCancellation?: boolean;
  isCancelAndMove?: boolean;
  onClose: () => void;
}

export const SuccessModal: React.FC<Props> = ({
  paymentId,
  open,
  onClose,
  isRefundWithCancellation,
  isRefundWithoutCancellation,
  isRefundOnlyNoCancellation,
  isCancelAndMove,
}) => {
  const heading = (
    <StyledHeading>
      {`This product has been ${isRefundOnlyNoCancellation ? 'refunded' : 'cancelled'} successfully`}
    </StyledHeading>
  );
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      testId="success-modal"
      heading={heading}
      icon={<StyledCheckmark />}
      cancelNode={
        <Button
          size={ButtonEnums.sizes.small}
          testId="return-to-purchases"
          variant={ButtonEnums.variants.primary}
          onClick={onClose}
        >
          Return to purchases
        </Button>
      }
    >
      <Grid stackable centered columns={3}>
        {isCancelAndMove && (
          <>
            <StyledGridRow>Please make a note of the payment ID:</StyledGridRow>
            <StyledGridRow>
              <StyledBold>{paymentId}</StyledBold>
            </StyledGridRow>
          </>
        )}
        <StyledGridRow>A confirmation email has been sent.</StyledGridRow>
      </Grid>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &&&& {
    background: ${props => props.theme.colors.neutralGrey1};
    min-height: ${props => props.theme.pxToRem(264)};
    max-width: ${props => props.theme.pxToRem(590)};
    margin-top: ${props => props.theme.pxToRem(48)};
    > .header {
      h2 {
        min-height: ${props => props.theme.pxToRem(64)};
        max-width: ${props => props.theme.pxToRem(440)};
        padding: ${props => props.theme.pxToRem(10)};
        margin: auto;
        text-align: center;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }
  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding-bottom: ${props => props.theme.pxToRem(2)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-top: ${props => props.theme.pxToRem(10)};
    }
  }
`;

const StyledHeading = styled.p`
  line-height: 1.57;
  text-align: center;
  justify-content: center;
  font-size: ${props => props.theme.fontSizes.l};
`;

const StyledBold = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: 0 ${props => props.theme.pxToRem(3)} 0 ${props => props.theme.pxToRem(3)};
`;

const StyledCheckmark = styled(Checkmark)`
  &&& {
    path {
      fill: ${props => props.theme.colors.interfaceGreen};
    }
  }
`;
