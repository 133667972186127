import { createSelector } from 'reselect';
import {
  Cart,
  CommerceTypes,
  Product,
  Shipping,
  User as UserTypes,
  Orders,
  MembershipTypes as Membership,
  Checkout,
  Salesforce,
  Contentful,
} from 'mxp-schemas';
import {
  isPhysicalProduct,
  phProductsInArrCheck,
  isCartHasRestrictedMix,
  Product as ProductUtils,
  Cart as CartUtils,
  CountriesList,
  ConvertFromISOAlpha3ToText,
  Admin as AdminUtils,
} from 'mxp-utils';
import {
  taxAmountSelector,
  membershipProratedAmountSelector,
  membershipRefundSelector,
  proratedPriceAmountSelector,
  isPageStepValidForPromoCodeSelector,
} from 'modules/checkout/selectors';
import moment from 'moment-timezone';
import { getHasItemInTheCart, transformItemsPriceData, transformLineItemsToCartDetailItems } from './helpers';
import { emptyArray, formatPrice, fromCentsFormat, priceToFloat, areAllTruthy, hasTruthyValue } from 'utils';
import {
  selectedVariantSKUSelector,
  selectedBundleItemsSkusSelector,
  bundleProductsProductIdsSelector,
  hasAttestationVariantsSelector,
  productCurrencySelector,
  hasMipCredentialProductSelector,
  productListByTypesSelector,
} from 'modules/products/selectors';
import {
  applicationPartsSelector,
  userRolesSelector,
  userTierSelector,
  customerMembershipsSelector,
  customerInactiveMembershipsSelector,
  primaryAddressSelector,
  personAccountDataSelector,
  isCimaMemberSelector,
  isAicpaMemberSelector,
  userLocationSelector,
  learningPathwaySelector,
  learningPathwayToSwitchSelector,
  isUserMemberSuspendedSelector,
  fcmaSfCredentialSelector,
  isAuthSelector,
  inactiveMembershipsBodySelector,
  customerInactiveCredentialsSelector,
  customerInactiveSectionsSelector,
  userMembershipBodySelector,
} from 'modules/user/selectors';
import { getVariants, getVariantsBundleItemsWithOutOfStockCheck } from 'modules/products/helpers';
import {
  isRenewalSeasonSelector,
  currentMembershipSubscriptionKeySelector,
  isCenterMembershipRenewalSelector,
  activeCredentialsSubscriptionSelector,
  activeSectionsSubscriptionSelector,
  isCimaRenewalSeasonSelector,
  isFLPSwitchSelector,
  isFLPUpgradeSelector,
  clickedMembershipUpgradeAndFLPSwitchOrUpgradeSelector,
  isCimaCandidateApprenticeLapsedRenewalSelector,
  isUserRegularAicpaMemberSelector,
  isCimaMembershipJourneySelector,
  isL7CandidateUpgradeSelector,
  isCimaRegularLapsedSelector,
  isCimaAffiliateLapsedSelector,
  hasCimaRegularMembershipSelector,
  isUserResignedSelector,
  clickedMembershipBuyAgainSelector,
  isCimaMembershipLapsedSelector,
  isAicpaMemberLapsedSelector,
  isAicpaMemberFiveYearsLapsedSelector,
  isAicpaMemberMoreThanOneYearLapsedSelector,
  hasCimaRetiredMembershipSelector,
} from 'modules/membership/selectors';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_CR_682, USE_CR_723_REMOVE_PRORATION } from 'modules/featureToggle/constants';
import { constantsSelector, fcmaPeriodCheckDurationSelector, isAdminPortalSelector } from 'modules/app/selectors';
import { isCartPageSelector, isPageCheckoutSelector } from 'modules/router/selectors';
import { userHasBeenLicensedBeforeSelector } from 'modules/personLevelExemption';
import { CONSTANTS } from 'modules/app/constants';
import { LineItem } from 'mxp-schemas/dist/types/carts';

const DEFAULT_LOCAL = 'en-US';
const rootSelector = createSelector(
  (state: State.Root): State.Cart => state.cart,
  (cart: State.Cart): State.Cart => cart
);

export const cartSelector = createSelector(rootSelector, (cart: State.Cart): Cart.Cart => cart.value);

export const cartPONumberSelector = createSelector(rootSelector, (cart: State.Cart): string => cart?.poNumber ?? '');

export const isCartLoadingSelector = createSelector(rootSelector, (cart: State.Cart): boolean => cart.loading);

export const isCartFetchedSelector = createSelector(rootSelector, (cart: State.Cart): boolean => cart.isCartFetched);

export const isLoadingShippingOptionsSelector = createSelector(
  rootSelector,
  (cart: State.Cart): boolean => cart.isLoadingShippingOptions
);

export const numberOfItemsInCartSelector = createSelector(
  cartSelector,
  (cart?: Cart.Cart): number => cart?.lineItems?.length || 0
);

export const cartVersionSelector = createSelector(
  rootSelector,
  (cart: State.Cart): number | undefined => cart.value?.version
);

export const cartCurrencySelector = createSelector(
  cartSelector,
  (cart: Cart.Cart): string => cart?.totalPrice?.currencyCode
);

export const hasCartSelector = createSelector(rootSelector, (cart: State.Cart): boolean => Boolean(cart?.value));

export const hasPromoSelector = createSelector(
  [hasCartSelector, cartSelector],
  (hasCart: boolean, cart: Cart.Cart): boolean => Boolean(hasCart && cart.discountCodes?.length)
);

const appliedPromoCodeObjSelector = createSelector(
  [cartSelector],
  (cart: Cart.Cart): CommerceTypes.DiscountCode | undefined => cart?.discountCodes?.[0]?.discountCode?.obj
);

export const appliedPromoCodeSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): string | undefined => (discountObj ? discountObj.code : undefined)
);
export const appliedPromoCodeCartDiscountDescriptionSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): string | undefined =>
    discountObj ? discountObj.cartDiscounts?.[0].obj?.description?.[DEFAULT_LOCAL] : undefined
);

export const appliedPromoCodeValueSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): any => discountObj?.cartDiscounts?.[0].obj?.value
);

export const isAppliedPromoCodeTargetShippingSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): boolean =>
    discountObj?.cartDiscounts?.[0]?.obj?.target?.type === 'shipping'
);

export const appliedPromoCodeDescriptionSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): string | undefined => discountObj?.description?.[DEFAULT_LOCAL]
);

export const appliedPromoCodeIdSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): string | undefined => discountObj?.id
);

export const productIdWithPromoCodeSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): string => {
    const productId = discountObj?.cartDiscounts?.[0]?.obj?.references?.find(
      product => product.typeId === 'product'
    )?.id;
    return productId || '';
  }
);

export const appliedPromoCodeCustomFields = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): boolean => (discountObj?.custom?.fields ? true : false)
);

export const appliedPromoCodeCustomExemptionFields = createSelector(
  [appliedPromoCodeObjSelector],
  (
    discountObj?: CommerceTypes.DiscountCode
  ): { promocode: string | undefined; exemptionGiveaway: string[]; exemptionTakeaway: string[] } => {
    return {
      promocode: discountObj?.code,
      exemptionGiveaway: (discountObj?.custom?.fields?.exemption_giveaway as string[]) || [],
      exemptionTakeaway: (discountObj?.custom?.fields?.exemption_takeaway as string[]) || [],
    };
  }
);

export const hasPromocodeExemptionSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): boolean =>
    Boolean(discountObj?.code) &&
    (Boolean(discountObj?.custom?.fields?.exemption_giveaway) ||
      Boolean(discountObj?.custom?.fields?.exemption_takeaway))
);

export const itemsPriceDataCartSelector = createSelector(
  [
    cartSelector,
    isRenewalSeasonSelector,
    currentMembershipSubscriptionKeySelector,
    membershipProratedAmountSelector,
    proratedPriceAmountSelector,
    isCenterMembershipRenewalSelector,
    activeCredentialsSubscriptionSelector,
    activeSectionsSubscriptionSelector,
    isCimaRenewalSeasonSelector,
    userRolesSelector,
    membershipRefundSelector,
    clickedMembershipUpgradeAndFLPSwitchOrUpgradeSelector,
  ],
  (
    cart: Cart.Cart,
    isRenewalSeason: boolean,
    currentMembershipSubscriptionKey: string,
    membershipProratedAmount?: number,
    proratedPricesAmount?: Orders.ZuoraInvoiceItem[],
    isCenterMembershipRenewal?: boolean,
    activeCredentials?: Cart.LineItemWithAccessDates[],
    activeSections?: Cart.LineItemWithAccessDates[],
    isCimaRenewalSeason?: boolean,
    userRoles?: UserTypes.MembershipIdsEnum[],
    membershipRefund?: number,
    isClickedMembershipUpgradeAndisFLPSwitchOrUpgrade?: any
  ): Cart.CartItemPriceInfo[] =>
    cart
      ? transformItemsPriceData(cart, {
          isRenewalSeason,
          hasExistingMembership: Boolean(currentMembershipSubscriptionKey),
          membershipProratedAmount,
          proratedPricesAmount,
          isCenterMembershipRenewal,
          activeCredentials,
          activeSections,
          isCimaRenewalSeason,
          userRoles,
          membershipRefund,
          isClickedMembershipUpgradeAndisFLPSwitchOrUpgrade,
        })
      : emptyArray
);

export const hasItemWithThreeMonthRuleProrationSelector = createSelector(
  itemsPriceDataCartSelector,
  (itemsPriceDataCart: Cart.CartItemPriceInfo[]): boolean =>
    itemsPriceDataCart.some(item => (item?.proration?.monthsProrated || 0) > 12)
);

export const cartShippingInfoSelector = createSelector(
  cartSelector,
  (cart: Cart.Cart): Shipping.ShippingPrice => ({
    id: cart?.shippingInfo?.shippingMethod?.id || null,
    name: cart?.shippingInfo?.shippingMethodName || '',
    centAmount: cart?.shippingInfo?.price.centAmount || 0,
    formattedShippingPriceAmount: formatPrice(fromCentsFormat(cart?.shippingInfo?.price.centAmount || 0)),
    isFreeShipping: cart?.shippingInfo?.discountedPrice?.value?.centAmount === 0,
  })
);

export const lineItemsSelector = createSelector(
  [cartSelector],
  (cart?: Cart.Cart): State.LineItem[] => cart?.lineItems || []
);

export const hasMembershipProductSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(lineItem => lineItem.productType === Product.ProductType.MEMBERSHIP) ||
    lineItems?.some(lineItem => lineItem.productType === Product.ProductType.FLP)
);

export const hasCenterMembershipProductSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(lineItem => lineItem.productType === Product.ProductType.CENTER_MEMBERSHIP)
);

export const hasSectionProductSelector = createSelector(lineItemsSelector, (lineItems: State.LineItem[]): boolean =>
  lineItems?.some(lineItem => lineItem.productType === Product.ProductType.SECTION)
);

export const hasCredentialProductSelector = createSelector(lineItemsSelector, (lineItems: State.LineItem[]): boolean =>
  lineItems?.some(lineItem => lineItem.productType === Product.ProductType.CREDENTIAL)
);

export const hasFcmaCredentialSelector = createSelector(
  lineItemsSelector,
  hasCredentialProductSelector,
  (lineItems: State.LineItem[], hasCredentialProduct: boolean): boolean =>
    lineItems?.some(lineItem => lineItem?.variant?.attributes?.credentialKey?.key === Product.CredentialKey.FCMA) &&
    hasCredentialProduct
);

export const hasMipCredentialSelector = createSelector(
  lineItemsSelector,
  hasCredentialProductSelector,
  (lineItems: State.LineItem[], hasCredentialProduct: boolean): boolean =>
    lineItems?.some(lineItem => lineItem?.variant?.attributes?.credentialKey?.key === Product.CredentialKey.MIP) &&
    hasCredentialProduct
);

export const hasCentersProductSelector = createSelector(lineItemsSelector, (lineItems: State.LineItem[]): boolean =>
  lineItems?.some(lineItem => lineItem.productType === Product.ProductType.CENTER_MEMBERSHIP)
);

export const isCPALicensedFLPRouteSelector = createSelector(
  [userHasBeenLicensedBeforeSelector, isUserRegularAicpaMemberSelector, isCimaMembershipJourneySelector],
  (userHasBeenLicensedBefore, isUserRegularAicpaMember, isCimaMembershipJourney) =>
    userHasBeenLicensedBefore && isCimaMembershipJourney && !isUserRegularAicpaMember
);

export const getMembershipApplicationTypeSelector = createSelector(
  [lineItemsSelector, isCPALicensedFLPRouteSelector],
  (lineItems: State.LineItem[], isCPALicensedFLPRoute: boolean): Product.MembershipApplicationType => {
    return isCPALicensedFLPRoute
      ? Product.MembershipApplicationType.CIMA
      : ProductUtils.checkProductMembershipApplicationType(lineItems);
  }
);

export const selectedCenterMembershipSelector = createSelector(
  [lineItemsSelector],
  (lineItems: State.LineItem[]): State.LineItem | undefined =>
    lineItems?.find(lineItem => lineItem.productType === Product.ProductType.CENTER_MEMBERSHIP)
);

export const selectedMembershipSelector = createSelector(
  [lineItemsSelector],
  (lineItems: State.LineItem[]): State.LineItem | undefined =>
    lineItems?.find(
      lineItem =>
        lineItem.productType === Product.ProductType.MEMBERSHIP || lineItem.productType === Product.ProductType.FLP
    )
);

export const hasCimaRegularProductInCartSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(
      item =>
        item.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.CIMA &&
        item.variant?.attributes?.membershipKey?.key === Membership.MembershipKeys.REGULAR
    )
);

export const hasCimaRetiredProductInCartSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(
      item =>
        item.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.CIMA &&
        item.variant?.attributes?.membershipKey?.key === Membership.MembershipKeys.RETIRED
    )
);

export const isAllowToRemoveFcmaOnlyInCartSelector = createSelector(
  [
    hasCimaRegularMembershipSelector,
    fcmaSfCredentialSelector,
    hasFcmaCredentialSelector,
    hasCimaRegularProductInCartSelector,
    hasCimaRetiredProductInCartSelector,
    isCartPageSelector,
    isAdminPortalSelector,
    isAuthSelector,
  ],
  (
    hasCimaRegularMembership,
    fcmaSfCredential,
    hasFcmaCredentialInCart,
    hasCimaRegularProductInCart,
    hasCimaRetiredProductInCart,
    isCartPage,
    isAdminPortal,
    isAuth
  ) => {
    const isImpersonation = areAllTruthy(Boolean(isAuth), isAdminPortal);
    const isAllowToRemoveFcmaOnlyInCart = areAllTruthy(
      Boolean(Object.keys(fcmaSfCredential || {}).length), // has existing credential in SF
      hasCimaRegularMembership, // has existing CIMA Regular membership
      hasFcmaCredentialInCart, // has CIMA FCMA in Cart
      hasTruthyValue(hasCimaRegularProductInCart, hasCimaRetiredProductInCart), // has either CIMA Regular/Retired in Cart, added during Renewal/Rejoin journey
      isCartPage, // Page Cart only (account/cart)
      isImpersonation // GEC only
    );
    return isAllowToRemoveFcmaOnlyInCart;
  }
);

export const isCartForFcmaRenewalSelector = createSelector(
  [
    hasCimaRegularMembershipSelector,
    fcmaSfCredentialSelector,
    hasFcmaCredentialSelector,
    hasCimaRegularProductInCartSelector,
    hasCimaRetiredProductInCartSelector,
    isPageCheckoutSelector,
  ],
  (
    hasCimaRegularMembership,
    fcmaSfCredential,
    hasFcmaCredentialInCart,
    hasCimaRegularProductInCart,
    hasCimaRetiredProductInCart,
    isPageCheckout
  ) => {
    const isCartForFcmaRenewal = areAllTruthy(
      Boolean(Object.keys(fcmaSfCredential || {}).length), // has existing credential in SF
      hasCimaRegularMembership, // has existing CIMA Regular membership
      hasFcmaCredentialInCart, // has CIMA FCMA in Cart
      hasTruthyValue(hasCimaRegularProductInCart, hasCimaRetiredProductInCart), // has either CIMA Regular/Retired in Cart, added during Renewal/Rejoin journey
      isPageCheckout // Checkout Page Only
    );
    return isCartForFcmaRenewal;
  }
);

export const isActiveFcmaRenewalSelector = createSelector(
  [
    (state: State.Root) => getFeatureToggleByKeySelector(state, USE_CR_682),
    hasCimaRegularMembershipSelector,
    hasCimaRetiredMembershipSelector,
    fcmaSfCredentialSelector,
    fcmaPeriodCheckDurationSelector,
    hasFcmaCredentialSelector,
    hasCimaRegularProductInCartSelector,
    hasCimaRetiredProductInCartSelector,
    isPageCheckoutSelector,
  ],
  (
    useCR682FcmaRenewalEnhancement,
    hasCimaRegularMembership,
    hasCimaRetiredrMembership,
    fcmaSfCredential,
    fcmaPeriodCheckDuration,
    hasFcmaCredentialInCart,
    hasCimaRegularProductInCart,
    hasCimaRetiredProductInCart,
    isPageCheckout
  ) => {
    if (!Boolean(Object.keys(fcmaSfCredential || {}).length)) return false; // If no existing FCMA credential in SF, return false immediately here.
    const isFcmaCredentialActive = fcmaSfCredential?.status === 'Active';
    const fcmaCredentialExpiryDate = moment(fcmaSfCredential?.credentialTerms[0]?.expiryDate);
    const currentDate = moment().format('YYYY-MM-DD');
    const expiryAndCurrentDateDifference = Math.floor(fcmaCredentialExpiryDate.diff(currentDate, 'days', true));
    const isFcmaExpiryDateWithin90Days = expiryAndCurrentDateDifference <= parseInt(fcmaPeriodCheckDuration, 10);

    const isActiveFcmaRenewal = areAllTruthy(
      useCR682FcmaRenewalEnhancement,
      !!fcmaSfCredential, // has existing credential in SF
      isFcmaCredentialActive, // Active Credential
      isFcmaExpiryDateWithin90Days,
      hasTruthyValue(hasCimaRegularMembership, hasCimaRetiredrMembership), // has existing CIMA Regular/Retired membership
      hasFcmaCredentialInCart, // has CIMA FCMA in Cart
      hasTruthyValue(hasCimaRegularProductInCart, hasCimaRetiredProductInCart), // has either CIMA Regular/Retired in Cart, added during Renewal/Rejoin journey
      isPageCheckout // Checkout Page Only
    );
    return isActiveFcmaRenewal;
  }
);

export const priceTotalCartSelector = createSelector(
  [
    itemsPriceDataCartSelector,
    cartSelector,
    taxAmountSelector,
    appliedPromoCodeSelector,
    membershipRefundSelector,
    proratedPriceAmountSelector,
    membershipProratedAmountSelector,
    selectedMembershipSelector,
    isFLPSwitchSelector,
    isFLPUpgradeSelector,
    isCartForFcmaRenewalSelector,
  ],
  (
    itemsPrice: Cart.CartItemPriceInfo[],
    cart: Cart.Cart,
    taxAmount: number | null,
    promoCode: string | undefined,
    membershipRefund: number,
    proratedPriceAmount: Orders.ZuoraInvoiceItem[],
    membershipProratedAmount?: number,
    selectedMembership?: Cart.LineItem,
    isFLPSwitch?: boolean,
    isFLPUpgrade?: boolean,
    isCartForFcmaRenewal?: boolean
  ): Cart.CartTotalPriceInfo => {
    const currency = cart?.totalPrice?.currencyCode;
    const isFlpSwitchOrUpgrade = Boolean(isFLPSwitch || isFLPUpgrade);
    const shippingDiscounts: CommerceTypes.DiscountedLineItemPortion[] =
      cart?.shippingInfo?.discountedPrice?.includedDiscounts || emptyArray;

    const totalDiscountAmount = (() => {
      const itemsDiscountAmount = itemsPrice.length
        ? itemsPrice.reduce((acc, c) => acc + (c?.totalDiscount?.centAmount || 0), 0)
        : 0;

      const shippingDiscountAmount = itemsPrice.length
        ? shippingDiscounts.reduce((acc, c) => acc + (c?.discountedAmount?.centAmount || 0), 0)
        : 0;
      return itemsDiscountAmount - shippingDiscountAmount;
    })();

    const isMembershipCart = cart ? CartUtils.isMembershipCart(cart) : false;
    const isCredentialSectionCart = cart ? CartUtils.isCredentialSectionCart(cart) : false;

    // this already includes also shipping
    let centAmount: number = cart?.totalPrice?.centAmount || 0;
    let membershipProratedFinalAmount = 0;
    let proratedFinalAmount = 0;

    if (proratedPriceAmount?.length > 0 && !isFlpSwitchOrUpgrade) {
      proratedFinalAmount =
        proratedPriceAmount?.reduce((prevValue, currValue) => prevValue + currValue.amountWithoutTax, 0) || 0;
    }
    if (
      membershipProratedAmount &&
      membershipProratedAmount !== fromCentsFormat(centAmount) &&
      !isFlpSwitchOrUpgrade &&
      !isCartForFcmaRenewal
    ) {
      centAmount = centAmount - (selectedMembership?.totalPrice.centAmount || 0);
      membershipProratedFinalAmount = membershipProratedAmount;
    }
    if (hasTruthyValue(isFlpSwitchOrUpgrade, areAllTruthy(isCartForFcmaRenewal, proratedFinalAmount === 0))) {
      proratedFinalAmount = fromCentsFormat(centAmount);
    }

    const centTaxAmount: number = taxAmount ? Math.round(taxAmount * 100) : 0;
    const discounts: Cart.DiscountDetails[] = Object.values(
      itemsPrice.reduce((acc: any, itemPrice) => {
        const discountsMap: {
          [key: string]: Cart.DiscountDetails;
        } = acc;
        itemPrice.discounts.forEach(discount => {
          const label =
            discount.discountType === Cart.DISCOUNT_TYPE.MEMBERSHIP_DISCOUNT ? 'membership' : discount.label;
          if (acc[label]) {
            discountsMap[label].discountAmount = {
              ...discountsMap[label].discountAmount,
              centAmount: discountsMap[label].discountAmount.centAmount + discount.discountAmount.centAmount,
            };
            discountsMap[label].formattedDiscountAmount = formatPrice(
              fromCentsFormat(discountsMap[label].discountAmount.centAmount),
              discountsMap[label].discountAmount.currencyCode
            );
          } else {
            discountsMap[label] = {
              ...discount,
            };
          }
        });
        return discountsMap;
      }, {})
    );

    shippingDiscounts.forEach(discount => {
      discounts.push({
        label: promoCode || '',
        formattedDiscountAmount: formatPrice(fromCentsFormat(-discount.discountedAmount.centAmount)),
        discountType: Cart.DISCOUNT_TYPE.CART_PROMO_DISCOUNT,
        discountAmount: discount.discountedAmount,
      });
    });
    const selectedShippingPrice = cart?.shippingInfo?.price?.centAmount || 0;
    return {
      hasDiscounts: totalDiscountAmount !== 0,
      formattedTotalPrice:
        (isMembershipCart || isCredentialSectionCart) &&
        (proratedPriceAmount?.length > 0 ||
          (hasTruthyValue(isFlpSwitchOrUpgrade, isCartForFcmaRenewal) && proratedFinalAmount > 0))
          ? formatPrice(proratedFinalAmount + fromCentsFormat(centTaxAmount), currency || '')
          : formatPrice(
              fromCentsFormat(centAmount + centTaxAmount) - membershipRefund + (membershipProratedFinalAmount || 0),
              currency || ''
            ),
      formattedTotalPriceWithoutTax:
        (isMembershipCart || isCredentialSectionCart) &&
        (proratedPriceAmount?.length > 0 ||
          (hasTruthyValue(isFlpSwitchOrUpgrade, isCartForFcmaRenewal) && proratedFinalAmount > 0))
          ? formatPrice(proratedFinalAmount, currency || '')
          : formatPrice(
              fromCentsFormat(centAmount) - membershipRefund + (membershipProratedFinalAmount || 0),
              currency || ''
            ),
      formattedTotalPriceWithoutTaxWithoutShipping:
        (isMembershipCart || isCredentialSectionCart) &&
        (proratedPriceAmount?.length > 0 ||
          (hasTruthyValue(isFlpSwitchOrUpgrade, isCartForFcmaRenewal) && proratedFinalAmount > 0))
          ? formatPrice(proratedFinalAmount - fromCentsFormat(selectedShippingPrice), currency || '')
          : formatPrice(
              fromCentsFormat(centAmount - selectedShippingPrice) -
                membershipRefund +
                (membershipProratedFinalAmount || 0),
              currency || ''
            ),
      formattedTotalDiscountAmount: !totalDiscountAmount
        ? ''
        : formatPrice(fromCentsFormat(totalDiscountAmount), currency || ''),
      formattedTaxAmount:
        taxAmount !== null && taxAmount >= 0 ? formatPrice(fromCentsFormat(centTaxAmount), currency || '') : null,
      discounts,
    };
  }
);

export const isCartEmptySelector = createSelector(
  numberOfItemsInCartSelector,
  (numberOfItems: number) => numberOfItems === 0
);

export const subscribedProductsSelector = createSelector(
  [cartSelector],
  (cart?: Cart.Cart): Product.SubscribedProduct[] | null => cart?.subscribedProducts || null
);

export const doesCartContainSubscribedProductsSelector = createSelector(
  [subscribedProductsSelector],
  (subscribedProducts?: Product.SubscribedProduct[] | null): boolean => Boolean(subscribedProducts?.length)
);

export const bundleCartUpsellSelector = createSelector(
  [cartSelector, hasMembershipProductSelector],
  (cart?: Cart.Cart, hasMembershipProduct?: boolean): Product.ProductBundle[] => {
    if (hasMembershipProduct) {
      return emptyArray;
    }
    return cart?.bundlesUpsell || emptyArray;
  }
);

export const mergeableBundlesSelector = createSelector(
  [cartSelector],
  (cart?: Cart.Cart): Product.ProductBundle[] => cart?.mergeableBundles || emptyArray
);

export const mergeableBundlesMaxPercentSelector = createSelector(
  [mergeableBundlesSelector],
  (bundles: Product.ProductBundle[]): number =>
    bundles.reduce<number>((acc, bundle) => Math.max(acc, bundle.bundleDiscountPercent || 0), 0)
);

export const upsellUserApplicableVariantsPricingSelector = createSelector(
  [bundleCartUpsellSelector, userRolesSelector, productCurrencySelector, userTierSelector],
  (
    bundleUpsell: Product.ProductBundle[],
    userRoles: UserTypes.MembershipIdsEnum[],
    currency: State.ProductCurrency,
    userTier: Checkout.Tiers
  ) => {
    return bundleUpsell.reduce<State.BundleUpsellPriceMap>((acc, bundle) => {
      const bundleProductVariants = bundle.bundleProducts?.map(getVariants);
      acc[bundle.name] =
        bundleProductVariants?.map(productVariants =>
          ProductUtils.variantsPriceInfoForUser(
            ProductUtils.userApplicableVariantsPricing(
              productVariants,
              userRoles,
              false, // isContribution
              currency?.label,
              userTier
            )
          )
        ) || [];
      return acc;
    }, {});
  }
);

// FixMe. AP-6866. Might needed adjustments
export const upsellBundleItemsWithOutOfStockCheck = createSelector(
  [bundleCartUpsellSelector, rootSelector],
  (bundleUpsell: Product.ProductBundle[], cart: State.Cart): State.BundleUpsellStockInfoMap =>
    bundleUpsell.reduce<State.BundleUpsellStockInfoMap>((acc, bundle) => {
      acc[bundle.name] = getVariantsBundleItemsWithOutOfStockCheck(bundle.bundleProducts, cart.value);
      return acc;
    }, {})
);

export const isCartHasRestrictedMixSelector = createSelector(
  [lineItemsSelector],
  (lineItems: State.LineItem[]): boolean => isCartHasRestrictedMix(lineItems)
);

export const cartDetailItemsSelector = createSelector(
  [lineItemsSelector, membershipProratedAmountSelector, proratedPriceAmountSelector],
  (
    cart: State.LineItem[],
    membershipProratedAmount?: number,
    proratedPricesAmount?: Orders.ZuoraInvoiceItem[]
  ): Common.ProductItemData[] => {
    return transformLineItemsToCartDetailItems(cart, null, membershipProratedAmount, proratedPricesAmount);
  }
);

export const getProductsWithEthics = createSelector(
  rootSelector,
  (cart: State.Cart): State.ProductsWithEthicStatus[] => cart.productsWithEthics
);

const productRootSelector = createSelector(
  (state: State.Root): State.Products => state.products,
  (products: State.Products): State.Products => products
);

const productPageItemSelector = createSelector(
  productRootSelector,
  (products: State.Products): Product.ProductItem => products.productItem
);

export const isExistingBundleCartItemSelector = createSelector(
  [rootSelector, productPageItemSelector],
  (cart: State.Cart, productItem: Product.ProductItem): boolean => {
    const cartIsEmpty = !cart?.value?.lineItems;
    const isBundle = productItem?.productType === Product.ProductType.BUNDLE;

    if (cartIsEmpty || !isBundle || !productItem?.bundleProducts?.length) return false;

    return productItem.bundleProducts.reduce(
      (acc: boolean, bundledProductItem) =>
        acc && cart.value.lineItems.some((item: Cart.LineItem) => item.productId === bundledProductItem?.productId),
      true
    );
  }
);

export const isExistingCartItemSelector = createSelector(
  [cartSelector, productPageItemSelector, isExistingBundleCartItemSelector, selectedVariantSKUSelector],
  (
    cart: Cart.Cart,
    productItem: Product.ProductItem,
    isExistingBundleCartItem: boolean,
    selectedVariant?: string
  ): boolean => {
    return getHasItemInTheCart(productItem, cart, selectedVariant) || isExistingBundleCartItem;
  }
);

export const bundleComponentProductVariantExistingInCartBundleItemsSelector = createSelector(
  [lineItemsSelector, bundleProductsProductIdsSelector, selectedBundleItemsSkusSelector],
  (
    lineItems: State.LineItem[],
    bundleProductsProductIds?: string[] | undefined,
    selectedBundleItemsSkus?: string[] | undefined
  ): string[] => {
    const bundleId: string | null =
      lineItems?.find((lineItem: State.LineItem) => {
        const sameProduct: boolean | undefined = bundleProductsProductIds?.includes(lineItem.productId);
        const sameVariant: boolean | undefined =
          lineItem.variant && selectedBundleItemsSkus?.includes(lineItem.variant.sku);
        return sameProduct && sameVariant && lineItem.bundleId;
      })?.bundleId || null;
    const lineItemsToRemove: string[] = lineItems.reduce((acc: string[], lineItem: State.LineItem) => {
      if (lineItem.bundleId === bundleId) {
        acc.push(lineItem.id);
      }
      return acc;
    }, []);
    return lineItemsToRemove;
  }
);

export const didCartTransitionFailSelector = createSelector(rootSelector, (cart: State.Cart): boolean => {
  return Boolean(cart?.error);
});

export const cartIdSelector = createSelector(rootSelector, (cart: State.Cart): string => cart.value?.id || '');

export const hasSubscriptionInCartSelector = createSelector(lineItemsSelector, (lineItems: State.LineItem[]): boolean =>
  Boolean(lineItems?.some((item: State.LineItem) => item.productType === Product.ProductType.SUBSCRIPTION))
);

export const hasPhysicalProductInCartSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    Boolean(lineItems?.some((item: State.LineItem) => isPhysicalProduct(item.variant?.attributes).isPhProduct))
);

export const hasBasicPhysicalProductInCartSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    Boolean(lineItems?.some((item: State.LineItem) => isPhysicalProduct(item.variant?.attributes).isBasicPhProduct))
);

export const cartsPhSubscriptionProductNamesSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): Cart.SubscriptionProductNamesInCart => {
    const physicalSubscriptionMagazinesInCart: string[] = [];
    const physicalSubscriptionCoursesInCart: string[] = [];
    lineItems.forEach(lineItem => {
      if (isPhysicalProduct(lineItem.variant?.attributes).isPhSubscriptionProduct) {
        if (
          lineItem.variant?.attributes?.subscriptionProductType?.key === Product.SubscriptionProductType.PUBLICATION
        ) {
          return physicalSubscriptionMagazinesInCart.push(lineItem.name);
        }
        if (lineItem.variant?.attributes?.subscriptionProductType?.key === Product.SubscriptionProductType.COURSE) {
          return physicalSubscriptionCoursesInCart.push(lineItem.name);
        }
      }
    });

    return { physicalSubscriptionMagazinesInCart, physicalSubscriptionCoursesInCart };
  }
);

export const hasStandingOrderProductInCartSelector = createSelector(
  lineItemsSelector,
  hasBasicPhysicalProductInCartSelector,
  (lineItems: State.LineItem[], hasBasicPhysicalProductInCart: boolean): boolean =>
    hasBasicPhysicalProductInCart &&
    Boolean(
      lineItems?.some(
        (item: State.LineItem) =>
          (item.custom?.fields.standing_order_discount as any) === Product.StandingOrderEligible.STANDING_NEW
      )
    )
);

export const cartErrorsSelector = createSelector([rootSelector], (cart: State.Cart): Cart.Errors => {
  if (cart.error) {
    if (cart.error?.primaryApplicationError?.errorCode) {
      return cart.error?.primaryApplicationError?.errorCode;
    }
    return Cart.Errors.GENERIC;
  }
  return Cart.Errors.NO_ERROR;
});

export const isAnyFreeTrialSubscriptionInCartSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems.some(
      lineItem =>
        lineItem.purchaseType === Product.FreeTrials.FREE_TRIAL &&
        lineItem.productType === Product.ProductType.SUBSCRIPTION
    )
);

export const isAnyFreeTrialCourseInCartSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems.some(
      lineItem =>
        lineItem.purchaseType === Product.FreeTrials.FREE_TRIAL && lineItem.productType === Product.ProductType.COURSE
    )
);

export const isAnyFreeTrialInCart = createSelector(lineItemsSelector, (lineItems: State.LineItem[]): boolean =>
  lineItems.some(lineItem => lineItem.purchaseType === Product.FreeTrials.FREE_TRIAL)
);

export const nonEligibleFreeTrialsInCartSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): Cart.ProductInfoWithIdNameUrl[] =>
    CartUtils.getNonFreeTrialEligibleCartLineItems(lineItems)
);

export const isFreeCartSelector = createSelector(
  [priceTotalCartSelector],
  (priceTotalCart: Cart.CartTotalPriceInfo): boolean => {
    return priceToFloat(priceTotalCart.formattedTotalPrice) === 0;
  }
);

export const cartConferenceInfoSelector = createSelector(
  [lineItemsSelector],
  (items: State.LineItem[]): State.ConferenceInfo | null => {
    const conferenceProducts = items?.filter(item => item.productType === Product.ProductType.CONFERENCE);
    const conferenceProductsExist = Boolean(conferenceProducts.length);
    if (!conferenceProductsExist) {
      return null;
    }
    const onsiteType: string = Product.AvailableFormat.ONSITE.toUpperCase();
    const flexPassType: string = Product.AvailableFormat.FLEX_PASS.toUpperCase().replace('-', ' ');
    const isOnsiteOption = conferenceProducts.some(confProds => {
      const onsiteLabel: string = confProds.variant?.attributes?.productType?.label.toUpperCase();
      return onsiteLabel === onsiteType || onsiteLabel === flexPassType;
    });
    const covidWaiver: boolean = conferenceProducts.some(confProds => {
      return Boolean(confProds.variant?.attributes?.covidWaiver);
    });
    const conferenceSafetyProtocols: boolean = conferenceProducts.some(confProds => {
      return Boolean(confProds.variant?.attributes?.conferenceSafetyProtocols);
    });
    return {
      hasConference: conferenceProductsExist,
      isOnsiteConference: isOnsiteOption,
      hasCovidWaiver: covidWaiver,
      hasConferenceSafetyProtocols: conferenceSafetyProtocols,
    };
  }
);

export const hasOnlyMagazinesInCartSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.every((item: State.LineItem) => isPhysicalProduct(item.variant?.attributes).isPhSubscriptionProduct)
);

export const phProductsInArrCheckSelector = createSelector(
  lineItemsSelector,
  (
    lineItems: State.LineItem[]
  ): {
    hasPhProduct: boolean;
    hasBasicPhProduct: boolean;
    hasPhSubscriptionProduct: boolean;
  } => phProductsInArrCheck(lineItems)
);

export const cartShippingAddressSelector = createSelector(
  cartSelector,
  (cart?: Cart.Cart): CommerceTypes.Address | undefined => cart?.shippingAddress
);

export const cartShippingPricesSelectors = createSelector(
  [cartSelector],
  (cart: Cart.Cart): Shipping.ShippingPrice[] => {
    return cart.shippingPrices?.prices || emptyArray;
  }
);

export const selectedShippingMethodIdSelector = createSelector([cartSelector], (cart: Cart.Cart): string | null => {
  return cart.shippingInfo?.shippingMethod?.id || null;
});

export const itemsMigratedBundleDiscountSelector = createSelector(cartSelector, (cart?: Cart.Cart): number | null => {
  if (!cart?.itemsMigratedBundleId) return null;
  const lineItem = cart!.lineItems.find(item => item.bundleId === cart?.itemsMigratedBundleId);
  return lineItem?.bundleDiscountPercentage || null;
});

export const upsellItemAddedBundleDiscountSelector = createSelector(cartSelector, (cart?: Cart.Cart): number | null => {
  if (!cart?.asPartOfToBundleId) return null;
  const lineItem = cart!.lineItems.find(item => item.bundleId === cart?.asPartOfToBundleId);
  return lineItem?.bundleDiscountPercentage || null;
});

export const variantLineItemForSwappingDonationSelector = createSelector(
  [hasAttestationVariantsSelector, lineItemsSelector, selectedVariantSKUSelector],
  (
    hasAttestationVariants: Product.Variant[],
    lineItems: State.LineItem[],
    selectedVariant?: string
  ): string | null | undefined => {
    if (!hasAttestationVariants.length || !selectedVariant || !lineItems.length) return null;

    const skuToBeSwappedOut = hasAttestationVariants.filter(
      (variant: Product.Variant) => variant.sku !== selectedVariant
    )?.[0]?.sku;

    if (!skuToBeSwappedOut) return null;

    return lineItems.find(item => item.variant?.sku === skuToBeSwappedOut)?.id;
  }
);

export const defaultProductVariantWithAttestationSkuSelector = createSelector(
  [hasAttestationVariantsSelector, lineItemsSelector],
  (hasAttestationVariants: Product.Variant[], lineItems: State.LineItem[]): string | null | undefined => {
    if (!hasAttestationVariants.length || !lineItems.length) return null;

    const personalAttestation = hasAttestationVariants.find(
      (variant: Product.Variant) => variant.attestationRequired === Product.DonationAttestation.YES
    );

    if (!personalAttestation) return null;

    return lineItems.find(item => item.variant?.sku === personalAttestation?.sku)?.variant?.sku;
  }
);

export const isContributionOnlySelector = createSelector(lineItemsSelector, (lineItems: State.LineItem[]): boolean =>
  lineItems?.every(product => product.productType === Product.ProductType.CONTRIBUTION)
);

export const hasContributionInCartSelector = createSelector(lineItemsSelector, (lineItems: State.LineItem[]): boolean =>
  lineItems?.some(product => product.productType === Product.ProductType.CONTRIBUTION)
);

export const selectedMembershipTypeSelector = createSelector(
  [lineItemsSelector],
  (lineItems: State.LineItem[]): string => {
    const membershipType = lineItems?.find(lineItem => lineItem.productType === Product.ProductType.MEMBERSHIP)?.variant
      ?.attributes?.membershipKey?.key;
    return membershipType || '';
  }
);

export const appliedOrganizationPromoCodeSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): string =>
    (discountObj?.custom?.fields?.organization as unknown as string) || ''
);

export const isMembershipDowngradeUpgradeSelector = createSelector(
  [selectedMembershipTypeSelector, currentMembershipSubscriptionKeySelector, isRenewalSeasonSelector],
  (selectedMembershipTypeKey: string, previousMembershipSubscriptionKey: string, isRenewalSeason: boolean): boolean =>
    !isRenewalSeason &&
    Boolean(selectedMembershipTypeKey) &&
    Boolean(previousMembershipSubscriptionKey) &&
    selectedMembershipTypeKey !== previousMembershipSubscriptionKey
);

export const isManualRenewalSameTypeSelector = createSelector(
  [selectedMembershipTypeSelector, currentMembershipSubscriptionKeySelector, isRenewalSeasonSelector],
  (selectedMembershipTypeKey: string, previousMembershipSubscriptionKey: string, isRenewalSeason: boolean): boolean =>
    isRenewalSeason &&
    Boolean(selectedMembershipTypeKey) &&
    Boolean(previousMembershipSubscriptionKey) &&
    selectedMembershipTypeKey === previousMembershipSubscriptionKey
);

export const isManualRenewalTypeChangeSelector = createSelector(
  [selectedMembershipTypeSelector, currentMembershipSubscriptionKeySelector, isRenewalSeasonSelector],
  (selectedMembershipTypeKey: string, previousMembershipSubscriptionKey: string, isRenewalSeason: boolean): boolean =>
    isRenewalSeason &&
    Boolean(selectedMembershipTypeKey) &&
    Boolean(previousMembershipSubscriptionKey) &&
    selectedMembershipTypeKey !== previousMembershipSubscriptionKey
);

export const cartCredentialsSelector = createSelector(
  [lineItemsSelector],
  (lineItems: State.LineItem[]): State.LineItem[] =>
    lineItems.filter(({ productType, variant }) => productType === Product.ProductType.CREDENTIAL && !!variant)
);

export const membershipLineItemSelector = createSelector(
  [lineItemsSelector],
  (lineItems: State.LineItem[]): LineItem | undefined => {
    const membershipType = lineItems?.find(lineItem => lineItem.productType === Product.ProductType.MEMBERSHIP);
    return membershipType;
  }
);

export const flpLineItemSelector = createSelector(
  [lineItemsSelector],
  (lineItems: State.LineItem[]): LineItem | undefined => {
    const membershipType = lineItems?.find(lineItem => lineItem.productType === Product.ProductType.FLP);
    return membershipType;
  }
);

export const membershipApplicationPartSelector = createSelector(
  [membershipLineItemSelector, applicationPartsSelector],
  (membershipLineItem, applicationParts) => {
    const membershipSKU = membershipLineItem?.variant?.sku;
    return applicationParts.find(part => part?.productKeyId === membershipSKU);
  }
);

export const credentialApplicationPartSelectors = createSelector(
  [cartCredentialsSelector, applicationPartsSelector],
  (credentialsList, applicationParts) => {
    const credentialsSKU = credentialsList.map(credential => credential.variant?.sku);
    return applicationParts.filter(part => (part?.productKeyId ? credentialsSKU.includes(part.productKeyId) : false));
  }
);

const webcastPassItemSelector = createSelector(
  productRootSelector,
  (products: State.Products): Product.ProductItem => products.webcastPass
);

export const hasWebcastPassInCartSelector = createSelector(
  [cartSelector, webcastPassItemSelector],
  (cart: Cart.Cart, webcastPass: Product.ProductItem): boolean => {
    return getHasItemInTheCart(webcastPass, cart, webcastPass?.variants[0].sku);
  }
);

// get the flp_seller account number in discount code's custom field
export const appliedPromoCodeFlpResellerSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): string =>
    (discountObj?.custom?.fields?.flp_reseller as unknown as string) || ''
);

// get the tuition_provider account number in discount code's custom field
export const appliedPromoCodeTrainingProviderSelector = createSelector(
  [appliedPromoCodeObjSelector],
  (discountObj?: CommerceTypes.DiscountCode): string =>
    (discountObj?.custom?.fields?.tuition_provider as unknown as string) || ''
);

export const hasFlpProductSelector = createSelector(lineItemsSelector, (lineItems: State.LineItem[]): boolean =>
  lineItems?.some(lineItem => lineItem.productType === Product.ProductType.FLP)
);

export const getCartEntityForFLPSelector = createSelector(
  [hasFlpProductSelector, customerMembershipsSelector, customerInactiveMembershipsSelector, primaryAddressSelector],
  (
    hasFlpInCart: boolean,
    currentMemberships: Salesforce.FirmMembership[],
    inactiveMemberships: Salesforce.FirmMembership[],
    userPrimaryAddress: State.SalesforceAddress | undefined
  ): Membership.MembershipBody | null => {
    const isPrimaryAddressAmericas =
      CountriesList.find(country => country.text === ConvertFromISOAlpha3ToText(userPrimaryAddress?.country || ''))
        ?.isAmerica || false;

    if (hasFlpInCart) {
      if (
        currentMemberships?.[0]?.membershipBody === Membership.MembershipBody.AICPA ||
        inactiveMemberships?.[0]?.membershipBody === Membership.MembershipBody.AICPA ||
        isPrimaryAddressAmericas
      ) {
        return Membership.MembershipBody.AICPA;
      }
      return Membership.MembershipBody.CIMA;
    }
    return null;
  }
);

export const hasCimaMembershipBodyProductSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(
      lineItem => lineItem.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.CIMA
    )
);

export const hasAicpaMembershipBodyProductSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(
      lineItem => lineItem.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.AICPA
    )
);

export const isCartHasFLPWithAICPAEntitySelector = createSelector(
  [getCartEntityForFLPSelector],
  (flpEntity: null | Membership.MembershipBody): boolean => flpEntity === Membership.MembershipBody.AICPA
);

export const hasAicpaMembershipBodyButNoFLPProductSelector = createSelector(
  [hasAicpaMembershipBodyProductSelector, isCartHasFLPWithAICPAEntitySelector],
  (hasAicpaMembershipBody: boolean, isCartHasFLPWithAICPAEntity: boolean): boolean =>
    hasAicpaMembershipBody && !isCartHasFLPWithAICPAEntity
);

export const hasCertificateProductSelector = createSelector(lineItemsSelector, (lineItems: State.LineItem[]): boolean =>
  lineItems?.some(lineItem => lineItem.productType === Product.ProductType.CERTIFICATE)
);

export const selectedMembershipTierCodeSelector = createSelector(
  [lineItemsSelector],
  (lineItems: State.LineItem[]): string => {
    const membershipType = lineItems?.find(lineItem => lineItem.productType === Product.ProductType.MEMBERSHIP)?.variant
      ?.attributes?.tierCode?.key;
    return membershipType || '';
  }
);

export const selectedMembershipSlugSelector = createSelector(
  [lineItemsSelector],
  (lineItems: State.LineItem[]): string => {
    const membershipType = lineItems?.find(
      lineItem => lineItem.productType === Product.ProductType.MEMBERSHIP
    )?.productSlug;
    return membershipType || '';
  }
);

export const hasAicpaProtectedProductSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(
      lineItem =>
        (lineItem.variant?.attributes?.zuora_product_rate_plan?.key !== 'N/A' ||
          Boolean(lineItem.variant?.attributes?.zuora_product_rate_plan?.key)) &&
        (lineItem.variant?.attributes?.cima_zuora_product_rate_plan?.key === 'N/A' ||
          !Boolean(lineItem.variant?.attributes?.cima_zuora_product_rate_plan?.key))
    )
);

export const hasCimaProtectedProductSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(
      lineItem =>
        (lineItem.variant?.attributes?.cima_zuora_product_rate_plan?.key !== 'N/A' ||
          Boolean(lineItem.variant?.attributes?.cima_zuora_product_rate_plan?.key)) &&
        (lineItem.variant?.attributes?.zuora_product_rate_plan?.key === 'N/A' ||
          !Boolean(lineItem.variant?.attributes?.zuora_product_rate_plan?.key))
    )
);

// Check if Cart has CIMA Certificate
export const hasCimaCertificateInCartSelector = createSelector(
  lineItemsSelector,
  hasCertificateProductSelector,
  (lineItems: State.LineItem[], hasCertificateProduct: boolean): boolean =>
    lineItems?.some(
      lineItem => lineItem?.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.CIMA
    ) && hasCertificateProduct
);

// Check if Cart has AICPA Certificate
export const hasAicpaCertificateInCartSelector = createSelector(
  lineItemsSelector,
  hasCertificateProductSelector,
  (lineItems: State.LineItem[], hasCertificateProduct: boolean): boolean =>
    lineItems?.some(
      lineItem => lineItem?.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.AICPA
    ) && hasCertificateProduct
);

export const isRegionalPathwayCartSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean => {
    return lineItems?.some(
      lineItem =>
        lineItem?.variant?.attributes?.isPathwayProduct &&
        lineItem.productType === Product.ProductType.MEMBERSHIP &&
        lineItem?.variant?.attributes?.membershipKey?.key === Membership.MembershipKeys.CANDIDATE
    );
  }
);

export const cartEntitySelector = createSelector(
  rootSelector,
  (cart: State.Cart): string => cart?.value?.legalEntity || ''
);

export const cartEntityLabelSelector = createSelector(
  [cartEntitySelector, constantsSelector],
  (
    cartEntity: string,
    constants: null | Contentful.Constants.Constants
  ): { urlLink: string; fullEntityName: string } | undefined => {
    if (cartEntity) {
      if (cartEntity === Salesforce.LegalEntity.ASSOCIATION.toString()) {
        return {
          urlLink: constants?.[CONSTANTS.AICPA_URL_LINK],
          fullEntityName: constants?.[CONSTANTS.AICPA_URL_TITLE],
        };
      }

      if (cartEntity === Salesforce.LegalEntity.CIMA.toString()) {
        return {
          urlLink: constants?.[CONSTANTS.CIMA_URL_LINK],
          fullEntityName: constants?.[CONSTANTS.CIMA_URL_TITLE],
        };
      }
    }
    return undefined;
  }
);

export const hasCimaApprenticeProductInCartSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(
      lineItem =>
        lineItem.membershipKey === Membership.MembershipKeys.CANDIDATE &&
        (lineItem.variant?.sku === Membership.CimaApprenticeSKU.L4 ||
          lineItem.variant?.sku === Membership.CimaApprenticeSKU.L7)
    )
);

export const isCimaApprenticeSuspendedRenewalSelector = createSelector(
  [lineItemsSelector, hasCimaApprenticeProductInCartSelector, isUserMemberSuspendedSelector],
  (lineItems: State.LineItem[], hasCimaApprenticeProductInCart: boolean, isUserMemberSuspended: boolean): boolean =>
    lineItems?.some(lineItem => lineItem.productSlug === Product.Fee.CIMA_LATE_FEE) &&
    hasCimaApprenticeProductInCart &&
    isUserMemberSuspended
);

export const hasCimaRegularRejoiningFeeProductSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(lineItem => lineItem?.productSlug === Product.Fee.CIMA_REJOINING_FEE_REGULAR)
);

export const hasCimaAffiliateRejoiningFeeProductSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(lineItem => lineItem?.productSlug === Product.Fee.CIMA_REJOINING_FEE_AFFILIATE)
);

export const hasCimaCandidateRejoiningFeeProductSelector = createSelector(
  lineItemsSelector,
  (lineItems: State.LineItem[]): boolean =>
    lineItems?.some(lineItem => lineItem?.productSlug === Product.Fee.CIMA_REJOINING_FEE_CANDIDATE)
);

export const isCimaApprenticeLapsedRenewalSelector = createSelector(
  [
    hasCimaApprenticeProductInCartSelector,
    isCimaCandidateApprenticeLapsedRenewalSelector,
    hasCimaCandidateRejoiningFeeProductSelector,
  ],
  (
    hasCimaApprenticeProductInCart: boolean,
    isCimaCandidateApprenticeLapsedRenewal: boolean,
    hasCimaCandidateRejoiningFeeProduct: boolean
  ): boolean =>
    hasCimaApprenticeProductInCart && isCimaCandidateApprenticeLapsedRenewal && hasCimaCandidateRejoiningFeeProduct
);

export const isCimaRegularLapsedRenewalSelector = createSelector(
  [lineItemsSelector, hasCimaRegularRejoiningFeeProductSelector],
  (lineItems: State.LineItem[], hasCimaRegularRejoiningFeeProduct: boolean): boolean =>
    lineItems?.some(
      lineItem =>
        lineItem.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.CIMA &&
        lineItem?.membershipKey === Membership.MembershipKeys.REGULAR
    ) && hasCimaRegularRejoiningFeeProduct
);

export const isApprenticeCimaResignedRenewalSelector = createSelector(
  [
    isUserResignedSelector,
    hasCimaCandidateRejoiningFeeProductSelector,
    hasCimaRegularRejoiningFeeProductSelector,
    hasCimaAffiliateRejoiningFeeProductSelector,
  ],
  (
    isUserResigned: boolean,
    hasCimaCandidateRejoiningFeeProduct: boolean,
    hasCimaRegularRejoiningFeeProduct: boolean,
    hasCimaAffiliateRejoiningFeeProduct: boolean
  ): boolean =>
    (hasCimaCandidateRejoiningFeeProduct || hasCimaAffiliateRejoiningFeeProduct || hasCimaRegularRejoiningFeeProduct) &&
    isUserResigned
);

export const isApprenticeCimaRegularLapsedRenewalSelector = createSelector(
  [lineItemsSelector, isCimaRegularLapsedSelector, hasCimaRegularRejoiningFeeProductSelector, learningPathwaySelector],
  (
    lineItems: State.LineItem[],
    isCimaRegularLapsed,
    hasCimaRegularRejoiningFeeProduct: boolean,
    learningPathway
  ): boolean =>
    lineItems?.some(
      lineItem =>
        lineItem.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.CIMA &&
        lineItem?.membershipKey === Membership.MembershipKeys.REGULAR
    ) &&
    isCimaRegularLapsed &&
    hasCimaRegularRejoiningFeeProduct &&
    AdminUtils.isApprenticePathway(learningPathway as string)
);

export const isApprenticeCimaAffiliateLapsedRenewalSelector = createSelector(
  [
    lineItemsSelector,
    isCimaAffiliateLapsedSelector,
    hasCimaAffiliateRejoiningFeeProductSelector,
    learningPathwaySelector,
  ],
  (
    lineItems: State.LineItem[],
    isCimaAffiliateLapsed,
    hasCimaAffiliateRejoiningFee: boolean,
    learningPathway
  ): boolean =>
    lineItems?.some(
      lineItem =>
        lineItem.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.CIMA &&
        lineItem?.membershipKey === Membership.MembershipKeys.AFFILIATE
    ) &&
    isCimaAffiliateLapsed &&
    hasCimaAffiliateRejoiningFee &&
    AdminUtils.isApprenticePathway(learningPathway as string)
);

export const isMipRenewalSelector = createSelector(
  [hasMipCredentialProductSelector, hasMipCredentialSelector],
  (hasExistingMipProduct: boolean | undefined, hasMipCredential: boolean): boolean =>
    Boolean(hasExistingMipProduct && hasMipCredential)
);

export const isPromoCodeChangesAllowedSelector = createSelector(
  hasMembershipProductSelector,
  isPageStepValidForPromoCodeSelector,
  (hasMembershipProduct: boolean, isPageStepValidForPromoCode: boolean) => {
    return hasMembershipProduct || isPageStepValidForPromoCode;
  }
);

export const flpEntityLabelSelector = createSelector(
  [
    hasFlpProductSelector,
    customerMembershipsSelector,
    constantsSelector,
    primaryAddressSelector,
    userHasBeenLicensedBeforeSelector,
    personAccountDataSelector,
    getMembershipApplicationTypeSelector,
  ],
  (
    hasFlpProduct: boolean,
    customerMemberships: Salesforce.FirmMembership[],
    constants: null | Contentful.Constants.Constants,
    primaryAddress: State.SalesforceAddress | undefined,
    userHasBeenLicensedBefore: boolean,
    personAccountData: State.PersonAccountData,
    membershipApplicationType: Product.MembershipApplicationType
  ): { urlLink: string; fullEntityName: string } | undefined => {
    const aicpaEntity = {
      urlLink: constants?.[CONSTANTS.AICPA_URL_LINK],
      fullEntityName: constants?.[CONSTANTS.AICPA_URL_TITLE],
    };

    const cimaEntity = {
      urlLink: constants?.[CONSTANTS.CIMA_URL_LINK],
      fullEntityName: constants?.[CONSTANTS.CIMA_URL_TITLE],
    };

    const labelCheckFromAddress = () => {
      const isPrimaryAddressAmericas =
        CountriesList.find(
          country =>
            country.text ===
            ConvertFromISOAlpha3ToText(primaryAddress?.country || personAccountData.address.country || '')
        )?.isAmerica || false;

      return isPrimaryAddressAmericas ? aicpaEntity : cimaEntity;
    };

    if (hasFlpProduct) {
      if (customerMemberships?.length) {
        const isAicpa = customerMemberships[0]?.membershipBody === Membership.MembershipBody.AICPA;
        return isAicpa ? aicpaEntity : cimaEntity;
      }
      return labelCheckFromAddress();
    }

    if (membershipApplicationType === Product.MembershipApplicationType.CIMA) {
      return labelCheckFromAddress();
    }

    if (userHasBeenLicensedBefore) {
      return aicpaEntity;
    }
    return undefined;
  }
);

// To proceed to checkout from Cart Page when active credential and cart credential are the same (Renewal)
export const isCredentialActiveAndInCartSelector = createSelector(
  [cartCredentialsSelector, activeCredentialsSubscriptionSelector],
  (cartCredentials: State.LineItem[], activeCredentials?: Cart.LineItemWithAccessDates[]): boolean => {
    const credentialsExist = cartCredentials.filter((cartCred: State.LineItem) =>
      activeCredentials?.some((activeCred: Cart.LineItemWithAccessDates) => cartCred.productId === activeCred.productId)
    );
    return Boolean(credentialsExist.length);
  }
);

export const isCartAicpaEntitySelector = createSelector(
  [
    hasCimaProtectedProductSelector,
    hasAicpaProtectedProductSelector,
    isCimaMemberSelector,
    isAicpaMemberSelector,
    primaryAddressSelector,
    personAccountDataSelector,
    userHasBeenLicensedBeforeSelector,
    userLocationSelector,
    hasFlpProductSelector,
    inactiveMembershipsBodySelector,
    clickedMembershipBuyAgainSelector,
  ],
  (
    hasCimaProtectedProductOnly: boolean,
    hasAicpaProtectedProductOnly: boolean,
    isCimaMember: boolean | undefined,
    isAicpaMember: boolean | undefined,
    primaryAddress: State.SalesforceAddress | undefined,
    personAccountData: State.PersonAccountData,
    userHasBeenLicensedBefore: boolean,
    userLocation: State.UserLocation,
    isFlpInCart: boolean,
    inactiveMembershipsBody: Membership.MembershipBody,
    clickedMembershipBuyAgain: boolean
  ): boolean => {
    const hasProtectedProduct = [hasCimaProtectedProductOnly, hasAicpaProtectedProductOnly].includes(true);
    const isUserMember = [isCimaMember, isAicpaMember].includes(true);
    const isUserLocationInAmericas =
      CountriesList.find((countryList: any) => countryList.key === userLocation?.country)?.isAmerica ?? false;
    const isPrimaryAddressAmericas =
      CountriesList.find(
        country =>
          country.text === ConvertFromISOAlpha3ToText(primaryAddress?.country ?? personAccountData?.address?.country)
      )?.isAmerica ?? false;
    const isUserInAmericas = [isUserLocationInAmericas, isPrimaryAddressAmericas && isFlpInCart].includes(true);

    // https://cgmastore.atlassian.net/wiki/spaces/NEX/pages/2819919025/4.3.5.15+System+Logic+for+Determining+Invoicing+Entity+and+Merchant+ID+MID
    if (clickedMembershipBuyAgain && inactiveMembershipsBody === Membership.MembershipBody.AICPA) return true;
    if (userHasBeenLicensedBefore) return true;
    if (hasProtectedProduct) return hasAicpaProtectedProductOnly;
    if (isUserMember) return isAicpaMember ?? false;
    if (isUserInAmericas) return true;
    return false;
  }
);

export const membershipApplicationTypeSelector = createSelector(
  [
    getMembershipApplicationTypeSelector,
    isCimaMembershipJourneySelector,
    learningPathwaySelector,
    learningPathwayToSwitchSelector,
    hasFlpProductSelector,
    hasCimaMembershipBodyProductSelector,
    isAicpaMemberSelector,
  ],
  (
    membershipApplicationType: Product.MembershipApplicationType,
    isCimaMembershipJourney: boolean,
    learningPathway: Membership.Pathway | null | undefined,
    learningPathwayToSwitch: Membership.Pathway.APPRENTICE_L4 | Membership.Pathway.APPRENTICE_L7 | null | undefined,
    hasFLPInCart: boolean,
    hasCimaMembershipInCart: boolean,
    isAicpaMember: boolean | undefined
  ): Product.MembershipApplicationType =>
    (!membershipApplicationType && isCimaMembershipJourney && learningPathway && learningPathwayToSwitch) ||
    ((hasFLPInCart || hasCimaMembershipInCart) && isAicpaMember)
      ? Product.MembershipApplicationType.CIMA
      : membershipApplicationType
);

export const isL7CandidateOrAffiliateCoreUpgradeSelector = createSelector(
  lineItemsSelector,
  isL7CandidateUpgradeSelector,
  (cartLineItems: State.LineItem[], isL7CandidateUpgrade: boolean) => {
    const hasCimaRegularInCart = cartLineItems?.some(
      item =>
        item.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.CIMA &&
        item.variant?.attributes?.membershipKey?.key === Membership.MembershipKeys.REGULAR &&
        item.variant?.attributes?.tierCode?.key === Membership.TierCode.CORE
    );
    return isL7CandidateUpgrade && hasCimaRegularInCart;
  }
);

export const isLapsedCredentialRejoiningSelector = createSelector(
  productListByTypesSelector,
  lineItemsSelector,
  customerInactiveCredentialsSelector,
  activeCredentialsSubscriptionSelector,
  (
    productListItem: State.ProductsListByTypes | null,
    cartLineItems: State.LineItem[],
    inactiveCredentials: State.Credential[],
    activeCredentials?: State.LineItemWithAccessDates[] | undefined
  ): boolean => {
    let isRejoining = false;
    if (productListItem?.credentials?.length) {
      for (const credential of productListItem?.credentials) {
        const lapsedCredential = inactiveCredentials?.find(
          (inactiveCredential: State.Credential) => inactiveCredential?.sku === credential?.variant?.sku
        );
        const reinstatedCredential = activeCredentials?.find(
          (activeCredential: State.LineItemWithAccessDates) => activeCredential?.productSlug === credential?.productSlug
        );
        const isCredentialLessThanOneYearLapsed = moment().diff(lapsedCredential?.endDate, 'year') < 1;
        isRejoining = cartLineItems?.some(
          (cartLineItem: State.LineItem) =>
            lapsedCredential &&
            cartLineItem?.productSlug === credential?.productSlug &&
            isCredentialLessThanOneYearLapsed &&
            !reinstatedCredential
        );
        if (isRejoining) break;
      }
    }

    return isRejoining;
  }
);

export const isLapsedSectionRejoiningSelector = createSelector(
  customerInactiveSectionsSelector,
  lineItemsSelector,
  activeSectionsSubscriptionSelector,
  (
    inactiveSections: Salesforce.Section[],
    lineItems: State.LineItem[],
    activeSections: State.LineItemWithAccessDates[] | undefined
  ): boolean => {
    let isRejoining = false;
    for (const section of inactiveSections) {
      const isSectionLessThanOneYearLapsed = moment().diff(section.endDate, 'year') < 1;
      const alreadyRepurchased = activeSections?.find(activeSection => activeSection.variant?.sku === section.sku);
      isRejoining =
        !alreadyRepurchased &&
        lineItems?.some(
          (lineItem: State.LineItem) => lineItem?.variant?.sku === section?.sku && isSectionLessThanOneYearLapsed
        );
      if (isRejoining) break;
    }

    return isRejoining;
  }
);

export const shouldRemoveProrationSelector = createSelector(
  [
    (state: State.Root) => getFeatureToggleByKeySelector(state, USE_CR_723_REMOVE_PRORATION),
    isCimaMembershipLapsedSelector,
    isAicpaMemberLapsedSelector,
    isLapsedCredentialRejoiningSelector,
    isLapsedSectionRejoiningSelector,
    isAicpaMemberFiveYearsLapsedSelector,
    isAicpaMemberMoreThanOneYearLapsedSelector,
    hasItemWithThreeMonthRuleProrationSelector,
    userMembershipBodySelector,
  ],
  (
    useRemoveProration,
    isCimaMemberLapsed,
    isAicpaMemberLapsed,
    isLapsedCredentialRejoining,
    isLapsedSectionRejoining,
    isAicpaMemberFiveYearsLapsed,
    isAicpaMemberMoreThanOneYearLapsed,
    hasItemWithThreeMonthRuleProration,
    membershipBody
  ): boolean => {
    // only 2 valid conditions for proration removal
    const isLapsedRejoining = hasTruthyValue(
      // reactivation of lapsed member
      hasTruthyValue(isCimaMemberLapsed, isAicpaMemberLapsed),
      // reinstatement of lapsed aicpa creds/sec
      areAllTruthy(
        membershipBody === Membership.MembershipBody.AICPA,
        hasTruthyValue(isLapsedCredentialRejoining, isLapsedSectionRejoining)
      )
    );

    return areAllTruthy(
      useRemoveProration,
      isLapsedRejoining,
      !hasItemWithThreeMonthRuleProration, // outside renewal season
      !isAicpaMemberFiveYearsLapsed,
      !isAicpaMemberMoreThanOneYearLapsed
    );
  }
);
