import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputWithValidation, ShowPasswordInput } from 'components/molecules';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { Link } from 'components/atoms';
import { Utils } from 'mxp-utils';
import { useLocation } from 'react-router';
import { User } from 'mxp-schemas';

export interface LoginForm {
  email: string;
  password: string;
  keepMeLoggedIn: boolean;
}

interface LoginFormProps {
  loginForm: LoginForm;
  setLoginForm: ({ email, password }: LoginForm) => void;
  setFormValid: (isFormValid: boolean) => void;
  isEmailFocusedOnLoad?: boolean;
  inviteData?: User.MembershipInviteData;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  loginForm,
  setLoginForm,
  setFormValid,
  isEmailFocusedOnLoad = true,
  inviteData,
}) => {
  const passwordRef: any = createRef();
  const isPasswordValid: boolean = Boolean(loginForm.password);
  const isEmailValid: boolean = Utils.isEmailFormatValid(loginForm.email);
  const isEmailValidOrEmpty: boolean = isEmailValid || !Boolean(loginForm.email);
  const location = useLocation() as any;
  const passedEmail = location?.state?.email;

  React.useEffect(() => {
    if (loginForm.email || !passedEmail) return;

    setLoginForm({ ...loginForm, email: passedEmail });

    const isPassedEmailValid = Utils.isEmailFormatValid(passedEmail);

    if (isPassedEmailValid && passwordRef && passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [passedEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (passwordRef && passwordRef.current && loginForm.email && isEmailValid) {
      passwordRef.current.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const isFormValid = isEmailValid && isPasswordValid;

    setFormValid(isFormValid);
  }, [isEmailValid, isPasswordValid, setFormValid]);

  // firm billing
  const [isAlreadyRepopulate, setIsAlreadyRepopulate] = useState(false);
  useEffect(() => {
    if (inviteData?.email && !isAlreadyRepopulate) {
      setLoginForm({
        ...loginForm,
        email: inviteData?.email,
      });
      setIsAlreadyRepopulate(true);
    }
  }, [inviteData, setLoginForm, loginForm, isAlreadyRepopulate]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginForm({ ...loginForm, email: event.target.value });
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginForm({ ...loginForm, password: event.target.value });
  };

  return (
    <>
      <StyledInputWithValidation>
        <InputWithValidation
          onChange={handleEmailChange}
          name="email"
          testId="email"
          isCorrect={isEmailValidOrEmpty}
          labelId="email"
          labelName="Email address"
          value={loginForm.email}
          autoFocus={isEmailFocusedOnLoad}
          errorMessage="Please enter a valid email address"
          type="email"
        />
      </StyledInputWithValidation>
      <StyledForgotEmailLink to={getPath(Routes.FORGOT_EMAIL)} testId="forgot-email-address">
        Forgotten email
      </StyledForgotEmailLink>
      <ShowPasswordInput
        fluid
        onChange={handlePasswordChange}
        labelName="Password"
        value={loginForm.password}
        testId="password"
        inputRef={passwordRef}
      />
      <StyledForgotPasswordLink to={getPath(Routes.FORGOT_PASSWORD)} testId="forgot-password">
        Forgotten password
      </StyledForgotPasswordLink>
    </>
  );
};

const StyledInputWithValidation = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(-5)};
`;

const StyledForgotEmailLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  margin-bottom: ${props => props.theme.pxToRem(36)};
  display: inline-block;
`;

const StyledForgotPasswordLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  margin-top: ${props => props.theme.pxToRem(18)};
  margin-bottom: ${props => props.theme.pxToRem(36)};
  display: inline-block;
`;
