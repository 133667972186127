import React from 'react';
import styled from 'styled-components';
import { ButtonEnums, ButtonLink, OnlyDesktop } from 'components/atoms';
import { DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { ExtLinks, SalesEmails, Routes, CampaignSlugs } from 'constants/index';
import { ReactComponent as ExternalIcon } from 'resources/images/ic-open-in-new.svg';
import { Product } from 'mxp-schemas';
import { generatePath } from 'react-router';
import { getPath } from 'utils';

interface Props {
  link?: string;
  productType?: string;
  productSlug?: string;
  contentRef?: () => void;
  productTypeLabel?: string;
}

export const BulkPurchasingBlock: React.FC<Props> = React.memo(
  ({ link, productType, productSlug, contentRef, productTypeLabel }: Props) => {
    const startOrderLink: string = generatePath(getPath(Routes.CAMPAIGNS), {
      slug: CampaignSlugs.BULK_ORDER_FORM,
      productSlug,
    });

    const telephone: string = '1-800-634-6780';
    const isConferenceProductType = productType === Product.ProductType.CONFERENCE;
    const isCourseSubscriptionType: boolean = productTypeLabel === Product.ProductTypeLabels.COURSE;
    const isPublicationSubscriptionType: boolean = productTypeLabel === Product.ProductTypeLabels.PUBLICATION;
    const isExamProductType: boolean = productTypeLabel === Product.ProductTypeLabels.EXAM;
    const contactUsLink = isPublicationSubscriptionType
      ? (link as string)
      : isExamProductType
      ? `${ExtLinks.CPE_LEARNING_EXAM_LINK}`
      : isCourseSubscriptionType
      ? `${ExtLinks.CPE_LEARNING_COURSE_LINK}`
      : `${ExtLinks.CPE_LEARNING_WEBCAST_LINK}`;

    return (
      <StyledDiv id="bulk-purchasing-block" ref={contentRef}>
        {!isConferenceProductType ? (
          <>
            <StyledTitle>Group ordering for your team</StyledTitle>
            <StyledBoxContainer>
              <StyledBox>
                <StyledSubheading>2 to 5 registrants</StyledSubheading>
                <StyledBulkFormText>
                  Save time with our group order form. We’ll send a consolidated invoice to keep your learning expenses
                  organized.
                </StyledBulkFormText>
                <StyledButtonLink
                  testId="bulk-order-form-link"
                  variant={ButtonEnums.variants.primary}
                  to={startOrderLink}
                  bordered
                  fluid
                >
                  Start order
                </StyledButtonLink>
              </StyledBox>
              <OnlyDesktop>
                <StyledVerticalDivider />
              </OnlyDesktop>
              <StyledBox>
                <StyledSubheading>6+ registrants</StyledSubheading>
                <StyledBulkFormText>
                  We can help with group discounts. Email{' '}
                  <b>
                    <StyledPhoneLink isTextLink testId="email-link" to={`mailto:${emailAddressText(productType)}`}>
                      {emailAddressText(productType)}
                    </StyledPhoneLink>
                  </b>
                  <br />
                  US customers call&nbsp;
                  <StyledPhoneLink isTextLink testId="phone-link" to={`tel:${telephone}`}>
                    {telephone}
                  </StyledPhoneLink>{' '}
                  (option {option(productType)})
                </StyledBulkFormText>
                <StyledButtonLink
                  testId="legacy-search-store"
                  variant={ButtonEnums.variants.primary}
                  icon={<StyledExternalIcon color={'white'} />}
                  iconPosition={ButtonEnums.iconPosition.left}
                  to={contactUsLink}
                  external
                  bordered
                  fluid
                >
                  Contact us
                </StyledButtonLink>
              </StyledBox>
            </StyledBoxContainer>
          </>
        ) : (
          <>
            <StyledTitle>Looking for group pricing?</StyledTitle>
            <StyledText>
              We can help with group discounts. Email{' '}
              <b>
                <StyledPhoneLink isTextLink testId="email-link" to={`mailto:${emailAddressText(productType)}`}>
                  {emailAddressText(productType)}
                </StyledPhoneLink>
              </b>
              <br />
              US customers call&nbsp;
              <StyledPhoneLink isTextLink testId="phone-link" to={`tel:${telephone}`}>
                {telephone}
              </StyledPhoneLink>{' '}
              (option {option(productType)})
            </StyledText>
            <StyledButtonLink
              testId="legacy-search-store"
              variant={ButtonEnums.variants.primary}
              icon={<StyledExternalIcon color={'white'} />}
              iconPosition={ButtonEnums.iconPosition.left}
              to={link || `${ExtLinks.BULK_PURCHASE_LINK}`}
              external
              bordered
              fluid
            >
              Contact us
            </StyledButtonLink>
          </>
        )}
      </StyledDiv>
    );
  }
);

const option = (type?: string) => (type === Product.ProductType.CONFERENCE ? '2' : '1');
const emailAddressText = (type?: string) =>
  type === Product.ProductType.CONFERENCE ? SalesEmails.GROUP_CONFERENCE : SalesEmails.SALES_SUPPORT;

const StyledDiv = styled.div`
  margin: ${props => props.theme.pxToRem(24)} 0;
  padding: ${props => props.theme.pxToRem(20)};
  text-align: center;
  font-size: ${props => props.theme.pxToRem(16)};
  border: solid 1px ${props => props.theme.colors.neutralGrey2};
  background-color: ${props => props.theme.colors.neutralGrey1};
`;

const StyledBoxContainer = styled.div`
  display: flex;
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: block;
  }
`;

const StyledTitle = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${props => props.theme.pxToRem(24)};
`;

const StyledSubheading = styled.p`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledText = styled.p`
  font-size: ${props => props.theme.pxToRem(16)};
  margin: 0 0 ${props => props.theme.pxToRem(20)};
`;

const StyledBulkFormText = styled.p`
  ${props => props.theme.mediaQueries.desktopOnly} {
    height: ${props => props.theme.pxToRem(115)};
  }
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.light};
  margin: 0 0 ${props => props.theme.pxToRem(20)};
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    width: auto;
    border-radius: 4px;
    font-size: ${props => props.theme.pxToRem(16)};
    padding: 0 ${props => props.theme.pxToRem(16)};

    svg {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: ${props => props.theme.pxToRem(12)};
    }
  }
`;

const StyledExternalIcon = styled(ExternalIcon)`
  path {
    fill: ${props => props.theme.colors.neutralWhite};
  }
`;

const StyledPhoneLink = styled(ButtonLink)`
  ${DefaultInlineLinkStyles};
`;

const StyledBox = styled.div`
  width: ${props => props.theme.pxToRem(290)};
  margin: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(30)} auto;
    width: ${props => props.theme.pxToRem(271)};
  }
`;

const StyledVerticalDivider = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey3};
  width: ${props => props.theme.pxToRem(1)};
  flex-shrink: 0;
  margin: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(190)};
`;
