import React from 'react';
import { Modal, Button, ButtonEnums, Link } from 'components/atoms';
import styled from 'styled-components/macro';

interface Props {
  onClose: () => void;
}

export const MipAlreadyMipMemberModal: React.FC<Props> = React.memo(({ onClose }) => {
  // Button IDs
  const ALREADY_MIP_MODAL_ID = 'already-mip-member-modal';
  const CLOSE_MODAL_BUTTON_ID = 'no-follow';
  const AICPA_HELP_LINK_ID = 'aicpa-help-link';

  return (
    <StyledModal
      open={true}
      onClose={onClose}
      heading="You already hold this credential"
      testId={ALREADY_MIP_MODAL_ID}
      showCloseCross
      cancelNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          testId={CLOSE_MODAL_BUTTON_ID}
          onClick={onClose}
        >
          I understand
        </Button>
      }
    >
      For more information, select the chat icon to the right of your screen, or{' '}
      <Link testId={AICPA_HELP_LINK_ID} to="https://www.aicpa.org/help" isExternal>
        visit our help page
      </Link>{' '}
      for frequently asked questions and other contact information.
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  &&&& > button {
    position: absolute !important;
    top: ${props => props.theme.pxToRem(16)}!important;
    right: ${props => props.theme.pxToRem(16)}!important;
  }

  &&&& {
    width: ${props => props.theme.pxToRem(590)};
    background: ${props => props.theme.colors.neutralWhite};
    margin-top: ${props => props.theme.pxToRem(48)};
    border-radius: ${props => props.theme.pxToRem(2)};
    padding: ${props => props.theme.pxToRem(37)} 0 ${props => props.theme.pxToRem(20)}!important;
    box-sizing: border-box;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    > .content {
      margin: 0 !important;
      text-align: center;
      box-sizing: border-box;
      padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(20)}!important;
    }

    > .header {
      margin: 0 auto;
      text-align: center;
      display: table;
      h2 {
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }

    > .actions {
      margin: 0 ${props => props.theme.pxToRem(20)};
    }
  }

  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;
