import React from 'react';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import { Button, Heading, ButtonEnums } from 'components/atoms';
import { SurveyOption, SurveyOptionTypeEnum } from 'constants/index';

interface SurveyProps {
  testId: string;
  isLoading?: boolean;
  options: SurveyOption[];
  isPositive?: boolean;
  submit: (...args: any[]) => void;
}

export const Survey: React.FC<SurveyProps> = React.memo(({ options, testId, submit, isPositive = false, ...props }) => {
  const [answers, setAnswers] = React.useState<string[]>([]);
  const onSubmit = React.useCallback(
    (event: React.SyntheticEvent<HTMLElement>) => {
      event.preventDefault();
      submit(answers);
      setAnswers([]);
    },
    [answers, submit, setAnswers]
  );

  const generateResponseOptions = React.useCallback(
    (resOptions: any, parentValues: string[] = [], testIndex: string = '-1') => {
      const elements: any = [];
      const nextElements: any = [];

      const changeAnswers = (event: any) => {
        setAnswers([...parentValues, event.target.value]);
      };

      resOptions.forEach((option: any) => {
        if (option.title) {
          elements.push(
            <StyledHeading key={option.title} data-testid={`${testId}${testIndex}-title`}>
              {option.title}
            </StyledHeading>
          );
        }
        switch (option.type) {
          case SurveyOptionTypeEnum.Radio:
            return elements.push(
              Object.keys(option.values).map((key, valuesIdx) => {
                const testIdx: string = testIndex.concat(`-${valuesIdx + 1}`);
                const isAnswered = answers.includes(key);
                if (option.values[key].activeForm) {
                  if (isAnswered) {
                    const activeForm = option.values[key].activeForm;
                    nextElements.push(generateResponseOptions(activeForm, [key, ...parentValues], testIdx));
                  }
                }

                const click = () => {
                  setAnswers([...parentValues, key]);
                };

                return (
                  <StyledRadio
                    key={key}
                    checked={isAnswered}
                    label={option.values[key].text}
                    onClick={click}
                    type="checkbox"
                    data-testid={`${testId}${testIdx}-checkbox`}
                  />
                );
              })
            );
          case SurveyOptionTypeEnum.TextArea:
            return elements.push(
              <StyledTextArea
                key={String(parentValues)}
                onChange={changeAnswers}
                placeholder="Please tell us your feedback."
                data-testid={`${testId}${testIndex}-textarea`}
              />
            );
          case SurveyOptionTypeEnum.Link:
            return elements.push(
              <a href={option.link} data-testid={`${testId}${testIndex}-link`}>
                {option.text}
              </a>
            );
        }
        return true;
      });

      return <div key="survey">{[...elements, ...nextElements]}</div>;
    },
    [answers, testId]
  );

  return (
    <StyledForm data-testid={testId} onSubmit={onSubmit} {...props}>
      {generateResponseOptions(options)}
      <SubmitFooter key="submit">
        <StyledSubmitButton
          disabled={!(isPositive || answers.length > 0)}
          variant={ButtonEnums.variants.primary}
          bordered
          testId={`submit-${testId}`}
        >
          Submit
        </StyledSubmitButton>
      </SubmitFooter>
    </StyledForm>
  );
});

const StyledForm = styled(Form)`
  &&& {
    width: ${props => props.theme.pxToRem(480)};

    .field,
    .header {
      padding: 0 ${props => props.theme.pxToRem(12)};
    }
  }
`;

const StyledTextArea = styled(Form.TextArea)`
  &&&&& {
    align-self: center;
    width: 100%;
    margin-top: ${props => props.theme.pxToRem(32)};

    > textarea {
      min-height: ${props => props.theme.pxToRem(200)};
    }
  }
`;

const StyledRadio = styled(Form.Radio)`
  &&&& {
    margin: ${props => props.theme.pxToRem(12.8)} 0;

    > label {
      color: ${props => props.theme.colors.neutralGrey8};
      font-family: ${props => props.theme.fontFamily};
      font-size: ${props => props.theme.fontSizes.s};
      font-weight: ${props => props.theme.fontWeights.light};
    }

    label::before {
      width: ${props => props.theme.pxToRem(24)};
      height: ${props => props.theme.pxToRem(24)};
      border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey4};
      border-radius: 50%;
      margin-top: ${props => props.theme.pxToRem(-4)};
      background-color: ${props => props.theme.colors.neutralWhite};
    }

    label::after {
      width: ${props => props.theme.pxToRem(24)};
      height: ${props => props.theme.pxToRem(24)};
      border-radius: 50%;
      margin: auto;
      margin-top: ${props => props.theme.pxToRem(-4)};
      background-color: ${props => props.theme.colors.neutralGrey3};
    }

    input:focus:checked ~ label::before,
    input:checked ~ label::before {
      background-color: ${props => props.theme.colors.primaryPurple};
      border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryPurple};
    }

    input:focus:checked ~ label::after,
    input:checked ~ label::after {
      background-color: ${props => props.theme.colors.neutralWhite};
    }

    input:focus:checked ~ label,
    input:checked ~ label {
      font-weight: ${props => props.theme.fontWeights.regular};
    }
  }
`;

const StyledHeading = styled(Heading)`
  &&& {
    width: 100%;
    color: ${props => props.theme.colors.neutralGrey8};
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSizes.l};
    font-weight: ${props => props.theme.fontWeights.regular};
    text-align: left;
  }
`;

const SubmitFooter = styled.div`
  margin-top: ${props => props.theme.pxToRem(32)};
  text-align: center;
`;

const StyledSubmitButton = styled(Button)`
  &&&& {
    width: ${props => props.theme.pxToRem(170)};
  }
`;
