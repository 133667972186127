import { employmentDataSelector } from './../user/selectors';
import { createAction } from 'redux-actions';
import request, { updateContext } from 'utils/GraphQLClient';
import {
  GET_CENTER_ORGANIZATIONS_BY_ACCOUNT_IDS,
  ORGANIZATION_ADMIN_USERS_BY_ROLE,
  GET_CENTER_MEMBERSHIPS,
  GET_ALL_CENTER_MEMBERSHIPS,
  GET_CENTER_MEMBERSHIPS_FIRM_HIERARCHY,
  GET_CENTER_MEMBERSHIP_INVOICES,
  GET_HOSTED_PAGE_SIGNATURE,
  MAKE_INVOICE_PAYMENT,
  GET_INVOICE_PAYMENT_CONFIRMATION_DETAILS,
  GET_INVOICE_FILE,
  PROVISION_SEAT,
  MUTATE_SEND_FIRM_SUB_EMAIL,
  GET_CENTER_SERVICE_CONTRACTS,
  DEALLOCATE_SEATS,
} from 'mxp-graphql-queries';
import { CenterAdminTableType } from 'constants/center-admin';
import { CenterAdminActionNames } from './constants';
import { User as UserTypes, B2B, Invoices, User, ZuoraTypes } from 'mxp-schemas';
import { createMatchSelector, push } from 'connected-react-router';
import { emptyObject, getPath } from 'utils';
import { PAYMENT_PROCESSING_TIMEOUT, Routes } from 'constants/index';
import { Dispatch } from 'redux';
import download from 'downloadjs';
import {
  dataSelectorFactory,
  paginationSelectorFactory,
  selectedInvoiceToDownloadSelector,
  selectedInvoiceSelector,
  organizationAdminsSelector,
  centerMembershipsListSelector,
  organizationsListSelector,
  selectedCenterMembershipSelector,
} from './selectors';
import { routeByTableType } from './helpers';
import { requestInvoices } from '../firmAdmin/helpers';
import { toggleModalPaymentProcessingOpen } from 'modules/layouts';
import { generatePath } from 'react-router';
// ------------------------------------
// Actions
// ------------------------------------

export const getOrganizations: any = createAction(
  CenterAdminActionNames.GET_ORGANIZATIONS,
  async () =>
    (
      dispatch: Dispatch
    ): Promise<{
      organizations: CenterAdmin.Organization[] | any[];
    }> => {
      dispatch(clearAllCenterMemberships());
      const createDataTree = (organizationList: CenterAdmin.Organization[]) => {
        const organizationIdMap = organizationList?.reduce<{ [key: string]: CenterAdmin.Organization }>(
          (acc, organization) => {
            acc[organization.id] = { ...organization, branches: [] };
            return acc;
          },
          {}
        );
        const dataTree: CenterAdmin.Organization[] = [];
        organizationList.forEach((organization: CenterAdmin.Organization) => {
          if (organization.currentParentId) {
            organizationIdMap[organization.currentParentId].branches?.push(organizationIdMap[organization.id]);
          } else dataTree.push(organizationIdMap[organization.id]);
        });
        return dataTree as CenterAdmin.Organization[];
      };

      return request(GET_CENTER_ORGANIZATIONS_BY_ACCOUNT_IDS).then(e => ({
        organizations: createDataTree(e?.centerOrganizations),
      }));
    }
);

export const getOrganizationAdminsByRole: any = createAction(
  CenterAdminActionNames.GET_ORGANIZATION_ADMINS_BY_ROLE,
  async () =>
    (
      _: Dispatch,
      getState: () => State.Root
    ): Promise<{
      organizationAdmins: CenterAdmin.Contact[] | any;
    }> => {
      const state = getState();
      const id: string = (createMatchSelector(getPath(Routes.CENTER_ADMIN_ROOT))(state)?.params as any)?.orgId;
      const roles = [B2B.AgentRole.CLIENT_ADMIN, B2B.AgentRole.FIRM_BILLING, B2B.AgentRole.CENTERS_MEMBERSHIP];
      return Promise.all(
        roles.map(role =>
          request(ORGANIZATION_ADMIN_USERS_BY_ROLE, { id, role }).then(e => e?.organizationAdminUsersByRole)
        )
      ).then(admins => ({ organizationAdmins: admins }));
    }
);

export const getCenterMemberships: any = createAction(
  CenterAdminActionNames.GET_CENTER_MEMBERSHIPS,
  async (params: { organizationId: string; adminAccountId?: string; membershipStatus?: string; date?: string }) =>
    (): Promise<{
      centerMemberships: CenterAdmin.FirmMembership[];
    }> => {
      const { organizationId, adminAccountId, membershipStatus, date } = params;
      return request(GET_CENTER_MEMBERSHIPS, {
        organizationId,
        adminAccountId: adminAccountId || '',
        membershipStatus: membershipStatus || '',
        date: date || '',
      }).then(res => {
        return { centerMemberships: res.getCenterMemberships };
      });
    }
);

export const getAllCenterMemberships: any = createAction(
  CenterAdminActionNames.GET_ALL_CENTER_MEMBERSHIPS,
  async (params: { organizationId: string }) =>
    (
      dispatch: Dispatch
    ): Promise<{
      centerMemberships: CenterAdmin.FirmMembership[];
    }> => {
      const { organizationId } = params;
      return request(GET_ALL_CENTER_MEMBERSHIPS, {
        organizationId,
      }).then(res => {
        return { centerMemberships: res.getAllCenterMemberships };
      });
    }
);

export const clearAllCenterMemberships: any = createAction(CenterAdminActionNames.CLEAR_ALL_CENTER_MEMBERSHIPS);

export const setCenterMembershipsFilters: any = createAction(
  CenterAdminActionNames.SET_CENTER_MEMBERSHIPS_FILTERS,
  async (membershipStatus: string) => (): CenterAdmin.CenterMembershipsFilters => ({ membershipStatus })
);

export const getOrganizationMemberships: any = createAction(
  CenterAdminActionNames.GET_ORGANIZATION_MEMBERSHIPS_BY_HIERARCHY,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const employers: State.EmploymentData['employers'] = employmentDataSelector(state).employers || [];
    if (employers.length > 0) {
      const organizationIds = [
        ...new Set([
          ...(employers.map(employer => employer.organization.id) || []),
          ...(employers.map(employer => employer.organization.parentId) || []),
        ]),
      ].filter(orgId => Boolean(orgId));

      const memberships = await request(GET_CENTER_MEMBERSHIPS_FIRM_HIERARCHY, {
        organizationIds,
      });
      return memberships.getCenterMembershipsByFirmHierarchy;
    }
    return [];
  }
);

export const setDataAction: any = createAction(CenterAdminActionNames.SET_DATA);

export const setPaginationAction: any = createAction(CenterAdminActionNames.SET_PAGINATION);

export const setIsLoading: any = createAction(CenterAdminActionNames.SET_IS_LOADING);

export const getCenterMembershipInvoices: any = createAction(
  CenterAdminActionNames.GET_CENTER_MEMBERSHIP_INVOICES,
  async () =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<any> => {
      const typeForOrgId = CenterAdminTableType.MANAGE_CENTER_MEMBERSHIP_INVOICES;
      const type = CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES;
      const state: State.Root = getState();
      const rootOrganizationId: string = (
        createMatchSelector(getPath(routeByTableType(typeForOrgId)))(state)?.params as any
      )?.orgId;
      const { filters }: CenterAdmin.Pagination = paginationSelectorFactory(type)(state);
      const dataSelector = dataSelectorFactory(type);
      const { hash, isLoading } = dataSelector(state);

      const { returnHash } = await requestInvoices({
        tableType: typeForOrgId,
        rootOrganizationId,
        setIsLoading,
        gql: GET_CENTER_MEMBERSHIP_INVOICES,
        isPaidInvoice: filters.isPaidInvoice,
        isPaidInvoiceFetchedData: filters.isPaidInvoiceFetchedData,
        defaultHash: hash,
        dispatch,
        isLoading,
        type,
        request,
      });

      const returnObject = {
        type,
        modifier: {
          hash: returnHash,
          count: Object.keys(returnHash).length,
        },
      };

      return returnObject;
    }
);

export const setModifiedHashAction: any = createAction(
  CenterAdminActionNames.SET_MODIFIED_HASH,
  (userId: string, type: CenterAdmin.CenterAdminTableType, modifierInput: Partial<CenterAdmin.Member>) =>
    (
      dispatch: Dispatch,
      getState: () => State.Root
    ): {
      type: CenterAdmin.CenterAdminTableType;
      userId: string;
      modifier: Partial<CenterAdmin.Member>;
    } => {
      const state: State.Root = getState();
      const data: any = dataSelectorFactory(type)(state);
      const originItem = data.hash[userId];
      const modifier = Object.keys(modifierInput).reduce((acc: any, key: string) => {
        acc[key] = (modifierInput as any)[key] === originItem[key] ? undefined : (modifierInput as any)[key];
        return acc;
      }, {});

      return { userId, type, modifier };
    }
);

export const getServiceContracts: any = createAction(
  CenterAdminActionNames.GET_CENTER_SERVICE_CONTRACTS,
  () => async (): Promise<CenterAdmin.ServiceContract[]> => {
    return request(GET_CENTER_SERVICE_CONTRACTS).then(response => response.getCenterServiceContracts);
  }
);

export const fetchContractLineItems: any = createAction(
  CenterAdminActionNames.FETCH_CONTRACT_LINE_ITEMS,
  () =>
    (dispatch: Dispatch, getState: () => State.Root): string => {
      const state = getState();
      const membership = selectedCenterMembershipSelector(state);
      return membership?.membershipTerm?.orderLineItemId || '';
    }
);

export const setContractLineItemModifiers: any = createAction(CenterAdminActionNames.SET_CONTRACT_LINE_ITEMS_MODIFIERS);
export const setInvoiceDownloadingId: any = createAction(CenterAdminActionNames.SET_INVOICE_DOWNLOADING_ID);

export const removeContractLineItems: any = createAction(
  CenterAdminActionNames.REMOVE_CONTRACT_LINE_ITEMS,
  async (lineItemIds: string[], emails: string[]) => (dispatch: Dispatch) => {
    return request(DEALLOCATE_SEATS, { lineItemIds, emails }).then(response => {
      if (!response.deAllocateSeats) {
        return Promise.reject('DEALLOCATE_SEATS: no response');
      }
      return lineItemIds;
    });
  }
);

export const getInvoiceFile: any = createAction(
  CenterAdminActionNames.GET_INVOICE_FILE,
  (invoiceId: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setInvoiceDownloadingId(invoiceId));
    const state: State.Root = getState();
    // Centers will only be for AICPA that's why the default.
    const legalEntity = ZuoraTypes.LegalEntity.ASSOCIATION;
    updateContext({
      existingEntity: legalEntity,
    });
    const invoice: State.InvoiceTableRow | null = selectedInvoiceToDownloadSelector(state);
    if (!invoice) return Promise.reject('Download Error: Invoice Not Found');
    return request(GET_INVOICE_FILE, { invoiceId: invoice.id }).then(response => {
      download(`data:application/pdf;base64,${response.getInvoiceFile}`, `${invoice.invoiceNumber}.pdf`);
    });
  }
);
export const setSelectedInvoice: any = createAction(
  CenterAdminActionNames.SET_SELECTED_INVOICE,
  () =>
    (dispatch: Dispatch, getState: () => State.Root): { invoiceId: string; type: CenterAdmin.CenterAdminTableType } => {
      const type = CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES;
      const state: State.Root = getState();
      const invoiceId: string = (
        createMatchSelector(getPath(Routes.CENTER_ADMIN_INVOICE_PAYMENT))(state)?.params as any
      )?.invoiceId;

      return { invoiceId, type };
    }
);

export const getHostedPageSignature: any = createAction(
  CenterAdminActionNames.INVOICES_GET_HOSTED_PAGE_SIGNATURE,
  () => () => request(GET_HOSTED_PAGE_SIGNATURE).then(response => response.getHostedPageSignature)
);

export const payInvoice: any = createAction(
  CenterAdminActionNames.INVOICES_MAKE_INVOICE_PAYMENT,
  (paymentMethodId: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const startTime: number = Date.now();
    dispatch(toggleModalPaymentProcessingOpen());

    const getTimeoutDiff = (): number => {
      const endTime: number = Date.now();
      const diff: number = endTime - startTime;
      return diff > PAYMENT_PROCESSING_TIMEOUT ? 0 : PAYMENT_PROCESSING_TIMEOUT - diff;
    };
    const state: State.Root = getState();
    const invoice = selectedInvoiceSelector(state);

    const payInvoiceParams: Invoices.PayInvoiceParams = {
      paymentMethodId,
      isB2B: true,
      saveCreditCard: false,
      amount: invoice?.amount,
      balance: invoice?.balance,
      invoiceId: invoice?.id,
      accountId: invoice?.accountId,
      invoiceNumber: invoice?.invoiceNumber,
      orderNumber: invoice?.orderNumber,
      installmentTerm: invoice?.installmentTerm,
      paymentTerm: invoice?.paymentTerm,
    };

    return request(MAKE_INVOICE_PAYMENT, payInvoiceParams)
      .then(res => {
        setTimeout(() => {
          dispatch(toggleModalPaymentProcessingOpen());
          const zuoraSuccess: boolean =
            res.payInvoice.success && res.payInvoice.status === Invoices.PaymentStatus.PROCESSED;

          if (zuoraSuccess) {
            dispatch(
              push(
                generatePath(getPath(Routes.CENTER_ADMIN_INVOICE_PAYMENT_CONFIRMATION), {
                  invoiceId: res.payInvoice.invoiceId,
                })
              )
            );
          } else {
            dispatch(
              push(
                generatePath(getPath(Routes.CENTER_ADMIN_INVOICE_PAYMENT_ERROR), {
                  invoiceId: res.payInvoice.invoiceId,
                })
              )
            );
            Promise.reject('Error paying for invoice');
          }
        }, getTimeoutDiff());

        return res.payInvoice;
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(toggleModalPaymentProcessingOpen());
          dispatch(push(generatePath(getPath(Routes.CENTER_ADMIN_INVOICE_PAYMENT_ERROR))));
        }, getTimeoutDiff());
      });
  }
);

export const getInvoicePaymentConfirmationResult: any = createAction(
  CenterAdminActionNames.INVOICES_GET_INVOICE_PAYMENT_CONFIRMATION_RESULT,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const match: any = createMatchSelector(getPath(Routes.CENTER_ADMIN_INVOICE_PAYMENT_CONFIRMATION))(
      state as State.Root
    );

    const { invoiceId } = (match && match.params) || emptyObject;

    return request(GET_INVOICE_PAYMENT_CONFIRMATION_DETAILS, { invoiceId }).then(response => {
      if (!response.getInvoicePaymentConfirmationDetails) {
        return Promise.reject('getInvoicePaymentConfirmationDetails: invoice not found');
      }
      const invoiceDetails = response.getInvoicePaymentConfirmationDetails;
      return invoiceDetails;
    });
  }
);

export const sendFirmSubEmail: any = createAction(
  CenterAdminActionNames.SEND_FIRM_SUB,
  (changeType: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const centerOrganization = organizationsListSelector(state);
    const centerMemberShipList = centerMembershipsListSelector(state);
    const organizationAdmin = organizationAdminsSelector(state);
    const baseUrl = window.location.hostname;
    const centerAdmins = organizationAdmin;

    if (centerAdmins) {
      centerAdmins[2].forEach(admin => {
        const firmSubEmail: UserTypes.FirmSubEmail = {
          subscriberKey: admin.aicpaId,
          email: admin.email,
          firstName: admin.firstName,
          lastName: admin.lastName,
          changeType,
          membershipType: centerMemberShipList[0].firmMembershipType || '',
          orgName: centerOrganization[0].name || '',
          url: `${baseUrl}/center-membership/admin/${centerOrganization[0].id}/memberships/manage`,
          status: centerMemberShipList[0].status || '',
        };

        return request(MUTATE_SEND_FIRM_SUB_EMAIL, { firmSubEmail })
          .then((response: any) => {
            if (response?.sendFirmSubEmail) {
              return { ...response };
            }
            return false;
          })
          .catch(err => {
            return false;
          });
      });
    }
  }
);

export const provisionSeat: any = createAction(
  CenterAdminActionNames.PROVISION_SEAT,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setProvisioningLoading(true));
    const state: State.Root = getState();
    const match: any = createMatchSelector(getPath(Routes.CENTER_MEMBERSHIP_PROVISIONING))(state as State.Root);
    const { accessToken } = (match && match.params) || emptyObject;
    const extractedToken = (accessToken || '').split('?')[0]; // make sure we are discarding rest of the params
    return request(PROVISION_SEAT, {
      inviteToken: extractedToken,
      type: User.ProvisioningTypes.CENTER_MEMBERSHIP,
    }).then((response: any) => {
      return response.provisionSeat;
    });
  }
);

export const setProvisioningLoading: any = createAction(
  CenterAdminActionNames.SET_PROVISION_SEAT_LOADING,
  (loading: boolean) => () => {
    return loading;
  }
);

export const setProvisioningError: any = createAction(
  CenterAdminActionNames.SET_PROVISION_SEAT_ERROR,
  (error: CustomErrors.GraphQLError) => () => {
    return error;
  }
);
