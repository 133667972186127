import React from 'react';
import { useSelector } from 'react-redux';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import styled from 'styled-components';
import { brightcoveConfigSelector } from 'modules/app/selectors';
import * as Sentry from '@sentry/browser';
import { ParagraphTitle, TitleSizes } from 'components/pages/PageProduct/ProductParagraphs';

interface Props {
  video: State.VideoOptions;
  testId: string;
  isProductPage?: boolean;
  inTestimonialsCarousel?: boolean;
}

enum BrightCovePlayerType {
  VIDEO = 'Video',
  PLAYLIST = 'Playlist',
}

export const VideoPlayer: React.FC<Props> = React.memo(
  ({ video, testId, isProductPage = false, inTestimonialsCarousel = false }) => {
    const brightcoveConfig: State.BrightcoveConfig = useSelector(brightcoveConfigSelector);
    const { brightcoveId, videoPlayerId, playlistPlayerId } = brightcoveConfig;

    const onFailure = (failure: any) => {
      Sentry.captureException(failure);
    };

    const videoComponent = video?.embedCode ? (
      <Video
        data-testid={testId}
        dangerouslySetInnerHTML={{ __html: video.embedCode }}
        inTestimonialsCarousel={inTestimonialsCarousel}
      />
    ) : video?.brightcove && brightcoveId ? (
      <Wrap isProductPage={isProductPage}>
        {isProductPage && <ParagraphTitle titleSize={TitleSizes.LARGE}>Video Preview</ParagraphTitle>}
        <StyledBrightcove
          data-test-id={testId}
          isProductPage={isProductPage}
          inTestimonialsCarousel={inTestimonialsCarousel}
        >
          {video.brightcove.type === BrightCovePlayerType.VIDEO && (
            <ReactPlayerLoader
              accountId={brightcoveId}
              videoId={video.brightcove.id}
              playerId={video.brightcove.playerTypeIdOverride || videoPlayerId}
              onFailure={onFailure}
              poster={video.brightcove?.thumbnail?.fields?.file?.url ?? undefined}
            />
          )}
          {video.brightcove.type === BrightCovePlayerType.PLAYLIST && (
            <ReactPlayerLoader
              accountId={brightcoveId}
              playlistId={video.brightcove.id}
              playerId={video.brightcove.playerTypeIdOverride || playlistPlayerId}
              onFailure={onFailure}
              embedOptions={{ playlist: true }}
            />
          )}
        </StyledBrightcove>
      </Wrap>
    ) : null;

    return videoComponent;
  }
);

const StyledBrightcove = styled.div<{ isProductPage?: boolean; inTestimonialsCarousel?: boolean }>`
  ${props =>
    props.isProductPage &&
    `margin-top: ${props.theme.pxToRem(24)}
  ${props.theme.mediaQueries.mobileOnly} {
    &&&&& {
      margin: ${props.theme.pxToRem(24)} ${props.theme.pxToRem(-33)} ${props.theme.pxToRem(30)};
    }
  } 
`}
  margin-bottom: ${props => props.theme.pxToRem(50)};
  .video-js {
    width: ${props => (props.inTestimonialsCarousel ? props.theme.pxToRem(740) : '100%')};
    height: ${props => (props.inTestimonialsCarousel ? props.theme.pxToRem(260) : '100%')};
    ${props => props.inTestimonialsCarousel && `margin: 0 auto;`};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
    }
  }

  .vjs-tech {
    position: relative;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 0 ${props => props.theme.pxToRem(-15)} ${props => props.theme.pxToRem(30)};
  }
`;

const Video = styled.div<{ inTestimonialsCarousel?: boolean }>`
  margin: 0 0 ${props => props.theme.pxToRem(50)};
  position: relative;
  padding-bottom: ${props => (props.inTestimonialsCarousel ? props.theme.pxToRem(260) : '56.25%')};
  height: 0;
  overflow: hidden;
  iframe {
    width: ${props => (props.inTestimonialsCarousel ? props.theme.pxToRem(740) : '100%')};
    height: ${props => (props.inTestimonialsCarousel ? props.theme.pxToRem(260) : '100%')};
    ${props => props.inTestimonialsCarousel && `margin: 0 auto`};
    ${props => props.inTestimonialsCarousel && `object-fit: cover`};
    ${props => !props.inTestimonialsCarousel && `top: 0`};
    ${props => !props.inTestimonialsCarousel && `left: 0`};
    ${props => !props.inTestimonialsCarousel && `padding: 0 ${props.theme.pxToRem(1)} 0 0`};
    ${props => props.inTestimonialsCarousel && `display: block`};
    ${props => !props.inTestimonialsCarousel && `position: absolute`};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
    }
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 0 ${props => props.theme.pxToRem(-15)} ${props => props.theme.pxToRem(30)};
  }
`;

const Wrap = styled.div<{ isProductPage?: boolean }>`
  ${props =>
    props.isProductPage &&
    `margin-top: ${props.theme.pxToRem(40)}
`}
`;
