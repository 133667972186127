import { createAction, handleActions, Action } from 'redux-actions';
import { Content } from 'mxp-schemas';

// ------------------------------------
// Helpers
// ------------------------------------
const getInitialCategoriesHash = () =>
  Object.values(Content.CategorySlugs)
    // filter out static category
    .filter((slug: string) => slug !== Content.CategorySlugs.ABOUT)
    .reduce((acc: State.CategoriesHash, slug: string) => {
      acc[slug] = slug === Content.CategorySlugs.ALL ? { name: 'All', slug } : (acc[slug] = { name: '', slug });
      return acc;
    }, {});

// ------------------------------------
// Constants
// ------------------------------------
const initialState: State.Categories = {
  categoriesHash: getInitialCategoriesHash(),
  categoriesFetched: false,
};

// ------------------------------------
// Actions
// ------------------------------------
export const getCategoriesHash: any = createAction(
  'categories/GET_CATEGORIES_HASH',
  (categories: State.CategoriesHash) => () => {
    return { categories };
  }
);

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [getCategoriesHash]: {
    next: (state: State.Categories, action: Action<{ categories: State.CategoriesHash }>): State.Categories => ({
      ...state,
      categoriesHash: {
        ...state.categoriesHash,
        ...action.payload.categories,
      },
      categoriesFetched: true,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
