import React from 'react';
import styled from 'styled-components';

interface Props {
  flexDirection?: string; // row | row-reverse | column | column-reverse;
}

export const Flex: React.FC<Props> = ({ flexDirection, children, ...props }) => (
  <StyledFlex flexDirection={flexDirection} {...props}>
    {children}
  </StyledFlex>
);

interface FlexProps {
  flexDirection?: string; // row | row-reverse | column | column-reverse;
}

const StyledFlex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection};
`;
