import { Product } from 'mxp-schemas';

export const masterConferenceObjBuilder: any = (productItem: any) => {

	if(!productItem || Object.keys(productItem).length === 0 || productItem?.productType !== Product.ProductType.CONFERENCE) return null;

	const pricesArr: any = productItem?.variants && productItem?.variants[0]?.prices.length !== 0 ? productItem?.variants[0]?.prices.map((priceAttributes: any) => {
		return {
				"currency": priceAttributes.currency,
				"amount": priceAttributes.amount / 100,
				"channel": {
		  		"applicableUserRoles": [
						"nonMemberRole"
		  			],
		  			"id": priceAttributes.channel.id
				}
	  		}
		}
	) : [];
	
	const masterConferenceObj = [{
	  "id": productItem?.productId,
	  "slug": productItem?.slug,
	  "contentfulType": "product",
	  "contentType": {
		"name": "Product",
		"slug": "product"
	  },
	  "title": productItem?.name,
	  "dateCreated": productItem?.createdAt,
	  "contentCategory": {
		"name": 'CPE & Learning',
		"slug": 'cpe-learning'
	  },
	  "image": {
		"altText": "",
		"caption": "",
		"credit": "",
		"title": "",
		"url": productItem?.variants && productItem?.variants[0]?.images[0]?.imageUrl ? productItem?.variants[0]?.images[0]?.imageUrl : ""
	  },
	  "timeToConsume": null,
	  "credits": productItem?.variants && productItem?.variants[0]?.credits ?  productItem?.variants[0]?.credits : 0,
	  "productType": [productItem?.productType],
	  "availableFormat": [productItem?.variants && productItem?.variants[0]?.format ? productItem?.variants[0]?.format.key : ''],
	  "startDate":  productItem?.variants && productItem?.variants[0]?.startDateTime ? productItem?.variants[0]?.startDateTime : null,
	  "endDate": productItem?.variants && productItem?.variants[0]?.endDateTime ? productItem?.variants[0]?.endDateTime : null,
	  "prices": pricesArr,
	  "externalUrl": null,
	  "contentSource": null,
	  "level": productItem?.programLevel?.label ? [productItem?.programLevel?.label] : null,
	  "variantsCount": productItem?.variants ? productItem?.variants.length : 0,
	  "conferenceCity": "",
	  "conferenceState": "",
	  "downloads": [],
	  "roles": [
		"loggedInRole"
	  ],
	  "matchedRoles": [],
	  "isLocked": "locked",
	  "parentProductInfo": {
		"sessions": null,
		"upcomingDates": null
	  },
	  "restrictionDetails": null,
	  "topicalSubscriptions": null,
	  "bundleInfo": null,
	  "fundName": null,
	  "applyFreeTrial": false
	}];

	return masterConferenceObj;
  }
