import React from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { IC_CANCEL, IC_EDIT, IC_VIEW } from 'resources/images';
import { Grid } from 'components/atoms';
import { Label } from 'semantic-ui-react';
import { MembershipTypes } from 'mxp-schemas';
import moment from 'moment-timezone';

interface ReviewStatus {
  label: string;
  status?: string;
  uln?: string;
  submittedDate?: any;
  module?: string;
  isPerDashboard: boolean;
  onEdit: () => void;
  onView: () => void;
}

// tslint:disable-next-line:no-shadowed-variable
const Item = ({ isNotWithdrawn, children }: { isNotWithdrawn: boolean; children: JSX.Element }) => {
  const StyledItem = styled.div`
    padding: ${props => `${props.theme.pxToRem(20)}`};
    box-shadow: ${props => `0 0 ${props.theme.pxToRem(10)} 0 ${props.theme.colors.neutralGrey3}`};
    width: 100%;
    margin-bottom: ${props => `${props.theme.pxToRem(25)}`};
    border-radius: ${props => props.theme.pxToRem(8)};
    display: flex;
    align-items: center;
    background-color: ${props => (isNotWithdrawn ? 'none' : props.theme.colors.neutralGrey2)};
    border: ${props =>
      isNotWithdrawn ? 'none' : `solid ${props.theme.pxToRem(1)} ${props.theme.colors.neutralGrey7}`};
  `;

  return <StyledItem>{children}</StyledItem>;
};

export const CimaReview: React.FC<ReviewStatus> = ({
  label,
  status,
  uln,
  submittedDate,
  module,
  isPerDashboard,
  onEdit,
  onView,
}) => {
  status = status?.toUpperCase();
  const Status = MembershipTypes.MembershipReviewStatus;
  const isNotWithdrawn = status !== Status.WITHDRAWN;
  const isEditVisible = isNotWithdrawn;
  const isNotApproved = isNotWithdrawn && status !== Status.APPROVED;
  const isNotCompleted = isNotWithdrawn && status !== Status.COMPLETED;
  const isNotRequestInfo = isNotWithdrawn && status !== Status.REQUEST_FURTHER_INFORMATION;
  const isViewVisible = isNotApproved && isNotCompleted && isNotRequestInfo;

  const EditHandler = React.useCallback(() => {
    onEdit();
  }, [onEdit]);

  const ViewHandler = React.useCallback(() => {
    onView();
  }, [onView]);

  return (
    <Item isNotWithdrawn={isNotWithdrawn}>
      <StyledGrid>
        <StyledGridRow>
          <StyledGridColumn width={12}>
            <StyledRecordLabel>{label}</StyledRecordLabel>
            {uln && (
              <>
                <StyledDivider>|</StyledDivider> ULN: {uln}
              </>
            )}
            {module && (
              <>
                <StyledDivider>|</StyledDivider> {module}
              </>
            )}
            {isEditVisible && (
              <StyledButtonLink
                size={ButtonEnums.sizes.small}
                variant={ButtonEnums.variants.white}
                testId={`per-record-${label}-edit`}
                onClick={EditHandler}
                to=""
              >
                <StyledIcon
                  src={
                    status === Status.COMPLETED ||
                    status === Status.APPROVED ||
                    status === Status.REQUEST_FURTHER_INFORMATION
                      ? IC_VIEW
                      : IC_EDIT
                  }
                />
                {status === Status.COMPLETED ||
                status === Status.APPROVED ||
                status === Status.REQUEST_FURTHER_INFORMATION
                  ? 'View'
                  : status === Status.IN_PROGRESS || status === Status.DRAFT
                  ? 'Continue review'
                  : 'Begin review'}
              </StyledButtonLink>
            )}

            {isViewVisible && (
              <StyledButtonLink
                size={ButtonEnums.sizes.small}
                variant={ButtonEnums.variants.white}
                testId={`per-record-${label}-withdraw`}
                onClick={ViewHandler}
                to=""
              >
                <StyledIcon src={IC_CANCEL} />
                {isPerDashboard ? 'Withdraw from review' : 'Decline review'}
              </StyledButtonLink>
            )}
          </StyledGridColumn>
          <StyledGridColumn width={4} data-testid="perItemStatus">
            {status === Status.WITHDRAWN && (
              <StyledLabel name="grey" horizontal>
                WITHDRAWN
              </StyledLabel>
            )}
            {status === Status.TO_BE_REVIEWED && (
              <StyledLabel name="blue" horizontal>
                TO BE REVIEWED
              </StyledLabel>
            )}
            {(status === Status.IN_PROGRESS || status === Status.DRAFT) && (
              <StyledLabel name="yellow" horizontal>
                IN PROGRESS
              </StyledLabel>
            )}
            {status === Status.OVERDUE && (
              <StyledLabel name="red" horizontal>
                OVERDUE
              </StyledLabel>
            )}
            {(status === Status.COMPLETED || status === Status.APPROVED) && (
              <StyledLabel name="green" horizontal>
                Approved
              </StyledLabel>
            )}
            {status === Status.REQUEST_FURTHER_INFORMATION && (
              <StyledLabel name="orange" horizontal>
                {isPerDashboard ? 'Requested further information' : 'Rejected'}
              </StyledLabel>
            )}
          </StyledGridColumn>
        </StyledGridRow>
        {submittedDate && (
          <StyledGridRow>
            <StyledDescription>
              Submitted:
              {` ${moment(submittedDate).format('DD MMMM, YYYY')}`}
            </StyledDescription>
          </StyledGridRow>
        )}
      </StyledGrid>
    </Item>
  );
};

const StyledDivider = styled.span`
  margin-right: ${props => props.theme.pxToRem(12)};
  margin-left: ${props => props.theme.pxToRem(12)};
`;

const StyledRecordLabel = styled.span`
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledLabel = styled(Label)<ThemedStyledProps<{ name?: string }, DefaultTheme>>`
  &&&& {
    text-transform: uppercase;
    color: ${props => props.theme.colors.neutralWhite};
    font-size: ${props => props.theme.fontSizes.xs};
    background: ${props => {
      switch (props.name) {
        case 'blue':
          return props.theme.colors.secondaryDarkBlue;
        case 'grey':
          return props.theme.colors.neutralGrey6;
        case 'yellow':
          return props.theme.colors.secondaryYellow;
        case 'red':
          return props.theme.colors.interfaceRed;
        case 'green':
          return props.theme.colors.secondaryTeal;
        case 'orange':
          return props.theme.colors.secondaryOrange;
        default:
          return props.theme.colors.neutralGrey5;
      }
    }} !important;
  }
`;

const StyledIcon = styled.img`
  margin-right: ${props => `${props.theme.pxToRem(5)}`};
`;

const StyledGrid = styled(Grid)`
  &&&&&& {
    margin: 0;
    width: 100%;
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&& {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const StyledGridColumn = styled(Grid.Row)`
  &&&& {
    display: flex;
  }
  display: flex;
  align-items: center;
`;

const StyledDescription = styled.span`
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralGrey7};
`;

const StyledButtonLink = styled(Button)`
  &&&&&&&& {
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(16)}`};
    margin: 0 ${props => `${props.theme.pxToRem(5)}`};
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }
  }
`;
