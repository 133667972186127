import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';
import { Container, Grid, Divider } from 'components/atoms';
import { useHistory, useParams } from 'react-router';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { CimaExamResultsSectionPanel } from 'components/molecules/CimaExamResultsSectionPanel';
import { CimaExamResultsSummary } from 'components/molecules/CimaExamResultsSummary';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { ReactComponent as Arrow } from 'resources/images/icon-ui-icons-ic-arrow-back.svg';
import { Footer } from 'components/molecules';
import { User, MembershipTypes } from 'mxp-schemas';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';

interface Props {
  loading: boolean;
  studentExamResults: User.StudentExamSectionResults;
  pathway: MembershipTypes.Pathway | null | undefined;
  getStudentExamSectionResults: (slug: string, pathway: string) => void;
  resetStudentExamSectionResults: () => void;
}

export const CimaExamResults: React.FC<Props> = ({
  loading,
  studentExamResults,
  pathway,
  getStudentExamSectionResults,
  resetStudentExamSectionResults,
}) => {
  const history = useHistory();
  const { slug }: { slug: string } = useParams();

  useEffect(() => {
    getStudentExamSectionResults(slug, pathway as string);

    return () => resetStudentExamSectionResults();
  }, [slug, getStudentExamSectionResults, resetStudentExamSectionResults, pathway]);

  const onButtonClick = useCallback(
    (event?: any) => {
      const destination = getPath(Routes.CIMA_EXAMS_DASHBOARD_ROOT);
      const { pageName, siteSection } = getPageNameAndSiteSection();
      const buttonText = event?.target?.innerText || event?.target?.textContent || '';

      handleEvent(
        {
          clickValue: `button:link:int::${buttonText}:${destination}`,
          pageName,
          siteSection,
        },
        NAV_CLICK
      );

      history.push(destination);
    },
    [history]
  );
  return (
    <>
      <HeaderPageWrapper>
        <StyledContainer>
          <Grid stackable>
            <Grid.Row stretched>
              {loading ? (
                <Loader active>Loading</Loader>
              ) : (
                studentExamResults && (
                  <StyledGridColumn tablet={16} computer={16} floated="left">
                    <Header>
                      <StyledBackArrowContainer onClick={onButtonClick}>
                        <StyledBackText>
                          <StyledArrow />
                          Go to my exam dashboard
                        </StyledBackText>
                      </StyledBackArrowContainer>
                      <ExamText>{studentExamResults.examName}</ExamText>
                    </Header>
                    {/* <StyledDividerLine />
                  <CimaExamResultsBanner bannerData={studentExamResults} candidateName={candidateName} /> */}
                    <StyledDividerLine />
                    <CimaExamResultsSummary data={studentExamResults} />
                    <StyledDividerLine />
                    {(studentExamResults.status === User.StudentExamResultStatus.PASS ||
                      studentExamResults.status === User.StudentExamResultStatus.FAIL) &&
                    !studentExamResults.epaDetails.isEpa2 ? (
                      <CimaExamResultsSectionPanel data={studentExamResults} />
                    ) : null}
                    <Spacer />
                  </StyledGridColumn>
                )
              )}
            </Grid.Row>
          </Grid>
        </StyledContainer>
        <Footer />
      </HeaderPageWrapper>
    </>
  );
};

const StyledBackArrowContainer = styled.div`
  height: auto;
  margin: 0 0 ${props => props.theme.pxToRem(23)};
  object-fit: contain;
  margin-top: ${props => props.theme.pxToRem(44)};
  cursor: pointer;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(10)};
    margin-right: ${props => props.theme.pxToRem(10)};
    position: relative;
    left: ${props => props.theme.pxToRem(-20)};
    width: 100%;
  }
`;

const StyledBackText = styled.div`
  height: ${props => props.theme.pxToRem(22)};
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: 300;
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralGrey8};
  &&&&:hover {
    text-decoration: underline;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const Spacer = styled(Container)`
  height: ${props => props.theme.pxToRem(141)};
`;

const StyledArrow = styled(Arrow)`
  position: relative;
  top: ${props => props.theme.pxToRem(4)};
`;

const StyledDividerLine = styled(Divider)`
  &.ui.divider {
    margin-left: auto;
    margin-right: auto;
  }
  width: ${props => props.theme.pxToRem(1123)};
  height: ${props => props.theme.pxToRem(2)};
  object-fit: contain;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: auto;
  }
`;

const Header = styled(Container)``;

const ExamText = styled.div`
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(32)};
  font-weight: 300;
  line-height: 1.38;
  letter-spacing: normal;
  margin-bottom: ${props => props.theme.pxToRem(40)}
  color: ${props => props.theme.colors.neutralGrey8};
   ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(10)};
    margin-right: ${props => props.theme.pxToRem(10)};
    position: relative;
    left: ${props => props.theme.pxToRem(-20)};
    width: 100%;
  }
`;

const StyledContainer = styled.div``;

const StyledGridColumn = styled(Grid.Column)`
  padding-top: ${props => props.theme.pxToRem(15)};

  &&&&&& {
    display: block !important;
  }
`;
