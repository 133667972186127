import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RemoveClientAdminsModal } from 'components/admin/pages/OrganizationProfile/RemoveClientAdminsModal';
import { setSwapAdminModalOpen, swapAdmins, removeAdmin } from 'modules/admin/actions';
import {
  adminToRemoveSelector,
  swapAdminModalLoadingSelector,
  swapAdminModalOpenSelector,
  adminToRemoveHasRelatedEntitiesSelector,
  isCheckForRelatedEntitiesLoadingSelector,
} from 'modules/admin/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  swapAdmins(adminToSwapEmail: string): void {
    dispatch(swapAdmins(adminToSwapEmail));
  },

  removeAdmin(): void {
    dispatch(removeAdmin());
  },

  closeSwapAdminModal(): void {
    dispatch(setSwapAdminModalOpen(false));
  },
});

const mapStateToProps = (state: State.Root) => {
  return {
    visible: swapAdminModalOpenSelector(state),
    loading: swapAdminModalLoadingSelector(state),
    adminToRemove: adminToRemoveSelector(state),
    hasRelatedEntities: adminToRemoveHasRelatedEntitiesSelector(state),
    isCheckForRelatedEntitiesLoading: isCheckForRelatedEntitiesLoadingSelector(state),
  };
};

export const RemoveClientAdminsModalContainer = connect(mapStateToProps, mapActionCreators)(RemoveClientAdminsModal);
