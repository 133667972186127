import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  fileName: string;
  fileType: string;
  isOpenLink?: boolean;
  isPreviewHidden?: boolean;
  isOpenRemoveModal: () => void;
  previewDocument?: () => void;
}

export const CimaFileUploadTable: React.FC<Props> = ({
  title,
  fileType,
  fileName,
  isOpenLink,
  isOpenRemoveModal,
  previewDocument,
  isPreviewHidden,
}) => {
  const DisplayIconFile = (file: any) => {
    switch (file) {
      case 'image/png':
        return '/cima-images.png';
      case 'image/jpeg':
        return '/cima-images.png';
      case 'application/pdf':
        return '/cima-pdf.png';
      case 'application/msword':
        return '/cima-docs.png';
      case 'applicat3ion/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '/cima-docs.png';
      default:
        return '/cima-images.png';
    }
  };

  return (
    <StyledUploadTable>
      <StyledTitleSpan>{title}</StyledTitleSpan>
      <StyledTableList>
        <StyledItemTableDiv
          isOpenLink={isOpenLink}
          isPreviewHidden={isPreviewHidden}
          onClick={!isPreviewHidden || !isOpenLink ? previewDocument : undefined}
        >
          <IconImg src={DisplayIconFile(fileType)} alt="icon" />
          <StyledFileListSpan>{fileName}</StyledFileListSpan>
        </StyledItemTableDiv>
        <StyledDeleteSpan onClick={isOpenRemoveModal}>Delete</StyledDeleteSpan>
      </StyledTableList>
    </StyledUploadTable>
  );
};

const StyledUploadTable = styled.div`
  border-radius: ${props => props.theme.pxToRem(10)}
  border-bottom: 0 !important;
  background-color: ${props => props.theme.colors.neutralGrey1};
  padding: ${props => props.theme.pxToRem(50)};
  margin-top: ${props => props.theme.pxToRem(20)};
  width: 100%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(40)} ${props => props.theme.pxToRem(15)};
  }
`;

const StyledItemTableDiv = styled.div<{ isPreviewHidden?: boolean; isOpenLink?: boolean }>`
  cursor: ${props => (props.isPreviewHidden ? 'default' : props.isOpenLink ? 'pointer' : 'default')};
  align-content: center;
  text-align: center;
  display: flex;
  margin-top: ${props => props.theme.pxToRem(20)};
`;

const StyledTableList = styled.div`
  width: 95%;
  color: ${props => props.theme.colors.primaryDarkPurple};
  padding-left: 0;
  font-weight: initial;
  justify-content: space-between;
  align-content: center;
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.pxToRem(16)};
`;

const IconImg = styled.img`
  width: ${props => props.theme.pxToRem(25)};
  height: ${props => props.theme.pxToRem(25)};
  margin-right: ${props => props.theme.pxToRem(10)};
`;

const StyledTitleSpan = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.m};
`;

const StyledFileListSpan = styled.span`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.m};
`;

const StyledDeleteSpan = styled.span`
  text-decoration: underline;
  float: right;
  cursor: pointer;
`;
