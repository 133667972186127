import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

import { ShareAllocationActionNames } from './constants';

import { createMatchSelector, replace } from 'connected-react-router';
import { emptyObject, getPath } from 'utils';
import { Routes } from 'constants/index';
import request from 'utils/GraphQLClient';
import { PROVISION_SEAT } from 'mxp-graphql-queries';
import { User } from 'mxp-schemas';
// ------------------------------------
// Actions
// ------------------------------------

export const allocate: any = createAction(
  ShareAllocationActionNames.ALLOCATE,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setProcessing(true));
    const state: State.Root = getState();
    const match: any = createMatchSelector(getPath(Routes.SHARE_ALLOCATION))(state as State.Root);
    const { token } = (match && match.params) || emptyObject;
    const extractedToken = (token || '').split('?')[0]; // make sure we are discarding rest of the params
    return request(PROVISION_SEAT, { inviteToken: extractedToken, type: User.ProvisioningTypes.B2B }).then(
      (response: any) => {
        dispatch(replace(getPath(Routes.PROFILE_PURCHASES)));
        return response.provisionSeat;
      }
    );
  }
);

export const setProcessing: any = createAction(
  ShareAllocationActionNames.SET_PROCESSING,
  (processing: boolean) => () => {
    return processing;
  }
);

export const setError: any = createAction(
  ShareAllocationActionNames.SET_ERROR,
  (error: CustomErrors.GraphQLError) => () => {
    return error;
  }
);
