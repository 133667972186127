import React from 'react';
import { Modal } from 'components/atoms/Modal/Modal';
import { ButtonEnums, Button } from 'components/atoms/Button/Button';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import styled, { css } from 'styled-components';
import { ReactComponent as InfoBubble } from 'resources/images/ic-unsuccessful.svg';

interface Props {
  open: boolean;
  header: string;
  testId: string;
  onCancel?: () => void;
  cancelButtonLabel?: string;
  onConfirm?: () => void;
  confirmButtonLabel?: string;
  description?: string;
  className?: string;
  onCancelLink?: string;
  descriptionLoading?: boolean;
  isConfirmationInProgress?: boolean;
  descriptionNode?: () => React.ReactNode;
}

export const WarningModal: React.FC<Props> = ({
  open,
  header,
  testId,
  onCancel,
  cancelButtonLabel,
  onConfirm,
  confirmButtonLabel,
  description,
  className,
  onCancelLink,
  isConfirmationInProgress,
  descriptionNode,
}) => {
  const isModalWithCancelAndConfirm: boolean = (Boolean(onCancel) || Boolean(onCancelLink)) && Boolean(onConfirm);

  const renderModalWithCancelAndConfirm = (): React.ReactNode => (
    <Modal
      className={className}
      open={open}
      size="tiny"
      heading={header}
      testId={testId}
      onClose={onCancel}
      icon={<StyledInfoBubble />}
      cancelNode={
        <StyledButtonLink
          className="cancel"
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          testId="cancel"
          onClick={onCancel}
          to={onCancelLink || ''}
        >
          {cancelButtonLabel}
        </StyledButtonLink>
      }
      confirmNode={
        <StyledButton
          className="confirm"
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          testId="confirm"
          onClick={onConfirm}
          disabled={isConfirmationInProgress}
          loading={isConfirmationInProgress}
        >
          {confirmButtonLabel}
        </StyledButton>
      }
    >
      {descriptionNode ? descriptionNode() : <StyledText>{description}</StyledText>}
    </Modal>
  );

  const renderModalWithoutCancelAndConfirm = (): React.ReactNode => (
    <Modal
      className={className}
      open={open}
      size="small"
      heading={header}
      testId={testId}
      onClose={onCancel}
      icon={<StyledInfoBubble />}
      showCloseCross
    >
      {descriptionNode ? descriptionNode() : <StyledText>{description}</StyledText>}
    </Modal>
  );

  return <>{isModalWithCancelAndConfirm ? renderModalWithCancelAndConfirm() : renderModalWithoutCancelAndConfirm()}</>;
};

const buttonCss = css`
  &&&&& {
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};
    margin: 0 ${props => `${props.theme.pxToRem(5)}`};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  ${buttonCss}
`;

const StyledButton = styled(Button)`
  ${buttonCss}
`;

const StyledText = styled.p`
  line-height: 1.57;
  text-align: center;
  max-width: ${props => props.theme.pxToRem(300)};
  margin: 0 auto;
`;

const StyledInfoBubble = styled(InfoBubble)`
  fill: ${props => props.theme.colors.interfaceRed};
`;
