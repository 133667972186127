import styled from 'styled-components';
import { Menu as SemanticUIMenu } from 'semantic-ui-react';

const StyledSemanticUIMenu: any = styled(SemanticUIMenu)`
  &&&.ui.secondary.pointing.menu {
    border-bottom: solid 1px ${props => props.theme.colors.neutralGrey3};
    margin-bottom: ${props => props.theme.pxToRem(13)};

    .item {
      background: transparent;
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.m};
      font-weight: ${props => props.theme.fontWeights.light};
      padding-bottom: ${props => props.theme.pxToRem(10)};
      padding-left: ${props => props.theme.pxToRem(0)};
      padding-right: ${props => props.theme.pxToRem(0)};
      margin-right: ${props => props.theme.pxToRem(60)};
    }

    .active {
      font-size: ${props => props.theme.fontSizes.m};
      font-weight: ${props => props.theme.fontWeights.medium};
      color: ${props => props.theme.colors.primaryPurple};
      background: ${props => props.theme.colors.neutralWhite};
      border-color: ${props => props.theme.colors.primaryPurple};

      .item {
        background: transparent;
        color: ${props => props.theme.colors.primaryPurple};
        border-color: ${props => props.theme.colors.primaryPurple};
      }
    }
  }
`;

StyledSemanticUIMenu.Item = SemanticUIMenu.Item;
StyledSemanticUIMenu.Header = SemanticUIMenu.Header;

export const Menu = StyledSemanticUIMenu;
