import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import {
  fetchExemptionEngine,
  setSelectedExemption,
  setSelectedExemptReferenceLevel,
  setPrevSelectedExemptReference,
} from './action';
import { isServer } from 'utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchExemptionEngine]: {
    next: (state: State.ExemptionEngineResult, action: any): State.ExemptionEngineResult => ({
      ...state,
      exempReference: action.payload.exemptionEngine,
      exemptReferenceFetched: true,
      exemptReferenceFrontloadRequest: isServer,
    }),
  },
  [setSelectedExemption]: (state: State.ExemptionEngineResult, action: any) => ({
    ...state,
    selectedExemptReference: action.payload,
  }),
  [setSelectedExemptReferenceLevel]: (state: State.ExemptionEngineResult, action: any) => ({
    ...state,
    selectedExemptReferenceLevel: action.payload,
  }),
  [setPrevSelectedExemptReference]: (state: State.ExemptionEngineResult, action: any) => ({
    ...state,
    prevSelectedExemptReference: action.payload,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
