import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Content, Product, Orders, User, Checkout, Contentful, MembershipTypes as Membership } from 'mxp-schemas';
import TruncateMarkup from 'react-truncate-markup';
import {
  currentCredentialProducts,
  hasActiveMigratedAcmaAndCgmaCredentialsSelector,
  isCimaMembershipLapsedSelector,
  isCimaRenewalSeasonSelector,
  activeMembershipSubscriptionSelector,
} from 'modules/membership/selectors';
import {
  hasMembershipProductSelector,
  cartDetailItemsSelector,
  isAllowToRemoveFcmaOnlyInCartSelector,
} from 'modules/cart/selectors';
import { hasDelayedBenefitProductSelector, productsDetailHasRejoiningItemSelector } from 'modules/products/selectors';
import { ProductThumbnail, ProductThumbSize } from 'components/molecules/ProductThumbnail/ProductThumbnail';
import { RemoveBundleComponentModal } from 'components/molecules/RemoveBundleComponentModal/RemoveBundleComponentModal';
import {
  Button,
  ButtonEnums,
  Input,
  Link,
  LinkEnums,
  OnlyDesktop,
  OnlyMobile,
  NotificationBanner,
  NotificationBannerEnums,
  OnlyDesktopCSS,
  Dropdown,
} from 'components/atoms';
import {
  CancelOrRefundProductModal,
  ActiveCredentialWarningModal,
  ConfirmationDetailsModal,
  SuccessModal,
  ConfirmationRemoveModal,
} from 'components/molecules';
import {
  formattedPriceToDecimal,
  priceToFloat,
  productTypeToSlug,
  donationTypeToSlug,
  getDiscontinuedProductTypeFromFormat,
  getPath,
  isMobileViewPort,
  areAllTruthy,
} from 'utils';
import { cancelRefundOrder } from 'modules/admin/actions';
import { abandonApplication } from 'modules/user/actions';
import { updateUnpublishedVariant } from 'modules/cart/actions';
import { unappliedPaymentIdSelector } from 'modules/admin/selectors';
import {
  quoteStatusAndIsMembershipPaidByFirmSelector,
  userRolesSelector,
  learningPathwaySelector,
} from 'modules/user/selectors';
import { handleEvent, CART_REMOVE_EVENT } from 'utils/Analytics';
import {
  CartCompoundAnalytics,
  getOrderTypeAnalytics,
  isB2BMembershipType,
  isB2CMembershipType,
} from 'utils/Analytics/helpers';
import { isActive, isEventInNHours } from 'modules/products/helpers';
import { ProductsListParent } from '../ProductsList/ProductsList';
import { ProductItemButton } from './ProductItemButton';
import { ProductItemDetails } from './ProductItemDetails';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { Routes, ExtLinks } from 'constants/index';
import { CART_UPDATE_EVENT, EVENT_CLICK } from 'utils/Analytics/constants';
import { isPhysicalProduct as isPhysicalProductCheck, Product as ProductUtils } from 'mxp-utils';
import { useLocation, useHistory } from 'react-router';
import { updateCancelledProduct } from 'modules/products/actions';
import { maxNumberOfExperienceDataSelector } from 'modules/personLevelExemption';
import { constantsSelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { resetUserChoice } from 'modules/membership/actions';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_CR_682 } from 'modules/featureToggle/constants';

interface Props {
  item: Common.ProductItemData;
  cmsProductData?: Contentful.ModularContent.ContentfulProduct;
  className?: string;
  parentPage: ProductsListParent;
  conferenceUserInfo?: Checkout.ConferenceUserInfo | null;
  userEmail?: string;
  fvsMembership?: User.MembershipIdsEnum[] | [];
  taxMembership?: User.MembershipIdsEnum[] | [];
  nfpMembership?: User.MembershipIdsEnum[] | [];
  pfpMembership?: User.MembershipIdsEnum[] | [];
  oktaId?: string;

  isAdminPortal?: boolean;
  showQuantity?: boolean;
  showUpdateButtons?: boolean;
  productItemId?: string;
  renderAsBundleItem?: boolean;
  isRegionalPathwayCart?: boolean | null;
  mipProductPrice?: string;
  navigate?: (payload: Common.PushPayload) => void;
  removeCartItems?: (ids: string[]) => void;
  accessItem?: (item: Common.ProductItemData) => void;
  deprovision?: (orderId: string, sku: string) => void;
  updateCartItem?: (quantity: number, standingOrder: boolean, lineItemId: string, donationPrice?: string) => void;
  setShowUpdateButtons?: (show: boolean) => void;
  setProductItemId?: (product: string) => void;
  setDisableCheckoutButton?: (disable: boolean) => void;
  setCancelSOrderModalMeta?: (data: State.CancelSOrderModalMeta) => void;
  toggleSubscriptionRenewal?: (enable: boolean, item: Common.ProductItemData, productName: string) => void;
  getRenewalSeason?: () => void;
  getMipRenewalSeasonStartDate?: () => void;
  getMipRenewalSeasonEndDate?: () => void;
}

export const ProductItem: React.FC<Props> = ({
  item,
  cmsProductData,
  className,
  parentPage,
  conferenceUserInfo,
  userEmail,
  fvsMembership,
  taxMembership,
  nfpMembership,
  pfpMembership,
  oktaId,
  isAdminPortal,
  showUpdateButtons,
  productItemId,
  renderAsBundleItem = false,
  isRegionalPathwayCart = false,
  mipProductPrice,
  navigate,
  removeCartItems,
  accessItem,
  deprovision,
  updateCartItem,
  setShowUpdateButtons,
  setProductItemId,
  setDisableCheckoutButton,
  setCancelSOrderModalMeta,
  toggleSubscriptionRenewal,
  getRenewalSeason,
  getMipRenewalSeasonStartDate,
  getMipRenewalSeasonEndDate,
}) => {
  const {
    availability,
    dateRange,
    formattedBasePrice: basePrice,
    formattedPrice: price,
    coverSrc,
    title,
    subtitle,
    productType,
    availableFormat,
    subscriptionProductType,
    sku,
    lineItemId,
    productLink,
    productId,
    domainString,
    showQuantity,
    availableQuantity = 0,
    quantity,
    custom,
    cartLineItemState,
    orderDate,
    isFreeTrial,
    freeTrialTerm,
    webcastAccessLink,
    subscriptionStatus,
    variantProductPublished = true,
    otherVariants = [],
  } = item;

  const location = useLocation();
  const history = useHistory();
  const [isRemoveBundleComponentModalOpen, setRemoveBundleComponentModalOpen] = useState(false);
  const [isEditContributionAmountToggled, setIsEditContributionAmountToggled] = useState(false);
  const [contributionAmount, setContributionAmount] = useState('');

  // cancellation and refund state
  const dispatch = useDispatch();
  const credentialProducts = useSelector(currentCredentialProducts);
  const currentMemProductStatus = useSelector(quoteStatusAndIsMembershipPaidByFirmSelector);
  const cartDetailItems = useSelector(cartDetailItemsSelector);
  const hasMembershipProduct = useSelector(hasMembershipProductSelector);
  const paymentId = useSelector(unappliedPaymentIdSelector);
  const maxNumberOfExp = useSelector(maxNumberOfExperienceDataSelector);
  const constants = useSelector(constantsSelector);
  const userRoles = useSelector(userRolesSelector);
  const hasActiveMigratedAcmaAndCgmaCredentials = useSelector(hasActiveMigratedAcmaAndCgmaCredentialsSelector);
  const learningPathway = useSelector(learningPathwaySelector);
  const useCR862FcmaRenewalEnhancement = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_CR_682)
  );
  const isAllowToRemoveFcmaOnlyInCart = useSelector(isAllowToRemoveFcmaOnlyInCartSelector);
  const isAllowToRemoveFcma = areAllTruthy(
    useCR862FcmaRenewalEnhancement,
    isAllowToRemoveFcmaOnlyInCart,
    sku === Product.CIMA_CREDENTIALS_SKU.FCMA
  );
  const activeMembership = useSelector(activeMembershipSubscriptionSelector);
  const isCimaRenewalSeason = useSelector(isCimaRenewalSeasonSelector);
  // Check if user is on membership reactivation journey
  const userHasRejoinProduct = useSelector(productsDetailHasRejoiningItemSelector);
  const isCimaMembershipLapsed = useSelector(isCimaMembershipLapsedSelector);
  const isMembershipReactivationJourney = userHasRejoinProduct && isCimaMembershipLapsed;

  const [cancelRefundData, setCancelRefundData] = useState({
    amountToMove: '',
    reason: '',
    refundAmount: '',
    requiredAction: '',
    typeOfCancellation: '',
    typeOfCancellationReason: '',
    comment: '',
    isCalculateProrationClicked: false,
  });
  const [isCancelRefundModalOpen, setCancelRefundModalOpen] = useState(false);
  const [isActiveCredentialWarningModalOpen, setActiveCredentialWarningModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isConfirmationRemoveModalOpen, setConfirmationRemoveModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRefund, setIsRefund] = useState(false);

  const [subscriptionAmountAdded, setSubscriptionAmountAdded] = useState<
    Array<{ subscriptionNumber: string; amount: number }>
  >([]);

  const isMobile = isMobileViewPort();
  const isCartPage: boolean = parentPage === ProductsListParent.CART_PAGE;
  const isProfilePurchasesPage = parentPage === ProductsListParent.PURCHASES_PROFILE;
  const isOrderConfirmationPage: boolean = parentPage === ProductsListParent.ORDER_CONFIRMATION;
  const viewTextForAnalytics: string = isProfilePurchasesPage
    ? 'profile-purchases'
    : isOrderConfirmationPage
    ? 'order-confirmation'
    : '';

  // product type checks
  const isCenterMembership: boolean = productType === Product.ProductType.CENTER_MEMBERSHIP;
  const isCertificate: boolean = productType === Product.ProductType.CERTIFICATE;
  const isCredential: boolean = productType === Product.ProductType.CREDENTIAL;
  const isMembership: boolean = productType === Product.ProductType.MEMBERSHIP;
  const isSection: boolean = productType === Product.ProductType.SECTION;
  const isConference: boolean = productType === Product.ProductType.CONFERENCE;
  const isWebcast: boolean = productType === Product.ProductType.WEBCAST;
  const isPublication: boolean = productType === Product.ProductType.PUBLICATION;
  const isExam: boolean = productType === Product.ProductType.EXAM;
  const isSubscription: boolean = productType === Product.ProductType.SUBSCRIPTION;
  const isCourse: boolean = productType === Product.ProductType.COURSE;
  const isOPL: boolean = Boolean(
    isSubscription &&
      subscriptionProductType?.key === Product.SubscriptionProductType.PUBLICATION &&
      (showQuantity === undefined || !showQuantity)
  );
  const isPremiumContent: boolean = Boolean(
    isSubscription && subscriptionProductType?.key === Product.SubscriptionProductType.CONTENT
  );
  const isDiscontinued: boolean = productType === Product.ProductType.DISCONTINUED;
  const isFlpType: boolean = productType === Product.ProductType.FLP;
  const isPQ = learningPathway === Membership.Pathway.PQ;
  const isFeeType: boolean = productType === Product.ProductType.FEE;

  // physical product checks
  const isCoursePhysicalProduct: boolean = isCourse && availableFormat?.key === Product.AvailableFormat.TEXT;
  const isPublicationPhysicalProduct: boolean =
    isPublication &&
    [Product.AvailableFormat.PAPERBACK, Product.AvailableFormat.HARDCOVER].includes(
      availableFormat?.key as Product.AvailableFormat
    );

  const isSubscriptionPhysicalProduct: boolean = isPhysicalProductCheck(item).isPhSubscriptionProduct;
  const isAnyPhysicalProduct: boolean =
    isPublicationPhysicalProduct || isCoursePhysicalProduct || isSubscriptionPhysicalProduct;

  const isActiveProduct: boolean = isActive(item, new Date());
  const isContribution: boolean = productType === Product.ProductType.CONTRIBUTION;
  const isExamCreditType: boolean = productType === Product.ProductType.EXAM_CREDIT;
  const isEventInNext48Hours: boolean = isEventInNHours(item, 48);
  const hasWebcastLink: boolean = Boolean(webcastAccessLink);

  const isAccessNowDisabled = isCertificate || (isWebcast && hasWebcastLink && !isEventInNext48Hours);

  // available format checks
  const scheduledType: string = Product.AvailableFormat.SCHEDULED.toUpperCase();
  const availableFormatLabel: string = availableFormat?.label.toUpperCase() || '';
  const isExamScheduled: boolean = Boolean(isExam && availableFormatLabel === scheduledType);

  const hasDomainString = Boolean(domainString);

  // physical product line item state checks
  const isAwaitingShipment: boolean = cartLineItemState === Orders.LineItemStates.AWAITING_SHIPMENT;
  const isAnyPhysicalProductAwaitingShipment: boolean = isAnyPhysicalProduct && isAwaitingShipment;
  const isPhSubscriptionShipped: boolean =
    cartLineItemState === Orders.LineItemStates.SHIPPED && isSubscriptionPhysicalProduct;

  // Added header status for FCMA/ACMA credentials - CR24
  const isDelayedBenefitProduct = useSelector(hasDelayedBenefitProductSelector);
  const isDelayedBenefitStatusActive: boolean =
    cartLineItemState === Orders.LineItemStates.PROVISIONED || subscriptionStatus === 'Active';
  const isDelayedBenefitStatusPending: boolean = cartLineItemState === Orders.LineItemStates.PENDING_PROVISION;

  const link = isContribution
    ? `/${Content.CategorySlugs.DONATIONS}/${donationTypeToSlug(productType as Product.ProductType)}/${productLink}`
    : `/${Content.CategorySlugs.CPE_LEARNING}/${
        subscriptionProductType?.key || productTypeToSlug(productType as Product.ProductType)
      }/${productLink}`;

  const subscriptionProductTypeValue: Product.SubscriptionProductType = (subscriptionProductType?.key ||
    '') as Product.SubscriptionProductType;

  const isStandingOrderOptIn: boolean =
    (custom?.fields?.standing_order_discount as any) === Product.StandingOrderEligible.STANDING_NEW;

  const extendAccessForSubscription = [
    Product.SubscriptionProductType.WEBCAST,
    Product.SubscriptionProductType.COURSE,
    Product.SubscriptionProductType.PUBLICATION,
    Product.SubscriptionProductType.CONTENT,
  ];
  const isExtendAccess =
    !isFreeTrial &&
    ((isSubscription && extendAccessForSubscription.includes(subscriptionProductTypeValue)) ||
      (isPublication && availableFormat?.key === Product.AvailableFormat.EBOOK) ||
      (isCourse && availableFormat?.key === Product.AvailableFormat.ONLINE)) &&
    availability === 365;

  const [productQty, setProductQty] = useState({ productQty: quantity!, isEmpty: false });
  const [productStandingOrder, setProductStandingOrder] = useState({ productStandingOrder: isStandingOrderOptIn });
  const [showTooBigQtyErrorMessage, setShowTooBigQtyErrorMessage] = useState(false);

  const productThumbnailType = isDiscontinued ? getDiscontinuedProductTypeFromFormat(availableFormat) : productType;

  const isRegionalPathway = useMemo(
    () =>
      Number.parseInt(maxNumberOfExp, 10) >=
      Number.parseInt(constants?.[CONSTANTS.CIMA_REGIONAL_QUALIFIED_YEARS_OF_EXPERIENCE], 10),
    [constants, maxNumberOfExp]
  );
  // TODO: Should be membershipKey instead of title.
  const productInCartIsFCMAOrderConfirmation =
    isRegionalPathway &&
    isRegionalPathwayCart &&
    isCredential &&
    title.includes(Product.CredentialKey.FCMA) &&
    isOrderConfirmationPage;

  const productInCartIsMip = title.includes(Product.CredentialKey.CIMA_MIP);
  // cancel and refunds actions
  useEffect(() => {
    setIsRefund(cancelRefundData.requiredAction === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION);
  }, [cancelRefundData]);

  const handleCancelRefundModal = useCallback(() => {
    setCancelRefundModalOpen(!isCancelRefundModalOpen);
  }, [isCancelRefundModalOpen, setCancelRefundModalOpen]);
  const handleActiveCredentialWarningModal = useCallback(() => {
    setActiveCredentialWarningModalOpen(!isActiveCredentialWarningModalOpen);
  }, [isActiveCredentialWarningModalOpen, setActiveCredentialWarningModalOpen]);
  const handleConfirmationDetailsModal = useCallback(() => {
    setConfirmationModalOpen(!isConfirmationModalOpen);
  }, [isConfirmationModalOpen, setConfirmationModalOpen]);
  const handleConfirmationRemoveModal = useCallback(() => {
    setConfirmationRemoveModalOpen(false);
  }, [setConfirmationRemoveModalOpen]);
  const [selectedItem, setSelectedItem] = useState<Common.ProductItemData | null>();
  const updateRemovedProduct = useCallback(() => {
    dispatch(updateCancelledProduct(selectedItem));
  }, [dispatch, selectedItem]);
  const handleSuccessModal = useCallback(() => {
    setSuccessModalOpen(!isSuccessModalOpen);
    updateRemovedProduct();
  }, [isSuccessModalOpen, setSuccessModalOpen, updateRemovedProduct]);
  const onUnderstand = useCallback(() => {
    handleActiveCredentialWarningModal();
    handleConfirmationDetailsModal();
  }, [handleActiveCredentialWarningModal, handleConfirmationDetailsModal]);

  const onConfirmRefundCancel = useCallback(
    (onConfirmData: any) => {
      setCancelRefundData(onConfirmData);
      setCancelRefundModalOpen(false);
      setConfirmationModalOpen(true);
    },
    [setCancelRefundData]
  );
  const handleWarningModal = useCallback(() => {
    setConfirmationModalOpen(false);
    setCancelRefundModalOpen(true);
  }, [setConfirmationModalOpen, setCancelRefundModalOpen]);

  const onConfirmCancellation = useCallback(async () => {
    if (item) {
      const amount: any = cancelRefundData.refundAmount || cancelRefundData.amountToMove;

      const cancelPayload = ProductUtils.createCancelPayload(item, cancelRefundData, amount);

      const refundPayload: User.RefundRequestDetails = ProductUtils.createRefundPayload(item, cancelRefundData, amount);

      // termination of section/credential in sf
      const sectionOrCredentialProduct = {
        productType:
          item?.productType === Product.ProductType.CREDENTIAL ||
          item?.productType === Product.ProductType.SECTION ||
          item?.productType === Product.ProductType.MEMBERSHIP
            ? item?.productType
            : null,
        productSku:
          item?.productType === Product.ProductType.CREDENTIAL || item?.productType === Product.ProductType.SECTION
            ? item?.sku
            : null,
      };

      setLoading(true);
      const res = await dispatch(
        cancelRefundOrder({
          orderId: item?.orderId,
          cancelPayload,
          refundPayload,
          cancelAction: cancelRefundData.requiredAction,
          isMembership: false,
          membershipId: '',
          productType: sectionOrCredentialProduct.productType || item?.productType,
          productSku: sectionOrCredentialProduct.productSku || item?.sku,
          typeOfCancellation: cancelRefundData.typeOfCancellation,
          typeOfCancellationReason: cancelRefundData.typeOfCancellationReason,
          typeOfCancellationComment: cancelRefundData.comment,
          isOnlyProductOnSubscription: item?.isOnlyProductOnSubscription,
        })
      );
      setLoading(false);

      if (res?.payload?.success) {
        handleConfirmationDetailsModal();
        setSuccessModalOpen(true);
        setSelectedItem(item);
      } else {
        setConfirmationModalOpen(false);
        alert(`R&C error: ${res?.payload?.errorMessage}`);
      }
    }
  }, [item, cancelRefundData, dispatch, handleConfirmationDetailsModal]);
  // handle actions
  React.useEffect(() => {
    if ((isEditContributionAmountToggled || quantity! > availableQuantity) && setDisableCheckoutButton) {
      setDisableCheckoutButton(true);
    }
  }, [isEditContributionAmountToggled, availableQuantity, quantity, setDisableCheckoutButton]);

  React.useEffect(() => {
    setContributionAmount(formattedPriceToDecimal(price));
  }, [price]);

  const isStandingOrderChecked: boolean = Boolean(productStandingOrder.productStandingOrder);

  const toggleRemoveBundleComponentModal = React.useCallback(() => {
    setRemoveBundleComponentModalOpen(!isRemoveBundleComponentModalOpen);
  }, [isRemoveBundleComponentModalOpen]);

  const formatAnalyticsPayload: (productItem: any, standingOrderChecked: boolean) => CartCompoundAnalytics =
    React.useCallback((productItem, standingOrderChecked) => {
      const isB2BMembership = isB2BMembershipType(productItem.productType);
      const isB2CMembership = isB2CMembershipType(productItem.productType);
      return {
        category: [productItem.productType],
        id: [productItem.productId],
        productBundleIds: productItem?.productBundleIds,
        productBundleSkus: productItem?.productBundleSkus,
        bundleId: [productItem?.bundleId],
        bundleSku: [productItem?.bundleSku],
        name: [productItem.title],
        price: [priceToFloat(productItem.formattedPrice)],
        quantity: [productItem.quantity],
        sku: [productItem?.sku || ''],
        orderType: [
          getOrderTypeAnalytics({
            isStandingOrder: standingOrderChecked,
            isBundle: !!productItem?.bundleId,
            isFreeTrial: !!productItem?.isFreeTrial,
            isB2BMembership,
            isB2CMembership,
          }),
        ],
        shippingCost: [],
        shippingLocation: [],
        shippingType: [],
        freeTrialEndDate: [productItem?.isFreeTrial ? productItem.accessEndDate : ''],
        productTax: [],
      };
    }, []);

  const onConfirmRemoval = useCallback(async () => {
    if (removeCartItems) {
      removeCartItems(cartDetailItems.map(productItem => productItem.lineItemId));
    }
    cartDetailItems.forEach((productItem: any) => {
      handleEvent(formatAnalyticsPayload(productItem, isStandingOrderChecked), CART_REMOVE_EVENT);
    });
    // Reset User Choice after clearing Cart
    dispatch(resetUserChoice());
    dispatch(abandonApplication());
  }, [formatAnalyticsPayload, isStandingOrderChecked, cartDetailItems, removeCartItems, dispatch]);

  const handleRemoveItem = React.useCallback(() => {
    // CR682 - Remove only FCMA in cart if GEC
    if (isAllowToRemoveFcma && removeCartItems) {
      return removeCartItems([lineItemId]);
    }
    if (location.pathname === getPath(Routes.CART_PAGE) && hasMembershipProduct) {
      setConfirmationRemoveModalOpen(true);
      return;
    }
    if (setDisableCheckoutButton) setDisableCheckoutButton(false);
    if (removeCartItems) removeCartItems([lineItemId]);

    const remainingItemsInBundle = item?.bundleId
      ? cartDetailItems.filter(
          cartItem => cartItem?.masterVariantSKU === item?.masterVariantSKU && cartItem?.productId !== item?.productId
        )
      : [];

    const productBundleIds = remainingItemsInBundle.map(remainingItem => remainingItem.productId);
    const productBundleSkus = remainingItemsInBundle.map(remainingItem => remainingItem.sku);

    const payload: CartCompoundAnalytics = formatAnalyticsPayload(
      {
        productType,
        productId,
        title,
        price,
        quantity: productQty.productQty,
        sku,
        isStandingOrderChecked,
        bundleId: item?.bundleId || '',
        bundleSku: item?.masterVariantSKU || '',
        productBundleIds,
        productBundleSkus,
        isFreeTrial: item.isFreeTrial,
      },
      isStandingOrderChecked
    );
    handleEvent(payload, CART_REMOVE_EVENT);
  }, [
    lineItemId,
    productType,
    productId,
    title,
    price,
    sku,
    isStandingOrderChecked,
    productQty.productQty,
    removeCartItems,
    setDisableCheckoutButton,
    item,
    hasMembershipProduct,
    location,
    formatAnalyticsPayload,
    cartDetailItems,
    isAllowToRemoveFcma,
  ]);

  const onRemoveBundleComponentConfirmation = React.useCallback(() => {
    toggleRemoveBundleComponentModal();
    handleEvent({ clickValue: `button:bundle-actions:int:modal-remove-item-confirm:${title}` }, EVENT_CLICK);
    handleRemoveItem();
  }, [toggleRemoveBundleComponentModal, title, handleRemoveItem]);

  const onRemoveBundleComponentCancel = React.useCallback(() => {
    toggleRemoveBundleComponentModal();
    handleEvent({ clickValue: `button:bundle-actions:int:modal-remove-item-cancel:${title}` }, EVENT_CLICK);
  }, [toggleRemoveBundleComponentModal, title]);

  const handleRemoveItemClick = React.useCallback(() => {
    if (renderAsBundleItem) {
      handleEvent({ clickValue: `button:bundle-actions:int:modal-remove-item-window-open:${title}` }, EVENT_CLICK);
      toggleRemoveBundleComponentModal();
    } else {
      handleRemoveItem();
    }
  }, [renderAsBundleItem, handleRemoveItem, toggleRemoveBundleComponentModal, title]);

  const handleOnCloseActiveCredentialWarningModal = () => {
    const path = getPath(Routes.PROFILE_PURCHASES);
    handleActiveCredentialWarningModal();
    history.push(path);
  };
  const updateCart = () => {
    const hasQuantityChanged: boolean = productQty.productQty !== quantity;
    const hasStandingOrderChanged: boolean = productStandingOrder.productStandingOrder !== isStandingOrderOptIn;

    if (isContribution && updateCartItem) {
      const donationPrice: string = isContribution ? `${priceToFloat(contributionAmount)}` : '';
      updateCartItem(productQty.productQty!, productStandingOrder.productStandingOrder, lineItemId, donationPrice);
      handleToggleEditContributionAmount();
    } else if (availableQuantity && productQty.productQty! <= availableQuantity) {
      if (updateCartItem) {
        updateCartItem(productQty.productQty!, productStandingOrder.productStandingOrder, lineItemId);
      }
    }

    if (setShowUpdateButtons) setShowUpdateButtons(false);
    if (setDisableCheckoutButton) setDisableCheckoutButton(false);

    const isB2BMembership = isB2BMembershipType(productType);
    const isB2CMembership = isB2CMembershipType(productType);

    const payload: CartCompoundAnalytics = {
      category: [productType],
      id: [productId!],
      bundleId: [],
      name: [title],
      price: [priceToFloat(price)],
      quantity: [productQty.productQty!],
      sku: [sku || ''],
      click: `button:cart:standing-order-opt-in-out:${hasStandingOrderChanged ? 'Y' : 'N'}:quantity-update:${
        hasQuantityChanged ? 'Y' : 'N'
      }`,
      orderType: [
        getOrderTypeAnalytics({
          isStandingOrder: isStandingOrderChecked,
          isBundle: !!item.bundleId,
          isFreeTrial: !!item.isFreeTrial,
          isB2BMembership,
          isB2CMembership,
        }),
      ],
      shippingCost: [],
      shippingLocation: [],
      shippingType: [],
      freeTrialEndDate: [item.isFreeTrial ? item.accessEndDate : ''],
      productTax: [],
    };
    handleEvent(payload, CART_UPDATE_EVENT);
  };

  const cancelUpdateCart = () => {
    setProductQty({
      productQty: quantity!,
      isEmpty: false,
    });
    setProductStandingOrder({
      productStandingOrder: isStandingOrderOptIn,
    });
    if (setShowUpdateButtons) setShowUpdateButtons(false);
    if (availableQuantity && quantity! > availableQuantity) setShowTooBigQtyErrorMessage(true);
    if (availableQuantity && quantity! <= availableQuantity) setShowTooBigQtyErrorMessage(false);
  };

  const handleProductQtyChange = React.useCallback(
    ({ target: { value } }: any) => {
      setProductQty({
        productQty: parseInt(value, 10),
        isEmpty: Boolean(value.length === 0),
      });
      if (setProductItemId) setProductItemId(lineItemId);
      if (availableQuantity && value > availableQuantity) setShowTooBigQtyErrorMessage(true);
      if (availableQuantity && value <= availableQuantity) setShowTooBigQtyErrorMessage(false);
      if (setShowUpdateButtons) setShowUpdateButtons(true);
    },
    [availableQuantity, setShowUpdateButtons, lineItemId, setProductItemId]
  );

  const handleCheckboxClick = React.useCallback(
    (e: any, data: any) => {
      setProductStandingOrder({
        productStandingOrder: data.checked,
      });
      if (setProductItemId) setProductItemId(lineItemId);
      if (setShowUpdateButtons) setShowUpdateButtons(true);
    },
    [setShowUpdateButtons, lineItemId, setProductItemId]
  );

  const handleToggleEditContributionAmount = React.useCallback(() => {
    // reset contribution amount to it's default value and re-enable checkout button
    if (isEditContributionAmountToggled) {
      setContributionAmount(formattedPriceToDecimal(price));
      if (setDisableCheckoutButton) setDisableCheckoutButton(false);
    }

    setIsEditContributionAmountToggled(!isEditContributionAmountToggled);
  }, [price, isEditContributionAmountToggled, setDisableCheckoutButton]);

  const handleContributionAmountChange = React.useCallback((event: { target: HTMLInputElement }) => {
    // allow numbers with 2 decimal only
    const regexp = /^\d*\.?\d{0,2}$/;

    if (event.target.validity.valid && regexp.test(event.target.value)) setContributionAmount(event.target.value);
  }, []);

  const isUpdateContributionDisabled = React.useCallback(() => {
    const isContributionAmountIsSameWithDefaultAmount =
      Number(contributionAmount) === Number(formattedPriceToDecimal(price));
    const isContributionAmountGreaterThan1 = Boolean(priceToFloat(contributionAmount) >= 1);

    return isContributionAmountIsSameWithDefaultAmount || !isContributionAmountGreaterThan1;
  }, [contributionAmount, price]);

  const variantOptions = otherVariants.reduce((agg, curr) => {
    const variantsPrices = ProductUtils.getProductPrice(
      // tslint:disable-next-line:no-shadowed-variable
      { variants: otherVariants.map(item => ({ ...item.details })) } as any,
      curr.details.sku,
      userRoles.length ? userRoles : [User.MembershipIdsEnum.NON_MEMBER]
    );
    if (variantsPrices) {
      agg.push({
        key: curr.details.sku,
        value: curr.details.sku,
        text: `${curr.details.format.label} - ${variantsPrices.priceFinal?.formattedPrice}`,
      });
    }
    return agg;
  }, []);
  const onVariantChange = React.useCallback(
    (e, data) => {
      e.preventDefault();
      dispatch(updateUnpublishedVariant(productId, data.value, lineItemId));
    },
    [productId, lineItemId, dispatch]
  );

  // render nodes
  const renderPriceWrap = (): React.ReactNode => {
    return (
      <div>
        <PriceWrap isCartPage={isCartPage} renderAsBundleItem={renderAsBundleItem}>
          {!productInCartIsMip && !isContribution && !isFreeTrial && basePrice !== price && (
            <PriceBase data-testid="base-price"> {basePrice}</PriceBase>
          )}

          {!productInCartIsMip && <Price data-testid="price">{price}</Price>}
          {productInCartIsMip && isOrderConfirmationPage && <Price data-testid="price">{mipProductPrice}</Price>}
        </PriceWrap>
        {renderAsBundleItem && isCartPage && (
          <StyledBundleDiscount data-testid="bundleDiscount">
            {item.bundleDiscountPercentage}% bundle discount applied
          </StyledBundleDiscount>
        )}
        {(isOrderConfirmationPage || isCartPage) && isFreeTrial && (
          <StyledBundleDiscount data-testid="free-trial-term">{freeTrialTerm}-day free trial</StyledBundleDiscount>
        )}
      </div>
    );
  };

  const renderQtyUpdateButtons = (isDisabled: boolean): React.ReactNode => (
    <QtyUpdateButtonsContainer>
      <StyledCancelButton
        testId={`cancel`}
        variant={ButtonEnums.variants.secondary}
        size={ButtonEnums.sizes.medium}
        bordered
        onClick={cancelUpdateCart}
      >
        Cancel
      </StyledCancelButton>
      <StyledUpdateButton
        testId={`cancel`}
        variant={ButtonEnums.variants.primary}
        size={ButtonEnums.sizes.medium}
        bordered
        onClick={updateCart}
        disabled={isDisabled || productQty.isEmpty || productQty.productQty === 0}
      >
        {' '}
        Update cart
      </StyledUpdateButton>
    </QtyUpdateButtonsContainer>
  );

  const renderProductThumbnails = (): React.ReactNode => (
    <>
      <OnlyDesktop>
        <ProductThumbnail
          coverSrc={coverSrc!}
          size={isProfilePurchasesPage || renderAsBundleItem ? ProductThumbSize.MEDIUM : ProductThumbSize.SMALL}
          title={title}
          subtitle={subtitle || ''}
          productType={(productThumbnailType || '') as Product.ProductType}
          subscriptionProductType={subscriptionProductTypeValue}
        />
      </OnlyDesktop>
      <OnlyMobile>
        <ProductThumbnailTag
          productType={(productThumbnailType || '') as Product.ProductType}
          subscriptionProductType={subscriptionProductTypeValue}
        />
      </OnlyMobile>
    </>
  );

  const renderContributionAmountInput = (): React.ReactNode => (
    <StyledInput
      fluid
      iconName="dollar sign"
      iconPosition="left"
      type="text"
      pattern="[0-9]*\.?[0-9]*"
      value={contributionAmount}
      onChange={handleContributionAmountChange}
      testId="contribution-amount"
    />
  );

  const renderEditContributionButton = (): React.ReactNode => (
    <StyledEditContributionButton
      testId="edit-contribution-amount"
      size={ButtonEnums.sizes.small}
      variant={ButtonEnums.variants.link}
      onClick={handleToggleEditContributionAmount}
    >
      Edit Contribution Amount
    </StyledEditContributionButton>
  );

  const renderActionContributionButtons = (): React.ReactNode => (
    <>
      <StyledCancelButton
        testId="cancel-edit-contribution-amount"
        size={ButtonEnums.sizes.medium}
        variant={ButtonEnums.variants.secondary}
        onClick={handleToggleEditContributionAmount}
      >
        Cancel
      </StyledCancelButton>
      <StyledUpdateButton
        testId="update-edit-contribution-amount"
        size={ButtonEnums.sizes.medium}
        variant={ButtonEnums.variants.primary}
        onClick={updateCart}
        disabled={isUpdateContributionDisabled()}
      >
        Update Cart
      </StyledUpdateButton>
    </>
  );

  const renderButtons = (): React.ReactNode => (
    <ProductItemButton
      isMobile={isMobile}
      parent={parentPage}
      isAccessNowDisabled={isAccessNowDisabled}
      isCertificate={isCertificate}
      isActiveProduct={isActiveProduct}
      isDiscontinued={isDiscontinued}
      isConference={isConference}
      isWebcast={isWebcast}
      isCourse={isCourse}
      isSection={isSection}
      isCenterMembership={isCenterMembership}
      isCredential={isCredential}
      isPremiumContent={isPremiumContent}
      isOPL={isOPL}
      isMembership={isMembership}
      isFlpType={isFlpType}
      hasDomainString={hasDomainString}
      userEmail={userEmail}
      oktaId={oktaId}
      fvsMembership={fvsMembership}
      taxMembership={taxMembership}
      nfpMembership={nfpMembership}
      pfpMembership={pfpMembership}
      isAdminPortal={isAdminPortal}
      isExamScheduled={isExamScheduled}
      item={item}
      productSlug={cmsProductData?.slug}
      handleRemoveItem={handleRemoveItemClick}
      navigate={navigate}
      link={link}
      deprovision={deprovision}
      accessItem={accessItem}
      isAnyPhysicalProduct={isAnyPhysicalProduct}
      isProfilePurchasesPage={isProfilePurchasesPage}
      isStackedInColumn={isProfilePurchasesPage}
      setCancelSOrderModalMeta={setCancelSOrderModalMeta}
      renderActionContributionButtons={renderActionContributionButtons}
      isEventInNext48Hours={isEventInNext48Hours}
      isContribution={isContribution}
      isEditContributionAmountToggled={isEditContributionAmountToggled}
      handleCancelRefundModal={handleCancelRefundModal}
      isExamCreditType={isExamCreditType}
      getRenewalSeason={getRenewalSeason}
      getMipRenewalSeasonStartDate={getMipRenewalSeasonStartDate}
      getMipRenewalSeasonEndDate={getMipRenewalSeasonEndDate}
      isExtendAccess={isExtendAccess}
    />
  );

  const renderCancelAndRefundsModals = (): React.ReactNode => (
    <>
      <CancelOrRefundProductModal
        productName={item?.title || ''}
        open={isCancelRefundModalOpen}
        onConfirm={onConfirmRefundCancel}
        onClose={handleCancelRefundModal}
        isViewingProRation={
          item?.productType === Product.ProductType.SUBSCRIPTION ||
          item?.productType === Product.ProductType.MEMBERSHIP ||
          item?.productType === Product.ProductType.SECTION ||
          item?.productType === Product.ProductType.CREDENTIAL
        }
        order={item}
        subscriptionAmountAdded={subscriptionAmountAdded}
        setSubscriptionAmountAdded={setSubscriptionAmountAdded}
      />
      <ActiveCredentialWarningModal
        open={isActiveCredentialWarningModalOpen}
        onClose={handleOnCloseActiveCredentialWarningModal}
        onUnderstand={onUnderstand}
      />
      <ConfirmationDetailsModal
        open={isConfirmationModalOpen}
        productMemo={item?.title || ''}
        amount={`$${cancelRefundData.refundAmount || cancelRefundData.amountToMove || 0}`}
        refundType={cancelRefundData?.requiredAction?.replace('-', ' ')}
        onClose={!!credentialProducts.length ? handleConfirmationDetailsModal : handleWarningModal}
        cancelRefundData={cancelRefundData}
        onConfirm={onConfirmCancellation}
        isLoading={isLoading}
        isRefund={isRefund}
      />
      <SuccessModal
        open={isSuccessModalOpen}
        paymentId={paymentId}
        onClose={handleSuccessModal}
        isRefundWithCancellation={
          cancelRefundData?.requiredAction === Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION
        }
        isRefundOnlyNoCancellation={
          cancelRefundData?.requiredAction === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION
        }
        isCancelAndMove={cancelRefundData?.requiredAction === Product.RefundOrCancellationEnums.CANCEL_AND_MOVE}
      />
      <ConfirmationRemoveModal
        open={isConfirmationRemoveModalOpen}
        onClose={handleConfirmationRemoveModal}
        onConfirm={onConfirmRemoval}
        isLoading={isLoading}
      />
    </>
  );

  return (
    <>
      {!productInCartIsFCMAOrderConfirmation && (
        <div>
          {renderCancelAndRefundsModals()}
          <RemoveBundleComponentModal
            bundleDiscount={item.bundleDiscountPercentage || 0}
            open={isRemoveBundleComponentModalOpen}
            onCancel={onRemoveBundleComponentCancel}
            onConfirmation={onRemoveBundleComponentConfirmation}
          />
          <FlexWrap className={className}>
            {isDiscontinued ? (
              <StyledThumbnailContainer>{renderProductThumbnails()}</StyledThumbnailContainer>
            ) : (
              <StyledThumbnailLink
                to={link}
                testId={`cart-thumb-link-${lineItemId}`}
                underline={LinkEnums.underline.noUnderlineOnHover}
              >
                {renderProductThumbnails()}
              </StyledThumbnailLink>
            )}
            <InfoSection>
              <InfoHead isContribution={isContribution}>
                {isDiscontinued ? (
                  <TruncateMarkup ellipsis={'…'} lines={2}>
                    <HeadTitle className="discontinued">{title}</HeadTitle>
                  </TruncateMarkup>
                ) : (
                  <HeadTitleWithStatus>
                    {' '}
                    {(isDelayedBenefitProduct || hasActiveMigratedAcmaAndCgmaCredentials) &&
                      !isOrderConfirmationPage &&
                      isCredential &&
                      !isCartPage &&
                      (isDelayedBenefitStatusPending || isDelayedBenefitStatusActive) && (
                        <StyledButton>
                          {isDelayedBenefitStatusPending
                            ? Membership.CimaCredentialStatus.PENDING
                            : Membership.CimaCredentialStatus.ACTIVE}
                        </StyledButton>
                      )}
                    <Link
                      type={LinkEnums.type.standaloneLink}
                      to={link}
                      testId={`cart-title-link-${sku}`}
                      viewTextForAnalytics={viewTextForAnalytics}
                    >
                      <TruncateMarkup ellipsis={'…'} lines={2}>
                        <HeadTitle>{title}</HeadTitle>
                      </TruncateMarkup>
                    </Link>
                  </HeadTitleWithStatus>
                )}
                {!isProfilePurchasesPage && !isEditContributionAmountToggled && (
                  <StyledOnlyDesktop isContribution={isContribution}>{renderPriceWrap()}</StyledOnlyDesktop>
                )}
                {!isProfilePurchasesPage && isContribution && isEditContributionAmountToggled && (
                  <StyledContributionAmountWrapper>
                    <StyledContributionAmountLabel>Amount:</StyledContributionAmountLabel>
                    {renderContributionAmountInput()}
                  </StyledContributionAmountWrapper>
                )}
              </InfoHead>

              {dateRange && !isPublication && !isConference && <DateRange>{dateRange}</DateRange>}
              <ProductContentContainer isDirectionRow={isProfilePurchasesPage && !isMobile}>
                <ProductItemDetails
                  isCartPage={isCartPage}
                  availableFormatLabel={availableFormatLabel}
                  isCredential={isCredential}
                  isSection={isSection}
                  isMembership={isMembership}
                  currentMemProductStatus={currentMemProductStatus}
                  isConference={isConference}
                  isPublication={isPublication}
                  isExam={isExam}
                  isDiscontinued={isDiscontinued}
                  parentPage={parentPage}
                  isSubscription={isSubscription}
                  item={item}
                  productDescription={cmsProductData?.description}
                  benefitsAccessLinks={cmsProductData?.benefitsAccessLinks}
                  productItemId={productItemId}
                  isOPL={isOPL}
                  orderDate={orderDate}
                  conferenceUserInfo={conferenceUserInfo}
                  isActiveProduct={isActiveProduct}
                  showTooBigQtyErrorMessage={showTooBigQtyErrorMessage}
                  productStandingOrder={productStandingOrder}
                  productQty={productQty!}
                  handleCheckboxClick={handleCheckboxClick}
                  handleProductQtyChange={handleProductQtyChange}
                  showUpdateButtons={showUpdateButtons}
                  renderQtyUpdateButtons={renderQtyUpdateButtons}
                  isAnyPhysicalProduct={isAnyPhysicalProduct}
                  isPublicationPhysicalProduct={isPublicationPhysicalProduct}
                  isCoursePhysicalProduct={isCoursePhysicalProduct}
                  isCourse={isCourse}
                  quantity={quantity!}
                  viewTextForAnalytics={viewTextForAnalytics}
                  toggleSubscriptionRenewal={toggleSubscriptionRenewal}
                  renderContributionAmountInput={renderContributionAmountInput}
                  renderEditContributionButton={renderEditContributionButton}
                  renderActionContributionButtons={renderActionContributionButtons}
                  isOrderConfirmationPage={isOrderConfirmationPage}
                  isEventInNext48Hours={isEventInNext48Hours}
                  isContribution={isContribution}
                  isEditContributionAmountToggled={isEditContributionAmountToggled}
                  isCenterMembership={isCenterMembership}
                  isFlpType={isFlpType}
                  isFeeType={isFeeType}
                  isPQ={isPQ}
                  isMembershipReactivationJourney={isMembershipReactivationJourney}
                  isCimaRenewalSeason={isCimaRenewalSeason}
                  activeMembership={activeMembership}
                />
                {isCartPage && !variantProductPublished && (
                  <>
                    <StyledDropdownLabelText>Select format from the following options:</StyledDropdownLabelText>
                    <StyledDropdown
                      testId={`dropdown-variants-${sku}`}
                      placeholder={`Select format`}
                      options={variantOptions}
                      onChange={onVariantChange}
                    />
                  </>
                )}
                {isProfilePurchasesPage && <ActionButtonsContainer> {renderButtons()}</ActionButtonsContainer>}
              </ProductContentContainer>

              <StyledFooter>
                {!isProfilePurchasesPage && (
                  <StyledMobileOnly>
                    {renderPriceWrap()}
                    {<ActionButtonsContainer> {renderButtons()}</ActionButtonsContainer>}
                  </StyledMobileOnly>
                )}
                <OnlyDesktop>
                  {!isProfilePurchasesPage && renderButtons()}
                  {showUpdateButtons &&
                    productItemId === lineItemId &&
                    renderQtyUpdateButtons(showTooBigQtyErrorMessage)}
                </OnlyDesktop>
              </StyledFooter>
            </InfoSection>
          </FlexWrap>

          {((isProfilePurchasesPage && isAnyPhysicalProductAwaitingShipment) ||
            isPhSubscriptionShipped ||
            (isOrderConfirmationPage && isAnyPhysicalProduct)) && (
            <StyledNotificationBanner
              testId="awaiting-shipment-state"
              variant={NotificationBannerEnums.variant.blue}
              icon={<StyledIconError />}
              childrenTestId="awaiting-shipment-state"
              isOrderConfirmationPage={isOrderConfirmationPage}
            >
              Need to change your shipping address? Changes can be made until the order is packaged.&nbsp;
              <Link
                testId="notification-banner--contact-us"
                to={getPath(Routes.CONTACT_US)}
                viewTextForAnalytics={viewTextForAnalytics}
              >
                Contact us
              </Link>
            </StyledNotificationBanner>
          )}

          {!isEventInNext48Hours && isProfilePurchasesPage && isConference && (
            <div>
              <br />
              <StyledNotificationBanner
                testId="awaiting-shipment-state"
                variant={NotificationBannerEnums.variant.blue}
                icon={<StyledIconError />}
                childrenTestId="awaiting-shipment-state"
                isOrderConfirmationPage={isOrderConfirmationPage}
              >
                Two days before the event’s start, the “Access now” button will be enabled and redirect you to&nbsp;
                <Link
                  testId="notification-banner--contact-us"
                  to={ExtLinks.CONFERENCES_LINK}
                  viewTextForAnalytics={viewTextForAnalytics}
                  target="blank"
                  isExternal
                >
                  AICPAConferences.com
                </Link>
                . Please note that the login isn’t tied to your AICPA.org account.
                <br />
                <div>
                  New to AICPAConferences.com? Create an account using the same email used for your event registration.
                </div>
              </StyledNotificationBanner>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const StyledMobileOnly = styled(OnlyMobile)`
  &&& {
    flex-wrap: wrap;
  }
`;

const FlexWrap = styled.div`
  display: flex;
`;

const thumbnailTagDimensions = {
  height: 20,
  width: 14,
};

const ProductThumbnailTag = styled.div<{
  productType: Product.ProductType | undefined;
  subscriptionProductType: Product.SubscriptionProductType | undefined;
}>`
  width: ${props => props.theme.pxToRem(thumbnailTagDimensions.width)};
  height: ${props => props.theme.pxToRem(thumbnailTagDimensions.height)};
  border-bottom-right-radius: ${props => props.theme.pxToRem(6)};
  ${props =>
    props.productType !== Product.ProductType.CONTRIBUTION &&
    `
    background-color: ${props.theme.getProductInfoBgColorByType(props.productType, props.subscriptionProductType)};
`}
`;

const thumbStyle = (props: any) => `${props.theme.mediaQueries.mobileOnly} {
  height: ${props.theme.pxToRem(thumbnailTagDimensions.height)};
}`;

const StyledThumbnailContainer = styled.div`
  ${props => thumbStyle(props)}
`;

const StyledThumbnailLink = styled(Link)`
  ${props => thumbStyle(props)}
`;

const InfoSection = styled.div`
  flex-grow: 1;
  margin-left: ${props => props.theme.pxToRem(20)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(16)};
  }
`;

const InfoHead = styled.div<{ isContribution?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${props => props.isContribution && `min-height: ${props.theme.pxToRem(50)};`}
  ${props => !props.isContribution && `align-items: center;`}

  &&& .discontinued {
    color: ${props => props.theme.colors.neutralGrey8};
  }
`;

const HeadTitle = styled.div`
  margin-top: 0;
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.25;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: ${props => props.theme.pxToRem(320)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(282)};
    font-size: ${props => props.theme.fontSizes.m};
    line-height: 1.33;
  }
`;

const PriceWrap = styled.div<{ isCartPage: boolean; renderAsBundleItem: boolean }>`
  display: flex;
  align-items: center;
  ${props => !props.isCartPage && 'width: 100%;'}
  ${props => props.theme.mediaQueries.desktopOnly} {
    justify-content: flex-end;
  }
  &&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      justify-content: flex-start;
      margin-left: 0;
    }
  }
`;

const PriceBase = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  text-decoration: line-through;
`;

const Price = styled.div`
  height: ${props => props.theme.pxToRem(24)};
  margin-left: ${props => props.theme.pxToRem(8)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
`;

const DateRange = styled.div`
  height: ${props => props.theme.pxToRem(22)};
  margin-top: ${props => props.theme.pxToRem(4)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.57;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(8)};
  }
`;

const ProductContentContainer = styled.div<{ isDirectionRow: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isDirectionRow ? 'row' : 'column')};
  justify-content: space-between;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-left: ${props => props.theme.pxToRem(40)};
    padding-top: 1rem;
    min-width: ${props => props.theme.pxToRem(186)};
  }
`;
export const StyledLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
`;

const QtyUpdateButtonsContainer = styled.div`
  position: relative;
  margin-bottom: ${props => props.theme.pxToRem(32)};

  ${props => props.theme.mediaQueries.desktopOnly} {
    top: ${props => props.theme.pxToRem(-6)};
    float: right;
    margin: 0;
  }
`;

const StyledUpdateButton = styled(Button)`
  &&&& {
    width: ${props => props.theme.pxToRem(111)};
    height: ${props => props.theme.pxToRem(34)};
    min-height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.pxToRem(14)};
    padding: 0;
  }
`;

const StyledCancelButton = styled(Button)`
  &&&& {
    margin-right: ${props => props.theme.pxToRem(10)};
    width: ${props => props.theme.pxToRem(75)};
    height: ${props => props.theme.pxToRem(34)};
    min-height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.pxToRem(14)};
    padding: 0;
  }
`;

const StyledFooter = styled.footer`
  > div {
    ${props => props.theme.mediaQueries.mobileOnly} {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div div {
        font-size: ${props => props.theme.fontSizes.s};
        line-height: 1.5;

        :first-child {
          margin-left: ${props => props.theme.pxToRem(8)};
          font-weight: ${props => props.theme.fontWeights.light};
          order: 2;
        }

        :last-child {
          margin-left: 0;
          font-weight: ${props => props.theme.fontWeights.medium};
          order: 1;
        }
      }
    }
  }
`;
const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  align-self: flex-start;
  ${props => props.theme.mediaQueries.mobileOnly} {
    align-self: center;
  }
  transform: rotateX(180deg);
`;

const StyledNotificationBanner = styled(NotificationBanner)<{ isOrderConfirmationPage?: boolean }>`
  &&&&&& {
    ${props =>
      props.isOrderConfirmationPage &&
      `
      margin-top: ${props.theme.pxToRem(22)};
  `}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    svg {
      align-self: flex-start;
    }
  }
`;

const StyledBundleDiscount = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 2;
  text-align: right;
  color: ${props => props.theme.colors.secondaryTeal};
  width: ${props => props.theme.pxToRem(156)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: auto;
    margin-bottom: ${props => props.theme.pxToRem(24)};
  }
`;

const StyledInput = styled(Input)`
  &&&& {
    font-size: ${props => props.theme.fontSizes.s};
    width: ${props => props.theme.pxToRem(114)};
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

const StyledEditContributionButton = styled(Button)`
  &&&& {
    color: ${props => props.theme.colors.primaryPurple};
    line-height: ${props => props.theme.pxToRem(22)};
    font-size: ${props => props.theme.pxToRem(14)};
    font-weight: ${props => props.theme.fontWeights.bold};
    ${props => props.theme.mediaQueries.computerMin} {
      &:hover {
        span {
          text-decoration: none;
        }
      }
    }
  }
`;

const StyledContributionAmountWrapper = styled(OnlyDesktop)`
  display: flex;
  align-items: center;
`;

const StyledContributionAmountLabel = styled.span`
  color: ${props => props.theme.colors.neutralBlack};
  line-height: ${props => props.theme.pxToRem(18)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-right: ${props => props.theme.pxToRem(8)};
`;

const StyledOnlyDesktop = styled(OnlyDesktopCSS)<{ isContribution?: boolean }>`
  flex-direction: column;
  ${props => props.isContribution && `align-self: center;`}
`;
const StyledButton = styled.button`
  padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(10)};
  letter-spacing: ${props => props.theme.pxToRem(1)};
  font-size: ${props => props.theme.fontSizes.xs};
  background-color: ${props => props.theme.colors.neutralGrey6};
  border: none;
  outline: none;
  color: ${props => props.theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(5)};
  margin-bottom: ${props => props.theme.pxToRem(30)};
`;
const HeadTitleWithStatus = styled.div``;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  max-width: ${props => props.theme.pxToRem(323)};
  padding: ${props => props.theme.pxToRem(10)};
  margin-bottom: ${props => props.theme.pxToRem(20)};

  &.ui.dropdown > .text {
    color: ${props => props.theme.colors.primaryPurple};
    font-size: ${props => props.theme.fontSizes.s};
  }

  &.ui.dropdown > .menu > .item {
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.light};

    &:hover {
      font-weight: ${props => props.theme.fontWeights.regular};
    }
  }
`;

const StyledDropdownLabelText = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  padding-top: ${props => props.theme.pxToRem(16)};
  padding-bottom: ${props => props.theme.pxToRem(8)};
`;
