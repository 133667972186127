import { Location } from 'history';
import { getLocation } from 'connected-react-router';
import { createSelector } from 'reselect';
import { Contentful } from 'mxp-schemas';
import { isLowerEnvironment } from 'utils/env';
// ------------------------------------
// Selectors
// ------------------------------------
const appSelector = createSelector(
  (state: State.Root): State.App => state.app,
  app => app
);

const appConfigSelector = createSelector(appSelector, (app: State.App): State.Config => app.config);
const appConstantSelector = createSelector(appSelector, (app: State.App): State.Constants | any => app.constants);

export const appInitializedSelector = createSelector(appSelector, (app: State.App): boolean => app.appInitialized);

export const appVersionSelector = createSelector(appSelector, app => app.version);

export const appEnvSelector = createSelector(appSelector, app => app.env);

export const isLowerEnvironmentSelector = createSelector(appSelector, app => isLowerEnvironment(app.env));

export const oktaConfigSelector = createSelector(appConfigSelector, ({ okta }: State.Config): State.Okta => okta);

export const oktaRedirectLoginUrlSelector = createSelector(oktaConfigSelector, (okta: State.Okta): string =>
  okta.redirectLoginUrl && okta.redirectLoginUrl !== 'undefined' && okta.publicUrl && okta.publicUrl !== 'undefined'
    ? `${okta.redirectLoginUrl}${okta.publicUrl}`
    : ''
);

export const doubleDonationApiKeySelector = createSelector(
  appConfigSelector,
  ({ doubleDonationApi }: State.Config): string => doubleDonationApi
);

export const fcmaPeriodCheckDurationSelector = createSelector(
  appConfigSelector,
  ({ fcmaPeriodCheckDuration }: State.Config): string => fcmaPeriodCheckDuration
);

export const sentryDSNSelector = createSelector(appConfigSelector, ({ sentryDSN }: State.Config): string => sentryDSN);

export const provisioningUrlSelector = createSelector(
  appConfigSelector,
  ({ provisioning }: State.Config): State.Provisioning => provisioning
);

export const brightcoveConfigSelector = createSelector(
  appConfigSelector,
  ({ brightcove }: State.Config): State.BrightcoveConfig => brightcove
);

export const fullUrlSelector = createSelector(
  [oktaConfigSelector, getLocation as any],
  (okta: State.Okta, location: Location): string => {
    return okta?.publicUrl + location.pathname;
  }
);

export const fullUrlWithSearchSelector = createSelector(
  [oktaConfigSelector, getLocation as any],
  (okta: State.Okta, location: Location): string => {
    return okta?.publicUrl + location.pathname + location.search;
  }
);

export const externalRegisterUrlSelector = createSelector(
  appConfigSelector,
  ({ externalRegisterUrl }: State.Config): string => externalRegisterUrl
);

export const vergicConfigSelector = createSelector(
  appConfigSelector,
  ({ vergicUrl, vergicId }: State.Config): State.VergicConfig => ({ vergicUrl, vergicId })
);

export const shipStationReturnPortalLinkConfigSelector = createSelector(
  appConfigSelector,
  ({ shipStationReturnPortalLink }: State.Config): string => shipStationReturnPortalLink
);

export const flpPlatformLinkConfigSelector = createSelector(
  appConfigSelector,
  ({ flpPlatformLink }: State.Config): string => flpPlatformLink
);
export const flpPlatformCGMALinkConfigSelector = createSelector(
  appConfigSelector,
  ({ flpPlatformCGMALink }: State.Constants): string => flpPlatformCGMALink
);

export const cimaStudyLinkConfigSelector = createSelector(
  appConfigSelector,
  ({ cimaStudyLink }: State.Constants): string => cimaStudyLink
);

export const cimaStudyHubLinkConfigSelector = createSelector(
  appConfigSelector,
  ({ cimaStudyHubLink }: State.Constants): string => cimaStudyHubLink
);

export const mipRenewalSeasonStartConfigSelector = createSelector(
  appConstantSelector,
  ({ mip_renewal_season_start }: State.Constants): string => mip_renewal_season_start
);

export const mipRenewalSeasonEndConfigSelector = createSelector(
  appConstantSelector,
  ({ mip_renewal_season_end }: State.Constants): string => mip_renewal_season_end
);

export const cimaExamQualificationNameOperationSelector = createSelector(
  appConstantSelector,
  ({ cima_exam_qualification_name_operation }: State.Constants): State.Constants =>
    cima_exam_qualification_name_operation
);

export const cimaExamQualificationNameBusinessFinance = createSelector(
  appConstantSelector,
  ({ cima_exam_qualification_name_business_finance }: State.Constants): State.Constants =>
    cima_exam_qualification_name_business_finance
);
export const cimaExamQualificationNameManagement = createSelector(
  appConstantSelector,
  ({ cima_exam_qualification_name_management }: State.Constants): State.Constants =>
    cima_exam_qualification_name_management
);
export const cimaExamQualificationNameCertBa = createSelector(
  appConstantSelector,
  ({ cima_exam_qualification_name_cert_ba }: State.Constants): State.Constants => cima_exam_qualification_name_cert_ba
);

export const cimaExamQualificationNameEpal4 = createSelector(
  appConstantSelector,
  ({ cima_exam_qualification_name_epal4 }: State.Constants): State.Constants => cima_exam_qualification_name_epal4
);

export const cimaExamQualificationNameDesignation = createSelector(
  appConstantSelector,
  ({ cima_exam_qualification_name_designation }: State.Constants): State.Constants =>
    cima_exam_qualification_name_designation
);

export const cimaExamQualificationNamesSelector = createSelector(
  cimaExamQualificationNameCertBa,
  cimaExamQualificationNameManagement,
  cimaExamQualificationNameBusinessFinance,
  cimaExamQualificationNameOperationSelector,
  cimaExamQualificationNameEpal4,
  cimaExamQualificationNameDesignation,
  (
    certBA: State.Constants,
    management: State.Constants,
    businessAndFinance: State.Constants,
    operations: State.Constants,
    epaL4: State.Constants,
    designation: State.Constants
  ): any => {
    return {
      ...certBA,
      ...management,
      ...businessAndFinance,
      ...operations,
      ...epaL4,
      ...designation,
    };
  }
);

export const cookieSecretKeyConfigSelector = createSelector(
  appConfigSelector,
  ({ cookieSecretKey }: State.Config): string => cookieSecretKey
);

export const isAdminPortalSelector = createSelector(
  appConfigSelector,
  ({ isAdminPortal }: State.Config): boolean => isAdminPortal
);

export const productAccessConfigSelector = createSelector(
  appConfigSelector,
  ({ productAccess }: State.Config): State.ProductAccess => productAccess
);

export const entryPageSelector = createSelector(appSelector, ({ entryPage }: State.App): string => entryPage || '');

export const constantsSelector = createSelector(
  appSelector,
  (app: State.App): null | Contentful.Constants.Constants => app.constants
);

export const getConstantByKeySelector = (key: string) =>
  createSelector(
    constantsSelector,
    (constants: null | Contentful.Constants.Constants): any => constants?.[key] || false
  );

export const pearsonVueSSOLinkConfigSelector = createSelector(
  appConfigSelector,
  ({ pearsonVueSSOLink }: State.Config): string => pearsonVueSSOLink
);

export const paypalUrlConfigSelector = createSelector(
  appConfigSelector,
  ({ paypalUrl }: State.Config): string => paypalUrl
);

export const paypalAicpaSWIndetifierSelector = createSelector(
  appConfigSelector,
  ({ paypalAicpaSWIndetifier }: State.Config): string => paypalAicpaSWIndetifier
);

export const paypalCimaSWIdentifierSelector = createSelector(
  appConfigSelector,
  ({ paypalCimaSWIdentifier }: State.Config): string => paypalCimaSWIdentifier
);

export const isCimaQpMembershipSelector = createSelector(
  appConfigSelector,
  ({ isCimaQpMembership }: State.Config): boolean => isCimaQpMembership
);

export const bridgeUrlConfigSelector = createSelector(appConfigSelector, ({ bridgeUrl }: State.Config): string => {
  // The URL in the param store has '/api'
  const baseUrl = bridgeUrl;
  const excessIndex = 4;
  const index = baseUrl.length - excessIndex;
  const modifiedUrl = baseUrl.slice(0, index);
  return modifiedUrl;
});

export const cimaMembershipMaxFileUploadSelector = createSelector(
  appConstantSelector,
  ({ cima_membership_max_file_upload }: State.Constants): number => cima_membership_max_file_upload
);

export const holdingPageBypassConfigSelector = createSelector(
  appConfigSelector,
  ({ holdingPageBypass }: State.Config): string => holdingPageBypass
);

export const reCAPTCHAKeyConfigSelector = createSelector(
  appConfigSelector,
  ({ reCAPTCHAKey }: State.Config): string => reCAPTCHAKey
);

export const blacklistEmailDomainsSelector = createSelector(
  appConfigSelector,
  ({ blacklistEmailDomains }: State.Config): string => blacklistEmailDomains
);
