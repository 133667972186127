import React from 'react';
import styled from 'styled-components';
import { ButtonEnums, Heading, ButtonLink, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { Contentful } from 'mxp-schemas';
import { DefaultInlineLinkStyles, ExternalLinkStyles } from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE } from 'resources/images';
interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}

export const BlockMarketing: React.FC<Props> = ({ modularBlock, headingType }) => {
  const block = modularBlock as Contentful.ModularContent.MarketingBlock;
  const subBlock: Contentful.LandingPages.LandingPageSubBlock | null = block?.landingPageSubBlocks?.length
    ? block.landingPageSubBlocks[0]
    : null;

  return (
    <>
      {subBlock && (
        <StyledSubBlockContainer>
          {subBlock.header && (
            <StyledHeading tabIndex={0} as={headingType} dangerouslySetInnerHTML={{ __html: subBlock.header }} />
          )}
          {subBlock.description && (
            <StyledParagraph tabIndex={0} dangerouslySetInnerHTML={{ __html: subBlock.description }} />
          )}
          {subBlock.ctaUrl && subBlock.ctaText && (
            <>
              {/* Following div is fix for SSR. Do not remove! */}
              <div>
                <OnlyDesktop>
                  <ButtonLink
                    testId="marketing-block-desktop"
                    variant={ButtonEnums.variants.primary}
                    size={ButtonEnums.sizes.large}
                    to={subBlock.ctaUrl}
                    external={subBlock.ctaUrl.includes('http')}
                  >
                    {subBlock.ctaText}
                  </ButtonLink>
                </OnlyDesktop>
              </div>
              {/* Following div is fix for SSR. Do not remove! */}
              <div>
                <OnlyMobile>
                  <ButtonLink
                    testId="marketing-block-mobile"
                    variant={ButtonEnums.variants.primary}
                    size={ButtonEnums.sizes.small}
                    to={subBlock.ctaUrl}
                    external={subBlock.ctaUrl.includes('http')}
                  >
                    {subBlock.ctaText}
                  </ButtonLink>
                </OnlyMobile>
              </div>
            </>
          )}
        </StyledSubBlockContainer>
      )}
    </>
  );
};

const StyledSubBlockContainer = styled.div`
  padding: ${props => props.theme.pxToRem(65)} ${props => props.theme.pxToRem(95)};
  margin-bottom: 10%;
  opacity: 0.9;
  background-color: ${props => props.theme.colors.neutralWhite};
  ${props => props.theme.mediaQueries.desktopOnly} {
    max-width: ${props => props.theme.pxToRem(550)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    padding: ${props => props.theme.pxToRem(56)} ${props => props.theme.pxToRem(18)} ${props => props.theme.pxToRem(50)};
    text-align: center;
  }
  .rich-text-external-link {
    ${ExternalLinkStyles}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${IC_OPEN_IN_NEW_PURPLE});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }

  .rich-text-internal-link {
    ${DefaultInlineLinkStyles};
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.medium};
  p {
    line-height: 1.38;
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.l};
    P {
      line-height: 1.33;
    }
  }
`;

const StyledParagraph = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};

  p {
    line-height: 1.33;
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};

    p {
      line-height: 1.5;
    }
  }
`;
