import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  TabFirmRosterManageContainer,
  TabFirmRosterMembersInvitesContainer,
  TabFlpSubscriptionsContainer,
} from 'components/App/lazy-imports';
import { FirmSupportBanner } from 'components/organisms/FirmSupportBanner/FirmSupportBanner';
import { getPath } from 'utils';
import { compareTabWithPath } from 'utils/routes';
import { Routes } from 'constants/index';
import { useSelector } from 'react-redux';
import { cimaFeaturesSelector } from 'modules/firmAdmin/selectors';

export const PageFirmRoster: React.FC<RouteComponentProps> = ({ location }) => {
  const handleCompareTabWithPath = React.useCallback(
    (comparison: string) => compareTabWithPath(location.pathname, comparison),
    [location.pathname]
  );
  const useCimaFeatures = useSelector(cimaFeaturesSelector);

  return (
    <>
      {handleCompareTabWithPath(getPath(Routes.FIRM_ROSTER_TAB_MANAGE)) && <TabFirmRosterManageContainer />}
      {handleCompareTabWithPath(getPath(Routes.FIRM_ROSTER_TAB_MEMBERS)) && <TabFirmRosterMembersInvitesContainer />}
      {handleCompareTabWithPath(getPath(Routes.FIRM_ROSTER_TAB_FLP_SUBSCRIPTIONS)) && <TabFlpSubscriptionsContainer />}
      {handleCompareTabWithPath(getPath(Routes.FIRM_ROSTER_TAB_SUPPORT)) && (
        <FirmSupportBanner useCimaFeatures={useCimaFeatures} />
      )}
    </>
  );
};
