import React from 'react';
import styled from 'styled-components';
import { IC_OPEN_IN_NEW } from '../../../resources/images';
import { Button } from '../Button/Button';
import { Link, LinkEnums } from 'components/atoms/Link/Link';

interface ButtonMobileProps {
  item: { label: string | undefined; path: string };
  isExternal?: boolean;
  onClick?(): void;
  className?: string;
}

export const ButtonMobileMenu = React.memo<ButtonMobileProps>(({ isExternal = true, item, onClick, className }) => (
  <>
    <MobileMenuButton testId={`header-button-${item.label}`} onClick={onClick} className={className}>
      {isExternal ? (
        <>
          <ExternalIcon src={IC_OPEN_IN_NEW} alt="externalIcon" />
          <StyledLink type={LinkEnums.type.standaloneLink} isExternal to={item.path} testId={item.label}>
            {item.label}
          </StyledLink>
        </>
      ) : (
        <div>{item.label}</div>
      )}
    </MobileMenuButton>
    <OptionDivider />
  </>
));

const MobileMenuButton = styled(Button)`
  &&&& {
    text-align: left;
    height: 64px;
    padding: 0 20px;
    width: 100%;
    font-size: ${props => props.theme.fontSizes.m};
    color: ${props => props.theme.colors.neutralGrey8};
    font-weight: ${props => props.theme.fontWeights.regular};
    border: none;
    background: none;
    margin: 0;

    i.icon {
      float: right;
      margin-right: 0;
    }
  }
`;

const ExternalIcon = styled.img`
  vertical-align: middle;
  margin-right: 6px;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.neutralBlack};
`;

const OptionDivider = styled.div`
  height: 1px;
  background-color: ${props => props.theme.colors.neutralGrey2};
  margin: 0 20px;
`;
