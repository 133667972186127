import React from 'react';
import { ReactComponent as IconDownload } from 'resources/images/ic-file-download.svg';
import styled from 'styled-components/macro';

interface ApplicationDownloadProps {
  title: string;
  downloadLink?: any;
}

export const CimaApplicationDownload: React.FC<ApplicationDownloadProps> = ({ title, downloadLink }) => {
  const handleDownload = () => {
    window.open(downloadLink, '_blank');
  };

  return (
    <StyledContainer>
      <StyledSection>
        <StyledSpan>{title}</StyledSpan>
        <StyledDownload onClick={handleDownload}>
          <IconImage />
          <StyledSpan>Download</StyledSpan>
        </StyledDownload>
      </StyledSection>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  border-radius: ${props => props.theme.pxToRem(10)};
  border: solid ${props => props.theme.pxToRem(0.5)} #bababa;
  padding: ${props => props.theme.pxToRem(25)};
`;

const StyledSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

const StyledSpan = styled.div`
  font-size: ${props => props.theme.pxToRem(16)};
`;

const StyledDownload = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  font-size: ${props => props.theme.pxToRem(16)};
`;

const IconImage = styled(IconDownload)`
  width: ${props => props.theme.pxToRem(25)};
  height: ${props => props.theme.pxToRem(25)};
`;
