import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { theme } from 'theme';
import { BenefitTile } from './BenefitTile';
import { FeedTargetableLink } from 'components/atoms/FeedTargetableLink/FeedTargetableLink';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import { ButtonEnums, Button, ToggleButtonNoIcon } from 'components/atoms';
import { UserMemberTypes } from 'modules/user/constants';
import { ReactComponent as OpenInNew } from 'resources/images/ic-open-in-new.svg';
import { ReactComponent as ContentPremium } from 'resources/images/icon-dev-ic-content-premium.svg';
import { User, Contentful } from 'mxp-schemas';
import { showLogin } from 'modules/layouts';
import { User as UserRoles, RichTextBlocks } from 'mxp-utils';
import { handleEvent, EVENT_CLICK } from 'utils/Analytics';
import { stripUrlParts } from 'utils';
interface BenefitBlockProps {
  userEmail?: string;
  oktaId?: string;
  oplRedirectUrl?: string;
  oplHidUrl?: string;
  associatedMembershipRestrictions: Contentful.MembershipRestriction.Main;
  userStatus: UserMemberTypes;
  userRoles: User.MembershipIdsEnum[];
  fvsMembership?: User.MembershipIdsEnum[] | [];
  toggleLoginReload: (shouldReload: boolean) => void;
}

export const BenefitBlock: React.FC<BenefitBlockProps> = ({
  associatedMembershipRestrictions,
  userStatus,
  userRoles,
  toggleLoginReload,
  userEmail,
  oktaId,
  oplRedirectUrl,
  oplHidUrl,
  fvsMembership,
}) => {
  const benefitTiles: Contentful.MembershipRestriction.BenefitsSubBlock[] =
    associatedMembershipRestrictions.benefitsSubBlock || [];
  const learnMoreLinkText: string = `OR Learn more about the ${
    UserRoles.membershipDiscountMapNames[associatedMembershipRestrictions.oktaRoleId]
  }`;

  const isForSectionMember: boolean = UserRoles.sectionMembershipRoles.includes(
    associatedMembershipRestrictions.oktaRoleId
  );
  const isForCredentialMember: boolean = UserRoles.credentialMembershipRoles.includes(
    associatedMembershipRestrictions.oktaRoleId
  );
  const isForFirmMember: boolean = UserRoles.firmMembershipRoles.includes(associatedMembershipRestrictions.oktaRoleId);

  const userHasRequiredAccessRestrictionRole: boolean = userRoles
    .toString()
    .includes(associatedMembershipRestrictions.oktaRoleId);

  const isSectionMember: boolean = isForSectionMember && userHasRequiredAccessRestrictionRole;
  const isCredentialMember: boolean = isForCredentialMember && userHasRequiredAccessRestrictionRole;
  const isFirmMember: boolean = isForFirmMember && userHasRequiredAccessRestrictionRole;

  const isWithoutSection: boolean = userStatus === UserMemberTypes.PREMIUM && !isSectionMember;
  const isLoggedOut: boolean = userStatus === UserMemberTypes.LOGGED_OUT;
  const isNonmember: boolean = userStatus === UserMemberTypes.NONMEMBER;
  const isMember = userRoles?.some((role: User.MembershipIdsEnum) =>
    [User.MembershipIdsEnum.MRUSR0001, User.MembershipIdsEnum.MRUKR0001].includes(role)
  );
  const dispatch = useDispatch();
  const [areAllVisible, setVisibility] = useState(false); // initial expanded/collapsed state
  const [benefitDisplay, setBenefitDisplay] = useState(6); // initial limit(6) for displaying benefit blocks in collapse view

  const moduleVersion: string = isSectionMember
    ? 'section-member'
    : isCredentialMember
    ? 'credential-member'
    : isFirmMember
    ? 'firm-member'
    : isLoggedOut
    ? 'logged out'
    : isMember
    ? 'member'
    : 'registered';

  const benefitArray = React.useMemo(
    () =>
      isSectionMember || isCredentialMember || isFirmMember || benefitTiles?.length <= 3
        ? benefitTiles
        : benefitTiles?.slice(0, 12), // maximum no. of benefits to display (12)
    [isSectionMember, isFirmMember, benefitTiles, isCredentialMember]
  );

  const toggleViewMore = React.useCallback(() => {
    if (!areAllVisible) {
      setBenefitDisplay(12); // setting max no. of benefits for expanded view
      setVisibility(true);
    } else {
      setBenefitDisplay(6); // setting min no. of benefits for collapsed view
      setVisibility(false);
    }
  }, [setBenefitDisplay, areAllVisible]);

  const handleClickButton = React.useCallback(
    ({ title, path }) => {
      if (!path) return;

      const isExternal = RichTextBlocks.isExternal(path);
      handleEvent(
        {
          clickValue: `button:section-mem-module:${path?.startsWith('http') ? 'ext' : 'int'}:${title}:${formattedURL(
            path,
            isExternal
          )}`,
          moduleVersion,
        },
        EVENT_CLICK
      );
    },
    [moduleVersion]
  );

  const handleToggleLoginReload = React.useCallback(() => {
    toggleLoginReload(true);
    dispatch(showLogin({ redirectToLoginPage: true }));
  }, [toggleLoginReload, dispatch]);

  const handleClickLink = React.useCallback(
    ({ title, path }) => {
      if (!path) return;

      const isExternal = isLinkExternal(path);
      handleEvent(
        {
          clickValue: `link:section-mem-module:${path?.startsWith('http') ? 'ext' : 'int'}:${title}:${formattedURL(
            path,
            isExternal
          )}`,
          moduleVersion,
        },
        EVENT_CLICK
      );
    },
    [moduleVersion]
  );

  const headerText =
    isSectionMember || isFirmMember
      ? associatedMembershipRestrictions.headerSectionMember
      : isCredentialMember
      ? 'Access now' // Credentials had no requirement for cms driven text
      : isLoggedOut
      ? associatedMembershipRestrictions.headerLoggedOut
      : isMember
      ? associatedMembershipRestrictions.headerMember
      : associatedMembershipRestrictions.headerNonMember;
  return (
    <BenefitBlockContainer data-testid="benefit-block-container">
      <BenefitTop data-testid="benefit-block-top">
        <BenefitHeader tabIndex={0} data-testid="benefit-block-top-header">
          {headerText}
        </BenefitHeader>
        {(isFirmMember || isSectionMember || isCredentialMember) && (
          <StyledTag tabIndex={0} data-testid="premium-tag">
            <StyledContentPremium />
            <StyledBoldText tabIndex={0}>
              {isFirmMember ? 'FIRM MEMBER' : isCredentialMember ? 'CREDENTIAL MEMBER' : 'SECTION MEMBER'}
            </StyledBoldText>
            <StyledText tabIndex={0}> EXCLUSIVE</StyledText>
          </StyledTag>
        )}
      </BenefitTop>
      {benefitArray && (
        <BenefitTileWrapper data-testid="benefit-tile-wrapper">
          {benefitArray.slice(0, benefitDisplay).map(benefits => (
            <BenefitTile
              data-testid="benefit-tile-wrapper"
              key={benefits.name}
              header={benefits.header}
              image={benefits.image}
              imageMembership={benefits.imageMembership}
              description={benefits.description}
              url={benefits.url}
              userEmail={userEmail}
              oktaId={oktaId}
              fvsMembership={fvsMembership}
              userHasRequiredAccessRestrictionRole={userHasRequiredAccessRestrictionRole}
              isOplLink={benefits?.oplLink}
              oplHidUrl={oplHidUrl}
              oplRedirectUrl={oplRedirectUrl}
              moduleVersion={moduleVersion}
            />
          ))}
        </BenefitTileWrapper>
      )}
      {benefitArray.length > 6 && (
        <StyledToggleButton testId={`view-more-less-benefits-button`} onClick={toggleViewMore} isOpen={areAllVisible}>
          {areAllVisible ? `View Less` : `View More`}
        </StyledToggleButton>
      )}
      <StyledFillingLineWrapper>
        <StyledFillingLine />
      </StyledFillingLineWrapper>
      {isLoggedOut && (
        <LoggedOutDescription
          associatedMembershipRestrictions={associatedMembershipRestrictions}
          learnMoreLinkText={learnMoreLinkText}
          handleToggleLoginReload={handleToggleLoginReload}
          handleClickLink={handleClickLink}
        />
      )}
      {!isLoggedOut &&
        !isFirmMember &&
        !isSectionMember &&
        !isCredentialMember &&
        (isNonmember || isMember || isWithoutSection) && (
          <MemberOrNonmemberDescription
            isMember={isMember}
            isForFirmMember={isForFirmMember}
            associatedMembershipRestrictions={associatedMembershipRestrictions}
            handleClickButton={handleClickButton}
          />
        )}
    </BenefitBlockContainer>
  );
};

const LoggedOutDescription: React.FC<{
  associatedMembershipRestrictions: Contentful.MembershipRestriction.Main;
  learnMoreLinkText: string;
  handleToggleLoginReload: () => void;
  handleClickLink: ({ title, path }: { title: string; path: string }) => void;
}> = ({ associatedMembershipRestrictions, learnMoreLinkText, handleToggleLoginReload, handleClickLink }) => {
  const linkClickHandler = React.useCallback(() => {
    handleClickLink({
      title: learnMoreLinkText,
      path: associatedMembershipRestrictions.learnMoreLink,
    });
  }, [handleClickLink, learnMoreLinkText, associatedMembershipRestrictions]);
  const isExternal = RichTextBlocks.isExternal(associatedMembershipRestrictions.learnMoreLink);
  return (
    <>
      <BenefitDescription tabIndex={0}>{associatedMembershipRestrictions.ctaDescriptionLoggedOut}</BenefitDescription>
      <StyledLoginButton
        testId="login-button"
        variant={ButtonEnums.variants.primary}
        size={ButtonEnums.sizes.medium}
        bordered
        onClick={handleToggleLoginReload}
      >
        Log in
      </StyledLoginButton>
      <FeedTargetableLink
        key="section-benefit-learn-more"
        title={learnMoreLinkText}
        iconPurple={isExternal}
        link={associatedMembershipRestrictions.learnMoreLink}
        handleClick={linkClickHandler}
        testId="section-benefit-learn-more"
      />
    </>
  );
};

const MemberOrNonmemberDescription: React.FC<{
  isMember: boolean;
  isForFirmMember: boolean;
  associatedMembershipRestrictions: Contentful.MembershipRestriction.Main;
  handleClickButton: ({ title, path }: { title: string; path: string }) => void;
}> = ({ isMember, isForFirmMember, associatedMembershipRestrictions, handleClickButton }) => {
  const title = isMember
    ? isForFirmMember
      ? 'Become a firm access member'
      : 'Become a section member'
    : 'Join the AICPA';
  const path = isMember
    ? associatedMembershipRestrictions.becomeMemberButton
    : associatedMembershipRestrictions.ctaUrlNonMember;

  const onClickHandler = React.useCallback(() => {
    handleClickButton({
      title,
      path,
    });
  }, [handleClickButton, title, path]);

  return (
    <>
      <BenefitDescription>
        {isMember
          ? associatedMembershipRestrictions.ctaDescriptionMember
          : associatedMembershipRestrictions.ctaDescriptionNonMembers}
      </BenefitDescription>
      <StyledButtonLink
        testId="benefit-become-member-button"
        variant={ButtonEnums.variants.primary}
        size={ButtonEnums.sizes.medium}
        icon={<StyledExternalIcon color={theme.colors.neutralWhite} />}
        iconPosition={ButtonEnums.iconPosition.left}
        to={path}
        onClick={onClickHandler}
        external
        fluid
      >
        {title}
      </StyledButtonLink>
    </>
  );
};

export const isLinkExternal = (url: string) => {
  return url?.startsWith('http');
};

export const formattedURL = (url: string, isExternal: boolean = false) => {
  const finalUrl = isExternal
    ? url
    : url.startsWith('#')
    ? `${window.location.href}${url}`
    : `${window.location.hostname}${url}`;

  return stripUrlParts(finalUrl, { withoutProtocol: true, withoutQuery: true });
};

const BenefitBlockContainer: any = styled.div`
  &&&& {
    padding: 0;
    margin: ${props => props.theme.pxToRem(10)};
    width: 100%;
    ${props => props.theme.mediaQueries.mobileOnly} {
      display: block;
      margin: 0;
    }
  }
`;

const BenefitTop = styled.div`
  display: flex;
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-wrap: wrap;
  }
`;

const BenefitHeader: any = styled.h3`
  font-size: ${props => props.theme.pxToRem(24)};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.regular};
  margin: 0;
`;

const StyledTag = styled.div`
  ${props => `
    height: ${props.theme.pxToRem(24)};
    font-size: ${props.theme.fontSizes.xxs};
    padding: ${props.theme.pxToRem(5)} ${props.theme.pxToRem(5)};
    display: inline-flex;
    align-self: center;
    color: ${props.theme.colors.neutralBlack};
    backdrop-filter: blur(2px);
    opacity: 0.95;
    border-radius: ${props.theme.pxToRem(1)};
    background-color: ${props.theme.colors.neutralWhite};
    margin-top: ${props.theme.pxToRem(11)};
    ${props.theme.mediaQueries.mobileMax} {
      margin: 0 0 0 ${props.theme.pxToRem(20)};
    }
`}
`;

const StyledContentPremium = styled(ContentPremium)`
  ${props => `
    width: ${props.theme.pxToRem(14)};
    height: ${props.theme.pxToRem(14)};
    path {
      fill: ${props.theme.colors.primaryPurple};
    }
    &&& {
      margin-right: ${props.theme.pxToRem(5)};
    }
  `}
`;

const StyledBoldText = styled.p`
  font-weight: ${props => props.theme.fontWeights.medium};
  margin: 0;
  ${props => props.theme.mediaQueries.ieOnly} {
    font-weight: ${props => props.theme.fontWeights.bold};
  }
  width: max-content;
`;

const StyledText = styled.p`
  padding-left: ${props => props.theme.pxToRem(3)};
`;

const BenefitTileWrapper: any = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  margin: ${props => props.theme.pxToRem(40)} 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(24)} 0 0 0;
  }
  ::after {
    height: 0;
    width: 33%;
    content: '';
  }
`;

const BenefitDescription: any = styled.h5`
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    width: auto;
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};
  }
`;

const StyledExternalIcon = styled(OpenInNew)`
  path {
    fill: ${props => props.theme.colors.neutralWhite};
  }
  &&&&&& {
    margin-right: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledLoginButton = styled(Button)`
  &&&& {
    min-width: ${props => props.theme.pxToRem(170)};
    margin-bottom: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledToggleButton = styled(ToggleButtonNoIcon)`
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: underline;
  position: relative;
  float: right;
  width: ${props => props.theme.pxToRem(200)};
  color: ${props => props.theme.colors.primaryPurple};
  margin-left: 90%; /* retaining the %based margin to satisfy the reactive behavior */
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(24)} 0 0 0;
  }
`;

const StyledFillingLineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme.pxToRem(20)};
`;

const StyledFillingLine = styled.span`
  flex: 1;
  height: 1px;
  background: ${props => props.theme.colors.neutralGrey3};
`;
