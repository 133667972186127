import React, { useState } from 'react';
import {
  Grid,
  Container,
  AicpaLogo,
  Divider,
  NotificationBannerEnums,
  NotificationBanner,
  ButtonEnums,
  Button,
  GlobalModalDimmerStyle,
  LinkEnums,
  Link,
} from 'components/atoms';
import styled from 'styled-components/macro';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { User as UserTypes } from 'mxp-schemas';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { ForgotEmail, ForgotEmailForm } from './ForgotEmailForm';
import { Modal as SemanticUIModal } from 'semantic-ui-react';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { Routes } from 'constants/index';
import { getPath, Scroller } from 'utils';
import deskBgImage from 'resources/pics/email-password.svg';
import { Footer } from 'components/molecules/Footer';
import { Route } from 'react-router-dom';
interface PageForgotEmail {
  getEmail: (idNumber: string, lastName: string) => void;
  clearPreviousResults: () => void;
  loading: boolean;
  error: State.UserError | null;
  maskedEmail: string;
}

export const PageForgotEmail: React.FC<PageForgotEmail> = ({
  error,
  loading,
  clearPreviousResults,
  getEmail,
  maskedEmail,
}) => {
  const defaultForgotEmailFormState: ForgotEmail = {
    idNumber: '',
    lastName: '',
  };
  const [forgotEmailFormData, setForgotEmailFormData] = useState(defaultForgotEmailFormState);
  const [isForgotEmailFormValid, setForgotEmailFormValid] = useState(false);
  const [isHelpModalVisible, setHelpModalVisible] = useState(false);

  const submitForgotEmailForm = React.useCallback((): void => {
    const { idNumber, lastName } = forgotEmailFormData;
    getEmail(idNumber, lastName);
  }, [forgotEmailFormData, getEmail]);

  const userFound = maskedEmail;

  const title = userFound ? (
    <SuccessH1 data-testid="success-title">Success! We found your account</SuccessH1>
  ) : (
    <StyledH1 data-testid="page-title">Forgot your email address?</StyledH1>
  );

  const description = userFound ? (
    <UserFoundDescriptionHeader email={maskedEmail} />
  ) : (
    <>
      Enter your account number and last name.
      <br />
      We’ll check our records for a match.
    </>
  );

  React.useEffect(() => {
    clearPreviousResults();
    return function cleanup() {
      clearPreviousResults();
    };
  }, [clearPreviousResults]);

  React.useEffect(() => {
    Scroller.scrollToTop();
  }, [maskedEmail]);

  const handleHelpModalClose = () => {
    setHelpModalVisible(false);
  };

  const handleHelpModalOpen = () => {
    setHelpModalVisible(true);
  };

  return (
    <>
      <div>
        <LogoHeader />
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column computer={8}>
                <WrapperForgotContent>
                  {!userFound && (
                    <PreviousPageStyled backText="Back to previous page" previousRoute={getPath(Routes.LOGIN)} />
                  )}
                  {title}
                  <DividerStyled />
                  <DescriptionForgot>{description}</DescriptionForgot>
                  {!userFound && (
                    <>
                      {error?.errorCode && (
                        <FormMessages
                          error={error}
                          idNumber={forgotEmailFormData.idNumber}
                          lastName={forgotEmailFormData.lastName}
                        />
                      )}
                      <ForgotEmailForm
                        forgotEmailForm={forgotEmailFormData}
                        setForgotEmail={setForgotEmailFormData}
                        setFormValid={setForgotEmailFormValid}
                        handleHelpModalOpen={handleHelpModalOpen}
                      />
                      <SubmitButton
                        size={ButtonEnums.sizes.medium}
                        loading={loading}
                        onClick={submitForgotEmailForm}
                        testId="get-email"
                        variant={ButtonEnums.variants.primary}
                        disabled={!isForgotEmailFormValid}
                      >
                        Get email address
                      </SubmitButton>
                    </>
                  )}
                </WrapperForgotContent>
              </Grid.Column>
              <Grid.Column computer={8}>
                <ImgHolder data-testid="forgot-pass-email-img" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
      <PreFooter />
      <Route exact path={getPath(Routes.PRIVACY_POLICY)} component={StyledFooter} />
      <HelpModal isVisible={isHelpModalVisible} onHelpModalClose={handleHelpModalClose} />
    </>
  );
};

export const StyledFooter = styled(Footer)`
  position: relative;
`;

export const PreFooter = styled.div`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: ${props => props.theme.pxToRem(225)}; // IE footer fix
  }
`;

export const ImgHolder = styled.section`
  position: relative;
  padding: 0 ${props => props.theme.pxToRem(80)};
  background: url(${deskBgImage}) no-repeat;
  background-size: ${props => props.theme.pxToRem(534)} ${props => props.theme.pxToRem(512)};
  margin-top: ${props => props.theme.pxToRem(95)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    background-size: ${props => props.theme.pxToRem(335)} ${props => props.theme.pxToRem(321)};
    margin: 0;
    min-height: ${props => props.theme.pxToRem(321)};
    height: auto;
    width: inherit;
  }
  ${props => props.theme.mediaQueries.largeScreenMin} {
    min-height: ${props => props.theme.pxToRem(525)};
    margin-top: ${props => props.theme.pxToRem(95)};
  }
`;

export const LogoHeader: React.FC = () => (
  <StyledHeader>
    <Container>
      <WrapperHeader>
        <StyledAicpaLogo overrideAnalytics={true} />
      </WrapperHeader>
    </Container>
  </StyledHeader>
);

export const WrapperForgotContent = styled.div`
  margin: ${props => props.theme.pxToRem(26)} 0;
  max-width: ${props => props.theme.pxToRem(458)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(12)};
  }
`;

export const PreviousPageStyled = styled(PreviousPage)`
  &&&&& {
    margin-bottom: ${props => props.theme.pxToRem(0)};
    margin-top: ${props => props.theme.pxToRem(6)};
  }
`;

export const DividerStyled = styled(Divider)`
  &&& {
    margin: ${props => props.theme.pxToRem(31)} 0;
    margin-top: ${props => props.theme.pxToRem(16)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: -5%;
      width: 110%;
    }
  }
`;

export const DescriptionForgot = styled.div`
  text-align: left;
  margin-bottom: ${props => props.theme.pxToRem(28)};
  margin-top: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
`;

interface HelpModalProps {
  onHelpModalClose: () => void;
  isVisible: boolean;
}

const HelpModal: React.FC<HelpModalProps> = ({ onHelpModalClose, isVisible }) => (
  <>
    <GlobalModalDimmerStyle />
    <StyledModal
      closeOnDimmerClick={false}
      data-testid="help-aicpaid"
      onClose={onHelpModalClose}
      open={isVisible}
      centered
    >
      <ModalHeading>
        <Title>How do I find my account number?</Title>
        <CloseButton onClick={onHelpModalClose} data-testid="modal-authentication-close">
          <StyledCloseIcon />
        </CloseButton>
      </ModalHeading>
      <ModalContent>
        <ModalDivider />
        You can find your nine-digit account number (e.g. 300000001) on any of the following correspondence you may have
        received from us.
        <StyledUl>
          <li>Welcome email</li>
          <li>Dues bill</li>
          <li>Emailed invoice</li>
          <li>Mailing label</li>
          <li>Printed invoice</li>
          <li>Renewal email</li>
        </StyledUl>
      </ModalContent>
    </StyledModal>
  </>
);

export const StyledUl = styled.ul`
  padding-left: ${props => props.theme.pxToRem(16)};
  margin-bottom: 0;
`;

const ModalDivider = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(13)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const ModalContent = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  margin: ${props => props.theme.pxToRem(20)};
  margin-top: 0;
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
`;

const Title = styled.h2`
  &&& {
    color: ${props => props.theme.colors.neutralGrey8};
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.regular};
    text-align: left;
    margin-bottom: ${props => props.theme.pxToRem(4)};
    line-height: 1.33;
  }
`;

const ModalHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;

  ${props => props.theme.mediaQueries.mobileOnly} {
    justify-content: space-between;
  }
`;

const StyledModal = styled(SemanticUIModal)`
  .ui.page.modals.dimmer.visible.active {
    background-color: transparent;
    background-image: linear-gradient(to right, rgba(106, 45, 135, 0.9), rgba(169, 61, 105, 0.9));
    padding: 0;
  }

  &&&&&& {
    font-family: ${props => props.theme.fontFamily};
    background-color: ${props => props.theme.colors.neutralWhite};
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
    padding: ${props => props.theme.pxToRem(20)} 0;
    padding-top: ${props => props.theme.pxToRem(14)};
    ${props => props.theme.mediaQueries.desktopOnly} {
      width: ${props => props.theme.pxToRem(590)};
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
      border-radius: 0;
    }
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-right: ${props => props.theme.pxToRem(-6)};
  }
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.neutralGrey4};
`;

const BoldedEmail: React.FC<{ email: string }> = ({ email }) => <b>{email} </b>;

const UserFoundDescriptionHeader: React.FC<{ email: string }> = ({ email }) => {
  return (
    <SuccessWrapper>
      Does this email address look familiar? <BoldedEmail email={email} />
      <StyledUl>
        <StyledLi>
          If <BoldedEmail email={email} /> looks like your email address,{' '}
          <StyledForgotPasswordLink type={LinkEnums.type.inlineLink} to={getPath(Routes.LOGIN)} testId="reset-password">
            continue to log in
          </StyledForgotPasswordLink>
        </StyledLi>
        <StyledLi>
          If you don’t know your password,{' '}
          <StyledForgotPasswordLink
            type={LinkEnums.type.inlineLink}
            to={getPath(Routes.FORGOT_PASSWORD)}
            testId="reset-password"
          >
            reset it now
          </StyledForgotPasswordLink>
        </StyledLi>
        <StyledLi>
          If you no longer have access to the email address <BoldedEmail email={email} />, contact us at 888-777-7077 or
          +1 919-402-4500 if outside the US. We’re open Monday through Friday, 9am to 6pm ET.
        </StyledLi>
      </StyledUl>
    </SuccessWrapper>
  );
};

const SuccessWrapper = styled.div`
  line-height: 1.5;
`;

const StyledLi = styled.li`
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;
const StyledForgotPasswordLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  display: inline-block;
`;

interface FormMessagesProps {
  error: any;
  idNumber: string;
  lastName: string;
}
const FormMessages: React.FC<FormMessagesProps> = ({ error, idNumber, lastName }) => {
  const [cachedIdNumber] = useState(idNumber);
  const [cachedLastName] = useState(lastName);
  return (
    <>
      {error?.errorCode === UserTypes.UserErrorCodes.LOGIN_NOT_FOUND && (
        <NotificationBannerStyled
          testId="error-incorrect-id-banner"
          childrenTestId="error-incorrect-id-children"
          variant={NotificationBannerEnums.variant.red}
          icon={<StyledIconError />}
        >
          We couldn’t find an account for AICPA account number <b>{cachedIdNumber}</b> and last name{' '}
          <b>{cachedLastName}</b>.{' '}
          <StyledUl>
            <li>
              {' '}
              If one or both are typed incorrectly, please try again
              <br />
            </li>
            <li>
              If both are correct, contact us at 888-777-7077 or +1 919-402-4500 if outside the US. We’re open Monday
              through Friday, 9am to 6pm ET.
            </li>
          </StyledUl>
        </NotificationBannerStyled>
      )}
    </>
  );
};

const SubmitButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(170)};
    margin-top: ${props => props.theme.pxToRem(28)};
    ${props => props.theme.mediaQueries.desktopOnly} {
      margin-bottom: ${props => props.theme.pxToRem(80)};
    }
  }
`;

export const NotificationBannerStyled = styled(NotificationBanner)`
  &&&&& {
    margin: ${props => props.theme.pxToRem(32)} 0 ${props => props.theme.pxToRem(28)} 0;
    padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(16)};
    max-width: ${props => props.theme.pxToRem(360)};
  }
`;

export const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  align-self: flex-start;
  margin-top: ${props => props.theme.pxToRem(10)};
  transform: rotateX(180deg);
`;

const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.theme.pxToRem(80)};
`;

export const StyledH1 = styled.h1`
  margin-top: ${props => props.theme.pxToRem(22)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(18)};
  }
`;
const SuccessH1 = styled(StyledH1)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(80)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(18)};
    }
  }
`;

const StyledAicpaLogo = styled(AicpaLogo)`
  height: ${props => props.theme.pxToRem(42)};
`;

const StyledHeader = styled.header`
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
  ${props => props.theme.mediaQueries.mobileOnly} {
    box-shadow: none;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  }

  background-color: ${props => props.theme.colors.neutralWhite};
`;
