import React, { Dispatch, SetStateAction } from 'react';
import { Button, Modal, ButtonEnums } from 'components/atoms';
import styled from 'styled-components';
import { ReactComponent as IconSave } from 'resources/images/icon-save.svg';
import { push } from 'connected-react-router';
import { updateApplication } from 'modules/user/actions';
import { applicationSelector } from 'modules/user/selectors';
import { MembershipTypes } from 'mxp-schemas';
import { useSelector } from 'react-redux';
import { initialState } from 'modules/user/constants';
import { setMembershipEvent } from 'modules/membership/actions';

interface Props {
  showApplicationModal: boolean;
  applicationProgress: string;
  setShowApplicationModal: Dispatch<SetStateAction<boolean>>;
  dispatch: Dispatch<any>;
  onRestartClick?: () => void;
  shouldUpdateApplicationProgress?: boolean;
}

export const ModalApplicationRedirect: React.FC<Props> = React.memo(
  ({
    showApplicationModal,
    applicationProgress,
    setShowApplicationModal,
    dispatch,
    onRestartClick,
    shouldUpdateApplicationProgress = true,
  }) => {
    const application = useSelector(applicationSelector);

    const handleResumeClick = React.useCallback((): void => {
      dispatch(setMembershipEvent('isTriggerCimaResumeApplication', true));
      setShowApplicationModal(false);
      dispatch(push(applicationProgress));
    }, [dispatch, applicationProgress, setShowApplicationModal]);

    const handleRestartClick = React.useCallback((): void => {
      dispatch(setMembershipEvent('isClickedStartNewApplication', true));
      setShowApplicationModal(false);
      dispatch(
        updateApplication({
          ...initialState.application,
          id: application.id,
          status: MembershipTypes.ApplicationStatusEnum.NOT_SUBMITTED,
          applicationPart: {
            ...initialState.application.applicationPart,
            id: application.applicationPart.id,
          },
        })
      );
      if (onRestartClick) onRestartClick();
    }, [application, setShowApplicationModal, dispatch, shouldUpdateApplicationProgress]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <StyledModal
        open={showApplicationModal}
        size="tiny"
        icon={<StyledIconSave />}
        heading={'You already have an application in progress.'}
        cancelNode={
          <Button
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.secondary}
            onClick={handleRestartClick}
            testId="discard-warning"
          >
            {'Start a new application'}
          </Button>
        }
        confirmNode={
          <Button
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.primary}
            bordered
            onClick={handleResumeClick}
            testId="resume-warning"
          >
            {'Resume Application'}
          </Button>
        }
        testId="redirect-last-visit-warning"
      >
        <WarningText>Would you like to resume your previous application or start a brand new one?</WarningText>
      </StyledModal>
    );
  }
);

const WarningText = styled.div`
  text-align: center;
`;

const StyledIconSave = styled(IconSave)`
  fill: ${props => props.theme.colors.secondaryOrange};
`;

const StyledModal = styled(Modal)`
  &&&& {
    border-radius: 0;
  }
`;
