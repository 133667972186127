import React from 'react';
import styled from 'styled-components/macro';
import { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Product } from 'mxp-schemas';
import { PRODUCT_CART_STATUS } from 'constants/index';
import { Icon } from 'semantic-ui-react';
import { PurchaseButton } from './PurchaseButton';
import { BundleSummedPrices } from 'modules/products/selectors';
import { emptyObject } from 'utils/index';

interface Props {
  handleToggleSummary: () => void;
  priceFull: Product.FormattedPriceData | null;
  discountAmount: Product.FormattedPriceData | null;
  priceFinal: Product.FormattedPriceData | null;
  isSummaryOpen: boolean;
  cartLoading: boolean;
  cartTransitionError: boolean;
  selectedVariantSKU?: string;
  handlePageButton: () => void;
  productButtonStatus: PRODUCT_CART_STATUS;
  thirdPartyLink?: string;
  isTransferableProductType: boolean;
  productType: string;
  productId: string;
  isPhysicalProduct: boolean;
  purchaseButtonDisabled: boolean;
  bundleSummedPrices?: BundleSummedPrices;
  isBundle?: boolean;
  isPurchased?: boolean;
  handleProvisionAccess: () => void;
  currentVariantPrice?: string;
  isProductHasUserPrice?: boolean | null;
  isPremiumContent?: boolean;
  premiumContentTo?: string;
  isUserSuspendedByEthics?: boolean;
}

export const StickyFooter: React.FC<Props> = ({
  cartLoading,
  cartTransitionError,
  selectedVariantSKU,
  priceFinal,
  handleToggleSummary,
  isSummaryOpen,
  discountAmount,
  priceFull,
  handlePageButton,
  productButtonStatus,
  thirdPartyLink,
  isTransferableProductType,
  productType,
  productId,
  isPhysicalProduct,
  purchaseButtonDisabled,
  bundleSummedPrices,
  isBundle,
  isPurchased = false,
  handleProvisionAccess,
  currentVariantPrice,
  isProductHasUserPrice,
  isPremiumContent,
  premiumContentTo,
  isUserSuspendedByEthics,
}) => {
  const { userRolesDiscounts } = bundleSummedPrices || (emptyObject as BundleSummedPrices);

  const showPriceFull = isBundle
    ? bundleSummedPrices?.priceFull && Boolean(userRolesDiscounts?.length)
    : priceFull && discountAmount;

  const priceFullToDisplay = isBundle
    ? bundleSummedPrices?.priceFull?.formattedPrice || ''
    : priceFull && priceFull.formattedPrice;

  const priceFinalToDisplay = isBundle
    ? bundleSummedPrices?.priceFinal?.formattedPrice || ''
    : priceFinal && priceFinal.formattedPrice;

  const priceFinalToDisplayForUserWithEthics = isBundle
    ? bundleSummedPrices?.priceFull?.formattedPrice || ''
    : priceFull && priceFull.formattedPrice;

  const isContribution: boolean = productType === Product.ProductType.CONTRIBUTION;

  return (
    <StickyFooterStyled>
      <FlexWrap>
        <div>
          <FlexWrap>
            <div onClick={handleToggleSummary} data-testid="trigger-collapsible-total">
              <AngleFooterIcon fitted name={!isSummaryOpen ? 'angle up' : 'angle down'} />
            </div>
            <div>
              <div onClick={handleToggleSummary}>
                <StyledDivDetails is-contribution={isContribution} is-product-has-user-price={isProductHasUserPrice}>
                  {!isSummaryOpen ? 'View details' : 'Close details'}{' '}
                </StyledDivDetails>
              </div>
              {!thirdPartyLink &&
                (!isUserSuspendedByEthics ? (
                  <FlexWrap>
                    {!isContribution && showPriceFull && (
                      <PricingValueFooter data-testid={`full-value`}>{priceFullToDisplay}</PricingValueFooter>
                    )}
                    {!isContribution && (
                      <PricingValue data-testid={`final-value`} final>
                        {priceFinalToDisplay}
                      </PricingValue>
                    )}
                    {isContribution && isProductHasUserPrice && (
                      <PricingValue data-testid={`final-value`} final>
                        {currentVariantPrice}
                      </PricingValue>
                    )}
                  </FlexWrap>
                ) : (
                  <FlexWrap>
                    {!isContribution && (
                      <PricingValue data-testid={`final-value`} final>
                        {priceFinalToDisplayForUserWithEthics}
                      </PricingValue>
                    )}
                    {isContribution && isProductHasUserPrice && (
                      <PricingValue data-testid={`final-value`} final>
                        {currentVariantPrice}
                      </PricingValue>
                    )}
                  </FlexWrap>
                ))}
            </div>
          </FlexWrap>
        </div>
        <div>
          {(!isContribution || (isContribution && isProductHasUserPrice)) && (
            <PurchaseButton
              thirdPartyLink={thirdPartyLink}
              isTransferableProductType={isTransferableProductType}
              handlePageButton={handlePageButton}
              productButtonStatus={productButtonStatus}
              cartLoading={cartLoading}
              selectedVariantSKU={selectedVariantSKU}
              cartTransitionError={cartTransitionError}
              productType={productType}
              productId={productId}
              isPhysicalProduct={isPhysicalProduct}
              disabled={purchaseButtonDisabled}
              handleProvisionAccess={handleProvisionAccess}
              isPurchased={isPurchased}
              isPremiumContent={isPremiumContent}
              premiumContentTo={premiumContentTo}
            />
          )}
        </div>
      </FlexWrap>
    </StickyFooterStyled>
  );
};

interface PricingProps {
  discounted?: boolean;
  final?: boolean;
  priceData?: Product.FormattedPriceData;
  testId?: string;
  selectedVariantFormat?: string;
  isPhysicalProduct?: boolean;
  thirdPartyLink?: string;
}

const FOOTER_HEIGHT = 70;

const StickyFooterStyled = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  background-color: ${props => props.theme.colors.neutralGrey1};
  height: ${props => props.theme.pxToRem(FOOTER_HEIGHT)};
  border-top: solid 1px ${props => props.theme.colors.neutralGrey3};
  padding: ${props => props.theme.pxToRem(20)};
  padding-top: ${props => props.theme.pxToRem(12)};
`;

const FlexWrap = styled.div`
  display: flex;
`;

const AngleFooterIcon = styled(Icon)`
  &&& {
    font-size: ${props => props.theme.fontSizes.m};
    color: ${props => props.theme.colors.primaryPurple};
    padding-right: ${props => props.theme.pxToRem(16)};
    padding-top: ${props => props.theme.pxToRem(12)};
  }
`;

const PricingValue = styled.div<ThemedStyledProps<PricingProps, DefaultTheme>>`
  color: ${props => (props.discounted ? props.theme.colors.secondaryTeal : props.theme.colors.neutralGrey8)};
  font-weight: ${props =>
    !(props.discounted || props.final) ? props.theme.fontWeights.regular : props.theme.fontWeights.medium};
  font-size: ${props => (props.final ? props.theme.pxToRem(18) : props.theme.pxToRem(14))};
  text-decoration: ${props => (!(props.discounted || props.final) ? 'line-through' : 'none')};
`;

const PricingValueFooter = styled(PricingValue)<ThemedStyledProps<PricingProps, DefaultTheme>>`
  padding-top: ${props => props.theme.pxToRem(4)};
  padding-right: ${props => props.theme.pxToRem(6)};
`;

const StyledDivDetails = styled.div<any>`
  padding-top: ${props =>
    props['is-contribution'] && !props['is-product-has-user-price'] ? props.theme.pxToRem(12) : undefined};
`;
