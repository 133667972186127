import { emptyObject } from 'utils';
import { Routes } from 'constants/index';
import { FirmAdminTableType } from 'constants/firm-admin';
import { FirmAdmin as FirmAdminUtils } from 'mxp-utils';
import { User } from 'mxp-schemas';
import { Dispatch } from 'redux';

// ------------------------------------
// Helpers
// ------------------------------------
export const combineHashWithEditedHash = <T = FirmAdmin.FirmMember>(
  original: FirmAdmin.Hash<T>,
  edited: FirmAdmin.Hash<T>,
  membershipsSlugMap: any,
  type?: FirmAdmin.FirmAdminTableType
): FirmAdmin.Hash<T> => {
  return Object.keys(original || emptyObject).reduce(
    (acc, key: string) => {
      const o: any = original[key];
      const e: any = edited[key];
      if (e) acc[key] = { ...o, ...e };

      if (type === FirmAdminTableType.UPGRADABLE_MEMBERS) {
        acc[key] = {
          ...acc[key],
          initialMembershipType: o.membershipType,
          price: FirmAdminUtils.getQuoteItemPrice(o),
        };
      }

      if (type === FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION) {
        acc[key] = {
          ...acc[key],
          initialFlpSubscriptionType: o.flpSubscriptionType,
          initialFlpSubscriptionDuration: o.flpSubscriptionDuration,
          price: FirmAdminUtils.getQuoteItemPrice(o),
        };
      }

      if (type === FirmAdminTableType.FLP_ORGANIZATION_ADDONS) {
        acc[key] = {
          ...acc[key],
          initialFlpSubscriptionType: o.flpSubscriptionType,
          initialFlpSubscriptionDuration: o.flpSubscriptionDuration,
          price: FirmAdminUtils.getQuoteItemPrice(o),
        };
      }
      // membershipTier on Membership and addons is a combination of user modification + quote + sf membershipTier
      acc[key].membershipTier =
        e?.membershipTier || o.quoteItems?.find((i: any) => i.isMembership)?.newVariantSku || o.membershipTier;
      const slug = acc[key].membershipType;
      const sku = acc[key].membershipTier;

      if (!slug && !membershipsSlugMap?.[slug]) return acc;
      const { name, tierMapBySku } = membershipsSlugMap?.[slug] || {};
      acc[key].membershipTypeName =
        name || (slug === User.B2BMembershipTypes.STUDENT_AFFILIATE ? 'AICPA-Membership - Student Affiliate' : slug);
      acc[key].membershipTierName = tierMapBySku?.[acc[key].membershipTier]?.tierName || sku;
      return acc;
    },
    { ...(original as any) }
  );
};

export const routeByTableType = (type: FirmAdmin.FirmAdminTableType) => {
  switch (type) {
    case FirmAdminTableType.MANAGE_FIRM_BILLING:
      return Routes.FIRM_BILLING;
    case FirmAdminTableType.MANAGE_FIRM_ROSTER:
      return Routes.FIRM_ROSTER;
    case FirmAdminTableType.CIMA_ORGANIZATION_BILLING:
      return Routes.FIRM_BILLING;
    case FirmAdminTableType.CIMA_ORGANIZATION_ROSTER:
      return Routes.FIRM_ROSTER;
    case FirmAdminTableType.UPGRADABLE_MEMBERS:
      return Routes.FIRM_ROSTER;
    case FirmAdminTableType.MEMBERSHIPS_AND_ADDONS:
      return Routes.FIRM_BILLING_TAB_MEMBERSHIPS;
    case FirmAdminTableType.FLP_ORGANIZATION_ADDONS:
      return Routes.FIRM_BILLING_TAB_MEMBERSHIPS;
    case FirmAdminTableType.FLP_ORGANIZATION_BILLING:
      return Routes.FIRM_BILLING_TAB_FLP_ORGANIZATION;
    case FirmAdminTableType.FLP_ADD_SUBSCRIPTION:
      return Routes.FIRM_ROSTER_TAB_FLP_SUBSCRIPTIONS;
    default:
      return Routes.FIRM_ROSTER;
  }
};

export const isFLPCapable = (org: FirmAdmin.Organization | null): boolean => {
  if (!org) return false;
  if (org?.organizationCapabilities?.isFLP) return true;
  if (org?.branches?.length) {
    return org?.branches?.some((branch: FirmAdmin.Organization) => isFLPCapable(branch));
  }
  return false;
};

export const requestInvoices = async ({
  tableType,
  rootOrganizationId,
  setIsLoading,
  gql,
  isPaidInvoice,
  isPaidInvoiceFetchedData,
  defaultHash,
  quoteStatus,
  dispatch,
  isLoading,
  type,
  request,
}: {
  tableType: string;
  rootOrganizationId: string;
  setIsLoading: any;
  gql: string;
  isPaidInvoice?: boolean;
  isPaidInvoiceFetchedData?: boolean;
  defaultHash: any;
  dispatch: Dispatch;
  quoteStatus?: string;
  isLoading: boolean;
  type: string;
  request: any;
}): Promise<any> => {
  let newQuoteStatus = quoteStatus;
  const propertyInvoicesObject =
    tableType === FirmAdminTableType.MANAGE_FIRM_BILLING ? 'getFirmBillingInvoices' : 'getCenterMembershipInvoices';
  const propertyInvoices =
    tableType === FirmAdminTableType.MANAGE_FIRM_BILLING ? 'firmBillingInvoices' : 'centerMembershipInvoices';
  const getInvoicesRequest: any = async (resultArray: any[], nextPage?: string) => {
    const getInvoicesResponse = await request(gql, {
      rootOrganizationId,
      isPaid: isPaidInvoice,
      nextPage,
    });

    if (getInvoicesResponse?.[propertyInvoicesObject]?.nextPage) {
      return getInvoicesRequest(
        [...resultArray, ...getInvoicesResponse[propertyInvoicesObject][propertyInvoices]],
        getInvoicesResponse?.[propertyInvoicesObject]?.nextPage
      );
    }

    newQuoteStatus = getInvoicesResponse?.[propertyInvoicesObject]?.quotesStatus;
    return [...resultArray, ...getInvoicesResponse[propertyInvoicesObject][propertyInvoices]];
  };

  let returnHash: any = defaultHash;

  if (isLoading || isPaidInvoiceFetchedData !== isPaidInvoice) {
    await dispatch(setIsLoading(type));
    const InvoicesResponse = await getInvoicesRequest(
      [],
      `/v1/transactions/invoices/accounts/${rootOrganizationId}?pageSize=40&page=1`
    );
    returnHash = InvoicesResponse.reduce(
      (acc: FirmAdmin.Hash<FirmAdmin.FirmBillingInvoice>, item: FirmAdmin.FirmBillingInvoice) => {
        acc[item.id] = item;
        return acc;
      },
      {}
    );
  }

  return { returnHash, newQuoteStatus };
};
