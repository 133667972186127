import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { LoginAndRegistration } from 'components/molecules/LoginAndRegistration/LoginAndRegistration';
import { User as UserTypes } from 'mxp-schemas';
import { pageToShowSelector, isAuthenticationModalVisibleSelector } from 'modules/layouts/selectors';
import { closeAuthenticationModal, showLogin, showRegistration } from 'modules/layouts';
import {
  userErrorSelector,
  userLoadingSelector,
  isAuthSelector,
  userPasswordUpdatedSelector,
  registrationLoadingSelector,
} from 'modules/user/selectors';
import {
  clearUserError,
  createUser,
  checkUserExists,
  login,
  setUserPasswordUpdated,
  setUserError,
  setRegistrationLoading,
} from 'modules/user/actions';

import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { push, getLocation } from 'connected-react-router';
import { getInviteIdSelector } from 'modules/startup/selectors';
import { getMembershipInviteData } from 'modules/membership/actions';
import { membershipLoggedOutInviteDataSelector } from 'modules/membership/selectors';
import { blacklistEmailDomainsSelector } from 'modules/app/selectors';

const checkEmailAgainstBlockedDomains = (email: string, blockedEmailDomains: string[]) => {
  const domain = email.split('@')[1];
  return blockedEmailDomains.includes(domain);
};

const mapActionCreators = (dispatch: Dispatch, { isModal }: { isModal: boolean }) => ({
  closeAuthenticationModal(): void {
    dispatch(closeAuthenticationModal());
  },

  showRegistration({ email }: { email: string }): void {
    dispatch(showRegistration({ redirectToRegistrationPage: !isModal, email }));
  },

  showLogin({ email }: { email: string }): void {
    dispatch(showLogin({ redirectToLoginPage: !isModal, email }));
  },

  login(email: string, password: string, remember: boolean, blockedEmailDomains: string[]): void {
    // clear possible password updated message
    dispatch(setUserPasswordUpdated(false));

    const userBlacklisted = checkEmailAgainstBlockedDomains(email, blockedEmailDomains);

    dispatch(checkUserExists(email))
      .then(({ payload: { userExists } }: { payload: { userExists: boolean } }) => {
        if (userBlacklisted) {
          dispatch(setUserError(UserTypes.UserErrorCodes.LOGIN_NOT_FOUND));
        } else if (userExists) {
          dispatch(login(email, password, remember));
        } else {
          dispatch(setUserError(UserTypes.UserErrorCodes.LOGIN_NOT_FOUND));
        }
      })
      .catch((e: any) => {
        // when error unknown
        // tslint:disable-next-line: prettier
        if (!e?.errorCode) {
          dispatch(push(getPath(Routes.NOT_FOUND)));
        }
      });
  },

  register(email: string, newUser: UserTypes.NewUser, blockedEmailDomains: string[]): void {
    dispatch(setRegistrationLoading(true));

    const userBlacklisted = checkEmailAgainstBlockedDomains(email, blockedEmailDomains);

    dispatch(checkUserExists(email)).then(({ payload: { userExists } }: { payload: { userExists: boolean } }) => {
      if (userBlacklisted) {
        dispatch(setUserError(UserTypes.UserErrorCodes.REGISTRATION_ERROR));
        dispatch(setRegistrationLoading(false));
      } else if (userExists) {
        dispatch(setUserError(UserTypes.UserErrorCodes.USER_ALREADY_EXISTS));
        dispatch(setRegistrationLoading(false));
      } else {
        dispatch(createUser(newUser));
      }
    });
  },

  clearUserError(): void {
    dispatch(clearUserError());
  },

  async getMembershipInviteData(inviteId: string): Promise<void> {
    await dispatch(getMembershipInviteData(inviteId));
  },
});
const mapStateToProps = (state: State.Root) => {
  const { pathname: currentPathname } = getLocation(state);

  const blockedEmailDomains = blacklistEmailDomainsSelector(state)
    .split(',')
    .map(domain => domain.trim());

  return {
    isAuthenticationModalVisible: isAuthenticationModalVisibleSelector(state),
    loading: userLoadingSelector(state),
    error: userErrorSelector(state),
    pageToShow: pageToShowSelector(state),
    isAuth: isAuthSelector(state),
    currentPathname,
    userPasswordUpdated: userPasswordUpdatedSelector(state),
    registrationLoading: registrationLoadingSelector(state),
    inviteId: getInviteIdSelector(state),
    inviteData: membershipLoggedOutInviteDataSelector(state),
    blockedEmailDomains,
  };
};

export const LoginAndRegistrationContainer = connect(mapStateToProps, mapActionCreators)(LoginAndRegistration);
