import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import { AicpaLogo } from 'components/atoms';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { StaticLandingPageHeaderButtons } from 'components/molecules/StaticLandingPageHeaderButtons/StaticLandingPageHeaderButtons';

interface Props {
  navigate: (path: string) => void;
  clearSuggestions: () => void;
  setSearchModal: (expanded: boolean) => void;
}

export const HeaderPlain = React.memo(({ navigate, clearSuggestions, setSearchModal }: Props) => {
  const goToHome = React.useCallback(() => {
    navigate(getPath(Routes.ROOT));
  }, [navigate]);

  return (
    <Container>
      <Container fluid data-testid="header-row-search">
        <StyledGrid columns={3}>
          <Grid.Column computer={4} mobile={4}>
            <LogoStyled onClick={goToHome} />
          </Grid.Column>
          <Grid.Column computer={8} mobile={8} />
          <Grid.Column computer={4} mobile={4}>
            <StaticLandingPageHeaderButtons
              logInButtonTestId="uw-login"
              registerButtonTestId="uw-register"
              navigate={navigate}
            />
          </Grid.Column>
        </StyledGrid>
      </Container>
    </Container>
  );
});

const StyledGrid = styled(Grid)`
  &&& {
    display: flex;
    align-items: center;
    height: ${props => props.theme.pxToRem(89)};
    margin: 0;
  }
`;

interface ILogoStyled {
  onClick(event: React.MouseEvent<HTMLDivElement>): void;
}

const LogoStyled = styled(AicpaLogo)<ILogoStyled>`
  cursor: pointer;
`;
