import * as Sentry from '@sentry/browser';

import { getPreloadedConfig, clientConfig } from '../../config';
import { getObjectContentValue, isServer } from 'utils';
import { getIsLowerEnvironment } from 'utils/env';
import { EVENTS_MAP } from './constants';
import { AllowedTealiumValues, Modifier, UTAG_TYPES } from './types';
import { getPageNameAndSiteSection, validateEvent } from './helpers';
import { store } from '../../store';
import { productCurrencySelector } from 'modules/products/selectors';
import { LOCATION_CHANGE } from 'connected-react-router';
import { personAccountDataSelector, userAuthStatusSelector, userDataSelector } from 'modules/user/selectors';
import { UserHomepage } from 'constants/index';

let lastPath: string | undefined = '';

const AICPA_PREFIX = 'aicpa_';

const waitForAnalyticsPromise = (function waitFor(count: number = 1): Promise<boolean> {
  // window object must be checked directly due to runtime execution
  if (typeof window === 'undefined') return Promise.resolve(false);
  if (getIsLowerEnvironment) return Promise.resolve(true);
  const isUTagExist: boolean = Boolean((window as any).utag_data);
  if (isUTagExist) return Promise.resolve(isUTagExist);

  return new Promise(resolve => {
    if (!isUTagExist) {
      // 5 seconds max wait time
      if (count === 5) {
        if (!isServer && clientConfig?.isTealiumEnabled) {
          try {
            Sentry.captureException(new Error('Tealium Tag manager not found'));
          } catch {
            console.error('Sentry failed to capture tealium tag manager not found error');
          }
        }
        return resolve(false);
      }
      setTimeout(() => resolve(waitFor(count + 1)), 1000);
    } else {
      return resolve(true);
    }
  });
})();

export const handleEvent = async (payload: any, evntType: string, state?: any) => {
  const getState = state || store?.getState();
  const isAppInitialized = getState?.app?.appInitialized;
  let type: string = evntType;

  if (type !== LOCATION_CHANGE && !isAppInitialized) return;

  if (!(type in EVENTS_MAP)) return;
  // FixMe. waitForAnalyticsPromise must be called only once in runtime, skipped because of ReferenceError: regeneratorRuntime is not defined
  const isUTagExist: boolean = await waitForAnalyticsPromise;
  const eventType = EVENTS_MAP[type].type;
  if (!isUTagExist) return;
  const referrer: string | undefined = eventType === UTAG_TYPES.VIEW ? lastPath : undefined;

  const toHandleEvent = validateEvent(type, getState, { ...payload, referrer });
  if (!toHandleEvent) return;

  const modifier: Modifier = EVENTS_MAP[type].accessors.reduce(
    (acc: Modifier, curr) => {
      let value: AllowedTealiumValues =
        curr.path !== '' ? getObjectContentValue(payload, curr.path, curr.default || '') : payload;
      if (curr.processor) value = curr.processor(value, getState);

      // remove a specific key:value pair of the event with nullish values
      const hideNulls = EVENTS_MAP[type].hideNulls;

      if (typeof hideNulls === 'boolean' && hideNulls && (value === null || value === '')) return acc;

      if (typeof hideNulls === 'object') {
        const hideNullKey = hideNulls.find(item => {
          return item === curr.name && (value === null || value === '');
        });
        if (hideNullKey) return acc;
      }

      acc[`${AICPA_PREFIX}${curr.name}`] = value;
      return acc;
    },
    referrer ? { aicpa_dom_referrer: referrer } : {}
  );

  updateLayer(modifier, EVENTS_MAP[type].name, eventType);
  if (eventType === UTAG_TYPES.VIEW) {
    lastPath = window.location.href;
  }
  return modifier;
};

const getPageName = () => {
  const { pageName, siteSection } = getPageNameAndSiteSection();
  return {
    aicpa_page_name: pageName,
    aicpa_site_section: siteSection,
  };
};

const getCurrency = () => {
  const state = store?.getState();
  const currency = state ? productCurrencySelector(state)?.label : 'USD';
  return {
    aicpa_currency: currency,
  };
};

const getUserDetails = () => {
  const state = store?.getState();
  if (!state) return;

  const authStatus = userAuthStatusSelector(state);
  const user = userDataSelector(state);
  const personContactId = personAccountDataSelector(state);

  if (authStatus !== UserHomepage.LOGGED_IN) return;
  if (!authStatus || !user || !personContactId) return;

  return {
    aicpa_user_login: authStatus,
    aicpa_user_id: user.AICPAUID,
    aicpa_user_okta_id: user.userId,
    aicpa_user_firstname: user.firstName,
    aicpa_user_lastname: user.lastName,
    aicpa_user_email: user.email,
    aicpa_user_email_2: user.secondEmail,
    aicpa_user_person_contact_id: personContactId.personContactId,
  };
};

const updateLayer = (modifier: Modifier, event?: string, type?: string) => {
  (window as any).utag_data = {
    ...getPageName(),
    ...getCurrency(),
    ...getUserDetails(),
    ...getGenericAttributes((window as any).utag_data),
    ...modifier,
  };
  if (event) fireEvent(event, type);

  return (window as any).utag_data;
};

// returns the generic analytics event attributes, removing event-specific ones
export const getGenericAttributes = (utagData: any) => {
  return utagData
    ? Object.keys(utagData)
        .filter(
          attrKey =>
            attrKey.startsWith(`${AICPA_PREFIX}user`) ||
            attrKey.startsWith(`${AICPA_PREFIX}page`) ||
            attrKey.startsWith(`${AICPA_PREFIX}site`) ||
            attrKey.startsWith(`${AICPA_PREFIX}dom`)
        )
        .reduce((acc, curr) => {
          return {
            ...acc,
            [curr]: utagData[curr],
          };
        }, {})
    : {};
};

const fireEvent = async (key: string, type?: string): Promise<any> => {
  if (type === UTAG_TYPES.VIEW) {
    (window as any).utag?.view({
      ...(window as any).utag_data,
      tealium_event: key,
    });

    const pathName = (window as any).location.pathname;

    if (typeof (window as any).twq === 'function') {
      (window as any).twq('track', 'PageView');
    }

    if (typeof (window as any).gtag === 'function') {
      (window as any).gtag('config', `${getPreloadedConfig()?.googleTagManagerTag?.id}`, {
        page_path: pathName,
      });
    }

    if (typeof (window as any).uetq !== 'undefined') {
      (window as any).uetq?.push('event', 'page_view', { page_path: pathName });
    }

    if (getIsLowerEnvironment) {
      console.groupCollapsed(`Analytics VIEW event ${key} ${(window as any).utag_data?.aicpa_page_name}`);
      // tslint:disable-next-line
      console.log(`${key}`);
      // tslint:disable-next-line
      console.log((window as any).utag_data);
      console.groupEnd();
    }
    return;
  }
  (window as any).utag?.link({
    ...(window as any).utag_data,
    tealium_event: key,
  });
  if (getIsLowerEnvironment) {
    console.groupCollapsed(`Analytics LINK event ${key}`);
    // tslint:disable-next-line
    console.log(`${key}`);
    // tslint:disable-next-line
    console.log((window as any).utag_data);
    console.groupEnd();
  }
};
