// ------------------------------------
// Constants

// ------------------------------------
export const initialState: State.IndividualConsent = {
  channel: {
    sms: false,
  },
  shareFirmBillingData: false,
  standardMemberDirectory: false,
  enhancedMemberDirectory: false,
  doNotSellMyPersonalInfo: false,
  isLoading: true,
  // shareMyData: false,
  // claimCertificate: false,
  // shareExamResult: false,
  // shareDataWithTP: false,
  // shareExamWithTP: false,
};
