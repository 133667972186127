import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ArticleFeedback } from 'components/organisms/ArticleFeedback';
import { getUserFeedbackValues, putUserFeedbackValues } from 'modules/user/actions';
import { userFeedbackValuesSelector } from 'modules/user/selectors';
import { sendContentFeedback } from 'modules/content/actions';

const mapActionCreators = (dispatch: Dispatch) => ({
  submitFeedback(contentId: string, feedback: State.UserFeedbackRecord): void {
    dispatch(putUserFeedbackValues(contentId, feedback));
    dispatch(sendContentFeedback(feedback));
  },
  getUserContentFeedbackValues(contentId: string): void {
    dispatch(getUserFeedbackValues(contentId));
  },
});

const mapStateToProps = (state: State.Root) => ({
  values: userFeedbackValuesSelector(state),
});

export const ArticleFeedbackContainer = connect(mapStateToProps, mapActionCreators)(ArticleFeedback);
