import { TRenewMembershipDetailsTableData, TRowProps, TMultiRowProps } from 'components/pages/PageRenewYourMembership';
import { RenewMembershipOptions } from 'constants/index';

export const setRowProps = (
  item: string,
  accountType: TRenewMembershipDetailsTableData['accountType'],
  addOns: TRenewMembershipDetailsTableData['addOns'],
  membershipCredentials: TRenewMembershipDetailsTableData['membershipCredentials'],
  membershipSections: TRenewMembershipDetailsTableData['membershipSections'],
  serviceTier: TRenewMembershipDetailsTableData['serviceTier']
): TRowProps | TMultiRowProps[] | undefined => {
  if (item === RenewMembershipOptions.ACCOUNT_TYPE) {
    return accountType;
  }
  if (item === RenewMembershipOptions.ADD_ONS) {
    return addOns;
  }
  if (item === RenewMembershipOptions.MEMBERSHIP_CREDENTIALS) {
    return membershipCredentials;
  }
  if (item === RenewMembershipOptions.MEMBERSHIP_SECTIONS) {
    return membershipSections;
  }
  if (item === RenewMembershipOptions.SERVICE_TIER) {
    return serviceTier;
  }
  return undefined;
};
