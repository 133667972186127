import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { PageCenterAdminNavigation } from 'components/pages/PageCenterAdminNavigation/PageCenterAdminNavigation';
import { getOrganizations } from 'modules/centerAdmin/index';
import { selectedOrganizationSelector, selectedOrganizationIdSelector } from 'modules/centerAdmin/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  getOrganizations(): void {
    dispatch(getOrganizations()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
});

const mapStateToProps = (state: State.Root) => ({
  selectedOrganization: selectedOrganizationSelector(state),
  id: selectedOrganizationIdSelector(state),
});

export const PageCenterAdminNavigationContainer = connect(
  mapStateToProps,
  mapActionCreators
)(PageCenterAdminNavigation);
