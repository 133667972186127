import moment, { unitOfTime } from 'moment-timezone';
import { ContentTypes, OtherPaymentTypes } from '../constants';
import { Product } from 'mxp-schemas';

moment.relativeTimeRounding(Math.floor);
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 30);
moment.relativeTimeThreshold('M', 12);

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

export class MomentHelpers {
  static isCardExpired(card: State.CreditCard): boolean {
    if (card.cardType.toLowerCase() === OtherPaymentTypes.ACH.toLowerCase()) {
      return false;
    }
    return MomentHelpers.isYearMonthExpired(card.expirationYear, card.expirationMonth);
  }

  static isYearMonthExpired(year?: number, month?: number): boolean {
    const dt = new Date();
    return !moment(`${year || dt.getFullYear()}-${month || dt.getMonth()}-01`, 'YYYY-MM-DD')
      .add(1, 'M')
      .isAfter(moment());
  }

  static getAgoTime(date: string): string {
    return moment(date).fromNow();
  }

  static getDate(date?: string, withMonthDot?: boolean): string {
    if (!date) return '';
    const monthDot = withMonthDot ? '.' : '';
    return moment(date).format(`MMM${monthDot} DD, YYYY`);
  }

  static getTz(date: string | undefined): string {
    const momentTimeZone = moment.tz.zone(moment.tz.guess());
    const timeZone = momentTimeZone ? momentTimeZone.abbr(date ? new Date(date).getTime() : Date.now()) : 'UTC';
    return timeZone.startsWith('+') || timeZone.startsWith('-') ? `UTC ${timeZone}` : timeZone;
  }

  static getDiff(date1: string, date2: string, unit?: unitOfTime.Diff): number {
    return moment(date1).diff(date2, unit);
  }

  static getISOString(date?: string): string {
    return date ? moment(date).toISOString() : moment().toISOString();
  }

  static getHours(date: string): string {
    return moment(date).format('ha');
  }

  static getHoursAndMinutes(date: string): string {
    return moment.utc(date).local().format('h A');
  }

  static getTimeDiffInHours = (date: string): number => {
    const currentTime = moment();
    const accessDate = moment(date);
    const timeDiff = accessDate.diff(currentTime, 'h');

    return timeDiff;
  };

  static getTimeToConsumeOrSize({
    time,
    category,
    downloads,
  }: {
    time: number | null;
    category?: string;
    downloads?: State.Download[];
  }): string {
    if (category === ContentTypes.DOWNLOAD) {
      const hasDownloads: boolean = Boolean(downloads && downloads.length);
      const fileSize: string | null | undefined = hasDownloads ? downloads![0].fileSize : null;
      return fileSize ? `${fileSize} Download` : '';
    }
    const consumptionType = (categoryType: string = ContentTypes.ARTICLE) =>
      ({
        [ContentTypes.VIDEO]: 'watch',
        [ContentTypes.PODCAST]: 'listen',
        [ContentTypes.ARTICLE]: 'read',
      }[categoryType]);
    if (!time) return '';
    const fixMinutes: number = time < 60 || time % 60 > 29 ? 1 : 0;
    const utcTime = moment.utc(time * 1000);
    return utcTime.hours()
      ? `${utcTime.hours()} hour ${utcTime.minutes() + fixMinutes} min ${consumptionType(category)}`
      : `${utcTime.minutes() + fixMinutes} min ${consumptionType(category)}`;
  }

  static getSecondsToMinutes(time: number): string {
    const utcTime = moment.utc(time * 1000);
    return utcTime.hours() ? utcTime.format('H:mm:ss') : utcTime.format('mm:ss');
  }

  static convertToMoment = (date: string, format: string) => {
    return moment(date, format);
  };

  static getDateBasedOnUserTimeZone(date: string): any {
    const dateToUtc = moment.utc(date);

    return dateToUtc.tz(moment.tz.guess());
  }

  static getProductVariantTime(date: any): string {
    return date.format('h:mmA').replace(':00', '').toLowerCase();
  }

  static getProductVariantDuration(startDate: any, endDate: any): number {
    return endDate.diff(startDate, 'hours');
  }

  static getDurationISOString(duration: number, unit?: any): string {
    return moment.duration(duration, unit).toISOString();
  }

  static daysToMonthYear = (days: number): string => {
    if (!days) return '';

    const MONTH = 30;
    const YEAR = 365;

    const monthRemainder = days % MONTH;
    const yearRemainder = days % YEAR;

    const fullMonthMultiply = monthRemainder === 0 && monthRemainder !== days;
    const fullYearMultiply = yearRemainder === 0 && yearRemainder !== days;

    if (days === 1) return '1 day';
    if (days === MONTH) return '1 month';
    if (days === YEAR) return '1 year';

    if (fullYearMultiply) return `${days / YEAR} years`;
    if (fullMonthMultiply) return `${days / MONTH} months`;

    return `${days} days`;
  };

  static daysToAvailabilityDuration = (days: number): string => {
    if (!days) return '';

    const MONTH = 30;
    const YEAR = 365;

    switch (days) {
      case MONTH:
        return '1 month';
      case 3 * MONTH:
        return '3 months';
      case YEAR:
        return '1 year';
      case 2 * YEAR:
        return '2 years';
      default:
        return `${days} days`;
    }
  };

  static formatExpiryDate = (endDate: string) => moment(new Date(endDate)).format(`MMM DD, YYYY`);

  static isDateBefore = (dateOne: string, dateTwo: string) => {
    return moment(dateOne).isBefore(dateTwo);
  };

  static getFullDay = (date: string): string => {
    return moment(date).add(1, 'days').subtract(1, 'milliseconds').toISOString();
  };

  static getLocalTimeWithTimezone = (date?: string, dateTimeZone?: string): LocalTime => {
    if (!dateTimeZone || !date) {
      return MomentHelpers.getLocalTime(date);
    }
    const now = moment.utc().unix();
    // NOTE:  moment.tz.guess() may have difference for 30mins to an hour for daylight saving time please refer to this link https://gist.github.com/mattjohnsonpint/7d55b63631ea067bd8b5
    const localTimezone = moment.tz.zone(moment.tz.guess());
    const timezone = localTimezone?.abbr(now);
    const dateDefaultTimeOffset = moment.tz.zone(dateTimeZone)?.utcOffset(now);

    if (!localTimezone || !dateDefaultTimeOffset || !timezone) {
      return MomentHelpers.getLocalTime(date);
    }

    const localTimeOffset = localTimezone?.utcOffset(now);
    const localDiffInMinutes = dateDefaultTimeOffset - localTimeOffset;
    const localDateTime = moment(date).add(localDiffInMinutes, 'minutes'); // Converted Local Date and Time
    const localTime = localDateTime.format(`${localDateTime.minute() === 0 ? 'ha' : 'hh:mma'}`);
    const localDate = localDateTime.format('MMM DD, YYYY');

    return {
      localDate,
      localTime,
      localTimeWithTZ: `${localTime} ${timezone}`,
    };
  };

  static getLocalTime = (date?: string): LocalTime => {
    const timeZone = MomentHelpers.getTz(date);
    const dateTime = date ? moment(date).tz(timeZone) : moment().tz(timeZone);
    const localTime = dateTime.format(`${dateTime.minute() === 0 ? 'ha' : 'hh:mma'}`);

    return {
      localDate: dateTime.format('MMM DD, YYYY'),
      localTime,
      localTimeWithTZ: `${localTime} ${timeZone}`,
    };
  };

  static getDateFormat(date?: string): string {
    return moment(date).format(`MMM-DD`);
  }
}

interface LocalTime {
  localDate: string;
  localTime: string;
  localTimeWithTZ: string;
}

export const getMomentDateTimeInfo = (
  sku: string,
  start: string | undefined,
  end: string | undefined,
  dateRange?: string | undefined
): Product.VariantDateTimeInfo => {
  const startDateTime = MomentHelpers.getDateBasedOnUserTimeZone(start || '');
  const startDate = MomentHelpers.getDate(startDateTime);
  const endDateTime = MomentHelpers.getDateBasedOnUserTimeZone(end || '');
  const endDate = MomentHelpers.getDate(endDateTime);
  const startTime = MomentHelpers.getProductVariantTime(startDateTime);
  const endTime = MomentHelpers.getProductVariantTime(endDateTime);
  const timeZone = MomentHelpers.getTz(start);
  const duration = MomentHelpers.getProductVariantDuration(startDateTime, endDateTime);
  return { sku, start, startDate, startTime, end, endDate, endTime, timeZone, duration, dateRange };
};
