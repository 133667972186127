// ------------------------------------
// Constants
// ------------------------------------

export const CONSTANTS = {
  MEMBERSHIP: {
    PACKAGE_PAGE: {
      HEADER_1: 'membership_package_page_header_1',
      HEADER_2: 'membership_package_page_header_2',
      SUB_HEADER: 'membership_package_page_sub_header',
    },
    BANNER: {
      HEADER: 'membership_banner_header',
      SUB_HEADER: 'membership_banner_sub_header',
      BUTTON: 'membership_banner_button',
    },
  },
  LOGGED_IN_BANNER: {
    TITLE: 'logged_in_site_banner_title',
    DESCRIPTION: 'logged_in_site_banner_description',
    CTA_TEXT: 'logged_in_site_banner_CTA_text',
    CTA_URL: 'logged_in_site_banner_CTA_URL',
  },
  LOGGED_OUT_BANNER: {
    TITLE: 'logged_out_site_banner_title',
    DESCRIPTION: 'logged_out_site_banner_description',
    CTA_TEXT: 'logged_out_site_banner_CTA_text',
    CTA_URL: 'logged_out_site_banner_CTA_URL',
  },
  MEMBERSHIP_BENEFITS: {
    DOWNLOAD: {
      WELCOME_PACK: 'welcome_pack',
    },
  },
  SEARCH_PAGE_TOP_AD_BANNER: 'search_page_top_ad_banner',
  SEARCH_PAGE_BOTTOM_AD_BANNER: 'search_page_bottom_ad_banner',
  CIMA_CONTINUOUS_REGULAR_MEMBERSHIP_FCMA_CHECK: 'cima_continuous_regular_membership_fcma_check',
  CIMA_REGIONAL_QUALIFIED_YEARS_OF_EXPERIENCE: 'regional_qualified_years_of_experience',
  CIMA_REGIONAL_PATHWAY_PROP_PAGE_SLUG: 'cima_regional_pathway_prop_page_slug',
  CIMA_PREMIUM_PRODUCT_REFERENCE_SLUG: 'cima_premium_product_reference_slug',
  CIMA_CFO_PRODUCT_REFERENCE_SLUG: 'cima_cfo_product_reference_slug',
  CIMA_MEMBERSHIP_LANDING_PAGE_SLUG: 'cima-membership-landing-page-slug',
  CREDENTIAL_SKUS: {
    PFS: 'credential_skus_pfs',
    MIP: 'credential_skus_mip',
    ACMA: 'credential_skus_acma',
    CVFI: 'credential_skus_cvfi',
    ABV: 'credential_skus_abv',
    CEIV: 'credential_skus_ceiv',
    CGMA: 'credential_skus_cgma',
    FCMA: 'credential_skus_fcma',
    CFF: 'credential_skus_cff',
    CITP: 'credential_skus_citp',
  },
  FCMA_MEMBERSHIP_LANDING_PAGE: 'fcma_credential',
  FCMA_FELLOWSHIP_EMAIL: 'fcma_fellowship_email',
  PER_URL_MORE_INFORMATION: 'per_url_more_information',
  PER_DECLARATION_FROM: 'per_declaration_form',
  PER_DECLARATION_FORM_TEMPLATE: 'per-declaration-form',
  GUIDANCE_DOCUMENTS: 'guidance_documents',
  AICPA_URL_LINK: 'aicpa_url_link',
  AICPA_URL_TITLE: 'aicpa_url_title',
  BLOCKED_RENEWAL_COUNTRIES: 'blocked_renewal_countries',
  CIMA_URL_LINK: 'cima_url_link',
  CIMA_URL_TITLE: 'cima_url_title',
  FCMA_FILE_DOWNLOAD: 'fcma_documents',
  FLP_RENEWAL_SEASON_OPEN: 'flp_renewal_season_open',
  PQ_RENEWAL_SEASON_OPEN: 'pq_renewal_season_open',
  APPRENTICE_RENEWAL_SEASON_OPEN: 'apprentice_renewal_season_open',
  CIMA_MEMBERSHIP_MAX_FILE_UPLOAD: 'cima_membership_max_file_upload',
  SPECIAL_CONSIDERATIONS_POLICY_FORM: 'special_considerations_policy_form',
  CIMA_EDUCATIONAL_QUALIFICATION_GRADUATION_YEAR_MIN: 'cima_educational_qualification_graduation_year_min',
  CIMA_EDUCATIONAL_QUALIFICATION_GRADUATION_YEAR_MAX: 'cima_educational_qualification_graduation_year_max',
  CIMA_PRACTICE_EXPERIENCE_REQUIREMENTS_GUIDANCE_FOR_PER_SUPERVISOR:
    'cima-practical-experience-requirements-guidance-for-per-supervisors',
  CIMA_EXAMS_DASHBOARD: {
    BUSINESS: 'business_and_finance_essentials_course',
    CERT_BA: 'cima_cert_ba',
    EPA_4: 'epa_level_4',
    EPA_7: 'epa_level_7',
    EPA_L4_REFLECTIVE_STATEMENTS_EXAMPLE: 'epa-l4-reflective-statements-example',
    EPA_L4_REFLECTIVE_STATEMENTS_GUIDE: 'epa-l4-reflective-statements-guide',
    EPA_L4_REFLECTIVE_STATEMENTS_TEMPLATE: 'epa-l4-reflective-statements-template',
    EPA_L4_PORTFOLIO_EVIDENCE_EXAMPLE: 'epa-l4-portfolio-evidence-example',
    EPA_L4_PORTFOLIO_EVIDENCE_GUIDE: 'epa-l4-portfolio-evidence-guide',
    EPA_L4_PORTFOLIO_EVIDENCE_TEMPLATE: 'epa-l4-portfolio-evidence-template',
    EPA_L7_PROJECT_REPORT_DATA_EXAMPLE: 'epa-l7-project-report-data-example',
    EPA_L7_PROJECT_REPORT_DATA_GUIDE: 'epa-l7-project-report-data-guide',
    EPA_L7_PROJECT_REPORT_DATA_TEMPLATE: 'epa-l7-project-report-data-template',
    EXAM_RELATED_INFORMATION: 'exam-related-information',
    EXAM_POLICIES: 'exam-policies',
    EPA_INFORMATION: 'epa-information',
    MANAGEMENT: 'management_level',
    OPERATIONAL: 'operational_level',
    STRATEGIC: 'strategic_level',
    ACADEMIC_GUIDANCE: 'professional-background-academic-guidance-documents-10-years-constant',
    EXPERIENTIAL_GUIDANCE: 'professional-background-experiential-guidance-documents-6-10-constant',
  },
  MIP_LINKS: {
    CONTACT_APPLICATION: {
      APPLICATION_CHECKLIST: 'mip_contact_application_checklist',
    },
    WORK_HISTORY_APPLICATION: {
      GEC_EMAIL: 'gec_email',
    },
    AML_APPLICATION: {
      AML_URL_REGULATION_24: 'aml_url_regulation_24',
      AML_URL_REGULATION_28: 'aml_url_regulation_28',
      AML_URL_SECTION_28: 'aml_url_section_28',
      AML_URL_AMENDED_ACT_2021: 'aml_url_amended_act_2021',
      AML_URL_SECTION_30A: 'aml_url_section_30A',
      AML_URL_CIMA_MEMBER_HANDBOOK: 'aml_url_cima_member_handbook',
      AML_URL_OFSI: 'aml_url_ofsi',
      AML_URL_CENTRAL_BANK_IRELAND: 'aml_url_central_bank_ireland',
      AML_URL_REGULATION_18: 'aml_url_regulation_18',
      AML_URL_PRACTICE_RISK_ASSESSMENT: 'aml_url_practice_risk_assessment',
      AML_URL_REGULATION_19: 'aml_url_regulation_19',
      AML_URL_CTF_POLICIES: 'aml_url_ctf_policies',
      AML_URL_ML_2017_REGULATION_54: 'aml_url_ml_2017_regulation_54',
      AML_URL_IRELAND_SECTION_54: 'aml_url_ireland_section_54',
      AML_URL_CENTRAL_BANK_IRELAND_EMAIL: 'aml_url_central_bank_ireland_email',
    },
    MIP_ATTESTATION: {
      MIP_ATTESTATION_URL_CIMA_REGULATION: 'mip_attestation_url_cima_regulation',
      MIP_ATTESTATION_URL_CIMA_ETHICAL_CONDUCT: 'mip_attestation_url_cima_ethical_conduct',
      MIP_ATTESTATION_URL_TERMS_OF_ENGAGEMENT: 'mip_attestation_url_terms_of_engagement',
      MIP_ATTESTATION_URL_CIMA_COMPLAINT_HANDLING_PROCEDURE: 'mip_attestation_url_cima_complaint_handling_procedure',
      MIP_ATTESTATION_URL_CIMA_PROFESSIONAL_DEVELOPMENT: 'mip_attestation_url_cima_professional_development',
    },
  },
  MIP_WORK_HISTORY_CV_FORMAT: 'mip_work_history_cv_format',
  MIP_RENEWAL_SEASON_START: 'mip_renewal_season_start',
  MIP_RENEWAL_SEASON_END: 'mip_renewal_season_end',
  FCMA_DESIGNATION: {
    TITLE: 'fcma_designation_title',
    DESCRIPTION: 'fcma_designation_description',
  },
  RENEWAL_DONATIONS_BANNER: {
    RENEWAL_DONATIONS_BANNER_HEADER_DESKTOP: 'renewal_donations_banner_header_desktop',
    RENEWAL_DONATIONS_BANNER_BOLD_TITLE_DESKTOP: 'renewal_donations_banner_bold_title_desktop',
    RENEWAL_DONATIONS_BANNER_SUBTITLE_DESKTOP: 'renewal_donations_banner_subtitle_desktop',
    RENEWAL_DONATIONS_BANNER_DESCRIPTION_DESKTOP: 'renewal_donations_banner_description_desktop',
  },
};

export const DOWNLOAD_CONSTANTS = {
  MIP_UK_FILE_DOWNLOAD: 'mip_uk_documents',
  MIP_IRELAND_FILE_DOWNLOAD: 'mip_ireland_documents',
  MIP_OTHER_COUNTRIES_FILE_DOWNLOAD: 'mip_other_countries_documents',
};
