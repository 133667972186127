import React from 'react';
import styled from 'styled-components';
import { generatePath } from 'react-router-dom';
import { ButtonEnums, ButtonLink, Divider } from 'components/atoms';
import { getPath, MomentHelpers, formatPrice } from 'utils';
import { Routes } from 'constants/index';
import { useSelector } from 'react-redux';
import { fullCimaInvoiceDetailsSelector } from 'modules/clientAdmin/selectors';

interface Props {
  invoicesPayment: State.InvoicesPaymentResponse;
}

export const InvoicesPaymentConfirmation: React.FC<Props> = ({ invoicesPayment }) => {
  const invoiceNumbers = invoicesPayment?.invoiceNumbers;
  const totalBalance = invoicesPayment?.totalBalance || 0;
  const totalAmountPaid = invoicesPayment?.totalAmountPaid || 0;
  const totalRemainingBalance: number = totalBalance - totalAmountPaid;
  const paymentDate = invoicesPayment?.paymentDate;
  const invoices = useSelector(fullCimaInvoiceDetailsSelector);
  const currency = invoices.find(item => item.currency)?.currency;

  return (
    <>
      <SummaryDetails>
        <Fields>
          <StyledDate>Payment Date: {MomentHelpers.getDate(paymentDate)}</StyledDate>
          <Text>Invoice Numbers:</Text>
          {invoiceNumbers?.map((invoice: string) => (
            <Text key={invoice}>{invoice}</Text>
          ))}
          <Text>Thank you for your payment.</Text>
        </Fields>
      </SummaryDetails>
      <InvoiceSummaryTitle data-testid="invoice-summary-title">Invoice summary</InvoiceSummaryTitle>
      <Divider />
      <SummaryDetails>
        <Fields>
          <BoldText>Total Invoice Balance:</BoldText>
          <BoldText>Amount Paid:</BoldText>
          <BoldText>Remaining Balance:</BoldText>
        </Fields>
        <Data>
          <Text>{formatPrice(totalBalance, currency)}</Text>
          <Text>{formatPrice(totalAmountPaid, currency)}</Text>
          <Text>{formatPrice(totalRemainingBalance, currency)}</Text>
        </Data>
      </SummaryDetails>
      <StyledButtonLink
        variant={ButtonEnums.variants.primary}
        to={generatePath(getPath(Routes.ORG_INVOICES))}
        testId="invoice-payment-confirmation-back-button"
      >
        Back to Invoices
      </StyledButtonLink>
    </>
  );
};

const StyledButtonLink = styled(ButtonLink)`
  &&&&&&& {
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(16)};
    margin-top: ${props => props.theme.pxToRem(40)};
  }
`;

const SummaryDetails = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${props => props.theme.pxToRem(224)};
  font-size: ${props => props.theme.fontSizes.xs};
`;

const Text = styled.div`
  font-weight: ${props => props.theme.fontWeights.light};
  padding: ${props => props.theme.pxToRem(8)} 0;
`;

const StyledDate = styled.div`
  font-weight: ${props => props.theme.fontWeights.light};
  padding: ${props => props.theme.pxToRem(16)} 0;
`;

const Fields = styled.div`
  display: flex-column;
`;

const Data = styled.div`
  display: flex-column;
`;

const BoldText = styled.div`
  font-weight: ${props => props.theme.fontWeights.medium};
  padding: ${props => props.theme.pxToRem(8)} 0;
`;

const InvoiceSummaryTitle = styled.div`
  padding: ${props => props.theme.pxToRem(21)} 0 ${props => props.theme.pxToRem(3)} 0;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.33;
`;
