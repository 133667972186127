import React from 'react';
import styled from 'styled-components';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { Loader } from 'semantic-ui-react';

interface Props {
  title: string;
  description: string;
  buttonLabel: string;
  handleButtonChange: () => void;
  loading?: boolean;
}

export const CenterMembershipCard: React.FC<Props> = ({
  title,
  description,
  buttonLabel,
  handleButtonChange,
  loading = false,
}) => {
  return (
    <>
      <StyledCard>
        {!loading ? (
          <>
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription>{description}</StyledDescription>
            <Button
              onClick={handleButtonChange}
              variant={ButtonEnums.variants.primary}
              bordered
              testId="test-id"
              iconName="plus"
              iconPosition={ButtonEnums.iconPosition.left}
            >
              {buttonLabel}
            </Button>
          </>
        ) : (
          <StyledLoader active>Loading</StyledLoader>
        )}
      </StyledCard>
    </>
  );
};

const StyledLoader = styled(Loader)`
  &&&&& {
    position: inherit;
  }
`;

const StyledCard = styled.div`
  box-sizing: border-box;
  width: 95%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  min-height: ${props => props.theme.pxToRem(250)};
  background-color: ${props => props.theme.colors.neutralWhite};

  padding-top: ${props => props.theme.pxToRem(5)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledTitle = styled.label`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 1.33;
  margin-bottom: ${props => props.theme.pxToRem(5)};
`;

const StyledDescription = styled.label`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  margin-bottom: ${props => props.theme.pxToRem(22)};
`;
