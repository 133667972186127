import React from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { generatePath } from 'react-router';
import { Product, Orders, Checkout } from 'mxp-schemas';
import { ProductsListParent } from 'components/molecules/ProductsList/ProductsList';
import {
  WarningLowStock,
  WarningNeedToAdjustQuantity,
  WarningOutOfStock,
} from 'components/molecules/PurchaseSummary/QuantityWarnings';
import { QuantityBox } from 'components/atoms/QuantityBox/QuantityBox';
import { OnlyMobileCSS, OnlyDesktopCSS, Link, Expandable } from 'components/atoms';
import { DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { Plus, Minus } from 'components/atoms/svg';
import { StyledLink } from './ProductItem';
import { StandingOrderCheckbox } from './StandingOrderCheckbox';
import {
  availableFormatToLabel,
  discontinuedAvailableFormatToLabel,
  productTypeToLabel,
  getPath,
  calculateAvailabilityDate,
} from 'utils';
import { isPhysicalProduct as isPhysicalProductCheck } from 'mxp-utils';
import { Routes } from 'constants/index';
import { ProductItemSubscriptionDetails } from './ProductItemSubscriptionDetails';
import { ConferenceProductDetails } from './ConferenceProductDetails';
import { PhysicalProductsDetails } from './PhysicalProductsDetails';
import { ContributionProductDetails } from './ContributionProductDetails';
import { ProductItemFreeTrialDetails } from './ProductItemFreeTrialDetails';

interface Props {
  item: Common.ProductItemData;
  isCartPage: boolean;
  isDiscontinued: boolean;
  isActiveProduct: boolean;
  availableFormatLabel: string;
  parentPage: ProductsListParent;
  isCredential: boolean;
  isSection: boolean;
  isMembership: boolean;
  isSubscription: boolean;
  isConference: boolean;
  isOPL: boolean;
  isPublication: boolean;
  isExam: boolean;
  isAnyPhysicalProduct: boolean;
  isPublicationPhysicalProduct: boolean;
  isCoursePhysicalProduct: boolean;
  isCourse: boolean;
  productDescription?: string;
  benefitsAccessLinks?: any;
  orderDate?: string;
  conferenceUserInfo?: Checkout.ConferenceUserInfo | null;
  productQty: { productQty: number; isEmpty: boolean };
  showTooBigQtyErrorMessage: boolean;
  showUpdateButtons?: boolean;
  quantity: number;
  productItemId?: string;
  productStandingOrder: { productStandingOrder: boolean };
  viewTextForAnalytics?: string;
  isOrderConfirmationPage: boolean;
  currentMemProductStatus?: State.QuotesStatusAndPaidByFirm | null;
  handleCheckboxClick: (e: any, data: any) => void;
  renderQtyUpdateButtons: (isDisabled: boolean) => React.ReactNode;
  renderContributionAmountInput: () => React.ReactNode;
  renderEditContributionButton: () => React.ReactNode;
  renderActionContributionButtons: () => React.ReactNode;
  handleProductQtyChange: ({ target: { value } }: any) => void;
  toggleSubscriptionRenewal?: (enable: boolean, item: Common.ProductItemData, productName: string) => void;
  isEventInNext48Hours: boolean;
  isContribution: boolean;
  isEditContributionAmountToggled: boolean;
  isCenterMembership: boolean;
  isFlpType?: boolean;
  isFeeType?: boolean;
  isPQ?: any;
  isMembershipReactivationJourney: boolean;
  isCimaRenewalSeason?: any;
  activeMembership?: any;
}

export const ProductItemDetails: React.FC<Props> = ({
  item,
  isConference,
  isDiscontinued,
  availableFormatLabel,
  parentPage,
  isSection,
  isCredential,
  isMembership,
  isSubscription,
  isOPL,
  isExam,
  isPublication,
  isCartPage,
  isAnyPhysicalProduct,
  conferenceUserInfo,
  isActiveProduct,
  isPublicationPhysicalProduct,
  isCoursePhysicalProduct,
  isCourse,
  productDescription,
  benefitsAccessLinks,
  productQty,
  showTooBigQtyErrorMessage = false,
  showUpdateButtons = false,
  productStandingOrder,
  quantity,
  productItemId,
  viewTextForAnalytics,
  isOrderConfirmationPage,
  currentMemProductStatus,
  renderQtyUpdateButtons,
  renderContributionAmountInput,
  renderEditContributionButton,
  renderActionContributionButtons,
  handleCheckboxClick,
  handleProductQtyChange,
  toggleSubscriptionRenewal,
  isEventInNext48Hours,
  isContribution,
  isEditContributionAmountToggled,
  isCenterMembership,
  isFlpType,
  isFeeType,
  isPQ,
  isMembershipReactivationJourney = false,
  isCimaRenewalSeason,
  activeMembership,
}) => {
  const {
    dateRange,
    productType,
    availableFormat,
    subscriptionProductType,
    sku,
    lineItemId,
    location,
    availableQuantity = 0,
    standingOrderEligible,
    standingOrderDiscount,
    optionalText,
    cartLineItemState,
    standingOrderStatus,
    shipmentDate,
    shouldRemindRenewal,
    autoRenewEnabled,
    fundName,
    orderNumber,
    attestationCartText,
    attestationRequired = '',
    isFreeTrial,
    flpTerm,
  } = item;

  const isSubscriptionPhysicalProduct: boolean = isPhysicalProductCheck(item).isPhSubscriptionProduct;
  const isProfilePurchasesPage: boolean = parentPage === ProductsListParent.PURCHASES_PROFILE;

  const onsiteType: string = Product.AvailableFormat.ONSITE.toUpperCase();
  const flexPassType: string = Product.AvailableFormat.FLEX_PASS.toUpperCase().replace('-', ' ');
  const isOnsiteConference: boolean = Boolean(
    isConference && (availableFormatLabel === onsiteType || availableFormatLabel === flexPassType)
  );

  const isWebcast: boolean = Boolean(productType === Product.ProductType.WEBCAST);

  const renderSubscriptionProductTypeFormatLabel = React.useMemo(() => {
    const subscriptionProductTypeLabel = subscriptionProductType?.label || '';
    if (isSubscription) {
      return isOPL
        ? availableFormatToLabel(Product.AvailableFormat.SUBSCRIPTION)
        : subscriptionProductType?.key === Product.SubscriptionProductType.PUBLICATION
        ? availableFormatToLabel(Product.AvailableFormat.MAGAZINE_SUBSCRIPTION)
        : subscriptionProductType?.key === Product.SubscriptionProductType.COURSE
        ? availableFormatToLabel(Product.AvailableFormat.CPE_SELF_STUDY)
        : subscriptionProductTypeLabel;
    }
    return '';
  }, [isSubscription, isOPL, subscriptionProductType]);

  const useAvailableFormatLabel = isConference || isPublication || isExam || isWebcast || isCourse || isDiscontinued;

  const renderFormatLabel = React.useMemo(() => {
    const availableFormatKey = availableFormat?.key || '';
    if (!availableFormatKey) return '';

    if (isCourse) {
      return availableFormatKey === Product.AvailableFormat.EXAM ||
        availableFormatKey === Product.AvailableFormat.ONLINE_EXAM
        ? availableFormatToLabel(availableFormatKey)
        : availableFormatKey === Product.AvailableFormat.TEXT
        ? availableFormatToLabel(Product.AvailableFormat.CPE_SELF_STUDY)
        : availableFormatToLabel(Product.AvailableFormat.ONLINE);
    }

    if (isExam || isPublication || isConference) {
      return isConference
        ? `Conference: ${availableFormatToLabel(availableFormatKey)}`
        : availableFormatToLabel(availableFormatKey);
    }

    if (isWebcast) {
      return availableFormat?.key === Product.AvailableFormat.MULTI_DAY
        ? Product.PRODUCT_TYPES_NAMES[Product.ProductType.WEBCAST]
        : !isCartPage
        ? availableFormatToLabel(Product.AvailableFormat.SUBSCRIPTION)
        : availableFormatToLabel(availableFormatKey);
    }

    if (isDiscontinued) {
      return discontinuedAvailableFormatToLabel(availableFormat?.key || '');
    }
  }, [isConference, isPublication, isExam, isWebcast, isCourse, availableFormat, isCartPage, isDiscontinued]);

  const formatLabel =
    renderSubscriptionProductTypeFormatLabel ||
    (useAvailableFormatLabel ? renderFormatLabel : productTypeToLabel((productType as Product.ProductType) || ''));

  const isUsingStandingOrderDiscount: boolean = isPublicationPhysicalProduct || isCoursePhysicalProduct;
  const showStandingOrder: boolean | undefined =
    isUsingStandingOrderDiscount &&
    standingOrderEligible &&
    standingOrderDiscount &&
    (standingOrderDiscount.new !== 0 || standingOrderDiscount.recurrent !== 0);
  const isRow = Boolean((isAnyPhysicalProduct || isContribution) && isCartPage);

  const membershipEndDate =
    isMembership &&
    !isCartPage &&
    item.zuoraTermEndDate &&
    moment(item.zuoraTermEndDate).subtract('1', 'days').format();

  const availabilityTitle: string = !isCartPage ? 'Expires' : 'Access for';
  const availabilityDate = calculateAvailabilityDate(
    isFlpType,
    isCartPage,
    flpTerm,
    isSection,
    isCredential,
    isMembership,
    isCenterMembership,
    isSubscription,
    membershipEndDate,
    item,
    isPQ,
    isMembershipReactivationJourney,
    isCimaRenewalSeason,
    activeMembership
  );

  const isWebcastMultiday: boolean = isWebcast && availableFormat?.key === Product.AvailableFormat.MULTI_DAY;
  const isItemRefunded: boolean = cartLineItemState === Orders.LineItemStates.CANCELLED_AND_DEPROVISIONED;
  const isEbook: boolean = availableFormat?.key === Product.AvailableFormat.EBOOK;

  const showExpiresAccessForRow: boolean = Boolean(
    isActiveProduct &&
      (isSubscriptionPhysicalProduct || !isAnyPhysicalProduct) &&
      !isConference &&
      !isWebcastMultiday &&
      !(isAnyPhysicalProduct && !isSubscription && isProfilePurchasesPage) &&
      !isItemRefunded &&
      !isContribution &&
      !isFeeType
  );
  const reminderText: string = !!shouldRemindRenewal ? `${!!autoRenewEnabled ? 'Renews' : 'Expires'} soon` : '';
  const showAdjustQuantityWarning = Boolean(
    (quantity > availableQuantity && availableQuantity > 0) || showTooBigQtyErrorMessage
  );
  const showLowStockWarning = Boolean(
    0 < availableQuantity && availableQuantity < 10 && quantity < availableQuantity && !showTooBigQtyErrorMessage
  );

  const isAttestationRequired =
    attestationRequired === Product.DonationAttestation.YES || attestationRequired === Product.DonationAttestation.NO;

  const getBodyContent = (accessLinks: any): React.ReactNode => {
    return (
      <>
        {Object.keys(accessLinks).map((group: string) => (
          <StyledBenefitContainer key={group}>
            <StyledAccessLinkGroup>{group !== 'undefined' ? group : ''}</StyledAccessLinkGroup>
            {accessLinks[group].map((accLink: { name: string; url: string }) => (
              <StyledAccessLink key={accLink.name}>
                {accLink.name}
                {accLink.url && (
                  <Link testId="ext-link" to={accLink.url} isExternal>
                    {' '}
                    Access Now
                  </Link>
                )}
              </StyledAccessLink>
            ))}
          </StyledBenefitContainer>
        ))}
      </>
    );
  };

  const noExpiryEbook = isPublication && isEbook && item?.availability === 9999;

  return (
    <>
      <DetailsWrap isRow={isRow} isContribution={isContribution}>
        <DetailsLeft>
          <DetailRowWrap>
            <StyledDescription>{productDescription}</StyledDescription>
          </DetailRowWrap>
          {isProfilePurchasesPage && orderNumber && (
            <DetailRowWrap>
              <DetailHead>Order #</DetailHead>
              <DetailContent>{orderNumber}</DetailContent>
            </DetailRowWrap>
          )}
          {sku && (
            <DetailRowWrap>
              <DetailHead>Product #</DetailHead>
              <DetailContent>{sku}</DetailContent>
            </DetailRowWrap>
          )}
          {fundName && (
            <DetailRowWrap>
              <DetailHead>Fund</DetailHead>
              <DetailContent>{fundName}</DetailContent>
            </DetailRowWrap>
          )}
          {isAttestationRequired && attestationCartText && (
            <DetailRowWrap>
              <DetailHead>Payment</DetailHead>
              <BoldDetailContent>{attestationCartText}</BoldDetailContent>
            </DetailRowWrap>
          )}
          {formatLabel && !isContribution && (
            <DetailRowWrap>
              <DetailHead>Format</DetailHead>
              <DetailContent>
                {formatLabel} {isConference && Boolean(optionalText) ? ` - ${optionalText}` : ''}&nbsp;
              </DetailContent>
            </DetailRowWrap>
          )}
          {isAnyPhysicalProduct && (isProfilePurchasesPage || isOrderConfirmationPage) && (
            <DetailRowWrap>
              <DetailHead>Qty</DetailHead>
              <DetailContent>{quantity}</DetailContent>
            </DetailRowWrap>
          )}

          {showExpiresAccessForRow && !(isFreeTrial && isOrderConfirmationPage) && (
            <DetailRowWrap>
              <DetailHead>{availabilityTitle}</DetailHead>
              <DetailContent singleLine={!!shouldRemindRenewal}>
                {item.isExtended && item?.orderConfirmationAddress ? (
                  <span>
                    {`${moment(item.lastExtendedOn).format('MMM DD, YYYY')} `}
                    <ExtensionDetails>{`Extended till ${availabilityDate}`}</ExtensionDetails>
                  </span>
                ) : (
                  <>
                    {noExpiryEbook && !isCartPage && <>No Expiration</>}
                    {noExpiryEbook && isCartPage && <>Lifetime</>}
                    {!noExpiryEbook && <>{availabilityDate}</>}
                  </>
                )}
                {!!shouldRemindRenewal && (
                  <StyledReminder
                    data-testid={`product-detail-${!!autoRenewEnabled ? 'renews' : 'expires'}-${productType}-${sku}`}
                    isBlue={!!autoRenewEnabled}
                  >
                    {reminderText}
                  </StyledReminder>
                )}
              </DetailContent>
            </DetailRowWrap>
          )}

          {Boolean(isCredential || isSection) && (
            <>
              <ProductItemSubscriptionDetails
                item={item}
                currentMemProductStatus={currentMemProductStatus}
                parentPage={parentPage}
                isSubscription={isSubscription}
                isCredential={isCredential}
                isSection={isSection}
                isCartPage={isCartPage}
                toggleSubscriptionRenewal={toggleSubscriptionRenewal}
                isActiveProduct={isActiveProduct}
                availabilityDate={availabilityDate}
                viewTextForAnalytics={viewTextForAnalytics}
              />
              {benefitsAccessLinks && (
                <StyledExpandableWrapper>
                  <StyledBenefitGroupContainer>
                    <Expandable
                      items={[
                        {
                          id: 1,
                          heading: (
                            <StyledAccordionTitle>
                              Benefits included with your{' '}
                              {isSection ? 'Section' : isCredential ? 'Credential' : 'Product'}
                            </StyledAccordionTitle>
                          ),
                          content: getBodyContent(benefitsAccessLinks),
                        },
                      ]}
                      expandIcon={<Plus testId={`benefits-expand-icon-${1}`} />}
                      collapseIcon={<Minus testId={`benefits-collapse-icon-${1}`} />}
                    />
                  </StyledBenefitGroupContainer>
                </StyledExpandableWrapper>
              )}
            </>
          )}
          {item?.orderConfirmationAddress && isActiveProduct && (
            <DetailRowWrap>
              <DetailHead>Assignee</DetailHead>
              <DetailContent>
                <span>
                  This product was assigned to you by <b>{item?.orderConfirmationAddress}</b>
                </span>
              </DetailContent>
            </DetailRowWrap>
          )}
          {isFreeTrial && (
            <ProductItemFreeTrialDetails
              item={item}
              parentPage={parentPage}
              viewTextForAnalytics={viewTextForAnalytics}
              isActiveProduct={isActiveProduct}
              toggleSubscriptionRenewal={toggleSubscriptionRenewal}
            />
          )}

          {isAnyPhysicalProduct && (
            <PhysicalProductsDetails
              viewTextForAnalytics={viewTextForAnalytics}
              isProfilePurchasesPage={isProfilePurchasesPage}
              isOrderConfirmationPage={isOrderConfirmationPage}
              isSubscription={isSubscription}
              cartLineItemState={cartLineItemState}
              quantity={quantity}
              standingOrderStatus={standingOrderStatus}
              shipmentDate={shipmentDate}
              productType={productType}
              sku={sku}
              publicationYear={item?.publicationYear}
            />
          )}
          {!isFreeTrial && (
            <ProductItemSubscriptionDetails
              item={item}
              currentMemProductStatus={currentMemProductStatus}
              parentPage={parentPage}
              isSubscription={isSubscription}
              isCartPage={isCartPage}
              toggleSubscriptionRenewal={toggleSubscriptionRenewal}
              isActiveProduct={isActiveProduct}
              availabilityDate={availabilityDate}
              viewTextForAnalytics={viewTextForAnalytics}
            />
          )}
          {isConference && (
            <ConferenceProductDetails
              isOrderConfirmationPage={isOrderConfirmationPage}
              conferenceUserInfo={conferenceUserInfo}
              isOnsiteConference={isOnsiteConference}
              dateRange={dateRange!}
              location={location!}
              isEventInNext48Hours={isEventInNext48Hours}
              isProfilePurchasesPage={isProfilePurchasesPage}
            />
          )}
          {isDiscontinued && !isActiveProduct && (
            <DetailRowWrap>
              <DetailContent>
                Sorry, this product isn’t available to buy again.{' '}
                <StyledLink
                  data-testid={`browse-search`}
                  to={`${generatePath(getPath(Routes.SEARCH))}/?category=cpe-learning`}
                >
                  Browse other options
                </StyledLink>
              </DetailContent>
            </DetailRowWrap>
          )}
        </DetailsLeft>
        {isRow && isAnyPhysicalProduct && (
          <QuantityContainer>
            <StyledQuantityBox
              handleProductQtyChange={handleProductQtyChange}
              productQty={productQty.productQty}
              availableQuantity={availableQuantity}
              testId="cart-page"
            />
            {showLowStockWarning && <StyledWarningLowStock availableQuantity={availableQuantity} isInProductDetails />}
            {showAdjustQuantityWarning && (
              <WarningNeedToAdjustQuantity availableQuantity={availableQuantity} isInProductDetails />
            )}
            {availableQuantity <= 0 && <WarningOutOfStock isInProductDetails />}
          </QuantityContainer>
        )}
        {isRow && isContribution && !isEditContributionAmountToggled && (
          <StyledEditContributionContainer>{renderEditContributionButton()}</StyledEditContributionContainer>
        )}
      </DetailsWrap>
      {showStandingOrder && (
        <DetailRowWrap isStandingOrder>
          <StandingOrderCheckbox
            standingOrderDiscount={standingOrderDiscount}
            productStandingOrder={productStandingOrder}
            handleCheckboxClick={handleCheckboxClick}
          />
        </DetailRowWrap>
      )}
      <StyledMobileOnly>
        {isRow && isContribution && (
          <ContributionProductDetails
            renderContributionAmountInput={renderContributionAmountInput}
            renderEditContributionButton={renderEditContributionButton}
            renderActionContributionButtons={renderActionContributionButtons}
            isEditContributionAmountToggled={isEditContributionAmountToggled}
          />
        )}
        {showUpdateButtons && productItemId === lineItemId && renderQtyUpdateButtons(showTooBigQtyErrorMessage)}
      </StyledMobileOnly>
    </>
  );
};

const StyledMobileOnly = styled(OnlyMobileCSS)`
  &&& {
    flex-wrap: wrap;
  }
`;

export const DetailsWrap = styled.div<{ isRow: boolean; isContribution?: boolean }>`
  ${props => !props.isContribution && `margin-top: ${props.theme.pxToRem(16)};`}
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${props => props.theme.mediaQueries.computerMin} {
    ${props =>
      props.isRow &&
      `flex-direction: row;
      `}
  }
`;

const DetailsLeft = styled.div`
  width: 100%;
`;
export const DetailRowWrap = styled.div<{ isStandingOrder?: boolean; isContribution?: boolean }>`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(4)};
  flex-grow: 1;
  ${props => props.isStandingOrder && `margin-top: ${props.theme.pxToRem(23)};`}
  ${props => props.theme.mediaQueries.computerMin} {
    margin: 0;
  }

  ${props => props.isContribution && `align-items: center; margin-bottom: ${props.theme.pxToRem(26)}`}

  &:last-child {
    margin-bottom: ${props => props.theme.pxToRem(24)};
  }
`;

export const DetailHead = styled.div`
  width: ${props => props.theme.pxToRem(95)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};
  height: ${props => props.theme.pxToRem(18)};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
  flex-shrink: 0;
`;

export const DetailContent = styled.div<{ singleLine?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.singleLine ? 'row' : 'column')};
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: ${props => props.theme.pxToRem(200)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
`;

const StyledDescription = styled.div<{ singleLine?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.singleLine ? 'row' : 'column')};
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: ${props => props.theme.pxToRem(200)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
  max-width: ${props => props.theme.pxToRem(500)};
  padding-bottom: ${props => props.theme.pxToRem(20)};
`;

const BoldDetailContent = styled.div<{ singleLine?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.singleLine ? 'row' : 'column')};
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: ${props => props.theme.pxToRem(200)};
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${props => props.theme.mediaQueries.tabletMax} {
    align-items: flex-end;
    max-width: ${props => props.theme.pxToRem(138)};
  }
`;
const StyledQuantityBox = styled(QuantityBox)`
  &&&& {
    align-items: center;
    margin-bottom: ${props => props.theme.pxToRem(6)};
  }
  input {
    height: ${props => props.theme.pxToRem(40)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    > p {
      margin-right: 0;
    }
  }
`;

const StyledWarningLowStock = styled(WarningLowStock)`
  text-align: right;
  white-space: nowrap;
`;

const StyledReminder = styled.span<{ isBlue?: boolean }>`
  color: ${props => (props.isBlue ? props.theme.colors.interfaceBlue : props.theme.colors.interfaceRed)};
  margin-left: ${props => props.theme.pxToRem(7)};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledEditContributionContainer = styled(OnlyDesktopCSS)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: ${props => props.theme.pxToRem(250)};
  align-items: flex-end;
`;

const StyledAccessLinkGroup = styled.label`
  display: block;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.m};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  margin: ${props => props.theme.pxToRem(20)} 0;
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;

const StyledAccessLink = styled.div`
  display: flex;
  justify-content: space-between;
  width: 35%;
  font-size: ${props => props.theme.fontSizes.xs};
  width: 70%;
  margin-bottom: ${props => props.theme.pxToRem(10)};
  margin-right: ${props => props.theme.pxToRem(1)} 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledExpandableWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)}
  border-top: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
`;

const StyledAccordionTitle = styled.h3`
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
  margin-bottom:  ${props => props.theme.pxToRem(10)}
  margin: 0;
  color: ${props => props.theme.colors.neutralGrey8};

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const ExtensionDetails = styled.span`
  color: ${props => props.theme.colors.interfaceGreen};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledBenefitContainer = styled.div`
  border-radius: ${props => props.theme.pxToRem(8)};
  box-shadow: 0 0 ${props => props.theme.pxToRem(6)} 0 ${props => props.theme.colors.neutralGrey3};
  background-color: ${props => props.theme.colors.neutralWhite};
  padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
    ${props => props.theme.pxToRem(10)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledBenefitGroupContainer = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey1};
  padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
    ${props => props.theme.pxToRem(10)};
  border-radius: ${props => props.theme.pxToRem(8)};
  margin-bottom: ${props => props.theme.pxToRem(10)};
  margin-top: ${props => props.theme.pxToRem(10)};
  box-shadow: 0 0 ${props => props.theme.pxToRem(4)} 0 ${props => props.theme.colors.neutralGrey3};
  padding-bottom: ${props => props.theme.pxToRem(20)};
`;
