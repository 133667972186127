import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { TextArea as SemanticUIInput, TextAreaProps as SemanticUIInputProps } from 'semantic-ui-react';

interface TextAreaProps extends SemanticUIInputProps {
  labelName?: string;
  labelId?: string;
  testId: string;
  className?: string;
  inputRef?: any;
  getIsFocused?: (e: boolean) => void;
  errorMessage?: string;
  allowedValidationOnload?: boolean;
  isCorrect?: boolean;
  externalIsValid?: boolean;
  value?: string;
  isErrorMessageAppearOnFocus?: boolean;
  isErrorMessageOnTop?: boolean;
  isErrorMessageOnBottom?: boolean;
  maxLength?: string;
}

export const TextArea: React.FC<TextAreaProps> = ({
  labelName,
  labelId,
  testId,
  className,
  inputRef,
  getIsFocused,
  errorMessage,
  allowedValidationOnload = true,
  isCorrect,
  externalIsValid = true,
  value,
  isErrorMessageAppearOnFocus,
  isErrorMessageOnTop,
  isErrorMessageOnBottom = false,
  ...props
}) => {
  const [, setFocused] = useState(false);
  const [isTouched, setTouched] = useState(false);

  // To have validation for Textbox on click
  const handleBlur = useCallback(() => {
    setFocused(false);
    if (getIsFocused) {
      getIsFocused(false);
    }
  }, [getIsFocused]);
  const handleFocus = useCallback(() => {
    setFocused(true);
    setTouched(true);
    if (getIsFocused) {
      getIsFocused(true);
    }
  }, [getIsFocused]);

  const hasValue = Boolean(value?.length);

  const hasError = allowedValidationOnload
    ? Boolean(
        isErrorMessageAppearOnFocus
          ? isTouched && (!isCorrect || (!externalIsValid && hasValue))
          : !isCorrect || (!externalIsValid && hasValue)
      )
    : Boolean(!isCorrect || (!externalIsValid && hasValue));

  const validationMessageValue = hasError && errorMessage;
  return (
    <>
      {labelName && labelId && <Label htmlFor={labelId}>{labelName}</Label>}
      {isErrorMessageOnTop && validationMessageValue && <ValidationMessage>{validationMessageValue}</ValidationMessage>}
      <StyledTextArea
        ref={inputRef}
        {...props}
        className={className}
        data-testid={`input-${testId}`}
        id={labelId}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {isErrorMessageOnBottom && validationMessageValue && (
        <ValidationMessage>{validationMessageValue}</ValidationMessage>
      )}
    </>
  );
};

const Label = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledTextArea = styled(SemanticUIInput)`
  &&&& > textarea {
    :focus {
      border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.interfaceBlue};
    }
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey4};

    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.colors.neutralGrey8};
    line-height: ${props => props.theme.pxToRem(24)};
    padding: ${props => props.theme.pxToRem(9)} ${props => props.theme.pxToRem(15)};
  }
  &&&& {
    &.ui.textarea > textarea {
      &:-ms-clear {
        display: none;
      }
      &::-ms-clear {
        display: none;
      }
      &:-ms-reveal {
        display: none;
      }
      &::-ms-reveal {
        display: none;
      }
    }
`;

const ValidationMessage = styled.p`
  margin: ${props => props.theme.pxToRem(-6)} 0 ${props => props.theme.pxToRem(16)} 0 !important;
  color: ${props => props.theme.colors.interfaceRed}!important;
  font-size: ${props => props.theme.fontSizes.xxs}!important;
  font-weight: ${props => props.theme.fontWeights.regular}!important;
`;
