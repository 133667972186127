import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMembershipTiers } from 'modules/membership/actions';
import { constantsSelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { membershipProductSelector } from 'modules/membership/selectors';
import { BenefitCard } from 'components/organisms/MembershipBenefit/BenefitCard';
import { Heading, Grid, Container } from 'components/atoms';
import { DefaultInlineLinkStyles, InlineLinkOnDarkBgStyles } from 'components/atoms/Link/Link';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful, MembershipTypes } from 'mxp-schemas';
interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
  isArticlePage?: boolean;
}
interface SubBlockProps {
  groupLabel?: string;
  blocks: Contentful.LandingPages.LandingPageSubBlock[];
}

export const BenefitsBecomeMember: React.FC<Props> = ({ modularBlock, headingType, isArticlePage }) => {
  const dispatch = useDispatch();
  const block = modularBlock as Contentful.ModularContent.BenefitsBlock;
  const isBgColorDark: boolean = isColorDark(block?.bgColor);

  const { pathname: currPath } = useLocation();

  const constants = useSelector(constantsSelector);
  const membershipProducts = useSelector(membershipProductSelector);

  const someBlockHasGroup = block?.landingPageSubBlocks?.some(item => item?.group);
  const BenefitTable: React.FC<{ BenefitTitle: string; BenefitDescription: string }> = React.memo(
    ({ BenefitTitle, BenefitDescription }) => {
      return (
        <>
          <StyledBenefitTitle dangerouslySetInnerHTML={{ __html: BenefitTitle }} />
          <StyledBenefitBody dangerouslySetInnerHTML={{ __html: BenefitDescription }} />
        </>
      );
    }
  );

  const groupedCards: SubBlockProps[] = useMemo(() => {
    const subBlocks = block?.landingPageSubBlocks || [];
    const groupsTempStorage: string[] = [];

    if (someBlockHasGroup) {
      subBlocks.forEach(
        item => item?.group && !groupsTempStorage.includes(item?.group) && groupsTempStorage.push(item?.group)
      );

      const groupedBlocks: SubBlockProps[] = groupsTempStorage.reduce((acc: SubBlockProps[], item) => {
        return [
          ...acc,
          {
            groupLabel: item || '',
            blocks: subBlocks?.filter(val => val.group === item) || [],
          },
        ];
      }, []);

      if (subBlocks?.some(val => !val.group)) {
        groupedBlocks.push({
          groupLabel: '',
          blocks: subBlocks?.filter(val => !val.group),
        });
      }

      return groupedBlocks;
    }
    return [{ groupLabel: '', blocks: subBlocks }];
  }, [block, someBlockHasGroup]);

  const isRegionalPathwayLandingPage: boolean = useMemo(
    () => currPath.includes(constants?.[CONSTANTS.CIMA_REGIONAL_PATHWAY_PROP_PAGE_SLUG]),
    [currPath, constants]
  );

  useEffect(() => {
    (async () => {
      if (
        isRegionalPathwayLandingPage &&
        (!membershipProducts ||
          (membershipProducts &&
            membershipProducts?.slug !== MembershipTypes.CimaMembershipProductSlug.CIMA_CANDIDATE_PRODUCT))
      ) {
        await dispatch(fetchMembershipTiers(true, MembershipTypes.CimaMembershipProductSlug.CIMA_CANDIDATE_PRODUCT));
      }
    })();
  }, [dispatch, currPath, constants, isRegionalPathwayLandingPage, membershipProducts]);

  return (
    <>
      {isArticlePage ? (
        <>
          {block?.header && <StyledBenefitTableHeading dangerouslySetInnerHTML={{ __html: block.header }} />}
          {block?.description && <StyledBenefitTablePrice dangerouslySetInnerHTML={{ __html: block.description }} />}
          <Grid columns="2">
            {block?.landingPageSubBlocks?.map((item: Contentful.LandingPages.LandingPageSubBlock) => (
              <Grid.Column mobile={16} tablet={8} computer={5} key={item.id}>
                <BenefitTable
                  BenefitTitle={item.header ? item.header : ''}
                  BenefitDescription={item.description ? item.description : ''}
                />
              </Grid.Column>
            ))}
          </Grid>
        </>
      ) : (
        <StyledDiv>
          <StyledHeaderContainer isBgColorDark={isBgColorDark}>
            {block?.header && (
              <StyledHeading
                as={headingType}
                dangerouslySetInnerHTML={{ __html: block.header }}
                isBgColorDark={isBgColorDark}
              />
            )}
            {block?.description && (
              <StyledParagraph dangerouslySetInnerHTML={{ __html: block.description }} isBgColorDark={isBgColorDark} />
            )}
          </StyledHeaderContainer>
          <StyledGrid columns="3" someBlockHasGroup={someBlockHasGroup}>
            {groupedCards?.map((card: SubBlockProps) => (
              <>
                {card?.groupLabel && (
                  <Grid.Row>
                    <StyledGroupLabel>{card?.groupLabel}</StyledGroupLabel>
                  </Grid.Row>
                )}
                {card?.blocks?.map((item: Contentful.LandingPages.LandingPageSubBlock) => (
                  <Grid.Column key={item.id}>
                    <BenefitCard
                      BenefitName={item.header ? item.header : ''}
                      BenefitBody={item.description ? item.description : ''}
                      IconUrl={item.image ? item.image : ''}
                      IconWidth={24}
                      IconHeight={24}
                      CtaUrl={item.ctaUrl}
                      downloads={item.downloads || null}
                      CtaType={item.ctaType || ''}
                      isRegionalPathwayLandingPage={isRegionalPathwayLandingPage}
                    />
                  </Grid.Column>
                ))}
              </>
            ))}
          </StyledGrid>
        </StyledDiv>
      )}
    </>
  );
};

const StyledBenefitTableHeading = styled.div`
  p {
    font-size: ${props => props.theme.fontSizes.l};
    text-align: left;
  }
`;
const StyledBenefitTablePrice = styled.div`
  p {
    font-size: ${props => props.theme.fontSizes.m};
    color: ${props => props.theme.colors.neutralGrey5};
    text-align: left;
  }
`;
const StyledBenefitTitle = styled.div`
  p {
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.medium}
    text-align: left;
  }
`;
const StyledBenefitBody = styled.div`
  p {
    max-width: ${props => props.theme.pxToRem(360)};
    font-size: ${props => props.theme.fontSizes.s};
    color: ${props => props.theme.colors.neutralGrey8};
    text-align: left;
    a {
      font-size: ${props => props.theme.fontSizes.m};
      color: ${props => props.theme.colors.primaryPurple};
      text-decoration: underline;
    }
  }
`;

const StyledHeaderContainer = styled.div<{ isBgColorDark?: boolean }>`
  margin: 0 auto;
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
`;
const StyledHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;

  p {
    margin: 0;
    line-height: 1.38;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.l};

    p {
      line-height: 1.33;
    }
  }
`;
const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;

  p {
    margin: 0;
    line-height: 1.33;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};

    p {
      line-height: 1.5;
    }
  }
`;
const StyledGrid = styled(Grid)<{ someBlockHasGroup?: boolean }>`
  &&&& {
    display: flex;
    margin-top: ${props => props.theme.pxToRem(39)};
    margin-bottom: ${props => props.theme.pxToRem(24)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(24)};
    }
  }
  ${props =>
    !props.someBlockHasGroup &&
    `&&&& {
      justify-content: space-around;
      }`}
`;
const StyledDiv = styled(Container)`
  margin: auto;
`;

const StyledGroupLabel = styled.h3`
  line-height: ${props => props.theme.pxToRem(30)};
  font-size: ${props => props.theme.pxToRem(24)};
`;
