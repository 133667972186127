import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Container, Grid } from 'semantic-ui-react';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { userNameSelector } from 'modules/user/selectors';
import { EndSessionModal } from 'components/admin/molecules/EndSessionModal/EndSessionModal';
import { ReactComponent as MemberIcon } from 'resources/images/icon-dev-ic-group.svg';
import { endImpersonation } from 'modules/admin/actions';

export const StickyBanner = () => {
  const fullName = useSelector(userNameSelector);
  const dispatch = useDispatch();

  const [isEndSessionModalOpen, setEndSessionModalOpen] = useState(false);

  const toggleEndSessionModal = (event?: React.SyntheticEvent<HTMLElement>) => {
    event?.preventDefault();
    setEndSessionModalOpen(!isEndSessionModalOpen);
  };

  const onConfirmation = () => {
    toggleEndSessionModal();
    dispatch(endImpersonation());
  };

  return (
    <>
      <EndSessionModal open={isEndSessionModalOpen} onCancel={toggleEndSessionModal} onConfirmation={onConfirmation} />
      <PlaceholderBanner />
      <Banner>
        <BannerContainer>
          <Grid>
            <Grid.Column floated="left" width={9}>
              <PresentationText>
                <StyledMemberIcon />
                You are currently impersonating <b>{fullName}</b>
              </PresentationText>
            </Grid.Column>
            <Grid.Column floated="right" width={3} textAlign="right">
              <EndSessionButton
                size={ButtonEnums.sizes.small}
                variant={ButtonEnums.variants.primary}
                testId="end-admin-session"
                type="submit"
                onClick={toggleEndSessionModal}
              >
                End Session
              </EndSessionButton>
            </Grid.Column>
          </Grid>
        </BannerContainer>
      </Banner>
    </>
  );
};

const PlaceholderBanner = styled.div`
  background-color: ${props => props.theme.colors.wispPink};
  width: 100%;
  height: ${props => props.theme.pxToRem(44)};
  display: table;
`;

const Banner = styled.div`
  background-color: ${props => props.theme.colors.wispPink};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 801;
`;

const PresentationText = styled.p`
  && {
    margin-top: ${props => props.theme.pxToRem(14)};
    font-size: ${props => props.theme.fontSizes.xxs};
  }
`;

const BannerContainer = styled(Container)`
  height: ${props => props.theme.pxToRem(60)};
  padding-right: ${props => props.theme.pxToRem(14)};
`;

const EndSessionButton = styled(Button)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(5)};
    margin-right: ${props => props.theme.pxToRem(2)};
    background-color: ${props => props.theme.colors.interfaceRed};
    width: ${props => props.theme.pxToRem(125)};
    height: ${props => props.theme.pxToRem(34)};
  }
`;

const StyledMemberIcon = styled(MemberIcon)`
  fill: ${props => props.theme.colors.interfaceRed};
  width: ${props => props.theme.pxToRem(24)};
  margin-right: ${props => props.theme.pxToRem(6)};
  vertical-align: middle;
  & > path {
    fill: ${props => props.theme.colors.interfaceRed};
  }
`;
