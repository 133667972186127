import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { searchUser, impersonate } from 'modules/admin/actions';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { push } from 'connected-react-router';
import { LookUpForm, SubmitArgs } from 'components/admin/organisations/LookUpForm/LookUpForm';
import { User as UserTypes } from 'mxp-schemas';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { BLOCK_CIMA_MIGRATED_MEMBERS_LOGIN } from 'modules/featureToggle/constants';
import { NotificationBanner, NotificationBannerEnums } from 'components/atoms';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';

export const PageAdminLookUpUser = () => {
  const dispatch = useDispatch();
  const blockCimaMigratedMembersLogin: boolean = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, BLOCK_CIMA_MIGRATED_MEMBERS_LOGIN)
  );
  const [displayError, setDisplayError] = useState(false);

  const onSubmit = ({ accountNumber, userEmail, countrySelected, impValue }: SubmitArgs) => {
    return dispatch(searchUser(accountNumber, userEmail)).then(
      async (action: { type: string; payload: UserTypes.UserDetails[] }) => {
        if (action.payload?.[0]?.profile?.siebelRegistered === true && blockCimaMigratedMembersLogin) {
          setDisplayError(true);
          return;
        }
        if (action.payload.length === 0) {
          dispatch(
            push({
              pathname: getPath(Routes.ADMIN_NO_FETCH_RESULTS),
              state: { query: accountNumber || userEmail },
            })
          );
          return;
        }
        const firstResult = action.payload[0];
        if (
          firstResult.status !== 'ACTIVE' &&
          firstResult.status !== UserTypes.UserErrorCodes.PASSWORD_EXPIRED &&
          firstResult.status !== UserTypes.UserErrorCodes.RECOVERY
        ) {
          dispatch(push(getPath(Routes.ADMIN_ERROR_PAGE)));
          return;
        }
        return dispatch(impersonate(firstResult.profile, impValue, countrySelected));
      }
    );
  };
  return (
    <>
      <StyledText data-testid="preferences-text">
        To begin an impersonation session, please lookup a customer using
        <br />a valid AICPA UID or email address.
      </StyledText>
      {displayError && (
        <NotificationBannerStyled
          testId="error-data-not-migrated"
          childrenTestId="error-data-not-migrated"
          variant={NotificationBannerEnums.variant.blue}
          icon={<StyledIconError />}
        >
          Your account data is currently being migrated, please try again later
        </NotificationBannerStyled>
      )}
      <LookUpForm isUserImpersonationView onSubmit={onSubmit} submitText="Impersonate" />
    </>
  );
};

const StyledText = styled.div`
  max-width: 720px;
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.pxToRem(14)};
  line-height: 1.71;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(17)};
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  &&&&& {
    margin: 0 0 ${props => props.theme.pxToRem(32)} 0;
    padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(16)};
    min-height: ${props => props.theme.pxToRem(68)};
    max-width: ${props => props.theme.pxToRem(490)};
  }
`;

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  align-self: flex-start;
  ${props => props.theme.mediaQueries.mobileOnly} {
    align-self: center;
  }
  transform: rotateX(180deg);
`;
