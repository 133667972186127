import React from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { ReactComponent as icClear } from 'resources/images/icon-dev-ic-clear.svg';

enum ColorThemes {
  green = 'green',
  red = 'red',
  blue = 'blue',
  teal = 'teal',
}

export const NotificationBannerEnums = {
  variant: ColorThemes,
};

interface NotificationBannerProps {
  children: string | React.ReactNode;
  testId: string;
  childrenTestId: string;
  closeActionTestId?: string;
  closeAction?: () => void;
  variant?: ColorThemes;
  icon?: React.ReactNode;
  className?: string;
  isSpaced?: boolean;
}

export const NotificationBanner: React.FC<NotificationBannerProps> = ({
  children,
  testId,
  childrenTestId,
  closeAction,
  variant,
  icon,
  className,
  isSpaced = false,
}) => {
  const renderIcon = (): React.ReactNode => {
    if (!icon) return null;
    return icon || <StyledIcon data-testid={`icon-${testId}`} />;
  };

  return (
    <Banner className={className} data-testid={testId} variant={variant} isSpaced={isSpaced}>
      {renderIcon()}
      <Message data-testid={childrenTestId}>{children}</Message>
      {closeAction && <StyledIconCross onClick={closeAction} />}
    </Banner>
  );
};

const Banner = styled.div<ThemedStyledProps<Partial<NotificationBannerProps>, DefaultTheme>>`
  display: flex;
  align-items: center;
  border-radius: ${props => props.theme.pxToRem(4)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  padding: ${props => `${props.theme.pxToRem(22)} ${props.theme.pxToRem(18)}`};
  margin: ${props => props.theme.pxToRem(32)} 0s;

  ${props => props.theme.mediaQueries.mobileOnly} {
    align-items: flex-start;
    padding: ${({ theme }) => `${theme.pxToRem(12)} ${theme.pxToRem(16)} ${theme.pxToRem(12)} ${theme.pxToRem(18)}`};
  }

  > svg {
    flex-shrink: 0;
    align-self: center;
  }

  ${({ variant, theme }) =>
    variant === NotificationBannerEnums.variant.red &&
    `
      &&&& {
        border: 1px solid ${theme.colors.interfaceRed};
        background-color: rgba(232, 49, 71, 0.1);

        > svg path {
          fill: ${theme.colors.interfaceRed};
        }
      }
  `}

  ${({ variant, theme }) =>
    variant === NotificationBannerEnums.variant.green &&
    `
      &&&& {
        border: 1px solid ${theme.colors.interfaceGreen};
        background-color: rgba(72, 162, 62, 0.1);

        > svg path {
          fill: ${theme.colors.interfaceGreen};
        }
      }
  `}

  ${({ variant, theme }) =>
    variant === NotificationBannerEnums.variant.blue &&
    `
      &&&& {
        border: 1px solid ${theme.colors.interfaceBlue};
        background-color: rgba(65, 182, 230, 0.1);

        > svg path {
          fill: ${theme.colors.interfaceBlue};
        }
      }
  `}

  ${({ variant, theme }) =>
    variant === NotificationBannerEnums.variant.teal &&
    `
      &&&& {
        border: 1px solid ${theme.colors.secondaryTeal};
        background-color: rgba(0, 133, 125, 0.1);

        > svg path {
          fill: ${theme.colors.secondaryTeal};
        }
      }
  `}

  ${({ isSpaced, theme }) =>
    isSpaced &&
    `
    &&&& {
      padding: ${theme.pxToRem(22)} 1rem;
    }
  `}
`;

const Message = styled.span`
  margin-left: ${props => props.theme.pxToRem(14)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};

  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
`;

const StyledIcon = styled(Icon)`
  align-self: flex-start;
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;

const StyledIconCross = styled(icClear)`
  cursor: pointer;
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  margin-left: auto;
`;
