import * as Sentry from '@sentry/browser';
import { handleActions } from 'redux-actions';
import {
  appliedPromoCodeDescriptionSelector,
  appliedPromoCodeSelector,
  cartErrorsSelector,
  itemsPriceDataCartSelector,
  priceTotalCartSelector,
  cartShippingInfoSelector,
  numberOfItemsInCartSelector,
  isFreeCartSelector,
  cartDetailItemsSelector,
  didCartTransitionFailSelector,
  isCartEmptySelector,
  cartConferenceInfoSelector,
  hasSubscriptionInCartSelector,
  isExistingCartItemSelector,
  cartIdSelector,
  bundleComponentProductVariantExistingInCartBundleItemsSelector,
  hasMembershipProductSelector,
} from './selectors';
import {
  loading,
  getCart,
  setCart,
  addCartItem,
  updateCartItem,
  removeCartItems,
  addPromoCodeItem,
  removePromoCodeItem,
  resetModule,
  resetCartError,
  migrateUserCart,
  resetCartData,
  getCartOrMigrate,
  setCartShippingAddress,
  setSelectedShippingInCart,
  isLoadingShippingOptions,
  addBundleCartItem,
  addBundleCartItemWithCheckForExistingVariants,
  mergeItemsToBundle,
  processDonations,
  updatePreferredCurrencyByCart,
  setProductsWithEthics,
  updateUnpublishedVariant,
  updatePONumber,
} from './actions';

if (
  [
    loading,
    getCart,
    setCart,
    addCartItem,
    updateCartItem,
    removeCartItems,
    addPromoCodeItem,
    removePromoCodeItem,
    resetModule,
    resetCartError,
    migrateUserCart,
    resetCartData,
    getCartOrMigrate,
    setSelectedShippingInCart,
  ].includes(undefined)
) {
  const message: string = 'cart module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

const initialState: State.Cart = {
  loading: false,
  isLoadingShippingOptions: false,
  error: null,
  value: {
    lineItems: [],
  } as unknown as State.CartValue,
  isCartFetched: false,
  productsWithEthics: [],
};
const defaultActionHandler = {
  next: (state: State.Cart, action: any): State.Cart => {
    if (action.payload?.type === loading().type) {
      return { ...state };
    }
    return {
      ...state,
      value: { ...action.payload, legalEntity: action.payload?.legalEntity || state.value?.legalEntity },
      error: null,
      loading: false,
    };
  },
  throw: (state: State.Cart, { payload: error }: { payload: CustomErrors.GraphQLError }): State.Cart => {
    return {
      ...state,
      error,
      loading: false,
    };
  },
};

const getActionHandler = {
  next: (state: State.Cart, action: any): State.Cart => {
    if (action.payload?.type === loading().type) {
      return { ...state };
    }
    return {
      ...state,
      value: action.payload
        ? {
            legalEntity: state?.value?.legalEntity ?? state?.legalEntity,
            ...action.payload,
          }
        : action.payload,
      error: null,
      loading: false,
      isCartFetched: true,
    };
  },
  throw: (state: State.Cart, { payload: error }: { payload: CustomErrors.GraphQLError }): State.Cart => {
    return {
      ...state,
      error,
      loading: false,
    };
  },
};

const updatePONumberHandler = {
  next: (state: State.Cart, action: any): State.Cart => {
    return {
      ...state,
      poNumber: action.payload,
    };
  },
  throw: (state: State.Cart, { payload: error }: { payload: CustomErrors.GraphQLError }): State.Cart => {
    return {
      ...state,
      error,
    };
  },
};

const resetCartErrorHandler = (state: State.Cart): State.Cart => ({
  ...state,
  error: null,
});

const resetCartDataHandler = (state: State.Cart): State.Cart => ({
  ...state,
  value: {
    lineItems: [],
  } as unknown as State.CartValue,
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [loading]: (state: State.Cart): State.Cart => ({
    ...state,
    loading: true,
  }),
  [isLoadingShippingOptions]: (state: State.Cart): State.Cart => ({
    ...state,
    isLoadingShippingOptions: true,
  }),
  [getCart]: getActionHandler,
  [setCart]: getActionHandler,
  [migrateUserCart]: defaultActionHandler,
  [addCartItem]: defaultActionHandler,
  [addBundleCartItem]: defaultActionHandler,
  [updateCartItem]: defaultActionHandler,
  [removeCartItems]: defaultActionHandler,
  [addPromoCodeItem]: defaultActionHandler,
  [removePromoCodeItem]: defaultActionHandler,
  [setCartShippingAddress]: defaultActionHandler,
  [setSelectedShippingInCart]: {
    next: (state: State.Cart, action: any): State.Cart => ({
      ...state,
      value: action.payload,
      error: null,
      loading: false,
      isLoadingShippingOptions: false,
    }),
    throw: (state: State.Cart, { payload: error }: { payload: CustomErrors.GraphQLError }): State.Cart => ({
      ...state,
      loading: false,
      error,
      isLoadingShippingOptions: false,
    }),
  },
  [resetCartError]: resetCartErrorHandler,
  [resetCartData]: resetCartDataHandler,
  [resetModule]: (): State.Cart => initialState,
  [mergeItemsToBundle]: getActionHandler,
  [processDonations]: defaultActionHandler,
  [setProductsWithEthics]: {
    next: (state: State.Cart, action: any): State.Cart => ({
      ...state,
      productsWithEthics: action.payload,
    }),
  },
  [updateUnpublishedVariant]: defaultActionHandler,
  [updatePONumber]: updatePONumberHandler,
};

export {
  appliedPromoCodeDescriptionSelector,
  appliedPromoCodeSelector,
  cartErrorsSelector,
  itemsPriceDataCartSelector,
  priceTotalCartSelector,
  cartShippingInfoSelector,
  isFreeCartSelector,
  cartDetailItemsSelector,
  didCartTransitionFailSelector,
  isCartEmptySelector,
  numberOfItemsInCartSelector,
  cartConferenceInfoSelector,
  hasSubscriptionInCartSelector,
  hasMembershipProductSelector,
  isExistingCartItemSelector,
  cartIdSelector,
  bundleComponentProductVariantExistingInCartBundleItemsSelector,
  // Actions
  addCartItem,
  updateCartItem,
  addPromoCodeItem,
  removePromoCodeItem,
  resetCartError,
  getCart,
  setCart,
  removeCartItems,
  resetModule,
  getCartOrMigrate,
  setSelectedShippingInCart,
  addBundleCartItem,
  addBundleCartItemWithCheckForExistingVariants,
  mergeItemsToBundle,
  processDonations,
  updatePreferredCurrencyByCart,
  updateUnpublishedVariant,
  updatePONumber,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
