import React from 'react';
import styled from 'styled-components';
import { Link } from 'components/atoms/Link/Link';
import { Routes } from 'constants/index';
import { Salesforce } from 'mxp-schemas';
import { getPath } from 'utils';

interface Props {
  employers: Array<Omit<Salesforce.AccountPersonAccount, 'personAccount'>>;
  isAvsDesign?: boolean;
}

export const ApplicationEmploymentSubForm: React.FC<Props> = ({ employers, isAvsDesign = false }) => {
  const primaryEmployer = employers.find(
    (data: Omit<Salesforce.AccountPersonAccount, 'personAccount'>) => data.isPrimary
  );
  return (
    <StyledContainer>
      <StyledParagraph>
        You specified your role when you last applied for a membership. If you are still in the same situation, then
        click Confirm. If your role has changed since, you can modify or add your role directly in{' '}
        <Link isExternal to={getPath(Routes.PROFILE_ROOT)}>
          your profile page.
        </Link>
      </StyledParagraph>
      <StyledLabel avsStyles={isAvsDesign}>Business Name</StyledLabel>
      <StyledContainerEmployers avsStyles={isAvsDesign}>
        {primaryEmployer && (
          <span>{`${primaryEmployer.organization.name} : ${primaryEmployer.jobTitle || 'Not Available'}`}</span>
        )}
      </StyledContainerEmployers>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(-20)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledParagraph = styled.p`
  margin-bottom: ${props => props.theme.pxToRem(32)};
`;

const StyledLabel = styled.div<{ avsStyles?: boolean }>`
  margin-bottom: ${props => props.theme.pxToRem(8)};
  font-weight: ${props => (props.avsStyles ? props.theme.fontWeights.bold : props.theme.fontWeights.regular)};
`;

const StyledContainerEmployers = styled.div<{ avsStyles?: boolean }>`
  background-color: ${props => props.theme.colors.neutralGrey1};
  font-size: ${props => (props.avsStyles ? props.theme.fontSizes.s : props.theme.fontSizes.xxs)};
  font-weight: normal;
  width: ${props => (props.avsStyles ? '100%' : 'max-content')};
  padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(20)};
  span {
    display: block;
  }
`;
