import React, { memo } from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { LabeledData } from 'components/atoms';
import { ProfileHeading } from 'components/molecules/ProfileHeading/ProfileHeading';
import { MembershipTypes } from 'mxp-schemas';
import { usePageContext } from 'components/pages/PagePracticalExperienceRequirement/PageContext';

interface Props {
  practicalExpReqRecord: MembershipTypes.PracticalExperienceRequirementSupervisor;
}

export const PracticalExpReqSupervisorDetails: React.FC<Props> = memo(({ practicalExpReqRecord }) => {
  const { data } = usePageContext(); // to fetch the data entered if supervisor data is not yet added in dropdown list.
  const practicalExpReqRecordData = practicalExpReqRecord;

  return (
    <>
      <br />
      <Grid.Row>
        <Grid.Column width={16}>
          <StyledProfileHeading title="Practical experience requirements (PER) supervisor" hasBorder={false} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="First Name"
            data={practicalExpReqRecordData?.firstName || data.newEmployment.supervisor?.firstName || ''}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="Last Name"
            data={practicalExpReqRecordData?.lastName || data.newEmployment.supervisor?.lastName || ''}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="Job title during IPD supervision"
            data={practicalExpReqRecordData?.jobTitle || data.newEmployment.supervisor?.jobTitle || ''}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="Organization Name"
            data={practicalExpReqRecordData?.employer?.name || data.newEmployment.supervisor?.employer?.name || ''}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="Business Address Line 1"
            data={
              practicalExpReqRecordData?.employer?.addressLine1 ||
              data.newEmployment.supervisor?.employer?.addressLine1 ||
              ''
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="Business Address Line 2"
            data={
              practicalExpReqRecordData?.employer?.addressLine2 ||
              data.newEmployment.supervisor?.employer?.addressLine2 ||
              ''
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="City"
            data={practicalExpReqRecordData?.employer?.city || data.newEmployment.supervisor?.employer?.city || ''}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="State"
            data={practicalExpReqRecordData?.employer?.state || data.newEmployment.supervisor?.employer?.state || ''}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="ZIP/Postal Code"
            data={
              practicalExpReqRecordData?.employer?.postalCode ||
              data.newEmployment.supervisor?.employer?.postalCode ||
              ''
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="Country"
            data={
              practicalExpReqRecordData?.employer?.country || data.newEmployment.supervisor?.employer?.country || ''
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="Email address"
            data={practicalExpReqRecordData?.email || data.newEmployment.supervisor?.email || ''}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LabeledData
            label="Linkedin profile URL"
            data={practicalExpReqRecordData?.linkedInUrl || data.newEmployment.supervisor?.linkedInUrl || ''}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
});

const StyledProfileHeading = styled(ProfileHeading)`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.l};
  margin-bottom: ${props => props.theme.pxToRem(5)};
`;
