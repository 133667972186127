import React from 'react';
import styled, { css } from 'styled-components';
import { AccessTime } from 'components/atoms/svg';
import { theme } from 'theme';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';

interface RecentSearchItemProps {
  children: string;
  onDelete: (searchItem: string) => void;
  onClick: (searchItem: string) => void;
  isLast: boolean;
  testId: string;
}

export const RecentSearchItem: React.FC<RecentSearchItemProps> = React.memo(
  ({ children, onDelete, onClick, isLast, testId }) => {
    const clickHandler = React.useCallback(() => {
      onClick(children);
    }, [onClick, children]);

    const deleteHandler = React.useCallback(
      (event: React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        onDelete(children);
      },
      [onDelete, children]
    );

    return (
      <StyledRecentSearchItem isLast={isLast}>
        <StyledItem onClick={clickHandler} data-testid={`recent-item-${testId}`}>
          <AccessTime color={theme.colors.neutralGrey4} data-testid={`recent-item-clock-icon-${testId}`} size={22} />
          <StyledItemText>{children}</StyledItemText>
        </StyledItem>
        <StyledDeleteButton onClick={deleteHandler} data-testid={`recent-item-delete-${testId}`}>
          <IconClose />
        </StyledDeleteButton>
      </StyledRecentSearchItem>
    );
  }
);

const StyledRecentSearchItem = styled.div<{ isLast: boolean }>`
  width: 100%;
  ${props => props.isLast && `margin-bottom: 1.5rem`};
  &:hover {
    background: ${props => props.theme.colors.primaryFadedPurple};
  }
  cursor: pointer;
  display: flex;
  padding: 1rem 3rem;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: 0;
    padding-right: 0;
  }
  ${props => !props.isLast && `border-bottom: 1px solid ${props.theme.colors.neutralGrey3}`};
`;

const buttonReset = css`
  border: none;
  background: none;
`;

const StyledDeleteButton = styled.button`
  ${buttonReset}
  cursor: pointer;
  padding: 0.6rem 0 0.6rem 0.6rem;
  width: 10%;

  > svg {
    fill: ${props => props.theme.colors.primaryPurple};
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0.6rem 0.6rem 0.6rem 0;
  }
`;

const StyledItem = styled.button`
  ${buttonReset}
  &:hover {
    color: ${props => props.theme.colors.primaryDarkPurple};
  }
  display: flex;
  width: 90%;
  align-items: center;
  padding: 0;
`;

const StyledItemText = styled.span`
  flex: 1;
  padding: 0 1rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  font-size: 1rem;
`;
