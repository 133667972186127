import React from 'react';
import { Modal, Button, ButtonEnums, Link } from 'components/atoms';
import styled from 'styled-components/macro';
import { userHasEthicsViolationOnListSelector } from 'modules/user/selectors';
import { useSelector } from 'react-redux';
import { MiPApplicationEmail } from 'constants/index';

interface Props {
  onClose: () => void;
}

export const MipConductViolationModal: React.FC<Props> = React.memo(({ onClose }) => {
  const userHasEthicsViolationOnList = useSelector(userHasEthicsViolationOnListSelector);

  return (
    <StyledModal
      open={true}
      onClose={onClose}
      heading={
        userHasEthicsViolationOnList
          ? 'This purchase is currently unavailable.'
          : 'You cannot proceed with this purchase.'
      }
      testId="conduct-violation-modal"
      showCloseCross
      cancelNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          testId="no-follow"
          onClick={onClose}
        >
          I understand
        </Button>
      }
    >
      {userHasEthicsViolationOnList ? (
        <>
          We are unable to process your application further. Please direct your queries to{' '}
          <Link isExternal to={MiPApplicationEmail.MIP_APPLICATION}>
            mip.applications@aicpa-cima.com.
          </Link>{' '}
        </>
      ) : (
        <>
          Your profile <BoldText>has</BoldText> been blocked for Conduct issues. Please contact us by selecting{' '}
          <BoldText>
            the chat icon to the right of your screen. Or call us at 999-777-7077 or +1919-402-4500 if outside the US.
          </BoldText>
        </>
      )}
    </StyledModal>
  );
});
// <Link isExternal to="mailto:mip.applications@aicpa-cima.com">

const StyledModal = styled(Modal)`
  &&&& > button {
    position: absolute !important;
    top: ${props => props.theme.pxToRem(16)}!important;
    right: ${props => props.theme.pxToRem(16)}!important;
  }

  &&&& {
    width: ${props => props.theme.pxToRem(590)};
    background: ${props => props.theme.colors.neutralWhite};
    margin-top: ${props => props.theme.pxToRem(48)};
    border-radius: ${props => props.theme.pxToRem(2)};
    padding: ${props => props.theme.pxToRem(37)} 0 ${props => props.theme.pxToRem(20)}!important;
    box-sizing: border-box;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    > .content {
      margin: 0 !important;
      text-align: center;
      box-sizing: border-box;
      padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(20)}!important;
    }

    > .header {
      margin: 0 auto;
      text-align: center;
      display: table;
      h2 {
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }

    > .actions {
      margin: 0 ${props => props.theme.pxToRem(20)};
    }
  }

  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const BoldText = styled.span`
  font-weight: ${props => props.theme.fontWeights.medium};
`;
