// ------------------------------------
// Constants

// ------------------------------------

export enum personLevelExemptionActionNames {
  SET_PROFESSIONAL_QUALIFICATION_DATA = 'personLevelExemption/SET_PROFFESIONAL_QUALIFICATION_DATA',
  SET_EDUCATIONAL_QUALIFICATION_DATA = 'personLevelExemption/SET_EDUCATIONAL_QUALIFICATION_DATA',
  SET_EDUCATIONAL_QUALIFICATION_DATA_FROM_REHYDRATE = 'personLevelExemption/SET_EDUCATIONAL_QUALIFICATION_DATA_FROM_REHYDRATE',
  SET_WORK_EXPERIENCE_DATA = 'personLevelExemption/SET_WORK_EXPERIENCE_DATA',
  GET_PERSON_LEVEL_EXEMPTION_UNIVERSITY_DETAILS = 'personLevelExemption/GET_PERSON_LEVEL_EXEMPTION_UNIVERSITY_DETAILS',
  SET_UNIVERSITY_DETAILS = 'personLevelExemption/SET_UNIVERSITY_DETAILS',
  SET_OFFLINE_EXEMPTION_STATUS = 'personLevelExemption/SET_OFFLINE_EXEMPTION_STATUS',
}

export enum professionalDataNames {
  US_STATE_BOARDS_OF_ACCOUNTANCY = 'US State Boards of Accountancy',
  CERTIFIED_PUBLIC_ACCOUNTANT = 'Certified Public Accountancy',
}

export const initialState: State.PersonLevelExemptionStatus = {
  id: '',
  exemptionKey: '',
  personAccountKey: '',
  status: '',
  date: '',
  graduationYear: '',
  professionalStatus: null,
  isLoading: true,
  professionalQualification: [
    {
      hasAccountingLicense: false,
      exemptionKey: '',
      selectedPassedUSCPAExam: '',
      nameOfProfessionalBody: '',
      nameofQualifications: '',
      yearQualify: '',
      membershipCandidateNumber: '',
    },
  ],
  educationalQualification: [],
  workExperience: [],
  tlwReason: '',
  yearsofAccountingExperience: '',
  experienceofWorking: '',
  chosenLowerExemption: '',
  personLevelExemptionRecords: {
    educRecord: [],
    profRecord: [],
  },
  personLevelExemptionFetched: false,
  universityDetails: [
    {
      id: '',
      exemptionId: '',
      universityName: '',
      universityHasMOU: false,
      graduationYear: '',
      eligibleFromYear: '',
      eligibleUntilYear: '',
    },
  ],
  universityDetailsFetched: false,
  hasActiveOfflineExemptionCalculation: false,
};
