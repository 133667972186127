import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { QUERY_LANDING_PAGE } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';
import { resetProductsModule } from 'modules/products/actions';

export const resetModule: any = createAction('landingPage/RESET_MODULE');
export const resetSkipLandingPageFrontloadRequest: any = createAction('landingPage/RESET_SKIP_FRONTLOAD');

export const fetchOfflinePageContent: any = createAction(
  'content/FETCH_OFFLINE_PAGE',
  (slug: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(resetProductsModule());

    return request(QUERY_LANDING_PAGE, { useDynamicTrending: false, slug, isPageStorefront: false });
  }
);
