import { jobRolesSlugIdMapSelector } from './selectors';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Helpers
// ------------------------------------

/*
 * Convert job roles filter slugs to job roles ids
 */
export const convertJobRolesSlugsToIds = (jobRoles: string | string[], state: State.Root): string[] | undefined => {
  if (!jobRoles) return;
  const jobRolesSlugs: string[] = Array.isArray(jobRoles) ? jobRoles : [jobRoles];
  // Call jobRolesSlugIdMapSelector only if jobRolesSlugs exist
  const jobRolesSlugIdMap: { [key: string]: string } = jobRolesSlugIdMapSelector(state);
  // Get jobRoles ids array from slugs if exist
  return jobRolesSlugs.map(slug => jobRolesSlugIdMap[slug]).filter(Boolean);
};

/*
 * slug id hash map creator
 */
export const slugIdMapCreator = (jobRoles: any[] | null): { [key: string]: string } => {
  if (!jobRoles) return emptyObject;
  return jobRoles.reduce((acc: any, jobRole: State.JobRole) => {
    acc[jobRole.slug] = jobRole.id;
    return acc;
  }, {});
};
