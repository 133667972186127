import styled from 'styled-components';
import { ProfileHeading } from 'components/molecules/ProfileHeading/ProfileHeading';
import { Dropdown as Dropdowns } from 'semantic-ui-react';
import {
  Dropdown,
  Link,
  Paragraph,
  Input,
  Checkbox,
  Label,
  Button as StyledButton,
  OnlyDesktopCSS,
  OnlyMobileCSS,
  NotificationBanner,
} from 'components/atoms';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { DropdownWithLabel } from 'components/molecules';
import { ReactComponent as IconCheckCircle } from 'resources/images/ic-check.svg';
import { ReactComponent as InfoBubbleInverted } from 'resources/images/info-bubble-inverted.svg';

export const StyledLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.s};
  margin: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} 0;
`;
export const StyledListContainer = styled.div`
  margin: ${props => props.theme.pxToRem(30)} 0;
  border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  &.university {
    &:last-child {
      border-bottom: none;
    }
  }
`;

export const StyledSpan = styled.span`
  color: ${props => props.theme.colors.primaryPurple};
  display: flex;
  justify-content: flex-end;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.bold};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledProfileHeading = styled(ProfileHeading)`
  border-bottom: none;
  margin-top: unset;
`;

export const StyledParagraph = styled(Paragraph)<{ align?: string }>`
  font-size: ${props => props.theme.fontSizes.s};
  text-align: ${props => (props.align ? props.align : 'inherit')};
  margin: unset;
`;

export const StyledTitle = styled(Paragraph)<{ align?: string }>`
  font-size: ${props => props.theme.fontSizes.l};
  text-align: ${props => (props.align ? props.align : 'inherit')};
  margin: unset;
`;

export const StyledDiv = styled.div`
  margin: ${props => props.theme.pxToRem(40)} 0;
`;

export const DropDownRoles = styled(Dropdown)`
  width: 100%;
  padding: ${props => props.theme.pxToRem(8)};
  margin-top: unset;
  .menu {
    height: ${props => props.theme.pxToRem(220)};
    overflow: auto;
  }
  min-width: unset;
  ${() => `
    &&& {
      &&&.ui.dropdown .menu {
        min-width: unset!important;
      }
    }
  `}
`;

export const StyledDropdown = styled(Dropdowns)`
  width: 100%;
  &&&&&.disabled {
    opacity: 1;
    background-color: ${props => props.theme.colors.neutralGrey2};
    input {
      color: ${props => props.theme.colors.neutralGrey8};
    }
  }

  &&&.ui.dropdown .menu > .item b {
    display: none;
  }

  &&&&& {
    height: ${props => props.theme.pxToRem(44)};
    display: flex;
    div {
      align-self: center;
    }
    i {
      padding-top: ${props => props.theme.pxToRem(12)};
    }
  }
`;

export const StyledDropdownMenuItem = styled(Dropdowns.Item)`
  &&&&& {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.primaryPurple};

    &:hover {
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

export const StyledInput = styled(Input)`
  &&& {
    display: flex;
    margin-bottom: ${props => props.theme.pxToRem(7)};
  }

  &&&.disabled {
    cursor: not-allowed;
    opacity: 1;
    input {
      border-color: ${props => props.theme.colors.neutralGrey4};
      background-color: ${props => props.theme.colors.neutralGrey2};
    }
  }
`;

export const StyledContainerCity = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

export const StyledContainerZIPCountry = styled.div`
  display: flex;
  max-width: ${props => props.theme.pxToRem(400)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

export const StyledContainerZIP = styled.div`
  margin-right: ${props => props.theme.pxToRem(16)};
  max-width: ${props => props.theme.pxToRem(180)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(144)};
  }
`;

export const StyledContainerState = styled.div`
  margin-right: ${props => props.theme.pxToRem(16)};
`;

export const StyledContainerPhoneNumber = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

export const StyledCheckBox = styled(Checkbox)`
  ${props => `
    &&&.ui.checkbox label {
      font-size: ${props.theme.pxToRem(14)}
      font-weight: ${props.theme.fontWeights.light};
    }
  `};
  width: ${props => props.theme.pxToRem(400)};
  height: ${props => props.theme.pxToRem(22)};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  display: inline-block;
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

export const StyledInputLabel = styled(Label)`
  height: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  display: inline-block;
  margin: ${props => props.theme.pxToRem(20)} 0 0;

  ${props => props.theme.mediaQueries.mobileOnly}  {
    margin: ${props => props.theme.pxToRem(12)} 0;
    display: block;
    width: 100%;
  }
`;

export const CenteredButtons = styled.div`
  text-align: center;
  margin: ${props => props.theme.pxToRem(40)} auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

export const StyledUnderstandButton = styled(StyledButton)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

export const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(4)};
    margin-left: ${props => props.theme.pxToRem(4)};
  }
`;

export const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

export const StyledNotificationBanner = styled(NotificationBanner)`
  margin-top: ${props => props.theme.pxToRem(24)};
`;

export const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;

export const StyledResetLink = styled.div`
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  margin-top: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.primaryPurple};
`;

export const StyledVerticalLine = styled.div`
  margin-top: ${props => props.theme.pxToRem(24)};
  border-left: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey5};
  display: inline-block;
  width: 100%;
  padding-left: ${props => props.theme.pxToRem(23)};
`;

export const StyledLabelBusinessCity = styled.label`
  margin-top: ${props => props.theme.pxToRem(32)};
  margin-bottom: ${props => props.theme.pxToRem(8)};

  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};

  display: block;
`;

export const StyledContainer = styled.div`
  * {
    font-family: ${props => props.theme.fontFamily};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: 0;
    padding-right: 0;
  }
  min-height: ${props => props.theme.pxToRem(200)};
`;

export const StyledCountryDropdown = styled(DropdownWithLabel)`
  .divider.text {
    cursor: pointer !important;

    .ui.image {
      margin-top: ${props => props.theme.pxToRem(-4)} !important;
    }
  }
`;

export const StyledCountryContainer = styled.div`
  max-width: ${props => props.theme.pxToRem(336)};
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

export const StyledEmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${props => props.theme.pxToRem(200)};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => `${props.theme.pxToRem(40)} 0`};
`;

export const StyledCheckIcon = styled(IconCheckCircle)`
  text-align: center;
`;

export const StyledSuccessModalContainer = styled.div`
  width: ${props => props.theme.pxToRem(64)};
  height: ${props => props.theme.pxToRem(64)};
  border: solid ${props => props.theme.pxToRem(1)} #efefef;
  display: flex;
  background: #f7f7f7;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: ${props => props.theme.pxToRem(30)};
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

export const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: right;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: ${props => props.theme.pxToRem(10)};
`;

export const StyledRadioLabel = styled.label`
  margin-right: ${props => props.theme.pxToRem(10)};
  margin-left: ${props => props.theme.pxToRem(10)};
`;

export const FlexedRadioDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.pxToRem(40)};
  margin-top: ${props => props.theme.pxToRem(30)};
  width: 100%;
  max-width: ${props => props.theme.pxToRem(700)};
`;

export const FlexedRadioDivFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-width: ${props => props.theme.pxToRem(320)};
`;

export const FlexedRadioDivLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FlexedEmploymentStatusFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.pxToRem(10)};
  min-width: ${props => props.theme.pxToRem(500)};
  max-width: ${props => props.theme.pxToRem(500)};
  margin-bottom: ${props => props.theme.pxToRem(40)};
`;

export const StyledCIMAInfoText = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

export const StyledInfoBubbleInverted = styled(InfoBubbleInverted)`
  margin-right: ${props => props.theme.pxToRem(8)};
`;

export const StyledConsentBox = styled(StyledCheckBox as any)`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

export const StyledBreak = styled.div`
  margin-top: ${props => props.theme.pxToRem(24)};
`;
