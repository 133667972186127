import { User, MembershipTypes } from 'mxp-schemas';

export const getFakeMemberData = (isCenter: boolean = false) => ({
  hash: firmRosterMembersDummyData.reduce<any>((acc, m) => {
    acc[m.userId] = {
      ...m,
      ...(isCenter && {
        invoiceDate: '2019-12-01T00:00-04:00',
        dueDate: '2019-12-01T00:00-04:00',
      }),
    };
    return acc;
  }, {}),
  count: 100,
  selectedIds: ['0123456789'],
  modifiedHash: {},
  isLoading: false,
  isFetched: true,
  isPaidByFirmCount: 100,
  isPaidByFlpCount: 0,
  isInFirmRosterCount: 100,
  flpCount: 100,
  downloadingId: null,
  downloadingError: false,
});

export const firmRosterMembersDummyData: User.FirmRosterMember[] = [
  {
    userId: '0123456789',
    organizationId: '0123456710',
    rootOrganizationId: '0123456710',
    firstName: 'Adrian',
    firstNameLower: 'adrian',
    lastName: 'ALFREDO',
    lastNameLower: 'alfredo',
    email: 'alfredo@abccompany.com',
    isPaidByFirm: false,
    isPaidByFlp: false,
    isAddToFirmBillingDisabled: true,
    isAddToFlpFirmBillingDisabled: true,
    isAdmin: false,
    isStudent: false,
    position: MembershipTypes.RolesNames.Partner,
    membershipType: User.B2BMembershipTypes.AFFILIATE,
    membershipTier: User.B2BMembershipTiers.VOTING,
    membershipStatus: User.MembershipStatus.ACTIVE,
    branchCountry: 'US',
    branchState: 'TX',
    branchCity: 'Houston',
    officeLocation: '1021 Spring Cypress Road',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
    createDateTime: '',
    applicationNumber: '',
    relationWithFirm: '',
    organizationJunctionId: '',
    firmBillingJunctionId: '',
    flpFirmBillingJunctionId: '',
    studentBillingJunctionId: '',
    oktaId: 'dm21m012m2',
    individualId: 'dma0oiwndonawnd2',
    emailId: ' aond12lowndnwand',
    aicpaId: '',
    expiryDate: null,
    subscriptionStatus: null,
    totalAddonCost: null,
    isSubscriptionsFetched: null,
    subscriptions: null,
    initialMembershipType: User.B2BMembershipTypes.AFFILIATE,
    isHonorary: false,
    isTLW: false,
    initialFlpSubscriptionType: undefined,
    initialFlpSubscriptionDuration: undefined,
    oktaStatus: User.OktaStatus.ACTIVE,
  },
  {
    userId: '0123456799',
    organizationId: '0123456711',
    rootOrganizationId: '0123456711',
    firstName: 'Bob',
    firstNameLower: 'Bob',
    lastName: 'BRIGHTWELL',
    lastNameLower: 'brigthwell',
    isPaidByFirm: true,
    isPaidByFlp: false,
    isAddToFirmBillingDisabled: false,
    isAddToFlpFirmBillingDisabled: false,
    isAdmin: false,
    isStudent: false,
    email: 'bbrightwell@gmail.com',
    position: MembershipTypes.RolesNames.Partner,
    membershipType: User.B2BMembershipTypes.CANDIDATE,
    membershipTier: User.B2BMembershipTiers.PRE_CANDIDATE,
    membershipStatus: User.MembershipStatus.ACTIVE,
    branchCountry: 'US',
    branchState: 'TX',
    branchCity: 'Houston',
    officeLocation: '1021 Spring Cypress Road',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
    createDateTime: '',
    applicationNumber: '',
    relationWithFirm: '',
    organizationJunctionId: '',
    firmBillingJunctionId: '',
    flpFirmBillingJunctionId: '',
    studentBillingJunctionId: '',
    oktaId: 'dm21m012m2',
    individualId: 'dma0oiwndonawnd2',
    emailId: ' aond12lowndnwand',
    aicpaId: '',
    expiryDate: null,
    subscriptionStatus: null,
    totalAddonCost: null,
    isSubscriptionsFetched: null,
    subscriptions: null,
    initialMembershipType: User.B2BMembershipTypes.CANDIDATE,
    isHonorary: false,
    isTLW: false,
    initialFlpSubscriptionType: undefined,
    initialFlpSubscriptionDuration: undefined,
    oktaStatus: User.OktaStatus.ACTIVE,
  },
  {
    userId: '0123456111',
    organizationId: '0123456712',
    rootOrganizationId: '0123456712',
    firstName: 'Claudio',
    firstNameLower: 'claudio',
    lastName: 'CLEVERMAN',
    lastNameLower: 'cleverman',
    email: 'ccleverman@abccompany.com',
    isPaidByFirm: false,
    isPaidByFlp: false,
    isAddToFirmBillingDisabled: false,
    isAddToFlpFirmBillingDisabled: false,
    isAdmin: false,
    isStudent: false,
    position: MembershipTypes.RolesNames.Partner,
    membershipType: User.B2BMembershipTypes.AFFILIATE,
    membershipTier: User.B2BMembershipTiers.LEAD,
    membershipStatus: User.MembershipStatus.ACTIVE,
    branchCountry: 'US',
    branchState: 'IL',
    branchCity: 'Springfield',
    officeLocation: '1968 Leland Grove Trail',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
    createDateTime: '',
    applicationNumber: '',
    relationWithFirm: '',
    organizationJunctionId: '',
    firmBillingJunctionId: '',
    flpFirmBillingJunctionId: '',
    studentBillingJunctionId: '',
    oktaId: 'dm21m012m2',
    individualId: 'dma0oiwndonawnd2',
    emailId: ' aond12lowndnwand',
    aicpaId: '',
    expiryDate: null,
    subscriptionStatus: null,
    totalAddonCost: null,
    isSubscriptionsFetched: null,
    subscriptions: null,
    initialMembershipType: User.B2BMembershipTypes.AFFILIATE,
    isHonorary: false,
    isTLW: false,
    initialFlpSubscriptionType: undefined,
    initialFlpSubscriptionDuration: undefined,
    oktaStatus: User.OktaStatus.ACTIVE,
  },
];
