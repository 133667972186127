import React from 'react';

import styled from 'styled-components/macro';

interface MemberDetailProps {
  title: string;
  uniqueLearnerNumber: string;
  level: string;
  dateOfSubmission: string;
}

export const CimaMemberDetails: React.FC<MemberDetailProps> = ({
  title,
  uniqueLearnerNumber,
  level,
  dateOfSubmission,
}) => {
  return (
    <StyledContainer>
      <StyledMemberHeader>{title}</StyledMemberHeader>
      <StyledSection>
        <StyledDivider>
          <StyledTitle>Unique Learner Number (ULN)</StyledTitle>
          <StyledSpan>{uniqueLearnerNumber}</StyledSpan>
        </StyledDivider>

        <StyledDivider>
          <StyledTitle>Level</StyledTitle>
          <StyledSpan>{level}</StyledSpan>
        </StyledDivider>

        <StyledDivider>
          <StyledTitle>Date of EPA2 submission</StyledTitle>
          <StyledSpan>{dateOfSubmission}</StyledSpan>
        </StyledDivider>
      </StyledSection>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  justify-content: space-between;
  align-content: center;
  width: 100%;
`;

const StyledMemberHeader = styled.div`
  font-size: ${props => props.theme.pxToRem(24)};
  margin-bottom: ${props => props.theme.pxToRem(10)};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const StyledSection = styled.div`
  border-radius: ${props => props.theme.pxToRem(10)};
  border: solid ${props => props.theme.pxToRem(0.5)} #bababa;
  margin-top: ${props => props.theme.pxToRem(20)};
  padding: ${props => props.theme.pxToRem(25)};
`;

const StyledTitle = styled.span`
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const StyledSpan = styled.span`
  margin-top: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.pxToRem(16)};
`;

const StyledDivider = styled.div`
  display: grid;
  margin-bottom: ${props => props.theme.pxToRem(25)};
`;
