import * as Sentry from '@sentry/browser';
import { handleActions } from 'redux-actions';
import { setError, setLoading, createFeedBack, updateFeedBack } from './actions';
import { initialState } from './constants';

if ([setLoading, setError].includes(undefined)) {
  const message: string = 'contractLineItem module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [setError]: (state: State.CimaFeedBack, action: { payload: CustomErrors.GraphQLError }): State.CimaFeedBack => ({
    ...state,
    error: action.payload,
  }),
  [setLoading]: (state: State.CimaFeedBack): State.CimaFeedBack => ({
    ...state,
    loading: true,
  }),
  [createFeedBack]: {
    next: (state: State.CimaFeedBack): State.CimaFeedBack => ({
      ...state,
      loading: false,
    }),
  },
  [updateFeedBack]: {
    next: (state: State.CimaFeedBack): State.CimaFeedBack => ({
      ...state,
      loading: false,
    }),
  },
};

export { setLoading, setError };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
