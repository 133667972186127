import React from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';
import { Grid } from 'semantic-ui-react';
import { Product } from 'mxp-schemas';

/**
 * Confirmation Details Modal
 * @category Refunds & Cancellations
 * RBUILD-8850
 */
export interface Props {
  productMemo: string;
  amount: string;
  isRefundWithCancellation?: boolean;
  isRefundOnlyNoCancellation?: boolean;
  isRefundWithoutCancellation?: boolean;
  isCancelAndMove?: boolean;
  refundType: string;
  credentials?: [];
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  isLoading?: boolean;
  isRefund?: boolean;
  cancelRefundData?: any;
}

export const ConfirmationDetailsModal: React.FC<Props> = ({
  productMemo,
  amount,
  refundType,
  credentials,
  open,
  onClose,
  onConfirm,
  isLoading,
  isRefund,
  cancelRefundData,
}) => {
  const heading = <>{`Are you sure you want to ${isRefund ? 'refund' : 'cancel'} this product?`}</>;
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      heading={heading}
      testId="confirmation-details-modal"
      showCloseCross
      cancelNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          testId="cancel-refund"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
      }
      confirmNode={
        <Button
          size={ButtonEnums.sizes.small}
          testId="confirm-refund"
          variant={ButtonEnums.variants.primary}
          onClick={onConfirm}
          loading={isLoading}
        >
          Confirm {isRefund ? 'refund' : 'cancellation'}
        </Button>
      }
    >
      <Grid stackable centered columns={3}>
        <StyledGridRow />
        {productMemo && (
          <StyledGridRow>
            <StyledBold>{productMemo}</StyledBold> {isRefund ? 'have a refund applied.' : 'will be cancelled.'}
          </StyledGridRow>
        )}
        {amount ? (
          <StyledGridRow>
            <StyledBold>{amount}</StyledBold>{' '}
            {cancelRefundData?.requiredAction === Product.RefundOrCancellationEnums.CANCEL_AND_MOVE
              ? `will be available to move..`
              : 'will be refunded.'}
          </StyledGridRow>
        ) : (
          <StyledGridRow>
            <StyledBold>$0</StyledBold> will be {isRefund ? 'refunded' : 'cancelled'}.
          </StyledGridRow>
        )}
        {refundType && (
          <StyledGridRow>
            Refund type is <StyledBold>{refundType}</StyledBold>.
          </StyledGridRow>
        )}
        {credentials && (
          <StyledGridRow>
            <StyledBold>{credentials}</StyledBold> will also be cancelled with no refund applied.
          </StyledGridRow>
        )}
      </Grid>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &&&& {
    background: ${props => props.theme.colors.neutralGrey1};
    min-height: ${props => props.theme.pxToRem(333)};
    max-width: ${props => props.theme.pxToRem(590)};
    margin-top: ${props => props.theme.pxToRem(48)};
    > .header {
      h2 {
        min-height: ${props => props.theme.pxToRem(64)};
        max-width: ${props => props.theme.pxToRem(440)};
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }
  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding-bottom: ${props => props.theme.pxToRem(10)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-top: ${props => props.theme.pxToRem(10)};
    }
  }
`;

const StyledBold = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: 0 ${props => props.theme.pxToRem(3)} 0 ${props => props.theme.pxToRem(3)};
`;
