import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  existingSectionSelector,
  membershipTiersSelector,
  userMembershipBenefitsSelector,
  userMembershipPackageSelector,
} from 'modules/membership/selectors';
import {
  addMembershipPackageSectionFreeProduct,
  addMembershipPackageSectionWithPriceProduct,
  removeMembershipPackageSectionFreeProduct,
  removeMembershipPackageSectionWithPriceProduct,
} from 'modules/membership/actions';
import { MembershipCard } from 'components/organisms';
import { productTypeToSlug, fromCentsFormat, formatPrice, getSelectedTierName } from 'utils';
import { AddNewComponentProps, StyledGrid, StyledGridColumn } from './AddNewMembershipProduct';
import { IC_INFO } from 'resources/images';
import styled from 'styled-components';
import { Divider } from 'components/atoms';

export const AddNewSections = ({
  list,
  freeList = [],
  currency,
  dispatch,
  variantsPriceInfoForUser,
  buttonGroup,
}: AddNewComponentProps) => {
  const { list: benefitsList } = useSelector(userMembershipBenefitsSelector);
  const existingSections = useSelector(existingSectionSelector);
  const {
    sectionFreeProduct: userChoicesFreeSection,
    sectionProductWithPrice: userChoicesSection,
    tier: userChoicesTier,
  } = useSelector(userMembershipPackageSelector);
  const membershipTiers = useSelector(membershipTiersSelector);
  const currentSelectedTierName = membershipTiers?.list?.find((tier: any) => tier?.sku === userChoicesTier)?.tierName;
  const [shouldShowLabel, setShouldShowLabel] = useState(false);
  const [selectedFreeSection, setSelectedFreeSection] = useState('');
  const [selectedSectionWithPrice, setSelectedSectionWithPrice] = useState(['']);
  const [isAnyOfTheCardSelected, setisAnyOfTheCardSelected] = useState(false);

  React.useEffect(() => {
    const hasSectionTypeBenefits = benefitsList?.some(benefit => benefit.productType === 'section-type');

    userChoicesFreeSection.forEach((item: any) => {
      if (hasSectionTypeBenefits && !selectedSectionWithPrice.includes(item.productId)) {
        setSelectedFreeSection(item.productId);
      }
    });

    if (hasSectionTypeBenefits) {
      setShouldShowLabel(true);
    } else {
      setShouldShowLabel(false);
    }
  }, [userChoicesFreeSection, benefitsList, existingSections, setSelectedFreeSection, selectedSectionWithPrice]);

  const handleSectionWithPriceButtonClick = useCallback(
    (productId: string, sku: string, selected: boolean) => {
      if (selected) {
        const remainingSelectedSectionWithPrice = selectedSectionWithPrice.filter(
          sectionProductId => sectionProductId !== productId
        );
        setSelectedSectionWithPrice([...remainingSelectedSectionWithPrice]);
        dispatch(removeMembershipPackageSectionWithPriceProduct(sku));
      } else {
        setSelectedSectionWithPrice([...selectedSectionWithPrice, productId]);
        dispatch(addMembershipPackageSectionWithPriceProduct(productId, sku));
        dispatch(removeMembershipPackageSectionFreeProduct(productId));
      }
    },
    [dispatch, selectedSectionWithPrice]
  );

  const handleMembershipSectionFreeProductButtonClick = useCallback(
    (args: {
      productId: string;
      sku: string;
      group: string;
      selected: boolean;
      groupSku: string;
      benefitName: string;
    }) => {
      const { selected, productId } = args;
      if (selected) {
        dispatch(removeMembershipPackageSectionFreeProduct(productId));
        setisAnyOfTheCardSelected(false);
        setSelectedFreeSection('');
      } else {
        setisAnyOfTheCardSelected(true);
        dispatch(addMembershipPackageSectionFreeProduct(args));
        setSelectedFreeSection(productId);
      }
    },
    [dispatch]
  );

  return (
    <>
      <StyledSectionsTextWrapper>
        <StyledSectionLabel>
          <StyledGreySectionsText>
            Access a network of experts and resources tailored to your professional knowledge and growth.
          </StyledGreySectionsText>
          {shouldShowLabel && (
            <>
              <StyledBoldPurpleSectionsText>
                {getSelectedTierName(currentSelectedTierName)} includes one section of your choice.
              </StyledBoldPurpleSectionsText>
              <StyledPurpleSectionsText>You may purchase additional sections.</StyledPurpleSectionsText>
            </>
          )}
        </StyledSectionLabel>
        <StyledInfoBubble src={IC_INFO} />
      </StyledSectionsTextWrapper>
      {shouldShowLabel && (
        <>
          <StyledSectionsTextWrapper>
            <StyledSectionLabel>
              <StyledBoldPurpleSectionsText>{userChoicesFreeSection.length}/1 </StyledBoldPurpleSectionsText>
              <StyledIncludedSectionsCounterText>Included Sections</StyledIncludedSectionsCounterText>
            </StyledSectionLabel>
          </StyledSectionsTextWrapper>
          <StyledGrid centered columns={2}>
            {[...freeList].map(data => {
              const isProductPreviouslyClaimed = userChoicesFreeSection.some(item => item.productId === data.productId);

              const isSelected = isAnyOfTheCardSelected
                ? data.productId === selectedFreeSection
                : isProductPreviouslyClaimed;

              const shouldCardsBeDisabled =
                data.productId !== selectedFreeSection && Boolean(selectedFreeSection?.length);

              return (
                <StyledGridColumn key={data.variants[0].sku}>
                  <MembershipCard
                    disabled={shouldCardsBeDisabled}
                    isEnable={!shouldCardsBeDisabled}
                    headerTitle={data.name}
                    description={data.description}
                    formattedPrice={formatPrice(fromCentsFormat(variantsPriceInfoForUser(data)), currency.label)}
                    isCardSelected={isSelected}
                    slug={`${productTypeToSlug(data.productType as any)}/${data.slug}`}
                    handleClick={handleMembershipSectionFreeProductButtonClick.bind(null, {
                      productId: data.productId,
                      sku: data.variants[0].sku || '',
                      group: data.includedInBenefit?.value?.[0]?.obj?.id,
                      selected: isSelected,
                      groupSku: data.includedInBenefit?.value?.[0]?.obj?.masterData?.current?.masterVariant
                        ?.sku as string,
                      benefitName: data.name,
                    })}
                    productDetails={data}
                    isExistingMember={true}
                    isAddNewMembershipProduct
                    shouldShowNewPackageBuilder={true}
                    isFree={true}
                    isRenewal={true}
                  />
                </StyledGridColumn>
              );
            })}
          </StyledGrid>
          <StyledDivider />
          <StyledSectionsBottomTextWrapper>
            <StyledPurpleSectionsText>
              <StyledSectionLabel>You can also choose any additional sections</StyledSectionLabel>
            </StyledPurpleSectionsText>
          </StyledSectionsBottomTextWrapper>
        </>
      )}
      <StyledGrid centered columns={2}>
        {[...list].map(data => {
          const isSelected = userChoicesSection.some(item => item.productId === data.productId);
          const showAsIncluded = data.productId === selectedFreeSection;

          return (
            <StyledGridColumn key={data.variants[0].sku}>
              <MembershipCard
                headerTitle={data.name}
                description={data.description}
                formattedPrice={formatPrice(fromCentsFormat(variantsPriceInfoForUser(data)), currency.label)}
                isCardSelected={isSelected}
                isCurrent={false}
                slug={`${productTypeToSlug(data.productType as any)}/${data.slug}`}
                handleClick={handleSectionWithPriceButtonClick.bind(
                  null,
                  data.productId,
                  data.variants[0].sku as string,
                  isSelected
                )}
                productDetails={data}
                isExistingMember={true}
                isAddNewMembershipProduct
                shouldShowNewPackageBuilder
                showAsIncluded={showAsIncluded}
              />
            </StyledGridColumn>
          );
        })}
      </StyledGrid>
      {buttonGroup}
    </>
  );
};

const StyledSectionsTextWrapper = styled.div`
  display: flex;
  padding: ${props => `${props.theme.pxToRem(0)}`} ${props => `${props.theme.pxToRem(24)}`};
  align-items: flex-start;
  gap: ${props => `${props.theme.pxToRem(8)}`};
  align-self: stretch;
  justify-content: center;
  margin-top: ${props => `${props.theme.pxToRem(16)}`};
`;

const StyledSectionsBottomTextWrapper = styled.div`
  display: flex;
  padding: ${props => `${props.theme.pxToRem(0)}`} ${props => `${props.theme.pxToRem(88)}`};
  align-items: flex-start;
  justify-content: start;
  margin-bottom: ${props => `${props.theme.pxToRem(32)}`};
`;

const StyledSectionLabel = styled.div`
  font-size: ${props => `${props.theme.pxToRem(16)}`};
  font-style: normal;
  line-height: 150%;
  letter-spacing: ${props => `${props.theme.pxToRem(-0.32)}`};
`;

const StyledGreySectionsText = styled.p`
  color: var(--neutral-n-07, ${props => props.theme.colors.neutralGrey10});
  font-weight: 400;
  margin-bottom: ${props => `${props.theme.pxToRem(0)}`};
`;

const StyledBoldPurpleSectionsText = styled.span`
  color: var(--Brand-Purple, ${props => props.theme.colors.primaryPurple});
  font-weight: 700;
`;

const StyledPurpleSectionsText = styled.span`
  color: var(--Brand-Purple, ${props => props.theme.colors.primaryPurple});
  font-weight: 400;
`;

const StyledInfoBubble = styled.img`
  fill: ${props => props.theme.colors.primaryPurple};
`;

const StyledIncludedSectionsCounterText = styled.span`
  color: var(--neutral-n-07, ${props => props.theme.colors.neutralGrey10});
  font-weight: 400;
`;

const StyledDivider = styled(Divider)`
  &&&& {
    margin: ${props => `${props.theme.pxToRem(24)}`} ${props => `${props.theme.pxToRem(89)}`};
    background: ${props => `${props.theme.colors.primaryPurple}`};
    height: ${props => `${props.theme.pxToRem(1)}`};
  }
`;
