import React from 'react';
import styled from 'styled-components';

interface DetailsType {
  label: string;
  value: string;
}

interface Props {
  details: DetailsType[];
}

export const TextAreaDetails: React.FC<Props> = ({ children, details }) => {
  return (
    <MainContainer>
      {details.map((item, index) => (
        <StyledItem key={`${item.label}_${index}`}>
          <StyledLabel>{item.label}</StyledLabel>
          {index + 1 === details.length ? (
            <StyledValueNoMargin>{item.value}</StyledValueNoMargin>
          ) : (
            <StyledValue>{item.value}</StyledValue>
          )}
        </StyledItem>
      ))}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${props => props.theme.pxToRem(20)} 0 ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(20)};
  border-radius: ${props => props.theme.pxToRem(8)};
  border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey3};
  background-color: ${props => props.theme.colors.neutralWhite};
`;
const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.div`
  margin: 0 ${props => props.theme.pxToRem(200)} ${props => props.theme.pxToRem(4)} 0;
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledValue = styled.div`
  font-size: ${props => props.theme.pxToRem(16)};
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledValueNoMargin = styled.div`
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
`;
