import React from 'react';
import styled from 'styled-components';
import { MomentHelpers } from 'utils/MomentHelpers';

interface Props {
  date: string;
}

export const DatePublished: React.FC<Props> = React.memo(({ date }) => (
  <StyledDate tabIndex={0} data-testid="date-published">
    {MomentHelpers.getDiff(new Date().toISOString(), date, 'hours') >= 24
      ? MomentHelpers.getDate(date)
      : MomentHelpers.getAgoTime(date)}
  </StyledDate>
));

const StyledDate = styled.small`
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey7};
`;
