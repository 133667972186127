import { createAction } from 'redux-actions';

// ------------------------------------
// Actions
// ------------------------------------
export const getCareerStagesHash: any = createAction(
  'skills/GET_CAREERSTAGES_HASH',
  (careerStages: State.CareerStages) => () => {
    return careerStages;
  }
);
