import React, { useCallback } from 'react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import {
  Button,
  ButtonEnums,
  Divider,
  Modal,
  OnlyDesktopCSS,
  OnlyMobileCSS,
  ButtonLink,
  ButtonVariants,
  ButtonProps,
} from 'components/atoms';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  quoteStatusAndIsMembershipPaidByFirm: State.QuotesStatusAndPaidByFirm | null;
  memberInitiatedUpgradeFlag?: boolean;
  pathToUpgradeMembershipForm?: string;
  useNewMembershipAICPA?: boolean;
}

export const ModalUpgradingMembership: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  handleSubmit,
  quoteStatusAndIsMembershipPaidByFirm,
  memberInitiatedUpgradeFlag = false,
  pathToUpgradeMembershipForm = '',
  useNewMembershipAICPA = false
}) => {
  const handleButtonClick = useCallback(() => {
    if (quoteStatusAndIsMembershipPaidByFirm) {
      if (quoteStatusAndIsMembershipPaidByFirm?.quoteStatusIsNotRejected) handleClose();
      else if (quoteStatusAndIsMembershipPaidByFirm?.isPaidByFirm) handleSubmit();
    }
  }, [handleSubmit, handleClose, quoteStatusAndIsMembershipPaidByFirm]);

  return (
    <StyledModal
      size="tiny"
      heading={
        memberInitiatedUpgradeFlag
          ? 'You are Upgrading a Membership Currently Being Paid for by an Organisation'
          : quoteStatusAndIsMembershipPaidByFirm
          ? quoteStatusAndIsMembershipPaidByFirm?.quoteStatusIsNotRejected
            ? 'Your membership is part of a Firm \n Billing Invoice'
            : quoteStatusAndIsMembershipPaidByFirm?.isPaidByFirm
            ? 'You are Upgrading a Membership Currently \n Being Paid for by a Firm'
            : ''
          : ''
      }
      open={isOpen}
    >
      <CloseButton>
        <StyledCloseIcon onClick={handleClose} />
      </CloseButton>
      <Spacer />
      <CenteredText>
        {memberInitiatedUpgradeFlag
          ? 'Please note that while one-time upgrade fees must be self-paid, your membership \nrenewals are currently set to be paid by your sponsoring organisation. If you are no \n longer with your sponsoring organization, please go to your user profile to update your \n organisation relationship, then continue to upgrade your membership.'
          : quoteStatusAndIsMembershipPaidByFirm
          ? quoteStatusAndIsMembershipPaidByFirm?.quoteStatusIsNotRejected
            ? 'If you are still employed with the firm, please contact firmservices@aicpa.org \n if you are no longer with the firm, please update your employment \n information, then continue to upgrade your membership.'
            : quoteStatusAndIsMembershipPaidByFirm?.isPaidByFirm
            ? 'If you wish to proceed and make any payment of your own, click confirm. If you are no \n longer with the firm, please update your employment information, then continue to \n upgrade your membership.'
            : ''
          : ''}
      </CenteredText>
      <DividerWithBottomMargin />
      <CenteredButtons>
        <DesktopStyled>
          {memberInitiatedUpgradeFlag ? (
            <StyledButtonLink
              onClick={handleSubmit}
              size={ButtonEnums.sizes.medium}
              variant={ButtonVariants.primary}
              bordered
              to={pathToUpgradeMembershipForm !== undefined ? pathToUpgradeMembershipForm : ''}
              testId="upgrade-membership-button"
              useNewMembershipAICPA={useNewMembershipAICPA}
            >
              Confirm
            </StyledButtonLink>
          ) : (
            <StyledSubmitButton
              onClick={handleButtonClick}
              quoteStatusIsNotRejected={
                quoteStatusAndIsMembershipPaidByFirm
                  ? quoteStatusAndIsMembershipPaidByFirm?.quoteStatusIsNotRejected
                  : null
              }
            />
          )}
        </DesktopStyled>
        <MobileStyled>
          <StyledSubmitButton
            onClick={handleButtonClick}
            quoteStatusIsNotRejected={
              quoteStatusAndIsMembershipPaidByFirm
                ? quoteStatusAndIsMembershipPaidByFirm?.quoteStatusIsNotRejected
                : null
            }
          />
        </MobileStyled>
      </CenteredButtons>
    </StyledModal>
  );
};

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;
const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

interface ModalUpgradeButtonProps {
  onClick: () => void;
  quoteStatusIsNotRejected: boolean | null;
}

const StyledSubmitButton: React.FC<ModalUpgradeButtonProps> = ({ onClick, quoteStatusIsNotRejected }) => (
  <StyledButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId={`${quoteStatusIsNotRejected ? 'understand' : 'confirm'}-upgrading-membership`}
  >
    {quoteStatusIsNotRejected ? 'I understand' : 'Confirm'}
  </StyledButton>
);

const StyledButton = styled(Button)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;

const StyledButtonLink = styled(ButtonLink)<ThemedStyledProps<ButtonProps, DefaultTheme>>`
  ${({ theme, disabled, useNewMembershipAICPA }) =>
    `
    &&&&& {
    padding: ${theme.pxToRem(8)} ${theme.pxToRem(80)};

    ${
      !disabled
        ? `
        &:active,
        &:focus {
          background-color: ${theme.colors.primaryPurple};
          color: ${theme.colors.neutralWhite};
          border: ${theme.pxToRem(1)} solid ${theme.colors.primaryPurple};
        }

        ${theme.mediaQueries.computerMin} {
          &:hover {
            background-color: ${theme.colors.primaryLightPurple};
            color: ${theme.colors.neutralWhite};
            border-color: transparent;
          }
        }
        `
        : ''
    };

    &:disabled {
      border-color: ${theme.colors.neutralGrey4};
      background-color: transparent;
      color: ${theme.colors.neutralGrey4};
      opacity: 1 !important;
    }

    ${
      disabled
        && `
          color: ${useNewMembershipAICPA ? theme.colors.neutralGrey8 : theme.colors.neutralWhite};
          border-color: ${theme.colors.neutralGrey4};
          background-color: ${theme.colors.neutralGrey4};
          opacity: 1 !important;
          cursor: default;
        `
    }
  }
`}
`;
