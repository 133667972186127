import React from 'react';
import { Modal, Button, ButtonEnums } from '../../atoms';
import { BenefitCard } from '../../atoms/BenefitCard/BenefitCard';
import styled from 'styled-components/macro';
import { Contentful } from 'mxp-schemas';
import { Grid } from 'semantic-ui-react';
interface Props {
  open: boolean;
  onClose: () => void;
  benefits?: Contentful.MembershipRestriction.BenefitsSubBlock[];
  productTitle?: string;
  benefitDescription?: string;
}

export const BenefitsModal: React.FC<Props> = ({ benefits, open, onClose, productTitle, benefitDescription }) => {
  const heading = <div dangerouslySetInnerHTML={{ __html: productTitle ? productTitle : '' }} />;
  return (
    <div>
      <StyledModal
        open={open}
        onClose={onClose}
        heading={heading}
        testId="benefits-modal"
        showCloseCross
        cancelNode={
          <StyledButton
            size={ButtonEnums.sizes.medium}
            variant={ButtonEnums.variants.primary}
            testId="view-purchase-options-link"
            onClick={onClose}
          >
            View My Purchase Options
          </StyledButton>
        }
      >
        <StyledText dangerouslySetInnerHTML={{ __html: benefitDescription ? benefitDescription : '' }} />
        <Spacing />
        <StyledGrid columns="3">
          {benefits?.map(item => (
            <Grid.Column computer={5} mobile={16} key={item.name}>
              <BenefitCard
                testId={`bsb-${item.name}`}
                title={item.header}
                description={item.description}
                imageUrl={item.image?.url}
                to={item.url}
              />
            </Grid.Column>
          ))}
        </StyledGrid>
      </StyledModal>
    </div>
  );
};

const Spacing = styled.div`
  margin: ${props => `${props.theme.pxToRem(40)} 0`};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `${props.theme.pxToRem(20)} 0`};
  }
`;
const StyledGrid = styled(Grid)`
  &&&& {
    display: flex;
    justify-content: space-around;
  }
`;
const StyledModal = styled(Modal)`
  &&&&&&& {
    max-width: ${props => props.theme.pxToRem(1200)};
    align-items: center;
    margin-top: ${props => props.theme.pxToRem(48)};

    > .header {
      h2 {
        margin-bottom: ${props => props.theme.pxToRem(10)};
        font-size: ${props => props.theme.fontSizes.xxl};
      }
    }
  }
  &&.ui.modal {
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 95% !important;
    }
    ${props => props.theme.mediaQueries.desktopOnly} {
      width: 95% !important;
    }
    ${props => props.theme.mediaQueries.tabletOnly} {
      width: 98% !important;
    }
  }
  && .ui.modal {
    background: ${props => props.theme.colors.neutralGrey1};
  }
`;

const StyledButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(356)} !important;
    height: ${props => props.theme.pxToRem(48)};
    font-size: ${props => props.theme.fontSizes.s};
    padding: ${props => props.theme.pxToRem(10)};
  }
`;

const StyledText = styled.p`
  line-height: 1.57;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.m};
`;
