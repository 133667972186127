import { handleActions } from 'redux-actions';
import { checkDeviceSSOAuthSupport, checkIsUserOsMacos } from './actions';
import { isSSOAuthSupported, isUserOsMacos } from 'utils';
const INITIAL_STATE = {
  isSSOAuthSupported: false,
  isUserOsMacos: false,
};
export default handleActions(
  {
    [checkDeviceSSOAuthSupport]: (state: State.Device, action: any): State.Device => ({
      ...state,
      isSSOAuthSupported: isSSOAuthSupported(),
    }),
    [checkIsUserOsMacos]: (state: State.Device, action: any): State.Device => ({
      ...state,
      isUserOsMacos: isUserOsMacos(),
    }),
  },
  INITIAL_STATE
);
