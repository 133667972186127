import React from 'react';
import styled from 'styled-components';
import { Tab as SemanticTab } from 'semantic-ui-react';

interface TabProps {
  panes: {
    menuItem: any;
    render: () => React.ReactNode;
    onClick?: (e?: React.SyntheticEvent<HTMLElement>) => void;
  }[];
  activeIndex?: number;
}

interface TabPaneProps {
  children: any;
}

export class Tab extends React.Component<TabProps> {
  static Pane: React.FC<TabPaneProps> = ({ children }) => (
    <SemanticTab.Pane attached={false}>{children}</SemanticTab.Pane>
  );

  tabChangeHandler = (event: React.MouseEvent<HTMLDivElement>, data: any) => {
    const tabClickHandler = this.props.panes[data.activeIndex].onClick;

    if (tabClickHandler) tabClickHandler();
  };

  render() {
    return (
      <StyledTab
        {...this.props}
        data-testid="tab"
        activeIndex={this.props.activeIndex}
        onTabChange={this.tabChangeHandler}
        menu={{ text: true }}
        panes={this.props.panes}
      />
    );
  }
}

const StyledTab = styled(SemanticTab)`
  .ui.menu {
    font-family: ${props => props.theme.fontFamily};
  }
  .ui.text.menu {
    display: flex;
    justify-content: space-between;
    margin: 0;
    min-height: 0;
    border-bottom: ${props => `${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3}`};

    ${props => `${props.theme.mediaQueries.tabletOnly} {
      justify-content: space-around;
    }`}
  }
  .ui.text.menu .item {
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.light};
    cursor: pointer;
    padding-bottom: ${props => props.theme.pxToRem(13)};
    border-bottom: 2px solid transparent;
    color: ${props => props.theme.colors.neutralGrey8};
    padding-left: 0;
    padding-right: 0;
  }

  .ui.menu:after {
    content: none;
  }

  .ui.text.menu .active.item {
    font-weight: ${props => props.theme.fontWeights.medium};
    border-bottom: 2px solid ${props => props.theme.colors.primaryPurple};
    color: ${props => props.theme.colors.primaryPurple};
  }
  .ui.segment {
    border: none;
    box-shadow: none;
    padding: 0;
    margin-top: ${props => props.theme.pxToRem(22)};
  }

  .ui.text.menu .item:hover {
    border-bottom: 2px solid ${props => props.theme.colors.primaryPurple};
  }
`;
