import React from 'react';
import styled from 'styled-components';
import { OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms/ResponsiveHelpers/ResponsiveHelpers';
import { ReactComponent as TierBenefitIcon } from 'resources/images/tier-benefit.svg';

interface Props {
  title: string;
  description: string;
}

export const TierBenefitCard: React.FC<Props> = ({ title, description }) => {
  return (
    <>
      <OnlyDesktopCSS>
        <StyledCard>
          <LeftAlign>
            <h4>
              <TierBenefitIcon /> <StyledTitle>{title}</StyledTitle>
            </h4>
          </LeftAlign>
          <LeftAlign>
            <StyledDescription>{description}</StyledDescription>
          </LeftAlign>
          <StyledBottom />
        </StyledCard>
      </OnlyDesktopCSS>
      <OnlyMobileCSS>
        <StyledCard>
          <h4>
            <TierBenefitIcon /> <StyledTitle>{title}</StyledTitle>
          </h4>
          <LeftAlign>
            <StyledDescription>{description}</StyledDescription>
          </LeftAlign>
          <StyledBottom />
        </StyledCard>
      </OnlyMobileCSS>
    </>
  );
};

const StyledTitle = styled.span`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const StyledDescription = styled.span`
  text-align: justify;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
`;

const StyledCard = styled.div`
  box-sizing: border-box;
  position: relative;
  border-radius: ${props => props.theme.pxToRem(8)};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);
  min-height: ${props => props.theme.pxToRem(156)};
  padding: ${props => `${props.theme.pxToRem(36)} ${props.theme.pxToRem(18)} 0`};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    height: auto;
    padding: ${props =>
      `${props.theme.pxToRem(26)} ${props.theme.pxToRem(8)} ${props.theme.pxToRem(10)} ${props.theme.pxToRem(8)}`};
  }
`;

const StyledBottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: ${props => `${props.theme.pxToRem(18)}`};
  height: ${props => props.theme.pxToRem(10)};
  border-radius: ${props => `0 0 ${props.theme.pxToRem(8)} ${props.theme.pxToRem(8)}`};
  background-image: linear-gradient(to right, #672d89 3%, rgba(154, 32, 94, 0.8));
  margin: ${props => `0 ${props.theme.pxToRem(-18)} 0`};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    height: ${props => props.theme.pxToRem(10)};
  }
`;

const LeftAlign = styled.p`
  text-align: left;
  font-size: ${props => props.theme.pxToRem(14)};
`;
