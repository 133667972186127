import { theme } from './';

export default `
  html {
    box-sizing: border-box;
    font-size:${theme.htmlFontSize}px !important;
  }
  #root {
    color: ${theme.colors.neutralGrey8};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.m};
    margin: 0;
    line-height: 1.33;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .ui, h1, h2, h3, h4, h5, h6{
        font-family: ${theme.fontFamily};
    }
  }

  #root {
    min-height: 100%;
    height: 1px;
    display: flex;
    flex-direction: column;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  strong {
    font-weight: ${theme.fontWeights.bold};
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    html, body {
      width: 210mm;
      height: auto !important;
    }

    .ui.container {
      width: 700px;
      margin-right: auto!important;
      margin-left: auto!important;
    }

    #root {
      display: block !important;
      height: auto !important;
    }

    body.with-print *:not(.printable) {
      visibility: hidden;
    }
    .printable * {
      visibility: visible !important;
    }
  }
`;
