// ------------------------------------
// Constants
// ------------------------------------

import { Invoices } from 'mxp-schemas';

export enum ClientAdminActionNames {
  INVOICES_GET_INVOICES = 'invoices/GET_INVOICES',
  INVOICES_GET_INVOICE_FILE = 'invoices/GET_INVOICE_FILE',
  INVOICES_SET_DOWNLOADING_ID = 'invoices/SET_DOWNLOADING_ID',
  INVOICES_RESET_MODULE = 'invoices/RESET_MODULE',
  INVOICES_GET_INVOICE_LOADING = 'invoices/GET_INVOICE_LOADING',
  INVOICES_GET_INVOICE = 'invoices/GET_INVOICE',
  INVOICES_SET_INVOICE_CHECKOUT_PAGE = 'invoices/SET_INVOICE_CHECKOUT_PAGE',
  INVOICES_GET_HOSTED_PAGE_SIGNATURE = 'invoices/GET_HOSTED_PAGE_SIGNATURE',
  INVOICES_MAKE_INVOICE_PAYMENT = 'invoices/MAKE_INVOICE_PAYMENT',
  INVOICES_SET_SELECTED_PAYMENT_AMOUNT_OPTION = 'invoices/SET_SELECTED_PAYMENT_AMOUNT_OPTION',
  INVOICES_SET_SELECTED_PAYMENT_AMOUNT_OPTION_CIMA = 'invoices/SET_SELECTED_PAYMENT_AMOUNT_OPTION_CIMA',
  INVOICES_PAY_INVOICE_RESPONSE = 'invoices/PAY_INVOICE_RESPONSE',
  INVOICES_RESET_INVOICE_PAYMENT_CONFIRMATION_DATA = 'invoices/RESET_INVOICE_PAYMENT_CONFIRMATION_DATA',
  INVOICES_GO_TO_INVOICE_CHECKOUT_ERROR_PAGE = 'invoices/GO_TO_INVOICE_CHECKOUT_ERROR_PAGE',
  INVOICES_SET_SELECTED_PAYMENT_OPTION = 'invoices/SET_SELECTED_PAYMENT_OPTION',
  INVOICES_SET_PAGINATION = 'invoices/SET_PAGINATION',
  INVOICES_SET_IS_VALID_AMOUNT_LOADING = 'invoices/SET_IS_VALID_AMOUNT_LOADING',
  INVOICES_GET_INVOICE_PAYMENT_CONFIRMATION_RESULT = 'invoices/GET_INVOICE_PAYMENT_CONFIRMATION_RESULT',
  INVOICES_GET_INVOICE_PAYMENT_CONFIRMATION_RESULT_LOADING = 'invoices/INVOICES_GET_INVOICE_PAYMENT_CONFIRMATION_RESULT_LOADING',
  GET_MULTI_ENTITY_INVOICES = 'invoices/GET_MULTI_ENTITY_INVOICES',
  PAY_INVOICES = 'invoices/PAY_INVOICES',
  RESET_PAY_INVOICES_MODULE = 'invoices/RESET_PAY_INVOICES_MODULE',
  RESET_FULL_INVOICE_DETAILS_MODULE = 'invoices/RESET_FULL_INVOICE_DETAILS_MODULE',
  SEAT_MANAGEMENT_LOADING = 'seatManagement/LOADING',
  SEAT_MANAGEMENT_GET_SERVICE_CONTRACTS = 'seatManagement/GET_SERVICE_CONTRACTS',
  SEAT_MANAGEMENT_ALLOCATE_SEATS = 'seatManagement/ALLOCATE_SEATS',
  SEAT_MANAGEMENT_INVITE_UPDATE = 'seatManagement/SEAT_MANAGEMENT_INVITE_UPDATE',
  ADD_EMAIL_TO_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_LIST = 'seatManagement/ADD_EMAIL_TO_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_LIST',
  REMOVE_EMAIL_FROM_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_LIST = 'seatManagement/REMOVE_EMAIL_FROM_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_LIST',
  SHOW_MANAGEMENT_RECIPIENTS_EMAILS_MODAL = 'seatManagement/SHOW_MANAGEMENT_RECIPIENTS_EMAILS_MODAL',
  HIDE_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_MODAL = 'seatManagement/HIDE_SEAT_MANAGEMENT_RECIPIENTS_EMAILS_MODAL',
  SEAT_MANAGEMENT_ASSIGN_LOADING = 'seatManagement/SEAT_MANAGEMENT_ASSIGN_LOADING',
  SEAT_MANAGEMENT_EMAIL_LIST_RESET_MODULE = 'seatManagement/SEAT_MANAGEMENT_EMAIL_LIST_RESET_MODULE',
  SET_DEALLOCATION_MODAL_VISIBILITY = 'seatManagement/SET_DEALLOCATION_MODAL_VISIBILITY',
  SET_DEALLOCATION_LOADING = 'seatManagement/SET_DEALLOCATION_LOADING',
  SET_SELECTED_SEATS_TO_DEALLOCATE = 'seatManagement/SET_SELECTED_SEATS_TO_DEALLOCATE',
  DEALLOCATE_LINE_ITEMS = 'seatManagement/DEALLOCATE_LINE_ITEMS',
  GET_ORGANIZATIONS = 'firmInformation/GET_ORGANIZATIONS',
  GET_ORGANIZATION_ADMINS_BY_ROLE = 'firmInformation/GET_ORGANIZATION_ADMINS_BY_ROLE',
  SET_ORGANIZATION_ADMINS_LOADING = 'firmInformation/SET_ORGANIZATION_ADMINS_LOADING',
  GET_SEATS_MANAGEMENT_INVITE = 'seatManagement/GET_SEATS_MANAGEMENT_INVITE',
  GET_CLIENT_TUITION_PROVIDERS = 'tuitionProvider/GET_CLIENT_TUITION_PROVIDERS',
  SEAT_MANAGEMENT_INVITE_CANCEL_ALL = 'seatManagement/SEAT_MANAGEMENT_INVITE_CANCEL_ALL',
  EXTEND_PRODUCT_ACCESS = 'seatManagement/EXTEND_PRODUCT_ACCESS',
  EXTEND_PRODUCT_ACCESS_IS_LOADING = 'seatManagement/EXTEND_PRODUCT_ACCESS_IS_LOADING',
  EXTEND_PRODUCT_ACCESS_DETAILS = 'seatManagement/EXTEND_PRODUCT_ACCESS_DETAILS',
  GET_RECENT_EXTENSION_PROCESS_STATUS = 'seatManagement/GET_RECENT_EXTENSION_PROCESS_STATUS',
  TOGGLE_PRODUCT_ACCESS_EXTENSION_MODAL = 'seatManagement/TOGGLE_PRODUCT_ACCESS_EXTENSION_MODAL',
}

export const initialState: State.ClientAdmin = {
  invoices: {
    invoicesList: [],
    invoicesListFetched: false,
    downloadingId: null,
    downloadingError: false,
    error: null,
    payment: {
      fullInvoiceDetails: {
        invoiceDetails: null,
        cimaInvoiceDetails: [],
        isValidAmountCheckLoading: false,
        isValidAmountCheckError: false,
        loading: false,
        error: null,
      },
      payInvoice: {
        loading: false,
        error: null,
        selectedPaymentOption: null,
        selectedPaymentAmountOption: Invoices.PaymentAmountOptions.FULL_PAYMENT,
        invoicePaymentResult: null,
        pageStep: null,
      },
      payInvoices: {
        result: null,
        loading: false,
        error: null,
      },
    },
    sendEmailPrint: {
      result: null,
      loading: false,
    },
  },
  seatManagement: {
    serviceContractsList: {
      list: [],
      loading: false,
      error: null,
    },
    seatManagementEmailInvite: {
      list: [],
      loading: false,
      isInviteUpdate: false,
      error: null,
    },
    seatManagementRecipientsEmailsList: {
      emails: [],
      modalVisible: false,
      loadingAssignment: false,
      error: null,
      assignmentResult: null,
    },
    seatManagementDeallocation: {
      modalVisible: false,
      deallocateLoading: false,
      error: null,
      selectedSeatsToDeallocate: null,
    },
    productAccessExtension: {
      isLoading: false,
      status: '',
      date: '',
      userLength: '',
      productName: '',
      existingEndDate: '',
      ongoingExtensionModalIsOpen: false,
    },
  },
  firmInformation: {
    organizations: {
      list: [],
      isFetched: false,
      error: null,
    },
    organizationAdmins: {
      list: [],
      isFetched: false,
    },
  },
  pagination: {
    [Invoices.InvoiceKeys.DUE_NOW]: {
      offset: 0,
      limit: 50,
      totalCount: 0,
    },
    [Invoices.InvoiceKeys.PAYMENT_HISTORY]: { offset: 0, limit: 50, totalCount: 0 },
  },
};
