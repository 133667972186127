import { connect } from 'react-redux';
import { getLocation } from 'connected-react-router';
import { Dispatch } from 'redux';
import {
  appliedPromoCodeDescriptionSelector,
  appliedPromoCodeSelector,
  isAppliedPromoCodeTargetShippingSelector,
  cartErrorsSelector,
  itemsPriceDataCartSelector,
  priceTotalCartSelector,
  cartShippingInfoSelector,
  isFreeCartSelector,
  hasBasicPhysicalProductInCartSelector,
  isCartFetchedSelector,
  isContributionOnlySelector,
  hasMembershipProductSelector,
  hasSectionProductSelector,
  hasCredentialProductSelector,
  lineItemsSelector,
  getMembershipApplicationTypeSelector,
  hasCimaMembershipBodyProductSelector,
  hasAicpaMembershipBodyProductSelector,
  hasAicpaProtectedProductSelector,
  hasCimaProtectedProductSelector,
  hasCenterMembershipProductSelector,
  hasFcmaCredentialSelector,
  isCartLoadingSelector,
  isPromoCodeChangesAllowedSelector,
  isCredentialActiveAndInCartSelector,
  cartCredentialsSelector,
  flpEntityLabelSelector,
  cartEntityLabelSelector,
  isCartAicpaEntitySelector,
} from 'modules/cart/selectors';
import { addPromoCodeItem, removePromoCodeItem, resetCartError, removeCartItems, updatePONumber } from 'modules/cart';
import { pageStepSelector, membershipRefundSelector, loadingSelector } from 'modules/checkout/selectors';
import { isAuthSelector, personAccountDataSelector } from 'modules/user/selectors';
import { CartSummary } from 'components/molecules/CartSummary';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { isCenterMembershipJourneySelector, membershipInviteDataSelector } from 'modules/membership/selectors';
import { userHasBeenLicensedBeforeSelector } from 'modules/personLevelExemption';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  addPromoCode(promoCode: string): void {
    dispatch(addPromoCodeItem(promoCode, false, true));
  },

  removePromoCode(): void {
    dispatch(removePromoCodeItem());
  },

  resetCartError(): void {
    dispatch(resetCartError());
  },

  removeCartItems(lineItemId: string[]): void {
    dispatch(removeCartItems(lineItemId));
  },
  updatePONumber(poNumber: string): void {
    dispatch(updatePONumber(poNumber));
  },
});

const mapStateToProps = (state: State.Root) => ({
  itemsPriceInfo: itemsPriceDataCartSelector(state),
  totalPriceInfo: priceTotalCartSelector(state),
  cartShippingInfo: cartShippingInfoSelector(state),
  appliedPromoCode: appliedPromoCodeSelector(state),
  isAppliedPromoCodeTargetShipping: isAppliedPromoCodeTargetShippingSelector(state),
  appliedPromoCodeDescription: appliedPromoCodeDescriptionSelector(state),
  cartError: cartErrorsSelector(state),
  isFreeCart: isFreeCartSelector(state),
  pageStep: pageStepSelector(state),
  isAuth: isAuthSelector(state),
  hasBasicPhysicalProductInCart: hasBasicPhysicalProductInCartSelector(state),
  isPageCart: getLocation(state).pathname.includes(getPath(Routes.CART_PAGE)),
  isPageCheckout: getPath(Routes.CHECKOUT_PAGE).includes(getLocation(state).pathname),
  isCartFetched: isCartFetchedSelector(state),
  isContributionOnly: isContributionOnlySelector(state),
  hasMembershipProduct: hasMembershipProductSelector(state),
  hasSectionProduct: hasSectionProductSelector(state),
  hasCredentialProduct: hasCredentialProductSelector(state),
  cartLineItems: lineItemsSelector(state),
  isAdmin: isAdminPortalSelector(state),
  isCenterMembershipJourney: isCenterMembershipJourneySelector(state),
  membershipRefund: membershipRefundSelector(state),
  membershipApplicationType: getMembershipApplicationTypeSelector(state),
  inviteData: membershipInviteDataSelector(state),
  hasCimaMembershipBodyProduct: hasCimaMembershipBodyProductSelector(state),
  hasAicpaMembershipBodyProduct: hasAicpaMembershipBodyProductSelector(state),
  checkoutLoading: loadingSelector(state),
  isCartLoading: isCartLoadingSelector(state),
  hasAicpaProtectedProduct: hasAicpaProtectedProductSelector(state),
  hasCimaProtectedProduct: hasCimaProtectedProductSelector(state),
  hasCenterMembershipProduct: hasCenterMembershipProductSelector(state),
  hasFcmaCredential: hasFcmaCredentialSelector(state),
  personAccountData: personAccountDataSelector(state),
  userHasBeenLicensedBefore: userHasBeenLicensedBeforeSelector(state),
  isPromoCodeChangesAllowed: isPromoCodeChangesAllowedSelector(state),
  cartEntityLabel: cartEntityLabelSelector(state),
  flpEntityLabel: flpEntityLabelSelector(state),
  isCredentialActiveAndInCart: isCredentialActiveAndInCartSelector(state),
  hasCredentialInCart: Boolean(cartCredentialsSelector(state).length),
  isCartAicpaEntity: isCartAicpaEntitySelector(state),
  useNewMembershipAICPA: Boolean(featureTogglesSelector(state).useNewMembershipAICPA),
});

export const CartSummaryContainer = connect(mapStateToProps, mapActionCreators)(CartSummary);
