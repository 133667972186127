import React from 'react';
import styled from 'styled-components';
import { ButtonEnums } from 'components/atoms/Button/Button';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import { ReactComponent as KeyboardArrowRight } from 'resources/images/icon-dev-ic-keyboard-arrow-right.svg';
import { getPath, emptyObject } from 'utils';
import { Routes, UserDropdownOptionLabels } from 'constants/index';
import { useSelector } from 'react-redux';
import { userPlatformSelector } from 'modules/user/selectors';
import { User } from 'mxp-schemas';

interface Props {
  pathName: string;
}

export const PrefNavLinks: React.FC<Props> = ({ pathName }) => {
  const platform = useSelector(userPlatformSelector);
  const links: any = {
    [getPath(Routes.PROFILE_PERSONAL_DETAILS)]: UserDropdownOptionLabels.MyProfile,
    [getPath(Routes.PROFILE_PREFERENCES)]: UserDropdownOptionLabels.MyCommunications,
    [getPath(Routes.PROFILE_PURCHASES)]: UserDropdownOptionLabels.MyPurchases,
    [getPath(Routes.PROFILE_WALLET)]: UserDropdownOptionLabels.MyWallet,
    [getPath(Routes.PROFILE_RECEIPTS)]: UserDropdownOptionLabels.MyReceipts,

    ...(platform !== User.UserPlatform.LEGACY
      ? { [getPath(Routes.PROFILE_CONTACT_PREFERENCES)]: UserDropdownOptionLabels.MyContactPreferences }
      : emptyObject),
  };

  return (
    <nav>
      <StyledList>
        {Object.keys(links).map((key, index) => {
          return (
            <StyledListItem key={index}>
              <StyledButtonLink
                testId={`${index}-pref-nav-link`}
                className={pathName === key ? 'active' : ''}
                variant={ButtonEnums.variants.link}
                size={ButtonEnums.sizes.large}
                icon={<KeyboardArrowRight />}
                iconPosition={ButtonEnums.iconPosition.right}
                to={key}
              >
                {links[key]}
              </StyledButtonLink>
            </StyledListItem>
          );
        })}
      </StyledList>
    </nav>
  );
};

const StyledList = styled.ul`
  padding-left: 0;
  margin: 0;
  list-style: none;
`;

const StyledListItem = styled.li`
  border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};

  :last-child {
    border-bottom-color: transparent;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    width: 100%;
    justify-content: space-between;
    padding-top: ${props => props.theme.pxToRem(20)};
    padding-right: ${props => props.theme.pxToRem(19)};
    padding-bottom: ${props => props.theme.pxToRem(20)};
    border-radius: 0;
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 1.33;

    :hover {
      padding-right: ${props => props.theme.pxToRem(9)};
    }

    &.active {
      padding-right: ${props => props.theme.pxToRem(9)};
      font-weight: ${props => props.theme.fontWeights.medium};
      color: ${props => props.theme.colors.neutralGrey6};

      span {
        text-decoration: underline;
      }
    }
  }
`;
