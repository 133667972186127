import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Dimmer, Loader, Grid, GridRow, GridColumn } from 'semantic-ui-react';
import { ProfileHeading } from 'components/molecules/ProfileHeading/ProfileHeading';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { NotificationBanner, NotificationBannerEnums } from 'components/atoms';
import { ReactComponent as IconPrint } from 'resources/images/ic-print.svg';
import { ReactComponent as IconCheckCircle } from 'resources/images/icon-dev-ic-check-circle.svg';
import { ErrorNotification } from 'components/admin/atoms/ErrorNotification/ErrorNotification';

import {
  adminShippingAddressSelector,
  checkoutLoadingStateSelector,
  invoiceDetailsSelector as invoiceDetailsState,
  organizationAdminsList,
  selectedCurrency,
} from 'modules/admin/selectors';
import { formatPrice } from 'mxp-utils/dist/lib/utils';

interface Item {
  title: string;
  description: string;
}
interface Product {
  title: string;
  quantity?: number | null;
  price: number;
  discount?: number;
  invoice_bundle?: string;
  subscription_start?: string;
  accessible_for?: string;
  occurrence_date?: string;
}

const ProductItem: React.FC<Product> = ({ title, price, discount = 0, ...props }) => {
  const currency = useSelector(selectedCurrency);
  return (
    <StyledRow>
      <GridColumn>
        <ItemTitle>{title}</ItemTitle>
        {Object.entries(props).map(([key, value], index) =>
          key === 'invoice_bundle' ? (
            <div key={index}>
              <CapitalizeKey>{`${key.replace('_', ' ')}`}</CapitalizeKey>
              {`: ${value}`}
            </div>
          ) : (
            value !== '' && (
              <div key={index}>
                <CapitalizeKey>{`${key.replace('_', ' ')}`}</CapitalizeKey>
                {`: ${value}`}
              </div>
            )
          )
        )}
      </GridColumn>
      <GridColumn>
        <ProductItemPriceContainer>
          {discount !== price && (
            <ProductItemPriceDiscounted>{formatPrice(price, currency)}</ProductItemPriceDiscounted>
          )}
          <ProductItemPrice>{formatPrice(discount, currency)}</ProductItemPrice>
        </ProductItemPriceContainer>
      </GridColumn>
    </StyledRow>
  );
};

const Item: React.FC<Item> = ({ title, description }) => {
  return (
    <StyledRow>
      <GridColumn>
        <ItemTitle>{title}</ItemTitle>
        <div>{description}</div>
      </GridColumn>
    </StyledRow>
  );
};

const CapitalizeKey = styled.span`
  text-transform: capitalize;
`;

const getSellingCompany = (sellingCompanyCode: string): string => {
  switch (sellingCompanyCode) {
    case '1001':
      return 'AICPA';
    case '2001':
      return 'CIMA';
    default:
      return 'Association';
  }
};

interface IProduct {
  title: string;
  quantity: number;
  invoice_bundle?: string;
  price: number;
  discount?: number;
  subscription_start?: string;
  access_for?: string;
  occurrence_date?: string;
}

interface IProps {
  showError: string;
  products?: IProduct[];
  total?: number;
}

export const Confirm: React.FC<IProps> = ({ showError, products, total }) => {
  const loading = useSelector(checkoutLoadingStateSelector);
  const currency = useSelector(selectedCurrency);

  const {
    sellingCompanyCode,
    clientAdmin: clientAdminId,
    invoiceDate,
    invoicePresentation,
    poNumber,
    opportunityNumber,
  } = useSelector(invoiceDetailsState);
  const organizationAdmins = useSelector(organizationAdminsList);
  const {
    addressLine1,
    city,
    country: location,
    company: businessName,
    zipCode,
  } = useSelector(adminShippingAddressSelector);

  const clientAdmin = organizationAdmins.find(value => value.id === clientAdminId || value.contactId === clientAdminId);

  const InvoiceDetails: Item[] = [
    { title: 'Invoicing Contact', description: `${clientAdmin?.firstName} ${clientAdmin?.lastName}` },
    { title: 'Order confirmation email address', description: `${clientAdmin?.email}` },
    { title: 'Invoice Date', description: invoiceDate },
    { title: 'Selling Company', description: getSellingCompany(sellingCompanyCode) },
    { title: 'Invoice Presentation', description: invoicePresentation },
    { title: 'PO Number', description: poNumber },
    { title: 'Salesforce Opportunity ID', description: opportunityNumber },
  ];

  const OrganizationDetails: Item[] = [
    { title: 'Business Name', description: `${businessName}` },
    { title: 'Business Address Line 1', description: `${addressLine1}` },
    { title: 'City', description: `${city}` },
    { title: 'Zip/Postal Code', description: `${zipCode}` },
    { title: 'Location', description: location },
  ];

  useEffect(() => {
    document.body.classList.add('with-print');
  });

  const handlePrintOrderSummaryClick = React.useCallback(() => {
    window.print();
  }, []);

  return loading ? (
    <Dimmer active={true} inverted>
      <Loader size="small" inverted>
        Loading
      </Loader>
    </Dimmer>
  ) : (
    <>
      <PreviousPage backText="Back to invoice" />
      <StyledProfileHeading title="Order Summary" />
      <Wrapper>
        {showError && (
          <>
            <ErrorNotification
              label={`Uh oh. Something went wrong and your changes have not been saved. Please try again.`}
            />
            <ErrorNotification
              label={`Timestamp: ${new Date()} ${showError ? `Detailed message: ${showError}` : ``}`}
            />
          </>
        )}
        <StyledNotificationBanner
          variant={NotificationBannerEnums.variant.green}
          testId="notification-banner"
          childrenTestId="notification-children"
          icon={<StyledIconSuccess />}
        >
          Success! Sales tax calculated.
        </StyledNotificationBanner>

        <>
          <PrintContainer>
            <PrintOrderSummaryButton onClick={handlePrintOrderSummaryClick}>
              <StyledPrint />
              <p>Print order summary</p>
            </PrintOrderSummaryButton>
          </PrintContainer>
          <PrintableContentWrapper className="printable">
            <StyledGrid columns={2}>
              <GridRow>
                <ProductHeader>Products</ProductHeader>
                <TotalHeaderContainer>
                  <TotalHeaderPriceText>{formatPrice(total as number, currency)}</TotalHeaderPriceText>
                  <TotalHeaderSubText>including tax</TotalHeaderSubText>
                </TotalHeaderContainer>
              </GridRow>
              {products?.map((product: Product, index) => (
                <ProductItem key={index} {...product} />
              ))}
            </StyledGrid>
            <StyledGrid>
              <StyledRow>
                <ItemHeader>Invoice Details</ItemHeader>
              </StyledRow>
              {InvoiceDetails?.map(({ title, description }: Item, index) => (
                <Item key={index} {...{ title }} {...{ description }} />
              ))}
            </StyledGrid>
            <Grid>
              <StyledRow>
                <ItemHeader>Organization Details</ItemHeader>
              </StyledRow>
              <ItemSubHeader>Shipping Address</ItemSubHeader>
              {OrganizationDetails?.map(({ title, description }: Item, index) => (
                <Item key={index} {...{ title }} {...{ description }} />
              ))}
            </Grid>
          </PrintableContentWrapper>
        </>
      </Wrapper>
    </>
  );
};

const StyledProfileHeading = styled(ProfileHeading)`
  margin-top: 0;
`;

const StyledIconSuccess = styled(IconCheckCircle)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  &&&&& {
    margin-bottom: ${props => props.theme.pxToRem(25)};
    padding-top: ${props => props.theme.pxToRem(21)};
    padding-bottom: ${props => props.theme.pxToRem(21)};
  }
`;

const StyledPrint = styled(IconPrint)`
  padding-left: ${props => props.theme.pxToRem(2)};
  & path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const PrintContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: ${props => props.theme.pxToRem(24)};
  margin-bottom: ${props => props.theme.pxToRem(24)};
  padding-top: ${props => props.theme.pxToRem(16)};
  padding-bottom: ${props => props.theme.pxToRem(16)};
  border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
`;

const PrintOrderSummaryButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledGrid = styled(Grid)`
  border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
`;

const StyledRow = styled(GridRow)`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledColumn = css`
  font-size: ${props => props.theme.fontSizes.l}
  font-family: ${props => props.theme.fontFamily}
  font-weight: ${props => props.theme.fontWeights.light};
`;

const ItemHeader = styled(GridColumn)`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.l};
`;

const ItemSubHeader = styled.p`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.m};
`;

const ProductHeader = styled(GridColumn)`
  ${StyledColumn}
  color: ${props => props.theme.colors.primaryPurple};
`;

const TotalHeaderContainer = styled(GridColumn)`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const TotalHeaderPriceText = styled.div`
  text-align: right;
  font-size: ${props => props.theme.fontSizes.l};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const TotalHeaderSubText = styled.div`
  text-align: right;
  font-size: ${props => props.theme.fontSizes.m};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const ItemTitle = styled.div`
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const ProductItemPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ProductItemPriceStyle = css`
  font-weight: ${props => props.theme.fontWeights.medium};
  text-align: right;
`;

const ProductItemPrice = styled.div`
  ${ProductItemPriceStyle}
`;

const ProductItemPriceDiscounted = styled.div`
  ${ProductItemPriceStyle}
  text-decoration: line-through;
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: ${props => props.theme.fontSizes.xxs};
`;

const Wrapper = styled.div`
  width: 60%;
  padding-bottom: ${props => props.theme.pxToRem(200)};
`;

const PrintableContentWrapper = styled.div`
  @media print {
    position: absolute;
    top: 2rem;
    width: 700px;
    margin-right: auto !important;
    margin-left: auto !important;
  }
`;
