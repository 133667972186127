import { store } from '../../store';
import { AuthHelper } from './base';
import { ConsumerAuthHelper } from './consumer';
import { AppcAuthHelper } from './appc';
import { ExternalAuthHelper } from './external';
import { isAdminPortalSelector } from 'modules/app/selectors';
let authHelper: AuthHelper;

export const getAuthHelper = (recreate?: boolean) => {
  if (authHelper && !recreate) {
    return authHelper;
  }
  if (
    typeof window !== 'undefined' &&
    window.location.pathname.split('=')[1] &&
    !window.location.pathname.split('=')[1].includes('utm_medium') && // added condition so it wont treat other param as token value
    window.location.pathname.includes('token')
  ) {
    localStorage.setItem('externalToken', window.location.pathname.split('=')[1]);
    return new ExternalAuthHelper();
  } else if (!isAdminPortalSelector(store.getState())) {
    // consumer
    return new ConsumerAuthHelper();
  }
  // impersonation
  return new AppcAuthHelper();
};
