import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
import { CustomDivider } from 'components/atoms';
import { RenewalSummaryTableFeeRow } from 'components/atoms/RenewalSummaryTableFeeRow/RenewalSummaryTableFeeRow';
import { RenewalDonationsListRow } from 'components/atoms/RenewalDonationsListRow/RenewalDonationsListRow';
import { RenewalTableCostSummary } from 'components/atoms/RenewalTableCostSummary/RenewalTableCostSummary';
import { RenewalPromoCode } from 'components/atoms/RenewalPromoCode/RenewalPromoCode';
import { TRenewalSummaryTableData } from 'components/pages/PageRenewYourMembership/types/types';
import { RenewButtonsGroup } from 'components/atoms/RenewButtonsGroup/RenewButtonsGroup';

export interface IRenewalSummaryTableProps {
  renewalSummaryTableData: TRenewalSummaryTableData;
  changeToUpdateProcess: () => void;
}

export const RenewalSummaryTable: FC<IRenewalSummaryTableProps> = ({
  renewalSummaryTableData,
  changeToUpdateProcess,
}) => {
  const { fee, costs, donations } = renewalSummaryTableData;
  const feeKeys = Object.keys(fee);
  const feeValues = Object.values(fee);

  return (
    <Container>
      <Header>Renewal Summary</Header>
      <TableContainer>
        <div>
          <BuyingFrom>You are currently purchasing from:</BuyingFrom>
          <SellerName>The Association of International Certified Professional Accountants</SellerName>
        </div>
        <SectionContainer backgroundColor={theme.colors.neutralGrey1}>
          <SectionTitle>Membership Details</SectionTitle>
          <ListContainer>
            {feeValues.map((feeValue, index) => (
              <RenewalSummaryTableFeeRow feeKeys={feeKeys} feeValue={feeValue} index={index} key={feeValue.title} />
            ))}
          </ListContainer>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle>Donations</SectionTitle>
          <ListContainer>
            <DonationsList>
              {donations?.map(donation => (
                <RenewalDonationsListRow title={donation.title} price={donation.price} key={donation.title} />
              ))}
            </DonationsList>
          </ListContainer>
        </SectionContainer>
        <CustomDivider borderColor={theme.colors.lightGrey} borderStyle="dotted" />

        <SectionContainer>
          <RenewalPromoCode />
        </SectionContainer>
        <CustomDivider borderColor={theme.colors.lightGrey} />
        <SectionContainer>
          <RenewalTableCostSummary subtotal={costs.subtotal} tax={costs.tax} total={costs.total} />
        </SectionContainer>
        <RenewButtonsGroupContainer>
          <RenewButtonsGroup changeToUpdateProcess={changeToUpdateProcess} updateButtonText="Cancel" />
        </RenewButtonsGroupContainer>
      </TableContainer>
    </Container>
  );
};

const Container = styled.div`
  max-width: ${props => props.theme.pxToRem(438)};
  width: 37%;
  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    width: 100%;
    max-width: 100%;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    max-width: 100%;
  }
`;

const HeaderMobileStyles = css`
  height: ${props => props.theme.pxToRem(51)};
  font-size: ${props => props.theme.pxToRem(16)};
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  height: ${props => props.theme.pxToRem(66)};
  background-color: ${props => props.theme.colors.primaryPurple};
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.pxToRem(24)};
  padding: ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(21)};
  border-radius: ${props => props.theme.pxToRem(4)};
  text-align: left;
  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    ${HeaderMobileStyles}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    ${HeaderMobileStyles}
  }
`;

const TableContainer = styled.div`
  padding-left: ${props => props.theme.pxToRem(24)};
  padding-right: ${props => props.theme.pxToRem(24)};
  padding-top: ${props => props.theme.pxToRem(26)};
  background-color: ${props => props.theme.colors.neutralWhite};
`;

const BuyingFrom = styled.div`
  color: ${props => props.theme.colors.neutralGrey10};
  font-size: ${props => props.theme.pxToRem(16)};
  margin-bottom: ${props => props.theme.pxToRem(8)};
  letter-spacing: -0.32px;
  text-align: left;
`;

const SellerName = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.medium};
  text-decoration-line: underline;
  margin-bottom: ${props => props.theme.pxToRem(24)};
  text-align: left;
`;

const SectionContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  padding: ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(8)};
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.pxToRem(16)};
  align-self: stretch;
  border-radius: ${props => props.theme.pxToRem(4)};
  background: ${props => (props.backgroundColor ? props.backgroundColor : props.theme.colors.neutralWhite)};
  font-size: ${props => props.theme.pxToRem(16)};
`;

const SectionTitle = styled.div`
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.medium};
  letter-spacing: -0.32px;
  color: ${props => props.theme.colors.primaryPurple};
`;

const ListContainer = styled.div`
  width: 100%;
`;

const DonationsList = styled.ul`
  margin-top: ${props => props.theme.pxToRem(-13)};
  margin-left: ${props => props.theme.pxToRem(-10)};
`;

const RenewButtonsGroupContainer = styled.div`
  display: none;
  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    display: flex;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: flex;
  }
`;
