import React from 'react';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { Content } from 'mxp-schemas';
import { Breadcrumb, BreadcrumbSection } from 'components/atoms/Breadcrumb/Breadcrumb';

interface Props {
  blogTitle: string;
}

export const BlogBreadcrumb: React.FC<Props> = ({ blogTitle }) => {
  const breadcrumbSections: BreadcrumbSection[] = [
    {
      content: 'Home',
    },
    {
      content: Content.BlogContentSource,
      to: getPath(Routes.BLOG_FEED),
    },
    {
      content: blogTitle,
    },
  ];

  return (
    <StyledDiv>
      <Container>
        <StyledBreadcrumbWrapper>
          <Breadcrumb sections={breadcrumbSections} />
        </StyledBreadcrumbWrapper>
      </Container>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  background: ${props => props.theme.colors.neutralGrey1};
`;

const StyledBreadcrumbWrapper = styled.div`
  margin: 0 ${props => props.theme.pxToRem(10)};
  padding: ${props => props.theme.pxToRem(10)} 0;
`;
