import React from 'react';
import styled from 'styled-components';
import { Button, ButtonEnums, ButtonLink } from 'components/atoms';
import { handleEvent, EVENT_CLICK } from 'utils/Analytics';
import { OtherPaymentTypes } from 'constants/index';

interface CreditCardActionProps {
  card: State.CreditCard;
  testId: string;
  isExpired: boolean;
  isImpersonation: boolean;
  paypalUrl: string;

  onSetDefault: (card: State.CreditCard) => void;
  onRemove: (card: State.CreditCard) => void;
}

export const CreditCardActions: React.FC<CreditCardActionProps> = ({
  card,
  testId,
  isExpired = false,
  isImpersonation = false,
  paypalUrl = '/',

  onSetDefault,
  onRemove,
}) => {
  const onSetDefaultCard = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onSetDefault(card);
      const buttonText = (event.target as HTMLAnchorElement).textContent || 'NA';
      return handleEvent({ clickValue: `text:card-actions:int:${buttonText}` }, EVENT_CLICK);
    },
    [card, onSetDefault]
  );

  const onRemoveCard = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onRemove(card);
      const buttonText = (event.target as HTMLAnchorElement).textContent || 'NA';
      return handleEvent({ clickValue: `text:card-actions:int:${buttonText}` }, EVENT_CLICK);
    },
    [card, onRemove]
  );

  return (
    <div>
      {/* show make default if credit card is not expired else other payment methods check if card is default */}
      {!isExpired && !card.defaultPaymentMethod && card.cardType !== OtherPaymentTypes.BANKTRANSFER && (
        <>
          <StyledButton testId={`makeDefault${testId}`} variant={ButtonEnums.variants.link} onClick={onSetDefaultCard}>
            {'Set as primary'}
          </StyledButton>
          {` | `}
        </>
      )}
      {/* Update button is for paypal only */}
      {card.cardType === OtherPaymentTypes.PAYPAL && !isImpersonation && (
        <>
          <StyledButtonLink to={paypalUrl} testId={`updateCard${testId}`} variant={ButtonEnums.variants.link}>
            Update
          </StyledButtonLink>
          {` | `}
        </>
      )}
      <StyledButton testId={`removeCard${testId}`} variant={ButtonEnums.variants.link} onClick={onRemoveCard}>
        {'Remove'}
      </StyledButton>
    </div>
  );
};

const StyledButton = styled(Button)`
  &&&& {
    color: ${props => props.theme.colors.primaryPurple};
    text-decoration: underline;
  }
`;
const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    color: ${props => props.theme.colors.primaryPurple};
    text-decoration: underline;
  }
`;
