import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { getJobRolesHash } from './actions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [getJobRolesHash]: {
    next: (state: State.JobRoles, action: any): State.JobRoles => ({
      ...state,
      jobRolesHash: action.payload,
      jobRolesFetched: true,
    }),

    throw: (state: State.JobRoles, action: any): State.JobRoles => ({
      ...state,
      error: action.payload,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
