import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Bookmark, Bookmarked, Copy } from 'components/atoms/svg';
import { Product, User, Content } from 'mxp-schemas';
import { useToggleSavedItem } from 'hooks/useToggleSavedItem';
import { useProductsPriceCurrency } from 'hooks/useProductsPriceCurrency';
import { CardTags } from 'components/molecules/CardTags/CardTags';
import { ProductThumbnail, ProductThumbSize } from 'components/molecules/ProductThumbnail/ProductThumbnail';
import { ContentLock, ContentLockAlign } from 'components/molecules/ContentLock/ContentLock';
import { ProductDetails } from 'components/molecules/ProductDetails/ProductDetails';
import { User as UserUtils, RichTextBlocks } from 'mxp-utils';
import { ReactComponent as OpenInNew } from 'resources/images/ic-open-in-new.svg';

import {
  Button,
  ButtonEnums,
  CardOutlay,
  CardTitleSubtitle,
  DatePublished,
  ConsumeTime,
  Category,
  CardThumbnail,
  ProductCategory,
  CPECreditsLabel,
  LazyImage,
  Link,
  LinkEnums,
  ContentSource,
} from 'components/atoms';
import { MomentHelpers, getFullContentUrl, getContentUrl, isMobileViewPort } from 'utils';
import { CARD_EVENT, SEARCH_CARD_EVENT, handleEvent } from 'utils/Analytics';
import { ContentTypes } from 'constants/index';

import {
  userMemberTypeSelector,
  isAuthSelector,
  userLocationSelector,
  isUserSuspendedSelector,
} from 'modules/user/selectors';
import { isPageBlogFeedSelector } from 'modules/router/selectors';
import { productCurrencySelector } from 'modules/products/selectors';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';

interface Props {
  contentItem: State.FeedCardContentItem;
  isSearchResult?: boolean;
  searchQuery?: string;
  isBookmarkIconShown?: boolean;
  isContentSaved?: boolean;
  onCopy: (copiedItem: State.CopiedItem) => void;
  userRoles?: User.MembershipIdsEnum[];
  lockAlign?: ContentLockAlign;
  onTagClick?: () => void;
  isPageFeed?: boolean;
}

export const FeedCard: React.FC<Props> = React.memo(
  ({
    contentItem,
    isSearchResult = false,
    searchQuery,
    isContentSaved = false,
    isBookmarkIconShown = true,
    onCopy,
    userRoles,
    lockAlign,
    onTagClick,
    isPageFeed,
  }) => {
    const isMobile = isMobileViewPort();

    const {
      id,
      title,
      description,
      dateCreated,
      slug,
      image,
      externalUrl,
      startDate,
      endDate,
      credits,
      timeToConsume,
      contentType,
      contentfulType,
      topics,
      subtopics,
      industries,
      trends,
      skills,
      subskills,
      contentCategory,
      productType,
      level,
      availableFormat,
      conferenceCity,
      conferenceState,
      contentSource,
      downloads,
      variantsCount,
      firstTopic,
      firstIndustry,
      firstTrend,
      firstSkill,
      roles,
      matchedRoles,
      isLocked,
      prices,
      parentProductInfo,
      hasSectionAccessRestriction,
      originProductType,
      showQuantity,
      bundleInfo,
      fundName,
    } = contentItem;
    const membershipType = useSelector(userMemberTypeSelector);
    const isAuth = useSelector(isAuthSelector);
    const isBlogFeed = useSelector(isPageBlogFeedSelector);

    const currency = useSelector(productCurrencySelector);
    const { country } = useSelector(userLocationSelector);
    const { useSuspendedRestriction } = useSelector(featureTogglesSelector);
    const userEthicsStatus = useSelector(isUserSuspendedSelector);
    const isUserSuspendedByEthics: boolean = Boolean(useSuspendedRestriction && userEthicsStatus);
    const isProduct: boolean = contentType.slug === ContentTypes.PRODUCT;
    const hasTopics: boolean = Boolean(Object.keys(topics || subtopics || {}).length);
    const hasIndustries: boolean = Boolean(Object.keys(industries || {}).length);
    const hasTrends: boolean = Boolean(Object.keys(trends || {}).length);
    const hasSkills: boolean = Boolean(Object.keys(skills || subskills || {}).length);
    const hasTime: boolean = Boolean(!isProduct && (dateCreated || timeToConsume));

    const fileSize: string = downloads && downloads.length ? downloads[0].fileSize : '';
    const showRoles: User.MembershipIdsEnum[] =
      userRoles && roles ? roles.filter((role: User.MembershipIdsEnum) => userRoles.indexOf(role) > -1) : roles;
    const isContribution: boolean = productType?.includes(Product.ProductType.CONTRIBUTION);
    const isAICPAInsightsBlog: boolean = contentSource === Content.BlogContentSource;

    const isAICPAInsightsBlogContent: boolean = contentSource === Content.BlogContentSource;
    const cardSubtitleLinesCount = isAICPAInsightsBlogContent ? (isMobile ? 6 : 4) : undefined;

    const allTags = [
      ...(topics || []),
      ...(subtopics || []),
      ...(industries || []),
      ...(skills || []),
      ...(trends || []),
      ...(subskills || []),
    ];

    const [seeMoreTags] = useState(allTags.length > 10);

    const handleToggleSavedItemClick = useToggleSavedItem({
      id,
      isSaved: isContentSaved,
      contentSource,
      title,
      description,
      slug,
      externalUrl,
      isBlogFeed,
    });

    const { priceRange, currencyLabel, currencySign } = useProductsPriceCurrency(prices, currency.label, country);

    const consumptionTime: string = React.useMemo(
      (): string =>
        MomentHelpers.getTimeToConsumeOrSize({
          time: timeToConsume,
          category: contentfulType,
          downloads,
        }),
      [timeToConsume, contentfulType, downloads]
    );

    const thumbnailConsumptionTime: string = React.useMemo(
      (): string => (timeToConsume ? MomentHelpers.getSecondsToMinutes(timeToConsume) : ''),
      [timeToConsume]
    );

    const formattedCPE: string | null = React.useMemo((): string | null => {
      const cpeCreditSize: number = credits?.length;

      if (!credits || !cpeCreditSize || !credits[0]) {
        return null;
      }
      return cpeCreditSize > 1 ? `${credits[0]} - ${credits[cpeCreditSize - 1]}` : credits[0].toString();
    }, [credits]);

    const handleLinkClick = React.useCallback((): void => {
      const card: string = `card:${title}:${slug || externalUrl}:${id}`;
      const url: string = window.location.href;
      const eventType: string = isSearchResult ? SEARCH_CARD_EVENT : CARD_EVENT;
      handleEvent({ card, description, url }, eventType);
    }, [title, slug, externalUrl, id, description, isSearchResult]);

    const isExternal = RichTextBlocks.isExternal(externalUrl);

    // Dynamically remove image behind Category area to make it transparent
    const [imgClip, setImgClip] = useState('');

    const headerRef: any = React.useRef();
    const categoryRef: any = React.useRef();

    const handleClipResize = () => {
      if (categoryRef.current && headerRef.current) {
        const imgH = headerRef.current.getBoundingClientRect().height || 0;
        const imgW = headerRef.current.getBoundingClientRect().width || 0;
        const catH = categoryRef.current.getBoundingClientRect().height || 0;
        const catW = categoryRef.current.getBoundingClientRect().width || 0;
        setImgClip(
          `polygon(0 0, ${imgW}px 0, ${imgW}px ${imgH}px, ${catW}px ${imgH}px, ${catW}px ${imgH - catH}px, 0 ${
            imgH - catH
          }px)`
        );
      }
    };

    React.useEffect(() => {
      handleClipResize();
      window.addEventListener('resize', handleClipResize);
      return () => window.removeEventListener('resize', handleClipResize);
    }, []);

    const renderTopBlock = (): React.ReactNode => {
      return isProduct && image ? (
        <ProductThumbnail
          coverSrc={`${image.url}?w=280&h=400&fit=thumb`}
          imageRatio="143%"
          title={title || ''}
          productType={
            isContribution
              ? (productType?.[0] as Product.ProductType | undefined)
              : (originProductType?.[0] as Product.ProductType | undefined)
          }
          size={ProductThumbSize.SMALL}
          subtitle={firstTopic || firstIndustry || firstSkill || firstTrend || ''}
        />
      ) : (
        <StyledHeader ref={headerRef}>
          {image && !isSearchResult && (
            <StyledLazyImage
              src={`${image.url}&w=1230&h=280&fit=thumb`}
              alt="card-image"
              imageRatio="22.76%"
              clipPath={imgClip}
            />
          )}
          {contentCategory && (
            <Category
              categoryName={contentCategory.name}
              categorySlug={contentCategory.slug}
              thumbnail
              isSearchResult={isSearchResult}
              isProduct={isProduct}
              categoryRef={categoryRef}
            />
          )}
        </StyledHeader>
      );
    };

    const productLabel = React.useMemo(() => {
      const { SUBSCRIPTION, PUBLICATION, COURSE, CPE_DIRECT, MAGAZINE_SUBSCRIPTION } = Product.ProductType;
      const isSubscription: boolean = originProductType?.includes(SUBSCRIPTION);

      if (isSubscription && productType.includes(PUBLICATION)) {
        return [showQuantity ? MAGAZINE_SUBSCRIPTION : PUBLICATION];
      }
      if (isSubscription && productType.includes(COURSE)) {
        return [showQuantity ? CPE_DIRECT : COURSE];
      }
      if (isPageFeed && isContribution) {
        return productType;
      }

      return originProductType;
    }, [originProductType, productType, showQuantity, isPageFeed, isContribution]);

    const renderTextBlock = (): React.ReactNode => (
      <>
        {Boolean(isProduct && (originProductType?.length || formattedCPE || (isPageFeed && isContribution))) && (
          <StyledProductTop>
            {((isPageFeed && isContribution) || originProductType) && <ProductCategory category={productLabel} />}
            {formattedCPE && <CPECreditsLabel credits={formattedCPE} />}
          </StyledProductTop>
        )}

        <CardTitleSubtitle
          testId="card-title-subtitle"
          title={title}
          subtitle={description}
          subtitleLinesCount={cardSubtitleLinesCount}
          searchString={searchQuery}
          contentType={contentfulType}
          isSearchResult={isSearchResult}
          fundName={fundName}
          isContribution={isContribution}
        />
        {isProduct && (
          <ProductDetails
            currencySign={currencySign}
            currencyLabel={currencyLabel}
            studyLevel={level}
            startDate={startDate}
            endDate={endDate}
            fileFormat={availableFormat}
            priceRange={priceRange}
            conferenceCity={conferenceCity}
            conferenceState={conferenceState}
            variantsCount={variantsCount}
            parentProductSessions={parentProductInfo?.sessions}
            upcomingParentProductDates={parentProductInfo?.upcomingDates}
            bundleInfo={bundleInfo}
            isBundleCard={!!bundleInfo}
            isAuth={isAuth}
            userMemberType={membershipType}
            isSearchResult={isSearchResult}
            isContribution={isContribution}
            isUserSuspendedByEthics={isUserSuspendedByEthics}
          />
        )}
      </>
    );

    const renderLinkedBlock = (children: React.ReactNode): React.ReactNode => {
      return externalUrl ? (
        <Link
          type={LinkEnums.type.standaloneLink}
          isExternal={isExternal}
          to={externalUrl}
          testId={`feed-card-${contentType.slug}-${id}`}
          onClick={handleLinkClick}
          aria-label={title}
        >
          {children}
        </Link>
      ) : (
        contentCategory && (
          <Link
            type={LinkEnums.type.standaloneLink}
            underline={LinkEnums.underline.noUnderlineOnHover}
            to={getContentUrl(contentItem)}
            testId={`internal-link-feed-card-${contentType}-${id}`}
            onClick={handleLinkClick}
            aria-label={title}
          >
            {children}
          </Link>
        )
      );
    };

    const handleCopy = React.useCallback((): void => {
      const url = getFullContentUrl(contentItem);

      onCopy({ id, copiedText: url, notificationText: title });
    }, [contentItem, id, title, onCopy]);

    return (
      <StyledCardOutlay type={contentType.slug} isProduct={isProduct} isSearchResult={isSearchResult}>
        {!isProduct && (
          <ContentLock
            isLocked={isLocked}
            testId="feed-card"
            align={lockAlign}
            matchedRoles={matchedRoles}
            contentRoles={roles}
            userStatus={membershipType}
            restrictionDetails={contentItem.restrictionDetails}
          />
        )}
        {renderLinkedBlock(renderTopBlock())}
        <StyledBody isProduct={isProduct} isSearchResult={isSearchResult}>
          {renderLinkedBlock(renderTextBlock())}
          {(hasTopics || hasIndustries || hasSkills || hasTrends) && !isContribution && (
            <CardTags
              topics={topics && [...topics]}
              subtopics={subtopics && [...subtopics]}
              industries={industries && [...industries]}
              trends={trends && [...trends]}
              skills={skills && [...skills]}
              subskills={subskills && [...subskills]}
              roles={
                hasSectionAccessRestriction
                  ? showRoles.filter((role: User.MembershipIdsEnum) => !UserUtils.sectionMembershipRoles.includes(role))
                  : showRoles
              }
              seeMoreTags={seeMoreTags}
              onClick={onTagClick}
            />
          )}
          <StyledBottom hasTime={hasTime} isExternal={!!(externalUrl && contentSource)}>
            {hasTime && (
              <StyledDateTimeDetails isOnSeparateLine={!!(externalUrl && contentSource)}>
                {dateCreated && <DatePublished date={dateCreated} />}
                {consumptionTime && <ConsumeTime time={consumptionTime} />}
                {isAICPAInsightsBlog && <ContentSource contentSource={contentSource} hasDotSeparator />}
                {contentfulType && !isSearchResult && (thumbnailConsumptionTime || fileSize) && (
                  <CardThumbnail
                    timeToConsume={thumbnailConsumptionTime}
                    contentType={contentfulType}
                    fileSize={fileSize}
                    isBody
                  />
                )}
              </StyledDateTimeDetails>
            )}
            {externalUrl && contentSource && (
              <StyledLink isExternal={isExternal} to={externalUrl} onClick={handleLinkClick}>
                {isExternal && <StyledIcon data-testid={`icon-${externalUrl}`} />}
                {contentSource}
              </StyledLink>
            )}
            <ButtonWrapper>
              <CopyButton
                testId={`copy-button-${id}`}
                aria-label={`Copy ${title}`}
                onClick={handleCopy}
                variant={ButtonEnums.variants.iconWithCircle}
              >
                <OffScreenSpan>{title}</OffScreenSpan>
                <Copy />
              </CopyButton>
              {!isProduct && isBookmarkIconShown && (
                <Button
                  testId={`bookmark-button-${id}`}
                  aria-label={`Bookmark ${title}`}
                  onClick={handleToggleSavedItemClick}
                  active={isContentSaved}
                  variant={ButtonEnums.variants.iconWithCircle}
                >
                  <OffScreenSpan>{title}</OffScreenSpan>
                  {isContentSaved ? <Bookmarked /> : <Bookmark />}
                </Button>
              )}
            </ButtonWrapper>
          </StyledBottom>
        </StyledBody>
      </StyledCardOutlay>
    );
  }
);

const StyledCardOutlay = styled(CardOutlay)<{ isSearchResult: boolean; isProduct: boolean }>`
  ${props =>
    !props.isSearchResult &&
    !props.isProduct &&
    `
      &:hover {
        outline: 1px solid ${props.theme.colors.primaryPurple};
      }
    `}

  ${props => `
    &:hover div[data-testid="card-title-subtitle"] {
      color: ${props.theme.colors.primaryPurple};
    }
  `}
`;

const OffScreenSpan = styled.span`
  border: 0 !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`;

const StyledHeader = styled.div`
  position: relative;
  min-height: ${props => props.theme.pxToRem(30)};
`;

const StyledBody = styled.div<{ isProduct: boolean; isSearchResult: boolean }>`
  ${props =>
    props.isProduct
      ? css`
          width: 100%;
          padding: 0 ${props.theme.pxToRem(20)};
          ${props.theme.mediaQueries.mobileOnly} {
            padding: 0;
          }
        `
      : css`
          padding: ${props.theme.pxToRem(20)} ${props.isSearchResult ? '0' : ''};
        `}
`;

const StyledProductTop = styled.div`
  display: flex;
  justify-content: space-between;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(25)};
  }
`;

const StyledBottom = styled.div<{ hasTime: boolean; isExternal: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => (props.hasTime || props.isExternal ? 'space-between' : 'flex-end')};
  align-items: center;
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const StyledDateTimeDetails = styled.div<{ isOnSeparateLine: boolean }>`
  ${props => props.isOnSeparateLine && 'width: 100%;'}
`;

const ButtonWrapper = styled.div`
  &&&& {
    display: flex;

    button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const CopyButton = styled(Button)`
  &&&& {
    margin-right: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledLazyImage = styled(LazyImage)<{ clipPath: string }>`
  ${props => props.clipPath && `clip-path: ${props.clipPath};`}
`;
const StyledIcon = styled(OpenInNew)`
  vertical-align: bottom;
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: inline-block;
  margin-right: ${props => props.theme.pxToRem(5)};
  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;
const StyledLink = styled(Link)`
  line-height: 16px;
  font-size: ${props => props.theme.fontSizes.xxs};
  margin-bottom: ${props => props.theme.pxToRem(15)};
`;
