import React, { FC } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import { CustomDivider } from 'components/atoms';

export interface IRenewalTableCostSummaryProps {
  subtotal: number;
  tax: number;
  total: number;
}
export const RenewalTableCostSummary: FC<IRenewalTableCostSummaryProps> = ({ subtotal, tax, total }) => (
  <>
    <TotalContainer>
      <TotalPosition fontSize={16}>Subtotal:</TotalPosition>
      <TotalPosition fontSize={20}>${subtotal}</TotalPosition>
    </TotalContainer>
    <TotalContainer>
      <TotalPosition fontSize={16}>Sales Tax:</TotalPosition>
      <TotalPosition fontSize={20}>${tax}</TotalPosition>
    </TotalContainer>
    <CustomDivider borderColor={theme.colors.neutralGrey9} />
    <TotalContainer>
      <TotalPosition fontSize={16} fontWeight={theme.fontWeights.medium} fontColor={theme.colors.neutralGrey8}>
        Total cost
      </TotalPosition>
      <TotalPosition fontSize={24} fontWeight={theme.fontWeights.medium} fontColor={theme.colors.primaryPurple}>
        ${total}
      </TotalPosition>
    </TotalContainer>
  </>
);

const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &:last-child {
    margin-top: ${props => props.theme.pxToRem(10)};
  }
`;

const TotalPosition = styled.div<{ fontSize?: number; fontColor?: string; fontWeight?: number }>`
  font-size: ${props => (props.fontSize ? props.theme.pxToRem(props.fontSize) : props.theme.pxToRem(14))};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
  color: ${props => (props.fontColor ? props.fontColor : props.theme.colors.neutralGrey10)};
  letter-spacing: -0.32px;
`;
