import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CardTag } from 'components/atoms/CardTag/CardTag';
import { User } from 'mxp-schemas';

interface CardTagProps {
  topics?: any;
  subtopics?: any;
  industries?: any;
  trends?: any;
  skills?: any;
  subskills?: any;
  seeMoreTags?: boolean;
  roles?: User.MembershipIdsEnum[];
  onClick?: () => void;
}

export const CardTags: React.FC<CardTagProps> = React.memo(
  ({ topics, subtopics, industries, trends, skills, subskills, roles, seeMoreTags, onClick }) => {
    const [showAllTags, setShowAllTags] = useState(true);

    const handleSeeMoreOnClick = () => {
      setShowAllTags(!showAllTags);
    };

    const sortByObjectName = (a: State.ContentTopic, b: State.ContentTopic) => {
      if (a.name > b.name) {
        return 1;
      }
      if (b.name > a.name) {
        return -1;
      }
      return 0;
    };
    const sortTags = (arr: any[] | undefined, tagType: string) => {
      if (!arr || !Array.isArray(arr)) {
        return null;
      }
      arr.sort(sortByObjectName);
      return arr.map(taxonomy => {
        return <CardTag key={taxonomy.id} item={taxonomy} tagType={tagType} />;
      });
    };

    const tagArray = [
      sortTags(topics, 'topic'),
      sortTags(subtopics, 'topic'),
      sortTags(industries, 'industry'),
      sortTags(trends, 'trend'),
      sortTags(skills, 'skill'),
      sortTags(subskills, 'skill'),
    ].flat();

    const [displayTags, setDisplayTags] = useState(tagArray);

    useEffect(() => {
      setDisplayTags(showAllTags ? tagArray : tagArray.slice(0, 10));
    }, [showAllTags]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <TagContainer>
        {displayTags}
        {seeMoreTags && (
          <SeeMoreTagsButton onClick={handleSeeMoreOnClick}>
            {!showAllTags ? '+ See more...' : '- See less...'}
          </SeeMoreTagsButton>
        )}
      </TagContainer>
    );
  }
);

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const SeeMoreTagsButton = styled.button`
  overflow: hidden;
  height: ${props => props.theme.pxToRem(20)};
  padding: 0 ${props => props.theme.pxToRem(8)};
  border-radius: ${props => props.theme.pxToRem(10)};
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryPurple};
  margin-right: ${props => props.theme.pxToRem(4)};
  margin-bottom: ${props => props.theme.pxToRem(4)};
  background-color: ${props => props.theme.colors.neutralGrey2};
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: ${props => props.theme.pxToRem(20)};
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.primaryPurple};
    color: ${props => props.theme.colors.neutralWhite};
  }
`;
