import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import {
  userLoadingSelector,
  getStudentExamSectionResultsSelector,
  studentProgressionSelector,
  learningPathwaySelector,
} from 'modules/user/selectors';
import { CimaExamResults } from 'components/pages/PageCimaExamResults';
import { getStudentExamSectionResultsData, resetStudentExamSectionResults } from 'modules/user/actions';
import { PearsonVue } from 'mxp-schemas';

const mapActionCreators = (dispatch: Dispatch) => ({
  getStudentExamSectionResults(slug: string): void {
    dispatch(getStudentExamSectionResultsData(slug))
      .then((response: any) => {
        if (
          !(
            response.payload.getStudentExamSectionResults.ratificationStatus ===
            PearsonVue.ExamResultRatificationStatus.ratified
          )
        ) {
          dispatch(push(getPath(Routes.NOT_FOUND)));
        }
      })
      .catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },

  resetStudentExamSectionResults(): void {
    dispatch(resetStudentExamSectionResults());
  },
});

const mapStateToProps = (state: State.Root) => ({
  loading: userLoadingSelector(state),
  studentExamResults: getStudentExamSectionResultsSelector(state),
  studentProgression: studentProgressionSelector(state),
  pathway: learningPathwaySelector(state),
});

export const PageCimaExamResultsContainer = connect(mapStateToProps, mapActionCreators)(CimaExamResults);
