import React from 'react';
import styled from 'styled-components';
import {
  ExternalLinkStyles,
  ExternalLinkStylesDark,
  DefaultInlineLinkStyles,
  InlineLinkOnDarkBgStyles,
} from 'components/atoms/Link/Link';
import { Scroller } from 'utils';
import { Heading, Grid, ButtonLink, ButtonEnums } from 'components/atoms';
import { SubBlockWelcome } from './SubBlockWelcome';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful } from 'mxp-schemas';
import { ReactComponent as ChevronsDown } from '../../../resources/images/chevrons-down.svg';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';
import { staticLandingPageSelector } from 'modules/staticLandingPagesContent/selectors';
import { useSelector } from 'react-redux';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}

export const BlockWelcome: React.FC<Props> = ({ modularBlock, headingType }) => {
  const block = modularBlock as Contentful.ModularContent.WelcomeBlock;
  const isBgColorDark: boolean = isColorDark(block.bgColor);
  const navigate = (value: any) => (event: React.MouseEvent) => {
    if (value.startsWith('#')) {
      event.preventDefault();
      Scroller.scrollIntoElement(value);
    }
  };
  const staticLanding = useSelector(state => staticLandingPageSelector(state as State.Root));
  return (
    <>
      <MainColumn isBgColorDark={isBgColorDark}>
        {block.header && staticLanding.contentType !== 'staticLandingPage' && (
          <StyledHeading
            as={headingType}
            dangerouslySetInnerHTML={{ __html: block.header }}
            isBgColorDark={isBgColorDark}
            tabIndex={0}
          />
        )}
        {block.description && staticLanding.contentType !== 'staticLandingPage' && (
          <StyledParagraph
            dangerouslySetInnerHTML={{ __html: block.description }}
            isBgColorDark={isBgColorDark}
            tabIndex={0}
          />
        )}
        {block.ctaText && block.ctaUrl && staticLanding.contentType !== 'staticLandingPage' && (
          <StyledButtonWrapper>
            <StyledButtonLink
              testId="button-welcome-block"
              to={block.ctaUrl}
              buttonText={block.ctaText}
              onClick={navigate(block.ctaUrl)}
              variant={ButtonEnums.variants.secondary}
              size={ButtonEnums.sizes.large}
              icon={block.ctaUrl.startsWith('#') ? <ChevronsDown /> : null}
              iconPosition={ButtonEnums.iconPosition.right}
            >
              <StyledCtaText>{block.ctaText}</StyledCtaText>
            </StyledButtonLink>
          </StyledButtonWrapper>
        )}
        {block.header && staticLanding.contentType === 'staticLandingPage' && (
          <StyledHeadingStatic
            as={headingType}
            dangerouslySetInnerHTML={{ __html: block.header }}
            isBgColorDark={isBgColorDark}
            tabIndex={0}
          />
        )}
        {block.description && staticLanding.contentType === 'staticLandingPage' && (
          <StyledParagraphStatic
            dangerouslySetInnerHTML={{ __html: block.description }}
            isBgColorDark={isBgColorDark}
            tabIndex={0}
          />
        )}
        {block.ctaText && block.ctaUrl && staticLanding.contentType === 'staticLandingPage' && (
          <StyledButtonWrapperStatic>
            <StyledButtonLink
              testId="button-welcome-block"
              buttonText={block.ctaText}
              to={block.ctaUrl}
              onClick={navigate(block.ctaUrl)}
              variant={ButtonEnums.variants.secondary}
              size={ButtonEnums.sizes.large}
              icon={block.ctaUrl.startsWith('#') ? <ChevronsDown /> : null}
              iconPosition={ButtonEnums.iconPosition.right}
            >
              <StyledCtaText>{block.ctaText}</StyledCtaText>
            </StyledButtonLink>
          </StyledButtonWrapperStatic>
        )}

        {Boolean(block.landingPageSubBlocks?.length) && (
          <StyledGrid columns="3">
            {block.landingPageSubBlocks?.map((item: Contentful.LandingPages.LandingPageSubBlock) => (
              <Grid.Column key={item.id}>
                <SubBlockWelcome
                  testId={`wsb-${item.id}`}
                  title={item.header}
                  description={item.description}
                  imageUrl={item.image}
                  colour={item.colourway}
                  to={item.ctaUrl}
                  ctaText={item.ctaText}
                />
              </Grid.Column>
            ))}
          </StyledGrid>
        )}
      </MainColumn>
    </>
  );
};
const MainColumn = styled.div<{
  isBgColorDark: boolean;
}>`
  .rich-text-external-link {
    ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${props => (props.isBgColorDark ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.35;
  margin: 0;

  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }

  p {
    line-height: ${props => props.theme.pxToRem(44)};
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: ${props => props.theme.pxToRem(32)};
  margin-top: ${props => props.theme.pxToRem(24)};
  text-align: left;
  width: 50%;

  p {
    line-height: ${props => props.theme.pxToRem(32)};
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    line-height: ${props => props.theme.pxToRem(22)}
    font-size: ${props => props.theme.fontSizes.s};
    text-align: left;
    p{
      line-height: ${props => props.theme.pxToRem(25)};
    }
  }
`;

const StyledGrid = styled(Grid)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(70)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(40)};
    }
  }
`;

const StyledButtonWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(30)};
  margin-bottom: ${props => props.theme.pxToRem(150)}
  text-align: left;

  > a {
    width: ${props => props.theme.pxToRem(340)};
    height: ${props => props.theme.pxToRem(48)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    > a {
      width: auto;
    }
    text-align: left;
    margin-bottom: ${props => props.theme.pxToRem(0)}
  }
`;

const StyledCtaText = styled.div`
  /* ${props => props.theme.mediaQueries.mobileOnly} { */
  margin-right: ${props => props.theme.pxToRem(20)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.bold};
  /* } */
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    ${props => props.theme.mediaQueries.computerMin} {
      &:focus {
        background-color: ${props => props.theme.colors.primaryLightPurple};
        color: ${props => props.theme.colors.neutralWhite};
        border-color: transparent;
      }
    }
  }
`;

const StyledHeadingStatic = styled(Heading)<{ isBgColorDark: boolean }>`
  position: relative;
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 2.75;
  width: 100%;
  margin-bottom: ${props => props.theme.pxToRem(20)} !important;
  z-index: 1;

  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }

  p {
    line-height: ${props => props.theme.pxToRem(44)};
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    line-height: ${props => props.theme.pxToRem(44)};
    p {
      line-height: ${props => props.theme.pxToRem(44)};
    }
  }
`;

const StyledParagraphStatic = styled.div<{ isBgColorDark: boolean }>`
  color: ${props => props.theme.colors.neutralWhite};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: ${props => props.theme.pxToRem(22)};
  margin-top: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.s};
  text-align: left;
  position: relative;
  z-index: 1;
  width: 50%;

  p {
    line-height: ${props => props.theme.pxToRem(22)};
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    line-height: ${props => props.theme.pxToRem(22)};
    text-align: left;
    p {
      line-height: ${props => props.theme.pxToRem(22)};
    }
  }
`;

const StyledButtonWrapperStatic = styled.div`
  margin-top: ${props => props.theme.pxToRem(30)};
  margin-bottom: ${props => props.theme.pxToRem(10)}
  position: relative;
  z-index: 1;
  text-align: left;

  > a {
    width: ${props => props.theme.pxToRem(340)};
    height: ${props => props.theme.pxToRem(48)};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    > a {
      width: auto;
    }
    text-align: left;
    margin-bottom: ${props => props.theme.pxToRem(0)}
  }
`;
