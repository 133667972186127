import { AicpaLogo, OnlyDesktopCSS, OnlyMobileCSS, StepperBar } from 'components/atoms';
import { Routes, MembershipApplicationStep } from '../../../constants';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getPath } from 'utils';
import { MiniUserWidgetContainer } from 'containers/UserWidgetContainer';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import { StepperProps } from 'components/atoms/StepperBar/StepperBar';

interface Props {
  passedSteps: MembershipApplicationStep[];
}
const StyledAicpaLogo = styled(AicpaLogo)`
  cursor: pointer;
`;

const StyledContainer = styled(Container)`
  &&&& {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledMobileContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledMobileTop = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(14)};
    margin-bottom: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledMobileBottom = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.neutralGrey1};
  padding: ${props => props.theme.pxToRem(8)} 0;
  margin: 0 ${props => props.theme.pxToRem(-22.5)};
  width: 100%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(8)};
  }
`;

export const FcmaApplicationJourneyHeader: React.FC<Props> = ({ passedSteps }) => {
  const { pathname: currPath } = useLocation();
  const history = useHistory();

  const handleLogoClick = () => {
    history.push(getPath(Routes.ROOT));
  };

  const steps: StepperProps[] = useMemo(
    () =>
      passedSteps
        .filter(({ name }) => name !== 'Start')
        .map(({ name, pathName }, i) => {
          const path = getPath(pathName);
          const currPageIdx = passedSteps.findIndex(el => getPath(el.pathName) === currPath);

          return {
            title: `${i + 1}. ${name}`,
            isActive: currPath === path,
            isSuccess: i < currPageIdx,
          };
        }) || [],
    [currPath, passedSteps]
  );

  return (
    <>
      <OnlyDesktopCSS>
        <StyledContainer fluid>
          <StyledAicpaLogo onClick={handleLogoClick} />
          <StepperBar steps={steps} />
          <MiniUserWidgetContainer showLogoutLink />
        </StyledContainer>
      </OnlyDesktopCSS>

      <OnlyMobileCSS>
        <StyledMobileContainer>
          <StyledMobileTop>
            <StyledAicpaLogo onClick={handleLogoClick} />
            <MiniUserWidgetContainer showLogoutLink />
          </StyledMobileTop>

          <StyledMobileBottom>
            <StepperBar steps={steps} />
          </StyledMobileBottom>
        </StyledMobileContainer>
      </OnlyMobileCSS>
    </>
  );
};
