import React from 'react';
interface Props {
  size?: number;
  color?: string;
  className?: string;
}

export const Info = React.memo<Props>(({ size = 24, color = '#72246c', className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" className={className}>
    <path fillRule="evenodd" fill={color} d="M11 17L13 17 13 11 11 11zM11 9L13 9 13 7 11 7z" />
  </svg>
));
