import React from 'react';
import Helmet from 'react-helmet';
import { Routes, PageTitleValues } from 'constants/index';
import { RouteComponentProps } from 'react-router-dom';
import { getPath, getRouterRoute, getPageTitles } from 'utils';
import { Content } from 'mxp-schemas';

interface Props extends RouteComponentProps {
  isLowerEnvironment: boolean;
  version: string | null;
  contentItemTitle: string;
  contentItemCategory: string;
  productItemTitle: string;
  productFormatLabel: string;
  staticLandingPageTitle: string;
  staticLandingPageCategory: string;
  useCimaFeatures: boolean;
}

export const PageTitle: React.FC<Props> = React.memo(props => {
  const {
    version,
    location,
    contentItemTitle,
    contentItemCategory,
    productItemTitle,
    staticLandingPageTitle,
    staticLandingPageCategory,
    isLowerEnvironment,
    productFormatLabel,
    useCimaFeatures,
  } = props;

  const matchingPath: any = getRouterRoute(location.pathname);

  const getTitleValues = (): PageTitleValues => {
    if (!matchingPath) {
      return null;
    }
    switch (matchingPath.path) {
      case getPath(Routes.SEARCH):
        return matchingPath.params;
      case getPath(Routes.PRIVACY_POLICY):
      case getPath(Routes.TERMS_CONDITIONS_SUBSCRIPTIONS):
      case getPath(Routes.TERMS_CONDITIONS_STANDING_ORDERS):
      case getPath(Routes.WEBCAST_DETAILS):
      case getPath(Routes.PUBLICATION_DETAILS):
      case getPath(Routes.CONFERENCE_DETAILS):
      case getPath(Routes.EXAM_DETAILS):
      case getPath(Routes.COURSE_DETAILS):
      case getPath(Routes.SUBSCRIPTION_DETAILS):
      case getPath(Routes.WHAT_TO_EXPECT_FROM_SUBSCRIPTIONS):
      case getPath(Routes.REFUND_POLICY):
      case getPath(Routes.CONFERENCE_SYSTEM_REQUIREMENTS):
      case getPath(Routes.CONFERENCE_CANCELLATION_POLICY):
      case getPath(Routes.DIGITAL_BADGES):
      case getPath(Routes.STANDING_ORDERS):
      case getPath(Routes.SHIPPING_DELIVERY):
      case getPath(Routes.VERGIC_CALENDAR):
        return {
          ...matchingPath.params,
          title: contentItemTitle,
        };
      case getPath(Routes.CONTENT_PAGE_OLD):
        return {
          ...matchingPath.params,
          ...(matchingPath.params?.category === Content.CategorySlugs.HELP && { title: contentItemTitle }),
        };
      case getPath(Routes.CONTENT_PAGE):
        return {
          ...matchingPath.params,
          title: contentItemTitle,
          category: contentItemCategory,
        };
      case getPath(Routes.PRODUCT_PAGE):
      case getPath(Routes.PRODUCT_PAGE_OLD):
      case getPath(Routes.DONATION_PAGE):
        return {
          ...matchingPath.params,
          title: productItemTitle,
          format: productFormatLabel,
        };
      case getPath(Routes.STATIC_LANDING_PAGES):
        return {
          ...matchingPath.params,
          title: staticLandingPageTitle,
          category: staticLandingPageCategory,
        };
      case getPath(Routes.FIRM_ROOT):
      case getPath(Routes.FIRM_INFO):
      case getPath(Routes.FIRM_INFO_TAB_BASIC_COMPANY_DETAILS):
      case getPath(Routes.FIRM_ROSTER):
      case getPath(Routes.FIRM_ROSTER_TAB_MANAGE):
      case getPath(Routes.FIRM_ROSTER_TAB_MEMBERS):
      case getPath(Routes.FIRM_ROSTER_TAB_SUPPORT):
      case getPath(Routes.FIRM_ROSTER_TAB_FLP_SUBSCRIPTIONS):
      case getPath(Routes.FIRM_BILLING):
      case getPath(Routes.FIRM_BILLING_TAB_MANAGE):
      case getPath(Routes.FIRM_BILLING_TAB_MEMBERSHIPS):
      case getPath(Routes.FIRM_BILLING_TAB_FLP_ORGANIZATION):
      case getPath(Routes.FIRM_BILLING_TAB_INVOICES):
      case getPath(Routes.FIRM_BILLING_INVOICE_CHECKOUT):
      case getPath(Routes.FIRM_BILLING_INVOICE_CONFIRMATION):
      case getPath(Routes.FIRM_BILLING_TAB_SUPPORT):
        return {
          ...matchingPath.params,
          useCimaFeatures,
        };
      default:
        return matchingPath.params;
    }
  };

  const values = getTitleValues();

  const path: string = (matchingPath && matchingPath.path) || getPath(Routes.ROOT);
  const template: (values?: PageTitleValues) => string = getPageTitles(path);
  const pageTitle: string = values ? template(values) : template();

  return (
    <Helmet>
      <title>
        {pageTitle} {isLowerEnvironment ? version : ''}
      </title>
    </Helmet>
  );
});
