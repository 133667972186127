import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { GET_TOP_TRENDING, GET_DYNAMIC_TOP_TRENDING } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';

// ------------------------------------
// Constants
// ------------------------------------

const initialState: State.TrendingAcross = {
  trendingArticles: null,
  trendingBlogs: null,
  acrossLinks: null,
};

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.trendingAcross,
  (trendingAcross: State.TrendingAcross): State.TrendingAcross => trendingAcross
);

export const trendingArticlesSelector = createSelector(
  rootSelector,
  (trendingAcross: State.TrendingAcross): State.ITrendingArticle[] | null => trendingAcross.trendingArticles
);

export const acrossLinksSelector = createSelector(
  rootSelector,
  (trendingAcross: State.TrendingAcross): State.IExternalLinkItem[] | null => trendingAcross.acrossLinks
);

// ------------------------------------
// Actions
// ------------------------------------

export enum TrendingAcrossActionNames {
  GET_TOP_TRENDING = 'trendingAcross/GET_TOP_TRENDING',
  GET_DYNAMIC_TOP_TRENDING = 'trendingAcross/GET_DYNAMIC_TOP_TRENDING',
  GET_POPULAR_SITES = 'trendingAcross/GET_POPULAR_SITES',
}

export const getTopTrending: any = createAction(
  TrendingAcrossActionNames.GET_TOP_TRENDING,
  () => () => request(GET_TOP_TRENDING)
);

export const getDynamicTopTrending: any = createAction(
  TrendingAcrossActionNames.GET_DYNAMIC_TOP_TRENDING,
  (isBlogs?: boolean) => () => request(GET_DYNAMIC_TOP_TRENDING, { isBlogs })
);

export const getPopularSites: any = createAction(TrendingAcrossActionNames.GET_POPULAR_SITES);

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [getTopTrending]: {
    next: (state: State.TrendingAcross, action: any): State.TrendingAcross => {
      const response = action.payload;
      const trendingArticles = response && response.trendingArticles && response.trendingArticles.articles;
      return {
        ...state,
        trendingArticles: trendingArticles || initialState.trendingArticles,
      };
    },
  },
  [getDynamicTopTrending]: {
    next: (state: State.TrendingAcross, action: any): State.TrendingAcross => {
      const response = action.payload;
      const trendingArticles =
        response && response.dynamicTrendingArticles && response.dynamicTrendingArticles.articles;
      return {
        ...state,
        trendingArticles: trendingArticles || initialState.trendingArticles,
      };
    },
  },
  [getPopularSites]: {
    next: (state: State.TrendingAcross, action: any): State.TrendingAcross => {
      const response = action.payload;
      const acrossLinks = response && response.popularSites && response.popularSites.sites;

      return {
        ...state,
        acrossLinks: acrossLinks || initialState.acrossLinks,
      };
    },
  },
};

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions(ACTION_HANDLERS, initialState);
