import { User } from 'mxp-schemas';
import { Product as ProductUtils } from 'mxp-utils';
import { isActiveCheckDates } from 'modules/products/helpers';
import { fromCentsFormat, formatPrice } from 'utils';

export const getMembershipCredentials = (
  existingCredentials: State.LineItem[],
  hasSelectedCredential: boolean,
  userChoicesCredentials: State.UserChoiceCredential[],
  credentialsList: State.CredentialProducts[],
  currency: State.ProductCurrency
) => {
  const addedCredentials = credentialsList.filter(item =>
    userChoicesCredentials.some(choice => choice.productId === item.productId)
  );
  const now = new Date();

  if (hasSelectedCredential) {
    if (!addedCredentials.length) return;

    return addedCredentials.map(item => {
      const sku = userChoicesCredentials.find(choice => choice.productId === item.productId)?.sku;
      const price =
        ProductUtils.getProductPrice(
          item,
          sku || item.variants[0].sku || '',
          [User.MembershipIdsEnum.MRUSR0001],
          currency.label
        )?.priceFinal?.amount ?? 0;

      return { name: item.name, value: formatPrice(fromCentsFormat(price), currency.label), status: 'Active' };
    });
  } else if (existingCredentials.length) {
    return existingCredentials.map(item => ({
      name: item.name,
      value: formatPrice(fromCentsFormat(item.totalPrice?.centAmount), item.totalPrice?.currencyCode),
      status: isActiveCheckDates(item as any, now) ? 'Active' : 'Expired',
    }));
  }
};
