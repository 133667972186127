import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'components/atoms';
import { StyledLink } from './ProductItem';
import { Product } from 'mxp-schemas';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

interface Props {
  productStandingOrder: { productStandingOrder: boolean };
  standingOrderDiscount?: Product.StandingOrderDiscount;
  handleCheckboxClick: (e: any, data: any) => void;
}

export const StandingOrderCheckbox: React.FC<Props> = ({
  standingOrderDiscount,
  productStandingOrder,
  handleCheckboxClick,
}) => (
  <>
    <StyledCheckbox
      onChange={handleCheckboxClick}
      testId={'standing-order-checkbox'}
      checked={productStandingOrder.productStandingOrder}
      height={18}
      width={18}
    />
    <StyledContainer inTwoLines={productStandingOrder.productStandingOrder}>
      <StyledText>Standing order option &nbsp;</StyledText>
      <StyledLink testId="standing-order-link" to={getPath(Routes.STANDING_ORDERS)} target="blank">
        {productStandingOrder.productStandingOrder && standingOrderDiscount?.new
          ? `${standingOrderDiscount.new}% off initial purchase`
          : null}
        {!productStandingOrder.productStandingOrder ? `Save ` : null}
        {!productStandingOrder.productStandingOrder && standingOrderDiscount?.new
          ? `${standingOrderDiscount.new}% now`
          : null}
        {!productStandingOrder.productStandingOrder && standingOrderDiscount?.new && standingOrderDiscount?.recurrent
          ? ` and `
          : null}
        {!productStandingOrder.productStandingOrder && standingOrderDiscount?.recurrent
          ? `${standingOrderDiscount.recurrent}% on future shipments`
          : null}
      </StyledLink>
    </StyledContainer>
  </>
);

const StyledCheckbox: any = styled(Checkbox)`
  margin: ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(9)} 0 0;
`;

const StyledContainer = styled.div<{ inTwoLines?: boolean }>`
  margin-bottom: ${props => props.theme.pxToRem(32)};
  ${props => !props.inTwoLines && 'margin-top: 6px;'}
  ${props => !props.inTwoLines && 'display: flex;'}
  ${props => !props.inTwoLines && 'flex-direction: column;'}
`;

const StyledText = styled.p`
  font-weight: ${props => props.theme.fontWeights.light};
  display: inline-block;
  margin: 0;
  font-size: ${props => props.theme.pxToRem(12)};
`;
