import React from 'react';
import styled, { css } from 'styled-components';
import { Accordion, AccordionTitleProps, Transition } from 'semantic-ui-react';
import { Container } from 'components/atoms';
import { IC_CHECK_GREEN, IC_CIRCLE_CLEAR, IC_LOCK } from 'resources/images';
import { Lock } from 'components/atoms/svg';

enum ExamStatusEnum {
  COMPLETED = 'Completed',
  LOCKED = 'Locked',
  OPEN = 'Active',
}

export interface ExamStructureItemProps {
  qualificationType: string;
  status: string;
  examsContent?: React.ReactNode;
}

interface ActiveIndexProps {
  stepNumber: number;
  isOpen: boolean;
}

interface Props {
  data: ExamStructureItemProps[];
  activeIndices: ActiveIndexProps[];
  onClick: AccordionTitleProps['onClick'];
}

export const ExamStructure: React.FC<Props> = ({ data, activeIndices, onClick }) => {
  return (
    <Container>
      <Accordion>
        <StyledUl>
          {data.map((examLevel: ExamStructureItemProps, index: number) => (
            <React.Fragment key={examLevel.qualificationType}>
              <StyledAccordionTitle active={activeIndices[index].isOpen} index={index} onClick={onClick}>
                <StyledLi status={examLevel.status} isActive={activeIndices[index].isOpen}>
                  <StyledLiContainer>
                    <StyledSpan>{examLevel.qualificationType}</StyledSpan>
                    <StyledInfoPillContainer>
                      <InfoPill status={examLevel.status}>
                        {examLevel.status === 'Locked' && <StyledLockIcon />}
                        {examLevel.status.toUpperCase()}
                      </InfoPill>

                      {activeIndices[index].isOpen ? <StyledH1>-</StyledH1> : <StyledH1>+</StyledH1>}
                    </StyledInfoPillContainer>
                  </StyledLiContainer>
                </StyledLi>
              </StyledAccordionTitle>

              <Transition visible={activeIndices[index].isOpen} animation="scale" duration={500}>
                <StyledAccordionContent active={activeIndices[index].isOpen && examLevel.status !== 'Locked'}>
                  {examLevel.examsContent}
                </StyledAccordionContent>
              </Transition>
            </React.Fragment>
          ))}
        </StyledUl>
      </Accordion>
    </Container>
  );
};

const StyledUl = styled.ul`
  background-image: linear-gradient(to bottom, ${props => props.theme.colors.neutralGrey1} 50%, #6a2d87 40%);
  background-position: left;
  background-size: ${props => props.theme.pxToRem(1)} ${props => props.theme.pxToRem(20)};
  background-repeat: repeat-y;
  margin: ${props => props.theme.pxToRem(10)} 0;
  padding-top: ${props => props.theme.pxToRem(20)};
`;

const StyledLi = styled.li<{ status: string; isActive: boolean }>`
  padding: 0 ${props => props.theme.pxToRem(10)};
  margin: 0 ${props => props.theme.pxToRem(-3.6)};

  ${({ status }) =>
    status === ExamStatusEnum.COMPLETED &&
    css`
      list-style-image: url(${IC_CHECK_GREEN});
      margin: 0 ${props => props.theme.pxToRem(1)};
    `}

  ${({ status }) =>
    status === ExamStatusEnum.OPEN &&
    css`
      list-style-image: url(${IC_CIRCLE_CLEAR});
      margin: 0 ${props => props.theme.pxToRem(1)};
    `}

    ${({ status }) =>
    status === ExamStatusEnum.LOCKED &&
    css`
      list-style-image: url(${IC_LOCK});
      margin: 0 ${props => props.theme.pxToRem(1)};
    `}

      ::after {
    content: '';
    height: ${props => props.theme.pxToRem(2)};
    background: ${props => props.theme.colors.primaryPurple};
    display: block;

    ${({ isActive }) =>
      isActive
        ? css`
            width: inherit;
          `
        : css`
            width: ${props => props.theme.pxToRem(60)};
          `}
  }
`;

const StyledLiContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.pxToRem(8)};
`;

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.33;
`;

const InfoPill = styled.div<{ status: string }>`
  width: ${props => props.theme.pxToRem(100)};
  height: ${props => props.theme.pxToRem(26)};
  padding: ${props => props.theme.pxToRem(15)} ${props => props.theme.pxToRem(12)};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: ${props => props.theme.pxToRem(1.5)};
  color: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(4)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ status }) =>
    status === 'Completed' &&
    css`
      background: #00857d;
    `}

  ${({ status }) =>
    status === 'Locked' &&
    css`
      background: ${props => props.theme.colors.neutralGrey1};
      color: ${({ theme }) => theme.colors.primaryDarkPurple} !important;
    `}

    ${({ status }) =>
    status === 'Open' &&
    css`
        border: ${props => props.theme.pxToRem(1.5)}; solid ${props => props.theme.colors.neutralGrey6};
        color: ${({ theme }) => theme.colors.neutralGrey8} !important;
      `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: auto;
  }
`;

const StyledH1 = styled.span`
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.xl};
`;

const StyledAccordionTitle = styled(Accordion.Title)`
  margin: ${props => `${props.theme.pxToRem(-40)} 0 ${props.theme.pxToRem(80)} ${props.theme.pxToRem(-23)}`} !important;
  padding: ${props => props.theme.pxToRem(10)} 0;

  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: left !important;
    margin: ${props =>
      `${props.theme.pxToRem(-40)} 0 ${props.theme.pxToRem(70)} ${props.theme.pxToRem(-23)}`} !important;
  }
`;

const StyledAccordionContent = styled(Accordion.Content)`
  margin: ${props =>
    `${props.theme.pxToRem(-75)} ${props.theme.pxToRem(-20)} ${props.theme.pxToRem(60)} ${props.theme.pxToRem(
      -32
    )}`} !important;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props =>
      `${props.theme.pxToRem(-70)} ${props.theme.pxToRem(-30)} ${props.theme.pxToRem(60)} ${props.theme.pxToRem(
        -25
      )}`} !important;
  }
`;

const StyledInfoPillContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.theme.pxToRem(160)};
`;

const StyledLockIcon = styled(Lock)`
  height: ${props => props.theme.pxToRem(18)};
  width: ${props => props.theme.pxToRem(18)};
  margin-right: ${props => props.theme.pxToRem(5)};
`;
