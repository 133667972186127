import React from 'react';
import styled from 'styled-components';
import { Divider } from 'semantic-ui-react';
import { ContentCardsCarousel } from 'components/organisms/ContentCardsCarousel';
import { ContentCard } from 'components/molecules/ContentCard/ContentCard';
import { Grid, OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms';

interface Props {
  editorialBlockItems: State.ContentCardItem[];
}

export const TopicHeroEditorialBlockItems: React.FC<Props> = React.memo(({ editorialBlockItems }) => {
  const isTwoRows: boolean = editorialBlockItems.length > 4 && editorialBlockItems.length >= 8;
  return (
    <>
      <Grid.Row>
        <Grid.Column computer={16} tablet={16}>
          <Divider hidden />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={16} tablet={16}>
          <OnlyMobileCSS
            editorialHeroCarousel
            as={StyledContentCardsCarousel}
            testId="hero-editorial-carousel"
            itemTestId="topic-hero-content-card"
            content={editorialBlockItems}
          />
        </Grid.Column>
      </Grid.Row>
      <OnlyDesktopCSS as={Grid.Row} data-testid="hero-editorial-carousel">
        {editorialBlockItems.length >= 2 &&
          editorialBlockItems.map((contentCardItem: State.ContentCardItem, index: number) => {
            const isDoubleWidthCard: boolean =
              editorialBlockItems.length < 3 || (editorialBlockItems.length < 4 && index === 0);
            return (
              <React.Fragment key={contentCardItem.id}>
                {index < 4 && (
                  <Grid.Column computer={isDoubleWidthCard ? 8 : 4} tablet={16}>
                    <ContentCard
                      isDoubleWidthCard={isDoubleWidthCard}
                      contentCardItem={contentCardItem}
                      testid="topic-hero-content-card"
                      isAggregationHeroCard
                    />
                  </Grid.Column>
                )}

                {isTwoRows && index === 4 && (
                  <Grid.Column computer={16} tablet={16}>
                    <Divider hidden />
                  </Grid.Column>
                )}
                {isTwoRows && index > 3 && (
                  <Grid.Column computer={4} tablet={16}>
                    <ContentCard
                      isAggregationHeroCard
                      isDoubleWidthCard={!index}
                      contentCardItem={contentCardItem}
                      testid="topic-hero-content-card"
                      isSmallCard
                    />
                  </Grid.Column>
                )}
              </React.Fragment>
            );
          })}
        <Grid.Column computer={16} tablet={16}>
          <Divider hidden />
        </Grid.Column>
      </OnlyDesktopCSS>
    </>
  );
});

const StyledContentCardsCarousel = styled(ContentCardsCarousel)`
  &&&&& {
    padding: 0;
  }
`;
