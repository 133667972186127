import React from 'react';
import styled from 'styled-components';

export const MembershipStepperNotificationHeader: any = () => {
  return (
    <StyledDiv>
      Your application will automatically save after each stage, should you need to step away and complete it later.
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
`;
