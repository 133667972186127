import { Salesforce } from 'mxp-schemas';
import { match as MatchProps } from 'react-router';
import { createSelector, OutputSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { emptyArray, emptyObject } from 'utils';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';
import { userMembershipTypeSelector } from 'modules/membership/selectors';
import { combineHashWithEditedHash } from './helpers';
import { CenterAdminTableType } from 'constants/center-admin';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.centerAdmin,
  (centerAdmin: CenterAdmin.Root): CenterAdmin.Root => centerAdmin
);

const organizationsSelector = createSelector(
  rootSelector,
  (centerAdmin: CenterAdmin.Root): CenterAdmin.Organizations => centerAdmin.organizations
);

export const organizationsListSelector = createSelector(
  [organizationsSelector],
  (organizations: CenterAdmin.Organizations) => organizations.list || emptyArray
);

export const isOrganizationsFetchedSelector = createSelector(
  [organizationsSelector],
  (organizations: CenterAdmin.Organizations): boolean => organizations.isOrganizationsFetched
);

export const organizationAdminsSelector = createSelector(
  rootSelector,
  (centerAdmin: CenterAdmin.Root): CenterAdmin.Contact[][] => centerAdmin.organizationAdmins
);

interface OrganizationNavigationParams {
  orgId: string;
}
const organizationNavigationMatchSelector: any = createMatchSelector(getPath(Routes.CENTER_ADMIN_ROOT));
export const selectedOrganizationIdSelector = createSelector(
  [organizationNavigationMatchSelector],
  (match: MatchProps<OrganizationNavigationParams>): string => match?.params?.orgId || ''
);

export const selectedOrganizationSelector = createSelector(
  [selectedOrganizationIdSelector, organizationsListSelector],
  (orgId: string, organizations: CenterAdmin.Organization[]): CenterAdmin.Organization | null => {
    const branchId = organizations
      .map((organization: CenterAdmin.Organization) => {
        if (organization?.branches && organization?.branches?.length > 0) {
          return (
            organization?.branches?.find(
              (organizationBranch: CenterAdmin.Organization) => organizationBranch.id === orgId
            ) || null
          );
        }
        return null;
      })
      ?.filter((organization: CenterAdmin.Organization | null) => {
        return organization !== null;
      });
    return (
      branchId[0] || organizations.find((organization: CenterAdmin.Organization) => organization.id === orgId) || null
    );
  }
);

export const centerMembershipsSelector = createSelector(
  rootSelector,
  (centerAdmin: CenterAdmin.Root): CenterAdmin.CenterMemberships => centerAdmin.centerMemberships
);

export const centerMembershipsListSelector = createSelector(
  [centerMembershipsSelector],
  (centerMemberships: CenterAdmin.CenterMemberships): CenterAdmin.FirmMembership[] => centerMemberships.list
);

export const getSelectedOrganizationByIdSelector = (orgId: string) =>
  createSelector(
    organizationsListSelector,
    (organizations: CenterAdmin.Organization[]): CenterAdmin.Organization | null =>
      organizations.find((organization: CenterAdmin.Organization) => organization.id === orgId) || null
  );

export const getCenterMembershipByIdSelector = (id: string) =>
  createSelector(
    centerMembershipsListSelector,
    (centerMemberships: CenterAdmin.FirmMembership[]): CenterAdmin.FirmMembership | undefined =>
      centerMemberships.find(membership => membership.id === id)
  );

export const centerMembershipsFilterSelector = createSelector(
  [centerMembershipsSelector],
  (centerMemberships: CenterAdmin.CenterMemberships): CenterAdmin.CenterMembershipsFilters =>
    centerMemberships.filters || emptyObject
);

export const centerMembershipsIsFetchedSelector = createSelector(
  [centerMembershipsSelector],
  (centerMemberships: CenterAdmin.CenterMemberships): boolean => centerMemberships.isFetched
);

export const getOrganizationMembershipsSelector = createSelector(
  [rootSelector, userMembershipTypeSelector],
  (centerAdmin: CenterAdmin.Root, membershipSlug: string): Salesforce.FirmMembership[] =>
    centerAdmin.organizationMemberships.filter(orgMembership =>
      orgMembership.firmMembershipType.toUpperCase().includes(membershipSlug.toUpperCase())
    )
);

export const dataSelectorFactory = <T = CenterAdmin.Member>(type: CenterAdmin.CenterAdminTableType) =>
  createSelector(rootSelector, (CenterAdmin: CenterAdmin.Root): CenterAdmin.Data<T> => CenterAdmin.data[type]);

export const dataAllIdsSelectorFactory = (type: CenterAdmin.CenterAdminTableType) =>
  createSelector(rootSelector, (CenterAdmin: CenterAdmin.Root): string[] => {
    const { hash } = CenterAdmin.data[type];
    return Object.keys(hash).reduce((acc: string[], key: string) => {
      if (!hash[key].isCheckboxDisabled) acc.push(key);
      return acc;
    }, []);
  });

export const paginationSelectorFactory = (type: CenterAdmin.CenterAdminTableType) =>
  createSelector(rootSelector, (CenterAdmin: CenterAdmin.Root): CenterAdmin.Pagination => CenterAdmin.pagination[type]);

export const isNoSearchAndFiltersInPaginationSelectorFactory = (type: CenterAdmin.CenterAdminTableType) =>
  createSelector(
    paginationSelectorFactory(type),
    (pagination: CenterAdmin.Pagination): boolean =>
      !pagination.query &&
      !Object.keys(pagination.filters).filter((key: string) => {
        return ['membershipType', 'membershipTier'].includes(key) && (pagination.filters as any)[key];
      }).length
  );

export const membersSelectorFactory = <T>(
  dataSelector: OutputSelector<
    State.Root,
    CenterAdmin.Data<any>,
    (CenterAdmin: CenterAdmin.Root) => CenterAdmin.Data<any>
  >
) =>
  createSelector(dataSelector, (data: CenterAdmin.Data<T>): CenterAdmin.WithEditedInfo<T>[] =>
    Object.values(combineHashWithEditedHash(data.hash, data.modifiedHash))
  );

export const selectedOrganizationOfficeLocationsHashSelector = createSelector(
  selectedOrganizationSelector,
  (organization: CenterAdmin.Organization | null): CenterAdmin.OfficeLocationsHash => {
    if (!organization) return emptyArray;
    const options = [
      {
        organizationId: organization.id,
        branchCountry: organization.shippingAddress.country,
        branchState: organization.shippingAddress.state || 'N/A',
        branchCity: organization.shippingAddress.city,
        officeLocation: organization.shippingAddress.addressLine1,
      },
    ];

    const organizationOfficeLocations = (function getBranchesAddresses(
      branches,
      officeLocations: CenterAdmin.OfficeLocation[]
    ) {
      if (!branches) return officeLocations;
      branches.forEach(branch => {
        officeLocations.push({
          organizationId: branch.id,
          branchCountry: branch.shippingAddress.country,
          branchState: branch.shippingAddress.state || 'N/A',
          branchCity: branch.shippingAddress.city,
          officeLocation: branch.shippingAddress.addressLine1,
        });
        if (branch.branches) getBranchesAddresses(branch.branches, officeLocations);
      });
      return officeLocations;
    })(organization.branches, options);

    return organizationOfficeLocations.reduce(
      (acc: CenterAdmin.OfficeLocationsHash, item) => ({
        ...acc,
        [item.branchCountry]: {
          ...(acc[item.branchCountry] || {}),
          [item.branchState]: {
            ...(acc[item.branchCountry]?.[item.branchState] || {}),
            [item.branchCity]: [...(acc[item.branchCountry]?.[item.branchState]?.[item.branchCity] || []), item],
          },
        },
      }),
      {}
    );
  }
);

export const seatManagementSelector = createSelector(
  rootSelector,
  (centerAdmin: CenterAdmin.Root): CenterAdmin.SeatManagement => centerAdmin.seatManagement
);

export const contractLineItemListSelector = createSelector(
  [seatManagementSelector],
  (seatManagement: CenterAdmin.SeatManagement): CenterAdmin.ContractLineItem[] => {
    const { query } = seatManagement.contractLineItemModifiers;

    return query
      ? seatManagement.contractLineItemList.filter(i => i.contactEmail.includes(query) || i.contactName.includes(query))
      : seatManagement.contractLineItemList || emptyArray;
  }
);

export const contractLineItemModifiersSelector = createSelector(
  [seatManagementSelector],
  (seatManagement: CenterAdmin.SeatManagement): CenterAdmin.ContractLineItemModifiers =>
    seatManagement.contractLineItemModifiers || emptyObject
);

export const contractLineItemIsFetchedSelector = createSelector(
  [seatManagementSelector],
  (seatManagement: CenterAdmin.SeatManagement): boolean => seatManagement.contractLineItemIsFetched
);

interface CenterMembershipSeatsManagementParams {
  orgId: string;
  membershipId: string;
}
const centerMembershipSeatsManagementMatchSelector: any = createMatchSelector(
  getPath(Routes.CENTER_ADMIN_MEMBERSHIPS_SEATS_MANAGEMENT)
);
export const selectedCenterMembershipIdSelector = createSelector(
  [centerMembershipSeatsManagementMatchSelector],
  (match: MatchProps<CenterMembershipSeatsManagementParams>): string => match?.params?.membershipId || ''
);

export const selectedCenterMembershipSelector = createSelector(
  [selectedCenterMembershipIdSelector, centerMembershipsListSelector],
  (membershipId: string, centerMemberships: CenterAdmin.FirmMembership[]): CenterAdmin.FirmMembership | null =>
    centerMemberships.find((centerMembership: CenterAdmin.FirmMembership) => centerMembership.id === membershipId) ||
    null
);

export const invoiceHashSelector = createSelector(
  rootSelector,
  (centerAdmin: CenterAdmin.Root): CenterAdmin.Data<any> =>
    centerAdmin.data[CenterAdminTableType.CENTER_MEMBERSHIP_INVOICES]
);

export const invoiceRecords = createSelector(
  invoiceHashSelector,
  (invoiceHash: CenterAdmin.Data<any>): CenterAdmin.Hash<any> => invoiceHash.hash
);

export const selectedInvoiceToDownloadIdSelector = createSelector(
  [invoiceHashSelector],
  (invoiceHash: CenterAdmin.Data<any>) => invoiceHash.downloadingId
);

export const selectedInvoiceToDownloadSelector = createSelector(
  [selectedInvoiceToDownloadIdSelector, invoiceRecords],
  (invoiceId: any, invoicesRecords: CenterAdmin.Hash<any>) => invoicesRecords[invoiceId]
);

export const selectedInvoiceSelector = createSelector(
  rootSelector,
  (centerAdmin: CenterAdmin.Root): CenterAdmin.CenterMembershipInvoice =>
    centerAdmin.selectedInvoice as CenterAdmin.CenterMembershipInvoice
);

export const invoicePaymentConfirmationSelector = createSelector(
  rootSelector,
  (centerAdmin: CenterAdmin.Root): State.InvoicePaymentConfirmation | null => {
    const invoice = centerAdmin.selectedInvoice as CenterAdmin.CenterMembershipInvoice;
    const invoicePaymentResult = centerAdmin.paymentResult as any;

    if (!invoicePaymentResult) {
      return null;
    }

    const totalInvoiceAmount = invoicePaymentResult.amount;
    const currentTotalInvoiceBalance = 0; // invoicePaymentResult.balance;
    const invoiceBalance = currentTotalInvoiceBalance === 0 ? totalInvoiceAmount : currentTotalInvoiceBalance;
    const paidAmount =
      currentTotalInvoiceBalance === 0
        ? totalInvoiceAmount
        : totalInvoiceAmount && invoiceBalance
        ? totalInvoiceAmount - invoiceBalance
        : 0;
    const remainingBalance = totalInvoiceAmount && paidAmount ? totalInvoiceAmount - paidAmount : 0;

    const invoicePaymentResultTransformed = {
      id: invoicePaymentResult.id,
      invoiceDate: invoice.invoiceDate,
      dueDate: invoice?.dueDate,
      amount: invoicePaymentResult.amount,
      balance: invoicePaymentResult.balance,
      invoiceNumber: invoice?.invoiceNumber,
      accountId: invoicePaymentResult.accountId,
      invoiceBalance,
      paidAmount,
      remainingBalance,
      paymentDate: invoicePaymentResult?.effectiveDate,
    };

    return invoicePaymentResultTransformed;
  }
);

const provisioningSelector = createSelector(
  rootSelector,
  (centerAdmin: CenterAdmin.Root): CenterAdmin.Provisioning => centerAdmin.provisioning
);

export const provisioningLoadingSelector = createSelector(
  provisioningSelector,
  (provisioning: CenterAdmin.Provisioning): boolean => provisioning.loading
);

export const provisioningErrorSelector = createSelector(
  provisioningSelector,
  (provisioning: CenterAdmin.Provisioning): CustomErrors.GraphQLError | null => provisioning.errors
);
