import React from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownProps } from 'components/atoms/Dropdown/Dropdown';

interface DropdownWithInfoProps extends DropdownProps {
  label: string;
  warning: string;
  hasError: boolean;
}

export const DropdownWithInfo: React.FC<DropdownWithInfoProps> = ({ label, warning, ...props }) => {
  return (
    <div>
      <StyledLabel>{label}</StyledLabel>
      <StyledDropdown {...props} />
      <StyledWarning hasError={props.hasError}>{warning}</StyledWarning>
    </div>
  );
};

const StyledLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.s};
  display: block;
  margin-bottom: ${props => props.theme.pxToRem(7)};
`;

const StyledWarning = styled.p<{ hasError: boolean }>`
  margin-top: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.fontSizes.xxs};
  ${props => props.hasError && `color: ${props.theme.colors.interfaceRed};`}
`;

const StyledDropdown = styled(Dropdown)<{ hasError: boolean }>`
  padding: ${props => props.theme.pxToRem(12)};
  &&&& {
    ${props => props.hasError && `border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.interfaceRed};`}
    >.default.text {
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
  &.ui.dropdown .menu {
    ${props => props.hasError && `border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.interfaceRed};`}
  }
`;
