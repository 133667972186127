import { StorageNames } from 'constants/index';
import { addSalesforceAddress } from 'modules/address/actions';
import { getInactiveMembershipSlug } from 'modules/membership/actions';
import {
  getUserSyllabusByPersonAccountId,
  resolveEmail,
  setApplicationInitialLoad,
  updateProfessionalStatus,
} from 'modules/postLoginActionsTable';
import {
  backupSubskills,
  backupSubtopics,
  checkIfOrganizationExists,
  checkSession,
  checkUserCountryIfEmbargoed,
  checkUserExists,
  clearAllSubskills,
  clearAllSubtopics,
  clearAttestation,
  clearNewEmployerData,
  clearEmployerDataEmployers,
  clearOrgazanizationFromPromoCode,
  clearResetEmailSendSuccess,
  clearSmartyStreetsSecondaryAddressValidation,
  clearUserError,
  clearUserForgotEmail,
  clearUserPlatform,
  clearUserPref,
  clearUserSkillPref,
  cpaStatusLoading,
  createEventRegistration,
  createEventRegistrationLoading,
  createEventRegistrationSuccess,
  createFirmMembershipWithAttestation,
  createGoldenRecord,
  createGoldenRecordCima,
  createSpecialConsideration,
  createSpecialConsiderationLoading,
  createSpecialConsiderationSuccess,
  createUser,
  fetchCPAStatus,
  getEPA2L7Submissions,
  getEPA2Submissions,
  getFlpResellerDataByAccountNumber,
  getGoldenRecord,
  getLocalUser,
  getMarketingPreferences,
  getMyProfile,
  getNetforumProfile,
  getOrganizationByNameAndCity,
  getOrganizationDataByAccountNumber,
  getSecurityQuestion,
  getSFMCLink,
  getSpecialConsiderationsExams,
  getStudentExamSectionResultsData,
  getStudentProgressionData,
  getTrainingProviderDataByAccountNumber,
  getUniversityDetails,
  getUser,
  getUserByAICPAIdAndLastName,
  getStudentExamHistoryData,
  getUserFeedbackValues,
  getUserLocation,
  getUserPlatform,
  invalidateSession,
  login,
  logout,
  processQualification,
  processQualificationLoading,
  processTLW,
  putUserFeedbackValues,
  removeEmploymentData,
  removeTLW,
  resetEmploymentDataEffect,
  resetStudentExamSectionResults,
  resetSubskills,
  resetSubtopics,
  resetUserPassword,
  searchOrganizationsByNewTrainingProvider,
  searchEmployer,
  searchOrganizationsByWildcard,
  searchOrganizationsByWildcardLoading,
  searchOrganizationsCities,
  searchOrganizationsCitiesLoading,
  searchUniversitiesByWildcard,
  searchUniversitiesByWildcardLoading,
  selectAllSubskills,
  selectAllSubtopics,
  sendRecoveryQuestionEmail,
  sendResetPasswordEmail,
  setAuth,
  setCPAStatus,
  setCurrentUserPrimaryAddress,
  setEventRegistrationData,
  setMembershipApplicationAdminDetail,
  setMembershipApplicationOrganizationDetail,
  setMembershipApplicationQuestion,
  // center membership
  setMembershipPackageOrganization,
  setMembershipPackageOrganizationIssuer,
  setNewEmployerJobTitle,
  setOrganizationSuggestedAddress,
  setPersonalSuggestedAddress,
  setRegistrationLoading,
  setStudentLearningPathwayToSwitch,
  setUserError,
  setUserPasswordUpdated,
  toggleApplicationInitialLoad,
  toggleIndustryPrefAndUpdateGoldenRecord,
  toggleMarketingPreferencesLoading,
  toggleNetforumLoading,
  toggleSkillPrefAndUpdateGoldenRecord,
  toggleSubskillPref,
  toggleSubskillPrefAndUpdateGoldenRecord,
  toggleSubtopicPref,
  toggleSubtopicPrefAndUpdateGoldenRecord,
  toggleTopicPrefAndUpdateGoldenRecord,
  toggleTrendPrefAndUpdateGoldenRecord,
  toggleUserShortcuts,
  updateAccountAsPartner,
  updateAccountLoading,
  updateAddressBook,
  updateApplication,
  updateApplicationPart,
  getApplications,
  updateAttestation,
  updateEPA2L4CaseStatus,
  updateEPA2L7CaseStatus,
  updateGoldenRecord,
  updateMarketingPreferences,
  updateOrganizationDetails,
  updatePersonAccount,
  updatePersonalInformation,
  updatePersonalInformationLoading,
  updateSecurityResponse,
  updateUserAddressList,
  updateYearOfGraduation,
  upgradeLearningPathway,
  upsertEmploymentData,
  upsertEmploymentDataLoading,
  upsertEmploymentDataStatus,
  userLoading,
  validateOrganizationAddress,
  validateOrganizationAddressLoading,
  validatePersonAddress,
  validatePersonAddressLoading,
  setHasPreferredCurrency,
  updateCurrentLearningPathway,
  updateAccountUniversityDetails,
} from 'modules/user/actions';
import { Salesforce, User } from 'mxp-schemas';
import { handleActions } from 'redux-actions';
import { setLocalStorageItem } from 'utils/localStorage';
import { initialState } from './constants';
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [userLoading]: (state: State.User): State.User => ({
    ...state,
    loading: true,
  }),

  [setAuth]: (state: State.User, action: any): State.User => ({
    ...state,
    isAuth: action.payload,
  }),

  [clearUserError]: (state: State.User): State.User => ({
    ...state,
    error: initialState.error,
  }),
  [clearUserForgotEmail]: (state: State.User): State.User => ({
    ...state,
    forgotEmail: initialState.forgotEmail,
  }),
  [clearUserPlatform]: (state: State.User): State.User => ({
    ...state,
    platform: initialState.platform,
  }),

  [clearResetEmailSendSuccess]: (state: State.User): State.User => ({
    ...state,
    resetEmailSendSuccess: initialState.resetEmailSendSuccess,
  }),

  [checkSession]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      ...action.payload,
    }),
  },

  [login]: {
    next: (state: State.User): State.User => ({
      ...state,
      loading: false,
      invalidatedSession: false,
    }),
    throw: (state: State.User, action: any): State.User => ({
      ...state,
      error: {
        ...action.payload,
      },
      loading: false,
    }),
  },
  [createUser]: {
    next: (state: State.User): State.User => ({
      ...state,
      loading: false,
      invalidatedSession: false,
    }),
    throw: (state: State.User, action: any): State.User => ({
      ...state,
      error: {
        ...action.payload,
      },
      loading: false,
    }),
  },
  [resolveEmail]: {
    next: (state: State.User): State.User => ({
      ...state,
      loading: false,
    }),
  },
  [setUserError]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      error: {
        errorCode: action.payload,
      },
      loading: false,
    };
  },
  [setUserPasswordUpdated]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      userPasswordUpdated: action.payload,
    };
  },
  [getUserByAICPAIdAndLastName]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      forgotEmail: action.payload,
      error: null,
      loading: false,
    }),
    throw: (state: State.User, action: any): State.User => ({
      ...state,
      error: {
        ...action.payload,
      },
      loading: false,
    }),
  },
  [getUserPlatform]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      platform: action.payload,
      loading: false,
    }),
  },
  [sendResetPasswordEmail]: {
    next: (state: State.User): State.User => ({
      ...state,
      resetEmailSendSuccess: true,
      loading: false,
    }),
  },
  [sendRecoveryQuestionEmail]: {
    next: (state: State.User): State.User => ({
      ...state,
      recoveryQuestionEmailSendSuccess: true,
      loading: false,
    }),
  },
  [resetUserPassword]: {
    next: (state: State.User): State.User => ({
      ...state,
      loading: false,
    }),
    throw: (state: State.User, action: any): State.User => ({
      ...state,
      error: {
        ...action.payload,
      },
      loading: false,
    }),
  },
  [checkUserExists]: {
    next: (state: State.User): State.User => ({
      ...state,
      error: null,
      loading: false,
    }),
    throw: (state: State.User, action: any): State.User => ({
      ...state,
      error: {
        ...action.payload,
      },
      loading: false,
    }),
  },

  [logout]: {
    next: (state: State.User): State.User => ({
      ...initialState,
      // in case if isAuth === false, PageLogoutHomeContainer will be rendered
      isAuth: state.isAuth,
    }),
  },

  [getLocalUser]: (state: State.User, action: any): State.User => {
    if (!action.payload) return state;
    return {
      ...state,
      data: {
        ...state.data,
        ...action.payload,
      },
      userFetched: true,
    };
  },

  [getNetforumProfile]: {
    next: (state: State.User, action: any): State.User => {
      return {
        ...state,
        netforumProfile: { ...action.payload, fetched: true, loading: false },
      };
    },
    throw: (state: State.User): State.User => {
      return {
        ...state,
        netforumProfile: { ...state.netforumProfile, fetched: true, loading: false },
      };
    },
  },

  [toggleNetforumLoading]: (state: State.User, action: any): State.User => {
    if (!action.payload) return state;
    return {
      ...state,
      netforumProfile: {
        ...state.netforumProfile,
        loading: action.payload,
      },
    };
  },

  [getMarketingPreferences]: {
    next: (state: State.User, action: any): State.User => {
      if (!action.payload) return state;
      return {
        ...state,
        marketingPreferences: {
          preferences: { ...(action.payload as User.MarketingPreferences) },
          error: null,
          loading: false,
        },
      };
    },
    throw: (state: State.User, action: any): State.User => {
      return {
        ...state,
        marketingPreferences: {
          preferences: { ...state.marketingPreferences.preferences },
          error: { ...action.payload },
          loading: false,
        },
      };
    },
  },

  [updateMarketingPreferences]: {
    next: (state: State.User, action: any): State.User => {
      return {
        ...state,
        marketingPreferences: {
          preferences: { ...(action.payload as User.MarketingPreferences) },
          error: null,
          loading: false,
        },
      };
    },
    throw: (state: State.User, action: any): State.User => {
      return {
        ...state,
        marketingPreferences: {
          preferences: { ...state.marketingPreferences.preferences },
          error: { ...action.payload },
          loading: false,
        },
      };
    },
  },

  [toggleMarketingPreferencesLoading]: (state: State.User, action: any): State.User => {
    if (!action.payload) return state;
    return {
      ...state,
      marketingPreferences: {
        preferences: { ...state.marketingPreferences.preferences },
        loading: action.payload,
        error: null,
      },
    };
  },

  [getUser]: {
    next: (state: State.User, action: any): State.User => {
      setLocalStorageItem({ [StorageNames.userData]: action.payload.me });
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.me,
        },
        userFetched: true,
      };
    },
  },

  [getGoldenRecord]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      preferences: action.payload.preferences || initialState.preferences,
      industryPreferences: action.payload.industryPreferences || initialState.industryPreferences,
      trendPreferences: action.payload.trendPreferences || initialState.trendPreferences,
      skillPreferences: action.payload.skillPreferences || initialState.skillPreferences,
      shortcuts: action.payload.shortcuts || initialState.shortcuts,
      goldenRecordFetched: true,
    }),
  },

  [createGoldenRecord]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      preferences: action.payload.updateGoldenRecord.preferences,
      loading: false,
    }),
    throw: (state: State.User): State.User => ({
      ...state,
      loading: false,
    }),
  },

  [createGoldenRecordCima]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      preferences: action.payload.updateGoldenRecordForCimaPreferences.preferences || state.preferences,
      industryPreferences:
        action.payload.updateGoldenRecordForCimaPreferences.industryPreferences || state.industryPreferences,
      trendPreferences: action.payload.updateGoldenRecordForCimaPreferences.trendPreferences || state.trendPreferences,
      skillPreferences: action.payload.updateGoldenRecordForCimaPreferences.skillPreferences || state.skillPreferences,
      loading: false,
    }),
    throw: (state: State.User): State.User => ({
      ...state,
      loading: false,
    }),
  },

  [updateGoldenRecord]: (state: State.User): State.User => ({
    ...state,
    loading: false,
  }),

  [toggleSubtopicPref]: (state: State.User, action: any): State.User => ({
    ...state,
    preferences: action.payload || initialState.preferences,
  }),

  [backupSubtopics]: (state: State.User): State.User => ({
    ...state,
    previousPreferences: { ...state.preferences },
  }),

  [resetSubtopics]: (state: State.User): State.User => ({
    ...state,
    preferences: { ...state.previousPreferences },
  }),

  [selectAllSubtopics]: (state: State.User, action: any): State.User => {
    if (!action.payload) return state;
    return {
      ...state,
      preferences: {
        ...state.preferences,
        ...action.payload,
      },
    };
  },

  [clearAllSubtopics]: (state: State.User, action: any): State.User => {
    if (!action.payload) return state;
    const preferences = Object.keys(state.preferences).reduce((acc: any, key: string) => {
      if (key === action.payload) return acc;
      acc[key] = state.preferences[key];
      return acc;
    }, {});

    return {
      ...state,
      preferences,
    };
  },

  [clearUserPref]: (state: State.User): State.User => ({
    ...state,
    preferences: initialState.preferences,
  }),

  [clearUserSkillPref]: (state: State.User): State.User => ({
    ...state,
    skillPreferences: initialState.skillPreferences,
  }),

  [toggleTopicPrefAndUpdateGoldenRecord]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      preferences: action.payload || initialState.preferences,
    }),
  },

  [toggleSubtopicPrefAndUpdateGoldenRecord]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      preferences: action.payload || initialState.preferences,
    }),
  },

  [toggleTrendPrefAndUpdateGoldenRecord]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      trendPreferences: action.payload || initialState.trendPreferences,
    }),
  },

  [toggleIndustryPrefAndUpdateGoldenRecord]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      industryPreferences: action.payload || initialState.industryPreferences,
    }),
  },

  [toggleSkillPrefAndUpdateGoldenRecord]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      skillPreferences: action.payload || initialState.skillPreferences,
    }),
  },

  [toggleSubskillPrefAndUpdateGoldenRecord]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      skillPreferences: action.payload || initialState.skillPreferences,
    }),
  },

  [getUserFeedbackValues]: (state: State.User, action: any): State.User => ({
    ...state,
    feedback:
      state.feedback || (action.payload && action.payload.feedback)
        ? {
            ...state.feedback,
            [action.payload.contentId]: action.payload.feedback,
          }
        : initialState.feedback,
  }),

  [putUserFeedbackValues]: (state: State.User, action: any): State.User => ({
    ...state,
    feedback:
      state.feedback || (action.payload && action.payload.feedback)
        ? {
            ...state.feedback,
            [action.payload.contentId]: action.payload.feedback,
          }
        : initialState.feedback,
  }),

  [toggleUserShortcuts]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      shortcuts: action.payload || initialState.shortcuts,
    }),
  },

  [invalidateSession]: {
    next: (state: State.User): State.User => ({
      ...state,
      invalidatedSession: true,
    }),
  },

  [fetchCPAStatus]: (state: State.User, action: any): State.User => ({
    ...state,
    cpaStatus: {
      isLicensedCPA: action.payload.getCPAStatus?.isLicensedCPA,
      cpaStatusFetched: true,
      loading: false,
    },
  }),

  [setCPAStatus]: (state: State.User, action: any): State.User => ({
    ...state,
    cpaStatus: { isLicensedCPA: action.payload.setCPAStatus.cpaFlag, cpaStatusFetched: true, loading: false },
  }),

  [cpaStatusLoading]: (state: State.User, action: any): State.User => ({
    ...state,
    cpaStatus: { ...state.cpaStatus, loading: true },
  }),
  [getMyProfile]: (state: State.User, action: any): State.User => {
    setLocalStorageItem({ [StorageNames.userData]: action.payload.me });
    const isActive = (obj: any) =>
      obj.status === Salesforce.MembershipStatus.ACTIVE ||
      obj.status === Salesforce.MembershipStatus.GRACE ||
      obj.status === Salesforce.MembershipStatus.SUSPENDED;
    const isInactive = (obj: any) =>
      obj.status === Salesforce.MembershipStatus.LAPSED ||
      obj.status === Salesforce.MembershipStatus.RESIGNED ||
      obj.status === Salesforce.MembershipStatus.TERMINATED;
    const isSuspended = (obj: any) => obj.status === Salesforce.MembershipStatus.SUSPENDED;
    return {
      ...state,
      personAccountData: {
        ...state.personAccountData,
        ...action.payload?.personAccount,
      },
      allAddresses: action.payload?.allAddresses || [],
      credentials: action.payload?.credentials?.filter(isActive) || [],
      inactiveCredentials: action.payload?.credentials?.filter(isInactive) || [],
      suspendedCredentials: action.payload?.credentials?.filter(isSuspended) || [],
      sections: action.payload?.sections?.filter(isActive) || [],
      inactiveSections: action.payload?.sections?.filter(isInactive) || [],
      memberships: action.payload?.memberships?.filter(isActive) || [],
      inactiveMemberships: action.payload?.memberships?.filter(isInactive) || [],
      customerOrderHistory: action.payload?.customerOrderHistory || [],
      application: {
        ...state.application,
        ...action.payload?.application,
      },
      qualificationData: {
        ...state.qualificationData,
        cpaLicenseData: {
          ...state.qualificationData.cpaLicenseData,
          ...action.payload?.cpaLicense,
        },
        cpaLicenseListData: {
          ...state.qualificationData.cpaLicenseListData,
          cpaLicense: action.payload?.cpaLicenses || [],
        },
      },
      employmentData: {
        ...state.employmentData,
        employers: action.payload?.employers ?? [],
      },
      eventRegistrationData: {
        ...state.eventRegistrationData,
        firstName: action?.payload?.personAccount?.firstName,
        lastName: action?.payload?.personAccount?.lastName,
        emailContactId: action?.payload?.personAccount?.primaryEmail?.emailAddress,
      },
      preferences: action.payload?.personAccount?.preferences && JSON.parse(action.payload.personAccount.preferences),
      industryPreferences:
        action.payload?.personAccount?.industryPreferences &&
        JSON.parse(action.payload.personAccount.industryPreferences),
      trendPreferences:
        action.payload?.personAccount?.trendPreferences && JSON.parse(action.payload.personAccount.trendPreferences),
      skillPreferences:
        action.payload?.personAccount?.skillPreferences && JSON.parse(action.payload.personAccount.skillPreferences),
      shortcuts: action.payload?.personAccount?.shortcuts && JSON.parse(action.payload.personAccount.shortcuts),
      goldenRecordFetched: true,
      profileFetched: true,
      profileFetchedSuccess: action.payload?.success,
      hasFirmBillingInvite: action.payload?.hasFirmBillingInvite,
      quoteStatusAndIsMembershipPaidByFirm: action.payload?.quoteStatusAndIsMembershipPaidByFirm,
      hasFirmBillingRelation: action.payload?.hasFirmBillingRelation,
      hasCimaFirmBillingRelation: action.payload?.hasCimaFirmBillingRelation,
      hasActiveQuoteFromFirm: action.payload?.hasActiveQuoteFromFirm,
      userUnpaidFirmBillingInvoices: action.payload?.userUnpaidFirmBillingInvoices,
      exams: action.payload?.exams,
      tlw: {
        ...state.tlw,
        id: action.payload?.tlw?.id,
        isTLW: action.payload?.tlw?.id && true,
        tlwReason: action.payload?.tlw?.reason,
        tlwEndDate: action.payload?.tlw?.endDate,
      },
      data: {
        ...state.data,
        ...action.payload.me,
      },
      userFetched: true,
      loading: false,
      studentProgression: action.payload?.studentProgression,
      studentExamSectionResults: action.payload?.studentExamSectionResults,
      zuoraCutOverDate: action.payload?.zuoraCutOverDate || '',
    };
  },
  [getEPA2Submissions]: (state: State.User, action: any): any => {
    return {
      ...state,
      EPA2Submissions: {
        ...state.EPA2Submissions,
        reflectiveStatements: action.payload?.results?.reflectiveStatements,
        portfolioEvidence: action.payload?.results?.portfolioEvidence,
        case: action.payload?.results?.case,
        canUserAccess: action.payload?.results?.canUserAccess,
        canUserSubmit: action.payload?.results?.canUserSubmit,
        checkExamCreditAndExternalValidator: action.payload?.results?.checkExamCreditAndExternalValidator,
      },
    };
  },
  [getSpecialConsiderationsExams]: (state: State.User, action: any): any => {
    return {
      ...state,
      specialConsiderationsExams: {
        results: action.payload,
      },
    };
  },
  [createSpecialConsiderationLoading]: (state: State.User): State.User => {
    return {
      ...state,
      createSpecialConsiderationLoading: true,
    };
  },
  [createSpecialConsideration]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      specialConsiderationInfo: {
        ...state.specialConsiderationInfo,
        firstName: action.payload?.createSpecialConsideration?.firstName,
        lastName: action.payload?.createSpecialConsideration?.lastName,
        appointmentId: action.payload?.createSpecialConsideration?.appointmentId,
        examKey: action.payload?.createSpecialConsideration?.examKey,
        examDate: action.payload?.createSpecialConsideration?.examDate,
        examDeliveryMethod: action.payload?.createSpecialConsideration?.examDeliveryMethod,
        testCenter: action.payload?.createSpecialConsideration?.testCenter,
        scReason: action.payload?.createSpecialConsideration?.scReason,
        pVCaseId: action.payload?.createSpecialConsideration?.pVCaseId,
        reasonText: action.payload?.createSpecialConsideration?.reasonText,
        documentType: action.payload?.createSpecialConsideration?.documentType,
        problemEncountered: action.payload?.createSpecialConsideration?.problemEncountered,
        problemStartDate: action.payload?.createSpecialConsideration?.problemStartDate,
        problemLength: action.payload?.createSpecialConsideration?.problemLength,
        problemImpact: action.payload?.createSpecialConsideration?.problemImpact,
        proctorReportFlag: action.payload?.createSpecialConsideration?.proctorReportFlag,
        proctorAdvice: action.payload?.createSpecialConsideration?.proctorAdvice,
        attestationText: action.payload?.createSpecialConsideration?.attestationText,
        personAccountId: action.payload?.createSpecialConsideration?.personAccountId,
      },
    };
  },
  [createSpecialConsiderationSuccess]: (state: State.User): State.User => {
    return {
      ...state,
      createSpecialConsiderationSuccess: true,
      createSpecialConsiderationLoading: false,
    };
  },
  [getEPA2L7Submissions]: (state: State.User, action: any): any => {
    return {
      ...state,
      EPA2L7Submissions: {
        ...state.EPA2L7Submissions,
        projectReportData: action.payload?.results?.projectReportData,
        case: action.payload?.results?.case,
        canUserAccess: action.payload?.results?.canUserAccess,
        canUserSubmit: action.payload?.results?.canUserSubmit,
        checkExamCreditAndExternalValidator: action.payload?.results?.checkExamCreditAndExternalValidator,
      },
    };
  },
  [updateEPA2L4CaseStatus]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      epa2L4CaseStatusUpdate: {
        ...state.epa2L4CaseStatusUpdate,
        ...action.payload.epa2L4CaseStatusUpdate,
      },
    };
  },
  [updateEPA2L7CaseStatus]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      epa2L7CaseStatusUpdate: {
        ...state.epa2L7CaseStatusUpdate,
        ...action.payload.epa2L7CaseStatusUpdate,
      },
    };
  },
  [updatePersonAccount]: (state: State.User, action: any): State.User => ({
    ...state,
    personAccountData: {
      ...state.personAccountData,
      ...action.payload.updatePersonAccount,
      personAccountDataUpdateSuccess: action.payload.success,
      personAccountDataUpdateLoading: false,
      isAddressValid: !action.payload.success,
      smartystreetsValidation: state.personAccountData.smartystreetsValidation,
      isSecondaryAddressNeeded: state.personAccountData.isSecondaryAddressNeeded,
      isAdressValidationLoading: state.personAccountData.isAdressValidationLoading,
    },
  }),

  [updateAccountLoading]: (state: State.User, action: any): State.User => ({
    ...state,
    personAccountData: { ...state.personAccountData, personAccountDataUpdateLoading: true },
  }),

  [upgradeLearningPathway]: (state: State.User, action: any): State.User => ({
    ...state,
    personAccountData: {
      ...state.personAccountData,
      learningPathway: action.payload.learningPathwayToSwitch,
      personAccountDataUpdateLoading: false,
    },
  }),

  [validatePersonAddress]: (state: State.User, action: any): State.User => {
    const { smartystreetsValidation, isShippingAddressValid, skipped, secondaryAddressNeeded } = action.payload;

    return {
      ...state,
      personAccountData: {
        ...state.personAccountData,
        smartystreetsValidation: {
          ...(smartystreetsValidation as State.SmartyAddressValidationState),
          smartystreetsValidationFetched: true,
        },
        isAddressValid: isShippingAddressValid,
        smartystreetsValidationSkipped: skipped,
        isAdressValidationLoading: false,
        isSecondaryAddressNeeded: secondaryAddressNeeded,
      },
    };
  },

  [validatePersonAddressLoading]: (state: State.User, action: any): State.User => ({
    ...state,
    personAccountData: { ...state.personAccountData, isAdressValidationLoading: true },
  }),

  [updatePersonalInformation]: (state: State.User, action: any): State.User => ({
    ...state,
    personAccountData: {
      ...state.personAccountData,
      ...action.payload.updatePersonAccount,
      personAccountDataUpdateSuccess: action.payload.success,
      personAccountDataUpdateLoading: false,
      isAddressValid: !action.payload.success,
      smartystreetsValidation: state.personAccountData.smartystreetsValidation,
      isSecondaryAddressNeeded: state.personAccountData.isSecondaryAddressNeeded,
      isAdressValidationLoading: state.personAccountData.isAdressValidationLoading,
    },
    qualificationData: {
      ...state.qualificationData,
      cpaLicenseData: {
        ...state.qualificationData.cpaLicenseData,
        ...action.payload?.processQualification?.cpaLicense,
        cpaLicenseDataUpdateSuccess: action.payload.success,
        cpaLicenseDataUpdateLoading: false,
      },
      cpaLicenseListData: {
        ...state.qualificationData.cpaLicenseListData,
        cpaLicense: action.payload?.cpaLicenses || [],
      },
    },
  }),

  [updatePersonalInformationLoading]: (state: State.User, action: any): State.User => ({
    ...state,
    personAccountData: {
      ...state.personAccountData,
      personAccountDataUpdateLoading: true,
    },
    qualificationData: {
      ...(state.qualificationData || initialState.qualificationData),
      cpaLicenseData: {
        ...state.qualificationData.cpaLicenseData,
        cpaLicenseDataUpdateLoading: true,
      },
    },
  }),

  [validateOrganizationAddress]: (state: State.User, action: any): State.User => {
    const { smartystreetsValidation, isShippingAddressValid, skipped, secondaryAddressNeeded } = action.payload;

    return {
      ...state,
      employmentData: {
        ...state.employmentData,
        smartystreetsValidation: {
          ...(smartystreetsValidation as State.SmartyAddressValidationState),
          smartystreetsValidationFetched: true,
        },
        isAddressValid: isShippingAddressValid,
        smartystreetsValidationSkipped: skipped,
        isAdressValidationLoading: false,
        isSecondaryAddressNeeded: secondaryAddressNeeded,
      },
    };
  },

  [validateOrganizationAddressLoading]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: { ...state.employmentData, isAdressValidationLoading: true },
  }),

  [clearSmartyStreetsSecondaryAddressValidation]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      isSecondaryAddressNeeded: false,
    },
  }),
  [processQualification]: (state: State.User, action: any): State.User => ({
    ...state,
    qualificationData: {
      ...(state.qualificationData || initialState.qualificationData),
      cpaLicenseData: {
        ...(action.payload?.processQualification?.cpaLicense || state.qualificationData.cpaLicenseData),
        cpaLicenseDataUpdateSuccess: action.payload.success,
        cpaLicenseDataUpdateLoading: false,
      },
    },
    employmentData: {
      ...state.employmentData,
      isEmploymentPageSkipped: action.payload?.skipEmploymentPage,
    },
  }),

  [processQualificationLoading]: (state: State.User, action: any): State.User => ({
    ...state,
    qualificationData: {
      ...(state.qualificationData || initialState.qualificationData),
      cpaLicenseData: { ...state.qualificationData.cpaLicenseData, cpaLicenseDataUpdateLoading: true },
    },
  }),

  [clearNewEmployerData]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      newEmployer: initialState.employmentData.newEmployer,
    },
  }),

  [clearEmployerDataEmployers]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      employers: initialState.employmentData.employers,
    },
  }),

  [upsertEmploymentDataLoading]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      employmentDataUpdateSuccess: false,
      employmentDataUpdateLoading: true,
    },
  }),

  [resetEmploymentDataEffect]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      employmentDataUpdateSuccess: false,
      employmentDataUpdateLoading: false,
    },
  }),

  [upsertEmploymentData]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...(action.payload?.updateEmploymentData || state.employmentData),
      employers: action.payload.processEmployment || action.payload?.updateEmployment,
      employmentDataUpdateSuccess: action.payload?.success,
      employmentDataUpdateLoading: false,
    },
  }),

  [removeEmploymentData]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      employers: action.payload.employers,
      employmentDataUpdateSuccess: action.payload?.success,
      employmentDataUpdateLoading: false,
    },
  }),

  [upsertEmploymentDataStatus]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      ...(action.payload?.updateEmploymentData || action.payload?.updateEmployment),
      employers: action.payload.updateAccountPersonAccountStatus,
      employmentDataUpdateSuccess: action.payload?.success,
      employmentDataUpdateLoading: false,
      employerAlreadyExists: action.payload.employerExists,
    },
  }),

  [searchOrganizationsByWildcardLoading]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      searchOrganizationsByWildcardLoading: true,
      employerAlreadyExists: false,
    },
  }),
  [searchEmployer]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...(state.employmentData || initialState.employmentData),
      searchResultOrganizations: action.payload?.searchEmployer || state.employmentData.searchResultOrganizations,
      searchOrganizationsByWildcardLoading: false,
      employerAlreadyExists: false,
    },
  }),
  [searchOrganizationsByWildcard]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...(state.employmentData || initialState.employmentData),
      searchResultOrganizations:
        action.payload?.searchOrganizationsByName || state.employmentData.searchResultOrganizations,
      searchOrganizationsByWildcardLoading: false,
      employerAlreadyExists: false,
    },
  }),
  [searchOrganizationsCitiesLoading]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      searchOrganizationsCitiesLoading: true,
    },
  }),
  [searchOrganizationsCities]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...(state.employmentData || initialState.employmentData),
      searchResultOrganizationsCities:
        action.payload?.searchOrganizationsByName || state.employmentData.searchResultOrganizationsCities,
      searchOrganizationsCitiesLoading: false,
    },
  }),
  [getOrganizationByNameAndCity]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      newEmployer: {
        ...state.employmentData.newEmployer,
        organization: action.payload,
      },
      searchOrganizationsCitiesLoading: false,
    },
  }),
  [checkIfOrganizationExists]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      employerAlreadyExists: action.payload,
      searchOrganizationsByWildcardLoading: false,
    },
  }),
  [searchUniversitiesByWildcardLoading]: (state: State.User, action: any): State.User => ({
    ...state,
    qualificationData: {
      ...(state.qualificationData || initialState.qualificationData),
      candidateData: {
        ...(state.qualificationData.candidateData || initialState.qualificationData.candidateData),
        searchUniversitiesLoading: true,
      },
    },
  }),
  [searchUniversitiesByWildcard]: (state: State.User, action: any): State.User => ({
    ...state,
    qualificationData: {
      ...(state.qualificationData || initialState.qualificationData),
      candidateData: {
        ...(state.qualificationData.candidateData || initialState.qualificationData.candidateData),
        searchResultUniversities:
          action.payload?.searchOrganizationsByName || state.qualificationData.candidateData.searchResultUniversities,
        searchUniversitiesLoading: false,
      },
    },
  }),
  [getUniversityDetails]: (state: State.User, action: any): State.User => ({
    ...state,
    qualificationData: {
      ...(state.qualificationData || initialState.qualificationData),
      candidateData: {
        ...(state.qualificationData.candidateData || initialState.qualificationData.candidateData),
        university: action.payload,
        searchUniversitiesLoading: false,
      },
    },
  }),
  [getOrganizationDataByAccountNumber]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      employmentDataFromPromoFetched: true,
      employmentDataFromPromoFetchSuccess: action.payload?.success,
      newEmployer: {
        ...state.employmentData.newEmployer,
        organization: action.payload?.getOrganizationByAccountNumberOrAccountId,
      },
    },
  }),
  [updateApplication]: (state: State.User, action: any): State.User => ({
    ...state,
    application: {
      ...state.application,
      ...(action.payload?.processApplication || initialState.application),
    },
  }),
  [updateCurrentLearningPathway]: (state: State.User, action: any): State.User => ({
    ...state,
    currentJourneyLearningPathway: action?.payload?.learningPathway,
    application: {
      ...state.application,
      ...(action.payload?.applicationId
        ? {
            applicationPart: {
              ...state.application.applicationPart,
              currentLearningPathway: action.payload?.learningPathway,
            },
          }
        : {
            id: action.payload?.application?.id,
            personAccountId: action.payload?.application?.personAccountId,
            status: action.payload?.application?.status,
            applicationPart: {
              ...state.application?.applicationPart,
              id: action.payload?.application?.applicationPart?.id,
              currentLearningPathway: action.payload?.learningPathway,
            },
          }),
    },
  }),
  // TODO: adjust when agreed a user has multiple application and there's a flag on each application.
  [getApplications]: (state: State.User, action: any): State.User => ({
    ...state,
    application: {
      ...state.application,
      ...(action.payload || initialState.application),
    },
  }),
  [processTLW]: (state: State.User, action: any): State.User => ({
    ...state,
    tlw: action.payload,
  }),
  [removeTLW]: (state: State.User, action: any): State.User => ({
    ...state,
    tlw: {
      id: '',
      isTLW: false,
      tlwReason: '',
      tlwEndDate: '',
      employmentStatus: '',
    },
  }),
  [setApplicationInitialLoad]: (state: State.User, action: any): State.User => ({
    ...state,
    applicationInitialLoad: action.payload,
  }),
  [toggleApplicationInitialLoad]: (state: State.User, action: any): State.User => ({
    ...state,
    applicationInitialLoad: false,
  }),
  [setPersonalSuggestedAddress]: (state: State.User, action: any): State.User => ({
    ...state,
    personAccountData: {
      ...state.personAccountData,
      suggestedAddress: {
        ...state.personAccountData.suggestedAddress,
        ...action.payload,
      },
    },
  }),
  [setOrganizationSuggestedAddress]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      suggestedAddress: {
        ...state.personAccountData.suggestedAddress,
        ...action.payload,
      },
    },
  }),
  // center membership
  [setMembershipPackageOrganization]: (state: State.User, action: any): State.User => ({
    ...state,
    centerMembershipApplication: {
      ...state.centerMembershipApplication,
      organization: action.payload,
    },
  }),
  [setMembershipPackageOrganizationIssuer]: (state: State.User, action: any): State.User => ({
    ...state,
    centerMembershipApplication: {
      ...state.centerMembershipApplication,
      organization: {
        ...state.centerMembershipApplication.organization,
        numberOfCpa: action.payload.numberOfCpa,
        PCAOBNumOfIssuers: action.payload.PCAOBNumOfIssuers,
        isPCAOBOrganization: action.payload.isPCAOBOrganization,
      },
    },
  }),
  [setMembershipApplicationAdminDetail]: (state: State.User, action: any): State.User => ({
    ...state,
    centerMembershipApplication: {
      ...state.centerMembershipApplication,
      adminDetail: action.payload,
    },
  }),
  [setMembershipApplicationOrganizationDetail]: (state: State.User, action: any): State.User => ({
    ...state,
    centerMembershipApplication: {
      ...state.centerMembershipApplication,
      organization: {
        ...state.centerMembershipApplication.organization,
        numberOfCpaOwners: action.payload.numberOfCpaOwners,
        numberOfNonCpaOwners: action.payload.numberOfNonCpaOwners,
        numberOfTotalGovtAudits: action.payload.numberOfTotalGovtAudits,
        numberOfSingleAudits: action.payload.numberOfSingleAudits,
      },
    },
  }),
  [setMembershipApplicationQuestion]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      centerMembershipApplication: {
        ...state.centerMembershipApplication,
        applicationObject: {
          ...state.centerMembershipApplication.applicationObject,
          applicationPart: {
            ...state.centerMembershipApplication.applicationObject.applicationPart,
            hearAboutUsMembershipQuestion: action.payload,
          },
        },
      },
    };
  },
  [updateOrganizationDetails]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      centerMembershipApplication: {
        ...state.centerMembershipApplication,
        organizationUpdateSuccess: action.payload,
      },
    };
  },
  [clearAttestation]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      attestation: initialState.attestation,
    };
  },
  [updateAttestation]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      attestation: action.payload,
    };
  },
  [updateApplicationPart]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      application: {
        ...state.application,
        applicationPart: {
          ...state.application.applicationPart,
          ...action.payload,
        },
      },
    };
  },
  [createFirmMembershipWithAttestation]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      centerMembershipApplication: {
        ...state.centerMembershipApplication,
        adminDetail: action.payload.adminDetail,
        applicationObject: action.payload.applicationObject,
        accountPersonAccountId: action.payload.accountPersonAccountId,
      },
    };
  },
  [updateAccountAsPartner]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      employmentData: {
        ...state.employmentData,
        employers: action.payload,
      },
    };
  },
  [getSecurityQuestion]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      securityQuestion: {
        ...state.securityQuestion,
        security: {
          ...state.securityQuestion.security,
          ...action.payload,
        },
        loading: false,
        error: {
          ...action.payload,
        },
      },
    };
  },
  [updateSecurityResponse]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      securityQuestion: {
        ...state.securityQuestion,
        security: {
          ...state.securityQuestion.security,
          ...action.payload,
        },
        loading: false,
        error: {
          ...action.payload,
        },
      },
    };
  },
  [getFlpResellerDataByAccountNumber]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      trainingProviderDataFromPromoFetched: true,
      trainingProviderDataFromPromoFetchSuccess: action.payload?.success,
      newFlpReseller: {
        ...state.employmentData.newFlpReseller,
        organization: action.payload?.getOrganizationByAccountNumberOrAccountId,
      },
    },
  }),
  [getTrainingProviderDataByAccountNumber]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      flpResellerDataFromPromoFetched: true,
      flpResellerDataFromPromoFetchSuccess: action.payload?.success,
      newTrainingProvider: {
        ...state.employmentData.newTrainingProvider,
        organization: action.payload?.getOrganizationByAccountNumberOrAccountId,
      },
    },
  }),

  [clearOrgazanizationFromPromoCode]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      ...action.payload,
    },
  }),

  [searchOrganizationsByNewTrainingProvider]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      newTrainingProvider: {
        ...state.employmentData.newTrainingProvider,
        organization: action.payload,
      },
      searchOrganizationsCitiesLoading: false,
    },
  }),
  [setNewEmployerJobTitle]: (state: State.User, action: any): State.User => ({
    ...state,
    employmentData: {
      ...state.employmentData,
      newEmployer: {
        ...state.employmentData.newEmployer,
        jobTitle: action.payload,
      },
    },
  }),
  [getStudentProgressionData]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      loading: false,
      studentProgression: { ...action.payload.getStudentExamProgression, isFetched: true },
    }),
    throw: (state: State.User, action: any): State.User => ({
      ...state,
      error: {
        ...action.payload,
      },
      loading: false,
    }),
  },
  [resetStudentExamSectionResults]: (state: State.User): State.User => ({
    ...state,
    loading: false,
    studentExamSectionResults: initialState.studentExamSectionResults,
  }),
  [getStudentExamSectionResultsData]: {
    next: (state: State.User, action: any): State.User => {
      return {
        ...state,
        loading: false,
        studentExamSectionResults: action.payload.getStudentExamSectionResults,
      };
    },
    throw: (state: State.User, action: any): State.User => ({
      ...state,
      error: {
        ...action.payload,
      },
      loading: false,
    }),
  },
  [setStudentLearningPathwayToSwitch]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      studentProgression: {
        ...state.studentProgression,
        learningPathwayToSwitch: action.payload,
      },
    };
  },
  [getSFMCLink]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      sfmcLink: action.payload?.getSFMCLink || '',
    };
  },
  [setCurrentUserPrimaryAddress]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      personAccountData: {
        ...state.personAccountData,
        address: {
          ...state.personAccountData.address,
          ...action.payload.address,
        },
      },
    };
  },
  [setEventRegistrationData]: (state: State.User, action: any): State.User => {
    const amountPaid = action.payload?.eventTypeItem?.[0]?.price?.centAmount / 100;
    return {
      ...state,
      eventRegistrationData: {
        ...state.eventRegistrationData,
        pricePaid: String(amountPaid),
        methodOfPayment: 'Credit Card', // TODO: Update methodOfPayment Selector
        event: action.payload?.eventTypeItem[0]?.name,
        eventDateAndTime: action.payload?.eventTypeItem[0]?.variant?.attributes?.startDateTime,
        eventLocation: action.payload?.eventTypeItem[0]?.variant?.attributes?.locations,
        dateOfBooking: action.payload?.eventTypeItem[0]?.orderDate,
      },
      eventDataFetchedSuccess: true,
    };
  },
  [createEventRegistrationLoading]: (state: State.User): State.User => {
    return {
      ...state,
      createEventRegistrationLoading: true,
    };
  },
  [createEventRegistration]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      eventRegistrationData: {
        ...state.eventRegistrationData,
        id: action.payload?.createEventRegistration?.id,
        personAccountId: action.payload?.eventRegistrationInfo?.personAccountId,
        firstName: action.payload?.eventRegistrationInfo?.firstName,
        lastName: action.payload?.eventRegistrationInfo?.lastName,
        emailContactId: action.payload?.eventRegistrationInfo?.emailContactId,
        jobTitle: action.payload?.eventRegistrationInfo?.jobTitle,
        employer: action.payload?.eventRegistrationInfo?.employer,
      },
    };
  },
  [createEventRegistrationSuccess]: (state: State.User): State.User => {
    return {
      ...state,
      createEventRegistrationSuccess: true,
      createEventRegistrationLoading: false,
    };
  },
  [updateAddressBook]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      allAddresses: action.payload,
    };
  },
  [updateUserAddressList]: (state: State.User, action: any): State.User => {
    const filteredAddress: State.SalesforceAddress[] = state.allAddresses?.filter(
      address => address.id !== action.payload
    );
    return {
      ...state,
      allAddresses: filteredAddress,
    };
  },
  [addSalesforceAddress]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      allAddresses: action.payload || [...state.allAddresses],
    };
  },

  [selectAllSubskills]: (state: State.User, action: any): State.User => {
    if (!action.payload) return state;
    return {
      ...state,
      skillPreferences: {
        ...state.skillPreferences,
        ...action.payload,
      },
    };
  },

  [toggleSubskillPref]: (state: State.User, action: any): State.User => ({
    ...state,
    skillPreferences: action.payload || initialState.skillPreferences,
  }),

  [backupSubskills]: (state: State.User): State.User => ({
    ...state,
    previousSkillPreferences: { ...state.skillPreferences },
  }),

  [resetSubskills]: (state: State.User): State.User => ({
    ...state,
    skillPreferences: { ...state.previousSkillPreferences },
  }),

  [clearAllSubskills]: (state: State.User, action: any): State.User => {
    if (!action.payload) return state;
    const skillPreferences = Object.keys(state.skillPreferences).reduce((acc: any, key: string) => {
      if (key === action.payload) return acc;
      acc[key] = state.skillPreferences[key];
      return acc;
    }, {});

    return {
      ...state,
      skillPreferences,
    };
  },
  [checkUserCountryIfEmbargoed]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      isUserCountryEmbargoed: action.payload?.checkUserCountryIfEmbargoed?.embargoed || false,
    };
  },
  [getUserLocation]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      userLocation: action.payload,
    };
  },
  [updateProfessionalStatus]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      professionalStatus: action.payload,
    };
  },
  [updateYearOfGraduation]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      yearOfGraduation: action.payload,
    };
  },
  [setRegistrationLoading]: (state: State.User, action: any): State.User => {
    return {
      ...state,
      registrationLoading: action.payload,
    };
  },
  [setHasPreferredCurrency]: (state: State.User, action: any): State.User => ({
    ...state,
    hasPreferredCurrency: true,
  }),
  [updateAccountUniversityDetails]: (state: State.User, action: any): State.User => ({
    ...state,
    personAccountData: {
      ...state.personAccountData,
      yearOfGraduation: action.payload?.yearOfGraduation,
      conditionalExemptionStudent: action.payload?.conditionalExemptionStudent,
    },
  }),
  [getInactiveMembershipSlug]: (state: State.User, action: any): State.User => ({
    ...state,
    inactiveMemberships: [{ ...state.inactiveMemberships?.[0], slug: action.payload?.getESProductBySku?.slug }],
  }),
  [getUserSyllabusByPersonAccountId]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      allExamResults: {
        ...state.allExamResults,
        syllabus: {
          results: action.payload?.syllabus,
          loading: true,
        },
      },
    }),
    throw: (state: State.User, action: any): State.User => ({
      ...state,
      error: {
        ...action.payload,
      },
      loading: false,
    }),
  },
  [getStudentExamHistoryData]: {
    next: (state: State.User, action: any): State.User => ({
      ...state,
      allExamResults: {
        ...state.allExamResults,
        allExams: {
          results: action.payload?.getStudentExamHistory,
          loading: true,
        },
      },
    }),
    throw: (state: State.User, action: any): State.User => ({
      ...state,
      error: {
        ...action.payload,
      },
      loading: false,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
