import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { isServer } from 'utils';
import { setSiteEntry } from 'modules/startup/index';

export const useSaveQueryParams = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => qs.parse(search), [search]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isServer) {
      async function process() {
        dispatch(setSiteEntry({ page: window.location.href, params: queryParams }));
      }
      process();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
