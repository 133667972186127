import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import { MembershipTypes, User, Salesforce } from 'mxp-schemas';
import {
  isStudentProgressionFetchedSelector,
  studentProgressionSelector,
  exemptionsStatusSelector,
} from 'modules/user/selectors';
import { uploadedDocumentsFetchedSelector, uploadedDocumentsSelector } from 'modules/uploadedDocuments/selectors';
import { exemptionReferenceSelector } from 'modules/allRequiredDocuments/selectors';
import { ExemptionCardProps } from 'components/molecules/CimaExemptionBasedCard/CimaExemptionBasedCard';

const rootSelector = createSelector(
  (state: State.Root) => state.personLevelExemptions,
  (personLevelExemptions: State.PersonLevelExemptions): State.PersonLevelExemptions => personLevelExemptions
);

export const personLevelExemptionsSelector = rootSelector;

export const personLevelExemptionFetchedSelector = createSelector(
  personLevelExemptionsSelector,
  (personLevelExemption: State.PersonLevelExemptions): boolean => personLevelExemption.isFetched
);

export const isDataLoadedSelector = createSelector(
  personLevelExemptionFetchedSelector,
  isStudentProgressionFetchedSelector,
  uploadedDocumentsFetchedSelector,
  (
    personLevelExemptionFetched: boolean,
    isStudentProgressionFetched: boolean,
    uploadedDocumentsFetched: boolean
  ): boolean => personLevelExemptionFetched && isStudentProgressionFetched && uploadedDocumentsFetched
);

export const combinedDocumentNamesSelector = createSelector(
  isDataLoadedSelector,
  personLevelExemptionsSelector,
  exemptionReferenceSelector,
  (
    isDataLoaded: boolean,
    personLevelExemptions: State.PersonLevelExemptions,
    exemptionReference: State.AllRequiredDocuments
  ): any => {
    if (!isDataLoaded) return;

    // // Get the level of each exemptions
    const exemptionLevels = personLevelExemptions?.getPersonLevelExemptions?.map(
      (item: any) =>
        Number(item.SFS_PLE_ExemptionKey__r?.SFS_EXR_ExemptionLevelKey__r?.Name?.replace('Level ', '')) || 0
    );

    const exemptionLevelsNoRefresh = exemptionReference.exempReference?.map(
      (item: any) => Number(item.SFS_EXR_ExemptionLevelKey__r?.Name?.replace('Level ', '')) || 0
    );

    // Get the highest level from those exemptions
    const getExemptionsMaximumLevel = Math.max.apply(
      Math,
      exemptionLevels?.map((maximumLevel): number => maximumLevel)
    );

    // Get the highest level from those exemptions
    const getExemptionsMaximumLevelNoRefresh = Math.max.apply(
      Math,
      exemptionLevelsNoRefresh?.map((maximumLevel: any) => maximumLevel)
    );

    // Create an array for Required Document based on the highest level (Will combine the required documents if they have the same level)
    const getAllDocumentNamesFilteredByHighestLevel: any = [];

    const getAllDocumentNames: any = [];
    if (exemptionReference.exempReference?.length > 0) {
      exemptionReference.exempReference?.forEach((item: any) => {
        if (item.SFS_EXR_ExemptionLevelKey__r.Name.includes(getExemptionsMaximumLevelNoRefresh.toString())) {
          item.SFS_EXR_RequiredDocument__c?.split(';')?.forEach((name: string) => {
            getAllDocumentNamesFilteredByHighestLevel?.push(name);
          });
        }
      });
    } else if (personLevelExemptions && personLevelExemptions?.getPersonLevelExemptions?.length > 0) {
      personLevelExemptions?.getPersonLevelExemptions?.forEach((item: any) => {
        if (
          item.SFS_PLE_ExemptionKey__r?.SFS_EXR_ExemptionLevelKey__r?.Name.includes(
            getExemptionsMaximumLevel.toString()
          )
        ) {
          item.SFS_PLE_ExemptionKey__r?.SFS_EXR_RequiredDocument__c?.split(';').forEach((name: string) => {
            getAllDocumentNamesFilteredByHighestLevel?.push(name);
          });
        }
      });
    }
    getAllDocumentNames?.push(...getAllDocumentNamesFilteredByHighestLevel);
    const uniqueDocumentNames = getAllDocumentNames?.filter(
      (val: any, id: any, array: any) => array.indexOf(val) === id
    );
    return uniqueDocumentNames;
  }
);

export const isCimaCertBAQualificationExemptedSelector = createSelector(
  studentProgressionSelector,
  (studentProgression: State.StudentProgression): boolean =>
    studentProgression?.qualificationLevels.some(
      qualification =>
        qualification.name === MembershipTypes.CimaQualificationLevelPathValue.CertBa &&
        qualification.status === User.QualificationLevelStatus.Exempted
    )
);

export const hasExemptionWithConditionSelector = createSelector(
  isCimaCertBAQualificationExemptedSelector,
  exemptionsStatusSelector,
  (
    isCimaCertBAQualificationExempted: boolean,
    exemptionStatus: State.StudentProgression['exemptionsStatus']
  ): boolean => Boolean(isCimaCertBAQualificationExempted || exemptionStatus?.entryPointLevel)
);

export const exemptionCardListSelector = createSelector(
  studentProgressionSelector,
  hasExemptionWithConditionSelector,
  combinedDocumentNamesSelector,
  uploadedDocumentsSelector,
  (
    studentProgression: State.StudentProgression,
    hasExemptionsWithCondition: boolean,
    combinedDocumentNames: string,
    document: State.UploadedDocument
  ): ExemptionCardProps[] => {
    const exemptionCardDataList: ExemptionCardProps[] = [];
    const studentExemptionStatus = studentProgression.exemptionsStatus;
    const studentGatewayStatus = studentProgression?.gatewayStatus;
    const documentsUploadedList = document?.uploadedDocuments
      ? Array.from(document?.uploadedDocuments)?.map((item: any) => item?.Name?.replace(/\s/g, '').split('-')[0])
      : [];

    if (hasExemptionsWithCondition && studentExemptionStatus && combinedDocumentNames?.length) {
      exemptionCardDataList.push({
        cardId: studentExemptionStatus?.exemptionReferenceId ?? '',
        cardType: 'Exemption',
        exemptionHeading: studentExemptionStatus?.exemptionRecordType ?? '',
        exemptionLevel: studentExemptionStatus?.selectedLevel ?? '',
        isDocumentSubmissionComplete: studentExemptionStatus?.status === User.ExemptionLevelStatus.Completed,
      });
    }
    if (
      hasExemptionsWithCondition &&
      !documentsUploadedList?.length &&
      studentProgression &&
      !exemptionCardDataList?.length &&
      studentExemptionStatus &&
      combinedDocumentNames?.length
    ) {
      exemptionCardDataList.push({
        cardId: studentExemptionStatus?.exemptionReferenceId ?? '',
        cardType: 'Exemption',
        exemptionHeading: studentExemptionStatus?.exemptionRecordType ?? '',
        exemptionLevel: studentExemptionStatus?.selectedLevel ?? '',
        isDocumentSubmissionComplete: studentExemptionStatus?.status === User.ExemptionLevelStatus.Completed,
      });
    }
    if (studentGatewayStatus && studentGatewayStatus?.enrollmentStatus !== Salesforce.CimaStatus.REJECTED) {
      exemptionCardDataList.push({
        cardId: studentGatewayStatus.gatewayId,
        cardType: 'Gateway',
        gatewayStatus: studentGatewayStatus.status as string,
        isDocumentSubmissionComplete: studentGatewayStatus.documentStatus === User.GatewayDocumentStatus.Submitted,
        gatewayPassedDate: studentGatewayStatus.actualGatewayCompletionDate?.length
          ? moment(studentGatewayStatus.actualGatewayCompletionDate).format('DD/MM/YYYY')
          : '',
      });
    }

    return exemptionCardDataList;
  }
);
