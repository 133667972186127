import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import request from 'utils/GraphQLClient';
import { GraphqlTypes } from 'mxp-schemas';
import { QUERY_PICK_LIST } from 'mxp-graphql-queries';

export const pickListLoading: any = createAction('pickLists/LOADING');

export const getPickList: any = createAction(
  'pickLists/GET_PICK_LIST',
  (pickListId: string, sort?: GraphqlTypes.SortOrder) => async (dispatch: Dispatch) => {
    dispatch(pickListLoading());
    const response = await request(QUERY_PICK_LIST, { pickListId, sort });

    const { getPickList } = response;
    if (!getPickList) {
      throw new Error(`Pick list with id=${pickListId} not found`);
    }
    return getPickList;
  }
);
