import React from 'react';
import { Container, Heading } from 'components/atoms';
import { ButtonEnums, Button } from 'components/atoms/Button/Button';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import styled from 'styled-components';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { RouteComponentProps } from 'react-router';
import { BackLink } from 'components/molecules/BackLink/BackLink';
import { generatePath } from 'react-router-dom';

export const AdminErrorPage: React.FC<RouteComponentProps> = ({ history }) => {
  const {
    location: { key },
  } = history;
  const back = history.goBack;
  const push = history.push;

  const backToAdminRoot: boolean = (history?.location?.state as { backToAdminRoot: boolean })?.backToAdminRoot;

  const previousPage = React.useCallback(() => {
    if (backToAdminRoot || !key) {
      push(getPath(Routes.ADMIN_ROOT, { withoutParams: true }));
    } else {
      back();
    }
  }, [back, push, key, backToAdminRoot]);
  return (
    <Container>
      {backToAdminRoot ? (
        <BackLink
          testId="back-to-client-admins"
          to={{ pathname: generatePath(getPath(Routes.ADMIN_ROOT), { withoutParams: true }) }}
        >
          Back to search
        </BackLink>
      ) : (
        <PreviousPage backText="Back to search" />
      )}

      <StyledHeader as="h1">Uh oh. Something went wrong.</StyledHeader>

      <StyledErrorText>We’re sorry. The Products & Purchases Console is currently unavailable.</StyledErrorText>
      <StyledErrorText>
        Please revert to using your usual source systems. You can also return to the search page using the button below.
      </StyledErrorText>

      <StyledSearchAgainButton
        size={ButtonEnums.sizes.medium}
        variant={ButtonEnums.variants.primary}
        testId="return-to-admin-page"
        onClick={previousPage}
      >
        Search again
      </StyledSearchAgainButton>
    </Container>
  );
};

const StyledHeader = styled(Heading)`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledErrorText = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-bottom: ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(380)};
`;

const StyledSearchAgainButton = styled(Button)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(24)};
    margin-top: ${props => props.theme.pxToRem(10)};
  }
`;
