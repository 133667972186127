import styled from 'styled-components';
import React from 'react';
import { ActionResult } from 'constants/firm-admin';
import { ReactComponent as IconCheck } from 'resources/images/ic-check.svg';
import { ReactComponent as IconExclamation } from 'resources/images/ic-exclamation.svg';

interface Props {
  resultTitle: string | JSX.Element;
  resultHelperText?: string;
  status: ActionResult;
  showResult: boolean;
}

export const ActionResultModal: React.FC<Props> = ({ resultTitle, resultHelperText, status, showResult = false }) => {
  if (!showResult) return null;
  return (
    <StyledContainer>
      <StyledResultContainer>
        <StyledIconContainer>
          {status === ActionResult.Success ? <StyledIconCheck /> : <StyledIconExclamation />}
        </StyledIconContainer>

        <>
          <StyledResultTitle>{resultTitle}</StyledResultTitle>

          {resultHelperText && <StyledResultText>{resultHelperText}</StyledResultText>}
        </>
      </StyledResultContainer>
    </StyledContainer>
  );
};

const StyledResultContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledIconCheck = styled(IconCheck)`
  width: ${props => props.theme.pxToRem(40)};
  height: ${props => props.theme.pxToRem(40)};
  path {
    fill: ${props => props.theme.colors.interfaceGreen};
  }
`;

const StyledIconExclamation = styled(IconExclamation)`
  width: ${props => props.theme.pxToRem(40)};
  height: ${props => props.theme.pxToRem(40)};
  path {
    fill: ${props => props.theme.colors.interfaceRed};
  }
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.theme.pxToRem(64)};
  height: ${props => props.theme.pxToRem(64)};
  background-color: ${props => props.theme.colors.neutralGrey2};
  border-radius: 50%;
  margin-bottom: ${props => props.theme.pxToRem(13)};
`;

const StyledResultTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledResultText = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  text-align: center;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-top: ${props => props.theme.pxToRem(3)};
  max-width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  text-align: center;

  & > .marginBottom {
    margin-bottom: ${props => props.theme.pxToRem(16)};
  }
`;
