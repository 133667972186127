import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { Button, ButtonEnums, Divider, Modal, OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { setMembershipEvent } from 'modules/membership/actions';
import { useDispatch } from 'react-redux';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  isPaidByFirm: boolean | null;
}

export const ModalRenewOnProfile: React.FC<ModalProps> = ({ isOpen, handleClose, handleSubmit, isPaidByFirm }) => {
  const dispatch = useDispatch();
  const handleButtonClick = useCallback(() => {
    if (isPaidByFirm) handleClose();
    else {
      dispatch(setMembershipEvent('isClickedCredentialsSectionsRenewal', true));
      handleSubmit();
    }
  }, [handleSubmit, handleClose, dispatch, isPaidByFirm]);

  return (
    <>
      <StyledModal
        size="tiny"
        heading={isPaidByFirm ? 'You cannot proceed with this purchase' : 'Renew your Membership first'}
        open={isOpen}
      >
        <CloseButton>
          <StyledCloseIcon onClick={handleClose} />
        </CloseButton>
        <Spacer />
        <CenteredText>
          {isPaidByFirm
            ? 'You are unable to renew section/credential is owned by your firm or is part of an active quote in firm billing. Contact your organization admin for any questions.'
            : 'You must renew your membership before you can renew a credential or section.'}
        </CenteredText>
        <DividerWithBottomMargin />
        <CenteredButtons>
          <DesktopStyled>
            {!isPaidByFirm && (
              <StyledButton
                onClick={handleClose}
                size={ButtonEnums.sizes.small}
                variant={ButtonEnums.variants.secondary}
                testId="cancel-button-id"
              >
                Cancel
              </StyledButton>
            )}
            <StyledButton
              onClick={handleButtonClick}
              size={ButtonEnums.sizes.small}
              variant={ButtonEnums.variants.primary}
              testId="confirm-button-id"
            >
              {isPaidByFirm ? 'I understand' : 'Renew my membership'}
            </StyledButton>
          </DesktopStyled>
          <MobileStyled>
            {!isPaidByFirm && (
              <StyledButton
                onClick={handleClose}
                size={ButtonEnums.sizes.small}
                variant={ButtonEnums.variants.secondary}
                testId="cancel-button-id"
              >
                Cancel
              </StyledButton>
            )}
            <StyledButton
              onClick={handleButtonClick}
              size={ButtonEnums.sizes.small}
              variant={ButtonEnums.variants.primary}
              testId="confirm-button-id"
            >
              {isPaidByFirm ? 'I understand' : 'Renew my membership'}
            </StyledButton>
          </MobileStyled>
        </CenteredButtons>
      </StyledModal>
    </>
  );
};

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;
const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
    margin-right: ${props => props.theme.pxToRem(10)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
