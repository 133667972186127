import React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'components/atoms/Button/Button';
import { ButtonMobileMenu } from 'components/atoms/Button/ButtonMobileMenu';
import { ICON_ARROW_RIGHT, SVG_FILTERS } from 'resources/images';

interface IProps {
  setAcrossLinksExpanded: (expanded: boolean) => void;
  acrossLinksExpanded: boolean;
  items: State.IExternalLinkItem[] | null;
  className?: string;
  heading?: string;
}

export const AcrossAICPAMobile: React.FC<IProps> = React.memo(
  ({ className, items, acrossLinksExpanded, setAcrossLinksExpanded, heading = 'Across the AICPA and CIMA' }) => {
    const toggleAcrossExpanded = React.useCallback(() => {
      setAcrossLinksExpanded(!acrossLinksExpanded);
    }, [setAcrossLinksExpanded, acrossLinksExpanded]);

    if (!items) {
      return null;
    }

    if (!acrossLinksExpanded) {
      return (
        <>
          <GoToSubmenuButton onClick={toggleAcrossExpanded} testId="user-settings">
            {heading}
            <StyledIcon src={ICON_ARROW_RIGHT} alt="go to links" loading="lazy" />
          </GoToSubmenuButton>
          <OptionDivider />
        </>
      );
    }

    return (
      <MyAccountContainer>
        <SubMenuTitle>{heading}</SubMenuTitle>
        <OptionDivider />
        {items.map(item => (
          <ExternalLinkItemMobile item={item} key={item.name} />
        ))}
      </MyAccountContainer>
    );
  }
);

interface TrendingItemProps {
  item: State.IExternalLinkItem;
}

const ExternalLinkItemMobile: React.FC<TrendingItemProps> = React.memo(({ item }) => {
  const { name, url } = item;

  return <ButtonMobileMenu item={{ label: name, path: url }} />;
});

const StyledIcon = styled.img`
  width: 24px;
  margin-top: 22px;
  float: right;
  filter: brightness(0) invert(1) url(${SVG_FILTERS.PRIMARY_PURPLE});
`;

const SubMenuTitle = styled.div`
  padding: 20px 20px 24px;
  font-size: ${props => props.theme.fontSizes.l};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const MyAccountContainer = styled.div`
  padding: 20px;
`;

const GoToSubmenuButton = styled(Button)`
  &&&& {
    text-align: left;
    height: 64px;
    line-height: 64px;
    padding: 0 20px;
    width: 100%;
    font-size: ${props => props.theme.fontSizes.m};
    color: ${props => props.theme.colors.neutralGrey8};
    font-weight: ${props => props.theme.fontWeights.regular};
    border: none;
    background: none;
    margin: 0;

    i.icon {
      color: ${props => props.theme.colors.primaryPurple};
      float: right;
      margin-right: 0;
    }
  }
`;

const OptionDivider = styled.div`
  height: 1px;
  background-color: ${props => props.theme.colors.neutralGrey2};
  margin: 0px 20px;
`;
