import * as Sentry from '@sentry/browser';
import { handleActions, Action, combineActions } from 'redux-actions';
import {
  savedBlogPostsSelector,
  topSavedBlogPostsSelector,
  savedBlogPostsResultsSelector,
  savedBlogPostsLoadingSelector,
  savedBlogPostsResultsFetchedSelector,
  savedBlogPostsMetaSelector,
  savedBlogPostsAggregationSelector,
} from './selectors';
import { savedBlogPostsLoading, getSavedBlogPosts, toggleSavedBlogPost, setSavedBlogPosts } from './actions';
import { initialState } from './constants';

if ([savedBlogPostsLoading, getSavedBlogPosts, toggleSavedBlogPost].includes(undefined)) {
  const message: string = 'savedBlogPosts module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [savedBlogPostsLoading]: (state: State.SavedItems): State.SavedItems => ({
    ...state,
  }),

  [combineActions(getSavedBlogPosts, toggleSavedBlogPost) as any]: {
    next: (state: State.SavedItems, action: Action<State.SavedItemsData[]>): State.SavedItems => {
      const savedItemsData = Object.values(action.payload)[0] as State.SavedItemsData;
      return {
        ...state,
        contentIds: savedItemsData?.ids,
        top: savedItemsData?.top,
      };
    },
  },

  [setSavedBlogPosts]: (state: State.SavedItems, action: Action<any>): any => {
    return {
      ...state,
      contentIds: action.payload?.ids,
      top: action.payload?.top,
    };
  },
};

export {
  // Selectors
  savedBlogPostsSelector,
  topSavedBlogPostsSelector,
  savedBlogPostsResultsSelector,
  savedBlogPostsLoadingSelector,
  savedBlogPostsResultsFetchedSelector,
  savedBlogPostsMetaSelector,
  savedBlogPostsAggregationSelector,
  // Actions
  savedBlogPostsLoading,
  getSavedBlogPosts,
  toggleSavedBlogPost,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
