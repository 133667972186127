import React from 'react';
import styled from 'styled-components';
import { Modal } from 'components/atoms/Modal/Modal';
import { ButtonEnums, Button } from 'components/atoms/Button/Button';

import { InputWithValidation } from 'components/molecules/InputWithValidation/InputWithValidation';
import { Utils } from 'mxp-utils';

/**
 * Firm membership sign up: Partner details (Send Email Invitation to a Partner)
 * @category Center Membership
 * MART-1451
 */

interface Props {
  open: boolean;
  onCancel?: () => void;
  confirmButtonLabel?: string;
  onConfirm: () => void;
  className?: string;
  isConfirmationInProgress?: boolean;

  testId?: string;
  header?: string;
  description?: string;
  personObject: PersonObject;
  setPersonObject: ({ email }: PersonObject) => void;
}

interface PersonObject {
  email: string;
}

export const PartnersSendInvitation: React.FC<Props> = ({
  open,
  onCancel,
  testId,
  header,
  description,
  setPersonObject,
  onConfirm,
  personObject,
  confirmButtonLabel,
  isConfirmationInProgress,
}) => {
  const onConfirmEvent = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onConfirm();
    },
    [onConfirm]
  );

  const [initialValidation, setInitialValidation] = React.useState({
    emailInitial: true,
  });

  const emailIsEmpty = Boolean(personObject.email.length);
  const isEmailValid: boolean = Utils.isEmailFormatValid(personObject.email);
  const isEmailValidOrEmpty: boolean = isEmailValid && emailIsEmpty;

  const errorEmailMessage = 'Enter a valid email address.';

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInitialValidation({ ...initialValidation, emailInitial: false });

    const value = event.target.value;
    setPersonObject({
      ...personObject,
      email: value,
    });
  };

  return (
    <StyledModal
      className="partners-modal"
      open={open}
      onClose={onCancel}
      size="small"
      heading={header}
      testId={testId}
      showCloseCross
      confirmNode={
        isEmailValidOrEmpty ? (
          <Button
            className="confirm"
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.primary}
            testId="confirm"
            onClick={onConfirmEvent}
            disabled={isConfirmationInProgress}
            loading={isConfirmationInProgress}
            type="button"
          >
            {confirmButtonLabel}
          </Button>
        ) : (
          <Button disabled={true} testId="confirm">
            {confirmButtonLabel}
          </Button>
        )
      }
    >
      <InputWithValidation
        labelName="Email address of partner/owner"
        autoFocus={true}
        name="email"
        type="text"
        value={personObject.email}
        testId="email"
        labelId="email"
        onChange={handleEmailChange}
        isCorrect={isEmailValidOrEmpty || initialValidation.emailInitial}
        errorMessage={errorEmailMessage}
        isCorrectIconShown
      />

      {description && <StyledParagraph>{description}</StyledParagraph>}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: relative;
  box-sizing: border-box;

  &&&&&&& {
    border-radius: ${props => props.theme.pxToRem(2)};
    width: ${props => props.theme.pxToRem(590)};
    box-sizing: border-box;
    padding: ${props => props.theme.pxToRem(36)} ${props => props.theme.pxToRem(80)} ${props => props.theme.pxToRem(19)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props => props.theme.pxToRem(36)} ${props => props.theme.pxToRem(20)}
        ${props => props.theme.pxToRem(19)};
      width: 90%;
    }

    .header {
      line-height: 1.33;
      margin-top: ${props => props.theme.pxToRem(12)};
      h2 {
        margin-top: ${props => props.theme.pxToRem(17)};
      }
    }

    .content {
      margin: ${props => props.theme.pxToRem(5)} auto auto;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }

    p {
      max-width: 100%;
    }
    .actions {
      padding: ${props => `${props.theme.pxToRem(20)}`} 0 0 !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        flex-direction: column-reverse;
        justify-content: center;
      }
    }

    button {
      position: absolute;
      top: ${props => props.theme.pxToRem(24)};
      right: ${props => props.theme.pxToRem(24)};
    }

    div > button {
      position: relative;
      top: 0;
      right: 0;
      width: ${props => props.theme.pxToRem(202)};
      height: ${props => props.theme.pxToRem(34)};
      padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};

      margin: auto ${props => props.theme.pxToRem(6)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100% !important;
        height: ${props => `${props.theme.pxToRem(40)}`};
        max-width: ${props => `${props.theme.pxToRem(296)}`};
        font-size: ${props => props.theme.fontSizes.s};
        border-radius: ${props => `${props.theme.pxToRem(4)}`};
        margin: 0 auto !important;
      }
    }
  }
`;

const StyledParagraph = styled.p`
  line-height: 1.57;
  margin-top: ${props => props.theme.pxToRem(0)};
`;
