import React from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';
import { Grid } from 'semantic-ui-react';

/**
 * Active Credential Warning Modal
 * @category Refunds & Cancellations
 * RBUILD-8850
 */
export interface Props {
  open: boolean;
  onClose(): void;
  onUnderstand(): void;
}

export const ActiveCredentialWarningModal: React.FC<Props> = ({ open, onClose, onUnderstand }) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      heading="Please note that this member has an active section or credential"
      testId="active-credential-warning-modal"
      showCloseCross
      cancelNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.secondary}
          testId="return-to-purchases-button"
          onClick={onClose}
        >
          Return to purchases
        </Button>
      }
      confirmNode={
        <Button
          size={ButtonEnums.sizes.small}
          testId="cancellation-continue-button"
          variant={ButtonEnums.variants.primary}
          onClick={onUnderstand}
        >
          I understand, continue
        </Button>
      }
    >
      <Grid stackable centered columns={3}>
        <StyledGridRow />
        <StyledGridRow>
          <span>
            <b>Warning:</b> Cancelling this membership will cancel all associated sections and credentials. If any
            refund is due to the member for sections or credentials, those refund requests must be processed separately
            before proceeding with this cancellation.
          </span>
        </StyledGridRow>
      </Grid>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &&&& {
    background: ${props => props.theme.colors.neutralGrey1};
    min-height: ${props => props.theme.pxToRem(333)};
    max-width: ${props => props.theme.pxToRem(590)};
    margin-top: ${props => props.theme.pxToRem(48)};
    > .header {
      h2 {
        min-height: ${props => props.theme.pxToRem(64)};
        max-width: ${props => props.theme.pxToRem(440)};
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }
  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(60)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(60)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props => props.theme.pxToRem(10)};
    }
  }
`;
