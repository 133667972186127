import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Utils } from 'mxp-utils';
import { stopLegacyImpersonation } from 'modules/admin/actions';
import { InputWithValidation } from 'components/molecules/InputWithValidation/InputWithValidation';
import { CountriesDropdown } from 'components/organisms/AuthenticationModal/CountriesDropdown';
import { FilterRadioLabel } from 'components/molecules/FilterRadioLabel';
import { Separator } from 'components/admin/atoms/Separator/Separator';
import { Button, ButtonEnums, Input } from 'components/atoms';
import { getLocalStorageItem, LOCAL_STORAGE } from 'utils/localStorage';
import { StorageNames, ImpersonationOptions } from 'constants/index';
import { StrictDropdownProps } from 'semantic-ui-react';

export interface SubmitArgs {
  accountNumber: string;
  userEmail: string;
  countrySelected: string;
  impValue: Common.ImpersonationOptions | null;
}

interface Props {
  isUserImpersonationView?: boolean;
  submitText: string;
  onSubmit: (args: SubmitArgs) => Promise<() => void>;
}

export const LookUpForm: React.FC<Props> = ({ isUserImpersonationView = false, submitText, onSubmit }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [stopLegacyImpersonationLoading, setStopLegacyImpersonationLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState({ value: '', isCorrect: true, isEmpty: true });
  const [userEmail, setUserEmail] = useState({ value: '', isCorrect: true, isEmpty: true });
  const [impValue, setImpValue] = useState(isUserImpersonationView ? ImpersonationOptions.AICPA : null);
  const [countrySelected, setCountrySelected] = useState('US');
  const isCountrySelected = Boolean(countrySelected);
  const disableButton =
    (!accountNumber.isCorrect || accountNumber.isEmpty) && (!userEmail.isCorrect || userEmail.isEmpty);

  const handleAccountNumberChange = React.useCallback(({ target: { value } }: any) => {
    setAccountNumber({ value, isCorrect: true, isEmpty: Boolean(value.length === 0) });
  }, []);

  const handleEmailChange = React.useCallback(({ target: { value } }: any) => {
    const isEmpty = Boolean(value.length === 0);
    setUserEmail({ value, isCorrect: Utils.isEmailFormatValid(value) || isEmpty, isEmpty });
  }, []);

  const handleImpersonationOptionsChange = React.useCallback(
    (value: string | undefined) => {
      setImpValue(value as Common.ImpersonationOptions);
    },
    [setImpValue]
  );

  const handleSubmit = React.useCallback(
    (event: any) => {
      event.preventDefault();
      setLoading(true);
      onSubmit({
        accountNumber: accountNumber.value,
        userEmail: userEmail.value,
        countrySelected: countrySelected,
        impValue,
      }).finally(() => setLoading(false));
    },
    [accountNumber, userEmail, impValue, countrySelected, onSubmit]
  );

  const handleStopImpersonation = React.useCallback(() => {
    setStopLegacyImpersonationLoading(true);
    dispatch(stopLegacyImpersonation()).finally(() => {
      setStopLegacyImpersonationLoading(false);
      setAccountNumber({ value: '', isCorrect: true, isEmpty: true });
      setUserEmail({ value: '', isCorrect: true, isEmpty: true });
      setImpValue(null);
    });
  }, [dispatch]);

  const handleCountryChange = React.useCallback((e: any, data: StrictDropdownProps) => {
    const selectedCountry: string = data.value as string;
    setCountrySelected(selectedCountry);
  }, []);

  return (
    <StyledForm isUserImpersonationView={isUserImpersonationView} onSubmit={handleSubmit}>
      <StyledInputContainer>
        <StyledRegularInput
          labelName="AICPA UID"
          name="aicpa-account-number"
          type="text"
          value={accountNumber.value}
          testId="aicpa-account-number"
          labelId="aicpa-account-number"
          onChange={handleAccountNumberChange}
          placeholder="e.g. 300000001"
        />

        <Separator>OR</Separator>

        <InputWithValidation
          labelName="Email address"
          name="email"
          type="email"
          value={userEmail.value}
          testId="email"
          labelId="email"
          onChange={handleEmailChange}
          isCorrect={userEmail.isCorrect}
          errorMessage={'Please enter a valid email address'}
          isCorrectIconShown
          placeholder="e.g. sam.thomas@gmail.com"
        />
      </StyledInputContainer>

      <StyledCountriesDropdown
        onChange={handleCountryChange}
        title="Country"
        countrySelected={!isCountrySelected ? 'US' : countrySelected}
      />

      {isUserImpersonationView && (
        <>
          <StyledText data-testid="preferences-text">
            Please choose which system you would like to
            <br />
            access to begin your impersonation session on
          </StyledText>
          {Object.values(ImpersonationOptions).map(item => (
            <StyledFilterRadioLabel
              key={item}
              testId={`${item}-imp-option`}
              text={item}
              id={item}
              checked={item === impValue}
              onChange={handleImpersonationOptionsChange}
              reverseLabelAndRadio
              smallerIcon
            />
          ))}
        </>
      )}

      <StyledButton
        size={ButtonEnums.sizes.medium}
        onClick={handleSubmit}
        testId="search-user"
        variant={ButtonEnums.variants.primary}
        disabled={disableButton || loading || stopLegacyImpersonationLoading}
        loading={loading}
      >
        {submitText}
      </StyledButton>
      {isUserImpersonationView && (
        <StyledButton
          type="button"
          size={ButtonEnums.sizes.medium}
          onClick={handleStopImpersonation}
          testId="stop-impersonation"
          variant={ButtonEnums.variants.primary}
          disabled={
            !getLocalStorageItem(StorageNames.isLegacyImpersonation, LOCAL_STORAGE.ANON_AICPA_DATA) ||
            loading ||
            stopLegacyImpersonationLoading
          }
          loading={stopLegacyImpersonationLoading}
        >
          Stop impersonation
        </StyledButton>
      )}
    </StyledForm>
  );
};

const StyledForm = styled.form<{ isUserImpersonationView: boolean }>`
  margin-bottom: ${props => props.theme.pxToRem(props.isUserImpersonationView ? 80 : 0)};
`;

const StyledFilterRadioLabel = styled(FilterRadioLabel)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledInputContainer = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(11)};
  max-width: ${props => props.theme.pxToRem(265)};
`;

const StyledRegularInput = styled(Input)`
  margin-bottom: ${props => props.theme.pxToRem(0)};
  width: 100%;
`;

const StyledText = styled.div`
  max-width: 720px;
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.pxToRem(14)};
  line-height: 1.71;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(17)};
`;

const StyledButton = styled(Button)`
  &&&& {
    padding-left: ${props => props.theme.pxToRem(25)};
    padding-right: ${props => props.theme.pxToRem(25)};
    margin-right: ${props => props.theme.pxToRem(10)};
  }
`;

const StyledCountriesDropdown = styled(CountriesDropdown)`
  &&& {
    width: ${props => props.theme.pxToRem(360)};
    border: solid 1px ${props => props.theme.colors.neutralGrey4};
    border-radius: 6px;
    color: ${props => props.theme.colors.neutralBlack} !important;
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: calc(100% - ${props => props.theme.pxToRem(5)});
    }
  }
  &&&.dropdown .menu {
    min-width: ${props => props.theme.pxToRem(300)} !important;
  }

  &.dropdown:hover {
    border-color: ${props => props.theme.colors.neutralGrey4};
  }
  &&&.dropdown > .default {
    color: ${props => props.theme.colors.neutralBlack} !important;
  }

  &&&.dropdown .menu > .item {
    &:hover {
      color: ${props => props.theme.colors.neutralBlack} !important;
    }
  }
`;
