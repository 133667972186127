import React from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';

interface Props {
  open: boolean;
  onCancel: () => void;
  onRemove: () => void;
}

export const CimaFileUploadRemoveModal: React.FC<Props> = ({ open, onCancel, onRemove }) => {
  const cancelNode = (
    <ButtonStyled
      testId="cima-remove-file"
      size={ButtonEnums.sizes.medium}
      variant={ButtonEnums.variants.secondary}
      onClick={onCancel}
    >
      Cancel
    </ButtonStyled>
  );
  const confirmNode = (
    <ButtonStyled
      testId="cima-cancel-remove-file"
      size={ButtonEnums.sizes.medium}
      variant={ButtonEnums.variants.primary}
      onClick={onRemove}
    >
      Delete file
    </ButtonStyled>
  );

  return (
    <>
      <StyledModal
        closeOnDimmerClick={false}
        open={open}
        centered
        heading={<>Are you sure you want to delete this file?</>}
        confirmNode={confirmNode}
        cancelNode={cancelNode}
      >
        <ModalContent>Once the file is deleted, you will be able to upload a new file</ModalContent>
      </StyledModal>
    </>
  );
};

const ButtonStyled = styled(Button)`
  &&&&&& {
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(150)} !important;
  }
`;

const StyledModal = styled(Modal)`
  max-width: ${props => props.theme.pxToRem(650)};
  &&&&&&& {
    > .header {
      font-weight: ${props => props.theme.fontWeights.light};
      margin-top: ${props => props.theme.pxToRem(20)};
    }
    > .content {
      display: flex;
      text-align: center;
      justify-content: center;
      margin-top: ${props => props.theme.pxToRem(10)};
    }
  }
`;

const ModalContent = styled.div`
  width: ${props => props.theme.pxToRem(450)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
`;
