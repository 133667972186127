import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { fetchRequiredDocuments } from './actions';
import { isServer } from 'utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchRequiredDocuments]: {
    next: (state: State.RequiredDocument, action: any): State.RequiredDocument => ({
      ...state,
      requiredDocuments: action.payload,
      requiredDocumentsFetched: true,
      requiredDocumentsFrontloadRequest: isServer,
    }),
    throw: (state: State.RequiredDocument): State.RequiredDocument => ({
      ...state,
      requiredDocumentsFetched: false,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);

export * from './selectors';
export * from './constants';
export * from './actions';
