import React from 'react';
import styled from 'styled-components/macro';
import { ProductItem } from 'components/molecules/ProductItem/ProductItem';
import { Divider, Link, LinkEnums } from 'components/atoms';
import { RemoveBundleButton } from './RemoveBundleButton';
import { User, Checkout, Contentful, Content, Product } from 'mxp-schemas';
import { productTypeToSlug } from 'utils';

export enum ProductsListParent {
  CART_PAGE = 'CART_PAGE',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  PURCHASES_PROFILE = 'PURCHASES_PROFILE',
}

interface Props {
  products: Common.ProductItemData[] | null;
  parentPage: ProductsListParent;
  skipLastDivider?: boolean;
  className?: string;
  conferenceUserInfo?: Checkout.ConferenceUserInfo | null;
  userEmail?: string;
  fvsMembership?: User.MembershipIdsEnum[] | [];
  taxMembership?: User.MembershipIdsEnum[] | [];
  nfpMembership?: User.MembershipIdsEnum[] | [];
  pfpMembership?: User.MembershipIdsEnum[] | [];
  oktaId?: string;
  isAdminPortal?: boolean;
  showUpdateButtons?: boolean;
  productItemId?: string;
  lowerLastDivider?: boolean;
  isRegionalPathwayCart?: boolean | null;
  mipProductPrice?: string;
  navigate?: (payload: Common.PushPayload) => void;
  removeCartItems?: (ids: string[]) => void;
  accessItem?: (item: Common.ProductItemData) => void;
  deprovision?: (orderId: string, sku: string) => void;
  updateCartItem?: (quantity: number, standingOrder: boolean, lineItemId: string, donationPrice?: string) => void;
  setShowUpdateButtons?: (show: boolean) => void;
  setProductItemId?: (product: string) => void;
  setDisableCheckoutButton?: (disable: boolean) => void;
  setCancelSOrderModalMeta?: (data: State.CancelSOrderModalMeta) => void;
  toggleSubscriptionRenewal?: (enable: boolean, item: Common.ProductItemData, productName: string) => void;
  getRenewalSeason?: () => void;
  getMipRenewalSeasonStartDate?: () => void;
  getMipRenewalSeasonEndDate?: () => void;
}

export const ProductsList: React.FC<Props> = ({
  products,
  parentPage,
  skipLastDivider = false,
  className = '',
  conferenceUserInfo,
  userEmail,
  fvsMembership,
  taxMembership,
  nfpMembership,
  pfpMembership,
  oktaId,
  isAdminPortal = false,
  showUpdateButtons,
  productItemId,
  lowerLastDivider = false,
  isRegionalPathwayCart = false,
  mipProductPrice,
  navigate,
  removeCartItems,
  accessItem,
  deprovision,
  updateCartItem,
  setShowUpdateButtons,
  setProductItemId,
  setDisableCheckoutButton,
  setCancelSOrderModalMeta,
  toggleSubscriptionRenewal,
  getRenewalSeason,
  getMipRenewalSeasonStartDate,
  getMipRenewalSeasonEndDate,
}) => {
  if (!products?.length) return null;

  const isCartPage: boolean = parentPage === ProductsListParent.CART_PAGE;
  const isOrderConfirmationPage: boolean = parentPage === ProductsListParent.ORDER_CONFIRMATION;

  return (
    <div className={className}>
      {products.map((item: Common.ProductItemData, index: number) => {
        const bundleId: string = item.bundleId || '';
        const isBundleItem: boolean = Boolean(bundleId) && (isCartPage || isOrderConfirmationPage);
        const isLastBundleItem: boolean = isBundleItem && bundleId !== products[index + 1]?.bundleId;
        const isFirstBundleItem: boolean = isBundleItem && bundleId !== products[index - 1]?.bundleId;
        const shouldRenderBundleName: boolean = (isCartPage || isOrderConfirmationPage) && isFirstBundleItem;
        const itemCountInThisBundle = products.filter(product => product.bundleId === bundleId).length;

        return (
          <React.Fragment key={item.lineItemId}>
            {shouldRenderBundleName && (
              <>
                <Link
                  type={LinkEnums.type.standaloneLink}
                  to={`/${Content.CategorySlugs.CPE_LEARNING}/${productTypeToSlug(
                    Product.ProductType.BUNDLE as Product.ProductType
                  )}/${item.bundleSlug}`}
                >
                  <StyledBundleName>{item.bundleName}</StyledBundleName>
                </Link>
                {isCartPage && (
                  <RemoveBundleButton
                    bundleId={bundleId}
                    products={products}
                    itemCountInThisBundle={itemCountInThisBundle}
                    removeCartItems={removeCartItems}
                  />
                )}

                <StyledBundleDetail>Products Included ({itemCountInThisBundle}):</StyledBundleDetail>
              </>
            )}
            <StyledContainer
              key={item.lineItemId}
              renderAsBundleItem={isBundleItem}
              shouldRenderBundleName={shouldRenderBundleName}
            >
              <ProductItem
                item={item}
                cmsProductData={item.cmsProductData as Contentful.ModularContent.ContentfulProduct}
                parentPage={parentPage}
                removeCartItems={removeCartItems}
                accessItem={accessItem}
                conferenceUserInfo={conferenceUserInfo}
                userEmail={userEmail}
                fvsMembership={fvsMembership}
                taxMembership={taxMembership}
                nfpMembership={nfpMembership}
                pfpMembership={pfpMembership}
                oktaId={oktaId}
                navigate={navigate}
                isAdminPortal={isAdminPortal}
                deprovision={deprovision}
                updateCartItem={updateCartItem}
                setShowUpdateButtons={setShowUpdateButtons}
                showUpdateButtons={showUpdateButtons}
                productItemId={productItemId}
                setProductItemId={setProductItemId}
                setDisableCheckoutButton={setDisableCheckoutButton}
                setCancelSOrderModalMeta={setCancelSOrderModalMeta}
                toggleSubscriptionRenewal={toggleSubscriptionRenewal}
                renderAsBundleItem={isBundleItem}
                getRenewalSeason={getRenewalSeason}
                getMipRenewalSeasonStartDate={getMipRenewalSeasonStartDate}
                getMipRenewalSeasonEndDate={getMipRenewalSeasonEndDate}
                isRegionalPathwayCart={isRegionalPathwayCart}
                mipProductPrice={mipProductPrice}
              />
              {(products.length - 1 > index || !skipLastDivider) && (
                <StyledDivider
                  data-full-width={isLastBundleItem && isBundleItem}
                  data-lower-divider={lowerLastDivider && products.length - 1 === index}
                />
              )}
            </StyledContainer>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const StyledContainer = styled.div<{ renderAsBundleItem: boolean; shouldRenderBundleName: boolean }>`
  margin-top: ${props => (props.shouldRenderBundleName ? props.theme.pxToRem(16) : props.theme.pxToRem(32))};
  ${props =>
    props.renderAsBundleItem &&
    `
    padding-left: ${props.theme.pxToRem(62)};
    `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => (props.shouldRenderBundleName ? props.theme.pxToRem(16) : props.theme.pxToRem(40))};
    ${props =>
      props.renderAsBundleItem &&
      `
    padding-left: ${props.theme.pxToRem(26)};
    `}
  }

  :first-of-type {
    margin-top: 0;
  }
`;

const StyledDivider = styled(Divider)<{ 'data-full-width': boolean; 'data-lower-divider': boolean }>`
  &&& {
    margin: ${props => (props['data-lower-divider'] ? props.theme.pxToRem(95) : props.theme.pxToRem(32))} 0 0 0;
    ${props =>
      props['data-full-width'] &&
      `
      margin-left: -${props.theme.pxToRem(62)};
    `}

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: ${props => props.theme.pxToRem(40)} 0 0 0;
      ${props =>
        props['data-full-width'] &&
        `
    margin-left: -${props.theme.pxToRem(26)};
    `}
    }
  }
`;

const StyledBundleName = styled.div`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.25;
  color: ${props => props.theme.colors.primaryPurple};
  margin: ${props => props.theme.pxToRem(16)} 0;
`;

const StyledBundleDetail = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.5;
  letter-spacing: 0.22px;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-left: ${props => props.theme.pxToRem(62)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(26)};
  }
`;
