import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { DocumentUploadActionNames } from './constants';
import request from 'utils/GraphQLClient';
import {
  GET_DOCUMENT,
  GET_DOCUMENT_CIMA,
  GET_DOCUMENT_FILES,
  GET_DOCUMENT_EXAM_EXEMPTION,
  REMOVE_DOCUMENT_CIMA,
  REMOVE_EXEMPTION_DOCUMENT,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_CIMA,
} from 'mxp-graphql-queries';
import { getLocalStorageItem, LOCAL_STORAGE } from 'utils/localStorage';
import { StorageNames } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const getDocumentUpload: any = createAction(
  DocumentUploadActionNames.GET_DOCUMENT,
  (fileName: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setLoading(true));
    return request(GET_DOCUMENT, { fileName }).then((response: any) => {
      return response.getDocument;
    });
  }
);

export const getDocumentCima: any = createAction(
  DocumentUploadActionNames.GET_DOCUMENT_CIMA,
  (fileName: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setLoading(true));
    return request(GET_DOCUMENT_CIMA, { fileName }).then((response: any) => {
      return response.getDocumentCima;
    });
  }
);

export const getDocumentExamExemption: any = createAction(
  DocumentUploadActionNames.GET_DOCUMENT_EXAM_EXEMPTION,
  (fileName: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setLoading(true));
    return request(GET_DOCUMENT_EXAM_EXEMPTION, { fileName }).then((response: any) => {
      return response.getDownloadExamExemption;
    });
  }
);

export const getDocumentFiles: any = createAction(
  DocumentUploadActionNames.GET_DOCUMENT_FILES,
  (fileName: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setLoading(true));
    return request(GET_DOCUMENT_FILES, { fileName }).then((response: any) => {
      return response.getDocumentFiles;
    });
  }
);

export const uploadDocument: any = createAction(
  DocumentUploadActionNames.UPLOAD_DOCUMENT,
  (
      fileName: string,
      entityName: string,
      file: File,
      applicationPartId?: string,
      fileSize?: string,
      personAccountId?: string,
      type?: string,
      personExemptionLevelKey?: string
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(setLoading(true));
      const response = await request(UPLOAD_DOCUMENT, {
        fileName,
        entityName,
        file,
        applicationPartId,
        fileSize,
        personAccountId,
        type,
        personExemptionLevelKey,
      });
      return response?.uploadDocument;
    }
);

export const uploadDocumentCima: any = createAction(
  DocumentUploadActionNames.UPLOAD_DOCUMENT_CIMA,
  (fileName: string, assessmentType: string, file: File, type?: string, sku?: string) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(setLoading(true));

      const subject = 'EPA2';
      const description = 'EPA2';

      const uploadPayload = {
        fileName,
        assessmentType,
        file,
        subject,
        description,
        type,
        sku,
      };

      return request(UPLOAD_DOCUMENT_CIMA, uploadPayload).then((response: any) => {
        return response?.uploadDocumentCima;
      });
    }
);

export const removeDocumentCima: any = createAction(
  DocumentUploadActionNames.REMOVE_DOCUMENT_CIMA,
  (fileName: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    return request(REMOVE_DOCUMENT_CIMA, { fileName }).then((response: any) => {
      return response.removeDocument;
    });
  }
);

export const removeExemptionDocument: any = createAction(
  DocumentUploadActionNames.REMOVE_DOCUMENT_CIMA,
  (fileName: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    return request(REMOVE_EXEMPTION_DOCUMENT, { fileName }).then((response: any) => {
      return response.removeExemptionDocument;
    });
  }
);

export const setLoading: any = createAction(DocumentUploadActionNames.SET_LOADING, (loading: boolean) => () => {
  return loading;
});

export const setError: any = createAction(
  DocumentUploadActionNames.SET_ERROR,
  (error: CustomErrors.GraphQLError) => () => {
    return error;
  }
);

export const getDocumentName: any = createAction(DocumentUploadActionNames.GET_DOCUMENT_NAME, () => {
  const documentName = getLocalStorageItem(StorageNames.userData, LOCAL_STORAGE.ANON_AICPA_DATA);

  return {
    file: documentName,
    success: true,
  };
});
