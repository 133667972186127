import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import {
  updatePersonLevelExemption,
  getPersonLevelExemptionId,
  setProfessionalQualificationData,
  setEducationalQualificationData,
  setWorkExperienceData,
  updateDocumentStatus,
  getQualificationData,
  showLoading,
  setEducationalQualificationDataFromRehydrate,
  getPersonLevelExemptionUniversityDetails,
  setUniversityDetails,
  setOfflineExemptionStatus,
} from './action';
import { RecordTypePage } from 'modules/exemptionProfessional/constants';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [showLoading]: (state: State.PersonLevelExemptionStatus): State.PersonLevelExemptionStatus => ({
    ...state,
    isLoading: true,
  }),
  [getPersonLevelExemptionId]: {
    next: (state: State.PersonLevelExemptionStatus, action: any): State.PersonLevelExemptionStatus => ({
      ...state,
      ...action.payload,
      isLoading: false,
      personLevelExemptionFetched: true,
    }),
    throw: (state: State.PersonLevelExemptionStatus): State.PersonLevelExemptionStatus => ({
      ...state,
      isLoading: true,
      personLevelExemptionFetched: false,
    }),
  },
  [setOfflineExemptionStatus]: (
    state: State.PersonLevelExemptionStatus,
    action: {
      payload: {
        hasActiveOfflineExemptionCalculation: boolean;
      };
    }
  ) => ({
    ...state,
    ...action.payload,
  }),
  [getQualificationData]: {
    next: (state: State.PersonLevelExemptionStatus, action: any): State.PersonLevelExemptionStatus => ({
      ...state,
      personAccountKey: action.payload.personAccountKey,
      professionalQualification:
        action.payload.recordType === RecordTypePage.PROFESSIONAL_QUALIFICATION
          ? action.payload.professionalQualification
          : state.professionalQualification,
      educationalQualification:
        action.payload.recordType === RecordTypePage.EDUCATIONAL_QUALIFICATION
          ? action.payload.educationalQualification
          : state.educationalQualification,
      personLevelExemptionRecords: {
        profRecord:
          action.payload.recordType === RecordTypePage.PROFESSIONAL_QUALIFICATION
            ? action.payload.personLevelExemptionRecords.profRecord
            : state.personLevelExemptionRecords?.profRecord || [],
        educRecord:
          action.payload.recordType === RecordTypePage.EDUCATIONAL_QUALIFICATION
            ? action.payload.personLevelExemptionRecords.educRecord
            : state.personLevelExemptionRecords?.educRecord || [],
      },
      isLoading: false,
    }),
    throw: (state: State.PersonLevelExemptionStatus): State.PersonLevelExemptionStatus => ({
      ...state,
      isLoading: true,
    }),
  },

  [updatePersonLevelExemption]: {
    next: (state: State.PersonLevelExemptionStatus, action: any): State.PersonLevelExemptionStatus => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    throw: (state: State.PersonLevelExemptionStatus, action: any): State.PersonLevelExemptionStatus => ({
      ...state,
      isLoading: true,
    }),
  },
  [setProfessionalQualificationData]: (state: State.PersonLevelExemptionStatus, action: any): any => {
    return {
      ...state,
      professionalQualification: action.payload.professionalQualificationData,
      personLevelExemptionRecords: { ...state.personLevelExemptionRecords, profRecord: action.payload.profRecord },
    };
  },
  [setEducationalQualificationData]: (state: State.PersonLevelExemptionStatus, action: any): any => {
    return {
      ...state,
      educationalQualification: action.payload.educationalQualificationData,
      personLevelExemptionRecords: { ...state.personLevelExemptionRecords, educRecord: action.payload.educRecord },
    };
  },
  [setEducationalQualificationDataFromRehydrate]: (state: State.PersonLevelExemptionStatus, action: any): any => {
    return {
      ...state,
      educationalQualification: action.payload.educationalQualificationData,
    };
  },
  [setWorkExperienceData]: (state: State.PersonLevelExemptionStatus, action: any): any => {
    return {
      ...state,
      workExperience: action.payload,
      yearsofAccountingExperience: action.payload?.[0]?.yearsofAccountingExperience,
      experienceofWorking: action.payload?.[0]?.experienceofWorking,
    };
  },
  [updateDocumentStatus]: {
    next: (state: State.PersonLevelExemptionStatus, action: any): State.PersonLevelExemptionStatus => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    throw: (state: State.PersonLevelExemptionStatus, action: any): State.PersonLevelExemptionStatus => ({
      ...state,
      isLoading: true,
    }),
  },
  [getPersonLevelExemptionUniversityDetails]: (
    state: State.PersonLevelExemptionStatus,
    action: any
  ): State.PersonLevelExemptionStatus => {
    return {
      ...state,
      universityDetails: action.payload?.getPersonLevelExemptionUniversityDetails,
      universityDetailsFetched: true,
    };
  },
  [setUniversityDetails]: (state: State.PersonLevelExemptionStatus, action: any): State.PersonLevelExemptionStatus => {
    return {
      ...state,
      universityDetails: action.payload?.universityDetailsData,
      universityDetailsFetched: true,
    };
  },
};

// ------------------------------------
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);

export * from './selectors';
export * from './constants';
export * from './action';
