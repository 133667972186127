import React from 'react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { LazyImage } from 'components/atoms/LazyImage/LazyImage';
import TruncateMarkup from 'react-truncate-markup';
import { Product } from 'mxp-schemas';

export enum ProductThumbSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface Props {
  coverSrc: string;
  imageRatio?: string;
  size?: ProductThumbSize;
  title: string;
  subtitle: string;
  productType: Product.ProductType | undefined;
  subscriptionProductType?: Product.SubscriptionProductType;
  alt?: string;
}

export const ProductThumbnail: React.FC<Props> = ({
  coverSrc,
  imageRatio = '143%',
  title,
  size,
  subtitle,
  productType,
  subscriptionProductType,
  alt,
  ...props
}) => {
  const titleLines: number = size === ProductThumbSize.LARGE ? 3 : 4;
  const isContribution: boolean = productType === Product.ProductType.CONTRIBUTION;
  return (
    <Cover size={size} data-testid="product-thumb" {...props}>
      <LazyImage src={coverSrc} alt={alt ? alt : 'product-image'} imageRatio={imageRatio} />
      {!isContribution && (
        <Info size={size} productType={productType} subscriptionProductType={subscriptionProductType}>
          <Title size={size}>
            <TruncateMarkup ellipsis={'…'} lines={titleLines}>
              <div>{title}</div>
            </TruncateMarkup>
          </Title>
          <Subtitle size={size}>
            <TruncateMarkup ellipsis={'…'} lines={1}>
              <div>{subtitle}</div>
            </TruncateMarkup>
          </Subtitle>
        </Info>
      )}
    </Cover>
  );
};

type StyledCoverProps = Pick<Props, 'size'>;
const Cover = styled.div<ThemedStyledProps<StyledCoverProps, DefaultTheme>>`
  border-radius: 0 0 ${props => props.theme.pxToRem(16)};
  overflow: hidden;
  position: relative;
  background-size: cover;
  display: block;
  box-shadow: 0 ${props => props.theme.pxToRem(19)} ${props => props.theme.pxToRem(10)}
    ${props => props.theme.pxToRem(-10)} rgba(0, 0, 0, 0.1);
  height: ${props => props.theme.pxToRem(200)};
  width: ${props => props.theme.pxToRem(140)};
  ${props =>
    props.size === ProductThumbSize.LARGE &&
    `
    width: ${props.theme.pxToRem(285)};
    height: ${props.theme.pxToRem(403)};
    border-radius: 0 0 ${props.theme.pxToRem(42)};
   `};

  ${props =>
    props.size === ProductThumbSize.SMALL &&
    `
    width: ${props.theme.pxToRem(170)};
    height: ${props.theme.pxToRem(240)};
    border-radius: 0 0 ${props.theme.pxToRem(16)};
   `};
`;

const Title = styled.div<ThemedStyledProps<StyledCoverProps, DefaultTheme>>`
  ${props =>
    `
      padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(8)};
      font-size: ${props.theme.fontSizes.s};
      width: 100%;
      height: ${props.theme.pxToRem(96)};
      font-weight: ${props.theme.fontWeights.light};
     `};

  ${props =>
    props.size === ProductThumbSize.LARGE &&
    `padding: ${props.theme.pxToRem(16)};
      font-size: ${props.theme.fontSizes.xl};
      height: ${props.theme.pxToRem(176)};
     `};

  ${props =>
    props.size === ProductThumbSize.SMALL &&
    `
      font-size: ${props.theme.fontSizes.s};
      font-weight: ${props.theme.fontWeights.light};
      height: ${props.theme.pxToRem(126)};
      line-height: ${props.theme.pxToRem(24)};
     `};
`;

const Subtitle = styled.div<ThemedStyledProps<StyledCoverProps, DefaultTheme>>`
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  padding-top: ${props => props.theme.pxToRem(3)};
  padding-left: ${props => props.theme.pxToRem(8)};
  padding-right: ${props => props.theme.pxToRem(8)};
  height: ${props => props.theme.pxToRem(22)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.medium};

  ${props =>
    props.size === ProductThumbSize.LARGE &&
    `
      padding-top: ${props.theme.pxToRem(8)};
      font-size: ${props.theme.fontSizes.s};
      font-weight: ${props.theme.fontWeights.medium};
      height: ${props.theme.pxToRem(35)};
      padding-left: ${props.theme.pxToRem(16)};
      padding-right: ${props.theme.pxToRem(16)};
     `};

  ${props =>
    props.size === ProductThumbSize.SMALL &&
    `
      padding-top: ${props.theme.pxToRem(4)};
      height: ${props.theme.pxToRem(26)};
      font-size: ${props.theme.fontSizes.xxs};
      font-weight: ${props.theme.fontWeights.medium};
      padding-left: ${props.theme.pxToRem(16)};
      padding-right: ${props.theme.pxToRem(16)};
     `};
`;

type InfoProps = Pick<Props, 'size' | 'productType' | 'subscriptionProductType'>;
const Info = styled.div<ThemedStyledProps<InfoProps, DefaultTheme>>`
  position: absolute;
  color: ${props => props.theme.colors.neutralWhite};
  background-color: ${props =>
    props.theme.getProductInfoBgColorByType(props.productType, props.subscriptionProductType)};

  ${props => `
      top: ${props.theme.pxToRem(40)};
      width: ${props.theme.pxToRem(128)};
      height: ${props.theme.pxToRem(136)};`}

  ${props =>
    props.size === ProductThumbSize.LARGE &&
    `
      width: ${props.theme.pxToRem(258)};
      height: ${props.theme.pxToRem(240)};
      top: ${props.theme.pxToRem(80)};
    `};
  ${props =>
    props.size === ProductThumbSize.SMALL &&
    `
      top: ${props.theme.pxToRem(48)};
      width: ${props.theme.pxToRem(154)};
      height: ${props.theme.pxToRem(152)};
    `};
`;
