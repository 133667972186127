import React from 'react';
import Helmet from 'react-helmet';
import { SingleColumnPageTemplate } from 'components/templates/SingleColumn/SingleColumn';
import { Loader } from 'semantic-ui-react';
import { ButtonEnums, ButtonLink, Grid, Link, Paragraph } from '../../atoms';
import styled from 'styled-components';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

interface Props {
  provision(): void;
  reset(): void;
  loading: boolean;
  error: CustomErrors.GraphQLError | null;
}

export const PageCenterAdminProvisioning: React.FC<Props> = ({ provision, reset, loading, error }) => {
  React.useEffect(() => {
    if (!loading) provision();
    return reset;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showLoading = Boolean(loading && !error);

  return (
    <>
      <Helmet>
        <title>AICPA & CIMA</title>
      </Helmet>

      <SingleColumnPageTemplate>
        {showLoading && <Loader active>Loading</Loader>}
        {error && (
          <Grid>
            <Grid.Row>
              <Grid.Column computer={8}>
                <Wrapper>
                  <ErrorScreen error={error} />
                  <StyledButtonLink
                    testId="error-go-to-invoices"
                    variant={ButtonEnums.variants.primary}
                    size={ButtonEnums.sizes.medium}
                    to={getPath(Routes.STOREFRONT_PAGE)}
                  >
                    Browse CPE &amp; Learning
                  </StyledButtonLink>
                </Wrapper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {!error && !showLoading && (
          <Grid>
            <Grid.Row>
              <Grid.Column computer={8}>
                <Wrapper>
                  <StyledH1 data-testid="success-title">Successfully Allocated!</StyledH1>
                  <StyledButtonLink
                    testId="success-go-to-purchases"
                    variant={ButtonEnums.variants.primary}
                    size={ButtonEnums.sizes.medium}
                    to={getPath(Routes.PROFILE_PURCHASES)}
                  >
                    Go to Purchases
                  </StyledButtonLink>
                </Wrapper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </SingleColumnPageTemplate>
    </>
  );
};

interface ErrorScreenProps {
  error: CustomErrors.GraphQLError | null;
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({ error }) => {
  const data = React.useMemo(() => {
    const errorMsg = error?.primaryApplicationError?.message || '';
    const tokenUsedStr = 'has been used already';
    const tokenAlreadyAllocatedStr = 'is already allocated';
    const notExistInOrgStr = 'does not exist in organization.';

    if (errorMsg.includes(tokenUsedStr) || errorMsg.includes(tokenAlreadyAllocatedStr)) {
      return {
        title: 'Check "My Purchases" to start your learning',
        message:
          'The invitation to access this product has been claimed. If you do not have access, please contact the individual who assigned this learning.',
        contactText: 'For more help, contact us at',
      };
    }
    if (errorMsg.includes('The price book entry is inactive.')) {
      return {
        title: 'Sorry for the inconvenience',
        message:
          'This product is no longer available for access. Please contact the individual who assigned this learning to you.',
        contactText: 'If you need help with this please contact us at',
      };
    }

    if (errorMsg.includes(notExistInOrgStr)) {
      return {
        title: 'Sorry for the inconvenience',
        message: 'You must register and be associated with the organization for access.',
        contactText: 'If you need help with this please contact us at',
      };
    }
    return {
      title: 'Sorry for the inconvenience',
      message:
        'The order for this product has reached maximum capacity. Please contact the individual who assigned this learning to you.',
      contactText: 'If you need help with this please contact us at',
    };
  }, [error]);
  return (
    <>
      <StyledH1 data-testid="error-title">{data.title}</StyledH1>
      <StyledParagraph testId="error-message-text">{data.message}</StyledParagraph>
      <StyledParagraph testId="error-please-contact-text">
        {data.contactText}&nbsp;
        <Link isExternal testId="error-email" to="mailto:service@aicpa.org">
          service@aicpa.org
        </Link>
        &nbsp;or&nbsp;
        <Link isExternal testId="error-phone" to="tel:888-777-7077">
          888-777-7077
        </Link>
        , 9am–6pm ET, Monday through Friday.
      </StyledParagraph>
    </>
  );
};

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${props => props.theme.pxToRem(22)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  :last-of-type {
    margin-bottom: ${props => props.theme.pxToRem(32)};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    width: ${props => props.theme.pxToRem(193)};
  }
`;

const Wrapper = styled.div`
  margin: ${props => props.theme.pxToRem(40)} 0;
`;

const StyledH1 = styled.h1`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
`;
