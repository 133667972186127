import React from 'react';
import styled from 'styled-components';
import { Content } from 'mxp-schemas';
import { CategoryDecorationMap } from 'constants/index';

interface Props {
  title: string;
  categorySlug: string;
  heroHeading?: boolean;
}

export const HeadingWithIcon: React.FC<Props> = React.memo(({ title, categorySlug, heroHeading }) => {
  const IconComponent: any = !CategoryDecorationMap[categorySlug]
    ? CategoryDecorationMap[Content.CategorySlugs.PROFESSIONAL_INSIGHTS].icon
    : CategoryDecorationMap[categorySlug].icon;
  return (
    <StyledHeading tabIndex={0} heroHeading={heroHeading} data-testid={`content-card-heading-${categorySlug}`}>
      {
        <StyledIcon heroHeading={heroHeading}>
          <IconComponent />
        </StyledIcon>
      }
      {title}
    </StyledHeading>
  );
});

const StyledHeading = styled.h3<Partial<Props>>`
  color: ${props => (props.heroHeading ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  font-size: ${props => (props.heroHeading ? props.theme.fontSizes.xl : props.theme.fontSizes.l)};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: ${props => props.theme.fontSizes.xl};
  margin: 0;
`;

const StyledIcon = styled.span<Partial<Props>>`
  svg {
    width: ${props => (props.heroHeading ? props.theme.pxToRem(24) : props.theme.pxToRem(20))};
    height: ${props => (props.heroHeading ? props.theme.pxToRem(24) : props.theme.pxToRem(20))};
  }
  ${props => props.heroHeading && `path {fill: ${props.theme.colors.neutralWhite};}`}
  display: inline-block;
  vertical-align: top;
  margin: 2px 10px 0 0;
  width: ${props => (props.heroHeading ? props.theme.pxToRem(24) : props.theme.pxToRem(20))};
  height: ${props => (props.heroHeading ? props.theme.pxToRem(24) : props.theme.pxToRem(20))};
`;
