import React from 'react';
import { useLocation } from 'react-router';
import { FirmSupportBanner } from 'components/organisms/FirmSupportBanner/FirmSupportBanner';
import {
  TabFirmBillingManageContainer,
  TabFirmBillingMembershipAddonsContainer,
  TabFirmBillingInvoicesContainer,
  TabFirmBillingFlpOrganizationContainer,
} from 'components/App/lazy-imports';
import { getPath } from 'utils';
import { compareTabWithPath } from 'utils/routes';
import { Routes } from 'constants/index';
import { useSelector } from 'react-redux';
import { cimaFeaturesSelector } from 'modules/firmAdmin/selectors';

export const PageFirmBilling: React.FC = () => {
  const location = useLocation();
  const path: string = location.pathname;
  const useCimaFeatures = useSelector(cimaFeaturesSelector);

  const handleCompareTabWithPath = React.useCallback(
    (comparison: string) => compareTabWithPath(path, comparison),
    [path]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {handleCompareTabWithPath(getPath(Routes.FIRM_BILLING_TAB_MANAGE)) && <TabFirmBillingManageContainer />}
      {handleCompareTabWithPath(getPath(Routes.FIRM_BILLING_TAB_MEMBERSHIPS)) && (
        <TabFirmBillingMembershipAddonsContainer />
      )}
      {handleCompareTabWithPath(getPath(Routes.FIRM_BILLING_TAB_FLP_ORGANIZATION)) && (
        <TabFirmBillingFlpOrganizationContainer />
      )}
      {handleCompareTabWithPath(getPath(Routes.FIRM_BILLING_TAB_INVOICES)) && <TabFirmBillingInvoicesContainer />}
      {handleCompareTabWithPath(getPath(Routes.FIRM_BILLING_TAB_SUPPORT)) && (
        <FirmSupportBanner useCimaFeatures={useCimaFeatures} />
      )}
    </>
  );
};
