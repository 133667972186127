import { MomentHelpers } from 'utils/MomentHelpers';
import { createSelector } from 'reselect';
import { SmartyStreets, ZuoraTypes, Cart, CommerceTypes, Orders, Salesforce, Product, Checkout } from 'mxp-schemas';
import { matchPath } from 'react-router-dom';
import { getLocation } from 'connected-react-router';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';
import {
  itemsPriceDataProductSelector,
  productsListDataSummarySelector,
  hasExistingZuoraPurchaseSelector,
} from 'modules/products/selectors';
import {
  userFirstNameSelector,
  userLastNameSelector,
  currentJourneyLearningPathwaySelector,
  membershipSelector,
  isPrimaryAddressAmericasSelector,
} from 'modules/user/selectors';
import { formatPrice, fromCentsFormat } from 'mxp-utils/dist/lib/utils';
import { emptyArray } from 'utils';
import {
  isRenewalSeasonSelector,
  currentMembershipProduct,
  isCimaRenewalSeasonSelector,
  clickedMembershipUpgradeSelector,
  isFLPSwitchOrUpgradeSelector,
} from 'modules/membership/selectors';
import { Admin as AdminUtils } from 'mxp-utils';

// ------------------------------------
// Selectors
// ------------------------------------

export const checkoutSelector = createSelector(
  (state: State.Root) => state.checkout,
  (checkout: State.Checkout): State.Checkout => checkout
);

export const checkoutShippingAddressSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.Address => ({
    addressLine1: checkout.shippingAddress.addressLine1 || '',
    addressLine2: checkout.shippingAddress.addressLine2 || '',
    city: checkout.shippingAddress.city || '',
    state: checkout.shippingAddress.state || '',
    zipCode: checkout.shippingAddress.zipCode || '',
    country: checkout.shippingAddress.country || '',
    bypassValidation: checkout.shippingAddress.bypassValidation,
  })
);
export const checkoutBillingAddressSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.Address => ({
    firstName: checkout.billingAddress.firstName || '',
    lastName: checkout.billingAddress.lastName || '',
    company: checkout.billingAddress.company || '',
    addressLine1: checkout.billingAddress.addressLine1 || '',
    addressLine2: checkout.billingAddress.addressLine2 || '',
    city: checkout.billingAddress.city || '',
    state: checkout.billingAddress.state || '',
    zipCode: checkout.billingAddress.zipCode || '',
    country: checkout.billingAddress.country || '',
    bypassValidation: checkout.billingAddress.bypassValidation,
  })
);
export const loadingSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.loading
);

export const successSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.success
);

export const zuoraAccountFetchedSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.zuoraAccountFetched
);

export const pageStepSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): string | null => checkout.pageStep
);

export const isPageStepValidForPromoCodeSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => {
    if (checkout.pageStep !== null) {
      const validPageSteps = (
        [Checkout.CheckoutStep.ADDRESS, Checkout.CheckoutStep.DETAILS, Checkout.CheckoutStep.PAYMENT] as string[]
      ).includes(checkout.pageStep);

      return validPageSteps;
    }
    return false;
  }
);

export const conferenceUserInfoSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.ConferenceUserInfo | null => checkout.conferenceUserInfo
);

export const accountIdSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): string | null => checkout.accountId
);

export const addressAutocompleteItemsSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.AddressAutocompleteItem[] => checkout.addressAutocompleteItems
);

export const defaultBillingAddressSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.Address => checkout.defaultBillingAddress
);

export const isDefaultBillingAddressExistSelector = createSelector(
  defaultBillingAddressSelector,
  (address: State.Address): boolean => Boolean(address.addressLine1 && address.city && address.state && address.zipCode)
);

export const shippingAddressSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.SalesforceAddress => checkout.shippingAddress
);

export const baseShippingAddressSelector = createSelector(
  shippingAddressSelector,
  (address: State.Address): State.Address => ({
    addressLine1: address.addressLine1 || '',
    addressLine2: address.addressLine2 || '',
    city: address.city || '',
    county: address.county || '',
    state: address.state || '',
    zipCode: address.zipCode || '',
    country: address.country || '',
    addressType: Salesforce.HomeAddressType.OFFICE_MAILING,
  })
);

export const billingAddressSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.Address => checkout.billingAddress
);

export const baseBillingAddressSelector = createSelector(
  [billingAddressSelector, userFirstNameSelector, userLastNameSelector],
  (address: State.Address, firstName: string, lastName: string): State.Address => ({
    firstName: address.firstName || firstName,
    lastName: address.lastName || lastName,
    company: address.company || '',
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2 || '',
    city: address.city,
    county: address.county || '',
    state: address.state,
    zipCode: address.zipCode || '',
    country: address.country,
  })
);

export const sameShippingAddressForBillingSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.sameShippingAddressForBilling
);

export const sameShippingAddressForPaymentSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.sameShippingAddressForPayment
);

export const creditCardsSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.CreditCard[] => checkout.creditCards
);

export const otherPaymentMethodSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.PaymentMethods => checkout.paymentMethods
);

export const associationIdSelector = createSelector(
  otherPaymentMethodSelector,
  (checkout: State.PaymentMethods): string => checkout.association.zuoraId
);

export const cimaIdSelector = createSelector(
  otherPaymentMethodSelector,
  (checkout: State.PaymentMethods): string => checkout.cima.zuoraId
);

export const isMultipleZuoraAccountsSelector = createSelector(
  otherPaymentMethodSelector,
  (checkout): boolean => !!checkout.association.zuoraId && !!checkout.cima.zuoraId
);

export const zuoraIdByEntitySelector = (legalEntity: string) =>
  createSelector(otherPaymentMethodSelector, (checkout: State.PaymentMethods): string => {
    return legalEntity?.toLowerCase() === ZuoraTypes.LegalEntity.CIMA.toLowerCase()
      ? checkout.cima.zuoraId
      : checkout.association.zuoraId;
  });

export const selectedPaymentMethodEntity = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): string | null => checkout.selectedPaymentMethodEntity
);

export const selectedCurrencySelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): string | undefined => checkout.currency
);

export const selectedCreditCardIdSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): string | null => checkout.selectedCreditCardId
);

export const isUserHasOnlyOneCardSelector = createSelector(
  creditCardsSelector,
  (cards: State.CreditCard[]): boolean => cards?.filter(card => !MomentHelpers.isCardExpired(card)).length < 2
);

export const defaultCreditCardSelector = createSelector(
  creditCardsSelector,
  (cards: State.CreditCard[]): State.CreditCard | null =>
    cards?.find((card: State.CreditCard) => card.defaultPaymentMethod) || null
);

export const isUserHasDefaultCreditCardSelector = createSelector(
  defaultCreditCardSelector,
  (card: State.CreditCard | null): boolean => Boolean(card)
);

export const savedShippingAddressCheckedSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.savedShippingAddressChecked
);

export const savedShippingAddressSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.Address | null => checkout.savedShippingAddress
);

export const savedBillingAddressCheckedSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.savedBillingAddressChecked
);

export const savedBillingAddressSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.Address | null => checkout.savedBillingAddress
);

export const isShippingAddressValidSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean | null => checkout.isShippingAddressValid
);

export const isBillingAddressValidSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean | null => checkout.isBillingAddressValid
);

export const isSecondaryAddressNeededSelector = createSelector(checkoutSelector, (checkout: State.Checkout): boolean =>
  Boolean(checkout.isSecondaryAddressNeeded)
);

export const isSecondaryBillingAddressNeededSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => Boolean(checkout.isSecondaryBillingAddressNeeded)
);

export const addressLine2Selector = createSelector(
  [isSecondaryAddressNeededSelector, isSecondaryBillingAddressNeededSelector],
  (isSecondaryAddressNeeded: boolean, isSecondaryBillingAddressNeeded: boolean): string =>
    !isSecondaryAddressNeeded && !isSecondaryBillingAddressNeeded ? 'Address Line 2 (if applicable)' : 'Address Line 2'
);

export const savedShippingAddressEditedSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => Boolean(checkout.savedShippingAddressEdited)
);

export const smartystreetsValidationSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.SmartyAddressValidationState | null => checkout.smartystreetsValidation
);

export const shippingValidationReasonSelector = createSelector(
  smartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): string | null =>
    smartystreetsValidation?.shippingAddress?.reason || null
);

export const billingValidationReasonSelector = createSelector(
  smartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): string | null =>
    smartystreetsValidation?.billingAddress?.reason || null
);

export const isShippingEmbargoedSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean | null => checkout.isShippingEmbargoed
);

export const isBillingEmbargoedSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean | null => checkout.isBillingEmbargoed
);

export const smartyShippingAddressSelector = createSelector(
  smartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): SmartyStreets.ValidationCandidate[] =>
    smartystreetsValidation?.shippingAddress?.suggestions || []
);

export const smartyBillingAddressSelector = createSelector(
  smartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): SmartyStreets.ValidationCandidate[] =>
    smartystreetsValidation?.billingAddress?.suggestions || []
);

export const smartyValidationReasonSelector = createSelector(
  smartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): SmartyStreets.ValidationReason | null =>
    (smartystreetsValidation?.shippingAddress?.reason as SmartyStreets.ValidationReason) || null
);

export const smartyValidationBillingReasonSelector = createSelector(
  smartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): SmartyStreets.ValidationReason | null =>
    (smartystreetsValidation?.billingAddress?.reason as SmartyStreets.ValidationReason) || null
);

export const smartyValidationFetchedSelector = createSelector(
  smartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): boolean =>
    Boolean(smartystreetsValidation?.smartystreetsValidationFetched)
);

export const isLoadingAutocompleteItemsSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.isLoadingAutocompleteItems
);

export const saveCreditCardForFutureSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.saveCreditCardForFuture
);

export const hasDefaultPaymentMethodSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.hasDefaultPaymentMethod
);

export const taxAmountSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): number | null => checkout.taxAmount
);

export const isSelectedCardSavedSelector = createSelector(
  [selectedCreditCardIdSelector, creditCardsSelector],
  (selectedCreditCardId: string | null, creditCards: State.CreditCard[]): boolean =>
    Boolean(selectedCreditCardId) &&
    creditCards.some((creditCard: State.CreditCard) => creditCard.id === selectedCreditCardId)
);

export const savedCardUsedSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean | null => checkout.savedCardUsed
);

export const accountHasCreditHoldSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout.creditHold === 'Yes'
);

export const isReceiptPaymentJourneySelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(
    matchPath(location.pathname, { path: getPath(Routes.PROFILE_RECEIPT_CHECKOUT), exact: true }) ||
      matchPath(location.pathname, { path: getPath(Routes.PROFILE_RECEIPT_PAYMENT_CONFIRMATION), exact: true })
  )
);

export const isReceiptLoadingSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout?.receipt?.payReceipt?.loading
);

export const getReceiptSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.Invoice | null => checkout?.receipt?.payReceipt?.receipt || null
);

export const isReceiptPaymentConfirmationResultLoadingSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean => checkout?.receipt?.paymentResult?.loading
);

export const receiptPaymentConfirmationSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): State.Invoice | null => checkout?.receipt?.paymentResult?.receiptPaymentResult
);

export const paymentVerifyResultSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): ZuoraTypes.PaymentVerifyResult | null => checkout.paymentVerifyResult
);

export const itemDiscountSelector = createSelector(
  [itemsPriceDataProductSelector, productsListDataSummarySelector],
  (itemsPrice: Cart.CartItemPriceInfo[], summary: State.ProductsListDataSummary | null): Cart.DiscountDetails[] => {
    const discounts: Cart.DiscountDetails[] = Object.values(
      itemsPrice.reduce((acc: any, itemPrice) => {
        const discountsMap: {
          [key: string]: Cart.DiscountDetails;
        } = acc;
        itemPrice.discounts.forEach(discount => {
          const label =
            discount.discountType === Cart.DISCOUNT_TYPE.MEMBERSHIP_DISCOUNT ? 'membership' : discount.label;
          if (acc[label]) {
            discountsMap[label].discountAmount = {
              ...discountsMap[label].discountAmount,
              centAmount: discountsMap[label].discountAmount.centAmount + discount.discountAmount.centAmount,
            };
            discountsMap[label].formattedDiscountAmount = formatPrice(
              fromCentsFormat(discountsMap[label].discountAmount.centAmount),
              discountsMap[label].discountAmount.currencyCode
            );
          } else {
            discountsMap[label] = {
              ...discount,
            };
          }
        });
        return discountsMap;
      }, {})
    );

    const shippingDiscounts: CommerceTypes.DiscountedLineItemPortion[] =
      summary?.shippingInfo?.discountedPrice?.includedDiscounts || emptyArray;

    shippingDiscounts.forEach(discount => {
      discounts.push({
        label: summary?.shippingPromoCode || '',
        formattedDiscountAmount: formatPrice(fromCentsFormat(-discount.discountedAmount.centAmount)),
        discountType: Cart.DISCOUNT_TYPE.CART_PROMO_DISCOUNT,
        discountAmount: discount.discountedAmount,
      });
    });

    return discounts;
  }
);

export const paymentTechCodeErrorSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): string => checkout?.paymentTechCodeError
);

export const membershipProratedAmountSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): number | undefined => checkout?.membershipProratedPrice
);

export const proratedPriceAmountSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): Orders.ZuoraInvoiceItem[] | [] => checkout?.proratedPrice
);

export const membershipRefundSelector = createSelector(
  [
    checkoutSelector,
    currentMembershipProduct,
    isRenewalSeasonSelector,
    isCimaRenewalSeasonSelector,
    currentJourneyLearningPathwaySelector,
    clickedMembershipUpgradeSelector,
    isFLPSwitchOrUpgradeSelector,
  ],
  (
    checkout: State.Checkout,
    currentMembership: Salesforce.MembershipProductAndOrder,
    isRenewalSeason: boolean,
    isCimaRenewalSeason: boolean = false,
    currentJourneyLearningPathway: string,
    isClickedMembershipUpgrade = false,
    isFlpSwitchOrUpgrade?: boolean
  ): number =>
    // check if CIMA or AICPA memberships first to determine what renewal season value to use.
    currentMembership.ctOrder?.variant?.attributes.membershipBody?.key === Product.ProductMembershipBody.CIMA
      ? isCimaRenewalSeason && !isClickedMembershipUpgrade && !isFlpSwitchOrUpgrade
        ? 0
        : checkout.membershipRefundPrice || 0
      : isRenewalSeason && !AdminUtils.isFlpPathway(currentJourneyLearningPathway) && !isClickedMembershipUpgrade // always display membershipRefund for AICPA to FLP pathway crossover and show refund amount in upgrade journey
      ? 0
      : checkout.membershipRefundPrice || 0
);

export const paymentsIdSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): string[] => checkout.paymentsId
);
export const legalEntitySelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): string | null => checkout?.legalEntity
);

export const mandateTextSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): any => checkout?.mandate
);

export const isSelectedPaymentAchSelector = createSelector(
  [selectedCreditCardIdSelector, otherPaymentMethodSelector],
  (selectedCardId: string | null, paymentMethods: State.PaymentMethods): boolean => {
    return Boolean(paymentMethods.association?.ach?.find(methods => methods.id === selectedCardId));
  }
);
export const paypalSessionIdentifierSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): any => checkout.paypalSessionIdentifier
);

export const paypalAddSuccessSelector = createSelector(
  checkoutSelector,
  (checkout: State.Checkout): boolean | null => checkout.addPaypalSuccess
);

export const isModifyingEntitySelector = createSelector(
  [membershipSelector, hasExistingZuoraPurchaseSelector, isPrimaryAddressAmericasSelector],
  (
    membership: State.Membership,
    hasExistingZuoraPurchase: boolean,
    isPrimaryAddressAmericas: boolean
  ): { isModifying: boolean; legalEntity: ZuoraTypes.LegalEntity | null } => {
    const inviteData = membership?.inviteData;

    if (inviteData?.inviteId && (!inviteData?.isPaidByFLP || !inviteData?.isPaidByFirm) && hasExistingZuoraPurchase) {
      if (
        isPrimaryAddressAmericas &&
        inviteData?.organization?.organizationCapabilities?.type !== Salesforce.LegalEntity.ASSOCIATION
      ) {
        return {
          isModifying: true,
          legalEntity: ZuoraTypes.LegalEntity.CIMA,
        };
      }
      if (
        !isPrimaryAddressAmericas &&
        inviteData?.organization?.organizationCapabilities?.type === Salesforce.LegalEntity.ASSOCIATION
      ) {
        return {
          isModifying: true,
          legalEntity: ZuoraTypes.LegalEntity.ASSOCIATION,
        };
      }
    }

    return { isModifying: false, legalEntity: null };
  }
);
