import React from 'react';
import styled from 'styled-components';
import { Button, ButtonEnums, ButtonVariants } from 'components/atoms/Button/Button';
import { Modal } from 'components/atoms/Modal/Modal';
import { Salesforce } from 'mxp-schemas';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
interface CimaModalProps {
  isOpen: boolean;
  heading: string;
  isLoading: boolean;
  selectedReview: Salesforce.Dashboard;
  showCloseButton?: boolean;
  onClose?: () => void;
  onClickCancelButton: () => void;
  onClickConfirmButton: (review: Salesforce.Dashboard) => void;
}

export const CimaModal: React.FC<CimaModalProps> = (props: CimaModalProps) => {
  const {
    isOpen,
    heading,
    isLoading,
    selectedReview,
    showCloseButton = false,
    onClose,
    onClickCancelButton,
    onClickConfirmButton,
  } = props;
  return (
    <StyledModal
      key={selectedReview?.id}
      heading={heading}
      testId="cima-modal"
      open={isOpen}
      cancelNode={
        <StyledBackButton
          onClick={onClickCancelButton}
          testId="cima-modal-cancel-button"
          size={ButtonEnums.sizes.small}
        >
          Cancel
        </StyledBackButton>
      }
      confirmNode={
        <StyledConfirm
          size={ButtonEnums.sizes.small}
          testId="cima-modal-confirm-button"
          variant={ButtonVariants.primary}
          loading={isLoading}
          onClick={onClickConfirmButton?.bind(null, selectedReview)}
        >
          Confirm
        </StyledConfirm>
      }
    >
      {showCloseButton && (
        <CloseButton onClick={onClose} data-testid="modal-authentication-close">
          <StyledCloseIcon />
        </CloseButton>
      )}
    </StyledModal>
  );
};
const StyledModal = styled(Modal)`
  &&&& {
    width: ${props => props.theme.pxToRem(590)};
    min-height: ${props => props.theme.pxToRem(200)};
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;

    .header {
      display: flex;
      justify-content: center;
      h2 {
        font-size: ${props => props.theme.fontSizes.s};
        width: ${props => props.theme.pxToRem(375)};
      }
    }
    .content {
      text-align: center;
      padding: 0 !important;
    }
  }
`;

const StyledBackButton = styled(Button)`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.pxToRem(15)};
    width: ${props => props.theme.pxToRem(160)};
    height: ${props => props.theme.pxToRem(30)};
    background: none;
    border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryPurple};
    font-weight: ${props => props.theme.fontWeights.bold};
    color: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledConfirm = styled(Button)`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.pxToRem(15)};
    width: ${props => props.theme.pxToRem(160)};
    height: ${props => props.theme.pxToRem(30)};
  }
`;
const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
  ${props => props.theme.mediaQueries.mobileOnly} {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  margin: ${props => props.theme.pxToRem(15)};
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
  display: block;
  position: absolute;
  right: 0;
  top: 0;
`;
