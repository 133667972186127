import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router';
import { HeaderGlobal } from 'components/molecules/HeaderGlobal/HeaderGlobal';
import { HeaderSearch } from 'components/molecules/HeaderSearch/HeaderSearch';
import { MegaMenu } from 'components/molecules/MegaMenu/MegaMenu';
import { OnlyDesktop } from 'components/atoms';
import styled from 'styled-components';
import { getZIndexFor, Z_INDEX_ORDER } from 'utils/getZIndexFor';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { MembershipBanner } from 'components/molecules/MembershipBanner/MembershipBanner';
import { isPdfSelector } from 'modules/amlforms/selector';
import { GenericBanner } from '../GenericBanner/GenericBanner';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_SITE_BANNER } from 'modules/featureToggle/constants';

interface Props {
  isAuth: boolean | null;
  isClientAdmin: boolean;
  isAdminPortal?: boolean;
  burgerExpanded: boolean;
  userSettingsExpanded: boolean;
  orgProfileExpanded: boolean;
  navigate: (path: string) => void;
  setBurger: (expanded: boolean) => void;
  setSearchModal: (expanded: boolean) => void;
  setUserSettingsExpanded: (expanded: boolean) => void;
  closeUserSettingsExpanded: () => void;
  closeAcrossLinksExpanded: () => void;
  closeOrgProfileExpanded: () => void;
  acrossLinksExpanded: boolean;
  megamenu: State.Level0Type[];
  backMegaHistory: () => void;
  clearMegaHistory: () => void;
  megaHistoryLast: string;
  clearSuggestions: () => void;
  numberOfItemsInCart: number;
  isUserMember: boolean | null;
}

export const HeaderCombined = React.memo<Props>(props => {
  const {
    isAuth,
    isClientAdmin,
    isAdminPortal = false,
    navigate,
    setBurger,
    setSearchModal,
    userSettingsExpanded,
    orgProfileExpanded,
    closeUserSettingsExpanded,
    closeOrgProfileExpanded,
    setUserSettingsExpanded,
    burgerExpanded,
    megamenu,
    acrossLinksExpanded,
    closeAcrossLinksExpanded,
    backMegaHistory,
    megaHistoryLast,
    clearMegaHistory,
    clearSuggestions,
    numberOfItemsInCart,
    isUserMember,
  } = props;

  const hideOnPrint = useSelector(isPdfSelector);

  const location = useLocation();
  const path: string = location.pathname;

  const isCategoryPage = Boolean(matchPath(path, { path: getPath(Routes.CATEGORY_AGGS_PAGE_CIMA), exact: false }));
  const useSiteBanner = useSelector(state => getFeatureToggleByKeySelector(state as State.Root, USE_SITE_BANNER));

  return (
    // TODO: To be converted to desktop CSS
    <HeaderCombinedContainer hideOnPrint={hideOnPrint} data-testid="header-combined" id="header-combined">
      <>
        <StyledSkipContent tabIndex={-1}>
          <a href="#mainContent">. </a>
        </StyledSkipContent>
      </>
      {isAuth && isAdminPortal && <StyledHeightDiv />}
      <HeaderGlobal
        currentPath={path}
        isClientAdmin={isClientAdmin}
        setBurger={setBurger}
        setSearchModal={setSearchModal}
        navigate={navigate}
        userSettingsExpanded={userSettingsExpanded}
        orgProfileExpanded={orgProfileExpanded}
        closeUserSettingsExpanded={closeUserSettingsExpanded}
        closeAcrossLinksExpanded={closeAcrossLinksExpanded}
        closeOrgProfileExpanded={closeOrgProfileExpanded}
        setUserSettingsExpanded={setUserSettingsExpanded}
        burgerExpanded={burgerExpanded}
        acrossLinksExpanded={acrossLinksExpanded}
        backMegaHistory={backMegaHistory}
        megaHistoryLast={megaHistoryLast}
        clearMegaHistory={clearMegaHistory}
        numberOfItemsInCart={numberOfItemsInCart}
      />
      <OnlyDesktop>
        <HeaderSearch navigate={navigate} clearSuggestions={clearSuggestions} setSearchModal={setSearchModal} />
        <MegaMenu
          testId="mega-menu"
          megaMenuData={megamenu}
          setBurger={setBurger}
          clearMegaHistory={clearMegaHistory}
        />
      </OnlyDesktop>
      {useSiteBanner && isAuth && <GenericBanner />}
      {useSiteBanner && !isAuth && <GenericBanner logoutBanner />}
      {isAuth && !isUserMember ? <MembershipBanner /> : isCategoryPage && <MembershipBanner />}
    </HeaderCombinedContainer>
  );
});

const StyledHeightDiv = styled.div`
  height: ${props => props.theme.pxToRem(45)};
`;

const HeaderCombinedContainer = styled.div<{ hideOnPrint?: boolean }>`
  position: relative;
  z-index: ${getZIndexFor(Z_INDEX_ORDER.HeaderCombinedContainerDesk)};
  background: ${props => props.theme.colors.neutralWhite};

  ${props => props.theme.mediaQueries.mobileOnly} {
    z-index: ${getZIndexFor(Z_INDEX_ORDER.HeaderCombinedContainerMob)};
    background: white !important;
  }
  @media print {
    ${props => `
      display: ${props.hideOnPrint ? 'none' : ''};
    `};
  }
`;

const StyledSkipContent = styled.div`
  a {
    position: absolute;
    z-index: 100;
    width: ${props => props.theme.pxToRem(10)};
    height: ${props => props.theme.pxToRem(10)};
    color: ${props => props.theme.colors.neutralBlack};
    font-size: ${props => props.theme.fontSizes.xxs};
  }
`;
