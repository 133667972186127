import React, { useState, createRef, useEffect } from 'react';
import { StrictDropdownProps } from 'semantic-ui-react';
import { Link, LinkEnums, Label } from 'components/atoms';
import { InputWithValidation } from 'components/molecules/InputWithValidation/InputWithValidation';
import { MarketingOptions } from 'components/molecules/MarketingOptions/MarketingOptions';
import { PasswordInput } from 'components/molecules/PasswordInput/PasswordInput';
import { CountriesDropdown } from './CountriesDropdown';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { getPath } from 'utils';
import { Utils, ConvertFromKeyToISOAlpha3, CheckoutCountriesListHash } from 'mxp-utils';
import { Routes } from 'constants/index';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { RadioOption } from 'components/molecules/RadioOption/RadioOption';
import { MobileInput } from 'components/atoms/Input/MobileInput';
import { User } from 'mxp-schemas';
import { resolveEmail } from 'modules/user/actions';

interface RegistrationForm {
  firstName: string;
  lastName: string;
  email: string;
  reEmail: string;
  password: string;
  exclusiveContent: boolean;
  businessContent: boolean;
  countryCode: string;
  mobileNumber: {
    phoneNumber: string;
    countryCode: string;
    dialCode: string;
  };
  optToReceiveSMS: boolean;
  CPA?: boolean;
}

interface RegistrationFormProps {
  setFormValid: (valid: boolean) => void;
  registrationForm: RegistrationForm;
  setRegistrationForm: ({
    firstName,
    lastName,
    email,
    reEmail,
    password,
    exclusiveContent,
    businessContent,
    countryCode,
    mobileNumber,
    optToReceiveSMS,
    CPA,
  }: RegistrationForm) => void;
  inviteData?: User.MembershipInviteData;
}

const EMAIL_FIELD_ID = 'email';
const F_NAME_FIELD_ID = 'first-name';
const L_NAME_FIELD_ID = 'last-name';

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  registrationForm,
  setRegistrationForm,
  setFormValid,
  inviteData,
}) => {
  const dispatch = useDispatch();
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isFirstnameValid, setIsFirstnameValid] = useState(false);
  const [isLastnameValid, setIsLastnameValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [emailIsFocused, setEmailIsFocused] = useState(false);
  const [countrySelected, setCountrySelected] = useState('');
  const location = useLocation() as any;
  const passedEmail = location?.state?.email;
  const [phoneObject, setPhoneObject] = useState({
    phoneNumber: '',
    countryCode: '',
    dialCode: '',
  });
  const [cpaSelected, setCPASelected] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const emailIsEmpty = !Boolean(registrationForm.email.length);
  const reEmailIsEmpty = !Boolean(registrationForm.reEmail.length);
  const isEmailValid = Utils.isEmailFormatValid(registrationForm.email);
  const [isEmailValidOnOutOfFocus, setIsEmailValidOnOutOfFocus] = useState(false);
  const isEmailValidOrEmpty = React.useMemo(
    () => (isEmailValid || emailIsEmpty) && isEmailValidOnOutOfFocus,
    [emailIsEmpty, isEmailValid, isEmailValidOnOutOfFocus]
  );
  const reEmailRef: any = createRef();

  const fieldsMatch = registrationForm.reEmail === registrationForm.email || emailIsEmpty || reEmailIsEmpty;
  const firstNameCorrect = Boolean(registrationForm.firstName);
  const lastNameCorrect = Boolean(registrationForm.lastName);
  const isCountrySelected = Boolean(countrySelected);

  const isCPASelected = (countrySelected === 'US' && cpaSelected) || countrySelected !== 'US';

  const { phoneNumber: enteredPhoneNumber, countryCode: selectedCountryCode } = phoneObject;
  const isUK = selectedCountryCode === CheckoutCountriesListHash.GBR.value;
  const isUS = selectedCountryCode === CheckoutCountriesListHash.USA.value;
  const isPhoneEmpty = !Boolean(enteredPhoneNumber.length);
  const isCountryCodeEmpty = !Boolean(selectedCountryCode.length);
  const isPrimaryPhoneValid = Utils.isNumericString(enteredPhoneNumber.toString());
  const isValidMinLength = Utils.isLongerThan(enteredPhoneNumber, isUK || isUS ? 8 : 5);
  const isValidNoneRepetitive = !Utils.hasRepetitiveNumberMorethanAllowedNumber(enteredPhoneNumber, 4);
  const isValidNoneSequential = !Utils.hasSequentialNumberMorethanAllowedNumber(enteredPhoneNumber, 4);
  // ^ we can increase the minimum digits for repeating and sequential digits, currently 4

  const isNameValidFormat = (string: string) => {
    return (
      !Utils.isShorterThan(string, 2) &&
      !Utils.isLongerThan(string, 50) &&
      !Utils.hasSpecialChar(string) &&
      Utils.isAlphaSpaceString(string)
    );
  };

  const isMobileNumberValid =
    !isPhoneEmpty &&
    !isCountryCodeEmpty &&
    isPrimaryPhoneValid &&
    isValidMinLength &&
    isValidNoneRepetitive &&
    isValidNoneSequential;

  // error messages
  const mobileErrorMessages = () => {
    // empty country code and mobile number
    if (isCountryCodeEmpty && isPhoneEmpty) return 'Please select your country code and enter a valid mobile number';
    // empty country code
    if (isCountryCodeEmpty) return 'Please enter your country code';
    // empty mobile number
    if (isPhoneEmpty) return 'Please enter your mobile number'; // empty mobile number
    // invalid number
    if (!isMobileNumberValid) return 'Please enter a valid mobile number'; // invalid number

    return null;
  };

  const mobileErrorMessage = mobileErrorMessages();

  const usePrevious = <T extends boolean>(value: T): T | undefined => {
    const ref = React.useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevFocus = usePrevious(emailIsFocused);

  React.useEffect(() => {
    if (isEmailValid && Boolean(prevFocus)) {
      setIsEmailLoading(true);
      const checkEmail: any = async (email: string) => {
        const resolve = await dispatch(resolveEmail(email));
        setIsEmailValidOnOutOfFocus(resolve.payload);
        setIsEmailLoading(false);
      };
      checkEmail(registrationForm.email);
    }
  }, [emailIsFocused, dispatch, isEmailValid, prevFocus, registrationForm.email]);

  React.useEffect(() => {
    if (registrationForm.email || !passedEmail) return;

    setRegistrationForm({ ...registrationForm, email: passedEmail });

    const isPassedEmailValid = Utils.isEmailFormatValid(passedEmail);

    if (isPassedEmailValid && reEmailRef && reEmailRef.current) {
      reEmailRef.current.focus();
    }
  }, [passedEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setCountrySelected('');
  }, []);

  React.useEffect(() => {
    setRegistrationForm({
      ...registrationForm,
      mobileNumber: { ...phoneObject },
      countryCode: ConvertFromKeyToISOAlpha3(countrySelected || 'US'),
    });
  }, [phoneObject]); // eslint-disable-line react-hooks/exhaustive-deps

  const marketingNewOptions = [
    {
      id: 'exclusive-content',
      onChange: () =>
        setRegistrationForm({ ...registrationForm, exclusiveContent: !registrationForm.exclusiveContent }),
      checked: registrationForm.exclusiveContent,
      testId: 'test-exclusive-content',
      label: 'I would like to receive best offers in my inbox.',
    },
    {
      id: 'business-services',
      onChange: () => setRegistrationForm({ ...registrationForm, businessContent: !registrationForm.businessContent }),
      checked: registrationForm.businessContent,
      testId: 'test-business-services',
      label: 'I would like to receive third party offers in my inbox.',
    },

    {
      id: 'sms-services',
      onChange: () => setRegistrationForm({ ...registrationForm, optToReceiveSMS: !registrationForm.optToReceiveSMS }),
      checked: registrationForm.optToReceiveSMS,
      testId: 'test-sms-services',
      label: 'I would like to receive best offers via text message.',
    },
  ];

  React.useEffect(() => {
    if (reEmailRef && reEmailRef.current && registrationForm.email && isEmailValid) {
      reEmailRef.current.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const formValid =
      !emailIsEmpty &&
      isEmailValidOrEmpty &&
      fieldsMatch &&
      isPasswordValid &&
      firstNameCorrect &&
      lastNameCorrect &&
      isMobileNumberValid &&
      isCPASelected &&
      isConfirmPasswordValid &&
      isFirstnameValid &&
      isLastnameValid &&
      isCountrySelected;
    setFormValid(formValid);
  }, [
    registrationForm,
    isFirstnameValid,
    isLastnameValid,
    isPasswordValid,
    isConfirmPasswordValid,
    isEmailValidOrEmpty,
    fieldsMatch,
    firstNameCorrect,
    lastNameCorrect,
    setFormValid,
    emailIsEmpty,
    reEmailIsEmpty,
    isCountrySelected,
    isMobileNumberValid,
    isCPASelected,
  ]);

  // firm billing
  const [isAlreadyRepopulate, setIsAlreadyRepopulate] = useState(false);

  useEffect(() => {
    if (inviteData?.email && !isAlreadyRepopulate) {
      setRegistrationForm({
        ...registrationForm,
        email: inviteData?.email,
        firstName: inviteData?.firstName,
        lastName: inviteData?.lastName,
      });
      setIsFirstnameValid(isNameValidFormat(inviteData?.firstName));
      setIsLastnameValid(isNameValidFormat(inviteData?.lastName));
      setIsAlreadyRepopulate(true);
    }
  }, [inviteData, registrationForm, setRegistrationForm, isAlreadyRepopulate]);

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRegistrationForm({ ...registrationForm, email: event.target.value });
    },
    [registrationForm, setRegistrationForm]
  );

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, password: event.target.value });
    if (confirmPassword.length) {
      setIsConfirmPasswordValid(event.target.value === confirmPassword);
    }
  };

  const handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    setIsConfirmPasswordValid(Boolean(event.target.value === registrationForm.password));
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, firstName: event.target.value });
    setIsFirstnameValid(isNameValidFormat(event.target.value));
  };

  const removeSpaceFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!Utils.isAlphaSpaceString(event.target.value)) {
      setRegistrationForm({ ...registrationForm, firstName: event.target.value.trim() });
      setIsFirstnameValid(isNameValidFormat(event.target.value.trim()));
    }
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, lastName: event.target.value });
    setIsLastnameValid(isNameValidFormat(event.target.value));
  };

  const removeSpaceLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!Utils.isAlphaSpaceString(event.target.value)) {
      setRegistrationForm({ ...registrationForm, lastName: event.target.value.trim() });
      setIsLastnameValid(isNameValidFormat(event.target.value.trim()));
    }
  };

  const handleCPAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.toString() === 'Yes') {
      setCPASelected(true);
      registrationForm.CPA = true;
    } else {
      setCPASelected(true);
      registrationForm.CPA = false;
    }
    setRegistrationForm({ ...registrationForm, CPA: registrationForm.CPA });
  };

  const handleCountryChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedCountry: string = data.value as string;
      setCountrySelected(selectedCountry);
      if (selectedCountry === 'US') {
        setRegistrationForm({
          ...registrationForm,
          exclusiveContent: true,
          businessContent: true,
          optToReceiveSMS: false,
          countryCode: ConvertFromKeyToISOAlpha3(selectedCountry),
        });
      } else {
        setRegistrationForm({
          ...registrationForm,
          exclusiveContent: true,
          businessContent: false,
          optToReceiveSMS: false,
          CPA: false,
          countryCode: ConvertFromKeyToISOAlpha3(selectedCountry),
        });
        setCPASelected(false);
      }
    },
    [setRegistrationForm, registrationForm]
  );

  const noMatchErrorMessage = 'Email addresses do not match';

  const firstnameErrorMessage = 'Please enter a valid first name';

  const lastnameErrorMessage = 'Please enter a valid last name';

  const emailErrorMessage = !isEmailValidOrEmpty ? 'Please enter a valid email address' : noMatchErrorMessage;

  return (
    <>
      <StyledInputValidation
        onChange={handleEmailChange}
        name={EMAIL_FIELD_ID}
        testId={EMAIL_FIELD_ID}
        type="email"
        isCorrect={isEmailValidOrEmpty}
        labelId={EMAIL_FIELD_ID}
        labelName="Email address"
        value={registrationForm.email}
        autoFocus
        errorMessage={emailErrorMessage}
        infoMessage={'Your email will be your username'}
        externalIsValid={emailIsFocused || fieldsMatch}
        getIsFocused={setEmailIsFocused}
        isCorrectIconShown={!isEmailLoading}
        isLoading={isEmailLoading}
      />
      <StyledPasswordInput
        testId="password"
        onChange={handlePasswordChange}
        value={registrationForm.password}
        setIsPasswordValid={setIsPasswordValid}
        labelName="Create password"
      />
      <StyledInputValidation
        labelName={`Re-enter password`}
        name="confirmPassword"
        type="password"
        value={confirmPassword}
        testId="confirmPassword"
        labelId="confirmPassword"
        onChange={handleConfirmPassword}
        isCorrect={registrationForm.password === confirmPassword}
        errorMessage={'Passwords do not match.'}
        isCorrectIconShown
      />
      <StyledInputValidation
        labelName="First name"
        name={F_NAME_FIELD_ID}
        type="text"
        errorMessage={firstnameErrorMessage}
        isCorrect={isNameValidFormat(registrationForm.firstName)}
        value={registrationForm.firstName}
        testId={F_NAME_FIELD_ID}
        labelId={F_NAME_FIELD_ID}
        onChange={handleFirstNameChange}
        onClick={removeSpaceFirstName}
        isCorrectIconShown
      />

      <StyledInputValidation
        labelName="Last name"
        name={L_NAME_FIELD_ID}
        type="text"
        errorMessage={lastnameErrorMessage}
        isCorrect={isNameValidFormat(registrationForm.lastName)}
        value={registrationForm.lastName}
        testId={L_NAME_FIELD_ID}
        labelId={L_NAME_FIELD_ID}
        onChange={handleLastNameChange}
        onClick={removeSpaceLastName}
        isCorrectIconShown
      />
      <MobileInput
        phoneObject={phoneObject}
        setPhoneObject={setPhoneObject}
        errorMessage={mobileErrorMessage}
        isValidated={isMobileNumberValid}
        label="Mobile number"
      />
      <StyledCountriesDropdown
        onChange={handleCountryChange}
        title="Location"
        countrySelected={!isCountrySelected ? '' : countrySelected}
      />
      <>
        <StyledLable children="Are you a CPA?" />
        <RadioContainer>
          <RadioOptionYes
            checked={cpaSelected ? cpaSelected === registrationForm.CPA : false}
            label="Yes"
            value="Yes"
            onClick={handleCPAChange}
            data-testid="yes-radio"
          />
          <RadioOption
            checked={cpaSelected ? !cpaSelected === registrationForm.CPA : false}
            label="No"
            value="No"
            onClick={handleCPAChange}
            data-testid="no-radio"
          />
        </RadioContainer>
      </>
      <MarketingOptions options={marketingNewOptions}>
        <div>
          You can change these settings at any time in your profile.
          <br />
          <Link
            key=""
            type={LinkEnums.type.inlineLink}
            to={getPath(Routes.PRIVACY_POLICY)}
            target={'_blank'}
            testId="forgot-password-address"
          >
            How we use your information
          </Link>
          .
        </div>
      </MarketingOptions>
    </>
  );
};

const StyledLable = styled(Label)`
  padding-bottom: ${props => props.theme.pxToRem(24)};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const RadioContainer = styled.p<ThemedStyledProps<{ isRegularFont?: boolean | void }, DefaultTheme>>`
  margin-top: ${props => props.theme.pxToRem(26)};
  display: flex;
`;

const RadioOptionYes = styled(RadioOption)`
  margin-right: ${props => props.theme.pxToRem(36)};
`;

const StyledInputValidation = styled(InputWithValidation)`
  width: ${props => props.theme.pxToRem(360)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: calc(100% - ${props => props.theme.pxToRem(5)});
  }
`;
const StyledPasswordInput = styled(PasswordInput)`
  width: ${props => props.theme.pxToRem(360)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: calc(100% - ${props => props.theme.pxToRem(5)});
  }
`;

const StyledCountriesDropdown = styled(CountriesDropdown)`
  &&& {
    width: ${props => props.theme.pxToRem(360)};
    border: solid 1px ${props => props.theme.colors.neutralGrey4};
    border-radius: 6px;
    color: ${props => props.theme.colors.neutralBlack} !important;
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: calc(100% - ${props => props.theme.pxToRem(5)});
    }
  }
  &&&.dropdown .menu {
    min-width: ${props => props.theme.pxToRem(300)} !important;
  }

  &.dropdown:hover {
    border-color: ${props => props.theme.colors.neutralGrey4};
  }
  &&&.dropdown > .default {
    color: ${props => props.theme.colors.neutralBlack} !important;
  }

  &&&.dropdown .menu > .item {
    &:hover {
      color: ${props => props.theme.colors.neutralBlack} !important;
    }
  }
`;
