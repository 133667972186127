import React from 'react';
interface Props {
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => void;
}

export const ArrowBackSVG = React.memo<Props>(({ size = 24, color = '#000', className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}
    onClick={onClick}
  >
    <path fill={color} d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z" />
  </svg>
));
