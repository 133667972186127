import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import { Button, ButtonEnums, Link, OnlyDesktop } from 'components/atoms';
import { Bookmark, Bookmarked, Copy, LogoSVG } from 'components/atoms/svg';
import { theme } from 'theme';
import { getPath, isServer } from 'utils';
import { getZIndexFor, Z_INDEX_ORDER } from 'utils/getZIndexFor';
import { Routes } from 'constants/index';

interface Props {
  top: number;
  bottom: number;
  title: string;
  categorySlug: string;
  categoryDisplayName: string;
  toggleSavedItem: (e: React.SyntheticEvent<HTMLElement>) => void;
  handleCopyToggle: () => void;
  isContentSaved: boolean;
  contentId: string;
  children?: any;
}

export const HeaderCopySave = React.memo<Props>(
  ({
    top,
    bottom,
    title,
    categorySlug,
    categoryDisplayName,
    isContentSaved,
    toggleSavedItem,
    contentId,
    handleCopyToggle,
    children,
  }) => {
    if (!isServer && (top > 0 || bottom < window.innerHeight)) return null;

    return (
      <Bar data-testid="content-page-content-bar">
        {children}
        <ContentPageWrapper>
          <Grid columns={2}>
            <StyledColumnLeft computer={10}>
              <FlexDiv>
                <Link to={getPath(Routes.FEED)} testId="content-page-content-header-home">
                  <StyledLogoSVG size={40} />
                </Link>
                <StyledOnlyDesktop>
                  <VerticalDivider />
                  <HeaderText>
                    <StyledTitle>{title}</StyledTitle>
                    <StyledLink to={`/search?category=${categorySlug}`} testId="content-page-content-header-category">
                      {categoryDisplayName}
                    </StyledLink>
                  </HeaderText>
                </StyledOnlyDesktop>
              </FlexDiv>
            </StyledColumnLeft>

            <StyledColumnRight computer={6}>
              <StyledCopyButton
                testId={`copy-button-${contentId}`}
                onClick={handleCopyToggle}
                variant={ButtonEnums.variants.icon}
              >
                <Copy color={theme.colors.neutralGrey4} />
              </StyledCopyButton>
              <Button
                testId={`bookmark-button-${contentId}`}
                onClick={toggleSavedItem}
                active={isContentSaved}
                variant={ButtonEnums.variants.icon}
              >
                {isContentSaved ? <Bookmarked /> : <Bookmark />}
              </Button>
            </StyledColumnRight>
          </Grid>
        </ContentPageWrapper>
      </Bar>
    );
  }
);

const Bar = styled.div`
  height: ${props => props.theme.pxToRem(80)};
  left: 0;
  top: 0;
  width: 100%;
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
  background: ${props => props.theme.colors.neutralWhite};
  position: fixed;
  ${props => props.theme.mediaQueries.mobileOnly} {
    height: ${props => props.theme.pxToRem(60)};
  }
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  @keyframes slideIn {
    from {
      top: -80px;
    }
    to {
      top: 0;
    }
  }
  z-index: ${getZIndexFor(Z_INDEX_ORDER.HeaderCopySave)};
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const StyledOnlyDesktop = styled(OnlyDesktop)`
  display: flex;
`;

const StyledColumnRight = styled(Grid.Column)`
  &&&&&&&&&& {
    text-align: right;
    padding-top: ${props => props.theme.pxToRem(42)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-top: ${props => props.theme.pxToRem(38)};
    }
  }
`;

const StyledColumnLeft = styled(Grid.Column)`
  &&&&&&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props => props.theme.pxToRem(7)} 1rem;
    }
  }
`;

const FlexDiv = styled.div`
  display: flex;
`;

const HeaderText = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)};
`;

const StyledLogoSVG = styled(LogoSVG)`
  margin-top: ${props => props.theme.pxToRem(20)};
`;

const StyledTitle = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const ContentPageWrapper = styled.div`
  padding: 0 ${props => props.theme.pxToRem(30)} 0;
  max-width: ${props => props.theme.pxToRem(1140)};
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 1rem;
  }
`;

const VerticalDivider = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey3};
  width: ${props => props.theme.pxToRem(1)};
  flex-shrink: 0;
  margin: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(60)};
`;

const StyledCopyButton = styled(Button)`
  &&&&& {
    margin-right: ${props => props.theme.pxToRem(14)};
  }
`;
