import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { Link, LinkEnums } from 'components/atoms';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { useDispatch } from 'react-redux';
import { goToProfilePreferences } from 'modules/user/actions';

type Size = 's' | 'xs';

interface MyProfileLinkProps {
  size?: Size;
}

export const MyProfileLink: React.FC<MyProfileLinkProps> = ({ size = 's' }) => {
  const dispatch = useDispatch();
  const handler = (e: any) => {
    e.preventDefault();
    dispatch(goToProfilePreferences());
  };
  return (
    <StyledLink
      type={LinkEnums.type.standaloneLink}
      to={getPath(Routes.PROFILE_PREFERENCES)}
      testId="my-profile-link"
      size={size}
      onClick={handler}
    >
      <Icon name="cog" />
      Manage preferences
    </StyledLink>
  );
};

const StyledLink = styled(Link)<any>`
  font-size: ${props => props.theme.fontSizes[props.size]};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;
  white-space: nowrap;
  color: ${props => props.theme.colors.neutralGrey8};
  &:hover,
  &:focus {
    i {
      text-decoration: none;
    }
  }
  i {
    color: ${props => props.theme.colors.primaryPurple};
  }
`;
