import React from 'react';
import { List } from 'semantic-ui-react';
import { Tag } from '../../atoms';
import styled from 'styled-components';

interface TagItemTypes {
  name: string;
  id: string;
}

interface FilterTagsProps {
  items: TagItemTypes[];
  testId: string;
}

const ListItem = styled(List.Item)`
  &&&& {
    margin-left: 0;
    margin-right: 0.5em;
  }
`;

export const FilterTags: React.SFC<FilterTagsProps> = ({ items, testId }) => {
  if (!items.length) return null;

  return (
    <List horizontal animated data-testid={testId}>
      {items.map(item => {
        // tslint:disable-next-line
        const onRemove = () => 
        {
          // use 1 parameter in console.log
          // tslint:disable-next-line
          console.log(`Filter tag removed, ID: ${item.id}`);
        };
        // TODO: replace with dispatch prop from container

        return (
          <ListItem key={item.id}>
            <Tag {...item} onRemove={onRemove} testId={`tag_${item.id}`} />
          </ListItem>
        );
      })}
    </List>
  );
};
