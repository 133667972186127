import React from 'react';
import styled, { DefaultTheme, ThemedStyledProps, css } from 'styled-components';
import { Link, LinkEnums } from 'components/atoms/Link/Link';
import { handleEvent, EXTERNAL_LINK_EVENT } from 'utils/Analytics';
import { ReactComponent as OpenInNEW } from 'resources/images/ic-open-in-new.svg';
import { RichTextBlocks } from 'mxp-utils';
interface Props {
  title: string;
  link: string;
  testId: string;
  section?: string;
  iconPurple?: boolean;
  isNoMargin?: boolean;
  setBurger?: (state: boolean) => void;
  handleClick?: () => void;
  colorBlack?: boolean;
  isNewTab?: boolean;
}
export const FeedTargetableLink: React.FC<Props> = React.memo(
  ({
    title,
    link,
    testId = '',
    section = '',
    iconPurple = false,
    isNoMargin = false,
    setBurger,
    handleClick,
    colorBlack = false,
    isNewTab = false,
  }) => {
    if (!link) {
      return null;
    }
    const handleLinkClick = () => {
      if (handleClick) {
        handleClick();
      } else {
        if (setBurger) setBurger(false);
        handleEvent({ text: `hm:${section}:${title}`, href: link }, EXTERNAL_LINK_EVENT);
      }
    };
    const isExternal = RichTextBlocks.isExternal(link);
    return (
      <StyledItemWrapper key={`unique-link-${title}`} isNoMargin={isNoMargin}>
        <StyledLink
          to={link}
          isExternal={isExternal}
          type={isExternal ? LinkEnums.type.standaloneLink : LinkEnums.type.inlineLink}
          testId={testId}
          onClick={handleLinkClick}
          colorBlack={colorBlack}
          target={isNewTab ? '_blank' : ''}
        >
          {(isExternal || isNewTab) && <StyledIcon iconPurple={iconPurple} />}
          {title}
        </StyledLink>
      </StyledItemWrapper>
    );
  }
);

const StyledIcon = styled(OpenInNEW)<{ iconPurple: boolean }>`
  vertical-align: middle;
  display: inline-block;
  margin: 0 ${props => props.theme.pxToRem(6)} 0 0;
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
  ${props =>
    props.iconPurple &&
    `
      path {
        fill: ${props.theme.colors.primaryPurple};
      }
    `}
`;

const StyledLink = styled(Link)<{ colorBlack: boolean }>`
  ${props => `
    font-size: ${props.theme.fontSizes.xs};
    line-height: 1.57;
  `}

  ${({ colorBlack }) =>
    colorBlack &&
    css`
      color: ${props => props.theme.colors.neutralBlack};
    `}
`;

const StyledItemWrapper = styled.div<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  ${props =>
    !props.isNoMargin &&
    `
      margin-bottom: ${props.theme.pxToRem(16)};
    `}
`;
