import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Dimmer, Loader } from 'semantic-ui-react';
import { DropdownWithInfo } from 'components/molecules/DropdownWithInfo/DropdownWithInfo';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import { Divider } from 'components/atoms/Divider/Divider';
import { Input } from 'components/atoms/Input/Input';
import { InputWithValidation } from 'components/molecules/InputWithValidation/InputWithValidation';
import { DropdownProps } from 'components/atoms/Dropdown/Dropdown';
import { ProfileHeading } from 'components/molecules/ProfileHeading/ProfileHeading';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { RadioOption } from 'components/molecules/RadioOption/RadioOption';
import { ErrorNotification } from 'components/admin/atoms/ErrorNotification/ErrorNotification';
import {
  organizationAdminsList,
  invoiceDetailsSelector,
  b2bCartSelector,
  checkoutLoadingStateSelector,
  legalEntitySelector,
} from 'modules/admin/selectors';

import { B2B, Salesforce, ZuoraTypes } from 'mxp-schemas';
import { isManualProduct, Utils } from 'mxp-utils';
import {
  changeOpportunityNumber,
  changePoNumber,
  changeInvoicePresentation,
  changeClientAdmin,
  setInvoiceInClientAdminProfile,
  setSendConfirmationEmailState,
  setSendConfirmationEmails,
  changeSellingCompany,
  changeInvoiceDate,
} from 'modules/admin/actions';

interface InvoiceProps {
  setNextDisabled: (isDisabled: boolean) => void;
  generalError: string;
}

export const Invoice: React.FC<InvoiceProps> = ({ setNextDisabled, generalError }) => {
  const dispatch = useDispatch();
  const cart = useSelector(b2bCartSelector);
  const invoiceDetails = useSelector(invoiceDetailsSelector);
  const clientAdmins = useSelector(organizationAdminsList);
  const checkoutLoading = useSelector(checkoutLoadingStateSelector);
  const noClientAdminSelected = !Boolean(invoiceDetails.clientAdmin);
  const [clientAdminOptions, setClientAdminOptions] = useState<{ key: string; text: string; value: string }[]>([]);
  const [correctInvoiceDate, setCorrectInvoiceDate] = useState(true);
  const [opportunityNumberError, setOpportunityNumberError] = useState('');
  const cartHasAICPAProvisionedItemForPhysicalProduct = cart.some(
    cartItem =>
      !isManualProduct(cartItem) &&
      cartItem.userInput.provisionBy === B2B.ProvisionBy.AICPA &&
      !cartItem.productDetails.isPhysicalProduct
  );
  const hasClientAdminError = noClientAdminSelected && cartHasAICPAProvisionedItemForPhysicalProduct;
  const orderConfirmationOnWithNoEmails =
    invoiceDetails.sendConfirmationEmail.state && !Boolean(invoiceDetails.sendConfirmationEmail.to);
  const emailChunks = invoiceDetails?.sendConfirmationEmail?.to?.split(',');
  const isEmailValid =
    emailChunks?.length > 1
      ? emailChunks.every(value => Utils.isEmailFormatValid(value.trim()))
      : Utils.isEmailFormatValid(invoiceDetails?.sendConfirmationEmail?.to);
  const invalidOpportunityNumber = Boolean(opportunityNumberError);
  const isNextDisabled =
    hasClientAdminError ||
    orderConfirmationOnWithNoEmails ||
    !correctInvoiceDate ||
    invalidOpportunityNumber ||
    (!isEmailValid && invoiceDetails.sendConfirmationEmail.state);

  const legalEntity = useSelector(legalEntitySelector);
  const isLegalEntityCima = Boolean(legalEntity === ZuoraTypes.LegalEntity.CIMA.toUpperCase());

  useEffect(() => {
    const noClientAdmin = {
      key: '',
      text: 'Select Client Admin',
      value: '',
    };
    const options = clientAdmins.map(clientAdmin => {
      return {
        key: clientAdmin.email,
        text: `${clientAdmin.firstName} ${clientAdmin.lastName}`,
        value: clientAdmin.contactId,
      };
    });
    setClientAdminOptions(invoiceDetails.clientAdmin ? [noClientAdmin, ...options] : options);
  }, [clientAdmins, invoiceDetails.clientAdmin]);

  useEffect(() => {
    if (invoiceDetails.clientAdmin) {
      dispatch(
        setSendConfirmationEmails(
          clientAdminOptions.find(clientAdminOption => clientAdminOption.value === invoiceDetails.clientAdmin)?.key
        )
      );
    }
  }, [dispatch, clientAdminOptions, invoiceDetails.clientAdmin]);

  useEffect(() => {
    setNextDisabled(isNextDisabled);
  }, [setNextDisabled, isNextDisabled]);

  useEffect(() => {
    if (!invoiceDetails.invoiceDate) {
      const today = moment().format('MM/DD/YYYY');
      dispatch(changeInvoiceDate(today));
    }
    setCorrectInvoiceDate(isValidInvoiceDate(invoiceDetails.invoiceDate));
  }, [dispatch, invoiceDetails.invoiceDate]);

  useEffect(() => {
    if (legalEntity === Salesforce.LegalEntity.CIMA) {
      dispatch(changeSellingCompany(B2B.SellingCompanyCodes.Cima));
    } else if (legalEntity === Salesforce.LegalEntity.ASSOCIATION) {
      dispatch(changeSellingCompany(B2B.SellingCompanyCodes.Association));
    }
  }, [dispatch, legalEntity]); // eslint-disable-line react-hooks/exhaustive-deps

  const onOrderConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
    dispatch(setSendConfirmationEmailState(data.checked));
  };

  const onOrderConfirmationEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSendConfirmationEmails(event.target.value));
  };

  const onInvoiceInClientAdminProfileChange = (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
    dispatch(setInvoiceInClientAdminProfile(data.checked));
  };

  const onPOChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(changePoNumber(value));
  };

  const onOpportunityNumberChange = ({ target: { value } }: any) => {
    dispatch(changeOpportunityNumber(value));
    if (/O-([0-9]){6}/.test(value) || value === '') {
      setOpportunityNumberError('');
      return;
    }
    setOpportunityNumberError('Invalid Opportunity Number. Please provide format O-XXXXXX');
  };

  const onInvoicePresentationChange = (value: B2B.InvoicePresentationOptions) => {
    dispatch(changeInvoicePresentation(value));
  };

  const onSellingCompanyChange = (value: B2B.InvoicePresentationOptions) => {
    if (!isLegalEntityCima || legalEntity === '' || legalEntity === null) {
      dispatch(changeSellingCompany(value));
    }
  };

  const onSellingCompanyChangeCima = (value: B2B.InvoicePresentationOptions) => {
    if (isLegalEntityCima) {
      dispatch(changeSellingCompany(value));
    }
  };

  const onClientAdminChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeClientAdmin(data.value));
  };

  const onInvoiceDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/[0-9]|\/|[\b]/.test(value) || value === '') {
      dispatch(changeInvoiceDate(value));
    }
  };

  const aicpaBillingProducts = cart.some(
    product => !isManualProduct(product) && product.userInput.provisionBy === B2B.ProvisionBy.AICPA
  );

  const isValidInvoiceDate = (date: string) => {
    const isValid = moment(date, 'MM/DD/YYYY', true).isValid();
    const isSameMonth = moment().isSame(date, 'month');
    return isValid && isSameMonth;
  };

  return checkoutLoading ? (
    <Dimmer active={true} inverted>
      <Loader size="small" inverted>
        Loading
      </Loader>
    </Dimmer>
  ) : (
    <Wrapper>
      <PreviousPage backText="Back to address" />
      <StyledProfileHeading title="Invoice" />
      {generalError && (
        <>
          <ErrorNotification label="Uh oh. Something went wrong and your changes have not been saved. Please try again." />
          <ErrorNotification
            label={`Timestamp: ${new Date()} ${generalError ? `Detailed message: ${generalError}` : ``}`}
          />
        </>
      )}
      <StyledSectionHeading>Assign order</StyledSectionHeading>
      <StyledDropdown
        hasError={hasClientAdminError}
        label="Invoicing contact (optional)"
        warning="Client admin must be assigned if order contains products to be provisioned by AICPA.org"
        testId="dropdown-select-client-admin"
        onChange={onClientAdminChange}
        placeholder="Select Client Admin"
        options={clientAdminOptions}
        value={invoiceDetails.clientAdmin}
      />
      <StyledCheckboxes>
        <StyledCheckbox
          onChange={onOrderConfirmationChange}
          checked={invoiceDetails.sendConfirmationEmail.state}
          id="order-confirmation"
          testId="order-confirmation"
          label="Send order confirmation email"
          width={18}
          height={18}
          isCentered
        />
        {invoiceDetails.sendConfirmationEmail.state && (
          <StyledInputWithValidationWrapper>
            <StyledInputWithValidation
              type="text"
              value={invoiceDetails.sendConfirmationEmail.to}
              testId="order-confirmation-emails"
              onChange={onOrderConfirmationEmailChange}
              labelName="Enter email address(es)"
              labelId="order-confirmation-emails"
              name="order-confirmation-emails"
              isCorrect={!orderConfirmationOnWithNoEmails && isEmailValid}
              errorMessage={'Please enter valid email address(es)'}
              infoMessage={`When entering multiple email addresses, use commas "," as separators`}
            />
          </StyledInputWithValidationWrapper>
        )}
        <StyledCheckbox
          onChange={onInvoiceInClientAdminProfileChange}
          checked={invoiceDetails.displayInvoiceInClientAdminProfile}
          id="invoice-for-client-admin"
          testId="invoice-for-client-admin"
          label="Display invoice in client admin profile"
          width={18}
          height={18}
          isCentered
          disabled={noClientAdminSelected}
        />
      </StyledCheckboxes>
      <StyledDivider />
      <StyledSectionHeading>Invoice details</StyledSectionHeading>
      {!aicpaBillingProducts && (
        <StyledInputDateWithValidation
          type="text"
          value={invoiceDetails.invoiceDate}
          testId="test-invoice-date"
          onChange={onInvoiceDateChange}
          labelName="Invoice date"
          labelId="label-invoice-date"
          name="invoice-date"
          isCorrect={correctInvoiceDate}
          errorMessage={[
            'Applicable for manual billing only if back dating of invoice is required.',
            // tslint:disable-next-line
            <br />,
            'Order date must be in current accounting period.',
          ]}
          infoMessage={[
            'Applicable for manual billing only if back dating of invoice is required.',
            // tslint:disable-next-line
            <br />,
            'Order date must be in current accounting period.',
          ]}
          autoFocus
        />
      )}
      <StyledSubHeading>Selling company</StyledSubHeading>
      <RadioOption
        label="Association"
        checked={invoiceDetails.sellingCompanyCode === B2B.SellingCompanyCodes.Association && !isLegalEntityCima}
        value={B2B.SellingCompanyCodes.Association}
        onClick={onSellingCompanyChange}
        disabled={legalEntity === 'CIMA' ? true : false}
      />
      <RadioOption
        label="AICPA"
        checked={invoiceDetails.sellingCompanyCode === B2B.SellingCompanyCodes.Aicpa && !isLegalEntityCima}
        value={B2B.SellingCompanyCodes.Aicpa}
        onClick={onSellingCompanyChange}
        disabled={legalEntity === 'CIMA' ? true : false}
      />

      <RadioOption
        label="CIMA"
        checked={invoiceDetails.sellingCompanyCode === B2B.SellingCompanyCodes.Cima}
        value={B2B.SellingCompanyCodes.Cima}
        onClick={onSellingCompanyChangeCima}
        disabled={legalEntity === 'CIMA' ? false : true}
      />

      <StyledSubHeading>Invoice presentation</StyledSubHeading>
      <RadioOption
        label="Line item detail"
        helpText="Display all individual products on the invoice"
        checked={invoiceDetails.invoicePresentation === B2B.InvoicePresentationOptions.LINE_ITEM_DETAIL}
        value={B2B.InvoicePresentationOptions.LINE_ITEM_DETAIL}
        onClick={onInvoicePresentationChange}
      />
      <StyledRadioOption
        label="Order summary only"
        helpText="Display products which share an 'Invoice Bundle name' together into one line item"
        checked={invoiceDetails.invoicePresentation === B2B.InvoicePresentationOptions.ORDER_SUMMARY}
        value={B2B.InvoicePresentationOptions.ORDER_SUMMARY}
        onClick={onInvoicePresentationChange}
      />
      <StyledInput
        labelName="PO Number (optional)"
        labelId="po-number-label"
        onChange={onPOChange}
        value={invoiceDetails.poNumber}
        testId="po-number"
      />
      <StyledDivider />
      <StyledSectionHeading hasInfoText>Salesforce opportunity</StyledSectionHeading>
      <StyledInfoText>Enter valid Opportunity Number so this order can be linked back into Salesforce</StyledInfoText>
      <StyledInputWithValidation
        name="opportunity"
        labelName="Salesforce Opportunity Number (optional)"
        labelId="salesforce-opportunity-id-label"
        type="text"
        value={invoiceDetails.opportunityNumber}
        testId="salesforce-opportunity-id"
        onChange={onOpportunityNumberChange}
        placeholder="O-012345"
        isCorrect={!Boolean(opportunityNumberError)}
        errorMessage={opportunityNumberError}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 60%;
  padding-bottom: ${props => props.theme.pxToRem(200)};
`;

const StyledProfileHeading = styled(ProfileHeading)`
  margin-top: 0;
`;

const StyledRadioOption = styled(RadioOption)`
  margin-bottom: ${props => props.theme.pxToRem(24)};
`;

const StyledSectionHeading = styled.h3<{ hasInfoText?: boolean }>`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  margin-bottom: ${props => (props.hasInfoText ? props.theme.pxToRem(8) : props.theme.pxToRem(32))};
  margin-top: ${props => props.theme.pxToRem(31)};
`;

const StyledSubHeading = styled.h4`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  margin-bottom: ${props => props.theme.pxToRem(24)};
`;

const StyledDivider = styled(Divider)`
  &&& {
    margin: ${props => props.theme.pxToRem(40)} 0;
  }
`;

const StyledInfoText = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  margin-bottom: ${props => props.theme.pxToRem(19)};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledInput = styled(Input)`
  &&&& {
    &.ui.input {
      width: 100%;
      max-width: ${props => props.theme.pxToRem(360)};
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  &.ui.checkbox label {
    font-weight: ${props => props.theme.fontWeights.regular};
    font-size: ${props => props.theme.fontSizes.xs};
  }
  &&& {
    display: block;
    margin-bottom: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledDropdown = styled(DropdownWithInfo)`
  width: 100%;
  max-width: ${props => props.theme.pxToRem(360)};
`;

const StyledCheckboxes = styled.div`
  margin-top: ${props => props.theme.pxToRem(24)};
`;

const StyledInputWithValidationWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(11)};
  margin-bottom: ${props => props.theme.pxToRem(24)};
  margin-left: ${props => props.theme.pxToRem(24)};
`;

const StyledInputWithValidation = styled(InputWithValidation)`
  max-width: ${props => props.theme.pxToRem(360)};
`;

const StyledInputDateWithValidation = styled(InputWithValidation)`
  &&& {
    font-size: ${props => props.theme.pxToRem(16)};
    max-width: ${props => props.theme.pxToRem(150)};
  }
`;
