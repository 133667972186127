import { env } from '../config';

export const isDevelopment: boolean = process.env.NODE_ENV === 'development';

export const isProduction: boolean = process.env.NODE_ENV === 'production'; // tells us only if we're running local development or on a 'real' server

export const isLowerEnvironment = (environment: string) =>
  !['production', 'prod', 'preview', 'uat', 'pt'].includes(environment);

export const getIsLowerEnvironment = isLowerEnvironment(env);
