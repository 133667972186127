import React, { Children, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { flpPlatformCGMALinkConfigSelector } from 'modules/app/selectors';
import styled, { css } from 'styled-components';
import { Container } from 'semantic-ui-react';
import { theme } from 'theme';
import { FeedTargetableLink, Button, ButtonEnums } from '../../atoms';
import { StyledCheck } from 'components/atoms/svg';
import { isLastMembershipLapsedSelector } from 'modules/membership/selectors';
/**
 * CIMA Exam Operational Cards
 * @category CIMA
 * CBUILD-414
 */

interface FlpCompetencyModuleList {
  moduleTitle?: string;
  isModuleComplete?: boolean;
}
interface CimaFlpCompetencyCardsProps {
  flpId: string;
  description: string;
  flpCompetencyModuleList?: FlpCompetencyModuleList[];
  isExempted?: boolean;
  isLocked?: boolean;
  blockStatus?: string;
  handleWaiveExemptionClick?: () => void;
}

interface CardContainerProps {
  isExempted: boolean;
  isLocked?: boolean;
}

export const CimaFlpCompetencyCards: React.FC<CimaFlpCompetencyCardsProps> = ({
  flpId,
  description,
  flpCompetencyModuleList,
  isExempted = false,
  isLocked = false,
  blockStatus = '',
  handleWaiveExemptionClick,
}) => {
  const isMembershipLapsed = useSelector(isLastMembershipLapsedSelector);
  const flpPlatformCGMALink = useSelector(flpPlatformCGMALinkConfigSelector);
  const isCompletedCheck =
    flpCompetencyModuleList?.every(module => module.isModuleComplete) || blockStatus === 'Completed';

  const handleWaiveExemptionButtonClick = useCallback(() => {
    if (handleWaiveExemptionClick) handleWaiveExemptionClick();
  }, [handleWaiveExemptionClick]);

  return (
    <StyledContainer fluid>
      <CardContainer key={flpId} isExempted={isExempted} isLocked={isLocked}>
        <TopPart>
          <SubjectHeaderBlock>
            <SmallSubHeading>{description}</SmallSubHeading>
            {isExempted ? (
              <StyledExamTitle>Exempt</StyledExamTitle>
            ) : isLocked ? (
              <></>
            ) : isCompletedCheck ? (
              <StyledIndicator completed={true}>Complete</StyledIndicator>
            ) : (
              <StyledIndicator completed={false}>Not complete</StyledIndicator>
            )}
          </SubjectHeaderBlock>
          <StyledTopicUl>
            {Children.toArray(
              flpCompetencyModuleList?.map((list: FlpCompetencyModuleList, k) => {
                return (
                  <TopicBlock key={k}>
                    <StyledParagraph>{list.moduleTitle}</StyledParagraph>
                    {list.isModuleComplete && <StyledIconCheck color={theme.colors.secondaryTeal} />}
                  </TopicBlock>
                );
              })
            )}
          </StyledTopicUl>
        </TopPart>

        <BottomPart>
          {isExempted && (
            <StyledWaiveExemption
              variant={ButtonEnums.variants.link}
              testId={'waive-exemption-button'}
              onClick={handleWaiveExemptionButtonClick}
            >
              Waive exemption
            </StyledWaiveExemption>
          )}

          {!isExempted && !isLocked && !isMembershipLapsed && (
            <StyledFeedTargetableLink
              testId="flp-platform-link"
              title="Go to FLP Platform"
              link={flpPlatformCGMALink}
            />
          )}
        </BottomPart>
      </CardContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  &&&& {
    width: 100%;
  }
`;

const CardContainer = styled.div<CardContainerProps>`
  position: relative;
  display: flex;
  height: 100% !important;
  flex-direction: column;
  align-items: stretch;
  border-radius: ${props => props.theme.pxToRem(8)};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  box-sizing: border-box;
  padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(19)};

  ${({ isExempted, isLocked }) =>
    (isExempted || isLocked) &&
    css`
      background: ${props => props.theme.colors.neutralGrey1};
    `}
`;

const TopPart = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: ${props => props.theme.pxToRem(48)};
`;

const BottomPart = styled.div`
  position: absolute;
  bottom: ${props => props.theme.pxToRem(19)};
  left: ${props => props.theme.pxToRem(20)};
`;

const SubjectHeaderBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: ${props => props.theme.pxToRem(14)};
`;

const SmallSubHeading = styled.p`
  font-size: ${props => props.theme.fontSizes.xxs};
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
  line-height: 1.5;
  color: ${props => props.theme.colors.neutralGrey8};
  text-transform: uppercase;
  padding: ${props => props.theme.pxToRem(5)} 0 !important;
  box-sizing: border-box;
  margin-bottom: 0 !important;
`;

const StyledIndicator = styled.p<{ completed?: boolean }>`
  display: table;
  width: auto;
  box-sizing: border-box;
  padding: ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(12)};
  border-radius: ${props => props.theme.pxToRem(4)};
  background-color: ${props => (props.completed ? props.theme.colors.secondaryTeal : props.theme.colors.neutralGrey1)};
  color: ${props => (props.completed ? props.theme.colors.neutralWhite : props.theme.colors.neutralBlack)};
  font-size: ${props => props.theme.fontSizes.xxs};
  text-align: center;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
  margin: 0 0 ${props => props.theme.pxToRem(16)};
`;

const StyledParagraph = styled.p`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  margin-bottom: 0;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const StyledTopicUl = styled.ul`
  padding: 0 !important;
  margin: 0 !important;

  > :last-child {
    border-bottom: 0 !important;
  }
`;

const TopicBlock = styled.li`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  padding: ${props => props.theme.pxToRem(10)} 0;
  border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
`;

const StyledFeedTargetableLink = styled(FeedTargetableLink)`
  > {
    margin-bottom: 0 !important;
  }

  > svg {
    color: ${props => props.theme.colors.primaryPurple}!important;
  }
`;

const StyledIconCheck = styled(StyledCheck)`
  display: block;
`;

const StyledExamTitle = styled.span`
  display: flex;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
  font-size: ${props => props.theme.fontSizes.xxs};
  width: auto;
  box-sizing: border-box;
  text-align: center;
  height: 1.5625rem;
  font-weight: 500;
  background-color: ${props => props.theme.colors.neutralGrey3};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(12)};
  border-radius: ${props => props.theme.pxToRem(4)};
  line-height: 1.33;
`;

const StyledWaiveExemption = styled(Button)`
  &&&&& {
    display: block;
    margin-top: 0;

    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.fontSizes.s};
      text-align: justify;
    }
  }
`;
