import React from 'react';
import styled from 'styled-components';

interface SubBlockSyllabusProps {
  testId: string;
  index: number;
  header?: string;
  description?: string;
}

export const SubBlockSyllabus: React.FC<SubBlockSyllabusProps> = ({ testId, index, header, description }) => {
  return (
    <Container data-testid={testId}>
      <StyledCard>
        <StyledHeaderContainer>
          <StyledNumberedCircle>
            <StyledSpan>{index}</StyledSpan>
          </StyledNumberedCircle>
          {header && <StyledHeader dangerouslySetInnerHTML={{ __html: header }} />}
        </StyledHeaderContainer>
        {description && <StyledDescription dangerouslySetInnerHTML={{ __html: description }} />}
      </StyledCard>
      <StyledBottom />
    </Container>
  );
};

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  position: relative;
  border-radius: ${props => props.theme.pxToRem(10)};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(10)} 0
    ${props => props.theme.colors.neutralGrey3};
  max-width: ${props => props.theme.pxToRem(480)};
  width: 100%;
  min-height: ${props => props.theme.pxToRem(10)};
  background-color: ${props => props.theme.colors.neutralWhite};

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(299)};
    min-height: ${props => props.theme.pxToRem(10)};
    max-height: 100%;
    margin-bottom: ${props => props.theme.pxToRem(15)};
    text-overflow: ellipsis;
  }
`;

const StyledCard = styled.div`
  border-radius: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(4)} 0 0`};
  min-height: ${props => props.theme.pxToRem(10)};
  padding: ${props => `${props.theme.pxToRem(36)} 0 0`};
  margin-right: ${props => props.theme.pxToRem(38.5)};
  color: ${props => props.theme.colors.neutralWhite};

  ${props => props.theme.mediaQueries.mobileOnly} {
    border-radius: ${props => `${props.theme.pxToRem(10)} ${props.theme.pxToRem(10)} 0 0`};
    max-width: ${props => props.theme.pxToRem(299)};
    width: 100%;
    height: auto;
    margin-right: 0;
  }
`;

const StyledHeaderContainer = styled.div`
  max-width: ${props => props.theme.pxToRem(415.5)};
  width: 100%;
  margin-left: ${props => props.theme.pxToRem(26)};
  margin-right: ${props => props.theme.pxToRem(22)};
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: ${props => props.theme.pxToRem(4)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: calc(100% - 45px);
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.33;

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(230)};
    width: 100%;
  }
`;

const StyledNumberedCircle = styled.div`
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  margin: 0 ${props => props.theme.pxToRem(8.5)} ${props => props.theme.pxToRem(0)} 0;
  padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(8)};
  border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.primaryDarkPurple};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpan = styled.div`
  width: ${props => props.theme.pxToRem(8)};
  height: ${props => props.theme.pxToRem(16)};
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.primaryDarkPurple};
  line-height: normal;
`;

const StyledDescription = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  padding-left: ${props => props.theme.pxToRem(26)};
  font-weight: ${props => props.theme.fontWeights.light};
  padding-bottom: ${props => props.theme.pxToRem(46)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(290)};
    width: 100%;
    padding-top: ${props => props.theme.pxToRem(4)};
  }
`;

const StyledBottom = styled.div`
  max-width: ${props => props.theme.pxToRem(480)};
  width: 100%;
  height: ${props => props.theme.pxToRem(8)};
  border-radius: ${props => `0 0 ${props.theme.pxToRem(8)} ${props.theme.pxToRem(8)}`};
  background-image: linear-gradient(to right, ${props => props.theme.colors.primaryPurple} 3%, rgba(154, 32, 94, 0.8));
  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(299)};
    width: 100%;
  }
`;
