import React, { useReducer, useState } from 'react';
import {
  Divider,
  GlobalModalDimmerStyle,
  Input,
  Modal,
  NotificationBanner,
  NotificationBannerEnums,
  OnlyDesktop,
  OnlyMobile,
  Button,
  ButtonEnums,
} from 'components/atoms';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';

import { ReactComponent as IconEmail } from 'resources/images/ic-email.svg';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { TextArea } from 'semantic-ui-react';
import { isEmailFormatValid } from 'mxp-utils/dist/lib/utils';
import { useHistory } from 'react-router';
import { getPath } from '../../../../utils';
import { Routes } from '../../../../constants';
enum ProcessingState {
  Initial,
  Loading,
  Failure,
  Success,
}

interface EmailRecipient {
  email: string;
  isInvalid: boolean;
  isDuplicate: boolean;
  index: number;
}

interface Props {
  visible: boolean;
  sendEmails: (endUserEmails: string[]) => void;
  hide: () => void;
  loading: boolean;
  sendError: boolean;
  result: { success: boolean; fileName: string } | null;
}

const INITIAL_VALUE = 'INITIAL_VALUE';
const PLACEHOLDER_VALUE = 'Please use commas to separate email addresses.';
const DUPLICATE_ERROR = 'The same email address has been entered twice. Please delete one of them.';
const INVALID_EMAIL_ERROR = 'The email format does not look right. Please check for errors.';
const CHARACTERS_LIMIT_WARRNING = '255 characters limit reached.';

const CHARACTERS_LIMIT = 255;

enum EmailModalAction {
  ADD_EMAIL,
  REMOVE_EMAIL,
  CHARACTER_COUNT_CHANGE,
  CLEAR,
}

interface EmailModalState {
  recipientList: EmailRecipient[];
  errors: string[];
  warnings: string[];
  charactersLeft: number;
}

const initialState: EmailModalState = {
  recipientList: [],
  errors: [],
  warnings: [],
  charactersLeft: CHARACTERS_LIMIT,
};

const getErrorWarnings: (recipients: EmailRecipient[]) => { errors: string[]; warnings: string[] } = recipients => {
  const allEmailsString = recipients.reduce((all: string, recipient) => {
    return all.concat(recipient.email);
  }, '');

  const limitReached = allEmailsString.length >= CHARACTERS_LIMIT;
  const errors = [
    ...(recipients.some(recipient => recipient.isDuplicate) ? [DUPLICATE_ERROR] : []),
    ...(recipients.some(recipient => recipient.isInvalid) ? [INVALID_EMAIL_ERROR] : []),
  ];

  const warnings = [...(limitReached ? [CHARACTERS_LIMIT_WARRNING] : [])];

  return { errors, warnings };
};

const getReevaluatedList: (recipients: EmailRecipient[]) => EmailRecipient[] = recipients =>
  recipients.map((recipient: EmailRecipient, index: number) => ({
    ...recipient,
    index,
    isDuplicate:
      recipients.filter((existingRecipient: EmailRecipient) => existingRecipient.email === recipient.email).length > 1,
  }));

const emailModalReducer = function reducer(state: EmailModalState, action: { type: EmailModalAction; payload: any }) {
  switch (action.type) {
    case EmailModalAction.ADD_EMAIL: {
      const isInvalid = !isEmailFormatValid(action.payload);

      const recipient: EmailRecipient = {
        email: action.payload,
        isInvalid,
        isDuplicate: false, // will be updated
        index: state.recipientList.length,
      };

      const newRecipientList: EmailRecipient[] = getReevaluatedList([...state.recipientList, recipient]);

      return { ...state, ...getErrorWarnings(newRecipientList), recipientList: newRecipientList };
    }

    case EmailModalAction.REMOVE_EMAIL: {
      const newRecipientList = [
        ...state.recipientList.filter((recipient: EmailRecipient) => recipient.index !== action.payload),
      ];

      const updatedRecipientList = getReevaluatedList(newRecipientList);

      return { ...state, ...getErrorWarnings(updatedRecipientList), recipientList: updatedRecipientList };
    }

    case EmailModalAction.CHARACTER_COUNT_CHANGE: {
      const allEmailsString = state.recipientList.reduce((all: string, recipient) => {
        return all.concat(recipient.email);
      }, '');

      const charactersUsed = allEmailsString.length + action.payload.length;

      return { ...state, charactersLeft: CHARACTERS_LIMIT - charactersUsed };
    }
    default:
      return state;
  }
};

export const SendEmailsModal: React.FC<Props> = ({ visible, hide, sendEmails, loading, sendError, result }) => {
  // @ts-ignore
  const [modalState, dispatchAction] = useReducer(emailModalReducer, initialState);
  const history = useHistory();

  let processingState = ProcessingState.Initial;
  if (loading) {
    processingState = ProcessingState.Loading;
  } else if (sendError) {
    processingState = ProcessingState.Failure;
  } else if (result?.success) {
    processingState = ProcessingState.Success;
  }

  const [headEmail, setHeadEmail] = useState(INITIAL_VALUE);

  const emailHeadInputRef = React.createRef() as any;

  const hasEmailList = Boolean(modalState.recipientList?.length);

  const confirmNodeTxt = processingState === ProcessingState.Success ? 'Back to Invoice' : 'Send email';

  React.useEffect(() => {
    if (!hasEmailList) {
      setHeadEmail(INITIAL_VALUE);
    }
  }, [hasEmailList]);

  const handleAddEmail = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (['Enter', 'Tab', ',', ' ', ';', 'Escape'].includes(e.key) || e.type === 'blur') {
        e.preventDefault();

        const email = e.currentTarget.value.trim();
        e.currentTarget.value = '';
        if (email) {
          dispatchAction({ type: EmailModalAction.ADD_EMAIL, payload: email });
          setHeadEmail('');
        }
      }
    },
    [setHeadEmail]
  );

  const handleInputAreaClick = React.useCallback(() => {
    if (emailHeadInputRef?.current) {
      emailHeadInputRef.current.focus();
    }
  }, [emailHeadInputRef]);

  const removeEmailFromList = React.useCallback(
    (index: number) => {
      dispatchAction({ type: EmailModalAction.REMOVE_EMAIL, payload: index });
      dispatchAction({ type: EmailModalAction.CHARACTER_COUNT_CHANGE, payload: '' });
    },
    [dispatchAction]
  );

  const handleExitClicked = React.useCallback(() => hide(), [hide]);
  const handleConfirmClick = React.useCallback(() => {
    if (processingState === ProcessingState.Initial) {
      const emails = modalState.recipientList.map((recipient: EmailRecipient) => recipient.email);
      sendEmails(emails);
    }
    if (processingState === ProcessingState.Success) {
      history.push(getPath(Routes.ADMIN_INVOICES));
    }
  }, [history, processingState, sendEmails, modalState]);

  // handle switch focus on emailList changes from text area to normal input (only used on mobile)
  React.useEffect(() => {
    if (emailHeadInputRef?.current) {
      emailHeadInputRef.current.focus();
    }
  }, [emailHeadInputRef, modalState.recipientList]);

  const onHeadEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isBelowLimit = modalState.charactersLeft > 0;
      const isRemoving = headEmail.length > event.target.value.length;

      if (isBelowLimit || isRemoving) {
        setHeadEmail(event.target.value);
        dispatchAction({ type: EmailModalAction.CHARACTER_COUNT_CHANGE, payload: event.target.value });
      }
    },
    [modalState, headEmail]
  );

  const confirmDisabled = loading || !Boolean(modalState.recipientList.length && !modalState.errors.length);

  const confirmNode =
    modalState.recipientList?.length && processingState !== ProcessingState.Failure ? (
      <Button
        size={ButtonEnums.sizes.medium}
        testId="send-emails"
        disabled={confirmDisabled}
        variant={ButtonEnums.variants.primary}
        onClick={handleConfirmClick}
        loading={loading}
      >
        {confirmNodeTxt}
      </Button>
    ) : null;

  return (
    <>
      <GlobalModalDimmerStyle />
      <StyledModal
        closeOnDimmerClick={false}
        open={visible}
        onClose={handleExitClicked}
        centered
        icon={<IconEmail />}
        showCloseCross
        heading={
          processingState === ProcessingState.Failure ? (
            <>An error occurred!</>
          ) : processingState === ProcessingState.Success ? (
            <>Your request is being processed</>
          ) : (
            <>Please enter email addresses</>
          )
        }
        confirmNode={confirmNode}
        success={processingState === ProcessingState.Success}
      >
        {processingState === ProcessingState.Failure ? (
          <FlexWrapMain>Please try again later.</FlexWrapMain>
        ) : processingState === ProcessingState.Success ? (
          <FlexWrapMainStyled>A confirmation email will be sent to you when complete.</FlexWrapMainStyled>
        ) : (
          <>
            <FlexWrapMain onClick={handleInputAreaClick}>
              <ModalContent>To: </ModalContent>
              <FlexWrap>
                {modalState.recipientList.map((emailListItem: EmailRecipient) => (
                  <EmailBubble
                    hasErrors={emailListItem.isDuplicate || emailListItem.isInvalid}
                    email={emailListItem.email}
                    index={emailListItem.index}
                    handleRemove={removeEmailFromList}
                    key={`${emailListItem.email}${emailListItem.index}`}
                  />
                ))}
                <InputHeadField
                  handleAddEmail={handleAddEmail}
                  value={headEmail}
                  handleChange={onHeadEmailChange}
                  inputRef={emailHeadInputRef}
                  hasEmailList={hasEmailList}
                />
              </FlexWrap>
            </FlexWrapMain>
            <StyledDivider />
            <HelperText hasError={modalState.charactersLeft === 0}>
              {modalState.charactersLeft} characters left
            </HelperText>
            <Banners>
              {modalState.errors.map((error: string) => (
                <StyledNotificationBanner
                  variant={NotificationBannerEnums.variant.red}
                  testId="checkout--warning"
                  childrenTestId="checkout-warning--text"
                  icon={<StyledIconError />}
                  key={error}
                >
                  <>{error}</>
                </StyledNotificationBanner>
              ))}

              {modalState.warnings.map((warning: string) => (
                <StyledNotificationBanner
                  variant={NotificationBannerEnums.variant.blue}
                  testId="checkout--warning"
                  childrenTestId="checkout-warning--text"
                  icon={<StyledIconError />}
                  key={warning}
                >
                  <>{warning}</>
                </StyledNotificationBanner>
              ))}
            </Banners>
          </>
        )}
      </StyledModal>
    </>
  );
};

interface EmailBubble {
  email: string;
  index: number;
  handleRemove: (index: number) => void;
  hasErrors: boolean;
}

const EmailBubble: React.FC<EmailBubble> = ({ email, index, handleRemove, hasErrors }) => {
  const removeEmail = React.useCallback(() => {
    handleRemove(index);
  }, [handleRemove, index]);

  return (
    <Bubble key={email} hasError={hasErrors}>
      <BubbleText>{email}</BubbleText>
      <CloseButton onClick={removeEmail} data-testid={`remove-email-${email}`}>
        <StyledCloseIcon />
      </CloseButton>
    </Bubble>
  );
};

interface InputHeadFieldProps {
  handleAddEmail: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: any) => void;
  hasEmailList: boolean;
  value: string;
  inputRef: any;
}

const InputHeadField: React.FC<InputHeadFieldProps> = ({
  handleAddEmail,
  handleChange,
  value,
  inputRef,
  hasEmailList,
}) => {
  const isInitial = value === INITIAL_VALUE;

  const charForWidthCalc = isInitial ? PLACEHOLDER_VALUE.length + 6 : Math.max(value.length, 4) || 1;

  const valueToDisplay = isInitial ? '' : value;
  const placeholderToDisplay = isInitial ? PLACEHOLDER_VALUE : '';

  return (
    <>
      <OnlyMobileStyled>
        {/*// textArea is used only to display placeholder on mobile */}
        {isInitial || !hasEmailList ? (
          <TextAreaStyled
            placeholder={placeholderToDisplay}
            value={valueToDisplay}
            type="email"
            onKeyDown={handleAddEmail}
            onChange={handleChange}
            onBlur={handleAddEmail}
            testId={'email-input'}
            rows={2}
            width={'100%'}
          />
        ) : (
          <StyledInput
            charNum={charForWidthCalc}
            value={valueToDisplay}
            type="email"
            onKeyDown={handleAddEmail}
            onChange={handleChange}
            onBlur={handleAddEmail}
            testId={'email-input'}
            inputRef={inputRef}
            placeholder={placeholderToDisplay}
          />
        )}
      </OnlyMobileStyled>

      <OnlyDesktop>
        <StyledInput
          charNum={charForWidthCalc}
          value={valueToDisplay}
          type="email"
          onKeyDown={handleAddEmail}
          onChange={handleChange}
          onBlur={handleAddEmail}
          testId={'email-input'}
          inputRef={inputRef}
          placeholder={placeholderToDisplay}
        />
      </OnlyDesktop>
    </>
  );
};

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  align-self: flex-start;
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(24)};
`;

const OnlyMobileStyled = styled(OnlyMobile)`
  width: 100%;
`;

const TextAreaStyled = styled(TextArea)`
  width: 100%;
`;

const StyledModal = styled(Modal)<ThemedStyledProps<{ success: boolean }, DefaultTheme>>`
  max-width: ${props => props.theme.pxToRem(605)};
  &&&&&&& {
    > .content {
      padding-bottom: 0 !important;
      padding-right: ${props => props.theme.pxToRem(20)} !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-right: 0 !important;
      }
    }
    > .header {

      padding-right: ${props => props.theme.pxToRem(20)} !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        padding-right: 0 !important;
      }
    }

    > .actions {
      border-top:${props => props.success && 'none'};
    }
    // 20 on right and top to fit close in the corner
    padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} ${props =>
  props.theme.pxToRem(40)} ${props => props.theme.pxToRem(40)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-left: ${props => props.theme.pxToRem(20)};
    }
  }
  }
`;

const StyledDivider = styled(Divider)`
  &&&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(-16)};
  }
`;

const StyledInput = styled(Input)<ThemedStyledProps<{ charNum: number }, DefaultTheme>>`
  &&&& > input {
    :focus {
      border: none;
    }
    height: ${props => props.theme.pxToRem(20)};
    border: none;

    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.colors.neutralGrey8};
    line-height: ${props => props.theme.pxToRem(24)};
    padding: 0;
    width: ${props => props.charNum}ch;

    :-ms-input-placeholder {
      color: ${props => props.theme.colors.neutralGrey8} !important;
      font-weight: ${props => props.theme.fontWeights.light} !important;
      font-size: ${props => props.theme.fontSizes.m} !important;
    }

    ::-ms-input-placeholder {
      color: ${props => props.theme.colors.neutralGrey8} !important;
      font-weight: ${props => props.theme.fontWeights.light} !important;
      font-size: ${props => props.theme.fontSizes.m} !important;
    }

    ::placeholder {
      color: ${props => props.theme.colors.neutralGrey8} !important;
      font-weight: ${props => props.theme.fontWeights.light} !important;
      font-size: ${props => props.theme.fontSizes.m} !important;
    }
  }

  margin-top: ${props => props.theme.pxToRem(3)};
`;

const FlexWrapMain = styled.div`
  display: flex;
  max-width: ${props => props.theme.pxToRem(521)};
  margin: ${props => props.theme.pxToRem(46)} auto 0;
`;

const FlexWrapMainStyled = styled(FlexWrapMain)`
  max-width: ${props => props.theme.pxToRem(400)};
  font-size: ${props => props.theme.fontSizes.s};
  text-align: center;
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const HelperText = styled.div<{ hasError: boolean }>`
  margin-top: ${props => props.theme.pxToRem(22)};
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => (props.hasError ? props.theme.colors.interfaceRed : props.theme.colors.neutralGrey8)};
`;

const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: ${props => props.theme.pxToRem(2)};
  width: 100%;
`;

const Banners = styled.div`
  margin-top: ${props => props.theme.pxToRem(18)};
`;

const ModalContent = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-right: ${props => props.theme.pxToRem(4)};
`;

const BubbleText = styled.div`
  margin-top: ${props => props.theme.pxToRem(2)};
`;

const Bubble = styled.div<ThemedStyledProps<{ hasError: boolean }, DefaultTheme>>`
  display: flex;
  height: ${props => props.theme.pxToRem(25)};
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  border-radius: ${props => props.theme.pxToRem(12.5)};
  background-color: ${props => (props.hasError ? 'rgba(240, 27, 41, 0.1)' : props.theme.colors.neutralGrey2)};
  margin-right: ${props => props.theme.pxToRem(10)};
  margin-bottom: ${props => props.theme.pxToRem(8)};
  padding: 0 ${props => props.theme.pxToRem(8)} 0 ${props => props.theme.pxToRem(13)};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(22)};
  height: ${props => props.theme.pxToRem(22)};
  margin-left: ${props => props.theme.pxToRem(8)};
  margin-bottom: 0;
  margin-top: ${props => props.theme.pxToRem(1)};
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.neutralGrey4};
  width: ${props => props.theme.pxToRem(22)};
  height: ${props => props.theme.pxToRem(22)};
`;
