import React, { useState } from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';
import { Heading, Grid, Button, ButtonEnums, Link, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { trimWithEllipsis, getPath } from 'utils';
import { Routes, FilterNames } from 'constants/index';
import { ReactComponent as KeyboardArrowUp } from 'resources/images/icon-dev-ic-keyboard-arrow-up.svg';
import { ReactComponent as KeyboardArrowDown } from 'resources/images/icon-dev-ic-keyboard-arrow-down.svg';
import { Product, Contentful } from 'mxp-schemas';
import { isColorDark } from 'utils/colorHelpers';
import {
  ExternalLinkStyles,
  ExternalLinkStylesDark,
  DefaultInlineLinkStyles,
  InlineLinkOnDarkBgStyles,
} from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
  isFullPagePagination: boolean;
}

export const BlockTileList: React.FC<Props> = ({ modularBlock, headingType, isFullPagePagination }) => {
  const block = modularBlock as any;
  const isBgColorDark: boolean = isColorDark(block.bgColor);
  const [expanded, setExpanded] = useState(false);
  const handleExpand = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const items: Contentful.LandingPages.LandingTopic[] | Contentful.ProductTypes.ProductType[] =
    block.topicList || block.productTypeList || [];
  const isTopicList = block.topicList && !block.productTypeList;

  const maxDescriptionLength = 50;
  const maxFirstDescriptionLength = 250;

  const renderSegment = (
    item: Contentful.LandingPages.LandingTopic | Contentful.ProductTypes.ProductType,
    firstItem?: boolean
  ): React.ReactNode => {
    const charLimit = firstItem ? maxFirstDescriptionLength : maxDescriptionLength;
    const isProductWebcastOrConference: boolean = Boolean(
      item.slug === Product.ProductSlug.WEBCAST || item.slug === Product.ProductSlug.CONFERENCE
    );

    return (
      <Segment>
        {isTopicList && (
          <StyledLink
            to={generatePath(getPath(Routes.PRODUCT_AGGS_BY_TOPIC_PAGE), {
              topicSlug: item.slug,
            })}
            data-testid={`btl-segment-${item.slug}`}
            isBlockLink
            aria-label={`Link to ${item?.slug?.split('-').join(' ')}`}
          />
        )}
        {!isTopicList && (
          <StyledLink
            to={
              isProductWebcastOrConference
                ? `${generatePath(getPath(Routes.PRODUCT_AGGS_CALENDAR))}/?${FilterNames.CONTENT_TYPE}=${item.slug}`
                : generatePath(getPath(Routes.PRODUCT_AGGS_BY_TYPE_PAGE), {
                    productTypeSlug: item.slug,
                  })
            }
            data-testid={`btl-segment-${item.slug}`}
            isBlockLink
            aria-label={`Link to ${item?.slug?.split('-').join(' ')}`}
          />
        )}
        <StyledFigure>
          <img src={item.storefrontImage} alt={item.name} loading="lazy" />
        </StyledFigure>
        <RightSideWrapper>
          <StyledTopicHeading tabIndex={0} as="h3">
            {item.name}
          </StyledTopicHeading>
          <OnlyDesktop>
            <StyledTopicParagraph tabIndex={0}>
              {item.storefrontDescription && item.storefrontDescription?.length > charLimit
                ? trimWithEllipsis(item.storefrontDescription, charLimit)
                : item.storefrontDescription}
            </StyledTopicParagraph>
          </OnlyDesktop>
          <OnlyMobile>
            <StyledTopicParagraph>
              {item.storefrontDescription && item.storefrontDescription?.length > maxDescriptionLength
                ? trimWithEllipsis(item.storefrontDescription, maxDescriptionLength)
                : item.storefrontDescription}
            </StyledTopicParagraph>
          </OnlyMobile>
        </RightSideWrapper>
      </Segment>
    );
  };

  return (
    <>
      <StyledHeaderContainer isBgColorDark={isBgColorDark}>
        {block.header && (
          <StyledHeading
            as={headingType}
            dangerouslySetInnerHTML={{ __html: block.header }}
            isBgColorDark={isBgColorDark}
            tabIndex={0}
          />
        )}
        {block.description && (
          <StyledParagraph
            tabIndex={0}
            dangerouslySetInnerHTML={{ __html: block.description }}
            isBgColorDark={isBgColorDark}
          />
        )}
      </StyledHeaderContainer>
      {Boolean(items?.length) && (
        <StyledGrid columns="3">
          <Grid.Row stretched>
            <Grid.Column>{renderSegment(items[0])}</Grid.Column>
            <Grid.Column>{renderSegment(items[1])}</Grid.Column>
            <Grid.Column>{renderSegment(items[2])}</Grid.Column>
            <Grid.Column>{renderSegment(items[3])}</Grid.Column>
            <Grid.Column>{renderSegment(items[4])}</Grid.Column>
          </Grid.Row>
          {items.length > 5 && expanded && (
            <Grid.Row stretched>
              {(items.slice(5, Infinity) as any).map(
                (item: Contentful.LandingPages.LandingTopic | Contentful.ProductTypes.ProductType) => (
                  <Grid.Column key={item.id}>{renderSegment(item)}</Grid.Column>
                )
              )}
            </Grid.Row>
          )}
        </StyledGrid>
      )}
      {items?.length > 5 && (
        <StyledButton
          testId={isTopicList ? 'btl-view-all-fewer-topics' : 'btl-view-all-fewer-types'}
          variant={ButtonEnums.variants.standAloneLink}
          size={ButtonEnums.sizes.large}
          icon={
            expanded ? (
              <StyledKeyboardArrowUp tabIndex={0} isBgColorDark={isBgColorDark} />
            ) : (
              <StyledKeyboardArrowDown tabIndex={0} isBgColorDark={isBgColorDark} />
            )
          }
          iconPosition={ButtonEnums.iconPosition.right}
          onClick={handleExpand}
          isBgColorDark={isBgColorDark}
        >
          View {expanded ? 'fewer' : 'all'} {isTopicList ? 'topics' : 'types'}
        </StyledButton>
      )}
    </>
  );
};

const StyledHeaderContainer = styled.div<{ isBgColorDark?: boolean }>`
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  margin: 0 auto;
  .rich-text-external-link {
    ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${props => (props.isBgColorDark ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  margin-bottom: ${props => props.theme.pxToRem(16)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;

  p {
    margin: 0;
    line-height: 1.38;
  }

  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.l};

    p {
      line-height: 1.33;
    }
  }
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  max-width: ${props => props.theme.pxToRem(693)};
  margin: 0 auto;
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;
  line-height: 1.33;

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};
    line-height: 1.5;
  }
`;

const StyledGrid = styled(Grid)`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(24)};
    margin-bottom: ${props => props.theme.pxToRem(24)};

    .row {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      .segment {
        display: flex;
        padding: 0;
        flex-shrink: 0;
        border: 0;
        border-radius: ${props => props.theme.pxToRem(10)};
        margin: ${props => props.theme.pxToRem(12)} 0 !important;
        background-color: ${props => props.theme.colors.neutralWhite};
        box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);
      }

      :first-child .column:first-child .segment:first-child figure {
        width: ${props => props.theme.pxToRem(151)};
        min-width: ${props => props.theme.pxToRem(151)};

        ${props => props.theme.mediaQueries.mobileOnly} {
          width: ${props => props.theme.pxToRem(97)};
          min-width: ${props => props.theme.pxToRem(97)};
        }
      }
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(28)};
      margin-bottom: ${props => props.theme.pxToRem(20)};

      .column {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  position: static;
  display: block;
  text-decoration: none;

  :hover,
  :active,
  :focus {
    text-decoration: none;
  }

  ::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
  }
`;

const StyledFigure = styled.figure`
  position: relative;
  overflow: hidden;
  margin: 0;
  min-width: ${props => props.theme.pxToRem(104)};
  min-height: ${props => props.theme.pxToRem(104)};
  border-radius: ${props => props.theme.pxToRem(10)} 0 0 ${props => props.theme.pxToRem(10)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(97)};
    min-width: ${props => props.theme.pxToRem(97)};
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

const RightSideWrapper = styled.div`
  padding: ${props => props.theme.pxToRem(14)} ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(16)}
    ${props => props.theme.pxToRem(16)};
`;

const StyledTopicHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.pxToRem(6)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.33;
`;

const StyledTopicParagraph = styled.p`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
`;

const StyledButton = styled(Button)<{ isBgColorDark?: boolean }>`
  &&&& {
    color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
    margin: 0 auto; /* to avoid unnecessary wide clickable empty area. */
    &:hover {
      color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
    }
    &:focus {
      color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
    }
  }
`;

const StyledKeyboardArrowUp = styled(KeyboardArrowUp)<{ isBgColorDark?: boolean }>`
  path {
    fill: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  }
`;

const StyledKeyboardArrowDown = styled(KeyboardArrowDown)<{ isBgColorDark?: boolean }>`
  path {
    fill: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  }
`;
