import React, { useEffect, useState } from 'react';
import { Product } from 'mxp-schemas';
import styled from 'styled-components';

interface Props {
  contentRef?: () => void;
  sku?: string;
  productItem: Product.ProductItem;
  url: string;
  isProductFetched: boolean;
}

export const TrustSpotWidget: React.FC<Props> = React.memo(
  ({ contentRef, sku, productItem, url, isProductFetched }: Props) => {
    const inActiveSku = productItem?.variants?.find((variant: Product.Variant) => variant.isMaster)?.sku;
    const [recallLimit, setLimit] = useState(0);
    const loopLimit = 5;

    useEffect(() => {
      // Will check if trust spot widget is already loaded
      // If not loaded will call a function to load it
      if (isProductFetched) {
        const isTrustSpotLoaded = Boolean(document.getElementById('trustspot-widget-wrapper'));
        if (!isTrustSpotLoaded && recallLimit <= loopLimit) {
          setTimeout(() => {
            if ((window as any).trustspot_init && !isTrustSpotLoaded) {
              (window as any).trustspot_init();
            }
            setLimit(recallLimit + 1);
          }, 500);
        }
      }
    }, [isProductFetched, recallLimit]);
    const { name, description } = productItem;

    return (
      <div>
        <StyledRatingContainer ref={contentRef}>
          <StyledRatingTitle>Ratings and reviews</StyledRatingTitle>
          <div
            className="trustspot trustspot-main-widget"
            data-product-sku={sku || inActiveSku}
            data-name={name}
            data-url={url}
            data-image-url="{ product.image_url }"
            data-description={description}
          />
        </StyledRatingContainer>
      </div>
    );
  }
);

const StyledRatingTitle = styled.h4`
  &&& {
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.l};
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 1.33;
  }
`;

const StyledRatingContainer = styled.div`
  &&& {
    margin-top: ${props => props.theme.pxToRem(15)};
  }
`;
