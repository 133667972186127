import { createAction } from 'redux-actions';
import { createMatchSelector, push } from 'connected-react-router';
import { Dispatch } from 'redux';
import {
  ACCESS_MERIDIAN_PRODUCT,
  ACCESS_VITALSOURCE_PRODUCT,
  GET_PRODUCT_ITEM,
  GET_PRODUCTS_LIST_DATA,
  GET_RELATED_CONTENT,
  QUERY_CONTENT_BY_SEARCH,
  CANCEL_STANDING_ORDER,
  GET_PRODUCTS_BY_TYPE,
  GET_MEMBERSHIPS,
  GET_CONTENTFUL_PRODUCT,
  GET_CURRENCY,
} from 'mxp-graphql-queries';
import { emptyObject, getPath, hasTruthyValue, masterConferenceObjBuilder, setSessionStorageItem } from 'utils';
import { default as request, updateContext } from 'utils/GraphQLClient';
import { FilterActiveKeys, ProfileListSortByValues, Routes, StorageNames } from 'constants/index';
import { cancelSOrderModalMetaSelector } from 'modules/layouts/selectors';
import {
  paginationSortChange,
  setPaginationInfo,
  toggleModalProvisionProcessingOpen,
  paginationFilterActiveChange,
  setPaginationTotal,
} from 'modules/layouts';

import { bridgeUrlConfigSelector, provisioningUrlSelector } from 'modules/app/selectors';
import {
  bundleFilteredProductVariantsSelector,
  filteredProductsDetailItemsSelector,
  productPageItemSelector,
  hasAttestationVariantsSelector,
  webcastPassItemSelector,
  benefitsAccessLinksSelector,
  existingEntitySelector,
  productsListDataLineItemsSelector,
  bridgeWebconferencesSelector,
  productsListDataSelector,
} from 'modules/products/selectors';
import {
  currentMembershipProduct,
  isRenewalsJourneySelector,
  isCimaMembershipJourneySelector,
  clickedMembershipBuyAgainSelector,
} from 'modules/membership/selectors';
import { productActionNames } from './constants';
import { EXTERNAL_LINK_EVENT, handleEvent } from 'utils/Analytics';
import { MembershipTypes, Product, User, ZuoraTypes } from 'mxp-schemas';
import { refreshUserData } from 'modules/user/actions';
import { defaultProductVariantWithAttestationSkuSelector, hasFlpProductSelector } from 'modules/cart/selectors';
import {
  aicpaMembershipSelector,
  centerMembershipPackageOrganizationSelector,
  currentJourneyLearningPathwaySelector,
  isAicpaMemberSelector,
  primaryAddressSelector,
} from 'modules/user/selectors';
import { CheckoutCountriesListHash, CountriesList, ConvertFromISOAlpha3ToText } from 'mxp-utils';
import { getSessionStorageItem } from 'utils/sessionStorage';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_BRIDGE_RELEASE } from 'modules/featureToggle/constants';
import { userHasBeenLicensedBeforeSelector } from 'modules/personLevelExemption';
// ------------------------------------
// Actions
// ------------------------------------
export const getProductItem: any = createAction(
  productActionNames.GET_PRODUCT_ITEM,
  (slug: string, sku?: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const productItem = productPageItemSelector(state);
    if (slug === productItem?.slug && productItem.isSubscribed !== null) return Promise.resolve(productItem);
    dispatch(resetProductsModule());

    return request(GET_PRODUCT_ITEM, { slug, sku }).then(({ getProductBySlugOrSku }: { [key: string]: any }) => {
      if (!getProductBySlugOrSku) {
        return Promise.reject('Product Item Not Found');
      }
      return getProductBySlugOrSku;
    });
  }
);

export const getWebcastPass: any = createAction(
  productActionNames.GET_WEBCAST_PASS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const webcastPass = webcastPassItemSelector(state);
    const webcastPassSlug = 'annual-webcast-pass';

    if (webcastPass) return Promise.resolve(webcastPass);

    return request(GET_PRODUCT_ITEM, { slug: webcastPassSlug }).then(
      ({ getProductBySlugOrSku }: { [key: string]: any }) => {
        if (!getProductBySlugOrSku) {
          return null;
        }
        return getProductBySlugOrSku;
      }
    );
  }
);

export const getProductBySku: any = createAction(
  productActionNames.GET_PRODUCT_BY_SKU,
  async (sku: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    return request(GET_PRODUCT_ITEM, { slug: null, sku }).then(({ getProductBySlugOrSku }: { [key: string]: any }) => {
      if (!getProductBySlugOrSku) {
        return null;
      }
      return getProductBySlugOrSku;
    });
  }
);

// FixMe. This is duplication of content module action and redux state
export const getRelatedContentList: any = createAction(
  productActionNames.GET_RELATED_CONTENT,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const productItem: any | null = productPageItemSelector(state);
    if (!productItem) return null;

    const masterConferenceProduct: any = productItem?.masterConference
      ? await request(GET_PRODUCT_ITEM, {
          slug: productItem?.masterConference[0]?.obj?.masterData?.current?.slug['en-US'],
          sku: productItem?.masterConference[0]?.obj?.masterData?.current?.masterVariant?.sku,
        })
      : null;

    const masterConferenceObj: any = productItem?.masterConference
      ? masterConferenceObjBuilder(masterConferenceProduct?.getProductBySlugOrSku)
      : null;

    const { topics, subtopics }: { topics: string[]; subtopics: string[] } = productItem.categories.reduce(
      (accumulator: { topics: string[]; subtopics: string[] }, category: any) => {
        if (category.isMain) {
          accumulator.topics = [...accumulator.topics, category.externalId];
        } else {
          accumulator.subtopics = category.externalId
            ? [...accumulator.subtopics, category.externalId]
            : accumulator.subtopics;
        }
        return accumulator;
      },
      { topics: [], subtopics: [] }
    );

    return request(GET_RELATED_CONTENT, {
      topics,
      subtopics,
      excludeIds: [productItem.productId],
    }).then(({ getRelatedContent }: any) => {
      if (!getRelatedContent) {
        if (productItem?.masterConference && masterConferenceObj) {
          getRelatedContent.push(masterConferenceObj[0]);
          return getRelatedContent;
        }
        return Promise.reject('Related content items not found');
      }
      if (!productItem?.masterConference || !masterConferenceObj) {
        return getRelatedContent;
      }
      getRelatedContent[0] = masterConferenceObj[0];
      return getRelatedContent;
    });
  }
);

export const getPremiumContentItems: any = createAction(
  productActionNames.GET_PREMIUM_CONTENT_ITEMS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();

    const subscriptionRole = state?.products?.productItem?.contentRoleId?.key;

    if (!subscriptionRole) {
      return Promise.resolve(null);
    }

    const q = {
      searchQuery: '',
      sortBy: 'newest-first',
      page: 1,
      perPage: 120,
      filters: {
        premium: [subscriptionRole],
      },
      options: {
        onlyProducts: false,
        onlyEvents: false,
      },
    };
    return request(QUERY_CONTENT_BY_SEARCH, q).then((response: any) => {
      if (!response.getContentBySearch?.results?.length) {
        return Promise.reject('getPremiumContentItems: not found');
      }

      return Promise.resolve(response.getContentBySearch.results);
    });
  }
);
export const setSelectedDonationPrice: any = createAction('products/SET_SELECTED_PRICE');
export const setSelectedVariant: any = createAction('products/SET_SELECTED_VARIANT');
export const setSelectedBundleProductVariant: any = createAction('products/SET_SELECTED_BUNDLE_PRODUCT_VARIANT');

export const setInitialSelectedBundleProductVariants: any = createAction(
  'products/SET_INITIAL_SELECTED_BUNDLE_PRODUCT_VARIANT',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const filtered = bundleFilteredProductVariantsSelector(state);
    const productItem = productPageItemSelector(state);
    const isBundle = productItem?.productType === Product.ProductType.BUNDLE;

    if (isBundle) {
      (productItem?.bundleProducts || []).forEach((item: Product.ProductItem, index: any) => {
        const filteredVariants = filtered[index];
        if (filteredVariants.length === 1) {
          const sku = filteredVariants[0].sku || '';
          dispatch(setSelectedBundleProductVariant({ index, sku }));
          return;
        }
        dispatch(setSelectedBundleProductVariant({ index, sku: '' }));
      });
    }
  }
);

export const setInitialSelectedContributionsVariants: any = createAction(
  'products/SET_INITIAL_SELECTED_CONTRIBUTIONS_VARIANT',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const hasAttestationVariants = hasAttestationVariantsSelector(state);
    const defaultProductVariantWithAttestationSku = defaultProductVariantWithAttestationSkuSelector(state);

    const initialVariant = hasAttestationVariants?.find(
      variant => variant?.attestationRequired === Product.DonationAttestation.NO
    )?.sku;

    if (!initialVariant) {
      return;
    }

    dispatch(
      setSelectedVariant({
        sku: defaultProductVariantWithAttestationSku || initialVariant || '',
        isFreeTrialSelected: false,
      })
    );
  }
);

export const getContentfulProductData: any = createAction(
  productActionNames.GET_CONTENTFUL_PRODUCT,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const product: any = currentMembershipProduct(state);
    const benefitsAccessLinks = benefitsAccessLinksSelector(state);
    const productSlug = product?.ctOrder?.productSlug;

    if (benefitsAccessLinks) return Promise.resolve(benefitsAccessLinks);
    if (productSlug) {
      return request(GET_CONTENTFUL_PRODUCT, { slug: productSlug }).then(
        ({ getContentItemBySlug }: { [key: string]: any }) => {
          if (!getContentItemBySlug) {
            return null;
          }

          if (getContentItemBySlug.benefitsAccessLinks) {
            const accessLinks = getContentItemBySlug.benefitsAccessLinks.reduce((result: any, currentValue: any) => {
              result[currentValue.group] = result[currentValue.group] || [];
              result[currentValue.group].push(currentValue);
              return result;
            }, {});

            return accessLinks;
          }
        }
      );
    }
    return benefitsAccessLinks;
  }
);

export const getProductsListData: any = createAction(
  productActionNames.GET_PRODUCTS_LIST_DATA,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { id } = centerMembershipPackageOrganizationSelector(state);
    const match: any = createMatchSelector(getPath(Routes.CHECKOUT_PAGE))(state as any);
    const { orderNumber } = (match && match.params) || emptyObject;
    return request(GET_PRODUCTS_LIST_DATA, { orderNumber, orgId: id }).then((response: any) => {
      if (!response.getProductsListData) {
        return Promise.reject('getProductsListData: order not found');
      }
      const total = response.getProductsListData?.lineItems?.length || 0;
      const { summary, discountCodes, conferenceUserInfo, webConferences } = response.getProductsListData;
      dispatch(setPaginationInfo(getInitialPaginationInfo(total)));
      if (orderNumber && total) {
        // An order has been made check if needs to refresh the token (i.e. premium content has been purchased)
        setTimeout(() => {
          // Put in a timeout to ensure 100% that checkout is not impacted
          if (
            response.getProductsListData?.lineItems.filter(
              (lineItem: any) =>
                lineItem.variant?.attributes?.subscriptionProductType?.key === Product.SubscriptionProductType.CONTENT
            ).length > 0
          ) {
            // A premium content product has been purchase, refresh the token
            dispatch(refreshUserData());
          }
          // 2 seconds to allow for provisioning to be done
        }, 2 * 1000);
      }
      const productListData = response.getProductsListData;

      const currentState = getState();
      const currentMatch: any = createMatchSelector(getPath(Routes.CHECKOUT_PAGE))(currentState);
      const currentProductsListData = productsListDataSelector(currentState);

      // prevents overwriting response needed for order confirmation if response for all orders came after
      if (currentMatch?.params?.orderNumber && currentProductsListData?.summary) {
        return Promise.resolve(currentProductsListData);
      }

      return Promise.resolve({
        ...productListData,
        summary,
        discountCodes,
        conferenceUserInfo,
        webConferences,
      });
    });
  }
);

export const changeHistorySort: any = createAction(
  'CHANGE_HISTORY_SORT',
  (sortBy: ProfileListSortByValues) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(paginationSortChange(sortBy));

    const state: State.Root = getState();
    const filtered = filteredProductsDetailItemsSelector(state);
    const total = filtered?.length || 0;

    dispatch(setPaginationTotal(total));
  }
);
export const changeFilterActive: any = createAction(
  'CHANGE_FILTER_ACTIVE',
  (filterKey: FilterActiveKeys) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(paginationFilterActiveChange(filterKey));

    const state: State.Root = getState();
    const filtered = filteredProductsDetailItemsSelector(state);
    const total = filtered?.length || 0;

    dispatch(setPaginationTotal(total));
  }
);

export const resetProductsListData: any = createAction('products/RESET_PRODUCTS_LIST_DATA');

export const accessMeridianAction: any = async (
  isProductAccess: boolean,
  dispatch: Dispatch,
  getState: () => State.Root
) => {
  const analyticsText = 'access-product:Access now:';

  dispatch(toggleModalProvisionProcessingOpen());

  await request(ACCESS_MERIDIAN_PRODUCT, {})
    .then(({ accessMeridianProduct: { url } }) => {
      if (url) {
        window.open(url);
        if (isProductAccess) handleEvent({ text: `${analyticsText}${url}`, href: url }, EXTERNAL_LINK_EVENT);
      } else {
        dispatch(push(getPath(Routes.PRODUCT_ACCESS_ERROR_PAGE)));
      }
    })
    .catch(e => {
      dispatch(push(getPath(Routes.PRODUCT_ACCESS_ERROR_PAGE)));
    });

  return dispatch(toggleModalProvisionProcessingOpen());
};

export const accessMeridian: any = createAction(
  productActionNames.ACCESS_PROVISIONED_PRODUCT,
  (isProductAccess = false) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      return accessMeridianAction(isProductAccess, dispatch, getState);
    }
);

const accessMediusAction: any = (isProductAccess: boolean, dispatch: Dispatch, getState: () => State.Root) => {
  const analyticsText = 'access-product:Access now:';
  const state = getState();
  const urls = provisioningUrlSelector(state);
  if (urls.mediusUrl) {
    window.open(urls.mediusUrl);
    if (isProductAccess) {
      handleEvent({ text: `${analyticsText}${urls.mediusUrl}`, href: urls.mediusUrl }, EXTERNAL_LINK_EVENT);
    }
  } else {
    dispatch(push(getPath(Routes.PRODUCT_ACCESS_ERROR_PAGE)));
  }
};

const accessBridgeAction: any = (
  isProductAccess: boolean,
  dispatch: Dispatch,
  getState: () => State.Root,
  product: Product.ProductItem
) => {
  const analyticsText = 'access-product:Access now:';
  const state = getState();

  const productListDataLineItems = productsListDataLineItemsSelector(state);
  const bridgeWebconferences = bridgeWebconferencesSelector(state);
  const bridgeUrlConfig = bridgeUrlConfigSelector(state);
  const found = productListDataLineItems?.find(lineItem => lineItem.productId === product?.productId);

  // const bridgeId = found?.variant?.attributes?.bridgeId || found?.custom?.fields?.bridgeId;
  // const bridgeUserGroupId = found?.variant?.attributes?.bridgeUserGroupId || found?.custom?.fields?.bridgeUserGroupId;
  // const bridgeLiveTrainingSessionId = found?.variant?.attributes?.bridgeLiveTrainingSessionId || found?.custom?.fields?.bridgeLiveTrainingSessionId;
  // const bridgeType = found?.variant?.attributes?.bridgeType?.key || found?.custom?.fields?.bridgeType;
  const bridgeId = found?.custom?.fields?.bridgeId || found?.variant?.attributes?.bridgeId;
  const bridgeUserGroupId = found?.custom?.fields?.bridgeUserGroupId || found?.variant?.attributes?.bridgeUserGroupId;
  const bridgeLiveTrainingSessionId =
    found?.custom?.fields?.bridgeLiveTrainingSessionId || found?.variant?.attributes?.bridgeLiveTrainingSessionId;
  const bridgeType = found?.custom?.fields?.bridgeType || found?.variant?.attributes?.bridgeType?.key;
  const url = bridgeUrlConfig;

  let bridgeAccessUrl = '';

  if (found && bridgeId) {
    switch (bridgeType) {
      case User.BridgeTypes.PROGRAM:
        bridgeAccessUrl = `${url}/learner/programs/${bridgeId}`;
        break;
      case User.BridgeTypes.COURSE:
        bridgeAccessUrl = `${url}/learner/courses/${bridgeId}/launch`;
        break;
      case User.BridgeTypes.LIVE_COURSE:
        const match = bridgeWebconferences?.find(
          webConferences => webConferences.live_course_session_id === bridgeLiveTrainingSessionId
        );
        const conferenceUrl = match?.meeting_url;
        bridgeAccessUrl = conferenceUrl || '';
        break;
      default:
    }
    window.open(bridgeAccessUrl);
  } else if (found && bridgeUserGroupId) {
    // Subscriptions like cpExpress will have bridgeUserGroupId. Direct to learning library
    bridgeAccessUrl = `${url}/learner/library`;
    window.open(bridgeAccessUrl);
  }

  if (bridgeAccessUrl) {
    if (isProductAccess) {
      handleEvent({ text: `${analyticsText}${bridgeAccessUrl}`, href: bridgeAccessUrl }, EXTERNAL_LINK_EVENT);
    }
  } else {
    dispatch(push(getPath(Routes.PRODUCT_ACCESS_ERROR_PAGE)));
  }
};

export const accessMedius: any = createAction(
  productActionNames.ACCESS_PROVISIONED_PRODUCT,
  (isProductAccess = false) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      accessMediusAction(isProductAccess, dispatch, getState);
    }
);

export const accessProvisionedProduct: any = createAction(
  productActionNames.ACCESS_PROVISIONED_PRODUCT,
  (item: Common.ProductItemData | undefined, product?: Product.ProductItem) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const guid = item?.guid || product?.variants[0].GUID;
      const vitalSourceCode = item?.vitalSourceCode || product?.variants[0].vitalSourceCode;
      const productType = item?.productType || product?.variants[0].productType;
      const state = getState();

      const useBridgeRelease = getFeatureToggleByKeySelector(state as State.Root, USE_BRIDGE_RELEASE);

      const analyticsText = 'access-product:Access now:';
      const isProductAccess = true;

      if (guid) {
        if (useBridgeRelease) {
          accessBridgeAction(isProductAccess, dispatch, getState, product || item); // Why do we have product and an item?
          return;
        }
        return accessMeridianAction(isProductAccess, dispatch, getState);
      }

      if (productType === Product.ProductType.PUBLICATION && !vitalSourceCode) {
        return dispatch(push(getPath(Routes.PRODUCT_ACCESS_ERROR_PAGE)));
      }

      if (vitalSourceCode) {
        // access ebook product in VitalSource
        dispatch(toggleModalProvisionProcessingOpen());
        await request(ACCESS_VITALSOURCE_PRODUCT, { vbid: vitalSourceCode })
          .then(({ accessVitalSourceProduct: { url } }) => {
            if (url) {
              window.open(url);
              handleEvent({ text: `${analyticsText}${url}`, href: url }, EXTERNAL_LINK_EVENT);
            } else {
              dispatch(push(getPath(Routes.PRODUCT_ACCESS_ERROR_PAGE)));
            }
          })
          .catch(e => {
            dispatch(push(getPath(Routes.PRODUCT_ACCESS_ERROR_PAGE)));
          });
        return dispatch(toggleModalProvisionProcessingOpen());
      }

      if (useBridgeRelease) {
        accessBridgeAction(isProductAccess, dispatch, getState, product || item); // Why do we have product and an item?
        return;
      }
      accessMediusAction(isProductAccess, dispatch, getState);
    }
);

export const getInitialPaginationInfo = (total: number): State.PaginationInfo => {
  const page = 1;
  const perPage = 10;
  const paginationInfo: State.PaginationInfo = {
    pagination: {
      total,
      from: (page - 1) * perPage + 1,
      to: total < page * perPage ? total : page * perPage,
      perPage,
      pageNumber: page / perPage,
    },
    currentPage: 1,
    filtering: {
      history: ProfileListSortByValues.ALL_TIME,
      active: FilterActiveKeys.ACTIVE,
    },
  };
  return paginationInfo;
};

export const setSavedCardUsed: any = createAction('checkout/SET_SAVED_CARD_USED');

export const cancelStandingOrder: any = createAction(
  productActionNames.CANCEL_STANDING_ORDER,
  () =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<void> => {
      const meta: State.CancelSOrderModalMeta = cancelSOrderModalMetaSelector(getState());
      return request(CANCEL_STANDING_ORDER, meta).then(() => {
        dispatch(getProductsListData());
      });
    }
);

export const getContributions: any = createAction(
  productActionNames.GET_CONTRIBUTIONS,
  (membershipApplicationType?: Product.MembershipApplicationType) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const primaryAddress = primaryAddressSelector(state);
    const hasFlpProduct = hasFlpProductSelector(state);
    const isRenewalsJourney = isRenewalsJourneySelector(state);
    const country = primaryAddress?.country;
    const existingEntity = existingEntitySelector(state);
    const hasAicpaMembership = !!aicpaMembershipSelector(state);
    const pathway = currentJourneyLearningPathwaySelector(state);
    const aicpaMember = isAicpaMemberSelector(state);
    const hasBeenLicensedBefore = userHasBeenLicensedBeforeSelector(state);
    const isCimaMembershipJourney = isCimaMembershipJourneySelector(state);
    const clickedMembershipBuyAgain = clickedMembershipBuyAgainSelector(state);

    return request(GET_PRODUCTS_BY_TYPE, { type: Product.ProductType.CONTRIBUTION })
      .then((response: any) => {
        if (response?.getProductsByType) {
          let membershipContributions = response?.getProductsByType?.reduce((acc: any, product: any) => {
            const variants = product.variants?.reduce((accum: any, variant: any) => {
              if (variant.renewable) accum.push(variant);
              return accum;
            }, []);

            if (variants.length) acc.push({ ...product, variants });
            return acc;
          }, []);

          if (
            !(
              country === CheckoutCountriesListHash.USA.text ||
              country === CheckoutCountriesListHash.USA.ISOAlpha3Code ||
              country === CheckoutCountriesListHash.USA.value
            )
          ) {
            // if not from USA, don't include AICPA PAC fund
            membershipContributions = membershipContributions.filter(
              (product: any) =>
                product.variants[0].attestationRequired !== Product.DonationAttestation.NO &&
                product.variants[0].attestationRequired !== Product.DonationAttestation.YES
            );
          }

          let filteredMembershipKey = Product.MembershipApplicationType.CIMA;
          if (hasFlpProduct || pathway === MembershipTypes.Pathway.FLP) {
            const isPrimaryAddressAmericas =
              CountriesList.find(ctry => ctry.text === ConvertFromISOAlpha3ToText(primaryAddress?.country || ''))
                ?.isAmerica ||
              (hasBeenLicensedBefore && isCimaMembershipJourney) ||
              false;

            if (hasTruthyValue(isRenewalsJourney, hasAicpaMembership, aicpaMember, clickedMembershipBuyAgain)) {
              filteredMembershipKey =
                existingEntity.toLocaleLowerCase() === ZuoraTypes.LegalEntity.ASSOCIATION
                  ? Product.MembershipApplicationType.AICPA
                  : Product.MembershipApplicationType.CIMA;
            } else if (isPrimaryAddressAmericas) {
              filteredMembershipKey = Product.MembershipApplicationType.AICPA;
            }

            membershipContributions = membershipContributions.filter(
              (product: any) => product.membershipBody.key === filteredMembershipKey
            );
            return { membershipContributions, success: true };
          }

          if (membershipApplicationType) {
            membershipContributions = membershipContributions.filter(
              (product: any) => product.membershipBody.key === membershipApplicationType
            );
          }

          return { membershipContributions, success: true };
        }
        return { success: false };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const setMembershipsLoading: any = createAction('products/SET_MEMBERSHIPS_LOADING');

export const getMemberships: any = createAction(
  productActionNames.GET_MEMBERSHIPS,
  (type: Product.ProductType) =>
    (dispatch: Dispatch): Promise<void> => {
      dispatch(setMembershipsLoading());
      return request(GET_MEMBERSHIPS)
        .then((response: any) => {
          if (response?.getMemberships) {
            return { ...response, success: true };
          }
          return { success: false, getMemberships: [] };
        })
        .catch(() => {
          return { success: false, getMemberships: [] };
        });
    }
);

export const resetProductsModule: any = createAction(productActionNames.RESET_MODULE);

export const getCurrency: any = createAction(
  'receipts/GET_CURRENCY',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(GET_CURRENCY, {}, { type: 'customHeaders', value: JSON.stringify({}) }).then(async response => {
      const currency =
        (response?.getCurrency?.latestCurrency !== 'undefined' && response?.getCurrency?.latestCurrency) ||
        (getSessionStorageItem(StorageNames.userPreferredCurrency) as any)?.currency?.label ||
        'USD';
      const customHeaders = {
        existingEntity: response?.getCurrency?.latestEntity || '',
        currency: { label: currency },
      };
      await updateContext(customHeaders);

      return response;
    });
  }
);

export const updatePreferredCurrency: any = createAction(
  productActionNames.UPDATE_PREFERRED_CURRENCY,
  (currency: State.ProductCurrency, isManuallyToggled = false) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      // update currency context via headers
      const state = getState();
      const existingEntity = existingEntitySelector(state);

      const customHeaders = {
        existingEntity,
        currency: { label: currency.label || 'USD' },
      };
      updateContext(customHeaders);

      const payload = { currency, isCurrencyToggledByDropdown: isManuallyToggled };

      // save if user has preferred currency to session storage
      if (isManuallyToggled) {
        setSessionStorageItem({ [StorageNames.userPreferredCurrency]: payload });
      }
      return payload;
    }
);

export const updateCancelledProduct: any = createAction(
  productActionNames.UPDATE_CANCELLED_PRODUCT,
  (item: Common.ProductItemData) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const productsListDataLineItems = productsListDataLineItemsSelector(state);

    return productsListDataLineItems?.map(lineItem => {
      return {
        ...lineItem,
        subscriptionStatus:
          lineItem.productId === item.productId && lineItem.orderId === item.orderId
            ? Product.ZuoraSubscriptionStatus.CANCELLED
            : lineItem.subscriptionStatus,
      };
    });
  }
);

export const setClaimGiftAid: any = createAction(
  productActionNames.SET_CLAIM_GIFT_AID,
  (claimGiftAid: boolean) =>
    (dispatch: Dispatch, getState: () => State.Root): boolean =>
      claimGiftAid
);
