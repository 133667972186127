import * as Sentry from '@sentry/browser';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import {
  GET_SUPERVISOR_DASHBOARD,
  MUTATE_UPDATE_ASSESSOR_FEEDBACK,
  MUTATE_UPDATE_SUPERVISOR_FEEDBACK,
  MUTATE_AUDIT_REVIEW_DECISION,
  UPDATE_PER_WITHDRAW,
  UPDATE_REVIEWER_DETAILS,
  MUTATE_SEND_SUPERVISOR_REVIEW,
  GET_STUDENT_DETAILS,
  MUTATE_SEND_DIFFERENT_SUPERVISOR,
  MUTATE_EPA_STATUS_AND_COMMENTS,
  MUTATE_EXAM_CREDIT_FROM_EPA,
  VALIDATE_SUPERVISOR_TOKEN,
} from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';
import { cimaSupervisorActionNames, SaveReviewerDetailsPayload, PERBanner } from './constants';
import { Routes, StorageNames } from 'constants/index';
import { generateResponse } from './helpers';
import { Salesforce, MembershipTypes } from 'mxp-schemas';
import { findValueInArray, getModuleNameFromPath, getPath, mapValueToArray } from 'utils';
import { sendStudentEmailNotification } from 'modules/supervisor/actions';
import { showLogin } from 'modules/layouts';
import { setLocalStorageItem, removeLocalStorageItem } from 'utils/localStorage';

export const loading: any = createAction(cimaSupervisorActionNames.LOADING);

export const getSupervisorDashboard: any = createAction(
  cimaSupervisorActionNames.GET_SUPERVISOR_DASHBOARD,
  () => (dispatch: Dispatch) => {
    // add delete here
    removeLocalStorageItem([StorageNames.assessorToken, StorageNames.perModuleId, StorageNames.assessorMemberData]);
    dispatch(setCimaSupervisorState({ isCimaReviewerDashboardLoading: true }));
    return request(GET_SUPERVISOR_DASHBOARD)
      .then((response: any) => {
        if (response?.getReviewerDashboardItem) {
          const { dashboardItems, supervisor } = response?.getReviewerDashboardItem;
          dispatch(setCimaSupervisorState({ isLoadingUpdateEPA: false, isCimaReviewerDashboardLoading: false }));
          return { dashboard: dashboardItems, success: true, supervisor };
        }
        dispatch(setCimaSupervisorState({ isLoadingUpdateEPA: false, isCimaReviewerDashboardLoading: false }));
        return { dashboard: [], success: false };
      })
      .catch(err => {
        dispatch(setCimaSupervisorState({ isLoadingUpdateEPA: false, isCimaReviewerDashboardLoading: false }));
        return { dashboard: [], success: false };
      });
  }
);

export const getMemberDetails: any = createAction(
  cimaSupervisorActionNames.GET_MEMBER_DETAILS,
  (caseNumber: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const { cimaSupervisor: cimaSupervisorState } = getState();
    const item = findValueInArray(
      cimaSupervisorState.dashboard,
      (dashboardItem: any) => dashboardItem.id === caseNumber
    );
    if (item) {
      let { memberDetails, modules } = item;
      memberDetails = memberDetails as MembershipTypes.PerMemberDetails;
      modules = modules as Salesforce.CimaModulesType;

      const memberDetailsObject = {
        name: `${memberDetails.firstName} ${memberDetails?.lastName}`,
        email: memberDetails?.email ?? 'N/A',
        phoneNumber: memberDetails?.phone ?? 'N/A',
        coreActivities: modules?.coreActivity,
        skillsAndBehaviours: modules?.skillsAndBehavior,
        employmentHistory: modules?.employment,
        id: memberDetails.Id,
      };
      return memberDetailsObject;
    }
    return cimaSupervisorState.memberDetails;
  }
);

export const getEmploymentInformation: any = createAction(
  cimaSupervisorActionNames.GET_EMPLOYMENT_INFORMATION,
  (id: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const { cimaSupervisor: cimaSupervisorState } = getState();

    const item = findValueInArray(
      cimaSupervisorState.memberDetails.employmentHistory,
      (empHistoryItem: any) => empHistoryItem.id === id
    );

    if (item) {
      const {
        employer: {
          name: bussinessName,
          type: organizationType,
          addressLine1: addressLineOne,
          addressLine2: addressLineTwo,
          city,
          state,
          postalCode,
        },
        jobTitle,
        startDate,
        endDate,
        numberOfDaysPerWeek,
        employmentType,
        perAuditKey,
        feedback,
        summary,
      } = item;
      return {
        bussinessName,
        organizationType,
        addressLineOne,
        addressLineTwo,
        city,
        state,
        postalCode,
        jobTitle,
        startDate,
        endDate,
        numberOfDaysPerWeek,
        employmentType,
        perAuditKey,
        feedback,
        summary,
      };
    }
    return cimaSupervisorState.employmentInformation;
  }
);

export const getCoreActivities: any = createAction(
  cimaSupervisorActionNames.GET_CORE_ACTIVITIES,
  (perModuleId: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const { cimaSupervisor: cimaSupervisorState } = getState();

    const item = findValueInArray(
      cimaSupervisorState.memberDetails.coreActivities,
      (coreItem: any) => coreItem.id === perModuleId
    );
    if (item) {
      const { id, activityType, situation, task, action, result, status, submissionDate, feedback, perAuditKey } = item;
      return {
        id,
        activityType,
        situation,
        task,
        action,
        result,
        status,
        submissionDate,
        perAuditKey,
        feedback,
      };
    }
    return cimaSupervisorState.coreActivities;
  }
);

export const getSkillBehaviour: any = createAction(
  cimaSupervisorActionNames.GET_SKILL_BEHAVIOUR,
  (perModuleId: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const { cimaSupervisor: cimaSupervisorState } = getState();

    const item = findValueInArray(
      cimaSupervisorState.memberDetails.skillsAndBehaviours,
      (skillItem: any) => skillItem.id === perModuleId
    );

    if (item) {
      const { id, activityType, situation, task, action, result, status, submissionDate, feedback, perAuditKey } = item;
      return {
        id,
        activityType,
        situation,
        task,
        action,
        result,
        status,
        submissionDate,
        perAuditKey,
        feedback,
      };
    }
    return cimaSupervisorState.skillBehaviour;
  }
);

export const updateEmpInfoViewStatus: any = createAction(
  cimaSupervisorActionNames.UPDATED_VIEW_INFO_STATUS,
  (isViewOnly: boolean) => () => {
    return isViewOnly;
  }
);

export const UpdateFeedBack: any = createAction(
  cimaSupervisorActionNames.UPDATE_FEEDBACK,
  (feedbackDetails: Salesforce.practicalExperienceAssessorFeedback, studentId: string) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(
        setCimaSupervisorState({
          isSaveDraftLoading: feedbackDetails.feedbackStatus === 'Draft' ? true : false,
          isApprovedOrRFILoading:
            feedbackDetails.feedbackStatus === 'Approved' || feedbackDetails.feedbackStatus === 'Action required'
              ? true
              : false,
        })
      );
      const { perModuleStatus, supervisor, memberDetails, perModuleId } = getState().cimaSupervisor;
      const { isCimaAssessor } = supervisor;
      const { coreActivities, employmentHistory, skillsAndBehaviours } = memberDetails;
      const { location } = getState().router;
      const moduleName = location.pathname.split('/', 3)[2];
      const supervisorName = supervisor.firstName;

      if (isCimaAssessor) {
        const { getStudentDetails } = await request(GET_STUDENT_DETAILS, { studentId, orgId: '' });
        const student = getStudentDetails?.studentDetails;
        const studentDetails = {
          name: `${student.firstName} ${student.lastName}`,
          email: student.email.emailAddress,
          personContactId: student.personContactId,
        };
        const assessor = getState().user.data;
        const assessorName = `${assessor.firstName} ${assessor.lastName}`;
        const module = getModuleNameFromPath(location.pathname);

        const { saveAssessorPERFeedback } = await request(MUTATE_UPDATE_ASSESSOR_FEEDBACK, {
          feedbackDetails: { ...feedbackDetails, ...studentDetails },
        });

        dispatch(
          setCimaSupervisorState({
            isSaveDraftLoading: false,
            isApprovedOrRFILoading: false,
          })
        );

        if (saveAssessorPERFeedback?.success) {
          const perModuleFeedbacks = [
            ...perModuleStatus,
            ...[{ id: feedbackDetails.perModuleId, status: feedbackDetails.feedbackStatus }],
          ];

          const makeAuditReview =
            coreActivities.length + employmentHistory.length + skillsAndBehaviours.length === perModuleFeedbacks.length;

          if (makeAuditReview) {
            const auditReviewayload = {
              perPortfolioId: perModuleId,
              externalValidator: supervisor.id,
              perAuditKey: feedbackDetails.perAuditKey,
              areasOfImprovement: feedbackDetails.areasOfImprovement,
              action: perModuleFeedbacks.every(feedback => feedback.status === 'Approved')
                ? 'AUDIT_APPROVED'
                : 'AUDIT_REJECTED',
              studentId,
              assessorName,
              moduleName: module,
              ...studentDetails,
            };

            const auditReviewDecision = await request(MUTATE_AUDIT_REVIEW_DECISION, {
              auditReviewDecision: auditReviewayload,
            });
            if (auditReviewDecision?.success) {
              return generateResponse(feedbackDetails);
            }
            return { success: false };
          }
          return generateResponse(feedbackDetails);
        }
        return { success: false };
      }

      return request(MUTATE_UPDATE_SUPERVISOR_FEEDBACK, {
        feedbackDetails: { ...feedbackDetails, studentId, supervisorName, moduleName },
      }).then((response: any) => {
        if (response?.saveFeedback) {
          dispatch(
            setCimaSupervisorState({
              isSaveDraftLoading: false,
              isApprovedOrRFILoading: false,
            })
          );
          return {
            success: true,
            updateResultMessage:
              feedbackDetails.feedbackStatus === 'Draft'
                ? ''
                : feedbackDetails.feedbackStatus === 'Approved'
                ? 'Your approval has been sent'
                : 'Your request has been sent',
          };
        }
        dispatch(
          setCimaSupervisorState({
            isSaveDraftLoading: false,
            isApprovedOrRFILoading: false,
          })
        );
        return { success: false };
      });
    }
);

export const resetUpdateFeedback: any = createAction(
  cimaSupervisorActionNames.RESET_UPDATE_RESULT_FEEDBACK,
  () => () => {
    return true;
  }
);

export const saveToken: any = createAction(cimaSupervisorActionNames.SAVE_TOKEN, (token: string) => () => {
  setLocalStorageItem({ [StorageNames.assessorToken]: token });
  return token;
});

export const saveReviewerDetailsLoading: any = createAction(cimaSupervisorActionNames.SAVE_REVIEWER_DETAILS_LOADING);
export const saveReviewerDetailsSuccess: any = createAction(cimaSupervisorActionNames.SAVE_REVIEWER_DETAILS_SUCCESS);
export const saveReviewerDetails: any = createAction(
  cimaSupervisorActionNames.SAVE_REVIEWER_DETAILS,
  (payload: SaveReviewerDetailsPayload) => async (dispatch: Dispatch) => {
    const response = await request(UPDATE_REVIEWER_DETAILS, { reviewerDetails: payload });

    if (response) {
      dispatch(saveReviewerDetailsSuccess());
      return { success: true };
    }
    return { success: false };
  }
);

export const withdrawSubmission: any = createAction(
  cimaSupervisorActionNames.WITHDRAW_SUBMISSION,
  (
      id: string,
      externalValidatorId: string,
      perAuditKey?: string,
      emailAddress?: string,
      studentFirstName?: string,
      supervisorFirstName?: string
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(setCimaSupervisorState({ isLoadingUpdateEPA: true }));
      try {
        const response = await request(UPDATE_PER_WITHDRAW, {
          perPortfolioId: id,
          externalValidator: externalValidatorId,
          perAuditKey,
        });

        const { supervisor } = getState().cimaSupervisor;
        const { isCimaAssessor } = supervisor;
        const payload = {
          emailAddress,
          studentFirstName,
          supervisorFirstName,
          moduleName: '',
        };

        if (response.updatePerWithdrawn.success) {
          dispatch(getSupervisorDashboard());
          if (!isCimaAssessor) {
            dispatch(sendDifferentSupervisorNotification(payload));
          }
          return {
            success: true,
            updateResultMessage:
              response.updatePerWithdrawn.body.action === Salesforce.ReviewerDecision.WITHDRAW
                ? PERBanner.WITHDRAWN
                : response.updatePerWithdrawn.body.action === MembershipTypes.PERFeedbackStatus.ACTION_REQUIRED
                ? PERBanner.RFI
                : response.updatePerWithdrawn.body.action === MembershipTypes.PERFeedbackStatus.APPROVED
                ? PERBanner.APPROVED
                : '',
          };
        }
        dispatch(setCimaSupervisorState({ isLoadingUpdateEPA: false }));
        return { success: false };
      } catch (error) {
        Sentry.captureException(error);
        dispatch(setCimaSupervisorState({ isLoadingUpdateEPA: false }));
        return { success: false };
      }
    }
);

export const setModuleStatus: any = createAction(
  cimaSupervisorActionNames.SET_MODULE_STATUS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const { supervisor, memberDetails } = getState().cimaSupervisor;
    const { coreActivities, skillsAndBehaviours, employmentHistory } = memberDetails;

    if (supervisor.isCimaAssessor) {
      const perModuleStatus: { id: string; status: string }[] = [
        ...mapValueToArray(coreActivities, (core: any) => ({
          id: core.id,
          status: core.status,
        })),
        ...mapValueToArray(skillsAndBehaviours, (skills: any) => ({
          id: skills.id,
          status: skills.status,
        })),
        ...mapValueToArray(employmentHistory, (emp: any) => ({
          id: emp.id,
          status: emp.status,
        })),
      ];
      return perModuleStatus.filter(
        per =>
          per.status === MembershipTypes.PracticalExperienceRequirementStatus.APPROVED ||
          per.status === MembershipTypes.PracticalExperienceRequirementStatus.ACTION_REQUIRED
      );
    }

    return [];
  }
);

export const savePerModuleId: any = createAction(
  cimaSupervisorActionNames.SAVE_PER_MODULE_ID,
  (perModuleId: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    setLocalStorageItem({ [StorageNames.perModuleId]: perModuleId });
    return perModuleId;
  }
);

export const sendSupervisorReview: any = createAction(
  cimaSupervisorActionNames.SEND_SUPERVISOR_REVIEW,
  (payload: any) => {
    return request(MUTATE_SEND_SUPERVISOR_REVIEW, payload);
  }
);

export const sendDifferentSupervisorNotification: any = createAction(
  cimaSupervisorActionNames.SEND_DIFFERENT_SUPERVISOR_NOTIFICATION,
  (payload: any) => {
    return request(MUTATE_SEND_DIFFERENT_SUPERVISOR, { differentSupervisor: payload });
  }
);

export const updateEPAStatusAndComments: any = createAction(
  cimaSupervisorActionNames.EPA_UPDATE_EPA_AND_COMMENTS,
  (
      epaDetails: { id: string; comment: string; status: string },
      status: Salesforce.PERFeedbackStatus,
      employerValidatorName?: string,
      memberDetails?: MembershipTypes.EpaMemberDetails,
      employerName?: string,
      attestationText?: string,
      recordType?: Salesforce.RecordTypeName
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(setCimaSupervisorState({ isLoadingUpdateEPA: true }));
      return request(MUTATE_EPA_STATUS_AND_COMMENTS, {
        epaDetails,
        epaMemberDetails: memberDetails,
        employerName,
        status,
        employerValidatorName,
        attestationText,
        recordType,
      })
        .then(async (response: any) => {
          if (response?.updateEPAStatusAndComments) {
            if (
              epaDetails.status === Salesforce.EPAStatus.SCREENING ||
              epaDetails.status === Salesforce.EPAStatus.REQUESTED_FURTHER_INFORMATION
            ) {
              await dispatch(updateExamCreditFromEpa(epaDetails.id));
            }
            dispatch(getSupervisorDashboard());
            dispatch(sendStudentEmailNotification(status, epaDetails.id, employerValidatorName));

            return {
              success: true,
              updateResultMessage:
                epaDetails.status === Salesforce.EPAStatus.SCREENING
                  ? PERBanner.APPROVED
                  : epaDetails.status === Salesforce.EPAStatus.REQUESTED_FURTHER_INFORMATION
                  ? PERBanner.RFI
                  : epaDetails.status === Salesforce.EPAStatus.WITHDRAWN
                  ? PERBanner.WITHDRAWN
                  : '',
            };
          }
          dispatch(setCimaSupervisorState({ isLoadingUpdateEPA: false }));
          return { success: false };
        })
        .catch(err => {
          dispatch(setCimaSupervisorState({ isLoadingUpdateEPA: false }));
          return { success: false };
        });
    }
);

export const updateExamCreditFromEpa: any = createAction(
  cimaSupervisorActionNames.UPDATE_EXAM_CREDIT_FROM_EPA,
  (caseId: string) => {
    return request(MUTATE_EXAM_CREDIT_FROM_EPA, { caseId });
  }
);

export const setCimaSupervisorState: any = createAction(
  cimaSupervisorActionNames.SET_STATE,
  (payload: { [key: string]: any }) => () => payload
);

export const validateSupervisorToken: any = createAction(
  cimaSupervisorActionNames.VALIDATE_SUPERVISOR_TOKEN,
  (token: string) => async () => {
    return request(VALIDATE_SUPERVISOR_TOKEN, { token });
  }
);

export const getCimaReviewerDashboard: any = createAction(
  cimaSupervisorActionNames.GET_CIMA_REVIEWER_DASHBOARD,
  (isAuth: boolean, tokenRedux: string | undefined, token: string) => async (dispatch: Dispatch) => {
    const tokenVal = tokenRedux || token ? true : false;
    removeLocalStorageItem([StorageNames.assessorToken, StorageNames.perModuleId, StorageNames.assessorMemberData]);
    if (isAuth) {
      dispatch(getSupervisorDashboard());
    } else if (tokenVal && token !== 'token=') {
      // Supervisor Access Checker
      await dispatch(validateSupervisorToken(token.replace('token=', '')))
        .then((response: any) => {
          if (response.payload.validateSupervisorToken.success) {
            dispatch(getSupervisorDashboard());
          } else {
            dispatch(push({ pathname: generatePath(getPath(Routes.CIMA_REVIEWER_EXPIRED)) }));
          }
        })
        .catch((err: any) => {
          dispatch(push({ pathname: generatePath(getPath(Routes.CIMA_REVIEWER_EXPIRED)) }));
        });
    } else {
      // Assessor Access Checker
      dispatch(showLogin({ redirectToLoginPage: true }));
    }
  }
);

export const getCimaReviewerDashboardFromExpiredLink: any = createAction(
  cimaSupervisorActionNames.GET_CIMA_REVIEWER_DASHBOARD_FROM_EXPIRED_LINK,
  (isAuth: boolean) => (dispatch: Dispatch) => {
    if (isAuth) {
      return dispatch(push({ pathname: generatePath(getPath(Routes.CIMA_REVIEWER_ROOT)) }));
    }
  }
);
