import { MembershipTierPanel } from 'components/organisms';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Button, ButtonEnums } from 'components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMembershipRelatedBenefits,
  fetchMembershipSections,
  setHasSelectedTier,
} from 'modules/membership/actions';
import { userMembershipPackageSelector } from 'modules/membership/selectors';

interface RenewalTierSelectionProps {
  handleOnClickContinue: () => void;
  handleOnClickGoBack: () => void;
}

export const RenewTierSelection: React.FC<RenewalTierSelectionProps> = ({
  handleOnClickContinue,
  handleOnClickGoBack,
}) => {
  const dispatch = useDispatch();
  const { tier: userChoicesTier } = useSelector(userMembershipPackageSelector);

  const handleContinue = useCallback(() => {
    dispatch(setHasSelectedTier(true));
    dispatch(fetchMembershipRelatedBenefits(userChoicesTier));
    handleOnClickContinue();
    dispatch(fetchMembershipSections());
  }, [handleOnClickContinue, dispatch, userChoicesTier]);

  const handleBack = useCallback(() => {
    handleOnClickGoBack();
  }, [handleOnClickGoBack]);

  return (
    <>
      <MembershipTierPanel
        isNotShowingRoleModal={true}
        shouldShowNewPackageBuilder={true}
        shouldShowCTAButtons={false}
      />
      <StyledButtonGroup>
        <Button
          testId="renewal-tier-selection-continue"
          onClick={handleContinue}
          variant={ButtonEnums.variants.primary}
          size={ButtonEnums.sizes.medium}
        >
          Continue
        </Button>
        <Button
          testId="renewal-tier-selection-back"
          onClick={handleBack}
          variant={ButtonEnums.variants.secondary}
          size={ButtonEnums.sizes.medium}
        >
          Go back
        </Button>
      </StyledButtonGroup>
    </>
  );
};

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.pxToRem(22)};
  padding: ${props => props.theme.pxToRem(24)};

  &&&& > button {
    padding: ${props => `${props.theme.pxToRem(5)} ${props.theme.pxToRem(54)}`};
  }
`;
