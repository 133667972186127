import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { createMatchSelector, push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { PageContent, Props } from 'components/pages/PageContent/PageContent';
import { Content } from 'mxp-schemas';
import {
  contentLoadingSelector,
  contentPageItemSelector,
  relatedContentSelector,
  contentIsExclusiveSelector,
} from 'modules/content/selectors';
import { createSelector } from 'reselect';
import { topicsHashSelector, topicsSlugIdMapSelector } from 'modules/topics/selectors';
import { getContentItem, getRelatedContentList, resetContentModule } from 'modules/content/actions';
import { burgerExpandedSelector } from 'modules/layouts/selectors';
import { resetCurrentContentId } from 'modules/layouts';
import { savedItemsSelector } from 'modules/savedItems';
import { savedBlogPostsSelector } from 'modules/savedBlogPosts';
import { copy } from 'modules/copy/actions';
import { categoriesHashSelector } from 'modules/categories/selectors';
import { arrayIncludes, getPath, emptyObject, isExternal } from 'utils';
import { Routes } from 'constants/index';
import { isAuthSelector, userHasPrefSelector } from 'modules/user/selectors';
import { appInitializedSelector } from 'modules/app/selectors';

const mapActionCreators = (dispatch: Dispatch) => {
  return {
    async getContentPageData(slug: string, category: string, type: string): Promise<void> {
      const isStaticContentRoute: boolean = category === Content.CategorySlugs.HELP;
      const actionType: string = isStaticContentRoute ? 'staticArticle' : type;
      await dispatch(getContentItem(slug, category, actionType));
      if (!isStaticContentRoute) await dispatch(getRelatedContentList());
    },

    resetCurrentContentId(): void {
      dispatch(resetCurrentContentId());
    },

    copy: (item: State.CopiedItem): void => {
      dispatch(copy(item));
    },

    navigate(path: string): void {
      if (isExternal(path)) {
        window.open(path);
        return;
      }
      dispatch(push(path));
    },

    resetContentModule(): void {
      dispatch(resetContentModule());
    },
  };
};

const mapStateToProps = (
  state: State.Root,
  ownProps: { match: { path: string; params: { slug: string; category: string; type: string } } }
) => {
  const {
    match: { path, params },
  } = ownProps;
  const isHelpPage = Boolean(getPath(Routes.HELP) === path);
  const getContentParams = isHelpPage
    ? { category: Content.CategorySlugs.HELP, slug: Content.CategorySlugs.HELP, type: '' }
    : { category: '', slug: '', type: '' };
  const { slug, category, type } = Boolean(Object.keys(params).length) ? params : getContentParams;
  const hideContentDetails: boolean = category === Content.CategorySlugs.HELP;

  const matchOldContentRoute: any = createMatchSelector(getPath(Routes.CONTENT_PAGE_OLD));
  const matchNewContentRoute: any = createMatchSelector(getPath(Routes.CONTENT_PAGE));

  const isAuth: boolean | null = isAuthSelector(state);
  const userHasPref: boolean = userHasPrefSelector(state);
  const isUserOnBoarded = Boolean(isAuth) && userHasPref;

  const topicSlugSelector = createSelector(
    matchOldContentRoute,
    matchNewContentRoute,
    (matchOldRoute: any, matchNewRoute: any): string =>
      (matchOldRoute && matchOldRoute.params.topicSlug) || (matchNewRoute && matchNewRoute.params.topicSlug) || ''
  );

  const subtopicSlugSelector = createSelector(
    matchOldContentRoute,
    matchNewContentRoute,
    (matchOldRoute: any, matchNewRoute: any): string =>
      (matchOldRoute && matchOldRoute.params.topicSlug) || (matchNewRoute && matchNewRoute.params.subtopicSlug) || ''
  );

  const topicFromParamsSelector = createSelector(
    [topicsHashSelector, topicsSlugIdMapSelector, topicSlugSelector],
    (
      topicsHash: State.TopicsHash | null,
      topicsSlugIdMap: { [key: string]: string },
      slg: string | null
    ): State.Topic | null => {
      if (!slg || !topicsHash || !topicsSlugIdMap) return null;
      const slugId: string = topicsSlugIdMap[slg];
      if (!slugId) return null;
      const topicObj: State.Topic = topicsHash[slugId];
      return topicObj;
    }
  );

  // FixMe use categoryNameSelectorFactory
  const categoriesHash = categoriesHashSelector(state);
  const categoryName = categoriesHash && categoriesHash[category] && categoriesHash[category].name;
  const categoryDisplayName = categoryName || category;
  const contentItem = contentPageItemSelector(state);
  const burgerExpanded = burgerExpandedSelector(state);
  const savedItems = savedItemsSelector(state) || [];
  const savedBlogPosts = savedBlogPostsSelector(state) || [];
  const id = (contentItem || { id: '' }).id;
  const isContentSaved = arrayIncludes([...savedItems, ...savedBlogPosts], id);
  const isLocked: boolean = contentItem ? contentItem.isLocked === Content.ContentLockStatus.LOCKED : false;
  const contentIsExclusive: Common.ContentExclusive = contentIsExclusiveSelector(state);
  const topicSlug = topicSlugSelector(state);
  const subtopicSlug = subtopicSlugSelector(state);
  const topic: State.Topic | null = topicFromParamsSelector(state);
  const { name: topicName = '' }: any = topic || emptyObject;

  return {
    contentLoading: contentLoadingSelector(state),
    contentItem,
    relatedContent: relatedContentSelector(state),
    hideContentDetails,
    isContentSaved,
    category,
    slug,
    type,
    burgerExpanded,
    categoryDisplayName,
    isLocked,
    contentIsExclusive,
    isAuth,
    topicSlug,
    subtopicSlug,
    topicName,
    isUserOnBoarded,
    appInitialized: appInitializedSelector(state),
  };
};

const frontload = async (props: Props) => {
  if (props.category && props.slug) {
    const getContentPageDataPromise: Promise<void> = props.getContentPageData(
      props.slug,
      props.category,
      props.type || props.category
    );

    const promises: Promise<void>[] = [getContentPageDataPromise];
    await Promise.all(promises);
  }
};

const options = {
  onUpdate: true,
  _experimental_updateFunc: (prevProps: Props, newProps: Props) =>
    prevProps.isAuth !== newProps.isAuth ||
    prevProps.category !== newProps.category ||
    prevProps.slug !== newProps.slug ||
    prevProps.appInitialized !== newProps.appInitialized,
};

export const PageContentContainer = connect(
  mapStateToProps,
  mapActionCreators
)(frontloadConnect(frontload, options)(PageContent));
