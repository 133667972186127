import { ProductCancellationOrRefundModal } from 'components/admin/molecules/ProductCancellationOrRefundModal/ProductCancellationOrRefundModal';
import { Button, Container, Grid, Heading, OnlyDesktop, Table } from 'components/atoms';
import { DropdownWithLabel } from 'components/molecules';
import { HeaderPlain } from 'components/molecules/HeaderPlain/HeaderPlain';
import { InputWithValidation } from 'components/molecules/InputWithValidation/InputWithValidation';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import {
  changeRequestedAction,
  onSeatNumberBlur,
  setCancellationDate,
  setReason,
  setRefundAmount,
  setSeatNumber,
  setSelectedProduct,
  resetOrganizationOrderRefundCancel,
} from 'modules/admin/actions';
import {
  organizationOrderDetailsList,
  organizationOrderRefundCancelSelector,
  selectedCurrency,
} from 'modules/admin/selectors';
import moment from 'moment-timezone';
import { Orders, B2B, Product } from 'mxp-schemas';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useParams, generatePath } from 'react-router';
import { DropdownProps, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { getPath, formatPrice, getCurrencySign, areAllTruthy, hasTruthyValue } from 'utils';
import { Routes } from 'constants/index';
import { push } from 'connected-react-router';
import { FirmAdmin as FirmAdminUtils } from 'mxp-utils';

interface Props extends RouteComponentProps<MatchParams> {
  navigate: (path: string) => void;
  setSearchModal: (expanded: boolean) => void;
  clearSuggestions: () => void;
}

interface MatchParams {
  accountId: string;
  accountNumber: string;
  legalEntity: string;
  orderNumber: string;
}

const refundCancelOptions = Orders.RefundCancellationOptions;

const reasons = Object.values(B2B.ReasonsCode);
const reasonOptions = reasons.map((reason, index) => ({
  key: index,
  text: reason,
  value: reason,
}));

export const RefundCancelProduct = ({ navigate, setSearchModal, clearSuggestions, match }: Props) => {
  const { productId } = useParams() as any;
  const dispatch = useDispatch();
  const orderDetails: State.OrganizationOrderDetail[] = useSelector(organizationOrderDetailsList);
  const {
    selectedProduct,
    requestedAction,
    reason,
    seatNumber,
    refundAmount,
    seatNumberDisabled,
    cancellationDateDisabled,
    totalRefundDisabled,
    isValidSeatNumber,
    previewCancelOrder,
  } = useSelector(organizationOrderRefundCancelSelector);
  const currency = useSelector(selectedCurrency);
  const currencySign = getCurrencySign(currency);

  const [disabledConfirm, setDisabledConfirm] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isTotalRefundLoading, setIsTotalRefundLoading] = useState<boolean>(false);

  const onRequestedActionChange = (event: React.SyntheticEvent<Element, Event>, data: DropdownProps) => {
    dispatch(changeRequestedAction(data.value));
  };

  const onReasonChange = (event: React.SyntheticEvent<Element, Event>, data: DropdownProps) => {
    dispatch(setReason(data.value));
  };

  const onNumberOfSeatChange = ({ value }: any) => {
    dispatch(setSeatNumber(value));
  };

  const onNumberOFSeatBlur = async ({ value }: any) => {
    setIsTotalRefundLoading(true);
    await dispatch(onSeatNumberBlur(value));
    setIsTotalRefundLoading(false);
  };

  const onRefundAmountChange = ({ value }: any) => {
    if (Number(value) || value === '') dispatch(setRefundAmount(value));
  };

  const onCancellationDateChange = (target: any): void => dispatch(setCancellationDate(target.value));
  const onBack = (): void => dispatch(resetOrganizationOrderRefundCancel());

  const openModal = () => setIsModalOpen(true); // tslint:disable-line jsx-no-lambda

  useEffect(() => {
    if (orderDetails) {
      const product = orderDetails.find(orderDetail => orderDetail.Id === productId);
      dispatch(setSelectedProduct(product));
    }
  }, [orderDetails, productId, dispatch]);

  const onSubmit = () => {
    dispatch(
      push(
        generatePath(getPath(Routes.ADMIN_ORDER_DETAILS), {
          accountId: match.params.accountId,
          accountNumber: match.params.accountNumber,
          legalEntity: match.params.legalEntity,
          orderNumber: match.params.orderNumber,
        })
      )
    );
  };

  const nonPartialRefundAmount =
    previewCancelOrder && ((previewCancelOrder!.refundAmount + previewCancelOrder!.taxAmount).toString() as string);
  const partialTaxAmount =
    requestedAction !== Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION
      ? previewCancelOrder?.taxAmount || 0
      : 0;
  const partialRefundAmount: string = FirmAdminUtils.valueToDefault(
    (previewCancelOrder && Number(refundAmount || '0') > 0
      ? (Number(refundAmount || '0') + partialTaxAmount).toFixed(2)
      : '0'
    )?.toString(),
    ''
  );

  const defaultToTodayIfNotEmpty = areAllTruthy((requestedAction as string) === '')
    ? ''
    : moment().format('DD/MM/YYYY').toString();

  const discountedPrice =
    Number(selectedProduct?.UnitPrice) - Number(selectedProduct?.RV_Discount__c) / Number(selectedProduct?.Quantity);

  useEffect(() => {
    if (requestedAction === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION) {
      dispatch(setSeatNumber(0));
    }
    if (
      hasTruthyValue(
        areAllTruthy(
          [
            Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION,
            Product.RefundOrCancellationEnums.CANCEL_ONLY,
          ].includes(requestedAction as Product.RefundOrCancellationEnums),
          Boolean(selectedProduct),
          Boolean(requestedAction),
          isValidSeatNumber,
          reason,
          Number(seatNumber as number) <= Number(selectedProduct?.AvailableSeats)
        ),
        areAllTruthy(
          requestedAction === Product.RefundOrCancellationEnums.PARTIAL_REFUND_WITH_CANCELLATION,
          Number(refundAmount) <= Number(seatNumber) * discountedPrice,
          hasTruthyValue(
            selectedProduct?.ratePlanChargeType?.toLowerCase() === 'onetime' && Number(refundAmount) >= 0,
            selectedProduct?.ratePlanChargeType?.toLowerCase() !== 'onetime' && Number(refundAmount) > 0
          ),
          Boolean(selectedProduct),
          Boolean(requestedAction),
          isValidSeatNumber,
          reason,
          Number(seatNumber as number) <= Number(selectedProduct?.AvailableSeats)
        ),
        areAllTruthy(
          requestedAction === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION,
          Number(refundAmount) <= discountedPrice * Number(selectedProduct?.Quantity),
          Boolean(selectedProduct),
          Boolean(requestedAction),
          isValidSeatNumber,
          reason,
          Number(seatNumber as number) <= Number(selectedProduct?.AvailableSeats)
        )
      )
    ) {
      setDisabledConfirm(false);
    } else setDisabledConfirm(true);
  }, [
    selectedProduct,
    seatNumber,
    requestedAction,
    reason,
    isValidSeatNumber,
    refundAmount,
    discountedPrice,
    dispatch,
  ]);

  return (
    <React.Fragment>
      <OnlyDesktop>
        <HeaderPlain navigate={navigate} clearSuggestions={clearSuggestions} setSearchModal={setSearchModal} />
      </OnlyDesktop>
      <Container>
        <Grid stackable>
          <Grid.Row stretched>
            <StyledGridColumn tablet={16} computer={16} floated="left">
              <StyledPreviousPage onClick={onBack} backText={`Back to Order #${match.params.orderNumber}`} />
              <StyledHeading>Refund/Cancel Product</StyledHeading>

              <StyledDiv>
                <StyledContentContainer>
                  <StyledTable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={5}>Product Name</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Total Seats</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Assigned Seats</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Available Seats</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Cancelled Seats</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Expires on</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <StyledTableProductHeader>{selectedProduct?.RV_ProductName__c}</StyledTableProductHeader>
                        <Table.Cell>{selectedProduct?.TotalSeats}</Table.Cell>
                        <Table.Cell>{selectedProduct?.AssignedSeats}</Table.Cell>
                        <Table.Cell>{selectedProduct?.AvailableSeats}</Table.Cell>
                        <Table.Cell>{selectedProduct?.CancelledSeats}</Table.Cell>
                        <Table.Cell>{moment(selectedProduct?.ExpiresOn).format('LL')}</Table.Cell>
                      </Table.Row>
                      <StyleTableProductUId>{`#${selectedProduct?.OrderItemSKU} | ${selectedProduct?.SubTypeSKU} `}</StyleTableProductUId>

                      <Table.Row>
                        <StyledGridRow>
                          <StyledTaleGridColumn>Discount Price</StyledTaleGridColumn>
                          <StyledTaleGridColumn isPriced>{formatPrice(discountedPrice, currency)}</StyledTaleGridColumn>
                        </StyledGridRow>
                        <StyledGridRow>
                          <StyledTaleGridColumn>Item Subtotal</StyledTaleGridColumn>

                          <StyledTaleGridColumn isPriced>
                            {formatPrice(discountedPrice * Number(selectedProduct?.Quantity), currency)}
                          </StyledTaleGridColumn>
                        </StyledGridRow>
                      </Table.Row>
                    </Table.Body>
                  </StyledTable>
                </StyledContentContainer>
              </StyledDiv>
              <StyledForm>
                <StyledDropdown
                  placeholder={'Select Refund/Cancellation option'}
                  title={'Select Refund/Cancellation option'}
                  options={
                    selectedProduct?.ratePlanChargeType?.toLowerCase() === 'onetime'
                      ? refundCancelOptions.filter(option => option.value !== 'Partial-refund-with-cancellation')
                      : refundCancelOptions
                  }
                  onChange={onRequestedActionChange}
                />
                <StyledLabel>No. of Seats to Refund/Cancel</StyledLabel>
                <InputWithValidation
                  name="no-of-seats-refund-cancel"
                  testId="no-of-seats-refund-cancel"
                  type="text"
                  value={seatNumber.toString()}
                  isCorrect={hasTruthyValue(
                    areAllTruthy(!isValidSeatNumber, seatNumber === ''),
                    areAllTruthy(
                      Object.values(Product.RefundOrCancellationEnums)
                        .filter(value => value !== Product.RefundOrCancellationEnums.PARTIAL_REFUND_WITH_CANCELLATION)
                        .includes(requestedAction as Product.RefundOrCancellationEnums),
                      isValidSeatNumber,
                      Number(seatNumber) <= Number(selectedProduct?.AvailableSeats)
                    ),
                    areAllTruthy(
                      requestedAction === Product.RefundOrCancellationEnums.PARTIAL_REFUND_WITH_CANCELLATION,
                      Number(seatNumber) > 0,
                      Number(seatNumber) <= Number(selectedProduct?.AvailableSeats)
                    )
                  )}
                  handleOnBlur={e => {
                    if (
                      hasTruthyValue(
                        requestedAction === Product.RefundOrCancellationEnums.PARTIAL_REFUND_WITH_CANCELLATION,
                        requestedAction === Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION
                      )
                    ) {
                      onNumberOFSeatBlur(e.target);
                    }
                  }} // tslint:disable-line jsx-no-lambda
                  isCorrectIconShown={areAllTruthy(seatNumber !== '', isValidSeatNumber)}
                  onChange={e => onNumberOfSeatChange(e.target)} // tslint:disable-line jsx-no-lambda
                  errorMessage="Invalid seat number!"
                  disabled={Boolean(seatNumberDisabled)}
                  allowedValidationOnload={false}
                />
                <StyledLabel>Cancellation Date</StyledLabel>
                <InputWithValidation
                  name="cancellation-date"
                  testId="cancellation-date"
                  type="text"
                  value={
                    hasTruthyValue(
                      requestedAction === Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION,
                      requestedAction === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION
                    )
                      ? moment(selectedProduct?.subscriptionStartDate as string)
                          .format('DD/MM/YYYY')
                          .toString()
                      : defaultToTodayIfNotEmpty
                  }
                  isCorrect={true}
                  isCorrectIconShown={true}
                  onChange={e => {
                    onCancellationDateChange(e);
                  }} // tslint:disable-line jsx-no-lambda
                  disabled={Boolean(cancellationDateDisabled)}
                  allowedValidationOnload={false}
                />
                <StyledLabel>Total refund amount {currencySign} (excl. sales tax)</StyledLabel>
                <InputWithValidation
                  name="total-refund-amount-excl"
                  testId="total-refund-amount-excl"
                  type="text"
                  value={refundAmount as string}
                  isCorrect={
                    !hasTruthyValue(
                      areAllTruthy(
                        requestedAction === Product.RefundOrCancellationEnums.PARTIAL_REFUND_WITH_CANCELLATION,
                        Number(refundAmount) !== 0,
                        Number(refundAmount) > Number(seatNumber) * discountedPrice
                      ),
                      areAllTruthy(
                        requestedAction === Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION,
                        Number(refundAmount) > discountedPrice * Number(selectedProduct?.Quantity)
                      ),
                      areAllTruthy(
                        requestedAction === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION,
                        Number(refundAmount) > discountedPrice * Number(selectedProduct?.Quantity)
                      )
                    )
                  }
                  isCorrectIconShown
                  onChange={e => onRefundAmountChange(e.target)} // tslint:disable-line jsx-no-lambda
                  disabled={totalRefundDisabled}
                  allowedValidationOnload={false}
                  isLoading={isTotalRefundLoading}
                />
                <StyledLabel>Total refund amount {currencySign} (incl. sales tax)</StyledLabel>
                <InputWithValidation
                  name="total-refund-amount-incl"
                  testId="total-refund-amount-incl"
                  type="text"
                  value={
                    !Boolean(requestedAction)
                      ? ''
                      : ![
                          Product.RefundOrCancellationEnums.PARTIAL_REFUND_WITH_CANCELLATION,
                          Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION,
                        ].includes(requestedAction as Product.RefundOrCancellationEnums)
                      ? (nonPartialRefundAmount as string)
                      : (partialRefundAmount as string)
                  }
                  isCorrect
                  onChange={e => {
                    return;
                  }} // tslint:disable-line jsx-no-lambda
                  disabled
                  allowedValidationOnload={false}
                  isLoading={isTotalRefundLoading}
                />
                <StyledDropdown
                  placeholder={'Reason'}
                  title={'Reason'}
                  options={reasonOptions}
                  onChange={onReasonChange}
                />
                <StyledConfirmButton
                  testId="confirm"
                  disabled={disabledConfirm}
                  onClick={() => openModal() /* tslint:disable-line jsx-no-lambda */}
                >
                  Confirm
                </StyledConfirmButton>
              </StyledForm>
            </StyledGridColumn>
          </Grid.Row>
        </Grid>
      </Container>
      <ProductCancellationOrRefundModal
        isOpen={isModalOpen}
        {...{ setIsModalOpen }}
        requestAction={requestedAction}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  );
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: ${props => props.theme.pxToRem(260)};
  box-shadow: ${props => props.theme.colors.neutralGrey3};
  background-color: ${props => props.theme.colors.neutralGrey1};
  margin-bottom: ${props => props.theme.pxToRem(40)};
`;

const StyledForm = styled(Form)`
  ${props => `
 &&&& {
	 max-width: ${props.theme.pxToRem(455)}
	 ${props.theme.mediaQueries.mobileOnly} {
		 width: ${props.theme.pxToRem(296)}
	 }
 }`}
`;

const StyledPreviousPage = styled(PreviousPage)`
  &&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(16)};
    }
  }
`;

const StyledHeading = styled(Heading)`
  &&& {
    font-size: ${props => props.theme.fontSizes.xl};
    color: ${props => props.theme.colors.neutralGrey8};
    font-weight: ${props => props.theme.fontWeights.light};
    margin: ${props => props.theme.pxToRem(16)} 0 !important;
  }
`;

const StyledLabel = styled.label`
  margin-bottom: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralGrey8};
  display: block;
`;

const StyledDropdown = styled(DropdownWithLabel)`
  width: 100%;
  margin-bottom: ${props => props.theme.pxToRem(19)};
`;

const StyledConfirmButton = styled(Button)`
  &&&&& {
    color: ${props => props.theme.colors.neutralWhite}
    background: ${props => props.theme.colors.primaryPurple}
    height: ${props => props.theme.pxToRem(48)};
    font-size: ${props => props.theme.fontSizes.m};
    width: ${props => props.theme.pxToRem(265)};
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(8)};

    &:disabled {
      color: ${props => props.theme.colors.neutralGrey7}
      background: ${props => props.theme.colors.neutralGrey3}
    }
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&&&& {
    display: block !important;
  }
`;

const StyledContentContainer = styled(Container)`
  display: flex;
  align-items: center;
`;

const StyledTableProductHeader = styled(Table.Cell)`
  padding-bottom: 0 !important;
  font-weight: ${props => props.theme.fontWeights.medium} !important;
`;

const StyleTableProductUId = styled(Table.Cell)`
  font-size: ${props => props.theme.fontSizes.xxs};
  padding-top: 0 !important;
  padding-left: ${props => props.theme.pxToRem(19)} !important;
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledGridRow = styled(Grid.Row)`
  display: flex;
`;

const StyledTaleGridColumn = styled(Grid.Column)<{ isPriced?: boolean }>`
  font-size: ${props => props.theme.fontSizes.xs};
  padding-left: ${props => props.theme.pxToRem(19)} !important;
  color: ${props => (props.isPriced ? '#28283a' : props.theme.colors.neutralBlack)};
  font-weight: ${props => (props.isPriced ? props.theme.fontWeights.thin : props.theme.fontWeights.medium)};
`;

const StyledTable = styled(Table)`
  background-color: ${props => props.theme.colors.neutralGrey1} !important;
`;
