import React from 'react';
import styled from 'styled-components';
import { Divider } from 'components/atoms';

interface Props {
  testId?: string;
}

export const StyledDivider: React.FC<Props> = React.memo(({ testId }) => {
  return <StyledDivide data-testid={testId} />;
});

const StyledDivide = styled(Divider)`
  width: 100%;
  &&& {
    margin: ${props => `0 0 ${props.theme.pxToRem(40)} 0`};
  }
`;
