import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { MembershipTypes } from 'mxp-schemas';
import { userMembershipCredentialSelector } from 'modules/membership/selectors';
import { removeMembershipPackageCredential, addMembershipPackageCredential } from 'modules/membership/actions';
import { MembershipCard, ModalCredentialPathWay } from 'components/organisms';
import { HandleAddToCartProps, AddNewComponentProps, StyledGrid, StyledGridColumn } from './AddNewMembershipProduct';
import { productTypeToSlug, fromCentsFormat, formatPrice } from 'utils';

export const AddNewCredentials = ({
  list,
  buttonGroup,
  currency,
  dispatch,
  variantsPriceInfoForUser,
}: AddNewComponentProps) => {
  const userChoicesCredentials = useSelector(userMembershipCredentialSelector);

  const [selectedCredential, setSelectedCredential] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleAddToCart = useCallback(
    ({ productId, isSelected, isCGMA, cgmaVariant, credentialKey }: HandleAddToCartProps) => {
      if (isSelected) {
        dispatch(removeMembershipPackageCredential(productId));
      } else if (isCGMA && cgmaVariant) {
        dispatch(
          addMembershipPackageCredential(
            productId,
            cgmaVariant?.sku,
            cgmaVariant?.num_of_exams,
            cgmaVariant?.eligibleExams,
            cgmaVariant?.pathway_name,
            cgmaVariant?.alternativeExams,
            credentialKey
          )
        );
      } else if (!isCGMA && !isSelected) {
        setSelectedCredential(productId);
        setOpenModal(true);
      }
    },
    [dispatch]
  );

  return (
    <>
      <StyledGrid centered columns={2}>
        {[...list].map(data => {
          const isCGMA = data.credentialKey === MembershipTypes.CredentialKeys.CGMA;
          const isSelected = userChoicesCredentials.some(item => item.productId === data.productId);
          return (
            <StyledGridColumn key={data.variants[0].sku}>
              <MembershipCard
                headerTitle={data.name}
                description={data.description}
                formattedPrice={formatPrice(fromCentsFormat(variantsPriceInfoForUser(data)), currency.label)}
                isCardSelected={isSelected}
                isCurrent={false}
                slug={`${productTypeToSlug(data.productType as any)}/${data.slug}`}
                handleClick={handleAddToCart.bind(null, {
                  productId: data.productId,
                  isSelected,
                  isCGMA,
                  cgmaVariant: isCGMA ? data?.variants[0] : null,
                  credentialKey: data.credentialKey,
                })}
                productDetails={data}
                isExistingMember={true}
                isAddNewMembershipProduct
                shouldShowNewPackageBuilder
              />
            </StyledGridColumn>
          );
        })}
      </StyledGrid>
      <ModalCredentialPathWay
        isRenewal={false}
        isOpen={openModal}
        setOpen={setOpenModal}
        selectedCredentialProductId={selectedCredential}
      />
      {buttonGroup}
    </>
  );
};
