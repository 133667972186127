import { handleActions } from 'redux-actions';
import { createNotification, removeNotification, removeAllNotifications } from './actions';

const initialState = {
  notificationItems: [],
  timeoutId: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [createNotification]: (state: State.Notification, action: any): State.Notification => {
    const { id } = action.payload.notificationItem;
    const { timeoutId } = action.payload;

    if (!state.notificationItems.some(notification => notification.id === id)) {
      return {
        ...state,
        notificationItems: [...state.notificationItems, action.payload.notificationItem],
        timeoutId,
      };
    }
    return state;
  },

  [removeNotification]: (state: State.Notification, action: any): State.Notification => ({
    ...state,
    notificationItems: state.notificationItems.filter(notification => notification.id !== action.payload.id),
  }),

  [removeAllNotifications]: (state: State.Notification): State.Notification => ({
    ...state,
    notificationItems: [],
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
