import React from 'react';
import styled from 'styled-components/macro';
import { Sidebar, Icon } from 'semantic-ui-react';
import { Button } from 'components/atoms/Button/Button';
import { emptyObject } from 'utils';
import { ARROW_FORWARD } from 'resources/images';
import IconClose from 'resources/images/ic-close.svg';

interface Props {
  isModalOpen: boolean;
  closeModal?: () => void;
  resultsNumber: number;
  clearFilter: (filterName: string) => void;
  items: any[];
  filtersApplied: string[];
  filterNames: any;
}

export const MobileFilters = ({
  isModalOpen,
  closeModal,
  resultsNumber,
  clearFilter,
  items,
  filtersApplied,
  filterNames,
}: Props) => {
  const [subMenuOpen, setSubMenuOpen] = React.useState<number>(-1);

  const clearFilterHandler = () => {
    clearFilter(filterNames[subMenuOpen]);
  };

  const handleChange = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (!event.currentTarget.dataset.id) {
        return;
      }
      setSubMenuOpen(Number(event.currentTarget.dataset.id));
    },
    [setSubMenuOpen]
  );
  const closeModalHandler = React.useCallback(() => {
    setSubMenuOpen(-1);
    if (closeModal) {
      closeModal();
    }
  }, [setSubMenuOpen, closeModal]);
  const isSubMenuOpen = React.useMemo(() => subMenuOpen > -1, [subMenuOpen]);
  const closeSubMenuHandler = React.useCallback(() => {
    if (isSubMenuOpen) {
      setSubMenuOpen(-1);
    } else {
      closeModalHandler();
    }
  }, [setSubMenuOpen, isSubMenuOpen, closeModalHandler]);
  const subMenuTitle: string | undefined = React.useMemo(
    () => (items.find(item => item.id === subMenuOpen) || emptyObject).heading,
    [items, subMenuOpen]
  );

  const isDisabled = !filtersApplied.includes(filterNames[subMenuOpen]);

  return (
    <Modal isModalOpen={isModalOpen}>
      <IconHeader>
        <IconButtonBottomLevel
          isSubMenuOpen
          onClick={closeSubMenuHandler}
          data-testid="mobile-filters-sub-menu-back-button"
        >
          <ArrowImage src={ARROW_FORWARD} />
        </IconButtonBottomLevel>
        <IconButton onClick={closeModal} data-testid="mobile-filters-modal-close-button">
          <CloseImage src={IconClose} />
        </IconButton>
      </IconHeader>
      <TitleHeader>
        <StyledHeading>{subMenuTitle ? subMenuTitle : 'Filter search results'}</StyledHeading>
        <IconButtonBottomLevel
          isSubMenuOpen={isSubMenuOpen}
          onClick={clearFilterHandler}
          data-testid="mobile-filters-clear-all-button"
          disabled={isDisabled}
        >
          <ClearIcon name="remove circle" disabled={isDisabled} /> Clear All
        </IconButtonBottomLevel>
      </TitleHeader>
      <SlidingMenuWrapper>
        <SlidingMenuTwo animation="push" direction="right" icon="labeled" visible={isSubMenuOpen}>
          <StyledFilterListWrapper>
            {(items.find(item => item.id === subMenuOpen) || emptyObject).content}
          </StyledFilterListWrapper>
        </SlidingMenuTwo>

        <SlidingMenuOne>
          {items.map(menuItem => (
            <Row
              key={menuItem.id}
              href="#"
              onClick={handleChange}
              data-id={menuItem.id}
              data-testid={`mobile-filters-menu-item-${menuItem.id}`}
            >
              <Text>{menuItem.heading}</Text>
              <ArrowIcon name="angle right" />
            </Row>
          ))}
        </SlidingMenuOne>
      </SlidingMenuWrapper>
      <StickyFooter>
        <FooterButton
          testId="mobile-filter-footer"
          onClick={closeModalHandler}
          data-testid="mobile-filters-view-results-button"
        >
          View {resultsNumber} results
        </FooterButton>
      </StickyFooter>
    </Modal>
  );
};

const IconButtonBottomLevel = styled.button<{ isSubMenuOpen: boolean }>`
  border: none;
  background: transparent;
  font-size: ${props => props.theme.pxToRem(15)};
  outline: none;
  cursor: pointer;
  flex-shrink: 0;
  visibility: ${props => (props.isSubMenuOpen ? 'visible' : 'hidden')};
`;

const Modal = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  transform: translate3d(0, ${(props: { isModalOpen: boolean }) => (props.isModalOpen ? '0' : '100vh')}, 0);
  transition: transform 0.75s ease-in-out;
  border-top: 5px solid ${props => props.theme.colors.primaryPurple};
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  z-index: 100000;
`;
const StyledHeading = styled.div`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: lighter;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.neutralGrey3};
  margin-top: ${props => props.theme.pxToRem(20)};
  padding-bottom: ${props => props.theme.pxToRem(20)};
  flex: 1 1 4rem;
`;
const SlidingMenuTwo = styled(Sidebar)`
  width: 100% !important;
`;
const SlidingMenuOne = styled(Sidebar.Pusher)`
  border: none;
  padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(15)};
  height: calc(100% - 1em - 20px);
  &&& {
    overflow-y: auto;
  }
`;
const SlidingMenuWrapper = styled(Sidebar.Pushable)`
  margin-bottom: 62px;
  overflow: hidden;
  .ui.visible.right.sidebar {
    box-shadow: none;
  }
  .ui.visible.right.sidebar ~ .pusher {
    transform: translate3d(-100%, 0, 0);
  }
`;
const StyledFilterListWrapper = styled.div`
  && > div {
    border: none;
    padding: 0;
    height: 100%;
    font-weight: lighter;
    font-size: ${props => props.theme.fontSizes.m};
  }
  .ui.list {
    height: 100%;
    max-height: 100%;
    padding: 0 15px;
    .item {
      padding: 0 !important;
      position: relative;
      label:first-child {
        padding: 15px 35px 15px 0;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;
const Row = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  color: black;
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: lighter;
  padding: 15px 0px;
  border-bottom: 1px solid ${props => props.theme.colors.neutralGrey3};
  &:hover {
    color: black;
  }
`;
const ArrowIcon = styled(Icon)`
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.l};
`;
const IconHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const IconButton = styled.button`
  border: none;
  background: transparent;
  height: 2rem;
  outline: none;
  cursor: pointer;
  color: ${props => props.theme.colors.primaryPurple};
`;

const ArrowImage = styled.img`
  transform: rotate(180deg);
  margin-top: 5px;
  cursor: pointer;
  height: ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
`;
const CloseImage = styled.img`
  height: 2rem;
  height: ${props => props.theme.pxToRem(32)};
  width: ${props => props.theme.pxToRem(32)};
`;
const StickyFooter = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.neutralGrey3};
  background: white;
`;
const ClearIcon = styled(Icon)`
  color: ${props => (props.disabled ? props.theme.colors.neutralGrey4 : props.theme.colors.primaryPurple)};
  && {
    margin: 0;
  }
`;
const FooterButton = styled(Button)`
  &&&& {
    background: ${props => props.theme.colors.primaryPurple};
    color: white;
  }
`;
const Text = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
