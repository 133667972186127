import { ContentfulHelpers } from 'mxp-utils';

export const getCustomBlockHeight = (
  blockName?: string,
  withHeader?: boolean,
  withFooter?: boolean,
  headerHeight?: number,
  footerHeight?: number
) => {
  switch (blockName) {
    case ContentfulHelpers.CONTENT_TYPES.QUICKLINKS_BLOCK:
      return '11.375rem';
    case ContentfulHelpers.CONTENT_TYPES.WELCOME_BLOCK_CAROUSEL:
      return '45rem';
    case ContentfulHelpers.CONTENT_TYPES.INQUIRY_FORM_BLOCK:
      return `calc(0vh - 0)}px)`;
    case ContentfulHelpers.CONTENT_TYPES.EDITORIAL_HERO_BLOCK:
      return '80rem';
    default:
      return `calc(100vh - ${(withHeader ? headerHeight : 0) || (withFooter ? footerHeight : 0)}px)`;
  }
};
