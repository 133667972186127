import React from 'react';
import styled from 'styled-components';
import { Breadcrumb as SemanticUIBreadCrumb } from 'semantic-ui-react';

import { Link } from '../Link/Link';

export interface BreadcrumbSection {
  content: string;
  to?: string;
}

interface Props {
  sections: BreadcrumbSection[];
  icon?: string;
}

export const Breadcrumb: React.FC<Props> = ({ sections, icon = 'right chevron' }) => {
  const renderBreadcrumbItem = (
    section: BreadcrumbSection,
    dividerIcon: string,
    isLastSection: boolean
  ): React.ReactNode => {
    const isExternalLink = Boolean(new RegExp(/^http(s)?:\/\//).test(section?.to || ''));

    return (
      <>
        <StyledSection data-testid={`breadcrumb-section-${section.content}`} isLastSection={isLastSection}>
          {section?.to ? (
            <StyledLink to={section.to} isExternal={isExternalLink}>
              {section.content}
            </StyledLink>
          ) : (
            section.content
          )}
        </StyledSection>
        {!isLastSection && <StyledDivider icon={dividerIcon} />}
      </>
    );
  };

  return (
    <SemanticUIBreadCrumb data-testid="breadcrumb">
      {sections.map((section, index) => {
        const isLastSection = sections.length === index + 1;
        return renderBreadcrumbItem(section, icon, isLastSection);
      })}
    </SemanticUIBreadCrumb>
  );
};

const StyledSection = styled(SemanticUIBreadCrumb.Section)<{ isLastSection?: boolean }>`
  &&& {
    font-size: ${props => props.theme.fontSizes.xxs};
    line-height: 1.5;
    color: ${props => (props.isLastSection ? props.theme.colors.neutralGrey5 : props.theme.colors.primaryPurple)};

    :not(:first-child) {
      margin-left: ${props => props.theme.pxToRem(8)};
    }

    :not(:last-child) {
      margin-right: ${props => props.theme.pxToRem(8)};
    }
  }
`;

const StyledLink = styled(Link)`
  &&& {
    color: ${props => props.theme.colors.primaryPurple};
    text-decoration: none;

    :hover {
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyledDivider = styled(SemanticUIBreadCrumb.Divider)`
  &&&& {
    font-size: ${props => props.theme.pxToRem(9)};
    width: ${props => props.theme.pxToRem(11)};
    height: ${props => props.theme.pxToRem(11)};
    margin: 0;

    :last-child {
      display: none;
    }
  }
`;
