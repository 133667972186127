import { createAction } from 'redux-actions';
import { QUERY_CONTENT_BY_IDS, REMOVE_SAVED_ITEM, ADD_SAVED_ITEM, GET_SAVED_ITEMS } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';
import { Dispatch } from 'redux';
import { getSearch } from 'connected-react-router';
import { DefaultPagination, SortByKeyNames, sortByOptions } from 'constants/index';
import { parse } from 'query-string';
import { convertSubtopicsSlugsToIds, convertTopicsSlugsToIds } from 'modules/topics/helpers';
import { toggleNotification } from 'modules/notifications/actions';
import { savedItemsSelector } from './selectors';
import { savedBlogPostsSelector } from 'modules/savedBlogPosts';
import { loginRequiredMetaData, addMetaData } from 'middlewares';

export const savedItemsLoading: any = createAction('savedItems/LOADING');

export const getSavedItems: any = createAction('savedItems/GET_SAVED_ITEMS', () =>
  request(GET_SAVED_ITEMS, { isSavedBlogPostsOnly: false }).catch(() => ({
    ids: [],
    top: [],
  }))
);

export const setSavedItems: any = createAction('savedItems/SET_SAVED_ITEMS', (savedItems: any) => savedItems);

export const toggleSavedItem: any = createAction(
  'savedItems/TOGGLE_CONTENT_SAVE',
  (id: string, isOnEvent: boolean, contentSource: string, isBlogFeed?: boolean) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const message = isOnEvent
        ? { id: `${id}-save`, title: 'Added to ', text: '', type: 'save' }
        : { id: `${id}-unsave`, title: 'Removed from My Saved Items', text: '', type: 'unsave' };
      dispatch(toggleNotification(message));

      return request(isOnEvent ? ADD_SAVED_ITEM : REMOVE_SAVED_ITEM, { contentId: id, contentSource, isBlogFeed });
    },
  addMetaData(loginRequiredMetaData)
);

export const fetchSearchContentByIds: any = createAction(
  'mySaved/FETCH_CONTENT_BY_IDS',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const search = getSearch(state);

    const savedItems: string[] = savedItemsSelector(state) || [];
    const savedBlogPosts: string[] = savedBlogPostsSelector(state) || [];
    const ids: string[] = [...savedItems, ...savedBlogPosts];

    if (!ids) return Promise.reject('No saved content');

    const {
      sortBy = sortByOptions[SortByKeyNames.NEWEST_FIRST].value,
      page = DefaultPagination.List.PAGE,
      perPage = DefaultPagination.List.MIN_PER_PAGE,
      date,
      topic,
      subtopic,
      category,
      type,
      contentSource,
    } = parse(search, {
      arrayFormat: 'comma',
      parseNumbers: true,
    });
    const topicsIds: string[] | undefined = convertTopicsSlugsToIds(topic, state);
    const subtopicsIds: string[] | undefined = convertSubtopicsSlugsToIds(subtopic, state);
    const hasFilters: boolean = date || topicsIds || subtopicsIds || category || type || contentSource;
    const filters: any = hasFilters && {
      date,
      topic: topicsIds,
      subtopic: subtopicsIds,
      category,
      type,
      contentSource,
    };

    return request(QUERY_CONTENT_BY_IDS, { ids, sortBy, page, perPage, filters });
  }
);
