import React from 'react';
import { Modal, Button as StyledButton, ButtonEnums, OnlyDesktopCSS, OnlyMobileCSS, Divider } from 'components/atoms';
import styled from 'styled-components/macro';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { MembershipPackageAccordionStatus } from '../../../constants';
import { useHistory } from 'react-router';
import { getMembershipPackagePath } from 'utils';

interface Props {
  visible: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  onClickConfirmButton?: () => void;
}

export const ModalUpgradeMembership: React.FC<Props> = ({ visible, setVisibility, onClickConfirmButton }) => {
  const history = useHistory();

  const onClickConfirm = React.useCallback(async () => {
    setVisibility(false);
    if (onClickConfirmButton) onClickConfirmButton();

    history.push(getMembershipPackagePath(MembershipPackageAccordionStatus.Tier));
  }, [history, setVisibility, onClickConfirmButton]);

  const onClickCancel = React.useCallback(() => {
    setVisibility(!visible);
  }, [setVisibility, visible]);

  return (
    <StyledModal size="tiny" heading="Check your membership" open={visible}>
      <CloseButton>
        <StyledCloseIcon onClick={onClickCancel} />
      </CloseButton>
      <Spacer />
      <CenteredText>
        <StyledText>You need to upgrade your membership to buy this credential.</StyledText>
      </CenteredText>
      <DividerWithBottomMargin />
      <CenteredButtons>
        <DesktopStyled>
          <CancelButton onClick={onClickCancel} />
          <UpgradeButton onClick={onClickConfirm} />
        </DesktopStyled>
        <MobileStyled>
          <CancelButton onClick={onClickCancel} />
          <UpgradeButton onClick={onClickConfirm} />
        </MobileStyled>
      </CenteredButtons>
    </StyledModal>
  );
};

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;

const StyledText = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;
interface ModalButtonProps {
  onClick: () => void;
}

const UpgradeButton: React.FC<ModalButtonProps> = ({ onClick }) => (
  <StyledUpgradeButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId="upgrade-button-modal"
  >
    Upgrade
  </StyledUpgradeButton>
);

const CancelButton: React.FC<ModalButtonProps> = ({ onClick }) => (
  <StyledCancelButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.secondary}
    testId="cancel-button-modal"
  >
    Cancel
  </StyledCancelButton>
);

const StyledUpgradeButton = styled(StyledButton)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledCancelButton = styled(StyledButton)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
  margin-right: ${props => props.theme.pxToRem(15)} !important;
`;

const StyledModal = styled(Modal)`
  width: ${props => props.theme.pxToRem(590)};
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }
    > .content {
      padding: 0.5rem 0 0 0 !important;
    }
    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${props => props.theme.pxToRem(30)};
  height: ${props => props.theme.pxToRem(30)};
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
