import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PageTitle } from 'components/molecules/PageTitle/PageTitle';
import { contentPageItemSelector } from 'modules/content/selectors';
import { appVersionSelector, isLowerEnvironmentSelector } from 'modules/app/selectors';
import {
  productPageItemSelector,
  productFormatLabelSelector,
  productTypeLabelSelector,
} from 'modules/products/selectors';
import { staticLandingPageSelector } from 'modules/staticLandingPagesContent/selectors';
import { ContentTypes } from 'constants/index';
import { Product, Contentful } from 'mxp-schemas';
import { startCase, productTypeToLabel } from 'utils';
import { cimaFeaturesSelector } from 'modules/firmAdmin/selectors';

const mapStateToProps = (state: State.Root) => {
  const contentItem: State.ContentItem | null = contentPageItemSelector(state);
  const productItem: Product.ProductItem = productPageItemSelector(state);
  const staticLandingPage: Contentful.StaticLandingPages.IParsedPage | null = staticLandingPageSelector(state);

  const contentItemTitle: string = contentItem?.title || '';
  const productItemTitle: string = productItem?.name || '';
  const staticLandingPageTitle: string = staticLandingPage?.title || '';

  const isPublication: boolean = productItem?.productType === Product.ProductType.PUBLICATION;
  const isCourse: boolean = productItem?.productType === Product.ProductType.COURSE;

  const isPublicationSubscription: boolean =
    productItem?.productType === Product.ProductType.SUBSCRIPTION &&
    productItem?.subscriptionProductType?.key === Product.SubscriptionProductType.PUBLICATION;

  const isCourseSubscription: boolean =
    productItem?.productType === Product.ProductType.SUBSCRIPTION &&
    productItem?.subscriptionProductType?.key === Product.SubscriptionProductType.COURSE;

  const productFormatLabel: string = isPublication
    ? productTypeToLabel(productItem.productType as Product.ProductType)
    : isPublicationSubscription
    ? productTypeLabelSelector(state)
    : isCourse || isCourseSubscription
    ? startCase(ContentTypes.COURSE)
    : productFormatLabelSelector(state);
  const contentItemCategory: string = contentItem?.contentCategory?.name || '';
  const staticLandingPageCategory: string = staticLandingPage?.contentCategory?.name || '';
  return {
    contentItemTitle,
    contentItemCategory,
    productItemTitle,
    productFormatLabel,
    staticLandingPageTitle,
    staticLandingPageCategory,
    version: appVersionSelector(state),
    isLowerEnvironment: isLowerEnvironmentSelector(state),
    useCimaFeatures: cimaFeaturesSelector(state),
  };
};

export const PageTitleContainer = withRouter(connect(mapStateToProps)(PageTitle));
