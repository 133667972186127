import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { Button, ButtonEnums, Divider, Modal, OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms';
import { ReactComponent as InfoBubble } from 'resources/images/ic-unsuccessful.svg';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  message?: string | React.ReactNode;
}

export const ModalFirmBillingFLPRequirementsNotMet: React.FC<ModalProps> = ({ isOpen, handleClose, message }) => {
  const handleButtonClick = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <>
      <StyledModal size="small" heading="" open={isOpen}>
        <Spacer />
        <StyledDiv>
          <StyledInfoBubble />
        </StyledDiv>
        <StyledH2>Warning</StyledH2>
        <CenteredText>
          {message || 'Please contact your Firm Admin as you are unable to progress as you do not qualify for FLP'}
        </CenteredText>
        <DividerWithBottomMargin />
        <CenteredButtons>
          <DesktopStyled>
            <StyledSubmitButton onClick={handleButtonClick} />
          </DesktopStyled>
          <MobileStyled>
            <StyledSubmitButton onClick={handleButtonClick} />
          </MobileStyled>
        </CenteredButtons>
      </StyledModal>
    </>
  );
};

const StyledDiv = styled.div`
  text-align: center;
`;

const StyledH2 = styled.h2`
  text-align: center;
`;

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;
const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

interface ModalButtonProps {
  onClick: () => void;
}

const StyledSubmitButton: React.FC<ModalButtonProps> = ({ onClick }) => (
  <StyledButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId="understand-credentials-error"
  >
    I understand
  </StyledButton>
);

const StyledButton = styled(Button)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const StyledInfoBubble = styled(InfoBubble)`
  fill: ${props => props.theme.colors.interfaceRed};
`;
