import React from 'react';
import { useSelector } from 'react-redux';
import { vergicConfigSelector } from 'modules/app/selectors';
import { getPath, isServer } from 'utils';
import { Routes } from 'constants/index';
import { theme } from 'theme';

export const useVergicChat = () => {
  const { vergicUrl, vergicId }: State.VergicConfig = useSelector(vergicConfigSelector);

  const removeElement = React.useCallback(() => {
    const element = document.querySelector('#vergic-chat');
    const styleElement = document.querySelector('#vergic-chat-style');
    if (element && element.parentNode) element.parentNode.removeChild(element);
    if (styleElement && styleElement.parentNode) styleElement.parentNode.removeChild(styleElement);
  }, []);

  React.useEffect(() => {
    // check variables and execution env
    if (isServer || !vergicUrl || !vergicId || (window as any).location?.pathname === getPath(Routes.VERGIC_CALENDAR)) {
      return;
    }

    // create and append script
    const body = document.querySelector('body');
    if (!body) return;
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('defer', 'true');
    script.setAttribute('id', 'vergic-chat');
    script.setAttribute('src', `${vergicUrl}/${vergicId}/engage.js`);
    document.body.appendChild(script);

    const style = document.createElement('style');
    style.setAttribute('id', 'vergic-chat-style');
    style.innerHTML = `
      .vngage-content a {
        color: ${theme.colors.primaryPurple};
        text-decoration: underline;
      }
      .vngage-content a:visited {
        color: ${theme.colors.primaryLightPurple};
      }
      .vngage-content a:hover,
      .vngage-content a:focus,
      .vngage-content a:active {
        color: ${theme.colors.interfaceBlue};
        text-decoration: none;
      }
    `;
    document.body.appendChild(style);

    // cleanup script on hook unmount
    return removeElement;
  }, [vergicUrl, vergicId, removeElement]);
};
