import React from 'react';
import { MembershipTypes, User } from 'mxp-schemas';
import { StrictDropdownProps, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { Dropdown } from 'components/atoms';
import { FirmAdminTableType } from 'constants/firm-admin';
import { subscriptionsSelector, dataSelectorFactory } from 'modules/firmAdmin/selectors';
import { useSelector } from 'react-redux';

interface Props {
  selectedDurationSku?: string;
  selectedSubscriptionSku?: string;
  testId: string;
  disabled?: boolean;
  renderAsTableCell?: boolean;
  flpSubscriptionTypeHeaderNode?: React.ReactNode;
  durationHeaderNode?: React.ReactNode;
  onSelectDuration?: (durationSku: string) => void;
  onSelectSubscription?: (subscriptionSku: string) => void;
  onUpdateSubscriptionSku?: (subscriptionSku: string, durationSku: string, productSku: string, userId?: string) => void;
  isDurationHighlighted?: boolean;
  selectedIsPaidByFirm?: boolean | string;
  tableType?: FirmAdmin.FirmAdminTableType;
  isTypeSelected?: boolean;
  hideSubscriptionDropdown?: boolean;
  hideDurationDropdown?: boolean;
  durationDisabled?: boolean;
  useCimaFeatures?: boolean;
  userId?: string;
  organizationType?: string;
  initialFlpSubscriptionType?: string;
  initialFlpSubscriptionDuration?: string;
  isNotAutoSelectedDuration?: boolean;
  isFilterModal?: boolean;
}

export const SubscriptionsAndDurationDropdown: React.FC<Props> = ({
  selectedDurationSku,
  selectedSubscriptionSku,
  testId,
  disabled,
  renderAsTableCell,
  flpSubscriptionTypeHeaderNode,
  durationHeaderNode,
  onSelectDuration,
  onSelectSubscription,
  onUpdateSubscriptionSku,
  isDurationHighlighted,
  selectedIsPaidByFirm,
  tableType,
  isTypeSelected,
  hideDurationDropdown,
  hideSubscriptionDropdown,
  durationDisabled,
  userId,
  organizationType,
  initialFlpSubscriptionType,
  initialFlpSubscriptionDuration,
  isNotAutoSelectedDuration,
  isFilterModal,
}) => {
  const [selectedSubscription, setSelectedSubscription] = React.useState<string>('');
  const [selectedDuration, setSelectedDuration] = React.useState<string>('');
  const subscriptions = useSelector(subscriptionsSelector);
  const { modifiedHash }: FirmAdmin.Data<FirmAdmin.FirmMember> = useSelector(
    dataSelectorFactory(tableType || FirmAdminTableType.MEMBERS_INVITES)
  );
  const isFlpDurationModified = userId ? Boolean(modifiedHash[userId]?.flpSubscriptionDuration) : false;

  const isFlpSubscriptionTypeModified = userId ? Boolean(modifiedHash[userId]?.flpSubscriptionType) : false;

  React.useEffect(() => {
    let ifAlreadyHasSubscriptionType = false;

    if (subscriptions?.length && selectedDurationSku && selectedDurationSku !== selectedDuration) {
      subscriptions.forEach(subscription => {
        const isSelectedDuration = subscription.variants.some(variant => variant.flpTerm === selectedDurationSku);
        const isSelectedSubcriptionSKU =
          subscription.variants.some(variant => variant.sku === selectedSubscriptionSku) ||
          subscription.slug === selectedSubscriptionSku;
        if (isSelectedDuration && isSelectedSubcriptionSKU) {
          setSelectedSubscription(subscription.slug);
          setSelectedDuration(selectedDurationSku || '');
          ifAlreadyHasSubscriptionType = true;
        }
      });
    }

    if (
      subscriptions?.length &&
      selectedSubscriptionSku &&
      selectedSubscriptionSku !== selectedSubscription &&
      !ifAlreadyHasSubscriptionType
    ) {
      setSelectedSubscription(selectedSubscriptionSku);
      setSelectedDuration(selectedDurationSku || '');
    }

    if ((selectedDurationSku === '' && selectedDuration !== selectedDurationSku) || selectedIsPaidByFirm === false) {
      setSelectedDuration('');
      setSelectedSubscription('');
      if (onSelectSubscription) onSelectSubscription('');
      if (onSelectDuration) onSelectDuration('');
    }
  }, [subscriptions, selectedDurationSku, selectedIsPaidByFirm, selectedSubscriptionSku]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDisabledSubscriptionTypeOption = (slug: User.FLPSubscriptions) => {
    const {
      SKILLS,
      SKILLS_CORE,
      SKILLS_PLUS,
      SKILLS_PREMIUM,
      SKILLS_UNIVERSITY,
      SKILLS_UNIVERSITY_CORE,
      SKILLS_PLUS_UNIVERSITY,
    } = User.FLPSubscriptions;
    // initialFlpSubscriptionType = variant's sku or slug
    const flpSubType = subscriptions.find(sub =>
      sub?.variants.find(variant => variant.sku === initialFlpSubscriptionType)
    )?.slug;

    let disableOption = false;
    switch (flpSubType || initialFlpSubscriptionType) {
      case SKILLS_PLUS:
        disableOption = [SKILLS, SKILLS_CORE].includes(slug);
        break;

      case SKILLS_PREMIUM:
        disableOption = [SKILLS, SKILLS_CORE, SKILLS_PLUS].includes(slug);
        break;

      case SKILLS_PLUS_UNIVERSITY:
        disableOption = [SKILLS_UNIVERSITY, SKILLS_UNIVERSITY_CORE].includes(slug);
        break;

      default:
        disableOption = false;
        break;
    }
    return disableOption;
  };

  const subscriptionOptions = React.useMemo(
    () =>
      subscriptions
        .filter(
          m =>
            m.variants.some(v => v.isStandard) &&
            (organizationType === MembershipTypes.OrganizationType.FLP_UNIVERSITY
              ? m.variants.some(v => v.isForUniversity)
              : !m.variants.some(v => v.isForUniversity))
        )
        .map(m => ({
          key: m.name,
          value: m.slug,
          text: m.name,
          disabled: Boolean(
            tableType === FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION &&
              handleDisabledSubscriptionTypeOption(m.slug as User.FLPSubscriptions)
          ),
        })),
    [subscriptions, tableType, organizationType] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const findDurationOptions = React.useCallback(
    slug => {
      const handleDisabledSubscriptionDurationOption = (duration: User.FLPSubscriptionDuration) => {
        const { SIX_MONTHS, TWELVE_MONTHS, TWENTY_FOUR_MONTHS, THIRTY_SIX_MONTHS } = User.FLPSubscriptionDuration;
        let disableOption = false;
        switch (initialFlpSubscriptionDuration) {
          case TWELVE_MONTHS:
            disableOption = [SIX_MONTHS].includes(duration);
            break;

          case TWENTY_FOUR_MONTHS:
            disableOption = [SIX_MONTHS, TWELVE_MONTHS].includes(duration);
            break;

          case THIRTY_SIX_MONTHS:
            disableOption = [SIX_MONTHS, TWELVE_MONTHS, TWENTY_FOUR_MONTHS].includes(duration);
            break;

          default:
            disableOption = false;
            break;
        }
        return disableOption;
      };

      return subscriptions
        .find(m => m.slug === slug)
        ?.variants.map(t => ({
          key: `${t.flpTerm} Months`,
          value: t.flpTerm,
          text: `${t.flpTerm} Months`,
          disabled: Boolean(
            tableType === FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION &&
              handleDisabledSubscriptionDurationOption(t.flpTerm as User.FLPSubscriptionDuration)
          ),
          sku: t.sku,
        }));
    },
    [subscriptions, tableType, initialFlpSubscriptionDuration]
  );
  const durationOptions = React.useMemo(() => {
    if (!selectedSubscription) return [];
    return findDurationOptions(selectedSubscription);
  }, [selectedSubscription, findDurationOptions]);

  const findDurationSku = React.useCallback(
    (slug, duration): string => {
      const findSku = subscriptions
        .find(subs => subs.slug === slug)
        ?.variants.find(variant => {
          if (duration === variant.flpTerm) return variant;
        });

      return findSku?.sku ? findSku?.sku : '';
    },
    [subscriptions] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleDurationChange = React.useCallback(
    (event?: React.SyntheticEvent<HTMLElement>, data?: StrictDropdownProps, optSelectedSubscription?: string) => {
      const duration = data?.value as string;
      setSelectedDuration(duration);
      if (onSelectDuration) onSelectDuration(duration);
      const subscriptionSku = optSelectedSubscription || selectedSubscription;
      const productSku = subscriptions
        .find(sub => sub.slug === subscriptionSku)
        ?.variants.find(variant => variant.flpTerm === duration)?.sku;
      if (onUpdateSubscriptionSku && productSku) {
        onUpdateSubscriptionSku(subscriptionSku, duration, productSku, userId);
      }
      if (isFilterModal && productSku && onSelectSubscription) onSelectSubscription(productSku);
    },
    [
      setSelectedDuration,
      onSelectDuration,
      onUpdateSubscriptionSku,
      subscriptions,
      userId,
      selectedSubscription,
      isFilterModal,
      onSelectSubscription,
    ]
  );

  const handleSubscriptionTypeChange = React.useCallback(
    (event: React.SyntheticEvent<HTMLElement>, data: StrictDropdownProps) => {
      const subscription = data.value as string;
      setSelectedSubscription(subscription);
      if (onSelectSubscription) onSelectSubscription(subscription);

      if (isNotAutoSelectedDuration) {
        setSelectedDuration('');
        if (onSelectDuration) onSelectDuration('');
        if (onUpdateSubscriptionSku) onUpdateSubscriptionSku(subscription, '', '', userId);
      } else {
        // check if updated sub type has duration option of previous sub type. e.g: premium -> core
        const durationOptionExists = findDurationOptions(subscription)?.find(
          duration => duration.value === selectedDuration
        );
        // prevents auto-selecting index 0 (12 months) when changing subscription type
        const durationOption =
          selectedDuration && durationOptionExists
            ? findDurationOptions(subscription)?.find(duration => duration.value === selectedDuration)
            : findDurationOptions(subscription)?.[0];
        handleDurationChange(undefined, durationOption, subscription);

        if (isFilterModal && onSelectSubscription) {
          const sku = findDurationSku(subscription, durationOption?.value);
          onSelectSubscription(sku);
        }
      }
    },
    [
      setSelectedSubscription,
      onSelectSubscription,
      handleDurationChange,
      findDurationOptions,
      onSelectDuration,
      onUpdateSubscriptionSku,
      isNotAutoSelectedDuration,
      userId,
      selectedDuration,
      findDurationSku,
      isFilterModal,
    ] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const renderSubscriptionTypeDropdown = (fullWidth?: boolean) => (
    <DropdownStyled
      testId={`${testId}-m-type-dropdown`}
      options={subscriptionOptions}
      value={selectedSubscription}
      onChange={handleSubscriptionTypeChange}
      placeholder="Select Subscription Type"
      disabled={disabled}
      data-full-width={fullWidth}
      data-no-margin={renderAsTableCell}
      data-highlighted={isFlpSubscriptionTypeModified}
      data-not-selected={
        !selectedSubscription ? tableType === FirmAdminTableType.MEMBERS_INVITES && !isTypeSelected : false
      }
    />
  );

  const renderDurationDropdown = (fullWidth?: boolean) => (
    <DropdownStyled
      testId={`${testId}-m-tier-dropdown`}
      options={durationOptions}
      value={selectedDuration}
      onChange={handleDurationChange}
      selectOnBlur={false}
      placeholder="Select Duration"
      disabled={durationDisabled || disabled || Boolean(!selectedSubscription)}
      data-full-width={fullWidth}
      data-no-margin={renderAsTableCell}
      data-highlighted={isDurationHighlighted || isFlpDurationModified}
      data-not-selected={
        !selectedDuration ? tableType === FirmAdminTableType.MEMBERS_INVITES && Boolean(selectedSubscription) : false
      }
    />
  );

  const renderSubscriptionTypeDropdownWithHeader = () => (
    <StyledContainer>
      {flpSubscriptionTypeHeaderNode}
      {renderSubscriptionTypeDropdown(true)}
    </StyledContainer>
  );

  const renderDurationDropdownWithHeader = () => (
    <StyledContainer>
      {durationHeaderNode}
      {renderDurationDropdown(true)}
    </StyledContainer>
  );

  return renderAsTableCell ? (
    <>
      {!hideSubscriptionDropdown && <Table.Cell singleLine>{renderSubscriptionTypeDropdown()}</Table.Cell>}
      {!hideDurationDropdown && <Table.Cell singleLine>{renderDurationDropdown()}</Table.Cell>}
    </>
  ) : (
    <>
      {!hideSubscriptionDropdown &&
        (flpSubscriptionTypeHeaderNode ? renderSubscriptionTypeDropdownWithHeader() : renderSubscriptionTypeDropdown())}

      {!hideDurationDropdown && (durationHeaderNode ? renderDurationDropdownWithHeader() : renderDurationDropdown())}
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  & ~ & {
    margin-top: ${props => props.theme.pxToRem(32)};
  }
`;

const DropdownStyled = styled(Dropdown)<{
  'data-full-width'?: boolean;
  'data-no-margin'?: boolean;
  'data-highlighted'?: boolean;
  'data-not-selected'?: boolean;
}>`
  &&&&&& {
    height: ${props => props.theme.pxToRem(44)};
    background-color: ${props => props.disabled && props.theme.colors.neutralGrey1};
    width: ${props => (props['data-full-width'] ? '100%' : props.theme.pxToRem(140))};
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => (props.disabled ? props.theme.fontWeights.black : props.theme.fontWeights.light)};
    border-radius: ${props => props.theme.pxToRem(2)};
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey4};
    font-family: ${props => props.theme.fontFamily};
    color: ${props =>
      props.disabled
        ? props.theme.colors.neutralBlack
        : props['data-highlighted']
        ? props.theme.colors.primaryPurple
        : props.theme.colors.neutralGrey8};
    padding: ${props => props.theme.pxToRem(9)} ${props => props.theme.pxToRem(15)};
    border-color: ${props =>
      props['data-highlighted']
        ? props.theme.colors.primaryPurple
        : props['data-not-selected']
        ? props.theme.colors.interfaceRed
        : props.theme.colors.neutralGrey4};
    && svg {
      min-width: ${props => props.theme.pxToRem(12)};
    }
    && .text {
      overflow: hidden;
      margin-right: ${props => props.theme.pxToRem(4)};
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
