import { AicpaLogo, OnlyDesktopCSS, OnlyMobileCSS, HeaderStepperBar } from 'components/atoms';
import { Routes, CimaMembershipSteps } from '../../../constants';
import React, { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { getPath } from 'utils';
import { MiniUserWidgetContainer } from 'containers/UserWidgetContainer';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import { StepperProps } from 'components/atoms/StepperBar/StepperBar';
import { setMembershipEvent } from 'modules/membership/actions';

interface Props {
  isCIMAJourney?: boolean;
}

const StyledAicpaLogo = styled(AicpaLogo)`
  cursor: pointer;
`;

const StyledContainer = styled(Container)`
  &&&& {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledMobileContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledMobileTop = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(14)};
    margin-bottom: ${props => props.theme.pxToRem(14)};
  }
`;

const StyledMobileBottom = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.neutralGrey1};
  padding: ${props => props.theme.pxToRem(8)} 0;
  margin: 0 ${props => props.theme.pxToRem(-22.5)};
  width: calc(100% + 45px);

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(8)};
  }
`;

const StyledDiv = styled.div`
  margin-top: ${props => props.theme.pxToRem(65)};
`;

export const HeaderMembershipCimaApplication: React.FC<Props> = () => {
  const { pathname: currPath } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogoClick = useCallback(() => {
    dispatch(setMembershipEvent('isMembershipApplicationLogoClicked', true));
    history.push(getPath(Routes.ROOT));
  }, [dispatch, history]);

  const steps: StepperProps[] = useMemo(
    () =>
      CimaMembershipSteps.map(({ name, pathName }, i) => {
        const path = getPath(pathName);
        const currPathName = currPath.replace(currPath.split('/')[3], '');

        const currPageId = CimaMembershipSteps.findIndex(
          el => getPath(el.pathName) === currPathName.substring(0, currPathName.length - 1)
        );

        return {
          title: `${name}`,
          isActive: currPathName.substring(0, currPathName.length - 1) === path,
          isSuccess: i < currPageId,
        };
      }),
    // eslint-disable-next-line
    [currPath]
  );

  return (
    <>
      <OnlyDesktopCSS>
        <StyledContainer fluid>
          <StyledAicpaLogo onClick={handleLogoClick} />
          <StyledDiv>
            <HeaderStepperBar steps={steps} />
          </StyledDiv>
          <MiniUserWidgetContainer showLogoutLink />
        </StyledContainer>
      </OnlyDesktopCSS>
      <OnlyMobileCSS>
        <StyledMobileContainer>
          <StyledMobileTop>
            <StyledAicpaLogo onClick={handleLogoClick} />
            <MiniUserWidgetContainer showLogoutLink />
          </StyledMobileTop>
          {currPath !== getPath(Routes.APPLICATION_FORM_START) && (
            <StyledMobileBottom>
              <HeaderStepperBar steps={steps} />
            </StyledMobileBottom>
          )}
        </StyledMobileContainer>
      </OnlyMobileCSS>
    </>
  );
};
