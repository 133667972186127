import { useSelector } from 'react-redux';
import { Product, User } from 'mxp-schemas';
import { extractContentCardItemPrices, checkIfCurrencyPriceInPrices } from 'utils/priceHelpers';
import { userMemberTypeSelector, userRolesSelector } from 'modules/user/selectors';
import { CountriesList, Utils } from 'mxp-utils';

export const useProductsPriceCurrency = (
  prices: State.Price[] | null,
  currency: Product.ProductCurrencyLabel,
  country?: string
) => {
  const membershipType = useSelector(userMemberTypeSelector);
  const membershipRoles = useSelector(userRolesSelector);
  const tier = CountriesList.find(c => c.key === country)?.tier || CountriesList[0]?.tier;

  const ifCurrencyPriceInPrices = checkIfCurrencyPriceInPrices(prices, currency, tier);
  const updatedPrices = Utils.getUpdatedPrices(prices as any, currency, tier);
  const priceRange = extractContentCardItemPrices(updatedPrices, membershipType, membershipRoles);

  const currencyLabel: Product.ProductCurrencyLabel = !ifCurrencyPriceInPrices
    ? Product.ProductCurrencyLabel.USD
    : updatedPrices?.[0]?.currency === Product.ProductCurrencyLabel.GBP
    ? Product.ProductCurrencyLabel.GBP
    : updatedPrices?.[0]?.currency === Product.ProductCurrencyLabel.EUR
    ? Product.ProductCurrencyLabel.EUR
    : Product.ProductCurrencyLabel.USD;

  const currencySign: Product.ProductCurrencySign = !ifCurrencyPriceInPrices
    ? Product.ProductCurrencySign.DOLLAR
    : updatedPrices?.[0]?.currency === Product.ProductCurrencyLabel.GBP
    ? Product.ProductCurrencySign.POUND
    : updatedPrices?.[0]?.currency === Product.ProductCurrencyLabel.EUR
    ? Product.ProductCurrencySign.EURO
    : Product.ProductCurrencySign.DOLLAR;

  return {
    priceRange,
    currencyLabel,
    currencySign,
  };
};

export const checkIfProductHasNonMemberPrice = (
  priceRange: Product.PriceRange,
  isAuth: boolean | null,
  isUserMember: boolean
) => {
  const combinedPriceRange = [{ ...priceRange.maxPrice }, { ...priceRange.minPrice }];
  const hasNonMemberPrice: any = combinedPriceRange.some(
    (p: any) => p.applyedDiscountRole === User.MembershipIdsEnum.NON_MEMBER
  );

  let isDisabledCart = false;

  if (!isAuth && !hasNonMemberPrice) isDisabledCart = true;
  else if (isAuth && !isUserMember && !hasNonMemberPrice) isDisabledCart = true;

  return isDisabledCart;
};
