import React, { ReactNodeArray } from 'react';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';

interface TwoColumnProps {
  className?: string;
  children: ReactNodeArray;
  mobileLeftWidth?: SemanticWIDTHS;
  mobileRightWidth?: SemanticWIDTHS;
  computerLeftWidth?: SemanticWIDTHS;
  computerRightWidth?: SemanticWIDTHS;
}

export const TwoColumn: React.SFC<TwoColumnProps> = ({
  className,
  children,
  mobileLeftWidth,
  mobileRightWidth,
  computerLeftWidth,
  computerRightWidth,
}) => {
  return (
    <Grid className={className} stackable columns={2}>
      <Grid.Column mobile={mobileLeftWidth} tablet={mobileLeftWidth} computer={computerLeftWidth}>
        {children[0]}
      </Grid.Column>
      <Grid.Column mobile={mobileRightWidth} tablet={mobileRightWidth} computer={computerRightWidth}>
        {children[1]}
      </Grid.Column>
    </Grid>
  );
};
