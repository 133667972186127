import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/browser';
import { isServer, isSSOAuthSupported } from 'utils';
import { User as UserTypes } from 'mxp-schemas';

import { AuthHelper } from './base';
const OKTA_STORAGE_KEY = 'oktaToken';
const USE_PKCE = false;
export class ConsumerAuthHelper extends AuthHelper {
  /***
   * On some devices SSO is not supported due too cookie sharing issues; disable it and ensure
   * sign is at least possible
   */
  private disableSSO = false;
  constructor() {
    super(
      OKTA_STORAGE_KEY,
      // using cookie as storage as XSS threat is bigger that XSRF,
      // when using local or session storage any f/e included npm package will
      // have access to storage and, therefor, to your tokens.
      {
        storage: 'cookie',
        secure: isServer || window.location.protocol === 'https:',
        autoRenew: true,
      },
      USE_PKCE
    );
    if (!isServer) {
      this.disableSSO = !isSSOAuthSupported();
      setTimeout(() => {
        // essential cleanup activities
        this.cleanupLegacyCookies();
      }, 1);
    }
  }
  private async generateAccessToken(sessionToken?: any) {
    const authorizationResponse = await this.authClient.token.getWithoutPrompt({
      scopes: ['spa'],
      responseType: ['token'],
      sessionToken,
      pkce: USE_PKCE,
    });
    return authorizationResponse?.tokens?.accessToken;
  }

  async signInUser(email: string, password: string) {
    try {
      const transaction = await this.authClient.signIn({
        username: email,
        password,
      });

      if (
        transaction.status === UserTypes.UserErrorCodes.LOCKED_OUT ||
        transaction.status === UserTypes.UserErrorCodes.PASSWORD_EXPIRED
      ) {
        return transaction;
      }
      if (transaction.status !== 'SUCCESS') {
        throw new Error(`unable to log-in, status: ${transaction.status}`);
      }

      const token = await this.generateAccessToken(transaction.sessionToken);

      this.authClient.tokenManager.add(this.oktaStorageKey, token);
      return transaction;
    } catch (error) {
      try {
        Sentry.captureException(`signInUser failed with error: ${error}`);
      } catch {
        console.error('Sentry failed to capture okta exception');
      }
      return error;
    }
  }

  async sessionExists() {
    if (isServer) return null;
    try {
      if (this.disableSSO) {
        /**
         * If SSO is disabled do not check the session (cross-site cookie).
         * Instead rely on the token stored (valid for 8 hours) to log the user in / out on future.aipca.org
         * Login / logout actions are no synced with other AICPA sites
         */
        const existingToken = await this.getValidAccessToken();
        if (existingToken) {
          return UserTypes.SessionType.OKTA_SESSION;
        }
      }
      const session = await this.authClient.session.exists();
      if (!session) {
        await this.clearTokens();
        return UserTypes.SessionType.NO_SESSION;
      }
      const now: number = Date.now();
      const sessionToken = await this.authClient.session.get();
      if (!sessionToken || new Date(sessionToken.expiresAt).getTime() <= now) {
        return UserTypes.SessionType.NO_SESSION;
      }

      const token = await this.generateAccessToken();
      if (!token) {
        const tokenToRevoke = await this.authClient.tokenManager.get(this.oktaStorageKey);
        await this.clearTokens();
        await this.authClient.revokeAccessToken(tokenToRevoke);
        return UserTypes.SessionType.NO_SESSION;
      }
      await this.authClient.tokenManager.add(this.oktaStorageKey, token);
      return UserTypes.SessionType.OKTA_SESSION;
    } catch (error) {
      Sentry.captureException(error);
      // if isAuth === false redirection will be performed by SecureRoute
      return UserTypes.SessionType.NO_SESSION;
    }
  }

  async getUserTokenData(): Promise<{ sub: string } | null> {
    const token: string | undefined = await this.getValidAccessToken();
    if (!token) return null;
    const parsedToken: { sub: string } = jwtDecode(token);
    if (!parsedToken) return null;
    return parsedToken;
  }
  async resetPassword(recoveryToken: string, newPassword: string) {
    if (isServer) return null;
    const transaction: any = await this.authClient.verifyRecoveryToken({
      recoveryToken,
    });
    await transaction.resetPassword({
      newPassword,
    });
    return transaction.data?._embedded?.user?.id;
  }
  async getValidAccessToken(): Promise<string | undefined> {
    return this.getValidAccessTokenFromOktaStore();
  }
  async signOutUser(): Promise<void> {
    return this.signOutUserFromOkta();
  }
  protected async getValidAccessTokenFromOktaStore(): Promise<string | undefined> {
    try {
      const token = await this.authClient.tokenManager.get(this.oktaStorageKey);
      if (!token || !token.accessToken) return;
      const now: number = Date.now();
      if (token.expiresAt * 1000 <= now) {
        await this.clearTokens();
        return;
      }
      return token.accessToken;
    } catch (error) {
      Sentry.captureException(error);
      return;
    }
  }
  async signOutUserFromOkta(): Promise<void> {
    if (isServer) return;
    const session = await this.authClient.session.exists();
    const token = await this.authClient.tokenManager.get(this.oktaStorageKey);
    await this.clearTokens();
    if (session) {
      // sign-out will cause a redirect / page reload
      await this.authClient.signOut({
        postLogoutRedirectUri: window.location.origin,
        accessToken: token,
      });
    } else if (token) {
      // Session doesn't exist but the access token is available (e.g. SSO disabled for Safari)
      // revoke the token
      await this.authClient.revokeAccessToken(token);
      // force a window re-direct to ensure consistency with the session exist route
      window.location.replace(window.location.origin);
    }
  }
}
