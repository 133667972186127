import React from 'react';
import { theme } from 'theme';

interface Props {
  size?: number;
  color?: string;
}

export const StyledCheck = React.memo<Props>(({ size = 24, color = theme.colors.primaryPurple, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      fill-rule="nonzero"
      d="M12.001 2a2.16 2.16 0 0 1 1.638.734l.105.132a2.168 2.168 0 0 0 1.747.865c.23 0 .458-.037.676-.108a2.206 2.206 0 0 1 1.944.282c.562.392.89 1.022.88 1.69-.011.907.598 1.715 1.5 1.986a2.1 2.1 0 0 1 1.397 1.322 1.99 1.99 0 0 1-.311 1.86 1.994 1.994 0 0 0 0 2.464c.414.535.532 1.23.319 1.863A2.102 2.102 0 0 1 20.5 16.42c-.902.27-1.512 1.078-1.5 1.986.01.67-.32 1.3-.883 1.693a2.206 2.206 0 0 1-1.95.276c-.895-.292-1.882.017-2.429.757-.364.499-.94.813-1.565.862L12 22a2.152 2.152 0 0 1-1.743-.866c-.545-.739-1.53-1.048-2.423-.76a2.206 2.206 0 0 1-1.944-.282 2.026 2.026 0 0 1-.88-1.69c.011-.907-.598-1.715-1.5-1.986a2.103 2.103 0 0 1-1.399-1.325 1.992 1.992 0 0 1 .313-1.864 1.99 1.99 0 0 0 0-2.464 1.99 1.99 0 0 1-.32-1.863 2.1 2.1 0 0 1 1.397-1.328c.901-.272 1.51-1.079 1.5-1.987a2.027 2.027 0 0 1 .886-1.688 2.206 2.206 0 0 1 1.947-.274c.22.072.45.108.682.108.694 0 1.345-.322 1.747-.865.365-.498.94-.81 1.565-.86L12.001 2zm4.434 6-5.73 5.824-3.14-3.175L6 12.235 10.705 17 18 9.589 16.435 8z"
    />
  </svg>
));
