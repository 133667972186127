import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

type InfoIconPopupProps = {
  data: string;
  subData?: string;
  size: 's' | 'm' | 'l' | 'xxl' | 'xl' | 'xs' | 'xxs';
  minWidth: number;
};
type FontSizes = {
  xxl: string;
  xl: string;
  l: string;
  m: string;
  s: string;
  xs: string;
  xxs: string;
};

export const InfoIconPopup: React.FC<InfoIconPopupProps> = ({ data, subData, size, minWidth }) => {
  const popupTrigger = () => (
    <StyledIconWrapper>
      <StyledIcon name="info circle" size={size} minWidth={minWidth} />
    </StyledIconWrapper>
  );

  const popupContent = () => (
    <PopContainer>
      <p>{data}</p>
      <p>{subData} </p>
    </PopContainer>
  );

  return (
    <StyledPopup
      content={popupContent()}
      key=""
      position="bottom right"
      trigger={popupTrigger()}
      hoverable
      hideOnScroll
    />
  );
};

const getFontSize = (fontSizes: FontSizes, size: string) => {
  return fontSizes[size as keyof typeof fontSizes];
};

const StyledIconWrapper = styled.div`
  padding-right: ${props => props.theme.pxToRem(12)};
  padding-bottom: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(16)};
  width: ${props => props.theme.pxToRem(28)};
`;

const StyledIcon = styled(Icon)`
  &&&& {
    color: ${props => props.theme.colors.primaryPurple};
    width: ${props => props.theme.pxToRem(26)};
    height: ${props => props.theme.pxToRem(26)};
    font-size: ${props => getFontSize(props.theme.fontSizes, props.size)};
  }
`;

const StyledPopup = styled(Popup)`
  &&&& {
    padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(20)};
    min-width: ${props => props.theme.pxToRem(props.minWidth)};
  }
`;

const PopContainer = styled.div`
  > p:first-child {
    font-size: ${props => props.theme.fontSizes.s};
    color: ${props => props.theme.colors.neutralGrey5};
  }
  > p:last-child {
    font-size: ${props => props.theme.fontSizes.s};
  }
`;
