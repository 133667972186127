import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { DefaultInlineLinkStyles } from 'components/atoms/Link/Link';

import { PROFILE_WHITE } from 'resources/images';

interface Props {
  logout: () => void;
  showLogoutLink?: boolean;
  userName: string | undefined;
  isMembershipJourney?: boolean;
  isWhite?: boolean;
}

export const MiniUserWidget = React.memo<Props>(
  ({ userName, logout, showLogoutLink = false, isMembershipJourney = false, isWhite = false }) => {
    const logoutClick = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      logout();
    };
    const membershipStyle = {
      color: '#fff',
    } as const;

    return (
      <MiniFlexDiv>
        <StyledMiniIcon src={PROFILE_WHITE} alt="profileIco" />
        <MiniUserDetails>
          <MiniUserName {...(isMembershipJourney && { style: membershipStyle })} isWhite={isWhite}>
            {userName}
          </MiniUserName>
          <MiniLogoutLink
            isWhite={isWhite}
            onClick={logoutClick}
            {...(isMembershipJourney && { style: membershipStyle })}
          >
            Log out
          </MiniLogoutLink>
        </MiniUserDetails>
      </MiniFlexDiv>
    );
  }
);

interface MiniUserDetailsProps {
  isWhite?: boolean;
}

const StyledMiniIcon = styled.img`
  width: 52px;
  height: 59px;
  margin-right: 4px;
  margin-left: 21px;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 34px;
    height: 34px;
    margin: 0 8px 0 0;
  }
`;

const MiniFlexDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 0;

  ${props => props.theme.mediaQueries.desktopOnly} {
    padding-right: 40px;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-right: 0;
    align-items: flex-start;
  }
`;

const MiniLogoutLink = styled.a<MiniUserDetailsProps>`
  ${props =>
    props.isWhite &&
    css`
      color: ${props.theme.colors.neutralWhite} !important;
    `}
  cursor: pointer;
  display: block;
  font-size: ${props => props.theme.fontSizes.xs};

  ${DefaultInlineLinkStyles};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xxs};
    line-height: 1.5;
  }
`;

const MiniUserName = styled.div<MiniUserDetailsProps>`
  color: ${props => props.theme.colors.neutralGrey8};
  ${props =>
    props.isWhite &&
    css`
      color: ${props.theme.colors.neutralWhite};
    `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
    line-height: 1.57;
  }
`;

const MiniUserDetails = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0;
    text-align: left;
  }
`;
