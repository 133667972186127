import React from 'react';
import styled from 'styled-components';
import { Lock } from 'components/atoms/svg/Lock';

export enum LockIconWrapperSizes {
  small = 'small',
  large = 'large',
}

interface LockIconWrapperProps {
  size?: LockIconWrapperSizes;
}

export const LockIconWrapper: React.FC<LockIconWrapperProps> = React.memo(({ size = LockIconWrapperSizes.large }) => {
  return (
    <IconWrapper size={size}>
      <Lock />
    </IconWrapper>
  );
});

const IconWrapper = styled.div<{ size: string }>`
  ${props =>
    props.size === LockIconWrapperSizes.large &&
    `
      width: ${props.theme.pxToRem(64)};
      height: ${props.theme.pxToRem(64)};
      margin: 0 ${props.theme.pxToRem(8)};
      padding: ${props.theme.pxToRem(10)} 0 0 ${props.theme.pxToRem(10)};
      bottom: ${props.theme.pxToRem(18)};
      box-shadow: 0 0 ${props.theme.pxToRem(17)} ${props.theme.pxToRem(2)} ${props.theme.colors.neutralGrey3};

      & svg {
        width: ${props.theme.pxToRem(43)};
        height: ${props.theme.pxToRem(43)};
      }
    `}

    ${props =>
      props.size === LockIconWrapperSizes.small &&
      `
      width: ${props.theme.pxToRem(24)};
      height: ${props.theme.pxToRem(24)};
      margin: 0 ${props.theme.pxToRem(8)} 0 0;
      padding: ${props.theme.pxToRem(3)};
      box-shadow: 0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(8)} 0 rgba(0, 0, 0, 0.1);
      & svg {
        width: ${props.theme.pxToRem(18)};
        height: ${props.theme.pxToRem(18)};
      }
    `}

  ${props => `
        background-color: ${props.theme.colors.neutralWhite};
        border-radius: 50%;
        display: inline-block;
        position: relative;
        `}
`;
