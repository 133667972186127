import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { getSkillsHash } from './actions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [getSkillsHash]: {
    next: (state: State.Skills, action: any): State.Skills => ({
      ...state,
      skillsHash: action.payload,
      skillsFetched: true,
    }),

    throw: (state: State.Skills, action: any): State.Skills => ({
      ...state,
      error: action.payload, // TODO
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
