import React, { useState } from 'react';
import styled from 'styled-components';
import { InputWithValidation } from 'components/molecules';
import { Checkbox, Dropdown } from 'components/atoms';
import { CheckboxProps as SemanticUICheckboxProps, StrictDropdownProps } from 'semantic-ui-react';
import { Utils, CountriesList } from 'mxp-utils';
import { currentStatusOptions } from 'constants/index';
import { InquiryForm, MembershipTypes } from 'mxp-schemas';
import { RadioOption } from 'components/molecules/RadioOption/RadioOption';
import { MobileInput } from 'components/atoms/Input/MobileInput';
import { useSelector } from 'react-redux';
import { registrationRestrictedCountriesSelector } from 'modules/featureToggle/selectors';

interface InquiryFormCIMAMembershipProps {
  setFormValid: (valid: boolean) => void;
  form: InquiryForm.CIMAMembership;
  setForm: (form: InquiryForm.CIMAMembership) => void;
  onFormStart: () => void;
}

export const InquiryFormCIMAMembership: React.FC<InquiryFormCIMAMembershipProps> = ({
  form,
  setForm,
  setFormValid,
  onFormStart,
}) => {
  const restrictedCountries = useSelector(registrationRestrictedCountriesSelector);
  const filteredOptions = Object.values(CountriesList).filter(
    option => !restrictedCountries.some(restrictedCountry => restrictedCountry === option.value)
  );

  const optionsCountryList = Object.values(filteredOptions).map((country: any, index) => {
    return {
      key: index,
      value: country.ISOAlpha3Code,
      text: Boolean(country.textUI) ? country.textUI : country.text,
      image: country.image,
    };
  });
  const isFirstNameEmpty = !Boolean(form.firstName.length);
  const isFirstNameValid =
    Utils.isShorterThan(form.firstName, InquiryForm.FieldLengths.FIRST_NAME) && Utils.isAlphabet(form.firstName);
  const isFirstNameLegal = !Boolean(/^[A-Za-z]+$/u.test(form.firstName) || null);
  const isLastNameEmpty = !Boolean(form.lastName.length);
  const isLastNameValid =
    Utils.isShorterThan(form.lastName, InquiryForm.FieldLengths.LAST_NAME) &&
    Boolean(/^[a-zA-Z -]+$/u.test(form.lastName));
  const isLastNameLegal = !Boolean(/^[A-Za-z -]+$/u.test(form.lastName) || null);

  const isEmailEmpty = !Boolean(form.email.length);
  const isEmailValid =
    Utils.isEmailFormatValid(form.email) && Utils.isShorterThan(form.email, InquiryForm.FieldLengths.EMAIL);
  const phoneInput = form?.phone.split(' ')[1];
  const isPhoneEmpty = !Boolean(phoneInput);
  const isPhoneValid = Utils.isShorterThan(phoneInput, 255) && Utils.isNumericString(phoneInput.toString());
  const isCurrentStatusEmpty = !Boolean(form.currentStatus.length);
  const isLocationEmpty = !Boolean(form.location.length);
  const isProductSku = !Boolean(form.productSku);
  const isReceivedSMSChecked = !Boolean(form.receivedSms);

  const [phoneObject, setPhoneObject] = useState({
    phoneNumber: form?.phone || '',
    countryCode: form?.countryCode || '',
    dialCode: form?.dialCode || '',
  });
  const checkState = form.location === 'USA' ? true : false;
  const mobileErrorMessage = !isPhoneValid && !isPhoneEmpty ? 'Please enter a valid mobile number' : null;

  React.useEffect(() => {
    const formValid =
      !isEmailEmpty &&
      isEmailValid &&
      !isFirstNameEmpty &&
      isFirstNameValid &&
      !isFirstNameLegal &&
      !isLastNameEmpty &&
      !isLastNameLegal &&
      isLastNameValid &&
      !isCurrentStatusEmpty &&
      !isLocationEmpty &&
      !isReceivedSMSChecked &&
      (isPhoneEmpty || isPhoneValid) &&
      !isProductSku;

    setFormValid(formValid);
  }, [
    form,
    isEmailValid,
    isEmailEmpty,
    isFirstNameEmpty,
    isFirstNameValid,
    isFirstNameLegal,
    isLastNameEmpty,
    isLastNameValid,
    isLastNameLegal,
    isPhoneEmpty,
    isPhoneValid,
    isCurrentStatusEmpty,
    isLocationEmpty,
    isProductSku,
    isReceivedSMSChecked,
    setFormValid,
  ]);

  // TODO: Need to refactor the way MobileInput component is handling its state so this can process be omitted.
  React.useEffect(() => {
    if (phoneObject) {
      setForm({
        ...form,
        phone: `${phoneObject.dialCode} ${phoneObject.phoneNumber}`,
        countryCode: phoneObject.countryCode,
      });
    }
  }, [phoneObject]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, firstName: event.target.value as string });
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, lastName: event.target.value as string });
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, email: event.target.value as string });
  };
  const handleCurrentStatusDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const currentStatus: string = data.value as string;
      setForm({ ...form, currentStatus });
    },
    [setForm, form]
  );
  const handleLocationChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const location: string = data.value as string;
      setForm({ ...form, location });
    },
    [setForm, form]
  );
  const handleTellMeMoreChange = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      setForm({ ...form, receivedEmail: Boolean(data.checked) });
    },
    [setForm, form]
  );

  const handleSmsAgreeChange = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      setForm({ ...form, receivedSms: Boolean(data.checked) });
    },
    [setForm, form]
  );

  const handleFieldChange = (value: string) => (event: React.MouseEvent) => {
    setForm({
      ...form,
      productSku: value,
    });
  };

  const fieldRequired = 'Required';
  return (
    <>
      <DropDownContainer>
        <StyledDropdown
          testId={InquiryForm.FieldIds.CURRENT_STATUS}
          onChange={handleCurrentStatusDropdownChange}
          options={currentStatusOptions}
          value={form.currentStatus}
          placeholder="Current status"
          upward={false}
        />
      </DropDownContainer>
      <InputWithValidation
        labelName={'First Name'}
        name={InquiryForm.FieldIds.FIRST_NAME}
        type="text"
        value={form.firstName}
        testId={InquiryForm.FieldIds.FIRST_NAME}
        labelId={InquiryForm.FieldIds.FIRST_NAME}
        onChange={handleFirstNameChange}
        onClick={onFormStart}
        isCorrect={isFirstNameValid || isFirstNameEmpty}
        infoMessage={fieldRequired}
        placeholder="Enter first name here"
        errorMessage={'Please enter a valid first name'}
      />
      <InputWithValidation
        labelName={'Last Name'}
        name={InquiryForm.FieldIds.LAST_NAME}
        type="text"
        value={form.lastName}
        testId={InquiryForm.FieldIds.LAST_NAME}
        labelId={InquiryForm.FieldIds.LAST_NAME}
        onChange={handleLastNameChange}
        isCorrect={isLastNameValid || isLastNameEmpty}
        infoMessage={fieldRequired}
        placeholder="Enter last name here"
        errorMessage={'Please enter a valid last name'}
      />
      <DropDownContainer>
        <StyledDropdown
          testId={InquiryForm.FieldIds.COUNTRY_CODE}
          onChange={handleLocationChange}
          options={optionsCountryList}
          value={form.location}
          placeholder="Location"
          search={true}
        />
      </DropDownContainer>
      <InputWithValidation
        labelName={'Email Address'}
        name={InquiryForm.FieldIds.EMAIL}
        type="email"
        value={form.email}
        testId={InquiryForm.FieldIds.EMAIL}
        labelId={InquiryForm.FieldIds.EMAIL}
        onChange={handleEmailChange}
        isCorrect={isEmailValid || isEmailEmpty}
        infoMessage={fieldRequired}
        placeholder="Enter email here"
        errorMessage={'Please enter a valid email address.'}
        isCorrectIconShown
      />
      <StyledMobileInput phoneObject={phoneObject} setPhoneObject={setPhoneObject} errorMessage={mobileErrorMessage} />
      <Label>I would like some specific information about:</Label>
      <RadioButtonContainer>
        {MembershipTypes.CIMAMembershipType.map(type => (
          <StyledRadioOption
            key={type.key}
            label={type.text}
            value={type.value}
            onClick={handleFieldChange(type.value)}
            checked={form.productSku === type.value}
          />
        ))}
      </RadioButtonContainer>
      {!checkState && (
        <StyledCheckbox
          width="18"
          height="18"
          type="checkbox"
          testId={InquiryForm.FieldIds.RECEIVED_EMAIL}
          label={'I would like to receive email updates about AICPA and CIMA news, offers and products & services.'}
          checked={form.receivedEmail}
          onChange={handleTellMeMoreChange}
        />
      )}
      <StyledCheckbox
        width="18"
        height="18"
        type="checkbox"
        testId={InquiryForm.FieldIds.RECEIVED_SMS}
        label={'I agree to receive SMS messages. Text and data rates may apply.'}
        checked={form.receivedSms}
        onChange={handleSmsAgreeChange}
      />
    </>
  );
};

const StyledRadioOption = styled(RadioOption)`
  &&&&& {
    object-fit: contain;
    line-height: 1;
    margin-top: ${props => props.theme.pxToRem(5)};
    margin-bottom: ${props => props.theme.pxToRem(10)};
    > label {
      font-size: ${props => props.theme.fontSizes.xs};
      color: ${props => props.theme.colors.neutralGrey8};
      line-height: 1.57;
      font-weight: ${props => props.theme.fontWeights.light};
      padding-left: ${props => props.theme.pxToRem(24)};
    }
  }
`;

const RadioButtonContainer = styled.div`
  &&&& {
    display: block;
    justify-content: space-around;
    margin-top: ${props => props.theme.pxToRem(32)};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  &&&&& {
    object-fit: contain;
    line-height: 1;
    margin-top: ${props => props.theme.pxToRem(5)};
    margin-bottom: ${props => props.theme.pxToRem(23)};
    > label {
      font-size: ${props => props.theme.fontSizes.xs};
      color: ${props => props.theme.colors.neutralGrey8};
      line-height: 1.57;
      font-weight: ${props => props.theme.fontWeights.light};
      padding-left: ${props => props.theme.pxToRem(24)};
    }
  }
`;

const DropDownContainer = styled.div`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(26)};
  }
`;

const Label = styled.label`
  margin: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(24)} 0;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      width: 100%;
      height: ${props.theme.pxToRem(48)};
      padding-right: ${props.theme.pxToRem(10)};
      background-color: ${props.theme.colors.neutralWhite};
      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }
      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.primaryPurple};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }
      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.primaryPurple};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }
      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.interfaceBlue};
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
    }
  `}
`;

const StyledMobileInput = styled(MobileInput)`
  &&& {
    width: ${props => props.theme.pxToRem(260)} !important;
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: auto !important;
    }
  }
`;
