import React from 'react';
import { Radio } from 'semantic-ui-react';
import styled from 'styled-components';
import { ReactComponent as IconDone } from 'resources/images/icon-dev-ic-done.svg';

interface Props {
  label: string;
  handleChange: () => void;
  checked?: boolean;
  disabled?: boolean;
  testId?: string;
  className?: string;
  isBgColorDark?: boolean;
}

export const RadioToggle: React.FC<Props> = ({
  testId = 'radio-toggle',
  label = '',
  handleChange,
  checked = false,
  disabled,
  className,
  isBgColorDark = false,
}) => (
  <StyledRadioWrapper className={className}>
    <StyledRadio
      data-testid={testId}
      toggle
      onChange={handleChange}
      checked={checked}
      disabled={disabled}
      label={label}
      isBgColorDark={isBgColorDark}
    />
    <StyledIconDone checked={checked} />
  </StyledRadioWrapper>
);

const StyledRadioWrapper = styled.div`
  position: relative;
`;

const StyledIconDone = styled(IconDone)<{ checked: boolean }>`
  display: block;
  position: absolute;
  top: ${props => props.theme.pxToRem(5)};
  left: ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(11)};
  height: ${props => props.theme.pxToRem(11)};
  pointer-events: none;
  z-index: 2;
  opacity: ${props => (props.checked ? '1' : '0')};

  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledRadio = styled(Radio)<{ isBgColorDark: boolean }>`
  &.ui.toggle.checkbox input {
    width: ${props => props.theme.pxToRem(42)};
    height: ${props => props.theme.pxToRem(22)};
  }
  &.ui.toggle.checkbox label {
    font-weight: ${props => props.theme.fontWeights.medium};
    padding-left: ${props => props.theme.pxToRem(53)};
    padding-top: ${props => props.theme.pxToRem(2)};
    font-size: ${props => props.theme.fontSizes.xs};
  }
  &&&&.ui.toggle.checkbox label:before {
    width: ${props => props.theme.pxToRem(40)};
    height: ${props => props.theme.pxToRem(20)};
  }

  ${props =>
    !props.isBgColorDark
      ? `
        &&&&.ui.toggle.checkbox label:before {
          border: 1px solid ${props.theme.colors.neutralGrey7};
          background: ${props.theme.colors.neutralGrey4};
        }
        &&&&.ui.toggle.checkbox:hover label:before {
          border: 1px solid ${props.theme.colors.neutralGrey7};
          background: ${props.theme.colors.neutralGrey4};
        }
        &&&&.ui.toggle.checkbox.checked label:before {
          border: 1px solid ${props.theme.colors.primaryPurple};
          background: ${props.theme.colors.primaryPurple} !important;
        }
      `
      : `
        &&&&.ui.toggle.checkbox label:before {
          border: 1px solid ${props.theme.colors.neutralWhite};
          background: ${props.theme.colors.neutralGrey4};
        }
        &&&&.ui.toggle.checkbox:hover label:before {
          border: 1px solid ${props.theme.colors.neutralWhite};
          background: ${props.theme.colors.neutralGrey4};
        }
        &&&&.ui.toggle.checkbox.checked label:before {
          border: 1px solid ${props.theme.colors.neutralWhite};
          background: ${props.theme.colors.primaryPurple} !important;
        }
      `}

  &.ui.toggle.checkbox label:after {
    left: ${props => props.theme.pxToRem(2)};
    top: ${props => props.theme.pxToRem(2)};
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  }
  &.ui.toggle.checkbox.checked label:after {
    left: ${props => props.theme.pxToRem(21)};
  }
`;
