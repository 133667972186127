import React from 'react';
import { Salesforce } from 'mxp-schemas';
import { FilterRadioLabel } from 'components/molecules/FilterRadioLabel/FilterRadioLabel';
import styled from 'styled-components';

interface Props {
  testId: string;
  address: State.Address | null;
  checked: boolean;
  onChange: () => void;
  hasMultipleSavedAddresses?: boolean;
  useAvsDesigns?: boolean | null;
}

export const SavedAddressCard: React.FC<Props> = ({
  testId,
  address,
  checked,
  onChange,
  hasMultipleSavedAddresses,
  useAvsDesigns=false
}) => {
  if (!address) return null;
  const { firstName, lastName, addressLine1, addressLine2, city, state, zipCode, company, country, addressType } =
    address as Salesforce.ContactPointAddress;
  const nameExists: boolean = Boolean(firstName || lastName);

  return (
    <FilterRadioLabelWrapper>
      <FilterRadioLabel
        className="radio"
        checked={checked}
        testId={testId}
        useAvsDesigns={useAvsDesigns}
        onChange={onChange}
        reverseLabelAndRadio
      >
        <div>
          {nameExists && (
            <>
              {firstName} {lastName}
              <br />
            </>
          )}
          {company && (
            <>
              {company} <br />
            </>
          )}
          {addressLine1} <br />
          {addressLine2 && (
            <>
              {addressLine2} <br />
            </>
          )}
          {city}, {state} {zipCode}
          <br />
          {country}
          <br />
          {addressType}
        </div>
      </FilterRadioLabel>
    </FilterRadioLabelWrapper>
  );
};

const FilterRadioLabelWrapper = styled.div`
  display: inline-flex;
`;
