import React from 'react';
import styled from 'styled-components';
import { ContentCard } from 'components/molecules/ContentCard/ContentCard';
import { ButtonEnums, ButtonLink, Grid, Link } from 'components/atoms';
import { getContentUrl } from 'utils';
import { StyledDivider } from 'components/pages/PageToolkit/StyledDivider';
import { Content } from 'mxp-schemas';
import { ReactComponent as ExternalIcon } from 'resources/images/ic-open-in-new.svg';
import { ExternalLinkStyles, DefaultInlineLinkStyles } from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE } from 'resources/images';

interface Props {
  item: any;
  showDivider?: boolean;
  idx: number;
}
/* tslint:disable:no-shadowed-variable */

export const RenderBlockList: React.FC<Props> = React.memo(({ item, showDivider = false, idx }) => {
  const { displayType, staticOrDynamicList, header, description } = item;
  const isDisplayText: boolean = displayType === Content.DisplayType.TEXT;

  const renderListItems = (listItem: any, contentType: string) => {
    const isExternalLink = Boolean(new RegExp(/^http(s)?:\/\//).test(getContentUrl(listItem)));
    switch (contentType) {
      case Content.ContentType.DYNAMIC_LIST:
        return (
          <StyledLinkBlock key={listItem.id}>
            {isExternalLink && <StyledExternalIcon />}
            <StyledLinkTitle isExternal={!!listItem.externalUrl} to={getContentUrl(listItem)}>
              {listItem.title}
            </StyledLinkTitle>
            <StyledLinkParagraph tabIndex={0}>{listItem.description}</StyledLinkParagraph>
          </StyledLinkBlock>
        );
      case Content.ContentType.LISTS:
        return (
          <StyledLinkBlock key={`link-${listItem.id}`}>
            {isExternalLink && <StyledExternalIcon />}
            <StyledLinkTitle isExternal={listItem.url} to={getContentUrl(listItem)}>
              {listItem.title || listItem.name}
            </StyledLinkTitle>
            <StyledLinkParagraph tabIndex={0}>{listItem.description}</StyledLinkParagraph>
          </StyledLinkBlock>
        );
      default:
        return null;
    }
  };

  const dynamicListButtons = (listItem: any) => {
    return (
      <>
        {listItem?.ctaUrl && (
          <StyledButtonBlock isDisplayText={isDisplayText}>
            <StyledButtonLink
              size={ButtonEnums.sizes.medium}
              variant={ButtonEnums.variants.primary}
              testId="search"
              to={listItem.ctaUrl}
            >
              {listItem.linkText}
            </StyledButtonLink>
          </StyledButtonBlock>
        )}
        {listItem.pageLink && listItem.pageLinkDisplayText && (
          <StyledButtonBlock isDisplayText={isDisplayText}>
            <StyledButtonLink
              size={ButtonEnums.sizes.medium}
              variant={ButtonEnums.variants.secondary}
              testId="page-link"
              to={listItem.pageLink}
            >
              {listItem.pageLinkDisplayText}
            </StyledButtonLink>
          </StyledButtonBlock>
        )}
      </>
    );
  };

  const renderImageItems = (listItem: State.ContentCardItem, contentType: string) => {
    switch (contentType) {
      case Content.ContentType.DYNAMIC_LIST:
        return (
          <ImageLayoutColumn key={`dynamicList-image-${listItem.id}`} rows={3}>
            <ContentCard contentCardItem={listItem} testid="dynamic-list-content-card" isAggregationCard />
          </ImageLayoutColumn>
        );
      case Content.ContentType.LISTS:
        return (
          <ImageLayoutColumn key={`list-image-${listItem.id}`} rows={3}>
            <SmallImageCard to={getContentUrl(listItem)} isExternal={!!listItem.url}>
              {!listItem.image.url && <NameLine>{listItem.title || listItem.name}</NameLine>}
              {listItem.image.url && <ContentCard contentCardItem={listItem} />}
            </SmallImageCard>
          </ImageLayoutColumn>
        );
      default:
        return null;
    }
  };

  return (
    <div key={`list-block-${idx}`}>
      <MainColumn>
        {header && (
          <StyledHeading dangerouslySetInnerHTML={{ __html: header }} data-is-single-column={true} tabIndex={0} />
        )}
        {description && (
          <>
            <StyledParagraph dangerouslySetInnerHTML={{ __html: description }} tabIndex={0} />
            <br />
          </>
        )}
        {staticOrDynamicList?.map((staticOrDynamicListItem: any) => (
          <ListLayout key={staticOrDynamicListItem.id}>
            {displayType === Content.DisplayType.TEXT &&
              staticOrDynamicListItem.list?.map((listItem: any) =>
                renderListItems(listItem, staticOrDynamicListItem.contentType)
              )}
            {displayType === Content.DisplayType.IMAGE && (
              <ImageLayout>
                <ImageLayoutRow columns={3}>
                  {staticOrDynamicListItem.list?.map((listItem: any) =>
                    renderImageItems(listItem, staticOrDynamicListItem.contentType)
                  )}
                </ImageLayoutRow>
              </ImageLayout>
            )}
            {staticOrDynamicListItem.contentType === Content.ContentType.DYNAMIC_LIST &&
              dynamicListButtons(staticOrDynamicListItem)}
          </ListLayout>
        ))}
        {showDivider && <StyledDivider />}
      </MainColumn>
    </div>
  );
});

const StyledHeading = styled.div<{ 'data-is-single-column': boolean }>`
  margin-bottom: ${props => (props['data-is-single-column'] ? props.theme.pxToRem(16) : props.theme.pxToRem(24))};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;

  p {
    line-height: 1.38;
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.l};
    line-height: 1.33;
  }
`;

const StyledParagraph = styled.div`
  &&&& {
    font-weight: ${props => props.theme.fontWeights.light};
    font-size: ${props => props.theme.fontSizes.m};
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};
  }
`;

const ImageLayout = styled(Grid)`
  &&& {
    width: 100%;
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const ImageLayoutRow = styled(Grid.Row)`
  &&&& {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }
`;

const ImageLayoutColumn = styled(Grid.Column)`
  padding-bottom: ${props => props.theme.pxToRem(48)};
`;

const SmallImageCard = styled(Link)`
  display: block;
  width: 100%;
  border-radius: ${props => props.theme.pxToRem(4)};

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.primaryFadedPurple};
    color: ${props => props.theme.colors.primaryDarkPurple};
    outline: none;
    text-decoration: none;

    p,
    div {
      color: ${props => props.theme.colors.primaryDarkPurple};
    }

    .icon {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const NameLine = styled.p`
  margin: 0 0 ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledLinkBlock = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  padding-bottom: ${props => props.theme.pxToRem(42)};
`;

const StyledLinkTitle = styled(Link)`
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledLinkParagraph = styled.p`
  line-height: 1.78;
  color: ${props => props.theme.colors.neutralGrey8};
  padding-top: ${props => props.theme.pxToRem(8)};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: 10%;
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    margin: 0 ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} 0;
  }
`;

const StyledButtonBlock = styled.div<{ isDisplayText: boolean }>`
  display: ${props => (props.isDisplayText ? 'block' : 'inline-block')};
`;

const StyledExternalIcon = styled(ExternalIcon)`
  vertical-align: bottom;
  margin-bottom: ${props => props.theme.pxToRem(2)};
  padding-right: ${props => props.theme.pxToRem(4)};
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  object-fit: contain;
`;

const ListLayout = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(40)};
`;
const MainColumn = styled.div`
  .rich-text-external-link {
    ${ExternalLinkStyles}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${IC_OPEN_IN_NEW_PURPLE});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;
