import { handleActions, Action } from 'redux-actions';
import { AddressActionNames, addressDefault } from './constants';
import { Salesforce } from 'mxp-schemas';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [AddressActionNames.ADD_ADDRESS]: {
    next: (state: State.AddressState, action: Action<Salesforce.ContactPointAddress[]>): State.AddressState => ({
      ...state,
      clientAddresses: action.payload,
    }),
    throw: (
      state: State.AddressState,
      { payload: error }: { payload: CustomErrors.GraphQLError }
    ): State.AddressState => ({
      ...state,
    }),
  },
  [AddressActionNames.DELETE_ADDRESS]: (
    state: State.AddressState,
    action: Action<{ deleteAddress: boolean; deletedId: string; clientAddresses: any[] }>
  ): State.AddressState => {
    return {
      ...state,
      loading: false,
    };
  },
  [AddressActionNames.GET_COMPLETE_ADDRESS_VALIDATION]: (
    state: State.AddressState,
    action: Action<{
      smartystreetsValidation: State.SmartyAddressValidationState | null;
      isShippingAddressValid: boolean;
      corrected: boolean;
      autoCorrection: boolean;
      isShippingEmbargoed: boolean;
    }>
  ): State.AddressState => {
    const { smartystreetsValidation, isShippingAddressValid, isShippingEmbargoed } = action.payload;
    return {
      ...state,
      smartystreetsValidation: {
        ...(smartystreetsValidation as State.SmartyAddressValidationState),
        smartystreetsValidationFetched: true,
      },
      isProfileCacheAddressValid: isShippingAddressValid,
      isEmbargoed: isShippingEmbargoed,
    };
  },
  [AddressActionNames.SET_SECONDARY_ADDRESS_NEEDED]: (state: State.Checkout, action: any): State.Checkout => ({
    ...state,
    isSecondaryAddressNeeded: action.payload,
  }),
  [AddressActionNames.SET_PROFILE_CACHE_ADDRESS]: (
    state: State.AddressState,
    action: Action<Partial<Salesforce.ContactPointAddress>>
  ): State.AddressState => {
    return {
      ...state,
      loading: false,
      profileCacheAddress: {
        ...state.profileCacheAddress,
        ...action.payload,
      },
      isProfileCacheAddressValid: null,
      isSecondaryAddressNeeded:
        state.isSecondaryAddressNeeded && action.payload.addressLine2 !== undefined
          ? null
          : state.isSecondaryAddressNeeded,
    };
  },
  [AddressActionNames.LOADING]: (state: State.AddressState): State.AddressState => {
    return { ...state, loading: true };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export const addressReducer = handleActions(ACTION_HANDLERS, addressDefault);
