import React from 'react';
import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';
import { Grid } from 'semantic-ui-react';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';

/**
 * Resignation & Termination Modal
 * @category Resignation & Termination
 *
 */

export interface Props {
  open: boolean;
  membershipName: string;
  onClose: () => void;
  isResignation?: boolean;
}

export const SuccessResignationOrTerminationModal: React.FC<Props> = ({
  open,
  onClose,
  isResignation,
  membershipName,
}) => {
  const heading = (
    <StyledHeading>
      {isResignation ? (
        <>
          <StyledBoldText>{membershipName}</StyledBoldText> membership has been cancelled
        </>
      ) : (
        <>
          <StyledBoldText>{membershipName}</StyledBoldText> membership cancelled
        </>
      )}
    </StyledHeading>
  );
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      testId="success-modal"
      heading={heading}
      icon={<StyledCheckmark />}
      cancelNode={
        <StyledButton
          size={ButtonEnums.sizes.small}
          testId="return-to-membership-centers"
          variant={ButtonEnums.variants.primary}
          onClick={onClose}
        >
          Return to membership centers
        </StyledButton>
      }
    >
      <Grid stackable centered columns={3}>
        <StyledGridRow>A confirmation email has been sent.</StyledGridRow>
      </Grid>
    </StyledModal>
  );
};

const StyledButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(350)};
  }
`;

const StyledModal = styled(Modal)`
  &&&& {
    background: ${props => props.theme.colors.neutralGrey1};
    min-height: ${props => props.theme.pxToRem(264)};
    max-width: ${props => props.theme.pxToRem(590)};
    margin-top: ${props => props.theme.pxToRem(48)};
    > .header {
      h2 {
        min-height: ${props => props.theme.pxToRem(64)};
        max-width: ${props => props.theme.pxToRem(440)};
        padding: ${props => props.theme.pxToRem(10)};
        margin: auto;
        text-align: center;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }
  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding-bottom: ${props => props.theme.pxToRem(2)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-top: ${props => props.theme.pxToRem(10)};
    }
  }
`;

const StyledHeading = styled.div`
  line-height: 1.57;
  text-align: center;
  justify-content: center;
  font-size: ${props => props.theme.fontSizes.l};
`;

const StyledBoldText = styled.span`
  line-height: 1.57;
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const StyledCheckmark = styled(Checkmark)`
  &&& {
    path {
      fill: ${props => props.theme.colors.interfaceGreen};
    }
  }
`;
