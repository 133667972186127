import { createSelector } from 'reselect';

const rootSelector = createSelector(
  (state: State.Root) => state.savedItems,
  (savedItems: State.SavedItems) => savedItems
);

export const savedItemsSelector = createSelector(
  rootSelector,
  (savedItems: State.SavedItems): string[] | null => savedItems.contentIds
);

export const topSavedItemsSelector = createSelector(
  rootSelector,
  // tslint:disable-next-line:prefer-array-literal
  (savedItems: State.SavedItems): Partial<State.ContentItem>[] | null => savedItems.top
);

export const savedItemsResultsSelector = createSelector(
  rootSelector,
  (savedItems: State.SavedItems): State.ContentItem[] => savedItems.results
);

export const savedItemsLoadingSelector = createSelector(
  rootSelector,
  (savedItems: State.SavedItems): boolean => savedItems.loading
);

export const savedItemsResultsFetchedSelector = createSelector(
  rootSelector,
  (savedItems: State.SavedItems): boolean => savedItems.resultsFetched
);

export const savedItemsMetaSelector = createSelector(
  rootSelector,
  (savedItems: State.SavedItems): State.Meta => savedItems.meta
);

export const savedItemsAggregationSelector = createSelector(
  savedItemsMetaSelector,
  (meta: State.Meta): State.SearchAggs | null => meta.aggs as State.SearchAggs | null
);
