import React from 'react';
import styled from 'styled-components';
import { ButtonLink, Heading, ButtonVariants, Grid, ButtonEnums, AnchorMarkdownRenderer } from 'components/atoms';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful } from 'mxp-schemas';
import ReactMarkdown from 'react-markdown';
import {
  ExternalLinkStyles,
  ExternalLinkStylesDark,
  DefaultInlineLinkStyles,
  InlineLinkOnDarkBgStyles,
} from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}

export const BlockTable: React.FC<Props> = ({ headingType, modularBlock }) => {
  const block = modularBlock as Contentful.ModularContent.TableBlock;
  const isBgColorDark: boolean = isColorDark(block.bgColor);
  const blockCopy = block?.description && block?.description.split(/<advancedTableBlock >/);
  const tableClass = (props: any) => {
    return <table className="ui striped table unstackable">{props.children}</table>;
  };

  const renderCtaButton = (): React.ReactNode => {
    return (
      <StyledButtonWrapper>
        {block.ctaUrl && block.ctaText && (
          <StyledButtonLink
            size={ButtonEnums.sizes.medium}
            variant={isBgColorDark ? ButtonVariants.secondaryNegative : ButtonVariants.primary}
            bordered
            to={block.ctaUrl}
            testId="product-promo-block"
          >
            {block.ctaText}
          </StyledButtonLink>
        )}
      </StyledButtonWrapper>
    );
  };

  const addSemanticTbl = (html: string) => {
    return html.replace('<table class="content-page-table">', '<table class="ui striped table">');
  };

  const renderDescription = (item: any) => {
    if (item.indexOf('<subAdvancedTableBlock>') > -1) {
      let modifiedMarkdown = item.replace(/<br\s*\\?>/g, '\n');
      modifiedMarkdown = modifiedMarkdown.replace(/<subAdvancedTableBlock>/g, '');
      return modifiedMarkdown;
    }
    return item;
  };

  return (
    <MainColumn isBgColorDark={isBgColorDark}>
      <Grid>
        <Grid.Row columns={1} verticalAlign="middle" centered={true}>
          <Grid.Column textAlign={'center'}>
            <StyledHeaderDescription isBgColorDark={isBgColorDark}>
              {block.header && (
                <StyledHeading
                  as={headingType}
                  isBgColorDark={isBgColorDark}
                  dangerouslySetInnerHTML={{ __html: block.header }}
                  data-is-single-column={true}
                  tabIndex={0}
                />
              )}

              {block.description &&
                blockCopy &&
                blockCopy?.map((item: string) =>
                  item.indexOf('<subAdvancedTableBlock>') > -1 ? (
                    <>
                      <ReactMarkdownStyled
                        isBgColorDark={isBgColorDark}
                        bgColor={block.bgColor}
                        children={renderDescription(item)}
                        renderers={{
                          table: tableClass,
                          link: AnchorMarkdownRenderer,
                        }}
                      />
                      <br />{' '}
                    </>
                  ) : (
                    <>
                      <StyledParagraph
                        isBgColorDark={isBgColorDark}
                        dangerouslySetInnerHTML={{ __html: addSemanticTbl(item) }}
                        tabIndex={0}
                      />
                      <br />
                    </>
                  )
                )}

              {renderCtaButton()}
            </StyledHeaderDescription>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </MainColumn>
  );
};

const MainColumn = styled.div<{
  isBgColorDark: boolean;
}>`
  .rich-text-external-link {
    ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${props => (props.isBgColorDark ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;
const StyledButtonLink = styled(ButtonLink)`
  &&&&& {
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(20)};
  }
`;

const StyledHeaderDescription = styled.div<{ isBgColorDark: boolean }>`
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
`;

const StyledHeading = styled(Heading)<{ 'data-is-single-column': boolean; isBgColorDark: boolean }>`
  margin-bottom: ${props => (props['data-is-single-column'] ? props.theme.pxToRem(16) : props.theme.pxToRem(24))};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.l};
    line-height: 1.33;
  }

  p {
    line-height: 1.38;
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  &&&& {
    text-align: center;
    border-style: solid;
    border-width: 0 0 ${props => props.theme.pxToRem(1)} 0;
    border-color: ${props => props.theme.colors.neutralGrey3};
    margin: 0;

    table {
      border: 0;
      text-align: left;
      table-layout: fixed;
      overflow: hidden;

      thead tr {
        background-color: ${props => props.theme.colors.neutralGrey1};
      }

      tr {
        :nth-child(2n) {
          background-color: rgba(247, 247, 247, 0.3);
        }

        ${props => props.theme.mediaQueries.mobileOnly} {
          padding-top: ${props => props.theme.pxToRem(40)};
          padding-bottom: ${props => props.theme.pxToRem(24)};
          box-shadow: 0 ${props => props.theme.pxToRem(-1)} 0 0 ${props => props.theme.colors.neutralGrey3} inset !important;
        }
      }

      tr th {
        padding: ${props => props.theme.pxToRem(11)} ${props => props.theme.pxToRem(20)};
        color: ${props => props.theme.colors.neutralGrey8};
        font-size: ${props => props.theme.fontSizes.s};
        font-weight: ${props => props.theme.fontWeights.medium};
        line-height: 1.57;
        min-width: 190px;

        :nth-child(2) {
          text-align: left;
        }

        ${props => props.theme.mediaQueries.mobileOnly} {
          padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;
        }
      }

      tr td {
        padding: ${props => props.theme.pxToRem(21)} ${props => props.theme.pxToRem(20)};
        border-top-color: ${props => props.theme.colors.neutralGrey3};
        color: ${props => props.theme.colors.neutralGrey8};
        font-size: ${props => props.theme.fontSizes.s};
        font-weight: ${props => props.theme.fontWeights.light};
        line-height: 1.5;
        min-width: 190px;

        :nth-child(2) {
          text-align: left;
        }

        ${props => props.theme.mediaQueries.mobileOnly} {
          padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;

          :first-child {
            font-weight: ${props => props.theme.fontWeights.regular};
          }

          :nth-child(2) {
            text-align: left;
          }
        }
      }

      td {
        word-wrap: break-word;
        overflow: hidden;
      }

      ${props => props.theme.mediaQueries.mobileOnly} {
        border-width: 0;
        margin-top: 0;
      }
    }

    a {
      ${DefaultInlineLinkStyles};

      ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
    }
  }
`;

const StyledButtonWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(32)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(52)};
    text-align: center;
  }
`;

const ReactMarkdownStyled = styled(ReactMarkdown as any)<{ isBgColorDark: boolean; bgColor: string }>`
  &&&& {
    text-align: center;
    border-style: solid;
    border-width: 0 0 ${props => props.theme.pxToRem(1)} 0;
    border-color: ${props => props.theme.colors.neutralGrey3};
    margin: 0;
    background: ${props => props.bgColor};
    overflow-x: scroll;

    a {
      color: ${props => props.theme.colors.primaryPurple};
      font-weight: ${props => props.theme.fontWeights.medium};

    .rich-text-internal-link {
      ${DefaultInlineLinkStyles};
    }

    table {
      border: 0;
      text-align: left;
      table-layout: fixed;
      overflow: hidden;

      thead tr {
        background-color: ${props => props.theme.colors.neutralGrey1};
      }

      tr {
        :nth-child(2n) {
          background-color: rgba(247, 247, 247, 0.3);
        }

        ${props => props.theme.mediaQueries.mobileOnly} {
          padding-top: ${props => props.theme.pxToRem(40)};
          padding-bottom: ${props => props.theme.pxToRem(24)};
          box-shadow: 0 ${props => props.theme.pxToRem(-1)} 0 0 ${props =>
  props.theme.colors.neutralGrey3} inset !important;
        }
      }

      tr th {
        padding: ${props => props.theme.pxToRem(11)} ${props => props.theme.pxToRem(20)};
        color: ${props => props.theme.colors.neutralGrey8};
        font-size: ${props => props.theme.fontSizes.s};
        font-weight: ${props => props.theme.fontWeights.medium};
        line-height: 1.57;
        min-width: 190px;

        :nth-child(2) {
          text-align: left;
        }

        ${props => props.theme.mediaQueries.mobileOnly} {
          padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;
          font-size: 10px;
        }
      }

      tr td {
        padding: ${props => props.theme.pxToRem(21)} ${props => props.theme.pxToRem(20)};
        border-top-color: ${props => props.theme.colors.neutralGrey3};
        color: ${props => props.theme.colors.neutralGrey8};
        font-size: ${props => props.theme.fontSizes.s};
        font-weight: ${props => props.theme.fontWeights.light};
        line-height: 1.5;
        min-width: 190px;

        :nth-child(2) {
          text-align: left;
        }

        ${props => props.theme.mediaQueries.mobileOnly} {
          padding: ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(16)} !important;
          font-size: 12px;
          :first-child {
            font-weight: ${props => props.theme.fontWeights.regular};
          }

          :nth-child(2) {
            text-align: left;
          }
        }
      }

      td {
        word-wrap: break-word;
        overflow: hidden;
      }

      ${props => props.theme.mediaQueries.mobileOnly} {
        border-width: 0;
        margin-top: 0;
      }
    }
  }
`;
