import { isServer } from 'utils';
import { isProduction } from 'utils/env';

export const get = (name: string): string | boolean | number | null => {
  if (isServer) return null;
  const cookie = document.cookie.split(';').reduce((prev: any, pair: string) => {
    const [key, value] = pair.split('=');
    if (!key || !value) {
      return prev;
    }
    prev[key.trim()] = value.trim();
    return prev;
  }, {});

  return cookie[name];
};

export const set = (name: string, value: string | boolean | number): void => {
  if (isServer) {
    throw new Error('Are you trying to use cookies on server side?');
  }
  const days = 60;
  const date = new Date();
  const expires = date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const secure: string = isProduction ? 'Secure;' : '';

  document.cookie = `${name}=${JSON.stringify(
    value
  )}; Expires=${expires.toString()}; ${secure} SameSite=Strict; Path=/;`;
};

export const del = (name: string): void => {
  if (isServer) {
    throw new Error('Are you trying to use cookies on server side?');
  }
  const secure: string = isProduction ? 'Secure;' : '';
  document.cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; ${secure} SameSite=Strict; Path=/;`;
};
