import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { ContentTopicsFollow, FollowTopicIF, FollowSubtopicIF } from 'components/organisms/ContentTopicsFollow';
import { userSubtopicsPrefSelector, userTopicsPrefSelector, userPrefSelector } from 'modules/user/selectors';
import { toggleTopicPrefAndUpdateGoldenRecord, toggleSubtopicPrefAndUpdateGoldenRecord } from 'modules/user/actions';
import { contentPageItemSelector } from 'modules/content/selectors';
import { topicsHashSelector } from 'modules/topics/selectors';
import { emptyArray } from 'utils';

const mapActionCreators = (dispatch: Dispatch) => ({
  toggleTopicPref(topicId: string, isOnEvent: boolean): void {
    dispatch(toggleTopicPrefAndUpdateGoldenRecord(topicId, isOnEvent));
  },

  toggleSubtopicPref(topicId: string, subtopicId: string, isOnEvent: boolean): void {
    dispatch(toggleSubtopicPrefAndUpdateGoldenRecord(topicId, subtopicId, isOnEvent));
  },
});

const mapStateToProps = (initState: State.Root) => {
  const contentTopicsFollowSelector = createSelector(
    [topicsHashSelector, contentPageItemSelector],
    (topicsHash: State.TopicsHash | null, contentPageItem: State.ContentItem | null) => {
      if (!topicsHash || !contentPageItem || !contentPageItem.topics) return emptyArray;

      return contentPageItem.topics.reduce((acc: any[], { id }: { id: string }) => {
        const topic: State.Topic = topicsHash[id];
        if (topic && topic.name) {
          acc.push({ id, name: topic.name, sort: topic.sort, slug: topic.slug });
        }
        return acc;
      }, []);
    }
  );

  const contentSubtopicsFollowSelector = createSelector(
    [topicsHashSelector, contentPageItemSelector],
    (topicsHash: State.TopicsHash | null, contentPageItem: State.ContentItem | null) => {
      if (!topicsHash || !contentPageItem || !contentPageItem.subtopics) return emptyArray;

      return contentPageItem.subtopics.reduce((acc: any[], item: any) => {
        const topic: State.Topic = topicsHash[item.topicId];
        if (!topic || !topic.subtopics) return acc;
        const subtopic: State.Subtopic = topic.subtopics[item.id];
        if (!subtopic || !subtopic.name) return acc;
        acc.push({
          id: item.id,
          topicId: item.topicId,
          name: subtopic.name,
          slug: subtopic.slug,
          topicSlug: topic.slug,
        });
        return acc;
      }, []);
    }
  );

  const topics: FollowTopicIF[] = contentTopicsFollowSelector(initState);
  const subtopics: FollowSubtopicIF[] = contentSubtopicsFollowSelector(initState);

  return (state: State.Root) => ({
    userTopicsPref: userTopicsPrefSelector(state),
    userSubtopicsPref: userSubtopicsPrefSelector(state),
    userPref: userPrefSelector(state),
    topics,
    subtopics,
  });
};

export const ContentTopicsFollowContainer = connect(mapStateToProps, mapActionCreators)(ContentTopicsFollow);
