import React from 'react';
import styled from 'styled-components';
import { ButtonEnums, ButtonLink } from 'components/atoms';
import { Product } from 'mxp-schemas';
import { productTypeToSlug } from 'utils';

interface Props {
  productTypeLabel: string;
  linkedProduct: Product.ProductItem;
}

export const RelatedProductBlock: React.FC<Props> = ({ productTypeLabel, linkedProduct }) => (
  <StyledDiv>
    <div>
      <StyledTitle>
        This {productTypeLabel} is also available as a <Bold>{linkedProduct.productTypeLabel}</Bold>
      </StyledTitle>
      <StyledText>
        CPE credits will be awarded once per title. For example, if you buy a webcast and a self-study course with the
        same name, only the CPE you finish first will result in credits earned.
      </StyledText>
    </div>
    <StyledButtonLink
      testId="related-product"
      variant={ButtonEnums.variants.primary}
      iconPosition={ButtonEnums.iconPosition.left}
      to={productLink(linkedProduct.productType as Product.ProductType, linkedProduct.slug as string, linkedProduct.subscriptionProductType?.key as string)}
      bordered
    >
      View {linkedProduct.productTypeLabel}
    </StyledButtonLink>
  </StyledDiv >
);

const productLink = (type: Product.ProductType, slug: string, subscriptionType?: string) => {
  return subscriptionType ? `../${subscriptionType}/${slug}` : `../${productTypeToSlug(type)}/${slug}`
};

const StyledDiv = styled.div`
  display: flex;
  padding: ${props => props.theme.pxToRem(20)};
  font-size: ${props => props.theme.fontSizes.s};
  border-top: solid 1px ${props => props.theme.colors.neutralGrey2};
  align-items: center;
  flex-direction: column;
  align-items: flex-start;  
  ${props => props.theme.mediaQueries.tabletMin} {
    flex-direction: row;
  };
`;

const StyledTitle = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-bottom: ${props => props.theme.pxToRem(12)};
`;

const StyledText = styled.p`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin: 0 0 ${props => props.theme.pxToRem(20)};
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    background-color: ${props => props.theme.colors.neutralWhite};
    color: ${props => props.theme.colors.primaryPurple};
    width: auto;
    min-width: ${props => props.theme.pxToRem(180)};
    height: ${props => props.theme.pxToRem(16)};
    border-radius: ${props => props.theme.pxToRem(4)};
    font-size: ${props => props.theme.fontSizes.s};
    padding: 0 ${props => props.theme.pxToRem(16)};
    margin-top ${props => props.theme.pxToRem(20)};
    margin-left: 0;
    ${props => props.theme.mediaQueries.tabletMin} {
      margin-left ${props => props.theme.pxToRem(40)};
    };
  }
`;

const Bold = styled.span`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.l};
  line-height: 1.78;
`;
