import React from 'react';
import styled from 'styled-components';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful } from 'mxp-schemas';
import { EditorialHeroCard } from 'components/molecules/EditorialHeroCard/EditorialHeroCard';
import { Grid, ButtonEnums, ButtonVariants, ButtonLink, Heading } from 'components/atoms';
import { LANDING_PAGES_CONSTS } from '../../../constants';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}

export const BlockEditorialHero: React.FC<Props> = ({ modularBlock, headingType }) => {
  const block = modularBlock as Contentful.ModularContent.EditorialHeroBlock;

  const products = block?.products || [];
  const contents = block?.contentReference || [];

  const pageContents = contents.concat(products).splice(0, LANDING_PAGES_CONSTS.EDITORIAL_HERO_AMOUNT);
  const isBgColorDark: boolean = isColorDark(block.bgColor);

  const renderEditorialCtaButton = (): React.ReactNode => {
    if (!block.ctaUrl || !block.ctaText) return;
    return (
      <StyledButtonLink
        size={ButtonEnums.sizes.medium}
        variant={isBgColorDark ? ButtonEnums.variants.secondaryNegative : ButtonVariants.secondary}
        bordered
        to={block.ctaUrl}
        testId="product-editorial-block"
      >
        {block.ctaText}
      </StyledButtonLink>
    );
  };

  const renderEditorialContent = (): React.ReactNode => {
    return (
      <>
        <StyledHeaderContainer isBgColorDark={isBgColorDark}>
          {block.header && (
            <StyledHeading
              as={headingType}
              dangerouslySetInnerHTML={{ __html: block.header }}
              tabIndex={0}
              isBgColorDark={isBgColorDark}
            />
          )}
          {block.description && (
            <StyledParagraph
              dangerouslySetInnerHTML={{ __html: block.description }}
              tabIndex={0}
              isBgColorDark={isBgColorDark}
            />
          )}
        </StyledHeaderContainer>
        <Grid centered>
          <Grid.Row columns={3}>
            {pageContents?.map((product: any, index: number) => {
              const isDoubleWidth = Boolean(pageContents?.length === 3 && index === 0);
              return (
                <StyledColumnCard key={product.id} computer={isDoubleWidth ? 8 : 5}>
                  <EditorialHeroCard
                    contentCardItem={product}
                    isDoubleWidthCard={isDoubleWidth}
                    isEditorialHeroCard
                    testid={`editorial-product-${product.id}`}
                  />
                </StyledColumnCard>
              );
            })}
          </Grid.Row>
          {renderEditorialCtaButton()}
        </Grid>
      </>
    );
  };

  return (
    <>
      <React.Fragment>{renderEditorialContent()}</React.Fragment>
    </>
  );
};

const StyledHeaderContainer = styled.div<{ isBgColorDark?: boolean }>`
  margin-top: ${props => props.theme.pxToRem(20)};
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
`;

const StyledColumnCard = styled(Grid.Column)`
  &&&&& {
    margin: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(30)} 0;

    ${props => props.theme.mediaQueries.desktopOnly} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)<{ isBgColorDark?: boolean }>`
  &&&&& {
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(20)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: ${props => props.theme.pxToRem(15)} 0 ${props => props.theme.pxToRem(55)} 0;
    }
  }

  &:hover,
  &:focus {
    &&&&& {
      background-color: ${props => props.theme.colors.neutralWhite};
      color: ${props => props.theme.colors.primaryLightPurple};
    }

    ${({ variant, bordered, theme }) =>
      variant === ButtonVariants.secondary &&
      ` 
    &&&&& {
      ${bordered && `border: ${theme.pxToRem(1)} solid ${theme.colors.primaryLightPurple}`}
    }
    `}
  }
`;

const StyledHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  text-align: center;
`;
