import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AuthenticationModal } from 'components/organisms';
import { pageToShowSelector, isAuthenticationModalVisibleSelector } from 'modules/layouts/selectors';
import { closeAuthenticationModal } from 'modules/layouts';
import { clearUserError } from 'modules/user/actions';

const mapActionCreators = (dispatch: Dispatch) => ({
  closeAuthenticationModal(): void {
    dispatch(closeAuthenticationModal());
  },
  clearUserError(): void {
    dispatch(clearUserError());
  },
});

const mapStateToProps = (state: State.Root) => {
  return {
    isAuthenticationModalVisible: isAuthenticationModalVisibleSelector(state),
    pageToShow: pageToShowSelector(state),
  };
};

export const AuthenticationModalContainer = connect(mapStateToProps, mapActionCreators)(AuthenticationModal);
