import React from 'react';
import { Label, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

interface TagProps {
  name: string;
  id: string;
  onRemove: () => void;
  testId: string;
}

const StyledLabel = styled(Label)`
  && {
    color: ${props => props.theme.colors.primaryLightPurple};
    background-color: ${props => props.theme.colors.neutralGrey3};
  }
`;

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.neutralGrey2};
  background-color: ${props => props.theme.colors.primaryLightPurple};
  transform: scale(0.65);
  &&&& {
    opacity: 1;
  }
`;

export const Tag: React.SFC<TagProps> = React.memo(({ name, id, onRemove, testId }) => {
  return (
    <StyledLabel data-testid={testId}>
      {name}
      <StyledIcon name="delete" circular data-id={id} data-testid={`remove-tag-${testId}`} onClick={onRemove} />
    </StyledLabel>
  );
});
