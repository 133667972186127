import React, { useState } from 'react';
import styled from 'styled-components';
import { List, Sidebar } from 'semantic-ui-react';
import { SlideInModal } from 'components/templates';
import { SubtopicListItem } from 'components/molecules/SubtopicListItem/SubtopicListItem';
import { Button, ButtonEnums } from 'components/atoms';
import { emptyObject } from 'utils';

interface Props {
  isOpen: boolean;
  selectedTopicName: string | null;
  subtopicsHash: State.SubtopicsHash | null;
  userSubtopicsPref: string[];
  showMoreMagicNumber?: number;
  children?: any;
  userHasChangedPref: boolean | null;
  userHasPrefFromSelectedTopic: boolean | null;

  closeModal(): void;
  toggleSubtopicPref(id: string): void;
  selectAllSubtopics(): void;
  clearAllSubtopics(): void;
  openModal(): void;
  resetSubtopics(): void;
}

interface SidebarPushableProps {
  isOpen: boolean;
}

export const SubtopicsSlideInModal: React.SFC<Props> = React.memo(props => {
  const {
    isOpen,
    selectedTopicName,
    subtopicsHash,
    userSubtopicsPref,
    closeModal,
    toggleSubtopicPref,
    selectAllSubtopics,
    clearAllSubtopics,
    resetSubtopics,
    openModal,
    showMoreMagicNumber = 5,
    children,
    userHasChangedPref,
    userHasPrefFromSelectedTopic,
  } = props;
  const subTopics: State.Subtopic[] = Object.values(subtopicsHash || emptyObject);
  const [showMore, setShowMore] = useState(false);

  const handleSelectAllClick = () => {
    selectAllSubtopics();
    setShowMore(true);
  };

  const handleOverlayClick = () => {
    if (isOpen) {
      return null;
    }
    openModal();
  };

  const hideMore = () => setShowMore(false);

  const handleCloseModal = (event?: React.SyntheticEvent) => {
    if (event) {
      return false;
    }
    hideMore();
    closeModal();
  };

  const handleBackClick = () => {
    resetSubtopics();
    handleCloseModal();
  };

  return (
    <StyledSidebarPushable isOpen={isOpen}>
      <SlideInModal isOpen={isOpen} onClose={handleCloseModal}>
        <StyledContainer>
          <StyledTitle data-testid="subtopics-title">Let's get more specific</StyledTitle>
          <StyledText data-testid="subtopics-text">
            Which <span>{selectedTopicName}</span> subtopics are you interested in?
          </StyledText>
          <StyledButtonContainer>
            <Button
              variant={ButtonEnums.variants.link}
              size={ButtonEnums.sizes.small}
              iconName="check circle"
              iconPosition={ButtonEnums.iconPosition.left}
              testId="subtopics-select-all"
              onClick={handleSelectAllClick}
            >
              Select All
            </Button>
            <StyledSeparator />
            <Button
              variant={ButtonEnums.variants.link}
              size={ButtonEnums.sizes.small}
              iconName="times circle"
              iconPosition={ButtonEnums.iconPosition.left}
              testId="subtopics-clear-all"
              onClick={clearAllSubtopics}
              disabled={!userHasPrefFromSelectedTopic}
            >
              Clear All
            </Button>
          </StyledButtonContainer>
          <List data-testid="subtopics-list">
            {subTopics.map((subtopic: State.Subtopic, index: number) => {
              if (index > showMoreMagicNumber && !showMore) return null;
              return (
                <SubtopicListItem
                  key={subtopic.id}
                  selected={userSubtopicsPref.includes(subtopic.id)}
                  {...subtopic}
                  toggleSubtopic={toggleSubtopicPref}
                />
              );
            })}
          </List>
          {subTopics && subTopics.length > showMoreMagicNumber + 1 && (
            <StyledShowMoreContainer>
              <Button
                variant={ButtonEnums.variants.link}
                size={ButtonEnums.sizes.small}
                iconName={showMore ? 'minus' : 'plus'}
                iconPosition={ButtonEnums.iconPosition.left}
                testId="subtopics-show-more"
                onClick={() => setShowMore(!showMore)} // tslint:disable-line:jsx-no-lambda
              >
                {showMore ? 'Show me less' : 'Show me more'}
              </Button>
            </StyledShowMoreContainer>
          )}
          <StyledFuncContainer>
            <StyledSaveButton
              testId="subtopics-save-changes"
              onClick={closeModal}
              variant={ButtonEnums.variants.primary}
              bordered
              disabled={!userHasChangedPref}
            >
              Save Changes
            </StyledSaveButton>
            <Button variant={ButtonEnums.variants.link} testId="subtopics-back" onClick={handleBackClick}>
              Go back
            </Button>
          </StyledFuncContainer>
        </StyledContainer>
      </SlideInModal>
      <StyledSidebarPusher dimmed={isOpen} isOpen={isOpen} onClick={handleOverlayClick}>
        {children}
      </StyledSidebarPusher>
    </StyledSidebarPushable>
  );
});

const StyledSidebarPushable = styled(Sidebar.Pushable)<SidebarPushableProps>`
  &&& {
    z-index: ${props => (props.isOpen ? 2 : 0)};
    height: ${props => (props.isOpen ? '100vh' : 'auto')};
    ${({ isOpen }: SidebarPushableProps) => isOpen && 'overflow: hidden'}
    transform: none;
    -webkit-overflow-scrolling: touch;

    & > .ui.sidebar {
      position: fixed;
    }
  }
`;

const StyledSidebarPusher = styled(Sidebar.Pusher)`
  &&&::after {
    background: linear-gradient(to right, #6a2d87, #a93d69, #6a2d87);
  }
  &&&.dimmed::after {
    opacity: 0.9 !important;
  }
`;

const StyledContainer = styled.div`
  overflow-y: auto;
  padding: 0 10px;
  max-width: 496px;
  margin: 0 auto;

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: 100%;
  }
`;

const StyledTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.33;
  text-align: center;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledText = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.33;
  text-align: center;
  color: ${props => props.theme.colors.neutralGrey8};
  padding-top: 10px;

  & span {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px 0 30px 0;
`;

const StyledSaveButton = styled(Button)`
  &&& {
    width: 100%;
    max-width: 265px;
  }
`;

const StyledSeparator = styled.div`
  margin: 0 20px;
  width: 1px;
  height: 28px;
  border-left: 1px solid ${props => props.theme.colors.neutralGrey3};
`;

const StyledShowMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0 20px 0;
  overflow: hidden;

  .ui.button {
    background-color: ${props => props.theme.colors.neutralWhite};
    padding-left: 2px;
    padding-right: 0;
  }

  &::after {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    margin-left: 10px;
    margin-right: -100%;
    border-top: 1px solid ${props => props.theme.colors.neutralGrey3};
  }
`;

const StyledFuncContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${props => props.theme.pxToRem(15)};
`;
