import React from 'react';
import { Link, LinkEnums } from 'components/atoms';
import { ParagraphTitle, TitleSizes } from './ProductParagraphs';
import styled from 'styled-components';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { Product } from 'mxp-schemas';

interface Props {
  creditsInfo: Product.VariantCreditInfo[];
  nasbaField: string[];
  level: string;
  prerequisites: string;
  access: Product.VariantCreditInfo[];
  duration: string;
  yellowBookHours: string;
  contentRef?: () => void;
  accessRef?: () => void;
  hasBadge?: boolean;
  isWebcast?: boolean;
}

interface RowContent {
  head: string;
  content: string | Product.VariantCreditInfo[] | null;
  reference?: () => void;
}

export const CreditInfo: React.FC<Props> = React.memo(
  ({
    creditsInfo,
    nasbaField,
    level,
    prerequisites,
    access,
    duration,
    yellowBookHours,
    contentRef,
    accessRef,
    hasBadge,
    isWebcast,
  }: Props) => {
    const rowsData: RowContent[] = React.useMemo(
      () =>
        [
          { head: 'CPE Credits', content: transformCPECredits(creditsInfo, isWebcast) },
          {
            head: 'NASBA Field of Study',
            content: nasbaField && nasbaField.join(', '),
          },
          {
            head: 'Level',
            content: level,
          },
          {
            head: 'Prerequisites',
            content: prerequisites,
          },
          {
            head: 'Access',
            content: access,
            reference: accessRef,
          },
          {
            head: 'Digital Badge',
            content:
              'After completing the course, you will receive a digital badge that can be easily shared with your professional network',
          },
          {
            head: 'Duration',
            content: duration,
          },
          {
            head: 'Yellow Book Hours',
            content: yellowBookHours,
          },
        ].filter(rd => {
          if (!hasBadge) {
            return rd.head !== 'Digital Badge' && rd.content?.length;
          }

          return rd.content?.length;
        }),
      [creditsInfo, nasbaField, level, prerequisites, access, duration, yellowBookHours, accessRef, hasBadge, isWebcast]
    );

    return rowsData?.length ? (
      <Wrap data-testid="credit-info" ref={contentRef}>
        <StyledParagraphTitle titleSize={TitleSizes.LARGE}>
          {creditsInfo.length ? 'Credit Info' : 'More Details'}
        </StyledParagraphTitle>
        <StyledListContainer>
          {rowsData.map((rd, i) => {
            return Array.isArray(rd.content) ? (
              rd.content.map((cInfo, idx) => (
                <StyledRow key={`${rd.head}triple${idx}`} hasBg={!!(i % 2)} ref={idx === 0 ? rd.reference : undefined}>
                  <StyledCellMain isRepeating={idx !== 0}>{idx === 0 && rd.head}</StyledCellMain>
                  {cInfo.format !== '' && !isWebcast && <StyledCellSecondary>{cInfo.format}</StyledCellSecondary>}
                  <StyledCellSecondary isDouble={cInfo.format === '' || (isWebcast && cInfo.format !== '')}>
                    {cInfo.isCreditRange ? `Up to ${cInfo.info}` : cInfo.info}
                  </StyledCellSecondary>
                </StyledRow>
              ))
            ) : (
              <StyledRow key={`${rd.head}double${i}`} hasBg={!!(i % 2)}>
                <StyledCellMain> {rd.head}</StyledCellMain>
                <StyledCellSecondary isDouble>{rd.content}</StyledCellSecondary>
              </StyledRow>
            );
          })}
          {Boolean(creditsInfo.length) && (
            <StyledRow hasBg={false}>
              <StyledCellSecondary isNASBA>
                For more information, please refer to{' '}
                <Link type={LinkEnums.type.inlineLink} to={getPath(Routes.NASBA_SPONSORSHIP)}>
                  CPE requirements and NASBA sponsorship information
                </Link>
              </StyledCellSecondary>
            </StyledRow>
          )}
        </StyledListContainer>
      </Wrap>
    ) : null;
  }
);

export default CreditInfo;

const Wrap = styled.div`
  padding-top: ${props => props.theme.pxToRem(18)};
  margin-top: ${props => props.theme.pxToRem(20)};
`;

const StyledListContainer = styled.div`
  margin: ${props => props.theme.pxToRem(16)} 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow-y: auto;
  }
`;

const StyledRow = styled.div<{ hasBg: boolean }>`
  display: flex;
  background-color: ${props => (props.hasBg ? 'rgba(247, 247, 247, 0.3)' : props.theme.colors.neutralWhite)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-width: ${props => props.theme.pxToRem(450)};
  }
`;

const StyledCellMain = styled.div<{ isRepeating?: boolean }>`
  display: flex;
  flex: 1;
  height: ${props => props.theme.pxToRem(64)};
  padding: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.5;
  border-top: ${props => (props.isRepeating ? 'none' : '1px solid rgba(34,36,38,.1)')};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-weight: ${props => props.theme.fontWeights.regular};
    min-width: ${props => props.theme.pxToRem(200)};
  }
`;

const StyledCellSecondary = styled.div<{ isDouble?: boolean; isNASBA?: boolean }>`
  ${props =>
    !props.isNASBA
      ? `
    display: flex;
    flex: ${props.isDouble ? '2 1 12%' : '1'};
  `
      : `display: block;`}
  
  padding: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  border-top: 1px solid rgba(34, 36, 38, 0.1);

  ${props => props.theme.mediaQueries.mobileOnly} {
    min-width: ${props => (props.isDouble ? props.theme.pxToRem(250) : props.theme.pxToRem(125))};
  }
`;

const StyledParagraphTitle = styled(ParagraphTitle)`
  line-height: 1.33;
`;

const transformCPECredits = (credits: Product.VariantCreditInfo[], isWebcast: boolean | undefined) => {
  if (!credits?.length || (credits?.[0]?.format && !isWebcast)) return credits;

  const cpeCredits: number[] = credits.map(item => Number(item.info));

  const minCpeCredits = Math.min(...cpeCredits);
  const maxCpeCredits = Math.max(...cpeCredits);
  if (minCpeCredits !== maxCpeCredits) {
    return `${minCpeCredits} - ${maxCpeCredits}`;
  }
  return `${minCpeCredits}`;
};
