import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { MiniUserWidgetContainer } from 'containers/UserWidgetContainer';
import { AicpaLogo } from 'components/atoms';

export const HeaderPreferences = React.memo(() => {
  return (
    <StyledContainer fluid data-testid="preferences-header-row">
      <Grid columns={2}>
        <Grid.Column>
          <Segment basic textAlign="left">
            <AicpaLogo overrideAnalytics={true} />
          </Segment>
        </Grid.Column>
        <StyledColumn>
          <StyledSegment basic textAlign="right">
            <MiniUserWidgetContainer showLogoutLink />
          </StyledSegment>
        </StyledColumn>
      </Grid>
    </StyledContainer>
  );
});

const StyledSegment = styled(Segment)`
  &&&& {
    padding: 0;
    align-self: center;
  }
`;

const StyledContainer = styled(Container)`
  &&&& {
    background-color: ${props => props.theme.colors.neutralGrey1};
  }
`;

const StyledColumn = styled(Grid.Column)`
  &&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      display: flex;
    }
  }
`;
