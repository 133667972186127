import React from 'react';
import { Modal, Button, ButtonEnums, Link } from 'components/atoms/';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

interface Props {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  criteria: any;
  to: string;
  applyLink?: string;
}

export const EligibilityCriteriaModal: React.FC<Props> = ({ open, onClose, onClick, criteria, to, applyLink }) => (
  <div style={{}}>
    <Modal
      open={open}
      onClose={onClose}
      testId="eligibility-criteria-modal"
      oneOption
      blur={true}
      contentBackground="rgb(239,231,237)"
      contentFullWidth={true}
      background="rgb(239,231,237)"
    >
      <MarkDownWrapper>
        <ReactMarkdown children={criteria as any} />
      </MarkDownWrapper>
      {applyLink ? (
        <Link to={{ pathname: `${applyLink}` }} overrideAnalytics={true} target="_blank">
          <StyledButtonLink
            to={applyLink}
            testId={`select-membership-link`}
            size={ButtonEnums.sizes.medium}
            variant={ButtonEnums.variants.primary}
          >
            Join Now
          </StyledButtonLink>
        </Link>
      ) : (
        <StyledButtonLink
          size={ButtonEnums.sizes.medium}
          variant={ButtonEnums.variants.primary}
          testId="yes-iam-eligible-btn"
          onClick={onClick}
        >
          Yes, I'm eligible
        </StyledButtonLink>
      )}
    </Modal>
  </div>
);

const StyledButtonLink = styled(Button)`
  &&&&& {
    display: block;
    width: ${props => `${props.theme.pxToRem(300)}`};
    margin: auto;
    font-size: ${props => `${props.theme.pxToRem(16)}`};
    margin-bottom: ${props => `${props.theme.pxToRem(10)}`};
  }
`;

const MarkDownWrapper = styled.div`
  &&&&& {
    margin-bottom: ${props => `${props.theme.pxToRem(20)}`};
  }
`;
