import React from 'react';
import styled from 'styled-components';
import { IconButton } from 'components/atoms';
import { ReactComponent as IconFacebook } from 'resources/images/ic-social-facebook.svg';
import { ReactComponent as IconTwitter } from 'resources/images/ic-social-twitter.svg';
import { ReactComponent as IconLinkedIn } from 'resources/images/ic-social-linkedin.svg';
import { ReactComponent as IconInstagram } from 'resources/images/ic-social-instagram.svg';
import { SocialIconType } from '../../../constants';

interface SocialIconProps {
  url: string;
  icon: SocialIconType;
  onClickHandler: (url: string) => void;
}

export const SocialIcon: React.FC<SocialIconProps> = ({ url, icon, onClickHandler }) => {
  const handleClick = () => onClickHandler(url);

  return (
    <StyledIconContainer>
      <StyledIconButton onClick={handleClick}>{renderIcon(icon)}</StyledIconButton>
    </StyledIconContainer>
  );
};

const renderIcon = (icon: SocialIconType): React.ReactNode => {
  switch (icon) {
    case SocialIconType.FACEBOOK:
      return <IconFacebook />;
    case SocialIconType.TWITTER:
      return <IconTwitter />;
    case SocialIconType.LINKEDIN:
      return <IconLinkedIn />;
    case SocialIconType.INSTAGRAM:
    default:
      return <IconInstagram />;
  }
};

const StyledIconButton = styled(IconButton)`
  padding: 0;
  > svg {
    width: ${props => props.theme.pxToRem(24)};
    height: ${props => props.theme.pxToRem(24)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => props.theme.pxToRem(32)};
      height: ${props => props.theme.pxToRem(32)};
    }
  }
`;

const StyledIconContainer = styled.div`
  margin-left: auto;
  display: inline;
  padding: 0 ${props => props.theme.pxToRem(20)} 0 0;
  && :focus {
    outline: none;
  }
`;
