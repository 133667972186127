import { skillsSlugIdMapSelector, subskillsSlugIdMapSelector } from './selectors';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Helpers
// ------------------------------------

/*
 * Convert skills filter slugs to skill ids
 */
export const convertSkillsSlugsToIds = (skills: string | string[], state: State.Root): string[] | undefined => {
  if (!skills) return;
  const skillsSlugs: string[] = Array.isArray(skills) ? skills : [skills];
  // Call skillsSlugIdMapSelector only if skillsSlugs exist
  const skillsSlugIdMap: { [key: string]: string } = skillsSlugIdMapSelector(state);
  // Get skills ids array from slugs if exist
  return skillsSlugs.map(slug => skillsSlugIdMap[slug]).filter(Boolean);
};

/*
 * Convert subskill filter slugs to subskill ids
 */
export const convertSubskillsSlugsToIds = (subskills: string | string[], state: State.Root): string[] | undefined => {
  if (!subskills) return;
  const subskillsSlugs: string[] = Array.isArray(subskills) ? subskills : [subskills];
  // Call subsubskillsSlugIdMapSelector only if subsubskillsSlugs exist
  const subskillsSlugIdMap: { [key: string]: string } = subskillsSlugIdMapSelector(state);
  // Get subskills ids array from slugs if exist
  return subskillsSlugs.map(slug => subskillsSlugIdMap[slug]).filter(Boolean);
};

/*
 * slug id hash map creator
 */
export const slugIdMapCreator = (skills: any[] | null): { [key: string]: string } => {
  if (!skills) return emptyObject;
  return skills.reduce((acc: any, skill: State.Skill | State.Subskill) => {
    acc[skill.slug] = skill.id;
    return acc;
  }, {});
};
