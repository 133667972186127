import React from 'react';
import styled from 'styled-components/macro';
import { Cart, Checkout, Shipping } from 'mxp-schemas';
import { NotificationForAnonymousUser } from 'components/molecules/NotificationForAnonymousUser';
import { Divider } from 'components/atoms/Divider/Divider';
import { Collapsible } from 'components/atoms/Collapsible/Collapsible';
import { SpaceBetweenFlex, PriceLabel, PriceDetail, discountColor } from './Item';
import { getAllDiscountsForType, getTestIdTypeLabel, getDiscountLabel, formatPrice } from 'utils';
import { Icon } from 'semantic-ui-react';

interface PriceSummaryProps {
  totalPriceInfo: Cart.CartTotalPriceInfo;
  cartShippingInfo: Shipping.ShippingPrice;
  isAuth: boolean | null;
  hasBasicPhysicalProductInCart: boolean;
  changeTextOnAddressPage: boolean;
  pageStep: string | null;
  isPageCart: boolean;
  isContributionOnly?: boolean;
  hasMembershipProduct?: boolean;
  isCenterMembershipJourney?: boolean;
  membershipRefund?: number;
  isPaymentStep?: boolean;
  currency?: string;
}

export const PriceSummary: React.FC<PriceSummaryProps> = ({
  totalPriceInfo,
  cartShippingInfo,
  isAuth,
  hasBasicPhysicalProductInCart,
  changeTextOnAddressPage,
  isPageCart,
  isContributionOnly,
  hasMembershipProduct,
  isCenterMembershipJourney = false,
  membershipRefund,
  isPaymentStep = false,
  currency,
}) => {
  const {
    formattedTotalDiscountAmount: totalDiscount,
    formattedTotalPriceWithoutTaxWithoutShipping: totalWithoutTaxWithoutShipping,
    formattedTotalPriceWithoutTax: totalPriceWithoutTax,
    formattedTaxAmount: taxAmount,
    discounts,
    formattedTotalPrice: totalPrice,
  } = totalPriceInfo;

  const [collapsed, setCollapsed] = React.useState(true);
  const handleCollapse = React.useCallback(() => {
    setCollapsed(!collapsed);
  }, [setCollapsed, collapsed]);

  const hasDiscounts = totalDiscount && discounts && Boolean(discounts.length);
  const memberDiscount = getAllDiscountsForType(discounts, Cart.DISCOUNT_TYPE.MEMBERSHIP_DISCOUNT)[0];
  const promoDiscount = getAllDiscountsForType(discounts, Cart.DISCOUNT_TYPE.CART_PROMO_DISCOUNT)[0];
  const autoDiscounts = getAllDiscountsForType(discounts, Cart.DISCOUNT_TYPE.AUTO_APPLIED_DISCOUNT);
  const showShippingCost = Boolean(!isPageCart && cartShippingInfo?.centAmount);

  return (
    <PriceDetailsContainer>
      {showShippingCost && Boolean(cartShippingInfo?.name) && (
        <SpaceBetweenTotalFlex>
          <PriceInfo data-testid="shipping-label">
            {Checkout.ShippingTypeNameByMethodNameMap[cartShippingInfo.name]} shipping
          </PriceInfo>
          <PriceSummaryDetail data-testid="shipping-value">
            {cartShippingInfo.isFreeShipping ? 'FREE' : cartShippingInfo.formattedShippingPriceAmount}
          </PriceSummaryDetail>
        </SpaceBetweenTotalFlex>
      )}
      {hasDiscounts && (
        <>
          <SpaceBetweenTotalFlex onClick={handleCollapse}>
            <PriceInfo data-testid="total-discount-label">
              Total Discount <AngleIcon fitted name={collapsed ? 'angle down' : 'angle up'} size="small" />
            </PriceInfo>
            <PriceSummaryDetail isDiscount data-testid="total-discount-value">
              {totalDiscount}
            </PriceSummaryDetail>
          </SpaceBetweenTotalFlex>
          <CollapsibleStyled collapsed={collapsed}>
            {memberDiscount && <DiscountTotalDetail discount={memberDiscount} />}
            {promoDiscount && <DiscountTotalDetail discount={promoDiscount} />}
            {autoDiscounts?.map((autoDiscount: Cart.DiscountDetails, index: number) => (
              <DiscountTotalDetail key={`autoDiscount${autoDiscount.label}${index}`} discount={autoDiscount} />
            ))}
            <Spacer />
          </CollapsibleStyled>
        </>
      )}
      {Boolean(membershipRefund) && (
        <>
          <SpaceBetweenTotalFlex>
            <PriceInfo data-testid={`refund-label`}>Refund</PriceInfo>
            <PriceInfo data-testid={`refund-value`}>-{formatPrice(Number(membershipRefund), currency)}</PriceInfo>
          </SpaceBetweenTotalFlex>
          <RefundLabel>The total shown above has already been adjusted to reflect this refund.</RefundLabel>
          <Divider />
        </>
      )}
      {isPaymentStep && (
        <SpaceBetweenTotalFlex>
          <PriceInfo data-testid={`subtotal-label`}>Subtotal</PriceInfo>
          <PriceInfo data-testid={`subtotal-value`}>{totalPriceWithoutTax}</PriceInfo>
        </SpaceBetweenTotalFlex>
      )}
      {taxAmount !== null && (
        <SpaceBetweenFlex>
          <PriceInfo data-testid={`tax-label`}>Sales tax</PriceInfo>
          <PriceInfo data-testid={`tax-value`}>{taxAmount}</PriceInfo>
        </SpaceBetweenFlex>
      )}
      {(hasDiscounts || showShippingCost || isPaymentStep) && <Divider />}

      {isCenterMembershipJourney ? (
        <SpaceBetweenFlex>
          <CenterBold data-testid={'total-label'}>Total</CenterBold>
          <CenterBold data-testid={'total-value'}>
            {isPageCart ? totalWithoutTaxWithoutShipping : isPaymentStep ? totalPrice : totalPriceWithoutTax}
          </CenterBold>
        </SpaceBetweenFlex>
      ) : (
        <SpaceBetweenFlex>
          <SemiBold data-testid={`${isPaymentStep ? 'total' : 'subtotal'}-label`}>
            {isPaymentStep ? 'Total cost' : 'Subtotal'}
          </SemiBold>
          <Bold data-testid={`${isPaymentStep ? 'total' : 'subtotal'}-value`}>
            {isPageCart ? totalWithoutTaxWithoutShipping : isPaymentStep ? totalPrice : totalPriceWithoutTax}
          </Bold>
        </SpaceBetweenFlex>
      )}

      {taxAmount === null && !isContributionOnly && !isCenterMembershipJourney && (
        <>
          <PriceInfo isSalesTaxLabel>{`Sales tax ${
            hasBasicPhysicalProductInCart && !cartShippingInfo?.name ? `and shipping` : ``
          }`}</PriceInfo>
          <PriceInfo
            isSalesTaxLabel
            hasBasicPhysicalProductInCart={hasBasicPhysicalProductInCart && !cartShippingInfo?.name}
          >
            {changeTextOnAddressPage && !hasMembershipProduct
              ? `calculated at next step`
              : `calculated during checkout, if applicable`}
          </PriceInfo>
        </>
      )}
      {!isAuth && <NotificationForAnonymousUserStyled />}
    </PriceDetailsContainer>
  );
};

const DiscountTotalDetail: React.FC<{ discount: Omit<Cart.DiscountDetails, 'discountAmount'> }> = ({ discount }) => {
  const testIdType = getTestIdTypeLabel(discount);
  const testIdTypeLabel = `discount-total-${testIdType}`;
  return (
    <SpaceBetweenFlex key={discount.label}>
      <PriceLabel data-testid={`${testIdTypeLabel}-label`}>{getDiscountLabel(discount)}</PriceLabel>
      <PriceDetail isDiscount data-testid={`${testIdTypeLabel}-value`}>
        {discount.formattedDiscountAmount}
      </PriceDetail>
    </SpaceBetweenFlex>
  );
};

const CollapsibleStyled = styled(Collapsible)`
  padding-left: ${props => `${props.theme.pxToRem(16)}`};
`;

const Bold = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.m};
  color: ${props => props.theme.colors.primaryPurple};
  line-height: 1.78;
`;

const SemiBold = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.78;
`;

const CenterBold = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.78;
`;

const PriceDetailsContainer = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(15)};
  }
`;

const SpaceBetweenTotalFlex = styled<any>(SpaceBetweenFlex)`
  margin-bottom: ${props => props.theme.pxToRem(5)};
`;

const PriceInfo = styled.span<{ isSalesTaxLabel?: boolean; hasBasicPhysicalProductInCart?: boolean }>`
  ${props =>
    props.isSalesTaxLabel
      ? `
      font-size: ${props.theme.fontSizes.xxs};
      font-weight: ${props.theme.fontWeights.light};
  `
      : `
      font-size: ${props.theme.fontSizes.xs};
  `}
  line-height: 1.57;
  ${props => props.hasBasicPhysicalProductInCart && `display: block;`}
`;

const PriceSummaryDetail = styled.span<{ isDiscount?: boolean }>`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.57;

  ${props =>
    props.isDiscount &&
    `
    color: ${props.theme.colors[discountColor]};
    font-weight: ${props.theme.fontWeights.medium};
  `}
`;

const AngleIcon = styled(Icon)`
  color: ${props => props.theme.colors.primaryPurple};
  padding-left: ${props => props.theme.pxToRem(5)};
  &&& {
    font-size: 0.85rem;
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(5)};
`;

const NotificationForAnonymousUserStyled = styled(NotificationForAnonymousUser)`
  &&&&&&& {
    margin-top: ${props => props.theme.pxToRem(10)};
  }
`;

const RefundLabel = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
`;
