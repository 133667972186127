import React from 'react';
import styled from 'styled-components';
import { Container, Divider } from 'semantic-ui-react';

export const OrganizationFirmInformationSection: React.FC<{ heading: string; children: React.ReactNode }> = ({
  heading,
  children,
}) => (
  <StyledContainer fluid>
    <StyledHeading>{heading}</StyledHeading>
    <StyledText>{children}</StyledText>
    <Divider />
  </StyledContainer>
);

const StyledContainer = styled(Container)`
  &&&& {
    display: flex;
    flex-direction: column;
    padding: ${props => props.theme.pxToRem(40)} 0;
    + div {
      padding-top: 0;
    }
  }
`;

const StyledHeading = styled.h3`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: 500;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(16)};
`;

const StyledText = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(8)};
  max-width: ${props => props.theme.pxToRem(430)};
`;
