import React from 'react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';

interface Props {
  title: string;
  testId: string;
  isMultipleLines?: boolean;
  isToolkit?: boolean;
}

export const HomeFeedSectionTitle: React.FC<Props> = React.memo(
  ({ title, testId, isMultipleLines, isToolkit = false }) => {
    return (
      <StyledH4 data-testid={testId} isMultipleLines={isMultipleLines} isToolkit={isToolkit}>
        {title}
      </StyledH4>
    );
  }
);

const StyledH4 = styled.h4<ThemedStyledProps<{ isMultipleLines?: boolean; isToolkit?: boolean }, DefaultTheme>>`
  font-size: ${props => props.theme.pxToRem(18)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  margin-top: ${props => props.theme.pxToRem(24)} !important;
  ${props => props.theme.mediaQueries.mobileOnly} {
    white-space: normal;
    font-size: ${props => props.theme.pxToRem(18)};
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;
