import React from 'react';
import styled from 'styled-components/macro';
import { Product, User } from 'mxp-schemas';
import { ProductThumbnail, ProductThumbSize } from 'components/molecules/ProductThumbnail/ProductThumbnail';
import { ProductListItemHeader } from 'components/molecules/ProductListItemHeader/ProductListItemHeader';
import { Button, ButtonEnums, Dropdown, Expandable, Link, LinkEnums } from 'components/atoms';
import { getProductFormatLabel } from 'modules/products/helpers';
import { Utils, User as UserUtils } from 'mxp-utils';
import { getMomentDateTimeInfo } from 'utils/MomentHelpers';
import { DropdownProps } from 'components/atoms/Dropdown/Dropdown';
import { emptyArray, centPriceToString, getPath, productTypeToSlug } from 'utils';
import { handleEvent, EVENT_CLICK, NAV_CLICK } from 'utils/Analytics';
import { generatePath } from 'react-router';
import { Routes } from 'constants/index';
import {
  AccordionListParagraph,
  AccordionTextParagraph,
} from '../../pages/PageProduct/BundleContentsItemAccordionStyles';
import { productCurrencySelector } from 'modules/products/selectors';
import { useSelector } from 'react-redux';

interface Props {
  productItem: Product.ProductItem;
  indexWithinBundle: number;
  bundleDiscountPercent: number;
  isAuth: boolean | null;
  isExistingCartItem?: boolean;
  bundleItemVariantPrices: State.VariantsPriceInfoForUserType[];
  bundleItemsOutOfStockInfo: State.BundleItemVariantOutOfStock[][];
  forceSingleMaxPrice?: boolean;
  children?: React.ReactNode;
  setSelectedBundleProductVariant: (variant: Common.BundleProductVariant) => void;
  accessProvisionedItem?: (product: Product.ProductItem) => void;
  isUserSuspendedByEthics?: boolean;
}

interface DropDownVariant {
  format?: string;
  startDateTime?: string;
  sku: string;
  start?: string;
  startDate: string;
  startTime: string;
  end?: string;
  endDate: string;
  endTime: string;
  timeZone: string;
  duration: number;
  dateRange?: string;
  formattedDates?: string;
  prices?: Product.Price[];
}

enum PriceRangeType {
  Single,
  Range,
  Free,
  NA,
}
export const BundleContentsItem: React.FC<Props> = ({
  productItem,
  indexWithinBundle,
  bundleDiscountPercent,
  isAuth,
  isExistingCartItem,
  bundleItemVariantPrices,
  bundleItemsOutOfStockInfo,
  forceSingleMaxPrice = false,
  children,
  setSelectedBundleProductVariant,
  accessProvisionedItem,
  isUserSuspendedByEthics,
}) => {
  const {
    productType,
    variants,
    subscriptionProductType,
    name,
    categories,
    nasbaFieldOfStudy,
    productId,
    slug,
    outcomes,
    keyTopics,
    overview,
    isSubscribed,
  } = productItem;
  const variantPriceMap = bundleItemVariantPrices[indexWithinBundle];
  const coverSrc = variants?.[0]?.images?.[0]?.imageUrl || '';
  const subscriptionType = subscriptionProductType?.key as Product.SubscriptionProductType;
  const isConference: boolean = productType === Product.ProductType.CONFERENCE;
  const isWebcast: boolean = productType === Product.ProductType.WEBCAST;
  const isCourse: boolean = productType === Product.ProductType.COURSE;
  const overviewTitle = isConference ? 'Conference Details' : 'Product Details';
  const [currentVariant, setCurrentVariant] = React.useState('');
  const productCurrency = useSelector(productCurrencySelector).label;

  const outOfStockBundleInfo: State.BundleItemVariantOutOfStock[] = bundleItemsOutOfStockInfo[indexWithinBundle];

  const thumbnailSubtitle = React.useMemo(
    () => (Array.isArray(categories) && categories.find(category => category.isMain)?.name) || '',
    [categories]
  );

  const highestPossiblePriceFormatted: string = React.useMemo(() => {
    let maxPrice = 0;
    let maxPriceVariant = '';
    let discountedWithBundle = Number.MAX_VALUE;
    if (currentVariant) {
      maxPriceVariant = currentVariant;
    } else {
      for (const prop in variantPriceMap) {
        if (Object.prototype.hasOwnProperty.call(variantPriceMap, prop)) {
          const fullPrice = Number(variantPriceMap[prop].priceFull?.amount);
          if (fullPrice > maxPrice) {
            maxPrice = fullPrice;
            maxPriceVariant = prop;
          }
        }
      }
    }
    if (isUserSuspendedByEthics) {
      discountedWithBundle = variantPriceMap[maxPriceVariant]?.priceFull?.amount || 0;
      discountedWithBundle = discountedWithBundle * (1 - bundleDiscountPercent / 100);
      return centPriceToString(discountedWithBundle, productCurrency);
    }
    return (variantPriceMap[maxPriceVariant]?.priceFull?.formattedPrice || '').replace('.00', '');
  }, [variantPriceMap, currentVariant, bundleDiscountPercent, isUserSuspendedByEthics, productCurrency]);

  const productURL = React.useMemo(
    () =>
      generatePath(getPath(Routes.PRODUCT_PAGE), {
        productTypeSlug: subscriptionType || productTypeToSlug(productType as Product.ProductType),
        slug,
      }),
    [productType, slug, subscriptionType]
  );

  const handleProvisionAccess = React.useCallback(() => {
    if (accessProvisionedItem) accessProvisionedItem(productItem);
  }, [accessProvisionedItem, productItem]);

  const handleBundleItemLinkClick = React.useCallback((): void => {
    handleEvent({ clickValue: `button:bundle-item:int:header:${productId}:${name}` }, NAV_CLICK);
  }, [productId, name]);

  const onReadMoreClick = React.useCallback((): void => {
    handleEvent({ clickValue: `button:bundle-item:int:read-more:${productId}:${name}` }, EVENT_CLICK);
  }, [productId, name]);

  const renderProductThumbnails = (): React.ReactNode => (
    <ProductThumbnail
      coverSrc={coverSrc}
      title={name || ''}
      productType={productType as Product.ProductType}
      subscriptionProductType={subscriptionType}
      size={ProductThumbSize.MEDIUM}
      subtitle={thumbnailSubtitle}
    />
  );

  const renderLinkedBundleItemBlock = (block: React.ReactNode): React.ReactNode => (
    <Link
      type={LinkEnums.type.standaloneLink}
      underline={LinkEnums.underline.noUnderlineOnHover}
      to={productURL}
      testId={`bundle-contents-item-${productId}`}
      onClick={handleBundleItemLinkClick}
    >
      {block}
    </Link>
  );

  const renderAccordionContent = (): React.ReactNode => (
    <>
      {!!outcomes?.length && <AccordionListParagraph title="Learning Outcomes" listItems={outcomes} />}
      {!!keyTopics?.length && <AccordionListParagraph title="Key Topics" listItems={keyTopics} />}
      {!!overview && <AccordionTextParagraph title={overviewTitle} summary={''} contentText={overview} />}
      <StyledLink to={productURL}>View product detail in full</StyledLink>
    </>
  );

  const productFormatLabel = getProductFormatLabel(productItem);

  const setSelectedVariant = React.useCallback(
    (sku: string) => {
      setCurrentVariant(sku);
      setSelectedBundleProductVariant({ index: indexWithinBundle, sku });
    },
    [setSelectedBundleProductVariant, indexWithinBundle]
  );

  const onVariantChange = React.useCallback(
    (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      setSelectedVariant(String(data.value));
    },
    [setSelectedVariant]
  );

  const formatVariantDates = (variant: DropDownVariant) => {
    if (variant.startDate === variant.endDate) {
      if (variant.startTime === variant.endTime) {
        return `${variant.startDate} ${variant.startTime} ${variant.timeZone}`;
      }
      return `${variant.startDate} ${variant.startTime} - ${variant.endTime} ${variant.timeZone}`;
    }
    const startDayAndYear = variant.startDate.split(', ');
    const endDayAndYear = variant.endDate.split(', ');
    if (startDayAndYear[1] === endDayAndYear[1]) {
      return `${startDayAndYear[0]} ${variant.startTime} - ${variant.endDate} ${variant.endTime} ${variant.timeZone}`;
    }
    return `${variant.startDate} ${variant.startTime} - ${variant.endDate} ${variant.endTime} ${variant.timeZone}`;
  };

  const variantPriceFormatted = React.useCallback(
    (sku: string) => {
      const finalPrice = !isUserSuspendedByEthics
        ? Number(variantPriceMap[sku]?.priceFinal?.amount) || 0
        : Number(variantPriceMap[sku]?.priceFull?.amount) || 0;
      return finalPrice ? centPriceToString(Math.round(finalPrice * (1 - bundleDiscountPercent / 100))) : '';
    },
    [variantPriceMap, bundleDiscountPercent, isUserSuspendedByEthics]
  );

  const { actualVariants, hasFormatVariants } = React.useMemo(() => {
    let hasMultipleFormats = true;
    let validVariants: DropDownVariant[] = emptyArray;

    validVariants = variants
      .map(v => {
        const dateTimeInfo = getMomentDateTimeInfo(v.sku || '', v.startDateTime, v.endDateTime);
        const formattedPrice = variantPriceFormatted(v.sku || '');
        return {
          ...dateTimeInfo,
          formattedDates: formatVariantDates(dateTimeInfo),
          format:
            `${v.format?.label}` +
            `${v.optionalText ? ` - ${v.optionalText}` : ''}` +
            `${v.examType?.label ? ` - ${v.examType?.label}` : ''}` +
            `${formattedPrice ? ` - ${formattedPrice}` : ''}`,
          startDateTime: v.startDateTime,
          prices: v.prices,
        };
      })
      .filter(v => !v.startDateTime || Date.parse(String(v.startDateTime)) > Date.now());

    const uniqueFormatCount = validVariants.filter(
      (item, pos) => validVariants.findIndex(itemToCheck => itemToCheck.format === item.format) === pos
    ).length;

    if (uniqueFormatCount < 2) {
      hasMultipleFormats = false;
      validVariants = validVariants.filter(
        (item, pos) =>
          validVariants.findIndex(itemToCheck => itemToCheck.formattedDates === item.formattedDates) === pos
      );

      validVariants =
        validVariants.length === 1
          ? validVariants
          : validVariants.map(v => ({
              ...v,
              format: v.formattedDates,
            }));
    }
    return { actualVariants: validVariants, hasFormatVariants: hasMultipleFormats };
  }, [variants, variantPriceFormatted]);

  const [priceRangeForActualVariantsFormatted, priceRangeForActualVariantsType] = React.useMemo(() => {
    let maxPrice = Number.NEGATIVE_INFINITY;
    let minPrice = Number.MAX_VALUE;
    let userRoles: string[] = [];
    let formattedRange: string;
    let rangeType: PriceRangeType;
    if (isAuth) {
      actualVariants
        .filter(v => !currentVariant || v.sku === currentVariant)
        .forEach(v => {
          let finalPrice = variantPriceMap[v.sku]?.priceFinal?.amount;
          if (finalPrice !== null && finalPrice !== undefined) {
            finalPrice = Number(finalPrice);
            maxPrice = Math.max(maxPrice, finalPrice);
            if (finalPrice < minPrice) {
              minPrice = finalPrice;
              userRoles = variantPriceMap[v.sku]?.priceFinal?.channel?.applicableUserRoles || [];
            }
          }
        });
    } else {
      actualVariants
        .filter(v => !currentVariant || v.sku === currentVariant)
        .forEach(v => {
          if (v.prices?.length) {
            const prices = v.prices.map(p => Number(p.amount));
            maxPrice = Math.max(maxPrice, ...prices);
            minPrice = Math.min(minPrice, ...prices);
          }
        });
    }
    if (maxPrice === Number.NEGATIVE_INFINITY || isNaN(maxPrice)) {
      return ['This product is not available at the moment.', PriceRangeType.NA];
    }

    maxPrice = Math.round(maxPrice * (1 - bundleDiscountPercent / 100));
    minPrice = Math.round(minPrice * (1 - bundleDiscountPercent / 100));

    if (maxPrice === 0) {
      formattedRange = 'FREE';
      rangeType = PriceRangeType.Free;
    } else if (maxPrice === minPrice) {
      formattedRange = centPriceToString(minPrice, productCurrency);
      rangeType = PriceRangeType.Single;
    } else if (!(forceSingleMaxPrice && currentVariant)) {
      formattedRange = `${centPriceToString(minPrice, productCurrency)} - ${centPriceToString(
        maxPrice,
        productCurrency
      )}`;
      rangeType = PriceRangeType.Range;
    } else {
      formattedRange = centPriceToString(maxPrice, productCurrency);
      rangeType = PriceRangeType.Single;
    }

    let userRoleDiscountSuffix = '';
    if (userRoles[0] && userRoles[0] !== User.MembershipIdsEnum.NON_MEMBER) {
      userRoleDiscountSuffix = UserUtils.membershipDiscountMapNamesForCartSummary[userRoles[0]] || '';
      if (userRoleDiscountSuffix) {
        userRoleDiscountSuffix = ` (${userRoleDiscountSuffix} price)`;
      }
    }

    return [formattedRange + userRoleDiscountSuffix, rangeType];
  }, [
    variantPriceMap,
    actualVariants,
    bundleDiscountPercent,
    isAuth,
    currentVariant,
    forceSingleMaxPrice,
    productCurrency,
  ]);

  const options =
    actualVariants?.length > 1
      ? actualVariants.map((v, i) => {
          const sku = v.sku;
          const isOutOfStock = Boolean(
            (outOfStockBundleInfo || []).find((oosInfo: any) => oosInfo.sku === sku)?.isOutOfStock
          );
          return {
            key: i,
            text: v.format,
            value: v.sku,
            disabled: isOutOfStock,
            ...(isOutOfStock && {
              description: <OutOfStock data-testid="out-of-stock-label">Currently out of stock</OutOfStock>,
            }),
          };
        })
      : null;

  const currentVariantDateFormatted: string | null = React.useMemo(() => {
    if (isConference || isWebcast) {
      if (!options || (options && hasFormatVariants)) {
        return (
          actualVariants.find(v => v.sku === currentVariant)?.formattedDates ||
          actualVariants[0]?.formattedDates ||
          null
        );
      }
    }
    return null;
  }, [actualVariants, currentVariant, hasFormatVariants, isConference, isWebcast, options]);

  const currentLocations: string | null = React.useMemo(() => {
    if (isConference) {
      return variants.find(v => v.sku === currentVariant)?.locations || variants[0]?.locations || null;
    }
    return null;
  }, [variants, currentVariant, isConference]);

  const nasbaFieldOfStudyFormatted: string = React.useMemo(() => {
    const fieldArray = Utils.getNasbaFieldOfStudy(nasbaFieldOfStudy || emptyArray) || emptyArray;
    return fieldArray.length ? `NASBA Field of Study: ${fieldArray.join(', ')}` : '';
  }, [nasbaFieldOfStudy]);

  const readMoreData = [
    {
      id: indexWithinBundle,
      heading: <span className="heading">Read </span>,
      content: renderAccordionContent(),
    },
  ];

  return (
    <>
      <ItemFlexWrap>
        <StyledThumbnailContainer>{renderLinkedBundleItemBlock(renderProductThumbnails())}</StyledThumbnailContainer>
        <InfoSection>
          <ProductListItemHeaderContainer>
            <ProductListItemHeader
              productItem={productItem}
              currentVariant={currentVariant}
              handleBundleItemLinkClick={handleBundleItemLinkClick}
            >
              {children}
            </ProductListItemHeader>
          </ProductListItemHeaderContainer>

          {!!currentVariantDateFormatted && <StyledBlackXSText>{currentVariantDateFormatted}</StyledBlackXSText>}
          {!!currentLocations && <StyledBlackXSText>{currentLocations}</StyledBlackXSText>}
          <StyledBlackXSText>{productFormatLabel}</StyledBlackXSText>
          {nasbaFieldOfStudyFormatted && <StyledBlackXSText>{nasbaFieldOfStudyFormatted}</StyledBlackXSText>}
          <PriceFlex>
            <>
              {!isUserSuspendedByEthics && (
                <>
                  {priceRangeForActualVariantsType !== PriceRangeType.NA && (
                    <PriceBase>{highestPossiblePriceFormatted}</PriceBase>
                  )}
                  {priceRangeForActualVariantsType === PriceRangeType.Free ? (
                    <PriceFree>{priceRangeForActualVariantsFormatted}</PriceFree>
                  ) : (
                    <Price>{priceRangeForActualVariantsFormatted}</Price>
                  )}
                </>
              )}
              {isUserSuspendedByEthics && (
                <>
                  {priceRangeForActualVariantsType !== PriceRangeType.NA && (
                    <Price>{highestPossiblePriceFormatted}</Price>
                  )}
                </>
              )}
            </>
          </PriceFlex>
          {priceRangeForActualVariantsType !== PriceRangeType.NA &&
            priceRangeForActualVariantsType !== PriceRangeType.Free && (
              <PriceDiscount>{`${bundleDiscountPercent}% bundle discount applied`}</PriceDiscount>
            )}

          {!!options && !(isSubscribed && accessProvisionedItem) && (
            <>
              <StyledDropdownLabelText>
                Select {hasFormatVariants ? 'format' : 'date'} from the following options:
              </StyledDropdownLabelText>
              <StyledDropdown
                testId={`dropdown-select-${productId}`}
                placeholder={`Select ${hasFormatVariants ? 'format' : 'date'}`}
                options={options}
                value={undefined}
                onChange={onVariantChange}
                disabled={isExistingCartItem}
              />
            </>
          )}
          {Boolean(isSubscribed && accessProvisionedItem) && (
            <>
              <ActiveSubscriptionNotificationStyled>
                You have an active {isCourse ? 'course' : 'subscription'} for this product. You can always manage it in
                your{' '}
                <Link
                  testId={`purchase-summary-link-to-purchases-page-${productId}`}
                  to={getPath(Routes.PROFILE_PURCHASES)}
                >
                  Profile
                </Link>
                .
              </ActiveSubscriptionNotificationStyled>

              <StyledButton
                testId={`access-now-purchase-summary-${productId}`}
                variant={ButtonEnums.variants.primary}
                onClick={handleProvisionAccess}
              >
                Access Now
              </StyledButton>
            </>
          )}
        </InfoSection>
      </ItemFlexWrap>
      <StyledExpandableWrapper>
        <Expandable items={readMoreData} onClick={onReadMoreClick} />
      </StyledExpandableWrapper>
    </>
  );
};

const ActiveSubscriptionNotificationStyled = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin: ${props => props.theme.pxToRem(10)} 0;
`;

const StyledButton = styled(Button)`
  &&&&&&&&& {
    width: ${props => props.theme.pxToRem(164)};
  }
`;

const StyledExpandableWrapper = styled.div`
  .accordion > div > .title {
    & > div {
      justify-content: flex-start;
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.medium};
      font-family: ${props => props.theme.fontFamily};
      color: ${props => props.theme.colors.neutralGrey8};

      & > div > svg {
        width: ${props => props.theme.pxToRem(19)};
        height: ${props => props.theme.pxToRem(19)};
      }
    }

    &:not(.active) > div > span.heading:after {
      content: ' more';
    }
    &.active > div > span.heading:after {
      content: ' less';
    }
  }
`;

const ProductListItemHeaderContainer = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(8)};
`;

const StyledLink = styled(Link)`
  display: block;
  margin-top: ${props => props.theme.pxToRem(20)};
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  max-width: ${props => props.theme.pxToRem(323)};
  padding: ${props => props.theme.pxToRem(10)};

  &&&.ui.dropdown > .text {
    color: ${props => props.theme.colors.primaryPurple};
    font-size: ${props => props.theme.fontSizes.s};
  }

  &&&.ui.dropdown > .menu > .item {
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.light};
    &.selected {
      font-weight: ${props => props.theme.fontWeights.regular};
    }
  }

  &&&.ui.dropdown.disabled {
    background-color: ${props => props.theme.colors.neutralGrey3};
    & > .text {
      color: ${props => props.theme.colors.neutralGrey5};
      font-size: ${props => props.theme.fontSizes.xs};
    }
    svg > path {
      fill: ${props => props.theme.colors.neutralGrey5};
    }
  }
`;

const StyledDropdownLabelText = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xs}; /* xs = 14 */
  font-weight: ${props => props.theme.fontWeights.light}; /* light = 300 */
  padding-top: ${props => props.theme.pxToRem(16)};
  padding-bottom: ${props => props.theme.pxToRem(8)};
`;

const PriceFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  height: ${props => props.theme.pxToRem(20)};
  margin-top: ${props => props.theme.pxToRem(16)};
`;

const PriceBase = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  text-decoration: line-through;
  margin-right: ${props => props.theme.pxToRem(8)};
`;

const Price = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.43;
  height: ${props => props.theme.pxToRem(24)};
`;

const PriceFree = styled(Price as any)`
  color: ${props => props.theme.colors.secondaryTeal};
`;

const OutOfStock = styled.div`
  &&&&&& {
    color: ${props => props.theme.colors.interfaceRed};
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.regular};
  }
  opacity: 1;
`;

const PriceDiscount = styled.div`
  height: ${props => props.theme.pxToRem(24)};
  color: ${props => props.theme.colors.secondaryTeal};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 2;
`;

const StyledBlackXSText = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.xs}; /* xs = 14 */
  font-weight: ${props => props.theme.fontWeights.light}; /* light = 300 */
  line-height: 1.43;
`;

const ItemFlexWrap = styled.div`
  display: flex;
  min-height: ${props => props.theme.pxToRem(250)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
  }
`;

const thumbnailTagDimensions = {
  height: 20,
  width: 14,
};

const thumbStyle = (props: any) => `${props.theme.mediaQueries.mobileOnly} {
  height: ${props.theme.pxToRem(thumbnailTagDimensions.height)};
}`;

const StyledThumbnailContainer = styled.div`
  ${props => thumbStyle(props)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-height: ${props => props.theme.pxToRem(220)};
  }
`;

const InfoSection = styled.div`
  flex-grow: 1;
  width: calc(100%);
  margin-left: ${props => props.theme.pxToRem(20)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: 0;
  }
`;
