import React from 'react';

export const useDoubleDonation = () => {
  const removeElement = React.useCallback(() => {
    const styleElement = document.querySelector('#dd-container');

    if (styleElement && styleElement.parentNode) styleElement.parentNode.removeChild(styleElement);
  }, []);

  React.useEffect(() => {
    const body = document.querySelector('body');
    if (!body) return;
    const element = document.querySelector('#dd-container');
    const style = document.createElement('style');
    if (element) {
      element.classList.add('donation-hide');
    }
    style.innerHTML = `
		.donation-hide {
			visibility:hidden;
		}
		`;

    document.body.appendChild(style);

    // cleanup script on hook unmount
    return removeElement;
  }, [removeElement]);
};
