import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';
import { userMembershipPackageSelector } from 'modules/membership/selectors';
import { addMembershipToCart } from 'modules/membership/actions';
import { Button } from 'components/atoms';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

export interface IRenewButtonsGroup {
  changeToUpdateProcess: () => void;
  updateButtonText?: string;
  proceedToDonations?: () => void;
}

export const RenewButtonsGroup: FC<IRenewButtonsGroup> = ({
  changeToUpdateProcess,
  updateButtonText,
  proceedToDonations,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userChoice = useSelector(userMembershipPackageSelector);

  const hasSelectedCredential = userChoice.credentials.length;

  const handleRenewMembershipClick = useCallback(async () => {
    await dispatch(addMembershipToCart());
    let nextRoute = Routes.APPLICATION_FORM_DONATIONS;

    if (hasSelectedCredential) {
      nextRoute = Routes.CREDENTIALS_APPLICATION_FORM;
    }

    history.push(generatePath(getPath(nextRoute)));
  }, [hasSelectedCredential, history, dispatch]);

  const handleGoToHomePage = useCallback(() => {
    history.push(generatePath(getPath(Routes.HOME)));
  }, [history]);

  return (
    <ButtonsContainer>
      <RenewButton testId="renew-membership" onClick={handleRenewMembershipClick}>
        Renew Membership
      </RenewButton>
      <UpdateButton testId="renew-update" onClick={handleGoToHomePage}>
        {updateButtonText ? updateButtonText : 'Go back'}
      </UpdateButton>
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding-bottom: ${props => props.theme.pxToRem(16)};
`;

const RenewButton = styled(Button)`
  width: min(75%, ${props => props.theme.pxToRem(594)});
  min-width: ${props => props.theme.pxToRem(219)};
  height: ${props => props.theme.pxToRem(51)};
  font-size: ${props => props.theme.pxToRem(20)} !important;
  font-weight: ${props => props.theme.fontWeights.regular} !important;
  background: ${props => props.theme.colors.primaryPurple} !important;
  color: ${props => props.theme.colors.neutralWhite} !important;
  margin-right: ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    font-size: ${props => props.theme.pxToRem(16)} !important;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.pxToRem(16)} !important;
  }
`;

const UpdateButton = styled(Button)`
  width: min(25%, ${props => props.theme.pxToRem(179)});

  font-size: ${props => props.theme.pxToRem(16)} !important;
  font-weight: ${props => props.theme.fontWeights.regular} !important;
  background: ${props => props.theme.colors.neutralWhite} !important;
  color: ${props => props.theme.colors.primaryPurple}!important;
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryPurple} !important;
  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    font-size: ${props => props.theme.pxToRem(16)} !important;
    width: auto;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.pxToRem(16)} !important;
    width: auto;
  }
`;
