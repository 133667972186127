import React from 'react';
import styled from 'styled-components';

import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { Heading } from 'components/atoms/Heading/Heading';

export const SubHeading: React.FC<{
  title: string;
  buttonLabel: string;
  withAction: boolean;
  onAction: () => void;
}> = ({ title, buttonLabel, onAction, withAction }) => (
  <>
    <CustomContainer>
      <StyledPreferencesHeading as="h1">{title}</StyledPreferencesHeading>
      {withAction && (
        <StyledButton
          size={ButtonEnums.sizes.medium}
          testId={`sub-heading-${buttonLabel}`}
          variant={ButtonEnums.variants.primary}
          onClick={onAction}
        >
          {buttonLabel}
        </StyledButton>
      )}
    </CustomContainer>
    <StyledSeparator />
  </>
);

const CustomContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSeparator = styled.div`
  height: ${props => props.theme.pxToRem(1)};
  background: ${props => props.theme.colors.neutralGrey3};
  margin-top: ${props => props.theme.pxToRem(24)};
  margin-bottom: ${props => (props.children ? props.theme.pxToRem(33) : props.theme.pxToRem(25))};
  position: relative;
`;

const StyledButton = styled(Button)`
  width: ${props => props.theme.pxToRem(173)};
`;

const StyledPreferencesHeading = styled(Heading)`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  border-bottom: none;
  margin-bottom: 0;
  margin-top: 0;
`;
