import * as Sentry from '@sentry/browser';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import { SupportedPayments } from 'components/atoms/SupportedPayments/SupportedPayments';
import { CreditCardAdd } from 'components/organisms/CreditCardAdd/CreditCardAdd';
import MyProfileDefaultModal from 'components/pages/PageProfile/Employment/MyProfileDefaultModal';
import { push } from 'connected-react-router';
import { CreditCardOperations, Routes, StorageNames } from 'constants/index';
import { legalEntitySelector } from 'modules/admin/selectors';
import { sendMessage } from 'modules/app';
import { getHostedPageSignature } from 'modules/centerAdmin';
import {
  getZuoraAccount,
  sendPaymentMethodEmail,
  setCimaDefaultPaymentMethod,
  setDefaultPaymentMethod,
  toggleSaveCreditCardForFuture,
} from 'modules/checkout/actions';
import { createPaymentMethods } from 'modules/checkout/helpers';
import {
  creditCardsSelector,
  otherPaymentMethodSelector,
  saveCreditCardForFutureSelector,
  selectedPaymentMethodEntity,
  selectedCurrencySelector,
  zuoraIdByEntitySelector,
} from 'modules/checkout/selectors';

import {
  setCardBeingProcessed,
  setCardOperation,
  setCardOperationBannerVisible,
  setCardProcessed,
  setIsDeleteCardBeingProcessed,
  toggleAddCreditCardModal,
} from 'modules/layouts/actions';
import {
  isAddCreditCardModalOpenSelector,
  cardBeingProcessedSelector,
  isDeleteCardBeingProcessedSelector,
} from 'modules/layouts/selectors';
import { ZuoraTypes } from 'mxp-schemas';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getPath, getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from 'utils';
import { EVENT_CLICK, handleEvent } from 'utils/Analytics';
import { getCardById } from 'utils/CreditCardHelpers';

const cardActionsAnalyticsText = 'card-actions:int:';

export const AddCreditCardModal: React.FC = () => {
  const dispatch = useDispatch();

  const { cima, association } = useSelector(otherPaymentMethodSelector);
  const legalEntity = useSelector(legalEntitySelector);
  const selectedPaymentMethodLegalEntity = useSelector(selectedPaymentMethodEntity)?.toLocaleLowerCase();
  const zuoraLegalEntity = legalEntity || selectedPaymentMethodLegalEntity;
  const userCurrency = useSelector(selectedCurrencySelector);
  const accountId = useSelector(zuoraIdByEntitySelector(zuoraLegalEntity as string));
  const isAddCreditCardModalOpen = useSelector(isAddCreditCardModalOpenSelector);
  const saveCreditCardForFuture = useSelector(saveCreditCardForFutureSelector);
  const creditCards = useSelector(creditCardsSelector);
  const isCardBeingProcessedDefaultPayment = useSelector(cardBeingProcessedSelector)?.defaultPaymentMethod;
  const isDeleteCardBeingProcessed = useSelector(isDeleteCardBeingProcessedSelector);

  const { cimaCard, associationCard } = createPaymentMethods(cima, association);

  const paymentMethods = zuoraLegalEntity === ZuoraTypes.LegalEntity.CIMA ? cimaCard : associationCard;
  const hasPaymentMethods: boolean = Boolean(paymentMethods?.length);
  const showSetAsDefautToggle = !Boolean(paymentMethods?.length === 1 && isCardBeingProcessedDefaultPayment);

  const [recentlyAddedCreditCardId, setRecentlyAddedCreditCardId] = useState('');
  const [addCreditCardAsDefault, setAddCreditCardAsDefault] = useState(!hasPaymentMethods);
  const [iFrameLoading, setIFrameLoading] = useState(true);

  const applyMultiEntity = zuoraLegalEntity;

  const toggleAddingCreditCard = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      dispatch(toggleAddCreditCardModal());
      dispatch(setIsDeleteCardBeingProcessed(false));
      setAddCreditCardAsDefault(!hasPaymentMethods);
      setIFrameLoading(true);
      const buttonText = (event.target as HTMLAnchorElement).textContent || 'NA';
      return handleEvent({ clickValue: `text:${cardActionsAnalyticsText}${String(buttonText).trim()}` }, EVENT_CLICK);
    },
    [hasPaymentMethods, dispatch]
  );

  const onCardSubmitError = React.useCallback((doSendMessage: boolean, data: any) => {
    dispatch(toggleAddCreditCardModal());
    setIFrameLoading(true);
    try {
      Sentry.captureException(data);
      if (doSendMessage) {
        dispatch(sendMessage(data));
      }
    } catch {
      console.error('Sentry failed to start');
    }
    const cardOperation: State.CreditCardOperation = { name: CreditCardOperations.ADD, success: false };
    dispatch(setCardOperation(cardOperation));
    dispatch(setCardOperationBannerVisible(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCardSubmitSuccess = React.useCallback(async (data: any) => {
    dispatch(toggleAddCreditCardModal());
    setIFrameLoading(true);
    setRecentlyAddedCreditCardId(data.refId);
    if (data) {
      const retainDeletedCard = !getSessionStorageItem(StorageNames.isDeleteCardBeingProcessed);
      await dispatch(setCimaDefaultPaymentMethod(data?.refId, retainDeletedCard));
    }
    removeSessionStorageItem(StorageNames.isDeleteCardBeingProcessed);
    await dispatch(getZuoraAccount(null, null, null, applyMultiEntity));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onGetHostedPageSignature = (): Promise<void> => {
    return dispatch(getHostedPageSignature()).catch(() => {
      dispatch(push(getPath(Routes.NOT_FOUND)));
    });
  };

  const toggleAddCreditCardAsDefault = () => {
    setAddCreditCardAsDefault(!addCreditCardAsDefault);
  };

  const handleAddCardCancelClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      toggleAddCreditCardModal(!isAddCreditCardModalOpen);
      setIFrameLoading(true);
      const buttonText = (event.target as HTMLAnchorElement).textContent || 'NA';
      const firstText = hasPaymentMethods ? ':not-first' : ':first';
      return handleEvent({ clickValue: `button:${cardActionsAnalyticsText}${buttonText}${firstText}` }, EVENT_CLICK);
    },
    [isAddCreditCardModalOpen, hasPaymentMethods]
  );

  const handleAddCardSubmitClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setSessionStorageItem({ [StorageNames.isDeleteCardBeingProcessed]: isDeleteCardBeingProcessed });
      if (!saveCreditCardForFuture) {
        dispatch(toggleSaveCreditCardForFuture());
      }
      (window as any).Z.submit();
      const buttonText = (event.target as HTMLAnchorElement).textContent || 'NA';
      const firstText = hasPaymentMethods ? ':not-first' : ':first';
      return handleEvent({ clickValue: `button:${cardActionsAnalyticsText}${buttonText}${firstText}` }, EVENT_CLICK);
    },
    [dispatch, hasPaymentMethods, saveCreditCardForFuture, isDeleteCardBeingProcessed]
  );

  const onConfirmSetDefaultCard = React.useCallback(
    async (card: State.CreditCard): Promise<void> => {
      const cardOperation: State.CreditCardOperation = { name: CreditCardOperations.ADD, success: false };
      try {
        await dispatch(setCardProcessed());
        await dispatch(setDefaultPaymentMethod(null, false));
        cardOperation.success = true;
      } catch {
        dispatch(getZuoraAccount()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
      }

      dispatch(setCardOperation(cardOperation));
      dispatch(setCardOperationBannerVisible(true));
    },
    [dispatch]
  );

  const onSendCreditCardMail = React.useCallback(
    (transactionName: string): Promise<void> => {
      return dispatch(sendPaymentMethodEmail(transactionName));
    },
    [dispatch]
  );

  const processAddedCard = React.useCallback(
    async (newCard: State.CreditCard) => {
      setRecentlyAddedCreditCardId('');
      let transactionName: string;
      await dispatch(setCardBeingProcessed(newCard));
      if (addCreditCardAsDefault) {
        onConfirmSetDefaultCard(newCard);
        transactionName = 'add new payment method as default';
      } else {
        dispatch(setCardProcessed(newCard));
        const cardOperation: State.CreditCardOperation = { name: CreditCardOperations.ADD, success: true };
        dispatch(setCardOperation(cardOperation));
        dispatch(setCardOperationBannerVisible(true));
        transactionName = 'add new payment method';
      }
      onSendCreditCardMail(transactionName).catch(() => null);
    },
    [addCreditCardAsDefault, dispatch, onConfirmSetDefaultCard, onSendCreditCardMail]
  );

  useEffect(() => {
    if (recentlyAddedCreditCardId && creditCards && creditCards.length) {
      const newCard: State.CreditCard | null = getCardById(creditCards, recentlyAddedCreditCardId);
      if (newCard) processAddedCard(newCard);
    }
  }, [recentlyAddedCreditCardId, creditCards, processAddedCard]);

  useEffect(() => {
    if (paymentMethods?.length === 1 && isCardBeingProcessedDefaultPayment) {
      setAddCreditCardAsDefault(true);
    }
  }, [paymentMethods, isCardBeingProcessedDefaultPayment]);

  return (
    <MyProfileDefaultModal
      heading="Add a new payment card"
      open={isAddCreditCardModalOpen}
      close={toggleAddingCreditCard}
    >
      <StyledSupportedPayments horizontal />

      <CreditCardAdd
        {...{ accountId }}
        onCardSubmitError={onCardSubmitError}
        onCardSubmitSuccess={onCardSubmitSuccess}
        getHostedPageSignature={onGetHostedPageSignature}
        onIFrameLoadingChange={setIFrameLoading}
        currency={userCurrency}
      />

      {!iFrameLoading && (
        <>
          {!hasPaymentMethods && (
            <StyledAddCardSmallText>
              This card will be saved as your default payment card and used for your auto-renewals.
            </StyledAddCardSmallText>
          )}
          {hasPaymentMethods && showSetAsDefautToggle && (
            <StyledAddCardSmallText>
              <>
                <CheckboxStyled
                  width="24"
                  height="24"
                  type="checkbox"
                  testId="add-card-set-as-default--checkbox"
                  label="Set as my default payment card"
                  checked={addCreditCardAsDefault}
                  onChange={toggleAddCreditCardAsDefault}
                />
              </>
            </StyledAddCardSmallText>
          )}
          <AddCardButtonContainer>
            <ButtonCancelStyled
              testId="add-card-cancel--button"
              variant={ButtonEnums.variants.secondary}
              onClick={handleAddCardCancelClick}
            >
              Cancel
            </ButtonCancelStyled>
            <ButtonStyled
              testId="add-card-submit--button"
              variant={ButtonEnums.variants.primary}
              onClick={handleAddCardSubmitClick}
            >
              Add card
            </ButtonStyled>
          </AddCardButtonContainer>
        </>
      )}
    </MyProfileDefaultModal>
  );
};

const StyledSupportedPayments = styled(SupportedPayments)`
  margin-bottom: ${props => props.theme.pxToRem(25)};
`;

const StyledAddCardSmallText = styled.div`
  max-width: ${props => props.theme.pxToRem(440)};
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
`;

const CheckboxStyled = styled(Checkbox)`
  &&&&& {
    object-fit: contain;
    line-height: 1;
    margin-bottom: 0;

    > label {
      font-size: ${props => props.theme.fontSizes.xs};
      color: ${props => props.theme.colors.neutralBlack};
      font-weight: ${props => props.theme.fontWeights.regular};
      line-height: 1.57;
      padding-left: ${props => props.theme.pxToRem(35)};
    }
  }
`;

const AddCardButtonContainer = styled.div`
  max-width: ${props => props.theme.pxToRem(455)};
  display: flex;
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const ButtonStyled = styled(Button)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(20)};
    line-height: ${props => props.theme.pxToRem(24)};
    width: ${props => props.theme.pxToRem(170)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100% !important;
      height: ${props => `${props.theme.pxToRem(40)}`};
      max-width: ${props => `${props.theme.pxToRem(296)}`};
      font-size: ${props => props.theme.fontSizes.s};
      border-radius: ${props => `${props.theme.pxToRem(4)}`};
    }
  }
`;

const ButtonCancelStyled = styled(ButtonStyled as any)`
  &&&& {
    margin-right: ${props => props.theme.pxToRem(20)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => `${props.theme.pxToRem(16)}`} !important;
    }
  }
`;
