import React from 'react';
import styled from 'styled-components';
import { Icon, Input as SemanticUIInput, InputProps as SemanticUIInputProps, SemanticICONS } from 'semantic-ui-react';

export interface InputProps extends SemanticUIInputProps {
  iconName?: SemanticICONS;
  labelName?: string;
  labelId?: string;
  testId: string;
  className?: string;
  onIconClick?: () => void;
  onKeyDown?: (event?: any) => void;
  onIconMouseDown?: (event?: any) => void;
  inputRef?: any;
  icon?: React.Component;
  isLoading?: boolean;
  isRequired?: boolean;
}

export const Input: React.FC<InputProps> = ({
  iconName,
  labelName,
  labelId,
  testId,
  className,
  onIconClick,
  onIconMouseDown,
  inputRef,
  icon,
  isLoading,
  isRequired,
  ...props
}) => {
  const iconByName = iconName && (
    <Icon name={iconName} link onClick={onIconClick} onMouseDown={onIconMouseDown} data-testid={`icon-${testId}`} />
  );
  const iconElem = icon ? icon : iconByName;
  return (
    <>
      {labelName && labelId && (
        <Label htmlFor={labelId}>
          {labelName}
          <RequiredLabel>{isRequired && '*'}</RequiredLabel>
        </Label>
      )}

      <StyledInput
        ref={inputRef}
        {...props}
        className={className}
        data-testid={`input-${testId}`}
        id={labelId}
        icon={iconElem}
        loading={isLoading}
      />
    </>
  );
};

const Label = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const RequiredLabel = styled.label`
  color: ${props => props.theme.colors.interfaceRed};
`;

const StyledInput = styled(SemanticUIInput)`
  &&&& > i.icon {
    :hover {
      color: ${props => props.theme.colors.neutralGrey6};
    }
    color: ${props => props.theme.colors.neutralGrey4};
    opacity: 1;
  }

  &&&& > input {
    :focus {
      border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.interfaceBlue};
    }
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey4};

    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.colors.neutralGrey8};
    line-height: ${props => props.theme.pxToRem(24)};
    padding: ${props => props.theme.pxToRem(9)} ${props => props.theme.pxToRem(15)};
  }
  &&&& {
    &.ui.input > input {
      &:-ms-clear {
        display: none;
      }
      &::-ms-clear {
        display: none;
      }
      &:-ms-reveal {
        display: none;
      }
      &::-ms-reveal {
        display: none;
      }
    }
    &input[type='text']::-ms-clear {
      display: none;
    }
    &input[type='email']::-ms-clear {
      display: none;
    }
    &input[type='password']::-ms-clear {
      display: none;
    }
    &input[type='password']::-ms-reveal {
      display: none;
    }
  }
`;
