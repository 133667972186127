import React from 'react';
import styled from 'styled-components/macro';
import { Heading, Grid, OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful } from 'mxp-schemas';
import {
  ExternalLinkStyles,
  ExternalLinkStylesDark,
  DefaultInlineLinkStyles,
  InlineLinkOnDarkBgStyles,
} from 'components/atoms/Link/Link';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
  isFullPagePagination: boolean;
  isFirstSlide: boolean;
  isLastSlide: boolean;
  blockHeight: string;
}

export const BlockLongForm: React.FC<Props> = ({
  modularBlock,
  headingType,
  isFullPagePagination,
  isFirstSlide,
  isLastSlide,
  blockHeight,
}) => {
  const block = modularBlock as Contentful.ModularContent.LongFormBlock;
  const isImage: boolean = Boolean(block?.image);
  const isBgColorDark: boolean = isColorDark(block?.bgColor);
  const isLeftAlignment: boolean = Boolean(block?.contentAlignment || block?.contentAlignment === null);

  const renderHeaderBody = (): React.ReactNode => {
    return (
      <StyledGridColumnHeader
        width={!isImage ? 11 : 6}
        textAlign={isImage ? 'left' : 'center'}
        floated={isImage ? (isLeftAlignment ? 'left' : 'right') : undefined}
        isLeftAlignment={isLeftAlignment}
      >
        <StyledHeaderDescription data-is-bg-dark={isBgColorDark} data-is-single-column={!isImage}>
          {block?.header && (
            <StyledHeading
              as={headingType}
              dangerouslySetInnerHTML={{ __html: block?.header }}
              data-is-single-column={!isBgColorDark}
              isBgColorDark={isBgColorDark}
              tabIndex={0}
            />
          )}
          {block?.description && (
            <StyledParagraph
              dangerouslySetInnerHTML={{ __html: block?.description }}
              isBgColorDark={isBgColorDark}
              tabIndex={0}
            />
          )}
        </StyledHeaderDescription>
      </StyledGridColumnHeader>
    );
  };

  const renderDesktopImage = (): React.ReactNode => {
    if (!isImage) return null;
    return (
      <StyledGridColumnImage width={6} floated={isLeftAlignment ? 'right' : 'left'} isLeftAlignment={isLeftAlignment}>
        <OnlyDesktopCSS>
          <StyledImage
            alt=""
            src={block.image}
            isFullPagePagination={isFullPagePagination}
            blockHeight={blockHeight}
            tabIndex={0}
            imgWidthDesktop={!isLeftAlignment}
          />
        </OnlyDesktopCSS>
      </StyledGridColumnImage>
    );
  };

  const renderMobileImage = (): React.ReactNode => {
    if (!isImage) return null;
    return (
      <StyledGridColumnMobileImg floated="right">
        <OnlyMobileCSS
          style={{
            width: 'calc(100% + 36px)',
            height: 'auto',
            margin: '0 -18px',
          }}
        >
          <StyledImage alt="" src={block.image} />
        </OnlyMobileCSS>
      </StyledGridColumnMobileImg>
    );
  };

  return (
    <>
      <StyledImageDiv>
        {isImage && (
          <Grid columns="2">
            <StyledGridRow>
              <Grid.Row>{!isLeftAlignment && renderDesktopImage()}</Grid.Row>
              {!isLeftAlignment && renderHeaderBody()}
              <StyledColumn>{isLeftAlignment && renderHeaderBody()}</StyledColumn>
              <OnlyMobileCSS>
                <Grid.Column>{renderMobileImage()}</Grid.Column>
              </OnlyMobileCSS>
              <StyledFlexColumn>{isLeftAlignment && renderDesktopImage()}</StyledFlexColumn>
            </StyledGridRow>
          </Grid>
        )}
      </StyledImageDiv>

      {!isImage && (
        <StyledHeaderDescription data-is-bg-dark={isBgColorDark} data-is-single-column={!isImage}>
          {block.header && (
            <StyledHeading
              as={headingType}
              dangerouslySetInnerHTML={{ __html: block?.header }}
              data-is-single-column={!isBgColorDark}
              isBgColorDark={isBgColorDark}
              tabIndex={0}
            />
          )}
          {block.description && (
            <StyledParagraph
              dangerouslySetInnerHTML={{ __html: block?.description }}
              isBgColorDark={isBgColorDark}
              tabIndex={0}
            />
          )}
        </StyledHeaderDescription>
      )}
    </>
  );
};

const StyledGridRow = styled(Grid.Row)`
  justify-content: center !important;
`;

const StyledColumn = styled(Grid.Column)`
  &&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props => props.theme.pxToRem(16)} 0 0 0 !important;
    }
  }
`;

const StyledGridColumnMobileImg = styled(Grid.Column)`
  &&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: center;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
`;

const StyledGridColumnImage = styled(Grid.Column)<{ isLeftAlignment: boolean }>`
  &&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: center;
      padding: 0 0 0 0 !important;
      display: flex;
    }
    margin: ${props => (props.isLeftAlignment ? 'auto' : '-14%')};
    padding: 0 0 0 0;
    display: flex;
  }
`;

const StyledGridColumnHeader = styled(Grid.Column)<{ isLeftAlignment: boolean }>`
  &&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: 0 0 0 0 !important;
    }
    margin: ${props => (props.isLeftAlignment ? '0' : `${props.theme.pxToRem(-20)} 4% -1% 24%`)};
    padding: 0 0 0 0;
  }
`;

const StyledImageDiv = styled.div`
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: 100%;
  }
`;

const StyledHeaderDescription = styled.div<{ 'data-is-bg-dark': boolean; 'data-is-single-column': boolean }>`
  .rich-text-external-link {
    ${props => (props['data-is-bg-dark'] ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${props => (props['data-is-bg-dark'] ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }

  color: ${props => (props['data-is-bg-dark'] ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};

  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }

  ul,
  ol {
    display: inline-block;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 0;
  }
`;

const StyledHeading = styled(Heading)<{ 'data-is-single-column': boolean; isBgColorDark: boolean }>`
  margin-bottom: ${props => (props['data-is-single-column'] ? props.theme.pxToRem(16) : props.theme.pxToRem(24))};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.38;
  padding-top: 2%;
  letter-spacing: normal;
  font-stretch: normal;
  p {
    line-height: 1.38;
    margin: 0;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: left;
    width: ${props => props.theme.pxToRem(376)} !important;
    height: ${props => props.theme.pxToRem(88)} !important;
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.xl};
  }
`;

const StyledParagraph = styled.div<{
  isBgColorDark: boolean;
  isLeftAlignment?: boolean;
}>`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  overflow: hidden;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  p {
    line-height: normal;
  }

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: left;
    p:first-child {
      margin-top: 0;
    }

    font-size: ${props => props.theme.fontSizes.s};
  }
`;

const StyledImage = styled.img<{
  isFullPagePagination?: boolean;
  blockHeight?: string;
  imgWidthDesktop?: boolean;
}>`
  align-self: center;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto -1.5% auto;
  max-height: ${props => props.theme.pxToRem(272)};
  max-width: ${props => props.theme.pxToRem(550)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: auto;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const StyledFlexColumn = styled(Grid.Column)`
  display: flex !important;
`;
