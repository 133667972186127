import { DefaultPagination } from 'constants/index';

// ------------------------------------
// Constants
// ------------------------------------
export const initialState: State.Content = {
  isContentFetched: false,
  isRelatedContentFetched: false,
  contentHash: null,
  meta: {
    page: DefaultPagination.List.PAGE,
    perPage: DefaultPagination.List.MIN_PER_PAGE,
    total: 0,
    aggs: null,
  },
  relatedContent: null,
  aggsPageContent: null,
  toolkitPageContent: null,
  productAggPageContent: {
    title: '',
    metaTitle: '',
    metaDescription: '',
    summary: '',
    mainSectionItems: null,
    mainSectionSlugs: [],
    mainSectionProductTypesAndPromotionalComponents: [],
    ogImage: '',
    externalAdsConfig: {},
    image: '',
  },
  error: null,
  loading: false,
  skipContentFrontloadRequest: false,
  brandImages: {},
  blogsContentHash: null,
  blogsMeta: {
    page: DefaultPagination.List.PAGE,
    perPage: DefaultPagination.List.MIN_PER_PAGE,
    total: 0,
    aggs: null,
  },
  blogsLoading: false,
  isComingFromFcmaPropPageJourney: false,
  downloadDocuments: [],
  rawRichTextContent: null,
  promotedProducts: [],
};

export const LOGIN_IMAGE_KEY = 'login';

export const TERMS_RICH_TEXT_SLUG = 'terms-conditions';
