import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { getPath, getPaperBillingPath } from 'utils/routes';
import {
  applicationSelector,
  customerProfileFetchedSelector,
  applicationInitialLoadSelector,
  isUserMemberSelector,
  isUserMemberSuspendedSelector,
  learningPathwaySelector,
  learningPathwayToSwitchSelector,
  cimaMembershipsTermTypeSelector,
  isEPA1CompletedSelector,
  isEPA2CompletedSelector,
  isAicpaMemberSelector,
  isUserMemberLapsedSelector,
} from 'modules/user/selectors';
import { hasMembershipProductSelector, cartCredentialsSelector } from 'modules/cart/selectors';
import { ApplicationProgressRoutesMap, getApplicationProgressRoute, Routes } from 'constants/index';
import { useLocation } from 'react-router';
import { toggleApplicationInitialLoad } from 'modules/user/actions';
import { Product, User, MembershipTypes } from 'mxp-schemas';
import { Admin as AdminUtils } from 'mxp-utils';
import { isPaperBillingSelector } from 'modules/startup/selectors';
import {
  isCimaMembershipJourneySelector,
  membershipInviteDataSelector,
  isFLPUpgradeSelector,
  isFLPSwitchSelector,
  selectedMembershipTierCodeSelector,
  practicalExperienceRequirementStatusSelector,
  clickedMembershipUpgradeSelector,
  isCimaMembershipLapsedSelector,
  isRegularUpgradingToRetiredSelector,
} from 'modules/membership/selectors';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_NEW_MEMBERSHIP_AICPA } from 'modules/featureToggle/constants';

export const useApplicationProgress = (membershipApplicationType: Product.MembershipApplicationType) => {
  const application = useSelector(applicationSelector);
  const hasMembershipProduct = useSelector(hasMembershipProductSelector);
  const customerProfileFetched = useSelector(customerProfileFetchedSelector);
  const applicationInitialLoad = useSelector(applicationInitialLoadSelector);
  const isUserMember = useSelector(isUserMemberSelector);
  const isUserMemberSuspended = useSelector(isUserMemberSuspendedSelector);
  const isPaperBilling = useSelector(isPaperBillingSelector);
  const cartCredentials = useSelector(cartCredentialsSelector);
  const isCimaMembershipJourney = useSelector(isCimaMembershipJourneySelector);
  const learningPathway = useSelector(learningPathwaySelector);
  const learningPathwayToSwitch = useSelector(learningPathwayToSwitchSelector);
  const inviteData = useSelector(membershipInviteDataSelector);
  const isFLPSwitch = useSelector(isFLPSwitchSelector);
  const isFLPUpgrade = useSelector(isFLPUpgradeSelector);
  const currentMembershipKey = useSelector(cimaMembershipsTermTypeSelector);
  const tierCode = useSelector(selectedMembershipTierCodeSelector);
  const isEPA1Completed = useSelector(isEPA1CompletedSelector);
  const isEPA2Completed = useSelector(isEPA2CompletedSelector);
  const isAicpaMember = useSelector(isAicpaMemberSelector);
  const perStatus = useSelector(practicalExperienceRequirementStatusSelector);
  const isForUpgrade = useSelector(clickedMembershipUpgradeSelector);
  const isUserMemberLapsed = useSelector(isUserMemberLapsedSelector);
  const isCimaMemTypeAff = currentMembershipKey === User.MembershipTypes.AFFILIATE;
  const isCimaMemTypCgmaAff = currentMembershipKey === User.MembershipTypes.CGMA_AFFILIATE;
  const isPERStatusCompleted = perStatus === MembershipTypes.PERPortfolioStatus.COMPLETED;
  const isUpgradeFlpToRegular = learningPathway === MembershipTypes.Pathway.FLP && isPERStatusCompleted;
  const isUpgradeToRegular =
    (learningPathway === MembershipTypes.Pathway.APPRENTICE_L7 && (isEPA1Completed || isEPA2Completed)) ||
    (isForUpgrade && isCimaMemTypeAff && tierCode === MembershipTypes.TierCode.CORE && isPERStatusCompleted);

  const isFlpSwitchOrUpgrade = isFLPSwitch || isFLPUpgrade;

  const isSubscribedToApprenticeship = AdminUtils.isApprenticePathway(learningPathway as string);
  const isSubscribedToFlp = AdminUtils.isFlpPathway(learningPathway as string);
  const isSubscribedToChinesePq = AdminUtils.isChinesePathway(learningPathway as string);
  const isRegularUpgradingToRetired = useSelector(isRegularUpgradingToRetiredSelector);
  const isCimaMembershipLapsed = useSelector(isCimaMembershipLapsedSelector);
  const useNewMembershipAICPA = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_NEW_MEMBERSHIP_AICPA)
  );

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    const { applicationProgress } = application;

    if (customerProfileFetched) {
      if (applicationInitialLoad && pathname !== getPath(Routes.MEMBERSHIP_FORM)) {
        if (inviteData.inviteId) {
          dispatch(
            push(
              getPath(getApplicationProgressRoute(User.MembershipApplicationStages.START, membershipApplicationType))
            )
          );
          dispatch(toggleApplicationInitialLoad());
          return;
        }
        if (isFlpSwitchOrUpgrade) {
          dispatch(
            push(
              getPath(getApplicationProgressRoute(User.MembershipApplicationStages.PERSONAL, membershipApplicationType))
            )
          );
          dispatch(toggleApplicationInitialLoad());
          return;
        }
        if (isCimaMembershipJourney && learningPathway && learningPathwayToSwitch) {
          if (
            pathname ===
            getPath(getApplicationProgressRoute(User.MembershipApplicationStages.PERSONAL, membershipApplicationType))
          ) {
            dispatch(toggleApplicationInitialLoad());
            return;
          }
          dispatch(
            push(
              getPath(getApplicationProgressRoute(User.MembershipApplicationStages.PERSONAL, membershipApplicationType))
            )
          );
          dispatch(toggleApplicationInitialLoad());
          return;
        }
        if (
          (isUserMember || isUserMemberSuspended || isCimaMembershipLapsed || isUserMemberLapsed) &&
          !isFlpSwitchOrUpgrade
        ) {
          if ((isUpgradeToRegular || isUpgradeFlpToRegular) && isForUpgrade) {
            if (isAicpaMember && isSubscribedToFlp && isPERStatusCompleted) {
              dispatch(
                push(
                  getPaperBillingPath(
                    getApplicationProgressRoute(User.MembershipApplicationStages.DONATION, membershipApplicationType),
                    isPaperBilling
                  )
                )
              );
            } else {
              dispatch(
                push(
                  getPaperBillingPath(
                    getApplicationProgressRoute(User.MembershipApplicationStages.PERSONAL, membershipApplicationType),
                    isPaperBilling
                  )
                )
              );
            }
          } else if (isSubscribedToApprenticeship) {
            dispatch(
              push(
                getPaperBillingPath(
                  getApplicationProgressRoute(User.MembershipApplicationStages.ATTESTATION, membershipApplicationType),
                  isPaperBilling
                )
              )
            );
          } else if (isSubscribedToFlp || isSubscribedToChinesePq || isCimaMemTypCgmaAff) {
            dispatch(
              push(
                getPath(
                  getApplicationProgressRoute(User.MembershipApplicationStages.DONATION, membershipApplicationType)
                )
              )
            );
          } else if (isCimaMembershipJourney && isAicpaMember) {
            dispatch(
              push(
                getPaperBillingPath(
                  getApplicationProgressRoute(User.MembershipApplicationStages.PERSONAL, membershipApplicationType),
                  isPaperBilling
                )
              )
            );
          } else if (isRegularUpgradingToRetired) {
            dispatch(
              push(
                getPaperBillingPath(
                  getApplicationProgressRoute(User.MembershipApplicationStages.PERSONAL, membershipApplicationType),
                  isPaperBilling
                )
              )
            );
          } else if (hasMembershipProduct) {
            dispatch(
              push(
                getPath(
                  getApplicationProgressRoute(User.MembershipApplicationStages.DONATION, membershipApplicationType)
                )
              )
            );
          } else if (cartCredentials) {
            dispatch(
              push(
                getPath(
                  getApplicationProgressRoute(User.MembershipApplicationStages.ATTESTATION, membershipApplicationType)
                )
              )
            );
          } else {
            dispatch(
              push(
                getPaperBillingPath(
                  getApplicationProgressRoute(User.MembershipApplicationStages.START, membershipApplicationType),
                  isPaperBilling
                )
              )
            );
          }
          dispatch(toggleApplicationInitialLoad());
          return;
        }

        if (applicationProgress) {
          const lastSavedPath = membershipApplicationType
            ? getPaperBillingPath(
                getApplicationProgressRoute(
                  applicationProgress as User.MembershipApplicationStages,
                  membershipApplicationType
                ),
                isPaperBilling
              )
            : getPaperBillingPath(ApplicationProgressRoutesMap[applicationProgress], isPaperBilling);

          if (lastSavedPath !== pathname) {
            dispatch(push(lastSavedPath));
          }
          dispatch(toggleApplicationInitialLoad());
          return;
        }

        if (hasMembershipProduct) {
          const initialPath = useNewMembershipAICPA
            ? User.MembershipApplicationStages.PERSONAL
            : User.MembershipApplicationStages.START;
          dispatch(
            push(
              getPaperBillingPath(getApplicationProgressRoute(initialPath, membershipApplicationType), isPaperBilling)
            )
          );
        } else {
          dispatch(
            push(
              getPaperBillingPath(
                getApplicationProgressRoute(
                  User.MembershipApplicationStages.PACKAGE_BUILDER,
                  membershipApplicationType
                ),
                isPaperBilling
              )
            )
          );
        }
      }
      dispatch(toggleApplicationInitialLoad());
    }
  }, [customerProfileFetched]); // eslint-disable-line react-hooks/exhaustive-deps
};
