import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import { setText } from './helpers/setText';
import { setIcon } from './helpers/setIcon';
import { setRowProps } from './helpers/setRowProps';
import { TRenewMembershipDetailsTableData } from 'components/pages/PageRenewYourMembership/types/types';
import { RenewMemberShipCard } from 'components/atoms/RenewMemberShipCard/RenewMemberShipCard';
import { isMobileViewPort } from 'utils';
import { HeadingWithSubtitle } from 'components/atoms';
import { RenewButtonsGroup } from 'components/atoms/RenewButtonsGroup/RenewButtonsGroup';
import { RenewMembershipOptions } from '../../../constants';

export interface IRenewMembershipDetailsTableProps {
  changeToUpdateProcess: () => void;
  renewMembershipDetailsTableData: TRenewMembershipDetailsTableData;
  serviceTierFieldsToBeShown: string[];
}

export const RenewMembershipDetailsTable: FC<IRenewMembershipDetailsTableProps> = ({
  changeToUpdateProcess,
  renewMembershipDetailsTableData,
  serviceTierFieldsToBeShown,
}) => {
  const { accountType, addOns, membershipCredentials, membershipSections, serviceTier } =
    renewMembershipDetailsTableData;

  const isMobile: boolean = isMobileViewPort();

  const renewalCardStatus: { [key: string]: { cardStatus: boolean } } = {
    [RenewMembershipOptions.SERVICE_TIER]: {
      cardStatus: false,
    },
    [RenewMembershipOptions.ACCOUNT_TYPE]: {
      cardStatus: false,
    },
    [RenewMembershipOptions.ADD_ONS]: { cardStatus: false },
    [RenewMembershipOptions.MEMBERSHIP_SECTIONS]: { cardStatus: false },
    [RenewMembershipOptions.MEMBERSHIP_CREDENTIALS]: { cardStatus: false },
  };

  const [renewalCardStatusObj, setRenewalCardStatusObj] = useState(renewalCardStatus);

  return (
    <Container>
      {isMobile ? (
        <HeadingWithSubtitle
          title="Your Current Membership"
          titleColor={`${theme.colors.primaryPurple}`}
          titleFontSize={20}
          marginBottom={24}
          marginTop={24}
        />
      ) : (
        <HeadingWithSubtitle
          title="Step 1: Review membership details"
          subtitle="Stay connected and explore exclusive benefits."
          titleColor={`${theme.colors.primaryPurple}`}
          titleFontSize={24}
          titleFontWeight={700}
          marginBottom={44}
        />
      )}
      {Object.keys(renewMembershipDetailsTableData).map(item => {
        const isRenewMemberShipCardActive = renewalCardStatusObj?.[item]?.cardStatus;
        const isUsedForTier = item === RenewMembershipOptions.SERVICE_TIER;

        const rowProps = setRowProps(item, accountType, addOns, membershipCredentials, membershipSections, serviceTier);
        const headerBackgroundColor =
          isRenewMemberShipCardActive || !rowProps ? `${theme.colors.primaryPurple}` : `${theme.colors.secondaryTeal}`;
        const isUpdateAvailable = !!rowProps && !isRenewMemberShipCardActive;

        return (
          <RenewMemberShipCard
            key={item}
            type={item as RenewMembershipOptions}
            text={setText(item, isMobile)}
            rowProps={rowProps}
            icon={setIcon(item)}
            isUpdateAvailable={isUpdateAvailable}
            isMobile={isMobile}
            headerBackgroundColor={headerBackgroundColor}
            isRenewMemberShipCardActive={isRenewMemberShipCardActive}
            setRenewalCardStatusObj={setRenewalCardStatusObj}
            renewalCardStatusObj={renewalCardStatusObj}
            isUsedForTier={isUsedForTier}
            serviceTierFieldsToBeShown={serviceTierFieldsToBeShown}
          />
        );
      })}
      <RenewButtonsGroupContainer>
        <RenewButtonsGroup changeToUpdateProcess={changeToUpdateProcess} />
      </RenewButtonsGroupContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${props => props.theme.pxToRem(40)};
  width: 66%;

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-left: ${props => props.theme.pxToRem(20)};
  }

  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    width: 100%;
    margin-right: 0;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    margin-right: 0;
  }
`;

const RenewButtonsGroupContainer = styled.div`
  ${props => props.theme.mediaQueries.mobileMaxOnly} {
    margin-bottom: ${props => props.theme.pxToRem(90)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(90)};
  }
`;
