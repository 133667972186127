import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { PageFirmSelection } from 'components/pages/PageFirmSelection/PageFirmSelection';
import { getOrganizations, initFirmRosterDynamoTable } from 'modules/firmAdmin';
import { organizationsListSelector, isOrganizationsFetchedSelector } from 'modules/firmAdmin/selectors';
import { firmBillingClientAdminSelector } from 'modules/user/selectors';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { getRenewalSeason } from 'modules/firmAdmin/actions';

const mapActionCreators = (dispatch: Dispatch) => ({
  getOrganizations(): void {
    dispatch(getOrganizations()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },

  initFirmRosterDynamoTable(orgId: string, path: string, orgCapabilityType: string): Promise<void> {
    return dispatch(initFirmRosterDynamoTable(orgId))
      .then(() => dispatch(push(path)))
      .then(() => dispatch(getRenewalSeason(orgCapabilityType)))
      .catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
});

const mapStateToProps = (state: State.Root) => ({
  organizationsList: organizationsListSelector(state),
  isOrganizationsFetched: isOrganizationsFetchedSelector(state),
  firmBillingClientAdmin: firmBillingClientAdminSelector(state),
});

export const PageFirmSelectionContainer = connect(mapStateToProps, mapActionCreators)(PageFirmSelection);
