import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { PageFirmNavigation } from 'components/pages/PageFirmNavigation/PageFirmNavigation';
import { getFirmAdminOrganizationDetails, getOrganizations } from 'modules/firmAdmin/index';
import { selectedOrganizationSelector, selectedOrganizationIdSelector } from 'modules/firmAdmin/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  getOrganizations(): void {
    dispatch(getOrganizations()).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
  getFirmAdminOrganizationDetails(orgId: string): void {
    dispatch(getFirmAdminOrganizationDetails(orgId));
  },
});

const mapStateToProps = (state: State.Root) => ({
  selectedOrganization: selectedOrganizationSelector(state),
  id: selectedOrganizationIdSelector(state),
});

export const PageFirmNavigationContainer = connect(mapStateToProps, mapActionCreators)(PageFirmNavigation);
