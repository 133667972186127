import React, { useState } from 'react';
import styled from 'styled-components';
import { InputWithValidation } from 'components/molecules';
import { Checkbox, Dropdown } from 'components/atoms';
import { CheckboxProps as SemanticUICheckboxProps, StrictDropdownProps } from 'semantic-ui-react';
import { Utils, InquiryForm as InquiryFormUtils } from 'mxp-utils';
import { emptyArray } from 'utils';
import { InquiryForm } from 'mxp-schemas';

interface InquiryFormB2BProps {
  setFormValid: (valid: boolean) => void;
  form: InquiryForm.B2B;
  setForm: (form: InquiryForm.B2B) => void;
  onFormStart: () => void;
}

export const InquiryFormB2B: React.FC<InquiryFormB2BProps> = ({ form, setForm, setFormValid, onFormStart }) => {
  const [isStateDropdownDisabled, setStateDropdownDisabled] = useState(true);
  const [stateOptions, setStateOptions] = useState(emptyArray);
  const isStateOptions = React.useCallback(() => {
    return Boolean(stateOptions?.length);
  }, [stateOptions]);

  const isFirstNameEmpty = !Boolean(form.firstName.length);
  const isFirstNameValid = Utils.isShorterThan(form.firstName, InquiryForm.FieldLengths.FIRST_NAME);
  const isLastNameEmpty = !Boolean(form.lastName.length);
  const isLastNameValid = Utils.isShorterThan(form.lastName, InquiryForm.FieldLengths.LAST_NAME);
  const isEmailEmpty = !Boolean(form.email.length);
  const isEmailValid =
    Utils.isEmailFormatValid(form.email) && Utils.isShorterThan(form.email, InquiryForm.FieldLengths.EMAIL);
  const isCountryEmpty = !Boolean(form.countryCode.length);
  const isStateEmpty = !Boolean(form.stateCode.length);
  const isTitleEmpty = !Boolean(form.title.length);
  const isTitleValid = Utils.isShorterThan(form.title, InquiryForm.FieldLengths.TITLE);
  const isCompanyEmpty = !Boolean(form.company.length);
  const isCompanyValid = Utils.isShorterThan(form.company, InquiryForm.FieldLengths.COMPANY);
  const isPhoneEmpty = !Boolean(form.phone.length);
  const isPhoneValid = Utils.isShorterThan(form.phone, 255);
  const isCommentsEmpty = !Boolean(form.comments.length);
  const isCommentsValid = Utils.isShorterThan(form.comments, InquiryForm.FieldLengths.COMMENTS);

  React.useEffect(() => {
    const formValid =
      !isEmailEmpty &&
      isEmailValid &&
      !isFirstNameEmpty &&
      isFirstNameValid &&
      !isLastNameEmpty &&
      isLastNameValid &&
      !isCountryEmpty &&
      (!isStateOptions() || !isStateEmpty) &&
      !isTitleEmpty &&
      isTitleValid &&
      !isCompanyEmpty &&
      isCompanyValid &&
      (isPhoneEmpty || isPhoneValid) &&
      (isCommentsEmpty || isCommentsValid);
    setFormValid(formValid);
  }, [
    form,
    isEmailValid,
    isEmailEmpty,
    isFirstNameEmpty,
    isFirstNameValid,
    isLastNameEmpty,
    isLastNameValid,
    isCountryEmpty,
    isStateOptions,
    isStateEmpty,
    isTitleEmpty,
    isTitleValid,
    isCompanyEmpty,
    isCompanyValid,
    isPhoneEmpty,
    isPhoneValid,
    isCommentsEmpty,
    isCommentsValid,
    setFormValid,
  ]);

  React.useEffect(() => {
    !isStateOptions() ? setStateDropdownDisabled(true) : setStateDropdownDisabled(false);
  }, [isStateOptions]);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, firstName: event.target.value });
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, lastName: event.target.value });
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, email: event.target.value });
  };
  const handleCountryDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedCountry: string = data.value as string;
      setForm({ ...form, countryCode: selectedCountry, stateCode: '' });
      setStateOptions(InquiryFormUtils.getStatesByCountry(selectedCountry));
    },
    [setForm, form]
  );
  const handleStateDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedState: string = data.value as string;
      setForm({ ...form, stateCode: selectedState });
    },
    [setForm, form]
  );
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, title: event.target.value });
  };
  const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, company: event.target.value });
  };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, phone: event.target.value });
  };
  const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, comments: event.target.value });
  };
  const handleMarketingOptInChange = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      setForm({ ...form, marketingOptIn: Boolean(data.checked) });
    },
    [setForm, form]
  );
  const fieldRequired = 'Required';
  const fieldMaxLength = (length: number) => `Sorry, that's too long. Must be fewer than ${length} characters.`;
  return (
    <>
      <InputWithValidation
        labelName={'First Name'}
        name={InquiryForm.FieldIds.FIRST_NAME}
        type="text"
        value={form.firstName}
        testId={InquiryForm.FieldIds.FIRST_NAME}
        labelId={InquiryForm.FieldIds.FIRST_NAME}
        onChange={handleFirstNameChange}
        onClick={onFormStart}
        isCorrect={isFirstNameValid || isFirstNameEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.FIRST_NAME)}`}
      />
      <InputWithValidation
        labelName={'Last Name'}
        name={InquiryForm.FieldIds.LAST_NAME}
        type="text"
        value={form.lastName}
        testId={InquiryForm.FieldIds.LAST_NAME}
        labelId={InquiryForm.FieldIds.LAST_NAME}
        onChange={handleLastNameChange}
        isCorrect={isLastNameValid || isLastNameEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.LAST_NAME)}`}
      />
      <InputWithValidation
        labelName={'Business Email Address'}
        name={InquiryForm.FieldIds.EMAIL}
        type="email"
        value={form.email}
        testId={InquiryForm.FieldIds.EMAIL}
        labelId={InquiryForm.FieldIds.EMAIL}
        onChange={handleEmailChange}
        isCorrect={isEmailValid || isEmailEmpty}
        infoMessage={fieldRequired}
        errorMessage={'Please enter a valid email address.'}
        isCorrectIconShown
      />
      <DropDownContainer>
        <Label>Country/Region</Label>
        <StyledDropdown
          testId={InquiryForm.FieldIds.COUNTRY_CODE}
          onChange={handleCountryDropdownChange}
          options={InquiryFormUtils.CountriesList}
          value={form.countryCode}
          placeholder="Select country/region"
          search={true}
        />
      </DropDownContainer>
      <DropDownContainer>
        <Label>State/Province</Label>
        <StyledDropdown
          testId={InquiryForm.FieldIds.STATE_CODE}
          onChange={handleStateDropdownChange}
          options={stateOptions}
          value={form.stateCode}
          placeholder="Select state/province"
          search={true}
          disabled={isStateDropdownDisabled}
        />
      </DropDownContainer>
      <InputWithValidation
        labelName={'Job Title'}
        name={InquiryForm.FieldIds.TITLE}
        type="text"
        value={form.title}
        testId={InquiryForm.FieldIds.TITLE}
        labelId={InquiryForm.FieldIds.TITLE}
        onChange={handleTitleChange}
        isCorrect={isTitleValid || isTitleEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.TITLE)}`}
      />
      <InputWithValidation
        labelName={'Name of Organization'}
        name={InquiryForm.FieldIds.COMPANY}
        type="text"
        value={form.company}
        testId={InquiryForm.FieldIds.COMPANY}
        labelId={InquiryForm.FieldIds.COMPANY}
        onChange={handleCompanyChange}
        isCorrect={isCompanyValid || isCompanyEmpty}
        infoMessage={fieldRequired}
        errorMessage={`${fieldMaxLength(InquiryForm.FieldLengths.COMPANY)}`}
      />
      <InputWithValidation
        labelName={'Phone Number'}
        name={InquiryForm.FieldIds.PHONE}
        type="text"
        value={form.phone}
        testId={InquiryForm.FieldIds.PHONE}
        labelId={InquiryForm.FieldIds.PHONE}
        onChange={handlePhoneChange}
        isCorrect={isPhoneValid || isPhoneEmpty}
        errorMessage={''}
      />
      <InputWithValidation
        labelName={'Other / Comments'}
        name={InquiryForm.FieldIds.COMMENTS}
        type="text"
        value={form.comments}
        testId={InquiryForm.FieldIds.COMMENTS}
        labelId={InquiryForm.FieldIds.COMMENTS}
        onChange={handleCommentsChange}
        isCorrect={isCommentsValid || isCommentsEmpty}
        errorMessage={fieldMaxLength(InquiryForm.FieldLengths.COMMENTS)}
      />
      <StyledCheckbox
        width="18"
        height="18"
        type="checkbox"
        testId={InquiryForm.FieldIds.MARKETING_OPT_IN}
        label={'Sign me up for group-learning marketing emails'}
        checked={form.marketingOptIn}
        onChange={handleMarketingOptInChange}
      />
    </>
  );
};

export const StyledCheckbox = styled(Checkbox)`
  &&&&& {
    object-fit: contain;
    line-height: 1;
    margin-top: ${props => props.theme.pxToRem(5)};
    margin-bottom: ${props => props.theme.pxToRem(23)};

    > label {
      font-size: ${props => props.theme.fontSizes.xs};
      color: ${props => props.theme.colors.neutralGrey8};
      line-height: 1.57;
      font-weight: ${props => props.theme.fontWeights.light};
      padding-left: ${props => props.theme.pxToRem(24)};
    }
  }
`;

const DropDownContainer = styled.div`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(26)};
  }
`;

const Label = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      width: 100%;
      height: ${props.theme.pxToRem(48)};
      padding-right: ${props.theme.pxToRem(10)};
      background-color: ${props.theme.colors.neutralWhite};

      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }

      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.primaryPurple};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }

      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.primaryPurple};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }

      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.interfaceBlue};
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
    }
  `}
`;
