import { handleActions, Action } from 'redux-actions';
import { tuitionProviderActionNames, initialState } from './constants';
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [tuitionProviderActionNames.UPDATE_APPRENTICE_GATEWAY_DETAILS]: {
    next: (state: State.TuitionProvider, action: Action<{ success: boolean;}>) => {
      return {
        ...state,
        updateApprenticeGatewayDetailsSuccess: action.payload.success
      }
    },
  },
  [tuitionProviderActionNames.GET_PERSONAL_DETAILS]: {
    next: (state: State.TuitionProvider, action: any) => {
      if (!action.payload) {
        return {
          ...state,
          getPersonalDetailsSuccess: false
        }
      } else {
        return {
          ...state,
          getStudentDetails: action.payload,
          getPersonalDetailsSuccess: true
        }
      }
    },
  },
  [tuitionProviderActionNames.SET_LOADING]: {
    next: (state: State.TuitionProvider, action: any) => ({
      ...state,
      loading: action.payload
    }),
  },
  [tuitionProviderActionNames.SET_UPDATE_LOADING]: {
    next: (state: State.TuitionProvider, action: any) => ({
      ...state,
      updateLoading: action.payload
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
