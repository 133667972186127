import React from 'react';

interface SurveyCheckProps {
  onClick?: () => void;
  color?: string;
  size?: number;
}

export const SurveyCheck: React.FC<SurveyCheckProps> = ({ onClick, color = '#48A23E', size = 52 }) => {
  return (
    <svg width={size} height={size} viewBox="-5 -15 52 52" onClick={onClick}>
      <path fill={color} d="M12.5 23.035L3.465 14 .388 17.055 12.5 29.167l26-26L35.445.112z" />
    </svg>
  );
};
