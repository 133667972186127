import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { OnlyDesktop } from 'components/atoms/ResponsiveHelpers/ResponsiveHelpers';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { BackToTop as BackToTopIcon } from 'components/atoms/svg';
import { isServer } from 'utils';

interface Props {
  showBelow?: number;
  modalOptions?: {
    scrollPositionRef: any;
    scrollIntoRef: any;
    scrollBelowRef: any;
  };
}

export const BackToTop: React.FC<Props> = ({ showBelow = 0, modalOptions }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isServer) return;

    const scrollArea = !modalOptions ? window : modalOptions.scrollPositionRef?.current;

    scrollArea.addEventListener('scroll', handleScroll);

    return () => {
      scrollArea.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = () => {
    if (isServer) return;

    const scrollPosition = !modalOptions ? window.pageYOffset : modalOptions.scrollPositionRef?.current?.scrollTop;
    const scrollPositionBelow = !modalOptions ? showBelow : modalOptions.scrollBelowRef?.current?.scrollHeight;

    if (!visible && scrollPosition > scrollPositionBelow) {
      setVisible(true);
    } else if (visible && scrollPosition <= scrollPositionBelow) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    if (isServer) return;

    if (!modalOptions) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      modalOptions.scrollIntoRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <StyledButton
      testId="back-to-top"
      variant={ButtonEnums.variants.cloud}
      onClick={scrollToTop}
      icon={<BackToTopIcon />}
      iconPosition={ButtonEnums.iconPosition.left}
      aria-label="Back to the top"
      isVisible={visible}
    >
      <OnlyDesktop>Back to the top</OnlyDesktop>
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{ isVisible: boolean }>`
  &&&&& {
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(25)};
    left: 50%;
    transform: translate(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: ${({ theme }) => `${theme.pxToRem(12)} ${theme.pxToRem(18)} ${theme.pxToRem(12)} ${theme.pxToRem(14)}`};
    margin: 0;
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    > svg {
      margin-right: ${({ theme }) => theme.pxToRem(2)};
    }

    ${({ theme }) => theme.mediaQueries.mobileOnly} {
      padding: ${({ theme }) => `${theme.pxToRem(8)}`};
      right: ${({ theme }) => theme.pxToRem(15)};
      bottom: ${({ theme }) => theme.pxToRem(80)};
      left: unset;
      transform: unset;

      > svg {
        margin-right: 0;
      }
    }
  }
`;
