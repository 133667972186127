import React from 'react';
import { matchPath, Redirect, RouteComponentProps, generatePath } from 'react-router-dom';
import { SingleColumnPageTemplate } from 'components/templates/SingleColumn/SingleColumn';
import { Grid, Paragraph, ButtonLink, ButtonEnums, Link, Container } from 'components/atoms';
import styled from 'styled-components';
import { Routes } from 'constants/index';
import { getPath } from 'utils';

export const PageInvoiceError: React.FC<RouteComponentProps> = ({ location }) => {
  if (
    !(
      (location?.state as any)?.invoiceCheckoutError ||
      (location?.state as any)?.receiptCheckoutError ||
      (location?.state as any)?.adminInvoicesPaymentError
    )
  ) {
    if (Boolean(matchPath(location?.pathname, { path: getPath(Routes.ADMIN_INVOICES_PAYMENT_ERROR), exact: true }))) {
      return <Redirect to={getPath(Routes.ADMIN_ROOT)} />;
    }
    return <Redirect to={getPath(Routes.PROFILE_ROOT)} />;
  }

  const { invoiceCheckoutError, receiptCheckoutError, adminInvoicesPaymentError, rootOrganizationId } =
    location.state as any;

  const renderButton = (): React.ReactNode => {
    if (invoiceCheckoutError) {
      return (
        <StyledButtonLink
          testId="error-go-to-invoices"
          variant={ButtonEnums.variants.primary}
          size={ButtonEnums.sizes.large}
          to={
            rootOrganizationId
              ? generatePath(getPath(Routes.FIRM_BILLING_TAB_INVOICES), { orgId: rootOrganizationId })
              : getPath(Routes.ORG_INVOICES)
          }
        >
          Go to My Invoices
        </StyledButtonLink>
      );
    }
    if (receiptCheckoutError) {
      return (
        <StyledButtonLink
          testId="error-go-to-receipts"
          variant={ButtonEnums.variants.primary}
          size={ButtonEnums.sizes.large}
          to={getPath(Routes.PROFILE_RECEIPTS)}
        >
          Go to My Receipts
        </StyledButtonLink>
      );
    }
    if (adminInvoicesPaymentError) {
      return (
        <StyledButtonLink
          testId="error-go-to-admin-invoices-search"
          variant={ButtonEnums.variants.primary}
          size={ButtonEnums.sizes.large}
          to={getPath(Routes.ADMIN_INVOICES_SEARCH)}
        >
          Go to Search
        </StyledButtonLink>
      );
    }
  };

  const phoneContact = receiptCheckoutError ? '888-777-7077' : '800-634-6780';
  const emailContact = receiptCheckoutError ? 'service@aicpa.org' : 'client.support@aicpa-cima.com';

  const renderBody = (): React.ReactNode => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column computer={8}>
            <Wrapper>
              <StyledH1 data-testid="error-title">Uh oh. Something went wrong.</StyledH1>
              <StyledParagraph testId="error-sorry-text">
                We're sorry. We can't process your payment at this time.
              </StyledParagraph>
              {!adminInvoicesPaymentError && (
                <StyledParagraph testId="error-please-contact-text">
                  Please contact our dedicated group sales team at&nbsp;
                  <Link isExternal testId="error-phone" to={`tel:${phoneContact}`}>
                    {phoneContact}
                  </Link>
                  ,&nbsp;option 1, or&nbsp;
                  <Link isExternal testId="error-email" to={`mailto:${emailContact}`}>
                    {emailContact}
                  </Link>
                  . We’re open 8am - 5pm Eastern (US time).
                </StyledParagraph>
              )}
              {renderButton()}
            </Wrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <>
      {!adminInvoicesPaymentError ? (
        <SingleColumnPageTemplate>{renderBody()}</SingleColumnPageTemplate>
      ) : (
        <StyledContainer>{renderBody()}</StyledContainer>
      )}
    </>
  );
};

const Wrapper = styled.div`
  margin: ${props => props.theme.pxToRem(40)} 0;
`;

const StyledH1 = styled.h1`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${props => props.theme.pxToRem(22)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.57;
  :last-of-type {
    margin-bottom: ${props => props.theme.pxToRem(32)};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    width: ${props => props.theme.pxToRem(193)};
  }
`;

const StyledContainer = styled(Container)`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: calc(67vh + ${props => props.theme.pxToRem(20)});
  }
`;
