import React from 'react';
import styled, { css } from 'styled-components';
import { Divider } from 'semantic-ui-react';
import { Modal } from 'components/atoms/Modal/Modal';
import { ButtonEnums, Button, RadioButton } from 'components/atoms';

interface Props {
  open: boolean;
  onCancel?: () => void;
  confirmButtonLabel?: string;
  onConfirm: () => void;
  className?: string;
  isConfirmationInProgress?: boolean;
  testId?: string;
  header?: string;
  firmName?: string;
  optionState: OptionState;
  setOptionState: ({ optionValueA, optionValueB }: OptionState) => void;
  setIsUnderReview: React.Dispatch<React.SetStateAction<boolean>>;
}

interface OptionState {
  optionValueA: boolean;
  optionValueB: boolean;
}

export const PartnerDeclareAcknowledgement: React.FC<Props> = ({
  open,
  onCancel,
  testId,
  header,
  firmName = '[Firm Name]',
  setIsUnderReview,
  onConfirm,
  confirmButtonLabel,
  isConfirmationInProgress,
  optionState,
  setOptionState,
}) => {
  const isConfirmValid: boolean = optionState.optionValueA || optionState.optionValueB;

  const handleRadioChange1 = React.useCallback(() => {
    setIsUnderReview(false);
    setOptionState({ ...optionState, optionValueA: true, optionValueB: false });
  }, [optionState, setOptionState, setIsUnderReview]);

  const handleRadioChange2 = React.useCallback(() => {
    setIsUnderReview(true);
    setOptionState({ ...optionState, optionValueA: false, optionValueB: true });
  }, [optionState, setOptionState, setIsUnderReview]);

  const onConfirmEvent = React.useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  const renderButton = isConfirmValid ? (
    <StyledButton
      className="confirm"
      size={ButtonEnums.sizes.small}
      variant={ButtonEnums.variants.primary}
      testId="confirm"
      onClick={onConfirmEvent}
      disabled={isConfirmationInProgress}
      loading={isConfirmationInProgress}
      type="button"
    >
      {confirmButtonLabel}
    </StyledButton>
  ) : (
    <StyledButton disabled={true} testId="confirm">
      {confirmButtonLabel}
    </StyledButton>
  );

  return (
    <StyledModal
      className="partners-modal"
      open={open}
      onClose={onCancel}
      size="small"
      heading={header}
      testId={testId}
      showCloseCross
      confirmNode={renderButton}
    >
      <StyledParagraph>
        Please acknowledge either A or B below are true for <StyledBold>{firmName}</StyledBold>
      </StyledParagraph>
      <StyledCustomSpacing />

      <FlexWrapRadio onClick={handleRadioChange1}>
        <StyledRadioButton checked={optionState.optionValueA} />
        <StyledOptionText bold={optionState.optionValueA}>
          All items below are true and apply to you and the firm are your creating
        </StyledOptionText>
      </FlexWrapRadio>

      <FlexWrapRadio onClick={handleRadioChange2}>
        <StyledRadioButton checked={optionState.optionValueB} />
        <StyledOptionText bold={optionState.optionValueB}>
          1 or more items below is not true regarding you and/or the firm you are creating
        </StyledOptionText>
      </FlexWrapRadio>

      <StyledList>
        <StyledListItem>
          <StyledParagraph>
            I am a Partner of <StyledBold>this firm</StyledBold> or other individual with authority to bind the firm
            with respect to the performance of a professional services engagement,
          </StyledParagraph>
        </StyledListItem>

        <StyledListItem>
          <StyledParagraph>
            <StyledBold>This firm</StyledBold> is <StyledBold>not currently enrolled</StyledBold> in a practice
            monitoring program (e.g., peer review),
          </StyledParagraph>
        </StyledListItem>

        <StyledListItem>
          <StyledParagraph>
            <StyledBold>This firm</StyledBold> is <StyledBold>not the result</StyledBold> of the purchase, sale, merger,
            or dissolution of an existing public accounting firm, currently enrolled in a practice monitoring program
            (e.g., peer review) ,
          </StyledParagraph>
        </StyledListItem>

        <StyledListItem>
          <StyledParagraph>
            <StyledBold>This firm</StyledBold> has <StyledBold>not performed in the past 12 months</StyledBold>, does
            not <StyledBold>currently perform</StyledBold>, and does not <StyledBold> intend to perform</StyledBold>,
            any of the following types of engagements:
          </StyledParagraph>
        </StyledListItem>

        <StyledList type="a">
          <StyledListItem isSubItem={true}>
            <StyledParagraph>
              Engagements under the <StyledBold>Statements on Auditing Standards (SASs)</StyledBold>: e.g., audits;
            </StyledParagraph>
          </StyledListItem>

          <StyledListItem isSubItem={true}>
            <StyledParagraph>
              Engagements under <StyledBold>Government Auditing Standards (GAS)</StyledBold>: e.g., financial audits,
              attestation engagements (examination, review, or agreed-upon procedures) under GAS;
            </StyledParagraph>
          </StyledListItem>

          <StyledListItem isSubItem={true}>
            <StyledParagraph>
              Examinations under the{' '}
              <StyledBold>Statements on Standards for Attestation Engagements (SSAEs)</StyledBold>: e.g., examination
              engagements, review engagements, agreed-upon procedure engagements;
            </StyledParagraph>
          </StyledListItem>

          <StyledListItem isSubItem={true}>
            <StyledParagraph>
              Engagements under <StyledBold>PCAOB standards</StyledBold>: e.g., audits of non-SEC Issuers, attestation
              of non-SEC Issuers;
            </StyledParagraph>
          </StyledListItem>

          <StyledListItem isSubItem={true}>
            <StyledParagraph>
              Services under{' '}
              <StyledBold>Statements on Standards for Accounting and Review Services (SSARSs)</StyledBold>: e.g.,
              reviews of financial statements, compilations, preparations of financial statement engagements; or
            </StyledParagraph>
          </StyledListItem>

          <StyledListItem isSubItem={true}>
            <StyledParagraph>
              Services under the <StyledBold>SSAEs</StyledBold> not listed above.
            </StyledParagraph>
          </StyledListItem>
        </StyledList>

        <StyledListItem>
          <StyledParagraph>
            None of the owners, Partners, or other individuals with authority to bind <StyledBold>this firm</StyledBold>{' '}
            with respect to the performance of a professional services engagement are currently associated with other
            firms that perform the types of engagements listed above.
          </StyledParagraph>
        </StyledListItem>
      </StyledList>

      <StyledDivider />
      <StyledList>
        <StyledListItem isSecondary={true}>
          <StyledEndParagraph>
            If your firm is currently enrolled in the AICPA Peer Review Program or a state society sponsored program
            using the AICPA Standards for Performing and Reporting on Peer Reviews your firm should already exist,
            please contact X for assistance in locating your firm.
          </StyledEndParagraph>
        </StyledListItem>

        <StyledListItem isSecondary={true}>
          <StyledEndParagraph>
            If this firm is the result of the purchase, sale, merger, or dissolution of an existing public accounting
            firm, please complete the Peer Review Firm Change Form and New Firm Creation forms and submit them to your
            Administering Entity as instructed in those documents.
          </StyledEndParagraph>
        </StyledListItem>
      </StyledList>
    </StyledModal>
  );
};

interface StyledOptionTextProps {
  bold: boolean;
}

interface StyledListItemProps {
  isSubItem?: boolean;
  isSecondary?: boolean;
}

const StyledModal = styled(Modal)`
  position: relative;
  box-sizing: border-box;

  &&&&&&& {
    border-radius: ${props => props.theme.pxToRem(2)};
    width: ${props => props.theme.pxToRem(590)};
    box-sizing: border-box;
    padding-left: ${props => props.theme.pxToRem(28)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 90%;
    }

    .header {
      line-height: 1.33;
      margin-top: ${props => props.theme.pxToRem(12)};
      h2 {
        margin-top: ${props => props.theme.pxToRem(17)};
      }
    }

    .content {
      margin: ${props => props.theme.pxToRem(5)} auto auto;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }

    p {
      max-width: 100%;
    }
    .actions {
      padding: ${props => `${props.theme.pxToRem(20)}`} 0 0 !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        flex-direction: column-reverse;
        justify-content: center;
      }
    }

    button {
      position: absolute;
      top: ${props => props.theme.pxToRem(24)};
      right: ${props => props.theme.pxToRem(24)};
    }

    div > button {
      position: relative;
      top: 0;
      right: 0;
      width: ${props => props.theme.pxToRem(202)};
      height: ${props => props.theme.pxToRem(34)};
      padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};

      margin: auto ${props => props.theme.pxToRem(6)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100% !important;
        height: ${props => `${props.theme.pxToRem(40)}`};
        max-width: ${props => `${props.theme.pxToRem(296)}`};
        font-size: ${props => props.theme.fontSizes.s};
        border-radius: ${props => `${props.theme.pxToRem(4)}`};
        margin: 0 auto !important;
      }
    }
  }
`;

const StyledParagraph = styled.span`
  margin-top: ${props => props.theme.pxToRem(20)};
  line-height: 1.5;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
  text-align: justify;
`;

const StyledEndParagraph = styled.span`
  margin-top: ${props => props.theme.pxToRem(30)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  text-align: justify;
  color: ${props => props.theme.colors.neutralGrey4};
`;

const StyledList = styled.ol`
  margin: ${props => props.theme.pxToRem(5)};
  padding: ${props => props.theme.pxToRem(5)};
`;

const StyledListItem = styled.li<StyledListItemProps>`
  margin-top: ${props => props.theme.pxToRem(10)};
  font-weight: ${props => props.theme.fontWeights.bold};
  padding-left: ${props => props.theme.pxToRem(10)};

  ${({ isSubItem }) =>
    isSubItem &&
    css`
      margin-left: ${props => props.theme.pxToRem(15)};
    `}

  ${({ isSecondary }) =>
    isSecondary &&
    css`
      font-weight: ${props => props.theme.fontWeights.regular} !important;
      color: ${props => props.theme.colors.neutralGrey4} !important;
      font-size: ${props => props.theme.pxToRem(8)};
      padding-left: 0 !important;
    `}
`;

const StyledBold = styled.span`
  margin-top: ${props => props.theme.pxToRem(20)};
  line-height: 1.5;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const StyledCustomSpacing = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const FlexWrapRadio = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(6)};
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const StyledRadioButton = styled(RadioButton)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  margin: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(12)} 0 0;
  div {
    height: ${props => props.theme.pxToRem(8)};
    width: ${props => props.theme.pxToRem(8)};
  }
`;

const StyledOptionText = styled.span<StyledOptionTextProps>`
  margin-top: ${props => props.theme.pxToRem(12)};
  line-height: 1.5;
  font-size: ${props => props.theme.fontSizes.xs};

  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  :hover {
    font-weight: ${props => props.theme.fontWeights.bold};
  }
  ${({ bold }) =>
    bold
      ? css`
          font-weight: ${props => props.theme.fontWeights.bold};
        `
      : css`
          font-weight: ${props => props.theme.fontWeights.regular};
        `}
`;

const StyledButton = styled(Button)`
  &&&&& {
    min-width: ${props => props.theme.pxToRem(320)};
    object-fit: contain;
    font-size: ${props => props.theme.fontSizes.xs};
    margin: 0 auto;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      font-size: ${props => props.theme.fontSizes.xxs};
    }
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: ${props => props.theme.pxToRem(18)};
  color: ${props => props.theme.colors.neutralGrey1};
`;
