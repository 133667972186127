import React from 'react';
import styled from 'styled-components';
import { useLocation, matchPath } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, ButtonEnums, ButtonLink } from 'components/atoms';
import { showLogin, showRegistration } from 'modules/layouts';
import { Routes } from 'constants/index';
import { getPath, isServer } from 'utils';
import { handleEvent, PREFIXES, HEADER_EVENT, FOOTER_EVENT } from 'utils/Analytics';

import { headerAndFooterEventPayload } from 'utils/Analytics/helpers';

interface Props {
  logInButtonTestId: string;
  registerButtonTestId: string;
  className?: string;
  isStickyButtons?: boolean;
}

export const LogInRegisterButtons: React.FC<Props> = ({
  logInButtonTestId,
  registerButtonTestId,
  className,
  isStickyButtons = false,
}) => {
  const dispatch = useDispatch();

  // click event handling
  const handleClickEvent = React.useCallback(
    (route: Routes) =>
      (e: React.MouseEvent<HTMLButtonElement>): void => {
        if (isServer) return;
        const event = {
          prefix: isStickyButtons ? PREFIXES.FOOTER : PREFIXES.HEADER,
          name: isStickyButtons ? FOOTER_EVENT : HEADER_EVENT,
        };

        const textValue = (e?.target as HTMLButtonElement)?.innerText;
        const sanitizedText = textValue.replace('|', '');
        const payload = headerAndFooterEventPayload(event.prefix, route, sanitizedText);
        handleEvent(payload, event.name);
      },
    [isStickyButtons]
  );

  // login redirection
  const handleShowLoginClick = React.useCallback(
    (route: Routes) =>
      (event: React.MouseEvent<HTMLButtonElement>): void => {
        if (isServer) return;
        handleClickEvent(route)(event);
        dispatch(showLogin({ redirectToLoginPage: true }));
      },
    [dispatch, handleClickEvent]
  );

  // register redirection
  const handleShowRegistrationClick = React.useCallback(
    (route: Routes) =>
      (event: React.MouseEvent<HTMLButtonElement>): void => {
        if (isServer) return;
        handleClickEvent(route)(event);
        dispatch(showRegistration({ redirectToRegistrationPage: true }));
      },
    [dispatch, handleClickEvent]
  );

  const location = useLocation();
  const path: string = location.pathname;
  const isCategoryPage = Boolean(matchPath(path, { path: getPath(Routes.CATEGORY_AGGS_PAGE_CIMA), exact: false }));

  return (
    <LogInRegisterContainer className={className}>
      <StyledButtonWrapper>
        {isCategoryPage ? (
          <StyledButton
            to={getPath(Routes.MEMBERSHIP_FORM)}
            testId="membership"
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.primary}
            bordered
          >
            Become a Member
          </StyledButton>
        ) : (
          <Button
            testId={logInButtonTestId}
            variant={ButtonEnums.variants.primary}
            size={ButtonEnums.sizes.medium}
            onClick={handleShowLoginClick(Routes.LOGIN)}
            overrideAnalytics={true}
            bordered
            fluid
          >
            Log in
          </Button>
        )}
        {isCategoryPage ? (
          <Button
            testId={logInButtonTestId}
            variant={ButtonEnums.variants.secondary}
            size={ButtonEnums.sizes.medium}
            onClick={handleShowLoginClick(Routes.LOGIN)}
            overrideAnalytics={true}
            bordered
            fluid
          >
            Log in/Register
          </Button>
        ) : (
          <Button
            testId={registerButtonTestId}
            variant={ButtonEnums.variants.secondary}
            size={ButtonEnums.sizes.medium}
            onClick={handleShowRegistrationClick(Routes.REGISTRATION)}
            overrideAnalytics={true}
            bordered
            fluid
          >
            Register
          </Button>
        )}
      </StyledButtonWrapper>
    </LogInRegisterContainer>
  );
};

const LogInRegisterContainer = styled.div`
  height: 100%;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  &&& > button:first-child {
    margin-right: ${props => props.theme.pxToRem(12)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-right: ${props => props.theme.pxToRem(20)};
    }
  }

  &&& > a:first-child {
    margin-right: ${props => props.theme.pxToRem(12)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-right: ${props => props.theme.pxToRem(20)};
    }
  }
`;

const StyledButton = styled(ButtonLink)`
  &&&& {
    font-size: ${props => props.theme.pxToRem(10)};
    flex-basis: 80%;
  }
`;
