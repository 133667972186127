import React from 'react';
import styled from 'styled-components';

interface ToggleSwitchProps {
  text1: string;
  text2: string;
  testId: string;
  value: number;
  handleChange: () => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ testId, value, text1, text2, handleChange }) => {
  return (
    <StyledDiv data-testid={`switch-${testId}`}>
      <StyledField>
        <input type="radio" id="radio-1" name="radio" value="0" checked={value === 0} onChange={handleChange} />
        <label htmlFor="radio-1">{text1}</label>
        <input type="radio" id="radio-2" name="radio" value="1" checked={value === 1} onChange={handleChange} />
        <label htmlFor="radio-2">{text2}</label>
      </StyledField>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  border-radius: ${props => props.theme.pxToRem(25)};
  width: max-content;
  height: ${props => props.theme.pxToRem(50)};
  border-width: thin;
  border-style: solid;
  border-color: ${props => props.theme.colors.neutralGrey3};
  padding: ${props => props.theme.pxToRem(4)};
`;

const StyledField = styled.form`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(36)};
  overflow: hidden;
  background-color: ${props => props.theme.colors.neutralWhite};
  height: ${props => props.theme.pxToRem(40)};
  color: ${props => props.theme.colors.neutralBlack};
  border-radius: ${props => props.theme.pxToRem(20)};

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: ${props => props.theme.pxToRem(1)};
    width: ${props => props.theme.pxToRem(1)};
    border: 0;
    overflow: hidden;
  }

  input:checked + label {
    background-color: ${props => props.theme.colors.primaryDarkPurple};
    box-shadow: none;
    color: ${props => props.theme.colors.neutralWhite};
    border-radius: ${props => props.theme.pxToRem(20)};
  }

  label {
    font-size: ${props => props.theme.pxToRem(16)};
    line-height: 1.7;
    text-align: center;
    padding: ${props => `${props.theme.pxToRem(8)} ${props.theme.pxToRem(16)}`};
    margin-right: -1px;
    color: ${props => props.theme.colors.primaryDarkPurple};
    transition: all 0.1s ease-in-out;
    font-weight: 500;
    cursor: pointer;
  }
`;
