import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { Divider } from 'semantic-ui-react';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';

interface Addon {
  productId: string;
  sku: string;
  name: string;
}

interface Props {
  title: string;
  subtitle?: string;
  link?: string;
  linkName?: string;
  addons?: Addon[];
  membershipTier?: string;
  membershipType?: string;
  handleRemove?: (sku: string) => void;
  handleSetChangeMyLearningPath?: () => void;
  isHidingLink?: boolean;
}

export const CimaSummaryCard: React.FC<Props> = ({
  title,
  subtitle,
  link,
  linkName,
  addons,
  membershipTier,
  membershipType,
  isHidingLink,
  handleRemove,
  handleSetChangeMyLearningPath,
}) => {
  const history = useHistory();

  const handleRemoveAddon = useCallback(
    (sku: string) => {
      if (handleRemove) handleRemove(sku);
    },
    [handleRemove]
  );

  const handleButtonLink = useCallback(() => {
    if (link) history.push(link, { showSummaryPage: false });
    if (handleSetChangeMyLearningPath) handleSetChangeMyLearningPath();
  }, [history, link, handleSetChangeMyLearningPath]);

  const StudyPathVariant = (
    <>
      <StyledDivider />
      <StyledCardSubHeading>{subtitle}</StyledCardSubHeading>
      {!isHidingLink && (
        <StyledButton variant={ButtonEnums.variants.link} testId="study-variant-button-link" onClick={handleButtonLink}>
          {linkName}
        </StyledButton>
      )}
    </>
  );

  const MembershipDetailsVariant = (
    <>
      <StyledDivider />
      <StyledMembershipSubHeading>
        While studying for your CGMA<sup>®</sup> designation, you'll be a member of CIMA or the AICPA, depending on
        location.
      </StyledMembershipSubHeading>
      {membershipType && (
        <StyledMembershipDiv>
          <StyledCardSubHeading isBold={true}>Type</StyledCardSubHeading>
          <StyledCardSubHeading>{membershipType}</StyledCardSubHeading>
        </StyledMembershipDiv>
      )}
      {membershipTier && (
        <StyledMembershipDiv>
          <StyledCardSubHeading isBold={true}>Tier</StyledCardSubHeading>
          <StyledCardSubHeading>{membershipTier}</StyledCardSubHeading>
        </StyledMembershipDiv>
      )}
    </>
  );

  const AddonsVariant = (
    <>
      <StyledDivider />
      {addons?.map(addon => (
        <>
          <StyledAddonProduct>
            {`${addon?.name}`}
            <StyledRemoveLink
              variant={ButtonEnums.variants.link}
              testId="remove-addon-product"
              onClick={handleRemoveAddon.bind(null, addon?.sku as string)}
            >
              Remove
            </StyledRemoveLink>
          </StyledAddonProduct>
        </>
      ))}
      {!addons?.length && <StyledNoAddons>{'No add-ons products have been selected'}</StyledNoAddons>}
      <StyledButton variant={ButtonEnums.variants.link} testId="addons-variant-button-link" onClick={handleButtonLink}>
        {linkName}
      </StyledButton>
    </>
  );

  return (
    <StyledCard>
      <StyledCardHeading>{title}</StyledCardHeading>
      {subtitle && link && StudyPathVariant}
      {(membershipType || membershipTier) && MembershipDetailsVariant}
      {addons && link && AddonsVariant}
    </StyledCard>
  );
};

interface StyledCardSubHeadingProps {
  isBold?: boolean;
}

const StyledCard = styled.div`
  box-sizing: border-box;
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)} 0
    ${props => props.theme.colors.neutralGrey3};
  background-color: ${props => props.theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(4)};
  min-width: ${props => props.theme.pxToRem(400)};
  min-height: ${props => props.theme.pxToRem(200)};
  margin: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)};
  padding: ${props => props.theme.pxToRem(36)} ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(32)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(5)};
  }
`;

const StyledDivider = styled(Divider)`
  &&& {
    min-width: ${props => props.theme.pxToRem(240)};
    margin: ${props => props.theme.pxToRem(8)};
    color: ${props => props.theme.colors.neutralGrey3};
  }
`;

const StyledCardHeading = styled.span`
  font-size: ${props => props.theme.fontSizes.m};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.33;
`;

const StyledCardSubHeading = styled.span<StyledCardSubHeadingProps>`
  display: inline-block;
  text-transform: capitalize;
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.57;
  margin: ${props => props.theme.pxToRem(15.5)} ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(18)}
    0;

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: ${props => props.theme.fontWeights.bold};
    `}
`;

const StyledNoAddons = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.57;
  margin: ${props => props.theme.pxToRem(15.5)} ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(18)}
    0;
`;

const StyledAddonProduct = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  margin: ${props => props.theme.pxToRem(15.5)} ${props => props.theme.pxToRem(25)} ${props => props.theme.pxToRem(14)}
    0;

  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: center;
    font-size: ${props => props.theme.fontSizes.s};
    align-items: center;
    justify-content: center;
  }
`;

const StyledButton = styled(Button)`
  &&&&& {
    display: block;

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: 0 auto;
    }
  }
`;

const StyledRemoveLink = styled(Button)`
  &&&&& {
    display: inline;
    font-size: ${props => props.theme.fontSizes.xxs};
    margin-left: ${props => props.theme.pxToRem(24)};
  }
`;

const StyledMembershipSubHeading = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledMembershipDiv = styled.div`
  margin: {props => props.theme.pxToRem(2)} 0;
`;
