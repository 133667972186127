import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import TruncateMarkup from 'react-truncate-markup';
import { Link, ExternalSourceLabel, HomeFeedSectionTitle, LinkEnums } from 'components/atoms';
import { handleEvent, TOP_MY_SAVED_EVENT } from 'utils/Analytics';
import { IC_BOOKMARK_BORDER } from 'resources/images';
import { Routes } from 'constants/index';
import { getPath, getContentUrl } from 'utils';

interface IProps {
  className?: string;
  // tslint:disable-next-line:prefer-array-literal
  items: Partial<State.ContentItem>[] | null;
  setBurger?: (state: boolean) => void;
}

export const TopSavedItems: React.FC<IProps> = React.memo(({ className, items, setBurger }) => {
  return (
    <StyledWrapper data-testid={`My-Saved-Items`} className={className}>
      <HomeFeedSectionTitle title="My Saved Items" testId="My-Saved-Items-title" />
      {!items || !items.length ? (
        <NothingSaved>
          You have no saved items yet. Look for the ‘bookmark’ icon to save articles, resources, CPE and much more.
        </NothingSaved>
      ) : (
        <LinksWrapper>
          {items.map((item, i) => (
            <Fragment key={item.id}>
              <MySavedLink item={item} setBurger={setBurger} data-testid={`saved-link-${item.title}`} />
              {i < items.length - 1 && <OptionDivider />}
            </Fragment>
          ))}
          <ViewAll label="View all Saved Items" setBurger={setBurger} />
        </LinksWrapper>
      )}
      <OptionDividerBottom />
    </StyledWrapper>
  );
});

interface IMySavedLinkProps {
  item: Partial<State.ContentItem>;
  setBurger?: (state: boolean) => void;
}

const MySavedLink: React.FC<IMySavedLinkProps> = React.memo(({ item, setBurger }) => {
  const { title, contentSource, slug, externalUrl } = item;
  const externalSource = externalUrl && contentSource;

  const handleLinkClick = React.useCallback(() => {
    handleEvent({ text: `my-saved-top:${title}:${slug}` }, TOP_MY_SAVED_EVENT);
    if (setBurger) {
      setBurger(false);
    }
  }, [setBurger, title, slug]);

  const renderLinkedBlock = () => {
    if (externalUrl) {
      return (
        <Link isExternal type={LinkEnums.type.standaloneLink} testId={title} to={externalUrl} onClick={handleLinkClick}>
          {title}
        </Link>
      );
    }
    return (
      <StyledLink to={getContentUrl(item as State.ContentCardItem)} onClick={handleLinkClick}>
        <TruncateMarkup ellipsis={'…'} lines={2}>
          <div>{title}</div>
        </TruncateMarkup>
      </StyledLink>
    );
  };

  return (
    <>
      {renderLinkedBlock()}
      {externalSource && <Source label={externalSource} />}
    </>
  );
});

interface IViewAll {
  label: string;
  setBurger?: (state: boolean) => void;
}

const ViewAll: React.FC<IViewAll> = React.memo(({ label, setBurger }) => {
  const handleClick = React.useCallback(() => {
    handleEvent({ text: `my-saved-top:/saved` }, TOP_MY_SAVED_EVENT);
    if (setBurger) {
      setBurger(false);
    }
  }, [setBurger]);

  return (
    <ViewAllLinkWrap>
      <StyledLink to={getPath(Routes.PROFILE_SAVED_ITEMS)} onClick={handleClick} data-testid={`view-all-saved`}>
        <StyledIcon src={IC_BOOKMARK_BORDER} alt="go to my saved items" />
        {label}
      </StyledLink>
    </ViewAllLinkWrap>
  );
});

const StyledWrapper = styled.div`
  margin: 0;
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.primaryPurple};
`;

const NothingSaved = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
`;

const LinksWrapper = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-left: ${props => props.theme.pxToRem(20)};
    padding-right: ${props => props.theme.pxToRem(12)};
  }
`;

const StyledLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.xs};
`;

const ViewAllLinkWrap = styled.div`
  margin-top: ${props => props.theme.pxToRem(24)};
`;

const Source = styled(ExternalSourceLabel)`
  text-align: left;
  padding-top: 4px;
`;

const StyledIcon = styled.img`
  vertical-align: bottom;
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  line-height: ${props => props.theme.pxToRem(18)};
  display: inline-block;
  margin-right: ${props => props.theme.pxToRem(4)};
`;

const OptionDivider = styled.div`
  height: ${props => props.theme.pxToRem(0)};
  margin: ${props => props.theme.pxToRem(12)} 0 ${props => props.theme.pxToRem(12)} 0;
`;

const OptionDividerBottom = styled(OptionDivider)`
  height: ${props => props.theme.pxToRem(1)};
  background-color: ${props => props.theme.colors.neutralGrey2};
  margin: ${props => props.theme.pxToRem(24)} 0 ${props => props.theme.pxToRem(24)} 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(44)};
  }
`;
