import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { userPrefSelector, userMemberTypeSelector, userRolesSelector } from 'modules/user/selectors';
import { productAccessConfigSelector } from 'modules/app/selectors';
import {
  toggleTopicPrefAndUpdateGoldenRecord,
  toggleSubtopicPrefAndUpdateGoldenRecord,
  toggleUserShortcuts,
} from 'modules/user/actions';
import { TopicHeroEditorialBlockItems } from './TopicHeroEditorialBlockItems';
import { Button, Container, Grid, ButtonEnums, Link, Tab, Divider } from 'components/atoms';
import { ReactComponent as ArrowBack } from 'resources/images/ic-arrow-back.svg';
import { ReactComponent as Shortcut } from 'resources/images/icon-dev-ic-shortcut.svg';
import { Copy } from 'components/atoms/svg';
import { getPath, emptyArray } from 'utils';
import { Routes } from 'constants/index';
import { copy } from 'modules/copy/actions';
import { BenefitBlock } from '../BenefitBlock/BenefitBlock';
import { UserMemberTypes } from 'modules/user/constants';
import { MembershipIdsEnum } from 'mxp-schemas/src/types/user';
import { Contentful, User } from 'mxp-schemas';

interface Props {
  topicId?: string;
  topicSlug?: string;
  topicName?: string;
  taxonomyType?: string;
  slicedTaxonomies?: any[] | null;
  subtopicId?: string;
  subtopicSlug?: string;
  subtopicName?: string;
  categorySlug?: string;
  topicSummary?: string;
  userEmail?: string;
  oktaId?: string;
  hasShortcut: boolean;
  useParentTopicConfig?: boolean;
  isSubtopicPage?: boolean;
  isToolkitPage?: boolean;
  isProductAggregationPage?: boolean;
  hideBackButton?: boolean;
  showCopyLinkButton?: boolean;
  editorialBlockItems?: State.ContentCardItem[];
  associatedMembershipRestrictions?: Contentful.MembershipRestriction.Main | null;
  panes?: Common.PageCategoryAggregationHeroTabPane[];
  fvsMembership?: User.MembershipIdsEnum[] | [];
  onShortcutClick?: () => void;
  toggleLoginReload?: (shouldReload: boolean) => void;
  hideButtons?: boolean;
  image?: string;
}

export const AggregationHero: React.FC<Props> = React.memo(
  ({
    topicId,
    topicSlug,
    topicName,
    taxonomyType,
    slicedTaxonomies,
    subtopicId,
    subtopicSlug,
    subtopicName,
    categorySlug = '',
    topicSummary = '',
    hasShortcut,
    editorialBlockItems = emptyArray,
    panes = null,
    associatedMembershipRestrictions = null,
    useParentTopicConfig = false,
    isSubtopicPage = false,
    isToolkitPage = false,
    isProductAggregationPage = false,
    hideBackButton = false,
    showCopyLinkButton = false,
    onShortcutClick,
    toggleLoginReload,
    userEmail,
    oktaId,
    fvsMembership,
    hideButtons,
    image = '',
  }) => {
    const dispatch = useDispatch();
    const [loadingShortcut, setLoadingShortcut] = useState(false);
    const [loadingFollow, setLoadingFollow] = useState(false);
    const userPref: State.Preferences = useSelector(userPrefSelector);
    const topicFollowed: boolean =
      slicedTaxonomies?.length === 2
        ? Boolean(topicId && userPref[slicedTaxonomies[0].id] && userPref[slicedTaxonomies[1].id])
        : Boolean(topicId && userPref[topicId]);
    const subtopicFollowed: boolean = Boolean(
      topicId && subtopicId && userPref[topicId] && userPref[topicId].includes(subtopicId)
    );
    const userRoles: MembershipIdsEnum[] = useSelector(userRolesSelector);
    const userStatus: UserMemberTypes = useSelector(userMemberTypeSelector);
    const { oplRedirectUrl, oplHidUrl }: State.ProductAccess = useSelector(productAccessConfigSelector);

    const isFollowed: boolean = isSubtopicPage ? subtopicFollowed : topicFollowed;
    const isTopicHeroEditorialBlockItemsVisible: boolean = Boolean(!useParentTopicConfig && editorialBlockItems.length);
    const isCategoryPage: boolean = Boolean(panes?.length);
    const isCategoryCollectionPage: boolean = Boolean(isCategoryPage && !topicSlug && !subtopicSlug);

    const tabActiveIndex: number = React.useMemo(() => {
      return panes?.findIndex(
        (pane: Common.PageCategoryAggregationHeroTabPane) => pane.categorySlug === categorySlug
      ) as number;
    }, [panes, categorySlug]);

    const handleFollowTopicClick = React.useCallback(async (): Promise<void> => {
      let settingApplied = false;
      const isOnEvent: boolean = !topicFollowed;
      setLoadingFollow(true);
      // If sliced category page
      if (slicedTaxonomies && slicedTaxonomies.length === 2) {
        // If both or neither are followed
        if (
          (!userPref[slicedTaxonomies[0].id] && !userPref[slicedTaxonomies[1].id]) ||
          (userPref[slicedTaxonomies[0].id] && userPref[slicedTaxonomies[1].id])
        ) {
          await dispatch(toggleTopicPrefAndUpdateGoldenRecord(slicedTaxonomies[0].id, isOnEvent));
          await dispatch(toggleTopicPrefAndUpdateGoldenRecord(slicedTaxonomies[1].id, isOnEvent));
          settingApplied = true;
        }
        // If only following second topic, follow first
        if (!topicFollowed && !userPref[slicedTaxonomies[0].id] && userPref[slicedTaxonomies[1].id]) {
          await dispatch(toggleTopicPrefAndUpdateGoldenRecord(slicedTaxonomies[0].id, isOnEvent));
          settingApplied = true;
        }
        // If only following first topic, follow second
        if (!topicFollowed && userPref[slicedTaxonomies[0].id] && !userPref[slicedTaxonomies[1].id]) {
          await dispatch(toggleTopicPrefAndUpdateGoldenRecord(slicedTaxonomies[1].id, isOnEvent));
          settingApplied = true;
        }
      }
      // If cleared one filter and reverted back to single topic page
      if (((slicedTaxonomies && slicedTaxonomies.length === 1) || !slicedTaxonomies) && topicId) {
        await dispatch(toggleTopicPrefAndUpdateGoldenRecord(topicId, isOnEvent));
        settingApplied = true;
      }

      // If single category page, toggle topic
      if (!settingApplied && ((slicedTaxonomies && !slicedTaxonomies.length) || !slicedTaxonomies) && topicId) {
        await dispatch(toggleTopicPrefAndUpdateGoldenRecord(topicId, isOnEvent));
        settingApplied = true;
      }
      setLoadingFollow(false);
    }, [topicId, topicFollowed, slicedTaxonomies, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFollowSubtopicClick = React.useCallback((): void => {
      const isOnEvent: boolean = !subtopicFollowed;
      dispatch(toggleSubtopicPrefAndUpdateGoldenRecord(topicId, subtopicId, isOnEvent));
    }, [topicId, subtopicId, subtopicFollowed, dispatch]);

    const handleShortcutClick = React.useCallback((): void => {
      if (onShortcutClick) {
        // PageToolkit uses custom onShortcutClick handler
        onShortcutClick();
      } else {
        setLoadingShortcut(true);
        const isOnEvent = !hasShortcut;
        if (slicedTaxonomies && slicedTaxonomies.length === 2) {
          // add shortcut for sliced taxonomies
          const slicedAggsFilter = `${slicedTaxonomies[1].type}=${slicedTaxonomies[1].slug}&${slicedTaxonomies[0].type}=${slicedTaxonomies[0].slug}`;
          dispatch(toggleUserShortcuts({ topicSlug, isOnEvent, subtopicSlug, categorySlug, slicedAggsFilter })).finally(
            () => setLoadingShortcut(false)
          );
        } else {
          dispatch(toggleUserShortcuts({ topicSlug, isOnEvent, subtopicSlug, categorySlug })).finally(() =>
            setLoadingShortcut(false)
          );
        }
      }
    }, [topicSlug, subtopicSlug, hasShortcut, categorySlug, onShortcutClick, dispatch, slicedTaxonomies]);

    const handleCopyClick = React.useCallback((): void => {
      dispatch(
        copy({
          notificationText: subtopicName,
          copiedText: window.location.href,
          id: subtopicName?.replace(' ', '-'),
        })
      );
    }, [subtopicName, dispatch]);

    const handleToggleLoginReload = () => {
      toggleLoginReload?.(true);
    };

    const renderBackLink = (): React.ReactNode => {
      if ((topicSlug && !isSubtopicPage && !isCategoryPage && !isProductAggregationPage) || hideBackButton) {
        return null;
      }
      const to: string = topicName
        ? isProductAggregationPage
          ? generatePath(getPath(Routes.STOREFRONT_PAGE))
          : isSubtopicPage && isCategoryPage && subtopicName
          ? generatePath(getPath(Routes.TOPIC_AGGS_PAGE), { topicSlug, subtopicSlug })
          : isSubtopicPage && !isToolkitPage
          ? generatePath(getPath(Routes.TOPIC_AGGS_PAGE), { topicSlug })
          : taxonomyType === 'topic'
          ? generatePath(getPath(Routes.TOPIC_AGGS_PAGE), { topicSlug })
          : taxonomyType === 'industry'
          ? generatePath(getPath(Routes.INDUSTRY_AGGS_PAGE), { industrySlug: topicSlug })
          : taxonomyType === 'trend'
          ? generatePath(getPath(Routes.TREND_AGGS_PAGE), { trendSlug: topicSlug })
          : taxonomyType === 'skill'
          ? generatePath(getPath(Routes.SKILL_AGGS_PAGE), { skillSlug: topicSlug })
          : getPath(Routes.FEED)
        : getPath(Routes.FEED);

      const toName: string = topicName
        ? isProductAggregationPage
          ? 'CPE & Learning'
          : isSubtopicPage && isCategoryPage && subtopicName
          ? subtopicName
          : topicName
        : 'Home';

      return (
        <Grid.Column computer="4" tablet="16">
          {topicSlug && (
            <StyledLink
              to={to}
              data-testid="back-button"
              data-toolkit-style={isToolkitPage}
              data-product-agg-style={isProductAggregationPage}
            >
              <ArrowBack />
              Go to {toName}
            </StyledLink>
          )}
        </Grid.Column>
      );
    };

    const renderBodyContent = (): React.ReactNode => {
      return (
        <>
          {isTopicHeroEditorialBlockItemsVisible && (
            <TopicHeroEditorialBlockItems editorialBlockItems={editorialBlockItems} />
          )}
          {!useParentTopicConfig && associatedMembershipRestrictions && (
            <Grid.Row>
              <Grid.Column computer={16} tablet={16}>
                <Divider />
                <BenefitBlock
                  associatedMembershipRestrictions={associatedMembershipRestrictions}
                  userStatus={userStatus}
                  userRoles={userRoles}
                  toggleLoginReload={handleToggleLoginReload}
                  userEmail={userEmail}
                  oktaId={oktaId}
                  oplRedirectUrl={oplRedirectUrl}
                  oplHidUrl={oplHidUrl}
                  fvsMembership={fvsMembership}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </>
      );
    };

    return (
      <React.Fragment>
        <StyledContainer
          isSubtopicPage={isSubtopicPage}
          isToolkitPage={isToolkitPage}
          isCategoryPage={isCategoryPage}
          isProductAggregationPage={isProductAggregationPage}
          image={image}
        >
          <Container>
            <StyledGrid columns={isProductAggregationPage ? '1' : 'equal'}>
              <Grid.Row>
                {renderBackLink()}
                <Grid.Column verticalAlign={isCategoryPage ? 'top' : 'middle'}>
                  <StyledHeader
                    tabIndex={0}
                    data-toolkit-style={isToolkitPage}
                    isProductAggregationPage={isProductAggregationPage}
                    image={image}
                  >
                    {slicedTaxonomies
                      ? `${slicedTaxonomies[0].name} and ${slicedTaxonomies[1].name}`
                      : subtopicName || topicName || 'Content Collection'}
                  </StyledHeader>
                  {(!useParentTopicConfig || isToolkitPage) && topicSummary && (
                    <StyledDescription
                      tabIndex={0}
                      data-toolkit-style={isToolkitPage}
                      isProductAggregationPage={isProductAggregationPage}
                      image={image}
                    >
                      {topicSummary}
                    </StyledDescription>
                  )}
                </Grid.Column>
                {!hideButtons && (
                  <>
                    {!isProductAggregationPage && (
                      <StyledRightGridColumn computer="5" tablet="16" verticalAlign="top">
                        <StyledButtonsWrapper data-toolkit-style={isToolkitPage}>
                          {!isCategoryCollectionPage &&
                            (showCopyLinkButton ? (
                              <StyledButton
                                data-toolkit-style={isToolkitPage}
                                active={isFollowed}
                                icon={<Copy color="currentColor" />}
                                iconName={isFollowed ? 'check' : 'plus'}
                                iconPosition={ButtonEnums.iconPosition.left}
                                testId={`follow-${isSubtopicPage ? `${topicSlug}-${subtopicSlug}` : subtopicSlug}`}
                                onClick={handleCopyClick}
                                variant={ButtonEnums.variants.primaryToggle}
                                toggle
                                tabIndex={0}
                              >
                                Copy Link
                              </StyledButton>
                            ) : (
                              <StyledButton
                                data-toolkit-style={isToolkitPage}
                                active={isFollowed}
                                iconName={isFollowed ? 'check' : 'plus'}
                                iconPosition={ButtonEnums.iconPosition.left}
                                testId={`follow-${isSubtopicPage ? `${topicSlug}-${subtopicSlug}` : subtopicSlug}`}
                                onClick={isSubtopicPage ? handleFollowSubtopicClick : handleFollowTopicClick}
                                variant={ButtonEnums.variants.primaryToggle}
                                loading={loadingFollow}
                                toggle
                                tabIndex={0}
                              >
                                {isFollowed ? 'Following' : 'Follow'}
                              </StyledButton>
                            ))}
                          <StyledButton
                            data-toolkit-style={isToolkitPage}
                            active={hasShortcut}
                            testId="pa-create-shortcut"
                            onClick={handleShortcutClick}
                            icon={<Shortcut />}
                            iconPosition={ButtonEnums.iconPosition.left}
                            variant={ButtonEnums.variants.primaryToggle}
                            toggle
                            tabIndex={0}
                            loading={loadingShortcut}
                          >
                            {hasShortcut ? 'Added to homepage' : 'Create shortcut'}
                          </StyledButton>
                        </StyledButtonsWrapper>
                      </StyledRightGridColumn>
                    )}
                  </>
                )}
              </Grid.Row>
            </StyledGrid>
          </Container>
          {isCategoryPage && panes && (
            <StyledTabContainer>
              <Grid>
                <Grid.Row>
                  <Grid.Column only="computer" computer="4" />
                  <Grid.Column computer="12" tablet="16">
                    <StyledTab panes={panes} activeIndex={tabActiveIndex} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </StyledTabContainer>
          )}
        </StyledContainer>
        <StyledContent>{renderBodyContent()}</StyledContent>
      </React.Fragment>
    );
  }
);

const StyledContainer = styled.div<any>`
  position: relative;
  padding-top: ${props => props.theme.pxToRem(40)};
  padding-bottom: ${props =>
    props.isCategoryPage ? props.theme.pxToRem(64) : props.theme.pxToRem(props.isSubtopicPage ? 40 : 70)};
  box-shadow: ${props =>
    props.isToolkitPage && props.image ? `inset 0 0 0.625rem 0 ${props.theme.colors.neutralGrey3}` : ''};
  background-color: ${props => (!props.isToolkitPage ? props.theme.colors.neutralGrey1 : '')};
  background: ${props =>
    props.isToolkitPage
      ? `linear-gradient(81deg, #6a2d87 35%, #a93d69 101%)`
      : props.isProductAggregationPage || props.image
      ? `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${props.image})`
      : null};
  background-repeat: no-repeat;
  background-size: cover;

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-top: ${props => props.theme.pxToRem(25)};
    padding-bottom: ${props =>
      props.isCategoryPage ? props.theme.pxToRem(80) : props.isProductAggregationPage ? props.theme.pxToRem(40) : ''};
  }
`;

const StyledContent = styled(Grid)<any>`
  min-height: 100%;

  ${props => props.theme.mediaQueries.desktopOnly} {
    &&& {
      padding: 0 ${props => props.theme.pxToRem(180)} 0 ${props => props.theme.pxToRem(180)};
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    &&& {
      padding-top: ${props => props.theme.pxToRem(15)};
    }
  }
`;

const StyledGrid = styled(Grid)<any>`
  min-height: ${props => props.theme.pxToRem(135)};
`;

const StyledLink = styled(Link)<any>`
  display: inline-flex;
  align-items: center;
  color: ${props =>
    props['data-toolkit-style'] || props['data-product-agg-style']
      ? props.theme.colors.neutralWhite
      : props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-bottom: ${props => props['data-product-agg-style'] && props.theme.pxToRem(27)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => (props['data-product-agg-style'] ? 0 : props.theme.pxToRem(20))};
  }

  > svg {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    margin-right: ${props => props.theme.pxToRem(4)};

    path {
      fill: ${props =>
        props['data-toolkit-style'] || props['data-product-agg-style']
          ? props.theme.colors.neutralWhite
          : props.theme.colors.primaryPurple};
    }
  }
`;

const StyledHeader = styled.h1<any>`
  color: ${props => (props['data-toolkit-style'] ? props.theme.colors.neutralWhite : props.theme.colors.neutralBlack)};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.35;
  &&& {
    margin-bottom: ${props => props.isProductAggregationPage && props.theme.pxToRem(8)};
  }
  padding-top: ${props => props.theme.pxToRem(35)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xl};
    color: ${props =>
      props.image || props['data-toolkit-style'] ? props.theme.colors.neutralWhite : props.theme.colors.neutralBlack};
  }

  ${props => props.theme.mediaQueries.desktopOnly} {
    color: ${props =>
      props.image || props['data-toolkit-style'] ? props.theme.colors.neutralWhite : props.theme.colors.neutralBlack};
  }
`;

const StyledDescription = styled.p<any>`
  color: ${props => (props['data-toolkit-style'] ? props.theme.colors.neutralWhite : props.theme.colors.neutralBlack)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.44;
  max-width: ${props => props.isProductAggregationPage && props.theme.pxToRem(645)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    color: ${props =>
      props.image || props['data-toolkit-style'] ? props.theme.colors.neutralWhite : props.theme.colors.neutralBlack};
  }

  ${props => props.theme.mediaQueries.desktopOnly} {
    color: ${props =>
      props.image || props['data-toolkit-style'] ? props.theme.colors.neutralWhite : props.theme.colors.neutralBlack};
  }
`;

const StyledRightGridColumn = styled(Grid.Column)`
  text-align: right;

  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: center;
  }
`;

const StyledButtonsWrapper = styled.div<any>`
  display: inline-flex;
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => (props['data-toolkit-style'] ? `rgba(255, 255, 255, 0.1)` : '')};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    margin-top: ${props => props.theme.pxToRem(20)};
  }
`;

const StyledButton = styled(Button)<any>`
  &&&& {
    height: ${props => props.theme.pxToRem(40)};
    padding: ${props => props.theme.pxToRem(6)} ${props => props.theme.pxToRem(24)};
    background-color: ${props => (props['data-toolkit-style'] ? `rgba(255, 255, 255, 0.1)` : '')};
    color: ${props => (props['data-toolkit-style'] ? props.theme.colors.neutralWhite : '')};

    ${props => props.theme.mediaQueries.computerMin} {
      &.toggle:focus {
        border: ${props => props.theme.pxToRem(1)} solid 'transparent';
        background-color: ${props => props.theme.colors.primaryLightPurple} !important;
        color: ${props => props.theme.colors.neutralWhite} !important;
      }
      &.toggle.active:focus {
        border: ${props => props.theme.pxToRem(1)} solid 'transparent';
        background-color: ${props => props.theme.colors.interfaceRed} !important;
      }
    }
    > svg {
      width: ${props => props.theme.pxToRem(16)};
      height: ${props => props.theme.pxToRem(16)};
      margin-right: ${props => props.theme.pxToRem(5)};
      fill: ${props =>
        props['data-toolkit-style'] || props.active
          ? props.theme.colors.neutralWhite
          : props.theme.colors.primaryPurple};
    }
    ${props => props.theme.mediaQueries.desktopOnly} {
      &:hover {
        > svg {
          fill: ${props => props.theme.colors.neutralWhite};
        }
      }
    }

    &:first-child {
      align-items: center;
      min-width: ${props => props.theme.pxToRem(112)};
      border-right: ${(props: any) =>
        props['data-toolkit-style'] ? `1px solid rgba(255, 255, 255, 0.8)` : `1px solid rgba(0, 0, 0, 0.1)`};
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      align-items: center;
      flex-grow: 1.8;
      min-width: ${props => props.theme.pxToRem(160)};
      border-left: ${(props: any) =>
        props['data-toolkit-style'] ? `1px solid rgba(255, 255, 255, 0.8)` : `1px solid rgba(0, 0, 0, 0.1)`};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      ${props => props.theme.mediaQueries.tabletMin} {
        flex-grow: 1;
      }
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      flex: 1;
      justify-content: center;
    }
  }
`;

const StyledTabContainer = styled(Container)`
  &&&& {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    .ui.grid {
      ${props => props.theme.mediaQueries.tabletMin} {
        margin-bottom: ${props => props.theme.pxToRem(-10)} !important;
      }

      ${props => props.theme.mediaQueries.mobileOnly} {
        margin-bottom: ${props => props.theme.pxToRem(-16)};
      }
    }
  }
`;

const StyledTab = styled(Tab)`
  overflow-x: auto;

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-right: ${props => props.theme.pxToRem(-18)};
    margin-left: ${props => props.theme.pxToRem(-18)};
  }

  .ui.text.menu {
    display: inline-flex;

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-right: ${props => props.theme.pxToRem(18)};
      padding-left: ${props => props.theme.pxToRem(18)};
    }

    .item {
      margin-right: ${props => props.theme.pxToRem(60)};

      ${props => props.theme.mediaQueries.mobileOnly} {
        margin-right: ${props => props.theme.pxToRem(40)};
      }

      :last-child {
        margin-right: 0;
      }
    }
  }
`;
