import OktaAuth, { OktaTokenManagerOptions } from '@okta/okta-auth-js';
import { store } from '../../store';
import { oktaConfigSelector } from 'modules/app/selectors';
import * as cookie from 'utils/cookie';
import { getSessionStorageItem, isServer } from 'utils';
import { SESSION_STORE_NAMES, StorageNames } from 'constants/index';
import { B2B, User as UserTypes } from 'mxp-schemas';

const BUILT_IN_AUTH_SERVER = 'BUILT_IN';
export abstract class AuthHelper {
  protected oktaCfg: State.Okta;
  protected authClient: OktaAuth;
  protected oktaStorageKey: string;
  protected isBuiltInServer: boolean;

  constructor(
    oktaStorageKey: string,
    tokenManager: OktaTokenManagerOptions,
    pkce: boolean,
    useClientIdOverride?: boolean
  ) {
    this.oktaCfg = oktaConfigSelector(store.getState());

    if (!this.oktaCfg || !this.oktaCfg.authServerId) {
      throw new Error(`No OKTA_SPA_CLIENT_ID provided`);
    }

    const clientIdOverride = useClientIdOverride && this.getCientIdOverride();

    const clientId = clientIdOverride || this.oktaCfg.spaClientId;

    this.isBuiltInServer = BUILT_IN_AUTH_SERVER === this.oktaCfg.authServerId;
    this.authClient = new OktaAuth({
      issuer:
        BUILT_IN_AUTH_SERVER !== this.oktaCfg.authServerId
          ? `https://${this.oktaCfg.orgUrl}/oauth2/${this.oktaCfg.authServerId}`
          : `https://${this.oktaCfg.orgUrl}`,
      clientId,
      redirectUri: window.location.origin,
      pkce,
      tokenManager,
    });
    this.oktaStorageKey = oktaStorageKey;
  }

  /**
   * Public interface; shared between consumer and appc
   */
  abstract async signOutUser(): Promise<void>;
  abstract getUserTokenData(): Promise<{ sub: string } | null>;
  abstract sessionExists(): Promise<UserTypes.SessionType | null>;
  abstract async getValidAccessToken(): Promise<string | undefined>;
  abstract async signOutUserFromOkta(): Promise<void>;

  protected async clearTokens(): Promise<void> {
    await this.authClient.tokenManager.clear();
    this.cleanupLegacyCookies();
  }

  private getCientIdOverride(): string {
    // pick the right app based on user type in the storage
    const selectedRole = getSessionStorageItem(
      StorageNames.adminClientRole,
      SESSION_STORE_NAMES.ADMIN_SESSION_STORE_KEY
    );

    const defaultId = this.oktaCfg.spaClientId;
    const financeId = this.oktaCfg.financeClientId;
    const b2bOps = this.oktaCfg.b2bOpsClientId;
    const raveOps = this.oktaCfg.raveOpsClientId;
    const firmBillingId = this.oktaCfg.firmBillingClientId;
    const centersClientId = this.oktaCfg.centersClientId;

    switch (selectedRole) {
      case B2B.AgentRole.B2B_OPS:
        return b2bOps;
      case B2B.AgentRole.FINANCE:
        return financeId;
      case B2B.AgentRole.RAVE_OPS:
        return raveOps;
      case B2B.AgentRole.FIRM_BILLING:
        return firmBillingId;
      case B2B.AgentRole.CENTERS_MEMBERSHIP:
        return centersClientId;
      case B2B.AgentRole.GEC:
      default:
        return defaultId;
    }
  }
  protected cleanupLegacyCookies() {
    if (isServer) return;
    cookie.del('email');
    cookie.del('okta-token-storage');
    cookie.del('okta-token-storage_okta-token-storage');
  }
}
