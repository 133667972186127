import * as Sentry from '@sentry/browser';
import { handleActions, Action, combineActions } from 'redux-actions';
import {
  savedItemsSelector,
  topSavedItemsSelector,
  savedItemsResultsSelector,
  savedItemsLoadingSelector,
  savedItemsResultsFetchedSelector,
  savedItemsMetaSelector,
  savedItemsAggregationSelector,
} from './selectors';
import { savedItemsLoading, getSavedItems, toggleSavedItem, fetchSearchContentByIds, setSavedItems } from './actions';
import { initialState } from './constants';

if ([savedItemsLoading, getSavedItems, toggleSavedItem, fetchSearchContentByIds].includes(undefined)) {
  const message: string = 'savedItems module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [savedItemsLoading]: (state: State.SavedItems): State.SavedItems => ({
    ...state,
  }),

  [combineActions(getSavedItems, toggleSavedItem) as any]: {
    next: (state: State.SavedItems, action: Action<State.SavedItemsData[]>): State.SavedItems => {
      const savedItemsData = Object.values(action.payload)[0] as State.SavedItemsData;
      return {
        ...state,
        contentIds: savedItemsData?.ids,
        top: savedItemsData?.top,
      };
    },
  },

  [fetchSearchContentByIds]: {
    next: (state: State.SavedItems, action: Action<any>): State.SavedItems => ({
      ...state,
      ...action.payload.getContentByIDs,
      loading: false,
      resultsFetched: true,
    }),

    throw: (state: State.SavedItems): State.SavedItems => ({
      ...state,
      loading: false,
    }),
  },
  [setSavedItems]: (state: State.SavedItems, action: Action<any>): any => {
    return {
      ...state,
      contentIds: action.payload?.ids,
      top: action.payload?.top,
    };
  },
};

export {
  // Selectors
  savedItemsSelector,
  topSavedItemsSelector,
  savedItemsResultsSelector,
  savedItemsLoadingSelector,
  savedItemsResultsFetchedSelector,
  savedItemsMetaSelector,
  savedItemsAggregationSelector,
  // Actions
  savedItemsLoading,
  getSavedItems,
  toggleSavedItem,
  fetchSearchContentByIds,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
