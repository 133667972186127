import React from 'react';
import styled from 'styled-components';
/**
 * FileViewer
 * @category Document Management
 * MART-691
 */

interface FileViewerProps {
  testId?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  url: string;
}

export const FileViewer: React.FC<FileViewerProps> = ({ placeholder, url, disabled }) => {
  return (
    <Container>
      {disabled ? (
        <StyledAnchorDisabled>{placeholder ?? 'View'}</StyledAnchorDisabled>
      ) : (
        <StyledA href={url} target="_blank">
          {placeholder ?? 'View'}
        </StyledA>
      )}
    </Container>
  );
};

const StyledA = styled.a`
  &&& {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.neutralGrey8};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    transition: all 0.3s;
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSizes.xs};

    padding: 0;

    i.icon {
      height: auto;
      margin: 0 ${({ theme }) => theme.pxToRem(4)} !important;
      color: ${({ theme }) => theme.colors.primaryPurple};
    }
    &:active,
    &:focus {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.primaryPurple};

      span {
        text-decoration: none;
      }
    }
    ${({ theme }) => theme.mediaQueries.computerMin} {
      &:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.primaryPurple};

        span {
          text-decoration: underline;
        }
      }
    }
  }
`;

const StyledAnchorDisabled = styled.span`
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  transition: all 0.3s;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.xs};

  padding: 0;
  color: ${({ theme }) => theme.colors.neutralGrey4};
  opacity: 1 !important;
  border-color: ${({ theme }) => theme.colors.neutralGrey4};

  i.icon {
    color: ${({ theme }) => theme.colors.neutralGrey4};
  }
`;
const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
`;
