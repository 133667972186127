import { createSelector } from 'reselect';

const rootSelector = createSelector(
  (state: State.Root) => state.savedBlogPosts,
  (saveBlogPosts: State.SavedItems) => saveBlogPosts
);

export const savedBlogPostsSelector = createSelector(
  rootSelector,
  (saveBlogPosts: State.SavedItems): string[] | null => saveBlogPosts.contentIds
);

export const topSavedBlogPostsSelector = createSelector(
  rootSelector,
  // tslint:disable-next-line:prefer-array-literal
  (savedBlogPosts: State.SavedItems): Partial<State.ContentItem>[] | null => savedBlogPosts.top
);

export const savedBlogPostsResultsSelector = createSelector(
  rootSelector,
  (savedBlogPosts: State.SavedItems): State.ContentItem[] => savedBlogPosts.results
);

export const savedBlogPostsLoadingSelector = createSelector(
  rootSelector,
  (savedBlogPosts: State.SavedItems): boolean => savedBlogPosts.loading
);

export const savedBlogPostsResultsFetchedSelector = createSelector(
  rootSelector,
  (savedBlogPosts: State.SavedItems): boolean => savedBlogPosts.resultsFetched
);

export const savedBlogPostsMetaSelector = createSelector(
  rootSelector,
  (savedBlogPosts: State.SavedItems): State.Meta => savedBlogPosts.meta
);

export const savedBlogPostsAggregationSelector = createSelector(
  savedBlogPostsMetaSelector,
  (meta: State.Meta): State.SearchAggs | null => meta.aggs as State.SearchAggs | null
);
