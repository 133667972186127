import React, { useState, useRef, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MembershipTypes } from 'mxp-schemas';
import { Icon, Dropdown as Dropdowns, DropdownProps, DropdownItemProps, Container } from 'semantic-ui-react';
import { Input, Label } from 'components/atoms';
import styled from 'styled-components';
import { usePageContext } from 'components/pages/PagePracticalExperienceRequirement/PageContext';
import {
  searchResultOrganizationsSelector,
  searchOrganizationsByWildcardLoadingSelector,
  employerAlreadyExistsSelector,
  searchOrganizationsCitiesLoadingSelector,
  searchResultOrganizationsCitiesSelector,
  newEmployerSelector,
} from 'modules/user/selectors';

import {
  checkIfOrganizationExists,
  clearNewEmployerData,
  getOrganizationByNameAndCity,
  searchOrganizationsByWildcard,
  searchOrganizationsByWildcardLoading,
  searchOrganizationsCitiesLoading,
  searchOrganizationsCities,
  resetEmploymentDataEffect,
} from 'modules/user/actions';
import { isEmptyString, getTypeValue, handleInputChange } from 'utils';

interface DropdownOption {
  key: number;
  text: string;
  value: string;
}
const optionsBusinesses: DropdownOption[] = [];
const optionsBusinessCities: DropdownOption[] = [];

const organizationTypes = [''].concat(Object.values(MembershipTypes.OrganizationType));

const optionsOrganizationType = organizationTypes.map((value: any, index: number) => {
  return { key: index, value, text: value };
});
// add blank value

interface EmployerType {
  id?: string;
  name: string;
  type: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}
interface Props {
  employer: EmployerType;
  hasOrganizationType?: boolean;
  testId?: string;
  onChangeData: (e: Partial<State.Employer>) => void;
  employeeData?: any;
  resetEmployerData?: () => void;
  resetEmploymentAddress?: () => void;
  setNewEmployementFormData?: (e: Partial<State.Employer>) => void;
  formDataEmployment?: any;
  isBusinessNew?: boolean;
  setIsBusinessNew?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmploymentDetailsForm: React.FC<Props> = memo(
  ({
    employer,
    testId,
    onChangeData,
    hasOrganizationType = true,
    employeeData,
    resetEmployerData,
    resetEmploymentAddress,
    setNewEmployementFormData,
    formDataEmployment,
    isBusinessNew,
    setIsBusinessNew,
  }) => {
    const dispatch = useDispatch();
    const searchOrganizationsByWildcardLoadingSelect = useSelector(searchOrganizationsByWildcardLoadingSelector);
    const searchOrganizationsCitiesLoadingSelect = useSelector(searchOrganizationsCitiesLoadingSelector);
    const employerAlreadyExists = useSelector(employerAlreadyExistsSelector);
    const searchResultOrganizations = useSelector(searchResultOrganizationsSelector);
    const searchResultOrganizationsCities = useSelector(searchResultOrganizationsCitiesSelector);
    const newEmployer = useSelector(newEmployerSelector);
    const [businesses, setBusinesses] = useState<DropdownOption[]>(optionsBusinesses);

    const [businessCities, setBusinessCities] = useState<DropdownOption[]>(optionsBusinessCities);
    const [existingEmployerFromSearch, setExistingEmployerFromSearch] = useState(false);
    const [employerData, setEmployerData] = useState(employer);
    const [fieldsTouched, setFieldsTouched] = useState(new Map());
    const refNewBusiness: any = useRef(null);
    const [stateBusinessList, setStateBusinessList] = useState({
      value: null,
      searchQuery: '',
      filteredList: businesses,
    });
    const [searchTempStorage, setSearchTempStorage] = useState({
      businessName: '',
      businessCity: '',
    });
    const { setIsFromDashboard } = usePageContext();
    const refDropdownBusinessName: any = React.createRef();

    const businessNameElementId = 'businessName';
    const businessCityElementId = 'businessCity';
    const requiredFieldErrorMessage = 'Please complete this mandatory field.';

    const businessNameDOM = document.getElementById(businessNameElementId);
    const businessCityDOM = document.getElementById(businessCityElementId);

    const BUSINESS_NAME = 'name';
    const TYPE_OF_COMPANY = 'type';
    const ADDRESS_LINE_1 = 'addressLine1';
    const ADDRESS_LINE_2 = 'addressLine2';
    const CITY = 'city';
    const STATE = 'state';
    const POSTAL_CODE = 'postalCode';
    const BUSINESS_NAME_SEARCH_ID = 'businessSearch';
    const COMPANY_LOCATION = 'companyLocation';
    const COUNTRY = 'country';

    const searchFn = (event: React.ChangeEvent<HTMLInputElement>, searchQuery: string) => {
      const filteredList = businesses.filter(item => !searchQuery || item.value.includes(searchQuery));
      return filteredList;
    };

    useEffect(() => {
      if (employerData) {
        setExistingEmployerFromSearch(true);
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (employeeData?.employer.name && newEmployer?.organization?.id) {
        const emp = newEmployer.organization;
        const address = newEmployer.organization.billingAddress;
        onChangeData?.({
          id: emp.id,
          name: emp.name,
          type: emp.type || '',
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          state: address.state,
          postalCode: address.zipCode,
          country: address.country,
        });
      }
    }, [newEmployer]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => () => dispatch(resetEmploymentDataEffect()), [dispatch]);

    useEffect(() => {
      if (searchResultOrganizations) {
        if (searchResultOrganizations?.length) {
          const items: DropdownOption[] = searchResultOrganizations?.map(
            (organization: { name: string; type: string }, index: number) => {
              return { value: organization.name, key: index, text: organization.name };
            }
          );
          setBusinesses(items);
          setStateBusinessList({
            ...stateBusinessList,
            filteredList: items,
          });
          businessNameDOM?.focus();
        } else {
          // setWarningMessage(`No results were found for "${searchTempStorage.businessName}"`);
        }
      }
    }, [searchResultOrganizations]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (searchResultOrganizationsCities?.length) {
        const items: DropdownOption[] = searchResultOrganizationsCities?.map((city: string, index: number) => {
          return { value: city, key: index, text: city };
        });
        setBusinessCities(items);
        businessCityDOM?.focus();
      } else {
        // setWarningMessage(`No results were found for "${searchTempStorage.employerData.city}"`);
      }
    }, [searchResultOrganizationsCities]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearchChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      { searchQuery }: { searchQuery: string }
    ) => {
      // set Warning Message empty
      const filteredList = searchFn(event, searchQuery);
      setStateBusinessList({ ...stateBusinessList, searchQuery, filteredList });
      setSearchTempStorage({
        ...searchTempStorage,
        businessName: event.target.value,
      });
    };

    const handleBusinessChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>, data: DropdownProps) => {
        const organizationFindResult = searchResultOrganizations?.find(
          (organization: any): any => organization.name === data.value
        );
        onChangeData?.({
          name: data.value as string,
          type: organizationFindResult?.type || MembershipTypes.OrganizationType.OTHER,
          city: organizationFindResult?.billingAddress?.city,
          addressLine1: organizationFindResult?.billingAddress?.addressLine1,
          addressLine2: organizationFindResult?.billingAddress?.addressLine2,
          state: organizationFindResult?.billingAddress?.state || 'State',
          postalCode: organizationFindResult?.billingAddress?.zipCode,
          country: organizationFindResult?.billingAddress?.country,
        });
        setIsBusinessNew?.(false);
        setExistingEmployerFromSearch(true);
        // set Warning Message empty
      },
      [searchResultOrganizations, onChangeData, setIsBusinessNew, setExistingEmployerFromSearch]
    );

    const handleSearchBusinessName = () => {
      if (searchTempStorage.businessName.length > 2) {
        dispatch(searchOrganizationsByWildcard(searchTempStorage.businessName));
        dispatch(searchOrganizationsByWildcardLoading());
      } else {
        // set Warning Message 'Business name should be at least three characters.';
      }
    };

    const handleSearchAgainBusinessName = React.useCallback(
      () => {
        if (resetEmployerData) resetEmployerData();
        dispatch(resetEmploymentDataEffect());

        setEmployerData({
          ...employerData,
        });
        setStateBusinessList({
          ...stateBusinessList,
          filteredList: [],
        });
        setBusinessCities([]);
        setIsBusinessNew?.(false);
        // set Warning Message empty
        setSearchTempStorage({
          businessName: '',
          businessCity: '',
        });
        setExistingEmployerFromSearch(false);
        setIsFromDashboard(true);
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        stateBusinessList,
        setEmployerData,
        setStateBusinessList,
        setIsBusinessNew,
        setSearchTempStorage,
        setExistingEmployerFromSearch,
      ]
    );

    const handleItemClick = (event: React.SyntheticEvent, data: DropdownItemProps) => {
      refDropdownBusinessName.current.handleItemClick(event, data);
    };

    const handleCreateNewClick = React.useCallback(
      async (event: React.SyntheticEvent, data: DropdownProps) => {
        // set Warning Message empty
        dispatch(clearNewEmployerData());
        await dispatch(checkIfOrganizationExists(searchTempStorage.businessName)).then((response: any) => {
          if (!response.payload) {
            setEmployerData({
              ...employerData,
              name: searchTempStorage.businessName || '',
            });
            setIsBusinessNew?.(true);
          }
        });
      },
      [searchTempStorage, setIsBusinessNew, checkIfOrganizationExists, clearNewEmployerData] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleBusinessCityChange = React.useCallback(
      (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        onChangeData?.({ city: data.value as string });
        dispatch(searchOrganizationsCitiesLoading());
        dispatch(getOrganizationByNameAndCity(employeeData?.employer.name as string, data.value as string));
      },
      [employeeData, onChangeData] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleSearchCityChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        // set Warning Message empty
        setSearchTempStorage({
          ...searchTempStorage,
          businessCity: event.target.value,
        });
      },
      [searchTempStorage, setSearchTempStorage]
    );

    const handleSearchBusinessCity = React.useCallback(() => {
      if (searchTempStorage.businessCity.length > 2) {
        dispatch(searchOrganizationsCitiesLoading());
        dispatch(searchOrganizationsCities(employeeData?.employer.name as string, searchTempStorage.businessCity));
      } else {
        // set Warning Message 'Business city should be at least three characters.');
      }
    }, [searchTempStorage, employerData, searchOrganizationsCities, employeeData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearchAgainBusinessCity = React.useCallback(() => {
      setEmployerData({
        ...employerData,
        name: employerData?.name,
        type: employerData?.type,
        city: employerData?.city,
      });
      // reset form address
      resetEmploymentAddress?.();
      businessCityDOM?.focus();
      setBusinessCities([]);
      setSearchTempStorage({
        ...searchTempStorage,
        businessCity: '',
      });
    }, [
      businessCityDOM,
      employerData,
      searchTempStorage,
      setEmployerData,
      setBusinessCities,
      setSearchTempStorage,
      resetEmploymentAddress,
    ]);

    const handleTouchField = React.useCallback(
      (event: any): void => {
        const { name } = event.target;
        setFieldsTouched(fields => new Map(fields.set(name, true)));
      },
      [setFieldsTouched]
    );

    return (
      <Container data-testid={testId} key={testId}>
        <StyledEmploymentInputLabel>Business name</StyledEmploymentInputLabel>
        <StyledEmploymentDropdown
          ref={refDropdownBusinessName}
          searchInput={{ id: businessNameElementId }}
          placeholder="Search Business Name"
          search={searchFn}
          onChange={handleBusinessChange}
          selectOnNavigation
          selectOnBlur={false}
          onSearchChange={handleSearchChange}
          loading={!!searchOrganizationsByWildcardLoadingSelect}
          icon={<Icon name="search" onClick={handleSearchBusinessName} />}
          disabled={
            (employeeData?.employer?.name || isBusinessNew) &&
            !searchOrganizationsByWildcardLoadingSelect &&
            !employerAlreadyExists
          }
          value={employeeData?.employer.name || ''}
          text={!isBusinessNew ? employeeData?.employer.name : ''}
          className="selection"
          onBlur={() => setFieldsTouched(fields => new Map(fields.set(BUSINESS_NAME_SEARCH_ID, true)))} // tslint:disable-line jsx-no-lambda
        >
          <Dropdowns.Menu key="business-menu">
            {stateBusinessList.filteredList.map((option: any) => {
              return (
                <Dropdowns.Item
                  key={`${option.key}`}
                  text={option.text}
                  value={option.value}
                  onClick={handleItemClick}
                />
              );
            })}
            <StyledEmploymentDropdownMenuItem text={'+ Create New'} onClick={handleCreateNewClick} />
          </Dropdowns.Menu>
        </StyledEmploymentDropdown>
        {fieldsTouched.get(BUSINESS_NAME_SEARCH_ID) && !isBusinessNew && isEmptyString(employeeData?.employer.name) && (
          <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
        )}
        {(employeeData?.employer?.name || isBusinessNew) &&
          !searchOrganizationsByWildcardLoadingSelect &&
          (!employerAlreadyExists || existingEmployerFromSearch) && (
            <>
              <StyledEmploymentResetLink onClick={handleSearchAgainBusinessName}>
                Search again
              </StyledEmploymentResetLink>
              <StyledEmploymentVerticalLine>
                {isBusinessNew && (
                  <>
                    <StyledEmploymentInputLabel>Name</StyledEmploymentInputLabel>
                    <StyledEmploymentSubInput
                      inputRef={refNewBusiness}
                      placeholder="New Business Name"
                      testId="new-business-name"
                      value={employerData?.name}
                      onChange={handleInputChange(onChangeData, setEmployerData)}
                      disabled={!isBusinessNew}
                      name={BUSINESS_NAME}
                      onBlur={handleTouchField}
                    />
                    {fieldsTouched.get(BUSINESS_NAME) && isEmptyString(employeeData?.employer.name || '') && (
                      <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                    )}
                  </>
                )}
                {hasOrganizationType && (
                  <>
                    <StyledEmploymentInputLabel>Type of company</StyledEmploymentInputLabel>
                    <StyledEmploymentSubDropdown
                      selection
                      value={getTypeValue(employerData, searchTempStorage)}
                      options={optionsOrganizationType}
                      onChange={handleInputChange(onChangeData, setEmployerData)}
                      disabled={!isBusinessNew}
                      name={TYPE_OF_COMPANY}
                      onBlur={() => setFieldsTouched(fields => new Map(fields.set(TYPE_OF_COMPANY, true)))} // tslint:disable-line jsx-no-lambda
                    />
                    {fieldsTouched.get(TYPE_OF_COMPANY) && isEmptyString(employeeData?.employer.type || '') && (
                      <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                    )}
                  </>
                )}
              </StyledEmploymentVerticalLine>

              <>
                <StyledEmploymentLabelBusinessCity>Company's location</StyledEmploymentLabelBusinessCity>
                <StyledEmploymentDropdown
                  searchInput={{ id: businessCityElementId }}
                  placeholder="Search Business City"
                  options={businessCities}
                  search
                  onChange={handleBusinessCityChange}
                  selectOnNavigation
                  selectOnBlur={false}
                  selection
                  onSearchChange={handleSearchCityChange}
                  loading={!!searchOrganizationsCitiesLoadingSelect}
                  icon={{ className: 'search', onClick: () => handleSearchBusinessCity() }}
                  disabled={Boolean(employeeData?.employer.city) || isBusinessNew}
                  value={employeeData?.employer?.city || ''}
                  text={!isBusinessNew ? employeeData?.employer?.city : ''}
                  onBlur={() => setFieldsTouched(fields => new Map(fields.set(COMPANY_LOCATION, true)))} // tslint:disable-line jsx-no-lambda
                />
                {fieldsTouched.get(COMPANY_LOCATION) && isEmptyString(employeeData?.employer.city || '') && (
                  <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                )}
              </>

              {(employeeData?.employer?.city || isBusinessNew) && (
                <div>
                  {isBusinessNew || (
                    <StyledEmploymentResetLink onClick={handleSearchAgainBusinessCity}>
                      Search again
                    </StyledEmploymentResetLink>
                  )}
                  <StyledEmploymentVerticalLine>
                    <StyledEmploymentInputLabel>Business Address Line 1</StyledEmploymentInputLabel>
                    <StyledEmploymentSubInput
                      placeholder="Address Line 1"
                      testId="address-line-1"
                      value={employerData?.addressLine1}
                      onChange={handleInputChange(onChangeData, setEmployerData)}
                      disabled={!isBusinessNew}
                      name={ADDRESS_LINE_1}
                      onBlur={handleTouchField}
                    />
                    {fieldsTouched.get(ADDRESS_LINE_1) && isEmptyString(employeeData?.employer.addressLine1 || '') && (
                      <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                    )}
                    <StyledEmploymentInputLabel>Business Address Line 2 (optional)</StyledEmploymentInputLabel>
                    <StyledEmploymentSubInput
                      placeholder="Address Line 2"
                      testId="address-line-2"
                      value={employerData?.addressLine2}
                      onChange={handleInputChange(onChangeData, setEmployerData)}
                      disabled={!isBusinessNew}
                      name={ADDRESS_LINE_2}
                    />
                    <StyledEmploymentContainerCity>
                      <StyledEmploymentInputLabel>City</StyledEmploymentInputLabel>
                      <StyledEmploymentSubInput
                        placeholder="City"
                        testId="address-city"
                        value={employerData?.city}
                        onChange={handleInputChange(onChangeData, setEmployerData)}
                        disabled={!isBusinessNew}
                        name={CITY}
                        onBlur={handleTouchField}
                      />
                      {fieldsTouched.get(CITY) && isEmptyString(employeeData?.employer.city || '') && (
                        <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                      )}
                    </StyledEmploymentContainerCity>
                    <StyledEmploymentContainerZIPCountry>
                      <StyledEmployerContainerState>
                        <StyledEmploymentInputLabel>State</StyledEmploymentInputLabel>
                        <StyledEmploymentInput
                          placeholder="State"
                          testId="state"
                          value={employerData?.state}
                          onChange={handleInputChange(onChangeData, setEmployerData)}
                          disabled={!isBusinessNew}
                          name={STATE}
                          onBlur={handleTouchField}
                        />
                        {fieldsTouched.get(STATE) && isEmptyString(employeeData?.employer.state || '') && (
                          <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                        )}
                      </StyledEmployerContainerState>
                      <StyledEmploymentContainerZIP>
                        <StyledEmploymentInputLabel>ZIP/Postal Code</StyledEmploymentInputLabel>
                        <StyledEmploymentInput
                          placeholder="ZIP/Postal Code"
                          testId="code"
                          value={employerData?.postalCode}
                          onChange={handleInputChange(onChangeData, setEmployerData)}
                          disabled={!isBusinessNew}
                          name={POSTAL_CODE}
                          onBlur={handleTouchField}
                        />
                        {fieldsTouched.get(POSTAL_CODE) && isEmptyString(employeeData?.employer.postalCode || '') && (
                          <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                        )}
                      </StyledEmploymentContainerZIP>
                    </StyledEmploymentContainerZIPCountry>
                    <StyledEmploymentContainerCountry>
                      <StyledEmploymentInputLabel>Country</StyledEmploymentInputLabel>
                      <StyledEmploymentSubInput
                        placeholder="Country"
                        testId="country"
                        value={employerData?.country}
                        onChange={handleInputChange(onChangeData, setEmployerData)}
                        disabled={!isBusinessNew}
                        name={COUNTRY}
                        onBlur={handleTouchField}
                      />
                      {fieldsTouched.get(COUNTRY) && isEmptyString(employeeData?.employer.country || '') && (
                        <StyledErrorMessage>{requiredFieldErrorMessage}</StyledErrorMessage>
                      )}
                    </StyledEmploymentContainerCountry>
                  </StyledEmploymentVerticalLine>
                </div>
              )}
            </>
          )}
      </Container>
    );
  }
);

const StyledEmploymentDropdown = styled(Dropdowns)`
  &&&&&.disabled {
    opacity: 1;
    background-color: ${props => props.theme.colors.neutralGrey2};
    input {
      color: ${props => props.theme.colors.neutralGrey8};
    }
  }

  &&&.ui.dropdown .menu > .item b {
    display: none;
  }

  &&&&& {
    height: ${props => props.theme.pxToRem(44)};
    display: flex;
    div {
      align-self: center;
    }
    i {
      padding-top: ${props => props.theme.pxToRem(12)};
    }
  }
`;

const StyledEmploymentSubDropdown = styled(Dropdowns)`
  /* width: 38.5rem; */
  &&&&&.disabled {
    opacity: 1;
    background-color: ${props => props.theme.colors.neutralGrey2};
    input {
      color: ${props => props.theme.colors.neutralGrey8};
    }
  }

  &&&.ui.dropdown .menu > .item b {
    display: none;
  }

  &&&&& {
    height: ${props => props.theme.pxToRem(44)};
    display: flex;
    div {
      align-self: center;
    }
    i {
      padding-top: ${props => props.theme.pxToRem(12)};
    }
  }
`;

const StyledEmploymentDropdownMenuItem = styled(Dropdowns.Item)`
  &&&&& {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.primaryPurple};

    &:hover {
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyledEmploymentInput = styled(Input)`
  &&& {
    display: flex;
    margin-bottom: ${props => props.theme.pxToRem(7)};
  }

  &&&.disabled {
    cursor: not-allowed;
    opacity: 1;
    input {
      border-color: ${props => props.theme.colors.neutralGrey4};
      background-color: ${props => props.theme.colors.neutralGrey2};
    }
  }
`;

const StyledEmploymentSubInput = styled(Input)`
  /* width: 38.5rem; */
  &&& {
    display: flex;
    margin-bottom: ${props => props.theme.pxToRem(7)};
  }

  &&&.disabled {
    cursor: not-allowed;
    opacity: 1;
    input {
      border-color: ${props => props.theme.colors.neutralGrey4};
      background-color: ${props => props.theme.colors.neutralGrey2};
    }
  }
`;

const StyledEmploymentContainerCity = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledEmploymentContainerCountry = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledEmploymentContainerZIPCountry = styled.div`
  display: flex;
  max-width: ${props => props.theme.pxToRem(400)};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledEmploymentContainerZIP = styled.div`
  margin-right: ${props => props.theme.pxToRem(16)};
  max-width: ${props => props.theme.pxToRem(180)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(144)};
  }
`;

const StyledEmployerContainerState = styled.div`
  margin-right: ${props => props.theme.pxToRem(16)};
`;

const StyledEmploymentInputLabel = styled(Label)`
  height: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  display: inline-block;

  ${props => props.theme.mediaQueries.mobileOnly}  {
    margin: ${props => props.theme.pxToRem(12)} 0;
    display: block;
    width: 100%;
  }
`;

const StyledEmploymentResetLink = styled.div`
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  margin-top: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.primaryPurple};
`;

const StyledEmploymentVerticalLine = styled.div`
  margin-top: ${props => props.theme.pxToRem(24)};
  border-left: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey5};
  display: inline-block;
  width: 100%;
  padding-left: ${props => props.theme.pxToRem(23)};
`;

const StyledEmploymentLabelBusinessCity = styled.label`
  margin-top: ${props => props.theme.pxToRem(32)};
  margin-bottom: ${props => props.theme.pxToRem(8)};

  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};

  display: block;
`;

const StyledErrorMessage = styled.p`
  margin-bottom: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.interfaceRed};
`;
