import { createSelector } from 'reselect';
import { selectedContentLanguageIdSelector } from '../layouts/selectors';
import { slugIdMapCreator } from './helpers';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.contentLanguages,
  contentLanguagesRoot => contentLanguagesRoot
);

export const contentLanguagesFetchedSelector = createSelector(
  rootSelector,
  (contentLanguages: State.ContentLanguages): boolean => contentLanguages.contentLanguagesFetched
);

export const contentLanguagesHashSelector = createSelector(
  rootSelector,
  (contentLanguagesRoot: State.ContentLanguages): State.ContentLanguagesHash | null =>
    contentLanguagesRoot.contentLanguagesHash
);

const contentLanguagesSelector = createSelector(
  contentLanguagesHashSelector,
  (contentLanguagesHash: State.ContentLanguagesHash | null): State.ContentLanguage[] | null =>
    contentLanguagesHash && Object.values(contentLanguagesHash)
);

export const contentLanguagesSlugIdMapSelector = createSelector(contentLanguagesSelector, slugIdMapCreator);

export const contentLanguagesSlugsStrSelector = createSelector(
  contentLanguagesSlugIdMapSelector,
  (contentLanguagesSlugIdMap: { [key: string]: string }): string =>
    Object.keys(contentLanguagesSlugIdMap || emptyObject).join('|')
);

export const selectedContentLanguageNameSelector = createSelector(
  [contentLanguagesHashSelector, selectedContentLanguageIdSelector],
  (
    contentLanguagesHash: State.ContentLanguagesHash | null,
    selectedContentLanguageId: string | null
  ): string | null => {
    if (!contentLanguagesHash || !selectedContentLanguageId || !contentLanguagesHash[selectedContentLanguageId]) {
      return null;
    }
    return contentLanguagesHash[selectedContentLanguageId].name;
  }
);
