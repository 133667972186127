import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { useVergicChat } from 'hooks/useVergicChat';
import { useEngagementMenu } from 'hooks/useEngagementMenu';
import { useSentry } from 'hooks/useSentry';
import { useSaveQueryParams } from 'hooks/useSaveQueryParams';
import { useSelector } from 'react-redux';
import { IS_SITE_OFFLINE, USE_NEW_MEMBERSHIP_AICPA } from 'modules/featureToggle/constants';
import { featureTogglesSelector, getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { ModalProvisionProcessingContainer } from 'containers/ModalProvisionProcessingContainer';
import { AuthenticationModalContainer } from 'containers/AuthenticationModalContainer';
import { CPABanner } from 'components/molecules/CPABanner/CPABanner';
import { Notifications } from 'components/molecules/Notifications';
import { PageHelmet } from 'components/atoms/PageHelmet/PageHelmet';
import { SecureRoute } from 'components/atoms/SecureRoute/SecureRoute';
import { NonAuthRoute } from 'components/atoms/NonAuthRoute/NonAuthRoute';
import { Routes } from 'constants/index';
import { getPath, isServer } from 'utils';

// FixMe: react-frontload with react-loadable call method twice, issue similar to: https://github.com/davnicwil/react-frontload/issues/19
import { PageContentContainer } from 'containers/PageContentContainer';
import { PageToolkitContainer } from 'containers/PageToolkitContainer';
import { PageProductContainer } from 'containers/PageProductContainer';
import { GdprContainer } from 'containers/GdprFormContainer';
import { PageFirm } from 'components/pages/PageFirm/PageFirm';
import { NotFound } from 'components/pages/NotFound/NotFound';
import { PageCenterAdmin } from 'components/pages/PageCenterAdmin/PageCenterAdmin';
import { PageOrganizationNew } from 'components/pages/PageOrganizationNew/PageOrganizationNew';
import { PageCenterMembershipRenewal } from 'components/pages/PageCenterMembershipRenewal/PageCenterMembershipRenewal';
import { PageCenterAdminInvoicesPaymentPage } from 'components/pages/PageCenterAdminInvoicesPaymentPage/PageCenterAdminInvoicesPaymentPage';
import { PageCenterAdminInvoiceError } from 'components/pages/PageCenterAdminInvoiceError/PageCenterAdminInvoiceError';
import { PageInvoicesPaymentConfirmation } from 'components/pages/PageInvoicesPaymentConfirmation/PageInvoicesPaymentConfirmation';
import {
  PageCategoryAggregationContainer,
  PageLandingContainer,
  PageSearchContainer,
  PageLoginContainer,
  PageForgotEmailContainer,
  PageHomeFeedContainer,
  PagePreferencesContainer,
  PersonalInformationContainer,
  PagePromptToSetPreferences,
  PageOrderConfirmationContainer,
  PageOrderError,
  PageCheckoutContainer,
  PageInvoiceCheckoutContainer,
  PageInvoicePaymentConfirmationContainer,
  PageFeatureToggleContainer,
  ModalTopicUnfollowContainer,
  PageCartContainer,
  ProductAccessError,
  PageInvoiceError,
  ModalPaymentProcessingContainer,
  ModalAddressValidationContainer,
  PageForgotPasswordContainer,
  PageResetPasswordContainer,
  PageProductAggregationContainer,
  PageProductCalendarContainer,
  PageTopicAggregationContainer,
  PageIndustryAggregationContainer,
  PageTrendAggregationContainer,
  PageSkillAggregationContainer,
  PageBlogFeedContainer,
  ModalResetImpersonatedUserPasswordContainer,
  PageStaticLandingContainer,
  PageMembershipApplication,
  FcmaPageCredentialApplication,
  PageMembershipPackage,
  PageMembershipBenefitsContainer,
  PageCenterMembershipPackage,
  PageCenterMembershipApplication,
  PageLoginSecurity,
  PageMyProfile,
  MyCommunications,
  MyContentPreferences,
  PageReceiptCheckoutContainer,
  // PersonalInformation,
  MyWallet,
  PageMemberCredentialDirectoryContainer,
  PageMemberCredentialDirectoryResultsContainer,
  PageMyPurchasesContainer,
  PageCenterAdminInvoicePaymentConfirmationContainer,
  PageCredentialsApplication,
  PageCimaMembershipStudyPath,
  PageCimaMembershipExemptionContainer,
  PageCimaMembershipPackage,
  PageMembershipInviteThankyouContainer,
  PagePracticalExperienceRequirement,
  PageCimaExamsDashboard,
  PageCimaExamsExemptions,
  PageCimaExamsGateway,
  PageCimaMembershipPromoCodeContainer,
  PageCimaMembershipExamFileUpload,
  PageCimaMembershipExamEPA2,
  PageCimaMembershipEPA2L7,
  PageCimaSupervisor,
  PageCimaMembershipDocumentUpload,
  PageConfirmationBenefitsContainer,
  PageTuitionProviderDetails,
  PageTuitionProviderApprenticeRoster,
  PageTuitionProvider,
  PageEventRegistrationContainer,
  PageMipContactDetails,
  PageMipWorkHistory,
  MipPracticeRequirementsContainer,
  PagePostExamsSpecialConsiderationContainer,
  MipAmlApplicationContainer,
  PageMipCheckout,
  MipMainApplicationContainer,
  PageMipRenewalMainApplication,
  PageMipRenewalPracticeRequirements,
  PageSkillsPreferencesContainer,
  CimaExemptionCalculatorContainer,
  PageIndustriesPreferencesContainer,
  PagePracticeAreasPreferencesContainer,
  ProfileSavedItemsContainer,
  PageDoubleDonation,
  PagePreSignUp,
} from './lazy-imports';
import { CaliforniaFormContainer } from 'containers/CaliforniaFormContainer';
import { PageCimaExamResultsContainer } from 'containers/PageCimaExamResultsContainer';
import { isAuthSelector } from 'modules/user/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { useDoubleDonation } from 'hooks/useDoubleDonation';
import { holdingPageBypassSelector } from 'modules/startup/selectors';
import { useQualtricsFeedback } from 'hooks/useQualtricsFeedback';
import { useHistory } from 'react-router';
import { PageCenterAdminProvisioningContainer } from 'containers/PageCenterAdminProvisioningContainer';
import { PageCimaAllExamResultsContainer } from 'containers/PageCimaAllExamResultsContainer';
import { PageShareAllocationContainer } from 'containers/PageShareAllocationContainer';
import { PageSiteOfflineContainer } from 'containers/PageSiteOfflineContainer';
import { PageTitleContainer } from 'containers/PageTitleContainer';

// https://github.com/jamiebuilds/react-loadable#loadablecomponentpreload
PageLandingContainer.preload();

interface Props {
  isAuth: boolean | null;
  isAdminPortal: boolean;
  isClientAdmin: boolean;
  isFirmBillingClientAdmin: boolean;
  isCentersClientAdmin: boolean;
  isDataExist: boolean;
  hasPreferences: boolean;
  hasIndustryPreferences: boolean;
  hasTrendPreferences: boolean;
  hasSkillPreferences: boolean;
  appInit(): void;
  showTogglePage: boolean;
  userSessionInvalidated: boolean;
  useCR215ExamResultDisplay: boolean;
  productTypesSlugsStr: string;
  topicsSlugsStr: string;
  cpaStatusFetched: boolean | null;
  isLicensedCPA: boolean | null;
  isUserMember: boolean;
  isPaperBilling: boolean | null;
  isUserMemberSuspended: boolean;
  inviteId: string;
  useCredentialDirectory?: boolean;
  useCR205OfflineExemptionCalculator?: boolean;
  hasUserPerManagementLevel?: boolean;
}

export const App: React.FC<Props> = ({
  isAuth,
  isAdminPortal,
  isClientAdmin,
  isFirmBillingClientAdmin,
  isCentersClientAdmin,
  hasPreferences,
  hasIndustryPreferences,
  hasTrendPreferences,
  hasSkillPreferences,
  isDataExist,
  showTogglePage,
  userSessionInvalidated,
  appInit,
  productTypesSlugsStr,
  topicsSlugsStr,
  cpaStatusFetched,
  isLicensedCPA,
  isUserMember,
  useCR215ExamResultDisplay,
  isPaperBilling,
  isUserMemberSuspended,
  useCredentialDirectory,
  inviteId,
  useCR205OfflineExemptionCalculator,
  hasUserPerManagementLevel,
}) => {
  useVergicChat();
  useEngagementMenu();
  useSentry();
  useSaveQueryParams();
  useDoubleDonation();
  useQualtricsFeedback();

  React.useEffect(() => {
    appInit();
  }, [appInit]);

  const isSiteOffline = useSelector(state => getFeatureToggleByKeySelector(state as State.Root, IS_SITE_OFFLINE));
  const holdingPageBypass = useSelector(state => holdingPageBypassSelector(state as State.Root));
  const history = useHistory();

  const useNewMembershipAICPA = useSelector(state => getFeatureToggleByKeySelector(state as State.Root, USE_NEW_MEMBERSHIP_AICPA));
  const newStartPath = useNewMembershipAICPA ? Routes.APPLICATION_FORM_PERSONAL : Routes.APPLICATION_FORM_START;
  const isInCimaMembershipPromotionPage = history?.location?.pathname?.includes('/cima-membership/promotion') || false;

  const isShowingNotFound = !inviteId ? true : !isInCimaMembershipPromotionPage;
  // if no inviteId = true, else check if it's in promotion page

  const renderRouteHome = (props: RouteComponentProps<{ slug: string }>): React.ReactNode => {
    return isAuth ? (
      <Redirect exact from={getPath(Routes.HOME)} to={getPath(Routes.FEED)} />
    ) : !props.match.params.slug ? (
      <PageLandingContainer {...props} />
    ) : (
      <Redirect
        exact
        from={getPath(Routes.HOME)}
        to={`${getPath(Routes.CAMPAIGNS, { withoutParams: true })}/${props.match.params.slug}`}
      />
    );
  };

  const renderRouteFeed = (props: RouteComponentProps): React.ReactNode => {
    if (isAuth) {
      return hasPreferences || hasIndustryPreferences || hasTrendPreferences || hasSkillPreferences ? (
        <PageHomeFeedContainer {...props} />
      ) : (
        <PagePromptToSetPreferences />
      );
    }
    return <Redirect exact from={getPath(Routes.FEED)} to={getPath(Routes.HOME, { withoutParams: true })} />;
  };

  const renderBlogRouteFeed = (props: RouteComponentProps): React.ReactNode => {
    return <PageBlogFeedContainer {...props} />;
  };

  const renderRouteCampaigns = (props: RouteComponentProps<{ slug: string }>): React.ReactNode => {
    return <PageLandingContainer {...props} />;
  };

  const renderPageInvoiceCheckout = (props: RouteComponentProps<{ invoiceId: string }>): React.ReactNode => (
    <>
      <PageInvoiceCheckoutContainer {...props} />
      <ModalPaymentProcessingContainer />
    </>
  );

  const renderPageReceiptCheckout = (props: RouteComponentProps<{ invoiceId: string }>): React.ReactNode => (
    <>
      <PageReceiptCheckoutContainer {...props} />
      <ModalPaymentProcessingContainer />
    </>
  );

  const renderPageMyProfile = (props: RouteComponentProps): React.ReactNode => {
    return (
      <>
        <PageMyProfile {...props} />
        <ModalResetImpersonatedUserPasswordContainer />
      </>
    );
  };

  const renderMyProfilePages = (): React.ReactNode => (
    <Switch>
      <Route exact path={getPath(Routes.MY_PERSONAL_DETAILS)} component={PersonalInformationContainer} />
      <Route exact path={getPath(Routes.MY_CONTACT_DETAILS)} component={PersonalInformationContainer} />
      <Route exact path={getPath(Routes.MY_EMPLOYMENT_DETAILS)} component={PersonalInformationContainer} />
      <Route exact path={getPath(Routes.MY_TUITION_PROVIDER)} component={PersonalInformationContainer} />
      <Route exact path={getPath(Routes.PROFILE_LOGIN_SETTINGS)} component={PageLoginSecurity} />
      <Route exact path={getPath(Routes.PROFILE_SECURITY_QUESTION)} component={PageLoginSecurity} />
      <Route exact path={getPath(Routes.MY_PRACTICE_AREAS)} component={MyContentPreferences} />
      <Route exact path={getPath(Routes.MY_INDUSTRIES)} component={MyContentPreferences} />
      <Route exact path={getPath(Routes.MY_TRENDS)} component={MyContentPreferences} />
      <Route exact path={getPath(Routes.MY_LOCATIONS)} component={MyContentPreferences} />
      <Route exact path={getPath(Routes.MY_JOBROLES)} component={MyContentPreferences} />
      <Route exact path={getPath(Routes.MY_CAREERSTAGES)} component={MyContentPreferences} />
      <Route exact path={getPath(Routes.MY_CONTENTLANGUAGES)} component={MyContentPreferences} />
      <Route exact path={getPath(Routes.MY_SKILLS)} component={MyContentPreferences} />
      <Route exact path={getPath(Routes.MY_TOPIC_PREFERENCES)} component={MyCommunications} />
      <Route exact path={getPath(Routes.MY_CONSENTS)} component={MyCommunications} />
      <Route exact path={getPath(Routes.MY_MARKETING_PREFERENCES)} component={MyCommunications} />
      <Route exact path={getPath(Routes.MY_PAYMENT_METHODS)} component={MyWallet} />
      <Route exact path={getPath(Routes.MY_RECEIPTS)} component={MyWallet} />
      <Route exact path={getPath(Routes.MY_PURCHASES)} component={PageMyPurchasesContainer} />
      <Route path={getPath(Routes.MY_PROFILE_ROOT)} render={renderPageMyProfile} />
      <Route component={NotFound} />
    </Switch>
  );

  const renderOrganizationPages = (): React.ReactNode => {
    if (!isClientAdmin) return <Route component={NotFound} />;
    return (
      <Switch>
        <Route exact path={getPath(Routes.PRODUCT_ACCESS_ERROR_PAGE)} component={ProductAccessError} />
        <Route exact path={getPath(Routes.ORG_INVOICE_CHECKOUT)} render={renderPageInvoiceCheckout} />
        <Route exact path={getPath(Routes.ORG_INVOICE_CHECKOUT_PAYPAL_PROCEED)} render={renderPageInvoiceCheckout} />
        <Route
          exact
          path={getPath(Routes.ORG_INVOICE_PAYMENT_CONFIRMATION)}
          component={PageInvoicePaymentConfirmationContainer}
        />
        <Route
          exact
          path={getPath(Routes.ORG_INVOICES_PAYMENT_CONFIRMATION)}
          component={PageInvoicesPaymentConfirmation}
        />
        <Route path={getPath(Routes.ORG_ROOT)} component={PageOrganizationNew} />
        <Route component={NotFound} />
      </Switch>
    );
  };

  const renderFirmBillingPages = (): React.ReactNode => {
    if (!isFirmBillingClientAdmin) return <Route component={NotFound} />;
    return (
      <Switch>
        <Route exact path={getPath(Routes.FIRM_BILLING_INVOICE_CHECKOUT)} render={renderPageInvoiceCheckout} />
        <Route
          exact
          path={getPath(Routes.FIRM_BILLING_INVOICE_CHECKOUT_PAYPAL_PROCEED)}
          render={renderPageInvoiceCheckout}
        />
        <Route
          exact
          path={getPath(Routes.FIRM_BILLING_INVOICE_CONFIRMATION)}
          component={PageInvoicePaymentConfirmationContainer}
        />
        <Route path={getPath(Routes.FIRM_ROOT)} component={PageFirm} />
        <Route component={NotFound} />
      </Switch>
    );
  };

  const renderCentersPages = (): React.ReactNode => {
    if (!isCentersClientAdmin) return <Route component={NotFound} />;
    return (
      <>
        <Switch>
          <Route
            exact
            path={getPath(Routes.CENTER_ADMIN_INVOICE_PAYMENT_ERROR)}
            component={PageCenterAdminInvoiceError}
          />
          <Route
            path={getPath(Routes.CENTER_ADMIN_INVOICE_PAYMENT_CONFIRMATION)}
            component={PageCenterAdminInvoicePaymentConfirmationContainer}
          />
          <Route
            exact
            path={getPath(Routes.CENTER_ADMIN_INVOICE_PAYMENT)}
            component={PageCenterAdminInvoicesPaymentPage}
          />
          <Route path={getPath(Routes.CENTER_ADMIN_ROOT)} component={PageCenterAdmin} />
          <Route component={NotFound} />
          <Route path={getPath(Routes.TUITION_PROVIDER_ROOT)} component={PageTuitionProvider} />
          <Route component={NotFound} />
        </Switch>
        <ModalPaymentProcessingContainer />
      </>
    );
  };

  const renderPageCheckout = (props: RouteComponentProps<{ orderNumber: string }>): React.ReactNode => {
    const showPageOrderConfirmation: boolean = Boolean(props.match.params?.orderNumber);
    const isRaveCheckout: boolean = Boolean(!isAdminPortal || isAuth);
    return !showPageOrderConfirmation ? (
      <>
        <PageCheckoutContainer {...props} />
        {isRaveCheckout && <ModalAddressValidationContainer isRaveCheckout={isRaveCheckout} />}
        <ModalPaymentProcessingContainer />
      </>
    ) : (
      <PageOrderConfirmationContainer {...props} />
    );
  };

  const renderPageEventRegistration = (props: RouteComponentProps<{ orderNumber: string }>): React.ReactNode => {
    return <PageEventRegistrationContainer {...props} />;
  };

  const renderCPELearningRoutes = () => (
    <Switch>
      <Route exact path={getPath(Routes.STOREFRONT_PAGE)} component={PageLandingContainer} />
      <SecureRoute
        isAuth={isAuth}
        isPublic
        exact
        path={[getPath(Routes.STATIC_LANDING_PAGES)]}
        component={PageStaticLandingContainer}
      />
      <Route
        exact
        path={getPath(Routes.PRODUCT_PAGE).replace('productTypeSlug', `productTypeSlug(${productTypesSlugsStr})`)}
        component={PageProductContainer}
      />

      <Route exact path={getPath(Routes.PRODUCT_AGGS_CALENDAR)} component={PageProductCalendarContainer} />

      <Route
        exact
        path={`${getPath(Routes.PRODUCT_AGGS_BY_TYPE_PAGE)}(${productTypesSlugsStr})`}
        render={renderProductAggByTypePageRoute}
      />

      <Route
        exact
        path={`${getPath(Routes.PRODUCT_AGGS_BY_TOPIC_PAGE)}(${topicsSlugsStr})`}
        render={renderProductAggByTopicPageRoute}
      />

      {/* PRODUCT_PAGE_OLD must be last */}
      <Route exact path={getPath(Routes.PRODUCT_PAGE_OLD)} component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  );

  const renderProductAggByTopicPageRoute = (props: any): React.ReactNode => (
    <PageProductAggregationContainer isAggByTopicPage {...props} topicSlug={props.match.params.topicSlug} />
  );

  const renderProductAggByTypePageRoute = (props: any): React.ReactNode => (
    <PageProductAggregationContainer
      isAggByTopicPage={false}
      {...props}
      productTypeSlug={props.match.params.productTypeSlug}
    />
  );

  // Make CIMA Mip Pages be available in Impersonation Only
  const isAdmin = useSelector(isAdminPortalSelector);
  const isAuthorization = useSelector(isAuthSelector);

  const renderCimaMipPages = (): React.ReactNode => {
    return isAuthorization ? (
      <Switch>
        <Redirect exact from={getPath(Routes.CIMA_MIP_ROOT)} to={getPath(Routes.CIMA_MIP_APPLICATION_MAIN_PAGE)} />
        {
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_MIP_APPLICATION_MAIN_PAGE)}
            component={MipMainApplicationContainer}
          />
        }
        {
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_MIP_CONTACT_APPLICATION_PAGE)}
            component={PageMipContactDetails}
          />
        }
        {
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_MIP_WORK_HISTORY_APPLICATION_PAGE)}
            component={PageMipWorkHistory}
          />
        }
        {
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_MIP_PRACTICE_REQUIREMENTS_APPLICATION_PAGE)}
            component={MipPracticeRequirementsContainer}
          />
        }
        {
          <SecureRoute
            isAuth={isAuth}
            exact
            path={[
              getPath(Routes.CIMA_MIP_AML_APPLICATION_CTF_RETURN_FORM),
              getPath(Routes.CIMA_MIP_AML_APPLICATION_CTF_RETURN_RESPONSE_SUMMARY),
              getPath(Routes.CIMA_MIP_AML_APPLICATION_ATTESTATION),
            ]}
            component={MipAmlApplicationContainer}
          />
        }
        <Redirect
          exact
          from={getPath(Routes.CIMA_MIP_AML_APPLICATION)}
          to={getPath(Routes.CIMA_MIP_AML_APPLICATION_CTF_RETURN_FORM)}
        />
        {
          <SecureRoute
            isAuth={isAuth}
            exact
            path={[
              getPath(Routes.CIMA_MIP_CHECKOUT_ATTESTATION),
              getPath(Routes.CIMA_MIP_CHECKOUT_MIP_DETAILS),
              getPath(Routes.CIMA_MIP_CHECKOUT_ADDRESS),
              getPath(Routes.CIMA_MIP_CHECKOUT_PAYMENT),
            ]}
            component={PageMipCheckout}
          />
        }
        <Redirect exact from={getPath(Routes.CIMA_MIP_CHECKOUT)} to={getPath(Routes.CIMA_MIP_CHECKOUT_ATTESTATION)} />
      </Switch>
    ) : (
      <Route component={NotFound} />
    );
  };

  const renderCimaMIPRenewalPages = (): React.ReactNode => {
    const isImpersonation = Boolean(isAuthorization) && isAdmin;
    return isImpersonation ? (
      <Switch>
        {
          <>
            <Redirect
              exact
              from={getPath(Routes.CIMA_MIP_RENEWAL_ROOT)}
              to={getPath(Routes.CIMA_MIP_RENEWAL_APPLICATION_MAIN_PAGE)}
            />
            <SecureRoute
              isAuth={isAuth}
              exact
              path={getPath(Routes.CIMA_MIP_RENEWAL_APPLICATION_MAIN_PAGE)}
              component={PageMipRenewalMainApplication}
            />
            <SecureRoute
              isAuth={isAuth}
              exact
              path={getPath(Routes.CIMA_MIP_RENEWAL_CONTACT_APPLICATION_PAGE)}
              component={PageMipContactDetails}
            />
            <SecureRoute
              isAuth={isAuth}
              exact
              path={getPath(Routes.CIMA_MIP_RENEWAL_PRACTICE_REQUIREMENTS_APPLICATION_PAGE)}
              component={PageMipRenewalPracticeRequirements}
            />
            <SecureRoute
              isAuth={isAuth}
              exact
              path={[
                getPath(Routes.CIMA_MIP_RENEWAL_AML_APPLICATION_CTF_RETURN_FORM),
                getPath(Routes.CIMA_MIP_RENEWAL_AML_APPLICATION_CTF_RETURN_RESPONSE_SUMMARY),
                getPath(Routes.CIMA_MIP_RENEWAL_AML_APPLICATION_ATTESTATION),
              ]}
              component={MipAmlApplicationContainer}
            />
          </>
        }
      </Switch>
    ) : (
      <Route component={NotFound} />
    );
  };

  const renderRoutes = (): React.ReactNode => {
    if (!isDataExist || userSessionInvalidated || isAuth === null) {
      return <Loader active content="Loading" />;
    }

    return (
      <>
        <Switch>
          <NonAuthRoute isAuth={isAuth} path={getPath(Routes.LOGIN)} exact component={PageLoginContainer} />
          <NonAuthRoute
            isAuth={isAuth}
            path={getPath(Routes.FORGOT_EMAIL)}
            exact
            component={PageForgotEmailContainer}
          />
          {<Route exact path={getPath(Routes.DOUBLE_DONATION)} component={PageDoubleDonation} />}
          <NonAuthRoute
            isAuth={isAuth}
            path={getPath(Routes.RESET_PASSWORD)}
            exact
            component={PageResetPasswordContainer}
          />
          <NonAuthRoute
            isAuth={isAuth}
            path={getPath(Routes.FORGOT_PASSWORD)}
            exact
            component={PageForgotPasswordContainer}
          />
          <NonAuthRoute isAuth={isAuth} path={getPath(Routes.REGISTRATION)} exact component={PageLoginContainer} />

          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CENTER_MEMBERSHIP_FORM)}
            component={PageCenterMembershipPackage}
          />
          {/* CIMA ROUTES */}
          <SecureRoute
            isAuth={isAuth}
            exact
            path={[getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY)]}
            component={PageCimaMembershipStudyPath}
          />
          <Redirect
            exact
            from={getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH)}
            to={getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY)}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={[
              getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_PROFESSIONAL),
              getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_EDUCATIONAL),
              getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_WORK),
            ]}
            component={PageCimaMembershipExemptionContainer}
          />

          {useCR205OfflineExemptionCalculator && (
            <Redirect
              exact
              from={getPath(Routes.EXEMPTION_CALCULATOR_ROOT)}
              to={getPath(Routes.EXEMPTION_CALCULATOR_PROFESSIONAL)}
            />
          )}
          {useCR205OfflineExemptionCalculator && (
            <Route
              isPublic
              exact
              path={[
                getPath(Routes.EXEMPTION_CALCULATOR_EDUCATIONAL),
                getPath(Routes.EXEMPTION_CALCULATOR_PROFESSIONAL),
                getPath(Routes.EXEMPTION_CALCULATOR_WORK),
                getPath(Routes.EXEMPTION_CALCULATOR_SUMMARY),
                getPath(Routes.EXEMPTION_CALCULATOR_RESULT),
              ]}
              component={CimaExemptionCalculatorContainer}
            />
          )}

          <Redirect
            exact
            from={getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS)}
            to={getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_PROFESSIONAL)}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}
            component={PageCimaMembershipPackage}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_MEMBERSHIP_EXAM_FILE_UPLOAD)}
            component={PageCimaMembershipExamFileUpload}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_MEMBERSHIP_DOCUMENT_FILE_UPLOAD)}
            component={PageCimaMembershipDocumentUpload}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={[
              getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_START),
              getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL),
              getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_CPA),
              getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_EMPLOYMENT),
              getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_TRAINING),
              getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DOCUMENT),
              getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_GATEWAY_DOCUMENT),
              getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS),
              getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION),
            ]}
            component={PageMembershipApplication}
          />
          <Redirect
            exact
            from={getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM)}
            to={
              isPaperBilling
                ? `${getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_START)}?isPaperBilling=true`
                : getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_START)
            }
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.EVENT_REGISTRATION)}
            render={renderPageEventRegistration}
          />
          {/* here */}
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_MEMBERSHIP_PROMO_CODE)}
            component={PageCimaMembershipPromoCodeContainer}
            isInCimaMembershipPromotionPage={isInCimaMembershipPromotionPage}
          />

          <Redirect
            exact
            from={getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM)}
            to={getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_START)}
          />

          {/* CENTER MEMBERSHIP RENEWAL ROUTES */}
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CENTER_MEMBERSHIP_RENEWAL)}
            component={PageCenterMembershipRenewal}
          />

          <SecureRoute
            isAuth={isAuth}
            exact
            path={[
              getPath(Routes.CENTER_MEMBERSHIP_FORM_START),
              getPath(Routes.CENTER_MEMBERSHIP_FORM_ADMIN),
              getPath(Routes.CENTER_MEMBERSHIP_FORM_FIRM),
              getPath(Routes.CENTER_MEMBERSHIP_FORM_ATTESTATION),
            ]}
            component={PageCenterMembershipApplication}
          />

          <Redirect
            exact
            from={getPath(Routes.CENTER_MEMBERSHIP_APPLICATION_FORM)}
            to={getPath(Routes.CENTER_MEMBERSHIP_FORM_START)}
          />

          <SecureRoute isAuth={isAuth} exact path={getPath(Routes.PRE_SIGN_UP)} component={PagePreSignUp} />
          <SecureRoute isAuth={isAuth} exact path={getPath(Routes.MEMBERSHIP_FORM)} component={PageMembershipPackage} />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={ useNewMembershipAICPA ? [
              getPath(Routes.APPLICATION_FORM_PERSONAL),
              getPath(Routes.APPLICATION_FORM_QUALIFICATION),
              getPath(Routes.APPLICATION_FORM_EMPLOYMENT),
              getPath(Routes.APPLICATION_FORM_DONATIONS),
              getPath(Routes.APPLICATION_FORM_ATTESTATION),
            ] : [
              getPath(Routes.APPLICATION_FORM_START),
              getPath(Routes.APPLICATION_FORM_PERSONAL),
              getPath(Routes.APPLICATION_FORM_QUALIFICATION),
              getPath(Routes.APPLICATION_FORM_EMPLOYMENT),
              getPath(Routes.APPLICATION_FORM_DONATIONS),
              getPath(Routes.APPLICATION_FORM_ATTESTATION),
            ]}
            component={PageMembershipApplication}
          />

          {
            <SecureRoute
              isAuth={isAuth}
              exact
              path={[
                getPath(Routes.FCMA_CREDENTIAL_APPLICATION_FORM_PERSONAL),
                getPath(Routes.FCMA_CREDENTIAL_APPLICATION_FORM_EMPLOYMENT),
                getPath(Routes.FCMA_CREDENTIAL_APPLICATION_FORM_DONATIONS),
                getPath(Routes.FCMA_CREDENTIAL_APPLICATION_FORM_ATTESTATION),
              ]}
              component={FcmaPageCredentialApplication}
            />
          }

          <Redirect
            exact
            from={getPath(Routes.APPLICATION_FORM)}
            to={
              isPaperBilling
                ? `${getPath(newStartPath)}?isPaperBilling=true`
                : getPath(newStartPath)
            }
          />

          {/* Redirect to 404 to disable customer from accessing this URL as it allows customer to add FCMA to cart and skipping the eligibility check. Including the ACMA */}
          <Redirect exact from={getPath(Routes.CIMA_FCMA_CREDENTIAL_ROUTE)} to={getPath(Routes.NOT_FOUND)} />
          <Redirect exact from={getPath(Routes.CIMA_ACMA_CREDENTIAL_ROUTE)} to={getPath(Routes.NOT_FOUND)} />

          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.MEMBERSHIP_BENEFIT)}
            component={PageMembershipBenefitsContainer}
          />

          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CONFIRMATION_BENEFIT)}
            component={PageConfirmationBenefitsContainer}
          />

          {
            <SecureRoute
              isAuth={isAuth}
              exact
              path={getPath(Routes.CIMA_POST_EXAMS_SPECIAL_CONSIDERATIONS)}
              component={PagePostExamsSpecialConsiderationContainer}
            />
          }

          <Redirect exact from={getPath(Routes.APPLICATION_FORM)} to={getPath(newStartPath)} />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CREDENTIALS_APPLICATION_FORM)}
            component={PageCredentialsApplication}
          />

          {<SecureRoute isAuth={isAuth} path={getPath(Routes.CIMA_MIP_ROOT)} render={renderCimaMipPages} />}

          {
            <SecureRoute
              isAuth={isAuth}
              path={getPath(Routes.CIMA_MIP_RENEWAL_ROOT)}
              render={renderCimaMIPRenewalPages}
            />
          }

          <Route exact path={getPath(Routes.CART_PAGE)} component={PageCartContainer} />
          <SecureRoute isAuth={isAuth} path={getPath(Routes.ORG_ROOT)} render={renderOrganizationPages} />
          <SecureRoute isAuth={isAuth} path={getPath(Routes.FIRM_ROOT)} render={renderFirmBillingPages} />
          <SecureRoute isAuth={isAuth} path={getPath(Routes.CENTER_ADMIN_ROOT)} render={renderCentersPages} />
          <Route exact path={getPath(Routes.PROFILE_RECEIPT_CHECKOUT)} render={renderPageReceiptCheckout} />
          <Route
            exact
            path={getPath(Routes.PROFILE_RECEIPT_PAYMENT_CONFIRMATION)}
            component={PageInvoicePaymentConfirmationContainer}
          />
          <SecureRoute
            isAuth={isAuth}
            path={getPath(Routes.PROFILE_SAVED_ITEMS)}
            component={ProfileSavedItemsContainer}
          />
          <SecureRoute isAuth={isAuth} path={getPath(Routes.MY_PROFILE_ROOT)} render={renderMyProfilePages} />

          <Route
            isAuth={isAuth}
            isPublic
            exact
            path={getPath(Routes.PRIVACY_CALIFORNIA_FORM)}
            component={CaliforniaFormContainer}
          />
          <Route isAuth={isAuth} isPublic exact path={getPath(Routes.PRIVACY_GDPR)} component={GdprContainer} />
          <SecureRoute isAuth={isAuth} exact path={getPath(Routes.PREFERENCES)} component={PagePreferencesContainer} />
          <SecureRoute isAuth={isAuth} isPublic exact path={getPath(Routes.FEED)} render={renderRouteFeed} />
          <SecureRoute isAuth={isAuth} isPublic path={getPath(Routes.HOME)} exact render={renderRouteHome} />
          <SecureRoute isAuth={isAuth} isPublic path={getPath(Routes.CAMPAIGNS)} exact render={renderRouteCampaigns} />
          <SecureRoute isAuth={isAuth} isPublic path={getPath(Routes.BLOG_FEED)} render={renderBlogRouteFeed} />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.SHARE_ALLOCATION)}
            component={PageShareAllocationContainer}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CENTER_MEMBERSHIP_PROVISIONING)}
            component={PageCenterAdminProvisioningContainer}
          />
          <Redirect
            exact
            from={getPath(Routes.ROOT)}
            to={isAuth ? getPath(Routes.FEED) : getPath(Routes.HOME, { withoutParams: true })}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            isPublic
            path={getPath(Routes.TOPIC_AGGS_PAGE)}
            component={PageTopicAggregationContainer}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            isPublic
            path={getPath(Routes.INDUSTRY_AGGS_PAGE)}
            component={PageIndustryAggregationContainer}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            isPublic
            path={getPath(Routes.TREND_AGGS_PAGE)}
            component={PageTrendAggregationContainer}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            isPublic
            path={getPath(Routes.SKILL_AGGS_PAGE)}
            component={PageSkillAggregationContainer}
          />
          <SecureRoute
            exact
            isAuth={isAuth}
            isPublic
            path={getPath(Routes.CATEGORY_AGGS_PAGE)}
            component={PageCategoryAggregationContainer}
          />
          <SecureRoute isAuth={isAuth} isPublic path={getPath(Routes.SEARCH)} component={PageSearchContainer} />
          <SecureRoute
            exact
            isAuth={isAuth}
            isPublic
            path={getPath(Routes.PREMIUM_CONTENT_AGGS_PAGE)}
            component={PageCategoryAggregationContainer}
          />
          <SecureRoute isAuth={isAuth} exact path={getPath(Routes.ORDER_ERROR_PAGE)} component={PageOrderError} />
          <SecureRoute isAuth={isAuth} exact path={getPath(Routes.ORG_INVOICE_ERROR)} component={PageInvoiceError} />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.PROFILE_RECEIPT_CHECKOUT_ERROR)}
            component={PageInvoiceError}
          />
          <SecureRoute
            isAuth={isAuth}
            path={getPath(Routes.STOREFRONT_PAGE)}
            isPublic
            render={renderCPELearningRoutes}
          />
          <SecureRoute isAuth={isAuth} exact path={getPath(Routes.CHECKOUT_PAGE)} render={renderPageCheckout} />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.PAYPAL_PAYMENT_CHECKOUT_PROCEED)}
            render={renderPageCheckout}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.PAYPAL_PAYMENT_CHECKOUT_CANCELLED)}
            render={renderPageCheckout}
          />
          <SecureRoute
            isAuth={isAuth}
            isPublic
            exact
            path={getPath(Routes.TOOLKIT_PAGE)}
            component={PageToolkitContainer}
          />
          <SecureRoute isAuth={isAuth} exact path={getPath(Routes.HELP)} isPublic component={PageContentContainer} />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={[getPath(Routes.CONTENT_PAGE_OLD), getPath(Routes.CONTENT_PAGE), getPath(Routes.CONTENT_PAGE_PREFIX)]}
            isPublic
            component={PageContentContainer}
          />
          <Route
            path={getPath(Routes.FEATURE_TOGGLES)}
            component={showTogglePage ? PageFeatureToggleContainer : NotFound}
          />
          <SecureRoute
            isAuth={isAuth}
            isPublic
            exact
            path={getPath(Routes.STATIC_LANDING_PAGES)}
            component={PageStaticLandingContainer}
          />
          <SecureRoute isAuth={isAuth} path={getPath(Routes.DONATION_PAGE)} isPublic component={PageProductContainer} />
          {useCredentialDirectory && (
            <Route
              isAuth={isAuth}
              exact
              path={getPath(Routes.MEMBER_CREDENTIAL_DIRECTORY)}
              component={PageMemberCredentialDirectoryContainer}
            />
          )}
          {useCredentialDirectory && (
            <Route
              isAuth={isAuth}
              exact
              path={getPath(Routes.MEMBER_CREDENTIAL_DIRECTORY_RESULTS)}
              component={PageMemberCredentialDirectoryResultsContainer}
            />
          )}
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.INVITE_THANKYOU_PAGE)}
            isPublic
            component={PageMembershipInviteThankyouContainer}
          />
          {hasUserPerManagementLevel && (
            <SecureRoute
              isAuth={isAuth}
              exact
              path={[
                getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT),
                getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES),
                getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_SKILLS_BEHAVIORS),
                getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT),
              ]}
              component={PagePracticalExperienceRequirement}
            />
          )}
          {
            <SecureRoute
              isAuth={isAuth}
              exact
              path={[
                getPath(Routes.CIMA_EXAMS_EXEMPTIONS_VIEW_FILES),
                getPath(Routes.CIMA_EXAMS_EXEMPTIONS_FILE_UPLOAD),
              ]}
              component={PageCimaExamsExemptions}
            />
          }

          {
            <SecureRoute
              isAuth={isAuth}
              exact
              path={[getPath(Routes.CIMA_EXAMS_GATEWAY_VIEW_FILES), getPath(Routes.CIMA_EXAMS_GATEWAY_FILE_UPLOAD)]}
              component={PageCimaExamsGateway}
            />
          }

          {
            <Route
              exact
              path={[
                getPath(Routes.CIMA_REVIEWER_ROOT),
                getPath(Routes.CIMA_REVIEWER_DETAILS),
                getPath(Routes.CIMA_REVIEWER_EXPIRED),
                getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT),
                getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT_REVIEW),
                getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_CORE_ACTIVITIES_REVIEW),
                getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_SKILL_BEHAVIOUR_REVIEW),
                getPath(Routes.END_POINT_ASSESSMENT),
              ]}
              component={PageCimaSupervisor}
            />
          }

          {
            <SecureRoute
              isAuth={isAuth}
              exact
              path={getPath(Routes.CIMA_EXAMS_DASHBOARD_EPA2L4)}
              component={PageCimaMembershipExamEPA2}
            />
          }

          {
            <SecureRoute
              isAuth={isAuth}
              exact
              path={getPath(Routes.CIMA_EXAMS_DASHBOARD_EPA2L7)}
              component={PageCimaMembershipEPA2L7}
            />
          }
          {
            <SecureRoute
              isAuth={isAuth}
              exact
              path={[
                getPath(Routes.CIMA_EXAMS_DASHBOARD_ROOT),
                getPath(Routes.CIMA_EXAM_BOOK_PERSONAL_DETAILS),
                getPath(Routes.CIMA_EXAM_BOOK_TERMS_CONDITIONS),
              ]}
              component={PageCimaExamsDashboard}
            />
          }

          {useCR215ExamResultDisplay && (
            <SecureRoute
              isAuth={isAuth}
              exact
              path={getPath(Routes.CIMA_ALL_EXAM_RESULTS)}
              component={PageCimaAllExamResultsContainer}
            />
          )}

          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_EXAM_RESULTS)}
            component={PageCimaExamResultsContainer}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.TUITION_PROVIDER_APPRENTICE_ROSTER)}
            component={PageTuitionProviderApprenticeRoster}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.TUITION_PROVIDER_PERSONAL_DETAILS)}
            component={PageTuitionProviderDetails}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.TUITION_PROVIDER_EMPLOYER_DETAILS)}
            component={PageTuitionProviderDetails}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={[getPath(Routes.TUITION_PROVIDER_ROOT)]}
            component={PageTuitionProvider}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_ONBOARDING_INDUSTRY_PREFERENCES)}
            component={PageIndustriesPreferencesContainer}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_ONBOARDING_PRACTICE_AREA_PREFERENCES)}
            component={PagePracticeAreasPreferencesContainer}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={getPath(Routes.CIMA_ONBOARDING_SKILL_PREFERENCES)}
            component={PageSkillsPreferencesContainer}
          />
          <SecureRoute
            exact
            isAuth={isAuth}
            isPublic
            path={getPath(Routes.CATEGORY_AGGS_PAGE_CIMA)}
            component={PageCategoryAggregationContainer}
          />
          <SecureRoute
            isAuth={isAuth}
            exact
            path={[getPath(Routes.CONTENT_PAGE_OLD), getPath(Routes.CONTENT_PAGE_CIMA)]}
            isPublic
            component={PageContentContainer}
          />
          {isShowingNotFound ? (
            <Route component={NotFound} />
          ) : isInCimaMembershipPromotionPage ? (
            <Redirect exact to={getPath(Routes.CIMA_MEMBERSHIP_PROMO_CODE)} />
          ) : (
            <Loader active content="Loading" />
          )}
        </Switch>
        <SecureRoute
          exact
          isAuth={isAuth}
          skipRedirect
          path={[
            getPath(Routes.TOPIC_AGGS_PAGE),
            getPath(Routes.CONTENT_PAGE_CIMA),
            getPath(Routes.CATEGORY_AGGS_PAGE),
          ]}
          component={ModalTopicUnfollowContainer}
        />
      </>
    );
  };
  // Check if feature toggles are fetched from contentful first to avoid debouncing state
  const featureTogglesFetched = useSelector(state => featureTogglesSelector(state as State.Root));

  return (
    <>
      {Object.keys(featureTogglesFetched).length && isSiteOffline && !holdingPageBypass ? (
        <PageSiteOfflineContainer />
      ) : (
        <>
          <PageTitleContainer />
          <PageHelmet />
          {renderRoutes()}
          {!isServer && (
            <>
              {isAuth && !isUserMember && !isUserMemberSuspended && cpaStatusFetched && isLicensedCPA === null && (
                <CPABanner />
              )}
              <Notifications />
              <ModalProvisionProcessingContainer />
              <AuthenticationModalContainer />
            </>
          )}
        </>
      )}
    </>
  );
};
