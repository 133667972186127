import { UserActionNames } from 'modules/user/constants';

const analyticsKeyNames = {
  aicpaID: 'user_id',
  oktaID: 'user_okta_id',
  firstName: 'user_firstname',
  lastName: 'user_lastname',
  email: 'user_email',
  personContactId: 'user_person_contact_id',
};

const addGoldenRecordNames: any = (preferences: any, state: any) => {
  const { topicsHash } = state.topics;
  const mappedTopics: any = {};

  // Fixme: improve building of topics output for data layer
  if (preferences) {
    Object.keys(preferences).forEach(preference => {
      if (topicsHash?.[preference]) {
        const subTopics = topicsHash[preference].subtopics;
        mappedTopics[preference] = {};
        mappedTopics[preference].name = topicsHash[preference].name;
        preferences[preference].forEach((subTopic: any) => {
          if (subTopics[subTopic]) {
            mappedTopics[preference][subTopic] = {};
            mappedTopics[preference][subTopic].name = subTopics[subTopic].name;
          }
        });
      }
    });
  }
  return mappedTopics;
};

const flattenGoldenRecord = (preferences: any, state: any) => {
  const goldenRecord: any = addGoldenRecordNames(preferences, state);
  let output: string = '';
  Object.keys(goldenRecord).forEach(key => {
    const flattenedPreferences: any[] = [];
    flattenedPreferences.push(key);
    output += flattenedPreferences.concat(Object.keys(goldenRecord[key]).filter(subKey => subKey !== 'name')).join(',');
    output += '|';
  });
  return output;
};

const flattenPreferences =
  (prefType: string, responseKey: string, isSubPref: boolean = false) =>
  (preferences: any) => {
    const { type, updateGoldenRecordForCimaPreferences } = preferences;
    const selectedPreferences: any = [];
    if (type !== prefType) return null;

    if (isSubPref) {
      Object.values(updateGoldenRecordForCimaPreferences[responseKey]).forEach((value: any) => {
        value.forEach((subValue: any) => {
          selectedPreferences.push(subValue);
        });
      });
    } else {
      Object.keys(updateGoldenRecordForCimaPreferences[responseKey]).forEach(key => {
        selectedPreferences.push(key);
      });
    }

    return selectedPreferences.join(',');
  };

export const UserAnalyticsConsts = {
  [UserActionNames.RESET_PREF]: {
    name: 'undoChanges',
    accessors: [],
  },
  [UserActionNames.CREATE_GOLDEN_RECORD]: {
    name: 'updated_prefs',
    accessors: [
      {
        name: 'user_golden_record',
        path: 'updateGoldenRecord.preferences',
        processor: flattenGoldenRecord,
      },
      {
        name: 'user_structured_golden_record',
        path: 'updateGoldenRecord.preferences',
        processor: addGoldenRecordNames,
      },
    ],
  },
  [UserActionNames.CREATE_GOLDEN_RECORD_CIMA]: {
    name: 'updated_prefs',
    hideNulls: true,
    accessors: [
      {
        name: 'user_golden_record',
        path: 'updateGoldenRecordForCimaPreferences.preferences',
        processor: flattenGoldenRecord,
      },
      {
        name: 'user_structured_golden_record',
        path: 'updateGoldenRecordForCimaPreferences.preferences',
        processor: addGoldenRecordNames,
      },
      {
        name: 'preferences_practice_areas',
        path: '',
        processor: flattenPreferences('topic', 'preferences'),
      },
      {
        name: 'preferences_practice_areas_subtopics',
        path: '',
        processor: flattenPreferences('topic', 'preferences', true),
      },
      {
        name: 'preferences_industry',
        path: '',
        processor: flattenPreferences('industry', 'industryPreferences'),
      },
      {
        name: 'preferences_skills',
        path: '',
        processor: flattenPreferences('skill', 'skillPreferences'),
      },
      {
        name: 'preferences_skills_subtopics',
        path: '',
        processor: flattenPreferences('skill', 'skillPreferences', true), // add true boolean if it's a sub preference
      },
      {
        name: 'preferences_trends',
        path: '',
        processor: flattenPreferences('trend', 'trendPreferences'),
      },
    ],
  },
  [UserActionNames.GET_GOLDEN_RECORD]: {
    accessors: [
      {
        name: 'user_golden_record',
        path: 'preferences',
        processor: flattenGoldenRecord,
      },
    ],
  },
  [UserActionNames.TOGGLE_TOPIC_PREF_UPDATE_GOLDEN_RECORD]: {
    name: 'updated_prefs',
    accessors: [
      {
        name: 'user_golden_record',
        path: '',
        processor: flattenGoldenRecord,
      },
      {
        name: 'user_structured_golden_record',
        path: '',
        processor: addGoldenRecordNames,
      },
    ],
  },
  [UserActionNames.TOGGLE_SUBTOPIC_PREF_UPDATE_GOLDEN_RECORD]: {
    name: 'updated_prefs',
    accessors: [
      {
        name: 'user_golden_record',
        path: '',
        processor: flattenGoldenRecord,
      },
      {
        name: 'user_structured_golden_record',
        path: '',
        processor: addGoldenRecordNames,
      },
    ],
  },
  [UserActionNames.TOGGLE_USER_SHORTCUTS]: {
    name: 'updated_shortcuts',
    accessors: [
      {
        name: 'user_golden_record',
        path: '',
      },
      {
        name: 'user_structured_golden_record',
        path: '',
      },
    ],
  },
  [UserActionNames.CREATE_USER_SUCCESS]: {
    name: 'registration_success',
    accessors: [
      {
        name: 'modal_event',
        path: '',
        processor: () => `registration-success`,
      },
      {
        name: analyticsKeyNames.email,
        path: 'email',
      },
      {
        name: analyticsKeyNames.aicpaID,
        path: 'AICPAUID',
      },
      {
        name: analyticsKeyNames.oktaID,
        path: 'id',
      },
      {
        name: analyticsKeyNames.firstName,
        path: 'firstname',
      },
      {
        name: analyticsKeyNames.lastName,
        path: 'lastname',
      },
      {
        name: 'user_exclusive_content_permission',
        path: 'exclusiveContent',
      },
      {
        name: 'user_business_content_permission',
        path: 'businessContent',
      },
      {
        name: analyticsKeyNames.personContactId,
        path: 'personContactId',
      },
    ],
  },
  [UserActionNames.CREATE_USER_FAILED]: {
    name: 'registration_fail',
    accessors: [
      {
        name: 'modal_event',
        path: '',
        processor: () => `registration-fail`,
      },
    ],
  },
  [UserActionNames.LOGIN_SUCCESS]: {
    name: 'login_success',
    accessors: [
      {
        name: 'modal_event',
        path: '',
        processor: () => `login-success`,
      },
    ],
  },
  [UserActionNames.LOGIN_FAIL]: {
    name: 'login_fail',
    accessors: [
      {
        name: 'modal_event',
        path: '',
        processor: () => `login-fail`,
      },
    ],
  },
  [UserActionNames.LOGOUT]: {
    name: 'logout',
    accessors: [],
  },
  [UserActionNames.GET]: {
    accessors: [
      {
        name: analyticsKeyNames.oktaID,
        path: 'me.userId',
      },
      {
        name: analyticsKeyNames.firstName,
        path: 'me.firstName',
      },
      {
        name: analyticsKeyNames.lastName,
        path: 'me.lastName',
      },
      {
        name: analyticsKeyNames.email,
        path: 'me.email',
      },
      {
        name: 'user_email_2',
        path: 'me.secondEmail',
      },
      {
        name: analyticsKeyNames.aicpaID,
        path: 'me.AICPAUID',
      },
      {
        name: analyticsKeyNames.personContactId,
        path: 'personAccount.personContactId',
      },
    ],
  },
  [UserActionNames.CLEAR_ALL_SUBTOPICS]: {
    name: 'clearAllSubtopics',
    accessors: [],
  },
  [UserActionNames.SELECT_ALL_SUBTOPICS]: {
    name: 'selectAllSubtopics',
    accessors: [],
  },
  [UserActionNames.PUT_CONTENT_FEEDBACK]: {
    name: 'content_feedback',
    accessors: [
      {
        name: 'content_rating',
        path: 'feedback.rating',
      },
      {
        name: 'content_option',
        path: 'feedback.answer1',
      },
      {
        name: 'content_description',
        path: 'feedback.answer2',
      },
    ],
  },
};
