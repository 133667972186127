import React from 'react';
import { Modal } from 'components/atoms/Modal/Modal';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { ReactComponent as SuccessBubble } from 'resources/images/ic-check.svg';
import styled from 'styled-components';

interface Props {
  open: boolean;
  onCancel: () => void;
  onReturn: () => void;
  membershipType: string;
}

export const RemoveSuccessModal: React.FC<Props> = ({ open, onCancel, onReturn, membershipType }) => (
  <Modal
    open={open}
    onClose={onCancel}
    icon={<StyledSuccessBubble />}
    heading={`The selected member(s) have now been removed from`}
    headingCenterBoldText={membershipType}
    headingCenterAfterBoldText="membership."
    testId="remove-member-success-modal"
    confirmNode={
      <StyledButton
        size={ButtonEnums.sizes.small}
        variant={ButtonEnums.variants.primary}
        testId="remove-member-success-modal-return"
        onClick={onReturn}
      >
        Return to Benefits access management
      </StyledButton>
    }
  >
    <StyledText>
      Emails will be sent to those on your roster who are not included in the {membershipType} Membership anymore.
    </StyledText>
  </Modal>
);

const StyledButton = styled(Button)`
  &&&&& {
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};
    margin: 0 ${props => `${props.theme.pxToRem(5)}`};
    min-width: ${props => props.theme.pxToRem(330)};
  }
`;

const StyledSuccessBubble = styled(SuccessBubble)`
  fill: ${props => props.theme.colors.interfaceRed};
`;

const StyledText = styled.p`
  line-height: 1.57;
  text-align: center;
  max-width: ${props => props.theme.pxToRem(600)};
  margin: 0 auto;
`;
