import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { StepperBarProps } from '../StepperBar/StepperBar';

interface DivProps {
  isActive: boolean;
  isSuccess?: boolean;
  isFirst?: boolean;
  theme: DefaultTheme;
  isCIMA?: boolean;
}

export const CimaHeaderStepperBar: React.FC<StepperBarProps> = ({ steps }) => {
  return (
    <StyledStepper>
      <StepperRow>
        {steps.map(({ title, isActive, isCIMA }, i) => (
          <StepperTitle isActive={isActive} isFirst={i === 0} isCIMA={isCIMA} key={`stepper-${title}`}>
            {title}
          </StepperTitle>
        ))}
      </StepperRow>
      <StepperRow2>
        {steps.map(({ title, isActive, isSuccess, isCIMA }, i) => (
          <StepperIcons key={`icons-${title}`}>
            <StepperLongCircle isFirst={i === 0} isActive={isActive} isSuccess={isSuccess} isCIMA={isCIMA}>
              {isSuccess ? <StyledLongCircleGreen /> : isActive ? <TriangleShape /> : null}
            </StepperLongCircle>
          </StepperIcons>
        ))}
      </StepperRow2>
    </StyledStepper>
  );
};
const StyledStepper = styled.div`
  margin: 0 ${props => props.theme.pxToRem(5)};
  width: 100%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
`;

const StepperTitle = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${(props: Omit<DivProps, 'isSuccess'>) => (props.isActive ? 600 : 300)};
  margin-bottom: ${props => props.theme.pxToRem(11)};
  margin-left: ${({ isFirst, isCIMA, theme }: DivProps) =>
    isFirst ? theme.pxToRem(33) : isCIMA ? theme.pxToRem(50) : theme.pxToRem(7)};
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${props => props.theme.colors.neutralGrey8};
  width: 100%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    visibility: visible;
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin: 0;
  }
`;

const StepperLongCircle = styled.div`
  width: 100%;
  height: ${({ isActive, theme }: DivProps) => (isActive ? theme.pxToRem(6) : theme.pxToRem(3))};
  border-radius: ${props => props.theme.pxToRem(16)};
  margin-left: ${({ isFirst, isCIMA, theme }: DivProps) =>
    isCIMA ? 'auto' : isFirst ? theme.pxToRem(33) : theme.pxToRem(4)};
  margin-right: ${props => props.theme.pxToRem(4)};
  border: ${props => props.theme.pxToRem(1)};solid
    ${({ isActive, isSuccess, theme }: DivProps) =>
      isSuccess ? theme.colors.secondaryTeal : isActive ? theme.colors.primaryLightPurple : theme.colors.neutralGrey3};
  background-color: ${({ isActive, isSuccess, theme }: DivProps) =>
    isSuccess ? theme.colors.secondaryTeal : isActive ? theme.colors.primaryLightPurple : theme.colors.neutralGrey3};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: auto;
    flex: 1;
    margin-right: ${props => props.theme.pxToRem(1)};
    margin-left: ${props => props.theme.pxToRem(1)};
  }
`;

const StepperIcons = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex: 1;
    margin: 0;
  }
`;

const StepperRow = styled.div`
  width: 100%;
  display: flex;
  ${props => props.theme.mediaQueries.mobileOnly} {
    justify-content: space-around;
  }
`;

const StepperRow2 = styled.div`
  width: 100%;
  display: flex;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: 0;
  }
`;

const TriangleShape = styled.div`
  width: ${props => props.theme.pxToRem(0)};
  height: ${props => props.theme.pxToRem(0)};
  margin-top: ${props => props.theme.pxToRem(10)}
  border-left: ${props => props.theme.pxToRem(17)} solid transparent;
  border-right: ${props => props.theme.pxToRem(17)} solid transparent;
  margin-inline: auto;
  border-top: ${props => props.theme.pxToRem(15)} solid ${props => props.theme.colors.primaryLightPurple};
`;

const StyledLongCircleGreen = styled.div`
  width: ${props => props.theme.pxToRem(13)};
  height: ${props => props.theme.pxToRem(13)};
`;
