import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonEnums, OnlyMobile, OnlyDesktop, ButtonLink } from 'components/atoms';
import { useSelector } from 'react-redux';
import { staticLandingPageSelector } from 'modules/staticLandingPagesContent/selectors';
import { ReactComponent as AicpaCimaLogoWhiteMobile } from 'resources/images/aicpa-cima-logo-white-mobile.svg';
import { ReactComponent as AicpaCimaLogo } from 'resources/images/aicpa-cima-logo.svg';
import { Routes } from 'constants/index';
import { Grid } from 'semantic-ui-react';
import { Scroller, getPath } from 'utils';
import { HEADER_EVENT, PREFIXES, handleEvent } from 'utils/Analytics';

interface Props {
  logInButtonTestId: string;
  registerButtonTestId: string;
  className?: string;
  navigate: (path: string) => void;
}

export const StaticLandingPageHeaderButtons: React.FC<Props> = ({
  logInButtonTestId,
  registerButtonTestId,
  className,
  navigate,
}) => {
  const getSlpRecord = useSelector(state => staticLandingPageSelector(state as State.Root));
  const getSlpRecordLength = getSlpRecord?.simpleHeaderConfig?.length - 1;
  const navigateToElement = (value: any) => (event: React.MouseEvent) => {
    event.preventDefault();
    Scroller.scrollIntoElement(value);
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const goToHome = React.useCallback(() => {
    handleEvent({ value: `${PREFIXES.HEADER}:logo` }, HEADER_EVENT);
    navigate(getPath(Routes.ROOT));
  }, [navigate]);

  return (
    <>
      <OnlyMobile style={{ width: '100%' }}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={3} floated="left">
              {scrollPosition === 0 ? (
                <StyledAicpaCimaWhiteLogoMobile onClick={goToHome} />
              ) : (
                <AicpaCimaLogoStyled onClick={goToHome} />
              )}
            </Grid.Column>
            <Grid.Column width={11} floated="right">
              <LogInRegisterContainer className={className}>
                <StyledButtonWrapper>
                  {getSlpRecord?.simpleHeaderConfig?.map((buttonConfig: any, index: any) => (
                    <ButtonLink
                      key={index}
                      testId={index === getSlpRecordLength ? registerButtonTestId : logInButtonTestId}
                      variant={
                        index === getSlpRecordLength
                          ? scrollPosition === 0
                            ? ButtonEnums.variants.secondary
                            : ButtonEnums.variants.primaryNegative
                          : scrollPosition === 0
                          ? ButtonEnums.variants.secondaryNegative
                          : ButtonEnums.variants.primary
                      }
                      size={ButtonEnums.sizes.small}
                      onClick={navigateToElement(buttonConfig.link)}
                      to={buttonConfig.link}
                      bordered
                      fluid
                    >
                      {buttonConfig.text}
                    </ButtonLink>
                  ))}
                </StyledButtonWrapper>
              </LogInRegisterContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </OnlyMobile>
      <OnlyDesktop>
        <LogInRegisterContainer className={className}>
          <StyledButtonWrapper>
            {getSlpRecord?.simpleHeaderConfig?.map((buttonConfig: any, index: any) => (
              <ButtonLink
                key={index}
                testId={index === getSlpRecordLength ? registerButtonTestId : logInButtonTestId}
                variant={index === getSlpRecordLength ? ButtonEnums.variants.secondary : ButtonEnums.variants.primary}
                size={ButtonEnums.sizes.medium}
                onClick={navigateToElement(buttonConfig.link)}
                to={buttonConfig.link}
                bordered
                fluid
              >
                {buttonConfig.text}
              </ButtonLink>
            ))}
          </StyledButtonWrapper>
        </LogInRegisterContainer>
      </OnlyDesktop>
    </>
  );
};

const LogInRegisterContainer = styled.div`
  height: 100%;
  position: center;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: absolute;
  height: 100%;

  &&& > a:first-child {
    margin-right: ${props => props.theme.pxToRem(12)};
  }

  &&& Button:first-of-type {
    margin-right: 10px;
  }
`;

interface ILogoWhiteStyled {
  onClick(event: React.MouseEvent<HTMLDivElement>): void;
}

const StyledAicpaCimaWhiteLogoMobile = styled(AicpaCimaLogoWhiteMobile)<ILogoWhiteStyled>`
  cursor: pointer;
`;

interface ILogoStyled {
  onClick(event: React.MouseEvent<HTMLDivElement>): void;
}

const AicpaCimaLogoStyled = styled(AicpaCimaLogo)<ILogoStyled>`
  cursor: pointer;
`;
