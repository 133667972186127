import React, { useMemo } from 'react';

import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
interface Props {
  linkText: string;
  linkTitle?: string;
  linkDescription?: string;
}

export const TextPopup: React.FC<Props> = ({ linkText, linkTitle, linkDescription }) => {
  const popupTrigger = () => <StyledLink>{linkText}</StyledLink>;

  const popupContent = useMemo(
    () => (
      <div>
        <StyledTitle>{linkTitle ? linkTitle : 'How we use your Information'}</StyledTitle>
        <StyledText>
          {linkDescription
            ? linkDescription
            : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum maxime qui sunt excepturi ab placeat beatae libero hic sed voluptate eos vitae, nisi, quod vel at tenetur fuga quae officiis.'}
        </StyledText>
      </div>
    ),
    [linkTitle, linkDescription]
  );

  return <StyledPopup content={popupContent} position="top left" trigger={popupTrigger()} hoverable hideOnScroll />;
};

const StyledPopup = styled(Popup)`
  &&&& {
    box-sizing: border-box;
    padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(20)};
    min-width: ${props => props.theme.pxToRem(315)};
  }
`;

const StyledTitle = styled.h4`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;

const StyledText = styled.p`
  &&&& {
    font-family: 'Roboto';
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.light};
    color: ${props => props.theme.colors.neutralGrey8};
    margin-bottom: ${props => props.theme.pxToRem(14)};
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const StyledLink = styled.p`
  color: ${props => props.theme.colors.primaryPurple};
  text-decoration: underline;
  margin-bottom: ${props => props.theme.pxToRem(20)};
`;
