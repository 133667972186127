import { createAction } from 'redux-actions';
import { REMOVE_SAVED_ITEM, ADD_SAVED_ITEM, GET_SAVED_ITEMS } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';
import { Dispatch } from 'redux';
import { toggleNotification } from 'modules/notifications/actions';
import { loginRequiredMetaData, addMetaData } from 'middlewares';

export const savedBlogPostsLoading: any = createAction('savedBlogPosts/LOADING');

export const getSavedBlogPosts: any = createAction('savedBlogPosts/GET_SAVED_ITEMS', () =>
  request(GET_SAVED_ITEMS, { isSavedBlogPostsOnly: true }).catch(() => ({
    ids: [],
    top: [],
  }))
);

export const setSavedBlogPosts: any = createAction('savedBlogPosts/SET_SAVED_ITEMS', (savedItems: any) => savedItems);

export const toggleSavedBlogPost: any = createAction(
  'savedBlogPosts/TOGGLE_CONTENT_SAVE',
  (id: string, isOnEvent: boolean, contentSource: string, isBlogFeed?: boolean) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const message = isOnEvent
        ? { id: `${id}-save`, title: 'Added to ', text: '', type: 'save' }
        : { id: `${id}-unsave`, title: 'Removed from My Saved Items', text: '', type: 'unsave' };
      dispatch(toggleNotification(message));

      return request(isOnEvent ? ADD_SAVED_ITEM : REMOVE_SAVED_ITEM, { contentId: id, contentSource, isBlogFeed });
    },
  addMetaData(loginRequiredMetaData)
);
