import React from 'react';
import { BlockBackgroundDecorator } from 'components/organisms/BlockRenderer/BlockBackgroundDecorator';
import { ExternalAdBanner, ExternalAdBannerType } from 'components/organisms/ExternalAdBanner/ExternalAdBanner';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { PageLandingAuth } from 'components/pages/PageLanding/PageLandingAuth';
import { Footer } from 'components/molecules/Footer/Footer';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { Contentful } from 'mxp-schemas';
import { getCustomBlockHeight } from 'utils/getCustomBlockHeight';
import { ContentfulHelpers } from 'mxp-utils';

interface Props {
  BlockComponent: React.FC<any>;
  modularBlock: Contentful.ModularContent.Block;
  isFullPagePagination: boolean;
  isUserOnBoarded: boolean;
  withHeader?: boolean;
  withFooter?: boolean;
  withExternalAd?: boolean;
  headerHeight?: number;
  footerHeight?: number;
  targetContainer?: any;
  containerContentRef?: any;
  blockRequiresAuth?: boolean;
  isFirstBlock?: boolean;
  isMobile?: boolean;
  isAuth: boolean | null;
  isToolkitPage?: boolean;
  isLandingPage?: boolean;
  isArticlePage?: boolean | undefined;
  isLocked?: boolean;
  isStaticLandingPage?: boolean;
  isInHeader?: boolean;
  isCimaMipJourney?: boolean;
  isHomePage?: boolean;
}

export const LandingPageBlock: React.FC<Props> = ({
  BlockComponent,
  modularBlock,
  isFullPagePagination,
  isUserOnBoarded,
  withHeader = false,
  withFooter = false,
  withExternalAd,
  headerHeight,
  footerHeight,
  targetContainer,
  containerContentRef,
  blockRequiresAuth,
  isFirstBlock,
  isMobile,
  isAuth,
  isToolkitPage,
  isLandingPage,
  isArticlePage,
  isLocked,
  isStaticLandingPage,
  isInHeader,
  isCimaMipJourney = false,
  isHomePage,
}) => {
  const headingType: 'h1' | 'h2' = isFirstBlock ? 'h1' : 'h2';
  const block = modularBlock as any;
  const blockHeight: string = getCustomBlockHeight(
    block?.contentType,
    withHeader,
    withFooter,
    headerHeight,
    footerHeight
  );

  const isInquiryFormBlock: boolean = block.contentType === ContentfulHelpers.CONTENT_TYPES.INQUIRY_FORM_BLOCK;

  const Component: React.ReactNode = (
    <BlockBackgroundDecorator
      testId={`block-${block.contentType}-${block.id}`}
      bgColor={block.bgColor}
      image={block.image}
      blockContentType={block.contentType}
      style={{ height: isInquiryFormBlock ? 'auto' : isFullPagePagination ? blockHeight : 'auto' }}
      isFullPagePagination={isFullPagePagination}
      id={block.anchorSlug}
      modularBlock={block}
      isToolkitPage={isToolkitPage}
      isStaticLandingPage={isStaticLandingPage}
      isInHeader={isInHeader}
      isArticlePage={isArticlePage}
      isCimaMipJourney={isCimaMipJourney}
      isHomePage={isHomePage}
    >
      <BlockComponent
        headingType={headingType}
        modularBlock={block}
        isMobile={isMobile}
        isFullPagePagination={isFullPagePagination}
        isLastSlide={withFooter}
        isFirstSlide={isFirstBlock}
        blockHeight={blockHeight}
        isUserOnBoarded={isUserOnBoarded}
        isLandingPage={isLandingPage}
        isArticlePage={isArticlePage}
        isLocked={isLocked}
      />
    </BlockBackgroundDecorator>
  );

  if (withHeader) {
    return (
      <HeaderPageWrapper contentRef={targetContainer} contentInnerRef={containerContentRef}>
        {withExternalAd && <ExternalAdBanner type={ExternalAdBannerType.TOP} />}
        {blockRequiresAuth && !isAuth ? <PageLandingAuth /> : Component}
      </HeaderPageWrapper>
    );
  }
  if (withFooter) {
    return (
      <>
        {blockRequiresAuth && !isAuth ? <PageLandingAuth /> : Component}
        {withExternalAd && <ExternalAdBanner type={ExternalAdBannerType.BOTTOM} />}
        <StyledFooter isAuth={isAuth} />
      </>
    );
  }
  return blockRequiresAuth && !isAuth ? <PageLandingAuth /> : (Component as JSX.Element);
};

interface FooterProps {
  isAuth: boolean | null;
}

const StyledFooter = styled(Footer)<ThemedStyledProps<FooterProps, DefaultTheme>>`
  ${props =>
    !props.isAuth &&
    `
    ::after {
      display: block;
      content: '';
      height: ${props.theme.pxToRem(60)};
  `}
`;
