const ABOVE_CONTENT = 105;

export enum Z_INDEX_ORDER {
  HeaderCombinedContainerMob,
  SlideToBeReplaced,
  HeaderCombinedContainerDesk,
  HeaderCopySave,
  SavePreferences,
  CalculationFooter,
  CookiePolicyBanner,
  SearchModal,
  SocialSharing = 801, // show above sticky sematic ui (800)
  SocialSharingGreyedBackground = -105, // show above sticky sematic ui (800)
}

export const getZIndexFor = (elementIndex: number) => elementIndex + ABOVE_CONTENT;
