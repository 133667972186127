import { CimaHeaderStepperBar } from 'components/atoms';
import {
  CimaMembershipPackageSteps,
  CimaMembershipSteps,
  CimaMembershipStudyPathSteps,
  ExemptionCalculatorSteps,
  MembershipPackageAccordionStatus,
} from '../../../constants';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { getPath } from 'utils';
import styled from 'styled-components';
import { StepperProps } from 'components/atoms/StepperBar/StepperBar';
import { getMembershipApplicationTypeSelector } from 'modules/cart/selectors';
import { Product } from 'mxp-schemas';
import { useSelector } from 'react-redux';
import { maxNumberOfExperienceDataSelector } from 'modules/personLevelExemption';
import { constantsSelector, isAdminPortalSelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { isAuthSelector } from 'modules/user/selectors';
import { Routes } from 'constants/index';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';

export const HeaderCimaMembership = () => {
  const { pathname: currPath, hash: currHash } = useLocation();
  const isPackageBuilder = currPath.includes('package');
  const isExemptionCalculator = currPath.includes(getPath(Routes.EXEMPTION_CALCULATOR_ROOT));
  const membershipApplicationType = useSelector(getMembershipApplicationTypeSelector);
  const maxNumberOfExp = useSelector(maxNumberOfExperienceDataSelector);
  const constants = useSelector(constantsSelector);
  const isAdmin = useSelector(isAdminPortalSelector);
  const isAuth = useSelector(isAuthSelector);
  const isImpersonated = Boolean(isAuth) && isAdmin;
  const isCIMA = membershipApplicationType === Product.MembershipApplicationType.CIMA;

  const currPathName = isPackageBuilder
    ? currPath.replace(currPath.split('#')[1], '')
    : currPath.replace(currPath.split('/')[3], '');

  const isRegionalPathway = useMemo(
    () =>
      Number.parseInt(maxNumberOfExp, 10) >=
      Number.parseInt(constants?.[CONSTANTS.CIMA_REGIONAL_QUALIFIED_YEARS_OF_EXPERIENCE], 10),
    [constants, maxNumberOfExp]
  );

  const pageMembershipSteps = useMemo(() => {
    if (isExemptionCalculator) {
      return ExemptionCalculatorSteps;
    }
    if (isPackageBuilder) {
      if (!isRegionalPathway || !isImpersonated) {
        return CimaMembershipPackageSteps.filter(data => data.endPathName !== MembershipPackageAccordionStatus.Pathway);
      }

      return CimaMembershipPackageSteps;
    }
    return CimaMembershipStudyPathSteps;
  }, [isPackageBuilder, isRegionalPathway, isImpersonated, isExemptionCalculator]);

  const steps: StepperProps[] = useMemo(
    () =>
      CimaMembershipSteps.map(({ name, pathName }, i) => {
        const path = getPath(pathName);

        const currPageId = CimaMembershipSteps.findIndex(el =>
          isPackageBuilder
            ? getPath(el.pathName) === currPathName
            : getPath(el.pathName) === currPathName.substring(0, currPathName.length - 1)
        );

        return {
          title: `${name}`,
          isActive: isPackageBuilder
            ? currPathName === path
            : currPathName.substring(0, currPathName.length - 1) === path,
          isSuccess: i < currPageId,
          isCIMA,
        };
      }),
    // eslint-disable-next-line
    [currPath]
  );
  const steps1: StepperProps[] = useMemo(
    () =>
      pageMembershipSteps.map(({ name, pathName, endPathName }, i) => {
        const path = getPath(pathName);
        const currPageId = isPackageBuilder
          ? pageMembershipSteps.findIndex(
              el => `${getPath(el.pathName)}#${el.endPathName}` === `${currPath}${currHash}`
            )
          : pageMembershipSteps.findIndex(el => getPath(el.pathName) === currPath);

        const order = isPackageBuilder ? 5 : 1;

        return {
          title: `${i + order}. ${name}`,
          isActive: isPackageBuilder ? `${currPath}${currHash}` === `${path}#${endPathName}` : currPath === path,
          isSuccess: i < currPageId,
          isCIMA,
          isPackageBuilder,
        };
      }),
    // eslint-disable-next-line
    [currPath, currHash]
  );

  return (
    <StyledContainer>
      {!isExemptionCalculator ? (
        <CimaHeaderStepperBar steps={steps} />
      ) : (
        <StyledTitle>Exemption Calculator</StyledTitle>
      )}
      <StyledDiv>
        {steps1.map(({ title, isActive, isSuccess }, i) => (
          <StyledStepItem {...{ isActive }} key={i}>
            <StyledBottomStepperText {...{ isActive }}>{title}</StyledBottomStepperText>
            <StyledStepContainer lastChild={steps1.length - 1 === i}>
              <StyledCheckMarkBorder {...{ isActive }} {...{ isSuccess }}>
                {isSuccess ? <StyledCheckMark /> : isActive ? <StyledCheckIcon /> : null}
              </StyledCheckMarkBorder>
            </StyledStepContainer>
          </StyledStepItem>
        ))}
      </StyledDiv>
    </StyledContainer>
  );
};

const StyledBottomStepperText = styled.p<{ isActive: boolean }>`
  ${({ theme }) => theme.mediaQueries.mobileOnly} {
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  }
`;

const StyledStepItem = styled.div<{ isActive: boolean }>`
  /* border: 1px solid red; */
  width: 25%;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  ${({ theme }) => theme.mediaQueries.mobileOnly} {
    align-self: ${({ isActive }) => !isActive && 'end'};
  }
`;

const StyledStepContainer = styled.div<{ lastChild: boolean }>`
  margin-top: ${props => props.theme.pxToRem(11)};
  position: relative;
  &:after {
    content: ' ';
    width: 80%;
    height: 2px;
    display: block;
    position: absolute;
    background: ${(props: any) => (props.lastChild ? 'transparent' : '#f1f1f1')};
    top: 5px;
    left: 60%;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  align-items: center;
`;

const StyledCheckMarkBorder = styled.div`
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
  border-radius: ${props => props.theme.pxToRem(16)};
  border: ${props => props.theme.pxToRem(1)} solid
    ${({ isActive, isSuccess, theme }: any) =>
      isActive || isSuccess ? theme.colors.secondaryTeal : theme.colors.neutralGrey4};
  background-color: ${({ isSuccess, theme }: any) =>
    isSuccess ? theme.colors.secondaryTeal : theme.colors.neutralWhite};
  display: flex;
  align-items: center;
  margin: auto;
`;

const StyledCheckMark = styled(Checkmark)`
  margin: auto;
  &&& {
    path {
      fill: white;
    }
  }

  width: ${props => props.theme.pxToRem(13)};
  height: ${props => props.theme.pxToRem(13)};
`;

const StyledCheckIcon = styled(Checkmark)`
  &&& {
    path {
      fill: ${props => props.theme.colors.secondaryTeal};
    }
  }
`;

const StyledContainer = styled.div`
  background-color: white;
  padding: 20px 50px;
  width: 75%;
  margin: ${props => props.theme.pxToRem(40)} auto 0 auto;
  border-radius: 1rem;
  box-shadow: 0px 0.125rem 0.875rem;

  ${({ theme }) => theme.mediaQueries.mobileOnly} {
    padding: 14px 0;
    width: 90%;
    margin: 0 auto;
  }
`;
const StyledDiv = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
`;
