import styled from 'styled-components';
import React, { useEffect } from 'react';
import { generatePath } from 'react-router';
import { getPath } from 'utils';
import { OrganizationNavigationList, Routes } from 'constants/index';
import { Link, TablePlaceholder } from 'components/atoms';
import { NavigationBox } from './NavigationBox';

interface Props {
  getOrganizations(): void;
  organizations: State.Organization[];
  organizationsIsFetched: boolean;
}

export const PageOrganizationNavigation: React.FC<Props> = ({
  getOrganizations,
  organizations,
  organizationsIsFetched,
}) => {
  useEffect(() => {
    getOrganizations();
  }, [getOrganizations]);

  const B2BNavigationList = React.useMemo(
    () =>
      OrganizationNavigationList.map(item => ({
        text: item.label,
        link: generatePath(getPath(item.key)),
      })),
    []
  );

  const organizationList = React.useMemo(
    () =>
      organizations.map(item => ({
        text: item.name,
        link: generatePath(getPath(Routes.ORG_FIRM_INFORMATION), { orgId: item.id }),
      })),
    [organizations]
  );

  if (!organizationsIsFetched) return <TablePlaceholder />;

  return (
    <>
      <StyledNavContainer>
        <NavigationBox iconName="user circle" navTitle="Firm Information" mainLinkTo="/">
          {organizationList.map(item => (
            <StyledLink key={item.text} testId={`navigation${item.link}`} to={item.link} isExternal={false}>
              {item.text} Firm Details
            </StyledLink>
          ))}
        </NavigationBox>
        <NavigationBox iconName="setting" navTitle="B2B Activities" mainLinkTo="/">
          {B2BNavigationList.map(item => (
            <StyledLink key={item.text} testId={`navigation${item.link}`} to={item.link} isExternal={false}>
              {item.text}
            </StyledLink>
          ))}
        </NavigationBox>
      </StyledNavContainer>
    </>
  );
};

const StyledNavContainer = styled.div`
  display: grid;
  align-items: stretch;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: ${props => props.theme.pxToRem(10)};
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primaryPurple};
  text-decoration: none;
  margin-bottom: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.fontSizes.s};
`;
