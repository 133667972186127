import React, { FC } from 'react';
import styled from 'styled-components';
import { camelCaseToWords } from '../RenewMemberShipCardRow/RenewMemberShipCardRow';
import { TRenewalFee } from 'components/pages/PageRenewYourMembership/types/types';

export interface IRenewalSummaryTableFeeRow {
  feeValue: TRenewalFee;
  feeKeys: string[];
  index: number;
}

export const RenewalSummaryTableFeeRow: FC<IRenewalSummaryTableFeeRow> = ({ feeValue, feeKeys, index }) => {
  return (
    <PositionContainer key={feeValue.title}>
      {feeKeys[index] !== 'enrolment' && (
        <PositionTitle data-testid="row-title">{camelCaseToWords(feeKeys[index])}</PositionTitle>
      )}
      <PositionPriceContainer>
        <PricePosition underline={feeKeys[index] !== 'enrolment'}>{feeValue.title}</PricePosition>
        <PricePosition>${feeValue.price}</PricePosition>
      </PositionPriceContainer>
    </PositionContainer>
  );
};

const PositionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PositionTitle = styled.div<{ fontSize?: number }>`
  display: flex;
  letter-spacing: -0.32px;
  color: ${props => props.theme.colors.neutralGrey10};
  margin-bottom: ${props => props.theme.pxToRem(8)};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : 'inherit')};
  text-align: left;
`;

const PositionPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.pxToRem(16)};
  padding-bottom: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.pxToRem(16)};
  text-align: left;
`;

const PricePosition = styled.div<{ underline?: boolean }>`
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.medium};
`;
