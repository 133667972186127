import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Divider } from 'semantic-ui-react';
import { Routes, MembershipPackageAccordionStatus } from '../../../constants';
import { MembershipTypes, ExemptionLevels } from 'mxp-schemas';
import { Admin as AdminUtils } from 'mxp-utils';
import { updateEmploymentDataSharing, upsertEmploymentData } from 'modules/postLoginActionsTable';

import { getPath, getCimaMembershipPackagePath, getPaperBillingPath } from 'utils';
import {
  addMembershipToCart,
  removeMembershipPackageRelatedAddon,
  fetchCimaMembershipTypes,
  setUserchoiceType,
  setChangeMyLearningPath,
  setMembershipEvent,
} from 'modules/membership/actions';
import {
  currentJourneyLearningPathwaySelector,
  employersWithEmployeeRelationshipSelector,
  isUserMemberLapsedSelector,
} from 'modules/user/selectors';
import {
  userMembershipPackageSelector,
  membershipAddonsSelector,
  membershipProductSelector,
  membershipFLPProductsSelector,
  userMembershipTypeSelector,
  userMembershipTierSelector,
  isUserAicpaMemberSelector,
  isUserRegularAicpaMemberSelector,
  activeMembershipSubscriptionSelector,
  membershipInviteDataSelector,
} from 'modules/membership/selectors';
import { isPaperBillingSelector, getCimaMembershipTypeSelector } from 'modules/startup/selectors';
import { Button, ButtonEnums, CimaSummaryCard } from 'components/atoms';
import { selectedExemptionSelector } from 'modules/exemptionEngine/selector';
import { getESProductBySku } from 'modules/search/actions';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_FLP_ADDONS } from 'modules/featureToggle/constants';
import { personLevelExemptionSelector } from 'modules/personLevelExemption';
import { professionalQualificationSelector } from 'modules/exemptionProfessional/selectors';
import { educationQualificationSelector } from 'modules/exemptionEducational/selectors';
import { workExperienceRequiredSelector } from 'modules/exemptionWorkExperience/selectors';
import { allRequiredDocuments } from 'modules/allRequiredDocuments/action';
import { isCimaQpMembershipSelector } from 'modules/app/selectors';
import { FirmAdminMembershipTierSKUS } from 'modules/firmAdmin/constants';
import { updateMembershipApplicationPartTier } from 'modules/user/actions';
import { triggerAddCartEvent } from 'utils/Analytics/helpers';
import { handleEvent } from 'utils/Analytics';

export const CimaMembershipPackageSummary: React.FC = () => {
  const [cartLoading, setCartLoading] = useState(false);
  const [isAnalyticsEventTriggered, setIsAnalyticsEventTriggered] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const useFLPAddons = useSelector(state => getFeatureToggleByKeySelector(state as State.Root, USE_FLP_ADDONS));

  const { addons } = useSelector(userMembershipPackageSelector);
  const membershipAddons = useSelector(membershipAddonsSelector).list;
  const membershipProduct = useSelector(membershipProductSelector);

  // firm billing
  const inviteData = useSelector(membershipInviteDataSelector);
  const isUserLapsedOrResigned = useSelector(isUserMemberLapsedSelector);
  const employersWithEmployeeRelationship = useSelector(employersWithEmployeeRelationshipSelector);

  const isPaperBilling = useSelector(isPaperBillingSelector);
  const productTier = useSelector(userMembershipTierSelector);
  const userTypeSlug = useSelector(userMembershipTypeSelector);
  const membershipFLPProducts = useSelector(membershipFLPProductsSelector);
  const cimaMembershipType = useSelector(getCimaMembershipTypeSelector);
  const currentJourneyLearningPathway =
    useSelector(currentJourneyLearningPathwaySelector) ||
    (inviteData?.inviteId && inviteData?.isFLP ? MembershipTypes.Pathway.FLP : '');

  const userSelectedExemptionEngine = useSelector(selectedExemptionSelector);

  const isUserAicpaMember = useSelector(isUserAicpaMemberSelector);
  const isUserRegularAicpaMember = useSelector(isUserRegularAicpaMemberSelector);

  const activeMembershipSubscriptions = useSelector(activeMembershipSubscriptionSelector);
  const getUserPersonlLevelExemption = useSelector(personLevelExemptionSelector);
  const educationalExempt = useSelector(educationQualificationSelector);
  const professionalExempt = useSelector(professionalQualificationSelector);
  const workExperienceExempt = useSelector(workExperienceRequiredSelector);

  const isCimaQpMembership = useSelector(isCimaQpMembershipSelector);

  const selectedStudyPath = MembershipTypes.LearningPathwayName[currentJourneyLearningPathway];
  const selectedFLPProductName = membershipFLPProducts.find((flpProducts: any) =>
    flpProducts?.variants?.otherVariants.find((variant: any) => variant.sku === productTier)
  )?.name;
  const isPathwayFLP = AdminUtils.isFlpPathway(currentJourneyLearningPathway);
  const isApprenticeCurrentJourney = AdminUtils.isApprenticePathway(currentJourneyLearningPathway);

  const isFlpCurrentJourney = currentJourneyLearningPathway
    ? AdminUtils.isFlpPathway(currentJourneyLearningPathway)
    : userTypeSlug === MembershipTypes.CIMALearningPathwaySlug.FLP;

  const getPQTypeAndTierName = {
    membershipTypeName: membershipProduct?.membershipKey,
    membershipTierName: membershipProduct?.variants?.find((variant: any) => variant?.sku === productTier)?.tierCode,
  };

  const [flpMembership, setFlpMembership] = useState(
    // if Aicpa Regular, get from current membership
    isUserRegularAicpaMember
      ? {
          membershipTypeName: activeMembershipSubscriptions?.variant?.attributes?.membershipKey?.label,
          membershipTierName: activeMembershipSubscriptions?.variant?.attributes?.tierName,
        }
      : {
          membershipTypeName: '',
          membershipTierName: '',
        }
  );

  const { membershipTypeName, membershipTierName } = useMemo(
    () => (isPathwayFLP ? flpMembership : getPQTypeAndTierName),
    [flpMembership, getPQTypeAndTierName, isPathwayFLP]
  );

  const showedAddons = addons.map(item => {
    const findAddon = membershipAddons.find(membershipAddon => membershipAddon.productId === item.productId);
    const result = {
      productId: item.productId,
      sku: item.sku,
      name: findAddon?.name || '',
    };
    return result;
  });

  // trigger a page view event on summary for analytics
  useEffect(() => {
    handleEvent({ force: true }, '@@router/LOCATION_CHANGE');
  }, []);

  useEffect(() => {
    if (isPathwayFLP) {
      const selectedFLPProduct = membershipFLPProducts?.find((e: any) => e.slug === userTypeSlug)?.variants;

      if (selectedFLPProduct) {
        let selectedFLPMembershipSku = isUserAicpaMember
          ? selectedFLPProduct.includedMembershipType
          : selectedFLPProduct.includedCimaMembershipType;

        if (
          getUserPersonlLevelExemption?.professionalQualification?.[0]?.selectedPassedUSCPAExam ===
          ExemptionLevels.USCpaExamEnum.YES_LICENSED_BEFORE
        ) {
          selectedFLPMembershipSku = FirmAdminMembershipTierSKUS.STAFFCORE;
        }
        dispatch(getESProductBySku(selectedFLPMembershipSku))
          .then((res: any) => {
            setFlpMembership({
              membershipTypeName: res?.payload?.getESProductBySku?.membershipKey,
              membershipTierName: res?.payload?.getESProductBySku?.tierName,
            });
          })
          .catch(console.error);
      }
    }
  }, [isPathwayFLP]); // eslint-disable-line react-hooks/exhaustive-deps

  const combinedExemptResult: any = [];

  const combineExemptionDocuments = useCallback(() => {
    getUserPersonlLevelExemption.professionalQualification?.forEach((item: any) => {
      professionalExempt?.forEach((exemptItem: any) => {
        if (item?.exemptionKey && exemptItem?.Id !== undefined && item?.exemptionKey === exemptItem?.Id) {
          combinedExemptResult.push(exemptItem);
        }
      });
    });
    getUserPersonlLevelExemption.educationalQualification?.forEach((item: any) => {
      educationalExempt?.forEach((exemptItem: any) => {
        if (item?.exemptionKey && exemptItem?.Id !== undefined && item?.exemptionKey === exemptItem?.Id) {
          combinedExemptResult.push(exemptItem);
        }
      });
    });
    getUserPersonlLevelExemption.workExperience?.forEach((item: any) => {
      workExperienceExempt?.forEach((exemptItem: any) => {
        if (item?.exemptionKey && exemptItem?.Id !== undefined && item?.exemptionKey === exemptItem?.Id) {
          combinedExemptResult.push(exemptItem);
        }
      });
    });
    dispatch(allRequiredDocuments(combinedExemptResult));
  }, [
    dispatch,
    combinedExemptResult,
    getUserPersonlLevelExemption,
    educationalExempt,
    professionalExempt,
    workExperienceExempt,
  ]);

  const handleAddToCart = useCallback(async () => {
    setCartLoading(true);
    if (cimaMembershipType && isCimaQpMembership) {
      await dispatch(fetchCimaMembershipTypes());
      await dispatch(setUserchoiceType());
    }

    const cartLineItems = await dispatch(addMembershipToCart());
    if (!isAnalyticsEventTriggered) {
      triggerAddCartEvent(cartLineItems.payload.addMembershipToCart);
      setIsAnalyticsEventTriggered(true);
    }
    combineExemptionDocuments();

    dispatch(updateMembershipApplicationPartTier());
    // This is to close Application Modal
    dispatch(setMembershipEvent('isClickedStartNewApplication', true));

    if (isUserLapsedOrResigned && inviteData?.inviteId) {
      const existedOrg =
        employersWithEmployeeRelationship &&
        employersWithEmployeeRelationship?.find(
          (employer: any) => employer?.organization?.id === inviteData?.organizationId
        );

      if (inviteData?.organizationId && inviteData?.organization) {
        if (!existedOrg) {
          // if no existed org then create
          const employmentInfoPayload = {
            id: '',
            isPrimary: true,
            jobTitle: inviteData?.position,
            organization: inviteData?.organization,
            relationshipType: 'Employee',
            status: 'Active',
            dataSharingConsent: true,
          };

          await dispatch(
            upsertEmploymentData(
              employmentInfoPayload,
              true, // createNew
              true, // preventRedirect
              true // isUpdateEmployment
            )
          );
        } else if (!existedOrg?.dataSharingConsent) {
          const employmentDataSharing = {
            dataSharingConsent: true,
            id: existedOrg.id,
          };
          dispatch(updateEmploymentDataSharing({ employmentDataSharing }));
        }
      }
    }

    history.push(getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM));
  }, [
    dispatch,
    history,
    cimaMembershipType,
    combineExemptionDocuments,
    isCimaQpMembership,
    isUserLapsedOrResigned,
    employersWithEmployeeRelationship,
    inviteData,
    isAnalyticsEventTriggered,
  ]);

  const handleRemoveAddon = useCallback(
    (sku: string) => {
      dispatch(removeMembershipPackageRelatedAddon(sku));
    },
    [dispatch]
  );

  const handleSetChangeMyLearningPath = useCallback(() => {
    dispatch(setChangeMyLearningPath(true));
  }, [dispatch]);

  return (
    <StyledContainer>
      <StyledBreak />
      <StyledPageTitle>Summary of your selections</StyledPageTitle>
      <StyledCardContainer>
        <StyledCardDiv>
          <CimaSummaryCard
            title="Learning pathway"
            link={getPaperBillingPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY, isPaperBilling)}
            subtitle={selectedFLPProductName ? `${selectedStudyPath} - ${selectedFLPProductName}` : selectedStudyPath}
            linkName="Change my learning pathway"
            handleSetChangeMyLearningPath={handleSetChangeMyLearningPath}
            isHidingLink={inviteData?.isPaidByFirm || inviteData?.isPaidByFLP}
          />
        </StyledCardDiv>
        <StyledCardDiv>
          <CimaSummaryCard
            title="Entry point"
            link={getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Level, isPaperBilling)}
            subtitle={userSelectedExemptionEngine}
            linkName="Change my entry point"
            isHidingLink={inviteData?.isPaidByFirm || inviteData?.isPaidByFLP}
          />
        </StyledCardDiv>

        {!isApprenticeCurrentJourney && (
          <StyledCardDiv>
            <CimaSummaryCard
              title="Membership details"
              membershipType={membershipTypeName}
              membershipTier={membershipTierName}
            />
          </StyledCardDiv>
        )}

        {!inviteData?.isPaidByFirm &&
          !inviteData?.isPaidByFLP &&
          (isFlpCurrentJourney ? useFLPAddons : !isApprenticeCurrentJourney) && (
            <StyledCardDiv>
              <CimaSummaryCard
                title="Additional products added"
                link={getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Addons, isPaperBilling)}
                addons={showedAddons}
                handleRemove={handleRemoveAddon}
                linkName="Add another product"
              />
            </StyledCardDiv>
          )}
      </StyledCardContainer>
      <StyledPageDivider />
      <StyledButton
        to={getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_START)}
        variant={ButtonEnums.variants.primary}
        bordered
        testId="test-id"
        onClick={handleAddToCart}
        loading={cartLoading}
      >
        Apply now
      </StyledButton>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  box-sizing: border-box;
  padding: ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(10)};
  width: ${props => props.theme.pxToRem(960)};
  margin-left: ${props => props.theme.pxToRem(20)};
  display: flex;
  flex-direction: column;

  ${props => props.theme.mediaQueries.mobileOnly} {
    justify-content: center;
    align-items: center;
  }
`;

const StyledPageDivider = styled(Divider)`
  &&& {
    min-width: ${props => props.theme.pxToRem(950)};
    margin: ${props => props.theme.pxToRem(25)};
    color: ${props => props.theme.colors.neutralGrey8};
  }
`;

const StyledCardContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(25)};
  display: flex;
  flex-wrap: wrap;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 80%;
    flex-direction: column;
  }
`;

const StyledBreak = styled.div`
  margin-top: ${props => props.theme.pxToRem(12)};
`;

const StyledButton = styled(Button)`
  &&& {
    width: ${props => props.theme.pxToRem(265)};
  }
`;

const StyledPageTitle = styled.span`
  font-size: ${props => props.theme.fontSizes.l};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const StyledCardDiv = styled.div`
  flex: 0;
  display: flex;

  ${props => props.theme.mediaQueries.mobileOnly} {
    flex: 0 0 100%;
  }
`;
