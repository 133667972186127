import { MembershipAccordion } from 'components/molecules/MembershipAccordion/MembershipAccordion';
import { MembershipPackageAccordionStatus } from '../../../constants';
import {
  hasSelectedAddOnsSelector,
  hasSelectedCredentialSelector,
  hasSelectedSectionSelector,
  hasSelectedTierSelector,
  hasSelectedTypeSelector,
  membershipCredentialSelector,
  membershipRelatedAddonsSelector,
  membershipSectionRootSelector,
  membershipTiersSelector,
  membershipTypesSelector,
  userMembershipPackageSelector,
} from 'modules/membership/selectors';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { theme } from 'theme';
import { allUserChoiceSections, membershipTypeSKUTitles } from 'utils';
import { MembershipRegularTypeSkus } from 'modules/membership/constants';
import { productCurrencySelector } from 'modules/products/selectors';
import { getMembershipCredentials } from 'components/pages/PageRenewYourMembership/helpers/getMembershipCredentials';
import { getServiceTier } from 'components/pages/PageRenewYourMembership/helpers/getServiceTier';
import { employmentDataSelector, tlwSelector } from 'modules/user/selectors';
import { getAddons } from 'components/pages/PageRenewYourMembership/helpers/getAddons';

interface AccordionProps {
  accordionKey: string;
  title?: any;
  icon?: string;
  secondaryIcon?: string;
  content?: any;
  onClickCallback?: any;
  isActive?: boolean;
  activeIndex?: number;
  index?: number;
  popupContent?: string;
  toolTipContent: (popupContent: string) => any;
  popupTrigger: () => any;
}

export const MembershipAccordionRenderer: React.FC<AccordionProps> = ({
  accordionKey,
  icon,
  secondaryIcon,
  title,
  content,
  onClickCallback,
  isActive,
  index,
  activeIndex,
  popupContent,
  toolTipContent,
  popupTrigger,
}) => {
  const hasSelectedType = useSelector(hasSelectedTypeSelector);
  const hasSelectedTier = useSelector(hasSelectedTierSelector);
  const hasSelectedCredential = useSelector(hasSelectedCredentialSelector);
  const hasSelectedSection = useSelector(hasSelectedSectionSelector);
  const hasSelectedAddOns = useSelector(hasSelectedAddOnsSelector);
  const userChoice = useSelector(userMembershipPackageSelector);
  const membershipTypes = useSelector(membershipTypesSelector)?.list;
  const membershipRelatedAddons = useSelector(membershipRelatedAddonsSelector)?.list;

  const { list: credentialsList } = useSelector(membershipCredentialSelector);
  const { listOfProductWithPrice: sectionWithPriceList } = useSelector(membershipSectionRootSelector);

  const employmentData = useSelector(employmentDataSelector);
  const tlwDetails = useSelector(tlwSelector);
  const membershipTiers = useSelector(membershipTiersSelector);

  const currency = useSelector(productCurrencySelector);

  const isType = accordionKey === MembershipPackageAccordionStatus.Type;
  const isTier = accordionKey === MembershipPackageAccordionStatus.Tier;
  const isCredentials = accordionKey === MembershipPackageAccordionStatus.Credentials;
  const isSections = accordionKey === MembershipPackageAccordionStatus.Sections;
  const isAddons = accordionKey === MembershipPackageAccordionStatus.Addons;

  const isCompleted = useCallback(
    () =>
      ((hasSelectedType && isType) ||
        (hasSelectedTier && isTier) ||
        (hasSelectedCredential && isCredentials) ||
        (hasSelectedSection && isSections) ||
        (hasSelectedAddOns && isAddons)) &&
      !isActive,
    [
      hasSelectedType,
      hasSelectedTier,
      hasSelectedCredential,
      hasSelectedSection,
      hasSelectedAddOns,
      isCredentials,
      isType,
      isTier,
      isSections,
      isActive,
      isAddons,
    ]
  );

  const accordionColor = useCallback(() => {
    if (isCompleted()) {
      return theme.colors.secondaryTeal;
    }

    return isActive ? theme.colors.primaryPurple : theme.colors.lightPink;
  }, [isActive, isCompleted]);

  const accordionTitleColor = useCallback(() => {
    return isActive || isCompleted() ? theme.colors.neutralWhite : theme.colors.primaryPurple;
  }, [isActive, isCompleted]);

  const accordionIcon = useCallback(() => {
    return isActive || isCompleted() ? icon : secondaryIcon;
  }, [isActive, icon, secondaryIcon, isCompleted]);

  const rowProps: any = {
    [MembershipPackageAccordionStatus.Type]: [
      {
        membershipType: membershipTypeSKUTitles(userChoice.type.slug, membershipTypes),
      },
    ],
    [MembershipPackageAccordionStatus.Tier]: [
      getServiceTier(
        userChoice?.tier,
        [],
        employmentData,
        tlwDetails,
        membershipTiers.list,
        currency,
        hasSelectedTier,
        true
      ),
    ],
    [MembershipPackageAccordionStatus.Credentials]: getMembershipCredentials(
      [],
      hasSelectedCredential,
      userChoice?.credentials,
      credentialsList,
      currency
    ),
    [MembershipPackageAccordionStatus.Sections]: allUserChoiceSections(
      hasSelectedSection,
      userChoice?.sectionProductWithPrice,
      userChoice?.sectionFreeProduct,
      sectionWithPriceList,
      currency
    ),
    [MembershipPackageAccordionStatus.Addons]: getAddons([], userChoice?.addons, membershipRelatedAddons, true),
  };

  const serviceTierFieldsToBeShown = useMemo(() => {
    switch (userChoice?.tier) {
      case MembershipRegularTypeSkus.CORE:
      case MembershipRegularTypeSkus.ESSENTIAL:
      case MembershipRegularTypeSkus.SELECT:
      case MembershipRegularTypeSkus.PARTNER_CORE:
      case MembershipRegularTypeSkus.PARTNER_SPECIALIST:
      case MembershipRegularTypeSkus.PARTNER_ADVANCE:
      case MembershipRegularTypeSkus.LEAD:
        return ['selectedTier', 'employmentStatus', 'role', 'value'];
      case MembershipRegularTypeSkus.RETIRED:
      case MembershipRegularTypeSkus.REGULAR_TLW:
        return ['selectedTier', 'employmentStatus', 'reason', 'value'];
      case MembershipRegularTypeSkus.EXAM_CANDIDATE:
      case MembershipRegularTypeSkus.INT_ASSOCIATE:
        return ['selectedTier', 'value'];
      default:
        return ['selectedTier', 'value'];
    }
  }, [userChoice]);

  const rowHeaders: { [key: string]: string[] } = useMemo(() => {
    return {
      [MembershipPackageAccordionStatus.Type]: ['membershipType'],
      [MembershipPackageAccordionStatus.Tier]: serviceTierFieldsToBeShown,
      [MembershipPackageAccordionStatus.Credentials]: ['selectedCredentials', 'value'],
      [MembershipPackageAccordionStatus.Sections]: ['selectedSections', 'value'],
      [MembershipPackageAccordionStatus.Addons]: ['selectedAdd-ons', 'value'],
    };
  }, [serviceTierFieldsToBeShown]);

  return (
    <MembershipAccordion
      accordionKey={accordionKey}
      title={title}
      content={content}
      onClickCallback={onClickCallback}
      isActive={isActive}
      index={index}
      activeIndex={activeIndex}
      popupContent={popupContent}
      toolTipContent={toolTipContent}
      popupTrigger={popupTrigger}
      isCompleted={isCompleted()}
      accordionColor={accordionColor()}
      accordionTitleColor={accordionTitleColor()}
      accordionIcon={accordionIcon() || ''}
      rowProps={rowProps[accordionKey]}
      rowHeaders={rowHeaders[accordionKey]}
    />
  );
};
