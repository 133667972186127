import { MembershipPackageAccordionStatus, RenewMembershipOptions } from '../constants';
import { MembershipTypes, Contentful } from 'mxp-schemas';
import moment from 'moment-timezone';
import { CONSTANTS } from '../modules/app/constants';

export const getSelectedTierName = (tierName: string | null | undefined) => {
  switch (tierName) {
    case 'Temporarily Left the Workforce':
      return 'TLW';
    case 'Retired - Core':
      return 'Retired';
    case 'Core':
      return 'International Associate';
    case 'Non-CPA affiliate':
      return 'Non-CPA Affiliate';
    case 'Partner - Advance':
      return 'Partner - Advanced';
    default:
      return tierName;
  }
};

export const includedSectionClaimed = (
  existingSections: State.LineItem[],
  benefitsList: any,
  sectionProductId: string
) => {
  const userHasThisSection = existingSections?.find((section: any) => section?.productId === sectionProductId);
  const isClaimed = benefitsList?.some((benefit: any) => benefit?.productId === userHasThisSection?.productId);
  return isClaimed;
};

export const accordionOptionsLocationMapping = (renewMembershipOptions: RenewMembershipOptions) => {
  switch (renewMembershipOptions) {
    case RenewMembershipOptions.SERVICE_TIER:
      return MembershipPackageAccordionStatus.Tier;
    case RenewMembershipOptions.MEMBERSHIP_CREDENTIALS:
      return MembershipPackageAccordionStatus.Credentials;
    case RenewMembershipOptions.MEMBERSHIP_SECTIONS:
      return MembershipPackageAccordionStatus.Sections;
    case RenewMembershipOptions.ADD_ONS:
      return MembershipPackageAccordionStatus.Addons;
    case RenewMembershipOptions.ACCOUNT_TYPE:
      return MembershipPackageAccordionStatus.Type;
    default:
      return MembershipPackageAccordionStatus.Type;
  }
};

export const isRenewalJourneyBlocked = (
  geolocation: string,
  blockedCountryData: any,
  isRenewedMembershipCima: boolean | undefined
) => {
  // if user location is marked as blocked by contentful list, return true to render modal to block journey
  return Boolean(blockedCountryData?.countries?.includes(geolocation) && isRenewedMembershipCima);
};

type RenewalSeason = {
  flpRenewalSeasonOpen: number;
  pqRenewalSeasonOpen: number;
};

export const isRenewablePathway = (
  learningPathway: MembershipTypes.Pathway | null | undefined,
  membershipEndDate: moment.Moment,
  constants?: Contentful.Constants.Constants | null
): boolean => {
  const today = moment();
  const membershipDiffDays = membershipEndDate.diff(today, 'days');

  const flpRenewalSeasonOpen: number = constants?.[CONSTANTS.FLP_RENEWAL_SEASON_OPEN] ?? 90;
  const pqRenewalSeasonOpen: number = constants?.[CONSTANTS.PQ_RENEWAL_SEASON_OPEN] ?? 90;
  const apprenticeRenewalSeasonOpen: number = constants?.[CONSTANTS.APPRENTICE_RENEWAL_SEASON_OPEN] ?? 90;

  switch (learningPathway) {
    case MembershipTypes.Pathway.FLP:
      return membershipDiffDays <= flpRenewalSeasonOpen;

    case MembershipTypes.Pathway.PQ:
    case MembershipTypes.Pathway.CHINESE_PQ:
      return membershipDiffDays <= pqRenewalSeasonOpen;

    case MembershipTypes.Pathway.APPRENTICE_L4:
    case MembershipTypes.Pathway.APPRENTICE_L7:
      return membershipDiffDays <= apprenticeRenewalSeasonOpen;

    default:
      return true;
  }
};

export const isMemberValidForRenewal = (
  isUserMember: boolean | undefined,
  isUserWithMultipleTerms: boolean,
  isMembershipRenewed: boolean
): boolean => {
  return !!isUserMember && (!isUserWithMultipleTerms || !isMembershipRenewed);
};

export const isValidForFlpRenewal = (
  learningPathway: MembershipTypes.Pathway | null | undefined,
  isPathwayRenewable: boolean,
  isMemberValidForRenewal: boolean,
  hasActiveFlpProduct: boolean
): boolean => {
  const isRenewableForFlp =
    isPathwayRenewable && learningPathway === MembershipTypes.Pathway.FLP && hasActiveFlpProduct;

  return isMemberValidForRenewal && isRenewableForFlp;
};

// ToDo: This is existing logic that has been refactored out for testing purposes. However, business input
// is needed to validate that this is actually correct.
export const shouldHideButtonFor3MonthRule = (latestMembershipEndDate: moment.Moment) => {
  const currentDate = moment();
  // Note:
  // User signup in 3month rule (e.g. currentYr = 2023 (10/01/2023), latestMembershipYr = 2024 (12/31/2024), Renewal Season: ON)
  // Hide renewal button if currentYr (2023) and within 3month rule
  // Show renewal button if currentYr (2024) and within 3month rule

  // Magic number is 11 to get the startDate of cycle based on end date instead of productListData
  const startDateOfCycle = moment(latestMembershipEndDate).subtract(11, 'months').startOf('month');
  const monthDifference = startDateOfCycle.diff(currentDate.startOf('month'), 'months');
  // Difference between 0 and 3
  const isThreeMonthRule = monthDifference <= 3 && monthDifference > 0;

  return currentDate.year() !== latestMembershipEndDate.year() && isThreeMonthRule;
};
