import { handleActions, combineActions } from 'redux-actions';
import { getFeatureToggles, loadFeatureToggles, toggleFeature } from './actions';

const initialState: State.FeatureToggle = {
  /**
   * Add key:value pair here for local development.
   * Please remove and add featureToggle to SIT Contentful for higher environments.
   */
  featureToggles: {},
};

export const ACTION_HANDLERS = {
  [combineActions(loadFeatureToggles, getFeatureToggles) as any]: {
    next: (state: State.FeatureToggle, action: any): State.FeatureToggle => ({
      ...state,
      featureToggles: {
        ...action.payload.featureToggles,
      },
    }),
  },

  [toggleFeature]: {
    next: (state: State.FeatureToggle, action: any): State.FeatureToggle => {
      const { key, toggleCheck } = action.payload;
      return {
        ...state,
        featureToggles: {
          ...state.featureToggles,
          [key]: toggleCheck,
        },
      };
    },
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
