import React from 'react';
import styled from 'styled-components';
import { RadioButton } from '../../atoms';

interface Props {
  checked?: boolean;
  disabled?: boolean;
  smallerIcon?: boolean;
  text?: string;
  id?: string;
  testId: string;
  onChange: (id: string | undefined) => void;
  children?: React.ReactNode;
  className?: string;
  reverseLabelAndRadio?: boolean;
  useAvsDesigns?: boolean | null;
}

export const FilterRadioLabel: React.FC<Props> = React.memo(props => {
  const {
    text = '',
    checked = false,
    disabled = false,
    id,
    testId,
    onChange,
    children,
    className = '',
    reverseLabelAndRadio,
    smallerIcon,
    useAvsDesigns = false,
  } = props;

  const handlePreviewLoaded = React.useCallback((): void => {
    if (!checked && !disabled) onChange(id);
  }, [id, checked, onChange, disabled]);

  return (
    
    <StyledFilter
      disabled={disabled}
      onClick={handlePreviewLoaded}
      isMultilineLabel={children}
      reverseLabelAndRadio={reverseLabelAndRadio}
      testId={testId}
      useAvsDesigns={useAvsDesigns}
    >
      <StyledFilterText
        reverseLabelAndRadio={reverseLabelAndRadio}
        checked={checked}
        disabled={disabled}
        useAvsDesigns={useAvsDesigns}
      >
        {children || text}
      </StyledFilterText>
      <RadioButton
        testId={testId}
        checked={checked}
        disabled={disabled}
        className={className}
        smallerIcon={smallerIcon}
      />
    </StyledFilter>
  );
});

const StyledFilter: any = styled.div`
  display: flex;
  cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
  ${({ testId, isMultilineLabel, reverseLabelAndRadio, theme, useAvsDesigns }: any) => `
    flex-direction: ${reverseLabelAndRadio && 'row-reverse'};
    justify-content: ${isMultilineLabel || reverseLabelAndRadio ? 'flex-end' : 'space-between'};
    margin-bottom: ${
      useAvsDesigns ? (!testId.includes('add-different-address') ? theme.pxToRem(24) : 0) : theme.pxToRem(24)
    }

    div.radio {
      width: ${theme.pxToRem(18)};
      height: ${theme.pxToRem(18)};
      margin-right: ${theme.pxToRem(8)};

      div{
        height: ${theme.pxToRem(8)};
        width: ${theme.pxToRem(8)};
      }
    }
`};
`;

const StyledFilterText: any = styled.div`
  ${({ checked, disabled, theme, reverseLabelAndRadio, useAvsDesigns }: any) => `
		font-size: ${checked && !disabled && !reverseLabelAndRadio ? theme.fontSizes.s : theme.fontSizes.xs};
		font-weight: ${checked && !disabled ? theme.fontWeights.medium : theme.fontWeights.light};
		line-height: ${useAvsDesigns ? 'normal' : '1.5'} ;
    color: ${disabled ? theme.colors.neutralGrey4 : theme.colors.neutralGrey8};
    margin-left: ${reverseLabelAndRadio ? theme.pxToRem(8) : theme.pxToRem(0)}
	`}
`;
