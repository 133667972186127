import React, { useReducer } from 'react';
import styled from 'styled-components';
import { InputWithValidation, ProfileHeading } from 'components/molecules';
import {
  Button,
  ButtonEnums,
  ButtonVariants,
  Checkbox,
  Container,
  Divider,
  Dropdown,
  RadioButton,
} from 'components/atoms';
import { StrictDropdownProps } from 'semantic-ui-react';
import { ReactComponent as Cancel } from 'resources/images/ic-cancel.svg';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import moment from 'moment-timezone';

import { useDispatch, useSelector } from 'react-redux';
import { adminStickyFooterInvoiceFilterVisibleSelector } from 'modules/layouts/selectors';
import { setAdminStickyFooterInvoiceFilterVisible } from 'modules/layouts/actions';
import { SendEmailsModal } from './SendEmailsModal';
import { SendToPrintModal } from './SendToPrintModal';
import { Notifications } from 'components/molecules/Notifications';
import { Salesforce, ZuoraTypes } from 'mxp-schemas';
import { resetSendEmailPrintInvoices, sendEmailPrintInvoices } from 'modules/clientAdmin/actions';
import { sendEmailPrintLoadingSelector, sendEmailPrintResultSelector } from 'modules/clientAdmin/selectors';
import { Utils } from 'mxp-utils';

enum HeaderCompany {
  ASSOCIATION = 'Association (1000)',
  AICPA = 'AICPA (0001)',
}

enum HeaderCompanyValue {
  ASSOCIATION = '1000',
  AICPA = '0001',
}

interface FormState {
  accountName: {
    open: boolean;
    from: string;
    to: string;
  };
  aicpaUID: {
    open: boolean;
    value: string;
    isCorrect: boolean;
  };
  legalEntity: {
    open: boolean;
    dropdownValue: string;
    useDropdown: boolean;
    isCorrect: boolean;
  };
  fileName: {
    open: boolean;
    value: string;
    isCorrect: boolean;
  };
  transactionType: {
    open: boolean;
    dropdownValue: string;
    useDropdown: boolean;
    isCorrect: boolean;
  };
  invoiceNumber: {
    open: boolean;
    from: string;
    to: string;
    isCorrect: boolean;
  };
  invoiceDate: {
    open: boolean;
    from: string;
    to: string;
    isCorrect: boolean;
  };
  balanceDue: {
    open: boolean;
    from: string;
    to: string;
    isCorrect: boolean;
  };
  daysOverdue: {
    open: boolean;
    from: string;
    to: string | null;
    useDropdown: boolean;
    dropdownValue: string;
    isCorrect: boolean;
  };
  productRatePlan: {
    open: boolean;
    from: string;
    to: string;
    isCorrect: boolean;
  };
  invoiceHeaderCompany: {
    open: boolean;
    header: HeaderCompany | null;
  };
  printModal: {
    open: boolean;
  };
  sendEmailModal: {
    open: boolean;
  };

  ableToProcess: boolean;
}

enum FormAction {
  OPEN_ACCOUNT_NAME,
  SET_FROM_ACCOUNT_NAME,
  SET_TO_ACCOUNT_NAME,

  OPEN_AICPAUID,
  SET_AICPAUID,
  SET_TO_AICPAUID,

  OPEN_LEGAL_ENTITY,
  SET_LEGAL_ENTITY,

  OPEN_FILENAME,
  SET_FILENAME,
  SET_TO_FILENAME,

  OPEN_TRANSACTION_TYPE,
  SET_DROPDOWN_VALUE_TRANSACTION_TYPE,

  OPEN_ACCOUNT_EMAILS,
  SET_ACCOUNT_EMAIL_ADDRESSES,

  OPEN_INVOICE_NUMBER,
  SET_FROM_INVOICE_NUMBER,
  SET_TO_INVOICE_NUMBER,

  OPEN_INVOICE_DATE,
  SET_FROM_INVOICE_DATE,
  SET_TO_INVOICE_DATE,

  OPEN_INVOICE_BALANCE_DUE,
  SET_FROM_INVOICE_BALANCE_DUE,
  SET_TO_INVOICE_BALANCE_DUE,

  OPEN_INVOICE_DAYS_OVERDUE,
  SET_FROM_INVOICE_DAYS_OVERDUE,
  SET_TO_INVOICE_DAYS_OVERDUE,
  SET_USE_DROPDOWN_INVOICE_DAYS_OVERDUE,
  SET_DROPDOWN_VALUE_INVOICE_DAYS_OVERDUE,

  OPEN_INVOICE_RATE_PLAN,
  SET_FROM_PRODUCT_RATE_PLAN,
  SET_TO_PRODUCT_RATE_PLAN,

  OPEN_INVOICE_HEADER_COMPANY,
  SET_INVOICE_HEADER_COMPANY,

  CLEAR_FILTERS,

  SET_PRINT_MODAL_VISIBILITY,
  SET_SEND_EMAIL_MODAL_VISIBILITY,

  SET_ABLE_TO_PROCESS,
}

interface DropdownOption {
  key: string;
  text: string;
  value: string;
}

interface DaysOverdueDropdownOption extends DropdownOption {
  from: string;
  to: string | null;
}

const daysOverdueDropdownOptions: DaysOverdueDropdownOption[] = [
  { text: '0-30 (Current)', key: '0-30', value: '0-30', from: '0', to: '30' },
  { text: '31-60 (Past due)', key: '31-60', value: '31-60', from: '31', to: '60' },
  { text: '61-90 (Past due)', key: '61-90', value: '61-90', from: '61', to: '90' },
  { text: '91-120 (Past due)', key: '91-120', value: '91-120', from: '91', to: '120' },
  { text: '121-150 (Past due)', key: '121-150', value: '121-150', from: '121', to: '150' },
  { text: '151-180 (Past due)', key: '151-180', value: '151-180', from: '151', to: '180' },
  { text: '181 and over (Past due)', key: '181-', value: '181-', from: '181', to: null },
];

const transactionTypeDropdownOptions: DropdownOption[] = [
  { text: 'Invoices', key: 'Invoices', value: 'Invoices' },
  { text: 'Debit Memos', key: 'Debit Memos', value: 'Debit Memos' },
  {
    text: 'Invoices and Debit Memos',
    key: 'Both Invoices and Debit Memos',
    value: 'Both Invoices and Debit Memos',
  },
];
const INVOICE_PREFIX = 'INV';

const regexNumber = /^[0-9\b]+$/;

const regexInvoiceNumber = /^(INV)[0-9\b]+$/;
const regexInvoicePrefix = /^(INV)/;
const formInitialState: FormState = {
  accountName: {
    open: true,
    from: '',
    to: '',
  },
  aicpaUID: {
    open: false,
    value: '',
    isCorrect: true,
  },
  legalEntity: {
    open: true,
    dropdownValue: '',
    useDropdown: true,
    isCorrect: true,
  },
  fileName: {
    open: false,
    value: '',
    isCorrect: true,
  },
  transactionType: {
    open: true,
    dropdownValue: '',
    useDropdown: true,
    isCorrect: true,
  },
  invoiceNumber: {
    open: true,
    from: '',
    to: '',
    isCorrect: true,
  },
  invoiceDate: {
    open: false,
    from: '',
    to: '',
    isCorrect: true,
  },
  balanceDue: {
    open: true,
    from: '',
    to: '',
    isCorrect: true,
  },
  daysOverdue: {
    open: false,
    from: '',
    to: '',
    useDropdown: true,
    dropdownValue: '',
    isCorrect: true,
  },
  productRatePlan: {
    open: false,
    from: '',
    to: '',
    isCorrect: true,
  },
  invoiceHeaderCompany: {
    open: false,
    header: null,
  },
  printModal: {
    open: false,
  },
  sendEmailModal: {
    open: false,
  },
  ableToProcess: false,
};

const FROM_TO_ERROR_MESSAGE = `Please adjust the “to” value - cannot be smaller than the “from” value.”`;

const formReducer = function reducer(state: FormState, action: { type: FormAction; payload: any }) {
  switch (action.type) {
    case FormAction.OPEN_ACCOUNT_NAME:
      // reset
      if (!action.payload) {
        return { ...state, accountName: { ...formInitialState.accountName, open: action.payload } };
      }

      return { ...state, accountName: { ...state.accountName, open: action.payload } };
    case FormAction.SET_FROM_ACCOUNT_NAME:
      return { ...state, accountName: { ...state.accountName, from: action.payload, to: action.payload } };
    case FormAction.SET_TO_ACCOUNT_NAME:
      return { ...state, accountName: { ...state.accountName, to: action.payload } };

    case FormAction.OPEN_AICPAUID:
      // reset
      if (!action.payload) {
        return { ...state, aicpaUID: { ...formInitialState.aicpaUID } };
      }
      return { ...state, aicpaUID: { ...state.aicpaUID, open: action.payload } };
    case FormAction.SET_AICPAUID:
      if (action.payload === '' || regexNumber.test(action.payload)) {
        return {
          ...state,
          aicpaUID: {
            ...state.aicpaUID,
            value: action.payload,
            isCorrect: true,
          },
        };
      }

      return { ...state };
    case FormAction.OPEN_LEGAL_ENTITY:
      // reset
      if (!action.payload) {
        return { ...state, legalEntity: { ...formInitialState.legalEntity, open: action.payload } };
      }
      return { ...state, legalEntity: { ...state.legalEntity, open: action.payload } };
    case FormAction.SET_LEGAL_ENTITY:
      return {
        ...state,
        legalEntity: {
          ...state.legalEntity,
          dropdownValue: action.payload,
          isCorrect: true,
        },
      };

    case FormAction.OPEN_FILENAME:
      // reset
      if (!action.payload) {
        return { ...state, fileName: { ...formInitialState.fileName } };
      }
      return { ...state, fileName: { ...state.fileName, open: action.payload } };
    case FormAction.SET_FILENAME:
      return {
        ...state,
        fileName: {
          ...state.fileName,
          value: action.payload,
          isCorrect: true,
        },
      };

    case FormAction.OPEN_TRANSACTION_TYPE:
      // reset
      if (!action.payload) {
        return { ...state, transactionType: { ...formInitialState.transactionType, open: action.payload } };
      }
      return { ...state, transactionType: { ...state.transactionType, open: action.payload } };
    case FormAction.SET_DROPDOWN_VALUE_TRANSACTION_TYPE: {
      // const selectedOption = transactionTypeDropdownOptions.find(option => option.key === action.payload);
      return {
        ...state,
        transactionType: {
          ...state.transactionType,
          dropdownValue: action.payload,
        },
      };
    }

    case FormAction.OPEN_INVOICE_NUMBER:
      // reset
      if (!action.payload) {
        return { ...state, invoiceNumber: { ...formInitialState.invoiceNumber, open: action.payload } };
      }
      return { ...state, invoiceNumber: { ...state.invoiceNumber, open: action.payload } };
    case FormAction.SET_FROM_INVOICE_NUMBER:
      if (
        action.payload === '' ||
        action.payload === INVOICE_PREFIX ||
        regexNumber.test(action.payload) ||
        regexInvoiceNumber.test(action.payload)
      ) {
        if (!regexInvoicePrefix.test(action.payload)) {
          const withPrefix = `${INVOICE_PREFIX}${action.payload}`;
          return {
            ...state,
            invoiceNumber: { ...state.invoiceNumber, from: withPrefix, to: withPrefix, isCorrect: true },
          };
        }
        return {
          ...state,
          invoiceNumber: { ...state.invoiceNumber, from: action.payload, to: action.payload, isCorrect: true },
        };
      }
      return { ...state };
    case FormAction.SET_TO_INVOICE_NUMBER:
      if (
        action.payload === '' ||
        action.payload === INVOICE_PREFIX ||
        regexNumber.test(action.payload) ||
        regexInvoiceNumber.test(action.payload)
      ) {
        if (!regexInvoicePrefix.test(action.payload)) {
          const withPrefix = `${INVOICE_PREFIX}${action.payload}`;
          return {
            ...state,
            invoiceNumber: {
              ...state.invoiceNumber,
              to: withPrefix,
              isCorrect: isFromToInvoiceNumberValuesCorrect(state.invoiceNumber.from, withPrefix),
            },
          };
        }
        return {
          ...state,
          invoiceNumber: {
            ...state.invoiceNumber,
            to: action.payload,
            isCorrect: isFromToInvoiceNumberValuesCorrect(state.invoiceNumber.from, action.payload),
          },
        };
      }
      return { ...state };

    case FormAction.OPEN_INVOICE_DATE:
      // reset
      if (!action.payload) {
        return { ...state, invoiceDate: { ...formInitialState.invoiceDate } };
      }
      return { ...state, invoiceDate: { ...state.invoiceDate, open: action.payload } };
    case FormAction.SET_FROM_INVOICE_DATE:
      if (action.payload?.length <= 10) {
        const formatted = Utils.replaceAutoSlashDate(action.payload);

        return {
          ...state,
          invoiceDate: {
            ...state.invoiceDate,
            from: formatted,
            to: formatted,
            isCorrect: isFromToDateValuesCorrect(formatted, formatted),
          },
        };
      }

      return { ...state };
    case FormAction.SET_TO_INVOICE_DATE:
      if (action.payload?.length <= 10) {
        const formatted = Utils.replaceAutoSlashDate(action.payload);

        return {
          ...state,
          invoiceDate: {
            ...state.invoiceDate,
            to: formatted,
            isCorrect: isFromToDateValuesCorrect(state.invoiceDate.from, formatted),
          },
        };
      }

      return { ...state };

    case FormAction.OPEN_INVOICE_BALANCE_DUE:
      // reset
      if (!action.payload) {
        return { ...state, balanceDue: { ...formInitialState.balanceDue, open: action.payload } };
      }
      return { ...state, balanceDue: { ...state.balanceDue, open: action.payload } };
    case FormAction.SET_FROM_INVOICE_BALANCE_DUE:
      if (action.payload === '' || regexNumber.test(action.payload)) {
        const filled = Boolean(action.payload);
        return {
          ...state,
          balanceDue: { ...state.balanceDue, from: action.payload, to: action.payload, isCorrect: filled },
        };
      }
      return { ...state };
    case FormAction.SET_TO_INVOICE_BALANCE_DUE:
      if (action.payload === '' || regexNumber.test(action.payload)) {
        return {
          ...state,
          balanceDue: {
            ...state.balanceDue,
            to: action.payload,
          },
        };
      }

      return { ...state };

    case FormAction.OPEN_INVOICE_DAYS_OVERDUE:
      // reset
      if (!action.payload) {
        return { ...state, daysOverdue: { ...formInitialState.daysOverdue } };
      }
      return { ...state, daysOverdue: { ...state.daysOverdue, open: action.payload } };
    case FormAction.SET_FROM_INVOICE_DAYS_OVERDUE:
      if (action.payload === '' || regexNumber.test(action.payload)) {
        return {
          ...state,
          daysOverdue: { ...state.daysOverdue, from: action.payload, to: action.payload, isCorrect: true },
        };
      }
      return { ...state };

    case FormAction.SET_TO_INVOICE_DAYS_OVERDUE:
      if (action.payload === '' || regexNumber.test(action.payload)) {
        return {
          ...state,
          daysOverdue: {
            ...state.daysOverdue,
            to: action.payload,
            isCorrect: isFromToNumberValuesCorrect(Number(state.daysOverdue.from), Number(action.payload)),
          },
        };
      }

      return { ...state };

    case FormAction.SET_USE_DROPDOWN_INVOICE_DAYS_OVERDUE: {
      return {
        ...state,
        daysOverdue: {
          ...state.daysOverdue,
          from: formInitialState.daysOverdue.from, // reset
          to: formInitialState.daysOverdue.to, // reset
          dropdownValue: formInitialState.daysOverdue.dropdownValue, // reset
          useDropdown: action.payload,
          isCorrect: true,
        },
      };
    }
    case FormAction.SET_DROPDOWN_VALUE_INVOICE_DAYS_OVERDUE: {
      const selectedOption = daysOverdueDropdownOptions.find(option => option.key === action.payload);

      return {
        ...state,
        daysOverdue: {
          ...state.daysOverdue,
          from: selectedOption?.from || '',
          to: selectedOption?.to || '',
          dropdownValue: action.payload,
        },
      };
    }

    case FormAction.OPEN_INVOICE_RATE_PLAN:
      // reset
      if (!action.payload) {
        return { ...state, productRatePlan: { ...formInitialState.productRatePlan } };
      }
      return { ...state, productRatePlan: { ...state.productRatePlan, open: action.payload } };
    case FormAction.SET_FROM_PRODUCT_RATE_PLAN:
      return {
        ...state,
        productRatePlan: {
          ...state.productRatePlan,
          from: action.payload,
          to: action.payload,
          isCorrect: Boolean(action.payload),
        },
      };
    case FormAction.SET_TO_PRODUCT_RATE_PLAN:
      return {
        ...state,
        productRatePlan: {
          ...state.productRatePlan,
          to: action.payload,
          isCorrect: isFromToRatePlanCorrect(state.productRatePlan.from, action.payload),
        },
      };

    case FormAction.OPEN_INVOICE_HEADER_COMPANY:
      // reset
      if (!action.payload) {
        return { ...state, invoiceHeaderCompany: { ...formInitialState.invoiceHeaderCompany } };
      }
      return {
        ...state,
        invoiceHeaderCompany: {
          ...state.invoiceHeaderCompany,
          open: action.payload,
          header: HeaderCompany.ASSOCIATION,
        },
      };
    case FormAction.SET_INVOICE_HEADER_COMPANY:
      return { ...state, invoiceHeaderCompany: { ...state.invoiceHeaderCompany, header: action.payload } };

    case FormAction.CLEAR_FILTERS:
      return formInitialState;

    case FormAction.SET_PRINT_MODAL_VISIBILITY:
      return { ...state, printModal: { ...state.printModal, open: action.payload } };

    case FormAction.SET_SEND_EMAIL_MODAL_VISIBILITY:
      return { ...state, sendEmailModal: { ...state.sendEmailModal, open: action.payload } };

    case FormAction.SET_ABLE_TO_PROCESS:
      return { ...state, ableToProcess: action.payload };

    default:
      return state;
  }
};

export const PageAdminFilterInvoices = () => {
  const [formState, dispatchFormAction] = useReducer(formReducer, formInitialState);

  // has to use global redux to manage parent footer position
  const stickyFooterVisible = useSelector(adminStickyFooterInvoiceFilterVisibleSelector);

  const loading = useSelector(sendEmailPrintLoadingSelector);
  const result = useSelector(sendEmailPrintResultSelector);

  const legalEntityOptions = Object.values(Salesforce.LegalEntity).map((value: any, index) => {
    return { key: index, value: value.toLowerCase(), text: value };
  });

  const dispatchRedux = useDispatch();

  // check if sticky CTA footer should be visible and CTAs disabled
  React.useEffect(() => {
    const formMinimumFilled =
      formState.accountName.from ||
      formState.accountName.to ||
      (formState.aicpaUID.value && formState.aicpaUID.isCorrect) ||
      (formState.legalEntity.dropdownValue && formState.legalEntity.isCorrect) ||
      (formState.invoiceNumber.from && formState.invoiceNumber.isCorrect) ||
      (formState.invoiceDate.from && formState.invoiceDate.isCorrect) ||
      (formState.balanceDue.from && formState.balanceDue.isCorrect) ||
      (formState.daysOverdue.from && formState.daysOverdue.isCorrect) ||
      (formState.productRatePlan.from && formState.productRatePlan.isCorrect) ||
      formState.invoiceHeaderCompany.header;

    const ableToProcess =
      formMinimumFilled &&
      formState.aicpaUID.isCorrect &&
      formState.legalEntity.isCorrect &&
      formState.invoiceNumber.isCorrect &&
      formState.invoiceDate.isCorrect &&
      formState.balanceDue.isCorrect &&
      formState.balanceDue.from &&
      formState.daysOverdue.isCorrect &&
      formState.productRatePlan.isCorrect;

    dispatchRedux(setAdminStickyFooterInvoiceFilterVisible(formMinimumFilled));

    if (ableToProcess !== formState.ableToProcess) {
      dispatchFormAction({ type: FormAction.SET_ABLE_TO_PROCESS, payload: ableToProcess });
    }
  }, [formState, stickyFooterVisible, dispatchRedux, dispatchFormAction]); // eslint-disable-line react-hooks/exhaustive-deps

  const togglePrintModal = React.useCallback(() => {
    dispatchFormAction({ type: FormAction.SET_PRINT_MODAL_VISIBILITY, payload: !formState.printModal.open });
  }, [formState, dispatchFormAction]);

  const toggleSendEmailModal = React.useCallback(() => {
    dispatchRedux(resetSendEmailPrintInvoices());
    dispatchFormAction({ type: FormAction.SET_SEND_EMAIL_MODAL_VISIBILITY, payload: !formState.sendEmailModal.open });
  }, [formState, dispatchFormAction, dispatchRedux]);

  const handleSendEmail = React.useCallback(
    (recipients: string[]) => {
      const sendEmailPayload: ZuoraTypes.InvoiceSearchPayload = {
        ...extractSendPrintPayloadFromForm(formState),
        EmailTransactions: true,
        EmailInvoices: true,
        EmailAddress: recipients.join(','),
        SendToPrint: false,
      };

      dispatchRedux(sendEmailPrintInvoices(sendEmailPayload));
    },
    [formState, dispatchRedux]
  );

  const handleSendToPrint = React.useCallback(() => {
    const sendEmailPayload: ZuoraTypes.InvoiceSearchPayload = {
      ...extractSendPrintPayloadFromForm(formState),
      EmailTransactions: false,
      EmailInvoices: false,
      EmailAddress: null,
      SendToPrint: true,
    };
    dispatchRedux(resetSendEmailPrintInvoices());
    dispatchRedux(sendEmailPrintInvoices(sendEmailPayload));
    togglePrintModal();
  }, [formState, dispatchRedux, togglePrintModal]);

  const clearAllFilters = React.useCallback(() => {
    dispatchFormAction({ type: FormAction.CLEAR_FILTERS, payload: null });
  }, []);

  const setFromAccountName = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_FROM_ACCOUNT_NAME, payload: e.target.value });
  }, []);

  const setToAccountName = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_TO_ACCOUNT_NAME, payload: e.target.value });
  }, []);

  const setAICPAUID = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_AICPAUID, payload: e.target.value });
  }, []);

  const setLegalEntity = React.useCallback((_: any, data: StrictDropdownProps) => {
    dispatchFormAction({ type: FormAction.SET_LEGAL_ENTITY, payload: data.value });
  }, []);

  const setFileName = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_FILENAME, payload: e.target.value });
  }, []);

  const setFromInvoiceBalanceDue = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_FROM_INVOICE_BALANCE_DUE, payload: e.target.value });
  }, []);

  const setToInvoiceBalanceDue = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_TO_INVOICE_BALANCE_DUE, payload: e.target.value });
  }, []);

  const changeDropdownTransactionType = React.useCallback((_: any, data: StrictDropdownProps) => {
    dispatchFormAction({ type: FormAction.SET_DROPDOWN_VALUE_TRANSACTION_TYPE, payload: data.value });
  }, []);

  const setFromInvoiceNumber = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_FROM_INVOICE_NUMBER, payload: e.target.value });
  }, []);

  const setToInvoiceNumber = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_TO_INVOICE_NUMBER, payload: e.target.value });
  }, []);

  const setFromInvoiceDate = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_FROM_INVOICE_DATE, payload: e.target.value });
  }, []);

  const setToInvoiceDate = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_TO_INVOICE_DATE, payload: e.target.value });
  }, []);

  const setUseDropdownInvoiceDaysOverdueTrue = React.useCallback(() => {
    dispatchFormAction({ type: FormAction.SET_USE_DROPDOWN_INVOICE_DAYS_OVERDUE, payload: true });
  }, []);

  const changeDropdownInvoiceDaysOverdue = React.useCallback((_: any, data: StrictDropdownProps) => {
    dispatchFormAction({ type: FormAction.SET_DROPDOWN_VALUE_INVOICE_DAYS_OVERDUE, payload: data.value });
  }, []);

  const setUseDropdownInvoiceDaysOverdueFalse = React.useCallback(() => {
    dispatchFormAction({ type: FormAction.SET_USE_DROPDOWN_INVOICE_DAYS_OVERDUE, payload: false });
  }, []);

  const setFromInvoiceDaysOverdue = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_FROM_INVOICE_DAYS_OVERDUE, payload: e.target.value });
  }, []);

  const setToInvoiceDaysOverdue = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_TO_INVOICE_DAYS_OVERDUE, payload: e.target.value });
  }, []);

  const setFromProductRatePlan = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_FROM_PRODUCT_RATE_PLAN, payload: e.target.value });
  }, []);

  const setToProductRatePlan = React.useCallback((e: { target: HTMLInputElement }) => {
    dispatchFormAction({ type: FormAction.SET_TO_PRODUCT_RATE_PLAN, payload: e.target.value });
  }, []);

  const setInvoiceHeaderAssociation = React.useCallback(() => {
    dispatchFormAction({
      type: FormAction.SET_INVOICE_HEADER_COMPANY,
      payload: HeaderCompanyValue.ASSOCIATION,
    });
  }, []);

  const setInvoiceHeaderAICPA = React.useCallback(() => {
    dispatchFormAction({ type: FormAction.SET_INVOICE_HEADER_COMPANY, payload: HeaderCompanyValue.AICPA });
  }, []);

  return (
    <>
      <StyledContainer>
        <PreviousPage backText={'Back to invoice'} />
        <StyledProfileHeading title={'Filter transactions'} />

        <Divider />
        <ContentWrap>
          <FlexWrapSpaceBetween>
            <SectionTitle>Account</SectionTitle> <RemoveButton handleRemove={clearAllFilters} />
          </FlexWrapSpaceBetween>

          <Divider />

          <FilterSection
            name={'Account name'}
            open={formState.accountName.open}
            dispatch={dispatchFormAction}
            openAction={{ type: FormAction.OPEN_ACCOUNT_NAME, payload: !formState.accountName.open }}
          >
            <FlexWrap>
              <div>
                <StyledInputWithValidation
                  labelName="From"
                  name={'From'}
                  type="text"
                  value={formState.accountName.from}
                  testId={'account-name-from-id'}
                  labelId={'account-name-from-label-id'}
                  onChange={setFromAccountName}
                  isCorrect={true}
                  errorMessage={''}
                  isCorrectIconShown
                  placeholder="e.g. Company-A"
                />
              </div>
              <div>
                <StyledInputWithValidation
                  labelName="To"
                  name={'To'}
                  type="text"
                  value={formState.accountName.to}
                  testId={'account-name-to-id'}
                  labelId={'account-name-to-label-id'}
                  onChange={setToAccountName}
                  isCorrect={true}
                  errorMessage={''}
                  isCorrectIconShown
                  placeholder="e.g. Company-Z"
                />
              </div>
            </FlexWrap>
          </FilterSection>

          <FilterSection
            name={'AICPA/CIMA UID'}
            open={formState.aicpaUID.open}
            dispatch={dispatchFormAction}
            openAction={{ type: FormAction.OPEN_AICPAUID, payload: !formState.aicpaUID.open }}
          >
            <FlexWrap>
              <div>
                <StyledInputWithValidation
                  name={'AICPA/CIMA UID'}
                  type="text"
                  value={formState.aicpaUID.value}
                  testId={'aicpa-uid-from-id'}
                  onChange={setAICPAUID}
                  isCorrect={formState.aicpaUID.isCorrect}
                  errorMessage={`${'AICPA/CIMA UID'} and Legal Entity are required field!”`}
                  absoluteErrorMessage
                  isCorrectIconShown
                  placeholder="e.g. 300000000001"
                />
              </div>
            </FlexWrap>
          </FilterSection>

          <FilterSection
            name={'Legal Entity'}
            open={formState.legalEntity.open}
            dispatch={dispatchFormAction}
            openAction={{ type: FormAction.OPEN_LEGAL_ENTITY, payload: !formState.legalEntity.open }}
          >
            {formState.legalEntity.useDropdown && (
              <div>
                <StyledDropdown
                  onChange={setLegalEntity}
                  testId="legal-entity"
                  value={formState.legalEntity.dropdownValue}
                  options={legalEntityOptions}
                  placeholder={'Select legal entity'}
                />
              </div>
            )}
          </FilterSection>

          <SectionTitle> Invoice</SectionTitle>
          <Divider />

          <FilterSection
            name={'File Name'}
            open={formState.fileName.open}
            dispatch={dispatchFormAction}
            openAction={{ type: FormAction.OPEN_FILENAME, payload: !formState.fileName.open }}
          >
            <FlexWrap>
              <div>
                <StyledInputWithValidation
                  name={'File Name'}
                  type="text"
                  value={formState.fileName.value}
                  testId={'file-name-id'}
                  onChange={setFileName}
                  isCorrect={formState.fileName.isCorrect}
                  errorMessage={FROM_TO_ERROR_MESSAGE}
                  absoluteErrorMessage
                  isCorrectIconShown
                />
              </div>
            </FlexWrap>
          </FilterSection>

          <FilterSection
            name={'Transaction Type'}
            open={formState.transactionType.open}
            dispatch={dispatchFormAction}
            openAction={{ type: FormAction.OPEN_TRANSACTION_TYPE, payload: !formState.transactionType.open }}
          >
            {formState.transactionType.useDropdown && (
              <StyledDropdown
                testId="transaction-type-dropdown"
                onChange={changeDropdownTransactionType}
                options={transactionTypeDropdownOptions}
                value={formState.transactionType.dropdownValue}
                placeholder={'Select type'}
                defaultValue={'Invoices'}
              />
            )}
          </FilterSection>

          <FilterSection
            name={'Balance due ($)'}
            open={formState.balanceDue.open}
            dispatch={dispatchFormAction}
            openAction={{ type: FormAction.OPEN_INVOICE_BALANCE_DUE, payload: !formState.balanceDue.open }}
          >
            <HelperRequiered>This is a required field.</HelperRequiered>
            <FlexWrap>
              <div>
                <StyledInputWithValidationShort
                  labelName="From"
                  name={'From'}
                  type="text"
                  value={formState.balanceDue.from}
                  testId={'account-balance-from-id'}
                  labelId={'account-balance-from-label-id'}
                  onChange={setFromInvoiceBalanceDue}
                  isCorrect={formState.balanceDue.isCorrect}
                  errorMessage={!formState.balanceDue.from ? 'This is a required field, please enter value.' : ''}
                  absoluteErrorMessage
                  isCorrectIconShown
                  placeholder="e.g. 0"
                />
              </div>
              <div>
                <StyledInputWithValidationShort
                  labelName="To"
                  name={'To'}
                  type="text"
                  value={formState.balanceDue.to}
                  testId={'account-balance-to-id'}
                  labelId={'account-balance-to-label-id'}
                  onChange={setToInvoiceBalanceDue}
                  isCorrect={formState.balanceDue.isCorrect}
                  errorMessage=""
                  isCorrectIconShown
                  placeholder="e.g. 100"
                />
              </div>
            </FlexWrap>
          </FilterSection>
          {formState.transactionType.dropdownValue !== 'Both Invoices and Debit Memos' && (
            <FilterSection
              name={'Transaction number'}
              open={formState.invoiceNumber.open}
              dispatch={dispatchFormAction}
              openAction={{ type: FormAction.OPEN_INVOICE_NUMBER, payload: !formState.invoiceNumber.open }}
            >
              <FlexWrap>
                <div>
                  <StyledInputWithValidation
                    labelName="From"
                    name={'From'}
                    type="text"
                    value={formState.invoiceNumber.from}
                    testId={'account-invoice-num-from-id'}
                    labelId={'account-invoice-num-from-label-id'}
                    onChange={setFromInvoiceNumber}
                    isCorrect={formState.invoiceNumber.isCorrect}
                    errorMessage={FROM_TO_ERROR_MESSAGE}
                    absoluteErrorMessage
                    isCorrectIconShown
                    placeholder="e.g. 32XXXXXXXXXXX"
                  />
                </div>
                <div>
                  <StyledInputWithValidation
                    labelName="To"
                    name={'To'}
                    type="text"
                    value={formState.invoiceNumber.to}
                    testId={'account-invoice-num-to-id'}
                    labelId={'account-invoice-num-to-label-id'}
                    onChange={setToInvoiceNumber}
                    isCorrect={formState.invoiceNumber.isCorrect}
                    errorMessage=""
                    isCorrectIconShown
                    placeholder="e.g. 32XXXXXXXXXXX"
                  />
                </div>
              </FlexWrap>
            </FilterSection>
          )}

          <FilterSection
            name={'Transaction date'}
            open={formState.invoiceDate.open}
            dispatch={dispatchFormAction}
            openAction={{ type: FormAction.OPEN_INVOICE_DATE, payload: !formState.invoiceDate.open }}
          >
            <FlexWrap>
              <div>
                <StyledInputWithValidationShort
                  labelName="From"
                  name={'From'}
                  type="text"
                  value={formState.invoiceDate.from}
                  testId={'account-invoice-date-from-id'}
                  labelId={'account-invoice-date-from-label-id'}
                  onChange={setFromInvoiceDate}
                  isCorrect={formState.invoiceDate.isCorrect}
                  errorMessage={`${FROM_TO_ERROR_MESSAGE} Invalid date. Please try again.`}
                  absoluteErrorMessage
                  isCorrectIconShown
                  placeholder="MM/DD/YYYY"
                />
              </div>
              <div>
                <StyledInputWithValidationShort
                  labelName="To"
                  name={'To'}
                  type="text"
                  value={formState.invoiceDate.to}
                  testId={'account-invoice-date-to-id'}
                  labelId={'account-invoice-date-to-label-id'}
                  onChange={setToInvoiceDate}
                  isCorrect={formState.invoiceDate.isCorrect}
                  errorMessage=""
                  isCorrectIconShown
                  placeholder="MM/DD/YYYY"
                />
              </div>
            </FlexWrap>
          </FilterSection>

          <FilterSection
            name={'Days overdue (aging bucket)'}
            open={formState.daysOverdue.open}
            dispatch={dispatchFormAction}
            openAction={{ type: FormAction.OPEN_INVOICE_DAYS_OVERDUE, payload: !formState.daysOverdue.open }}
          >
            <FlexWrapRadio onClick={setUseDropdownInvoiceDaysOverdueTrue}>
              <StyledRadioButton checked={formState.daysOverdue.useDropdown} />
              <StyledOptionText checked={formState.daysOverdue.useDropdown}>Between</StyledOptionText>
            </FlexWrapRadio>

            {formState.daysOverdue.useDropdown && (
              <StyledDropdown
                testId="days-overdue-dropdown"
                onChange={changeDropdownInvoiceDaysOverdue}
                options={daysOverdueDropdownOptions}
                value={formState.daysOverdue.dropdownValue}
                placeholder={'Select range'}
                defaultValue={''}
              />
            )}

            <FlexWrapRadio onClick={setUseDropdownInvoiceDaysOverdueFalse}>
              <StyledRadioButton checked={!formState.daysOverdue.useDropdown} />
              <StyledOptionText checked={!formState.daysOverdue.useDropdown}>Custom range</StyledOptionText>
            </FlexWrapRadio>

            {!formState.daysOverdue.useDropdown && (
              <>
                <FlexWrap>
                  <div>
                    <StyledInputWithValidationShort
                      labelName="From"
                      name="From"
                      type="text"
                      value={formState.daysOverdue.from}
                      testId={'account-days-overdue-from-id'}
                      labelId={'account-days-overdue-from-label-id'}
                      onChange={setFromInvoiceDaysOverdue}
                      isCorrect={formState.daysOverdue.isCorrect}
                      errorMessage={FROM_TO_ERROR_MESSAGE}
                      absoluteErrorMessage
                      isCorrectIconShown
                      placeholder="e.g. 0"
                    />
                  </div>
                  <div>
                    <StyledInputWithValidationShort
                      labelName="To"
                      name="To"
                      type="text"
                      value={formState.daysOverdue.to}
                      testId={'account--days-overdue-to-id'}
                      labelId={'account--days-overdue-to-label-id'}
                      onChange={setToInvoiceDaysOverdue}
                      isCorrect={formState.daysOverdue.isCorrect}
                      errorMessage=""
                      isCorrectIconShown
                      placeholder="e.g. 100"
                    />
                  </div>
                </FlexWrap>
              </>
            )}
          </FilterSection>

          <FilterSection
            name={'Product rate plan'}
            open={formState.productRatePlan.open}
            dispatch={dispatchFormAction}
            openAction={{ type: FormAction.OPEN_INVOICE_RATE_PLAN, payload: !formState.productRatePlan.open }}
          >
            <FlexWrap>
              <div>
                <StyledInputWithValidation
                  labelName="From"
                  name={'From'}
                  type="text"
                  value={formState.productRatePlan.from}
                  testId={'product-rate-plan-from-id'}
                  labelId={'product-rate-plan-from-label-id'}
                  onChange={setFromProductRatePlan}
                  isCorrect={formState.productRatePlan.isCorrect}
                  errorMessage={FROM_TO_ERROR_MESSAGE}
                  absoluteErrorMessage
                  isCorrectIconShown
                  placeholder="e.g. 123"
                />
              </div>
              <div>
                <StyledInputWithValidation
                  labelName="To"
                  name={'To'}
                  type="text"
                  value={formState.productRatePlan.to}
                  testId={'product-rate-plan-to-id'}
                  labelId={'product-rate-plan-to-label-id'}
                  onChange={setToProductRatePlan}
                  isCorrect={formState.productRatePlan.isCorrect}
                  errorMessage={''}
                  isCorrectIconShown
                  placeholder="e.g. 123"
                />
              </div>
            </FlexWrap>
          </FilterSection>

          <FilterSection
            name={'Invoice header company'}
            open={formState.invoiceHeaderCompany.open}
            dispatch={dispatchFormAction}
            openAction={{ type: FormAction.OPEN_INVOICE_HEADER_COMPANY, payload: !formState.invoiceHeaderCompany.open }}
          >
            <FlexWrapRadio onClick={setInvoiceHeaderAssociation}>
              <StyledRadioButton checked={formState.invoiceHeaderCompany.header === HeaderCompanyValue.ASSOCIATION} />
              <StyledOptionText checked={formState.invoiceHeaderCompany.header === HeaderCompanyValue.ASSOCIATION}>
                {HeaderCompany.ASSOCIATION}
              </StyledOptionText>
            </FlexWrapRadio>
            <FlexWrapRadio onClick={setInvoiceHeaderAICPA}>
              <StyledRadioButton checked={formState.invoiceHeaderCompany.header === HeaderCompanyValue.AICPA} />
              <StyledOptionText checked={formState.invoiceHeaderCompany.header === HeaderCompanyValue.AICPA}>
                {HeaderCompany.AICPA}
              </StyledOptionText>
            </FlexWrapRadio>
          </FilterSection>
        </ContentWrap>
        {stickyFooterVisible && (
          <StickyFooter>
            {' '}
            <FooterButton
              testId="email-invoices-footer"
              disabled={!formState.ableToProcess}
              iconName={'mail'}
              iconPosition={ButtonEnums.iconPosition.left}
              variant={ButtonVariants.secondary}
              onClick={toggleSendEmailModal}
            >
              Email invoices
            </FooterButton>
            <FooterButton
              testId="print-invoices-footer"
              disabled={!formState.ableToProcess}
              iconName={'print'}
              iconPosition={ButtonEnums.iconPosition.left}
              variant={ButtonVariants.primary}
              onClick={handleSendToPrint}
            >
              Send to print vendor
            </FooterButton>
          </StickyFooter>
        )}
      </StyledContainer>

      <SendEmailsModal
        visible={formState.sendEmailModal.open}
        hide={toggleSendEmailModal}
        loading={loading}
        sendError={Boolean(result && !result.success)}
        sendEmails={handleSendEmail}
        result={result}
      />

      <SendToPrintModal
        visible={formState.printModal.open}
        hide={togglePrintModal}
        loading={loading}
        fileName={result?.fileName}
      />
      <StyledNotifications />
    </>
  );
};

const extractSendPrintPayloadFromForm = (
  formData: FormState
): Omit<ZuoraTypes.InvoiceSearchPayload, 'EmailInvoices' | 'EmailAddress' | 'SendToPrint'> => ({
  FileName: formData.fileName.value || null,
  InvoiceNumberFrom: formData.invoiceNumber.from || null,
  InvoiceNumberTo: formData.invoiceNumber.to || null,
  TransactionType: formData.transactionType.dropdownValue || null,
  CreationDateFrom: formData.invoiceDate.from
    ? moment(formData.invoiceDate.from, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : null,
  CreationDateTo: formData.invoiceDate.to ? moment(formData.invoiceDate.to, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
  AICPAUID: formData.aicpaUID.value || null,
  LegalEntity: formData.legalEntity.dropdownValue || null,
  AccountNameFrom: formData.accountName.from || null,
  AccountNameTo: formData.accountName.to || null,
  ProductRatePlanFrom: formData.productRatePlan.from || null,
  ProductRatePlanTo: formData.productRatePlan.to || null,
  Company: formData.invoiceHeaderCompany.header || null,
  DaysOverdueFrom: formData.daysOverdue.from || null,
  DaysOverdueTo: formData.daysOverdue.to || null,
  BalanceFrom: formData.balanceDue.from || null,
  BalanceTo: formData.balanceDue.to || null,
});

const isFromToNumberValuesCorrect = (from: number, to: number) => {
  return from <= to;
};
const isFromToInvoiceNumberValuesCorrect = (from: string, to: string) => {
  const fromNumber = Number(from.split(INVOICE_PREFIX)[1]);
  const toNumber = Number(to.split(INVOICE_PREFIX)[1]);
  return fromNumber <= toNumber;
};

const isFromToRatePlanCorrect = (from: string, to: string) => {
  return Boolean(from <= to);
};

const isDateFormatCorrect = (date: string) => {
  // check MM/DD/YYYY format
  const splitted = date.split('/');
  if (splitted?.length !== 3) {
    return false;
  }

  if (Number(splitted[0]) > 12) {
    return false;
  }

  if (Number(splitted[1]) > 31) {
    return false;
  }

  // is not in the future
  const isInFuture = moment(date).isAfter(moment());
  if (isInFuture) {
    return false;
  }
  return date?.length === 10;
};

const isFromToDateValuesCorrect = (from: string, to: string) => {
  const fromFormatCorrect = isDateFormatCorrect(from);
  const toFormatCorrect = isDateFormatCorrect(to);
  const chronological = moment(to).isAfter(from) || from === to;

  return chronological && fromFormatCorrect && toFormatCorrect;
};

const FilterSection: React.FC<{
  name: string;
  open: boolean;
  dispatch: any;
  openAction: { type: FormAction; payload: boolean };
}> = ({ name, children, dispatch, open, openAction }) => {
  const dispatchOpenAction = React.useCallback(() => {
    dispatch(openAction);
  }, [dispatch, openAction]);

  return (
    <>
      <StyledCheckbox
        label={name}
        checked={open}
        id={`${name}-id`}
        testId={`${name}-test-id`}
        onClick={dispatchOpenAction}
      />

      {open && <SectionContent>{children}</SectionContent>}
      <Divider />
    </>
  );
};

const StyledNotifications = styled(Notifications)`
  &&&&& {
    z-index: 10000000;
  }
`;

const FooterButton = styled(Button)`
  &&&& {
    height: ${props => props.theme.pxToRem(40)};
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-left: ${props => props.theme.pxToRem(20)};
  }
`;

const StickyFooter = styled.div`
  z-index: 1;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  height: ${props => props.theme.pxToRem(100)};
  left: 0;
  bottom: 0;
  right: 0;
  padding-right: ${props => props.theme.pxToRem(110)};
  border-top: 1px solid ${props => props.theme.colors.neutralGrey3};
  background: ${props => props.theme.colors.neutralGrey1};
`;

const StyledProfileHeading = styled(ProfileHeading)`
  &&&&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
    padding-bottom: 0;
    border-bottom: none;

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(16)};
    }
  }
`;

const StyledInputWithValidation = styled(InputWithValidation)`
  &&&&& {
    width: ${props => props.theme.pxToRem(265)};
    margin-right: ${props => props.theme.pxToRem(20)};
  }
`;

const StyledInputWithValidationShort = styled(StyledInputWithValidation as any)`
  &&&&& {
    width: ${props => props.theme.pxToRem(160)};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  &&&&& {
    display: block;
  }

  &&&&& > label {
    font-weight: ${props => props.theme.fontWeights.regular};
    font-size: ${props => props.theme.fontSizes.s};
    color: ${props => props.theme.colors.neutralGrey8};
    padding-top: ${props => props.theme.pxToRem(2)};
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      padding: ${props.theme.pxToRem(12)};
      width: ${props.theme.pxToRem(280)};
      height: ${props.theme.pxToRem(46)};
      margin-right: ${props.theme.pxToRem(12)};
    }
  `}
`;

const StyledContainer = styled(Container)`
  text-align: left;
  max-width: ${props => props.theme.pxToRem(640)};
`;

const SectionContent = styled.div`
  margin-top: ${props => props.theme.pxToRem(24)};
  margin-left: ${props => props.theme.pxToRem(40)};
`;

const SectionTitle = styled.div`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
`;

const ContentWrap = styled.div`
  max-width: ${props => props.theme.pxToRem(744)};
`;

const FlexWrapRadio = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(24)};
  :hover {
    font-weight: ${props => props.theme.fontWeights.bold};
    cursor: pointer;
  }
`;

const FlexWrapSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FlexWrap = styled.div`
  display: flex;
`;

const StyledRadioButton = styled(RadioButton)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  margin: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(12)} 0 0;
  div {
    height: ${props => props.theme.pxToRem(8)};
    width: ${props => props.theme.pxToRem(8)};
  }
`;

const StyledOptionText = styled.div<{ checked?: boolean }>`
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  margin-top: ${props => props.theme.pxToRem(11)};
  font-weight: ${props => (props.checked ? props.theme.fontWeights.medium : props.theme.fontWeights.light)};

  :hover {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
`;

const HelperText = styled.div`
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xxs};
`;

const HelperRequiered = styled(HelperText as any)`
  margin-top: ${props => props.theme.pxToRem(-16)};
  margin-left: ${props => props.theme.pxToRem(-10)};
  margin-bottom: ${props => props.theme.pxToRem(12)};
`;

const RemoveButton: React.FC<{ handleRemove: () => void }> = ({ handleRemove }) => {
  return (
    <StyledButton
      testId={`remove-code`}
      variant={ButtonEnums.variants.link}
      size={ButtonEnums.sizes.small}
      icon={<StyledCancel />}
      iconPosition={ButtonEnums.iconPosition.left}
      onClick={handleRemove}
    >
      Clear all filters
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  &&&& {
    &:hover {
      color: ${props => props.theme.colors.neutralGrey8};
    }
    font-size: ${props => props.theme.pxToRem(14)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.pxToRem(16)};
    }
    svg path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const StyledCancel = styled(Cancel)`
  &&&&&&& {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: ${props => props.theme.pxToRem(24)};
      height: ${props => props.theme.pxToRem(24)};
    }
  }
`;
