import React, { useState } from 'react';
import styled, { ThemedStyledProps, DefaultTheme, css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { savedItemsSelector } from 'modules/savedItems';
import { savedBlogPostsSelector } from 'modules/savedBlogPosts';
import { copy } from 'modules/copy/actions';
import { useToggleSavedItem } from 'hooks/useToggleSavedItem';
import { ContentLock } from 'components/molecules/ContentLock/ContentLock';
import {
  LazyImage,
  Button,
  ButtonEnums,
  Category,
  CardTitleSubtitle,
  Link,
  LinkEnums,
  OnlyDesktop,
  OnlyMobile,
  ContentSource,
} from 'components/atoms';
import { ContentTypes } from 'constants/index';
import { MomentHelpers, getContentUrl, getFullContentUrl, arrayIncludes } from 'utils';
import { Bookmark, Bookmarked, Copy } from 'components/atoms/svg';
import { userMemberTypeSelector } from 'modules/user/selectors';

interface Props {
  className?: string;
  contentCardItem: State.ContentCardItem;
  isDoubleWidthCard?: boolean;
  testid?: string;
  isEditorialHeroCard?: boolean;
  isBgColorDark?: boolean;
  hasLargeTitleText?: boolean;
  isTransparentCard?: boolean;
}

export const EditorialHeroCard: React.FC<Props> = React.memo(
  ({
    className,
    contentCardItem,
    isDoubleWidthCard = false,
    testid,
    isEditorialHeroCard = false,
    isBgColorDark = false,
    hasLargeTitleText = false,
    isTransparentCard = false,
  }) => {
    const dispatch = useDispatch();

    const {
      id,
      title,
      slug,
      externalUrl,
      description,
      timeToConsume,
      contentCategory,
      image,
      contentType,
      contentfulType,
      productType,
      dateCreated,
      contentSource,
      downloads,
      roles,
      matchedRoles,
      isLocked,
      topicalSubscriptions,
    } = contentCardItem;

    const savedItems: string[] | null = useSelector(savedItemsSelector);
    const savedBlogPosts: string[] | null = useSelector(savedBlogPostsSelector);
    const membershipType = useSelector(userMemberTypeSelector);
    const isSaved: boolean = arrayIncludes([...(savedItems || []), ...(savedBlogPosts || [])], id);
    const isProduct: boolean = contentType.slug === ContentTypes.PRODUCT;
    const isDownloadTemplate: boolean = contentfulType === ContentTypes.DOWNLOAD;

    const date = contentCardItem.dateCreated ? MomentHelpers.getAgoTime(contentCardItem.dateCreated) : 'Date unknown';

    const [imgClip, setImgClip] = useState('');
    const headerRef: any = React.useRef();
    const categoryRef: any = React.useRef();

    const handleBookmarkButtonClick = useToggleSavedItem({
      id,
      isSaved,
      contentSource,
      title,
      description,
      slug,
      externalUrl,
    });

    const consumptionValue = MomentHelpers.getTimeToConsumeOrSize({
      time: timeToConsume,
      category: contentfulType,
      downloads,
    });

    const handleCopy = React.useCallback(
      (event: React.SyntheticEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const url = getFullContentUrl(contentCardItem);

        dispatch(copy({ id, copiedText: url, notificationText: title }));
      },
      [id, title, contentCardItem, dispatch]
    );

    const handleClipResize = () => {
      if (categoryRef.current && headerRef.current) {
        const imgH = headerRef.current.getBoundingClientRect().height || 0;
        const imgW = headerRef.current.getBoundingClientRect().width || 0;
        const catH = categoryRef.current.getBoundingClientRect().height || 0;
        const catW = categoryRef.current.getBoundingClientRect().width || 0;
        setImgClip(
          `polygon(0 0, ${imgW}px 0, ${imgW}px ${imgH}px, ${catW}px ${imgH}px, ${catW}px ${imgH - catH}px, 0 ${
            imgH - catH
          }px)`
        );
      }
    };

    React.useEffect(() => {
      handleClipResize();
      window.addEventListener('resize', handleClipResize);
      return () => window.removeEventListener('resize', handleClipResize);
    }, []);

    const renderImage = (): React.ReactNode => {
      if (!image) return null;
      const defaultImageRatio: string = `100%`;

      const contentfulAdjustments = '&w=360&h=380&fit=thumb';

      return isDoubleWidthCard ? (
        <>
          <OnlyDesktop
            as={StyledLazyImage}
            src={`${image.url}${contentfulAdjustments}`}
            alt={image.altText}
            imageRatio="26.42%"
            clipPath={imgClip}
            stretchByWidth
          />
          <OnlyMobile
            as={StyledLazyImage}
            src={`${image.url}${contentfulAdjustments}`}
            alt={image.altText}
            imageRatio={defaultImageRatio}
            clipPath={imgClip}
            stretchByWidth
          />
        </>
      ) : (
        <StyledLazyImage
          src={`${image.url}${contentfulAdjustments}`}
          alt={image.altText}
          imageRatio="80%"
          clipPath={imgClip}
          stretchByWidth
        />
      );
    };

    return (
      <StyledContainer
        className={className}
        data-testid={testid}
        isTransparentCard={isTransparentCard}
        isBgColorDark={isBgColorDark}
      >
        {!isProduct && (
          <ContentLock
            isLocked={isLocked}
            testId={testid}
            matchedRoles={matchedRoles}
            contentRoles={roles}
            userStatus={membershipType}
            restrictionDetails={contentCardItem.restrictionDetails}
            topicalSubscriptions={topicalSubscriptions}
          />
        )}
        <Link
          underline={LinkEnums.underline.noUnderlineOnHover}
          type={LinkEnums.type.standaloneLink}
          isExternal={Boolean(externalUrl)}
          to={getContentUrl(contentCardItem)}
          testId={testid}
          target={isDownloadTemplate ? '_blank' : ''}
          aria-label={title}
        >
          <StyledCard>
            {renderImage()}
            <StyledContent>
              <StyledCategoryItem>
                <Category
                  categoryName={isProduct && productType ? productType.join(', ') : contentCategory.name}
                  categorySlug={contentCategory.name}
                  isProduct={!isProduct}
                  isBgColorDark={isBgColorDark}
                />
              </StyledCategoryItem>
              <StyledCardTitleSubtitle>
                <CardTitleSubtitle
                  testId="card-title-subtitle"
                  title={contentCardItem.title}
                  hasLargeTitleText={hasLargeTitleText}
                />
              </StyledCardTitleSubtitle>
              {description && <StyledDescription>{description}</StyledDescription>}
              <StyledContentItems>
                <StyledBottom isLeftAligned={isEditorialHeroCard} isBgColorDark={isBgColorDark}>
                  <StyledDetails>
                    {!isProduct && (
                      <StyledContentInfo
                        isEditorialHeroCard={isEditorialHeroCard}
                        isExternalArticle={Boolean(externalUrl)}
                        isBgColorDark={isBgColorDark}
                        tabIndex={0}
                      >
                        {consumptionValue && date && isEditorialHeroCard && (
                          <StyledDate>{`${date} · ${consumptionValue}`}</StyledDate>
                        )}
                        {contentSource && isEditorialHeroCard && (
                          <StyledContentSource isStacked={!!(dateCreated && consumptionValue)}>
                            <ContentSource contentSource={contentSource} />
                          </StyledContentSource>
                        )}
                      </StyledContentInfo>
                    )}
                  </StyledDetails>
                  {Boolean(!isProduct) && (
                    <ButtonWrapper>
                      <CopyButton
                        isProduct={isProduct}
                        aria-label={`Copy ${title}`}
                        testId={`copy-button-${id}`}
                        onClick={handleCopy}
                        variant={
                          isBgColorDark ? ButtonEnums.variants.iconWhiteWithCircle : ButtonEnums.variants.iconWithCircle
                        }
                      >
                        <OffScreenSpan>{title}</OffScreenSpan>
                        <Copy />
                      </CopyButton>
                      {!isProduct && (
                        <StyledBookmarkButton
                          aria-label={`Bookmark ${title}`}
                          testId={`bookmark-button-${id}`}
                          onClick={handleBookmarkButtonClick}
                          active={isSaved}
                          variant={
                            isBgColorDark
                              ? ButtonEnums.variants.iconWhiteWithCircle
                              : ButtonEnums.variants.iconWithCircle
                          }
                        >
                          <OffScreenSpan>{title}</OffScreenSpan>
                          {isSaved ? <Bookmarked /> : <Bookmark />}
                        </StyledBookmarkButton>
                      )}
                    </ButtonWrapper>
                  )}
                </StyledBottom>
              </StyledContentItems>
            </StyledContent>
          </StyledCard>
        </Link>
      </StyledContainer>
    );
  }
);

const StyledContainer = styled.div<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  border-radius: 0.0625rem;
  min-height: ${props => props.theme.pxToRem(380)};
  box-shadow: 0 0.225rem 0.875rem 0 rgba(0, 0, 0, 0.2);
  background-color: ${props =>
    props.isTransparentCard ? 'rgba(255, 255, 255, 0.1)' : props.theme.colors.neutralWhite};
  display: block;
  position: relative;

  ${props => props.theme.mediaQueries.desktopOnly} {
    max-width: 105%;
    width: ${props => props.theme.pxToRem(360)};

    img {
      height: ${props => props.theme.pxToRem(380)};
    }
  }

  &:hover {
    outline: ${props => props.theme.pxToRem(1)} solid
      ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.primaryPurple)};

    a > article > div:last-child > div:first-child > div {
      color: ${props => props.theme.colors.primaryPurple};
    }

    ${props =>
      props.isTransparentCard &&
      `
        background-color: rgba(255, 255, 255, 0.2);
        outline-color: rgba(255, 255, 255, 0.6);
    `}
  }
`;

const StyledContent = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: ${props => props.theme.pxToRem(96)};
  padding: ${props => props.theme.pxToRem(12)};
  box-sizing: border-box;
  background: ${props => props.theme.colors.neutralWhite};
  border-top: ${props => props.theme.pxToRem(2)} solid green;

  @media (min-width: 320px) and (max-width: 767px) {
    &&& {
      bottom: 0;
      height: ${props => props.theme.pxToRem(96)};
    }
  }

  @media (min-width: 1500px) and (max-width: 2000px) {
    &&& {
      bottom: 0;
    }
  }
`;

const StyledContentItems = styled.div`
  visibility: hidden;
`;

const StyledDescription = styled.div`
  visibility: hidden;
  color: ${props => props.theme.colors.neutralBlack};
  font-size: 14px;
  font-family: Roboto;
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledCard = styled.div`
  position: relative;
  min-height: ${props => props.theme.pxToRem(380)};
  height: 100%;

  &:hover {
    cursor: pointer;

    img {
      height: ${props => props.theme.pxToRem(230)} !important;
    }
  }

  &:hover ${StyledContent} {
    width: 100%;
    height: ${props => props.theme.pxToRem(246)};
    left: 0;
    bottom: 0;
  }

  &:hover ${StyledContentItems}, &:hover ${StyledDescription} {
    visibility: visible;
  }

  @media (min-width: 765px) and (max-width: 1024px) {
    &:hover ${StyledDescription} {
      visibility: hidden;
    }
  }

  @media (min-width: 1036px) and (max-width: 1320px) {
    height: ${props => props.theme.pxToRem(40)};
    overflow: hidden;

    &:hover ${StyledDescription} {
      font-size: ${props => props.theme.fontSizes.xs};
    }
  }
`;

const StyledCategoryItem = styled.div`
  .category-box {
    height: ${props => props.theme.pxToRem(30)};
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.neutralWhite};
    position: absolute;
    top: ${props => props.theme.pxToRem(-30)};
    left: 0;
    padding: ${props => props.theme.pxToRem(10)};
  }

  .category-icon {
    display: none;
  }
`;

const StyledCardTitleSubtitle = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};

  .card-title {
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: normal;
    position: relative;
    font-family: Roboto;

    @media (min-width: 765px) and (max-width: 1320px) {
      font-size: ${props => props.theme.fontSizes.s};
    }
  }
`;

interface StyledBottomProps {
  isAggregationCard?: boolean;
  isLeftAligned?: boolean;
  isBgColorDark?: boolean;
}

const StyledBottom = styled.div<StyledBottomProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
  height: 28%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(15)} ${props => props.theme.pxToRem(10)};

  ${props => props.isLeftAligned && 'text-align: left;'}
`;

const StyledDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: ${props => props.theme.pxToRem(5)};
`;

interface StyledContentInfoProps {
  isEditorialHeroCard: boolean;
  isExternalArticle: boolean;
  isBgColorDark: boolean;
}

const StyledContentInfo = styled.div<StyledContentInfoProps>`
  ${props => `width: ${props.isExternalArticle ? '100%' : 'auto'}`}
  font-size: ${props => props.theme.fontSizes.xxs};
  small {
    color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey7)};
  }

  ${props =>
    props.isEditorialHeroCard &&
    css`
      line-height: 1.33;
      color: ${props.theme.colors.neutralGrey7};
    `};
`;

const StyledDate = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
`;

const StyledContentSource = styled.div<{ isStacked: boolean }>`
  margin-top: ${props => (props.isStacked ? '0' : props.theme.pxToRem(6))};
`;

const ButtonWrapper = styled.div`
  button {
    margin-bottom: 0.3rem !important;
  }
  & {
    display: flex;
    line-height: 0;
  }
`;

const CopyButton = styled(Button)<{ isProduct: boolean }>`
  &&&& {
    margin-right: ${props => (!props.isProduct ? '1rem' : '0.3rem')};
    margin-right: 1rem;
    ${props => props.theme.mediaQueries.computerMin} {
      &&:focus {
        border-color: transparent;
        background: ${props => props.theme.colors.primaryLightPurple};
        outline: none;
        path {
          fill: ${props =>
            props.variant === ButtonEnums.variants.iconWhiteTone
              ? props.theme.colors.primaryIndicatorPurple
              : props.theme.colors.neutralWhite};
        }
      }
    }
  }
`;

const OffScreenSpan = styled.span`
  border: 0 !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`;

const StyledBookmarkButton = styled(Button)`
  &&&& {
    ${props => props.theme.mediaQueries.computerMin} {
      &&:focus {
        border-color: transparent;
        background: ${props => props.theme.colors.primaryLightPurple};
        outline: none;
        path {
          fill: ${props =>
            props.variant === ButtonEnums.variants.iconWhiteTone
              ? props.theme.colors.primaryIndicatorPurple
              : props.theme.colors.neutralWhite};
        }
      }
    }
  }
`;

const StyledLazyImage = styled(LazyImage)<{ clipPath: string }>`
  ${props => props.clipPath && `clip-path: ${props.clipPath};`}

  @media (min-width: 320px) and (max-width: 375px) {
    height: 100%;
  }
`;
