import React from 'react';
import styled from 'styled-components';
import { ContentCardsCarousel } from 'components/organisms/ContentCardsCarousel';
import { Container } from 'components/atoms';

interface Props {
  relatedContent: State.ContentCardItem[] | null;
}

export const RelatedContent: React.FC<Props> = ({ relatedContent }: Props) => {
  if (!relatedContent || !relatedContent.length) return null;

  return (
    <StyledWrapper data-testid="related-content">
      <Container>
        <StyledRelatedContentTitle tabIndex={0}>Related content</StyledRelatedContentTitle>
        <ContentCardsCarousel content={relatedContent} />
      </Container>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.section`
  padding: 3rem 0 4rem;
  border-top: 1px solid ${props => props.theme.colors.neutralGrey2};
  background-color: ${props => props.theme.colors.neutralGrey1};

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 2rem 0 3rem;
  }
`;

const StyledRelatedContentTitle = styled.h4`
  &&& {
    margin: 0 0 1.25rem;
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.l};
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 1.25;

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: 1.625rem;
    }
  }
`;
