import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import download from 'downloadjs';
import { default as request, updateContext } from 'utils/GraphQLClient';
import { Product, B2B, User, MembershipTypes, ZuoraTypes, Salesforce } from 'mxp-schemas';
import { CountriesList, FirmAdmin as FirmAdminUtils } from 'mxp-utils';
import {
  GET_FIRM_ROSTER_MEMBERS,
  GET_ORGANIZATIONS_BY_ACCOUNT_IDS,
  INIT_FIRM_ROSTER_DYNAMO_TABLE,
  UPDATE_FIRM_ROSTER,
  TOGGLE_IS_PAID_BY_FIRM,
  IMPORT_B2B_CLIENT_ADMINS,
  LIST_MEMBERSHIP_INVITE,
  REMOVE_FIRM_ROSTER_MEMBER,
  REMOVE_MEMBERSHIP_INVITE,
  SEND_FIRM_ROSTER_INVITE_REMINDER,
  SEND_MEMBERSHIP_UPGRADE_REMINDER,
  GET_PRODUCTS_BY_TYPE_FLAT_CARDS,
  GET_ADDONS_BY_CONTENTFUL_SKUS,
  SAVE_FIRM_BILLING_QUOTE_ITEMS,
  GENERATE_QUOTE,
  INVALIDATE_QUOTE,
  INIT_QUOTE_GENERATION,
  REMOVE_FIRM_BILLING_QUOTE_ITEMS,
  GET_FIRM_BILLING_INVOICES,
  UPGRADE_FIRM_ROSTER_INVITE,
  GENERATE_INVOICE,
  ORGANIZATION_ADMIN_USERS_BY_ROLE,
  GET_INVOICE_FILE,
  GET_GENERATE_INVOICE_MODAL_INFO,
  APPLY_DISCOUNT_CODE_TO_QUOTE,
  REMOVE_DISCOUNT_CODE_FROM_QUOTE,
  GET_RENEWAL_SEASON_INFO,
  GET_FIRM_ADMIN_ORGANIZATION_DETAILS,
} from 'mxp-graphql-queries';
import { createMatchSelector, getLocation } from 'connected-react-router';
import { cimaFeaturesSelector, membershipsSelector, currencySelector } from 'modules/firmAdmin/selectors';
import {
  paginationSelectorFactory,
  dataSelectorFactory,
  bulkAddonsModalProductsBySkusHashSelector,
  selectedMembersSelectorFactory,
  allMembershipsBySkuMapSelector,
  selectedOrganizationSelector,
  allSubscriptionsBySkuMapSelector,
  slugBySkuSubscriptionsSelector,
} from './selectors';
import { routeByTableType, requestInvoices } from './helpers';
import { emptyArray, getPath } from 'utils';
import { FirmAdminTableType, BulkAddonsModalActionsTypes } from 'constants/firm-admin';
import { FirmAdminActionNames, FirmAdminMembershipTierSKUS } from './constants';
import { Routes } from 'constants/index';
import queryString from 'query-string';
import { userDataSelector } from 'modules/user/selectors';
import { flpPlatformCGMALinkConfigSelector } from 'modules/app/selectors';
import moment from 'moment-timezone';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_CR_723_REMOVE_PRORATION } from 'modules/featureToggle/constants';
import { getFirmMembersPayloadBuilder } from './members/helpers';

// ------------------------------------
// Actions
// ------------------------------------
export const setDataAction: any = createAction(FirmAdminActionNames.SET_DATA);

export const setModifiedHashAction: any = createAction(
  FirmAdminActionNames.SET_MODIFIED_HASH,
  (
      userId: string,
      type: FirmAdmin.FirmAdminTableType,
      modifierInput: Partial<FirmAdmin.FirmMember>,
      skipOriginComparison?: boolean
    ) =>
    (
      dispatch: Dispatch,
      getState: () => State.Root
    ): {
      type: FirmAdmin.FirmAdminTableType;
      userId: string;
      modifier: Partial<FirmAdmin.FirmMember>;
    } => {
      const state: State.Root = getState();
      const data: any = dataSelectorFactory(type)(state);
      const originItem = data.hash[userId];
      const modifier = Object.keys(modifierInput).reduce((acc: any, key: string) => {
        acc[key] =
          !skipOriginComparison && (modifierInput as any)[key] === originItem[key]
            ? undefined
            : (modifierInput as any)[key];
        return acc;
      }, {});
      return { userId, type, modifier };
    }
);

export const setModifiedPositionAction: any = createAction(
  FirmAdminActionNames.SET_MODIFIED_POSITION,
  (userId: string, type: FirmAdmin.FirmAdminTableType, position: string) =>
    (dispatch: Dispatch, getState: () => State.Root): void => {
      const state: State.Root = getState();
      const { modifiedHash }: FirmAdmin.Data<FirmAdmin.FirmMember> = dataSelectorFactory(type)(state);

      const data: any = dataSelectorFactory(type)(state);
      const originPositionValue = data.hash[userId].position;
      const originPositionItem = MembershipTypes.Roles.find(role => role.value === originPositionValue);
      const originTierItem = data.hash[userId].membershipTier;
      const memberships = membershipsSelector(state);

      const isRoleSelection = memberships.find(m => m.slug === data.hash[userId]?.membershipType)?.variants?.[0]
        ?.roleSelection;

      const isAllowedPage =
        type === FirmAdminTableType.MANAGE_FIRM_ROSTER || type === FirmAdminTableType.MEMBERSHIPS_AND_ADDONS;
      const newPositionItem = MembershipTypes.Roles.find(role => role.value === position);
      const fromStaffToPartner =
        originPositionItem?.category === MembershipTypes.RoleCategoryEnum.STAFF &&
        newPositionItem?.category === MembershipTypes.RoleCategoryEnum.PARTNER;
      const fromPartnerToStaff =
        originPositionItem?.category === MembershipTypes.RoleCategoryEnum.PARTNER &&
        newPositionItem?.category === MembershipTypes.RoleCategoryEnum.STAFF;
      const membershipSku =
        isRoleSelection && (fromPartnerToStaff || fromStaffToPartner)
          ? memberships
              .map(m => m.variants)
              .flat()
              .find(
                variant =>
                  variant.sku ===
                  (fromPartnerToStaff ? FirmAdminMembershipTierSKUS.STAFFCORE : FirmAdminMembershipTierSKUS.PARTNERCORE)
              )?.sku
          : originTierItem || undefined;

      if (isAllowedPage && membershipSku && !Boolean(modifiedHash[userId]?.membershipTier)) {
        dispatch(
          setModifiedHashAction(userId, type, {
            position,
            membershipTier: membershipSku,
          })
        );
        return;
      }
      dispatch(
        setModifiedHashAction(userId, type, {
          position,
          ...(Boolean(modifiedHash[userId]?.membershipTier) && {
            membershipTier: modifiedHash[userId]?.membershipTier,
          }),
        })
      );
      return;
    }
);

export const setPaginationAction: any = createAction(FirmAdminActionNames.SET_PAGINATION);

export const resetTableState: any = createAction(FirmAdminActionNames.RESET_TABLE_STATE);

export const getFirmMembers: any = createAction(
  FirmAdminActionNames.GET_FIRM_MEMBERS,
  async (type: FirmAdmin.FirmAdminTableType) =>
    (
      dispatch: Dispatch,
      getState: () => State.Root
    ): Promise<{
      type: FirmAdmin.FirmAdminTableType;
      modifier: Omit<FirmAdmin.Data<FirmAdmin.FirmMember>, 'isFetched' | 'isLoading' | 'selectedIds' | 'modifiedHash'>;
    }> => {
      const state: State.Root = getState();
      const rootOrganizationId: string = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)
        ?.orgId;
      const graphqlPayload = getFirmMembersPayloadBuilder(state, type, rootOrganizationId);

      return request(GET_FIRM_ROSTER_MEMBERS, graphqlPayload).then(async res => {
        if (res?.getFirmRosterMembers?.isSubscriptionsDataLoaded) {
          await dispatch(getFirmAdminOrganizationDetails(rootOrganizationId));
        }

        const useCimaFeatures = cimaFeaturesSelector(state);
        const orgCurrency = currencySelector(state);
        const orgEntity = useCimaFeatures
          ? Salesforce.LegalEntity.CIMA.toLowerCase()
          : Salesforce.LegalEntity.ASSOCIATION.toLocaleLowerCase();

        const organizationEntity = useCimaFeatures
          ? B2B.OrganizationCapabilityType.CIMA.toLowerCase()
          : B2B.OrganizationCapabilityType.AICPA.toLowerCase();

        return {
          type,
          modifier: {
            hash: res.getFirmRosterMembers.result.reduce(
              (acc: FirmAdmin.Hash<FirmAdmin.FirmMember>, item: FirmAdmin.FirmMember) => {
                item.isRemoveFromFirmDisabled = Boolean(
                  item.subscriptions?.some(sub => sub.invoiceBalance && sub.invoiceBalance > 0)
                );
                if (
                  FirmAdminTableType.MANAGE_FIRM_ROSTER === type ||
                  FirmAdminTableType.CIMA_ORGANIZATION_ROSTER === type
                ) {
                  item.isCheckboxDisabled =
                    item.isAdmin ||
                    item.position === MembershipTypes.RolesNames.Partner ||
                    item.position === MembershipTypes.RolesNames.SolePractitioner ||
                    ((Boolean(item.isPaidByFirm) || Boolean(item.isPaidByFlp)) && item.isRemoveFromFirmDisabled);
                  item.roleToDisplay = item.isAdmin
                    ? 'an Admin'
                    : item.position === MembershipTypes.RolesNames.Partner ||
                      item.position === MembershipTypes.RolesNames.SolePractitioner
                    ? 'a Partner'
                    : '';
                }
                if (
                  FirmAdminTableType.MANAGE_FIRM_BILLING === type ||
                  FirmAdminTableType.CIMA_ORGANIZATION_BILLING === type
                ) {
                  item.isUserAddressUS = Boolean(
                    CountriesList.find(country => country.text === item.address.country)?.isAmerica
                  );
                  item.isUserAddressMatchOrg = useCimaFeatures ? !item.isUserAddressUS : item.isUserAddressUS;
                  item.userEntity = item?.accountEntity
                    ? item?.accountEntity?.toLowerCase()
                    : item.isUserAddressUS
                    ? Salesforce.LegalEntity.ASSOCIATION.toLowerCase()
                    : Salesforce.LegalEntity.CIMA.toLowerCase();

                  item.accountCurrency = item.accountCurrency || orgCurrency;
                  item.isCurrencyMatchOrg = orgCurrency === item.accountCurrency;
                  item.isUserEntityMatchOrg = orgEntity === item.userEntity || organizationEntity === item.userEntity;
                  item.isAddToFirmBillingDisabled = Boolean(!item.isPaidByFirm && item.isAddToFirmBillingDisabled);

                  item.isCheckboxDisabled =
                    Boolean(!item.membershipType) ||
                    item.isRemoveFromFirmDisabled ||
                    (!item.isPaidByFirm && !item.isCurrencyMatchOrg) ||
                    (!item.isPaidByFirm && !item.isUserEntityMatchOrg);
                }
                if (FirmAdminTableType.FLP_ORGANIZATION_BILLING === type) {
                  item.isUserAddressUS = Boolean(
                    CountriesList.find(country => country.text === item.address.country)?.isAmerica
                  );
                  item.isUserAddressMatchOrg = useCimaFeatures ? !item.isUserAddressUS : item.isUserAddressUS;
                  item.isCurrencyMatchOrg = orgCurrency === item.accountCurrency;
                  item.isUserEntityMatchOrg = item.accountEntity
                    ? organizationEntity === item.accountEntity?.toLowerCase()
                    : true;
                  item.isAddToFirmBillingDisabled = Boolean(!item.isPaidByFirm && item.isAddToFirmBillingDisabled);
                  item.isAddToFlpFirmBillingDisabled = Boolean(!item.isPaidByFlp && item.isAddToFlpFirmBillingDisabled);
                  item.isCheckboxDisabled =
                    (!item.isPaidByFlp && Boolean(!item.flpSubscriptionType)) ||
                    item.isRemoveFromFirmDisabled ||
                    (!item.isPaidByFlp && !item.isCurrencyMatchOrg) ||
                    (!item.isPaidByFlp && !item.isUserEntityMatchOrg);
                }
                if (FirmAdminTableType.MEMBERSHIPS_AND_ADDONS === type) {
                  item.isCheckboxDisabled = Boolean(FirmAdminUtils.isQuoteWithHiddenItems(item.quoteItems));
                }

                if (
                  type === FirmAdminTableType.MEMBERSHIPS_AND_ADDONS ||
                  type === FirmAdminTableType.FLP_ORGANIZATION_ADDONS
                ) {
                  const firmNotInvoiceOwner = item?.subscriptions?.find(
                    sub => sub?.subscriptionInvoiceOwnerId !== rootOrganizationId && sub?.membershipType
                  );                  

                  item.isDisabledAddOns =
                    Boolean(firmNotInvoiceOwner) ||
                    !item?.oktaId ||
                    item?.oktaStatus === User.OktaMembersStatus.INACTIVE ||
                    item?.oktaStatus === User.OktaMembersStatus.PROVISIONED ||
                    (Boolean(item?.zuoraAccountNumber) && item?.oktaId !== item?.zuoraAccountNumber);

                  item.isCheckboxDisabled = item.isDisabledAddOns;
                  if (
                    item?.oktaStatus === User.OktaMembersStatus.INACTIVE ||
                    item?.oktaStatus === User.OktaMembersStatus.PROVISIONED
                  ) {
                    item.disabledAddOnsMessage = `This member has ${item?.oktaStatus} status in Okta`;
                  }

                  if (!item.oktaId) {
                    item.disabledAddOnsMessage = `This member has no Okta ID in Salesforce`;
                  }
                  if (Boolean(firmNotInvoiceOwner)) {
                    item.disabledAddOnsMessage = `This member Subscription ${firmNotInvoiceOwner?.subscriptionNumber} invoice owner is not Firm`;
                  }
                  if (Boolean(item?.zuoraAccountNumber) && item?.oktaId !== item?.zuoraAccountNumber) {
                    item.disabledAddOnsMessage = `This member OktaId in SF ${item?.oktaId} is not equals to Zuora account Number ${item?.zuoraAccountNumber} `;
                  }

                  if (item.quoteStatus === User.FirmBillingQuoteStatus.ERROR) {
                    item.isCheckboxDisabled = true;
                    item.isDisabledAddOns = true;
                    item.disabledAddOnsMessage = `There has been an error processing the invoice. Contact support.`;
                  }
                }

                acc[item.userId] = item;
                return acc;
              },
              {}
            ),
            count: res.getFirmRosterMembers.totalCount,
            isPaidByFirmCount: res.getFirmRosterMembers.isPaidByFirmCount,
            isPaidByFlpCount: res.getFirmRosterMembers.isPaidByFlpCount,
            isInFirmRosterCount: res.getFirmRosterMembers.isInFirmRosterCount,
            flpCount: res.getFirmRosterMembers.FLPCount,
            includedTotal: res.getFirmRosterMembers.includedTotal,
            notIncludedTotal: res.getFirmRosterMembers.notIncludedTotal,
            isDataLoaded: res.getFirmRosterMembers.isDataLoaded,
            quotesStatus: res.getFirmRosterMembers.quotesStatus,
            isAllSubscriptionsFetched:
              (!res.getFirmRosterMembers.result.length && res.getFirmRosterMembers.isDataLoaded) ||
              res.getFirmRosterMembers.result?.every((item: FirmAdmin.FirmMember) =>
                Boolean(item.isSubscriptionsFetched)
              ),
            quotesLength: res.getFirmRosterMembers.quotesLength,
            isSubscriptionsDataLoaded: res.getFirmRosterMembers.isSubscriptionsDataLoaded,
            flpQuotesStatus: res.getFirmRosterMembers.flpQuotesStatus,
            flpQuotesLength: res.getFirmRosterMembers.flpQuotesLength,
            quoteOrInvoiceInProgressStatus: [
              User.FirmBillingQuoteStatus.QUOTE_IN_PROGRESS,
              User.FirmBillingQuoteStatus.INVOICE_IN_PROGRESS,
            ].includes(res.getFirmRosterMembers.quotesStatus)
              ? res.getFirmRosterMembers.quotesStatus
              : null,
          },
        };
      });
    }
);

export const generateQuote: any = createAction(
  FirmAdminActionNames.GENERATE_QUOTE,
  async (userIds: string[], type: FirmAdmin.FirmAdminTableType) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<FirmAdmin.FirmAdminTableType> => {
      const state: State.Root = getState();
      const rootOrganizationId = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)?.orgId;
      const useRemoveProration = getFeatureToggleByKeySelector(state as State.Root, USE_CR_723_REMOVE_PRORATION);

      return request(GENERATE_QUOTE, { userIds, rootOrganizationId, useRemoveProration }).then(async response => {
        dispatch(setDataAction({ type, modifier: { selectedIds: userIds } }));
        await dispatch(getFirmMembers(type));
        return response;
      });
    }
);

export const invalidateQuote: any = createAction(
  FirmAdminActionNames.INVALIDATE_QUOTE,
  async (type: FirmAdmin.FirmAdminTableType) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<FirmAdmin.FirmAdminTableType> => {
      const state: State.Root = getState();
      const rootOrganizationId = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)?.orgId;

      return request(INVALIDATE_QUOTE, { rootOrganizationId }).then(async res => {
        await dispatch(getFirmMembers(type));
        return res;
      });
    }
);

export const applyDiscountCodeToQuote: any = createAction(
  FirmAdminActionNames.APPLY_DISCOUNT_CODE_TO_QUOTE,
  async (type: FirmAdmin.FirmAdminTableType, promoCode: string) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<FirmAdmin.FirmAdminTableType> => {
      const state: State.Root = getState();
      const rootOrganizationId = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)?.orgId;

      return request(APPLY_DISCOUNT_CODE_TO_QUOTE, { rootOrganizationId, promoCode });
    }
);

export const removeDiscountCodeFromQuote: any = createAction(
  FirmAdminActionNames.REMOVE_DISCOUNT_CODE_FROM_QUOTE,
  async (type: FirmAdmin.FirmAdminTableType, promoId: string) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<FirmAdmin.FirmAdminTableType> => {
      const state: State.Root = getState();
      const rootOrganizationId = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)?.orgId;

      return request(REMOVE_DISCOUNT_CODE_FROM_QUOTE, { rootOrganizationId, promoId });
    }
);

export const initQuoteGeneration: any = createAction(
  FirmAdminActionNames.INIT_QUOTE_GENERATION,
  async (userIds: string[], type: FirmAdmin.FirmAdminTableType) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<FirmAdmin.FirmAdminTableType> => {
      const state: State.Root = getState();
      const rootOrganizationId = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)?.orgId;

      return request(INIT_QUOTE_GENERATION, { userIds, rootOrganizationId });
    }
);

export const getGenerateInvoiceModalInfo: any = createAction(
  FirmAdminActionNames.GET_GENERATE_INVOICE_MODAL_INFO,
  async (type: FirmAdmin.FirmAdminTableType) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<User.GenerateInvoiceModalInfo> => {
      const state: State.Root = getState();
      const rootOrganizationId = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)?.orgId;
      return request(GET_GENERATE_INVOICE_MODAL_INFO, { rootOrganizationId });
    }
);

export const getOrganizations: any = createAction(
  FirmAdminActionNames.GET_ORGANIZATIONS,
  async () =>
    (): Promise<{
      organizations: FirmAdmin.Organization[] | any[];
    }> => {
      const createDataTree = (organizationList: FirmAdmin.Organization[]) => {
        const organizationIdMap = organizationList.reduce<{ [key: string]: FirmAdmin.Organization }>(
          (acc, organization) => {
            acc[organization.id] = { ...organization, branches: [] };
            return acc;
          },
          {}
        );
        const dataTree: FirmAdmin.Organization[] = [];
        organizationList.forEach((organization: FirmAdmin.Organization) => {
          if (organization.currentParentId) {
            organizationIdMap[organization.currentParentId].branches?.push(organizationIdMap[organization.id]);
          } else dataTree.push(organizationIdMap[organization.id]);
        });
        return dataTree as FirmAdmin.Organization[];
      };

      return request(GET_ORGANIZATIONS_BY_ACCOUNT_IDS).then(e => ({
        organizations: createDataTree(e?.organizations),
      }));
    }
);

export const getFirmAdminOrganizationDetails: any = createAction(
  FirmAdminActionNames.GET_FIRM_ADMIN_ORGANIZATION_DETAILS,
  async (orgId: string) => (): Promise<User.FirmAdminTypes> => {
    return request(GET_FIRM_ADMIN_ORGANIZATION_DETAILS, { orgId });
  }
);

export const getOrganizationAdminsByRole: any = createAction(
  FirmAdminActionNames.GET_ORGANIZATION_ADMINS_BY_ROLE,
  async () =>
    (
      _: Dispatch,
      getState: () => State.Root
    ): Promise<{
      organizationAdmins: FirmAdmin.Contact[] | any;
    }> => {
      const state = getState();
      const id: string = (createMatchSelector(getPath(Routes.FIRM_ROOT))(state)?.params as any)?.orgId;
      const roles = [B2B.AgentRole.CLIENT_ADMIN, B2B.AgentRole.FIRM_BILLING, B2B.AgentRole.CENTERS_MEMBERSHIP];
      return Promise.all(
        roles.map(role =>
          request(ORGANIZATION_ADMIN_USERS_BY_ROLE, { id, role }).then(e => e?.organizationAdminUsersByRole)
        )
      ).then(admins => ({ organizationAdmins: admins }));
    }
);

export const initFirmRosterDynamoTable: any = createAction(
  FirmAdminActionNames.INIT_FIRM_ROSTER_DYNAMO_TABLE,
  async (orgId: string, isRefreshRequested?: boolean) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<void> => {
      return request(INIT_FIRM_ROSTER_DYNAMO_TABLE, { orgId, isRefreshRequested });
    }
);

export const saveTableChanges: any = createAction(
  FirmAdminActionNames.SAVE_TABLE_CHANGES,
  async (type: FirmAdmin.FirmAdminTableType) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<FirmAdmin.FirmAdminTableType> => {
      const state: State.Root = getState();
      const rootOrganizationId: string = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)
        ?.orgId;
      const { filters }: FirmAdmin.Pagination = paginationSelectorFactory(type)(state);

      const isFlpAddonsTable = type === FirmAdminTableType.FLP_ORGANIZATION_ADDONS;
      const isFlpAddSubscriptionTable = type === FirmAdminTableType.FLP_ADD_SUBSCRIPTION;
      const isFlpUpgradeSubscriptionTable = type === FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION;
      const isMembershipAddonsTable = type === FirmAdminTableType.MEMBERSHIPS_AND_ADDONS;
      const isPaidByFlp: boolean = Boolean(
        (isFlpAddSubscriptionTable || isFlpUpgradeSubscriptionTable || isFlpAddonsTable) && filters?.isPaidByFlp
      );
      const flpPlatformCGMALink = flpPlatformCGMALinkConfigSelector(state);
      const { modifiedHash }: FirmAdmin.Data<any> = dataSelectorFactory(type)(state);
      const { userList, quoteTiers } = Object.keys(modifiedHash)?.reduce(
        (
          acc: {
            userList: FirmAdmin.UpdateFirmRosterMemberInput[];
            quoteTiers: Array<{ userId: string; membershipTier: string }>;
          },
          userId: string
        ) => {
          const {
            email,
            organizationId,
            position,
            membershipTier,
            branchCity,
            branchCountry,
            flpSubscriptionType,
            flpSubscriptionDuration,
          } = modifiedHash[userId];
          if (
            email ||
            organizationId ||
            position ||
            branchCity ||
            branchCountry ||
            flpSubscriptionType ||
            flpSubscriptionDuration ||
            membershipTier
          ) {
            acc.userList.push({
              userId,
              email,
              organizationId,
              position,
              branchCity,
              branchCountry,
              flpSubscriptionType,
              flpSubscriptionDuration,
              membershipTier,
              isPaidByFlp,
              flpPlatformCGMALink: isFlpUpgradeSubscriptionTable ? flpPlatformCGMALink : '',
              isFlpUpgrade: membershipTier && isFlpUpgradeSubscriptionTable,
            });
          }
          if (membershipTier || (membershipTier && (flpSubscriptionDuration || flpSubscriptionType))) {
            acc.quoteTiers.push({ userId, membershipTier });
          }
          return acc;
        },
        { userList: [], quoteTiers: [] }
      );
      let modifiableUserList = userList;

      const saveFirmBillingQuoteItemsPromise = (() => {
        if (!quoteTiers.length) return Promise.resolve();
        const { hash }: FirmAdmin.Data<FirmAdmin.FirmMember> = isFlpUpgradeSubscriptionTable
          ? // if flpUpgradeSubscription, combine hash with modified hash to allow multiple upgrades from diff pages
            {
              ...dataSelectorFactory(type)(state),
              hash: { ...dataSelectorFactory(type)(state).hash, ...modifiedHash },
            }
          : dataSelectorFactory(type)(state);

        const allMembershipsBySku =
          isFlpAddonsTable || isFlpUpgradeSubscriptionTable
            ? allSubscriptionsBySkuMapSelector(state)
            : allMembershipsBySkuMapSelector(state);
        const currency = currencySelector(state);
        const quotes = quoteTiers.map(item => {
          const membershipSubscription = hash[item.userId].subscriptions?.find(sub => sub.membershipType);
          const { updatedQuoteItems, didRevert } = hash[item.userId].quoteItems?.reduce(
            (acc: { didRevert: boolean; updatedQuoteItems: FirmAdmin.QuoteItem[] }, i: FirmAdmin.QuoteItem) => {
              const isReverting = Boolean(i.variantSku === item.membershipTier && i.isMembership && i.newVariantSku);
              const quoteItem = {
                ...i,
                newVariantSku: i.isMembership && !isReverting ? item.membershipTier : undefined,
                newCentPrice:
                  i.isMembership && !isReverting ? allMembershipsBySku[item.membershipTier].centPrice : undefined,
                options: (() => {
                  if (isFlpAddonsTable) return undefined;
                  if (!i.isMembership || (isMembershipAddonsTable && i.isMembership)) return i.options;
                  if (
                    Boolean(modifiedHash[item.userId]?.position) ||
                    (Boolean(modifiedHash[item.userId]?.flpSubscriptionDuration) &&
                      isFlpUpgradeSubscriptionTable &&
                      !isReverting)
                  ) {
                    return User.FirmBillingQuoteItemOptions.NOT_RESETTABLE;
                  }

                  return isReverting
                    ? isFlpUpgradeSubscriptionTable
                      ? User.FirmBillingQuoteItemOptions.RESETTABLE
                      : undefined
                    : User.FirmBillingQuoteItemOptions.RESETTABLE;
                })(),
                ...((isFlpAddonsTable || isFlpUpgradeSubscriptionTable) && {
                  // if flp, update description to new, but save copy of old description for reset
                  description: allMembershipsBySku[item.membershipTier].description,
                  oldDescription: allMembershipsBySku[i.variantSku].description,
                }),
                ...(isFlpAddonsTable && {
                  isUpdateRenewal: true,
                }),
              };
              if (isReverting && isFlpUpgradeSubscriptionTable) {
                modifiableUserList = modifiableUserList.map(user => {
                  return { ...user, isFlpUpgrade: !(user.userId === item.userId) };
                });
              }
              if (isReverting && i.options === User.FirmBillingQuoteItemOptions.RESETTABLE) {
                acc.didRevert = true;
                return acc;
              }

              acc.updatedQuoteItems.push(quoteItem);
              return acc;
            },
            { didRevert: false, updatedQuoteItems: [] as FirmAdmin.QuoteItem[] }
          ) || { updatedQuoteItems: [], didRevert: false };
          return {
            userId: item.userId,
            oktaId: hash[item.userId].oktaId,
            rootOrganizationId: hash[item.userId].rootOrganizationId,
            organizationId: hash[item.userId].organizationId,
            membershipEndDate: hash[item.userId].duration
              ? moment(hash[item.userId].expiryDate).add(hash[item.userId].duration, 'M')
              : hash[item.userId].expiryDate,
            quoteItems: (() => {
              if (
                (!updatedQuoteItems?.length || !updatedQuoteItems.some(quoteItem => quoteItem.isMembership)) &&
                (membershipSubscription || isFlpAddonsTable) &&
                !didRevert
              ) {
                return [
                  ...updatedQuoteItems,
                  {
                    ...allMembershipsBySku[item.membershipTier],
                    subscriptionNumber: membershipSubscription?.subscriptionNumber,
                    variantSku: isFlpAddonsTable ? item.membershipTier : membershipSubscription?.sku,
                    options: Boolean(modifiedHash[item.userId]?.position)
                      ? User.FirmBillingQuoteItemOptions.NOT_RESETTABLE
                      : User.FirmBillingQuoteItemOptions.RESETTABLE,
                    ratePlanId: membershipSubscription?.ratePlanId,
                    renewalDate: membershipSubscription?.renewalDate,
                    subscriptionStartDate: membershipSubscription?.subscriptionStartDate,
                  },
                ];
              }
              return updatedQuoteItems;
            })(),
            aicpaId: hash[item.userId].aicpaId,
            isPaidByFlp: Boolean(filters?.isPaidByFlp),
            currency,
          };
        });

        return request(SAVE_FIRM_BILLING_QUOTE_ITEMS, { quotes });
      })();

      const updateFirmRosterPromise = modifiableUserList.length
        ? request(UPDATE_FIRM_ROSTER, {
            rootOrganizationId,
            userList: modifiableUserList,
          })
        : Promise.resolve();

      return Promise.all([updateFirmRosterPromise, saveFirmBillingQuoteItemsPromise]).then(async () => {
        await dispatch(getFirmMembers(type));
        return type;
      });
    }
);

export const upgradeFirmRosterEmployeeInvites: any = createAction(
  FirmAdminActionNames.UPGRADE_FIRM_ROSTER_INVITE,
  (records: FirmAdmin.UpgradeFirmRosterInviteInput[]) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const rootOrganizationId: string = (
      createMatchSelector(getPath(routeByTableType(FirmAdminTableType.MEMBERS_INVITES)))(state)?.params as any
    )?.orgId;
    return request(UPGRADE_FIRM_ROSTER_INVITE, { rootOrganizationId, records }).then(() =>
      dispatch(getFirmMembersInvites(FirmAdminTableType.MEMBERS_INVITES))
    );
  }
);

export const toggleIsPaidByFirm: any = createAction(
  FirmAdminActionNames.TOGGLE_IS_PAID_BY_FIRM,
  async (type: FirmAdmin.FirmAdminTableType) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<FirmAdmin.FirmAdminTableType> => {
      const state: State.Root = getState();
      const { selectedIds }: FirmAdmin.Data<FirmAdmin.FirmMember> = dataSelectorFactory(type)(state);
      const { filters }: FirmAdmin.Pagination = paginationSelectorFactory(type)(state);
      const rootOrganizationId: string = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)
        ?.orgId;
      const organizationName = selectedOrganizationSelector(state)?.name;
      const admin = userDataSelector(state);
      const adminName = `${admin?.firstName} ${admin?.lastName}`;
      const isPaidByFirm = type === FirmAdminTableType.FLP_ORGANIZATION_BILLING ? undefined : !filters?.isPaidByFirm;
      const isPaidByFlp = type === FirmAdminTableType.FLP_ORGANIZATION_BILLING ? !filters?.isPaidByFlp : undefined;

      return request(TOGGLE_IS_PAID_BY_FIRM, {
        userIds: selectedIds,
        isPaidByFirm,
        rootOrganizationId,
        isPaidByFlp,
        organizationName,
        adminName,
      }).then(async () => {
        await dispatch(getFirmMembers(type));
        return type;
      });
    }
);

export const getFirmMembersInvites: any = createAction(
  FirmAdminActionNames.GET_FIRM_MEMBERS_INVITES,
  async (type: FirmAdmin.FirmAdminTableType) =>
    (
      dispatch: Dispatch,
      getState: () => State.Root
    ): Promise<{
      type: FirmAdmin.FirmAdminTableType;
      modifier: Omit<FirmAdmin.Data<FirmAdmin.Invite>, 'isFetched' | 'isLoading' | 'selectedIds' | 'modifiedHash'>;
    }> => {
      const state: State.Root = getState();
      const organizationId: string = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)
        ?.orgId;
      const { limit, offset, query, filters }: FirmAdmin.Pagination = paginationSelectorFactory(type)(state);

      return request(LIST_MEMBERSHIP_INVITE, {
        accountId: organizationId,
        offset,
        limit,
        searchText: query,
        filters,
        isFLP: type === FirmAdminTableType.FLP_ADD_SUBSCRIPTION,
      }).then(res => ({
        type,
        modifier: {
          hash: res.listMembershipInvites.result.reduce(
            (acc: FirmAdmin.Hash<FirmAdmin.Invite>, item: FirmAdmin.Invite) => {
              acc[item.inviteId] = item;
              return acc;
            },
            {}
          ),
          count: res.listMembershipInvites.totalCount,
        },
      }));
    }
);

export const setIsLoading: any = createAction(FirmAdminActionNames.SET_IS_LOADING);

export const getFirmBillingInvoices: any = createAction(
  FirmAdminActionNames.GET_FIRM_BILLING_INVOICES,
  async () =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<any> => {
      const state: State.Root = getState();
      const useCimaFeatures = cimaFeaturesSelector(state);
      const typeForOrgId = FirmAdminTableType.MANAGE_FIRM_BILLING;
      const type = useCimaFeatures
        ? FirmAdminTableType.FIRM_BILLING_INVOICES_CIMA
        : FirmAdminTableType.FIRM_BILLING_INVOICES;
      const dataSelector = dataSelectorFactory(type);
      const { hash, quotesStatus, isLoading } = dataSelector(state);
      const rootOrganizationId: string = (
        createMatchSelector(getPath(routeByTableType(typeForOrgId)))(state)?.params as any
      )?.orgId;
      const { filters }: FirmAdmin.Pagination = paginationSelectorFactory(type)(state);
      const legalEntity = cimaFeaturesSelector(state)
        ? ZuoraTypes.LegalEntity.CIMA
        : ZuoraTypes.LegalEntity.ASSOCIATION;
      const currency = currencySelector(state);

      await updateContext({
        existingEntity: legalEntity,
        currency: {
          label: currency,
        },
      });

      const newQuoteStatusProp = quotesStatus;

      const { returnHash, newQuoteStatus } = await requestInvoices({
        tableType: typeForOrgId,
        rootOrganizationId,
        setIsLoading,
        gql: GET_FIRM_BILLING_INVOICES,
        isPaidInvoice: filters.isPaidInvoice,
        isPaidInvoiceFetchedData: filters.isPaidInvoiceFetchedData,
        defaultHash: hash,
        quoteStatus: newQuoteStatusProp,
        dispatch,
        isLoading,
        type,
        request,
      });
      const returnObject = {
        type,
        modifier: {
          hash: returnHash,
          count: Object.keys(returnHash).length,
          quotesStatus: newQuoteStatus,
        },
      };
      return returnObject;
    }
);

export const getCSVData: any = createAction(
  FirmAdminActionNames.GET_CSV_DATA,
  async (type: FirmAdmin.FirmAdminTableType) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<Array<{ [key: string]: string | boolean | number }>> => {
      const state: State.Root = getState();
      const rootOrganizationId: string = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)
        ?.orgId;

      let payload = getFirmMembersPayloadBuilder(state, type, rootOrganizationId);

      payload = { ...payload, limit: 0 };

      return request(GET_FIRM_ROSTER_MEMBERS, payload).then(res => res.getFirmRosterMembers.result);
    }
);

export const uploadFirmRosterClientAdminsInvites: any = createAction(
  FirmAdminActionNames.IMPORT_B2B_CLIENT_ADMINS,
  (
      adminDataRows: State.FirmRosterEmployeeInvite[],
      csv: File,
      type: FirmAdmin.FirmAdminTableType,
      isFLP: boolean,
      subscriptions?: Product.SimplifiedMembership[],
      organizationType?: MembershipTypes.OrganizationType
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const organizationId: string = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)
        ?.orgId;
      const isCima = cimaFeaturesSelector(state);
      return request(IMPORT_B2B_CLIENT_ADMINS, {
        organizationId,
        adminDataRows,
        csv,
        isFLP,
        subscriptions,
        isCima,
        organizationType,
      }).then(() => dispatch(getFirmMembersInvites(type)));
    }
);

export const removeFirmRosterMember: any = createAction(
  FirmAdminActionNames.REMOVE_FIRM_ROSTER_MEMBER,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const isCima = cimaFeaturesSelector(state);
    const type = isCima ? FirmAdminTableType.CIMA_ORGANIZATION_ROSTER : FirmAdminTableType.MANAGE_FIRM_ROSTER;
    const organizationId: string = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)?.orgId;
    const organizationName = selectedOrganizationSelector(state)?.name;
    const { selectedIds }: FirmAdmin.Data<FirmAdmin.FirmMember> = dataSelectorFactory(type)(state);

    return request(REMOVE_FIRM_ROSTER_MEMBER, { organizationId, userIds: selectedIds, organizationName }).then(
      async () => {
        await dispatch(getFirmMembers(type));
        return type;
      }
    );
  }
);

export const removeMembershipInvite: any = createAction(
  FirmAdminActionNames.REMOVE_MEMBERSHIP_INVITE,
  (type: FirmAdminTableType) =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<FirmAdminTableType> => {
      const state: State.Root = getState();
      const organizationId: string = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)
        ?.orgId;
      const { selectedIds }: FirmAdmin.Data<FirmAdmin.FirmRosterEmployeeInvite> = dataSelectorFactory(type)(state);

      return request(REMOVE_MEMBERSHIP_INVITE, { organizationId, inviteIds: selectedIds }).then(async () => {
        await dispatch(getFirmMembersInvites(type));
        return type;
      });
    }
);

export const sendFirmRosterInviteReminder: any = createAction(
  FirmAdminActionNames.SEND_FIRM_ROSTER_INVITE_REMINDER,
  (type: FirmAdminTableType) =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<FirmAdminTableType> => {
      const state: State.Root = getState();
      const rootOrganizationId: string = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)
        ?.orgId;
      const { selectedIds }: FirmAdmin.Data<FirmAdmin.FirmRosterEmployeeInvite> = dataSelectorFactory(type)(state);

      return request(SEND_FIRM_ROSTER_INVITE_REMINDER, { rootOrganizationId, inviteIds: selectedIds }).then(
        async () => {
          await dispatch(getFirmMembersInvites(type));
          return type;
        }
      );
    }
);

export const sendMembershipUpgradeReminder: any = createAction(
  FirmAdminActionNames.SEND_MEMBERSHIP_UPGRADE_REMINDER,
  (type: FirmAdminTableType) =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<FirmAdminTableType> => {
      const state: State.Root = getState();
      const rootOrganizationId: string = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)
        ?.orgId;
      const { selectedIds }: FirmAdmin.Data<FirmAdmin.FirmRosterEmployeeInvite> = dataSelectorFactory(type)(state);
      return request(SEND_MEMBERSHIP_UPGRADE_REMINDER, { rootOrganizationId, inviteIds: selectedIds }).then(
        async () => {
          await dispatch(getFirmMembersInvites(type));
          return type;
        }
      );
    }
);

export const fetchAddons: any = createAction(
  FirmAdminActionNames.GET_MEMBERSHIP_RELATED_ADDONS,
  async () => async () => {
    const { getAddonsByContentfulSkus } = await request(GET_ADDONS_BY_CONTENTFUL_SKUS);
    return getAddonsByContentfulSkus || emptyArray;
  }
);

export const fetchSections: any = createAction(FirmAdminActionNames.GET_MEMBERSHIP_SECTIONS, async () => async () => {
  const { getProductsByType } = await request(GET_PRODUCTS_BY_TYPE_FLAT_CARDS, {
    type: Product.ProductType.SECTION,
  });
  return getProductsByType || emptyArray;
});

export const fetchCredentials: any = createAction(
  FirmAdminActionNames.GET_MEMBERSHIP_CREDENTIALS,
  async () => async () => {
    const { getProductsByType } = await request(GET_PRODUCTS_BY_TYPE_FLAT_CARDS, {
      type: Product.ProductType.CREDENTIAL,
    });
    return getProductsByType || emptyArray;
  }
);

export const saveFirmBillingQuoteItems: any = createAction(
  FirmAdminActionNames.SAVE_FIRM_BILLING_QUOTE_ITEMS,
  (member: FirmAdmin.FirmMember, quoteItems: FirmAdmin.QuoteItem[]) =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      const selectedOrganization: FirmAdmin.Organization | null = selectedOrganizationSelector(state);
      const quotes: Array<Omit<FirmAdmin.FirmBillingQuote, 'status' | 'dateCreated'>> = [
        {
          userId: member.userId,
          oktaId: member.oktaId,
          rootOrganizationId: member.rootOrganizationId,
          organizationId: member.organizationId,
          quoteItems,
          membershipEndDate: member.expiryDate || undefined,
          aicpaId: member.aicpaId,
          currency: selectedOrganization?.currency || Product.ProductCurrencyLabel.USD,
        },
      ];
      return request(SAVE_FIRM_BILLING_QUOTE_ITEMS, { quotes }).then(async () => {
        await dispatch(getFirmMembers(FirmAdminTableType.MEMBERSHIPS_AND_ADDONS));
      });
    }
);

export const saveFirmBillingBulkModalQuoteItems: any = createAction(
  FirmAdminActionNames.SAVE_FIRM_BILLING_BULK_MODAL_QUOTE_ITEMS,
  (actions: FirmAdmin.BulkAddonsModalActions) =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      const productsBySkusHash: {
        [key: string]: FirmAdmin.TransformedProduct;
      } = bulkAddonsModalProductsBySkusHashSelector(state);
      const selectedMembers = selectedMembersSelectorFactory<FirmAdmin.FirmMember>(
        FirmAdminTableType.MEMBERSHIPS_AND_ADDONS
      )(state);
      const selectedOrganization: FirmAdmin.Organization | null = selectedOrganizationSelector(state);

      const getSubscriptionQuote = ({
        acc,
        member,
        product,
        subscription,
        isIncludedInFirmBilling,
      }: {
        acc: { [key: string]: Omit<FirmAdmin.FirmBillingQuote, 'status' | 'dateCreated'> };
        member: FirmAdmin.FirmMember;
        product: FirmAdmin.TransformedProduct;
        subscription: FirmAdmin.FirmAdminSubscription;
        isIncludedInFirmBilling: boolean;
      }) => ({
        ...acc[member.userId],
        userId: member.userId,
        oktaId: member.oktaId,
        aicpaId: member.aicpaId,
        rootOrganizationId: member.rootOrganizationId,
        organizationId: member.organizationId,
        currency: selectedOrganization?.currency || Product.ProductCurrencyLabel.USD,
        quoteItems: [
          // Remove if already exists
          ...acc[member.userId]?.quoteItems.filter(
            (quote: FirmAdmin.QuoteItem) => quote.variantSku !== subscription.sku
          ),
          {
            productId: product.productId,
            variantSku: subscription.sku,
            centPrice: product.variants[0].prices?.find(
              vPrice => vPrice?.currency === (selectedOrganization?.currency || Product.ProductCurrencyLabel.USD)
            )?.amount,
            channelId: product.variants[0].prices?.find(
              vPrice => vPrice?.currency === (selectedOrganization?.currency || Product.ProductCurrencyLabel.USD)
            )?.channel?.id,
            subscriptionNumber: subscription.subscriptionNumber,
            renewalDate: subscription.renewalDate,
            subscriptionStartDate: subscription.subscriptionStartDate,
            isIncludedInFirmBilling,
            action: User.QuoteItemActions.OWNER,
            isMembership: Boolean(productsBySkusHash[subscription.sku].productType === Product.ProductType.MEMBERSHIP),
          },
        ],
      });

      const quotesHash: {
        [key: string]: Omit<FirmAdmin.FirmBillingQuote, 'status' | 'dateCreated'>;
      } = selectedMembers.reduce((acc: any, member: FirmAdmin.FirmMember) => {
        acc[member.userId] = { quoteItems: member.quoteItems || emptyArray };

        // 1. AddProduct - action
        const actionAddProductSkus = actions[BulkAddonsModalActionsTypes.AddProduct];
        const addedSkusMemo: string[] = [];

        // 1.1 Exclude invoiced subscriptions
        member.subscriptions?.forEach(subscription => {
          if (actionAddProductSkus.includes(subscription.sku)) addedSkusMemo.push(subscription.sku);
        });

        // 1.2 Exclude sku if already added
        member.quoteItems?.forEach(quoteItem => {
          if (actionAddProductSkus.includes(quoteItem.variantSku)) addedSkusMemo.push(quoteItem.variantSku);
        });

        // 1.3 Add rest skus
        const restSkusToAdd = actionAddProductSkus.filter((sku: string) => !addedSkusMemo.includes(sku));
        if (restSkusToAdd.length) {
          acc[member.userId] = {
            ...acc[member.userId],
            userId: member.userId,
            oktaId: member.oktaId,
            aicpaId: member.aicpaId,
            rootOrganizationId: member.rootOrganizationId,
            organizationId: member.organizationId,
            currency: selectedOrganization?.currency || Product.ProductCurrencyLabel.USD,
            quoteItems: [
              ...acc[member.userId]?.quoteItems,
              ...restSkusToAdd.map((sku: string) => ({
                productId: productsBySkusHash[sku].productId,
                variantSku: sku,
                centPrice: productsBySkusHash[sku].variants[0].prices?.find(
                  vPrice => vPrice?.currency === (selectedOrganization?.currency || Product.ProductCurrencyLabel.USD)
                )?.amount,
                channelId: productsBySkusHash[sku].variants[0].prices?.find(
                  vPrice => vPrice?.currency === (selectedOrganization?.currency || Product.ProductCurrencyLabel.USD)
                )?.channel?.id,
                subscriptionNumber: null,
                renewalDate: null,
                isIncludedInFirmBilling: true,
                addonType: Boolean(
                  productsBySkusHash[sku].productType !== Product.ProductType.SECTION &&
                    productsBySkusHash[sku].productType !== Product.ProductType.CREDENTIAL
                )
                  ? User.AddonType.ADD_ON
                  : null,
                isMembership: Boolean(productsBySkusHash[sku].productType === Product.ProductType.MEMBERSHIP),
                action: User.QuoteItemActions.QUOTE,
              })),
            ],
          };
        }

        // 2. RenewProduct - action
        const actionRenewProductSkus = actions[BulkAddonsModalActionsTypes.RenewProduct];
        const renewedSkusMemo: string[] = [];

        // 2.1 Exclude sku if already added
        member.quoteItems?.forEach(quoteItem => {
          if (actionRenewProductSkus.includes(quoteItem.variantSku)) renewedSkusMemo.push(quoteItem.variantSku);
        });

        if (renewedSkusMemo.length > 0) {
          acc[member.userId].quoteItems = member.quoteItems?.filter(
            quoteItem => !renewedSkusMemo.includes(quoteItem.variantSku)
          );
        }

        // 2.2 Change subscriptions owner
        member.subscriptions?.forEach(subscription => {
          if (
            actionRenewProductSkus.includes(subscription.sku) &&
            subscription.subscriptionAccountId === subscription.subscriptionInvoiceOwnerId &&
            [null, 0].includes(subscription.invoiceBalance)
          ) {
            acc[member.userId] = getSubscriptionQuote({
              acc,
              member,
              product: productsBySkusHash[subscription.sku],
              subscription,
              isIncludedInFirmBilling: true,
            });
          }
        });

        // 3. Remove products - action
        const removedSkusMemo: string[] = [];
        const actionRemoveProductSkus = actions[BulkAddonsModalActionsTypes.RemoveProduct];

        // 3.1 Change subscriptions owner and remember used sku
        member.subscriptions?.forEach(subscription => {
          if (
            actionRemoveProductSkus.includes(subscription.sku) &&
            subscription.subscriptionAccountId !== subscription.subscriptionInvoiceOwnerId &&
            [null, 0].includes(subscription.invoiceBalance)
          ) {
            acc[member.userId] = getSubscriptionQuote({
              acc,
              member,
              product: productsBySkusHash[subscription.sku],
              subscription,
              isIncludedInFirmBilling: false,
            });
            removedSkusMemo.push(subscription.sku);
          }
        });

        // 3.2 Remove rest skus
        const restSkusToRemove = actionRemoveProductSkus.filter((sku: string) => !removedSkusMemo.includes(sku));
        const quoteItems = acc[member.userId].quoteItems.filter(
          (quote: FirmAdmin.QuoteItem) => !restSkusToRemove.includes(quote.variantSku)
        );

        acc[member.userId] = {
          ...acc[member.userId],
          userId: member.userId,
          oktaId: member.oktaId,
          aicpaId: member.aicpaId,
          rootOrganizationId: member.rootOrganizationId,
          organizationId: member.organizationId,
          membershipEndDate: member.expiryDate,
          quoteItems,
          currency: selectedOrganization?.currency || Product.ProductCurrencyLabel.USD,
        };

        return acc;
      }, {});

      return request(SAVE_FIRM_BILLING_QUOTE_ITEMS, { quotes: Object.values(quotesHash) }).then(async () => {
        await dispatch(getFirmMembers(FirmAdminTableType.MEMBERSHIPS_AND_ADDONS));
      });
    }
);

export const removeFirmBillingQuoteItems: any = createAction(
  FirmAdminActionNames.REMOVE_FIRM_BILLING_QUOTE_ITEMS,
  (rootOrganizationId: string, userIds: string[], type: FirmAdminTableType) =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<void> => {
      const promises = [];
      if (type === FirmAdminTableType.FLP_ORGANIZATION_ADDONS) {
        const state: State.Root = getState();
        const { hash }: FirmAdmin.Data<FirmAdmin.FirmMember> = dataSelectorFactory(type)(state);

        const slugBySku = slugBySkuSubscriptionsSelector(state);
        const userListToUpdate = userIds.reduce((acc, userId) => {
          const flpSubscriptionVariantSku = hash[userId]?.flpSubscriptionVariantSku;

          if (flpSubscriptionVariantSku) {
            acc.push({
              userId,
              flpSubscriptionType: slugBySku[flpSubscriptionVariantSku].slug,
              flpSubscriptionDuration: slugBySku[flpSubscriptionVariantSku].duration,
              membershipTier: flpSubscriptionVariantSku,
            });
          }

          return acc;
        }, [] as any);

        if (userListToUpdate.length) {
          promises.push(
            request(UPDATE_FIRM_ROSTER, {
              rootOrganizationId,
              userList: userListToUpdate,
            })
          );
        }
      } else if (type === FirmAdminTableType.MEMBERSHIPS_AND_ADDONS) {
        const state: State.Root = getState();
        const { hash }: FirmAdmin.Data<FirmAdmin.FirmMember> = dataSelectorFactory(type)(state);

        const userListToUpdate = userIds.reduce((acc, userId) => {
          const { quoteItems } = hash[userId];
          const isMembershipData = quoteItems?.find(item => item.isMembership);
          const membershipTier = isMembershipData?.variantSku;

          acc.push({
            userId,
            membershipTier,
          });

          return acc;
        }, [] as any);

        if (userListToUpdate.length) {
          promises.push(
            request(UPDATE_FIRM_ROSTER, {
              rootOrganizationId,
              userList: userListToUpdate,
            })
          );
        }
      }

      return Promise.all([
        request(REMOVE_FIRM_BILLING_QUOTE_ITEMS, {
          rootOrganizationId,
          userIds,
        }),
        ...promises,
      ]).then(async () => {
        await dispatch(getFirmMembers(type));
      });
    }
);

export const generateInvoice: any = createAction(
  FirmAdminActionNames.GENERATE_INVOICE,
  async (type: FirmAdmin.FirmAdminTableType, invoiceReference: string, userIds?: string[], isFLP?: boolean) =>
    (dispatch: Dispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      const rootOrganizationId = (createMatchSelector(getPath(routeByTableType(type)))(state)?.params as any)?.orgId;

      return request(GENERATE_INVOICE, {
        rootOrganizationId,
        invoiceReference,
        userIds,
        isFLP,
      }).then(async res => {
        await dispatch(getFirmMembers(type));
        return res;
      });
    }
);

export const getInvoiceFile: any = createAction(
  FirmAdminActionNames.GET_INVOICE_FILE,
  (invoiceId: string, invoiceNumber: string) =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const legalEntity = cimaFeaturesSelector(state)
        ? ZuoraTypes.LegalEntity.CIMA
        : ZuoraTypes.LegalEntity.ASSOCIATION;
      await updateContext({
        existingEntity: legalEntity,
      });
      return request(GET_INVOICE_FILE, { invoiceId }).then(response => {
        download(`data:application/pdf;base64,${response.getInvoiceFile}`, `${invoiceNumber}.pdf`);
      });
    }
);

export const setPersonalAddonsModalUserId: any = createAction(FirmAdminActionNames.SET_PERSONAL_ADDONS_MODAL_USER_ID);

export const getRenewalSeason: any = createAction(
  FirmAdminActionNames.GET_RENEWAL_SEASON_INFO,
  (organizationType: B2B.OrganizationCapabilityType) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { search }: any = getLocation(state as any);
    const { renewalseason } = queryString.parse(search);

    let isRenewalSeason = String(renewalseason).toLowerCase();

    if (isRenewalSeason === 'true') return { isRenewalSeason: true };
    if (isRenewalSeason === 'false') return { isRenewalSeason: false };

    return request(GET_RENEWAL_SEASON_INFO).then(response => {
      const { getRenewalSeasonInfo } = response;
      if (organizationType === B2B.OrganizationCapabilityType.CIMA) {
        isRenewalSeason = getRenewalSeasonInfo.hasOwnProperty('fbIsCimaRenewalSeason')
          ? getRenewalSeasonInfo.fbIsCimaRenewalSeason
          : getRenewalSeasonInfo.isCimaRenewalSeason;
      } else {
        isRenewalSeason = getRenewalSeasonInfo.hasOwnProperty('fbIsRenewalSeason')
          ? getRenewalSeasonInfo.fbIsRenewalSeason
          : getRenewalSeasonInfo.isRenewalSeason;
      }

      return { isRenewalSeason };
    });
  }
);

export const toggleIsGenerateInvoiceModalOpen: any = createAction(
  FirmAdminActionNames.SET_IS_GENERATE_INVOICE_MODAL_OPEN
);

export const setIsRefreshFirmData: any = createAction(
  FirmAdminActionNames.SET_IS_REFRESH_FIRM_DATA,
  async (isRefreshRequested: boolean) => (): boolean => {
    return isRefreshRequested;
  }
);

export const setFirmAdminEvent: any = createAction(
  FirmAdminActionNames.SET_FIRM_ADMIN_EVENT,
  (event: string, value: boolean) => () => {
    return { event, value };
  }
);
