import React from 'react';
import { Modal } from 'components/atoms/Modal/Modal';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import styled from 'styled-components';

interface Props {
  bundleDiscount: number;
  open: boolean;
  onCancel: () => void;
  onConfirmation: () => void;
}

export const RemoveBundleComponentModal: React.FC<Props> = ({ bundleDiscount, open, onCancel, onConfirmation }) => (
  <StyledModal
    open={open}
    size="tiny"
    onClose={onCancel}
    heading="Are you sure?"
    testId="remove-bundle-component-modal"
    cancelNode={
      <Button
        size={ButtonEnums.sizes.small}
        variant={ButtonEnums.variants.secondary}
        testId="keep-bundle-item"
        onClick={onCancel}
      >
        Keep bundle
      </Button>
    }
    confirmNode={
      <Button
        size={ButtonEnums.sizes.small}
        variant={ButtonEnums.variants.primary}
        testId="yes-remove-bundle-item"
        onClick={onConfirmation}
      >
        Remove item
      </Button>
    }
  >
    <StyledText>
      This product is part of a bundle that qualifies you for {bundleDiscount}% additional savings. By removing this
      item, the discount will no longer apply.
    </StyledText>
  </StyledModal>
);

const StyledText = styled.p`
  line-height: 1.57;
  text-align: center;
  max-width: ${props => props.theme.pxToRem(483)};
  margin: 0 auto;

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(296)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    ${props => props.theme.mediaQueries.desktopOnly} {
      width: ${props => props.theme.pxToRem(590)};
    }

    .actions {
      padding: ${props => props.theme.pxToRem(20)} 0 0 !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        flex-direction: column-reverse;
        justify-content: center;
      }
    }

    button {
      margin: auto ${props => props.theme.pxToRem(6)} !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100% !important;
        max-width: ${props => props.theme.pxToRem(296)};
        margin: ${props => props.theme.pxToRem(8)} auto !important;
      }
    }
  }
`;
