import React from 'react';
import styled from 'styled-components/macro';
import { FeedTargetableLink } from 'components/atoms';

interface Props {
  items: State.IExternalLinkItem[] | null;
  className?: string;
  heading?: string;
}

export const AcrossAICPADesktop: React.FC<Props> = React.memo(
  ({ className, items, heading = 'Across the AICPA and CIMA' }) => {
    if (!items) return null;
    return (
      <StyledWrapper data-testid={`across-aicpa`} className={className}>
        <StyledTitle>{heading}</StyledTitle>
        {items.map((item: State.IExternalLinkItem) => (
          <FeedTargetableLink key={item.name} iconPurple={true} title={item.name} link={item.url} testId={item.name} />
        ))}
      </StyledWrapper>
    );
  }
);

const StyledWrapper = styled.div`
  margin: 0;
`;

const StyledTitle = styled.h4`
  font-size: ${props => props.theme.fontSizes.l};
  line-height: 32px;
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: normal;
  margin: 0 0 12px;
  white-space: pre-line;
  ${props => props.theme.mediaQueries.mobileOnly} {
    white-space: normal;
  }
`;
