import React from 'react';
import { Checkbox as SemanticUICheckbox, CheckboxProps as SemanticUICheckboxProps } from 'semantic-ui-react';
import { CHECKMARK, CHECKMARK_GREY } from '../../../resources/images';
import styled from 'styled-components';

export interface CheckboxProps extends SemanticUICheckboxProps {
  testId: string;
  width?: number;
  height?: number;
}

const StyledCheckbox = styled(SemanticUICheckbox)`
  &.ui.checkbox label {
    height: ${props => props.theme.pxToRem(props.height)};
    padding-left: calc(${props => props.theme.pxToRem(6)} + ${props => props.theme.pxToRem(props.width)});
    line-height: ${props => props.theme.pxToRem(props.height)};
  }

  &.ui.checkbox label:empty {
    padding-left: 0;
  }

  &.ui.checkbox label:before,
  &.ui.checkbox input:focus ~ label:before,
  &.ui.checkbox input:checked ~ label:before {
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border-radius: 2px;
    border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey7};
  }

  &.ui.checkbox label:hover:before {
    border: 1px solid ${props => props.theme.colors.neutralGrey7};
  }

  @media (hover: hover) and (pointer: fine) {
    &.ui.checkbox label:hover:after {
      content: '';
      background-image: url(${CHECKMARK_GREY});
      background-size: contain;
      background-position: center;
      background-color: ${props => props.theme.colors.neutralWhite};
      border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey7};
      border-radius: 2px;
      width: ${({ width }) => `${width}px`};
      height: ${({ height }) => `${height}px`};
      opacity: 1;
    }
  }

  &.ui.checkbox input:checked ~ label:after {
    content: '';
    background-image: url(${CHECKMARK});
    background-size: contain;
    background-position: center;
    background-color: ${props => props.theme.colors.primaryPurple};
    border: 1px solid ${props => props.theme.colors.primaryPurple};
    border-radius: 2px;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    opacity: 1;
  }

  &.ui.checkbox input:disabled ~ label:after {
    content: '';
    background-size: contain;
    background-position: center;
    background-color: ${props => props.theme.colors.neutralGrey3};
    border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey7};
    opacity: 1;
    border-radius: ${props => props.theme.pxToRem(2)};
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
  }

  &.ui.checkbox input:checked ~ label {
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;

export const Checkbox: React.FC<CheckboxProps> = ({ testId, width = 24, height = 24, ...props }) => (
  <StyledCheckbox data-testid={`checkbox-${testId}`} width={width} height={height} {...props} />
);
