import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  userName?: string | undefined;
  membership?: string | undefined;
  showLogoutLink?: boolean;
  logout: () => void;
}

export const Trigger: React.FC<Props> = ({ userName, showLogoutLink, logout }) => {
  const logoutClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    logout();
  };

  return (
    <>
      <UserWrapper>
        <UserName>{userName}</UserName>
      </UserWrapper>
      {showLogoutLink && <LogoutLink onClick={logoutClick}>Log out</LogoutLink>}
    </>
  );
};

const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const UserName = styled.span`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  line-height: 1.5;
`;

const LogoutLink = styled.a`
  display: block;
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.xs};

  :hover {
    text-decoration: underline;
  }
`;
