import React from 'react';
import styled from 'styled-components/macro';
import { Heading, Grid } from 'components/atoms';
import { DefaultInlineLinkStyles, InlineLinkOnDarkBgStyles } from 'components/atoms/Link/Link';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful } from 'mxp-schemas';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}

export const BlockGroupMembers: React.FC<Props> = ({ modularBlock, headingType }) => {
  const block = modularBlock as Contentful.ModularContent.GroupMembersBlock;
  const isBgColorDark: boolean = isColorDark(block.bgColor);

  const matchJobCompany = (jobTitle: string[] = [], companyName: string[] = []) => {
    const matchInfo = (arrayData1: string[], arrayName1: string, arrayData2: string[], arrayName2: string) => {
      return arrayData1.reduce((acc: object[], current: string, idx: number) => {
        if (arrayData2[idx]) {
          acc.push({ id: idx, [`${arrayName1}`]: current, [`${arrayName2}`]: arrayData2[idx] });
        } else if (arrayData2.length === 0) {
          acc.push({ id: idx, [`${arrayName1}`]: current, [`${arrayName2}`]: '' });
        } else {
          acc.push({ id: idx, [`${arrayName1}`]: current, [`${arrayName2}`]: arrayData2[arrayData2.length - 1] });
        }

        return acc;
      }, []);
    };

    if (jobTitle.length > companyName.length) {
      return matchInfo(jobTitle, 'jobTitle', companyName, 'companyName');
    }

    return matchInfo(companyName, 'companyName', jobTitle, 'jobTitle');
  };

  const renderMember = (member: Contentful.ModularContent.Members, idx: number) => {
    const jobCompany = matchJobCompany(member.jobTitle, member.companyName);
    return (
      <MemberLayoutColumn key={`member-${idx}`}>
        <MemberWrapper>
          <NameLine tabIndex={0} isBgColorDark={isBgColorDark}>
            {member.name ?? ''}
            {member.credentials && `, ${member.credentials}`}
          </NameLine>
        </MemberWrapper>
        <MemberWrapper>
          {jobCompany.length > 0 &&
            jobCompany.map((info: { id?: number; jobTitle?: string; companyName?: string }) => (
              <InformationLine tabIndex={0} isBgColorDark={isBgColorDark} key={`job-company-${info.id}`}>
                {info.jobTitle ?? ''}
                {info.jobTitle && info.companyName && ', '}
                {info.companyName ?? ''}
              </InformationLine>
            ))}
        </MemberWrapper>
      </MemberLayoutColumn>
    );
  };

  return (
    <StyledGrid>
      <Grid.Row>
        <StyledHeaderDescription isBgColorDark={isBgColorDark}>
          {block.header && (
            <StyledHeading tabIndex={0} as={headingType} dangerouslySetInnerHTML={{ __html: block.header }} />
          )}
          {block.description && (
            <StyledParagraph
              tabIndex={0}
              dangerouslySetInnerHTML={{ __html: block.description.slice(0, 200) }}
              isBgColorDark={isBgColorDark}
            />
          )}
        </StyledHeaderDescription>
      </Grid.Row>
      <Grid.Row columns={3}>
        {block.members?.map((member: Contentful.ModularContent.Members, idx: number) => renderMember(member, idx))}
      </Grid.Row>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  &&&&& {
    ${props => props.theme.mediaQueries.desktopOnly} {
      margin-left: 0;
    }
  }
`;

const StyledHeaderDescription = styled.div<{ isBgColorDark: boolean }>`
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  margin-bottom: ${props => props.theme.pxToRem(30)};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => (props['data-is-single-column'] ? props.theme.pxToRem(16) : props.theme.pxToRem(24))};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
`;

const StyledParagraph = styled.p<{ isBgColorDark: boolean }>`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.m};
  margin: 0;

  a {
    ${DefaultInlineLinkStyles};

    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
`;

const MemberLayoutColumn = styled(Grid.Column)`
  padding: 0 ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} 0 !important;
`;

const MemberWrapper = styled.div`
  margin: 0;
  margin-bottom: ${props => props.theme.pxToRem(5)};
  width: 90%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 70%;
  }
`;

const NameLine = styled.p<{ isBgColorDark: boolean }>`
  margin: 0;
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.primaryPurple)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const InformationLine = styled.p<{ isBgColorDark: boolean }>`
  margin: 0;
  color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.thin};
`;
