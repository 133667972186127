import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { HelpSection } from 'components/organisms';
import { Container, Grid, Divider, Checkbox, ButtonEnums, Dropdown, Link, LinkEnums } from 'components/atoms';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { ReactComponent as Correct } from 'resources/images/icon-dev-ic-check-circle.svg';
import { getPath, hasTruthyValue } from 'utils';
import { MobileInput } from 'components/atoms/Input/MobileInput';
import { InputWithValidation, Footer, RadioOption } from 'components/molecules';
import { Button } from 'semantic-ui-react';
import { userLoadingSelector, personAccountDataSelector, isUserMemberSelector } from 'modules/user/selectors';
import { Utils } from 'mxp-utils';
import {
  createCCPACase,
  caseAddressValidation,
  fetchExistingCase,
  sendCaliforniaLawEmail,
} from 'modules/createCase/actions';
import { Routes } from 'constants/index';
import { ReactComponent as ErrorIcon } from 'resources/images/ic-error.svg';

enum reason {
  Valid = 'VALID',
  CleanUp = 'CLEAN_UP',
  Invalid = 'INVALID',
  Ambiguous = 'AMBIGUOUS',
}

enum disposition {
  Yes = 'Yes',
  No = 'No',
}

export const CaliforniaLaw: React.FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const loading = useSelector(userLoadingSelector);
  const personAccountData = useSelector(personAccountDataSelector);
  const isMember: boolean = useSelector(isUserMemberSelector);

  const defaultRegistrationFormState = {
    accountId: isMember ? personAccountData.id : '',
    infoRequest: '',
    addressLine1: '',
    city: '',
    zipcode: '',
    region: 'USA',
    aicpaNumber: '',
    state: 'California',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    aicpaMember: 'Yes',
    cimaMember: 'Yes',
    certification: 'false',
  };

  const [registrationForm, setRegistrationForm] = useState(defaultRegistrationFormState);
  const [isRegistrationFormValid, setRegistrationFormValid] = useState(false);
  const [californiaResident, setCaliforniaResident] = useState('');
  const [submitFormOnBefalf, setSubmitFormOnBefalf] = useState('');
  const [phoneObject, setPhoneObject] = useState({
    phoneNumber: '',
    countryCode: 'US',
    dialCode: '+1',
  });

  const firstNameIsEmpty = !Boolean(registrationForm.firstName.length);
  const lastNameIsEmpty = !Boolean(registrationForm.lastName.length);
  const addressIsEmpty = !Boolean(registrationForm.addressLine1.length);
  const cityIsEmpty = !Boolean(registrationForm.city.length);
  const zipIsEmpty = !Boolean(registrationForm.zipcode.length);
  const emailIsEmpty = !Boolean(registrationForm.email.length);
  const phoneIsEmpty = !Boolean(phoneObject.phoneNumber.length);
  const aicpaNumberEmpty = !Boolean(registrationForm.aicpaNumber.length);
  const aicpaMemberEmpty = !Boolean(registrationForm.aicpaMember.length);
  const cimaMemberEmpty = !Boolean(registrationForm.cimaMember.length);
  const certificationEmpty = !Boolean(registrationForm.certification === 'true');
  const personalInfoEmpty = !Boolean(registrationForm.infoRequest.length);
  const isShowMemberIdFieldCaliforniaForm = hasTruthyValue(
    registrationForm.aicpaMember === disposition.Yes,
    registrationForm.cimaMember === disposition.Yes
  );
  const infoReq = [
    {
      key: 1,
      text: 'Summary view request',
      value: 'Summary view request',
    },
    {
      key: 2,
      text: 'Do not sell my personal information',
      value: 'Do not sell my personal information',
    },
    {
      key: 3,
      text: 'Delete my personal information',
      value: 'Delete my personal information',
    },
  ];

  const onchangeOptionValue = (event: any) => {
    infoReq.forEach(v => {
      if (v.text === event.target.textContent) {
        setRegistrationForm({ ...registrationForm, infoRequest: v.value });
      }
    });
  };

  React.useEffect(() => {
    if (phoneObject) {
      setRegistrationForm({ ...registrationForm, phone: ` ${phoneObject.dialCode} ${phoneObject.phoneNumber}` });
    }
  }, [phoneObject]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCAResidentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCaliforniaResident(event.toString());
  };

  const handleSubmitOnBefalfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitFormOnBefalf(event.toString());
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, firstName: event.target.value });
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, lastName: event.target.value });
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, addressLine1: event.target.value });
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({ ...registrationForm, city: event.target.value });
  };

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/[a-z]/i.test(value) || /[!@#$%^&*`~()_+\-=[\]{};':"\\|,.<>\/?]+/.test(value)) return; // eslint-disable-line
    setRegistrationForm({ ...registrationForm, zipcode: value });
  };

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRegistrationForm({ ...registrationForm, email: event.target.value });
    },
    [registrationForm, setRegistrationForm]
  );

  const handleAICPAMemberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    registrationForm.aicpaMember = event.toString() === disposition.Yes ? disposition.Yes : disposition.No;
    setRegistrationForm({ ...registrationForm, aicpaMember: registrationForm.aicpaMember });
  };

  const handleAICPANumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/[a-z]/i.test(value) || /[!@#$%^&*`~()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)) return; // eslint-disable-line
    setRegistrationForm({ ...registrationForm, aicpaNumber: value });
  };

  const handleCIMAMemberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    registrationForm.cimaMember = event.toString() === 'Yes' ? disposition.Yes : disposition.No;
    setRegistrationForm({ ...registrationForm, cimaMember: registrationForm.cimaMember });
  };

  const handleCheckChange = (event: any) => {
    event.target.checked
      ? setRegistrationForm({ ...registrationForm, certification: 'true' })
      : setRegistrationForm({ ...registrationForm, certification: 'false' });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  React.useEffect(() => {
    const isAicpaNumberValid = () => (isShowMemberIdFieldCaliforniaForm ? (aicpaNumberEmpty ? false : true) : true);

    const formValid =
      !firstNameIsEmpty &&
      !lastNameIsEmpty &&
      !addressIsEmpty &&
      !cityIsEmpty &&
      !zipIsEmpty &&
      !emailIsEmpty &&
      !phoneIsEmpty &&
      isAicpaNumberValid() &&
      !aicpaMemberEmpty &&
      !cimaMemberEmpty &&
      !certificationEmpty &&
      !personalInfoEmpty;
    setRegistrationFormValid(formValid);
  }, [
    registrationForm,
    firstNameIsEmpty,
    lastNameIsEmpty,
    addressIsEmpty,
    cityIsEmpty,
    zipIsEmpty,
    emailIsEmpty,
    phoneIsEmpty,
    aicpaNumberEmpty,
    aicpaMemberEmpty,
    cimaMemberEmpty,
    certificationEmpty,
    personalInfoEmpty,
    isShowMemberIdFieldCaliforniaForm,
  ]);

  const [caseParam, setCaseParam] = useState({ caseInfo: {} });

  React.useEffect(() => {
    setCaseParam({ caseInfo: registrationForm });
  }, [registrationForm]);

  const [validReason, setValidReason] = useState('');
  const [submitStatus, setSubmitStatus] = useState('');

  const completeAddress = {
    addressLine1: registrationForm.addressLine1,
    addressLine2: '',
    city: registrationForm.city,
    state: registrationForm.state,
    zipCode: registrationForm.zipcode,
    country: registrationForm.region || 'USA',
  };

  React.useEffect(() => {
    const { addressLine1, city } = completeAddress;
    if (addressLine1 && city) {
      const addressResult = dispatch(caseAddressValidation(completeAddress));
      addressResult.then((rec: any) => {
        setValidReason(rec.payload.smartystreetsValidation.shippingAddress.reason);
      });
    }
  }, [dispatch, completeAddress]);

  const submitRegistration = () => {
    if (validReason === reason.Valid || validReason === reason.CleanUp) {
      dispatch(fetchExistingCase(registrationForm.email)).then((rec: any) => {
        if (rec.payload.getExistingCaseByEmail.length) {
          setSubmitStatus('missing');
        } else {
          dispatch(createCCPACase(caseParam))
            .then(() => {
              setSubmitStatus('success');
              // Send California law email
              if (personAccountData) {
                const payload = {
                  subscriberKey: personAccountData.id,
                  emailAddress: registrationForm.email,
                  firstName: registrationForm.firstName,
                  lastName: registrationForm.lastName,
                  oktaId: personAccountData.oktaId,
                };
                dispatch(sendCaliforniaLawEmail({ californiaLaw: payload })).then(() => {
                  setSubmitStatus('success');
                });
              }
            })
            .catch(() => {
              setValidReason(''); // clear trigger
              setSubmitStatus('missing');
            });
        }
      });
    } else if (validReason === reason.Invalid || validReason === reason.Ambiguous) {
      // setStatus('false');
      setSubmitStatus('missing');
    } else {
      setValidReason(''); // clear trigger
      setSubmitStatus('');
    }
  };

  const isEmailValidOrEmpty = Utils.isEmailFormatValid(registrationForm.email) || emailIsEmpty;
  const isFirstNameValidOrEmpty = Utils.isAlphabet(registrationForm.firstName) || firstNameIsEmpty;
  const isLastNameValidOrEmpty = Utils.isAlphabet(registrationForm.lastName) || lastNameIsEmpty;

  return (
    <>
      <HeaderPageWrapper>
        <StyledContainer>
          <Grid stackable>
            <Grid.Row stretched>
              <StyledGridColumn tablet={16} computer={16} floated="left">
                <StyledDiv>
                  <StyledContentContainer>
                    <StyledText>California Law</StyledText>
                  </StyledContentContainer>
                </StyledDiv>
                <StyledContentContainer>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <h2>CCPA Exercise your rights forms</h2>

                      {submitStatus === 'success' && (
                        <Status color={'#47a23d'}>
                          <StyledCorrectIcon />
                          <p>
                            Your request has been received. The Security & Privacy Office will follow up with you within
                            30 days regarding the status of your request.
                          </p>
                        </Status>
                      )}
                      {submitStatus === 'missing' && (
                        <ErrorStatus>
                          <ErrorIcon />
                          <p>
                            Uh oh! It looks like you have already submitted a request using this email address. To check
                            the status of your request, please contact our Security & Privacy Office at{' '}
                            <a
                              href={'mailto:SecurityandPrivacyOffice@aicpa-cima.com.'}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              SecurityandPrivacyOffice@aicpa-cima.com.
                            </a>
                            Thank you!
                          </p>
                        </ErrorStatus>
                      )}

                      {californiaResident === 'No' && submitFormOnBefalf === 'No' ? (
                        <ErrorStatus>
                          <ErrorIcon height={30} width={30} />
                          <p>
                            You are not eligible to submit a California law request. Thank you for your request. <br />{' '}
                            The California Consumer Privacy Act applies to California residents. To learn more about how
                            we protect your privacy, please visit our{' '}
                            <Link
                              type={LinkEnums.type.inlineLink}
                              testId={`privacy-policy`}
                              to={getPath(Routes.PRIVACY_POLICY)}
                              isExternal
                              viewTextForAnalytics="privacy-policy"
                            >
                              Privacy Policy
                            </Link>
                            . <br /> If you would like to view and manage your marketing preferences, please visit your{' '}
                            <Link
                              type={LinkEnums.type.inlineLink}
                              testId={`preference-center`}
                              to={getPath(Routes.PREFERENCES)}
                              isExternal
                              viewTextForAnalytics="preference-center"
                            >
                              Preference Center
                            </Link>
                            .
                          </p>
                        </ErrorStatus>
                      ) : null}

                      <div>
                        <StyledRadioContainer>
                          <p>Are you a California Resident? </p>
                          <RadioDiv>
                            <RadioOption
                              checked={californiaResident === 'Yes' ? true : false}
                              value="Yes"
                              label="Yes"
                              onClick={handleCAResidentChange}
                            />
                            <RadioOption
                              checked={californiaResident === 'No' ? true : false}
                              value="No"
                              label="No"
                              onClick={handleCAResidentChange}
                            />
                          </RadioDiv>
                        </StyledRadioContainer>
                        <StyledRadioContainer>
                          <p>Are you submitting this form on behalf of a California Resident? </p>

                          <RadioDiv>
                            <RadioOption
                              checked={submitFormOnBefalf === 'Yes' ? true : false}
                              value="Yes"
                              label="Yes"
                              onClick={handleSubmitOnBefalfChange}
                            />
                            <RadioOption
                              checked={submitFormOnBefalf === 'No' ? true : false}
                              value="No"
                              label="No"
                              onClick={handleSubmitOnBefalfChange}
                            />
                          </RadioDiv>
                        </StyledRadioContainer>
                      </div>
                    </div>

                    {submitStatus !== 'success' && (
                      <div>
                        <StyledDropdownWithInfo
                          testId={'First Question'}
                          options={infoReq}
                          value={registrationForm.infoRequest}
                          onChange={onchangeOptionValue}
                          placeholder={'Selection Required'}
                        />
                        <StyledInput
                          onChange={handleFirstNameChange}
                          name={'First Name'}
                          testId={'First Name'}
                          type="text"
                          isCorrect={isFirstNameValidOrEmpty}
                          labelId={'first-name'}
                          labelName="First Name"
                          value={registrationForm.firstName}
                          autoFocus
                          errorMessage={'You have entered an invalid character'}
                          infoMessage={'Please enter your First Name'}
                          isCorrectIconShown
                        />
                        <StyledInput
                          onChange={handleLastNameChange}
                          name={'Last Name'}
                          testId={'Last Name'}
                          type="text"
                          isCorrect={isLastNameValidOrEmpty}
                          labelId={'Last-name'}
                          labelName="Last Name"
                          value={registrationForm.lastName}
                          autoFocus
                          errorMessage={'You have entered an invalid character'}
                          infoMessage={'Please enter your Last Name'}
                          isCorrectIconShown
                        />
                        <StyledInput
                          onChange={handleAddressChange}
                          name={'Address'}
                          testId={'Address'}
                          type="text"
                          isCorrect={validReason === reason.Invalid ? false : true}
                          labelId={'Address'}
                          labelName="Address Line 1"
                          value={registrationForm.addressLine1}
                          autoFocus
                          errorMessage={'This address is not recognised, please try again.'}
                          infoMessage={'Please enter your Address'}
                          isCorrectIconShown
                        />
                        <StyledInput
                          onChange={handleCityChange}
                          name={'City'}
                          testId={'City'}
                          type="text"
                          isCorrect={true}
                          labelId={'City'}
                          labelName="City"
                          value={registrationForm.city}
                          autoFocus
                          errorMessage={'Please input a City.'}
                          infoMessage={'Please enter your City'}
                          isCorrectIconShown
                        />
                        <div>
                          <StyledFlex>
                            <div>
                              <StyledTxt>State/Province</StyledTxt>
                              <StyledButton disabled={true}>California</StyledButton>
                            </div>
                            <div>
                              <StyledZipInput
                                onChange={handleZipChange}
                                name={'Zip'}
                                testId={'Zip'}
                                type="text"
                                isCorrect={submitStatus === 'addressError' ? false : true}
                                labelId={'Zip'}
                                labelName="Zip"
                                value={registrationForm.zipcode}
                                autoFocus
                                errorMessage={'Please enter a valid Zip code'}
                                infoMessage={'Please enter your Zip'}
                                isCorrectIconShown
                              />
                            </div>
                          </StyledFlex>
                        </div>
                        <div>
                          <StyledTxt>Location</StyledTxt>
                          <StyledButtonLoc disabled={true}>United States</StyledButtonLoc>
                        </div>
                        <StyledInput
                          onChange={handleEmailChange}
                          name={'Email Address'}
                          testId={'Email Address'}
                          type="email"
                          isCorrect={isEmailValidOrEmpty}
                          labelId={'Email-address'}
                          labelName="Email Address"
                          value={registrationForm.email}
                          autoFocus
                          errorMessage={'Please enter a valid email address'}
                          infoMessage={'Please enter your Email Address'}
                          isCorrectIconShown
                        />
                        <StyledMobileInput
                          phoneObject={phoneObject}
                          setPhoneObject={setPhoneObject}
                          errorMessage={'Please enter a valid phone number'}
                          label={'Phone Number'}
                        />
                        <StyledRadioContainer>
                          <p>Are you an AICPA member? </p>
                          <RadioDiv>
                            <RadioOption
                              checked={registrationForm.aicpaMember === disposition.Yes && true}
                              value="Yes"
                              label="Yes"
                              onClick={handleAICPAMemberChange}
                            />
                            <RadioOption
                              checked={registrationForm.aicpaMember === disposition.No && true}
                              value="No"
                              label="No"
                              onClick={handleAICPAMemberChange}
                            />
                          </RadioDiv>
                        </StyledRadioContainer>
                        <StyledRadioContainer>
                          <p>Are you a CIMA member or student? </p>
                          <RadioDiv>
                            <RadioOption
                              checked={registrationForm.cimaMember === disposition.Yes && true}
                              value="Yes"
                              label="Yes"
                              onClick={handleCIMAMemberChange}
                            />
                            <RadioOption
                              checked={registrationForm.cimaMember === disposition.No && true}
                              value="No"
                              label="No"
                              onClick={handleCIMAMemberChange}
                            />
                          </RadioDiv>
                        </StyledRadioContainer>
                        {isShowMemberIdFieldCaliforniaForm && (
                          <StyledInput
                            onChange={handleAICPANumberChange}
                            name={'AICPAMemberNumber'}
                            testId={'AICPAMemberNumber-CaliforniaForm'}
                            type="text"
                            isCorrect={true}
                            labelId={'AICPAMemberNumber'}
                            labelName="Please provide your Member ID"
                            value={registrationForm.aicpaNumber}
                            autoFocus
                            errorMessage={''}
                            infoMessage={'Please provide your Member ID'}
                            isCorrectIconShown
                          />
                        )}
                        <StyledCheckbox>
                          <Checkbox testId="checkbox" onChange={handleCheckChange} id="checkbox" autoFocus />
                          <StyledConfirmationLabel>
                            I certify under penalty of perjury that I am the Individual or have authority to submit this
                            request on behalf of the individual about whom I am making this request.
                          </StyledConfirmationLabel>
                        </StyledCheckbox>
                        <SubmitButton
                          loading={loading}
                          size={ButtonEnums.sizes.large}
                          onClick={submitRegistration}
                          testId="register"
                          variant={ButtonEnums.variants.primary}
                          disabled={!isRegistrationFormValid}
                          complete={isRegistrationFormValid ? true : false}
                        >
                          Submit
                        </SubmitButton>
                      </div>
                    )}
                  </form>
                </StyledContentContainer>
                <StyledDivider />
                <StyledContentContainer>
                  <StyledHelpSection hideCheckoutHelp={true} />
                </StyledContentContainer>
              </StyledGridColumn>
            </Grid.Row>
          </Grid>
        </StyledContainer>
        <Footer />
      </HeaderPageWrapper>
    </>
  );
};

const StyledHelpSection = styled(HelpSection)`
  margin-top: ${props => props.theme.pxToRem(40)};
  margin-bottom: ${props => props.theme.pxToRem(80)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(24)};
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledContainer = styled.div``;

const StyledContentContainer = styled(Container)`
  display: flex;
  align-items: center;
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(40)};
    border-top-color: transparent;
    border-bottom-color: ${props => props.theme.colors.neutralGrey3};
    color: ${props => props.theme.colors.neutralGrey3};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(25)};
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: ${props => props.theme.pxToRem(260)};
  box-shadow: ${props => props.theme.colors.neutralGrey3};
  background-color: ${props => props.theme.colors.neutralGrey1};
  margin-bottom: ${props => props.theme.pxToRem(40)};
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&&&& {
    display: block !important;
  }
`;

const StyledText = styled.p`
  font-size: ${props => props.theme.fontSizes.xl};
`;

const StyledTxt = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledButton = styled.button`
  margin-top: ${props => props.theme.pxToRem(10)};
  height: ${props => props.theme.pxToRem(55)};
  padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)};
  padding-right: ${props => props.theme.pxToRem(130)};
  font-size: ${props => props.theme.fontSizes.m};
  transform: translateY(${props => props.theme.pxToRem(-16)});
`;

const StyledButtonLoc = styled.button`
  margin: ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(20)} 0;
  height: ${props => props.theme.pxToRem(55)};
  padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)};
  padding-right: ${props => props.theme.pxToRem(380)};
  color: ${props => props.theme.colors.neutralGrey5};
  font-size: ${props => props.theme.fontSizes.m};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    padding-right: 0;
    text-align: left;
  }
`;

const StyledInput = styled(InputWithValidation)`
  height: ${props => props.theme.pxToRem(60)};
  width: ${props => props.theme.pxToRem(500)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: calc(100% - ${props => props.theme.pxToRem(5)});
  }
`;
const Status = styled.div`
  position: relative;
  max-width: 699px;
  width: 100%;
  padding: 15px 16px;
  border-radius: 4px;
  background: #d4e7ee;
  border: 1px solid ${props => props.color};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  p {
    margin-left: ${props => props.theme.pxToRem(24)};
    line-height: 1.57;
    font-size: ${props => props.theme.pxToRem(14)};
  }
`;

const ErrorStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 15px 16px;
  border-radius: 4px;
  background: #fceaec;
  border: 1px solid #e83147;
  margin-bottom: ${props => props.theme.pxToRem(20)};
  p {
    margin-left: ${props => props.theme.pxToRem(24)};
    line-height: 1.57;
    font-size: ${props => props.theme.pxToRem(14)};
    font-weight: ${props => props.theme.fontWeights.light};
  }
  svg {
    color: ${props => props.theme.colors.interfaceRed};
  }
`;

const StyledCorrectIcon = styled(Correct)`
  position: absolute;
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  left: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.interfaceGreen};
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledRadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${props => props.theme.pxToRem(500)};
  align-items: baseline;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: auto;
    flex-wrap: wrap;
  }
`;

const RadioDiv = styled.div`
  display: flex;
  gap: ${props => props.theme.pxToRem(10)};
`;

const StyledDropdownWithInfo = styled(Dropdown)`
  height: ${props => props.theme.pxToRem(60)};
  width: ${props => props.theme.pxToRem(500)};
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 1rem;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${props => props.theme.pxToRem(30)};
`;

const StyledZipInput = styled(InputWithValidation)`
  position: relative;
  height: ${props => props.theme.pxToRem(60)};
  width: ${props => props.theme.pxToRem(250)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: calc(100% - ${props => props.theme.pxToRem(5)});
    input#Zip {
      width: 100% !important;
    }
  }
`;

const StyledMobileInput = styled(MobileInput)`
  &&& {
    width: ${props => props.theme.pxToRem(400)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: calc(100% - ${props => props.theme.pxToRem(5)});
    }
    input {
      border-radius: 0 5px 5px 0;
      height: ${props => props.theme.pxToRem(60)} !important;
    }
    div:nth-child(3) {
      flex: 1 100%;
    }
  }
  &&&& {
    ${props => `
    &&& {
      height: ${props.theme.pxToRem(60)};
   }
	}
  `}
  }
`;

const StyledConfirmationLabel = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.thin};
  max-width: ${props => props.theme.pxToRem(400)};
  transform: translateY(${props => props.theme.pxToRem(-8)});
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${props => props.theme.pxToRem(15)};
  margin-top: ${props => props.theme.pxToRem(30)};
`;

const SubmitButton = styled(Button)`
  background: ${props => (props.complete ? '#72246c !important' : '')};
  color: ${props => (props.complete ? '#fff !important' : '')};
  &&&& {
    padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(120)};
    margin-top: ${props => props.theme.pxToRem(30)};
  }
`;
