import React from 'react';
import { Modal } from 'components/atoms/Modal/Modal';
import { ButtonEnums } from 'components/atoms/Button/Button';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import styled from 'styled-components';
import { ReactComponent as InfoBubble } from 'resources/images/ic-unsuccessful.svg';

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirmation: () => void;
}

export const EndSessionModal: React.FC<Props> = ({ open, onCancel, onConfirmation }) => (
  <Modal
    open={open}
    size="tiny"
    onClose={onCancel}
    heading="Are you sure?"
    testId="end-session-admin-modal"
    icon={<StyledInfoBubble />}
    cancelNode={
      <StyledButtonLink
        size={ButtonEnums.sizes.small}
        variant={ButtonEnums.variants.secondary}
        testId="return-to-prev-page"
        onClick={onCancel}
        to=""
      >
        No, return to previous page
      </StyledButtonLink>
    }
    confirmNode={
      <StyledButtonLink
        size={ButtonEnums.sizes.small}
        variant={ButtonEnums.variants.primary}
        testId="yes-end-session"
        onClick={onConfirmation}
        to=""
      >
        Yes, end session
      </StyledButtonLink>
    }
  >
    <StyledText>By continuing, your impersonation session will end and any work in progress will be lost.</StyledText>
  </Modal>
);

const StyledButtonLink = styled(ButtonLink)`
  &&&&& {
    padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};
    margin: 0 ${props => `${props.theme.pxToRem(5)}`};
  }
`;

const StyledText = styled.p`
  line-height: 1.57;
  text-align: center;
  max-width: ${props => props.theme.pxToRem(300)};
  margin: 0 auto;
`;

const StyledInfoBubble = styled(InfoBubble)`
  fill: ${props => props.theme.colors.interfaceRed};
`;
