import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Modal, Button, ButtonEnums, Link, LinkEnums } from 'components/atoms';
import { Routes, MembershipPackageAccordionStatus } from 'constants/index';
import { ModalVariants, getPath, getCurrentLocation } from 'utils';
import styled from 'styled-components/macro';
import { ReactComponent as Exclamation } from 'resources/images/ic-exclamation.svg';
import { useSelector } from 'react-redux';
import { MembershipTypes } from 'mxp-schemas';
import { isFLPSwitchSelector, isFLPUpgradeSelector } from 'modules/membership/selectors';
import { learningPathwaySelector } from 'modules/user/selectors';
/**
 * Error Modal/Pop-in
 * Purchase or Upgrade FLP subscription mid-term
 */

interface Props {
  open: boolean;
  onClose: () => void;
  variant?: ModalVariants;
  id?: string;
  typeSlug?: string;
  onConfirm?: () => void;
}

export const FLPErrorModal: React.FC<Props> = memo(({ open, onClose, variant, onConfirm }) => {
  const history = useHistory();

  const isFLPUpgrade = useSelector(isFLPUpgradeSelector);
  const isFLPSwitch = useSelector(isFLPSwitchSelector);

  const [heading, setHeading] = useState<string | React.ReactNode>();
  const [subHeading, setSubHeading] = useState<string | React.ReactNode>();
  const [cancelNodeText, setCancelNodeText] = useState<string | React.ReactNode>();
  const [confirmNodeText, setConfirmNodeText] = useState<string | React.ReactNode>();

  const handleOnClose = () => {
    const showMembershipPage = variant
      ? [ModalVariants.INELIGIBLE_RETIRED, ModalVariants.FLP_PROCEED, ModalVariants.INELIGIBLE_REGULAR].includes(
          variant
        )
      : false;

    if (showMembershipPage) history.push(getPath(Routes.MY_MEMBERSHIPS));
    onClose();
  };

  useEffect(() => {
    switch (variant) {
      case ModalVariants.INELIGIBLE_REGULAR:
        setHeading('Attention');
        setSubHeading(
          'Unfortunately, regular members are not eligible for the Finance Leadership Program or the Self-directed Learning and Assessment Route.'
        );
        setConfirmNodeText('I understand');
        break;
      case ModalVariants.INELIGIBLE_RETIRED:
        setHeading('Attention');
        setSubHeading(
          'As a retired member, you are not eligible for the Finance Leadership Program or the Self-directed Learning and Assessment Route.'
        );
        setConfirmNodeText('I understand');
        break;
      case ModalVariants.INELIGIBLE_AFFILIATE:
        setHeading('Attention');
        setSubHeading(
          'Since you have passed all your exams, you are no longer eligible to subscribe to the Finance Leadership Program.'
        );
        setConfirmNodeText('I understand');
        break;
      case ModalVariants.INCOMPLETE_CERTBA:
        setHeading('Attention');
        setSubHeading(
          'You’ll need to complete your Certificate in Business Administration prior to subscribing to the Finance Leadership Program.'
        );
        setConfirmNodeText('I understand');
        break;
      case ModalVariants.ALL_EXAMS_PASSED:
        setHeading('Attention');
        setSubHeading(
          'Since you have passed all your exams, you are no longer eligible to subscribe to the Finance Leadership Program.'
        );
        setConfirmNodeText('I understand');
        break;
      case ModalVariants.GEOLOCATION_ERROR:
        setHeading('Unfortunately, the Finance Leadership Program is not currently available in your country.');
        setSubHeading('Are you interested in being contacted about other study options?');
        setConfirmNodeText('Yes');
        setCancelNodeText('No');
        break;
      case ModalVariants.EXAMS_CANCELLATION_WARNING:
        setSubHeading(
          'Once your new Finance Leadership Program subscription is active, you will have the opportunity to rebook your exams and use any of your newly issued credits.'
        );
        setConfirmNodeText('Proceed');
        setCancelNodeText('Cancel');
        break;
      case ModalVariants.FLP_PROCEED:
        setHeading('Attention');
        setSubHeading(
          'Once your new Finance Leadership Program subscription is active, you will have the opportunity to rebook your exams and use any of your newly issued credits.'
        );
        setConfirmNodeText('Proceed');
        setCancelNodeText('Cancel');
        break;
      case ModalVariants.ACH_ERROR:
        setHeading('Attention');
        setSubHeading('The information entered were not correct, please enter again');
        setConfirmNodeText('I understand');
        break;
      case ModalVariants.FLP_NO_EXCEPTION:
        setHeading('Switch between pathways');
        setSubHeading(
          <>
            <div>
              <StyledSpan>Switching between CertBA and FLP Business Essentials?</StyledSpan>
            </div>
            <br />
            <div>
              If you are currently studying (but have not completed) the CIMA Certification in Business Accounting
              (CertBA) or the FLP Business and Finance Essentials Course, this progress will NOT be carried over when
              switching between learning pathways.
            </div>
            <br />
            <br />
            <div>
              <StyledSpan>If you have reached the Operational Level or Above?</StyledSpan>
            </div>
            <br />
            <div>
              Don't worry! Any Objective Tests you have passed on the Self-Directed Learning pathway, and any full sets
              of competencies you have finished on the Finance Leadership Program will directly transfer between
              learning pathways, and you will not need to re-do the content of test(s).
            </div>
          </>
        );
        setCancelNodeText('Exit');
        setConfirmNodeText('I understand');
        break;
      case ModalVariants.HAS_ETHICS_VIOLATION:
        setHeading('It appears that you are unable to proceed');
        setSubHeading(
          <>
            <p>
              Before you can complete sign-up, there are open questions about the status of your account that may need
              to be addressed.
            </p>
            <p>
              {'Please contact us '}
              <Link
                type={LinkEnums.type.standaloneLink}
                isExternal
                to={getPath(Routes.CONTACT_US)}
                testId="ei-question-us-phone"
              >
                here
              </Link>
              {' for more information.'}
            </p>
          </>
        );
        setConfirmNodeText('I understand');
        break;
      default:
        setHeading('Attention');
        setConfirmNodeText('I understand');
        break;
    }
  }, [variant]);

  const location = useLocation();
  const learningPathway = useSelector(learningPathwaySelector) as string;

  const destinationUrl = React.useCallback(
    variant => {
      const currentLocation = getCurrentLocation(location);
      if (currentLocation === getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY)) {
        if (variant === ModalVariants.FLP_NO_EXCEPTION) {
          if (learningPathway === MembershipTypes.Pathway.FLP) {
            return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Addons}`;
          }
        }

        if (learningPathway === MembershipTypes.Pathway.PQ) {
          return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Skill}`;
        }

        return getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY);
      }
    },
    [location, learningPathway]
  );

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      testId="flp-error-modal"
      variant={variant}
      size="small"
      showCloseCross
      heading={heading}
      icon={<StyledExclamation />}
      cancelNode={
        <>
          {variant === ModalVariants.GEOLOCATION_ERROR ||
          variant === ModalVariants.EXAMS_CANCELLATION_WARNING ||
          variant === ModalVariants.FLP_PROCEED ||
          variant === ModalVariants.FLP_NO_EXCEPTION ? (
            <Button
              size={ButtonEnums.sizes.small}
              variant={ButtonEnums.variants.secondary}
              testId="modal-cancel-button"
              onClick={isFLPUpgrade || isFLPSwitch ? handleOnClose : onClose}
            >
              {cancelNodeText}
            </Button>
          ) : (
            ''
          )}
        </>
      }
      confirmNode={
        <Button
          to={destinationUrl(variant)}
          size={ButtonEnums.sizes.small}
          testId="modal-confirm-button"
          variant={ButtonEnums.variants.primary}
          onClick={onConfirm ? onConfirm : handleOnClose}
        >
          {confirmNodeText}
        </Button>
      }
    >
      <StyledSubHeading>{subHeading}</StyledSubHeading>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  &&&& {
    background: ${props => props.theme.colors.neutralGrey1};
    min-height: ${props => props.theme.pxToRem(264)};
    max-width: ${props => props.theme.pxToRem(590)};
    margin-top: ${props => props.theme.pxToRem(48)};
    > .header {
      h2 {
        min-height: ${props => props.theme.pxToRem(64)};
        max-width: ${props => props.theme.pxToRem(440)};
        padding: ${props => props.theme.pxToRem(10)};
        margin: auto;
        text-align: center;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }
  button > svg {
    fill: ${props => props.theme.colors.primaryDarkPurple};
  }
`;

const StyledSubHeading = styled.p`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xs};
  text-align: center;
`;

const StyledExclamation = styled(Exclamation)`
  &&& {
    path {
      fill: ${props => props.theme.colors.interfaceRed};
    }
  }
`;

const StyledSpan = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
`;
