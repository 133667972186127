import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IC_EDIT_PAD } from 'resources/images';

import styled from 'styled-components';
import { newEmployerJobTitleSelector, tlwSelector } from 'modules/user/selectors';

import { ModalEmploymentInformation } from 'components/organisms';

interface Props {
  modalProps: {
    isShowEmploymentModal: boolean;
    handleSelectedEmployment: (value: any) => void;
  };
}

export const MembershipAccordionHeading: React.FC<Props> = ({ modalProps }) => {
  const { isShowEmploymentModal, handleSelectedEmployment } = modalProps;
  const [isEditingEmployment, editEmployment] = useState(!isShowEmploymentModal);

  const jobTitle = useSelector(newEmployerJobTitleSelector);
  const tlwData = useSelector(tlwSelector);
  const employmentStatus = jobTitle ? 'Employed' : 'Temporarily left the workforce (TLW)';
  const tlwReason = tlwData.tlwReason;

  const updateEmployment = () => {
    editEmployment(!isEditingEmployment);
  };

  const selectEmployment = (value: any) => {
    handleSelectedEmployment(value);
    editEmployment(false);
  };

  return (
    <>
      <ModalEmploymentInformation
        isOpenModal={isEditingEmployment}
        handleSelectedEmployment={selectEmployment}
        stayOnClose={true}
      />
      <StyledHeadingContainer>
        <StyledTable>
          <colgroup>
            <StyledCol />
            <StyledCol />
          </colgroup>
          <StyledTr>
            <StyledTh>Emploment Status</StyledTh>
            <StyledTh>{jobTitle ? 'Role' : 'Reason'}</StyledTh>
            <StyledTh>Action</StyledTh>
          </StyledTr>
          <StyledTr>
            <StyledTd>{employmentStatus}</StyledTd>
            <StyledTd>{jobTitle || tlwReason}</StyledTd>
            <StyledTd>
              <StyledBtn onClick={updateEmployment}>
                <StyledIcon src={IC_EDIT_PAD} />
                <span>Update</span>
              </StyledBtn>
            </StyledTd>
          </StyledTr>
        </StyledTable>
      </StyledHeadingContainer>
    </>
  );
};

const StyledHeadingContainer = styled.div`
  display: flex;
  padding-bottom: ${props => props.theme.pxToRem(24)};
  flex-wrap: wrap;
`;

const StyledTable = styled.table`
  border-spacing: 0px;
  text-align: left;
  width: 100%;
`;

const StyledTh = styled.th`
  color: ${props => props.theme.colors.neutralGrey7};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledTd = styled.td`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.m};
`;

const StyledTr = styled.tr`
  ${StyledTh}:last-child {
    border-left: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
    font-size: ${props => props.theme.fontSizes.s};
    padding-left: ${props => props.theme.pxToRem(10)};
    width: ${props => props.theme.pxToRem(100)};
  }

  ${StyledTd}:last-child {
    border-left: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
    padding-left: ${props => props.theme.pxToRem(10)};
    width: ${props => props.theme.pxToRem(100)};
  }
`;

const StyledIcon = styled.img`
  display: inline-block;
  padding-right: ${props => props.theme.pxToRem(5)};
  vertical-align: middle;
  width: ${props => props.theme.pxToRem(18)};
`;

const StyledBtn = styled.button`
  background-color: ${props => props.theme.colors.neutralWhite};
  border: none;
  display: inline-block;
  cursor: pointer;
  span: last-child {
    color: ${props => props.theme.colors.primaryLightPurple};
    display: inline-block;
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.bold};
    text-decoration: underline;
    width: ${props => props.theme.pxToRem(18)};
  }
  :hover {
  }
`;

const StyledCol = styled.col`
  width: 45%;
`;
