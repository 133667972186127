import React from 'react';
import styled, { css } from 'styled-components';
import { RichTextBlocks } from 'mxp-utils';
import { Link, Heading } from 'components/atoms';
import { IC_OPEN_IN_NEW_PURPLE } from 'resources/images';
import { DefaultInlineLinkStyles, ExternalLinkStyles } from 'components/atoms/Link/Link';
import { ReactComponent as ArrowForward } from 'resources/images/arrow-forward.svg';

interface Props {
  testId: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  to?: string;
  titleStyle?: React.CSSProperties;
  className?: string;
  isCenterMembership?: boolean;
}

export const SubBlockBenefit: React.FC<Props> = ({
  testId,
  title = '',
  description = '',
  imageUrl = '',
  to = '',
  titleStyle,
  className,
  isCenterMembership = false,
}) => {
  const isExternal = RichTextBlocks.isExternal(to);

  return (
    <Wrapper data-testid={testId} className={className} isCenterMembership={isCenterMembership}>
      <StyledLink to={to || '#'} isExternal={isExternal} isBlockLink isCenterMembership={isCenterMembership}>
        {imageUrl && <StyledImage tabIndex={0} src={imageUrl} alt="icon" isCenterMembership={isCenterMembership} />}
        {title && <StyledHeading tabIndex={0} as="h2" dangerouslySetInnerHTML={{ __html: title }} style={titleStyle} />}
      </StyledLink>
      <MarkupWrapper isCenterMembership={isCenterMembership}>
        {description ? (
          <StyledParagraph tabIndex={0} dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          <>
            {isCenterMembership ? (
              <StyledCenterButtonLink to={to || '#'} isExternal={isExternal}>
                Go now
                <StyledArrowForward />
              </StyledCenterButtonLink>
            ) : (
              <StyledButtonLink to={to || '#'} isExternal={isExternal} isBlockLink>
                Go now
              </StyledButtonLink>
            )}
          </>
        )}
      </MarkupWrapper>
      {isCenterMembership && <StyledBottom />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isCenterMembership: boolean }>`
  position: relative;
  max-width: ${props => props.theme.pxToRem(361)};
  padding-bottom: ${props => props.theme.pxToRem(24)};
  border-radius: ${props => props.theme.pxToRem(10)};
  margin: 0 auto;
  background-color: ${props => props.theme.colors.neutralWhite};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);

  ${({ isCenterMembership }) =>
    !isCenterMembership &&
    css`
      height: ${props => props.theme.pxToRem(296)};
    `}

  .rich-text-external-link {
    ${ExternalLinkStyles}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${IC_OPEN_IN_NEW_PURPLE});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }

  .rich-text-internal-link {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(335)};
    height: ${props => props.theme.pxToRem(296)};
    margin-bottom: ${props => props.theme.pxToRem(15)};
  }
`;

const StyledLink = styled(Link)<{ isCenterMembership: boolean }>`
  position: static;
  display: flex;
  align-items: center;
  text-decoration: none;

  ${({ isCenterMembership }) =>
    isCenterMembership
      ? css`
          padding: ${props => props.theme.pxToRem(14)} ${props => props.theme.pxToRem(24)};
        `
      : css`
          text-decoration: none;
          padding: ${props => props.theme.pxToRem(24)};
          height: ${props => props.theme.pxToRem(125)};
          border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
        `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    height: ${props => props.theme.pxToRem(100)};
  }
`;

const MarkupWrapper = styled.div<{ isCenterMembership: boolean }>`
  overflow: hidden;

  ${({ isCenterMembership }) =>
    isCenterMembership
      ? css`
          padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(30)};
        `
      : css`
          text-align: center;
          height: ${props => props.theme.pxToRem(160)};
          padding: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(24)} 0;
        `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(16)} 0;
  }
`;

const StyledImage = styled.img<{ isCenterMembership: boolean }>`
  display: inline-block;
  width: ${props => props.theme.pxToRem(50)};
  margin: 0 ${props => props.theme.pxToRem(20)} 0 0;
  vertical-align: middle;

  ${({ isCenterMembership }) =>
    !isCenterMembership &&
    css`
      height: ${props => props.theme.pxToRem(50)};
    `}
`;

const StyledHeading = styled(Heading)`
  display: inline-block;
  overflow: hidden;
  margin: 0;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  vertical-align: middle;

  p {
    max-height: ${props => props.theme.pxToRem(64)};
    line-height: 1.33;
    word-break: break-word;
    word-wrap: break-word;
  }

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledParagraph = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};

  p {
    margin: 0;
    line-height: 1.5;

    ${props => props.theme.mediaQueries.tabletOnly} {
      line-height: 1.4;
    }
  }

  a {
    ${DefaultInlineLinkStyles};
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledButtonLink = styled(Link)`
  &&&& {
    width: ${props => props.theme.pxToRem(170)};
    height: ${props => props.theme.pxToRem(40)};
    color: ${props => props.theme.colors.neutralWhite} !important;
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.regular};
    background-color: ${props => props.theme.colors.primaryPurple} !important;
    margin-top: ${props => props.theme.pxToRem(15)};
    text-decoration: none;
    border-radius: ${props => props.theme.pxToRem(5)};
    padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(60)};

    &:hover {
      background-color: #491245 !important;
    }
  }
`;

const StyledCenterButtonLink = styled(Link)`
  &&&& {
    width: ${props => props.theme.pxToRem(170)};
    height: auto;
    color: ${props => props.theme.colors.neutralGrey8} !important;
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    text-decoration: none;
    padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(60)};
  }
`;

const StyledArrowForward = styled(ArrowForward)`
  display: inline-block;
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  margin: 0 ${props => props.theme.pxToRem(20)} 0 ${props => props.theme.pxToRem(8)};
  vertical-align: middle;
`;

const StyledBottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: ${props => `${props.theme.pxToRem(18)}`};
  height: ${props => props.theme.pxToRem(6)};
  border-radius: ${props => `0 0 ${props.theme.pxToRem(8)} ${props.theme.pxToRem(8)}`};
  background-image: linear-gradient(to right, #672d89 3%, rgba(154, 32, 94, 0.8));
  margin: ${props => `0 ${props.theme.pxToRem(-18)} 0`};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;
