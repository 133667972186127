import React from 'react';
import styled from 'styled-components';
import { ButtonLink, ButtonEnums } from 'components/atoms';
import { PromotionalBanner } from 'components/molecules/PromotionalBanner/PromotionalBanner';
import { StyledDivider } from 'components/pages/PageToolkit/StyledDivider';
import { getPromotionsParams } from 'utils/index';
import { handleEvent, EXTERNAL_LINK_EVENT } from 'utils/Analytics';

interface Props {
  item: any;
  showDivider?: boolean;
  idx: number;
  isFullWidth?: boolean;
  testId: string;
}

export const RenderPromotionalComponent: React.FC<Props> = React.memo(
  ({ item, showDivider = false, idx, isFullWidth = false, testId }) => {
    const { header, summary, hasButton, to, label }: State.PromotionalComponentParams = getPromotionsParams(item);
    const onclick = React.useCallback(() => {
      handleEvent(
        {
          text: `toolkit-button:${label}:${to}`,
          href: to,
        },
        EXTERNAL_LINK_EVENT
      );
    }, [label, to]);
    if (!header && !summary && !hasButton) return null;
    return (
      <div key={`promo-component-${idx}`}>
        <StyledMargin>
          <StyledPromotionalBanner
            isHighlighted={item.highlighted}
            isFullWidth={!item.highlighted && isFullWidth}
            title={header}
            description={summary}
            testId={testId}
            titleTestId="pc-header"
            descriptionTestId="pc-summary"
            showBackground={item.highlighted}
            isLeftAligned={!item.highlighted}
            firstButton={
              hasButton && (
                <ButtonLink
                  testId="left-promotions-button-link"
                  variant={ButtonEnums.variants.primary}
                  size={ButtonEnums.sizes.medium}
                  to={to}
                  onClick={onclick}
                  fluid
                >
                  {label}
                </ButtonLink>
              )
            }
          />
        </StyledMargin>
        {showDivider && <StyledDivider />}
      </div>
    );
  }
);

const StyledPromotionalBanner = styled(PromotionalBanner)<{ isHighlighted: boolean; isFullWidth: boolean }>`
  padding: 0;

  ${props =>
    !props.isHighlighted &&
    !props.isFullWidth &&
    `
    margin: 0 ${props.theme.pxToRem(40)} ${props.theme.pxToRem(40)} 0;
  `}

  ${props =>
    props.isHighlighted &&
    `
    padding: ${props.theme.pxToRem(32)} 10%;
    margin-bottom: ${props.theme.pxToRem(40)};

    ${props.theme.mediaQueries.mobileOnly} {
      padding: ${props.theme.pxToRem(44)} 10%;
      margin: 0 ${props.theme.pxToRem(-18)} ${props.theme.pxToRem(40)};
    }
  `}

  ${props =>
    props.isFullWidth &&
    `
    margin-right: 10%;
    margin-bottom: ${props.theme.pxToRem(40)};
  `}

  p {
    :first-of-type {
      margin-bottom: ${props => props.theme.pxToRem(8)};
    }

    :last-of-type {
      margin-bottom: ${props => props.theme.pxToRem(24)};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.s};
      line-height: 1.5;
    }
  }

  &&&&&&&& {
    a,
    button {
      max-width: ${props => props.theme.pxToRem(225)};

      ${props => props.theme.mediaQueries.mobileOnly} {
        max-width: ${props => props.theme.pxToRem(350)};
      }
    }
  }
`;

const StyledMargin = styled.div`
  margin: 2% 7%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 2%;
  }
`;
