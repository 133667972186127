import React from 'react';
import styled, { css } from 'styled-components';
import { MembershipTypes } from 'mxp-schemas';
import { Lock } from 'components/atoms/svg';

interface Props {
  status: MembershipTypes.CimaExamCardStatus;
  isGatewayNotPassed?: boolean;
  isExempted?: boolean;
}

export const ExamCardNotification: React.FC<Props> = ({ status, isGatewayNotPassed = false, isExempted = false }) => {
  const showNotificationStatus = [
    MembershipTypes.CimaExamCardStatus.LOCKED,
    MembershipTypes.CimaExamCardStatus.EPA_LOCKED,
    MembershipTypes.CimaExamCardStatus.EXEMPT,
    MembershipTypes.CimaExamCardStatus.PASSED,
    MembershipTypes.CimaExamCardStatus.RESULTS_PENDING,
    MembershipTypes.CimaExamCardStatus.FLP_COMPLETE,
    MembershipTypes.CimaExamCardStatus.FAILED,
    MembershipTypes.CimaExamCardStatus.EPA_FAILED,
  ].includes(status);

  return (
    <>
      {showNotificationStatus && (
        <StyledStatus status={status} isGatewayNotPassed={isGatewayNotPassed}>
          {(() => {
            if (isExempted) return 'EXEMPT';
            if (
              [MembershipTypes.CimaExamCardStatus.FAILED, MembershipTypes.CimaExamCardStatus.EPA_FAILED].includes(
                status
              )
            ) {
              return 'IN PROGRESS';
            }
            if (status === MembershipTypes.CimaExamCardStatus.EXEMPT) return 'EXEMPT';
            if (status === MembershipTypes.CimaExamCardStatus.RESULTS_PENDING) return 'RESULTS PENDING';
            if (
              status === MembershipTypes.CimaExamCardStatus.PASSED ||
              status === MembershipTypes.CimaExamCardStatus.FLP_COMPLETE
            ) {
              return 'COMPLETE';
            }
          })()}

          {!isExempted &&
            (status === MembershipTypes.CimaExamCardStatus.LOCKED ||
              status === MembershipTypes.CimaExamCardStatus.EPA_LOCKED ||
              isGatewayNotPassed) && (
              <StyledLock>
                <StyledLockIcon />
                LOCKED
              </StyledLock>
            )}
        </StyledStatus>
      )}
    </>
  );
};

interface StyledStatusProps {
  status: MembershipTypes.CimaExamCardStatus;
  isGatewayNotPassed: boolean;
}

const StyledStatus = styled.span<StyledStatusProps>`
  width: auto;
  box-sizing: border-box;
  font-size: ${props => props.theme.fontSizes.xxs};
  padding: ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
    ${props => props.theme.pxToRem(10)};
  text-align: center;
  border-radius: ${props => props.theme.pxToRem(4)};
  height: ${props => props.theme.pxToRem(25)};
  font-weight: ${props => props.theme.fontWeights.medium};
  background-color: ${props => props.theme.colors.neutralGrey3};

  ${({ status, isGatewayNotPassed }) =>
    status === MembershipTypes.CimaExamCardStatus.LOCKED ||
    status === MembershipTypes.CimaExamCardStatus.EXEMPT ||
    status === MembershipTypes.CimaExamCardStatus.EPA_LOCKED ||
    isGatewayNotPassed
      ? css`
          color: ${props => props.theme.colors.neutralGrey8};
        `
      : css`
          color: ${props => props.theme.colors.neutralWhite};
        `}

  ${({ status }) =>
    [
      MembershipTypes.CimaExamCardStatus.RESULTS_PENDING,
      MembershipTypes.CimaExamCardStatus.FAILED,
      MembershipTypes.CimaExamCardStatus.EPA_FAILED,
    ].includes(status) &&
    css`
      background-color: ${props => props.theme.colors.tertiaryYellow} !important;
    `}

	

	${({ status }) =>
    (status === MembershipTypes.CimaExamCardStatus.PASSED ||
      status === MembershipTypes.CimaExamCardStatus.FLP_COMPLETE) &&
    css`
      background-color: #00857d !important;
    `}
`;

const StyledLockIcon = styled(Lock)`
  height: ${props => props.theme.pxToRem(18)};
  width: ${props => props.theme.pxToRem(18)};
`;

const StyledLock = styled.div`
  display: flex;
`;
