import { Routes } from 'constants/index';
import { productActionNames } from './constants';
import { productPageItemSelector, productItemPrices, productSKU, relatedProductSelector } from './selectors';
import { extractProductPayload, getPageNameAccessor } from 'utils/Analytics/helpers';
import { UTAG_TYPES, EventDescribor } from 'utils/Analytics/types';

const isRelatedContent = (state: State.Root) => {
  const relatedContent = relatedProductSelector(state);
  const contentLength = relatedContent?.length || 0;
  const payload = extractProductPayload(relatedContent, {
    total: contentLength,
    from: 1,
    to: contentLength,
    perPage: contentLength,
    pageNumber: 1,
  });

  return payload;
};

export const ProductAnalyticsConsts: { [key: string]: EventDescribor } = {
  [productActionNames.GET_PRODUCT_ITEM]: {
    name: 'product_view',
    route: Routes.PRODUCT_PAGE,
    accessors: [
      ...getPageNameAccessor(),
      {
        name: 'product_name',
        path: 'name',
        processor: (a, b) => [productPageItemSelector(b)?.name],
      },
      {
        name: 'product_category',
        path: 'productType',
        processor: (a, b) => [productPageItemSelector(b)?.productType],
      },
      {
        name: 'product_id',
        path: 'productId',
        processor: (a, b) => [productPageItemSelector(b)?.productId],
      },
      {
        name: 'product_sku',
        path: 'sku',
        processor: (a, b) => {
          const item = productSKU(b);
          return [item || ''] || [];
        },
      },

      {
        name: 'product_price',
        path: 'pricing',
        processor: (a, b) => {
          return [productItemPrices(b) || 0];
        },
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
  [productActionNames.GET_RELATED_CONTENT]: {
    name: 'related_product_view',
    route: Routes.PRODUCT_PAGE,
    accessors: [
      ...getPageNameAccessor(),
      {
        name: 'product_name',
        path: 'name',
        processor: (a, b) => {
          return isRelatedContent(b)?.name || '';
        },
      },
      {
        name: 'product_category',
        path: 'productType',
        processor: (a, b) => {
          return isRelatedContent(b)?.category || '';
        },
      },
      {
        name: 'product_id',
        path: 'productId',
        processor: (a, b) => {
          return isRelatedContent(b)?.id || '';
        },
      },
      {
        name: 'product_location',
        path: 'location',
        processor: (a, b) => {
          return isRelatedContent(b)?.location || '';
        },
      },

      {
        name: 'product_price',
        path: 'pricing',
        processor: (a, b) => {
          return isRelatedContent(b)?.price || 0;
        },
      },
    ],
    type: UTAG_TYPES.VIEW,
  },
};
