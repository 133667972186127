export const caseInitialState: State.Case = {
  CaseData: {
    accountId: '',
    contactId: '',
    addressLine1: '',
    city: '',
    zipcode: '',
    region: 'USA',
    aicpaNumber: '',
    state: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    aicpaMember: '',
    cimaMember: '',
    requestData: '',
    certification: 'false',
    description: '',
  },
  error: false,
  loading: false,
};

export enum CaseActionNames {
  LOADING = 'case/LOADING',
  ADDRESS_VALIDATION = 'case/ADDRESS_VALIDATION',
  GDPR = 'case/GDPR',
  CALIFORNIA_LAW = 'case/CALIFORNIA_LAW',
  CALIFORNIA_LAW_EMAIL = 'case/SEND_CALIFORNIA_LAW_EMAIL',
  SEND_GDPR_EMAIL = 'case/SEND_GDPR_EMAIL',
  GET_EXISTING_CASE = 'case/GET_EXISTING_CASE',
}
