import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  details: string;
}

export const Title: React.FC<Props> = ({ title, details }) => (
  <>
    <StyledSubtitle>{title}</StyledSubtitle>
    <StyledNormalText>{details}</StyledNormalText>
  </>
);

const StyledSubtitle = styled.div`
  font-size: ${props => props.theme.fontSizes.l};
  margin-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledNormalText = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  margin-bottom: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.light};
`;
