// @ts-ignore
export const numberToWords = (num: number): string => {
  const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  const teens = [
    '',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];
  const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  function convertChunk(chunk: number): string {
    const chunkHundreds = Math.floor(chunk / 100);
    const chunkRemainder = chunk % 100;

    let chunkResult = '';

    if (chunkHundreds > 0) {
      chunkResult += `${ones[chunkHundreds]} hundred`;
      if (chunkRemainder > 0) {
        chunkResult += ' and ';
      }
    }

    if (chunkRemainder === 0) {
      // Do nothing
    } else if (chunkRemainder < 10) {
      chunkResult += ones[chunkRemainder];
    } else if (chunkRemainder < 20) {
      chunkResult += teens[chunkRemainder - 10];
    } else {
      const chunkTen = Math.floor(chunkRemainder / 10);
      const chunkOne = chunkRemainder % 10;
      chunkResult += `${tens[chunkTen]}${chunkOne > 0 ? ` ${ones[chunkOne]}` : ''}`;
    }

    return chunkResult;
  }

  if (num === 0) {
    return 'zero';
  }

  const billions = Math.floor(num / 1000000000);
  const millions = Math.floor((num % 1000000000) / 1000000);
  const thousands = Math.floor((num % 1000000) / 1000);
  const remainder = num % 1000;

  let result = '';

  if (billions > 0) {
    result += `${convertChunk(billions)} billion${millions + thousands + remainder > 0 ? ' ' : ''}`;
  }

  if (millions > 0) {
    result += `${convertChunk(millions)} million${thousands + remainder > 0 ? ' ' : ''}`;
  }

  if (thousands > 0) {
    result += `${convertChunk(thousands)} thousand${remainder > 0 ? ' ' : ''}`;
  }

  if (remainder > 0) {
    result += convertChunk(remainder);
  }

  return result;
};
