import { createSelector } from 'reselect';
import { ProfileListSortByOptions, profileListSortByOptions, ProfileListSortByOption } from '../../constants';
import { paginationInfoSelector } from 'modules/layouts/selectors';
import { isInHistoryRangeReceipts } from 'modules/products/helpers';
import { emptyArray } from 'utils';
import { Invoices, ZuoraTypes } from 'mxp-schemas';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.receipts,
  (receipts: State.Receipts): State.Receipts => receipts
);

export const receiptsListSelector = createSelector(
  rootSelector,
  (receipts: State.Receipts): State.InvoiceTableRow[] | null => receipts.receiptsList
);

export const zuoraAccountsSelector = createSelector(
  rootSelector,
  (receipts: State.Receipts): string | null => receipts.downloadingId
);

export const cimaReceiptsListSelector = createSelector(
  [receiptsListSelector],
  (receipts: State.InvoiceTableRow[] | null): State.InvoiceTableRow[] =>
    receipts?.filter((receipt: State.InvoiceTableRow) => receipt.legalEntity === ZuoraTypes.LegalEntity.CIMA) || []
);

export const associationReceiptsListSelector = createSelector(
  [receiptsListSelector],
  (receipts: State.InvoiceTableRow[] | null): State.InvoiceTableRow[] =>
    receipts?.filter((receipt: State.InvoiceTableRow) => receipt.legalEntity === ZuoraTypes.LegalEntity.ASSOCIATION) ||
    []
);

export const receiptsListFetchedSelector = createSelector(
  rootSelector,
  (receipts: State.Receipts): boolean => receipts.receiptsListFetched
);

export const downloadingIdSelector = createSelector(
  rootSelector,
  (receipts: State.Receipts): string | null => receipts.downloadingId
);

export const receiptSelector = createSelector(
  [receiptsListSelector, downloadingIdSelector],
  (receipts: State.InvoiceTableRow[] | null, receiptId: string | null): State.InvoiceTableRow | null =>
    (receipts && receipts.find((item: State.InvoiceTableRow) => item.id === receiptId)) || null
);

export const downloadingErrorSelector = createSelector(
  rootSelector,
  (receipts: State.Receipts): boolean => receipts.downloadingError
);

export const historyOptionsReceiptsSelector = createSelector(
  [receiptsListSelector],
  (receipts: State.InvoiceTableRow[] | null): ProfileListSortByOptions => {
    const nowD = new Date();
    const toFilter = receipts || emptyArray;
    const out: ProfileListSortByOptions = { ...profileListSortByOptions };
    Object.values(profileListSortByOptions).forEach((option: ProfileListSortByOption) => {
      const hasReceiptInRange = toFilter.some((receipt: State.InvoiceTableRow) => {
        return isInHistoryRangeReceipts(receipt, option.value, nowD);
      });
      out[option.text] = { ...profileListSortByOptions[option.text], disabled: !hasReceiptInRange };
    });

    return out;
  }
);

export const dueReceiptsListSelector = createSelector(
  [receiptsListSelector],
  (receipts: State.InvoiceTableRow[] | null): State.InvoiceTableRow[] =>
    receipts?.filter((receipt: State.InvoiceTableRow) => receipt.status !== Invoices.InvoiceStatus.PAID) || []
);

export const filteredDueReceiptsSelector = createSelector(
  [dueReceiptsListSelector, paginationInfoSelector],
  (receipts: State.InvoiceTableRow[] | null, paginationInfo: State.PaginationInfo): State.InvoiceTableRow[] | null => {
    const nowD = new Date();
    const toFilter = receipts || emptyArray;
    const out = toFilter.filter((receipt: State.InvoiceTableRow) => {
      return isInHistoryRangeReceipts(receipt, paginationInfo.filtering.history, nowD);
    });
    return out;
  }
);

export const paginatedDueReceiptsListSelector = createSelector(
  [filteredDueReceiptsSelector, paginationInfoSelector],
  (receipts: State.InvoiceTableRow[] | null, paginationInfo: State.PaginationInfo): State.InvoiceTableRow[] | null => {
    const out = receipts?.slice(paginationInfo.pagination.from - 1, paginationInfo.pagination.to) || null;
    return out;
  }
);

export const paidReceiptsListSelector = createSelector(
  [receiptsListSelector],
  (receipts: State.InvoiceTableRow[] | null): State.InvoiceTableRow[] =>
    receipts?.filter((receipt: State.InvoiceTableRow) => receipt.status === Invoices.InvoiceStatus.PAID) || []
);

export const filteredPaidReceiptsSelector = createSelector(
  [paidReceiptsListSelector, paginationInfoSelector],
  (receipts: State.InvoiceTableRow[] | null, paginationInfo: State.PaginationInfo): State.InvoiceTableRow[] | null => {
    const nowD = new Date();
    const toFilter = receipts || emptyArray;
    const out = toFilter.filter((receipt: State.InvoiceTableRow) => {
      return isInHistoryRangeReceipts(receipt, paginationInfo.filtering.history, nowD);
    });
    return out;
  }
);

export const paginatedPaidReceiptsListSelector = createSelector(
  [filteredPaidReceiptsSelector, paginationInfoSelector],
  (receipts: State.InvoiceTableRow[] | null, paginationInfo: State.PaginationInfo): State.InvoiceTableRow[] | null => {
    const out = receipts?.slice(paginationInfo.pagination.from - 1, paginationInfo.pagination.to) || null;
    return out;
  }
);
