import React from 'react';
import styled from 'styled-components/macro';
import { emptyObject, formatPrice, fromCentsFormat, getPath, Scroller } from 'utils';
import { Product } from 'mxp-schemas';
import { BundleItemsSelectionInfo } from 'modules/products/selectors';
import { notAvailableTxt } from './QuantityWarnings';
import { Link } from 'components/atoms';
import { Routes } from 'constants/index';

interface Props {
  bundleItem: Product.ProductBundle;
  bundleItemsSelectionInfo?: BundleItemsSelectionInfo[] | null;
  isExistingCartItem?: boolean;
  index: number;
  bundleBlockRefs?: Common.BundleElement[];
  mobile?: boolean;
  handleToggleSummary: () => void;
  firstBundleItemRef?: any;
  isUserSuspendedByEthics?: boolean;
}

export const BundleItem: React.FC<Props> = ({
  bundleItem,
  bundleItemsSelectionInfo,
  isExistingCartItem,
  index,
  bundleBlockRefs,
  mobile,
  handleToggleSummary,
  firstBundleItemRef,
  isUserSuspendedByEthics,
}) => {
  const scrollToBundleComponentHandler = React.useCallback(() => {
    if (isExistingCartItem) return;

    const scrollToBlock = bundleBlockRefs?.find((block: Common.BundleElement) => block.id === bundleItem.productId);
    Scroller.scrollToTarget(scrollToBlock?.blockRef, { useWindow: true });
    if (mobile) {
      handleToggleSummary();
    }
  }, [bundleBlockRefs, handleToggleSummary, isExistingCartItem, mobile, bundleItem]);

  const {
    selectedSku,
    hasMultiple,
    selectionValue,
    useFormat,
    priceWithDiscountsForDisplay,
    isAvailableForSale,
    isOutOfStock,
    isSubscribed,
    // priceFull,
    priceFinalWithOutMembershipDiscount,
  } = bundleItemsSelectionInfo?.[index] || (emptyObject as any);
  const currency = bundleItemsSelectionInfo?.[0]?.currency || '';
  const isCourse: boolean = bundleItem.productType === Product.ProductType.COURSE;
  const unselectedLabel = useFormat ? 'Select format' : 'Select date';
  const changeSelectionLabel = useFormat ? 'Change format' : 'Change date';
  //suspended ethicStatus dont get discount even have membership only bundle discount for bundles
  const suspendedFinalPrice = formatPrice(fromCentsFormat(priceFinalWithOutMembershipDiscount || 0), currency);
  const refToItemLink = hasMultiple ? (selectedSku || isExistingCartItem ? changeSelectionLabel : unselectedLabel) : '';
  const renderManagePurchases = (): React.ReactNode => (
    <ManagePurchasesMessage>
      You have an active {isCourse ? 'course' : 'subscription'} for this product. You can always manage it in your{' '}
      <Link testId="purchase-summary-link-to-purchases-page" to={getPath(Routes.PROFILE_PURCHASES)}>
        Profile
      </Link>
    </ManagePurchasesMessage>
  );

  const renderItemUnavailable = (): React.ReactNode => (
    <BundleItemUnavailable data-testid={'not-available-label'}>{notAvailableTxt}</BundleItemUnavailable>
  );

  const renderVariantSelection = (): React.ReactNode => (
    <>
      {selectionValue && (
        <BundleVariantSelectionValue data-testid={'bundle-item-selection-value'}>
          {selectionValue}
        </BundleVariantSelectionValue>
      )}

      {refToItemLink && (
        <BundleSelectLabel
          data-testid={`bundle-item-${refToItemLink.replace(' ', '-').toLowerCase()}`}
          onClick={scrollToBundleComponentHandler}
          disabled={isExistingCartItem}
        >
          {refToItemLink}
        </BundleSelectLabel>
      )}
    </>
  );

  const renderOutOfStock = (): React.ReactNode => (
    <BundleItemUnavailable data-testid={'bundle-item-out-of-stock'}>Currently out of stock</BundleItemUnavailable>
  );

  const renderDetails = (): React.ReactNode => {
    if (isSubscribed) return renderManagePurchases();
    if (isOutOfStock) return renderOutOfStock();
    if (isAvailableForSale) {
      return (
        <>
          <FlexWrap>{renderVariantSelection()}</FlexWrap>
          <BundleItemPrice data-testid={'bundle-item-price'}>
            {!isUserSuspendedByEthics ? priceWithDiscountsForDisplay : suspendedFinalPrice}
          </BundleItemPrice>
        </>
      );
    }
    return renderItemUnavailable();
  };

  const addFirstBundleItemRef = mobile && index === 0;

  return (
    <div ref={addFirstBundleItemRef ? firstBundleItemRef : undefined}>
      <BundleItemWrap data-testid={`bundle-item-wrap-${bundleItem.slug}`} key={bundleItem.productId}>
        <BundleItemTitle data-testid={'bundle-item-title'}>{bundleItem.name}</BundleItemTitle>
        {renderDetails()}
      </BundleItemWrap>
      <HorizontalDivider />
    </div>
  );
};

const BundleItemWrap = styled.div`
  padding: ${props => props.theme.pxToRem(20)};
`;

const BundleItemTitle = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BundleSelectLabel = styled.div<{ disabled?: boolean }>`
  color: ${props => (props.disabled ? props.theme.colors.neutralGrey5 : props.theme.colors.primaryPurple)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  text-decoration: underline;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const BundleVariantSelectionValue = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: ${props => props.theme.pxToRem(20)};
`;
const BundleItemPrice = styled(BundleVariantSelectionValue)`
  margin-top: ${props => props.theme.pxToRem(8)};
`;

const HorizontalDivider = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey3};
  width: 100%;
  height: ${props => props.theme.pxToRem(1)};
`;

const BundleItemUnavailable = styled.div`
  color: ${props => props.theme.colors.interfaceRed};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-top: ${props => props.theme.pxToRem(4)};
`;

const ManagePurchasesMessage = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
`;
