import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '../Button/Button';
import { useSelector } from 'react-redux';
import { areAllTruthy, getPath } from 'utils';
import { handleEvent, DOWNLOAD_EVENT } from 'utils/Analytics';
import { userNameSelector } from 'modules/user/selectors';
import { lineItemsSelector, itemsPriceDataCartSelector } from 'modules/cart/selectors';
import { productsListDataLineItemsSelector } from 'modules/products/selectors';
import { pdfDownloader } from './PDFDownloader';
import { Product, Cart } from 'mxp-schemas';
import { useLocation, matchPath } from 'react-router';
import { Routes } from 'constants/index';
import { User as UserUtils } from 'mxp-utils';
import { ReactComponent as IconFileDownload } from 'resources/images/ic-file-download.svg';

interface Data {
  SKU: string;
  Description: string;
  Unit_Price: string;
  Quantity: string;
  Amount: string;
}

interface Props {
  totalPriceInfo?: Cart.CartTotalPriceInfo;
  appliedPromoCode?: string;
  hasItsOwnStyling?: boolean;
  isDisplayedForOtherPage?: boolean;
  totalDiscount?: string;
  totalPriceAmount?: string;
  withIcon?: boolean;
}

interface CartInfo extends State.LineItem {
  itemPriceInfo?: Cart.CartItemPriceInfo;
}
export const DownloadPaymentSummary: React.FC<Props> = ({
  totalPriceInfo,
  appliedPromoCode,
  hasItsOwnStyling = false,
  isDisplayedForOtherPage = false,
  totalDiscount,
  totalPriceAmount,
  withIcon,
}) => {
  const { pathname } = useLocation();
  const isOrderConfirmationPage = useMemo(() => {
    return (
      Boolean(matchPath(pathname, { path: getPath(Routes.CHECKOUT_PAGE), exact: true, strict: false })) &&
      isDisplayedForOtherPage
    );
  }, [pathname, isDisplayedForOtherPage]);

  const fullName = useSelector(userNameSelector);
  const cartItems = useSelector(lineItemsSelector);
  const itemsPriceInfo = useSelector(itemsPriceDataCartSelector);
  const productItems = useSelector(productsListDataLineItemsSelector);

  const lineItems: CartInfo[] = useMemo(() => {
    const productList = isOrderConfirmationPage && productItems ? productItems : cartItems;
    return productList.reduce((total: CartInfo[], item: State.LineItem) => {
      total.push({
        ...item,
        itemPriceInfo: itemsPriceInfo?.find(product => product?.productId === item.productId),
      });
      return total;
    }, []);
  }, [isOrderConfirmationPage, productItems, cartItems, itemsPriceInfo]);

  const currencySign: string = useMemo(
    () =>
      lineItems?.[0]?.totalPrice?.currencyCode === Product.ProductCurrencyLabel.GBP
        ? Product.ProductCurrencySign.POUND
        : Product.ProductCurrencySign.DOLLAR,
    [lineItems]
  );

  const handleClick = useCallback(() => {
    handleEvent({ filename: 'Payment-Summary.pdf' }, DOWNLOAD_EVENT);

    const description = (desc: string, type: string) => {
      if (type === Product.ProductType.CONTRIBUTION) return `Donations \n - ${desc}`;
      return desc;
    };

    // 1. Get all items in the cart
    const tempRowData =
      (lineItems.length &&
        lineItems.map((item: CartInfo) => {
          const proratedPrice = !isOrderConfirmationPage
            ? parseFloat((item?.itemPriceInfo?.formattedProratedPrice || '0').replace(/,/g, ''))
            : 0;

          return {
            type: item?.productType,
            SKU: item?.variant?.sku,
            Description:
              item?.productType === Product.ProductType.MEMBERSHIP
                ? `Membership Type \n - ${item.name} \nMembership Tier \n - ${item.variant?.attributes.tierName}`
                : item.name,

            Unit_Price: UserUtils.conditionalFunction(
              proratedPrice,
              proratedPrice,
              UserUtils.conditionalFunction(
                areAllTruthy(isOrderConfirmationPage, item.totalContractedValue),
                // we can get the prorated membership price/amount from totalContractedValue if proratedPrice is 0/undefined
                item.totalContractedValue,
                UserUtils.conditionalFunction(item.totalPrice.centAmount / 100, item.totalPrice.centAmount / 100, 0)
              )
            ),
            Quantity: item.quantity,
          };
        })) ||
      [];

    const tempMergingStorage: any[] = [];

    // 2. Merge all item with the same product type
    tempRowData.forEach((item: any) => {
      const objectData = tempMergingStorage.find(data => data.type === item.type);
      if (objectData) {
        const index = tempMergingStorage.findIndex(data => data.type === item.type);
        tempMergingStorage[index] = {
          ...objectData,
          Description: `${objectData.Description}\n - ${item.Description}`,
          Unit_Price: `${objectData.Unit_Price}\n${currencySign} ${item.Unit_Price} \n`,
          Quantity: `${objectData.Quantity}\n${item.Quantity} \n`,
          Amount: `${objectData.Amount}\n${currencySign} ${item.Quantity * item.Unit_Price} \n`,
        };
      } else {
        tempMergingStorage.push({
          type: item?.type,
          SKU: item?.SKU,
          Description: description(item.Description, item.type),
          Unit_Price: `\n${currencySign} ${item.Unit_Price} \n`,
          Quantity: `\n${item.Quantity}\n\n`,
          Amount: `\n${currencySign} ${item.Quantity * item.Unit_Price}\n`,
        });
      }
    });

    const rowData: Data[] = [];

    // 3. Configure Data for table row
    tempMergingStorage.forEach(data => {
      return rowData.push({
        SKU:
          data.type !== Product.ProductType.CONTRIBUTION && data.type !== Product.ProductType.MEMBERSHIP
            ? `${data.SKU}`
            : data.SKU,
        Description:
          data.type !== Product.ProductType.CONTRIBUTION && data.type !== Product.ProductType.MEMBERSHIP
            ? `\n${data.Description}`
            : data.Description,
        Unit_Price: data.Unit_Price,
        Quantity: data.Quantity,
        Amount: data.Amount,
      });
    });

    // 4. Create cart summary text
    const summaryText = `${appliedPromoCode ? `Promo Code: ${appliedPromoCode}` : ''} \nTotal discount: ${
      isOrderConfirmationPage && totalDiscount ? totalDiscount : totalPriceInfo?.formattedTotalDiscountAmount
    }\nTotal(taxes included): ${
      isOrderConfirmationPage && totalPriceAmount ? totalPriceAmount : totalPriceInfo?.formattedTotalPrice
    }`;

    // 5. Create table header
    const headersData = ['SKU', 'Description', 'Unit_Price', 'Quantity', 'Amount'];

    // 6. Download PDF file
    pdfDownloader('Payment-Summary', fullName, { headersData, rowData }, summaryText);
  }, [
    fullName,
    totalPriceInfo,
    lineItems,
    appliedPromoCode,
    totalDiscount,
    isOrderConfirmationPage,
    totalPriceAmount,
    currencySign,
  ]);

  return (
    <>
      {hasItsOwnStyling ? (
        <span onClick={handleClick}>Download Payment Summary</span>
      ) : (
        <StyleButton testId={'id-for-download-payment-pdf'} onClick={handleClick}>
          {withIcon && <StyledIconFileDownload />}
          Download Payment Summary
        </StyleButton>
      )}
    </>
  );
};

const StyleButton = styled(Button)<ButtonProps>`
  &&&& {
    display: block;
    margin-bottom: ${props => props.theme.pxToRem(20)};
    background-color: unset;
    color: ${props => props.theme.colors.neutralBlack};
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.medium};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledIconFileDownload = styled(IconFileDownload)`
  &&&& {
    vertical-align: middle;
  }
`;
