import React, { useState } from 'react';
import styled from 'styled-components';
import download from 'downloadjs';
import { Button, ButtonEnums } from 'components/atoms';
import { PromotionalBanner } from 'components/molecules/PromotionalBanner/PromotionalBanner';
import { DOWNLOAD_EVENT, handleEvent } from 'utils/Analytics';
import { ReactComponent as GetApp } from 'resources/images/icon-dev-ic-get-app.svg';

interface DownloadItemProps {
  name: string;
  fileNames: string[];
  buttonText: string;
  fileSize: string;
  fileLink: string;
  isLocked?: boolean;
}

export const DownloadItem: React.FC<DownloadItemProps> = React.memo(
  ({ name, fileNames, buttonText, fileLink, fileSize, isLocked = false }) => {
    const [fileLoading, setFileLoading] = useState(false);
    const fileName: string = fileNames[0];
    const handleDownloadClick = React.useCallback((): void => {
      handleEvent({ filename: fileName }, DOWNLOAD_EVENT);
      setFileLoading(true);
      fetch(fileLink)
        .then(response => response.blob())
        .then(result => {
          download(result, fileName);
          setFileLoading(false);
        })
        .catch(() => {
          setFileLoading(false);
        });
    }, [fileLink, fileName]);
    return (
      <StyledPromotionalBanner
        title={`Download the ${name}`}
        description={fileNames && `File name: ${fileNames.join(', ')}`}
        testId="download-component"
        titleTestId="download-name"
        descriptionTestId="download-files"
        isMobileFullWidth
        showBorder
        showBackground
        firstButton={
          <StyledDownloadButton
            testId="download-button"
            variant={ButtonEnums.variants.primary}
            size={ButtonEnums.sizes.medium}
            icon={<GetApp />}
            iconPosition={ButtonEnums.iconPosition.left}
            onClick={handleDownloadClick}
            loading={fileLoading}
            disabled={isLocked || fileLoading}
            bordered
            fluid
          >
            {buttonText} <StyledFileSize>({fileSize})</StyledFileSize>
          </StyledDownloadButton>
        }
      />
    );
  }
);

const StyledPromotionalBanner = styled(PromotionalBanner)`
  padding: ${props => props.theme.pxToRem(32)} ${props => props.theme.pxToRem(40)} ${props => props.theme.pxToRem(38)};
  margin-bottom: ${props => props.theme.pxToRem(25)};

  p:first-of-type {
    margin-bottom: ${props => props.theme.pxToRem(4)};
    font-weight: ${props => props.theme.fontWeights.medium};
    line-height: 1.33;
  }

  p:last-of-type {
    margin-bottom: ${props => props.theme.pxToRem(20)};
    font-size: ${props => props.theme.fontSizes.xs};
    line-height: 1.57;
  }

  &&&&&&&& {
    a,
    button {
      max-width: ${props => props.theme.pxToRem(260)};
      height: auto;
      min-height: ${props => props.theme.pxToRem(40)};
      word-wrap: break-word;
      word-break: break-all;
    }
  }
`;

const StyledFileSize = styled.span`
  white-space: nowrap;
`;

const StyledDownloadButton = styled(Button)`
  &&&& {
    ${props => props.theme.mediaQueries.computerMin} {
      &:focus {
        background-color: ${props => props.theme.colors.primaryLightPurple};
        color: ${props => props.theme.colors.neutralWhite};
        ${props =>
          props.bordered ? `border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.primaryLightPurple}` : ''};
      }
    }
  }
`;
