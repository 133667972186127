import { Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components';
import React, { memo, useState, useEffect, useCallback } from 'react';
import { usePageContext } from 'components/pages/PagePracticalExperienceRequirement/PageContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePracticalExperienceRequirement,
  getPracticalExperienceRequirement,
  clearNewEmploymentData,
} from 'modules/membership/actions';
import { useParams } from 'react-router';
import {
  practicalExperienceRequirementSelector,
  practicalExpReqEmploymentRecordSelector,
} from 'modules/membership/selectors';
import { MembershipTypes } from 'mxp-schemas';
import moment from 'moment-timezone';
import { Routes } from 'constants/index';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
interface Props {
  isOpen: boolean;
  handleModalProceed: () => void;
  variant?: ModalVariants;
  PERmodule?: any;
}

export enum ModalVariants {
  EMPLOYMENT_SUBMIT = 'employment-submit',
  SKILLS_SUBMIT = 'skills-submit',
  ACTIVITIES_SUBMIT = 'activities-submit',
  WITHDRAW_SUBMISSION = 'withdraw-submission',
  DECLINE_REVIEW = 'decline-review',
  REQUEST_INFO = 'request-info',
  CHANGE_SUPERVISOR = 'change-supervisor',
  REMOVE = 'remove',
}

type employmentType = MembershipTypes.PracticalExperienceRequirementEmployment;
const statusType = MembershipTypes.PracticalExperienceRequirementStatus;

export const PracticalExpReqModal: React.FC<Props> = memo(({ isOpen, handleModalProceed, variant, PERmodule }) => {
  const { perId } = useParams() as any;

  const dispatch = useDispatch();
  const { portfolio, newEmployment, formDataEmployment } = useSelector(practicalExperienceRequirementSelector);
  const formData =
    useSelector(practicalExpReqEmploymentRecordSelector(perId, 'employment')) || ({} as any as employmentType); // tslint:disable:no-object-literal-type-assertion

  const [heading, setHeading] = useState<string | React.ReactNode>('Are you sure you want to');
  const [subHeading, setSubHeading] = useState<string | React.ReactNode>();
  const [confirmText, setConfirmText] = useState<string | React.ReactNode>();
  const {
    isSaving,
    showModal,
    setShowModal,
    isChangingSupervisor,
    setIsChangingSupervisor,
    isSubmitted,
    isSupervisorReadOnly,
    setIsSupervisorReadOnly,
    setIsFromDashboard,
    setIsPageReadOnly,
    isPageReadOnly,
    setIsSupervisorChanged,
    setIsEmployerEdit,
    setIsRoleEdit,
    setIsSupervisorEdit,
    resetSupervisorData,
    setIsResubmit,
  } = usePageContext();

  const confirmEditSupervisor = useCallback(async () => {
    if (isSubmitted) {
      const formattedData = perId
        ? {
            ...formDataEmployment,
            employmentType: String(formDataEmployment.employmentType),
            supervisor: formDataEmployment.supervisor,
            attestation: PERmodule?.attestation,
          }
        : { ...newEmployment, attestation: formData.attestation };

      const payload = {
        ...formattedData,
        status: isPageReadOnly ? statusType.SUBMITTED : statusType.DRAFTED,
        submissionDate: isPageReadOnly ? moment(new Date()).format('YYYY-MM-DD') : '',
        operationType: MembershipTypes.PEROperationType.CHANGED_SUPERVISOR,
        currentVisorId: PERmodule?.supervisor?.id,
        currentVisorEmail: PERmodule?.supervisor?.email,
        currentVisorName: PERmodule?.supervisor?.firstName,
      };
      await dispatch(
        updatePracticalExperienceRequirement(payload, portfolio, MembershipTypes.PERRecordType.EMPLOYMENT)
      );
      await dispatch(getPracticalExperienceRequirement());
      dispatch(clearNewEmploymentData());
      resetSupervisorData();
      await dispatch(
        push({
          pathname: getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT),
        })
      );

      setIsSupervisorReadOnly(!isSupervisorReadOnly);
      setIsChangingSupervisor(false);
      setShowModal(false);
      setIsSupervisorChanged(true);
      setIsResubmit(false);
    } else {
      setIsFromDashboard(false);
      setIsPageReadOnly(!isPageReadOnly);
      setIsSupervisorReadOnly(!isSupervisorReadOnly);
      setIsChangingSupervisor(false);
      setShowModal(false);
      setIsEmployerEdit(false);
      setIsRoleEdit(false);
      setIsSupervisorEdit(false);
      setIsResubmit(false);
    }
  }, [formData, isPageReadOnly, formData, newEmployment, formDataEmployment]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (variant?.toString().includes('submit')) {
      setHeading(`${heading}submit`);
      setConfirmText('Confirm');
    }

    switch (variant) {
      case ModalVariants.EMPLOYMENT_SUBMIT:
        setHeading(`Are you ready to submit your employment information?`);
        break;
      case ModalVariants.SKILLS_SUBMIT:
        setHeading(
          `${heading} ${
            isChangingSupervisor && isSubmitted ? 'change your supervisor?' : 'submit Skills & Behaviours?'
          }`
        );
        break;
      case ModalVariants.ACTIVITIES_SUBMIT:
        setHeading(
          `${heading} ${isChangingSupervisor && isSubmitted ? 'change your supervisor?' : 'submit Core activity?'} `
        );
        break;
      case ModalVariants.WITHDRAW_SUBMISSION:
        setHeading(`${heading} withdraw your submission?`);
        setConfirmText('Confirm');
        break;
      case ModalVariants.DECLINE_REVIEW:
        setHeading(`${heading} decline review?`);
        break;
      case ModalVariants.REQUEST_INFO:
        setHeading(`${heading} request further information?`);
        setSubHeading(
          <>
            Clicking on<strong>confirm</strong> will send feedback to the student
          </>
        );
        break;
      case ModalVariants.CHANGE_SUPERVISOR:
        setHeading(`${heading} change your supervisor?`);
        setConfirmText('Confirm');
        break;
      case ModalVariants.REMOVE:
        setHeading(`${heading} remove this record?`);
        setConfirmText('Confirm');
        break;
      default:
        setConfirmText('Confirm');
        break;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledModal
      heading={heading}
      testId="practical-exp-req-modal"
      open={showModal}
      onClose={() => setShowModal(!showModal)} // tslint:disable-line jsx-no-lambda
      showCloseCross={true}
      cancelNode={
        <StyledButton
          size={ButtonEnums.sizes.medium}
          testId="cancel"
          variant={ButtonEnums.variants.secondary}
          onClick={() => setShowModal(!showModal)} // tslint:disable-line jsx-no-lambda
        >
          Cancel
        </StyledButton>
      }
      confirmNode={
        <StyledButton
          size={ButtonEnums.sizes.medium}
          testId="proceed"
          variant={ButtonEnums.variants.primary}
          onClick={isChangingSupervisor === false ? handleModalProceed : confirmEditSupervisor}
          loading={isSaving}
          to={getPath(Routes.PRACTICAL_EXPERIENCE_REQUIREMENT_ROOT)}
        >
          {confirmText}
        </StyledButton>
      }
    >
      <StyledText>{subHeading}</StyledText>
    </StyledModal>
  );
});

const StyledText = styled.p`
  &&& {
    font-weight: ${props => props.theme.fontWeights.light};
    font-size: ${props => props.theme.fontSizes.xs};
    text-align: center;
  }
`;

const StyledModal = styled(Modal)`
  &&&&& {
    width: ${props => props.theme.pxToRem(590)};
  }
  &&&&&&.ui.modal {
    ${props => props.theme.mediaQueries.tabletMin} {
      width: ${props => props.theme.pxToRem(590)};
      padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(18)};
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 95%;
    }

    h2 {
      width: 50%;
      margin: auto;
    }
  }
  &&&&&&.ui.modal .header {
    ${props => props.theme.mediaQueries.tabletMin} {
      margin: auto;
    }
  }
  &&&&&&.ui.modal .isKoEL {
    ${props => props.theme.mediaQueries.tabletMin} {
      width: ${props => props.theme.pxToRem(33)};
      height: ${props => props.theme.pxToRem(33)};
      margin: ${props => props.theme.pxToRem(3)} ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(0)}
        ${props => props.theme.pxToRem(0)};
    }
    ${props => props.theme.mediaQueries.mobileMin} {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
  &&&&&&.ui.modal .content {
    ${props => props.theme.mediaQueries.mobileMin} {
      padding: ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(0)}!important;
    }
  }
`;

const StyledButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(170)};
    height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;
