import React from 'react';
import { Modal } from 'components/atoms/Modal/Modal';
import { ButtonEnums, Button } from 'components/atoms/Button/Button';
import styled from 'styled-components';
import { handleEvent, EVENT_CLICK } from 'utils/Analytics';

interface Props {
  open: boolean;
  header?: string;
  testId?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  description?: string;
  className?: string;
  isConfirmationInProgress?: boolean;
}

export const ProfileConfirmationModal: React.FC<Props> = ({
  open,
  header = 'Saved !',
  testId = 'confirm-default-card',
  onConfirm,
  onCancel,
  confirmButtonLabel = 'Ok',
  description,
  className,
  isConfirmationInProgress,
}) => {
  const onConfirmEvent = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onConfirm();
      return handleEvent({ clickValue: `button:card-actions:int:modal-${confirmButtonLabel}` }, EVENT_CLICK);
    },
    [onConfirm, confirmButtonLabel]
  );

  const onCancelEvent = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onCancel) onCancel();
      return handleEvent({ clickValue: `button:card-actions:int:modal` }, EVENT_CLICK);
    },
    [onCancel]
  );

  return (
    <StyledModal
      className={className}
      open={open}
      size="small"
      heading={header}
      testId={testId}
      showCloseCross={true}
      onClose={onCancelEvent}
      isCloseButtonPullRight={true}
      isCloseButtonPrimary={true}
      confirmNode={
        <ButtonStyled
          size={ButtonEnums.sizes.medium}
          variant={ButtonEnums.variants.primary}
          testId="confirm"
          onClick={onConfirmEvent}
          disabled={isConfirmationInProgress}
          loading={isConfirmationInProgress}
        >
          {confirmButtonLabel}
        </ButtonStyled>
      }
    >
      <StyledParagraph>{description || `Your changes have been saved.`}</StyledParagraph>
    </StyledModal>
  );
};

const ButtonStyled = styled(Button)`
  &&&&&& {
    min-width: ${props => props.theme.pxToRem(190)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&&& {
    border-radius: ${props => props.theme.pxToRem(2)};
    max-width: ${props => props.theme.pxToRem(590)};

    .header {
      line-height: 1.33;
      margin-top: ${props => props.theme.pxToRem(12)};
      h2 {
        margin-top: ${props => props.theme.pxToRem(17)};
      }
    }

    .content {
      margin: ${props => props.theme.pxToRem(5)} auto auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
    }

    p {
      max-width: 100%;
    }
    .actions {
      padding: ${props => `${props.theme.pxToRem(20)}`} 0 0 !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        flex-direction: column-reverse;
        justify-content: center;
      }
    }

    button {
      width: ${props => props.theme.pxToRem(202)};
      height: ${props => props.theme.pxToRem(34)};
      padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};

      margin: auto ${props => props.theme.pxToRem(6)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100% !important;
        height: ${props => `${props.theme.pxToRem(40)}`};
        max-width: ${props => `${props.theme.pxToRem(296)}`};
        font-size: ${props => props.theme.fontSizes.s};
        border-radius: ${props => `${props.theme.pxToRem(4)}`};
        margin: 0 auto !important;
      }
    }

    button:first-child {
      ${props => props.theme.mediaQueries.mobileOnly} {
        margin-top: ${props => `${props.theme.pxToRem(16)}`} !important;
      }
    }
  }
`;

const StyledParagraph = styled.p`
  line-height: 1.57;
  text-align: center;
  margin-top: ${props => props.theme.pxToRem(25)};
`;
