import React from 'react';
import styled from 'styled-components';
import { Heading, Link } from 'components/atoms';

interface Props {
  title: string;
  text?: string;
  link?: string;
  linkText?: string;
  className?: string;
  hasBorder?: boolean;
}

export const ProfileHeading: React.FC<Props> = ({
  title = '',
  text = '',
  link,
  linkText,
  hasBorder = true,
  className,
}) => (
  <>
    {link && linkText && (
      <Link testId="test" to={link} isExternal={false}>
        {linkText}
      </Link>
    )}
    <PreferencesHeading as="h1" className={className} hasBorder={hasBorder}>
      {title}
    </PreferencesHeading>
    {text && <Instructions>{text}</Instructions>}
  </>
);

const PreferencesHeading = styled(Heading)<{ hasBorder: boolean }>`
  padding-bottom: ${props => props.theme.pxToRem(16)};
  ${props => props.hasBorder && `border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey3}`}
  margin-bottom: ${props => props.theme.pxToRem(24)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  min-height: auto; // fix for Safari

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-bottom: ${props => props.theme.pxToRem(20)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
    line-height: 1.25;
  }
`;

const Instructions = styled.p`
  margin-bottom: ${props => props.theme.pxToRem(40)};
  max-width: 550px;

  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
  }
`;
