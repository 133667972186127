import React from 'react';
import { Checkbox, Link } from 'components/atoms';
import styled from 'styled-components';
import { getContentUrl } from 'utils';
import { Content } from 'mxp-schemas';
import { ContentfulHelpers } from 'mxp-utils';
import { CONSTANTS } from 'modules/app/constants';

export const DeclarationFormCheckbox: React.FC<{
  name: string;
  declarationChecked: boolean;
  setDeclarationChecked: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ name, declarationChecked, setDeclarationChecked }) => {
  function checkboxHandler() {
    setDeclarationChecked(!declarationChecked);
  }

  return (
    <StyledCheckboxContainer>
      <Checkbox id="checkbox" type="checkbox" testId="your-test-id" autoFocus onClick={checkboxHandler} />
      <StyledLabel htmlFor="checkbox">
        I, <strong>{name}</strong> hereby declare that all information provided is true and I attest to the{' '}
        <StyledLink
          testId="test-guidance-link"
          target="_blank"
          to={getContentUrl({
            contentCategory: { name: '', slug: Content.CategorySlugs.RESOURCES },
            contentfulType: ContentfulHelpers.CONTENT_TYPES.DOWNLOAD_TEMPLATE,
            slug: CONSTANTS.PER_DECLARATION_FORM_TEMPLATE,
          })}
        >
          declaration form.
        </StyledLink>
      </StyledLabel>
    </StyledCheckboxContainer>
  );
};

const StyledCheckboxContainer = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(23)};
`;

const StyledLabel = styled.label`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  margin-left: ${props => props.theme.pxToRem(23)};
  line-height: ${props => props.theme.pxToRem(23)};
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primaryPurple};
  text-decoration: underline;
`;
