import React from 'react';
import { ButtonEnums, ButtonLink } from 'components/atoms';
import { Lock } from 'components/atoms/svg';

interface Props {
  to: string;
  label: string;
  className?: string;
  disableCheckoutButton?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ProceedButtonLink: React.FC<Props> = ({ to, label, className, disableCheckoutButton, onClick }) => (
  <ButtonLink
    onClick={onClick}
    testId="proceed-to-checkout"
    iconPosition={ButtonEnums.iconPosition.left}
    icon={<Lock color="#fff" />}
    variant={ButtonEnums.variants.primary}
    to={to}
    className={className}
    disabled={disableCheckoutButton}
  >
    {label}
  </ButtonLink>
);
