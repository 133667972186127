import React, { useEffect } from 'react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { DefaultInlineLinkStyles, ExternalLinkStyles } from 'components/atoms/Link/Link';
import { handleEvent, EXTERNAL_LINK_EVENT } from 'utils/Analytics';
import { IC_OPEN_IN_NEW_PURPLE } from '../../../resources/images';
import { replaceHttpWithHttps, replaceSpecificUrls } from 'utils';

interface ContentRichTextProps {
  source: string;
  standFirst?: boolean;
  isSubtitle?: boolean;
  testid: string;
  navigate: (path: string) => void;
}

export const ContentRichText: React.FC<ContentRichTextProps> = ({
  source,
  standFirst,
  isSubtitle,
  testid,
  navigate,
}) => {
  const richTextContainerRef = React.useRef<any>();

  useEffect(() => {
    const externalLinks: HTMLCollectionOf<Element> =
      richTextContainerRef.current.getElementsByClassName('rich-text-external-link');
    const internalLinks: HTMLCollectionOf<Element> =
      richTextContainerRef.current.getElementsByClassName('rich-text-internal-link');

    const externalLinkHandler = function (this: Element) {
      const href = this.getAttribute('href');
      const content = this.innerHTML;
      handleEvent({ text: `page-copy:${content}:${href}`, href }, EXTERNAL_LINK_EVENT);
    };

    const internalLinkHandler = function (this: Element, event: Event) {
      event.preventDefault();
      const text = this.innerHTML || this.textContent || '';
      const href = this.getAttribute('href');
      if (href) {
        handleEvent({ text: `text:link:#${text}:${href}`, href }, EXTERNAL_LINK_EVENT);
        navigate(href);
      }
    };

    Array.from(externalLinks).forEach(externalLink => {
      externalLink.addEventListener('click', externalLinkHandler);
      externalLink.setAttribute('target', '_blank');
    });

    Array.from(internalLinks).forEach(internalLink => {
      internalLink.addEventListener('click', internalLinkHandler);
    });

    if (richTextContainerRef.current) {
      // 1. Get the HTML content from dangerouslySetInnerHTML
      const originalHtml = richTextContainerRef.current.innerHTML;

      // 2. Replace HTTP with HTTPS
      const httpsHtml = replaceHttpWithHttps(originalHtml);

      // 3. Replace specific URLs
      const finalHtml = replaceSpecificUrls(httpsHtml);

      // 4. Set the updated HTML content back into the component
      richTextContainerRef.current.innerHTML = finalHtml;
    }

    return () => {
      Array.from(externalLinks).forEach(externalLink => {
        externalLink.removeEventListener('click', externalLinkHandler);
        externalLink.setAttribute('target', '_blank');
      });
      Array.from(internalLinks).forEach(internalLink => {
        internalLink.removeEventListener('click', internalLinkHandler);
      });
    };
  }, [navigate]);

  return (
    <StyledContentRichTextHolder
      ref={richTextContainerRef}
      data-testid={testid}
      standFirst={standFirst}
      isSubtitle={isSubtitle}
      dangerouslySetInnerHTML={{ __html: source }}
      tabIndex={0}
    />
  );
};

// eslint-disable-next-line
const StyledContentRichTextHolder = styled.article<
  ThemedStyledProps<{ standFirst: boolean | undefined; isSubtitle: boolean | undefined }, DefaultTheme>
>`
  display: block;
  font-size: ${(props: any) => (props.standFirst ? props.theme.fontSizes.l : props.theme.fontSizes.m)};
  line-height: ${(props: any) => (props.standFirst ? '1.5' : '1.77')};
  padding: 0 0 ${(props: any) => props.theme.pxToRem(20)};
  ${(props: any) => `
    ${props.standFirst ? `padding: 0 0 ${props.theme.pxToRem(27)}` : ''};
  `}
  ${(props: any) => `
    ${props.isSubtitle ? `padding: 0 0 ${props.theme.pxToRem(10)}` : ''};
  `}
  overflow: auto;
  ${(props: any) => `
    ${props.standFirst ? `color: ${props.theme.colors.neutralGrey8}` : ''};
  `}
  && p {
    font-size: ${(props: any) => (props.standFirst ? props.theme.fontSizes.l : props.theme.fontSizes.m)};
  }

  .rich-text-external-link {
    ${ExternalLinkStyles}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${IC_OPEN_IN_NEW_PURPLE});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }

  .rich-text-internal-link {
    ${DefaultInlineLinkStyles};
  }

  p {
    margin: ${(props: any) => (props.isSubtitle ? '0' : `padding: 0 0 ${props.theme.pxToRem(10)}`)};
    line-height: ${props => props.theme.fontSizes.xl};
  }
  ul:last-child,
  ol:last-child {
    padding: 0;
  }
  ul {
    list-style: none;
    padding: 0 0 25px;
    margin: 0;

    li {
      padding: 0 0 5px 23px;
      overflow: hidden;
    }
    li::before {
      content: '·';
      font-size: 4.375rem;
      line-height: 2rem;
      float: left;
      margin: 0 0 -50px -23px;
      vertical-align: top;
    }
  }
  ol {
    list-style: none;
    counter-reset: item;
    margin: 0;
    padding: 0 0 25px;
    li {
      counter-increment: item;
      margin: 0;
      padding: 0 0 5px 23px;
      overflow: hidden;
    }
    li:before {
      content: counter(item) '.';
      float: left;
      margin: 0 0 -50px -23px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${props => props.theme.fontWeights.medium};
  }

  h2 {
    font-size: ${props => props.theme.fontSizes.l};
    line-height: 1.33;
    margin: 0 0 20px;
  }

  blockquote {
    margin: 30px 0 50px;
    padding: 40px 0;
    border: 1px solid ${props => props.theme.colors.neutralGrey4};
    border-width: 1px 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    quotes: none;
    position: relative;
    text-align: center;
  }
  blockquote q {
    font-size: ${props => props.theme.fontSizes.xl};
    line-height: 1.375;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    quotes: none;
  }

  blockquote cite {
    font-style: normal;
    margin: 16px 0 0;
    font-size: ${props => props.theme.fontSizes.xs};
    line-height: 1.57;
    color: ${props => props.theme.colors.neutralGrey4};
    display: block;
  }

  blockquote::before,
  blockquote::after {
    content: '“';
    font-weight: 400;
    font-size: ${props => props.theme.fontSizes.xxl};
    line-height: 2rem;
    position: absolute;
    left: 50%;
    top: -5px;
    padding: 0 15px;
    color: ${props => props.theme.colors.interfaceBlue};
    background: #fff;
    margin: 0 0 0 -22px;
  }

  blockquote::after {
    content: '”';
    top: auto;
    bottom: -28px;
  }

  .content-full-width-image {
    ${props => `
      margin: 0 0 40px;
      color: ${props.theme.colors.neutralGrey8};
      width: 100%;
      font-size: ${props.theme.fontSizes.xxs};
      line-height: 1.5;
    `}
  }

  .content-inline-image {
    ${props => `
      margin: 8px 20px 18px 0;
      color: ${props.theme.colors.neutralGrey8};
      float: left;
      width: 360px;
      font-size: ${props.theme.fontSizes.xxs};
      line-height: 1.5;
      ${props.theme.mediaQueries.mobileOnly} {
        width: 100%;
      }
    `}
  }

  hr {
    border-top: 2px solid ${props => props.theme.colors.neutralGrey3};
  }

  .content-page-image-holder {
    margin: 0 0 10px;
    width: 100%;
    img {
      display: block;
      border: 0;
      width: 100%;
      height: 100%;
    }
  }

  .content-page-image-caption {
    margin: 0 0 5px;
  }

  .content-page-image-credit {
    text-transform: uppercase;
  }

  .content-page-table-wrapper {
    width: 100%;
    ${props => props.theme.mediaQueries.mobileOnly} {
      overflow-x: scroll;
    }
  }
  .content-page-table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    margin: ${props => props.theme.pxToRem(8)} 0 ${props => props.theme.pxToRem(28)};
    thead {
      background-color: ${props => props.theme.colors.neutralGrey1};
    }
    th {
      font-size: ${props => props.theme.fontSizes.xs};
      line-height: ${props => props.theme.pxToRem(22)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        min-width: 190px;
      }
    }
    td {
      font-size: ${props => props.theme.fontSizes.s};
      line-height: ${props => props.theme.pxToRem(24)};
    }
    td,
    th {
      padding: ${props => props.theme.pxToRem(20)};
    }
    tr {
      border-bottom: 1px solid ${props => props.theme.colors.neutralGrey3};
    }
    tbody > tr:nth-child(even) {
      background-color: rgba(247, 247, 247, 0.3);
    }
  }
`;
