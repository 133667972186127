import React, { SetStateAction, useEffect, useState, useCallback } from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components/macro';
import { Product } from 'mxp-schemas';
import { RadioButton, Checkbox, ButtonEnums, Button, Input } from 'components/atoms';
import { NotificationForAnonymousUser } from 'components/molecules/NotificationForAnonymousUser/NotificationForAnonymousUser';
import { NotificationForNonMemberUser } from 'components/molecules/NotificationForNonMemberUser/NotificationForNonMemberUser';
import { formattedPriceToDecimal, priceToFloat } from 'utils';
import { useSelector } from 'react-redux';
import { productCurrencySelector } from 'modules/products/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { isUnitedKingdomSelector } from 'modules/user/selectors';

interface Props {
  variants: Product.Variant[];
  selectedVariantSKU?: string;
  handleSelectionChange: (variantSKU: string) => () => void;
  hasAttestation: boolean;
  hasAttestationVariants: Product.Variant[];
  priceFinal: Product.FormattedPriceData | null;
  onPriceChange: (selectedDonationPrice: string) => void;
  showButtons: boolean;
  setShowPriceUpdateButtons: (status: boolean) => void;
  settingPrice: (priceFinal: Product.FormattedPriceData | null) => string;
  currentVariantPrice: CurrentVariantPriceInterface;
  setVariantPrice: (variantPrice: CurrentVariantPriceInterface) => void;
  setEditHistory: (historyObj: SetStateAction<EditHistoryInterface[]>) => void;
  updateContribPrice: (variantPrice: string, inputPrice: string, isFromInput?: boolean) => void;
  editHistory: EditHistoryInterface[];
  setIsUpdateCartPrice: (status: boolean) => void;
  setLineItemId: (lineId: string) => void;
  defaultProductVariantWithAttestationSku?: string | null;
  isProductHasUserPrice?: boolean | null;
  isAuth: boolean | null;
  isMember: boolean;
  product?: any;
}

interface CurrentVariantPriceInterface {
  price: string;
}

interface EditHistoryInterface {
  sku: string;
  price: string;
  isAlreadyinCart: boolean;
  lineId: string;
}

export const ContributionVariants: React.FC<Props> = ({
  variants,
  selectedVariantSKU,
  handleSelectionChange,
  hasAttestation,
  hasAttestationVariants,
  priceFinal,
  onPriceChange,
  showButtons,
  setShowPriceUpdateButtons,
  settingPrice,
  currentVariantPrice,
  setEditHistory,
  setVariantPrice,
  updateContribPrice,
  editHistory,
  setIsUpdateCartPrice,
  setLineItemId,
  defaultProductVariantWithAttestationSku,
  isProductHasUserPrice,
  isAuth,
  isMember,
  product,
}) => {
  const currency = useSelector(productCurrencySelector);
  const isUnitedKingdom = useSelector(isUnitedKingdomSelector);
  const [checked, setChecked] = useState(false);
  const [isDefaultProductAttestationSet, setIsDefaultProductAttestationSet] = useState(false);
  useEffect(() => {
    if (!selectedVariantSKU) return;
    if (!isDefaultProductAttestationSet && defaultProductVariantWithAttestationSku) {
      setCurrentVariantFunc(false);
      setChecked(true);
      handleSelectionChange(defaultProductVariantWithAttestationSku)();
      setIsDefaultProductAttestationSet(true);
    }

    const contribCartItem = editHistory?.find(
      (item: EditHistoryInterface) => item?.sku === selectedVariantSKU && item?.isAlreadyinCart
    );
    const result = contribCartItem ? true : false;
    setIsUpdateCartPrice(result);
    setLineItemId(contribCartItem?.lineId || '');

    if (editHistory.length > 0) {
      const prevPriceEdits = editHistory
        ?.filter((item: EditHistoryInterface) => item?.sku === selectedVariantSKU)
        .slice(-1);

      const hasAttest = hasAttestationVariants.map(item => item.sku);
      const prevSelectedSKU = hasAttest.find(item => item !== prevPriceEdits[0].sku);
      const newObj: EditHistoryInterface = {
        sku: prevSelectedSKU || '',
        price: prevPriceEdits[0].price || '',
        isAlreadyinCart: false,
        lineId: '',
      };
      setEditHistory(history => [...editHistory, newObj]);

      setVariantPrice({
        ...currentVariantPrice,
        price: prevPriceEdits ? prevPriceEdits[0]?.price : settingPrice(priceFinal),
      });
      // Set Redux
      onPriceChange(prevPriceEdits ? prevPriceEdits[0]?.price : '');
    } else {
      const prevPriceEdits = editHistory?.find((item: EditHistoryInterface) => item?.sku === selectedVariantSKU);

      setVariantPrice({
        ...currentVariantPrice,
        price: prevPriceEdits ? prevPriceEdits?.price : settingPrice(priceFinal),
      });
      // Set Redux
      onPriceChange(prevPriceEdits ? prevPriceEdits?.price : '');
    }
  }, [selectedVariantSKU, defaultProductVariantWithAttestationSku]); // eslint-disable-line react-hooks/exhaustive-deps

  const cimaContribution: boolean = product?.membershipBody?.key === Product.ProductMembershipBody.CIMA;
  const selectText: string = 'Direct my contribution to:';
  const hasMultipleVariants: boolean = variants?.length > 1 ? true : false;

  const falseAttestationVariant = hasAttestationVariants?.find(
    variant => variant?.attestationRequired === Product.DonationAttestation.NO
  );
  const trueAttestationVariant = hasAttestationVariants?.find(
    variant => variant?.attestationRequired === Product.DonationAttestation.YES
  );

  const [currentVariant, setCurrentVariant] = useState({
    sku: falseAttestationVariant?.sku,
    fundName: falseAttestationVariant?.fundName,
    attestationText: falseAttestationVariant?.attestationText,
    attestationRequired: falseAttestationVariant?.attestationRequired,
  });

  const handleUpdate = (inputPrice: string) => (e: any) => {
    setShowPriceUpdateButtons(false);

    updateContribPrice(selectedVariantSKU || '', inputPrice);
    // Setting Redux Price
    onPriceChange(inputPrice);
  };

  const handleCancel = () => (e: any) => {
    const prevPriceEdits = editHistory?.find((item: EditHistoryInterface) => item?.sku === selectedVariantSKU);

    setVariantPrice({
      ...currentVariantPrice,
      price: prevPriceEdits ? prevPriceEdits?.price : settingPrice(priceFinal),
    });
    setShowPriceUpdateButtons(false);

    handleSelectionChange(selectedVariantSKU || '')();
    // Set Redux
    onPriceChange(prevPriceEdits ? prevPriceEdits?.price : '');
  };

  const handleTxtPriceChange = () => (e: any) => {
    const regexp = /^\d*\.?\d{0,2}$/;

    if (e.target.validity.valid && regexp.test(e.target.value)) {
      setShowPriceUpdateButtons(true);
      setVariantPrice({
        ...currentVariantPrice,
        price: e.target.value,
      });
      // updateContribPrice('', e.target.value, true);
      const hasAttest = hasAttestationVariants.map(item => item.sku);
      const prevSelectedSKU = hasAttest.find(item => item !== selectedVariantSKU);
      const newObj: EditHistoryInterface = {
        sku: prevSelectedSKU || '',
        price: e.target.value || '',
        isAlreadyinCart: false,
        lineId: '',
      };
      setEditHistory(history => [...editHistory, newObj]);
    }
  };

  const setCurrentVariantFunc = useCallback(
    (boolState: boolean) => {
      const attestationVariantSku = () => {
        if (boolState) return falseAttestationVariant?.sku;
        return trueAttestationVariant?.sku;
      };
      const attestationVariantFundName = () => {
        if (boolState) return falseAttestationVariant?.fundName;
        return trueAttestationVariant?.fundName;
      };
      const variantAttestationText = () => {
        if (boolState) return falseAttestationVariant?.attestationText;
        return trueAttestationVariant?.attestationText;
      };
      const variantAttestationRequired = () => {
        if (boolState) return falseAttestationVariant?.attestationRequired;
        return trueAttestationVariant?.attestationRequired;
      };
      setCurrentVariant({
        ...currentVariant,
        sku: attestationVariantSku(),
        fundName: attestationVariantFundName(),
        attestationText: variantAttestationText(),
        attestationRequired: variantAttestationRequired(),
      });
    },
    [currentVariant, falseAttestationVariant, trueAttestationVariant]
  );

  const handleCheckTick = useCallback(
    (attestationRequired: string | undefined) => () => {
      const boolState: boolean = attestationRequired === Product.DonationAttestation.YES;

      const variantSKU = () => {
        if (boolState) return falseAttestationVariant?.sku;
        return trueAttestationVariant?.sku;
      };

      setChecked(!checked);
      setCurrentVariantFunc(boolState);
      handleSelectionChange(variantSKU() || '')();
    },
    [checked, falseAttestationVariant, trueAttestationVariant, handleSelectionChange, setCurrentVariantFunc]
  );

  const isUpdateContributionDisabled = React.useCallback(
    () => !Boolean(priceToFloat(currentVariantPrice.price) >= 1),
    [currentVariantPrice]
  );

  const isContributionAmountZero = React.useCallback(
    () => Boolean(Number(formattedPriceToDecimal(currentVariantPrice?.price)) === 0),
    [currentVariantPrice]
  );

  const variantName: string = Boolean(currentVariant?.fundName && !hasAttestation)
    ? currentVariant?.fundName || ''
    : 'Enter amount';

  const renderContributionInputGroup = (): React.ReactNode => (
    <StyledContributionGroupWrapper isInputGroup>
      <StyledLabel>{variantName}</StyledLabel>
      <StyledInput
        fluid
        iconName={`${
          currency.label === Product.ProductCurrencyLabel.GBP
            ? 'pound'
            : currency.label === Product.ProductCurrencyLabel.EUR
            ? 'euro'
            : 'dollar'
        } sign`}
        iconPosition="left"
        type="text"
        pattern="[0-9]*\.?[0-9]*"
        testId="contribution-amount"
        // always return empty value/ string instead of 0
        value={isContributionAmountZero() ? '' : formattedPriceToDecimal(currentVariantPrice?.price)}
        onChange={handleTxtPriceChange()}
      />
    </StyledContributionGroupWrapper>
  );

  const renderContributionButtonGroup = (): React.ReactNode => (
    <StyledContributionGroupWrapper isButtonGroup>
      <StyledButton
        testId="cancel"
        variant={ButtonEnums.variants.secondary}
        size={ButtonEnums.sizes.medium}
        bordered
        onClick={handleCancel()}
      >
        Cancel
      </StyledButton>
      <StyledButton
        testId="update"
        variant={ButtonEnums.variants.primary}
        size={ButtonEnums.sizes.medium}
        bordered
        onClick={handleUpdate(currentVariantPrice.price)}
        disabled={isUpdateContributionDisabled()}
      >
        Update
      </StyledButton>
    </StyledContributionGroupWrapper>
  );

  const renderContributionAmount = (): React.ReactNode => (
    <StyledContributionAmountWrapper>
      <HorizontalDivider />
      <StyledContributionGroupWrapper isTextGroup>
        <StyledHeadLabel>Your contribution</StyledHeadLabel>
        <StyledHeadLabel>
          {currency.sign}
          {currentVariantPrice.price}
        </StyledHeadLabel>
      </StyledContributionGroupWrapper>
    </StyledContributionAmountWrapper>
  );

  const renderAttestation = useCallback((): any => {
    return (
      <StyledAttestationWrapper>
        <CheckboxStyled
          width="20"
          height="20"
          type="checkbox"
          testId={`attestation-checkbox`}
          label={currentVariant.attestationText}
          disabled={false}
          onChange={handleCheckTick(currentVariant.attestationRequired)}
          checked={checked}
        />
      </StyledAttestationWrapper>
    );
  }, [currentVariant, checked, handleCheckTick]);

  const isNotCimaContribution = useCallback(() => {
    return !cimaContribution;
  }, [cimaContribution]);

  const isUnitedKingdomFunc = useCallback(() => {
    return isUnitedKingdom;
  }, [isUnitedKingdom]);

  const attestation = useCallback(() => {
    if (isNotCimaContribution() || isUnitedKingdomFunc()) return renderAttestation();

    return null;
  }, [isNotCimaContribution, isUnitedKingdomFunc, renderAttestation]);

  const isAdmin = useSelector(isAdminPortalSelector);
  const isImpersonated = Boolean(isAuth) && isAdmin;

  return isProductHasUserPrice &&
    ((hasAttestation && (isMember || (isImpersonated && (isMember || !isMember)))) || !hasAttestation) ? (
    <StyledContributionWrapper>
      {hasMultipleVariants && !hasAttestation && (
        <>
          <StyledDiv data-testid="purchase-summary-contribution-info">
            <StyledText data-testid="purchase-summary-select-text">{selectText}</StyledText>
          </StyledDiv>
          {(variants as Product.Variant[]).map((item: Product.Variant, key) => {
            const isSelected = item.sku === selectedVariantSKU;
            return (
              <StyledVariantDiv key={key}>
                <StyledVariant key={key}>
                  <StyledRadioButtonSmall
                    testId={`${key}-radio`}
                    checked={isSelected}
                    onChange={handleSelectionChange(item.sku || '')}
                  />
                  <StyledOptionTexts grayedOut={!isSelected}>{item.fundName}</StyledOptionTexts>
                </StyledVariant>
                <MarginedHr />
              </StyledVariantDiv>
            );
          })}
        </>
      )}

      {renderContributionInputGroup()}
      {hasAttestation && hasAttestationVariants?.length === 2 && attestation()}
      {showButtons && renderContributionButtonGroup()}
      {renderContributionAmount()}
    </StyledContributionWrapper>
  ) : (
    <>
      {!isAuth && (
        <StyledNotificationForAnonymousUser isPurchaseSummary isContribution showDefault={!!isProductHasUserPrice} />
      )}
      {isAuth && <StyledNotificationForNonMemberUser />}
    </>
  );
};

const StyledDiv = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
  padding: 0 0 ${props => `${props.theme.pxToRem(4)}`} ${props => `${props.theme.pxToRem(20)}`};
`;

const StyledVariantDiv = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(10)};
  :last-child {
    padding-bottom: 0;
  }
`;

const MarginedHr = styled.div`
  margin-right: ${props => props.theme.pxToRem(25)};
  margin-left: ${props => props.theme.pxToRem(25)};
  ${props => `border-bottom: 1px solid ${props.theme.colors.neutralGrey3};`}
`;

const StyledText = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.57;
`;

const StyledRadioButtonSmall = styled(RadioButton)`
  margin-right: ${props => props.theme.pxToRem(12)};
  min-width: ${props => props.theme.pxToRem(18)};
  &&& {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    & > div {
      width: ${props => props.theme.pxToRem(8)};
      height: ${props => props.theme.pxToRem(8)};
    }
  }
`;

const StyledVariant = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => `${props.theme.pxToRem(15)} ${props.theme.pxToRem(5)}`};
  margin: 0 ${props => props.theme.pxToRem(20)};
`;

const StyledOptionTexts = styled.div<ThemedStyledProps<{ grayedOut: boolean }, DefaultTheme>>`
  font-weight: ${props => (props.grayedOut ? props.theme.fontWeights.light : props.theme.fontWeights.medium)};
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.57;
`;

const CheckboxStyled = styled(Checkbox)`
  &&&&& {
    object-fit: contain;
    line-height: 1;

    > label {
      font-size: ${props => props.theme.fontSizes.xxs};
      color: ${props => props.theme.colors.neutralBlack};
      padding-left: ${props => props.theme.pxToRem(30)};
      font-weight: ${props => props.theme.fontWeights.bold};
      line-height: ${props => props.theme.pxToRem(22)};
    }
  }
`;

const StyledInput = styled(Input)`
  &&&& {
    margin-left: auto;
    font-size: ${props => props.theme.fontSizes.xs};
    width: ${props => props.theme.pxToRem(120)};
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.fontSizes.s};
    }
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    width: ${props => props.theme.pxToRem(93)};
    height: ${props => props.theme.pxToRem(34)};
    min-height: ${props => props.theme.pxToRem(34)};
    font-size: ${props => props.theme.pxToRem(14)};
    padding: 0;

    :last-child {
      margin-left: ${props => props.theme.pxToRem(10)};
    }
  }
`;

const StyledLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.xs};
`;

const StyledHeadLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.m};
  :last-child {
    margin-left: auto;
  }
`;

const StyledNotificationForAnonymousUser = styled(NotificationForAnonymousUser)`
  padding: ${props => props.theme.pxToRem(7)} ${props => props.theme.pxToRem(20)} 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-top: ${props => props.theme.pxToRem(12)};
  }
`;

const StyledNotificationForNonMemberUser = styled(NotificationForNonMemberUser)`
  padding: ${props => props.theme.pxToRem(7)} ${props => props.theme.pxToRem(20)} 0;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-top: ${props => props.theme.pxToRem(12)};
  }
`;

const HorizontalDivider = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey3};
  width: 100%;
  height: ${props => props.theme.pxToRem(1)};
`;

const StyledContributionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.mediaQueries.mobileOnly} {
    height: 100%;
  }
`;

const StyledContributionGroupWrapper = styled.div<{
  isInputGroup?: boolean;
  isButtonGroup?: boolean;
  isTextGroup?: boolean;
}>`
  display: flex;

  ${props => (props?.isInputGroup || props?.isTextGroup) && `align-items: center;`}
  ${props => props?.isButtonGroup && ` justify-content: flex-end;`}

  // padding
  ${props => props?.isInputGroup && `padding: ${props.theme.pxToRem(20)};`}
  ${props =>
    props?.isButtonGroup &&
    `padding: 0 ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)};`}
  ${props =>
    props?.isTextGroup &&
    `padding: ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} 0 ${props.theme.pxToRem(20)};`}
`;

const StyledContributionAmountWrapper = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: auto;
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledAttestationWrapper = styled.div`
  padding: ${props => `0 ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)}`};
`;
