import React from 'react';
interface Props {
  size?: number;
  className?: string;
  onClick?: () => void;
}

export const LogoSVG = React.memo<Props>(({ size = 32, className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    className={className}
    viewBox="0 0 35 34"
    onClick={onClick}
  >
    <defs>
      <path
        id="aLogo"
        d="M.996 12.304L22.85 2.195c-1.446-1.16-3.48-1.752-5.981-1.752C9.372.469 3.045 5.46.996 12.304zm2.375.561l14.257 9.99c4.384-3.588 7.702-9.23 7.702-14.732 0-1.466-.269-2.727-.778-3.779l-21.18 8.521zM28.85 7.331c0 6.315-3.669 12.959-9.044 17.049l7.811 5.474a16.623 16.623 0 0 0 5.942-12.758c0-4.621-1.88-8.802-4.914-11.819.132.712.205 1.411.205 2.054zM.297 17.096c0 7.32 4.31 9.545 8.073 9.545 2.644 0 5.404-1.045 7.912-2.772L.799 13.016a16.73 16.73 0 0 0-.502 4.08zm8.441 11.265c-3.121 0-5.573-1.79-6.1-2.744h-.003c2.903 4.87 8.218 8.133 14.293 8.133 3.465 0 6.682-1.06 9.347-2.877l-7.867-5.513c-2.904 1.87-6.216 3-9.67 3z"
      />
      <linearGradient id="bLogo" x1="69.999%" x2="30.385%" y1="95.817%" y2="4.017%">
        <stop offset="0%" stopColor="#672D89" />
        <stop offset="100%" stopColor="#B64264" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="rotate(90 17.289 17.289)">
        <mask id="cLogo" fill="#fff">
          <use xlinkHref="#aLogo" />
        </mask>
        <path
          fill="url(#bLogo)"
          d="M.996 12.304L22.85 2.195c-1.446-1.16-3.48-1.752-5.981-1.752C9.372.469 3.045 5.46.996 12.304zm2.375.561l14.257 9.99c4.384-3.588 7.702-9.23 7.702-14.732 0-1.466-.269-2.727-.778-3.779l-21.18 8.521zM28.85 7.331c0 6.315-3.669 12.959-9.044 17.049l7.811 5.474a16.623 16.623 0 0 0 5.942-12.758c0-4.621-1.88-8.802-4.914-11.819.132.712.205 1.411.205 2.054zM.297 17.096c0 7.32 4.31 9.545 8.073 9.545 2.644 0 5.404-1.045 7.912-2.772L.799 13.016a16.73 16.73 0 0 0-.502 4.08zm8.441 11.265c-3.121 0-5.573-1.79-6.1-2.744h-.003c2.903 4.87 8.218 8.133 14.293 8.133 3.465 0 6.682-1.06 9.347-2.877l-7.867-5.513c-2.904 1.87-6.216 3-9.67 3z"
          mask="url(#cLogo)"
        />
      </g>
      <path
        fill="#231F20"
        d="M30.12 31.907h1.201v.23h-.474v1.377h-.252v-1.377h-.475zM32.985 31.907h.222l.197 1.607h-.248l-.138-1.148-.472.969h-.21l-.474-.969-.138 1.148h-.248l.196-1.607h.22l.547 1.145z"
      />
    </g>
  </svg>
));
