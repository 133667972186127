/*
  WARNING: REDUCER FILE CAN BE IMPORTED IN ROOT-REDUCER ONLY
*/
import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import {
  contentLoading,
  getContent,
  getContentBlogs,
  contentBlogsLoading,
  getContentItem,
  sendContentFeedback,
  getRelatedContentList,
  getStaticContentItem,
  fetchAggsPageContent,
  getBrandImages,
  getToolkitPage,
  resetContentModule,
  getProductAggsPageContent,
  resetSkipContentFrontloadRequest,
  setIsComingFromFcmaPropPageJourney,
  getDownloadContentItemBySlug,
  fetchRawRichText,
  getPromotedProducts,
} from './actions';
import { emptyObject, isServer } from 'utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [contentLoading]: (state: State.Content): State.Content => ({
    ...state,
    loading: true,
  }),

  [getContent]: {
    next: (state: State.Content, action: any): State.Content => {
      const { contentHash, useMerge, meta } = action.payload;

      return {
        ...state,
        contentHash: {
          ...(useMerge ? state.contentHash : emptyObject),
          ...contentHash,
        },
        meta,
        loading: false,
        isContentFetched: true,
      };
    },

    throw: (state: State.Content, action: any): State.Content => ({
      ...state,
      error: action.payload, // TODO
      loading: false,
      isContentFetched: true,
    }),
  },

  [getDownloadContentItemBySlug]: {
    next: (state: State.Content, action: any): State.Content => {
      return {
        ...state,
        downloadDocuments: action?.payload,
      };
    },
  },

  [getContentItem]: {
    next: (state: State.Content, action: any): State.Content => {
      const { contentHash } = state;
      const modifier: State.ContentItem = action.payload;
      const { id } = modifier;
      const contentItem: State.FeedCardContentItem | {} = contentHash ? contentHash[id] : emptyObject;

      return {
        ...state,
        contentHash: {
          ...contentHash,
          [id]: { ...contentItem, ...modifier, hasAllProps: true },
        },
        loading: false,
        isContentFetched: true,
      };
    },

    throw: (state: State.Content, action: any): State.Content => ({
      ...state,
      error: action.payload, // TODO
      loading: false,
      isContentFetched: true,
    }),
  },

  [getStaticContentItem]: {
    next: (state: State.Content, action: any): State.Content => {
      const { contentHash } = state;
      const modifier: State.ContentItem = action.payload;
      const { id } = modifier;
      const contentItem: State.FeedCardContentItem | {} = contentHash ? contentHash[id] : emptyObject;

      return {
        ...state,
        contentHash: {
          ...contentHash,
          [id]: { ...contentItem, ...modifier, hasAllProps: true },
        },
        loading: false,
      };
    },

    throw: (state: State.Content, action: any): State.Content => ({
      ...state,
      error: action.payload, // TODO
      loading: false,
    }),
  },

  [sendContentFeedback]: {
    next: (state: State.Content): State.Content => ({
      ...state,
      loading: false,
    }),
  },

  [getRelatedContentList]: {
    next: (state: State.Content, action: any): State.Content => ({
      ...state,
      relatedContent: action.payload,
      isRelatedContentFetched: !!state.relatedContent,
    }),
  },

  [getPromotedProducts]: {
    next: (state: State.Content, action: any): State.Content => ({
      ...state,
      promotedProducts: action.payload,
    }),
  },

  [fetchAggsPageContent]: {
    next: (state: State.Content, action: any): State.Content => ({
      ...state,
      aggsPageContent: action.payload.getAggsPageContent,
      loading: false,
      skipContentFrontloadRequest: isServer,
    }),
  },

  [getBrandImages]: {
    next: (state: State.Content, action: any): State.Content => {
      if (!action.payload.brandImages.length) {
        return state;
      }
      const key = action.payload.brandImages[0].name;
      return {
        ...state,
        brandImages: {
          ...state.brandImages,
          [key]: action.payload.brandImages,
        },
      };
    },
  },

  [getToolkitPage]: {
    next: (state: State.Content, action: any): State.Content => ({
      ...state,
      toolkitPageContent: action.payload,
      isContentFetched: true,
      loading: false,
    }),
  },
  [getProductAggsPageContent]: {
    next: (state: State.Content, action: any): State.Content => ({
      ...state,
      productAggPageContent: action.payload,
      loading: false,
      skipContentFrontloadRequest: isServer,
    }),
  },
  [resetSkipContentFrontloadRequest]: (state: State.Content): State.Content => ({
    ...state,
    skipContentFrontloadRequest: false,
  }),

  [resetContentModule]: (): State.Content => initialState,

  [contentBlogsLoading]: (state: State.Content): State.Content => ({
    ...state,
    blogsLoading: true,
  }),

  [setIsComingFromFcmaPropPageJourney]: (state: State.Content, action: any): State.Content => ({
    ...state,
    isComingFromFcmaPropPageJourney: action.payload,
  }),

  [getContentBlogs]: {
    next: (state: State.Content, action: any): State.Content => {
      const { contentBlogsHash, useMerge, blogsMeta } = action.payload;

      return {
        ...state,
        blogsContentHash: {
          ...(useMerge ? state.blogsContentHash : emptyObject),
          ...contentBlogsHash,
        },
        blogsMeta,
        blogsLoading: false,
      };
    },

    throw: (state: State.Content, action: any): State.Content => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
  },
  [fetchRawRichText]: {
    next: (state: State.Content, action: any): State.Content => {
      const slug = action?.payload?.getRawRichTextBySlug.slug;
      return {
        ...state,
        rawRichTextContent: {
          ...state.rawRichTextContent,
          [slug]: { ...action?.payload?.getRawRichTextBySlug },
        },
      };
    },
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
