import { createAction } from 'redux-actions';
import { GET_EXEMPTION_REFERENCE } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';

export const setWorkExperience: any = createAction(
  'exempReference/SET_WORK_EXPERIENCE_ID',
  (id: any, numberOfExp: any) => () => {
    return {
      id,
      numberOfExp,
    };
  }
);

export const fetchWorkExperienceQualifications: any = createAction(
  'exempReference/FETCH_WORK_EXPERIENCE',
  (type: string) => () => {
    return request(GET_EXEMPTION_REFERENCE, { type });
  }
);
