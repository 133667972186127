import React from 'react';
import { Redirect, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { Routes } from 'constants/index';
import { getPath, isServer } from 'utils';
import { getCurrentLocation } from 'utils/routes';
interface Props extends RouteProps {
  isAuth: boolean | null;
  isPublic?: boolean;
  skipRedirect?: boolean;
  isInCimaMembershipPromotionPage?: boolean;
}

export const SecureRoute: React.FC<Props> = ({
  component: Component,
  isAuth,
  isPublic = false,
  skipRedirect = isServer,
  isInCimaMembershipPromotionPage,
  ...routeProps
}) => {
  // 1. Render requested route when user authenticated
  if (isAuth || isPublic) {
    return <Route {...routeProps} component={Component} />;
  }

  // Redirect can be skipped optionally, or by SSR
  if (skipRedirect || (isAuth && isInCimaMembershipPromotionPage)) return null;

  const { location } = routeProps;
  const [prevRoute, hash] = getCurrentLocation(location).split('#');

  // 2. Redirect to internal login
  return <Redirect to={{ pathname: getPath(Routes.LOGIN), state: { prevRoute, hash: `#${hash}` }, hash }} />;
};
