import { Responsive } from 'semantic-ui-react';
import { DefaultTheme } from 'styled-components';
import { Product } from 'mxp-schemas';

const htmlFontSize: number = 16;

const colors = {
  primaryPurple: '#72246c',
  primaryFadedPurple: '#f7f2f5',
  primaryLightPurple: '#9a205f',
  primaryDarkPurple: '#5b1d56',
  primaryIndicatorPurple: '#d3c0da',
  membershipTagPurple: '#eee4ed',
  interfaceRed: '#e83147',
  interfaceBlue: '#0091bd',
  tertiaryBlue: '#2c7fa1',
  interfaceGreen: '#48a23f',
  secondaryYellow: '#f0b322',
  tertiaryDarkGreen: '#377530',
  secondaryOrange: '#dc6b2f',
  secondaryOrange2: '#d7821d',
  tertiaryOrange: '#b05525',
  secondaryDarkBlue: '#3a5dae',
  secondaryTeal: '#00857d',
  successGreen: '#00857D',
  tertiaryTeal: '#006864',
  tertiaryDarkBlue: '#2e4a8b',
  tertiaryYellow: '#c08f1d',
  neutralWhite: '#ffffff',
  neutralGrey1: '#f7f7f7',
  neutralGrey2: '#efefef',
  neutralGrey3: '#e5e5e6',
  neutralGrey4: '#bbbcbc',
  neutralGrey5: '#97999b',
  neutralGrey6: '#8e8e93',
  neutralGrey7: '#63656b',
  neutralGrey8: '#282937',
  neutralGrey9: '#737373',
  neutralGrey10: '#636669',
  neutralBlack: '#000000',
  fadedGray: '#f7f7f74d',
  wispPink: '#fceaec',
  lightPink: '#72246c1a',
  gradientDarkPurple: '#3c1131',
  aliceBlue: '#f2f5f7',
  lightBlue: '#cce8fe',
  secondaryLightPurple: '#f1e7f0',
  lightSilver: '#d8d8d8',
  raspberryMagenta: '#991f5e',
  lightGrey: '#D9D9D9',
};

const theme: DefaultTheme = {
  htmlFontSize,

  colors,

  buttonColors: {
    primary: colors.primaryPurple,
  },
  fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },
  fontSizes: {
    xxl: '2.5rem', // 40px
    xl: '2rem', // 32px
    l: '1.5rem', // 24px
    m: '1.125rem', // 18px
    s: '1rem', // 16px
    xs: '0.875rem', // 14px
    xxs: '0.75rem', // 12px
  },

  mediaQueries: {
    // Basic
    desktopOnly: `@media only screen and (min-width: ${Responsive.onlyComputer.minWidth}px)`,
    tabletOnly: `@media only screen and (min-width: ${Responsive.onlyTablet.minWidth}px) and (max-width: ${Responsive.onlyTablet.maxWidth}px)`,
    mobileOnly: `@media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px)`, // 992px
    // All possible semantic UI
    mobileMin: `@media only screen and (min-width: ${Responsive.onlyMobile.minWidth}px)`,
    mobileMax: `@media only screen and (min-width: ${Responsive.onlyMobile.maxWidth}px)`,
    mobileMaxOnly: `@media only screen and (max-width: ${Responsive.onlyMobile.maxWidth}px)`,
    tabletMin: `@media only screen and (min-width: ${Responsive.onlyTablet.minWidth}px)`,
    tabletMax: `@media only screen and (min-width: ${Responsive.onlyTablet.maxWidth}px)`,
    computerMin: `@media only screen and (min-width: ${Responsive.onlyComputer.minWidth}px)`,
    largeScreenMin: `@media only screen and (min-width: ${Responsive.onlyLargeScreen.minWidth}px)`,
    largeScreenMax: `@media only screen and (min-width: ${Responsive.onlyLargeScreen.maxWidth}px)`,
    wideScreen: `@media only screen and (min-width: ${Responsive.onlyWidescreen.minWidth}px)`, // 1920px
    ieOnly: `@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)`,
  },

  pxToRem: (px: number): string => `${px / htmlFontSize}rem`,

  getProductInfoBgColorByType: (pT: Product.ProductType | undefined, sPT?: Product.SubscriptionProductType) => {
    switch (sPT || pT) {
      case Product.ProductType.CONFERENCE:
        return theme.colors.tertiaryDarkGreen;
      case Product.ProductType.PUBLICATION:
        return theme.colors.tertiaryBlue;
      case Product.SubscriptionProductType.CONTENT:
      case Product.SubscriptionProductType.PUBLICATION:
      case Product.SubscriptionProductType.COURSE:
      case Product.ProductType.CONTENT:
        return theme.colors.tertiaryOrange;
      case Product.ProductType.WEBCAST:
      case Product.SubscriptionProductType.WEBCAST:
        return theme.colors.tertiaryTeal;
      case Product.ProductType.BUNDLE:
        return theme.colors.secondaryDarkBlue;
      case Product.ProductType.COURSE:
      case Product.ProductType.EXAM:
      default:
        return theme.colors.primaryDarkPurple;
    }
  },
};

export { theme };
