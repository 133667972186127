import styled from 'styled-components';
import { Salesforce } from 'mxp-schemas';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { getFirmNaming, getPath, getNavList, getNavKeys } from 'utils';
import { FirmInformationNavigationList, Routes, CampaignSlugs } from 'constants/index';
import { Link, ButtonLink, ButtonEnums } from 'components/atoms';
import { NavigationBox } from './NavigationBox';
import { flpPlatformCGMALinkConfigSelector } from 'modules/app/selectors';
import { cimaFeaturesSelector, useFLPFeaturesSelector } from 'modules/firmAdmin/selectors';

interface Props {
  id: string;
  selectedOrganization: Salesforce.Organization | null;
  getOrganizations: () => void;
  getFirmAdminOrganizationDetails: (orgId: string) => void;
}

enum FirmBillingFLP {
  ACCESS_FLP_PLATFORM = 'Access FLP platform',
}

export const PageFirmNavigation: React.FC<Props> = ({
  id,
  selectedOrganization,
  getOrganizations,
  getFirmAdminOrganizationDetails,
}) => {
  const flpPlatformCGMALink = useSelector(flpPlatformCGMALinkConfigSelector);
  useEffect(() => {
    if (!selectedOrganization) getOrganizations();
  }, [selectedOrganization, getOrganizations]);

  useEffect(() => {
    if (id && selectedOrganization) {
      getFirmAdminOrganizationDetails(id);
    }
  }, [getFirmAdminOrganizationDetails, id, selectedOrganization]);

  const firmInfoList = useMemo(() => getNavKeys(id, FirmInformationNavigationList), [id]);

  const useFLPFeatures = useSelector(useFLPFeaturesSelector);
  const useCimaFeatures = useSelector(cimaFeaturesSelector);

  const { rosterNavList: firmRosterList, billingNavList: firmBillingList } = useMemo(
    () => getNavList(id, useFLPFeatures, useCimaFeatures, selectedOrganization),
    [id, useCimaFeatures, useFLPFeatures] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <StyledNavContainer>
        <NavigationBox
          iconName="user circle"
          navTitle="Company Details"
          mainLinkTo={generatePath(getPath(Routes.FIRM_INFO_TAB_BASIC_COMPANY_DETAILS), { orgId: id || '/' })}
        >
          {firmInfoList.map(item => (
            <StyledLink key={item.label} testId={`navigation${item.key}`} to={item.key} isExternal={false}>
              {item.label}
            </StyledLink>
          ))}
          {useCimaFeatures && (
            <>
              <StyledLearnMoreText>
                If you are interested in organization billing, select the chat icon to the right of your screen, or{' '}
                <Link to="https://www.aicpa.org/help" isExternal>
                  visit our help page
                </Link>{' '}
                for frequently asked questions and other contact information.
              </StyledLearnMoreText>

              <StyledButtonLink
                size={ButtonEnums.sizes.small}
                variant={ButtonEnums.variants.primary}
                testId="find-out-more"
                to={generatePath(getPath(Routes.CAMPAIGNS), {
                  slug: CampaignSlugs.FIRM_LEAD_CAPTURE_FORM,
                })}
              >
                Find out more about organizational billing
              </StyledButtonLink>
            </>
          )}
        </NavigationBox>

        <NavigationBox
          iconName="setting"
          navTitle={`${getFirmNaming(useCimaFeatures)} Roster`}
          mainLinkTo={generatePath(getPath(Routes.FIRM_ROSTER_TAB_MANAGE), { orgId: id || '/' })}
        >
          {firmRosterList.map(item => (
            <StyledLink key={item.label} testId={`navigation${item.key}`} to={item.key}>
              {item.label}
            </StyledLink>
          ))}
        </NavigationBox>

        <NavigationBox
          iconName="file text"
          navTitle={`${getFirmNaming(useCimaFeatures)} Billing`}
          mainLinkTo={generatePath(getPath(Routes.FIRM_BILLING_TAB_MANAGE), { orgId: id || '/' })}
        >
          {firmBillingList.map(item => (
            <StyledLink key={item.label} testId={`navigation${item.key}`} to={item.key}>
              {item.label}
            </StyledLink>
          ))}
          <StyledLink
            key={FirmBillingFLP.ACCESS_FLP_PLATFORM}
            testId={`navigation${flpPlatformCGMALink}`}
            to={flpPlatformCGMALink}
            isExternal={true}
            isHide={!useFLPFeatures}
          >
            {FirmBillingFLP.ACCESS_FLP_PLATFORM}
          </StyledLink>
        </NavigationBox>
      </StyledNavContainer>
    </>
  );
};

const StyledNavContainer = styled.div`
  display: grid;
  align-items: stretch;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: ${props => props.theme.pxToRem(10)};
`;

const StyledLink = styled(Link)<{ isHide?: boolean }>`
  color: ${props => props.theme.colors.primaryPurple};
  text-decoration: none;
  margin-bottom: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.fontSizes.s};
  display: ${props => (props.isHide ? `none` : `block`)};
`;

const StyledLearnMoreText = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  margin-top: ${props => props.theme.pxToRem(10)};
  margin-bottom: ${props => props.theme.pxToRem(10)};
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&&&&& {
    margin-bottom: ${props => props.theme.pxToRem(20)};
    padding-left: ${props => props.theme.pxToRem(5)};
    padding-right: ${props => props.theme.pxToRem(5)};
  }
`;
