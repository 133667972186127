import { industrySlugIdMapSelector } from './selectors';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Helpers
// ------------------------------------

/*
 * Convert industry filter slugs to industry ids
 */
export const convertIndustriesSlugsToIds = (industries: string | string[], state: State.Root): string[] | undefined => {
  if (!industries) return;
  const industriesSlugs: string[] = Array.isArray(industries) ? industries : [industries];
  // Call industriesSlugIdMapSelector only if industriesSlugs exist
  const industriesSlugIdMap: { [key: string]: string } = industrySlugIdMapSelector(state);
  // Get industries ids array from slugs if exist
  return industriesSlugs.map(slug => industriesSlugIdMap[slug]).filter(Boolean);
};

/*
 * slug id hash map creator
 */
export const slugIdMapCreator = (industries: any[] | null): { [key: string]: string } => {
  if (!industries) return emptyObject;
  return industries.reduce((acc: any, industry: State.Industry) => {
    acc[industry.slug] = industry.id;
    return acc;
  }, {});
};
