import React from 'react';
import styled from 'styled-components';
import progressBarImg from '../../../resources/pics/progress-bar-bg.png';
import { OnlyDesktop, OnlyMobile } from '../ResponsiveHelpers/ResponsiveHelpers';
import { ProgressBarMapping } from './ProgressBarMapping';
import { membershipProgressBarSelector } from 'modules/membership/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { currentRenewalPageCount, currentPageStep, currentSignUpPageCount } from './ProgressBarUtils';
import { userFirstNameSelector } from 'modules/user/selectors';
import { pageStepSelector } from 'modules/checkout/selectors';
import { Checkout } from 'mxp-schemas';
import { setCheckoutPage } from 'modules/checkout/actions';
import { isPaperBillingSelector } from 'modules/startup/selectors';

// prettier-ignore
export const ProgressBar: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { pathname: currPath, hash: currHash } = useLocation();

  const { isMembershipForRenewal, isCredentialMemberJourney, isSectionsJourney } = useSelector(membershipProgressBarSelector);
  const userFirstName = useSelector(userFirstNameSelector);
  const pageCheckoutSteps = useSelector(pageStepSelector);
  const isPaperBilling = useSelector(isPaperBillingSelector);

  const isPreSignUp = Boolean(matchPath(currPath, { path: getPath(Routes.PRE_SIGN_UP), exact: true, strict: false }));
  const isPackageBuilder = Boolean(matchPath(currPath, { path: getPath(Routes.MEMBERSHIP_FORM), exact: true, strict: false }));
  const isApplicationForm = Boolean(matchPath(currPath.replace(currPath.split('/')[3], ''), {path: getPath(Routes.APPLICATION_FORM), exact: true, strict: false }));

  const isPageAddress: boolean = pageCheckoutSteps === Checkout.CheckoutStep.ADDRESS;
  const isPagePayment: boolean = pageCheckoutSteps === Checkout.CheckoutStep.PAYMENT;
  const isCheckoutPage: boolean = isPageAddress || isPagePayment;

  const currPathName = isPackageBuilder
    ? currPath.replace(currPath.split('#')[1], '')
    : isMembershipForRenewal
    ? currPath
    : currPath.replace(currPath.split('/')[3], '');

  const backToAddress = React.useCallback((): void => {
    dispatch(setCheckoutPage(Checkout.CheckoutStep.ADDRESS));
  }, [setCheckoutPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const headerContent = isMembershipForRenewal ? (
    <StyledHeaderContainer>
      <StyledGreetings>
        Welcome back <StyledName>{userFirstName || ''}</StyledName>
      </StyledGreetings>
      <StyledStepperSubHeader>
        <StyledSteps>Step {currentRenewalPageCount(currPath)}</StyledSteps>:&nbsp;
        {currentPageStep(currPath, isMembershipForRenewal)}
      </StyledStepperSubHeader>
    </StyledHeaderContainer>
  ) : isPreSignUp ? (
    <>
      <OnlyDesktop>
        <StyledHeaderContainer>
          <StyledStepperHeader>A whole new journey awaits you</StyledStepperHeader>
          <StyledStepperSubHeader>Become a Member Today!</StyledStepperSubHeader>
        </StyledHeaderContainer>
      </OnlyDesktop>
      <OnlyMobile>
        <StyledHeaderContainer>
          <StyledStepperHeader>Elevate your career with benefits exclusively for you.</StyledStepperHeader>
          <StyledStepperSubHeader>Become a Member Today!</StyledStepperSubHeader>
        </StyledHeaderContainer>
      </OnlyMobile>
    </>
  ) : (
    <StyledHeaderContainer>
      <StyledStepperHeader>Become an AICPA Member Today!</StyledStepperHeader>
      <StyledStepperSubHeader>
        <StyledSteps>Step {currentSignUpPageCount(currPath, isPageAddress, isPagePayment)}</StyledSteps>:&nbsp;
        {currentPageStep(currPath, isMembershipForRenewal, isPageAddress, isPagePayment)}
      </StyledStepperSubHeader>
    </StyledHeaderContainer>
  );

  const renderProgressBarMapping = (
    <>
      <ProgressBarMapping
        isMembershipForRenewal={isMembershipForRenewal}
        isPackageBuilder={isPackageBuilder}
        currPath={currPath}
        currHash={currHash}
        currPathName={currPathName}
        isCredentialsJourney={isCredentialMemberJourney}
        isSectionsJourney={isSectionsJourney}
        isPageAddress={isPageAddress}
        isPagePayment={isPagePayment}
        backToAddress={backToAddress}
        isPaperBilling={isPaperBilling}
      />
    </>
  );

  return (
    <StyledBackground progressBarImg={progressBarImg}>
      <StyledStepperBar 
        isPackageBuilder={isPackageBuilder} 
        isPreSignUpPage={isPreSignUp} 
        isApplicationForm={isApplicationForm} 
        isCheckoutPage={isCheckoutPage}
      >
        <StyledStepperBarHeader>{headerContent}</StyledStepperBarHeader>
        <StyledStepperBarContent>{renderProgressBarMapping}</StyledStepperBarContent>
      </StyledStepperBar>
    </StyledBackground>
  );
};

const StyledBackground = styled.div<{ progressBarImg: any }>`
  width: 100% !important;
  background-image: ${props => `url(${props.progressBarImg}), linear-gradient(to right, #5A1D55, #300E27)`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const StyledStepperBar = styled.div<{
  isPackageBuilder: boolean;
  isPreSignUpPage: boolean;
  isApplicationForm: boolean;
  isCheckoutPage: boolean;
}>`
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: ${props =>
      props.isApplicationForm
        ? props.theme.pxToRem(1235)
        : props.isCheckoutPage
        ? props.theme.pxToRem(1125)
        : 'calc(100% - 31rem)'};
    max-width: ${props => (props.isApplicationForm || props.isCheckoutPage) && '100%'};
    padding-top: ${props =>
      props.isPackageBuilder || props.isPreSignUpPage ? props.theme.pxToRem(50) : props.theme.pxToRem(40)};
    padding-bottom: 30px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(15)};
    display: flex;
    align-items: left;
    justify-content: left;
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    margin-left: ${props => props.theme.pxToRem(20)};
  }
`;

const StyledHeaderContainer = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    position: relative;
  }
`;

const StyledGreetings = styled.div`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.s};
  margin-bottom: ${props => props.theme.pxToRem(16)};
`;

const StyledName = styled.span`
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const StyledStepperHeader = styled.div`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.s};
  margin-bottom: ${props => props.theme.pxToRem(6)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(15)};
  }
`;

const StyledStepperSubHeader = styled.div`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.xl};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.bold};
    margin-bottom: ${props => props.theme.pxToRem(15)};
  }
`;

const StyledStepperBarHeader = styled.div`
  ${props => props.theme.mediaQueries.desktopOnly} {
    color: white;
  }
`;

const StyledStepperBarContent = styled.div`
  float: right;
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: none;
  }
`;

const StyledSteps = styled.span`
  font-weight: ${props => props.theme.fontWeights.light};
`;
