import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  userMembershipRelatedAddonsSelector,
  isRenewalSelector,
  membershipSubscriptionsSelector,
  userMembershipBenefitsSelector,
} from 'modules/membership/selectors';
import { removeMembershipPackageRelatedAddon, addMembershipPackageRelatedAddon } from 'modules/membership/actions';
import { MembershipCard } from 'components/organisms';
import { HandleAddToCartProps, AddNewComponentProps, StyledGrid, StyledGridColumn } from './AddNewMembershipProduct';
import { productTypeToSlug, fromCentsFormat, formatPrice } from 'utils';

export const AddNewAddons = ({ list, currency, dispatch, variantsPriceInfoForUser }: AddNewComponentProps) => {
  const userChoicesAddons = useSelector(userMembershipRelatedAddonsSelector);
  const isRenewal = useSelector(isRenewalSelector);
  const productListData = useSelector(membershipSubscriptionsSelector);
  const { list: benefitsList } = useSelector(userMembershipBenefitsSelector);

  const handleAddToCart = useCallback(
    ({ productId, sku, isSelected }: HandleAddToCartProps) => {
      isSelected
        ? dispatch(removeMembershipPackageRelatedAddon(sku))
        : dispatch(addMembershipPackageRelatedAddon(productId, sku));
    },
    [dispatch]
  );

  return (
    <StyledGrid centered columns={2}>
      {[...list].map(data => {
        const isSelected = userChoicesAddons.some(item => item.productId === data.productId);
        const isCurrent =
          isRenewal && productListData?.lineItems.some(lineItem => lineItem.variant?.sku === data.variants[0].sku);
        const showAsIncluded = benefitsList?.some(item => item.productId === data.productId);

        return (
          <StyledGridColumn key={data.variants[0].sku}>
            <MembershipCard
              headerTitle={data.name}
              description={data.description}
              formattedPrice={formatPrice(fromCentsFormat(variantsPriceInfoForUser(data)), currency.label)}
              isCardSelected={isSelected}
              isCurrent={isCurrent}
              slug={`${productTypeToSlug(data.productType as any)}/${data.slug}`}
              handleClick={handleAddToCart.bind(null, {
                productId: data.productId,
                sku: data.variants[0].sku,
                isSelected,
              })}
              productDetails={data}
              isExistingMember={true}
              showAsIncluded={showAsIncluded}
              isAddNewMembershipProduct
              shouldShowNewPackageBuilder
            />
          </StyledGridColumn>
        );
      })}
    </StyledGrid>
  );
};
