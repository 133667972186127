import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { OnlyDesktopCSS, OnlyMobileCSS, Link, Button, ButtonEnums } from 'components/atoms';
import { DOWNLOAD_EVENT, handleEvent } from 'utils/Analytics';
import { Contentful } from 'mxp-schemas';
import { ReactComponent as GetApp } from 'resources/images/icon-dev-ic-get-app.svg';
import download from 'downloadjs';
import { ReactComponent as ArrowRight } from 'resources/images/arrow-forward.svg';
import { ProductModal } from 'components/organisms';
import { membershipProductSelector } from 'modules/membership/selectors';

/**
 * Benefit Card
 * @category Membership
 * MART-877
 */

interface Props {
  BenefitName: string;
  BenefitBody: string;
  IconUrl: string;
  IconWidth: number;
  IconHeight: number;
  CtaUrl: any;
  downloads: any;
  CtaType: string;
  isRegionalPathwayLandingPage: boolean;
}
let IconWidthHandler = 24;
let IconHeightHandler = 24;

export const BenefitCard: React.FC<Props> = React.memo(
  ({
    BenefitName,
    BenefitBody,
    IconUrl,
    IconWidth,
    IconHeight,
    CtaUrl,
    CtaType,
    downloads,
    isRegionalPathwayLandingPage,
  }: Props) => {
    const [isOpenProductModal, setIsOpenProductModal] = useState(false);
    const [productDetails, setProductDetails] = useState<any>(null);
    IconWidthHandler = IconWidth === 0 ? 30 : IconWidth;
    IconHeightHandler = IconHeight === 0 ? 30 : IconHeight;

    const membershipProducts = useSelector(membershipProductSelector);

    // --- needed to prevent empty <p> tags from being included from Contentful as this messes up the UI
    const emptyPTagRegEx = /<p><\/p>/gi;
    const formattedBenefitBody = BenefitBody.replace(emptyPTagRegEx, '');
    const formattedBenefitName = BenefitName.replace(emptyPTagRegEx, '');
    // ----

    const [fileLoading, setFileLoading] = useState(false);

    const handleDownloadClick = useCallback((): void => {
      const fileNames = downloads
        .map((downloadable: Contentful.LandingPages.DownloadItemProps) => downloadable.fileNames.join())
        .join();
      handleEvent({ filename: fileNames }, DOWNLOAD_EVENT);
      setFileLoading(true);
      Promise.all(
        downloads.map((item: Contentful.LandingPages.DownloadItemProps) =>
          fetch(item.fileLink)
            .then(response => response.blob())
            .then(result => {
              download(result, item.fileNames.join());
            })
        )
      )
        .then(() => {
          setFileLoading(false);
        })
        .catch(() => {
          setFileLoading(false);
        });
    }, [downloads]);

    const handleLearnMoreClick = useCallback(() => {
      if (isRegionalPathwayLandingPage) {
        const findRegionalPathwayProductByProductSlug = membershipProducts?.variants?.find(
          (section: State.Variant) => section?.sku === CtaUrl // CTA URL from contentful should have a value equivalent to specific variant SKU in commercetools
        );
        if (findRegionalPathwayProductByProductSlug) {
          setProductDetails(findRegionalPathwayProductByProductSlug);
          setIsOpenProductModal(true);
        }
      }
    }, [CtaUrl, membershipProducts, isRegionalPathwayLandingPage]);

    const handleModal = useCallback(() => {
      setIsOpenProductModal(prev => !prev);
    }, []);

    return (
      <Container>
        {isOpenProductModal && productDetails && (
          <ProductModal
            isOpenModal={isOpenProductModal}
            headerText={productDetails?.name || ''}
            description={productDetails?.description || ''}
            overview={productDetails?.eligibility || ''}
            handleModal={handleModal}
            imageUrl={productDetails?.variant?.images?.[0]?.imageUrl || ''}
            productType={productDetails.productType}
          />
        )}
        <OnlyDesktopCSS>
          <StyledCard>
            <LeftAlign>
              <StyledH4>
                <StyledIcon src={IconUrl} />
                <StyledDivTitle dangerouslySetInnerHTML={{ __html: formattedBenefitName }} />
              </StyledH4>
            </LeftAlign>
            <LeftAlign>
              <StyledDivDescription dangerouslySetInnerHTML={{ __html: formattedBenefitBody }} />
            </LeftAlign>
            <br />
            <StyledTextBold>
              {Boolean(CtaUrl) && (
                <div style={{ display: 'flex' }}>
                  {!CtaType || CtaType === Contentful.LandingPages.LandingPageSubBlockCtaType.Redirect ? (
                    <StyledLink testId="benefit-card" to={CtaUrl} target={'_blank'}>
                      Learn more
                    </StyledLink>
                  ) : (
                    CtaType === Contentful.LandingPages.LandingPageSubBlockCtaType.PopIn && (
                      <StyledButtonLink
                        testId="benefit-card"
                        variant={ButtonEnums.variants.link}
                        onClick={handleLearnMoreClick}
                      >
                        Learn more
                      </StyledButtonLink>
                    )
                  )}
                  <StyledArrowRight />
                </div>
              )}
              {downloads && (
                <StyledButtonLink
                  testId="download-button"
                  variant={ButtonEnums.variants.link}
                  size={ButtonEnums.sizes.medium}
                  icon={<GetApp />}
                  iconPosition={ButtonEnums.iconPosition.right}
                  onClick={handleDownloadClick}
                  loading={fileLoading}
                  disabled={fileLoading}
                >
                  Download Application
                </StyledButtonLink>
              )}
            </StyledTextBold>
          </StyledCard>
          <StyledBottom />
        </OnlyDesktopCSS>
        <OnlyMobileCSS>
          <StyledCard>
            <StyledH4>
              <StyledIcon src={IconUrl} />
              <StyledDivTitle dangerouslySetInnerHTML={{ __html: formattedBenefitName }} />
            </StyledH4>
            <LeftAlign>
              <StyledDivDescription dangerouslySetInnerHTML={{ __html: formattedBenefitBody }} />
            </LeftAlign>
            <StyledTextBold>
              {Boolean(CtaUrl) && (
                <div style={{ display: 'flex' }}>
                  {!CtaType || CtaType === Contentful.LandingPages.LandingPageSubBlockCtaType.Redirect ? (
                    <StyledLink testId="benefit-card" to={CtaUrl} target={'_blank'}>
                      Learn more
                    </StyledLink>
                  ) : (
                    CtaType === Contentful.LandingPages.LandingPageSubBlockCtaType.PopIn && (
                      <StyledButtonLink
                        testId="benefit-card"
                        variant={ButtonEnums.variants.link}
                        onClick={handleLearnMoreClick}
                      >
                        Learn more
                      </StyledButtonLink>
                    )
                  )}
                  <StyledArrowRight />
                </div>
              )}
              {downloads && (
                <StyledButtonLink
                  testId="download-button"
                  variant={ButtonEnums.variants.link}
                  size={ButtonEnums.sizes.medium}
                  icon={<GetApp />}
                  iconPosition={ButtonEnums.iconPosition.right}
                  onClick={handleDownloadClick}
                  loading={fileLoading}
                  disabled={fileLoading}
                >
                  Download Application
                </StyledButtonLink>
              )}
            </StyledTextBold>
          </StyledCard>
          <StyledBottom />
        </OnlyMobileCSS>
      </Container>
    );
  }
);

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  position: relative;
  border-radius: ${props => props.theme.pxToRem(10)};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(10)} 0
    ${props => props.theme.colors.neutralGrey3};
  margin: 0 auto;
  width: 100%;
  min-height: ${props => props.theme.pxToRem(240)};
  background-color: ${props => props.theme.colors.neutralWhite};
`;

const StyledCard = styled.div`
  border-radius: ${props => `${props.theme.pxToRem(10)} ${props.theme.pxToRem(10)} 0 0`};
  width: auto;
  min-height: ${props => props.theme.pxToRem(240)};
  margin: ${props => `0 ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} 0`};
  padding: ${props => `${props.theme.pxToRem(36)} ${props.theme.pxToRem(18)} 0`};
  display: grid;
  ${props => props.theme.mediaQueries.mobileOnly} {
    border-radius: ${props => `${props.theme.pxToRem(10)} ${props.theme.pxToRem(10)} 0 0`};
    box-shadow: ${props => `0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(10)} 0`} rgba(0, 0, 0, 0.1);
    height: ${props => props.theme.pxToRem(192)};
    margin: ${props => `0 0 ${props.theme.pxToRem(16)}`};
    padding: ${props => `${props.theme.pxToRem(36)} ${props.theme.pxToRem(18)} 0`};
    object-fit: 'contain';
  }
`;

const StyledBottom = styled.div`
  width: auto;
  height: ${props => props.theme.pxToRem(8)};
  border-radius: ${props => `0 0 ${props.theme.pxToRem(8)} ${props.theme.pxToRem(8)}`};
  background-image: linear-gradient(to right, ${props => props.theme.colors.primaryPurple} 3%, rgba(154, 32, 94, 0.8));
  margin: ${props => `${props.theme.pxToRem(-20)} 0 ${props.theme.pxToRem(50)} 0`};
  ${props => props.theme.mediaQueries.mobileOnly} {
    top: 95%;
    border-radius: ${props => `0 0 ${props.theme.pxToRem(8)} ${props.theme.pxToRem(8)}`};
    height: ${props => props.theme.pxToRem(8)};
    background-image: linear-gradient(to right, #672d89 3%, rgba(154, 32, 94, 0.8));
  }
`;

const LeftAlign = styled.p`
  text-align: left;
  font-size: ${props => props.theme.pxToRem(14)};
`;

const StyledTextBold = styled.p`
  font-size: ${props => props.theme.pxToRem(14)};
  font-weight: bold;
  display: flex;
  position: absolute;
  top: 70%;
  margin-top: ${props => props.theme.pxToRem(14)};
  justify-content: space-between;
  width: 92%;
  display: flex;
`;

const StyledH4 = styled.h4`
  display: flex;
`;
const StyledArrowRight = styled(ArrowRight)`
  height: 20px;
`;

const StyledIcon = styled.img`
  width: ${props => props.theme.pxToRem(IconWidthHandler)};
  height: ${props => props.theme.pxToRem(IconHeightHandler)};
  margin-right: ${props => props.theme.pxToRem(10)};
`;

const StyledDivTitle = styled.div`
  margin: 0px;
  padding: 0px;
`;
const StyledDivDescription = styled.div`
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  height: ${props => props.theme.pxToRem(80)};
  display: flex;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledButtonLink = styled(Button)`
  &&&&&& {
    span {
      text-decoration: none;
    }
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;
