import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.aml,
  (aml: State.AMLForms): State.AMLForms => aml
);

export const isPdfSelector = createSelector(rootSelector, (AMLForm: State.AMLForms): boolean => AMLForm.isPdf);

export const amlFormSelector = createSelector(rootSelector, (aml: State.AML): State.AML => aml);

export const almlLoadingSelector = createSelector(rootSelector, (aml: State.AMLForms): boolean => aml.isLoading);

export const amlRenewalSelector = createSelector(rootSelector, (aml: State.AMLForms): boolean => aml.isRenewal);
