import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import { Button, ButtonEnums, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { setCPAStatus, cpaStatusLoading } from 'modules/user/actions';
import { cpaStatusLoadingSelector } from 'modules/user/selectors';

export const CPABanner = React.memo(() => {
  const loading = useSelector(cpaStatusLoadingSelector);
  const [answer, setAnswer] = useState(false);
  const dispatch = useDispatch();

  const handleClickButton = (result: boolean) => {
    setAnswer(result);
    dispatch(cpaStatusLoading(result));
    dispatch(setCPAStatus(result));
  };

  return (
    <StyledContainer>
      <Container>
        <OnlyMobile>
          <br />
          <p>Are you a licensed CPA?</p>
          <p className="sub-text">Confirm your current status for the most relevant experience.</p>
        </OnlyMobile>
      </Container>
      <Container>
        <Wrapper>
          {/* Following div is fix for SSR. Do not remove! */}
          <div>
            <OnlyDesktop>
              <p>Are you a licensed CPA?</p>
              <p className="sub-text">Confirm your current status for the most relevant experience.</p>
            </OnlyDesktop>
          </div>
          <StyledButtonWrapper>
            <Button
              testId={'cpa-banner-yes'}
              variant={ButtonEnums.variants.secondary}
              size={ButtonEnums.sizes.medium}
              onClick={() => handleClickButton(true)}
              loading={loading && answer}
              disabled={loading}
              bordered
              fluid
            >
              <p className="button-text">Yes, I am</p>
            </Button>
            <Button
              testId={'cpa-banner-no'}
              variant={ButtonEnums.variants.secondary}
              size={ButtonEnums.sizes.medium}
              onClick={() => handleClickButton(false)}
              loading={loading && !answer}
              disabled={loading}
              bordered
              fluid
            >
              <p className="button-text">No, I am not</p>
            </Button>
          </StyledButtonWrapper>
        </Wrapper>
      </Container>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 109;
  width: 100vw;
  background-color: ${props => props.theme.colors.neutralGrey1};
  backdrop-filter: blur(${props => props.theme.pxToRem(3)});

  p {
    margin-bottom: 0;
    line-height: 1.33;
    font-size: ${props => props.theme.pxToRem(22)};
    font-weight: ${props => props.theme.fontWeights.light};
  }

  .button-text {
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.medium};
    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.fontSizes.s};
    }
  }

  .sub-text {
    font-size: ${props => props.theme.fontSizes.xs};
    padding-top: ${props => props.theme.pxToRem(10)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.pxToRem(100)};
`;

const StyledButtonWrapper = styled.div`
  width: ${props => props.theme.pxToRem(360)};

  button {
    ${props => props.theme.mediaQueries.desktopOnly} {
      max-width: ${props => props.theme.pxToRem(170)};
    }

    :first-child {
      margin-right: ${props => props.theme.pxToRem(20)};
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }

  display: flex;
  align-items: center;
  height: 100%;

  &&& > button:first-child {
    margin-right: ${props => props.theme.pxToRem(12)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-right: ${props => props.theme.pxToRem(20)};
    }
  }
`;
