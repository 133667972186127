import React from 'react';
import styled from 'styled-components';

interface Props {
  contentSource: string;
  hasDotSeparator?: boolean;
}

export const ContentSource: React.SFC<Props> = React.memo(({ contentSource, hasDotSeparator = false }) => (
  <StyledContentSource tabIndex={0} data-testid="content-source">
    {hasDotSeparator && '・'}
    {contentSource}
  </StyledContentSource>
));

const StyledContentSource = styled.small`
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey5};
`;
