import React from 'react';
import { Search as SemanticUISearch, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { sanitizeSearchString } from 'utils';

interface InquiryFormBulkOrderSearchInputProps {
  placeholder?: string;
  searchResults: State.InquiryFormSearchResult[];
  isResultSelected: boolean;
  searchLoading: boolean;
  showNoResultsMessage: boolean;
  searchString: string;
  setSearchString: (value: string) => void;
  onFocus: () => void;
  fetchResults: (searchQuery: string) => void;
  handleResultSelection: (selection: State.InquiryFormSearchResult) => void;
  handleClearSelection: () => void;
}

export const InquiryFormBulkOrderSearchInput: React.FC<InquiryFormBulkOrderSearchInputProps> = ({
  placeholder = '',
  searchResults,
  isResultSelected,
  searchLoading,
  showNoResultsMessage,
  searchString,
  setSearchString,
  onFocus,
  fetchResults,
  handleResultSelection,
  handleClearSelection,
}) => {
  const MIN_QUERY_LENGTH: number = 3;

  const handleSearchChange = React.useCallback(
    (e, data) => {
      const query: string = data.value;
      setSearchString(query);
      handleClearSelection();

      if (query.length >= MIN_QUERY_LENGTH && fetchResults) fetchResults(sanitizeSearchString(query));
    },
    [fetchResults, handleClearSelection, setSearchString]
  );

  const handleSearchSelectionChange = React.useCallback(
    (e, data) => {
      setSearchString(data.result.title);
      handleResultSelection(data.result);
    },
    [handleResultSelection, setSearchString]
  );

  const handleClearSearch = () => {
    setSearchString('');
    handleClearSelection();
  };

  return (
    <StyledSearch
      icon={isResultSelected ? <Icon name="delete" link onClick={handleClearSearch} /> : <Icon name="search" />}
      disabled={isResultSelected}
      loading={searchLoading}
      onResultSelect={handleSearchSelectionChange}
      onSearchChange={handleSearchChange}
      onClick={onFocus}
      results={searchResults}
      value={searchString}
      placeholder={placeholder}
      showNoResults={!searchLoading && showNoResultsMessage}
    />
  );
};

const StyledSearch = styled(SemanticUISearch)`
  &&&&&& {
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSizes.s};

    &&&.ui.search > .results {
      overflow: auto;
      height: ${props => props.theme.pxToRem(200)};
      width: 100%;
      border-radius: 0;
      margin-top: 0;
      box-shadow: none;
      border-color: ${props => props.theme.colors.primaryPurple};
      border-top: none;
      .result {
        font-size: ${props => props.theme.fontSizes.xs};
      }
    }

    .ui.input {
      width: 100%;
    }
    .ui.icon.input > input {
      color: ${props => props.theme.colors.primaryPurple};
      border-radius: ${props => props.theme.pxToRem(0)};
    }

    .ui.disabled.input,
    .ui.input:not(.disabled) input[disabled] {
      opacity: 1;
      &&& {
        color: ${props => props.theme.colors.primaryPurple};
      }
    }

    .ui.icon.input > i.icon,
    .ui.icon.input > i.icon:not(.link),
    i.icon.delete:before {
      opacity: 1;
      color: ${props => props.theme.colors.primaryPurple};
    }

    .ui.input.focus > input,
    .ui.input > input:focus {
      border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.primaryPurple};
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
`;
