import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ContentTrendsFollow, FollowTrendIF } from 'components/organisms/ContentTrendsFollow';
import { userTrendsPrefSelector, userPrefSelector } from 'modules/user/selectors';
import { toggleTrendPrefAndUpdateGoldenRecord } from 'modules/user/actions';
import { contentTrendsFollowSelector } from 'modules/content/selectors';

const mapActionCreators = (dispatch: Dispatch) => ({
  toggleTrendPref(trendId: string, isOnEvent: boolean): void {
    dispatch(toggleTrendPrefAndUpdateGoldenRecord(trendId, isOnEvent));
  },
});

const mapStateToProps = (initState: State.Root) => {
  const trends: FollowTrendIF[] = contentTrendsFollowSelector(initState);

  return (state: State.Root) => ({
    trends,
    userTrendsPref: userTrendsPrefSelector(state),
    toggleTrendPref: userPrefSelector(state),
  });
};

export const ContentTrendsFollowContainer = connect(mapStateToProps, mapActionCreators)(ContentTrendsFollow);
