import moment from 'moment-timezone';
import { Product } from 'mxp-schemas';
import { dataConvertion, dataConvertionCheckbox } from 'modules/mip/helpers';

export const mipRenewalSeasonDatesCalculator = (renewalSeasonStart: string, renewalSeasonEnd: string) => {
  let mipRenewalSeasonStartDate = moment(renewalSeasonStart, 'MMM-DD');
  const mipRenewalSeasonEndDate = moment(renewalSeasonEnd, 'MMM-DD');

  if (mipRenewalSeasonStartDate > mipRenewalSeasonEndDate) {
    const newStartdate = new Date(mipRenewalSeasonStartDate.toString());
    newStartdate.setFullYear(newStartdate.getFullYear() - 1);
    mipRenewalSeasonStartDate = moment(newStartdate, 'MMM-DD');
  }

  return { mipRenewalSeasonStartDate, mipRenewalSeasonEndDate };
};

export const mipRenewal = async (
  renewalSeasonStart?: any,
  renewalSeasonEnd?: any,
  mipData?: any,
  productItem?: any,
  isMipRenewalSeason?: any,
  pathwaySelector?: any
) => {
  const { mipRenewalSeasonStartDate, mipRenewalSeasonEndDate } = mipRenewalSeasonDatesCalculator(
    renewalSeasonStart,
    renewalSeasonEnd
  );
  const applicationPartCreatedDate = moment(mipData?.contactDetails?.isApplicationPartRenewal?.[0]?.CreatedDate);
  const mipProduct: any = productItem?.find((product: any) => product.name === Product.CredentialKey.CIMA_MIP);

  // RENEWAL
  return (
    // it will return true if renewal season && (existing renewal date is !between renewal season date || no existing Mip Renewal)
    isMipRenewalSeason &&
    mipProduct &&
    ((mipData && !applicationPartCreatedDate.isBetween(mipRenewalSeasonStartDate, mipRenewalSeasonEndDate)) ||
      !mipData?.contactDetails?.isApplicationPartRenewal?.[0]?.CreatedDate)
  );
};

export const mipRenewalPayload = (mipData: any, practiceRequirementAttestation: any, aml: any) => {
  const practiceList = mipData?.contactDetails?.practiceList.map((mip: any) => {
    return {
      id: '',
      name: mip.name,
      personAccountKey: mip.personAccountKey,
      website: mip.website,
      totalOfStaff: mip.totalOfStaff,
      primaryPractice: mip.primaryPractice,
      practiceName: mip.practiceName,
      bodyName: mip.bodyName,
      functionalSpecialism: mip.functionalSpecialism,
      brokerInsurerName: mip.brokerInsurerName,
      policyNumber: mip.policyNumber,
      expiryDate: mip.expiryDate,
      fax: mip.fax?.join('-'),
      nominatedPerson: mip.nominatedPerson,
    };
  });
  const contactPayload = {
    id: mipData?.contactDetails?.id,
    accountId: mipData?.contactDetails?.accountId,
    parentId: mipData?.contactDetails?.parentId,
    firstName: mipData?.contactDetails?.firstName,
    lastName: mipData?.contactDetails?.lastName,
    telNumbers: mipData?.contactDetails?.telNumbers,
    mobileNumbers: mipData?.contactDetails?.mobileNumbers,
    practiceList,
    isCreateContactRenewal: true,
  };
  const practiceRequirementPayload = {
    brokerInsurerName: mipData?.practiceRequirement?.brokerInsurerName,
    policyNumber: mipData?.practiceRequirement?.policyNumber,
    expiryDate: mipData?.practiceRequirement?.expiryDate ? mipData?.practiceRequirement?.expiryDate : null,
    primaryPracticeName: mipData?.practiceRequirement?.practiceName,
    setPrimaryPractice: true,
    fax: mipData?.practiceRequirement?.fax?.join('-'),
    nominatedPerson: mipData?.practiceRequirement?.nominatedPerson,
    professionalBodyName: mipData?.practiceRequirement?.bodyName,
    functionalSpecialism: mipData?.practiceRequirement?.functionalSpecialism,
    continuityLastName: mipData?.practiceRequirement?.continuityLastName,
    continuityFirstName: mipData?.practiceRequirement?.continuityFirstName,
    continuityPracticeName: mipData?.practiceRequirement?.continuityPracticeName,
    continuityMobileNumber: mipData?.practiceRequirement?.continuityMobileNumber,
    continuityFax: mipData?.practiceRequirement?.continuityFax,
    continuityAddressLine1: mipData?.practiceRequirement?.continuityAddressLine1,
    continuityAddressLine2: mipData?.practiceRequirement?.continuityAddressLine2,
    continuityAddressLine3: mipData?.practiceRequirement?.continuityAddressLine3,
    continuityCity: mipData?.practiceRequirement?.continuityCity,
    continuityCountryState: mipData?.practiceRequirement?.continuityCountryState,
    continuityPostalCode: mipData?.practiceRequirement?.continuityPostalCode,
    continuityLocation: mipData?.practiceRequirement?.continuityLocation,
    continuityFaxAreaCode: mipData?.practiceRequirement?.continuityFaxAreaCode,
    continuityMobileAreaCode: mipData?.practiceRequirement?.continuityMobileAreaCode,
    isDeclaration: Boolean(
      practiceRequirementAttestation.attestationPracticeReference && mipData?.practiceRequirement?.id
    ),
    isCreateRenewal: true,
  };
  const amlData = {
    countryOfPractice: aml?.countryOfPractice,
    registeredAMLCTF: dataConvertion(aml?.registeredAMLCTF),
    criminalityCheck: dataConvertion(aml?.criminalityCheck),
    beneficialOwners: dataConvertion(aml?.beneficialOwners),
    residentOverseas: dataConvertion(aml?.residentOverseas),
    mLRiskWithPayroll: dataConvertion(aml?.mLRiskWithPayroll),
    businessRelationship: dataConvertion(aml?.businessRelationship),
    trustChecked: aml?.trustChecked,
    mLRiskWithCashIntensive: dataConvertion(aml?.mLRiskWithCashIntensive),
    amlCTFSupervisorName: aml?.amlCTFSupervisorName,
    beneficialOfficers: dataConvertion(aml?.beneficialOfficers),
    financialCompliance: dataConvertion(aml?.financialCompliance),
    businessType: aml?.businessType,
    businessRules: aml?.businessRules?.toString().split(';') || [],
    registrationNumber: aml?.registrationNumber,
    sizeofClientBase: aml?.sizeofClientBase,
    numberOfStaff: aml?.numberOfStaff?.toString(),
    annualTurnover: aml?.annualTurnover,
    franchise: dataConvertion(aml?.franchise),
    franchiseName: aml?.franchiseName,
    singleMultipleOffice: aml?.singleMultipleOffice,
    partnersInPractice: dataConvertion(aml?.partnersInPractice),
    terroristFinancingThreats: aml?.terroristFinancingThreats,
    companies: dataConvertion(aml?.companies),
    riskWithClient: aml?.riskWithClient,
    clientBaseComposed: aml?.clientBaseComposed,
    clientTypeOrService: aml?.clientTypeOrService,
    clientType: aml?.clientType?.toString().split(';') || [],
    clientsRejected: aml?.clientsRejected?.toString(),
    cddProcesses: dataConvertion(aml?.cddProcesses),
    electronicProviders: dataConvertion(aml?.electronicProviders),
    specifyObligations: dataConvertion(aml?.specifyObligations),
    cddInformation: aml?.cddInformation,
    financialServices: dataConvertion(aml?.financialServices),
    typesOfIndustries: aml?.typesOfIndustries?.toString().split(';') || [],
    clientsBusiness: dataConvertion(aml?.clientsBusiness),
    changesInRisk: dataConvertion(aml?.changesInRisk),
    practiceProvides: aml?.practiceProvides?.toString().split(';') || [],
    higherRiskClients: aml?.higherRiskClients,
    trainingUndertaken: aml?.trainingUndertaken,
    trainingProvidedToStaff: aml?.trainingProvidedToStaff,
    offshoreTCSP: aml?.offshoreTCSP?.toString().split(';') || [],
    cimaMoneyLaundering: dataConvertion(aml?.cimaMoneyLaundering),
    disclosuresHotline: dataConvertion(aml?.disclosuresHotline),
    doCompleteChecks: aml?.doCompleteChecks,
    sanctionExplain: undefined,
    sanctionChecks: false,
    breaches: false,
    licenses: false,
    launderingOfficer: dataConvertion(aml?.launderingOfficer),
    amlAssessments: aml?.amlAssessments,
    suspiciousActivity: dataConvertion(aml?.suspiciousActivity),
    supervisor: aml?.supervisor,
    specifyCountry: aml?.specifyCountry,
    serviceInUK: dataConvertion(aml?.serviceInUK),
    offerInUK: aml?.offerInUK,
    supervisedAMLCTF: dataConvertion(aml?.supervisedAMLCTF),
    theSupervisor: aml?.theSupervisor,
    officeAndLocation: aml?.officeAndLocation,
    systemAndControl: aml?.systemAndControl,
    directorName: aml?.directorName,
    regulatedServices: dataConvertion(aml?.regulatedServices),
    numberOfCompanies: aml?.numberOfCompanies?.toString(),
    registrationName: aml?.registrationName,
    numberOfClients: aml?.numberOfClients,
    secureRecordsCDD: dataConvertion(aml?.secureRecordsCDD),
    recordsOfSanctions: dataConvertion(aml?.recordsOfSanctions),
    licensesFromOFSI: dataConvertion(aml?.licensesFromOFSI),
    receivedTrainingPeps: dataConvertion(aml?.receivedTrainingPeps),
    assetServiceProvider: dataConvertion(aml?.assetServiceProvider),
    designatedPerson: dataConvertion(aml?.designatedPerson),
    irelandFieldName: aml?.irelandFieldName,
    registeredSupervision: dataConvertion(aml?.registeredSupervision),
    checkComplyAML: aml?.checkComplyAML,
    servicesProvidedOutsideCIMA: aml?.servicesProvidedOutsideCIMA,
    specifySectors: aml?.specifySectors,
    specifyJurisdictions: aml?.specifyJurisdictions,
    specifyPracticeProvides: aml?.specifyPracticeProvides,
    submissionsHMRC: aml?.submissionsHMRC,
    numSARSPracticeMade: aml?.numSARSPracticeMade?.toString(),
    addDirectorInfo: aml?.addDirectorInfo,
    reportedToOFSI: dataConvertion(aml?.reportedToOFSI),
    dueDiligence: aml?.dueDiligence,
    diligenceMeasure: aml?.diligenceMeasure,
    natureService: aml?.natureService,
    identifyOwnership: aml?.identifyOwnership,
    tcspServices: aml?.tcspServices,
    companiesFormed: aml?.companiesFormed?.toString(),
    ifNoExplainWhy: aml?.ifNoExplainWhy,
    nameOfLaundering: aml?.nameOfLaundering,
    clientsAsADirector: aml?.clientsAsADirector?.toString(),
    registeredOffice: aml?.registeredOffice?.toString(),
    beneficialOwnerList: aml?.beneficialOwnerList,
    directorPartners: aml?.directorPartners,
    riskAssessment: dataConvertionCheckbox(aml?.riskAssessment),
    policyPractice: dataConvertionCheckbox(aml?.policyPractice),
    assessmentProcess: dataConvertionCheckbox(aml?.assessmentProcess),
  };
  return { contactPayload, practiceRequirementPayload, amlData };
};
