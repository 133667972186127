import { createGlobalStyle } from 'styled-components';

export const GlobalModalDimmerStyle = createGlobalStyle<{ isFullWidthOnMobile?: boolean; blur?: boolean }>`
.ui.page.modals.dimmer.visible.active {
  background-color: transparent;
  ${props =>
    props.blur
      ? `backdrop-filter: blur(2px); filter: blur(2px)`
      : `background-image: linear-gradient(to right, rgba(106,45,135,0.9), rgba(169,61,105,0.9));`}
  padding: 0;

  ${props => props.theme.mediaQueries.mobileOnly} {
    ${props =>
      props.isFullWidthOnMobile &&
      `
    background: none;
  `}
  }
}

${props => props.theme.mediaQueries.mobileOnly} {
  ${props =>
    props.isFullWidthOnMobile &&
    `
    .ui.dimmer::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,.25) !important;
    }

    .ui.dimmer::-webkit-scrollbar-track {
      background: rgba(0,0,0,.1) !important;
    }
`}
`;
