import { RenewMembershipOptions } from 'constants/index';

export const setText = (item: string, isMobile: boolean = false): string => {
  switch (item) {
    case RenewMembershipOptions.ACCOUNT_TYPE:
      return isMobile ? 'Member Type' : 'Membership Type';
    case RenewMembershipOptions.ADD_ONS:
      return 'Add-ons';
    case RenewMembershipOptions.MEMBERSHIP_SECTIONS:
      return 'Sections';
    case RenewMembershipOptions.MEMBERSHIP_CREDENTIALS:
      return 'Credentials';
    case RenewMembershipOptions.SERVICE_TIER:
      return 'Tier';
    default:
      return '';
  }
};
