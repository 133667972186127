import React, { useCallback, useState } from 'react';
import { SemanticICONS, Form, Icon } from 'semantic-ui-react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';
import { IconButton } from 'components/atoms/Icon/Icon';
import { sanitizeSearchString, encodePercentChar, getPath } from 'utils';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { Routes } from 'constants/index';

interface MatchProps {
  params: {
    searchQuery: string;
  };
}

interface ContainerProps {
  active: boolean;
}

interface Props {
  placeholder?: string;
  testId?: string;
  className?: string;
  minLength?: number;
  autoFocus?: boolean;
  icon?: SemanticICONS;
  isInModal?: boolean;
  handleSubmit?(search: string): void;
  onFocus?(): void;
  fetchSuggestions?(searchQuery: string): void;
  clearSuggestions: () => void;
  navigate: (path: string) => void;
}

export const SearchInput: React.FC<Props> = ({
  placeholder = 'Search AICPA-CIMA.com',
  testId = 'search-input-header',
  className = '',
  autoFocus = false,
  minLength = 3,
  icon,
  onFocus,
  handleSubmit,
  fetchSuggestions,
  clearSuggestions,
  navigate,
  isInModal = false,
}) => {
  const match: MatchProps = useRouteMatch();
  const searchQuery: string = (match && match.params && match.params.searchQuery) || '';
  const decodedSearchQuery: string =
    searchQuery && decodeURIComponent(encodePercentChar(searchQuery).replace(/\+/g, ' '));

  const [searchValue, setSearchValue] = useState(decodedSearchQuery);
  const [active, setActive] = useState(false);

  React.useEffect(() => {
    setSearchValue(decodedSearchQuery);
  }, [decodedSearchQuery]);

  const bubbleSubmit = React.useCallback((): void => {
    if (handleSubmit) handleSubmit(searchValue);
  }, [searchValue, handleSubmit]);

  const handleSearchChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const query: string = e.target.value;
      setSearchValue(sanitizeSearchString(query));
      if (query.length >= minLength && fetchSuggestions) fetchSuggestions(query);
    },
    [minLength, fetchSuggestions]
  );

  const handleSearchEnterKey = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === 'Enter') bubbleSubmit();
    },
    [bubbleSubmit]
  );

  const handleClearInputClick = useCallback((): void => {
    if (isInModal) {
      setSearchValue('');
      clearSuggestions();
      return;
    }
    clearSuggestions();
    navigate(getPath(Routes.SEARCH).split(':')[0]);
  }, [clearSuggestions, navigate, isInModal]);

  const handleFocus = useCallback((): void => {
    setActive(true);
    if (onFocus) onFocus();
  }, [onFocus]);

  const handleBlur = useCallback((): void => {
    setActive(false);
  }, []);

  return (
    <FormStyled className={className}>
      <InputContainer active={active}>
        <SearchIcon link data-testid="search-icon" onClick={bubbleSubmit} name={icon} />
        <InputStyled
          type="search"
          data-testid={testId}
          aria-label="Search"
          value={searchValue}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onChange={handleSearchChange}
          onKeyPress={handleSearchEnterKey}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {searchValue && (
          <StyledIconButton data-testid="clear-search" aria-label="Clear Search" onClick={handleClearInputClick}>
            <IconClose />
          </StyledIconButton>
        )}
      </InputContainer>
    </FormStyled>
  );
};

const FormStyled = styled(Form)`
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    font-size: ${props => props.theme.fontSizes.m} !important;
    font-weight: ${props => props.theme.fontWeights.light};
  }
`;

const InputContainer: React.SFC<ContainerProps> = styled.div<ContainerProps>`
  border: 1px solid ${props => (props.active ? props.theme.colors.interfaceBlue : props.theme.colors.neutralGrey6)};
  &:hover {
    border-color: ${props => props.theme.colors.interfaceBlue};
  }
  border-radius: 1.3rem;
  padding: 0.6rem 2.6rem 0.5rem 2.6rem;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0.6rem 2rem 0.5rem 1rem;
    border: 0;
  }
  width: 100%;
`;

const InputStyled = styled.input`
  &&&& {
    width: 100%;
    padding: 0 0 0 ${props => props.theme.pxToRem(3)};
    border: 0;
    font-family: ${props => props.theme.fontFamily};
    border-radius: 22px;
    color: ${props => props.theme.colors.neutralGrey8};
    &::-ms-clear {
      display: none;
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      top: 1.2rem;
      background-color: inherit;
    }
    &::placeholder {
      color: ${props => props.theme.colors.neutralGrey7};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0.75rem;
  height: 1.25rem;
  width: 1.25rem;
  top: 0.68rem;
  background-color: ${props => props.theme.colors.neutralGrey3};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  > svg {
    fill: ${props => props.theme.colors.neutralWhite};
    width: ${props => props.theme.pxToRem(20)};
    height: ${props => props.theme.pxToRem(20)};
    flex-shrink: 0;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    top: 1.2rem;
    background-color: inherit;
    width: ${props => props.theme.pxToRem(32)};
    height: ${props => props.theme.pxToRem(32)};

    > svg {
      fill: ${props => props.theme.colors.primaryPurple};
      width: ${props => props.theme.pxToRem(32)};
      height: ${props => props.theme.pxToRem(32)};
    }
  }
`;

const SearchIcon = styled(Icon)`
  &&& {
    color: ${props => props.theme.colors.primaryDarkPurple};
    position: absolute;
    left: 0.75rem;
    height: 1.25rem;
    width: 1.25rem;
    top: 0.68rem;
    margin-right: 0;
    ${props => props.theme.mediaQueries.mobileOnly} {
      display: none;
    }
  }
`;
