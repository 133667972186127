import React from 'react';
import styled from 'styled-components';
import { IC_SCHOOL } from 'resources/images';

interface CPECreditsLabelProps {
  credits: string | null;
}

// using 255, as it is used for "Unlimited" textual value. Not expecting Products with values higher than 70 CPE Credits
// Check lambdas/services/Contentful.helpers.ts:204 for more description
export const CPECreditsLabel: React.FC<CPECreditsLabelProps> = ({ credits }) => (
  <CardLink data-testid={`cpe-credits`}>
    <StyledIcon src={IC_SCHOOL} alt="credits" />
    CPE Credits: {credits === '255' ? 'Unlimited' : credits}
  </CardLink>
);

const CardLink = styled.div`
  display: flex;
  color: ${props => props.theme.colors.neutralGrey7};
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.5;
`;

const StyledIcon = styled.img`
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
  margin-right: ${props => props.theme.pxToRem(4)};
`;
