import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Salesforce, User } from 'mxp-schemas';
import { push } from 'connected-react-router';
import { useLocation, generatePath, matchPath } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { User as UserUtils } from 'mxp-utils';
import {
  getFirmNaming,
  getNavKeys,
  getNavList,
  areAllTruthy,
  hasTruthyValue,
  getFirmHeaderTitle,
  getFirmHeaderButtonUrl,
} from 'utils';
import {
  cimaFeaturesSelector,
  orgDetailsSelector,
  quotesFLPStatusSelector,
  quotesStatusSelector,
  useFLPFeaturesSelector,
} from 'modules/firmAdmin/selectors';
import { Container, Grid } from 'semantic-ui-react';
import { FirmInformationNavigationList, Routes } from 'constants/index';
import { Heading, ButtonLink, ButtonEnums, Link, Menu, OnlyDesktopCSS, Button } from 'components/atoms';
import { compareTabWithPath, getPath } from 'utils/routes';
import { ReactComponent as BackArrowIcon } from 'resources/images/ic-arrow-back.svg';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
import moment from 'moment-timezone';
import { initFirmRosterDynamoTable, setFirmAdminEvent, setIsRefreshFirmData } from 'modules/firmAdmin';

interface Props {
  id: string;
  selectedOrganization: Salesforce.Organization | null;
  getOrganizations: () => void;
}

export const FirmHeader: React.FC<Props> = ({ id, selectedOrganization, getOrganizations }) => {
  const useCimaFeatures = useSelector(cimaFeaturesSelector);
  const useFLPFeatures = useSelector(useFLPFeaturesSelector);
  const orgDetails = useSelector(orgDetailsSelector);
  const quotesStatus = useSelector(quotesStatusSelector);
  const quotesFLPStatus = useSelector(quotesFLPStatusSelector);

  React.useEffect(() => {
    if (!selectedOrganization) getOrganizations();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();
  const location = useLocation();
  const path: string = location.pathname;

  const isFirmRootPage = Boolean(matchPath(path, { path: getPath(Routes.FIRM_ROOT), exact: true }) && !id);
  const isFirmNavigationPage = Boolean(matchPath(path, { path: getPath(Routes.FIRM_ROOT), exact: true }) && id);
  const isFirmInfoPages = Boolean(matchPath(path, { path: getPath(Routes.FIRM_INFO), exact: false }));
  const isFirmBillingPages = Boolean(matchPath(path, { path: getPath(Routes.FIRM_BILLING), exact: false }));
  const isFirmRosterPages = Boolean(matchPath(path, { path: getPath(Routes.FIRM_ROSTER), exact: false }));

  const title = getFirmHeaderTitle(
    selectedOrganization,
    isFirmNavigationPage,
    isFirmInfoPages,
    isFirmBillingPages,
    isFirmRosterPages,
    useCimaFeatures
  );

  const subTitle: string = UserUtils.conditionalFunction(
    areAllTruthy(
      selectedOrganization,
      hasTruthyValue(isFirmInfoPages, isFirmNavigationPage, isFirmBillingPages, isFirmRosterPages)
    ),
    `${selectedOrganization?.name} ${selectedOrganization?.billingAddress.state}`,
    ''
  );
  const isFirmPages = hasTruthyValue(isFirmInfoPages, isFirmBillingPages, isFirmRosterPages);

  const linkTitle: string = UserUtils.conditionalFunction(
    isFirmPages,
    `Back to ${subTitle} Profile`,
    `Back to Firm Selection`
  );

  const linkUrl: string = UserUtils.conditionalFunction(
    isFirmPages,
    generatePath(getPath(Routes.FIRM_ROOT), { orgId: id || '/' }),
    generatePath(getPath(Routes.FIRM_ROOT))
  );

  const handleCompareTabWithPath = React.useCallback(
    (comparison: string) => compareTabWithPath(path, comparison),
    [path]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const firmInfoList = React.useMemo(
    () => getNavKeys(id, FirmInformationNavigationList),
    [id] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const { rosterNavList: firmRosterList, billingNavList: firmBillingList } = React.useMemo(
    () => getNavList(id, useFLPFeatures, useCimaFeatures),
    [id, useCimaFeatures, useFLPFeatures] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const buttonTitle = isFirmBillingPages
    ? `Go to ${getFirmNaming(useCimaFeatures)} Roster`
    : isFirmRosterPages
    ? `Go to ${getFirmNaming(useCimaFeatures)} Billing`
    : 'Switch to my user profile';

  const buttonUrl = getFirmHeaderButtonUrl(isFirmBillingPages, isFirmRosterPages, firmRosterList, firmBillingList);

  const handleClickAnalyticsEvent = (event?: any, a?: any) => {
    const { pageName, siteSection } = getPageNameAndSiteSection();
    const url = a?.name || (window as any)?.location?.pathname;
    const buttonText = event?.target?.innerText || event?.target?.textContent || '';
    handleEvent({ clickValue: `button:link:int::${buttonText}:${url}`, pageName, siteSection }, NAV_CLICK);
  };

  const handleClick = React.useCallback(
    (e: string, a: any) => {
      handleClickAnalyticsEvent(e, a);
      dispatch(push(a.name, { orgId: id }));
    },
    [dispatch, id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [isDisabledRefreshButton, setIsDisabledRefreshButton] = useState(orgDetails?.isRefreshRequested || false);

  useEffect(() => {
    const quoteOrInvoiceInProgressStatus = [
      User.FirmBillingQuoteStatus.INVOICE_IN_PROGRESS,
      User.FirmBillingQuoteStatus.QUOTE_IN_PROGRESS,
    ].includes(quotesStatus);

    const flpQuoteOrInvoiceInProgressStatus = [
      User.FirmBillingQuoteStatus.INVOICE_IN_PROGRESS,
      User.FirmBillingQuoteStatus.QUOTE_IN_PROGRESS,
    ].includes(quotesFLPStatus);

    if (orgDetails.organizationId) {
      setIsDisabledRefreshButton(
        orgDetails.isRefreshRequested || quoteOrInvoiceInProgressStatus || flpQuoteOrInvoiceInProgressStatus || false
      );
    } else {
      setIsDisabledRefreshButton(Boolean(orgDetails?.isFetched && orgDetails?.organizationId));
    }
  }, [orgDetails, quotesFLPStatus, quotesStatus]);

  const onClickRefresh = useCallback(() => {
    setIsDisabledRefreshButton(true);
    dispatch(initFirmRosterDynamoTable(id, true));
    dispatch(setIsRefreshFirmData(true));
    dispatch(setFirmAdminEvent('isRefreshFirmButtonClicked', true));
  }, [dispatch, id]);

  return (
    <StyledFullWidthContainer data-testid="firm-header" data-addbottompadding={isFirmRootPage || isFirmNavigationPage}>
      <Container>
        <StyledGridContainer columns={16} container stackable>
          <Grid.Column computer={16} mobile={16}>
            <StyledFlexContainer>
              <StyledFlexLeftSection>
                {!isFirmRootPage && (
                  <StyledLink testId="test" to={linkUrl} buttonText={linkTitle} isExternal={false}>
                    <StyledBackArrowIcon /> {linkTitle}
                  </StyledLink>
                )}
                <StyledHeading as="h1">{title}</StyledHeading>
                {!isFirmRootPage && <StyledSubheading>{subTitle}</StyledSubheading>}
              </StyledFlexLeftSection>

              <StyledFlexRightSection>
                <OnlyDesktopCSS>
                  {!isFirmRootPage && (
                    <StyledButton
                      testId={`user-profile-button`}
                      variant={ButtonEnums.variants.primary}
                      size={ButtonEnums.sizes.medium}
                      onClick={onClickRefresh}
                      disabled={isDisabledRefreshButton}
                    >
                      Refresh Firm Data
                    </StyledButton>
                  )}
                  <StyledButtonLink
                    testId={`user-profile-button`}
                    variant={ButtonEnums.variants.primary}
                    size={ButtonEnums.sizes.medium}
                    to={buttonUrl}
                  >
                    {buttonTitle}
                  </StyledButtonLink>
                  <StyledDiv>
                    {!isFirmRootPage && (
                      <StyledSubheading>
                        Last Refresh: {moment(orgDetails?.lastRefreshDate).format('YYYY-MM-DD HH:mm')}
                      </StyledSubheading>
                    )}
                  </StyledDiv>
                </OnlyDesktopCSS>
              </StyledFlexRightSection>
            </StyledFlexContainer>

            {areAllTruthy(id, isFirmPages) && (
              <StyledMenu data-testid="tabs-menu" pointing secondary>
                {(isFirmInfoPages ? firmInfoList : isFirmBillingPages ? firmBillingList : firmRosterList)?.map(item => (
                  <Menu.Item
                    data-testid={item.key}
                    key={item.key}
                    name={item.key}
                    content={item.label}
                    active={handleCompareTabWithPath(item.key)}
                    onClick={handleClick}
                  />
                ))}
              </StyledMenu>
            )}
          </Grid.Column>
        </StyledGridContainer>
      </Container>
    </StyledFullWidthContainer>
  );
};

const StyledFullWidthContainer = styled(Container)<{
  'data-addbottompadding': boolean;
}>`
  &&& {
    width: 100vw;
    min-height: ${props => props.theme.pxToRem(217)};
    padding: ${props => props.theme.pxToRem(28)} 0
      ${props => props.theme.pxToRem(props['data-addbottompadding'] ? 28 : 0)} 0;
    box-shadow: inset 0 0 10px 0 ${props => props.theme.colors.neutralGrey3};
    background-color: ${props => props.theme.colors.neutralGrey1};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

const StyledGridContainer = styled(Grid)`
  padding-top: ${props => props.theme.pxToRem(44)};
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  min-height: ${props => props.theme.pxToRem(143)};
`;

const StyledFlexLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledFlexRightSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledHeading = styled(Heading)`
  margin-top: ${props => props.theme.pxToRem(22)};
  margin-bottom: 0;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  min-height: auto; // fix for Safari
`;

const StyledSubheading = styled.div`
  margin-top: ${props => props.theme.pxToRem(8)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  min-height: auto; // fix for Safari
`;

const StyledMenu = styled(Menu)`
  &&&&&&& {
    margin-top: ${props => props.theme.pxToRem(20)};
    margin-bottom: 0;

    & > .active.item {
      background: inherit;
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    width: auto;
    height: ${props => props.theme.pxToRem(40)};
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(24)};
    margin-top: ${props => props.theme.pxToRem(62)};
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    margin-right: ${props => props.theme.pxToRem(10)};
    width: auto;
    height: ${props => props.theme.pxToRem(40)};
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(24)};
    margin-top: ${props => props.theme.pxToRem(62)};
  }
`;

const StyledDiv = styled.div`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(22)};
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.xxl};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: 1.38;
    min-height: auto; // fix for Safari
    position: absolute;
    top: ${props => props.theme.pxToRem(90)};
  }
`;

const StyledLink = styled(Link)`
  &&& {
    color: ${props => props.theme.colors.neutralBlack};
    line-height: 1.75;
    font-weight: ${props => props.theme.fontWeights.medium};
    font-size: ${props => props.theme.fontSizes.xs};
    text-decoration: none;
  }
`;

const StyledBackArrowIcon = styled(BackArrowIcon)`
  fill: ${props => props.theme.colors.primaryPurple};
  width: ${props => props.theme.pxToRem(18)};
  margin-right: ${props => props.theme.pxToRem(4)};
  vertical-align: middle;
  & > path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;
