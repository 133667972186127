import React from 'react';
import styled from 'styled-components';
import { ReactComponent as IconArrowBack } from 'resources/images/ic-arrow-back.svg';
import { Link, Divider } from 'components/atoms';

interface Props {
  title: string;
  buttonText: string;
  path: string;
  onClick?: () => void;
}

export const HeaderBackButton: React.FC<Props> = ({ title, buttonText, path, ...props }) => {
  const handleBackButtonClick = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div>
      <StyledLink to={path} onClick={handleBackButtonClick}>
        <StyledSpan>
          <StyledIconArrow />
          {buttonText}
        </StyledSpan>
      </StyledLink>
      <StyledTitle>{title}</StyledTitle>
      <StyledDivider />
    </div>
  );
};

const StyledTitle = styled.span`
  margin: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(188)} ${props => props.theme.pxToRem(20)} 0;
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(40)};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  width: 100%;
  color: var(--neutral-grey-8);
  display: block;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(24)} 0 0;
  }
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    margin-bottom: ${props => props.theme.pxToRem(60)};
    border-top-color: transparent;
    border-bottom-color: ${props => props.theme.colors.neutralGrey3};
    color: ${props => props.theme.colors.neutralGrey3};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(25)};
    }
  }
`;

const StyledLink = styled(Link)`
  &&&&&& {
    text-decoration: none;
  }
`;

const StyledSpan = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const StyledIconArrow = styled(IconArrowBack)`
  color: ${props => props.theme.colors.primaryPurple};
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  bottom: ${props => props.theme.pxToRem(-3)};
  position: relative;
  margin-right: ${props => props.theme.pxToRem(8)};
`;
