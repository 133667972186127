import React from 'react';
import styled from 'styled-components';

export const OrganizationFirmInformationSimpleTable: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <SimpleTable>{children}</SimpleTable>
);

const SimpleTable = styled.table``;

export const SimpleTableRow = styled.tr``;

export const SimpleTableCell = styled.td`
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 2em;
  &:first-child {
    padding-right: ${props => props.theme.pxToRem(24)};
    font-weight: 700;
  }
`;
