import { connect } from 'react-redux';
import { isOnboardingFinishedModalOpenSelector } from 'modules/layouts/selectors';
import { toggleModalOnboardingFinishedOpen } from 'modules/layouts';
import { ModalOnboardingFinish } from 'components/organisms/ModalOnboardingFinishProcessing/ModalOnboardingFinish';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

const mapActionCreators = (dispatch: Dispatch) => ({
  closeModal(): void {
    dispatch(toggleModalOnboardingFinishedOpen());
  },

  goToFeed(): void {
    dispatch(toggleModalOnboardingFinishedOpen());
    dispatch(push(getPath(Routes.FEED)));
  },
});
const mapStateToProps = (state: State.Root) => ({
  isOnboardingFinishedModalOpen: isOnboardingFinishedModalOpenSelector(state),
});

export const ModalOnboardingFinishContainer = connect(mapStateToProps, mapActionCreators)(ModalOnboardingFinish);
