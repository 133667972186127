import React from 'react';
import styled from 'styled-components';
import { Product, User } from 'mxp-schemas';
import { ButtonEnums, Button, ButtonLink } from 'components/atoms';
import { AccessOPLProduct } from 'components/molecules/AccessOPLProduct/AccessOPLProduct';
import { CART_CTA_TEXT, PRODUCT_CART_STATUS } from 'constants/index';
interface Props {
  handleButtonClick: () => void;
  maxProductFreeTrialDays: number;
  contentRef?: () => void;
  isExistingCartItem?: boolean;
  productItem: Product.ProductItem;
  isPremiumContent?: boolean;
  premiumContentTo: string;
  isOPL?: boolean;
  userEmail?: string;
  fvsMembership?: User.MembershipIdsEnum[] | [];
  oktaId?: string;
  oplRedirectUrl?: string;
  oplHidUrl?: string;
  isUserFromRestrictedCountry?: boolean;
}

export const ProductFreeTrialBlock: React.FC<Props> = React.memo(
  ({
    handleButtonClick,
    maxProductFreeTrialDays,
    contentRef,
    isExistingCartItem,
    productItem,
    isPremiumContent,
    premiumContentTo,
    isOPL,
    userEmail,
    fvsMembership,
    oktaId,
    oplRedirectUrl,
    oplHidUrl,
    isUserFromRestrictedCountry,
  }: Props) => {
    const { isSubscribed, previousSubscriptionType } = productItem;

    const freeTrialIsAlreadyUsed =
      previousSubscriptionType === Product.PreviousSubscriptionType.PURCHASED_FREE_TRIAL_IN_LAST_12MONTHS;
    return (
      <StyledDiv id="free-trial-block" ref={contentRef}>
        <StyledTitle>Try this product for {maxProductFreeTrialDays} days &mdash; 100% free*</StyledTitle>
        <StyledText>
          You're invited to experience all the benefits and see to if this learning product is right for you. When the
          trial ends, full paid access and auto-renewal will begin for your convenience. Opt out at any time before the
          free trial ends.
        </StyledText>
        {freeTrialIsAlreadyUsed ? (
          <StyledRedText>You have used your annual free trial allowance for this product</StyledRedText>
        ) : (
          <>
            {isPremiumContent && isSubscribed ? (
              <StyledButtonLink
                fluid
                external
                testId="select-free-trial-to-cart"
                variant={ButtonEnums.variants.primary}
                to={premiumContentTo}
              >
                {CART_CTA_TEXT[PRODUCT_CART_STATUS.ACCESS_NOW]}
              </StyledButtonLink>
            ) : isOPL && isSubscribed ? (
              <AccessOPLProduct
                testId="select-opl-free-trial-to-cart"
                userEmail={userEmail}
                oktaId={oktaId}
                fvsMembership={fvsMembership}
                oplRedirectUrl={oplRedirectUrl}
                oplHidUrl={oplHidUrl}
                isFreeTrial
              />
            ) : (
              <StyledButton
                testId="select-free-trial-to-cart"
                variant={ButtonEnums.variants.primary}
                iconPosition={ButtonEnums.iconPosition.left}
                bordered
                fluid
                onClick={handleButtonClick}
                disabled={isUserFromRestrictedCountry}
              >
                {isSubscribed
                  ? CART_CTA_TEXT[PRODUCT_CART_STATUS.ACCESS_NOW]
                  : isExistingCartItem
                  ? CART_CTA_TEXT[PRODUCT_CART_STATUS.IN_CART]
                  : 'Start free trial'}
              </StyledButton>
            )}
          </>
        )}
      </StyledDiv>
    );
  }
);

const StyledDiv = styled.div`
  margin: ${props => props.theme.pxToRem(24)} 0;
  padding: ${props => props.theme.pxToRem(20)};
  text-align: center;
  font-size: ${props => props.theme.pxToRem(16)};
  border: solid 1px ${props => props.theme.colors.neutralGrey2};
  background-color: ${props => props.theme.colors.neutralGrey1};
`;

const StyledTitle = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${props => props.theme.pxToRem(24)};
`;

const StyledText = styled.p`
  font-size: ${props => props.theme.pxToRem(16)};
  margin: 0 0 ${props => props.theme.pxToRem(20)};
`;

const StyledRedText = styled.p`
  font-size: ${props => props.theme.pxToRem(16)};
  margin: 0 0 ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.interfaceRed};
`;

const StyledButton = styled(Button)`
  &&&& {
    width: auto;
    border-radius: 4px;
    font-size: ${props => props.theme.pxToRem(16)};
    padding: 0 ${props => props.theme.pxToRem(16)};

    svg {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: ${props => props.theme.pxToRem(12)};
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  max-width: ${props => props.theme.pxToRem(225)};
`;
