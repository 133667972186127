import React from 'react';
import { Plus, Minus } from 'components/atoms/svg';
import styled from 'styled-components';

interface ToggleButtonProps {
  children: string;
  isOpen: boolean;
  onClick: () => void;
  testId: string;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ children, onClick, isOpen, testId, ...props }) => (
  <StyledButtonWrapper>
    <StyledToggleButton onClick={onClick} data-testid={testId} {...props}>
      {isOpen ? <Minus width={18} height={18} /> : <Plus width={18} height={18} />}
      <StyledToggleButtonText>{children}</StyledToggleButtonText>
    </StyledToggleButton>
    <StyledFillingLine />
  </StyledButtonWrapper>
);

export const ToggleButtonNoIcon: React.FC<ToggleButtonProps> = ({ children, onClick, testId, ...props }) => (
  <StyledButtonWrapper>
    <StyledToggleButton onClick={onClick} data-testid={testId} {...props}>
      <StyledToggleButtonText>{children}</StyledToggleButtonText>
    </StyledToggleButton>
  </StyledButtonWrapper>
); // moved the filling line before the toggle button to align right.

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFillingLine = styled.span`
  flex: 1;
  height: 1px;
  background: ${props => props.theme.colors.neutralGrey3};
`;

const StyledToggleButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin-right: 0.3rem;
`;

const StyledToggleButtonText = styled.span`
  padding-left: 0.2rem;
  padding-right: 0.2rem;
`;
