import React, { useCallback, RefObject } from 'react';
import { ButtonEnums, Button } from 'components/atoms';
import { ClosedCaption } from 'components/atoms/svg';
import styled from 'styled-components';
import { Product } from 'mxp-schemas';
import { Scroller, arrayIncludes, availableFormatToLabel } from 'utils';
import { Utils } from 'mxp-utils';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_INSTRUCTOR_SPEAKER_FIELDS } from 'modules/featureToggle/constants';
import { useSelector } from 'react-redux';

interface Props {
  productItem: Product.ProductItem;
  isExam: boolean;
  isCourse: boolean;
  isMultiDayWebcast?: boolean;
  isProductWithMultipleOptions: boolean;
  isWebcastSeries?: boolean;
  isPhSubscriptionMagazine: boolean;
  productFormatLabel: string;
  examFormatLabel: string | null;
  dateRange?: any;
  location?: string;
  nasbaFieldOfStudy?: Product.KeyLabel[];
  programLevel: Product.KeyLabel | undefined;
  credits: string | undefined;
  authors: Product.Author[] | undefined;
  publisher: Product.Publisher[] | undefined;
  sessions: number | null | undefined;
  calculatedAccessDuration: string;
  showCC?: boolean | undefined | null;
  sku: string | null;
  creditInfoScrollTarget: RefObject<HTMLDivElement>;
  productChildrenInfoScrollTarget: RefObject<HTMLDivElement>;
  accessRowScrollTarget: RefObject<HTMLDivElement>;
  productType: string | null;
  productSku: string | undefined;
  isPublication: boolean;
  publicationYear: string | undefined;
  hasOnlineConference: boolean;
  instructors: any[];
  speakers: any[];
}

export const ProductHeroDetailsTable: React.FC<Props> = React.memo(
  ({
    productItem,
    isExam,
    isCourse,
    isMultiDayWebcast,
    isProductWithMultipleOptions,
    isWebcastSeries,
    isPhSubscriptionMagazine,
    productFormatLabel,
    examFormatLabel,
    dateRange,
    location,
    nasbaFieldOfStudy,
    programLevel,
    credits,
    authors,
    publisher,
    sessions,
    calculatedAccessDuration,
    showCC,
    sku,
    creditInfoScrollTarget,
    productChildrenInfoScrollTarget,
    accessRowScrollTarget,
    productType,
    productSku,
    isPublication,
    publicationYear,
    hasOnlineConference,
    instructors,
    speakers,
  }) => {
    const scrollToCreditInfoHandler = () => {
      Scroller.scrollToTarget(creditInfoScrollTarget, { useWindow: true });
    };

    const scrollToProductChildrenInfoHandler = useCallback(() => {
      Scroller.scrollToTarget(productChildrenInfoScrollTarget, { useWindow: true });
    }, [productChildrenInfoScrollTarget]);

    const scrollToAccessRowHandler = () => {
      Scroller.scrollToTarget(accessRowScrollTarget, { useWindow: true });
    };

    const bundleItemCount = productItem?.bundleProducts?.length;
    const isConference = arrayIncludes([Product.ProductType.CONFERENCE], productType);
    const isHardCover = arrayIncludes([Product.AvailableFormat.HARDCOVER], productFormatLabel.toLowerCase());
    const isPaperBack = arrayIncludes([Product.AvailableFormat.PAPERBACK], productFormatLabel.toLowerCase());
    const isTextFormat = arrayIncludes([Product.AvailableFormat.TEXT], productFormatLabel.toLowerCase());
    const isEbook = arrayIncludes([Product.AvailableFormat.EBOOK], productFormatLabel.toLowerCase());
    const nasbaFieldOfStudyList: string[] = Utils.getNasbaFieldOfStudy(nasbaFieldOfStudy || []);
    const isWebcast = arrayIncludes([Product.ProductType.WEBCAST], productType);
    const useInstructorSpeakerFields = useSelector(state =>
      getFeatureToggleByKeySelector(state as State.Root, USE_INSTRUCTOR_SPEAKER_FIELDS)
    );
    // Will add location online live if the conference has online conference variant
    if (hasOnlineConference) {
      // tslint:disable-next-line: no-parameter-reassignment
      location = location?.concat(
        `${location ? ', ' : ''}${availableFormatToLabel(Product.AvailableFormat.ONLINE_CONFERENCE)}`
      );
    }

    const uniqueSpeakers = speakers?.filter(
      (speaker: any) => !instructors.some((instructor: any) => instructor.name === speaker.name)
    );

    // Speaker list
    const uniqueSpeakersList = uniqueSpeakers.map(speaker => speaker?.name).join(', ');

    // condition for rendering speakers
    const shouldRenderSpeakers =
      useInstructorSpeakerFields &&
      uniqueSpeakersList &&
      uniqueSpeakersList?.length &&
      !isConference &&
      !isPhSubscriptionMagazine &&
      isWebcast;

    const renderDetailColumn = (title: string, text: string | null) => (
      <StyledDetailColumn>
        <DetailTitle>{title}</DetailTitle>
        <DetailText>{text}</DetailText>
      </StyledDetailColumn>
    );

    const noExpiryEbook = isPublication && isEbook && productItem?.availability === 9999;

    return (
      <StyledDetailColumns data-testid="hero-details-table">
        {!!bundleItemCount && renderDetailColumn('Products included', String(bundleItemCount))}
        {!isConference &&
          (productFormatLabel || examFormatLabel) &&
          renderDetailColumn('Format', isExam ? examFormatLabel : productFormatLabel)}
        {dateRange && !isExam && !isMultiDayWebcast && !isWebcastSeries && renderDetailColumn('Date', dateRange)}
        {location && !isExam && renderDetailColumn('Location', location)}
        {nasbaFieldOfStudyList && !isPhSubscriptionMagazine && (
          <StyledDetailColumn>
            <DetailTitle>NASBA Field of Study</DetailTitle>
            <DetailText>
              {nasbaFieldOfStudyList && nasbaFieldOfStudyList[0]}
              {nasbaFieldOfStudyList && nasbaFieldOfStudyList.length > 1 && (
                <StyledMoreButton
                  testId="show-more-nasba-study"
                  variant={ButtonEnums.variants.link}
                  size={ButtonEnums.sizes.medium}
                  onClick={scrollToCreditInfoHandler}
                >
                  + {nasbaFieldOfStudyList.length - 1} more
                </StyledMoreButton>
              )}
            </DetailText>
          </StyledDetailColumn>
        )}
        {programLevel &&
          programLevel.label &&
          !isConference &&
          !isPhSubscriptionMagazine &&
          renderDetailColumn('Level', programLevel.label)}
        {credits && !isPhSubscriptionMagazine && renderDetailColumn('CPE Credits', credits)}
        {authors &&
          authors.length > 0 &&
          !isConference &&
          !isPhSubscriptionMagazine &&
          !isWebcast &&
          renderDetailColumn('Author(s)', authors.map(author => author.name).join(', '))}
        {!useInstructorSpeakerFields &&
          authors &&
          authors.length > 0 &&
          !isConference &&
          !isPhSubscriptionMagazine &&
          isWebcast &&
          renderDetailColumn('Instructor', authors.map(author => author.name).join(', '))}
        {useInstructorSpeakerFields &&
          instructors &&
          instructors.length > 0 &&
          !isConference &&
          !isPhSubscriptionMagazine &&
          isWebcast &&
          renderDetailColumn('Instructor(s)', instructors.map(instructor => instructor.name).join(', '))}
        {shouldRenderSpeakers && renderDetailColumn('Speaker(s)', uniqueSpeakersList)}
        {publisher &&
          publisher.length > 0 &&
          renderDetailColumn('Publisher', publisher.map(pub => pub.name).join(', '))}
        {sessions && (
          <StyledDetailColumn>
            <DetailTitle>Sessions</DetailTitle>
            <DetailText>
              {sessions.toString()}
              <StyledMoreButtonSession
                testId="view-schedule-sessions"
                variant={ButtonEnums.variants.link}
                size={ButtonEnums.sizes.medium}
                onClick={scrollToProductChildrenInfoHandler}
              >
                View schedule
              </StyledMoreButtonSession>
            </DetailText>
          </StyledDetailColumn>
        )}
        {calculatedAccessDuration && !isConference && !isHardCover && !isPaperBack && !(isTextFormat && isCourse) && (
          <StyledDetailColumn>
            <DetailTitle>Availability</DetailTitle>
            <DetailText>
              {noExpiryEbook && <>Lifetime</>}
              {(isMultiDayWebcast || !isProductWithMultipleOptions) && !noExpiryEbook
                ? calculatedAccessDuration
                : isProductWithMultipleOptions && (
                    <StyledMoreButton
                      testId="show-more-nasba-study"
                      variant={ButtonEnums.variants.link}
                      size={ButtonEnums.sizes.medium}
                      onClick={scrollToAccessRowHandler}
                    >
                      More details
                    </StyledMoreButton>
                  )}
            </DetailText>
          </StyledDetailColumn>
        )}
        {showCC && !isPhSubscriptionMagazine && (
          <StyledDetailColumn>
            <StyledClosedCaption>
              <ClosedCaption size={24} testId="cc-hero-icon" />
            </StyledClosedCaption>
          </StyledDetailColumn>
        )}
        {productSku && (
          <StyledDetailColumn>
            <DetailTitle>Product Number</DetailTitle>
            <DetailText>{productSku}</DetailText>
          </StyledDetailColumn>
        )}
        {isPublication && publicationYear && (
          <StyledDetailColumn>
            <DetailTitle>Publication Date</DetailTitle>
            <DetailText>{publicationYear}</DetailText>
          </StyledDetailColumn>
        )}
      </StyledDetailColumns>
    );
  }
);

const StyledDetailColumns = styled.div`
  display: flex;
  padding: 0;
  border-top: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  overflow-x: auto;
  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-wrap: wrap;

    &&&& {
      padding-top: ${props => props.theme.pxToRem(16)};
      padding-bottom: 0;
    }
  }
`;

const StyledDetailColumn = styled.div`
  position: relative;
  flex-grow: 1;
  max-width: 100%;
  padding: ${props => props.theme.pxToRem(16)};

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: ${props => props.theme.pxToRem(1)};
    height: 40%;
    border-right: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
    transform: translate(0, -50%);
  }

  :first-child {
    padding-left: ${props => props.theme.pxToRem(24)};
  }

  :last-child {
    padding-right: ${props => props.theme.pxToRem(24)};

    ::after {
      display: none;
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 50%;
    padding: ${props => props.theme.pxToRem(19.2)};
    padding-top: 0;
    padding-bottom: 0;

    :first-child {
      padding-left: ${props => props.theme.pxToRem(19.2)};
    }

    :last-child {
      padding-right: ${props => props.theme.pxToRem(19.2)};

      ::after {
        display: none;
      }
    }

    &::after {
      display: none;
    }
  }
`;

const DetailTitle = styled.p`
  white-space: nowrap;

  ${props => props.theme.mediaQueries.computerMin} {
    white-space: normal;
  }

  margin-bottom: ${props => props.theme.pxToRem(4.8)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const DetailText = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};

  ${props => props.theme.mediaQueries.mobileOnly} {
    && {
      margin-bottom: ${props => props.theme.pxToRem(16)};
    }
  }
`;

const StyledMoreButton = styled(Button)`
  &&&& {
    margin-left: ${props => props.theme.pxToRem(4)};
    color: ${props => props.theme.colors.primaryPurple};
    font-weight: ${props => props.theme.fontWeights.regular};
    text-decoration: underline;
  }
`;

const StyledMoreButtonSession = styled(Button)`
  &&&& {
    margin-left: ${props => props.theme.pxToRem(4)};
    color: ${props => props.theme.colors.primaryPurple};
    font-weight: ${props => props.theme.fontWeights.regular};
    text-decoration: underline;
    width: max-content;
  }
`;

const StyledClosedCaption = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-bottom: ${props => props.theme.pxToRem(9)};
  }
`;
