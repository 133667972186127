import React, { useState } from 'react';
import styled from 'styled-components';
import { TextArea } from 'components/atoms';
import { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';

interface TextAreaWithValidationProps {
  labelName?: string;
  name: string;
  testId: string;
  labelId?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  isCorrect: boolean;
  showEmptyError?: boolean;
  autoFocus?: boolean;
  errorMessage?: string | React.ReactNode;
  infoMessage?: string | React.ReactNode;
  onPaste?: (e: any) => void;
  getIsFocused?: (e: boolean) => void;
  inputRef?: any;
  externalIsValid?: boolean;
  placeholder?: string;
  clear?: () => void;
  className?: string;
  absoluteErrorMessage?: boolean;
  validationMessageDisabled?: boolean;
  disabled?: boolean;
  maxLength?: string;
}

export const TextAreaWithValidation: React.FC<TextAreaWithValidationProps> = ({
  testId,
  labelId,
  labelName,
  name,
  onChange,
  onClick,
  value,
  isCorrect,
  showEmptyError,
  autoFocus,
  errorMessage,
  infoMessage,
  onPaste,
  inputRef,
  externalIsValid = true,
  getIsFocused,
  placeholder,
  clear,
  className,
  absoluteErrorMessage,
  validationMessageDisabled,
  disabled,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false);
  const handleBlur = React.useCallback(() => {
    setFocused(false);
    if (getIsFocused) {
      getIsFocused(false);
    }
  }, [getIsFocused]);
  const handleFocus = React.useCallback(() => {
    setFocused(true);
    if (getIsFocused) {
      getIsFocused(true);
    }
  }, [getIsFocused]);

  const hasValue = Boolean(value?.length);
  const hasError = Boolean(!isCorrect || (!externalIsValid && hasValue));
  const validationMessageValue = isFocused ? infoMessage || '\u00A0' : (hasError && errorMessage) || '\u00A0';
  const colourAsError = hasError && !isFocused;
  return (
    <>
      <StyledTextAreaValidation
        fluid
        labelName={labelName}
        name={name}
        value={value}
        testId={testId}
        labelId={labelId}
        autoComplete="off"
        onChange={onChange}
        onClick={onClick}
        onBlur={handleBlur}
        onFocus={handleFocus}
        autoFocus={autoFocus}
        hasError={colourAsError}
        onPaste={onPaste}
        inputRef={inputRef}
        placeholder={placeholder}
        className={className}
        disabled={disabled}
        {...props}
      />
      {showEmptyError ? (
        <ValidationMessage hasError={true} absoluteErrorMessage={false}>
          Please enter {labelName}
        </ValidationMessage>
      ) : (
        <></>
      )}
      {!validationMessageDisabled && (
        <ValidationMessage hasError={colourAsError} absoluteErrorMessage={absoluteErrorMessage}>
          {validationMessageValue}
        </ValidationMessage>
      )}
    </>
  );
};

interface ValidationMessageProps {
  hasError?: boolean;
  absoluteErrorMessage?: boolean;
}

const ValidationMessage = styled.div<ThemedStyledProps<ValidationMessageProps, DefaultTheme>>`
  margin-bottom: ${props => props.theme.pxToRem(4)};
  color: ${props => props.theme.colors.neutralGrey5};
  font-size: ${props => props.theme.fontSizes.xxs};
  ${({ hasError, theme, absoluteErrorMessage }) =>
    hasError &&
    `
      color: ${theme.colors.interfaceRed};
      position: ${absoluteErrorMessage ? 'absolute' : 'static'}
    `}
`;

const StyledTextAreaValidation = styled(({ hasError, children, ...rest }) => <TextArea {...rest}>{children}</TextArea>)`
  padding-bottom: ${props => props.theme.pxToRem(4)};
  ${({ hasError, theme }) =>
    hasError &&
    `
      &&&& > textarea {
        :focus {
          border: solid ${theme.pxToRem(1)} ${theme.colors.interfaceRed};
        }
         border: solid ${theme.pxToRem(1)} ${theme.colors.interfaceRed};
      }
    `}
`;
