import { createSelector } from 'reselect';
import { selectedCareerStageIdSelector } from '../layouts/selectors';
import { slugIdMapCreator } from './helpers';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.careerStages,
  careerStagesRoot => careerStagesRoot
);

export const careerStagesFetchedSelector = createSelector(
  rootSelector,
  (careerStages: State.CareerStages): boolean => careerStages.careerStagesFetched
);

export const careerStagesHashSelector = createSelector(
  rootSelector,
  (careerStagesRoot: State.CareerStages): State.CareerStagesHash | null => careerStagesRoot.careerStagesHash
);

export const careerStagesSelector = createSelector(
  careerStagesHashSelector,
  (careerStagesHash: State.CareerStagesHash | null): State.CareerStage[] | null =>
    careerStagesHash && Object.values(careerStagesHash)
);

export const careerStagesSlugIdMapSelector = createSelector(careerStagesSelector, slugIdMapCreator);

export const careerStagesSlugsStrSelector = createSelector(
  careerStagesSlugIdMapSelector,
  (careerStagesSlugIdMap: { [key: string]: string }): string =>
    Object.keys(careerStagesSlugIdMap || emptyObject).join('|')
);

export const selectedCareerStageNameSelector = createSelector(
  [careerStagesHashSelector, selectedCareerStageIdSelector],
  (careerStagesHash: State.CareerStagesHash | null, selectedCareerStageId: string | null): string | null => {
    if (!careerStagesHash || !selectedCareerStageId || !careerStagesHash[selectedCareerStageId]) return null;
    return careerStagesHash[selectedCareerStageId].name;
  }
);
