import { User } from 'mxp-schemas';
import { FirmAdminTableType } from 'constants/firm-admin';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_FIRM_ROSTER_CASE_INSENSITIVE_SEARCH } from 'modules/featureToggle/constants';
import { paginationSelectorFactory } from '../selectors';

export const getFirmMembersPayloadBuilder = (
  state: State.Root,
  type: FirmAdmin.FirmAdminTableType,
  rootOrganizationId: string
) => {
  const { limit, offset, query, filters }: FirmAdmin.Pagination = paginationSelectorFactory(type)(state);
  const useFilterRenewalEndDate = false;
  const useFirmRosterCaseInsensitiveSearch = getFeatureToggleByKeySelector(
    state,
    USE_FIRM_ROSTER_CASE_INSENSITIVE_SEARCH
  );

  let updatedFilters = {};

  switch (type) {
    case FirmAdminTableType.UPGRADABLE_MEMBERS:
      updatedFilters = {
        ...filters,
        subscriptionStatus: [
          User.MembershipSubscriptionStatus.RENEWAL,
          User.MembershipSubscriptionStatus.INVOICED_PAID,
        ],
      };
      break;
    case FirmAdminTableType.FLP_ORGANIZATION_ADDONS:
      updatedFilters = { ...filters, isPaidByFirm: false, isPaidByFlp: true, isFLPView: true, isBillingView: true };
      break;
    case FirmAdminTableType.MEMBERSHIPS_AND_ADDONS:
      updatedFilters = { ...filters, isPaidByFirm: true, isPaidByFlp: false, isFLPView: false, isBillingView: true };
      break;
    default:
      updatedFilters = filters;
      break;
  }

  return {
    rootOrganizationId,
    offset,
    limit,
    searchText: query,
    filters: updatedFilters,
    includeAddons:
      type === FirmAdminTableType.MEMBERSHIPS_AND_ADDONS ||
      type === FirmAdminTableType.MANAGE_FIRM_ROSTER ||
      type === FirmAdminTableType.FLP_ORGANIZATION_ADDONS ||
      type === FirmAdminTableType.FLP_UPGRADE_SUBSCRIPTION ||
      type === FirmAdminTableType.CIMA_ORGANIZATION_ROSTER,
    useFilterRenewalEndDate,
    useFirmRosterCaseInsensitiveSearch,
  };
};
