import React from 'react';
import styled, { css } from 'styled-components';
import {
  Button,
  ButtonEnums,
  Checkbox,
  Divider,
  NotificationBannerEnums,
  Table,
  NotificationBanner,
  RadioButton,
  Link,
  TablePlaceholder,
} from 'components/atoms';
import { theme } from 'theme';
import { DownloadArrow, Info } from 'components/atoms/svg';
import { ReactComponent as IconCheckCircle } from 'resources/images/icon-dev-ic-check-circle.svg';
import {
  downloadReport,
  generateBulkRenewalReport,
  processRenewal,
  resetBulkRenewalState,
} from 'modules/admin/actions';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { IconErrorBanner } from 'components/pages/PageOrganizationNew/PageOrganizationNew';
import {
  BulkRenewalMembershipType,
  BulkRenewalCheckBoxType,
  BulkRenewalReportBannerMessage,
  BulkRenewalInstructions,
  BulkRenewalAndReportActions,
  BulkRenewalAndReportStatus,
  BulkRenewalTableStatus,
} from 'constants/index';
import { DatePicker } from 'components/molecules';
import { getAdminFeatureToggleByKeySelector } from 'modules/admin/selectors';
import { USE_BULK_RENEWAL_FILTER } from 'modules/featureToggle/constants';
interface PageAdminBulkRenewalProps {
  getTransactionHistory: () => any;
  getRecentDownloadedReport: () => any;
  getRecentTransaction: () => any;
  recentTransaction: any;
  bulkRenewalHistory: State.BulkRenewalHistoryTableContents[];
  reportHistory: State.BulkRenewalReportHistoryTableContents[];
  preSignedUrl: string | null;
  recentlyGenerated: State.BulkRenewalProcessDownload;
  isTransactionHistoryLoading: boolean;
  isReportHistoryLoading: boolean;
}

export const PageAdminBulkRenewal = ({
  getTransactionHistory,
  getRecentDownloadedReport,
  getRecentTransaction,
  recentTransaction,
  bulkRenewalHistory,
  reportHistory,
  preSignedUrl,
  recentlyGenerated,
  isTransactionHistoryLoading,
  isReportHistoryLoading,
}: PageAdminBulkRenewalProps) => {
  const dispatch = useDispatch();
  const [honoraryChecked, isHonoraryChecked] = React.useState(false);
  const [dateQueryChecked, isDateQueryChecked] = React.useState(false);
  const [studentChecked, isStudenChecked] = React.useState(false);
  const [downloadedChecked, isDownloadedChecked] = React.useState<boolean>(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [generateStarted, setGenerateStarted] = React.useState(false);
  const [bulkRenewStarted, setBulkRenewStarted] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const [shouldCallInterval, setShouldCallInterval] = React.useState(false);
  const [shouldShowSuccess, setShouldShowSuccess] = React.useState(false);
  const [isDownloadButtonClicked, setIsDownloadButtonClicked] = React.useState(false);
  const [isPreSignedUrlEmpty, setPreSignedUrlEmpty] = React.useState(false);
  const [dateQueryFilter, setDateQueryFilter] = React.useState({
    startDate: '',
    endDate: '',
  });

  const useFilterBulkRenewalDateFilter = useSelector(state =>
    getAdminFeatureToggleByKeySelector(state as State.Root, USE_BULK_RENEWAL_FILTER)
  );

  const isDownload = recentTransaction?.action === BulkRenewalAndReportActions.DOWNLOAD;
  const isRenewal = recentTransaction?.action === BulkRenewalAndReportActions.RENEWAL;

  const isGenerationInProgress = isDownload && recentTransaction?.status === BulkRenewalAndReportStatus.IN_PROGRESS;
  const isGenerationCompleted = isDownload && recentTransaction?.status === BulkRenewalAndReportStatus.COMPLETED;

  const isRenewalInProgress =
    (isRenewal && recentTransaction?.status === BulkRenewalAndReportStatus.IN_PROGRESS) ||
    bulkRenewalHistory?.[0]?.status === BulkRenewalAndReportStatus.IN_PROGRESS;

  const isRenewalSuccessful = isRenewal && recentTransaction?.status === BulkRenewalAndReportStatus.COMPLETED;
  const isRenewalError =
    isRenewal &&
    (recentTransaction?.status === BulkRenewalAndReportStatus.RENEWAL_COMPLETED_WITH_ERROR ||
      recentTransaction?.status === BulkRenewalAndReportStatus.RENEWAL_FAILED);

  React.useEffect(() => {
    setSelectedId(recentlyGenerated?.uniqueID ? recentlyGenerated?.uniqueID : '');
  }, [recentlyGenerated]);

  React.useEffect(() => {
    setPreSignedUrlEmpty(false);
    if (isDownloadButtonClicked && preSignedUrl) {
      setPreSignedUrlEmpty(false);
      window.open(preSignedUrl, '_blank');
    }
    if (isDownloadButtonClicked && !preSignedUrl && preSignedUrl !== null) {
      setPreSignedUrlEmpty(true);
    }
  }, [isDownloadButtonClicked, preSignedUrl, setPreSignedUrlEmpty, isPreSignedUrlEmpty]);

  React.useEffect(() => {
    getTransactionHistory();
  }, [getTransactionHistory]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (shouldCallInterval && !isGenerationCompleted) {
        setShouldShowSuccess(true);
        getRecentTransaction();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [shouldCallInterval, isGenerationCompleted, getRecentTransaction]);

  React.useEffect(() => {
    getRecentTransaction();
  }, [getRecentTransaction]);

  React.useEffect(() => {
    getRecentDownloadedReport();
  }, [getRecentDownloadedReport]);

  React.useEffect(() => {
    if (generateStarted) {
      setShowMessage(true);
    }
  }, [generateStarted, isGenerationInProgress, isGenerationCompleted]);

  const handleSelectiveHistory = (reportId: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedId(reportId ? reportId : '');
  };

  const handleHonoraryCheckBox = React.useCallback(
    (e: any) => {
      isHonoraryChecked(e.target.checked);
      setShouldCallInterval(false);
      setGenerateStarted(false);
      setBulkRenewStarted(false);
      isDownloadedChecked(false);
      setShowMessage(false);
      setShouldShowSuccess(false);
      dispatch(resetBulkRenewalState());
      getRecentDownloadedReport();
    },
    [isHonoraryChecked, dispatch, getRecentDownloadedReport]
  );
  const handleDateQuery = React.useCallback(
    (e: any) => {
      isDateQueryChecked(e.target.checked);
    },
    [isDateQueryChecked]
  );
  const handleStudentCheckBox = React.useCallback(
    (e: any) => {
      isStudenChecked(e.target.checked);
      setShouldCallInterval(false);
      setGenerateStarted(false);
      setBulkRenewStarted(false);
      isDownloadedChecked(false);
      setShowMessage(false);
      setShouldShowSuccess(false);
      dispatch(resetBulkRenewalState());
      getRecentDownloadedReport();
    },
    [isStudenChecked, dispatch, getRecentDownloadedReport]
  );

  const handleIsDownloadedCheckBox = React.useCallback(
    (e: any) => {
      isDownloadedChecked(e.target.checked);
      setShowMessage(!e.target.checked);
    },
    [isDownloadedChecked, setShowMessage]
  );

  const handleIsDownloadedForReportTableCheckBox = React.useCallback(
    (e: any) => {
      isDownloadedChecked(e.target.checked);
    },
    [isDownloadedChecked]
  );

  const generateButtonHandler = () => {
    setShowMessage(true);
    setGenerateStarted(true);
    setShouldCallInterval(true);
    if (honoraryChecked && studentChecked) {
      dispatch(
        generateBulkRenewalReport(
          BulkRenewalMembershipType.HONORARY_AND_STUDENT,
          dateQueryChecked,
          dateQueryFilter.startDate,
          dateQueryFilter.endDate
        )
      );
      return;
    }

    dispatch(
      generateBulkRenewalReport(
        honoraryChecked ? BulkRenewalMembershipType.HONORARY : BulkRenewalMembershipType.STUDENT,
        dateQueryChecked,
        dateQueryFilter.startDate,
        dateQueryFilter.endDate
      )
    );
  };

  const downloadReportButtonHandler = (uniqueID: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(downloadReport(uniqueID));
    setIsDownloadButtonClicked(true);
  };

  const bulkRenewButtonHandler = React.useCallback(() => {
    setBulkRenewStarted(true);
    dispatch(processRenewal(selectedId));
    isHonoraryChecked(false);
    isStudenChecked(false);
    isDownloadedChecked(false);
  }, [selectedId, dispatch]);

  const downloadedCheckbox = (): React.ReactNode => {
    return (
      <>
        <StyledConfirmationCheckboxContainer>
          <Checkbox
            width={24}
            height={24}
            id="i-have-downloaded"
            type="checkbox"
            onChange={handleIsDownloadedCheckBox}
            testId={`select-i-have-downloaded`}
            checked={downloadedChecked}
          />
          <StyledLabel htmlFor="i-have-downloaded" checked={downloadedChecked}>
            {BulkRenewalCheckBoxType.I_HAVE_DOWNLOADED}
          </StyledLabel>
        </StyledConfirmationCheckboxContainer>
      </>
    );
  };

  const downloadedCheckboxForReportTable = (): React.ReactNode => {
    return (
      <>
        <StyledConfirmationCheckboxContainer>
          <Checkbox
            width={24}
            height={24}
            id="i-have-downloaded"
            type="checkbox"
            onChange={handleIsDownloadedForReportTableCheckBox}
            testId={`select-i-have-downloaded`}
            checked={downloadedChecked}
            disabled={!selectedId}
          />
          <StyledLabel htmlFor="i-have-downloaded" checked={downloadedChecked}>
            {BulkRenewalCheckBoxType.I_HAVE_DOWNLOADED}
          </StyledLabel>
        </StyledConfirmationCheckboxContainer>
      </>
    );
  };

  const generateMainSection = (): React.ReactNode => {
    return (
      <StyledGeneratedMainSection>
        {honoraryChecked || studentChecked ? renderGenerateReport() : null}

        {isGenerationCompleted && generateStarted ? downloadedCheckbox() : null}
        {downloadedChecked ? renderBulkRenewalOption() : null}
      </StyledGeneratedMainSection>
    );
  };

  const generateMessage = (): React.ReactNode => {
    return (
      <>
        <StyledNotificationBanner
          childrenTestId="message-info-children"
          testId="message-info-in-progress"
          variant={isGenerationCompleted ? NotificationBannerEnums.variant.green : NotificationBannerEnums.variant.blue}
          icon={
            isGenerationCompleted ? (
              <StyledIconSuccess />
            ) : (
              <InfoIcon>
                <Info color={theme.colors.neutralWhite} />
              </InfoIcon>
            )
          }
          isGenerationSuccessful={isGenerationCompleted}
        >
          {isGenerationCompleted
            ? BulkRenewalReportBannerMessage.GENERATION_SUCCESSFUL
            : BulkRenewalReportBannerMessage.GENERATION_IN_PROGRESS}
        </StyledNotificationBanner>
      </>
    );
  };

  const startDateFilterChangeHandler = React.useCallback((date: any) => {
    setDateQueryFilter(state => ({
      ...state,
      startDate: moment(date).format('YYYY-MM-DD'),
    }));
  }, []);

  const endDateFilterChangeHandler = React.useCallback((date: any) => {
    setDateQueryFilter(state => ({
      ...state,
      endDate: moment(date).format('YYYY-MM-DD'),
    }));
  }, []);

  const generateErrorMessage = (): React.ReactNode => {
    return (
      <>
        <StyledErrorBanner
          childrenTestId="message-info-children"
          testId="message-info-in-progress"
          variant={NotificationBannerEnums.variant.red}
          icon={<IconErrorBanner />}
          isPreSignedUrl={isPreSignedUrlEmpty}
        >
          {isPreSignedUrlEmpty
            ? BulkRenewalReportBannerMessage.PRESIGNEDURL_ERROR
            : BulkRenewalReportBannerMessage.BULK_RENEWAL_ERROR}
        </StyledErrorBanner>
      </>
    );
  };

  const renderHistoryReportTable = (): React.ReactNode => {
    return isReportHistoryLoading ? (
      <TablePlaceholder />
    ) : (
      <>
        <StyledTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <StyledReportHistoryTableHeaderCell>
                <StyledTableHeaderSpan>Date</StyledTableHeaderSpan>
              </StyledReportHistoryTableHeaderCell>
              <StyledReportHistoryTableHeaderCell>
                <StyledTableHeaderSpan>Time (EST)</StyledTableHeaderSpan>
              </StyledReportHistoryTableHeaderCell>
              <StyledReportHistoryTableHeaderCell>
                <StyledTableHeaderSpan>Generated by</StyledTableHeaderSpan>
              </StyledReportHistoryTableHeaderCell>
              <StyledReportHistoryTableHeaderCell>
                <StyledTableHeaderSpan>Selections</StyledTableHeaderSpan>
              </StyledReportHistoryTableHeaderCell>
              <StyledReportHistoryTableHeaderCell>
                <StyledTableHeaderSpan>Report link</StyledTableHeaderSpan>
              </StyledReportHistoryTableHeaderCell>
            </Table.Row>
          </Table.Header>
        </StyledTable>
        <StyledReportHistoryTableWrapper>
          <StyledTable>
            <Table.Body>
              {reportHistory.map((row: State.BulkRenewalReportHistoryTableContents) => {
                const selections = row.selections.charAt(0).toUpperCase() + row.selections.slice(1);
                if (row && row.uniqueID) {
                  return (
                    <StyledBodyRow key={row.uniqueID}>
                      <StyledReportHistoryTableCell>
                        <div key={row.uniqueID} onClick={handleSelectiveHistory(row.uniqueID)}>
                          <RadioButton testId="single-multiple-renew" checked={selectedId === row.uniqueID} />
                        </div>
                      </StyledReportHistoryTableCell>
                      <StyledReportHistoryTableCell>
                        <StyledReportHistoryTableCellSpan checked={selectedId === row.uniqueID}>
                          {row.date}
                        </StyledReportHistoryTableCellSpan>
                      </StyledReportHistoryTableCell>
                      <StyledReportHistoryTableCell>
                        <StyledReportHistoryTableCellSpan checked={selectedId === row.uniqueID}>
                          {row.time}
                        </StyledReportHistoryTableCellSpan>
                      </StyledReportHistoryTableCell>
                      <StyledReportHistoryTableCell>
                        <StyledReportHistoryTableCellSpan checked={selectedId === row.uniqueID}>
                          {row.generatedBy}
                        </StyledReportHistoryTableCellSpan>
                      </StyledReportHistoryTableCell>
                      <StyledReportHistoryTableCell>
                        <StyledReportHistoryTableCellSpan checked={selectedId === row.uniqueID}>
                          {selections}
                        </StyledReportHistoryTableCellSpan>
                      </StyledReportHistoryTableCell>
                      <StyledReportHistoryTableCell>
                        <StyledLink to="" onClick={downloadReportButtonHandler(row.uniqueID)}>
                          View
                        </StyledLink>
                      </StyledReportHistoryTableCell>
                    </StyledBodyRow>
                  );
                }

                return null;
              })}
            </Table.Body>
          </StyledTable>
        </StyledReportHistoryTableWrapper>
        {downloadedCheckboxForReportTable()}
      </>
    );
  };

  const renderGenerateReport = (): React.ReactNode => (
    <>
      <Divider />
      <StyledParagraph>{BulkRenewalInstructions.GENERATE_NEW_REPORT}</StyledParagraph>
      <div>
        <StyledReportLabel>
          {isGenerationCompleted && generateStarted && shouldShowSuccess && (
            <StyledLink to="" onClick={downloadReportButtonHandler(recentlyGenerated?.uniqueID)}>
              <DownloadArrow />
            </StyledLink>
          )}
          {isGenerationCompleted && generateStarted && shouldShowSuccess && (
            <>
              <div>
                <StyledLink to="" onClick={downloadReportButtonHandler(recentlyGenerated?.uniqueID)}>
                  <StyledSpan>Download</StyledSpan>
                </StyledLink>
                <StyledGenerateLabel testId="single-renewal-download" variant={ButtonEnums.variants.link}>
                  &nbsp;report
                </StyledGenerateLabel>
              </div>
            </>
          )}
        </StyledReportLabel>
        {useFilterBulkRenewalDateFilter && (
          <StyledCheckboxContainer>
            <Checkbox
              width={24}
              height={24}
              id="use-date-query"
              type="checkbox"
              onChange={handleDateQuery}
              testId={`select-date-query`}
            />
            <StyledHonoraryLabel htmlFor="use-date-query" shouldBeBold={dateQueryChecked}>
              {BulkRenewalCheckBoxType.USE_DATE_QUERY}
            </StyledHonoraryLabel>
          </StyledCheckboxContainer>
        )}

        {dateQueryChecked && useFilterBulkRenewalDateFilter && (
          <StyledDatePickerWrapper>
            <div>
              <StyledDateLabel>
                Start Date <StyledRequiredMark>*</StyledRequiredMark>
              </StyledDateLabel>
              <DatePicker
                id="bulk-renewal-filter-active-start-date"
                startDate={dateQueryFilter?.startDate}
                placeholder="Select a date"
                onDateChangeFunc={startDateFilterChangeHandler}
                displayFormat="YYYY-MM-DD"
              />
            </div>
            <div>
              <StyledDateLabel>
                End Date <StyledRequiredMark>*</StyledRequiredMark>
              </StyledDateLabel>
              <DatePicker
                id="bulk-renewal-filter-active-end-date"
                startDate={dateQueryFilter?.endDate}
                placeholder="Select a date"
                onDateChangeFunc={endDateFilterChangeHandler}
                displayFormat="YYYY-MM-DD"
              />
            </div>
          </StyledDatePickerWrapper>
        )}

        <StyledGenerateButton>
          {(!isGenerationCompleted || !generateStarted || isRenewalSuccessful || isRenewalError) && (
            <>
              <StyledGenerateNewButton
                aria-label={`Generate Report`}
                testId="report-generate-link"
                variant={ButtonEnums.variants.primary}
                onClick={generateButtonHandler}
                disabled={generateStarted}
              >
                Generate new report
              </StyledGenerateNewButton>
            </>
          )}
        </StyledGenerateButton>
      </div>
      {showMessage ? generateMessage() : null}
      {!generateStarted ? (
        <>
          <StyledChoicesLabel>Or</StyledChoicesLabel>
          <StyledChoicesLabel>Resume with previous reports</StyledChoicesLabel>
        </>
      ) : null}
      {!generateStarted ? renderHistoryReportTable() : null}
    </>
  );

  const renderBulkRenewalOption = (): React.ReactNode => (
    <>
      <Divider />
      <StyledParagraph>{BulkRenewalInstructions.CLICK_BULK_RENEW}</StyledParagraph>
      <StyledBulkRenewButton
        aria-label={'Download Report'}
        testId="report-download-link"
        variant={ButtonEnums.variants.primary}
        onClick={bulkRenewButtonHandler}
        disabled={!selectedId}
      >
        Bulk Renewal
      </StyledBulkRenewButton>
    </>
  );

  const renderBulkRenewalInProgress = (): React.ReactNode => (
    <>
      <StyledLargeParagraphDiv>
        <StyledLargeParagraph>{BulkRenewalReportBannerMessage.BULK_RENEWAL_IN_PROGRESS}</StyledLargeParagraph>
      </StyledLargeParagraphDiv>
    </>
  );

  return (
    <>
      {bulkRenewStarted || isRenewalInProgress ? renderBulkRenewalInProgress() : null}
      {isRenewalError ? generateErrorMessage() : null}
      {isPreSignedUrlEmpty ? generateErrorMessage() : null}
      {isTransactionHistoryLoading ? (
        <TablePlaceholder />
      ) : (
        !(bulkRenewStarted || isRenewalInProgress) && (
          <>
            <StyledHeaderText data-testid="invoice-intro">
              Please select all the membership types you wish to bulk renew.
            </StyledHeaderText>
            <StyledMembershipTypeTitle>Membership Type</StyledMembershipTypeTitle>
            <StyledCheckboxContainer>
              <Checkbox
                width={24}
                height={24}
                id="honorary-membership"
                type="checkbox"
                onChange={handleHonoraryCheckBox}
                testId={`select-honorary-membership`}
              />
              <StyledHonoraryLabel htmlFor="honorary-membership" shouldBeBold={honoraryChecked}>
                {BulkRenewalCheckBoxType.HONORARY}
              </StyledHonoraryLabel>
            </StyledCheckboxContainer>
            <StyledCheckboxContainer>
              <Checkbox
                width={24}
                height={24}
                id="student-membership"
                type="checkbox"
                onChange={handleStudentCheckBox}
                testId={`select-honorary-membership`}
              />
              <StyledStudentLabel htmlFor="student-membership" shouldBeBold={studentChecked}>
                {BulkRenewalCheckBoxType.STUDENT}
              </StyledStudentLabel>
            </StyledCheckboxContainer>
          </>
        )
      )}
      {!(bulkRenewStarted || isRenewalInProgress) ? generateMainSection() : null}
      <Divider />
      <StyledBulkRenewalHistoryTableTitle>Bulk renewal history</StyledBulkRenewalHistoryTableTitle>
      {isTransactionHistoryLoading ? (
        <TablePlaceholder />
      ) : (
        <>
          <StyledTable>
            <Table.Header>
              <Table.Row key="bulk-renewal-history-table">
                <StyledBulkRenewalHistoryTableHeaderCell>
                  <StyledTableHeaderSpan>Date Performed</StyledTableHeaderSpan>
                </StyledBulkRenewalHistoryTableHeaderCell>
                <StyledBulkRenewalHistoryTableHeaderCell>
                  <StyledTableHeaderSpan>APPC Admin</StyledTableHeaderSpan>
                </StyledBulkRenewalHistoryTableHeaderCell>
                <StyledBulkRenewalHistoryTableHeaderCell>
                  <StyledTableHeaderSpan>Report link</StyledTableHeaderSpan>
                </StyledBulkRenewalHistoryTableHeaderCell>
                <StyledBulkRenewalHistoryTableHeaderCell>
                  <StyledTableHeaderSpan>Bulk Renew Status</StyledTableHeaderSpan>
                </StyledBulkRenewalHistoryTableHeaderCell>
              </Table.Row>
            </Table.Header>
          </StyledTable>
          <StyledTableWrapper>
            <StyledTable>
              <Table.Body>
                {bulkRenewalHistory.map((row: State.BulkRenewalHistoryTableContents) => {
                  const status =
                    row.status === BulkRenewalAndReportStatus.COMPLETED ||
                    row.status === BulkRenewalAndReportStatus.RENEWAL_COMPLETED
                      ? BulkRenewalTableStatus.COMPLETED
                      : row.status === BulkRenewalAndReportStatus.RENEWAL_COMPLETED_WITH_ERROR ||
                        row.status === BulkRenewalAndReportStatus.COMPLETE_BUT_HAVE_FAILED
                      ? BulkRenewalTableStatus.COMPLETE_WITH_ERRORS
                      : row.status === BulkRenewalAndReportStatus.RENEWAL_FAILED
                      ? BulkRenewalTableStatus.FAILED
                      : row.status === BulkRenewalAndReportStatus.IN_PROGRESS
                      ? BulkRenewalTableStatus.IN_PROGRESS
                      : '';

                  if (row && row.uniqueID) {
                    return (
                      <StyledBodyRow key={row.uniqueID}>
                        <StyledBulkRenewalHistoryTableCell>
                          <StyledBulkRenewalHistoryTableCellSpan>{row.date}</StyledBulkRenewalHistoryTableCellSpan>
                        </StyledBulkRenewalHistoryTableCell>
                        <StyledBulkRenewalHistoryTableCell>
                          <StyledBulkRenewalHistoryTableCellSpan>
                            {row.generatedBy}
                          </StyledBulkRenewalHistoryTableCellSpan>
                        </StyledBulkRenewalHistoryTableCell>
                        <StyledBulkRenewalHistoryTableCell>
                          <StyledLink
                            to=""
                            onClick={downloadReportButtonHandler(row.uniqueID)}
                            disabled={
                              status === BulkRenewalTableStatus.IN_PROGRESS ||
                              status === BulkRenewalTableStatus.FAILED ||
                              status === ''
                                ? true
                                : false
                            }
                          >
                            <DownloadArrow
                              color={
                                status === BulkRenewalTableStatus.IN_PROGRESS ||
                                status === BulkRenewalTableStatus.FAILED ||
                                status === ''
                                  ? theme.colors.neutralGrey6
                                  : theme.colors.primaryPurple
                              }
                            />
                          </StyledLink>
                        </StyledBulkRenewalHistoryTableCell>
                        <StyledBulkRenewalHistoryTableCell>
                          <StyledBulkRenewalHistoryTableCellSpan>{status}</StyledBulkRenewalHistoryTableCellSpan>
                        </StyledBulkRenewalHistoryTableCell>
                      </StyledBodyRow>
                    );
                  }
                  return null;
                })}
              </Table.Body>
            </StyledTable>
          </StyledTableWrapper>
        </>
      )}
    </>
  );
};

const StyledHeaderText = styled.div`
  width: ${props => props.theme.pxToRem(1120)};
  height: ${props => props.theme.pxToRem(22)};
  margin: ${props => props.theme.pxToRem(37)} ${props => props.theme.pxToRem(159)} ${props => props.theme.pxToRem(24)} 0;
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.22;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledMembershipTypeTitle = styled.div`
  width: ${props => props.theme.pxToRem(144)};
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(1135)} ${props => props.theme.pxToRem(24)}
    0;
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledGeneratedMainSection = styled.div`
  min-height: ${props => props.theme.pxToRem(378)};
`;

const StyledBulkRenewalHistoryTableTitle = styled.div`
  width: ${props => props.theme.pxToRem(163)};
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(1135)} ${props => props.theme.pxToRem(24)}
    0;
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledLabel = styled.label<{ checked: boolean }>`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => (props.checked ? props.theme.fontWeights.medium : props.theme.fontWeights.light)};
  padding-left: ${props => props.theme.pxToRem(15)};
  cursor: pointer;
`;

const StyledSelectionLabel = css`
  font-size: ${props => props.theme.fontSizes.m};
  padding-left: ${props => props.theme.pxToRem(15)};
  cursor: pointer;
  user-select: none;
`;

const StyledHonoraryLabel = styled.label<{ shouldBeBold: boolean }>`
  ${StyledSelectionLabel} {
    font-weight: ${props => (props.shouldBeBold ? props.theme.fontWeights.medium : props.theme.fontWeights.light)};
  }
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledStudentLabel = styled.label<{ shouldBeBold: boolean }>`
  ${StyledSelectionLabel} {
    font-weight: ${props => (props.shouldBeBold ? props.theme.fontWeights.medium : props.theme.fontWeights.light)};
  }
  font-size: ${props => props.theme.fontSizes.s};
`;

const StyledConfirmationCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.theme.pxToRem(46)} 0 ${props => props.theme.pxToRem(16)} 0;
`;

const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.theme.pxToRem(16)} 0 ${props => props.theme.pxToRem(16)} 0;
`;

const StyledReportLabel = styled.label`
  display: flex;
  flex-direction: row;
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xs};
`;

const StyledGenerateNewButton = styled(Button)`
  cursor: pointer;
  width: ${props => props.theme.pxToRem(164)};
  height: ${props => props.theme.pxToRem(40)};
  margin: 0 ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(16)} 0 !important;
  object-fit: contain;
`;

const StyledBulkRenewButton = styled(Button)`
  cursor: pointer;
  width: ${props => props.theme.pxToRem(164)};
  height: ${props => props.theme.pxToRem(40)};
  margin: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(1116)} ${props => props.theme.pxToRem(49)}
    0 !important;
  object-fit: contain;
`;

const StyledSpan = styled.span`
  width: ${props => props.theme.pxToRem(72)};
  height: ${props => props.theme.pxToRem(32)};
  margin: 0 ${props => props.theme.pxToRem(2)} 0 ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: normal;
  line-height: 2;
`;

const StyledGenerateLabel = styled(Button)<{ disabled?: boolean }>`
  &&&& {
    cursor: default;
    > span {
      width: ${props => props.theme.pxToRem(43)};
      height: ${props => props.theme.pxToRem(32)};
      margin: 0 0 0 ${props => props.theme.pxToRem(2)};
      font-weight: ${props => props.theme.fontWeights.light};
      font-size: ${props => props.theme.fontSizes.s};
      text-decoration: none;
      color: ${props => (props.disabled ? props.theme.colors.neutralGrey4 : props.theme.colors.neutralGrey8)};
    }
  }
`;

const StyledParagraph = styled.p`
  width: ${props => props.theme.pxToRem(1120)};
  height: ${props => props.theme.pxToRem(22)};
  margin: ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(34)};
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.22;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledLargeParagraph = styled.p`
  width: ${props => props.theme.pxToRem(1121)};
  height: ${props => props.theme.pxToRem(96)};
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xl};
  text-align: center;
`;

const StyledGenerateButton = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
`;

const StyledNotificationBanner = styled(NotificationBanner)<{ isGenerationSuccessful: boolean }>`
  margin-top: ${props => props.theme.pxToRem(16)};
  width: ${props => (props.isGenerationSuccessful ? props.theme.pxToRem(600) : props.theme.pxToRem(850))};
  height: ${props => props.theme.pxToRem(90)};
`;

const StyledErrorBanner = styled(NotificationBanner)<{ isPreSignedUrl: boolean }>`
  margin-top: ${props => props.theme.pxToRem(16)};
  margin-bottom: ${props => props.theme.pxToRem(32)};
  width: ${props => (props.isPreSignedUrl ? props.theme.pxToRem(430) : props.theme.pxToRem(549))};
  height: ${props => props.theme.pxToRem(68)};
`;

const StyledTableWrapper = styled.div`
  height: ${props => props.theme.pxToRem(370)};
  overflow: auto;
`;

const StyledReportHistoryTableWrapper = styled.div`
  min-height: ${props => props.theme.pxToRem(222)};
`;

const StyledTable = styled(Table)`
  width: ${props => props.theme.pxToRem(1120)};
`;

const StyledBodyRow = styled(Table.Row)`
  height: ${props => props.theme.pxToRem(74)};
`;

const StyledDatePickerWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${props => props.theme.pxToRem(20)};
  max-width: ${props => props.theme.pxToRem(600)};
  width: 100%;
`;

const StyledBulkRenewalHistoryTableHeaderCell = styled(Table.HeaderCell)`
  &:nth-child(1) {
    span {
      width: ${props => props.theme.pxToRem(100)};
    }
  }
  &:nth-child(2) {
    span {
      width: ${props => props.theme.pxToRem(81)};
    }
  }
  &:nth-child(3) {
    span {
      width: ${props => props.theme.pxToRem(68)};
    }
  }
  &:nth-child(4) {
    span {
      width: ${props => props.theme.pxToRem(117)};
    }
  }
`;

const StyledBulkRenewalHistoryTableCell = styled(Table.Cell)`
  &:nth-child(1) {
    width: ${props => props.theme.pxToRem(298.1)};
    span {
      width: ${props => props.theme.pxToRem(238)};
    }
  }
  &:nth-child(2) {
    width: ${props => props.theme.pxToRem(257.65)};
    span {
      width: ${props => props.theme.pxToRem(61)};
    }
  }
  &:nth-child(3) {
    width: ${props => props.theme.pxToRem(229.967)};
    span {
      width: ${props => props.theme.pxToRem(14)};
    }
  }
  &:nth-child(4) {
    span {
      width: ${props => props.theme.pxToRem(90)};
    }
  }
`;

const StyledReportHistoryTableHeaderCell = styled(Table.HeaderCell)`
  &:nth-child(2) {
    span {
      width: ${props => props.theme.pxToRem(29)};
    }
  }
  &:nth-child(3) {
    span {
      width: ${props => props.theme.pxToRem(70)};
    }
  }
  &:nth-child(4) {
    span {
      width: ${props => props.theme.pxToRem(83)};
    }
  }
  &:nth-child(5) {
    span {
      width: ${props => props.theme.pxToRem(66)};
    }
  }
  &:nth-child(6) {
    span {
      width: ${props => props.theme.pxToRem(68)};
    }
  }
`;

const StyledReportHistoryTableCell = styled(Table.Cell)`
  &:nth-child(1) {
    width: ${props => props.theme.pxToRem(80.5833)};
    span {
      width: ${props => props.theme.pxToRem(50)};
    }
  }
  &:nth-child(2) {
    width: ${props => props.theme.pxToRem(139)};
    span {
      width: ${props => props.theme.pxToRem(106)};
    }
  }
  &:nth-child(3) {
    width: ${props => props.theme.pxToRem(221.583)};
    span {
      width: ${props => props.theme.pxToRem(45)};
    }
  }
  &:nth-child(4) {
    width: ${props => props.theme.pxToRem(247.767)};
    span {
      width: ${props => props.theme.pxToRem(128)};
    }
  }
  &:nth-child(5) {
    width: ${props => props.theme.pxToRem(213.517)};
    span {
      width: ${props => props.theme.pxToRem(62)};
    }
  }
  &:nth-child(6) {
    width: ${props => props.theme.pxToRem(217.55)};
    span {
      width: ${props => props.theme.pxToRem(39)};
    }
  }
`;

const StyledTableHeaderSpan = styled.span`
  display: block;
  height: ${props => props.theme.pxToRem(22)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledReportHistoryTableCellSpan = styled.span<{ checked: boolean }>`
  display: block;
  min-height: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => (props.checked ? props.theme.fontWeights.medium : props.theme.fontWeights.light)};
`;

const StyledBulkRenewalHistoryTableCellSpan = styled.span`
  display: block;
  min-height: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const InfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.neutralWhite};
  border-radius: 50%;
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  background: ${props => props.theme.colors.interfaceBlue};
`;

const StyledIconSuccess = styled(IconCheckCircle)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;

const StyledChoicesLabel = styled.div`
  width: ${props => props.theme.pxToRem(1120)};
  height: ${props => props.theme.pxToRem(22)};
  margin: ${props => props.theme.pxToRem(29)} ${props => props.theme.pxToRem(159)} ${props => props.theme.pxToRem(31)} 0;
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.22;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledLargeParagraphDiv = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(380)};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledDateLabel = styled.label`
  display: block;
  margin-bottom: ${props => props.theme.pxToRem(5)};
`;

const StyledRequiredMark = styled.span`
  color: red;
`;
