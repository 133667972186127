import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Container, Grid, Divider } from 'components/atoms';
import { adminSearchInvoiceScreens, Routes } from 'constants/index';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { InvoiceSummary } from 'components/molecules/InvoiceSummary/InvoiceSummary';
import { HeaderSteps } from 'components/molecules/HeaderSteps/HeaderSteps';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import { getPath } from 'utils';
import { StepPaymentInvoiceContainer } from 'components/App/lazy-imports';
import {
  isAdminInvoicesPaymentJourneySelector,
  getTotalBalanceSelector,
  selectedInvoicesSelector,
} from 'modules/admin/selectors';
import { useDispatch, useSelector } from 'react-redux';

export const AdminInvoicesPaymentPage: React.FC = () => {
  const dispatch = useDispatch();
  const targetContainer = React.useRef<HTMLDivElement | null>(null);
  const selectedInvoices: State.SearchInvoicesResult[] = useSelector(selectedInvoicesSelector);
  const isAdminInvoicesPayment: boolean = useSelector(isAdminInvoicesPaymentJourneySelector);
  const totalBalance: number = useSelector(getTotalBalanceSelector);

  React.useEffect(() => {
    if (!selectedInvoices?.length) {
      dispatch(push(generatePath(getPath(Routes.ADMIN_INVOICES_SEARCH))));
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <script async src="https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js" />
      </Helmet>
      <Wrapper ref={targetContainer}>
        <HeaderSteps stepIndex={2} labels={adminSearchInvoiceScreens} />
        <Container>
          <PreviousPage backText="Back to search results" />
          <Grid stackable>
            <Grid.Row>
              <StyledColumn computer={10} tablet={16}>
                <StyledPageTitle>Payment</StyledPageTitle>
                <StyledPageSubtitle>Please enter the customer's payment card details</StyledPageSubtitle>
                <StyledDivider />
                <StepPaymentInvoiceContainer isThisAdminInvoicesPayment={isAdminInvoicesPayment} />
              </StyledColumn>
              <StyledColumn computer={1} />
              <Grid.Column computer={5} tablet={16}>
                <InvoiceSummary
                  isAdminInvoicesPayment={isAdminInvoicesPayment}
                  invoices={selectedInvoices}
                  amountToPay={totalBalance}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  overflow-x: hidden;
  height: 100%;
  ${props => props.theme.mediaQueries.desktopOnly} {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow-y: visible;
    overflow: visible;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow: visible;
    height: auto;
    background: transparent;
  }
`;

const StyledColumn = styled(Grid.Column)`
  &&&&&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }
`;

const StyledPageTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes.xl};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.light};
  height: ${props => props.theme.pxToRem(44)};
  &&& {
    margin: ${props => `${props.theme.pxToRem(18)} 0 ${props.theme.pxToRem(8)}`};
  }
`;

const StyledPageSubtitle = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.57;
  font-weight: ${props => props.theme.fontWeights.light};
  height: ${props => props.theme.pxToRem(22)};
  &&& {
    margin: ${props => `${props.theme.pxToRem(8)} 0 ${props.theme.pxToRem(8)}`};
  }
`;

const StyledDivider = styled(Divider)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(23)};
    margin-bottom: ${props => props.theme.pxToRem(26)};
  }
`;
