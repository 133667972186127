import React from 'react';
import styled from 'styled-components';
import { Label } from 'components/atoms/Label/Label';
import { Grid } from 'semantic-ui-react';

interface Props {
  label: string | React.ReactNode;
  data: string | React.ReactNode;
}

export const LabeledData: React.FC<Props> = ({ label, data }) => {
  return (
    <StyledContainer>
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <StyledLabel>{label}</StyledLabel>
          </Grid.Column>
          <Grid.Column width={10}>
            {typeof data === 'string' && <StyledP dangerouslySetInnerHTML={{ __html: data as string }} />}
            {typeof data !== 'string' && <StyledDetails>{data}</StyledDetails>}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledContainer>
  );
};

const StyledP = styled.div`
  height: 100%;
  font-size: ${props => props.theme.fontSizes.s} !important;
  font-weight: ${props => props.theme.fontWeights.light} !important;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledContainer = styled.div`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(20)};
    padding-bottom: 0;
  }
`;

const StyledLabel = styled(Label)`
  height: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  display: inline-block;
  width: 100%;
  margin-bottom: ${props => props.theme.pxToRem(14)};
  ${props => props.theme.mediaQueries.mobileOnly}  {
    display: block;
    width: 100%;
  }
`;

const StyledDetails = styled(Label)`
  height: 100%;
  font-size: ${props => props.theme.fontSizes.s} !important;
  font-weight: ${props => props.theme.fontWeights.light} !important;
  color: ${props => props.theme.colors.neutralGrey8};
`;
