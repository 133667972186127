import React from 'react';
import styled from 'styled-components';
import { Link } from 'components/atoms';

interface Props {
  className?: string;
}

export const NotificationForNonMemberUser: React.FC<Props> = React.memo(({ className = '' }) => {
  return (
    <StyledWrapper className={className}>
      <br />
      Our records indicate that you don't hold AICPA membership.
      <br />
      <br />
      Please call us instead at&nbsp;
      <Link isExternal testId="error-phone" to="tel:888-777-7077">
        888-777-7077
      </Link>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xs};
`;
