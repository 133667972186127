import { createSelector } from 'reselect';
import { emptyObject, emptyArray } from 'utils/emptyItems';

const rootSelector = createSelector(
  (state: State.Root) => state.categories,
  categories => categories
);

export const categoriesHashSelector = createSelector(
  rootSelector,
  (categories: State.Categories): State.CategoriesHash => (categories && categories.categoriesHash) || emptyObject
);

export const categoriesSelector = createSelector(
  categoriesHashSelector,
  (categoriesHash: State.CategoriesHash): State.CategoriesItem[] => {
    if (!categoriesHash) return emptyArray;
    return Object.values(categoriesHash);
  }
);

export const categoryNameSelectorFactory = (slug: string) =>
  createSelector(categoriesHashSelector, (categoriesHash: State.CategoriesHash): string => {
    if (!categoriesHash || !slug) return '';
    return categoriesHash[slug] ? categoriesHash[slug].name : '';
  });

export const categoriesFetchedSelector = createSelector(rootSelector, topics => topics.categoriesFetched);
