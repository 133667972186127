export const formatContentfulString = (description: string) => {
  if (!description || typeof description !== 'string') {
    return description;
  }

  // Convert line breaks
  let htmlText = description.replace(/\n/g, '<br />');

  // Convert bold text (__text__) - taking into account the potential for nesting
  htmlText = htmlText.replace(/__(.*?)__/g, (_, match) => {
    return `<strong>${match.replace(/\*(.*?)\*/g, '<em>$1</em>')}</strong>`;
  });

  // Convert italic text (*text*) - outside of bold
  htmlText = htmlText.replace(/\*(.*?)\*/g, '<em>$1</em>');

  return htmlText;
};
