import React from 'react';
import styled from 'styled-components';

interface MinusIF {
  stroke?: string;
  strokeWidth?: string | number;
  background?: string;
  border?: string;
  borderWidth?: string | number;
  borderRadius?: string | number;
  width?: number;
  height?: number;
  testId?: string;
}

export const Minus: React.FC<MinusIF> = ({ width = 24, height = 24, borderRadius = 7, testId, ...props }) => {
  return (
    <StyledSvg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      data-testid={testId}
      tabIndex={0}
    >
      <circle cx="12" cy="12" r={borderRadius} />
      <path d="M5 12 H19 12" />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg`
  & > path {
    stroke: ${props => props.stroke || props.theme.colors.primaryPurple};
    stroke-width: ${props => props.strokeWidth || 2};
  }
  & > circle {
    stroke: ${(props: MinusIF) => props.border || 'none'};
    stroke-width: ${(props: MinusIF) => props.borderWidth || 2};
    fill: ${(props: MinusIF) => props.background || 'none'};
  }
`;
