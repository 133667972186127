import React from 'react';
import styled from 'styled-components';

import { RadioButton } from 'components/atoms/RadioButton/RadioButton';

interface RadioOptionProps {
  onClick: (value: any) => void;
  label: string;
  checked: boolean;
  value: string;
  helpText?: string;
  disabled?: boolean;
}

export const RadioOption: React.FC<RadioOptionProps> = ({
  onClick,
  label,
  helpText,
  checked,
  value,
  disabled = false,
  ...props
}) => {
  const handleClick = React.useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <StyledRadioButtonWithHelp {...props}>
      <StyledRadioButton testId="" checked={checked} onChange={handleClick} {...{ disabled }} />
      <div>
        <StyledLabel onClick={handleClick} hasHelpText={Boolean(helpText)} checked={checked}>
          {label}
        </StyledLabel>
        <StyledHelpText>{helpText}</StyledHelpText>
      </div>
    </StyledRadioButtonWithHelp>
  );
};

const StyledRadioButtonWithHelp = styled.div`
  display: flex;
`;

const StyledLabel = styled.p<{ hasHelpText: boolean; checked: boolean }>`
  font-weight: ${props => (props.checked ? props.theme.fontWeights.medium : props.theme.fontWeights.light)};
  line-height: 1;
  margin-bottom: ${props => props.theme.pxToRem(props.hasHelpText ? 8 : 16)};
`;

const StyledHelpText = styled.p`
  &&& {
    font-size: ${props => props.theme.fontSizes.xxs};
    font-weight: ${props => props.theme.fontWeights.light};
    margin-bottom: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledRadioButton = styled(RadioButton)`
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  margin-right: ${props => props.theme.pxToRem(16)};
  & > div {
    width: ${props => props.theme.pxToRem(8)};
    height: ${props => props.theme.pxToRem(8)};
  }
`;
