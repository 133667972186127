import { createSelector } from 'reselect';
import { SalesforceResponses } from 'mxp-schemas';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.exemptionWorkExperience,
  (exemptionWorkExperience: State.ExemptionWorkExperience): State.ExemptionWorkExperience => exemptionWorkExperience
);

export const workExperienceSelector = createSelector(
  rootSelector,
  (
    exemptionWorkExperience: State.ExemptionWorkExperience
  ): SalesforceResponses.SalesforceExemptionIdWorkExperience | any => exemptionWorkExperience.exempReference
);

export const workExperienceRequiredSelector = createSelector(
  rootSelector,
  (
    exemptionWorkExperience: State.ExemptionWorkExperience
  ): SalesforceResponses.SalesforceExemptionIdWorkExperience | any =>
    exemptionWorkExperience?.allWorkExperienceReference
);
