import { createSelector } from 'reselect';
import { selectedSkillIdSelector } from '../layouts/selectors';
import { slugIdMapCreator } from './helpers';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.skills,
  skillsRoot => skillsRoot
);

export const skillsFetchedSelector = createSelector(
  rootSelector,
  (skills: State.Skills): boolean => skills.skillsFetched
);

export const skillsHashSelector = createSelector(
  rootSelector,
  (skillsRoot: State.Skills): State.SkillsHash | null => skillsRoot.skillsHash
);

export const skillsSelector = createSelector(
  skillsHashSelector,
  (skillsHash: State.SkillsHash | null): State.Skill[] | null => skillsHash && Object.values(skillsHash)
);

export const skillsSlugIdMapSelector = createSelector(skillsSelector, slugIdMapCreator);

export const skillsSlugsStrSelector = createSelector(
  skillsSlugIdMapSelector,
  (skillsSlugIdMap: { [key: string]: string }): string => Object.keys(skillsSlugIdMap || emptyObject).join('|')
);

export const skillsSortedSelector = createSelector(
  skillsSelector,
  (skills: State.Skill[] | null): State.Skill[] | null =>
    skills && [...skills].sort((a, b) => (a.sort && b.sort && a.sort - b.sort) || -1)
);

export const skillNameBySkillSlugSelectorFactory = (skillSlug: string) =>
  createSelector(
    [skillsHashSelector, skillsSlugIdMapSelector],
    (skillsHash: State.SkillsHash | null, skillsSlugIdMap: { [key: string]: string }): string => {
      if (!skillSlug || !skillsHash || !skillsSlugIdMap) return '';
      const skillId: string = skillsSlugIdMap[skillSlug];
      if (!skillId) return '';
      const skill: State.Skill = skillsHash[skillId];
      return skill ? skill.name : '';
    }
  );

export const selectedSkillNameSelector = createSelector(
  [skillsHashSelector, selectedSkillIdSelector],
  (skillsHash: State.SkillsHash | null, selectedSkillId: string | null): string | null => {
    if (!skillsHash || !selectedSkillId || !skillsHash[selectedSkillId]) return null;
    return skillsHash[selectedSkillId].name;
  }
);

export const subskillsHashSelector = createSelector(
  skillsSelector,
  (skills: State.Skill[] | null): State.SubskillsHash | null => {
    if (!skills) return null;
    return skills.reduce((acc: State.SubskillsHash, skill: State.Skill) => ({ ...acc, ...skill.subskills }), {});
  }
);

export const subskillsSelector = createSelector(subskillsHashSelector, (subskillsHash: State.SubskillsHash | null):
  | State.Subskill[]
  | null => {
  return subskillsHash && Object.values(subskillsHash);
});

export const subskillsSlugIdMapSelector = createSelector(subskillsSelector, slugIdMapCreator);

export const subskillsHashBySkillIdSelectorFactory = (skillId: string | null) =>
  createSelector(skillsHashSelector, (skillsHash: State.SkillsHash | null): State.SubskillsHash | null => {
    if (!skillsHash || !skillId || !skillsHash[skillId]) return null;
    return skillsHash[skillId].subskills;
  });
