import React from 'react';
import styled from 'styled-components';
import { Salesforce } from 'mxp-schemas';
import { TablePlaceholder } from 'components/atoms';
import { CenterAdminSelectionItem } from './CenterAdminSelectionItem';

interface Props {
  isOrganizationsFetched: boolean;
  organizationsList: Salesforce.Organization[];
  centersClientAdmin: string[];
  getOrganizations: () => void;
  selectOrganization: (path: string) => void;
}

export const PageCenterAdminSelection: React.FC<Props> = ({
  isOrganizationsFetched,
  organizationsList,
  getOrganizations,
  selectOrganization,
}) => {
  React.useEffect(getOrganizations, [getOrganizations]);

  const TreeNode = (props: { organizations: Salesforce.Organization[] }) => {
    return (
      <>
        {props.organizations?.map((organization: Salesforce.Organization, index: number) => (
          <div key={organization.id}>
            <CenterAdminSelectionItem
              key={organization.id}
              orgId={organization.id}
              title={`${organization.name} ${organization.billingAddress.state}`}
              selectOrganization={selectOrganization}
              address={organization.billingAddress || organization.shippingAddress}
            />
            {organization?.branches && (
              <div style={{ marginLeft: '30px', marginTop: '20px', marginBottom: '20px' }}>
                <TreeNode organizations={organization?.branches} />
              </div>
            )}
          </div>
        ))}
      </>
    );
  };

  if (!isOrganizationsFetched) return <TablePlaceholder />;
  return (
    <>
      <StyledTitle>Select my firm</StyledTitle>
      <StyledList>
        <TreeNode organizations={organizationsList} />
      </StyledList>
    </>
  );
};

const StyledTitle = styled.h3`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.l};
  margin-bottom: ${props => props.theme.pxToRem(32)};
`;

const StyledList = styled.ul`
  padding: 0;
`;
