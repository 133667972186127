import React from 'react';
import { ShowHideItems } from 'components/molecules/ShowHideItems/ShowHideItems';
import { RecentSearchItem } from 'components/atoms/RecentSearchItem/RecentSearchItem';

interface SearchHistoryProps {
  recentSearches: string[];
  onItemClick: (searchItem: string) => void;
  onItemDeletion: (searchItem: string) => void;
  testId: string;
}

export const SearchHistory: React.SFC<SearchHistoryProps> = ({
  recentSearches,
  onItemClick,
  onItemDeletion,
  testId,
}) => {
  const renderedItem = (item: string, isLast: boolean) => (
    <RecentSearchItem key={item} onClick={onItemClick} onDelete={onItemDeletion} isLast={isLast} testId={testId}>
      {item}
    </RecentSearchItem>
  );

  return (
    <ShowHideItems
      items={recentSearches}
      renderedItem={renderedItem}
      numberOfItemsShownWhenCollapsed={3}
      openText="View all recent searches"
      collapsedText="Show less"
      testId={testId}
    />
  );
};
