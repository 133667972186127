import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Divider, ButtonEnums, Button } from 'components/atoms';
import { Cart } from 'mxp-schemas';
import { Lock } from 'components/atoms/svg';
import { ProceedButtonLink } from './ProceedButtonLink';

interface Props {
  handleToggleSummary: () => void;
  to: string;
  hideProceedButton: boolean;
  totalPriceInfo: Cart.CartTotalPriceInfo;
  isSummaryOpen: boolean;
  useSingleLineLayout: boolean;
  disableCheckoutButton: boolean;
  isModalError?: boolean;
  openErrorModal?: () => void;
  isUserMemberWithSuspendedEthics?: boolean;
}

export const StickyFooter: React.FC<Props> = ({
  totalPriceInfo,
  handleToggleSummary,
  to,
  hideProceedButton,
  isSummaryOpen,
  useSingleLineLayout = false,
  disableCheckoutButton,
  isModalError = false,
  openErrorModal,
  isUserMemberWithSuspendedEthics,
}) => (
  <StickyFooterStyled>
    <Divider0Margin />
    <FlexWrap useSingleLineLayout={useSingleLineLayout}>
      <TriggerWrap
        useSingleLineLayout={useSingleLineLayout}
        onClick={handleToggleSummary}
        data-testid="trigger-collapsible-total"
      >
        <AngleFooterIcon
          useSingleLineLayout={useSingleLineLayout}
          fitted
          name={!isSummaryOpen ? 'angle up' : 'angle down'}
        />
      </TriggerWrap>
      <OrderSummaryWrap useSingleLineLayout={useSingleLineLayout} onClick={handleToggleSummary}>
        <div>Order summary</div>
        <Bold>{totalPriceInfo.formattedTotalPrice}</Bold>
      </OrderSummaryWrap>
      {!hideProceedButton && !isModalError && (
        <StyledProceedButtonLink to={to} label="Checkout" disableCheckoutButton={disableCheckoutButton} />
      )}
      {!hideProceedButton && isModalError && (
        <StyledButton
          onClick={openErrorModal}
          testId="mobile-checkout-button"
          iconPosition={ButtonEnums.iconPosition.left}
          icon={<Lock color="#fff" />}
          variant={ButtonEnums.variants.primary}
        >
          Checkout
        </StyledButton>
      )}
    </FlexWrap>
  </StickyFooterStyled>
);

const FOOTER_HEIGHT = 70;

const StickyFooterStyled = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  background-color: ${props => props.theme.colors.neutralGrey1};
  height: ${props => props.theme.pxToRem(FOOTER_HEIGHT)};
`;

const FlexWrap = styled.div<{ useSingleLineLayout: boolean }>`
  height: 100%;
  display: flex;
  padding-top: ${props => (props.useSingleLineLayout ? props.theme.pxToRem(10) : 0)};
  padding-left: ${props => props.theme.pxToRem(20)};
  padding-right: ${props => props.theme.pxToRem(20)};
  line-height: 1.38;
`;

const TriggerWrap = styled.span<{ useSingleLineLayout: boolean }>`
  ${props =>
    !props.useSingleLineLayout &&
    `
  display: flex;
  align-items: center;
`}
`;

const OrderSummaryWrap = styled.div<{ useSingleLineLayout: boolean }>`
  ${props =>
    !props.useSingleLineLayout &&
    `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  `}
`;

const Divider0Margin = styled(Divider)`
  &&& {
    margin: 0;
  }
`;

const AngleFooterIcon = styled(({ useSingleLineLayout, ...others }) => <Icon {...others} />)`
  color: ${props => props.theme.colors.primaryPurple};
  padding-top: ${props => (props.useSingleLineLayout ? props.theme.pxToRem(14) : 0)};
  padding-right: ${props => props.theme.pxToRem(16)};
`;

const Bold = styled.span`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.m};
  line-height: 1.78;
`;

const StyledButton = styled(Button)`
  &&&&&&& {
    width: ${props => props.theme.pxToRem(148)};
    height: ${props => props.theme.pxToRem(40)};
    margin-left: auto;
    margin-top: ${props => props.theme.pxToRem(4)};
  }
`;

const StyledProceedButtonLink = styled(ProceedButtonLink)`
  &&&&&&& {
    width: ${props => props.theme.pxToRem(148)};
    height: ${props => props.theme.pxToRem(40)};
    margin-left: auto;
    margin-top: ${props => props.theme.pxToRem(4)};
  }
`;
