import React from 'react';
import styled from 'styled-components';
import {
  ButtonLink,
  Heading,
  ButtonVariants,
  Grid,
  ButtonEnums,
  OnlyDesktopCSS,
  OnlyMobileCSS,
} from 'components/atoms';
import { VideoPlayer } from 'components/molecules/VideoPlayer/VideoPlayer';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful } from 'mxp-schemas';

interface Props {
  modularBlock: Contentful.ModularContent.Block;
  headingType: 'h1' | 'h2';
}

export const BlockVideo: React.FC<Props> = ({ headingType, modularBlock }) => {
  const block = modularBlock as Contentful.ModularContent.VideoBlock;
  const hasVideo: boolean = Boolean(block.video);
  const isLeftAligned: boolean = Boolean(block.contentAlignment || block.contentAlignment === null);
  const isBgColorDark: boolean = isColorDark(block.bgColor);

  const renderCtaButton = (): React.ReactNode => {
    return (
      <StyledButtonWrapper>
        {block.ctaUrl && block.ctaText && (
          <StyledButtonLink
            size={ButtonEnums.sizes.medium}
            variant={isBgColorDark ? ButtonVariants.secondaryNegative : ButtonVariants.primary}
            bordered
            to={block.ctaUrl}
            testId="product-promo-block"
          >
            {block.ctaText}
          </StyledButtonLink>
        )}
      </StyledButtonWrapper>
    );
  };

  const renderVideo = (): React.ReactNode => {
    return (
      <StyledVideoGridColumn floated={isLeftAligned ? 'right' : 'left'} textAlign="center">
        {block.video && <VideoPlayer video={block.video} testId="video-player" />}
        <OnlyMobileCSS>{renderCtaButton()}</OnlyMobileCSS>
      </StyledVideoGridColumn>
    );
  };

  const renderBodyDesktop = (): React.ReactNode => {
    return (
      <StyledGridColumn
        width={5}
        textAlign={hasVideo ? 'left' : 'center'}
        floated={!hasVideo ? undefined : isLeftAligned ? 'left' : 'right'}
      >
        <StyledHeaderDescription isBgColorDark={isBgColorDark}>
          {block.header && (
            <StyledHeading
              as={headingType}
              dangerouslySetInnerHTML={{ __html: block.header }}
              data-is-single-column={!hasVideo}
              tabIndex={0}
            />
          )}
          {block.description && (
            <StyledParagraph tabIndex={0} dangerouslySetInnerHTML={{ __html: block.description }} />
          )}
          {renderCtaButton()}
        </StyledHeaderDescription>
      </StyledGridColumn>
    );
  };

  const renderBodyMobile = (): React.ReactNode => {
    return (
      <StyledGridColumn width={11} textAlign="center">
        <StyledHeaderDescription isBgColorDark={isBgColorDark}>
          {block.header && (
            <StyledHeading
              as={headingType}
              dangerouslySetInnerHTML={{ __html: block.header }}
              data-is-single-column={true}
            />
          )}
          {block.description && <StyledParagraph dangerouslySetInnerHTML={{ __html: block.description }} />}
        </StyledHeaderDescription>
      </StyledGridColumn>
    );
  };

  return (
    <>
      <OnlyDesktopCSS>
        <Grid style={{ width: '100%' }}>
          <Grid.Row columns={hasVideo ? 2 : 1} verticalAlign="middle" centered={!hasVideo}>
            {isLeftAligned ? renderBodyDesktop() : renderVideo()}
            {isLeftAligned ? renderVideo() : renderBodyDesktop()}
          </Grid.Row>
        </Grid>
      </OnlyDesktopCSS>
      <OnlyMobileCSS>
        <Grid style={{ width: '100%', padding: '0' }}>
          <Grid.Row columns="1" verticalAlign="middle" centered={true}>
            {renderBodyMobile()}
            {renderVideo()}
          </Grid.Row>
        </Grid>
      </OnlyMobileCSS>
    </>
  );
};

const StyledButtonLink = styled(ButtonLink)`
  &&&&& {
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(20)};
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: center;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`;

const StyledVideoGridColumn = styled(Grid.Column)`
  &&&&& {
    margin-bottom: ${props => `${props.theme.pxToRem(-50)}`};
    flex-basis: 63.645%;
    ${props => props.theme.mediaQueries.mobileOnly} {
      > div:first-child {
        margin: 0 ${props => props.theme.pxToRem(-33)} ${props => props.theme.pxToRem(30)};
      }
    }
  }
`;

const StyledHeaderDescription = styled.div<{ isBgColorDark: boolean }>`
   {
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => (props.isBgColorDark ? props.theme.colors.neutralWhite : props.theme.colors.neutralGrey8)};
  }
`;

const StyledHeading = styled(Heading)<{ 'data-is-single-column': boolean }>`
  margin-bottom: ${props => (props['data-is-single-column'] ? props.theme.pxToRem(16) : props.theme.pxToRem(24))};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.l};
    line-height: 1.33;
  }

  p {
    line-height: 1.38;
    margin: 0;
  }
`;

const StyledParagraph = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};

  p {
    line-height: 1.33;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(12)};
    font-size: ${props => props.theme.fontSizes.s};
    line-height: 1.5;
  }
`;

const StyledButtonWrapper = styled.div`
  margin-top: ${props => props.theme.pxToRem(32)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(12)};
    text-align: center;
  }
`;
