import { createSelector } from 'reselect';
import { selectedJobRoleIdSelector } from '../layouts/selectors';
import { slugIdMapCreator } from './helpers';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.jobRoles,
  jobRolesRoot => jobRolesRoot
);

export const jobRolesFetchedSelector = createSelector(
  rootSelector,
  (jobRoles: State.JobRoles): boolean => jobRoles.jobRolesFetched
);

export const jobRolesHashSelector = createSelector(
  rootSelector,
  (jobRolesRoot: State.JobRoles): State.JobRolesHash | null => jobRolesRoot.jobRolesHash
);

const jobRolesSelector = createSelector(
  jobRolesHashSelector,
  (jobRolesHash: State.JobRolesHash | null): State.JobRole[] | null => jobRolesHash && Object.values(jobRolesHash)
);

export const jobRolesSlugIdMapSelector = createSelector(jobRolesSelector, slugIdMapCreator);

export const jobRolesSlugsStrSelector = createSelector(
  jobRolesSlugIdMapSelector,
  (jobRolesSlugIdMap: { [key: string]: string }): string => Object.keys(jobRolesSlugIdMap || emptyObject).join('|')
);

export const selectedJobRoleNameSelector = createSelector(
  [jobRolesHashSelector, selectedJobRoleIdSelector],
  (jobRolesHash: State.JobRolesHash | null, selectedJobRoleId: string | null): string | null => {
    if (!jobRolesHash || !selectedJobRoleId || !jobRolesHash[selectedJobRoleId]) return null;
    return jobRolesHash[selectedJobRoleId].name;
  }
);
