import React from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import { formatPrice } from 'utils';
import { Invoices } from 'mxp-schemas';
import { ButtonEnums, Button, Table, Checkbox, ClapSpinner } from 'components/atoms';
import { ReactComponent as GetApp } from 'resources/images/icon-dev-ic-get-app.svg';
import { useDispatch } from 'react-redux';
import { toggleSelectInvoice } from 'modules/admin/actions';
import { CancelInvoiceModal } from 'components/admin/molecules/CancelInvoiceModal/CancelInvoiceModal';

interface Props {
  invoice: State.SearchInvoicesResult;
  downloadId: string | null;
  downloadError: boolean;
  isDownloadButtonDisabled: boolean;
  isFirmBilling?: boolean;
  downloadFileClick: (invoice: State.SearchInvoicesResult) => void;
  payInvoiceClick: (id: string) => void;
  invoiceDetailsClick?: (invoiceNumber: string) => void;
  legalEntity?: string;
  onSuccessCancel?: () => void;
}

export const SearchInvoicesTableRow: React.FC<Props> = React.memo(
  ({
    invoice,
    downloadId,
    downloadError,
    isDownloadButtonDisabled,
    isFirmBilling = false,
    downloadFileClick,
    payInvoiceClick,
    invoiceDetailsClick,
    legalEntity,
    onSuccessCancel,
  }) => {
    const dispatch = useDispatch();

    const handleDownloadFileClick = React.useCallback(() => {
      downloadFileClick(invoice);
    }, [invoice, downloadFileClick]);

    const handlePayInvoiceClick = React.useCallback(() => {
      payInvoiceClick(invoice.id);
    }, [invoice.id, payInvoiceClick]);

    const handleSelectInvoice = React.useCallback(() => {
      dispatch(toggleSelectInvoice(invoice.invoiceNumber));
    }, [dispatch, invoice]);

    const handleInvoiceDetails = React.useCallback(() => {
      if (invoiceDetailsClick) {
        invoiceDetailsClick(invoice.invoiceNumber);
      }
    }, [invoice, invoiceDetailsClick]);

    return (
      <Table.Row>
        <StyledTableCell>
          <CheckboxStyled
            width="18"
            height="18"
            type="checkbox"
            testId={`select-all-invoices-checkbox`}
            checked={invoice.isSelected}
            onChange={handleSelectInvoice}
          />
        </StyledTableCell>
        <StyledTableCell>{invoice.invoiceDate}</StyledTableCell>
        <StyledTableCell>
          {isFirmBilling ? (
            <StyledCancelInvoiceButton
              testId={'invoice-invoice-btn'}
              variant={ButtonEnums.variants.link}
              onClick={handleInvoiceDetails}
            >
              {invoice.invoiceNumber}
            </StyledCancelInvoiceButton>
          ) : (
            invoice.invoiceNumber
          )}
        </StyledTableCell>
        <StyledTableCell>{formatPrice(invoice.amount, invoice.currency)}</StyledTableCell>
        <StyledTableCell>{invoice.DueDateOverride__c ? invoice.DueDateOverride__c : invoice.dueDate}</StyledTableCell>
        <StyledTableCell>
          {invoice.status === Invoices.InvoiceStatus.OVERDUE ? (
            <StyledSpan>{invoice.status}</StyledSpan>
          ) : (
            invoice.status
          )}
        </StyledTableCell>
        <StyledTableCell>
          {downloadId === invoice.id && !downloadError && !isDownloadButtonDisabled ? (
            <StyledSpinner>
              <ClapSpinner size={24} frontColor={theme.colors.primaryPurple} backColor={theme.colors.primaryPurple} />
            </StyledSpinner>
          ) : (
            <StyledDownloadButton
              testId={invoice.id}
              variant={ButtonEnums.variants.link}
              size={ButtonEnums.sizes.medium}
              icon={<GetApp />}
              iconPosition={ButtonEnums.iconPosition.left}
              onClick={handleDownloadFileClick}
            >
              Download
            </StyledDownloadButton>
          )}
        </StyledTableCell>
        {isFirmBilling && (
          <StyledTableCell>
            {invoice.status !== Invoices.InvoiceStatus.PAID &&
              invoice.status !== Invoices.InvoiceStatus.CANCELLED &&
              invoice.status !== Invoices.InvoiceStatus.INPROGRESS && (
                <CancelInvoiceModal
                  invoice={{ ...invoice, legalEntity }}
                  isInvoiceParent={true}
                  onSuccess={onSuccessCancel}
                />
              )}
          </StyledTableCell>
        )}
        <StyledTableCell>
          {invoice.status !== Invoices.InvoiceStatus.PAID &&
            invoice.status !== Invoices.InvoiceStatus.CANCELLED &&
            invoice.status !== Invoices.InvoiceStatus.INPROGRESS && (
              <StyledPayButton
                bordered
                size={ButtonEnums.sizes.small}
                testId={'pay-invoice-btn'}
                variant={ButtonEnums.variants.primary}
                onClick={handlePayInvoiceClick}
                toggle
              >
                Pay
              </StyledPayButton>
            )}
        </StyledTableCell>
      </Table.Row>
    );
  }
);

const StyledSpinner = styled.div`
  > div {
    margin: 0 auto;
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: initial;
    }
  }
`;

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.interfaceRed};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledDownloadButton = styled(Button)`
  display: flex;
  margin-left: auto;
  svg path {
    fill: ${props => props.theme.colors.primaryPurple};
  }

  ${props =>
    props.disabled &&
    `
  svg path {
    fill: ${props.theme.colors.neutralGrey4};
  }
  `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: initial;
  }
`;

const StyledPayButton = styled(Button)`
  &&&& {
    justify-content: center;
    line-height: 1.57;
    padding: ${props => props.theme.pxToRem(5)} 0;
    min-width: ${props => props.theme.pxToRem(92)};
  }
`;

const StyledCancelInvoiceButton = styled(Button)`
  &&&& {
    justify-content: center;
    line-height: 1.57;
    min-width: ${props => props.theme.pxToRem(119)};
  }
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    > label {
      font-size: ${props => props.theme.fontSizes.s};
      color: ${props => props.theme.colors.neutralGrey8};
      font-weight: ${props => props.theme.fontWeights.light};
    }
  }
`;

const StyledTableCell = styled(Table.Cell)`
  &&&&& {
    vertical-align: inherit;
  }
`;
