import { receiptSelector } from './selectors';
import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import download from 'downloadjs';
import { default as request, updateContext } from 'utils/GraphQLClient';
import { getHash } from 'connected-react-router';
import { GET_RECEIPTS, GET_RECEIPT_FILE } from 'mxp-graphql-queries';
import { ProfileListSortByValues } from 'constants/index';
import { paginationSortChange } from 'modules/layouts';
import { setPaginationTotal, setPaginationInfo, paginationFilterActiveChange } from 'modules/layouts/actions';
import { getInitialPaginationInfo } from 'modules/products/actions';
import { filteredDueReceiptsSelector, filteredPaidReceiptsSelector } from 'modules/receipts/selectors';
import { Invoices } from 'mxp-schemas';

// ------------------------------------
// Actions
// ------------------------------------

export const getReceipts: any = createAction(
  'receipts/GET_RECEIPTS',
  (legalEntity: any) => async (dispatch: Dispatch, getState: () => State.Root) => {
    if (legalEntity) {
      const customHeaders = {
        existingEntity: legalEntity.toLowerCase(),
      };
      await updateContext(customHeaders);
    }
    return request(GET_RECEIPTS).then(response => {
      const receipts = response.getReceipts;
      if (!receipts?.length) return receipts;

      const state = getState();
      const urlHash = getHash(state).toUpperCase();
      // necessary as pagination info is set separately to selectors
      const dueReceipts =
        receipts?.filter((receipt: State.InvoiceTableRow) => receipt.status !== Invoices.InvoiceStatus.PAID) || [];
      const paidReceipts =
        receipts?.filter((receipt: State.InvoiceTableRow) => receipt.status === Invoices.InvoiceStatus.PAID) || [];
      const isHashPaidTab = urlHash.includes(Invoices.InvoiceKeys.PAYMENT_HISTORY);
      // if hash value doesn't exist, default is always payment history tab
      const total = !urlHash || isHashPaidTab ? paidReceipts?.length : dueReceipts?.length || 0;
      dispatch(setPaginationInfo(getInitialPaginationInfo(total)));
      return receipts;
    });
  }
);

export const getReceiptFile: any = createAction(
  'receipts/GET_RECEIPT_FILE',
  (receiptId: string, dropdownLegalEntity: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setDownloadingId(receiptId));
    const state: State.Root = getState();
    const receipt: State.Invoice | null = receiptSelector(state);
    if (!receipt) return Promise.reject('Download Error: Receipt Not Found');
    return request(GET_RECEIPT_FILE, { invoiceId: receipt.id, legalEntity: dropdownLegalEntity }).then(response => {
      download(`data:application/pdf;base64,${response.getReceiptFile}`, `${receipt.invoiceNumber}.pdf`);
    });
  }
);

export const setDownloadingId: any = createAction('receipts/SET_DOWNLOADING_ID');

export const resetModule: any = createAction('receipts/RESET_MODULE');

export const changeHistorySort: any = createAction(
  'CHANGE_HISTORY_SORT',
  (tab: Invoices.InvoiceKeys, sortBy: ProfileListSortByValues) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(paginationSortChange(sortBy));
    const state: State.Root = getState();
    const isPaidList = tab === Invoices.InvoiceKeys.PAYMENT_HISTORY;
    const filtered = !isPaidList ? filteredDueReceiptsSelector(state) : filteredPaidReceiptsSelector(state);
    const total = filtered?.length || 0;

    dispatch(setPaginationTotal(total));
  }
);

export const changeFilterActive: any = createAction(
  'CHANGE_FILTER_ACTIVE',
  (tab: Invoices.InvoiceKeys) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(paginationFilterActiveChange(tab));
    const state: State.Root = getState();
    const isPaidList = tab === Invoices.InvoiceKeys.PAYMENT_HISTORY;
    const filtered = !isPaidList ? filteredDueReceiptsSelector(state) : filteredPaidReceiptsSelector(state);
    const total = filtered?.length || 0;
    dispatch(setPaginationTotal(total));
  }
);
