import { handleActions } from 'redux-actions';
import { initialState } from './constants';
import { pickListLoading, getPickList } from './actions';

const ACTION_HANDLERS = {
  [pickListLoading]: (state: State.PickListsState): State.PickListsState => ({
    ...state,
    loading: true,
    error: null,
  }),

  [getPickList]: {
    next: (state: State.PickListsState, action: any): State.PickListsState => {
      const pickListData = action.payload;
      const { pickListId } = pickListData;

      return {
        ...state,
        loading: false,
        error: null,
        pickLists: {
          ...state.pickLists,
          [pickListId]: pickListData,
        },
      };
    },
    throw: (state: State.PickListsState, action: any): State.PickListsState => ({
      ...state,
      loading: false,
      error: action.payload?.message || 'Error fetching pick list',
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
