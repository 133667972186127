import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AccordionPanelProps } from 'semantic-ui-react';
import { Product } from 'mxp-schemas';
import { User as UserUtils } from 'mxp-utils';
import { Button, ButtonEnums } from 'components/atoms';
import { MembershipCard } from 'components/organisms';
import { fetchedProductBySkuPriceSelector } from 'modules/products/selectors';
import { CimaMembershipPackagePanelWrapper as PanelContainer } from '../CimaMembershipPackagePanelWrapper/CimaMembershipPackagePanelWrapper';
import { getConstantByKeySelector, isAdminPortalSelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { areAllTruthy, fromCentsFormat, formatPrice } from 'utils';
import { isAuthSelector } from 'modules/user/selectors';
import { setHasSelectedFcmaDesignation, setIsAllowToAddFcmaInCart } from 'modules/membership/actions';
import { hasSelectedFcmaDesignationSelector, isAllowToAddFcmaInCartSelector } from 'modules/membership/selectors';
import { getProductBySku } from 'modules/products/actions';

interface MembershipPackagePanelProps extends AccordionPanelProps {
  handleClick: () => void;
  loading?: boolean;
}

export const CimaMembershipConfirmDesignationPanel: React.FC<MembershipPackagePanelProps> = ({
  handleClick,
  loading,
}) => {
  const dispatch = useDispatch();
  const [isCardSelected, setIsCardSelected] = useState(true);

  const ALL = 'All';
  const hasSelectedFcmaDesignation = useSelector(hasSelectedFcmaDesignationSelector);
  const isAllowToAddFcma = useSelector(isAllowToAddFcmaInCartSelector);
  const fetchedProductBySkuPrice = useSelector(fetchedProductBySkuPriceSelector);

  const designationTitleFallback = 'CGMA FCMA';
  const designationDescriptionFallBack =
    'As a CGMA Fellow your designation has automatically been added to your cart and you will see it at checkout';
  const fcmaDesignationTitle =
    useSelector(getConstantByKeySelector(CONSTANTS.FCMA_DESIGNATION.TITLE)) || designationTitleFallback;
  const fcmaDesignationDescription =
    useSelector(getConstantByKeySelector(CONSTANTS.FCMA_DESIGNATION.DESCRIPTION)) || designationDescriptionFallBack;
  const isAuth = useSelector(isAuthSelector);
  const isAdminPortal = useSelector(isAdminPortalSelector);
  const isImpersonation = areAllTruthy(Boolean(isAuth), isAdminPortal);

  // Use the price fetched from CT Query
  const fcmaFormattedPrice = UserUtils.conditionalFunction(
    !!fetchedProductBySkuPrice,
    formatPrice(fromCentsFormat(fetchedProductBySkuPrice?.amount), fetchedProductBySkuPrice?.currency),
    formatPrice(20, Product.ProductCurrencyLabel.GBP) // Fallback value £20.00
  );

  const handleSelectToBasketClick = useCallback(() => {
    setIsCardSelected(prevState => !prevState);
    if (hasSelectedFcmaDesignation) dispatch(setHasSelectedFcmaDesignation(false));
  }, [dispatch, hasSelectedFcmaDesignation]);

  const handleClickNextButton = useCallback(() => {
    dispatch(setHasSelectedFcmaDesignation(true));
    handleClick();
  }, [dispatch, handleClick]);

  useEffect(() => {
    if (hasSelectedFcmaDesignation && !isAllowToAddFcma) {
      setIsCardSelected(false);
    }
  }, [dispatch, hasSelectedFcmaDesignation, isAllowToAddFcma]);

  useEffect(() => {
    dispatch(setIsAllowToAddFcmaInCart(isCardSelected));
  }, [dispatch, isCardSelected]);

  useEffect(() => {
    dispatch(getProductBySku(Product.CIMA_CREDENTIALS_SKU.FCMA));
  }, [dispatch]);

  return (
    <PanelContainer>
      <CategoriesContainer>
        <StyledButton testId={`fcma-designation-all}`} isSelected={true}>
          {ALL}
        </StyledButton>
      </CategoriesContainer>
      <MembershipCard
        headerTitle={fcmaDesignationTitle}
        description={fcmaDesignationDescription}
        formattedPrice={fcmaFormattedPrice}
        isImpersonation={isImpersonation}
        handleClick={handleSelectToBasketClick}
        isCardSelected={isCardSelected}
        isHideLearnMore={true}
      />
      <ConfirmButtonContainer>
        <StyledConfirmButton
          testId={'next-btn'}
          onClick={handleClickNextButton}
          loading={loading}
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
        >
          {'Next'}
        </StyledConfirmButton>
      </ConfirmButtonContainer>
    </PanelContainer>
  );
};

interface StyledButtonProp {
  isSelected: boolean;
}

const activeButtonStyles = css`
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryPurple};
  color: ${props => props.theme.colors.neutralWhite};
  background-color: ${props => props.theme.colors.primaryPurple};
`;

const desktopWidthResponsiveStyles = css`
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: 100%;
  }
`;

const CategoriesContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)};
  padding-left: ${props => props.theme.pxToRem(8)};
  text-align: left;
  ${desktopWidthResponsiveStyles}
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(-22)};
    padding-top: ${props => props.theme.pxToRem(9)};
  }
`;

const StyledButton = styled(Button)<StyledButtonProp>`
  &&& {
    font-weight: normal;
    align-items: center;
    border-radius: ${props => props.theme.pxToRem(20)};
    padding: ${props =>
      `${props.theme.pxToRem(8)} ${props.theme.pxToRem(14)} ${props.theme.pxToRem(8)} ${props.theme.pxToRem(14)}`};
    margin: ${props =>
      `${props.theme.pxToRem(-5)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(40)} ${props.theme.pxToRem(-15)}`};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: ${props =>
        `${props.theme.pxToRem(10)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(12)} ${props.theme.pxToRem(3)}`};
    }

    &:hover {
      ${activeButtonStyles}
    }

    &:focus {
      ${activeButtonStyles}
    }

    ${props =>
      props.isSelected
        ? css`
            ${activeButtonStyles}
          `
        : css`
            color: ${props.theme.colors.primaryPurple};
            background-color: ${props.theme.colors.neutralGrey2};
          `}
  }
`;

const StyledConfirmButton = styled(Button)`
  &&& {
    height: ${props => props.theme.pxToRem(24)};
    width: ${props => props.theme.pxToRem(265)};
    border-radius: ${props => props.theme.pxToRem(4)};
    margin-top: ${props => props.theme.pxToRem(46)} !important;
    line-height: 1.57 !important;
    color: ${props => props.theme.colors.neutralWhite};
    background-color: ${props => props.theme.colors.primaryPurple};
    object-fit: contain;
    &:hover {
      ${activeButtonStyles}
    }
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    position: relative;
    top: ${props => props.theme.pxToRem(25)};
  }
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.theme.pxToRem(50)};
  ${desktopWidthResponsiveStyles}
`;
