import React, { useState, useCallback } from 'react';
import { AccordionTitleProps } from 'semantic-ui-react';
import { MembershipTypes } from 'mxp-schemas';
import { CimaQualificationLevel, ExamStructure, ExamStructureItemProps } from 'components/molecules';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
interface Props {
  qualificationLevels: State.StudentProgression['qualificationLevels'];
  pathway: MembershipTypes.Pathway | null | undefined;
}

export const CimaDashboardQualificationPanel: React.FC<Props> = ({ qualificationLevels, pathway }) => {
  const qualifications = MembershipTypes.CimaQualificationLevelPathValue;

  const examStructureItems: ExamStructureItemProps[] =
    qualificationLevels?.map(qualificationLevel => {
      let qualificationType: string = '';
      switch (qualificationLevel.name) {
        case qualifications.EPA4:
          qualificationType = 'Level 4 End Point Assessment (EPA)';
          break;
        case qualifications.EPA7:
          qualificationType = 'Level 7 EPA2';
          break;
        default:
          qualificationType = qualificationLevel.name;
          break;
      }
      return {
        qualificationType,
        status:
          pathway === MembershipTypes.Pathway.FLP && qualificationLevel.status === 'Locked'
            ? 'Active'
            : qualificationLevel.status,
        examsContent: <CimaQualificationLevel qualificationLevelDetails={qualificationLevel} pathway={pathway} />,
      };
    }) || [];
  const [activeIndices, setActiveIndices] = useState(
    examStructureItems
      ? examStructureItems?.map((exam, index: number) => ({
          stepNumber: index,
          isOpen: false,
        }))
      : [
          {
            stepNumber: -1,
            isOpen: false,
          },
        ]
  );

  const { windowPathname } = getPageNameAndSiteSection();

  const handleAccordionClick = useCallback(
    (e, { index, ...props }: AccordionTitleProps) => {
      if (index !== undefined) {
        setActiveIndices(currData => {
          return currData?.map(currentIndex => {
            const newActiveIndex = {
              stepNumber: currentIndex.stepNumber,
              isOpen: currentIndex.isOpen,
            };

            if (currentIndex.stepNumber === +index) {
              newActiveIndex.isOpen = !currentIndex.isOpen;

              const title = examStructureItems[index as any].qualificationType || index;

              if (currentIndex.isOpen) {
                handleEvent({ clickValue: `button:link:int::ACCORDION-CLOSE:${title}:${windowPathname}` }, NAV_CLICK);
              } else {
                handleEvent({ clickValue: `button:link:int::ACCORDION-OPEN:${title}:${windowPathname}` }, NAV_CLICK);
              }
            }

            return newActiveIndex;
          });
        });
      }
    },
    [examStructureItems, windowPathname]
  );

  if (!examStructureItems) return <></>;

  return <ExamStructure data={examStructureItems} activeIndices={activeIndices} onClick={handleAccordionClick} />;
};
