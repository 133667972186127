import React from 'react';
import { useSelector } from 'react-redux';
import { appInitializedSelector } from 'modules/app/selectors';
import { isPageSearchSelector } from 'modules/router';
import { ExternalAdBanners } from 'components/organisms/ExternalAdBanner/ExternalAdBanner';
import { isServer } from 'utils';
import { Contentful } from 'mxp-schemas';

declare global {
  interface Window {
    googletag: any;
    slotRenderEndedListener: any;
  }
}

export const useGoogleAds = (adsConfig: Contentful.ExternalAdsConfig | undefined) => {
  const appInitialized = useSelector(appInitializedSelector);
  const isPageSearch = useSelector(isPageSearchSelector);

  const removeElement = React.useCallback(() => {
    const googletag = window.googletag || {};

    // Remove ad listener
    if (
      googletag.pubadsReady &&
      googletag.pubads().removeEventListener === 'function' &&
      window.slotRenderEndedListener
    ) {
      googletag.pubads().removeEventListener('slotRenderEnded', window.slotRenderEndedListener);
    }

    // Remove previous ad slots
    if (typeof googletag.destroySlots === 'function') {
      googletag.destroySlots();
    }

    const gptElement = document.querySelector('#gpt-script');
    const initAdsElement = document.querySelector('#initAds-script');
    const displayAdsElement = document.querySelector('#displayAds-script');
    if (gptElement && gptElement.parentNode) gptElement.parentNode.removeChild(gptElement);
    if (initAdsElement && initAdsElement.parentNode) initAdsElement.parentNode.removeChild(initAdsElement);
    if (displayAdsElement && displayAdsElement.parentNode) displayAdsElement.parentNode.removeChild(displayAdsElement);
  }, []);

  React.useEffect(() => {
    if (isServer || !adsConfig || !appInitialized) return;

    const head = document.querySelector('head');
    const body = document.querySelector('body');
    const isEmpty = !Object.values(adsConfig).some(ad => Boolean(ad));
    const isContainersReady = !Object.keys(adsConfig).some(
      adKey => !(Boolean(document.querySelector(`#${ExternalAdBanners[adKey].id}`)) === Boolean(adsConfig[adKey]))
    );
    if (!head || !body || isEmpty || !isContainersReady) return;

    const gpt = document.createElement('script');
    gpt.setAttribute('async', 'true');
    gpt.setAttribute('id', 'gpt-script');
    gpt.setAttribute('src', 'https://securepubads.g.doubleclick.net/tag/js/gpt.js');
    document.head.appendChild(gpt);

    const adSlotScript = () => {
      let text = '';

      Object.keys(adsConfig).forEach(adKey => {
        const adConfig = adsConfig[adKey];
        if (adConfig) {
          // Define slot
          text += `var ${adKey} = googletag.defineSlot('${adConfig?.adUnitPath}', ${JSON.stringify(
            ExternalAdBanners[adKey].size
          )}, '${ExternalAdBanners[adKey].id}')`;
          // Define key value targeting
          for (const [key, value] of Object.entries(ExternalAdBanners[adKey]?.keyValueTargeting || {})) {
            text += `.setTargeting('${key}', '${value}')`;
          }
          text += `.addService(googletag.pubads());`;
          // Responsive size
          if (ExternalAdBanners[adKey].size.length === 2) {
            text += `var mapping = googletag.sizeMapping()
                      .addSize([0, 0], ${JSON.stringify(ExternalAdBanners[adKey].size[0])})
                      .addSize([750, 200], ${JSON.stringify(ExternalAdBanners[adKey].size[1])})
                      .build();
                    ${adKey}.defineSizeMapping(mapping);`;
          }
        }
      });

      return text;
    };

    const adDisplayScript = () => {
      let text = '';

      Object.keys(adsConfig).forEach(adKey => {
        const adConfig = adsConfig[adKey];
        if (adConfig) {
          text += `googletag.cmd.push(function() { googletag.display('${ExternalAdBanners[adKey].id}'); });`;
        }
      });

      return text;
    };

    const initAds = document.createElement('script');
    initAds.setAttribute('id', 'initAds-script');
    initAds.innerHTML = `
      window.googletag = window.googletag || { cmd: [] };
    
      googletag.cmd.push(function() {
        ${adSlotScript()}
 
        window.slotRenderEndedListener = function(event) {
          var slotId = event.slot.getSlotElementId();
          var isEmpty = event.isEmpty;
          if (isEmpty) {
            var adElement = document.getElementById(slotId+'-container');
            if (adElement && adElement.parentNode) {
              adElement.parentNode.removeChild(adElement);
            }
          }
        };
        googletag.pubads().addEventListener('slotRenderEnded', window.slotRenderEndedListener);
        
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });
		`;
    document.head.appendChild(initAds);

    const displayAds = document.createElement('script');
    displayAds.setAttribute('id', 'displayAds-script');
    displayAds.innerHTML = adDisplayScript();
    document.body.appendChild(displayAds);

    // cleanup script on hook unmount
    return removeElement;
  }, [appInitialized, removeElement, ...(isPageSearch ? [] : [adsConfig])]); // eslint-disable-line react-hooks/exhaustive-deps
};
