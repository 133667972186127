import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Container, Grid, Divider } from 'components/atoms';
import { adminSearchInvoiceScreens } from 'constants/index';
import { PreviousPage } from 'components/organisms/PreviousPage/PreviousPage';
import { InvoiceSummary } from 'components/molecules/InvoiceSummary/InvoiceSummary';
import { HeaderSteps } from 'components/molecules/HeaderSteps/HeaderSteps';
import { useDispatch, useSelector } from 'react-redux';
import { selectedInvoiceSelector } from 'modules/centerAdmin/selectors';
import { setSelectedInvoice } from 'modules/centerAdmin';
import { Loader } from 'components/atoms/Loader/Loader';
import { CenterAdminStepPaymentInvoiceContainer } from 'containers/CenterAdminStepPaymentInvoiceContainer';

export const PageCenterAdminInvoicesPaymentPage: React.FC = () => {
  const dispatch = useDispatch();
  const targetContainer = React.useRef<HTMLDivElement | null>(null);
  const selectedInvoice = useSelector(selectedInvoiceSelector) as State.Invoice;
  const isAdminInvoicesPayment: boolean = true;

  React.useEffect(() => {
    if (!selectedInvoice) {
      dispatch(setSelectedInvoice());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!selectedInvoice) return <Loader size="medium">Loading</Loader>;

  const totalBalance: number = selectedInvoice.balance;

  return (
    <>
      <Helmet>
        <script async src="https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js" />
      </Helmet>
      <Wrapper ref={targetContainer}>
        <HeaderSteps stepIndex={2} labels={adminSearchInvoiceScreens} />
        <Container>
          <PreviousPage backText="Back to search results" />
          <Grid stackable>
            <Grid.Row>
              <StyledColumn computer={10} tablet={16}>
                <StyledPageTitle>Payment</StyledPageTitle>
                <StyledPageSubtitle>Please enter the customer's payment card details</StyledPageSubtitle>
                <StyledDivider />
                <CenterAdminStepPaymentInvoiceContainer />
              </StyledColumn>
              <StyledColumn computer={1} />
              <Grid.Column computer={5} tablet={16}>
                <InvoiceSummary
                  isAdminInvoicesPayment={isAdminInvoicesPayment}
                  invoices={[selectedInvoice]}
                  amountToPay={totalBalance}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  overflow-x: hidden;
  height: 100%;
  ${props => props.theme.mediaQueries.desktopOnly} {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow-y: visible;
    overflow: visible;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow: visible;
    height: auto;
    background: transparent;
  }
`;

const StyledColumn = styled(Grid.Column)`
  &&&&&&&&&& {
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }
`;

const StyledPageTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes.xl};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.light};
  height: ${props => props.theme.pxToRem(44)};
  &&& {
    margin: ${props => `${props.theme.pxToRem(18)} 0 ${props.theme.pxToRem(8)}`};
  }
`;

const StyledPageSubtitle = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.57;
  font-weight: ${props => props.theme.fontWeights.light};
  height: ${props => props.theme.pxToRem(22)};
  &&& {
    margin: ${props => `${props.theme.pxToRem(8)} 0 ${props.theme.pxToRem(8)}`};
  }
`;

const StyledDivider = styled(Divider)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(23)};
    margin-bottom: ${props => props.theme.pxToRem(26)};
  }
`;
