import React, { useState } from 'react';
import styled from 'styled-components';
import { InputWithValidation } from 'components/molecules';
import { Checkbox, Dropdown, Link } from 'components/atoms';
import { StrictDropdownProps, CheckboxProps as SemanticUICheckboxProps } from 'semantic-ui-react';
import { Utils, InquiryForm as InquiryFormUtils } from 'mxp-utils';
import { InquiryForm } from 'mxp-schemas';
import { MobileInput } from 'components/atoms/Input/MobileInput';
import { getPath } from 'utils';
import { Routes } from 'constants/index';

interface InquiryFormLeadCaptureRegionalProps {
  setFormValid: (valid: boolean) => void;
  form: InquiryForm.RegionalLeadCapture;
  setForm: (form: InquiryForm.RegionalLeadCapture) => void;
  onFormStart: () => void;
}

export const InquiryFormLeadCaptureRegional: React.FC<InquiryFormLeadCaptureRegionalProps> = ({
  form,
  setForm,
  setFormValid,
  onFormStart,
}) => {
  const [phoneObject, setPhoneObject] = useState({
    phoneNumber: form?.phone || '',
    countryCode: form?.countryCode || 'US',
    dialCode: form?.dialCode || '+1',
  });
  const isFirstNameEmpty = !Boolean(form.firstName.length);
  const isFirstNameValid =
    Utils.isAlphaSpaceString(form.firstName) &&
    Utils.isShorterThan(form.firstName, InquiryForm.FieldLengths.FIRST_NAME);
  const isLastNameEmpty = !Boolean(form.lastName.length);
  const isLastNameValid =
    Utils.isAlphaSpaceString(form.lastName) && Utils.isShorterThan(form.lastName, InquiryForm.FieldLengths.LAST_NAME);
  const isEmailEmpty = !Boolean(form.email.length);
  const isEmailValid =
    Utils.isEmailFormatValid(form.email) && Utils.isShorterThan(form.email, InquiryForm.FieldLengths.EMAIL);
  const isCountryEmpty = !Boolean(form.country.length);
  const isPhoneEmpty = !Boolean(form.phone.length);
  const isCountryCodeEmpty = !Boolean(phoneObject.countryCode.length);
  const isPhoneValid =
    (isPhoneEmpty && isCountryCodeEmpty) ||
    (isPhoneEmpty && !isCountryCodeEmpty) ||
    (!isPhoneEmpty && !isCountryCodeEmpty);
  const isConsentClicked = form.GDPR;

  React.useEffect(() => {
    const formValid =
      !isEmailEmpty &&
      isEmailValid &&
      !isFirstNameEmpty &&
      isFirstNameValid &&
      !isLastNameEmpty &&
      isLastNameValid &&
      !isCountryEmpty &&
      isConsentClicked &&
      (isPhoneEmpty || isPhoneValid);
    setFormValid(formValid);
  }, [
    form,
    isEmailValid,
    isEmailEmpty,
    isFirstNameEmpty,
    isFirstNameValid,
    isLastNameEmpty,
    isLastNameValid,
    isCountryEmpty,
    isPhoneEmpty,
    isPhoneValid,
    isConsentClicked,
    setFormValid,
  ]);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, firstName: event.target.value });
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, lastName: event.target.value });
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, email: event.target.value });
  };
  const handleCountryDropdownChange = React.useCallback(
    (e: any, data: StrictDropdownProps) => {
      const selectedCountry: string = data.value as string;
      setForm({ ...form, country: selectedCountry });
    },
    [setForm, form]
  );

  const handleGDPRChange = React.useCallback(
    (e: any, data: SemanticUICheckboxProps) => {
      setForm({ ...form, GDPR: Boolean(data.checked) });
    },
    [setForm, form]
  );
  const fieldRequired = 'Required';
  const fieldMaxLength = (length: number) => `Sorry, that's too long. Must be fewer than ${length} characters.`;
  const emptyMessage = (input: string) => `Please enter a valid ${input}.`;
  const mobileErrorMessage = isCountryCodeEmpty && !isPhoneEmpty ? 'Please enter country code' : null;

  React.useEffect(() => {
    if (phoneObject) {
      setForm({
        ...form,
        phone: ` ${phoneObject.dialCode} ${phoneObject.phoneNumber}`,
        countryCode: phoneObject.countryCode,
      });
    }
  }, [phoneObject]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <InputWithValidation
        labelName={'First name'}
        name={InquiryForm.FieldIds.FIRST_NAME}
        type="text"
        value={form.firstName}
        testId={InquiryForm.FieldIds.FIRST_NAME}
        labelId={InquiryForm.FieldIds.FIRST_NAME}
        onChange={handleFirstNameChange}
        onClick={onFormStart}
        isCorrect={isFirstNameValid && !isFirstNameEmpty}
        infoMessage={fieldRequired}
        errorMessage={
          isFirstNameEmpty || !isFirstNameValid
            ? emptyMessage('first name')
            : `${fieldMaxLength(InquiryForm.FieldLengths.FIRST_NAME)}`
        }
      />
      <InputWithValidation
        labelName={'Last name'}
        name={InquiryForm.FieldIds.LAST_NAME}
        type="text"
        value={form.lastName}
        testId={InquiryForm.FieldIds.LAST_NAME}
        labelId={InquiryForm.FieldIds.LAST_NAME}
        onChange={handleLastNameChange}
        isCorrect={isLastNameValid && !isLastNameEmpty}
        infoMessage={fieldRequired}
        errorMessage={
          isLastNameEmpty || !isLastNameValid
            ? emptyMessage('last name')
            : `${fieldMaxLength(InquiryForm.FieldLengths.LAST_NAME)}`
        }
      />
      <DropDownContainer>
        <Label>Location</Label>
        <StyledDropdown
          testId={InquiryForm.FieldIds.LOCATION}
          onChange={handleCountryDropdownChange}
          options={InquiryFormUtils.LeadCaptureFormCountryList}
          value={form.country}
          placeholder="Location"
          errorMessage="Please select a location from the dropdown."
          error={isCountryEmpty}
          search={true}
        />
      </DropDownContainer>
      <InputWithValidation
        labelName={'Email address'}
        name={InquiryForm.FieldIds.EMAIL}
        type="email"
        value={form.email}
        testId={InquiryForm.FieldIds.EMAIL}
        labelId={InquiryForm.FieldIds.EMAIL}
        onChange={handleEmailChange}
        isCorrect={isEmailValid && !isEmailEmpty}
        infoMessage={fieldRequired}
        errorMessage={'Please enter a valid email address.'}
        isCorrectIconShown
      />
      <StyledMobileInput phoneObject={phoneObject} setPhoneObject={setPhoneObject} errorMessage={mobileErrorMessage} />
      <StyledCheckbox
        width="18"
        height="18"
        type="checkbox"
        testId={InquiryForm.FieldIds.GDPR_CONSENT}
        label={
          <label>
            I agree that my personal data will be processed and secured in accordance with the{' '}
            <Link
              testId="inquiry-form-regional-lead-capture-privacy-policy"
              target={'_blank'}
              to={getPath(Routes.PRIVACY_POLICY)}
            >
              privacy policy
            </Link>
          </label>
        }
        checked={form.GDPR}
        onChange={handleGDPRChange}
      />
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  &&&&& {
    object-fit: contain;
    line-height: 1;
    margin-top: ${props => props.theme.pxToRem(5)};
    margin-bottom: ${props => props.theme.pxToRem(23)};

    > label {
      font-size: ${props => props.theme.fontSizes.xs};
      color: ${props => props.theme.colors.neutralGrey8};
      line-height: 1.57;
      font-weight: ${props => props.theme.fontWeights.light};
      padding-left: ${props => props.theme.pxToRem(24)};
    }
  }
`;

const DropDownContainer = styled.div`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(26)};
  }
`;

const Label = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      width: 100%;
      height: ${props.theme.pxToRem(48)};
      padding-right: ${props.theme.pxToRem(10)};
      background-color: ${props.theme.colors.neutralWhite};

      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }

      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.primaryPurple};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }

      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.primaryPurple};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }

      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: ${props.theme.pxToRem(1)} solid ${props.theme.colors.interfaceBlue};
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
    }
  `}
`;

const StyledMobileInput = styled(MobileInput)`
  &&& {
    width: ${props => props.theme.pxToRem(260)} !important;
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: auto !important;
    }
  }
`;
