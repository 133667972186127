import {
  filteredCIMAMembershipTypesSelector,
  isAllowToAddFcmaInCartSelector,
  userMembershipPackageSelector,
} from 'modules/membership/selectors';
import { currentJourneyLearningPathwaySelector } from 'modules/user/selectors';
import { MembershipTypes } from 'mxp-schemas';
import React from 'react';
import { useSelector } from 'react-redux';
import { IC_ACCORDION_DOWN, IC_ACCORDION_UP, IC_CANCEL_GRAY } from 'resources/images';
import { Accordion, AccordionTitleProps, Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { Membership as MembershipUtils } from 'mxp-utils';
import { CONSTANTS } from 'modules/app/constants';
import { getConstantByKeySelector } from 'modules/app/selectors';

interface MembershipPackageAccordionItemProps {
  key: string;
  title: string;
  content: {
    content: React.ReactNode;
  };
  pillValue?: string;
  showInFlpRenewals?: boolean;
  showInUpgrade?: boolean;
  popupContent?: string;
}

interface MembershipPackageAccordionProps {
  data: MembershipPackageAccordionItemProps[];
  activeIndex: number;
  maxIndex: number;
  onClick: AccordionTitleProps['onClick'];
  isCenterMembershipRenewal?: boolean;
  isCimaMembership?: boolean;
  isCenterMembershipJourney?: boolean;
  centerRenewalIssuer?: string;
  isCAQType?: boolean;
}

export const CimaMembershipPackageAccordion: React.FC<MembershipPackageAccordionProps> = ({
  data,
  activeIndex,
  maxIndex,
  onClick,
  isCenterMembershipRenewal = false,
  isCimaMembership = false,
  isCenterMembershipJourney,
  centerRenewalIssuer,
  isCAQType,
}) => {
  const currentJourneyLearningPathway = useSelector(currentJourneyLearningPathwaySelector);
  const userChoice = useSelector(userMembershipPackageSelector);
  const types = useSelector(filteredCIMAMembershipTypesSelector);
  const isAllowToAddFcma = useSelector(isAllowToAddFcmaInCartSelector);
  const fcmaDesignationTitle = useSelector(getConstantByKeySelector(CONSTANTS.FCMA_DESIGNATION.TITLE)) || '';

  const popupTrigger = () => (
    <div style={{ display: 'inline-block' }}>
      <StyledIcon name="info circle" />
    </div>
  );
  const toolTipContent = (content: string) => (
    <div>
      <p>{content}</p>
    </div>
  );
  const getPillValue = (key: string, pillValue: string | undefined, i: number) => {
    let pill = activeIndex === 0 && pillValue !== '0' ? pillValue : pillValue ?? '0';
    if ((key === 'skill' && pill === '0') || pill === userChoice?.tier) {
      pill = MembershipUtils.getPillValueFormat(userChoice?.tier, types);
    }
    if (key === 'designation' && activeIndex > i && isAllowToAddFcma) pill = fcmaDesignationTitle;
    if ((activeIndex === 0 && key === 'section') || (key === 'credentials' && pillValue === '0')) pill = '1';
    if (activeIndex < i) pill = '';
    return pill;
  };

  return (
    <>
      {data.map(({ key, title, content, pillValue, popupContent }, i) => {
        // This Condition is for Slug Pill value, some Slug Pill value is undefined and null.
        const pill = getPillValue(key, pillValue, i);
        return (
          <>
            <StyledAccordionItemHeader>
              <StyledAccordionItemCounter>{!isCenterMembershipRenewal && <>{i + 1}</>}</StyledAccordionItemCounter>
              <StyledAccordionItemTitleContainer
                activeIndex={activeIndex}
                active={activeIndex === i}
                index={i}
                onClick={onClick}
              >
                <div style={{ width: '100%' }}>
                  {popupContent && (
                    <StyledPopup
                      content={toolTipContent(popupContent)}
                      key="icon"
                      trigger={popupTrigger()}
                      hoverable
                      hideOnScroll
                    />
                  )}
                  {title}
                  {activeIndex === i && (
                    <>
                      {isCenterMembershipRenewal && (
                        <>
                          {Boolean(isCAQType) && (
                            <InfoPill isCenterMembershipRenewal={isCenterMembershipRenewal} index={data.length}>
                              {centerRenewalIssuer} Issuer audits
                            </InfoPill>
                          )}
                          <InfoPill isCenterMembershipRenewal={isCenterMembershipRenewal} index={data.length}>
                            {pill} CPAs
                          </InfoPill>
                        </>
                      )}
                    </>
                  )}
                  {i !== activeIndex && i < maxIndex && pill !== '' && pill !== undefined && (
                    <InfoPill
                      isFlpPackage={
                        currentJourneyLearningPathway === MembershipTypes.Pathway.FLP &&
                        (userChoice.type.slug === MembershipTypes.CimaMembershipProductSlug.CIMA_CANDIDATE_PRODUCT ||
                          userChoice.type.slug === MembershipTypes.CimaMembershipProductSlug.CIMA_AFFILIATE_PRODUCT) &&
                        Boolean(pill) &&
                        // pillValue!.length > 20 &&
                        i === 1
                      }
                      index={data.length}
                    >
                      <StyledSpanChoose>{pill}</StyledSpanChoose>
                      <StyledHeaderSlugCloseIcon src={IC_CANCEL_GRAY} />
                    </InfoPill>
                  )}
                </div>
                <StyledHeaderAccordionIcon src={activeIndex === i ? IC_ACCORDION_UP : IC_ACCORDION_DOWN} />
              </StyledAccordionItemTitleContainer>
            </StyledAccordionItemHeader>
            <StyledAccordionItemContent>
              {activeIndex === i && (
                <>
                  {Boolean(isCenterMembershipJourney) && activeIndex === 0 && (
                    <StyledCenterDescription>
                      Please select one center, then click apply. You will have the opportunity to apply for another
                      center after you complete the application.
                    </StyledCenterDescription>
                  )}
                  <StyledAccordionContent active={activeIndex === i}>
                    {content?.content && typeof content.content !== 'string' && typeof content.content !== 'number'
                      ? React.cloneElement(content.content as any, { index: i, activeIndex: i })
                      : content.content}
                  </StyledAccordionContent>
                </>
              )}
            </StyledAccordionItemContent>
          </>
        );
      })}
    </>
  );
};

const StyledAccordionContent = styled(Accordion.Content)`
  width: 100%;
`;

const StyledCenterDescription = styled.p`
  text-align: left;
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralBlack};
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.m};
  margin-bottom: 32px;
`;

const StyledAccordionItemContent = styled.div`
  position: relative;
  padding-left: 72px;
  padding-bottom: 72px;
  padding-top: 32px;

  &:after {
    content: ' ';
    position: absolute;
    width: 8px;
    left: 24px;
    top: 0;
    bottom: 0
    background-image: linear-gradient(to bottom, ${props => props.theme.colors.neutralGrey1} 50%, #6a2d87 40%);
    background-position: left;
    background-size: ${props => props.theme.pxToRem(1)} ${props => props.theme.pxToRem(20)};
    background-repeat: repeat-y;
  }

  ${({ theme }) => theme.mediaQueries.mobileOnly} {
    padding-left: 32px;
  }
`;

const StyledHeaderAccordionIcon = styled.img`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const StyledHeaderSlugCloseIcon = styled.img`
  padding-left: ${props => `${props.theme.pxToRem(5)}`};
  vertical-align: middle;
`;

const StyledSpanChoose = styled.span`
  text-align: left;
  font-family: 'Roboto';
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledIcon = styled(Icon)<{ isCursorPointer?: boolean; isFloatRight?: boolean }>`
  &&&& {
    color: ${props => props.theme.colors.primaryDarkPurple};
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    font-size: ${props => props.theme.fontSizes.m};
    margin-right: ${props => props.theme.pxToRem(10)};
    cursor: ${props => (props.isCursorPointer ? 'pointer' : 'default')};
    ${props => (props.isFloatRight ? ' float: right' : '')};
  }
`;

const StyledAccordionItemHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAccordionItemCounter = styled.div`
  background-image: linear-gradient(85deg, #6a2d87 35%, #a93d69 101%);
  font-family: 'Roboto';
  padding: ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(18)};
  border-radius: ${props => props.theme.pxToRem(5)};
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.xl}
  font-weight: ${props => props.theme.fontWeights.bold};
  text-align: center;
  margin-right: 16px;
`;

const StyledAccordionItemTitleContainer = styled(Accordion.Title)<{
  activeIndex: number;
  index: number;
  active: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 1px solid black;
  font-family: 'Roboto';
  font-size: 1.5rem;
`;

const StyledPopup = styled(Popup)`
  &&&& {
    padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(20)};
    min-width: ${props => props.theme.pxToRem(100)};
  }
`;

const InfoPill = styled.span<{ isCenterMembershipRenewal?: boolean; isFlpPackage?: boolean; index: number }>`
  width: fit-content;
  font-size: ${props => props.theme.fontSizes.xs};
  text-transform: ${props => !props.isCenterMembershipRenewal && `capitalize !important`};
  font-weight: ${props =>
    props.isCenterMembershipRenewal ? props.theme.fontWeights.medium : props.theme.fontWeights.regular};

  text-align: center;
  color: ${({ theme }) => theme.colors.neutralGrey6};
  background-color: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(16)};
  border-style: solid;
  border-width: ${props => props.theme.pxToRem(1)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  vertical-align: middle;
  padding: 4px 8px;
  margin-left: 8px;

  ${({ theme }) => theme.mediaQueries.desktopOnly} {
    display: inline-block;
  }

  ${({ theme }) => theme.mediaQueries.mobileOnly} {
    display: block;
  }
`;
