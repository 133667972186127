import React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { Container, Heading, Divider, Paragraph } from 'components/atoms';
import { BackLink } from 'components/molecules/BackLink/BackLink';
import { LookUpForm } from 'components/admin/organisations/LookUpForm/LookUpForm';
import { User } from 'mxp-schemas';

import { searchUser } from 'modules/admin/actions';

export const AdminAddClientAdminPage: React.SFC<RouteComponentProps> = (props: any) => {
  const dispatch = useDispatch();
  const { accountId, accountNumber: accountNumberParam, legalEntity } = props.match.params;

  // tslint:disable-next-line:no-shadowed-variable
  const onSubmit = ({ accountNumber, userEmail }: { accountNumber: string; userEmail: string }) => {
    return dispatch(searchUser(accountNumber, userEmail)).then(
      async (action: { type: string; payload: User.UserDetails[] }) => {
        if (action.payload.length === 0) {
          dispatch(
            push({
              pathname: getPath(Routes.ADMIN_NO_FETCH_RESULTS),
              state: { query: accountNumber || userEmail },
            })
          );
          return;
        }

        dispatch(
          push({
            pathname: generatePath(getPath(Routes.ADMIN_CLIENT_ADMIN_RESULTS), {
              accountId,
              accountNumber: accountNumberParam,
              legalEntity,
            }),
            state: { results: action.payload },
          })
        );
      }
    );
  };

  return (
    <Container>
      <BackLink
        testId="back-to-client-admins"
        to={{
          pathname: generatePath(getPath(Routes.ADMIN_ORGANIZATION), {
            accountId,
            accountNumber: accountNumberParam,
            legalEntity,
          }),
          hash: 'client-admins',
        }}
      >
        Back to client admins
      </BackLink>
      <StyledHeading as="h1">Add client admin</StyledHeading>
      <Divider />
      <StyledText>Please lookup a customer using a valid AICPA UID or email address.</StyledText>
      <LookUpForm onSubmit={onSubmit} submitText="Search" />
    </Container>
  );
};

const StyledHeading = styled(Heading)`
  font-weight: ${props => props.theme.fontWeights.light};
  margin-top: 0;
`;

const StyledText = styled(Paragraph)`
  max-width: ${props => props.theme.pxToRem(720)};
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.pxToRem(14)};
  line-height: 1.71;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(17)};
`;
