import React from 'react';
import styled, { ThemedStyledProps, DefaultTheme, css } from 'styled-components';
import { Divider, Heading, Link } from 'components/atoms';
import { isExternal } from 'utils';
import { Utils } from 'mxp-utils';

interface Props {
  testId: string;
  header?: string;
  description?: string;
  imageUrl?: string;
  to?: string;
  className?: string;
}

export const SubBlockPromoCard: React.FC<Props> = ({ testId, header, description, imageUrl, to = '', className }) => {
  const renderSubBlockContent = (): React.ReactNode => {
    return (
      <WrapperCardLink
        to={to.substring(0, 3) === 'www' ? `//${to}` : to || '#'}
        buttonText={Utils.stripHtml(header || '')}
        isExternal={isExternal(to)}
      >
        <Wrapper data-testid={testId} className={className}>
          {imageUrl && (
            <StyledImage tabIndex={0} src={imageUrl} alt="images" loading="lazy" width="2000" height="1000" />
          )}
          <StyledPromoCardHeader>
            {header && <StyledHeading as="h2" dangerouslySetInnerHTML={{ __html: header }} />}
          </StyledPromoCardHeader>
          <StyledDivider />
          {description && (
            <MarkupWrapper>
              <StyledDescription dangerouslySetInnerHTML={{ __html: description }} />
            </MarkupWrapper>
          )}
        </Wrapper>
      </WrapperCardLink>
    );
  };
  return <React.Fragment>{renderSubBlockContent()}</React.Fragment>;
};

const hoverCardDesign = css`
  outline: 1px solid ${props => props.theme.colors.primaryPurple};
  background-color: #f7f7f7;
`;

const Wrapper = styled.div<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  margin: 0 auto;
  position: relative;
  max-width: ${props => props.theme.pxToRem(360)};
  height: ${props => props.theme.pxToRem(512)};
  border-radius: ${props => props.theme.pxToRem(1)};
  background-color: ${props => props.theme.colors.neutralWhite};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);

  :hover {
    ${hoverCardDesign}
  }
`;

const WrapperCardLink = styled(Link)`
  text-decoration: none !important;
`;

const StyledImage = styled.img`
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: auto;
`;

const StyledHeading = styled(Heading)`
  width: ${props => props.theme.pxToRem(252)};
  height: ${props => props.theme.pxToRem(44)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  vertical-align: middle;
  line-height: ${props => props.theme.pxToRem(1.83)};
`;

const StyledPromoCardHeader = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: ${props => props.theme.pxToRem(24)} 0 ${props => props.theme.pxToRem(30)} 0;
`;

const StyledDivider = styled(Divider)`
  margin: 0 ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(20)} !important;
`;

const MarkupWrapper = styled.div`
  height: ${props => props.theme.pxToRem(120)}
  overflow: hidden;
`;
const StyledDescription = styled.div`
  position: relative;
  margin: 0 ${props => props.theme.pxToRem(26)} 0 ${props => props.theme.pxToRem(26)};
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralGrey8};
  text-align: center;
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: ${props => props.theme.pxToRem(1.5)};
`;
