import React from 'react';
import { theme } from 'theme';
import { isMobileViewPort } from 'utils';

interface Props {
  size?: number;
  color?: string;
}

const isMobile: boolean = isMobileViewPort();

export const BackToTop = React.memo<Props>(
  ({ size = !isMobile ? 32 : 44, color = theme.colors.primaryPurple, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...props}>
      <path
        fill-rule="evenodd"
        fill={color}
        d="m16 17.76 6.12 6.12L24 22l-8-8-8 8 1.88 1.88L16 17.76zm-8-6.427h16V8.667H8v2.666z"
      />
    </svg>
  )
);
