import { createSelector } from 'reselect';
import { MembershipTypes } from 'mxp-schemas';
import { requiredDocumentGateway } from './constants';
import { personAccountDataSelector } from 'modules/user/selectors';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.uploadedDocument,
  (uploadedDocument: State.UploadedDocument): State.UploadedDocument => uploadedDocument
);

export const uploadedDocumentsSelector = rootSelector;

export const isGatewayRequiredDocumentSelector = createSelector(
  uploadedDocumentsSelector,
  personAccountDataSelector,
  (document: State.UploadedDocument, personAccountData: State.PersonAccountData): boolean => {
    const documentList: string[] = [];

    document?.uploadedDocuments.map((files: any) => {
      // Added condition for L7 and L4 that already uploaded proof of university registration on Normal docs
      if (
        (personAccountData.learningPathway === MembershipTypes.Pathway.APPRENTICE_L7 ||
          personAccountData.learningPathway === MembershipTypes.Pathway.APPRENTICE_L4) &&
        files.Name.replace(/ .*/, '').toLowerCase() === 'proofofuniversityregistration'
      ) {
        const fileName = files.Name.replace(/ .*/, '');
        return documentList.push(fileName.replace('Proofof', ''));
      }
      documentList.push(files.Name.replace(/ .*/, ''));
    });

    const documentGateway: boolean[] = requiredDocumentGateway?.map((file: string) => documentList?.includes(file));
    const isDocumentGateway = documentGateway?.every((docs: boolean) => docs);
    return isDocumentGateway;
  }
);

export const uploadedDocumentsFetchedSelector = createSelector(
  uploadedDocumentsSelector,
  (document: State.UploadedDocument): boolean => {
    return document.uploadedDocumentsFetched;
  }
);
