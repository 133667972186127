import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, AnyAction, Store, compose } from 'redux';
import reduxThunkFsa from 'redux-thunk-fsa';
import promiseMiddleware from 'redux-promise';
import { createRootReducer } from './rootReducer';
import { isServer } from 'utils';
import { getIsLowerEnvironment } from 'utils/env';
import {
  analyticsMiddleware,
  triggerOnboardingMiddleware,
  loginRequiredMiddleware,
  refreshVergicMiddleware,
} from 'middlewares';

const composeEnhancers =
  (!isServer && getIsLowerEnvironment && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

let store: Store<any, AnyAction>;
let history;

export const configureStore: (
  url?: string,
  preloadedState?: State.Root
) => { store: Store<any, AnyAction>; history: any } = (url = '/', preloadedState) => {
  history = isServer ? createMemoryHistory({ initialEntries: [url] }) : createBrowserHistory();

  // Not a fan of using any here but I think this is the best option, would need to change State.Root and therefore fakeState and that makes our test fail
  if (!isServer && (preloadedState as any)?.router?.location) {
    (preloadedState as any).router.location.hash = window.location.hash;
  }
  if (!isServer && preloadedState) {
    (preloadedState as any).app.entryPage = window.location.href.split('#')[0].replace(/http(s)?:\/\//, '');
  }

  store = createStore(
    createRootReducer(history), // root reducer with router state,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        loginRequiredMiddleware,
        triggerOnboardingMiddleware,
        analyticsMiddleware,
        refreshVergicMiddleware,
        routerMiddleware(history), // for dispatching history actions
        reduxThunkFsa,
        promiseMiddleware
      )
    )
  );

  return { store, history };
};

export { store, history };
