import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.allRequiredDocuments,
  (allRequiredDocuments: State.AllRequiredDocuments): State.AllRequiredDocuments => allRequiredDocuments
);

export const exemptionReferenceFetchedSelector = createSelector(
  rootSelector,
  (allRequiredDocuments: State.AllRequiredDocuments): boolean => allRequiredDocuments.exemptReferenceFetched
);

export const exemptionReferenceSelector = rootSelector;
