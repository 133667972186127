import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { push, getLocation } from 'connected-react-router';
import { Routes, StorageNames } from 'constants/index';
import { getPath, removeSessionStorageItem } from 'utils';
import { LayoutActionNames } from 'modules/layouts/constants';
import { getCurrentLocation } from 'utils/routes';
import { isUserHasDefaultCreditCardSelector } from 'modules/checkout/selectors';

// ------------------------------------
// Actions
// ------------------------------------
export const setBurgerExpand: any = createAction(LayoutActionNames.SET_BURGER_EXPAND);

export const setSearchModalExpand: any = createAction(LayoutActionNames.SET_SEARCH_MODAL_EXPAND);

export const setUserSettingsExpanded: any = createAction(LayoutActionNames.SET_USER_SETTINGS_EXPAND);

export const setAcrossLinksExpanded: any = createAction(LayoutActionNames.SET_ACROSS_LINKS_EXPAND);
export const setAdminStickyFooterInvoiceFilterVisible: any = createAction(
  LayoutActionNames.SET_ADMIN_STICKY_FOOTER_INVOICE_FILTER_VISIBLE
);

export const selectTopicId: any = createAction(LayoutActionNames.SELECT_TOPIC_ID);

export const selectIndustryId: any = createAction(LayoutActionNames.SELECT_INDUSTRY_ID);

export const selectTrendId: any = createAction(LayoutActionNames.SELECT_TREND_ID);

export const selectSkillId: any = createAction(LayoutActionNames.SELECT_SKILL_ID);

export const selectJobRoleId: any = createAction(LayoutActionNames.SELECT_JOBROLE_ID);

export const setCurrentContentId: any = createAction(LayoutActionNames.SET_CURRENT_CONTENT_ID);

export const resetCurrentContentId: any = createAction(LayoutActionNames.RESET_CURRENT_CONTENT_ID);

export const pushMegaMobileNavHistory: any = createAction(LayoutActionNames.PUSH_MEGA_MOBILE_NAV_HISTORY);

export const backMegaMobileNavHistory: any = createAction(LayoutActionNames.BACK_MEGA_MOBILE_NAV_HISTORY);

export const clearMegaMobileNavHistory: any = createAction(LayoutActionNames.CLEAR_MEGA_MOBILE_NAV_HISTORY);

export const toggleModalTopicUnfollow: any = createAction(
  LayoutActionNames.TOGGLE_MODAL_TOPIC_UNFOLLOW,
  (nextState: State.ModalTopicUnfollowMeta) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { pathname }: any = getLocation(state as any);
    const route: string =
      nextState && nextState.isLastItem ? `${pathname}${getPath(Routes.UNFOLLOW_UNSUCCESSFUL_HASH)}` : pathname;
    dispatch(push(route));
    return nextState;
  }
);

export const goToPreviousRoute: any = createAction(
  LayoutActionNames.GO_TO_PREVIOUS_ROUTE,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { state: locationState } = getLocation(state as any) as any;
    const redirectUri: string = locationState?.prevRoute || getPath(Routes.FEED);
    dispatch(push(redirectUri));
    return;
  }
);

export const toggleModalPaymentProcessingOpen: any = createAction(LayoutActionNames.TOGGLE_MODAL_PAYMENT_PROCESSING);
export const toggleModalResetImpersonatedUserPassword: any = createAction(
  LayoutActionNames.TOGGLE_MODAL_RESET_IMPERSONATED_USER_PASSWORD
);
export const toggleModalProvisionProcessingOpen: any = createAction(LayoutActionNames.TOGGLE_PROVISION_PROCESSING);
export const toggleModalOnboardingFinishedOpen: any = createAction(LayoutActionNames.TOGGLE_ONBOARDING_FINISHED);
export const toggleModalOnboardingStartVisible: any = createAction(LayoutActionNames.TOGGLE_ONBOARDING_START);
export const toggleModalOnboardingCancelOpen: any = createAction(LayoutActionNames.TOGGLE_ONBOARDING_CANCEL);
export const toggleModalAddressValidationOpen: any = createAction(LayoutActionNames.TOGGLE_ADDRESS_VALIDATION_MODAL);
export const setIsCartContainsInvalidBundles: any = createAction(
  LayoutActionNames.SET_IS_CART_CONTAINS_INVALID_BUNDLES
);
export const toggleModalExtendAccessOpen: any = createAction(LayoutActionNames.TOGGLE_EXTEND_ACCESS_MODAL);
export const toggleModalInvalidAddressOpen: any = createAction(LayoutActionNames.TOGGLE_INVALID_ADDRESS_MODAL);

export const showRegistration: any = createAction(
  LayoutActionNames.SHOW_REGISTRATION,
  ({
      redirectToRegistrationPage,
      trigger = 'header',
      email,
    }: {
      redirectToRegistrationPage?: boolean;
      trigger: string;
      email?: string;
    }) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const location: any = getLocation(state as any);
      const prevRoute: string = getCurrentLocation(location);
      if (redirectToRegistrationPage) {
        dispatch(
          push({
            pathname: getPath(Routes.REGISTRATION),
            state: {
              email,
              prevRoute: location?.state?.prevRoute || prevRoute,
              redirectToPrevRoute: location?.state?.redirectToPrevRoute,
            },
          })
        );
      } else {
        dispatch(
          push({
            pathname: location.pathname,
            hash: getPath(Routes.REGISTRATION_HASH),
            state: { prevRoute: location.pathname, email },
          })
        );
      }
      return Promise.resolve({ trigger, isModal: !redirectToRegistrationPage });
    }
);

export const showLogin: any = createAction(
  LayoutActionNames.SHOW_LOGIN,
  ({
      redirectToLoginPage,
      email,
      trigger = 'header',
    }: {
      redirectToLoginPage?: boolean;
      email?: string;
      trigger: string;
    }) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state: any = getState();
      const location: any = getLocation(state);
      const prevRoute: string = getCurrentLocation(location);
      if (redirectToLoginPage) {
        dispatch(
          push({
            pathname: getPath(Routes.LOGIN),
            state: {
              email,
              prevRoute: location?.state?.prevRoute || prevRoute,
              redirectToPrevRoute: location?.state?.redirectToPrevRoute,
            },
          })
        );
      } else {
        dispatch(
          push({
            pathname: location.pathname,
            hash: getPath(Routes.LOGIN_HASH),
            state: { prevRoute: location.pathname, email },
          })
        );
      }
      return Promise.resolve({ trigger, isModal: !redirectToLoginPage });
    }
);

export const toggleLoginReload: any = createAction(
  LayoutActionNames.TOGGLE_LOGIN_RELOAD,
  (shouldLoginReload?: boolean) => () => {
    return shouldLoginReload;
  }
);

export const closeAuthenticationModal: any = createAction(
  LayoutActionNames.CLOSE_AUTHENTICATION_MODAL,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { pathname }: any = getLocation(state as any);

    dispatch(toggleLoginReload());
    dispatch(push(pathname));

    removeSessionStorageItem(StorageNames.actionsToDispatchAfterLogin);
  }
);

export const paginationPageChange: any = createAction('PAGINATION_PAGE_CHANGE');
export const paginationSortChange: any = createAction('PAGINATION_SORT_CHANGE');
export const paginationFilterActiveChange: any = createAction('PAGINATION_FILTER_ACTIVE_CHANGE');
export const setPaginationInfo: any = createAction('PAGINATION_SET_INFO');
export const setPaginationTotal: any = createAction('PAGINATION_SET_TOTAL');

export const toggleCardSetDefaultModal: any = createAction(LayoutActionNames.TOGGLE_CARD_SET_DEFAULT_MODAL);
export const toggleCardRemoveModal: any = createAction(LayoutActionNames.TOGGLE_CARD_REMOVE_MODAL);
export const toggleCimaDeleteDefaultPaymentModal: any = createAction(
  LayoutActionNames.TOGGLE_DELETE_DEFAULT_PAYMENT_MODAL
);
export const toggleCimaDeleteOnlyPaymentMethodModal: any = createAction(
  LayoutActionNames.TOGGLE_DELETE_ONLY_PAYMENT_METHOD_MODAL
);
export const setIsDeleteCardBeingProcessed: any = createAction(LayoutActionNames.TOGGLE_DELETE_CARD_BEING_PROCESSED);
export const CimaDeleteOnlyPaymentMethodModalButtonLoading: any = createAction(
  LayoutActionNames.TOGGLE_DELETE_ONLY_PAYMENT_METHOD_MODAL_BUTTON_LOADING
);
export const toggleAddCreditCardModal: any = createAction(LayoutActionNames.TOGGLE_ADD_CREDIT_CARD_MODAL);
export const setCardBeingProcessed: any = createAction(LayoutActionNames.SET_CARD_BEING_PROCESSED);
export const setCardProcessed: any = createAction(LayoutActionNames.SET_CARD_PROCESSED);
export const toggleSubscriptionRenewalToggleModal: any = createAction(
  LayoutActionNames.TOGGLE_SUBSCRIPTION_RENEWAL_TOGGLE_MODAL
);
export const setSubscriptionRenewalToggleSuccess: any = createAction(
  LayoutActionNames.SET_SUBSCRIPTION_RENEWAL_TOGGLE_SUCCESS
);

export const setSubscriptionRenewalToggleLoading: any = createAction(
  LayoutActionNames.SET_SUBSCRIPTION_RENEWAL_TOGGLE_LOADING
);

export const closeCardOperationBanner: any = createAction(LayoutActionNames.CLOSE_CARD_OPERATION_BANNER);
export const setCardOperationBannerVisible: any = createAction(LayoutActionNames.SHOW_CARD_OPERATION_BANNER);
export const setCardOperation: any = createAction(LayoutActionNames.SET_CARD_OPERATION);
export const setOrgProfileExpanded: any = createAction(LayoutActionNames.SET_ORG_PROFILE_EXPAND);
export const toggleSubscriptionRenewal: any = createAction(
  LayoutActionNames.TOGGLE_SUBSCRIPTION_RENEWAL,
  ({ enable, item }: { enable?: boolean; item?: Common.ProductItemData }) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state: any = getState();
      const isUserHasDefaultCreditCard: boolean = isUserHasDefaultCreditCardSelector(state);
      return enable && item ? { enable, item, hasDefaultCard: isUserHasDefaultCreditCard } : null;
    }
);

export const setCancelSOrderModalMeta: any = createAction(LayoutActionNames.TOGGLE_CANCEL_STANDING_ORDER_MODAL);

export const setRecentlyAddedBundleDiscountPercentage: any = createAction(
  LayoutActionNames.SET_RECENTLY_ADDED_BUNDLE_DISCOUNT
);

export const setCartBundleMergeBannerMessage: any = createAction(
  LayoutActionNames.SET_CART_BUNDLE_MERGE_BANNER_MESSAGE
);

export const clearCartSingleTimeBanners: any = createAction(
  'layout/CLEAR_CART_BUNDLE_BANNERS',
  () => (dispatch: Dispatch) => {
    dispatch(setCartBundleMergeBannerMessage(''));
    dispatch(setRecentlyAddedBundleDiscountPercentage(null));
    dispatch(setIsCartContainsInvalidBundles(false));
    dispatch(setCartMigrationMessages({}));
  }
);

export const setCartMigrationMessages: any = createAction(LayoutActionNames.SET_CART_MIGRATION_MESSAGES);

export const showPersonalDetails: any = createAction(
  LayoutActionNames.SHOW_PERSONAL_DETAILS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.MY_PERSONAL_DETAILS),
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);

export const showContactDetails: any = createAction(
  LayoutActionNames.SHOW_CONTACT_DETAILS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.MY_CONTACT_DETAILS),
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);

export const showEmploymentDetails: any = createAction(
  LayoutActionNames.SHOW_EMPLOYMENT_DETAILS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.MY_EMPLOYMENT_DETAILS),
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);

export const showTuitionProvider: any = createAction(
  LayoutActionNames.SHOW_TUITION_PROVIDER,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.MY_TUITION_PROVIDER),
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);

export const showLoginSettings: any = createAction(
  'user/SHOW_LOGIN_SETTINGS',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.PROFILE_LOGIN_SETTINGS),
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);

export const showMyConsents: any = createAction(
  LayoutActionNames.SHOW_MY_CONSENTS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.MY_CONSENTS),
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);

export const showQuestion: any = createAction(
  'user/SHOW_QUESTION',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.PROFILE_SECURITY_QUESTION),
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);

export const showMarketingPreferences: any = createAction(
  LayoutActionNames.SHOW_MARKETING_PREFERENCES,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.MY_MARKETING_PREFERENCES),
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);

export const showTopicPreferences: any = createAction(
  LayoutActionNames.SHOW_TOPIC_PREFERENCES,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.MY_TOPIC_PREFERENCES),
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);

export const showPaymentMethods: any = createAction(
  LayoutActionNames.SHOW_MY_PAYMENT_METHODS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.MY_PAYMENT_METHODS), // change to Routes.MY_PAYMENT_METHODS
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);

export const showTransactions: any = createAction(
  LayoutActionNames.SHOW_MY_RECEIPTS,
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: any = getState();
    const location: any = getLocation(state);
    const prevRoute: string = getCurrentLocation(location);

    dispatch(
      push({
        pathname: getPath(Routes.MY_RECEIPTS), // change to Routes.MY_RECEIPTS
        state: { prevRoute: location?.state?.prevRoute || prevRoute },
      })
    );

    return Promise.resolve({ success: 'true' });
  }
);
