import React from 'react';
import styled from 'styled-components';
import { Grid, Container, Divider } from 'semantic-ui-react';
import { OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms/ResponsiveHelpers/ResponsiveHelpers';
import { Heading } from 'components/atoms/Heading/Heading';
import BenefitsIconPng from 'resources/pics/icon-homepage-memberblock-benefits.png';
import BenefitsIconWebp from 'resources/pics/icon-homepage-memberblock-benefits.webp';

interface Props {
  body: string;
  title: string;
}

export const BenefitsBanner: React.FC<Props> = ({ children, title, body }) => {
  return (
    <Rectangle>
      <Container>
        <OnlyDesktopCSS>
          <StyledGrid columns={16}>
            <StyledGridColumn computer={3}>
              <picture>
                <source type="image/webp" srcSet={BenefitsIconWebp} />
                <source type="image/jpeg" srcSet={BenefitsIconPng} />
                <StyledBenefitIcon src={BenefitsIconPng} alt="" />
              </picture>
            </StyledGridColumn>
            <Grid.Column computer={13}>
              <Title as="h1">{title}</Title>
              <Text>{body}</Text>
            </Grid.Column>
          </StyledGrid>
          <StyledDivider />
          <StyledContent>{children}</StyledContent>
        </OnlyDesktopCSS>
      </Container>

      <OnlyMobileCSS>
        <StyledGrid>
          <Title as="h1">{title}</Title>
          <Text>{body}</Text>
          <StyledContent>{children}</StyledContent>
        </StyledGrid>
      </OnlyMobileCSS>
    </Rectangle>
  );
};

const Rectangle = styled.div`
  &&& {
    position: relative;
    top: ${props => props.theme.pxToRem(-100)};
    width: 50%;
    height: ${props => props.theme.pxToRem(210)};
    box-shadow: inset 0 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.colors.neutralGrey3};
    background-color: ${props => props.theme.colors.neutralGrey1};
    margin: auto;
    border-radius: ${props => props.theme.pxToRem(10)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 80%;
      padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(23)};
    }
  }
`;

const StyledGrid = styled(Grid)`
  &&& {
    margin: 0;
  }
`;

const Title = styled(Heading)`
  margin: 0 ${props => props.theme.pxToRem(96)} ${props => props.theme.pxToRem(8)} 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: x-large;
  font-weight: ${props => props.theme.fontWeights.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: medium;
  }
`;

const Text = styled.p`
  height: ${props => props.theme.pxToRem(30)};
  margin: ${props => props.theme.pxToRem(8)} 0 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: large;
  font-weight: ${props => props.theme.fontWeights.light};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: small;
  }
`;

const StyledContent = styled.div`
  text-align: center;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: auto;
    padding-top: 20px;
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  text-align: center;
`;

const StyledBenefitIcon = styled.img`
  margin: ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(23)} ${props => props.theme.pxToRem(16)} 0;
  object-fit: contain;
`;

const StyledDivider = styled(Divider)`
  &&& {
    margin: 0;
  }
`;
