import { getLoginAccessors, LoginOrReg } from 'utils/Analytics/helpers';
import { EventDescribor } from 'utils/Analytics/types';
import { LayoutActionNames } from 'modules/layouts/constants';

export const LayoutAnalyticsConsts: {
  [key: string]: EventDescribor;
} = {
  [LayoutActionNames.SHOW_REGISTRATION]: {
    name: 'registration',
    accessors: [...getLoginAccessors(LoginOrReg.Reg)],
  },
  [LayoutActionNames.SHOW_LOGIN]: {
    name: 'login',
    accessors: [...getLoginAccessors(LoginOrReg.Login)],
  },
};
