import React, { useState } from 'react';
import { GlobalModalDimmerStyle, Modal, Button, ButtonEnums } from 'components/atoms';
import styled from 'styled-components/macro';
import { Loader } from 'components/atoms/Loader/Loader';
import { InputWithValidation } from 'components/molecules/InputWithValidation/InputWithValidation';
import { useDispatch } from 'react-redux';
import { searchUser } from 'modules/admin/actions';
import { User } from 'mxp-schemas';

interface Props {
  visible: boolean;
  loading: boolean;
  closeSwapAdminModal: () => void;
  adminToRemove: State.OrganizationAdmin | null;
  swapAdmins: (adminToSwapEmail: string) => void;
  removeAdmin: () => void;
  hasRelatedEntities: boolean;
  isCheckForRelatedEntitiesLoading: boolean;
}

interface AlternativeAdmin {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export const RemoveClientAdminsModal: React.FC<Props> = ({
  visible,
  loading,
  closeSwapAdminModal,
  adminToRemove,
  swapAdmins,
  hasRelatedEntities,
  isCheckForRelatedEntitiesLoading,
  removeAdmin,
}) => {
  const dispatch = useDispatch();
  const errorInvalidEmailMessage =
    'This email address does not match our records. Please check the spelling, or create a new administrator.';
  const [alternativeAdminEmail, setAlternativeAdminEmail] = useState('');
  const [selectedAlternativeAdmin, setSelectedAlternativeAdmin] = useState<AlternativeAdmin | null>(null);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  React.useEffect(() => {
    if (!loading) {
      setAlternativeAdminEmail('');
      setSelectedAlternativeAdmin(null);
      setSearchErrorMessage('');
    }
  }, [loading]);

  const handleAlternativeAdminEmailChange = React.useCallback(({ target: { value } }: any) => {
    setAlternativeAdminEmail(value);
  }, []);

  const handleSearchAlternativeAdmin = React.useCallback(() => {
    setIsLoadingSearch(true);
    dispatch(searchUser('', alternativeAdminEmail)).then(
      async (action: { type: string; payload: User.UserDetails[] }) => {
        if (action.payload.length === 0) {
          setSelectedAlternativeAdmin(null);
          setSearchErrorMessage(errorInvalidEmailMessage);
          setIsLoadingSearch(false);
          return;
        }
        const { email, firstName, lastName, mobilePhone } = action.payload[0].profile;
        setSelectedAlternativeAdmin({
          email,
          firstName,
          lastName,
          phone: mobilePhone,
        });
        setSearchErrorMessage('');
        setIsLoadingSearch(false);
      }
    );
  }, [alternativeAdminEmail, dispatch]);

  const handleConfirm = React.useCallback(async () => {
    if (selectedAlternativeAdmin) {
      swapAdmins(selectedAlternativeAdmin.email);
    } else {
      removeAdmin();
    }
  }, [selectedAlternativeAdmin, swapAdmins, removeAdmin]);

  const handleCancel = React.useCallback(() => {
    setAlternativeAdminEmail('');
    setSelectedAlternativeAdmin(null);
    setSearchErrorMessage('');
    closeSwapAdminModal();
  }, [closeSwapAdminModal]);

  const adminName = `${adminToRemove?.firstName} ${adminToRemove?.lastName}`;

  const isSaveBtnDisabled =
    isCheckForRelatedEntitiesLoading || (hasRelatedEntities && !selectedAlternativeAdmin?.email);

  const cancelNode = (
    <ButtonStyled
      size={ButtonEnums.sizes.medium}
      testId="send-invitations"
      variant={ButtonEnums.variants.primary}
      onClick={handleConfirm}
      loading={loading}
      disabled={isSaveBtnDisabled}
    >
      Save changes
    </ButtonStyled>
  );

  const confirmNode = (
    <ButtonStyled
      size={ButtonEnums.sizes.medium}
      testId="send-cancel"
      variant={ButtonEnums.variants.secondary}
      onClick={handleCancel}
    >
      Cancel
    </ButtonStyled>
  );

  return (
    <>
      <GlobalModalDimmerStyle />
      <StyledModal
        closeOnDimmerClick={false}
        open={visible}
        centered
        heading={<>Do you want to remove this administrator?</>}
        confirmNode={confirmNode}
        cancelNode={cancelNode}
        hasRelatedEntities={hasRelatedEntities}
      >
        <AdminName>{adminName}</AdminName>
        {isCheckForRelatedEntitiesLoading ? (
          <Loader inline="centered" />
        ) : hasRelatedEntities ? (
          <>
            <ModalContent>
              <>
                <ModalContentPartOne>
                  The administrator you wish to remove is currently assigned to order(s).
                </ModalContentPartOne>
                <ModalContentPartTwo>
                  To proceed, please select an alternative administrator, and reassign to those orders.
                </ModalContentPartTwo>
              </>
            </ModalContent>
            <>
              <SearchFormTitle>Alternative admin email</SearchFormTitle>
              <SearchFormContainer>
                <SearchFormInputStyled
                  name="email"
                  type="email"
                  value={alternativeAdminEmail}
                  testId="search-form-input-email"
                  labelId="email"
                  onChange={handleAlternativeAdminEmailChange}
                  placeholder="e.g. sam.thomas@gmail.com"
                  isCorrect={Boolean(!searchErrorMessage)}
                  isCorrectIconShown
                />
                <SearchFormButtonStyled
                  size={ButtonEnums.sizes.medium}
                  testId="search-form-button"
                  variant={ButtonEnums.variants.primary}
                  onClick={handleSearchAlternativeAdmin}
                  disabled={!alternativeAdminEmail}
                  loading={isLoadingSearch}
                >
                  Search
                </SearchFormButtonStyled>
              </SearchFormContainer>
              {searchErrorMessage && <SearchErrorMessage>{searchErrorMessage}</SearchErrorMessage>}
              {selectedAlternativeAdmin && (
                <>
                  <SearchResultContainer>
                    <SearchResultItemTextStyled>Name: {selectedAlternativeAdmin?.firstName}</SearchResultItemTextStyled>
                    <SearchResultItemTextStyled>
                      Surname: {selectedAlternativeAdmin?.lastName}
                    </SearchResultItemTextStyled>
                    <SearchResultItemTextStyled>Phone: {selectedAlternativeAdmin?.phone}</SearchResultItemTextStyled>
                  </SearchResultContainer>
                </>
              )}
            </>
          </>
        ) : (
          <>
            <ModalContent>
              <>
                <ModalContentPartOne>
                  By continuing, this person will no longer have administrative access and will no longer be listed as
                  an administrator on the account record.
                </ModalContentPartOne>
              </>
            </ModalContent>
          </>
        )}
      </StyledModal>
    </>
  );
};

const ButtonStyled = styled(Button)`
  &&&&&& {
    min-width: ${props => props.theme.pxToRem(190)};
  }
`;

const SearchFormTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  text-align: left;
  margin-bottom: 12px;
  margin-top: 30px;
`;

const SearchFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${props => props.theme.pxToRem(4)};
`;

const SearchFormInputStyled = styled(InputWithValidation)`
  &&&&&& {
    margin-top: 0;
    min-width: ${props => props.theme.pxToRem(300)};
  }
`;

const SearchFormButtonStyled = styled(Button)`
  &&&&&& {
    min-height: ${props => props.theme.pxToRem(43)};
    min-width: ${props => props.theme.pxToRem(110)};
  }
`;

const SearchResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${props => props.theme.pxToRem(10)};
  padding: ${props => props.theme.pxToRem(12)};
  background-color: ${props => props.theme.colors.neutralGrey2};
`;

const SearchResultItemTextStyled = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const SearchErrorMessage = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.interfaceRed};
  margin-top: ${props => props.theme.pxToRem(5)};
  text-align: left;
`;

const ModalContentPartOne = styled.div`
  margin-bottom: 12px;
`;

const ModalContentPartTwo = styled.div`
  margin-bottom: 12px;
  width: 368px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const AdminName = styled.div`
  margin-top: 20px;
  margin-bottom: 28px;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledModal = styled(Modal)<{ hasRelatedEntities: boolean }>`
  max-width: ${props => props.theme.pxToRem(605)};
  &&&&&&& {
    > .content {
      max-width: ${props =>
        props.hasRelatedEntities ? `${props.theme.pxToRem(430)};` : `${props.theme.pxToRem(360)};`}
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      ${props => !props.hasRelatedEntities && `padding-bottom: ${props.theme.pxToRem(32)} !important`}
    }
    > .header {
    }
    
    > .actions {
      padding-top: 10px !important;
      flex-direction: row-reverse;  //mobile desk button order is reversed
      
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
      
      > button { 
        margin: 10px;
        width: 210px;
      }
    }
  }
`;

const ModalContent = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin-right: ${props => props.theme.pxToRem(4)};
`;
