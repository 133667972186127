import { InputWithValidation } from 'components/molecules/InputWithValidation/InputWithValidation';
import styled, { css } from 'styled-components';
import { Dropdown, Button } from 'components/atoms';

export const StyledTierContainer = styled.div`
  color: ${props => props.theme.colors.neutralBlack};
`;

export const StyledBenefitHeader = styled.p`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 1.33;
  margin: ${props => props.theme.pxToRem(26)} 0;
`;

export const StyledTierBenefitContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${props => props.theme.pxToRem(15)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
  }
`;

export const StyledTierBenefitItem = styled.div`
  flex: 0 0 48%;
  margin: ${props => props.theme.pxToRem(10)} 0;
`;

export const StyledButtonContainer = styled.div`
  margin: ${props => props.theme.pxToRem(20)} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.pxToRem(15)};
`;

export const StyledTierPrice = styled.span`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
  color: ${props => props.theme.colors.neutralGrey8};
`;

export const MainDiv = styled.div`
  margin-top: ${props => props.theme.pxToRem(-60)};
  padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(20)};
  width: 100%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(280)};
    margin-top: 0;
    justify-content: center;
    text-align: center;
  }
`;

export const StyledButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(300)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      font-size: ${props => props.theme.fontSizes.xs};
    }
  }
`;

export const StyledCAQEditButton = styled(Button)`
  &&&&& {
    width: ${props => props.theme.pxToRem(170)};
    height: ${props => props.theme.pxToRem(30)};
    margin-top: ${props => props.theme.pxToRem(43)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
      font-size: ${props => props.theme.fontSizes.xxs};
    }
  }
`;

export const StyledBenefitsContainer = styled.div`
  box-sizing: border-box;
  background: ${props => props.theme.colors.neutralWhite};
  padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(35)};
`;

export const StyledInputContainer = styled.div<{ isCAQ: boolean }>`
  display: flex;
  gap: ${props => props.theme.pxToRem(15)};
  padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(15)};
  background: ${props => props.theme.colors.neutralWhite};
  margin: ${props => props.theme.pxToRem(12)} 0;

  ${({ isCAQ }) =>
    !isCAQ &&
    css`
      justify-content: center;
    `}

  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
    text-align: center;
  }
`;

export const StyledCpaContainer = styled.div`
  width: ${props => props.theme.pxToRem(260)};
`;

export const StyledPCAOBContainer = styled.div`
  width: ${props => props.theme.pxToRem(260)};
`;

export const StyledLabel = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => props.theme.colors.neutralGrey8};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
  margin-bottom: ${props => props.theme.pxToRem(20)};
  width: ${props => props.theme.pxToRem(260)};
`;

export const StyledFormInputDesc = styled.span`
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.neutralGrey6};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.33;
`;

export const StyledInputWithValidation = styled(InputWithValidation)`
  &&& {
    display: flex;
  }
  &&&.disabled {
    cursor: not-allowed;
    opacity: 1;

    input {
      border-color: ${props => props.theme.colors.neutralGrey4};
      background-color: ${props => props.theme.colors.neutralGrey2};
    }
  }
`;

export const StyledHelpUs = styled.div`
  font-size: ${props => props.theme.fontSizes.l};
  text-align: center;
  color: ${props => props.theme.colors.neutralGrey6};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.33;
  margin-top: ${props => props.theme.pxToRem(125)};
`;

export const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      height: ${props.theme.pxToRem(43.5)};
      width: ${props.theme.pxToRem(260)};
      border-radius: 5px 0 0 5px;
      padding-right: ${props.theme.pxToRem(10)};
      color: ${props.theme.colors.neutralBlack};
      border: solid ${props.theme.pxToRem(1)} ${props.theme.colors.neutralGrey4};
      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(5)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &&&.disabled {
        opacity: 1;
        cursor: not-allowed;
        background-color: ${props.theme.colors.neutralGrey2};
        border-color: ${props.theme.colors.neutralGrey4};
        input {
          border-color: ${props.theme.colors.neutralGrey4};
          background-color: ${props.theme.colors.neutralGrey2};
        }
      }
      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }
      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.neutralBlack};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }
      &&&.ui.dropdown > .menu {
        max-height: ${props.theme.pxToRem(250)};
        width: auto !important;
      }
      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: 1px solid ${props.theme.colors.neutralGrey1};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: 1px solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.neutralBlack};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }
      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: 1px solid blue;
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
    }
  `}
`;

export const StyledApplyNowValidationText = styled.p`
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.interfaceRed};
`;
