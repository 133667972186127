import React, { useState } from 'react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { Dropdown as SemanticUIDropdown, StrictDropdownProps } from 'semantic-ui-react';
import { ReactComponent as ExpandMore } from 'resources/images/ic-expand-more.svg';
import { ReactComponent as ExpandLess } from 'resources/images/ic-expand-less.svg';
import { ReactComponent as CloseIcon } from 'resources/images/ic-close.svg';

export interface DropdownProps extends StrictDropdownProps {
  testId?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  clearable?: boolean;
  scrolling?: boolean;
  closeOnBlur?: boolean;
  isActive?: boolean;
  defaultValue?: any;
  icon?: any;
  hideIcon?: boolean;
  disabled?: boolean;
  onOpen?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  onClose?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  errorMessage?: string;
  activeRow?: number;
  currentRow?: number;
  isRowIncomplete?: boolean;
  onFocusDropdown?: (currentRow: number) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  testId,
  name = '',
  className = '',
  placeholder = '',
  options,
  trigger,
  value,
  children,
  defaultOpen = false,
  search = false,
  clearable = false,
  scrolling = false,
  closeOnBlur = true,
  open = null, // External value to control whether or not the dropdown menu is displayed.
  defaultValue,
  icon,
  onOpen,
  onClose,
  onChange,
  onFocus,
  onClick,
  errorMessage,
  disabled = false,
  ...props
}) => {
  const hasExternalDisplayControl: boolean = open !== null;

  // Internal value to control whether or not the dropdown menu is displayed.
  const [isOpen, setIsOpen] = useState(defaultOpen);

  React.useEffect(() => {
    // External value has higher priority if passed
    if (hasExternalDisplayControl && open !== isOpen) setIsOpen(open as boolean);
  }, [open, isOpen, hasExternalDisplayControl]);

  const handleOnOpen = React.useCallback(
    (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
      if (!hasExternalDisplayControl) setIsOpen(true);
      onOpen?.(event, data);
    },
    [hasExternalDisplayControl, onOpen]
  );

  const handleOnClose = React.useCallback(
    (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
      if (!hasExternalDisplayControl) setIsOpen(false);
      if (onClose) onClose(event, data);
    },
    [hasExternalDisplayControl, onClose]
  );

  const isMenuOpen: boolean = hasExternalDisplayControl ? (open as boolean) : isOpen;
  return (
    <StyledSemanticUIDropdown
      data-testid={testId}
      search={search}
      className={className}
      name={name}
      options={options}
      open={isMenuOpen}
      value={value}
      icon={icon || isMenuOpen ? <ExpandLess /> : value && clearable ? <CloseIcon /> : <ExpandMore />}
      placeholder={placeholder}
      clearable={clearable}
      scrolling={scrolling}
      closeOnBlur={closeOnBlur}
      defaultValue={defaultValue}
      trigger={trigger}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      onChange={onChange}
      onFocus={onFocus}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </StyledSemanticUIDropdown>
  );
};

const StyledSemanticUIDropdown = styled(SemanticUIDropdown)<ThemedStyledProps<DropdownProps, DefaultTheme>>`
  &&& {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: ${props => props.theme.colors.primaryPurple};
    border: solid 1px ${props => props.theme.colors.neutralGrey3};
    border-radius: 2px;
    box-shadow: none;
    font-size: ${props => props.theme.fontSizes.m};

    ${props => props.theme.mediaQueries.mobileOnly} {
      height: 40px;
    }
  }

  &.dropdown:hover {
    border-color: ${props => props.theme.colors.primaryPurple};
  }
  &.dropdown.active {
    border-color: ${props => props.theme.colors.primaryPurple};
    border-radius: 2px 2px 0 0;
  }

  &.ui.dropdown .menu > .item:hover {
    background: none;
    font-weight: ${props => props.theme.fontWeights.bold};
  }

  &.ui.dropdown .menu {
    box-shadow: none;
    border-radius: 0 0 2px 2px;
    right: 0;
    border: solid 1px ${props => props.theme.colors.primaryPurple};
    border-top: none;
    margin-left: -1px;
    margin-right: -1px;

    ${props => props.theme.mediaQueries.mobileOnly} {
      min-width: auto;
    }
  }
  &.ui.dropdown .menu .item {
    padding: 0.6rem 1rem !important;
    border-top: none;

    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.fontSizes.xs};
    }
  }

  &.ui.dropdown .menu .item:first-of-type {
    padding-top: 1.2rem !important;
  }

  &.ui.dropdown .menu .item:last-of-type {
    padding-bottom: 1.2rem !important;
  }

  &.visible .menu:before {
    content: '';
    display: block;
    margin: 0 1rem;
    border-top: 1px solid ${props => props.theme.colors.neutralGrey3};
  }

  &.visible.dropdown .menu .selected.item {
    background: none;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    &.visible.dropdown .menu > * {
      white-space: initial;
    }
  }

  &.dropdown i {
    margin-right: 0.5625rem;
    font-size: ${props => props.theme.fontSizes.s};
  }
`;
