import React from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { RichTextBlocks } from 'mxp-utils';
import { Link, Heading, Divider, ButtonEnums, ButtonLink } from 'components/atoms';
import { ReactComponent as ArrowForward } from 'resources/images/arrow-forward.svg';
import { IC_OPEN_IN_NEW_PURPLE } from 'resources/images';
import { DefaultInlineLinkStyles, ExternalLinkStyles } from 'components/atoms/Link/Link';
interface Props {
  testId: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  colour?: string;
  to?: string;
  ctaText?: string;
}

export const SubBlockWelcome: React.FC<Props> = ({
  testId,
  title = '',
  description = '',
  imageUrl = '',
  colour = '',
  to = '',
  ctaText = '',
}) => {
  const isExternal = RichTextBlocks.isExternal(to);
  return (
    <Wrapper data-testid={testId} colour={colour}>
      <StyledLink to={to} isExternal={isExternal} isBlockLink>
        {imageUrl && <StyledImage src={imageUrl} alt="icon" />}
        {title && <StyledHeading as="h2" dangerouslySetInnerHTML={{ __html: title }} />}
      </StyledLink>
      {description && (
        <MarkupWrapper>
          <StyledParagraph tabIndex={0} dangerouslySetInnerHTML={{ __html: description }} />
        </MarkupWrapper>
      )}
      <StyledDivider />
      {ctaText && (
        <ButtonLink
          testId={`btn-${testId}`}
          variant={ButtonEnums.variants.standAloneLink}
          size={ButtonEnums.sizes.small}
          icon={<ArrowForward />}
          iconPosition={ButtonEnums.iconPosition.right}
          to={to}
        >
          {ctaText}
        </ButtonLink>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  position: relative;
  margin: 0 auto;
  max-width: ${props => props.theme.pxToRem(365)};
  max-height: ${props => props.theme.pxToRem(325)};
  padding: ${props => `${props.theme.pxToRem(30)} ${props.theme.pxToRem(24)} ${props.theme.pxToRem(20)}`};
  border-bottom-width: ${props => props.theme.pxToRem(4)};
  border-bottom-style: solid;
  ${props => props.colour && `border-bottom-color: ${props.colour}`};
  border-radius: ${props => props.theme.pxToRem(10)};
  background-color: ${props => props.theme.colors.neutralWhite};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)} 0 rgba(0, 0, 0, 0.1);

  ${props => props.theme.mediaQueries.mobileOnly} {
    max-width: ${props => props.theme.pxToRem(335)};
    height: ${props => props.theme.pxToRem(180)};
    padding: ${props => `${props.theme.pxToRem(19)} ${props.theme.pxToRem(19)} ${props.theme.pxToRem(10)}`};
    border-bottom-width: ${props => props.theme.pxToRem(5)};
  }

  .rich-text-external-link {
    ${ExternalLinkStyles}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${IC_OPEN_IN_NEW_PURPLE});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }

  .rich-text-internal-link {
    ${DefaultInlineLinkStyles};
  }
`;

const StyledLink = styled(Link)`
  position: static;
  display: block;
  margin-bottom: ${props => props.theme.pxToRem(24)};
  text-decoration: none;

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(6)};
  }
`;

const MarkupWrapper = styled.div`
  height: ${props => props.theme.pxToRem(70)};
  overflow: hidden;

  ${props => props.theme.mediaQueries.mobileOnly} {
    height: ${props => props.theme.pxToRem(60)};
  }
`;

const StyledImage = styled.img`
  display: inline-block;
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  margin-right: ${props => props.theme.pxToRem(10)};
  vertical-align: middle;
`;

const StyledHeading = styled(Heading)`
  display: inline-block;
  overflow: hidden;
  margin: 0;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.medium};
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  p {
    line-height: 1.33;
  }
  a {
    ${DefaultInlineLinkStyles};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};

    p {
      line-height: 1.56;
    }
  }
`;

const StyledParagraph = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
  }
  p {
    margin: 0;
    line-height: 1.5;
  }
  a {
    ${DefaultInlineLinkStyles};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    p {
      line-height: 1.43;
    }
  }
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    border-top: 0;
    border-bottom-color: ${props => props.theme.colors.neutralGrey3};
    margin: ${props => `${props.theme.pxToRem(16)} ${props.theme.pxToRem(-24)} ${props.theme.pxToRem(16)}`};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: ${props => `${props.theme.pxToRem(14)} ${props.theme.pxToRem(-20)} ${props.theme.pxToRem(16)}`};
    }
  }
`;
