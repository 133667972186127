import * as Sentry from '@sentry/browser';
import { handleActions } from 'redux-actions';
import { setError, setLoading, selectBenefit, unselectBenefit } from './actions';
import { initialState } from './constants';

if ([setLoading, setError].includes(undefined)) {
  const message: string = 'contractLineItem module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [setError]: (
    state: State.ContractLineItemList,
    action: { payload: CustomErrors.GraphQLError }
  ): State.ContractLineItemList => ({
    ...state,
    error: action.payload,
  }),
  [setLoading]: (state: State.ContractLineItemList, action: { payload: boolean }): State.ContractLineItemList => ({
    ...state,
    loading: true,
  }),
  [selectBenefit]: {
    next: (state: State.ContractLineItemList, action: any): State.ContractLineItemList => ({
      ...state,
      loading: false,
    }),
    throw: (state: State.ContractLineItemList, action: any): State.ContractLineItemList => ({
      ...state,
      loading: false,
    }),
  },
  [unselectBenefit]: {
    next: (state: State.ContractLineItemList, action: any): State.ContractLineItemList => ({
      ...state,
      loading: false,
    }),
    throw: (state: State.ContractLineItemList, action: any): State.ContractLineItemList => ({
      ...state,
      loading: false,
    }),
  },
};

export { setLoading, setError };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
