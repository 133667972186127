import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import request from 'utils/GraphQLClient';
import { MUTATE_APPRENTICE, QUERY_APPRENTICE } from 'mxp-graphql-queries';
import { personAccountIdSelector } from 'modules/user/selectors';

// ------------------------------------
// Actions
// ------------------------------------

export const createApprenticePathway: any = createAction(
  'createApprenticePathway/CREATE_APPRENTICE',
  (apprenticePathway: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const accountId = personAccountIdSelector(state);
    return request(MUTATE_APPRENTICE, { accountId, apprenticePathway });
  }
);

export const getApprenticePathway: any = createAction(
  'getApprenticePathway/GET_APPRENTICE',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const accountId = personAccountIdSelector(state);
    return request(QUERY_APPRENTICE, { accountId }).then((response: any) => {
      const { apprenticePathway } = response.getApprenticePathway;
      const apprentice = {
        accountId,
        apprenticePathway,
      };
      return apprentice;
    });
  }
);
