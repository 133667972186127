import { createAction } from 'redux-actions';
import { USCpaExamEnum } from './constants';
import { GET_EXEMPTION_REFERENCE } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';

export const setProfessionalQualification: any = createAction(
  'exempReference/SET_PROFESSIONAL_QUALIFICATION_ID',
  (id: any) => () => {
    return {
      id,
    };
  }
);

export const setSelectedPassedUSCPAExam: any = createAction(
  'exempReference/SET_SELECTED_PASSED_US_CPA_EXAM',
  (selectedPassedUSCPAExam: USCpaExamEnum | null) => () => {
    return {
      selectedPassedUSCPAExam,
    };
  }
);

export const fetchProfessionalQualifications: any = createAction(
  'exempReference/FETCH_PROFESSIONAL_QUALIFICATION',
  (type: string) => () => {
    return request(GET_EXEMPTION_REFERENCE, { type });
  }
);
