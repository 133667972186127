import React, { Fragment, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { MembershipTypes } from 'mxp-schemas';
import { Button as AtomButton, ButtonEnums } from 'components/atoms/Button/Button';
import { Divider } from 'components/atoms/Divider/Divider';
import { ReactComponent as IconChevronDown } from 'resources/images/chevrons-down.svg';
import { ReactComponent as IconComplete } from 'resources/images/icon-brand-icon-award.svg';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { isServer } from 'utils';
import { Utils } from 'mxp-utils';

interface ExamData {
  level?: string;
  subject?: string;
  online_exam?: boolean;
  address?: string;
  warning?: string;
  completed?: boolean;
  pvBookingId?: string;
}

interface NotificationBannerData {
  title?: string;
  main?: string;
  sub?: string;
  warning?: string;
  remaining?: string;
  exam?: ExamData[];
  takeOnlineExamClick?: (appointmentId?: string) => void;
  rescheduleClick?: (appointmentId?: string) => void;
  cancelClick?: (appointmentId?: string) => void;
}
interface Props {
  data: NotificationBannerData[];
  nextStepClick?: () => void;
  showBadge?: boolean;
  isGatewayNotPassed?: boolean;
  showRescheduleAndCancel?: boolean;
}

export const CimaNotificationBanner: React.FC<Props> = ({
  data,
  nextStepClick,
  showBadge,
  isGatewayNotPassed,
  showRescheduleAndCancel,
}) => {
  const buttonText = 'Next steps';

  const handleShowNextStepClick = useCallback(() => {
    if (nextStepClick) {
      nextStepClick();
      if (!isServer) {
        const currentLocation = (window as any)?.location?.pathname;
        handleEvent({ clickValue: `button:link:int::${buttonText}:${currentLocation}` }, NAV_CLICK);
      }
    }
  }, [nextStepClick]);

  const showNext = () => (
    <StyledButton onClick={handleShowNextStepClick}>
      {buttonText} <StyledIconChevronDown />
    </StyledButton>
  );

  return (
    <StyledDiv>
      {data.map((items, i) => {
        const handleTakeOnlineExamClick = () => {
          if (items.takeOnlineExamClick && items.exam && items.exam.length) {
            items.takeOnlineExamClick(items.exam[0].pvBookingId);
          }
        };

        const handleRescheduleClick = () => {
          if (items.rescheduleClick && items.exam && items.exam.length) {
            items.rescheduleClick(items.exam[0].pvBookingId);
          }
        };

        const handleCancelClick = () => {
          if (items.cancelClick && items.exam && items.exam.length) items.cancelClick(items.exam[0].pvBookingId);
        };

        return (
          <React.Fragment key={`${items.title}-${i}`}>
            <StyledTitleRow>
              <StyledLabel>{items.title}</StyledLabel>
              {items.remaining && <StyledRemaining>{items.remaining}</StyledRemaining>}
            </StyledTitleRow>

            {showBadge && (
              <BoxBadge>
                <Badge>
                  <StyledCompleteIcon />
                </Badge>
              </BoxBadge>
            )}
            <StyledFlexColumn>
              <StyledHeading>{items.main}</StyledHeading>

              {items.sub && items.sub.length ? (
                <StyledFlexRow>
                  <StyledSubHeading>{items.sub}</StyledSubHeading>
                </StyledFlexRow>
              ) : null}
              {Boolean(items.exam && items.exam.length) && (
                <>
                  {items.exam?.map(exam => (
                    <Fragment key={exam.subject}>
                      {!exam.completed ? (
                        <>
                          {exam.online_exam !== true ? (
                            <>
                              <StyledFlexRow>
                                <StyledSubHeading>{exam.address}</StyledSubHeading>
                              </StyledFlexRow>
                            </>
                          ) : null}
                          <StyledTag>
                            {exam.level} <StyledSpan>|</StyledSpan> {exam.subject}
                            {exam.online_exam === true ? (
                              <>
                                <StyledSpan>|</StyledSpan> Online Exam
                              </>
                            ) : null}
                          </StyledTag>

                          {isGatewayNotPassed &&
                            ((exam.level === MembershipTypes.CimaQualificationLevelPathValue.StrategicLevel &&
                              Utils.hasThisWord(exam.subject || '', 'EPA')) ||
                              exam.level === MembershipTypes.CimaQualificationLevelPathValue.EPA4) && (
                              <StyledAlertDiv>
                                <StyledIconError />
                                <StyledAlertText>
                                  Contact your TP to pass your gateway before sitting the exam.
                                </StyledAlertText>
                              </StyledAlertDiv>
                            )}

                          <StyledSpacer />

                          {exam.online_exam === true ? (
                            <StyledOnline>
                              <StyledButtonWhiteLink
                                variant={ButtonEnums.variants.link}
                                testId={'take-online-exam-button'}
                                onClick={handleTakeOnlineExamClick}
                              >
                                Take Online Exam
                              </StyledButtonWhiteLink>
                              {!showRescheduleAndCancel && (
                                <>
                                  <StyledButtonWhiteLink
                                    variant={ButtonEnums.variants.link}
                                    testId={'reschedule-button'}
                                    onClick={handleRescheduleClick}
                                  >
                                    Reschedule
                                  </StyledButtonWhiteLink>

                                  <StyledButtonWhiteLink
                                    variant={ButtonEnums.variants.link}
                                    testId={'cancel-button'}
                                    onClick={handleCancelClick}
                                  >
                                    Cancel
                                  </StyledButtonWhiteLink>
                                </>
                              )}
                            </StyledOnline>
                          ) : (
                            <StyledOffline>
                              {!showRescheduleAndCancel && (
                                <>
                                  <StyledButtonWhiteLink
                                    variant={ButtonEnums.variants.link}
                                    testId={'reschedule-button'}
                                    onClick={handleRescheduleClick}
                                  >
                                    Reschedule
                                  </StyledButtonWhiteLink>

                                  <StyledButtonWhiteLink
                                    variant={ButtonEnums.variants.link}
                                    testId={'cancel-button'}
                                    onClick={handleCancelClick}
                                  >
                                    Cancel
                                  </StyledButtonWhiteLink>
                                </>
                              )}
                            </StyledOffline>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Fragment>
                  ))}
                </>
              )}
            </StyledFlexColumn>
            <Divider />
          </React.Fragment>
        );
      })}
      <StyledButtonContainer>
        {data.map((items, i) => (
          <Fragment key={`${items.title}-${i}`}>
            {items.exam && items.exam.length ? (
              <>
                {items.exam.map(exam => (
                  <Fragment key={exam.subject}>{exam.completed !== true ? <> {showNext()} </> : null}</Fragment>
                ))}
              </>
            ) : (
              <Fragment>{showNext()}</Fragment>
            )}
          </Fragment>
        ))}
      </StyledButtonContainer>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 ${props => props.theme.pxToRem(24)};
  min-height: ${props => props.theme.pxToRem(256)};
  padding: ${props => props.theme.pxToRem(32)} ${props => props.theme.pxToRem(40)};
  border-radius: ${props => props.theme.pxToRem(8)};
  background-image: linear-gradient(to left, #72246c, #3a5dae);
`;

const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTitleRow = styled.div`
  width: inherit;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
  text-transform: uppercase;
  color: ${props => props.theme.colors.neutralWhite};
`;

const StyledHeading = styled.label`
  margin: ${props => props.theme.pxToRem(10)} 0;
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.25;
  color: ${props => props.theme.colors.neutralWhite};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledSubHeading = styled.label`
  width: ${props => props.theme.pxToRem(630)};
  min-height: ${props => props.theme.pxToRem(24)};
  margin: ${props => props.theme.pxToRem(10)} 0;
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralWhite};

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledRemaining = styled.span`
  padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(6)};
  border-radius: ${props => props.theme.pxToRem(4)};
  width: auto;
  height: ${props => props.theme.pxToRem(18)};
  background-color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: ${props => props.theme.pxToRem(1.33)};
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
  text-align: center;
  color: ${props => props.theme.colors.primaryDarkPurple};
`;

const StyledButtonContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(8)};
  &&&& {
    .button:not(:first-child) {
      display: none;
    }
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    width: ${props => props.theme.pxToRem(350)};
    height: ${props => props.theme.pxToRem(40)};
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(8)}
      ${props => props.theme.pxToRem(31)};
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.medium};
    border: 1px solid ${props => props.theme.colors.neutralWhite};
    background: ${props => props.theme.colors.neutralWhite};
    color: ${props => props.theme.colors.primaryPurple};
    align-items: center;
    display: flex;
    justify-content: space-between;

    ${props => props.theme.mediaQueries.mobileOnly} {
      width: 100%;
    }
  }
`;

const StyledIconChevronDown = styled(IconChevronDown)`
  &&& {
    width: ${props => props.theme.pxToRem(20)};
  }
`;

const StyledTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${props => props.theme.pxToRem(8)};
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
`;

const StyledSpan = styled.span`
  margin-left: ${props => props.theme.pxToRem(16)};
  margin-right: ${props => props.theme.pxToRem(16)};
  color: ${props => props.theme.colors.neutralWhite};
`;

const StyledOnline = styled.div`
  width: ${props => props.theme.pxToRem(290)};
  margin-top: ${props => props.theme.pxToRem(8)};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    justify-content: flex-start;
    gap: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledOffline = styled.div`
  width: ${props => props.theme.pxToRem(150)};
  margin-top: ${props => props.theme.pxToRem(8)};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    justify-content: flex-start;
    gap: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledButtonWhiteLink = styled(AtomButton)`
  &&&&& {
    color: ${props => props.theme.colors.neutralWhite};
    font-weight: ${props => props.theme.fontWeights.light};
    font-size: ${props => props.theme.fontSizes.s};
    line-height: 1.5;

    &:hover {
      color: ${props => props.theme.colors.neutralWhite};
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
    }
  }
`;

const BoxBadge = styled.div`
  float: right;
  display: inline;
  height: ${props => props.theme.pxToRem(80)};
  width: ${props => props.theme.pxToRem(80)};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.pxToRem(11)};
  margin-right: ${props => props.theme.pxToRem(15)};
  text-align: center;
  font-weight: ${props => props.theme.fontWeights.bold};
  white-space: pre;
`;

const Badge = styled.div`
  background-color: ${props => props.theme.colors.neutralWhite};
  border: ${props => props.theme.pxToRem(2)} solid ${props => props.theme.colors.primaryDarkPurple};
  border-radius: ${props => props.theme.pxToRem(15)} ${props => props.theme.pxToRem(15)}
    ${props => props.theme.pxToRem(50)} ${props => props.theme.pxToRem(50)};
  height: ${props => props.theme.pxToRem(70)};
  width: ${props => props.theme.pxToRem(70)};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const StyledCompleteIcon = styled(IconComplete)`
  width: ${props => props.theme.pxToRem(65)};
  height: ${props => props.theme.pxToRem(65)};
  color: ${props => props.theme.colors.primaryDarkPurple};
`;

const StyledAlertText = styled.span`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralWhite};
`;

const StyledIconError = styled(IconError)`
  display: display-inline;
  width: ${props => props.theme.pxToRem(18)};
  height: ${props => props.theme.pxToRem(18)};
  color: ${props => props.theme.colors.neutralWhite};
  margin-right: ${props => props.theme.pxToRem(10)};
`;

const StyledAlertDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${props => props.theme.pxToRem(20)} 0;
`;

const StyledSpacer = styled.div`
  margin: ${props => props.theme.pxToRem(10)} 0;
`;
