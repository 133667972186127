import { MembershipTypes } from 'mxp-schemas';
import { Admin as AdminUtils } from 'mxp-utils';
import { isEmptyString } from 'utils/StringHelpers';

export const PCAOB_MIN_NUM = 0;

const MIN_CPA_ACADEMIA = '1';

export const FIRM_REGISTERED_DROPDOWN_OPTIONS = [
  { text: 'Yes', value: 'Yes', key: 'Yes' },
  { text: 'No', value: 'No', key: 'No' },
];

export const valueOptionalSetter = (mainValue: any, optionalValue: any) => {
  if (mainValue) {
    return mainValue;
  }
  return optionalValue;
};

export const getFirmRegisteredPCAOBDefaultValue = (isPCAOBOrganization: boolean | undefined) => {
  const index = isPCAOBOrganization ? 0 : 1;
  return `${FIRM_REGISTERED_DROPDOWN_OPTIONS[index].value}`;
};

export const isAcademiaUtil = (relationshipType: string | undefined, membershipType: string) => {
  return relationshipType === MembershipTypes.OrganizationType.EDUCATION && AdminUtils.isCPEAType(membershipType);
};

export const checkIfInRange = (num: string, membershipType: string, min: string = '0', max: string = '0') => {
  const convertToZeroIfEmptyString = (str: string) => {
    if (isEmptyString(str)) {
      return 0;
    }
    // tslint:disable-next-line radix
    return parseInt(str);
  };
  // tslint:disable-next-line radix
  const parseMin = convertToZeroIfEmptyString(min);
  // tslint:disable-next-line radix
  const parsedMax = convertToZeroIfEmptyString(max);
  // tslint:disable-next-line radix
  const parseNum = convertToZeroIfEmptyString(num);

  if (AdminUtils.isCAQType(membershipType)) {
    const parsedMaxGreaterThanZero = parseMin <= parseNum && parsedMax >= parseNum;
    const parsedMaxLessThanZero = parseMin > 0 ? parseMin <= parseNum : parseNum === 0;
    return parsedMax > 0 ? parsedMaxGreaterThanZero : parsedMaxLessThanZero;
  }
  return parsedMax > 0 ? parseMin <= parseNum && parsedMax >= parseNum : parseMin <= parseNum;
};

// logic to get the available tab item
export const getCenterMembershipProductsTabItems = (
  cpas: string,
  membershipType: string,
  isAcademia: boolean,
  relationshipType?: string,
  pcaobIssuersCount: string = '0',
  membershipTiers: State.MembershipTierBlock[] = []
) => {
  let defaultTierId: string = '';
  const totalNumOfCpa = isAcademia ? MIN_CPA_ACADEMIA : cpas;
  const membershipTiersASC = [...membershipTiers].sort(
    (a: State.MembershipTierBlock, b: State.MembershipTierBlock) =>
      valueOptionalSetter(a.minIssuers, 0) - valueOptionalSetter(b.minIssuers, 0)
  );
  const sortedMembershipTiers = AdminUtils.isCAQType(membershipType) ? membershipTiersASC : membershipTiers;

  const tabs: State.MembershipTierBlock[] = sortedMembershipTiers.map((tier: State.MembershipTierBlock) => {
    let isEnabled = false;
    const applicableOrganization: string = valueOptionalSetter(tier.applicableOrganization?.label, '');
    const isTierDoesNotExist = !membershipTiers.find(
      (tempTier: State.MembershipTierBlock) =>
        valueOptionalSetter(tempTier.applicableOrganization?.label, '') === relationshipType
    );
    // if membershipType is CPEA and check if variant is restricted to specific organization type
    if (AdminUtils.isCPEAType(membershipType) && applicableOrganization) {
      isEnabled = applicableOrganization === relationshipType;
    } else if (
      (AdminUtils.isCPEAType(membershipType) &&
        (isTierDoesNotExist || (!relationshipType && !applicableOrganization))) ||
      (!AdminUtils.isCPEAType(membershipType) && !AdminUtils.isCAQType(membershipType))
    ) {
      // if membershipType is CPEA and if variant is not restricted to any relationship type then check range min and max
      // or if membership type is not CPEA or CAQ
      isEnabled = checkIfInRange(totalNumOfCpa, membershipType, `${tier.minCpas}`, `${tier.maxCpas}`);
    } else if (AdminUtils.isCAQType(membershipType)) {
      // if membershipType is CAQ
      isEnabled = checkIfInRange(pcaobIssuersCount, membershipType, `${tier.minIssuers}`, `${tier.maxIssuers}`);
    }

    if (!defaultTierId && isEnabled && tier?.sku) {
      defaultTierId = tier.sku;
    }

    return {
      ...tier,
      isEnabled,
    };
  });

  return { defaultTierId, tabs };
};

/* -------- INPUT UTILS -------- */
export const cpaInputValidationIsCorrect = (param1: any, param2: boolean) => {
  // param1 = cpaInputHasError, param2 = isNumCpaTouched
  return Boolean(param1 === '') || !param2;
};

export const cpaInputValidationErrorMessage = (param1: boolean, param2: any) => {
  // param1 = isNumCpaTouched, param2 = cpaInputHasError
  return param1 ? param2 : '';
};

export const cpaFormInputDescription = (isCAQ: boolean) => {
  // isCAQ = AdminUtils.isCAQType(membershipType)
  return isCAQ
    ? 'This number includes partners/owners, professionals and staff at your last fiscal year end.'
    : 'Include all CPA Partner/Owners, Professionals and staff at your last fiscal year end';
};

export const pcaobInputNumberOfIssuersValue = (param1: boolean, param2: any) => {
  // param1 = isFirmPCAOB, param2 = form.PCAOBNumOfIssuers
  return param1 ? param2 : String(PCAOB_MIN_NUM);
};
export const pcaobInputNumberOfIssuersErrorMessage = (param1: boolean, param2: any) => {
  // param1 = isNumIssuerTouched, param2 = issuersInputHasError
  return param1 ? param2 : '';
};

export const getButtonIconName = (boolParam: boolean) => {
  // boolParam = isCAQDisable | isNonCAQDisable
  return boolParam ? 'pencil alternate' : undefined;
};

export const getButtonTitle = (boolParam: boolean) => {
  // boolParam = isCAQDisable | isNonCAQDisable
  return boolParam ? 'Edit' : 'Go';
};

export const renewApplyButtonDisabled = (param1: boolean, param2: any, param3: boolean, param4: any) => {
  // param1 = showTiers, param2 = issuersInputHasError, param3 = AdminUtils.isCAQType(membershipType), param4 = cpaInputHasError
  return (param1 && param2 !== '') || (param3 && param4 !== '');
};

export const renewApplyButtonTitle = (boolParam: boolean) => {
  // boolParam = isRenewal
  return boolParam ? 'Renew' : 'Apply Now';
};
