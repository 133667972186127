import React from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';

interface CollapsibleProps {
  collapsed: boolean;
  maxHeight?: number;
  children: string | React.ReactNode;
  className?: string;
}

export const Collapsible: React.FC<CollapsibleProps> = ({ children, collapsed, maxHeight = 500, className }) => {
  return (
    <CollapsibleStyled className={className} collapsed={collapsed} maxHeight={maxHeight}>
      {children}
    </CollapsibleStyled>
  );
};

const CollapsibleStyled = styled.div<ThemedStyledProps<{ collapsed: boolean; maxHeight: number }, DefaultTheme>>`
  max-height: ${({ maxHeight }) => maxHeight}px;
  overflow: hidden;
  transition: max-height .25s ease-in-out;}
  ${props => props.collapsed && 'max-height:0;'}
`;
