import React from 'react';
import styled from 'styled-components';
import { Container as SemanticContainer, ContainerProps } from 'semantic-ui-react';

export const Container: React.FC<ContainerProps> = ({ children, ...props }) => (
  <StyledSemanticContainer {...props}>{children}</StyledSemanticContainer>
);

const StyledSemanticContainer = styled(SemanticContainer)`
  @media only screen and (min-width: 1200px) {
    &&& {
      width: 1120px;
    }
  }
`;
