import React from 'react';
import styled from 'styled-components';
import { NotificationBanner, NotificationBannerEnums } from 'components/atoms';
import { ReactComponent as IconInfo } from 'resources/images/icon-live-ic-info.svg';

interface Props {
  text: string;
  testId: string;
}

export const FirmRosterModalInfoNotification: React.FC<Props> = ({ text, testId }) => {
  return (
    <StyledNotificationContainer>
      <StyledNotificationBanner
        variant={NotificationBannerEnums.variant.blue}
        testId={testId}
        childrenTestId={`${testId}-children`}
        icon={<StyledIconInfo />}
      >
        {text}
      </StyledNotificationBanner>
    </StyledNotificationContainer>
  );
};

const StyledNotificationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  max-width: ${props => props.theme.pxToRem(372)};
  margin: ${props => props.theme.pxToRem(9)} 0 ${props => props.theme.pxToRem(16)} 0;
`;

const StyledIconInfo = styled(IconInfo)`
  &&&& {
    align-self: flex-start;
    flex: 0 0 ${props => props.theme.pxToRem(24)};
    width: ${props => props.theme.pxToRem(24)};
    height: ${props => props.theme.pxToRem(24)};
  }
`;
