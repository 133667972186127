import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Accordion, Icon, Popup } from 'semantic-ui-react';
import { ReactComponent as EditIcon } from 'resources/images/edit-purple.svg';
import { ButtonEnums, Button } from 'components/atoms';
import { MembershipPackageAccordionStatus } from '../../../constants';

import {
  RENEWAL_TIER,
  RENEWAL_MEMBERSHIP_TYPE,
  TWL_MEMBERSHIP_TIER,
  RETIRED_MEMBERSHIP_TIER,
  CPA_EXAM_AFFILIATE_TIER,
  INTERNAIONAL_AFFILIATE_TIER,
  AFFILIATE_MEMBERSHIP_TYPE,
  INT_ASSOC_MEMBERSHIP_TYPE,
  CANDIDATE_MEMBERSHIP_TYPE,
  RETIRED_MEMBERSHIP_TYPE,
  STUDENT_AFF_MEMBERSHIP_TYPE,
} from 'resources/images';

interface AccordionProps {
  accordionKey: string;
  title?: any;
  content?: any;
  onClickCallback?: any;
  isActive?: boolean;
  activeIndex?: number;
  index?: number;
  popupContent?: string;
  toolTipContent: (popupContent: string) => any;
  popupTrigger: () => any;
  isCompleted: boolean;
  accordionColor: string;
  accordionTitleColor: string;
  accordionIcon: string;
  rowProps: any[];
  rowHeaders: string[];
}

export const MembershipAccordion: React.FC<AccordionProps> = ({
  accordionKey,
  title,
  content,
  onClickCallback,
  isActive,
  index = 0,
  activeIndex = 0,
  popupContent,
  toolTipContent,
  popupTrigger,
  isCompleted,
  accordionColor,
  accordionTitleColor,
  accordionIcon,
  rowProps,
  rowHeaders,
}) => {
  const handleUpdateOnClick = useCallback(
    (e: any) => {
      onClickCallback(e, { index });
    },
    [onClickCallback, index]
  );

  const camelCaseToWords = (key: string): string => {
    const result = key.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const renderAccordionDescriptions = () => {
    switch (accordionKey) {
      case MembershipPackageAccordionStatus.Sections:
        return 'Choose a preferred sections to to enhance your membership';
      case MembershipPackageAccordionStatus.Addons:
        return `Select the type of add-on service, packages or product you're interested in`;
      case MembershipPackageAccordionStatus.Tier:
      case MembershipPackageAccordionStatus.Credentials:
      default:
        return 'Customise your experience to meet your professional needs';
    }
  };

  const renderMembershipTypeIcons = (membershipType: string | number | undefined) => {
    switch (membershipType) {
      case 'Affiliate':
        return AFFILIATE_MEMBERSHIP_TYPE;
      case 'International associate':
        return INT_ASSOC_MEMBERSHIP_TYPE;
      case 'Candidate':
        return CANDIDATE_MEMBERSHIP_TYPE;
      case 'Student affiliate':
        return STUDENT_AFF_MEMBERSHIP_TYPE;
      case 'Retired':
        return RETIRED_MEMBERSHIP_TYPE;
      case 'Regular':
      default:
        return RENEWAL_MEMBERSHIP_TYPE;
    }
  };

  const renderServiceTierIcons = (selectedTier: string | number | undefined) => {
    switch (selectedTier) {
      case 'TLW':
        return TWL_MEMBERSHIP_TIER;
      case 'Retired':
        return RETIRED_MEMBERSHIP_TIER;
      case 'CPA Exam Candidate Affiliate':
        return CPA_EXAM_AFFILIATE_TIER;
      case 'International Associate':
        return INTERNAIONAL_AFFILIATE_TIER;
      case 'Regular':
      default:
        return RENEWAL_TIER;
    }
  };

  return (
    <>
      <StyledAccordion key={accordionKey}>
        <StyledAccordionTitle active={isActive} onClick={onClickCallback} index={index} activeIndex={activeIndex}>
          <AccordionHeader backgroundColor={accordionColor} accordionTitleColor={accordionTitleColor}>
            <IconAndTitleWrapper>
              {accordionIcon && <StyledUsersIcon className="custom-icon" src={accordionIcon} />}
              <StyledSpan>
                {popupContent && (
                  <StyledPopup
                    content={toolTipContent(popupContent)}
                    key="icon"
                    trigger={popupTrigger()}
                    hoverable
                    hideOnScroll
                  />
                )}
                {title}
                {!isCompleted && !isActive && (
                  <StyledAccordionDescription>{renderAccordionDescriptions()}</StyledAccordionDescription>
                )}
              </StyledSpan>
            </IconAndTitleWrapper>
            <StyledIcon
              name={isActive ? 'chevron up' : 'chevron down'}
              accordionChevronColorColor={accordionTitleColor}
            />
          </AccordionHeader>
        </StyledAccordionTitle>
        <StyledAccordionContent active={isActive}>{isActive && content}</StyledAccordionContent>
      </StyledAccordion>
      {isCompleted && Boolean(rowProps?.length) && (
        <StyledCard>
          {rowProps?.map((contents: any) => {
            return (
              <StyledCardContents
                key={
                  contents?.membershipType ||
                  contents?.selectedTier ||
                  contents?.selectedCredentials ||
                  contents?.selectedSections
                }
              >
                {rowHeaders?.map((header: string) => {
                  const headerMapValue =
                    header === 'selectedCredentials' || header === 'selectedSections' || header === 'selectedAdd-ons'
                      ? 'name'
                      : header;

                  return (
                    <RowContentsColumn key={header} rowTitle={header}>
                      <RowContentsTitle>{camelCaseToWords(header)}</RowContentsTitle>
                      <RowContentsCaption>
                        {header === 'selectedTier' && (
                          <StyledTypeAndTypeIcon
                            src={renderServiceTierIcons(contents[header])}
                            alt="Renew Membership"
                            data-testid="membershipType-img"
                            loading="lazy"
                          />
                        )}
                        {header === 'membershipType' && (
                          <StyledTypeAndTypeIcon
                            src={renderMembershipTypeIcons(contents[header])}
                            alt="Renew Membership"
                            data-testid="membershipType-img"
                            loading="lazy"
                          />
                        )}
                        {contents[headerMapValue]}
                        {header === 'value' && contents.isIncluded && <IncludedTag>Included</IncludedTag>}
                      </RowContentsCaption>
                    </RowContentsColumn>
                  );
                })}
                <RowContentsColumn>
                  <RowContentsTitle>Action</RowContentsTitle>
                  <StyledAction>
                    <StyledUpdateInlineButton
                      testId={'express-renewal-update-inline'}
                      icon={<EditIcon />}
                      onClick={handleUpdateOnClick}
                      iconPosition={ButtonEnums.iconPosition.left}
                      size={ButtonEnums.sizes.small}
                    />
                    <StyledActionSpan onClick={handleUpdateOnClick}>Update</StyledActionSpan>
                  </StyledAction>
                </RowContentsColumn>
              </StyledCardContents>
            );
          })}
        </StyledCard>
      )}
    </>
  );
};

const StyledAccordion = styled(Accordion)`
  padding: 0 !important;
  overflow: hidden;
  border-radius: ${props => props.theme.pxToRem(4)};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(32)};
  }
`;

const StyledAccordionTitle = styled(Accordion.Title)`
  padding: 0 !important;
`;

const AccordionHeader = styled.div<{ backgroundColor?: string; accordionTitleColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${props => props.accordionTitleColor};
  font-weight: ${props => props.theme.fontWeights.regular};
  padding: ${props => `${props.theme.pxToRem(16)} ${props.theme.pxToRem(21)}`};
  background: ${props => props.backgroundColor};
  font-style: normal;
  line-height: normal;

  ${props => props.theme.mediaQueries.desktopOnly} {
    font-size: ${props => props.theme.fontSizes.l};
    letter-spacing: ${props => props.theme.pxToRem(-0.48)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s};
    letter-spacing: ${props => props.theme.pxToRem(-0.32)};
  }
`;

const IconAndTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledUsersIcon = styled.img`
  margin-right: ${props => props.theme.pxToRem(16)};
`;

const StyledAccordionContent = styled(Accordion.Content)`
  padding: ${props => `${props.theme.pxToRem(24)} ${props.theme.pxToRem(16)}`} !important;
`;

const StyledSpan = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.pxToRem(8)};
  font-family: 'Roboto';
  font-size: ${props => props.theme.fontSizes.l};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.pxToRem(20)};
    display: inline-block;
  }
`;

const StyledAccordionDescription = styled.div`
  color: ${props => props.theme.colors.neutralGrey10}
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
`;

const StyledPopup = styled(Popup)`
  &&&& {
    padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(20)};
    min-width: ${props => props.theme.pxToRem(100)};
  }
`;

const StyledIcon = styled(Icon)<{ accordionChevronColorColor?: string }>`
  &&&& {
    color: ${props => props.accordionChevronColorColor};
  }
`;

const StyledCard = styled.div`
  min-width: ${props => props.theme.pxToRem(120)};
  display: flex;
  padding: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(16)}
    ${props => props.theme.pxToRem(24)};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.theme.pxToRem(24)};

  align-self: stretch;

  border-radius: ${props => props.theme.pxToRem(4)};
  background: var(--Neutral-N01, #fff);

  box-shadow: ${props => props.theme.pxToRem(0)} ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(35)}
    ${props => props.theme.pxToRem(0)} rgba(41, 46, 61, 0.1);
`;

const StyledCardContents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.theme.pxToRem(24)};
  align-self: stretch;

  > div:not(:first-child) {
    padding-left: ${props => props.theme.pxToRem(8)};
  }

  > div:last-child {
    border-left: ${props => props.theme.pxToRem(1)} solid var(--Neutral-N04, #ccc);
  }
`;

const StyledTypeAndTypeIcon = styled.img`
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;
const RowContentsColumn = styled.div<{ rowTitle?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.pxToRem(8)};
  ${props => props.rowTitle && `width: ${getColumnWidth(props.rowTitle)}`};
  line-height: normal;
`;

const RowContentsTitle = styled.span`
  font-size: ${props => props.theme.pxToRem(16)};
  color: var(--Neutral-N07, #636669);
  font-weight: 400;
  letter-spacing: ${props => props.theme.pxToRem(-0.32)};
`;

const RowContentsCaption = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.pxToRem(20)};
  font-weight: 500;
  letter-spacing: ${props => props.theme.pxToRem(-0.4)};
`;

const StyledUpdateInlineButton = styled(Button)`
  background-color: transparent !important;
  color: ${props => props.theme.colors.primaryPurple} !important;
  margin: 0 !important;
  padding: 0 !important;
`;

const StyledAction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledActionSpan = styled.span`
  font-weight: 400;
  font-size: ${props => props.theme.pxToRem(16)};
  line-height: normal;
  letter-spacing: ${props => props.theme.pxToRem(-0.32)};
  color: ${props => props.theme.colors.primaryPurple};
  margin-left: ${props => props.theme.pxToRem(10)};
  cursor: pointer;
`;

const IncludedTag = styled.div`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.xs};
  border-radius: 12px;
  background-color: ${props => props.theme.colors.secondaryTeal};
  padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(8)}`};
  margin-bottom: ${props => props.theme.pxToRem(12)};
  text-align: center !important;
  width: ${props => props.theme.pxToRem(69)} !important;
  height: ${props => props.theme.pxToRem(26)} !important;
  line-height: 1.33;
`;

const getColumnWidth = (name: string) => {
  switch (name) {
    case 'employmentStatus':
    case 'role':
    case 'value':
    case 'validUntil':
      return '20%';

    case 'selectedTier':
      return '30%';

    default:
      return '70%';
  }
};
