import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { parse, stringify } from 'query-string';
import { fullUrlSelector } from 'modules/app/selectors';
import { searchMetaSelector } from 'modules/search/selectors';
import { trimWithEllipsis } from 'utils';
import { COMPANY_NAME } from 'constants/index';

interface Props {
  title?: string;
  isNoFollow?: boolean;
  meta?: {
    title?: string;
    description?: string;
  };
  openGraph?: {
    title?: string;
    description?: string;
    image?: string;
    type?: string;
    passUrl?: boolean;
  };
  usePagination?: boolean;
  isNoIndex?: boolean;
}

export const PageHelmet: React.FC<Props> = ({
  title,
  meta,
  openGraph,
  children,
  isNoFollow,
  isNoIndex,
  usePagination,
  ...props
}) => {
  const maxMetaTitleLength: number = 60;
  const maxMetaDescriptionLength: number = 155;
  const defaultValues = React.useMemo(
    () => ({
      CONTEXT: 'https://schema.org',
      TYPE: 'Website',
      LOCALE: 'en_US',
      SUMMARY: 'Summary',
      TWITTER_TITLE: '@AICPA',
      APP_ID: '288416708186975',
      DESCRIPTION: `AICPA® & CIMA® is the most influential body of accountants and finance experts in the world, with 689,000 members, students and engaged professionals globally. We advocate for the profession, the public interest and business sustainability.`,
      TITLE: 'AICPA & CIMA',
      URL: 'https://www.aicpa-cima.com',
      LOGO_URL:
        'https://images.ctfassets.net/rb9cdnjh59cm/43ZKFkefBBGDMM3VoBYWIP/b4d7a47dedb6d7232936e0c8beb6e764/Logo_1200x630.png',
      LOGO_TWITTER:
        'https://images.ctfassets.net/rb9cdnjh59cm/7mnrnYZgfxdybtOMGVPQUS/ebecfab9684f277ad4f959a7b4d4257c/Logo_144x144_.png',
    }),
    []
  );
  const url: string = useSelector(fullUrlSelector);
  const searchMeta = useSelector(searchMetaSelector);
  const location = useLocation();
  const queryParams = parse(location.search, { parseNumbers: true });
  const prevPaginationTag = usePagination && queryParams?.page && queryParams?.page !== 1;
  const nextPaginationTag =
    usePagination &&
    queryParams?.page &&
    searchMeta.total &&
    queryParams?.page !== Math.ceil(searchMeta.total / searchMeta.perPage);

  return (
    <Helmet {...props}>
      {title && (
        <title>
          {title} | {COMPANY_NAME}
        </title>
      )}
      {meta?.title && (
        <meta
          name="title"
          content={
            meta?.title.length > maxMetaTitleLength ? trimWithEllipsis(meta?.title, maxMetaTitleLength) : meta?.title
          }
        />
      )}
      {meta?.description && (
        <meta
          name="description"
          content={
            meta?.description.length > maxMetaDescriptionLength
              ? trimWithEllipsis(meta?.description, maxMetaDescriptionLength)
              : meta?.description
          }
        />
      )}
      {isNoIndex && <meta name="robots" content="noindex" />}
      {isNoFollow && <meta name="robots" content="nofollow" />}
      <meta property="og:title" content={openGraph?.title || defaultValues.TITLE} />
      <meta property="og:image" content={openGraph?.image || defaultValues.LOGO_URL} />
      <meta property="og:description" content={openGraph?.description || defaultValues.DESCRIPTION} />
      <meta property="og:url" content={openGraph?.passUrl ? url : defaultValues.URL} />
      <meta property="og:site" name={defaultValues.TITLE} />
      <meta property="fb:app_id" content={defaultValues.APP_ID} />
      <meta property="og:type" content={openGraph?.type || defaultValues.TYPE} />
      <meta property="og:locale" content={defaultValues.LOCALE} />
      <meta name="twitter:site" content={defaultValues.TWITTER_TITLE} />
      <meta name="twitter:card" content={defaultValues.SUMMARY} />
      <meta name="twitter:url" content={openGraph?.passUrl ? url : defaultValues.URL} />
      <meta name="twitter:title" content={openGraph?.title || defaultValues.TITLE} />
      <meta name="twitter:description" content={openGraph?.description || defaultValues.DESCRIPTION} />
      <meta name="twitter:image" content={openGraph?.image || defaultValues.LOGO_TWITTER} />
      {!openGraph?.image && <meta property="og:image:width" content="1200" />}
      {!openGraph?.image && <meta property="og:image:height" content="630" />}
      <link rel="canonical" href={url} />
      {prevPaginationTag && (
        <link rel="prev" href={`${url}?${stringify({ ...queryParams, page: queryParams?.page - 1 })}`} />
      )}
      {nextPaginationTag && (
        <link rel="next" href={`${url}?${stringify({ ...queryParams, page: queryParams?.page + 1 })}`} />
      )}
      {children}
    </Helmet>
  );
};
