import {
  customerProfileFetchedSelector,
  customerProfileFetchSuccessSelector,
  employerIsInUSSelector,
  isActiveEmployerSelector,
  isAuthSelector,
  personAccountCountrySelector,
  userLocationSelector,
  userMemberTypeSelector,
  userRolesSelector,
  epaLevel4Epa2ProductIdSelector,
  epa2Level7ProductIdSelector,
  userTierSelector,
  cimaMembershipTermIsTenYearsSelector,
} from 'modules/user/selectors';
import { constantsSelector } from 'modules/app/selectors';
import moment from 'moment-timezone';
import {
  Cart,
  Checkout,
  CommerceTypes,
  Orders,
  Product,
  Salesforce,
  User as UserTypes,
  MembershipTypes,
  Contentful,
} from 'mxp-schemas';
import {
  Cart as CartUtils,
  CheckoutCountriesListHash,
  CountriesList,
  isPhysicalProduct,
  Product as ProductUtils,
} from 'mxp-utils';
import { createSelector } from 'reselect';

import { getAccessText } from 'components/pages/PageProduct/ProductConstants';
import { transformItemsPriceData, transformLineItemsToCartDetailItems } from 'modules/cart/helpers';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';
import { paginationInfoSelector } from 'modules/layouts/selectors';
import { UserMemberTypes } from 'modules/user/constants';
import {
  arrayIncludes,
  availableFormatToLabel,
  emptyArray,
  flattenArray,
  formatPrice,
  fromCentsFormat,
  getMomentDateTimeInfo,
  getPriceRangeForNonmembers,
  MomentHelpers,
} from 'utils';
import {
  FilterActiveKeys,
  ProfileListSortByOption,
  ProfileListSortByOptions,
  profileListSortByOptions,
} from '../../constants';
import {
  getFilteredVariants,
  getIsConference,
  getIsCourse,
  getIsExam,
  getIsProductTypeWithMultipleOptions,
  getProductFormatLabel,
  getProductTypeLabel,
  getVariants,
  getVariantsBundleItemsWithOutOfStockCheck,
  isActiveCheckDates,
  isInHistoryRangePurchases,
  mapProductBundleToContentCardItem,
  matchActiveFilter,
} from './helpers';
import { inviteDataSelector } from 'modules/firmAdmin/selectors';
import { profileCacheAddressSelector } from 'modules/address/selectors';

const DEFAULT_MASTER_ID = 1;
// ------------------------------------
// Selectors
// ------------------------------------

const cartRootSelector = createSelector(
  (state: State.Root): State.Cart => state.cart,
  (cart: State.Cart): State.Cart => cart
);

const rootSelector = createSelector(
  (state: State.Root): State.Products => state.products,
  (products: State.Products): State.Products => products
);

const stateSelector = createSelector(
  (state: State.Root): State.Root => state,
  (state: State.Root): State.Root => state
);

export const isProductFetchedSelector = createSelector(
  rootSelector,
  (products: State.Products): boolean => products.isProductFetched
);

export const donationPriceSelector = createSelector(
  rootSelector,
  (products: State.Products): string => products.selectedDonationPrice
);

export const fetchedProductBySkuPriceSelector = createSelector(rootSelector, (products: State.Products) => {
  const filteredPrice = products?.fetchedProductBySku?.variants[0]?.prices?.find(
    (price: any) =>
      price?.channel?.applicableUserRoles.includes(UserTypes.MembershipIdsEnum.MRUKR0001) &&
      price?.currency === Product.ProductCurrencyLabel.GBP
  );
  return filteredPrice;
});

export const fetchedProductBySkuSelector = createSelector(rootSelector, (products: State.Products) => {
  return products?.fetchedProductBySku;
});

export const productPageItemSelector = createSelector(rootSelector, (products: State.Products): Product.ProductItem => {
  if (products?.productItem?.linkedProducts) {
    products?.productItem?.linkedProducts.forEach((product: Product.ProductItem) => {
      product.productTypeLabel = getProductTypeLabel(product);
    });
  }
  return products.productItem;
});

const bundleProductsInfo = (product: State.ContentCardItem, state: State.Root) => {
  const isAuth = isAuthSelector(state);
  const userMemberType = userMemberTypeSelector(state);
  const isMember: boolean = userMemberType === UserMemberTypes.PREMIUM || userMemberType === UserMemberTypes.MEMBER;

  const bundlePrices: State.BundleMembershipPrices | undefined = product?.bundlePrices;

  const fractionAfterDiscount = Math.round(100 - (product?.bundleDiscountPercent || 0)) / 100;

  const minPriceNonMemberBundleFormatted = fromCentsFormat(
    (bundlePrices?.nonMember?.min?.amount || 0) * fractionAfterDiscount
  ).toFixed(2);

  const minPriceMemberBundleFormatted = fromCentsFormat(
    (bundlePrices?.member?.min?.amount || 0) * fractionAfterDiscount
  ).toFixed(2);

  const minPrice: string | undefined = isAuth
    ? isMember
      ? minPriceMemberBundleFormatted
      : minPriceNonMemberBundleFormatted
    : minPriceNonMemberBundleFormatted;

  return minPrice;
};

export const productItemPrices = createSelector(
  rootSelector,
  stateSelector,
  (products: State.Products, state: State.Root) => {
    const productItem = products?.productItem;

    const isBundle = productItem?.productType === 'bundle-type';
    const bundlePrice = bundleProductsInfo(productItem, state);
    const normalPrice = selectedVariantPriceInfoForUserSelector(state)?.priceFinal?.amount;
    const finalNormalPrice = normalPrice ? normalPrice / 100 : 0;

    return isBundle ? +bundlePrice : +finalNormalPrice;
  }
);

export const productSKU = createSelector(rootSelector, (products: State.Products) => {
  const productItem = products?.productItem;
  const isBundle = productItem?.productType === 'bundle-type';
  return isBundle
    ? productItem?.bundles[0]?.obj.key || productItem?.variants[0]?.sku
    : productItem?.variants[0]?.sku || '';
});

export const premiumContentItemsSelector = createSelector(
  rootSelector,
  (products: State.Products): Array<Partial<State.ContentCardItem>> =>
    products?.premiumContentItems?.length ? products?.premiumContentItems : emptyArray
);

export const getProductIncludedMembership = (productItem: Product.ProductItem): UserTypes.MembershipIdsEnum[] => {
  const productType: Product.ProductType | '' = (productItem?.productType as Product.ProductType) || '';
  const productIncludedMembership =
    productItem &&
    (productItem.includedMembership && productType === Product.ProductType.SUBSCRIPTION
      ? productItem.includedMembership.map(membership => membership.key)
      : []);
  return productIncludedMembership as UserTypes.MembershipIdsEnum[];
};

export const getProductContentRoleId = (productItem: Product.ProductItem): UserTypes.MembershipIdsEnum => {
  const productType: Product.ProductType | '' = (productItem?.productType as Product.ProductType) || '';
  const productContentRoleId =
    productItem &&
    (productItem.contentRoleId && productType === Product.ProductType.SUBSCRIPTION
      ? productItem.contentRoleId.key
      : '');
  return productContentRoleId as UserTypes.MembershipIdsEnum;
};

export const productTypeLabelSelector = createSelector(
  productPageItemSelector,
  (productItem: Product.ProductItem): string => getProductTypeLabel(productItem)
);

export const getProductIncludedMembershipSelector = createSelector(
  productPageItemSelector,
  (productItem: Product.ProductItem): UserTypes.MembershipIdsEnum[] => getProductIncludedMembership(productItem)
);

export const getProductContentRoleIdSelector = createSelector(
  productPageItemSelector,
  (productItem: Product.ProductItem): UserTypes.MembershipIdsEnum => getProductContentRoleId(productItem)
);

export const productFormatLabelSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): string => getProductFormatLabel(productItem)
);

export const slugSelector = createSelector(
  productPageItemSelector,
  (productItem: Product.ProductItem): string | undefined => productItem && productItem.slug
);

export const productVariantsSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): Product.Variant[] => getVariants(productItem)
);

export const isConferenceSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): boolean => getIsConference(productItem)
);

const isCourseSelector = createSelector([productPageItemSelector], (productItem: Product.ProductItem): boolean =>
  getIsCourse(productItem)
);

const isPublicationSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): boolean => productItem?.productType === Product.ProductType.PUBLICATION
);

const isExamSelector = createSelector([productPageItemSelector], (productItem: Product.ProductItem): boolean =>
  getIsExam(productItem)
);

const isWebcastSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): boolean => productItem?.productType === Product.ProductType.WEBCAST
);

const isSubscriptionSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): boolean => productItem?.productType === Product.ProductType.SUBSCRIPTION
);

export const isContributionSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): boolean => productItem?.productType === Product.ProductType.CONTRIBUTION
);

const isEventSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): boolean => productItem?.productType === Product.ProductType.EVENT
);

export const isPhPrSubscriptionSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): boolean =>
    isPhysicalProduct(productItem).isPhSubscriptionProduct && productItem.showQuantity
);

export const isPremiumContentSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): boolean =>
    productItem?.productType === Product.ProductType.SUBSCRIPTION &&
    productItem?.subscriptionProductType?.key === Product.SubscriptionProductType.CONTENT
);

export const isBundleSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): boolean => productItem?.productType === Product.ProductType.BUNDLE
);

export const bundleProductsSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): Product.ProductItem[] | undefined => productItem?.bundleProducts
);

export const isBundleItemsPurchasedSelector = createSelector(
  [bundleProductsSelector],
  (bundleProducts: Product.ProductItem[] | undefined): boolean => Boolean(bundleProducts?.some(i => i.isSubscribed))
);

export const bundleProductsProductIdsSelector = createSelector(
  [bundleProductsSelector],
  (bundleProducts: Product.ProductItem[] | undefined): string[] | undefined =>
    bundleProducts?.map((product: Product.ProductItem) => product.productId)
);

const bundleProductVariantsSelector = createSelector(
  [bundleProductsSelector],
  (bundleProductItems: Product.ProductItem[] | undefined): Product.Variant[][] | undefined => {
    const out = bundleProductItems?.map(getVariants);
    return out;
  }
);

export const hasExistingZuoraPurchaseSelector = createSelector(rootSelector, (products: State.Products): boolean => {
  return products.hasExistingZuoraPurchase;
});

export const productCurrencySelector = createSelector(
  [rootSelector, featureTogglesSelector, userLocationSelector, inviteDataSelector, hasExistingZuoraPurchaseSelector],
  (
    products: State.Products,
    featureToggle: any,
    location: State.UserLocation,
    inviteData: State.InviteData,
    hasExistingZuoraPurchase: boolean
  ): State.ProductCurrency => {
    const { useProductsPriceCurrency } = featureToggle;
    const isUseProductsPrice = useProductsPriceCurrency;

    if (inviteData?.inviteId && !hasExistingZuoraPurchase) {
      // if there's a firm invite
      // AICPA org invite = USD
      // CIMA org invite = GBP
      if (inviteData?.organization?.organizationCapabilities?.type === Salesforce.LegalEntity.CIMA) {
        return {
          label: Product.ProductCurrencyLabel.GBP,
          sign: Product.ProductCurrencySign.POUND,
        };
      }

      return {
        label: Product.ProductCurrencyLabel.USD,
        sign: Product.ProductCurrencySign.DOLLAR,
      };
    }

    if (isUseProductsPrice) return products.currency;

    const country = CountriesList.find((countryList: any) => countryList.key === location.country);
    if (country?.isAmerica) {
      return {
        label: Product.ProductCurrencyLabel.USD,
        sign: Product.ProductCurrencySign.DOLLAR,
      };
    }
    return {
      label: Product.ProductCurrencyLabel.GBP,
      sign: Product.ProductCurrencySign.POUND,
    };
  }
);

export const bundleUserApplicableVariantsPricingSelector = createSelector(
  [bundleProductVariantsSelector, userRolesSelector, productCurrencySelector, userTierSelector],
  (
    bundleItemsVariants: Product.Variant[][] | undefined,
    userRoles: UserTypes.MembershipIdsEnum[],
    currency: State.ProductCurrency,
    userTier: Checkout.Tiers
  ) => {
    const out = bundleItemsVariants?.map((variants: Product.Variant[]) =>
      ProductUtils.userApplicableVariantsPricing(
        variants,
        userRoles,
        false, // isContribution
        currency?.label,
        userTier
      )
    );

    return out;
  }
);

export const variantsBundleItemsPriceInfoForUserSelector = createSelector(
  [bundleUserApplicableVariantsPricingSelector],
  (userApplicableBundleItemsVariantsPricing: any): State.VariantsPriceInfoForUserType[] => {
    const out = userApplicableBundleItemsVariantsPricing?.map(ProductUtils.variantsPriceInfoForUser);
    return out;
  }
);

export const variantsBundleItemsWithOutOfStockCheck = createSelector(
  [bundleProductsSelector, cartRootSelector],
  (bundleProductItems: Product.ProductItem[] | undefined, cart: State.Cart): State.BundleItemVariantOutOfStock[][] =>
    getVariantsBundleItemsWithOutOfStockCheck(bundleProductItems, cart.value)
);

const isWebcastFormatSelector = createSelector(
  [isWebcastSelector, productPageItemSelector],
  (isWebcast: boolean, productItem: Product.ProductItem): boolean =>
    isWebcast && (productItem?.format?.key === Product.AvailableFormat.WEBCAST || productItem?.format?.key === '')
);

const isMultiDayWebcastFormatSelector = createSelector(
  [isWebcastSelector, productPageItemSelector],
  (isWebcast: boolean, productItem: Product.ProductItem): boolean =>
    isWebcast && productItem?.format?.key === Product.AvailableFormat.MULTI_DAY
);

const isWebcastSeriesFormatSelector = createSelector(
  [isWebcastSelector, productPageItemSelector],
  (isWebcast: boolean, productItem: Product.ProductItem): boolean =>
    isWebcast && productItem?.format?.key === Product.AvailableFormat.SERIES
);

export const isProductTypeWithMultipleOptionsSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): boolean => getIsProductTypeWithMultipleOptions(productItem)
);

export const filteredProductVariantsSelector = createSelector(
  [
    isExamSelector,
    isCourseSelector,
    productVariantsSelector,
    isProductTypeWithMultipleOptionsSelector,
    isConferenceSelector,
    isContributionSelector,
  ],
  (
    isExam: boolean,
    isCourse: boolean,
    variants: Product.Variant[],
    isProductTypeWithVariants,
    isConference: boolean,
    isContribution: boolean
  ): Product.Variant[] => {
    return getFilteredVariants(variants, isProductTypeWithVariants, isExam, isCourse, isConference, isContribution);
  }
);

export const bundleFilteredProductVariantsSelector = createSelector(
  [bundleProductsSelector, bundleProductVariantsSelector],
  (
    bundleProducts: Product.ProductItem[] | undefined,
    bundleVariants: Product.Variant[][] | undefined
  ): Product.Variant[][] => {
    return (bundleVariants || []).map((variants: Product.Variant[], i: number) => {
      const bundleProduct = bundleProducts?.[i] || null;

      const isProductTypeWithVariants = Boolean(bundleProduct && getIsProductTypeWithMultipleOptions(bundleProduct));
      const isExam = Boolean(bundleProduct && getIsExam(bundleProduct));
      const isCourse = Boolean(bundleProduct && getIsCourse(bundleProduct));
      const isConference = Boolean(bundleProduct && getIsConference(bundleProduct));

      return getFilteredVariants(variants, isProductTypeWithVariants, isExam, isCourse, isConference);
    });
  }
);

const filteredParentProductVariantsSelector = createSelector(
  [isMultiDayWebcastFormatSelector, productVariantsSelector, isProductTypeWithMultipleOptionsSelector],
  (isMultiDayWebcastFormat: boolean, variants: Product.Variant[], isProductTypeWithVariants): Product.Variant[] => {
    // Filter all Parent Product Variant and return the variants whose children's start date is before the date and time now or if it is published
    return isProductTypeWithVariants && isMultiDayWebcastFormat
      ? variants.filter(
          (variant: Product.Variant) =>
            !variant.productChildrenInfo?.find(
              (variantChild: Product.ProductChildrenInfo) =>
                moment().isAfter(variantChild.startDateTime) || variantChild.published === false
            )
        )
      : variants;
  }
);

const firstFilteredProductVariantsSelector = createSelector(
  [filteredProductVariantsSelector],
  (filteredVariants: Product.Variant[]): Product.Variant => {
    return filteredVariants[0];
  }
);

export const relatedProductSelector = createSelector(
  rootSelector,
  (products: State.Products): State.ContentCardItem[] | null => products.relatedContent
);

const getDateRange = (variantDateInfo: any) => {
  if (variantDateInfo && variantDateInfo.startDate && variantDateInfo.endDate) {
    if (variantDateInfo.startDate !== variantDateInfo.endDate) {
      return `${variantDateInfo.startDate.split(',')[0]} - ${variantDateInfo.endDate}`;
    }
    return variantDateInfo.endDate;
  }
  return '';
};

const getDateTimeRange = (dateTimeInfo: Product.VariantDateTimeInfo): string => {
  return dateTimeInfo.startDate === dateTimeInfo.endDate
    ? `${dateTimeInfo.startDate.split(',')[0]}, ${dateTimeInfo.startTime} \u2013 ${dateTimeInfo.endTime} ${
        dateTimeInfo.timeZone
      }`
    : `${dateTimeInfo.startDate.split(',')[0]}, ${dateTimeInfo.startTime} \u2013 ${
        dateTimeInfo.endDate.split(',')[0]
      }, ${dateTimeInfo.endTime} ${dateTimeInfo.timeZone}`;
};

const webcastSeriesDateTimeInfoSelector = createSelector(
  [filteredParentProductVariantsSelector, isWebcastSeriesFormatSelector],
  (webcastVariants: Product.Variant[], isWebcastSeriesFormat: boolean): Product.VariantDateTimeInfo[] | undefined => {
    return isWebcastSeriesFormat && webcastVariants
      ? webcastVariants.reduce((acc: Product.VariantDateTimeInfo[], variant: Product.Variant) => {
          const variantSessionOneDateTimeInfo: Product.VariantDateTimeInfo[] =
            variant.productChildrenInfo?.reduce(
              (innerAcc: Product.VariantDateTimeInfo[], productChild: Product.ProductChildrenInfo) => {
                if (productChild.name.startsWith('Session 1:')) {
                  innerAcc.push(
                    getMomentDateTimeInfo(
                      variant.sku || '',
                      productChild.startDateTime,
                      productChild.endDateTime,
                      productChild.dateRange
                    )
                  );
                }
                return innerAcc;
              },
              []
            ) || [];
          acc.push(...variantSessionOneDateTimeInfo);
          return acc;
        }, [])
      : undefined;
  }
);

export const variantDateTimeSelector = createSelector(
  [
    filteredProductVariantsSelector,
    filteredParentProductVariantsSelector,
    isMultiDayWebcastFormatSelector,
    isWebcastSeriesFormatSelector,
    webcastSeriesDateTimeInfoSelector,
  ],
  (
    variants: Product.Variant[],
    webcastVariants: Product.Variant[],
    isMultiDayWebcastFormat: boolean,
    isWebcastSeriesFormat: boolean,
    webcastSeriesDateTimeInfo: Product.VariantDateTimeInfo[] | undefined
  ): Product.VariantDateTimeInfo[] | undefined => {
    return isMultiDayWebcastFormat && webcastVariants
      ? webcastVariants.map((item: Product.Variant) => {
          return getMomentDateTimeInfo(
            item.sku || '',
            item.startDateTime,
            item.endDateTime,
            webcastVariants?.[0]?.productChildrenInfo?.[0]?.dateRange
          );
        })
      : isWebcastSeriesFormat && webcastVariants
      ? webcastSeriesDateTimeInfo
      : variants
      ? variants.map((item: Product.Variant) =>
          getMomentDateTimeInfo(item.sku || '', item.startDateTime, item.endDateTime)
        )
      : undefined;
  }
);

export const showCCSelector = createSelector([productPageItemSelector], (productItem: Product.ProductItem): boolean => {
  return productItem &&
    productItem.closedCaptioning &&
    productItem.closedCaptioning.label === 'Close captioned' &&
    productItem.closedCaptioning.key === 'has'
    ? true
    : false;
});

export const currencySelector = createSelector(
  [userLocationSelector],
  (location: State.UserLocation): State.ProductCurrency => {
    const country = CountriesList.find((countryList: any) => countryList.key === location.country);
    if (country?.isAmerica) {
      return {
        label: Product.ProductCurrencyLabel.USD,
        sign: Product.ProductCurrencySign.DOLLAR,
      };
    }

    return {
      label: Product.ProductCurrencyLabel.GBP,
      sign: Product.ProductCurrencySign.POUND,
    };
  }
);

export const isUserFromRestrictedCountrySelector = createSelector(
  [userLocationSelector, featureTogglesSelector],
  (location: State.UserLocation, featureToggle: any): boolean => {
    const { useProductsPriceCurrency } = featureToggle;
    const isUseProductsPrice = useProductsPriceCurrency;
    const country = CountriesList.find((countryList: any) => countryList.key === location.country);

    if (isUseProductsPrice && country && country?.isRestricted) {
      return true;
    }

    return false;
  }
);

export const userApplicableVariantsPricingSelector = createSelector(
  [productVariantsSelector, userRolesSelector, productPageItemSelector, productCurrencySelector, userTierSelector],
  (
    productVariants: Product.Variant[],
    userRoles: UserTypes.MembershipIdsEnum[],
    productItem: Product.ProductItem,
    currency: State.ProductCurrency,
    userTier: Checkout.Tiers
  ): Product.UserApplicableVariantsPricingType => {
    const isContribution: boolean = productItem?.productType === Product.ProductType.CONTRIBUTION;

    return ProductUtils.userApplicableVariantsPricing(
      productVariants,
      userRoles,
      isContribution,
      currency?.label,
      userTier
    );
  }
);

export const variantsPriceInfoForUserSelector = createSelector(
  [userApplicableVariantsPricingSelector],
  ProductUtils.variantsPriceInfoForUser
);

export const masterVariantSelector = createSelector(
  [productVariantsSelector],
  (variants): Product.Variant => variants.find(variant => variant.isMaster) || variants[0]
);

export const examMasterVariantSkuSelector = createSelector(
  [masterVariantSelector, isExamSelector],
  (variant: Product.Variant, isExam: boolean): string | null => {
    return isExam && variant?.sku ? variant.sku : null;
  }
);

export const isProductWithMultipleOptionsSelectors = createSelector(
  filteredProductVariantsSelector,
  (products: Product.Variant[]): boolean => products.length > 1
);

export const isFreeTrialSelectedSelector = createSelector(rootSelector, (products: State.Products): boolean => {
  return products.isFreeTrialSelected;
});

export const previousSubscriptionTypeSelector = createSelector(
  productPageItemSelector,
  (productItem: Product.ProductItem): Product.PreviousSubscriptionType | null => {
    return productItem?.previousSubscriptionType || null;
  }
);

export const selectedVariantSKUSelector = createSelector(
  [
    rootSelector,
    isProductTypeWithMultipleOptionsSelector,
    isProductWithMultipleOptionsSelectors,
    filteredProductVariantsSelector,
    masterVariantSelector,
    previousSubscriptionTypeSelector,
  ],
  (
    products: State.Products,
    isProductTypeWithVariants: boolean,
    isProductWithMultiple,
    filteredProductVariants,
    masterVariant,
    previousSubscriptionType: Product.PreviousSubscriptionType | null
  ): string | undefined => {
    if (products.selectedItemSKUs[0]) {
      return products.selectedItemSKUs[0];
    }
    if (isProductTypeWithVariants) {
      if (!isProductWithMultiple) {
        return filteredProductVariants[0] ? filteredProductVariants[0].sku : undefined;
      }
      return products.selectedItemSKUs[0] || '';
    }
    return masterVariant && !previousSubscriptionType ? masterVariant.sku : undefined;
  }
);

export interface BundleItemsSelectionInfo {
  selectedSku: string;
  hasMultiple: boolean;
  selectionValue: string;
  useFormat: boolean;
  priceFull: number;
  memberDiscountPriceInfo: Product.FormattedPriceData | undefined;
  priceWithMemberBundleDiscount: number;
  userRoleDiscountInfo: Product.FormattedPriceData | null;
  bundleDiscountAmount: number;
  priceFinalWithOutMembershipDiscount: number;
  currency: string;
  priceWithDiscountsForDisplay: string;
  isOutOfStock: boolean;
  isAvailableForSale: boolean;
  isPhysicalProduct: boolean;
  isSubscribed: boolean;
}

export const bundleItemsSelectionInfoSelector = createSelector(
  [
    rootSelector,
    productPageItemSelector,
    isBundleSelector,
    variantsBundleItemsPriceInfoForUserSelector,
    bundleFilteredProductVariantsSelector,
    variantsBundleItemsWithOutOfStockCheck,
  ],
  (
    products: State.Products,
    productItem: Product.ProductItem,
    isBundle: boolean,
    variantsPriceInfoForUser: State.VariantsPriceInfoForUserType[],
    filteredVariants: Product.Variant[][],
    outOfStockInfo: State.BundleItemVariantOutOfStock[][]
  ): BundleItemsSelectionInfo[] | null => {
    if (isBundle) {
      return (
        productItem?.bundleProducts?.map((item: Product.ProductBundle, i) => {
          const selectedSku = products?.selectedItemSKUs?.[i];
          const priceForSku: Product.PriceInfo = variantsPriceInfoForUser[i][selectedSku];
          const isAvailableForSale = Boolean(filteredVariants?.[i].length);

          const selectedVariant = item?.variants.filter(v => v?.sku === selectedSku)?.[0];

          const isOutOfStock = Boolean(
            outOfStockInfo?.[i].find(
              (oosInfo: State.BundleItemVariantOutOfStock) => selectedVariant?.sku === oosInfo?.sku
            )?.isOutOfStock
          );

          const isExam = item?.productType === Product.ProductType.EXAM;
          const isConference = item?.productType === Product.ProductType.CONFERENCE;
          const isPublication = item?.productType === Product.ProductType.PUBLICATION;
          const isCourse = item?.productType === Product.ProductType.COURSE;
          const useFormat = isExam || isConference || isPublication || isCourse;

          const variantDateTimeInfo = getMomentDateTimeInfo(
            selectedVariant?.sku || '',
            selectedVariant?.startDateTime,
            selectedVariant?.endDateTime
          );

          const hasDates = selectedVariant?.startDateTime && selectedVariant?.endDateTime;
          const hasFormat = selectedVariant?.format?.label || selectedVariant?.examType?.label;

          const selectedFormat =
            (hasFormat && `${selectedVariant?.format?.label} ${selectedVariant?.examType?.label}`) || '';

          const selectedDate =
            (hasDates &&
              `${variantDateTimeInfo?.startDate} ${variantDateTimeInfo?.startTime} - ${variantDateTimeInfo?.endTime} ${variantDateTimeInfo?.timeZone}`) ||
            '';

          const selectionValue = useFormat ? selectedFormat : selectedDate;
          const memberDiscountPriceInfo = priceForSku?.priceFinal;

          const priceWithMemberDiscount = memberDiscountPriceInfo?.amount || 0;
          const priceFull = priceForSku?.priceFull?.amount || 0;

          const priceWithMemberBundleDiscount =
            priceWithMemberDiscount * (1 - (productItem?.bundleDiscountPercent || 0) / 100);

          const bundleDiscountAmount = priceWithMemberDiscount * ((productItem?.bundleDiscountPercent || 0) / 100);
          const priceToDisplayDiscounted = `${formatPrice(
            fromCentsFormat(priceWithMemberBundleDiscount),
            priceForSku?.priceFinal?.currency
          )}`;
          // this only triggers when the user is member and the ethicsStatus is suspended
          const priceFinalWithOutMembershipDiscount = priceFull * (1 - (productItem?.bundleDiscountPercent || 0) / 100);
          const userRoleDiscountInfo = priceForSku?.discountAmount;

          return {
            selectedSku,
            hasMultiple: (filteredVariants?.[i].length || 0) > 1,
            selectionValue,
            useFormat,
            priceFull,
            memberDiscountPriceInfo,
            priceWithMemberBundleDiscount,
            userRoleDiscountInfo,
            bundleDiscountAmount,
            currency: priceForSku?.priceFull?.currency || '',
            priceWithDiscountsForDisplay: priceForSku && priceToDisplayDiscounted,
            isOutOfStock,
            isAvailableForSale,
            isPhysicalProduct: Boolean(selectedVariant?.isPhysicalProduct),
            isSubscribed: !!item.isSubscribed,
            priceFinalWithOutMembershipDiscount,
          };
        }) || null
      );
    }
    return null;
  }
);

export const selectedBundleItemsSkusSelector = createSelector(
  [bundleItemsSelectionInfoSelector],
  (bundleProducts: BundleItemsSelectionInfo[] | null): string[] | undefined =>
    bundleProducts?.map((bundleItemsSelectionInfo: BundleItemsSelectionInfo) => bundleItemsSelectionInfo.selectedSku)
);

export interface BundleSummedPrices {
  priceFull: Product.FormattedPriceData;
  userRolesDiscounts: Product.FormattedPriceData[] | null;
  bundleDiscount: Product.FormattedPriceData;
  priceFinal: Product.FormattedPriceData;
  suspendedUserPriceFinal: Product.FormattedPriceData;
}

export const bundleSummedPricesSelector = createSelector(
  [bundleItemsSelectionInfoSelector],
  (bundleItemsSelectionInfo: BundleItemsSelectionInfo[] | null): BundleSummedPrices => {
    const rawSummedAmounts = bundleItemsSelectionInfo?.reduce(
      (
        acc: {
          priceFull: number;
          priceWithMemberBundleDiscount: number;
          bundleDiscountAmount: number;
          sumUserRoleDiscountInfo: { [key: string]: { amount: number; label: string } };
        },
        info
      ) => {
        const priceFullFinal = acc.priceFull + info.priceFull;

        const priceWithMemberBundleDiscount = acc.priceWithMemberBundleDiscount + info.priceWithMemberBundleDiscount;
        const bundleDiscountAmount = acc.bundleDiscountAmount + info.bundleDiscountAmount;
        const discountRoleKeyLabel = info?.userRoleDiscountInfo?.label;
        // sum discounts for each role separately
        // products in bundle can have different role discounts, using label as a key
        const sumUserRoleDiscountInfo = discountRoleKeyLabel
          ? {
              ...acc.sumUserRoleDiscountInfo,
              [discountRoleKeyLabel]: {
                amount:
                  (acc.sumUserRoleDiscountInfo?.[discountRoleKeyLabel]?.amount || 0) +
                  (info?.userRoleDiscountInfo?.amount || 0),
                label: discountRoleKeyLabel,
              },
            }
          : acc.sumUserRoleDiscountInfo;

        return {
          priceFull: priceFullFinal,
          sumUserRoleDiscountInfo,
          priceWithMemberBundleDiscount,
          bundleDiscountAmount,
        };
      },
      {
        priceFull: 0,
        priceWithMemberBundleDiscount: 0,
        bundleDiscountAmount: 0,
        sumUserRoleDiscountInfo: {},
      }
    );

    const currency = bundleItemsSelectionInfo?.[0]?.currency || '';

    const priceFull: Product.FormattedPriceData = {
      currency,
      amount: rawSummedAmounts?.priceFull || 0,
      label: 'Nonmember Price',
      formattedPrice: formatPrice(fromCentsFormat(rawSummedAmounts?.priceFull || 0), currency),
    };

    const userRolesDiscounts = rawSummedAmounts?.sumUserRoleDiscountInfo
      ? Object.keys(rawSummedAmounts?.sumUserRoleDiscountInfo).map((key: string) => {
          return {
            currency,
            amount: rawSummedAmounts?.sumUserRoleDiscountInfo[key].amount || 0,
            label: rawSummedAmounts?.sumUserRoleDiscountInfo[key].label || '',
            formattedPrice: formatPrice(
              fromCentsFormat(rawSummedAmounts?.sumUserRoleDiscountInfo[key].amount || 0),
              currency
            ),
          };
        })
      : null;

    const bundleDiscount = {
      currency,
      amount: rawSummedAmounts?.bundleDiscountAmount || 0,
      label: 'Bundle Discount',
      formattedPrice: formatPrice(fromCentsFormat(rawSummedAmounts?.bundleDiscountAmount || 0), currency),
    };

    const priceFinal = {
      currency,
      amount: rawSummedAmounts?.priceWithMemberBundleDiscount || 0,
      label: 'Price excluding applicable tax',
      formattedPrice: formatPrice(fromCentsFormat(rawSummedAmounts?.priceWithMemberBundleDiscount || 0), currency),
    };

    const discountAmount = rawSummedAmounts?.bundleDiscountAmount || 0;
    const priceFullRaw = rawSummedAmounts?.priceFull || 0;
    const suspendedPriceFinalAmount = priceFullRaw - discountAmount;
    const suspendedUserPriceFinal = {
      currency,
      amount: suspendedPriceFinalAmount,
      label: 'Price excluding applicable tax',
      formattedPrice: formatPrice(fromCentsFormat(suspendedPriceFinalAmount || 0), currency),
    };

    return { priceFull, userRolesDiscounts, bundleDiscount, priceFinal, suspendedUserPriceFinal };
  }
);

export const allUserPricesForSelectedVariantSelector = createSelector(
  [
    isWebcastSelector,
    userApplicableVariantsPricingSelector,
    selectedVariantSKUSelector,
    firstFilteredProductVariantsSelector,
    masterVariantSelector,
  ],
  (
    isWebcast: boolean,
    variantsPriceInfoForUser: Product.UserApplicableVariantsPricingType,
    variantSKU?: string,
    earliestFutureVariant?: Product.Variant,
    masterVariant?: Product.Variant
  ): Product.PriceForRole[] | undefined => {
    const variantPriceInfo: Product.PriceForRole[] | undefined =
      variantsPriceInfoForUser[
        variantSKU ||
          (isWebcast && earliestFutureVariant?.sku) ||
          (!isWebcast && masterVariant?.sku) ||
          DEFAULT_MASTER_ID
      ];
    if (isWebcast) {
      return variantPriceInfo?.map(item => {
        if (item?.variantsWithAvailableFormatInfo?.[0]) {
          return {
            ...item,
            variantsWithAvailableFormatInfo: [item?.variantsWithAvailableFormatInfo[0]],
          };
        }
        return item;
      });
    }
    return variantPriceInfo;
  }
);

export const nonmemberUserPriceRangesSelector = createSelector(
  [userApplicableVariantsPricingSelector, userMemberTypeSelector],
  (
    variantsPriceInfoForUser: Product.UserApplicableVariantsPricingType,
    userStateName: UserMemberTypes
  ): Product.FormattedPriceRanges | null => {
    if (userStateName === UserMemberTypes.NONMEMBER) {
      const memberPriceRange: Product.PriceForRole[] = getPriceRangeForNonmembers(variantsPriceInfoForUser, true);
      const defaultPriceRange: Product.PriceForRole[] = getPriceRangeForNonmembers(variantsPriceInfoForUser, false);

      const [memberLow, memberHigh] = memberPriceRange;
      const [nonMemberLow, nonMemberHigh] = defaultPriceRange;
      return {
        member: { low: memberLow?.transformedPrice, high: memberHigh?.transformedPrice },
        nonMember: { low: nonMemberLow?.transformedPrice, high: nonMemberHigh?.transformedPrice },
      };
    }
    return null;
  }
);

export const priceRangeSelector = createSelector(
  [userApplicableVariantsPricingSelector, filteredProductVariantsSelector],
  (
    variantsPriceInfoForUser: Product.UserApplicableVariantsPricingType,
    variants: Product.Variant[]
  ): Product.PriceRange => {
    const applicableVariantsPrices: Product.PriceForRole[] = Object.keys(variantsPriceInfoForUser).reduce(
      (allPrices: Product.PriceForRole[], key: string) => {
        if (
          variants.some(variant => {
            return variant.sku === key;
          })
        ) {
          return allPrices.concat(variantsPriceInfoForUser[key]);
        }
        return allPrices;
      },
      []
    );
    return applicableVariantsPrices.reduce(
      (result: Product.PriceRange, current: Product.PriceForRole): Product.PriceRange => {
        if (current.applyedDiscountRole === UserTypes.MembershipIdsEnum.FREE_TRIAL) {
          return result;
        }
        if (!result.minPrice || !result.maxPrice) {
          return {
            minPrice: current,
            maxPrice: current,
          };
        }
        return {
          minPrice: result.minPrice.price.amount > current.price.amount ? current : result.minPrice,
          maxPrice: result.maxPrice.price.amount < current.price.amount ? current : result.maxPrice,
        };
      },
      { minPrice: null, maxPrice: null }
    );
  }
);

const applyFreePriceToPriceInfo = (priceInfo: Product.PriceInfo): Product.PriceInfo => {
  if (!priceInfo) return priceInfo;
  const freePriceInfo: Product.PriceInfo = JSON.parse(JSON.stringify(priceInfo));
  freePriceInfo.discountAmount = null;
  if (freePriceInfo.priceFinal) {
    freePriceInfo.priceFinal.amount = 0;
    freePriceInfo.priceFinal.formattedPrice = formatPrice(fromCentsFormat(0), freePriceInfo.priceFinal?.currency);
  }
  if (freePriceInfo.priceFull) {
    freePriceInfo.priceFull.amount = 0;
    freePriceInfo.priceFull.formattedPrice = formatPrice(fromCentsFormat(0), freePriceInfo.priceFinal?.currency);
  }
  return freePriceInfo;
};

export const selectedVariantPriceInfoForUserSelector = createSelector(
  [
    variantsPriceInfoForUserSelector,
    isFreeTrialSelectedSelector,
    firstFilteredProductVariantsSelector,
    selectedVariantSKUSelector,
  ],
  (
    variantsPriceInfoForUser: Product.VariantsPriceInfoForUserType,
    isFreeTrialSelected: boolean,
    firstVariant?: Product.Variant,
    variantSKU?: string
  ): Product.PriceInfo | undefined =>
    isFreeTrialSelected
      ? applyFreePriceToPriceInfo(
          variantsPriceInfoForUser[variantSKU || (firstVariant && firstVariant.sku) || DEFAULT_MASTER_ID]
        )
      : variantsPriceInfoForUser[variantSKU || (firstVariant && firstVariant.sku) || DEFAULT_MASTER_ID]
);

export const freeTrialEndDateSelector = createSelector(
  [isFreeTrialSelectedSelector, productPageItemSelector, selectedVariantSKUSelector],
  (isFreeTrialSelected: boolean, productItem: Product.ProductItem, variantSKU?: string | null) => {
    const freeTrialEndDate = (() => {
      if (!isFreeTrialSelected) return '';
      const accessDays = productItem.variants.find(variant => variant.sku === variantSKU)?.freeTrialTerm;
      return moment().add(accessDays, 'days').toISOString();
    })();

    return freeTrialEndDate;
  }
);

export const selectedExamVariantFormatLabelSelector = createSelector(
  [isExamSelector, masterVariantSelector, productVariantsSelector, selectedVariantSKUSelector],
  (
    isExam: boolean,
    masterVariant: Product.Variant,
    variants: Product.Variant[],
    variantSKU?: string | null
  ): string | null => {
    const selectedExamVariantFormatKey =
      isExam && variantSKU ? variants.find(variant => variant.sku === variantSKU)?.format?.key : null;

    return isExam
      ? variantSKU
        ? selectedExamVariantFormatKey
          ? availableFormatToLabel(selectedExamVariantFormatKey)
          : null
        : masterVariant?.format
        ? availableFormatToLabel(masterVariant.format.key)
        : null
      : null;
  }
);

export const isAvailableForSaleSelector = createSelector(
  filteredProductVariantsSelector,
  (products: Product.Variant[]): boolean => !!products.length
);

export const productsListDataSelector = createSelector(
  rootSelector,
  (products: State.Products): State.ProductsListData | null => products.productsListData
);

export const conferenceUserInfoSelector = createSelector(
  productsListDataSelector,
  (productsListData: State.ProductsListData | null): State.ConferenceUserInfo | null =>
    (productsListData && productsListData.conferenceUserInfo) || null
);

export const bridgeWebconferencesSelector = createSelector(
  productsListDataSelector,
  (productsListData: State.ProductsListData | null): UserTypes.BridgeWebconferences[] | null =>
    (productsListData && productsListData.webConferences) || null
);

export const productsListDataLineItemsSelector = createSelector(
  productsListDataSelector,
  (productsListData: State.ProductsListData | null): State.LineItem[] | null =>
    productsListData && productsListData.lineItems
);

export const productsListDataDiscountsSelector = createSelector(
  productsListDataSelector,
  (productsListData: State.ProductsListData | null): CommerceTypes.DiscountCodeInfo[] | null =>
    (productsListData && productsListData.discountCodes) || null
);

export const allProductsDetailItemsSelector = createSelector(
  [productsListDataLineItemsSelector, productsListDataDiscountsSelector],
  (
    lineItems: State.LineItem[] | null,
    discountCodes: CommerceTypes.DiscountCodeInfo[] | null
  ): Common.ProductItemData[] | null => {
    return lineItems && transformLineItemsToCartDetailItems(lineItems, discountCodes);
  }
);

export const profileProductsDetailItemsSelector = createSelector(
  allProductsDetailItemsSelector,
  (items: Common.ProductItemData[] | null) =>
    items?.filter(
      (lineItem: Common.ProductItemData) =>
        !(
          [
            Product.ProductType.MEMBERSHIP,
            Product.ProductType.CREDENTIAL,
            Product.ProductType.SECTION,
            Product.ProductType.FEE,
          ] as string[]
        ).includes(lineItem.productType)
    ) || []
);

export const fcmaCredentialProductsDetailItemsSelector = createSelector(
  allProductsDetailItemsSelector,
  (items: Common.ProductItemData[] | null): Common.ProductItemData | undefined =>
    items?.find((lineItem: Common.ProductItemData) => lineItem?.sku === Product.CIMA_CREDENTIALS_SKU.FCMA)
);

export const productsDetailItemsOrderedByBundlesSelector = createSelector(
  [allProductsDetailItemsSelector, productsListDataLineItemsSelector],
  (items: Common.ProductItemData[] | null, lineItems: State.LineItem[] | null): Common.ProductItemData[] | null => {
    if (items?.some((item: Common.ProductItemData) => item.bundleId)) {
      items.sort(CartUtils.sortCartItemsByBundleName);
    }
    const withMemBodyAttrItems = (items || []).map((item: Common.ProductItemData) => {
      const productLineItem = lineItems?.find((lineItem: State.LineItem) => lineItem.id === item.lineItemId);
      return { ...item, membershipBody: productLineItem?.variant?.attributes.membershipBody?.key };
    });

    return withMemBodyAttrItems;
  }
);

export const productsDetailHasRejoiningItemSelector = createSelector(
  [productsDetailItemsOrderedByBundlesSelector],
  (items: Common.ProductItemData[] | null): boolean => {
    return Boolean(
      items?.some(
        (item: Common.ProductItemData) =>
          item.productLink?.toLowerCase() === Product.Fee.CIMA_REJOINING_FEE_CANDIDATE.toLowerCase()
      )
    );
  }
);

export const hasUserActiveSubscriptionsSelector = createSelector(
  [allProductsDetailItemsSelector],
  (lineItems: Common.ProductItemData[] | null): boolean => {
    const nowD = new Date();
    return Boolean(
      lineItems?.some(
        (lineItem: Common.ProductItemData) =>
          isActiveCheckDates(lineItem, nowD) &&
          (lineItem.productType === Product.ProductType.SUBSCRIPTION ||
            (lineItem.productType === Product.ProductType.COURSE && lineItem.isFreeTrial) ||
            lineItem.productType === Product.ProductType.MEMBERSHIP ||
            lineItem.productType === Product.ProductType.CREDENTIAL ||
            lineItem.productType === Product.ProductType.SECTION ||
            lineItem.productType === Product.ProductType.CENTER_MEMBERSHIP) &&
          lineItem.autoRenewEnabled &&
          lineItem.subscriptionStatus === 'Active'
      )
    );
  }
);

export const hasUserActiveStandingOrderSelector = createSelector(
  [profileProductsDetailItemsSelector],
  (lineItems: Common.ProductItemData[] | null): boolean => {
    return Boolean(
      lineItems?.some(
        (lineItem: Common.ProductItemData) =>
          lineItem.isPhysicalProduct && lineItem.standingOrderStatus === Product.StandingOrderStatus.ACTIVE
      )
    );
  }
);

export const hasUserAwaitingShipmentOrderSelector = createSelector(
  [profileProductsDetailItemsSelector],
  (lineItems: Common.ProductItemData[] | null): boolean => {
    return Boolean(
      lineItems?.some(
        (lineItem: Common.ProductItemData) =>
          lineItem.isPhysicalProduct &&
          (lineItem.cartLineItemState === Orders.LineItemStates.AWAITING_SHIPMENT ||
            lineItem.cartLineItemState === Orders.LineItemStates.INITIAL)
      )
    );
  }
);

export const hasPhysicalProductInProductsSelector = createSelector(
  [profileProductsDetailItemsSelector],
  (lineItems: Common.ProductItemData[] | null): boolean => {
    return Boolean(lineItems?.some((lineItem: Common.ProductItemData) => lineItem.isPhysicalProduct));
  }
);

export const hasContributionProductInProductsSelector = createSelector(
  [profileProductsDetailItemsSelector],
  (lineItems: Common.ProductItemData[] | null): boolean => {
    return Boolean(
      lineItems?.some((lineItem: Common.ProductItemData) => lineItem.productType === Product.ProductType.CONTRIBUTION)
    );
  }
);

const sortByOrderDate = (items: Common.ProductItemData[] | null): Common.ProductItemData[] | null => {
  const out = items ? [...items].sort((a, b) => moment(b.orderDate).diff(moment(a.orderDate))) : null;
  return out;
};

const sortByRenewalReminderState = (items: Common.ProductItemData[] | null): Common.ProductItemData[] | null => {
  const out = items
    ? [...items].sort((a, b) => {
        if (a.shouldRemindRenewal && a.shouldRemindRenewal === b.shouldRemindRenewal) {
          return moment(a.accessEndDate).diff(moment(b.accessEndDate));
        }
        if (!a.shouldRemindRenewal && a.shouldRemindRenewal === b.shouldRemindRenewal) {
          return moment(b.orderDate).diff(moment(a.orderDate));
        }
        return a.shouldRemindRenewal ? -1 : 1;
      })
    : null;
  return out;
};

const sortByEndDate = (items: Common.ProductItemData[] | null): Common.ProductItemData[] | null => {
  const out = items ? [...items].sort((a, b) => moment(b.accessEndDate).diff(moment(a.accessEndDate))) : null;
  return out;
};

const sortBySelectedFilter = (
  items: Common.ProductItemData[] | null,
  filter: FilterActiveKeys
): Common.ProductItemData[] | null => {
  switch (filter) {
    case FilterActiveKeys.ACTIVE:
      if (items?.some((item: Common.ProductItemData) => !!item.shouldRemindRenewal)) {
        return sortByRenewalReminderState(items);
      }
      return sortByOrderDate(items);
    case FilterActiveKeys.INACTIVE:
      return sortByEndDate(items);
  }
};

export const hasResultsSelector = createSelector(
  [profileProductsDetailItemsSelector],
  (lineItems: Common.ProductItemData[] | null): boolean => {
    return Boolean(lineItems?.length);
  }
);

export const filteredProductsDetailItemsSelector = createSelector(
  [profileProductsDetailItemsSelector, paginationInfoSelector],
  (
    lineItems: Common.ProductItemData[] | null,
    paginationInfo: State.PaginationInfo
  ): Common.ProductItemData[] | null => {
    const nowD = new Date();
    const toFilter = lineItems || [];
    const out = toFilter.filter((item: Common.ProductItemData) => {
      return (
        isInHistoryRangePurchases(item, paginationInfo.filtering.history, nowD) &&
        matchActiveFilter(item, paginationInfo.filtering.active, nowD)
      );
    });

    return sortBySelectedFilter(out, paginationInfo.filtering.active);
  }
);

export const historyOptionsSelector = createSelector(
  [filteredProductsDetailItemsSelector],
  (lineItems: Common.ProductItemData[] | null): ProfileListSortByOptions => {
    const nowD = new Date();
    const toFilter = lineItems || [];
    const out: ProfileListSortByOptions = { ...profileListSortByOptions };
    Object.values(profileListSortByOptions).forEach((option: ProfileListSortByOption) => {
      const hasItemInRange = toFilter.some((item: Common.ProductItemData) => {
        return isInHistoryRangePurchases(item, option.value, nowD);
      });
      out[option.text] = { ...profileListSortByOptions[option.text], disabled: !hasItemInRange };
    });

    return out;
  }
);

export const paginatedFilteredProductsDetailItemsSelector = createSelector(
  [filteredProductsDetailItemsSelector, paginationInfoSelector],
  (
    lineItems: Common.ProductItemData[] | null,
    paginationInfo: State.PaginationInfo
  ): Common.ProductItemData[] | null => {
    const out = lineItems?.slice(paginationInfo.pagination.from - 1, paginationInfo.pagination.to) || null;
    return out;
  }
);

export const filteredProductsTotalSelector = createSelector(
  [filteredProductsDetailItemsSelector, paginationInfoSelector],
  (products: Common.ProductItemData[] | null): State.FilteredProductTotalCounts => {
    const membershipProducts =
      products?.filter(
        lineItem =>
          lineItem.orderNumber?.toLowerCase().includes(Orders.AccessOrderType.ACCESS_ONLY) ||
          lineItem.orderNumber?.toLowerCase().includes(Orders.AccessOrderType.BENEFIT)
      ) || null;
    const membership = membershipProducts ? membershipProducts.length : 0;
    const individualProducts = products?.filter(lineItem => !membershipProducts?.includes(lineItem)) || null;
    const individual = individualProducts ? individualProducts.length : 0;
    const all = membership + individual;
    return {
      all,
      membership,
      individual,
    };
  }
);

export const membershipIncludedProductsSelector = createSelector(
  [filteredProductsDetailItemsSelector, paginationInfoSelector],
  (
    products: Common.ProductItemData[] | null,
    paginationInfo: State.PaginationInfo
  ): Common.ProductItemData[] | null => {
    // if order number has ACC we can say that it is an access only order and it is a membership benefit product
    const membershipProducts =
      products?.filter(
        lineItem =>
          lineItem.orderNumber?.toLowerCase().includes(Orders.AccessOrderType.ACCESS_ONLY) ||
          lineItem.orderNumber?.toLowerCase().includes(Orders.AccessOrderType.BENEFIT)
      ) || null;

    const paginatedMembershipProducts =
      membershipProducts?.slice(paginationInfo.pagination.from - 1, paginationInfo.pagination.to) || null;

    return paginatedMembershipProducts;
  }
);

export const individualProductsSelector = createSelector(
  [filteredProductsDetailItemsSelector, paginationInfoSelector],
  (
    products: Common.ProductItemData[] | null,
    paginationInfo: State.PaginationInfo
  ): Common.ProductItemData[] | null => {
    const membershipProducts =
      products?.filter(
        lineItem =>
          lineItem.orderNumber?.toLowerCase().includes(Orders.AccessOrderType.ACCESS_ONLY) ||
          lineItem.orderNumber?.toLowerCase().includes(Orders.AccessOrderType.BENEFIT)
      ) || null;

    const individualProducts = products?.filter(lineItem => !membershipProducts?.includes(lineItem)) || null;
    const paginatedIndividualProducts =
      individualProducts?.slice(paginationInfo.pagination.from - 1, paginationInfo.pagination.to) || null;

    return paginatedIndividualProducts;
  }
);

export const productsListDataSummarySelector = createSelector(
  productsListDataSelector,
  (productsListData: State.ProductsListData | null): State.ProductsListDataSummary | null =>
    (productsListData && productsListData.summary) || null
);

export const productsListDataFetchedSelector = createSelector(rootSelector, (products: State.Products): boolean =>
  Boolean(products.productsListDataFetched)
);

export const firstProductVariantDateSelector = createSelector(
  [variantDateTimeSelector, firstFilteredProductVariantsSelector, selectedVariantSKUSelector],
  (date: any, firstVariant?: Product.Variant, variantSKU?: string): any => {
    return date[variantSKU || (firstVariant && firstVariant.sku) || DEFAULT_MASTER_ID];
  }
);

export const firstDateRangeSelector = createSelector(
  [productPageItemSelector, masterVariantSelector, variantDateTimeSelector, selectedVariantSKUSelector],
  (productItem: Product.ProductItem, masterVariant: Product.Variant, variantDateTimes, variantSKU?: string) => {
    const variantDateRange =
      (variantDateTimes && variantDateTimes.find(variant => variant.sku === (variantSKU || masterVariant.sku))) || null;
    return (productItem && productItem.duration && getDateRange(variantDateRange)) || null;
  }
);

export const attendanceOptionsSelector = createSelector(
  [
    isConferenceSelector,
    productPageItemSelector,
    productVariantsSelector,
    variantDateTimeSelector,
    userRolesSelector,
    productCurrencySelector,
    userLocationSelector,
  ],
  (
    isConference: boolean,
    productItem: Product.ProductItem,
    variants: Product.Variant[],
    variantDateTimes,
    roles,
    productCurrency,
    location
  ): Product.ConferenceAttendanceOptions[] => {
    return isConference && variants
      ? variants.map((item: any) => {
          const country = CountriesList.find((countryList: any) => countryList.key === location.country);

          const itemDateTime = variantDateTimes && variantDateTimes.find((variant: any) => variant.sku === item.sku);
          const itemPricing = ProductUtils.userApplicableVariantsPricing(
            [item],
            roles,
            false,
            productCurrency.label,
            country?.tier
          );
          return {
            sku: item.sku,
            format: item.format && item.format.label,
            date: getDateRange(itemDateTime),
            prices: item.sku ? itemPricing[item.sku] : [],
          };
        })
      : [];
  }
);

export const filteredProductVariantsWithPriceInfoSelector = createSelector(
  [filteredProductVariantsSelector, variantsPriceInfoForUserSelector],
  (filteredVariants: Product.Variant[], variantPriceInfoForUser): Product.VariantWithPriceInfo[] =>
    flattenArray(
      (filteredVariants || []).map((item: Product.Variant) => {
        const priceInfo: Product.PriceInfo = variantPriceInfoForUser[item.sku as string];
        if (item.freeTrialEligible && item.freeTrialTerm) {
          const freePriceInfoForTrial: Product.PriceInfo = applyFreePriceToPriceInfo(priceInfo);
          return [
            {
              ...item,
              isFreeTrial: false,
              priceInfo,
            },
            {
              ...item,
              isFreeTrial: true,
              priceInfo: freePriceInfoForTrial,
            },
          ];
        }
        return [
          {
            ...item,
            isFreeTrial: false,
            priceInfo,
          },
        ];
      })
    )
);

export const variantOptionPriceSelector = createSelector(
  [
    isConferenceSelector,
    isExamSelector,
    isCourseSelector,
    isPublicationSelector,
    isSubscriptionSelector,
    isEventSelector,
    filteredProductVariantsWithPriceInfoSelector,
  ],
  (
    isConference: boolean,
    isExam: boolean,
    isCourse: boolean,
    isPublication: boolean,
    isSubscription: boolean,
    isEvent: boolean,
    filteredProductVariantsWithPriceInfo: Product.VariantWithPriceInfo[]
  ): Product.VariantWithPriceInfo[] =>
    isConference || isExam || isCourse || isPublication || isSubscription || isEvent
      ? filteredProductVariantsWithPriceInfo
      : emptyArray
);

export const priceRangeForUserSelector = createSelector(
  [
    isConferenceSelector,
    isExamSelector,
    isCourseSelector,
    isPublicationSelector,
    isProductWithMultipleOptionsSelectors,
    variantsPriceInfoForUserSelector,
    nonmemberUserPriceRangesSelector,
  ],
  (
    isConference: boolean,
    isExam: boolean,
    isCourse: boolean,
    isPublication: boolean,
    isProductWithMultipleOptions: boolean,
    variantPriceInfoForUser,
    nonmemberUserPriceRanges
  ): Product.FormattedPriceRanges | null => {
    if ((isCourse || isPublication) && isProductWithMultipleOptions) {
      return nonmemberUserPriceRanges;
    }
    if ((isConference || isExam) && isProductWithMultipleOptions) {
      const variants = Object.keys(variantPriceInfoForUser).map(key => {
        return variantPriceInfoForUser[key];
      });
      const memberPriceRange = [...variants]
        .sort((a: any, b: any) => a.priceFinal.amount - b.priceFinal.amount)
        .filter((_, i) => i === 0 || i === [...variants].length - 1);

      const defaultPriceRange = [...variants]
        .sort((a: any, b: any) => a.priceFull.amount - b.priceFull.amount)
        .filter((_, i) => i === 0 || i === [...variants].length - 1);

      const memberLow =
        memberPriceRange[0] && memberPriceRange[0].priceFinal && memberPriceRange[0].priceFinal.formattedPrice;
      const memberHigh =
        memberPriceRange[1] && memberPriceRange[1].priceFinal && memberPriceRange[1].priceFinal.formattedPrice;
      const nonMemberLow =
        defaultPriceRange[0] && defaultPriceRange[0].priceFull && defaultPriceRange[0].priceFull.formattedPrice;
      const nonMemberHigh =
        defaultPriceRange[1] && defaultPriceRange[1].priceFull && defaultPriceRange[1].priceFull.formattedPrice;

      return {
        member: { low: memberLow, high: memberHigh },
        nonMember: { low: nonMemberLow, high: nonMemberHigh },
      };
    }
    return null;
  }
);

export const variantAdditionalDetails = createSelector(
  [isConferenceSelector, masterVariantSelector],
  (isConference: boolean, masterVariant: Product.Variant): Product.Variant => {
    // add details where necessary
    return isConference && masterVariant
      ? {
          id: masterVariant.id,
          isMaster: masterVariant.isMaster,
          locations: masterVariant.locations,
          address: masterVariant.address,
          details: masterVariant.details,
          sku: masterVariant.sku,
        }
      : masterVariant;
  }
);

export const creditRangeSelector = createSelector(
  [productPageItemSelector, rootSelector],
  (productItem: Product.ProductItem, products: State.Products): string => {
    const present = new Date().toISOString();
    const selectedVariant: Product.Variant | undefined = productItem?.variants?.find(
      (variant: Product.Variant) => variant.sku === products.selectedItemSKUs[0]
    );

    if (selectedVariant?.credits) {
      return selectedVariant?.isCreditRange ? `Up to ${selectedVariant.credits}` : selectedVariant.credits.toString();
    }

    const cpeCreditRange = productItem?.variants
      ? productItem.variants
          .filter(
            (variant: Product.Variant) =>
              variant?.startDateTime && variant?.startDateTime > present && Boolean(variant?.credits)
          )
          .map((variant: Product.Variant) => variant?.credits)
          .sort((a: any, b: any) => a - b)
      : [];

    if (cpeCreditRange.length) {
      const minCpeCredits = cpeCreditRange[0];
      const maxCpeCredits = cpeCreditRange[cpeCreditRange.length - 1];
      if (minCpeCredits !== maxCpeCredits) {
        return `${minCpeCredits} - ${maxCpeCredits}`;
      }
      return `${minCpeCredits}`;
    }
    return productItem?.credits || '';
  }
);

export const itemsPriceDataProductSelector = createSelector(
  [productsListDataSelector, cimaMembershipTermIsTenYearsSelector],
  (productsListData: State.ProductsListData | null, isTenYearsCimaRegular: boolean): Cart.CartItemPriceInfo[] =>
    productsListData
      ? transformItemsPriceData(productsListData, {
          isProductsListData: true,
          isTenYearsCimaRegular,
        })
      : emptyArray
);

export const yellowBookHoursRangeSelector = createSelector(
  [productPageItemSelector, isConferenceSelector],
  (productItem: Product.ProductItem, isConference: boolean): string => {
    if (!isConference) return productItem?.yellowBookHours?.toString() || '';

    const yellowBookHoursRange = productItem?.variants
      .map((variant: Product.Variant) => variant.yellowBookHours)
      .filter(Boolean)
      .sort((a: any, b: any) => a - b);

    const minYellowBookHours = yellowBookHoursRange[0];
    const maxYellowBookHours = yellowBookHoursRange[yellowBookHoursRange.length - 1];

    if (yellowBookHoursRange.length > 1 && minYellowBookHours !== maxYellowBookHours) {
      return `${minYellowBookHours} - ${maxYellowBookHours}`;
    }
    return minYellowBookHours ? `${minYellowBookHours}` : '';
  }
);

export const availableFormatsSelector = createSelector(
  [variantOptionPriceSelector],
  (variantsOptionPrice: Product.VariantWithPriceInfo[] | null): string[] =>
    variantsOptionPrice
      ? variantsOptionPrice.reduce((acc: string[], variant: Product.VariantWithPriceInfo) => {
          const format = (variant.format && availableFormatToLabel(variant.format.key)) || '';
          if (format !== '') {
            acc.push(format.charAt(0).toUpperCase() + format.toLowerCase().slice(1));
          }
          return acc;
        }, [])
      : []
);

export const calculatedAccessDurationSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): string =>
    productItem?.availability ? MomentHelpers.daysToMonthYear(productItem.availability) : ''
);

export const calculatedAvailabilityDurationSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): string =>
    productItem?.availability ? MomentHelpers.daysToAvailabilityDuration(productItem.availability) : ''
);

export const variantsCreditsInfoSelector = createSelector(
  [productPageItemSelector, productFormatLabelSelector],
  (productItem: Product.ProductItem, productFormatLabel: string): Product.VariantCreditInfo[] =>
    productItem?.variants
      .filter((variant: Product.Variant) => {
        return !variant.startDateTime || Date.parse(variant.startDateTime) > Date.now();
      })
      .reduce((acc: Product.VariantCreditInfo[], variant: Product.Variant) => {
        if (variant.credits && (variant.format?.label || productFormatLabel)) {
          acc.push({
            sku: variant.sku || '',
            format: `${variant.format?.label || productFormatLabel || ''} ${
              variant.optionalText ? ` - ${variant.optionalText}` : ''
            }`,
            info: variant.credits?.toString() || '',
            isCreditRange: variant.isCreditRange,
          });
        }
        return acc;
      }, [])
);

export const variantsAvailabilityInfoSelector = createSelector(
  [productPageItemSelector, calculatedAvailabilityDurationSelector, availableFormatsSelector, isWebcastSelector],
  (
    productItem: Product.ProductItem,
    calculatedAvailabilityDuration: string,
    availableFormats: string[],
    isWebcast: boolean
  ): Product.VariantCreditInfo[] => {
    if (productItem?.availability && !arrayIncludes([Product.ProductType.CONFERENCE], productItem.productType)) {
      // There should only be one access text for all webcast variants
      if (isWebcast) {
        const firstVariant = productItem?.variants[0];
        const acc: Product.VariantCreditInfo[] = [];

        acc.push({
          sku: firstVariant?.sku || '',
          format: firstVariant?.format ? availableFormatToLabel(firstVariant?.format?.key) : '',
          info:
            getAccessText(
              productItem.productType as Product.ProductType,
              calculatedAvailabilityDuration,
              availableFormats,
              productItem?.availability
            ) || '',
        });
        return acc;
      }

      return productItem?.variants.reduce((acc: Product.VariantCreditInfo[], variant: Product.Variant) => {
        if (
          !arrayIncludes(
            [Product.AvailableFormat.TEXT, Product.AvailableFormat.PAPERBACK, Product.AvailableFormat.HARDCOVER],
            variant.format?.key
          )
        ) {
          acc.push({
            sku: variant.sku || '',
            format: variant.format ? availableFormatToLabel(variant.format?.key) : '',
            info:
              getAccessText(
                productItem.productType as Product.ProductType,
                calculatedAvailabilityDuration,
                availableFormats,
                productItem?.availability
              ) || '',
          });
        }
        return acc;
      }, []);
    }
    return emptyArray;
  }
);

export const isTransferableProductTypeSelector = createSelector(
  [isCourseSelector, isPublicationSelector, isSubscriptionSelector, isEventSelector],
  (isCourse: boolean, isPublication: boolean, isSubscription: boolean, isEvent: boolean): boolean =>
    isCourse || isPublication || isSubscription || isEvent
);

export const thirdPartyLinkSelector = createSelector(
  [productPageItemSelector, selectedVariantSKUSelector],
  (productItem: Product.ProductItem, selectedVariantSKU: string | undefined): string | undefined =>
    arrayIncludes(
      [
        Product.ProductType.CONFERENCE,
        Product.ProductType.COURSE,
        Product.ProductType.PUBLICATION,
        Product.ProductType.SUBSCRIPTION,
        Product.ProductType.EVENT,
      ],
      productItem?.productType
    )
      ? productItem?.variants.find(variant => variant.sku === selectedVariantSKU)?.thirdPartyLink
      : ''
);

export const isPhysicalProductSelector = createSelector(
  [productPageItemSelector, isProductWithMultipleOptionsSelectors, selectedVariantSKUSelector],
  (
    productItem: Product.ProductItem,
    isProductWithMultipleOptions: boolean,
    selectedVariantSKU: string | undefined
  ): boolean => {
    return isProductWithMultipleOptions
      ? !!(
          productItem?.variants &&
          selectedVariantSKU &&
          !!productItem.variants.find(item => item.sku === selectedVariantSKU)?.isPhysicalProduct
        )
      : !!(productItem?.variants && !!productItem.variants[0].isPhysicalProduct);
  }
);

export const productChildrenCountSelector = createSelector(
  [filteredParentProductVariantsSelector, isMultiDayWebcastFormatSelector, isWebcastFormatSelector],
  (variants: Product.Variant[], isMultiDayWebcastFormat: boolean, isWebcastSeriesFormat: boolean): number | null => {
    return isMultiDayWebcastFormat || isWebcastFormatSelector
      ? variants?.[0]?.productChildrenInfo?.length || null
      : null;
  }
);

export const formattedProductChildrenInfoSelector = createSelector(
  [
    isMultiDayWebcastFormatSelector,
    isWebcastSeriesFormatSelector,
    filteredParentProductVariantsSelector,
    variantDateTimeSelector,
  ],
  (
    isMultiDayWebcastFormat: boolean,
    isWebcastSeriesFormat: boolean,
    variants: Product.Variant[],
    variantDateTimes
  ): Product.FormattedProductChildren[] => {
    return (isMultiDayWebcastFormat || isWebcastSeriesFormat) && variants
      ? variants.map((item: Product.Variant) => {
          const itemDateTime =
            variantDateTimes &&
            variantDateTimes.find((variant: Product.VariantDateTimeInfo) => variant.sku === item.sku);

          const productChildren: Product.FormattedChildProduct[] =
            item?.productChildrenInfo?.map((productChild: Product.ProductChildrenInfo) => {
              const childDateTimeInfo = getMomentDateTimeInfo(
                productChild.sku || '',
                productChild.startDateTime,
                productChild.endDateTime
              );

              return {
                name: productChild.name || '',
                sku: productChild.sku || '',
                slug: productChild.slug || '',
                dateRange:
                  isWebcastSeriesFormat && productChild.dateRange
                    ? productChild.dateRange
                    : getDateTimeRange(childDateTimeInfo) || '',
                soldSeparately: productChild.soldSeparately,
                isPublishedOnProductLevel: productChild.isPublishedOnProductLevel,
              };
            }) || [];
          return {
            parentDateRange: getDateRange(itemDateTime).replace(',', ''),
            productChildren,
          };
        })
      : [];
  }
);

export const variantIsbnSelector = createSelector(
  [productVariantsSelector],
  (variants: Product.Variant[]): string[] | null => variants && variants.map((item: any) => item.isbn)
);

export const productItemBundlesFormattedAsContentCardsSelector = createSelector(
  [productPageItemSelector, productCurrencySelector],
  (productItem: Product.ProductItem, currency: State.ProductCurrency): State.ContentCardItem[] => {
    if (productItem?.bundles?.length) {
      return productItem.bundles
        .map((bundle: Product.ProductBundle) => {
          return mapProductBundleToContentCardItem(bundle, currency?.label);
        })
        .sort((a: any, b: any) => {
          return a.bundleCardInfo?.prices?.minBundlePrice.amount && b.bundleCardInfo?.prices?.minBundlePrice.amount
            ? a.bundleCardInfo?.prices?.minBundlePrice.amount - b.bundleCardInfo?.prices?.minBundlePrice.amount
            : 0;
        });
    }
    return [];
  }
);

export const hasAttestationSelector = createSelector(
  productPageItemSelector,
  (productItem: Product.ProductItem): boolean => {
    return (
      productItem?.productType === Product.ProductType.CONTRIBUTION &&
      productItem?.variants?.some((variant: any) => variant?.attestationRequired === Product.DonationAttestation.YES)
    );
  }
);

export const hasAttestationVariantsSelector = createSelector(
  [productPageItemSelector, hasAttestationSelector],
  (productItem: Product.ProductItem, hasAttestation: boolean): Product.Variant[] => {
    if (!hasAttestation) return [];
    const trueAttestationVariant: any = productItem?.variants
      ?.filter((variant: any) => {
        if (variant?.attestationRequired === Product.DonationAttestation.YES) {
          return variant;
        }
      })
      .slice(0, 1);

    const falseAttestationVariant: any = productItem?.variants
      ?.filter((variant: any) => {
        if (variant?.attestationRequired === Product.DonationAttestation.NO) {
          return variant;
        }
      })
      .slice(0, 1);

    let hasAttestationVariants = [];

    if (trueAttestationVariant?.length > 0 && falseAttestationVariant?.length > 0) {
      hasAttestationVariants = trueAttestationVariant.concat(falseAttestationVariant);
    }

    return hasAttestationVariants;
  }
);

export const isProductHasUserPriceSelector = createSelector(
  [userApplicableVariantsPricingSelector, userMemberTypeSelector, productPageItemSelector, userRolesSelector],
  (
    variantsPriceInfoForUser: Product.UserApplicableVariantsPricingType,
    userStateName: UserMemberTypes,
    productItem: Product.ProductItem,
    userRoles: UserTypes.MembershipIdsEnum[]
  ): boolean | null => {
    const isContribution: boolean = productItem?.productType === Product.ProductType.CONTRIBUTION;
    const isLoggedOutUser = userStateName === UserMemberTypes.LOGGED_OUT;
    const isNonMemberUser = !userRoles.some((role: UserTypes.MembershipIdsEnum) =>
      [UserTypes.MembershipIdsEnum.MRUSR0001, UserTypes.MembershipIdsEnum.MRUKR0001].includes(role)
    );
    const isMemberUser = !isLoggedOutUser && !isNonMemberUser;

    if (!isContribution || isMemberUser) return true;
    if (isLoggedOutUser || isNonMemberUser) {
      const priceRange: Product.PriceForRole[] = getPriceRangeForNonmembers(variantsPriceInfoForUser, false);
      return Boolean(priceRange.length) ? true : false;
    }
    return false;
  }
);

export const maxProductFreeTrialDaysSelector = createSelector(
  [productPageItemSelector],
  (productItem: Product.ProductItem): number =>
    productItem?.variants?.reduce?.(
      (acc: number, v: Product.Variant) =>
        v.freeTrialEligible && v.freeTrialTerm && v.freeTrialTerm > acc ? v.freeTrialTerm : acc,
      0
    ) || 0
);

export const productHasOnlineVariantsSelector = createSelector([variantOptionPriceSelector], (variants): boolean => {
  return variants.map(variant => variant.format?.key).includes(Product.AvailableFormat.ONLINE_CONFERENCE);
});

export const contributionsSelector = createSelector(
  [
    rootSelector,
    featureTogglesSelector,
    customerProfileFetchedSelector,
    customerProfileFetchSuccessSelector,
    personAccountCountrySelector,
    employerIsInUSSelector,
    isActiveEmployerSelector,
    profileCacheAddressSelector,
  ],
  (
    products: State.Products,
    featureToggle: any,
    isPersonAccountFetched?: boolean,
    isPersonAccountFetchSuccess?: boolean,
    personAccountCountry?: string,
    employerIsInUS?: boolean | undefined,
    isActiveEmployer?: boolean | undefined,
    profileCacheAddress?: any
  ): Product.TransformedProduct[] => {
    if (
      isPersonAccountFetched &&
      isPersonAccountFetchSuccess &&
      (!(
        personAccountCountry === CheckoutCountriesListHash.USA.text ||
        personAccountCountry === CheckoutCountriesListHash.USA.ISOAlpha3Code ||
        personAccountCountry === CheckoutCountriesListHash.USA.value ||
        profileCacheAddress.country === CheckoutCountriesListHash.USA.ISOAlpha3Code
      ) ||
        (!employerIsInUS && isActiveEmployer))
    ) {
      return (
        products.contributions?.filter(
          contribution =>
            contribution.variants[0].attestationRequired !== Product.DonationAttestation.NO &&
            contribution.variants[0].attestationRequired !== Product.DonationAttestation.YES
        ) || []
      );
    }

    return products.contributions || [];
  }
);

export const donationDetailsSelector = createSelector(
  [contributionsSelector, constantsSelector],
  (contributions: Product.TransformedProduct[], constant: null | Contentful.Constants.Constants) => {
    const newDetailsObj = contributions.map((contribution: Product.TransformedProduct) => {
      const slug = contribution.slug as string;
      return { slug: contribution.slug, imageUrl: constant?.[slug], learnMoreUrl: constant?.[`${slug}-donation-url`] };
    });
    return newDetailsObj;
  }
);

export const isContributionsFetchedSelector = createSelector(
  rootSelector,
  (products: State.Products): boolean => products.contributionsFetched
);

export const isContributionsFetchSuccessSelector = createSelector(
  rootSelector,
  (products: State.Products): boolean => products.contributionsFetchSuccess
);

export const isMembershipsLoadingSelector = createSelector(
  rootSelector,
  (products: State.Products): boolean => products.membershipsLoading
);

export const isMembershipsLoadedSelector = createSelector(
  rootSelector,
  (products: State.Products): boolean => products.membershipsLoaded
);

export const productProductListDataSelector = createSelector(productsListDataSelector, lineItem =>
  lineItem?.lineItems
    .filter(
      item =>
        item.productType === Product.ProductType.MEMBERSHIP ||
        item.productType === Product.ProductType.CENTER_MEMBERSHIP
    )
    .map(value => {
      return {
        type: value.name,
        tier: value.variant?.sku,
        membershipType: value.variant?.attributes?.membershipType,
        productSlug: value.productSlug,
      };
    })
);

export const membershipProductListDataSelector = createSelector(productsListDataSelector, lineItem =>
  lineItem?.lineItems.find(item => item.productType === Product.ProductType.MEMBERSHIP)
);

export const flpProductListDataSelector = createSelector(productsListDataSelector, lineItem =>
  lineItem?.lineItems.find(
    item =>
      item.productType === Product.ProductType.FLP && item.subscriptionStatus === Product.ZuoraSubscriptionStatus.ACTIVE
  )
);

export const userHistoricalFlpDataSelector = createSelector(productsListDataSelector, lineItem =>
  lineItem?.lineItems.find(item => item.productType === Product.ProductType.FLP)
);

export const benefitsAccessLinksSelector = createSelector(
  rootSelector,
  (products: State.Products): any => products.benefitsAccessLinks
);

export const webcastPassItemSelector = createSelector(
  rootSelector,
  (products: State.Products): Product.ProductItem => products.webcastPass
);

export const isWebcastPassFetchedSelector = createSelector(
  rootSelector,
  (products: State.Products): boolean => products.isWebcastPassFetched
);

export const lineItemsSelector = createSelector(
  [productsListDataSelector],
  (product?: State.ProductsListData | null): State.LineItem[] => product?.lineItems || []
);

export const eventProductLineItemSelector = createSelector(
  [lineItemsSelector],
  (lineItems: State.LineItem[]): State.LineItem[] => {
    const eventProductLineItem = lineItems?.filter(lineItem => lineItem.productType === Product.ProductType.EVENT);
    return eventProductLineItem;
  }
);

export const hasEventProductItemSelector = createSelector([lineItemsSelector], (lineItems: State.LineItem[]): boolean =>
  lineItems?.some(lineItem => lineItem.productType === Product.ProductType.EVENT)
);

export const hasFcmaCredentialProductSelector = createSelector(productsListDataSelector, productsListData =>
  productsListData?.lineItems?.some(
    lineItem =>
      lineItem.productType === Product.ProductType.CREDENTIAL &&
      lineItem?.variant?.attributes?.credentialKey?.key === Product.CredentialKey.FCMA
  )
);

export const hasMipCredentialProductSelector = createSelector(productsListDataSelector, productsListData =>
  productsListData?.lineItems?.some(
    lineItem =>
      lineItem.productType === Product.ProductType.CREDENTIAL &&
      lineItem?.variant?.attributes?.credentialKey?.key === Product.CredentialKey.MIP
  )
);

export const mipCredentialSelector = createSelector(
  productsListDataSelector,
  (productsListData: State.ProductsListData | null): any => {
    const mipCredentials = productsListData?.lineItems
      ?.filter(
        lineItem =>
          lineItem.productType === Product.ProductType.CREDENTIAL &&
          lineItem?.variant?.attributes?.credentialKey?.key === Product.CredentialKey.MIP
      )
      ?.sort((a: any, b: any) => moment(b.accessEndDate).diff(moment(a.accessEndDate)));

    return mipCredentials?.[0];
  }
);

export const hasMultipleMipCredentialsSelector = createSelector(productsListDataSelector, productsListData => {
  const mipCredentials = productsListData?.lineItems?.filter(
    lineItem =>
      lineItem.productType === Product.ProductType.CREDENTIAL &&
      lineItem?.variant?.attributes?.credentialKey?.key === Product.CredentialKey.MIP
  );
  return mipCredentials !== undefined && mipCredentials?.length > 1;
});

export const existingEntitySelector = createSelector(rootSelector, (products: State.Products): string => {
  return products.existingEntity || '';
});

export const productHasAvailablePreferredCurrencySelector = createSelector(
  [selectedVariantSKUSelector, productCurrencySelector, productPageItemSelector],
  (
    selectedVariantSKU: string | undefined,
    productCurrency: State.ProductCurrency,
    productItem: Product.ProductItem
  ): boolean => {
    // product is bundle type
    if (productItem.productType === Product.ProductType.BUNDLE && productItem.bundleProducts?.length) {
      return productItem.bundleProducts.some(bundleProduct =>
        bundleProduct.variants?.some(variant => variant.prices?.some(price => price.currency === productCurrency.label))
      );
    }

    // product has only one variant
    if (productItem.variants.length === 1) {
      return Boolean(productItem.variants[0].prices?.some(price => price.currency === productCurrency.label));
    }

    // product has multiple variant
    const selectedProductVariant = productItem.variants?.find(product => product.sku === selectedVariantSKU);
    return Boolean(selectedProductVariant?.prices?.some(price => price.currency === productCurrency.label));
  }
);
export const hasDelayedBenefitProductSelector = createSelector(productsListDataSelector, productsListData =>
  productsListData?.lineItems?.some(
    lineItem =>
      (lineItem.productType === Product.ProductType.CREDENTIAL &&
        lineItem?.variant?.attributes?.credentialKey?.key === Product.CredentialKey.FCMA) ||
      lineItem?.variant?.attributes?.credentialKey?.key === Product.CredentialKey.ACMA ||
      lineItem?.variant?.attributes?.credentialKey?.key === Product.CredentialKey.MIP
  )
);

export const epaLevel4Epa2ProductSkuSelector = createSelector(
  [lineItemsSelector, epaLevel4Epa2ProductIdSelector],
  (lineItems, epa2L4): string => {
    const EPA2L4lineItems = lineItems?.filter(item =>
      item.productSlug?.length
        ? item?.productSlug === epa2L4.epa2L4ProductId
        : item.variant?.attributes.examCreditName?.replace(/\s/g, '-').toLowerCase() === epa2L4.epa2L4Name
    );
    return EPA2L4lineItems[0]?.variant?.sku || MembershipTypes.EPALevel.LEVEL4;
  }
);

export const epa2Level7ProductSkuSelector = createSelector(
  [lineItemsSelector, epa2Level7ProductIdSelector],
  (lineItems, epa2L7): string => {
    const EPA2L7lineItems = lineItems?.filter(item =>
      item.productSlug?.length
        ? item?.productSlug === epa2L7.epa2L7ProductId
        : item.variant?.attributes.examCreditName?.replace(/\s/g, '-').toLowerCase() === epa2L7.epa2L7Name
    );
    return EPA2L7lineItems[0]?.variant?.sku || MembershipTypes.EPALevel.LEVEL7;
  }
);

export const isCurrencyToggledByDropdownSelector = createSelector(
  rootSelector,
  (products: State.Products): boolean => products.isCurrencyToggledByDropdown
);

export const hasBridgeItem = createSelector(productsListDataLineItemsSelector, lineItems => {
  const hasBridgeUserGroupId = lineItems?.find(
    lineItem => lineItem?.variant?.attributes?.bridgeUserGroupId || lineItem?.custom?.fields?.bridgeUserGroupId
  );
  const hasBridgeId = lineItems?.find(
    lineItem => lineItem?.variant?.attributes?.bridgeId || lineItem?.custom?.fields?.bridgeId
  );
  return Boolean(hasBridgeId || hasBridgeUserGroupId);
});

export const claimGiftAidSelector = createSelector(
  rootSelector,
  (products: State.Products): boolean => products.claimGiftAid || false
);

export const zuoraAccountsSelector = createSelector(
  rootSelector,
  (products: State.Products): State.ZuoraAccounts => products.entities
);

export const isMultipleZuoraAccountsSelector = createSelector(
  zuoraAccountsSelector,
  (accounts): boolean => !!accounts.association && !!accounts.cima
);

export const hasExistingMembershipPurchaseSelector = createSelector(
  productsListDataSelector,
  (productsListData: State.ProductsListData | null): boolean | undefined =>
    productsListData?.lineItems?.some(
      (lineItem: State.LineItem) =>
        lineItem?.productType === Product.ProductType.MEMBERSHIP || lineItem?.productType === Product.ProductType.FLP
    )
);

export const productListByTypesSelector = createSelector(
  rootSelector,
  (products: State.Products): State.ProductsListByTypes | null => products.productListByTypes
);
