import React from 'react';
import Carousel from 'react-multi-carousel';
import styled from 'styled-components';
import {
  ExternalLinkStyles,
  ExternalLinkStylesDark,
  DefaultInlineLinkStyles,
  InlineLinkOnDarkBgStyles,
} from 'components/atoms/Link/Link';
import { Responsive } from 'semantic-ui-react';
import { Heading, Grid, ButtonLink, ButtonEnums } from 'components/atoms';
import { isColorDark } from 'utils/colorHelpers';
import { Contentful } from 'mxp-schemas';
import { IC_OPEN_IN_NEW_PURPLE, IC_OPEN_IN_NEW_WHITE } from 'resources/images';
import { handleEvent, NAV_CLICK } from 'utils/Analytics';
import { store } from '../../../store';
import { getLocation } from 'connected-react-router';
import { WelcomeBannerCarousel } from '../../../constants/image-dimensions';
interface Props {
  modularBlock: Contentful.ModularContent.WelcomeBlockCarousel;
  headingType: 'h1' | 'h2';
}

interface DotProps {
  index?: number;
  active?: boolean;
  onClick?: () => void;
  blocks?: any[];
}

export const BlockWelcomeCarousel: React.FC<Props> = ({ modularBlock, headingType }) => {
  const { onlyMobile, onlyTablet, onlyComputer } = Responsive;
  const block = modularBlock;
  const rotationFrequency: number = (block?.rotation || 0) * 1000;
  const isBgColorDark: boolean = isColorDark(block?.bgColor);
  const state: State.Root = store?.getState();
  const location: any = getLocation(state);

  const StyledDots = ({ onClick, ...rest }: DotProps) => {
    const { index, active, blocks } = rest;

    const handleCarouselDotClick = (event: React.MouseEvent<HTMLLIElement>) => {
      const currentIndex = index && index + 1;
      const heading = (blocks && blocks[index || 0].heading) || currentIndex;

      handleEvent(
        {
          clickValue: `button:link:int::carousel-dot:${heading}`,
        },
        NAV_CLICK
      );

      if (onClick) onClick();
    };

    return (
      <li className={active ? 'active' : 'inactive'} onClick={handleCarouselDotClick}>
        &#11044;
      </li>
    );
  };

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: onlyComputer.minWidth as number,
      }, //  Number(onlyComputer.minWidth) etc. Prevents the slides to render
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: onlyTablet.maxWidth as number,
        min: onlyTablet.minWidth as number,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: onlyMobile.maxWidth as number,
        min: onlyMobile.minWidth as number,
      },
      items: 1,
    },
  };

  const handleCTAClickEvent =
    (heading: string | undefined, index: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
      const buttonText = (event.target as HTMLAnchorElement).textContent || '';
      const hrefURL = location.pathname || '';
      const carouselHeading = heading || index + 1;

      return handleEvent(
        {
          clickValue: `button:link:int::carousel-cta:${carouselHeading}:${buttonText}:${hrefURL}`,
        },
        NAV_CLICK
      );
    };

  return (
    <StyledContainer>
      <Carousel
        arrows={false}
        responsive={responsive}
        showDots={true}
        customDot={<StyledDots blocks={block?.welcomeBannerSubBlocks} />}
        renderDotsOutside={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={rotationFrequency}
        draggable={false}
        swipeable={false}
      >
        {block?.welcomeBannerSubBlocks?.map((item: Contentful.LandingPages.WelcomeBannerSubBlock, index) => (
          <MainColumn isBgColorDark={isBgColorDark} key={item?.id}>
            <StyledColumn>
              {item?.image && (
                <picture>
                  <source
                    type="image/avif"
                    srcSet={`${item?.image}&w=${WelcomeBannerCarousel.WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.WIDTH}w,
                      ${item?.image}&w=${WelcomeBannerCarousel.LAPTOP_WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.LAPTOP_WIDTH}w,
                      ${item?.image}&w=${WelcomeBannerCarousel.TABLET_WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.TABLET_WIDTH}w,
                      ${item?.image}&w=${WelcomeBannerCarousel.MOBILE_WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.MOBILE_WIDTH}w`}
                    sizes="(max-width: 735px) 100vw, 736px"
                  />

                  <source
                    type="image/webP"
                    srcSet={`${item?.image}&w=${WelcomeBannerCarousel.WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.WIDTH}w,
                      ${item?.image}&w=${WelcomeBannerCarousel.LAPTOP_WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.LAPTOP_WIDTH}w,
                      ${item?.image}&w=${WelcomeBannerCarousel.TABLET_WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.TABLET_WIDTH}w,
                      ${item?.image}&w=${WelcomeBannerCarousel.MOBILE_WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.MOBILE_WIDTH}w`}
                    sizes="(max-width: 735px) 100vw, 736px"
                  />

                  <StyledImage
                    srcSet={`${item?.image}&w=${WelcomeBannerCarousel.WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.WIDTH}w,
                    ${item?.image}&w=${WelcomeBannerCarousel.LAPTOP_WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.LAPTOP_WIDTH}w,
                    ${item?.image}&w=${WelcomeBannerCarousel.TABLET_WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.TABLET_WIDTH}w,
                    ${item?.image}&w=${WelcomeBannerCarousel.MOBILE_WIDTH}&q=${WelcomeBannerCarousel.QUALITY} ${WelcomeBannerCarousel.MOBILE_WIDTH}w`}
                    sizes="(max-width: 735px) 100vw, 736px"
                    src={item?.image}
                    loading="eager"
                    decoding="async"
                    alt={`${item?.heading}`}
                    width="2000"
                    height="1000"
                  />
                </picture>
              )}

              <StyledDiv>
                {item?.headingTag && (
                  <StyledHeadingTag
                    as={headingType}
                    dangerouslySetInnerHTML={{ __html: item?.headingTag }}
                    isBgColorDark={isBgColorDark}
                    tabIndex={0}
                  />
                )}

                {item?.heading && (
                  <StyledHeading
                    as={headingType}
                    dangerouslySetInnerHTML={{ __html: item?.heading }}
                    isBgColorDark={isBgColorDark}
                    tabIndex={0}
                  />
                )}

                {item?.subHeading && (
                  <StyledSubHeading
                    as={headingType}
                    dangerouslySetInnerHTML={{ __html: item?.subHeading }}
                    isBgColorDark={isBgColorDark}
                    tabIndex={0}
                  />
                )}

                {item?.description && (
                  <StyledParagraph
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                    isBgColorDark={isBgColorDark}
                    tabIndex={0}
                  />
                )}

                {item?.ctaText && item?.ctaUrl && (
                  <StyledButtonWrapper>
                    <StyledButtonLink
                      testId="button-welcome-block"
                      to={item?.ctaUrl}
                      external
                      target="_blank"
                      variant={ButtonEnums.variants.secondary}
                      size={ButtonEnums.sizes.large}
                      iconPosition={ButtonEnums.iconPosition.right}
                      overrideAnalytics={true}
                      onClick={handleCTAClickEvent(item?.heading, index)}
                    >
                      <StyledCtaText>{item?.ctaText}</StyledCtaText>
                    </StyledButtonLink>
                  </StyledButtonWrapper>
                )}
              </StyledDiv>
            </StyledColumn>
          </MainColumn>
        ))}
      </Carousel>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .react-multi-carousel-list {
    min-height: ${props => props.theme.pxToRem(100)};
    overflow: hidden;
    align-items: flex-start;
    height: ${props => props.theme.pxToRem(720)} !important;
    ${props => props.theme.mediaQueries.desktopOnly} {
      width: 100%;
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: ${props => props.theme.pxToRem(-20)};
    }
  }
  .react-multi-carousel-item {
    position: absolute;
    margin: 0;
    height: 100% !important;
    align-items: flex-start;
  }
  .react-multi-carousel-dot-list {
    margin-top: 0;
    padding-bottom: ${props => props.theme.pxToRem(70)};
    position: absolute;
    margin-left: -${props => props.theme.pxToRem(12)};
    cursor: pointer;
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-bottom: ${props => props.theme.pxToRem(20)};
    }
  }
  .react-multi-carousel-dot-list > li {
    text-align: center;
    color: ${({ theme }) => theme.colors.neutralWhite};
    opacity: 0.2;
    padding: ${props => props.theme.pxToRem(8)};
    font-size: ${props => props.theme.pxToRem(20)};
    cursor: pointer;
    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.pxToRem(15)};
    }
  }
  .react-multi-carousel-dot-list > li.active {
    opacity: 1;
    transform: scale(1);
  }
  img {
    position: absolute;
    margin-top: 0;
    object-fit: cover;
    width: 100%;
    display: block;
  }
  .rich-text-external-link {
    ${ExternalLinkStylesDark}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${IC_OPEN_IN_NEW_WHITE});
    vertical-align: middle;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
    width: ${props => props.theme.pxToRem(16)};
    height: ${props => props.theme.pxToRem(16)};
  }
`;

const MainColumn = styled.div<{
  isBgColorDark: boolean;
}>`
  .rich-text-external-link {
    ${props => (props.isBgColorDark ? ExternalLinkStylesDark : ExternalLinkStyles)}
  }
  .rich-text-external-link:before {
    content: '';
    background: url(${props => (props.isBgColorDark ? IC_OPEN_IN_NEW_WHITE : IC_OPEN_IN_NEW_PURPLE)});
    vertical-align: middle;
    display: block;
    background-size: auto;
    background-position: center;
    margin: 0 ${props => props.theme.pxToRem(4)} ${props => props.theme.pxToRem(2.5)} 0;
  }
`;

const StyledColumn = styled(Grid.Column)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow: visible;
  display: block;
  object-fit: cover;
  ${props => props.theme.mediaQueries.tabletOnly} {
    margin-left: ${props => props.theme.pxToRem(20)};
  }
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${props => props.theme.pxToRem(720)} !important;
  overflow: hidden;
  display: block;
  object-fit: cover;
`;

const StyledDiv = styled.div`
  display: block;
  position: absolute;
  left: 0;
  height: ${props => props.theme.pxToRem(720)};
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
  ${props => props.theme.mediaQueries.tabletOnly} {
    width: 100%;
  }
`;

const StyledHeadingTag = styled(Heading)<{ isBgColorDark: boolean }>`
  width: ${props => props.theme.pxToRem(700)};
  padding: ${props => props.theme.pxToRem(110)} ${props => props.theme.pxToRem(25)} 0
    ${props => props.theme.pxToRem(152)};
  object-fit: contain;
  display: block;
  background-color: var(--neutral-white);
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.regular};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralWhite};
  b {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
  a {
    ${DefaultInlineLinkStyles};
    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
    width: 95%;
    padding: ${props => props.theme.pxToRem(80)} ${props => props.theme.pxToRem(10)} 0
      ${props => props.theme.pxToRem(10)};
  }
  ${props => props.theme.mediaQueries.tabletOnly} {
    width: 60%;
  }
`;

const StyledHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  width: ${props => props.theme.pxToRem(700)};
  margin: 0;
  padding: ${props => props.theme.pxToRem(3)} 0 0 ${props => props.theme.pxToRem(150)};
  object-fit: contain;
  display: block;
  background-color: var(--neutral-white);
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(40)};
  font-weight: ${props => props.theme.fontWeights.regular};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  color: ${props => props.theme.colors.neutralWhite};
  b {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
  p {
    margin: 0;
    line-height: ${props => props.theme.pxToRem(40)};
  }
  a {
    ${DefaultInlineLinkStyles};
    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xxl};
    padding: ${props => props.theme.pxToRem(1.5)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)};
    width: 95%;
    line-height: ${props => props.theme.pxToRem(40)};
  }
  ${props => props.theme.mediaQueries.tabletOnly} {
    width: 60%;
  }
`;

const StyledSubHeading = styled(Heading)<{ isBgColorDark: boolean }>`
  width: ${props => props.theme.pxToRem(700)};
  margin: 0;
  padding: ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)}
    ${props => props.theme.pxToRem(152)};
  object-fit: contain;
  display: block;
  background-color: var(--neutral-white);
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(32)};
  font-weight: ${props => props.theme.fontWeights.regular};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralWhite};
  b {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
  a {
    ${DefaultInlineLinkStyles};
    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xl};
    padding: ${props => props.theme.pxToRem(1)} ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(5)}
      ${props => props.theme.pxToRem(10)};
    width: 95%;
    line-height: ${props => props.theme.pxToRem(32)};
  }
  ${props => props.theme.mediaQueries.tabletOnly} {
    width: 60%;
  }
`;

const StyledParagraph = styled.div<{ isBgColorDark: boolean }>`
  width: ${props => props.theme.pxToRem(700)};
  padding: ${props => props.theme.pxToRem(5)} 0 0 ${props => props.theme.pxToRem(152)};
  object-fit: contain;
  display: block;
  color: ${props => props.theme.colors.neutralWhite};
  font-family: Roboto;
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  a {
    ${DefaultInlineLinkStyles};
    ${props => props.isBgColorDark && InlineLinkOnDarkBgStyles}
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
    width: 95%;
    padding: ${props => props.theme.pxToRem(12)} ${props => props.theme.pxToRem(12)} 0
      ${props => props.theme.pxToRem(10)};
    text-align: left;
    line-height: ${props => props.theme.pxToRem(18)};
  }
  ${props => props.theme.mediaQueries.tabletOnly} {
    width: 60%;
  }
`;

const StyledButtonWrapper = styled.div`
  object-fit: contain;
  display: block;
  padding-top: ${props => props.theme.pxToRem(32)};
  padding-left: ${props => props.theme.pxToRem(152)};
  text-align: left;
  > a {
    width: ${props => props.theme.pxToRem(265)};
    height: ${props => props.theme.pxToRem(48)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    > a {
      width: ${props => props.theme.pxToRem(185)};
      height: ${props => props.theme.pxToRem(24)};
    }
    text-align: left;
    padding-top: ${props => props.theme.pxToRem(15)};
    padding-left: ${props => props.theme.pxToRem(10)};
    margin-bottom: ${props => props.theme.pxToRem(0)};
  }
`;

const StyledCtaText = styled.div`
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  font-weight: ${props => props.theme.fontWeights.bold};
  ${props => props.theme.mediaQueries.desktopOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    ${props => props.theme.mediaQueries.computerMin} {
      &:focus {
        background-color: ${props => props.theme.colors.primaryLightPurple};
        color: ${props => props.theme.colors.neutralWhite};
        border-color: transparent;
      }
    }
  }
`;
