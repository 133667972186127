import React from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { ContentCard } from 'components/molecules/ContentCard/ContentCard';
import { Grid } from 'components/atoms';
import { formatContentfulString } from 'utils/formatContentfulString';

interface Props {
  title: string;
  description: string | React.ReactNode;
  testId: string;
  titleTestId: string;
  descriptionTestId: string;
  firstButton?: React.ReactNode;
  secondButton?: React.ReactNode;
  isMobileFullWidth?: boolean;
  showBorder?: boolean;
  showBackground?: boolean;
  isLeftAligned?: boolean;
  className?: string;
  product?: State.ContentCardItem | null;
  fromInvoicePage?: boolean;
}

export const PromotionalBanner: React.FC<Props> = React.memo(
  ({
    title,
    description,
    testId,
    titleTestId,
    descriptionTestId,
    firstButton,
    secondButton,
    isMobileFullWidth = false,
    showBorder = false,
    showBackground = false,
    isLeftAligned = false,
    className,
    product,
    fromInvoicePage = false,
  }) => (
    <Container
      className={className}
      data-testid={testId}
      isMobileFullWidth={isMobileFullWidth}
      showBorder={showBorder}
      showBackground={showBackground}
      isLeftAligned={isLeftAligned}
      product={product}
    >
      {!product ? (
        <React.Fragment>
          <StyledTitle tabIndex={0} data-testid={titleTestId}>
            {title}
          </StyledTitle>
          {fromInvoicePage ? (
            <StyledDescription tabIndex={0} data-testid={descriptionTestId}>
              {description}
            </StyledDescription>
          ) : (
            <StyledDescription
              tabIndex={0}
              data-testid={descriptionTestId}
              dangerouslySetInnerHTML={{ __html: formatContentfulString(description as string) }}
            />
          )}
        </React.Fragment>
      ) : (
        // Render promotional banner with product card
        <StyledPromotionalBannerWrapper>
          <StyledPromotionalBannerContent mobile={16} tablet={16} computer={10} verticalAlign="middle">
            <StyledTitle tabIndex={0} data-testid={titleTestId}>
              {title}
            </StyledTitle>
            <StyledDescription
              tabIndex={0}
              data-testid={descriptionTestId}
              dangerouslySetInnerHTML={{ __html: formatContentfulString(description as string) }}
            />
          </StyledPromotionalBannerContent>
          <StyledPromotionalBannerCard mobile={16} tablet={16} computer={6}>
            <ContentCard isAggregationCard contentCardItem={product} testid="promotional-banner-product-card" />
          </StyledPromotionalBannerCard>
        </StyledPromotionalBannerWrapper>
      )}

      {Boolean(firstButton || secondButton) && (
        <Grid stackable>
          <Grid.Row>
            {firstButton && (
              <StyledGridColumn mobile="16" tablet="16" computer={secondButton ? 8 : 16}>
                {firstButton}
              </StyledGridColumn>
            )}
            {secondButton && (
              <StyledGridColumn mobile="16" tablet="16" computer={8}>
                {secondButton}
              </StyledGridColumn>
            )}
          </Grid.Row>
        </Grid>
      )}
    </Container>
  )
);

const Container = styled.div<ThemedStyledProps<Partial<Props>, DefaultTheme>>`
  padding: ${props => props.theme.pxToRem(40)} ${props => props.theme.pxToRem(40)} ${props => props.theme.pxToRem(50)};
  ${props =>
    props.showBorder &&
    `
      border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGrey2};
  `}
  ${props =>
    props.showBackground &&
    `
      background-color: ${props.theme.colors.neutralGrey1};
  `}
  ${props =>
    props.isLeftAligned
      ? `
        text-align: left;
      `
      : `
        text-align: center;
  `}
  margin-bottom: ${props => props.theme.pxToRem(40)};
  ${props =>
    props.product &&
    `
      padding: ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)} 0};
      
      p:first-of-type {
        color: ${props.theme.colors.neutralBlack};
        font-size: ${props.theme.fontSizes.xl};
        font-weight: ${props.theme.fontWeights.light};
        line-height: 1.38;
        margin-bottom: ${props.theme.pxToRem(24)};
      }
      
      p:last-of-type {
        color: ${props.theme.colors.neutralBlack};
        font-size: ${props.theme.fontSizes.m};
        font-weight: ${props.theme.fontWeights.light};
        line-height: 1.33;
      }
  `}
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: ${props => props.theme.pxToRem(40)} ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(48)};
    ${props =>
      props.isMobileFullWidth &&
      `
        margin-right: ${props.theme.pxToRem(-18)};
        margin-left: ${props.theme.pxToRem(-18)};
    `}
  }
`;

const StyledTitle = styled.p`
  margin-bottom: ${props => props.theme.pxToRem(2)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.33;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(6)};
  }
`;

const StyledDescription = styled.p`
  margin-bottom: ${props => props.theme.pxToRem(28)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&&&&&& {
    :not(:only-child):first-of-type {
      text-align: right;
    }
    :not(:only-child):last-of-type {
      text-align: left;
    }
    a,
    button {
      justify-content: space-between;
      max-width: ${props => props.theme.pxToRem(310)};
      height: ${props => props.theme.pxToRem(40)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        max-width: ${props => props.theme.pxToRem(320)};
      }
      > span {
        flex-grow: 1;
      }
      > svg {
        flex: 0 0 ${props => props.theme.pxToRem(24)};
        width: ${props => props.theme.pxToRem(24)};
        height: ${props => props.theme.pxToRem(24)};
      }
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-top: ${props => props.theme.pxToRem(8)} !important;
      padding-bottom: ${props => props.theme.pxToRem(8)} !important;
      :not(:only-child):first-of-type,
      :not(:only-child):last-of-type {
        text-align: center;
      }
    }
  }
`;

const StyledPromotionalBannerWrapper = styled(Grid)`
  &&&&& {
    margin: 0;
  }
`;

const StyledPromotionalBannerContent = styled(Grid.Column)`
  &&&&& {
    padding: ${props => `0 ${props.theme.pxToRem(48)}`};
    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: center;
    }
  }
`;

const StyledPromotionalBannerCard = styled(Grid.Column)`
  &&&&&& {
    padding: 0;
    ${props => props.theme.mediaQueries.mobileOnly} {
      max-width: ${props => props.theme.pxToRem(265)};
      margin: 0 auto !important;
    }
  }
`;
