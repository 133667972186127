import React from 'react';
import { RadioToggle } from 'components/atoms/RadioToggle/RadioToggle';

interface Props {
  label?: string;
  handleChange: (name: string, value: string) => void;
  value?: string;
  filterName: string;
  disabled: boolean;
  className?: string;
}

export const SearchRadioToggle: React.FC<Props> = ({
  label = '',
  handleChange,
  value,
  filterName,
  disabled,
  className,
}) => {
  const handleToggleClick = React.useCallback(() => {
    if (handleChange) handleChange(filterName, 'yes');
  }, [filterName, handleChange]);
  return (
    <RadioToggle
      className={className}
      testId="webcast-pass-radio-toggle"
      handleChange={handleToggleClick}
      checked={value === 'yes'}
      disabled={disabled}
      label={label}
      isBgColorDark
    />
  );
};
