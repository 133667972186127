import React from 'react';

interface HeroImageProps {
  testId?: string;
  sources: Source[];
  alt: string;
  src: string;
  style?: React.CSSProperties;
}

export interface Source {
  id: string;
  media: string;
  srcSet: string;
}

export const HeroImage: React.FC<HeroImageProps> = ({ testId, sources, alt, src, style }) => (
  <picture data-testid={`hero-image-${testId}`} style={{ display: 'block' }}>
    {sources.map(source => (
      <source key={source.id} media={source.media} srcSet={source.srcSet} />
    ))}
    <img src={src} alt={alt} style={style} />
  </picture>
);
