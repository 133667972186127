export enum apprenticeRosterActionNames {
  IS_LOADING = 'apprenticeRoster/LOADiNG',
  FETCH_APPRENTICE_ROSTER = 'apprenticeRoster/FETCH_APPRENTICE_ROSTER',
  GET_APPRENTICEROSTER_DETAILS = 'apprenticeRoster/GET_APPRENTICE_DETAILS',
  TOTAL_RECORDS = 'apprenticeRoster/TOTAL_RECORDS',
  RESET_RECORDS = 'apprenticeRoster/RESET_RECORDS',
  RE_INITIALIZE = 'apprenticeRoster/RE_INITIALIZE',
}

export const CONSTANTS = {
  FILTERS: {
    OFFSET: 0,
    LIMIT: 10,
    INCLUDE_ADD_ONS: false,
  },
  perPage: 10,
};

export const initialState: State.ApprenticeRoster = {
  allRoster: [],
  roster: [],
  isLoading: false,
  totalRecords: 0,
};
