import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { ContentTypes } from 'constants/index';

interface CardThumbnailProps {
  contentType: string;
  timeToConsume?: string;
  fileSize?: string;
  isBody?: boolean;
}

const renderVideoLength = (isBody: boolean, videoLength?: string) => {
  return (
    <StyledFormat data-testid="card-thumbnail-video" isBody={isBody} tabIndex={0}>
      <StyledIcon name="play" />
      {videoLength}
    </StyledFormat>
  );
};

const renderPodcastLength = (isBody: boolean, podcastLength?: string) => {
  return (
    <StyledFormat data-testid="card-thumbnail-podcast" isBody={isBody} tabIndex={0}>
      <StyledIcon name="volume up" />
      {podcastLength}
    </StyledFormat>
  );
};

const renderSize = (isBody: boolean, fileSize?: string) => {
  return (
    <StyledFormat data-testid="card-thumbnail-file-size" isBody={isBody}>
      <StyledIcon name="file" />
      {fileSize}
    </StyledFormat>
  );
};

export const CardThumbnail: React.SFC<CardThumbnailProps> = React.memo(
  ({ timeToConsume, contentType, fileSize, isBody = false }) => {
    const thumbnail = React.useCallback(
      () =>
        ({
          [ContentTypes.VIDEO]: renderVideoLength(isBody, timeToConsume),
          [ContentTypes.PODCAST]: renderPodcastLength(isBody, timeToConsume),
          [ContentTypes.DOWNLOAD]: renderSize(isBody, fileSize),
        }[contentType]),
      [timeToConsume, contentType, fileSize, isBody]
    );
    return (
      <StyledCardThumbnail isBody={isBody} data-testid="card-thumbnail">
        {thumbnail()}
      </StyledCardThumbnail>
    );
  }
);

const StyledCardThumbnail = styled.div<{ isBody: boolean }>`
  display: ${props => (!props.isBody ? 'inline-block' : 'block')};
`;

const StyledFormat = styled.div<{ isBody: boolean }>`
  ${props => `
    font-size: ${props.theme.fontSizes.xxs};
    display: inline-flex;
    `}

  ${props =>
    !props.isBody
      ? `
      font-weight: ${props.theme.fontWeights.light};
      padding: ${props.theme.pxToRem(5)} ${props.theme.pxToRem(10)};
      color: ${props.theme.colors.neutralBlack};
      z-index: 5;
      backdrop-filter: blur(2px);
      opacity: 0.95;
      border-radius: 1px;
      background-color: ${props.theme.colors.neutralWhite};
      height: ${props.theme.pxToRem(24)};
    `
      : `
      font-weight: ${props.theme.fontWeights.regular};
      color: ${props.theme.colors.neutralGrey7};
    `}
`;

const StyledIcon = styled(Icon)`
  &&& {
    padding-right: ${props => props.theme.pxToRem(10)};
  }
`;
