import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  productQty: number | null | undefined;
  handleProductQtyChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  testId: string;
  className?: string;
  availableQuantity?: number;
}
export const QuantityBox: React.FC<Props> = ({
  productQty,
  handleProductQtyChange,
  className,
  testId,
  availableQuantity = 0,
}) => {
  const showError: boolean = Boolean(productQty && productQty > availableQuantity && availableQuantity !== 0);
  return (
    <QuantityWrapper className={className}>
      <Label>Qty:</Label>
      <StyledInput
        name="itemQuantity"
        type="number"
        value={productQty ? productQty : ''}
        data-testid={`itemQuantity--${testId}`}
        onChange={handleProductQtyChange}
        autoFocus={false}
        className={className}
        disabled={availableQuantity < 1}
        showError={showError}
      />
    </QuantityWrapper>
  );
};

const QuantityWrapper = styled.div`
  display: flex;
`;

const Label = styled.p`
  font-size: ${props => props.theme.pxToRem(16)};
  display: inline-block;
  margin-right: ${props => props.theme.pxToRem(10)};
  && {
    margin-bottom: auto;
    margin-top: auto;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: ${props => props.theme.pxToRem(93)};
  }
`;

const StyledInput = styled.input<{
  disabled?: boolean;
  showError?: boolean;
}>`
  &&&& {
    width: ${props => props.theme.pxToRem(56)};
    min-width: ${props => props.theme.pxToRem(56)};
    height: ${props => props.theme.pxToRem(44)};
    padding: ${props => props.theme.pxToRem(10)};
    border: 0;
    font-family: ${props => props.theme.fontFamily};
    border: 1px solid ${props => props.theme.colors.neutralGrey4};
    border-radius: ${props => props.theme.pxToRem(5)};
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.s};
    outline: none;
    -moz-appearance: textfield;
    ${props => props.disabled && `background-color: ${props.theme.colors.neutralGrey2};`}
    ${props => props.disabled && `color: ${props.theme.colors.neutralGrey5}`};
    &::-ms-clear {
      display: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    border-color: ${props => props.showError && props.theme.colors.interfaceRed};
    outline: ${props => props.showError && props.theme.colors.interfaceRed};

    &:focus {
      border-color: ${props => props.showError && props.theme.colors.interfaceRed};
      outline: ${props => props.showError && props.theme.colors.interfaceRed};
    }
  }
`;
