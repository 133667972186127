export const splitPhoneIntoParts = (phoneString: string) => {
  // split number until we get the ff: dial code, phone number, area code, extension
  // sample format of number with area code and extension: (880) +1 8015559864 (ext.805)

  const phoneHasExtension = phoneString.includes(' (ext.');
  const doesPhoneBeginsWithAnAreaCode = phoneString.charAt(0) === '(';

  let splittedPhoneUntilDialCodeAndPhoneNumber = phoneString.split(' (ext.').shift(); // returns string without extension substring
  splittedPhoneUntilDialCodeAndPhoneNumber =
    splittedPhoneUntilDialCodeAndPhoneNumber && splittedPhoneUntilDialCodeAndPhoneNumber.split(') ').pop(); // returns string without areaCode substring

  const dialCode =
    splittedPhoneUntilDialCodeAndPhoneNumber && splittedPhoneUntilDialCodeAndPhoneNumber.split(' ').length > 1
      ? splittedPhoneUntilDialCodeAndPhoneNumber && splittedPhoneUntilDialCodeAndPhoneNumber.split(' ').shift()
      : '';
  const phone =
    (splittedPhoneUntilDialCodeAndPhoneNumber && splittedPhoneUntilDialCodeAndPhoneNumber.split(' ').pop()) || '';

  return {
    phoneNumber: splittedPhoneUntilDialCodeAndPhoneNumber,
    ...(phoneHasExtension && {
      extension: phoneString
        .split(' (ext.')
        .pop() // returns the last element
        ?.replace(/[^a-zA-Z0-9 ]/g, ''), // removes special characters
    }),
    ...(doesPhoneBeginsWithAnAreaCode && {
      areaCode: phoneString
        .split(') ')
        .shift() // returns the first element
        ?.replace(/[^a-zA-Z0-9 ]/g, ''),
    }),
    dialCode,
    phone,
  };
};
