import React from 'react';
import { ContentfulHelpers } from 'mxp-utils';
import { BlockRenderer } from 'components/organisms/BlockRenderer/BlockRenderer';
import { CardListPlaceholder, Divider } from 'components/atoms';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { Content } from 'mxp-schemas';
import { isValidIframe } from 'utils/StringHelpers';
import { PaywallNotificationContainer } from 'components/App/lazy-imports';

interface Props {
  blockRenderToolkitPages: BlockRenderToolkitPages;
}

interface BlockRenderToolkitPages {
  mainColumn: State.ToolkitMainColumn[];
  leftColumn: State.ToolkitLeftColumn[];
  topicSlug?: string;
  topicName?: string;
  subtopicSlug?: string;
  loading?: boolean;
  hasContent?: boolean;
  targetContainer?: any;
  containerContentRef?: any;
  isMobile?: boolean;
  isAuth: boolean | null;
  isUserOnBoarded: boolean;
  contentItem?: State.ContentItem | null;
  contentIsExclusive: Common.ContentExclusive;
  isStaticLandingPageFetched?: boolean;
  isStaticLandingPage?: boolean;
}

export const BlockToolkitPageRenderer: React.FC<Props> = ({ blockRenderToolkitPages }) => {
  const checkNextItemForAccordion = React.useCallback(
    (idx: number) => {
      const item = blockRenderToolkitPages.mainColumn[idx];
      const isToolkitPageBlock =
        item &&
        (item as any).contentType &&
        (item as any).contentType === ContentfulHelpers.CONTENT_TYPES.TOOLKIT_PAGE_BLOCK;
      if (!isToolkitPageBlock) {
        return false;
      }
      return (item as State.ToolkitPageBlock).accordion;
    },
    [blockRenderToolkitPages.mainColumn]
  );

  const renderMainColumn = (mainColumn: any[], isLocked: boolean): React.ReactNode => {
    return mainColumn?.map((item: State.ToolkitMainColumn, idx: number) => {
      if (!item) return null;

      const type = item.contentType;

      if (type) {
        const nextId: number = idx + 1;
        const showDivider: boolean = idx !== mainColumn.length - 1 ? !checkNextItemForAccordion(nextId) : false;

        switch (type) {
          case ContentfulHelpers.CONTENT_TYPES.PROMOTIONAL_COMPONENT_BLOCK:
          case ContentfulHelpers.CONTENT_TYPES.FEATURED_CONTENT_BLOCK:
          case ContentfulHelpers.CONTENT_TYPES.TOOLKIT_PAGE_BLOCK:
            const block = {
              block: item as State.ToolkitMainColumn,
              idx,
              isFullWidth: type === ContentfulHelpers.CONTENT_TYPES.PROMOTIONAL_COMPONENT_BLOCK,
              showDivider,
              topicSlug: blockRenderToolkitPages.topicSlug,
              topicName: blockRenderToolkitPages.topicName,
              subtopicSlug: blockRenderToolkitPages.subtopicSlug,
              key: 'main-promotional-section',
            };
            return <BlockRenderer blockRenderToolkit={block} key={(item as any)?.id} />;
          default:
            const landingPageBlock = {
              block: item as any,
              isFullPagePagination: false,
              targetContainer: blockRenderToolkitPages.targetContainer,
              containerContentRef: blockRenderToolkitPages.containerContentRef,
              isMobile: blockRenderToolkitPages.isMobile,
              isAuth: blockRenderToolkitPages.isAuth,
              isUserOnBoarded: blockRenderToolkitPages.isUserOnBoarded,
              isToolkitPage: true,
              isLocked,
              isStaticLandingPageFetched: blockRenderToolkitPages.isStaticLandingPageFetched,
              isStaticLandingPage: blockRenderToolkitPages.isStaticLandingPage,
            };
            return (
              <React.Fragment key={(item as any)?.id}>
                <BlockRenderer blockRenderLandingPage={landingPageBlock} />
                {showDivider && <StyledDividerLandingPageBlock />}
              </React.Fragment>
            );
        }
      }
      return null;
    });
  };

  const renderLeftColumn = (leftColumn: any[]): React.ReactNode => {
    return leftColumn
      .filter(item => item)
      ?.map((item, idx: number) => {
        const showDivider: boolean = leftColumn.length - 1 > idx;

        const type = (item as any).contentType;
        if (type) {
          const block = {
            block: item as State.ToolkitLeftColumn,
            idx,
            isFullWidth: type === ContentfulHelpers.CONTENT_TYPES.PROMOTIONAL_COMPONENT_BLOCK,
            showDivider,
            topicSlug: blockRenderToolkitPages.topicSlug,
            topicName: blockRenderToolkitPages.topicName,
            subtopicSlug: blockRenderToolkitPages.subtopicSlug,
            key: 'left-promotional-section',
          };
          return <BlockRenderer blockRenderToolkit={block} />;
        }
        return null;
      });
  };

  const renderPage = (): React.ReactNode => {
    if (
      blockRenderToolkitPages &&
      (blockRenderToolkitPages.leftColumn.length || blockRenderToolkitPages.mainColumn.length)
    ) {
      const showLeftColumn = !!blockRenderToolkitPages.leftColumn.length;
      // get by contentItem if is locked
      const isLocked: boolean = blockRenderToolkitPages.contentItem
        ? blockRenderToolkitPages.contentItem.isLocked === Content.ContentLockStatus.LOCKED
        : false;

      const blueToad: any = blockRenderToolkitPages.mainColumn.find(
        (item: any) =>
          item.contentType === ContentfulHelpers.CONTENT_TYPES.BLUETOAD_PAGETURNER_BLOCK &&
          isValidIframe(item.description)
      );
      return (
        <StyledContainer>
          {showLeftColumn && <ExtraSpace />}
          <Grid stackable>
            <Grid.Row>
              <StyledGridColumn computer={showLeftColumn ? 9 : 16} tablet={16} data-testid="toolkit-main-column">
                <Wrapper isLocked={isLocked}>
                  {showLeftColumn ? (
                    <>
                      <StyledMainColumnContainer>
                        {renderMainColumn(blockRenderToolkitPages.mainColumn, isLocked)}
                        {isLocked && blueToad && (
                          <>
                            <ExtraSpace />
                            <PaywallNotificationContainer
                              isContentExclusive={blockRenderToolkitPages.contentIsExclusive}
                              image={blueToad?.accessRestrictionImage}
                            />
                          </>
                        )}
                      </StyledMainColumnContainer>
                    </>
                  ) : (
                    <>
                      {renderMainColumn(blockRenderToolkitPages.mainColumn, isLocked)}
                      {isLocked && blueToad && (
                        <>
                          <ExtraSpace />
                          <PaywallNotificationContainer
                            isContentExclusive={blockRenderToolkitPages.contentIsExclusive}
                            image={blueToad?.accessRestrictionImage}
                          />
                        </>
                      )}
                    </>
                  )}
                </Wrapper>

                {blockRenderToolkitPages.loading && !blockRenderToolkitPages.hasContent && <CardListPlaceholder />}
                <ExtraSpace />
              </StyledGridColumn>
              {showLeftColumn && (
                <StyledLeftColumnContainer>
                  <Grid.Column computer={4} tablet={16} data-testid="toolkit-left-column">
                    {renderLeftColumn(blockRenderToolkitPages.leftColumn)}
                  </Grid.Column>
                </StyledLeftColumnContainer>
              )}
            </Grid.Row>
          </Grid>
        </StyledContainer>
      );
    }
    return null;
  };

  return renderPage() as JSX.Element;
};
const ExtraSpace = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(30)};
`;

const Wrapper = styled.div<any>`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledContainer = styled.div`
  overflow: hidden;
`;

const StyledMainColumnContainer = styled.div`
  padding: 0 3%;
`;

const StyledLeftColumnContainer = styled.div`
  &&&& {
    padding: 0 0 0 16%;
    width: 32%;
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: 0 5%;
      width: 100%;
    }
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  ${props => props.theme.mediaQueries.desktopOnly} {
    order: 1;
  }
`;

const StyledDividerLandingPageBlock = styled(Divider)`
  &&& {
    margin: ${props => `${props.theme.pxToRem(40)} 0 ${props.theme.pxToRem(40)} 0`};
  }
  &&.ui.divider {
    width: 100%;
    margin: 0;
  }
`;
