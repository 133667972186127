import { trendSlugIdMapSelector } from './selectors';
import { emptyObject } from 'utils/emptyItems';

// ------------------------------------
// Helpers
// ------------------------------------

/*
 * Convert industry filter slugs to industry ids
 */
export const convertTrendsSlugsToIds = (trends: string | string[], state: State.Root): string[] | undefined => {
  if (!trends) return;
  const trendsSlugs: string[] = Array.isArray(trends) ? trends : [trends];
  // Call trendsSlugIdMapSelector only if trendsSlugs exist
  const trendsSlugIdMap: { [key: string]: string } = trendSlugIdMapSelector(state);
  // Get trends ids array from slugs if exist
  return trendsSlugs.map(slug => trendsSlugIdMap[slug]).filter(Boolean);
};

/*
 * slug id hash map creator
 */
export const slugIdMapCreator = (trends: any[] | null): { [key: string]: string } => {
  if (!trends) return emptyObject;
  return trends.reduce((acc: any, trend: State.Trend) => {
    acc[trend.slug] = trend.id;
    return acc;
  }, {});
};
