import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { User, MembershipTypes } from 'mxp-schemas';
import {
  flpPlatformCGMALinkConfigSelector,
  cimaExamQualificationNamesSelector,
  getConstantByKeySelector,
} from 'modules/app/selectors';
import { Badge, BadgeItemProps, BadgeStatus, FeedTargetableLink } from 'components/atoms';
import { pathwaySwitchedSelector } from 'modules/user/selectors';
import { CONSTANTS } from 'modules/app/constants';
import { isLastMembershipLapsedSelector } from 'modules/membership/selectors';
import { Admin as AdminUtils } from 'mxp-utils';

interface Props {
  pathway: MembershipTypes.Pathway | null | undefined;
  qualificationLevels: State.StudentProgression['qualificationLevels'];
}

export const CimaDashboardCertificate: React.FC<Props> = ({ pathway, qualificationLevels }) => {
  const examRelatedInformationLink = useSelector(
    getConstantByKeySelector(CONSTANTS.CIMA_EXAMS_DASHBOARD.EXAM_RELATED_INFORMATION)
  );
  const examPoliciesLink = useSelector(getConstantByKeySelector(CONSTANTS.CIMA_EXAMS_DASHBOARD.EXAM_POLICIES));
  const epaInformation = useSelector(getConstantByKeySelector(CONSTANTS.CIMA_EXAMS_DASHBOARD.EPA_INFORMATION));
  const flpPlatformLink = useSelector(flpPlatformCGMALinkConfigSelector);
  const cimaExamQualificationNames = useSelector(cimaExamQualificationNamesSelector);
  const isPathwaySwitched = useSelector(pathwaySwitchedSelector);
  const isMembershipLapsed = useSelector(isLastMembershipLapsedSelector);
  const isApprentice = AdminUtils.isApprenticePathway(pathway as string);

  // Will check if user is Switched to Level 7 EPA2 from 4
  // conditional filtering.
  const switchedToL7 = pathway === MembershipTypes.Pathway.APPRENTICE_L7 && isPathwaySwitched;
  const certificateBadges = qualificationLevels
    ?.filter(qualificationLevel =>
      switchedToL7 ? qualificationLevel?.name === MembershipTypes.CimaQualificationLevelPathValue.EPA4 : true
    )
    .map(qualificationLevel => {
      // Will display default qualification name if value is undefined
      // The name value will be based on values provided in Contentful constant cima_exam_qualification_names
      const badgeName =
        cimaExamQualificationNames !== undefined && cimaExamQualificationNames[qualificationLevel?.name] !== undefined
          ? cimaExamQualificationNames[qualificationLevel?.name].label
          : qualificationLevel?.name;

      const badge: BadgeItemProps = {
        name: badgeName,
        status: BadgeStatus.PROCESSING,
      };

      if (qualificationLevel.status === User.QualificationLevelStatus.Completed) {
        badge.status = BadgeStatus.COMPLETED;
      }

      if (
        [User.QualificationLevelStatus.Locked, User.QualificationLevelStatus.Exempted].includes(
          qualificationLevel.status
        )
      ) {
        badge.status = BadgeStatus.NOT_ELIGIBLE;
      }

      return badge;
    });

  const isFLP = pathway === MembershipTypes.Pathway.FLP;
  const isCimaPQ = pathway === MembershipTypes.Pathway.PQ;

  return (
    <>
      <StyledCertContainer>
        <StyledFlexItem>
          {certificateBadges && (switchedToL7 || isFLP || isCimaPQ) && <Badge data={certificateBadges} />}
        </StyledFlexItem>

        <StyledFlexItem>
          <StyledUseful>USEFUL LINKS</StyledUseful>
          <FeedTargetableLink
            testId="exam-related-information-link"
            title={'Exam information'}
            link={examRelatedInformationLink}
            isNoMargin={true}
            iconPurple={true}
            colorBlack={true}
            isNewTab={true}
          />
          <FeedTargetableLink
            testId="exam-policies-link"
            title={'Exam policies'}
            link={examPoliciesLink}
            isNoMargin={true}
            iconPurple={true}
            colorBlack={true}
            isNewTab={true}
          />

          {isApprentice && (
            <FeedTargetableLink
              testId="epa-information"
              title={'EPA information'}
              link={epaInformation}
              isNoMargin={true}
              iconPurple={true}
              colorBlack={true}
              isNewTab={true}
            />
          )}

          {pathway === MembershipTypes.Pathway.FLP && !isMembershipLapsed && (
            <FeedTargetableLink
              testId="flp-platform-link"
              title={'FLP Platform'}
              link={flpPlatformLink}
              isNoMargin={true}
              iconPurple={true}
              colorBlack={true}
            />
          )}
        </StyledFlexItem>
      </StyledCertContainer>
    </>
  );
};

const StyledCertContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${props => props.theme.mediaQueries.mobileOnly} {
    flex-direction: column;
    margin-right: ${props => props.theme.pxToRem(6)};
  }

  ${props => props.theme.mediaQueries.tabletOnly} {
    margin: 0 ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(8)};
  }

  ${props => props.theme.mediaQueries.desktopOnly} {
    margin: 0 ${props => props.theme.pxToRem(16)} 0 ${props => props.theme.pxToRem(8)};
  }

  width: auto;
  min-height: ${props => props.theme.pxToRem(160)};
  border-style: solid none solid none;
  border-width: ${props => props.theme.pxToRem(1)};
  border-color: ${props => props.theme.colors.neutralGrey3};
`;

const StyledFlexItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.theme.pxToRem(25)} ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(20)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(5)};
  }
`;

const StyledUseful = styled.span`
  margin-bottom: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(1.3)};
  color: ${props => props.theme.colors.neutralGrey6};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(0)};
  }
`;
