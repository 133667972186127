import { createAction } from 'redux-actions';
import {
  GET_EXEMPTION_REFERENCE,
  MUTATE_PERSON_LEVEL_EXEMPTION,
  MUTATE_PROMOCODE_TRIGGERED_EXEMPTION,
  MUTATE_LOWER_EXEMPTION,
  MUTATE_QUALIFICATIONS,
  QUERY_EXEMPTION_LEVEL_REFERENCE_BY_NAME,
} from 'mxp-graphql-queries';
import { Salesforce } from 'mxp-schemas';
import { Dispatch } from 'redux';
import { getByProfBodyAndQualName, validateYearQualify } from './helpers';
import { default as request } from 'utils/GraphQLClient';
export const fetchProfessionalQualification: any = createAction(
  'exempReference/FETCH_PROFESSIONAL_QUALIFICATION',
  (type: string) => () => {
    return request(GET_EXEMPTION_REFERENCE, { type });
  }
);

export const fetchEducationQualification: any = createAction(
  'exempReference/FETCH_EDUCATIONAL_QUALIFICATION',
  (type: string) => () => {
    return request(GET_EXEMPTION_REFERENCE, { type });
  }
);

export const fetchWorkExperience: any = createAction(
  'exempReference/FETCH_WORK_EXPERIENCE',
  (type: string, setEmpty?: boolean) => () => {
    return setEmpty ? {} : request(GET_EXEMPTION_REFERENCE, { type });
  }
);

export const getExemptionByRecordTypeId: any = createAction(
  'exempReference/GET_EXEMPTION_BY_RECORD_TYPE_ID',
  (addedParams: object, type: string = Salesforce.ExemptionRecordType.EDUCATIONAL_QUALIFICATION) =>
    () => {
      return request(GET_EXEMPTION_REFERENCE, { type, addedParams });
    }
);

export const createPersonLevelExemption: any = createAction(
  'createPersonLevelExemption/CREATE_PERSON_LEVEL_EXEMPTION',
  (
      personAccountId: string,
      professionalQualificationData: State.ProfessionalQualification[] | undefined,
      educationalQualificationData: State.EducationalQualification[] | undefined,
      workExperienceData: State.WorkExperience[] | undefined
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const { allProfessionalExperienceReference } = state?.exemptionProfessional;

      const profQualificationData = !!professionalQualificationData?.length
        ? professionalQualificationData.map((professional: any) => {
            const getExemptKeyId = getByProfBodyAndQualName(
              allProfessionalExperienceReference,
              professional?.nameOfProfessionalBody,
              professional?.nameofQualifications
            );
            const addExemptionKey: boolean = validateYearQualify(
              professional?.yearQualify,
              `${new Date(getExemptKeyId?.SFS_EXR_EligibleFromYear__c).getFullYear()}`,
              `${new Date(getExemptKeyId?.SFS_EXR_EligibleUntilYear__c).getFullYear()}`
            );

            return { ...professional, exemptionKey: addExemptionKey ? getExemptKeyId?.Id : '' };
          })
        : [];

      return request(MUTATE_PERSON_LEVEL_EXEMPTION, {
        personAccountId,
        professionalQualificationData: profQualificationData,
        educationalQualificationData,
        workExperienceData,
      });
    }
);

export const updateLowerExemption: any = createAction(
  'updateLowerExemption/UPDATE_LOWER_EXEMPTION',
  (exemptionLevelKey: string) => () => {
    return request(MUTATE_LOWER_EXEMPTION, {
      exemptionLevelKey,
    });
  }
);

export const getExemptionLevelReferenceByName: any = createAction(
  'exemptionLevelRef/FETCH_EXEMPTION_LEVEL',
  (name: string) => async () => {
    return request(QUERY_EXEMPTION_LEVEL_REFERENCE_BY_NAME, { name });
  }
);

export const saveQualifications: any = createAction(
  'saveQualifications/SAVE_QUALIFICATIONS',
  (
      personAccountId: string,
      professionalQualificationData: State.ProfessionalQualification[] | undefined,
      educationalQualificationData: State.EducationalQualification[] | undefined,
      recordType: string
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const { allProfessionalExperienceReference } = state?.exemptionProfessional;
      const qualificationData = !!professionalQualificationData?.length
        ? professionalQualificationData.map((professional: any) => {
            const getExemptKeyId = getByProfBodyAndQualName(
              allProfessionalExperienceReference,
              professional?.nameOfProfessionalBody,
              professional?.nameofQualifications
            );
            const addExemptionKey: boolean = validateYearQualify(
              professional?.yearQualify,
              `${new Date(getExemptKeyId?.SFS_EXR_EligibleFromYear__c).getFullYear()}`,
              `${new Date(getExemptKeyId?.SFS_EXR_EligibleUntilYear__c).getFullYear()}`
            );

            return { ...professional, exemptionKey: addExemptionKey ? getExemptKeyId?.Id : '' };
          })
        : [];

      return request(MUTATE_QUALIFICATIONS, {
        personAccountId,
        professionalQualificationData: qualificationData,
        educationalQualificationData,
        recordType,
      });
    }
);

export const createPromoCodeTriggeredExemption: any = createAction(
  'createPromoCodeTriggeredExemption/CREATE_PROMO_CODE_EXEMPTION',
  (
      personAccountId: string,
      appliedPromoCodeCustomExemption: {
        promocode: string;
        exemptionGiveaway: string[];
        exemptionTakeaway: string[];
        pathway?: string;
      } | null
    ) =>
    () => {
      return request(MUTATE_PROMOCODE_TRIGGERED_EXEMPTION, {
        personAccountId,
        promoCode: appliedPromoCodeCustomExemption?.promocode,
        giveawayExemption: appliedPromoCodeCustomExemption?.exemptionGiveaway,
        takeawayExemption: appliedPromoCodeCustomExemption?.exemptionTakeaway,
        pathway: appliedPromoCodeCustomExemption?.pathway,
      });
    }
);
