import React, { RefObject } from 'react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components/macro';
import { Product } from 'mxp-schemas';
import { Scroller, availableFormatToLabel, MomentHelpers, priceToFloat } from 'utils';
import { ButtonEnums, RadioButton, Button } from 'components/atoms';
import { QuantityBox } from 'components/atoms/QuantityBox/QuantityBox';
import { WarningNeedToAdjustQuantity, WarningOutOfStock, WarningLowStock } from './QuantityWarnings';
import { PRODUCT_CART_STATUS } from 'constants/index';
import { numberToWords } from './helpers/numberToWords';

interface Props {
  calculatedAccessDuration: string;
  isLowOnStock: boolean;
  isMoreThanAvailable: boolean;
  isMultiDayWebcast: boolean;
  isPhPrSubscription?: boolean;
  isPhSubscriptionMagazine?: boolean;
  isProductWithMultipleOptions: boolean;
  isUpdateButtonsShown: boolean;
  isWebcastSeries: boolean;
  productButtonStatus: PRODUCT_CART_STATUS;
  productChildrenInfoScrollTarget: RefObject<HTMLDivElement>;
  productType: Product.ProductType;
  quantityValue: number | null;
  selectedVariantSKU?: string;
  sessions?: number | null;
  tempQuantityValue: number | null;
  thirdPartyLink?: string;
  variantDateTime?: Product.VariantDateTimeInfo[] | null;
  variantOptionPrice?: Product.VariantWithPriceInfo[] | null;
  changeQuantity: (e: { target: HTMLInputElement }) => void;
  handleSelectionChange: (variantSKU: string, isFreeTrial?: boolean) => () => void;
  handleToggleSummary: () => void;
  renderQtyUpdateButtons: () => void;
  renderUpdateQuantity: (e: { target: HTMLInputElement }) => void;
  setAvailableQuantity: (availableQuantity: number) => void;
  standingOrderValue: () => void;
  isFreeTrialSelected: boolean;
  subscriptionProductType: Product.KeyLabel;
  previousSubscriptionType: Product.PreviousSubscriptionType | null;
  userLocation?: { country: string };
  isUserSuspendedByEthics?: boolean;
}

export const WebcastOrConferenceOrExamVariants: React.FC<Props> = ({
  calculatedAccessDuration,
  isLowOnStock,
  isMoreThanAvailable,
  isMultiDayWebcast,
  isPhPrSubscription,
  isPhSubscriptionMagazine,
  isProductWithMultipleOptions,
  isUpdateButtonsShown,
  isWebcastSeries,
  productButtonStatus,
  productChildrenInfoScrollTarget,
  productType,
  quantityValue,
  selectedVariantSKU,
  sessions,
  tempQuantityValue,
  thirdPartyLink,
  variantDateTime,
  variantOptionPrice,
  changeQuantity,
  handleSelectionChange,
  handleToggleSummary,
  renderQtyUpdateButtons,
  renderUpdateQuantity,
  setAvailableQuantity,
  standingOrderValue,
  isFreeTrialSelected,
  subscriptionProductType,
  previousSubscriptionType,
  isUserSuspendedByEthics,
}) => {
  const hasMultipleVariants = (
    list: Product.VariantDateTimeInfo[] | Product.VariantWithPriceInfo[] | undefined | null
  ) => Boolean(list?.length);
  const sessionsText: string = sessions ? (sessions < 10 ? numberToWords(sessions) : sessions.toString()) : '';
  const selectText: string =
    isMultiDayWebcast && sessionsText
      ? `This webcast includes ${sessionsText} sessions. Select from the following options:`
      : isWebcastSeries && sessionsText
      ? `This webcast series contain ${sessionsText} sessions.`
      : 'Select from the following options:';

  const hasSubscribedToFullProduct: boolean =
    previousSubscriptionType === Product.PreviousSubscriptionType.SUBSCRIBED_TO_FULL_PRODUCT;
  const hasSubscribedToFreeTrial: boolean =
    previousSubscriptionType === Product.PreviousSubscriptionType.SUBSCRIBED_TO_FREE_TRIAL;
  const hasPurchasedFreeTrialInLast12Months: boolean =
    previousSubscriptionType === Product.PreviousSubscriptionType.PURCHASED_FREE_TRIAL_IN_LAST_12MONTHS;

  const shouldDisableOptionForPreviouslyPurchasedSubscriptions = (isFreeTrial: boolean) =>
    isFreeTrial && hasPurchasedFreeTrialInLast12Months;

  const disabledPreviouslyPurchasedSubscriptionMessage = (isFreeTrial: boolean): string | null => {
    if (!hasSubscribedToFreeTrial && !hasSubscribedToFullProduct && !hasPurchasedFreeTrialInLast12Months) {
      return null;
    }
    if (!isFreeTrial && hasSubscribedToFreeTrial) {
      return 'You already have an active free trial to this product';
    }
    if (isFreeTrial && hasSubscribedToFullProduct) {
      return 'You already have full access to this product';
    }
    if (isFreeTrial && hasPurchasedFreeTrialInLast12Months) {
      return 'You have used your annual free trial allowance for this product';
    }
    return null;
  };
  const scrollToProductChildrenInfoHandler = React.useCallback(() => {
    Scroller.scrollToTarget(productChildrenInfoScrollTarget, { useWindow: true });
    handleToggleSummary();
  }, [productChildrenInfoScrollTarget, handleToggleSummary]);

  const displayMultipleOptions =
    isProductWithMultipleOptions || !!(variantOptionPrice?.length && variantOptionPrice?.length > 1);

  return (
    <>
      {isWebcastSeries && (
        <StyledWebcastSeriesDiv data-testid="purchase-summary-webcast-series-info">
          <StyledWebcastSeriesText data-testid="purchase-summary-access-duration">
            {calculatedAccessDuration} access
          </StyledWebcastSeriesText>
          <StyledWebcastSeriesText data-testid="purchase-summary-select-text">{selectText}</StyledWebcastSeriesText>
          <StyledWebcastSeriesText data-testid="purchase-summary-next-start-date-text">
            {sessions && sessions > 0
              ? `Next start date: ${variantDateTime?.[0]?.dateRange || variantDateTime?.[0]?.startDate || ''}`
              : `Next start date: To be announced`}
          </StyledWebcastSeriesText>
        </StyledWebcastSeriesDiv>
      )}
      {(hasMultipleVariants(variantDateTime || variantOptionPrice) || (isMultiDayWebcast && sessions)) && (
        <SelectTextStyled data-testid="purchase-summary-select-text">{selectText}</SelectTextStyled>
      )}
      {isMultiDayWebcast && (
        <StyledMoreButton
          testId="view-schedule-sessions"
          variant={ButtonEnums.variants.link}
          size={ButtonEnums.sizes.medium}
          onClick={scrollToProductChildrenInfoHandler}
        >
          View schedule
        </StyledMoreButton>
      )}
      {isWebcastSeries && (
        <StyledMoreButtonWebcastSeries
          testId="view-schedule-sessions"
          variant={ButtonEnums.variants.link}
          size={ButtonEnums.sizes.medium}
          onClick={scrollToProductChildrenInfoHandler}
        >
          View schedule
        </StyledMoreButtonWebcastSeries>
      )}
      {productType === Product.ProductType.EVENT &&
        variantOptionPrice?.map((item: Product.VariantWithPriceInfo, key) => {
          const isSelected = item.sku === selectedVariantSKU;
          const startDate = MomentHelpers.getLocalTimeWithTimezone(item?.startDateTime, item?.timeZone);
          const endDate = MomentHelpers.getLocalTimeWithTimezone(item?.endDateTime, item?.timeZone);
          return (
            <StyledVariant
              key={key}
              onClick={handleSelectionChange(item.sku || '')}
              isLast={key === variantOptionPrice.length - 1}
            >
              {displayMultipleOptions && <StyledRadioButton testId={`${key}-radio`} checked={isSelected} />}
              <StyledOptionTexts grayedOut={Boolean(thirdPartyLink) || !isSelected}>
                <StyledDate data-testid="purchase-summary-start-date">{startDate.localDate}</StyledDate>
                <StyledTime data-testid="purchase-summary-date-time-variant-info">
                  {startDate.localTime} - {endDate.localTimeWithTZ}
                </StyledTime>
              </StyledOptionTexts>
            </StyledVariant>
          );
        })}
      {productType === Product.ProductType.CONFERENCE &&
        variantOptionPrice?.map((item: Product.VariantWithPriceInfo, key) => {
          const isSelected = item.sku === selectedVariantSKU;
          return (
            <StyledVariant
              key={key}
              onClick={handleSelectionChange(item.sku || '')}
              isLast={key === variantOptionPrice.length - 1}
            >
              {displayMultipleOptions && <StyledRadioButton testId={`${key}-radio`} checked={isSelected} />}
              <StyledOptionTexts grayedOut={Boolean(thirdPartyLink) || !isSelected}>
                <StyledDate data-testid="purchase-summary-start-date">
                  {item.format ? availableFormatToLabel(item.format.key) : ''}
                  {item.optionalText ? ` - ${item.optionalText}` : ''}
                </StyledDate>
                <StyledTime data-testid="purchase-summary-date-time-variant-info">
                  {!isUserSuspendedByEthics
                    ? item.priceInfo && item.priceInfo.priceFinal && item.priceInfo.priceFinal.formattedPrice
                    : item?.priceInfo?.priceFull?.formattedPrice}{' '}
                </StyledTime>
              </StyledOptionTexts>
            </StyledVariant>
          );
        })}
      {productType === Product.ProductType.WEBCAST &&
        !isWebcastSeries &&
        variantDateTime &&
        variantDateTime.map((item, key) => {
          const { startDate, startTime, endTime, timeZone } = item;
          const isSelected = item.sku === selectedVariantSKU;
          return (
            <StyledVariant
              key={key}
              onClick={handleSelectionChange(item.sku)}
              isLast={key === variantDateTime.length - 1}
            >
              {displayMultipleOptions && <StyledRadioButton testId={`${key}-radio`} checked={isSelected} />}
              <StyledOptionTexts grayedOut={!isSelected}>
                <StyledDate data-testid="purchase-summary-start-date">
                  {isMultiDayWebcast && `Starts `}
                  {startDate}
                </StyledDate>
                <StyledTime data-testid="purchase-summary-date-time-variant-info">
                  {startTime} &ndash; {endTime} {timeZone}
                </StyledTime>
              </StyledOptionTexts>
            </StyledVariant>
          );
        })}
      {productType === Product.ProductType.EXAM &&
        variantOptionPrice &&
        variantOptionPrice.map((item: Product.VariantWithPriceInfo, key) => {
          const isSelected: boolean = item.sku === selectedVariantSKU;
          const examTypeLabel: string = item?.examType?.label ? ` - ${item.examType.label}` : '';

          return (
            <StyledVariant
              key={key}
              onClick={handleSelectionChange(item.sku || '')}
              isLast={key === variantOptionPrice.length - 1}
            >
              {displayMultipleOptions && <StyledRadioButton testId={`${key}-radio`} checked={isSelected} />}
              <StyledOptionTexts grayedOut={!isSelected}>
                <StyledDate data-testid="purchase-summary-variant-label">
                  {item?.format?.label} {examTypeLabel}
                </StyledDate>
                <StyledTime data-testid="purchase-summary-variant-price">
                  {!isUserSuspendedByEthics
                    ? item?.priceInfo?.priceFinal?.formattedPrice
                    : item?.priceInfo?.priceFull?.formattedPrice}{' '}
                </StyledTime>
              </StyledOptionTexts>
            </StyledVariant>
          );
        })}
      {([Product.ProductType.COURSE, Product.ProductType.PUBLICATION].includes(productType) || isPhPrSubscription) &&
        variantOptionPrice?.map((item: Product.VariantWithPriceInfo, key) => {
          const isSelected: boolean = item.sku === selectedVariantSKU && item.isFreeTrial === isFreeTrialSelected;
          const isInStock: boolean = !!item?.availability?.isOnStock;
          const disableProduct =
            Boolean(item?.isPhysicalProduct && !isInStock) ||
            shouldDisableOptionForPreviouslyPurchasedSubscriptions(Boolean(item.isFreeTrial));
          const disabledMessage: string | null = disabledPreviouslyPurchasedSubscriptionMessage(
            Boolean(item.isFreeTrial)
          );
          const isPhProductLowOnStock = Boolean(
            isLowOnStock && isSelected && item?.availability?.isOnStock && !isMoreThanAvailable
          );
          const isPhProductIsOutOfStock = Boolean(!item?.availability?.isOnStock && item.isPhysicalProduct);
          const itemAvailability = item?.availability?.availableQuantity;
          if (item?.availability?.availableQuantity && isSelected) {
            setAvailableQuantity(item?.availability?.availableQuantity);
          }
          /* replacing here instead of selector level as business have not decided that no decimals is wanted across all levels - so we have to manipulate on component level */
          const initialPrice = item?.priceInfo?.priceFinal?.formattedPrice?.replace('.00', '');
          const initialPriceValue = priceToFloat(initialPrice);
          const isZeroInitialPrice = initialPriceValue === 0;

          return (
            <StyledVariantFlexRow key={key} isLast={key === variantOptionPrice.length - 1}>
              <StyledVariantFlexColumn>
                {displayMultipleOptions && (
                  <StyledRadioButtonSmall
                    testId={`${key}-radio`}
                    checked={isSelected}
                    disabled={disableProduct}
                    onChange={!disableProduct ? handleSelectionChange(item.sku || '', item.isFreeTrial) : undefined}
                  />
                )}
                <StyledOptionTexts
                  grayedOut={!isSelected}
                  onClick={!disableProduct ? handleSelectionChange(item.sku || '', item.isFreeTrial) : undefined}
                >
                  <StyledDate data-testid="purchase-summary-variant-label" isDisabled={disableProduct}>
                    {`${
                      isPhPrSubscription
                        ? isPhSubscriptionMagazine
                          ? Product.AvailableFormat.MAGAZINE_SUBSCRIPTION
                          : Product.AvailableFormat.CPE_SELF_STUDY
                        : item?.format?.label
                    }${item.isFreeTrial ? `: ${item.freeTrialTerm}-day free trial *` : ''}`}
                  </StyledDate>
                  <StyledPrice data-testid="purchase-summary-variant-price" isDisabled={disableProduct}>
                    {!isUserSuspendedByEthics && !isZeroInitialPrice
                      ? item?.priceInfo?.priceFinal?.formattedPrice
                      : item?.priceInfo?.priceFull?.formattedPrice}{' '}
                  </StyledPrice>
                  {disabledMessage && <StyledDisabledMessage>{disabledMessage}</StyledDisabledMessage>}
                </StyledOptionTexts>

                {isSelected && isInStock && item.isPhysicalProduct && (
                  <QuantityBox
                    availableQuantity={itemAvailability}
                    testId="purchase-summary--multi-variants"
                    handleProductQtyChange={
                      productButtonStatus === PRODUCT_CART_STATUS.NOT_IN_CART ? changeQuantity : renderUpdateQuantity
                    }
                    productQty={PRODUCT_CART_STATUS.IN_CART && isUpdateButtonsShown ? tempQuantityValue : quantityValue}
                  />
                )}
              </StyledVariantFlexColumn>
              {isSelected && item.availability && isMoreThanAvailable && (
                <StyledWarningNeedToAdjustQuantity availableQuantity={itemAvailability} />
              )}
              {isPhProductLowOnStock && <WarningLowStock availableQuantity={itemAvailability} />}
              {isPhProductIsOutOfStock && <WarningOutOfStock productInterestFormUrl={item.productInterestFormUrl} />}
              {isSelected && isInStock && item.isPhysicalProduct && standingOrderValue()}
              {isUpdateButtonsShown && isSelected && renderQtyUpdateButtons()}
            </StyledVariantFlexRow>
          );
        })}

      {productType === Product.ProductType.SUBSCRIPTION &&
        !isPhPrSubscription &&
        variantOptionPrice &&
        variantOptionPrice.map((item: Product.VariantWithPriceInfo, key) => {
          const isSelected: boolean = item.sku === selectedVariantSKU && item.isFreeTrial === isFreeTrialSelected;
          const disableProduct = shouldDisableOptionForPreviouslyPurchasedSubscriptions(Boolean(item.isFreeTrial));
          const disabledMessage: string | null = disabledPreviouslyPurchasedSubscriptionMessage(
            Boolean(item.isFreeTrial)
          );

          return (
            <StyledVariantFlexRow key={key} isLast={key === variantOptionPrice.length - 1}>
              <StyledVariantFlexColumn>
                <StyledRadioButtonSmall
                  testId={`${key}-radio`}
                  checked={isSelected}
                  disabled={disableProduct}
                  onChange={!disableProduct ? handleSelectionChange(item.sku || '', item.isFreeTrial) : undefined}
                />
                <StyledOptionTexts
                  grayedOut={!isSelected}
                  onClick={!disableProduct ? handleSelectionChange(item.sku || '', item.isFreeTrial) : undefined}
                >
                  <StyledDate data-testid="purchase-summary-variant-label" isDisabled={disableProduct}>
                    {`${subscriptionProductType.label}${
                      item.isFreeTrial ? `: ${item.freeTrialTerm}-day free trial *` : ''
                    }`}
                  </StyledDate>
                  <StyledPrice data-testid="purchase-summary-variant-price" isDisabled={disableProduct}>
                    {!isUserSuspendedByEthics
                      ? item?.priceInfo?.priceFinal?.formattedPrice
                      : item?.priceInfo?.priceFull?.formattedPrice}{' '}
                  </StyledPrice>
                  {disabledMessage && <StyledDisabledMessage>{disabledMessage}</StyledDisabledMessage>}
                </StyledOptionTexts>
              </StyledVariantFlexColumn>
            </StyledVariantFlexRow>
          );
        })}

      <HorizontalDivider />
    </>
  );
};
const StyledWebcastSeriesDiv = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)};
  padding: 0 0 ${props => `${props.theme.pxToRem(4)}`} ${props => `${props.theme.pxToRem(20)}`};
`;
const StyledWebcastSeriesText = styled.div`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.57;
`;

const SelectTextStyled = styled.div`
  margin-top: ${props => props.theme.pxToRem(20)};
  padding-left: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledMoreButton = styled(Button)`
  &&&& {
    padding: 0 0 0 ${props => `${props.theme.pxToRem(20)}`};
    font-size: ${props => props.theme.fontSizes.xxs};
    color: ${props => props.theme.colors.primaryPurple};
    font-weight: ${props => props.theme.fontWeights.light};
    text-decoration: underline;
  }
`;

const StyledMoreButtonWebcastSeries = styled(StyledMoreButton)`
  &&&& {
    padding: 0 0 ${props => `${props.theme.pxToRem(20)}`} ${props => `${props.theme.pxToRem(20)}`};
  }
`;

const StyledVariant = styled.div<{ isLast: boolean }>`
  display: flex;
  align-items: center;
  ${props => !props.isLast && `border-bottom: 1px solid ${props.theme.colors.neutralGrey3};`}
  margin: 0 ${props => props.theme.pxToRem(20)};
  padding: ${props => `${props.theme.pxToRem(15)} ${props.theme.pxToRem(5)}`};
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: ${props => props.theme.pxToRem(12)};
  min-width: ${props => props.theme.pxToRem(24)};
`;

const StyledOptionTexts = styled.div<ThemedStyledProps<{ grayedOut: boolean }, DefaultTheme>>`
  font-weight: ${props => (props.grayedOut ? props.theme.fontWeights.light : props.theme.fontWeights.medium)};
  flex: 1;
  margin-bottom: ${props => props.theme.pxToRem(5)};
`;

const StyledDate = styled.div<ThemedStyledProps<{ isDisabled?: boolean }, DefaultTheme>>`
  height: ${props => props.theme.pxToRem(22)};
  width: 100%;
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.57;
  color: ${props => (props.isDisabled ? props.theme.colors.neutralGrey6 : props.theme.colors.neutralGrey8)};
`;

const StyledTime = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.57;
  color: ${props => props.theme.colors.neutralGrey8};
  width: 100%;
`;

const StyledPrice = styled.div<ThemedStyledProps<{ isDisabled?: boolean }, DefaultTheme>>`
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.57;
  color: ${props => (props.isDisabled ? props.theme.colors.neutralGrey6 : props.theme.colors.neutralGrey8)};
  width: 100%;
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledRadioButtonSmall = styled(RadioButton)`
  margin-right: ${props => props.theme.pxToRem(12)};
  margin-top: ${props => props.theme.pxToRem(12)};
  min-width: ${props => props.theme.pxToRem(18)};
  &&& {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    & > div {
      width: ${props => props.theme.pxToRem(8)};
      height: ${props => props.theme.pxToRem(8)};
    }
  }
`;

const HorizontalDivider = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey3};
  width: 100%;
  height: ${props => props.theme.pxToRem(1)};
`;

const StyledVariantFlexRow = styled.div<{ isLast: boolean }>`
  display: flex;
  flex-direction: column;
  ${props => !props.isLast && `border-bottom: 1px solid ${props.theme.colors.neutralGrey3};`}
  margin: 0 ${props => props.theme.pxToRem(20)};
  padding: ${props => `${props.theme.pxToRem(15)} ${props.theme.pxToRem(5)}`};
`;

const StyledWarningNeedToAdjustQuantity = styled(WarningNeedToAdjustQuantity)`
  margin-left: ${props => props.theme.pxToRem(30)};
  margin-left: ${props => props.theme.pxToRem(30)};
`;

const StyledVariantFlexColumn = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledDisabledMessage = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.colors.interfaceRed};
`;
