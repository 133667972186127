import React from 'react';
interface Props {
  size?: number;
  color?: string;
  className?: string;
}

export const Copy = React.memo<Props>(({ size = 24, color = '#72246c', className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" className={className}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M19.122 6.917c1.033 0 1.878.846 1.878 1.878v10.327A1.883 1.883 0 0 1 19.122 21H8.795a1.884 1.884 0 0 1-1.878-1.878V8.795c0-1.032.846-1.878 1.878-1.878zm-.139 2.018H8.935v10.048h10.048V8.935zM16.071 3v2.017H5.017V16.07H3V4.878C3 3.845 3.845 3 4.878 3h11.193z"
    />
  </svg>
));
