import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { B2B, Product } from 'mxp-schemas';

import { Input } from 'components/atoms';
import { Dropdown, DropdownProps } from 'components/atoms/Dropdown/Dropdown';

import {
  changeField,
  editBundleName,
  editBundleSubStart,
  editBundleOccurrenceDate,
  editBundlePeriod,
  editBundlePeriodType,
} from 'modules/admin/actions';
import { isAccessiblePeriodSelectionValid, accessiblePeriodPlaceholder } from './CreateOrder.helpers';

interface DateInputWarningProps {
  date: string;
}

const DateInputWarning: React.FC<DateInputWarningProps> = ({ date }) => {
  const isWithinAYear: boolean = moment(date).diff(moment(), 'months', true) < 12;
  const isNotValid = !moment(date, 'MM/DD/YYYY', true).isValid() || !isWithinAYear;
  const isEmpty = !Boolean(date.length);

  if (isEmpty) return <StyledInputWarning isError>Empty</StyledInputWarning>;
  if (isNotValid) return <StyledInputWarning isError>Invalid date</StyledInputWarning>;

  return null;
};

const StyledInputWarning = styled.div<{ isError?: boolean }>`
  margin-top: ${props => props.theme.pxToRem(6)};
  font-size: ${props => props.theme.fontSizes.xxs};

  ${props =>
    props.isError &&
    `
    border-color: ${props.theme.colors.interfaceRed};
    color: ${props.theme.colors.interfaceRed};
    font-weight: ${props.theme.fontWeights.regular};
    `}
`;

const StyledAccesibleInputWarning = styled.div<{ isError?: boolean }>`
  max-width: ${props => props.theme.pxToRem(180)};
  margin-top: ${props => props.theme.pxToRem(6)};
  font-size: ${props => props.theme.fontSizes.xxs};

  ${props =>
    props.isError &&
    `
    border-color: ${props.theme.colors.interfaceRed};
    color: ${props.theme.colors.interfaceRed};
    font-weight: ${props.theme.fontWeights.regular};
    `}
`;

interface AccessiblePeriodWarningProps {
  period: number | undefined;
  periodType: string | undefined;
}

const AccessiblePeriodWarning: React.FC<AccessiblePeriodWarningProps> = ({ period, periodType }) => {
  const isNotValid = !isAccessiblePeriodSelectionValid(period, periodType);

  if (isNotValid) {
    return (
      <StyledAccesibleInputWarning isError>
        Invalid billing period. Please enter another combination.
      </StyledAccesibleInputWarning>
    );
  }

  return null;
};

const maxProjectNumberChar: number = 7;
const maxProjectTeamsChar: number = 6;

const isValidProjectNumber = (input: string | undefined) => {
  return input?.length !== maxProjectNumberChar;
};

const isValidProjectTeams = (input: string | undefined) => {
  return input?.length !== maxProjectTeamsChar;
};

const hasDateError = (date: string) => {
  const isWithinAYear: boolean = moment(date).diff(moment(), 'months', true) < 12;
  const isNotValid = !moment(date, 'MM/DD/YYYY', true).isValid() || !isWithinAYear;
  const isEmpty = !Boolean(date.length);
  return isNotValid || isEmpty;
};

const StyledDateInput = styled(Input)<{ isError: boolean }>`
  max-width: ${props => props.theme.pxToRem(130)};
  ${props =>
    props.isError &&
    `
    &&&& > input {
      &:focus {
        border-color: ${props.theme.colors.interfaceRed};
      }
      border-color: ${props.theme.colors.interfaceRed};
    }
  `}
`;

const periodTypeOptions = [
  {
    key: 1,
    text: B2B.AccessiblePeriodTypes.WEEK,
    value: B2B.AccessiblePeriodTypes.WEEK,
  },
  {
    key: 2,
    text: B2B.AccessiblePeriodTypes.MONTH,
    value: B2B.AccessiblePeriodTypes.MONTH,
  },
  {
    key: 3,
    text: B2B.AccessiblePeriodTypes.YEAR,
    value: B2B.AccessiblePeriodTypes.YEAR,
  },
];

interface AdditionalProdAttrProps {
  item: B2B.CartItem;
  index: number;
}

export const AdditionalProductAttr: React.FC<AdditionalProdAttrProps> = ({ item, index }) => {
  const dispatch = useDispatch();

  const isProvByBillOnly = item.userInput?.provisionBy === 'bill-only';
  const isRecurring = (
    [
      Product.ProductType.SUBSCRIPTION,
      Product.ProductType.MEMBERSHIP,
      Product.ProductType.SECTION,
      Product.ProductType.CREDENTIAL,
    ] as string[]
  ).includes(item.productDetails.productType);

  const isProductNonStore = Product.ProductType.NON_STORE === item.productDetails.productType;
  const isNonStoreRecurringProduct = isProductNonStore && item.productDetails.isManualSubscription;
  const isNonStoreOneTimeProduct = isProductNonStore && !item.productDetails.isManualSubscription;

  // Setup default values for date input:
  const getSubscriptionStart = item.userInput?.additionalAttrs?.subscriptionStart || '';
  const getOccurrenceStart = item.userInput?.additionalAttrs?.occurrenceDate || '';

  const onFieldChange =
    (i: number, field: string) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (
        !/^\d*$/.test(value) ||
        (field === 'teams' && value.length > 6) ||
        (field === 'projectNumber' && value.length > 7)
      ) {
        return;
      }

      dispatch(changeField(i, value, field));
    };

  const onBundleNameChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(editBundleName(index, value));
  };

  const onStartDateInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (/[0-9]|\/|[\b]/.test(value) || value === '') {
      dispatch(editBundleSubStart(index, value));
    }
  };

  const onOccDateInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (/[0-9]|\/|[\b]/.test(value) || value === '') {
      dispatch(editBundleOccurrenceDate(index, value));
    }
  };

  const handlePeriodChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (!/^\d*$/.test(value)) return;
    dispatch(editBundlePeriod(index, value !== undefined && value !== '' ? Number(value) : value));
  };

  const handlePeriodTypeChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(editBundlePeriodType(index, data.value as string));
  };

  return (
    <StyledFlexContainer>
      {/* Provision by AICPA - recurring OR one-time*/}
      <StyledBundleNameContainer>
        <Input
          fluid
          labelName="Invoice bundle name (optional)"
          name={`provby-aicpa-invoice-bundle-name-${item.productDetails.sku}`}
          type="text"
          value={item.userInput?.additionalAttrs?.bundleName}
          testId={`provby-aicpa-invoice-bundle-name-${item.productDetails.sku}`}
          labelId={`provby-aicpa-invoice-bundle-name-${item.productDetails.sku}`}
          autoComplete="off"
          onChange={onBundleNameChange}
        />
      </StyledBundleNameContainer>
      {/* Provision by Bill Only - recurring*/}
      {isProvByBillOnly && isRecurring && (
        <>
          <StyledFlexBlock>
            <StyledDateInput
              name={`provby-billonly-rec-sub-start-${item.productDetails.sku}`}
              labelName="Subscription start"
              type="text"
              value={getSubscriptionStart}
              testId={`provby-billonly-rec-sub-start-${item.productDetails.sku}`}
              labelId={`provby-billonly-rec-sub-start-${item.productDetails.sku}`}
              onChange={onStartDateInputChange}
              placeholder={getSubscriptionStart}
              autoFocus={false}
              isError={hasDateError(getSubscriptionStart)}
              autoComplete="off"
              maxLength={10}
            />
            <DateInputWarning date={getSubscriptionStart} />
          </StyledFlexBlock>

          <StyledFlexBlock>
            <StyledLabel>Accessible for</StyledLabel>
            <StyledAccessibleForContainer>
              <StyledInputPeriod
                name={`accessible-${item.productDetails.sku}`}
                type="text"
                value={item.userInput?.additionalAttrs?.period}
                testId={`accessible-${item.productDetails.sku}`}
                onChange={handlePeriodChange}
                autoFocus={false}
                isError={
                  !isAccessiblePeriodSelectionValid(
                    item.userInput?.additionalAttrs?.period,
                    item.userInput?.additionalAttrs?.periodType
                  )
                }
                placeholder={accessiblePeriodPlaceholder(item.userInput?.additionalAttrs?.periodType)}
              />
              <StyledDropdownPeriod
                testId={`dropdown-accessible-${item.productDetails.sku}`}
                onChange={handlePeriodTypeChange}
                options={periodTypeOptions}
                value={item.userInput?.additionalAttrs?.periodType}
                isError={
                  !isAccessiblePeriodSelectionValid(
                    item.userInput?.additionalAttrs?.period,
                    item.userInput?.additionalAttrs?.periodType
                  )
                }
              />
            </StyledAccessibleForContainer>
            <AccessiblePeriodWarning
              period={item.userInput?.additionalAttrs?.period}
              periodType={item.userInput?.additionalAttrs?.periodType}
            />
          </StyledFlexBlock>
        </>
      )}

      {/* Provision by Bill Only - one-time*/}
      {isProvByBillOnly && !isRecurring && (
        <StyledFlexBlock>
          <StyledDateInput
            name={`provby-billonly-onetime-occ-${item.productDetails.sku}`}
            labelName="Occurrence date"
            type="text"
            value={getOccurrenceStart}
            testId={`provby-billonly-onetime-occ-${item.productDetails.sku}`}
            labelId={`provby-billonly-onetime-occ-${item.productDetails.sku}`}
            onChange={onOccDateInputChange}
            placeholder={getOccurrenceStart}
            autoFocus={false}
            isError={hasDateError(getOccurrenceStart)}
            autoComplete="off"
            maxLength={10}
          />
          <DateInputWarning date={getOccurrenceStart} />
        </StyledFlexBlock>
      )}

      {/* Provision By N/A - Non-store Recurring Product */}
      {isNonStoreRecurringProduct && (
        <>
          <StyledFlexBlock>
            <StyledDateInput
              name={`provby-na-non-store-manual-rec-sub-start-${item.productDetails.sku}`}
              labelName="Subscription start"
              type="text"
              value={getSubscriptionStart}
              testId={`provby-na-non-store-manual-rec-sub-start-${item.productDetails.sku}`}
              labelId={`provby-na-non-store-manual-rec-sub-start-${item.productDetails.sku}`}
              onChange={onStartDateInputChange}
              placeholder={getSubscriptionStart}
              autoFocus={false}
              isError={hasDateError(getSubscriptionStart)}
              autoComplete="off"
              maxLength={10}
            />
            <DateInputWarning date={getSubscriptionStart} />
          </StyledFlexBlock>

          <StyledFlexBlock>
            <StyledLabel>Accessible for</StyledLabel>
            <div>
              <StyledInputPeriod
                name={`accessible-${item.productDetails.sku}`}
                type="text"
                value={item.userInput?.additionalAttrs?.period}
                testId={`accessible-${item.productDetails.sku}`}
                onChange={handlePeriodChange}
                autoFocus={false}
                isError={
                  !isAccessiblePeriodSelectionValid(
                    item.userInput?.additionalAttrs?.period,
                    item.userInput?.additionalAttrs?.periodType
                  )
                }
                placeholder={accessiblePeriodPlaceholder(item.userInput?.additionalAttrs?.periodType)}
              />
              <StyledDropdownPeriod
                testId={`dropdown-accessible-${item.productDetails.sku}`}
                onChange={handlePeriodTypeChange}
                options={periodTypeOptions}
                value={item.userInput?.additionalAttrs?.periodType}
                isError={
                  !isAccessiblePeriodSelectionValid(
                    item.userInput?.additionalAttrs?.period,
                    item.userInput?.additionalAttrs?.periodType
                  )
                }
              />
            </div>
            <AccessiblePeriodWarning
              period={item.userInput?.additionalAttrs?.period}
              periodType={item.userInput?.additionalAttrs?.periodType}
            />
          </StyledFlexBlock>
        </>
      )}

      {/* Provision By N/A - Non-store One Time Product */}
      {isNonStoreOneTimeProduct && (
        <StyledFlexBlock>
          <StyledDateInput
            name={`provby-na-non-store-manual-onetime-occ-${item.productDetails.sku}`}
            labelName="Occurrence date"
            type="text"
            value={getOccurrenceStart}
            testId={`provby-na-non-store-manual-onetime-occ-${item.productDetails.sku}`}
            labelId={`provby-na-non-store-manual-onetime-occ-${item.productDetails.sku}`}
            onChange={onOccDateInputChange}
            placeholder={getOccurrenceStart}
            autoFocus={false}
            isError={hasDateError(getOccurrenceStart)}
            autoComplete="off"
            maxLength={10}
          />
          <DateInputWarning date={getOccurrenceStart} />
        </StyledFlexBlock>
      )}
      {(isNonStoreRecurringProduct || (isProvByBillOnly && isRecurring)) && (
        <>
          <StyledFlexBlock>
            <Input
              name={`promo-code-${item.productDetails.sku}`}
              labelId={`label-promo-code-${item.productDetails.sku}`}
              labelName="Promo Code"
              type="text"
              value={item.userInput?.promoCode}
              testId={`promo-code-${item.productDetails.sku}`}
              onChange={onFieldChange(index, 'promoCode')}
              autoFocus={false}
            />
          </StyledFlexBlock>
          <StyledFlexBlock>
            <Input
              name={`promo-code-reason-${item.productDetails.sku}`}
              labelId={`label-promo-code-reason-${item.productDetails.sku}`}
              labelName="Promo Code Reason"
              type="text"
              value={item.userInput?.promoReason}
              testId={`promo-code-reason-${item.productDetails.sku}`}
              onChange={onFieldChange(index, 'promoReason')}
              autoFocus={false}
            />
          </StyledFlexBlock>
          <StyledFlexBlockSmall>
            <StyledInputTeams
              name={`project-teams-${item.productDetails.teams}`}
              labelId={`label-project-teams-${item.productDetails.teams}`}
              labelName="Teams"
              type="text"
              value={item.userInput.teams}
              testId={`project-teams-${item.productDetails.teams}`}
              onChange={onFieldChange(index, 'teams')}
              autoFocus={false}
              isError={isValidProjectTeams(item.userInput?.teams)}
            />
            {isValidProjectTeams(item.userInput?.teams) && (
              <StyledQuantityInfo isError>Please add a numerical code of exactly 6 digits.</StyledQuantityInfo>
            )}
          </StyledFlexBlockSmall>
          <StyledFlexBlockSmall>
            <StyledInputTeams
              name={`project-number-${item.productDetails.projectNumber}`}
              labelId={`label-project-number-${item.productDetails.projectNumber}`}
              labelName="Project Code"
              type="text"
              value={item.userInput?.projectNumber}
              testId={`project-number-${item.productDetails.projectNumber}`}
              onChange={onFieldChange(index, 'projectNumber')}
              autoFocus={false}
              isError={isValidProjectNumber(item.userInput?.projectNumber)}
            />
            {isValidProjectNumber(item.userInput?.projectNumber) && (
              <StyledQuantityInfo isError>Please add a numerical code of exactly 7 digits.</StyledQuantityInfo>
            )}
          </StyledFlexBlockSmall>
        </>
      )}
    </StyledFlexContainer>
  );
};

const StyledBundleNameContainer = styled.div`
  width: ${props => props.theme.pxToRem(240)};
  margin-right: ${props => props.theme.pxToRem(20)};
`;

const StyledAccessibleForContainer = styled.div`
  width: ${props => props.theme.pxToRem(160)};
  margin-right: ${props => props.theme.pxToRem(20)};
`;

const StyledFlexBlock = styled.div`
  margin-right: ${props => props.theme.pxToRem(20)};
`;
const StyledFlexBlockSmall = styled.div`
  width: ${props => props.theme.pxToRem(120)};
  margin-right: ${props => props.theme.pxToRem(20)};
`;

const StyledQuantityInfo = styled.div<{ isError?: boolean }>`
  margin-top: ${props => props.theme.pxToRem(6)};
  font-size: ${props => props.theme.fontSizes.xxs};
  ${props =>
    props.isError &&
    `
    border-color: ${props.theme.colors.interfaceRed};
    color: ${props.theme.colors.interfaceRed};
    font-weight: ${props.theme.fontWeights.regular};
    `}
`;

const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: start;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const StyledLabel = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};

  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;

const StyledDropdownPeriod = styled(Dropdown)<{ isError: boolean }>`
  ${props => `
		&&& {
			font-size: ${props.theme.pxToRem(14)};
			width: ${props.theme.pxToRem(80)};
			height: ${props.theme.pxToRem(44)};
			padding: ${props.theme.pxToRem(10)};
			background-color: #fff;

			&:first-child {
				margin-right: ${props.theme.pxToRem(20)};
			}

			&&&.ui.dropdown > .text {
				overflow: hidden;
				font-size: ${props.theme.fontSizes.s};
				text-overflow: ellipsis;
				white-space: nowrap;
			}

      &&&.ui.dropdown .menu {
        z-index: 112;
      }
		}
	`}

  ${props =>
    props.isError &&
    `
    &&&& {
      &:focus {
        border-color: ${props.theme.colors.interfaceRed};
      }
      border-color: ${props.theme.colors.interfaceRed};
    }
  `}
`;

const StyledInputPeriod = styled(Input)<{ isError: boolean }>`
  max-width: ${props => props.theme.pxToRem(80)};
  padding-right: ${props => props.theme.pxToRem(10)};
  ${props =>
    props.isError &&
    `
  &&&& > input {
    &:focus {
      border-color: ${props.theme.colors.interfaceRed};
    }
    border-color: ${props.theme.colors.interfaceRed};
  }
`}

  ${props =>
    props.isError &&
    `
    &&&& {
      &:focus {
        border-color: ${props.theme.colors.interfaceRed};
      }
      border-color: ${props.theme.colors.interfaceRed};
    }
  `}
`;

const StyledInputTeams = styled(Input)<{ isError: boolean }>`
  max-width: ${props => props.theme.pxToRem(120)};
  padding-right: ${props => props.theme.pxToRem(10)};
  ${props =>
    props.isError &&
    `
  &&&& > input {
    &:focus {
      border-color: ${props.theme.colors.interfaceRed};
    }
    border-color: ${props.theme.colors.interfaceRed};
  }
`}

  ${props =>
    props.isError &&
    `
    &&&& {
      &:focus {
        border-color: ${props.theme.colors.interfaceRed};
      }
      border-color: ${props.theme.colors.interfaceRed};
    }
  `}
`;
