import React from 'react';
import styled from 'styled-components';
import { Container, Grid, Divider } from 'semantic-ui-react';
import { Modal, Heading, IconButton } from 'components/atoms';
import { Survey } from 'components/molecules/Survey/Survey';
import { ArticleFeedbackPrompt } from 'components/molecules/ArticleFeedback/ArticleFeedbackPrompt';
import { positiveOptions, negativeOptions } from './ExitSurveyData';
import { ThumbDirection, Routes } from 'constants/index';
import { getPath } from 'utils';
import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';

interface Props {
  submitFeedback: (...args: any[]) => Promise<void>;
  show: boolean;
  navigateToLegacy: () => void;
  navigate: (path: string) => void;
}

export const ExitSurvey: React.FC<Props> = React.memo(({ submitFeedback, show, navigateToLegacy, navigate }) => {
  React.useEffect(() => {
    if (show) {
      navigate(getPath(Routes.EXIT_SURVEY_HASH));
    }
  }, [navigate, show]);

  const [thumbState, setThumbs] = React.useState<string>('');

  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const handleThumbsUp = React.useCallback(() => {
    setThumbs(ThumbDirection.UP);
  }, []);

  const handleThumbsDown = React.useCallback(() => {
    setThumbs(ThumbDirection.DOWN);
  }, []);

  const handleSubmit = React.useCallback(
    values => {
      setSubmitting(true);
      submitFeedback('exit survey', values).then(navigateToLegacy);
    },
    [submitFeedback, navigateToLegacy]
  );

  const renderSurveys = () => (
    <Grid>
      <StyledIconButton
        data-testid="close-survey-before-exit-modal"
        aria-label="Close Survey Before Exit Modal"
        onClick={navigateToLegacy}
      >
        <IconClose />
      </StyledIconButton>
      <Container>
        <StyledHeading>How did you find the new site experience?</StyledHeading>
        <ArticleFeedbackPrompt
          heading=""
          subheading=""
          testId="survey-before-exit-thumbs"
          actionDown={handleThumbsDown}
          actionUp={handleThumbsUp}
          isUpHighlighted={thumbState === ThumbDirection.UP}
          isDownHighlighted={thumbState === ThumbDirection.DOWN}
        />
        <StyledDivider />
      </Container>

      {thumbState === ThumbDirection.UP && (
        <Grid.Row centered>
          <StyledSurvey
            isPositive
            options={positiveOptions}
            submit={handleSubmit}
            testId="survey-before-exit-positive"
          />
        </Grid.Row>
      )}

      {thumbState === ThumbDirection.DOWN && (
        <Grid.Row centered>
          <StyledSurvey options={negativeOptions} submit={handleSubmit} testId="survey-before-exit-negative" />
        </Grid.Row>
      )}
    </Grid>
  );

  const renderRedirecting = () => (
    <Grid>
      <Container>
        <StyledHeading>We're redirecting you to AICPA.org</StyledHeading>
      </Container>
    </Grid>
  );

  return (
    <StyledModal size="fullscreen" open={show} testId="survey-before-exit-modal" onClose={navigateToLegacy}>
      <StyledHeaderBorder />
      {isSubmitting ? renderRedirecting() : renderSurveys()}
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  &&& {
    &.fullscreen {
      width: 100% !important;
      top: 0;
      margin: 0 !important;
      border-radius: 0;
      overflow-y: scroll;
    }
    height: 100%;
  }
`;

const StyledHeaderBorder = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  backdrop-filter: blur(10px);
  background-image: linear-gradient(to right, #6a2d87, #a93d69);
`;

const StyledDivider = styled(Divider)`
  &&& {
    margin: 5.5rem 0 4.5rem 0;
    width: 100%;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 3rem;
  height: 3rem;
  background-color: ${props => props.theme.colors.neutralGrey1};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    flex-shrink: 0;
    fill: ${props => props.theme.colors.primaryPurple};
    width: ${props => props.theme.pxToRem(32)};
    height: ${props => props.theme.pxToRem(32)};
  }
`;

const StyledHeading = styled(Heading)`
  &&& {
    padding-top: 3rem;
    padding-bottom: 0.5rem;
    font-size: ${props => props.theme.fontSizes.xl};
    color: ${props => props.theme.colors.neutralGrey8};
    text-align: center;
    font-weight: ${props => props.theme.fontWeights.light};
    font-family: ${props => props.theme.fontFamily};
  }
`;

const StyledSurvey = styled(Survey)`
  text-align: left;
`;
