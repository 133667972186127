import React from 'react';
import styled from 'styled-components';
import { Bookmark, Bookmarked, Copy } from 'components/atoms/svg';
import { Grid } from 'semantic-ui-react';
import { ReactComponent as OpenInNew } from 'resources/images/ic-open-in-new.svg';
import { centPriceToString, productTypeToLabel, productTypeToSlug, arrayIncludes, getPromotedUrl } from 'utils';
import { ReactComponent as SchoolHatIcon } from 'resources/images/ic-school.svg';
import { ButtonLink, Button, ButtonEnums, OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms';
import moment from 'moment-timezone';
import { Content, Product } from 'mxp-schemas';
import { useDispatch, useSelector } from 'react-redux';
import { copy } from 'modules/copy/actions';
import { useToggleSavedItem } from 'hooks/useToggleSavedItem';
import { savedItemsSelector } from 'modules/savedItems';
import { savedBlogPostsSelector } from 'modules/savedBlogPosts';

interface Props {
  item: State.PromotedProductsCard;
  setBurger: (state: boolean) => void;
  globalHistory: any;
  clearMegaHistory: () => void;
}

export const PromotedProductCards: React.FC<Props> = ({ item, setBurger, globalHistory, clearMegaHistory }) => {
  const dispatch = useDispatch();
  const { productId, description, title, slug } = item;
  const savedItems: string[] | null = useSelector(savedItemsSelector);
  const savedBlogPosts: string[] | null = useSelector(savedBlogPostsSelector);
  const variants = item?.variants?.map((variant: any) => {
    return variant;
  })[0];
  const isSaved: boolean = arrayIncludes([...(savedItems || []), ...(savedBlogPosts || [])], productId);
  const handleCopy = React.useCallback(
    (event: React.SyntheticEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const url = getPromotedUrl(item);

      dispatch(copy({ productId, copiedText: url, notificationText: title }));
    },
    [productId, title, item, dispatch]
  );

  const productTypeArray: any = [variants?.productType];
  const externalLink = `${window.location.origin}/${Content.CategorySlugs.CPE_LEARNING}/${productTypeToSlug(
    productTypeArray as Product.ProductType
  )}/${item.slug}`;

  const handleBookmarkButtonClick = useToggleSavedItem({
    id: productId,
    isSaved,
    contentSource: 'aicpa',
    title: slug,
    description,
    slug,
    externalUrl: '',
  });
  const externalUrl = `/${Content.CategorySlugs.CPE_LEARNING}/${productTypeToSlug(
    productTypeArray as Product.ProductType
  )}/${item.slug}`;

  const navigateOut = (url: string) => () => {
    setBurger(false);
    globalHistory.push(url);
    clearMegaHistory();
  };
  return (
    <>
      <ProductWrapper>
        <StyledCardWrapper>
          <StyledCard testId={`link-button ${item?.productId}`} to={externalLink} target="_blank">
            <StyledCredits>
              <StyledSchoolIcon />
              CPE Credits: {variants?.credits ? variants?.credits : 0}
            </StyledCredits>
            <StyledImage src={variants?.images?.[0]?.imageUrl} />
            <StyledProductType>{productTypeToLabel(variants?.productType as Product.ProductType)}</StyledProductType>
            <StyledProductName>{item?.name}</StyledProductName>
            <StyledDateTime>
              {variants?.startDateTime ? `${moment(variants?.startDateTime).format('MMM D, YYYY')} -` : ''}
              {variants?.endDateTime ? moment(variants?.endDateTime).format('MMM D, YYYY') : ''}
            </StyledDateTime>
            <StyledPrice>
              {variants?.prices?.[0]?.amount ? centPriceToString(variants?.prices?.[0]?.amount) : ''}
            </StyledPrice>
            <ButtonWrapper>
              <StyledLink testId={`link-button ${item?.productId}`} to={externalLink} target="_blank">
                <StyledIcon /> External Link
              </StyledLink>
              <CopyButton
                aria-label={`Copy ${title}`}
                testId={`copy-button-${item?.productId}`}
                onClick={handleCopy}
                variant={ButtonEnums.variants.iconWithCircle}
              >
                <OffScreenSpan>{title}</OffScreenSpan>
                <Copy />
              </CopyButton>
              <StyledBookmarkButton
                aria-label={`Bookmark ${title}`}
                testId={`bookmark-button-${item?.productId}`}
                onClick={handleBookmarkButtonClick}
                active={isSaved}
                variant={ButtonEnums.variants.iconWithCircle}
              >
                <OffScreenSpan>{title}</OffScreenSpan>
                {isSaved ? <Bookmarked /> : <Bookmark />}
              </StyledBookmarkButton>
            </ButtonWrapper>
          </StyledCard>
        </StyledCardWrapper>
      </ProductWrapper>
      <>
        <OnlyMobileCSS>
          <PromotedProductsWrapper
            columns={2}
            testId={`link-button ${item?.productId}`}
            onClick={navigateOut(externalUrl || '')}
          >
            <StyledLeftColum>
              <StyledCreditsMobile>
                <StyledSchoolIcon />
                {item?.credits ? item?.credits : 0}
              </StyledCreditsMobile>
              <StyledImageMobile src={item?.variants?.[0]?.images?.[0].imageUrl} />
            </StyledLeftColum>
            <StyledRightColum>
              <StyledName>{item?.name}</StyledName>
              <StyledPriceMobile>
                {' '}
                {item?.variants?.[0]?.prices?.[0]?.amount
                  ? centPriceToString(item?.variants?.[0]?.prices?.[0]?.amount)
                  : ''}
              </StyledPriceMobile>
            </StyledRightColum>
          </PromotedProductsWrapper>
        </OnlyMobileCSS>
        <OptionDivider />
      </>
    </>
  );
};

const ProductWrapper = styled(OnlyDesktopCSS)`
  margin-left: ${props => props.theme.pxToRem(12)};
  display: flex;
  flex-wrap: wrap;
  row-gap: ${props => props.theme.pxToRem(8)};
  width: 33.33%;
`;

const StyledCardWrapper = styled(Grid.Row)`
  font-family: 'Roboto';
  width: 100%;
  display: flex;
  padding-top: 0;
`;

const StyledCard = styled(ButtonLink)`
  display: block;
  &:hover {
    outline: 1px solid ${props => props.theme.colors.primaryPurple};
    color: ${props => props.theme.colors.neutralBlack};
  }
  color: ${props => props.theme.colors.neutralBlack};
`;

const StyledImage = styled.img`
  width: 100%;
  height: ${props => props.theme.pxToRem(150)};
  margin-bottom: ${props => props.theme.pxToRem(-40)};
  ${props => props.theme.mediaQueries.largeScreenMin} {
    min-height: ${props => props.theme.pxToRem(150)};
  }
  border-bottom: solid ${props => props.theme.pxToRem(2)} ${props => props.theme.colors.secondaryTeal};
`;

const StyledCredits = styled.div`
  position: relative;
  float: right;
  margin: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(-32)} 0;
  padding: ${props => props.theme.pxToRem(3)};
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.neutralGrey8};
  background-color: ${props => props.theme.colors.neutralWhite};
  object-fit: contain;
  z-index: 1;
`;

const StyledProductType = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.neutralGrey5};
  background-color: ${props => props.theme.colors.neutralWhite};
  text-transform: uppercase;
  padding: ${props => props.theme.pxToRem(10)};
  display: inline-block;
  letter-spacing: 1.2;
`;

const StyledProductName = styled.div`
  height: ${props => props.theme.pxToRem(70)};
  margin-top: ${props => props.theme.pxToRem(15)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.regular};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const StyledDateTime = styled.div`
  margin-top: ${props => props.theme.pxToRem(15)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  height: ${props => props.theme.pxToRem(20)};
`;

const StyledPrice = styled.div`
  margin-top: ${props => props.theme.pxToRem(3)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  height: ${props => props.theme.pxToRem(20)};
`;

const ButtonWrapper = styled.div`
  &&&& {
    button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const CopyButton = styled(Button)`
  &&&& {
    position: inherit;
    float: right;
  }
`;

const OffScreenSpan = styled.span`
  border: 0 !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`;

const StyledSchoolIcon = styled(SchoolHatIcon)`
  width: ${props => props.theme.pxToRem(14)};
  height: ${props => props.theme.pxToRem(14)};
  &&& {
    margin: 0 ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(-3)} 0;
  }
`;

const StyledBookmarkButton = styled(Button)`
  &&&& {
    ${props => props.theme.mediaQueries.computerMin} {
      position: inherit;
      float: right;
      &&:focus {
        border-color: transparent;
        background: ${props => props.theme.colors.primaryLightPurple};
        outline: none;
        path {
          fill: ${props =>
            props.variant === ButtonEnums.variants.iconWhiteTone
              ? props.theme.colors.primaryIndicatorPurple
              : props.theme.colors.neutralWhite};
        }
      }
    }
  }
`;

const StyledLink = styled(ButtonLink)`
  display: inline-block;
  color: ${props => props.theme.colors.neutralGrey5};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  padding-top: ${props => props.theme.pxToRem(12)};
`;

const StyledIcon = styled(OpenInNew)`
  vertical-align: bottom;
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
  line-height: 1rem;
  display: inline-block;
  margin-right: ${props => props.theme.pxToRem(5)};
`;

const PromotedProductsWrapper = styled(Grid.Row)`
  width: 100%;
  display: flex;
  margin: ${props => `${props.theme.pxToRem(15)} 0 ${props.theme.pxToRem(20)} ${props.theme.pxToRem(20)}`};
  cursor: pointer;
`;

const StyledLeftColum = styled.div`
  width: ${props => props.theme.pxToRem(75)};
  height: ${props => props.theme.pxToRem(75)};
`;

const StyledRightColum = styled.div`
  width: 75%;
  height: ${props => props.theme.pxToRem(75)};
  padding-left: ${props => props.theme.pxToRem(10)};
`;

const StyledCreditsMobile = styled.div`
  position: relative;
  width: ${props => props.theme.pxToRem(40)};
  float: right;
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.neutralGrey8};
  background-color: ${props => props.theme.colors.neutralWhite};
  z-index: 1;
  margin-right: ${props => props.theme.pxToRem(-1)};
`;

const StyledName = styled.div`
  height: ${props => props.theme.pxToRem(40)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.neutralBlack};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const StyledPriceMobile = styled.div`
  height: ${props => props.theme.pxToRem(16)};
  margin-top: ${props => props.theme.pxToRem(2)};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.neutralGrey5};
`;

const StyledImageMobile = styled.img`
  position: absolute;
  width: ${props => props.theme.pxToRem(75)};
  height: ${props => props.theme.pxToRem(75)};
  border-bottom: solid ${props => props.theme.pxToRem(2)} ${props => props.theme.colors.secondaryTeal};
`;

const OptionDivider = styled(OnlyMobileCSS)`
  height: ${props => props.theme.pxToRem(1)};
  background-color: ${props => props.theme.colors.neutralGrey2};
  margin: ${props => `0 ${props.theme.pxToRem(20)}`};

  :last-child {
    height: ${props => props.theme.pxToRem(0)};
  }
`;
