import React from 'react';
import { Button, Modal, ButtonEnums } from 'components/atoms';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { goToPreviousRoute } from 'modules/layouts';

interface Props {
  showRenewalSeasonModal: boolean;
}

export const ModalRenewalSeason: React.FC<Props> = React.memo(({ showRenewalSeasonModal }) => {
  const dispatch = useDispatch();
  const goToPreviousPage = () => {
    dispatch(goToPreviousRoute());
  };

  return (
    <StyledModal
      open={showRenewalSeasonModal}
      size="tiny"
      heading={'Renewal season not yet here.'}
      confirmNode={
        <Button
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          bordered
          onClick={goToPreviousPage}
          testId="confirm-warning"
        >
          {'Confirm'}
        </Button>
      }
      testId="redirect-last-visit-warning"
    />
  );
});

const StyledModal = styled(Modal)`
  &&&& {
    border-radius: 0;
  }
`;
