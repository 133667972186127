import { FcmaEthicsModal, FcmaIneligibleModal, Grid, OnlyDesktopCSS, OnlyMobileCSS } from 'components/atoms';
import {
  MipAlreadyMipMemberModal,
  MipCimaPracticeCheckModal,
  MipConductViolationModal,
  MipApplyCimaRegularModal,
} from 'components/molecules';
import { CimaFcmaApplicationFormModal } from 'components/molecules/CimaFcmaApplicationFormModal/CimaFcmaApplicationFormModal';
import { ModalFiveYearsInCandidate } from 'components/organisms';
import { push } from 'connected-react-router';
import { PackageMembership, Routes } from 'constants/index';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { setIsComingFromFcmaPropPageJourney } from 'modules/content/actions';
import { isComingFromFcmaPropPageSelector } from 'modules/content/selectors';
import { addMembershipToCart } from 'modules/membership';
import { setFcmaInUserChoice, setMembershipEvent } from 'modules/membership/actions';
import {
  activeMembershipSubscriptionSelector,
  canceledMembershipSubscriptionSelector,
  clickedMembershipUpgradeSelector,
  isCimaRegularLapsedSelector,
  isFiveYearsCandidateSelector,
  membershipInviteDataSelector,
  membershipRenewalsSelector,
  membershipTypesSelector,
  userMembershipPackageSelector,
  isRenewalsJourneySelector,
  isCimaPqCandidateRenewalSelector,
  inactiveMembershipProduct,
} from 'modules/membership/selectors';
import { getProductItem } from 'modules/products/actions';
import { hasFcmaCredentialProductSelector, hasMipCredentialProductSelector } from 'modules/products/selectors';
import { getInviteIdSelector } from 'modules/startup/selectors';
import {
  staticLandingPageContentTypeSlugSelector,
  staticLandingPageMainColumnSelector,
} from 'modules/staticLandingPagesContent/selectors';
import {
  cimaMembershipTermIsTenYearsSelector,
  cimaMembershipTermSelector,
  isAuthSelector,
  userHasEthicsViolationOnListSelector,
  cimaMembershipsTermTypeSelector,
  isCimaMemberSelector,
  cimaMembershipSelector,
  isUserMemberLapsedSelector,
} from 'modules/user/selectors';
import { MembershipTypes } from 'mxp-schemas';
import { Admin as AdminUtils, ContentfulHelpers } from 'mxp-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { getPath } from 'utils';
import { getCurrentLocation } from 'utils/routes';
import { MembershipTypeSelectionButton } from './MembershipTypeSelectionButton';
import { MembershipTypeSelectionCardDesktop, MembershipTypeSelectionCardMobile } from './MembershipTypeSelectionCard';
import { handleEvent } from 'utils/Analytics';
import { membershipActionNames } from 'modules/membership/constants';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';
export interface PersonalisedMembershipProducts extends Partial<State.MembershipProductBlock> {
  isRecommended?: boolean;
  isCurrent?: boolean;
  membershipUpgradeList?: any;
}
export interface MembershipTypeProps {
  products: PersonalisedMembershipProducts[];
  selectMembership: (
    id: string,
    slug: string,
    productType: string[],
    isVariant: boolean,
    membershipKey?: string
  ) => void;
  isUserMember?: boolean;
  isCenterMembershipJourney?: boolean;
  isCenterMembershipLoading?: boolean;
  isPropPage?: boolean;
  benefitsListDescription?: string;
  isUserMemberSuspended?: boolean;
  isConsistCenterProduct?: boolean;
  shouldShowNewPackageBuilder?: boolean;
}
export const MembershipTypeSelection: React.FC<MembershipTypeProps> = ({
  products,
  selectMembership,
  isUserMember = false,
  isCenterMembershipJourney = false,
  isCenterMembershipLoading = false,
  isPropPage = false,
  benefitsListDescription,
  isUserMemberSuspended = false,
  isConsistCenterProduct,
  shouldShowNewPackageBuilder,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  // SELECTORS and GLOBAL STATE
  const prevRoute: string = getCurrentLocation(location);
  const isAicpaMembershipPackagePage = location?.pathname === PackageMembership.MEMBERSHIP_PACKAGE;
  const isCimaMembershipPackagePage = location?.pathname === MembershipTypes.MembershipRoute.PACKAGE;
  const contentTypeSlug = useSelector(staticLandingPageContentTypeSlugSelector);
  const staticLandingPageMainContent = useSelector(staticLandingPageMainColumnSelector);
  const currentMembershipKey = useSelector(cimaMembershipsTermTypeSelector);
  const cimaMemBody = useSelector(cimaMembershipSelector)?.membershipBody as string;
  const hasMipCredentialProduct = useSelector(hasMipCredentialProductSelector);
  const isForUpgrade = useSelector(clickedMembershipUpgradeSelector);
  const isCimaPqCandidateRenewal = useSelector(isCimaPqCandidateRenewalSelector);
  // check if user is CIMA Regular member for 10 continuous years (check if greater than 1 day for now)
  const isCimaRegularMemberForTenYears = useSelector(cimaMembershipTermIsTenYearsSelector);
  const isCimaMember = useSelector(isCimaMemberSelector);
  const isCimaRegularLapsed = useSelector(isCimaRegularLapsedSelector);
  const isUserMemberLapsed = useSelector(isUserMemberLapsedSelector);
  const { membership: inactiveMembership } = useSelector(inactiveMembershipProduct);
  const subscriptionMembershipType = useSelector(activeMembershipSubscriptionSelector);
  const isFiveYearsCandidate = useSelector(isFiveYearsCandidateSelector);
  const membershipTypes = useSelector(membershipTypesSelector).list;
  const inviteId = useSelector(getInviteIdSelector);
  const { isPaidByFirm } = useSelector(membershipInviteDataSelector);
  const { type } = useSelector(userMembershipPackageSelector);
  const membershipRenewals = useSelector(membershipRenewalsSelector);
  const isAdmin = useSelector(isAdminPortalSelector);
  const isAuth = useSelector(isAuthSelector);
  const userFcmaHasEthicsViolation = useSelector(userHasEthicsViolationOnListSelector);
  const cancelledMembershipSubscription = useSelector(canceledMembershipSubscriptionSelector);
  // check if the user has already purchased a FCMA credential
  const userHasFcmaCredential = useSelector(hasFcmaCredentialProductSelector);
  // check if user is coming from FCMA prop page
  const isComingFromFcmaPropPage = useSelector(isComingFromFcmaPropPageSelector);
  const isRenewalsJourney = useSelector(isRenewalsJourneySelector);
  const { useNewSignUpOnly, useNewMembershipAICPA } = useSelector(featureTogglesSelector);

  // check if CIMA MiP and coming from prop page
  const isCimaMipPropPage = ContentfulHelpers.isCimaMipContentType(contentTypeSlug) && isPropPage;
  // check if FCMA and coming from prop page
  const isFCMAPropPageJourney = ContentfulHelpers.isFCMAContentType(contentTypeSlug) && isPropPage;
  const isSubscribedToCimaRegular = AdminUtils.isCimaRegularType(cimaMemBody, currentMembershipKey);
  const isSubscribedToCimaAffiliate = AdminUtils.isCimaAffiliateType(cimaMemBody, currentMembershipKey);
  const membershipProductsBlock = staticLandingPageMainContent?.find(
    (item: any) => item?.contentType === ContentfulHelpers.BLOCK_TYPES.MEMBERSHIP_PRODUCT_BLOCK
  );
  const membershipTypesLoaded = membershipTypes?.length;
  const membershipProductsLoaded = Boolean(
    (membershipProductsBlock?.cmsProducts && Object.values(membershipProductsBlock.cmsProducts).length) ||
      membershipTypesLoaded
  );

  let isWideView =
    products?.length === 1 &&
    !isCimaRegularLapsed &&
    !isSubscribedToCimaRegular &&
    !isSubscribedToCimaAffiliate &&
    !isForUpgrade &&
    !isCimaMember;
  if (isFCMAPropPageJourney) {
    isWideView = true;
  }
  if (isCimaMipPropPage) {
    isWideView = true;
  }

  // LOCAL STATES

  const [selectionCardShow, setSelectionCardShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(type?.slug || '');
  // FCMA modal state
  const [isFCMAEthicsModalOpen, setIsFCMAIsEthicsModalOpen] = useState(false);
  const [isFCMAIneligibleModalOpen, setIsFCMAIneligibleModalOpen] = useState(false);
  const [isFCMACartLoading, setIsFCMACartLoading] = useState(false);
  const [isCimaFcmaApplicationFormModalOpen, setCimaFcmaApplicationFormModalOpen] = useState(false);
  // MiP Modals
  const [isMipNotCimaRegularModalOpen, setIsMipNotCimaRegularModalOpen] = useState(false);
  const [isMipConductNotPassedModalOpen, setIsMipConductNotPassedModalOpen] = useState(false);
  const [isAlreadyMipModalOpen, setIsAlreadyMipModalOpen] = useState(false);
  const [isMipCimaPracticeCheckModalOpen, setIsMipCimaPracticeCheckModalOpen] = useState(false);
  const isImpersonation = Boolean(isAuth) && isAdmin;
  const [membershipTypeList, setMembershipTypeList] = useState(membershipTypes);
  const [upgradedProducts, setUpgradedProducts] = useState(products);
  const [isFiveYearsCandidateModalOpen, setFiveYearsCandidateModalOpen] = useState(false);
  const toggleFiveYearsCandidateModal = () => {
    setFiveYearsCandidateModalOpen(!isFiveYearsCandidateModalOpen);
  };

  // FCMA modal actions
  const toggleFCMAEthicsModal = () => setIsFCMAIsEthicsModalOpen(false);
  const toggleFCMAIneligibleModalClose = () => setIsFCMAIneligibleModalOpen(false);
  const handleFCMAIneligibleModalContinue = () => {
    // the action is just close the modal
    setIsFCMAIneligibleModalOpen(false);
  };
  const toggleFcmaApplicationFormModal = () => setCimaFcmaApplicationFormModalOpen(false);
  // MiP modal actions
  const toggleMipNotCimaRegularModal = () => setIsMipNotCimaRegularModalOpen(false);
  const toggleIsMipConductNotPassedModal = () => setIsMipConductNotPassedModalOpen(false);
  const toggleAlreadyMipModalOpen = () => setIsAlreadyMipModalOpen(false);
  const toggleMipCimaPracticeCheckModalOpen = () => setIsMipCimaPracticeCheckModalOpen(false);
  const cimaMembershipTerms = useSelector(cimaMembershipTermSelector);
  const userHasCimaRegularMembership = cimaMembershipTerms?.some(
    (item: any) => item?.membershipTermType === MembershipTypes.MembershipKeys.REGULAR
  );

  // ACTIONS

  const navigateToFCMAApplicationJourney = useCallback(async () => {
    if (!isAuth) {
      dispatch(setIsComingFromFcmaPropPageJourney(true));
      dispatch(
        push({
          pathname: getPath(Routes.LOGIN),
          state: { prevRoute },
        })
      );
      return;
    }
    if (userHasFcmaCredential && !isRenewalsJourney) {
      dispatch(push(getPath(Routes.MY_CREDENTIALS)));
      return;
    }
    if (userFcmaHasEthicsViolation) {
      setIsFCMAIsEthicsModalOpen(true);
      return;
    }
    if (!userHasCimaRegularMembership) {
      setIsFCMAIneligibleModalOpen(true);
      return;
    }
    if (isCimaRegularMemberForTenYears || isImpersonation) {
      setIsFCMACartLoading(true);
      const { payload } = await dispatch(getProductItem(upgradedProducts[0].slug));
      await dispatch(setFcmaInUserChoice(payload?.productId, payload?.variants[0].sku));
      await dispatch(setMembershipEvent('isClickedFcmaPropPage', true));
      await dispatch(addMembershipToCart());
      history.push(getPath(Routes.FCMA_CREDENTIAL_APPLICATION_FORM_PERSONAL));
      setIsFCMACartLoading(false);
      return;
    }
    setCimaFcmaApplicationFormModalOpen(true);
    return;
  }, [
    dispatch,
    prevRoute,
    upgradedProducts,
    isAuth,
    userHasFcmaCredential,
    userFcmaHasEthicsViolation,
    userHasCimaRegularMembership,
    isImpersonation,
    isCimaRegularMemberForTenYears,
    history,
    isRenewalsJourney,
  ]);
  // if user is not CIMA Regular and is on MiP prop page, clicking apply will send them to the CIMA Regular application process
  const navigateToCimaRegularJourney = useCallback(async () => {
    dispatch(push(getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH)));
    return;
  }, [dispatch]);
  // Check if user already have MiP credentials
  const navigateToCimaMipApplicationJourney = useCallback(async () => {
    // TODO: Remove this is data is available (For testing purposes only)
    dispatch(getProductItem(upgradedProducts[0].slug));
    if (hasMipCredentialProduct) {
      dispatch(
        push({
          pathname: getPath(Routes.MY_CREDENTIALS),
          state: { prevRoute },
        })
      );
      setIsAlreadyMipModalOpen(true);
      return;
    }
    if (!isAuth) {
      dispatch(
        push({
          pathname: getPath(Routes.LOGIN),
          state: { prevRoute },
        })
      );
      return;
    }
    if (userFcmaHasEthicsViolation) {
      setIsMipConductNotPassedModalOpen(true);
      return;
    }
    if (isImpersonation) {
      if (userFcmaHasEthicsViolation) {
        setIsMipConductNotPassedModalOpen(true);
      }
      if (!userHasCimaRegularMembership) {
        setIsMipNotCimaRegularModalOpen(true);
      }
      if (userHasCimaRegularMembership) {
        dispatch(push({ pathname: getPath(Routes.CIMA_MIP_APPLICATION_MAIN_PAGE), state: { prevRoute } }));
        return;
      }
    }
    if (userHasCimaRegularMembership) {
      setIsMipCimaPracticeCheckModalOpen(true);
      return;
    }
    if (!userHasCimaRegularMembership) {
      setIsMipNotCimaRegularModalOpen(true);
      return;
    }
  }, [
    dispatch,
    prevRoute,
    upgradedProducts,
    isAuth,
    hasMipCredentialProduct,
    userFcmaHasEthicsViolation,
    isImpersonation,
    userHasCimaRegularMembership,
  ]);

  // EFFECTS

  useEffect(() => {
    if (membershipTypes?.length) {
      setMembershipTypeList(membershipTypes);
      if (!isCimaPqCandidateRenewal) {
        handleEvent(membershipTypes, membershipActionNames.STOREFRONT_AGGREGATION_VIEW_TYPE);
      }
    }
  }, [membershipTypes, isCimaPqCandidateRenewal]);
  useEffect(() => {
    setFiveYearsCandidateModalOpen(Boolean(isFiveYearsCandidate));
  }, [isFiveYearsCandidate]);

  useEffect(() => {
    // The selected product is being pre-selected here
    if (upgradedProducts && !!upgradedProducts[0]) {
      if (isCimaMipPropPage || isFCMAPropPageJourney) {
        setSelectedProduct(upgradedProducts[0].slug as string);
        return;
      }
      if (isUserMember) {
        const upgradedProductsSlugs = upgradedProducts.map(p => p.slug);
        setSelectedProduct(
          upgradedProductsSlugs.includes(subscriptionMembershipType?.productSlug)
            ? subscriptionMembershipType?.productSlug || (upgradedProducts[0].slug as string)
            : (upgradedProducts[0].slug as string)
        );
        return;
      }
      // Set the default to whatever the user has selected previously
      // That way we can take control of the userChoice and have a better user experience
      setSelectedProduct(type?.slug || (upgradedProducts[0].slug as string));
      if (shouldShowNewPackageBuilder) {
        setSelectedProduct(type?.slug || '');
      }

      if (type.slug && isCenterMembershipJourney) {
        setSelectedProduct(type.slug);
      }
    }
  }, [
    upgradedProducts,
    isUserMember,
    subscriptionMembershipType,
    inviteId,
    isPaidByFirm,
    isCenterMembershipJourney,
    isCimaMipPropPage,
    isFCMAPropPageJourney,
    type,
    shouldShowNewPackageBuilder,
  ]);
  useEffect(() => {
    if (
      !isImpersonation &&
      isUserMember &&
      !isCenterMembershipJourney &&
      !cancelledMembershipSubscription &&
      !isCimaPqCandidateRenewal
    ) {
      // set only allowed membership
      const allowedUpgradeTypesRef = products.find(
        (product: any) => product.membershipKey === subscriptionMembershipType?.membershipKey
      )?.membershipUpgradeList;
      const allowedUpgradeTypes = products.filter(
        (cmsProduct: any) =>
          allowedUpgradeTypesRef?.includes(cmsProduct.id) ||
          cmsProduct.membershipKey === subscriptionMembershipType?.membershipKey
      );
      // this will be the condition for only updating the products if it's a membership
      if (allowedUpgradeTypesRef && allowedUpgradeTypesRef?.length > 0 && allowedUpgradeTypes?.length > 0) {
        setUpgradedProducts(allowedUpgradeTypes);
        return;
      }
      // if the user has clicked to renew their credential and hasnt yet renewed their membership
      // they end up in this block, and just need to be shown memberships to renew
      if (Object.keys(membershipRenewals).length > 0) {
        setUpgradedProducts(products);
      }
    } else {
      setUpgradedProducts(products);
    }
  }, [
    cancelledMembershipSubscription,
    isUserMember,
    subscriptionMembershipType,
    membershipRenewals,
    products,
    isCenterMembershipJourney,
    isAdmin,
    isImpersonation,
    isCimaPqCandidateRenewal,
  ]);
  useEffect(() => {
    if (isComingFromFcmaPropPage && isFCMAPropPageJourney) {
      if (!userHasCimaRegularMembership) {
        setIsFCMAIneligibleModalOpen(true);
        dispatch(setIsComingFromFcmaPropPageJourney(false));
        return;
      }
      if (userHasFcmaCredential && !isRenewalsJourney) {
        dispatch(setIsComingFromFcmaPropPageJourney(false));
        dispatch(push(getPath(Routes.MY_CREDENTIALS)));
        return;
      }
      if (isCimaRegularMemberForTenYears || isImpersonation) {
        dispatch(setIsComingFromFcmaPropPageJourney(false));
        dispatch(push(getPath(Routes.FCMA_CREDENTIAL_APPLICATION_FORM_PERSONAL)));
        return;
      }
      if (userFcmaHasEthicsViolation) {
        setIsFCMAIsEthicsModalOpen(true);
        dispatch(setIsComingFromFcmaPropPageJourney(false));
        return;
      }
      setCimaFcmaApplicationFormModalOpen(true);
      dispatch(setIsComingFromFcmaPropPageJourney(false));
      return;
    }
  }, [
    dispatch,
    userHasCimaRegularMembership,
    isComingFromFcmaPropPage,
    isFCMAPropPageJourney,
    userHasFcmaCredential,
    isCimaRegularMemberForTenYears,
    isImpersonation,
    userFcmaHasEthicsViolation,
    isRenewalsJourney,
  ]);

  // INTERNAL COMPONENT(S)

  const renderCards = () => {
    const product: PersonalisedMembershipProducts | undefined = upgradedProducts.find(p => p.slug === selectedProduct);

    const handleOnCloseTypeSelection = () => {
      setSelectionCardShow(false);
      setSelectedProduct('');
    };
    return (
      product && (
        <MembershipTypeSelectionCardDesktop
          {...product}
          selectMembership={selectMembership}
          isWideView={isWideView}
          isUserMember={isUserMember}
          // this line fails on envionments when the products are re-imported, because orders lose slugs if the
          // matching product is erased
          isCurrent={
            (isAicpaMembershipPackagePage &&
            typeof inactiveMembership?.inactiveTerms?.[0]?.membershipTermType !== 'undefined'
              ? product?.slug === subscriptionMembershipType?.productSlug ||
                product?.membershipKey === inactiveMembership?.inactiveTerms?.[0]?.membershipTermType || // add this condition if not undefined to get lapsed
                product?.membershipKey === subscriptionMembershipType?.membershipKey
              : product?.slug === subscriptionMembershipType?.productSlug ||
                product?.membershipKey === subscriptionMembershipType?.membershipKey) &&
            (isUserMember || isUserMemberLapsed || isUserMemberSuspended)
          }
          membershipProductsLoaded={membershipProductsLoaded}
          isCenterMembershipJourney={isCenterMembershipJourney}
          isCenterMembershipLoading={isCenterMembershipLoading}
          isPropPage={isPropPage}
          productTitle={product?.header}
          benefitDescription={product?.sub}
          isCimaMipJourney={isCimaMipPropPage}
          isFCMAPropPageJourney={isFCMAPropPageJourney}
          navigateToCimaMipJourney={navigateToCimaMipApplicationJourney}
          navigateToFCMAApplicationJourney={navigateToFCMAApplicationJourney}
          isFCMACartLoading={isFCMACartLoading}
          benefitsListDescription={benefitsListDescription}
          isConsistCenterProduct={isConsistCenterProduct}
          preChecked={false} // should be unchecked by default
          onCloseSelectionCard={handleOnCloseTypeSelection} // tslint:disable-line
          selectedProduct={selectedProduct}
        />
      )
    );
  };

  const membershipTypeSelectionCardMobile = (product?: any, handleOnCloseTypeSelection?: any) => (
    <MembershipTypeSelectionCardMobile
      key={product.id}
      {...product}
      selectMembership={selectMembership}
      isUserMember={isUserMember}
      isCurrent={
        (isAicpaMembershipPackagePage &&
        typeof inactiveMembership?.inactiveTerms?.[0]?.membershipTermType !== 'undefined'
          ? product?.slug === subscriptionMembershipType?.productSlug ||
            product?.membershipKey === inactiveMembership?.inactiveTerms?.[0]?.membershipTermType || // add this condition if not undefined to get lapsed
            product?.membershipKey === subscriptionMembershipType?.membershipKey
          : product?.slug === subscriptionMembershipType?.productSlug ||
            product?.membershipKey === subscriptionMembershipType?.membershipKey) &&
        (isUserMember || isUserMemberLapsed || isUserMemberSuspended)
      }
      isCenterMembershipJourney={isCenterMembershipJourney}
      isCenterMembershipLoading={isCenterMembershipLoading}
      isPropPage={true}
      productTitle={product?.header}
      benefitDescription={product?.sub}
      isCimaMipJourney={isCimaMipPropPage}
      isFCMAPropPageJourney={isFCMAPropPageJourney}
      navigateToCimaMipJourney={navigateToCimaMipApplicationJourney}
      navigateToFCMAApplicationJourney={navigateToFCMAApplicationJourney}
      isFCMACartLoading={isFCMACartLoading}
      benefitsListDescription={benefitsListDescription}
      preChecked={product?.slug === type?.slug}
      onCloseSelectionCard={handleOnCloseTypeSelection} // tslint:disable-line
      selectedProduct={selectedProduct}
    />
  );

  const newAicpaRenewal = useNewMembershipAICPA && isRenewalsJourney;

  const renderMobileCards = () => {
    const product: PersonalisedMembershipProducts | undefined = upgradedProducts.find(p => p.slug === selectedProduct);

    const handleOnCloseTypeSelection = () => {
      setSelectionCardShow(false);
      setSelectedProduct('');
    };

    return product && membershipTypeSelectionCardMobile(product, handleOnCloseTypeSelection);
  };

  const renderMembershipCards = (product: any) => (
    <Grid.Column key={product.id} computer={newAicpaRenewal ? 8 : 6} padded={newAicpaRenewal}>
      <MembershipTypeSelectionButton
        key={product.id}
        {...product}
        handleProductClick={setSelectedProduct}
        selected={product.slug === selectedProduct}
        onClick={() => setSelectionCardShow(true)} // tslint:disable-line
        selectionCardShow={selectionCardShow}
        isCurrent={
          (isAicpaMembershipPackagePage &&
          typeof inactiveMembership?.inactiveTerms?.[0]?.membershipTermType !== 'undefined'
            ? product?.slug === subscriptionMembershipType?.productSlug ||
              product?.membershipKey === inactiveMembership?.inactiveTerms?.[0]?.membershipTermType || // add this condition if not undefined to get lapsed
              product?.membershipKey === subscriptionMembershipType?.membershipKey
            : product?.slug === subscriptionMembershipType?.productSlug ||
              product?.membershipKey === subscriptionMembershipType?.membershipKey) &&
          (isUserMember || isUserMemberLapsed || isUserMemberSuspended)
        }
        isRecommended={
          isSubscribedToCimaAffiliate && product.slug === MembershipTypes.CimaMembershipProductSlug.CIMA_REGULAR_PRODUCT
        }
        isMembershipPackagePage={isAicpaMembershipPackagePage}
        useNewMembershipAICPA={useNewSignUpOnly}
      />
    </Grid.Column>
  );

  const renderMembershipCardContent = (item: any) => (
    <>
      {upgradedProducts && item.slug === selectedProduct && (
        <StyledGrid>
          <Grid.Column computer={5}>
            <StyledDescriptionCard
              className={isCimaMipPropPage || isFCMAPropPageJourney ? 'column is-single-variant-product' : 'column'}
              isWideView={false}
              selectionCardShow={selectionCardShow}
            >
              {selectionCardShow && renderCards()}
            </StyledDescriptionCard>
          </Grid.Column>
        </StyledGrid>
      )}
    </>
  );

  const renderMobile = (item: any) => (
    <>
      {upgradedProducts && item.slug === selectedProduct && (
        <StyledGrid>
          <Grid.Column computer={5} mobile={16}>
            <StyledDescriptionCard
              className={isCimaMipPropPage || isFCMAPropPageJourney ? 'column is-single-variant-product' : 'column'}
              isWideView={false}
              selectionCardShow={selectionCardShow}
            >
              {selectionCardShow && renderMobileCards()}
            </StyledDescriptionCard>
          </Grid.Column>
        </StyledGrid>
      )}
    </>
  );

  // RENDER

  return (
    <Container isCenterMembershipJourney={isCenterMembershipJourney}>
      {isFiveYearsCandidate && (
        <ModalFiveYearsInCandidate
          showFiveYearsInCandidateModal={isFiveYearsCandidateModalOpen}
          membershipTypes={membershipTypeList}
          onClose={toggleFiveYearsCandidateModal}
          setSelectedProduct={setSelectedProduct}
        />
      )}
      {isFCMAEthicsModalOpen && (
        <FcmaEthicsModal onConfirmation={toggleFCMAEthicsModal} onCancel={toggleFCMAEthicsModal} />
      )}
      {isFCMAIneligibleModalOpen && (
        <FcmaIneligibleModal
          onConfirmation={handleFCMAIneligibleModalContinue}
          onCancel={toggleFCMAIneligibleModalClose}
        />
      )}
      {isMipNotCimaRegularModalOpen && (
        <MipApplyCimaRegularModal onConfirm={navigateToCimaRegularJourney} onClose={toggleMipNotCimaRegularModal} />
      )}
      {isCimaFcmaApplicationFormModalOpen && (
        <CimaFcmaApplicationFormModal
          isOpen={isCimaFcmaApplicationFormModalOpen}
          onClose={toggleFcmaApplicationFormModal}
        />
      )}
      {isMipConductNotPassedModalOpen && <MipConductViolationModal onClose={toggleIsMipConductNotPassedModal} />}
      {isAlreadyMipModalOpen && <MipAlreadyMipMemberModal onClose={toggleAlreadyMipModalOpen} />}
      {isMipCimaPracticeCheckModalOpen && (
        <MipCimaPracticeCheckModal
          open={isMipCimaPracticeCheckModalOpen}
          onClose={toggleMipCimaPracticeCheckModalOpen}
          isSubscribedToCimaRegular={userHasCimaRegularMembership}
        />
      )}
      <StyledOnlyDesktopCSS newAicpaRenewal={newAicpaRenewal}>
        <StyledGridContent container className="membership-type-content">
          {!isWideView ? (
            <>
              {isAicpaMembershipPackagePage ? (
                <>
                  {[0, 3].map((startIndex, index) => (
                    <React.Fragment key={startIndex}>
                      <StyledRow>
                        {upgradedProducts
                          ?.slice(startIndex, startIndex + 3)
                          .map(product => renderMembershipCards(product))}
                      </StyledRow>
                      {upgradedProducts
                        ?.slice(startIndex, startIndex + 3)
                        .map(item => renderMembershipCardContent(item))}
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  <Grid.Column computer={7}>
                    {upgradedProducts?.map(product => (
                      <MembershipTypeSelectionButton
                        key={product.id}
                        {...product}
                        handleProductClick={setSelectedProduct}
                        selected={product.slug === selectedProduct}
                        isCurrent={
                          (isAicpaMembershipPackagePage &&
                          typeof inactiveMembership?.inactiveTerms?.[0]?.membershipTermType !== 'undefined'
                            ? product?.slug === subscriptionMembershipType?.productSlug ||
                              product?.membershipKey === inactiveMembership?.inactiveTerms?.[0]?.membershipTermType || // add this condition if not undefined to get lapsed
                              product?.membershipKey === subscriptionMembershipType?.membershipKey
                            : product?.slug === subscriptionMembershipType?.productSlug ||
                              product?.membershipKey === subscriptionMembershipType?.membershipKey) &&
                          (isUserMember || isUserMemberLapsed || isUserMemberSuspended)
                        }
                        isRecommended={
                          isSubscribedToCimaAffiliate &&
                          product.slug === MembershipTypes.CimaMembershipProductSlug.CIMA_REGULAR_PRODUCT
                        }
                        isMembershipPackagePage={isCimaMembershipPackagePage}
                      />
                    ))}
                  </Grid.Column>
                  {upgradedProducts && (
                    <Grid.Column computer={9}>
                      <StyledDescription
                        className={
                          isCimaMipPropPage || isFCMAPropPageJourney ? 'column is-single-variant-product' : 'column'
                        }
                        isWideView={false}
                      >
                        {renderCards()}
                      </StyledDescription>
                    </Grid.Column>
                  )}
                </>
              )}
            </>
          ) : (
            <Grid.Column>
              {upgradedProducts && (
                <StyledDescription
                  className={isCimaMipPropPage || isFCMAPropPageJourney ? 'column is-single-variant-product' : 'column'}
                  isWideView={true}
                >
                  {renderCards()}
                </StyledDescription>
              )}
            </Grid.Column>
          )}
        </StyledGridContent>
      </StyledOnlyDesktopCSS>
      <OnlyMobileCSS>
        {useNewSignUpOnly ? (
          <StyledMobileGrid container centered columns={1}>
            <Grid.Column width={15}>
              <Grid.Row centered>
                {upgradedProducts?.slice(0, 6).map((product, index) => (
                  <React.Fragment key={product.id}>
                    {renderMembershipCards(product)}
                    {renderMobile(product)}
                  </React.Fragment>
                ))}
              </Grid.Row>
            </Grid.Column>
          </StyledMobileGrid>
        ) : (
          <Grid>
            <Grid.Row centered>
              <Grid.Column>{upgradedProducts?.map(product => membershipTypeSelectionCardMobile(product))}</Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </OnlyMobileCSS>
    </Container>
  );
};

const StyledOnlyDesktopCSS = styled(OnlyDesktopCSS)<{ newAicpaRenewal: boolean }>`
  width: ${props => (props.newAicpaRenewal ? `100% !important` : `inherit`)};
`;

const Container = styled.div<{ isCenterMembershipJourney: boolean }>`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  ${props => props.theme.mediaQueries.mobileOnly} {
    position: relative;
  }

  .is-single-variant-product {
    margin: ${props => `0 auto 0 auto`};
    box-shadow: ${props => `0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(14)} 0`}
      ${({ theme }) => theme.colors.neutralGrey6};
    min-height: 0;
  }
`;

const StyledDescriptionCard = styled.div<{ isWideView: boolean; selectionCardShow: boolean }>`
  width: 342%;
  min-height: ${props => (props.selectionCardShow ? props.theme.pxToRem(626) : 0)};
  background-color: ${({ theme }) => theme.colors.neutralGrey2};
  box-sizing: border-box;
  z-index: 1;
  position: relative;
  left: ${props => props.theme.pxToRem(8)};
  bottom: ${props => props.theme.pxToRem(20)};
  .ui.grid > .row {
    display: block;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    left: ${props => props.theme.pxToRem(6)};
  }
`;

const StyledGrid = styled(Grid)`
  position: relative;
  width: 100%;
  right: ${props => props.theme.pxToRem(6)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 0;
  }
`;

const StyledDescription = styled.div<{ isWideView: boolean }>`
  width: ${props => (props.isWideView ? props.theme.pxToRem(900) : props.theme.pxToRem(460))};
  min-height: ${props => props.theme.pxToRem(626)};
  padding: ${props => `${props.theme.pxToRem(44)} ${props.theme.pxToRem(65)}`};
  border-radius: ${props => props.theme.pxToRem(4)};
  box-shadow: ${props => `0 ${props.theme.pxToRem(2)} ${props.theme.pxToRem(14)} `}
    ${({ theme }) => theme.colors.neutralGrey6};
  background-color: ${({ theme }) => theme.colors.neutralWhite};
  box-sizing: border-box;
  z-index: -1;
  .ui.grid > .row {
    display: block;
  }
`;

const StyledMobileGrid = styled(Grid)`
  @media only screen and (max-width: 767px) {
    position: relative;
    left: ${props => props.theme.pxToRem(15)};
  }
`;

const StyledRow = styled(Grid.Row)`
  &&&& {
    flex-wrap: unset;
  }
`;

const StyledGridContent = styled(Grid)`
  &.ui.grid.container {
    width: calc(100%) !important;
    padding-right: ${props => props.theme.pxToRem(15)};
  }
`;
