import { Salesforce, MembershipTypes } from 'mxp-schemas';
import { NO_VALUE_STRING, TRenewMembershipDetailsTableData } from '../types/types';
import { MembershipRegularTypeSkus } from 'modules/membership/constants';
import { formatPrice, fromCentsFormat, getSelectedTierName } from 'utils';

export const getServiceTier = (
  userChoicesTier: string,
  customerMemberships: Salesforce.FirmMembership[],
  employmentData: State.EmploymentData,
  tlwDetails: State.TLWInfoList,
  membershipTiers: any,
  currency: State.ProductCurrency,
  hasSelectedTier: boolean,
  isForSignUp?: boolean
): TRenewMembershipDetailsTableData['serviceTier'] => {
  if (!hasSelectedTier && !isForSignUp) {
    const membershipProductId = customerMemberships[0].membershipTerm.productId;

    const userCurrentTier = membershipTiers?.find((tier: any) => tier?.sku === membershipProductId);
    const price = userCurrentTier?.prices?.find((tierPrice: any) => tierPrice.currency === currency.label)?.amount;

    const selectedTier =
      getSelectedTierName(customerMemberships?.[0]?.membershipTerm?.membershipTermTier) || NO_VALUE_STRING;
    const employmentStatus = getEmploymentStatus(membershipProductId);

    const jobTitles = employmentData?.employers?.map(employer => employer?.jobTitle);

    const filterUniqueJobTitles = (value: string, index: number, self: string[]) => self?.indexOf(value) === index;
    const uniqueJobTitles = jobTitles?.filter(filterUniqueJobTitles);
    const role = uniqueJobTitles?.join(', ');

    const reason = tlwDetails?.tlwReason || NO_VALUE_STRING;

    const data: TRenewMembershipDetailsTableData['serviceTier'] = {
      selectedTier,
      employmentStatus,
      role,
      reason,
      value: `${formatPrice(fromCentsFormat(price), currency.label)}`,
      status:
        'inactive' in customerMemberships[0].membershipTerm
          ? MembershipTypes.MembershipState.EXPIRED
          : MembershipTypes.MembershipState.ACTIVE,
      productId: membershipProductId,
    };
    return data;
  }

  if (hasSelectedTier && userChoicesTier) {
    const membershipProductId = userChoicesTier;

    const userCurrentTier = membershipTiers?.find((tier: any) => tier?.sku === membershipProductId);
    const price = userCurrentTier?.prices?.find((tierPrice: any) => tierPrice.currency === currency.label)?.amount;

    const selectedTier = getSelectedTierName(userCurrentTier?.tierName) || NO_VALUE_STRING;

    const employmentStatus = getEmploymentStatus(membershipProductId);

    const jobTitles = employmentData?.employers?.map(employer => employer?.jobTitle);

    const filterUniqueJobTitles = (value: string, index: number, self: string[]) => self?.indexOf(value) === index;
    const uniqueJobTitles = jobTitles?.filter(filterUniqueJobTitles);
    const role = uniqueJobTitles?.join(', ');

    const reason = tlwDetails?.tlwReason || NO_VALUE_STRING;

    if (isForSignUp) {
      return {
        selectedTier,
        employmentStatus,
        role: employmentData?.newEmployer?.jobTitle,
        reason,
        value: `${formatPrice(fromCentsFormat(price), currency.label)}`,
        status: '',
        productId: membershipProductId,
      };
    }

    const data: TRenewMembershipDetailsTableData['serviceTier'] = {
      selectedTier,
      employmentStatus,
      role,
      reason,
      value: `${formatPrice(fromCentsFormat(price), currency.label)}`,
      status:
        'inactive' in customerMemberships[0].membershipTerm
          ? MembershipTypes.MembershipState.EXPIRED
          : MembershipTypes.MembershipState.ACTIVE,
      productId: membershipProductId,
    };
    return data;
  }
  return undefined;
};

const getEmploymentStatus = (productId: string): string => {
  const isTlw = productId === MembershipRegularTypeSkus.REGULAR_TLW;
  const isRetired = productId === MembershipRegularTypeSkus.RETIRED;

  if (isTlw) {
    return 'Temporary left the workforce (TLW)';
  }

  if (isRetired) {
    return 'Retired';
  }

  return 'Employed';
};
