// ------------------------------------
// Constants
// ------------------------------------

export enum ContractLineItemActionNames {
  SET_LOADING = 'contractLineItem/SET_LOADING',
  SET_ERROR = 'contractLineItem/SET_ERROR',
  CREATE_CONTRACT_LINE_ITEM = 'contractLineItem/CREATE_CONTRACT_LINE_ITEM',
  DELETE_CONTRACT_LINE_ITEM = 'contractLineItem/DELETE_CONTRACT_LINE_ITEM',
}

export const initialState: State.ContractLineItemList = {
  error: null,
  loading: false,
};
