import logoImage from 'resources/pics/aicpa-logo.png';

interface Table {
  headersData: string[];
  rowData: any[];
}

interface CellConfig {
  name: string;
  prompt: string;
  align: 'left' | 'center' | 'right';
  padding: number;
  width: number;
}

export const pdfDownloader = async (tableName: string, fullName: string, table: Table, summary: string) => {
  try {
    const { jsPDF } = await import('jspdf');
    const doc = new jsPDF({ putOnlyUsedFonts: true });
    doc.addImage(logoImage, 'PNG', 8, 10, 24, 8);
    doc.setFontSize(12).text(`Full Name: ${fullName}`, 10, 40, { align: 'left' });
    const headers = headerConfig(table.headersData);
    doc.table(11, 50, table.rowData, headers, { autoSize: false, fontSize: 10 });
    const yCoordinate =
      table.rowData.length === 1
        ? 150
        : table.rowData.length === 2
        ? 175
        : table.rowData.length === 3
        ? 200
        : table.rowData.length === 4
        ? 225
        : 250;
    doc.text(summary, 195, yCoordinate, { align: 'right' });
    doc.text('***NOTE: THIS IS NOT FOR USE WITH CHEQUES***', 20, 285, { align: 'left' });
    return doc.save(`${tableName}.pdf`);
  } catch (error) {
    return;
  }
};

const headerConfig = (keys: string[]): CellConfig[] => {
  const result: CellConfig[] = [];
  for (const key of keys) {
    result.push({
      name: key,
      prompt: key,
      width: 50,
      align: 'center',
      padding: 0,
    });
  }
  return result;
};
