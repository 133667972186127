import React from 'react';
import { generatePath } from 'react-router';
import styled from 'styled-components';
import { ContentCard } from 'components/molecules/ContentCard/ContentCard';
import { HeadingWithIcon, Grid, ButtonLink, ButtonEnums } from 'components/atoms';
import { getPath, emptyArray } from 'utils';
import { Routes, CategoryDecorationMap, FilterNames } from 'constants/index';
import { AGGREGATION_PRODUCT_VIEW, extractProductPayload, handleEvent } from 'utils/Analytics';
import { Product, Content } from 'mxp-schemas';
import { premiumContentTopicalSubItems } from 'mxp-utils/dist/lib/user';
import { extractPremiumPayload } from '../../../utils/Analytics/helpers';
import { useSelector } from 'react-redux';
import { ContentfulHelpers } from 'mxp-utils';
import { staticLandingPageContentTypeSlugSelector } from 'modules/staticLandingPagesContent/selectors';

interface Props {
  topicName: string;
  contentList: State.ContentCardItem[] | null;
  subtopicSlug?: string;
  topicSlug: string;
  customHeadline?: string;
  hideMobileLink?: boolean;
  isTopicAggregationPage?: boolean;
  isAggregationPage?: boolean;
  isProductAggregationPage?: boolean;
  categorySlug?: string;
  cardListTitle?: string;
  productTypeName?: string;
  productTypeSlug?: string;
  customDescription?: string;
  aggregationPageType?: string;
}

export const ContentCardList: React.FC<Props> = ({
  contentList,
  topicSlug = '',
  topicName,
  subtopicSlug,
  customHeadline = '',
  hideMobileLink = false,
  isTopicAggregationPage = false,
  isAggregationPage = false,
  isProductAggregationPage = false,
  cardListTitle = '',
  productTypeName = '',
  productTypeSlug = '',
  customDescription = '',
  aggregationPageType,
}) => {
  React.useEffect(() => {
    const amount = contentList?.length || 0;
    const isPremiumContent = Boolean(premiumContentTopicalSubItems[topicSlug]);

    if (isPremiumContent && amount) {
      const premiumPayload = extractPremiumPayload(contentList || []);
      if (premiumPayload) {
        handleEvent(premiumPayload, AGGREGATION_PRODUCT_VIEW);
      }
    }

    const payload = extractProductPayload(contentList, {
      total: amount,
      from: 1,
      to: amount,
      perPage: amount,
      pageNumber: 1,
    });
    if (!payload) {
      return;
    }
    handleEvent(payload, AGGREGATION_PRODUCT_VIEW);
  }, [contentList, topicSlug]);
  const categoryName: string = contentList?.[0]?.contentCategory.name || '';
  const categorySlug: string = contentList?.[0]?.contentCategory.slug || '';
  const testIdLabel: string = isProductAggregationPage
    ? isTopicAggregationPage
      ? topicSlug
      : productTypeSlug
    : categorySlug;
  const contentLabel: string = isProductAggregationPage
    ? productTypeSlug === Product.ProductSlug.COURSE || productTypeSlug === Product.ProductSlug.CERTIFICATE_PROGRAM
      ? productTypeName
      : `${productTypeName}s`
    : categoryName;

  const emptyItemsArray: number[] =
    contentList && contentList.length < 3 ? Array.from(Array(3 - contentList.length).keys()) : emptyArray;

  const categoryAggsPagePath = getPath(Routes.CATEGORY_AGGS_PAGE_CIMA);

  const linkUrl: string = React.useMemo(() => {
    if (isTopicAggregationPage) {
      const pageType = aggregationPageType === 'topic' ? 'subtopic' : 'subskill';
      const subtopicQueryParam = subtopicSlug ? `?${pageType}=${subtopicSlug}` : '';
      return `${generatePath(categoryAggsPagePath, { categorySlug, topicSlug })}${subtopicQueryParam}`;
    }
    const categoryFilter: string = `${FilterNames.CATEGORY}=${categorySlug}`;
    const topicFilter: string = `&${FilterNames.TOPIC_LEVEL0}=${topicSlug}`;
    const subtopicFilter: string = subtopicSlug ? `&${FilterNames.TOPIC_LEVEL1}=${subtopicSlug}` : '';
    const contentTypeFilter: string = `&${FilterNames.CONTENT_TYPE}=${productTypeSlug}`;
    const certificateProgramFilter = `${FilterNames.GET_NOTICED}=hasCertificateProgram`;
    if (isProductAggregationPage && productTypeSlug && topicSlug) {
      switch (productTypeSlug) {
        case Product.ProductSlug.CONFERENCE:
        case Product.ProductSlug.WEBCAST:
          return `${generatePath(getPath(Routes.PRODUCT_AGGS_CALENDAR))}?${topicFilter}${contentTypeFilter}`;
        case Product.ProductSlug.CERTIFICATE_PROGRAM:
          return `${generatePath(categoryAggsPagePath, {
            categorySlug: Content.CategorySlugs.CPE_LEARNING,
            topicSlug,
          })}?${certificateProgramFilter}`;
        case Product.ProductSlug.EXAM:
        case Product.ProductSlug.COURSE:
        case Product.ProductSlug.PUBLICATION:
        default:
          return `${generatePath(categoryAggsPagePath, {
            categorySlug: Content.CategorySlugs.CPE_LEARNING,
            topicSlug,
          })}?${contentTypeFilter}`;
      }
    }
    return `${getPath(Routes.SEARCH).slice(
      0,
      getPath(Routes.SEARCH).indexOf(':')
    )}?${categoryFilter}${topicFilter}${subtopicFilter}`;
  }, [
    subtopicSlug,
    topicSlug,
    categorySlug,
    isTopicAggregationPage,
    isProductAggregationPage,
    productTypeSlug,
    categoryAggsPagePath,
    aggregationPageType,
  ]);

  const renderLink = (): React.ReactNode => {
    const iconColor: string = CategoryDecorationMap[categorySlug]?.color || CategoryDecorationMap.DEFAULT.color;
    return (
      <StyledButtonLinkWrapper iconColor={iconColor}>
        <ButtonLink
          testId={`content-card-arrow-link-${testIdLabel}`}
          variant={ButtonEnums.variants.link}
          size={ButtonEnums.sizes.small}
          iconName="arrow right"
          iconPosition={ButtonEnums.iconPosition.right}
          to={linkUrl}
        >
          View all {contentLabel}
          {isProductAggregationPage && ` for ${topicName}`}
        </ButtonLink>
      </StyledButtonLinkWrapper>
    );
  };

  const contentTypeSlug = useSelector(staticLandingPageContentTypeSlugSelector);
  const isCimaMipPropPage = ContentfulHelpers.isCimaMipContentType(contentTypeSlug);

  return (
    <StyledContentCardList data-testid={`content-card-list-${testIdLabel}`} data-aggregation-page={isAggregationPage}>
      {!isAggregationPage &&
        (customHeadline ? (
          <StyledHeading className={isCimaMipPropPage ? 'CimaMipStyles' : ''}>{customHeadline}</StyledHeading>
        ) : (
          <StyledTopHolder data-testid={`content-card-top-box-${testIdLabel}`}>
            {renderLink()}
            <HeadingWithIcon
              title={isProductAggregationPage ? cardListTitle : categoryName}
              categorySlug={categorySlug}
            />
          </StyledTopHolder>
        ))}

      {!isAggregationPage && customDescription && <StyledDescription>{customDescription}</StyledDescription>}
      <Grid data-testid={`content-cards-holder-${testIdLabel}`}>
        <Grid.Row columns={3}>
          {contentList &&
            contentList.map((contentCardItem: State.ContentCardItem) => (
              <StyledContentCardContainer key={contentCardItem.id} data-aggregation-page={isAggregationPage}>
                <ContentCard
                  isAggregationCard
                  contentCardItem={contentCardItem}
                  testid={`content-card-${testIdLabel}`}
                  isTransparentCard={isCimaMipPropPage}
                />
              </StyledContentCardContainer>
            ))}
          {emptyItemsArray.map((item: number) => (
            <Grid.Column key={item}>
              <StyledEmptyCard data-testid={`content-card-empty-${testIdLabel}`}>
                <span>
                  More {topicName} {contentLabel} will be released in the future
                </span>
              </StyledEmptyCard>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      {!isAggregationPage && !hideMobileLink && (
        <StyledMobileArrowLinkHolder>{renderLink()}</StyledMobileArrowLinkHolder>
      )}
    </StyledContentCardList>
  );
};

const StyledHeading = styled.h3<Partial<Props>>`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: ${props => props.theme.fontSizes.xl};
  padding-bottom: ${props => props.theme.pxToRem(16)};
  padding-top: ${props => props.theme.pxToRem(16)};
  margin: 0;

  &.CimaMipStyles {
    color: ${props => props.theme.colors.neutralWhite};
    margin: 0 auto ${props => props.theme.pxToRem(24)};
    font-size: ${props => props.theme.fontSizes.xl};
    text-align: center;
  }
`;

const StyledContentCardList = styled.section<any>`
  margin: 0 0 ${props => props.theme.pxToRem(props['data-aggregation-page'] ? 16 : 50)};
  padding: 0 0 ${props => props.theme.pxToRem(50)};
  border-bottom: 1px solid ${props => props.theme.colors.neutralGrey3};
  &:last-child {
    border: 0;
    margin: 0;
    padding-bottom: 0;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: 0 0 ${props => props.theme.pxToRem(40)};
    padding: 0 0 ${props => props.theme.pxToRem(40)};
    &:last-child {
      margin: 0 0 ${props => props.theme.pxToRem(10)};
      padding-bottom: ${props => props.theme.pxToRem(40)};
    }
  }
`;

const StyledTopHolder = styled.div`
  overflow: hidden;
  margin: 0 0 ${props => props.theme.pxToRem(20)};
`;

const StyledButtonLinkWrapper = styled.span<{ iconColor: string }>`
  &&& {
    float: right;

    > a {
      padding-right: 0;
    }

    i.icon {
      color: ${props => props.iconColor};
      margin-right: 0;
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    display: none;
  }
`;

const StyledContentCardContainer = styled(Grid.Column)<any>`
  &&&&& {
    margin-bottom: ${props => props['data-aggregation-page'] && props.theme.pxToRem(40)};
  }
`;

const StyledEmptyCard = styled.div`
  height: 100%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    display: none;
  }

  span {
    padding: ${props => props.theme.pxToRem(10)};
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background: ${props => props.theme.colors.neutralGrey1};
    border: 1px solid ${props => props.theme.colors.neutralGrey2};
    border-radius: ${props => props.theme.pxToRem(4)};
    text-align: center;
    font-size: ${props => props.theme.fontSizes.m};
    line-height: ${props => props.theme.fontSizes.l};
    color: ${props => props.theme.colors.neutralGrey5};
    font-weight: ${props => props.theme.fontWeights.light};
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
`;

const StyledMobileArrowLinkHolder = styled.div`
  display: none;
  overflow: hidden;

  ${props => props.theme.mediaQueries.mobileOnly} {
    display: block;
    > span {
      display: block;
    }
  }
`;

const StyledDescription = styled.p`
  color: ${props => props.theme.colors.neutralWhite};
  margin: 0 auto ${props => props.theme.pxToRem(50)};
  font-size: ${props => props.theme.fontSizes.m};
  text-align: center;
`;
