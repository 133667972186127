import { createSelector } from 'reselect';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';
import { AuthenticationPageToShow } from 'modules/layouts/constants';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.layouts,
  (layouts: State.Layouts): State.Layouts => layouts
);

export const burgerExpandedSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.burgerExpanded
);

export const searchModalExpandedSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.searchModalExpanded
);

export const userSettingsExpandedSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.userSettingsExpanded
);
export const acrossLinksExpandedSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.acrossLinksExpand
);

export const adminStickyFooterInvoiceFilterVisibleSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.adminStickyFooterInvoiceFilterVisible
);

export const selectedTopicIdSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string | null => layouts.selectedTopicId
);

export const selectedIndustryIdSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string | null => layouts.selectedIndustryId
);

export const selectedSkillIdSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string | null => layouts.selectedSkillId
);

export const selectedJobRoleIdSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string | null => layouts.selectedJobRoleId
);

export const selectedCareerStageIdSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string | null => layouts.selectedCareerStageId
);

export const selectedContentLanguageIdSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string | null => layouts.selectedContentLanguageId
);

export const selectedLocationIdSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string | null => layouts.selectedLocationId
);

export const currentContentIdSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string | null => layouts.currentContentId
);

export const previousContentIdSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string | null => layouts.previousContentId
);

export const megaMobileNavHistoryLastSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string => layouts.megaMobileNavHistory.slice(-1)[0]
);

export const modalTopicUnfollowMetaSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): State.ModalTopicUnfollowMeta | null => layouts.modalTopicUnfollowMeta
);

export const isPaymentProcessingModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isPaymentProcessingModalOpen
);

export const isOnboardingFinishedModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isOnboardingFinishedModalOpen
);

export const isOnboardingStartModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isOnboardingStartModalOpen
);

export const cancelSOrderModalMetaSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): State.CancelSOrderModalMeta => layouts.cancelSOrderModalMeta
);

export const isProvisionProcessingModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isProvisionProcessingModalOpen
);

export const isAddressValidationModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isAddressValidationModalOpen
);

export const allowUserToBypassAddressValidationSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): State.AddressValidationBypassFlags => {
    return {
      allowShippingAddressValidationBypass:
        layouts.allowUserToBypassAddressValidation?.allowShippingAddressValidationBypass || false,
      allowBillingAddressValidationBypass:
        layouts.allowUserToBypassAddressValidation?.allowBillingAddressValidationBypass || false,
    };
  }
);

export const isExtendAccessModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isExtendAccessModalOpen
);

export const isInvalidAddressModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isInvalidAddressModalOpen
);

export const isModalOnboardingCancelOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isOnboardingCancelModalOpen
);
export const reloadAfterLoginSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.reloadAfterLogin
);

export const paginationInfoSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): State.PaginationInfo => layouts.paginationInfo
);

const hashSelector = createSelector(
  (state: any) => state.router,
  (router: any): string => router.location.hash
);

export const isAuthenticationModalVisibleSelector = createSelector(
  hashSelector,
  (hash: any): boolean => hash === getPath(Routes.REGISTRATION_HASH) || hash === getPath(Routes.LOGIN_HASH)
);

export const pageToShowSelector = createSelector(
  (state: any) => state.router,
  (router: any): AuthenticationPageToShow => {
    if (router.location.hash === getPath(Routes.LOGIN_HASH)) {
      return AuthenticationPageToShow.LOGIN_MODAL;
    }
    if (router.location.hash === getPath(Routes.REGISTRATION_HASH)) {
      return AuthenticationPageToShow.REGISTRATION_MODAL;
    }
    if (router.location.pathname === getPath(Routes.LOGIN)) {
      return AuthenticationPageToShow.LOGIN_PAGE;
    }
    if (router.location.pathname === getPath(Routes.REGISTRATION)) {
      return AuthenticationPageToShow.REGISTRATION_PAGE;
    }
    return AuthenticationPageToShow.OTHER;
  }
);

export const isCardSetDefaultModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isCardSetDefaultModalOpen
);

export const isCardRemoveModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isCardRemoveModalOpen
);

export const isCimaDeleteDefaultPaymentModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isCimaDeleteDefaultPaymentModalOpen
);

export const isCimaDeleteOnlyPaymentMethodModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isCimaDeleteOnlyPaymentMethodModalOpen
);

export const isDeleteCardBeingProcessedSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isDeleteCardBeingProcessed
);

export const isCardOperationBannerVisibleSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isCardOperationBannerVisible
);

export const cardBeingProcessedSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): State.CreditCard | null => layouts.cardBeingProcessed
);

export const isCardBeingProcessedDefaultSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.cardBeingProcessed?.defaultPaymentMethod || false
);

export const cardProcessedSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): State.CreditCard | null => layouts.cardProcessed
);

export const creditCardOperationSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): State.CreditCardOperation => layouts.creditCardOperation
);

export const orgProfileExpandedSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.orgProfileExpanded
);

export const isToggleSubscriptionRenewalModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.subscriptionRenewalToggle?.enable || false
);

export const orderBeingProcessedSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): Common.ProductItemData | null => layouts.subscriptionRenewalToggle?.item || null
);

export const subscriptionRenewalToggleSuccessSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean | null => layouts.subscriptionRenewalToggleSuccess
);

export const subscriptionRenewalToggleLoadingSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.subscriptionRenewalToggleLoading
);

export const toggleSubscriptionRenewalHasDefaultCardSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean | null => layouts.subscriptionRenewalToggle?.hasDefaultCard || false
);

export const recentlyAddedBundleDiscountPercentageSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string | null => layouts.recentlyAddedBundleDiscountPercentage
);

export const cartBundleMergeBannerMessageSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): string => layouts.cartBundleMergeBannerMessage
);

export const isCartContainsInvalidBundlesSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isCartContainsInvalidBundles
);

export const isImpersonatedUserPasswordModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean => layouts.isImpersonatedUserPasswordModalOpen
);

export const cartMigrationMessagesSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): State.MigrationMessages => layouts.cartMigrationMessages || {}
);

export const isAddCreditCardModalOpenSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): any => layouts.isAddCreditCardModalOpen
);

export const CimaDeleteOnlyPaymentMethodModalButtonLoadingSelector = createSelector(
  rootSelector,
  (layouts: State.Layouts): boolean | undefined => layouts?.cimaDeleteOnlyPaymentMethodModalButtonLoading
);
