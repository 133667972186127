import { createAction } from 'redux-actions';
import { default as request } from 'utils/GraphQLClient';
import { GET_FEATURE_TOGGLES } from 'mxp-graphql-queries';

interface IFeatureToggle {
  key: string;
  toggleCheck: boolean;
}

export const getFeatureToggles: any = createAction(
  'featureToggle/GET_FEATURE_TOGGLE',
  () => () => request(GET_FEATURE_TOGGLES)
);

export const loadFeatureToggles: any = createAction('featureToggle/LOAD_FEATURE_TOGGLE');

export const toggleFeature: any = createAction('featureToggle/TOGGLE_FEATURE', (featureToggle: IFeatureToggle) => {
  return featureToggle;
});
