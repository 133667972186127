import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.notifications,
  (notifications: State.Notification): State.Notification => notifications
);

export const notificationsSelector = createSelector(
  rootSelector,
  (notifications: State.Notification): State.NotificationItem[] => notifications.notificationItems
);
