import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { toggleNotification } from 'modules/notifications/actions';
import copyLib from 'copy-to-clipboard';

export const copy: any = createAction(
  'copy/COPY_ITEM',
  ({ notificationText, copiedText, id, title }: State.CopiedItem) => (dispatch: Dispatch) => {
    const notificationItem: any = {
      id: `copy-link-${id}`,
      title: title || 'Link copied',
      text: notificationText,
      type: 'copy',
    };
    copyLib(copiedText);

    dispatch(toggleNotification(notificationItem));
    return Promise.resolve({
      ...notificationItem,
      copiedText,
    });
  }
);
