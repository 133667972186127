import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'components/atoms';
import { ReactComponent as Correct } from 'resources/images/icon-dev-ic-check-circle.svg';
import { DefaultTheme, ThemedStyledProps } from 'styled-components/macro';
import { ReactComponent as Wrong } from 'resources/images/ic-cancel.svg';
import { ShowPasswordInput } from 'components/molecules/ShowPasswordInput/ShowPasswordInput';

export interface InputWithValidationProps {
  labelName?: string;
  name: string;
  testId: string;
  labelId?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  iconElemCorrect?: any;
  iconElemWrong?: any;
  isCorrect: boolean | React.ReactNode;
  showEmptyError?: boolean;
  onlyLegalCharacters?: boolean;
  onlyLegalNumber?: boolean;
  autoFocus?: boolean;
  errorMessage?: string | React.ReactNode;
  infoMessage?: string | React.ReactNode;
  onPaste?: (e: any) => void;
  getIsFocused?: (e: boolean) => void;
  isCorrectIconShown?: boolean;
  inputRef?: any;
  type: string;
  externalIsValid?: boolean;
  password?: boolean;
  placeholder?: string;
  clear?: () => void;
  className?: string;
  absoluteErrorMessage?: boolean;
  validationMessageDisabled?: boolean;
  disabled?: boolean;
  onKeyPress?: (e: any) => void;
  handleOnBlur?: (e: any) => void;
  allowedValidationOnload?: boolean;
  activeRow?: number;
  currentRow?: number;
  isRowIncomplete?: boolean;
  onFocus?: (currentRow: number) => void;
  isLoading?: boolean;
  maxLength?: number;
}

export const InputWithValidation: React.FC<InputWithValidationProps> = ({
  testId,
  labelId,
  labelName,
  name,
  onChange,
  onClick,
  value,
  isCorrect,
  showEmptyError,
  onlyLegalCharacters,
  onlyLegalNumber,
  autoFocus,
  errorMessage,
  infoMessage,
  onPaste,
  isCorrectIconShown,
  inputRef,
  type,
  externalIsValid = true,
  getIsFocused,
  password,
  placeholder,
  clear,
  className,
  absoluteErrorMessage,
  validationMessageDisabled,
  disabled,
  onKeyPress,
  handleOnBlur,
  allowedValidationOnload = true,
  isLoading,
  maxLength,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false);
  const [isTouched, setTouched] = useState(false);

  const handleBlur = React.useCallback(
    (e: any) => {
      handleOnBlur?.(e);
      setFocused(false);
      if (getIsFocused) {
        getIsFocused(false);
      }
    },
    [getIsFocused, handleOnBlur]
  );
  const handleFocus = React.useCallback(() => {
    setFocused(true);
    setTouched(true);
    if (getIsFocused) {
      getIsFocused(true);
    }
  }, [getIsFocused]);

  const hasValue = Boolean(value?.length);

  const iconWrongToShow = hasValue && !password && <StyledWrongIcon onClick={clear} />;
  const icon =
    !isFocused &&
    (isCorrect && externalIsValid ? hasValue && isCorrectIconShown && <StyledCorrectIcon /> : iconWrongToShow);
  const hasError = allowedValidationOnload
    ? Boolean(isTouched && (!isCorrect || (!externalIsValid && hasValue)))
    : Boolean(!isCorrect || (!externalIsValid && hasValue));
  const validationMessageValue = isFocused ? infoMessage || '\u00A0' : (hasError && errorMessage) || '\u00A0';
  const colourAsError = hasError && !isFocused;
  return (
    <>
      <StyledInputValidation
        fluid
        labelName={labelName}
        name={name}
        value={value}
        testId={testId}
        labelId={labelId}
        autoComplete="off"
        onChange={onChange}
        onClick={onClick}
        onBlur={handleBlur}
        onFocus={handleFocus}
        icon={icon}
        autoFocus={autoFocus}
        hasError={colourAsError}
        onPaste={onPaste}
        inputRef={inputRef}
        type={type}
        password={password}
        placeholder={placeholder}
        className={className}
        disabled={disabled}
        onKeyPress={onKeyPress}
        isLoading={isLoading}
        maxLength={maxLength}
        {...props}
      />
      {showEmptyError && (
        <ValidationMessage hasError={true} absoluteErrorMessage={false}>
          Please enter {labelName}
        </ValidationMessage>
      )}
      {!value ||
        (value?.length && onlyLegalCharacters && (
          <ValidationMessage hasError={true} absoluteErrorMessage={false}>
            You have entered an invalid character
          </ValidationMessage>
        ))}
      {!validationMessageDisabled && (
        <ValidationMessage hasError={colourAsError} absoluteErrorMessage={absoluteErrorMessage}>
          {validationMessageValue}
        </ValidationMessage>
      )}
      {!value ||
        (value.length > 0 && onlyLegalNumber && (
          <ValidationMessage hasError={true} absoluteErrorMessage={false}>
            You have entered an invalid value
          </ValidationMessage>
        ))}
    </>
  );
};

interface ValidationMessageProps {
  hasError?: boolean;
  absoluteErrorMessage?: boolean;
}

const ValidationMessage = styled.div<ThemedStyledProps<ValidationMessageProps, DefaultTheme>>`
  margin-bottom: ${props => props.theme.pxToRem(4)};
  color: ${props => props.theme.colors.neutralGrey5};
  font-size: ${props => props.theme.fontSizes.xxs};
  ${({ hasError, theme, absoluteErrorMessage }) =>
    hasError &&
    `
      color: ${theme.colors.interfaceRed};
      position: ${absoluteErrorMessage ? 'absolute' : 'static'}
    `}
`;

const StyledCorrectIcon = styled(Correct)`
  position: absolute;
  width: ${props => props.theme.pxToRem(23)};
  height: ${props => props.theme.pxToRem(23)};
  right: ${props => props.theme.pxToRem(13)};
  top: ${props => props.theme.pxToRem(11)};
  color: ${props => props.theme.colors.interfaceGreen};
`;

const StyledWrongIcon = styled(Wrong)`
  &&&&&&& {
    position: absolute;
    right: ${props => props.theme.pxToRem(12)};
    top: ${props => props.theme.pxToRem(12)};
    width: ${props => props.theme.pxToRem(23)};
    height: ${props => props.theme.pxToRem(23)};
    color: ${props => props.theme.colors.interfaceRed};
  }
`;

const StyledInputValidation = styled(({ hasError, children, password, ...rest }) =>
  password ? <ShowPasswordInput {...rest}>{children}</ShowPasswordInput> : <Input {...rest}>{children}</Input>
)`
  padding-bottom: ${props => props.theme.pxToRem(4)};
  ${({ hasError, theme }) =>
    hasError &&
    `
      &&&& > input {
        :focus {
          border: solid ${theme.pxToRem(1)} ${theme.colors.interfaceRed};
        }
        border: solid ${theme.pxToRem(1)} ${theme.colors.interfaceRed};
      }
    `}
`;
