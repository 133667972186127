import React from 'react';
import { StrictDropdownProps, DropdownItemProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { registrationRestrictedCountriesSelector } from 'modules/featureToggle/selectors';
import { Dropdown } from 'components/atoms/Dropdown/Dropdown';
import { CountriesList } from 'mxp-utils';
import { CHECKMARK_GREY } from 'resources/images';

interface Props {
  options?: DropdownItemProps[];
  onChange: (e: any, data: StrictDropdownProps) => void;
  title: string;
  countrySelected: string;
}

export const CountriesDropdown: React.FC<Props> = ({
  onChange,
  options = CountriesList,
  title,
  countrySelected,
  ...props
}) => {
  const restrictedCountries = useSelector(registrationRestrictedCountriesSelector);
  const filteredOptions = Object.values(options).filter(
    option => !restrictedCountries.some(restrictedCountry => restrictedCountry === option.value)
  );

  const countriesOptions = React.useMemo(
    () =>
      Object.values(filteredOptions).map(country => {
        if (country.textUI) {
          return { ...country, text: country.textUI };
        }
        return country;
      }),
    [filteredOptions]
  );

  return (
    <DropDownContainer>
      <Label>{title}</Label>
      <StyledDropdown
        selection
        testId="dropdown-select-country"
        onChange={onChange}
        search={true}
        placeholder="Select country/region"
        options={countriesOptions}
        value={countrySelected}
        {...props}
      />
    </DropDownContainer>
  );
};

export const DropDownContainer = styled.div`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(26)};
  }
`;

export const StyledDropdown = styled(Dropdown)`
  ${props => `
    &&& {
      width: 100%;
      height: ${props.theme.pxToRem(48)};
      padding-right: ${props.theme.pxToRem(10)};

      &&& img {
        width: ${props.theme.pxToRem(32)};
        height: ${props.theme.pxToRem(32)};
      }

      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }

      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.primaryPurple};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }

      &&&.ui.dropdown > .menu {
        max-height: ${props.theme.pxToRem(170)};
      }

      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        border-top: 1px solid ${props.theme.colors.primaryFadedPurple};
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: 1px solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.neutralGrey1};
          color: ${props.theme.colors.primaryPurple};
          font-weight: ${props.theme.fontWeights.regular};
        }
      }

      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        border-bottom: 1px solid blue;
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
      &&&.ui.selection.dropdown {
        display: inline-flex;
        padding: 0 ${props.theme.pxToRem(10)} 0 0;
        color: ${props.theme.colors.primaryPurple};
      }
    }
  `}
`;

export const StyledDropdownNew = styled(Dropdown)`
  ${props => `
    &&& {
      width: 100%;
      height: ${props.theme.pxToRem(48)};
      padding-right: ${props.theme.pxToRem(10)};

      &&& img {
        width: ${props.theme.pxToRem(32)};
        height: ${props.theme.pxToRem(32)};
      }
      border: 1px solid #D9D9D9 !important;
      border-radius:  ${props.theme.pxToRem(5)} !important;

      &&&.ui.dropdown > .text {
        overflow: hidden;
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        font-size: ${props.theme.fontSizes.s};
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &&&.ui.dropdown .selected > .text {
        font-weight: ${props.theme.fontWeights.regular};
      }

      &&&.ui.dropdown > .default {
        color: ${props.theme.colors.primaryPurple};
        ${props.theme.mediaQueries.mobileOnly} {
          font-size: ${props.theme.fontSizes.xs};
        }
      }

      &&&.ui.dropdown > .menu {
        max-height: ${props.theme.pxToRem(170)};
        margin-top: ${props.theme.pxToRem(6)};
      }

      &&&.ui.dropdown .menu > .message {
        font-family: ${props.theme.fontFamily};
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
      }
      &&&.ui.active.visible.search.dropdown > .menu {
        // border-top: 1px solid ${props.theme.colors.primaryFadedPurple};
        border: 1px solid #D9D9D9 !important;
        border-radius: ${props.theme.pxToRem(5)} !important;
        &:before {
          display: none;
        }
      }
      &&&.ui.dropdown .menu > .item {
        font-size: ${props.theme.fontSizes.xs};
        font-weight: ${props.theme.fontWeights.light};
        padding: 0.6rem 1rem !important;
        border-top: 1px solid ${props.theme.colors.neutralGrey1};
        line-height: 1.57;
        &:first-of-type {
          border-top: none;
        }
        &:hover {
          background: ${props.theme.colors.primaryPurple};
          color: ${props.theme.colors.neutralWhite};
          font-weight: ${props.theme.fontWeights.regular};
          margin: ${props.theme.pxToRem(5)};
          border-radius:  ${props.theme.pxToRem(4)};
          background-image: url('${CHECKMARK_GREY}') !important;
          background-position: right 5px center;
          background-size:  ${props.theme.pxToRem(20)};
          background-repeat: no-repeat;
          
        }
      }
      
      &&&.ui.search.dropdown > input {
        font-family: ${props.theme.fontFamily};
        color: transparent;
        font-size: ${props.theme.fontSizes.s};
        line-height: ${props.theme.pxToRem(24)};
        padding: ${props.theme.pxToRem(9)} ${props.theme.pxToRem(15)};
        // border-bottom: 1px solid blue;
      }
      &&&.ui.search.dropdown.visible > input {
        color: ${props.theme.colors.neutralBlack};
      }
      &&&.ui.selection.dropdown {
        display: inline-flex;
        padding: 0 ${props.theme.pxToRem(10)} 0 0;
        color: ${props.theme.colors.primaryPurple};
      }
    }
  `}
`;

export const Label = styled.label`
  display: block;
  padding-bottom: ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  letter-spacing: ${props => props.theme.pxToRem(0.2)};
  &&&&& {
    font-weight: ${props => props.theme.fontWeights.regular};
  }
`;
