import React from 'react';
import styled, { css } from 'styled-components';
import { Accordion, AccordionTitleProps, Transition } from 'semantic-ui-react';
import { Container } from 'components/atoms';
import { User } from 'mxp-schemas';
import ArrowDownIcon from 'resources/images/ic_check_circle2.svg';
import ArrowUpIcon from 'resources/images/ic_check_circle.svg';

interface Props {
  data: User.StudentExamSectionResults | User.Exams | User.PreviousExamResults;
  activeIndices: ActiveIndexProps[];
  onClick: AccordionTitleProps['onClick'];
  isAllExams?: boolean;
}

interface ActiveIndexProps {
  stepNumber: number;
  isOpen: boolean;
}

export const CimaExamResultsSections: React.FC<Props> = React.memo(
  ({ data, activeIndices, onClick, isAllExams = false }) => {
    return (
      <Container>
        <Accordion>
          <StyledUl>
            {data.examSectionResults.map((section, index: number) => (
              <>
                <StyledAccordionTitle active={activeIndices[index].isOpen} index={index} onClick={onClick} key={index}>
                  <StyledLi
                    status={section.proficiency.name}
                    isActive={activeIndices[index].isOpen}
                    isAllExams={isAllExams}
                  >
                    <StyledLiContainer>
                      <StyledSpan>
                        {section.name} ({section.weight}%)
                      </StyledSpan>
                      <StyledLiIcons>
                        {section.proficiency.name === User.StudentExamProficiencyLevels.PASS ||
                        section.proficiency.name === User.StudentExamProficiencyLevels.PROFICIENT ||
                        section.proficiency.name === User.StudentExamProficiencyLevels.DISTINCTION ? (
                          <StyledArrowUp>
                            <img src={ArrowUpIcon} alt={User.StudentExamProficiencyLevels.PASS} />
                          </StyledArrowUp>
                        ) : section.proficiency.name === User.StudentExamProficiencyLevels.FAIL ||
                          section.proficiency.name === User.StudentExamProficiencyLevels.NOT_PROFICIENT ||
                          section.proficiency.name === User.StudentExamProficiencyLevels.NON_PROFICIENT ||
                          section.proficiency.name === User.StudentExamProficiencyLevels.NOT_PROFICIENT_LC ? (
                          <StyledArrowDown>
                            <img src={ArrowDownIcon} alt={User.StudentExamProficiencyLevels.FAIL} />
                          </StyledArrowDown>
                        ) : null}
                        <StyledInfoPillContainer>
                          {activeIndices[index].isOpen ? (
                            <StyledH1 isAllExams={isAllExams}>-</StyledH1>
                          ) : (
                            <StyledH1 isAllExams={isAllExams}>+</StyledH1>
                          )}
                        </StyledInfoPillContainer>
                      </StyledLiIcons>
                    </StyledLiContainer>
                  </StyledLi>
                </StyledAccordionTitle>

                <Transition visible={activeIndices[index].isOpen} animation="scale" duration={500}>
                  <StyledAccordionContent active={activeIndices[index].isOpen}>
                    <span dangerouslySetInnerHTML={{ __html: section.proficiency.feedback }} />
                  </StyledAccordionContent>
                </Transition>
              </>
            ))}
          </StyledUl>
        </Accordion>
      </Container>
    );
  }
);

const StyledUl = styled.div`
  background-position: left;
  background-size: ${props => props.theme.pxToRem(1)} ${props => props.theme.pxToRem(20)};
  background-repeat: repeat-y;
  margin: ${props => props.theme.pxToRem(26)} 0;
  padding-top: ${props => props.theme.pxToRem(10)};
`;

const StyledLi = styled.div<{ status: string; isActive: boolean; isAllExams: boolean }>`
  ::after {
    content: '';
    height: ${props => props.theme.pxToRem(1.44)} !important;
    border-radius: ${props => props.theme.pxToRem(4)};
    display: block;
    background-image: linear-gradient(87deg, #672d89, ${props => props.theme.colors.neutralGrey8} 100%);

    ${({ isActive, isAllExams }) =>
      isAllExams
        ? css`
            height: ${props => props.theme.pxToRem(1)} !important;
            border-radius: ${props => props.theme.pxToRem(4)};
            background-image: linear-gradient(
              87deg,
              ${props => props.theme.colors.neutralGrey8},
              ${props => props.theme.colors.neutralGrey8} 100%
            );
            width: ${props => props.theme.pxToRem(60)};
            margin-bottom: ${props => props.theme.pxToRem(10)};
          `
        : isActive
        ? css`
            width: inherit;
            position: relative;
            top: ${props => props.theme.pxToRem(4)};
          `
        : css`
            width: ${props => props.theme.pxToRem(60)};
            position: relative;
            top: ${props => props.theme.pxToRem(4)};
          `}

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(20)};
    }
  }
`;

const StyledLiContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLiIcons = styled.div`
  justify-content: flex-end;
  width: ${props => props.theme.pxToRem(60)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: inline-flex;
    margin-left: ${props => props.theme.pxToRem(10)};
  }
`;

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.33;
  font-family: roboto;

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.m};
  }
`;

const StyledH1 = styled.span<{ isAllExams: boolean }>`
  color: ${props => props.theme.colors.primaryPurple};
  font-size: ${props => props.theme.fontSizes.xl};

  ${({ isAllExams }) =>
    isAllExams &&
    css`
      color: ${props => props.theme.colors.neutralGrey9};
    `}
`;

const StyledAccordionTitle = styled(Accordion.Title)`
  padding: ${props => props.theme.pxToRem(10)} 0;
  margin-bottom: ${props => props.theme.pxToRem(15)};
`;

const StyledAccordionContent = styled(Accordion.Content)`
  margin: ${props => `0 0 0 0`} !important;
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  word-wrap: break-word;
  overflow: auto;
  color: #282937;
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => props.theme.pxToRem(40)} 0 0 0;
  }
`;

const StyledInfoPillContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: right;
  display: inline;
`;

const StyledArrowUp = styled.div`
  fill: ${props => props.theme.colors.secondaryTeal};
  width: 1.5rem;
  height: ${props => props.theme.pxToRem(17)};
  display: inline;
  margin-right: 20px;
  position: relative;
  bottom: ${props => props.theme.pxToRem(2)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    position: relative;
    top: ${props => props.theme.pxToRem(15)};
  }
`;

const StyledArrowDown = styled.div`
  fill: ${props => props.theme.colors.interfaceRed};
  width: 1.5rem;
  height: ${props => props.theme.pxToRem(17)};
  display: inline;
  margin-right: 20px;
  position: relative;
  bottom: ${props => props.theme.pxToRem(2)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    position: relative;
    top: ${props => props.theme.pxToRem(15)};
  }
`;
