import { Product } from 'mxp-schemas';
export const filterProductsByCategory = (
  products: any[],
  categories: State.ProductCategories[],
  categoryName: string
) => {
  const currentPageOfSpecificCategory = categories
    .filter(category => category.name === categoryName)
    .map(category => category.page)[0];

  let indexOfAllProducts = -1;
  let indexOfFilteredProducts = -1;

  const updateSelectedCategory = categories.map(product =>
    product && product.name === categoryName ? { ...product, selected: true } : { ...product, selected: false }
  );

  const filteredProducts = products.map(product => {
    indexOfAllProducts += product ? 1 : 0;

    if (categoryName === 'All' && indexOfAllProducts <= currentPageOfSpecificCategory * 6 - 1) {
      return { ...product, visible: true, filtered: false };
    }

    if (
      !!product.categories?.length &&
      product.categories.some((category: { name: string }) => category.name === categoryName)
    ) {
      indexOfFilteredProducts += 1;

      if (indexOfFilteredProducts <= currentPageOfSpecificCategory * 6 - 1) {
        return {
          ...product,
          visible: true,
          filtered: categoryName === 'All' ? false : true,
        };
      }
    }
    return { ...product, visible: false, filtered: categoryName === 'All' ? false : true };
  });

  return {
    updateSelectedCategory,
    filteredProducts,
  };
};

export const seeMoreProducts = (products: any[], categories: State.ProductCategories[]) => {
  // TODO: slice to get the first n elements. Else, filter products to only products of that category then slice.
  const ITEM_COUNT_PER_PAGINATION = 6;

  const selectedCategory = categories.filter(category => category.selected);
  const { page: currentPageOfSpecificCategory, name: categoryName } = selectedCategory[0];

  let indexOfAllProducts = -1;
  let indexOfFilteredProducts = -1;

  const moreProducts = products.map(product => {
    indexOfAllProducts += product ? 1 : 0;

    if (categoryName === 'All' && indexOfAllProducts <= currentPageOfSpecificCategory * 6 - 1) {
      return {
        ...product,
        visible:
          indexOfAllProducts <= ITEM_COUNT_PER_PAGINATION * currentPageOfSpecificCategory - 1 &&
          ITEM_COUNT_PER_PAGINATION * currentPageOfSpecificCategory
            ? true
            : false,
        filtered: false,
      };
    }

    if (
      !!product.categories?.length &&
      product.categories.some((category: { name: string }) => category.name === categoryName)
    ) {
      indexOfFilteredProducts += 1;

      if (indexOfFilteredProducts <= ITEM_COUNT_PER_PAGINATION * currentPageOfSpecificCategory - 1) {
        return {
          ...product,
          visible: true,
          filtered: true,
        };
      }
    }

    return { ...product, visible: false, filtered: true };
  });

  return moreProducts;
};

export const getFirstFiveProducts = (products: any[]) => {
  // TODO: use slice
  const VISIBLE_ITEM_COUNT = 5;

  let index = -1;

  const results = products.map(data => {
    index += data ? 1 : 0;

    return {
      ...data,
      visible: index <= VISIBLE_ITEM_COUNT ? true : false,
      filtered: false,
    };
  });

  return results;
};

export const checkOutStandingProducts = (productListData: State.ProductsListData[], productType: string) => {
  let filteredProducts: State.LineItem[] = [];
  let outStandingProduct: State.LineItem[] = [];
  productListData.forEach((item: { lineItems: State.LineItem[] }) => {
    item.lineItems.forEach(lineItem => {
      if (lineItem.productType === productType && lineItem.name) {
        filteredProducts = [...filteredProducts, lineItem];
      }
    });
  });

  filteredProducts.forEach(filteredProduct => {
    if (outStandingProduct.filter(product => product.name === filteredProduct.name).length === 1) {
      outStandingProduct = [...outStandingProduct, filteredProduct];
    }
  });

  return outStandingProduct;
};

// NOTE: only use this when a lineItem has a zuora counterpart else this won't find anything.
export const getProductByType = (productListData: any, productType: string) =>
  productListData?.lineItems.filter(
    (item: State.LineItem) =>
      item.productType === productType &&
      item.name &&
      item.subscriptionStatus === Product.ZuoraSubscriptionStatus.ACTIVE
  ) || [];

export const removeDashSpaceFromArray = (data: any[]) =>
  data.map(item => item.replace('-', ' ').replace(' ', '').toLowerCase());
