import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from 'constants/index';
import { getPath, fromCentsFormat } from 'utils';
import { User as UserUtils } from 'mxp-utils';

import { Build } from './Build';
import { Address } from './Address';
import { Invoice } from './Invoice';
import { Confirm } from './Confirm';
import { Container } from 'components/atoms/Container/Container';
import { HeaderSteps } from 'components/molecules/HeaderSteps/HeaderSteps';
import { CalculationStickyFooter } from 'components/admin/molecules/CalculationStickyFooter/CalculationStickyFooter';

import { b2bCartSelector, salesTaxSelector, selectedCurrency } from 'modules/admin/selectors';
import {
  addressProceedToNext,
  createB2BOrder,
  getB2BOrderPreview,
  getOrganizationAddress,
} from 'modules/admin/actions';
import { Product } from 'mxp-schemas';

export const CreateOrder: React.FC<RouteComponentProps> = props => {
  const dispatch = useDispatch();
  const cart = useSelector(b2bCartSelector);
  const checkoutTax = useSelector(salesTaxSelector);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading] = useState(false);
  const { activeScreenIndex } = (props.location.state as any) || {};
  const { push: historyPush } = props.history as any;
  const [showErrorOnInvoice, setShowErrorOnInvoice] = useState('');
  const [showErrorOnConfirmation, setShowErrorOnConfirmation] = useState('');
  const [isExistingOrder, setIsExistingOrder] = useState(false);
  const { accountId, accountNumber, legalEntity } = props.match.params as {
    accountId: string;
    accountNumber?: string;
    legalEntity?: string;
  };

  const currency = useSelector(selectedCurrency);

  if (accountId === undefined) return <Redirect to={getPath(Routes.ROOT)} />;
  if (activeScreenIndex === undefined) {
    return <Redirect to={generatePath(getPath(Routes.ADMIN_ORGANIZATION), { accountId })} />;
  }
  if (!cart.length && activeScreenIndex !== 0) {
    return <Redirect to={{ pathname: generatePath(getPath(Routes.ADMIN_CREATE_ORDER), { accountId }), state: 0 }} />;
  }

  const salesTax = {
    isValueShown: Boolean(checkoutTax !== null) && activeScreenIndex === 3,
    value: checkoutTax?.taxAmount || 0,
    placeholderText: activeScreenIndex > 1 ? 'Calculated on next step' : 'Calculated upon invoice entry',
  };

  const products = cart.map(({ productDetails, userInput }) => {
    const currencyPrice = productDetails.prices?.map((item: any) => {
      return { price: item.value.centAmount, currency: item.value.currencyCode, channel: item.channel };
    });
    const selectedPrice = currencyPrice?.find((item: any) => {
      return (
        item.currency === currency &&
        (item.channel.obj.key === UserUtils.CORRECT_NON_MEMBER_CHANNEL_KEY ||
          item.channel.obj.key === UserUtils.INCORRECT_NON_MEMBER_CHANNEL_KEY)
      );
    });
    const isRecurring = (
      [
        Product.ProductType.SUBSCRIPTION,
        Product.ProductType.MEMBERSHIP,
        Product.ProductType.SECTION,
        Product.ProductType.CREDENTIAL,
      ] as string[]
    ).includes(productDetails.productType);
    const isProductNonStore = Product.ProductType.NON_STORE === productDetails.productType;
    const isNonStoreRecurringProduct = isProductNonStore && productDetails.isManualSubscription;
    const isNonStoreOneTimeProduct = isProductNonStore && !productDetails.isManualSubscription;
    return {
      title: productDetails.name,
      quantity: userInput.quantity,
      invoice_bundle: userInput.additionalAttrs?.bundleName,
      price: fromCentsFormat(selectedPrice?.price || 0),
      discount: userInput.price,
      subscription_start: isRecurring || isNonStoreRecurringProduct ? userInput.additionalAttrs?.subscriptionStart : '',
      access_for:
        isRecurring || isNonStoreRecurringProduct
          ? `${userInput.additionalAttrs?.period} ${userInput.additionalAttrs?.periodType}`
          : '',
      occurrence_date:
        (!isRecurring && !isNonStoreRecurringProduct) || isNonStoreOneTimeProduct
          ? userInput.additionalAttrs?.occurrenceDate
          : '',
    };
  });

  const productSubtotals: number[] | null = products.map(({ price, quantity }) => price * quantity);
  const productDiscounts: number[] | null = products.map(({ discount, price }) => discount && price - discount);

  const subtotal = productSubtotals.reduce((a, b) => a + b, 0);
  const totalDiscount = productDiscounts.reduce((a, b) => a + b, 0);
  const total = subtotal - totalDiscount + salesTax.value;

  const screens = [
    {
      label: 'Build',
      content: (
        <Build
          accountId={accountId}
          accountNumber={accountNumber}
          legalEntity={legalEntity}
          setNextDisabled={setIsDisabled}
        />
      ),
      nextHandler: () => {
        if (!isExistingOrder) {
          dispatch(getOrganizationAddress(accountId, legalEntity)).then(() => {
            setIsExistingOrder(true);
          });
        }
        historyPush({
          pathname: generatePath(getPath(Routes.ADMIN_CREATE_ORDER), { accountId, accountNumber, legalEntity }),
          state: {
            activeScreenIndex: activeScreenIndex + 1,
          },
        });
      },
      nextText: 'Continue to address',
    },
    {
      label: 'Address',
      content: <Address setNextDisabled={setIsDisabled} />,
      nextHandler: () => {
        dispatch(addressProceedToNext());
      },
      nextText: 'Continue to invoice',
    },
    {
      label: 'Invoice',
      content: <Invoice generalError={showErrorOnInvoice} setNextDisabled={setIsDisabled} />,
      nextHandler: () => {
        dispatch(getB2BOrderPreview(accountId, legalEntity))
          .then((response: { payload: { taxAmount: number } }) => {
            if (!response?.payload) {
              return setShowErrorOnInvoice('Server failed');
            }

            historyPush({
              pathname: generatePath(getPath(Routes.ADMIN_CREATE_ORDER), { accountId, accountNumber, legalEntity }),
              state: {
                activeScreenIndex: activeScreenIndex + 1,
              },
            });
          })
          .catch((error: { message: string; response: { errors: { message: string; status: string }[] } }) => {
            if (error.message.includes('Could not find opportunity')) {
              setShowErrorOnInvoice(
                'Could not find opportunity. Please provide a valid Opportunity Number from Salesforce and try again.'
              );
            } else {
              setShowErrorOnInvoice(error.response?.errors?.map(e => e.message).join(' >> '));
            }
          });
      },
      nextText: 'Calculate sales tax',
    },
    {
      label: 'Order summary',
      content: <Confirm showError={showErrorOnConfirmation} {...{ products }} {...{ total }} />,
      nextHandler: () => {
        dispatch(createB2BOrder(accountId, legalEntity))
          .then((response: any) => {
            if (!response?.payload) {
              return setShowErrorOnConfirmation('Server error');
            }

            historyPush({
              pathname: generatePath(getPath(Routes.ADMIN_ORGANIZATION), { accountId, accountNumber, legalEntity }),
              state: {
                activeScreenIndex,
                orderNumber: response.payload.orderNumber,
              },
            });
          })
          .catch((error: { message: string; response: { errors: { message: string; status: string }[] } }) =>
            setShowErrorOnConfirmation(error.response?.errors?.map(e => e.message).join(' >> '))
          );
      },
      nextText: 'Submit order',
    },
  ];
  const activeScreen = screens[activeScreenIndex];
  const onNext = () => {
    if (activeScreen.nextHandler) {
      activeScreen.nextHandler();
    } else {
      historyPush({
        pathname: generatePath(getPath(Routes.ADMIN_CREATE_ORDER), { accountId }),
        state: {
          activeScreenIndex: activeScreenIndex + 1,
        },
      });
    }
  };

  return (
    <>
      <StyledContainer>
        <HeaderSteps stepIndex={activeScreenIndex} labels={screens.map(screen => screen.label)} />
        <StyledContent>
          <Container>{activeScreen.content}</Container>
        </StyledContent>
      </StyledContainer>
      {Boolean(cart.length) && (
        <CalculationStickyFooter
          cart={cart}
          salesTax={salesTax}
          next={{ handler: onNext, text: activeScreen.nextText, isLoading, isDisabled }}
        />
      )}
    </>
  );
};

const StyledContent = styled.div`
  margin-bottom: 1rem;
`;

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
