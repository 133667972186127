import React from 'react';
import styled from 'styled-components/macro';
import { NotificationForAnonymousUser } from 'components/molecules/NotificationForAnonymousUser/NotificationForAnonymousUser';
import { priceToFloat, productDiscountLabel, zeroToLabel } from 'utils';
import { Product, User as UserTypes } from 'mxp-schemas';
import { UserMemberTypes } from 'modules/user/constants';

// TODO: to pass all types of pricing ranges into this component e.g. !isAuth | conference ranges
interface PriceProps {
  priceFull: any;
  priceFinal: any;
  isAuth: boolean | null;
  discountAmount: any;
  userMemberType: UserMemberTypes;
  hasNonMemberPrice?: boolean;
  isUserSuspendedByEthics?: boolean;
  allPrices?: Product.PriceForRole[];
}

export const ProductPrice: React.FC<PriceProps> = ({
  priceFull,
  priceFinal,
  isAuth,
  discountAmount,
  userMemberType,
  hasNonMemberPrice,
  isUserSuspendedByEthics,
  allPrices,
}) => {
  /* replacing here instead of selector level as business have not decided that no decimals is wanted across all levels - so we have to manipulate on component level */
  const initialPrice = priceFull?.formattedPrice?.replace('.00', '');
  const discountPrice = priceFinal?.formattedPrice?.replace('.00', '');

  const initialPriceValue = priceToFloat(initialPrice);
  const discountPriceValue = priceToFloat(discountPrice);

  const isZeroInitialPrice = initialPriceValue === 0;
  const isZeroDiscountPrice = discountPriceValue === 0;
  // To display free products ASAP - use free journey
  const discountLabel = discountAmount?.label ? productDiscountLabel(discountAmount.label) : '';

  const isUserNonMember = userMemberType === UserMemberTypes.NONMEMBER;
  const memberPrice =
    allPrices &&
    (
      allPrices.find(item => item.applyedDiscountRole === UserTypes.MembershipIdsEnum.MRUSR0001) ||
      allPrices.find(item => item.applyedDiscountRole === UserTypes.MembershipIdsEnum.MRUKR0001)
    )?.transformedPrice?.replace('.00', '');
  const forMembersPrice =
    isUserNonMember && memberPrice && !(memberPrice === discountPrice) ? `(${memberPrice} for members)` : '';

  if (isZeroDiscountPrice && isZeroInitialPrice) {
    return (
      <>
        <StyledPriceInfo isAuth={Boolean(isAuth)}>
          <StyledFreePrice>{zeroToLabel(discountPriceValue)}</StyledFreePrice>
        </StyledPriceInfo>
      </>
    );
  }
  if (isZeroDiscountPrice && !isUserSuspendedByEthics) {
    return (
      <>
        <StyledPriceInfo isAuth={Boolean(isAuth)}>
          {hasNonMemberPrice && discountPrice !== initialPrice && <StyledFullPrice>{initialPrice}</StyledFullPrice>}
          <StyledFreePrice>{`${zeroToLabel(discountPriceValue)} ${discountLabel}`}</StyledFreePrice>
        </StyledPriceInfo>
        {!isAuth && <StyledNotificationForAnonymousUser />}
      </>
    );
  }
  if (isUserSuspendedByEthics) {
    return (
      <>
        <StyledPriceInfo isAuth={Boolean(isAuth)}>
          <StyledPriceInfoSuspended>{initialPrice}</StyledPriceInfoSuspended>
          {discountPrice !== initialPrice && !isZeroDiscountPrice && (
            <StyledFinalPrice> {`(${discountPrice} for members)`}</StyledFinalPrice>
          )}
        </StyledPriceInfo>
        {!isAuth && <StyledNotificationForAnonymousUser />}
      </>
    );
  }

  // normal pricing journey
  return (
    <>
      <StyledPriceInfo isAuth={Boolean(isAuth)}>
        {!(initialPrice === discountPrice) && <StyledFullPrice>{initialPrice}</StyledFullPrice>}
        <StyledFinalPrice>From {`${discountPrice} ${discountLabel} ${forMembersPrice}`}</StyledFinalPrice>
      </StyledPriceInfo>
      {!isAuth && <StyledNotificationForAnonymousUser />}
    </>
  );
};

const StyledFreePrice = styled.span`
  color: ${props => props.theme.colors.secondaryTeal};
`;

const StyledPriceInfo = styled.div<{ isAuth?: boolean }>`
  margin-bottom: ${props => (props.isAuth ? props.theme.pxToRem(26) : props.theme.pxToRem(4))};
`;

const StyledFullPrice = styled.span`
  margin-right: ${props => props.theme.pxToRem(8)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  text-decoration: line-through;
`;

const StyledFinalPrice = styled.span`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledNotificationForAnonymousUser = styled(NotificationForAnonymousUser)`
  margin-top: ${props => props.theme.pxToRem(4)};
  margin-bottom: ${props => props.theme.pxToRem(25)};
  font-size: ${props => props.theme.fontSizes.xs};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(12)};
    margin-bottom: ${props => props.theme.pxToRem(18)};
  }
`;

const StyledPriceInfoSuspended = styled.div`
  font-size: ${props => props.theme.fontSizes.m};
`;
