import {
  CimaMembershipEntryPointPanel,
  CimaMembershipPackageSummary,
  CimaMembershipTypePanel,
  MembershipTierPanel,
  ModalError,
  RegionalPathwayProductsPanel,
} from 'components/organisms';
import { MembershipPackageAccordionItemProps } from 'components/pages/PageMembershipPackage/PageMembershipPackage';
import { push } from 'connected-react-router';
import { CONSTANTS } from 'modules/app/constants';
import { constantsSelector, isAdminPortalSelector } from 'modules/app/selectors';
import { exemptionEngineResultsSelector, selectedExemptionSelector } from 'modules/exemptionEngine/selector';
import { USE_CGMA_AFFILIATE_ADDONS, USE_FLP_ADDONS, USE_CR_682 } from 'modules/featureToggle/constants';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import {
  addMembershipToCart,
  getEntryLevels,
  setMembershipJourneyType,
  setMembershipPackageTier,
  setMembershipPackageType,
} from 'modules/membership/actions';
import {
  clickedMembershipUpgradeSelector,
  currentMembershipProductIdSelector,
  currentMembershipProductSkuSelector,
  currentMembershipSubscriptionSlugSelector,
  isCimaAffiliateLapsedSelector,
  isCimaCandidateTypeSelector,
  isCimaFLPLapsedSelector,
  isCimaMembershipLapsedSelector,
  isCimaPQApprenticeLapsedSelector,
  isCimaPQCandidateLapsedSelector,
  isCimaPqCandidateRenewalSelector,
  isCimaRegularLapsedSelector,
  isCimaRenewalSelector,
  isCimaRetiredLapsedSelector,
  isFLPSwitchSelector,
  isFLPUpgradeSelector,
  membershipInviteDataSelector,
  membershipRelatedPathwaysSelector,
  membershipSubscriptionsFetchedSelector,
  membershipTierNameSelector,
  practicalExperienceRequirementStatusSelector,
  selectedCimaMembershipKeyByUserChoiceSelector,
  selectedMembershipTierCodeSelector,
  tiersWithUserApplicablePricingSelector,
  userMembershipPackageSelector,
  selectedPathwayBundleProductIdSelector,
  isCimaCgmaAffiliateLapsedSelector,
  isAddFcmaCredentialInCartSelector,
  isRegularUpgradingToRetiredSelector,
} from 'modules/membership/selectors';
import { maxNumberOfExperienceDataSelector } from 'modules/personLevelExemption';
import { productsListDataFetchedSelector } from 'modules/products/selectors';
import { isPaperBillingSelector } from 'modules/startup/selectors';
import {
  cimaMembershipSelector,
  cimaMembershipsTermTypeSelector,
  currentJourneyLearningPathwaySelector,
  isAuthSelector,
  isEPA1CompletedSelector,
  isEPA2CompletedSelector,
  learningPathwaySelector,
  isCimaMemberSelector,
  isUserMemberLapsedSelector,
  userEmailSelector,
} from 'modules/user/selectors';
import { MembershipTypes } from 'mxp-schemas';
import { Admin as AdminUtils } from 'mxp-utils';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AccordionTitleProps } from 'semantic-ui-react';
import { getCimaMembershipPackagePath, getPaperBillingPath, getPath, areAllTruthy, hasTruthyValue } from 'utils';
import { MembershipPackageAccordionStatus, Routes, StorageNames } from '../../../constants';
import { CimaMembershipAddonPanel } from '../CimaMembershipAddonPanel/CimaMembershipAddonPanel';
import { CimaMembershipPackageAccordion } from '../CimaMembershipPackageAccordion/CimaMembershipPackageAccordion';
import { getLocalStorageItem } from 'utils/localStorage';
import { CimaMembershipConfirmDesignationPanel } from '../CimaMembershipConfirmDesignationPanel/CimaMembershipConfirmDesignationPanel';

interface Props {
  setLastAccordionKey: Dispatch<SetStateAction<string>>;
}

export const CimaMembershipPackageSelector: React.FC<Props> = ({ setLastAccordionKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { showSummaryPage, skippedAccordionKey } = (location.state as any) || false;

  const [activeIndex, setActiveIndex] = useState(-1);
  const [maxIndex, setMaxIndex] = useState(-1);
  const [summaryPage, setSummaryPage] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [switchedEntryPoint, setSwitchedEntryPoint] = useState('');
  const { exempReference } = useSelector(exemptionEngineResultsSelector);
  const userChoice = useSelector(userMembershipPackageSelector);
  const { list: relatedPathwayProductList } = useSelector(membershipRelatedPathwaysSelector);
  const currentJourneyLearningPathway = useSelector(currentJourneyLearningPathwaySelector);
  const isPaperBilling = useSelector(isPaperBillingSelector);
  const isCimaRenewal = useSelector(isCimaRenewalSelector);
  const learningPathway = useSelector(learningPathwaySelector) as string;
  const isMembershipSubscriptionsFetched = useSelector(membershipSubscriptionsFetchedSelector);
  const currentMembershipType = useSelector(cimaMembershipsTermTypeSelector);
  const currentMembershipTier = useSelector(currentMembershipProductSkuSelector);
  const currentMembershipProductSlug = useSelector(currentMembershipSubscriptionSlugSelector);
  const currentMembershipProductId = useSelector(currentMembershipProductIdSelector);
  const productsListDataFetched = useSelector(productsListDataFetchedSelector);
  const isSubscribedToFlp = AdminUtils.isFlpPathway(learningPathway as string);
  const isFLPSwitch = useSelector(isFLPSwitchSelector);
  const isFLPUpgrade = useSelector(isFLPUpgradeSelector);
  const isForUpgrade = useSelector(clickedMembershipUpgradeSelector);
  const isEPA1Completed = useSelector(isEPA1CompletedSelector);
  const isEPA2Completed = useSelector(isEPA2CompletedSelector);
  const tierCode = useSelector(selectedMembershipTierCodeSelector);
  const membershipTierName = useSelector(membershipTierNameSelector);
  const useFLPAddons = useSelector(state => getFeatureToggleByKeySelector(state as State.Root, USE_FLP_ADDONS));
  const useCGMAAffiliateAddons = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_CGMA_AFFILIATE_ADDONS)
  );
  const useCR862FcmaRenewalEnhancement = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, USE_CR_682)
  );

  const isCimaPqCandidateRenewal = useSelector(isCimaPqCandidateRenewalSelector);
  const isCimaCandidateType = useSelector(isCimaCandidateTypeSelector);

  const cimaMemBody = useSelector(cimaMembershipSelector)?.membershipBody as string;
  const isSubscribedToCimaRegular = AdminUtils.isCimaRegularType(cimaMemBody, currentMembershipType);
  const isSubscribedToCimaAffiliate = AdminUtils.isCimaAffiliateType(cimaMemBody, currentMembershipType);
  const isSubscribedToCimaCgmaAffiliate = AdminUtils.isCimaCgmaAffiliateType(cimaMemBody, currentMembershipType);
  const isCimaCgmaAffiliateLapsed = useSelector(isCimaCgmaAffiliateLapsedSelector);
  const isCgmaAffiliateRenewal = hasTruthyValue(
    areAllTruthy(isCimaRenewal && isSubscribedToCimaCgmaAffiliate && !isForUpgrade),
    isCimaCgmaAffiliateLapsed
  );
  const isSubscribedToCimaRetired = AdminUtils.isCimaRetiredType(cimaMemBody, currentMembershipType);
  const isCimaPqCurrentJourney = AdminUtils.isCimaPqPathway(currentJourneyLearningPathway);
  const isApprenticeCurrentJourney = AdminUtils.isApprenticePathway(currentJourneyLearningPathway);
  const isChinesePathwayCurrentJourney = AdminUtils.isChinesePathway(currentJourneyLearningPathway);
  const isFlpCurrentJourney = currentJourneyLearningPathway
    ? AdminUtils.isFlpPathway(currentJourneyLearningPathway)
    : userChoice.type.slug === MembershipTypes.CIMALearningPathwaySlug.FLP;
  const isSubscribedToCimaPq = AdminUtils.isCimaPqPathway(learningPathway);
  const isAdmin = useSelector(isAdminPortalSelector);
  const isAuth = useSelector(isAuthSelector);
  const isImpersonated = Boolean(isAuth) && isAdmin;
  const maxNumberOfExp = useSelector(maxNumberOfExperienceDataSelector);
  const constants = useSelector(constantsSelector);
  const userSelectedExemptionEngine = useSelector(selectedExemptionSelector);
  const perStatus = useSelector(practicalExperienceRequirementStatusSelector);
  const isCimaFLPLapsed = useSelector(isCimaFLPLapsedSelector);
  const isPERStatusCompleted = perStatus === MembershipTypes.PERPortfolioStatus.COMPLETED;
  const isUpgradeToRegular =
    (learningPathway === MembershipTypes.Pathway.APPRENTICE_L7 && isEPA1Completed && isEPA2Completed) ||
    (isForUpgrade && isSubscribedToCimaAffiliate && tierCode === MembershipTypes.TierCode.CORE && isPERStatusCompleted);

  const isUpgradeFlpToRegular = isForUpgrade && isSubscribedToFlp && isPERStatusCompleted;

  const isCimaMembershipLapsed = useSelector(isCimaMembershipLapsedSelector);
  const isCimaAffiliateLapsed = useSelector(isCimaAffiliateLapsedSelector);
  const isCimaRegularLapsed = useSelector(isCimaRegularLapsedSelector);
  const isCimaRetiredLapsed = useSelector(isCimaRetiredLapsedSelector);
  const selectedCimaMembershipKeyByUserChoice = useSelector(selectedCimaMembershipKeyByUserChoiceSelector);
  const isCimaPQCandidateLapsed = useSelector(isCimaPQCandidateLapsedSelector);
  const isCimaApprenticeCandidateLapsed = useSelector(isCimaPQApprenticeLapsedSelector);
  const { list: tierList } = useSelector(tiersWithUserApplicablePricingSelector);
  const isUserCimaMember = useSelector(isCimaMemberSelector);
  const selectedPathwayBundleProductId = useSelector(selectedPathwayBundleProductIdSelector);

  // FCMA Designation
  const isAddFcmaCredentialInCart = useSelector(isAddFcmaCredentialInCartSelector);

  // firm billing
  const [isAlreadyRemovedAddons, setIsAlreadyRemovedAddons] = useState(false);
  const inviteData = useSelector(membershipInviteDataSelector);
  const isUserLapsedOrResigned = useSelector(isUserMemberLapsedSelector);
  const userEmail = useSelector(userEmailSelector);
  const [isUserBlock, setIsUserBlock] = useState(false);

  const isSwitchFLPtoPQ = isSubscribedToFlp && isCimaPqCurrentJourney; // CR386 Requirement
  const isFlpRegularOrRetired =
    isSubscribedToFlp &&
    (isCimaRegularLapsed || isCimaRetiredLapsed || isSubscribedToCimaRegular || isSubscribedToCimaRetired);

  const isForCimaRenewal =
    (isCimaMembershipLapsed || isCimaRenewal) && isUserCimaMember && !isForUpgrade && !isFLPUpgrade;
  const isRegularUpgradingToRetired = useSelector(isRegularUpgradingToRetiredSelector);
  const isRegionalPathway = useMemo(
    () =>
      Number.parseInt(maxNumberOfExp, 10) >=
      Number.parseInt(constants?.[CONSTANTS.CIMA_REGIONAL_QUALIFIED_YEARS_OF_EXPERIENCE], 10),
    [constants, maxNumberOfExp]
  );

  const selectedBundle = useMemo(() => {
    return (
      (
        relatedPathwayProductList?.find(product => selectedPathwayBundleProductId === product?.productId) || {
          productId: '',
        }
      )?.name || ''
    );
  }, [selectedPathwayBundleProductId, relatedPathwayProductList]);

  const goToAddonPage = useCallback(async () => {
    if (isRegionalPathway && isImpersonated && isFlpCurrentJourney && !useFLPAddons) {
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Summary, isPaperBilling), {
        showSummaryPage: true,
      });
      setSummaryPage(true);
    }
    history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Addons));
  }, [history, isFlpCurrentJourney, isRegionalPathway, isImpersonated, useFLPAddons, isPaperBilling]);

  const goToSummaryPage = useCallback(async () => {
    if (isFLPSwitch || isFLPUpgrade || isUpgradeToRegular || isUpgradeFlpToRegular || isRegularUpgradingToRetired) {
      setCartLoading(true);
      await dispatch(addMembershipToCart());
      history.push(getPaperBillingPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL, isPaperBilling));
    } else if (
      ((isCimaRenewal || (isCimaMembershipLapsed && !inviteData?.inviteId)) &&
        !isRegularUpgradingToRetired &&
        (isSubscribedToCimaPq || isCimaAffiliateLapsed || isSubscribedToCimaCgmaAffiliate)) ||
      isSubscribedToFlp ||
      isCimaFLPLapsed
    ) {
      setCartLoading(true);
      await dispatch(addMembershipToCart());
      history.push(getPaperBillingPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS, isPaperBilling));
    } else if ((isCimaPqCandidateRenewal && isCimaCandidateType) || (isSubscribedToCimaCgmaAffiliate && isForUpgrade)) {
      setCartLoading(true);
      await dispatch(addMembershipToCart());
      history.push(getPaperBillingPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS, isPaperBilling));
    } else if (inviteData?.inviteId && isUserLapsedOrResigned) {
      setCartLoading(true);
      await dispatch(addMembershipToCart());

      history.push(
        getPaperBillingPath(
          inviteData?.isPaidByFLP || inviteData?.isPaidByFirm
            ? Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_ATTESTATION
            : Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_DONATIONS,
          isPaperBilling
        )
      );
    } else {
      history.push(getCimaMembershipPackagePath(MembershipPackageAccordionStatus.Summary, isPaperBilling), {
        showSummaryPage: true,
      });
      setSummaryPage(true);
    }
  }, [
    history,
    isForUpgrade,
    isPaperBilling,
    isCimaPqCandidateRenewal,
    isCimaCandidateType,
    dispatch,
    isCimaRenewal,
    isSubscribedToCimaPq,
    isFLPSwitch,
    isFLPUpgrade,
    isSubscribedToFlp,
    isUpgradeToRegular,
    isUpgradeFlpToRegular,
    isCimaMembershipLapsed,
    isCimaAffiliateLapsed,
    isCimaFLPLapsed,
    isSubscribedToCimaCgmaAffiliate,
    inviteData,
    isUserLapsedOrResigned,
    isRegularUpgradingToRetired,
  ]);

  const FLP_ACCORDION_DATA: MembershipPackageAccordionItemProps[] = useMemo(
    () => [
      {
        key: MembershipPackageAccordionStatus.Level,
        title: `View your entry point level`,
        content: {
          content: <CimaMembershipEntryPointPanel setSwitchedEntryPoint={setSwitchedEntryPoint} />,
        },
      },
      {
        key: MembershipPackageAccordionStatus.Skill,
        title: `Choose your ${isSubscribedToCimaRegular ? 'tiers' : 'package'}`,
        content: {
          content: (
            <MembershipTierPanel
              isCima={true}
              isAddonHidden={
                (isFlpCurrentJourney && !useFLPAddons) ||
                (!useCGMAAffiliateAddons &&
                  (isCgmaAffiliateRenewal || isCimaPqCandidateRenewal || isCimaPQCandidateLapsed))
              }
              goToSummaryPage={goToSummaryPage}
              isFLPProductType={isFlpCurrentJourney || inviteData?.isFLP}
            />
          ),
        },
      },
      {
        key: MembershipPackageAccordionStatus.Addons,
        title: `Choose your add-ons`,
        content: {
          content: <CimaMembershipAddonPanel handleClick={goToSummaryPage} loading={cartLoading} />,
        },
      },
    ],
    [
      setSwitchedEntryPoint,
      isSubscribedToCimaRegular,
      isFlpCurrentJourney,
      goToSummaryPage,
      cartLoading,
      useFLPAddons,
      isCgmaAffiliateRenewal,
      useCGMAAffiliateAddons,
      isCimaPqCandidateRenewal,
      isCimaPQCandidateLapsed,
      inviteData,
    ]
  );

  const ACCORDION_DATA: MembershipPackageAccordionItemProps[] = useMemo(
    () => [
      {
        key: MembershipPackageAccordionStatus.Type,
        title: `Choose your membership type`,
        content: {
          content: <CimaMembershipTypePanel />,
        },
        showInUpgrade: true,
        popupContent: isForCimaRenewal
          ? 'You can pick up where you left off, stepping into your journey at the membership type you had before.'
          : '',
      },
      {
        key: MembershipPackageAccordionStatus.Level,
        title: `Choose your entry point`,
        content: {
          content: <CimaMembershipEntryPointPanel />,
        },
        popupContent: `Based on your experience, your entry point could be the Operational, Management, or Strategic Level of the CGMA syllabus. `,
      },
      {
        key: MembershipPackageAccordionStatus.Skill,
        title: `Choose your ${
          isSubscribedToCimaRegular ||
          isSubscribedToCimaAffiliate ||
          isUpgradeToRegular ||
          isCimaRegularLapsed ||
          (isUserLapsedOrResigned && inviteData?.inviteId)
            ? 'membership tier'
            : 'package'
        }`,
        content: {
          content: (
            <MembershipTierPanel
              isCima={true}
              isAddonHidden={
                (!isUpgradeFlpToRegular && !isFlpRegularOrRetired && isFlpCurrentJourney && !useFLPAddons) ||
                (!useCGMAAffiliateAddons &&
                  (isCgmaAffiliateRenewal || isCimaPqCandidateRenewal || isCimaPQCandidateLapsed))
              }
              goToSummaryPage={goToSummaryPage}
              isFLPProductType={
                (!isUpgradeFlpToRegular && !isFlpRegularOrRetired && isFlpCurrentJourney) || inviteData?.isFLP
              }
            />
          ),
        },
        showInFlpRenewals: true,
        showInUpgrade: true,
        popupContent: isForCimaRenewal
          ? `We're developing a range of value-added tiers 
        so you can customise your membership 
        package. You'll be informed of tiers before the 
        next renewals season.`
          : '',
      },
      {
        key: MembershipPackageAccordionStatus.Pathway,
        title: `Choose your Products`,
        content: {
          content: <RegionalPathwayProductsPanel handleClick={goToAddonPage} loading={cartLoading} />,
        },
      },
      {
        key: MembershipPackageAccordionStatus.Addons,
        title: `Customise your package`,
        content: {
          content: <CimaMembershipAddonPanel handleClick={goToSummaryPage} loading={cartLoading} />,
        },
        showInUpgrade: true,
        popupContent: isForCimaRenewal
          ? `We're developing a range of new benefits. 
        Before the next renewals season, you'll be 
        informed of additional features you can 
        choose. `
          : `The learning pathway, plus any customisations that you'd like, creates your unique package.`,
      },
    ],
    [
      cartLoading,
      useFLPAddons,
      isFlpCurrentJourney,
      isSubscribedToCimaRegular,
      isSubscribedToCimaAffiliate,
      isUpgradeToRegular,
      isCimaRegularLapsed,
      goToSummaryPage,
      goToAddonPage,
      isForCimaRenewal,
      isCgmaAffiliateRenewal,
      useCGMAAffiliateAddons,
      isCimaPqCandidateRenewal,
      isCimaPQCandidateLapsed,
      isUpgradeFlpToRegular,
      isUserLapsedOrResigned,
      inviteData,
      isFlpRegularOrRetired,
    ]
  );

  const FCMA_RENEWAL_ACCORDION_DATA: MembershipPackageAccordionItemProps[] = useMemo(
    () => [
      {
        key: MembershipPackageAccordionStatus.Type,
        title: `Choose your membership type`,
        content: {
          content: <CimaMembershipTypePanel />,
        },
        popupContent: isForCimaRenewal
          ? 'You can pick up where you left off, stepping into your journey at the membership type you had before.'
          : '',
      },
      {
        key: MembershipPackageAccordionStatus.Skill,
        title: `Choose your ${
          isSubscribedToCimaRegular ||
          isSubscribedToCimaAffiliate ||
          isUpgradeToRegular ||
          isCimaRegularLapsed ||
          (isUserLapsedOrResigned && inviteData?.inviteId)
            ? 'membership tier'
            : 'package'
        }`,
        content: {
          content: (
            <MembershipTierPanel
              isFLPProductType={
                (!isUpgradeFlpToRegular && !isFlpRegularOrRetired && isFlpCurrentJourney) || inviteData?.isFLP
              }
              isAddonHidden={
                (!isUpgradeFlpToRegular && !isFlpRegularOrRetired && isFlpCurrentJourney && !useFLPAddons) ||
                (!useCGMAAffiliateAddons &&
                  (isCgmaAffiliateRenewal || isCimaPqCandidateRenewal || isCimaPQCandidateLapsed))
              }
              isCima={true}
              goToSummaryPage={goToSummaryPage}
            />
          ),
        },
        popupContent: isForCimaRenewal
          ? `We're developing a range of value-added tiers 
        so you can customise your membership 
        package. You'll be informed of tiers before the 
        next renewals season.`
          : '',
      },
      {
        key: MembershipPackageAccordionStatus.Designation,
        title: `Confirm your designation`,
        content: {
          content: <CimaMembershipConfirmDesignationPanel handleClick={goToAddonPage} loading={cartLoading} />,
        },
        popupContent: isForCimaRenewal
          ? `We're developing a range of new benefits. 
        Before the next renewals season, you'll be 
        informed of additional features you can 
        choose. `
          : `The learning pathway, plus any customisations that you'd like, creates your unique package.`,
      },
      {
        key: MembershipPackageAccordionStatus.Addons,
        title: `Customise your package`,
        content: {
          content: <CimaMembershipAddonPanel loading={cartLoading} handleClick={goToSummaryPage} />,
        },
        popupContent: isForCimaRenewal
          ? `We're developing a range of new benefits. 
        Before the next renewals season, you'll be 
        informed of additional features you can 
        choose. `
          : `The learning pathway, plus any customisations that you'd like, creates your unique package.`,
      },
    ],
    [
      cartLoading,
      useFLPAddons,
      isFlpCurrentJourney,
      isSubscribedToCimaRegular,
      isSubscribedToCimaAffiliate,
      isUpgradeToRegular,
      isCimaRegularLapsed,
      goToSummaryPage,
      goToAddonPage,
      isForCimaRenewal,
      isCgmaAffiliateRenewal,
      useCGMAAffiliateAddons,
      isCimaPqCandidateRenewal,
      isCimaPQCandidateLapsed,
      isUpgradeFlpToRegular,
      isUserLapsedOrResigned,
      inviteData,
      isFlpRegularOrRetired,
    ]
  );

  const CRP_ACCORDION_DATA: MembershipPackageAccordionItemProps[] = useMemo(
    () => [
      {
        key: MembershipPackageAccordionStatus.Level,
        title: `Determine your entry point`,
        content: {
          content: <CimaMembershipEntryPointPanel />,
        },
      },
      {
        key: MembershipPackageAccordionStatus.Pathway,
        title: `Choose your Products`,
        content: {
          content: <RegionalPathwayProductsPanel handleClick={goToAddonPage} loading={cartLoading} />,
        },
      },
      {
        key: MembershipPackageAccordionStatus.Addons,
        title: `Customise your package`,
        content: {
          content: <CimaMembershipAddonPanel handleClick={goToSummaryPage} loading={cartLoading} />,
        },
        showInFlpRenewals: true,
        showInUpgrade: true,
      },
    ],
    [cartLoading, goToSummaryPage, goToAddonPage]
  );

  const [accordionData, setAccordionData] = useState(
    isFLPUpgrade || isFLPSwitch
      ? FLP_ACCORDION_DATA
      : !isChinesePathwayCurrentJourney
      ? ACCORDION_DATA
      : CRP_ACCORDION_DATA
  );

  useEffect(() => {
    if ((inviteData?.isPaidByFirm || inviteData?.isPaidByFLP) && !isAlreadyRemovedAddons) {
      const updatedAccordion = accordionData?.filter(
        (accordion: any) => accordion.key !== MembershipPackageAccordionStatus.Addons
      );
      setAccordionData(updatedAccordion);
      setIsAlreadyRemovedAddons(true);
    }
  }, [inviteData, accordionData, isAlreadyRemovedAddons]);

  const handleAccordionClick = React.useCallback(
    (e, { index }: AccordionTitleProps) => {
      if (index !== undefined && index <= maxIndex) {
        setActiveIndex(+index);
        if (index > maxIndex) {
          setMaxIndex(+index);
        }
        if (!(skippedAccordionKey && skippedAccordionKey === accordionData[+index].key) && index !== activeIndex) {
          history.push(
            `${getCimaMembershipPackagePath(
              accordionData[+index].key as MembershipPackageAccordionStatus,
              isPaperBilling
            )}`
          );
        }
      }
    },
    [maxIndex, history, accordionData, isPaperBilling, skippedAccordionKey, activeIndex]
  );

  useEffect(() => {
    setSummaryPage(showSummaryPage);
  }, [showSummaryPage]);

  useEffect(() => {
    if (
      userChoice.type.slug === '' &&
      !currentJourneyLearningPathway && // no current pathway selected
      !isCimaPqCandidateRenewal &&
      !isSubscribedToCimaRegular &&
      !isSubscribedToCimaAffiliate &&
      !isSubscribedToCimaRetired &&
      !isUpgradeToRegular &&
      !isUpgradeFlpToRegular &&
      isMembershipSubscriptionsFetched &&
      !isCimaAffiliateLapsed &&
      !isCimaRetiredLapsed &&
      !isCimaRegularLapsed &&
      !isCimaFLPLapsed &&
      !isSubscribedToCimaCgmaAffiliate &&
      !isUserLapsedOrResigned
    ) {
      dispatch(push(getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY)));
    }
  }, [
    dispatch,
    currentJourneyLearningPathway,
    isCimaPqCandidateRenewal,
    userChoice,
    isSubscribedToCimaRegular,
    learningPathway,
    isMembershipSubscriptionsFetched,
    isSubscribedToCimaAffiliate,
    isSubscribedToCimaRetired,
    isUpgradeToRegular,
    isUpgradeFlpToRegular,
    isCimaAffiliateLapsed,
    isCimaRetiredLapsed,
    isCimaRegularLapsed,
    isCimaFLPLapsed,
    isSubscribedToCimaCgmaAffiliate,
    isUserLapsedOrResigned,
  ]);

  useEffect(() => {
    dispatch(setMembershipJourneyType(MembershipTypes.MembershipJourneyType.CIMA_MEMBERSHIP_SIGN_UP));
    dispatch(getEntryLevels(currentJourneyLearningPathway));
  }, [dispatch, currentJourneyLearningPathway]);

  useEffect(() => {
    const inviteEmailStorage = getLocalStorageItem(StorageNames.inviteEmail) as any;
    const inviteIdStorage = getLocalStorageItem(StorageNames.inviteId) as any;

    if (isCimaMembershipLapsed && !inviteData?.inviteId && inviteIdStorage && inviteEmailStorage === userEmail) {
      setIsUserBlock(true);
    }
  }, [inviteData, isCimaMembershipLapsed, userEmail]);

  // Accordion Panel filtering
  useEffect(
    () => {
      if (inviteData?.inviteId && isCimaMembershipLapsed) {
        return setAccordionData(currData => {
          return currData.filter(
            data =>
              data.key !== MembershipPackageAccordionStatus.Level &&
              data.key !== MembershipPackageAccordionStatus.Type &&
              data.key !== MembershipPackageAccordionStatus.Pathway
          );
        });
      }

      // CR682 - This will cover the Manual Renewal, Rejoining Scenario and Upgrade from Regular to Retired (Regular/Retired with FCMA History)
      if (areAllTruthy(useCR862FcmaRenewalEnhancement, isAddFcmaCredentialInCart)) {
        return setAccordionData(FCMA_RENEWAL_ACCORDION_DATA);
      }

      if (isCimaRegularLapsed) {
        return setAccordionData(currData => {
          return currData.filter(
            data =>
              data.key !== MembershipPackageAccordionStatus.Level &&
              data.key !== MembershipPackageAccordionStatus.Pathway
          );
        });
      }

      if (
        isUpgradeToRegular ||
        isUpgradeFlpToRegular ||
        isCimaPqCandidateRenewal ||
        (isForUpgrade && (isSubscribedToCimaAffiliate || isSubscribedToCimaCgmaAffiliate)) ||
        isCgmaAffiliateRenewal ||
        (isCimaPQCandidateLapsed && isCimaPqCurrentJourney)
      ) {
        // hide addon panel during cgma affiliate renewal
        if (isCgmaAffiliateRenewal) {
          setAccordionData(currData => {
            return currData.filter(data =>
              useCGMAAffiliateAddons ? true : data.key !== MembershipPackageAccordionStatus.Addons
            );
          });
        }

        if (isChinesePathwayCurrentJourney) {
          return setAccordionData(ACCORDION_DATA.filter(data => data.showInUpgrade));
        }

        return setAccordionData(currData => {
          return currData.filter(data => data.showInUpgrade);
        });
      }

      if (
        (isCimaRenewal || isCimaMembershipLapsed || isSubscribedToFlp) &&
        isMembershipSubscriptionsFetched &&
        !isFLPUpgrade &&
        !isFLPSwitch &&
        !isSwitchFLPtoPQ // CR386 Requirement
      ) {
        if (hasTruthyValue(isSubscribedToCimaRegular, isSubscribedToCimaRetired)) {
          return setAccordionData(currData => {
            return currData.filter(
              data =>
                data.key !== MembershipPackageAccordionStatus.Level &&
                data.key !== MembershipPackageAccordionStatus.Pathway
            );
          });
        }
        if ((isSubscribedToCimaPq && isFlpCurrentJourney) || isCimaFLPLapsed) {
          return setAccordionData(currData => {
            return currData.filter(data => data.showInFlpRenewals);
          });
        }
        if (
          isSubscribedToCimaAffiliate ||
          isCimaAffiliateLapsed ||
          isCimaRetiredLapsed ||
          isSubscribedToCimaPq ||
          (isSubscribedToFlp && isCimaPqCurrentJourney)
        ) {
          if (isCimaRetiredLapsed) {
            return setAccordionData(currData => {
              return currData.filter(data =>
                tierList.length === 1
                  ? data.key === MembershipPackageAccordionStatus.Addons
                  : data.key === MembershipPackageAccordionStatus.Addons ||
                    data.key === MembershipPackageAccordionStatus.Skill
              );
            });
          }
          return setAccordionData(currData => {
            return currData.filter(data => data.key === MembershipPackageAccordionStatus.Addons);
          });
        }
        if (isSubscribedToFlp) {
          return setAccordionData(currData => {
            return currData.filter(data => data.showInFlpRenewals);
          });
        }
      }
      if (isCimaPqCurrentJourney && !isFLPUpgrade && !isFLPSwitch) {
        setAccordionData(currData => {
          return currData.filter(
            data =>
              data.key !== MembershipPackageAccordionStatus.Skill && data.key !== MembershipPackageAccordionStatus.Type
          );
        });
      }

      if (isApprenticeCurrentJourney && !isFLPUpgrade && !isFLPSwitch) {
        setAccordionData(currData => {
          return currData.filter(data => data.key === MembershipPackageAccordionStatus.Level);
        });
      }

      if (isFlpCurrentJourney) {
        setAccordionData(currData => {
          return currData.filter(data =>
            useFLPAddons
              ? data.key !== MembershipPackageAccordionStatus.Type
              : data.key !== MembershipPackageAccordionStatus.Type &&
                data.key !== MembershipPackageAccordionStatus.Addons
          );
        });
      }

      if (!isRegionalPathway || !isImpersonated) {
        setAccordionData(currData => {
          return currData.filter(data => data.key !== MembershipPackageAccordionStatus.Pathway);
        });
      }

      if (isChinesePathwayCurrentJourney) {
        setAccordionData(
          CRP_ACCORDION_DATA.map((obj, index) => ({ ...obj, pillValue: accordionData[index]?.pillValue }))
        );
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      CRP_ACCORDION_DATA,
      ACCORDION_DATA,
      isCgmaAffiliateRenewal,
      isChinesePathwayCurrentJourney,
      useFLPAddons,
      tierList,
      isCimaRetiredLapsed,
      isCimaPqCurrentJourney,
      isApprenticeCurrentJourney,
      isCimaRenewal,
      isSubscribedToCimaPq,
      isSubscribedToCimaRegular,
      isFlpCurrentJourney,
      isMembershipSubscriptionsFetched,
      isSubscribedToCimaAffiliate,
      isFLPSwitch,
      isFLPUpgrade,
      isRegionalPathway,
      isImpersonated,
      isSubscribedToCimaRetired,
      isSubscribedToFlp,
      isForUpgrade,
      isCimaPqCandidateRenewal,
      isUpgradeToRegular,
      isUpgradeFlpToRegular,
      isCimaMembershipLapsed,
      isCimaAffiliateLapsed,
      isCimaRegularLapsed,
      isCimaFLPLapsed,
      isSubscribedToCimaCgmaAffiliate,
      isSwitchFLPtoPQ,
      inviteData,
      useCGMAAffiliateAddons,
      isCimaPQCandidateLapsed,
      isUserLapsedOrResigned,
      isAddFcmaCredentialInCart,
      useCR862FcmaRenewalEnhancement,
    ]
  );

  useEffect(() => {
    setAccordionData(currData => {
      let pillValue: any[] = [];
      if (
        areAllTruthy(
          isCimaPqCurrentJourney,
          !isFLPSwitch,
          !isFLPUpgrade,
          isImpersonated,
          isRegionalPathway,
          !isCimaPqCandidateRenewal
        )
      ) {
        pillValue = [userSelectedExemptionEngine, selectedBundle];
      } else if (
        areAllTruthy(isCimaPqCandidateRenewal, !isFLPSwitch, !isFLPUpgrade, isImpersonated, isRegionalPathway)
      ) {
        pillValue = [currentMembershipType, membershipTierName];
      } else if (areAllTruthy(isChinesePathwayCurrentJourney, isImpersonated)) {
        pillValue = [userSelectedExemptionEngine, selectedBundle];
      } else if (areAllTruthy(isFlpCurrentJourney, isImpersonated, isRegionalPathway)) {
        pillValue = [userSelectedExemptionEngine, userChoice.tier, selectedBundle];
      } else if (hasTruthyValue(isFLPSwitch, isFLPUpgrade)) {
        pillValue = [switchedEntryPoint, membershipTierName];
      } else if (
        areAllTruthy(
          isCimaPqCurrentJourney,
          !isCimaPqCandidateRenewal,
          !isCgmaAffiliateRenewal,
          !isForUpgrade,
          !isCimaPQCandidateLapsed
        )
      ) {
        pillValue = [userSelectedExemptionEngine];
      } else if (
        hasTruthyValue(
          isForUpgrade,
          isCimaPqCandidateRenewal,
          isCgmaAffiliateRenewal,
          isCimaPQCandidateLapsed,
          isCimaRegularLapsed,
          isRegularUpgradingToRetired
        )
      ) {
        pillValue = [selectedCimaMembershipKeyByUserChoice, membershipTierName];
      } else if (hasTruthyValue(isSubscribedToCimaRegular, isSubscribedToCimaAffiliate)) {
        pillValue = [currentMembershipType];
      } else if (isSubscribedToCimaRetired) {
        pillValue = [currentMembershipType, membershipTierName];
      } else if (isCimaRetiredLapsed) {
        pillValue = [membershipTierName];
      } else {
        pillValue = [userSelectedExemptionEngine, membershipTierName];
      }

      return currData.map((panelData, i) => ({
        ...panelData,
        pillValue: pillValue[i],
      }));
    });
  }, [
    userChoice,
    isCimaPqCandidateRenewal,
    isChinesePathwayCurrentJourney,
    isCgmaAffiliateRenewal,
    membershipTierName,
    isCimaPqCurrentJourney,
    currentJourneyLearningPathway,
    isSubscribedToCimaRegular,
    isSubscribedToCimaAffiliate,
    currentMembershipType,
    exempReference,
    userSelectedExemptionEngine,
    isFLPSwitch,
    switchedEntryPoint,
    isForUpgrade,
    selectedCimaMembershipKeyByUserChoice,
    isImpersonated,
    isRegionalPathway,
    isFLPUpgrade,
    isFlpCurrentJourney,
    selectedBundle,
    isCimaPQCandidateLapsed,
    isSubscribedToCimaRetired,
    isCimaRetiredLapsed,
    isCimaRegularLapsed,
    isRegularUpgradingToRetired,
  ]);

  useEffect(() => {
    if (!location.hash.replace('#', '') && isMembershipSubscriptionsFetched) {
      const accordionPath =
        isSubscribedToCimaRegular || isSubscribedToCimaAffiliate
          ? MembershipPackageAccordionStatus.Type
          : MembershipPackageAccordionStatus.Level;
      history.push(getCimaMembershipPackagePath(accordionPath, isPaperBilling));
    }
  }, [
    history,
    location,
    isPaperBilling,
    isSubscribedToCimaRegular,
    isMembershipSubscriptionsFetched,
    isSubscribedToCimaAffiliate,
  ]);

  useEffect(() => {
    const index = accordionData.findIndex(({ key }) => key === location.hash.replace('#', ''));

    if (index !== activeIndex) {
      setActiveIndex(index < 0 ? 0 : index);
    }

    if (index > maxIndex) {
      setMaxIndex(index < 0 ? 0 : index);
    }
  }, [location, accordionData, maxIndex, activeIndex]);

  useEffect(() => {
    setLastAccordionKey(accordionData?.[accordionData?.length - 1]?.key || '');
  }, [accordionData, setLastAccordionKey]);

  useEffect(() => {
    const setMembershipUserchoice = async () => {
      if (!isCimaPQCandidateLapsed && !isCimaApprenticeCandidateLapsed) {
        // userChoice Type id and slug is already set in StudyPath for Candidate Lapsed
        await dispatch(setMembershipPackageType(currentMembershipProductId, currentMembershipProductSlug));
      }
      if (!isSubscribedToCimaRetired && !isCimaRetiredLapsed) {
        await dispatch(setMembershipPackageTier(currentMembershipTier));
      }
    };
    const isMembershipLapsed =
      isCimaAffiliateLapsed || isCimaRetiredLapsed || isCimaPQCandidateLapsed || isCimaApprenticeCandidateLapsed;
    if (
      productsListDataFetched &&
      (isCimaRenewal || isCimaMembershipLapsed) &&
      (isSubscribedToCimaAffiliate || isSubscribedToCimaRetired || isMembershipLapsed)
    ) {
      setMembershipUserchoice();
    }
  }, [
    dispatch,
    isCimaRetiredLapsed,
    isCimaPQCandidateLapsed,
    isCimaApprenticeCandidateLapsed,
    productsListDataFetched,
    isSubscribedToCimaAffiliate,
    isCimaRenewal,
    currentMembershipProductId,
    currentMembershipProductSlug,
    currentMembershipTier,
    isSubscribedToCimaRetired,
    isCimaMembershipLapsed,
    isCimaAffiliateLapsed,
  ]);

  const handleClose = useCallback(() => {
    history.push(getPath(Routes.ROOT));
  }, [history]);

  if (isUserBlock) {
    return (
      <ModalError
        headerText="You cannot proceed since the data is lost!"
        bodyText={<div>Please access the invite link again to complete invitation!</div>}
        isOpen={true}
        handleClose={handleClose}
      />
    );
  }

  return (
    <>
      {summaryPage ? (
        <CimaMembershipPackageSummary />
      ) : (
        <CimaMembershipPackageAccordion
          data={accordionData}
          activeIndex={activeIndex}
          maxIndex={maxIndex}
          onClick={handleAccordionClick}
          isCimaMembership={true}
        />
      )}
    </>
  );
};
