import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Icon, Grid, Button } from 'semantic-ui-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LongLink } from 'components/molecules/LongLink/LongLink';
import { LinkList } from 'components/molecules/LinkList/LinkList';
import { Link, ExternalSourceLabel, LinkEnums } from 'components/atoms';
import { handleEvent, EXTERNAL_LINK_EVENT, HEADER_EVENT } from 'utils/Analytics';
import { Content } from 'mxp-schemas';
import { ReactComponent as Home } from 'resources/images/icon-dev-ic-home.svg';
import { generatePath } from 'react-router';
import { MomentHelpers, getContentUrl, getPath, getPromotedProducts } from 'utils';
import { Routes } from 'constants/index';
import { getPageNameAndSiteSection } from 'utils/Analytics/helpers';
import { PromotedProducts } from '../PromotedProducts/PromotedProducts';

export enum ListStyle {
  LONG = 'Long',
  SHORT = 'Short',
}

export enum ContentType {
  LIST = 'list',
  HOME = 'home',
  DYNAMIC_CARDS = 'megamenuLevel1Images',
  DYNAMIC_CATEGORY = 'megamenuLevel2DynamicCategory',
  LISTS = 'lists',
  DYNAMIC_TOPICS = 'megamenuLevel2DynamicTopics',
  DYNAMIC_INDUSTRY = 'megamenuLevel2DynamicIndustries',
  DYNAMIC_SKILL = 'megamenuLevel2DynamicSkills',
  DYNAMIC_TREND = 'megamenuLevel2DynamicTrends',
  SUBMENU = 'megamenuLevel1Submenu',
  MEGAMENU_LIST = 'megamenuList',
}

interface UnnumberedProps {
  links: State.Level2Type[];
  level1: State.Level1Type;
  onClick: (name: string, target: string | undefined) => void;
}

const Unnumbered: React.FC<UnnumberedProps> = props => (
  <>
    {props.links.map((link: State.Level2Type) => {
      const isStatic = Boolean(link.slug);
      if (isStatic) {
        const contentPath: string = generatePath(getPath(Routes.STATIC_LANDING_PAGES), {
          slug: link.slug,
          category: link.contentCategory?.slug,
          type: link.contentType,
        });
        link.url = contentPath;
        link.name = link.title || '';
      }
      if (!link.url || !link.contentSource || !link.contentSource.name) return null;
      return (
        <LongLink
          key={link.id}
          to={link.url}
          source={link.contentSource.name}
          onClick={() => props.onClick(link.name || '', link.url)} // tslint:disable-line jsx-no-lambda
        >
          {link.name}
        </LongLink>
      );
    })}
  </>
);

interface NumberedProps {
  links: State.ListItem[];
  onClick: (name: string, target: string | undefined) => void;
}

const Numbered: React.FC<NumberedProps> = props => (
  <>
    {props.links.map((link: State.ListItem, index: number) => {
      const linkTarget = getContentUrl(link as State.ContentCardItem);
      return (
        <LongLink
          key={link.id}
          to={linkTarget}
          source={link.contentSource}
          date={link.date}
          onClick={() => props.onClick(link.title || '', linkTarget)} // tslint:disable-line jsx-no-lambda
        >
          {`${index + 1}. ${link.title}`}
        </LongLink>
      );
    })}
  </>
);

const { pageName, siteSection } = getPageNameAndSiteSection();
const renderImageRowItem = (listItem: State.ListItem, closeMegamenu?: () => void) => {
  const destinationUrl = getContentUrl(listItem as State.ContentCardItem);

  const handleImageItemClick = () => {
    handleEvent(
      {
        clickValue: `button:link:int:header:mega-menu:${listItem.title}:${destinationUrl}`,
        value: `mega-menu:${listItem.title}:${destinationUrl}`,
        href: destinationUrl,
        pageName,
        siteSection,
      },
      HEADER_EVENT
    );
    if (closeMegamenu) closeMegamenu();
  };
  return (
    <Grid.Column key={listItem.id}>
      {listItem.image && (
        <SmallImageCard
          type={LinkEnums.type.standaloneLink}
          to={destinationUrl}
          isExternal={!!listItem.externalUrl}
          onClick={handleImageItemClick}
        >
          <Image src={listItem.image.url} alt={listItem.image?.altText || ''} />
          <NameLine>{listItem.title}</NameLine>
          <DateSourceWrapper>
            {listItem.date && <DateLine>{MomentHelpers.getDate(listItem.date)}</DateLine>}
            {listItem.externalUrl && listItem.contentSource && (
              <StyledExternalSourceLabel label={listItem.contentSource} />
            )}
          </DateSourceWrapper>
        </SmallImageCard>
      )}
    </Grid.Column>
  );
};

const displayAsImageList = (
  displayType: string | undefined,
  origin: string | undefined,
  list: State.ListItem[]
): boolean => {
  if (displayType === Content.DisplayType.TEXT) {
    return false;
  }

  if (origin === Content.ContentType.LISTS && displayType === Content.DisplayType.IMAGE) {
    return !Boolean(list.filter((item: State.ListItem) => !item.image?.url).length);
  }

  return true;
};

const renderLevel2Items = (
  level1: State.Level1Type,
  testId: string,

  handleLinkClick: (name: string, target: string | undefined) => void,
  closeMegamenu: () => void,
  setBurger: (state: boolean) => void,
  clearMegaHistory: () => void,
  history: any
) => {
  switch (level1.contentType) {
    case ContentType.DYNAMIC_TOPICS:
      return <LinkList testId={`level2-${testId}`} links={level1.topicList || []} />;
    case ContentType.DYNAMIC_INDUSTRY:
      return <LinkList testId={`level2-${testId}`} links={level1.industryList || []} />;

    case ContentType.DYNAMIC_TREND:
      return <LinkList testId={`level2-${testId}`} links={level1.trendList || []} />;

    case ContentType.DYNAMIC_SKILL:
      return <LinkList testId={`level2-${testId}`} links={level1.skillList || []} />;

    case ContentType.DYNAMIC_CATEGORY:
      const linkClick = () => {
        return level1 && level1.category
          ? handleLinkClick(level1.linkText as string, level1.category.slug as string)
          : '';
      };
      return (
        <>
          {level1.categoryList && <Numbered links={level1.categoryList} onClick={handleLinkClick} />}
          {level1.category && level1.category.slug && (
            <StyledLink to={`/search?category=${level1.category.slug}&sortBy=newest-first`} onClick={linkClick}>
              {level1.linkText}
            </StyledLink>
          )}
        </>
      );
    case ContentType.LISTS:
      if (level1.type === ListStyle.LONG) {
        return <Unnumbered links={level1.list || []} level1={level1 || {}} onClick={handleLinkClick} />;
      }
      const promotedProducts: State.PromotedProductsCard[] = getPromotedProducts(level1.list || []);
      const itemDescription = promotedProducts?.[0]?.description;
      const isPromotedProducts = level1?.list?.map((item: any) => {
        return item.contentType;
      });
      return (
        <>
          <LinkList testId={`level2-${testId}`} links={level1.list || []} />
          {isPromotedProducts?.includes(Content.ListsType.PROMOTED_PRODUCTS) ? (
            <>
              <OptionDivider />
              <StyledPromotedTitle>{itemDescription}</StyledPromotedTitle>
              <StyledCardPromoted columns="3">
                <PromotedProducts
                  items={promotedProducts}
                  setBurger={setBurger}
                  globalHistory={history}
                  clearMegaHistory={clearMegaHistory}
                />
              </StyledCardPromoted>
            </>
          ) : null}
        </>
      );
    case ContentType.MEGAMENU_LIST:
      if (
        level1.staticOrDynamicList &&
        displayAsImageList(level1.displayType, level1.origin, level1.staticOrDynamicList)
      ) {
        return (
          <>
            <Level1ImageLayout>
              <ImageLayoutRow columns={1}>
                <Grid.Column>
                  <TextLine>{level1.header}</TextLine>
                </Grid.Column>
              </ImageLayoutRow>
              <ImageLayoutRow columns={3} divided>
                {level1.staticOrDynamicList?.map((listItem: State.ListItem) => {
                  return renderImageRowItem(listItem);
                })}
              </ImageLayoutRow>
              {level1.ctaUrl && (
                <StyledLink to={level1.ctaUrl} onClick={closeMegamenu}>
                  {level1.linkText}
                </StyledLink>
              )}
              {level1.origin === Content.ContentType.DYNAMIC_LIST && level1.pageLink && level1.pageLinkDisplayText && (
                <StyledLink to={level1.pageLink} onClick={closeMegamenu}>
                  {level1.pageLinkDisplayText}
                </StyledLink>
              )}
            </Level1ImageLayout>
          </>
        );
      }
      return (
        <>
          {level1.staticOrDynamicList && <Numbered links={level1.staticOrDynamicList} onClick={handleLinkClick} />}
          {level1.ctaUrl && (
            <StyledLink to={level1.ctaUrl} onClick={closeMegamenu}>
              {level1.linkText}
            </StyledLink>
          )}
          {level1.origin === Content.ContentType.DYNAMIC_LIST && level1.pageLink && level1.pageLinkDisplayText && (
            <StyledLink to={level1.pageLink} onClick={closeMegamenu}>
              {level1.pageLinkDisplayText}
            </StyledLink>
          )}
        </>
      );
    default:
      return <LinkList testId={`level2-${testId}`} links={level1.list || []} />;
  }
};

interface MegaMenuProps extends RouteComponentProps {
  megaMenuData: State.Level0Type[];
  testId: string;
  setBurger: (state: boolean) => void;
  clearMegaHistory: () => void;
  history: any;
}

export const MegaMenu = withRouter((props: MegaMenuProps) => {
  const [selectedLevel0, setSelectedLevel0] = useState('');
  const [selectedLevel1, setSelectedLevel1] = useState('');
  const [timeMegaMenu, setTimeMegaMenu] = useState(0);

  const isEnter = (event: any) => event.keyCode === 13;

  const toggleLevel0Selection = (level0: State.Level0Type) => {
    // Have first item always opened
    const isOpening = selectedLevel0 !== level0.id;
    const itemToBeSelected = isOpening ? level0.id : '';

    if (isOpening && level0.submenuItems && level0.submenuItems[0]) {
      setSelectedLevel1(level0.submenuItems[0].id);
    } else {
      setSelectedLevel1('');
    }

    setSelectedLevel0(itemToBeSelected);
  };

  const handleLevel0Selection = (level0: State.Level0Type) => () => {
    toggleLevel0Selection(level0);
  };

  const handleLevel0SelectionWithEnter = (level0: State.Level0Type) => (event: any) => {
    if (isEnter(event)) toggleLevel0Selection(level0);
  };

  const toggleLevel1Selection = (level1Id: string = '') => {
    setSelectedLevel1(level1Id);
  };

  const handleLevel1Selection =
    (level1Id: string = '') =>
    () => {
      const timeoutId = window.setTimeout(() => {
        toggleLevel1Selection(level1Id);
      }, 500);
      setTimeMegaMenu(timeoutId);
    };

  const clearTimeoutMegaMenu = () => {
    clearTimeout(timeMegaMenu);
  };

  const handleLevel1SelectionWithEnter =
    (level1Id: string = '') =>
    (event: any) => {
      if (isEnter(event)) toggleLevel1Selection(level1Id);
    };

  const closeMegamenu = () => {
    setSelectedLevel0('');
    setSelectedLevel1('');
  };

  const handleLinkClick = React.useCallback((name: string, target: string | undefined): void => {
    if (target?.includes('http')) {
      handleEvent({ text: `mega-menu:${name}:${target}`, href: target }, EXTERNAL_LINK_EVENT);
      return;
    }

    handleEvent(
      {
        clickValue: `button:link:int:header:mega-menu:${name}:${target}`,
        value: `mega-menu:${name}:${target}`,
        href: target,
        pageName,
        siteSection,
      },
      HEADER_EVENT
    );
  }, []);

  return (
    <StyledWrapper>
      <Container>
        <Level0 data-testid={props.testId}>
          {props.megaMenuData.map((level0: State.Level0Type, index: number) => (
            <Level0Item
              isActive={level0.id === selectedLevel0}
              key={level0.id}
              onMouseEnter={level0.contentType !== ContentType.HOME ? handleLevel0Selection(level0) : () => undefined}
              onMouseLeave={level0.contentType !== ContentType.HOME ? handleLevel0Selection(level0) : () => undefined}
            >
              <Level0Button
                as={level0.contentType === ContentType.HOME ? Link : Button}
                to={level0.url}
                data-testid={`level0-button-${props.testId}-${index}`}
                onKeyDown={handleLevel0SelectionWithEnter(level0)}
              >
                {level0.contentType === ContentType.HOME && <StyledHome />}
                {level0.name}
                {level0.contentType !== ContentType.HOME && (
                  <Level0Icon fitted name={selectedLevel0 === level0.id ? 'angle up' : 'angle down'} size="small" />
                )}
              </Level0Button>
              {level0.id === selectedLevel0 && level0.contentType !== ContentType.HOME && (
                <Level1>
                  {(level0.contentType === ContentType.DYNAMIC_CARDS ||
                    (level0.contentType === ContentType.MEGAMENU_LIST &&
                      level0.staticOrDynamicList &&
                      displayAsImageList(level0.displayType, level0.origin, level0.staticOrDynamicList))) && (
                    <ImageLayout>
                      <ImageLayoutRow columns={1}>
                        <Grid.Column>
                          <TextLine>{level0.description || level0.header}</TextLine>
                        </Grid.Column>
                      </ImageLayoutRow>
                      <ImageLayoutRow columns={4} divided>
                        {(level0.categoryList || level0.staticOrDynamicList) &&
                          (level0.categoryList || level0.staticOrDynamicList)?.map((level1: State.ListItem) =>
                            renderImageRowItem(level1, closeMegamenu)
                          )}
                      </ImageLayoutRow>

                      <ImageLayoutRow columns={1}>
                        <Grid.Column>
                          {level0.category && level0.category.slug && (
                            <StyledLink to={`/search?category=${level0.category.slug}`} onClick={closeMegamenu}>
                              {level0.linkText}
                            </StyledLink>
                          )}
                          {level0.contentType === ContentType.MEGAMENU_LIST && (
                            <>
                              {level0.ctaUrl && (
                                <StyledLink to={level0.ctaUrl} onClick={closeMegamenu}>
                                  {level0.linkText}
                                </StyledLink>
                              )}
                              {level0.origin === Content.ContentType.DYNAMIC_LIST &&
                                level0.pageLink &&
                                level0.pageLinkDisplayText && (
                                  <StyledLink to={level0.pageLink} onClick={closeMegamenu}>
                                    {level0.pageLinkDisplayText}
                                  </StyledLink>
                                )}
                            </>
                          )}
                        </Grid.Column>
                      </ImageLayoutRow>
                    </ImageLayout>
                  )}
                  {level0.contentType === ContentType.MEGAMENU_LIST &&
                    level0.staticOrDynamicList &&
                    !displayAsImageList(level0.displayType, level0.origin, level0.staticOrDynamicList) && (
                      <Level1ListLayout key={level0.id} onClick={closeMegamenu}>
                        <Numbered links={level0.staticOrDynamicList} onClick={handleLinkClick} />
                        {level0.ctaUrl && level0.linkText && (
                          <StyledLink to={level0.ctaUrl} onClick={closeMegamenu}>
                            {level0.linkText}
                          </StyledLink>
                        )}
                        {level0.pageLink && level0.pageLinkDisplayText && (
                          <StyledLink to={level0.pageLink} onClick={closeMegamenu}>
                            {level0.pageLinkDisplayText}
                          </StyledLink>
                        )}
                      </Level1ListLayout>
                    )}
                  {level0.contentType === ContentType.SUBMENU && (
                    <>
                      <Level1Items>
                        {level0.submenuItems &&
                          level0.submenuItems.map((level1: State.Level1Type, level1Index: number) => (
                            <Level1Item
                              onMouseEnter={handleLevel1Selection(level1.id)}
                              onMouseLeave={clearTimeoutMegaMenu}
                              key={level1.id}
                            >
                              <Level1Button
                                data-testid={`level1-button-${props.testId}-${index}-${level1Index}`}
                                onKeyDown={handleLevel1SelectionWithEnter(level1.id)}
                                isActive={selectedLevel1 === level1.id}
                              >
                                {level1.name}

                                <Icon name="angle right" size="small" />
                              </Level1Button>
                            </Level1Item>
                          ))}
                      </Level1Items>

                      {level0.submenuItems &&
                        level0.submenuItems.map(
                          (level1: State.Level1Type) =>
                            selectedLevel1 === level1.id && (
                              <Level2 key={level1.id} isActive={selectedLevel1 === level1.id} onClick={closeMegamenu}>
                                {renderLevel2Items(
                                  level1,
                                  props.testId,
                                  handleLinkClick,
                                  closeMegamenu,
                                  props.setBurger,
                                  props.clearMegaHistory,
                                  props.history
                                )}
                              </Level2>
                            )
                        )}
                    </>
                  )}
                </Level1>
              )}
            </Level0Item>
          ))}
        </Level0>
      </Container>
    </StyledWrapper>
  );
});

const ResetLi = styled.li`
  list-style-type: none;
`;

const TextLine = styled.p`
  && {
    padding: ${props => props.theme.pxToRem(9.6)};
    margin: 0;
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.light};
  }
`;

const ImageLayout = styled(Grid)`
  &&& {
    width: 100%;
    padding: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(16)};
    margin: 0;
  }
`;

const Level1ImageLayout = styled(Grid)`
  &&& {
    margin: 0;
  }
`;

const Level1ListLayout = styled.div`
  display: 'block';
  width: 100%;
  padding: ${props => props.theme.pxToRem(16)};
`;

const ImageLayoutRow = styled(Grid.Row)`
  &&&& {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const SmallImageCard = styled(Link)`
  display: block;
  width: 100%;
  padding: ${props => props.theme.pxToRem(6.4)} ${props => props.theme.pxToRem(9.6)};
  border-radius: ${props => props.theme.pxToRem(4)};

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.primaryFadedPurple};
    color: ${props => props.theme.colors.primaryDarkPurple};
    outline: none;
    text-decoration: none;

    p,
    div {
      color: ${props => props.theme.colors.primaryDarkPurple};
    }

    .icon {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const Image = styled.img`
  width: 100%;
  min-height: ${props => props.theme.pxToRem(116)};

  ${props => props.theme.mediaQueries.largeScreenMin} {
    min-height: ${props => props.theme.pxToRem(148)};
  }
`;

const NameLine = styled.p`
  margin: 0 0 ${props => props.theme.pxToRem(10)};
  color: ${props => props.theme.colors.neutralBlack};
  font-size: ${props => props.theme.fontSizes.s};
`;

const DateLine = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.neutralGrey7};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
`;

const DateSourceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledExternalSourceLabel = styled(ExternalSourceLabel)`
  font-weight: ${props => props.theme.fontWeights.light};
`;

const StyledLink = styled(Link)`
  display: inline-block;
  padding: ${props => props.theme.pxToRem(9.6)};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledWrapper = styled.div`
  border-top: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
`;

const Level0 = styled.ul<{ 'data-testid': string }>`
  position: relative;
  z-index: 2;
  display: flex;
  margin: 0;
  padding: 0;
`;

const Level0Icon = styled(Icon)`
  padding-left: ${props => props.theme.pxToRem(5)};
  color: ${props => props.theme.colors.primaryPurple};
`;

const Level2 = styled.div<{ isActive: boolean }>`
  display: ${props => (props.isActive ? 'block' : 'none')};
  width: 100%;
  padding: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(16)};
`;

const Level1Button = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(16)};
  border: none;
  margin-top: ${props => props.theme.pxToRem(1)};
  background-color: ${props => props.theme.colors.neutralWhite};
  text-align: left;
  cursor: pointer;

  ${props => {
    if (props.isActive) {
      return `
      color: ${props.theme.colors.primaryPurple};
      font-weight: ${props.theme.fontWeights.medium};
    `;
    }
  }}

  &:hover, &:focus {
    outline: none;
    background: ${props => props.theme.colors.neutralGrey1};

    i {
      color: ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const Level1Item = styled(ResetLi)`
  position: relative;

  &::after {
    position: absolute;
    top: 100%;
    left: 6%;
    content: '';
    display: block;
    width: 88%;
    border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  }

  &:last-of-type::after {
    content: none;
  }
`;

const Level1Items = styled.ul`
  position: relative;
  min-width: ${props => props.theme.pxToRem(256)};
  margin: 0;
  padding: 0;

  &::after {
    position: absolute;
    right: 0;
    bottom: 10%;
    content: '';
    width: ${props => props.theme.pxToRem(1)};
    height: 80%;
    background: ${props => props.theme.colors.neutralGrey3};
  }
`;

const Level0Button = styled(Button)`
  &&&& {
    display: flex;
    align-items: center;
    padding: ${props => props.theme.pxToRem(10)} 0;
    border-bottom: ${props => props.theme.pxToRem(2)} solid transparent;
    border-radius: 0;
    margin: 0 ${props => props.theme.pxToRem(9)};
    background-color: transparent;
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.s};
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: 1.5;
    text-decoration: none;
    &:hover,
    &:focus {
      border-bottom: ${props => props.theme.pxToRem(2)} solid ${props => props.theme.colors.primaryPurple};
    }
  }
`;

const Level1 = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: none;
  border-top: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  background: ${props => props.theme.colors.neutralWhite};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);

  & ${ResetLi} {
    cursor: pointer;
  }
`;

const Level0Item = styled(ResetLi)<{ isActive: boolean }>`
  display: inline-block;

  ${props => {
    if (props.isActive) {
      return `
      & > ${Level1} {
        display: flex;
      }
    `;
    }
  }}
`;

const StyledHome = styled(Home)`
  align-self: flex-start;
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  margin-right: ${props => props.theme.pxToRem(4)};

  > path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledCardPromoted = styled(Grid.Row)`
  font-family: 'Roboto';
  width: 100%;
  display: flex;
  padding-top: 0;
`;

const StyledPromotedTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: ${props => `${props.theme.pxToRem(20)} 0 ${props.theme.pxToRem(15)} 0`};
  margin-left: ${props => props.theme.pxToRem(12)};
`;

const OptionDivider = styled.div`
  height: ${props => props.theme.pxToRem(1)};
  background-color: ${props => props.theme.colors.neutralGrey2};
  margin-left: ${props => `${props.theme.pxToRem(10)}`};
`;
