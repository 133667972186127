// ------------------------------------
// Constants
// ------------------------------------
export const initialState: State.ExemptionEngineResult = {
  exempReference: {
    id: '',
    name: '',
    description: '',
    exemptionLevelKey: '',
    masterExamReference: [],
    lowerExemptionLevelRef: [],
    title: '',
  },
  exemptReferenceFetched: false,
  exemptReferenceFrontloadRequest: false,
  selectedExemptReference: '',
  selectedExemptReferenceLevel: '',
  prevSelectedExemptReference: '',
};
