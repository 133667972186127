import React, { useCallback, useState, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AccordionPanelProps, Label } from 'semantic-ui-react';
import { centPriceToString } from 'utils';
import { Product, MembershipTypes } from 'mxp-schemas';
import { Product as ProductUtils, Admin as AdminUtils } from 'mxp-utils';
import { Button, FeedPlaceholder } from 'components/atoms';
import { CimaMembershipCard } from 'components/organisms';
import {
  filterMembershipRelatedPathwaysByCategory,
  setMembershipPackageTier,
  getAllPathwayProducts,
  removeMembershipPackageRelatedPathway,
  addMembershipPackagePathway,
} from 'modules/membership';
import {
  membershipRelatedPathwaysSelector,
  selectedPathwayBundleProductIdSelector,
  membershipTiersSelector,
} from 'modules/membership/selectors';
import { currentJourneyLearningPathwaySelector, userRolesSelector, userTierSelector } from 'modules/user/selectors';
import {
  membershipPackageUpdatePageOfSpecificPathwayCategory,
  seeMoreMembershipRelatePathwayProduct,
} from 'modules/membership/actions';

import { productCurrencySelector } from 'modules/products/selectors';
interface MembershipPackagePanelProps extends AccordionPanelProps {
  handleClick: () => void;
  loading?: boolean;
}

export const RegionalPathwayProductsPanel: React.FC<MembershipPackagePanelProps> = ({ handleClick, loading }) => {
  const ALL = 'All';
  const NUMBER_OF_VARIANTS = 9;
  const NUM_PER_PAGE = 6;
  const [selectedCategory, setSelectedCategory] = useState(ALL);

  const dispatch = useDispatch();

  const { list: relatedPathwayProductList, categories } = useSelector(membershipRelatedPathwaysSelector);
  const selectedPathwayBundleProductId = useSelector(selectedPathwayBundleProductIdSelector);
  const currentJourneyLearningPathway = useSelector(currentJourneyLearningPathwaySelector);
  const isCimaPqCurrentJourney = AdminUtils.isCimaPqPathway(currentJourneyLearningPathway);
  const isChinesePqCurrentJourney = AdminUtils.isChinesePathway(currentJourneyLearningPathway);
  const membershipTiers = useSelector(membershipTiersSelector).list;
  const membershipCandidateSku = membershipTiers?.find(tiers => tiers.tierCode === MembershipTypes.TierCode.CORE)?.sku;

  const currency = useSelector(productCurrencySelector);
  const userLocationTier = useSelector(userTierSelector);
  const userRoles = useSelector(userRolesSelector);
  const [membershipLoading, setMembershipLoading] = useState(false);
  const [isAlreadyGetPathways, setIsAlreadyGetPathways] = useState(false);
  const [variantNumber, setVariantNumber] = useState(NUMBER_OF_VARIANTS);

  const selectedBundle = useMemo(() => {
    return (
      relatedPathwayProductList?.find(product => selectedPathwayBundleProductId === product?.productId) || {
        productId: '',
      }
    );
  }, [selectedPathwayBundleProductId, relatedPathwayProductList]);

  const filterBundlesByCurrency = useMemo(() => {
    return relatedPathwayProductList?.filter((bundle: State.PathwayProducts) =>
      bundle?.bundleProducts?.every((product: Product.ProductItem) =>
        product?.variants?.some((variant: Product.Variant) =>
          variant.prices?.some((price: Product.Price) => price?.currency === currency?.label)
        )
      )
    );
  }, [relatedPathwayProductList, currency]);

  const relatedProducts = useMemo(() => {
    return filterBundlesByCurrency
      .filter((product: State.PathwayProducts) => selectedCategory === ALL || product.visible)
      .map(product => ({
        ...product,
        selected: selectedBundle?.productId === product.productId,
      }));
  }, [filterBundlesByCurrency, selectedBundle, selectedCategory]);

  const hasNextPage = useMemo(() => {
    const sectionWithPriceCategory = categories.filter(({ name }) => name === selectedCategory);

    const numbPages = Math.ceil(
      selectedCategory === ALL
        ? relatedPathwayProductList.length / NUM_PER_PAGE
        : relatedPathwayProductList.filter(product => product.categories?.some(({ name }) => name === selectedCategory))
            .length / NUM_PER_PAGE
    );
    return (sectionWithPriceCategory[0]?.page ?? 0) < numbPages;
  }, [categories, relatedPathwayProductList, selectedCategory]);

  const productCategoryCount = categories.length;

  const handleMembershipCardPathwayButtonClick = useCallback(
    (productId: string, selected: boolean) => {
      selected ? dispatch(removeMembershipPackageRelatedPathway()) : dispatch(addMembershipPackagePathway(productId));
    },
    [dispatch]
  );

  const filterByCategory = useCallback(
    (categoryName: string) => {
      if (productCategoryCount >= 2) {
        setSelectedCategory(categoryName);
        dispatch(filterMembershipRelatedPathwaysByCategory(categoryName));
      }
    },
    [dispatch, productCategoryCount]
  );

  const variantsPriceInfoForUser = useCallback(
    (data: State.PathwayProducts) => {
      const bundleProducts = data.bundleProducts?.map((item: any) => item?.variants || []);
      const bundleTotalPrice = bundleProducts?.reduce((acc: number, variants: any) => {
        // Remove Candidate Variant for this is Zero in Adding to Cart
        const filteredVariants = variants?.filter(
          (variant: any) => variant?.productType !== Product.ProductType.MEMBERSHIP
        );
        const priceInfoForUser = ProductUtils.variantsPriceInfoForUser(
          ProductUtils.userApplicableVariantsPricing(
            filteredVariants,
            userRoles,
            false,
            currency.label,
            userLocationTier
          )
        );
        const slug = Object.keys(priceInfoForUser)[0];
        return acc + (priceInfoForUser[slug]?.priceFinal?.amount || 0);
      }, 0);
      return bundleTotalPrice ?? 0;
    },
    [currency, userRoles, userLocationTier]
  );

  const handleSeeMore = useCallback(() => {
    if (selectedCategory !== ALL) {
      dispatch(membershipPackageUpdatePageOfSpecificPathwayCategory(selectedCategory));
      dispatch(seeMoreMembershipRelatePathwayProduct(selectedCategory));
      setVariantNumber(variantNumber === NUMBER_OF_VARIANTS || hasNextPage ? Infinity : NUMBER_OF_VARIANTS);
    } else {
      setVariantNumber(variantNumber === NUMBER_OF_VARIANTS ? Infinity : NUMBER_OF_VARIANTS);
    }
  }, [dispatch, selectedCategory, variantNumber, hasNextPage]);

  const showSeeMore = useCallback(() => {
    const numbPages = Math.ceil(
      selectedCategory === ALL
        ? relatedPathwayProductList.length / NUMBER_OF_VARIANTS
        : relatedPathwayProductList.filter(product => product.categories?.some(({ name }) => name === selectedCategory))
            .length / NUM_PER_PAGE
    );
    if (numbPages > 1) {
      return true;
    }
    return false;
  }, [relatedPathwayProductList, selectedCategory]);

  useEffect(() => {
    const getPathwaysBySku = async () => {
      setMembershipLoading(true);
      setIsAlreadyGetPathways(true);
      if (isCimaPqCurrentJourney) dispatch(setMembershipPackageTier(membershipCandidateSku));
      await dispatch(getAllPathwayProducts(isChinesePqCurrentJourney));
      setMembershipLoading(false);
    };

    if (!isAlreadyGetPathways) getPathwaysBySku();
  }, [dispatch, isCimaPqCurrentJourney, isAlreadyGetPathways, membershipCandidateSku, isChinesePqCurrentJourney]);

  if (membershipLoading) {
    return <FeedPlaceholder />;
  }

  return (
    <>
      <RemainingProductTextWrapper>
        <RemainingProductText>Here are additional pathway products available to you</RemainingProductText>
      </RemainingProductTextWrapper>
      <CategoriesWrapper>
        {categories.map((category: State.ProductCategories) => {
          return !!category?.name ? (
            <StyledButton
              key={category.name}
              testId={`${category.name}`}
              isSelected={category.selected}
              onClick={filterByCategory.bind(null, category.name)}
            >
              {category.name}
            </StyledButton>
          ) : null;
        })}
      </CategoriesWrapper>
      <br />
      <StyledProductsGrid>
        {relatedProducts.map((data, index) => {
          if (index < variantNumber) {
            return (
              <CimaMembershipCard
                headerTitle={data?.name || ''}
                description={data?.description || ''}
                formattedPrice={centPriceToString(variantsPriceInfoForUser(data), currency.label)}
                isCardSelected={data.selected}
                isCurrent={false}
                slug={`${data.slug}`}
                disabled={Boolean(selectedBundle?.productId) && selectedBundle?.productId !== data?.productId}
                isEnable={!Boolean(selectedBundle?.productId) || selectedBundle?.productId === data?.productId}
                handleClick={handleMembershipCardPathwayButtonClick.bind(null, data.productId, !!data.selected)}
                productDetails={data}
                isExistingMember={false}
              />
            );
          }
          return null;
        })}
      </StyledProductsGrid>
      {showSeeMore() && (
        <SeeMoreWrapper>
          <StyledLabel onClick={handleSeeMore}>
            {variantNumber === NUMBER_OF_VARIANTS || (selectedCategory !== ALL && hasNextPage)
              ? 'See more'
              : 'See less'}
          </StyledLabel>
        </SeeMoreWrapper>
      )}
      <ConfirmButtonWrapper>
        <StyledConfirmButton
          testId={'confirm-btn'}
          onClick={handleClick}
          loading={loading}
          disabled={!Boolean(selectedBundle?.productId)}
        >
          Confirm
        </StyledConfirmButton>
      </ConfirmButtonWrapper>
    </>
  );
};

interface StyledButtonProps {
  isSelected: boolean;
}

const StyledProductsGrid = styled.div`
  display: grid;

  ${({ theme }) => theme.mediaQueries.desktopOnly} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }}
`;

const activeCategoryButtonStyles = css`
  border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryPurple};
  color: ${props => props.theme.colors.neutralWhite};
  background-color: ${props => props.theme.colors.primaryPurple};
`;

const wrapperWidthResponsiveStyles = css`
  ${props => props.theme.mediaQueries.desktopOnly} {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    &&& {
      width: 74%;
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 35%;
  }
`;

const RemainingProductTextWrapper = styled.div`
  text-align: left;
`;

const CategoriesWrapper = styled.div`
  text-align: left;

  ${wrapperWidthResponsiveStyles}
`;

const RemainingProductText = styled.span`
  width: ${props => props.theme.pxToRem(855)};
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props =>
    `${props.theme.pxToRem(30)} ${props.theme.pxToRem(245)} ${props.theme.pxToRem(20)} ${props.theme.pxToRem(1)}`};
  font-family: Roboto;
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.colors.neutralGrey8};
`;

const StyledButton = styled(Button)<StyledButtonProps>`
  &&& {
    border-radius: ${props => props.theme.pxToRem(6)};
    align-items: center;
    padding: ${props =>
      `${props.theme.pxToRem(8)} ${props.theme.pxToRem(14)} ${props.theme.pxToRem(8)} ${props.theme.pxToRem(14)}`};
    border-radius: ${props => props.theme.pxToRem(20)};

    margin: ${props => `${props.theme.pxToRem(5)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(10)} 0`};

    &:hover {
      ${activeCategoryButtonStyles}
    }

    &:focus {
      ${activeCategoryButtonStyles}
    }

    ${props =>
      props.isSelected
        ? css`
            ${activeCategoryButtonStyles}
          `
        : css`
            color: ${props.theme.colors.primaryPurple};
            background-color: ${props.theme.colors.neutralGrey2};
          `}
  }
`;

const StyledLabel = styled(Label)`
  &&& {
    background-color: transparent;
    color: ${props => props.theme.colors.primaryPurple};

    &:hover {
      cursor: pointer;
    }
  }
`;

const SeeMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${wrapperWidthResponsiveStyles}
`;

const StyledConfirmButton = styled(Button)`
  &&& {
    width: ${props => props.theme.pxToRem(290)};
    height: ${props => props.theme.pxToRem(50)};
    border-radius: ${props => props.theme.pxToRem(4)};
    background-color: ${props => props.theme.colors.primaryPurple};
    color: ${props => props.theme.colors.neutralWhite};

    &:hover {
      ${activeCategoryButtonStyles}
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    position: relative;
    top: ${props => props.theme.pxToRem(25)};
  }
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${props => props.theme.pxToRem(50)};
  margin-bottom: ${props => props.theme.pxToRem(50)};
`;
