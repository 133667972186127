import { createSelector } from 'reselect';
import { SalesforceResponses } from 'mxp-schemas';

// ------------------------------------
// Selectors
// ------------------------------------

const rootSelector = createSelector(
  (state: State.Root) => state.mip,
  (mip: State.MIP): State.MIP => mip
);

export const mipSelector = rootSelector;

export const contactReferenceSelector = createSelector(
  mipSelector,
  (mip: State.MIP): State.MipContact | any => mip.contactDetails
);

export const allPracticeRequirementSelector = createSelector(
  mipSelector,
  (mip: State.MIP): State.MipPracticeRequirement[] | any => mip.contactDetails?.practiceList
);

export const primaryPracticeRequirementSelector = createSelector(
  mipSelector,
  (mip: State.MIP): State.MipPracticeRequirement | any => mip.practiceRequirement
);

export const mipLoadingSelector = createSelector(rootSelector, (mip: State.MIP): boolean | any => mip.loading);

export const workHistorySelector = createSelector(
  mipSelector,
  (mip: State.MIP): State.MipWorkHistory | any => mip.workHistory
);

// Attestation Practice Requirements
export const attestationPracticeRequirementRootSelector = createSelector(
  (state: State.Root) => state.attesPracticeRequirementsReference,
  (
    attesPracticeRequirementsReference: State.AttestationPracticeRequirementReference
  ): State.AttestationPracticeRequirementReference => attesPracticeRequirementsReference
);

export const attestationPracticeRequirementReferenceSelector = createSelector(
  attestationPracticeRequirementRootSelector,
  (
    attesPracticeRequirementsReference: State.AttestationPracticeRequirementReference
  ): SalesforceResponses.PracticeRequirementAttestation | any =>
    attesPracticeRequirementsReference.attestationPracticeReference
);

export const isMipRenewalSeasonSelector = createSelector(
  mipSelector,
  (mip: State.MIP): boolean | any => mip.isRenewalSeason
);

export const getMipRenewalSeasonStartSelector = createSelector(
  mipSelector,
  (mip: State.MIP): string | any => mip.mipStartDate
);

export const getMipRenewalSeasonEndSelector = createSelector(
  mipSelector,
  (mip: State.MIP): string | any => mip.mipEndDate
);
