import { createSelector } from 'reselect';
import { emptyArray, filterNameNormalizer } from 'utils';
import { createMatchSelector } from 'connected-react-router';
import { savedItemsAggregationSelector, savedItemsMetaSelector } from 'modules/savedItems/selectors';
import { topicsHashSelector } from 'modules/topics/selectors';
import { industriesHashSelector } from 'modules/industries/selectors';
import { skillsHashSelector } from 'modules/skills/selectors';
import { trendsHashSelector } from 'modules/trends/selectors';
import { careerStagesHashSelector } from 'modules/careerStages/selectors';
import { contentLanguagesHashSelector } from 'modules/contentLanguages/selectors';
import { jobRolesHashSelector } from 'modules/jobRoles/selectors';
import { locationsHashSelector } from 'modules/locations/selectors';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';
import { Product } from 'mxp-schemas';

// eslint-disable-next-line
const rootSelector = createSelector(
  (state: State.Root) => state.search,
  search => search
);

export const searchResultsSelector = createSelector(rootSelector, search => search.results);

export const searchResultsLoadingSelector = createSelector(rootSelector, search => search.loading);

export const searchResultsFetchedSelector = createSelector(rootSelector, search => search.resultsFetched);

export const searchSuggestionsSelector = createSelector(rootSelector, search => search.suggestions);

export const searchMetaSelector = createSelector(rootSelector, search => search.meta);

export const suggestedSelector = createSelector(rootSelector, search => search.suggestedSearchQuery);

export const searchHistorySelector = createSelector(rootSelector, (search: State.Search): string[] => search.history);

export const searchAggregationSelector = createSelector(
  searchMetaSelector,
  (meta: State.Meta): State.SearchAggs | null => meta.aggs as State.SearchAggs | null
);

const routeMatchSelectorFactory = (route: Routes) => createMatchSelector(getPath(route));

export const smartAggregationSelector = createSelector(
  [searchAggregationSelector, savedItemsAggregationSelector, routeMatchSelectorFactory(Routes.PROFILE_SAVED_ITEMS)],
  (searchMeta: State.SearchAggs | null, savedItemsMeta: State.SearchAggs | null, route: any): any => {
    return route?.url === getPath(Routes.PROFILE_SAVED_ITEMS) ? savedItemsMeta : searchMeta;
  }
);

export const paginationSelector = createSelector(
  [searchMetaSelector, savedItemsMetaSelector, routeMatchSelectorFactory(Routes.PROFILE_SAVED_ITEMS)],
  (searchMeta: State.Meta | null, savedItemsMeta: State.Meta | null, route: any) => {
    const meta = (
      route && route.url === getPath(Routes.PROFILE_SAVED_ITEMS) ? savedItemsMeta : searchMeta
    ) as State.Meta;
    return {
      total: meta.total,
      from: (meta.page - 1) * meta.perPage + 1,
      to: meta.total < meta.page * meta.perPage ? meta.total : meta.page * meta.perPage,
      perPage: meta.perPage,
      pageNumber: meta.page / meta.perPage,
    };
  }
);

export const searchContentTypesSelector = createSelector(searchAggregationSelector, aggs =>
  aggs && aggs.contentTypes ? Object.keys(aggs.contentTypes) : emptyArray
);

export const contentIsContributionSelector = (productId: string) =>
  createSelector(searchResultsSelector, (search: any): boolean | undefined => {
    const product = search.find((p: any) => p.id === productId);
    return product?.productType.includes(Product.ProductType.CONTRIBUTION);
  });

export const topicFilterItemsSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector(
    [topicsHashSelector, smartAggregationSelector],
    (topicsHash: State.TopicsHash | null, aggs: State.SearchAggs | null): any[] => {
      if (!topicsHash || !aggs || !aggs.topics) return emptyArray;
      return Object.keys(aggs.topics)
        .reduce((acc: any[], key: string) => {
          const topic: State.Topic = topicsHash[key];
          // topic can be deleted from contentful but still exist in ES indexes
          if (topic) {
            acc.push(
              asDropdownOptions
                ? { key: topic.slug, text: topic.name, value: topic.slug }
                : {
                    name: topic.name,
                    id: topic.slug, // use the topic slug as a unique filter id
                    resultCount: aggs.topics[key],
                  }
            );
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (asDropdownOptions) return a.text.localeCompare(b.text);
          return b.resultCount - a.resultCount || a.name.localeCompare(b.name);
        });
    }
  );
};

export const industryFilterItemsSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector(
    [industriesHashSelector, smartAggregationSelector],
    (industriesHash: State.IndustriesHash | null, aggs: State.SearchAggs | null): Common.FilterItemProps[] => {
      if (!industriesHash || !aggs || !aggs.industries) return emptyArray;
      return Object.keys(aggs.industries)
        .reduce((acc: any[], key: string) => {
          const industry: State.Industry = industriesHash[key];

          if (industry) {
            acc.push(
              asDropdownOptions
                ? { key: industry.slug, text: industry.name, value: industry.slug }
                : {
                    name: industry.name,
                    id: industry.slug, // use the topic slug as a unique filter id
                    resultCount: aggs.industries[key],
                  }
            );
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.name && b.name) {
            return b.resultCount - a.resultCount || a.name.localeCompare(b.name);
          } else {
            return 0;
          }
        });
    }
  );
};

export const skillFilterItemsSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector(
    [skillsHashSelector, smartAggregationSelector],
    (skillsHash: State.SkillsHash | null, aggs: State.SearchAggs | null): Common.FilterItemProps[] => {
      if (!skillsHash || !aggs || !aggs.skills) return emptyArray;
      return Object.keys(aggs.skills)
        .reduce((acc: any[], key: string) => {
          const skill: State.Skill = skillsHash[key];

          if (skill) {
            acc.push(
              asDropdownOptions
                ? { key: skill.slug, text: skill.name, value: skill.slug }
                : {
                    name: skill.name,
                    id: skill.slug, // use the topic slug as a unique filter id
                    resultCount: aggs.skills[key],
                  }
            );
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.name && b.name) {
            return b.resultCount - a.resultCount || a.name.localeCompare(b.name);
          } else {
            return 0;
          }
        });
    }
  );
};

export const locationFilterItemsSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector(
    [locationsHashSelector, smartAggregationSelector],
    (locationsHash: State.LocationsHash | null, aggs: State.SearchAggs | null): Common.FilterItemProps[] => {
      if (!locationsHash || !aggs || !aggs.location) return emptyArray;
      return Object.keys(aggs.location)
        .reduce((acc: any[], key: string) => {
          const location: State.Location = locationsHash[key];

          if (location) {
            acc.push(
              asDropdownOptions
                ? { key: location.slug, text: location.name, value: location.slug }
                : {
                    name: location.name,
                    id: location.slug, // use the topic slug as a unique filter id
                    resultCount: aggs.location[key],
                  }
            );
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.name && b.name) {
            return b.resultCount - a.resultCount || a.name.localeCompare(b.name);
          } else {
            return 0;
          }
        });
    }
  );
};

export const trendFilterItemsSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector(
    [trendsHashSelector, smartAggregationSelector],
    (trendsHash: State.TrendsHash | null, aggs: State.SearchAggs | null): Common.FilterItemProps[] => {
      if (!trendsHash || !aggs || !aggs.trends) return emptyArray;
      return Object.keys(aggs.trends)
        .reduce((acc: any[], key: string) => {
          const trend: State.Trend = trendsHash[key];

          if (trend) {
            acc.push(
              asDropdownOptions
                ? { key: trend.slug, text: trend.name, value: trend.slug }
                : {
                    name: trend.name,
                    id: trend.slug, // use the topic slug as a unique filter id
                    resultCount: aggs.trends[key],
                  }
            );
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.name && b.name) {
            return b.resultCount - a.resultCount || a.name.localeCompare(b.name);
          } else {
            return 0;
          }
        });
    }
  );
};

export const jobRoleFilterItemsSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector(
    [jobRolesHashSelector, smartAggregationSelector],
    (jobRolesHash: State.JobRolesHash | null, aggs: State.SearchAggs | null): Common.FilterItemProps[] => {
      if (!jobRolesHash || !aggs || !aggs.jobRole) return emptyArray;
      return Object.keys(aggs.jobRole)
        .reduce((acc: any[], key: string) => {
          const jobRole: State.JobRole = jobRolesHash[key];

          if (jobRole) {
            acc.push(
              asDropdownOptions
                ? { key: jobRole.slug, text: jobRole.name, value: jobRole.slug }
                : {
                    name: jobRole.name,
                    id: jobRole.slug, // use the topic slug as a unique filter id
                    resultCount: aggs.jobRole[key],
                  }
            );
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.name && b.name) {
            return b.resultCount - a.resultCount || a.name.localeCompare(b.name);
          } else {
            return 0;
          }
        });
    }
  );
};

export const careerStageFilterItemsSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector(
    [careerStagesHashSelector, smartAggregationSelector],
    (careerStagesHash: State.CareerStagesHash | null, aggs: State.SearchAggs | null): Common.FilterItemProps[] => {
      if (!careerStagesHash || !aggs || !aggs.careerStage) return emptyArray;
      return Object.keys(aggs.careerStage)
        .reduce((acc: any[], key: string) => {
          const careerStage: State.CareerStage = careerStagesHash[key];

          if (careerStage) {
            acc.push(
              asDropdownOptions
                ? { key: careerStage.slug, text: careerStage.name, value: careerStage.slug }
                : {
                    name: careerStage.name,
                    id: careerStage.slug, // use the topic slug as a unique filter id
                    resultCount: aggs.careerStage[key],
                  }
            );
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.name && b.name) {
            return b.resultCount - a.resultCount || a.name.localeCompare(b.name);
          } else {
            return 0;
          }
        });
    }
  );
};

export const contentLanguageFilterItemsSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector(
    [contentLanguagesHashSelector, smartAggregationSelector],
    (
      contentLanguagesHash: State.ContentLanguagesHash | null,
      aggs: State.SearchAggs | null
    ): Common.FilterItemProps[] => {
      if (!contentLanguagesHash || !aggs || !aggs.contentLanguage) return emptyArray;
      return Object.keys(aggs.contentLanguage)
        .reduce((acc: any[], key: string) => {
          const contentLanguage: State.ContentLanguage = contentLanguagesHash[key];

          if (contentLanguage) {
            acc.push(
              asDropdownOptions
                ? { key: contentLanguage.slug, text: contentLanguage.name, value: contentLanguage.slug }
                : {
                    name: contentLanguage.name,
                    id: contentLanguage.slug, // use the topic slug as a unique filter id
                    resultCount: aggs.contentLanguage[key],
                  }
            );
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.name && b.name) {
            return b.resultCount - a.resultCount || a.name.localeCompare(b.name);
          } else {
            return 0;
          }
        });
    }
  );
};

export const contentTypeFilterItemsSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector([smartAggregationSelector], (aggs: State.SearchAggs | null): any[] => {
    if (!aggs || !aggs.contentTypes) return emptyArray;
    const list: any[] = [];

    Object.keys(aggs.contentTypes).forEach((key: string) => {
      const name: string = Object.keys(aggs.contentTypes[key])[0];
      if (aggs.contentTypes[key][name] !== 0) {
        list.push(
          asDropdownOptions
            ? {
                key,
                text: name,
                value: key,
              }
            : {
                name,
                id: key,
                resultCount: aggs.contentTypes[key][name],
              }
        );
      }
    });
    list.sort((a, b) => {
      if (a.name && b.name) {
        return b.resultCount - a.resultCount || a.name.localeCompare(b.name);
      } else {
        return 0;
      }
    });
    return list;
  });
};

export const cpeCreditFilterItemSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector([smartAggregationSelector], (aggs: State.SearchAggs | null): any[] => {
    if (!aggs) return emptyArray;
    const { credits0, credits1, credits2, credits3, credits4, credits5 } = aggs;
    if (!credits0 || !credits1 || !credits2 || !credits3 || !credits4 || !credits5) {
      return emptyArray;
    }
    const credits = [credits0, credits1, credits2, credits3, credits4, credits5];
    const cpeCredits: any[] = credits.map((obj: { [key: string]: number }, idx: number) => {
      const resultCount: number = obj['1'];
      if (!resultCount) return null;
      const key: string = idx.toString();
      const text: string = `${idx} Credit${idx === 1 ? '' : 's'} ${idx === 5 ? 'or more' : ''}`;
      return asDropdownOptions ? { key, text, value: key } : { name: text, id: key, resultCount };
    });
    return cpeCredits.filter(Boolean);
  });
};

export const getNoticedFilterItemSelectorFactory = (
  { asDropdownOptions }: { asDropdownOptions: boolean } = { asDropdownOptions: false }
) => {
  return createSelector([smartAggregationSelector], (aggs: State.SearchAggs | null): Common.FilterItemProps[] => {
    if (!aggs) return emptyArray;
    const { hasCertificateProgram, hasBadge } = aggs;

    if (!hasCertificateProgram || !hasBadge) {
      return emptyArray;
    }
    const getNoticed = { hasCertificateProgram, hasBadge };
    const options: (
      | { key: string; text: string; value: string }
      | { name: string; id: string; resultCount: number }
      | null
    )[] = Object.keys(getNoticed).map((key: string) => {
      const value: { [k: string]: number } = getNoticed[key as keyof typeof getNoticed];
      const resultCount: number = value['1'];
      if (!resultCount) return null;
      const text: string = filterNameNormalizer(key);
      return asDropdownOptions ? { key, text, value: key } : { name: text, id: key, resultCount };
    });
    return options.filter(Boolean) as Common.FilterItemProps[];
  });
};

export const eventDateSelector = createSelector(
  [smartAggregationSelector],
  (aggs: State.SearchAggs | null): Common.FilterItemProps[] => {
    if (!aggs || !aggs.variants || !aggs.variants.eventDate || !aggs.variants.eventDate.buckets) return emptyArray;
    return Object.keys(aggs.variants.eventDate.buckets)
      .filter(date => aggs.variants.eventDate.buckets[date])
      .map((key: string) => ({
        name: key,
        id: key,
        resultCount: aggs.variants.eventDate.buckets[key],
      }));
  }
);

export const skipUserInfoRefreshSelector = createSelector(
  rootSelector,
  (search: State.Search): boolean => search.skipUserInfoRefresh
);
// react-frontload with react-loadable call method twice
export const skipSearchFrontloadRequestSelector = createSelector(
  rootSelector,
  (search: State.Search): boolean => search.skipSearchFrontloadRequest
);

export const getReferrerSelector = createSelector(rootSelector, (search: State.Search): string => search.referrer);

export const searchPromotionalBannerProductSelector = createSelector(
  rootSelector,
  search => search.promotionalBannerProduct
);
