import React from 'react';
import { BlockRendererLandingPageBlock } from './BlockRendererLandingPageBlock';
import { BlockRendererToolkitBlock } from './BlockRendererToolkitBlock';
import { Contentful } from 'mxp-schemas';

interface Props {
  blockRenderLandingPage?: BlockRenderLandingPage | null;
  blockRenderToolkit?: BlockRenderToolkitPage | null;
}

interface BlockRenderToolkitPage {
  block: State.ToolkitMainColumn | State.ToolkitLeftColumn;
  idx: number;
  showDivider?: boolean;
  isFullWidth?: boolean;
  topicSlug?: string;
  topicName?: string;
  subtopicSlug?: string;
  key: string;
}

interface BlockRenderLandingPage {
  block: Contentful.ModularContent.Block;
  isFullPagePagination: boolean;
  isUserOnBoarded: boolean;
  headerHeight?: number;
  footerHeight?: number;
  withHeader?: boolean;
  withFooter?: boolean;
  withExternalAd?: boolean;
  targetContainer?: any;
  containerContentRef?: any;
  isFirstBlock?: boolean;
  isMobile?: boolean;
  isAuth: boolean | null;
  isToolkitPage?: boolean;
  isLandingPage?: boolean;
  isArticlePage?: boolean;
  isLocked?: boolean;
  isStaticLandingPageFetched?: boolean;
  isStaticLandingPage?: boolean;
  isInHeader?: boolean;
  isHomePage?: boolean;
}

export const BlockRenderer: React.FC<Props> = ({ blockRenderLandingPage, blockRenderToolkit }) => {
  const renderPage = (): React.ReactNode => {
    if (blockRenderLandingPage) {
      return (
        <BlockRendererLandingPageBlock
          block={blockRenderLandingPage.block}
          isFullPagePagination={blockRenderLandingPage.isFullPagePagination}
          isUserOnBoarded={blockRenderLandingPage.isUserOnBoarded}
          headerHeight={blockRenderLandingPage.headerHeight}
          footerHeight={blockRenderLandingPage.footerHeight}
          withHeader={blockRenderLandingPage.withHeader}
          withFooter={blockRenderLandingPage.withFooter}
          withExternalAd={blockRenderLandingPage.withExternalAd}
          targetContainer={blockRenderLandingPage.targetContainer}
          containerContentRef={blockRenderLandingPage.containerContentRef}
          isFirstBlock={blockRenderLandingPage.isFirstBlock}
          isMobile={blockRenderLandingPage.isMobile}
          isAuth={blockRenderLandingPage.isAuth}
          isToolkitPage={blockRenderLandingPage.isToolkitPage}
          isLandingPage={blockRenderLandingPage.isLandingPage}
          isArticlePage={blockRenderLandingPage.isArticlePage}
          isLocked={blockRenderLandingPage.isLocked}
          isStaticLandingPageFetched={blockRenderLandingPage.isStaticLandingPageFetched}
          isStaticLandingPage={blockRenderLandingPage.isStaticLandingPage}
          isInHeader={blockRenderLandingPage.isInHeader}
          isHomePage={blockRenderLandingPage.isHomePage}
        />
      );
    }

    if (blockRenderToolkit) {
      return (
        <BlockRendererToolkitBlock
          block={blockRenderToolkit.block}
          idx={blockRenderToolkit.idx}
          showDivider={blockRenderToolkit?.showDivider}
          isFullWidth={blockRenderToolkit.isFullWidth}
          topicSlug={blockRenderToolkit.topicSlug || ''}
          topicName={blockRenderToolkit.topicName || ''}
          subtopicSlug={blockRenderToolkit.subtopicSlug || ''}
          key={blockRenderToolkit.key}
        />
      );
    }
  };

  return renderPage() as JSX.Element;
};
