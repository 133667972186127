import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = createSelector(
  (state: State.Root) => state.inquiryForm,
  (inquiryForm: State.InquiryForm): State.InquiryForm => inquiryForm
);

export const isErrorSelector = createSelector(
  rootSelector,
  (inquiryForm: State.InquiryForm): boolean => inquiryForm.error
);

export const isLoadingSelector = createSelector(
  rootSelector,
  (inquiryForm: State.InquiryForm): boolean => inquiryForm.loading
);

export const isSuccessSelector = createSelector(
  rootSelector,
  (inquiryForm: State.InquiryForm): boolean => inquiryForm.success
);

export const isErrorMessageSelector = createSelector(
  rootSelector,
  (inquiryForm: State.InquiryForm): string => inquiryForm.errorMessage
);

export const isSearchLoadingSelector = createSelector(
  rootSelector,
  (inquiryForm: State.InquiryForm): boolean => inquiryForm.searchLoading
);

export const searchResultsSelector = createSelector(
  rootSelector,
  (inquiryForm: State.InquiryForm): State.InquiryFormSearchResult[] => {
    return inquiryForm?.searchResults;
  }
);

export const searchSelectionSelector = createSelector(
  rootSelector,
  (inquiryForm: State.InquiryForm): State.InquiryFormSearchResult | null => {
    return inquiryForm?.searchSelection;
  }
);
