import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, ButtonEnums, Input } from 'components/atoms';
import { DropdownWithLabel } from 'components/molecules';
import { Salesforce } from 'mxp-schemas';
import { Routes } from 'constants/index';
import { push } from 'connected-react-router';
import { getPath } from 'utils';
import { generatePath } from 'react-router';
import { default as request } from 'utils/GraphQLClient';
import { GET_ORGANIZATION_BY_ACCOUNT_NUMBER } from 'mxp-graphql-queries';
import { IS_SALESFORCE_OFFLINE } from 'modules/featureToggle/constants';
import {
  resetCartModule,
  getOrganizationCurrency,
  getOrganizationZuoraAccountId,
  setLegalEntity,
  setSalesforceAccountNumber as setAccountNumber,
} from 'modules/admin/actions';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';

const ORG_NUMBER = 'org-number';

export const PageAdminLookUpOrg = () => {
  const [salesforceAccountNumber, setSalesforceAccountNumber] = useState('');
  const [saleforceLegalEntity, setSaleforceLegalEntity] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    request(GET_ORGANIZATION_BY_ACCOUNT_NUMBER, { accountNumber: salesforceAccountNumber })
      .then((data: { organization: Salesforce.Organization | null }) => {
        if (!data.organization) {
          dispatch(
            push({
              pathname: getPath(Routes.ADMIN_NO_FETCH_RESULTS),
              state: { query: salesforceAccountNumber },
            })
          );
          return;
        }
        dispatch(resetCartModule());
        dispatch(setLegalEntity(saleforceLegalEntity));
        dispatch(getOrganizationCurrency(salesforceAccountNumber, saleforceLegalEntity));
        dispatch(getOrganizationZuoraAccountId(salesforceAccountNumber, saleforceLegalEntity));
        dispatch(setAccountNumber(salesforceAccountNumber));
        dispatch(
          push(
            generatePath(getPath(Routes.ADMIN_ORGANIZATION), {
              accountId: data.organization.id,
              accountNumber: salesforceAccountNumber,
              legalEntity: saleforceLegalEntity,
            })
          )
        );

        setLoading(false);
        return data.organization;
      })
      .catch(() => {
        setLoading(false);
        dispatch(push(getPath(Routes.ADMIN_ERROR_PAGE)));
      });
  };

  const handleSalesforceAccountNumberChange = useCallback(
    ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
      setSalesforceAccountNumber(value);
    },
    []
  );

  const isSalesforceOffline = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, IS_SALESFORCE_OFFLINE)
  );

  const legalEntityOptions = Object.values(Salesforce.LegalEntity).map((value: any, index) => {
    return { key: index, value, text: value };
  });

  const handleDropdownChange = (e: any, data: any) => setSaleforceLegalEntity(data.value);

  return (
    <>
      {isSalesforceOffline ? (
        <></>
      ) : (
        <form onSubmit={onSubmit}>
          <StyledText data-testid="preferences-text">
            To begin a new client order, please look up the Organization using <br /> the Salesforce Account Number
            (listed on Details page of the Account).
          </StyledText>
          <StyledInputContainer>
            <StyledRegularInput
              labelName="Salesforce account number"
              placeholder="e.g. A-000000"
              name={ORG_NUMBER}
              type="text"
              value={salesforceAccountNumber}
              testId={ORG_NUMBER}
              labelId={ORG_NUMBER}
              onChange={handleSalesforceAccountNumberChange}
            />
          </StyledInputContainer>

          <StyledDropdown
            onChange={handleDropdownChange}
            testId="legal-entity"
            title="Legal Entity"
            selected={saleforceLegalEntity || ''}
            options={legalEntityOptions}
            placeholder={'Select legal entity'}
          />

          <SearchButton
            size={ButtonEnums.sizes.medium}
            onClick={onSubmit}
            testId="search-user"
            variant={ButtonEnums.variants.primary}
            disabled={!salesforceAccountNumber || !saleforceLegalEntity || loading}
            loading={loading}
          >
            Search
          </SearchButton>
        </form>
      )}
    </>
  );
};

const StyledInputContainer = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(40)};
  max-width: ${props => props.theme.pxToRem(265)};
`;

const StyledText = styled.div`
  max-width: 720px;
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.pxToRem(14)};
  line-height: 1.71;
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(17)};
`;

const StyledRegularInput = styled(Input)`
  margin-bottom: ${props => props.theme.pxToRem(0)};
  width: 100%;
`;

const SearchButton = styled(Button)`
  width: ${props => props.theme.pxToRem(98)};
`;

const StyledDropdown = styled(DropdownWithLabel)`
  ${props => `
    &&& {
      width: ${props.theme.pxToRem(265)};
      padding: ${props.theme.pxToRem(12)};
      height: ${props.theme.pxToRem(46)};
      margin-right: ${props.theme.pxToRem(12)};
      border-radius: ${props.theme.pxToRem(5)};
      margin-bottom: ${props.theme.pxToRem(20)};
   }
  `}
`;
