import React from 'react';
import { Product } from 'mxp-schemas';
import styled from 'styled-components';
import { NotificationForAnonymousUser } from 'components/molecules/NotificationForAnonymousUser';
import { ParagraphTitle, TitleSizes } from './ProductParagraphs';
import { StandingOrderNotification } from 'components/molecules/StandingOrderNotification/StandingOrderNotification';

interface Props {
  allPrices?: Product.PriceForRole[];
  isAuth: boolean | null;
  isWebcast?: boolean;
  standingOrderEligible?: boolean;
  isStandingOrderVariantInStock?: boolean;
  standingOrderDiscount?: Product.StandingOrderDiscount;
}

interface CellProps {
  'data-is-lower-price': boolean;
}

export const PricingInfo: React.FC<Props> = ({
  allPrices,
  isAuth,
  isWebcast,
  standingOrderEligible,
  isStandingOrderVariantInStock,
  standingOrderDiscount,
}) => (
  <Wrap data-testid="pricing-table-info">
    <StyledParagraphTitle titleSize={TitleSizes.LARGE}>Pricing</StyledParagraphTitle>
    {!isAuth && <StyledNotificationForAnonymousUser />}
    <StyledListContainer>
      {allPrices &&
        allPrices.map((item, key) => {
          if (!item?.variantsWithAvailableFormatInfo) return null;
          const isLowestPrice: boolean = Boolean(isAuth && item.isLowestApplicablePrice);
          return (
            <React.Fragment key={`fragment${key}`}>
              {(item?.variantsWithAvailableFormatInfo || []).map(
                (availableFormatInfo: Product.VariantPriceInfoForAvailableFormat, idx: number) => (
                  <StyledRow key={`${key}${idx}`} hasBg={!!(key % 2)}>
                    <StyledCellMain isLowerPrice={isLowestPrice} isRepeating={!!(idx !== 0)}>
                      {idx === 0 && item.appliedDiscountName}
                    </StyledCellMain>
                    {availableFormatInfo.format !== '' && !isWebcast && (
                      <StyledCellSecondary isLowerPrice={isLowestPrice}>
                        {availableFormatInfo.format}
                      </StyledCellSecondary>
                    )}
                    <StyledCellSecondary isLowerPrice={isLowestPrice}>
                      {availableFormatInfo.transformedPrice}
                    </StyledCellSecondary>
                  </StyledRow>
                )
              )}
            </React.Fragment>
          );
        })}
      {standingOrderEligible && isStandingOrderVariantInStock && (
        <StyledDiscountRow hasBg={(allPrices && allPrices?.length % 2 !== 0) || false}>
          <StandingOrderNotification standingOrderDiscount={standingOrderDiscount} />
        </StyledDiscountRow>
      )}
    </StyledListContainer>
  </Wrap>
);

const Wrap = styled.div`
  margin-top: ${props => props.theme.pxToRem(38)};
`;

const StyledParagraphTitle = styled(ParagraphTitle)`
  line-height: 1.33;
`;

const StyledNotificationForAnonymousUser = styled(NotificationForAnonymousUser)`
  && {
    margin-top: ${props => props.theme.pxToRem(10)};
    font-size: ${props => props.theme.fontSizes.m};
    line-height: 1.78;

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-top: ${props => props.theme.pxToRem(16)};
      margin-bottom: ${props => props.theme.pxToRem(11)};
      font-size: ${props => props.theme.fontSizes.xs};
      line-height: 1.57;
    }
  }
`;

const StyledListContainer = styled.div`
  margin: ${props => props.theme.pxToRem(16)} 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow-y: auto;
  }
`;

const StyledRow = styled.div<{ hasBg: boolean }>`
  display: flex;
  background-color: ${props => (props.hasBg ? 'rgba(247, 247, 247, 0.3)' : props.theme.colors.neutralWhite)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-width: ${props => props.theme.pxToRem(450)};
  }
`;

const StyledDiscountRow = styled.div<{ hasBg: boolean }>`
  display: flex;
  background-color: ${props => (props.hasBg ? 'rgba(247, 247, 247, 0.3)' : props.theme.colors.neutralWhite)};
  padding: ${props => props.theme.pxToRem(20)};
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-width: ${props => props.theme.pxToRem(450)};
  }
`;

const StyledCellMain = styled.div<{ isRepeating?: boolean; isLowerPrice?: boolean }>`
  display: flex;
  flex: 1;
  height: ${props => props.theme.pxToRem(64)};
  padding: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => (props.isLowerPrice ? props.theme.fontWeights.medium : props.theme.fontWeights.regular)};
  line-height: 1.5;
  border-top: ${props => (props.isRepeating ? 'none' : '1px solid rgba(34,36,38,.1)')};

  ${props => props.theme.mediaQueries.mobileOnly} {
    font-weight: ${props => props.theme.fontWeights.regular};
    min-width: ${props => props.theme.pxToRem(200)};
  }
`;

const StyledCellSecondary = styled.div<{ isLowerPrice?: boolean }>`
  display: flex;
  flex: 1;
  padding: ${props => props.theme.pxToRem(20)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => (props.isLowerPrice ? props.theme.fontWeights.medium : props.theme.fontWeights.regular)};
  line-height: 1.5;
  border-top: 1px solid rgba(34, 36, 38, 0.1);

  ${props => props.theme.mediaQueries.mobileOnly} {
    min-width: ${props => props.theme.pxToRem(125)};
  }
`;
