import React from 'react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { DefaultInlineLinkStyles, Link } from 'components/atoms/Link/Link';
import { getContentUrl } from 'utils';
import { ReactComponent as ArrowUp } from 'resources/images/icon-arrow-up.svg';
import { ReactComponent as ArrowDown } from 'resources/images/icon-arrow-down.svg';
import { AnchorMarkdownRenderer } from 'components/atoms';

export enum TitleSizes {
  LARGE = 'l',
  MEDIUM = 'm',
}

interface BaseParagraphProps {
  title?: string;
  titleSize?: TitleSizes;
  subtitle?: string;
  summary?: string;
}

interface ListParagraphProps extends BaseParagraphProps {
  listItems: string[];
  testId: string;
}

interface ListContentLinksParagraphProps extends BaseParagraphProps {
  contentItems: Array<Partial<State.ContentCardItem>>;
  subtitle?: string;
  testId: string;
}

interface TextParagraphProps extends BaseParagraphProps {
  contentText: string | React.ReactNode;
  hasMarkdown?: boolean;
  testId: string;
  subtitleLink?: string;
  subtitleLinkName?: string;
  isContribution?: boolean;
  isConference?: boolean;
}

interface CustomParagraphProps extends BaseParagraphProps {
  child?: JSX.Element;
  testId: string;
  firstTitle?: string;
  secondTitle?: string;
}

// is custom for a little more flexibility
export const CustomParagraph: React.FC<CustomParagraphProps> = React.memo(
  ({ title, child, testId, titleSize, subtitle, summary }) => {
    return (
      <Wrap data-testid={testId}>
        <ParagraphTitle titleSize={titleSize}>{title}</ParagraphTitle>
        <StyledText noPaddTop={true}>{child}</StyledText>
      </Wrap>
    );
  }
);

export const ListParagraph: React.FC<ListParagraphProps> = React.memo(
  ({ title, listItems, titleSize = TitleSizes.LARGE, testId }) => {
    return (
      <Wrap data-testid={testId}>
        <ParagraphTitle titleSize={titleSize}>{title}</ParagraphTitle>
        <StyledUl>
          {Boolean(listItems && listItems.length) &&
            listItems.map((item: string, i) => <StyledLi key={i}>{item}</StyledLi>)}
        </StyledUl>
      </Wrap>
    );
  }
);

export const ListContentLinksParagraph: React.FC<ListContentLinksParagraphProps> = React.memo(
  ({ title, contentItems, titleSize = TitleSizes.LARGE, testId, subtitle }) => {
    const [isReadMore, setIsReadMore] = React.useState(false);

    const onReadMoreClick = () => {
      setIsReadMore(!isReadMore);
    };

    return (
      <Wrap data-testid={testId}>
        <ParagraphTitle titleSize={titleSize}>{title}</ParagraphTitle>
        {subtitle && <StyledText>{subtitle}</StyledText>}
        <StyledPremiumContentUl>
          {Boolean(contentItems?.length) &&
            contentItems.slice(0, isReadMore ? contentItems.length : 10).map((item: Partial<State.ContentCardItem>) => (
              <StyledLi key={item.title}>
                <StyledPremiumContentLink to={getContentUrl(item)}>{item.title}</StyledPremiumContentLink>
              </StyledLi>
            ))}
          {Boolean(contentItems?.length && contentItems?.length > 10) && (
            <StyledExpandListWrapper onClick={onReadMoreClick} data-testid="expand-list-button">
              {isReadMore ? (
                <span>
                  Read less <StyledArrowUp />
                </span>
              ) : (
                <span>
                  Read more <StyledArrowDown />
                </span>
              )}
            </StyledExpandListWrapper>
          )}
        </StyledPremiumContentUl>
      </Wrap>
    );
  }
);

export const TextParagraph: React.FC<TextParagraphProps> = React.memo(
  ({
    title,
    contentText,
    titleSize,
    subtitle,
    subtitleLink,
    subtitleLinkName,
    summary,
    hasMarkdown,
    testId,
    isContribution,
    isConference,
  }) => {
    React.useEffect(() => {
      if (isConference) {
        const anchorButtons = document.querySelectorAll('a');
        for (const anchorButton of anchorButtons) {
          anchorButton.setAttribute('target', '_blank');
        }
      }
    }, [isConference]);
    return (
      <Wrap noTopGap={!title} data-testid={testId}>
        {title && <ParagraphTitle titleSize={titleSize}>{title}</ParagraphTitle>}
        {subtitle && (
          <Subtitle>
            {subtitle}
            {subtitleLink && subtitleLinkName && <Link to={subtitleLink}>{subtitleLinkName}</Link>}
          </Subtitle>
        )}
        {summary && <StyledText noPaddTop>{summary}</StyledText>}
        <StyledText tabIndex={0} noPaddTop={Boolean(summary)} isContribution={Boolean(isContribution)}>
          {hasMarkdown ? (
            <ReactMarkdownStyled renderers={{ link: AnchorMarkdownRenderer }} children={contentText as string} />
          ) : (
            contentText
          )}
        </StyledText>
      </Wrap>
    );
  }
);

type StyledTitleProps = Pick<BaseParagraphProps, 'titleSize'>;
export const ParagraphTitle = styled.div<ThemedStyledProps<StyledTitleProps, DefaultTheme>>`
  font-size: ${props => (props.titleSize === TitleSizes.LARGE ? props.theme.fontSizes.l : props.theme.fontSizes.m)};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.neutralGrey8};
`;

export const ReactMarkdownStyled = styled(ReactMarkdown)`
  a {
    ${DefaultInlineLinkStyles};
  }

  table {
    width: 100%;
    color: ${props => props.theme.colors.neutralGrey8};
    border-spacing: 0;

    th {
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.medium};
      line-height: 1.57;
      padding: ${props => props.theme.pxToRem(11)} ${props => props.theme.pxToRem(20)};
      background-color: ${props => props.theme.colors.neutralGrey1};
      text-align: left;
      background-color: rgba(247, 247, 247, 0.3);
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    }

    td {
      font-size: ${props => props.theme.fontSizes.s};
      line-height: 1.5;
      padding: ${props => props.theme.pxToRem(11)} ${props => props.theme.pxToRem(20)};
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: rgba(247, 247, 247, 0.3);
        }
      }
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-height: 2.25rem;
  font-size: ${props => props.theme.fontSizes.xs};
  -webkit-tap-highlight-color: transparent;
`;

const Wrap = styled.div<{ noTopGap?: boolean }>`
  ${props => !props.noTopGap && `margin-top: ${props.theme.pxToRem(28)}`}
`;

const StyledUl = styled.ul`
  padding-left: ${props => props.theme.pxToRem(18)};
`;

const StyledExpandListWrapper = styled.div`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-top: ${props => props.theme.pxToRem(18)};

  & > span {
    display: flex;
    cursor: pointer;
  }
`;

const StyledArrowUp = styled(ArrowUp)`
  fill: ${props => props.theme.colors.primaryDarkPurple};
  align-self: center;
  margin-left: ${props => props.theme.pxToRem(6)};
  width: 1.5rem;
  height: 1.5rem;
`;

const StyledArrowDown = styled(ArrowDown)`
  fill: ${props => props.theme.colors.primaryPurple};
  align-self: center;
  margin-left: ${props => props.theme.pxToRem(6)};
  width: 1.5rem;
  height: 1.5rem;
`;

const StyledPremiumContentUl = styled(StyledUl)`
  margin-top: ${props => props.theme.pxToRem(8)};
  margin-bottom: ${props => props.theme.pxToRem(38)};
`;

const StyledPremiumContentLink = styled(StyledLink)`
  display: block;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-height: 2.25rem;
  margin-bottom: ${props => props.theme.pxToRem(10)};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.medium};
  -webkit-tap-highlight-color: transparent;
`;

const StyledLi = styled.li`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.78;
`;

const StyledText = styled.div<ThemedStyledProps<{ noPaddTop?: boolean; isContribution?: boolean }, DefaultTheme>>`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  padding-top: ${props => (props.noPaddTop ? 0 : props.theme.pxToRem(16))};
  line-height: ${props => (props.isContribution ? props.theme.pxToRem(32) : 1.78)};
`;

const Subtitle = styled(StyledText)<ThemedStyledProps<{ noPaddTop?: boolean }, DefaultTheme>>`
  font-weight: ${props => props.theme.fontWeights.medium};
  padding-top: ${props => (props.noPaddTop ? 0 : props.theme.pxToRem(16))};
`;
