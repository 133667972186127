import { Routes } from '../../../constants';
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { MiniUserWidgetContainer } from 'containers/UserWidgetContainer';
import { useHistory } from 'react-router';
import { Container } from 'semantic-ui-react';
import { Link, OnlyDesktop, OnlyMobile } from 'components/atoms';
import { ReactComponent as AicpaCimaLogoWhite } from 'resources/images/aicpa-cima-logo-white.svg';
import { ReactComponent as AicpaCimaLogoWhiteMobile } from 'resources/images/aicpa-cima-logo-white-mobile.svg';
import { ReactComponent as ArrowBack } from 'resources/images/ic-arrow-back.svg';
import { getPath } from 'utils';

interface Props {
  firstHeader?: string;
  secondHeader?: string;
  thirdHeader?: string;
  headerTitle?: string | undefined;
}

export const MembershipHeader: React.FC<Props> = ({ firstHeader, secondHeader, thirdHeader, headerTitle }) => {
  const history = useHistory();

  const handleLogoClick = () => {
    history.push(getPath(Routes.ROOT));
  };

  return (
    <>
      <Helmet>
        <title>{headerTitle}</title>
      </Helmet>
      <StyledHeader />
      <>
        <StyledHeaderContainer fluid>
          <OnlyDesktop minWidth={768}>
            <StyledContainer fluid>
              <StyledAicpaCimaLogoWhite onClick={handleLogoClick} />
              <StyledMiniUserWidget isWhite={true} showLogoutLink />
            </StyledContainer>
          </OnlyDesktop>
          <OnlyMobile maxWidth={767}>
            <StyledContainer fluid>
              <StyledAicpaCimaLogoWhiteMobile onClick={handleLogoClick} />
              <StyledMiniUserWidget isWhite={true} showLogoutLink />
            </StyledContainer>
          </OnlyMobile>
          <StyledContainer fluid>
            <StyledBackLink to={getPath(Routes.LOGIN)}>
              <ArrowBack /> Back
            </StyledBackLink>
          </StyledContainer>{' '}
          {firstHeader && <StyledH1>{firstHeader}</StyledH1>}
          {secondHeader && <StyledH2>{secondHeader}</StyledH2>}
          {thirdHeader && <StyledText>{thirdHeader}</StyledText>}
        </StyledHeaderContainer>
      </>
    </>
  );
};

const StyledH1 = styled.h1`
  font-size: ${props => props.theme.pxToRem(45)};
  color: ${props => props.theme.colors.neutralWhite};
  text-align: center;
  font-family: ${props => props.theme.fontFamily};
`;
const StyledH2 = styled.h2`
  margin-top: 0;
  color: ${props => props.theme.colors.neutralWhite};
  font-weight: normal;
  font-size: ${props => props.theme.pxToRem(35)};
  font-family: ${props => props.theme.fontFamily};
  text-align: center;
`;
const StyledText = styled.p`
  font-size: ${props => props.theme.pxToRem(20)};
  text-align: center;
  color: #ffdeff;
  font-family: ${props => props.theme.fontFamily};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-bottom: ${props => props.theme.pxToRem(30)};
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;
const StyledHeader = styled.section`
  height: ${props => props.theme.pxToRem(449)};
  width: 150%;
  border-radius: 0 0 65% 65%/0 0 100% 100%;
  background-image: linear-gradient(73deg, #6a2d87 35%, #a93d69 101%);
  transform: translateX(-16.5%);
  ${props => props.theme.mediaQueries.mobileOnly} {
    height: ${props => props.theme.pxToRem(504.4)};
    border-radius: 0 0 200% 200%/0 0 100% 100%;
  }
`;

const StyledHeaderContainer = styled(Container)`
  top: 0;
  position: absolute;
  margin: ${props => `0 0 ${props.theme.pxToRem(1030)}`};
  padding: ${props =>
    `${props.theme.pxToRem(15)} ${props.theme.pxToRem(160)} ${props.theme.pxToRem(49)} ${props.theme.pxToRem(160)}`};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `0 0 ${props.theme.pxToRem(-5)}`};
    left: 0;
    text-align: center;
    padding: ${props =>
      `${props.theme.pxToRem(18)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(13.4)} ${props.theme.pxToRem(10)}`};
  }
`;

const StyledMiniUserWidget = styled(MiniUserWidgetContainer)`
  color: ${props => props.theme.colors.neutralWhite} !important;
  text-decoration: none;
`;

const StyledAicpaCimaLogoWhite = styled(AicpaCimaLogoWhite)`
  cursor: pointer;
  height: ${props => props.theme.pxToRem(34)};
  width: ${props => props.theme.pxToRem(288)};
`;

const StyledAicpaCimaLogoWhiteMobile = styled(AicpaCimaLogoWhiteMobile)`
  cursor: pointer;
  float: left !important;
  height: ${props => props.theme.pxToRem(34)};
  width: ${props => props.theme.pxToRem(48)};
`;

const StyledContainer = styled(Container)`
  &&&& {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    left: 0;
  }
`;

const StyledBackLink = styled(Link)`
  display: inline-flex;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  text-decoration: none;
  color: ${props => props.theme.colors.neutralWhite} !important;
  margin: ${props => `${props.theme.pxToRem(25)} 0 ${props.theme.pxToRem(-50)} 0`};
  :hover {
    text-decoration: underline;
  }
  > svg {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    margin-right: ${props => props.theme.pxToRem(4)};
    path {
      fill: ${props => props.theme.colors.neutralWhite};
    }
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props => `${props.theme.pxToRem(50)} 0 0 0`};
  }
`;
