import React from 'react';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import { getFirmNaming, getPath } from 'utils';
import { Link } from 'components/atoms';
import { Routes } from 'constants/index';

interface Props {
  useCimaFeatures?: boolean;
  isForBillingInvoices?: boolean;
}

export const FirmSupportBanner: React.FC<Props> = ({ useCimaFeatures = false, isForBillingInvoices = false }) => (
  <StyledContainer fluid>
    {useCimaFeatures && isForBillingInvoices ? (
      <StyledText>
        If you have any questions or concerns regarding invoices, please select the chat icon to the right of your
        screen, or{' '}
        <Link isExternal to={getPath(Routes.HELP)}>
          visit our help page
        </Link>{' '}
        for frequently asked questions and other contact information.
      </StyledText>
    ) : (
      <>
        <StyledHeading>Have a question?</StyledHeading>
        <StyledText>
          If you have any questions or concerns regarding invoices, please reach out to your{' '}
          {getFirmNaming(useCimaFeatures)} Billing Contact.
        </StyledText>
      </>
    )}
  </StyledContainer>
);

const StyledContainer = styled(Container)`
  &&&& {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.pxToRem(180)};
    background-color: ${props => props.theme.colors.neutralGrey1};
    text-align: center;
    border-width: ${props => props.theme.pxToRem(1)};
    border-style: solid;
    border-color: ${props => props.theme.colors.neutralGrey2};
  }
`;

const StyledHeading = styled.h3`
  font-size: ${props => props.theme.fontSizes.l};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(8)};
`;

const StyledText = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.neutralGrey8};
  margin-bottom: ${props => props.theme.pxToRem(8)};
  max-width: ${props => props.theme.pxToRem(430)};
`;
