import * as Sentry from '@sentry/browser';
import { handleActions } from 'redux-actions';
import {
  loading,
  fetchCalendarSearchContent,
  fetchPromotionalBannerProduct,
  fetchSearchContent,
  fetchSearchSuggestions,
  clearSuggestions,
  removeSearchTerm,
  getSearchHistory,
  changeFilter,
  clearFilter,
  resetSkipSearchFrontloadRequest,
  resetSearchModule,
  setSearchReferrer,
  setSkipUserInfoRefresh,
} from 'modules/search/actions';
import { isServer } from 'utils';

if (
  [
    resetSearchModule,
    loading,
    fetchPromotionalBannerProduct,
    fetchSearchContent,
    fetchSearchSuggestions,
    clearSuggestions,
    removeSearchTerm,
    getSearchHistory,
    changeFilter,
    clearFilter,
  ].includes(undefined)
) {
  const message: string = 'search module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

const initialState: State.Search = {
  results: [],
  loading: false,
  resultsFetched: false,
  skipSearchFrontloadRequest: false,
  skipUserInfoRefresh: false,
  meta: {
    total: 0,
    perPage: 0,
    page: 0,
    aggs: null,
  },
  suggestions: {
    results: [],
    searchQuery: '',
  },
  history: [],
  suggestedSearchQuery: null,
  referrer: '',
  promotionalBannerProduct: null,
  historyCategories: {
    total: 0,
    perPage: 0,
    page: 0,
    aggs: null,
  },
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [loading]: (state: State.Search): State.Search => ({
    ...state,
    loading: true,
  }),
  [fetchPromotionalBannerProduct]: {
    next: (state: State.Search, action: any): State.Search => ({
      ...state,
      promotionalBannerProduct: action.payload,
    }),
  },
  [fetchSearchContent]: {
    next: (state: State.Search, action: any): State.Search => ({
      ...state,
      ...action.payload.getContentBySearch,
      loading: false,
      resultsFetched: true,
      skipSearchFrontloadRequest: isServer,
      historyCategories:
        state.historyCategories.aggs === null ? action.payload.getContentBySearch.meta : state.historyCategories,
    }),
  },
  [fetchSearchSuggestions]: {
    next: (state: State.Search, action: any): State.Search => {
      const { results, searchQuery } = action.payload.getSearchSuggestions;

      return {
        ...state,
        suggestions: {
          results,
          searchQuery,
        },
      };
    },
  },

  [clearSuggestions]: (state: State.Search, action: any): State.Search => ({
    ...state,
    suggestions: initialState.suggestions,
  }),

  [getSearchHistory]: {
    next: (state: State.Search, action: any): State.Search => ({
      ...state,
      history: action.payload.getSearchHistory,
    }),
  },

  [removeSearchTerm]: {
    next: (state: State.Search, action: any): State.Search => ({
      ...state,
      history: action.payload.removeSearchTerm,
    }),
  },

  [resetSkipSearchFrontloadRequest]: (state: State.Search): State.Search => ({
    ...state,
    skipSearchFrontloadRequest: false,
  }),

  [setSearchReferrer]: (state: State.Search, action: any): State.Search => ({
    ...state,
    referrer: action.payload,
  }),

  [setSkipUserInfoRefresh]: (state: State.Search, action: any): State.Search => ({
    ...state,
    skipUserInfoRefresh: action.payload,
  }),

  [resetSearchModule]: (state: State.Search): State.Search => {
    const { promotionalBannerProduct, ...searchInitialState } = initialState;

    return { ...state, ...searchInitialState };
  },
};

export {
  fetchSearchContent,
  changeFilter,
  clearFilter,
  fetchCalendarSearchContent,
  fetchPromotionalBannerProduct,
  resetSkipSearchFrontloadRequest,
  resetSearchModule,
  setSearchReferrer,
};

export default handleActions(ACTION_HANDLERS, initialState);
