import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, ButtonEnums, Input, LabeledIcon } from 'components/atoms';
import { DropdownWithLabel } from 'components/molecules/DropdownWithLabel/DropdownWithLabel';
import moment from 'moment-timezone';
import { ReactComponent as InfoBubble } from 'resources/images/info-bubble-inverted.svg';
import styled from 'styled-components/macro';
import { Grid, Popup, TextArea } from 'semantic-ui-react';
import { Product, ZuoraTypes, User } from 'mxp-schemas';
import { cancelProductPreview, cancelSubscriptionPreview } from 'modules/admin/actions';
import { priceToFloat } from 'utils';
import { ethicsCaseSelector } from 'modules/user/selectors';

/**
 * Cancel/Refund Product Modal
 * @category Refunds & Cancellations
 * RBUILD-8850
 */

interface CancelPreviewResponse {
  payload: { previewCancelOrder: ZuoraTypes.CancelPreviewDetails };
}

export interface Props {
  productName: string;
  open: boolean;
  onClose(): void;
  onConfirm(data: any): void;
  isViewingProRation?: boolean;
  order: any;
  membershipRelatedProducts?: Common.ProductItemData[];
  subscriptionAmountAdded: { subscriptionNumber: string; amount: number }[];
  setSubscriptionAmountAdded(data: any): void;
}

export const CancelOrRefundProductModal: React.FC<Props> = ({
  productName,
  open,
  onClose,
  onConfirm,
  isViewingProRation,
  order,
  membershipRelatedProducts,
  subscriptionAmountAdded,
  setSubscriptionAmountAdded,
}) => {
  const dispatch = useDispatch();
  const [productAmount, setProductAmount] = useState(0);
  const [requiredActionNotSelected, setRequiredActionNotSelected] = useState(true);
  const [notMetConditions, setNotMetConditions] = useState(true);
  const [reasonFieldDisabled, setReasonFieldDisabled] = useState(true);
  const [proratedButtonDisabled, setProratedButtonDisabled] = useState(true);
  const [amountToMoveDisabled, setAmountToMoveDisabled] = useState(true);
  const [refundAmountDisabled, setRefundAmountDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('You have exceeded the original amount.');
  const toolTipMessage = 'For proration purposes, not deprovision purposes';
  const PRORATION_DATE_FIELD_ID = 'proration_date';
  const REFUND_AMOUNT_FIELD_ID = 'refund_amount';
  const AMOUNT_TO_MOVE_FIELD_ID = 'amount_to_move';
  const REQUIRED_ACTION_FIELD_ID = 'required_action';
  const TERMINATION_FIELD_ID = 'termination';
  const TERMINATION_REASON_FIELD_ID = 'termination_reason';
  const REASON_FIELD_ID = 'reason';
  const RequiredActionOptions = Product.RefundOrCancellationRequiredActions;

  const [reasonOptions, setReasonOptions] = useState(Product.RefundOrCancellationReasons);
  const typeOfCancellationOptions = Product.RefundOrCancellationTypeOfCancellation;
  const [typeOfCancellationReasonOptions, setTypeOfCancellationReasonOptions] = useState(
    Product.RefundOrCancellationTerminationReason
  );
  const [reason, setReason] = useState('');
  const [requiredAction, setRequiredAction] = useState('');
  const [amountToMove, setAmountToMove] = useState('');
  const [refundAmount, setRefundAmount] = useState('');
  const [hasErrorRefundAmount, setHasErrorRefundAmount] = useState(false);
  const [hasErrorMoveAmount, setHasErrorMoveAmount] = useState(false);
  const [isCancelPreviewInProgress, setCancelPreviewInProgress] = useState(false);
  const [isTerminationSectionVisible, setIsTerminationSectionVisible] = useState(false);
  const [isRefundSectionVisible, setIsRefundSectionVisible] = useState(false);
  const [comment, setComment] = useState('');
  const [typeOfCancellationReason, setTypeOfCancellationReason] = useState('');
  const [typeOfCancellation, setTypeOfCancellation] = useState('');
  const [isCancellationBlock, setIsCancellationBlock] = useState(false);
  const [isCalculateProrationClicked, setIsCalculateProrationClicked] = useState(false);

  const ethicStatus = useSelector(ethicsCaseSelector);
  const MEMBERSHIP_PRODUCTS = [
    Product.ProductType.MEMBERSHIP,
    Product.ProductType.SECTION,
    Product.ProductType.CREDENTIAL,
  ];
  const isMembershipSectionCredentialProducts = MEMBERSHIP_PRODUCTS.some((item: string) => order?.productType === item);
  const isFLPSkillsCoreMembership = order?.productSlug === User.FLPSubscriptions.SKILLS_CORE;

  useEffect(() => {
    if (isMembershipSectionCredentialProducts || isFLPSkillsCoreMembership) {
      if (
        requiredAction === Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION ||
        requiredAction === Product.RefundOrCancellationEnums.CANCEL_AND_MOVE
      ) {
        setIsTerminationSectionVisible(true);
        setIsRefundSectionVisible(true);
      } else if (requiredAction === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION) {
        setIsTerminationSectionVisible(false);
        setIsRefundSectionVisible(true);
      } else if (requiredAction === Product.RefundOrCancellationEnums.CANCEL_ONLY) {
        setIsTerminationSectionVisible(true);
        setIsRefundSectionVisible(false);
      }
    } else {
      if (
        [
          Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION,
          Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION,
          Product.RefundOrCancellationEnums.CANCEL_AND_MOVE,
        ].includes(requiredAction as Product.RefundOrCancellationEnums)
      ) {
        setIsTerminationSectionVisible(false);
        setIsRefundSectionVisible(true);
      }
    }
  }, [requiredAction, isMembershipSectionCredentialProducts, isFLPSkillsCoreMembership]);

  useEffect(() => {
    const productsToBeCancelled = [
      ...[order],
      ...(order?.productType === Product.ProductType.MEMBERSHIP && membershipRelatedProducts
        ? membershipRelatedProducts
        : []),
    ];
    const addTax = async () => {
      setCancelPreviewInProgress(true);
      await Promise.all(
        productsToBeCancelled.map(async (data: any) => {
          // TODO: change the params of cancelSubscriptionPreview as an array instead of calling it multiple times in FE
          if (open && data?.subscriptionNumber && data?.ratePlanId) {
            const cancelPayload = {
              subscriptionNumber: data?.subscriptionNumber,
              subscriptionStartDate: moment().format(data?.zuoraTermStartDate),
              isAutoRenewable: data?.isAutoRenewable,
              shouldCancelFromStartDate: true,
              previewThruStartDate: moment(data?.zuoraTermStartDate) > moment(),
              ...(!data?.isOnlyProductOnSubscription && { ratePlanId: data?.ratePlanId }),
            };
            await dispatch(
              data?.isOnlyProductOnSubscription ||
                [Product.ProductType.MEMBERSHIP, Product.ProductType.FLP].includes(
                  order?.productType as Product.ProductType
                )
                ? cancelSubscriptionPreview(cancelPayload)
                : cancelProductPreview(cancelPayload)
            ).then((response: CancelPreviewResponse) => {
              const paidAmount = response?.payload?.previewCancelOrder?.pricePaid || 0;
              const isSubscriptionIncludedInTaxAdded = subscriptionAmountAdded?.some(
                (st: any) => st.subscriptionNumber === data?.subscriptionNumber
              );
              if (!isSubscriptionIncludedInTaxAdded) {
                // if subsNumber is not yet added to the state, add amount
                setProductAmount(prevState => Math.round(Number(prevState + paidAmount) * 100) / 100);
                // add tax amount to the state if done adding it to the original amount
                setSubscriptionAmountAdded((prevState: any) => [
                  ...prevState,
                  ...[{ subscriptionNumber: data.subscriptionNumber, amount: paidAmount }],
                ]);
              }
            });
          }
        })
      );
      setCancelPreviewInProgress(false);
    };
    addTax();
  }, [dispatch, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRequiredActionChange = (e: any, data: any) => {
    setTypeOfCancellation('');
    setTypeOfCancellationReason('');
    if (data.value === Product.RefundOrCancellationEnums.CANCEL_ONLY) {
      setProratedButtonDisabled(true);
      setRequiredActionNotSelected(false);
      setReasonFieldDisabled(false);
      setRefundAmountDisabled(true);
      setAmountToMoveDisabled(true);
      setHasErrorMoveAmount(false);
      setHasErrorRefundAmount(false);
      setRefundAmount('');
      setAmountToMove('');
      if (requiredAction && reason) setNotMetConditions(false);
    } else if (data.value === Product.RefundOrCancellationEnums.CANCEL_AND_MOVE) {
      setAmountToMoveDisabled(false);
      setProratedButtonDisabled(false);
      setRequiredActionNotSelected(false);
      setReasonFieldDisabled(false);
      setRefundAmountDisabled(true);
      setRefundAmount('');
      setAmountToMove(String(productAmount));
      setHasErrorRefundAmount(false);
    } else {
      setRequiredActionNotSelected(false);
      setReasonFieldDisabled(false);
      setProratedButtonDisabled(false);
      setRefundAmountDisabled(false);
      setAmountToMoveDisabled(true);
      setAmountToMove('');
      setRefundAmount(String(productAmount));
      setHasErrorMoveAmount(false);
    }

    if (data.value === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION) {
      const filteredReasons = reasonOptions.filter((reasonOption: any) => reasonOption.value !== 'Cancellation');
      setReasonOptions(filteredReasons);
    } else {
      setReasonOptions(Product.RefundOrCancellationReasons);
    }
    setRequiredAction(data.value);
  };

  const handleRefundAmountChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setRefundAmount(value);
      if (!value) setHasErrorRefundAmount(false);

      if (!Number(value) && value) {
        setErrorMessage('Please enter number only.');
        setHasErrorRefundAmount(true);
        return;
      }

      if (Number(value) > productAmount) {
        setHasErrorRefundAmount(true);
        setErrorMessage('You have exceeded the original amount.');
      } else {
        setHasErrorRefundAmount(false);
      }
    },
    [setRefundAmount, setErrorMessage, productAmount]
  );

  const handleReasonChange = (e: any, data: any) => {
    setReason(data.value);
  };

  const handleTypeOfCancellationReasonChange = useCallback(
    (e: any, data: any) => {
      setTypeOfCancellationReason(data.value);
    },
    [setTypeOfCancellationReason]
  );

  const handleTypeOfCancellationChange = useCallback(
    (e: any, data: any) => {
      setTypeOfCancellation(data.value);
    },
    [setTypeOfCancellation]
  );

  const handleAmountToMoveChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setAmountToMove(value);
      if (!value) setHasErrorMoveAmount(false);

      if (!Number(value) && value) {
        setErrorMessage('Please enter number only.');
        setHasErrorMoveAmount(true);
        return;
      }

      if (Number(value) > productAmount) {
        setHasErrorMoveAmount(true);
        setErrorMessage('You have exceeded the original amount.');
      } else {
        setHasErrorMoveAmount(false);
      }
    },
    [setAmountToMove, productAmount]
  );

  const handleCommentChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    },
    [setComment]
  );

  const handleCloseModal = useCallback(() => {
    onClose();
    setRefundAmount('');
    setAmountToMove('');
    setRequiredAction('');
    setReason('');
    setTypeOfCancellationReason('');
    setTypeOfCancellation('');
    setRefundAmountDisabled(true);
    setAmountToMoveDisabled(true);
    setHasErrorMoveAmount(false);
    setHasErrorRefundAmount(false);
    setReasonFieldDisabled(true);
    setNotMetConditions(true);
    setIsTerminationSectionVisible(false);
    setIsRefundSectionVisible(false);
  }, [
    onClose,
    setRefundAmount,
    setAmountToMove,
    setRequiredAction,
    setReason,
    setRefundAmountDisabled,
    setAmountToMoveDisabled,
    setHasErrorMoveAmount,
    setHasErrorRefundAmount,
    setReasonFieldDisabled,
    setTypeOfCancellationReason,
    setTypeOfCancellation,
    setNotMetConditions,
    setIsTerminationSectionVisible,
    setIsRefundSectionVisible,
  ]);

  const handleConfirmModal = useCallback(() => {
    onConfirm({
      reason,
      requiredAction,
      amountToMove,
      refundAmount,
      typeOfCancellation,
      typeOfCancellationReason,
      comment,
      isCalculateProrationClicked,
    });
    handleCloseModal();
  }, [
    onConfirm,
    handleCloseModal,
    reason,
    requiredAction,
    amountToMove,
    refundAmount,
    typeOfCancellation,
    typeOfCancellationReason,
    comment,
    isCalculateProrationClicked,
  ]);

  const formatDate = (date?: string) => {
    return moment(date || new Date()).format('YYYY/MM/DD');
  };

  const popupStyle = {
    cursor: 'pointer',
  };

  useEffect(() => {
    if (requiredActionNotSelected) {
      setRequiredActionNotSelected(true);
    }
  }, [requiredActionNotSelected]);

  useEffect(() => {
    if (isMembershipSectionCredentialProducts) {
      if (
        requiredAction === Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION ||
        requiredAction === Product.RefundOrCancellationEnums.CANCEL_AND_MOVE ||
        requiredAction === Product.RefundOrCancellationEnums.CANCEL_ONLY
      ) {
        const isConditionsMet =
          reason.length &&
          typeOfCancellation.length &&
          typeOfCancellationReason.length &&
          !hasErrorRefundAmount &&
          !hasErrorMoveAmount;

        setNotMetConditions(!isConditionsMet);
      } else {
        const isConditionsMet = reason.length && requiredAction.length && !hasErrorRefundAmount && !hasErrorMoveAmount;
        setNotMetConditions(!isConditionsMet);
      }
    } else {
      if (
        requiredAction === Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION ||
        requiredAction === Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION ||
        requiredAction === Product.RefundOrCancellationEnums.CANCEL_AND_MOVE
      ) {
        if (reason.length && !hasErrorRefundAmount && !hasErrorMoveAmount) setNotMetConditions(false);
      } else {
        if (reason.length) setNotMetConditions(false);
      }
    }
  }, [
    requiredAction,
    reason,
    typeOfCancellation,
    typeOfCancellationReason,
    hasErrorRefundAmount,
    hasErrorMoveAmount,
    setNotMetConditions,
    isMembershipSectionCredentialProducts,
  ]);

  useEffect(() => {
    setTypeOfCancellationReasonOptions(
      typeOfCancellation === Product.RefundOrCancellationTypeOfCancellationEnums.TERMINATION
        ? Product.RefundOrCancellationTerminationReason
        : Product.RefundOrCancellationResignationReason
    );
  }, [typeOfCancellation]);

  useEffect(() => {
    if (isMembershipSectionCredentialProducts) {
      const blockListForEthicStatus = [
        Product.ProductEthicStatus.REMEDIATION,
        Product.ProductEthicStatus.SANCTIONED,
        Product.ProductEthicStatus.SUSPENDED,
        Product.ProductEthicStatus.UNDER_INVESTIGATION,
      ];
      const requiredActionListForEthicStatusCheck = [
        Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION,
        Product.RefundOrCancellationEnums.CANCEL_ONLY,
        Product.RefundOrCancellationEnums.CANCEL_AND_MOVE,
      ];
      if (requiredActionListForEthicStatusCheck.some((item: string) => requiredAction === item)) {
        if (blockListForEthicStatus.some((item: string) => ethicStatus === item)) {
          setIsCancellationBlock(true);
        }
      } else {
        setIsCancellationBlock(false);
      }
    } else {
      setIsCancellationBlock(false);
    }
  }, [ethicStatus, isMembershipSectionCredentialProducts, requiredAction]);

  useEffect(() => {
    if (!reason || !requiredAction) setNotMetConditions(true);
  }, [reason, requiredAction]);

  const handleCalculateProrationButton = useCallback(() => {
    setCancelPreviewInProgress(true);
    setIsCalculateProrationClicked(true);
    const cancelPayload = {
      subscriptionNumber: order?.subscriptionNumber,
      subscriptionStartDate: moment().format('YYYY-MM-DD'),
      isAutoRenewable: order?.isAutoRenewable,
      shouldCancelFromStartDate: false,
      ...(!order?.isOnlyProductOnSubscription && { ratePlanId: order?.ratePlanId }),
    };
    dispatch(
      order?.isOnlyProductOnSubscription ||
        [Product.ProductType.MEMBERSHIP, Product.ProductType.FLP].includes(order?.productType as Product.ProductType)
        ? cancelSubscriptionPreview(cancelPayload)
        : cancelProductPreview(cancelPayload)
    )
      .then((response: CancelPreviewResponse) => {
        setCancelPreviewInProgress(false);

        if (amountToMoveDisabled) setRefundAmount(response.payload.previewCancelOrder.refundAmount.toString());
        else setAmountToMove(response.payload.previewCancelOrder.refundAmount.toString());
      })
      .catch(() => {
        setCancelPreviewInProgress(false);
      });
  }, [order, dispatch, amountToMoveDisabled]);

  return (
    <StyledModal
      open={open}
      onClose={handleCloseModal}
      size="tiny"
      testId="cancel-or-refund-product-modal"
      heading="Cancel/Refund product"
      confirmNode={
        <ButtonContainer>
          {isCancellationBlock && (
            <EthicsStatusErrorMessage>{`You cannot cancel this customer’s products due to Ethics reasons`}</EthicsStatusErrorMessage>
          )}
          <Button
            size={ButtonEnums.sizes.small}
            testId="confirm-button"
            variant={ButtonEnums.variants.primary}
            onClick={handleConfirmModal}
            disabled={notMetConditions || hasErrorMoveAmount || hasErrorRefundAmount}
          >
            Confirm
          </Button>
        </ButtonContainer>
      }
      showCloseCross
    >
      <Grid stackable centered columns={3}>
        <StyledGridRow>
          <StyledSection>
            <StyledBold>Product:</StyledBold>
            {productName}
            <br />
            <StyledBold>Original amount: </StyledBold>
            {isCancelPreviewInProgress ? (
              <StyledButtonLinkLoader
                size={ButtonEnums.sizes.small}
                testId="original-amount-button-dummy"
                variant={ButtonEnums.variants.link}
                loading={true}
              />
            ) : (
              `${productAmount.toFixed(2)}`
            )}
          </StyledSection>
        </StyledGridRow>
        <StyledGridRow>
          <Grid.Column width={14}>
            <StyledDropdown
              disabled={isCancelPreviewInProgress}
              scrolling
              placeholder="Select an action"
              testId={REQUIRED_ACTION_FIELD_ID}
              name={REQUIRED_ACTION_FIELD_ID}
              title="Required action"
              options={RequiredActionOptions}
              onChange={handleRequiredActionChange}
            />
          </Grid.Column>
        </StyledGridRow>
        <StyledGridRow>
          <Grid.Column width={14}>
            <StyledInnerGridContainer>
              <StyledDropdown
                scrolling
                placeholder="Select a Reason"
                testId={REASON_FIELD_ID}
                name={REASON_FIELD_ID}
                title="Reason"
                options={reasonOptions}
                onChange={handleReasonChange}
                disabled={reasonFieldDisabled || isCancellationBlock}
              />
            </StyledInnerGridContainer>
          </Grid.Column>
        </StyledGridRow>
        {isTerminationSectionVisible && (
          <>
            <StyledGridRow>
              <Grid.Column width={14}>
                <StyledDropdown
                  scrolling
                  placeholder="Select type of cancellation"
                  testId={TERMINATION_FIELD_ID}
                  name={TERMINATION_FIELD_ID}
                  title="Termination/Resignation"
                  options={typeOfCancellationOptions}
                  onChange={handleTypeOfCancellationChange}
                  value={typeOfCancellation}
                  disabled={isCancellationBlock}
                />
              </Grid.Column>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column width={14}>
                <StyledInnerGridContainer>
                  <StyledDropdown
                    scrolling
                    placeholder="Select a Reason"
                    testId={TERMINATION_REASON_FIELD_ID}
                    name={TERMINATION_REASON_FIELD_ID}
                    title="Reason"
                    options={typeOfCancellationReasonOptions} // TO DO ADD THE OPTIONS ADDED FROM ZUORA
                    onChange={handleTypeOfCancellationReasonChange}
                    value={typeOfCancellationReason}
                    disabled={isCancellationBlock}
                  />
                  <StyledTextAreaContainer>
                    <TextAreaLabel>Comment (Optional)</TextAreaLabel>
                    <TextAreaStyled
                      value={comment}
                      onChange={handleCommentChange}
                      testId={'comment-input'}
                      rows={2}
                      width={'100%'}
                      disabled={isCancellationBlock}
                    />
                  </StyledTextAreaContainer>
                </StyledInnerGridContainer>
              </Grid.Column>
            </StyledGridRow>
          </>
        )}
        {isRefundSectionVisible && (
          <>
            <StyledGridRow>
              <Grid.Column width={isViewingProRation ? 7 : 14}>
                <StyledInput
                  labelName={
                    <LabeledIcon
                      icon={
                        <Popup
                          content={toolTipMessage}
                          trigger={<StyledInfoBubble />}
                          position="bottom left"
                          style={popupStyle}
                        />
                      }
                      label="Proration date"
                      mobileLabel="Proration date"
                      labelFirst
                    />
                  }
                  autoFocus={true}
                  name={PRORATION_DATE_FIELD_ID}
                  value={formatDate()}
                  testId={PRORATION_DATE_FIELD_ID}
                  labelId={PRORATION_DATE_FIELD_ID}
                  disabled
                />
              </Grid.Column>
              {isViewingProRation && (
                <StyledGridColumn width={7}>
                  <Button
                    size={ButtonEnums.sizes.medium}
                    testId="calculate-proration-button"
                    variant={ButtonEnums.variants.secondary}
                    disabled={proratedButtonDisabled}
                    onClick={handleCalculateProrationButton}
                    loading={isCancelPreviewInProgress}
                  >
                    Calculate pro-ration
                  </Button>
                </StyledGridColumn>
              )}
            </StyledGridRow>
            <StyledGridRow>
              {/* //TODO: Fix proration button for rendering refund amount and amount to move values */}
              <Grid.Column width={7}>
                <StyledInput
                  labelName="Refund amount "
                  autoFocus={true}
                  name={REFUND_AMOUNT_FIELD_ID}
                  value={priceToFloat(refundAmount)}
                  // defaultValue={zuoraDetails.refundAmount}
                  placeholder=" 0.00"
                  testId={REFUND_AMOUNT_FIELD_ID}
                  labelId={REFUND_AMOUNT_FIELD_ID}
                  onChange={handleRefundAmountChange}
                  disabled={refundAmountDisabled || isCancellationBlock}
                  // isCorrect={correctAmountCheck}
                  // errorMessage={errorAmountMessage}
                  // isCorrectIconShown
                />
                {hasErrorRefundAmount && <StyledP>{errorMessage}</StyledP>}
              </Grid.Column>
              <Grid.Column width={7}>
                <StyledInput
                  labelName="Amount to move "
                  autoFocus={true}
                  name={AMOUNT_TO_MOVE_FIELD_ID}
                  value={priceToFloat(amountToMove)}
                  placeholder=" 0.00"
                  testId={AMOUNT_TO_MOVE_FIELD_ID}
                  labelId={AMOUNT_TO_MOVE_FIELD_ID}
                  onChange={handleAmountToMoveChange}
                  disabled={amountToMoveDisabled || isCancellationBlock}
                  // isCorrect={correctAmountCheck}
                  // errorMessage={errorAmountMessage}
                  // isCorrectIconShown
                />
                {hasErrorMoveAmount && <StyledP>{errorMessage}</StyledP>}
              </Grid.Column>
            </StyledGridRow>
          </>
        )}
      </Grid>
    </StyledModal>
  );
};

const EthicsStatusErrorMessage = styled.p`
  color: ${props => props.theme.colors.interfaceRed};
  font-size: ${props => props.theme.pxToRem(13)};
`;

const StyledP = styled.p`
  color: ${props => props.theme.colors.interfaceRed};
`;

const StyledModal = styled(Modal)`
  &&&& {
    background: ${props => props.theme.colors.neutralGrey1};
    min-height: ${props => props.theme.pxToRem(682)};
    max-width: ${props => props.theme.pxToRem(590)};
    margin-top: ${props => props.theme.pxToRem(48)};
    > .header {
      h2 {
        min-height: ${props => props.theme.pxToRem(64)};
        max-width: ${props => props.theme.pxToRem(440)};
        padding: ${props => props.theme.pxToRem(10)};
        margin: 0 auto;
        font-size: ${props => props.theme.fontSizes.l};
      }
    }
  }
  button > svg {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledGridRow = styled(Grid.Row)`
  &&&&& {
    padding: 0 0 ${props => props.theme.pxToRem(10)} 0;
  }
`;

const StyledButtonLinkLoader = styled(Button)`
  &&& {
    padding-left: ${props => props.theme.pxToRem(10)} !important;
    top: ${props => props.theme.pxToRem(5)};
  }
`;

const StyledSection = styled.div`
  min-width: ${props => props.theme.pxToRem(250)};
  border-radius: ${props => props.theme.pxToRem(2)};
  padding: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(43)};
  background-color: ${props => props.theme.colors.neutralGrey1};
  color: ${props => props.theme.colors.neutralGrey8};
  text-align: center;
  justify-content: center;
  align-items: center;
  word-wrap: break-word;
  font-size: ${props => props.theme.fontSizes.xs};
`;

const StyledInfoBubble = styled(InfoBubble)`
  &&&& {
    fill: ${props => props.theme.colors.primaryPurple};
    padding-top: ${props => props.theme.pxToRem(10)};
  }
`;

const StyledBold = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: 0 ${props => props.theme.pxToRem(3)} 0 ${props => props.theme.pxToRem(3)};
`;

const StyledDropdown = styled(DropdownWithLabel)`
  &&&.ui.search.dropdown {
    border-radius: ${props => props.theme.pxToRem(4)};
  }
  &&&.ui.dropdown .menu {
    height: auto;
  }
  &&&.ui.search.dropdown > input.search {
    cursor: pointer;
  }
  &&&.ui.default.dropdown:not(.button) > .text,
  &&&.ui.dropdown:not(.button) > .default.text,
  &&&.ui.search.dropdown > .text,
  &&&.ui.dropdown .menu > .item {
    color: ${props => props.theme.colors.primaryPurple};
    font-weight: ${props => props.theme.fontWeights.regular};
  }
  &&&.ui.dropdown .menu > * {
    white-space: normal;
  }
  &&&.disabled {
    &&&.ui.dropdown {
      border-color: ${props => props.theme.colors.neutralGrey8};
      background-color: ${props => props.theme.colors.neutralGrey3};
    }
    &&&.ui.dropdown .menu > .item {
      color: ${props => props.theme.colors.neutralGrey8};
    }
  }
`;

const StyledInput = styled(Input)`
  &&&.ui.input > input {
    margin-bottom: ${props => props.theme.pxToRem(10)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(5)};
    }
  }
  &&&.disabled {
    input {
      border-color: ${props => props.theme.colors.neutralGrey8};
      background-color: ${props => props.theme.colors.neutralGrey3};
    }
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&& {
    margin-top: ${props => props.theme.pxToRem(40)} !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: ${props => props.theme.pxToRem(160)};
  }
`;

const StyledInnerGridContainer = styled.div`
  width: 100%;
  border-width: 0 0 0 2px;
  border-style: solid;
  border-color: ${props => props.theme.colors.neutralGrey4};
  padding-left: 30px;
`;

const TextAreaStyled = styled(TextArea)`
  width: 100%;
  height: ${props => props.theme.pxToRem(90)};
  margin-top: ${props => props.theme.pxToRem(15)};
`;

const TextAreaLabel = styled.span`
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: 1rem;
`;

const StyledTextAreaContainer = styled.div`
  margin-top: ${props => props.theme.pxToRem(15)};
`;
