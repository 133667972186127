// ------------------------------------
// Constants
// ------------------------------------
import { Salesforce } from 'mxp-schemas';

export enum SupervisorActionNames {
  SET_LOADING = 'feedback/SET_LOADING',
  SET_ERROR = 'feedback/SET_ERROR',
  GET_FEEDBACK = 'feedback/GET_FEEDBACK',
  CREATE_FEEDBACK = 'feedback/CREATE_FEEDBACK',
  UPDATE_FEEDBACK = 'feedback/UPDATE_FEEDBACK',
  SEND_STUDENT_EMAIL_NOTIFICATION = 'user/SEND_STUDENT_EMAIL_NOTIFICATION',
}

export const initialState: State.Supervisor = {
  feedback: {
    requestedDate: '',
    achievements: '',
    areasOfImprovement: '',
    externalValidatorKey: '',
    perKey: '',
    caseKey: '',
    feedbackStatus: Salesforce.PERFeedbackStatus.DRAFT,
    submissionDate: '',
    error: null,
    loading: false,
  },
};
