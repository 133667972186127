import { Product } from 'mxp-schemas';
import { fromCentsFormat } from 'mxp-utils/dist/lib/utils';
import { MomentHelpers } from 'utils/MomentHelpers';

export const getStructuredData = (
  productItem: Product.ProductItem,
  fullUrl: string,
  variantsDateTimeInfo?: Product.VariantDateTimeInfo[] | null
) => {
  const isProductTypeWebcast: boolean = productItem?.productType === Product.ProductType.WEBCAST;
  const isProductTypeConference: boolean = productItem?.productType === Product.ProductType.CONFERENCE;

  return `
	  {
		"@context": "http://schema.org/",
		"@id": "./",
		"@type": "Course",
		"genre": "${productItem.categories?.filter(category => category.isMain)?.[0]?.name || ''}",
		"text": "${productItem.overview}",
		"timeRequired": "${MomentHelpers.getDurationISOString(
      variantsDateTimeInfo?.[0]?.duration || 0,
      isProductTypeWebcast ? 'hours' : 'days'
    )}",
		"description": "${productItem.description || ''}",
		"identifier": "${productItem.slug}",
		"image": "${productItem.variants.filter(variant => variant.isMaster)?.[0]?.images?.[0]?.imageUrl || ''}",
		"name": "${productItem.name}",
		"url": "${fullUrl}",
		"creator": [
		  ${productItem.authors?.map(
        author =>
          `
			  {
				"@type": "Person",
				"name": "${author.name}"
			  }
			  `
      )}
		],
		${
      isProductTypeWebcast || isProductTypeConference
        ? `
			"hasCourseInstance":
			[
			  ${variantsDateTimeInfo?.map((variant: Product.VariantDateTimeInfo) => {
          const currentVariant = productItem.variants?.filter(itemVariant => itemVariant.sku === variant.sku)?.[0];
          return `
				  {
					"@type": "CourseInstance",
					"endDate": "${variant.end}",
					"startDate": "${variant.start}",
					"image": "${currentVariant.images?.[0]?.imageUrl || ''}",
					"name": "${productItem.name}",
					"location": {
					  "@type": "Place",
					  "name": "Event Address of ${productItem.name}",
					  "address": {
						"@type": "PostalAddress",
						"name": "${isProductTypeWebcast ? 'Online' : currentVariant.address}"
					  }
					},
					"offers": [
					  ${currentVariant?.prices?.map(
              price => `
						  {
							"@type": "Offer",
							"sku": "${variant.sku || ''}",
							"price": "${fromCentsFormat(price.amount)}",
							"priceCurrency": "${price.currency || ''}"
						  }
						  `
            )}
					]
				  }
				  `;
        })}
			]
			`
        : `
			"offers":
			[
			  ${productItem.variants?.map(variant =>
          variant?.prices?.map(
            price => `
				  {
					"@type": "Offer",
					"sku": "${variant.sku || ''}",
					"price": "${fromCentsFormat(price.amount)}",
					"priceCurrency": "${price.currency || ''}"
				  }
				  `
          )
        )}
			]`
    }
	  }
	`;
};
