import { LOCATION_CHANGE } from 'connected-react-router';
import { getRoute, getRouteAnalytics } from 'utils/routes';
import { handleEvent } from 'utils/Analytics/AnalyticsManager';
import { EVENTS_MAP } from '../utils/Analytics/constants';

// ------------------------------------
// Middleware
// ------------------------------------

const isPromiseObject = (payload: any) => {
  return payload && Object.prototype.toString.call(payload) === '[object Promise]';
};

export const analyticsMiddleware = (store: any) => (next: any) => async (action: any) => {
  const { type, payload } = action;
  let isAnalyticsEnabledRoute = false;
  let newType = type;

  const nextAction = await next(action);

  const isLocationChangeEvent: boolean = type === LOCATION_CHANGE;
  // if this is a location change, then look up the route in constants to see if this
  // route has analytics turned on
  if (isLocationChangeEvent && Boolean(payload?.location?.pathname)) {
    const route = getRoute(payload.location.pathname) as string;
    isAnalyticsEnabledRoute = getRouteAnalytics(route) && !payload.location.hash;

    // if no analytics for this route
    if (!isAnalyticsEnabledRoute) {
      // SSR view - some pages have redux events firing SS
      // we should find the relevant event and spoof that in place of the pageview
      newType = Object.keys(EVENTS_MAP).find(event => EVENTS_MAP[event].route === route);

      if (!newType || payload?.isFirstRendering === false) {
        // if nothing is listening for this, or is a normal client side navigation, then just skip
        return nextAction;
      }
    }
  }

  if (typeof payload !== 'function' && !isPromiseObject(payload)) {
    const state = store.getState();
    handleEvent(payload, newType, state);
  }

  return nextAction;
};
