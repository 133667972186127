import { MembershipPackageAccordionStatus, Routes, getApplicationProgressRoute } from 'constants/index';
import { User } from 'mxp-schemas';
import { generatePath } from 'react-router';
import { getPaperBillingPath, getPath, previousAddOnsState } from 'utils';

// Progress bar's header
enum ProgressLabel {
  MembershipPackageHeader = 'Customise your membership',
  ApplicationFormHeader = 'Profile Setup',
  AddressHeader = 'Billing & Shipping Address',
  PaymentHeader = 'Payment',
}

export const previousSteps = (
  id: number,
  isMembershipForRenewal?: boolean,
  isCredentialsJourney?: boolean,
  isSectionsJourney?: boolean,
  membershipApplicationType?: any,
  isPaperBilling?: any
) => {
  let selectedRoute;

  if (isMembershipForRenewal) {
    // Ids are used to determine and track which step the user is currently on during a process/journey.
    // Use the variable selectedRoute to store the previous route or path when the user wants to navigate back.
    if (id === 3 && isPaperBilling) {
      selectedRoute = getPaperBillingPath(
        getApplicationProgressRoute(User.MembershipApplicationStages.ATTESTATION, membershipApplicationType),
        isPaperBilling
      );
    } else if (id === 2 && isPaperBilling) {
      selectedRoute = generatePath(
        getPath(
          Routes[getApplicationProgressRoute(User.MembershipApplicationStages.DONATION, membershipApplicationType)]
        )
      );
    } else if (id === 1 && isPaperBilling) {
      selectedRoute = previousAddOnsState(
        getApplicationProgressRoute(User.MembershipApplicationStages.PACKAGE_BUILDER, membershipApplicationType),
        MembershipPackageAccordionStatus.Addons,
        isPaperBilling
      );
    }
  } else {
    if (isCredentialsJourney) {
      selectedRoute = Routes.CREDENTIALS_APPLICATION_FORM;
    } else if (isSectionsJourney) {
      selectedRoute = Routes.APPLICATION_FORM_ATTESTATION;
    } else {
      selectedRoute = Routes.APPLICATION_FORM_PERSONAL;
    }
  }

  const routesListing: any = [Routes.MEMBERSHIP_FORM, selectedRoute, Routes.PAGE_PAYMENT, Routes.CHECKOUT_PAGE];
  return isMembershipForRenewal
    ? selectedRoute
    : previousAddOnsState(routesListing[id], MembershipPackageAccordionStatus.Addons, isPaperBilling);
};

export const membershipPreviousStep = (id: number) => {
  const routesListing = [
    Routes.APPLICATION_FORM_PERSONAL,
    Routes.APPLICATION_FORM_QUALIFICATION,
    Routes.APPLICATION_FORM_EMPLOYMENT,
    Routes.APPLICATION_FORM_DONATIONS,
    Routes.APPLICATION_FORM_ATTESTATION,
  ];

  if (id in routesListing) {
    return getPath(routesListing[id - 1]);
  }
};

// prettier-ignore
// This changes the default progress bar label in PackageMembershipApplicationSteps during renewals journey.
export const nameReplacementSteps = (
  name: string,
  isMembershipForRenewal?: boolean,
): string => {
  const replacementMap: Record<string, string> = {
    'Customize': 'Review',
    'Profile Setup': 'Donations',
    'Address': 'Attestation',
    'Payment': 'Payment',
  };

  if (isMembershipForRenewal) {
    return replacementMap[name] || name;
  }

  return name;
};

// This maintains the rendering of progress bar after user choose Paypal and redirected back into payment/checkout page.
export const pathCheckout = (pathName: any) => {
  switch (pathName) {
    case getPath(Routes.MEMBERSHIP_FORM):
      return pathName;
    case getPath(Routes.APPLICATION_FORM):
      return getPath(Routes.APPLICATION_FORM_DONATIONS);
    case getPath(Routes.PAGE_PAYMENT):
      return getPath(Routes.APPLICATION_FORM_ATTESTATION);
    case getPath(Routes.CHECKOUT_PAGE):
      return '/paypal/checkout/proceed';
  }
};

// Returns length of PackageMembershipApplicationSteps during renewal.
export const paths = (path: any, isMembershipForRenewal?: boolean) => {
  if (isMembershipForRenewal) {
    return path.length;
  } else {
    return path;
  }
};

// Returns corrected path until the last step in progress bar during both renewal & sign-up journey.
// The parameter 'pathName' in PackageMembershipApplicationSteps must be equal to the current path name.
export const pathReplacementName = (pathName: any, isMembershipForRenewal?: boolean) => {
  if (isMembershipForRenewal) {
    switch (pathName) {
      case getPath(Routes.MEMBERSHIP_FORM):
        return pathName;
      case getPath(Routes.APPLICATION_FORM):
        return getPath(Routes.APPLICATION_FORM_DONATIONS);
      case getPath(Routes.PAGE_PAYMENT):
        return getPath(Routes.APPLICATION_FORM_ATTESTATION);
      case getPath(Routes.CHECKOUT_PAGE):
        return '/checkout';
    }
  } else {
    switch (pathName) {
      case getPath(Routes.MEMBERSHIP_FORM):
      case getPath(Routes.CHECKOUT_PAGE):
        return pathName;
      case getPath(Routes.APPLICATION_FORM):
        return getPath(Routes.CREDENTIALS_APPLICATION_FORM);
      case getPath(Routes.PAGE_PAYMENT):
        return getPath(Routes.PAYPAL_PAYMENT_CHECKOUT_PROCEED).toString().replace(':proceed?', '');
    }
  }
};

// This returns the content of progress bar (Incl. Header / Sub-Header / Number of steps)
const routeData: Record<string, { steps: string; header: string; pageCount: string }> = {
  [getPath(Routes.PRE_SIGN_UP)]: {
    steps: 'Review',
    header: ProgressLabel.MembershipPackageHeader,
    pageCount: '1',
  },
  [getPath(Routes.MEMBERSHIP_FORM)]: {
    steps: 'Review your membership',
    header: ProgressLabel.MembershipPackageHeader,
    pageCount: '1',
  },
  [getPath(Routes.APPLICATION_FORM_START)]: {
    steps: 'Donations',
    header: ProgressLabel.ApplicationFormHeader,
    pageCount: '2',
  },
  [getPath(Routes.APPLICATION_FORM_PERSONAL)]: {
    steps: 'Donations',
    header: ProgressLabel.ApplicationFormHeader,
    pageCount: '2',
  },
  [getPath(Routes.APPLICATION_FORM_EMPLOYMENT)]: {
    steps: 'Donations',
    header: ProgressLabel.ApplicationFormHeader,
    pageCount: '2',
  },
  [getPath(Routes.APPLICATION_FORM_QUALIFICATION)]: {
    steps: 'Donations',
    header: ProgressLabel.ApplicationFormHeader,
    pageCount: '2',
  },
  [getPath(Routes.APPLICATION_FORM_DONATIONS)]: {
    steps: 'Donations',
    header: ProgressLabel.ApplicationFormHeader,
    pageCount: '2',
  },
  [getPath(Routes.APPLICATION_FORM_ATTESTATION)]: {
    steps: 'Attestation',
    header: ProgressLabel.ApplicationFormHeader,
    pageCount: '3',
  },
  [getPath(Routes.CREDENTIALS_APPLICATION_FORM)]: {
    steps: 'Review your membership',
    header: ProgressLabel.MembershipPackageHeader,
    pageCount: '1',
  },
  [getPath(Routes.PAGE_PAYMENT)]: {
    steps: 'Payment',
    header: ProgressLabel.AddressHeader,
    pageCount: '4',
  },
  [getPath(Routes.PAYPAL_PAYMENT_CHECKOUT_PROCEED).toString().replace(':proceed?', 'proceed')]: {
    steps: 'Payment',
    header: ProgressLabel.PaymentHeader,
    pageCount: '4',
  },
};

// Used in sign-up page.
// Determines the count steps in the progress bar.
// This returns the content found in routeData above when pageCount reaches to 2, otherwise will return number 3 & 4.
export const currentSignUpPageCount = (
  path: string,
  isPageAddress?: boolean,
  isPagePayment?: boolean,
  isRenewalsJourney?: boolean
) => {
  const config = routeData[path];
  return ['1', '2'].includes(config?.pageCount)
    ? config?.pageCount
    : isPageAddress
    ? '3'
    : isPagePayment
    ? '4'
    : !isRenewalsJourney
    ? '2'
    : '';
};

// Used in renewal journey page.
// Returns the count steps in the progress bar.
export const currentRenewalPageCount = (path: string) => {
  const config = routeData[path];
  return config ? config.pageCount : '';
};

// Returns the content header from routeData above.
export const currentPageStep = (
  step: string,
  isMembershipForRenewal?: boolean,
  isPageAddress?: boolean,
  isPagePayment?: boolean
) => {
  const routeInfo = routeData[step];
  if (!routeInfo) return '';
  return isMembershipForRenewal
    ? routeInfo.steps
    : isPageAddress
    ? ProgressLabel.AddressHeader
    : isPagePayment
    ? ProgressLabel.PaymentHeader
    : routeInfo.header;
};
