import * as Sentry from '@sentry/browser';
import { Cart } from 'mxp-schemas';
import { Action, handleActions } from 'redux-actions';
import { initialState } from 'modules/layouts/constants';
import {
  closeAuthenticationModal,
  goToPreviousRoute,
  selectTopicId,
  selectIndustryId,
  selectTrendId,
  selectSkillId,
  selectJobRoleId,
  setAcrossLinksExpanded,
  setBurgerExpand,
  showLogin,
  showRegistration,
  setCurrentContentId,
  resetCurrentContentId,
  setUserSettingsExpanded,
  pushMegaMobileNavHistory,
  backMegaMobileNavHistory,
  clearMegaMobileNavHistory,
  toggleModalTopicUnfollow,
  toggleModalPaymentProcessingOpen,
  toggleModalExtendAccessOpen,
  toggleModalInvalidAddressOpen,
  toggleModalProvisionProcessingOpen,
  toggleModalAddressValidationOpen,
  setSearchModalExpand,
  toggleModalOnboardingFinishedOpen,
  toggleModalOnboardingStartVisible,
  toggleModalOnboardingCancelOpen,
  setCancelSOrderModalMeta,
  toggleLoginReload,
  paginationPageChange,
  paginationSortChange,
  setPaginationInfo,
  setPaginationTotal,
  paginationFilterActiveChange,
  toggleCardSetDefaultModal,
  toggleCardRemoveModal,
  setCardBeingProcessed,
  setCardOperationBannerVisible,
  setCardOperation,
  closeCardOperationBanner,
  setCardProcessed,
  setOrgProfileExpanded,
  toggleSubscriptionRenewal,
  toggleSubscriptionRenewalToggleModal,
  setSubscriptionRenewalToggleSuccess,
  setAdminStickyFooterInvoiceFilterVisible,
  setRecentlyAddedBundleDiscountPercentage,
  setCartBundleMergeBannerMessage,
  clearCartSingleTimeBanners,
  setIsCartContainsInvalidBundles,
  toggleModalResetImpersonatedUserPassword,
  setCartMigrationMessages,
  setSubscriptionRenewalToggleLoading,
  toggleCimaDeleteDefaultPaymentModal,
  toggleCimaDeleteOnlyPaymentMethodModal,
  toggleAddCreditCardModal,
  CimaDeleteOnlyPaymentMethodModalButtonLoading,
  setIsDeleteCardBeingProcessed,
} from 'modules/layouts/actions';

if (
  [
    closeAuthenticationModal,
    goToPreviousRoute,
    selectTopicId,
    selectIndustryId,
    selectTrendId,
    selectSkillId,
    selectJobRoleId,
    setAcrossLinksExpanded,
    setBurgerExpand,
    showLogin,
    showRegistration,
    setCurrentContentId,
    resetCurrentContentId,
    setUserSettingsExpanded,
    pushMegaMobileNavHistory,
    backMegaMobileNavHistory,
    clearMegaMobileNavHistory,
    toggleModalTopicUnfollow,
    toggleModalPaymentProcessingOpen,
    toggleModalExtendAccessOpen,
    toggleModalInvalidAddressOpen,
    toggleModalProvisionProcessingOpen,
    toggleModalAddressValidationOpen,
    setSearchModalExpand,
    toggleModalOnboardingFinishedOpen,
    toggleModalOnboardingStartVisible,
    toggleModalOnboardingCancelOpen,
    toggleLoginReload,
    paginationPageChange,
    paginationSortChange,
    setPaginationInfo,
    setOrgProfileExpanded,
    paginationFilterActiveChange,
    setPaginationTotal,
    setRecentlyAddedBundleDiscountPercentage,
    setCartBundleMergeBannerMessage,
    setIsCartContainsInvalidBundles,
    toggleModalResetImpersonatedUserPassword,
    setCartMigrationMessages,
  ].includes(undefined)
) {
  const message: string = 'layouts module initialization error';
  console.error(message);
  Sentry.captureException(message);
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [setBurgerExpand]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    burgerExpanded: Boolean(action.payload),
  }),
  [setSearchModalExpand]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    searchModalExpanded: Boolean(action.payload),
  }),

  [setUserSettingsExpanded]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    userSettingsExpanded: Boolean(action.payload),
  }),

  [setAcrossLinksExpanded]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    acrossLinksExpand: Boolean(action.payload),
  }),

  [setAdminStickyFooterInvoiceFilterVisible]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    adminStickyFooterInvoiceFilterVisible: Boolean(action.payload),
  }),

  [selectTopicId]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    selectedTopicId: action.payload,
  }),

  [selectIndustryId]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    selectedIndustryId: action.payload,
  }),

  [selectTrendId]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    selectedTrendId: action.payload,
  }),

  [selectSkillId]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    selectedSkillId: action.payload,
  }),

  [selectJobRoleId]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    selectedJobRoleId: action.payload,
  }),

  [setCurrentContentId]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    previousContentId: state.currentContentId,
    currentContentId: action.payload,
  }),

  [resetCurrentContentId]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    previousContentId: null,
    currentContentId: null,
  }),

  [pushMegaMobileNavHistory]: (state: State.Layouts, action: any): State.Layouts => {
    const copyHistory = [...state.megaMobileNavHistory];
    copyHistory.push(action.payload as string);
    return {
      ...state,
      megaMobileNavHistory: copyHistory,
    };
  },

  [backMegaMobileNavHistory]: (state: State.Layouts): State.Layouts => {
    const copyHistory = [...state.megaMobileNavHistory];
    if (copyHistory.length > 1) {
      copyHistory.pop();
    }
    return {
      ...state,
      megaMobileNavHistory: copyHistory,
    };
  },

  [clearMegaMobileNavHistory]: (state: State.Layouts): State.Layouts => ({
    ...state,
    megaMobileNavHistory: initialState.megaMobileNavHistory,
  }),

  [toggleModalTopicUnfollow]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    modalTopicUnfollowMeta: action.payload || initialState.modalTopicUnfollowMeta,
  }),

  [toggleModalPaymentProcessingOpen]: (state: State.Layouts): State.Layouts => ({
    ...state,
    isPaymentProcessingModalOpen: !state.isPaymentProcessingModalOpen,
  }),
  // If you want to extend bypass address functionality, 'allowUserToBypassAddress' will need to be set to true when dispatching the action
  [toggleModalAddressValidationOpen]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    isAddressValidationModalOpen: action.payload.isAddressValidationModalOpen,
    allowUserToBypassAddressValidation: {
      allowShippingAddressValidationBypass:
        action.payload?.allowUserToBypassAddressValidation?.allowShippingAddressValidationBypass || false,
      allowBillingAddressValidationBypass:
        action.payload?.allowUserToBypassAddressValidation?.allowBillingAddressValidationBypass || false,
    },
  }),
  [toggleModalInvalidAddressOpen]: (state: State.Layouts): State.Layouts => ({
    ...state,
    isInvalidAddressModalOpen: !state.isInvalidAddressModalOpen,
  }),
  [toggleModalExtendAccessOpen]: (state: State.Layouts): State.Layouts => ({
    ...state,
    isExtendAccessModalOpen: !state.isExtendAccessModalOpen,
  }),
  [toggleModalProvisionProcessingOpen]: (state: State.Layouts): State.Layouts => ({
    ...state,
    isProvisionProcessingModalOpen: !state.isProvisionProcessingModalOpen,
  }),
  [toggleModalOnboardingFinishedOpen]: (state: State.Layouts): State.Layouts => ({
    ...state,
    isOnboardingFinishedModalOpen: !state.isOnboardingFinishedModalOpen,
  }),
  [toggleModalOnboardingStartVisible]: (state: State.Layouts): State.Layouts => ({
    ...state,
    isOnboardingStartModalOpen: !state.isOnboardingStartModalOpen,
  }),
  [toggleModalOnboardingCancelOpen]: (state: State.Layouts): State.Layouts => ({
    ...state,
    isOnboardingCancelModalOpen: !state.isOnboardingCancelModalOpen,
  }),
  [toggleLoginReload]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    reloadAfterLogin: action.payload || initialState.reloadAfterLogin,
  }),
  [setPaginationInfo]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    paginationInfo: action.payload,
  }),
  [setSubscriptionRenewalToggleSuccess]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    subscriptionRenewalToggleSuccess: action.payload,
  }),
  [setSubscriptionRenewalToggleLoading]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    subscriptionRenewalToggleLoading: action.payload,
  }),
  [CimaDeleteOnlyPaymentMethodModalButtonLoading]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    cimaDeleteOnlyPaymentMethodModalButtonLoading: action.payload,
  }),
  [setPaginationTotal]: (state: State.Layouts, action: any): State.Layouts => {
    const newPage = 1;
    const perPage = state.paginationInfo.pagination.perPage;
    const total = action.payload;

    const paginationInfo: State.PaginationInfo = {
      pagination: {
        total,
        from: (newPage - 1) * perPage + 1,
        to: total < newPage * perPage ? total : newPage * perPage,
        perPage,
        pageNumber: newPage / perPage,
      },
      filtering: state.paginationInfo.filtering,
      currentPage: newPage,
    };

    return {
      ...state,
      paginationInfo,
    };
  },

  [paginationPageChange]: (state: State.Layouts, action: any): State.Layouts => {
    const newPage = action.payload;
    const perPage = state.paginationInfo.pagination.perPage;
    const total = state.paginationInfo.pagination.total;

    const paginationInfo: State.PaginationInfo = {
      pagination: {
        total,
        from: (newPage - 1) * perPage + 1,
        to: total < newPage * perPage ? total : newPage * perPage,
        perPage,
        pageNumber: newPage / perPage,
      },
      filtering: state.paginationInfo.filtering,
      currentPage: newPage,
    };

    return {
      ...state,
      paginationInfo,
    };
  },

  [paginationSortChange]: (state: State.Layouts, action: any): State.Layouts => {
    return {
      ...state,
      paginationInfo: {
        ...state.paginationInfo,
        filtering: { ...state.paginationInfo.filtering, history: action.payload },
      },
    };
  },
  [paginationFilterActiveChange]: (state: State.Layouts, action: any): State.Layouts => {
    return {
      ...state,
      paginationInfo: {
        ...state.paginationInfo,
        filtering: { ...state.paginationInfo.filtering, active: action.payload },
      },
    };
  },

  [toggleCardSetDefaultModal]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    isCardSetDefaultModalOpen: !state.isCardSetDefaultModalOpen,
  }),

  [toggleCardRemoveModal]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    isCardRemoveModalOpen: !state.isCardRemoveModalOpen,
  }),

  [toggleCimaDeleteDefaultPaymentModal]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    isCimaDeleteDefaultPaymentModalOpen: !state.isCimaDeleteDefaultPaymentModalOpen,
  }),

  [setIsDeleteCardBeingProcessed]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    isDeleteCardBeingProcessed: action.payload,
  }),

  [toggleCimaDeleteOnlyPaymentMethodModal]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    isCimaDeleteOnlyPaymentMethodModalOpen: !state.isCimaDeleteOnlyPaymentMethodModalOpen,
  }),

  [toggleAddCreditCardModal]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    isAddCreditCardModalOpen: !state.isAddCreditCardModalOpen,
  }),

  [setCardBeingProcessed]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    cardBeingProcessed: action.payload,
  }),

  [setCardProcessed]: (state: State.Layouts): State.Layouts => ({
    ...state,
    cardProcessed: state.cardBeingProcessed,
  }),

  [setCardOperationBannerVisible]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    isCardOperationBannerVisible: action.payload,
  }),

  [closeCardOperationBanner]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    isCardOperationBannerVisible: false,
    creditCardOperation: { name: 'none', success: false },
  }),

  [setCardOperation]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    creditCardOperation: action.payload,
  }),

  [setOrgProfileExpanded]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    orgProfileExpanded: Boolean(action.payload),
  }),

  [toggleSubscriptionRenewal]: (state: State.Layouts, action: any): State.Layouts => {
    return {
      ...state,
      subscriptionRenewalToggle: {
        ...action.payload,
      },
    };
  },

  [setCancelSOrderModalMeta]: (state: State.Layouts, action: Action<State.CancelSOrderModalMeta>): State.Layouts => ({
    ...state,
    cancelSOrderModalMeta: action.payload,
  }),

  [toggleSubscriptionRenewalToggleModal]: (state: State.Layouts): State.Layouts => ({
    ...state,
    subscriptionRenewalToggle: {
      enable: !state.subscriptionRenewalToggle?.enable,
      item: state.subscriptionRenewalToggle?.item,
      hasDefaultCard: null,
    },
  }),

  [setRecentlyAddedBundleDiscountPercentage]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    recentlyAddedBundleDiscountPercentage: action.payload,
  }),

  [setCartBundleMergeBannerMessage]: (state: State.Layouts, action: any): State.Layouts => ({
    ...state,
    cartBundleMergeBannerMessage: action.payload,
  }),

  [setCartMigrationMessages]: (state: State.Layouts, action: Action<Cart.MigrationMessages>): State.Layouts => ({
    ...state,
    cartMigrationMessages: action.payload,
  }),

  [setIsCartContainsInvalidBundles]: (state: State.Layouts, action: Action<boolean>): State.Layouts => ({
    ...state,
    isCartContainsInvalidBundles: action.payload,
  }),

  [toggleModalResetImpersonatedUserPassword]: (state: State.Layouts): State.Layouts => ({
    ...state,
    isImpersonatedUserPasswordModalOpen: !state.isImpersonatedUserPasswordModalOpen,
  }),
};

export {
  backMegaMobileNavHistory,
  clearMegaMobileNavHistory,
  closeAuthenticationModal,
  goToPreviousRoute,
  pushMegaMobileNavHistory,
  resetCurrentContentId,
  selectTopicId,
  selectIndustryId,
  selectTrendId,
  selectSkillId,
  selectJobRoleId,
  setAcrossLinksExpanded,
  setBurgerExpand,
  setCurrentContentId,
  setSearchModalExpand,
  setUserSettingsExpanded,
  showLogin,
  showRegistration,
  toggleLoginReload,
  toggleModalOnboardingCancelOpen,
  toggleModalOnboardingFinishedOpen,
  toggleModalOnboardingStartVisible,
  toggleModalPaymentProcessingOpen,
  toggleModalExtendAccessOpen,
  toggleModalInvalidAddressOpen,
  toggleModalProvisionProcessingOpen,
  toggleModalAddressValidationOpen,
  toggleModalTopicUnfollow,
  paginationPageChange,
  paginationSortChange,
  setPaginationInfo,
  toggleCardSetDefaultModal,
  toggleCardRemoveModal,
  setCardBeingProcessed,
  setCardOperationBannerVisible,
  closeCardOperationBanner,
  setCardOperation,
  setCardProcessed,
  setOrgProfileExpanded,
  toggleSubscriptionRenewal,
  paginationFilterActiveChange,
  setPaginationTotal,
  toggleSubscriptionRenewalToggleModal,
  setCancelSOrderModalMeta,
  setRecentlyAddedBundleDiscountPercentage,
  setCartBundleMergeBannerMessage,
  clearCartSingleTimeBanners,
  setIsCartContainsInvalidBundles,
  setCartMigrationMessages,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS as any, initialState);
