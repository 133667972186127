import React from 'react';
import styled from 'styled-components';
import { Modal } from 'components/atoms/Modal/Modal';
import { ButtonEnums, Button } from 'components/atoms/Button/Button';
import { SurveyCheck } from 'components/atoms/svg';

interface Props {
  open: boolean;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm: () => void;
  className?: string;
  isConfirmationInProgress?: boolean;

  testId?: string;
  header?: string;
  description?: string;
}

export const PartnerOrganizationReview: React.FC<Props> = ({
  open,
  testId,
  header,
  description,
  onConfirm,
  confirmButtonLabel = 'I Understand',
  isConfirmationInProgress,
}) => {
  const onConfirmEvent = React.useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <StyledModal
      className="partners-modal"
      open={open}
      size="small"
      heading={header}
      icon={<SurveyCheck size={29} />}
      testId={testId}
      confirmNode={
        <Button
          className="confirm"
          size={ButtonEnums.sizes.small}
          variant={ButtonEnums.variants.primary}
          testId="confirm"
          onClick={onConfirmEvent}
          disabled={isConfirmationInProgress}
          loading={isConfirmationInProgress}
        >
          {confirmButtonLabel}
        </Button>
      }
    >
      <StyledParagraph>
        {description || `Your application has been saved and will be reviewed`}
        <br />
        <StyledSpan>You will get notified when the process is over</StyledSpan>
      </StyledParagraph>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: relative;
  box-sizing: border-box;

  &&&&&&& {
    border-radius: ${props => props.theme.pxToRem(2)};
    width: ${props => props.theme.pxToRem(590)};
    box-sizing: border-box;
    padding: ${props => props.theme.pxToRem(36)} ${props => props.theme.pxToRem(80)} ${props => props.theme.pxToRem(19)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props => props.theme.pxToRem(36)} ${props => props.theme.pxToRem(20)}
        ${props => props.theme.pxToRem(19)};
      width: 90%;
    }

    .header {
      line-height: 1.33;
      margin-top: ${props => props.theme.pxToRem(12)};
      h2 {
        margin-top: ${props => props.theme.pxToRem(17)};
      }
    }

    .content {
      margin: ${props => props.theme.pxToRem(5)} auto auto;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }

    p {
      max-width: 100%;
    }
    .actions {
      padding: ${props => `${props.theme.pxToRem(20)}`} 0 0 !important;
      ${props => props.theme.mediaQueries.mobileOnly} {
        flex-direction: column-reverse;
        justify-content: center;
      }
    }

    button {
      width: auto;
      height: ${props => props.theme.pxToRem(34)};
      padding: ${props => `${props.theme.pxToRem(4)} ${props.theme.pxToRem(32)}`};

      margin: auto ${props => props.theme.pxToRem(6)};
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: auto;
        height: ${props => `${props.theme.pxToRem(40)}`};
        font-size: ${props => props.theme.fontSizes.s};
        border-radius: ${props => `${props.theme.pxToRem(4)}`};
        margin: 0 auto !important;
        padding: ${props => `${props.theme.pxToRem(10)}`};
      }
    }

    button:first-child {
      ${props => props.theme.mediaQueries.mobileOnly} {
        margin-top: ${props => `${props.theme.pxToRem(16)}`} !important;
      }
    }
  }
`;

const StyledParagraph = styled.p`
  line-height: 1.27;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.l};
  margin-top: ${props => props.theme.pxToRem(0)};
`;

const StyledSpan = styled.span`
  line-height: 1.27;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.s};
`;
