import styled from 'styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { HelpSection } from 'components/organisms/HelpSection/HelpSection';
import { Footer } from 'components/molecules/Footer/Footer';
import { Container, Grid, Divider, NotificationBanner, NotificationBannerEnums } from 'components/atoms';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { OrganizationHeader } from './OrganizationHeader';
import { OrganizationSeatsContainer } from 'containers/OrganizationSeatsContainer';
import { OrganizationInvoicesContainer } from 'containers/OrganizationInvoicesContainer';
import { OrganizationFirmInformationContainer } from 'containers/OrganizationFirmInformationContainer';
import {
  deallocateSeatsErrorSelector,
  seatManagementAssignLoading,
  seatManagementAssignmentResultsExistSelector,
} from 'modules/clientAdmin/selectors';
import { StyledIconError } from '../PageForgotEmail/PageForgotEmail';
import { EmailInviteFormContainer } from 'containers/EmailInviteFormContainer';
import { ConfirmSeatDeallocateContainer } from 'containers/ConfirmSeatDeallocateContainer';
import { PageOrganizationNavigationContainer } from 'containers/PageOrganizationNavigationContainer';

export const PageOrganizationNew: React.FC = () => {
  const [showSeatAssignSuccess, setShowSeatAssignSuccess] = useState(false);

  const assignResult: boolean = useSelector(seatManagementAssignmentResultsExistSelector);
  const assignLoading: boolean = useSelector(seatManagementAssignLoading);
  const deallocateSeatsError: boolean = Boolean(useSelector(deallocateSeatsErrorSelector));

  const targetContainer = React.useRef<HTMLDivElement | null>(null);
  const containerContentRef = React.useRef<HTMLDivElement | null>(null);

  const onBannerClose = React.useCallback(() => setShowSeatAssignSuccess(false), []);

  React.useEffect(() => {
    if (assignResult) setShowSeatAssignSuccess(true);
    if (assignLoading) setShowSeatAssignSuccess(false);
  }, [assignResult, assignLoading]);

  return (
    <>
      <HeaderPageWrapper contentRef={targetContainer} contentInnerRef={containerContentRef}>
        <OrganizationHeader />
        <StyledContainer>
          <Grid stackable>
            <Grid.Row stretched>
              <Grid.Column tablet={16} computer={16}>
                <StyledContentContainer>
                  {showSeatAssignSuccess && (
                    <NotificationBannerStyled
                      testId="nb-success"
                      childrenTestId="nb-text"
                      closeActionTestId="nb-close"
                      closeAction={onBannerClose}
                      variant={NotificationBannerEnums.variant.green}
                      icon={<IconErrorBanner />}
                    >
                      Success. The status will update as users access the link in the invite email.
                    </NotificationBannerStyled>
                  )}
                  {deallocateSeatsError && (
                    <NotificationBannerStyled
                      testId="nb-deallocate-error"
                      childrenTestId="nb-deallocate-error"
                      variant={NotificationBannerEnums.variant.red}
                      icon={<IconErrorBanner />}
                    >
                      Error occurred while deallocating the seat(s).
                    </NotificationBannerStyled>
                  )}
                  <Switch>
                    <Route exact path={getPath(Routes.ORG_ROOT)} component={PageOrganizationNavigationContainer} />
                    <Route
                      path={getPath(Routes.ORG_FIRM_INFORMATION)}
                      component={OrganizationFirmInformationContainer}
                    />
                    <Route path={getPath(Routes.ORG_SEAT_MANAGEMENT)} component={OrganizationSeatsContainer} />
                    <Route path={getPath(Routes.ORG_INVOICES)} component={OrganizationInvoicesContainer} />
                  </Switch>
                  <StyledDivider />
                  <HelpSection />
                </StyledContentContainer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </StyledContainer>
        <Footer />
      </HeaderPageWrapper>
      <EmailInviteFormContainer />
      <ConfirmSeatDeallocateContainer />
    </>
  );
};

const StyledContainer = styled(Container)`
  &.ui.container {
    flex: 1 0 auto;
    display: flex;
    > .grid {
      flex: 1 1 auto;
    }
  }
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${props => props.theme.pxToRem(16)};
  margin-top: ${props => props.theme.pxToRem(32)};
  margin-bottom: ${props => props.theme.pxToRem(80)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-top: ${props => props.theme.pxToRem(24)};
    margin-bottom: ${props => props.theme.pxToRem(40)};
  }
`;

const StyledDivider = styled(Divider)`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(30)};
    margin-bottom: ${props => props.theme.pxToRem(30)};
    border-top-color: transparent;
    border-bottom-color: ${props => props.theme.colors.neutralGrey3};
    color: ${props => props.theme.colors.neutralGrey3};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(25)};
    }
  }
`;

const NotificationBannerStyled = styled(NotificationBanner)`
  &&&&& {
    width: 100%;
    min-height: ${props => props.theme.pxToRem(68)};
    margin-bottom: 0;
  }
`;

export const IconErrorBanner = styled(StyledIconError)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(8)};
  }
`;
