import React from 'react';
import styled from 'styled-components';
import { productTypeToLabel } from 'utils/index';

interface Props {
  category: string[];
}

export const ProductCategory: React.FC<Props> = ({ category = [] }) => {
  const categories = category.map(productTypeToLabel as any).join(', ');
  return <StyledProductCategory>{categories.toUpperCase()}</StyledProductCategory>;
};

const StyledProductCategory = styled.div`
  color: ${props => props.theme.colors.neutralGrey7};
  font-size: ${props => props.theme.fontSizes.xxs};
  line-height: 1.33;
  letter-spacing: ${props => props.theme.pxToRem(1.2)};
`;
