import { createSelector } from 'reselect';
import { ADMIN_AUTH_STATUS, AdminTableType } from '../../constants';
import { SmartyStreets, Invoices, B2B } from 'mxp-schemas';
import { matchPath } from 'react-router-dom';
import { getLocation } from 'connected-react-router';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';
import { InvoicesPaymentResponse } from 'mxp-schemas/dist/types/invoices';

const rootSelector = createSelector(
  (state: State.Root) => state.admin,
  (admin: State.Admin): State.Admin => admin
);

export const paginationSelectorFactory = (type: AdminTableType) =>
  createSelector(rootSelector, (admin: State.Admin): State.Pagination => admin.pagination[type]);

export const adminFeatureTogglesSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.FeatureToggleByKey => admin.featureToggles
);

export const getAdminFeatureToggleByKeySelector = createSelector(
  adminFeatureTogglesSelector,
  (_: any, key: string): string => key,
  (featureToggles: State.FeatureToggleByKey, key: string): boolean => featureToggles[key] || false
);

export const isAdminLogged = createSelector(
  rootSelector,
  (admin: State.Admin): ADMIN_AUTH_STATUS => admin.adminAuthStatus
);

export const getImpersonatedUserSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.ImpersonatedUser => admin.impersonatedUser
);

export const adminClientRoleSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.B2BRoles | null => admin.adminClientRole
);

export const getProductCancellationStatusSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.ProductCancellationStatus => admin.productCancellationStatus
);

export const getImpersonatedUserStatusSelector = createSelector(
  getImpersonatedUserSelector,
  (impersonatedUser: State.ImpersonatedUser): boolean => impersonatedUser.loading
);

export const isB2BSelector = createSelector(
  adminClientRoleSelector,
  (role: State.B2BRoles | null): boolean => role === B2B.AgentRole.FINANCE || role === B2B.AgentRole.B2B_OPS
);

export const isOperationsSelector = createSelector(
  adminClientRoleSelector,
  (role: State.B2BRoles | null): boolean => role === B2B.AgentRole.RAVE_OPS
);

export const isFirmBillingSelector = createSelector(
  adminClientRoleSelector,
  (role: State.B2BRoles | null): boolean => role === B2B.AgentRole.FIRM_BILLING
);

export const isCenterMembershipSelector = createSelector(
  adminClientRoleSelector,
  (role: State.B2BRoles | null): boolean => role === B2B.AgentRole.CENTERS_MEMBERSHIP
);

export const isB2BAgentSelector = createSelector(adminClientRoleSelector, (role: State.B2BRoles | null): boolean =>
  [
    B2B.AgentRole.APPC,
    B2B.AgentRole.FINANCE,
    B2B.AgentRole.FIRM_BILLING,
    B2B.AgentRole.CENTERS_MEMBERSHIP,
    B2B.AgentRole.B2B_OPS,
    B2B.AgentRole.RAVE_OPS,
    B2B.AgentRole.SYSTEM_LAMBDA_STANDING_ORDER,
    B2B.AgentRole.GEC,
    B2B.AgentRole.CLIENT_ADMIN,
  ].includes(role as any)
);

export const organizationAdminsList = createSelector(
  rootSelector,
  (admin: State.Admin): State.OrganizationAdmin[] => admin.organizationAdmins.list
);

export const adminToRemoveSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.OrganizationAdmin | null => admin.organizationAdmins.adminToRemove
);

export const adminToRemoveHasRelatedEntitiesSelector = createSelector(
  adminToRemoveSelector,
  (adminToRemove: State.OrganizationAdminState | null): boolean => Boolean(adminToRemove?.hasRelatedEntities)
);
export const isCheckForRelatedEntitiesLoadingSelector = createSelector(
  adminToRemoveSelector,
  (adminToRemove: State.OrganizationAdminState | null): boolean => Boolean(adminToRemove?.loading)
);

export const swapAdminModalOpenSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean => admin.organizationAdmins.swapAdminModalOpen
);
export const swapAdminModalLoadingSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean => admin.organizationAdmins.swapAdminModalLoading
);
export const swapAdminSuccessSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean => admin.organizationAdmins.swapAdminSuccess
);

export const organizationAdminsStatus = createSelector(
  rootSelector,
  (admin: State.Admin): boolean => admin.organizationAdmins.loading
);

export const organizationOrderList = createSelector(
  rootSelector,
  (admin: State.Admin): State.OrganizationOrder[] => admin.organizationOrders.list
);

export const organizationOrderDetailsList = createSelector(
  rootSelector,
  (admin: State.Admin): State.OrganizationOrderDetail[] => admin.organizationOrdersDetails.list
);

export const organizationOrderDetailsListLoading = createSelector(
  rootSelector,
  (admin: State.Admin): boolean => admin.organizationOrdersDetails.loading
);

export const organizationOrderListLoading = createSelector(
  rootSelector,
  (admin: State.Admin): boolean => admin.organizationOrders.loading
);
export const b2bCartSelector = createSelector(rootSelector, (admin: State.Admin): B2B.CartItem[] => admin.cart.items);
export const selectedCurrency = createSelector(rootSelector, (admin: State.Admin): string => admin.currency);

export const b2bCartLoadingStateSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean => admin.cart.loading
);

export const checkoutLoadingStateSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean => admin.checkout.loading
);

export const adminBillingAddressSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.Address => admin.checkout.billingAddress
);

export const adminShippingAddressSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.Address => admin.checkout.shippingAddress
);

export const isSalesforceAddressFetchedSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean | null => admin.checkout.salesforceAddressFetched
);

export const isShippingAddressValidSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean | null => admin.checkout.isShippingAddressValid
);

export const isSecondaryAddressNeededSelector = createSelector(rootSelector, (admin: State.Admin): boolean =>
  Boolean(admin.checkout.isSecondaryAddressNeeded)
);

export const isSecondaryBillingAddressNeededSelector = createSelector(rootSelector, (admin: State.Admin): boolean =>
  Boolean(admin.checkout.isSecondaryBillingAddressNeeded)
);

export const isDisplayBypassValidationSelector = createSelector(rootSelector, (admin: State.Admin): boolean =>
  Boolean(admin.checkout.displayBypassValidation)
);
export const isDisplayBypassValidationBillingSelector = createSelector(rootSelector, (admin: State.Admin): boolean =>
  Boolean(admin.checkout.displayBypassValidationBilling)
);
export const adminSmartystreetsValidationSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.SmartyAddressValidationState | null => admin.checkout.smartystreetsValidation
);

export const adminSmartyShippingAddressSelector = createSelector(
  adminSmartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): SmartyStreets.ValidationCandidate[] =>
    smartystreetsValidation?.shippingAddress?.suggestions || []
);

export const adminSmartyBillingAddressSelector = createSelector(
  adminSmartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): SmartyStreets.ValidationCandidate[] =>
    smartystreetsValidation?.billingAddress?.suggestions || []
);

export const adminIsShippingAddressValidSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean | null => admin.checkout.isShippingAddressValid
);
export const adminIsBillingAddressValidSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean | null => admin.checkout.isBillingAddressValid
);
export const adminIsShippingEmbargoedSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean | null => admin.checkout.isShippingEmbargoed
);
export const adminIsBillingEmbargoedSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean | null => admin.checkout.isBillingEmbargoed
);
export const adminSmartyValidationReasonSelector = createSelector(
  adminSmartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): SmartyStreets.ValidationReason | null =>
    (smartystreetsValidation?.shippingAddress?.reason as SmartyStreets.ValidationReason) || null
);

export const adminSmartyBillingValidationReasonSelector = createSelector(
  adminSmartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): SmartyStreets.ValidationReason | null =>
    (smartystreetsValidation?.billingAddress?.reason as SmartyStreets.ValidationReason) || null
);

export const adminSmartyValidationFetchedSelector = createSelector(
  adminSmartystreetsValidationSelector,
  (smartystreetsValidation: State.SmartyAddressValidationState | null): boolean =>
    Boolean(smartystreetsValidation?.smartystreetsValidationFetched)
);

export const invoiceDetailsSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.InvoiceDetails => admin.invoiceDetails
);

const searchInvoicesSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.SearchInvoices => admin.invoices
);

export const isMultipleInvoiceSelectedSelector = createSelector(
  rootSelector,
  (admin: State.Admin): string[] => admin.invoicesSelected
);

export const isSearchInvoicesLoadingSelector = createSelector(
  searchInvoicesSelector,
  (invoices: State.SearchInvoices): boolean => invoices.loading
);

export const searchInvoicesListSelector = createSelector(
  searchInvoicesSelector,
  (invoices: State.SearchInvoices): State.SearchInvoicesResult[] | null => invoices.results
);

export const invoiceToShowDetailsSelectorFactory = (invoiceNumber: string) =>
  createSelector(
    searchInvoicesListSelector,
    (results: State.SearchInvoicesResult[] | null): State.SearchInvoicesResult | null =>
      results?.find((invoice: State.SearchInvoicesResult) => invoice.invoiceNumber === invoiceNumber) || null
  );

export const downloadingErrorSelector = createSelector(
  searchInvoicesSelector,
  (invoices: State.SearchInvoices): boolean => invoices.downloadingError
);

export const downloadingIdSelector = createSelector(
  searchInvoicesSelector,
  (invoices: State.SearchInvoices): string | null => invoices.downloadingId
);

export const selectedInvoicesSelector = createSelector(
  searchInvoicesSelector,
  (invoices: State.SearchInvoices): State.SearchInvoicesResult[] => {
    return invoices.results?.reduce((acc: State.SearchInvoicesResult[], invoice: State.SearchInvoicesResult) => {
      if (invoice.isSelected) {
        acc.push(invoice);
      }
      return acc;
    }, []);
  }
);

export const isMultipleInvoicesSelectedSelector = createSelector(
  searchInvoicesSelector,
  (invoices: State.SearchInvoices): boolean => {
    return invoices.results?.some((invoice: State.SearchInvoicesResult) => invoice.isSelected);
  }
);

export const isAllInvoicesSelectedSelector = createSelector(
  searchInvoicesSelector,
  (invoices: State.SearchInvoices): boolean => {
    return !invoices.results?.some((invoice: State.SearchInvoicesResult) => !invoice.isSelected) || false;
  }
);

export const isPayMultipleInvoicesButtonEnabledSelector = createSelector(
  selectedInvoicesSelector,
  isMultipleInvoicesSelectedSelector,
  (selectedInvoices: State.SearchInvoicesResult[], isMultipleInvoicesSelected: boolean): boolean => {
    return selectedInvoices && isMultipleInvoicesSelected
      ? !selectedInvoices?.some(
          (invoice: State.SearchInvoicesResult) =>
            invoice.status === Invoices.InvoiceStatus.PAID || invoice.status === Invoices.InvoiceStatus.CANCELLED
        )
      : false;
  }
);

export const paginatedInvoicesSelector = createSelector(
  [searchInvoicesListSelector, paginationSelectorFactory(AdminTableType.INVOICES)],
  (
    invoicesList: State.SearchInvoicesResult[] | null,
    paginationInfo: State.Pagination
  ): State.SearchInvoicesResult[] | null => {
    return invoicesList?.slice(paginationInfo.offset, paginationInfo.offset + paginationInfo.limit) || null;
  }
);

export const getAccountIdSelector = createSelector(
  selectedInvoicesSelector,
  (selectedInvoices: State.SearchInvoicesResult[]): string | null => {
    const account = selectedInvoices.find(invoice => invoice.accountId);

    return account?.accountId || null;
  }
);

export const isAdminInvoicesPaymentJourneySelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(
    matchPath(location.pathname, { path: getPath(Routes.ADMIN_INVOICES_PAYMENT), exact: true }) ||
      matchPath(location.pathname, { path: getPath(Routes.ADMIN_INVOICES_PAYMENT_CONFIRMATION), exact: true })
  )
);

export const getTotalBalanceSelector = createSelector(
  selectedInvoicesSelector,
  (selectedInvoices: State.SearchInvoicesResult[]): number => {
    const totalBalance: number = selectedInvoices.reduce(
      (accumulator: number, currentValue: State.SearchInvoicesResult) => {
        return accumulator + currentValue.balance;
      },
      0
    );
    return totalBalance;
  }
);

export const invoicesPaymentConfirmationSelector = createSelector(
  searchInvoicesSelector,
  (invoice: State.SearchInvoices): InvoicesPaymentResponse | null => invoice.pay?.result || null
);

export const salesTaxSelector = createSelector(
  rootSelector,
  (admin: State.Admin): { taxAmount: number } | null => admin.checkout.salesTax
);

export const resetImpersonatedUserPasswordSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.ResetImpersonatedUserPassword => admin.resetImpersonatedUserPassword
);

export const resetImpersonatedUserPasswordErrorSelector = createSelector(
  resetImpersonatedUserPasswordSelector,
  (resetImpersonatedUserPassword: State.ResetImpersonatedUserPassword): string => resetImpersonatedUserPassword.error
);

export const isResetImpersonatedUserPasswordLoadingSelector = createSelector(
  resetImpersonatedUserPasswordSelector,
  (resetImpersonatedUserPassword: State.ResetImpersonatedUserPassword): boolean => resetImpersonatedUserPassword.loading
);

const invoiceItemsSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.InvoiceItems => admin.invoiceItems
);

export const invoiceItemsListSelector = createSelector(
  invoiceItemsSelector,
  (invoiceItems: State.InvoiceItems): State.InvoiceItemResult[] => invoiceItems.list || []
);

export const isInvoiceItemsFetchedSelector = createSelector(
  invoiceItemsSelector,
  (invoiceItems: State.InvoiceItems): boolean => invoiceItems.isInvoiceItemsFetched
);

export const isAllInvoiceItemsSelectedSelector = createSelector(
  invoiceItemsListSelector,
  (list: State.InvoiceItemResult[]): boolean => {
    return list.every((invoiceItem: State.InvoiceItemResult) => invoiceItem.isSelected);
  }
);

const subscriptionsSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.SubscriptionList => admin.subscriptionsList
);

export const isSubscriptionsFetchedSelector = createSelector(
  subscriptionsSelector,
  (subscriptions: State.SubscriptionList): boolean => subscriptions.isSubscriptionsFetched
);

export const subscriptionsListSelector = createSelector(
  subscriptionsSelector,
  (subscriptions: State.SubscriptionList): State.SubscriptionsResult[] => subscriptions.list || []
);

export const isMultipleSubscriptionListSelectedSelector = createSelector(
  subscriptionsListSelector,
  (list: State.SubscriptionsResult[]): boolean => {
    return list.some((subscriptions: State.SubscriptionsResult) => subscriptions.isSelected);
  }
);

export const selectedSubscriptionsSelector = createSelector(
  subscriptionsListSelector,
  (list: State.SubscriptionsResult[]): State.SubscriptionsResult[] =>
    list.filter((subscription: State.SubscriptionsResult) => subscription.isSelected)
);

export const isAllSubscriptionsSelectedSelector = createSelector(
  subscriptionsListSelector,
  (list: State.SubscriptionsResult[]): boolean => {
    return list.every((subscription: State.SubscriptionsResult) => subscription.isSelected);
  }
);

export const isMultipleInvoiceItemsSelectedSelector = createSelector(
  invoiceItemsListSelector,
  (list: State.InvoiceItemResult[]): boolean => {
    return list.some((invoiceItem: State.InvoiceItemResult) => invoiceItem.isSelected);
  }
);

export const selectedInvoiceItemsSelector = createSelector(
  invoiceItemsListSelector,
  (list: State.InvoiceItemResult[]): State.InvoiceItemResult[] =>
    list.filter((invoiceItem: State.InvoiceItemResult) => invoiceItem.isSelected)
);

export const invoiceItemsLoadingSelector = createSelector(
  invoiceItemsSelector,
  (invoiceItems: State.InvoiceItems): boolean => invoiceItems.loading
);

export const subscriptionsLoadingSelector = createSelector(
  subscriptionsSelector,
  (subscriptions: State.SubscriptionList): boolean => subscriptions.loading
);

export const unappliedPaymentIdSelector = createSelector(
  getProductCancellationStatusSelector,
  (cancellationStatus: State.ProductCancellationStatus): string => cancellationStatus.paymentId || ''
);

export const legalEntitySelector = createSelector(
  rootSelector,
  (admin: State.Admin): string | null => admin.legalEntity
);

export const salesforceAccountNumberSelector = createSelector(
  rootSelector,
  (admin: State.Admin): string | null => admin.salesforceAccountNumber
);

export const organizationZuoraAccountIdSelector = createSelector(
  rootSelector,
  (admin: State.Admin): string | null => admin.zuoraAccountId
);

export const organizationOrderRefundCancelSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.OrganizationOrderRefundCancel => admin.organizationOrderRefundCancel
);

export const bulkRenewalHistorySelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.BulkRenewalHistoryTableContents[] => admin.bulkRenewal.bulkRenewalHistory
);

export const reportHistorySelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.BulkRenewalReportHistoryTableContents[] => admin.bulkRenewal.reportHistory
);

export const recentTransactionSelector = createSelector(
  rootSelector,
  (admin: State.Admin): State.BulkRenewalRecentTransaction => admin.bulkRenewal.recentTransaction
);

export const getBulkRenewalAccessSelector = createSelector(
  rootSelector,
  (admin: State.Admin): boolean => admin.bulkRenewal.shouldHaveBulkRenewalAccess
);

export const generateBulkRenewalReportSelector = createSelector(
  rootSelector,
  (admin: State.Admin): any => admin.bulkRenewal.generateBulkRenewalReport
);

export const isTransactionHistoryLoadingSelector = createSelector(
  rootSelector,
  (admin: State.Admin): any => admin.bulkRenewal.isTransactionHistoryLoading
);

export const isReportHistoryLoadingSelector = createSelector(
  rootSelector,
  (admin: State.Admin): any => admin.bulkRenewal.isReportHistoryLoading
);

export const preSignedUrlSelector = createSelector(
  rootSelector,
  (admin: State.Admin): any => admin.bulkRenewal.preSignedUrl
);

export const orderRefundCacelSelectedProduct = createSelector(
  organizationOrderRefundCancelSelector,
  (organizationOrderRefundCancel: State.OrganizationOrderRefundCancel) => organizationOrderRefundCancel.selectedProduct
);

export const extendAccessSelector = createSelector(
  rootSelector,
  (
    admin: State.Admin
  ): { loading: boolean; product?: Common.ProductItemData; success?: boolean; extendedDate?: string } =>
    admin.extendAccess
);
