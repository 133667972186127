import React from 'react';
import styled from 'styled-components';
import { useLocation, matchPath, generatePath } from 'react-router';
import { Container, Grid } from 'semantic-ui-react';
import { Routes } from 'constants/index';
import { Heading, ButtonLink, ButtonEnums, OnlyDesktopCSS, Link } from 'components/atoms';
import { getPath } from 'utils/routes';
import { ReactComponent as BackArrowIcon } from 'resources/images/ic-arrow-back.svg';

export const OrganizationHeader: React.FC = () => {
  const location = useLocation();
  const path: string = location.pathname;

  const isOrganizationSeatsPage = Boolean(matchPath(path, { path: getPath(Routes.ORG_SEAT_MANAGEMENT), exact: false }));
  const isOrganizationInvoicePage = Boolean(matchPath(path, { path: getPath(Routes.ORG_INVOICES), exact: false }));
  const isOrganizationFirmInfoPage = Boolean(
    matchPath(path, { path: getPath(Routes.ORG_FIRM_INFORMATION), exact: false })
  );

  const title: string =
    isOrganizationSeatsPage || isOrganizationInvoicePage
      ? 'B2B Activities'
      : isOrganizationFirmInfoPage
      ? 'Firm Information'
      : 'B2B Profile';
  const buttonTitle: string = 'Switch to my user profile';
  const buttonUrl: string = getPath(Routes.MY_PROFILE_ROOT);
  const backLinkTitle: string = 'Back to B2B Profile';

  return (
    <StyledFullWidthContainer data-testid="organization-header">
      <Container>
        <StyledGridContainer columns={16} container stackable>
          <Grid.Column computer={16} mobile={16}>
            <StyledFlexContainer>
              <StyledFlexLeftSection>
                {(isOrganizationSeatsPage || isOrganizationInvoicePage || isOrganizationFirmInfoPage) && (
                  <StyledLink testId="test" to={generatePath(getPath(Routes.ORG_ROOT))} isExternal={false}>
                    <StyledBackArrowIcon /> {backLinkTitle}
                  </StyledLink>
                )}
                <StyledHeading as="h1">{title}</StyledHeading>
              </StyledFlexLeftSection>
              <StyledFlexRightSection>
                <OnlyDesktopCSS>
                  <StyledButtonLink
                    testId={`user-profile-button`}
                    variant={ButtonEnums.variants.primary}
                    size={ButtonEnums.sizes.medium}
                    to={buttonUrl}
                  >
                    {buttonTitle}
                  </StyledButtonLink>
                </OnlyDesktopCSS>
              </StyledFlexRightSection>
            </StyledFlexContainer>
          </Grid.Column>
        </StyledGridContainer>
      </Container>
    </StyledFullWidthContainer>
  );
};

const StyledFullWidthContainer = styled(Container)`
  &&& {
    width: 100vw;
    min-height: ${props => props.theme.pxToRem(217)};
    padding: ${props => props.theme.pxToRem(28)}
    box-shadow: inset 0 0 10px 0 ${props => props.theme.colors.neutralGrey3};
    background-color: ${props => props.theme.colors.neutralGrey1};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

const StyledGridContainer = styled(Grid)`
  padding-top: ${props => props.theme.pxToRem(44)};
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  min-height: ${props => props.theme.pxToRem(143)};
`;

const StyledFlexLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledFlexRightSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledHeading = styled(Heading)`
  margin-top: ${props => props.theme.pxToRem(22)};
  margin-bottom: 0;
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.38;
  min-height: auto; // fix for Safari
`;

const StyledButtonLink = styled(ButtonLink)`
  &&&& {
    width: auto;
    height: ${props => props.theme.pxToRem(40)};
    padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(24)};
    margin-top: ${props => props.theme.pxToRem(62)};
  }
`;

const StyledLink = styled(Link)`
  &&& {
    color: ${props => props.theme.colors.neutralBlack};
    line-height: 1.75;
    font-weight: ${props => props.theme.fontWeights.medium};
    font-size: ${props => props.theme.fontSizes.xs};
    text-decoration: none;
  }
`;

const StyledBackArrowIcon = styled(BackArrowIcon)`
  fill: ${props => props.theme.colors.primaryPurple};
  width: ${props => props.theme.pxToRem(18)};
  margin-right: ${props => props.theme.pxToRem(4)};
  vertical-align: middle;
  & > path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;
