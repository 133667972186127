import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'components/atoms/Input/Input';
import { Info } from 'components/atoms/svg';
import { Collapsible } from 'components/atoms/Collapsible/Collapsible';
import { Cart } from 'mxp-schemas';
import { theme } from 'theme';
import { ReactComponent as KeyboardArrowUp } from 'resources/images/icon-dev-ic-keyboard-arrow-up.svg';
import { ReactComponent as KeyboardArrowDown } from 'resources/images/icon-dev-ic-keyboard-arrow-down.svg';
import { handleEvent, NAV_CLICK } from 'utils/Analytics';

const PROMO_MAX_WIDTH = 26;
const ErrorMessages: { [key: string]: (promoCode: string, isCimaSignUpJourney?: boolean) => any } = {
  // ERRORS
  [Cart.Errors.INACTIVE]: (promoCode: string) => (
    <>
      {'Sorry, '}
      <span>{promoCode}</span>
      {` can't be found. Please enter a valid code.`}
    </>
  ),
  [Cart.Errors.INVALID]: (promoCode: string, isCimaSignUpJourney?: boolean) =>
    isCimaSignUpJourney ? (
      <>{'This registration code is invalid. Please enter a new code or proceed to the next step.'}</>
    ) : (
      <>
        {'Sorry, '}
        <span>{promoCode}</span>
        {` can't be found. Please enter a valid code.`}
      </>
    ),
  [Cart.Errors.EXPIRED]: (promoCode: string) => (
    <>
      {'Sorry, '}
      <span>{promoCode}</span>
      {' has expired.'}
    </>
  ),
  [Cart.Errors.VALID_IN_FUTURE]: (promoCode: string) => (
    <>
      {'Sorry, '}
      <span>{promoCode}</span>
      {` can't be found. Please enter a valid code.`}
    </>
  ),
  [Cart.Errors.ALREADY_USED]: (promoCode: string) => (
    <>
      {'Sorry, '}
      <span>{promoCode}</span>
      {' has already been used.'}
    </>
  ),
  [Cart.Errors.NO_MEMBERSHIP]: (promoCode: string) => (
    <>
      {'Sorry, '}
      <span>{promoCode}</span>
      {` doesn't apply to your membership.`}
    </>
  ),
  [Cart.Errors.GENERIC]: () => <>{'An error occurred; please refresh'}</>,
  // WARNINGS
  [Cart.Warnings.DOES_NOT_APPLY]: (promoCode: string) => (
    <>
      {'Sorry, '}
      <span>{promoCode}</span>
      {` doesn't apply to your cart.`}
    </>
  ),
  [Cart.Warnings.WILL_BE_VALIDATED]: (promoCode: string) => (
    <>
      <span>{promoCode}</span>
      {` code will be applied after you select the shipping option.`}
    </>
  ),
  [Cart.Warnings.SELECTED_SHIPPING_INVALID]: (promoCode: string) => (
    <>
      <span>{promoCode}</span>
      {` is not valid for your selected shipping type.`}
    </>
  ),
};

const processInput: (codeValue: string) => string = codeValue => {
  return codeValue.toUpperCase().substring(0, PROMO_MAX_WIDTH);
};

interface PromoCodeProps {
  addPromo: (code: string) => void;
  removePromo: () => void;
  appliedPromoCode?: string;
  isAppliedPromoCodeTargetShipping: boolean;
  isPageCart: boolean;
  cartError: Cart.Errors;
  className?: string;
  resetCartError: () => void;
  promoDiscount: Cart.DiscountDetails | null;
  initialSentCodeValue?: string;
  notExpandable?: boolean;
  suppressWarning?: boolean;
  isCimaSignUpJourney?: boolean;
  isPromoCodeLabelHide?: boolean;
  isPagePromotion?: boolean;
  isPromocodeExemptionAvail?: boolean;
  setPromoCode?: any;
}

export const PromoCode: React.FC<PromoCodeProps> = ({
  addPromo,
  removePromo,
  appliedPromoCode,
  isAppliedPromoCodeTargetShipping,
  isPageCart,
  cartError,
  className,
  resetCartError,
  promoDiscount,
  notExpandable = false,
  initialSentCodeValue = '',
  suppressWarning = false,
  isCimaSignUpJourney = false,
  isPromoCodeLabelHide,
  isPagePromotion,
  isPromocodeExemptionAvail,
  setPromoCode,
}) => {
  const placeholder = 'Enter your promo code';
  const [codeValue, setCodeInput] = useState('');
  const [selectionStart, setSelectionStart] = React.useState(0);
  const [selectionEnd, setSelectionEnd] = React.useState(0);
  const input = React.useRef(null);

  const [collapsed, setCollapsed] = useState(true);
  const [sentCodeValue, setSentCodeValue] = useState(initialSentCodeValue);

  const handleRemove = React.useCallback(() => {
    if (appliedPromoCode && !isPromocodeExemptionAvail) {
      removePromo();
      setCodeInput('');
      setSentCodeValue('');
    }
  }, [removePromo, appliedPromoCode, isPromocodeExemptionAvail]);

  React.useEffect(() => {
    setCollapsed(!(sentCodeValue && cartError !== Cart.Errors.NO_ERROR)); // error promocode = visible text field
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdd = React.useCallback(() => {
    handleEvent(
      {
        clickValue: `button:link:int::Apply:${window?.location?.pathname}`,
        couponCode: codeValue,
      },
      NAV_CLICK
    );
    setPromoCode(codeValue);
    addPromo(codeValue);
    setSentCodeValue(codeValue);
    setCodeInput('');
  }, [addPromo, codeValue, setPromoCode]);

  const handleCollapse = React.useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const handleFocus = () => {
    resetCartError();
  };
  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = e;
    // save cursor position
    setSelectionStart(target.selectionStart ? target.selectionStart : 0);
    setSelectionEnd(target.selectionEnd ? target.selectionEnd : 0);
    setCodeInput(processInput(target.value || ''));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // fix for cursor jumping to the end of the text when editing
  React.useEffect(() => {
    const inputRefValue: HTMLInputElement =
      // @ts-ignore: Object is possibly 'null'.
      input && input.current && input.current && input.current.inputRef.current ? input.current.inputRef.current : null;

    if (inputRefValue && inputRefValue.value && inputRefValue.selectionStart && inputRefValue.selectionEnd) {
      inputRefValue.value = processInput(codeValue);
      inputRefValue.selectionStart = selectionStart;
      inputRefValue.selectionEnd = selectionEnd;
    }
  }, [codeValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={className} id="promoCode">
      <CollapsiblePromo onClick={handleCollapse} data-testid="trigger-collapsible-promo">
        {!isPromoCodeLabelHide && (
          <PromoTitle>{appliedPromoCode || notExpandable ? 'Promo Code' : 'Have a promo code?'}</PromoTitle>
        )}

        {!notExpandable && (collapsed ? <StyledKeyboardArrowDown /> : <StyledKeyboardArrowUp />)}
      </CollapsiblePromo>
      <Collapsible collapsed={notExpandable ? !notExpandable : collapsed}>
        {!appliedPromoCode ? (
          <div>
            <StyledInput
              action={{
                content: 'Apply code',
                onClick: handleAdd,
                'data-testid': 'add-promo',
              }}
              labelId="promo-label-id"
              placeholder={placeholder}
              testId="promo-input"
              onChange={handleChange}
              maxLength={PROMO_MAX_WIDTH}
              onFocus={handleFocus}
              inputRef={input}
            />

            <ErrorMessage>
              {sentCodeValue && cartError !== Cart.Errors.NO_ERROR
                ? ErrorMessages[cartError]
                  ? ErrorMessages[cartError](sentCodeValue, isCimaSignUpJourney)
                  : ErrorMessages[Cart.Errors.GENERIC](sentCodeValue)
                : ''}
            </ErrorMessage>
          </div>
        ) : (
          <>
            <FlexWrap>
              {' '}
              <StyledInput
                action={{
                  content: 'Remove',
                  onClick: handleRemove,
                  'data-testid': 'remove-promo',
                }}
                disabled={isPromocodeExemptionAvail}
                labelId="promo-label-id"
                placeholder={placeholder}
                testId="promo-input"
                onChange={handleChange}
                maxLength={PROMO_MAX_WIDTH}
                onFocus={handleFocus}
                inputRef={input}
                value={appliedPromoCode}
                readOnly={true}
              />
              <AppliedPromoCode isWithWarning={!promoDiscount} id="appliedPromoCode" />
            </FlexWrap>
            <div>
              {appliedPromoCode && isPagePromotion && (
                <ValidMessage>
                  <>Registration code applied!</>
                </ValidMessage>
              )}
            </div>
            {!promoDiscount && !suppressWarning && !isPromocodeExemptionAvail && (
              <WarningNotification data-test-id="promo-code-warning-notification">
                <div>
                  <InfoIcon>
                    <Info color={theme.colors.neutralWhite} />
                  </InfoIcon>
                </div>
                <p data-test-id="promo-code-warning-message">
                  {ErrorMessages[
                    (() => {
                      // show general error for non shipping promos
                      if (!isAppliedPromoCodeTargetShipping) return Cart.Warnings.DOES_NOT_APPLY;
                      // shipping promos cart view message
                      if (isPageCart) return Cart.Warnings.WILL_BE_VALIDATED;
                      // shipping promos checkout view message
                      return Cart.Warnings.SELECTED_SHIPPING_INVALID;
                    })()
                  ](appliedPromoCode)}
                </p>
              </WarningNotification>
            )}
          </>
        )}
      </Collapsible>
    </div>
  );
};

const AppliedPromoCode = styled.div<{ isWithWarning: boolean }>`
  ${props => !props.isWithWarning && `padding-bottom: ${props.theme.pxToRem(12)}`};
`;

const CollapsiblePromo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledKeyboardArrowUp = styled(KeyboardArrowUp)`
  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const StyledKeyboardArrowDown = styled(KeyboardArrowDown)`
  path {
    fill: ${props => props.theme.colors.primaryPurple};
  }
`;

const FlexWrap = styled.div`
  display: flex;
  width: 100%;
`;

const ErrorMessage = styled.div`
  min-height: ${props => props.theme.pxToRem(18)};
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.interfaceRed};
  margin: ${props => props.theme.pxToRem(6)} 0;
  line-height: ${props => props.theme.pxToRem(18)};

  span {
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;

export const WarningNotification = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.pxToRem(14)};
  color: ${props => props.theme.colors.neutralGrey8};
  padding: ${props => props.theme.pxToRem(16)};
  background-color: rgba(65, 182, 230, 0.1);
  border-radius: ${props => props.theme.pxToRem(4)};
  border: 1px solid ${props => props.theme.colors.interfaceBlue};
  margin: ${props => props.theme.pxToRem(12)} 0;

  span {
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;

const InfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.neutralWhite};
  border-radius: 50%;
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  background: ${props => props.theme.colors.interfaceBlue};
  margin-right: ${props => props.theme.pxToRem(16)};
`;

export const PromoTitle = styled.div`
  &&& {
    padding-top: ${props => props.theme.pxToRem(12)};
    padding-bottom: ${props => props.theme.pxToRem(12)};
    color: ${props => props.theme.colors.primaryPurple};
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 1.57;

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding-top: ${props => props.theme.pxToRem(20)};
      padding-bottom: ${props => props.theme.pxToRem(20)};
      font-size: ${props => props.theme.fontSizes.m};
      line-height: 1.33;
    }
  }
`;

export const StyledInput = styled(Input)`
  &&&& {
    width: 100%;
    padding-right: 5.25rem;
    &.ui.input > input {
      height: ${props => props.theme.pxToRem(40)};
      padding: ${props => props.theme.pxToRem(8)} ${props => props.theme.pxToRem(12)};
      color: ${props => props.theme.colors.neutralGrey8};
      font-size: ${props => props.theme.fontSizes.s};
      font-weight: ${props => props.theme.fontWeights.regular};
      line-height: 1.57;
    }

    .ui.button {
      display: flex;
      justify-content: center;
      padding: 3%;
      width: ${props => props.theme.pxToRem(90)};
      height: ${props => props.theme.pxToRem(40)};
      background: ${props => props.theme.colors.primaryPurple};
      color: ${props => props.theme.colors.neutralWhite};
      font-size: ${props => props.theme.fontSizes.xs};
      font-weight: ${props => props.theme.fontWeights.medium};
      line-height: 1.57;
    }
  }
`;
const ValidMessage = styled.div`
  min-height: ${props => props.theme.pxToRem(18)};
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.interfaceGreen};
  margin: ${props => props.theme.pxToRem(6)} 0;
  line-height: ${props => props.theme.pxToRem(18)};

  span {
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;
