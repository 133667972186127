import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components/macro';

import { ADMIN_AUTH_STATUS, Routes } from 'constants/index';
import { getPath } from 'utils';
import { AicpaLogo, Container, Link } from 'components/atoms';
import { Footer } from 'components/molecules/Footer/Footer';
import { useLocation } from 'react-router';
import { matchPath, Redirect, Route, Switch } from 'react-router-dom';
import { AppContainer } from 'containers/AppContainer';
import { AdminErrorPage } from 'components/admin/pages/AdminErrorPage/AdminErrorPage';
import { AdminNoLookUpResult } from 'components/admin/pages/AdminNoLookUpResult/AdminNoLookUpResult';
import { CancelPage } from 'components/admin/pages/CancelPage/CancelPage';
import { AdminAddClientAdminPage } from 'components/admin/pages/AdminAddClientAdminPage/AdminAddClientAdminPage';
import { AdminClientAdminResultsPage } from 'components/admin/pages/AdminClientAdminResultsPage/AdminClientAdminResultsPage';
import { CreateOrder } from 'components/admin/pages/CreateOrder';
import { PageAdminLookUp } from 'components/admin/pages/PageAdminLookUp/PageAdminLookUp';
import { AdminInvoicesSearchPage } from 'components/admin/pages/AdminInvoicesSearchPage/AdminInvoicesSearchPage';
import { AdminInvoicesSearchResultsPage } from 'components/admin/pages/AdminInvoicesSearchResultsPage/AdminInvoicesSearchResultsPage';
import { AdminInvoicesDetailsPage } from 'components/admin/pages/AdminInvoicesDetailsPage/AdminInvoicesDetailsPage';
import { AdminInvoicesPaymentPage } from 'components/admin/pages/AdminInvoicesPaymentPage/AdminInvoicesPaymentPage';
import { AdminInvoicesConfirmationPage } from 'components/admin/pages/AdminInvoicesConfirmationPage/AdminInvoicesConfirmationPage';
import { holdingPageBypassSelector } from 'modules/startup/selectors';
import { StickyBanner as AdminStickyBanner } from 'components/admin/molecules/StickyBanner/StickyBanner';
import { useSelector } from 'react-redux';
import {
  isB2BSelector,
  isFirmBillingSelector,
  isCenterMembershipSelector,
  adminFeatureTogglesSelector,
} from 'modules/admin/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { OrganizationProfile } from 'components/admin/pages/OrganizationProfile/OrganizationProfile';
import { PageOrderDetails } from 'components/admin/pages/PageOrderDetails/PageOrderDetails';
import { useSaveQueryParams } from 'hooks/useSaveQueryParams';

import { PreLogin } from '../admin/pages/PreLogin/PreLogin';
import { Loader } from '../atoms/Loader/Loader';
import { ModalAddressValidationContainer } from 'containers/ModalAddressValidationContainer';
import { PageAdminFilterInvoices } from '../admin/pages/PageAdminLookUp/PageAdminFilterInvoices';
import { isPageAdminInvoiceFilterSelector } from 'modules/router/selectors';
import { adminStickyFooterInvoiceFilterVisibleSelector } from 'modules/layouts/selectors';
import { ModalPaymentProcessingContainer } from './lazy-imports';
import { PageInvoiceError } from 'components/pages/PageInvoiceError';
import { RefundCancelProduct } from 'components/admin/pages/PageRefundCancelProduct/PageRefundCancelProduct';
import { PageSiteOfflineContainer } from 'containers/PageSiteOfflineContainer';

interface Props {
  isImpersonating: boolean | null;
  isAdminSiteOffline: boolean | null;
  adminAuthStatus: ADMIN_AUTH_STATUS;
  adminClientRole: State.B2BRoles | null;
  checkAdminSession(): void;
  checkAdminClientRoleChosen(): void;
}

const title = {
  [ADMIN_AUTH_STATUS.AUTHORIZED]: 'Products & Purchases Console',
  [ADMIN_AUTH_STATUS.UNAUTHENTICATED]: 'Logged out: I will never see this',
  [ADMIN_AUTH_STATUS.UNAUTHORIZED]: 'You are not authorized to use this app',
};
export const AdminApp: React.FC<Props> = ({
  adminAuthStatus,
  isImpersonating,
  isAdminSiteOffline,
  checkAdminSession,
  adminClientRole,
  checkAdminClientRoleChosen,
}) => {
  useSaveQueryParams();

  React.useEffect(() => {
    if (!adminClientRole) {
      checkAdminClientRoleChosen();
    } else {
      checkAdminSession();
    }
  }, [adminClientRole, checkAdminClientRoleChosen, checkAdminSession]);

  const isB2B = useSelector(isB2BSelector);
  const isFirmBilling = useSelector(isFirmBillingSelector);
  const isCenterMembership = useSelector(isCenterMembershipSelector);
  const isAdminPortal = useSelector(isAdminPortalSelector);
  const isInvoiceFilter = useSelector(isPageAdminInvoiceFilterSelector);
  const isStickyFooterVisible = useSelector(adminStickyFooterInvoiceFilterVisibleSelector);
  const location = useLocation();
  const isCreateOrder = Boolean(
    matchPath(location.pathname, {
      path: getPath(Routes.ADMIN_CREATE_ORDER),
      exact: true,
      strict: false,
    })
  );
  const isSearchInvoices = Boolean(
    matchPath(location.pathname, {
      path: getPath(Routes.ADMIN_INVOICES_SEARCH),
      exact: true,
      strict: false,
    }) ||
      matchPath(location.pathname, {
        path: getPath(Routes.ADMIN_INVOICES_SEARCH_RESULTS),
        exact: true,
        strict: false,
      }) ||
      matchPath(location.pathname, {
        path: getPath(Routes.ADMIN_INVOICES_PAYMENT),
        exact: true,
        strict: false,
      }) ||
      matchPath(location.pathname, {
        path: getPath(Routes.ADMIN_INVOICES_DETAILS),
        exact: true,
        strict: false,
      })
  );

  const renderAdminInvoicesPayment = (): React.ReactNode => {
    return (
      <>
        <AdminInvoicesPaymentPage />
        <ModalPaymentProcessingContainer />
      </>
    );
  };

  const adminFeatureTogglesFetched = useSelector(state => adminFeatureTogglesSelector(state as State.Root));
  const holdingPageBypass = useSelector(state => holdingPageBypassSelector(state as State.Root));
  const unauthorized = adminAuthStatus === ADMIN_AUTH_STATUS.UNAUTHORIZED;
  const isUserIdentified = adminAuthStatus === ADMIN_AUTH_STATUS.AUTHORIZED || unauthorized;
  // if user role not set show Prelogin page
  if ((!adminClientRole || unauthorized) && Object.keys(adminFeatureTogglesFetched).length) {
    return isAdminSiteOffline && !holdingPageBypass ? (
      <PageSiteOfflineContainer />
    ) : (
      <PreLogin unauthorized={unauthorized} />
    );
  }

  if (!isUserIdentified || isImpersonating === null) return <Loader active content="Loading" />;

  const isB2BCheckout: boolean = isAdminPortal && !isImpersonating;

  return (
    <>
      {!isImpersonating ? (
        // If we are not impersonating; show admin pages such as customer search / impersonation pages
        <StyledContainer>
          <Helmet>
            <title>{title[adminAuthStatus]}</title>
          </Helmet>

          {!isCreateOrder && !isSearchInvoices && <HeaderAdmin />}

          <StyledContent>
            {adminAuthStatus === ADMIN_AUTH_STATUS.AUTHORIZED ? (
              <Switch>
                <Route exact path={getPath(Routes.ADMIN_ROOT)} component={PageAdminLookUp} />
                <Route exact path={getPath(Routes.ADMIN_USER)} component={PageAdminLookUp} />
                <Route exact path={getPath(Routes.ADMIN_INVOICES)} component={PageAdminLookUp} />
                <Route exact path={getPath(Routes.ADMIN_OPERATIONS)} component={PageAdminLookUp} />
                <Route exact path={getPath(Routes.ADMIN_BULK_RENEWAL)} component={PageAdminLookUp} />

                <Route exact path={getPath(Routes.ADMIN_ERROR_PAGE)} component={AdminErrorPage} />
                <Route exact path={getPath(Routes.ADMIN_NO_FETCH_RESULTS)} component={AdminNoLookUpResult} />
                <Route exact path={getPath(Routes.ADMIN_INVOICES_SEARCH)} component={AdminInvoicesSearchPage} />
                <Route exact path={getPath(Routes.ADMIN_INVOICES_FILTER)} component={PageAdminFilterInvoices} />
                <Route
                  exact
                  path={getPath(Routes.ADMIN_INVOICES_SEARCH_RESULTS)}
                  component={AdminInvoicesSearchResultsPage}
                />
                <Route exact path={getPath(Routes.ADMIN_INVOICES_DETAILS)} component={AdminInvoicesDetailsPage} />
                <Route exact path={getPath(Routes.ADMIN_INVOICES_PAYMENT)} render={renderAdminInvoicesPayment} />
                <Route
                  exact
                  path={getPath(Routes.ADMIN_INVOICES_PAYMENT_CONFIRMATION)}
                  component={AdminInvoicesConfirmationPage}
                />
                <Route exact path={getPath(Routes.ADMIN_INVOICES_PAYMENT_ERROR)} component={PageInvoiceError} />
                {/* B2B Routes */}
                {isB2B && <Route exact path={getPath(Routes.ADMIN_CREATE_ORDER)} component={CreateOrder} />}
                {(isB2B || isFirmBilling || isCenterMembership) && (
                  <Route exact path={getPath(Routes.ADMIN_ADD_CLIENT_ADMIN)} component={AdminAddClientAdminPage} />
                )}
                {(isB2B || isFirmBilling || isCenterMembership) && (
                  <Route
                    exact
                    path={getPath(Routes.ADMIN_CLIENT_ADMIN_RESULTS)}
                    component={AdminClientAdminResultsPage}
                  />
                )}
                {(isB2B || isFirmBilling || isCenterMembership) && (
                  <Route exact path={getPath(Routes.ADMIN_ORGANIZATION)} component={OrganizationProfile} />
                )}
                {(isB2B || isFirmBilling || isCenterMembership) && (
                  <Route exact path={getPath(Routes.ADMIN_ORDER_DETAILS)} component={PageOrderDetails} />
                )}
                {(isB2B || isFirmBilling || isCenterMembership) && (
                  <Route exact path={getPath(Routes.ADMIN_ORDER_REFUND_CANCEL)} component={RefundCancelProduct} />
                )}
                {(isB2B || isFirmBilling || isCenterMembership) && (
                  <Route exact path={getPath(Routes.ADMIN_ORGANIZATION_ROOT)} component={PageAdminLookUp} />
                )}

                <Redirect to={getPath(Routes.ADMIN_ROOT)} />
              </Switch>
            ) : (
              <div> {title[ADMIN_AUTH_STATUS.UNAUTHORIZED]}</div>
            )}
          </StyledContent>
          <StyledFooter addBottomMargin={isInvoiceFilter && isStickyFooterVisible} />
        </StyledContainer>
      ) : (
        // If impersonating; show the full app, this should magically work!
        <>
          <AdminStickyBanner />
          <Switch>
            <Route exact path={getPath(Routes.ADMIN_CANCEL)} component={CancelPage} />
            <Route component={AppContainer} />
          </Switch>
        </>
      )}{' '}
      {isB2BCheckout && <ModalAddressValidationContainer isRaveCheckout={!isB2BCheckout} />}{' '}
    </>
  );
};

const LogoStyled = styled(AicpaLogo)`
  cursor: pointer;
`;

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledHeaderContainer = styled(Container)`
  &&&& {
    display: flex;
    align-items: center;
    min-height: ${props => props.theme.pxToRem(81)};
    border: solid ${props => props.theme.pxToRem(1)} ${props => props.theme.colors.neutralGrey3};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: ${props => props.theme.pxToRem(31)};
    width: 100%;
  }
`;

const StyledFooter = styled(Footer)<{ addBottomMargin?: boolean }>`
  &&& {
    margin-bottom: ${props => (props.addBottomMargin ? props.theme.pxToRem(100) : 0)};
  }
`;
export const HeaderAdmin: React.FC = () => (
  <StyledHeaderContainer fluid data-testid="header-row-search">
    <Container>
      <Link testId="test-admin-home" to={getPath(Routes.ADMIN_ROOT)} overrideAnalytics={true}>
        <LogoStyled to={Routes.ADMIN_ROOT} />
      </Link>
    </Container>
  </StyledHeaderContainer>
);

const StyledContent = styled.div`
  margin-bottom: 1rem;
`;
