import React, { memo, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {
  Link,
  LabeledIcon,
  Button,
  ButtonEnums,
  ToggleButton,
  Divider,
  OnlyDesktopCSS,
  OnlyMobileCSS,
} from 'components/atoms';
import { Utils, Admin as AdminUtils, CheckoutCountriesListHash } from 'mxp-utils';
import { Product, Contentful, ExemptionLevels, MembershipTypes } from 'mxp-schemas';
import { ReactComponent as Checkmark } from 'resources/images/checkmark.svg';
import { TitleImageDesktop } from 'components/molecules/MembershipTypeSelection/MembershipTypeSelectionButton';
import { Grid, GridColumn, Image } from 'semantic-ui-react';
import {
  EntryPointLevelCard,
  EntryPointLevelCardProps,
} from 'components/molecules/EntryPointLevelCard/EntryPointLevelCard';
import { ModalEntryPointLevel, ModalCardProps } from 'components/molecules/ModalEntryPointLevel/ModalEntryPointLevel';
import {
  isFLPSwitchSelector,
  isFLPUpgradeSelector,
  membershipProductSelector,
  isRenewalsJourneySelector,
  hasNotMetMinimumRequirementsSelector,
  membershipInviteDataSelector,
  isCimaMembershipLapsedSelector,
  membershipTiersSelector,
} from 'modules/membership/selectors';
import {
  learningPathwaySelector,
  isUserMemberSelector,
  personAccountCountrySelector,
  currentJourneyLearningPathwaySelector,
  isAuthSelector,
} from 'modules/user/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { fetchMembershipTiers } from 'modules/membership';
import { MembershipPackageAccordionStatus, Routes } from 'constants/index';
import { ModalVariants, getPath, getCurrentLocation } from 'utils';
import { setSelectedExemptReferenceLevel } from 'modules/exemptionEngine/action';
import {
  hasPromoSelector,
  appliedPromoCodeDescriptionSelector,
  appliedPromoCodeValueSelector,
  appliedPromoCodeCartDiscountDescriptionSelector,
} from 'modules/cart/selectors';
import { updateLowerExemption } from 'modules/exemptionReference/action';
import { ModalFirmBillingFLPRequirementsNotMet } from 'components/organisms';

/**
 * CIMA Membership Type Selector
 * @category Pathway Selection
 * CBUILD-67
 */
interface MembershipTypeSelectionCard {
  id?: string;
  slug?: string;
  name?: string;
  title?: string;
  applyLink?: string;
  description?: string;
  prices?: Product.Price[];
  benefits?: Contentful.MembershipRestriction.BenefitsSubBlock[];
  productDiscount?: Contentful.MembershipRestriction.ProductDiscount;
  benefitsReference?: string;
  propPageReference?: string;
  eligibility?: string;
  image?: Contentful.ContentfulImage;
  isRecommended?: boolean;
  selectMembership: (id: string, slug: string, membershipType: string[], isApprentice: boolean) => void;
  isWideView?: boolean;
  productType?: string[];
  isEntryPointLevel?: boolean;
  isCardSelected?: boolean;
  hasDiscount?: boolean;
  productTypeSubNotes?: string;
  entryPoint?: EntryPointLevelCardProps;
  onConfirmModal?: () => void;
  modalHeader?: string;
  modalDescription?: string;
  entryPointDataList?: ModalCardProps[];
  // Added Props
  selectedLevelSubmitHandler?: any;
  setParentExemption?: any;
  exemptionInfo?: any;
  setSelectedExemptionMaster?: any;
  selectMembershipTier?: (sku: string, tierCode?: string) => void;
  isApprenticeshipCard?: boolean;
  isRenewal?: boolean;
  showFLPModal?: (
    variant: ModalVariants,
    id: string,
    slug: string,
    productType: string[],
    overRideFlpSwitch?: boolean
  ) => void;
  isLoading?: boolean;
  selectedPathwayId?: string;
  isRegionalPathway?: boolean;
  levelNameValidateText?: string;
  isApprenticeMembershipModal?: boolean;
  setUserSelectedExemption?: (exemption: string) => void;
  isStudyPath?: boolean;
  setNewEntryPoint?: (newEntryPoint: string) => void;
  activeLevel?: string;
  additionalInfo?: string;
  displayTitle?: boolean;
  customPriceLabel?: string;
  isOfflineExemptionCalculator?: boolean;
  selectPathway?: (pathway: string) => void;
}
const Benefits: React.FC<{
  slug?: string;
  benefits?: Contentful.MembershipRestriction.BenefitsSubBlock[];
  benefitsReference?: string;
  setOpenModal?: () => void;
}> = ({ slug, benefits = [], benefitsReference }) => {
  const isFLPUpgrade = useSelector(isFLPUpgradeSelector);
  const isFLPSwitch = useSelector(isFLPSwitchSelector);

  return (
    <>
      <ul>
        {benefits?.map(({ header }, index: any) =>
          header ? (
            <StyledLabeledIcon icon={<StyledCheckIcon />} label={header} mobileLabel={header} key={index} />
          ) : null
        )}
      </ul>
      <p />
      {benefitsReference && !isFLPUpgrade && !isFLPSwitch && (
        <StyledLink
          testId={`benefits-page-${slug}`}
          to={!isFLPUpgrade && !isFLPSwitch ? benefitsReference : '/'}
          isExternal={true}
        >
          See all Benefits
        </StyledLink>
      )}
    </>
  );
};

export const CimaMembershipTypeCard: React.FC<MembershipTypeSelectionCard> = memo(
  ({
    id = '',
    slug = '',
    description = 'Short description for indicating the strengths of the program in order to make users want to choose it.',
    name,
    title,
    benefits = [],
    productDiscount,
    benefitsReference,
    propPageReference = '',
    isRecommended = false,
    selectMembership,
    image,
    productType = [],
    isEntryPointLevel = false,
    isCardSelected = false,
    hasDiscount = false,
    productTypeSubNotes = '',
    entryPoint,
    onConfirmModal,
    modalHeader,
    modalDescription,
    entryPointDataList,
    // Added Props
    selectedLevelSubmitHandler,
    setParentExemption,
    setSelectedExemptionMaster,
    exemptionInfo,
    selectMembershipTier,
    isApprenticeshipCard = false,
    isRenewal,
    showFLPModal,
    isLoading,
    selectedPathwayId,
    isRegionalPathway = false,
    levelNameValidateText,
    isApprenticeMembershipModal = false,
    setUserSelectedExemption,
    isStudyPath = false,
    setNewEntryPoint,
    activeLevel,
    additionalInfo,
    displayTitle = true,
    customPriceLabel,
    isOfflineExemptionCalculator = false,
    selectPathway,
  }) => {
    const dispatch = useDispatch();
    const [areAllVisible, setVisibility] = useState(false);
    const toggleVisibility = useCallback(() => {
      setVisibility(!areAllVisible);
    }, [areAllVisible]);
    const collapsedText = 'Hide Benefits';
    const defaultText = 'Show Benefits';
    const [isModalOpen, setModalOpen] = useState(false);
    const setOpenCloseModalCallBack = useCallback(() => setModalOpen(!isModalOpen), [isModalOpen]);
    const currentJourneyLearningPathway = useSelector(currentJourneyLearningPathwaySelector);
    const isChinesePathwayCurrentJourney = AdminUtils.isChinesePathway(currentJourneyLearningPathway);
    const isFlpCurrentJourney = AdminUtils.isFlpPathway(currentJourneyLearningPathway);
    const isApprenticeCurrentJourney = AdminUtils.isApprenticePathway(currentJourneyLearningPathway);
    const isFLPUpgrade = useSelector(isFLPUpgradeSelector);
    const isFLPSwitch = useSelector(isFLPSwitchSelector);
    const learningPathway = useSelector(learningPathwaySelector) as string;
    const isUserMember = useSelector(isUserMemberSelector);
    const getCountryName = useSelector(personAccountCountrySelector);
    // Product Code selectors for Discount
    const hasPromoCode = useSelector(hasPromoSelector);
    const discountCodeValue = useSelector(appliedPromoCodeValueSelector);
    const discountCodeDescription = useSelector(appliedPromoCodeDescriptionSelector);
    const discountCodeCartDescription = useSelector(appliedPromoCodeCartDiscountDescriptionSelector);
    const isCimaMembershipLapsed = useSelector(isCimaMembershipLapsedSelector);
    const isAuth = useSelector(isAuthSelector);
    const isAdminPortal = useSelector(isAdminPortalSelector);
    const isImpersonation = Boolean(isAuth) && isAdminPortal;

    const isUserFromUSA = getCountryName === CheckoutCountriesListHash.USA.text;
    const history = useHistory();
    const location = useLocation();
    const [submitExemption, setSubmitExemption] = useState({
      id: '',
      name: '',
      description: '',
      masterExamReference: '',
      title: '',
    });
    const selectedApprentice = useCallback(async () => {
      await selectMembership(id, slug, productType, true);
      await dispatch(fetchMembershipTiers(false, '', false, true));
      setOpenCloseModalCallBack();
    }, [id, slug, productType, dispatch, selectMembership, setOpenCloseModalCallBack]);

    const selectedMembershipProduct = useSelector(membershipProductSelector);
    const isMembershipBodyCIMA = selectedMembershipProduct?.membershipBody?.key === Product.ProductMembershipBody.CIMA;
    const isMembershipKeyCandidate =
      selectedMembershipProduct?.membershipKey === MembershipTypes.MembershipKeys.CANDIDATE;
    const showPQMandatoryExamCreditsText =
      isEntryPointLevel &&
      currentJourneyLearningPathway === MembershipTypes.Pathway.PQ &&
      isMembershipKeyCandidate &&
      isMembershipBodyCIMA;

    const membershipTiers = useSelector(membershipTiersSelector).list;
    const isRenewalsJourney = useSelector(isRenewalsJourneySelector);

    const isCurrentMembershipSubscription = slug === MembershipTypes.LearningPathwaySlug[learningPathway];
    const isSubscribedToFlp = AdminUtils.isFlpPathway(learningPathway as string);
    const isCimaPqCurrentJourney = AdminUtils.isCimaPqPathway(currentJourneyLearningPathway as string);

    const handleFLPAction = () => {
      const overRideFlpSwitch = membershipButtonLabel === `Switch to ${name}`;
      if (isCurrentMembershipSubscription) {
        history.push(getPath(Routes.MY_MEMBERSHIPS));
      } else {
        if (showFLPModal) {
          showFLPModal(ModalVariants.FLP_NO_EXCEPTION, id, slug, productType, overRideFlpSwitch);
        }
      }
    };

    useEffect(() => {
      if (isApprenticeMembershipModal && isApprenticeCurrentJourney) {
        setModalOpen(true);
        dispatch(fetchMembershipTiers(false, '', false, true));
      }
    }, [dispatch, isApprenticeCurrentJourney, isApprenticeMembershipModal]);

    // Switch FLP to PQ and vice versa | Simulate autoclick for Entry Point to redirect to add-ons.
    // Entry Point Level page has the person exam exemption creation logic.
    // But FD states to redirect to add-ons during switch FLP to PQ and vice versa, hence the autoclick solution.
    useEffect(() => {
      if (
        !isRegionalPathway &&
        ((exemptionInfo &&
          selectedLevelSubmitHandler &&
          isSubscribedToFlp &&
          isCimaPqCurrentJourney &&
          membershipTiers?.length > 0) ||
          isFLPUpgrade ||
          (isFLPSwitch && isEntryPointLevel))
      ) {
        selectedLevelSubmitHandler();
      }
    }, [
      exemptionInfo,
      selectedLevelSubmitHandler,
      isSubscribedToFlp,
      isCimaPqCurrentJourney,
      isFLPSwitch,
      isFLPUpgrade,
      isEntryPointLevel,
      isRegionalPathway,
      membershipTiers,
    ]);

    useEffect(() => {
      // sets the value of the current entry level (switched | upgrade)
      if ((isFLPSwitch || isFLPUpgrade) && entryPoint?.headerTitle !== undefined) {
        const entryPointNum = Number(entryPoint?.headerTitle);
        const newEntryPoint = Object.values(ExemptionLevels.LevelNameFLP)[
          entryPointNum > 0 ? entryPointNum - 1 : entryPointNum
        ];
        // This is to ensure that there will be no negative values for the FLPLevel index
        setNewEntryPoint?.(newEntryPoint);
      }
    }, [setNewEntryPoint, isFLPSwitch, isFLPUpgrade, entryPoint]);

    const membershipButtonLabel =
      (isRenewal || isFLPUpgrade || isFLPSwitch || isCimaMembershipLapsed || isSubscribedToFlp) && learningPathway
        ? isCurrentMembershipSubscription
          ? 'Keep my current selection'
          : isEntryPointLevel
          ? `Continue`
          : `Switch to ${name}`
        : !exemptionInfo
        ? `Let's get started`
        : isRecommended
        ? 'Contact me with more information'
        : isApprenticeCurrentJourney
        ? 'Submit'
        : Utils.replaceStringToNumber(exemptionInfo?.name) !== entryPoint?.headerTitle
        ? 'Your current entry point level'
        : 'Keep my current entry point';

    const destinationURL = useCallback(
      // tslint:disable-next-line:no-shadowed-variable
      (slug: string): any => {
        const currentLocation = getCurrentLocation(location);
        const currentHash = location?.hash.replace('#', '');

        if (currentLocation === getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY)) {
          if (
            isRenewalsJourney &&
            slug === MembershipTypes.CIMALearningPathwaySlug.FLP &&
            isCurrentMembershipSubscription
          ) {
            return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Skill}`;
          }

          if (
            isRenewalsJourney &&
            slug === MembershipTypes.CIMALearningPathwaySlug.CIMA_PQ &&
            isCurrentMembershipSubscription
          ) {
            return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Level}`;
          }

          if (!isRenewalsJourney && slug !== MembershipTypes.CIMALearningPathwaySlug.APPRENTICESHIP) {
            return getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_EXEMPTIONS_PROFESSIONAL);
          }

          return getPath(Routes.CIMA_MEMBERSHIP_STUDYPATH_PATHWAY);
        }

        if (
          currentLocation === getPath(Routes.CIMA_MEMBERSHIP_PACKAGE) &&
          membershipButtonLabel === 'Contact me with more information'
        ) {
          if (isImpersonation) {
            return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Level}`;
          }
          return `${getPath(Routes.CIMA_MEMBERSHIP_REGIONAL_PATHWAY)}`;
        }

        if (
          currentLocation === getPath(Routes.CIMA_MEMBERSHIP_PACKAGE) &&
          currentHash === MembershipPackageAccordionStatus.Level &&
          isCardSelected
        ) {
          // if slug selected is cima pq
          if (slug === MembershipTypes.CIMALearningPathwaySlug.CIMA_PQ) {
            return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Addons}`;
          }
          // if slug selected is flp
          if (slug === MembershipTypes.CIMALearningPathwaySlug.FLP) {
            if (isRenewal) {
              return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Level}`;
            }
            return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Skill}`;
          }
          return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Summary}`;
        }

        if (
          currentLocation === getPath(Routes.CIMA_MEMBERSHIP_PACKAGE) &&
          currentHash === MembershipPackageAccordionStatus.Skill &&
          isCardSelected
        ) {
          // if slug selected is flp
          if (slug === MembershipTypes.CIMALearningPathwaySlug.FLP) {
            if (isRenewal) {
              return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Skill}`;
            }
            return `${getPath(Routes.CIMA_MEMBERSHIP_PACKAGE)}#${MembershipPackageAccordionStatus.Skill}`;
          }
        }

        return `${currentLocation}#${MembershipPackageAccordionStatus.Skill}`;
      },
      [
        location,
        membershipButtonLabel,
        isRenewalsJourney,
        isRenewal,
        isCardSelected,
        isImpersonation,
        isCurrentMembershipSubscription,
      ]
    );

    const ApplyNowOpenModal = (
      <StyledButton
        buttonText={membershipButtonLabel}
        to={`${destinationURL(slug)}${slug ? `/${slug}` : ''}`}
        onClick={selectedApprentice}
        testId={`select-membership-link-${slug}`}
        variant={ButtonEnums.variants.primary}
        fluid
      >
        {membershipButtonLabel}
      </StyledButton>
    );

    const ApplyNowButton = (
      <StyledButton
        buttonText={membershipButtonLabel}
        to={`${destinationURL(slug)}${slug ? `/${slug}` : ''}`}
        testId={`select-membership-${slug}`}
        loading={isLoading && selectedPathwayId === id}
        onClick={
          exemptionInfo
            ? selectedLevelSubmitHandler
            : isFLPSwitch || isFLPUpgrade || membershipButtonLabel === `Switch to ${name}`
            ? handleFLPAction
            : selectMembership.bind(null, id, slug, productType, false)
        }
        variant={ButtonEnums.variants.primary}
        fluid
      >
        {membershipButtonLabel}
      </StyledButton>
    );

    const ApplyNowButtonDisabled = (
      <StyledButton
        testId={`select-membership-${slug}`}
        loading={isLoading && selectedPathwayId === id}
        disabled={true}
        variant={ButtonEnums.variants.primary}
        fluid
      >
        {membershipButtonLabel}
      </StyledButton>
    );

    const onModalConfirm = () => {
      setOpenCloseModalCallBack();
      if (onConfirmModal) onConfirmModal();
      if (isOfflineExemptionCalculator && selectPathway) {
        selectPathway(slug);
      }
      if (submitExemption?.name) {
        setParentExemption({
          id: submitExemption?.id,
          name: submitExemption?.name,
          title: submitExemption?.title,
          description: submitExemption?.description,
          masterExamReference: submitExemption?.masterExamReference,
        });
        setSelectedExemptionMaster({
          id: submitExemption?.id,
          name: submitExemption?.name,
          title: submitExemption?.title,
          description: submitExemption?.description,
          masterExamReference: submitExemption?.masterExamReference,
        });

        const cimaEntryLevel = MembershipTypes.FLPLevels.find(
          (level: { key: string }) => level.key === submitExemption?.name
        )?.value;

        dispatch(updateLowerExemption(submitExemption?.id));
        dispatch(setSelectedExemptReferenceLevel(cimaEntryLevel));

        if (setUserSelectedExemption) {
          setUserSelectedExemption(submitExemption?.title || '');
        }
      }
    };

    const onCardSelect = useCallback(
      async (sku: string, tierCode?: string) => {
        await selectMembershipTier?.(sku, tierCode);
        setOpenCloseModalCallBack();
      },
      [selectMembershipTier, setOpenCloseModalCallBack]
    );

    // Display Discount code on Card if Promo code is applied in the beginning
    const cardSlug = slug?.replace('-pathway', '').replace(/-/g, ' ');

    const renderDiscountValue = (): React.ReactNode => {
      let discountValue = '';
      const DISCOUNT_VALUE_RELATIVE = 'relative';
      const DISCOUNT_VALUE_ABSOLUTE = 'absolute';
      if (discountCodeValue?.type === DISCOUNT_VALUE_RELATIVE) {
        discountValue = `${Utils.fromCentsFormat(discountCodeValue?.permyriad)}% discount`;
      } else if (discountCodeValue?.type === DISCOUNT_VALUE_ABSOLUTE) {
        discountValue = `${discountCodeValue?.money[0].currencyCode} ${Utils.fromCentsFormat(
          discountCodeValue?.money[0].centAmount
        )}OFF`;
      }

      return (
        <>
          <StyledDiscountPrice>{discountValue}</StyledDiscountPrice>
          <StyledNotes>{discountCodeDescription ? discountCodeDescription : discountCodeCartDescription}</StyledNotes>
        </>
      );
    };

    // firm billing
    const hasNotMetMinimumRequirements = useSelector(hasNotMetMinimumRequirementsSelector);
    const inviteData = useSelector(membershipInviteDataSelector);
    const onCloseModal = useCallback(() => {
      history.push(getPath(Routes.FEED));
    }, [history]);

    return (
      <>
        <ModalEntryPointLevel
          open={isModalOpen}
          testId="regular-flp-modal"
          {...(isEntryPointLevel && { modalDescription })}
          confirmButtonLabel={isOfflineExemptionCalculator ? 'Select Pathway' : 'Submit'}
          onCancel={setOpenCloseModalCallBack}
          isApprenticeModal={!isEntryPointLevel}
          isSelectable={!isEntryPointLevel}
          onConfirm={onModalConfirm}
          modalHeader={modalHeader}
          modalDescription={modalDescription}
          entryPointDataList={entryPointDataList}
          setSubmitExemption={setSubmitExemption}
          onCardSelected={onCardSelect}
          currentEntryLevelPointName={exemptionInfo?.name}
          currentActiveLevel={activeLevel}
          isFLP={isOfflineExemptionCalculator && slug === MembershipTypes.CIMALearningPathwaySlug.FLP}
          isOfflineExemptionCalculator={isOfflineExemptionCalculator}
        />

        {(inviteData?.isPaidByFirm || inviteData?.isPaidByFLP) && hasNotMetMinimumRequirements && (
          <ModalFirmBillingFLPRequirementsNotMet isOpen={hasNotMetMinimumRequirements} handleClose={onCloseModal} />
        )}

        {(isCardSelected || isRecommended) && (
          <CardTopWrapper>
            <CurrentWrapper className={isRecommended ? 'line-green' : 'line-red'}>
              <StyledCurrentTextWrapper className={isRecommended ? 'text-green' : 'text-red'}>
                <StyledCurrentText>{isRecommended ? 'Recommended' : 'Selected package'}</StyledCurrentText>
              </StyledCurrentTextWrapper>
            </CurrentWrapper>
          </CardTopWrapper>
        )}
        <StyledCard isEntryPointLevel={isEntryPointLevel}>
          <StyledUpperWrapper>
            <>
              <Grid>
                <Grid.Row>
                  {image && (
                    <GridColumn mobile={6} computer={16} tablet={16}>
                      <StyledImage src={image.url} alt={image.altText} />
                    </GridColumn>
                  )}
                  <GridColumn mobile={10} computer={16} tablet={16}>
                    <TitleImageDesktop heading={name} title={(!isEntryPointLevel && displayTitle && title) || ''} />
                  </GridColumn>
                </Grid.Row>
              </Grid>

              <Divider />
            </>
            {description && (
              <>
                {!isRecommended ? (
                  <StyledTextExtra>
                    <ReactMarkdown children={description as any} />
                  </StyledTextExtra>
                ) : (
                  <StyledParagraph dangerouslySetInnerHTML={{ __html: description }} tabIndex={0} />
                )}
              </>
            )}
            {!isRecommended && (
              <OnlyDesktopCSS>
                <Grid columns={1}>
                  <GridColumn>
                    <StyledTextBold>Benefits include</StyledTextBold>
                    <Benefits
                      benefits={benefits}
                      benefitsReference={benefitsReference}
                      setOpenModal={setOpenCloseModalCallBack}
                    />
                  </GridColumn>
                </Grid>
              </OnlyDesktopCSS>
            )}
          </StyledUpperWrapper>
          <OnlyMobileCSS>
            <StyledBenefitsMobile>
              <StyledToggleButton
                testId={`show-hide-benefits-${slug}`}
                onClick={toggleVisibility}
                isOpen={areAllVisible}
              >
                {areAllVisible ? collapsedText : defaultText}
              </StyledToggleButton>
              {areAllVisible && (
                <>
                  <Benefits
                    benefits={benefits}
                    benefitsReference={benefitsReference}
                    setOpenModal={setOpenCloseModalCallBack}
                  />
                  <Divider />
                </>
              )}
            </StyledBenefitsMobile>
          </OnlyMobileCSS>
          {showPQMandatoryExamCreditsText && !isRecommended && (
            <StyledMandatoryExamCreditsText>
              A bonus exam credit, valid for 12 months, will be added to your cart.
            </StyledMandatoryExamCreditsText>
          )}
          <StyledLowerContainer>
            {!isFLPUpgrade && !isFLPSwitch && (
              <>
                {!isApprenticeCurrentJourney && !isStudyPath && !isOfflineExemptionCalculator && (
                  <StyledSubtitle dangerouslySetInnerHTML={{ __html: customPriceLabel || '' }} />
                )}
                {productDiscount && hasDiscount && !isApprenticeCurrentJourney && (
                  <>
                    <StyledDiscountPrice>{productDiscount.title}</StyledDiscountPrice>
                    {productDiscount.description && <StyledNotes>{productDiscount.description}</StyledNotes>}
                  </>
                )}
                {hasPromoCode &&
                  (discountCodeDescription?.toLowerCase().includes(cardSlug) ||
                    discountCodeCartDescription?.toLowerCase().includes(cardSlug)) &&
                  renderDiscountValue()}
                {isStudyPath && <StyledSubtitle dangerouslySetInnerHTML={{ __html: customPriceLabel || '' }} />}
                <>
                  {isStudyPath && <StyledNotes>{additionalInfo}</StyledNotes>}
                  <StyledNotes>{productTypeSubNotes}</StyledNotes>
                </>
              </>
            )}
            {(isEntryPointLevel || isOfflineExemptionCalculator) && (
              <>
                {!isRecommended && (
                  <>
                    <Divider />
                    <StyledHint isCardSelected={isCardSelected || isOfflineExemptionCalculator}>
                      {isCardSelected || isOfflineExemptionCalculator
                        ? 'Your current entry point level'
                        : 'Entry Point Level'}
                    </StyledHint>

                    <EntryPointLevelCardWrapper>
                      <EntryPointLevelCard
                        headerTitle={entryPoint?.headerTitle}
                        description={entryPoint?.description}
                        entryPointLevelExamList={entryPoint?.entryPointLevelExamList}
                        isSelected={isCardSelected}
                        isFLPPath={isFLPUpgrade || isFLPSwitch}
                        levelNameValidateText={levelNameValidateText}
                        slug={slug}
                        isOfflineExemptionCalculator={isOfflineExemptionCalculator}
                      />
                    </EntryPointLevelCardWrapper>
                  </>
                )}
                {!isRecommended &&
                  !isOfflineExemptionCalculator &&
                  ((exemptionInfo.title === ExemptionLevels.ExemptionLevels.Level_1 && isFlpCurrentJourney) ||
                  exemptionInfo.title === ExemptionLevels.LevelName.Level_1 ||
                  exemptionInfo.title === ExemptionLevels.LevelNameFLP.Level_1 ||
                  exemptionInfo.title === ExemptionLevels.LevelNameChinesePQ.Level_1 ||
                  isApprenticeshipCard ||
                  isFLPUpgrade ||
                  isFLPSwitch ||
                  isChinesePathwayCurrentJourney ? (
                    ''
                  ) : (
                    <StyledLearnMoreLink
                      testId={`learn-more-link-${slug}`}
                      to={propPageReference}
                      onClick={setOpenCloseModalCallBack.bind(null, id, slug, productType)}
                      variant={ButtonEnums.variants.primary}
                      fluid
                    >
                      Select another entry point
                    </StyledLearnMoreLink>
                  ))}
              </>
            )}
            {!isOfflineExemptionCalculator ? (
              <>
                {!isUserMember && isStudyPath && slug === MembershipTypes.CIMALearningPathwaySlug.APPRENTICESHIP
                  ? ApplyNowOpenModal
                  : isApprenticeCurrentJourney
                  ? isApprenticeshipCard
                    ? ApplyNowButton
                    : isRenewal
                    ? ApplyNowButton
                    : slug !== MembershipTypes.CIMALearningPathwaySlug.APPRENTICESHIP
                    ? ApplyNowButton
                    : ApplyNowOpenModal
                  : exemptionInfo
                  ? isFLPUpgrade || isFLPSwitch
                    ? ApplyNowButton
                    : exemptionInfo.title === ExemptionLevels.LevelName.Level_1 && isFlpCurrentJourney && isUserFromUSA
                    ? ApplyNowButtonDisabled
                    : ApplyNowButton
                  : ApplyNowButton}
              </>
            ) : (
              <StyledButton
                buttonText={'See my exemptions'}
                testId={`learn-more-link-${slug}`}
                to={propPageReference}
                onClick={setOpenCloseModalCallBack}
                variant={ButtonEnums.variants.primary}
                fluid
              >
                See my exemptions
              </StyledButton>
            )}
          </StyledLowerContainer>
        </StyledCard>
      </>
    );
  }
);
interface StyledCimaMembershipTypeCardProps {
  isEntryPointLevel?: boolean;
  isCardSelected?: boolean;
}

const StyledCheckIcon = styled(Checkmark)`
  &&& {
    path {
      fill: ${props => props.theme.colors.interfaceGreen};
    }
  }
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
  margin: ${props => `0 ${props.theme.pxToRem(5)} 0 0`};
  object-fit: contain;
`;
const StyledCard = styled.div<StyledCimaMembershipTypeCardProps>`
  overflow-x: clip;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0;
  padding: ${props => props.theme.pxToRem(28)};
  border-radius: ${props => props.theme.pxToRem(4)};
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(14)} 0
    ${({ theme }) => theme.colors.neutralGrey6};
  background-color: ${({ theme }) => theme.colors.neutralWhite};
  z-index: -1;
  text-align: left;
  background-origin: border-box;
  width: auto;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100% !important;
  }
  .large-heading {
    display: block;
    position: relative;
    text-align: center;
    width: 100%;
    margin: 0 auto 0;
    ${props => props.theme.mediaQueries.mobileMaxOnly} {
      display: inline-block !important;
      width: 70%;
      text-align: left;
      margin-left: ${props => props.theme.pxToRem(10)};
    }
  }
  > .ui.grid {
    margin: 0 !important;
  }
`;
const StyledUpperWrapper = styled.div`
  align-content: flex-start;
  position: relative;
`;
const StyledTextExtra = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.neutralGrey6};
  height: ${props => props.theme.pxToRem(200)};
  min-height: ${props => props.theme.pxToRem(200)};
  overflow-y: auto;
`;
const StyledMandatoryExamCreditsText = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.neutralGrey6};
  margin: ${props => props.theme.pxToRem(10)} 0 0 0;
`;
const StyledLabeledIcon = styled(LabeledIcon)`
  margin: ${props => `0 ${props.theme.pxToRem(5)} 0 ${props.theme.pxToRem(-48)}`};
  padding: ${props => props.theme.pxToRem(5)};
  font-size: ${props => props.theme.fontSizes.xs};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;

const StyledLink = styled(Link)`
  &&&& {
    font-size: ${props => props.theme.fontSizes.xs};
    line-height: 1.57;
    color: ${({ theme }) => theme.colors.primaryPurple};
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      text-align: center;
      align-items: center;
      margin-left: 30%;
    }
  }
`;

const StyledLearnMoreLink = styled(Button)`
  &&&& {
    width: 100%;
    border: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.primaryLightPurple};
    color: ${props => props.theme.colors.primaryLightPurple};
    background-color: ${props => props.theme.colors.neutralWhite};
    margin: ${props => props.theme.pxToRem(10)} 0;
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: 0 auto ${props => props.theme.pxToRem(8)};
      position: relative;
    }
  }
`;
const StyledToggleButton = styled(ToggleButton)`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin: ${props => props.theme.pxToRem(8)} 0;
`;
const StyledHint = styled.div<StyledCimaMembershipTypeCardProps>`
  display: table;
  margin: ${props => props.theme.pxToRem(7)} 0;
  box-sizing: border-box;
  width: 100%;
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(3)};
  text-align: center;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.pxToRem(1)};
  ${props =>
    props.isCardSelected
      ? `
      background-color: ${props.theme.colors.primaryPurple};
      color: ${props.theme.colors.neutralWhite};
      padding: ${props.theme.pxToRem(5)} ${props.theme.pxToRem(10)};
    `
      : `
      background-color: none;
      color: ${props.theme.colors.neutralGrey8};
      padding: 0;
    `}
`;
const StyledDiscountPrice = styled.div`
  display: table;
  margin: ${props => props.theme.pxToRem(7)} 0;
  padding: ${props => props.theme.pxToRem(5)} ${props => props.theme.pxToRem(10)};
  box-sizing: border-box;
  width: auto;
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.regular};
  background-color: ${props => props.theme.colors.interfaceGreen};
  color: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(3)};
  text-align: center;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.pxToRem(1)};
`;

export const CardTopWrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;
  > .line-red {
    &:after {
      background-color: ${props => props.theme.colors.primaryLightPurple};
    }
  }
  > .line-green {
    &:after {
      background-color: ${props => props.theme.colors.secondaryTeal};
    }
  }
`;

const CurrentWrapper = styled.div`
  & {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
  }
  &:after {
    content: '';
    width: 100%;
    height: ${props => props.theme.pxToRem(7)};
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${props => props.theme.colors.primaryLightPurple};
    border-top-left-radius: ${props => props.theme.pxToRem(4)};
    border-top-right-radius: ${props => props.theme.pxToRem(4)};
  }
  > .text-red {
    background-color: ${props => props.theme.colors.primaryLightPurple};
  }
  > .text-green {
    background-color: ${props => props.theme.colors.secondaryTeal};
  }
`;
const StyledCurrentTextWrapper = styled.div`
  width: auto;
  box-sizing: border-box;
  padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(20)};
  border-radius: ${props => props.theme.pxToRem(4)};
  background-color: ${props => props.theme.colors.primaryLightPurple};
  position: relative;
  text-align: center;
  line-height: 0;
  z-index: 1;
  margin: auto;
  ${props => props.theme.mediaQueries.desktopOnly} {
    top: ${props => props.theme.pxToRem(-8)};
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    top: ${props => props.theme.pxToRem(-8)};
  }
`;
const StyledCurrentText = styled.span`
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.pxToRem(10)};
  text-transform: uppercase;
`;
const StyledTextBold = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.primaryPurple};
  margin-top: ${props => props.theme.pxToRem(40)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xs} !important;
    font-weight: ${props => props.theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.neutralGrey8};
  }
`;

const StyledSubtitle = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-bottom: ${props => props.theme.pxToRem(8)};
`;
const StyledLowerContainer = styled.div`
  width: 100%;
  position: relative;
  display: block;
  bottom: 0;
  margin: ${props => props.theme.pxToRem(40)} auto 0;
`;
const StyledButton = styled(Button)`
  bottom: 0;
`;
const StyledBenefitsMobile = styled.div`
  display: block;
  width: 100%;
`;
const StyledNotes = styled.p`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  margin: ${props => props.theme.pxToRem(8)} 0 ${props => props.theme.pxToRem(24)};
  color: ${({ theme }) => theme.colors.neutralGrey7};
`;

const EntryPointLevelCardWrapper = styled.div`
  margin: ${props => props.theme.pxToRem(24)} 0 ${props => props.theme.pxToRem(8)}!important;
`;

const StyledParagraph = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const StyledImage = styled(Image)`
  margin: 0 auto !important;
  ${props => props.theme.mediaQueries.tabletMax} {
    display: block !important;
  }
`;
