import { Content } from 'mxp-schemas';

export const getShortcutLabel = ({
  categorySlug,
  categoryName,
  topicName,
  subtopicName,
}: {
  categorySlug: string | undefined;
  categoryName: string | undefined;
  topicName: string;
  subtopicName: string;
}): string => {
  const topicSubtopicName = subtopicName || topicName || 'Content Collection';
  return categorySlug === Content.CategorySlugs.ALL
    ? `All Content: ${topicSubtopicName}`
    : `${topicSubtopicName} ${categoryName || ''}`;
};
