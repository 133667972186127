import { handleActions } from 'redux-actions';
import { initialState, cimaSupervisorActionNames } from './constants';
import { StorageNames } from 'constants/index';
import { setLocalStorageItem } from 'utils/localStorage';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [cimaSupervisorActionNames.LOADING]: (state: State.CimaSupervisor): State.CimaSupervisor => ({
    ...state,
    loading: true,
  }),
  [cimaSupervisorActionNames.GET_SUPERVISOR_DASHBOARD]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        dashboard: action.payload.dashboard,
        success: action.payload.success,
        supervisor: action.payload.supervisor,
        isWithdrawnSuccess: false,
      };
    },
  },
  [cimaSupervisorActionNames.GET_MEMBER_DETAILS]: {
    next: (state: State.CimaSupervisor, action: any) => {
      setLocalStorageItem({ [StorageNames.assessorMemberData]: action.payload });
      return {
        ...state,
        memberDetails: action.payload,
      };
    },
  },
  [cimaSupervisorActionNames.GET_EMPLOYMENT_INFORMATION]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        employmentInformation: {
          ...state.employmentInformation,
          ...action.payload,
        },
      };
    },
  },
  [cimaSupervisorActionNames.GET_CORE_ACTIVITIES]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        coreActivities: {
          ...state.coreActivities,
          ...action.payload,
        },
      };
    },
  },
  [cimaSupervisorActionNames.GET_SKILL_BEHAVIOUR]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        skillBehaviour: {
          ...state.skillBehaviour,
          ...action.payload,
        },
      };
    },
  },
  [cimaSupervisorActionNames.UPDATED_VIEW_INFO_STATUS]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        employmentInformation: {
          ...state.employmentInformation,
          isViewOnly: action.payload,
        },
        coreActivities: {
          ...state.coreActivities,
          isViewOnly: action.payload,
        },
        skillBehaviour: {
          ...state.skillBehaviour,
          isViewOnly: action.payload,
        },
      };
    },
  },
  [cimaSupervisorActionNames.UPDATE_FEEDBACK]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        updateResultMessage: action.payload.success ? action.payload.updateResultMessage : '',
      };
    },
  },
  [cimaSupervisorActionNames.RESET_UPDATE_RESULT_FEEDBACK]: {
    next: (state: State.CimaSupervisor) => {
      return {
        ...state,
        updateResultMessage: '',
      };
    },
  },
  [cimaSupervisorActionNames.SAVE_TOKEN]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        token: action.payload,
      };
    },
  },
  [cimaSupervisorActionNames.SET_MODULE_STATUS]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        perModuleStatus: action.payload,
      };
    },
  },
  [cimaSupervisorActionNames.SAVE_PER_MODULE_ID]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        perModuleId: action.payload,
      };
    },
  },
  [cimaSupervisorActionNames.WITHDRAW_SUBMISSION]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        isWithdrawnSuccess: action.payload.success,
        loading: false,
        updateResultMessage: action.payload.success ? action.payload.updateResultMessage : '',
      };
    },
  },
  [cimaSupervisorActionNames.EPA_UPDATE_EPA_AND_COMMENTS]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        updateEpaSuccess: action.payload.success,
        updateResultMessage: action.payload.success ? action.payload.updateResultMessage : '',
      };
    },
  },
  [cimaSupervisorActionNames.SET_STATE]: {
    next: (state: State.CimaSupervisor, action: any) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  [cimaSupervisorActionNames.SAVE_REVIEWER_DETAILS_LOADING]: (state: State.CimaSupervisor): State.CimaSupervisor => ({
    ...state,
    saveReviewerDetailsLoading: true,
  }),
  [cimaSupervisorActionNames.SAVE_REVIEWER_DETAILS_SUCCESS]: (state: State.CimaSupervisor): State.CimaSupervisor => ({
    ...state,
    saveReviewerDetailsSuccess: true,
    saveReviewerDetailsLoading: false,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
