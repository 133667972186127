import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'mxp-schemas';
import moment from 'moment-timezone';
import { setMembershipPackageType } from 'modules/membership';
import { personAccountDataSelector } from 'modules/user/selectors';
import { MembershipTypeSelection } from '../MembershipTypeSelection/MembershipTypeSelection';
import { membershipTypesSelector } from 'modules/membership/selectors';
import styled from 'styled-components';
import { RetiredQualificationModal } from '../ApplicationPersonalForm/RetiredQualificationModal';

export const RenewTypeSelection: React.FC<any> = () => {
  const personalDetail = useSelector(personAccountDataSelector);
  const { list: membershipTypes } = useSelector(membershipTypesSelector);

  const [isModalOpen, setModalOpen] = useState(false);

  const userDiffAge = moment().diff(personalDetail.birthDate, 'years');
  const isUserRetired = moment(personalDetail.birthDate).isValid() && userDiffAge > 64;

  const dispatch = useDispatch();
  const handleSelectType = useCallback(
    (id: string, typeSlug: string) => {
      if (typeSlug === User.B2BMembershipTypes.RETIRED && !isUserRetired) {
        setModalOpen(true);
        return;
      }

      // Add to card right away?
      dispatch(setMembershipPackageType(id, typeSlug));
    },
    [dispatch, isUserRetired]
  );

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <StyledContainer>
      <RetiredQualificationModal retiredModalOpen={isModalOpen} onClose={handleCloseModal} />
      <MembershipTypeSelection
        products={membershipTypes}
        selectMembership={handleSelectType}
        isUserMember={true}
        shouldShowNewPackageBuilder={true}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  padding-right: ${props => props.theme.pxToRem(20)};
  padding-top: ${props => props.theme.pxToRem(40)};
`;
