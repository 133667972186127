import { handleActions } from 'redux-actions';
import { initialStateContact } from './constants';
import { getMipContact } from './action';
import { isServer } from 'utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [getMipContact]: {
    next: (state: State.MipContactReference, action: any): State.MipContactReference => ({
      ...state,
      contactReference: {
        contact: action.payload.getMipContact,
        accountDetails: action.payload.getMipContact.accountDetails,
      },
      contactReferenceFetched: true,
      contactReferenceFrontloadRequest: isServer,
    }),
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialStateContact);
