import React from 'react';
import styled from 'styled-components/macro';
import Helmet from 'react-helmet';
import { Grid } from 'components/atoms/Grid/Grid';
import { useScript } from 'hooks/useScript';
import { NotificationBanner, NotificationBannerEnums } from 'components/atoms/NotificationBanner/NotificationBanner';
import { isValidIframe } from 'utils/StringHelpers';
import { ReactComponent as IconInfo } from 'resources/images/icon-live-ic-info.svg';
import { isMobileViewPort, isServer } from 'utils';

export enum ANIMATIONTYPES {
  CEROS,
  TURTL,
  BLUETOAD,
}

interface Props {
  oembed: string;
  header?: string;
  descriptionText?: string;
  isFullPagePagination?: boolean;
  isLandingPage?: boolean;
  isBlueToad?: boolean;
  isLocked?: boolean;
  type: ANIMATIONTYPES;
}

export const AnimationBlock: React.FC<Props> = ({
  oembed,
  header,
  descriptionText,
  isFullPagePagination,
  isLandingPage,
  isLocked = false,
  type,
}) => {
  const targetContainer = React.useRef<HTMLDivElement | null>(null);
  const [showError, setShowError] = React.useState(false);
  const [isHomePage, setHomePage] = React.useState(false);
  const hasHomePage = Boolean(isLandingPage && !isFullPagePagination);
  const turtlContent = type === ANIMATIONTYPES.TURTL;
  const cerosContent = type === ANIMATIONTYPES.CEROS;
  const isBlueToad = type === ANIMATIONTYPES.BLUETOAD;
  let isIE11: boolean = false;
  if (!isServer) {
    isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode && isBlueToad;
  }

  const isMobile: boolean = isMobileViewPort();
  React.useEffect(() => {
    if (!oembed || !targetContainer.current) {
      return;
    }
    const isValidAnimation = isValidIframe(oembed) || turtlContent;
    if (!isValidAnimation) {
      setShowError(true);
      return;
    }

    const useOembed = isMobile && isBlueToad ? oembed.replace(/pp=2/g, 'pp=1') : oembed;
    targetContainer.current.innerHTML = useOembed;

    setHomePage(hasHomePage);
    if (!isServer) {
      (window as any).turtlEmbed = false; // tslint:disable-next-line:no-shadowed-variable
    }
  }, [oembed, targetContainer, hasHomePage, isBlueToad, isServer]); // eslint-disable-line react-hooks/exhaustive-deps

  const scriptSrc = turtlContent
    ? 'https://app-static.turtl.co/embed/turtl.embed.v1.js'
    : cerosContent
    ? 'https://view.ceros.com/scroll-proxy.min.js'
    : '';
  const scriptStatus = useScript(scriptSrc, 'C0001');

  const titleDescription = () => {
    return (
      (header || descriptionText) && (
        <StyledHeaderDescription
          data-full-page-pagination={isFullPagePagination}
          is-home-page={isHomePage}
          is-landing-page={isLandingPage}
        >
          {header && <StyledHeading tabIndex={0}>{header}</StyledHeading>}
          {descriptionText && (
            <StyledParagraph tabIndex={0} isBlueToad={isBlueToad}>
              {descriptionText}
            </StyledParagraph>
          )}
        </StyledHeaderDescription>
      )
    );
  };

  return (
    <>
      <Helmet>
        <script
          async
          defer
          type="text/javascript"
          src="https://apps.akerolabs.com/public/contents/js/iframe-embed-v3.js"
        />
      </Helmet>
      {showError || isIE11 ? (
        <>
          {isBlueToad && (titleDescription() as JSX.Element)}
          <StyledNotificationBanner
            variant={NotificationBannerEnums.variant.blue}
            testId="notification-banner"
            childrenTestId="notification-children"
            icon={<StyledIconInfo />}
          >
            The following interactive content can't be shown at this time. Try again later.
          </StyledNotificationBanner>
        </>
      ) : (
        <>
          {titleDescription() as JSX.Element}
          {!isLocked && scriptStatus && <WrapperContent is-landing-page={isLandingPage} ref={targetContainer} />}
        </>
      )}
    </>
  );
};

const WrapperContent = styled.div<{ isLandingPage?: boolean }>`
  margin: 0;
  width: 100%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }

  ${props =>
    props.isLandingPage &&
    `
      padding-top: 0;
      padding-bottom: 0;
    `}

  padding-top: ${props => props.theme.pxToRem(30)};
  padding-bottom: ${props => props.theme.pxToRem(30)};
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(25)};
  margin-top: ${props => props.theme.pxToRem(25)};
  width: 85%;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledIconInfo = styled(IconInfo)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  width: ${props => props.theme.pxToRem(24)};
  height: ${props => props.theme.pxToRem(24)};
`;

const StyledHeaderDescription = styled(Grid.Column)`
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-bottom: ${props => props.theme.pxToRem(30)};
  width: 100% !important;
  position: relative;
  padding-left: 0 !important;
  padding-right: 0 !important;

  ${props =>
    (props['data-full-page-pagination'] || props['is-home-page'] || props['is-landing-page']) &&
    `
      text-align: center;
      justify-content: center;
    `}
`;

const StyledHeading = styled.p`
  padding-bottom: ${props => `${props.theme.pxToRem(26)}`};
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 1.33;
  margin: 0;
  white-space: normal !important;
  word-break: break-all;
`;

const StyledParagraph = styled.p<{ isBlueToad: boolean }>`
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.m};
  padding-bottom: ${props => `${props.theme.pxToRem(24)}`};
  line-height: 1.33;
  margin: 0;
  white-space: normal !important;
  width: ${props => (props.isBlueToad ? '80%' : '100%')};
`;
