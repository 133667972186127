import React from 'react';
import { theme } from 'theme';
interface Props {
  size?: number;
  color?: string;
}

export const ArrowDown = React.memo<Props>(({ size = 24, color = theme.colors.primaryPurple, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...props}>
    <path fill={color} fillRule="evenodd" d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8z" />
  </svg>
));
