import React from 'react';
import styled from 'styled-components';
import { LocationDescriptor } from 'history';
import { ReactComponent as ArrowBack } from 'resources/images/ic-arrow-back.svg';
import { Link } from 'components/atoms/Link/Link';

interface BackLinkProps {
  children: string;
  to: LocationDescriptor<{ prevRoute: string }>;
  testId: string;
}

export const BackLink: React.SFC<BackLinkProps> = ({ children, to, testId }) => (
  <StyledBackLink to={to} testId={`back-to-${testId}`}>
    <ArrowBack /> {children}
  </StyledBackLink>
);

const StyledBackLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: ${props => props.theme.fontSizes.xs};
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-weight: ${props => props.theme.fontWeights.light};

  > svg {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    margin-right: ${props => props.theme.pxToRem(4)};

    path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
`;
