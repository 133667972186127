import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { PromotionalBanner } from 'components/molecules/PromotionalBanner/PromotionalBanner';
import {
  NotificationBanner,
  NotificationBannerEnums,
  Link,
  Divider,
  Modal,
  Button,
  ButtonEnums,
} from 'components/atoms';
import { OrganizationSectionLabels } from 'constants/index';
import { SeatManagementTable } from 'components/organisms/SeatManagementTable/SeatManagementTable';
import { TablePlaceholder } from 'components/atoms/TablePlaceholder/TablePlaceholder';
import { ProfileHeading } from 'components/molecules/ProfileHeading/ProfileHeading';
import { Menu } from 'components/atoms/Menu/Menu';
import { ServiceContract } from 'mxp-schemas/src/types/seatManagement';
import { EVENT_CLICK, handleEvent } from '../../../utils/Analytics';
import { SeatManagement } from 'mxp-schemas';
import { IS_SALESFORCE_OFFLINE } from 'modules/featureToggle/constants';
import { Info } from 'components/atoms/svg';
import { theme } from 'theme';

interface Props {
  getContracts: () => void;
  getSeatManagementInvite: (orderNumber: string) => void;
  showSeatAssignmentModal: (contract: ServiceContract) => void;
  handleInviteCancel: (inviteId: string) => void;
  showDeallocationModal: () => void;
  loading: boolean;

  isOpenModal: boolean;
  isInviteUpdate: boolean;
  error: CustomErrors.GraphQLError | null;
  seatManagementInviteList: SeatManagement.AllocatedSeatDynamoDbRecord[];
  activeInactiveContracts: { active: State.ServiceContract[]; inactive: State.ServiceContract[] };
  setSelectedSeatsToDeallocate(
    payload: {
      contract: SeatManagement.ServiceContract;
      lineItemsToDeallocate: SeatManagement.ContractLineItem[];
      pendingList?: string[];
    } | null
  ): void;
  eventDateFilterItems: Common.FilterItemProps[];
  eventStartDateSelectedItems: string[];
  eventEndDateSelectedItems: string[];
  changeFilter: (name: string, value: string) => void;
  extendProductAccess: (args: SeatManagement.ProductAccessExtension) => void;
  status: string;
  isProductAccessExtensionIsLoading: boolean;
  productAccessExtensionDate: string;
  userLength: string;
  productName: string;
  existingEndDate: string;
  ongoingExtensionModalIsOpen: boolean;
  toggleProductAccessExtensionModal: () => void;
  getRecentExtensionProcessStatus: (contractId: string) => boolean;
}

const CONTRACT_TABS = [
  { label: 'Active Orders', key: 'active' },
  { label: 'Inactive Orders', key: 'inactive' },
];

export const OrganizationSeats: React.FC<Props> = ({
  getContracts,
  getSeatManagementInvite,
  loading,
  isOpenModal,
  isInviteUpdate,
  seatManagementInviteList,
  activeInactiveContracts,
  showSeatAssignmentModal,
  handleInviteCancel,
  showDeallocationModal,
  setSelectedSeatsToDeallocate,
  eventDateFilterItems,
  eventStartDateSelectedItems,
  eventEndDateSelectedItems,
  changeFilter,
  extendProductAccess,
  status,
  userLength,
  productName,
  existingEndDate,
  ongoingExtensionModalIsOpen,
  toggleProductAccessExtensionModal,
  getRecentExtensionProcessStatus,
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const onBannerClose = React.useCallback(() => setShowMessage(false), []);

  useEffect(() => {
    setShowMessage(Boolean(status));
  }, [status, setShowMessage]);

  React.useEffect(() => {
    getContracts();
  }, [getContracts]);

  const activeTabKey = CONTRACT_TABS[0].key;

  const handleTabChange = React.useCallback((event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
    const { name, content } = data;
    setActive(name);

    handleEvent({ clickValue: `text:b2b-seat-management:int:${content}` }, EVENT_CLICK);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [activeItem, setActive] = useState(activeTabKey);

  const isSalesforceOffline = useSelector(state =>
    getFeatureToggleByKeySelector(state as State.Root, IS_SALESFORCE_OFFLINE)
  );

  const activeSortedFilled = React.useMemo(
    () =>
      activeInactiveContracts.active.sort((a, b) => {
        const aIsFilled = a.totalQuantity === a.allocatedQuantity;
        const bIsFilled = b.totalQuantity === b.allocatedQuantity;

        return aIsFilled === bIsFilled ? 0 : aIsFilled ? 1 : -1;
      }),
    [activeInactiveContracts]
  );

  const contractList = activeItem === activeTabKey ? activeSortedFilled : activeInactiveContracts.inactive;

  const SalesforceOfflineBannerDescription: React.FC = () => (
    <>We apologize for this convenience. You'll be able to distribute access to your employees soon.</>
  );

  const hasList = contractList && contractList.length;

  const renderContractsList = (): React.ReactNode => {
    if (isSalesforceOffline) {
      return SalesforceOfflineBannerDescription;
    }
    if (!hasList) {
      return <NoOrdersBanner name={activeItem} />;
    }
    return (
      <>
        <SeatManagementTable
          serviceContracts={contractList}
          isOpenModal={isOpenModal}
          isInviteUpdate={isInviteUpdate}
          seatManagementInvite={seatManagementInviteList}
          handleInviteCancel={handleInviteCancel}
          inactive={activeItem !== activeTabKey}
          getSeatManagementInvite={getSeatManagementInvite}
          showSeatAssignmentModal={showSeatAssignmentModal}
          showDeallocationModal={showDeallocationModal}
          setSelectedSeatsToDeallocate={setSelectedSeatsToDeallocate}
          eventDateFilterItems={eventDateFilterItems}
          eventStartDateSelectedItems={eventStartDateSelectedItems}
          eventEndDateSelectedItems={eventEndDateSelectedItems}
          changeFilter={changeFilter}
          extendProductAccess={extendProductAccess}
          toggleProductAccessExtensionModal={toggleProductAccessExtensionModal}
          getRecentExtensionProcessStatus={getRecentExtensionProcessStatus}
        />
        {Boolean(hasList) && <HelpBanner />}
      </>
    );
  };

  const ProductAccessExtensionBanner = () =>
    status === 'SUCCESS' ? (
      <StyledNotificationBanner
        childrenTestId="message-info-children"
        testId="message-info-success"
        variant={NotificationBannerEnums.variant.green}
        closeAction={onBannerClose}
        icon={
          <InfoIcon>
            <Info color={theme.colors.neutralWhite} />
          </InfoIcon>
        }
      >
        Success. Your changes have been saved, the processing of product extension is already underway. Once it is
        completed, it will be reflected in the new end date.
      </StyledNotificationBanner>
    ) : (
      <StyledNotificationBanner
        childrenTestId="message-info-children"
        testId="message-info-failed-product"
        variant={NotificationBannerEnums.variant.red}
        closeAction={onBannerClose}
        icon={
          <InfoIcon isError>
            <Info color={theme.colors.neutralWhite} />
          </InfoIcon>
        }
      >
        Fail, Your changes could not be saved. Please ensure you have a good network connection, verify your entries and
        try again, if error persist contact IT.
      </StyledNotificationBanner>
    );
  return (
    <>
      {showMessage && <ProductAccessExtensionBanner />}
      <Modal
        open={ongoingExtensionModalIsOpen}
        onClose={toggleProductAccessExtensionModal}
        centered
        testId="on-going-extension-modal"
        heading="Product not available for extension"
        confirmNode={
          <Button
            size={ButtonEnums.sizes.small}
            variant={ButtonEnums.variants.primary}
            testId="extend-confirm"
            onClick={toggleProductAccessExtensionModal}
          >
            Okay
          </Button>
        }
      >
        <StyledModalBody>
          Please try again later, There is an on going access extension for the selected product
        </StyledModalBody>
      </Modal>
      <StyledProfileHeading title={OrganizationSectionLabels.SeatManagement} />
      <StyledDivider />
      <MenuStyled data-testid="tabs-menu" pointing secondary>
        {CONTRACT_TABS &&
          CONTRACT_TABS.map(item => {
            return (
              <Menu.Item
                data-testid={item.key}
                key={item.key}
                name={item.key}
                content={item.label}
                active={activeItem === item.key}
                onClick={handleTabChange}
              />
            );
          })}
      </MenuStyled>
      {!loading ? <>{renderContractsList()}</> : <TablePlaceholder />}
    </>
  );
};

const BannerDescription: React.FC = () => (
  <>
    Contact us by selecting the chat icon to the right of your screen. Or call us at&nbsp;
    <Link isExternal testId="ei-question-us-phone" to="tel:800-634-6780">
      800-634-6780
    </Link>{' '}
    or email us at&nbsp;
    <Link isExternal testId="ei-question-non-us-phone" to="mailto:client.support@aicpa-cima.com">
      client.support@aicpa-cima.com
    </Link>
  </>
);

const HelpBanner: React.FC = () => (
  <StyledPromotionalBanner
    title="Need help with your order?"
    description={<BannerDescription />}
    testId="need-help-banner"
    titleTestId="need-help-title"
    descriptionTestId="need-help-desc"
    isMobileFullWidth
    showBorder
    showBackground
  />
);

const NoOrdersBanner: React.FC<{ name: string }> = ({ name }) => {
  return (
    <StyledPromotionalBanner
      title={`You don’t have any ${name} orders`}
      description={<BannerDescription />}
      testId="no-orders-banner"
      titleTestId="no-orders-title"
      descriptionTestId="no-orders-desc"
      isMobileFullWidth
      showBorder
      showBackground
    />
  );
};

const StyledProfileHeading = styled(ProfileHeading)`
  &&&&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
    padding-bottom: 0;
    border-bottom: none;

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-bottom: ${props => props.theme.pxToRem(16)};
    }
  }
`;

const MenuStyled = styled(Menu)`
  &&&&&{
   margin-top ${props => props.theme.pxToRem(0)};
  }
`;

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: ${props => props.theme.pxToRem(24)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(16)};
  }
`;

const StyledPromotionalBanner = styled(PromotionalBanner)`
  margin-top: ${props => props.theme.pxToRem(40)};
  margin-bottom: ${props => props.theme.pxToRem(40)};
`;

const InfoIcon = styled.div<{ isError?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.neutralWhite};
  border-radius: 50%;
  width: ${props => props.theme.pxToRem(20)};
  height: ${props => props.theme.pxToRem(20)};
  background: ${props => props.theme.colors.interfaceGreen}
    ${props => props.isError && `background: ${props.theme.colors.interfaceRed};`};
`;

const StyledDivider = styled(Divider)`
  &&&& {
    margin-bottom: ${props => props.theme.pxToRem(8)};
  }
`;

const StyledModalBody = styled.div`
  text-align: center;
`;
