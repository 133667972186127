import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setDeallocationModalVisibility, deallocateLineItems, getServiceContracts } from 'modules/clientAdmin/actions';
import {
  deallocationModalVisibilitySelector,
  selectedSeatsToDeallocateSelector,
  deallocationLoadingSelector,
} from 'modules/clientAdmin/selectors';
import { SeatManagement } from 'mxp-schemas';
import { ConfirmSeatDeallocateModal } from 'components/pages/PageOrganization/ConfirmSeatDeallocateModal';

const mapActionCreators = (dispatch: Dispatch) => ({
  deallocateLineItems(
    serviceContract: SeatManagement.ServiceContract,
    lineItems: SeatManagement.ContractLineItem[],
    pendingList?: string[]
  ): void {
    dispatch(deallocateLineItems(serviceContract, lineItems, pendingList))
      .then(() => dispatch(getServiceContracts()))
      .catch((e: any) => {
        console.error(`deallocateLineItems error ${e}`);
      });
  },

  setVisibility(visible: boolean): void {
    dispatch(setDeallocationModalVisibility(visible));
  },
});

const mapStateToProps = (state: State.Root) => {
  const selectedSeats = selectedSeatsToDeallocateSelector(state);
  return {
    visible: deallocationModalVisibilitySelector(state),
    lineItems: selectedSeats.lineItemsToDeallocate,
    deallocateLoading: deallocationLoadingSelector(state),
    serviceContract: selectedSeats.contract,
    pendingList: selectedSeats.pendingList,
  };
};

export const ConfirmSeatDeallocateContainer = connect(mapStateToProps, mapActionCreators)(ConfirmSeatDeallocateModal);
