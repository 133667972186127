import React from 'react';
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';
import { TwoColumn, PageContainer } from 'components/templates';
import { AicpaLogo, Heading, Loader } from 'components/atoms';
import backgroundImage from 'resources/images/illustrations-build-feed.svg';
import { Props } from 'containers/PageSiteOfflineContainer';

/**
 * Holding page for site when offline
 * 10894
 */

const defaultContent = {
  header: 'This site is under careful construction',
  description: 'Please visit again soon for exciting new features.',
};

export const PageSiteOffline: React.FC<Props> = ({ landingPage, landingPageFetched }) => {
  const landingPageBlocks: any = landingPage?.landingPageBlocks[0];
  const header = landingPageBlocks?.header ? landingPageBlocks?.header : defaultContent.header;
  const description = landingPageBlocks?.description ? landingPageBlocks?.description : defaultContent?.description;

  return !landingPageFetched ? (
    <Loader />
  ) : (
    <StyledBackground>
      <PageContainer>
        <StyledHeader>
          <LogoContainer>
            <AicpaLogo />
          </LogoContainer>
        </StyledHeader>
        <Container>
          <TwoColumn>
            <StyledContainer>
              {header && (
                <StyledHeading>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: header,
                    }}
                  />
                </StyledHeading>
              )}

              {description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              )}
            </StyledContainer>
            <StyledHeadlineContainer data-testid="right-block-headline">
              <StyledImageContainer logo={landingPageBlocks?.image ? landingPageBlocks?.image : ''}>
                <StyledImage data-testid="right-block-image" />
              </StyledImageContainer>
            </StyledHeadlineContainer>
          </TwoColumn>
        </Container>
      </PageContainer>
    </StyledBackground>
  );
};

const StyledBackground = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey1};
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  padding-bottom: 10%;
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-bottom: 45%;
  }
`;

const StyledHeader = styled.header`
  box-shadow: 0 ${props => props.theme.pxToRem(2)} ${props => props.theme.pxToRem(6)} 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.97);
  height: ${props => props.theme.pxToRem(80)};
  margin-bottom: 2rem;
`;

const StyledHeading = styled(Heading)`
  &&& {
    font-size: ${props => props.theme.fontSizes.xxl};
    color: ${props => props.theme.colors.neutralGrey8};
    font-weight: ${props => props.theme.fontWeights.light};
    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.fontSizes.xl};
    }
  }
`;

const LogoContainer = styled(Container)`
  padding: 1rem 0 1rem 0;
`;

const StyledContainer = styled.div`
  ${props => props.theme.mediaQueries.tabletMin} {
    padding: ${props => props.theme.pxToRem(200)} 0;
    margin-left: 0vw;
    max-width: ${props => props.theme.pxToRem(400)};
  }
`;

const StyledImage = styled.img`
  min-height: ${props => props.theme.pxToRem(400)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    min-height: ${props => props.theme.pxToRem(321)};
  }
`;

const StyledImageContainer = styled(Container)`
  position: relative;
  background: url(${props => (props?.logo ? props.logo : backgroundImage)}) no-repeat;
  display: flex;
  height: ${props => props.theme.pxToRem(450)};

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-top: ${props => props.theme.pxToRem(20)};
    padding-bottom: ${props => props.theme.pxToRem(20)};
    background-size: contain;
  }
  ${props => props.theme.mediaQueries.desktopOnly} {
    padding: ${props => props.theme.pxToRem(200)} 0;
    z-index: -1;
    background-size: contain;
    min-width: ${props => props.theme.pxToRem(590)};
    height: auto;
    ${props => props.theme.mediaQueries.ieOnly} {
      bottom: ${props => props.theme.pxToRem(-20)};
    }
  }
`;

const StyledHeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: ${props => props.theme.pxToRem(483)};
`;
