import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { parse } from 'query-string';
import { getSearch } from 'connected-react-router';
import {
  GET_CONTENT_ITEM,
  QUERY_CONTENT_BY_PREF,
  GET_RELATED_CONTENT,
  GET_STATIC_CONTENT_ITEM,
  QUERY_AGGS_PAGE_CONTENT,
  GET_BRAND_IMAGE,
  GET_TOOLKIT_PAGE,
  QUERY_PRODUCT_AGGS_PAGE_CONTENT,
  GET_CONTENT_BLOGS,
  GET_RICH_TEXT,
  GET_PRODUCTS_ITEMS,
} from 'mxp-graphql-queries';
import { LOGIN_IMAGE_KEY } from './constants';
import { contentBlogsMetaSelector, contentMetaSelector } from './selectors';
import { topicsSlugIdMapSelector, subtopicsSlugIdMapSelector, topicsHashSelector } from 'modules/topics/selectors';
import { industriesHashSelector, industrySlugIdMapSelector } from 'modules/industries/selectors';
import { trendsHashSelector, trendSlugIdMapSelector } from 'modules/trends/selectors';
import { skillsHashSelector, skillsSlugIdMapSelector, subskillsSlugIdMapSelector } from 'modules/skills/selectors';
import { productTypesSlugIdMapSelector } from 'modules/productTypes/selectors';
import { convertTopicsSlugsToIds } from 'modules/topics/helpers';
import { convertIndustriesSlugsToIds } from 'modules/industries/helpers';
import { convertTrendsSlugsToIds } from 'modules/trends/helpers';
import { convertSkillsSlugsToIds } from 'modules/skills/helpers';
import { contentPageItemSelector } from 'modules/content/selectors';
import { resetCurrentContentId, setCurrentContentId } from 'modules/layouts';
import {
  pageTopicAggsTopicSlugSelector,
  pageTopicAggsSubtopicSlugSelector,
  pageIndustryAggsIndustrySlugSelector,
  pageTrendAggsTrendSlugSelector,
  pageSkillAggsSkillSlugSelector,
  pageSkillAggsSubskillSlugSelector,
} from 'modules/router';
import { emptyArray } from 'utils';
import { loginRequiredMetaData, addMetaData } from 'middlewares';
import { default as request } from 'utils/GraphQLClient';
import { DefaultPagination } from 'constants/index';
import { FEED_PRODUCT_VIEW, handleEvent, extractProductPayload } from 'utils/Analytics';

// ------------------------------------
// Actions
// ------------------------------------
export const contentLoading: any = createAction('content/LOADING');
export const resetSkipContentFrontloadRequest: any = createAction('content/RESET_SKIP_FRONTLOAD');

export const getContent: any = createAction(
  'content/GET_CONTENT_HASH',
  (options: { useMerge: boolean } = { useMerge: false }) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(contentLoading());
      const state: State.Root = getState();
      const search = getSearch(state);
      const { topic, industry, skill, trend, category }: any = parse(search, {
        arrayFormat: 'comma',
        parseNumbers: true,
      });
      const topicsIds: string[] | undefined = convertTopicsSlugsToIds(topic, state);
      const industryIds: string[] | undefined = convertIndustriesSlugsToIds(industry, state);
      const trendIds: string[] | undefined = convertTrendsSlugsToIds(trend, state);
      const skillIds: string[] | undefined = convertSkillsSlugsToIds(skill, state);
      const { page, perPage }: State.Meta = contentMetaSelector(state);
      const pageQuery: number = options.useMerge ? page + 1 : DefaultPagination.List.PAGE;

      const products =
        state.products.productsListData &&
        state.products.productsListData.lineItems.reduce(
          (purchased: string[], lienItem: State.LineItem) => [...purchased, lienItem.productId],
          []
        );
      const filters = { topic: topicsIds, industry: industryIds, trend: trendIds, skill: skillIds, category, products };
      return request(QUERY_CONTENT_BY_PREF, { page: pageQuery, perPage, filters }).then(response => {
        const { results, meta } = response.getContentByPref;
        const contentHash: State.ContentHash = results.reduce((acc: any, item: State.ContentItem) => {
          acc[item.id] = item;
          return acc;
        }, {});

        const content = Object.values(contentHash);

        const payload = extractProductPayload(content, {
          from: (pageQuery - 1) * perPage,
          total: (pageQuery - 1) * perPage + content.length,
          to: pageQuery * perPage,
          perPage,
          pageNumber: pageQuery,
        });
        if (payload) {
          handleEvent(payload, FEED_PRODUCT_VIEW);
        }

        return { contentHash, useMerge: options.useMerge, meta };
      });
    }
);

const ContentItemHandler = (dispatch: any, getContentItemBySlug: { id: string }) => {
  if (!getContentItemBySlug) {
    return Promise.reject('Static Content Item Not Found');
  }
  dispatch(setCurrentContentId(getContentItemBySlug.id));
  return getContentItemBySlug;
};

export const getContentItem: any = createAction(
  'content/GET_CONTENT_ITEM',
  (slug: string, category: string, type: string) => (dispatch: Dispatch) => {
    dispatch(resetCurrentContentId());
    dispatch(contentLoading());
    return request(GET_CONTENT_ITEM, { slug, category, type }).then(({ getContentItemBySlug }: any) =>
      ContentItemHandler(dispatch, getContentItemBySlug)
    );
  }
);
export const getProductAggsPageContent: any = createAction(
  'content/GET_PRODUCT_AGGS_PAGE_CONTENT',
  ({
      isAggByTopicPage,
      topicSlug,
      productTypeSlug,
    }: {
      isAggByTopicPage: boolean;
      topicSlug?: string;
      productTypeSlug?: string;
    }) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(contentLoading());
      const state: State.Root = getState();

      const slugIdMap: { [key: string]: string } | undefined = isAggByTopicPage
        ? topicsSlugIdMapSelector(state)
        : productTypesSlugIdMapSelector(state);

      const slugId: string | undefined = slugIdMap[(isAggByTopicPage ? topicSlug : productTypeSlug) as string];

      if (!slugId) {
        return Promise.reject('Topic not found, Redirect to 404');
      }

      return request(QUERY_PRODUCT_AGGS_PAGE_CONTENT, {
        topicId: topicSlug && slugId,
        productTypeId: productTypeSlug && slugId,
      }).then(pageContent => {
        if (!pageContent) {
          return Promise.reject('No page for this topic, Redirect to 404');
        }
        return pageContent.getProductAggsPageContent;
      });
    }
);

export const getToolkitPage: any = createAction('content/GET_TOOLKIT_PAGE', (slug: string) => (dispatch: Dispatch) => {
  dispatch(contentLoading());
  return request(GET_TOOLKIT_PAGE, { slug }).then(({ toolkitPage }: any) => {
    if (!toolkitPage) {
      return Promise.reject('Toolkit Page Content Not Found');
    }
    dispatch(setCurrentContentId(toolkitPage.id));
    return ContentItemHandler(dispatch, toolkitPage);
  });
});

export const getStaticContentItem: any = createAction(
  'content/GET_STATIC_CONTENT_ITEM',
  (slug: string, type: string) => (dispatch: Dispatch) => {
    dispatch(contentLoading());
    return request(GET_STATIC_CONTENT_ITEM, { slug, type }).then(({ getContentItemBySlug }: any) =>
      ContentItemHandler(dispatch, getContentItemBySlug)
    );
  }
);

export const getRelatedContentList: any = createAction(
  'content/GET_RELATED_CONTENT',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const contentItem: State.ContentItem | null = contentPageItemSelector(state);
    if (!contentItem) return null;
    const topics: string[] = contentItem.topics ? contentItem.topics.map(item => item.id) : emptyArray;
    const subtopics: string[] = contentItem.subtopics ? contentItem.subtopics.map(item => item.id) : emptyArray;
    return request(GET_RELATED_CONTENT, {
      topics,
      subtopics,
      excludeIds: [contentItem.id],
    }).then(({ getRelatedContent }: any) => {
      if (!getRelatedContent) {
        return Promise.reject('Related content items not found');
      }
      return getRelatedContent;
    });
  }
);

export const sendContentFeedback: any = createAction(
  'content/SEND_FEEDBACK',
  (payload: { rating: boolean; option: string; description: string }) => (dispatch: Dispatch) => {
    const { rating, option, description } = payload;
    return Promise.resolve({
      rating,
      option,
      description,
    });
  },
  addMetaData(loginRequiredMetaData)
);

export const fetchAggsPageContent: any = createAction(
  'content/FETCH_AGGS_PAGE',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(contentLoading());
    const state: State.Root = getState();
    const topicSlug: string = pageTopicAggsTopicSlugSelector(state);
    const subtopicSlug: string = pageTopicAggsSubtopicSlugSelector(state);
    const industrySlug: string = pageIndustryAggsIndustrySlugSelector(state);
    const trendSlug: string = pageTrendAggsTrendSlugSelector(state);
    const skillSlug: string = pageSkillAggsSkillSlugSelector(state);
    const subskillSlug: string = pageSkillAggsSubskillSlugSelector(state);

    const topicsSlugIdMap: { [key: string]: string } | undefined = topicSlug
      ? topicsSlugIdMapSelector(state)
      : undefined;
    const subtopicsSlugIdMap: { [key: string]: string } | undefined = subtopicSlug
      ? subtopicsSlugIdMapSelector(state)
      : undefined;
    const industriesSlugIdMap: { [key: string]: string } | undefined = industrySlug
      ? industrySlugIdMapSelector(state)
      : undefined;
    const trendsSlugIdMap: { [key: string]: string } | undefined = trendSlug
      ? trendSlugIdMapSelector(state)
      : undefined;
    const skillsSlugIdMap: { [key: string]: string } | undefined = skillSlug
      ? skillsSlugIdMapSelector(state)
      : undefined;
    const subskillsSlugIdMap: { [key: string]: string } | undefined = subskillSlug
      ? subskillsSlugIdMapSelector(state)
      : undefined;
    const topicId: string | undefined = topicSlug && topicsSlugIdMap && topicsSlugIdMap[topicSlug];
    const subtopicId: string | undefined = subtopicSlug && subtopicsSlugIdMap && subtopicsSlugIdMap[subtopicSlug];
    const industryId: string | undefined = industrySlug && industriesSlugIdMap && industriesSlugIdMap[industrySlug];
    const trendId: string | undefined = trendSlug && trendsSlugIdMap && trendsSlugIdMap[trendSlug];
    const skillId: string | undefined = skillSlug && skillsSlugIdMap && skillsSlugIdMap[skillSlug];
    const subskillId: string | undefined = subskillSlug && subskillsSlugIdMap && subskillsSlugIdMap[subskillSlug];
    const topics: any = topicsHashSelector(state);
    const topicSlugs = Object.keys(topics);
    const industries: any = industriesHashSelector(state);
    const industrySlugs = Object.keys(industries);
    const trends: any = trendsHashSelector(state);
    const trendSlugs = Object.keys(trends);
    const skills: any = skillsHashSelector(state);
    const skillSlugs = Object.keys(skills);
    if (
      (topicSlug && !topicId) ||
      (industrySlug && !industryId) ||
      (trendSlug && !trendId) ||
      (skillSlug && !skillId) ||
      (subskillSlug && !subskillId) ||
      (subtopicSlug && !subtopicId)
    ) {
      return Promise.reject('Taxonomies not found, Redirect to 404');
    }
    return request(QUERY_AGGS_PAGE_CONTENT, {
      topicId,
      subtopicId,
      industryId,
      trendId,
      skillId,
      subskillId,
      topicSlugs,
      industrySlugs,
      trendSlugs,
      skillSlugs,
    });
  }
);

export const getBrandImages: any = createAction(
  'content/GET_IMAGES',
  () => () => request(GET_BRAND_IMAGE, { name: LOGIN_IMAGE_KEY })
);

export const resetContentModule: any = createAction('content/RESET_MODULE');

export const contentBlogsLoading: any = createAction('content/BLOGS_LOADING');

export const getContentBlogs: any = createAction(
  'content/GET_CONTENT_BLOGS_HASH',
  (options: { useMerge: boolean } = { useMerge: false }) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(contentLoading());
      const state: State.Root = getState();
      const { page, perPage }: State.Meta = contentBlogsMetaSelector(state);
      const pageQuery: number = options.useMerge ? page + 1 : DefaultPagination.List.PAGE;

      return request(GET_CONTENT_BLOGS, { page: pageQuery, perPage }).then(response => {
        const { results, meta } = response.getContentBlogs;
        const contentBlogsHash: State.ContentHash = results.reduce((acc: any, item: State.ContentItem) => {
          acc[item.id] = item;
          return acc;
        }, {});

        return { contentBlogsHash, useMerge: options.useMerge, blogsMeta: meta };
      });
    }
);

export const setIsComingFromFcmaPropPageJourney: any = createAction(
  'content/SET_IS_COMING_FROM_FCMA_PROP_PAGE_JOURNEY',
  (isComingFromFcmaPropPageJourney: boolean) => isComingFromFcmaPropPageJourney
);

export const getDownloadContentItemBySlug: any = createAction(
  'content/GET_DOWNLOAD_CONTENT_ITEM_BY_SLUG',
  (slug: string, type: string) => (dispatch: Dispatch) => {
    return request(GET_CONTENT_ITEM, { slug, type }).then(
      ({ getContentItemBySlug }: any) => getContentItemBySlug?.downloads || []
    );
  }
);

export const fetchRawRichText: any = createAction('content/FETCH_RICH_TEXT', (slug: string, type: string) => () => {
  return request(GET_RICH_TEXT, { slug, type });
});

export const getPromotedProducts: any = createAction(
  'content/GET_PROMOTED_PRODUCTS',
  (slugs: string[]) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(GET_PRODUCTS_ITEMS, {
      slug: slugs,
      isPublished: false,
    }).then(async response => {
      return response?.getProductsBySlugs || [];
    });
  }
);
